import moment from "moment"
import { Project } from "../../../utils/Project"
import { dateToJson } from "../../utils/serializer"


export type ConfirmInboundInfo = Partial<Optional>
type ConfirmInboundInfoJson = Partial<JsonOptional>
export const confirmInboundInfoToJson: Project<ConfirmInboundInfo, ConfirmInboundInfoJson> = obj => ({
    ...obj,
    inboundDate: dateToJson(obj.inboundDate),
    inboundTime: obj.inboundTime ? moment(obj.inboundTime).format(moment.HTML5_FMT.TIME_SECONDS) : null,
})

interface Optional {
    inbIdList: number[] | null,
    inboundNo: string | null,
    containerNo: string | null,
    outerPackageNo: string | null,
    innerPackageNo: string | null,
    inboundRefNo: string,
    inboundDate: Date,
    inboundTime: Date | null,
}


interface JsonOptional {
    inbIdList: number[] | null,
    inboundNo: string | null,
    containerNo: string | null,
    outerPackageNo: string | null,
    innerPackageNo: string | null,
    inboundRefNo: string,
    inboundDate: string,
    inboundTime: string | null,
}