import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { dateToJson } from "../../utils/serializer"
import { ActiveFlag } from "../enums/ActiveFlag"
import { PairedOrderFlag } from "../enums/PairedOrderFlag"
import { RepackingType } from "../enums/RepackingType"
import { UnitType } from "../enums/UnitType"

export type PartsDetailResult = Required & Partial<Optional>
export type PartsDetailResultJson = JsonRequired & Partial<JsonOptional>
export const partsDetailResultFromJson: Project<PartsDetailResultJson, PartsDetailResult> = json => ({
    ...json,
    effectiveFrom: json.effectiveFrom ? dateFromJson(json.effectiveFrom) : undefined
})
export const partsDetailResultToJson: Project<PartsDetailResult, PartsDetailResultJson> = obj => ({
    ...obj,
    effectiveFrom: obj.effectiveFrom ? dateToJson(obj.effectiveFrom) : undefined
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'effectiveFrom'> {
    unitType: UnitType,
    pairedOrderFlag: PairedOrderFlag,
    activeFlag: ActiveFlag,
    effectiveFrom: Date,
    repackingType: RepackingType,
}

interface JsonRequired {
    unitPartsId: number,
}

interface JsonOptional {
    partsId: number,
    unitType: number,
    buId: number,
    customerId: number,
    supplierId: number,
    unitPartsNo: string,
    unitPartsName: string,
    partsRefNo: string,
    backNo: string,
    hscode: string,
    pairedFlag: number,
    pairedUnitPartsId: number,
    pairedOrderFlag: number,
    activeFlag: number,
    partsNo: string,
    pairedPartsNo: string,
    contractNo: string,
    contractPartsId: number,
    dataKeyId: number,

    uomCode: string,
    unitPartsSpecId: number,
    orderLot: number,
    spq: number,
    m3: number,
    netWeight: number,
    grossWeight: number,

    unitPartsPriceId: number,
    currency: string,
    unitPrice: number,

    effectiveFrom: string,

    requestDetailId: number,
    contractRouteNo: string,
    sellerCode: string,
    sellerPartsNo: string,
    sellerPartsId: number,

    contractId: number,
    contrRouteId: number,

    repackingType: number,
}
