import { Dialog, DialogActions, DialogContent, Grid, makeStyles, Typography } from "@material-ui/core"
import { Add, Close } from "@material-ui/icons"
import StarBorderIcon from '@material-ui/icons/StarBorder'
import { ColumnFreeze, ColumnResizing, Data, DataGrid, DataTypePreset, Filtering, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { Records } from "@rithe/utils"
import { SetStateAction, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { SectionCard } from "../../components/Card/SectionCard"
import { SectionCardContent } from "../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../components/Card/SectionCardHeader"
import { FlexScrollbar } from "../../components/DataGrid/components/FlexScrollbar"
import { SearchInput } from "../../components/DataGrid/components/SearchInput"
import { DialogAction } from "../../components/Dialog/DialogAction"
import { DialogHeader } from "../../components/Dialog/DialogHeader"
import { useAddFavouritePage, useRemoveFavouritePage } from "../../services/system/apis/FavouritePageApi"
import { applicationActions, useApplicationSelector } from "../Application/applicationSlice"
import { CoMonitoringListIcon } from "./CoMonitoringListIcon"
import { useHomeSelector } from "./HomeSlice"
import { PageIcon } from "./PageIcon"
import { pageInfoList } from "./PageInfoList"

interface FavouritePage {
    pageId: number
    pageCode: string
    pageName: string
    userId: number
    cbdsUid: string
    resourceId: number
    resourceCode: string
    url: string
    createdDate: Date
    updatedDate: Date
}

interface FrequentlyUsedandPage {
    pageId: number,
    pageCode: string,
    userId: number,
    cbdsUid: string,
    accessedCount: number
    pageName: string,
    url: string
}


export const FrequentlyUsedandFavouritePageView = () => {
    const intl = useIntl()

    return <SectionCard allowCollapse>
        <SectionCardHeader
            title={intl.formatMessage({ id: 'quickAccess' })}
            subtitle={intl.formatMessage({ id: 'quickAccessSubtitle' })}
        />
        <SectionCardContent>
            <FrequentlyUsedPageView />
            <FavouritePageView />
        </SectionCardContent>
    </SectionCard>
}

const FrequentlyUsedPageView = () => {
    const resources = useApplicationSelector(state => state.auth.accessResources)
    const frequentlyUsedPages = useHomeSelector(state => state.frequentlyUsedPages)
    const navigate = useNavigate()
    const styles = useStyles()
    const intl = useIntl()

    let list = [] as FrequentlyUsedandPage[]
    if (frequentlyUsedPages === null) {
        list = []
    } else {
        list = frequentlyUsedPages.filter(item => pageInfoList.map(item => item.pageCode).includes(item.pageCode)).map(item => ({
            ...item,
            url: pageInfoList.filter(page => page.pageCode === item.pageCode)[0].url,
            pageName: pageInfoList.filter(page => page.pageCode === item.pageCode)[0].pageName,
            access: pageInfoList.filter(page => page.pageCode === item.pageCode)[0].access
        })).filter(item => item.access === '' || resources?.includes(item.access))
        list = list.length > 6 ? list.slice(0, 6) : list
    }
    return <Grid container spacing={5}>
        <Grid item md={12} style={{ marginLeft: 50 }}>
            <div style={{ display: 'flex' }}>
                <CoMonitoringListIcon style={{ height: 30, width: 30 }} />
                <Typography variant="h3" color="initial" >{intl.formatMessage({ id: 'frequentlyUsed' })}</Typography>
            </div>
        </Grid>
        <Grid item md={12} style={{ marginBottom: 20 }}>
            <Grid container spacing={2}>
                {list.map(item => {
                    return <Grid item md={2} key={item.pageId} style={{ paddingLeft: 3, paddingRight: 3 }}>
                        <div className={styles.frequentUsedIcon} onClick={() => navigate(item.url)}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <PageIcon pageCode={item.pageCode} />
                            </div>
                            <Typography variant="subtitle1" align="center" color="textPrimary" style={{ marginTop: 10 }} >{item.pageName}</Typography>
                        </div>
                    </Grid>
                })}
                {list.length === 0 && <div style={{ height: 80, width: 80 }}></div>
                }
            </Grid>
        </Grid>
    </Grid >
}

const FavouritePageView = () => {
    const [open, setOpen] = useState(false)
    const resources = useApplicationSelector(state => state.auth.accessResources)
    const favouritePages = useApplicationSelector(state => state.auth.favouritePages)
    let list = [] as FavouritePage[]
    if (favouritePages === null) {
        list = []
    } else {
        list = favouritePages.filter(item => pageInfoList.map(item => item.pageCode).includes(item.pageCode)).map(item => ({
            ...item,
            url: pageInfoList.filter(page => page.pageCode === item.pageCode)[0].url,
            pageName: pageInfoList.filter(page => page.pageCode === item.pageCode)[0].pageName,
            access: pageInfoList.filter(page => page.pageCode === item.pageCode)[0].access
        })).filter(item => item.access === '' || resources?.includes(item.access))
        list = list.length > 6 ? list.slice(0, 6) : list
    }

    const navigate = useNavigate()
    const styles = useStyles()
    const intl = useIntl()

    const defaultHoverList = [
        { isHover: false }, { isHover: false }, { isHover: false }, { isHover: false }, { isHover: false }, { isHover: false }
    ]
    const [hoverList, setHoverList] = useState<{ isHover: boolean }[]>(defaultHoverList);
    const handleMouseEnter = (index: number) => {
        setHoverList(defaultHoverList.map((item, idx) => idx === index ? { ...item, isHover: true } : item));
    }

    const handleMouseLeave = (index: number) => {
        setHoverList(defaultHoverList.map((item, idx) => idx === index ? { ...item, isHover: false } : item));
    }

    const removePage = useRemoveFavouritePage()
    const dispatch = useDispatch()

    return <>
        <Grid container spacing={5}>
            <Grid item md={3} style={{ marginLeft: 50 }}>
                <div style={{ display: 'flex' }}>
                    <StarBorderIcon style={{ height: 30, width: 30 }} />
                    <Typography variant="h3" color="initial" >{intl.formatMessage({ id: 'favourites' })}</Typography>
                </div>
            </Grid>
            <Grid item md={7}>
            </Grid>
            {/* <Grid item md={1}>
                <EditAction data={list} />
            </Grid> */}
            <Grid item md={12}>
                <Grid container spacing={2}>
                    {list.map((item, index) => {
                        return <Grid item md={2} style={{ flex: 1, paddingLeft: 3, paddingRight: 3 }} key={index}>
                            {hoverList[index].isHover &&
                                <div onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={() => handleMouseLeave(index)}
                                    className={styles.closeIconDiv}
                                    onClick={() => {
                                        removePage({ pageCode: item.pageCode, resourceCode: item.resourceCode ?? "" }, { silent: true }).then(() => {
                                            dispatch(applicationActions.loadFavouritePages())
                                        })
                                    }}>
                                    <Close className={styles.closeIcon} />
                                </div>}
                        </Grid>
                    })}
                </Grid>
                <Grid container spacing={2} className={styles.iconGrid}>
                    {list.map((item, index) => {
                        return <Grid item md={2} style={{ paddingLeft: 3, paddingRight: 3 }} key={index}>
                            <div onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={() => handleMouseLeave(index)}
                                className={hoverList[index].isHover ? styles.hoverFavoutiteIcon : styles.favoutiteIcon}
                                onClick={() => navigate(item.url)}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <PageIcon pageCode={item.pageCode} />
                                </div>
                                <Typography variant="subtitle1" align="center" color="textPrimary" style={{ marginTop: 10 }}>{item.pageName}</Typography>
                            </div>
                        </Grid>
                    })}
                    {list.length < 6 && <Grid item md={2} style={{ paddingLeft: 3, paddingRight: 3 }}>
                        <div className={styles.addIcon} onClick={() => setOpen(true)}>
                            <Add style={{ height: 70, width: 70, opacity: '0.5' }} />
                        </div>
                    </Grid>}
                </Grid>
            </Grid>
        </Grid >
        <AddFavouritePage open={open} setOpen={setOpen} data={list} />
    </>
}

const AddFavouritePage = ({ open, setOpen, data }: { open: boolean, setOpen: React.Dispatch<SetStateAction<boolean>>, data: FavouritePage[] }) => {
    const resources = useApplicationSelector(state => state.auth.accessResources)
    const pages = data.map(item => item.pageCode)
    const [selection, setSelection] = useState<string>("")
    const onSelectionsChange = useCallback((selects: string[]) => {
        setSelection(selects[selects.length - 1])
    }, [])

    const addPage = useAddFavouritePage()
    const dispatch = useDispatch()
    const callback = useCallback(() => {
        if (selection.length > 0) {
            const pageInfo = pageInfoList.filter(item => item.pageCode === selection)
            addPage({ pageCode: selection, resourceCode: pageInfo.length > 0 ? pageInfo[0].access : "" }, { silent: true }).then(() => {
                setOpen(false)
                dispatch(applicationActions.loadFavouritePages())
            })
        }
        //no change
        if (selection.length === 0) {
            setOpen(false)
        }
        setSelection("")
    }, [addPage, dispatch, selection, setOpen])
    const intl = useIntl()

    const columns = useMemo(() => [
        { field: 'category', dataTypeName: 'string', title: intl.formatMessage({ id: 'category' }), width: 150 },
        { field: 'pageCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'pageCode' }), width: 200 },
        { field: 'pageName', dataTypeName: 'string', title: intl.formatMessage({ id: 'pageName' }), width: 400 },
    ], [intl])
    const getRowId = useCallback((row: any) => row.pageCode, [])
    const displayData = pageInfoList.filter(page => page.access === '' || resources?.includes(page.access)).filter(page => !pages.includes(page.pageCode))


    return <Dialog open={open} style={{ overflow: 'hidden' }} fullWidth maxWidth={'md'}>
        <DialogHeader onClose={() => {
            setOpen(false)
            setSelection("")
        }}>
            <FormattedMessage id="addFavouritePage" />
        </DialogHeader>
        <DialogContent style={{ overflow: 'hidden' }}>
            <div style={{ width: '100%', height: 500, display: 'flex' }}>
                <div style={{ flex: '1 1 auto', overflow: 'hidden' }}>
                    <DataGrid>
                        <ToolbarLayout />
                        <TableLayout Container={FlexScrollbar}>
                            <TableHeaderLayout sticky />
                            <TableBodyLayout />
                        </TableLayout>
                        <DataTypePreset />
                        <Data rows={displayData} columns={columns} getRowId={getRowId} />
                        <ColumnFreeze />
                        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
                        <Sorting />
                        <Filtering />
                        <Searching ignoreCase Input={SearchInput} />
                        <Selection highlightSelectedRow selectedRowIds={[selection]} onSelectedRowIdsChange={onSelectionsChange} />
                    </DataGrid>
                </div>
            </div>
        </DialogContent>
        <DialogActions>
            <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={() => {
                setOpen(false)
                setSelection("")
            }} />
            <DialogAction title={<FormattedMessage id="confirm" />} callback={callback} />
        </DialogActions>
    </Dialog>
}

// const EditAction = ({ data }: { data: FavouritePage[] }) => {
//     const pages = data.map(item => item.pageCode)
//     const [open, setOpen] = useState(false)
//     const [selections, setSelections] = useState<string[]>([])
//     const resources = useApplicationSelector(state => state.auth.accessResources)
//     const addPages = useAddFavouritePageList()
//     const removePages = useRemoveFavouritePageList()
//     const intl = useIntl()
//     const dispatch = useDispatch()
//     const callback = useCallback(() => {
//         const pagesNeedToCreate = selections.filter(selection => !pages.includes(selection))
//         const pagesNeedToRemove = pages.filter(page => !selections.includes(page))
//         const removePageList = pagesNeedToRemove.map(page => {
//             const pageInfo = pageInfoList.filter(item => item.pageCode === page)
//             return { pageCode: page, resourceCode: pageInfo.length > 0 ? pageInfo[0].access : "" }
//         })
//         const createPageList = pagesNeedToCreate.map(page => {
//             const pageInfo = pageInfoList.filter(item => item.pageCode === page)
//             return { pageCode: page, resourceCode: pageInfo.length > 0 ? pageInfo[0].access : "" }
//         })
// 
//         if (pagesNeedToRemove.length > 0 && pagesNeedToCreate.length > 0) {
//             //remove
//             removePages(removePageList).then(() => {
//                 //then create
//                 addPages(createPageList).then(() => {
//                     setOpen(false)
//                     dispatch(applicationActions.loadFavouritePages())
//                 })
//             })
//         }
//         //remove
//         if (pagesNeedToRemove.length > 0 && pagesNeedToCreate.length === 0) {
//             removePages(removePageList).then(() => {
//                 setOpen(false)
//                 dispatch(applicationActions.loadFavouritePages())
//             })
//         }
//         //create
//         if (pagesNeedToCreate.length > 0 && pagesNeedToRemove.length === 0) {
//             addPages(createPageList).then(() => {
//                 setOpen(false)
//                 dispatch(applicationActions.loadFavouritePages())
//             })
//         }
//         //no change
//         if (pagesNeedToCreate.length === 0 && pagesNeedToRemove.length === 0) {
//             setOpen(false)
//         }
//     }, [addPages, dispatch, pages, removePages, selections])
// 
//     const columns = useMemo(() => [
//         { field: 'category', dataTypeName: 'string', title: intl.formatMessage({ id: 'category' }), width: 150 },
//         { field: 'pageCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'pageCode' }), width: 200 },
//         { field: 'pageName', dataTypeName: 'string', title: intl.formatMessage({ id: 'pageName' }), width: 400 },
//     ], [intl])
//     const getRowId = useCallback((row: any) => row.pageCode, [])
//     const displayData = pageInfoList.filter(page => page.access === '' || resources?.includes(page.access))
// 
//     return <>
//         < EditCallbackToolbarAction title={< FormattedMessage id="edit" />} callback={() => {
//             setSelections(pages)
//             setOpen(true)
//         }} />
//         <Dialog open={open} style={{ overflow: 'hidden' }} fullWidth maxWidth={'md'}>
//             <DialogHeader onClose={() => setOpen(false)}><FormattedMessage id="editFavouritePages" /></DialogHeader>
//             <DialogContent style={{ overflow: 'hidden' }}>
//                 <div style={{ width: '100%', height: 500, display: 'flex' }}>
//                     <div style={{ flex: '1 1 auto', overflow: 'hidden' }}>
//                         <DataGrid>
//                             <ToolbarLayout />
//                             <TableLayout Container={FlexScrollbar}>
//                                 <TableHeaderLayout sticky />
//                                 <TableBodyLayout />
//                             </TableLayout>
//                             <DataTypePreset />
//                             <Data rows={displayData} columns={columns} getRowId={getRowId} />
//                             <ColumnFreeze />
//                             <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
//                             <Sorting />
//                             <Filtering />
//                             <Searching ignoreCase Input={SearchInput} />
//                             <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
//                         </DataGrid>
//                     </div>
//                 </div>
//             </DialogContent>
//             <DialogActions>
//                 <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={() => setOpen(false)} />
//                 <DialogAction title={<FormattedMessage id="confirm" />} callback={callback} />
//             </DialogActions>
//         </Dialog>
//     </>
// }

const useStyles = makeStyles(theme => ({
    frequentUsedIcon: {
        height: '100%',
        cursor: 'pointer',
        borderRadius: 5,
        '&:hover': {
            backgroundColor: theme.palette.background.search.hover,
        },
        margin: 5
    },
    favoutiteIcon: {
        height: '100%',
        cursor: 'pointer',
        borderRadius: 5,
        margin: 5
    },
    hoverFavoutiteIcon: {
        height: '100%',
        cursor: 'pointer',
        borderRadius: 5,
        backgroundColor: theme.palette.background.search.hover,
        margin: 5
    },
    addIcon: {
        height: '100%',
        cursor: 'pointer',
        borderRadius: 5,
        '&:hover': {
            backgroundColor: theme.palette.background.search.hover,
        },
        margin: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    closeIcon: {
        '&:hover': {
            backgroundColor: 'red',
            opacity: 0.5,
            color: 'white',
        },
        cursor: 'pointer',
        color: theme.palette.primary.main,
    },
    closeIconDiv: {
        height: 0,
        zIndex: 999,
        textAlign: 'right',
        marginRight: 10,
        position: 'relative',
        top: '15%'
    },
    iconGrid: {
        marginBottom: 10,
        position: 'relative',
        bottom: '11%'
    }
}))




