import { makeStyles, Typography } from "@material-ui/core"
import { NumberTypeProvider } from "@rithe/data-grid"
import { NumberFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/NumberFormatter"
import clsx from 'clsx'
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { OrderCalcStatus } from "../../../services/order/enums/OrderCalcStatus"
import { useGetCodeName } from "../../../utils/CodeCategoryUtil"

export interface StatusTypeProviderProps {
  name: string
}

export function StatusTypeProvider(props: StatusTypeProviderProps) {
  const { name } = props
  return <NumberTypeProvider name={name} Formatter={Formatter} />
}

function Formatter(props: NumberFormatterProps) {
  const { row } = props
  const { getCodeName } = useGetCodeName()
  const codeName = getCodeName(CodeCategory.OrderCalcStatus, row.status ?? undefined)

  const styles = useStyles()
  return <div className={clsx(styles.root, row.status ? styles[row.status] : styles.defaultStatus)}>
    <Typography variant="body2">
      {codeName}
    </Typography>
  </div>
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '70%',
    paddingRight: theme.spacing(1),
    borderRadius: 5,
    color: 'white',
  },
  [OrderCalcStatus.PENDING_CALCULATION]: {
    backgroundColor: '#FF3300',
  },
  [OrderCalcStatus.PENDING_ORDER]: {
    backgroundColor: '#ED7D31',
  },
  [OrderCalcStatus.PENDING_ISSUED]: {
    backgroundColor: '#D94C00',
  },
  [OrderCalcStatus.ORDER_ISSUED]: {
    backgroundColor: '#00CCAD',
  },
  defaultStatus: {
    backgroundColor: '#C0C0C0',
  },
}))