import { Selection } from "@rithe/data-grid"
import { useDispatch } from "react-redux"
import { occls010Actions, useOCCLS010Selector } from "./OCCLS010Slice"

export function ListTableSelection() {
  const selections = useOCCLS010Selector(state => state.selections)
  const dispatch = useDispatch()
  return <Selection
    showSelectAll
    highlightSelectedRow
    selectedRowIds={selections}
    onSelectedRowIdsChange={(selections: string[]) => dispatch(occls010Actions.setSelections(selections))}
  />
}