import { Project } from "../../../utils/Project"

export type AlarmUsagePatternDetail = Required & Partial<Optional>

type AlarmUsagePatternDetailJson = JsonRequired & Partial<JsonOptional>

export const alarmUsagePatternDetailToJson: Project<AlarmUsagePatternDetail, AlarmUsagePatternDetailJson> = obj => ({
    ...obj,
})

export const alarmUsagePattemDetailFromJson: Project<AlarmUsagePatternDetailJson, AlarmUsagePatternDetail> = json => ({
    customerPart: odmCustomerPartsFromJson(json.customerPart ?? {}),
    customerPartControl: odmCustomerPartsControlFromJson(json.customerPartControl ?? {}),
    customerPartOrder: odmCustomerPartsOrderFromJson(json.customerPartOrder ?? {}),
})

interface Required extends JsonRequired {

}

interface Optional {
    customerPart: OdmCustomerParts,
    customerPartControl: OdmCustomerPartsControl,
    customerPartOrder: OdmCustomerPartsOrder,
}

interface JsonRequired {

}

interface JsonOptional {
    customerPart: OdmCustomerPartsJson,
    customerPartControl: OdmCustomerPartsControlJson,
    customerPartOrder: OdmCustomerPartsOrderJson,
}

export type OdmCustomerParts = CustomerPartsRequired & Partial<CustomerPartsOptional>
type OdmCustomerPartsJson = CustomerPartsJsonRequired & Partial<CustomerPartsJsonOptional>

const odmCustomerPartsFromJson: Project<OdmCustomerPartsJson, OdmCustomerParts> = json => ({
    ...json,
})
interface CustomerPartsRequired extends CustomerPartsJsonRequired {

}
interface CustomerPartsOptional extends CustomerPartsJsonOptional {

}
interface CustomerPartsJsonRequired {

}
interface CustomerPartsJsonOptional {
    customerPartsId: number,
    partsId: number,
    partsNo: string,
    dataSource: number,
    externalPartsKey: string,
    externalRefNo: string,
    externalCustomerCode: string,
    commonPartsNo: string,
    customerId: number,
    customerPartsNo: string,
    customerPartsName: string,
    customerBackNo: string,
    hscode: string,
    pairedFlag: number,
    pairedPartsId: number,
    pairedOrderFlag: number,
    activeFlag: number,
    partsRemark: string,
    createdBy: string,
    createdDate: string,
    updatedBy: string,
    updatedDate: string,
    version: number,
    model: string,

    /** Customer */
    customerCode: string,
    customerName: string,
    regionCode: string,
    timeZone: string,

    sellerCode: string,
    ownerType: number,
    supplierId: number,

}


export type OdmCustomerPartsControl = CustomerPartsControlRequired & Partial<CustomerPartsControlOptional>
type OdmCustomerPartsControlJson = CustomerPartsControlJsonRequired & Partial<CustomerPartsControlJsonOptional>

const odmCustomerPartsControlFromJson: Project<OdmCustomerPartsControlJson, OdmCustomerPartsControl> = json => ({
    ...json,
})
interface CustomerPartsControlRequired extends CustomerPartsControlJsonRequired {

}
interface CustomerPartsControlOptional extends CustomerPartsControlJsonOptional {

}
interface CustomerPartsControlJsonRequired {

}
interface CustomerPartsControlJsonOptional {
    partControlId: number,
    customerPartsId: number,
    orderLot: number,
    spq: number,
    custStockFlag: number,
    inventoryBoxFlag: number,
    minDays1: number,
    minDays2: number,
    minDays3: number,
    maxDays: number,
    minBoxes1: number,
    minBoxes2: number,
    minBoxes3: number,
    maxBoxes: number,
    outboundFluctuation: number,
    alarmEndLeadtime: number,
    alarmEndUom: number,
    alarmEndDatePat: number,

    /** Usage Pattern */
    deliveryCustPat: string,
    custDelayPat: number,
    custAdvancePat: number,

    custWhsId: number,
    etdDelayPat: number,
    etdAdvancePat: number,
    onshippingDelayPat: number,
    createdBy: string,
    createdDate: string,
    updatedBy: string,
    updatedDate: string,
    version: number,
    ltEtaBySea: number,
    ltEtaByTruck: number,
    ltEtaByAir: number,
    alertType: number,
}


export type OdmCustomerPartsOrder = CustomerPartsOrderRequired & Partial<CustomerPartsOrderOptional>
type OdmCustomerPartsOrderJson = CustomerPartsOrderJsonRequired & Partial<CustomerPartsOrderJsonOptional>

const odmCustomerPartsOrderFromJson: Project<OdmCustomerPartsOrderJson, OdmCustomerPartsOrder> = json => ({
    ...json,
})
interface CustomerPartsOrderRequired extends CustomerPartsOrderJsonRequired {

}
interface CustomerPartsOrderOptional extends CustomerPartsOrderJsonOptional {

}
interface CustomerPartsOrderJsonRequired {

}
interface CustomerPartsOrderJsonOptional {
    partsOrderId: number,
    customerPartsId: number,
    orderGroupId: number,
    safetyStockUom: number,
    safetyStock: number,
    maxStock: number,
    calculationPattern: number,
    createdBy: string,
    createdDate: string,
    updatedBy: string,
    updatedDate: string,
    version: number,
    minStock: number,
    orderGroupNo: string,
}