import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useComMasterGetAllCurrency, useComMasterGetAllUom } from "../../../services/common/apis/commonMasterApi"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { useGetRequestToList } from "../../../services/master/apis/requestApi"
import { useGetRequest } from "../../../services/master/apis/requestDetailApi"
import { ActiveFlag } from "../../../services/master/enums/ActiveFlag"
import { ContractRequestType } from "../../../services/master/enums/ContaractRequestType"
import { PartsDetailResult } from "../../../services/master/models/PartsDetailResult"
import { RequestHeaderResult } from "../../../services/master/models/RequestHeaderResult"
import { RequestToResult } from "../../../services/master/models/RequestToResult"
import { TnmCurrency } from "../../../services/master/models/TnmCurrency"
import { TnmUom } from "../../../services/master/models/TnmUom"
import { useMatch } from "../../../utils/useMatch"
import { MOS021PcUi } from "./MOS021PcUi"
import { Message } from "@rithe/form"


export const MOS021 = () => {
    const { requestNo } = useParams() as any
    const { path } = useMatch()

    // if create mode, check request type
    const mode = path === '/requestTo/view-:requestNo' ? ScreenMode.VIEW : path === '/requestTo/edit-:requestNo' ? ScreenMode.EDIT : ScreenMode.CREATE

    const [type, setType] = useState<number | undefined>()
    const [requestToList, setRequestToList] = useState<RequestToResult[]>([])
    const [headerInfo, setHeaderInfo] = useState<RequestHeaderResult>({} as RequestHeaderResult)
    const [partsList, setPartsList] = useState<PartsDetailResult[]>([])
    const [uomList, setUom] = useState<TnmUom[]>([])
    const [currencyList, setCurrencyList] = useState<TnmCurrency[]>([] as TnmCurrency[])
    const [messages, setMessages] = useState<Message[]>([])
    const getRequestToList = useGetRequestToList()
    const getRequest = useGetRequest()
    const getUomList = useComMasterGetAllUom()
    const getCurrencyList = useComMasterGetAllCurrency()
    const search = useCallback((requestNo: string) => {
        if (requestNo !== undefined) {
            getRequest({ requestNo: requestNo }, { serialized: true, silent: true }).then(result => {
                setType(result.requestHeader?.requestType)
                setHeaderInfo(result.requestHeader ?? {} as RequestHeaderResult)
                setPartsList(result.requestPartsList ?? [])
            })
        }
    }, [getRequest])

    useEffect(() => {
        if (path === '/requestTo/editPacking') {
            setType(ContractRequestType.MODIFY_PACKING)
        } else if (path === '/requestTo/editPrice') {
            setType(ContractRequestType.MODIFY_PRICE)
        } else if (path === '/requestTo/remove') {
            setType(ContractRequestType.REMOVE)
        } else if (path === '/requestTo/addNewPart') {
            setType(ContractRequestType.ADD)
        } else {
            setType(undefined)
        }
        setHeaderInfo({} as RequestHeaderResult)
        setPartsList([])
    }, [path])

    useEffect(() => {
        getUomList(undefined, { silent: true }).then(result => {
            setUom(result || [])
        })
        getCurrencyList(undefined, { silent: true, serialized: true }).then(result => {
            setCurrencyList(result || [])
        })
        search(requestNo)
    }, [search, requestNo, getUomList, getCurrencyList])

    useEffect(() => {
        if (type !== undefined) {
            getRequestToList({ requestType: type }, { silent: true }).then(result => {
                setRequestToList(result.filter(obj => obj.activeFlag === ActiveFlag.ACTIVE))
            })
        }
    }, [getRequestToList, type])

    return <MOS021PcUi
        requestType={type}
        mode={mode}
        search={search}
        requestNo={requestNo}
        requestToList={requestToList}
        headerInfo={headerInfo}
        setHeaderInfo={setHeaderInfo}
        partsList={partsList}
        setPartsList={setPartsList}
        uomList={uomList}
        currencyList={currencyList}
        messages={messages}
        setMessages={setMessages}
    />
}
