import React, { useCallback, useMemo, useState } from "react"
import { useGetSoListByPage } from "../../../services/order/apis/SalesOrderApi"
import { OrderListResult } from "../../../services/order/models/OrderListResult"
import { SalesOrderListFactor, salesOrderListFactorFromJson, salesOrderListFactorToJson } from "../../../services/order/models/SalesOrderListFactor"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { OSS010PcUi } from "./OSS010PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }
const searchCachekey = sessionKeys.Filters_OSS010

export const OSS010 = () => {
    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? salesOrderListFactorFromJson(JSON.parse(filters)) : null
    }, [])

    const [filters, setFilters] = useState<SalesOrderListFactor>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<OrderListResult[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)

    const getData = useGetSoListByPage()
    const search = useCallback((filters: SalesOrderListFactor) => {
        getData(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(salesOrderListFactorToJson(filters)))
        })
    }, [getData, setData])

    useSearchOnLoad(search, filters)

    return <OSS010PcUi
        filters={filters}
        setFilters={setFilters}
        search={search}
        data={data}
        totalCount={totalCount}
    />
}