import React, { useCallback, useState } from "react"
import { useGetRePCWCDataByPage } from "../../../services/order/apis/ChangeRequestApi"
import { ReceivedPlaceChangView } from "../../../services/order/models/ReceivedPlaceChangView"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { OCS027PcUi } from "./OCS027PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }

export const OCS027 = () => {


    const [filters, setFilters] = useState<ReceivedPlaceChangView>({ ...defaultFilters })
    const [data, setData] = useState<ReceivedPlaceChangView[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const getData = useGetRePCWCDataByPage()



    const search = useCallback((filters: ReceivedPlaceChangView) => {
        getData(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
        })
    }, [getData])

    useSearchOnLoad(search, filters)

    return <OCS027PcUi
        filters={filters}
        setFilters={setFilters}
        data={data}
        search={search}
        totalCount={totalCount}
    />
}