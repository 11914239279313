import { Card, CardContent, Grid, makeStyles, TextField, Typography, useTheme } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { ColumnFreeze, ColumnOrdering, ColumnResizing, Data, DataGrid, DataTypePreset, Filtering, ObjectTypeProvider, Row, Searching, Sorting, StringTypeProvider, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid";
import { ObjectFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/ObjectFormatter";
import { YearMonthItem } from "@rithe/form";
import { Arrays, Records } from "@rithe/utils";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import Widgets from "fusioncharts/fusioncharts.widgets";
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import moment from "moment";
import { default as React, memo, useCallback, useEffect, useMemo, useState } from "react";
import ReactFusioncharts from 'react-fusioncharts';
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { SimpleCard } from "../../../components/Card/SimpleCard";
import { SimpleCardContent } from "../../../components/Card/SimpleCardContent";
import { SimpleCardHeader } from "../../../components/Card/SimpleCardHeader";
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar";
import { SearchInput } from "../../../components/DataGrid/components/SearchInput";
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem";
import { Scrollbar } from "../../../components/Scrollbar";
import { SectionTitle } from "../../../components/SectionTitle/SectionTitle";
import { VerdorProfileFactor } from "../../../services/dashboard/models/VendorDasboardFactor";
import { VerdorProfileResult } from "../../../services/dashboard/models/VendorDasboardResult";
import { DataCard } from "../Card/DataCard";

export interface DAS014PcUiProps {
    filters: VerdorProfileFactor,
    setFilters: React.Dispatch<React.SetStateAction<VerdorProfileFactor>>,
    venders: VerdorProfileResult[],
    search: (filters: VerdorProfileFactor) => void,
}

interface SelectedCountryProps {
    id?: string,
    color?: string
}

export const DAS013PcUi = ({ filters, setFilters, venders, search }: DAS014PcUiProps) => {

    const intl = useIntl()
    const [countries, setCountries] = useState<SelectedCountryProps[]>([])

    ReactFusioncharts.fcRoot(FusionCharts, Charts, Widgets, FusionTheme);
    return (<SimpleCard>
        <SimpleCardHeader title={intl.formatMessage({ id: 'supplierStatistics' })} actions={[<FilterItem filters={filters} setFilters={setFilters} search={search} />]} />
        <SimpleCardContent>
            <OverViewCards venders={venders} />
            <CountrySuppliersView venders={venders} countries={countries} setCountries={setCountries} />
            <SectionTitle size='large' ><FormattedMessage id='completedVenders' /></SectionTitle>
            <CompletedVendersView venders={venders} countries={countries} />
        </SimpleCardContent>
    </SimpleCard >);
}


const FilterItem = (props: {
    filters: VerdorProfileFactor,
    setFilters: React.Dispatch<React.SetStateAction<VerdorProfileFactor>>,
    search: (filters: VerdorProfileFactor) => void
}) => {
    const { filters, setFilters, search } = props
    const clear = useCallback((filters: VerdorProfileFactor) => {
        return {}
    }, [])
    const filterCounter = useCallback((filters: VerdorProfileFactor) => {
        return [
            filters.suppliers,
            filters.regions,
            filters.startMonth,
            filters.endMonth
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <YearMonthItem field="startMonth" label={intl.formatMessage({ id: 'startMonth' })} />
        <YearMonthItem field="endMonth" label={intl.formatMessage({ id: 'endMonth' })} />
    </FilterToolbarItem>
}

const OverViewCards = ({ venders }: { venders: VerdorProfileResult[] }) => {
    const intl = useIntl();
    const styles = useStyles()
    const totalDelivery = useMemo(() => {

        const totalCompletedDelivery = venders.map(m => m.completedCount).reduce((a, b) => a + b, 0)
        const totalOntimeDelivery = venders.map(m => m.onTimeCount).reduce((a, b) => a + b, 0)
        const totalActualDays = venders.flatMap(f => f.deliveries).map(m => moment(m.drDate).diff(moment(m.actualDrDate), 'days')).reduce((a, b) => a + b, 0)

        const averageDuration = venders.flatMap(f => f.durations).map(m => moment(m.minDrDate).diff(moment(m.soDate), 'days')).reduce((a, b) => a + b, 0)
        const totalAverageDurationcount = venders.flatMap(m => m.durations).length
        return {
            totalCompletedDelivery: totalCompletedDelivery,
            averageOnTime: totalCompletedDelivery === 0 ? 0 : Number((totalOntimeDelivery * 100 / totalCompletedDelivery).toFixed(1)),
            averageActualDays: totalCompletedDelivery === 0 ? 0 : Number((totalActualDays / totalCompletedDelivery).toFixed(1)),
            averageDuration: totalAverageDurationcount === 0 ? 0 : Number((averageDuration / totalAverageDurationcount).toFixed(1)),
        }
    }, [venders])

    return <Scrollbar flexbox>
        <div className={styles.viewcard}>
            <DataCard subContents={intl.formatMessage({ id: 'completedVenders' })} numberData={totalDelivery.totalCompletedDelivery} />
            <DataCard subContents={intl.formatMessage({ id: 'onTimeVenders' })} percentageData={totalDelivery.averageOnTime} />
            <DataCard subContents={intl.formatMessage({ id: 'actualDeliveryDuration' })} numberData={totalDelivery.averageDuration} />
            <DataCard subContents={intl.formatMessage({ id: 'actualVsExpected' })} numberData={totalDelivery.averageActualDays} />
        </div>
    </Scrollbar>
}

export const CountrySuppliersView = ({ venders, countries, setCountries }: {
    venders: VerdorProfileResult[],
    countries: SelectedCountryProps[],
    setCountries: React.Dispatch<React.SetStateAction<SelectedCountryProps[]>>
}) => {

    // use
    const intl = useIntl()
    const styles = useStyles()
    const currencys = useMemo(() => Arrays.distinct(venders.flatMap(f => f.purchaseAmounts).map(m => m.currency)), [venders])
    const [currency, setCurrency] = useState<string | null>(null)
    const supplierAmounts = useMemo(() => {
        return venders.filter(f => !countries || countries.length === 0 || countries.some(s => s.id === f.regionCode))
            .map(m => {
                const amount = m.purchaseAmounts && m.purchaseAmounts.length > 0 ? m.purchaseAmounts.find(k => currency === k.currency)?.amount : undefined
                return {
                    supplierCode: m.supplierCode,
                    supplierName: m.supplierName,
                    amount: amount !== undefined ? intl.formatNumber(amount, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : "-",
                } as SupplierAmount
            });
    }, [countries, currency, intl, venders])

    useEffect(() => {
        if (currency == null && currencys?.length > 0) {
            setCurrency(currencys[0])
        }
    }, [currency, currencys])

    // prepare all data
    const regions = useMemo(() => Arrays.distinct(venders.map(m => m.regionCode)), [venders])
    return (
        <Grid container direction="row" justifyContent="flex-start" spacing={2}>
            <Grid item xs={6}>
                <Card style={{ marginTop: 10, marginBottom: 15 }}>
                    <CardContent>
                        <div className={styles.cardroot}>
                            <div className={styles.cardcontent}>
                                <Typography variant="h3" color="textPrimary" className={styles.cardtitle}><FormattedMessage id='numberofSupplierByCountry' /></Typography>
                            </div>
                        </div>
                        <PieChartView countrys={regions} venders={venders} setCountries={setCountries} />
                    </CardContent>
                </Card >
            </Grid>
            <Grid item xs={6}>
                <div className={styles.cardroot} style={{ paddingTop: 42 }}>
                    <div className={styles.cardcontent}>
                        <Typography variant="h3" color="textPrimary" className={styles.cardtitle}><FormattedMessage id='supplierByCountry' /></Typography>
                    </div>
                    <div className={styles.actions} style={{ display: 'flex', alignItems: 'center', width: '80px' }}>
                        <AutocompleteItem value={currency} options={currencys} setValue={setCurrency} />
                    </div>
                </div>
                <div style={{ height: 240 }}>
                    <SupplierAmountTable data={supplierAmounts} currency={currency} />
                </div>
            </Grid>
        </Grid >
    )
}

interface SupplierAmount {
    supplierCode: string,
    supplierName: string,
    amount: string
}

const SupplierAmountTable = ({ currency, data }: { currency: string | null, data: SupplierAmount[] }) => {
    const intl = useIntl();
    const columns = useMemo(() => [
        { field: 'supplierName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierName' }), width: 240 },
        { field: 'amount', dataTypeName: 'amount', title: intl.formatMessage({ id: 'field.amount' }) + "(" + (currency ?? "") + ")", width: 180 },
    ], [currency, intl])
    return <DataGrid>
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <DataTypePreset />
        <StringTypeProvider name="amount" align="end" />
        <Sorting />
        <Filtering />
        <Data rows={data} columns={columns} />
    </DataGrid>
}

export const AutocompleteItem = ({ value, options, setValue }: {
    value: any | null;
    options: any[];
    setValue: (value: any | null) => void;
}) => {
    const theme = useTheme()
    return <div
        style={{ width: '100%' }}
        onClick={e => e.stopPropagation()}
    >
        <Autocomplete
            autoHighlight
            autoComplete
            selectOnFocus
            handleHomeEndKeys
            disableClearable
            fullWidth
            value={value}
            options={options}
            getOptionLabel={option => option}
            onChange={(_, newValue) => setValue(newValue)}
            renderInput={params => <TextField style={{ backgroundColor: '#ffffff', borderRadius: theme.spacing(1) }} {...params} InputProps={{ ...params.InputProps, disableUnderline: false }} inputProps={{ ...params.inputProps, style: { textAlign: 'center', fontSize: theme.typography.body1.fontSize, fontWeight: 600 } }} />}
            renderOption={(option, { inputValue }) => {
                const matches = match(option, inputValue)
                const parts = parse(option, matches)
                return <div>
                    {parts.map((part, index) => (
                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                            {part.text}
                        </span>
                    ))}
                </div>
            }}
        />
    </div>
}


export const PieChartView = memo(({ countrys, venders, setCountries }: {
    countrys: string[],
    venders: VerdorProfileResult[],
    setCountries: React.Dispatch<React.SetStateAction<SelectedCountryProps[]>>
}) => {

    const chartData = useMemo(() => countrys.map(m => { return { label: m, value: venders.filter(f => f.regionCode === m).length, regionCode: m } }), [countrys, venders]);
    const dataSource = useMemo(() => ({
        chart: {
            theme: "fusion",
            placeValuesInside: 1,
            divLineAlpha: 0,
            showYAxisValues: 0,
            showXAxisLine: 0,
            baseFontSize: 14,
            plottooltext: "$label : $value Suppliers",
            showLegend: 1,
            legendPosition: "right",
            legendCaption: "Country",
            showPercentValues: 0,
            showLabels: 0,
            showValues: 0,
            showPercentInToolTip: 0,
        },
        data: chartData
    }), [chartData]);

    const chartConfigs = useMemo(() => ({
        type: 'pie2d',
        width: '100%',
        height: 240,
        dataFormat: 'json',
        dataSource: dataSource,
        events: {
            'dataplotClick': (eventObj: any, dataObj: any) => {
                setCountries(countries => {
                    const ifSelectRegion = countries.some(m => m.id === dataObj.categoryLabel);
                    return ifSelectRegion ? countries.filter(f => f.id !== dataObj.categoryLabel) : [...countries, { id: dataObj.categoryLabel, color: dataObj.color }];
                })
            },
            'legendItemClicked': (eventObj: any, dataObj: any) => {
                setCountries(countries => {
                    const ifSelectRegion = countries.some(m => m.id === dataObj.label);
                    return ifSelectRegion ? countries.filter(f => f.id !== dataObj.label) : [...countries, { id: dataObj.label, color: dataObj.color }];
                })
            }
        }
    }), [dataSource, setCountries]);

    return (<ReactFusioncharts {...chartConfigs} />);
})

export const CompletedVendersView = ({ venders, countries }: { venders: VerdorProfileResult[], countries: SelectedCountryProps[] }) => {
    const intl = useIntl();

    // find all max data
    const calMax = useCallback((invoices: VerdorProfileResult[], mapFunc: (map: VerdorProfileResult) => number) => {
        const values = invoices.map(mapFunc).sort()
        return values && values.length > 0 ? values[values.length - 1] : 0
    }, [])
    const maxCompletedVenders = useMemo(() => calMax(venders, (m) => m.completedCount), [calMax, venders])
    const maxOnTimeVenders = useMemo(() => calMax(venders, (m) => Number(m.completedCount === 0 ? 0 : (m.onTimeCount * 100 / m.completedCount).toFixed(1))), [calMax, venders])
    const maxActualDeliveryDuration = useMemo(() => calMax(venders, (m) => m.avgDuration), [calMax, venders])
    const maxActualVsExpectedDays = useMemo(() => calMax(venders, (m) => m.avgActualExpectedDays), [calMax, venders])

    const columns = useMemo(() => [
        { field: 'supplierName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierName' }), width: 260 },
        { field: 'regionCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'country' }), width: 140, },
        { field: 'completedCount', dataTypeName: 'bar', title: intl.formatMessage({ id: 'completedVenders' }), width: 220, getCellValue: (row: Row) => ({ qty: row.completedCount, maxQty: maxCompletedVenders, minDigits: 0, maxDigits: 0 }) },
        { field: 'onTimeCount', dataTypeName: 'bar', title: intl.formatMessage({ id: 'onTimeVenders' }), width: 220, getCellValue: (row: Row) => ({ qty: Number(row.completedCount === 0 ? 0 : (row.onTimeCount * 100 / row.completedCount).toFixed(1)), maxQty: maxOnTimeVenders, minDigits: 0, maxDigits: 1, isPercent: 1 }) },
        { field: 'avgDuration', dataTypeName: 'bar', title: intl.formatMessage({ id: 'actualDeliveryDuration' }), width: 220, getCellValue: (row: Row) => ({ qty: row.avgDuration, maxQty: maxActualDeliveryDuration, minDigits: 0, maxDigits: 1 }) },
        { field: 'avgActualExpectedDays', dataTypeName: 'bar', title: intl.formatMessage({ id: 'actualVsExpected' }), width: 240, getCellValue: (row: Row) => ({ qty: row.avgActualExpectedDays, maxQty: maxActualVsExpectedDays, minDigits: 0, maxDigits: 1 }) },
    ], [intl, maxActualDeliveryDuration, maxActualVsExpectedDays, maxCompletedVenders, maxOnTimeVenders]);

    // display value
    const displayList = countries && countries.length > 0 ? venders.filter(f => countries.some(e => e.id === f.regionCode)) : venders

    return <div style={{ width: '100%', paddingTop: 10, height: 460 }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <DataTypePreset />
            <BarTypeProvider />
            <Data rows={displayList} columns={columns} />
            <ColumnFreeze freeze={{ supplierName: 'start', regionCode: 'start' }} />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
        </DataGrid>
    </div>
}

const BarFormatter = ({ value }: ObjectFormatterProps) => {
    return value ? <UseProgress {...value} /> : null
}

const BarTypeProvider = () => {
    return <ObjectTypeProvider name="bar" Formatter={BarFormatter} />
}

const UseProgress = ({ qty, maxQty, minDigits, maxDigits, isPercent }: { qty: number, maxQty: number, minDigits: 0, maxDigits: 3, isPercent: number | undefined }) => {

    const width: number = maxQty === 0 ? 1 : (qty / maxQty) * 95;
    const theme = useTheme()
    const styles: React.CSSProperties = {
        height: "35px",
        width: width + "%",
        display: "flex",
        overflow: "hidden",
        alignItems: 'center',
        // border: `1px solid ${props.color}`,
        background: width > 0.5 ? '#1aaf5d' : '#818583',
        backgroundSize: "5px 5px",
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        // paddingLeft: 8,
        float: 'left',
        position: 'absolute',
        transition: `all ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`
    };

    if (isPercent === 1) {
        return <>
            <div style={{ width: '100%', float: 'left', position: 'absolute' }}><Typography variant="body2" style={{ paddingLeft: 8 }}>{qty + '%'}</Typography></div>
            <div style={styles} ><Typography variant="body2" style={{ paddingLeft: 8 }}>{qty + '%'}</Typography></div>
        </>
    } else {
        return <>
            <div style={{ width: '100%', float: 'left', position: 'absolute' }}><Typography variant="body2" style={{ paddingLeft: 8 }}><FormattedNumber value={qty} minimumFractionDigits={minDigits} maximumFractionDigits={maxDigits} /></Typography></div>
            <div style={styles} ><Typography variant="body2" style={{ paddingLeft: 8 }}><FormattedNumber value={qty} minimumFractionDigits={minDigits} maximumFractionDigits={maxDigits} /></Typography></div>
        </>
    }

};

const useStyles = makeStyles(theme => ({
    remark: {
        textAlign: "center",
        fontSize: "0.3rem!important",
    },
    fontSize: {
        fontSize: "0.8rem!important",
    },
    viewcard: {
        width: '100%',
        minWidth: 850,
        paddingBottom: theme.spacing(2),
        columnCount: 4,
        columnGap: theme.spacing(3),
        columnWidth: 180,
        columnFill: 'balance',
        boxSizing: 'border-box'
    },
    leftCard: {
        flex: '0 0 auto',
        width: '50%',
        marginLeft: 20,
        paddingTop: 30,
        '&>div': {
            marginTop: theme.spacing(2)
        }
    },
    cardroot: {
        padding: theme.spacing(2),
        flex: '0 0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'stretch',
    },
    cardcontent: {
        flex: '1 1 auto',
        overflow: 'hidden',
    },
    cardtitle: {
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    actions: {
        flex: '0 0 auto',
    },
    dot: {
        position: 'absolute',
        top: '50%',
        marginLeft: -5,
        marginTop: -5,
        width: 10,
        height: 10,
        borderRadius: '100%',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, .2)',
    }
}))
