import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { Projects } from "../../../utils/Projects"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { CCRequestDetailResult, cCRequestDetailResultFromJson, cCRequestDetailResultToJson } from "../models/CCRequestDetailResult"
import { CCRequestListFactor, cCRequestListFactorToJson } from "../models/CCRequestListFactor"
import { CCRequestListResult, cCRequestListResultFromJson } from "../models/CCRequestListResult"

export const useGetCcRequestListByPage = () => {
    const url = '/lcbm-order-api/order/ccr/api/getCcRequestListByPage'
    const payloadProject = useCallback(cCRequestListFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(cCRequestListResultFromJson), [])
    return usePost<CCRequestListFactor, PageResultEntity<CCRequestListResult>>(url, payloadProject, resultProject)
}

export const useGetCcSupplerList = () => {
    const url = '/lcbm-order-api/order/ccr/api/getCcSupplerList'
    const resultProject = useCallback(Projects.array((json: { supplierId: number, supplierCode: string }) => ({ ...json })), [])
    return usePost<undefined, { supplierId: number, supplierCode: string }[]>(url, Projects.IGNORE, resultProject)
}

export const useGetCcCustomerList = () => {
    const url = '/lcbm-order-api/order/ccr/api/getCcCustomerList'
    const resultProject = useCallback(Projects.array((json: { customerId: number, customerCode: string }) => ({ ...json })), [])
    return usePost<undefined, { customerId: number, customerCode: string }[]>(url, Projects.IGNORE, resultProject)
}

// export const useGetCcRequestFromList = () => {
//     const url = '/lcbm-order-api/order/ccr/api/getCcRequestFromList'
//     const resultProject = useCallback(Projects.array((json: { requestFromId: number, requestFromCode: string }) => ({ ...json })), [])
//     return usePost<undefined, { requestFromId: number, requestFromCode: string }[]>(url, Projects.IGNORE, resultProject)
// }

export const useRejectRequest = () => {
    const url = '/lcbm-order-api/order/ccr/api/reject'
    const payloadProject = useCallback((obj: { lineKeys: string[], rejectRemark?: string }) => ({ ...obj }), [])
    return usePost<{ lineKeys: string[], rejectRemark?: string }, undefined>(url, payloadProject, Projects.IGNORE)
}

export const useProposeNewConfirm = () => {
    const url = '/lcbm-order-api/order/ccr/api/proposeNewConfirm'
    const payloadProject = useCallback(cCRequestDetailResultToJson, [])
    const resultProject = useCallback(cCRequestDetailResultFromJson, [])
    return usePost<CCRequestDetailResult, CCRequestDetailResult>(url, payloadProject, resultProject)
}

export const useRefreshInboundInfo = () => {
    const url = '/lcbm-order-api/order/ccr/api/refreshInboundInfo'
    const payloadProject = useCallback(cCRequestDetailResultToJson, [])
    const resultProject = useCallback(cCRequestDetailResultFromJson, [])
    return usePost<CCRequestDetailResult, CCRequestDetailResult>(url, payloadProject, resultProject)
}

export const useApproveRequest = () => {
    const url = '/lcbm-order-api/order/ccr/api/approve'
    const payloadProject = useCallback((obj: { lineKeys: string[] }) => obj.lineKeys, [])
    return usePost<{ lineKeys: string[] }, undefined>(url, payloadProject, Projects.IGNORE)
}

export const useGetRoSoDetail = () => {
    const url = '/lcbm-order-api/order/ccr/api/getRoSoDetail'
    const payloadProject = useCallback((obj: { roSoId: number, dataFlag: number, soId: number }) => ({ ...obj }), [])
    const resultProject = useCallback(cCRequestDetailResultFromJson, [])
    return usePost<{ roSoId: number, dataFlag: number, soId: number }, CCRequestDetailResult>(url, payloadProject, resultProject)
}