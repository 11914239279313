import { Project } from "@rithe/utils"
import { Projects } from "../../../utils/Projects"
import { PageViewEntity, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"

export interface ShippingDetailFactor {
    bookingNo?: string,
    containerNo?: string,
    shippingMode?: number[],
    sellerUid?: string,
    loadingPortId?: string,
    dischargePortId?: string,
    atdStart?: Date,
    atdEnd?: Date,
    etaStart?: Date,
    etaEnd?: Date,
    cargoStatus?: string,
    page?: PageViewEntity,
    viaPortcastFlag?: number
}

interface ShippingDetailFactorJson {
    bookingNo?: string,
    containerNo?: string,
    shippingMode?: number[],
    sellerUid?: string,
    loadingPortId?: string,
    dischargePortId?: string,
    atdStart?: string,
    atdEnd?: string,
    etaStart?: string,
    etaEnd?: string,
    cargoStatus?: string,
    page?: PageViewEntityJson,
    viaPortcastFlag?: number
}

export const shippingDetailFactorToJson: Project<ShippingDetailFactor, ShippingDetailFactorJson> = obj => ({
    ...obj,
    atdStart: dateToJson(obj.atdStart),
    atdEnd: dateToJson(obj.atdEnd),
    etaStart: dateToJson(obj.etaStart),
    etaEnd: dateToJson(obj.etaEnd),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
})