import { makeStyles } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { DateInputProps, FormContext } from "@rithe/form";
import { useContext } from "react";

export function DateInput(props: DateInputProps) {
    const {
        field,
        value,
        setValue,
        placeholder,
        readonly,
        disabled,
        minDate,
        maxDate,
        disablePast,
        disableFuture,
    } = props

    const { dateInputPickerProps } = useContext(FormContext)
    const styles = useStyles()
    return <div className={styles.root}>
        <DatePicker
            disableToolbar
            autoOk
            variant="inline"
            InputProps={{ disableUnderline: true }}
            PopoverProps={{ PaperProps: { className: styles.paper } }}
            {...dateInputPickerProps}
            name={field}
            value={value ?? null}
            placeholder={placeholder}
            readOnly={readonly}
            disabled={disabled}
            onChange={e => setValue(e?.toDate() ?? null)}

            minDate={minDate}
            maxDate={maxDate}
            disablePast={disablePast}
            disableFuture={disableFuture}
        />
    </div>
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'stretch',
        '&>*:first-child': {
            flex: '1 1 auto',
        },
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
    },
}))