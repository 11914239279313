import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { tableEntityToJson } from "../../utils/TableEntity"


export type VisualizationReportView = Required & Partial<Optional>

type VisualizationReportViewJson = JsonRequired & Partial<JsonOptional>;;

export const VisualizationReportViewFromJson: Project<VisualizationReportViewJson, VisualizationReportView> = json => ({
    ...tableEntityToJson(json),
    stockDate: dateFromJson(json.stockDate)

})
interface Required {
    stockDate: Date,
    totalAlerts: number,
    newAlerts: number
}
interface Optional {
}

interface JsonRequired {
    stockDate: string,
    totalAlerts: number,
    newAlerts: number
}

interface JsonOptional {
}