import { Grid, makeStyles, Theme, Typography } from "@material-ui/core"
import { ColumnFreeze, ColumnOrdering, ColumnResizing, Data, DataGrid, DataTypePreset, Filtering, ObjectTypeProvider, Row, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { ObjectFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/ObjectFormatter"
import { GridContainer, GridItem } from "@rithe/ui"
import { Arrays, Comparator, Records } from "@rithe/utils"
import React, { useMemo, useState } from "react"
import { FormattedDate, FormattedMessage, useIntl } from "react-intl"
import { SimpleCard } from "../../../components/Card/SimpleCard"
import { SimpleCardContent } from "../../../components/Card/SimpleCardContent"
import { SimpleCardHeader } from "../../../components/Card/SimpleCardHeader"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { LinkTypeProvider } from "../../../components/DataGrid/typeProviders/LinkTypeProvider"
import { PopoverMenu } from "../../../components/PopoverMenu"
import { SectionTitle } from "../../../components/SectionTitle/SectionTitle"
import { DelayedPartsResult, OrderDelayedResult, OrderPicResult, SimulationParts } from "../../../services/dashboard/models/OrderDashboard"
import { OrderFactor } from "../../../services/dashboard/models/OrderDashboardFactor"
import { OrderDetail, OrderResult } from "../../../services/dashboard/models/OrderDashboardResult"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { SimulatedType } from "../../../services/order/enums/SimulatedType"
import { useGetCompanyType } from "../../../utils/ApplicationUtils"
import { DataCard } from "../Card/DataCard"

interface DAS010PcUiProps {
    search: (filters: OrderFactor) => void,
    data: OrderResult,
}

export const DAS010PcUi = (props: DAS010PcUiProps) => {
    const { data } = props
    const intl = useIntl()

    return <SimpleCard>
        <SimpleCardHeader title={intl.formatMessage({ id: 'orderStatistics' })} actions={[]} />
        <SimpleCardContent>
            <OverViewCards data={data} />
            <SectionTitle size='large' ><FormattedMessage id='inboundRecord' /></SectionTitle>
            <InboundRecordView data={data} />
            <LegendView />
            <OverViewOfPOView data={data} />
        </SimpleCardContent>
    </SimpleCard>
}

const OverViewCards = ({ data }: { data: OrderResult }) => {
    const intl = useIntl()
    const companyType = useGetCompanyType()
    return <GridContainer columnWidth={['1fr', '1fr', '1fr', '1fr']} columnGap={24} style={{ width: '100%', paddingBottom: 15 }}>
        <GridItem>
            <DataCard subContents={intl.formatMessage({ id: 'openPo' })} numberData={data.openCount ?? 0} suffix={"Orders"} />
        </GridItem>
        <GridItem style={{ width: '100%', overflow: 'hidden' }}>
            <DataCard subContents={intl.formatMessage({ id: 'lastOrder' })} stringData={data.lastOrderNo ?? 'No Data'} />
        </GridItem>
        <GridItem>
            {CbdsType.CUST === companyType ? <DataCard subContents={intl.formatMessage({ id: 'nextRegularOrder' })} stringData={data.nextRegularDate ? intl.formatDate(data.nextRegularDate, { dateStyle: 'medium' }) : ""} /> : undefined}
        </GridItem>
        <GridItem>
        </GridItem>
    </GridContainer>
}

const InboundRecordView = ({ data }: { data: OrderResult }) => {
    const intl = useIntl()
    const companyType = useGetCompanyType()
    const columns = useMemo(() => [
        { field: 'orderNo', dataTypeName: 'link', title: intl.formatMessage({ id: 'purchaseOrderNo' }), width: 274, getCellValue: (row: Row) => ({ href: CbdsType.BU === companyType ? `/po/detail-${row.orderId}` : `/co/detail-${row.orderId}`, text: row.orderNo }) },
        { field: 'status', dataTypeName: 'bar', title: intl.formatMessage({ id: 'field.status' }), width: 280, getCellValue: (row: Row) => ({ inboundedQty: row.inboundedQty, planOnTimeQty: row.planOnTimeQty, planDelayedQty: row.planDelayedQty, location: row.location }) },
        { field: 'orderDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'orderDate' }), width: 180 },
        { field: 'onTime', dataTypeName: 'onTime', title: intl.formatMessage({ id: 'onTime' }), width: 180 },
        { field: 'inboundDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'inboundPlan' }), width: 180 },
        // { field: 'latestInboundDate', dataTypeName: 'dateOnTime', title: intl.formatMessage({ id: 'latestInboundPlan' }), width: 224, getCellValue: (row: Row) => ({ onTime: row.onTime, date: row.latestInboundDate }) },
        { field: 'latestInboundDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'latestInboundPlan' }), width: 200 },
    ], [companyType, intl])

    const resultData = processInboundRecords(data.orderList ?? [])

    return <div style={{ width: '100%', height: 460, paddingTop: 15, }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <DataTypePreset />
            <LinkTypeProvider />
            <BarTypeProvider />
            <DateOnTimeTypeProvider />
            <OnTimeTypeProvider />
            <Data rows={resultData} columns={columns} />
            <ColumnFreeze freeze={{ orderNo: 'start' }} />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Sorting />
            <Filtering />
            <Searching ignoreCase Input={SearchInput} columnSettings={{ orderNo: { valueToString: (value) => value?.text ?? '' } }} />
        </DataGrid>
    </div>
}


const OverViewOfPOView = ({ data }: { data: OrderResult }) => {
    const intl = useIntl()
    const columns = useMemo(() => [
        { field: 'exp', dataTypeName: 'string', title: intl.formatMessage({ id: 'exp' }), width: 180 },
        { field: 'orderNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'purchaseOrderNo' }), width: 300 },
        { field: 'partsTotal', dataTypeName: 'number', title: intl.formatMessage({ id: 'partsTotal' }), width: 180 },
        { field: 'qty', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.qty' }), width: 180 },
    ], [intl])

    const resultData = processOverviewsRecords(data)

    return <div style={{ width: '100%', paddingTop: 20, height: 400, display: 'flex' }}>
        <div style={{ width: '300px', paddingTop: 10 }}>
            <Grid container direction="column" spacing={2} >
                <Grid item>
                    <DataCard subContents={intl.formatMessage({ id: 'openPo' })} numberData={resultData.openPoNum} />
                </Grid>
                <Grid item>
                    <DataCard subContents={intl.formatMessage({ id: 'delayParts' })} numberData={resultData.delayedPartsNum} color={'alarm'} />
                </Grid>
                <Grid item>
                    <DataCard subContents={intl.formatMessage({ id: 'delayQuantity' })} numberData={resultData.delayedQty} />
                </Grid>
            </Grid>
        </div>
        <div style={{ paddingLeft: 15, width: '100%' }}>
            <SectionTitle size='large' ><FormattedMessage id='overViewOfPurchaseOrder' /></SectionTitle>
            <DataGrid>
                <ToolbarLayout />
                <TableLayout Container={FlexScrollbar}>
                    <TableHeaderLayout sticky />
                    <TableBodyLayout />
                </TableLayout>
                <DataTypePreset />
                <Data rows={resultData.delayedPartsDetail} columns={columns} />
                <ColumnFreeze />
                <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
                <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
                <Sorting />
                <Filtering />
            </DataGrid>
        </div>
    </div>
}

const LegendView = () => {

    const intl = useIntl()
    return <div style={{ width: '100%', display: 'flex', marginTop: 15, backgroundColor: 'white', alignItems: 'center', height: 42, borderRadius: 5, paddingBottom: 15 }}>
        <Typography variant="body2" style={{ fontWeight: 'bold', marginLeft: 20 }} >{intl.formatMessage({ id: 'legend' })}</Typography>
        <Legend color='red' label={intl.formatMessage({ id: 'planDelayed' })} />
        <Legend color='green' label={intl.formatMessage({ id: 'planOnTime' })} />
        <Legend color='blue' label={intl.formatMessage({ id: 'inbound' })} />
    </div>
}

const Legend = ({ color, label }: { color: 'red' | 'green' | 'blue', label: string }) => {
    return <>
        <div style={{ backgroundColor: colors[color], marginLeft: 20, height: 10, width: 10 }}></div>
        <Typography variant="body2" color="textSecondary" style={{ marginLeft: 10 }}>{label}</Typography>
    </>
}

const colors = {
    red: "#CB4747",
    green: "#0CA78F",
    blue: "#00A6ED",
}

interface ProgressProps {
    progressData: { color: string, qty: number }[],
    location: SimulationParts[],
}

const ProportionView = (props: ProgressProps) => {

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const classes = useStyles()
    const totalQty = useMemo(() => props.progressData.map((m) => m.qty).reduce((a, b) => a + b, 0), [props])
    const progressData = useMemo(() => props.progressData.filter((m) => m.qty > 0), [props])
    const lastIndex = progressData.length - 1

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    return (
        <Grid aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            container direction="row" justifyContent="flex-start" spacing={0}>
            {progressData.map((obj, index) => (
                <UseProgress totalQty={totalQty} color={obj.color} qty={obj.qty} index={index} lastIndex={lastIndex} key={index} />
            ))}
            {open && <PopoverMenu
                className={classes.popover}
                classes={{ paper: classes.paper }}
                open={open}
                onClose={handlePopoverClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                disableRestoreFocus
            >
                <PartsTable result={props.location}></PartsTable>
            </PopoverMenu>
            }
        </Grid>
    )
}

const UseProgress = (props: { totalQty: number, color: string, qty: number, index: number, lastIndex: number }) => {

    if (props.totalQty === 0 || props.qty === 0) return <></>
    const width: number = (props.qty / props.totalQty) * 99
    const styles: React.CSSProperties = {
        height: "35px",
        width: width + "%",
        display: "flex",
        overflow: "hidden",
        border: `1px solid ${props.color}`,
        backgroundColor: `${props.color}`,
        backgroundSize: "5px 5px",
        borderTopLeftRadius: props.index === 0 ? 5 : 0,
        borderBottomLeftRadius: props.index === 0 ? 5 : 0,
        borderTopRightRadius: props.index === props.lastIndex ? 5 : 0,
        borderBottomRightRadius: props.index === props.lastIndex ? 5 : 0,
    }

    return <div style={styles} ></div>
}

const PartsTable = ({ result }: { result: SimulationParts[] }) => {
    const intl = useIntl()
    const columns = useMemo(() => [
        { field: "partsNo", dataTypeName: "string", title: intl.formatMessage({ id: 'field.partsNo' }), width: 180 },
        { field: "customer", dataTypeName: "string", title: intl.formatMessage({ id: 'customerCode' }), width: 150 },
        { field: "location", dataTypeName: "string", title: intl.formatMessage({ id: 'location' }), width: 300 },
        { field: "qty", dataTypeName: "number", title: intl.formatMessage({ id: 'field.qty' }), width: 120 },
    ], [intl])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <DataTypePreset />
        <Data rows={result} columns={columns} />
        <ColumnFreeze />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Sorting />
        <Filtering />
    </DataGrid>
}

const processInboundRecords = (orderData: OrderDetail[]) => {

    const orderList: OrderPicResult[] = []
    if (orderData && orderData.length > 0) {
        // ajust
        orderData.forEach(order => {
            // prepare by plan
            const partsList = order.partsList
            const planDates = Arrays.distinct(partsList.flatMap(m => m.planList).map(m => m?.crdDate.getTime()) ?? [])
            planDates.forEach(date => {
                const planPartsList: SimulationParts[] = []
                partsList.forEach(part => {
                    const plans = part.planList.filter(f => f.crdDate.getTime() === date)
                    plans.flatMap(p => p.simulationPlanList ?? []).map(simulation => planPartsList.push({
                        partsNo: part.unitPartsNo,
                        partsName: part.unitPartsName,
                        customer: part.customerCode,
                        location: simulation.simulatedType === SimulatedType.ON_STOCK ? simulation.fromCompany : simulation.fromCompany + " Ship To " + simulation.toCompany,
                        qty: simulation.simulatedQty
                    } as SimulationParts))
                })

                const plans = partsList.flatMap(p => p.planList).filter(f => f.crdDate.getTime() === date)
                const maxLatestCrdDate = Arrays.max(plans.filter(f => f.lastCrdDate !== undefined).map(m => m.lastCrdDate?.getTime() ?? 0), (a, b) => a > b ? 1 : a === b ? 0 : -1)
                const maxSimulateDate = Arrays.max(plans.flatMap(p => p.simulationPlanList ?? []).map(m => m.estimatedCrdDate.getTime()), (a, b) => a > b ? 1 : a === b ? 0 : -1)
                const latestInboundDate = (maxLatestCrdDate ?? 0) > (maxSimulateDate ?? 0) ? maxLatestCrdDate : maxSimulateDate
                const inboundedQty = plans.map(m => m.totalReceivedQty).reduce((a, b) => a + b, 0)
                const planOnTimeQty = plans.flatMap(p => p.simulationPlanList ?? []).filter(f => f.estimatedCrdDate.getTime() <= date).map(m => m.simulatedQty).reduce((a, b) => a + b, 0)
                const planDelayedQty = plans.flatMap(p => p.simulationPlanList ?? []).filter(f => f.estimatedCrdDate.getTime() > date).map(m => m.simulatedQty).reduce((a, b) => a + b, 0)

                // prepare each data
                orderList.push({
                    orderId: order.orderId,
                    orderNo: order.orderNo,
                    orderDate: order.orderDate,
                    inboundDate: new Date(date),
                    latestInboundDate: latestInboundDate === undefined ? null : new Date(latestInboundDate),
                    onTime: planDelayedQty === 0,
                    inboundedQty: inboundedQty,
                    planDelayedQty: planDelayedQty,
                    planOnTimeQty: planOnTimeQty,
                    location: planPartsList,
                })
            })
        })
    }

    const comparator: Comparator<OrderPicResult> = (a, b) => {
        const prev = "" + a.orderDate.getTime() + a.orderNo + a.inboundDate.getTime()
        const next = "" + b.orderDate.getTime() + b.orderNo + b.inboundDate.getTime()
        return prev > next ? 1 : prev === next ? 0 : -1
    }

    return Arrays.sort(orderList, comparator)
}

const processOverviewsRecords = (data: OrderResult) => {

    const delayedPartsDetail: DelayedPartsResult[] = []
    if (data.orderList && data.orderList.length > 0) {
        // ajust
        data.orderList.forEach(order => {
            const expCtrys = Arrays.distinct(order.partsList.map(m => m.expCtry))
            expCtrys.forEach(exp => {
                const filtersParts = order.partsList.filter(f => f.expCtry === exp)
                delayedPartsDetail.push({
                    exp: exp,
                    orderNo: order.orderNo,
                    partsTotal: filtersParts.filter(f => f.planList.flatMap(p => p.simulationPlanList ?? []).some(s => s.estimatedCrdDate.getTime() > s.crdDate.getTime())).length,
                    qty: filtersParts.flatMap(f => f.planList).flatMap(f => f.simulationPlanList ?? []).filter(s => s.estimatedCrdDate.getTime() > s.crdDate.getTime()).map(m => m.simulatedQty).reduce((a, b) => a + b, 0),
                })
            })
        })

        // calculate delayed parts number
        const delayedPartsNum = Arrays.distinct(data.orderList.flatMap(f => f.partsList).filter(f => f.planList.flatMap(p => p.simulationPlanList ?? []).some(s => s.estimatedCrdDate.getTime() > s.crdDate.getTime())).map(m => m.partsId)).length
        const delayedQty = delayedPartsDetail.map(m => m.qty).reduce((a, b) => (a ?? 0) + (b ?? 0), 0)

        const comparator: Comparator<DelayedPartsResult> = (a, b) => {
            const prev = (a.orderNo ?? '') + (a.exp ?? '')
            const next = (b.orderNo ?? '') + (b.exp ?? '')
            return prev > next ? 1 : prev === next ? 0 : -1
        }

        return {
            openPoNum: data.openCount ?? 0,
            delayedPartsNum: delayedPartsNum,
            delayedQty: delayedQty ?? 0,
            delayedPartsDetail: Arrays.sort(delayedPartsDetail, comparator),
        } as OrderDelayedResult
    }

    return {
        openPoNum: 0,
        delayedPartsNum: 0,
        delayedQty: 0,
        delayedPartsDetail: [],
    } as OrderDelayedResult
}

const BarFormatter = ({ value }: ObjectFormatterProps) => {
    return value ? <ProportionView progressData={[{ color: `${colors.blue}`, qty: value.inboundedQty }, { color: `${colors.green}`, qty: value.planOnTimeQty }, { color: `${colors.red}`, qty: value.planDelayedQty }]} location={value.location} /> : null
}

const BarTypeProvider = () => {
    return <ObjectTypeProvider name="bar" Formatter={BarFormatter} />
}

const DateOnTimeFormatter = ({ value }: ObjectFormatterProps) => {
    return value ? <Typography variant="body1" style={value.onTime ? undefined : { color: `${colors.red}` }}><FormattedDate value={value.date} dateStyle="medium" /></Typography> : null
}

const DateOnTimeTypeProvider = () => {
    return <ObjectTypeProvider name="dateOnTime" Formatter={DateOnTimeFormatter} />
}

const OnTimeFormatter = ({ value }: ObjectFormatterProps) => {
    const style = useStyles()
    return <div className={value ? style.onTime : style.delay}><Typography variant="body1" style={value ? undefined : { color: 'white' }}>{value ? 'YES' : 'NO'}</Typography></div>
}

const OnTimeTypeProvider = () => {
    return <ObjectTypeProvider name="onTime" Formatter={OnTimeFormatter} />
}


const useStyles = makeStyles((theme: Theme) => ({
    viewcard: {
        width: '100%',
        minWidth: 850,
        paddingBottom: theme.spacing(2),
        columnCount: 4,
        columnGap: theme.spacing(3),
        columnWidth: 180,
        columnFill: 'balance',
        boxSizing: 'border-box'
    },
    popover: {
        pointerEvents: "none",
    },
    paper: {
        padding: theme.spacing(1),
    },
    onTime: {
        width: '100%',
        height: 35,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    delay: {
        width: '100%',
        height: 35,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: `${colors.red}`,
        borderRadius: 5,
        border: `1px solid ${colors.red}`,
    },
    tabIconGrid: {
        position: 'absolute',
        left: 0,
    },
    totalPartsTable: {
        minHeight: "192px",
    },
    headerTitle: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        alignItems: 'flex-start',
    },
}))