import { useCallback } from "react"
import { IntlString } from "../../../utils/IntlString"
import { Projects } from "../../../utils/Projects"
import { useUpload } from "../../../utils/fetch/useFetch"
import { InboundResult, inboundResultFromJson } from "../models/InboundResult"
import { OutboundCreateFactor, outboundCreateFactorFromJson } from "../models/OutboundCreateFactor"

export const useDeliveryUploadDi = () => {
    const url = '/lcbm-logistics-api/upload/uploadDI'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useDeliveryUploadDiForBu = () => {
    const url = '/lcbm-logistics-api/upload/uploadDIForBu'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useDeliveryUploadDiChangeFrom = () => {
    const url = '/lcbm-logistics-api/upload/uploadDIChangeForm'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useDeliveryUploadDiForBuChangeFrom = () => {
    const url = '/lcbm-logistics-api/upload/uploadDIForBuChangeForm'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useDeliveryUploadOutbound = () => {
    const url = '/lcbm-logistics-api/upload/uploadOutbound'
    const payloadProject = useCallback((obj: { file: File, isConfirm?: boolean }) => obj, [])
    const resultProject = useCallback((m: IntlString[]) => m, [])
    return useUpload<{ file: File, isConfirm?: boolean }, IntlString[]>(url, payloadProject, resultProject)
}

export const useUploadExternalOutbound = () => {
    const url = '/lcbm-logistics-api/upload/uploadExternalOutbound'
    const payloadProject = useCallback((obj: { file: File, isConfirm?: boolean }) => obj, [])
    const resultProject = useCallback((m: IntlString[]) => m, [])
    return useUpload<{ file: File, isConfirm?: boolean }, IntlString[]>(url, payloadProject, resultProject)
}

export const useDeliveryUploadOutboundForCreate = () => {
    const url = '/lcbm-logistics-api/upload/uploadOutboundByCreate'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = useCallback(outboundCreateFactorFromJson, [])
    return useUpload<{ file: File }, OutboundCreateFactor>(url, payloadProject, resultProject)
}

export const useUploadSimpleOutboundForCreate = () => {
    const url = '/lcbm-logistics-api/upload/simple-outbound/uploadByCreate'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = useCallback(outboundCreateFactorFromJson, [])
    return useUpload<{ file: File }, OutboundCreateFactor>(url, payloadProject, resultProject)
}

export const useUploadExternalOutboundByDetail = () => {
    const url = '/lcbm-logistics-api/upload/uploadExternalOutboundByDetail'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = useCallback(outboundCreateFactorFromJson, [])
    return useUpload<{ file: File }, OutboundCreateFactor>(url, payloadProject, resultProject)
}

export const useUploadOutboundByInvoiceExcel = () => {
    const url = '/lcbm-logistics-api/upload/uploadOutboundByInvoiceExcel'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = useCallback(outboundCreateFactorFromJson, [])
    return useUpload<{ file: File }, OutboundCreateFactor>(url, payloadProject, resultProject)
}

export const useUploadInvoiceExcelForOutbound = () => {
    const url = '/lcbm-logistics-api/upload/uploadInvoiceExcelForOutbound'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = useCallback(outboundCreateFactorFromJson, [])
    return useUpload<{ file: File }, OutboundCreateFactor>(url, payloadProject, resultProject)
}

export const useUploadInbResult = () => {
    const url = '/lcbm-logistics-api/upload/uploadInbResult'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = useCallback(Projects.array(inboundResultFromJson), [])
    return useUpload<{ file: File }, InboundResult[]>(url, payloadProject, resultProject)
}

export const useUploadExternalInbound = () => {
    const url = '/lcbm-logistics-api/upload/uploadExternalInbound'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = useCallback(Projects.array(inboundResultFromJson), [])
    return useUpload<{ file: File }, InboundResult[]>(url, payloadProject, resultProject)
}

export const useUploadInbResultForPNA = () => {
    const url = '/lcbm-logistics-api/upload/uploadInbResultForPNA';
    const payloadProject = Projects.KEEP
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, void>(url, payloadProject, resultProject);
}

export const useUploadInbResultForRoots = () => {
    const url = '/lcbm-logistics-api/upload/uploadInbResultForRoots';
    const payloadProject = Projects.KEEP
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File, dcId: number }, void>(url, payloadProject, resultProject);
}

export const useUploadOutboundToWIP = () => {
    const url = '/lcbm-logistics-api/upload/outboundtowip'
    const payloadProject = useCallback((obj: { file: File, isConfirm?: boolean }) => obj, [])
    const resultProject = useCallback((m: IntlString[]) => m, [])
    return useUpload<{ file: File, isConfirm?: boolean }, IntlString[]>(url, payloadProject, resultProject)
}

export const useUploadWIPStockAdjust = () => {
    const url = '/lcbm-logistics-api/upload/wipadjust'
    const payloadProject = useCallback((obj: { file: File, isConfirm?: boolean }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File, isConfirm?: boolean }, undefined>(url, payloadProject, resultProject)
}