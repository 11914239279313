import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { CoStatus } from "../enums/CoStatus"
import { RequestStatus } from "../enums/RequestStatus"
import { RequestType } from "../enums/RequestType"

export type BUChangeRequestListResult = BUChangeRequestListResultRequired & Partial<BUChangeRequestListResultOptional>
type BUChangeRequestListResultJson = BUChangeRequestListResultJsonRequired & Partial<BUChangeRequestListResultJsonOptional>

export const buChangeRequestListResultFromJson: Project<BUChangeRequestListResultJson, BUChangeRequestListResult> = json => ({
    ...json,
    poDate: dateFromJson(json.poDate),
    orderFirstDate: dateFromJson(json.orderFirstDate),
    orderLastDate: dateFromJson(json.orderLastDate),
    targetFirstDate: dateFromJson(json.targetFirstDate),
    targetLastDate: dateFromJson(json.targetLastDate),
    completedDate: dateFromJson(json.completedDate)
})

interface BUChangeRequestListResultRequired extends BUChangeRequestListResultJsonRequired {
    status: CoStatus,

}

interface BUChangeRequestListResultOptional extends Omit<BUChangeRequestListResultJsonOptional, 'poDate' | 'orderFirstDate' | 'orderLastDate' | 'targetFirstDate' | 'targetLastDate' |  'completedDate' | 'reviseType' | 'roStatus'> {
    coDate: Date,
    orderFirstDate: Date,
    orderLastDate: Date,
    targetFirstDate: Date,
    targetLastDate: Date,
    combinedDate: Date,
    completedDate: Date,
    reviseType: RequestType,
    roStatus: RequestStatus
}

interface BUChangeRequestListResultJsonRequired {
    poId: number,
    poNo: string,
    contractNo: string,
    contrRouteCode: string,
    status: number,
}

interface BUChangeRequestListResultJsonOptional {
    orderRefNo: string,
    businessType: number,
    orderFrequency: number,
    orderType: number,
    orderVersion: number,
    spotOrderReason: string,
    poDate: string,
    orderFirstDate: string,
    orderLastDate: string,
    targetFirstDate: string,
    targetLastDate: string,
    shippingMode: number,
    remark: string,
    completedDate: string,
    sellerCode: string,
    dcCode: string,
    roId: number,
    reviseNo: string,
    reviseType: number,
    roStatus: number,
    roVersion: number,
}

