import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { dateFromJson } from "../../utils/deserializer"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"
import { ActiveFlag } from "../enums/ActiveFlag"

export type CompanyListDownloadFactor = Omit<CompanyListFactor, 'page'>
type CompanyListDownloadFactorJson = Omit<CompanyListFactorJson, 'page'>
export const companyListDownloadFactorToJson: Project<CompanyListDownloadFactor, CompanyListDownloadFactorJson> = obj => ({
    ...obj,
    createdDateStart: dateToJson(obj.createdDateStart),
    createdDateEnd: dateToJson(obj.createdDateEnd),
    updatedDateStart: dateToJson(obj.updatedDateStart),
    updatedDateEnd: dateToJson(obj.updatedDateEnd)
})


export type CompanyListFactor = Required & Partial<Optional>
type CompanyListFactorJson = JsonRequired & Partial<JsonOptional>
export const companyListFactorToJson: Project<CompanyListFactor, CompanyListFactorJson> = obj => ({
    ...obj,
    createdDateStart: dateToJson(obj.createdDateStart),
    createdDateEnd: dateToJson(obj.createdDateEnd),
    updatedDateStart: dateToJson(obj.updatedDateStart),
    updatedDateEnd: dateToJson(obj.updatedDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
})
export const companyListFactorFromJson: Project<CompanyListFactorJson, CompanyListFactor> = json => ({
    ...json,
    createdDateStart: dateFromJson(json.createdDateStart),
    createdDateEnd: dateFromJson(json.createdDateEnd),
    updatedDateStart: dateFromJson(json.updatedDateStart),
    updatedDateEnd: dateFromJson(json.updatedDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page),
})

interface Required extends JsonRequired {

}

interface Optional extends Omit<JsonOptional, 'createdDateStart' | 'createdDateEnd' | 'updatedDateStart' | 'updatedDateEnd'> {
    statusList: ActiveFlag[],
    createdDateStart: Date,
    createdDateEnd: Date,
    updatedDateStart: Date,
    updatedDateEnd: Date,
    page: PageViewEntity
}

interface JsonRequired {

}

interface JsonOptional {
    companyCode: string,
    companyName: string,
    companyShortCode: string,
    regionCode: string,
    statusList: number[],
    createdDateStart: string,
    createdDateEnd: string,
    updatedDateStart: string,
    updatedDateEnd: string,
    page: PageViewEntityJson
}

