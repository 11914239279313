import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@material-ui/core'
import { SaveOutlined } from '@material-ui/icons'
import { Action, Column, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Editing, Filtering, PaginationLayout, Paging, Row, RowActionProvider, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarLayout } from '@rithe/data-grid'
import { DataGridRowActionProps } from '@rithe/data-grid/dist/components/basic/DataGridRowAction'
import { Break, EntryItem, Form, Message, StringItem } from "@rithe/form"
import { GridItem } from '@rithe/ui'
import { Records, arrx } from '@rithe/utils'
import { default as React, memo, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { useFunctionStore } from '../../../Root'
import { SubmitCallbackViewAction } from '../../../components/Action/SubmitCallbackViewAction'
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from '../../../components/DataGrid/components/PageInfo'
import { PageSelect } from '../../../components/DataGrid/components/PageSelect'
import { PageSizeSelect } from '../../../components/DataGrid/components/PageSizeSelect'
import { DeleteCallbackRowAction } from '../../../components/DataGrid/rowActions/DeleteCallbackRowAction'
import { DownloadGroupedToolbarAction } from '../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction'
import { GroupedCallbackItem } from '../../../components/DataGrid/toolbarActions/GroupedCallbackItem'
import { UploadGroupedToolbarAction } from '../../../components/DataGrid/toolbarActions/UploadGroupedToolbarAction'
import { PercentTypeProvider } from '../../../components/DataGrid/typeProviders/PercentTypeProvider'
import { DialogAction } from '../../../components/Dialog/DialogAction'
import { DialogHeader } from '../../../components/Dialog/DialogHeader'
import { ScreenMode } from '../../../services/common/enums/ScreenMode'
import { useDownloadRequestPartsPrice } from '../../../services/master/apis/masterDownloadApi'
import { useUploadRequestPartsPrice } from '../../../services/master/apis/masterUploadApi'
import { useSubmitRequestDetail } from '../../../services/master/apis/requestApi'
import { useSaveRequest } from '../../../services/master/apis/requestDetailApi'
import { ContractRequestType } from '../../../services/master/enums/ContaractRequestType'
import { PartsDetailResult } from '../../../services/master/models/PartsDetailResult'
import { RequestHeaderResult } from '../../../services/master/models/RequestHeaderResult'
import { RequestToResult } from "../../../services/master/models/RequestToResult"
import { useGetCompanyUid } from '../../../utils/ApplicationUtils'
import { useFieldChecker, useFormValidater } from '../../../utils/ValidatorUtils'
import { applicationActions } from '../../Application/applicationSlice'
import { MOS021PcUiProps } from './MOS021PcUi'
import { PartsListView } from './PartsListView'

export const RequestPriceView = (props: MOS021PcUiProps) => {
    const { mode, requestToList, headerInfo, setHeaderInfo, partsList, setPartsList, search, requestNo, messages, setMessages } = props
    const intl = useIntl()
    return (
        <>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={1}
                    step
                    title={intl.formatMessage({ id: 'add_new_parts_title_one' })}
                    subtitle={intl.formatMessage({ id: "add_new_parts_message_one" })}
                />
                <SectionCardContent>
                    <TheFirstDiv mode={mode} requestToList={requestToList} headerInfo={headerInfo} setHeaderInfo={setHeaderInfo} messages={messages} setMessages={setMessages} setPartsList={setPartsList} />
                </SectionCardContent>
            </SectionCard>

            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={2}
                    step
                    title={intl.formatMessage({ id: 'add_new_parts_title_two' })}
                    subtitle={intl.formatMessage({ id: "remove_parts_message_two" })}
                    actions={mode !== ScreenMode.VIEW ? [
                        <SelectPartsAction headerInfo={headerInfo} partsList={partsList} setPartsList={setPartsList} mode={mode} />,
                        <SaveRequestAction headerInfo={headerInfo} partsList={partsList} setPartsList={setPartsList} search={search} setMessages={setMessages} requestNo={requestNo} setHeaderInfo={setHeaderInfo} />
                    ] : []}
                />
                <SectionCardContent>
                    <TheFifthDiv mode={mode} headerInfo={headerInfo} partsList={partsList} setPartsList={setPartsList} />
                </SectionCardContent>
            </SectionCard>

            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={3}
                    step
                    title={intl.formatMessage({ id: 'add_new_parts_title_three' })}
                    subtitle={intl.formatMessage({ id: "add_new_parts_message_three" })}
                    actions={mode !== ScreenMode.VIEW ? [
                        <DownLoadAction headerInfo={headerInfo} partsList={partsList} />,
                        <UploadAction headerInfo={headerInfo} setPartsList={setPartsList} />
                    ] : []}
                />
            </SectionCard>
        </ >
    )
}

const SaveRequestAction = ({ headerInfo, partsList, search, setMessages, requestNo, setHeaderInfo, setPartsList }: {
    headerInfo: RequestHeaderResult,
    partsList: PartsDetailResult[],
    setHeaderInfo: React.Dispatch<React.SetStateAction<RequestHeaderResult>>,
    setPartsList: React.Dispatch<React.SetStateAction<PartsDetailResult[]>>,
    search: (requestNo: string) => void,
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
    requestNo: string,
}) => {
    const saveRequest = useSaveRequest()
    const fields = useMemo(() => getFormCheckFields(true), [])
    const formValidate = useFormValidater(setMessages, fields)
    const [disabled, setDisabled] = useState<boolean>(false)
    const clickToSave = useCallback(() => {
        if (formValidate(headerInfo)) {
            const requestInfo = {
                requestHeader: { ...headerInfo, requestType: ContractRequestType.MODIFY_PRICE },
                requestPartsList: [...partsList]
            }
            setDisabled(true)
            saveRequest(requestInfo, { serialized: true }).then((result) => {
                if (result.requestHeader) {
                    setHeaderInfo(result.requestHeader)
                }
                if (result.requestPartsList) {
                    setPartsList(result.requestPartsList)
                }
            }).finally(() => {
                setDisabled(false)
            })
        }
    }, [formValidate, headerInfo, partsList, saveRequest, setHeaderInfo, setPartsList])
    // return <SaveCallbackViewAction outlined callback={clickToSave} />
    return <>
        <Tooltip title={<FormattedMessage id="save" />}>
            <IconButton onClick={clickToSave} disabled={disabled}><SaveOutlined></SaveOutlined></IconButton>
        </Tooltip>
    </>
}

export const SubmitForModifyPriceAction = ({ headerInfo, setMessages, partsList }: {
    headerInfo: RequestHeaderResult,
    partsList: PartsDetailResult[],
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>
}) => {
    const [open, setOpen] = useState<boolean>(false)
    const navigate = useNavigate()
    const submitApi = useSubmitRequestDetail()
    const fields = useMemo(() => getFormCheckFields(true), [])
    const dispatch = useDispatch()
    const intl = useIntl()
    const formValidate = useFormValidater(setMessages, fields)
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'submit' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const submitClick = useCallback(() => {
        const functionId = functionStore.register(() => {
            if (formValidate(headerInfo)) {
                setMessages([])
                const requestInfo = {
                    requestHeader: { ...headerInfo, requestType: ContractRequestType.MODIFY_PRICE },
                    requestPartsList: [...partsList],
                    useConfirmFlag: 2
                }
                setDisabled(true)
                submitApi((requestInfo), { serialized: true }).then(result => {
                    navigate(`/requestTo`)
                }).finally(() => {
                    setDisabled(false)
                })
            }
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [functionStore, dispatch, title, formValidate, headerInfo, setMessages, partsList, submitApi, navigate])

    return <>
        <SubmitCallbackViewAction callback={submitClick} disabled={disabled} />
        <ConfirmSubmitAction headerInfo={headerInfo} partsList={partsList} open={open} setOpen={setOpen} />
    </>
}

const ConfirmSubmitAction = ({ headerInfo, partsList, open, setOpen }: {
    headerInfo: RequestHeaderResult,
    partsList: PartsDetailResult[],
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
}) => {
    const navigate = useNavigate()
    const submitApi = useSubmitRequestDetail()
    const dispatch = useDispatch()
    const intl = useIntl()
    const [disabled, setDisabled] = useState<boolean>(false)
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'confirm' }), [intl])
    const confirm = useCallback(() => {
        const functionId = functionStore.register(() => {
            const requestInfo = {
                requestHeader: { ...headerInfo, requestType: ContractRequestType.MODIFY_PRICE },
                requestPartsList: [...partsList],
                useConfirmFlag: 2
            }
            setDisabled(true)
            submitApi((requestInfo), { serialized: true }).then(() => { navigate(`/requestTo`) }).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [functionStore, dispatch, title, headerInfo, partsList, submitApi, navigate])

    return <>
        <Dialog open={open} style={{ overflow: 'hidden' }} maxWidth="sm" fullWidth fullScreen={false}>
            <DialogHeader onClose={() => setOpen(false)}>
                <FormattedMessage id="isRequestConfirm" />
            </DialogHeader>
            <DialogContent>
                <GridItem columnSpan={3}><FormattedMessage id="isRequestConfirmSub" /></GridItem>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={() => setOpen(false)} />
                <DialogAction title={<FormattedMessage id="confirm" />} callback={confirm} disabled={disabled} />
            </DialogActions>
        </Dialog>
    </>
}


const SelectPartsAction = ({ headerInfo, partsList, setPartsList, mode }: {
    headerInfo: RequestHeaderResult,
    partsList: PartsDetailResult[],
    setPartsList: React.Dispatch<React.SetStateAction<PartsDetailResult[]>>,
    mode: ScreenMode
}) => {
    const dispatch = useDispatch()
    const [open, setOpen] = useState<boolean>(false)
    const onOpen = useCallback(() => {
        if (headerInfo.requestTo === undefined || headerInfo.requestTo === null) {
            dispatch(applicationActions.pushError({ title: { code: 'field.requestTo' }, messages: { code: 'w0379' } }))
            return
        }
        setOpen(true)
    }, [dispatch, headerInfo.requestTo])

    const onClose = useCallback(() => setOpen(false), [])
    const selectedPartsList = useMemo(() => partsList.filter(f => f.unitPartsId !== null && f.unitPartsId !== undefined).map(p => p.unitPartsId + '-' + p.contractNo), [partsList])
    const [selectedList, setSelectedList] = useState<PartsDetailResult[]>([])
    const keyIds = useMemo(() => partsList.map(p => p.contractPartsId).join(","), [partsList])

    const resetPartsList = useCallback(() => {
        if (selectedList.length > 0) {
            setPartsList(partsList => ([...partsList, ...selectedList]))
        }
        setOpen(false)
        setSelectedList([])
    }, [selectedList, setPartsList])

    return <>
        <Button onClick={onOpen} variant="contained" style={{ backgroundColor: '#7bd3fb' }}><FormattedMessage id="selectPart" /></Button >
        <Dialog open={open} style={{ height: '700px', overflow: 'hidden', color: '#fff', }} maxWidth="md" fullWidth>
            <DialogTitle>
                <FormattedMessage id="selectPartsScreen" />
            </DialogTitle>
            <DialogContent>
                <PartsListView requestType={ContractRequestType.MODIFY_PRICE} requestTo={headerInfo.requestTo} selectedPartsList={selectedPartsList} setSelectedList={setSelectedList} keyIds={keyIds} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained"><FormattedMessage id="close" /></Button>
                <Button autoFocus onClick={resetPartsList} variant="contained" style={{ backgroundColor: '#ffcc99' }}><FormattedMessage id="alert.ok" /></Button>
            </DialogActions>
        </Dialog>
    </>
}

/** Request To */
interface TheFirstDivInfo {
    mode: ScreenMode,
    requestToList: RequestToResult[],
    headerInfo: RequestHeaderResult,
    setHeaderInfo: React.Dispatch<React.SetStateAction<RequestHeaderResult>>
    messages: Message[],
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
    setPartsList: React.Dispatch<React.SetStateAction<PartsDetailResult[]>>,
}
const TheFirstDiv = memo((props: TheFirstDivInfo) => {
    const { mode, requestToList, headerInfo, setHeaderInfo, messages, setMessages, setPartsList } = props
    const intl = useIntl()
    const companyUid = useGetCompanyUid()
    const requestToMap = useMemo(() => requestToList.filter((m) => m.cbdsUid !== companyUid).map(m => [m.cbdsUid, m.cbdsCode] as [string, string]), [companyUid, requestToList])
    const fields = useMemo(() => getFormCheckFields(true), [])
    const filedCheck = useFieldChecker(fields, setMessages)
    const dataChange = useCallback<React.Dispatch<React.SetStateAction<RequestHeaderResult>>>(nextDraftDataFunc => {
        setHeaderInfo(data => {
            const draftData = typeof nextDraftDataFunc === 'function' ? nextDraftDataFunc(data) : nextDraftDataFunc
            if (draftData.requestType !== data.requestType || draftData.requestTo !== data.requestTo) {
                setPartsList([])
            }
            return draftData;
        })
    }, [setHeaderInfo, setPartsList])
    return <Form data={headerInfo} setData={dataChange} labelDisplay="block" helperDisplay="tooltip" messages={messages} setMessages={setMessages} ensure={filedCheck}>
        <EntryItem field="requestTo" required label={intl.formatMessage({ id: 'field.requestTo' })} entries={requestToMap} readonly={mode !== ScreenMode.CREATE} />
        <Break />
        <StringItem field="description" label={intl.formatMessage({ id: 'field.description' })} colSpan={2} rowSpan={4} readonly={mode === ScreenMode.VIEW} />
    </Form>
})

/** INPUT PART INFO */
const TheFifthDiv = memo(({ mode, headerInfo, partsList, setPartsList }: {
    mode: ScreenMode,
    headerInfo: RequestHeaderResult,
    partsList: PartsDetailResult[],
    setPartsList: React.Dispatch<React.SetStateAction<PartsDetailResult[]>>,
}) => {
    const intl = useIntl()
    const columns = useMemo(() => arrx(
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 180 },
        { field: 'sellerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsNo' }), width: 180 },
        { field: 'unitPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsName' }), width: 180 },
        { field: 'contractNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contractNo' }), width: 180 },
        { field: 'effectiveFrom', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.effectiveFrom' }), width: 200 },
        { field: 'unitPrice', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.price' }), width: 200 },
        { field: 'currency', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.currency' }), width: 200 },
    ), [intl])
    const display1 = useCallback((tableRow: TableRow) => mode !== ScreenMode.VIEW, [mode])
    const actionProps1 = useMemo(() => ({ setPartsList }), [setPartsList])
    const onEditingRowCommit = useCallback((_: Column, row: Row) => {
        setPartsList(partsList => partsList.map((item, idx) => item.unitPartsNo === row.unitPartsNo && item.contractNo === row.contractNo ? row as PartsDetailResult : item))
        return true
    }, [setPartsList])

    return <div style={{ width: '100%', height: '500px' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout />
            <DataTypePreset />
            <PercentTypeProvider />
            <Data rows={partsList} columns={columns} />
            <RowActionProvider name="delete" Action={DeleteRowActionTotal} actionProps={actionProps1} display={display1} />
            <ColumnFreeze />
            <ColumnVisibility
                defaultHiddenFields={[]}
                columnSettings={{
                    unitPartsNo: { disableUserControl: true },
                    globalPartsNo: { disableUserControl: true },
                }} />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase />
            <Editing
                enableInlineEdit={mode !== ScreenMode.VIEW}
                columnSettings={{
                    partsNo: { editingDisabled: true },
                    sellerPartsNo: { editingDisabled: true },
                    unitPartsNo: { editingDisabled: true },
                    unitPartsName: { editingDisabled: true },
                    contractNo: { editingDisabled: true },
                    currency: { editingDisabled: true },
                }}
                onEditingCellCommit={onEditingRowCommit}
            />
            <Sorting />
            <Filtering />
            <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
            <Action width={52} />
        </DataGrid>
    </div>
})

const DownLoadAction = ({ headerInfo, partsList }: {
    headerInfo: RequestHeaderResult,
    partsList: PartsDetailResult[]
}) => {
    const dispatch = useDispatch()
    const downloadRequestPartsPrice = useDownloadRequestPartsPrice()
    const downLoadMethod = useCallback(() => {
        if (headerInfo.requestTo === undefined || headerInfo.requestTo === null) {
            dispatch(applicationActions.pushError({ title: { code: 'field.requestTo' }, messages: { code: 'w0379' } }))
            return
        }
        if (partsList.length === 0) {
            dispatch(applicationActions.pushWarning({ title: { code: 'downloadPartForm' }, messages: { code: 'w0004' } }))
            return
        }
        const requestInfo = {
            requestHeader: { ...headerInfo, requestType: ContractRequestType.MODIFY_PRICE },
            requestPartsList: [...partsList],
            useConfirmFlag: 2
        }
        downloadRequestPartsPrice(requestInfo)
    }, [dispatch, downloadRequestPartsPrice, headerInfo, partsList])
    return <DownloadGroupedToolbarAction>
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="downloadPartForm" />} callback={downLoadMethod} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}

const UploadAction = ({ headerInfo, setPartsList }: {
    headerInfo: RequestHeaderResult,
    setPartsList: React.Dispatch<React.SetStateAction<PartsDetailResult[]>>,
}) => {
    const dispatch = useDispatch()
    const uploadRequestPartsPrice = useUploadRequestPartsPrice()
    const uploadMethod = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        if (headerInfo.requestTo === undefined || headerInfo.requestTo === null) {
            dispatch(applicationActions.pushError({ title: { code: 'field.requestTo' }, messages: { code: 'w0379' } }))
            return
        }
        popupUpload((files: FileList | null) => {
            if (files) {
                uploadRequestPartsPrice({ file: files[0], requestTo: headerInfo.requestTo }, { serialized: true }).then((result) => {
                    if (result?.length > 0) {
                        setPartsList(result)
                    } else {
                        setPartsList([])
                    }
                })
            }
        })
    }, [headerInfo.requestTo, dispatch, uploadRequestPartsPrice, setPartsList])

    return <>
        <UploadGroupedToolbarAction>
            {(popupUpload, onClose) => <>
                <GroupedCallbackItem label={<FormattedMessage id="uploadPartForm" />} callback={() => uploadMethod(popupUpload)} onClose={onClose} />
            </>}
        </UploadGroupedToolbarAction>
    </>

}

const DeleteRowActionTotal = ({ tableRow, setPartsList }: DataGridRowActionProps & { setPartsList: React.Dispatch<React.SetStateAction<PartsDetailResult[]>> }) => {
    const callback = useCallback((tableRow: TableRow) => setPartsList(partList => partList.filter((_, index) => _.unitPartsNo !== tableRow.row?.unitPartsNo || _.contractNo !== tableRow.row?.contractNo)), [setPartsList])
    return <DeleteCallbackRowAction tableRow={tableRow} callback={callback} />
}

/** formValidate function */
const getFormCheckFields = (isIssue: boolean) => {
    return ({
        requestTo: { labelId: 'field.requestTo', required: true },
        description: { labelId: 'field.description', length: { max: 255 } },
    })
}
