
import React, { useCallback, useEffect, useState } from "react"
import { useGetOrderList } from "../../../services/dashboard/apis/dashboardApi"
import { OrderFactor } from "../../../services/dashboard/models/OrderDashboardFactor"
import { OrderResult } from "../../../services/dashboard/models/OrderDashboardResult"
import { DAS010PcUi } from "./DAS010PcUi"


export const DAS010 = () => {
    const [resultData, setResultData] = useState<OrderResult>({})

    const getOrderList = useGetOrderList()

    const search = useCallback((filter: OrderFactor) => {
        getOrderList(filter, { silent: true, serialized: true }).then(result => {
            setResultData(result ?? {})
        })
    }, [getOrderList])

    useEffect(() => {
        search({})
    }, [search])

    return <DAS010PcUi
        search={search}
        data={resultData}
    />
}
