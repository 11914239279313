import { Badge, makeStyles } from "@material-ui/core"
import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, RowActionProvider, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import Records from "@rithe/utils/dist/base/Records"
import React, { useCallback, useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DetailRedirectRowAction } from "../../../components/DataGrid/rowActions/DetailRedirectRowAction"
import { ForecastRedirectRowAction } from "../../../components/DataGrid/rowActions/ForecastRedirectRowAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { ReceivedPlaceOrderView } from "../../../services/order/models/ReceivedPlaceOrderView"

interface OCS022PcUiProps {
    filters: ReceivedPlaceOrderView,
    setFilters: React.Dispatch<React.SetStateAction<ReceivedPlaceOrderView>>,
    search: (filters: ReceivedPlaceOrderView) => void,
    data: ReceivedPlaceOrderView[],
    totalCount: number,
}

export const OCS022PcUi = (props: OCS022PcUiProps) => {

    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>

}

const DataTable = ({ filters, setFilters, data, search }: OCS022PcUiProps) => {
    const intl = useIntl()
    const columns = useMemo(() => [
        { field: 'buyerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.buyerCode' }), width: 250 },
        { field: 'orderType', dataTypeName: CodeCategory.OrderType, title: intl.formatMessage({ id: 'field.orderType' }), width: 250 },
        { field: 'soDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.orderDate' }), width: 250 },
        { field: 'soNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.soNo' }), width: 250 },
        { field: 'status', dataTypeName: CodeCategory.SoSpotStatus, title: intl.formatMessage({ id: 'field.soStatus' }), width: 250 },
        { field: 'remark', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.remark' }), width: 250 },
    ], [intl])
    const getRowId = useCallback((row: any) => row.soSpotId, [])
    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search }), [filters, setFilters, search])

    const actionProps = useMemo(() => ({ search, filters }), [filters, search])

    const display1 = useCallback((tableRow: TableRow) => tableRow.row?.status !== undefined && tableRow.row?.status !== null && tableRow.row?.status >= 30 && tableRow.row?.spotId !== undefined && tableRow.row?.spotId !== null, [])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OrderType} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.SoSpotStatus} />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <RowActionProvider name="detail" Action={DetailRowAction} actionProps={actionProps} />
        <RowActionProvider name="placeOrderRequest" Action={ForecastRowAction} actionProps={actionProps} display={display1} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['remark',]}
            columnSettings={{
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Action width={300} />
    </DataGrid>
}

const FilterItem = (props: {
    filters: any,
    search: (filters: any) => void,
    setFilters: React.Dispatch<React.SetStateAction<any>>,
}) => {
    const { filters, search, setFilters } = props

    const clear = useCallback((filters: any) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: any) => {
        return [
            filters.status,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])

    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <CodesItem field="statusList" label={intl.formatMessage({ id: 'status' })} code={CodeCategory.SoSpotStatus} />
    </FilterToolbarItem>
}

const DetailRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/rece/modify-${tableRow.rowId}`, [])
    const styles = useStyles()
    const status = tableRow.row?.pendingUpdateFlag
    const invisible = status === 1

    return <>
        <Badge color='error' variant="dot" invisible={!invisible} classes={{ badge: styles.badge }}>
            <DetailRedirectRowAction tableRow={tableRow} path={path} />
        </Badge>
    </>

}

const ForecastRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const styles = useStyles()
    const status = tableRow.row?.pendingReqeustFlag
    const invisible = status === 1
    const editPath = useCallback((tableRow: TableRow) => `/placebuorder/sowcDetailEdit-${tableRow.row?.spotId}`, [])
    const viewPath = useCallback((tableRow: TableRow) => `/placebuorder/sowcDetailView-${tableRow.row?.spotId}`, [])
    return <>
        <Badge color='error' variant="dot" invisible={!invisible} classes={{ badge: styles.badge }}>
            <ForecastRedirectRowAction title={<FormattedMessage id="My Request" />} tableRow={tableRow} path={invisible ? editPath : viewPath} />
        </Badge>
    </>
}

const useStyles = makeStyles(theme => ({
    badge: {
        transform: 'scale(0.8) translate(-5px, -5px)',
        width: 9,
        height: 9,
        minWidth: 9,
        borderRadius: 8,
        marginTop: 10,
    },
}))
