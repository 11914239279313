import { makeStyles, Typography } from "@material-ui/core"
import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Row, Searching, Sorting, StringTypeProvider, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { StringFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/StringFormatter"
import { Break, DateItem, EntryItem, Form, StringItem } from "@rithe/form"
import { Arrays, Records } from "@rithe/utils"
import React, { memo, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CodeItem } from "../../../components/Form/CodeItem"
import { View } from "../../../components/View/View"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { CoPartsData, EstimatedData, InboundPalnData } from "../../../services/order/models/CoDetailResult"
import { OrderDetailHead, OrderPartsData } from "../../../services/order/models/OrderDetailResult"

interface OCS031PcUiProps {
    headerInfo: OrderDetailHead,
    partsDetailList: OrderPartsData[],
    receiveDcList: TnvCbds[],
    cbdsList: TnvCbds[],
}

export const OCS031PcUi = (props: OCS031PcUiProps) => {
    const intl = useIntl()
    const { headerInfo, receiveDcList, cbdsList, partsDetailList } = props

    return <View>
        <SectionCard>
            <SectionCardHeader
                serialNumber={1}
                title={intl.formatMessage({ id: 'basicInfo' })}
                subtitle={intl.formatMessage({ id: 'basicInfoSub' })}
            />
            <SectionCardContent>
                <HeaderDetailTable headerInfo={headerInfo} receiveDcList={receiveDcList} cbdsList={cbdsList} />
            </SectionCardContent>
        </SectionCard>
        <SectionCard>
            <SectionCardHeader
                serialNumber={2}
                title={intl.formatMessage({ id: 'shippingPlanInfo' })}
                subtitle={intl.formatMessage({ id: 'shippingPlanInfoSub' })}
            />
            <SectionCardContent>
                <ShippingPlanTbale currentDate={headerInfo.currentDate as Date} partsDetailList={partsDetailList} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const HeaderDetailTable = memo((props: { headerInfo: OrderDetailHead, receiveDcList: TnvCbds[], cbdsList: TnvCbds[] }) => {
    const { headerInfo, receiveDcList, cbdsList } = props
    const intl = useIntl()

    const dcMap: [number, string][] = useMemo(() => receiveDcList.filter(f => f.cbdsType === CbdsType.DC).map(m => [m.cbdsId, m.cbdsCode]), [receiveDcList])
    const buSuppMap: [string, string][] = useMemo(() => cbdsList.filter((m) => m.cbdsType === CbdsType.SUPP || m.cbdsType === CbdsType.BU).map((m) => [m.cbdsUid, m.cbdsCode]), [cbdsList])
    return <>
        <Form readonly data={headerInfo} labelDisplay="block" helperDisplay="tooltip">
            <StringItem field="customerOrderNo" label={intl.formatMessage({ id: 'field.customerOrderNo' })} />
            <CodeItem field="orderType" label={intl.formatMessage({ id: 'field.orderType' })} code={CodeCategory.OrderType} />
            <CodeItem field="spotStatus" label={intl.formatMessage({ id: 'field.status' })} code={CodeCategory.CoSpotStatus} />
            <EntryItem field="receiveDcId" label={intl.formatMessage({ id: 'receiveDcCode' })} entries={dcMap} />
            <EntryItem field="sellerUid" required readonly label={intl.formatMessage({ id: 'seller' })} entries={buSuppMap} />
            <CodeItem field="shippingMode" label={intl.formatMessage({ id: 'field.shippingMode' })} code={CodeCategory.ShippingMode} />
            <StringItem colSpan={2} field="paymentTermsDesc" label={intl.formatMessage({ id: 'field.paymentTermsDesc' })} multiline />
            <Break />
            <StringItem field="submittedBy" label={intl.formatMessage({ id: 'field.submittedBy' })} />
            <DateItem field="submittedDate" label={intl.formatMessage({ id: 'field.submittedDate' })} />
            <DateItem field="completedDate" label={intl.formatMessage({ id: 'field.completedDate' })} />
            <StringItem colSpan={2} field="remark" label={intl.formatMessage({ id: 'field.remark' })} multiline />
        </Form>
    </>
})

const ShippingPlanTbale = memo((props: { currentDate: Date, partsDetailList: CoPartsData[] }) => {
    const intl = useIntl()
    const { currentDate, partsDetailList } = props
    const [order, setOrder] = useState<string[]>([])

    const palnDates: InboundPalnData[] = useMemo(() => partsDetailList[0]?.inboundPlanList ?? [], [partsDetailList])
    const estimatedDatas: EstimatedData[] = useMemo(() => partsDetailList[0]?.estimatedInboundList ?? [], [partsDetailList])
    const columns = useMemo(() => {
        const fixedColumns = [
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
            { field: 'supplierCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierCode' }), width: 200 },
            { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 100 },
            { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 150 },
            { field: 'orderQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderQty' }), width: 150 },
            { field: 'unitPrice', dataTypeName: 'number', title: intl.formatMessage({ id: 'unitPrice' }), width: 150 },
            { field: 'currency', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.currency' }), width: 120 },
            { field: 'spotDetailStatus', dataTypeName: CodeCategory.CoSpotDetailStatus, title: intl.formatMessage({ id: 'field.status' }), width: 120 }
        ]
        //Inbounded Qty(Current Date) Column
        const iCurrentCategories = [{ key: 'iCurrent', value: intl.formatMessage({ id: 'InboundedQtyCurrent' }) }]
        const iCurrentDateColumns = [{ field: 'currentInbQty', dataTypeName: 'number', title: intl.formatTime(currentDate, { dateStyle: 'medium' }), categories: iCurrentCategories, width: 240 }]

        //Inbound Plan Date Column
        const getPlanCategories = (index: number) => [
            { key: 'plan', value: intl.formatMessage({ id: 'field.inboundPlanDate' }) },
            { key: `plan${index}`, value: intl.formatDate(palnDates[index].crdDate, { dateStyle: 'medium' }) },
        ]
        const inboundPlanColumns = Arrays.range(0, palnDates.length).flatMap(index => [
            { field: 'crdQty' + index, dataTypeName: 'number', title: intl.formatMessage({ id: 'plan' }), categories: getPlanCategories(index), width: 150, getCellValue: getPlanDataValueByName(index, 'crdQty') },
            { field: 'status' + index, dataTypeName: 'status', title: intl.formatMessage({ id: 'field.status' }), categories: getPlanCategories(index), width: 150, getCellValue: getPlanDataValueByName(index, 'status') }
        ])

        const getEstimatedCategories = (index: number) => [
            { key: 'estimated', value: intl.formatMessage({ id: 'estimatedInboundDate' }) },
            { key: `estimated${index}`, value: intl.formatTime(estimatedDatas[index].estimatedCrdDate, { dateStyle: 'medium' }) }
        ]
        const estimatedInboundColums = Arrays.range(0, estimatedDatas.length).map(index => {
            return { field: `qty${index}`, dataTypeName: 'number', title: estimatedDatas[index].companyTitle ?? '', categories: getEstimatedCategories(index), width: 220, getCellValue: getEstimatedValue(index) }
        })

        return Arrays.concat(fixedColumns, iCurrentDateColumns, inboundPlanColumns, estimatedInboundColums)

    }, [currentDate, estimatedDatas, intl, palnDates])


    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <StatusTypeProvider />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.CoSpotDetailStatus} />
            <Data rows={partsDetailList} columns={columns} />
            <ColumnFreeze />
            <ColumnVisibility
                columnSettings={{
                    partsNo: { disableUserControl: true },
                }} ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnOrdering order={order} onOrderChange={setOrder} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
        </DataGrid>
    </div>
})

const getEstimatedValue = (index: number) => {
    return (row: Row) => {
        return row.estimatedInboundList[index].qty
    }
}

const getPlanDataValueByName = (index: number, columnName: string) => {
    return (row: Row) => {
        if (row.inboundPlanList[index][columnName] === 0) {
            return ''
        } else {
            return row.inboundPlanList[index][columnName]
        }
    }
}

const CheckFormatter = ({ value }: StringFormatterProps) => {
    const style = useStyles()
    const bgcolor = 'OK' === value ? '#00CCAD' : '#D94C00'
    return <div className={style.status} style={{ background: bgcolor, color: 'white', width: '100%' }}>
        <Typography variant="body2" >{value}</Typography>
    </div>
}

const StatusTypeProvider = () => {
    return <StringTypeProvider name="status" Formatter={CheckFormatter} />
}

const useStyles = makeStyles(theme => ({
    status: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70%',
        paddingRight: theme.spacing(1),
        borderRadius: 5
    },
    default: {},
}))