import { AxiosInstance } from 'axios'

export interface FrequentlyUsedPageAPIResponse {
    pageId: number,
    pageCode: string,
    userId: number,
    cbdsUid: string,
    accessedCount: number
}

const frequentlyUsedPagesApi = {
    list: function (axiosInstance: AxiosInstance, data: { limit?: number }) {
        return axiosInstance.get<FrequentlyUsedPageAPIResponse[]>('/common-system-api/user-pages/top-accessed', { params: data })
    },
    statistics: function (axiosInstance: AxiosInstance, data: { pageCode: string }) {
        return axiosInstance.post('/common-system-api/user-pages/access', data)
    },

}

export default frequentlyUsedPagesApi