export enum TabId {
    Regular,
    Spot,
    SpotWithoutContract,
    ONS010
}

export interface ViewTabInfo {
    access: string,
    tabId: TabId,
    label: string,
    navigation: string,
}

export const ViewTabList: ViewTabInfo[] = [
    {
        access: "ORDER.ONS010REGULAR.VIEW",
        tabId: TabId.Regular,
        label: "regularOrder",
        navigation: "/placecustorder-regular",
    }, {
        access: "ORDER.ONS010SPOT.VIEW",
        tabId: TabId.Spot,
        label: "spotOrder",
        navigation: "/placecustorder-spot",
    }, {
        access: "ORDER.ONS010SOWC.VIEW",
        tabId: TabId.SpotWithoutContract,
        label: "spotWithoutContract",
        navigation: "/placecustorder-sowc",
    }
]