import { Button, Checkbox, Divider, Fade, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles, Menu, MenuItem, TableCell, Tooltip, useTheme } from "@material-ui/core"
import { FileCopyOutlined, Print, Send, Undo } from "@material-ui/icons"
import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Detail, Expanding, Filtering, PaginationLayout, Paging, Row, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridBodyCellProps } from "@rithe/data-grid/dist/components/basic/DataGridBodyCell"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { DataGridDetailFormatterProps } from "@rithe/data-grid/dist/components/detail/DataGridDetailFormatter"
import { DateRangeItem, EntriesItem, StringItem } from "@rithe/form"
import { Records } from "@rithe/utils"
import React, { SetStateAction, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { NeumorphismButton } from "../../../components/Button/NeumorphismButton"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { ApproveCallbackRowAction } from "../../../components/DataGrid/rowActions/ApproveCallbackRowAction"
import { CallbackRowAction } from "../../../components/DataGrid/rowActions/CallbackRowAction"
import { EditRedirectRowAction } from "../../../components/DataGrid/rowActions/EditRedirectRowAction"
import { ViewRedirectRowAction } from "../../../components/DataGrid/rowActions/ViewRedirectRowAction"
import { ApproveCallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/ApproveCallbackToolbarAction"
import { CombineCallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/CombineCallbackToolbarAction"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { ReleaseCallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/ReleaseCallbackToolbarAction"
import { UndoGroupToolbarAction } from "../../../components/DataGrid/toolbarActions/UndoGroupToolbarAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { useFunctionStore } from "../../../Root"
import { useDoApproveInvoice, useDoCombineInvoice, useDoReleaseInvoice, useDoUndoApproveInvoice, useDoUndoCombineInvoice, useDownloadSellerList } from "../../../services/accounting/apis/accountingBuyerApi"
import { InvoiceStatus } from "../../../services/accounting/enums/InvoiceStatus"
import { BuyerInvoiceTransfer } from "../../../services/accounting/models/BuyerInvoiceTransfer"
import { BuyerInvoiceView } from "../../../services/accounting/models/BuyerInvoiceView"
import { InvoiceCbds } from "../../../services/accounting/models/SellerInvoiceFilterResponse"
import { useDownloadInvoicePDF } from "../../../services/delivery/apis/deliveryOutboundApi"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { TnmPort } from "../../../services/master/models/TnmPort"
import { useGetCodeName } from "../../../utils/CodeCategoryUtil"
import { applicationActions } from "../../Application/applicationSlice"

interface AIS020PcUiProps {
    filters: BuyerInvoiceView,
    setFilters: React.Dispatch<SetStateAction<BuyerInvoiceView>>,
    search: (filters: BuyerInvoiceView) => void,
    data: BuyerInvoiceTransfer[],
    totalCount: number,
    portList: TnmPort[],
    buyerList: InvoiceCbds[],
    sellerList: InvoiceCbds[],
}

export const AIS020PcUi = (props: AIS020PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({
    filters,
    setFilters,
    search,
    data,
    portList,
    buyerList,
    sellerList
}: AIS020PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])
    const { getCodeName } = useGetCodeName()
    const getShippingMode = useCallback((row: Row) => {
        return row.shippingMode ? getCodeName(CodeCategory.ShippingMode, row.shippingMode) : (row.shippingModes && row.shippingModes.map((m: any) => getCodeName(CodeCategory.ShippingMode, m)).join(','))
    }, [getCodeName])

    const columns = useMemo(() => [
        { field: 'invoiceNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.invoiceNo' }), width: 300 },
        { field: 'externalInvoiceNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.externalInvoiceNo' }), width: 300 },
        { field: 'outboundNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.outboundNo' }), width: 300 },
        { field: 'contractNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contractNo' }), width: 200 },
        { field: 'buyer', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.buyer' }), width: 150 },
        { field: 'seller', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.seller' }), width: 150 },
        { field: 'shippingMode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.shippingMode' }), width: 180, getCellValue: getShippingMode },
        { field: 'outboundDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.outboundDate' }), width: 200 },
        { field: 'loadingPort', dataTypeName: 'string', title: intl.formatMessage({ id: 'loadingPort' }), width: 200 },
        { field: 'dischargePort', dataTypeName: 'string', title: intl.formatMessage({ id: 'dischargePort' }), width: 200 },
        { field: 'issuedDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'issuedDate' }), width: 150 },
        { field: 'approvedBy', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.approvedBy' }), width: 200 },
        { field: 'approvedDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.approvedDate' }), width: 200 },
        { field: 'releasedBy', dataTypeName: 'string', title: intl.formatMessage({ id: 'releasedBy' }), width: 200 },
        { field: 'releasedDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'releasedDate' }), width: 200 },
        { field: 'status', dataTypeName: CodeCategory.InvoiceStatus, title: intl.formatMessage({ id: 'field.status' }), width: 150 },
    ], [getShippingMode, intl])
    const getRowId = useCallback((row: any) => row.invoiceId, [])

    const itemProps1 = useMemo(() => ({ filters, setFilters, search, portList, buyerList, sellerList }), [filters, setFilters, search, portList, buyerList, sellerList])
    const actionProps1 = useMemo(() => ({
        invoiceIds: data.filter(f => selections.indexOf(f.invoiceId) >= 0).map(m => m.invoiceId),
        search,
        filters
    }), [data, filters, search, selections])
    const actionProps2 = useMemo(() => ({
        invoiceNos: data.filter(f => selections.indexOf(f.invoiceId) >= 0).map(m => m.invoiceNo)
    }), [data, selections])
    const actionProps3 = useMemo(() => ({ search, filters }), [search, filters])
    const actionProps4 = useMemo(() => ({ selections, filters }), [selections, filters])
    const displayEdit = useCallback((tableRow: TableRow) => tableRow.row?.status === InvoiceStatus.NEW || tableRow.row?.status === InvoiceStatus.COMBINED, [])
    const display1 = useCallback((tableRow: TableRow) => tableRow.row?.status === InvoiceStatus.COMBINED, [])
    const display2 = useCallback((tableRow: TableRow) => tableRow.row?.status === InvoiceStatus.NEW || tableRow.row?.status === InvoiceStatus.COMBINED, [])
    const display3 = useCallback((tableRow: TableRow) => tableRow.row?.status === InvoiceStatus.APPROVED, [])
    const display4 = useCallback((tableRow: TableRow) => tableRow.row?.status === InvoiceStatus.APPROVED, [])
    // const display5 = useCallback((tableRow: TableRow) => tableRow.row?.status === InvoiceStatus.RELEASED, [])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.ShippingMode} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.InvoiceStatus} />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={CombineAction} actionProps={actionProps1} />
        <ToolbarActionProvider Action={ApproveAction} actionProps={actionProps1} />
        <ToolbarActionProvider Action={ReleaseAction} actionProps={actionProps1} />
        <ToolbarActionProvider Action={UndoAction} actionProps={actionProps1} />
        <ToolbarActionProvider Action={PrintAction} actionProps={actionProps2} />
        <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps4} />
        <RowActionProvider name="view" Action={ViewRowAction} />
        <RowActionProvider name="edit" Action={EditRowAction} display={displayEdit} />
        <RowActionProvider name="undoCombine" Action={UndoCombineRowAction} actionProps={actionProps3} display={display1} />
        <RowActionProvider name="approve" Action={ApproveRowAction} actionProps={actionProps3} display={display2} />
        <RowActionProvider name="undoApprove" Action={UndoApproveRowAction} actionProps={actionProps3} display={display3} />
        <RowActionProvider name="release" Action={ReleaseRowAction} actionProps={actionProps3} display={display4} />
        {/* <RowActionProvider name="undoRelease" Action={UndoReleaseRowAction} actionProps={actionProps3} display={display5} /> */}
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['externalInvoiceNo', 'loadingPort', 'dischargePort', 'approvedBy', 'releasedBy']}
            columnSettings={{
                invoiceNo: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemProps1} />
        <Detail DetailFormatter={DetailFormatter} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={178} />
        <Expanding BodyExpandCell={DataGridBodyCell} />
    </DataGrid>
}


const DetailFormatter = (props: DataGridDetailFormatterProps) => {
    const { row } = props
    const invoices = row?.invoices ?? []
    const intl = useIntl()
    const columns = useMemo(() => [
        { field: 'invoiceNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.invoiceNo' }), width: 200 },
        { field: 'externalInvoiceNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.externalInvoiceNo' }), width: 200 },
        { field: 'outboundNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.outboundNo' }), width: 300 },
        { field: 'shippingMode', dataTypeName: CodeCategory.ShippingMode, title: intl.formatMessage({ id: 'field.shippingMode' }), width: 180 },
        { field: 'loadingPort', dataTypeName: 'string', title: intl.formatMessage({ id: 'loadingPort' }), width: 200 },
        { field: 'dischargePort', dataTypeName: 'string', title: intl.formatMessage({ id: 'dischargePort' }), width: 200 },
        { field: 'issuedDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'issuedDate' }), width: 150 },
    ], [intl])
    const getRowId = useCallback((row: any) => row.invoiceId, [])

    return <div style={{ paddingLeft: 100, paddingTop: 15, paddingBottom: 15 }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <DataTypePreset />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.ShippingMode} />
            <Data rows={invoices} columns={columns} getRowId={getRowId} />
            <RowActionProvider name="view" Action={ViewRowAction} />
            <Action width={178} />
        </DataGrid>
    </div>
}

const DataGridBodyCell = (props: DataGridBodyCellProps) => {
    const {
        align,
        width,
        colSpan,
        rowSpan,
        freezePosition,
        freezeOffset,
        children,
        tableRows,
    } = props

    const invoices = tableRows[0].row?.invoices ?? []
    const display = invoices && invoices.length && invoices.length > 0
    const styles = useStyles()
    return <TableCell
        colSpan={colSpan}
        rowSpan={rowSpan}
        style={{
            width,
            position: freezePosition ? 'sticky' : 'static',
            left: freezePosition === 'start' ? freezeOffset : undefined,
            right: freezePosition === 'end' ? freezeOffset : undefined,
            zIndex: freezePosition ? 1 : 0,
        }}
        className={styles.root}
    >
        <div
            style={{
                width,
                justifyContent: align === 'center' ? 'center' : `flex-${align}`,
            }}
            className={styles.layout}
        >
            {display ? children : null}
        </div>
    </TableCell>
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: 0,
        boxSizing: 'border-box',
    },
    layout: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        padding: `0 ${theme.spacing(1)}px`,
        boxSizing: 'border-box',
        overflow: 'hidden',
    }
}))

const FilterItem = (props: { filters: BuyerInvoiceView, setFilters: React.Dispatch<SetStateAction<BuyerInvoiceView>>, search: (filters: BuyerInvoiceView) => void, portList: TnmPort[], buyerList: InvoiceCbds[], sellerList: InvoiceCbds[] }) => {
    const { filters, setFilters, portList, search, buyerList, sellerList } = props
    const portEntries: [number, string][] = useMemo(() => portList.map(m => [m.portId, m.portCode]), [portList])
    const buyerEntries: [string, string][] = useMemo(() => buyerList.map(m => [m.cbdsUid, m.cbdsCode]), [buyerList])
    const sellerEntries: [string, string][] = useMemo(() => sellerList.map(m => [m.cbdsUid, m.cbdsCode]), [sellerList])

    const outboundDateGetValue = useCallback((filters: BuyerInvoiceView) => {
        return [filters.outboundDateStart ?? null, filters.outboundDateEnd ?? null]
    }, [])

    const outboundDateMapValue = useCallback((filters: BuyerInvoiceView, value: any) => {
        return { ...filters ?? {}, outboundDateStart: value[0], outboundDateEnd: value[1] }
    }, [])

    const createdDateGetValue = useCallback((filters: BuyerInvoiceView) => {
        return [filters.issuedDateStart ?? null, filters.issuedDateEnd ?? null]
    }, [])

    const createdDateMapValue = useCallback((filters: BuyerInvoiceView, value: any) => {
        return { ...filters ?? {}, issuedDateStart: value[0], issuedDateEnd: value[1] }
    }, [])

    const updatedDateGetValue = useCallback((filters: BuyerInvoiceView) => {
        return [filters.approvedDateStart ?? null, filters.approvedDateEnd ?? null]
    }, [])

    const updatedDateMapValue = useCallback((filters: BuyerInvoiceView, value: any) => {
        return { ...filters ?? {}, approvedDateStart: value[0], approvedDateEnd: value[1] }
    }, [])

    const clear = useCallback((filters: BuyerInvoiceView) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: BuyerInvoiceView) => {
        return [
            filters.invoiceNo,
            filters.externalInvoiceNo,
            filters.outboundNo,
            filters.contractNo,
            // filters.buyers,
            // filters.sellers,
            filters.shippingModeList,
            filters.loadingPortList,
            filters.dischargePortList,
            filters.approvedBy,
            filters.statusList,
            filters.outboundDateStart || filters.outboundDateEnd,
            filters.issuedDateStart || filters.issuedDateEnd,
            filters.approvedDateStart || filters.approvedDateEnd,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="invoiceNo" label={intl.formatMessage({ id: 'field.invoiceNo' })} />
        <StringItem field="externalInvoiceNo" label={intl.formatMessage({ id: 'field.externalInvoiceNo' })} />
        <StringItem field="outboundNo" label={intl.formatMessage({ id: 'field.outboundNo' })} />
        <StringItem field="contractNo" label={intl.formatMessage({ id: 'field.contractNo' })} />
        <EntriesItem field="buyers" label={intl.formatMessage({ id: 'field.buyer' })} entries={buyerEntries} />
        <EntriesItem field="sellers" label={intl.formatMessage({ id: 'field.seller' })} entries={sellerEntries} />
        <CodesItem field="shippingModeList" label={intl.formatMessage({ id: 'field.shippingMode' })} code={CodeCategory.ShippingMode} />

        <EntriesItem field="loadingPortList" label={intl.formatMessage({ id: 'loadingPort' })} entries={portEntries} />
        <EntriesItem field="dischargePortList" label={intl.formatMessage({ id: 'dischargePort' })} entries={portEntries} />
        <StringItem field="approvedBy" label={intl.formatMessage({ id: 'field.approvedBy' })} />
        <CodesItem field="statusList" label={intl.formatMessage({ id: 'field.status' })} code={CodeCategory.InvoiceStatus} />
        <DateRangeItem field="outboundDate" label={intl.formatMessage({ id: 'field.outboundDate' })} getValue={outboundDateGetValue} mapValue={outboundDateMapValue} />
        <DateRangeItem field="issuedDate" label={intl.formatMessage({ id: 'issuedDate' })} getValue={createdDateGetValue} mapValue={createdDateMapValue} />
        <DateRangeItem field="approvedDate" label={intl.formatMessage({ id: 'field.approvedDate' })} getValue={updatedDateGetValue} mapValue={updatedDateMapValue} />
    </FilterToolbarItem>
}

const CombineAction = ({ invoiceIds, filters, search }: {
    invoiceIds: number[],
    filters: BuyerInvoiceView,
    search: (filters: BuyerInvoiceView) => void,
}) => {
    const dispatch = useDispatch()
    const combineInvoice = useDoCombineInvoice()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'combine' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const callback = useCallback(() => {
        if (invoiceIds.length <= 1) {
            dispatch(applicationActions.pushError({ title: { code: 'combine' }, messages: { code: 'w0369' } }))
        } else {
            const functionId = functionStore.register(() => {
                setDisabled(true)
                combineInvoice({ invoiceIdList: invoiceIds }, { serialized: true }).then(() => search(filters)).finally(() => {
                    setDisabled(false)
                })
            })
            dispatch(applicationActions.pushWarning({
                title: title,
                messages: { code: 'c0001', args: [title] },
                actions: [{
                    label: 'CANCEL'
                }, {
                    functionId,
                    label: 'CONFIRM',
                }]
            }))
        }
    }, [combineInvoice, dispatch, filters, functionStore, invoiceIds, search, title])
    return <CombineCallbackToolbarAction access="ACCT.AIS020.COMBINE" callback={callback} disabled={disabled} />
}

const ApproveAction = ({ invoiceIds, filters, search }: {
    invoiceIds: number[],
    filters: BuyerInvoiceView,
    search: (filters: BuyerInvoiceView) => void,
}) => {
    const dispatch = useDispatch()
    const approveInvoice = useDoApproveInvoice()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'approve' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const callback = useCallback(() => {
        if (invoiceIds.length <= 0) {
            dispatch(applicationActions.pushError({ title: { code: 'approve' }, messages: { code: 'w0002' } }))
        } else {
            const functionId = functionStore.register(() => {
                setDisabled(true)
                approveInvoice({ invoiceIdList: invoiceIds }, { serialized: true }).then(() => search(filters)).finally(() => {
                    setDisabled(false)
                })
            })
            dispatch(applicationActions.pushWarning({
                title: title,
                messages: { code: 'c0001', args: [title] },
                actions: [{
                    label: 'CANCEL'
                }, {
                    functionId,
                    label: 'CONFIRM',
                }]
            }))
        }
    }, [approveInvoice, dispatch, filters, functionStore, invoiceIds, search, title])
    return <ApproveCallbackToolbarAction access="ACCT.AIS020.APPROVE" callback={callback} disabled={disabled} />
}

const ReleaseAction = ({ invoiceIds, filters, search }: {
    invoiceIds: number[],
    filters: BuyerInvoiceView,
    search: (filters: BuyerInvoiceView) => void,
}) => {
    const dispatch = useDispatch()
    const releaseInvoice = useDoReleaseInvoice()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'release' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const callback = useCallback(() => {
        if (invoiceIds.length <= 0) {
            dispatch(applicationActions.pushError({ title: { code: 'release' }, messages: { code: 'w0002' } }))
        } else {
            const functionId = functionStore.register(() => {
                setDisabled(true)
                releaseInvoice({ invoiceIdList: invoiceIds }, { serialized: true }).then(() => search(filters)).finally(() => {
                    setDisabled(false)
                })
            })
            dispatch(applicationActions.pushWarning({
                title: title,
                messages: { code: 'c0001', args: [title] },
                actions: [{
                    label: 'CANCEL'
                }, {
                    functionId,
                    label: 'CONFIRM',
                }]
            }))
        }
    }, [invoiceIds, dispatch, functionStore, title, releaseInvoice, search, filters])
    return <ReleaseCallbackToolbarAction access="ACCT.AIS020.RELEASE" callback={callback} disabled={disabled} />
}

const UndoAction = ({ invoiceIds, filters, search }: {
    invoiceIds: number[],
    filters: BuyerInvoiceView,
    search: (filters: BuyerInvoiceView) => void,
}) => {
    const dispatch = useDispatch()
    const undoCombine = useDoUndoCombineInvoice()
    const undoApprove = useDoUndoApproveInvoice()
    // const undoRelease = useDoUndoReleaseInvoice()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const undoCombineTitle = useMemo(() => intl.formatMessage({ id: 'undoCombine' }), [intl])
    const undoApproveTitle = useMemo(() => intl.formatMessage({ id: 'undoApprove' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const undoCombineCallback = useCallback(() => {
        if (invoiceIds.length <= 0) {
            dispatch(applicationActions.pushError({ title: { code: 'undoCombine' }, messages: { code: 'w0002' } }))
        } else {
            const functionId = functionStore.register(() => {
                setDisabled(true)
                undoCombine({ invoiceIdList: invoiceIds }, { serialized: true }).then(() => search(filters)).finally(() => {
                    setDisabled(false)
                })
            })
            dispatch(applicationActions.pushWarning({
                title: undoCombineTitle,
                messages: { code: 'c0001', args: [undoCombineTitle] },
                actions: [{
                    label: 'CANCEL'
                }, {
                    functionId,
                    label: 'CONFIRM',
                }]
            }))
        }
    }, [invoiceIds, dispatch, functionStore, undoCombineTitle, undoCombine, search, filters])
    const undoApproveCallback = useCallback(() => {
        if (invoiceIds.length <= 0) {
            dispatch(applicationActions.pushError({ title: { code: 'undoApprove' }, messages: { code: 'w0002' } }))
        } else {
            const functionId = functionStore.register(() => {
                setDisabled(true)
                undoApprove({ invoiceIdList: invoiceIds }, { serialized: true }).then(() => search(filters)).finally(() => {
                    setDisabled(false)
                })
            })
            dispatch(applicationActions.pushWarning({
                title: undoApproveTitle,
                messages: { code: 'c0001', args: [undoApproveTitle] },
                actions: [{
                    label: 'CANCEL'
                }, {
                    functionId,
                    label: 'CONFIRM',
                }]
            }))
        }
    }, [invoiceIds, dispatch, functionStore, undoApproveTitle, undoApprove, search, filters])
    // const undoReleaseCallback = useCallback(() => {
    //     if (invoiceIds.length <= 0) {
    //         dispatch(applicationActions.pushError({ title: { code: 'undoRelease'   }, messages: { code: 'w0002'   } }))
    //     } else {
    //         undoRelease({ invoiceIdList: invoiceIds }, { serialized: true }).then(() => search(filters))
    //     }
    // }, [invoiceIds, alert, intl, undoRelease, search, filters])
    return <UndoGroupToolbarAction>
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="undoCombine" />} callback={undoCombineCallback} onClose={onClose} disabled={disabled} />
            <GroupedCallbackItem label={<FormattedMessage id="undoApprove" />} callback={undoApproveCallback} onClose={onClose} disabled={disabled} />
            {/* <GroupedCallbackItem label={<FormattedMessage id="undoRelease" />} callback={undoReleaseCallback} onClose={onClose} /> */}
        </>}
    </UndoGroupToolbarAction>
}

const PrintAction = ({ invoiceNos }: { invoiceNos: string[] }) => {
    const intl = useIntl()
    const theme = useTheme()
    const dispatch = useDispatch()
    const [open, setOpen] = useState<boolean>(false)
    const [checked, setChecked] = useState<number[]>([])
    const handleClose = () => { setOpen(false) }
    const handleCheck = (value: number) => () => {
        const currentIndex = checked.indexOf(value)
        const newChecked = [...checked]
        if (currentIndex === -1) {
            newChecked.push(value)
        } else {
            newChecked.splice(currentIndex, 1)
        }
        setChecked(newChecked)
    }

    const typeList = useMemo(() => [
        { typeName: intl.formatMessage({ id: 'printInvoice' }), typeCode: 1 },
        { typeName: intl.formatMessage({ id: 'printPackingList' }), typeCode: 2 }
    ], [intl])
    const print = useDownloadInvoicePDF()
    const printChangeFrom = useCallback(() => {
        if (checked.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'print' }, messages: { code: 'w0036' } }))
            return
        }
        if (invoiceNos.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'print' }, messages: { code: 'w0002' } }))
            return
        }
        const conditions = {
            invoiceNos: invoiceNos,
            printInvoiceFlag: checked.find(m => m === 1) !== undefined ? 1 : 0,
            printPackingListFlag: checked.find(m => m === 2) !== undefined ? 1 : 0,
            printPayableFlag: checked.find(m => m === 3) !== undefined ? 1 : 0,
            printReceiveableFlag: 0
        }

        print(conditions)
    }, [checked, invoiceNos, print, dispatch])

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
        setOpen(true)
    }
    return <>
        {/* <Access access=" ACCT.AIS020.PRINT"> */}
        <Tooltip title={intl.formatMessage({ id: "print" })}>
            <NeumorphismButton onClick={handleClickListItem} style={{ width: 36, marginLeft: theme.spacing(1) }}>{<Print></Print>}</NeumorphismButton>
        </Tooltip>
        <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <List>
                {typeList.map((value, index) => {
                    const labelId = `checkbox-list-label-${index}`
                    return (
                        <>
                            <ListItem key={index} dense button onClick={handleCheck(value.typeCode)}>
                                <ListItemAvatar>
                                    <FileCopyOutlined></FileCopyOutlined>
                                </ListItemAvatar>
                                <ListItemText id={labelId} primary={value.typeName} />
                                <ListItemSecondaryAction>
                                    <Checkbox
                                        edge="end"
                                        onChange={handleCheck(value.typeCode)}
                                        checked={checked.indexOf(value.typeCode) !== -1}
                                        inputProps={{ 'aria-labelledby': labelId }} />
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider component="li" />
                        </>
                    )
                })}
            </List>
            <MenuItem>
                <Button onClick={printChangeFrom} variant="contained" color="primary"><FormattedMessage id="print" /></Button>
            </MenuItem>
        </Menu>
        {/* </Access> */}
    </>
}

const DownloadAction = ({ selections, filters }: { selections: number[], filters: BuyerInvoiceView }) => {
    const dowloadApi = useDownloadSellerList()
    const download = useCallback(() => {
        dowloadApi({ ids: selections, filter: filters })
    }, [dowloadApi, selections, filters])
    return <>
        <DownloadGroupedToolbarAction >
            {onClose => <>
                <GroupedCallbackItem label={<FormattedMessage id="downloadSellerInvoice" />} callback={download} onClose={onClose} />
            </>}
        </DownloadGroupedToolbarAction>
    </>
}

const ViewRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/gi/view-${tableRow.rowId}`, [])
    return <ViewRedirectRowAction tableRow={tableRow} access="ACCT.AIS020.VIEWDETAIL" path={path} />
}

const EditRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/gi/edit-${tableRow.rowId}`, [])
    return <EditRedirectRowAction tableRow={tableRow} access="ACCT.AIS020.EDIT" path={path} />
}

const ApproveRowAction = ({ tableRow, search, filters }: DataGridRowActionProps & { search: (filter: BuyerInvoiceView) => void, filters: BuyerInvoiceView }) => {
    const approveInvoice = useDoApproveInvoice()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'approve' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const callback = useCallback((tableRow: TableRow) => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            approveInvoice({ invoiceIdList: [tableRow.rowId as number] }, { serialized: true }).then(() => search(filters)).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [approveInvoice, dispatch, filters, functionStore, search, title])
    return <ApproveCallbackRowAction tableRow={tableRow} access="ACCT.AIS020.APPROVE" callback={callback} disabled={disabled} />
}

const ReleaseRowAction = ({ tableRow, search, filters }: DataGridRowActionProps & { search: (filter: BuyerInvoiceView) => void, filters: BuyerInvoiceView }) => {
    const releaseInvoice = useDoReleaseInvoice()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'release' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const callback = useCallback((tableRow: TableRow) => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            releaseInvoice({ invoiceIdList: [tableRow.rowId as number] }, { serialized: true }).then(() => search(filters)).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [functionStore, dispatch, title, releaseInvoice, search, filters])
    return <CallbackRowAction title={<FormattedMessage id="release" />} icon={<Send />} tableRow={tableRow} access="ACCT.AIS020.RELEASE" callback={callback} disabled={disabled} />
}

const UndoApproveRowAction = ({ tableRow, search, filters }: DataGridRowActionProps & { search: (filter: BuyerInvoiceView) => void, filters: BuyerInvoiceView }) => {
    const undoApproveInvoice = useDoUndoApproveInvoice()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const undoApproveTitle = useMemo(() => intl.formatMessage({ id: 'undoApprove' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const callback = useCallback((tableRow: TableRow) => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            undoApproveInvoice({ invoiceIdList: [tableRow.rowId as number] }, { serialized: true }).then(() => search(filters)).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: undoApproveTitle,
            messages: { code: 'c0001', args: [undoApproveTitle] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [functionStore, dispatch, undoApproveTitle, undoApproveInvoice, search, filters])
    return <CallbackRowAction title={<FormattedMessage id="undoApprove" />} icon={<Undo />} tableRow={tableRow} access="ACCT.AIS020.UNDOAPPROVE" callback={callback} disabled={disabled} />
}

const UndoCombineRowAction = ({ tableRow, search, filters }: DataGridRowActionProps & { search: (filter: BuyerInvoiceView) => void, filters: BuyerInvoiceView }) => {
    const undoCombineInvoice = useDoUndoCombineInvoice()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const undoCombineTitle = useMemo(() => intl.formatMessage({ id: 'undoCombine' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const callback = useCallback((tableRow: TableRow) => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            undoCombineInvoice({ invoiceIdList: [tableRow.rowId as number] }, { serialized: true }).then(() => search(filters)).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: undoCombineTitle,
            messages: { code: 'c0001', args: [undoCombineTitle] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [functionStore, dispatch, undoCombineTitle, undoCombineInvoice, search, filters])
    return <CallbackRowAction title={<FormattedMessage id="undoCombine" />} icon={<Undo />} tableRow={tableRow} access="ACCT.AIS020.UNDOCOMBINE" callback={callback} disabled={disabled} />
}
