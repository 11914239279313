import { Project } from "../../../utils/Project"

export type ContractPeopleInfoResult = Required & Partial<Optional>
type ContractPeopleInfoResultJson = JsonRequired & Partial<JsonOptional>
export const contractPeopleInfoResultFromJson: Project<ContractPeopleInfoResultJson, ContractPeopleInfoResult> = json => ({
    ...json,
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
}

interface JsonRequired {
}

interface JsonOptional {
    sellerList: UidCode[],
    buyerList: UidCode[],
    consigneeList: UidCode[],
    accounteeList: UidCode[],
    deliveryToList: UidCode[],
}

interface UidCode {
    uid: string,
    code: string
}