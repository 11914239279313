import { Divider, Grid, makeStyles, Typography } from "@material-ui/core"
import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, NumberTypeProvider, PaginationLayout, Paging, Row, Searching, Sorting, StringTypeProvider, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { ArrayMultimaps, Arrays, Maps, Multisets, Records } from "@rithe/utils"
import { ReactNode, useCallback, useMemo, useState } from "react"
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { Scrollbar } from "../../../components/Scrollbar"
import { View } from "../../../components/View/View"
import { Currency, GiGrInvoice } from "../../../services/accounting/models/GiGrInvoice"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { AIS011BreakdownPcUi } from "./AIS011BreakdownPcUi"

interface AIS011PcUiProps {
    invoice?: GiGrInvoice,
}

export const AIS011PcUi = ({ invoice }: AIS011PcUiProps) => {
    const intl = useIntl()

    return <View>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={1}
                title={intl.formatMessage({ id: 'summaryInfo' })}
                subtitle={intl.formatMessage({ id: 'summaryInfoSub' })}
            />
            <SectionCardContent>
                <CoverPage invoice={invoice} />
            </SectionCardContent>
        </SectionCard>

        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={2}
                title={intl.formatMessage({ id: 'packageDetailInfo' })}
                subtitle={intl.formatMessage({ id: 'packageDetailInfoSub' })}
            />
            <SectionCardContent>
                <PackageDetailView invoice={invoice} />
            </SectionCardContent>
        </SectionCard>

        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={3}
                title={intl.formatMessage({ id: 'partsDetailInfo' })}
                subtitle={intl.formatMessage({ id: 'partsDetailInfoSub' })}
            />
            <SectionCardContent>
                <PartsDetailView invoice={invoice} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const CoverPage = ({ invoice }: {
    invoice?: GiGrInvoice,
}) => {
    const orderNos = Arrays.distinct(invoice?.details.map(detail => detail.poNo) ?? [])
    const [breakdownEditorOpen, setBreakdownEditorOpen] = useState(false)
    const seller = useMemo(() => invoice?.invoices ? invoice?.invoices[0].seller : (invoice?.seller), [invoice?.seller, invoice?.invoices])
    const accountee = useMemo(() => invoice?.invoices ? invoice?.invoices[0].accountee : (invoice?.accountee), [invoice?.accountee, invoice?.invoices])
    const consignee = useMemo(() => invoice?.invoices ? invoice?.invoices[0].consignee : (invoice?.consignee), [invoice?.consignee, invoice?.invoices])
    const deliveryTo = useMemo(() => invoice?.invoices ? invoice?.invoices[0].deliveryTo : (invoice?.deliveryTo), [invoice?.deliveryTo, invoice?.invoices])
    const contianers = useMemo(() => invoice?.invoices ? (invoice?.invoices?.flatMap(f => f.containers) ?? []) : (invoice?.containers ?? []), [invoice?.containers, invoice?.invoices])
    const packageSums = Arrays.from(
        Multisets.from(
            contianers.flatMap(container => container.packages.map(pack => pack.packageType)).filter(v => v) as string[] ?? []
        ).asMap()
    )
    const quantitySums = Arrays.from(
        Maps.transform(
            ArrayMultimaps.from(invoice?.details.map(detail => [detail.part?.uom?.uomCode, [detail.part?.uom?.decimalDigits, detail.qty]]) ?? []).asMap(),
            ([, data]) => data.reduce((acc, d) => [d[0], (acc[1] ?? 0) + (d[1] ?? 0)], [0, 0])
        )
    )
    const totalNetWeight = contianers.flatMap(container => container.packages).map(pack => pack.netWeight).reduce((acc, weight) => (acc ?? 0) + (weight ?? 0), 0) ?? 0
    const totalGrossWeight = contianers.flatMap(container => container.packages).map(pack => pack.grossWeight).reduce((acc, weight) => (acc ?? 0) + (weight ?? 0), 0) ?? 0
    const totalM3 = contianers.flatMap(container => container.packages).map(pack => pack.m3).reduce((acc, m3) => (acc ?? 0) + (m3 ?? 0), 0) ?? 0
    const packageNos = Arrays.distinct(contianers.flatMap(container => container.packages).map(pack => pack.packageNo) ?? [])
    const countryOfOrigins = invoice?.invoices ? Arrays.distinct(invoice?.invoices.flatMap(m => m.countryOfOrigin?.split(",") ?? [])) : (invoice?.countryOfOrigin?.split(",") ?? [])
    const vesselName = invoice?.invoices ? Arrays.distinct(invoice?.invoices.map(m => (m.vesselName ?? '') + '/' + (m.voyageNo ?? '')) ?? []).join(",") : ((invoice?.vesselName ?? '') + '/' + (invoice?.voyageNo ?? ''))
    const incotermAndPlaces = invoice?.invoices ? Arrays.from(Maps.from(invoice?.invoices.map(m => [(m.incoterms?.code ?? '') + "," + (m.incoterms?.place ?? ''), m.incoterms]) ?? []).values()) : [invoice?.incoterms]
    const etdEtas = invoice?.invoices ? Arrays.from(Maps.from(invoice?.invoices.map(m => [m.etd?.getTime() + "," + m.eta?.getTime(), [m.etd, m.eta]]) ?? []).values()) : [[invoice?.etd, invoice?.eta]] as [Date | undefined, Date | undefined][]
    const placeAndOrders = invoice?.invoices ? Arrays.from(Maps.from(invoice?.invoices.map(m => [m.invoiceDate?.getTime(), m.invoiceDate]) ?? []).values()) : [invoice?.invoiceDate] as (Date | undefined)[]
    const remark = invoice?.invoices ? Arrays.distinct(invoice?.invoices.map(m => (m.remark ?? '')) ?? []).join(",") : invoice?.remark ?? ''
    const paymentTerms = invoice?.invoices ? Arrays.from(Maps.from(invoice?.invoices.map(m => [m.paymentTerms?.description, m.paymentTerms]) ?? []).values()) : [invoice?.paymentTerms]
    const ports = invoice?.invoices ? Arrays.from(Maps.from(invoice?.invoices.map(m => [(m?.loadingPort?.portName ?? '') + "," + (m?.dischargePort?.portName ?? '') + "," + m?.shippingMode, { loadingPort: m.loadingPort, dischargePort: m.dischargePort, shippingMode: m.shippingMode }]) ?? []).values()) : [{ loadingPort: invoice?.loadingPort, dischargePort: invoice?.dischargePort, shippingMode: invoice?.shippingMode }]

    const invoices = useMemo(() => invoice?.invoices ? invoice?.invoices ?? [] : (invoice ? [invoice] : []), [invoice])
    const ifNeedShowInit = useCallback((invoice: any) => {
        const breakdown = invoice.breakdown
        return checkIfHasValue(breakdown.costItem1, breakdown.costItem1Amount)
            || checkIfHasValue(breakdown.additionalItem1, breakdown.additionalItem1Amount)
            || breakdown.initialIncotermsId !== breakdown.finalIncotermsId
    }, [])

    const styles = useStyles()
    return <div className={styles.coverPageContainer}>
        <div className={styles.coverPageUp}>
            <DataBlock title={<FormattedMessage id="field.invoiceNo" />}>
                <Typography variant="body2">{invoice?.invoiceNo + (invoice?.externalInvoiceNo ?  `(${invoice?.externalInvoiceNo})` : '')}</Typography>
            </DataBlock>
            <DataBlock title={<FormattedMessage id="sellerHeaderInfo" />} colSpan={2} rowSpan={2}>
                <Scrollbar >
                    <Typography variant="body2" className={styles.text}>{seller?.cbdsName}</Typography>
                    <Typography variant="body2" className={styles.text}>{seller?.contact?.address1}&nbsp;</Typography>
                    <Typography variant="body2" className={styles.text}>{seller?.contact?.address2}&nbsp;</Typography>
                    <Typography variant="body2" className={styles.text}>{seller?.contact?.address3}&nbsp;</Typography>
                    <Typography variant="body2" className={styles.text}>{seller?.contact?.address4}&nbsp;</Typography>
                    <Typography variant="body2" className={styles.text}><FormattedMessage id="tel" />:{seller?.contact?.tel}&nbsp;&nbsp;&nbsp;&nbsp;<FormattedMessage id="fax" />:{seller?.contact?.fax}</Typography>
                </Scrollbar >
            </DataBlock>
            <DataBlockFlex title={<FormattedMessage id="placeAndDate" />}>
                {placeAndOrders.map(m => {
                    return <div style={{ width: 120 }} >
                        <Typography variant="body2">{seller?.regionName}</Typography>
                        <Typography variant="body2"><FormattedDate value={m} dateStyle="medium" /></Typography>
                    </div>
                })}
            </DataBlockFlex>
        </div>
        <Divider style={{ gridColumn: 'span 3' }} />
        <div className={styles.coverPageLeft}>
            <DataBlock title={<FormattedMessage id="consigneeInfo" />} colSpan={2}>
                <Scrollbar >
                    <Typography variant="body2" className={styles.text}>{consignee?.cbdsName}</Typography>
                    <Typography variant="body2" className={styles.text}>{consignee?.contact?.address1}&nbsp;</Typography>
                    <Typography variant="body2" className={styles.text}>{consignee?.contact?.address2}&nbsp;</Typography>
                    <Typography variant="body2" className={styles.text}>{consignee?.contact?.address3}&nbsp;</Typography>
                    <Typography variant="body2" className={styles.text}>{consignee?.contact?.address4}&nbsp;</Typography>
                    <Typography variant="body2" className={styles.text}><FormattedMessage id="tel" />:{consignee?.contact?.tel}&nbsp;&nbsp;&nbsp;&nbsp;<FormattedMessage id="fax" />:{consignee?.contact?.fax}</Typography>
                </Scrollbar >
            </DataBlock>
            <DataBlock title={<FormattedMessage id="orderNo" />} colSpan={2}>
                {orderNos.map(orderNo => <Typography key={orderNo} variant="body2" className={styles.text}>{orderNo}</Typography>)}
            </DataBlock>
            <DataBlock title={<FormattedMessage id="vesselAndCarrier" />} >
                <Typography variant="body2" className={styles.text}>{vesselName}</Typography>
            </DataBlock>
            <DataBlockFlex title={<FormattedMessage id="etdOrEta" />} >
                {etdEtas.map(m => {
                    return <div style={{ width: 150 }} >
                        <Typography variant="body2">ETD: {m[0] ? <FormattedDate value={m[0]} dateStyle="medium" /> : ''}</Typography>
                        <Typography variant="body2">ETA: {m[1] ? <FormattedDate value={m[1]} dateStyle="medium" /> : ''}</Typography>
                    </div>
                })}
            </DataBlockFlex>
            <DataBlock title={<FormattedMessage id="portAndShippingMode" />} colSpan={2}>
                {ports.map(port => {
                    return <Typography variant="body2">FROM: {port?.loadingPort?.portName ?? ''}  TO: {port?.dischargePort?.portName ?? ''}  VIA: {port?.shippingMode ? <FormattedMessage id={`${CodeCategory.ShippingMode}_${port?.shippingMode}`} /> : ''}</Typography>
                })}
            </DataBlock>
            <DataBlock title={<FormattedMessage id="paymentTerms" />}>
                {paymentTerms.map(m => {
                    return <Typography variant="body2" className={styles.text}>{m?.description ?? ''}</Typography>
                })}
            </DataBlock>
            <DataBlock title={<FormattedMessage id="incoterms" />}>
                {incotermAndPlaces.map(m => <Typography variant="body2" className={styles.text}>{(m?.code ?? '') + " " + (m?.place ?? '')}</Typography>)}
            </DataBlock>
            <DataBlock title={<FormattedMessage id="quantity" />} colSpan={2}>
                <Grid container>
                    <Grid item xs={6}>
                        {packageSums.map((packageSum, index) => <Typography key={index} variant="body2">{packageSum[1]} {packageSum[0]}</Typography>)}
                    </Grid>
                    <Grid item xs={6}>
                        {quantitySums.map((quantitySum, index) => <Typography key={index} variant="body2"><FormattedNumber value={quantitySum[1][1] ?? 0} minimumFractionDigits={quantitySum[1][0]} maximumFractionDigits={quantitySum[1][0]} /> {quantitySum[0]}</Typography>)}
                    </Grid>
                </Grid>
            </DataBlock>
            <DataBlock title={<FormattedMessage id="packageWeights" />} colSpan={2}>
                <Grid container>
                    <Grid item xs={4} container direction="column">
                        <Typography variant="body2"><FormattedMessage id="totalNetWeight" /></Typography>
                        <Typography variant="body2"><FormattedMessage id="totalGrossWeight" /></Typography>
                        <Typography variant="body2"><FormattedMessage id="totalM3" /></Typography>
                    </Grid>
                    <Grid item xs={4} container direction="column" alignItems="flex-end">
                        <Typography variant="body2"><FormattedNumber value={totalNetWeight} minimumFractionDigits={3} maximumFractionDigits={3} /> KG</Typography>
                        <Typography variant="body2"><FormattedNumber value={totalGrossWeight} minimumFractionDigits={3} maximumFractionDigits={3} /> KG</Typography>
                        <Typography variant="body2"><FormattedNumber value={totalM3} minimumFractionDigits={3} maximumFractionDigits={3} /> M<sup>3</sup></Typography>
                    </Grid>
                </Grid>
            </DataBlock>
            <DataBlock title={<FormattedMessage id="accounteeInfo" />} >
                <Scrollbar >
                    <Typography variant="body2" className={styles.text}>{accountee?.cbdsName}</Typography>
                    <Typography variant="body2" className={styles.text}>{accountee?.contact?.address1}&nbsp;</Typography>
                    <Typography variant="body2" className={styles.text}>{accountee?.contact?.address2}&nbsp;</Typography>
                    <Typography variant="body2" className={styles.text}>{accountee?.contact?.address3}&nbsp;</Typography>
                    <Typography variant="body2" className={styles.text}>{accountee?.contact?.address4}&nbsp;</Typography>
                    <Typography variant="body2" className={styles.text}><FormattedMessage id="tel" />:{accountee?.contact?.tel}&nbsp;&nbsp;&nbsp;&nbsp;<FormattedMessage id="fax" />:{accountee?.contact?.fax}</Typography>
                </Scrollbar>
            </DataBlock>
            <DataBlock title={<FormattedMessage id="destinationInfo" />} >
                <Scrollbar >
                    <Typography variant="body2" className={styles.text}>{deliveryTo?.cbdsName}</Typography>
                    <Typography variant="body2" className={styles.text}>{deliveryTo?.contact?.address1}&nbsp;</Typography>
                    <Typography variant="body2" className={styles.text}>{deliveryTo?.contact?.address2}&nbsp;</Typography>
                    <Typography variant="body2" className={styles.text}>{deliveryTo?.contact?.address3}&nbsp;</Typography>
                    <Typography variant="body2" className={styles.text}>{deliveryTo?.contact?.address4}&nbsp;</Typography>
                    <Typography variant="body2"><FormattedMessage id="tel" />:{deliveryTo?.contact?.tel}&nbsp;&nbsp;&nbsp;&nbsp;<FormattedMessage id="fax" />:{deliveryTo?.contact?.fax}</Typography>
                </Scrollbar>
            </DataBlock>
            <DataBlock title={<FormattedMessage id="remark" />} colSpan={2} >
                <Scrollbar>
                    <Typography variant="body2" style={{ overflowWrap: 'break-word' }}>{remark}</Typography>
                </Scrollbar>
            </DataBlock>
        </div>
        <div className={styles.coverPageRight}>
            <div>
                <DataBlock title={<FormattedMessage id="shippingMarks" />}>
                    <Typography variant="body2"><FormattedMessage id="shippingMark" /></Typography>
                    <Typography variant="body2">{invoice?.invoiceNo}</Typography>
                    {/* <Typography variant="body2">{seller?.regionName}</Typography> */}
                    <Typography variant="body2">{countryOfOrigins.length > 0 ? countryOfOrigins.join(",") : ''}</Typography>
                    <Typography variant="body2"><FormattedMessage id="cNo" /></Typography>
                    <Grid container>
                        {Arrays.range(0, 20).map(indx => {
                            return <>
                                {indx < packageNos.length && <Grid item xs={4}><Typography key={packageNos[indx]} variant="body2">{packageNos[indx]}</Typography></Grid>}
                                {indx + 20 < packageNos.length ?
                                    <Grid item xs={4}><Typography key={packageNos[indx + 20]} variant="body2">{packageNos[indx + 20]}</Typography></Grid>
                                    : (indx < packageNos.length ? <Grid item xs={4}></Grid> : <></>)}
                                {indx + 40 < packageNos.length ?
                                    <Grid item xs={4}><Typography key={packageNos[indx + 40]} variant="body2">{packageNos[indx + 40]}</Typography></Grid>
                                    : (indx < packageNos.length ? <Grid item xs={4}></Grid> : <></>)}
                            </>
                        })}
                    </Grid>
                </DataBlock>
                <DataBlock title={<FormattedMessage id="incotermBreakdown" />} onClick={() => setBreakdownEditorOpen(true)} >
                    {invoices && invoices.map(invoice => {
                        return <>
                            {
                                ifNeedShowInit(invoice) ? <>
                                    <Grid container>
                                        <Grid item xs={2} container direction="column">
                                            <Typography variant="body2">{invoice?.breakdown?.initialIncotermsCode}</Typography>
                                        </Grid>
                                        <Grid item xs={6} container direction="column" alignItems="flex-start">
                                            <Typography variant="body2">{invoice?.breakdown?.initialIncotermsPlace}</Typography>
                                        </Grid>
                                        <Grid item xs={4} container direction="column" alignItems="flex-end">
                                            <Typography variant="body2">{invoice?.invoiceCurrency?.currencyCode} <FormattedNumber value={invoice?.breakdown?.initialIncotermsAmount ?? 0} minimumFractionDigits={invoice?.invoiceCurrency?.decimalDigits} maximumFractionDigits={invoice?.invoiceCurrency?.decimalDigits} /></Typography>
                                        </Grid>
                                    </Grid>
                                    <ItemBreakDown itemName={invoice?.breakdown?.costItem1} itemAmount={invoice?.breakdown?.costItem1Amount} currency={invoice?.invoiceCurrency} />
                                    <ItemBreakDown itemName={invoice?.breakdown?.costItem2} itemAmount={invoice?.breakdown?.costItem2Amount} currency={invoice?.invoiceCurrency} />
                                    <ItemBreakDown itemName={invoice?.breakdown?.costItem3} itemAmount={invoice?.breakdown?.costItem3Amount} currency={invoice?.invoiceCurrency} />
                                    <ItemBreakDown itemName={invoice?.breakdown?.costItem4} itemAmount={invoice?.breakdown?.costItem4Amount} currency={invoice?.invoiceCurrency} />
                                    <ItemBreakDown itemName={invoice?.breakdown?.costItem5} itemAmount={invoice?.breakdown?.costItem5Amount} currency={invoice?.invoiceCurrency} />

                                    <Grid item container xs={12}><div className={styles.subDivider} ><Divider /></div></Grid>
                                    <Grid container>
                                        <Grid item xs={6} container direction="column">
                                            <Typography variant="body2">SUB TOTAL</Typography>
                                        </Grid>
                                        <Grid item xs={6} container direction="column" alignItems="flex-end">
                                            <Typography variant="body2">{invoice?.invoiceCurrency?.currencyCode} <FormattedNumber value={(invoice?.breakdown?.invoiceAmount ?? 0) + (invoice?.breakdown?.invoiceAdjustmentAmount ?? 0)} minimumFractionDigits={invoice?.invoiceCurrency?.decimalDigits} maximumFractionDigits={invoice?.invoiceCurrency?.decimalDigits} /></Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item container xs={12}>
                                        <div style={{ width: '100%', alignItems: 'center', padding: 8 }}></div>
                                    </Grid>
                                </> : <></>
                            }
                            <ItemBreakDown itemName={invoice?.breakdown?.additionalItem1} itemAmount={invoice?.breakdown?.additionalItem1Amount} currency={invoice?.invoiceCurrency} />
                            <ItemBreakDown itemName={invoice?.breakdown?.additionalItem2} itemAmount={invoice?.breakdown?.additionalItem2Amount} currency={invoice?.invoiceCurrency} />
                            <ItemBreakDown itemName={invoice?.breakdown?.additionalItem3} itemAmount={invoice?.breakdown?.additionalItem3Amount} currency={invoice?.invoiceCurrency} />
                            <ItemBreakDown itemName={invoice?.breakdown?.additionalItem4} itemAmount={invoice?.breakdown?.additionalItem4Amount} currency={invoice?.invoiceCurrency} />
                            <ItemBreakDown itemName={invoice?.breakdown?.additionalItem5} itemAmount={invoice?.breakdown?.additionalItem5Amount} currency={invoice?.invoiceCurrency} />
                            <Grid container>
                                <Grid item xs={2} container direction="column">
                                    <Typography variant="body2">{invoice?.breakdown?.finalIncotermsCode}</Typography>
                                </Grid>
                                <Grid item xs={6} container direction="column" alignItems="flex-start">
                                    <Typography variant="body2">{invoice?.breakdown?.finalIncotermsPlace}</Typography>
                                </Grid>
                                <Grid item xs={4} container direction="column" alignItems="flex-end">
                                    <Typography variant="body2">{invoice?.invoiceCurrency?.currencyCode} <FormattedNumber value={invoice?.breakdown?.finalIncotermsAmount ?? 0} minimumFractionDigits={invoice?.invoiceCurrency?.decimalDigits} maximumFractionDigits={invoice?.invoiceCurrency?.decimalDigits} /></Typography>
                                </Grid>
                            </Grid>

                            <Grid item container xs={12}><div className={styles.divider} ><Divider /></div></Grid>
                            <Grid container>
                                <Grid item xs={8} container direction="column">
                                    <Typography variant="body2" style={{ fontWeight: 'bold' }}><FormattedMessage id="GrandTotal" /></Typography>
                                </Grid>
                                <Grid item xs={4} container direction="column" alignItems="flex-end">
                                    <Typography variant="body2" style={{ fontWeight: 'bold' }}>{invoice?.invoiceCurrency?.currencyCode} <FormattedNumber value={invoice?.breakdown?.finalIncotermsAmount as number} minimumFractionDigits={invoice?.invoiceCurrency?.decimalDigits} maximumFractionDigits={invoice?.invoiceCurrency?.decimalDigits} /></Typography>
                                </Grid>
                            </Grid>
                        </>
                    })}
                </DataBlock>
                {breakdownEditorOpen &&
                    <AIS011BreakdownPcUi
                        invoice={invoice}
                        open={breakdownEditorOpen}
                        onClose={() => setBreakdownEditorOpen(false)}
                    />}
                <DataBlock title={<FormattedMessage id="insuranceStamp" />}>
                </DataBlock>
                <DataBlock title={<FormattedMessage id="signature" />}>
                </DataBlock>
            </div>
        </div>
    </div>
}

const ItemBreakDown = ({ itemName, itemAmount, currency }: {
    itemName?: string,
    itemAmount?: number,
    currency?: Currency
}) => {
    return checkIfHasValue(itemName, itemAmount) ? <Grid container>
        <Grid item xs={8} container direction="column">
            <Typography variant="body2">{itemName}</Typography>
        </Grid>
        <Grid item xs={4} container direction="column" alignItems="flex-end">
            <Typography variant="body2">{currency?.currencyCode} <FormattedNumber value={itemAmount ?? 0} minimumFractionDigits={currency?.decimalDigits} maximumFractionDigits={currency?.decimalDigits} /></Typography>
        </Grid>
    </Grid> : <></>
}

const PackageDetailView = ({ invoice }: { invoice?: GiGrInvoice }) => {
    const intl = useIntl()
    const packages = useMemo(() => {
        if (invoice?.invoices) {
            return invoice?.invoices.flatMap(inv => inv.containers).flatMap(container => container.packages)
        } else {
            return invoice?.containers.flatMap(container => container.packages) ?? []
        }
    }, [invoice?.containers, invoice?.invoices])

    const columns = useMemo(() => [
        { field: 'packageNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.packageNo' }), width: 200 },
        { field: 'packageType', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.packageType' }), width: 200 },
        { field: 'm3', dataTypeName: 'volume', title: intl.formatMessage({ id: 'field.m3' }), width: 200 },
        { field: 'netWeight', dataTypeName: 'weight', title: intl.formatMessage({ id: 'field.netWeight' }), width: 200 },
        { field: 'grossWeight', dataTypeName: 'weight', title: intl.formatMessage({ id: 'field.grossWeight' }), width: 200 },
        // { field: 'unpackingFlag', dataTypeName: CodeCategory.UnpackingFlag, title: intl.formatMessage({ id: 'field.unpackingFlag' }), width: 200 },
    ], [intl])

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <NumberTypeProvider name="weight" options={{ minimumFractionDigits: 3, maximumFractionDigits: 3 }} />
            <NumberTypeProvider name="volume" options={{ minimumFractionDigits: 3, maximumFractionDigits: 3 }} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.UnpackingFlag} />
            <Data rows={packages} columns={columns} />
            <ColumnFreeze />
            <ColumnVisibility columnSettings={{ packageNo: { disableUserControl: true } }} ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
            <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        </DataGrid>
    </div >
}

const PartsDetailView = ({ invoice }: { invoice?: GiGrInvoice }) => {
    const intl = useIntl()
    const details = useMemo(() => invoice?.details ?? [], [invoice?.details])
    const displayDetails = useMemo(() => Arrays.from(ArrayMultimaps.from(details.map(m => [m.soDetailOrigin + (m.price ?? 0), m])).collections()).map(m => ({
        ...m[0],
        qty: m.map(q => q.qty ?? 0).reduce((a, b) => a + b, 0)
    })), [details])

    const columns = useMemo(() => [
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200, getCellValue: (row: Row) => row.part?.partsNo },
        { field: 'buyerPN', dataTypeName: 'string', title: intl.formatMessage({ id: 'buyerPartsNo' }), width: 200, getCellValue: (row: Row) => row.poPart?.unitPartsNo },
        { field: 'colorCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.colorCode' }), width: 150, getCellValue: (row: Row) => row.part?.colorCode },
        { field: 'buyerPNDesc', dataTypeName: 'string', title: intl.formatMessage({ id: 'buyerPartsName' }), width: 300, getCellValue: (row: Row) => row.poPart?.unitPartsName },
        { field: 'shipperPN', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sellerPartsNo' }), width: 200, getCellValue: (row: Row) => row.soPart?.unitPartsNo },
        { field: 'shipperPNDesc', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sellerPartsName' }), width: 300, getCellValue: (row: Row) => row.soPart?.unitPartsName },
        { field: 'poNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'purchaseOrderNo' }), width: 220, getCellValue: (row: Row) => row.poNo },
        { field: 'externalOrderNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'externalOrderNo' }), width: 300, getCellValue: (row: Row) => row.externalOrderNo },
        { field: 'buyerSpq', dataTypeName: 'quantity', title: intl.formatMessage({ id: 'field.spq' }), width: 120, getCellValue: (row: Row) => intl.formatNumber(row.buyerSpq, { minimumFractionDigits: row.part?.decimalDigits, maximumFractionDigits: row.part?.decimalDigits }) },
        { field: 'qty', dataTypeName: 'quantity', title: intl.formatMessage({ id: 'field.qty' }), width: 120, getCellValue: (row: Row) => intl.formatNumber(row.qty, { minimumFractionDigits: row.part?.decimalDigits, maximumFractionDigits: row.part?.decimalDigits }) },
        { field: 'unit', dataTypeName: 'string', title: intl.formatMessage({ id: 'unit' }), width: 120, getCellValue: (row: Row) => row.part?.uom?.uomCode },
        { field: 'currency', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.currency' }), width: 120 },
        { field: 'price', dataTypeName: 'quantity', title: intl.formatMessage({ id: 'unitPrice' }), width: 150, getCellValue: (row: Row) => intl.formatNumber(row.price, { minimumFractionDigits: row?.decimalDigits, maximumFractionDigits: row?.decimalDigits }) },
        { field: 'amount', dataTypeName: 'quantity', title: intl.formatMessage({ id: 'amount' }), width: 150, getCellValue: (row: Row) => intl.formatNumber((row.price ?? 0) * (row.qty ?? 0), { minimumFractionDigits: row?.decimalDigits, maximumFractionDigits: row?.decimalDigits }) },
    ], [intl])

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <StringTypeProvider name="quantity" align="end" />
            <Data rows={displayDetails} columns={columns} />
            <ColumnFreeze />
            <ColumnVisibility
                defaultHiddenFields={['shipperPN', 'shipperPNDesc']}
                columnSettings={{
                    partsNo: { disableUserControl: true },
                    buyerPartsNo: { disableUserControl: true },
                }} ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
            <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        </DataGrid>
    </div >
}

const DataBlock = ({ title, colSpan = 1, rowSpan = 1, onClick, children }: { title: ReactNode, colSpan?: number, rowSpan?: number, onClick?: () => void, children?: ReactNode | ReactNode[] }) => {
    const styles = useStyles()
    return <div className={styles.dataArea} style={{ gridColumn: `span ${colSpan}`, gridRow: `span ${rowSpan}` }}>
        <Typography variant="subtitle2">{title}</Typography>
        <div onClick={onClick}>
            {children}
        </div>
    </div>
}


const DataBlockFlex = ({ title, colSpan = 1, rowSpan = 1, onClick, children }: { title: ReactNode, colSpan?: number, rowSpan?: number, onClick?: () => void, children?: ReactNode | ReactNode[] }) => {
    const styles = useStyles()
    return <div className={styles.dataArea} style={{ gridColumn: `span ${colSpan}`, gridRow: `span ${rowSpan}` }}>
        <Typography variant="subtitle2">{title}</Typography>
        <div onClick={onClick}>
            {children}
        </div>
    </div>
}

const checkIfHasValue = (name: string | undefined, amount: number | null | undefined) => (name || (amount !== null && amount !== undefined))

const useStyles = makeStyles(theme => ({
    coverPageContainer: {
        display: 'grid',
        gridTemplateColumns: `repeat(3, minmax(0, 1fr))`,
        gap: `${theme.spacing(2)}px`,
    },
    coverPageUp: {
        gridColumn: 'span 3',
        display: 'grid',
        gridTemplateColumns: `repeat(3, minmax(0, 1fr))`,
        gap: `${theme.spacing(2)}px`,
    },
    coverPageLeft: {
        gridColumn: 'span 2',
        display: 'grid',
        gridTemplateColumns: `repeat(2, minmax(0, 1fr))`,
        gap: `${theme.spacing(2)}px`,
    },
    coverPageRight: {
        '&>div': {
            display: 'grid',
            gridTemplateColumns: `repeat(1, minmax(0, 1fr))`,
            gap: `${theme.spacing(2)}px`,
        }
    },
    dataArea: {
        border: `1px dashed ${theme.palette.divider}`,
        borderRadius: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: theme.spacing(2),
        '&>*:nth-child(1)': {
            textTransform: 'uppercase',
            marginBottom: theme.spacing(2),
        },
        '&>*:nth-child(2)': {
            padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
            borderRadius: theme.spacing(1),
            backgroundColor: theme.palette.grey[200],
            minHeight: 37,
            boxSizing: 'border-box',
        },
    },
    editable: {
        '&>*:nth-child(2)': {
            border: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.common.white,
            cursor: 'pointer',
        },
    },
    text: {
        whiteSpace: 'break-spaces',
        overflowWrap: 'break-word',
    },
    divider: {
        width: '100%',
        alignItems: 'center',
        padding: theme.spacing(1, 0),
    },
    subDivider: {
        width: '100%',
        alignItems: 'center',
        padding: 5,
    }
}))