import { Project } from "../../../../utils/Project"
import { dateToJson } from "../../../utils/serializer"

export type IncomingDownloadFactor = Required & Partial<Optional>
type IncomingDownloadFactorJson = JsonRequired & Partial<JsonOptional>
export const incomingDownloadFactorToJson: Project<IncomingDownloadFactor, IncomingDownloadFactorJson> = obj => ({
    ...obj,
    inboundPlanDateStart: dateToJson(obj.inboundPlanDateStart),
    inboundPlanDateEnd: dateToJson(obj.inboundPlanDateEnd),
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'inboundPlanDateStart' | 'inboundPlanDateEnd'> {
    inboundPlanDateStart: Date,
    inboundPlanDateEnd: Date
}

interface JsonRequired {
}

interface JsonOptional {
    customerPartsIds: number[],
    groupings: string[],
    inboundPlanDateStart: string,
    inboundPlanDateEnd: string,
    status: number[],
    buyerList: string[]
}