import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"

export type CustomsInvoiceTransfer = Required & Partial<Optional>

type CustomsInvoiceTransferJson = JsonRequired & Partial<JsonOptional>

export const customsInvoiceTransferFromJson: Project<CustomsInvoiceTransferJson, CustomsInvoiceTransfer> = json => ({
    ...json,
    issuedDate: dateFromJson(json.issuedDate),
})

interface Required extends JsonRequired { }

interface Optional extends Omit<JsonOptional, 'issuedDate'> {
    issuedDate: Date,
}

interface JsonRequired {
    invoiceId: number,
    invoiceNo: string,
}

interface JsonOptional {
    invoiceId: number,
    expCcUid: string,
    invoiceNo: string,
    outboundNo: string,
    bookingNo: string,
    containerNo: string
    contractId: number,
    contractNo: string,
    shippingMode: number,
    loadingPortId: number,
    loadingPort: string,
    dischargePortId: number,
    dischargePort: string,
    issuedDate: string,
    status: number,
    role: number,
    expCargoStatus: string,
    impCargoStatus: string,
    version: number
}
