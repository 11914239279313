import { Button, FilledInput, InputAdornment, makeStyles } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { DataGridSearchInputProps } from "@rithe/data-grid/dist/components/searching/DataGridSearchInput";
import React from "react";

export function SearchInput(props: DataGridSearchInputProps) {
    const { value, setValue, operator, setOperator } = props

    const styles = useStyles()
    return <FilledInput
        value={value}
        onChange={e => setValue(e.currentTarget.value)}
        endAdornment={<InputAdornment position="end">
            <Button onClick={() => setOperator(operator === 'AND' ? 'OR' : 'AND')}>
                {operator}
            </Button>
            <Search />
        </InputAdornment>}
        inputProps={{
            className: styles.input,
        }}
        className={styles.root}
    />
}

const useStyles = makeStyles(theme => ({
    root: {
        width: 400,
        backgroundColor: theme.palette.background.search.main,
        marginRight: theme.spacing(1),
        '&:hover': {
            backgroundColor: theme.palette.background.search.hover,
        },
        '&$focused': {
            backgroundColor: theme.palette.background.search.focused,
        }
    },
    input: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    }
}))