import { Project } from "@rithe/utils";
import { dateToJson } from "../../utils/serializer";


export type SchedulesFactor = Required & Partial<Optional>;

type SchedulesFactorJson = JsonRequired & Partial<JsonOptional>;

export const schedulesFactorToJson: Project<SchedulesFactor, SchedulesFactorJson> = obj => ({
    ...obj,
    remindStartDate: dateToJson(obj.remindStartDate),
    remindEndDate: dateToJson(obj.remindEndDate),
})

interface Required extends JsonRequired { }

interface Optional extends Omit<JsonOptional, 'remindStartDate' | 'remindEndDate'> {
    remindStartDate: Date,
    remindEndDate: Date,
}

interface JsonRequired {
}

interface JsonOptional {
    userId: number,
    title: string,
    description: string,
    scheduleType: number,
    urgency: number,
    remindStartDate: string,
    remindEndDate: string,
}