import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"
import { ActiveFlag } from "../enums/ActiveFlag"

export type CalendarResult = Required & Partial<Optional> & TableEntity
type CalendarResultJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const calendarResultFromJson: Project<CalendarResultJson, CalendarResult> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
    activeFlag: ActiveFlag,
}

interface JsonRequired {
    calendarId: number,
}

interface JsonOptional {
    calendarCode: string,
    regionCode: string,
    companyId: number,
    description: string,
    activeFlag: number,
    companyCode: string,
}