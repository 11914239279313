import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"
import { ActiveFlag } from "../enums/ActiveFlag"

export type PortResult = Required & Partial<Optional> & TableEntity
type PortResultJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const portResultFromJson: Project<PortResultJson, PortResult> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
    activeFlag: ActiveFlag,
}

interface JsonRequired {
    portId: number,
}

interface JsonOptional {
    portCode: string,
    portName: string,
    regionCode: string,
    activeFlag: number,
}



