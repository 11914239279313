export enum NotificationAction {
    CONTRACT_REQUEST = 'CONTRACT_REQUEST',
    PLACE_ORDER = 'PLACE_ORDER',
    INCOMING_INBOUND = 'INCOMING_INBOUND',
    SHIPMENT_DELAY = 'SHIPMENT_DELAY',
    CUSTOMER_USAGE = 'CUSTOMER_USAGE',
    INVENTORY_ALERT = 'INVENTORY_ALERT',
    CHANGE_CANCEL_REQUEST_APPROVAL = 'CHANGE_CANCEL_REQUEST_APPROVAL',
    SUPPLIER_TO_CONFIRM_ORDER = 'SUPPLIER_TO_CONFIRM_ORDER',
    PERSONAL_TODO_LIST = 'PERSONAL_TODO_LIST',
    NEW_ORDER_REQUEST = 'NEW_ORDER_REQUEST',
    ORDER_CHANGE_CANCEL_REQUEST = 'ORDER_CHANGE_CANCEL_REQUEST',
    ORDER_FORCE_COMPLETE = 'ORDER_FORCE_COMPLETE',
    INBOUND_DELAY = 'INBOUND_DELAY',
    OUTBOUND_DELAY = 'OUTBOUND_DELAY',
    CONTAINER_REFRESH_STATUS = 'CONTAINER_REFRESH_STATUS',
}