import { useEffect, useState } from "react"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { useGetCustomerByLoginUser, useGetSellerByLoginUser } from "../../../services/smt/api/ComboboxApi"
import { useGetGrouping } from "../../../services/smt/api/CustomerUsageApi"
import { DirectInputDownloadFactor } from "../../../services/smt/models/DirectInputDownloadFactor"
import { Pair } from "../../../services/utils/Pair"
import { useGetCompanyType } from "../../../utils/ApplicationUtils"
import { CSUGS120PcUi } from "./CSUGS120PcUi"

export const CSUGS120 = () => {
    const [factor, setFactor] = useState<DirectInputDownloadFactor>({} as any)
    const [participantList, setParticipantList] = useState<Pair[]>([])
    const [groupings, setGroupings] = useState<string[]>([])

    const getCustomerList = useGetCustomerByLoginUser()
    const getSellerList = useGetSellerByLoginUser()
    const getGrouping = useGetGrouping()
    const cbstType = useGetCompanyType()

    useEffect(() => {
        if (cbstType === CbdsType.BU) {
            getCustomerList(undefined, { silent: true, serialized: true }).then((result) => {
                setParticipantList(result ?? [])
            })
        } else {
            getSellerList(undefined, { silent: true, serialized: true }).then((result) => {
                setParticipantList(result ?? [])
            })
        }

        getGrouping(undefined, { silent: true, serialized: true }).then((result) => {
            setGroupings(result ?? [])
        })
    }, [cbstType, getCustomerList, getGrouping, getSellerList])

    return <CSUGS120PcUi
        factor={factor}
        setFactor={setFactor}
        participantList={participantList}
        groupings={groupings}
    />
}