import { Objects } from "@rithe/utils"
import React, { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useComMasterGetActiveCbds, useComMasterGetAllUom } from "../../../services/common/apis/commonMasterApi"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { TnmUom } from "../../../services/master/models/TnmUom"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { useGetReceivedPCWCDetail } from "../../../services/order/apis/ChangeRequestApi"
import { ReceivedPCDetailResult, ReceivedPCPartsDetail } from "../../../services/order/models/ReceivedPCDetailResult"
import { addNumber, mulNumber } from "../../../utils/NumberUtil"
import { useMatch } from "../../../utils/useMatch"
import { OCS028PcUi } from "./OCS028PcUi"


export interface ReceivedPCDetailResultBasic extends ReceivedPCDetailResult {
    totalQty: number,
    totalAmount: number,
    totalNumberOfParts: number,
}

export const OCS028 = () => {

    const { path } = useMatch()
    const { roSpotId } = useParams() as any
    const mode = path === '/rc/edit-:roSpotId' ? ScreenMode.EDIT : ScreenMode.VIEW

    const [basic, setBasic] = useState<ReceivedPCDetailResultBasic>({} as ReceivedPCDetailResultBasic)
    const [inboundPlans, setInboundPlans] = useState<ReceivedPCPartsDetail[]>([] as ReceivedPCPartsDetail[])
    const [uomList, setUomList] = useState<TnmUom[]>([])
    const [receiveDcList, setReceiveDcList] = useState<TnvCbds[]>([])

    const splitDatas = useSplitDatas()
    const getData = useGetReceivedPCWCDetail()
    const getUomList = useComMasterGetAllUom()
    const getCbds = useComMasterGetActiveCbds()

    const search = useCallback((roSpotId: number | null | undefined) => {
        if (roSpotId) {
            getData({ roSpotId: roSpotId }, { silent: true }).then(result => {
                if (result) {
                    const { orderbasic, orderInboundPlans } = splitDatas(result)
                    setBasic(orderbasic)
                    setInboundPlans(orderInboundPlans)

                }
            })
        }
    }, [getData, splitDatas])

    useEffect(() => {
        getUomList(undefined, { silent: true }).then(result => {
            setUomList(result || [])
        })
        getCbds({ types: [] }, { silent: true }).then(result => {
            setReceiveDcList(result || [])
        })
        search(roSpotId)
    }, [getUomList, search, roSpotId, getCbds])

    return <OCS028PcUi
        search={search}
        basic={basic}
        setBasic={setBasic}
        inboundPlans={inboundPlans}
        setInboundPlans={setInboundPlans}
        mode={mode}
        uomList={uomList}
        receiveDcList={receiveDcList}
    />
}

const useSplitDatas = () => {
    return useCallback((basic: ReceivedPCDetailResult) => {
        const { totSoRoSpotDetailList } = basic
        const totalQty = totSoRoSpotDetailList.reduce((v1, v2) => addNumber(v1, v2.orderQtyInRo ? v2.orderQtyInRo : 0), 0)
        const totalAmount = totSoRoSpotDetailList.reduce((v1, v2) => addNumber(v1, (v2.orderQtyInRo && v2.sellingPrice ? mulNumber(v2.orderQtyInRo, v2.sellingPrice) : 0)), 0)
        const totalNumberOfParts = totSoRoSpotDetailList.length
        const orderbasic: ReceivedPCDetailResultBasic = { ...basic, totalQty, totalNumberOfParts, totalAmount }
        const orderInboundPlans: ReceivedPCPartsDetail[] = totSoRoSpotDetailList.map(parts => ({ ...parts }))
        return { orderbasic: orderbasic, orderInboundPlans: orderInboundPlans }
    }, [])
}

export const useMergeDatas = () => {
    return useCallback((orderbasic: ReceivedPCDetailResultBasic, orderInboundPlans: ReceivedPCPartsDetail[]) => {
        const basic = Objects.delete(orderbasic, 'totalQty', 'totalNumberOfParts', 'totalAmount') as ReceivedPCDetailResult
        return { ...basic, totSoRoSpotDetailList: orderInboundPlans }
    }, [])
}