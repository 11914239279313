import { Maps } from "@rithe/utils"
import moment from "moment"
import React, { useCallback, useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { useGetCalendarInfo, useGetCalendarWorkDateInfo } from "../../../services/master/apis/calendarApi"
import { WorkingFlag } from "../../../services/master/enums/WorkingFlag"
import { CalendarGetDateResult } from "../../../services/master/models/CalendarGetDateResult"
import { TnmCalendar } from "../../../services/master/models/TnmCalendar"
import { today } from "../../../utils/ApplicationUtils"
import { useMatch } from "../../../utils/useMatch"
import { MLS131PcUi } from "./MLS131PcUi"

export const MLS131 = () => {
    const { calendarId } = useParams() as any
    const { path, url } = useMatch()
    const { transferId, flagRoleCode } = useLocation().state as any ?? {}

    const pathFlag = (path === '/calendar/edit-:calendarId' || path === '/bu/edit-:buCode/calendar-edit-:calendarId' || path === '/customer/edit-:customerCode/calendar-edit-:calendarId'
        || path === '/supplier/edit-:supplierCode/calendar-edit-:calendarId' || path === '/dc/edit-:dcCode/calendar-edit-:calendarId') ? ScreenMode.EDIT :

        (path === '/calendar/create' || path === '/bu/create-:buCode' || path === '/customer/create-:customerCode' ||
            path === '/supplier/create-:supplierCode' || path === '/dc/create-:dcCode') ? ScreenMode.CREATE : ScreenMode.VIEW

    const [year, setYear] = useState<number>((today()).getFullYear())
    const [calendarInfo, setCalendarInfo] = useState<TnmCalendar>({} as TnmCalendar)
    const [orgDateList, setOrgDateList] = useState<CalendarGetDateResult[]>([])
    const [monthList, setMonthList] = useState<Record<string, CalendarGetDateResult[]>>({})

    const [mode, setMode] = useState<ScreenMode>(pathFlag)

    const getCalendarInfo = useGetCalendarInfo()
    const getWorkingDateInfo = useGetCalendarWorkDateInfo()

    const search = useCallback(() => {
        getCalendarInfo({ calendarId }, { silent: true, serialized: true }).then(result => {
            setCalendarInfo(result ?? {} as any)
        })
        getWorkingDateInfo({calendarId, year: year.toString()}, {silent: true, serialized: true}).then(result => {
            setOrgDateList(result ?? [])
            setMonthList(splitCalendars(year, result ?? []))
        })
    }, [calendarId, getCalendarInfo, getWorkingDateInfo, year])


    useEffect(() => {
        if (calendarId !== null && calendarId !== undefined) {
            search()
        } else if (ScreenMode.CREATE === mode) {
            setMonthList(splitCalendars(year, []))
        }
    }, [calendarId, search, getWorkingDateInfo, mode, year])

    return <MLS131PcUi
        url={url}
        path={path}
        year={year}
        setYear={setYear}
        calendarInfo={calendarInfo}
        orgDateList={orgDateList}
        setCalendarInfo={setCalendarInfo}
        monthList={monthList}
        setMonthList={setMonthList}
        mode={mode}
        setMode={setMode}
        search={search}
        flagRoleCode={flagRoleCode}
        transferId={transferId}
    />
}

const splitCalendars = (year: number, dateList: CalendarGetDateResult[]) => {

    const firstDate = moment(year + '-01-01')
    const lastDate = moment((year + 1) + '-01-01')
    const dateMap = Maps.from(dateList.map(k => [k.workingDate?.getTime(), k]))
    let months: Record<string, CalendarGetDateResult[]> = {}
    let dates = []
    let loopDate = firstDate
    let month = 0
    while (!loopDate.isAfter(lastDate)) {
        if (loopDate.month() !== month) {
            months['month' + month] = dates
            dates = []
            month = loopDate.month()
        }
        // put into dates
        const processDate = loopDate.toDate()
        dates.push({
            workingDate: processDate,
            workingFlag: dateMap.get(processDate.getTime())?.workingFlag ?? WorkingFlag.WORKING_DAY
        })
        // to next date
        loopDate.add(1, 'd')
    }

    return months
}
