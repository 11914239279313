import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"
import { ActiveFlag } from "../enums/ActiveFlag"
import { BusinessType } from "../enums/BusinessType"
import { ContractType } from "../enums/ContractType"

export type ContractResult = Required & Partial<Optional> & TableEntity
type ContractResultJson = JsonRequired & Partial<JsonOptional> & TableEntityJson

export const contractResultFromJson: Project<ContractResultJson, ContractResult> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
    contractType: ContractType,
    businessType: BusinessType,
    status: ActiveFlag,
}

interface JsonRequired {
    contractId: number,
    sellerCode: string,
    buyerCode: string,
    consignee: string,
    accountee: string,
    sellerUid: string,
    buyerUid: string,
}

interface JsonOptional {
    contractNo: string,
    contractShortCode: string,
    contractType: number,
    description: string,
    businessType: number,
    orderFrequency: number,
    paymentTermsId: string,
    paymentTermsCode: string,
    status: number,
    deliveryTo: string,
    receiverCode: string,
    confirmOrderLeadtime: number,
    leadtime: number,
    deliveryPlanStartDate: string,
    deliveryPlanEndDate: string,
    deliveryTypeFlag: number,
}