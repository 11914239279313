import React, { useCallback, useEffect, useRef, useState } from "react"
import { useGetCargoStatusSetting } from "../../../services/master/apis/cargoStatusSettingApi"
import { CargoStatusSettingList } from "../../../services/master/models/CargoStatusSettingList"
import { MLS250PcUi } from "./MLS250PcUi"

export const MLS250 = () => {
    const [data, setData] = useState<CargoStatusSettingList>()
    const getData = useGetCargoStatusSetting()
    const refresh = useCallback(() => {
        getData(undefined, { serialized: true, silent: true }).then(data => {
            setData(data)
        })
    }, [getData])
    const loadedRef = useRef(false)
    useEffect(() => {
        if (!loadedRef.current) {
            refresh()
            loadedRef.current = true
        }
    }, [refresh])

    return <MLS250PcUi data={data} refresh={refresh} />
}