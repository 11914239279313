import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"

export type TnmUom = Required & Partial<Optional> & TableEntity
type TnmUomJson = JsonRequired & Partial<JsonOptional> & TableEntityJson

export const tnmUomFromJson: Project<TnmUomJson, TnmUom> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
}

interface JsonRequired {
    uomCode: string,
}

interface JsonOptional {
    uomName: string,
    decimalDigits: number,
}