import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { PageViewEntity, PageViewEntityJson, pageViewEntityFromJson, pageViewEntityToJson } from "../../utils/PageViewEntity"

export type IntBatchView = Required & Partial<Optional>
export type IntBatchViewJson = JsonRequired & Partial<JsonOptional>
export const intBatchViewToJson: Project<IntBatchView, IntBatchViewJson> = obj => ({
    ...obj,
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page)
})
export const intBatchViewFromJson: Project<IntBatchViewJson, IntBatchView> = json => ({
    ...json,
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page)
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
    page: PageViewEntity,
}

interface JsonRequired {
}

interface JsonOptional {
    page: PageViewEntityJson,
}