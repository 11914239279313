import { useCallback, useEffect, useState } from "react"
import { useComMasterGetActiveCustomer, useComMasterGetAllCbds } from "../../../services/common/apis/commonMasterApi"
import { useGetOutboundToWIPPage } from "../../../services/delivery/apis/deliveryOutboundApi"
import { OutboundToWIPEntityList } from "../../../services/delivery/models/OutboundToWIPEntityList"
import { OutboundToWIPFactor } from "../../../services/delivery/models/OutboundToWIPFactor"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { TnmCustomer } from "../../../services/master/models/TnmCustomer"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { LOS040PcUi } from "./LOS040PcUi"


const defaultFilters = { page: { currentPage: 0, pageSize: 1000000 } }
export const LOS040 = () => {

    const [filters, setFilters] = useState<OutboundToWIPFactor>({ ...defaultFilters, outboundDateStart: new Date(), outboundDateEnd: new Date() })
    const [data, setData] = useState<OutboundToWIPEntityList[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [customerList, setCustomerList] = useState<TnmCustomer[]>([]);
    const [supplierList, setSupplierList] = useState<TnvCbds[]>([])

    const getOutboundToWIPList = useGetOutboundToWIPPage()

    const search = useCallback((filters: OutboundToWIPFactor) => {
        getOutboundToWIPList(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result.page?.totalCount || 0)
            setData(result.data ?? [])
        })
    }, [getOutboundToWIPList])

    useSearchOnLoad(search, filters)

    const getSupplierList = useComMasterGetAllCbds()

    const getCustomerList = useComMasterGetActiveCustomer()
    useEffect(() => {
        getCustomerList(undefined, { silent: true, serialized: false }).then((result) => {
            setCustomerList(result ?? [])
        })
        getSupplierList({ types: [CbdsType.SUPP] }, { silent: true, serialized: true }).then((result) => {
            setSupplierList(result ?? [])
        })
    }, [getCustomerList, getSupplierList])

    return <LOS040PcUi
        filters={filters}
        search={search}
        data={data}
        totalCount={totalCount}
        setFilters={setFilters}
        customerList={customerList}
        supplierList={supplierList}
    />
}