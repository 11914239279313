import React, { useCallback, useEffect, useState } from "react"
import { useGetContractRouteIdAndCodeList, useGetCustomerIdAndCodeList, useUpdateContractList } from "../../../services/managementTool/apis/mtApis"
import { DownloadInbPlanFactor } from "../../../services/managementTool/models/DownloadInbPlanFactor"
import { IdAndCode } from "../../../services/managementTool/models/IdAndCode"
import { MTS040PcUi } from "./MTS040PcUi"

export const MTS040 = () => {
    const [customerList, setCustomerList] = useState<IdAndCode[]>([])
    const [contractRouteList, setContractRouteList] = useState<IdAndCode[]>([])
    const [contractList, setContractList] = useState<IdAndCode[]>([])
    const [factor, setFactor] = useState<DownloadInbPlanFactor>({} as DownloadInbPlanFactor)

    const getCustomerList = useGetCustomerIdAndCodeList()
    useEffect(() => {
        getCustomerList(undefined, { silent: true }).then(result => {
            setCustomerList(result ?? [])
        })
    }, [getCustomerList])

    const getContractRouteList = useGetContractRouteIdAndCodeList()
    const findContractRouteList = useCallback((factor: DownloadInbPlanFactor) => {
        if (factor.customerId !== undefined && factor.customerId !== null) {
            getContractRouteList({ customerId: factor.customerId }, { silent: true, serialized: true }).then(result => {
                setContractRouteList(result ?? [])
            })
        } else {
            setContractRouteList([])
        }
    }, [getContractRouteList])

    const getContractList = useUpdateContractList()
    const findContractList = useCallback((factor: DownloadInbPlanFactor) => {
        if (factor.contractRouteId !== undefined && factor.contractRouteId !== null) {
            getContractList({ customerId: factor.customerId, contractRouteId: factor.contractRouteId }, { silent: true }).then(result => {
                setContractList(result ?? [])
            })
        } else {
            setContractList([])
        }
    }, [getContractList])

    return <MTS040PcUi
        customerList={customerList}
        contractRouteList={contractRouteList}
        contractList={contractList}
        factor={factor}
        setFactor={setFactor}
        findContractRouteList={findContractRouteList}
        findContractList={findContractList}
    />
}