import { Card, CardContent, useTheme } from "@material-ui/core"
import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { Break, DateItem, Form, NumberItem, Placeholder, StringItem } from "@rithe/form"
import { GridItem } from "@rithe/ui"
import { Arrays, Records } from "@rithe/utils"
import React, { memo, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { CallbackCardAction } from "../../../components/Action/CallbackCardAction"
import { BlackSimpleCard } from "../../../components/Card/BlackSimpleCard"
import { BlackSimpleCardContent } from "../../../components/Card/BlackSimpleCardContent"
import { BlackSimpleCardHeader } from "../../../components/Card/BlackSimpleCardHeader"
import { CardPanel } from "../../../components/Card/CardPanel"
import { CardTab } from "../../../components/Card/CardTab"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { TabsCard } from "../../../components/Card/TabsCard"
import { TabsCardContent } from "../../../components/Card/TabsCardContent"
import { TabsCardHeader } from "../../../components/Card/TabsCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DottedDivider } from "../../../components/Divider/DottedDivider"
import { CodeItem } from "../../../components/Form/CodeItem"
import { SectionTitle } from "../../../components/SectionTitle/SectionTitle"
import { View } from "../../../components/View/View"
import { OutboundDatasource } from "../../../services/delivery/enums/OutboundDatasource"
import { ContainerInfo, InnerPackageInfo, OutboundMonitorInfo, OutboundPartsInfo, OuterPackageInfo } from "../../../services/delivery/models/OutboundMonitorInfo"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"

export const LOS031PcUi = (props: { outboundInfo: OutboundMonitorInfo, hugeData: boolean }) => {
    const { outboundInfo, hugeData } = props
    const intl = useIntl()

    const [gridMode, setGridMode] = useState<boolean>(true)

    return <View >
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={1}
                title={intl.formatMessage({ id: 'viewOutboundBasic' })}
                subtitle={intl.formatMessage({ id: 'viewOutboundBasicSub' })}
            />
            <SectionCardContent>
                <OutboundBaiscView outboundInfo={outboundInfo} />
            </SectionCardContent>
        </SectionCard>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={2}
                title={intl.formatMessage({ id: 'viewOutboundParts' })}
                subtitle={intl.formatMessage({ id: 'viewOutboundPartsSub' })}
                actions={hugeData ? [] : [<ChangeModeAction gridMode={gridMode} setGridMode={setGridMode} />]}
            />
            <SectionCardContent>
                {gridMode ? <ContainerPackageGridView outboundInfo={outboundInfo} />
                    : <ContainerPackageView outboundInfo={outboundInfo} />}
            </SectionCardContent>
        </SectionCard>
    </View>
}

const OutboundBaiscView = ({ outboundInfo }: {
    outboundInfo: OutboundMonitorInfo,
}) => {
    const intl = useIntl()
    return outboundInfo.datasource === OutboundDatasource.INTERNAL ?
        <Form data={outboundInfo} readonly labelDisplay="block" helperDisplay="tooltip" >
            <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="TripInfo" /></SectionTitle></GridItem>
            <StringItem field="outboundNo" label={intl.formatMessage({ id: 'field.outboundNo' })} />
            <StringItem field="outboundRefNo" label={intl.formatMessage({ id: 'field.outboundRefNo' })} />
            <StringItem field="receiver" label={intl.formatMessage({ id: 'receiverCode' })} />
            <CodeItem field="outboundType" label={intl.formatMessage({ id: 'field.outboundType' })} code={CodeCategory.UploadOutboundType} />
            <DateItem field="outboundDate" label={intl.formatMessage({ id: 'field.outboundDate' })}></DateItem>
            <StringItem field="outboundTime" label={intl.formatMessage({ id: 'field.outboundTime' })}></StringItem>
            <DateItem field="etd" label={intl.formatMessage({ id: 'fullEtd' })}></DateItem>
            <DateItem field="eta" label={intl.formatMessage({ id: 'fullEta' })}></DateItem>
            <CodeItem field="shippingMode" label={intl.formatMessage({ id: 'field.shippingMode' })} code={CodeCategory.ShippingMode} />
            <StringItem field="bookingNo" label={intl.formatMessage({ id: 'field.bookingNo' })} />
        </Form>
        : <Form data={outboundInfo} readonly labelDisplay="block" helperDisplay="tooltip" >
            <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="TripInfo" /></SectionTitle></GridItem>
            <StringItem field="outboundNo" label={intl.formatMessage({ id: 'field.outboundNo' })} />
            <StringItem field="outboundRefNo" label={intl.formatMessage({ id: 'field.outboundRefNo' })} />
            <StringItem field="receiver" label={intl.formatMessage({ id: 'receiverCode' })} />
            <DateItem field="outboundDate" label={intl.formatMessage({ id: 'field.outboundDate' })}></DateItem>
            <DateItem field="etd" label={intl.formatMessage({ id: 'fullEtd' })}></DateItem>
            <DateItem field="eta" label={intl.formatMessage({ id: 'fullEta' })}></DateItem>
            <CodeItem field="shippingMode" label={intl.formatMessage({ id: 'field.shippingMode' })} code={CodeCategory.ShippingMode} />
        </Form>
}

const ChangeModeAction = ({ gridMode, setGridMode }: {
    gridMode: boolean,
    setGridMode: React.Dispatch<React.SetStateAction<boolean>>,
}) => {

    const onChangeMode = useCallback(() => {
        setGridMode(mode => !mode)
    }, [setGridMode])

    return <CallbackCardAction callback={onChangeMode} title={gridMode ? <FormattedMessage id="changeToTreeMode" /> : <FormattedMessage id="changeToGridMode" />} />
}

const ContainerPackageView = ({ outboundInfo }: {
    outboundInfo: OutboundMonitorInfo,
}) => {

    const theme = useTheme()
    const { containers } = outboundInfo

    return <>
        <PartsSummaryView containers={containers ?? []} />
        <ContainerPackagesSummaryView containers={containers ?? []} />
        <DottedDivider style={{ marginTop: theme.spacing(4), marginBottom: theme.spacing(4) }} />
        <ContainerPackagesTabView containers={containers ?? []} />
    </>
}

const PartsSummaryView = memo(({ containers }: {
    containers: ContainerInfo[]
}) => {

    const intl = useIntl()
    const theme = useTheme()
    const partsSummaryList = useMemo(() => {
        const partsList = containers.flatMap(f => f.outerPackageList ?? []).flatMap(f => f.innerPackageList ?? []).flatMap(f => f.partsList ?? [])
        const partsNoList = Arrays.distinct(partsList.map(m => m.partsNo))
        return partsNoList.map((partsNo, index) => {
            const filterList = partsList.filter(f => f.partsNo === partsNo)
            return {
                rowNo: index + 1,
                partsNo: partsNo,
                partsName: filterList[0].sellerPartsName,
                qty: filterList.map(m => m.qty ?? 0).reduce((a, b) => a + b, 0),
            }
        })
    }, [containers])
    const columns = useMemo(() => [
        { field: 'rowNo', dataTypeName: 'number', title: intl.formatMessage({ id: 'rowNo' }), width: 180 },
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 400 },
        { field: 'partsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsName' }), width: 400 },
        { field: 'qty', dataTypeName: 'number', title: intl.formatMessage({ id: 'totalQuantity' }), width: 300 }
    ], [intl])

    return <div style={{ width: '100%', height: 300, marginBottom: theme.spacing(2) }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <Data rows={partsSummaryList} columns={columns} />
            <ColumnFreeze />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Sorting />
            <Filtering />
            <Paging defaultPageSize={15} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        </DataGrid>
    </div>
})

const ContainerPackagesSummaryView = memo(({ containers }: {
    containers: ContainerInfo[]
}) => {
    const intl = useIntl()
    const containerPackageSummary = useMemo(() => {
        const packageList = containers.flatMap(m => m.outerPackageList ?? [])
        return {
            numOfContianer: containers.length,
            contentOfContainer: Arrays.distinct(containers.map(m => m.commodityType ?? '')).join(','),
            typeOfContainer: Arrays.distinct(containers.map(m => m.containerType ?? '')).join(','),
            totalGWOfContianer: containers.map(m => m.grossWeight ?? 0).reduce((a, b) => a + b, 0),
            numOfPackage: packageList.length,
            totalM3OfPackage: packageList.map(m => m.m3 ?? 0).reduce((a, b) => a + b, 0),
            totalNWOfPackage: packageList.map(m => m.netWeight ?? 0).reduce((a, b) => a + b, 0),
            totalGWOfPackage: packageList.map(m => m.grossWeight ?? 0).reduce((a, b) => a + b, 0),
        }
    }, [containers])

    return <Card>
        <CardContent>
            <Form data={containerPackageSummary} labelDisplay="block" helperDisplay="tooltip" columnCount={2}>
                <GridItem columnSpan={2}><SectionTitle size="small">{intl.formatMessage({ id: 'containerInfo' })}</SectionTitle></GridItem>
                <NumberItem field="numOfContianer" readonly label={intl.formatMessage({ id: 'numOfContianer' })} />
                <StringItem field="contentOfContainer" readonly label={intl.formatMessage({ id: 'contentOfContainer' })} />
                <StringItem field="typeOfContainer" readonly label={intl.formatMessage({ id: 'typeOfContainer' })} />
                <NumberItem field="totalGWOfContianer" readonly label={intl.formatMessage({ id: 'totalGWOfContianer' })} />

                <GridItem columnSpan={2}><SectionTitle size="middle">{intl.formatMessage({ id: 'packageInfo' })} </SectionTitle></GridItem>
                <NumberItem field="numOfPackage" readonly label={intl.formatMessage({ id: 'numOfPackage' })} />
                <NumberItem field="totalM3OfPackage" readonly label={intl.formatMessage({ id: 'totalM3OfPackage' })} />
                <NumberItem field="totalNWOfPackage" readonly label={intl.formatMessage({ id: 'totalNWOfPackage' })} />
                <NumberItem field="totalGWOfPackage" readonly label={intl.formatMessage({ id: 'totalGWOfPackage' })} />
            </Form>
        </CardContent>
    </Card>
})

const ContainerPackagesTabView = memo(({ containers }: {
    containers: ContainerInfo[],
}) => {

    const [containerIndex, setContainerIndex] = useState<number>(0)

    const onSelectedValueChange = useCallback((value: string | number) => {
        setContainerIndex(value as number)
    }, [setContainerIndex])

    return <TabsCard>
        <TabsCardHeader selectedValue={containerIndex} onSelectedValueChange={onSelectedValueChange} >
            {containers.map((m, index) => <CardTab title={'Container ' + (index + 1)} subtitle={m.containerNo} value={index} />)}
        </TabsCardHeader>
        <TabsCardContent>
            <CardPanel value={containerIndex}>
                <ContainerPackageCardView container={containers[containerIndex]} />
            </CardPanel>
        </TabsCardContent>
    </TabsCard>
})

const ContainerPackageCardView = memo(({ container }: {
    container: ContainerInfo,
}) => {

    const { outerPackageList } = container

    return <>
        <ContainerInfoView container={container} />
        <OutPackageListView outerPackageList={outerPackageList ?? []} />
    </>
})

const ContainerInfoView = memo(({ container }: {
    container: ContainerInfo,
}) => {

    const getNumberOfCarton = useCallback(() => {
        return container.outerPackageList?.length ?? 0
    }, [container.outerPackageList?.length])

    const getPartsQty = useCallback(() => {
        const partsList = (container.outerPackageList ?? []).flatMap(m => m.innerPackageList ?? []).flatMap(m => m.partsList)
        return partsList.map(m => m.qty ?? 0).reduce((a, b) => a + b, 0)
    }, [container.outerPackageList])

    return <Form readonly data={container} labelDisplay="block" helperDisplay="tooltip" >
        <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="containerInfo" /></SectionTitle></GridItem>
        <StringItem field="containerType" label={<FormattedMessage id='typeOfContainer' />} />
        <NumberItem field="numberOfCarton" readonly label={<FormattedMessage id='numberOfCarton' />} getValue={getNumberOfCarton} />
        <StringItem field="commodityType" label={<FormattedMessage id='typeOfCommodities' />} />

        <NumberItem field="qty" readonly label={<FormattedMessage id='quantity' />} getValue={getPartsQty} />
        <StringItem field="containerNo" label={<FormattedMessage id='field.containerNo' />} />
        <StringItem field="sealNo" label={<FormattedMessage id='field.sealNo' />} />

        <NumberItem field="m3" label={<FormattedMessage id='containerM3' />} />
        <NumberItem field="netWeight" label={<FormattedMessage id='containerNW' />} />
        <NumberItem field="grossWeight" label={<FormattedMessage id='containerGW' />} />
    </Form>
})

const OutPackageListView = memo(({ outerPackageList }: {
    outerPackageList: OuterPackageInfo[],
}) => {

    const theme = useTheme()
    return <div style={{ width: '100%', paddingTop: theme.spacing(2) }}>
        <GridItem><SectionTitle size="middle"><FormattedMessage id="withInContainerOuterPackage" /></SectionTitle></GridItem>
        {outerPackageList?.map((outerPackage, index) => {
            return <GridItem>
                <OutPackageInfoCardView
                    outerPackageIndex={index}
                    outerPackage={outerPackage}
                />
            </GridItem>
        })}
    </div>
})

const OutPackageInfoCardView = memo(({ outerPackage, outerPackageIndex, }: {
    outerPackage: OuterPackageInfo,
    outerPackageIndex: number,
}) => {

    const { outerPackageNo, innerPackageList } = outerPackage
    const hasBlank = useMemo(() => innerPackageList.some(f => f.boxNo === ''), [innerPackageList])
    return <BlackSimpleCard allowCollapse defaultCollapse>
        <BlackSimpleCardHeader title={'Outer Package ' + (outerPackageIndex + 1) + ': ' + (outerPackageNo && outerPackageNo !== '' ? outerPackageNo : '')} />
        <BlackSimpleCardContent>
            <OutPackageBasicInfoView outerPackage={outerPackage} />
            {hasBlank && <WithInOutboundPackagePartsView innerPackageList={innerPackageList} />}
            <WithInOutboundPackageInnerPackageView innerPackageList={innerPackageList} />
        </BlackSimpleCardContent>
    </BlackSimpleCard>
})

const OutPackageBasicInfoView = memo(({ outerPackage }: {
    outerPackage: OuterPackageInfo,
}) => {

    return <Form readonly data={outerPackage} labelDisplay="block" helperDisplay="tooltip" >
        <StringItem field="outerPackageNo" label={<FormattedMessage id='outerPackageNo' />} />
        <StringItem field="palletNo" label={<FormattedMessage id='palletNo' />} />
        <StringItem field="outerPackageType" label={<FormattedMessage id='outerPackageType' />} />
        <DateItem field="productionDate" label={<FormattedMessage id='productionDate' />} />

        <NumberItem field="m3" label={<FormattedMessage id='packageM3' />} />
        <NumberItem field="netWeight" label={<FormattedMessage id='packageNW' />} />
        <NumberItem field="grossWeight" label={<FormattedMessage id='packageGW' />} />
    </Form>
})

const WithInOutboundPackagePartsView = memo(({ innerPackageList }: {
    innerPackageList: InnerPackageInfo[],
}) => {

    const theme = useTheme()
    const partsList = useMemo(() => {
        const innerPackage = innerPackageList.find(f => f.boxNo === '')
        return innerPackage?.partsList ?? []
    }, [innerPackageList])

    return <div style={{ width: '100%', padding: theme.spacing(2, 0) }}>
        <GridItem><SectionTitle size="middle"><FormattedMessage id="withOuterPackageParts" /></SectionTitle></GridItem>
        <GridItem >
            <PartsInfoListView partsList={partsList} />
        </GridItem>
    </div>
})

const WithInOutboundPackageInnerPackageView = memo(({ innerPackageList }: {
    innerPackageList: InnerPackageInfo[],
}) => {

    const theme = useTheme()
    const hasBlank = useMemo(() => innerPackageList.some(f => f.boxNo === ''), [innerPackageList])

    return <div style={{ width: '100%', padding: theme.spacing(2, 0) }}>
        <GridItem><SectionTitle size="middle"><FormattedMessage id="withOuterPackageInnerPackage" /></SectionTitle></GridItem>
        {innerPackageList.map((innerPackage, index) => {
            return innerPackage.boxNo === '' ? <></> : <GridItem >
                <InnerPackageInfoCardView innerPackage={innerPackage} hasBlank={hasBlank} innerPackageIndex={index} />
            </GridItem>
        })}
    </div>
})

const InnerPackageInfoCardView = memo(({ innerPackage, hasBlank, innerPackageIndex }: {
    innerPackage: InnerPackageInfo,
    hasBlank: boolean,
    innerPackageIndex: number
}) => {

    const { boxNo, partsList } = innerPackage

    return <BlackSimpleCard allowCollapse defaultCollapse>
        <BlackSimpleCardHeader title={'Inner Package ' + (hasBlank ? innerPackageIndex : innerPackageIndex + 1) + ': ' + (boxNo && boxNo !== '' ? boxNo : '')} />
        <BlackSimpleCardContent>
            <InnerPackageBasicInfoView innerPackage={innerPackage} />
            <PartsInfoListView partsList={partsList} />
        </BlackSimpleCardContent>
    </BlackSimpleCard>
})

const InnerPackageBasicInfoView = memo(({ innerPackage }: {
    innerPackage: InnerPackageInfo,
}) => {

    return <Form readonly data={innerPackage} labelDisplay="block" helperDisplay="tooltip" >
        <StringItem field="boxNo" label={<FormattedMessage id='innerPacakgeNo' />} />
        <StringItem field="ipPackageType" label={<FormattedMessage id='innerPacakgeType' />} />
        <Placeholder />

        <NumberItem field="m3" label={<FormattedMessage id='innerPacakgeM3' />} />
        <NumberItem field="netWeight" label={<FormattedMessage id='innerPacakgeNw' />} />
        <NumberItem field="grossWeight" label={<FormattedMessage id='innerPacakgeGw' />} />
        <Break />
    </Form>
})

const PartsInfoListView = memo(({ partsList }: {
    partsList: OutboundPartsInfo[],
}) => {

    const intl = useIntl()
    const columns = useMemo(() => [
        { field: 'sellerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'sellerPartsNo' }), width: 220 },
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 280 },
        { field: 'sellerPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'buyerPartsNo' }), width: 220 },
        { field: 'sellerBackNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'backNo' }), width: 180 },
        { field: 'colorCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'colorCode' }), width: 180 },
        { field: 'soNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'salesOrderNo' }), width: 280 },
        { field: 'qty', dataTypeName: 'number', title: intl.formatMessage({ id: 'quantity' }), width: 150 },
    ], [intl])

    const getRowId = useCallback((row: any) => row.soDetailId, [])

    return <div style={{ width: '100%', height: 300 }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <DataTypePreset />
            <Data rows={partsList} columns={columns} getRowId={getRowId} />
            <ColumnFreeze />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <ColumnVisibility
                defaultHiddenFields={['sellerPartsName', 'sellerBackNo', 'colorCode']}
                columnSettings={{
                    companyCode: { disableUserControl: true },
                    companyName: { disableUserControl: true },
                }}
                ToolbarButton={ColumnVisibilityToolbarButton} />
            <Sorting />
            <Filtering />
        </DataGrid>
    </div>
})


const ContainerPackageGridView = ({ outboundInfo }: {
    outboundInfo: OutboundMonitorInfo,
}) => {
    const partsList = useMemo(() => {
        const contPartsList: any[] = []
        outboundInfo.containers?.forEach(container => {
            container.outerPackageList?.forEach(pkg => {
                pkg.innerPackageList.forEach(box => {
                    box.partsList.forEach(part => {
                        contPartsList.push({
                            ...container,
                            ...pkg,
                            ...box,
                            ...part,
                            m3Container: container.m3,
                            netWeightContainer: container.netWeight,
                            grossWeightContainer: container.grossWeight,
                            m3Outer: pkg.m3,
                            netWeightOuter: pkg.netWeight,
                            grossWeightOuter: pkg.grossWeight,
                            m3Inner: box.m3,
                            netWeightInner: box.netWeight,
                            grossWeightInner: box.grossWeight,
                        })
                    })
                })
            })
        })
        return contPartsList
    }, [outboundInfo.containers])

    const intl = useIntl()
    const containerCategories = useMemo(() => [{ key: 'container', value: intl.formatMessage({ id: 'containerInfo' }) }], [intl])
    const outerPacakgeCategories = useMemo(() => [{ key: 'outerPackage', value: intl.formatMessage({ id: 'outerPkgInfo' }) }], [intl])
    const innerPacakgeCategories = useMemo(() => [{ key: 'innerPackage', value: intl.formatMessage({ id: 'innerPkgInfo' }) }], [intl])
    const columns = useMemo(() => [
        { field: 'sellerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsNo' }), width: 220 },
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 220 },
        { field: 'sellerPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsName' }), width: 280 },
        { field: 'sellerBackNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.backNo' }), width: 180 },
        { field: 'colorCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'colorCode' }), width: 180 },
        { field: 'buyerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'buyerCode' }), width: 180 },
        { field: 'sellerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'sellerCode' }), width: 180 },
        { field: 'soNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'salesOrderNo' }), width: 220 },
        { field: 'srbq', dataTypeName: 'number', title: intl.formatMessage({ id: 'srbq' }), width: 180 },
        { field: 'qty', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.qty' }), width: 180 },

        { field: 'containerNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'cntTruNo' }), categories: containerCategories, width: 240 },
        { field: 'containerType', dataTypeName: 'string', title: intl.formatMessage({ id: 'cntTruType' }), categories: containerCategories, width: 240 },
        { field: 'commodityType', dataTypeName: 'string', title: intl.formatMessage({ id: 'typeOfCommodities' }), categories: containerCategories, width: 200 },
        { field: 'sealNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sealNo' }), categories: containerCategories, width: 240 },
        { field: 'm3Container', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.m3' }), categories: containerCategories, width: 180 },
        { field: 'netWeightContainer', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.netWeight' }), categories: containerCategories, width: 220 },
        { field: 'grossWeightContainer', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.grossWeight' }), categories: containerCategories, width: 220 },
        { field: 'palletNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'palletNo' }), categories: outerPacakgeCategories, width: 240 },
        { field: 'outerPackageNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'outerPkgNo' }), categories: outerPacakgeCategories, width: 240 },
        { field: 'outerPackageType', dataTypeName: 'string', title: intl.formatMessage({ id: 'outerPkgType' }), categories: outerPacakgeCategories, width: 200 },
        { field: 'productionDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'productionDate' }), categories: outerPacakgeCategories, width: 200 },
        { field: 'm3Outer', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.m3' }), categories: outerPacakgeCategories, width: 200 },
        { field: 'netWeightOuter', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.netWeight' }), categories: outerPacakgeCategories, width: 220 },
        { field: 'grossWeightOuter', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.grossWeight' }), categories: outerPacakgeCategories, width: 220 },
        { field: 'boxNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'innerPkgNo' }), categories: innerPacakgeCategories, width: 240 },
        { field: 'ipPackageType', dataTypeName: 'string', title: intl.formatMessage({ id: 'innerPkgType' }), categories: innerPacakgeCategories, width: 200 },
        { field: 'm3Inner', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.m3' }), categories: innerPacakgeCategories, width: 200 },
        { field: 'netWeightInner', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.netWeight' }), categories: innerPacakgeCategories, width: 220 },
        { field: 'grossWeightInner', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.grossWeight' }), categories: innerPacakgeCategories, width: 220 },
    ], [containerCategories, innerPacakgeCategories, intl, outerPacakgeCategories])

    return <div style={{ width: '100%', height: 460 }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <Data rows={partsList} columns={columns} />
            <ColumnFreeze />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <ColumnVisibility
                defaultHiddenFields={['sellerPartsName', 'sellerBackNo', 'colorCode', 'srbq',
                    'commodityType', 'sealNo', 'm3Container', 'netWeightContainer', 'grossWeightContainer',
                    'm3Outer', 'netWeightOuter', 'grossWeightOuter', 'm3Inner', 'netWeightInner', 'grossWeightInner']}
                columnSettings={{
                    companyCode: { disableUserControl: true },
                    companyName: { disableUserControl: true },
                }}
                ToolbarButton={ColumnVisibilityToolbarButton} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
            <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        </DataGrid>
    </div>
}
