import { Project } from "../../../utils/Project"
import { dateToJson } from "../../utils/serializer"

export type InputDownloadDataRangeFactor = Required & Partial<Optional>
type InputDownloadDataRangeFactorJson = JsonRequired & Partial<JsonOptional>
export const inputDownloadDataRangeFactorToJson: Project<InputDownloadDataRangeFactor, InputDownloadDataRangeFactorJson> = obj => ({
    ...obj,
    stockDate: dateToJson(obj.stockDate),
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'stockDate' | 'includePlanFlag'> {
    stockDate: Date,
    includePlanFlag: boolean,
    simulateByParts: boolean
    baseCarrierDate: boolean
}

interface JsonRequired {
}

interface JsonOptional {
    stockDate: string,
    customerPartsIdList: number[],
    includePlanFlag: boolean,
    simulateByParts: boolean
    baseCarrierDate: boolean
}