import { useCallback } from "react"
import { usePost } from "../../../../utils/fetch/useFetch"
import { PageResultEntity, pageResultEntityFromJson } from "../../../utils/PageResultEntity"
import { CustomerStockList, customerStockFromJson } from "../../models/CustomerStockList"
import { CustomerStockListFactor, customerStockListFactorToJson } from "../../models/CustomerStockListFactor"
import { DailyRundownList, dailyRundownFromJson } from "../../models/DailyRundownList"
import { DailyRundownListFactor, dailyRundownListFactorToJson } from "../../models/DailyRundownListFactor"
import { ImpInventoryList, impInventoryFromJson } from "../../models/ImpInventoryList"
import { ImpInventoryListFactor, impInventoryListFactorToJson } from "../../models/ImpInventoryListFactor"
import { ImpInventoryListForCust, impInventoryForCustFromJson } from "../../models/ImpInventoryListForCust"
import { StockAdjustmentList, stockAdjustmentFromJson } from "../../models/StockAdjustmentList"
import { StockAdjustmentListFactor, stockAdjustmentListFactorToJson } from "../../models/StockAdjustmentListFactor"
import { StockAdjustmentListForCust, stockAdjustmentForCustFromJson } from "../../models/StockAdjustmentListForCust"
import { DailyRundownFactor, dailyRundownFactorToJson } from "../models/DailyRundownFactor"
import { DailyRundownResult, dailyRundownResultFromJson } from "../models/DailyRundownResult"
import { WeeklyRundownFactor, weeklyRundownFactorToJson } from "../models/WeeklyRundownFactor"
import { WeeklyRundownResult, weeklyRundownResultFromJson } from "../models/WeeklyRundownResult"

//Get Weekly Rundown list
export const useGetWeeklyRundownList = () => {
    const url = '/smt-api/stockmng/getRundownlist'
    const payloadProject = useCallback(weeklyRundownFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(weeklyRundownResultFromJson), [])
    return usePost<WeeklyRundownFactor, PageResultEntity<WeeklyRundownResult>>(url, payloadProject, resultProject)
}

// Daily Rundown List(SMGTS210)
export const useGetDailyRundownList = () => {
    const url = '/smt-api/stockmng/getDailyRundownList'
    const payloadProject = useCallback(dailyRundownListFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(dailyRundownFromJson), [])
    return usePost<DailyRundownListFactor, PageResultEntity<DailyRundownList>>(url, payloadProject, resultProject)
}

// Daily Rundown Stock Detail(SMGTS211)// { customerPartsIdList: number[] } // (obj: { customerPartsIdList: number[] }) => obj.customerPartsIdList, []
export const useGetDailyRundownStock = () => {
    const url = '/smt-api/stockmng/getDailyRundownStock'
    const payloadProject = useCallback(dailyRundownFactorToJson, [])
    const resultProject = useCallback(r => r.map(dailyRundownResultFromJson)[0], [])
    return usePost<DailyRundownFactor, DailyRundownResult>(url, payloadProject, resultProject)
}

// Customer Stock List(SMGTS310)
export const useGetCustomerStockList = () => {
    const url = '/smt-api/stockmng/getCustomerStockList'
    const payloadProject = useCallback(customerStockListFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(customerStockFromJson), [])
    return usePost<CustomerStockListFactor, PageResultEntity<CustomerStockList>>(url, payloadProject, resultProject)
}

// Stock Adjustment List(SMGTS410)
export const useGetStockAdjustmentList = () => {
    const url = '/smt-api/stockmng/getStockAdjustmentList'
    const payloadProject = useCallback(stockAdjustmentListFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(stockAdjustmentFromJson), [])
    return usePost<StockAdjustmentListFactor, PageResultEntity<StockAdjustmentList>>(url, payloadProject, resultProject)
}

// Stock Adjustment List For Customer(SMGTS410)
export const useGetStockAdjustmentListForCustomer = () => {
    const url = '/smt-api/stockmng/getStockAdjustmentListForCust'
    const payloadProject = useCallback(stockAdjustmentListFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(stockAdjustmentForCustFromJson), [])
    return usePost<StockAdjustmentListFactor, PageResultEntity<StockAdjustmentListForCust>>(url, payloadProject, resultProject)
}


// Imp Inventory History List(SMGTS610)
export const useGetImpInventoryList = () => {
    const url = '/smt-api/stockmng/getStockHistoryList'
    const payloadProject = useCallback(impInventoryListFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(impInventoryFromJson), [])
    return usePost<ImpInventoryListFactor, PageResultEntity<ImpInventoryList>>(url, payloadProject, resultProject)
}

// Imp Inventory History List For Customer(SMGTS610)
export const useGetImpInventoryListForCust = () => {
    const url = '/smt-api/stockmng/getStockHistoryListForCust'
    const payloadProject = useCallback(impInventoryListFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(impInventoryForCustFromJson), [])
    return usePost<ImpInventoryListFactor, PageResultEntity<ImpInventoryListForCust>>(url, payloadProject, resultProject)
}
