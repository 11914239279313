import { IconButton, Tooltip } from "@material-ui/core"
import AttachFileIcon from '@material-ui/icons/AttachFile'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import TimerIcon from '@material-ui/icons/Timer'
import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, ObjectTypeProvider, PaginationLayout, Paging, Row, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { ObjectFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/ObjectFormatter"
import { DateRangeItem, StringItem } from "@rithe/form"
import { Arrays, Comparator, Records } from "@rithe/utils"
import moment from "moment"
import React, { useCallback, useMemo, useState } from "react"
import { FormattedDate, FormattedMessage, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { Access } from "../../../components/Access/Access"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { EditRedirectRowAction } from "../../../components/DataGrid/rowActions/EditRedirectRowAction"
import { ViewCallbackRowAction } from "../../../components/DataGrid/rowActions/ViewCallbackRowAction"
import { CancelCallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/CancelCallbackToolbarAction"
import { CreateRedirectToolbarAction } from "../../../components/DataGrid/toolbarActions/CreateRedirectToolbarAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { TodoListProrityProvider } from "../../../components/DataGrid/typeProviders/TodoListProrityProvider"
import { TodoListTypeProvider } from "../../../components/DataGrid/typeProviders/TodoListTypeProvider"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { TodoListTypeMode } from "../../../services/common/enums/TodoListTypeMode"
import { useDownloadTDL, useUpdateById } from "../../../services/delivery/apis/deliveryToDoListApi"
import { TnmUserToListFilter } from "../../../services/delivery/models/TnmUserToList"
import { TnmUserToListChangeRequestResult } from "../../../services/delivery/models/TnmUserToListChangeRequestResult"
import { TnmUserToListInboundResult } from "../../../services/delivery/models/TnmUserToListInboundResult"
import { TnmUserToListOutboundResult } from "../../../services/delivery/models/TnmUserToListOutboundResult"
import { TnmUserToListPlaceOrderResult } from "../../../services/delivery/models/TnmUserToListPlaceOrderResult"
import { TnmUserToListRequestResult } from "../../../services/delivery/models/TnmUserToListRequestResult"
import { TnmUserToListResult } from "../../../services/delivery/models/TnmUserToListResult"
import { TnmUserToListShipmentDealyResult } from "../../../services/delivery/models/TnmUserToListShipmentDealyResult"
import { TnmUserToListSupplierConfirm } from "../../../services/delivery/models/TnmUserToListSupplierConfirm"
import { TnmUserToListView } from "../../../services/delivery/models/TnmUserToListView"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { TodoListStatus } from "../../../services/master/enums/TodoListStatus"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { placeOrderDetailFactorSerializer } from "../../../services/order/models/PlaceOrderDetailFactor"
import { TodoScheduleType } from "../../../services/others/enums/TodoScheduleType"
import { dateFromJson } from "../../../services/utils/deserializer"
import { dateToJson } from "../../../services/utils/serializer"
import { today } from "../../../utils/ApplicationUtils"
import { useGetCodeName } from "../../../utils/CodeCategoryUtil"
import sessionKeys from "../../../utils/sessionKeys"
import { useSetDataIntoSession } from "../../../utils/sessionUtil"
import Switches from "./Switch"

interface TODOLISTPcUiProps {
    filters: TnmUserToListFilter,
    setFilters: React.Dispatch<React.SetStateAction<TnmUserToListFilter>>,
    data: TnmUserToListResult[],
    totalCount: number,
    search: (filters: TnmUserToListFilter) => void,
    companyList: TnvCbds[],
}

export const TODOLISTPcUi = (props: TODOLISTPcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({ filters, data, setFilters, search, companyList }: TODOLISTPcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<string[]>([])

    const columns = useMemo(() => [
        { field: 'task', dataTypeName: 'stringFormat', title: intl.formatMessage({ id: 'field.task' }), width: 250, getCellValue: (row: Row) => ({ todoType: row.todoType, task: row.task, titleParam: row.titleParam, taskCategory: row.taskCategory, companyList: companyList }) },
        { field: 'taskCategory', dataTypeName: CodeCategory.TodoListTaskType, title: intl.formatMessage({ id: 'field.taskCategory' }), width: 200 },
        { field: 'description', dataTypeName: 'desFormat', title: intl.formatMessage({ id: 'field.description' }), width: 400, getCellValue: (row: Row) => ({ todoType: row.todoType, description: row.description, scheduleType: row.scheduleType, companyList: companyList }) },
        { field: 'dueDate', dataTypeName: 'dateOnTime', title: intl.formatMessage({ id: 'field.dueDate' }), width: 300, getCellValue: (row: Row) => ({ dueDate: row.dueDate, taskCategory: row.taskCategory }) },
        { field: 'remindStartDate', dataTypeName: 'dateOnLocalTime', title: intl.formatMessage({ id: 'field.remindStartDate' }), width: 300, getCellValue: (row: Row) => ({ date: row.remindStartDate }) },
        { field: 'remindEndDate', dataTypeName: 'dateOnLocalTime', title: intl.formatMessage({ id: 'field.remindEndDate' }), width: 300, getCellValue: (row: Row) => ({ date: row.remindEndDate }) },
        { field: 'status', dataTypeName: CodeCategory.TodoListStatus, title: intl.formatMessage({ id: 'field.status' }), width: 180 },
        { field: 'file', dataTypeName: 'file', title: intl.formatMessage({ id: 'field.file' }), width: 100, getCellValue: (row: Row) => ({ todoId: row.todoId, file: row.file }) },
        { field: 'urgency', dataTypeName: CodeCategory.TodoListPrority, title: intl.formatMessage({ id: 'field.prority' }), width: 200 },
        { field: 'createdBy', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.createdBy' }), width: 180 },
        { field: 'createdDate', dataTypeName: 'dateOnLocalTime', title: intl.formatMessage({ id: 'field.createdDate' }), width: 300, getCellValue: (row: Row) => ({ date: row.createdDate }) },
        { field: 'updatedBy', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.updatedBy' }), width: 180 },
        { field: 'updatedDate', dataTypeName: 'dateOnLocalTime', title: intl.formatMessage({ id: 'field.lastUpdateDate' }), width: 180, getCellValue: (row: Row) => ({ date: row.UpdatedDate }) },
    ], [companyList, intl])

    const getRowId = useCallback((row: any) => row.todoId, [])
    const [flag, setFlag] = useState<boolean>(false)
    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search, data, flag, setFlag }), [filters, setFilters, search, data, flag, setFlag])
    const actionProps = useMemo(() => ({ data, search, filters }), [data, filters, search])
    const display1 = useCallback((tableRow: TableRow) => tableRow.row?.todoId !== null && tableRow.row?.todoId !== undefined, [])
    const display2 = useCallback((tableRow: TableRow) => tableRow.row?.todoType !== null && tableRow.row?.todoType === TodoListTypeMode.USER && tableRow.row.status !== TodoListStatus.Processed, [])
    const { getCodeName } = useGetCodeName()

    const taskCategoryComparator: Comparator<number> = (a, b) => {
        const prev = getCodeName(CodeCategory.TodoListTaskType, a) ?? ''
        const next = getCodeName(CodeCategory.TodoListTaskType, b) ?? ''
        return prev > next ? 1 : prev === next ? 0 : -1
    }
    const statusComparator: Comparator<number> = (a, b) => {
        const prev = getCodeName(CodeCategory.TodoListStatus, a) ?? ''
        const next = getCodeName(CodeCategory.TodoListStatus, b) ?? ''
        return prev > next ? 1 : prev === next ? 0 : -1
    }
    const urgencyComparator: Comparator<number> = (a, b) => {
        const prev = getCodeName(CodeCategory.TodoListPrority, a) ?? ''
        const next = getCodeName(CodeCategory.TodoListPrority, b) ?? ''
        return prev > next ? 1 : prev === next ? 0 : -1
    }

    const dueDateComparator: Comparator<TnmUserToListResult> = (a, b) => {
        const prev = new Date(a.dueDate + "")
        const next = new Date(b.dueDate + "")
        return prev > next ? 1 : prev === next ? 0 : -1
    }

    const taskComparator: Comparator<TnmUserToListResult> = (a, b) => {
        if ((a.task === undefined || a.task === null) && (b.task === undefined || b.task === null)) {
            return 0
        }
        else if ((a.task === undefined || a.task === null) && (b.task !== undefined || b.task !== null)) {
            return 1
        }
        else if ((a.task !== undefined || a.task !== null) && (b.task === undefined || b.task === null)) {
            return -1
        }
        else {
            const prev = (a.titleParam ? intl.formatMessage({ id: a.task }, a.titleParam.split(",") as Record<number, string>) : intl.formatMessage({ id: a.task }))
            const next = (b.titleParam ? intl.formatMessage({ id: b.task }, b.titleParam.split(",") as Record<number, string>) : intl.formatMessage({ id: b.task }))
            return prev > next ? -1 : prev === next ? 0 : 1
        }
    }

    const desComparator: Comparator<TnmUserToListResult> = (a, b) => {
        const prev = a.description ?? ''
        const next = b.description ?? ''
        return prev > next ? 1 : prev === next ? 0 : -1
    }


    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <TodoListTypeProvider />
        <DateOnTimeTypeProvider />
        <DateOnLocalTimeTypeProvider />
        <FileTypeProvider />
        <StringTypeProvider />
        <DesTypeProvider />
        <TodoListProrityProvider />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.TodoListTaskType} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.TodoListScheduleType} />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={CreateAction} actionProps={display1} />
        <ToolbarActionProvider Action={ShowAllAction} actionProps={itemPropsForFilters} />
        <RowActionProvider name="view" Action={ViewRowAction} actionProps={actionProps} />
        <RowActionProvider name="importance" Action={StarAction} actionProps={actionProps} />
        <RowActionProvider name="complete" Action={CompleteAction} actionProps={actionProps} />
        <RowActionProvider name="edit" Action={EditRowAction} actionProps={display1} display={display2} />
        <ColumnFreeze />
        <ColumnVisibility defaultHiddenFields={['createdBy', 'createdDate', 'updatedBy',]} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} columnSettings={
            {
                task: {
                    valueToString: (value) => {
                        if (value.task) {
                            return value.titleParam ? intl.formatMessage({ id: value.task }, value.titleParam.split(",") as Record<number, string>) : intl.formatMessage({ id: value.task })
                        }
                        else {
                            return ''
                        }
                    }
                },
                taskCategory: { valueToString: (value) => getCodeName(CodeCategory.TodoListTaskType, value) ?? '' },
                status: { valueToString: (value) => getCodeName(CodeCategory.TodoListStatus, value) ?? '' },
                urgency: { valueToString: (value) => getCodeName(CodeCategory.TodoListPrority, value) ?? '' },
                description: { valueToString: (value) => value.description ?? '' }
            }
        } />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting columnSettings={
            {
                task: { comparator: taskComparator },
                taskCategory: { comparator: taskCategoryComparator },
                status: { comparator: statusComparator },
                urgency: { comparator: urgencyComparator },
                dueDate: { comparator: dueDateComparator },
                description: { comparator: desComparator },
            }} />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={200} />
    </DataGrid>
}

const FilterItem = (props: {
    filters: TnmUserToListFilter,
    setFilters: React.Dispatch<React.SetStateAction<TnmUserToListFilter>>,
    search: (filters: TnmUserToListFilter) => void
}) => {
    const { filters, setFilters, search } = props

    const remindDateGetValue = useCallback((filters: TnmUserToListFilter) => {
        return [filters.remindStartDate ?? null, filters.remindEndDate ?? null]
    }, [])

    const remindDateMapValue = useCallback((filters: TnmUserToListFilter, value: any) => {
        return { ...filters ?? {}, remindStartDate: value[0], remindEndDate: value[1] }
    }, [])

    const clear = useCallback((filters: TnmUserToListFilter) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: TnmUserToListFilter) => {
        return [
            filters.task,
            filters.description,
            filters.urgencies,
            filters.remindStartDate || filters.remindEndDate,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="task" label={intl.formatMessage({ id: 'field.task' })} />
        <StringItem field="description" label={intl.formatMessage({ id: 'field.description' })} />
        <CodesItem field="urgencies" label={intl.formatMessage({ id: 'field.prority' })} code={CodeCategory.TodoListPrority} />
        <DateRangeItem field="remindStartDate" label={intl.formatMessage({ id: 'field.remindStartDate' })} getValue={remindDateGetValue} mapValue={remindDateMapValue} />
    </FilterToolbarItem>
}


const CreateAction = () => {
    return <CreateRedirectToolbarAction title={<FormattedMessage id="createTodoList" />} path="/todolist/create" />
}

const DateOnLocalTimeFormatter = ({ value }: ObjectFormatterProps) => {
    return value.date ? <><FormattedDate value={value.date} dateStyle="medium" /></> : null

}
const DateOnTimeFormatter = ({ value }: ObjectFormatterProps) => {
    const taskCategory = value.taskCategory
    const overdue = taskCategory !== 1 ? (value.dueDate ? !moment(moment(value.dueDate).format("YYYY-MM-DD")).isAfter(moment(moment(today()).format("YYYY-MM-DD"))) : false) : false
    return value.dueDate ? <><FormattedDate value={value.dueDate} dateStyle="medium" /><> </>{overdue === true ? <TimerIcon color="error" /> : undefined}</> : null
}

const FileFormatter = ({ value }: ObjectFormatterProps) => {
    const todoId = value.todoId
    const file = value.file
    const useDownloadTDLs = useDownloadTDL()
    const download = useCallback(() => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useDownloadTDLs({ todoId: todoId, file: file })
    }, [file, todoId, useDownloadTDLs])
    return file ? <Tooltip title={'downloadFile'}><IconButton onClick={download}><AttachFileIcon /></IconButton></Tooltip> : null
}
const StringFormatter = ({ value }: ObjectFormatterProps) => {
    const intl = useIntl()
    const todoType = value.todoType
    const task = value.task
    const companyList = value.companyList
    const titleParam = value.titleParam
    let titleParamArr: string[] = []
    if (titleParam) {
        titleParamArr = titleParam.split(",")
        const filters = companyList?.filter((f: TnvCbds) => f.cbdsUid === titleParamArr[2])
        const receiver = filters && filters.length > 0 ? filters[0].cbdsCode : titleParamArr[2]
        titleParamArr[2] = receiver
    }
    return task ? <>{todoType === TodoListTypeMode.SYSTEM ? intl.formatMessage({ id: task }, titleParamArr as Record<number, string>) : task} </> : task
}
const DesFormatter = ({ value }: ObjectFormatterProps) => {
    const todoType = value.todoType
    const description = value.description
    const scheduleType = value.scheduleType
    const companyList = value.companyList
    let requestData: TnmUserToListRequestResult = {}
    let supplierConfirmData: TnmUserToListSupplierConfirm = {}
    let placeOrderData: TnmUserToListPlaceOrderResult = {}
    let changeResultData: TnmUserToListChangeRequestResult = {}
    let shipmentDailyData: TnmUserToListShipmentDealyResult[] = []
    let inboundData: TnmUserToListInboundResult[] = []
    let outboundData: TnmUserToListOutboundResult[] = []
    if (isJSON(description) && todoType === TodoListTypeMode.SYSTEM) {
        if (scheduleType === TodoScheduleType.REQUEST) {
            requestData = JSON.parse(description)
            return <>Customer Code:{requestData.customerCode}<br />Request No.:{requestData.requestNo}</>
        }
        else if (scheduleType === TodoScheduleType.PLACE_ORDER) {
            placeOrderData = JSON.parse(description)
            return <>Contract No.:{placeOrderData.contractNo}<br />Due Date:<FormattedDate value={dateFromJson(placeOrderData.dueDate)} dateStyle="medium" /></>
        }
        else if (scheduleType === TodoScheduleType.SUPPLIER_TO_CONFIRM_ORDER) {
            supplierConfirmData = JSON.parse(description)
            return <>Customer Code:{supplierConfirmData.customerCode}<br />Order No.:{supplierConfirmData.orderNo}</>
        }
        else if (scheduleType === TodoScheduleType.CHANGE_CANCEL_REQUEST_APPROVAL) {
            changeResultData = JSON.parse(description)
            const isCus = description.includes("customerCode")
            const isSupp = description.includes("supplierCode")
            // return <>Request Type:{getCodeName(CodeCategory.RequestType,changeResultData.requestType)}<br />Customer Code:{changeResultData.customerCode}<br/>Customer Oder No.:{changeResultData.customerOrderNo}</>
            return <>
                {isCus && <>Customer Code:{changeResultData.customerCode}<br />Request No.:{changeResultData.requestNo}</>}
                {isSupp && <>Supplier Code:{changeResultData.supplierCode}<br />Request No.:{changeResultData.requestNo}</>}
            </>
        }
        else if (scheduleType === TodoScheduleType.SHIPMENT_DELAY) {
            shipmentDailyData = JSON.parse(description)
            return <>{
                shipmentDailyData.map((value, index) => {
                    return <>
                        Outbound No.:{value.outboundNo},Other Amount:{value.otherAmount},Outbound RefNo:{value.outboundRefNo}<br />
                    </>
                })
            }</>
        }
        else if (scheduleType === TodoScheduleType.REMINDER_TO_PERFORM_INBOUND) {
            inboundData = JSON.parse(description)
            return <>
                {
                    inboundData.map((m, index) => {
                        return <>Inbound No.:{m.inboundNo},Inbound Plan Date:<FormattedDate value={dateFromJson(m.inboundPlanDate)} dateStyle="medium" /><br /></>
                    })
                }

            </>

        }
        else if (scheduleType === TodoScheduleType.REMINDER_TO_PERFORM_OUTBOUND) {
            outboundData = JSON.parse(description)
            const comparator: Comparator<TnmUserToListOutboundResult> = (a, b) => {
                const prevDate = (a.planOutboundDate ?? '')
                const nextDate = (b.planOutboundDate ?? '')
                const prevNo = (a.noOfParts ?? '')
                const nextNo = (b.noOfParts ?? '')
                if (prevDate === nextDate) {
                    return prevNo < nextNo ? 1 : prevNo === nextNo ? 0 : -1
                } else {
                    return prevDate > nextDate ? 1 : prevDate === nextDate ? 0 : -1
                }
            }
            return <>
                {
                    Arrays.sort(outboundData, comparator).map((m, index) => {
                        const filters = companyList?.filter((f: TnvCbds) => f.cbdsUid === m.receiver)
                        const receiver = filters && filters.length > 0 ? filters[0].cbdsCode : m.receiver
                        return <>Receiver:{receiver},Sales Order:{m.orderNo},Plan Outbound Date:<FormattedDate value={dateFromJson(m.planOutboundDate)} dateStyle="medium" />,No. of Parts:{m.noOfParts}<br /></>
                    })
                }
            </>
        }


        else {
            return <>{description}</>
        }
    }
    else {
        return <>{description}</>
    }
}

const DateOnLocalTimeTypeProvider = () => {
    return <ObjectTypeProvider name="dateOnLocalTime" Formatter={DateOnLocalTimeFormatter} />
}

const DateOnTimeTypeProvider = () => {
    return <ObjectTypeProvider name="dateOnTime" Formatter={DateOnTimeFormatter} />
}
const FileTypeProvider = () => {
    return <ObjectTypeProvider name="file" Formatter={FileFormatter} />
}
const StringTypeProvider = () => {
    return <ObjectTypeProvider name="stringFormat" Formatter={StringFormatter} />
}
const DesTypeProvider = () => {
    return <ObjectTypeProvider name="desFormat" Formatter={DesFormatter} />
}
const ViewRowAction = ({ tableRow, search, filters }: DataGridRowActionProps & { search: (filters: TnmUserToListFilter) => void, filters: TnmUserToListFilter }) => {
    const data = tableRow.row
    const navigate = useNavigate()
    const setSesion = useSetDataIntoSession()
    const viewClick = useCallback(() => {
        if (data === undefined) return
        if (data.url.startsWith('/placecustorder-regular/create-')) {
            setSesion(sessionKeys.Data_ONS011, placeOrderDetailFactorSerializer, data.urlParam ? JSON.parse(data.urlParam) : {})
        }
        navigate(data.url)
    }, [data, navigate, setSesion])
    if (data) {
        const todoType = data.todoType
        const status = data.status
        if (todoType === TodoListTypeMode.SYSTEM && status === TodoListStatus.Processed) {
            return <></>
        } else {
            return <ViewCallbackRowAction access="LOGI.TDL010.VIEW" callback={viewClick} tableRow={tableRow}></ViewCallbackRowAction>
        }

    } else {
        return <></>
    }
}

const EditRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/todolist/edit-${tableRow.row?.todoId}`, [])
    return <EditRedirectRowAction tableRow={tableRow} path={path} />
}

const ShowAllAction = (props: {
    search: (filters: TnmUserToListFilter) => void,
    filters: TnmUserToListFilter,
    setFilters: React.Dispatch<React.SetStateAction<TnmUserToListFilter>>,
    flag: boolean,
    setFlag: React.Dispatch<React.SetStateAction<boolean>>,

}) => {

    const { search, setFilters, flag, setFlag } = props

    const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }
    const afilters = { page: { currentPage: 0, pageSize: 1000 }, status: [TodoListStatus.Pending, TodoListStatus.InProcess] }
    const intl = useIntl()

    const title = flag ? intl.formatMessage({ id: 'showPendingTask' }) : intl.formatMessage({ id: 'showAll' })
    const clickfun = () => {
        setFlag(!flag)
        if (!flag) {
            search(defaultFilters)
            setFilters(defaultFilters)
        } else {
            search(afilters)
            setFilters(afilters)
        }
    }
    return <CancelCallbackToolbarAction title={title} icon={<Switches />} access="LOGI.TDL010.VIEW" callback={clickfun} />
}

const StarAction = ({ tableRow, search, filters, data }: DataGridRowActionProps & { search: (filters: TnmUserToListFilter) => void, filters: TnmUserToListFilter, data: TnmUserToListResult[] }) => {
    const updateById = useUpdateById()
    const importanceFlag = tableRow.row?.importanceFlag
    const intl = useIntl()
    const title = intl.formatMessage({ id: 'star' })
    const todo = data[data.findIndex(t => t.todoId === tableRow.row?.todoId)]

    const onclickSubmit = useCallback(() => {
        if (tableRow.row) {
            let todolistView: TnmUserToListView = {
                todoId: tableRow.row?.todoId,
                remindStartDate: dateToJson(tableRow.row?.remindStartDate),
                remindEndDate: dateToJson(tableRow.row?.remindEndDate),
                importanceFlag: tableRow.row?.importanceFlag === 1 ? 0 : 1,
                dueDate: dateToJson(tableRow.row?.dueDate),
                file: tableRow.row?.file,
                fileName: todo.fileName,
                toCbdsUid: tableRow.row.toCbdsUid,
                title: tableRow.row.title,
                task: tableRow.row.task,
                description: tableRow.row.description,
                fromUserId: todo.fromUserId,
                toUserId: todo.toUserId,
                titleParam: todo.titleParam,
                descriptionParam: todo.descriptionParam,
                todoType: todo.todoType,
                scheduleType: todo.scheduleType,
                urgency: todo.urgency,
                url: todo.url,
                urlParam: todo.urlParam,
                taskKey: todo.taskKey,
                status: todo.status,
                createdBy: todo.createdBy,
                createdDate: Number(dateToJson(todo.createdDate)),
                taskCategory: todo.taskCategory,
                recurrence: todo.recurrence,
            }
            updateById({ todolistView: todolistView }, { serialized: true }).then(() => search(filters))
        }
    }, [filters, search, tableRow.row, updateById, todo])
    if (importanceFlag === 1) {
        return (
            <Access access="LOGI.TDL011.UPDATE">
                <Tooltip title={title}><IconButton onClick={onclickSubmit}><StarIcon style={{ color: 'orange' }} /></IconButton></Tooltip>
            </Access>)
    }
    return (
        <Access access="LOGI.TDL011.UPDATE">
            <Tooltip title={title}><IconButton onClick={onclickSubmit}><StarBorderIcon /></IconButton></Tooltip>
        </Access>)
}

const CompleteAction = ({ tableRow, search, filters, data }: DataGridRowActionProps & { search: (filters: TnmUserToListFilter) => void, filters: TnmUserToListFilter, data: TnmUserToListResult[] }) => {
    const { todoType, status } = tableRow.row ?? { todoType: undefined, status: undefined }
    const updateById = useUpdateById()
    const intl = useIntl()
    const title = intl.formatMessage({ id: 'complete' })
    const todo = data[data.findIndex(t => t.todoId === tableRow.row?.todoId)]

    const onclickSubmit = useCallback(() => {
        if (tableRow.row) {
            let todolistView: TnmUserToListView = {
                todoId: tableRow.row?.todoId,
                remindStartDate: dateToJson(tableRow.row?.remindStartDate),
                remindEndDate: dateToJson(tableRow.row?.remindEndDate),
                status: status !== 2 ? 2 : status,
                dueDate: dateToJson(tableRow.row?.dueDate),
                file: tableRow.row?.file,
                fileName: todo.fileName,
                toCbdsUid: tableRow.row.toCbdsUid,
                title: tableRow.row.title,
                task: tableRow.row.task,
                description: tableRow.row.description,
                importanceFlag: todo.importanceFlag,
                fromUserId: todo.fromUserId,
                toUserId: todo.toUserId,
                titleParam: todo.titleParam,
                descriptionParam: todo.descriptionParam,
                todoType: todo.todoType,
                scheduleType: todo.scheduleType,
                urgency: todo.urgency,
                url: todo.url,
                urlParam: todo.urlParam,
                taskKey: todo.taskKey,
                createdBy: todo.createdBy,
                createdDate: Number(dateToJson(todo.createdDate)),
                taskCategory: todo.taskCategory,
                recurrence: todo.recurrence,
            }
            if (status === 2) {
                return
            }
            updateById({ todolistView: todolistView }, { serialized: true }).then(() => search(filters))
        }
    }, [filters, search, status, tableRow.row, updateById, todo])


    return (
        <Access access="LOGI.TDL011.UPDATE">
            {status === 2 ?
                <Tooltip title={title}><IconButton onClick={() => {}}><CheckBoxIcon /></IconButton></Tooltip>
                : <Tooltip title={title}><IconButton disabled={todoType === TodoListTypeMode.SYSTEM} onClick={onclickSubmit}><CheckBoxOutlineBlankIcon /></IconButton></Tooltip>
            }
        </Access>
    )
}


const isJSON = (str: string) => {
    if (typeof str == 'string') {
        try {
            var obj = JSON.parse(str)
            if (typeof obj == 'object' && obj) {
                return true
            } else {
                return false
            }
        } catch (e) {
            return false
        }
    }
}
















