import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { ReactNode } from 'react'
import { useViewContext } from './ViewContext'

interface ViewBodyProps {
    children?: ReactNode | ReactNode[],
}

export function ViewBody(props: ViewBodyProps) {
    const { children } = props
    const { flex } = useViewContext()
    const classes = useStyles()
    return <div className={clsx(classes.root, flex && classes.flex)}>
        {children}
    </div>
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: `${theme.spacing(0.75)}px ${theme.spacing(12)}px ${theme.spacing(2)}px `,
        flex: '1 0 auto',
        boxSizing: 'border-box',
    },
    flex: {
        flex: '1 1 auto',
        overflow: 'hidden',
    },
}))