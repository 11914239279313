import {Project} from "../../../utils/Project"
import {Projects} from "../../../utils/Projects"
import {dateFromJson} from "../../utils/deserializer"
import {PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson} from "../../utils/PageViewEntity"
import {dateToJson} from "../../utils/serializer"
import {AccessHistoryType} from "../enums/AccessHistoryType"
import {AccessPlatformType} from "../enums/AccessPlatformType"

export type UserAccessHistoryDownloadListFactor = Omit<UserAccessHistoryListFactor, 'page'>
export type UserAccessHistoryDownloadListFactorJson = Omit<UserAccessHistoryListFactorJson, 'page'>
export const UserAccessHistoryDownloadListFactorToJson: Project<UserAccessHistoryListFactor, UserAccessHistoryListFactorJson> = obj => ({
    ...obj,
  accessDateStart: dateToJson(obj.accessDateStart),
  accessDateEnd: dateToJson(obj.accessDateEnd),
})


export type UserAccessHistoryListFactor = Partial<Optional>
export type UserAccessHistoryListFactorJson = Partial<JsonOptional>
export const UserAccessHistoryListFactorToJson: Project<UserAccessHistoryListFactor, UserAccessHistoryListFactorJson> = obj => ({
    ...obj,
    accessDateStart: dateToJson(obj.accessDateStart),
    accessDateEnd: dateToJson(obj.accessDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
})

export const UserAccessHistoryListFactorFromJson: Project<UserAccessHistoryListFactorJson, UserAccessHistoryListFactor> = json => ({
    ...json,
    accessDateStart: dateFromJson(json.accessDateStart),
    accessDateEnd: dateFromJson(json.accessDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page),
})

interface Optional extends Omit<JsonOptional, 'accessDateStart' | 'accessDateEnd' | 'page'> {
    type: AccessHistoryType[]
    accessDateEnd: Date
    accessDateStart: Date
    platform: AccessPlatformType[]
    page: PageViewEntity
}


interface JsonOptional {
    type: number[]
    accessDateEnd: string
    accessDateStart: string
    platform: number[]
    page: PageViewEntityJson
}
