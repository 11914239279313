import { AxiosInstance, AxiosResponse } from "axios"
import { useCallback } from "react"
import { Projects } from "../../../utils/Projects"
import { usePost } from "../../../utils/fetch/useFetch"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { OCPlaceRegularOrderResult, ocPlaceRegularOrderResultDeserializer, ocPlaceRegularOrderResultSerializer } from "../models/OCPlaceOrderDetailResult"
import { OrderCalcRunConditionVo, orderCalcRunConditionVoToJson } from "../models/OrderCalcRunConditionVo"
import { OrderCalculationCreateViewCondition, orderCalculationCreateViewConditionToJson } from "../models/OrderCalculationCreateViewCondition"
import { OrderCalculationCreateViewResult, OrderCalculationCreateViewResultFromJson } from "../models/OrderCalculationCreateViewResult"
import { OrderCalculationDetailRequestBo, orderCalculationDetailRequestBoToJson } from "../models/OrderCalculationDetailRequestBo"
import { OrderCalculationDetailResult, orderCalculationDetailResultFromJson } from "../models/OrderCalculationDetailResult"
import { OrderCalculationListViewCondition, orderCalculationListViewConditionToJson } from "../models/OrderCalculationListViewCondition"
import { OrderCalculationListViewResult, OrderCalculationListViewResultFromJson } from "../models/OrderCalculationListViewResult"
import { OrderCalculationReviewBo, orderCalculationReviewBoToJson } from "../models/OrderCalculationReviewBo"
import { OrderCalculationReviewResult, orderCalculationReviewResultFromJson } from "../models/OrderCalculationReviewResult"
import { OrderCalculationTransfer, orderCalculationTransferFromJson } from "../models/OrderCalculationTransfer"
import { OrderCalculationView, orderCalculationViewToJson } from "../models/OrderCalculationView"
import { PlaceOrderCondition, PlaceOrderConditionToJson } from "../models/PlaceOrderCondition"
import { SdtSmtCustUsage, sdtSmtCustUsageFromJson } from "../models/SdtSmtCustUsage"

/** MSOGS010 */
export const useGetOrderCalculationPage = () => {
    const url = '/smt-api/ordermng/getOrderGroupList'
    const payloadProject = useCallback(orderCalculationViewToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(orderCalculationTransferFromJson), [])
    return usePost<OrderCalculationView, PageResultEntity<OrderCalculationTransfer>>(url, payloadProject, resultProject)
}

/** MSOGS020 */
export const useGetOrderCalculationPageForEhm = () => {
    const url = '/smt-api/ordermng/getOrderGroupListForEhm'
    const payloadProject = useCallback(orderCalculationViewToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(orderCalculationTransferFromJson), [])
    return usePost<OrderCalculationView, PageResultEntity<OrderCalculationTransfer>>(url, payloadProject, resultProject)
}

/** OCCLS010 */
export const orderCalculationListView = (axiosInstance: AxiosInstance, data: OrderCalculationListViewCondition) => {
    const url = '/smt-api/ordermng/order-calculations/list-view'
    return axiosInstance.post(url, data, {
        transformRequest: data => JSON.stringify(orderCalculationListViewConditionToJson(data)),
    }).then(response => {
        response.data = pageResultEntityFromJson(OrderCalculationListViewResultFromJson)(response.data)
        return response
    }) as Promise<AxiosResponse<PageResultEntity<OrderCalculationListViewResult>>>
}

/** OCCLS010Ehm */
export const orderCalculationListViewForEhm = (axiosInstance: AxiosInstance, data: OrderCalculationListViewCondition) => {
    const url = '/smt-api/ordermng/order-calculations/list-view-ehm'
    return axiosInstance.post(url, data, {
        transformRequest: data => JSON.stringify(orderCalculationListViewConditionToJson(data)),
    }).then(response => {
        response.data = pageResultEntityFromJson(OrderCalculationListViewResultFromJson)(response.data)
        return response
    }) as Promise<AxiosResponse<PageResultEntity<OrderCalculationListViewResult>>>
}

export const runOrderCalculation = (axiosInstance: AxiosInstance, data: OrderCalcRunConditionVo) => {
    const url = '/smt-api/ordermng/order-calculations/run'
    return axiosInstance.post(url, data, {
        transformRequest: data => JSON.stringify(orderCalcRunConditionVoToJson(data)),
    }) as Promise<AxiosResponse<{ orderCalcNo: string }>>
}

export const runOrderCalculationPNA = (axiosInstance: AxiosInstance, data: OrderCalcRunConditionVo) => {
    const url = '/smt-api/ordermng/order-calculations/run-pna'
    return axiosInstance.post(url, data, {
        transformRequest: data => JSON.stringify(orderCalcRunConditionVoToJson(data)),
    }) as Promise<AxiosResponse<{ orderCalcNo: string }>>
}

export const runOrderCalculationEhm = (axiosInstance: AxiosInstance, data: OrderCalcRunConditionVo) => {
    const url = '/smt-api/ordermng/order-calculations/run-ehm'
    return axiosInstance.post(url, data, {
        transformRequest: data => JSON.stringify(orderCalcRunConditionVoToJson(data)),
    }) as Promise<AxiosResponse<{ orderCalcNo: string }>>
}

export const orderCalculationGetCustomerOptions = (axiosInstance: AxiosInstance) => {
    return axiosInstance.post('/smt-api/custparts/getCustomerByBu', undefined).then(response => {
        response.data = response.data.reduce((map: { [x: string]: any }, item: { first: string | number; second: any }) => { map[item.first] = item.second; return map }, {})
        return response
    })
}

export const orderCalculationGetBuyerOptions = (axiosInstance: AxiosInstance) => {
    //return axiosInstance.post('/smt-api/custparts/getBuyerByBu', undefined).then(response => {
        return axiosInstance.post('/smt-api/ordermng/group/list-buyers', undefined).then(response => {
        response.data = response.data.reduce((map: { [x: string]: any }, item: { first: string | number; second: any }) => { map[item.first] = item.second; return map }, {})
        return response
    })
}

export const orderCalculationEhmGetBuyerOptions = (axiosInstance: AxiosInstance) => {
    return axiosInstance.post('/smt-api/ordermng/group/list-buyers-ehm', undefined).then(response => {
        response.data = response.data.reduce((map: { [x: string]: any }, item: { first: string | number; second: any }) => { map[item.first] = item.second; return map }, {})
        return response
    })
}

export const orderCalculationGetSellerOptions = (axiosInstance: AxiosInstance) => {
    //return axiosInstance.post('/smt-api/custparts/getSellerByCustomer', undefined).then(response => {
    return axiosInstance.post('/smt-api/ordermng/group/list-sellers', undefined).then(response => {
        response.data = response.data.reduce((map: { [x: string]: any }, item: { first: string | number; second: any }) => { map[item.first] = item.second; return map }, {})
        return response
    })
}

// useWithdrawOrderCalculation
export const useWithdrawOrderCalculation = () => {
    const url = '/smt-api/ordermng/withdrawOrderCalc'
    const payloadProject = useCallback((obj: { poGroupId: number | undefined }) => obj, [])
    const resultProject = Projects.IGNORE
    return usePost<{ poGroupId: number | undefined }, undefined>(url, payloadProject, resultProject)
}

/** OCCLS013 */
export const orderCalculationCreateView = (axiosInstance: AxiosInstance, data: OrderCalculationCreateViewCondition) => {
    const url = '/smt-api/ordermng/order-calculations/create-view'
    return axiosInstance.post(url, data, {
        transformRequest: data => JSON.stringify(orderCalculationCreateViewConditionToJson(data))
    }).then(response => {
        response.data = OrderCalculationCreateViewResultFromJson(response.data)
        return response
    }) as Promise<AxiosResponse<OrderCalculationCreateViewResult>>
}

export const changeCustomerUsageDate = (axiosInstance: AxiosInstance, data: { orderGroupId?: string, custUsageDate?: string }) => {
    const url = '/smt-api/usage/getOrderGroupUsageVersion'
    return axiosInstance.post(url, data).then(response => {
        response.data = response.data.map(sdtSmtCustUsageFromJson)
        return response
    }) as Promise<AxiosResponse<SdtSmtCustUsage[]>>
}

/** OCCLS014 */
export const useGetOrderCalculationDetail = () => {
    const url = '/smt-api/ordermng/order-calculations/getOrderCalcRegular'
    const payloadProject = useCallback(orderCalculationDetailRequestBoToJson, [])
    const resultProject = useCallback(orderCalculationDetailResultFromJson, [])
    return usePost<OrderCalculationDetailRequestBo, OrderCalculationDetailResult>(url, payloadProject, resultProject)
}
export const useGetOrderCalculationEhmDetail = () => {
    const url = '/smt-api/ordermng/order-calculations/getOrderCalcRegularEhm'
    const payloadProject = useCallback(orderCalculationDetailRequestBoToJson, [])
    const resultProject = useCallback(orderCalculationDetailResultFromJson, [])
    return usePost<OrderCalculationDetailRequestBo, OrderCalculationDetailResult>(url, payloadProject, resultProject)
}

export const useSaveOrderCalculationDetail = () => {
    const url = '/smt-api/ordermng/order-calculations/saveOrderCalc'
    const payloadProject = useCallback(orderCalculationDetailRequestBoToJson, [])
    const resultProject = Projects.IGNORE
    return usePost<OrderCalculationDetailRequestBo, undefined>(url, payloadProject, resultProject)
}

// ResetDeliveryDate 
export const useGetDeliveryDateForSmt = () => {
    const url = '/smt-api/ordermng/getDeliveryDate'
    const payloadProject = useCallback(Projects.array(ocPlaceRegularOrderResultSerializer), [])
    const resultProject = useCallback(Projects.array(ocPlaceRegularOrderResultDeserializer), [])
    return usePost<OCPlaceRegularOrderResult[], OCPlaceRegularOrderResult[]>(url, payloadProject, resultProject)
}

// ResetDeliveryDate For PNA
export const useGetDeliveryDateForPNA = () => {
    const url = '/smt-api/ordermng/getDeliveryDateForPNA'
    const payloadProject = useCallback(Projects.array(ocPlaceRegularOrderResultSerializer), [])
    const resultProject = useCallback(Projects.array(ocPlaceRegularOrderResultDeserializer), [])
    return usePost<OCPlaceRegularOrderResult[], OCPlaceRegularOrderResult[]>(url, payloadProject, resultProject)
}

// ResetDeliveryDate For SMT
export const useGetDeliveryDateForEhm = () => {
    const url = '/smt-api/ordermng/getDeliveryDateForEhm'
    const payloadProject = useCallback(Projects.array(ocPlaceRegularOrderResultSerializer), [])
    const resultProject = useCallback(Projects.array(ocPlaceRegularOrderResultDeserializer), [])
    return usePost<OCPlaceRegularOrderResult[], OCPlaceRegularOrderResult[]>(url, payloadProject, resultProject)
}

// Create 
export const useCreatePlaceOrder = () => {
    const url = '/smt-api/ordermng/createPlaceOrder'
    const payloadProject = useCallback(PlaceOrderConditionToJson, [])
    const resultProject = useCallback((json: number) => json, [])
    return usePost<PlaceOrderCondition, number>(url, payloadProject, resultProject)
}

// LIST 
export const useGetRegularOrderFormInfoList = () => {
    const url = '/smt-api/ordermng/getRegularOrderFormInfoList'
    const payloadProject = Projects.KEEP
    const resultProject = useCallback(Projects.array(ocPlaceRegularOrderResultDeserializer), [])
    return usePost<{ poGroupId: string }, OCPlaceRegularOrderResult[]>(url, payloadProject, resultProject)
}

export const useGetSpotOrderFormInfoList = () => {
    const url = '/smt-api/ordermng/getSpotOrderFormInfoList'
    const payloadProject = Projects.KEEP
    const resultProject = useCallback(Projects.array(ocPlaceRegularOrderResultDeserializer), [])
    return usePost<{ poGroupId: string }, OCPlaceRegularOrderResult[]>(url, payloadProject, resultProject)
}

// SAVE
export const useSavePlacedOrderList = () => {
    const url = '/smt-api/ordermng/saveRegularOrder'
    const payloadProject = useCallback(Projects.array(ocPlaceRegularOrderResultSerializer), [])
    const resultProject = Projects.IGNORE
    return usePost<OCPlaceRegularOrderResult[], undefined>(url, payloadProject, resultProject)
}

// SAVE PNA
export const useSavePlacedOrderListPNA = () => {
    const url = '/smt-api/ordermng/saveRegularOrderPNA'
    const payloadProject = useCallback(Projects.array(ocPlaceRegularOrderResultSerializer), [])
    const resultProject = Projects.IGNORE
    return usePost<OCPlaceRegularOrderResult[], undefined>(url, payloadProject, resultProject)
}

// SAVE SMT
export const useSavePlacedOrderListSMT = () => {
    const url = '/smt-api/ordermng/saveRegularOrderSMT'
    const payloadProject = useCallback(Projects.array(ocPlaceRegularOrderResultSerializer), [])
    const resultProject = Projects.IGNORE
    return usePost<OCPlaceRegularOrderResult[], undefined>(url, payloadProject, resultProject)
}

export const useSaveAndIssuePlacedOrderList = () => {
    const url = '/smt-api/ordermng/saveAndIssueRegularOrder'
    const payloadProject = useCallback(Projects.array(ocPlaceRegularOrderResultSerializer), [])
    const resultProject = Projects.IGNORE
    return usePost<OCPlaceRegularOrderResult[], undefined>(url, payloadProject, resultProject)
}

export const useSaveAndIssuePlacedOrderListPNA = () => {
    const url = '/smt-api/ordermng/saveAndIssueRegularOrderPNA'
    const payloadProject = useCallback(Projects.array(ocPlaceRegularOrderResultSerializer), [])
    const resultProject = Projects.IGNORE
    return usePost<OCPlaceRegularOrderResult[], undefined>(url, payloadProject, resultProject)
}

export const useSaveAndIssuePlacedOrderListSMT = () => {
    const url = '/smt-api/ordermng/saveAndIssueRegularOrderSMT'
    const payloadProject = useCallback(Projects.array(ocPlaceRegularOrderResultSerializer), [])
    const resultProject = Projects.IGNORE
    return usePost<OCPlaceRegularOrderResult[], undefined>(url, payloadProject, resultProject)
}

/** OCCLS016  getOrderCalcPoDetailList */
export const useGetOrderCalcSpot = () => {
    const url = '/smt-api/ordermng/order-calculations/getOrderCalcSpot'
    const payloadProject = useCallback((obj: { orderCalcNo: string }) => obj, [])
    const resultProject = useCallback(Projects.array(orderCalculationReviewResultFromJson), [])
    return usePost<{ orderCalcNo: string }, OrderCalculationReviewResult[]>(url, payloadProject, resultProject)
}

export const useGetOrderCalcSpotEhm = () => {
    const url = '/smt-api/ordermng/order-calculations/getOrderCalcSpotEhm'
    const payloadProject = useCallback((obj: { orderCalcNo: string }) => obj, [])
    const resultProject = useCallback(Projects.array(orderCalculationReviewResultFromJson), [])
    return usePost<{ orderCalcNo: string }, OrderCalculationReviewResult[]>(url, payloadProject, resultProject)
}

// review
export const useReviewOrderCalculationByCondition = () => {
    const url = '/smt-api/ordermng/createSpotOrder'
    const payloadProject = useCallback(orderCalculationReviewBoToJson, [])
    const resultProject = useCallback((obj: { poGroupId: string }) => obj, [])
    return usePost<OrderCalculationReviewBo, { poGroupId: string }>(url, payloadProject, resultProject)
}

// get latest usage version
export const useGetLatestUsageVersion = () => {
    const url = '/smt-api/ordermng/order-calculations/getLatestUsageVersion'
    const payloadProject = useCallback((obj: { orderCalcNo: string }) => obj, [])
    const resultProject = useCallback((obj: string) => obj, [])
    return usePost<{ orderCalcNo: string }, string>(url, payloadProject, resultProject)
}






// get latest usage version
export const useReviewOrderCalculationAfterUploadNewUsage = () => {
    const url = '/smt-api/ordermng/OCCLS016/reviewOrderCalculationAfterUploadNewUsage'
    const payloadProject = useCallback((obj: { orderCalcNo: string }) => obj.orderCalcNo, [])
    const resultProject = useCallback((obj: string) => obj, [])
    return usePost<{ orderCalcNo: string }, string>(url, payloadProject, resultProject)
}







/** OCCLS017 */
export const useSaveSpotPlacedOrderList = () => {
    const url = '/smt-api/ordermng/saveSpotOrder'
    const payloadProject = useCallback(Projects.array(ocPlaceRegularOrderResultSerializer), [])
    const resultProject = Projects.IGNORE
    return usePost<OCPlaceRegularOrderResult[], undefined>(url, payloadProject, resultProject)
}

export const useSaveSpotPlacedOrderListPNA = () => {
    const url = '/smt-api/ordermng/saveSpotOrder'
    const payloadProject = useCallback(Projects.array(ocPlaceRegularOrderResultSerializer), [])
    const resultProject = Projects.IGNORE
    return usePost<OCPlaceRegularOrderResult[], undefined>(url, payloadProject, resultProject)
}

export const useSaveSpotPlacedOrderListSMT = () => {
    const url = '/smt-api/ordermng/saveSpotOrder'
    const payloadProject = useCallback(Projects.array(ocPlaceRegularOrderResultSerializer), [])
    const resultProject = Projects.IGNORE
    return usePost<OCPlaceRegularOrderResult[], undefined>(url, payloadProject, resultProject)
}

export const useSaveAndIssueSpotPlacedOrderList = () => {
    const url = '/smt-api/ordermng/saveAndIssueSpotOrder'
    const payloadProject = useCallback(Projects.array(ocPlaceRegularOrderResultSerializer), [])
    const resultProject = Projects.IGNORE
    return usePost<OCPlaceRegularOrderResult[], undefined>(url, payloadProject, resultProject)
}

export const useSaveAndIssueSpotPlacedOrderListPNA = () => {
    const url = '/smt-api/ordermng/saveAndIssueSpotOrderPNA'
    const payloadProject = useCallback(Projects.array(ocPlaceRegularOrderResultSerializer), [])
    const resultProject = Projects.IGNORE
    return usePost<OCPlaceRegularOrderResult[], undefined>(url, payloadProject, resultProject)
}

export const useSaveAndIssueSpotPlacedOrderListSMT = () => {
    const url = '/smt-api/ordermng/saveAndIssueSpotOrderSMT'
    const payloadProject = useCallback(Projects.array(ocPlaceRegularOrderResultSerializer), [])
    const resultProject = Projects.IGNORE
    return usePost<OCPlaceRegularOrderResult[], undefined>(url, payloadProject, resultProject)
}

// ResetDeliveryDate 
export const useGetSpotDeliveryDateForSmt = () => {
    const url = '/smt-api/ordermng/getDeliveryDate'
    const payloadProject = useCallback(Projects.array(ocPlaceRegularOrderResultSerializer), [])
    const resultProject = useCallback(Projects.array(ocPlaceRegularOrderResultDeserializer), [])
    return usePost<OCPlaceRegularOrderResult[], OCPlaceRegularOrderResult[]>(url, payloadProject, resultProject)
}

export const useGetSpotDeliveryDateForPNA = () => {
    const url = '/smt-api/ordermng/getDeliveryDateForPNA'
    const payloadProject = useCallback(Projects.array(ocPlaceRegularOrderResultSerializer), [])
    const resultProject = useCallback(Projects.array(ocPlaceRegularOrderResultDeserializer), [])
    return usePost<OCPlaceRegularOrderResult[], OCPlaceRegularOrderResult[]>(url, payloadProject, resultProject)
}

export const useGetSpotDeliveryDateForSMT = () => {
    const url = '/smt-api/ordermng/getDeliveryDateForSMT'
    const payloadProject = useCallback(Projects.array(ocPlaceRegularOrderResultSerializer), [])
    const resultProject = useCallback(Projects.array(ocPlaceRegularOrderResultDeserializer), [])
    return usePost<OCPlaceRegularOrderResult[], OCPlaceRegularOrderResult[]>(url, payloadProject, resultProject)
}