import { Project } from "@rithe/utils"
import { dateFromJson } from "../../utils/deserializer"
import { dateToJson } from "../../utils/serializer"

export type ActualUsageEntityList = Required & Partial<Optional>
export type ActualUsageEntityListJson = JsonRequired & Partial<JsonOptional>
export const actualUsageEntityListToJson: Project<ActualUsageEntityList, ActualUsageEntityListJson> = obj => ({
    ...obj,
    usageDate: dateToJson(obj.usageDate)
})
export const actualUsageEntityListFromJson: Project<ActualUsageEntityListJson, ActualUsageEntityList> = json => ({
    ...json,
    usageDate: dateFromJson(json.usageDate)
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'usageDate'> {
    usageDate: Date,
}

interface JsonRequired {
}

interface JsonOptional {
    actualUsageId: number
    partsNo: string,
    customerCode: string,
    externalRefNo: string,
    customerPartsNo: string,
    backNo: string,
    expCountry: string,
    supplierCode: string,
    model: string,
    usageDate: string,
    usageQty: number,
    decimalDigits: number
}