import { Route, Routes } from "react-router-dom"
import { AuthNavigate } from "../components/AuthNavigate/AuthNavigate"
import { NotFound } from "../layouts/NotFound/NotFound"

import { TDL010 } from "../layouts/others/TDL010/TDL010"
import { TDL011 } from "../layouts/others/TDL011/TDL011"

export function TodolistRoutes() {
    return <Routes>
        <Route path="/todolist/create" element={<AuthNavigate><TDL011 /></AuthNavigate>} />,
        <Route path="/todolist/view-:todoId" element={<AuthNavigate><TDL011 /></AuthNavigate>} />,
        <Route path="/todolist/edit-:todoId" element={<AuthNavigate><TDL011 /></AuthNavigate>} />,
        <Route path="/todolist" element={<AuthNavigate><TDL010 /></AuthNavigate>} />,
        <Route path="*" element={<NotFound scope="todo"/>} />,
    </Routes>
}