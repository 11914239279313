import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, RowActionProvider, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { StringItem } from "@rithe/form/dist/components/StringItem"
import Records from "@rithe/utils/dist/base/Records"
import React, { useCallback, useMemo } from "react"
import { useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { EditRedirectRowAction } from "../../../components/DataGrid/rowActions/EditRedirectRowAction"
import { ViewRedirectRowAction } from "../../../components/DataGrid/rowActions/ViewRedirectRowAction"
import { CreateRedirectToolbarAction } from "../../../components/DataGrid/toolbarActions/CreateRedirectToolbarAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CodesItem } from "../../../components/Form/CodesItem"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { PlaceSpotStatus } from "../../../services/master/enums/PlaceSpotStatus"
import { SpotView } from "../../../services/order/models/SpotView"
import { useGetCompanyType } from "../../../utils/ApplicationUtils"

interface OCS020PcUiProps {
    filters: SpotView,
    setFilters: React.Dispatch<React.SetStateAction<SpotView>>,
    search: (filters: SpotView) => void,
    data: SpotView[],
    totalCount: number,
}

export const OCS020PcUi = (props: OCS020PcUiProps) => {

    return <SectionCard>
        <SectionCardContent>
            <DataTable {...props} />
        </SectionCardContent>
    </SectionCard>
}

const DataTable = ({ filters, setFilters, data, search }: OCS020PcUiProps) => {
    const intl = useIntl()
    const columns = useMemo(() => [
        { field: 'customerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerCode' }), width: 250 },
        { field: 'orderRefNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.orderReference' }), width: 250 },
        { field: 'shippingMode', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.shippingMode' }), width: 250 },
        { field: 'placeDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'placeDate' }), width: 250 },
        { field: 'submitDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'submitDate' }), width: 250 },
        { field: 'status', dataTypeName: CodeCategory.PlaceSpotStatus, title: intl.formatMessage({ id: 'status' }), width: 250 },
    ], [intl])
    const getRowId = useCallback((row: any) => row.orderId, [])
    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search }), [filters, setFilters, search])

    const display1 = useCallback((tableRow: TableRow) => tableRow.row?.companyUid !== undefined && tableRow.row?.companyUid !== null, [])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.ShippingMode} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.PlaceSpotStatus} />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={CreateAction} actionProps={{}} />
        <RowActionProvider name="view" Action={ViewRowAction} actionProps={display1} />
        <RowActionProvider name="edit" Action={EditRowAction} actionProps={display1} />
        <ColumnFreeze />
        <ColumnVisibility defaultHiddenFields={['shippingMode', '', '',]}
            columnSettings={{
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Action width={300} />
    </DataGrid>
}

const FilterItem = (props: {
    filters: any,
    search: (filters: any) => void,
    setFilters: React.Dispatch<React.SetStateAction<any>>,
}) => {
    const { filters, search, setFilters } = props

    const clear = useCallback((filters: any) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: any) => {
        return [
            filters.companyCode,
            filters.status,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])

    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="customerCode" label={intl.formatMessage({ id: 'field.customerCode' })} />
        <CodesItem field="statusList" label={intl.formatMessage({ id: 'status' })} code={CodeCategory.PlaceSpotStatus} />
    </FilterToolbarItem>
}

const CreateAction = () => {
    if (useGetCompanyType() !== CbdsType.CUST) return <></>
    return <CreateRedirectToolbarAction path="/placecustorder-sowc/create" />
}

const ViewRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/placecustorder-sowc/view-${tableRow.rowId}`, [])
    if (useGetCompanyType() !== CbdsType.CUST) return <></>
    return <ViewRedirectRowAction tableRow={tableRow} path={path} />
}

const EditRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const status = tableRow.row?.status
    const path = useCallback((tableRow: TableRow) => `/placecustorder-sowc/edit-${tableRow.rowId}`, [])
    if (useGetCompanyType() !== CbdsType.CUST || status === PlaceSpotStatus.Confirmed) return <></>
    return <EditRedirectRowAction tableRow={tableRow} path={path} />
}
