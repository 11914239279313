export enum TabId {
    order,
    forecast,
    ONS040
}
export interface ViewTabInfo {
    access: string,
    tabId: TabId,
    label: string,
    navigation: string
}

export const ViewTabList: ViewTabInfo[] = [
    {
        access: "ORDER.ONS040ORDER.VIEW",
        tabId: TabId.order,
        label: "orderChange/Cancel",
        navigation: "/bro-order"
    }, {
        access: "ORDER.ONS040FORECAST.VIEW",
        tabId: TabId.forecast,
        label: "forecastChange",
        navigation: "/bro-forecast"
    }
]