import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, RowActionProvider, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { DateRangeItem, EntriesItem, StringItem } from "@rithe/form"
import { arrx, Records } from "@rithe/utils"
import React, { SetStateAction, useCallback, useMemo } from "react"
import { useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { EditRedirectRowAction } from "../../../components/DataGrid/rowActions/EditRedirectRowAction"
import { ViewRedirectRowAction } from "../../../components/DataGrid/rowActions/ViewRedirectRowAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { ContractRequestType } from "../../../services/master/enums/ContaractRequestType"
import { ContractRequestStatus } from "../../../services/master/enums/ContractRequestStatus"
import { RequestFactor } from "../../../services/master/models/RequestFactor"
import { RequestResult } from "../../../services/master/models/RequestResult"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import sessionKeys from "../../../utils/sessionKeys"
//import { useFieldChecker, useFormValidater } from "../../../utils/ValidatorUtils";
import { FromTo } from "./MOS030"

const searchCachekey = sessionKeys.Data_MOS031
interface MOS030PcUiProps {
    path: string,
    filters: RequestFactor,
    search: (filters: RequestFactor) => void,
    data: RequestResult[],
    totalCount: number,
    requestToList: TnvCbds[],
    requestFromList: TnvCbds[],
    setFilters: React.Dispatch<SetStateAction<RequestFactor>>,
    fromTo: number
}

export const MOS030PcUi = (props: MOS030PcUiProps) => {
    const { path } = props

    if (path === "/masterOverView/request") {
        return <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    } else {
        return <View flex>
            <SectionCard>
                <SectionCardContent>
                    <DataTable {...props} />
                </SectionCardContent>
            </SectionCard>
        </View>
    }
}

const DataTable = ({ filters, data, setFilters, requestFromList, requestToList, search, fromTo, path }: MOS030PcUiProps) => {
    const intl = useIntl()

    const columns = useMemo(() => arrx(
        { field: 'requestNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.requestNo' }), width: 230 },
        { field: 'description', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.description' }), width: 180 },
        { field: 'requestType', dataTypeName: CodeCategory.ContractRequestType, title: intl.formatMessage({ id: 'field.requestType' }), width: 180 },
        { field: 'requestFrom', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.requestFrom' }), width: 180 },
        { field: 'requestTo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.requestTo' }), width: 180 },
        { field: 'requestBy', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.requestBy' }), width: 180 },
        { field: 'status', dataTypeName: CodeCategory.ContractRequestStatus, title: intl.formatMessage({ id: 'field.status' }), width: 110 },
        { field: 'requestDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.requestDate' }), width: 180 },
        { field: 'submittedBy', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.submittedBy' }), width: 180 },
        { field: 'submittedDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.submittedDate' }), width: 180 },
        { field: 'createdDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.createdDate' }), width: 180 },
        { field: 'updatedDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.updatedDate' }), width: 180 },
    ), [intl])
    const getRowId = useCallback((row: any) => row.requestId, [])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search, requestToList, requestFromList, fromTo }), [filters, setFilters, search, requestToList, requestFromList, fromTo])
    const ViewOrEditAction = useMemo(() => ({ path }), [path])
    const display1 = useCallback((tableRow: TableRow) => tableRow.row?.status !== ContractRequestStatus.COMPLETED, [])

    return <>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.ContractRequestType} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.ContractRequestStatus} />
            <Data rows={data} columns={columns} getRowId={getRowId} />
            <RowActionProvider name="view" Action={ViewRowAction} actionProps={ViewOrEditAction} />
            <RowActionProvider name="edit" Action={EditRowAction} actionProps={ViewOrEditAction} display={display1} />

            <ColumnFreeze />
            <ColumnVisibility
                defaultHiddenFields={['submittedBy', 'createdDate', 'updatedDate']}
                columnSettings={{
                    requestNo: { disableUserControl: true },
                }} ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
            <Sorting />
            <Filtering />
            <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
            <Action width={200} />
        </DataGrid>
    </>
}


const FilterItem = (props: {
    search: (filters: RequestFactor) => void,
    filters: RequestFactor,
    setFilters: React.Dispatch<SetStateAction<RequestFactor>>,
    requestToList: TnvCbds[],
    requestFromList: TnvCbds[],
    fromTo: number
}) => {
    const { filters, requestFromList, requestToList, setFilters, search, fromTo } = props
    const requestToEntries: [string, string][] = useMemo(() => requestToList.map(m => [m.cbdsUid, m.cbdsCode]), [requestToList])
    const requestFromEntries: [string, string][] = useMemo(() => requestFromList.map(m => [m.cbdsUid, m.cbdsCode]), [requestFromList])

    const createdDateGetValue = useCallback((filters: RequestFactor) => {
        return [filters.requestDateStart ?? null, filters.requestDateEnd ?? null]
    }, [])

    const createdDateMapValue = useCallback((filters: RequestFactor, value: any) => {
        return { ...filters ?? {}, requestDateStart: value[0], requestDateEnd: value[1] }
    }, [])

    const updatedDateGetValue = useCallback((filters: RequestFactor) => {
        return [filters.submittedDateStart ?? null, filters.submittedDateEnd ?? null]
    }, [])

    const updatedDateMapValue = useCallback((filters: RequestFactor, value: any) => {
        return { ...filters ?? {}, submittedDateStart: value[0], submittedDateEnd: value[1] }
    }, [])

    const clear = useCallback((filters: RequestFactor) => {
        return { page: filters.page }
    }, [])
    const intl = useIntl()
    const show = fromTo === FromTo.FROM ? true : false


    const filterCounter = useCallback((filters: RequestFactor) => {
        return [
            filters.requestNo,
            filters.description,
            filters.requestTypeList,
            filters.requestFromList,
            filters.requestToList,
            filters.statusList,
            filters.requestBy,
            filters.submittedBy,
            filters.requestDateStart || filters.requestDateEnd,
            filters.submittedDateStart || filters.submittedDateEnd,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])


    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="requestNo" label={intl.formatMessage({ id: 'field.requestNo' })} />
        <StringItem field="description" label={intl.formatMessage({ id: 'field.description' })} />
        <CodesItem field="requestTypeList" label={intl.formatMessage({ id: 'field.requestType' })} code={CodeCategory.ContractRequestType} />
        {show &&
            <EntriesItem field="requestFromList" label={intl.formatMessage({ id: 'field.requestFrom' })} entries={requestFromEntries} />}
        {show &&
            <EntriesItem field="requestToList" label={intl.formatMessage({ id: 'field.requestTo' })} entries={requestToEntries} />}
        <CodesItem field="statusList" label={intl.formatMessage({ id: 'field.status' })} code={CodeCategory.ContractRequestStatus} />
        <StringItem field="requestBy" label={intl.formatMessage({ id: 'field.requestBy' })} />
        <StringItem field="submittedBy" label={intl.formatMessage({ id: 'field.submittedBy' })} />
        <DateRangeItem field="requestDate" label={intl.formatMessage({ id: 'field.requestDate' })} getValue={createdDateGetValue} mapValue={createdDateMapValue} />
        <DateRangeItem field="submittedDate" label={intl.formatMessage({ id: 'field.submittedDate' })} getValue={updatedDateGetValue} mapValue={updatedDateMapValue} />
    </FilterToolbarItem>
}

const EditRowAction = ({ tableRow, path }: DataGridRowActionProps & { path: string }) => {
    sessionStorage.removeItem(searchCachekey)
    let pagePath = ''
    if (tableRow.row?.requestType === ContractRequestType.ADD) {
        pagePath = `/receivedRequest/addNewPart-${tableRow.row?.requestNo}`
    } else if (tableRow.row?.requestType === ContractRequestType.MODIFY_PACKING) {
        pagePath = `/receivedRequest/modify-${tableRow.row?.requestNo}`
    } else if (tableRow.row?.requestType === ContractRequestType.REMOVE) {
        pagePath = `/receivedRequest/remove-${tableRow.row?.requestNo}`
    } else if (tableRow.row?.requestType === ContractRequestType.TERMINATE) {
        pagePath = `/receivedRequest/terminate-${tableRow.row?.requestNo}`
    } else if (tableRow.row?.requestType === ContractRequestType.MODIFY_PRICE) {
        pagePath = `/receivedRequest/modifyPrice-${tableRow.row?.requestNo}`
    }

    const toPath = useCallback((tableRow: TableRow) => pagePath, [pagePath])

    return <EditRedirectRowAction access="MARS.MOS030.EDIT" tableRow={tableRow} path={toPath} />
}

const ViewRowAction = ({ tableRow, path }: DataGridRowActionProps & { path: string }) => {
    sessionStorage.removeItem(searchCachekey)
    let pagePath = ''
    if (tableRow.row?.requestType === ContractRequestType.ADD) {
        pagePath = `/receivedRequest/addNewPartView-${tableRow.row?.requestNo}`
    } else if (tableRow.row?.requestType === ContractRequestType.MODIFY_PACKING) {
        pagePath = `/receivedRequest/modifyView-${tableRow.row?.requestNo}`
    } else if (tableRow.row?.requestType === ContractRequestType.REMOVE) {
        pagePath = `/receivedRequest/removeView-${tableRow.row?.requestNo}`
    } else if (tableRow.row?.requestType === ContractRequestType.TERMINATE) {
        pagePath = `/receivedRequest/terminateView-${tableRow.row?.requestNo}`
    } else if (tableRow.row?.requestType === ContractRequestType.MODIFY_PRICE) {
        pagePath = `/receivedRequest/modifyPriceView-${tableRow.row?.requestNo}`
    }
    const toPath = useCallback((tableRow: TableRow) => pagePath, [pagePath])
    return <ViewRedirectRowAction access="MARS.MOS030.VIEWDETAIL" tableRow={tableRow} path={toPath} />
}