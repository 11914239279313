import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"

export type OutboundPartsResult = Required & Partial<Optional>

type OutboundPartsResultJson = JsonRequired & Partial<JsonOptional>

export const outboundPartsResultFromJson: Project<OutboundPartsResultJson, OutboundPartsResult> = json => ({
    ...json,
    inboundDate: dateFromJson(json.inboundDate),
})

interface Required extends JsonRequired { }

interface Optional extends Omit<JsonOptional, 'inboundDate'> {
    inboundDate: Date,
}

interface JsonRequired {
}

interface JsonOptional {
    partsNo: string,
    shipperCode: string,
    reciverCode: string,
    packageNo: string,
    inboundDate: string,
}
