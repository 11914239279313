import { DialogActions, DialogContent, DialogTitle, Divider, Grid, makeStyles, Typography } from "@material-ui/core"
import { Column, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Editing, Filtering, NumberTypeProvider, PaginationLayout, Paging, Row, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { AutoCompleteItem, Break, DateItem, EntriesItem, EntryItem, Form, Placeholder, StringItem } from "@rithe/form"
import { GridItem } from "@rithe/ui"
import { ArrayMultimaps, Arrays, arrx, Maps, Multisets, Records } from "@rithe/utils"
import clsx from 'clsx'
import React, { ReactNode, useCallback, useMemo, useState } from "react"
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from "react-intl"
import { SaveCallbackViewAction } from "../../../components/Action/SaveCallbackViewAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CardDialog } from "../../../components/Dialog/CardDialog"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { Scrollbar } from "../../../components/Scrollbar"
import { SectionTitle } from "../../../components/SectionTitle/SectionTitle"
import { View } from "../../../components/View/View"
import { Currency, GiGrInvoice } from "../../../services/accounting/models/GiGrInvoice"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { TnmIncoterms } from "../../../services/master/models/TnmIncoterms"
import { TnmPaymentTerms } from "../../../services/master/models/TnmPaymentTerms"
import { TnmRegion } from "../../../services/master/models/TnmRegion"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { AIS021BreakdownPcUi } from "./AIS021BreakdownPcUi"

interface AIS021PcUiProps {
    editing: boolean,
    invoice?: GiGrInvoice,
    setInvoice?: React.Dispatch<React.SetStateAction<GiGrInvoice | undefined>>,
    onSave: () => void,
    paymentTerms: TnmPaymentTerms[],
    incoterms: TnmIncoterms[],
    partys: TnvCbds[],
    regions: TnmRegion[],
    search: () => void,
}

export const AIS021PcUi = ({ editing, invoice, setInvoice, onSave, paymentTerms, incoterms, partys, regions, search }: AIS021PcUiProps) => {
    const intl = useIntl()

    return <View actions={arrx(editing && <SaveCallbackViewAction callback={onSave} />)}>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={1}
                title={intl.formatMessage({ id: 'summaryInfo' })}
                subtitle={intl.formatMessage({ id: 'summaryInfoSub' })}
            />
            <SectionCardContent>
                <CoverPage editing={editing} invoice={invoice} setInvoice={setInvoice} paymentTerms={paymentTerms} incoterms={incoterms} partys={partys} regions={regions} search={search} />
            </SectionCardContent>
        </SectionCard>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={2}
                title={intl.formatMessage({ id: 'packageDetailInfo' })}
                subtitle={intl.formatMessage({ id: 'packageDetailInfoSub' })}
            />
            <SectionCardContent>
                <PackageDetailView editing={editing} invoice={invoice} setInvoice={setInvoice} />
            </SectionCardContent>
        </SectionCard>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={3}
                title={intl.formatMessage({ id: 'partsDetailInfo' })}
                subtitle={intl.formatMessage({ id: 'partsDetailInfoSub' })}
            />
            <SectionCardContent>
                <PartsDetailView editing={editing} invoice={invoice} setInvoice={setInvoice} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const CoverPage = ({ editing, invoice, setInvoice, paymentTerms, incoterms, partys, regions, search }: {
    editing: boolean,
    invoice?: GiGrInvoice,
    setInvoice?: React.Dispatch<React.SetStateAction<GiGrInvoice | undefined>>,
    paymentTerms: TnmPaymentTerms[],
    incoterms: TnmIncoterms[],
    partys: TnvCbds[],
    regions: TnmRegion[],
    search: () => void,
}) => {
    const orderNos = Arrays.distinct(
        invoice?.details.map(detail => detail.poNo) ?? []
    )

    const seller = useMemo(() => invoice?.invoices ? invoice?.invoices[0].seller : (invoice?.seller), [invoice?.seller, invoice?.invoices])
    const accountee = useMemo(() => invoice?.invoices ? invoice?.invoices[0].accountee : (invoice?.accountee), [invoice?.accountee, invoice?.invoices])
    const consignee = useMemo(() => invoice?.invoices ? invoice?.invoices[0].consignee : (invoice?.consignee), [invoice?.consignee, invoice?.invoices])
    const deliveryTo = useMemo(() => invoice?.invoices ? invoice?.invoices[0].deliveryTo : (invoice?.deliveryTo), [invoice?.deliveryTo, invoice?.invoices])
    const contianers = useMemo(() => invoice?.invoices ? (invoice?.invoices?.flatMap(f => f.containers) ?? []) : (invoice?.containers ?? []), [invoice?.containers, invoice?.invoices])
    const packageSums = Arrays.from(
        Multisets.from(
            contianers.flatMap(container => container.packages.map(pack => pack.packageType)).filter(v => v) as string[] ?? []
        ).asMap()
    )
    const quantitySums = Arrays.from(
        Maps.transform(
            ArrayMultimaps.from(invoice?.details.map(detail => [detail.part?.uom?.uomCode, [detail.part?.uom?.decimalDigits, detail.qty]]) ?? []).asMap(),
            ([, data]) => data.reduce((acc, d) => [d[0], (acc[1] ?? 0) + (d[1] ?? 0)], [0, 0])
        )
    )
    const totalNetWeight = contianers.flatMap(container => container.packages).map(pack => pack.netWeight).reduce((acc, weight) => (acc ?? 0) + (weight ?? 0), 0) ?? 0
    const totalGrossWeight = contianers.flatMap(container => container.packages).map(pack => pack.grossWeight).reduce((acc, weight) => (acc ?? 0) + (weight ?? 0), 0) ?? 0
    const totalM3 = contianers.flatMap(container => container.packages).map(pack => pack.m3).reduce((acc, m3) => (acc ?? 0) + (m3 ?? 0), 0) ?? 0
    const packageNos = Arrays.distinct(contianers.flatMap(container => container.packages).map(pack => pack.packageNo) ?? [])
    const countryOfOrigins = invoice?.invoices ? Arrays.distinct(invoice?.invoices.flatMap(m => m.countryOfOrigin?.split(",") ?? [])) : (invoice?.countryOfOrigin?.split(",") ?? [])
    const vesselName = invoice?.invoices ? Arrays.distinct(invoice?.invoices.map(m => (m.vesselName ?? '') + '/' + (m.voyageNo ?? '')) ?? []).join(",") : ((invoice?.vesselName ?? '') + '/' + (invoice?.voyageNo ?? ''))
    const incotermAndPlaces = invoice?.invoices ? Arrays.from(Maps.from(invoice?.invoices.map(m => [(m.incoterms?.code ?? '') + "," + (m.incoterms?.place ?? ''), m.incoterms]) ?? []).values()) : [invoice?.incoterms]
    const etdEtas = invoice?.invoices ? Arrays.from(Maps.from(invoice?.invoices.map(m => [m.etd?.getTime() + "," + m.eta?.getTime(), [m.etd, m.eta]]) ?? []).values()) : [[invoice?.etd, invoice?.eta]] as [Date | undefined, Date | undefined][]
    const placeAndOrders = invoice?.invoices ? Arrays.from(Maps.from(invoice?.invoices.map(m => [m.invoiceDate?.getTime(), m.invoiceDate]) ?? []).values()) : [invoice?.invoiceDate] as (Date | undefined)[]
    const remark = invoice?.invoices ? Arrays.distinct(invoice?.invoices.map(m => (m.remark ?? '')) ?? []).join(",") : invoice?.remark ?? ''
    const paymentTermInfos = invoice?.invoices ? Arrays.from(Maps.from(invoice?.invoices.map(m => [m.paymentTerms?.description, m.paymentTerms]) ?? []).values()) : [invoice?.paymentTerms]
    const ports = invoice?.invoices ? Arrays.from(Maps.from(invoice?.invoices.map(m => [(m?.loadingPort?.portName ?? '') + "," + (m?.dischargePort?.portName ?? '') + "," + m?.shippingMode, { loadingPort: m.loadingPort, dischargePort: m.dischargePort, shippingMode: m.shippingMode }]) ?? []).values()) : [{ loadingPort: invoice?.loadingPort, dischargePort: invoice?.dischargePort, shippingMode: invoice?.shippingMode }]


    const [consigneeEditorOpen, setConsigneeEditorOpen] = useState(false)
    const [accounteeEditorOpen, setAccounteeEditorOpen] = useState(false)
    const [deliveryToEditorOpen, setDeliveryToEditorOpen] = useState(false)
    const [vesselEditorOpen, setVesselEditorOpen] = useState(false)
    const [issueDateEditorOpen, setIssueDateEditorOpen] = useState(false)
    const [paymentEditorOpen, setPaymentEditorOpen] = useState(false)
    // const [incotermsEditorOpen, setIncotermsEditorOpen] = useState(false)
    const [etdEtaEditorOpen, setEtdEtaEditorOpen] = useState(false)
    const [remarkEditorOpen, setRemarkEditorOpen] = useState(false)
    const [shippingMarkEditorOpen, setShippingMarkEditorOpen] = useState(false)
    const [breakdownEditorOpen, setBreakdownEditorOpen] = useState(false)
    const invoices = useMemo(() => invoice?.invoices ? invoice?.invoices ?? [] : (invoice ? [invoice] : []), [invoice])

    const ifNeedShowInit = useCallback((invoice: any) => {
        const breakdown = invoice.breakdown
        return checkIfHasValue(breakdown.costItem1, breakdown.costItem1Amount)
            || checkIfHasValue(breakdown.additionalItem1, breakdown.additionalItem1Amount)
            || breakdown.initialIncotermsId !== breakdown.finalIncotermsId
    }, [])

    const styles = useStyles()
    return <div className={styles.coverPageContainer}>
        <div className={styles.coverPageUp}>
            <DataBlock title={<FormattedMessage id="field.invoiceNo" />}>
                <Typography variant="body2">{invoice?.invoiceNo + (invoice?.externalInvoiceNo ?  `(${invoice?.externalInvoiceNo})` : '')}</Typography>
            </DataBlock>
            <DataBlock title={<FormattedMessage id="sellerHeaderInfo" />} colSpan={2} rowSpan={2}>
                <Typography variant="body2" className={styles.text}>{seller?.cbdsName}</Typography>
                <Typography variant="body2" className={styles.text}>{seller?.contact?.address1}&nbsp;</Typography>
                <Typography variant="body2" className={styles.text}>{seller?.contact?.address2}&nbsp;</Typography>
                <Typography variant="body2" className={styles.text}>{seller?.contact?.address3}&nbsp;</Typography>
                <Typography variant="body2" className={styles.text}>{seller?.contact?.address4}&nbsp;</Typography>
                <Typography variant="body2" className={styles.text}><FormattedMessage id="tel" />:{seller?.contact?.tel}&nbsp;&nbsp;&nbsp;&nbsp;<FormattedMessage id="fax" />:{seller?.contact?.fax}</Typography>
            </DataBlock>
            <DataBlockFlex title={<FormattedMessage id="placeAndDate" />} editable={editing} onClick={() => setIssueDateEditorOpen(true)}>
                {placeAndOrders.map(m => {
                    return <div style={{ width: 120 }} >
                        <Typography variant="body2">{seller?.regionName}</Typography>
                        <Typography variant="body2"><FormattedDate value={m} dateStyle="medium" /></Typography>
                    </div>
                })}
            </DataBlockFlex>
            {issueDateEditorOpen && <IssueDateEditor title={<FormattedMessage id="placeAndDate" />} invoice={invoice} onInvoiceChange={setInvoice} open={issueDateEditorOpen} onClose={() => setIssueDateEditorOpen(false)} />}
        </div>
        <Divider style={{ gridColumn: 'span 3' }} />
        <div className={styles.coverPageLeft}>
            <DataBlock title={<FormattedMessage id="consigneeInfo" />} colSpan={2} editable={editing} onClick={() => setConsigneeEditorOpen(true)}>
                <Typography variant="body2" className={styles.text}>{consignee?.cbdsName}</Typography>
                <Typography variant="body2" className={styles.text}>{consignee?.contact?.address1}&nbsp;</Typography>
                <Typography variant="body2" className={styles.text}>{consignee?.contact?.address2}&nbsp;</Typography>
                <Typography variant="body2" className={styles.text}>{consignee?.contact?.address3}&nbsp;</Typography>
                <Typography variant="body2" className={styles.text}>{consignee?.contact?.address4}&nbsp;</Typography>
                <Typography variant="body2" className={styles.text}><FormattedMessage id="tel" />:{consignee?.contact?.tel}&nbsp;&nbsp;&nbsp;&nbsp;<FormattedMessage id="fax" />:{consignee?.contact?.fax}</Typography>
            </DataBlock>
            {consigneeEditorOpen && <ConsigneeEditor title={<FormattedMessage id="consigneeInfo" />} invoice={invoice} onInvoiceChange={setInvoice} open={consigneeEditorOpen} onClose={() => setConsigneeEditorOpen(false)} partys={partys} />}
            <DataBlock title={<FormattedMessage id="orderNo" />} colSpan={2}>
                {orderNos.map(orderNo => <Typography key={orderNo} variant="body2" className={styles.text}>{orderNo}</Typography>)}
            </DataBlock>
            <DataBlock title={<FormattedMessage id="vesselAndCarrier" />} editable={editing} onClick={() => setVesselEditorOpen(true)}>
                <Typography variant="body2" className={styles.text}>{vesselName}</Typography>
            </DataBlock>
            {vesselEditorOpen && <VesselEditor title={<FormattedMessage id="vesselAndCarrier" />} invoice={invoice} onInvoiceChange={setInvoice} open={vesselEditorOpen} onClose={() => setVesselEditorOpen(false)} />}
            <DataBlockFlex title={<FormattedMessage id="etdOrEta" />} editable={editing} onClick={() => setEtdEtaEditorOpen(true)}>
                {etdEtas.map(m => {
                    return <div style={{ width: 150 }} >
                        <Typography variant="body2">ETD: {m[0] ? <FormattedDate value={m[0]} dateStyle="medium" /> : ''}</Typography>
                        <Typography variant="body2">ETA: {m[1] ? <FormattedDate value={m[1]} dateStyle="medium" /> : ''}</Typography>
                    </div>
                })}
            </DataBlockFlex>
            {etdEtaEditorOpen && <EtdEtaEditor title={<FormattedMessage id="etdOrEta" />} invoice={invoice} onInvoiceChange={setInvoice} open={etdEtaEditorOpen} onClose={() => setEtdEtaEditorOpen(false)} />}
            <DataBlock title={<FormattedMessage id="portAndShippingMode" />} colSpan={2}>
                {ports.map(port => {
                    return <Typography variant="body2">FROM: {port?.loadingPort?.portName ?? ''}  TO: {port?.dischargePort?.portName ?? ''}  VIA: {port?.shippingMode ? <FormattedMessage id={`${CodeCategory.ShippingMode}_${port?.shippingMode}`} /> : ''}</Typography>
                })}
            </DataBlock>
            <DataBlock title={<FormattedMessage id="paymentTerms" />} editable={editing} onClick={() => setPaymentEditorOpen(true)}>
                {paymentTermInfos.map(m => <Typography variant="body2" className={styles.text}>{m?.description ?? ''}</Typography>)}
            </DataBlock>
            {paymentEditorOpen && <PaymentTermsEditor title={<FormattedMessage id="paymenttermInfo" />} invoice={invoice} onInvoiceChange={setInvoice} open={paymentEditorOpen} paymentTerms={paymentTerms} onClose={() => setPaymentEditorOpen(false)} />}
            <DataBlock title={<FormattedMessage id="incoterms" />} editable={false} >
                {incotermAndPlaces.map(m => <Typography variant="body2" className={styles.text}>{(m?.code ?? '') + " " + (m?.place ?? '')}</Typography>)}
            </DataBlock>
            {/* {incotermsEditorOpen && <IncotermsEditor title={<FormattedMessage id="incotermsInfo" />} invoice={invoice} onInvoiceChange={setInvoice} open={incotermsEditorOpen} incortems={incoterms} onClose={() => setIncotermsEditorOpen(false)} />} */}
            <DataBlock title={<FormattedMessage id="quantity" />} colSpan={2}>
                <Grid container>
                    <Grid item xs={6}>
                        {packageSums.map((packageSum, index) => <Typography key={index} variant="body2">{packageSum[1]} {packageSum[0]}</Typography>)}
                    </Grid>
                    <Grid item xs={6}>
                        {quantitySums.map((quantitySum, index) => <Typography key={index} variant="body2"><FormattedNumber value={quantitySum[1][1] ?? 0} minimumFractionDigits={quantitySum[1][0]} maximumFractionDigits={quantitySum[1][0]} /> {quantitySum[0]}</Typography>)}
                    </Grid>
                </Grid>
            </DataBlock>
            <DataBlock title={<FormattedMessage id="packageWeights" />} colSpan={2}>
                <Grid container>
                    <Grid item xs={4} container direction="column">
                        <Typography variant="body2"><FormattedMessage id="totalNetWeight" /></Typography>
                        <Typography variant="body2"><FormattedMessage id="totalGrossWeight" /></Typography>
                        <Typography variant="body2"><FormattedMessage id="totalM3" /></Typography>
                    </Grid>
                    <Grid item xs={4} container direction="column" alignItems="flex-end">
                        <Typography variant="body2"><FormattedNumber value={totalNetWeight} minimumFractionDigits={3} maximumFractionDigits={3} /> KG</Typography>
                        <Typography variant="body2"><FormattedNumber value={totalGrossWeight} minimumFractionDigits={3} maximumFractionDigits={3} /> KG</Typography>
                        <Typography variant="body2"><FormattedNumber value={totalM3} minimumFractionDigits={3} maximumFractionDigits={3} /> M<sup>3</sup></Typography>
                    </Grid>
                </Grid>
            </DataBlock>
            <DataBlock title={<FormattedMessage id="accounteeInfo" />} editable={editing} onClick={() => setAccounteeEditorOpen(true)}>
                <Typography variant="body2" className={styles.text}>{accountee?.cbdsName}</Typography>
                <Typography variant="body2" className={styles.text}>{accountee?.contact?.address1}&nbsp;</Typography>
                <Typography variant="body2" className={styles.text}>{accountee?.contact?.address2}&nbsp;</Typography>
                <Typography variant="body2" className={styles.text}>{accountee?.contact?.address3}&nbsp;</Typography>
                <Typography variant="body2" className={styles.text}>{accountee?.contact?.address4}&nbsp;</Typography>
                <Typography variant="body2" className={styles.text}><FormattedMessage id="tel" />:{accountee?.contact?.tel}&nbsp;&nbsp;&nbsp;&nbsp;<FormattedMessage id="fax" />:{accountee?.contact?.fax}</Typography>
            </DataBlock>
            {accounteeEditorOpen && <AccounteeEditor title={<FormattedMessage id="accounteeInfo" />} invoice={invoice} onInvoiceChange={setInvoice} open={accounteeEditorOpen} onClose={() => setAccounteeEditorOpen(false)} partys={partys} />}
            <DataBlock title={<FormattedMessage id="destinationInfo" />} editable={editing} onClick={() => setDeliveryToEditorOpen(true)}>
                <Scrollbar >
                    <Typography variant="body2" className={styles.text}>{deliveryTo?.cbdsName}</Typography>
                    <Typography variant="body2" className={styles.text}>{deliveryTo?.contact?.address1}&nbsp;</Typography>
                    <Typography variant="body2" className={styles.text}>{deliveryTo?.contact?.address2}&nbsp;</Typography>
                    <Typography variant="body2" className={styles.text}>{deliveryTo?.contact?.address3}&nbsp;</Typography>
                    <Typography variant="body2" className={styles.text}>{deliveryTo?.contact?.address4}&nbsp;</Typography>
                    <Typography variant="body2"><FormattedMessage id="tel" />:{deliveryTo?.contact?.tel}&nbsp;&nbsp;&nbsp;&nbsp;<FormattedMessage id="fax" />:{deliveryTo?.contact?.fax}</Typography>
                </Scrollbar>
            </DataBlock>
            {deliveryToEditorOpen && <DeliveryToEditor title={<FormattedMessage id="deliveryToInfo" />} invoice={invoice} onInvoiceChange={setInvoice} open={deliveryToEditorOpen} onClose={() => setDeliveryToEditorOpen(false)} partys={partys} />}
            <DataBlock title={<FormattedMessage id="remark" />} colSpan={2} editable={editing} onClick={() => setRemarkEditorOpen(true)}>
                <Typography variant="body2" style={{ overflowWrap: 'break-word' }}>{remark}</Typography>
            </DataBlock>
            {remarkEditorOpen && <RemarkEditor title={<FormattedMessage id="remark" />} invoice={invoice} onInvoiceChange={setInvoice} open={remarkEditorOpen} onClose={() => setRemarkEditorOpen(false)} />}
        </div>
        <div className={styles.coverPageRight}>
            <div>
                <DataBlock title={<FormattedMessage id="shippingMarks" />} editable={editing} onClick={() => setShippingMarkEditorOpen(true)}>
                    <Typography variant="body2"><FormattedMessage id="shippingMark" /></Typography>
                    <Typography variant="body2">{invoice?.invoiceNo}</Typography>
                    {/* <Typography variant="body2">{seller?.regionName}</Typography>  */}
                    <Typography variant="body2">{countryOfOrigins.length > 0 ? countryOfOrigins.join(",") : ''}</Typography>
                    <Typography variant="body2"><FormattedMessage id="cNo" /></Typography>
                    {/* {packageNos.map(packageNo => <Typography key={packageNo} variant="body2">{packageNo}</Typography>)} */}
                    <Grid container>
                        {Arrays.range(0, 20).map(indx => {
                            return <>
                                {indx < packageNos.length && <Grid item xs={4}><Typography key={packageNos[indx]} variant="body2">{packageNos[indx]}</Typography></Grid>}
                                {indx + 20 < packageNos.length ?
                                    <Grid item xs={4}><Typography key={packageNos[indx + 20]} variant="body2">{packageNos[indx + 20]}</Typography></Grid>
                                    : (indx < packageNos.length ? <Grid item xs={4}></Grid> : <></>)}
                                {indx + 40 < packageNos.length ?
                                    <Grid item xs={4}><Typography key={packageNos[indx + 40]} variant="body2">{packageNos[indx + 40]}</Typography></Grid>
                                    : (indx < packageNos.length ? <Grid item xs={4}></Grid> : <></>)}
                            </>
                        })}
                    </Grid>
                </DataBlock>
                {shippingMarkEditorOpen &&
                    <ShippingMarkEditor
                        title={<FormattedMessage id="shippingMark" />}
                        invoice={invoice}
                        onInvoiceChange={setInvoice}
                        open={shippingMarkEditorOpen}
                        onClose={() => setShippingMarkEditorOpen(false)}
                        regions={regions}
                    />}
                <DataBlock title={<FormattedMessage id="incotermBreakdown" />} editable={editing} allwaysClick={true} onClick={() => setBreakdownEditorOpen(true)}>
                    {invoices && invoices.map(invoice => {
                        return <div>
                            {
                                ifNeedShowInit(invoice) ? <>
                                    <Grid container>
                                        <Grid item xs={2} container direction="column">
                                            <Typography variant="body2">{invoice?.breakdown?.initialIncotermsCode}</Typography>
                                        </Grid>
                                        <Grid item xs={6} container direction="column" alignItems="flex-start">
                                            <Typography variant="body2">{invoice?.breakdown?.initialIncotermsPlace}</Typography>
                                        </Grid>
                                        <Grid item xs={4} container direction="column" alignItems="flex-end">
                                            <Typography variant="body2">{invoice?.invoiceCurrency?.currencyCode} <FormattedNumber value={invoice?.breakdown?.initialIncotermsAmount ?? 0} minimumFractionDigits={invoice?.invoiceCurrency?.decimalDigits} maximumFractionDigits={invoice?.invoiceCurrency?.decimalDigits} /></Typography>
                                        </Grid>
                                    </Grid>
                                    <ItemBreakDown itemName={invoice?.breakdown?.costItem1} itemAmount={invoice?.breakdown?.costItem1Amount} currency={invoice?.invoiceCurrency} />
                                    <ItemBreakDown itemName={invoice?.breakdown?.costItem2} itemAmount={invoice?.breakdown?.costItem2Amount} currency={invoice?.invoiceCurrency} />
                                    <ItemBreakDown itemName={invoice?.breakdown?.costItem3} itemAmount={invoice?.breakdown?.costItem3Amount} currency={invoice?.invoiceCurrency} />
                                    <ItemBreakDown itemName={invoice?.breakdown?.costItem4} itemAmount={invoice?.breakdown?.costItem4Amount} currency={invoice?.invoiceCurrency} />
                                    <ItemBreakDown itemName={invoice?.breakdown?.costItem5} itemAmount={invoice?.breakdown?.costItem5Amount} currency={invoice?.invoiceCurrency} />

                                    <Grid item container xs={12}><div className={styles.subDivider} ><Divider /></div></Grid>
                                    <Grid container>
                                        <Grid item xs={6} container direction="column">
                                            <Typography variant="body2">SUB TOTAL</Typography>
                                        </Grid>
                                        <Grid item xs={6} container direction="column" alignItems="flex-end">
                                            <Typography variant="body2">{invoice?.invoiceCurrency?.currencyCode} <FormattedNumber value={(invoice?.breakdown?.invoiceAmount ?? 0) + (invoice?.breakdown?.invoiceAdjustmentAmount ?? 0)} minimumFractionDigits={invoice?.invoiceCurrency?.decimalDigits} maximumFractionDigits={invoice?.invoiceCurrency?.decimalDigits} /></Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item container xs={12}>
                                        <div style={{ width: '100%', alignItems: 'center', padding: 8 }}></div>
                                    </Grid>
                                </> : <></>
                            }
                            <ItemBreakDown itemName={invoice?.breakdown?.additionalItem1} itemAmount={invoice?.breakdown?.additionalItem1Amount} currency={invoice?.invoiceCurrency} />
                            <ItemBreakDown itemName={invoice?.breakdown?.additionalItem2} itemAmount={invoice?.breakdown?.additionalItem2Amount} currency={invoice?.invoiceCurrency} />
                            <ItemBreakDown itemName={invoice?.breakdown?.additionalItem3} itemAmount={invoice?.breakdown?.additionalItem3Amount} currency={invoice?.invoiceCurrency} />
                            <ItemBreakDown itemName={invoice?.breakdown?.additionalItem4} itemAmount={invoice?.breakdown?.additionalItem4Amount} currency={invoice?.invoiceCurrency} />
                            <ItemBreakDown itemName={invoice?.breakdown?.additionalItem5} itemAmount={invoice?.breakdown?.additionalItem5Amount} currency={invoice?.invoiceCurrency} />
                            <Grid container>
                                <Grid item xs={2} container direction="column">
                                    <Typography variant="body2">{invoice?.breakdown?.finalIncotermsCode}</Typography>
                                </Grid>
                                <Grid item xs={6} container direction="column" alignItems="flex-start">
                                    <Typography variant="body2">{invoice?.breakdown?.finalIncotermsPlace} </Typography>
                                </Grid>
                                <Grid item xs={4} container direction="column" alignItems="flex-end">
                                    <Typography variant="body2">{invoice?.invoiceCurrency?.currencyCode} <FormattedNumber value={invoice?.breakdown?.finalIncotermsAmount ?? 0} minimumFractionDigits={invoice?.invoiceCurrency?.decimalDigits} maximumFractionDigits={invoice?.invoiceCurrency?.decimalDigits} /></Typography>
                                </Grid>
                            </Grid>

                            <Grid item container xs={12}><div className={styles.divider} ><Divider /></div></Grid>
                            <Grid container>
                                <Grid item xs={8} container direction="column">
                                    <Typography variant="body2" style={{ fontWeight: 'bold' }}><FormattedMessage id="GrandTotal" /></Typography>
                                </Grid>
                                <Grid item xs={4} container direction="column" alignItems="flex-end">
                                    <Typography variant="body2" style={{ fontWeight: 'bold' }}>{invoice?.invoiceCurrency?.currencyCode} <FormattedNumber value={invoice?.breakdown?.finalIncotermsAmount as number} minimumFractionDigits={invoice?.invoiceCurrency?.decimalDigits} maximumFractionDigits={invoice?.invoiceCurrency?.decimalDigits} /></Typography>
                                </Grid>
                            </Grid>
                        </div>
                    })}
                </DataBlock>
                {breakdownEditorOpen &&
                    <AIS021BreakdownPcUi
                        editable={editing}
                        title={<FormattedMessage id="incotermBreakdownView" />}
                        incoterms={incoterms}
                        invoice={invoice}
                        onInvoiceChange={setInvoice}
                        open={breakdownEditorOpen}
                        onClose={() => setBreakdownEditorOpen(false)}
                        search={search}
                    />}
                <DataBlock title={<FormattedMessage id="insuranceStamp" />}>
                </DataBlock>
                <DataBlock title={<FormattedMessage id="signature" />}>
                </DataBlock>
            </div>
        </div>
    </div >
}

const ItemBreakDown = ({ itemName, itemAmount, currency }: {
    itemName?: string,
    itemAmount?: number,
    currency?: Currency
}) => {
    return checkIfHasValue(itemName, itemAmount) ? <Grid container>
        <Grid item xs={8} container direction="column">
            <Typography variant="body2">{itemName}</Typography>
        </Grid>
        <Grid item xs={4} container direction="column" alignItems="flex-end">
            <Typography variant="body2">{currency?.currencyCode} <FormattedNumber value={itemAmount ?? 0} minimumFractionDigits={currency?.decimalDigits} maximumFractionDigits={currency?.decimalDigits} /></Typography>
        </Grid>
    </Grid> : <></>

}

const PackageDetailView = ({ editing, invoice, setInvoice }: {
    editing: boolean,
    invoice?: GiGrInvoice,
    setInvoice?: React.Dispatch<React.SetStateAction<GiGrInvoice | undefined>>,
}) => {
    const intl = useIntl()
    const packages = useMemo(() => {
        if (invoice?.invoices) {
            return invoice?.invoices.flatMap(inv => inv.containers).flatMap(container => container.packages)
        } else {
            return invoice?.containers.flatMap(container => container.packages) ?? []
        }
    }, [invoice?.containers, invoice?.invoices])

    const columns = useMemo(() => [
        { field: 'packageNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.packageNo' }), width: 200 },
        { field: 'packageType', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.packageType' }), width: 200 },
        { field: 'length', dataTypeName: 'volume', title: intl.formatMessage({ id: 'field.length' }), width: 200 },
        { field: 'width', dataTypeName: 'volume', title: intl.formatMessage({ id: 'field.width' }), width: 200 },
        { field: 'height', dataTypeName: 'volume', title: intl.formatMessage({ id: 'field.height' }), width: 200 },
        { field: 'm3', dataTypeName: 'volume', title: intl.formatMessage({ id: 'field.m3' }), width: 200 },
        { field: 'netWeight', dataTypeName: 'weight', title: intl.formatMessage({ id: 'field.netWeight' }), width: 200 },
        { field: 'grossWeight', dataTypeName: 'weight', title: intl.formatMessage({ id: 'field.grossWeight' }), width: 200 },
        // { field: 'unpackingFlag', dataTypeName: CodeCategory.UnpackingFlag, title: intl.formatMessage({ id: 'field.unpackingFlag' }), width: 200 },
    ], [intl])

    const defaultColumnsDisabled = Records.from(columns.filter(({ field }) => field === 'packageNo' || field === 'packageType' || field === 'unpackingFlag').map(({ field }) => [field, { editingDisabled: true }]))
    const onEditingRowCommit = useCallback((_column: Column, row: Row) => {
        setInvoice && setInvoice(invoice => {
            if (!invoice) return undefined
            // get packages
            if (invoice?.invoices) {
                const invoices = invoice.invoices.map(m => {
                    if (m.containers.flatMap(f => f.packages).some(s => s.invoicePackageId === row.invoicePackageId)) {
                        const containers = m.containers.map(m2 => ({ ...m2, packages: m2.packages.map(p => p.invoicePackageId === row.invoicePackageId ? row as any : p) }))
                        return ({ ...m, containers: containers })
                    } else {
                        return m
                    }
                })
                return ({ ...invoice, invoices: invoices })
            } else {
                const containers = invoice.containers.map(m => {
                    if (m.packages.some(s => s.invoicePackageId === row.invoicePackageId)) {
                        return { ...m, packages: m.packages.map(p => p.invoicePackageId === row.invoicePackageId ? row as any : p) }
                    } else {
                        return m
                    }
                })
                return ({ ...invoice, containers: containers })
            }
        })
        return true
    }, [setInvoice])

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <NumberTypeProvider name="weight" options={{ minimumFractionDigits: 3, maximumFractionDigits: 3 }} />
            <NumberTypeProvider name="volume" options={{ minimumFractionDigits: 3, maximumFractionDigits: 3 }} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.UnpackingFlag} />
            <Data rows={packages} columns={columns} />
            <ColumnFreeze />
            <ColumnVisibility columnSettings={{ packageNo: { disableUserControl: true } }} ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            {editing ? <Editing columnSettings={defaultColumnsDisabled} enableInlineEdit onEditingCellCommit={onEditingRowCommit} /> : <></>}
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
            <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        </DataGrid>
    </div >
}

const PartsDetailView = ({ editing, invoice, setInvoice }: {
    editing: boolean,
    invoice?: GiGrInvoice,
    setInvoice?: React.Dispatch<React.SetStateAction<GiGrInvoice | undefined>>,
}) => {
    const intl = useIntl()
    const details = useMemo(() => invoice?.details ?? [], [invoice?.details])
    const displayDetails = useMemo(() => Arrays.from(ArrayMultimaps.from(details.map(m => [m.soDetailOrigin + (m.price ?? 0), m])).collections()).map(m => ({
        ...m[0],
        qty: m.map(q => q.qty ?? 0).reduce((a, b) => a + b, 0)
    })), [details])
    const currencyDigits = invoice?.invoiceCurrency?.decimalDigits

    const columns = useMemo(() => [
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200, getCellValue: (row: Row) => row.part?.partsNo },
        { field: 'buyerPN', dataTypeName: 'string', title: intl.formatMessage({ id: 'buyerPartsNo' }), width: 200, getCellValue: (row: Row) => row.poPart?.unitPartsNo },
        { field: 'colorCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.colorCode' }), width: 150, getCellValue: (row: Row) => row.part?.colorCode },
        { field: 'buyerPNDesc', dataTypeName: 'string', title: intl.formatMessage({ id: 'buyerPartsName' }), width: 300, getCellValue: (row: Row) => row.poPart?.unitPartsName },
        { field: 'shipperPN', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sellerPartsNo' }), width: 200, getCellValue: (row: Row) => row.soPart?.unitPartsNo },
        { field: 'shipperPNDesc', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sellerPartsName' }), width: 300, getCellValue: (row: Row) => row.soPart?.unitPartsName },
        { field: 'poNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'purchaseOrderNo' }), width: 220, getCellValue: (row: Row) => row.poNo },
        { field: 'externalOrderNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'externalOrderNo' }), width: 300, getCellValue: (row: Row) => row.externalOrderNo },
        { field: 'buyerSpq', dataTypeName: 'quantity', title: intl.formatMessage({ id: 'field.spq' }), width: 120, getCellValue: (row: Row) => row.buyerSpq },
        { field: 'qty', dataTypeName: 'quantity', title: intl.formatMessage({ id: 'field.qty' }), width: 120, getCellValue: (row: Row) => row.qty },
        { field: 'unit', dataTypeName: 'string', title: intl.formatMessage({ id: 'unit' }), width: 120, getCellValue: (row: Row) => row.part?.uom?.uomCode },
        { field: 'currency', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.currency' }), width: 120 },
        // { field: 'invoiceNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.invoiceNo' }), width: 250 },
        { field: 'price', dataTypeName: 'amount', title: intl.formatMessage({ id: 'unitPrice' }), width: 150, getCellValue: (row: Row) => row.price },
        { field: 'amount', dataTypeName: 'amount', title: intl.formatMessage({ id: 'amount' }), width: 150, getCellValue: (row: Row) => (row.price ?? 0) * (row.qty ?? 0) },
    ], [intl])

    const defaultColumnsDisabled = Records.from(columns.filter(({ field }) => field !== 'price').map(({ field }) => [field, { editingDisabled: true }]))

    const onEditingRowCommit = useCallback((_column: Column, row: Row) => {
        setInvoice && setInvoice(invoice => invoice ? ({
            ...invoice, details: invoice.details.map(m => {
                // find current data
                if (m.soDetailOrigin === row.soDetailOrigin && m.orgPrice === row.orgPrice) {
                    return { ...m, price: row.price, orgPrice: row.price }
                } else {
                    return m
                }
            })
        }) : undefined)
        return true
    }, [setInvoice])

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <NumberTypeProvider name="quantity" />
            <NumberTypeProvider name="amount" options={{ minimumFractionDigits: currencyDigits, maximumFractionDigits: currencyDigits }}/>
            <Data rows={displayDetails} columns={columns} />
            <ColumnFreeze />
            <ColumnVisibility
                defaultHiddenFields={['shipperPN', 'shipperPNDesc', 'invoiceNo']}
                columnSettings={{
                    partsNo: { disableUserControl: true },
                    buyerPartsNo: { disableUserControl: true },
                }} ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            {editing ? <Editing columnSettings={defaultColumnsDisabled} enableInlineEdit onEditingCellCommit={onEditingRowCommit} /> : <></>}
            <Filtering />
            <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        </DataGrid>
    </div >
}

const DataBlock = ({ title, colSpan = 1, rowSpan = 1, editable, onClick, children, allwaysClick }: {
    title: ReactNode,
    colSpan?: number,
    rowSpan?: number,
    editable?: boolean,
    onClick?: () => void,
    children?: ReactNode | ReactNode[],
    allwaysClick?: boolean
}) => {
    const styles = useStyles()
    return <div className={clsx(styles.dataArea, editable && styles.editable)} style={{ gridColumn: `span ${colSpan}`, gridRow: `span ${rowSpan}` }}>
        <Typography variant="subtitle2">{title}</Typography>
        <div onClick={(editable || allwaysClick) ? onClick : undefined}>
            {children}
        </div>
    </div>
}

const DataBlockFlex = ({ title, colSpan = 1, rowSpan = 1, editable, onClick, children }: { title: ReactNode, colSpan?: number, rowSpan?: number, editable?: boolean, onClick?: () => void, children?: ReactNode | ReactNode[] }) => {
    const styles = useStyles()
    return <div className={clsx(styles.dataArea, editable && styles.editable)} style={{ gridColumn: `span ${colSpan}`, gridRow: `span ${rowSpan}` }}>
        <Typography variant="subtitle2">{title}</Typography>
        <div onClick={editable ? onClick : undefined} style={{ display: "flex", flexDirection: "row" }}>
            {children}
        </div>
    </div>
}

const prepareCompanyInfo = (compay: any, contact: any) => {
    return compay ? {
        cbdsUid: contact.cbdsUid,
        cbdsCode: contact.cbdsCode,
        cbdsName: contact.cbdsName,
        regionCode: contact.regionCode,
        regionName: contact.regionName,
        contact: {
            address1: contact.address1,
            address2: contact.address2,
            address3: contact.address3,
            address4: contact.address4,
            tel: contact.telephone1,
            fax: contact.fax1,
            postalCode: contact.postalCode,
        }
    } : undefined
}

const ConsigneeEditor = ({ title, invoice, onInvoiceChange, open, onClose, partys }: {
    title: ReactNode,
    invoice?: GiGrInvoice,
    onInvoiceChange?: React.Dispatch<React.SetStateAction<GiGrInvoice | undefined>>,
    open: boolean,
    onClose: () => void,
    partys: TnvCbds[],
}) => {
    const consignees = useMemo(() => invoice?.invoices ? invoice?.invoices.flatMap(m => m.consignee) : [invoice?.consignee], [invoice?.consignee, invoice?.invoices])
    const defaultContacts = consignees.map(m => m ? ({ ...m.contact, telephone1: m.contact?.tel, fax1: m.contact?.fax, cbdsUid: m.cbdsUid, cbdsCode: m.cbdsCode, cbdsName: m.cbdsName, regionCode: m.regionCode, regionName: m.regionName }) : undefined)
    const [contacts, setContacts] = useState<any[]>(defaultContacts)

    const invoiceNos = useMemo(() => invoice?.invoices ? invoice?.invoices.flatMap(m => m.invoiceNo) ?? [] : [invoice?.invoiceNo], [invoice?.invoiceNo, invoice?.invoices])
    const entires: [string, string][] = useMemo(() => partys.filter(f => CbdsType.CUST === f.cbdsType || CbdsType.BU === f.cbdsType).map(m => [m.cbdsUid, m.cbdsCode]), [partys])
    const onContactChange = useCallback((index) => {
        return (nextDraftDataFunc: React.Dispatch<React.SetStateAction<any>>) => {
            setContacts(contacts => {
                const editContact = contacts[index]
                const draftData: any = typeof nextDraftDataFunc === 'function' ? nextDraftDataFunc(editContact) : nextDraftDataFunc
                // do check if all data change
                if (draftData.cbdsUid !== editContact.cbdsUid) {
                    const filters = partys.filter(p => p.cbdsUid === draftData.cbdsUid)
                    return contacts.map((m, idx) => idx === index && filters.length > 0 ? filters[0] : m)
                } else {
                    return contacts.map((m, idx) => idx === index ? draftData : m)
                }
            })
        }
    }, [partys])

    const fetchInvoiceChange = useCallback((contacts) => {
        onInvoiceChange && onInvoiceChange(inv => {
            if (contacts.length === 1) {
                return inv ? { ...inv, consignee: prepareCompanyInfo(inv.consignee, contacts[0]) } : undefined
            } else {
                return inv ? { ...inv, invoices: inv?.invoices ? inv?.invoices?.map((m, index) => ({ ...m, consignee: prepareCompanyInfo(m.consignee, contacts[index]) })) : null } : undefined
            }
        })
    }, [onInvoiceChange])

    return <CardDialog open={open} onClose={onClose} fullWidth fullScreen={false} maxWidth='lg'>
        <DialogTitle style={{ cursor: 'move' }} ><Typography variant="h3">{title}</Typography></DialogTitle>
        <DialogContent>
            <Divider />
            {contacts.map((contact, index) => {
                return <>
                    {index > 0 && <Divider />}
                    {invoiceNos.length > 1 && <div style={{ paddingLeft: 16, paddingTop: 16 }}>
                        <Typography variant="subtitle2">{invoiceNos[index]}</Typography>
                    </div>}
                    <div style={{ padding: 16 }}>
                        <Form data={contact} setData={onContactChange(index)} labelDisplay="block" helperDisplay="tooltip" columnCount={2} labelWidth={150}>
                            <EntryItem field="cbdsUid" label="Code" entries={entires} />
                            <StringItem field="cbdsName" label="Name" />
                            <Break />
                            <StringItem field="address1" label="Address 1" />
                            <StringItem field="address2" label="Address 2" />
                            <StringItem field="address3" label="Address 3" />
                            <StringItem field="address4" label="Address 4" />
                            <StringItem field="telephone1" label="Telephone" />
                            <StringItem field="fax1" label="Fax" />
                        </Form>
                    </div>
                </>
            })}
            <Divider />
        </DialogContent>
        <DialogActions>
            <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={onClose} />
            <DialogAction title={<FormattedMessage id="ok" />} callback={() => {
                fetchInvoiceChange(contacts)
                onClose()
            }} />
        </DialogActions>
    </CardDialog >
}

const AccounteeEditor = ({ title, invoice, onInvoiceChange, open, onClose, partys }: {
    title: ReactNode,
    invoice?: GiGrInvoice,
    onInvoiceChange?: React.Dispatch<React.SetStateAction<GiGrInvoice | undefined>>,
    open: boolean,
    onClose: () => void,
    partys: TnvCbds[],
}) => {
    const accountees = useMemo(() => invoice?.invoices ? invoice?.invoices.flatMap(m => m.accountee) : [invoice?.accountee], [invoice?.accountee, invoice?.invoices])
    const defaultContacts = accountees.map(m => m ? ({ ...m.contact, telephone1: m.contact?.tel, fax1: m.contact?.fax, cbdsUid: m.cbdsUid, cbdsCode: m.cbdsCode, cbdsName: m.cbdsName, regionCode: m.regionCode, regionName: m.regionName }) : undefined)
    const [contacts, setContacts] = useState<any[]>(defaultContacts)

    const invoiceNos = useMemo(() => invoice?.invoices ? invoice?.invoices.flatMap(m => m.invoiceNo) ?? [] : [invoice?.invoiceNo], [invoice?.invoiceNo, invoice?.invoices])
    const entires: [string, string][] = useMemo(() => partys.filter(f => CbdsType.CUST === f.cbdsType || CbdsType.BU === f.cbdsType).map(m => [m.cbdsUid, m.cbdsCode]), [partys])
    const onContactChange = useCallback((index) => {
        return (nextDraftDataFunc: React.Dispatch<React.SetStateAction<any>>) => {
            setContacts(contacts => {
                const editContact = contacts[index]
                const draftData: any = typeof nextDraftDataFunc === 'function' ? nextDraftDataFunc(editContact) : nextDraftDataFunc
                if (draftData.cbdsUid !== editContact.cbdsUid) {
                    const filters = partys.filter(p => p.cbdsUid === draftData.cbdsUid)
                    return contacts.map((m, idx) => idx === index && filters.length > 0 ? filters[0] : m)
                } else {
                    return contacts.map((m, idx) => idx === index ? draftData : m)
                }
            })
        }
    }, [partys])

    const fetchInvoiceChange = useCallback((contacts) => {
        onInvoiceChange && onInvoiceChange(inv => {
            if (contacts.length === 1) {
                return inv ? { ...inv, accountee: prepareCompanyInfo(inv.accountee, contacts[0]) } : undefined
            } else {
                return inv ? { ...inv, invoices: inv?.invoices ? inv?.invoices?.map((m, index) => ({ ...m, accountee: prepareCompanyInfo(m.accountee, contacts[index]) })) : null } : undefined
            }
        })
    }, [onInvoiceChange])

    return <CardDialog open={open} onClose={onClose} fullWidth fullScreen={false} maxWidth='lg'>
        <DialogTitle style={{ cursor: 'move' }} ><Typography variant="h3">{title}</Typography></DialogTitle>
        <DialogContent>
            <Divider />
            {contacts.map((contact, index) => {
                return <>
                    {index > 0 && <Divider />}
                    {invoiceNos.length > 1 && <div style={{ paddingLeft: 16, paddingTop: 16 }}>
                        <Typography variant="subtitle2">{invoiceNos[index]}</Typography>
                    </div>}
                    <div style={{ padding: 16 }}>
                        <Form data={contact} setData={onContactChange(index)} labelDisplay="block" helperDisplay="tooltip" columnCount={2} labelWidth={150}>
                            <EntryItem field="cbdsUid" label="Code" entries={entires} />
                            <StringItem field="cbdsName" label="Name" />
                            <Break />
                            <StringItem field="address1" label="Address 1" />
                            <StringItem field="address2" label="Address 2" />
                            <StringItem field="address3" label="Address 3" />
                            <StringItem field="address4" label="Address 4" />
                            <StringItem field="telephone1" label="Telephone" />
                            <StringItem field="fax1" label="Fax" />
                        </Form>
                    </div>
                </>
            })}
            <Divider />
        </DialogContent>
        <DialogActions>
            <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={onClose} />
            <DialogAction title={<FormattedMessage id="ok" />} callback={() => {
                fetchInvoiceChange(contacts)
                onClose()
            }} />
        </DialogActions>
    </CardDialog >
}

const DeliveryToEditor = ({ title, invoice, onInvoiceChange, open, onClose, partys }: {
    title: ReactNode,
    invoice?: GiGrInvoice,
    onInvoiceChange?: React.Dispatch<React.SetStateAction<GiGrInvoice | undefined>>,
    open: boolean,
    onClose: () => void,
    partys: TnvCbds[],
}) => {
    const deliveryTos = useMemo(() => invoice?.invoices ? invoice?.invoices.flatMap(m => m.deliveryTo) : [invoice?.deliveryTo], [invoice?.deliveryTo, invoice?.invoices])
    const defaultContacts = deliveryTos.map(m => m ? ({ ...m.contact, telephone1: m.contact?.tel, fax1: m.contact?.fax, cbdsUid: m.cbdsUid, cbdsCode: m.cbdsCode, cbdsName: m.cbdsName, regionCode: m.regionCode, regionName: m.regionName }) : undefined)
    const [contacts, setContacts] = useState<any[]>(defaultContacts)

    const invoiceNos = useMemo(() => invoice?.invoices ? invoice?.invoices.flatMap(m => m.invoiceNo) ?? [] : [invoice?.invoiceNo], [invoice?.invoiceNo, invoice?.invoices])
    const entires: [string, string][] = useMemo(() => partys.filter(f => CbdsType.CUST === f.cbdsType || CbdsType.DC === f.cbdsType).map(m => [m.cbdsUid, m.cbdsCode]), [partys])
    const onContactChange = useCallback((index) => {
        return (nextDraftDataFunc: React.Dispatch<React.SetStateAction<any>>) => {
            setContacts(contacts => {
                const editContact = contacts[index]
                const draftData: any = typeof nextDraftDataFunc === 'function' ? nextDraftDataFunc(editContact) : nextDraftDataFunc
                if (draftData.cbdsUid !== editContact.cbdsUid) {
                    const filters = partys.filter(p => p.cbdsUid === draftData.cbdsUid)
                    return contacts.map((m, idx) => idx === index && filters.length > 0 ? filters[0] : m)
                } else {
                    return contacts.map((m, idx) => idx === index ? draftData : m)
                }
            })
        }
    }, [partys])

    const fetchInvoiceChange = useCallback((contacts) => {
        onInvoiceChange && onInvoiceChange(inv => {
            if (contacts.length === 1) {
                return inv ? { ...inv, deliveryTo: prepareCompanyInfo(inv.deliveryTo, contacts[0]) } : undefined
            } else {
                return inv ? { ...inv, invoices: inv?.invoices ? inv?.invoices?.map((m, index) => ({ ...m, deliveryTo: prepareCompanyInfo(m.deliveryTo, contacts[index]) })) : null } : undefined
            }
        })
    }, [onInvoiceChange])

    return <CardDialog open={open} onClose={onClose} fullWidth fullScreen={false} maxWidth='lg'>
        <DialogTitle style={{ cursor: 'move' }} ><Typography variant="h3">{title}</Typography></DialogTitle>
        <DialogContent>
            <Divider />
            {contacts.map((contact, index) => {
                return <>
                    {index > 0 && <Divider />}
                    {invoiceNos.length > 1 && <div style={{ paddingLeft: 16, paddingTop: 16 }}>
                        <Typography variant="subtitle2">{invoiceNos[index]}</Typography>
                    </div>}
                    <div style={{ padding: 16 }}>
                        <Form data={contact} setData={onContactChange(index)} labelDisplay="block" helperDisplay="tooltip" columnCount={2} labelWidth={150}>
                            <EntryItem field="cbdsUid" label="Code" entries={entires} />
                            <StringItem field="cbdsName" label="Name" />
                            <Break />
                            <StringItem field="address1" label="Address 1" />
                            <StringItem field="address2" label="Address 2" />
                            <StringItem field="address3" label="Address 3" />
                            <StringItem field="address4" label="Address 4" />
                            <StringItem field="telephone1" label="Telephone" />
                            <StringItem field="fax1" label="Fax" />
                        </Form>
                    </div>
                </>
            })}
            <Divider />
        </DialogContent>
        <DialogActions>
            <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={onClose} />
            <DialogAction title={<FormattedMessage id="ok" />} callback={() => {
                fetchInvoiceChange(contacts)
                onClose()
            }} />
        </DialogActions>
    </CardDialog >
}

const IssueDateEditor = ({ title, invoice, onInvoiceChange, open, onClose }: {
    title: ReactNode,
    invoice?: GiGrInvoice,
    onInvoiceChange?: React.Dispatch<React.SetStateAction<GiGrInvoice | undefined>>,
    open: boolean,
    onClose: () => void,
}) => {
    const [datas, setDatas] = useState<any[]>(invoice?.invoices ? invoice?.invoices.flatMap(m => ({ place: m.seller?.regionName, issueDate: m.invoiceDate })) ?? [] : [({ place: invoice?.seller?.regionName, issueDate: invoice?.invoiceDate })])
    const invoiceNos = useMemo(() => invoice?.invoices ? invoice?.invoices.flatMap(m => m.invoiceNo) ?? [] : [invoice?.invoiceNo], [invoice?.invoiceNo, invoice?.invoices])
    const onDataChange = useCallback((index) => {
        return (nextDraftDataFunc: React.Dispatch<React.SetStateAction<any>>) => {
            setDatas(editIssueDates => {
                const editIssueDate = editIssueDates[index]
                const draftData = typeof nextDraftDataFunc === 'function' ? nextDraftDataFunc(editIssueDate) : nextDraftDataFunc
                return editIssueDates.map((m, idx) => idx === index ? draftData : m)
            })
        }
    }, [])

    const fetchInvoiceChange = useCallback((datas) => {
        onInvoiceChange && onInvoiceChange(inv => {
            if (datas.length === 1) {
                return inv ? { ...inv, invoiceDate: datas[0].issueDate } : undefined
            } else {
                return inv ? { ...inv, invoices: inv?.invoices ? inv?.invoices?.map((m, index) => ({ ...m, invoiceDate: datas[index].issueDate })) : null } : undefined
            }
        })
    }, [onInvoiceChange])

    return <CardDialog open={open} onClose={onClose} fullWidth fullScreen={false}>
        <DialogTitle style={{ cursor: 'move' }} ><Typography variant="h3">{title}</Typography></DialogTitle>
        <DialogContent>
            <Divider />
            {datas.map((data, index) => {
                return <>
                    {index > 0 && <Divider />}
                    {datas.length > 1 && <div style={{ paddingLeft: 16, paddingTop: 16 }}>
                        <Typography variant="subtitle2">{invoiceNos[index]}</Typography>
                    </div>}
                    <div style={{ padding: 16 }}>
                        <Form data={data} setData={onDataChange(index)} columnCount={1} minWidth={500} maxWidth={500} labelDisplay="inline" labelAlign="end" labelWidth={150}>
                            <StringItem field="place" label="place" readonly />
                            <DateItem field="issueDate" label="issueDate" />
                        </Form>
                    </div>
                </>
            })}
            <Divider />
        </DialogContent>
        <DialogActions>
            <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={onClose} />
            <DialogAction title={<FormattedMessage id="ok" />} callback={() => {
                fetchInvoiceChange(datas)
                onClose()
            }} />
        </DialogActions>
    </CardDialog >
}

const VesselEditor = ({ title, invoice, onInvoiceChange, open, onClose }: {
    title: ReactNode,
    invoice?: GiGrInvoice,
    onInvoiceChange?: React.Dispatch<React.SetStateAction<GiGrInvoice | undefined>>,
    open: boolean,
    onClose: () => void
}) => {
    const [datas, setDatas] = useState<any[]>(invoice?.invoices ? invoice?.invoices ?? [] : (invoice ? [invoice] : []))
    const onDataChange = useCallback((index) => {
        return (nextDraftDataFunc: React.Dispatch<React.SetStateAction<any>>) => {
            setDatas(datas => {
                const draftData = typeof nextDraftDataFunc === 'function' ? nextDraftDataFunc(datas[index]) : nextDraftDataFunc
                return datas.map((m, idx) => idx === index ? draftData : m)
            })
        }
    }, [])

    const fetchInvoiceChange = useCallback((invoices) => {
        onInvoiceChange && onInvoiceChange(invoice => (invoices.length === 1) ? invoices[0] : invoice ? { ...invoice, invoices: invoices } : undefined)
    }, [onInvoiceChange])

    return <CardDialog open={open} onClose={onClose} fullWidth fullScreen={false}>
        <DialogTitle style={{ cursor: 'move' }} ><Typography variant="h3">{title}</Typography></DialogTitle>
        <DialogContent>
            <Divider />
            {datas.map((inv, index) => {
                return <>
                    {index > 0 && <Divider />}
                    {datas.length > 1 && <div style={{ paddingLeft: 16, paddingTop: 16 }}>
                        <Typography variant="subtitle2">{inv.invoiceNo}</Typography>
                    </div>}
                    <div style={{ padding: 16 }}>
                        <Form data={inv} setData={onDataChange(index)} columnCount={1} minWidth={500} maxWidth={500} labelDisplay="inline" labelAlign="end" labelWidth={150}>
                            <StringItem field="vesselName" label="Vessel Name" />
                            <StringItem field="voyageNo" label="Voyage No." />
                        </Form>
                    </div>
                </>
            })}
            <Divider />
        </DialogContent>
        <DialogActions>
            <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={onClose} />
            <DialogAction title={<FormattedMessage id="ok" />} callback={() => {
                fetchInvoiceChange(datas)
                onClose()
            }} />
        </DialogActions>
    </CardDialog >

}

const PaymentTermsEditor = ({ title, invoice, onInvoiceChange, open, onClose, paymentTerms }: {
    title: ReactNode,
    invoice?: GiGrInvoice,
    onInvoiceChange?: React.Dispatch<React.SetStateAction<GiGrInvoice | undefined>>,
    open: boolean,
    onClose: () => void,
    paymentTerms: TnmPaymentTerms[]
}) => {
    const [datas, setDatas] = useState<any[]>(invoice?.invoices ? invoice?.invoices.flatMap(m => m.paymentTerms) ?? [] : [invoice?.paymentTerms])
    const invoiceNos = useMemo(() => invoice?.invoices ? invoice?.invoices.flatMap(m => m.invoiceNo) ?? [] : [invoice?.invoiceNo], [invoice?.invoiceNo, invoice?.invoices])
    const onDataChange = useCallback((index) => {
        return (nextDraftDataFunc: React.Dispatch<React.SetStateAction<any>>) => {
            setDatas(editPaymentTerms => {
                const editPaymentTerm = editPaymentTerms[index]
                const draftData: any = typeof nextDraftDataFunc === 'function' ? nextDraftDataFunc(editPaymentTerm) : nextDraftDataFunc
                // add payment description
                const payment = paymentTerms.filter(f => f.paymentTermsCode === draftData.code)[0]
                const editData = payment ? {
                    paymentTermsId: payment.paymentTermsId,
                    code: payment.paymentTermsCode,
                    description: payment.description,
                    dueDate: null,
                } : {}
                return editPaymentTerms.map((m, idx) => idx === index ? payment ? editData : draftData : m)
            })
        }
    }, [paymentTerms])

    const fetchInvoiceChange = useCallback((datas) => {
        onInvoiceChange && onInvoiceChange(inv => {
            if (datas.length === 1) {
                return inv ? { ...inv, paymentTerms: datas[0] } : undefined
            } else {
                return inv ? { ...inv, invoices: inv?.invoices ? inv?.invoices?.map((m, index) => ({ ...m, paymentTerms: datas[index] })) : null } : undefined
            }
        })
    }, [onInvoiceChange])

    const suggustion = useMemo(() => paymentTerms.map(m => m.paymentTermsCode), [paymentTerms])

    return <CardDialog open={open} onClose={onClose} fullWidth fullScreen={false}>
        <DialogTitle style={{ cursor: 'move' }} ><Typography variant="h3">{title}</Typography></DialogTitle>
        <DialogContent>
            <Divider />
            {datas.map((data, index) => {
                return <>
                    {index > 0 && <Divider />}
                    {datas.length > 1 && <div style={{ paddingLeft: 16, paddingTop: 16 }}>
                        <Typography variant="subtitle2">{invoiceNos[index]}</Typography>
                    </div>}
                    <div style={{ padding: 16 }}>
                        <Form data={data} setData={onDataChange(index)} columnCount={1} minWidth={500} maxWidth={500} labelDisplay="inline" labelAlign="end" labelWidth={150}>
                            <AutoCompleteItem field="code" label="Code" suggestions={suggustion} />
                            <StringItem field="description" label="Description" readonly />
                        </Form>
                    </div>
                </>
            })}
            <Divider />
        </DialogContent>
        <DialogActions>
            <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={onClose} />
            <DialogAction title={<FormattedMessage id="ok" />} callback={() => {
                fetchInvoiceChange(datas)
                onClose()
            }} />
        </DialogActions>
    </CardDialog >
}

const EtdEtaEditor = ({ title, invoice, onInvoiceChange, open, onClose }: {
    title: ReactNode,
    invoice?: GiGrInvoice,
    onInvoiceChange?: React.Dispatch<React.SetStateAction<GiGrInvoice | undefined>>,
    open: boolean,
    onClose: () => void
}) => {
    const [datas, setDatas] = useState<any[]>(invoice?.invoices ? invoice?.invoices ?? [] : (invoice ? [invoice] : []))
    const onDataChange = useCallback((index) => {
        return (nextDraftDataFunc: React.Dispatch<React.SetStateAction<any>>) => {
            setDatas(datas => {
                const draftData = typeof nextDraftDataFunc === 'function' ? nextDraftDataFunc(datas[index]) : nextDraftDataFunc
                return datas.map((m, idx) => idx === index ? draftData : m)
            })
        }
    }, [])

    const fetchInvoiceChange = useCallback((invoices) => {
        onInvoiceChange && onInvoiceChange(invoice => (invoices.length === 1) ? invoices[0] : invoice ? { ...invoice, invoices: invoices } : undefined)
    }, [onInvoiceChange])

    return <CardDialog open={open} onClose={onClose} fullWidth fullScreen={false}>
        <DialogTitle style={{ cursor: 'move' }} ><Typography variant="h3">{title}</Typography></DialogTitle>
        <DialogContent>
            <Divider />
            {datas.map((inv, index) => {
                return <>
                    {index > 0 && <Divider />}
                    {datas.length > 1 && <div style={{ paddingLeft: 16, paddingTop: 16 }}>
                        <Typography variant="subtitle2">{inv.invoiceNo}</Typography>
                    </div>}
                    <div style={{ padding: 16 }}>
                        <Form data={inv} setData={onDataChange(index)} columnCount={1} minWidth={500} maxWidth={500} labelDisplay="inline" labelAlign="end" labelWidth={150}>
                            <DateItem field="etd" label="ETD" />
                            <DateItem field="eta" label="ETA" />
                        </Form>
                    </div>
                </>
            })}
            <Divider />
        </DialogContent>
        <DialogActions>
            <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={onClose} />
            <DialogAction title={<FormattedMessage id="ok" />} callback={() => {
                fetchInvoiceChange(datas)
                onClose()
            }} />
        </DialogActions>
    </CardDialog >
}

const RemarkEditor = ({ title, invoice, onInvoiceChange, open, onClose }: {
    title: ReactNode,
    invoice?: GiGrInvoice,
    onInvoiceChange?: React.Dispatch<React.SetStateAction<GiGrInvoice | undefined>>,
    open: boolean,
    onClose: () => void
}) => {
    const [datas, setDatas] = useState<any[]>(invoice?.invoices ? invoice?.invoices ?? [] : (invoice ? [invoice] : []))
    const onDataChange = useCallback((index) => {
        return (nextDraftDataFunc: React.Dispatch<React.SetStateAction<any>>) => {
            setDatas(datas => {
                const draftData = typeof nextDraftDataFunc === 'function' ? nextDraftDataFunc(datas[index]) : nextDraftDataFunc
                return datas.map((m, idx) => idx === index ? draftData : m)
            })
        }
    }, [])

    const fetchInvoiceChange = useCallback((invoices) => {
        onInvoiceChange && onInvoiceChange(invoice => (invoices.length === 1) ? invoices[0] : invoice ? { ...invoice, invoices: invoices } : undefined)
    }, [onInvoiceChange])

    return <CardDialog open={open} onClose={onClose} fullWidth fullScreen={false}>
        <DialogTitle style={{ cursor: 'move' }} ><Typography variant="h3">{title}</Typography></DialogTitle>
        <DialogContent>
            <Divider />
            {datas.map((inv, index) => {
                return <>
                    {index > 0 && <Divider />}
                    {datas.length > 1 && <div style={{ paddingLeft: 16, paddingTop: 16 }}>
                        <Typography variant="subtitle2">{inv.invoiceNo}</Typography>
                    </div>}
                    <div style={{ padding: 16 }}>
                        <Form data={inv} setData={onDataChange(index)} columnCount={1} minWidth={500} maxWidth={500} labelDisplay="inline" labelAlign="end" labelWidth={150}>
                            <StringItem field="remark" label="Remark" multiline />
                        </Form>
                    </div>
                </>
            })}
            <Divider />
        </DialogContent>
        <DialogActions>
            <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={onClose} />
            <DialogAction title={<FormattedMessage id="ok" />} callback={() => {
                fetchInvoiceChange(datas)
                onClose()
            }} />
        </DialogActions>
    </CardDialog >
}

interface ShippingMark {
    invoiceNo: string,
    countryOfOrigins: string[],
    packages: string[]
}

const ShippingMarkEditor = ({ title, invoice, onInvoiceChange, open, onClose, regions }: {
    title: ReactNode,
    invoice?: GiGrInvoice,
    onInvoiceChange?: React.Dispatch<React.SetStateAction<GiGrInvoice | undefined>>,
    open: boolean,
    onClose: () => void,
    regions: TnmRegion[],
}) => {

    // prepare 
    const invoices = useMemo(() => invoice?.invoices ? invoice?.invoices ?? [] : [invoice], [invoice])
    const defaultShippingMarks = useMemo(() => invoices.map(inv => {
        return {
            invoiceNo: inv?.invoiceNo ?? '',
            countryOfOrigins: inv?.countryOfOrigin?.split(",") ?? [],
            packages: inv?.containers.flatMap(f => f.packages).map(m => m.packageNo) ?? []
        } as ShippingMark
    }), [invoices])
    const [shippingMarks, setShippingMarks] = useState<ShippingMark[]>(defaultShippingMarks)
    const entires: [string, string][] = useMemo(() => regions.map(m => [m.regionName, m.regionName]), [regions])

    const onShippingMarkChange = useCallback((index) => {
        return (nextDraftDataFunc: React.Dispatch<React.SetStateAction<any>>) => {
            setShippingMarks(shippingMarks => {
                const editShippingMark = shippingMarks[index]
                const draftData: any = typeof nextDraftDataFunc === 'function' ? nextDraftDataFunc(editShippingMark) : nextDraftDataFunc
                return shippingMarks.map((m, idx) => idx === index ? draftData : m)
            })
        }
    }, [])

    const fetchInvoiceChange = useCallback((shippingMarks) => {
        onInvoiceChange && onInvoiceChange(inv => {
            if (shippingMarks.length === 1) {
                return inv ? { ...inv, countryOfOrigin: shippingMarks[0].countryOfOrigins.join(",") } : undefined
            } else {
                return inv ? { ...inv, invoices: inv?.invoices ? inv?.invoices?.map((m, index) => ({ ...m, countryOfOrigin: shippingMarks[index].countryOfOrigins.join(",") })) : null } : undefined
            }
        })
    }, [onInvoiceChange])

    return <CardDialog open={open} onClose={onClose} fullWidth fullScreen={false} maxWidth='md'>
        <DialogTitle style={{ cursor: 'move' }} ><Typography variant="h3">{title}</Typography></DialogTitle>
        <DialogContent>
            <Divider />
            {shippingMarks.map((shippingMark, index) => {
                return <>
                    {index > 0 && <Divider />}
                    {invoices.length > 1 && <div style={{ paddingLeft: 16, paddingTop: 16 }}>
                        <Typography variant="subtitle2">{shippingMark.invoiceNo}</Typography>
                    </div>}
                    <div style={{ padding: 16 }}>
                        <Form data={shippingMark} setData={onShippingMarkChange(index)} labelDisplay="inline" labelAlign="start" helperDisplay="tooltip" columnCount={3} labelWidth={120}>
                            <EntriesItem field="countryOfOrigins" label="Country Of Origin" entries={entires} colSpan={2} />
                            <Break />
                            <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="cNo" /></SectionTitle></GridItem>
                            {Arrays.range(0, 20).map(indx => {
                                return <>
                                    {indx < shippingMark.packages.length && <Typography key={shippingMark.packages[indx]} variant="body2">{shippingMark.packages[indx]}</Typography>}
                                    {indx + 20 < shippingMark.packages.length ?
                                        <Typography key={shippingMark.packages[indx + 20]} variant="body2">{shippingMark.packages[indx + 20]}</Typography>
                                        : (indx < shippingMark.packages.length ? <Placeholder /> : <></>)}
                                    {indx + 40 < shippingMark.packages.length ?
                                        <Typography key={shippingMark.packages[indx + 40]} variant="body2">{shippingMark.packages[indx + 40]}</Typography>
                                        : (indx < shippingMark.packages.length ? <Placeholder /> : <></>)}
                                </>
                            })}
                        </Form>
                    </div>
                </>
            })}
            <Divider />
        </DialogContent>
        <DialogActions>
            <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={onClose} />
            <DialogAction title={<FormattedMessage id="ok" />} callback={() => {
                fetchInvoiceChange(shippingMarks)
                onClose()
            }} />
        </DialogActions>
    </CardDialog >
}

const checkIfHasValue = (name: string | undefined, amount: number | null | undefined) => (name || (amount !== null && amount !== undefined))

const useStyles = makeStyles(theme => ({
    coverPageContainer: {
        display: 'grid',
        gridTemplateColumns: `repeat(3, minmax(0, 1fr))`,
        gap: `${theme.spacing(2)}px`,
    },
    coverPageUp: {
        gridColumn: 'span 3',
        display: 'grid',
        gridTemplateColumns: `repeat(3, minmax(0, 1fr))`,
        gap: `${theme.spacing(2)}px`,
    },
    coverPageLeft: {
        gridColumn: 'span 2',
        display: 'grid',
        gridTemplateColumns: `repeat(2, minmax(0, 1fr))`,
        gap: `${theme.spacing(2)}px`,
    },
    coverPageRight: {
        '&>div': {
            display: 'grid',
            gridTemplateColumns: `repeat(1, minmax(0, 1fr))`,
            gap: `${theme.spacing(2)}px`,
        }
    },
    dataArea: {
        border: `1px dashed ${theme.palette.divider}`,
        borderRadius: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: theme.spacing(2),
        '&>*:nth-child(1)': {
            textTransform: 'uppercase',
            marginBottom: theme.spacing(2),
        },
        '&>*:nth-child(2)': {
            padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
            borderRadius: theme.spacing(1),
            backgroundColor: theme.palette.grey[200],
            minHeight: 37,
            boxSizing: 'border-box',
        },
    },
    editable: {
        '&>*:nth-child(2)': {
            border: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.common.white,
            cursor: 'pointer',
        },
    },
    text: {
        whiteSpace: 'break-spaces',
        overflowWrap: 'break-word',
    },
    divider: {
        width: '100%',
        alignItems: 'center',
        padding: theme.spacing(1, 0),
    },
    subDivider: {
        width: '100%',
        alignItems: 'center',
        padding: 5,
    }
}))