import moment from "moment";

function _dateToJson(v: Date): string
// eslint-disable-next-line no-redeclare
function _dateToJson(v: Date | undefined): string | undefined
// eslint-disable-next-line no-redeclare
function _dateToJson(v: Date | null): string | null
// eslint-disable-next-line no-redeclare
function _dateToJson(v: any): any {
    return v ? moment(v).format('YYYY-MM-DD') : v
}
export const dateToJson = _dateToJson

function _enumToJson<E>(e: E): any
// eslint-disable-next-line no-redeclare
function _enumToJson<E>(e: E | undefined): E | undefined
// eslint-disable-next-line no-redeclare
function _enumToJson(e: any): any {
    return e
}
export const enumToJson = _enumToJson

function _datetimeToJson(v: Date): number
// eslint-disable-next-line no-redeclare
function _datetimeToJson(v: Date | undefined): number | undefined
// eslint-disable-next-line no-redeclare
function _datetimeToJson(v: any): any {
    return v ? v.getTime() : v
}
export const datetimeToJson = _datetimeToJson