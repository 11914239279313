import moment from 'moment'
import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"

export type CustomerUsageView = Required & Partial<Optional>
type CustomerUsageViewJson = JsonRequired & Partial<JsonOptional>

export const customerUsageViewToJson: Project<CustomerUsageView, CustomerUsageViewJson> = obj => ({
    ...obj,
    updatedDateStart: obj.updatedDateStart == null ? undefined : moment(obj.updatedDateStart).format('YYYY-MM-DD'),
    updatedDateEnd: obj.updatedDateEnd == null ? undefined : moment(obj.updatedDateEnd).format('YYYY-MM-DD'),
    planStartDate: obj.planStartDate == null ? undefined : moment(obj.planStartDate).format('YYYY-MM-DD'),
    planEndDate: obj.planEndDate == null ? undefined : moment(obj.planEndDate).format('YYYY-MM-DD'),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
})

export const customerUsageViewFromJson: Project<CustomerUsageViewJson, CustomerUsageView> = json => ({
    ...json,
    updatedDateStart: json.updatedDateStart ? undefined : moment(json.updatedDateStart).toDate(),
    updatedDateEnd: json.updatedDateEnd == null ? undefined : moment(json.updatedDateEnd).toDate(),
    planStartDate: json.planStartDate == null ? undefined : moment(json.planStartDate).toDate(),
    planEndDate: json.planEndDate == null ? undefined : moment(json.planEndDate).toDate(),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page),
})

interface Required extends JsonRequired {

}

interface Optional extends Omit<JsonOptional, 'updatedDateStart' | 'updatedDateEnd' | 'planStartDate' | 'planEndDate'> {
    updatedDateStart: Date,
    updatedDateEnd: Date,
    planStartDate: Date,
    planEndDate: Date,
    page: PageViewEntity,

}

interface JsonRequired {

}

interface JsonOptional {
    customerCode: string,
    grade: string,
    katashiki: string,
    level1PartsNo: string,
    modelNo: string,
    planStartDate: string,
    planEndDate: string,
    updatedDateStart: string,
    updatedDateEnd: string,
    updatedDateTime: string,
    page: PageViewEntityJson,
}