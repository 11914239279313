import { AxiosResponse } from 'axios'
import moment from "moment"

export default function downloadResponse(response: AxiosResponse, options?: { defaultFilename?: string, timestamp?: boolean, mimeType?: string }) {
    // get content type from header
    const contentType = options?.mimeType ?? response.headers['content-type'] ?? response.headers['Content-Type']
    // get file name from header
    const contentDisposition = response.headers['content-disposition'] || response.headers['Content-Disposition'] || response.headers['Content-disposition']
    const filename = filenameFromContentDisposition(contentDisposition)
    // get file name
    let downloadFilename = 'download'
    if (filename) {
        downloadFilename = options?.timestamp ? appendTimestamp(filename) : filename
    } else if (options?.defaultFilename) {
        downloadFilename = options?.timestamp ? appendTimestamp(options?.defaultFilename) : options?.defaultFilename
    }
    // construct blob
    const blob = new Blob([response.data], { type: contentType })
    // create url
    const url = window.URL.createObjectURL(blob)
    // create link
    const link = document.createElement('a')
    link.href = url
    link.download = downloadFilename
    // mount link
    document.body.appendChild(link)
    // click link
    link.click()
    // unmount link
    document.body.removeChild(link)
    // revoke url
    window.URL.revokeObjectURL(url)
}

function filenameFromContentDisposition(contentDisposition: string) {
    if (contentDisposition !== null) {
        const matchResult = contentDisposition.match(/filename="(.+)"/)
        return matchResult === null ? null : matchResult[1]
    }
    return null
}

function appendTimestamp(fileName: string) {
    const timestamp = moment().format('YYYYMMDDHHmmss')
    const matchResult = fileName.match(/(.*)\.(.*)/)
    return matchResult === null ? fileName + timestamp : `${matchResult[1]}.${timestamp}.${matchResult[2]}`

}