import React, { useCallback, useEffect, useState } from "react"
import { useGetIncomingOrProcessInbound } from "../../../services/delivery/apis/deliveryInboundApi"
import { InboundInfo } from "../../../services/delivery/models/InboundInfo"
import { LIS013PcUi } from "./LIS013PcUi"

export const LIS013 = () => {
    const [data, setData] = useState<InboundInfo[]>([])

    const getData = useGetIncomingOrProcessInbound()
    const search = useCallback(() => {
        getData(undefined, { serialized: true }).then(result => {
            setData(result || [])
        })
    }, [getData])
    useEffect(() => {
        search()
    }, [getData, search])

    return <LIS013PcUi inboundList={data} search={search} />
}
