import { Arrays, Comparators } from "@rithe/utils";
import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from "react";

interface StepDescriptor {
    serialNumber?: number,
    variant?: 'normal' | 'success' | 'warning' | 'error' | 'info' | 'disabled',
    icon?: ReactNode,
    title: string,
}

interface Context {
    targetAnchorEl: HTMLElement | null,
    setTargetAnchorEl: (anchorEl: HTMLElement | null) => void,
    activeAnchorEl: HTMLElement | null,
    setActiveAnchorEl: (anchorEl: HTMLElement | null) => void,
    stepDescriptors: ({ anchorEl: HTMLElement } & StepDescriptor)[]
    registerAnchorEl: (anchorEl: HTMLElement, stepDescriptor: StepDescriptor) => void,
    unregisterAnchorEl: (anchorEl: HTMLElement) => void,
}

const StepContext = createContext<Context>(undefined as any)

export const StepContextProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
    const [targetAnchorEl, setTargetAnchorEl] = useState<HTMLElement | null>(null)
    const [activeAnchorEl, setActiveAnchorEl] = useState<HTMLElement | null>(null)
    const [stepDescriptors, setStepDescriptors] = useState<({ anchorEl: HTMLElement } & StepDescriptor)[]>([])
    const registerAnchorEl = useCallback((anchorEl: HTMLElement, stepDescriptor: StepDescriptor) => {
        setStepDescriptors(
            descriptors => Arrays.sort([...descriptors, { anchorEl, ...stepDescriptor }],
                Comparators.compare(descriptor => descriptor.anchorEl.getBoundingClientRect().top, Comparators.natualOrder())
            ))
    }, [])
    const unregisterAnchorEl = useCallback((anchorEl: HTMLElement) => {
        setStepDescriptors(descriptors => descriptors.filter(descriptor => descriptor.anchorEl !== anchorEl))
    }, [])

    const value = useMemo(() => ({
        targetAnchorEl,
        setTargetAnchorEl,
        activeAnchorEl,
        setActiveAnchorEl,
        stepDescriptors,
        registerAnchorEl,
        unregisterAnchorEl,
    }), [activeAnchorEl, registerAnchorEl, stepDescriptors, targetAnchorEl, unregisterAnchorEl])

    return <StepContext.Provider value={value}>
        {children}
    </StepContext.Provider>
}

export const useStepContext = () => {
    return useContext(StepContext)
}