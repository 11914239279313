import { Project } from "../../../utils/Project"
import { ShippingMode } from "../../master/enums/ShippingMode"
import { YESNOFlag } from "../../privilege/enums/YESNOFlag"
import { dateFromJson } from "../../utils/deserializer"


export type ShippingDetailView = Required & Partial<Optional>

type ShippingDetailViewJson = JsonRequired & Partial<JsonOptional>

export const shippingDetailViewFromJson: Project<ShippingDetailViewJson, ShippingDetailView> = json => ({
    ...json,
    atd: dateFromJson(json.atd),
    eta: dateFromJson(json.eta),
})


interface Required extends JsonRequired { }

interface Optional extends Omit<JsonOptional, 'shippingMode' | 'atd' | 'eta' | 'viaPortcastFlag'> {
    shippingMode: ShippingMode,
    atd: Date,
    eta: Date,
    viaPortcastFlag: YESNOFlag
}

interface JsonRequired {
    shippingDetailId: number,
}

interface JsonOptional {
    bookingNo: string,
    containerNo: string,
    shippingMode: number,
    sellerCode: string,
    loadingPortCode: string,
    dischargePortCode: string,
    atd: string,
    eta: string,
    role: number,
    cargoStatus: string,
    viaPortcastFlag: number,
}