import React, { useCallback, useMemo, useState } from 'react'
import { useGetSoMonitoringListByPage } from '../../../services/order/apis/SalesOrderApi'
import { SoListResult } from '../../../services/order/models/SoListResult'
import { SoMonitoringListFactor, soMonitoringListFactorFromJson, soMonitoringListFactorToJson } from '../../../services/order/models/SoMonitoringListFactor'
import sessionKeys from '../../../utils/sessionKeys'
import { useSearchOnLoad } from '../../../utils/useSearchOnLoad'
import { OSS020PcUi } from './OSS020PcUi'


const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }
const searchCachekey = sessionKeys.Filters_OSS020

export const OSS020 = () => {

    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? soMonitoringListFactorFromJson(JSON.parse(filters)) : null
    }, [])

    const [totalCount, setTotalCount] = useState<number>(0)
    const [filters, setFilters] = useState<SoMonitoringListFactor>(cacheFilters ?? defaultFilters)
    const [soList, setSoList] = useState<SoListResult[]>([])


    const getSoData = useGetSoMonitoringListByPage()

    const search = useCallback(() => {
        getSoData(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setSoList(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(soMonitoringListFactorToJson(filters)))
        })
    }, [filters, getSoData])

    useSearchOnLoad(search, filters)

    return <OSS020PcUi
        filters={filters}
        soList={soList}
        setFilters={setFilters}
        search={search}
        totalCount={totalCount}
    />
}