import { DateRangeItem, EntriesItem, StringItem, YearMonthItem } from "@rithe/form"
import { Arrays, Comparators } from "@rithe/utils"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodesItem } from "../../../components/Form/CodesItem"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { OrderCalculationListViewCondition, orderCalculationListViewConditionToJson } from "../../../services/smt/models/OrderCalculationListViewCondition"
import { useGetCompanyType } from "../../../utils/ApplicationUtils"
import sessionKeys from "../../../utils/sessionKeys"
import { useApplicationSelector } from "../../Application/applicationSlice"
import { occls010Actions, useOCCLS010Selector } from "./OCCLS010Slice"

export function Filters() {
  const intl = useIntl()
  const type = useOCCLS010Selector(state => state.type)
  const companyType = useGetCompanyType()

  const createdDateGetValue = useCallback((filters: OrderCalculationListViewCondition) => {
    return [filters.createDateStart ?? null, filters.createDateEnd ?? null]
  }, [])

  const createdDateMapValue = useCallback((filters: OrderCalculationListViewCondition, value: any) => {
    return { ...filters ?? {}, createDateStart: value[0], createDateEnd: value[1] }
  }, [])

  const filterCounter = useCallback((filters: OrderCalculationListViewCondition) => {
    return [
      filters.orderGroupNo,
      filters.orderCalcNo,
      filters.orderMonthStart,
      filters.orderMonthEnd,
      filters.createDateStart || filters.createDateEnd,
      filters.statusList,
      filters.customerIdList,
      filters.buyerUids,
      filters.sellerUids,
      filters.orderFrequencyList,
      filters.createdBy,
    ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
  }, [])

  const codeCategories = useApplicationSelector(state => state.cache.codeCategories ?? [])
  const calcEntries = useMemo(() => {
    return Arrays.distinct(codeCategories.filter(cc => cc.codeCategory === CodeCategory.OrderCalcStatus).map(cc => cc.codeValue))
        .sort(Comparators.natualOrder())
        .filter(f => f)
        .map(option => [option, intl.formatMessage({ id: `${CodeCategory.OrderCalcStatus}_${option}` })] as [number, string])
  }, [codeCategories, intl])

  const stateFilters = useOCCLS010Selector(state => state.filters)
  const [filters, setFilters] = useState(stateFilters)
  const buyerOptions = useOCCLS010Selector(state => Object.keys(state.buyerOptions).sort().map(key => [key, state.buyerOptions[key as any]]))
  const sellerOptions = useOCCLS010Selector(state => Object.keys(state.sellerOptions).sort().map(key => [key, state.sellerOptions[key as any]]))
  const dispatch = useDispatch()
  useEffect(() => { dispatch(occls010Actions.setFilters(filters)) }, [filters, dispatch])
  return <FilterToolbarItem
    filters={filters}
    onFiltersChange={setFilters}
    onSubmit={() => {
        dispatch(type === 'Ehm' ? occls010Actions.listViewForEhm() : occls010Actions.listView())
        sessionStorage.setItem(sessionKeys.Data_OCCLS010, JSON.stringify(orderCalculationListViewConditionToJson(filters)))
    }}
    clear={() => ({})}
    filterCounter={filterCounter}>
    <StringItem field='orderGroupNo' label={intl.formatMessage({ id: 'field.orderGroupNo' })} />
    <YearMonthItem field="orderMonthStart" label={intl.formatMessage({ id: 'orderMonthFrom' })} />
    <YearMonthItem field="orderMonthEnd" label={intl.formatMessage({ id: 'orderMonthEnd' })} />
    <EntriesItem field="statusList" label={intl.formatMessage({ id: 'field.status' })} entries={calcEntries} />
    <StringItem field='orderCalcNo' label={intl.formatMessage({ id: 'field.orderCalcNo' })} />
    <DateRangeItem field="createDate" label={intl.formatMessage({ id: 'field.createDate' })} getValue={createdDateGetValue} mapValue={createdDateMapValue} />
    {type === 'default' && companyType === CbdsType.BU && <EntriesItem field='customerIdList' label={intl.formatMessage({ id: 'field.customerCode' })} entries={buyerOptions as any} />}
    {type === 'default' && companyType === CbdsType.CUST && <EntriesItem field='sellerUids' label={intl.formatMessage({ id: 'field.sellerCode' })} entries={sellerOptions as any} />}
    {type === 'PNA' && <EntriesItem field='customerIdList' label={intl.formatMessage({ id: 'field.customerCode' })} entries={buyerOptions as any} />}
    {type === 'Ehm' && <EntriesItem field='buyerUids' label={intl.formatMessage({ id: 'field.buyerCode' })} entries={buyerOptions as any} />}
    <CodesItem field='orderFrequencyList' label={intl.formatMessage({ id: 'field.orderFrequency' })} code={CodeCategory.OrderFrequency} />
    <StringItem field='createdBy' label={intl.formatMessage({ id: 'field.createBy' })} />
  </FilterToolbarItem>
}