import { Popover, PopoverProps, withStyles } from "@material-ui/core";
import React from "react";

export const PopoverMenu = withStyles(theme => ({
    root: {
    },
    paper: {
        minWidth: 200,
        background: theme.palette.background.popover.main,
        color: theme.palette.getContrastText(theme.palette.background.popover.main),
        borderRadius: theme.spacing(1.5),
    },
}))((props: PopoverProps) => <Popover
    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    {...props}
/>)