import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"

export type StockManagementMasterView = Required & Partial<Optional>

type StockManagementMasterViewJson = JsonRequired & Partial<JsonOptional>

export const stockManagementMasterViewToJson: Project<StockManagementMasterView, StockManagementMasterViewJson> = obj => ({
    ...obj,
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page)
})

export const stockManagementMasterViewFromJson: Project<StockManagementMasterViewJson, StockManagementMasterView> = json => ({
    ...json,
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page)
})


interface Required extends JsonRequired {

}

interface Optional extends JsonOptional {
    page: PageViewEntity,
}

interface JsonRequired {

}

interface JsonOptional {
    calculationPatterns: string[],
    
    sellerList: string[],
    buyerList: string[],
    inventoryBoxFlags: number[],
    onshippingDelayPats: number[],
    custDelayPats: number[],
    custAdvancePats: number[],

    page: PageViewEntityJson,
}
