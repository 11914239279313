import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { useGetCustomerByLoginUser, useGetSellerByLoginUser } from "../../../services/smt/api/ComboboxApi"
import { useGetAllProject, useGetFieldListById, useGetFieldName, useGetMaterialName, useGetMeterialListById, useGetPlanHistoryPage, useGetProjectById, useGetUsageHistoryPage } from "../../../services/smt/api/ProjectApi"
import { ProjectFieldsResult } from "../../../services/smt/models/ProjectFieldsResult"
import { ProjectPlanHistoryResult } from "../../../services/smt/models/ProjectPlanHistoryResult"
import { ProjectResult } from "../../../services/smt/models/ProjectResult"
import { UsageHistoryResult } from "../../../services/smt/models/UsageHistoryResult"
import { Pair } from "../../../services/utils/Pair"
import { useGetCompanyType } from "../../../utils/ApplicationUtils"
import { useMatch } from "../../../utils/useMatch"
import { CSUGS131PcUi } from "./CSUGS131PcUi"

export const CSUGS131 = () => {

    const { projectId } = useParams() as any

    const { path } = useMatch()
    const mode = path === '/project/modify-:projectId' ? ScreenMode.EDIT : path === '/project/view-:projectId' ? ScreenMode.VIEW : ScreenMode.CREATE

    const [fieldNameList, setFieldNameList] = useState<string[]>([])
    const [materialNameList, setMaterialNameList] = useState<string[]>([])
    const [fieldList, setFieldList] = useState<ProjectFieldsResult[]>([])
    const [meterialList, setMeterialList] = useState<ProjectFieldsResult[]>([])
    const [usageHistory, setUsageHistory] = useState<UsageHistoryResult[]>([])
    const [planHistory, setPlanHistory] = useState<ProjectPlanHistoryResult[]>([])
    const [projectInfo, setProjectInfo] = useState<ProjectResult>({})
    const [participantList, setParticipantList] = useState<Pair[]>([])

    const getFieldName = useGetFieldName()
    const getMaterialName = useGetMaterialName()
    const getMeterialList = useGetMeterialListById()
    const getFieldList = useGetFieldListById()
    const usageHistoryPage = useGetUsageHistoryPage()
    const planHistoryPage = useGetPlanHistoryPage()
    const getProjectInfo = useGetProjectById()
    const getCustomerList = useGetCustomerByLoginUser()
    const getSellerList = useGetSellerByLoginUser()
    const getProjectCode = useGetAllProject()

    const searchProjectInfo = useCallback((id: number) => {
        getProjectInfo({ projectId: id }, { silent: true, serialized: true }).then(result => setProjectInfo(result))
    }, [getProjectInfo])

    const searchFieldNames = useCallback(() => {
        getFieldName(undefined, { silent: true, serialized: true }).then(result => setFieldNameList(result || []))
    }, [getFieldName])

    const searchMaterialNames = useCallback(() => {
        getMaterialName(undefined, { silent: true, serialized: true }).then(result => setMaterialNameList(result || []))
    }, [getMaterialName])

    const searchField = useCallback((id: number) => {
        getFieldList({ projectId: id }, { silent: true, serialized: true }).then(result => setFieldList(result || []))
    }, [getFieldList])

    const searchMeterial = useCallback((id: number) => {
        getMeterialList({ projectId: id }, { silent: true, serialized: true }).then(result => {
            setMeterialList(draft => result || [])
        })
    }, [getMeterialList])

    const searchUsageHistory = useCallback((id: number) => {
        usageHistoryPage({ projectId: id }, { silent: true, serialized: true }).then(result => {
            setUsageHistory(result || [])
        })
    }, [usageHistoryPage])

    const searchPlanHistory = useCallback((id: number) => {
        planHistoryPage({ projectId: id }, { silent: true, serialized: true }).then(result => {
            setPlanHistory(result || [])
        })
    }, [planHistoryPage])


    const cbdsType = useGetCompanyType()
    useEffect(() => {
        searchFieldNames()
        searchMaterialNames()
        if (cbdsType === CbdsType.BU) {
            getCustomerList(undefined, { silent: true }).then(result => setParticipantList(result || []))
        } else {
            getSellerList(undefined, { silent: true }).then(result => setParticipantList(result || []))
        }

    }, [cbdsType, getCustomerList, getProjectCode, getSellerList, searchFieldNames, searchMaterialNames])


    useEffect(() => {
        if (projectId !== null && projectId !== undefined) {
            searchUsageHistory(projectId)
            searchPlanHistory(projectId)
            searchField(projectId)
            searchMeterial(projectId)
            searchProjectInfo(projectId)
        }
    }, [projectId, searchField, searchMeterial, searchPlanHistory, searchProjectInfo, searchUsageHistory])

    return <CSUGS131PcUi
        fieldNameList={fieldNameList}
        materialNameList={materialNameList}
        searchFieldNames={searchFieldNames}
        searchMaterialNames={searchMaterialNames}
        searchField={searchField}
        searchMeterial={searchMeterial}
        fieldList={fieldList}
        meterialList={meterialList}
        usageHistory={usageHistory}
        planHistory={planHistory}
        searchUsageHistory={searchUsageHistory}
        searchPlanHistory={searchPlanHistory}
        projectInfo={projectInfo}
        setProjectInfo={setProjectInfo}
        searchProjectInfo={searchProjectInfo}
        participantList={participantList}
        mode={mode}
    />
}
