import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, RowActionProvider, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { DateRangeItem, EntriesItem, StringItem } from "@rithe/form"
import { Records } from "@rithe/utils"
import React, { useCallback, useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { EditRedirectRowAction } from "../../../components/DataGrid/rowActions/EditRedirectRowAction"
import { ViewRedirectRowAction } from "../../../components/DataGrid/rowActions/ViewRedirectRowAction"
import { CreateRedirectToolbarAction } from "../../../components/DataGrid/toolbarActions/CreateRedirectToolbarAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { ActiveFlagTypeProvider } from "../../../components/DataGrid/typeProviders/ActiveFlagTypeProvider"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { CalendarFactor } from "../../../services/master/models/CalendarFactor"
import { CalendarResult } from "../../../services/master/models/CalendarResult"
import { TnmCompany } from "../../../services/master/models/TnmCompany"
import { TnmRegion } from "../../../services/master/models/TnmRegion"

interface MLS130PcUiProps {
    path: string,
    filters: CalendarFactor,
    search: (filters: CalendarFactor) => void,
    data: CalendarResult[],
    totalCount: number,
    regionList: TnmRegion[]
    companyList: TnmCompany[],
    setFilters: React.Dispatch<React.SetStateAction<Partial<CalendarFactor>>>
}

export const MLS130PcUi = (props: MLS130PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({ filters, data, regionList, companyList, setFilters, search, path }: MLS130PcUiProps) => {
    const intl = useIntl()

    const columns = useMemo(() => [
        { field: 'calendarCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.calendarCode' }), width: 200 },
        { field: 'companyCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.companyCode' }), width: 200 },
        { field: 'regionCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.regionCode' }), width: 180 },
        { field: 'description', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.description' }), width: 300 },
        { field: 'activeFlag', dataTypeName: CodeCategory.ActiveFlag, title: intl.formatMessage({ id: 'field.activeFlag' }), width: 150 },
        { field: 'createdBy', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.createdBy' }), width: 180 },
        { field: 'createdDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.createdDate' }), width: 180 },
        { field: 'updatedBy', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.updatedBy' }), width: 180 },
        { field: 'updatedDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.updatedDate' }), width: 180 },
    ], [intl])
    const getRowId = useCallback((row: any) => row.calendarId, [])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search, regionList, companyList }), [filters, setFilters, search, regionList, companyList])
    const ViewOrEditAction = useMemo(() => ({ path }), [path])
    const display = useCallback((tableRow: TableRow) => tableRow.row?.companyId !== null && tableRow.row?.companyId !== undefined, [])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <ActiveFlagTypeProvider />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={CreateAction} actionProps={ViewOrEditAction} />
        <RowActionProvider name="view" Action={ViewRowAction} display={display} actionProps={ViewOrEditAction} />
        <RowActionProvider name="edit" Action={EditRowAction} actionProps={ViewOrEditAction} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['updatedDate']}
            columnSettings={{
                calendarCode: { disableUserControl: true },
                companyCode: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Action width={88} />
    </DataGrid>
}

const FilterItem = (props: {
    filters: CalendarFactor,
    search: (filters: CalendarFactor) => void,
    setFilters: React.Dispatch<React.SetStateAction<Partial<CalendarFactor>>>
    regionList: TnmRegion[]
    companyList: TnmCompany[],
}) => {
    const { filters, regionList, companyList, setFilters, search } = props
    const regionListEntries: [string, string][] = useMemo(() => regionList.map(m => [m.regionCode, m.regionCode]), [regionList])
    const companyListEntries: [number, string][] = useMemo(() => companyList.map(m => [m.companyId, m.companyCode]), [companyList])

    const createdDateGetValue = useCallback((filters: CalendarFactor) => {
        return [filters.createdDateStart ?? null, filters.createdDateEnd ?? null]
    }, [])

    const createdDateMapValue = useCallback((filters: CalendarFactor, value: any) => {
        return { ...filters ?? {}, createdDateStart: value[0], createdDateEnd: value[1] }
    }, [])

    const updatedDateGetValue = useCallback((filters: CalendarFactor) => {
        return [filters.updatedDateStart ?? null, filters.updatedDateEnd ?? null]
    }, [])

    const updatedDateMapValue = useCallback((filters: CalendarFactor, value: any) => {
        return { ...filters ?? {}, updatedDateStart: value[0], updatedDateEnd: value[1] }
    }, [])

    const clear = useCallback((filters: CalendarFactor) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: CalendarFactor) => {
        return [
            filters.calendarCode,
            filters.regionList,
            filters.companyList,
            filters.description,
            filters.discontinueIndicatorList,
            filters.createdBy,
            filters.updatedBy,
            filters.createdDateStart || filters.createdDateEnd,
            filters.updatedDateStart || filters.updatedDateEnd,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="calendarCode" label={intl.formatMessage({ id: 'field.calendarCode' })} />
        <EntriesItem field="regionList" label={intl.formatMessage({ id: 'field.regionCode' })} entries={regionListEntries} />
        <EntriesItem field="companyList" label={intl.formatMessage({ id: 'field.companyCode' })} entries={companyListEntries} />
        <StringItem field="description" label={intl.formatMessage({ id: 'field.description' })} />
        <CodesItem field="discontinueIndicatorList" label={intl.formatMessage({ id: 'field.activeFlag' })} code={CodeCategory.ActiveFlag} />
        <StringItem field="createdBy" label={intl.formatMessage({ id: 'field.createdBy' })} />
        <StringItem field="updatedBy" label={intl.formatMessage({ id: 'field.updatedBy' })} />
        <DateRangeItem field="createdDate" label={intl.formatMessage({ id: 'field.createdDate' })} getValue={createdDateGetValue} mapValue={createdDateMapValue} />
        <DateRangeItem field="updatedDate" label={intl.formatMessage({ id: 'field.updatedDate' })} getValue={updatedDateGetValue} mapValue={updatedDateMapValue} />
    </FilterToolbarItem>
}



const CreateAction = ({ path }: { path: string }) => {
    const toPath = `${path}/create`
    return <CreateRedirectToolbarAction access="MARS.MLS130.CREATE" title={<FormattedMessage id="create" />} path={toPath} />
}

const ViewRowAction = ({ tableRow, path }: DataGridRowActionProps & { path: string }) => {
    const toPath = useCallback((tableRow: TableRow) => `${path}/view-${tableRow.rowId}`, [path])
    return <ViewRedirectRowAction tableRow={tableRow} access="MARS.MLS130.VIEWDETAIL" path={toPath} />
}

const EditRowAction = ({ tableRow, path }: DataGridRowActionProps & { path: string }) => {
    const toPath = useCallback((tableRow: TableRow) => `${path}/edit-${tableRow.rowId}`, [path])
    return <EditRedirectRowAction tableRow={tableRow} access="MARS.MLS130.EDIT" path={toPath} />
}