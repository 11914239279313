import { Button, DialogActions, DialogContent, DialogContentText, IconButton, Tooltip } from "@material-ui/core"
import { AssignmentTurnedInOutlined, Clear, SaveOutlined } from "@material-ui/icons"
import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { AutoCompleteItem, EntryItem, Form, Message, Placeholder, StringItem, YearMonthItem } from "@rithe/form"
import { GridItem } from "@rithe/ui"
import { Arrays, Records } from "@rithe/utils"
import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useFunctionStore } from "../../../Root"
import { CallbackCardAction } from "../../../components/Action/CallbackCardAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { DeleteCallbackRowAction } from "../../../components/DataGrid/rowActions/DeleteCallbackRowAction"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { UploadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/UploadGroupedToolbarAction"
import { ActiveFlagTypeProvider } from "../../../components/DataGrid/typeProviders/ActiveFlagTypeProvider"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { DarkDialog } from "../../../components/Dialog/DarkDialog"
import { SectionTitle } from "../../../components/SectionTitle/SectionTitle"
import { StepperAction } from "../../../components/View/Step/StepperAction"
import { View } from "../../../components/View/View"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { SmtActiveFlag } from "../../../services/master/enums/SmtActiveFlag"
import { useDeleteUasgeHistory, useSaveField, useSaveMeterial, useSaveProject } from "../../../services/smt/api/ProjectApi"
import { useDownloadField, useDownloadMaterials, useDownloadPlan, useDownloadUsage } from "../../../services/smt/api/smtDownloadApi"
import { useUploadBillOfMaterial, useUploadBom, useUploadBomMaterial, useUploadProduction } from "../../../services/smt/api/smtUploadApi"
import { ProductionPlanType } from "../../../services/smt/enums/ProductionPlanType"
import { ProjectFieldsFactor } from "../../../services/smt/models/ProjectFieldsFactor"
import { ProjectFieldsResult } from "../../../services/smt/models/ProjectFieldsResult"
import { ProjectMaterialsFactor } from "../../../services/smt/models/ProjectMaterialsFactor"
import { ProjectPlanFactor } from "../../../services/smt/models/ProjectPlanFactor"
import { ProjectPlanHistoryResult } from "../../../services/smt/models/ProjectPlanHistoryResult"
import { ProjectResult } from "../../../services/smt/models/ProjectResult"
import { UsageHistoryResult } from "../../../services/smt/models/UsageHistoryResult"
import { Pair } from "../../../services/utils/Pair"
import { useGetCompanyType } from "../../../utils/ApplicationUtils"
import { useFieldChecker, useFormValidater } from "../../../utils/ValidatorUtils"
import { applicationActions } from "../../Application/applicationSlice"

interface CSUGS131PcUiProps {
    fieldNameList: string[],
    materialNameList: string[],
    searchFieldNames: () => void,
    searchMaterialNames: () => void,
    searchField: (id: number) => void,
    searchMeterial: (id: number) => void,
    fieldList: ProjectFieldsResult[],
    meterialList: ProjectFieldsResult[],
    usageHistory: UsageHistoryResult[],
    planHistory: ProjectPlanHistoryResult[],
    searchUsageHistory: (id: number) => void,
    searchPlanHistory: (id: number) => void,
    projectInfo: ProjectResult,
    setProjectInfo: React.Dispatch<React.SetStateAction<ProjectResult>>,
    searchProjectInfo: (id: number) => void,
    participantList: Pair[],
    mode: ScreenMode
}

export const CSUGS131PcUi = (props: CSUGS131PcUiProps) => {

    const { projectInfo, setProjectInfo, searchProjectInfo,
        participantList, fieldNameList,
        materialNameList, searchFieldNames, searchMaterialNames,
        fieldList, searchField, meterialList, searchMeterial,
        usageHistory, planHistory, searchUsageHistory, searchPlanHistory, mode } = props

    const { noOfLevel, projectId, step } = projectInfo

    return (
        <View actions={<StepperAction />}>
            <CreateProjectView mode={mode} participantList={participantList} projectInfo={projectInfo} searchProjectInfo={searchProjectInfo} setProjectInfo={setProjectInfo}></CreateProjectView>
            <CreateFieldView mode={mode} projectInfo={projectInfo} fieldNameList={fieldNameList} searchFieldNames={searchFieldNames} step={step} hasUsageHistory={usageHistory.length > 0} projectId={projectId} fieldList={fieldList} searchField={searchField} searchProjectInfo={searchProjectInfo} ></CreateFieldView>
            <CreateMaterialsView mode={mode} projectInfo={projectInfo} materialNameList={materialNameList} step={step} hasUsageHistory={usageHistory.length > 0} projectId={projectId} searchMaterialNames={searchMaterialNames} meterialList={meterialList} searchMeterial={searchMeterial} searchProjectInfo={searchProjectInfo}></CreateMaterialsView>
            <CreateBillMaterialsView mode={mode} usageHistory={usageHistory} step={step} projectId={projectId} searchUsageHistory={searchUsageHistory} searchProjectInfo={searchProjectInfo}></CreateBillMaterialsView>
            <CreateProductionView mode={mode} planHistory={planHistory} usageHistory={usageHistory} step={step} projectId={projectId} noOfLevel={noOfLevel} searchPlanHistory={searchPlanHistory} searchProjectInfo={searchProjectInfo}></CreateProductionView>
        </View>
    )
}

// 1-0:create project
const CreateProjectView = memo(({ projectInfo, setProjectInfo, searchProjectInfo, participantList, mode }: {
    projectInfo: ProjectResult,
    setProjectInfo: React.Dispatch<React.SetStateAction<ProjectResult>>,
    searchProjectInfo: (id: number) => void,
    participantList: Pair[],
    mode: ScreenMode
}) => {
    const cbdsType = useGetCompanyType()
    const intl = useIntl()
    const fields = useMemo(() => cbdsType === CbdsType.BU ? getFormCheckFields() : getFormCheckFieldsForCust(), [cbdsType])
    const [messages, setMessages] = useState<Message[]>([])
    const fieldCheck = useFieldChecker(fields, setMessages)
    const participantMap = useMemo(() => cbdsType === CbdsType.BU ?
        participantList.map(m => [m.first, m.second] as [number, string])
        : participantList.map(m => [m.first, m.second] as [string, string]
        ), [cbdsType, participantList])
    const readonly = useMemo(() => (mode === ScreenMode.VIEW) || (projectInfo.projectId !== null && projectInfo.projectId !== undefined), [mode, projectInfo.projectId])

    const { step } = projectInfo

    return <SectionCard allowCollapse>
        <SectionCardHeader
            title={intl.formatMessage({ id: 'project' })}
            subtitle={intl.formatMessage({ id: 'projectSub' })}
            serialNumber={1} step
            variant={step === 0 || step === undefined ? 'info' : step > 0 ? 'success' : 'disabled'}
            actions={mode === ScreenMode.VIEW ? [] : <ProjectAction projectInfo={projectInfo} participantMap={participantMap} setMessages={setMessages} searchProjectInfo={searchProjectInfo}></ProjectAction>}
        />
        <SectionCardContent>
            <Form data={projectInfo} setData={setProjectInfo} labelDisplay="block" helperDisplay="tooltip" messages={messages} ensure={fieldCheck}>
                {cbdsType === CbdsType.BU ?
                    <EntryItem field="customerId" required readonly={readonly} label={intl.formatMessage({ id: "buyer" })} entries={participantMap} />
                    : <EntryItem field="sellerUid" required readonly={readonly} label={intl.formatMessage({ id: "seller" })} entries={participantMap} />}
                <StringItem field="projectCode" required readonly={readonly} label={intl.formatMessage({ id: "projectCode" })} />
                <StringItem field="projectName" required readonly={mode === ScreenMode.VIEW} label={intl.formatMessage({ id: "projectName" })} />
                <StringItem field="remarks" colSpan={2} readonly={mode === ScreenMode.VIEW} label={intl.formatMessage({ id: "remarks" })} />
            </Form>
        </SectionCardContent>
    </SectionCard>
})

const ProjectAction = ({ projectInfo, participantMap, searchProjectInfo, setMessages }: {
    projectInfo: ProjectResult,
    participantMap: [number | string, string][],
    // setStep: React.Dispatch<React.SetStateAction<number>>,
    searchProjectInfo: (id: number) => void,
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>
}) => {
    const saveMethod = useSaveProject()
    const cbdsType = useGetCompanyType()
    const fields = useMemo(() => cbdsType === CbdsType.BU ? getFormCheckFields() : getFormCheckFieldsForCust(), [cbdsType])
    const formValidate = useFormValidater(setMessages, fields)
    const [disabled, setDisabled] = useState<boolean>(false)
    const saveProject = useCallback(() => {
        if (formValidate(projectInfo)) {
            setDisabled(true)
            const param = { ...projectInfo }
            if (cbdsType === CbdsType.BU) {
                param.customerCode = participantMap.find(m => m[0] === projectInfo.customerId)?.[1]
            } else {
                param.sellerCode = participantMap.find(m => m[0] === projectInfo.sellerUid)?.[1]
            }

            saveMethod(param, { serialized: true }).then(result => {
                result && searchProjectInfo(result)
                // setStep(step => step > 0 ? step : 1)
            }).finally(() => {
                setDisabled(false)
            })
        }
    }, [formValidate, projectInfo, cbdsType, saveMethod, participantMap, searchProjectInfo])

    return <Tooltip title={<FormattedMessage id="save" />}>
        <IconButton onClick={saveProject} disabled={disabled}><SaveOutlined></SaveOutlined></IconButton>
    </Tooltip>
}

//2-1-Create Fields
const CreateFieldView = ({ projectInfo, fieldList, searchField, fieldNameList, searchFieldNames, hasUsageHistory, step, projectId, mode, searchProjectInfo }: {
    projectInfo: ProjectResult,
    fieldNameList: string[],
    searchFieldNames: () => void,
    projectId?: number,
    hasUsageHistory: boolean,
    step: number | undefined,
    // setStep: React.Dispatch<React.SetStateAction<number>>,
    fieldList: ProjectFieldsResult[],
    searchField: (id: number) => void,
    mode: ScreenMode,
    searchProjectInfo: (id: number) => void,
}) => {
    const intl = useIntl()
    const [messages, setMessages] = useState<Message[]>([])

    const fields = getFormCheckFields2(true)
    const fieldCheck = useFieldChecker(fields)
    const [formData, setFormData] = useState<ProjectFieldsFactor>({})

    const readonly = useMemo(() => mode === ScreenMode.VIEW || step !== 1, [mode, step])

    useEffect(() => {
        if (fieldList.length > 0) {
            setFormData({
                projectId: projectId,
                mainFieldId: fieldList.find(m => m.mainFlag === 1)?.fieldId,
                mainFieldName: fieldList.find(m => m.mainFlag === 1)?.fieldName?.trim(),
                otherFieldNames: fieldList.filter(m => m.mainFlag === 0).map((m, index) => ({ mainFlag: m.mainFlag, fieldName: m.fieldName?.trim() ?? "", orderNo: m.orderNo ?? index, fieldId: m.fieldId }))
            })
        }
    }, [fieldList, projectId])

    const getFieldValue = useCallback((index: number) => {
        return (data: any) => (data.otherFieldNames && data.otherFieldNames.length > index) ? data.otherFieldNames[index].fieldName?.trim() : ''
    }, [])

    const mapFieldValue = useCallback((index: number) => {
        return (data: any, value: any) => ({ ...data, otherFieldNames: data.otherFieldNames.map((field: any, _index: number) => ({ ...field, fieldName: _index === index ? value?.trim() : field.fieldName?.trim() })) })
    }, [])

    return <SectionCard allowCollapse>
        <SectionCardHeader
            title={intl.formatMessage({ id: "createFields" })}
            subtitle=""
            serialNumber={2}
            step
            variant={step === 1 ? 'info' : (step !== undefined && step > 1) ? 'success' : 'disabled'}
            actions={mode === ScreenMode.VIEW || step === undefined || step < 1 ? [] : <FieldAction hasUsageHistory={hasUsageHistory} formData={formData} setFormData={setFormData} projectInfo={projectInfo} setMessages={setMessages} searchField={searchField} searchFieldNames={searchFieldNames} searchProjectInfo={searchProjectInfo} />}
        />
        <SectionCardContent>
            <Form data={formData} setData={setFormData} labelDisplay="block" helperDisplay="tooltip" messages={messages} ensure={fieldCheck}>
                <AutoCompleteItem field="mainFieldName" required readonly={readonly} label={intl.formatMessage({ id: "mainDescription" })} suggestions={fieldNameList} />
                <Placeholder />
                <Placeholder />

                <GridItem columnSpan={2}>
                    <SectionTitle size="small">
                        <FormattedMessage id="otherDescription" />
                    </SectionTitle>
                </GridItem>
                <Placeholder />

                {formData.otherFieldNames
                    && formData.otherFieldNames.map((_, index) => {
                        return <>
                            <AutoCompleteItem readonly={readonly} field={'otherFieldName' + index} label={intl.formatMessage({ id: "otherDesc" }) + ' ' + (index + 1)} suggestions={fieldNameList} getValue={getFieldValue(index)} mapValue={mapFieldValue(index)} />
                            <GridItem style={{ display: 'flex', alignItems: 'flex-end' }}>{mode !== ScreenMode.VIEW && step === 1 && <RemoveFieldAction setFormData={setFormData} index={index} />}</GridItem>
                            <Placeholder />
                        </>
                    })}

                {mode !== ScreenMode.VIEW && step === 1 && <GridItem columnSpan={3}><AddFieldAction setFormData={setFormData} /></GridItem>}
            </Form>
        </SectionCardContent>
    </SectionCard >
}

//add description
const AddFieldAction = ({ setFormData }: {
    setFormData: React.Dispatch<React.SetStateAction<ProjectFieldsFactor>>
}) => {
    const addAction = useCallback(() => {
        setFormData((formData) => {
            const oldOtherFieldNames = formData.otherFieldNames ?? []
            const otherFieldNames = [...oldOtherFieldNames, { fieldName: '', orderNo: oldOtherFieldNames.length }]
            return { ...formData, otherFieldNames: otherFieldNames }
        })
    }, [setFormData])
    return <CallbackCardAction outlined title={<FormattedMessage id="addDesc" />} callback={addAction} />
}

//delete description
const RemoveFieldAction = ({ setFormData, index }: {
    setFormData: React.Dispatch<React.SetStateAction<ProjectFieldsFactor>>,
    index: number
}) => {
    const removeAction = useCallback(() => {
        setFormData((formData) => ({ ...formData, otherFieldNames: formData.otherFieldNames?.filter(f => f.orderNo !== index).map((data, idx) => ({ ...data, orderNo: idx })) }))
    }, [index, setFormData])
    return <Tooltip title={<FormattedMessage id="delDesc" />}>
        <IconButton onClick={removeAction}><Clear fontSize={'small'} /></IconButton>
    </Tooltip>
}

const FieldAction = ({ hasUsageHistory, projectInfo, formData, setFormData, setMessages, searchField, searchFieldNames, searchProjectInfo }: {
    hasUsageHistory: boolean,
    projectInfo: ProjectResult,
    formData: ProjectFieldsFactor,
    setFormData: React.Dispatch<React.SetStateAction<ProjectFieldsFactor>>,
    // step: number
    // setStep: React.Dispatch<React.SetStateAction<number>>,
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
    searchField: (id: number) => void,
    searchFieldNames: () => void,
    searchProjectInfo: (id: number) => void,
}) => {
    const { projectId, step } = projectInfo
    const saveMethod = useSaveField()
    const fields = useMemo(() => getFormCheckFields2(true), [])
    const formValidate = useFormValidater(setMessages, fields)
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'confirm' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const doCheck = useCallback((formData: ProjectFieldsFactor): boolean => {
        if (formData.mainFieldName === null || formData.mainFieldName === undefined || formData.mainFieldName.trim() === '') {
            dispatch(applicationActions.pushError({ title: { code: 'MainFieldName' }, messages: { code: 'w0387' } }))
            return false
        }

        if (formData.otherFieldNames && formData.otherFieldNames.length > 0) {
            const arr = Arrays.distinct(formData.otherFieldNames.map(m => m.fieldName?.trim()))
            if (arr.length < formData.otherFieldNames.length) {
                dispatch(applicationActions.pushError({ title: { code: 'OtherRepeated' }, messages: { code: 'w0388' } }))
                return false
            }
            if (arr.includes(formData.mainFieldName?.trim())) {
                dispatch(applicationActions.pushError({ title: { code: 'OtherRepeated' }, messages: { code: 'w0388' } }))
                return false
            }
        }
        return true
    }, [dispatch])

    const [warnOpen, setWarnOpen] = useState<boolean>(false)

    const saveField = useCallback((isConfirm: number) => {

        const saveData = {
            ...formData,
            //otherFieldNamesArr: formData?.otherFieldNames?.filter(f => f.fieldName !== undefined && f.fieldName !== null && f.fieldName?.trim() !== '')
        }
        setFormData(saveData)

        if (formValidate(saveData)) {
            setMessages([])
            if (doCheck(saveData)) {
                // const otherFieldNamesArr = formData?.otherFieldNames?.filter(f => f.fieldName !== undefined && f.fieldName !== null && f.fieldName?.trim() !== '')
                // saveMethod({ ...formData, otherFieldNames: otherFieldNamesArr, projectId: projectInfo.projectId, isConfirm: isConfirm }, { serialized: true }).then(result => {

                if (isConfirm === 1) {
                    const functionId = functionStore.register(() => {
                        setDisabled(true)
                        saveMethod({ ...saveData, projectId: projectInfo.projectId, isConfirm: isConfirm }, { serialized: true }).then(result => {
                            // setStep(step => step > 1 ? step : step + 1)
                            projectId && searchProjectInfo(projectId)
                            projectId && searchField(projectId)
                            searchFieldNames()
                        }).finally(() => {
                            setDisabled(false)
                        })
                    })
                    dispatch(applicationActions.pushWarning({
                        title: title,
                        messages: { code: 'c0001', args: [title] },
                        actions: [{
                            label: 'CANCEL'
                        }, {
                            functionId,
                            label: 'CONFIRM',
                        }]
                    }))
                } else {
                    setDisabled(true)
                    saveMethod({ ...saveData, projectId: projectInfo.projectId, isConfirm: isConfirm }, { serialized: true }).then(result => {
                        // setStep(step => step > 1 ? step : step + 1)
                        projectId && searchProjectInfo(projectId)
                        projectId && searchField(projectId)
                        searchFieldNames()
                    }).finally(() => {
                        setDisabled(false)
                    })
                }
            }
        }
    }, [dispatch, doCheck, formData, formValidate, functionStore, projectId, projectInfo.projectId, saveMethod, searchField, searchFieldNames, searchProjectInfo, setFormData, setMessages, title])

    const downloadField = useDownloadField()
    const downLoadMethod = useCallback(() => {
        setWarnOpen(false)
        downloadField({ projectId: projectId, noOfLevel: 0 })
    }, [downloadField, projectId])

    const saveAndDownload = useCallback(() => {
        const saveData = {
            ...formData,
            //otherFieldNamesArr: formData?.otherFieldNames?.filter(f => f.fieldName !== undefined && f.fieldName !== null && f.fieldName?.trim() !== '')
        }
        setFormData(saveData)
        if (formValidate(saveData)) {
            setMessages([])
            if (doCheck(saveData)) {
                setWarnOpen(false)
                // const otherFieldNamesArr = formData?.otherFieldNames?.filter(f => f.fieldName !== undefined && f.fieldName !== null && f.fieldName?.trim() !== '')
                // saveMethod({ ...formData, otherFieldNames: otherFieldNamesArr, projectId: projectInfo.projectId, isConfirm: 0 }, { serialized: true }).then(result => {
                const functionId = functionStore.register(() => {
                    setDisabled(true)
                    saveMethod({ ...saveData, projectId: projectInfo.projectId, isConfirm: 0 }, { serialized: true }).then(result => {
                        // setStep(step => step > 1 ? step : step + 1)
                        projectId && searchProjectInfo(projectId)
                        projectId && searchField(projectId)
                        searchFieldNames()
                        downloadField({ projectId: result, noOfLevel: 0 })
                    }).finally(() => {
                        setDisabled(false)
                    })
                })
                dispatch(applicationActions.pushWarning({
                    title: title,
                    messages: { code: 'c0001', args: [title] },
                    actions: [{
                        label: 'CANCEL'
                    }, {
                        functionId,
                        label: 'CONFIRM',
                    }]
                }))
            }
        }
    }, [dispatch, doCheck, downloadField, formData, formValidate, functionStore, projectId, projectInfo.projectId, saveMethod, searchField, searchFieldNames, searchProjectInfo, setFormData, setMessages, title])

    const uploadBom = useUploadBom()
    const uploadMethod = useCallback((files: FileList | null) => {
        if (files === null) return
        uploadBom({ file: files[0], projectId: projectId, noOfLevel: 0 }, { serialized: true }).then(() => {
            projectId && searchProjectInfo(projectId)
            projectId && searchField(projectId)
            searchFieldNames()

        })
    }, [projectId, searchField, searchFieldNames, uploadBom, searchProjectInfo])

    return projectId === null || projectId === undefined ?
        <></> : <>
            {step === 1 && <Tooltip title={<FormattedMessage id="save" />}><IconButton onClick={() => { saveField(0) }} disabled={disabled}
            ><SaveOutlined></SaveOutlined></IconButton></Tooltip>}
            {step === 1 && <Tooltip title={<FormattedMessage id="confirm" />}><IconButton onClick={() => { saveField(1) }} disabled={disabled}
            ><AssignmentTurnedInOutlined></AssignmentTurnedInOutlined></IconButton></Tooltip>}
            {(step !== undefined && step > 1) && <>
                <DownloadGroupedToolbarAction>
                    {onClose => <GroupedCallbackItem label={<FormattedMessage id="downloadField" />} callback={downLoadMethod} onClose={onClose} />}
                </DownloadGroupedToolbarAction>
                <DarkDialog onClose={() => { setWarnOpen(() => !warnOpen) }} aria-labelledby="simple-dialog-title" open={warnOpen}>
                    <DialogContent>
                        <DialogContentText>
                            <FormattedMessage id="saveAndDownload" />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={downLoadMethod} variant="contained"><FormattedMessage id="alert.cancel" /></Button>
                        <Button onClick={saveAndDownload} variant="contained" disabled={disabled}><FormattedMessage id="alert.ok" /></Button>
                    </DialogActions>
                </DarkDialog>
            </>}
            {(step !== undefined && step > 1 && !hasUsageHistory) && (
                <UploadGroupedToolbarAction>
                    {(upload, onClose) => <GroupedCallbackItem label={<FormattedMessage id="uploadField" />} onClose={onClose} callback={() => upload(uploadMethod)} />}
                </UploadGroupedToolbarAction>
            )}
        </>
}

//3-2:Materials
const CreateMaterialsView = memo(({ projectInfo, meterialList, searchMeterial, materialNameList, searchMaterialNames, hasUsageHistory, step, mode, searchProjectInfo }: {
    projectInfo: ProjectResult,
    projectId?: number,
    materialNameList: string[],
    searchMaterialNames: () => void,
    step: number | undefined,
    hasUsageHistory: boolean,
    // setStep: React.Dispatch<React.SetStateAction<number>>,
    meterialList: ProjectFieldsResult[],
    searchMeterial: (id: number) => void,
    mode: ScreenMode,
    searchProjectInfo: (id: number) => void,
}) => {
    const intl = useIntl()
    const [formData, setFormData] = useState<ProjectMaterialsFactor>({} as ProjectMaterialsFactor)
    const [messages, setMessages] = useState<Message[]>([])
    const levelEntries = useMemo(() => [1, 2, 3].map(m => [m, 'Level ' + m] as [number, string]), [])
    const readonly = useMemo(() => mode === ScreenMode.VIEW || step !== 2, [mode, step])
    useEffect(() => {
        if (meterialList.length > 0) {
            setFormData(f => {
                return {
                    noOfLevel: Math.max(...meterialList.map(m => m.levelType ?? 0)),
                    levelOneMainMaterialId: meterialList.find(m => m.levelType === 1 && m.mainFlag === 1)?.fieldId,
                    levelOneMainMaterial: meterialList.find(m => m.levelType === 1 && m.mainFlag === 1)?.fieldName?.trim(),
                    oneOtherMaterials: meterialList.filter(m => m.levelType === 1 && m.mainFlag !== 1),
                    levelTwoMainMaterialId: meterialList.find(m => m.levelType === 2 && m.mainFlag === 1)?.fieldId,
                    levelTwoMainMaterial: meterialList.find(m => m.levelType === 2 && m.mainFlag === 1)?.fieldName?.trim(),
                    twoOtherMaterials: meterialList.filter(m => m.levelType === 2 && m.mainFlag !== 1),
                    levelThreeMainMaterialId: meterialList.find(m => m.levelType === 3 && m.mainFlag === 1)?.fieldId,
                    levelThreeMainMaterial: meterialList.find(m => m.levelType === 3 && m.mainFlag === 1)?.fieldName?.trim(),
                    threeOtherMaterials: meterialList.filter(m => m.levelType === 3 && m.mainFlag !== 1),
                    projectId: meterialList[0].projectId,
                }
            })
        }
    }, [meterialList])

    const noOfLevel: number = formData.noOfLevel ?? 0

    return <SectionCard allowCollapse>
        <SectionCardHeader
            title={intl.formatMessage({ id: "createMaterials" })}
            subtitle=""
            serialNumber={3}
            step
            variant={step === 2 ? 'info' : (step !== undefined && step > 2) ? 'success' : 'disabled'}
            actions={mode === ScreenMode.VIEW || step === undefined || step < 2 ?
                []
                : <MaterialAction hasUsageHistory={hasUsageHistory} materialNameList={materialNameList} formData={formData} setFormData={setFormData} projectInfo={projectInfo} setMessages={setMessages} searchMeterial={searchMeterial} searchMaterialNames={searchMaterialNames} searchProjectInfo={searchProjectInfo}></MaterialAction>}
        />
        <SectionCardContent>
            <Form data={formData} setData={setFormData} labelDisplay="block" helperDisplay="tooltip" messages={messages}>
                <EntryItem field="noOfLevel" required readonly={readonly} label={intl.formatMessage({ id: "levelType" })} entries={levelEntries} />
                <Placeholder /><Placeholder />
                {noOfLevel >= 1 && <LevelView step={step} fields={materialNameList} level={1} formData={formData} setFormData={setFormData} mode={mode} />}
                {noOfLevel >= 2 && <LevelView step={step} fields={materialNameList} level={2} formData={formData} setFormData={setFormData} mode={mode} />}
                {noOfLevel === 3 && <LevelView step={step} fields={materialNameList} level={3} formData={formData} setFormData={setFormData} mode={mode} />}
            </Form>
        </SectionCardContent>
    </SectionCard>
})

const LevelView = ({ fields, level, formData, setFormData, mode, step }: {
    fields: string[],
    level: number,
    formData: ProjectMaterialsFactor,
    setFormData: React.Dispatch<React.SetStateAction<ProjectMaterialsFactor>>,
    mode: ScreenMode,
    step: number | undefined,
}) => {

    const intl = useIntl()
    const readonly = useMemo(() => mode === ScreenMode.VIEW || step !== 2, [mode, step])
    const findMainFileName = useCallback((level: number) => {
        return level === 1 ? 'levelOneMainMaterial' : level === 2 ? 'levelTwoMainMaterial' : 'levelThreeMainMaterial'
    }, [])
    const findOtherFileName = useCallback((level: number) => {
        return level === 1 ? 'oneOtherMaterials' : level === 2 ? 'twoOtherMaterials' : 'threeOtherMaterials'
    }, [])

    const getFieldValue = useCallback((level: number) => {
        return (data: any) => data[findMainFileName(level)]
    }, [findMainFileName])
    const mapFieldValue = useCallback((level: number) => {
        return (data: any, value: any) => ({ ...data, [`${findMainFileName(level)}`]: value })
    }, [findMainFileName])

    const getOtherFieldValue = useCallback((index: number, level: number) => {
        return (data: any) => {
            return data[findOtherFileName(level)] && data[findOtherFileName(level)].length > index ? data[findOtherFileName(level)][index].fieldName?.trim() : ''
        }
    }, [findOtherFileName])

    const mapOtherFieldValue = useCallback((index: number, level: number) => {
        return (data: any, value: any) => {
            const fieldName = findOtherFileName(level)
            const fieldValue = data[fieldName]
            return ({ ...data, [`${fieldName}`]: fieldValue.map((field: any, _index: number) => ({ ...field, fieldName: _index === index ? value?.trim() : field.fieldName?.trim() })) })
        }
    }, [findOtherFileName])

    const otherFieldNames = level === 1 ? formData.oneOtherMaterials : level === 2 ? formData.twoOtherMaterials : formData.threeOtherMaterials

    return <>
        <GridItem columnSpan={3}><SectionTitle size="small">{'Level ' + level}</SectionTitle></GridItem>
        <AutoCompleteItem readonly={readonly} field={findMainFileName(level)} required label={intl.formatMessage({ id: "mainDescription" })} suggestions={fields} getValue={getFieldValue(level)} mapValue={mapFieldValue(level)} />
        <Placeholder /><Placeholder />

        <GridItem columnSpan={2}><SectionTitle size="small"><FormattedMessage id="otherDescription" /></SectionTitle></GridItem>
        <Placeholder />

        {otherFieldNames
            && otherFieldNames.map((_, index) => {
                return <>
                    <AutoCompleteItem readonly={readonly} field={findOtherFileName(level) + index} label={intl.formatMessage({ id: "otherDesc" }) + ' ' + (index + 1)} suggestions={fields} getValue={getOtherFieldValue(index, level)} mapValue={mapOtherFieldValue(index, level)} />
                    <GridItem style={{ display: 'flex', alignItems: 'flex-end' }}>{mode !== ScreenMode.VIEW && step === 2 && <RemoveMaterialAction setFormData={setFormData} level={level} index={index} />}</GridItem>
                    <Placeholder />
                </>
            })}

        <GridItem columnSpan={3}> {mode !== ScreenMode.VIEW && step === 2 && <AddMaterialAction setFormData={setFormData} level={level} />}</GridItem>
    </>
}

//add or delete description
const AddMaterialAction = ({ setFormData, level }: {
    setFormData: React.Dispatch<React.SetStateAction<ProjectMaterialsFactor>>
    level: number
}) => {
    const addAction = useCallback(() => {
        setFormData((formData) => {
            const fieldName = level === 1 ? 'oneOtherMaterials' : level === 2 ? 'twoOtherMaterials' : 'threeOtherMaterials'
            const oldOtherFieldNames = level === 1 ? formData.oneOtherMaterials ?? [] : level === 2 ? formData.twoOtherMaterials ?? [] : formData.threeOtherMaterials ?? []
            const otherFieldNames = [...oldOtherFieldNames, { fieldName: '', orderNo: oldOtherFieldNames.length }]
            return { ...formData, [`${fieldName}`]: otherFieldNames }
        })
    }, [level, setFormData])
    return <CallbackCardAction outlined title={<FormattedMessage id="addDesc" />} callback={addAction} />
}

//add or delete description
const RemoveMaterialAction = ({ setFormData, level, index }: {
    setFormData: React.Dispatch<React.SetStateAction<ProjectMaterialsFactor>>,
    level: number,
    index: number,
}) => {
    const removeAction = useCallback(() => {
        setFormData((formData) => {
            const fieldName = level === 1 ? 'oneOtherMaterials' : level === 2 ? 'twoOtherMaterials' : 'threeOtherMaterials'
            const oldOtherFieldNames = level === 1 ? formData.oneOtherMaterials : level === 2 ? formData.twoOtherMaterials : formData.threeOtherMaterials
            const otherFieldNames = Arrays.splice(oldOtherFieldNames ?? [], index, 1).map((data, idx) => ({ ...data, orderNo: idx }))
            return { ...formData, [`${fieldName}`]: otherFieldNames }
        })
    }, [index, level, setFormData])
    return <Tooltip title={<FormattedMessage id="delDesc" />}>
        <IconButton onClick={removeAction}><Clear fontSize={'small'} /></IconButton>
    </Tooltip>
}


const MaterialAction = ({ hasUsageHistory, projectInfo, formData, setFormData, setMessages, searchMeterial, searchMaterialNames, materialNameList, searchProjectInfo }: {
    hasUsageHistory: boolean,
    projectInfo: ProjectResult,
    formData: ProjectMaterialsFactor,
    setFormData: React.Dispatch<React.SetStateAction<ProjectMaterialsFactor>>,
    // step: number,
    // setStep: React.Dispatch<React.SetStateAction<number>>,
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
    searchMeterial: (id: number) => void,
    searchMaterialNames: () => void,
    materialNameList: string[],
    searchProjectInfo: (id: number) => void,
}) => {
    const dispatch = useDispatch()
    const [disabled, setDisabled] = useState<boolean>(false)
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'confirm' }), [intl])
    const saveMethod = useSaveMeterial()
    const { projectId, step } = projectInfo
    const doCheck = useCallback((factor: ProjectMaterialsFactor): boolean => {
        if (factor.noOfLevel === null || factor.noOfLevel === undefined || factor.noOfLevel === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'LevelType' }, messages: { code: 'w0389' } }))
            return false
        }

        if ((factor.noOfLevel ?? 0) > 0) {
            if (factor.levelOneMainMaterial === null || factor.levelOneMainMaterial === undefined) {
                dispatch(applicationActions.pushError({ title: { code: 'MainRequied' }, messages: { code: 'w0390' } }))
                return false
            }

            if ((factor.oneOtherMaterials ?? []).length > 0) {
                const fieldArr = factor.oneOtherMaterials ?? []
                const arr = Arrays.distinct(fieldArr.map(m => m.fieldName?.trim()))
                if (arr.length < fieldArr.length) {
                    dispatch(applicationActions.pushError({ title: { code: 'OtherRepeated' }, messages: { code: 'w0388' } }))
                    return false
                }
                if (arr.includes(factor.levelOneMainMaterial)) {
                    dispatch(applicationActions.pushError({ title: { code: 'OtherRepeated' }, messages: { code: 'w0388' } }))
                    return false
                }
            }

        }
        if ((factor.noOfLevel ?? 0) > 1) {
            if (factor.levelTwoMainMaterial === null || factor.levelTwoMainMaterial === undefined) {
                dispatch(applicationActions.pushError({ title: { code: 'MainRequied' }, messages: { code: 'w0390' } }))
                return false
            }

            if ((factor.twoOtherMaterials ?? []).length > 0) {
                const fieldArr = factor.twoOtherMaterials ?? []
                const arr = Arrays.distinct(fieldArr.map(m => m.fieldName?.trim()))
                if (arr.length < fieldArr.length) {
                    dispatch(applicationActions.pushError({ title: { code: 'OtherRepeated' }, messages: { code: 'w0388' } }))
                    return false
                }
                if (arr.includes(factor.levelTwoMainMaterial)) {
                    dispatch(applicationActions.pushError({ title: { code: 'OtherRepeated' }, messages: { code: 'w0388' } }))
                    return false
                }
            }
        }
        if ((factor.noOfLevel ?? 0) > 2) {
            if (factor.levelThreeMainMaterial === null || factor.levelThreeMainMaterial === undefined) {
                dispatch(applicationActions.pushError({ title: { code: 'MainRequied' }, messages: { code: 'w0390' } }))
                return false
            }

            if ((factor.threeOtherMaterials ?? []).length > 0) {
                const fieldArr = factor.threeOtherMaterials ?? []
                const arr = Arrays.distinct(fieldArr.map(m => m.fieldName?.trim()))
                if (arr.length < fieldArr.length) {
                    dispatch(applicationActions.pushError({ title: { code: 'OtherRepeated' }, messages: { code: 'w0388' } }))
                    return false
                }
                if (arr.includes(factor.levelThreeMainMaterial)) {
                    dispatch(applicationActions.pushError({ title: { code: 'OtherRepeated' }, messages: { code: 'w0388' } }))
                    return false
                }
            }
        }

        return true
    }, [dispatch])

    const [warnOpen, setWarnOpen] = useState<boolean>(false)

    const saveMeterial = useCallback((isConfirm: number) => {
        const saveData = {
            ...formData,
            levelOneMainMaterial: formData?.levelOneMainMaterial?.trim(),
            levelTwoMainMaterial: formData?.levelTwoMainMaterial?.trim(),
            levelThreeMainMaterial: formData?.levelThreeMainMaterial?.trim(),
            //oneOtherMaterialsArr: formData?.oneOtherMaterials?.filter(f => f.fieldName !== undefined && f.fieldName !== null && f.fieldName?.trim() !== ''),
            //twoOtherMaterialsArr: formData?.twoOtherMaterials?.filter(f => f.fieldName !== undefined && f.fieldName !== null && f.fieldName?.trim() !== ''),
            //threeOtherMaterialsArr: formData?.threeOtherMaterials?.filter(f => f.fieldName !== undefined && f.fieldName !== null && f.fieldName?.trim() !== ''),
        }
        setFormData(saveData)
        if (doCheck(saveData)) {

            const saveFuction = () => {
                setDisabled(true)
                saveMethod({ ...saveData, projectId: projectInfo.projectId, isConfirm: isConfirm }, { serialized: true }).then(() => {
                    projectId && searchProjectInfo(projectId)
                    projectId && searchMeterial(projectId)
                    searchMaterialNames()
                }).finally(() => {
                    setDisabled(false)
                })
            }

            if (isConfirm === 1) {
                const functionId = functionStore.register(() => {
                    saveFuction()
                })
                dispatch(applicationActions.pushWarning({
                    title: title,
                    messages: { code: 'c0001', args: [title] },
                    actions: [{
                        label: 'CANCEL'
                    }, {
                        functionId,
                        label: 'CONFIRM',
                    }]
                }))
            } else {
                saveFuction()
            }

        }
    }, [dispatch, doCheck, formData, functionStore, projectId, projectInfo.projectId, saveMethod, searchMaterialNames, searchMeterial, searchProjectInfo, setFormData, title])
    const downloadMaterials = useDownloadMaterials()
    const downLoadMethod = useCallback(() => {
        setWarnOpen(false)
        downloadMaterials({ projectId: projectId }, { serialized: true }).then(() => { })
    }, [downloadMaterials, projectId])

    const uploadBomMaterial = useUploadBomMaterial()
    const uploadMethod = useCallback((files: FileList | null) => {
        if (files === null) return
        uploadBomMaterial({ file: files[0], projectId: projectId }, { serialized: true }).then(() => {
            projectId && searchProjectInfo(projectId)
            projectId && searchMeterial(projectId)
            searchMaterialNames()
        })
    }, [projectId, searchMaterialNames, searchMeterial, searchProjectInfo, uploadBomMaterial])

    const saveAndDownload = () => {
        const saveData = {
            ...formData,
            levelOneMainMaterial: formData?.levelOneMainMaterial?.trim(),
            levelTwoMainMaterial: formData?.levelTwoMainMaterial?.trim(),
            levelThreeMainMaterial: formData?.levelThreeMainMaterial?.trim(),
            //oneOtherMaterialsArr: formData?.oneOtherMaterials?.filter(f => f.fieldName !== undefined && f.fieldName !== null && f.fieldName?.trim() !== ''),
            //twoOtherMaterialsArr: formData?.twoOtherMaterials?.filter(f => f.fieldName !== undefined && f.fieldName !== null && f.fieldName?.trim() !== ''),
            //threeOtherMaterialsArr: formData?.threeOtherMaterials?.filter(f => f.fieldName !== undefined && f.fieldName !== null && f.fieldName?.trim() !== ''),
        }
        setFormData(saveData)
        if (doCheck(saveData)) {
            setWarnOpen(false)
            setDisabled(true)
            saveMethod({ ...saveData, projectId: projectInfo.projectId, isConfirm: 0 }, { serialized: true }).then(() => {
                projectId && searchProjectInfo(projectId)
                projectId && searchMeterial(projectId)
                searchMaterialNames()
                downloadMaterials({ projectId: projectId }, { serialized: true }).then(() => { })
            }).finally(() => {
                setDisabled(false)
            })
        }
    }
    return projectId === null || projectId === undefined ?
        <></> : <>
            {/* {(step > 1) && <SaveCallbackCardAction callback={saveMeterial} />} */}
            {step === 2 && <Tooltip title={<FormattedMessage id="save" />}><IconButton onClick={() => { saveMeterial(0) }} disabled={disabled}><SaveOutlined></SaveOutlined></IconButton></Tooltip>}
            {step === 2 && <Tooltip title={<FormattedMessage id="confirm" />}><IconButton onClick={() => { saveMeterial(1) }} disabled={disabled}><AssignmentTurnedInOutlined></AssignmentTurnedInOutlined></IconButton></Tooltip>}
            {(step !== undefined && step > 2) && <>
                <DownloadGroupedToolbarAction>
                    {onClose => <GroupedCallbackItem label={<FormattedMessage id="downloadMaterial" />} callback={downLoadMethod} onClose={onClose} />}
                </DownloadGroupedToolbarAction>
                <DarkDialog onClose={() => { setWarnOpen(() => !warnOpen) }} aria-labelledby="simple-dialog-title" open={warnOpen}>
                    <DialogContent>
                        <DialogContentText>
                            <FormattedMessage id="saveAndDownload" />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={downLoadMethod} variant="contained"><FormattedMessage id="alert.cancel" /></Button>
                        <Button onClick={saveAndDownload} variant="contained" disabled={disabled}><FormattedMessage id="alert.ok" /></Button>
                    </DialogActions>
                </DarkDialog>
            </>
            }
            {/* {(step !== undefined && step > 2 && !hasUsageHistory) && ( */}
            {(step !== undefined && step > 2) && (
                <UploadGroupedToolbarAction>
                    {(upload, onClose) => <GroupedCallbackItem label={<FormattedMessage id="uploadMaterial" />} onClose={onClose} callback={() => upload(uploadMethod)} />}
                </UploadGroupedToolbarAction>
            )}
        </>
}


//4-Bill Of Materails
const CreateBillMaterialsView = ({ usageHistory, step, projectId, searchUsageHistory, mode, searchProjectInfo }:
    {
        usageHistory: UsageHistoryResult[],
        projectId?: number,
        searchUsageHistory: (id: number) => void,
        step: number | undefined,
        mode: ScreenMode,
        searchProjectInfo: (id: number) => void,
    }) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])
    const columns = useMemo(
        () => [
            { field: "bomNo", dataTypeName: "string", title: intl.formatMessage({ id: "billOfMaterialNo" }), width: 300, },
            { field: "uploadDate", dataTypeName: "date", title: intl.formatMessage({ id: "date" }), width: 200, },
            //{ field: "remarks", dataTypeName: "string", title: intl.formatMessage({ id: "remarks" }), width: 300, },
            { field: "activeFlag", dataTypeName: CodeCategory.SmtActiveFlag, title: intl.formatMessage({ id: "field.status" }), width: 200, },
        ],
        [intl]
    )
    const actionProps = useMemo(() => ({ projectId, searchUsageHistory, step, searchProjectInfo }), [projectId, searchUsageHistory, step, searchProjectInfo])
    const actionProps2 = useMemo(() => ({ projectId, selections, step }), [projectId, selections, step])
    const display1 = useCallback(() => mode !== ScreenMode.VIEW && step !== undefined && step > 2, [mode, step])


    const getRowId = useCallback((row: any) => row.usageHistoryId, [])
    return <SectionCard allowCollapse>
        <SectionCardHeader
            title={intl.formatMessage({ id: "createBillMaterials" })}
            subtitle={intl.formatMessage({ id: 'createBillMaterialsSub' })}
            serialNumber={4}
            step
            variant={step === 3 ? 'info' : (step !== undefined && step > 3) ? 'success' : 'disabled'}
        />
        <SectionCardContent>
            <DataGrid>
                <ToolbarLayout />
                <TableLayout Container={FlexScrollbar}>
                    <TableHeaderLayout sticky />
                    <TableBodyLayout />
                </TableLayout>
                <PaginationLayout />
                <DataTypePreset />
                <CodeCategoryTypeProvider codeCategory={CodeCategory.SmtActiveFlag} />
                <Data rows={usageHistory} columns={columns} getRowId={getRowId} />
                <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps2} display={display1} />
                <ToolbarActionProvider Action={UploadAction} actionProps={actionProps} display={display1} />
                <RowActionProvider name="delete" Action={DeleteRowAction} actionProps={actionProps} display={display1} />
                <ColumnFreeze />
                <ColumnVisibility
                    defaultHiddenFields={[]}
                    columnSettings={{
                        companyCode: { disableUserControl: true },
                        companyName: { disableUserControl: true },
                    }} />
                <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
                <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
                <Searching ignoreCase />
                <Sorting />
                <Filtering />
                <Paging defaultPageSize={20} />
                <Selection showSelectAll selectByRowClick highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
                <Action width={160} />
            </DataGrid>
        </SectionCardContent>
    </SectionCard>
}

const DownloadAction = ({ selections, projectId, step }: { selections: number[], projectId?: number, step: number | undefined }) => {
    const dispatch = useDispatch()
    const downloadByIdApi = useDownloadUsage()
    const download = useCallback(() => {
        if (selections.length > 1) {
            dispatch(applicationActions.pushError({ title: { code: 'download' }, messages: { code: 'w0003' } }))
            return
        }
        if (selections !== null && selections.length > 0) {
            downloadByIdApi({ projectId: projectId, usageHistoryId: selections[0] })
            return
        }
        downloadByIdApi({ projectId: projectId }, { serialized: true })
    }, [dispatch, downloadByIdApi, projectId, selections])
    return projectId && step !== undefined && step > 2 ? <DownloadGroupedToolbarAction>
        {onClose => <GroupedCallbackItem label={<FormattedMessage id="downloadUsage" />} callback={download} onClose={onClose} />}
    </DownloadGroupedToolbarAction> : null
}

const UploadAction = ({ projectId, searchUsageHistory, step, searchProjectInfo }: { projectId?: number, searchUsageHistory: (id: number) => void, step: number | undefined, searchProjectInfo: (id: number) => void }) => {
    const uploadApi = useUploadBillOfMaterial()
    const uploadMethod = useCallback((files: FileList | null) => {
        if (projectId === null || projectId === undefined) {
            return
        }
        if (files === null) return
        uploadApi({ file: files[0], projectId: projectId },
            { serialized: true }).then(() => {
                searchProjectInfo(projectId)
                searchUsageHistory(projectId)
            })
    }, [projectId, searchProjectInfo, searchUsageHistory, uploadApi])
    return projectId && step !== undefined && step > 2 ? <UploadGroupedToolbarAction>
        {(upload, onClose) => <GroupedCallbackItem label={<FormattedMessage id="uploadUsage" />} onClose={onClose} callback={() => upload(uploadMethod)} />}
    </UploadGroupedToolbarAction> : null
}

const DeleteRowAction = ({ tableRow, projectId, searchUsageHistory }: DataGridRowActionProps & { projectId: number, searchUsageHistory: (id: number) => void }) => {
    const deleteMethod = useDeleteUasgeHistory()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'delete' }), [intl])
    const callback = useCallback((tableRow: TableRow) => {
        const functionId = functionStore.register(() => deleteMethod(
            { usageHistoryId: tableRow.row?.usageHistoryId },
            { serialized: true }
        ).then(() => {
            searchUsageHistory(projectId)
        }))
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [deleteMethod, dispatch, functionStore, projectId, searchUsageHistory, title])
    return <>{tableRow.row?.activeFlag === SmtActiveFlag.ACTIVE && <DeleteCallbackRowAction tableRow={tableRow} callback={callback} />}</>
}

//5-Production
const CreateProductionView = ({ planHistory, usageHistory, noOfLevel, projectId, searchPlanHistory, searchProjectInfo, step, mode }: {
    planHistory: ProjectPlanHistoryResult[],
    usageHistory: UsageHistoryResult[],
    projectId?: number,
    noOfLevel?: number,
    searchPlanHistory: (id: number) => void,
    searchProjectInfo: (id: number) => void,
    step: number | undefined,
    mode: ScreenMode,
}) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])

    const columns = useMemo(() => [
        { field: "productionPlanNo", dataTypeName: "string", title: intl.formatMessage({ id: "productionPlan" }), width: 300, },
        { field: "uploadDate", dataTypeName: "date", title: intl.formatMessage({ id: "date" }), width: 200, },
        { field: "remarks", dataTypeName: "string", title: intl.formatMessage({ id: "remarks" }), width: 400, },
        { field: "activeFlag", dataTypeName: CodeCategory.SmtActiveFlag, title: intl.formatMessage({ id: "field.status" }), width: 200, },
    ], [intl])

    const [factor, setFactor] = useState<ProjectPlanFactor>({} as ProjectPlanFactor)

    const actionProps2 = useMemo(() => ({ searchPlanHistory, usageHistory, noOfLevel, projectId, step, selections, factor, setFactor }), [searchPlanHistory, usageHistory, noOfLevel, projectId, step, selections, factor])
    const actionProps3 = useMemo(() => ({ searchPlanHistory, searchProjectInfo, usageHistory, projectId, step }), [searchPlanHistory, searchProjectInfo, usageHistory, projectId, step])
    const display1 = useCallback(() => mode !== ScreenMode.VIEW, [mode])

    const getRowId = useCallback((row: any) => row.planHistoryId, [])
    return <SectionCard allowCollapse>
        <SectionCardHeader
            title={intl.formatMessage({ id: "createProductionPlan" })}
            subtitle={intl.formatMessage({ id: 'createProductionPlanSub' })}
            serialNumber={5}
            step
            variant={step === 4 ? 'info' : (step !== undefined && step > 4) ? 'success' : 'disabled'}
        />
        <SectionCardContent>
            <DataGrid>
                <ToolbarLayout />
                <TableLayout Container={FlexScrollbar}>
                    <TableHeaderLayout sticky />
                    <TableBodyLayout />
                </TableLayout>
                <PaginationLayout />
                <DataTypePreset />
                <ActiveFlagTypeProvider />
                <CodeCategoryTypeProvider codeCategory={CodeCategory.SmtActiveFlag} />
                <Data rows={planHistory} columns={columns} getRowId={getRowId} />
                <ToolbarActionProvider Action={PlanDownLoadAction} actionProps={actionProps2} display={display1} />
                <ToolbarActionProvider Action={PlanUploadAction} actionProps={actionProps3} display={display1} />
                {/* <RowActionProvider name="delete" Action={DeletePlanRowAction} actionProps={actionProps} display={display1} /> */}
                <ColumnFreeze />
                <ColumnVisibility
                    defaultHiddenFields={[]}
                    columnSettings={{
                        companyCode: { disableUserControl: true },
                        companyName: { disableUserControl: true },
                    }} />
                <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
                <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
                <Searching ignoreCase />
                <Sorting />
                <Filtering />
                <Paging defaultPageSize={20} />
                <Selection showSelectAll selectByRowClick highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
                <Action width={160} />
            </DataGrid>
        </SectionCardContent>
    </SectionCard>
}

const PlanDownLoadAction = ({ searchPlanHistory, usageHistory, noOfLevel, projectId, step, selections, factor, setFactor }:
    {
        noOfLevel?: number,
        projectId?: number,
        usageHistory: UsageHistoryResult[],
        step: number | undefined,
        searchPlanHistory: (id: number) => void,
        selections: number[],
        factor: ProjectPlanFactor,
        setFactor: React.Dispatch<React.SetStateAction<ProjectPlanFactor>>,
    }) => {
    const [open, setOpen] = useState<boolean>(false)
    const dispatch = useDispatch()
    const downloadPlan = useDownloadPlan()

    const handleOpen = useCallback(() => {
        if (selections && selections.length > 1) {
            dispatch(applicationActions.pushError({ title: { code: 'downloadPlan' }, messages: { code: 'w0003' } }))
            return
        }
        if (selections && selections.length === 1) {
            downloadPlan({ projectId: projectId, planHistoryId: selections[0] }, { serialized: true })
        } else {
            setOpen(true)
        }
    }, [dispatch, downloadPlan, projectId, selections])

    return (
        <>
            {projectId && (step !== undefined && step >= 3) && (usageHistory?.length > 0) &&
                <DownloadGroupedToolbarAction>
                    {onClose => <>
                        <GroupedCallbackItem label={<FormattedMessage id="downloadPlan" />} callback={handleOpen} onClose={onClose} />
                    </>}
                </DownloadGroupedToolbarAction>
            }
            <DialogView factor={factor} setFactor={setFactor} searchPlanHistory={searchPlanHistory} noOfLevel={noOfLevel} projectId={projectId} open={open} setOpen={setOpen} actionTYpe={0} usageHistory={usageHistory}></DialogView>
        </>
    )
}

const PlanUploadAction = ({ usageHistory, projectId, step, searchPlanHistory, searchProjectInfo }:
    {
        projectId?: number,
        usageHistory: UsageHistoryResult[],
        step: number | undefined,
        searchPlanHistory: (id: number) => void,
        searchProjectInfo: (id: number) => void,
    }) => {

    const uploadPlan = useUploadProduction()
    const uploadMethod = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            files && uploadPlan({ file: files[0], projectId: projectId }, { serialized: true }).then(() => {
                searchProjectInfo(projectId ?? 0)
                searchPlanHistory(projectId ?? 0)
            })
        })
    }, [projectId, searchPlanHistory, searchProjectInfo, uploadPlan])

    return (
        <>
            {projectId && (step !== undefined && step >= 3) && (usageHistory?.length > 0) &&
                <UploadGroupedToolbarAction>
                    {(upload, onClose) => <>
                        <GroupedCallbackItem label={<FormattedMessage id="uploadPlan" />} callback={() => uploadMethod(upload)} onClose={onClose} />
                    </>}
                </UploadGroupedToolbarAction>}
        </>
    )
}

const DialogView = ({ factor, setFactor, open, setOpen, actionTYpe, usageHistory, noOfLevel, projectId, searchPlanHistory }:
    {
        open: boolean,
        setOpen: React.Dispatch<React.SetStateAction<boolean>>,
        actionTYpe: number,
        usageHistory: UsageHistoryResult[],
        noOfLevel?: number,
        projectId?: number,
        searchPlanHistory: (id: number) => void,
        factor: ProjectPlanFactor,
        setFactor: React.Dispatch<React.SetStateAction<ProjectPlanFactor>>,
    }) => {
    const intl = useIntl()
    const [messages, setMessages] = useState<Message[]>([])
    const fields = getProductionPlanFormCheckFields(true)
    const fieldCheck = useFieldChecker(fields, setMessages)
    const formValidate = useFormValidater(setMessages, fields)

    const typeList = useMemo(() =>
        [
            { key: "DAILY", value: ProductionPlanType.DAILY },
            { key: "WEEKLY", value: ProductionPlanType.WEEKLY },
            { key: "MONTHLY", value: ProductionPlanType.MONTHLY }
        ].map(m => [m.value, m.key] as [number, string]), [])
    const usageList = useMemo(() => usageHistory.filter(m => m.activeFlag === SmtActiveFlag.ACTIVE).map(m => [m.usageHistoryId, m.bomNo] as [number, string]), [usageHistory])

    const levelEntries = useMemo(() => {
        if (noOfLevel) {
            return [0, 1, 2, 3].filter(f => f <= noOfLevel).map(m => [m, m === 0 ? 'Final Product' : 'Level ' + m] as [number, string])
        } else {
            return []
        }
    }, [noOfLevel])

    const downloadPlan = useDownloadPlan()
    const downLoadMethod = useCallback(() => {
        if (projectId == null || projectId === undefined) return
        if (formValidate(factor)) {
            setMessages([])
            downloadPlan({ ...factor, projectId: projectId }, { serialized: true }).then(() => { setOpen(false) })
        }
    }, [downloadPlan, factor, formValidate, projectId, setOpen, setMessages])

    return (<DarkDialog maxWidth={"sm"} open={open} onClose={() => { setOpen(v => !v) }} keepMounted={false} aria-labelledby="simple-dialog-title" >
        <DialogContent>
            <Form data={factor} setData={setFactor} labelDisplay="block" helperDisplay="tooltip" maxWidth={500} minWidth={500} columnCount={1} ensure={fieldCheck} messages={messages} >
                <EntryItem field={"productionType"} label={intl.formatMessage({ id: "productionType" })} required entries={typeList} />
                <EntryItem field={"usageHistoryId"} label={intl.formatMessage({ id: "billOfMaterialNo" })} required entries={usageList} />
                <EntryItem field={"noOfLevel"} label={intl.formatMessage({ id: "levelType" })} required entries={levelEntries} />
                <YearMonthItem field={"yearMonthFrom"} label={intl.formatMessage({ id: "from" })} required />
                <YearMonthItem field={"yearMonthTo"} label={intl.formatMessage({ id: "to" })} required />
            </Form>
        </DialogContent>
        <DialogActions>
            <Button autoFocus onClick={() => { setOpen(v => !v) }} variant="contained">
                <FormattedMessage id="alert.cancel" />
            </Button>
            <Button onClick={downLoadMethod} variant="contained"><FormattedMessage id="download" /></Button>
        </DialogActions>
    </DarkDialog>
    )
}

const getFormCheckFields = () => {
    return {
        projectName: { labelId: 'projectName', required: true, length: { max: 255 } },
        projectCode: { labelId: 'projectCode', required: true, length: { max: 32 } },
        customerId: { labelId: 'customerId', required: true },
        remarks: { labelId: 'remarks', length: { max: 255 } },
    }
}

const getFormCheckFieldsForCust = () => {
    return {
        projectName: { labelId: 'projectName', required: true, length: { max: 255 } },
        projectCode: { labelId: 'projectCode', required: true, length: { max: 32 } },
        sellerUid: { labelId: 'sellerUid', required: true },
        remarks: { labelId: 'remarks', length: { max: 255 } },
    }
}

const getFormCheckFields2 = (required: boolean) => {
    return {
        mainFieldName: { labelId: 'mainDescription', required: required, length: { max: 255 } },
    }
}

const getProductionPlanFormCheckFields = (required: boolean) => {
    return {
        productionType: { labelId: 'productionType', required: required },
        usageHistoryId: { labelId: 'usage', required: required },
        noOfLevel: { labelId: 'levelType', required: required },
        yearMonthFrom: { labelId: 'from', required: required },
        yearMonthTo: { labelId: 'to', required: required },
    }
}