import { FormControl, FormControlLabel, Radio, RadioGroup, Typography, useTheme } from "@material-ui/core"
import { Column, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Editing, Filtering, KeyTypeProvider, PaginationLayout, Paging, Row, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarLayout } from "@rithe/data-grid"
import { GridContainer, GridItem } from "@rithe/ui"
import { Objects, Records } from "@rithe/utils"
import React, { memo, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { DownloadCallbackCardAction } from "../../../components/Action/DownloadCallbackCardAction"
import { SubmitCallbackViewAction } from "../../../components/Action/SubmitCallbackViewAction"
import { UploadCallbackCardAction } from "../../../components/Action/UploadCallbackCardAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CreateCallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/CreateCallbackToolbarAction"
import { RemoveCallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/RemoveCallbackToolbarAction"
import { ActiveFlagTypeProvider } from "../../../components/DataGrid/typeProviders/ActiveFlagTypeProvider"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { View } from "../../../components/View/View"
import { useFunctionStore } from "../../../Root"
import { useUnitPartsMasterByEdit } from "../../../services/master/apis/masterDownloadApi"
import { useUploadMasterUnitPartsMaster } from "../../../services/master/apis/masterUploadApi"
import { useSaveUnitPartsList } from "../../../services/master/apis/unitPartsApi"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { TnmUom } from "../../../services/master/models/TnmUom"
import { applicationActions } from "../../Application/applicationSlice"
import { UnitPartsResultEx } from "./MLS081"

interface MLS081PcUiProps {
    entityList: UnitPartsResultEx[],
    setEntityList: React.Dispatch<React.SetStateAction<UnitPartsResultEx[]>>,
    uoms: TnmUom[]
}

export const MLS081PcUi = (props: MLS081PcUiProps) => {
    const { entityList, setEntityList, uoms } = props
    const [type, setType] = useState<number>(0)
    const intl = useIntl()
    const actions = [<SubmitAction entityList={entityList} setEntityList={setEntityList} />]

    return <View actions={actions}>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={1}
                title={'Select Mode'}
                subtitle={intl.formatMessage({ id: 'SelectUnitparts' })}
            />
            <SectionCardContent>
                <SelectCard type={type} setType={setType} />
            </SectionCardContent>
        </SectionCard>
        {type === 1 && <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={2}
                title={'Select Download Or Upload'}
                subtitle={'Please select download or upload'}
            />
            <SectionCardContent>
                <StepDownloadUploadCard entityList={entityList} setEntityList={setEntityList} />
            </SectionCardContent>
        </SectionCard>}
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={type === 1 ? 3 : 2}
                title={'Display Data'}
                subtitle={'Please create or modify data'}
            />
            <SectionCardContent>
                <DataTable uoms={uoms} entityList={entityList} setEntityList={setEntityList} />
            </SectionCardContent>
        </SectionCard>
    </View >
}

interface SelectCardProps {
    type: number,
    setType: React.Dispatch<React.SetStateAction<number>>
}

const SelectCard = memo(({ type, setType }: SelectCardProps) => {
    const intl = useIntl()
    const changeRedioValue = useCallback((value) => (event: React.ChangeEvent<{}>, checked: boolean) => { checked && setType(value) }, [setType])
    return <FormControl component="fieldset">
        <RadioGroup aria-label="method" name="method" value={type} >
            <FormControlLabel value={0} control={<Radio id="MLS081_Radio_CreateUnitparts" />} onChange={changeRedioValue(0)} label={intl.formatMessage({ id: 'CreateUnitparts' })} />
            <FormControlLabel value={1} control={<Radio id="MLS081_Radio_CreateUnitPartsbyDownloadUpload" />} onChange={changeRedioValue(1)} label={intl.formatMessage({ id: 'CreateUnitPartsbyDownloadUpload' })} style={{ marginTop: '20px' }} />
        </RadioGroup>
    </FormControl>
})

interface DownloadUploadProps {
    entityList: UnitPartsResultEx[],
    setEntityList: React.Dispatch<React.SetStateAction<UnitPartsResultEx[]>>,
}

const StepDownloadUploadCard = memo(({ entityList, setEntityList }: DownloadUploadProps) => {
    const theme = useTheme()

    return (
        <GridContainer columnWidth={[500, 200]} style={{ paddingLeft: theme.spacing(5) }} rowGap={theme.spacing(2)}>
            <GridItem style={{ display: 'flex', alignItems: 'center' }} >
                <Typography variant='body1'><FormattedMessage id='downloadUnitInStep1' /></Typography>
            </GridItem>
            <GridItem>
                <DownloadAction entityList={entityList} />
            </GridItem>
            <GridItem style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='body1'><FormattedMessage id='uplaodUnitInStep2' /></Typography>
            </GridItem>
            <GridItem >
                <UploadAction setEntityList={setEntityList} />
            </GridItem>
        </GridContainer>
    )
})


const UploadAction = ({ setEntityList }: { setEntityList: React.Dispatch<React.SetStateAction<UnitPartsResultEx[]>> }) => {
    const uploadMethod = useUploadMasterUnitPartsMaster()
    const upload = useCallback((files: FileList | null) => {
        if (files === null) return
        uploadMethod({ file: files[0] }, { serialized: true }).then((result) => {
            if (result) {
                setEntityList((result || []).map((m, idx) => ({ virturalId: idx, ...m } as UnitPartsResultEx)))
            }
        })
    }, [setEntityList, uploadMethod])

    return <UploadCallbackCardAction access="MARS.MLS081.UPLOAD" callback={upload} />
}

const DownloadAction = ({ entityList }: { entityList: UnitPartsResultEx[] }) => {
    const downloadByEdit = useUnitPartsMasterByEdit()
    const download = useCallback(() => {
        const partsList = entityList.map(m => Objects.delete(m, 'virturalId'))
        downloadByEdit(partsList)
    }, [downloadByEdit, entityList])
    return <DownloadCallbackCardAction outlined access="MARS.MLS071.DOWNLOAD" callback={download} />
}

const DataTable = memo(({ entityList, setEntityList, uoms }: {
    entityList: UnitPartsResultEx[],
    setEntityList: React.Dispatch<React.SetStateAction<UnitPartsResultEx[]>>,
    uoms: TnmUom[]
}) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])

    const columns = useMemo(() => [
        { field: 'newMod', dataTypeName: 'NewModType', title: intl.formatMessage({ id: 'newMod' }), width: 180 },
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 180 },
        { field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsNo' }), width: 180 },
        { field: 'unitPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsName' }), width: 180 },
        { field: 'partsRefNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsRefNo' }), width: 180 },
        { field: 'backNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.backNo' }), width: 180 },
        { field: 'hscode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.hscode' }), width: 180 },
        { field: 'uomCode', dataTypeName: 'uomType', title: intl.formatMessage({ id: 'field.uomCode' }), width: 180 },
        { field: 'pairedFlag', dataTypeName: CodeCategory.PairedFlag, title: intl.formatMessage({ id: 'field.pairedFlag' }), width: 180 },
        { field: 'pairedPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'pairedPartsNo' }), width: 180 },
        { field: 'pairedOrderFlag', dataTypeName: CodeCategory.PairedOrderFlag, title: intl.formatMessage({ id: 'field.pairedOrderFlag' }), width: 180 },
        { field: 'activeFlag', dataTypeName: CodeCategory.ActiveFlag, title: intl.formatMessage({ id: 'field.activeFlag' }), width: 150 },
    ], [intl])

    const actionProps1 = useMemo(() => ({ setEntityList }), [setEntityList])
    const actionProps2 = useMemo(() => ({ selections, setEntityList, setSelections }), [selections, setEntityList, setSelections])
    const displayAdd = useCallback(() => true, [])
    const displayRemove = useCallback(() => true, [])

    const onEditingRowCommit = useCallback((_: Column, row: Row) => {
        setEntityList(entityList => entityList.map((item, idx) => {
            if (item.virturalId === row.virturalId) {
                return row as UnitPartsResultEx
            } else {
                return item
            }
        }))
        return true
    }, [setEntityList])

    const editingDisabledColumns = {}//{ partsNo: { editingDisabled: mode !== 'create' }, unitPartsNo: { editingDisabled: mode !== 'create' } }

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.UnitType} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.PairedFlag} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.PairedOrderFlag} />
            <NewModTypeProvider />
            <UomProvider uoms={uoms} />
            <ActiveFlagTypeProvider />
            <Data rows={entityList} columns={columns} />
            <ToolbarActionProvider Action={AddAction} actionProps={actionProps1} display={displayAdd} />
            <ToolbarActionProvider Action={RemoveAction} actionProps={actionProps2} display={displayRemove} />
            <ColumnFreeze />
            <ColumnVisibility
                // defaultHiddenFields={['currentSpq', 'currOrderLot', 'currLength', 'currWidth', 'currHeight', 'currNetWeight', 'currGrossWeight', 'currEffectiveFrom']}
                columnSettings={{
                    partsNo: { disableUserControl: true },
                    oldPartsNo: { disableUserControl: true },
                    displayPartsNo: { disableUserControl: true },
                }} ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Editing
                enableInlineEdit
                onEditingCellCommit={onEditingRowCommit}
                columnSettings={editingDisabledColumns}
            />
            <Sorting />
            <Filtering />
            <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
            <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        </DataGrid>
    </div>
})


const NewModTypeProvider = (props: {}) => {
    const formatter = useMemo(() => ({ format: (value: any) => value }), [])
    const options = useMemo(() => ['NEW', 'MOD'], [])
    return <KeyTypeProvider name={'NewModType'} formatter={formatter} options={options} />
}

const UomProvider = ({ uoms }: { uoms: TnmUom[] }) => {
    const formatter = useMemo(() => ({ format: (value: any) => value }), [])
    const options = useMemo(() => uoms.map(uom => uom.uomCode), [uoms])
    return <KeyTypeProvider name={'uomType'} formatter={formatter} options={options} />
}

const SubmitAction = (({ entityList, setEntityList }: {
    entityList: UnitPartsResultEx[],
    setEntityList: React.Dispatch<React.SetStateAction<UnitPartsResultEx[]>>
}) => {
    const navigate = useNavigate()
    const saveEvent = useSaveUnitPartsList()
    const dispatch = useDispatch()
    const intl = useIntl()
    const title = useMemo(() => intl.formatMessage({ id: 'submit' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const functionStore = useFunctionStore()
    const clickEvent = useCallback(() => {

        if (entityList.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'submit' }, messages: { code: 'w0004' } }))
            return
        }
        const functionId = functionStore.register(() => {
            setDisabled(true)
            const partsList = entityList.map(m => Objects.delete(m, 'virturalId'))
            saveEvent(partsList, { serialized: true }).then(() => {
                navigate(`/unitparts`)
            }).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [entityList, functionStore, dispatch, title, saveEvent, navigate])

    return <SubmitCallbackViewAction access="MARS.MLS081.SUBMIT" callback={clickEvent} disabled={disabled} />
})

const AddAction = ({ setEntityList }: { setEntityList: React.Dispatch<React.SetStateAction<UnitPartsResultEx[]>> }) => {
    const callback = useCallback(() => setEntityList(entityList => [...entityList, { newMod: 'NEW', virturalId: entityList.length } as UnitPartsResultEx]), [setEntityList])
    return <CreateCallbackToolbarAction title={<FormattedMessage id="Add Item" />} callback={callback} />
}

const RemoveAction = ({ selections, setEntityList, setSelections }: {
    selections: number[],
    setEntityList: React.Dispatch<React.SetStateAction<UnitPartsResultEx[]>>,
    setSelections: React.Dispatch<React.SetStateAction<number[]>>,
}) => {
    const callback = useCallback(() => (
        setSelections((selections) => {
            setEntityList(entityList => entityList.filter((f, idx) => selections.indexOf(idx) < 0))
            return []
        })
    ), [setEntityList, setSelections])
    return <RemoveCallbackToolbarAction title={<FormattedMessage id="Remove Item" />} callback={callback} />
}

