import { Project } from "../../../utils/Project"
import { ShippingMode } from "../../master/enums/ShippingMode"
import { dateFromJson } from "../../utils/deserializer"


export type CargoTrackingView = Required & Partial<Optional>

type CargoTrackingViewJson = JsonRequired & Partial<JsonOptional>

export const cargoTrackingViewFromJson: Project<CargoTrackingViewJson, CargoTrackingView> = json => ({
    ...json,
    etd: dateFromJson(json.etd),
    eta: dateFromJson(json.eta),
    atd: dateFromJson(json.atd),
    ata: dateFromJson(json.ata),
    updatedTime: dateFromJson(json.updatedTime),
})


interface Required extends JsonRequired { }

interface Optional extends Omit<JsonOptional, 'shippingMode' | 'etd' | 'eta' | 'atd' | 'ata' | 'updatedTime'> {
    shippingMode: ShippingMode,
    etd: Date,
    eta: Date,
    atd: Date,
    ata: Date,
    updatedTime: Date,
}

interface JsonRequired {
    cargoTrackingId: number,
}

interface JsonOptional {
    bookingNo: string,
    containerNo: string,
    shippingMode: number,
    sellerCode: string,
    loadingPortCode: string,
    dischargePortCode: string,
    etd: string,
    eta: string,
    atd: string,
    ata: string,
    cargoStatus: string,
    updatedTime: string,
}