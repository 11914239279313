import { Action, Column, ColumnFreeze, ColumnOrdering, ColumnResizing, Data, DataGrid, DataTypePreset, Editing, Filtering, ObjectTypeProvider, PaginationLayout, Row, RowActionProvider, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { ObjectFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/ObjectFormatter"
import { Break, EntryItem, Form, Message, NumberItem, StringItem } from "@rithe/form"
import { Arrays, Records } from "@rithe/utils"
import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { CallbackViewAction } from "../../../components/Action/CallbackViewAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { RejectCallbackRowAction } from "../../../components/DataGrid/rowActions/RejectCallbackRowAction"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { LibertyTypeProvider } from "../../../components/DataGrid/typeProviders/LibertyTypeProvider"
import { View } from "../../../components/View/View"
import { useFunctionStore } from "../../../Root"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { TnmUom } from "../../../services/master/models/TnmUom"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { useConfirmReceivedPCWCDetail, useRejectOneSoRoDetail, useRejectReceivedPCWCDetail } from "../../../services/order/apis/ChangeRequestApi"
import { FinalFlag } from "../../../services/order/enums/FinalFlag"
import { ReceivedPCPartsDetail } from "../../../services/order/models/ReceivedPCDetailResult"
import { useGetCompanyCode } from "../../../utils/ApplicationUtils"
import { useFieldChecker } from "../../../utils/ValidatorUtils"
import { applicationActions } from "../../Application/applicationSlice"
import { ReceivedPCDetailResultBasic, useMergeDatas } from "./OCS028"

interface OCS028PcUiProps {
    search: (roSpotId: number | null | undefined) => void,
    basic: ReceivedPCDetailResultBasic,
    setBasic: React.Dispatch<React.SetStateAction<ReceivedPCDetailResultBasic>>,
    inboundPlans: ReceivedPCPartsDetail[],
    setInboundPlans: React.Dispatch<React.SetStateAction<ReceivedPCPartsDetail[]>>,
    mode: ScreenMode,
    uomList: TnmUom[],
    receiveDcList: TnvCbds[],

}

export const OCS028PcUi = (props: OCS028PcUiProps) => {
    const { search, basic, setBasic, inboundPlans, uomList, setInboundPlans, receiveDcList } = props

    const actions = [<ConfirmAction basic={basic} inboundPlans={inboundPlans} search={search} />, <RejectAction basic={basic} inboundPlans={inboundPlans} search={search} />]
    const intl = useIntl()
    const uomArr = useMemo(() => Arrays.distinct(uomList.map((m) => m.uomCode)), [uomList])


    return <View actions={actions}>
        <SectionCard allowCollapse>
            <SectionCardHeader
                step
                serialNumber={1}
                title={intl.formatMessage({ id: 'inputOrderFcTitle' })}
                subtitle={intl.formatMessage({ id: 'inputShippingPlanTitleSub' })}
            />
            <SectionCardContent>
                <ShippingPlanTable search={search} setInboundPlans={setInboundPlans} uomArr={uomArr} editable={true} basic={basic} setBasic={setBasic} inboundPlans={inboundPlans} />
            </SectionCardContent>
        </SectionCard>

        <SectionCard allowCollapse>
            <SectionCardHeader
                step
                serialNumber={2}
                title={intl.formatMessage({ id: 'inputInboundPlanTitle' })}
                subtitle={intl.formatMessage({ id: 'inputShippingPlanTitleSub' })}
            />
            <SectionCardContent>
                <ShippingPlanTable2 search={search} setInboundPlans={setInboundPlans} uomArr={uomArr} editable={true} basic={basic} setBasic={setBasic} inboundPlans={inboundPlans} />
            </SectionCardContent>
        </SectionCard>

        <SectionCard allowCollapse>
            <SectionCardHeader
                step
                serialNumber={3}
                title={intl.formatMessage({ id: 'basicInfo' })}
                subtitle={intl.formatMessage({ id: 'inputOrderQTYForEachPNTitleSub' })}
            />
            <SectionCardContent>
                <OrderSummaryForm basic={basic} setBasic={setBasic} messages={[]} receiveDcList={receiveDcList} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const OrderSummaryForm = ({ basic, setBasic, messages, receiveDcList }: {
    basic: ReceivedPCDetailResultBasic,
    setBasic: React.Dispatch<React.SetStateAction<ReceivedPCDetailResultBasic>>,
    messages: Message[],
    receiveDcList: TnvCbds[],
}) => {
    const dcMap: [number, string][] = useMemo(() => receiveDcList.filter((m) => m.cbdsType === CbdsType.DC).map(m => [m.cbdsId, m.cbdsCode]), [receiveDcList])
    const intl = useIntl()
    const fields = getFormCheckFields(false)
    const filedCheck = useFieldChecker(fields)
    // const readonly = (basic.status >= SoSpotStatus.Confirmed) ? true : false

    return <Form data={basic} setData={setBasic} labelDisplay="block" helperDisplay="tooltip" ensure={filedCheck} messages={messages} columnCount={3}>
        <StringItem field='orderReference' readonly label={intl.formatMessage({ id: 'orderReference' })} />
        <EntryItem field="receiveDcId" readonly label={intl.formatMessage({ id: 'receiveDcCode' })} entries={dcMap} />
        <Break />
        <NumberItem field="totalAmount" readonly label={intl.formatMessage({ id: 'totalAmount' })} />
        <NumberItem field="totalNumberOfParts" readonly label={intl.formatMessage({ id: 'totalNumberofParts' })} />
        <NumberItem field="totalQty" readonly label={intl.formatMessage({ id: 'totalQty' })} />
        <Break />
        <StringItem field="remark" colSpan={2} readonly label={intl.formatMessage({ id: 'field.remark' })} />
    </Form>
}

const getFormCheckFields = (required: boolean) => {
    return {
        orderReference: { labelId: 'orderReference', required: required, length: { max: 255 } },
        remark: { labelId: 'remark', required: required, length: { max: 255 } }
    }
}

interface ShippingPlanProps {
    basic: ReceivedPCDetailResultBasic,
    setBasic: React.Dispatch<React.SetStateAction<ReceivedPCDetailResultBasic>>,
    inboundPlans: ReceivedPCPartsDetail[],
    setInboundPlans: React.Dispatch<React.SetStateAction<ReceivedPCPartsDetail[]>>,
    editable: boolean,
    uomArr: string[],
    search: (roSpotId: number | null | undefined) => void,
}

const ShippingPlanTable = memo((props: ShippingPlanProps) => {
    const { basic, inboundPlans, editable, uomArr, search } = props
    const intl = useIntl()
    const [order, setOrder] = useState<string[]>([])
    const columns = useMemo(() => {
        const fixedColumns = [
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
            { field: 'partsDescription', dataTypeName: 'string', title: intl.formatMessage({ id: 'partsDescription' }), width: 350 },
            { field: 'buyerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'buyerPartsNo' }), width: 350 },
            { field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'unitPartsNo' }), width: 220 },
            { field: 'uomCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'uomCode' }), width: 180 },
            { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 180 },
            { field: 'sspq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 150 },
            { field: 'oldFirmQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.oldFirmQty' }), width: 150 },
            { field: 'orderQtyInRo', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.newFirmQty' }), width: 150 },
            { field: 'nextSellerCodeInRo', dataTypeName: 'stringFormat', title: intl.formatMessage({ id: 'field.nextSellerCode' }), width: 150, getCellValue: (row: Row) => ({ nextSellerCodeInRo: row.nextSellerCodeInRo, }) },
            { field: 'statusInRo', dataTypeName: CodeCategory.RoSpotDetailStatus, title: intl.formatMessage({ id: 'field.status' }), width: 150 },
        ] as Column[]
        return Arrays.concat(fixedColumns)
    }, [intl])
    const defaultEditingDisabled = Records.from(columns.filter(f => !f.field.includes('-1')).map(({ field }) => [field, { editingDisabled: true }]))
    useEffect(() => {
        setOrder(columns.map(column => column.field))
    }, [columns])

    // Reject
    const actionProps1 = useMemo(() => ({ basic, search }), [basic, search])
    const display1 = useCallback((tableRow: TableRow) =>
        (tableRow.row?.finalFlag === FinalFlag.N && (tableRow.row?.status === 90 || tableRow.row?.status === 20) && tableRow.row?.statusInRo === 20)
        || (tableRow.row?.statusInRo < 80 && tableRow.row?.belowRoDetailStatus === 90)
        || (tableRow.row?.finalFlag === FinalFlag.Y && tableRow.row?.status === 10 && (tableRow.row?.statusInRo === 20 || tableRow.row?.statusInRo !== 90))
        , [])

    return <>
        <div style={{ width: '100%' }}>
            <DataGrid>
                <ToolbarLayout />
                <TableLayout Container={FlexScrollbar}>
                    <TableHeaderLayout sticky />
                    <TableBodyLayout />
                </TableLayout>
                <PaginationLayout Pagination={Pagination} />
                <DataTypePreset />
                <StringTypeProvider />
                <Data rows={inboundPlans} columns={columns} />
                <LibertyTypeProvider name="uomType" options={uomArr} />
                <CodeCategoryTypeProvider codeCategory={CodeCategory.RoSpotDetailStatus} />
                <RowActionProvider name="viewReject" Action={SpotDetailRejectRowAction} actionProps={actionProps1} display={display1} />
                <ColumnFreeze />
                <ColumnOrdering order={order} onOrderChange={setOrder} />

                <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
                <Searching ignoreCase Input={SearchInput} />
                <Sorting />
                <Filtering />
                <Editing
                    enableInlineEdit={editable ? true : false}
                    columnSettings={defaultEditingDisabled}
                />
                <Action width={100} />
            </DataGrid>
        </div>
    </>
})

const ShippingPlanTable2 = memo((props: ShippingPlanProps) => {
    const { inboundPlans, editable, uomArr } = props
    const intl = useIntl()
    const [order, setOrder] = useState<string[]>([])
    const oldPlanDates = useMemo(() => {
        return inboundPlans && inboundPlans.length > 0 ? Arrays.distinct(inboundPlans.flatMap(parts => parts.oldReqInboundPlanList).map(plan => (plan.crdDate).getTime())).sort().map(m => new Date(m)) : []
    }, [inboundPlans])

    const newPlanDates = useMemo(() => {
        return inboundPlans && inboundPlans.length > 0 ? Arrays.distinct(inboundPlans.flatMap(parts => parts.newReqInboundPlanList).map(plan => (plan.crdDate).getTime())).sort().map(m => new Date(m)) : []
    }, [inboundPlans])


    const columns = useMemo(() => {
        const fixedColumns = [
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
            { field: 'partsDescription', dataTypeName: 'string', title: intl.formatMessage({ id: 'partsDescription' }), width: 350 },
            { field: 'buyerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'buyerPartsNo' }), width: 350 },
            { field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'unitPartsNo' }), width: 220 },
            { field: 'uomCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'uomCode' }), width: 180 },
            { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 180 },
            { field: 'sspq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 150 },
        ] as Column[]

        const firmCategories = [{ key: 'firm', value: intl.formatMessage({ id: 'orderQty' }) }]
        const firmColumns = [{ field: 'oldFirmQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.oldFirmQty' }), categories: firmCategories, width: 150 },
        { field: 'orderQtyInRo', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.newFirmQty' }), categories: firmCategories, width: 150 }]

        //old
        const getOldInboundPlanCategories = (index: number) => [
            { key: 'inbound', value: intl.formatMessage({ id: 'oldReqInboundPlanList' }) },
            { key: `inbound${index}`, value: intl.formatDate(oldPlanDates[index], { dateStyle: 'medium' }) },
        ]
        const oldInboundPlanColumns = Arrays.range(0, oldPlanDates.length).flatMap(index => [
            { field: 'crdQty' + index, dataTypeName: 'number', title: intl.formatMessage({ id: 'Qty' }), categories: getOldInboundPlanCategories(index), width: 150, getCellValue: (row: Row) => row.oldReqInboundPlanList.find((f: any) => f.crdDate.getTime() === oldPlanDates[index].getTime())?.crdQty ?? null, },
        ])

        //new
        const getNewInboundPlanCategories = (index: number) => [
            { key: 'newInbound', value: intl.formatMessage({ id: 'newReqInboundPlanList' }) },
            { key: `newInbound${index}`, value: intl.formatDate(newPlanDates[index], { dateStyle: 'medium' }) },
        ]
        const newInboundPlanColumns = Arrays.range(0, newPlanDates.length).flatMap(index => [
            { field: 'newCrdQty' + index, dataTypeName: 'number', title: intl.formatMessage({ id: 'Qty' }), categories: getNewInboundPlanCategories(index), width: 150, getCellValue: (row: Row) => row.newReqInboundPlanList.find((f: any) => f.crdDate.getTime() === newPlanDates[index].getTime())?.crdQty ?? null, },
        ])

        return Arrays.concat(fixedColumns, firmColumns, oldInboundPlanColumns, newInboundPlanColumns)
    }, [intl, newPlanDates, oldPlanDates])

    const defaultEditingDisabled = Records.from(columns.filter(f => !f.field.includes('-1')).map(({ field }) => [field, { editingDisabled: true }]))
    useEffect(() => {
        setOrder(columns.map(column => column.field))
    }, [columns])

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <Data rows={inboundPlans} columns={columns} />
            <LibertyTypeProvider name="uomType" options={uomArr} />
            <ColumnFreeze />
            <ColumnOrdering order={order} onOrderChange={setOrder} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
            <Editing
                enableInlineEdit={editable ? true : false}
                columnSettings={defaultEditingDisabled}
            />

            <Action width={200} />
        </DataGrid>

    </div>
})


const ConfirmAction = memo((props: {
    basic: ReceivedPCDetailResultBasic, inboundPlans: ReceivedPCPartsDetail[], search: (roSpotId: number | null | undefined) => void
}) => {
    const { basic, inboundPlans } = props
    const mergeDatas = useMergeDatas()
    const confirm = useConfirmReceivedPCWCDetail()
    const navigate = useNavigate()

    const onclickSave = useCallback(() => {
        //confirm
        const data = mergeDatas(basic, inboundPlans)
        confirm(data, { serialized: true }).then(result => {
            if (result && result.hasInsertNextRo === 1) {
                navigate(`/rc/modify-${result.roSpotIdInRo}`)
            } else {
                navigate(`/repcwc`)
            }

        })
    }, [mergeDatas, basic, inboundPlans, confirm, navigate])

    if (basic.statusInRo === 20 || basic.statusInRo === 25) return <CallbackViewAction access="ORDER.OCS028.CONFRIM" title={<FormattedMessage id="comfirm" />} callback={onclickSave} />
    return <></>

})

const RejectAction = memo((props: {
    basic: ReceivedPCDetailResultBasic, inboundPlans: ReceivedPCPartsDetail[], search: (roSpotId: number | null | undefined) => void
}) => {
    const { basic, inboundPlans } = props
    const navigate = useNavigate()
    const mergeDatas = useMergeDatas()
    const reject = useRejectReceivedPCWCDetail()

    const onclickSave = useCallback(() => {
        //reject
        const data = mergeDatas(basic, inboundPlans)
        reject(data, { serialized: true }).then(result => {
            if (result) {
                navigate(`/repcwc`)
            }

        })
    }, [mergeDatas, basic, inboundPlans, reject, navigate])

    if (basic.statusInRo === 20 || basic.statusInRo === 25) return <CallbackViewAction access="ORDER.OCS028.REJECT" title={<FormattedMessage id="reject" />} callback={onclickSave} />
    return <></>

})

const StringTypeProvider = () => {
    return <ObjectTypeProvider name="stringFormat" Formatter={StringFormatter} />
}
const StringFormatter = ({ value }: ObjectFormatterProps) => {
    const loginCompanyCode = useGetCompanyCode()
    const nextSellerCode = value.nextSellerCodeInRo
    if (loginCompanyCode !== nextSellerCode) {
        return <>{nextSellerCode}</>
    } else {
        return <></>
    }
}

const SpotDetailRejectRowAction = ({ tableRow, basic, search }: DataGridRowActionProps & { basic: ReceivedPCDetailResultBasic, search: (roSpotId: number | null | undefined) => void, }) => {
    const rejectRequest = useRejectOneSoRoDetail()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'reject' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const callback = useCallback((tableRow: TableRow) => {
        if (tableRow.row !== undefined && tableRow.row?.roSpotIdInRo !== undefined) {
            // const data = mergeDatas(basic, [tableRow.row as ReceivedPCPartsDetail])
            const data = tableRow.row as ReceivedPCPartsDetail
            const functionId = functionStore.register(() => {
                setDisabled(true)
                rejectRequest(data, { serialized: true }).then(result => {
                    if (result) {
                        search(result.roSpotIdInRo)
                    }
                }).finally(() => {
                    setDisabled(false)
                })
            })
            dispatch(applicationActions.pushWarning({
                title: title,
                messages: { code: 'c0001', args: [title] },
                actions:[{
                    label: 'CANCEL'
                },{
                    functionId,
                    label:'CONFIRM',
                }]
            }))
        }
    }, [dispatch, functionStore, rejectRequest, search, title])

    return <RejectCallbackRowAction access="ORDER.OCS028.REJECT" tableRow={tableRow} callback={callback} disabled={disabled}/>
}