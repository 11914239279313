import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { dateFromJson } from "../../utils/deserializer"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"

export type ChangeRequestWithoutContractListResult = ChangeRequestResultRequired & Partial<ChangeRequestResultOptional>
type ChangeRequestWithoutContractListResultJson = ChangeRequestResultJsonRequired & Partial<ChangeRequestResultJsonOptional>

export const changeRequestWithoutContractListResultFromJson: Project<ChangeRequestWithoutContractListResultJson, ChangeRequestWithoutContractListResult> = json => ({
    ...json,
    submitDate: dateFromJson(json.submitDate),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page),

})

export const changeRequestWithoutContractListResultToJson: Project<ChangeRequestWithoutContractListResult, ChangeRequestWithoutContractListResultJson> = obj => ({
    ...obj,
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
    submitDate: dateToJson(obj.submitDate),
})

interface ChangeRequestResultRequired extends ChangeRequestResultJsonRequired {


}

interface ChangeRequestResultOptional extends Omit<ChangeRequestResultJsonOptional, 'submitDate' | 'page'> {
    submitDate: Date,
    page: PageViewEntity,
}

interface ChangeRequestResultJsonRequired {

}

interface ChangeRequestResultJsonOptional {
    coSpotId: number,
    status: number,
    customerOrderNo: string,
    orderFrequency: number,
    orderType: number,
    sellerCode: string,
    submitDate: string,
    page: PageViewEntityJson,
}

export type ParamRequest = ParamRequestRequired & Partial<ParamRequestResultOptional>
type ParamRequestJson = ParamRequestResultJsonRequired & Partial<ParamRequestResultJsonOptional>

export const ParamRequestFromJson: Project<ParamRequestJson, ParamRequest> = json => ({
    ...json,
})

export const ParamRequestToJson: Project<ParamRequest, ParamRequestJson> = obj => ({
    ...obj,
})

interface ParamRequestRequired extends ParamRequestResultJsonRequired {
}

interface ParamRequestResultOptional extends Omit<ParamRequestResultJsonOptional, ''> {

}

interface ParamRequestResultJsonRequired {

}

interface ParamRequestResultJsonOptional {
    coSpotId: number,
    roSpotId: number | null | undefined,
}

