import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useComMasterGetAllRegion } from "../../../services/common/apis/commonMasterApi"
import { useGetPortList } from "../../../services/master/apis/portApi"
import { PortFactor, portFactorFromJson, portFactorToJson } from "../../../services/master/models/PortFactor"
import { PortResult } from "../../../services/master/models/PortResult"
import { TnmRegion } from "../../../services/master/models/TnmRegion"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { MLS060PcUi } from "./MLS060PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }
const searchCachekey = sessionKeys.Filters_MLS060

export const MLS060 = () => {

    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? portFactorFromJson(JSON.parse(filters)) : null
    }, [])

    const [filters, setFilters] = useState<PortFactor>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<PortResult[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [regionList, setRegion] = useState<TnmRegion[]>([])

    const getPortList = useGetPortList()
    const search = useCallback((filters: PortFactor) => {
        getPortList(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(portFactorToJson(filters)))
        })
    }, [getPortList])

    useSearchOnLoad(search, filters)

    const getRegionList = useComMasterGetAllRegion()
    useEffect(() => {
        getRegionList(undefined, { silent: true }).then(result => {
            setRegion(result || [])
        })
    }, [getRegionList])

    return <MLS060PcUi
        filters={filters}
        search={search}
        data={data}
        totalCount={totalCount}
        regionList={regionList}
        setFilters={setFilters}
    />
}
