import { Project } from "../../../utils/Project"
import { dateToJson } from "../../utils/serializer"

export type DownloadInbPlanFactor = Partial<Optional>
type DownloadInbPlanFactorJson = Partial<JsonOptional>
export const downloadInbPlanFactorToJson: Project<DownloadInbPlanFactor, DownloadInbPlanFactorJson> = obj => ({
    ...obj,
    inbPlanDateStart: dateToJson(obj.inbPlanDateStart),
    inbPlanDateEnd: dateToJson(obj.inbPlanDateEnd),
    inbPlanDateStartForEst: dateToJson(obj.inbPlanDateStartForEst),
    inbPlanDateEndForEst: dateToJson(obj.inbPlanDateEndForEst),
})

interface Optional extends Omit<JsonOptional, 'inbPlanDateStart' | 'inbPlanDateEnd' | 'inbPlanDateStartForEst' | 'inbPlanDateEndForEst'> {
    inbPlanDateStart: Date,
    inbPlanDateEnd: Date,
    inbPlanDateStartForEst: Date,
    inbPlanDateEndForEst: Date,
}


interface JsonOptional {
    cbdsId: number,
    partsNo: string,
    contractId: number,
    contractNo: string,
    customerId: number,
    contractRouteId: number,
    contractRouteNo: string,
    inbPlanDateStart: string,
    inbPlanDateEnd: string,
    inbPlanDateStartForEst: string,
    inbPlanDateEndForEst: string,
    cbdsType: number,
    contractNoList: string[],
}
