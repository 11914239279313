import { makeStyles } from '@material-ui/core';
import { ScrollEventCallback } from 'overlayscrollbars';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { HTMLAttributes, ReactNode, useMemo } from 'react';

interface ScrollbarProps extends HTMLAttributes<HTMLDivElement> {
    flexbox?: boolean,
    onScrollbarScroll?: (event: UIEvent) => void,
    children?: ReactNode | ReactNode[],
}

export const Scrollbar = React.forwardRef<OverlayScrollbarsComponent | null, ScrollbarProps>((props, ref) => {
    const { flexbox, className, onScrollbarScroll, children, ...rest } = props;

    const options = useMemo<OverlayScrollbars.Options>(() => ({
        scrollbars: {
            autoHide: 'leave',
            autoHideDelay: 1000,
        },
        callbacks: {
            onScroll: onScrollbarScroll as ScrollEventCallback
        },
    }), [onScrollbarScroll])

    const styles = useStyles()
    return (
        <OverlayScrollbarsComponent ref={inst => {
            ref && (ref instanceof Function ? ref(inst) : ref.current = inst)
        }} options={options} className={flexbox ? className ? `${className} ${styles.flexbox}` : styles.flexbox : className} {...rest}>
            {children}
        </OverlayScrollbarsComponent>
    )
})

const useStyles = makeStyles(() => ({
    flexbox: {
        flex: '1 1 auto',
        display: 'flex',
        overflow: 'hidden !important',
    },
}))