import { Project } from "@rithe/utils"
import { OtherField, otherFieldToJson } from "./ProjectFieldsFactor"

export type ProjectMaterialsFactor = Required & Partial<Optional>;

type ProjectMaterialsFactorJson = JsonRequired & Partial<JsonOptional>;

export const projectMaterialsFactorToJson: Project<ProjectMaterialsFactor, ProjectMaterialsFactorJson> = obj => ({
    ...obj,
    oneOtherMaterials: obj.oneOtherMaterials?.map(otherFieldToJson),
    twoOtherMaterials: obj.twoOtherMaterials?.map(otherFieldToJson),
    threeOtherMaterials: obj.threeOtherMaterials?.map(otherFieldToJson),
})
interface Required extends JsonRequired {

}

interface Optional extends JsonOptional {
}

interface JsonRequired {

}

interface JsonOptional {
    noOfLevel: number,
    levelOneMainMaterialId: number,
    levelOneMainMaterial: string,
    oneOtherMaterials: OtherField[],
    levelTwoMainMaterialId: number,
    levelTwoMainMaterial: string,
    twoOtherMaterials: OtherField[],
    levelThreeMainMaterialId: number,
    levelThreeMainMaterial: string,
    threeOtherMaterials: OtherField[],
    projectId: number,
    isConfirm: number,
}
