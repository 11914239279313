import { useShallow } from "@rithe/utils"
import { AxiosResponse } from "axios"
import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { applicationActions } from "../../layouts/Application/applicationSlice"
import downloadResponse from "../axios/downloadResponse"
import errorToNotification from "../axios/errorToNotification"
import openResponse from "../axios/openResponse"
import printResponse from "../axios/printResponse"
import responseToNotification from "../axios/responseToNotification"
import useAxiosInstance from "../axios/useAxiosInstance"
import { formify } from "../formify"

interface FetchOptions {
    serialized?: boolean,
    silent?: boolean,
    fileName?: string,
    fileTimestamp?: boolean,
    mimeType?: string,
    message?: string,
}

export const usePost = <T, R>(
    url: string,
    payloadProject: (payload: T) => any,
    resultProject: (result: any) => R,
    globalOptions?: FetchOptions
) => {
    const responseProject = useCallback((response: AxiosResponse<any, any>) => resultProject(response.data), [resultProject])
    return usePostFetch<T, R>(url, payloadProject, responseProject, false, globalOptions)
}

export const useUpload = <T, R>(
    url: string,
    payloadProject: (payload: T) => any,
    resultProject: (result: any) => R,
    globalOptions?: FetchOptions
) => {
    const responseProject = useCallback((response: AxiosResponse<any, any>) => resultProject(response.data), [resultProject])
    return usePostFetch<T, R>(url, payload => formify(payloadProject(payload)), responseProject, false, globalOptions)
}

export const useDownload = <T>(
    url: string,
    payloadProject: (payload: T) => any,
    globalOptions?: FetchOptions
) => {
    const responseProject = useCallback((response: AxiosResponse<any, any>, options: FetchOptions) => downloadResponse(response, {
        defaultFilename: options.fileName,
        timestamp: options.fileTimestamp,
        mimeType: options.mimeType,
    }), [])
    return usePostFetch<T, void>(url, payloadProject, responseProject, true, globalOptions)
}

export const useOpen = <T>(
    url: string,
    payloadProject: (payload: T) => any,
    globalOptions?: FetchOptions
) => {
    const responseProject = useCallback((response: AxiosResponse<any, any>, options: FetchOptions) => openResponse(response, {
        defaultFilename: options.fileName,
        timestamp: options.fileTimestamp,
        mimeType: options.mimeType,
    }), [])
    return usePostFetch<T, void>(url, payloadProject, responseProject, true, globalOptions)
}

export const usePrint = <T>(
    url: string,
    payloadProject: (payload: T) => any,
    globalOptions?: FetchOptions
) => {
    const responseProject = useCallback((response: AxiosResponse<any, any>, options: FetchOptions) => printResponse(response, {
        defaultFilename: options.fileName,
        timestamp: options.fileTimestamp,
        mimeType: options.mimeType,
    }), [])
    return usePostFetch<T, void>(url, payloadProject, responseProject, true, globalOptions)
}

const usePostFetch = <T, R>(
    url: string,
    payloadProject: (payload: T) => any,
    responseProject: (response: AxiosResponse<any, any>, options: FetchOptions) => R,
    isBlob: boolean,
    globalOptions?: FetchOptions
) => {
    const shallowGlobalOptions = useShallow(globalOptions)
    const dispatch = useDispatch()
    const axiosInstance = useAxiosInstance()
    const fetch = useCallback((payload: T, localOptions?: FetchOptions) => {
        const options = { ...shallowGlobalOptions, ...localOptions }
        options?.serialized && dispatch(applicationActions.addMask())
        return axiosInstance.post(url, payloadProject(payload), { responseType: isBlob ? 'blob' : 'json' })
            .then(response => {
                !options?.silent && dispatch(applicationActions.pushNotification(responseToNotification(response)))
                return responseProject(response, options)
            })
            .catch(error => {
                dispatch(applicationActions.pushNotification(errorToNotification(error)))
                throw error
            })
            .finally(() => {
                options?.serialized && dispatch(applicationActions.removeMask())
            })
    }, [axiosInstance, dispatch, isBlob, payloadProject, responseProject, shallowGlobalOptions, url])
    return fetch
}


export const usePut = <T, R>(
    url: string,
    payloadProject: (payload: T) => any,
    resultProject: (result: any) => R,
    globalOptions?: FetchOptions
) => {
    const responseProject = useCallback((response: AxiosResponse<any, any>) => resultProject(response.data), [resultProject])
    return usePutFetch<T, R>(url, payloadProject, responseProject, false, globalOptions)
}

const usePutFetch = <T, R>(
    url: string,
    payloadProject: (payload: T) => any,
    responseProject: (response: AxiosResponse<any, any>, options: FetchOptions) => R,
    isBlob: boolean,
    globalOptions?: FetchOptions
) => {
    const shallowGlobalOptions = useShallow(globalOptions)
    const dispatch = useDispatch()
    const axiosInstance = useAxiosInstance()
    const fetch = useCallback((payload: T, localOptions?: FetchOptions) => {
        const options = { ...shallowGlobalOptions, ...localOptions }
        options?.serialized && dispatch(applicationActions.addMask())
        return axiosInstance.put(url, payloadProject(payload), { responseType: isBlob ? 'blob' : 'json' })
            .then(response => {
                !options?.silent && dispatch(applicationActions.pushNotification(responseToNotification(response)))
                return responseProject(response, options)
            })
            .catch(error => {
                dispatch(applicationActions.pushNotification(errorToNotification(error)))
                throw error
            })
            .finally(() => {
                options?.serialized && dispatch(applicationActions.removeMask())
            })
    }, [axiosInstance, dispatch, isBlob, payloadProject, responseProject, shallowGlobalOptions, url])
    return fetch
}