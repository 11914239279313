import { Project } from "../../../utils/Project"

export type InvoiceFilterResponse = Required & Partial<Optional>

type InvoiceFilterResponseJson = JsonRequired & Partial<JsonOptional>
export const invoiceFilterToJson: Project<InvoiceFilterResponse, InvoiceFilterResponseJson> = obj => ({
  ...obj,
  buyerFilter: obj.buyerFilter ? obj.buyerFilter.map(m => invoiceCbdsResultToJson(m)) : [],
  sellerFilter: obj.sellerFilter ? obj.sellerFilter.map(m => invoiceCbdsResultToJson(m)) : [],
})
export const invoiceFilterFromJson: Project<InvoiceFilterResponseJson, InvoiceFilterResponse> = json => ({
  ...json,
  buyerFilter: json.buyerFilter ? json.buyerFilter.map(m => invoiceCbdsResultFromJson(m)) : [],
  sellerFilter: json.sellerFilter ? json.sellerFilter.map(m => invoiceCbdsResultFromJson(m)) : [],
})

interface Required extends JsonRequired { }

interface Optional extends JsonOptional {
}

interface JsonRequired {
    buyerFilter: InvoiceCbds[]
    sellerFilter: InvoiceCbds[]
}

interface JsonOptional {
}

export type InvoiceCbds =  InvoiceCbdsRequired & Partial<InvoiceCbdsOptional>
type InvoiceCbdsJson = InvoiceCbdsJsonRequired & Partial<InvoiceCbdsJsonOptional>
const invoiceCbdsResultFromJson: Project<InvoiceCbdsJson, InvoiceCbds> = json => ({
    ...json,
})
const invoiceCbdsResultToJson: Project<InvoiceCbds, InvoiceCbdsJson> = obj => ({
    ...obj,
})

interface InvoiceCbdsRequired extends InvoiceCbdsJsonRequired { }

interface InvoiceCbdsJsonRequired {
    cbdsUid: string,
    cbdsType: number,
    cbdsId: number,
    cbdsCode: string,
    cbdsName: string,
}

interface InvoiceCbdsOptional extends InvoiceCbdsJsonOptional {
}

interface InvoiceCbdsJsonOptional {
}