import { Divider, withStyles } from "@material-ui/core";

export const DashedDivider = withStyles(theme => ({
    root: {
        background: `linear-gradient(90deg, ${theme.palette.primary.light} 2px, transparent 2px 6px)`,
        backgroundSize: '6px 1px',
    },
    light: {
        background: `linear-gradient(90deg, rgba(152,155,158, 0.5) 2px, transparent 2px 6px)`,
        backgroundSize: '6px 1px',
    },
}))(Divider)