import { useCallback, useEffect, useMemo, useState } from "react"
import { useGetOrderCalculationMasterListPage, useGetStockManagementAndOrderCalculationMasterPage, useGetStockManagementMasterPage } from "../../../services/smt/api/StockManagementMasterApi"
import { alarmUsagePatternViewFromson } from "../../../services/smt/models/AlarmUsagePatternView"
import { StockManagementMasterTransfer } from "../../../services/smt/models/StockManagementMasterTransfer"
import { StockManagementMasterView, stockManagementMasterViewToJson } from "../../../services/smt/models/StockMangementMasterView"
import sessionKeys from "../../../utils/sessionKeys"
import { useMatch } from "../../../utils/useMatch"
import { MSAUF020PcUi } from "./MSAUF020PcUi"


const defaultFilters = { page: { currentPage: 0, pageSize: 1000, } }
const searchCachekey = sessionKeys.Filters_MSAUF010

export enum StockOrderScreenMode {
    ONLYSMT = 1,
    ONLYOC = 2,
    ALLPATTERN = 3,
}

export const MSAUF020 = () => {
    const { path } = useMatch()
    const display = path === "/stockManagementMaster" ? StockOrderScreenMode.ONLYSMT :
        path === "/orderCalculationMaster" ? StockOrderScreenMode.ONLYOC : StockOrderScreenMode.ALLPATTERN

    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? alarmUsagePatternViewFromson(JSON.parse(filters)) : null
    }, [])

    const [filters, setFilters] = useState<StockManagementMasterView>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<StockManagementMasterTransfer[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)

    const getStockManagementMasterPage = useGetStockManagementMasterPage()
    const getOrderCalculationMasterListPage = useGetOrderCalculationMasterListPage()
    const getStockManagementAndOrderCalculationMasterPage = useGetStockManagementAndOrderCalculationMasterPage()
    const getPageData = StockOrderScreenMode.ONLYSMT === display
        ? getStockManagementMasterPage
        : StockOrderScreenMode.ONLYOC === display
            ? getOrderCalculationMasterListPage
            : getStockManagementAndOrderCalculationMasterPage
    const search = useCallback(() => {
        getPageData(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount ?? 0)
            setData(result?.data ?? [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(stockManagementMasterViewToJson(filters)))
        })
    }, [filters, getPageData])

    useEffect(() => {
        search()
    }, [search])

    return <MSAUF020PcUi
        filters={filters}
        setFilters={setFilters}
        search={search}
        data={data}
        totalCount={totalCount}
        display={display}
    />
}