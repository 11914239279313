import { makeStyles, Typography } from "@material-ui/core"
import { SaveOutlined } from "@material-ui/icons"
import { Column, ColumnFreeze, ColumnOrdering, ColumnResizing, Data, DataGrid, DataTypePreset, Filtering, NumberTypeProvider, PaginationLayout, Paging, Row, Searching, Sorting, StringTypeProvider, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarLayout } from "@rithe/data-grid"
import { NumberFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/NumberFormatter"
import { StringFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/StringFormatter"
import { DateItem, Form, StringItem, YearMonthItem } from "@rithe/form"
import { Arrays, Records } from "@rithe/utils"
import { useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/CallbackToolbarAction"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { UploadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/UploadGroupedToolbarAction"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { PercentTypeProvider } from "../../../components/DataGrid/typeProviders/PercentTypeProvider"
import { CodeItem } from "../../../components/Form/CodeItem"
import { View } from "../../../components/View/View"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { useSaveOrderCalculationDetail } from "../../../services/smt/api/OrderCalculationApi"
import { useUploadOrderCalculation } from "../../../services/smt/api/smtUploadApi"
import { SafetyStockUom } from "../../../services/smt/enums/SafetyStockUom"
import { OrderCalculationDetailResult } from "../../../services/smt/models/OrderCalculationDetailResult"
import { OrderCalculationPartsDetailResult } from "./OCCLS014"

interface OCCLS014PNAPcUiProps {
    orderCalcId?: string,
    search: (orderCalcId?: string) => void,
    mode: ScreenMode,
    headerInfo: OrderCalculationDetailResult,
    partsDetailList: OrderCalculationPartsDetailResult[],
}

export const OCCLS014PNAPcUi = (props: OCCLS014PNAPcUiProps) => {
    const { orderCalcId, mode, search, headerInfo, partsDetailList } = props
    const intl = useIntl()

    return <View>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={1}
                title={intl.formatMessage({ id: 'basicInfo' })}
                subtitle={intl.formatMessage({ id: 'inputOrderQTYForEachPNTitleSub' })}
                actions={mode === ScreenMode.VIEW ? [] : [<SaveAction partsDetailList={partsDetailList} search={search} orderCalcId={orderCalcId}></SaveAction>]}
            />
            <SectionCardContent>
                <OrderSummaryForm headerInfo={headerInfo} />
            </SectionCardContent>
        </SectionCard>

        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={2}
                title={intl.formatMessage({ id: 'inputShippingPlanTitle' })}
                subtitle={intl.formatMessage({ id: 'inputShippingPlanTitleSub' })}
            />
            <SectionCardContent>
                <ShippingPlanTable mode={mode} search={search} orderCalcId={orderCalcId} partsDetailList={partsDetailList} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const OrderSummaryForm = ({ headerInfo }: {
    headerInfo: OrderCalculationDetailResult
}) => {
    const intl = useIntl();
    return <Form data={headerInfo} labelDisplay="block" helperDisplay="tooltip" columnCount={3}>
        <StringItem field='customerCode' readonly={true} label={intl.formatMessage({ id: 'field.commonCustomerCode' })} />
        <StringItem field="orderGroupNo" readonly={true} label={intl.formatMessage({ id: 'orderGroupNo' })} />
        <StringItem field="orderCalcNo" readonly={true} label={intl.formatMessage({ id: 'field.orderCalcNo' })} />

        <CodeItem field='orderFrequency' readonly={true} label={intl.formatMessage({ id: 'field.orderFrequency' })} code={CodeCategory.OrderFrequency} />
        <YearMonthItem field="orderStartDate" readonly={true} label={intl.formatMessage({ id: 'field.orderStartDate' })} />
        <YearMonthItem field="targetStartDate" readonly={true} label={intl.formatMessage({ id: 'field.targetStartDate' })} />

        <StringItem field="usageVersion" readonly={true} label={intl.formatMessage({ id: 'customerUsageVersion' })} />
        <DateItem field="custUsageDate" readonly={true} label={intl.formatMessage({ id: 'custUsageDate' })} />
        <DateItem field="previousUsageDate" readonly={true} label={intl.formatMessage({ id: 'previousUsageDate' })} />

        <DateItem field="cutoffDate" readonly={true} label={intl.formatMessage({ id: 'stockTakingDate' })} />
    </Form>
}

const SaveAction = ({ partsDetailList, search, orderCalcId }: {
    orderCalcId?: string,
    partsDetailList: OrderCalculationPartsDetailResult[],
    search: (orderCalcId?: string) => void,
}) => {
    const saveOrderCalculationDetail = useSaveOrderCalculationDetail()
    const [disabled, setDisabled] = useState<boolean>(false)

    const onclickToSave = useCallback(() => {
        setDisabled(true)
        saveOrderCalculationDetail({ orderCalcId: orderCalcId, partsDetailList: partsDetailList }, { serialized: true }).then(result => {
            search(orderCalcId)
        }).finally(() => {
            setDisabled(false)
        })
    }, [orderCalcId, partsDetailList, saveOrderCalculationDetail, search])

    return <>
        <CallbackToolbarAction access="STCK.OCCLS014.SAVE" callback={onclickToSave} title={<FormattedMessage id="save" />} icon={<SaveOutlined />} disabled={disabled} />
    </>
}

const ShippingPlanTable = (props: {
    orderCalcId?: string,
    mode: ScreenMode,
    search: (orderCalcId?: string) => void,
    partsDetailList: OrderCalculationPartsDetailResult[],
}) => {
    const { mode, search, orderCalcId, partsDetailList } = props
    const intl = useIntl();
    const [order, setOrder] = useState<string[]>([])
    const partsDetail = partsDetailList && partsDetailList.length > 0 ? partsDetailList[0] : null

    const getPeriodValue = useCallback((index: number, record: string) => {
        return (row: Row) => row.periodInfoList[index][record]
    }, [])

    const columns = useMemo(() => {
        const baseColumns = [
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
            { field: 'externalRefNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.externalReferenceNo' }), width: 200 },
            { field: 'customerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerPartsNo' }), width: 200 },
            { field: 'partsDescription', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.description' }), width: 200 },
            { field: 'expCountry', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.expCountry' }), width: 200 },
            { field: 'supplier', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplier' }), width: 200 },
            { field: 'pairedPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.pairedPartsNo' }), width: 200 },
            { field: 'model', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.model' }), width: 200 },
            { field: 'orderLot', dataTypeName: 'digitsNumber', title: intl.formatMessage({ id: 'moq' }), width: 200 },
            { field: 'spq', dataTypeName: 'digitsNumber', title: intl.formatMessage({ id: 'mpq' }), width: 200 },
            { field: 'outboundFluctuation', dataTypeName: 'percent', title: intl.formatMessage({ id: 'field.fluctustionStandard' }), width: 200 },
            { field: 'safetyStockUom', dataTypeName: CodeCategory.SafetyStockUom, title: intl.formatMessage({ id: 'field.safetyStockUnit' }), width: 200 },
        ] as Column[]

        const stockLevelCategories = [{ key: 'stockLevel', value: intl.formatMessage({ id: 'stockLevel' }) }]
        const stockLevelColumns = [
            { field: 'maxStock', dataTypeName: 'stockUnitNumber', title: intl.formatMessage({ id: 'field.maximumStock' }), width: 200, categories: stockLevelCategories },
            { field: 'safetyStock', dataTypeName: 'stockUnitNumber', title: intl.formatMessage({ id: 'field.standardSafetyStock' }), width: 200, categories: stockLevelCategories },
        ] as Column[]

        const stockTakingDayCategories = [{ key: 'stockTakingDay', value: intl.formatMessage({ id: 'stockTakingDay' }) }]
        const stockTakingDayColumn = [
            { field: 'warehouseStock', dataTypeName: 'digitsNumber', title: intl.formatMessage({ id: 'field.stockAtWarehouse' }), width: 200, categories: stockTakingDayCategories },
            { field: 'customerStock', dataTypeName: 'digitsNumber', title: intl.formatMessage({ id: 'field.stockAtCustomer' }), width: 200, categories: stockTakingDayCategories },
            { field: 'wipQty', dataTypeName: 'digitsNumber', title: intl.formatMessage({ id: 'field.workInProgress' }), width: 200, categories: stockTakingDayCategories },
        ] as Column[]

        // prepare order information
        const periodColumns: Column[] = []
        if (partsDetail && partsDetail.periodInfoList && partsDetail.periodInfoList.length > 0) {
            const last = partsDetail.periodInfoList.length - 1
            partsDetail.periodInfoList.forEach((m, index) => {
                // get current month
                const currentMonth = intl.formatDate(m.targetStartDate, { year: 'numeric', month: 'short' })
                const nName = (m.periodIndex > 0 ? ('N + ' + m.periodIndex) : (m.periodIndex === 0 ? 'N' : ('N - ' + Math.abs(m.periodIndex))))
                const forecastName = (m.periodIndex === 0 ? intl.formatMessage({ id: 'firmOrder' }) : (m.periodIndex > 0 ? intl.formatMessage({ id: 'forecastWithN' }, { '0': Math.abs(m.periodIndex) }) : ''))

                // period first title
                const periodFirstLevel = { key: 'periodFirstTitle' + index, value: nName + '(' + currentMonth + ')' }
                // Customer Consumption
                const customerConsumption = [periodFirstLevel, { key: 'customerConsumption' + index, value: intl.formatMessage({ id: 'customerConsumption' }) }]
                const balanceOrder = [periodFirstLevel, { key: 'balanceOrder' + index, value: intl.formatMessage({ id: 'BalanceOrder' }) }]
                const importOrder = [periodFirstLevel, { key: 'importOrder' + index, value: intl.formatMessage({ id: 'ImportOrder' }) }]
                const importStock = [periodFirstLevel, { key: 'importStock' + index, value: intl.formatMessage({ id: 'ImportStock' }) }]
                const orderCalculationMonth = [periodFirstLevel, { key: 'orderCalculationMonth' + index, value: intl.formatMessage({ id: 'OrderCalculationMonth' }, { '0': forecastName }) }]

                // customerConsumption
                periodColumns.push({ field: 'latestQty' + index, dataTypeName: 'digitsNumber', title: intl.formatMessage({ id: 'field.latestQty' }), width: 200, categories: customerConsumption, getCellValue: getPeriodValue(index, 'currentUsageQty') })
                if (index === last) return
                periodColumns.push({ field: 'previousQty' + index, dataTypeName: 'digitsNumber', title: intl.formatMessage({ id: 'field.previousQty' }), width: 200, categories: customerConsumption, getCellValue: getPeriodValue(index, 'previousUsageQty') })
                periodColumns.push({ field: 'fluctuation' + index, dataTypeName: 'fluctuation', title: intl.formatMessage({ id: 'fluctuation' }), width: 200, categories: customerConsumption, getCellValue: getPeriodValue(index, 'usageFluctuation') })
                // check if first one, show Customer Consumption, Balance Order, Import Stock
                if (index === 0) {
                    // add Balance Customer Usage
                    periodColumns.push({ field: 'balanceCustomerUsage' + index, dataTypeName: 'digitsNumber', title: intl.formatMessage({ id: 'balanceCustomerUsage' }), width: 200, categories: customerConsumption, getCellValue: getPeriodValue(index, 'currentUsageBalance') })
                    /** add Balance Order */
                    // Order qty
                    periodColumns.push({ field: 'orderQty' + index, dataTypeName: 'digitsNumber', title: intl.formatMessage({ id: 'field.orderQty' }), width: 200, categories: balanceOrder, getCellValue: getPeriodValue(index, 'currentOrderQty') })
                    // Order Balance From Previous Month (delayed shipment)
                    periodColumns.push({ field: 'orderBalanceFromPreviousMonth' + index, dataTypeName: 'digitsNumber', title: intl.formatMessage({ id: 'orderBalanceFromPreviousMonth' }), width: 200, categories: balanceOrder, getCellValue: getPeriodValue(index, 'previousOrderBalance') })
                    // Total Order Balance Qty.(not yet inbound)
                    periodColumns.push({ field: 'totalOrderBalanceQty' + index, dataTypeName: 'digitsNumber', title: intl.formatMessage({ id: 'totalOrderBalanceQty' }), width: 200, categories: balanceOrder, getCellValue: getPeriodValue(index, 'currentOrderBalance') })
                } else if (m.periodIndex < 0) {
                    // check if Next, show Import Order// Order qty
                    // Order Already Placed
                    periodColumns.push({ field: 'OrderAlreadyPlaced' + index, dataTypeName: 'digitsNumber', title: intl.formatMessage({ id: 'OrderAlreadyPlaced' }), width: 200, categories: importOrder, getCellValue: getPeriodValue(index, 'currentOrderQty') })
                    // Order Balance Qty.(yet to inbound)
                    periodColumns.push({ field: 'OrderBalanceQty' + index, dataTypeName: 'digitsNumber', title: intl.formatMessage({ id: 'OrderBalanceQty' }), width: 200, categories: importOrder, getCellValue: getPeriodValue(index, 'currentOrderBalance') })
                } else if (m.periodIndex >= 0) {
                    // check if Target, show Order Calculation (FIRM ORDER)
                    // Suggested Order Qty.
                    periodColumns.push({ field: 'SuggestedOrderQty' + index, dataTypeName: 'digitsNumber', title: intl.formatMessage({ id: 'SuggestedOrderQty' }), width: 200, categories: orderCalculationMonth, getCellValue: getPeriodValue(index, 'suggestedQty') })
                    // Final Order Qty.(adjusted)
                    periodColumns.push({ field: 'FinalOrderQty' + index, dataTypeName: 'finalOrder', title: intl.formatMessage({ id: 'FinalOrderQty' }), width: 200, categories: orderCalculationMonth, getCellValue: () => index })
                    // Reason for Adjustment
                    periodColumns.push({ field: 'ReasonforAdjustment' + index, dataTypeName: 'string', title: intl.formatMessage({ id: 'ReasonforAdjustment' }), width: 200, categories: orderCalculationMonth, getCellValue: getPeriodValue(index, 'adjustmentReason') })
                    // Last Forecast Qty.
                    periodColumns.push({ field: 'LastForecastQty' + index, dataTypeName: 'digitsNumber', title: intl.formatMessage({ id: 'LastForecastQty' }), width: 200, categories: orderCalculationMonth, getCellValue: getPeriodValue(index, 'lastFcQty') })
                    // *Fluctuation(final order vs forecast qty last)
                    periodColumns.push({ field: 'FluctuationFinalOrder' + index, dataTypeName: 'fluctuation', title: intl.formatMessage({ id: 'FluctuationFinalOrder' }), width: 200, categories: orderCalculationMonth, getCellValue: getPeriodValue(index, 'ocFluctuation') })
                }

                // Import Stock   
                // *Stock at End [N-3](Qty)
                periodColumns.push({ field: 'StockatEndQty' + index, dataTypeName: 'digitsNumber', title: intl.formatMessage({ id: 'StockatEndQty' }, { '0': nName }), width: 200, categories: importStock, getCellValue: getPeriodValue(index, 'stockAtEndQty') })
                // *Stock at End [N-3]
                periodColumns.push({ field: 'StockatEnd' + index, dataTypeName: 'stockAtEnd', title: intl.formatMessage({ id: 'StockatEnd' }, { '0': nName }), width: 200, categories: importStock, getCellValue: getPeriodValue(index, 'stockAtEnd') })
                // *Risky Inventory Flag
                periodColumns.push({ field: 'RiskyInventoryFlag' + index, dataTypeName: 'riskyInventoryFlag', title: intl.formatMessage({ id: 'RiskyInventoryFlag' }), width: 200, categories: importStock, getCellValue: getPeriodValue(index, 'riskyInventoryFlag') })
            })

        }
        return Arrays.concat(baseColumns, stockLevelColumns, stockTakingDayColumn, periodColumns)
    }, [getPeriodValue, intl, partsDetail])

    useEffect(() => {
        setOrder(columns.map(column => column.field))
    }, [columns])

    const actionProps1 = useMemo(() => ({ search, orderCalcId }), [search, orderCalcId])
    const actionProps2 = useMemo(() => ({ search, orderCalcId }), [search, orderCalcId])

    const display1 = useCallback(() => mode !== ScreenMode.VIEW, [mode])

    const getRowId = useCallback((row: any) => row.orderCalcPartsId, []);
    return <div style={{ width: '100%', height: 500 }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <Data rows={partsDetailList} columns={columns} getRowId={getRowId} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.SafetyStockUom} />
            <PercentTypeProvider />
            <FluctuationTypeProvider />
            <StockAtEndTypeProvider />
            <RiskyInventoryFlagTypeProvider />
            <DigitsNumberTypeProvider />
            <FinalOrderTypeProvider />
            <StockUnitTypeProvider />
            <ToolbarActionProvider Action={UploadAction} actionProps={actionProps1} display={display1} />
            <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps2} display={display1} />
            <ColumnFreeze />
            <ColumnOrdering order={order} onOrderChange={setOrder} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
            <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        </DataGrid>
    </div>
}

const DigitsNumberTypeProvider = () => {
    return <NumberTypeProvider name="digitsNumber" Formatter={DigitsNumberFormatter} />
}

const DigitsNumberFormatter = ({ value, row, formatter }: NumberFormatterProps) => {
    const style = useStyles()
    const intl = useIntl()
    const digits = row.decimalDigits ?? 0
    const displayValue = value !== null && value !== undefined ? intl.formatNumber(value, { maximumFractionDigits: digits, minimumFractionDigits: digits }) : ''
    return <div className={style.numberCheck} style={{ width: '100%' }}>
        <Typography variant="body2" >{displayValue}</Typography>
    </div>
}

const StockUnitTypeProvider = () => {
    return <NumberTypeProvider name="stockUnitNumber" Formatter={StockUnitTypeFormatter} />
}
const StockUnitTypeFormatter = ({ value, row }: NumberFormatterProps) => {
    const style = useStyles()
    const safetyStockUom = row.safetyStockUom
    const displayValue = SafetyStockUom.BY_SIGN === safetyStockUom ? ((value ?? 0) * 100) + '%' : '' + (value ?? 0)
    return <div className={style.numberCheck} style={{ width: '100%' }}>
        <Typography variant="body2" >{displayValue}</Typography>
    </div>
}

const FluctuationTypeProvider = () => {
    return <NumberTypeProvider name="fluctuation" options={{ style: 'percent', maximumFractionDigits: 0, minimumFractionDigits: 0 }} Formatter={FluctuationFormatter} />
}

const FluctuationFormatter = ({ value, row, formatter }: NumberFormatterProps) => {
    const style = useStyles()
    const fluctuationRate = row.outboundFluctuation
    const displayValue = value === null ? 'N/A' : formatter.format(value)
    const bgcolor = (value === null || fluctuationRate === null) ? '' : Math.abs(value) > fluctuationRate ? '#FF9900' : ''
    return <div className={style.fulcuationCheck} style={{ background: bgcolor, width: '100%' }}>
        <Typography variant="body2" >{displayValue}</Typography>
    </div>
}

const StockAtEndTypeProvider = () => {
    return <StringTypeProvider name="stockAtEnd" align="end" />
}

const RiskyInventoryFlagTypeProvider = () => {
    return <StringTypeProvider name="riskyInventoryFlag" Formatter={RiskyInventoryFlagFormatter} />
}
const RiskyInventoryFlagFormatter = ({ value }: StringFormatterProps) => {
    const style = useStyles()
    const bgcolor = value === '+' ? '#FFCC00' : (value === 'X' ? '#FF9900' : '')
    const fontSize = value === '+' ? '1.2rem' : ''
    return <div className={style.riskyInventoryFlagCheck} style={{ background: bgcolor, width: '100%' }}>
        <Typography variant="body2" style={{ fontSize: fontSize }}>{value}</Typography>
    </div>
}

const FinalOrderTypeProvider = () => {
    return <NumberTypeProvider name="finalOrder" Formatter={FinalOrderTypeFormatter} />
}
const FinalOrderTypeFormatter = ({ value, row }: NumberFormatterProps) => {
    const style = useStyles()
    const intl = useIntl()
    const digits = row.decimalDigits ?? 0
    const suggestedQty = row.periodInfoList[value ?? 0].suggestedQty
    const finalQty = row.periodInfoList[value ?? 0].finalQty
    const finalQtyValue = intl.formatNumber(finalQty ?? 0, { maximumFractionDigits: digits, minimumFractionDigits: digits })
    const bgcolor = suggestedQty !== null && suggestedQty !== undefined && finalQty !== null && finalQty !== undefined && finalQty !== suggestedQty ? '#008000' : ''
    return <div className={style.numberCheck} style={{ background: bgcolor, width: '100%' }}>
        <Typography variant="body2" >{finalQtyValue}</Typography>
    </div>
}

const UploadAction = ({ search, orderCalcId }: { search: (orderCalcId?: string) => void, orderCalcId?: string }) => {
    const uploadApi = useUploadOrderCalculation();
    const upload = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            files && uploadApi({ file: files[0] }, { serialized: true }).then(() => search(orderCalcId))
        })//TODO may be upload file does not belongs to current order calculation
    }, [orderCalcId, search, uploadApi])
    return <UploadGroupedToolbarAction access="STCK.OCCLS014.UPLOAD">
        {(popupUpload, onClose) => <>
            <GroupedCallbackItem label={<FormattedMessage id="upload" />} callback={() => upload(popupUpload)} onClose={onClose} />
        </>}
    </UploadGroupedToolbarAction>
}

const DownloadAction = ({ orderCalcId }: { orderCalcId?: string }) => {
    // const downloadOrderCaculation = useDownloadOrderCaculation()
    const download = useCallback(() => {
        // downloadOrderCaculation({ orderCalcNos: [orderCalcId] })
    }, [])
    return <DownloadGroupedToolbarAction access="STCK.OCCLS014.DOWNLOAD">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="download" />} callback={download} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}

const useStyles = makeStyles(theme => ({
    fulcuationCheck: {
        width: '100%',
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        height: '70%',
        paddingRight: theme.spacing(1),
        borderRadius: 5
    },
    riskyInventoryFlagCheck: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70%',
        paddingRight: theme.spacing(1),
        borderRadius: 5
    },
    numberCheck: {
        width: '100%',
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        height: '70%',
        paddingRight: theme.spacing(1),
        borderRadius: 5
    },
    default: {},
}));



