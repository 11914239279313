import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useGetCustomsInvoice } from "../../../services/accounting/apis/accountingSellerApi"
import { CustomsInvoice } from "../../../services/accounting/models/CustomsInvoice"
import { AIS041PcUi } from "./AIS041PcUi"

export const AIS041 = () => {
    const { invoiceId } = useParams() as any
    const [invoice, setInvoice] = useState<CustomsInvoice>()
    const getCustomsInvoice = useGetCustomsInvoice()

    useEffect(() => {
        if (invoiceId !== undefined && invoiceId !== null) {
            getCustomsInvoice(({ invoiceId: invoiceId }), { silent: true, serialized: true }).then(result => {
                setInvoice(result ?? {} as any)
            })
        };
    }, [getCustomsInvoice, invoiceId])

    return <AIS041PcUi invoice={invoice} />
}