import { EntriesItem, EntriesItemProps } from "@rithe/form"
import { Arrays, Comparators, Objects } from "@rithe/utils"
import React, { useMemo } from "react"
import { useIntl } from "react-intl"
import { useApplicationSelector } from "../../layouts/Application/applicationSlice"
import { CodeCategory } from "../../services/master/enums/CodeCategory"

type CodesItemProp = Omit<EntriesItemProps, 'entries'> & {
    code: CodeCategory,
}

export const CodesItem = React.memo((props: CodesItemProp) => {
    const { code } = props

    const intl = useIntl()
    const codeCategories = useApplicationSelector(state => state.cache.codeCategories ?? [])
    const entries = useMemo(() => {
        return Arrays.distinct(
            codeCategories.filter(cc => cc.codeCategory === code).map(cc => cc.codeValue)
        ).sort(Comparators.natualOrder())
            .map(option => [option, intl.formatMessage({ id: `${code}_${option}` })] as [number, string])
    }, [code, codeCategories, intl])

    return <EntriesItem {...Objects.delete(props, 'code')} entries={entries} />
})
CodesItem.displayName = 'CodesItem'