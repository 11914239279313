import { Project } from "../../utils/Project"


export type PageViewEntity = Partial<Optional>

export type PageViewEntityJson = Partial<JsonOptional>

export const pageViewEntityToJson: Project<PageViewEntity, PageViewEntityJson> = obj => ({
    ...obj
})

export const pageViewEntityFromJson: Project<PageViewEntityJson, PageViewEntity> = json => ({
    ...json
})

interface Optional extends JsonOptional {
}

interface JsonOptional {
    currentPage: number,
    pageSize: number,
}