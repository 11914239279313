import { useCallback, useEffect, useState } from "react"
import { useGetSuppliers } from "../../../services/smt/api/ComboboxApi"
import { useGetCustActualUsagePage } from "../../../services/smt/api/CustomerUsageApi"
import { ActualUsageEntityList } from "../../../services/smt/models/ActualUsageEntityList"
import { ActualUsageFactor } from "../../../services/smt/models/ActualUsageFactor"
import { Pair } from "../../../services/utils/Pair"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { CSUHS150PcUi } from "./CSUHS150PcUi"


const defaultFilters = { page: { currentPage: 0, pageSize: 100000 } }
export const CSUHS150 = () => {

    const [filters, setFilters] = useState<ActualUsageFactor>({ ...defaultFilters, usageDateStart: new Date(), usageDateEnd: new Date() })
    const [data, setData] = useState<ActualUsageEntityList[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [supplierList, setSupplierList] = useState<Pair[]>([]);
    const getActualUsageList = useGetCustActualUsagePage()

    const search = useCallback((filters: ActualUsageFactor) => {
        getActualUsageList(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result.page?.totalCount || 0)
            setData(result.data ?? [])
        })
    }, [getActualUsageList, setData])

    useSearchOnLoad(search, filters)

    const getSupplierList = useGetSuppliers()
    useEffect(() => {
        getSupplierList(undefined, { silent: true, serialized: true }).then((result) => {
            setSupplierList(result ?? [])
        })
    }, [getSupplierList])

    return <CSUHS150PcUi
        filters={filters}
        search={search}
        data={data}
        totalCount={totalCount}
        setFilters={setFilters}
        supplierList={supplierList}
    />
}