import { useCallback, useState } from "react"
import { useGetIntegrationBatchPage } from "../../../services/integration/apis/integrationBatchApis"
import { IntBatchTransfer } from "../../../services/integration/models/IntBatchTransfer"
import { IntBatchView } from "../../../services/integration/models/IntBatchView"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { INT030PcUi } from "./INT030PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }

export const INT030 = () => {

    const [filters, setFilters] = useState<IntBatchView>(defaultFilters)
    const [data, setData] = useState<IntBatchTransfer[]>([])

    const getIntegrationBatchPage = useGetIntegrationBatchPage()
    const search = useCallback((filters: IntBatchView) => {
        getIntegrationBatchPage({ ...filters }, { silent: true, serialized: true }).then(result => {
            setData(result?.data || [])
        })
    }, [getIntegrationBatchPage])

    useSearchOnLoad(search, filters)

    return <INT030PcUi
        filters={filters}
        setFilters={setFilters}
        search={search}
        data={data}
    />
}