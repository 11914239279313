import { useCallback, useEffect, useState } from "react"
import { useComMasterGetAllCbds } from "../../../services/common/apis/commonMasterApi"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { useGetCustomerByLoginUser } from "../../../services/smt/api/ComboboxApi"
import { DailyRundownList } from "../../../services/smt/models/DailyRundownList"
import { DailyRundownListFactor } from "../../../services/smt/models/DailyRundownListFactor"
import { useGetDailyRundownList } from "../../../services/smt/stockManagement/api/weeklyRundownApi"
import { CbdsType } from "../../../services/systemmaster/enums/CbdsType"
import { Pair } from "../../../services/utils/Pair"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { SMGTS210PcUi } from "./SMGTS210PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }

export const SMGTS210 = () => {

    const [filters, setFilters] = useState<DailyRundownListFactor>(defaultFilters)
    const [data, setData] = useState<DailyRundownList[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [customerList, setCustomerList] = useState<Pair[]>([])
    const [supplierList, setSupplierList] = useState<TnvCbds[]>([])

    const getDailyRundownList = useGetDailyRundownList()
    const search = useCallback((filters: DailyRundownListFactor) => {
        getDailyRundownList(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
        })
    }, [getDailyRundownList, setData])


    useSearchOnLoad(search, filters)

    const getCustomerList = useGetCustomerByLoginUser()
    const getSupplierList = useComMasterGetAllCbds()
    useEffect(() => {
        getCustomerList(undefined, { silent: true, serialized: true }).then((result) => {
            setCustomerList(result ?? [])
        })
        getSupplierList({ types: [CbdsType.BU, CbdsType.SUPP] }, { silent: true, serialized: true }).then((result) => {
            setSupplierList(result ?? [])
        })
    }, [getCustomerList, getSupplierList])

    return <SMGTS210PcUi
        filters={filters}
        search={search}
        data={data}
        totalCount={totalCount}
        setFilters={setFilters}
        customerList={customerList}
        supplierList={supplierList}
    />
}