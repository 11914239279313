import { Project } from "../../../utils/Project"
import { dateFromJson, datetimeFromJson } from "../../utils/deserializer"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"
import { CoStatus } from "../enums/CoStatus"

export type TotCo = Required & Partial<Optional> & TableEntity
export type TotCoJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const totCoFromJson: Project<TotCoJson, TotCo> = json => ({
    ...tableEntityFromJson(json),
    coDate: dateFromJson(json.coDate),
    orderFirstDate: dateFromJson(json.orderFirstDate),
    orderLastDate: dateFromJson(json.orderLastDate),
    targetFirstDate: dateFromJson(json.targetFirstDate),
    targetLastDate: dateFromJson(json.targetLastDate),
    combinedDate: dateFromJson(json.combinedDate),
    completedDate: dateFromJson(json.completedDate),
    createdDate: datetimeFromJson(json.createdDate),
    updatedDate: datetimeFromJson(json.updatedDate),
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'coDate' | 'orderFirstDate' | 'orderLastDate' | 'targetFirstDate' | 'targetLastDate' | 'status' | 'combinedDate' | 'completedDate' | 'createdDate' | 'updatedDate'> {
    coDate: Date,
    orderFirstDate: Date,
    orderLastDate: Date,
    targetFirstDate: Date,
    targetLastDate: Date,
    status: CoStatus,
    combinedDate: Date,
    completedDate: Date,
    createdDate: Date,
    updatedDate: Date,
}

interface JsonRequired {
    coId: number,
    customerId: number,
    customerContractId: number,
    customerOrderNo: string,
}

interface JsonOptional {
    buId: number,
    customerRefNo: string,
    contractRouteId: number,
    businessType: number,
    orderFrequency: number,
    orderType: number,
    orderVersion: number,
    receiveDcId: number,
    spotOrderReason: string,
    coDate: string,
    forecastNum: number,
    orderFirstDate: string,
    orderLastDate: string,
    targetFirstDate: string,
    targetLastDate: string,
    shippingMode: number,
    remark: string,
    status: number,
    combinedDate: string,
    completedDate: string,
    createdBy: string,
    createdDate: number,
    updatedBy: string,
    updatedDate: number,
    version: number,
}