import { Project } from "../../../utils/Project"

export type CalendarGetDateFactor = Required & Partial<Optional>
type CalendarGetDateFactorJson = JsonRequired & Partial<JsonOptional>
export const calendarGetDateFactorToJson: Project<CalendarGetDateFactor, CalendarGetDateFactorJson> = obj => ({
    ...obj,

})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
}

interface JsonRequired {
}

interface JsonOptional {
    calendarId: number,
    year: string
}
