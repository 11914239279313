import { createContext, ReactNode, useContext, useMemo } from "react";

interface Context {
    flex?: boolean,
    scrollTarget?: HTMLElement,
}

const ViewContext = createContext<Context>({})


interface ViewContextProviderProps {
    flex?: boolean,
    scrollTarget?: HTMLElement,
    children?: ReactNode,
}

export function ViewContextProvider(props: ViewContextProviderProps) {
    const { flex, scrollTarget, children } = props
    const value = useMemo(() => ({ flex, scrollTarget }), [flex, scrollTarget])
    return <ViewContext.Provider value={value}>
        {children}
    </ViewContext.Provider>
}

export const useViewContext = () => useContext(ViewContext)