import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { OrderDetailResult, OrderDetailResultDeserializer } from "../models/OrderDetailResult"
import { OrderForecastDetailResult, OrderForecastDetailResultDeserializer } from "../models/OrderForecastDetailResult"
import { OrderListResult, orderListResultFromJson } from "../models/OrderListResult"
import { PurchaseOrderListFactor, purchaseOrderListFactorToJson } from "../models/PurchaseOrderListFactor"
import { Projects } from "../../../utils/Projects"

export const useGetPoListByPage = () => {
    const url = '/lcbm-order-api/order/po/api/getPoListByPage'
    const payloadProject = useCallback(purchaseOrderListFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(orderListResultFromJson), [])
    return usePost<PurchaseOrderListFactor, PageResultEntity<OrderListResult>>(url, payloadProject, resultProject)
}

export const useGetPoDetail = () => {
    const url = '/lcbm-order-api/order/po/api/getPoDetail'
    const payloadProject = useCallback((obj: { orderId: number }) => obj.orderId, [])
    const resultProject = useCallback(OrderDetailResultDeserializer, [])
    return usePost<{ orderId: number }, OrderDetailResult>(url, payloadProject, resultProject)
}

export const useGetPoMonitoringDetail = () => {
    const url = '/lcbm-spot-order-api/order/po/api/getPoMonitoringDetail'
    const payloadProject = useCallback((obj: { orderId: number }) => obj.orderId, [])
    const resultProject = useCallback(OrderDetailResultDeserializer, [])
    return usePost<{ orderId: number }, OrderDetailResult>(url, payloadProject, resultProject)
}

// =========== OPS012
export const useGetPoForecast = () => {
    const url = '/lcbm-order-api/order/po/api/getPoForecast'
    const payloadProject = useCallback((obj: { orderId: number }) => obj.orderId, [])
    const resultProject = useCallback(OrderForecastDetailResultDeserializer, [])
    return usePost<{ orderId: number }, OrderForecastDetailResult>(url, payloadProject, resultProject)
}

export const useForceComplete = () => {
    const url = '/lcbm-order-api/order/po/api/forceComplete'
    const payloadProject = useCallback((obj: { orderIdList: number[] }) => obj.orderIdList, [])
    return usePost<{ orderIdList: number[] }, undefined>(url, payloadProject, Projects.IGNORE)
}

export const useCancelOrder = () => {
    const url = '/lcbm-order-api/order/po/api/cancelOrder'
    const payloadProject = useCallback((obj: { orderIdList: number[] }) => obj.orderIdList, [])
    return usePost<{ orderIdList: number[] }, undefined>(url, payloadProject, Projects.IGNORE)
}
