import { useCallback, useEffect, useMemo, useState } from "react"
import { useGetCargoTrackingPageList } from "../../../services/cargo/api/cargoTackingApi"
import { CargoTackingFactor, cargoTackingFactorFactorFromJson, cargoTackingFactorFactorToJson } from "../../../services/cargo/models/CargoTackingFactor"
import { CargoTackingResults } from "../../../services/cargo/models/CargoTackingResults"
import { useComMasterGetActivePort } from "../../../services/common/apis/commonMasterApi"
import { useListAllCarriers } from "../../../services/delivery/apis/deliveryShippingDetailApi"
import { CarrierEntity } from "../../../services/delivery/models/CarrierEntity"
import { TnmPort } from "../../../services/master/models/TnmPort"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { CIS010PcUi } from "./CIS010PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }
const searchCachekey = sessionKeys.Filters_CIS010
export const CIS010 = () => {

    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? cargoTackingFactorFactorFromJson(JSON.parse(filters)) : null
    }, [])

    const [filters, setFilters] = useState<CargoTackingFactor>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<CargoTackingResults[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [portList, setPortList] = useState<TnmPort[]>([])
    const [carrierList, setCarrierList] = useState<CarrierEntity[]>([])
    const getCargoTrackingList = useGetCargoTrackingPageList()
    const getPortList = useComMasterGetActivePort()
    useEffect(() => {
        getPortList(undefined, { silent: true }).then(result => {
            setPortList(result || [])
        })
    }, [getPortList])
    const search = useCallback((filters: CargoTackingFactor) => {
        getCargoTrackingList(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(cargoTackingFactorFactorToJson(filters)))
        })
    }, [getCargoTrackingList])

    const getCarrierList = useListAllCarriers()
    useEffect(() => {
        getCarrierList(undefined, { silent: true }).then(result => {
            setCarrierList(result || [])
        })
    }, [getCarrierList])

    useSearchOnLoad(search, filters)

    return <CIS010PcUi
        filters={filters}
        setFilters={setFilters}
        search={search}
        data={data}
        totalCount={totalCount}
        portList={portList}
        carrierList={carrierList}
    />
}