import { Project } from "../../../utils/Project"
import { ShippingMode } from "../../accounting/enums/ShippingMode"
import { BusinessType } from "../../master/enums/BusinessType"
import { dateFromJson } from "../../utils/deserializer"
import { dateToJson } from "../../utils/serializer"
import { CoStatus } from "../enums/CoStatus"
import { OrderFrequency } from "../enums/OrderFrequency"
import { OrderType } from "../enums/OrderType"
import { RequestType } from "../enums/RequestType"
import { RoStatus } from "../enums/RoStatus"

/** main Bean */
export type ChangeOrderDetailResult = ResultRequired & Partial<ResultOptional>
type ChangeOrderDetailResulttson = ResultJsonRequired & Partial<ResultJsonOptional>

export const changeOrderDetailResultFromJson: Project<ChangeOrderDetailResulttson, ChangeOrderDetailResult> = json => ({
    ...json,
    orderFirstDate: dateFromJson(json.orderFirstDate),
    orderLastDate: dateFromJson(json.orderLastDate),
    targetFirstDate: dateFromJson(json.targetFirstDate),
    targetLastDate: dateFromJson(json.targetLastDate),
    coDate: dateFromJson(json.coDate),
    roDate: dateFromJson(json.roDate),
    partsDetailList: json.partsDetailList.map(partsDetailDeserializer),
})

export const changeOrderDetailResultToJson: Project<ChangeOrderDetailResult, ChangeOrderDetailResulttson> = data => ({
    ...data,
    orderFirstDate: dateToJson(data.orderFirstDate),
    orderLastDate: dateToJson(data.orderLastDate),
    targetFirstDate: dateToJson(data.targetFirstDate),
    targetLastDate: dateToJson(data.targetLastDate),
    coDate: dateToJson(data.coDate),
    roDate: dateToJson(data.roDate),
    partsDetailList: data.partsDetailList.map(partsDetailSerializer),
})

interface ResultRequired extends Omit<ResultJsonRequired, 'orderType' | 'orderFrequency' | 'businessType' | 'orderFirstDate' | 'orderLastDate' | 'targetFirstDate' | 'targetLastDate' | 'shippingMode' | 'coDate' | 'coStatus' | 'partsDetailList'> {
    orderType: OrderType,
    orderFrequency: OrderFrequency,
    businessType: BusinessType,
    orderFirstDate: Date,
    orderLastDate: Date,
    targetFirstDate: Date,
    targetLastDate: Date,
    shippingMode: ShippingMode,
    coDate: Date,
    coStatus: CoStatus,
    partsDetailList: PartsDetail[],
}

interface ResultOptional extends Omit<ResultJsonOptional, 'reviseType' | 'roDate' | 'roStatus'> {
    reviseType: RequestType,
    roDate: Date,
    roStatus: RoStatus,
}

interface ResultJsonRequired {
    customerId: number,
    customerCode: string,
    sellerId: number,
    sellerCode: string,
    contractId: number,
    contractNo: string,
    contractRouteId: number,
    contractRouteCode: string,
    orderType: number,
    orderFrequency: number,
    businessType: number,
    orderFirstDate: string,
    orderLastDate: string,
    targetFirstDate: string,
    targetLastDate: string,
    coId: number,
    coNo: string,
    shippingMode: number,
    coDate: string,
    coStatus: number,
    partsDetailList: PartsDetailJson[],
    totalQty: number,
    totalAmount: number,
}

interface ResultJsonOptional {
    orderVersion: number,
    spotOrderReason: string,
    roId: number,
    reviseNo: string,
    reviseType: number,
    roBy: string,
    roDate: string,
    roStatus: number,
    customerRefNo: string,
    remark: string,
    version: number,
    paymentTermsCode: string,
    paymentTermsDescription: string,
}


/** Parts Detail information */
export interface PartsDetail extends Omit<PartsDetailJson, 'fcList' | 'revisedPlanList' | 'inbPlanList'> {
    fcList?: ForecastDetail[],
    inbPlanList: InboundPalnDetail[],
    revisedPlanList: InboundPalnDetail[],
}

interface PartsDetailJson {
    partsId: number,
    partsNo: string,
    customerPartsId: number,
    customerPartsNo: string,
    supplierPartsNo: string,
    supplierId: number,
    supplierCode: string,
    exportCountry: string,
    oldPartsNo?: string,
    colorCode?: string,
    customerPartsName: string,
    pairedUnitPartsId?: number,
    pairedOrderFlag?: number,
    pairedPartsNo?: string,
    uomCode: string,
    uomDigits: number,
    fluctuationRate?: number,
    fcFluctuationRate?: number,
    spq: number,
    orderLot: number,
    poBalance?: number,
    firmQty?: number,
    revisedQty?: number,
    lastOrderForecast1?: number,
    orderDetailId?: number,
    coDetailOrigin?: string,
    coDetailStatus?: number,
    fluctReasonFirm?: string,
    inventoryCtrlQty?: number,
    buyingPrice?: number,
    buyingCurrency?: string,
    buildoutFlag?: number,
    orderlotFlag?: number,
    lastOrderMonth?: string,
    roSoId?: number,
    roDetailId?: string,
    fcList?: ForecastDetailJson[],
    inbPlanList?: InboundPalnDetailJson[],
    revisedPlanList?: InboundPalnDetailJson[],
}

const partsDetailDeserializer: Project<PartsDetailJson, PartsDetail> = json => ({
    ...json,
    fcList: json.fcList ? json.fcList.map(forecastDetailDeserializer) : [],
    inbPlanList: json.inbPlanList ? json.inbPlanList.map(inboundPalnDetailDeserializer) : [],
    revisedPlanList: json.revisedPlanList ? json.revisedPlanList.map(inboundPalnDetailDeserializer) : [],
})

const partsDetailSerializer: Project<PartsDetail, PartsDetailJson> = data => ({
    ...data,
    fcList: data.fcList ? data.fcList.map(forecastDetailSerializer) : [],
    inbPlanList: data.inbPlanList ? data.inbPlanList.map(inboundPalnDetailSerializer) : [],
    revisedPlanList: data.revisedPlanList ? data.revisedPlanList.map(inboundPalnDetailSerializer) : [],
})


/** Inbound plan Information */
interface InboundPalnDetail extends Omit<InboundPalnDetailJson, 'crdDate'> {
    crdDate: Date,
}

interface InboundPalnDetailJson {
    crdDate: string,
    crdQty: number | null
}

const inboundPalnDetailDeserializer: Project<InboundPalnDetailJson, InboundPalnDetail> = json => ({
    ...json,
    crdDate: dateFromJson(json.crdDate)
})

const inboundPalnDetailSerializer: Project<InboundPalnDetail, InboundPalnDetailJson> = data => ({
    ...data,
    crdDate: dateToJson(data.crdDate)
})


/** Inbound plan Information */
interface ForecastDetail extends Omit<ForecastDetailJson, 'fcFirstDate' | 'fcLastDate' | 'targetFirstDate' | 'targetLastDate'> {
    fcFirstDate: Date,
    fcLastDate: Date,
    targetFirstDate: Date,
    targetLastDate: Date,
}

interface ForecastDetailJson {
    fcFirstDate: string,
    fcLastDate: string,
    targetFirstDate: string,
    targetLastDate: string,
    fcQty: number,
    oldFcQty: number,
    lastFcQty: number,
    fluctuationReason: string
}

const forecastDetailDeserializer: Project<ForecastDetailJson, ForecastDetail> = json => ({
    ...json,
    fcFirstDate: dateFromJson(json.fcFirstDate),
    fcLastDate: dateFromJson(json.fcLastDate),
    targetFirstDate: dateFromJson(json.targetFirstDate),
    targetLastDate: dateFromJson(json.targetLastDate),
})
const forecastDetailSerializer: Project<ForecastDetail, ForecastDetailJson> = data => ({
    ...data,
    fcFirstDate: dateToJson(data.fcFirstDate),
    fcLastDate: dateToJson(data.fcLastDate),
    targetFirstDate: dateToJson(data.targetFirstDate),
    targetLastDate: dateToJson(data.targetLastDate),
})

