import moment from "moment"
import {useCallback} from "react"
import {useDownload, usePost} from "../../../utils/fetch/useFetch"
import {PageResultEntity, pageResultEntityFromJson} from "../../utils/PageResultEntity"
import {UserAccessHistoryDownloadListFactorToJson, UserAccessHistoryListFactor} from "../models/UserAccessHistoryFactor"
import {UserAccessHistoryListResult, userAccessHistoryListResult} from "../models/UserAccessHistoryListResult"

export const useGetUserAccessHistoryByPage = () => {
    const url = '/common-system-api/accessLog/list'
    const payloadProject = useCallback(UserAccessHistoryDownloadListFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(userAccessHistoryListResult), [])
    return usePost<UserAccessHistoryListFactor, PageResultEntity<UserAccessHistoryListResult>>(url, payloadProject, resultProject)
}

export const useGetSelfUserAccessHistoryByPage = () => {
    const url = '/common-system-api/accessLog/selfList'
    const payloadProject = useCallback(UserAccessHistoryDownloadListFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(userAccessHistoryListResult), [])
    return usePost<UserAccessHistoryListFactor, PageResultEntity<UserAccessHistoryListResult>>(url, payloadProject, resultProject)
}

export const useDownloadUserAccessLog = () => {
    const url = '/common-system-api/accessLog/downloadAccessLog'
    const payloadProject = useCallback((obj: {ids: string[], apiFilter: UserAccessHistoryListFactor}) => {
        return {
            ids: obj.ids,
            apiFilter: {
            ...obj.apiFilter,
            accessDateStart: obj.apiFilter.accessDateStart ? moment(obj.apiFilter.accessDateStart).format('YYYY-MM-DD') : null,
            accessDateEnd: obj.apiFilter.accessDateEnd ? moment(obj.apiFilter.accessDateEnd).format('YYYY-MM-DD') : null,
        }};
    }, []);
    return useDownload<{ ids: string[], apiFilter: UserAccessHistoryListFactor}>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadSelfUserAccessLog = () => {
    const url = '/common-system-api/accessLog/downloadSelfAccessLog'
    const payloadProject = useCallback((obj: {ids: string[], apiFilter: UserAccessHistoryListFactor}) => {
        return {
            ids: obj.ids,
            apiFilter: {
            ...obj.apiFilter,
            accessDateStart: obj.apiFilter.accessDateStart ? moment(obj.apiFilter.accessDateStart).format('YYYY-MM-DD') : null,
            accessDateEnd: obj.apiFilter.accessDateEnd ? moment(obj.apiFilter.accessDateEnd).format('YYYY-MM-DD') : null,
        }};
    }, []);
    return useDownload<{ ids: string[], apiFilter: UserAccessHistoryListFactor}>(url, payloadProject, { fileTimestamp: true })
}