import { useCallback } from "react"
import { Projects } from "../../../utils/Projects"
import { usePost } from "../../../utils/fetch/useFetch"
import { SchedulesFactor, schedulesFactorToJson } from "../models/SchedulesFactor"
import { SchedulesResult, schedulesResultFromJson } from "../models/SchedulesResult"


export const useGetToDoList = () => {
    const url = '/common-todo-list-api/schedule/api/queryMyTodoList'
    const payloadProject = useCallback((obj: { searchDate: string }) => obj, [])
    const resultProject = useCallback(Projects.array(schedulesResultFromJson), [])
    return usePost<{ searchDate: string }, SchedulesResult[]>(url, payloadProject, resultProject)
}

export const useGetSave = () => {
    const url = '/common-todo-list-api/schedule/api/doSave'
    const payloadProject = useCallback(schedulesFactorToJson, [])
    const resultProject = Projects.IGNORE
    return usePost<SchedulesFactor, undefined>(url, payloadProject, resultProject)
}
