import { Typography } from "@material-ui/core"
import { Remove } from "@material-ui/icons"
import { Break, EntryItem, Form, Message, Placeholder, StringItem } from "@rithe/form"
import { GridItem } from "@rithe/ui"
import React, { memo, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useFunctionStore } from "../../../Root"
import { SubmitCallbackViewAction } from "../../../components/Action/SubmitCallbackViewAction"
import { TempSaveCallbackViewAction } from "../../../components/Action/TempSaveCallbackViewAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { CodeItem } from "../../../components/Form/CodeItem"
import { SectionTitle } from "../../../components/SectionTitle/SectionTitle"
import { View } from "../../../components/View/View"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { useSaveAndIssueCompanyInfo, useUpdatedOrInsertCompanyDetail } from "../../../services/master/apis/companyApi"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { TnmCompanyTemp } from "../../../services/master/models/TnmCompanyTemp"
import { TnmRegion } from "../../../services/master/models/TnmRegion"
import { useFieldChecker, useFormValidater } from "../../../utils/ValidatorUtils"
import { applicationActions } from "../../Application/applicationSlice"

interface MLS011PcUiProps {
    companyInfo: TnmCompanyTemp,
    setCompanyInfo: React.Dispatch<React.SetStateAction<TnmCompanyTemp>>,

    search: () => void,
    zones: { first: string, second: string }[],
    regions: TnmRegion[],
    mode: ScreenMode,
    companyCode: string
}

export const MLS011PcUi = (props: MLS011PcUiProps) => {
    const { companyInfo, setCompanyInfo, search, zones, regions, mode, companyCode } = props

    const intl = useIntl()
    const [messages, setMessages] = useState<Message[]>([])
    const actions = mode === ScreenMode.VIEW ? [] : [
        <SaveAction companyInfo={companyInfo} search={search} mode={mode} companyCode={companyCode} setMessages={setMessages} />,
        <SubmitAction companyInfo={companyInfo} setMessages={setMessages} />
    ]

    return <View actions={actions}>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={1}
                title={intl.formatMessage({ id: 'basicInfo' })}
                subtitle={intl.formatMessage({ id: 'basicInfoSub' })}
            />
            <SectionCardContent>
                <CompanyDetailView companyInfo={companyInfo} setCompanyInfo={setCompanyInfo} zones={zones} regions={regions} mode={mode} messages={messages} setMessages={setMessages} />
            </SectionCardContent>
        </SectionCard>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={2}
                title={intl.formatMessage({ id: 'contactInfo' })}
                subtitle={intl.formatMessage({ id: 'contactInfoSub' })}
            />
            <SectionCardContent>
                <ContactDetailView companyInfo={companyInfo} setCompanyInfo={setCompanyInfo} mode={mode} messages={messages} setMessages={setMessages} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

interface CompanyDetailViewProps {
    companyInfo: TnmCompanyTemp,
    setCompanyInfo: React.Dispatch<React.SetStateAction<TnmCompanyTemp>>,
    zones: { first: string, second: string }[],
    regions: TnmRegion[],
    mode: ScreenMode,
    messages: Message[],
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>
}

const CompanyDetailView = (props: CompanyDetailViewProps) => {
    const { companyInfo, setCompanyInfo, zones, regions, mode, messages, setMessages } = props
    const intl = useIntl()
    const zonesMap = useMemo(() => zones.map(m => [m.first, m.second] as [string, string]), [zones])
    const regionsMap = useMemo(() => regions.map(m => [m.regionCode, m.regionCode] as [string, string]), [regions])

    const fields = useMemo(() => getFormCheckFields(true, companyInfo), [companyInfo])
    const filedCheck = useFieldChecker(fields, setMessages)
    const getCompanySub = useCallback((data: any) => {
        const companyCode = data.companyCode
        const indx = companyCode ? companyCode.indexOf('-') : -1
        return indx > -1 ? companyCode?.substring(indx + 1) : companyCode
    }, [])

    const setCompanySub = useCallback((data: any, value?: string | undefined) => {
        return { ...data, companyCode: (data.regionCode ?? '') + '-' + (value ?? '') }
    }, [])

    const changeRegion = useCallback((data: any, value?: any) => {
        const companyCode = data.companyCode
        const indx = companyCode ? companyCode.indexOf('-') : -1
        const companySub = indx > -1 ? companyCode?.substring(indx + 1) : companyCode
        return { ...data, regionCode: value, companyCode: (value ?? '') + '-' + (companySub ?? '') }
    }, [])

    const viewOnlyFlag = !companyInfo.companyId ? false : (mode !== ScreenMode.CREATE)
    const readOnly = mode === ScreenMode.VIEW

    const companyCodePrefix = useMemo(() => <>
        <div style={{ width: '45px' }}>
            <Typography variant="body2">{companyInfo.regionCode}</Typography>
        </div>
        <Remove fontSize="small" />
    </>, [companyInfo.regionCode])

    return <Form data={companyInfo} setData={setCompanyInfo} labelDisplay="block" helperDisplay="tooltip" messages={messages} setMessages={setMessages} ensure={filedCheck}>
        <StringItem field="companyCode" required readonly={viewOnlyFlag} label={intl.formatMessage({ id: 'field.companyCode' })}
            getValue={getCompanySub} mapValue={setCompanySub} prefix={companyCodePrefix} />
        <EntryItem field="regionCode" required readonly={viewOnlyFlag} label={intl.formatMessage({ id: 'field.regionCode' })}
            entries={regionsMap} mapValue={changeRegion} />
        <StringItem field="companyName" required readonly={readOnly} label={intl.formatMessage({ id: 'field.companyName' })} />
        <StringItem field="companyShortCode" required readonly={readOnly} label={intl.formatMessage({ id: 'field.companyShortCode' })} />
        <StringItem field="address1" required readonly={readOnly} label={intl.formatMessage({ id: 'field.address1' })} />
        <StringItem field="address2" readonly={readOnly} label={intl.formatMessage({ id: 'field.address2' })} />
        <StringItem field="address3" readonly={readOnly} label={intl.formatMessage({ id: 'field.address3' })} />
        <StringItem field="address4" readonly={readOnly} label={intl.formatMessage({ id: 'field.address4' })} />
        <StringItem field="postalCode" required readonly={readOnly} label={intl.formatMessage({ id: 'field.postalCode' })} />
        <EntryItem field="timeZone" required readonly={readOnly} label={intl.formatMessage({ id: 'field.timeZone' })} entries={zonesMap} />
        <CodeItem field="activeFlag" required readonly={readOnly} label={intl.formatMessage({ id: 'field.activeFlag' })} code={CodeCategory.ActiveFlag} />
        <CodeItem field="portcastFlag" required readonly={readOnly} label={intl.formatMessage({ id: 'field.portcastFlag' })} code={CodeCategory.YesNoFlag} />
    </Form>
}

interface ContactDetailViewProps {
    companyInfo: TnmCompanyTemp,
    setCompanyInfo: React.Dispatch<React.SetStateAction<TnmCompanyTemp>>,
    mode: ScreenMode,
    messages: Message[],
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>
}
const ContactDetailView = (props: ContactDetailViewProps) => {
    const { companyInfo, setCompanyInfo, mode, messages, setMessages } = props
    const intl = useIntl()

    const fields = useMemo(() => getFormCheckFields(true, companyInfo), [companyInfo])
    const filedCheck = useFieldChecker(fields, setMessages)

    return <Form data={companyInfo} setData={setCompanyInfo} labelDisplay="block" helperDisplay="tooltip" readonly={mode === ScreenMode.VIEW} messages={messages} setMessages={setMessages} ensure={filedCheck}>
        <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="mainContact" /></SectionTitle> </GridItem>
        <StringItem field="contact1" required={fields['contact1'].required} label={intl.formatMessage({ id: 'field.contactName' })} />
        <StringItem field="fax1" required label={intl.formatMessage({ id: 'companyFax' })} />
        <Placeholder />
        <StringItem field="telephone1" required label={intl.formatMessage({ id: 'companyTelephone' })} />
        <StringItem field="email1" required label={intl.formatMessage({ id: 'companyEmail' })} />
        <Placeholder />
        <Break />
        <Placeholder />

        <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="secondaryContact" /></SectionTitle></GridItem>
        <StringItem field="contact2" label={intl.formatMessage({ id: 'field.contactName' })} />
        <StringItem field="fax2" label={intl.formatMessage({ id: 'companyFax' })} />
        <Placeholder />
        <StringItem field="telephone2" label={intl.formatMessage({ id: 'companyTelephone' })} />
        <StringItem field="email2" label={intl.formatMessage({ id: 'companyEmail' })} />
    </Form>
}

const SaveAction = memo((props: {
    companyInfo: TnmCompanyTemp,
    mode: ScreenMode,
    companyCode: String,
    search: () => void,
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>
}) => {
    const { mode, companyInfo, setMessages, search, companyCode } = props
    const updatedOrInsertCompanyDetail = useUpdatedOrInsertCompanyDetail()
    const navigate = useNavigate()
    const fields = useMemo(() => getFormCheckFields(false, companyInfo), [companyInfo])
    const formValidate = useFormValidater(setMessages, fields)
    const dispatch = useDispatch()
    const [disabled, setDisabled] = useState<boolean>(false)

    const onclickSave = useCallback(() => {
        // DO filed check
        if (formValidate(companyInfo)) {
            setMessages([])
            if (companyInfo) {
                const indx = companyInfo.companyCode ? companyInfo.companyCode.indexOf('-') : -1
                const companySub = indx > -1 ? companyInfo.companyCode?.substring(indx + 1) : companyInfo.companyCode
                if (!companySub) {
                    dispatch(applicationActions.pushError({ title: { code: 'tempSave' }, messages: { code: 'w0354' } }))
                } else {
                    setDisabled(true)
                    // do update
                    updatedOrInsertCompanyDetail({ basicInfo: companyInfo }, { serialized: true }).then(result => {
                        if (mode === ScreenMode.CREATE || companyCode !== companyInfo.companyCode) {
                            // refresh screen
                            companyInfo.tempCompanyId = result.basicInfo.tempCompanyId
                            companyInfo.version = result.basicInfo.version
                            navigate(`/company/edit-${companyInfo.companyCode}`)
                        } else {
                            search()
                        }
                    }).finally(() => {
                        setDisabled(false)
                    })
                }
            }
        }
    }, [formValidate, companyInfo, setMessages, dispatch, updatedOrInsertCompanyDetail, mode, companyCode, navigate, search])

    return <TempSaveCallbackViewAction outlined access="MARS.MLS011.TEMPSAVE" callback={onclickSave} disabled={disabled} />
})

const SubmitAction = memo((props: {
    companyInfo: TnmCompanyTemp,
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>
}) => {
    const { companyInfo, setMessages } = props
    const updatedCompanyInfo = useSaveAndIssueCompanyInfo()
    const fields = useMemo(() => getFormCheckFields(true, companyInfo), [companyInfo])
    const formValidate = useFormValidater(setMessages, fields)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'submit' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)

    const onclickSubmit = useCallback(() => {
        if (formValidate(companyInfo)) {
            if (companyInfo) {
                const indx = companyInfo.companyCode ? companyInfo.companyCode.indexOf('-') : -1
                const companySub = indx > -1 ? companyInfo.companyCode?.substring(indx + 1) : companyInfo.companyCode
                if (!companySub) {
                    dispatch(applicationActions.pushError({ title: { code: 'submit' }, messages: { code: 'w0354' } }))
                } else {
                    const functionId = functionStore.register(() => {
                        setDisabled(true)
                        updatedCompanyInfo({ basicInfo: companyInfo }, { serialized: true }).then((result) => {
                            navigate('/company')
                        }).finally(() => {
                            setDisabled(false)
                        })
                    })
                    dispatch(applicationActions.pushWarning({
                        title: title,
                        messages: { code: 'c0001', args: [title] },
                        actions: [{
                            label: 'CANCEL'
                        }, {
                            functionId,
                            label: 'CONFIRM',
                        }]
                    }))
                }
            }
        }
    }, [formValidate, companyInfo, dispatch, functionStore, title, updatedCompanyInfo, navigate])

    return <SubmitCallbackViewAction access="MARS.MLS011.SUBMIT" callback={onclickSubmit} disabled={disabled} />
})

const getFormCheckFields = (isIssue: boolean, companyInfo: TnmCompanyTemp) => {
    return ({
        companyCode: { labelId: 'field.companyCode', required: true, length: { max: 20 } },
        regionCode: { labelId: 'field.regionCode', required: true },
        companyName: { labelId: 'field.companyName', required: isIssue, length: { max: 255 } },
        companyShortCode: { labelId: 'field.companyShortCode', required: true, length: { max: 3 } },
        address1: { labelId: 'field.address1', required: isIssue, length: { max: 100 } },
        address2: { labelId: 'field.address2', length: { max: 100 } },
        address3: { labelId: 'field.address3', length: { max: 100 } },
        address4: { labelId: 'field.address4', length: { max: 100 } },
        postalCode: { labelId: 'field.postalCode', required: isIssue, length: { max: 10 } },
        timeZone: { labelId: 'field.timeZone', required: isIssue },
        activeFlag: { labelId: 'field.activeFlag', required: isIssue },
        portcastFlag: { labelId: 'field.portcastFlag', required: isIssue },
        contact1: { labelId: 'field.contact1', required: isIssue, length: { max: 100 } },
        fax1: { labelId: 'field.fax1', required: isIssue, length: { max: 100 } },
        telephone1: { labelId: 'field.telephone1', required: isIssue, length: { max: 100 } },
        email1: { labelId: 'field.email1', required: isIssue, length: { max: 255 } },
        contact2: { labelId: 'field.contact2', length: { max: 100 } },
        fax2: { labelId: 'field.fax2', length: { max: 100 } },
        telephone2: { labelId: 'field.telephone2', length: { max: 100 } },
        email2: { labelId: 'field.email2', length: { max: 255 } },
    })
}