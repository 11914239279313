import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, MenuItem, MenuList, Popover, PopoverProps, Tooltip, useTheme, withStyles } from "@material-ui/core"
import { Action, Column, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Editing, Filtering, PaginationLayout, Paging, Row, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { AutoCompleteItem, Break, DateItem, EntryItem, Form, Message, NumberItem, Placeholder, StringItem, TimeItem } from "@rithe/form"
import { GridItem } from "@rithe/ui"
import { Arrays, Records, usePopover } from "@rithe/utils"
import moment from "moment"
import React, { Dispatch, memo, ReactNode, SetStateAction, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useFunctionStore } from "../../../Root"
import { Access } from "../../../components/Access/Access"
import { NoAccess } from "../../../components/Access/NoAccess"
import { CallbackCardAction } from "../../../components/Action/CallbackCardAction"
import { SaveCallbackViewAction } from "../../../components/Action/SaveCallbackViewAction"
import { SubmitCallbackViewAction } from "../../../components/Action/SubmitCallbackViewAction"
import { UploadCallbackCardAction } from "../../../components/Action/UploadCallbackCardAction"
import { BlackSimpleCard } from "../../../components/Card/BlackSimpleCard"
import { BlackSimpleCardContent } from "../../../components/Card/BlackSimpleCardContent"
import { BlackSimpleCardHeader } from "../../../components/Card/BlackSimpleCardHeader"
import { CardPanel } from "../../../components/Card/CardPanel"
import { CardTab } from "../../../components/Card/CardTab"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { TabsCard } from "../../../components/Card/TabsCard"
import { TabsCardContent } from "../../../components/Card/TabsCardContent"
import { TabsCardHeader } from "../../../components/Card/TabsCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DeleteCallbackRowAction } from "../../../components/DataGrid/rowActions/DeleteCallbackRowAction"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { DottedDivider } from "../../../components/Divider/DottedDivider"
import { CodeItem } from "../../../components/Form/CodeItem"
import { SectionTitle } from "../../../components/SectionTitle/SectionTitle"
import { View } from "../../../components/View/View"
import { useDownloadExternalOutboundTemplate, useDownloadOutTemplate } from "../../../services/delivery/apis/deliveryDownloadApi"
import { useGetPackagePartsList, useSaveAndSubmitOutbound, useSaveOutbound } from "../../../services/delivery/apis/deliveryOutboundApi"
import { useDeliveryUploadOutboundForCreate, useUploadExternalOutboundByDetail, useUploadInvoiceExcelForOutbound, useUploadOutboundByInvoiceExcel } from "../../../services/delivery/apis/deliveryUploadApi"
import { OutboundDatasource } from "../../../services/delivery/enums/OutboundDatasource"
import { DownloadOutBoundPartsInfo } from "../../../services/delivery/models/DownloadOutBoundPartsInfo"
import { InnerPartsResult } from "../../../services/delivery/models/InnerPartsResult"
import { ContainerInfo, InnerPackageInfo, OutboundCreateFactor, OutboundPartsInfo, OuterPackageInfo } from "../../../services/delivery/models/OutboundCreateFactor"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { RolledPartsFlag } from "../../../services/master/enums/RolledPartsFlag"
import { TnmContainerType } from "../../../services/master/models/TnmContainerType"
import { TnmPackageType } from "../../../services/master/models/TnmPackageType"
import { Pair } from "../../../services/utils/Pair"
import { useGetCompanyCode, useGetCompanyType } from "../../../utils/ApplicationUtils"
import { useFieldChecker } from "../../../utils/ValidatorUtils"
import { applicationActions, useApplicationSelector } from "../../Application/applicationSlice"
import { DownloadOutboundForm } from "./DownloadOutboundForm"
import { DownloadOutboundFormSelectPkg } from "./DownloadOutboundFormSelectPkg"
import { defaultContainers } from "./LOS034"

interface LOS034PcUiProps {
    data: OutboundCreateFactor,
    setData: Dispatch<SetStateAction<OutboundCreateFactor>>,
    containers: ContainerInfo[],
    setContainers: Dispatch<SetStateAction<ContainerInfo[]>>,
    hugeData: boolean,
    setHugeData: Dispatch<SetStateAction<boolean>>,
    buyerList: Pair[],
    receiverList: Pair[],
    outerPackages: Pair[],
    innerPartsList: InnerPartsResult[],
    dbInnerPartsList: InnerPartsResult[],
    containerTypes: TnmContainerType[],
    packageTypes: TnmPackageType[],
    restInnerPartsList: (buyer: string) => void,
    restPackageList: (buyer: string) => void,
    resetNotyetDeliveryQty: (containers: ContainerInfo[]) => ContainerInfo[]
}

export const LOS034PcUi = (props: LOS034PcUiProps) => {
    const { buyerList, receiverList,
        outerPackages, innerPartsList, dbInnerPartsList, containerTypes, packageTypes,
        restInnerPartsList, restPackageList, resetNotyetDeliveryQty,
        data, setData, containers, setContainers, hugeData, setHugeData } = props
    const intl = useIntl()
    const [actions, setActions] = useState<SelectPartsActions>({})

    return <View actions={[
        <SaveAction data={data} containers={containers} />,
        <SaveAndSubmitAction data={data} containers={containers} />
    ]}>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={1}
                step
                title={intl.formatMessage({ id: 'step1OfCreateOutbound' })}
                subtitle={intl.formatMessage({ id: 'step1OfCreateOutboundSub' })}
                actions={[
                    <Step1DownloadAction partsList={dbInnerPartsList} buyerList={buyerList} receiverList={receiverList} outbound={data} containers={containers} />,
                    <Step1UploadAction resetNotyetDeliveryQty={resetNotyetDeliveryQty} restInnerPartsList={restInnerPartsList} restPackageList={restPackageList} setData={setData} setContainers={setContainers} />,
                    <Step1UploadByInvoiceExcelAction resetNotyetDeliveryQty={resetNotyetDeliveryQty} restInnerPartsList={restInnerPartsList} restPackageList={restPackageList} setData={setData} setContainers={setContainers} />,
                    <Step1ExternalUploadAction resetNotyetDeliveryQty={resetNotyetDeliveryQty} restInnerPartsList={restInnerPartsList} restPackageList={restPackageList} setData={setData} setContainers={setContainers} />,
                    <Step1UploadByTTPHInvoiceAction resetNotyetDeliveryQty={resetNotyetDeliveryQty} restInnerPartsList={restInnerPartsList} restPackageList={restPackageList} setData={setData} setContainers={setContainers} setHugeData={setHugeData}/>
                ]}
            />
            <SectionCardContent>
                <Step1DownloadUploadCard />
            </SectionCardContent>
        </SectionCard>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={2}
                step
                title={intl.formatMessage({ id: 'step2OfCreateOutbound' })}
                subtitle={intl.formatMessage({ id: 'step2OfCreateOutboundSub' })}
            />
            <SectionCardContent>
                <OutboundBaiscView data={data} setData={setData} setContainers={setContainers} buyerList={buyerList} receiverList={receiverList} restInnerPartsList={restInnerPartsList} restPackageList={restPackageList} />
            </SectionCardContent>
        </SectionCard>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={3}
                step
                title={intl.formatMessage({ id: 'step3OfCreateOutbound' })}
                subtitle={intl.formatMessage({ id: 'step3OfCreateOutboundSub' })}
            />
            <SectionCardContent>
                { hugeData ? <ContainerPackageGridView containers={containers} setContainers={setContainers} containerTypes={containerTypes} />
                    : <ContainerPackageView partsList={dbInnerPartsList} containers={containers} setContainers={setContainers} containerTypes={containerTypes} packageTypes={packageTypes} outerPackages={outerPackages} setActions={setActions} />
                }
                <SelectPartsListView innerPartsList={innerPartsList} actions={actions} setActions={setActions} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const SaveAction = ({ data, containers }: {
    data: OutboundCreateFactor,
    containers: ContainerInfo[]
}) => {
    const dispatch = useDispatch()
    const intl = useIntl()
    const navigate = useNavigate()
    const saveOutbound = useSaveOutbound()
    const [disabled, setDisabled] = useState<boolean>(false)

    const saveOutboundMethod = useCallback(() => {
        setDisabled(true)
        saveOutbound({ ...data, containers: containers }, { serialized: true, silent: true }).then((outboundNo: string) => {
            dispatch(applicationActions.pushSuccess({
                title: intl.formatMessage({ id: 'post /lcbm-logistics-api/api/outbound/save' }),
                messages: [{ code: 'notice.success' }],
            }))
            if (!data.outboundNo && outboundNo) {
                navigate("/outbound/edit-" + outboundNo)
            }
        }).finally(() => {
            setDisabled(false)
        })
    }, [saveOutbound, data, containers, dispatch, intl, navigate])
    return <SaveCallbackViewAction outlined access="LOGI.LOS034.SUBMIT" callback={saveOutboundMethod} disabled={disabled} />
}

const SaveAndSubmitAction = ({ data, containers }: {
    data: OutboundCreateFactor,
    containers: ContainerInfo[]
}) => {
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const navigate = useNavigate()
    const saveAndSubmitOutbound = useSaveAndSubmitOutbound()
    const title = useMemo(() => intl.formatMessage({ id: 'submit' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)

    const submitActionMethod = useCallback(() => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            saveAndSubmitOutbound({ ...data, containers: containers }, { serialized: true, silent: true }).then(warningMessages => {
                if (warningMessages.length === 0) {
                    dispatch(applicationActions.pushSuccess({
                        title: intl.formatMessage({ id: 'post /lcbm-logistics-api/api/createOutbound' }),
                        messages: [{ code: 'notice.success' }],
                    }))
                    navigate("/outbound")
                } else {
                    // if failed to call portcast, push info
                    const message = warningMessages.find(m => m.code === "i0004")
                    if (message) {
                        dispatch(applicationActions.pushInfo({
                            title: intl.formatMessage({ id: 'post /lcbm-shipping-detail-api/api/listtracking' }),
                            messages: [{ code: message.code, args: message.args }],
                        }))
                        navigate("/outbound")
                    } else {
                        const functionId = functionStore.register(() => {
                            saveAndSubmitOutbound({ ...data, isConfirm: true, containers: containers }, { serialized: true }).then(messages => {
                                if (messages.filter(m => m.code !== "c0025").length === 0) {
                                    dispatch(applicationActions.pushSuccess({
                                        title: intl.formatMessage({ id: 'post /lcbm-logistics-api/api/outbound/saveAndSubmit' }),
                                        messages: [{ code: 'c0026' }],
                                    }))
                                }
                                navigate("/outbound")
                            })
                        })
                        dispatch(applicationActions.pushWarning({
                            title: intl.formatMessage({ id: 'confirm' }),
                            messages: warningMessages,
                            actions: [{
                                label: 'CANCEL'
                            }, {
                                functionId,
                                label: 'CONFIRM',
                            }]
                        }))
                    }
                }

            }).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [functionStore, dispatch, title, saveAndSubmitOutbound, data, containers, intl, navigate])

    // comp
    return <SubmitCallbackViewAction access="LOGI.LOS034.SUBMIT" callback={submitActionMethod} disabled={disabled} />
}


const Step1DownloadAction = ({ buyerList, receiverList, partsList, outbound, containers }: {
    partsList: InnerPartsResult[],
    buyerList: Pair[],
    receiverList: Pair[],
    outbound: OutboundCreateFactor,
    containers: ContainerInfo[]
}) => {

    // use popover
    const { open, anchorEl, onOpen, onClose } = usePopover()
    // download    
    const [pop, setPop] = useState<boolean>(false)
    const [isInternal, setIsInternal] = useState<boolean>(true)
    const openDownloadInput = useCallback((internal: boolean) => {
        setPop(true)
        setIsInternal(internal)
    }, [setPop, setIsInternal])

    const [pkgPop, setPkgPop] = useState<boolean>(false)
    const openDownloadForm = useCallback(() => {
        setPkgPop(true)
    }, [])

    // hander download
    const shipper = useGetCompanyCode()
    const downloadApi = useDownloadOutTemplate()
    const downloadExternalApi = useDownloadExternalOutboundTemplate()
    const downloadFromScreen = useCallback(() => {
        // selections
        downloadApi(prepareDownloadFromScreen(outbound, containers, partsList, buyerList, receiverList, shipper ?? ''))
    }, [buyerList, containers, downloadApi, outbound, partsList, receiverList, shipper])
    const downloadExternalFromScreen = useCallback(() => {
        // selections
        downloadExternalApi(prepareDownloadFromScreen(outbound, containers, partsList, buyerList, receiverList, shipper ?? ''))
    }, [buyerList, containers, downloadExternalApi, outbound, partsList, receiverList, shipper])

    // blank file
    const downloadBlankFile = useCallback(() => downloadApi([]), [downloadApi])
    const downloadBlankExternalFile = useCallback(() => downloadExternalApi([]), [downloadExternalApi])
   
    //companyType
    const companyType = useGetCompanyType()

    return <>
        <Button onClick={onOpen} variant="outlined" style={{ marginLeft: 8, minWidth: 230 }} color="secondary">
            <FormattedMessage id="DownloadOutboundForm" />
        </Button >
        <PopoverMenu open={open} anchorEl={anchorEl} onClose={onClose} >
            <MenuList>
                <OutboundAccess access={["LOGI.LOS034.UPLOAD", "LOGI.LOS034.INVOICEUPLOAD"]} >
                    <Tooltip title={<FormattedMessage id="downloadBySelect" />}>
                        <MenuItem onClick={() => {
                            openDownloadInput(true)
                            onClose()
                        }}>
                            <FormattedMessage id="downloadBySelect" />
                        </MenuItem>
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id="downloadFromScreen" />}>
                        <MenuItem onClick={() => {
                            downloadFromScreen()
                            onClose()
                        }}>
                            <FormattedMessage id="downloadFromScreen" />
                        </MenuItem>
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id="downloadBlankOutbound" />}>
                        <MenuItem onClick={() => {
                            downloadBlankFile()
                            onClose()
                        }}>
                            <FormattedMessage id="downloadBlankOutbound" />
                        </MenuItem>
                    </Tooltip>
                </OutboundAccess>
                <Access access="LOGI.LOS034.EXTERNALUPLOAD">
                    <Tooltip title={<FormattedMessage id="downloadExternalBySelect" />}>
                        <MenuItem onClick={() => {
                            openDownloadInput(false)
                            onClose()
                        }}>
                            <FormattedMessage id="downloadExternalBySelect" />
                        </MenuItem>
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id="downloadExternalFromScreen" />}>
                        <MenuItem onClick={() => {
                            downloadExternalFromScreen()
                            onClose()
                        }}>
                            <FormattedMessage id="downloadExternalFromScreen" />
                        </MenuItem>
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id="downloadBlankExternalOutbound" />}>
                        <MenuItem onClick={() => {
                            downloadBlankExternalFile()
                            onClose()
                        }}>
                            <FormattedMessage id="downloadBlankExternalOutbound" />
                        </MenuItem>
                    </Tooltip>
                </Access>
                {CbdsType.SUPP !== companyType && <Access access="LOGI.LOS034.ROLLDOWNLOAD">
                    <Tooltip title={<FormattedMessage id="downloadBySelectPkg" />}>
                        <MenuItem onClick={() => {
                            openDownloadForm()
                            onClose()
                        }}>
                            <FormattedMessage id="downloadBySelectPkg" />
                        </MenuItem>
                    </Tooltip>
                </Access>}
            </MenuList>
        </PopoverMenu>
        <DownloadOutboundForm open={pop} setOpen={setPop} partsList={partsList} buyerList={buyerList} receiverList={receiverList} outbound={outbound} containers={containers} isInternal={isInternal} />
        <DownloadOutboundFormSelectPkg open={pkgPop} setOpen={setPkgPop} partsList={partsList} buyerList={buyerList} receiverList={receiverList} outbound={outbound} containers={containers} />
    </>
}

const Step1UploadAction = ({ setData, setContainers, resetNotyetDeliveryQty, restInnerPartsList, restPackageList }: {
    setData: React.Dispatch<React.SetStateAction<OutboundCreateFactor>>,
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
    restInnerPartsList: (buyer: string) => void,
    restPackageList: (buyer: string) => void,
    resetNotyetDeliveryQty: (containers: ContainerInfo[]) => ContainerInfo[],
}) => {
    const uploadApi = useDeliveryUploadOutboundForCreate()
    const uploadFrom = useCallback((files: FileList | null) => {
        if (files === null) return
        uploadApi({ file: files[0] }, { serialized: true }).then((result: OutboundCreateFactor) => {
            if (result) {
                setData(data => ({ ...result, containers: undefined, outboundNo: data.outboundNo }))
                setContainers(resetNotyetDeliveryQty(result.containers ?? defaultContainers))
                // set parts and set net
                restInnerPartsList(result.buyer)
                restPackageList(result.buyer)
            }
        })
    }, [resetNotyetDeliveryQty, restInnerPartsList, restPackageList, setContainers, setData, uploadApi])
    return <UploadCallbackCardAction access="LOGI.LOS034.UPLOAD" callback={uploadFrom} title={<FormattedMessage id="UploadOutboundForm" />} />
}

const Step1UploadByInvoiceExcelAction = ({ setData, setContainers, resetNotyetDeliveryQty, restInnerPartsList, restPackageList }: {
    setData: React.Dispatch<React.SetStateAction<OutboundCreateFactor>>,
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
    restInnerPartsList: (buyer: string) => void,
    restPackageList: (buyer: string) => void,
    resetNotyetDeliveryQty: (containers: ContainerInfo[]) => ContainerInfo[],
}) => {
    const uploadApi = useUploadOutboundByInvoiceExcel()
    const uploadFrom = useCallback((files: FileList | null) => {
        if (files === null) return
        uploadApi({ file: files[0] }, { serialized: true }).then((result: OutboundCreateFactor) => {
            if (result) {
                setData(data => ({ ...result, containers: undefined, outboundNo: data.outboundNo }))
                setContainers(resetNotyetDeliveryQty(result.containers ?? defaultContainers))
                // set parts and set net
                restInnerPartsList(result.buyer)
                restPackageList(result.buyer)
            }
        })
    }, [resetNotyetDeliveryQty, restInnerPartsList, restPackageList, setContainers, setData, uploadApi])
    return <UploadCallbackCardAction access="LOGI.LOS034.INVOICEUPLOAD" callback={uploadFrom} title={<FormattedMessage id="UploadOutboundInvoice" />} />
}


const Step1UploadByTTPHInvoiceAction = ({ setData, setContainers, resetNotyetDeliveryQty, restInnerPartsList, restPackageList, setHugeData }: {
    setData: React.Dispatch<React.SetStateAction<OutboundCreateFactor>>,
    setHugeData: React.Dispatch<React.SetStateAction<boolean>>,
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
    restInnerPartsList: (buyer: string) => void,
    restPackageList: (buyer: string) => void,
    resetNotyetDeliveryQty: (containers: ContainerInfo[]) => ContainerInfo[],
}) => {
    const uploadApi = useUploadInvoiceExcelForOutbound()
    const uploadFrom = useCallback((files: FileList | null) => {
        if (files === null) return
        uploadApi({ file: files[0] }, { serialized: true }).then((result: OutboundCreateFactor) => {
            if (result) {
                setHugeData(true)
                setData(data => ({ ...result, containers: undefined, outboundNo: data.outboundNo }))
                setContainers(resetNotyetDeliveryQty(result.containers ?? defaultContainers))
                // set parts and set net
                restInnerPartsList(result.buyer)
                restPackageList(result.buyer)
            }
        })
    }, [resetNotyetDeliveryQty, restInnerPartsList, restPackageList, setContainers, setData, setHugeData, uploadApi])
    return <UploadCallbackCardAction access="LOGI.LOS034.TTPHINVOICEUPLOAD" callback={uploadFrom} title={<FormattedMessage id="UploadOutboundInvoice" />} />
}

const Step1ExternalUploadAction = ({ setData, setContainers, resetNotyetDeliveryQty, restInnerPartsList, restPackageList }: {
    setData: React.Dispatch<React.SetStateAction<OutboundCreateFactor>>,
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
    restInnerPartsList: (buyer: string) => void,
    restPackageList: (buyer: string) => void,
    resetNotyetDeliveryQty: (containers: ContainerInfo[]) => ContainerInfo[],
}) => {
    const uploadApi = useUploadExternalOutboundByDetail()
    const uploadFrom = useCallback((files: FileList | null) => {
        if (files === null) return
        uploadApi({ file: files[0] }, { serialized: true }).then((result: OutboundCreateFactor) => {
            if (result) {
                setData(data => ({ ...result, containers: undefined, outboundNo: data.outboundNo }))
                setContainers(resetNotyetDeliveryQty(result.containers ?? defaultContainers))
                // set parts and set net
                restInnerPartsList(result.buyer)
                restPackageList(result.buyer)
            }
        })
    }, [resetNotyetDeliveryQty, restInnerPartsList, restPackageList, setContainers, setData, uploadApi])
    return <UploadCallbackCardAction access="LOGI.LOS034.EXTERNALUPLOAD" callback={uploadFrom} title={<FormattedMessage id="UploadExternalOutboundForm" />} />
}

const Step1DownloadUploadCard = memo((props: {}) => {
    return <></>
})


const OutboundBaiscView = ({ data, setData, restInnerPartsList, restPackageList, receiverList, buyerList, setContainers }: {
    data: OutboundCreateFactor,
    setData: React.Dispatch<React.SetStateAction<OutboundCreateFactor>>,
    buyerList: Pair[],
    receiverList: Pair[],
    restInnerPartsList: (buyer: string) => void
    restPackageList: (buyer: string) => void,
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
}) => {
    const intl = useIntl()
    const receivers = useMemo(() => receiverList.map(m => [m.first, m.second] as [string, string]), [receiverList])
    const buyers = useMemo(() => Arrays.distinct(buyerList.map(m => [m.first, m.second] as [string, string])), [buyerList])
    const [messages, setMessages] = useState<Message[]>([])
    const optionalRequired = data.datasource === OutboundDatasource.INTERNAL
    const chekFields = useMemo(() => ({
        receiver: { labelId: 'receiverCode', required: true, length: { max: 30 } },
        outboundDate: { labelId: 'field.outboundDate', required: optionalRequired },
        outboundRefNo: { labelId: 'field.outboundRefNo', required: optionalRequired, length: { max: 30 } },
        outboundType: { labelId: 'field.outboundType', required: optionalRequired },
        bookingNo: { labelId: 'field.bookingNo', required: false },
    }), [optionalRequired])
    const filedCheck = useFieldChecker(chekFields, setMessages)
    const mapBuyerValue = useCallback((data, value) => {
        // do warning
        restInnerPartsList(value)
        restPackageList(value)
        data.buyer !== value && setContainers(defaultContainers)
        return { ...data, buyer: value }
    }, [restInnerPartsList, restPackageList, setContainers])

    return data.datasource === OutboundDatasource.INTERNAL ?
        <Form data={data} setData={setData} labelDisplay="block" helperDisplay="tooltip" messages={messages} setMessages={setMessages} ensure={filedCheck}>
            <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="TripInfo" /></SectionTitle></GridItem>
            <EntryItem field="buyer" required label={intl.formatMessage({ id: 'buyer' })} entries={buyers} mapValue={mapBuyerValue} />
            <EntryItem field="receiver" required label={intl.formatMessage({ id: 'receiverCode' })} entries={receivers} />
            <StringItem field="outboundRefNo" required label={intl.formatMessage({ id: 'field.outboundRefNo' })} />
            <CodeItem field="outboundType" required label={intl.formatMessage({ id: 'field.outboundType' })} code={CodeCategory.UploadOutboundType} />
            <DateItem field="outboundDate" required label={intl.formatMessage({ id: 'field.outboundDate' })}></DateItem>
            <TimeItem field="outboundTime" label={intl.formatMessage({ id: 'field.outboundTime' })}></TimeItem>
            <DateItem field="etd" label={intl.formatMessage({ id: 'fullEtd' })}></DateItem>
            <DateItem field="eta" label={intl.formatMessage({ id: 'fullEta' })}></DateItem>
            <CodeItem field="shippingMode" label={intl.formatMessage({ id: 'field.shippingMode' })} code={CodeCategory.ShippingMode} />
            <StringItem field="bookingNo" label={intl.formatMessage({ id: 'field.bookingNo' })} />
        </Form>
        : <Form data={data} setData={setData} labelDisplay="block" helperDisplay="tooltip" messages={messages} setMessages={setMessages} ensure={filedCheck}>
            <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="TripInfo" /></SectionTitle></GridItem>
            <EntryItem field="buyer" required label={intl.formatMessage({ id: 'buyer' })} entries={buyers} mapValue={mapBuyerValue} />
            <EntryItem field="receiver" required label={intl.formatMessage({ id: 'receiverCode' })} entries={receivers} />
            <StringItem field="outboundRefNo" label={intl.formatMessage({ id: 'field.outboundRefNo' })} />
            <DateItem field="outboundDate" required label={intl.formatMessage({ id: 'field.outboundDate' })}></DateItem>
            <DateItem field="etd" label={intl.formatMessage({ id: 'fullEtd' })}></DateItem>
            <DateItem field="eta" label={intl.formatMessage({ id: 'fullEta' })}></DateItem>
            <CodeItem field="shippingMode" label={intl.formatMessage({ id: 'field.shippingMode' })} code={CodeCategory.ShippingMode} />
        </Form>
}


const ContainerPackageView = ({ containers, setContainers, containerTypes, packageTypes, outerPackages, setActions, partsList }: {
    containers: ContainerInfo[],
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
    containerTypes: TnmContainerType[],
    packageTypes: TnmPackageType[],
    outerPackages: Pair[],
    setActions: React.Dispatch<React.SetStateAction<SelectPartsActions>>,
    partsList: InnerPartsResult[],
}) => {

    const theme = useTheme()

    return <>
        <PartsSummaryView containers={containers} />
        <ContainerPackagesSummaryView containers={containers} />
        <DottedDivider style={{ marginTop: theme.spacing(4), marginBottom: theme.spacing(4) }} />
        <ContainerPackagesTabView partsList={partsList} containers={containers} setContainers={setContainers} containerTypes={containerTypes} packageTypes={packageTypes} outerPackages={outerPackages} setActions={setActions} />
    </>
}

const PartsSummaryView = ({ containers }: {
    containers: ContainerInfo[]
}) => {

    const intl = useIntl()
    const theme = useTheme()
    const partsSummaryList = useMemo(() => {
        const partsList = containers.flatMap(f => f.outerPackageList ?? []).flatMap(f => f.innerPackageList ?? []).flatMap(f => f.partsList ?? [])
        const partsNoList = Arrays.distinct(partsList.map(m => m.partsNo))
        return partsNoList.map((partsNo, index) => {
            const filterList = partsList.filter(f => f.partsNo === partsNo)
            return {
                rowNo: index + 1,
                partsNo: partsNo,
                partsName: filterList[0].sellerPartsName,
                qty: filterList.map(m => m.qty ?? 0).reduce((a, b) => a + b, 0),
            }
        })
    }, [containers])
    const columns = useMemo(() => [
        { field: 'rowNo', dataTypeName: 'number', title: intl.formatMessage({ id: 'rowNo' }), width: 180 },
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 400 },
        { field: 'partsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsName' }), width: 400 },
        { field: 'qty', dataTypeName: 'number', title: intl.formatMessage({ id: 'totalQuantity' }), width: 300 }
    ], [intl])

    return <div style={{ width: '100%', height: 300, marginBottom: theme.spacing(2) }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <Data rows={partsSummaryList} columns={columns} />
            <ColumnFreeze />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Sorting />
            <Filtering />
            <Paging defaultPageSize={15} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        </DataGrid>
    </div>
}

const ContainerPackagesSummaryView = ({ containers }: {
    containers: ContainerInfo[]
}) => {
    const intl = useIntl()
    const containerPackageSummary = useMemo(() => {
        const packageList = containers.flatMap(m => m.outerPackageList ?? [])
        return {
            numOfContianer: containers.length,
            contentOfContainer: Arrays.distinct(containers.map(m => m.commodityType ?? '')).join(','),
            typeOfContainer: Arrays.distinct(containers.map(m => m.containerType ?? '')).join(','),
            totalGWOfContianer: containers.map(m => m.grossWeight ?? 0).reduce((a, b) => a + b, 0),
            numOfPackage: packageList.length,
            totalM3OfPackage: packageList.map(m => m.m3 ?? 0).reduce((a, b) => floatAdd(a + b, 10), 0),
            totalNWOfPackage: packageList.map(m => m.netWeight ?? 0).reduce((a, b) => floatAdd(a + b, 10), 0),
            totalGWOfPackage: packageList.map(m => m.grossWeight ?? 0).reduce((a, b) => floatAdd(a + b, 10), 0),
        }
    }, [containers])

    return <Card>
        <CardContent>
            <Form data={containerPackageSummary} labelDisplay="block" helperDisplay="tooltip" columnCount={2}>
                <GridItem columnSpan={2}><SectionTitle size="small">{intl.formatMessage({ id: 'containerInfo' })}</SectionTitle></GridItem>
                <NumberItem field="numOfContianer" readonly label={intl.formatMessage({ id: 'numOfContianer' })} />
                <StringItem field="contentOfContainer" readonly label={intl.formatMessage({ id: 'contentOfContainer' })} />
                <StringItem field="typeOfContainer" readonly label={intl.formatMessage({ id: 'typeOfContainer' })} />
                <NumberItem field="totalGWOfContianer" readonly label={intl.formatMessage({ id: 'totalGWOfContianer' })} />

                <GridItem columnSpan={2}><SectionTitle size="middle">{intl.formatMessage({ id: 'packageInfo' })} </SectionTitle></GridItem>
                <NumberItem field="numOfPackage" readonly label={intl.formatMessage({ id: 'numOfPackage' })} />
                <NumberItem field="totalM3OfPackage" readonly label={intl.formatMessage({ id: 'totalM3OfPackage' })} />
                <NumberItem field="totalNWOfPackage" readonly label={intl.formatMessage({ id: 'totalNWOfPackage' })} />
                <NumberItem field="totalGWOfPackage" readonly label={intl.formatMessage({ id: 'totalGWOfPackage' })} />
            </Form>
        </CardContent>
    </Card>
}

const ContainerPackagesTabView = ({ containers, setContainers, containerTypes, packageTypes, outerPackages, setActions, partsList }: {
    containers: ContainerInfo[],
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
    containerTypes: TnmContainerType[],
    packageTypes: TnmPackageType[],
    outerPackages: Pair[],
    setActions: React.Dispatch<React.SetStateAction<SelectPartsActions>>,
    partsList: InnerPartsResult[],
}) => {

    const dispatch = useDispatch()
    const [containerIndex, setContainerIndex] = useState<number>(0)

    const onSelectedValueChange = useCallback((value: string | number) => {
        setContainerIndex(value as number)
    }, [setContainerIndex])

    const closeCommon = (tabId: number) => {
        if (tabId === (containers.length - 1) && tabId > 0) {
            setContainerIndex(containers.length - 2)
        }
        setContainers(containers => {
            if (containers.length > 1) {
                return containers.filter((_, index) => index !== tabId)
            } else {
                dispatch(applicationActions.pushError({ title: { code: 'remove' }, messages: { code: 'w0362' } }))
                return containers
            }
        })
    }

    return <TabsCard>
        <TabsCardHeader selectedValue={containerIndex} onSelectedValueChange={onSelectedValueChange} actions={[<AddContainerAction setContainers={setContainers} />]}>
            {containers.map((m, index) => <CardTab title={'Container ' + (index + 1)} subtitle={m.containerNo} value={index} onClose={closeCommon} />)}
        </TabsCardHeader>
        <TabsCardContent>
            <CardPanel value={containerIndex}>
                <ContainerPackageCardView
                    setActions={setActions}
                    containerIndex={containerIndex}
                    container={containers[containerIndex]}
                    setContainers={setContainers}
                    containerTypes={containerTypes}
                    outerPackages={outerPackages}
                    packageTypes={packageTypes}
                    partsList={partsList}
                />
            </CardPanel>
        </TabsCardContent>
    </TabsCard>
}

const AddContainerAction = ({ setContainers }: {
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
}) => {

    const callback = useCallback(() => {
        setContainers(containers => [...containers, ...defaultContainers])
    }, [setContainers])
    return <CallbackCardAction callback={callback} title={<FormattedMessage id="addContainer" />} />
}

const ContainerPackageCardView = memo(({ container, setContainers, containerIndex, containerTypes, packageTypes, outerPackages, setActions, partsList }: {
    container: ContainerInfo,
    containerIndex: number,
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
    containerTypes: TnmContainerType[],
    packageTypes: TnmPackageType[],
    outerPackages: Pair[],
    setActions: React.Dispatch<React.SetStateAction<SelectPartsActions>>,
    partsList: InnerPartsResult[],
}) => {

    const { outerPackageList } = container

    return <>
        <ContainerInfoView container={container} containerIndex={containerIndex} setContainers={setContainers} containerTypes={containerTypes} />
        <OutPackageListView partsList={partsList} setActions={setActions} containerIndex={containerIndex} outerPackages={outerPackages} outerPackageList={outerPackageList} setContainers={setContainers} packageTypes={packageTypes} />
    </>
})

const ContainerInfoView = memo(({ container, containerIndex, setContainers, containerTypes }: {
    container: ContainerInfo,
    containerIndex: number,
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
    containerTypes: TnmContainerType[],
}) => {

    const suggestions = containerTypes.map(m => m.containerTypeCode ?? '')
    const onFieldChange = useCallback<React.Dispatch<React.SetStateAction<ContainerInfo>>>(nextDraftDataFunc => {
        setContainers(containers => {
            const currentContainer = containers[containerIndex]
            const draftData = typeof nextDraftDataFunc === 'function' ? nextDraftDataFunc(currentContainer) : nextDraftDataFunc
            return containers.map((m, index) => index === containerIndex ? draftData : m)
        })
    }, [containerIndex, setContainers])

    const getNumberOfCarton = useCallback(() => {
        return container.outerPackageList?.length ?? 0
    }, [container.outerPackageList?.length])

    const getPartsQty = useCallback(() => {
        const partsList = (container.outerPackageList ?? []).flatMap(m => m.innerPackageList ?? []).flatMap(m => m.partsList)
        return partsList.map(m => m.qty ?? 0).reduce((a, b) => floatAdd(a + b, 5), 0)
    }, [container.outerPackageList])

    return <Form data={container} setData={onFieldChange} labelDisplay="block" helperDisplay="tooltip" >
        <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="containerInfo" /></SectionTitle></GridItem>
        <AutoCompleteItem field="containerType" label={<FormattedMessage id='typeOfContainer' />} suggestions={suggestions} />
        <NumberItem field="numberOfCarton" readonly label={<FormattedMessage id='numberOfCarton' />} getValue={getNumberOfCarton} />
        <StringItem field="commodityType" label={<FormattedMessage id='typeOfCommodities' />} />

        <NumberItem field="qty" readonly label={<FormattedMessage id='quantity' />} getValue={getPartsQty} />
        <StringItem field="containerNo" label={<FormattedMessage id='field.containerNo' />} />
        <StringItem field="sealNo" label={<FormattedMessage id='field.sealNo' />} />

        <NumberItem field="m3" label={<FormattedMessage id='containerM3' />} />
        <NumberItem field="netWeight" label={<FormattedMessage id='containerNW' />} />
        <NumberItem field="grossWeight" label={<FormattedMessage id='containerGW' />} />
    </Form>
})

const floatAdd = (float: number, digit: number) => {
    var m = Math.pow(10, digit)
    return Math.round(float * m) / m 
}

const OutPackageListView = memo(({ outerPackageList, containerIndex, setContainers, packageTypes, outerPackages, setActions, partsList }: {
    containerIndex: number,
    outerPackageList?: OuterPackageInfo[],
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
    packageTypes: TnmPackageType[],
    outerPackages: Pair[],
    setActions: React.Dispatch<React.SetStateAction<SelectPartsActions>>,
    partsList: InnerPartsResult[],
}) => {

    const theme = useTheme()

    const clickAddOuterPackage = useCallback(() => {
        setContainers(containers => {
            const container = containers[containerIndex]
            const packageList = container.outerPackageList ?? []
            const newContainer = {
                ...container, outerPackageList: [...packageList, { outerPackageNo: '', outboundByPackage: false, innerPackageList: [] }]
            }
            return containers.map((m, index) => index === containerIndex ? newContainer : m)
        })
    }, [containerIndex, setContainers])

    return <div style={{ width: '100%', paddingTop: theme.spacing(2) }}>
        <GridItem><SectionTitle size="middle"><FormattedMessage id="withInContainerOuterPackage" /></SectionTitle></GridItem>
        <GridItem style={{ border: 'dotted', borderRadius: theme.spacing(1), borderWidth: 'thin', borderColor: '#003361', margin: theme.spacing(2, 0) }}>
            <Button onClick={clickAddOuterPackage} style={{ justifyContent: 'start', paddingLeft: theme.spacing(2), width: '100%', fontSize: 14, fontWeight: 'bold' }}><FormattedMessage id="addOuterPackage2" /></Button>
        </GridItem>
        {outerPackageList?.map((outerPackage, index) => {
            return <GridItem>
                <OutPackageInfoCardView
                    setActions={setActions}
                    containerIndex={containerIndex}
                    outerPackageIndex={index}
                    outerPackage={outerPackage}
                    setContainers={setContainers}
                    packageTypes={packageTypes}
                    outerPackages={outerPackages}
                    partsList={partsList}
                />
            </GridItem>
        })}
    </div>
})

const OutPackageInfoCardView = memo(({ outerPackage, containerIndex, outerPackageIndex, setContainers, packageTypes, outerPackages, setActions, partsList }: {
    outerPackage: OuterPackageInfo,
    containerIndex: number,
    outerPackageIndex: number,
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
    packageTypes: TnmPackageType[],
    outerPackages: Pair[],
    setActions: React.Dispatch<React.SetStateAction<SelectPartsActions>>,
    partsList: InnerPartsResult[],
}) => {

    const { outerPackageNo, innerPackageList } = outerPackage
    const removeOuterPackage = useCallback(() => {
        setContainers(containers => {
            return containers.map((m, index) => index === containerIndex ? { ...m, outerPackageList: m.outerPackageList?.filter((_, pkgIdx) => pkgIdx !== outerPackageIndex) } : m)
        })
    }, [containerIndex, outerPackageIndex, setContainers])

    return <BlackSimpleCard allowCollapse>
        <BlackSimpleCardHeader clear={removeOuterPackage} title={'Outer Package ' + (outerPackageIndex + 1) + ': ' + (outerPackageNo && outerPackageNo !== '' ? outerPackageNo : '')} />
        <BlackSimpleCardContent>
            <OutPackageBasicInfoView
                containerIndex={containerIndex}
                outerPackageIndex={outerPackageIndex}
                outerPackage={outerPackage}
                setContainers={setContainers}
                packageTypes={packageTypes}
                outerPackages={outerPackages}
                partsList = {partsList}
            />
            <WithInOutboundPackagePartsView
                containerIndex={containerIndex}
                outerPackageIndex={outerPackageIndex}
                innerPackageList={innerPackageList}
                setContainers={setContainers}
                setActions={setActions}
                outerPackage={outerPackage}
            />
            <WithInOutboundPackageInnerPackageView
                containerIndex={containerIndex}
                outerPackageIndex={outerPackageIndex}
                innerPackageList={innerPackageList}
                setContainers={setContainers}
                setActions={setActions}
            />
        </BlackSimpleCardContent>
    </BlackSimpleCard>
})

const OutPackageBasicInfoView = memo(({ outerPackage, containerIndex, outerPackageIndex, setContainers, packageTypes, outerPackages, partsList }: {
    outerPackage: OuterPackageInfo,
    containerIndex: number,
    outerPackageIndex: number,
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
    packageTypes: TnmPackageType[],
    outerPackages: Pair[],
    partsList: InnerPartsResult[],
}) => {

    const typeSugestions = useMemo(() => packageTypes.map(m => m.packageTypeCode ?? ''), [packageTypes])
    const pkgSugestions = useMemo(() => Arrays.distinct(outerPackages.map(m => m.first as string)), [outerPackages])
    const getPackagePartsList = useGetPackagePartsList()
    const searchPackage = useCallback((packageNo) => {
        if (outerPackages.some(s => s.first === packageNo)) {
            getPackagePartsList({ packageNo }, { serialized: true, silent: true }).then(result => {
                // reset values
                const outerPkg = {
                    ...result,
                    innerPackageList : result.innerPackageList.map(ip => {
                        // reset partsList
                        const list = ip.partsList.map(part => {
                            const orderPart = partsList.find(detail => detail.soDetailId === part.soDetailId)
                            return orderPart ? {
                                ...part, 
                                contractRouteId: orderPart.contractRouteId,
                                notYetDeliveryQty: orderPart.notYetDeliveryQty,
                                uomCode: orderPart.uomCode,
                                sellingPrice: orderPart.sellingPrice,
                                rolledPartsFlag: orderPart.rolledPartsFlag
                            } : {
                                ...part
                            }
                        })
                        // reset list
                        return { ...ip,  partsList : list }
                    })
                }

                setContainers(containers => {
                    const container = containers[containerIndex]
                    const packageList = container.outerPackageList ?? []
                    const newContainer = {
                        ...container, outerPackageList: packageList.map((m, idx) => idx === outerPackageIndex ? outerPkg : m)
                    }
                    return containers.map((m, index) => index === containerIndex ? newContainer : m)
                })
            })
        }
    }, [containerIndex, getPackagePartsList, outerPackageIndex, outerPackages, partsList, setContainers])
    const onFieldChange = useCallback<React.Dispatch<React.SetStateAction<OuterPackageInfo>>>(nextDraftDataFunc => {
        setContainers(containers => {
            const container = containers[containerIndex]
            const packageList = container.outerPackageList ?? []
            const orgPackageData = packageList[outerPackageIndex]
            const draftData = typeof nextDraftDataFunc === 'function' ? nextDraftDataFunc(orgPackageData) : nextDraftDataFunc
            // do check if package is change 
            if (draftData.outerPackageNo !== orgPackageData.outerPackageNo) {
                searchPackage(draftData.outerPackageNo)
            }
            const newContainer = {
                ...container, outerPackageList: packageList.map((m, idx) => idx === outerPackageIndex ? draftData : m)
            }
            return containers.map((m, index) => index === containerIndex ? newContainer : m)
        })
    }, [containerIndex, outerPackageIndex, searchPackage, setContainers])

    return <Form data={outerPackage} setData={onFieldChange} labelDisplay="block" helperDisplay="tooltip" >
        <AutoCompleteItem field="outerPackageNo" label={<FormattedMessage id='outerPackageNo' />} suggestions={pkgSugestions} />
        <StringItem field="palletNo" label={<FormattedMessage id='palletNo' />} />
        <AutoCompleteItem field="outerPackageType" label={<FormattedMessage id='outerPackageType' />} suggestions={typeSugestions} />
        <DateItem field="productionDate" label={<FormattedMessage id='productionDate' />} />

        <NumberItem field="m3" label={<FormattedMessage id='packageM3' />} />
        <NumberItem field="netWeight" label={<FormattedMessage id='packageNW' />} />
        <NumberItem field="grossWeight" label={<FormattedMessage id='packageGW' />} />
    </Form>
})

const WithInOutboundPackagePartsView = memo(({ innerPackageList, containerIndex, outerPackageIndex, setContainers, setActions, outerPackage }: {
    innerPackageList: InnerPackageInfo[],
    containerIndex: number,
    outerPackageIndex: number,
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
    setActions: React.Dispatch<React.SetStateAction<SelectPartsActions>>,
    outerPackage: OuterPackageInfo
}) => {

    const theme = useTheme()
    const partsList = useMemo(() => {
        const innerPackage = innerPackageList.find(f => f.isBlank === true)
        return innerPackage?.partsList ?? []
    }, [innerPackageList])

    const handleSure = useCallback((innerPartsList: InnerPartsResult[], selections: number[]) => {
        const pickUpPartsList = innerPartsList.filter(f => selections.includes(f.soDetailId)).map(castSelectPartToOutboundParts)
        setContainers(containers => {
            const container = containers[containerIndex]
            const packageList = container.outerPackageList ?? []
            const packageInfo = packageList[outerPackageIndex]
            const innerPackageList = packageInfo.innerPackageList
            const innerPackageInfo = innerPackageList.find(f => f.isBlank)
            const partsList = innerPackageInfo?.partsList ?? []
            const newPartsList = [...partsList, ...pickUpPartsList]
            const newInnerPackage = innerPackageInfo ? { ...innerPackageInfo, partsList: newPartsList } : { isBlank: true, partsList: newPartsList }
            const newInnerPackageList = innerPackageInfo ? innerPackageList.map(m => m.isBlank ? newInnerPackage : m) : [newInnerPackage, ...innerPackageList]
            const newPackageInfo = { ...packageInfo, innerPackageList: newInnerPackageList }
            const newContainer = {
                ...container, outerPackageList: packageList.map((m, idx) => idx === outerPackageIndex ? newPackageInfo : m)
            }
            return containers.map((m, index) => index === containerIndex ? newContainer : m)
        })
    }, [containerIndex, outerPackageIndex, setContainers])

    const handleData = useCallback((innerPartsList: InnerPartsResult[]) => {
        let list = []
        const existList: number[] = partsList ? partsList.map(p => p.soDetailId) : []
        list = innerPartsList.filter(f => !existList.includes(f.soDetailId))
        if (outerPackage.outerPackageType === "ROLL") {
            list = list.filter(item => item.rolledPartsFlag === RolledPartsFlag.ROLL_PARTS)
        }
        return list
    }, [outerPackage.outerPackageType, partsList])

    const clickAddPartsIntoOuterPackage = useCallback(() => {
        setActions({ handleSure: handleSure, handleData: handleData })
    }, [handleData, handleSure, setActions])

    return <div style={{ width: '100%', padding: theme.spacing(2, 0) }}>
        <GridItem><SectionTitle size="middle"><FormattedMessage id="withOuterPackageParts" /></SectionTitle></GridItem>
        <GridItem style={{ border: 'dotted', borderRadius: theme.spacing(1), borderWidth: 'thin', borderColor: '#003361', margin: theme.spacing(2, 0) }}>
            <Button onClick={clickAddPartsIntoOuterPackage} style={{ justifyContent: 'start', paddingLeft: theme.spacing(2), width: '100%', fontSize: 14, fontWeight: 'bold' }}><FormattedMessage id="addParts2" /></Button>
        </GridItem>
        <GridItem >
            <PartsInfoListView
                innerPackageIndex={0}
                containerIndex={containerIndex}
                outerPackageIndex={outerPackageIndex}
                partsList={partsList}
                setContainers={setContainers}
            />
        </GridItem>
    </div>
})

const castSelectPartToOutboundParts = (selectPart: InnerPartsResult): OutboundPartsInfo => {
    return {
        soNo: selectPart.soNo,
        contractRouteId: selectPart.contractRouteId,
        sellerCode: selectPart.sellerCode,
        sellerPartsNo: selectPart.sellerPartsNo,
        sellerPartsName: selectPart.sellerPartsName,
        sellerBackNo: selectPart.sellerBackNo,
        colorCode: selectPart.colorCode,
        srbq: selectPart.srbq,
        partsNo: selectPart.partsNo,
        soDetailId: selectPart.soDetailId,
        notYetDeliveryQty: selectPart.notYetDeliveryQty,
        rolledPartsFlag: selectPart.rolledPartsFlag,
    }
}

const WithInOutboundPackageInnerPackageView = memo(({ innerPackageList, containerIndex, outerPackageIndex, setContainers, setActions }: {
    innerPackageList: InnerPackageInfo[],
    containerIndex: number,
    outerPackageIndex: number,
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
    setActions: React.Dispatch<React.SetStateAction<SelectPartsActions>>,
}) => {

    const theme = useTheme()
    const clickAddInnerPackage = useCallback(() => {
        setContainers(containers => {
            const container = containers[containerIndex]
            const packageList = container.outerPackageList ?? []
            const packageInfo = packageList[outerPackageIndex]
            const newPackageInfo = { ...packageInfo, innerPackageList: [...packageInfo.innerPackageList, { isBlank: false, partsList: [] }] }
            const newContainer = {
                ...container, outerPackageList: packageList.map((m, idx) => idx === outerPackageIndex ? newPackageInfo : m)
            }
            return containers.map((m, index) => index === containerIndex ? newContainer : m)
        })
    }, [containerIndex, outerPackageIndex, setContainers])
    const hasBlank = useMemo(() => innerPackageList.some(f => f.isBlank), [innerPackageList])

    return <div style={{ width: '100%', padding: theme.spacing(2, 0) }}>
        <GridItem><SectionTitle size="middle"><FormattedMessage id="withOuterPackageInnerPackage" /></SectionTitle></GridItem>
        <GridItem style={{ border: 'dotted', borderRadius: theme.spacing(1), borderWidth: 'thin', borderColor: '#003361', margin: theme.spacing(2, 0) }}>
            <Button onClick={clickAddInnerPackage} style={{ justifyContent: 'start', paddingLeft: theme.spacing(2), width: '100%', fontSize: 14, fontWeight: 'bold' }}><FormattedMessage id="addInnerPackage" /></Button>
        </GridItem>
        {innerPackageList.map((innerPackage, index) => {
            return innerPackage.isBlank ? <></> : <GridItem >
                <InnerPackageInfoCardView
                    setActions={setActions}
                    innerPackage={innerPackage}
                    containerIndex={containerIndex}
                    outerPackageIndex={outerPackageIndex}
                    innerPackageIndex={index}
                    hasBlank={hasBlank}
                    setContainers={setContainers}
                />
            </GridItem>
        })}
    </div>
})

const InnerPackageInfoCardView = memo(({ innerPackage, containerIndex, outerPackageIndex, innerPackageIndex, hasBlank, setContainers, setActions }: {
    innerPackage: InnerPackageInfo,
    containerIndex: number,
    outerPackageIndex: number,
    innerPackageIndex: number,
    hasBlank: boolean,
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
    setActions: React.Dispatch<React.SetStateAction<SelectPartsActions>>,
}) => {

    const { boxNo, partsList } = innerPackage
    const removeInnerPackage = useCallback(() => {
        setContainers(containers => {
            const container = containers[containerIndex]
            const packageList = container.outerPackageList ?? []
            const packageInfo = packageList[outerPackageIndex]
            const innerPackageList = packageInfo.innerPackageList
            const newInnerPackageList = innerPackageList.filter((f, idx) => idx !== innerPackageIndex)
            const newPackageInfo = { ...packageInfo, innerPackageList: newInnerPackageList }
            const newContainer = {
                ...container, outerPackageList: packageList.map((m, idx) => idx === outerPackageIndex ? newPackageInfo : m)
            }
            return containers.map((m, index) => index === containerIndex ? newContainer : m)
        })
    }, [containerIndex, outerPackageIndex, innerPackageIndex, setContainers])

    return <BlackSimpleCard allowCollapse>
        <BlackSimpleCardHeader
            clear={removeInnerPackage}
            title={'Inner Package ' + (hasBlank ? innerPackageIndex : innerPackageIndex + 1) + ': ' + (boxNo && boxNo !== '' ? boxNo : '')}
            actions={[<AddPartsAction
                innerPackage={innerPackage}
                containerIndex={containerIndex}
                outerPackageIndex={outerPackageIndex}
                innerPackageIndex={innerPackageIndex}
                setContainers={setContainers}
                setActions={setActions} />]}
        />
        <BlackSimpleCardContent>
            <InnerPackageBasicInfoView
                containerIndex={containerIndex}
                outerPackageIndex={outerPackageIndex}
                innerPackageIndex={innerPackageIndex}
                innerPackage={innerPackage}
                setContainers={setContainers}
            />
            <PartsInfoListView
                containerIndex={containerIndex}
                outerPackageIndex={outerPackageIndex}
                innerPackageIndex={innerPackageIndex}
                partsList={partsList}
                setContainers={setContainers} />

        </BlackSimpleCardContent>
    </BlackSimpleCard>
})

const AddPartsAction = ({ containerIndex, outerPackageIndex, innerPackageIndex, innerPackage, setContainers, setActions }: {
    containerIndex: number,
    outerPackageIndex: number,
    innerPackageIndex: number,
    innerPackage: InnerPackageInfo,
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
    setActions: React.Dispatch<React.SetStateAction<SelectPartsActions>>,
}) => {
    const handleSure = useCallback((innerPartsList: InnerPartsResult[], selections: number[]) => {
        const pickUpPartsList = innerPartsList.filter(f => selections.includes(f.soDetailId)).map(castSelectPartToOutboundParts)
        setContainers(containers => {
            const container = containers[containerIndex]
            const packageList = container.outerPackageList ?? []
            const packageInfo = packageList[outerPackageIndex]
            const innerPackageList = packageInfo.innerPackageList
            const innerPackageInfo = innerPackageList[innerPackageIndex]
            const partsList = innerPackageInfo.partsList
            const newPartsList = [...partsList, ...pickUpPartsList]
            const newInnerPackageList = innerPackageList.map((m, idx) => idx === innerPackageIndex ? { ...innerPackageInfo, partsList: newPartsList } : m)
            const newPackageInfo = { ...packageInfo, innerPackageList: newInnerPackageList }
            const newContainer = {
                ...container, outerPackageList: packageList.map((m, idx) => idx === outerPackageIndex ? newPackageInfo : m)
            }
            return containers.map((m, index) => index === containerIndex ? newContainer : m)
        })
    }, [containerIndex, innerPackageIndex, outerPackageIndex, setContainers])
    const handleData = useCallback((innerPartsList: InnerPartsResult[]) => {
        const existList: number[] = innerPackage.partsList ? innerPackage.partsList.map(p => p.soDetailId) : []
        return innerPartsList.filter(f => !existList.includes(f.soDetailId))
    }, [innerPackage.partsList])

    const callback = useCallback(() => {
        setActions({ handleSure: handleSure, handleData: handleData })
    }, [handleData, handleSure, setActions])
    return <CallbackCardAction callback={callback} title={<FormattedMessage id="addParts2" />} />
}

const InnerPackageBasicInfoView = memo(({ innerPackage, containerIndex, outerPackageIndex, innerPackageIndex, setContainers }: {
    innerPackage: InnerPackageInfo,
    containerIndex: number,
    outerPackageIndex: number,
    innerPackageIndex: number,
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
}) => {

    const onFieldChange = useCallback<React.Dispatch<React.SetStateAction<InnerPackageInfo>>>(nextDraftDataFunc => {
        setContainers(containers => {
            const container = containers[containerIndex]
            const packageList = container.outerPackageList ?? []
            const packageInfo = packageList[outerPackageIndex]
            const innerPackageList = packageInfo.innerPackageList
            const innerPackageInfo = innerPackageList[innerPackageIndex]
            const draftData = typeof nextDraftDataFunc === 'function' ? nextDraftDataFunc(innerPackageInfo) : nextDraftDataFunc
            const newInnerPackageList = innerPackageList.map((m, idx) => idx === innerPackageIndex ? draftData : m)
            const newPackageInfo = { ...packageInfo, innerPackageList: newInnerPackageList }
            const newContainer = {
                ...container, outerPackageList: packageList.map((m, idx) => idx === outerPackageIndex ? newPackageInfo : m)
            }
            return containers.map((m, index) => index === containerIndex ? newContainer : m)
        })
    }, [containerIndex, innerPackageIndex, outerPackageIndex, setContainers])

    return <Form data={innerPackage} setData={onFieldChange} labelDisplay="block" helperDisplay="tooltip" >
        <StringItem field="boxNo" label={<FormattedMessage id='innerPacakgeNo' />} />
        <StringItem field="ipPackageType" label={<FormattedMessage id='innerPacakgeType' />} />
        <Placeholder />

        <NumberItem field="m3" label={<FormattedMessage id='innerPacakgeM3' />} />
        <NumberItem field="netWeight" label={<FormattedMessage id='innerPacakgeNw' />} />
        <NumberItem field="grossWeight" label={<FormattedMessage id='innerPacakgeGw' />} />
        <Break />
    </Form>
})

const PartsInfoListView = memo(({ partsList, containerIndex, outerPackageIndex, innerPackageIndex, setContainers }: {
    partsList: OutboundPartsInfo[],
    containerIndex: number,
    outerPackageIndex: number,
    innerPackageIndex: number,
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
}) => {

    const intl = useIntl()
    const columns = useMemo(() => [
        { field: 'sellerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'sellerPartsNo' }), width: 220 },
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 280 },
        { field: 'sellerPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'buyerPartsNo' }), width: 220 },
        { field: 'sellerBackNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'backNo' }), width: 180 },
        { field: 'colorCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'colorCode' }), width: 180 },
        { field: 'soNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'salesOrderNo' }), width: 280 },
        { field: 'qty', dataTypeName: 'number', title: intl.formatMessage({ id: 'quantity' }), width: 150 },
        { field: 'notYetDeliveryQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'notYetDeliveryQty' }), width: 280 },
    ], [intl])

    const defaultEditingDisabled = Records.from(columns.filter(f => !f.field.includes('qty')).map(({ field }) => [field, { editingDisabled: true }]))
    const onEditingCellCommit = useCallback((_column: Column, row: Row) => {
        setContainers(containers => {
            const container = containers[containerIndex]
            const packageList = container.outerPackageList ?? []
            const packageInfo = packageList[outerPackageIndex]
            const innerPackageList = packageInfo.innerPackageList
            const innerPackageInfo = innerPackageList[innerPackageIndex]
            const partsList = innerPackageInfo.partsList
            const newPartsList = partsList.map(item => item.soDetailId === row.soDetailId ? row as OutboundPartsInfo : item)
            const newInnerPackageList = innerPackageList.map((m, idx) => idx === innerPackageIndex ? { ...innerPackageInfo, partsList: newPartsList } : m)
            const newPackageInfo = { ...packageInfo, innerPackageList: newInnerPackageList }
            const newContainer = {
                ...container, outerPackageList: packageList.map((m, idx) => idx === outerPackageIndex ? newPackageInfo : m)
            }
            return containers.map((m, index) => index === containerIndex ? newContainer : m)
        })
        return true
    }, [containerIndex, innerPackageIndex, outerPackageIndex, setContainers])

    const actionProps = useMemo(() => ({ containerIndex, outerPackageIndex, innerPackageIndex, setContainers }), [containerIndex, innerPackageIndex, outerPackageIndex, setContainers])

    const getRowId = useCallback((row: any) => row.soDetailId, [])

    return <div style={{ width: '100%', height: 300 }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <DataTypePreset />
            <Data rows={partsList} columns={columns} getRowId={getRowId} />
            <ColumnFreeze />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <RowActionProvider name="delete" Action={DeleteRowAction} actionProps={actionProps} />
            <ColumnVisibility
                defaultHiddenFields={['sellerPartsName', 'sellerBackNo', 'colorCode']}
                columnSettings={{
                    companyCode: { disableUserControl: true },
                    companyName: { disableUserControl: true },
                }}
                ToolbarButton={ColumnVisibilityToolbarButton} />
            <Editing
                enableInlineEdit={true}
                onEditingCellCommit={onEditingCellCommit}
                columnSettings={defaultEditingDisabled}
            />
            <Sorting />
            <Filtering />
            <Action width={80} />
        </DataGrid>
    </div>
})

const DeleteRowAction = ({ tableRow, containerIndex, outerPackageIndex, innerPackageIndex, setContainers }: DataGridRowActionProps & {
    containerIndex: number,
    outerPackageIndex: number,
    innerPackageIndex: number,
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
}) => {

    const deleteAction = useCallback(() => {
        setContainers(containers => {
            const container = containers[containerIndex]
            const packageList = container.outerPackageList ?? []
            const packageInfo = packageList[outerPackageIndex]
            const innerPackageList = packageInfo.innerPackageList
            const innerPackageInfo = innerPackageList[innerPackageIndex]
            const partsList = innerPackageInfo.partsList
            const newPartsList = partsList.filter(item => item.soDetailId !== tableRow.row?.soDetailId)
            const newInnerPackageList = innerPackageList.map((m, idx) => idx === innerPackageIndex ? { ...innerPackageInfo, partsList: newPartsList } : m).filter(ip => !ip.isBlank || (ip.partsList && ip.partsList.length > 0))
            const newPackageInfo = { ...packageInfo, innerPackageList: newInnerPackageList }
            const newContainer = {
                ...container, outerPackageList: packageList.map((m, idx) => idx === outerPackageIndex ? newPackageInfo : m)
            }
            return containers.map((m, index) => index === containerIndex ? newContainer : m)
        })
    }, [containerIndex, innerPackageIndex, outerPackageIndex, setContainers, tableRow.row?.soDetailId])

    return <DeleteCallbackRowAction tableRow={tableRow} callback={deleteAction} />
}


interface SelectPartsActions {
    handleSure?: (innerPartsList: InnerPartsResult[], selections: number[]) => void,
    handleData?: (innerPartsList: InnerPartsResult[]) => InnerPartsResult[]
}

const SelectPartsListView = memo(({ innerPartsList, actions, setActions }: {
    innerPartsList: InnerPartsResult[],
    actions: SelectPartsActions,
    setActions: React.Dispatch<React.SetStateAction<SelectPartsActions>>,
}) => {

    const intl = useIntl()
    const { handleSure, handleData } = actions
    const [selections, setSelections] = useState<number[]>([])
    const handleClose = useCallback(() => {
        setSelections([])
        setActions({})
    }, [setSelections, setActions])
    const getRowId = useCallback((row: any) => row.soDetailId, [])
    const displayList = useMemo(() => handleData ? handleData(innerPartsList) : innerPartsList, [handleData, innerPartsList])
    const open = useMemo(() => handleSure !== undefined, [handleSure])
    const handleOkAction = useMemo(() => () => {
        handleSure && handleSure(innerPartsList, selections)
        setSelections([])
        setActions({})
    }, [handleSure, innerPartsList, selections, setActions])

    const columns = useMemo(() => [
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 180 },
        { field: 'sellerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sellerPartsNo' }), width: 180 },
        { field: 'sellerPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sellerPartsName' }), width: 260 },
        { field: 'soNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'salesOrderNo' }), width: 220 },
        { field: 'notYetDeliveryQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'notYetDeliveryQty' }), width: 180 },
    ], [intl])

    return <Dialog keepMounted={false} maxWidth={"lg"} open={open} onClose={handleClose} >
        <DialogHeader onClose={handleClose}><FormattedMessage id="Inner Parts List" /></DialogHeader>
        <DialogContent style={{ height: 600 }}>
            <DataGrid>
                <ToolbarLayout />
                <TableLayout Container={FlexScrollbar}>
                    <TableHeaderLayout sticky />
                    <TableBodyLayout />
                </TableLayout>
                <PaginationLayout Pagination={Pagination} />
                <DataTypePreset />
                <Data rows={displayList} columns={columns} getRowId={getRowId} />
                <ColumnFreeze />
                <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
                <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
                <Searching ignoreCase Input={SearchInput} />
                <Sorting />
                <Filtering />
                <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
                <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
            </DataGrid>
        </DialogContent>
        <DialogActions>
            <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={handleClose} />
            <DialogAction title={<FormattedMessage id="ok" />} callback={handleOkAction} />
        </DialogActions>
    </Dialog >
})

const PopoverMenu = withStyles(theme => ({
    root: {
    },
    paper: {
        minWidth: 230,
        background: theme.palette.background.popover.main,
        color: theme.palette.getContrastText(theme.palette.background.popover.main),
        borderRadius: theme.spacing(1.5),
    },
}))((props: PopoverProps) => <Popover
    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    {...props}
/>)


const prepareDownloadFromScreen = (data: OutboundCreateFactor, containers: ContainerInfo[], orderDetails: InnerPartsResult[], buyerList: Pair[], receiverList: Pair[], shipper: string) => {
    const downloadList: DownloadOutBoundPartsInfo[] = []
    const receiver = receiverList.find(f => f.first === data.receiver)?.second as string
    const buyer = buyerList.find(f => f.first === data.buyer)?.second as string
    containers.forEach(ct => {
        ct.outerPackageList?.forEach(op => {
            op.innerPackageList.forEach(ip => {
                ip.partsList.forEach(part => {
                    const orderDetail = orderDetails.find(d => d.partsNo === part.partsNo && d.soNo === part.soNo)
                    downloadList.push({
                        shipper: shipper,
                        outboundNo: data.outboundRefNo,
                        outboundDate: data.outboundDate,
                        outboundTime: data.outboundTime ? moment(data.outboundTime).format(moment.HTML5_FMT.TIME_SECONDS) : undefined,
                        bookingNo: data.bookingNo,
                        partsNo: part.partsNo,
                        uomCode: orderDetail?.uomCode,
                        uomDigits: orderDetail?.uomDigits,
                        outboundQty: part.qty,
                        shippingMode: data.shippingMode,
                        outboundType: data.outboundType,
                        receiver: receiver,
                        buyer: buyer,
                        etd: data.etd,
                        eta: data.eta,
                        containerTruckNo: ct.containerNo,
                        containerTruckType: ct.containerType,
                        commodityType: ct.commodityType,
                        sealNo: ct.sealNo,
                        containerM3: ct.m3,
                        containerNetWeight: ct.netWeight,
                        containerGrossWeight: ct.grossWeight,
                        palletNo: op.palletNo,
                        outerPackageNo: op.outerPackageNo,
                        outerPackageType: op.outerPackageType,
                        outerM3: op.m3,
                        outerNetWeight: op.netWeight,
                        outerGrossWeight: op.grossWeight,
                        innerPackageNo: ip.boxNo,
                        innerPackageType: ip.ipPackageType,
                        innerM3: ip.m3,
                        innerNetWeight: ip.netWeight,
                        innerGrossWeight: ip.grossWeight,
                        soNo: part.soNo,
                        sellerCode: part.sellerCode,
                        sellerPartsNo: part.sellerPartsNo,
                        sellerPartsName: part.sellerPartsName,
                        sellerBackNo: part.sellerBackNo,
                        colorCode: part.colorCode,
                        srbq: part.srbq,
                        remainingQty: part.notYetDeliveryQty,
                        productionDate: op.productionDate,
                    })
                })
            })
        })
    })
    return downloadList
}

interface OutboundAccessProps {
    access?: string[],
    power?: boolean,
    children: ReactNode | ReactNode[]
}
const OutboundAccess = ({ access, power, children }: OutboundAccessProps) => {
    const authed = useApplicationSelector(state => {
        const accessResources = state.auth.accessResources ?? []
        const poweruser = state.auth.user?.powerUser ?? false
        let include = false
        for (let value of access ?? []) {
            if (accessResources.includes(value)) {
                include = true
                break
            }
        }
        return include && (!power || poweruser)
    })

    return <>{React.Children.map(children, (child: any) => {
        if (!child || !child.type || (child.type !== Access && child.type !== NoAccess))
            return authed ? child : null
        return React.cloneElement(child, { access })
    })}</>
}

const ContainerPackageGridView = ({ containers, setContainers, containerTypes }: {
    containers: ContainerInfo[],
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
    containerTypes: TnmContainerType[],
}) => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const [containerIndex, setContainerIndex] = useState<number>(0)
    const onSelectedValueChange = useCallback((value: string | number) => {
        setContainerIndex(value as number)
    }, [setContainerIndex])
    const closeCommon = (tabId: number) => {
        if (tabId === (containers.length - 1) && tabId > 0) {
            setContainerIndex(containers.length - 2)
        }
        setContainers(containers => {
            if (containers.length > 1) {
                return containers.filter((_, index) => index !== tabId)
            } else {
                dispatch(applicationActions.pushError({ title: { code: 'remove' }, messages: { code: 'w0362' } }))
                return containers
            }
        })
    }

    return <>
        <ContainerPackagesSummaryView containers={containers} />
        <DottedDivider style={{ marginTop: theme.spacing(4), marginBottom: theme.spacing(4) }} />
        <TabsCard>
        <TabsCardHeader selectedValue={containerIndex} onSelectedValueChange={onSelectedValueChange} >
            {containers.map((m, index) => <CardTab title={'Container ' + (index + 1)} subtitle={m.containerNo} value={index} onClose={closeCommon} />)}
        </TabsCardHeader>
        <TabsCardContent>
            <ContainerInfoView container={containers[containerIndex]} containerIndex={containerIndex} setContainers={setContainers} containerTypes={containerTypes} />
            <GridItem style={{ border: 'none', borderRadius: theme.spacing(1), borderWidth: 'thin', borderColor: '#003361', margin: theme.spacing(2, 0) }}>
                <PackageGridView container={containers[containerIndex]} containerIndex={containerIndex} setContainers={setContainers} />
            </GridItem>
        </TabsCardContent>
    </TabsCard>
    </>
}

interface PackagePartResult {
    lineId: number,

    palletNo?: string,
    outerPackageNo: string,
    outerPackageType?: string,
    productionDate?: Date,
    outboundByPackage: boolean,
    m3Outer?: number
    netWeightOuter?: number
    grossWeightOuter?: number

    soNo: string,
    sellerCode: string,
    sellerPartsNo: string,
    sellerPartsName?: string,
    sellerBackNo?: string,
    colorCode?: string,
    srbq: number,
    partsNo: string,
    soDetailId: number,
    contractRouteId: number,
    qty?: number,
    notYetDeliveryQty?: number,
    uomCode?: string,
    netWeight?: number,
    grossWeight?: number,
    externalOrderNo?: string,
    sellingPrice?: number
    rolledPartsFlag?: number,
    netWeightPart?: number
    grossWeightPart?: number
}

const PackageGridView = ({ container, containerIndex, setContainers }: {
    container: ContainerInfo,
    containerIndex: number,
    setContainers: React.Dispatch<React.SetStateAction<ContainerInfo[]>>,
}) => {
    const [entityList, setEntityList] = useState<PackagePartResult[]>([])
    useMemo(() => {
        let index = 0
        const contPartsList: PackagePartResult[] = []
        container.outerPackageList?.forEach(pkg => {
            pkg.innerPackageList.forEach(box => {
                box.partsList.forEach(part => {
                    contPartsList.push({
                        lineId: index++,
                        ...pkg,
                        ...part,
                        m3Outer: pkg.m3,
                        netWeightOuter: pkg.netWeight,
                        grossWeightOuter: pkg.grossWeight,
                        netWeightPart: part.netWeight,
                        grossWeightPart: part.grossWeight,
                    })
                })
            })
        })
        setEntityList(contPartsList)
    }, [container.outerPackageList])

    const intl = useIntl()
    const outerPacakgeCategories = useMemo(() => [{ key: 'outerPackage', value: intl.formatMessage({ id: 'outerPkgInfo' }) }], [intl])
    const columns = useMemo(() => [
        { field: 'sellerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsNo' }), width: 220 },
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 220 },
        { field: 'sellerPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsName' }), width: 280 },
        { field: 'soNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'salesOrderNo' }), width: 220 },
        { field: 'uomCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'uom' }), width: 100 },
        { field: 'qty', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.qty' }), width: 120 },

        { field: 'palletNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'palletNo' }), categories: outerPacakgeCategories, width: 240 },
        { field: 'outerPackageNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'outerPkgNo' }), categories: outerPacakgeCategories, width: 240 },
        { field: 'outerPackageType', dataTypeName: 'string', title: intl.formatMessage({ id: 'outerPkgType' }), categories: outerPacakgeCategories, width: 200 },
        { field: 'productionDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'productionDate' }), categories: outerPacakgeCategories, width: 200 },
        { field: 'm3Outer', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.m3' }), categories: outerPacakgeCategories, width: 120 },
        { field: 'netWeightOuter', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.netWeight' }), categories: outerPacakgeCategories, width: 120 },
        { field: 'grossWeightOuter', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.grossWeight' }), categories: outerPacakgeCategories, width: 150 },
    ], [intl, outerPacakgeCategories])

    const defaultEditingDisabled = Records.from(columns
        .filter(f => !f.field.includes('qty') 
            && !f.field.includes('m3Outer')
            && !f.field.includes('netWeightOuter')
            && !f.field.includes('grossWeightOuter')
            && !f.field.includes('productionDate'))
        .map(({ field }) => [field, { editingDisabled: true }]))
        
    const containerConverter = useCallback((list: PackagePartResult[], previous: ContainerInfo) => {
        if (list.length === 0) {
            return {}
        }
        
        const outerPackageMap: { [outerPkgNo: string] : OutboundPartsInfo[] } = {}
        const outerPackages: OuterPackageInfo[] = []
        list.forEach(e => {
            outerPackages.push({ 
                palletNo: e.palletNo, 
                outerPackageNo: e.outerPackageNo, 
                outerPackageType: e.outerPackageType, 
                productionDate: e.productionDate, 
                m3: e.m3Outer, 
                netWeight: e.netWeightOuter, 
                grossWeight: e.grossWeightOuter,
                outboundByPackage: e.outboundByPackage,
                innerPackageList: [] 
            })

            if (!outerPackageMap[e.outerPackageNo]) {
                outerPackageMap[e.outerPackageNo] = []
            }
            outerPackageMap[e.outerPackageNo].push({
                soNo: e.soNo,
                sellerCode: e.sellerCode,
                sellerPartsNo: e.sellerPartsNo,
                sellerPartsName: e.sellerPartsName,
                sellerBackNo: e.sellerBackNo,
                colorCode: e.colorCode,
                srbq: e.srbq,
                partsNo: e.partsNo,
                soDetailId: e.soDetailId,
                contractRouteId: e.contractRouteId,
                qty: e.qty,
                notYetDeliveryQty: e.notYetDeliveryQty,
                uomCode: e.uomCode,
                netWeight: e.netWeightPart,
                grossWeight: e.grossWeightPart,
                externalOrderNo: e.externalOrderNo,
                sellingPrice: e.sellingPrice,
                rolledPartsFlag: e.rolledPartsFlag
            })
        })

        outerPackages.forEach(outer => {
            if (outerPackageMap[outer.outerPackageNo]) {
                const inner: InnerPackageInfo = {
                    isBlank: true,
                    partsList: outerPackageMap[outer.outerPackageNo]
                }
                outer.innerPackageList = [inner]
            }
        })

        const newContainer: ContainerInfo = {
            ...previous,
            outerPackageList: outerPackages
        }
        return newContainer
    }, [])

    const onEditingCellCommit = useCallback((_column: Column, row: Row) => {
        setEntityList(previous => {
            const list: PackagePartResult[] = previous.map((item, idx) => item.lineId === row.lineId ? row as PackagePartResult : item)
            setContainers(containers => {
                containers[containerIndex] = containerConverter(list, containers[containerIndex])
                return containers
            })
            return list
        })
        return true
    }, [containerConverter, containerIndex, setContainers])

    return <div style={{ width: '100%', height: 700 }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <Data rows={entityList} columns={columns} />
            <ColumnFreeze />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)}/>
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <ColumnVisibility
                defaultHiddenFields={['sellerPartsName']}
                columnSettings={{
                    companyCode: { disableUserControl: true },
                    companyName: { disableUserControl: true },
                }}
                ToolbarButton={ColumnVisibilityToolbarButton} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Editing
                enableInlineEdit={true}
                onEditingCellCommit={onEditingCellCommit}
                columnSettings={defaultEditingDisabled}
            />
            <Filtering />
            <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        </DataGrid>
    </div>
}