import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { Projects } from "../../../utils/Projects"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { CompanyDetail, companyDetailFromJson, companyDetailToJson } from "../models/CompanyDetail"
import { CompanyListFactor, companyListFactorToJson } from "../models/CompanyListFactor"
import { TnmCompanyTemp, tnmCompanyTempFromJson, tnmCompanyTempToJson } from "../models/TnmCompanyTemp"


export const useGetCompanyList = () => {
    const url = '/lcbm-system-master-api/api/getCompanyList'
    const payloadProject = useCallback(companyListFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(tnmCompanyTempFromJson), [])
    return usePost<CompanyListFactor, PageResultEntity<TnmCompanyTemp>>(url, payloadProject, resultProject)
}


export const useGetPartnerCompanyList = () => {
    const url = '/lcbm-system-master-api/api/getPartnerCompanyList'
    const payloadProject = useCallback(companyListFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(tnmCompanyTempFromJson), [])
    return usePost<CompanyListFactor, PageResultEntity<TnmCompanyTemp>>(url, payloadProject, resultProject)
}


// Query Company Info
export const useGetCompanyDetailForView = () => {
    const url = '/lcbm-system-master-api/api/getCompanyDetailForView'
    // const payloadProject = useCallback((obj: { companyCode: string }) => obj.companyCode, []);
    const payloadProject = useCallback(tnmCompanyTempToJson, [])
    const resultProject = useCallback(companyDetailFromJson, [])
    return usePost<TnmCompanyTemp, CompanyDetail>(url, payloadProject, resultProject)
}
export const useGetCompanyDetailForEdit = () => {
    const url = '/lcbm-system-master-api/api/getCompanyDetailForEdit'
    // const payloadProject = useCallback((obj: { companyCode: string }) => obj.companyCode, []);
    const payloadProject = useCallback(tnmCompanyTempToJson, [])
    const resultProject = useCallback(companyDetailFromJson, [])
    return usePost<TnmCompanyTemp, CompanyDetail>(url, payloadProject, resultProject)
}

// To Temporary DataBase
export const useUpdatedOrInsertCompanyDetail = () => {
    const url = '/lcbm-system-master-api/api/updatedOrInsertCompanyDetail'
    const payloadProject = useCallback(companyDetailToJson, [])
    const resultProject = useCallback(companyDetailFromJson, [])
    return usePost<CompanyDetail, CompanyDetail>(url, payloadProject, resultProject)
}

// To Formal DataBase
export const useSaveAndIssueCompanyInfo = () => {
    const url = '/lcbm-system-master-api/api/saveAndIssueCompanyInfo'
    const payloadProject = useCallback(companyDetailToJson, [])
    const resultProject = Projects.IGNORE
    return usePost<CompanyDetail, undefined>(url, payloadProject, resultProject)
}

export const useIssueCompanyInfo = () => {
    const url = '/lcbm-system-master-api/api/issueCompanyInfo'
    const payloadProject = useCallback((obj: { tempCompanyId: number }) => obj.tempCompanyId, [])
    const resultProject = Projects.IGNORE
    return usePost<{ tempCompanyId: number }, undefined>(url, payloadProject, resultProject)
}

export const useWithdrawCompanyInfo = () => {
    const url = '/lcbm-system-master-api/api/withdrawEditCompany'
    const payloadProject = useCallback((obj: { tempCompanyId: number }) => obj.tempCompanyId, [])
    const resultProject = Projects.IGNORE
    return usePost<{ tempCompanyId: number }, undefined>(url, payloadProject, resultProject)
}
