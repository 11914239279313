import React, { useEffect, useState } from "react"
import { useGetGlobalOartsList } from "../../../services/master/apis/globalPartsApi"
import { ContractRequestType } from "../../../services/master/enums/ContaractRequestType"
import { GlobalPartsResult } from "../../../services/master/models/GlobalPartsResult"
import { OCS021GlobalPartsPcUi } from "./OCS021GlobalPartsPcUi"

interface OCS021GlobalPartsProps {
    requestType: ContractRequestType,
    requestTo?: string,
    selectedPartsList: (number | undefined)[],
    setSelectedList: React.Dispatch<React.SetStateAction<GlobalPartsResult[]>>,
    keyIds?: string,
}

export const OCS021GlobalParts = (props: OCS021GlobalPartsProps) => {
    const { requestType, requestTo, selectedPartsList, setSelectedList, keyIds } = props

    const [partList, setPartList] = useState<GlobalPartsResult[]>([])
    const [selections, setSelections] = useState<number[]>([])

    const getDataList = useGetGlobalOartsList()

    useEffect(() => {
        getDataList({ activeFlagList: [0] }, { silent: true, serialized: true }).then(result => {

            setPartList(result.data ? result.data.filter(parts => !selectedPartsList.includes(parts.partsId)) : [])
        })
    }, [setPartList, keyIds, requestTo, requestType, selectedPartsList, getDataList])


    return <OCS021GlobalPartsPcUi
        requestType={requestType}
        requestTo={requestTo}
        partList={partList}
        selections={selections}
        setSelections={setSelections}
        setSelectedList={setSelectedList}
    />
}