export enum TabId {
    order,
    forecast,
    orderWithoutContract,
    ONS030
}
export interface ViewTabInfo {
    access: string,
    tabId: TabId,
    label: string,
    navigation: string
}

export const ViewTabList: ViewTabInfo[] = [
    {
        access: "ORDER.ONS030ORDER.VIEW",
        tabId: TabId.order,
        label: "orderChange/Cancel",
        navigation: "/cro-order"
    }, {
        access: "ORDER.ONS030FORECAST.VIEW",
        tabId: TabId.forecast,
        label: "forecastChange",
        navigation: "/cro-forecast"
    }
]