//Solve the problem of floating point number addition accuracy
export const addNumber = (n1: number, n2: number) => {
    var s1, s2, s3
    try {
        s1 = n1.toString().split(".")[1].length
    }
    catch (s3) {
        s1 = 0
    }
    try {
        s2 = n2.toString().split(".")[1].length
    }
    catch (s3) {
        s2 = 0
    }
    s3 = Math.pow(10, Math.max(s1, s2))
    return (n1 * s3 + n2 * s3) / s3
}

//Solve the problem of floating point multiplication accuracy
export const mulNumber = (n1: number, n2: number) => {
    var s1 = 0,
        s2 = n1.toString(),
        s3 = n2.toString()
    try {
        s1 += s2.split(".")[1].length
    } catch (f) { }
    try {
        s1 += s3.split(".")[1].length
    } catch (f) { }
    return Number(s2.replace(".", "")) * Number(s3.replace(".", "")) / Math.pow(10, s1)

}

export const subNumber = (n1: number, n2: number) => {
    var s1, s2, s3
    try {
        s1 = n1.toString().split(".")[1].length
    } catch (f) {
        s1 = 0
    }
    try {
        s2 = n2.toString().split(".")[1].length
    } catch (f) {
        s2 = 0
    }
    s3 = Math.pow(10, Math.max(s1, s2))
    return (mulNumber(n1, s3) - mulNumber(n2, s3)) / s3
}

export const divNumber = (n1: number, n2: number) => {
    var s1, s2, s3 = 0,
        f = 0
    try {
        s3 = n1.toString().split(".")[1].length
    } catch (g) { }
    try {
        f = n2.toString().split(".")[1].length
    } catch (g) { }
    s1 = Number(n1.toString().replace(".", ""))
    s2 = Number(n2.toString().replace(".", ""))
    return mulNumber(s1 / s2, Math.pow(10, f - s3))
}

/**
 * check n1 is a multiple of n2
 * 
 * @param n1 
 * @param n2 
 * @returns 
 */
export const isMultiple = (n1: number, n2: number) => {
    var s1 = 0,
        s2 = n1.toString(),
        s3 = n2.toString()
    try {
        s1 += s2.split(".")[1].length
    } catch (f) { }
    try {
        s1 += s3.split(".")[1].length
    } catch (f) { }
    return mulNumber(n1, Math.pow(10, s1)) % mulNumber(n2, Math.pow(10, s1)) === 0 ? true : false
}
