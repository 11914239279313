import { DoneAll } from "@material-ui/icons"
import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Row, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { StringItem } from "@rithe/form"
import { Records } from "@rithe/utils"
import React, { SetStateAction, memo, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useFunctionStore } from "../../../Root"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CallbackRowAction } from "../../../components/DataGrid/rowActions/CallbackRowAction"
import { DetailRedirectRowAction } from "../../../components/DataGrid/rowActions/DetailRedirectRowAction"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { UploadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/UploadGroupedToolbarAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { ActiveFlagTypeProvider } from "../../../components/DataGrid/typeProviders/ActiveFlagTypeProvider"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { useConfirmOrderGroup, useDownloadOrderGroup, useDownloadOrderGroupTemplate, useUploadOrderGroup } from "../../../services/master/apis/orderGroupApi"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { GroupStatus } from "../../../services/master/enums/GroupStatus"
import { ContractFactor } from "../../../services/master/models/ContractFactor"
import { OrderGroupFactor } from "../../../services/master/models/OrderGroupFactor"
import { OrderGroupResult } from "../../../services/master/models/OrderGroupResult"
import { applicationActions } from "../../Application/applicationSlice"

interface ML140PcUiProps {
    path: string,
    filters: OrderGroupFactor,
    search: (filters: OrderGroupFactor) => void,
    data: OrderGroupResult[],
    totalCount: number,
    setFilters: React.Dispatch<SetStateAction<ContractFactor>>,
}

export const MLS140PcUi = (props: ML140PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({ filters, setFilters, search, data, path }: ML140PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])
    const getStatusName = useCallback((row: Row) => {
        const status = row.status as number
        if (status === GroupStatus.DRAFT) {
            return intl.formatMessage({ id: 'draft' })
        }
        if (status === GroupStatus.CONFIRMED) {
            return intl.formatMessage({ id: 'confirmed' })
        }
    }, [intl])

    const columns = useMemo(() => [
        { field: 'grouping', dataTypeName: 'string', title: intl.formatMessage({ id: 'grouping' }), width: 180 },
        { field: 'businessType', dataTypeName: CodeCategory.BusinessType, title: intl.formatMessage({ id: 'field.businessType' }), width: 180 },
        { field: 'orderFrequency', dataTypeName: CodeCategory.OrderFrequency, title: intl.formatMessage({ id: 'field.orderFrequency' }), width: 200 },
        { field: 'forecastNum', dataTypeName: 'string', title: intl.formatMessage({ id: 'forecastNumber' }), width: 180 },
        { field: 'targetLeadtime', dataTypeName: 'string', title: intl.formatMessage({ id: 'targetLeadtime' }), width: 180 },
        { field: 'status', dataTypeName: 'string', title: intl.formatMessage({ id: 'status' }), width: 200, getCellValue: getStatusName },
        { field: 'description', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.description' }), width: 200 },
        { field: 'remark', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.remark' }), width: 200 },
        { field: 'createdBy', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.createdBy' }), width: 200 },
        { field: 'createdDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.createdDate' }), width: 200 },
        { field: 'updatedBy', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.updatedBy' }), width: 200 },
        { field: 'updatedDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.updatedDate' }), width: 200 },
    ], [getStatusName, intl])

    const getRowId = useCallback((row: any) => row.groupId, [])
    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search }), [filters, setFilters, search])
    const actionProps1 = useMemo(() => ({ selections }), [selections])
    const actionProps2 = useMemo(() => ({ search, filters }), [filters, search])
    const displayComplete = useCallback((tableRow: TableRow) => (tableRow.row?.status === GroupStatus.DRAFT), [])
    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.ContractType} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.BusinessType} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OrderFrequency} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.DeliveryType} />
        <ActiveFlagTypeProvider />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps1} />
        <ToolbarActionProvider Action={UploadAction} actionProps={actionProps2} />
        <RowActionProvider name="detail" Action={DetailRowAction} />
        <RowActionProvider name="complete" Action={CompleteRowAction} actionProps={actionProps2} display={displayComplete} />
        <ColumnFreeze />
        <ColumnVisibility ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={88} />
    </DataGrid>
}

const FilterItem = (props: {
    search: (filters: OrderGroupFactor) => void,
    filters: OrderGroupFactor,
    setFilters: React.Dispatch<SetStateAction<OrderGroupFactor>>,
}) => {
    const { filters, setFilters, search } = props
    const clear = useCallback((filters: OrderGroupFactor) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: OrderGroupFactor) => {
        return [
            filters.grouping,
            filters.businessTypeList,
            filters.orderFrequency,
            filters.description,
            filters.remark,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="grouping" label={intl.formatMessage({ id: 'grouping' })} />
        <CodesItem field="businessTypeList" label={intl.formatMessage({ id: 'field.businessType' })} code={CodeCategory.BusinessType} />
        <CodesItem field='orderFrequency' label={intl.formatMessage({ id: 'field.orderFrequency' })} code={CodeCategory.OrderFrequency} />
        <StringItem field="description" label={intl.formatMessage({ id: 'description' })} />
        <StringItem field="remark" label={intl.formatMessage({ id: 'remark' })} />
    </FilterToolbarItem>
}

const DetailRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/orderGroup/detail-${tableRow.rowId}`, [])
    return <DetailRedirectRowAction tableRow={tableRow} access="MARS.MLS140.VIEWDETAIL" path={path} />
}

const DownloadAction = memo(({ selections }: { selections: number[] }) => {
    const dispatch = useDispatch()
    const dowloadApi = useDownloadOrderGroup()
    const downloadTemplate = useDownloadOrderGroupTemplate()
    const download = useCallback(() => {
        if (selections.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'downloadOrderGroup' }, messages: { code: 'w0002' } }))
            return
        }
        dowloadApi({ orderGroupIds: selections })
    }, [dispatch, dowloadApi, selections])

    const callbackDownTemplate = useCallback(() => {
        downloadTemplate({})
    }, [downloadTemplate])
    return <DownloadGroupedToolbarAction access="MARS.MLS140.DOWNLOAD" >
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="downloadOrderGroup" />} callback={download} onClose={onClose} />
            <GroupedCallbackItem label={<FormattedMessage id="downloadTemplate" />} callback={callbackDownTemplate} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
})

const UploadAction = ({ search, filters }: { search: (filters: ContractFactor) => void, filters: ContractFactor }) => {
    const uploadOrderGroupApi = useUploadOrderGroup()
    const uploadOrderGroup = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            files && uploadOrderGroupApi({ file: files[0] }, { serialized: true }).then(() => search(filters))
        })
    }, [filters, search, uploadOrderGroupApi])

    return <UploadGroupedToolbarAction >
        {(popupUpload, onClose) => <>
            <GroupedCallbackItem label={<FormattedMessage id="uploadOrderGroup" />} callback={() => uploadOrderGroup(popupUpload)} onClose={onClose} access="MARS.MLS140.UPLOAD" />
        </>}
    </UploadGroupedToolbarAction>
}


const CompleteRowAction = ({ tableRow, filters, search }: DataGridRowActionProps & { filters: OrderGroupFactor, search: (filters: OrderGroupFactor) => void }) => {
    const orderGroupCompleteApi = useConfirmOrderGroup()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'complete' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)


    const callback = useCallback((tableRow: TableRow) => {
        setDisabled(true)
        const functionId = functionStore.register(() => {
            orderGroupCompleteApi({ orderGroupId: tableRow.rowId as number }, { serialized: true }).then(() => search(filters)).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [dispatch, filters, orderGroupCompleteApi, functionStore, search, title])
    return <CallbackRowAction tableRow={tableRow} title={<FormattedMessage id="complete" />} icon={<DoneAll />} callback={callback} disabled={disabled} access="MARS.MLS140.COMPLETE"/>
}
