import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson } from "../../utils/PageViewEntity"

export type CargoTackingResults = Required & Partial<Optional>

type CargoTackingResultsJson = JsonRequired & Partial<JsonOptional>

export const cargoTackingResultsFromJson: Project<CargoTackingResultsJson, CargoTackingResults> = json => ({
    ...json,
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page),
})

interface Required extends JsonRequired { }

interface Optional extends Omit<JsonOptional, 'eventList' | 'page'> {

    eventList: EventResult[]
    page: PageViewEntity,
}

interface JsonRequired {
}

interface JsonOptional {
    bookMarkId: number,
    externalBookmarkId:number,
    containerNo: string,
    commodityType: string,
    containerType: string,
    carrierNo: string,
    vessels: {
        vesselName: string,
        voyageNo: string
    }[],
    etd: string,
    eta: string,
    ata: string,
    scheduledEta: string,
    initialScheduledEta: string,
    lastEvent: string,
    lastEventDate: string,
    actualDischargeLt: string,
    predictedDischarge: string,
    scheduledDischarge: string,
    actualLoadingLt: string,
    predictedLoadingLt: string,
    scheduledLoadingLt: string,
    pol: string,
    pot: string,
    pod: string,
    status: string,
    eventList: EventResultJson[],
    blNo: string,
    actionType: number,
    page: PageViewEntityJson,
}

export interface EventResult extends EventResultJson {

}

interface EventResultJson {
    bookMarkId: number,
    containerNo: string,
    eventRaw: string,
    eventTime: string,
    eventTimeEstimated: string,
    eventTypeCode: string,
    eventTypeName: string,
    locationRaw: string,
    locationTypeCode: string,
    locationTypeName: string,
    portCode: string,
    portName: string,
}

