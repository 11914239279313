import { IconButton, Tooltip } from "@material-ui/core"
import { Refresh } from "@material-ui/icons"
import React from "react"
import { FormattedMessage } from "react-intl"
import { Access } from "../../Access/Access"

interface RefreshToolbarItemProps {
    access?: string,
    refresh?: () => void,
}

export function RefreshToolbarItem(props: RefreshToolbarItemProps) {
    const { access, refresh } = props

    const action = <Tooltip title={<FormattedMessage id="refresh" />}>
        <IconButton onClick={refresh}><Refresh /></IconButton>
    </Tooltip>
    return access ? <Access access={access}>{action}</Access> : action
}