import { Button, makeStyles } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { useMeasure, useMixed } from "@rithe/utils";
import React, { MouseEvent, ReactNode, useCallback, useLayoutEffect, useRef, useState } from "react";
import { useTabContext } from "../View/Tab/TabContext";

interface TabsCardHeaderProps {
    selectedValue?: any,
    defaultSelectedValue?: any,
    onSelectedValueChange?: (value: any) => void,
    actions?: ReactNode[],
    children?: ReactNode | ReactNode[],
}

export function TabsCardHeader(props: TabsCardHeaderProps) {
    const { actions, children } = props
    const [selectedValue, setSelectedValue] = useMixed(props.selectedValue, props.onSelectedValueChange, props.defaultSelectedValue, '')

    const { setSelectedValue: setTabId } = useTabContext()
    useLayoutEffect(() => {
        setTabId(selectedValue)
    }, [selectedValue, setTabId])

    const [refTrack, trackRect] = useMeasure<HTMLDivElement>()
    const [refTabs, tabsRect] = useMeasure<HTMLDivElement>()
    const [left, setLeft] = useState(0)
    const [transition, setTransition] = useState('')
    const minLeft = Math.min(0, (trackRect?.width ?? 0) - (tabsRect?.width ?? 0))

    const moveLeft = useCallback(() => {
        setLeft(x => {
            return x >= -150 ? 0 : Math.min(0, x + 100)
        })
    }, [])
    const moveRight = useCallback(() => {
        setLeft(x => {
            return x - minLeft <= 150 ? minLeft : Math.max(minLeft, x - 100)
        })
    }, [minLeft])
    const [leftMouseDown, leftMouseUp, leftMouseEnter, leftMouseLeave] = useMoveControl(moveLeft, transition, setTransition)
    const [rightMouseDown, rightMouseUp, rightMouseEnter, rightMouseLeave] = useMoveControl(moveRight, transition, setTransition)

    const showMoveLeft = (minLeft < 0 && left < 0) || (minLeft >= 0 && left < 0)
    const showMoveRight = minLeft < 0 && left > minLeft

    const styles = useStyles()
    return <div className={styles.root}>
        <div ref={refTrack} className={styles.track}>
            <div ref={refTabs} className={styles.tabs} style={{ left: left, transition }}>
                {React.Children.map(children, (child: any, index) => {
                    if (!child || !child.type) return child
                    return React.cloneElement(child, {
                        key: index,
                        onClick: (value: any) => setSelectedValue(value),
                    })
                })}
            </div>
        </div>
        <div className={styles.actions}>
            <Button color="primary"
                onMouseDown={leftMouseDown}
                onMouseUp={leftMouseUp}
                onMouseEnter={leftMouseEnter}
                onMouseLeave={leftMouseLeave}
                className={showMoveLeft ? undefined : styles.disabled}
            >
                <ChevronLeft />
            </Button>
            <Button color="primary"
                onMouseDown={rightMouseDown}
                onMouseUp={rightMouseUp}
                onMouseEnter={rightMouseEnter}
                onMouseLeave={rightMouseLeave}
                className={showMoveRight ? undefined : styles.disabled}
            >
                <ChevronRight />
            </Button>
            {actions}
        </div>
    </div>
}

const DELAY = 1
const TRANSITION_DURATION = 250
const SINGLE_TRANSITION = `left ${TRANSITION_DURATION}ms ease-in-out`
const START_TRANSITION = `left ${TRANSITION_DURATION}ms ease-in`
const MIDDLE_TRANSITION = `left ${TRANSITION_DURATION}ms linear`
const END_TRANSITION = `left ${TRANSITION_DURATION}ms ease-out`

const useMoveControl = (move: () => void, transition: string, setTransition: (transition: string) => void) => {
    const intervalIdRef = useRef<any>(0)
    const delayRef = useRef(DELAY)
    const moveStart = useCallback(() => {
        setTransition(SINGLE_TRANSITION)
        move()
        intervalIdRef.current = setInterval(() => {
            if (delayRef.current === 0) {
                setTransition(START_TRANSITION)
                move()
            } else if (delayRef.current < 0) {
                setTransition(MIDDLE_TRANSITION)
                move()
            }
            delayRef.current--
        }, TRANSITION_DURATION / 2)
    }, [setTransition, move])
    const moveEnd = useCallback(() => {
        clearInterval(intervalIdRef.current)
        transition !== SINGLE_TRANSITION && setTransition(END_TRANSITION)
        intervalIdRef.current = 0
        delayRef.current = DELAY
    }, [setTransition, transition])
    const onMouseDown = moveStart
    const onMouseUp = moveEnd
    const onMouseEnter = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        e.buttons === 1 && moveStart()
    }, [moveStart])
    const onMouseLeave = moveEnd
    return [onMouseDown, onMouseUp, onMouseEnter, onMouseLeave]
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: 0,
        flex: '0 0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'stretch',
    },
    track: {
        flex: '1 1 auto',
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        height: 52,
        backgroundColor: theme.palette.background.card.inactive,
    },
    tabs: {
        whiteSpace: 'nowrap',
        width: 'fit-content',
        position: 'relative',
        transition: `left ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeIn}`,
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
    },
    actions: {
        flex: '0 0 auto',
        padding: theme.spacing(1, 2, 1, 1),
        '&>*': {
            marginLeft: theme.spacing(1),
        }
    },
    disabled: {
        color: theme.palette.action.disabled,
    },
}))