import { Project } from "../../../utils/Project"

export type InitOutboundResult = Partial<Optional>
type InitOutboundResultJson = Partial<JsonOptional>
export const initOutboundResultFromJson: Project<InitOutboundResultJson, InitOutboundResult> = json => ({
    supplierCodeList: json.supplierCodeList?.map(m => [m.first, m.second]),
    receiverList: json.receiverList?.map(m => [m.first, m.second]),
    customerCodeList: json.customerCodeList?.map(m => [m.first, m.second]),
})
interface Optional {
    supplierCodeList: [number, string][]
    receiverList: [string, string][]
    customerCodeList: [number, string][]
}

interface JsonOptional {
    supplierCodeList: { first: number, second: string }[]
    receiverList: { first: string, second: string }[]
    customerCodeList: { first: number, second: string }[]
}