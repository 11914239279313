import React, { useCallback, useMemo, useState } from "react"
import { useGetCoListSpotWithContractByPage } from "../../../services/order/apis/CustomerOrderApi"
import { CoListFactor, coListFactorFactorFromJson, coListFactorFactorToJson } from "../../../services/order/models/CoListFactor"
import { CoListResult } from "../../../services/order/models/CoListResult"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { OCS030PcUi } from "./OCS030PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }
const searchCachekey = sessionKeys.Filters_OCS030

export const OCS030 = () => {

    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? coListFactorFactorFromJson(JSON.parse(filters)) : null
    }, [])


    const [filters, setFilters] = useState<CoListFactor>(cacheFilters ?? defaultFilters)

    const [coList, setCoList] = useState<CoListResult[]>([])

    const getCoList = useGetCoListSpotWithContractByPage()

    const search = useCallback(() => {
        getCoList(filters, { silent: true, serialized: true }).then(result => {
            setCoList(result.data ?? [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(coListFactorFactorToJson(filters)))
        })
    }, [getCoList, filters])

    useSearchOnLoad(search, filters)

    return <OCS030PcUi
        search={search}
        coList={coList}
        setCoList={setCoList}
        filters={filters}
        setFilters={setFilters}
    />
}

