import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"

export type PendingOutboundRollPackage = Required & Partial<Optional>

type PendingOutboundRollPackageJson = JsonRequired & Partial<JsonOptional>

export const pendingOutboundRollPackageFromJson: Project<PendingOutboundRollPackageJson, PendingOutboundRollPackage> = json => ({
    ...json,
    productionDate: dateFromJson(json.productionDate),
})

interface Required extends JsonRequired {}

interface Optional extends Omit<JsonOptional, 'productionDate'> {
    productionDate: Date,
}

interface JsonRequired {
}

interface JsonOptional {
    inbPkgId: number,
    palletNo: string,
    outerPackageNo: string,
    outerPackageType?: string,
    soDetailOrigin: string,
    number: string,
    productionDate?: string,
    m3Outer?: number,
    netWeightOuter?: number,
    grossWeightOuter?: number,
    stockDays: number,
    sellerPartsNo: string,
    qty: number,
    soNo: string,
    originalOutboundRefNo?: string
}