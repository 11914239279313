import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { dateFromJson } from "../../utils/deserializer"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"
import { OrderType } from "../enums/OrderType"
import { SoStatus } from "../enums/SoStatus"

export type SoMonitoringListFactor = Partial<Optional>
export type SoMonitoringListFactorJson = Partial<JsonOptional>
export const soMonitoringListFactorToJson: Project<SoMonitoringListFactor, SoMonitoringListFactorJson> = obj => ({
    ...obj,
    soDateStart: dateToJson(obj.soDateStart),
    soDateEnd: dateToJson(obj.soDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page)
})

export const soMonitoringListFactorFromJson: Project<SoMonitoringListFactorJson, SoMonitoringListFactor> = json => ({
    ...json,
    soDateStart: dateFromJson(json.soDateStart),
    soDateEnd: dateFromJson(json.soDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page),

})

interface Optional extends Omit<JsonOptional, 'page' | 'soDateStart' | 'soDateEnd'> {
    orderTypeList: OrderType[],
    statusList: SoStatus[],
    soDateStart: Date,
    soDateEnd: Date,
    page: PageViewEntity,
}


interface JsonOptional {
    soNo: string,  // orderNo
    orderTypeList: number[],
    statusList: number[],
    soDateStart: string,
    soDateEnd: string,
    page: PageViewEntityJson
}
