import { DialogActions, DialogContent } from "@material-ui/core"
import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { EntriesItem, Form, StringItem } from "@rithe/form"
import { Objects, Records } from "@rithe/utils"
import React, { SetStateAction, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CancelCallbackRowAction } from "../../../components/DataGrid/rowActions/CancelCallbackRowAction"
import { EditRedirectRowAction } from "../../../components/DataGrid/rowActions/EditRedirectRowAction"
import { ViewRedirectRowAction } from "../../../components/DataGrid/rowActions/ViewRedirectRowAction"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { UploadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/UploadGroupedToolbarAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { ActiveFlagTypeProvider } from "../../../components/DataGrid/typeProviders/ActiveFlagTypeProvider"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { DarkDialog } from "../../../components/Dialog/DarkDialog"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { useFunctionStore } from "../../../Root"
import { useTerminateContract } from "../../../services/master/apis/contractApi"
import {
    useDownloadContractMasterByContractId, useDownloadContractMasterByFilter, useDownloadContractPartsMasterByContractId, useDownloadContractPartsMasterByFilter,
    useDownloadContractSplitMasterByContractId, useDownloadContractSplitMasterByFilter
} from "../../../services/master/apis/masterDownloadApi"
import { useUploadMasterContractMaster, useUploadMasterContractSplitMaster } from "../../../services/master/apis/masterUploadApi"
import { ActiveFlag } from "../../../services/master/enums/ActiveFlag"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { ContractFactor } from "../../../services/master/models/ContractFactor"
import { ContractPeopleInfoResult } from "../../../services/master/models/ContractPeopleInfoResult"
import { ContractResult } from "../../../services/master/models/ContractResult"
import { useGetCompanyUid } from "../../../utils/ApplicationUtils"
import { isNotEmpty } from "../../../utils/ValidatorUtils"
import { applicationActions } from "../../Application/applicationSlice"

interface MLS090PcUiProps {
    path: string,
    filters: ContractFactor,
    search: (filters: ContractFactor) => void,
    data: ContractResult[],
    totalCount: number,
    partners: ContractPeopleInfoResult,
    setFilters: React.Dispatch<SetStateAction<ContractFactor>>,
}

export const MLS090PcUi = (props: MLS090PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({ filters, setFilters, partners, search, data, path }: MLS090PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])
    const companyUid = useGetCompanyUid()

    const columns = useMemo(() => [
        { field: 'contractNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contractNo' }), width: 180 },
        { field: 'contractShortCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contractShortCode' }), width: 180 },
        { field: 'contractType', dataTypeName: CodeCategory.ContractType, title: intl.formatMessage({ id: 'field.contractType' }), width: 180 },
        { field: 'description', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.description' }), width: 150 },
        { field: 'sellerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'sellerCode' }), width: 150 },
        { field: 'buyerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'buyerCode' }), width: 180 },
        { field: 'businessType', dataTypeName: CodeCategory.BusinessType, title: intl.formatMessage({ id: 'field.businessType' }), width: 180 },

        { field: 'orderFrequency', dataTypeName: CodeCategory.OrderFrequency, title: intl.formatMessage({ id: 'field.orderFrequency' }), width: 200 },

        { field: 'paymentTermsCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.paymentTermsCode' }), width: 230 },
        { field: 'consigneeCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.consignee' }), width: 200 },
        { field: 'accounteeCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.accountee' }), width: 200 },
        { field: 'deliveryToCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'deliveryToCode' }), width: 200 },
        { field: 'status', dataTypeName: CodeCategory.ActiveFlag, title: intl.formatMessage({ id: 'field.status' }), width: 90 },
        { field: 'createdDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.createdDate' }), width: 180 },
        { field: 'updatedDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.updatedDate' }), width: 180 },
        { field: 'confirmOrderLeadtime', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.confirmOrderLeadtime' }), width: 300 },
        { field: 'leadtime', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.leadtime' }), width: 200 },
        { field: 'deliveryPlanStartDate', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.deliveryPlanStartDate' }), width: 350 },
        { field: 'deliveryPlanEndDate', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.deliveryPlanEndDate' }), width: 350 },
    ], [intl])
    const getRowId = useCallback((row: any) => row.contractId, [])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search, partners }), [filters, setFilters, search, partners])
    const actionProps1 = useMemo(() => ({ search, filters }), [filters, search])
    const actionProps2 = useMemo(() => ({ selections, filters }), [filters, selections])
    const ViewOrEditProps = useMemo(() => ({ path }), [path])
    const TerminateProps = useMemo(() => ({ filters, search }), [filters, search])
    const displayEdit = useCallback((tableRow: TableRow) => tableRow.row?.status === ActiveFlag.ACTIVE && tableRow.row?.sellerUid === companyUid, [companyUid])
    const displayTerminate = useCallback((tableRow: TableRow) => tableRow.row?.status === ActiveFlag.ACTIVE && tableRow.row?.buyerUid === companyUid, [companyUid])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.ContractType} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.BusinessType} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OrderFrequency} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.DeliveryType} />
        <ActiveFlagTypeProvider />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={UploadAction} actionProps={actionProps1} />
        <ToolbarActionProvider Action={DownloadContractAction} actionProps={actionProps2} />
        <RowActionProvider name="view" Action={ViewRowAction} actionProps={ViewOrEditProps} />
        <RowActionProvider name="edit" Action={EditRowAction} display={displayEdit} actionProps={ViewOrEditProps} />
        <RowActionProvider name="terminate" Action={TerminateRowAction} display={displayTerminate} actionProps={TerminateProps} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['consigneeCode', 'accounteeCode', 'deliveryTo', 'status', 'confirmOrderLeadtime', 'leadtime', 'deliveryPlanStartDate', 'deliveryPlanEndDate', 'deliveryTypeFlag', 'createdDate', 'updatedDate']}
            columnSettings={{
                contractNo: { disableUserControl: true },
                contractShortCode: { disableUserControl: true },
                sellerCode: { disableUserControl: true },
                buyerCode: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={88} />
    </DataGrid>
}

const FilterItem = (props: {
    search: (filters: ContractFactor) => void,
    filters: ContractFactor,
    setFilters: React.Dispatch<SetStateAction<ContractFactor>>,
    partners: ContractPeopleInfoResult
}) => {
    const { filters, partners, setFilters, search } = props
    const sellerCodeEntries: [string, string][] = useMemo(() => (partners.sellerList ?? []).map(m => [m.uid, m.code]), [partners])
    const buyerCodeEntries: [string, string][] = useMemo(() => (partners.buyerList ?? []).map(m => [m.uid, m.code]), [partners])
    const consigneeEntries: [string, string][] = useMemo(() => (partners.consigneeList ?? []).map(m => [m.uid, m.code]), [partners])
    const accounteeEntries: [string, string][] = useMemo(() => (partners.accounteeList ?? []).map(m => [m.uid, m.code]), [partners])
    const deliveryToEntries: [string, string][] = useMemo(() => (partners.deliveryToList ?? []).map(m => [m.uid, m.code]), [partners])
    // const receiverCodeEntries: [string, string][] = useMemo(() => Arrays.distinct(unitList.filter(f => f.receiverCode).map(m => m.receiverCode!).map(c => [c, c])), [unitList]);

    const clear = useCallback((filters: ContractFactor) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: ContractFactor) => {
        return [
            filters.contractNo,
            filters.description,
            filters.sellerList,
            filters.buyerList,
            filters.businessTypeList,
            filters.consigneeList,
            filters.accounteeList,
            filters.deliveryToList,
            filters.receiverList,
            filters.statusList,
            filters.today,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="contractNo" label={intl.formatMessage({ id: 'field.contractNo' })} />
        <StringItem field="description" label={intl.formatMessage({ id: 'field.description' })} />
        <EntriesItem field="sellerList" label={intl.formatMessage({ id: 'sellerCode' })} entries={sellerCodeEntries} />
        <EntriesItem field="buyerList" label={intl.formatMessage({ id: 'buyerCode' })} entries={buyerCodeEntries} />
        <CodesItem field="businessTypeList" label={intl.formatMessage({ id: 'field.businessType' })} code={CodeCategory.BusinessType} />
        <EntriesItem field="consigneeList" label={intl.formatMessage({ id: 'consigneeCode' })} entries={consigneeEntries} />
        <EntriesItem field="accounteeList" label={intl.formatMessage({ id: 'accounteeCode' })} entries={accounteeEntries} />
        <EntriesItem field="deliveryToList" label={intl.formatMessage({ id: 'deliveryToCode' })} entries={deliveryToEntries} />
        {/* <EntriesItem field="receiverList" label={intl.formatMessage({ id: 'receiverCode' })} entries={receiverCodeEntries} /> */}
        <CodesItem field="statusList" label={intl.formatMessage({ id: 'field.status' })} code={CodeCategory.ActiveFlag} />
        {/* <StringItem field="today" label={intl.formatMessage({ id: 'today' })} /> */}
    </FilterToolbarItem>
}



const UploadAction = ({ search, filters }: { search: (filters: ContractFactor) => void, filters: ContractFactor }) => {
    const uploadMasterApi = useUploadMasterContractMaster()
    const uploadSplitApi = useUploadMasterContractSplitMaster()
    const uploadMaster = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            files && uploadMasterApi({ file: files[0] }, { serialized: true }).then(() => search(filters))
        })
    }, [filters, search, uploadMasterApi])
    const uploadSplit = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            files && uploadSplitApi({ file: files[0] }, { serialized: true }).then(() => search(filters))
        })
    }, [filters, search, uploadSplitApi])
    return <UploadGroupedToolbarAction access="MARS.MLS090.UPLOAD">
        {(popupUpload, onClose) => <>
            <GroupedCallbackItem label={<FormattedMessage id="uploadContract" />} callback={() => uploadMaster(popupUpload)} onClose={onClose} />
            <GroupedCallbackItem label={<FormattedMessage id="UploadContractByType" />} callback={() => uploadSplit(popupUpload)} onClose={onClose} />
        </>}
    </UploadGroupedToolbarAction>
}

export const DownloadContractAction = ({ selections, filters }: { selections: number[], filters: ContractFactor }) => {
    const downloadMasterBySelectionApi = useDownloadContractMasterByContractId()
    const downloadMasterByFilterApi = useDownloadContractMasterByFilter()
    const downloadPartsBySelectionApi = useDownloadContractPartsMasterByContractId()
    const downloadPartsByFilterApi = useDownloadContractPartsMasterByFilter()
    const downloadSplitBySelectionApi = useDownloadContractSplitMasterByContractId()
    const downloadSplitByFilterApi = useDownloadContractSplitMasterByFilter()
    const downloadMaster = useCallback(() => {
        selections.length <= 0 ? downloadMasterByFilterApi(Objects.delete(filters, 'page')) : downloadMasterBySelectionApi({ contractIdList: selections })
    }, [downloadMasterByFilterApi, downloadMasterBySelectionApi, filters, selections])
    const downloadParts = useCallback(() => {
        selections.length <= 0 ? downloadPartsByFilterApi(Objects.delete(filters, 'page')) : downloadPartsBySelectionApi({ contractIdList: selections })
    }, [downloadPartsByFilterApi, downloadPartsBySelectionApi, filters, selections])
    const downloadSplit = useCallback(() => {
        selections.length <= 0 ? downloadSplitByFilterApi(Objects.delete(filters, 'page')) : downloadSplitBySelectionApi({ contractIdList: selections })
    }, [downloadSplitByFilterApi, downloadSplitBySelectionApi, filters, selections])
    return <DownloadGroupedToolbarAction access="MARS.MLS090.DOWNLOAD">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="downloadContractMaster" />} callback={downloadMaster} onClose={onClose} />
            <GroupedCallbackItem label={<FormattedMessage id="downloadParts" />} callback={downloadParts} onClose={onClose} />
            <GroupedCallbackItem label={<FormattedMessage id="downloadSplit" />} callback={downloadSplit} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}

const ViewRowAction = ({ tableRow, path }: DataGridRowActionProps & { path: string }) => {
    const toPath = useCallback((tableRow: TableRow) => `${path}/view-${tableRow.rowId}`, [path])
    return <ViewRedirectRowAction tableRow={tableRow} access="MARS.MLS090.VIEWDETAIL" path={toPath} />
}

const EditRowAction = ({ tableRow, path }: DataGridRowActionProps & { path: string }) => {
    const toPath = useCallback((tableRow: TableRow) => `${path}/edit-${tableRow.rowId}`, [path])
    return <EditRedirectRowAction tableRow={tableRow} access="MARS.MLS090.EDIT" path={toPath} />
}

const TerminateRowAction = ({ tableRow, filters, search }: DataGridRowActionProps & { filters: ContractFactor, search: (filters: ContractFactor) => void }) => {
    const [open, setOpen] = useState<boolean>(false)
    const [factor, setFactor] = useState<{ reason?: string }>({})
    const intl = useIntl()
    const dispatch = useDispatch()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'terminate' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const terminateContract = useTerminateContract()
    const callback = useCallback(() => {

        // do check
        if (!isNotEmpty(factor.reason)) {
            dispatch(applicationActions.pushError({
                title: title,
                messages: { code: 'w0001', args: [intl.formatMessage({ id: 'terminateReason' })] },
            }))
            return
        } else if ((factor.reason ?? '').length > 255) {
            dispatch(applicationActions.pushError({
                title: title,
                messages: { code: 'w0019', args: [intl.formatMessage({ id: 'terminateReason' }), 255] },
            }))
            return
        }

        const functionId = functionStore.register(() => {
            setDisabled(true)
            terminateContract({ contractId: tableRow?.row?.contractId, reason: factor.reason ?? '' }, { serialized: true }).then(() => {
                setOpen(false)
                setFactor({})
                search(filters)
            }).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [dispatch, factor.reason, filters, functionStore, intl, search, tableRow?.row?.contractId, terminateContract, title])

    // access="MARS.MLS090.TERMINATE" TODO

    return <>
        <CancelCallbackRowAction title={intl.formatMessage({ id: 'terminate' })} tableRow={tableRow} callback={() => { setFactor({}); setOpen(true) }} />
        <DarkDialog open={open} style={{ overflow: 'hidden' }} maxWidth="sm" fullWidth fullScreen={false}>
            <DialogHeader onClose={() => setOpen(false)}><FormattedMessage id="confirm" /></DialogHeader>
            <DialogContent>
                <Form data={factor} setData={setFactor} labelDisplay="block" helperDisplay="tooltip" columnCount={1} minWidth={500}>
                    <StringItem required field="reason" rowSpan={3} label={intl.formatMessage({ id: 'terminateReason' })} multiline />
                </Form>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={() => { setOpen(false) }} />
                <DialogAction title={<FormattedMessage id="terminate" />} callback={callback} disabled={disabled} />
            </DialogActions>
        </DarkDialog>
    </>
}