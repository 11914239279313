import { useCallback } from "react"
import { Projects } from "../../../utils/Projects"
import { usePost } from "../../../utils/fetch/useFetch"
import { CreditLimitResult, creditLimitResultFromJson } from "../../order/models/CreditLimitResult"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { OrderDetailResult, OrderDetailResultDeserializer } from "../models/OrderDetailResult"
import { OrderForecastDetailResult, OrderForecastDetailResultDeserializer } from "../models/OrderForecastDetailResult"
import { OrderListResult, orderListResultFromJson } from "../models/OrderListResult"
import { SalesOrderListFactor, salesOrderListFactorToJson } from "../models/SalesOrderListFactor"
import { SoListResult, soListResultFromJson } from "../models/SoListResult"
import { SoMonitoringListFactor, soMonitoringListFactorToJson } from "../models/SoMonitoringListFactor"

export const useGetSoListByPage = () => {
    const url = '/lcbm-order-api/order/so/api/getSoListByPage'
    const payloadProject = useCallback(salesOrderListFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(orderListResultFromJson), [])
    return usePost<SalesOrderListFactor, PageResultEntity<OrderListResult>>(url, payloadProject, resultProject)
}

// OSS020
export const useGetSoMonitoringListByPage = () => {
    const url = '/lcbm-spot-order-api/order/so/api/soMonitoringPageList'
    const payloadProject = useCallback(soMonitoringListFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(soListResultFromJson), [])
    return usePost<SoMonitoringListFactor, PageResultEntity<SoListResult>>(url, payloadProject, resultProject)
}

// OSS021
export const useGetSoMonitroingDetail = () => {
    const url = '/lcbm-spot-order-api/order/so/api/getSoHeaderAndPartsData'
    const payloadProject = useCallback((obj: { soSpotId: number }) => obj.soSpotId, [])
    const resultProject = useCallback(OrderDetailResultDeserializer, [])
    return usePost<{ soSpotId: number }, OrderDetailResult>(url, payloadProject, resultProject)
}

export const useForceComplete = () => {
    const url = '/lcbm-order-api/order/so/api/forceComplete'
    const payloadProject = useCallback((obj: { orderIdList: number[] }) => obj.orderIdList, [])
    return usePost<{ orderIdList: number[] }, undefined>(url, payloadProject, Projects.IGNORE)
}

export const useGetSoDetail = () => {
    const url = '/lcbm-order-api/order/so/api/getSoDetail'
    const payloadProject = useCallback((obj: { orderId: number }) => obj.orderId, [])
    const resultProject = useCallback(OrderDetailResultDeserializer, [])
    return usePost<{ orderId: number }, OrderDetailResult>(url, payloadProject, resultProject)
}

export const useConfirmSupplierSo = () => {
    const url = '/lcbm-order-api/order/so/api/confirmSupplierSo'
    const payloadProject = useCallback((obj: { orderId: number }) => obj.orderId, [])
    const resultProject = Projects.IGNORE
    return usePost<{ orderId: number }, undefined>(url, payloadProject, resultProject)
}
// ======= OSS012
export const useGetSoForecast = () => {
    const url = '/lcbm-order-api/order/so/api/getSoForecast'
    const payloadProject = useCallback((obj: { orderId: number }) => obj.orderId, [])
    const resultProject = useCallback(OrderForecastDetailResultDeserializer, [])
    return usePost<{ orderId: number }, OrderForecastDetailResult>(url, payloadProject, resultProject)
}

export const useGetOrderCreditCheck = () => {
    const url = '/lcbm-order-api/order/so/api/getOrderCreditCheck'
    const payloadProject = useCallback((obj: { soId: number, creditCheckFlag: number }) => obj, [])
    const resultProject = useCallback(creditLimitResultFromJson, [])
    return usePost<{ soId: number, creditCheckFlag: number }, CreditLimitResult>(url, payloadProject, resultProject)
}

export const useRefreshSoDetailParts = () => {
    const url = '/lcbm-order-api/order/so/api/refreshSoDetailParts'
    const payloadProject = useCallback((obj: { orderId: number, groupId: number }) => ({ ...obj }), [])
    const resultProject = Projects.IGNORE
    return usePost<{ orderId: number, groupId: number }, undefined>(url, payloadProject, resultProject)
}

export const useCancelBuOrder = () => {
    const url = '/lcbm-order-api/order/so/api/cancelBuOrder'
    const payloadProject = useCallback((obj: { poIdList: number[] }) => obj.poIdList, [])
    const resultProject = Projects.IGNORE
    return usePost<{ poIdList: number[] }, undefined>(url, payloadProject, resultProject)
}
