import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, RowActionProvider, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { Records } from "@rithe/utils"
import { memo, useCallback, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Access } from "../../../components/Access/Access"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CancelCallbackRowAction } from "../../../components/DataGrid/rowActions/CancelCallbackRowAction"
import { CreateRedirectRowAction } from "../../../components/DataGrid/rowActions/CreateRedirectRowAction"
import { DeleteCallbackRowAction } from "../../../components/DataGrid/rowActions/DeleteCallbackRowAction"
import { EditRedirectRowAction } from "../../../components/DataGrid/rowActions/EditRedirectRowAction"
import { IssueCallbackRowAction } from "../../../components/DataGrid/rowActions/IssueCallbackRowAction"
import { ViewRedirectRowAction } from "../../../components/DataGrid/rowActions/ViewRedirectRowAction"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { ViewPanel } from "../../../components/View/Tab/ViewPanel"
import { ViewPanels } from "../../../components/View/Tab/ViewPanels"
import { ViewTab } from "../../../components/View/Tab/ViewTab"
import { ViewTabControl } from "../../../components/View/Tab/ViewTabControl"
import { ViewTabs } from "../../../components/View/Tab/ViewTabs"
import { View } from "../../../components/View/View"
import { useFunctionStore } from "../../../Root"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { useDeleteRevisedCustomerOrder, useIssueRevisedCustomerOrder } from "../../../services/order/apis/ChangeRequestApi"
import { useCancelOrder } from "../../../services/order/apis/PurchaseOrderApi"
import { RequestType } from "../../../services/order/enums/RequestType"
import { RoStatus } from "../../../services/order/enums/RoStatus"
import type { BUChangeRequestListResult } from "../../../services/order/models/BUChangeRequestListResult"
import { formatDateRange } from "../../../utils/formatDateRange"
import { applicationActions, useApplicationSelector } from "../../Application/applicationSlice"
import { TabId, ViewTabList } from "./Records"

interface ONS040PcUiProps {
    tabId: number,
    orderChangedableList: BUChangeRequestListResult[],
    searchOrderChangedableList: () => void,
    fcChangedableList: BUChangeRequestListResult[],
    searchFcChangedableList: () => void
}

export const ONS040PcUi = (props: ONS040PcUiProps) => {
    const { orderChangedableList, searchOrderChangedableList, fcChangedableList, searchFcChangedableList, tabId } = props
    const navigate = useNavigate()
    const intl = useIntl()
    const selectedChange = useCallback((value: string | number) => {
        navigate(prepareUrlbyTabId(value))
    }, [navigate])
    const resources = useApplicationSelector(state => state.auth.accessResources)
    const activeViewTabList = ViewTabList.filter(f => resources?.includes(f.access))

    if (tabId === TabId.ONS040) {
        navigate(activeViewTabList[0].navigation)
        return <></>
    }
    return <View flex>
        <ViewTabControl>
            <ViewTabs selectedValue={tabId} onSelectedValueChange={selectedChange}>
                {activeViewTabList.map(activeViewTab => {
                    return <ViewTab key={activeViewTab.tabId} label={intl.formatMessage({ id: activeViewTab.label })} value={activeViewTab.tabId} />
                })}
            </ViewTabs>
            <ViewPanels>
                {tabId === TabId.order
                    ? <ViewPanel value={TabId.order}>
                        <Access access="ORDER.ONS040ORDER.VIEW" >
                            <SectionCard>
                                <SectionCardContent>
                                    <OrderChangeUi orderChangedableList={orderChangedableList} searchOrderChangedableList={searchOrderChangedableList} />
                                </SectionCardContent>
                            </SectionCard>
                        </Access>
                    </ViewPanel>
                    : <></>
                }
                {tabId === TabId.forecast
                    ? <ViewPanel value={TabId.forecast}>
                        <Access access="ORDER.ONS040FORECAST.VIEW" >
                            <SectionCard>
                                <SectionCardContent>
                                    <FcChangeUi fcChangedableList={fcChangedableList} searchFcChangedableList={searchFcChangedableList} />
                                </SectionCardContent>
                            </SectionCard>
                        </Access>
                    </ViewPanel> : <></>
                }
            </ViewPanels>
        </ViewTabControl>
    </View>
}
const prepareUrlbyTabId = (id: any) => {
    switch (id) {
        case TabId.order:
            return "/bro-order"
        case TabId.forecast:
            return "/bro-forecast"
        default:
            return "/bro-order"
    }
}

interface OrderChangeUiProps {
    orderChangedableList: BUChangeRequestListResult[],
    searchOrderChangedableList: () => void,
}

const OrderChangeUi = (props: OrderChangeUiProps) => {
    return <OrderChangeDataTable {...props} />
}

const OrderChangeDataTable = ({ orderChangedableList, searchOrderChangedableList }: OrderChangeUiProps) => {
    const intl = useIntl()
    const getOrderDateRange = useCallback((row: any) => formatDateRange(intl, row.orderFirstDate, row.orderLastDate), [intl])
    const getTargetDateRange = useCallback((row: any) => formatDateRange(intl, row.targetFirstDate, row.targetLastDate), [intl])
    const columns = useMemo(() => [
        { field: 'poNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'purchaseOrderNo' }), width: 300 },
        { field: 'contractNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contractNo' }), width: 300 },
        { field: 'contrRouteCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contrRouteCode' }), width: 300 },
        { field: 'sellerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'sellerCode' }), width: 200 },
        { field: 'businessType', dataTypeName: CodeCategory.BusinessType, title: intl.formatMessage({ id: 'field.businessType' }), width: 200 },
        { field: 'orderFrequency', dataTypeName: CodeCategory.OrderFrequency, title: intl.formatMessage({ id: 'field.orderFrequency' }), width: 200 },
        { field: 'orderType', dataTypeName: CodeCategory.OrderType, title: intl.formatMessage({ id: 'field.orderType' }), width: 180 },
        { field: 'orderRange', dataTypeName: 'string', title: intl.formatMessage({ id: 'orderPeriod' }), width: 200, getCellValue: getOrderDateRange },
        { field: 'targetRange', dataTypeName: 'string', title: intl.formatMessage({ id: 'targetPeriod' }), width: 200, getCellValue: getTargetDateRange },
        { field: 'shippingMode', dataTypeName: CodeCategory.ShippingMode, title: intl.formatMessage({ id: 'field.shippingMode' }), width: 180 },
        { field: 'poDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.poDate' }), width: 200 },
        { field: 'spotOrderReason', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.spotOrderReason' }), width: 300 },
        { field: 'remark', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.remark' }), width: 300, },
        { field: 'status', dataTypeName: CodeCategory.PoStatus, title: intl.formatMessage({ id: 'orderStatus' }), width: 180 },
        { field: 'reviseNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.reviseNo' }), width: 300 },
        { field: 'roStatus', dataTypeName: CodeCategory.RoStatus, title: intl.formatMessage({ id: 'roStatus' }), width: 180 },
    ], [getOrderDateRange, getTargetDateRange, intl])
    const getRowId = useCallback((row: any) => row.poId, [])

    const actionProps1 = useMemo(() => ({ search: searchOrderChangedableList }), [searchOrderChangedableList])
    const display1 = useCallback((tableRow: TableRow) => !tableRow.row?.roStatus, [])
    const display2 = useCallback((tableRow: TableRow) => tableRow.row?.roStatus === RoStatus.DRAFT && tableRow.row?.reviseType === RequestType.ORDER_CHANGE, [])
    const display3 = useCallback((tableRow: TableRow) => tableRow.row?.roStatus === RoStatus.DRAFT, [])
    const display4 = useCallback((tableRow: TableRow) => tableRow.row?.roStatus && tableRow.row?.roStatus !== RoStatus.DRAFT, [])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.BusinessType} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OrderFrequency} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OrderType} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.ShippingMode} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.PoStatus} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.RoStatus} />
        <Data rows={orderChangedableList} columns={columns} getRowId={getRowId} />
        <RowActionProvider name="create" Action={CreateOrderRowAction} display={display1} />
        <RowActionProvider name="cancel" Action={CancelRowAction} actionProps={actionProps1} display={display1} />
        <RowActionProvider name="edit" Action={EditOrderRowAction} display={display2} />
        <RowActionProvider name="issue" Action={IssueRowAction} actionProps={actionProps1} display={display3} />
        <RowActionProvider name="delete" Action={DeleteRowAction} actionProps={actionProps1} display={display3} />
        <RowActionProvider name="view" Action={ViewOrderRowAction} display={display4} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['buCode', 'businessType', 'orderFrequency', 'spotOrderReason', 'poDate', 'orderRange', 'shippingMode', 'status', 'remark', 'reviseNo', 'roStatus']}
            columnSettings={{
                customerOrderNo: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Action width={124} />
    </DataGrid>
}

interface FcChangeUiProps {
    fcChangedableList: BUChangeRequestListResult[],
    searchFcChangedableList: () => void,
}

const FcChangeUi = memo((props: FcChangeUiProps) => {
    return <FcChangeTable {...props} />
})

const FcChangeTable = ({ fcChangedableList, searchFcChangedableList }: FcChangeUiProps) => {
    const intl = useIntl()
    const getOrderDateRange = useCallback((row: any) => formatDateRange(intl, row.orderFirstDate, row.orderLastDate), [intl])
    const getTargetDateRange = useCallback((row: any) => formatDateRange(intl, row.targetFirstDate, row.targetLastDate), [intl])
    const columns = useMemo(() => [
        { field: 'poNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'purchaseOrderNo' }), width: 250 },
        { field: 'contractNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contractNo' }), width: 220 },
        { field: 'contrRouteCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contrRouteCode' }), width: 220 },
        { field: 'sellerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'sellerCode' }), width: 180 },
        { field: 'businessType', dataTypeName: CodeCategory.BusinessType, title: intl.formatMessage({ id: 'field.businessType' }), width: 180 },
        { field: 'orderFrequency', dataTypeName: CodeCategory.OrderFrequency, title: intl.formatMessage({ id: 'field.orderFrequency' }), width: 200 },
        { field: 'orderRange', dataTypeName: 'string', title: intl.formatMessage({ id: 'orderPeriod' }), width: 200, getCellValue: getOrderDateRange },
        { field: 'targetRange', dataTypeName: 'string', title: intl.formatMessage({ id: 'targetPeriod' }), width: 200, getCellValue: getTargetDateRange },
        { field: 'shippingMode', dataTypeName: CodeCategory.ShippingMode, title: intl.formatMessage({ id: 'field.shippingMode' }), width: 180 },
        { field: 'poDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.poDate' }), width: 150 },
        { field: 'remark', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.remark' }), width: 300, },
        { field: 'status', dataTypeName: CodeCategory.PoStatus, title: intl.formatMessage({ id: 'field.status' }), width: 150 },
        { field: 'reviseNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.reviseNo' }), width: 250 },
        { field: 'roStatus', dataTypeName: CodeCategory.RoStatus, title: intl.formatMessage({ id: 'roStatus' }), width: 180 },
    ], [getOrderDateRange, getTargetDateRange, intl])
    const getRowId = useCallback((row: any) => row.poId, [])

    const actionProps1 = useMemo(() => ({ search: searchFcChangedableList }), [searchFcChangedableList])
    const display1 = useCallback((tableRow: TableRow) => !tableRow.row?.roStatus, [])
    const display2 = useCallback((tableRow: TableRow) => tableRow.row?.roStatus === RoStatus.DRAFT, [])
    const display3 = useCallback((tableRow: TableRow) => tableRow.row?.roStatus && tableRow.row?.roStatus !== RoStatus.DRAFT, [])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.BusinessType} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OrderFrequency} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.ShippingMode} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.PoStatus} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.RoStatus} />
        <Data rows={fcChangedableList} columns={columns} getRowId={getRowId} />
        <RowActionProvider name="create" Action={CreateFcRowAction} display={display1} />
        <RowActionProvider name="edit" Action={EditFcRowAction} display={display2} />
        <RowActionProvider name="issue" Action={IssueRowAction} actionProps={actionProps1} display={display2} />
        <RowActionProvider name="delete" Action={DeleteRowAction} actionProps={actionProps1} display={display2} />
        <RowActionProvider name="view" Action={ViewFcRowAction} display={display3} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['buCode', 'businessType', 'orderFrequency', 'spotOrderReason', 'poDate', 'orderRange', 'shippingMode', 'status', 'remark', 'reviseNo', 'roStatus']}
            columnSettings={{
                customerOrderNo: { disableUserControl: true }
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Action width={124} />
    </DataGrid>
}

const CreateOrderRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/bro-order/create-${tableRow.row?.poId}`, [])
    return <CreateRedirectRowAction tableRow={tableRow} access="ORDER.ONS040.CREATE" path={path} />
}

const CreateFcRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/bro-forecast/create-${tableRow.row?.poId}`, [])
    return <CreateRedirectRowAction tableRow={tableRow} access="ORDER.ONS040.CREATE" path={path} />
}

const CancelRowAction = ({ tableRow, search }: DataGridRowActionProps & { search: () => void }) => {
    const cancelOrder = useCancelOrder()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'cancel' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const callback = useCallback((tableRow: TableRow) => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            const poId = tableRow.row?.poId
            poId && cancelOrder({ orderIdList: [poId] }, { serialized: true }).then(search).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [cancelOrder, dispatch, functionStore, search, title])
    return <CancelCallbackRowAction tableRow={tableRow} access="ORDER.ONS040.CREATE" callback={callback} disabled={disabled} />
}

const EditOrderRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/bro-order/edit-${tableRow.row?.roId}`, [])
    return <EditRedirectRowAction tableRow={tableRow} access="ORDER.ONS040.EDIT" path={path} />
}

const EditFcRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/bro-forecast/edit-${tableRow.row?.roId}`, [])
    return <EditRedirectRowAction tableRow={tableRow} access="ORDER.ONS040.EDIT" path={path} />
}

const ViewOrderRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/bro-order/view-${tableRow.row?.roId}`, [])
    return <ViewRedirectRowAction tableRow={tableRow} access="ORDER.ONS040.VIEWDETAIL" path={path} />
}

const ViewFcRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/bro-forecast/view-${tableRow.row?.roId}`, [])
    return <ViewRedirectRowAction tableRow={tableRow} access="ORDER.ONS040.VIEWDETAIL" path={path} />
}

const IssueRowAction = ({ tableRow, search }: DataGridRowActionProps & { search: () => void }) => {
    const issueRevisedCustomerOrder = useIssueRevisedCustomerOrder()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'Issue' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const callback = useCallback((tableRow: TableRow) => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            const roId = tableRow.row?.roId
            roId && issueRevisedCustomerOrder({ roId }, { serialized: true }).then(search).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [dispatch, functionStore, issueRevisedCustomerOrder, search, title])
    return <IssueCallbackRowAction tableRow={tableRow} access="ORDER.ONS040.ISSUE" callback={callback} disabled={disabled} />
}

const DeleteRowAction = ({ tableRow, search }: DataGridRowActionProps & { search: () => void }) => {
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'delete' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const deleteRevisedCustomerOrder = useDeleteRevisedCustomerOrder()
    const callback = useCallback((tableRow: TableRow) => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            const roId = tableRow.row?.roId
            roId && deleteRevisedCustomerOrder({ roId }, { serialized: true }).then(search).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [deleteRevisedCustomerOrder, dispatch, functionStore, search, title])
    return <DeleteCallbackRowAction tableRow={tableRow} access="ORDER.ONS040.DELETE" callback={callback} disabled={disabled} />
}
