import { Collapse, makeStyles } from "@material-ui/core";
import { condition } from "@rithe/utils";
import React, { ReactNode } from "react";
import { useCollapseContext } from "./CollapseContext";

interface BlackSimpleCardContentProps {
    height?: number,
    children?: ReactNode | ReactNode[],
}

export function BlackSimpleCardContent(props: BlackSimpleCardContentProps) {
    const { height, children } = props
    const { enabled, collapsed } = useCollapseContext()
    const styles = useStyles()
    return condition()
        .case(enabled)
        .then(<Collapse in={!collapsed}>
            <div className={styles.root} style={{ height }}>
                {children}
            </div>
        </Collapse>)
        .default(<div className={styles.root} style={{ height }}>
            {children}
        </div>)
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: theme.spacing(1, 2),
        flex: '1 1 auto',
        overflow: 'hidden',
        //margin: theme.spacing(0, 2),
    },
}))