import React, { useEffect, useState } from "react"
import { useInitDIMonitorScreen } from "../../../services/delivery/apis/deliveryCustApi"
import { DownloadDiMonitorView } from "../../../services/delivery/models/DownloadDiMonitorView"
import { TnmDistributionCenter } from "../../../services/master/models/TnmDistributionCenter"
import { LDS013PcUi } from "./LDS013PcUi"

export const LDS013 = () => {
    const [factor, setFactor] = useState<DownloadDiMonitorView>({} as DownloadDiMonitorView)
    const [initData, setInitData] = useState<TnmDistributionCenter[]>([])

    const getInitData = useInitDIMonitorScreen()
    useEffect(() => {
        getInitData(undefined, { silent: true }).then(result => {
            setInitData(result || [])
        })
    }, [getInitData])

    return <LDS013PcUi
        factor={factor}
        setFactor={setFactor}
        initData={initData}
    />
}