import { AxiosInstance } from 'axios'
import { PageResultEntity } from '../../utils/PageResultEntity'
import { CreateUserAPIRequest } from '../models/CreateUserAPIRequest'
import { ListUsersAPIRequest } from '../models/ListUsersAPIRequest'
import { UpdateUserAPIRequest } from '../models/UpdateUserAPIRequest'
import { UserAPIResponse } from '../models/UserAPIResponse'
import { useCallback } from 'react'
import { useDownload } from '../../../utils/fetch/useFetch'

const userApi = {
    current: function(axiosInstance: AxiosInstance) {
        return axiosInstance.get<UserAPIResponse>('/common-system-api/user')
    },
    get: function (axiosInstance: AxiosInstance, id: string) {
        return axiosInstance.get<UserAPIResponse>('/common-system-api/users/:id', {pathVars: {id}})
    },
    batchGet: function (axiosInstance: AxiosInstance, ids: string[]) {
        return axiosInstance.post<UserAPIResponse[]>('/common-system-api/users/batch-get', ids)
    },
    list: function (axiosInstance: AxiosInstance, data: ListUsersAPIRequest) {
        return axiosInstance.get<PageResultEntity<UserAPIResponse>>('/common-system-api/users', {params: data})
    },
    create: function (axiosInstance: AxiosInstance, data: CreateUserAPIRequest) {
        return axiosInstance.post<UserAPIResponse>('/common-system-api/users', data)
    },
    batchCreate: function (axiosInstance: AxiosInstance, data: CreateUserAPIRequest[]) {
        return axiosInstance.post<UserAPIResponse[]>('/common-system-api/users/batch-create', data)
    },
    update: function (axiosInstance: AxiosInstance, data: UpdateUserAPIRequest) {
        return axiosInstance.put<UserAPIResponse>('/common-system-api/users/:id', data, {pathVars: {id: data.userId}})
    },
    batchUpdate: function (axiosInstance: AxiosInstance, data: UpdateUserAPIRequest[]) {
        return axiosInstance.post<UserAPIResponse[]>('/common-system-api/users/batch-update', data)
    },
    delete: function (axiosInstance: AxiosInstance, id: string) {
        return axiosInstance.delete('/common-system-api/users/:id', {pathVars: {id}})
    },
    batchDelete: function (axiosInstance: AxiosInstance, ids: string[]) {
        return axiosInstance.post('/common-system-api/users/batch-delete', ids)
    }
}

export const useDownloadUserDetail = () => {
    const url = '/common-system-api/users/downloadUserDetail'
    const payloadProject = useCallback((obj: { userIds: string[] }) => obj.userIds, [])
    return useDownload<{ userIds: string[] }>(url, payloadProject, { fileTimestamp: true })
}

export default userApi