import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { Projects } from "../../../utils/Projects"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { GetContractListFactor, getContractListFactorToJson } from "../models/ContractListFactor"
import { IdAndCode, idAndCodeFromJson } from "../models/IdAndCode"
import { InventoryByPackageFactor, inventoryByPackageFactorToJson } from "../models/InventoryByPackageFactor"
import { InventoryByPackageResult, inventoryByPackageResultFromJson } from "../models/InventoryByPackageResult"
import { InventoryByPartsFactor, inventoryByPartsFactorToJson } from "../models/InventoryByPartsFactor"
import { InventoryByPartsResult, inventoryByPartsResultFromJson } from "../models/InventoryByPartsResult"
import { ShippingStatusFactor, shippingStatusFactorToJson } from "../models/ShippingStatusFactor"
import { ShippingStatusResult, shippingStatusResultFromJson } from "../models/ShippingStatusResult"

// MTS010
export const useGetShippingStatusList = () => {
    const url = '/roe-api/mt/api/getShippingStatusList'
    const payloadProject = useCallback(shippingStatusFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(shippingStatusResultFromJson), [])
    return usePost<ShippingStatusFactor, PageResultEntity<ShippingStatusResult>>(url, payloadProject, resultProject)
}

// MTS020 
export const useInitInventoryByPartsList = () => {
    const url = '/lcbm-management-tool-api/mt/initInventoryByPartsList'
    const payloadProject = useCallback(inventoryByPartsFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(inventoryByPartsResultFromJson), [])
    return usePost<InventoryByPartsFactor, PageResultEntity<InventoryByPartsResult>>(url, payloadProject, resultProject)
}

// MTS030
export const useInitInventoryByPackageList = () => {
    const url = '/lcbm-management-tool-api/mt/initInventoryByPackageList'
    const payloadProject = useCallback(inventoryByPackageFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(inventoryByPackageResultFromJson), [])
    return usePost<InventoryByPackageFactor, PageResultEntity<InventoryByPackageResult>>(url, payloadProject, resultProject)
}

// MTS040
export const useGetCustomerIdAndCodeList = () => {
    const url = '/lcbm-management-tool-api/mt/getCustomerIdAndCodeList'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(idAndCodeFromJson), [])
    return usePost<undefined, IdAndCode[]>(url, payloadProject, resultProject)
}

export const useGetContractRouteIdAndCodeList = () => {
    const url = '/lcbm-management-tool-api/mt/getContractRouteIdAndCodeList'
    const payloadProject = useCallback((obj: { customerId: number | undefined }) => obj.customerId, [])
    const resultProject = useCallback(Projects.array(idAndCodeFromJson), [])
    return usePost<{ customerId: number | undefined }, IdAndCode[]>(url, payloadProject, resultProject)
}

export const useUpdateContractList = () => {
    const url = '/lcbm-management-tool-api/mt/getContractIdAndCodeList'
    const payloadProject = useCallback(getContractListFactorToJson, [])
    const resultProject = useCallback(Projects.array(idAndCodeFromJson), [])
    return usePost<GetContractListFactor, IdAndCode[]>(url, payloadProject, resultProject)
}

// MTS050
export const useGetCustomerIdAndCodeListForOverall = () => {
    const url = '/lcbm-management-tool-api/mt/getCustomerIdAndCodeListForOverall'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(idAndCodeFromJson), [])
    return usePost<undefined, IdAndCode[]>(url, payloadProject, resultProject)
}

export const useGetContractRouteIdAndCodeListForOverall = () => {
    const url = '/lcbm-management-tool-api/mt/getContractRouteIdAndCodeListForOverall'
    const payloadProject = useCallback((obj: { customerId: number | undefined }) => obj.customerId, [])
    const resultProject = useCallback(Projects.array(idAndCodeFromJson), [])
    return usePost<{ customerId: number | undefined }, IdAndCode[]>(url, payloadProject, resultProject)
}

export const useGetContractIdAndCodeListForOverall = () => {
    const url = '/lcbm-management-tool-api/mt/getContractIdAndCodeListForOverall';
    const payloadProject = useCallback((obj: { contractRouteId: number }) => obj.contractRouteId, []);
    const resultProject = useCallback(Boolean, []);
    return usePost<{ contractRouteId: number }, Boolean>(url, payloadProject, resultProject);
}
