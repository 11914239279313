import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { Projects } from "../../../utils/Projects"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { UnitPartsFactor, unitPartsFactorToJson } from "../models/UnitPartsFactor"
import { UnitPartsResult, unitPartsResultFromJson, unitPartsResultToJson } from "../models/UnitPartsResult"

export const useGetUnitPartsList = () => {
    const url = '/lcbm-local-master-api/api/getUnitPartsList'
    const payloadProject = useCallback(unitPartsFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(unitPartsResultFromJson), [])
    return usePost<UnitPartsFactor, PageResultEntity<UnitPartsResult>>(url, payloadProject, resultProject)
}

// save data to formal dataBase
export const useSaveUnitPartsList = () => {
    const url = '/lcbm-local-master-api/api/saveUnitPartsList'
    const payloadProject = useCallback(Projects.array(unitPartsResultToJson), [])
    const resultProject = Projects.IGNORE
    return usePost<UnitPartsResult[], undefined>(url, payloadProject, resultProject)
}
