import { Divider, Grid, makeStyles, Typography } from "@material-ui/core"
import { Save } from "@material-ui/icons"
import { Column, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Editing, Filtering, NumberTypeProvider, PaginationLayout, Paging, Row, Searching, Sorting, StringTypeProvider, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarLayout } from "@rithe/data-grid"
import { ArrayMultimaps, Arrays, Maps, Multisets, Records } from "@rithe/utils"
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/CallbackToolbarAction"
import { EditCallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/EditCallbackToolbarAction"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { View } from "../../../components/View/View"
import { useUpdateFtaInfo } from "../../../services/accounting/apis/accountingSellerApi"
import { Currency, CustomsInvoice, Detail } from "../../../services/accounting/models/CustomsInvoice"
import { TatInvoiceBreakdown } from "../../../services/accounting/models/TatInvoiceBreakdown"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { AIS031BreakdownPcUi, InvoiceBreakDown } from "../AIS031/AIS031BreakdownPcUi"

interface AIS031PcUiProps {
    invoice?: CustomsInvoice
}

export const AIS031PcUi = ({ invoice }: AIS031PcUiProps) => {
    const intl = useIntl()

    return <View>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={1}
                title={intl.formatMessage({ id: 'summaryInfo' })}
                subtitle={intl.formatMessage({ id: 'summaryInfoSub' })}
            />
            <SectionCardContent>
                <CoverPage invoice={invoice} />
            </SectionCardContent>
        </SectionCard>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={2}
                title={intl.formatMessage({ id: 'packageDetailInfo' })}
                subtitle={intl.formatMessage({ id: 'packageDetailInfoSub' })}
            />
            <SectionCardContent>
                <PackageDetailView invoice={invoice} />
            </SectionCardContent>
        </SectionCard>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={3}
                title={intl.formatMessage({ id: 'partsDetailInfo' })}
                subtitle={intl.formatMessage({ id: 'partsDetailInfoSub' })}
            />
            <SectionCardContent>
                <PartsDetailView invoice={invoice} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const CoverPage = ({ invoice }: {
    invoice?: CustomsInvoice
}) => {
    const [breakdownEditorOpen, setBreakdownEditorOpen] = useState(false)
    const orderNos = Arrays.distinct(
        invoice?.details.map(detail => detail.poNo) ?? []
    )
    const packageSums = Arrays.from(
        Multisets.from(
            invoice?.containers.flatMap(container => container.packages.map(pack => pack.packageType)).filter(v => v) as string[] ?? []
        ).asMap()
    )
    const quantitySums = Arrays.from(
        Maps.transform(
            ArrayMultimaps.from(invoice?.details.map(detail => [detail.part?.uom?.uomCode, [detail.part?.uom?.decimalDigits, detail.qty]]) ?? []).asMap(),
            ([, data]) => data.reduce((acc, d) => [d[0], (acc[1] ?? 0) + (d[1] ?? 0)], [0, 0])
        )
    )
    const totalNetWeight = invoice?.containers.flatMap(container => container.packages).map(pack => pack.netWeight).reduce((acc, weight) => (acc ?? 0) + (weight ?? 0), 0) ?? 0
    const totalGrossWeight = invoice?.containers.flatMap(container => container.packages).map(pack => pack.grossWeight).reduce((acc, weight) => (acc ?? 0) + (weight ?? 0), 0) ?? 0
    const totalM3 = invoice?.containers.flatMap(container => container.packages).map(pack => pack.m3).reduce((acc, m3) => (acc ?? 0) + (m3 ?? 0), 0) ?? 0
    const packageNos = Arrays.distinct(invoice?.containers.flatMap(container => container.packages).map(pack => pack.packageNo) ?? [])
    const countryOfOrigins = invoice?.countryOfOrigin?.split(",") ?? []
    const currencyCode = invoice?.invoiceCurrency?.currencyCode
    const currencyDigits = invoice?.invoiceCurrency?.decimalDigits

    const invoiceBreakDown: InvoiceBreakDown = prepareBreakdown(invoice?.breakdown, invoice?.invoiceNo, invoice?.invoiceCurrency)
    const ifNeedShowInit = useCallback((invoiceBreakDown: InvoiceBreakDown) => {
        return (invoiceBreakDown.costItems && invoiceBreakDown.costItems.length > 0)
            || (invoiceBreakDown.additionalItems && invoiceBreakDown.additionalItems.length > 0)
            || invoiceBreakDown?.initialIncotermsCode !== invoiceBreakDown?.finalIncotermsCode
    }, [])

    const styles = useStyles()
    return <div className={styles.coverPageContainer}>
        <div className={styles.coverPageUp}>
            <DataBlock title={<FormattedMessage id="field.invoiceNo" />}>
                <Typography variant="body2">{invoice?.invoiceNo}</Typography>
            </DataBlock>
            <DataBlock title={<FormattedMessage id="sellerHeaderInfo" />} colSpan={2} rowSpan={2}>
                <Typography variant="body2" className={styles.text}>{invoice?.seller?.cbdsName}</Typography>
                <Typography variant="body2" className={styles.text}>{invoice?.seller?.contact?.address1}</Typography>
                <Typography variant="body2" className={styles.text}>{invoice?.seller?.contact?.address2}</Typography>
                <Typography variant="body2" className={styles.text}>{invoice?.seller?.contact?.address3}</Typography>
                <Typography variant="body2" className={styles.text}>{invoice?.seller?.contact?.address4} {invoice?.seller?.contact?.postalCode}</Typography>
                <Typography variant="body2"><FormattedMessage id="tel" />:{invoice?.seller?.contact?.tel}&nbsp;&nbsp;&nbsp;&nbsp;<FormattedMessage id="fax" />:{invoice?.seller?.contact?.fax}</Typography>
            </DataBlock>
            <DataBlock title={<FormattedMessage id="placeAndDate" />}>
                <Typography variant="body2">{invoice?.seller?.regionName}</Typography>
                <Typography variant="body2"><FormattedDate value={invoice?.invoiceDate} dateStyle="medium" /></Typography>
            </DataBlock>
        </div>
        <Divider style={{ gridColumn: 'span 3' }} />
        <div className={styles.coverPageLeft}>
            <DataBlock title={<FormattedMessage id="consigneeInfo" />} colSpan={2}>
                <Typography variant="body2" className={styles.text}>{invoice?.consignee?.cbdsName}</Typography>
                <Typography variant="body2" className={styles.text}>{invoice?.consignee?.contact?.address1}</Typography>
                <Typography variant="body2" className={styles.text}>{invoice?.consignee?.contact?.address2}</Typography>
                <Typography variant="body2" className={styles.text}>{invoice?.consignee?.contact?.address3}</Typography>
                <Typography variant="body2" className={styles.text}>{invoice?.consignee?.contact?.address4} {invoice?.consignee?.contact?.postalCode}</Typography>
                <Typography variant="body2"><FormattedMessage id="tel" />:{invoice?.consignee?.contact?.tel}&nbsp;&nbsp;&nbsp;&nbsp;<FormattedMessage id="fax" />:{invoice?.consignee?.contact?.fax}</Typography>
            </DataBlock>
            <DataBlock title={<FormattedMessage id="orderNo" />} colSpan={2}>
                {orderNos.map(orderNo => <Typography key={orderNo} variant="body2">{orderNo}</Typography>)}
            </DataBlock>
            <DataBlock title={<FormattedMessage id="vesselAndCarrier" />}>
                <Typography variant="body2" className={styles.text}>{(invoice?.vesselName ?? '') + '/' + (invoice?.voyageNo ?? '')}</Typography>
            </DataBlock>
            <DataBlock title={<FormattedMessage id="etdOrEta" />}>
                <Typography variant="body2">ETD: <FormattedDate value={invoice?.atd ?? invoice?.etd} dateStyle="medium" /></Typography>
                <Typography variant="body2">ETA: <FormattedDate value={invoice?.eta} dateStyle="medium" /></Typography>
            </DataBlock>
            <DataBlock title={<FormattedMessage id="portAndShippingMode" />} colSpan={2}>
                <Typography variant="body2" className={styles.text}>FROM:{invoice?.loadingPort}  TO:{invoice?.dischargePort}  VIA: <FormattedMessage id={`${CodeCategory.ShippingMode}_${invoice?.shippingMode}`} /></Typography>
            </DataBlock>
            <DataBlock title={<FormattedMessage id="paymentTerms" />}>
                <Typography variant="body2" className={styles.text}>{invoice?.paymentTerms?.description}</Typography>
            </DataBlock>
            <DataBlock title={<FormattedMessage id="incoterms" />}>
                <Typography variant="body2" className={styles.text}>{invoice?.incoterms?.code} {invoice?.incoterms?.place}</Typography>
            </DataBlock>
            <DataBlock title={<FormattedMessage id="quantity" />} colSpan={2}>
                <Grid container>
                    <Grid item xs={6}>
                        {packageSums.map((packageSum, index) => <Typography key={index} variant="body2">{packageSum[1]} {packageSum[0]}</Typography>)}
                    </Grid>
                    <Grid item xs={6}>
                        {quantitySums.map((quantitySum, index) => <Typography key={index} variant="body2"><FormattedNumber value={quantitySum[1][1] ?? 0} minimumFractionDigits={quantitySum[1][0]} maximumFractionDigits={quantitySum[1][0]} /> {quantitySum[0]}</Typography>)}
                    </Grid>
                </Grid>
            </DataBlock>
            <DataBlock title={<FormattedMessage id="packageWeights" />} colSpan={2}>
                <Grid container>
                    <Grid item xs={4} container direction="column">
                        <Typography variant="body2"><FormattedMessage id="totalNetWeight" /></Typography>
                        <Typography variant="body2"><FormattedMessage id="totalGrossWeight" /></Typography>
                        <Typography variant="body2"><FormattedMessage id="totalM3" /></Typography>
                    </Grid>
                    <Grid item xs={4} container direction="column" alignItems="flex-end">
                        <Typography variant="body2"><FormattedNumber value={totalNetWeight} minimumFractionDigits={3} maximumFractionDigits={3} /> KG</Typography>
                        <Typography variant="body2"><FormattedNumber value={totalGrossWeight} minimumFractionDigits={3} maximumFractionDigits={3} /> KG</Typography>
                        <Typography variant="body2"><FormattedNumber value={totalM3} minimumFractionDigits={3} maximumFractionDigits={3} /> M<sup>3</sup></Typography>
                    </Grid>
                </Grid>
            </DataBlock>
            <DataBlock title={<FormattedMessage id="accounteeInfo" />}>
                <Typography variant="body2" className={styles.text}>{invoice?.accountee?.cbdsName}</Typography>
                <Typography variant="body2" className={styles.text}>{invoice?.accountee?.contact?.address1}</Typography>
                <Typography variant="body2" className={styles.text}>{invoice?.accountee?.contact?.address2}</Typography>
                <Typography variant="body2" className={styles.text}>{invoice?.accountee?.contact?.address3}</Typography>
                <Typography variant="body2" className={styles.text}>{invoice?.accountee?.contact?.address4}</Typography>
                <Typography variant="body2"><FormattedMessage id="tel" />:{invoice?.accountee?.contact?.tel}&nbsp;&nbsp;&nbsp;&nbsp;<FormattedMessage id="fax" />:{invoice?.accountee?.contact?.fax}</Typography>
            </DataBlock>
            <DataBlock title={<FormattedMessage id="destinationInfo" />}>
                <Typography variant="body2" className={styles.text}>{invoice?.deliveryTo?.cbdsName}</Typography>
                <Typography variant="body2" className={styles.text}>{invoice?.deliveryTo?.contact?.address1}</Typography>
                <Typography variant="body2" className={styles.text}>{invoice?.deliveryTo?.contact?.address2}</Typography>
                <Typography variant="body2" className={styles.text}>{invoice?.deliveryTo?.contact?.address3}</Typography>
                <Typography variant="body2" className={styles.text}>{invoice?.deliveryTo?.contact?.address4}</Typography>
                <Typography variant="body2"><FormattedMessage id="tel" />:{invoice?.deliveryTo?.contact?.tel}&nbsp;&nbsp;&nbsp;&nbsp;<FormattedMessage id="fax" />:{invoice?.deliveryTo?.contact?.fax}</Typography>
            </DataBlock>
            <DataBlock title={<FormattedMessage id="remark" />} colSpan={2}>
                <Typography variant="body2" style={{ overflowWrap: 'break-word' }}>{invoice?.remark}</Typography>
            </DataBlock>
        </div>
        <div className={styles.coverPageRight}>
            <div>
                <DataBlock title={<FormattedMessage id="shippingMarks" />}>
                    <Typography variant="body2"><FormattedMessage id="shippingMark" /></Typography>
                    <Typography variant="body2">{invoice?.invoiceNo}</Typography>
                    {/* <Typography variant="body2">{invoice?.seller?.regionName}</Typography> */}
                    <Typography variant="body2">{countryOfOrigins.length > 0 ? countryOfOrigins.join(",") : ''}</Typography>
                    <Typography variant="body2"><FormattedMessage id="cNo" /></Typography>
                    {/* {packageNos.map(packageNo => <Typography key={packageNo} variant="body2">{packageNo}</Typography>)} */}
                    <Grid container>
                        {Arrays.range(0, 20).map(indx => {
                            return <>
                                {indx < packageNos.length && <Grid item xs={4}><Typography key={packageNos[indx]} variant="body2">{packageNos[indx]}</Typography></Grid>}
                                {indx + 20 < packageNos.length ?
                                    <Grid item xs={4}><Typography key={packageNos[indx + 20]} variant="body2">{packageNos[indx + 20]}</Typography></Grid>
                                    : (indx < packageNos.length ? <Grid item xs={4}></Grid> : <></>)}
                                {indx + 40 < packageNos.length ?
                                    <Grid item xs={4}><Typography key={packageNos[indx + 40]} variant="body2">{packageNos[indx + 40]}</Typography></Grid>
                                    : (indx < packageNos.length ? <Grid item xs={4}></Grid> : <></>)}
                            </>
                        })}
                    </Grid>
                </DataBlock>

                <DataBlock title={<FormattedMessage id="incotermBreakdown" />} onClick={() => setBreakdownEditorOpen(true)}>
                    {
                        ifNeedShowInit(invoiceBreakDown) ? <>
                            <Grid container>
                                <Grid item xs={2} container direction="column">
                                    <Typography variant="body2">{invoiceBreakDown?.initialIncotermsCode}</Typography>
                                </Grid>
                                <Grid item xs={6} container direction="column" alignItems="flex-start">
                                    <Typography variant="body2">{invoiceBreakDown?.initialIncotermsPlace}</Typography>
                                </Grid>
                                <Grid item xs={4} container direction="column" alignItems="flex-end">
                                    <Typography variant="body2">{currencyCode} <FormattedNumber value={invoiceBreakDown?.initialIncotermsAmount ?? 0} minimumFractionDigits={currencyDigits} maximumFractionDigits={currencyDigits} /></Typography>
                                </Grid>
                            </Grid>
                            {
                                invoiceBreakDown && invoiceBreakDown.costItems && invoiceBreakDown.costItems.map((item) => {
                                    return <ItemBreakDown itemName={item.itemName} itemAmount={item.itemAmount} currency={invoice?.invoiceCurrency} />
                                })
                            }

                            <Grid item container xs={12}><div className={styles.subDivider} ><Divider /></div></Grid>
                            <Grid container>
                                <Grid item xs={6} container direction="column">
                                    <Typography variant="body2">SUB TOTAL</Typography>
                                </Grid>
                                <Grid item xs={6} container direction="column" alignItems="flex-end">
                                    <Typography variant="body2">{invoice?.invoiceCurrency?.currencyCode} <FormattedNumber value={(invoiceBreakDown?.invoiceAmount ?? 0) + (invoiceBreakDown?.invoiceAdjustmentAmount ?? 0)} minimumFractionDigits={currencyDigits} maximumFractionDigits={currencyDigits} /></Typography>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12}>
                                <div style={{ width: '100%', alignItems: 'center', padding: 8 }}></div>
                            </Grid>
                        </> : <></>
                    }
                    {
                        invoiceBreakDown && invoiceBreakDown.additionalItems && invoiceBreakDown.additionalItems.map((item) => {
                            return <ItemBreakDown itemName={item.itemName} itemAmount={item.itemAmount} currency={invoice?.invoiceCurrency} />
                        })
                    }
                    <Grid container>
                        <Grid item xs={2} container direction="column">
                            <Typography variant="body2">{invoiceBreakDown?.finalIncotermsCode}</Typography>
                        </Grid>
                        <Grid item xs={6} container direction="column" alignItems="flex-start">
                            <Typography variant="body2">{invoiceBreakDown?.finalIncotermsPlace}</Typography>
                        </Grid>
                        <Grid item xs={4} container direction="column" alignItems="flex-end">
                            <Typography variant="body2">{currencyCode} <FormattedNumber value={invoiceBreakDown?.finalIncotermsAmount ?? 0} minimumFractionDigits={currencyDigits} maximumFractionDigits={currencyDigits} /></Typography>
                        </Grid>
                    </Grid>

                    <Grid item container xs={12}><div className={styles.divider} ><Divider /></div></Grid>
                    <Grid container>
                        <Grid item xs={8} container direction="column">
                            <Typography variant="body2" style={{ fontWeight: 'bold' }}><FormattedMessage id="GrandTotal" /></Typography>
                        </Grid>
                        <Grid item xs={4} container direction="column" alignItems="flex-end">
                            <Typography variant="body2" style={{ fontWeight: 'bold' }}>{currencyCode} <FormattedNumber value={invoiceBreakDown?.finalIncotermsAmount as number} minimumFractionDigits={currencyDigits} maximumFractionDigits={currencyDigits} /></Typography>
                        </Grid>
                    </Grid>
                </DataBlock>
                {breakdownEditorOpen &&
                    <AIS031BreakdownPcUi
                        title={<FormattedMessage id="incotermBreakdownView" />}
                        breakdown={invoiceBreakDown}
                        open={breakdownEditorOpen}
                        onClose={() => setBreakdownEditorOpen(false)}
                    />}
                <DataBlock title={<FormattedMessage id="insuranceStamp" />}>
                </DataBlock>
                <DataBlock title={<FormattedMessage id="signature" />}>
                </DataBlock>
            </div>
        </div>
    </div>
}

const ItemBreakDown = ({ itemName, itemAmount, currency }: {
    itemName?: string,
    itemAmount?: number,
    currency?: Currency
}) => {
    return <Grid container>
        <Grid item xs={8} container direction="column">
            <Typography variant="body2">{itemName}</Typography>
        </Grid>
        <Grid item xs={4} container direction="column" alignItems="flex-end">
            <Typography variant="body2">{currency?.currencyCode} <FormattedNumber value={itemAmount ?? 0} minimumFractionDigits={currency?.decimalDigits} maximumFractionDigits={currency?.decimalDigits} /></Typography>
        </Grid>
    </Grid>
}

const PackageDetailView = ({ invoice }: { invoice?: CustomsInvoice }) => {
    const intl = useIntl()
    const packages = invoice?.containers.flatMap(container => container.packages) ?? []

    const columns = useMemo(() => [
        { field: 'packageNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.packageNo' }), width: 200 },
        { field: 'packageType', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.packageType' }), width: 200 },
        { field: 'm3', dataTypeName: 'volume', title: intl.formatMessage({ id: 'field.m3' }), width: 200 },
        { field: 'netWeight', dataTypeName: 'weight', title: intl.formatMessage({ id: 'field.netWeight' }), width: 200 },
        { field: 'grossWeight', dataTypeName: 'weight', title: intl.formatMessage({ id: 'field.grossWeight' }), width: 200 },
        { field: 'unpackingFlag', dataTypeName: CodeCategory.UnpackingFlag, title: intl.formatMessage({ id: 'field.unpackingFlag' }), width: 200 },
    ], [intl])

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <NumberTypeProvider name="weight" options={{ minimumFractionDigits: 3, maximumFractionDigits: 3 }} />
            <NumberTypeProvider name="volume" options={{ minimumFractionDigits: 3, maximumFractionDigits: 3 }} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.UnpackingFlag} />
            <Data rows={packages} columns={columns} />
            <ColumnFreeze />
            <ColumnVisibility defaultHiddenFields={['unpackingFlag']} columnSettings={{ packageNo: { disableUserControl: true } }} ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
            <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        </DataGrid>
    </div >
}

const PartsDetailView = ({ invoice }: { invoice?: CustomsInvoice }) => {
    const intl = useIntl()
    const currencyCode = invoice?.invoiceCurrency?.currencyCode
    const currencyDigits = invoice?.invoiceCurrency?.decimalDigits
    const [editable, setEditable] = useState<boolean>(false)
    const [details, setDetails] = useState<Detail[]>([])
    const columns = useMemo(() => [
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200, getCellValue: (row: Row) => row.part?.partsNo },
        { field: 'buyerPN', dataTypeName: 'string', title: intl.formatMessage({ id: 'buyerPartsNo' }), width: 200, getCellValue: (row: Row) => row.poPart?.unitPartsNo },
        { field: 'colorCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.colorCode' }), width: 150, getCellValue: (row: Row) => row.part?.colorCode },
        { field: 'buyerPNDesc', dataTypeName: 'string', title: intl.formatMessage({ id: 'buyerPartsName' }), width: 300, getCellValue: (row: Row) => row.poPart?.unitPartsName },
        { field: 'shipperPN', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sellerPartsNo' }), width: 200, getCellValue: (row: Row) => row.soPart?.unitPartsNo },
        { field: 'shipperPNDesc', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sellerPartsName' }), width: 300, getCellValue: (row: Row) => row.soPart?.unitPartsName },
        { field: 'soNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'salesOrderNo' }), width: 220, getCellValue: (row: Row) => row.soNo },
        { field: 'poNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'purchaseOrderNo' }), width: 220, getCellValue: (row: Row) => row.poNo },
        { field: 'buyerSpq', dataTypeName: 'quantity', title: intl.formatMessage({ id: 'field.spq' }), width: 120, getCellValue: (row: Row) => intl.formatNumber(row.buyerSpq, { minimumFractionDigits: row.part?.decimalDigits, maximumFractionDigits: row.part?.decimalDigits }) },
        { field: 'qty', dataTypeName: 'quantity', title: intl.formatMessage({ id: 'field.qty' }), width: 120, getCellValue: (row: Row) => intl.formatNumber(row.qty, { minimumFractionDigits: row.part?.decimalDigits, maximumFractionDigits: row.part?.decimalDigits }) },
        { field: 'ftaFlag', dataTypeName: CodeCategory.FtaFlag, title: intl.formatMessage({ id: 'field.ftaFlag' }), width: 120 },
        { field: 'unit', dataTypeName: 'string', title: intl.formatMessage({ id: 'unit' }), width: 120, getCellValue: (row: Row) => row.part?.uom?.uomCode },
        { field: 'currency', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.currency' }), width: 120, getCellValue: () => currencyCode },
        { field: 'price', dataTypeName: 'currency', title: intl.formatMessage({ id: 'unitPrice' }), width: 150 },
        { field: 'amount', dataTypeName: 'currency', title: intl.formatMessage({ id: 'amount' }), width: 150, getCellValue: (row: Row) => (row.price ?? 0) * (row.qty ?? 0) },
    ], [currencyCode, intl])

    const editProps = useMemo(() => ({ setEditable }), [setEditable])
    const saveProps = useMemo(() => ({ setEditable, details, invoice }), [setEditable, details, invoice])

    const defaultColumnsDisabled = Records.from(columns.filter(({ field }) => field !== 'ftaFlag').map(({ field }) => [field, { editingDisabled: true }]))
    const onEditingRowCommit = useCallback((_column: Column, row: Row) => {
        setDetails && setDetails(details => details.map(detail => detail.invoicePriceId === row.invoicePriceId ? { ...detail, ftaFlag: row.ftaFlag } : detail))
        return true
    }, [setDetails])

    useEffect(() => {
        invoice?.details && setDetails(invoice?.details)
    }, [invoice?.details])

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <StringTypeProvider name="quantity" align="end" />
            <NumberTypeProvider name="currency" options={{ minimumFractionDigits: currencyDigits, maximumFractionDigits: currencyDigits }} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.FtaFlag} />
            <Data rows={details} columns={columns} />
            <ToolbarActionProvider Action={EditToolBarAction} actionProps={editProps} display={() => !editable} />
            <ToolbarActionProvider Action={SaveToolBarAction} actionProps={saveProps} display={() => editable} />
            <ColumnFreeze />
            {editable ? <Editing columnSettings={defaultColumnsDisabled} enableInlineEdit onEditingCellCommit={onEditingRowCommit} /> : <></>}
            <ColumnVisibility
                defaultHiddenFields={['shipperPN', 'shipperPNDesc']}
                columnSettings={{
                    partsNo: { disableUserControl: true },
                    buyerPartsNo: { disableUserControl: true },
                }} ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
            <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        </DataGrid>
    </div >
}

const EditToolBarAction = ({ setEditable }: {
    setEditable: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    const callback = useCallback(() => {
        setEditable(true)
    }, [setEditable])
    return <EditCallbackToolbarAction callback={callback} />
}

const SaveToolBarAction = ({ setEditable, invoice, details }: {
    setEditable: React.Dispatch<React.SetStateAction<boolean>>,
    invoice?: CustomsInvoice,
    details: Detail[]
}) => {
    const saveFtaInfo = useUpdateFtaInfo()
    const [disabled, setDisabled] = useState<boolean>(false)
    const callback = useCallback(() => {
        setDisabled(true)
        saveFtaInfo({
            invoiceId: invoice?.invoiceId ?? 0,
            invoiceNo: invoice?.invoiceNo ?? '',
            details: details.map(m => ({
                invoicePriceId: m.invoicePriceId,
                unitPartsNo: m.soPart?.unitPartsNo,
                partsNo: m.part?.partsNo,
                soNo: m.soNo,
                poNo: m.poNo,
                ftaFlag: m.ftaFlag,
            }))
        }, { serialized: true }).then(() => { setEditable(false) }).finally(() => {
            setDisabled(false)
        })
    }, [details, invoice?.invoiceId, invoice?.invoiceNo, saveFtaInfo, setEditable])
    return <CallbackToolbarAction callback={callback} icon={<Save />} title={<FormattedMessage id="save" />} disabled={disabled} />
}

const DataBlock = ({ title, colSpan = 1, rowSpan = 1, onClick, children }: { title: ReactNode, colSpan?: number, rowSpan?: number, onClick?: () => void, children?: ReactNode | ReactNode[] }) => {
    const styles = useStyles()
    return <div className={styles.dataArea} style={{ gridColumn: `span ${colSpan}`, gridRow: `span ${rowSpan}` }}>
        <Typography variant="subtitle2">{title}</Typography>
        <div onClick={onClick}>
            {children}
        </div>
    </div>
}

const prepareBreakdown = (breakdowns: TatInvoiceBreakdown[] | undefined, invoiceNo: string | undefined, currency: Currency | undefined) => {

    return breakdowns ? {
        invoiceNo: invoiceNo,
        invoiceCurrency: currency?.currencyCode,
        currencyDigits: currency?.decimalDigits,
        invoiceAmount: breakdowns.map(m => m.invoiceAmount ?? 0).reduce((a, b) => a + b, 0),
        invoiceAdjustmentAmount: breakdowns.map(m => m.invoiceAdjustmentAmount ?? 0).reduce((a, b) => a + b, 0),
        invoiceAdjustmentReason: breakdowns.map(m => m.invoiceAdjustmentReason ?? '').join(','),
        initialIncotermsCode: breakdowns[0]?.initialIncotermsCode,
        initialIncotermsPlace: breakdowns[0]?.initialIncotermsPlace,
        initialIncotermsAmount: breakdowns.map(m => m.initialIncotermsAmount ?? 0).reduce((a, b) => a + b, 0),
        finalIncotermsCode: breakdowns[0]?.finalIncotermsCode,
        finalIncotermsPlace: breakdowns[0]?.finalIncotermsPlace,
        finalIncotermsAmount: breakdowns.map(m => m.finalIncotermsAmount ?? 0).reduce((a, b) => a + b, 0),
        costItems: prepareItems(breakdowns.map(breakdown => [
            [breakdown.costItem1, breakdown.costItem1Amount],
            [breakdown.costItem2, breakdown.costItem2Amount],
            [breakdown.costItem3, breakdown.costItem3Amount],
            [breakdown.costItem4, breakdown.costItem4Amount],
            [breakdown.costItem5, breakdown.costItem5Amount],
        ]).flatMap(v => v) as [string | undefined, number | undefined][]),
        additionalItems: prepareItems(
            breakdowns.map(breakdown => [
                [breakdown.additionalItem1, breakdown.additionalItem1Amount],
                [breakdown.additionalItem2, breakdown.additionalItem2Amount],
                [breakdown.additionalItem3, breakdown.additionalItem3Amount],
                [breakdown.additionalItem4, breakdown.additionalItem4Amount],
                [breakdown.additionalItem5, breakdown.additionalItem5Amount],
            ]).flatMap(v => v) as [string | undefined, number | undefined][]),
    } : {
        invoiceNo: invoiceNo
    } as InvoiceBreakDown
}
const prepareItems = (items: [string | undefined, number | undefined][]) => {

    // do distinct
    const activeItems = items.filter(f => f[0])
    // do summary
    const sumItems = Arrays.from(
        Maps.transform(
            ArrayMultimaps.from(activeItems.map(m => [m[0], m])).asMap(), ([, data]) => data.reduce((acc, d) => [d[0], (acc[1] ?? 0) + (d[1] ?? 0)], ['', 0])
        )
    ).map(m => m[1]).sort((a, b) => (a[0] ?? '').localeCompare(b[0] ?? ''))
    return sumItems.length > 0 ? sumItems.map((m, idx) => ({ itemNo: idx, itemName: m[0], itemAmount: m[1] })) : []
}

const useStyles = makeStyles(theme => ({
    coverPageContainer: {
        display: 'grid',
        gridTemplateColumns: `repeat(3, minmax(0, 1fr))`,
        gap: `${theme.spacing(2)}px`,
    },
    coverPageUp: {
        gridColumn: 'span 3',
        display: 'grid',
        gridTemplateColumns: `repeat(3, minmax(0, 1fr))`,
        gap: `${theme.spacing(2)}px`,
    },
    coverPageLeft: {
        gridColumn: 'span 2',
        display: 'grid',
        gridTemplateColumns: `repeat(2, minmax(0, 1fr))`,
        gap: `${theme.spacing(2)}px`,
    },
    coverPageRight: {
        '&>div': {
            display: 'grid',
            gridTemplateColumns: `repeat(1, minmax(0, 1fr))`,
            gap: `${theme.spacing(2)}px`,
        }
    },
    dataArea: {
        border: `1px dashed ${theme.palette.divider}`,
        borderRadius: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: theme.spacing(2),
        '&>*:nth-child(1)': {
            textTransform: 'uppercase',
            marginBottom: theme.spacing(2),
        },
        '&>*:nth-child(2)': {
            padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
            borderRadius: theme.spacing(1),
            backgroundColor: theme.palette.grey[200],
            minHeight: 37,
            boxSizing: 'border-box',
        },
    },
    text: {
        whiteSpace: 'break-spaces',
        overflowWrap: 'break-word',
    },
    divider: {
        width: '100%',
        alignItems: 'center',
        padding: theme.spacing(1, 0),
    },
    subDivider: {
        width: '100%',
        alignItems: 'center',
        padding: 5,
    }
}))