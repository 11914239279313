import { Project } from "../../../utils/Project"
import { AutoDiFlag } from "../../master/enums/AutoDiFlag"
import { BusinessType } from "../../master/enums/BusinessType"
import { CbdsType } from "../../master/enums/CbdsType"
import { CustomsFlag } from "../../master/enums/CustomsFlag"
import { PriceBasis } from "../../master/enums/PriceBasis"
import { RepackingType } from "../../master/enums/RepackingType"
import { dateFromJson, datetimeFromJson } from "../../utils/deserializer"
import { datetimeToJson, dateToJson } from "../../utils/serializer"

export type CompanyOrderInfo = Required & Partial<Optional>
export type CompanyOrderInfoJson = JsonRequired & Partial<JsonOptional>
export const companyOrderInfoToJson: Project<CompanyOrderInfo, CompanyOrderInfoJson> = obj => ({
    ...obj,
    outboundDate: dateToJson(obj.outboundDate),
    inboundDate: dateToJson(obj.inboundDate),
    dbTime: datetimeToJson(obj.dbTime),
})
export const companyOrderInfoFromJson: Project<CompanyOrderInfoJson, CompanyOrderInfo> = json => ({
    ...json,
    outboundDate: dateFromJson(json.outboundDate),
    inboundDate: dateFromJson(json.inboundDate),
    dbTime: datetimeFromJson(json.dbTime),
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'outboundDate' | 'inboundDate' | 'dbTime'> {
    companyType: CbdsType,
    businessType: BusinessType,
    autoDIFlag: AutoDiFlag,
    priceBasis: PriceBasis,
    outboundDate: Date,
    inboundDate: Date,
    customsFlag: CustomsFlag,
    dbTime: Date,
    repackingType: RepackingType,

}

interface JsonRequired {
    inbId: number,
    inboundNo: string,

}

interface JsonOptional {
    linkNo: number,
    soOrigin: string,
    coOrigin: string,
    soId: number,
    soDetailId: number,
    poId: number,
    poDetailId: number,
    companyId: number,
    companyType: number,
    companyUid: string,
    timeZone: string,
    companyCode: string,
    salesOrderNo: string,
    purchaseOrderNo: string,
    contractRouteId: number,
    salesContractId: number,
    salesContractNo: string,
    purchaseContractId: number,
    inboundDcId: number,
    businessType: number,
    unitPartsId: number,
    custPartsId: number,
    autoDIFlag: number,
    partsId: number,
    partsNo: string,
    spq: number,
    orderQty: number,
    forceCompletedQty: number,
    totalDeliveryQty: number,
    soStatus: number,
    soDetailStatus: number,
    poStatus: number,
    priceBasis: number,
    sellingPrice: number,
    sellingCurrency: string,
    outboundNo: string,
    inboundNo: string,
    outboundDate: string,
    inboundDate: string,
    remaindQty: number,
    srId: number,
    cpCalendarId: number,
    customsFlag: number,
    skipFlag: number,
    receiverFlag: number,
    buyCustomerId: number,
    buyCustCalendarId: number,
    buyBuId: number,
    loginId: string,
    dbTime: number,
    repackingType: number,
    index: number,
    nodeId: string,
    customerId: number,
    supplierId: number,
    roId: number,
    shipperDcId: number,
}