import React, { useCallback, useEffect, useState } from "react"
import { useComMasterGetActiveCbds } from "../../../services/common/apis/commonMasterApi"
import { useQueryByPage } from "../../../services/delivery/apis/deliveryToDoListApi"
import { TnmUserToListFilter, tnmUserToListFilterJson } from "../../../services/delivery/models/TnmUserToList"
import { TnmUserToListResult } from "../../../services/delivery/models/TnmUserToListResult"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { TODOLISTPcUi } from "./TDL010PcUi"
import { TodoListStatus } from "../../../services/master/enums/TodoListStatus"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 }, status: [TodoListStatus.Pending, TodoListStatus.InProcess] }
const searchCachekey = sessionKeys.Filters_TDL010

export const TDL010 = () => {
    // const cacheFilters = useMemo(() => {
    //     const filters = sessionStorage.getItem(searchCachekey)
    //     return filters ? tnmUserToListFilterFromJson(JSON.parse(filters)) : null
    // }, [])

    const [filters, setFilters] = useState<TnmUserToListFilter>(defaultFilters)
    const [data, setData] = useState<TnmUserToListResult[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const getTodoList = useQueryByPage()
    const getActiveCbds = useComMasterGetActiveCbds()
    const [companyList, setCompanyList] = useState<TnvCbds[]>([])

    const search = useCallback((filters: TnmUserToListFilter) => {
        getTodoList(filters, { silent: true, serialized: true }).then((result) => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(tnmUserToListFilterJson(filters)))
        })
    }, [getTodoList])

    useEffect(() => {
        getActiveCbds({ types: [1, 2] }, { silent: true }).then(result => {
            setCompanyList(result || [])
        })
    }, [getActiveCbds, setCompanyList])

    useSearchOnLoad(search, filters)

    return <TODOLISTPcUi
        filters={filters}
        setFilters={setFilters}
        search={search}
        data={data}
        totalCount={totalCount}
        companyList={companyList}
    />
}