
import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"

export interface VerdorProfileResult {
    supplierId: number,
    supplierCode: string,
    supplierName: string,
    regionCode: string,
    completedCount: number,
    onTimeCount: number,
    avgActualExpectedDays: number,
    deliveries: DeliveryResult[],
    avgDuration: number,
    durations: DurationResult[],
    purchaseAmounts: PurchaseAmountResult[]
}

interface VerdorProfileResultJson {
    supplierId: number,
    supplierCode: string,
    supplierName: string,
    regionCode: string,
    completedCount: number,
    onTimeCount: number,
    avgActualExpectedDays: number,
    deliveries: DeliveryResultJson[],
    avgDuration: number,
    durations: DurationResultJson[],
    purchaseAmounts: PurchaseAmountResultJson[]
}

export const verdorProfileResultFromJson: Project<VerdorProfileResultJson, VerdorProfileResult> = json => ({
    ...json,
    deliveries: json.deliveries.map(deliveryResultFromJson),
    durations: json.durations.map(durationResultFromJson),
    purchaseAmounts: json.purchaseAmounts.map(purchaseAmountResultFromJson),
})

interface DeliveryResult {
    supplierId: number,
    soId: number,
    drDate: Date,
    actualDrDate: Date,
}

interface DeliveryResultJson {
    supplierId: number,
    soId: number,
    drDate: string,
    actualDrDate: string,
}

const deliveryResultFromJson: Project<DeliveryResultJson, DeliveryResult> = json => ({
    ...json,
    drDate: dateFromJson(json.drDate),
    actualDrDate: dateFromJson(json.actualDrDate),
})

interface DurationResult {
    supplierId: number,
    soId: number,
    soDate: Date,
    minDrDate: Date,
}

interface DurationResultJson {
    supplierId: number,
    soId: number,
    soDate: string,
    minDrDate: string,
}

const durationResultFromJson: Project<DurationResultJson, DurationResult> = json => ({
    ...json,
    soDate: dateFromJson(json.soDate),
    minDrDate: dateFromJson(json.minDrDate),
})


export interface PurchaseAmountResult {
    supplierId: number,
    currency: string,
    amount: number,
}

export interface PurchaseAmountResultJson {
    supplierId: number,
    currency: string,
    amount: number,
}

export const purchaseAmountResultFromJson: Project<PurchaseAmountResultJson, PurchaseAmountResult> = json => ({
    ...json
})