import { useCallback } from "react"
import { useDownload, usePost } from "../../../utils/fetch/useFetch"
import { Projects } from "../../../utils/Projects"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { ARTransfer, aRTransferFromJson, PaidTransfer, PaidTransferToJson } from "../models/ARTransfer"
import { ARView, aRViewToJson } from "../models/ArView"
import { BuyerInvoiceTransfer, buyerInvoiceTransferFromJson } from "../models/BuyerInvoiceTransfer"
import { BuyerInvoiceView, buyerInvoiceViewToJson } from "../models/BuyerInvoiceView"
import { GiGrInvoice, giGrInvoiceDeserializer } from "../models/GiGrInvoice"
import { GiGrInvoiceSaveVo, giGrInvoiceSaveVoSerializer } from "../models/GiGrInvoiceSaveVo"
import { invoiceFilterFromJson, InvoiceFilterResponse } from "../models/SellerInvoiceFilterResponse"

export const useGetGRInvoicePage = () => {
    const url = '/lcbm-accounting-api/api/getGRInvoicePageByVO'
    const payloadProject = useCallback(buyerInvoiceViewToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(buyerInvoiceTransferFromJson), [])
    return usePost<BuyerInvoiceView, PageResultEntity<BuyerInvoiceTransfer>>(url, payloadProject, resultProject)
}

export const useGetGIInvoicePage = () => {
    const url = '/lcbm-accounting-api/api/getGIInvoicePageByVO'
    const payloadProject = useCallback(buyerInvoiceViewToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(buyerInvoiceTransferFromJson), [])
    return usePost<BuyerInvoiceView, PageResultEntity<BuyerInvoiceTransfer>>(url, payloadProject, resultProject)
}

export const useGetBuyerSellerFilterList = () => {
    const url = '/lcbm-accounting-api/api/getBuyerSellerFilterList '
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(invoiceFilterFromJson, [])
    return usePost<undefined, InvoiceFilterResponse>(url, payloadProject, resultProject)
}

export const useDownloadSellerList = () => {
    const url = '/lcbm-accounting-api/api/downloadSellerInvoice '
    const payloadProject = useCallback((obj: {ids: number[], filter: BuyerInvoiceView}) => {
        return {
            ids: obj.ids,
            filter: {
            ...obj.filter
        }};
    }, []);
    return useDownload<{ ids: number[], filter: BuyerInvoiceView}>(url, payloadProject, { fileTimestamp: true })
}

export const useDoCombineInvoice = () => {
    const url = '/lcbm-accounting-api/api/doCombine'
    const payloadProject = useCallback((obj: { invoiceIdList: number[] }) => obj.invoiceIdList, [])
    const resultProject = Projects.IGNORE
    return usePost<{ invoiceIdList: number[] }, undefined>(url, payloadProject, resultProject)
}

export const useDoUndoCombineInvoice = () => {
    const url = '/lcbm-accounting-api/api/doUndoCombine'
    const payloadProject = useCallback((obj: { invoiceIdList: number[] }) => obj.invoiceIdList, [])
    const resultProject = Projects.IGNORE
    return usePost<{ invoiceIdList: number[] }, undefined>(url, payloadProject, resultProject)
}

export const useDoApproveInvoice = () => {
    const url = '/lcbm-accounting-api/api/doApprove'
    const payloadProject = useCallback((obj: { invoiceIdList: number[] }) => obj.invoiceIdList, [])
    const resultProject = Projects.IGNORE
    return usePost<{ invoiceIdList: number[] }, undefined>(url, payloadProject, resultProject)
}

export const useDoUndoApproveInvoice = () => {
    const url = '/lcbm-accounting-api/api/doUndoApprove'
    const payloadProject = useCallback((obj: { invoiceIdList: number[] }) => obj.invoiceIdList, [])
    const resultProject = Projects.IGNORE
    return usePost<{ invoiceIdList: number[] }, undefined>(url, payloadProject, resultProject)
}

export const useDoReleaseInvoice = () => {
    const url = '/lcbm-accounting-api/api/doRelease'
    const payloadProject = useCallback((obj: { invoiceIdList: number[] }) => obj.invoiceIdList, [])
    const resultProject = Projects.IGNORE
    return usePost<{ invoiceIdList: number[] }, undefined>(url, payloadProject, resultProject)
}

// AIS011
export const useGetInvoice = () => {
    const url = '/lcbm-accounting-api/api/getInvoice'
    const payloadProject = useCallback((obj: { invoiceId: number }) => obj.invoiceId, [])
    const resultProject = useCallback(giGrInvoiceDeserializer, [])
    return usePost<{ invoiceId: number }, GiGrInvoice>(url, payloadProject, resultProject)
}

export const useSaveInvoice = () => {
    const url = '/lcbm-accounting-api/api/editInvoice'
    const payloadProject = useCallback(giGrInvoiceSaveVoSerializer, [])
    const resultProject = Projects.IGNORE
    return usePost<GiGrInvoiceSaveVo, undefined>(url, payloadProject, resultProject)
}

export const useGetPaymentStatusList = () => {
    const url = '/lcbm-arap-api/api/getPaymentStatusList'
    const payloadProject = useCallback(aRViewToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(aRTransferFromJson), [])
    return usePost<ARView, PageResultEntity<ARTransfer>>(url, payloadProject, resultProject)
}

export const useUpdatePaymentAmount = () => {
    const url = '/lcbm-arap-api/api/updatePaymentAmount'
    const payloadProject = useCallback(PaidTransferToJson, [])
    const resultProject = Projects.IGNORE
    return usePost<PaidTransfer, undefined>(url, payloadProject, resultProject)
}

export const useFullPaymentReceived = () => {
    const url = '/lcbm-arap-api/api/fullPaymentReceived'
    const payloadProject = useCallback((obj: { arIds: number[] }) => obj.arIds, [])
    const resultProject = Projects.IGNORE
    return usePost<{ arIds: number[] }, undefined>(url, payloadProject, resultProject)
}



