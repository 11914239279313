import { Project } from "../../../utils/Project"
import { PageResultEntity, PageResultEntityJson } from "../../utils/PageResultEntity"


export interface CustomerUsageResult {
    monthNewList: string[],
    page: PageResultEntity<CustomerUsageTransfer>
}

interface CustomerUsageResultJson {
    monthNewList: string[],
    page: PageResultEntityJson<CustomerUsageTransfer>,
}

export const customerUsageResultDeserializer: Project<CustomerUsageResultJson, CustomerUsageResult> = json => ({
    monthNewList: json.monthNewList,
    page: json.page
})

export type CustomerUsageTransfer = Required & Partial<Optional>

interface Required extends JsonRequired { }

interface Optional extends Omit<JsonOptional, 'updatedDateTime' | 'updatedDate' | 'planStartDate' | 'planEndDate'> {
    updatedDateTime: Date,
    updatedDate: Date,
    planStartDate: Date,
    planEndDate: Date,
}

interface JsonRequired {
}

interface JsonOptional {
    bomId: number,
    customerCode: string,
    grade: string,
    katashiki: string,
    level1PartsNo: string,
    modelNo: string,
    monthlyQtyMap: Map<string, string>,
    planEndDate: string,
    planQty: string,
    planStartDate: string,
    updatedDate: string,
    updatedDateTime: number,
    monthList: Array<string>,
    monthlyQtyList: Array<number>,
}
