import { Arrays } from "@rithe/utils"
import React, { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useComMasterGetActiveCbds } from "../../../services/common/apis/commonMasterApi"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { useGetPoMonitoringDetail } from "../../../services/order/apis/PurchaseOrderApi"
import { PoSpotDetailStatus } from "../../../services/order/enums/PoSpotDetailStatus"
import { SimulatedType } from "../../../services/order/enums/SimulatedType"
import { EstimatedData, InboundPalnData, OrderDetailHead, OrderPartsData, OrderPartsDetail } from "../../../services/order/models/OrderDetailResult"
import { datetimeFromJson } from "../../../services/utils/deserializer"
import { OPS021PcUi } from "./OPS021PcUi"

export const OPS021 = () => {
    const { orderId } = useParams() as any
    const [headerInfo, setHeaderInfo] = useState<OrderDetailHead>({} as OrderDetailHead)
    const [partsDetailList, setPartsDetailList] = useState<OrderPartsData[]>([])
    const [cbdsList, setCbdsList] = useState<TnvCbds[]>([])
    const creatInboundPlanList = useCallback((partsDetailList: OrderPartsDetail[], headerInfo: OrderDetailHead) => {

        let list: OrderPartsDetail[] = partsDetailList.filter(f => f.spotDetailStatus !== PoSpotDetailStatus.CANCELLED)
        let headerData: OrderDetailHead = headerInfo
        let newPartsDetailList: OrderPartsData[] = []
        let allIPlanDateList: number[] = []
        let allEstimatedDataList: string[] = []
        list.filter((f) => f.inboundPlanList).map(m => m.inboundPlanList.map(m2 => m2.crdDate.getTime())).map(m3 => m3.forEach(o => { allIPlanDateList.push(o) }))
        list.filter((f) => f.estimatedInboundList).map(m => m.estimatedInboundList.map(m2 => m2).sort((a, b) => {
            if (a.estimatedCrdDate.getTime() === b.estimatedCrdDate.getTime()) {
                if (a.fromBuId === b.fromBuId) {
                    return a.qty - b.qty
                } else {
                    return a.fromBuId - b.fromBuId
                }
            } else {
                if (a.estimatedCrdDate.getTime() > b.estimatedCrdDate.getTime()) {
                    return 1
                } else {
                    return -1
                }
            }
        }).map(m3 => [m3.estimatedCrdDate.getTime(), m3.fromCompany, SimulatedType.ON_STOCK !== m3.simulatedType ? m3.toCompany : ''].join(','))).map(m4 => m4.forEach(o => { allEstimatedDataList.push(o) }))

        allIPlanDateList = Arrays.distinct(allIPlanDateList).sort((a, b) => { return a - b })
        allEstimatedDataList = Arrays.distinct(allEstimatedDataList).sort()

        list.forEach((opd) => {
            // const acutalOrderQty = (opd.orderQty ?? 0) - (opd.forceCompletedQty ?? 0)
            let newinboundPlanList: InboundPalnData[] = []
            let inboundQtyTotal: number = opd?.currentInbQty | 0
            // let planQtyTotal: number = 0
            let i: number = 0
            allIPlanDateList.forEach((pDate) => {
                if (opd?.estimatedInboundList && opd.estimatedInboundList.length > 0) {
                    for (i; i < opd.estimatedInboundList.length;) {
                        if (pDate >= opd.estimatedInboundList[i].estimatedCrdDate.getTime()) {
                            inboundQtyTotal = inboundQtyTotal + opd.estimatedInboundList[i]?.qty | 0
                            i++
                        } else {
                            break
                        }
                    }
                }
                let flag: boolean = true
                if (opd.inboundPlanList.length > 0) {
                    let planData: InboundPalnData | undefined = opd.inboundPlanList.find(o => o.crdDate.getTime() === pDate)
                    if (planData) {
                        flag = false
                        const planQtyTotal = planData.crdQty
                        if (opd.estimatedInboundList && opd.estimatedInboundList.length > 0) {
                            if (headerData.currentDate.getTime() >= pDate) {
                                planData.status = planQtyTotal > inboundQtyTotal ? 'NG-' + (planQtyTotal - inboundQtyTotal) : 'OK'
                            } else {
                                planData.status = 'OK'
                            }
                        } else {
                            planData.status = 'OK'
                        }
                        // planQtyTotal = planQtyTotal + planData.crdQty
                        // const actualQty = (planQtyTotal > acutalOrderQty ? acutalOrderQty : planQtyTotal)
                        // planData.status = actualQty > inboundQtyTotal ? 'NG-' + (planQtyTotal - inboundQtyTotal) : 'OK'

                        newinboundPlanList.push(planData)
                    }
                }
                if (flag) {
                    newinboundPlanList.push({ crdDate: datetimeFromJson(pDate), status: 'OK', crdQty: 0 } as InboundPalnData)
                }
            })

            let newEstimatedInboundList: EstimatedData[] = []
            allEstimatedDataList.forEach((eData) => {
                let flag: boolean = true

                if (opd.estimatedInboundList.length > 0) {
                    let estimatedData: EstimatedData | undefined = opd.estimatedInboundList.find(f => [f.estimatedCrdDate.getTime(), f.fromCompany, SimulatedType.ON_STOCK !== f.simulatedType ? f.toCompany : ''].join(',') === eData)
                    if (estimatedData) {
                        flag = false
                        if (SimulatedType.ON_STOCK !== estimatedData.simulatedType) {
                            estimatedData.companyTitle = estimatedData.fromCompany + ' Ship To ' + estimatedData.toCompany
                        } else {
                            estimatedData.companyTitle = estimatedData.fromCompany
                        }
                        newEstimatedInboundList.push(estimatedData)
                    }
                }
                if (flag) {
                    let tempDataList: string[] = eData.split(',')
                    let crdDate: number = Number(tempDataList[0])
                    let companyTitle: string = tempDataList[1]
                    if ('' !== tempDataList[2]) {
                        companyTitle = tempDataList[1] + ' Ship To ' + tempDataList[2]
                    }
                    newEstimatedInboundList.push({ estimatedCrdDate: datetimeFromJson(crdDate), companyTitle: companyTitle, qty: 0 } as EstimatedData)
                }

            })
            newPartsDetailList.push({
                ...opd,
                inboundPlanList: newinboundPlanList,
                estimatedInboundList: newEstimatedInboundList,
            })
        })

        return newPartsDetailList
    }, [])

    const getPoDetail = useGetPoMonitoringDetail()
    useEffect(() => {
        if (orderId) {
            getPoDetail({ orderId: orderId }, { silent: true, serialized: true }).then(result => {
                setHeaderInfo(result?.headerInfo || {} as OrderDetailHead)
                if (result?.partsDetailList && result.partsDetailList.length) {
                    setPartsDetailList(creatInboundPlanList(result.partsDetailList, result.headerInfo))
                }
            })
        } else {
            //TODO Show ERROR Message
            setHeaderInfo({} as OrderDetailHead)
            setPartsDetailList([])
        }
    }, [creatInboundPlanList, getPoDetail, orderId])

    const getCbds = useComMasterGetActiveCbds()
    useEffect(() => {
        getCbds({ types: [] }, { silent: true }).then(result => {
            setCbdsList(result || [])
        })
    }, [getCbds])

    const props = {
        headerInfo: headerInfo,
        partsDetailList: partsDetailList,
        cbdsList: cbdsList
    }

    return <OPS021PcUi {...props} />
}