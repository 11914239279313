import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Row, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from '@rithe/data-grid'
import { DateRangeItem } from '@rithe/form'
import { Records } from "@rithe/utils"
import React, { memo, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { SectionCard } from '../../../components/Card/SectionCard'
import { SectionCardContent } from '../../../components/Card/SectionCardContent'
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DownloadGroupedToolbarAction } from '../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction'
import { GroupedCallbackItem } from '../../../components/DataGrid/toolbarActions/GroupedCallbackItem'
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { View } from '../../../components/View/View'
import { useDownloadUserAccessLog } from '../../../services/privilege/api/UserAccessHistoryApi'
import { AccessHistoryType } from '../../../services/privilege/enums/AccessHistoryType'
import { AccessPlatformType } from '../../../services/privilege/enums/AccessPlatformType'
import { UserAccessHistoryListFactor } from '../../../services/privilege/models/UserAccessHistoryFactor'
import { UserAccessHistoryListResult } from '../../../services/privilege/models/UserAccessHistoryListResult'
import { CAS030CodeItems } from './CAS030CodeItems'
export interface CAS030PcUiProps {
  filters: UserAccessHistoryListFactor,
  setFilters: React.Dispatch<React.SetStateAction<UserAccessHistoryListFactor>>,
  search: (filters: UserAccessHistoryListFactor) => void,
  data: UserAccessHistoryListResult[]
}

export function CAS030PcUi(props: CAS030PcUiProps) {
  return <View flex>
    <SectionCard>
      <SectionCardContent>
        <DataTable {...props} />
      </SectionCardContent>
    </SectionCard>
  </View>

}

const DataTable = memo(({filters, data, setFilters, search}: CAS030PcUiProps) => {
  const intl = useIntl()
  const getPlatform = useCallback((row: Row) => {
    const status = row.platform as number
    if (status === AccessPlatformType.WEB) {
      return intl.formatMessage({id: 'web'})
    }
    if (status === AccessPlatformType.APP) {
      return intl.formatMessage({id: 'app'})
    }
    return ""
  }, [intl])
  const [selections, setSelections] = useState<string[]>([])
  const actionProps1 = useMemo(() => ({selections, filters}), [selections, filters])
  const columns = useMemo(() => [
    {field: 'authId', dataTypeName: 'string', title: intl.formatMessage({id: 'field.loginId'}), width: 200},
    {field: 'ip', dataTypeName: 'string', title: intl.formatMessage({id: 'field.ip'}), width: 200},
    {field: 'operationType', dataTypeName: 'string', title: intl.formatMessage({id: 'field.operationType'}), width: 250},
    {field: 'accessTime', dataTypeName: 'datetime', title: intl.formatMessage({id: 'field.accessTime'}), width: 250},
    {field: 'platform', dataTypeName: 'string', title: intl.formatMessage({id: 'field.platform'}), width: 150, getCellValue: getPlatform},
    {field: 'accessDevice', dataTypeName: 'string', title: intl.formatMessage({id: 'field.accessDevice'}), width: 200},
    {field: 'contents', dataTypeName: 'string', title: intl.formatMessage({id: 'field.operationDescription'}), width: 350},
  ], [intl, getPlatform])

  const getRowId = useCallback((row: any) => row.id, [])
  const itemPropsForFilters = useMemo(() => ({filters, setFilters, search}), [filters, search, setFilters])
  return <DataGrid>
    <ToolbarLayout />
    <TableLayout Container={FlexScrollbar}>
      <TableHeaderLayout sticky />
      <TableBodyLayout />
    </TableLayout>
    <PaginationLayout Pagination={Pagination} />
    <DataTypePreset />
    <Data rows={data} columns={columns} getRowId={getRowId} />
    <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps1} />
    <ColumnFreeze />
    <ColumnVisibility
      defaultHiddenFields={['failReason']}
      ToolbarButton={ColumnVisibilityToolbarButton} />
    <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
    <ColumnResizing defaultSize={Records.from(columns.map(({field, width}) => [field, width ?? 0]))} />
    <Searching ignoreCase Input={SearchInput} />
    <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
    <Sorting />
    <Filtering />
    <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
    <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections}/>
    <Action width={150} />
  </DataGrid>
})

const FilterItem = memo((props: {
  filters: UserAccessHistoryListFactor,
  setFilters: React.Dispatch<React.SetStateAction<UserAccessHistoryListFactor>>,
  search: (filters: UserAccessHistoryListFactor) => void
}) => {
  const {filters, setFilters, search} = props

  const orderDateGetValue = useCallback((filters: UserAccessHistoryListFactor) => {
    return [filters.accessDateStart ?? null, filters.accessDateEnd ?? null]
  }, [])

  const orderDateMapValue = useCallback((filters: UserAccessHistoryListFactor, value: any) => {
    return {...filters ?? {}, accessDateStart: value[0], accessDateEnd: value[1]}
  }, [])

  const clear = useCallback((filters: UserAccessHistoryListFactor) => {
    return {page: filters.page, type: [AccessHistoryType.LOGIN]}
  }, [])

  const filterCounter = useCallback((filters: UserAccessHistoryListFactor) => {
    return [
      filters.platform,
      filters.accessDateStart || filters.accessDateEnd,
    ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
  }, [])
  const intl = useIntl()

  return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter} >
    <CAS030CodeItems field="platform" label={intl.formatMessage({id: 'platform'})} data={[{
        codeValue: AccessPlatformType.WEB,
        codeName: intl.formatMessage({id: 'web'})
    }, {
        codeValue: AccessPlatformType.APP,
        codeName: intl.formatMessage({id: 'app'})
    }]} />
    <DateRangeItem field="accessDateRange" label={intl.formatMessage({id: 'accessDateRange'})} getValue={orderDateGetValue} mapValue={orderDateMapValue}/>
  </FilterToolbarItem>
})

const DownloadAction = memo(({selections, filters}: {selections: string[], filters: UserAccessHistoryListFactor}) => {
  const dowloadApi = useDownloadUserAccessLog()
  const download = useCallback(() => {
    dowloadApi({ids: selections, apiFilter: filters})
  }, [dowloadApi, selections, filters])
  return <>
    <DownloadGroupedToolbarAction >
      {onClose => <>
        <GroupedCallbackItem label={<FormattedMessage id="downloadAccessHistory" />} callback={download} onClose={onClose} />
      </>}
    </DownloadGroupedToolbarAction>
  </>
}
)
