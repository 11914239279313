import { Tooltip } from "@material-ui/core"
import { Undo } from "@material-ui/icons"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import moment from "moment"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { Access } from "../../../components/Access/Access"
import { IconButton } from "../../../components/Button/IconButton"
import { OrderCalcStatus } from "../../../services/order/enums/OrderCalcStatus"
import { ConfirmDialog } from "./ConfirmDialog"
import { DetailTableRow } from "./DetailTable"
import { OrderCalculationType, occls010Actions } from "./OCCLS010Slice"

export interface DeailWithdrawRowActionProps extends DataGridRowActionProps {
  type: OrderCalculationType
}

export function DetailWithdrawRowAction(props: DeailWithdrawRowActionProps) {
  const { tableRow, type } = props
  const row = tableRow.row as DetailTableRow

  const dispatch = useDispatch()
  const intl = useIntl()
  const [open, setOpen] = useState(false)
  const poGroupId = row.poGroupId!

  const display = row.status === OrderCalcStatus.PENDING_ISSUED && row.inCurrentMonth && false

  if (!display) {
    return null
  } else {
    return <>
      <Access access="STCK.OCCLS010.WITHDRAW">
        <Tooltip title={<FormattedMessage id="withdraw" />}>
          <IconButton onClick={() => setOpen(true)}>
            <Undo />
          </IconButton>
        </Tooltip>
      </Access>
      <ConfirmDialog
        open={open}
        onClose={() => setOpen(false)}
        title={<FormattedMessage id="withdraw" />}
        // content={`Are you sure would like to withdraw <${row.customerCode}-${row.targetMonth ? moment(row.targetMonth).format('YYYYMM') : ''}-${row.orderVersion}>? This action cannot be reversed. `}
        content={intl.formatMessage({id: "OC_WITHDRAW_CONFIRM"}).replace('{0}', (row.orderCalcNo??'') + "(" +  (row.targetMonth ? moment(row.targetMonth).format('YYYYMM') : '') + ")")}
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={<FormattedMessage id="withdraw" />}
        onConfirm={() => dispatch(occls010Actions.withdraw({ poGroupId, type }))}
      />
    </>
  }
}