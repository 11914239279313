import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"
import { ContractRouteStatus } from "../enums/ContractRouteStatus"


export type TnmContractRoute = Required & Partial<Optional> & TableEntity
type TnmContractRouteJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const tnmContractRouteFromJson: Project<TnmContractRouteJson, TnmContractRoute> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
    status: ContractRouteStatus,
}

interface JsonRequired {
    contrRouteId: number,
}

interface JsonOptional {
    contrRouteCode: string,
    description: string,
    status: number,
}