import { Project } from "../../../utils/Project"

export type IntBatchTransfer = Required & Partial<Optional>
export type IntBatchTransferJson = JsonRequired & Partial<JsonOptional>
export const intBatchTransferToJson: Project<IntBatchTransfer, IntBatchTransferJson> = obj => ({
    ...obj,
})
export const intBatchTransferFromJson: Project<IntBatchTransferJson, IntBatchTransfer> = json => ({
    ...json,
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
}

interface JsonRequired {
}

interface JsonOptional {
    batchId: string
    dataSource: number
    generateTime: number
    launchTime: number
    backupTime: number
    verifyTime: number
    storeTime: number
    runTime: number
    dataType: number
    createdDate: number
    updatedDate: number
}