import { CheckItem, EntryItem, Form, StringItem } from "@rithe/form"
import { default as React, useCallback, useMemo } from "react"
import { useIntl } from "react-intl"
import { DownloadCallbackViewAction } from '../../../components/Action/DownloadCallbackViewAction'
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { View } from "../../../components/View/View"
import { useDownloadOverallVisualisation } from '../../../services/managementTool/apis/mtDownloadApis'
import { DownloadOverallVisualisationFactor } from '../../../services/managementTool/models/DownloadOverallVisualisationFactor'
import { IdAndCode } from "../../../services/managementTool/models/IdAndCode"

interface MTS050PcUiProps {
    customerList: IdAndCode[],
    contractRouteList: IdAndCode[],
    factor: DownloadOverallVisualisationFactor,
    setFactor: React.Dispatch<React.SetStateAction<DownloadOverallVisualisationFactor>>,
    findContractRouteList: (factor: DownloadOverallVisualisationFactor) => void,
}

export const MTS050PcUi = (props: MTS050PcUiProps) => {
    const intl = useIntl()
    const { customerList, contractRouteList, factor, setFactor, findContractRouteList } = props
    const customersMap = useMemo(() => customerList ? customerList.map(m => [m.id, m.code] as [number, string]) : [], [customerList])
    const contractRouteMap = useMemo(() => contractRouteList ? contractRouteList.map(m => [m.id, m.code] as [number, string]) : [], [contractRouteList])

    const handleDataChange = useCallback<React.Dispatch<React.SetStateAction<DownloadOverallVisualisationFactor>>>(nextDraftDataFunc => {
        setFactor(factor => {
            const newFactor = typeof nextDraftDataFunc === 'function' ? nextDraftDataFunc(factor) : nextDraftDataFunc
            if (factor.customerId !== newFactor.customerId) {
                const resetFactor = { ...newFactor, contractRouteId: undefined }
                findContractRouteList(resetFactor)
                return resetFactor
            }
            return newFactor
        })
    }, [findContractRouteList, setFactor])

    return <View actions={[<DownloadAction factor={factor} />]}>
        <SectionCard>
            <SectionCardContent>
                <Form data={factor} setData={handleDataChange} labelDisplay="block" helperDisplay="tooltip" labelWidth={260} columnCount={1} >
                    <EntryItem field="customerId" required label={intl.formatMessage({ id: 'field.customerCode' })} entries={customersMap} />
                    <EntryItem field="contractRouteId" required label={intl.formatMessage({ id: 'field.contrRouteCode' })} entries={contractRouteMap} />
                    <StringItem field="partsNo" label={intl.formatMessage({ id: 'field.partsNo' })} />
                    <CheckItem field="withPoNoFlag" label={intl.formatMessage({ id: 'withPoNoFlag' })} />
                    <CheckItem field="withInvoiceNoFlag" label={intl.formatMessage({ id: 'withInvoiceNoFlag' })} />
                </Form>
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DownloadAction = ({ factor }: { factor: DownloadOverallVisualisationFactor, }) => {
    const downloadTemplate = useDownloadOverallVisualisation()
    const download = useCallback(() => {
        downloadTemplate(factor)
    }, [downloadTemplate, factor])
    return <DownloadCallbackViewAction access="MT.MTS050.DOWNLOAD" callback={download} />
}