import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"

export type CustomerStockList = Required & Partial<Optional> & TableEntity
type CustomerStockListJson = JsonRequired & Partial<JsonOptional> & TableEntityJson

export const customerStockFromJson: Project<CustomerStockListJson, CustomerStockList> = json => ({
    ...tableEntityFromJson(json),
    customerStockDate: dateFromJson(json.customerStockDate),
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'customerStockDate'> {
    customerStockDate: Date,
}

interface JsonRequired {
    customerPartsId: number
}

interface JsonOptional {
    partsNo: string,
    customerCode: string,
    sourceStr: string,
    externalRefNo: string,
    customerPartsNo: string,
    customerBackNo: string,
    customerStockDate: string,
    // customerStockDateStr: string,
    customerStockQty: number,
    customerStockQtyStr: number,
    decimalDigits: number,
    customerPartsId: number,
}