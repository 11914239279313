import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson, tableEntityToJson } from "../../utils/TableEntity"

export type TnmCreditSetting = Required & Partial<Optional> & TableEntity
type TnmCreditSettingJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const tnmCreditSettingToJson: Project<TnmCreditSetting, TnmCreditSettingJson> = obj => ({
    ...tableEntityToJson(obj),
})
export const tnmCreditSettingFromJson: Project<TnmCreditSettingJson, TnmCreditSetting> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
}

interface JsonRequired {
}

interface JsonOptional {
    settingId: number,
    supplierId: number,
    sellerBuId: number,
    buyerBuId: number,
    customerId: number,
    creditId: number,
    currency: string,
    limitAmount: number,
    autoHoldOrder: number,
    autoHoldInvoice: number,
    status: number,
}