import { makeStyles } from "@material-ui/core";
import { FormInputWrapperProps } from "@rithe/form";
import clsx from 'clsx';
import React from "react";

export function InputWrapper(props: FormInputWrapperProps) {
    const {
        readonly,
        disabled,
        info,
        success,
        warning,
        error,
        children,
    } = props

    const styles = useStyles()
    return <div
        onClick={e => {
            if (e.currentTarget.contains(e.target as any)) {
                const input = e.currentTarget.querySelector('input')
                if (input && input.type !== 'checkbox') {
                    input.parentElement?.click()
                }
            }
        }}
        className={clsx(
            styles.root,
            info && styles.info,
            success && styles.success,
            warning && styles.warning,
            error && styles.error,
            readonly && styles.readonly,
            disabled && styles.disabled,
        )}
    >
        {children}
    </div>
}

const useStyles = makeStyles(theme => ({
    root: {
        flex: '1 1 auto',
        overflow: 'hidden',
        boxSizing: 'border-box',
        minHeight: 36,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'stretch',
        padding: theme.spacing(0, 2),
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        '&>*': {
            marginLeft: theme.spacing(1)
        }
    },
    info: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.info.main,
    },
    success: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.success.main,
    },
    warning: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.warning.main,
    },
    error: {
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.error.main,
    },
    readonly: {
        borderColor: 'transparent',
        backgroundColor: theme.palette.background.readonly.main,
    },
    disabled: {
        color: theme.palette.text.disabled,
        borderColor: 'transparent',
        backgroundColor: theme.palette.background.disabled.main,
    },
}))