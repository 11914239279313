import React from 'react'
import { FormattedMessage } from 'react-intl'
import { CallbackViewAction } from './CallbackViewAction'

interface ModifyCallbackViewActionProps {
    access?: string,
    disabled?: boolean,
    outlined?: boolean,
    callback: () => void,
}

export const ModifyCallbackViewAction = (props: ModifyCallbackViewActionProps) => {
    return <CallbackViewAction {...props} title={<FormattedMessage id="modify" />} />
}