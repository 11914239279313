import { Send } from "@material-ui/icons"
import React from "react"
import { FormattedMessage } from "react-intl"
import { CallbackToolbarAction } from "./CallbackToolbarAction"

interface ReleaseCallbackToolbarActionProps {
    access?: string,
    power?: boolean,
    title?: NonNullable<React.ReactNode>,
    icon?: NonNullable<React.ReactNode>,
    callback: () => void,
    disabled?: boolean,
}

export const ReleaseCallbackToolbarAction = (props: ReleaseCallbackToolbarActionProps) => {
    const defaultProps = {
        title: <FormattedMessage id="release" />,
        icon: <Send />
    }
    return <CallbackToolbarAction {...defaultProps} {...props} />
}