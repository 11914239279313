import { DialogActions, DialogContent } from "@material-ui/core"
import { ColumnFreeze, ColumnOrdering, ColumnResizing, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { Form } from "@rithe/form"
import { DateItem } from "@rithe/form/dist/components/DateItem"
import { Records } from "@rithe/utils"
import { SetStateAction, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { CallbackViewAction } from "../../../components/Action/CallbackViewAction"
import { DownloadCallbackViewAction } from "../../../components/Action/DownloadCallbackViewAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { ActiveFlagTypeProvider } from "../../../components/DataGrid/typeProviders/ActiveFlagTypeProvider"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CardDialog } from "../../../components/Dialog/CardDialog"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { DialogUploadAction } from "../../../components/Dialog/DialogUploadAction"
import { View } from "../../../components/View/View"
import { useUploadExternalMPDStock } from "../../../services/integration/apis/ExternalMPDStockUploadApis"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"

import { ExternalMPDStockTransfer } from "../../../services/smt/models/ExternalMPDStockTransfer"
import { ExternalMPDStockView } from "../../../services/smt/models/ExternalMPDStockView"
import { dateToJson } from "../../../services/utils/serializer"
import { applicationActions } from "../../Application/applicationSlice"

interface INT070PcUiProps {
    filters: ExternalMPDStockView,
    setFilters: React.Dispatch<React.SetStateAction<ExternalMPDStockView>>,
    data: ExternalMPDStockTransfer[],
    setData: React.Dispatch<SetStateAction<ExternalMPDStockTransfer[]>>,
    search: (filters: ExternalMPDStockView) => void,
    totalCount: number
}


export const INT070PcUi = (props: INT070PcUiProps) => {
    const { filters, search } = props
    const intl = useIntl()

    return (
        <View>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={1}
                    title={intl.formatMessage({ id: 'External MPD Stock List' })}
                    actions={[DownloadAction(), UploadAction({ filters, search })]}
                />
                <SectionCardContent>
                    <DataTable {...props} />
                </SectionCardContent>
            </SectionCard>
        </View >
    )
}


const DataTable = ({ filters, setFilters, data, search }: INT070PcUiProps) => {

    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])

    const columns = useMemo(() => [
        { field: 'fileName', dataTypeName: "string", title: intl.formatMessage({ id: 'field.fileName' }), width: 200 },
        { field: 'uploadDateTime', dataTypeName: 'datetime', title: intl.formatMessage({ id: 'field.uploadDateTime' }), width: 200 },
        { field: 'stockDate', dataTypeName: 'datetime', title: intl.formatMessage({ id: 'field.stockDate' }), width: 200 },
        { field: 'createBy', dataTypeName: 'datetime', title: intl.formatMessage({ id: 'field.createBy' }), width: 200 },
        { field: 'createdDate', dataTypeName: 'datetime', title: intl.formatMessage({ id: 'field.createdDate' }), width: 200 },
    ], [intl])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search }), [filters, setFilters, search])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.DataSource} />
        <ActiveFlagTypeProvider />
        <Data rows={[]} columns={columns} />
        <ColumnFreeze />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
    </DataGrid>
}

const FilterItem = (props: {
    filters: ExternalMPDStockView,
    setFilters: React.Dispatch<React.SetStateAction<ExternalMPDStockView>>,
    search: (filters: ExternalMPDStockView) => void
}) => {
    const { filters, setFilters, search } = props

    const clear = useCallback((filters: ExternalMPDStockView) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: ExternalMPDStockView) => {
        return [
            filters.stockDate
        ].filter(value => value !== undefined && value !== null).length
    }, [])
    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <DateItem field='stockDate' label={intl.formatMessage({ id: 'stockDate' })} />
    </FilterToolbarItem>
}


export const DownloadAction = () => {
    const download = useCallback(() => {
    }, [])

    return <DownloadCallbackViewAction /*access="INT.INT070.DOWNLOAD"*/ callback={() => download()} outlined />

}


interface UploadRemark {
    uploadDate?: Date
}

const UploadAction = ({ filters, search }: { filters: ExternalMPDStockView, search: (filters: ExternalMPDStockView) => void }) => {
    const [open, setOpen] = useState<boolean>(false)
    const [date, setDate] = useState<UploadRemark>({ uploadDate: new Date() })
    const dispatch = useDispatch()
    const uploadApi = useUploadExternalMPDStock()

    const upload = useCallback((files: FileList | null) => {
        if (files === null) {
            dispatch(applicationActions.pushError({ title: { code: 'upload' }, messages: { code: 'w0342' } }))
            return
        }
        uploadApi({
            file: files[0], stockDate: dateToJson(date.uploadDate ?? new Date())
        }, { serialized: true }).then(() => {
            search(filters)
        })
        setOpen(false)

    }, [date.uploadDate, dispatch, filters, search, uploadApi])

    const intl = useIntl()

    return <>
        <CallbackViewAction /*access="INT.INT070.UPLOAD"*/ callback={() => setOpen(true)} title={"upload"} />
        <CardDialog open={open} style={{ overflow: 'hidden' }} maxWidth="sm" fullWidth fullScreen={false}>
            <DialogHeader onClose={() => setOpen(false)}>
                <FormattedMessage id="selectStockDate" />
            </DialogHeader>
            <DialogContent>
                <Form data={date} setData={setDate} labelDisplay="block" helperDisplay="tooltip" minWidth={500} maxWidth={500} columnCount={1}>
                    <DateItem field="uploadDate" label={intl.formatMessage({ id: 'stockDate' })} />
                </Form>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={() => setOpen(false)} />
                <DialogUploadAction callback={upload} title={<FormattedMessage id="upload" />} />
            </DialogActions>
        </CardDialog>
    </>
}

