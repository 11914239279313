import {Project} from "../../../utils/Project"
import {dateFromJson} from "../../utils/deserializer"
import {AccessPlatformType} from "../enums/AccessPlatformType"
export type UserAccessHistoryListResult = Required & Partial<Optional>

export type UserAccessHistoryListResultJson = JsonRequired & Partial<JsonOptional>

export const userAccessHistoryListResult: Project<UserAccessHistoryListResultJson, UserAccessHistoryListResult> = json => ({
    ...json,
    accessTime: dateFromJson(json.accessTime),
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional,'accessTime'> {
    accessTime: Date,
    platform: AccessPlatformType
}

interface JsonRequired {
  id: string
  authId: string
  ip: string
  url: string
  requestType: string
  content: string
  accessDevice: string
  address: string
  operationType: string
}

interface JsonOptional {
    accessTime: string
    platform: number
}
