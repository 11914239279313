import React, { useCallback, useMemo, useState } from "react"
import { useGetCustomerUsagePage } from "../../../services/smt/api/CustomerUsageApi"
import { CustomerUsageTransfer } from "../../../services/smt/models/CustomerUsageTransfer"
import { CustomerUsageView, customerUsageViewFromJson, customerUsageViewToJson } from "../../../services/smt/models/CustomerUsageView"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { CSUHS110PcUi } from "./CSUHS110PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }

const searchCachekey = sessionKeys.Filters_CSUHS110

export const CSUHS110 = () => {
    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? customerUsageViewFromJson(JSON.parse(filters)) : null
    }, [])

    const [filters, setFilters] = useState<CustomerUsageView>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<CustomerUsageTransfer[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)

    const getCustomerUsageList = useGetCustomerUsagePage()
    const search = useCallback((filters: CustomerUsageView) => {
        getCustomerUsageList(filters, { silent: true, serialized: true }).then(result => {
            if (result?.page) {
                setTotalCount(result.page.page?.totalCount || 0)
                setData(result.page?.data || [])
                sessionStorage.setItem(searchCachekey, JSON.stringify(customerUsageViewToJson(filters)))
            }
        })
    }, [getCustomerUsageList, setData])

    useSearchOnLoad(search, filters)

    return <CSUHS110PcUi
        filters={filters}
        search={search}
        data={data}
        totalCount={totalCount}
        setFilters={setFilters}
    />
}