import { Project } from "@rithe/utils"

export type DownloadInboundFormResult = Required & Partial<Optional>
type DownloadInboundFormResultJson = JsonRequired & Partial<JsonOptional>

export const downloadInboundFormResultFromJson: Project<DownloadInboundFormResultJson, DownloadInboundFormResult> = json => ({
    ...json,
})

interface Required extends JsonRequired { }

interface Optional extends JsonOptional { }

interface JsonRequired {
    contractPartsId: number,
    partsId: number,
}

interface JsonOptional {
    contractPartsId: number,
    partsId: number,
    partsNo: string,
    unitPartsNo: string,
    unitPartsName: string,
    customerCode: string,
    receiver: string,
    receiverUid: string,
    buyer: string,
    buyerUid: string,
    spq: number
}