import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"

export type RollPackageInfo = Required & Partial<Optional>

type RollPackageInfoJson = JsonRequired & Partial<JsonOptional>

export const rollPackageInfoFromJson: Project<RollPackageInfoJson, RollPackageInfo> = json => ({
    ...json,
    productionDate: dateFromJson(json.productionDate),
})

interface Required extends JsonRequired {}

interface Optional extends Omit<JsonOptional, 'productionDate'> {
    productionDate: Date,
}

interface JsonRequired {
}

interface JsonOptional {
    inbPkgId: number,
    outerPackageNo: string,
    soDetailOrigin: string,
    number: string,
    productionDate: string,
    stockDays: number,
    sellerPartsNo: string,
    notYetDeliveryQty: number,
    soNo: string
}