import {Project} from "../../../utils/Project"

export type OrderCalculationUpdateResult = Required & Partial<Optional>

type OrderCalculationUpdateResultJson = JsonRequired & Partial<JsonOptional>

export const orderCalculationUpdateResultToJson: Project<OrderCalculationUpdateResult, OrderCalculationUpdateResultJson> = obj => ({
    ...obj,
})

export const orderCalculationUpdateResultFromJson: Project<OrderCalculationUpdateResultJson, OrderCalculationUpdateResult> = json => ({
    ...json,
})

interface Required extends JsonRequired {

}

interface Optional {
    
}

interface JsonRequired {
    warningFlag: number

}

interface JsonOptional {
    
}
