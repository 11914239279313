import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { dateFromJson } from "../../utils/deserializer"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"
import { ActiveFlag } from "../enums/ActiveFlag"

export type CustomerDownloadFactor = Omit<CustomerFactor, 'page'>
type CustomerDownloadFactorJson = Omit<CustomerFactorJson, 'page'>
export const customerDownloadFactorToJson: Project<CustomerDownloadFactor, CustomerDownloadFactorJson> = obj => ({
    ...obj,
    createdDateStart: dateToJson(obj.createdDateStart),
    createdDateEnd: dateToJson(obj.createdDateEnd),
    updatedDateStart: dateToJson(obj.updatedDateStart),
    updatedDateEnd: dateToJson(obj.updatedDateEnd),
})

export type CustomerFactor = Required & Partial<Optional>
type CustomerFactorJson = JsonRequired & Partial<JsonOptional>
export const customerFactorToJson: Project<CustomerFactor, CustomerFactorJson> = obj => ({
    ...obj,
    createdDateStart: dateToJson(obj.createdDateStart),
    createdDateEnd: dateToJson(obj.createdDateEnd),
    updatedDateStart: dateToJson(obj.updatedDateStart),
    updatedDateEnd: dateToJson(obj.updatedDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
})

export const customerFactorFromJson: Project<CustomerFactorJson, CustomerFactor> = json => ({
    ...json,
    createdDateStart: dateFromJson(json.createdDateStart),
    createdDateEnd: dateFromJson(json.createdDateEnd),
    updatedDateStart: dateFromJson(json.updatedDateStart),
    updatedDateEnd: dateFromJson(json.updatedDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page),
})


interface Required extends JsonRequired {

}

interface Optional extends Omit<JsonOptional, 'createdDateStart' | 'createdDateEnd' | 'updatedDateStart' | 'updatedDateEnd'> {
    statusList: ActiveFlag[],
    createdDateStart: Date,
    createdDateEnd: Date,
    updatedDateStart: Date,
    updatedDateEnd: Date,
    page: PageViewEntity
}

interface JsonRequired {

}

interface JsonOptional {
    companyIdList: number[],
    customerCode: string,
    customerName: string,
    customerShortCode: string,
    timeZone: string,
    regionCode: string,
    statusList: number[],
    createdBy: string,
    updatedBy: string,
    createdDateStart: string,
    createdDateEnd: string,
    updatedDateStart: string,
    updatedDateEnd: string,
    page: PageViewEntityJson
}