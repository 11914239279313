import { Route, Routes } from "react-router-dom"
import { AuthNavigate } from "../components/AuthNavigate/AuthNavigate"
import { Home } from "../layouts/Home/Home"
import { NotFound } from "../layouts/NotFound/NotFound"

export function AppRoutes() {
    return <Routes>
        <Route path="/home" element={<AuthNavigate><Home /></AuthNavigate>} />,
        <Route path="*" element={<NotFound scope="home" />} />,
    </Routes>
}