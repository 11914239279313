import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { ShippingMode } from "../../master/enums/ShippingMode"
import { dateFromJson } from "../../utils/deserializer"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"


export type OutboundMonitorView = Required & Partial<Optional>

type OutboundMonitorViewJson = JsonRequired & Partial<JsonOptional>

export const outboundMonitorViewToJson: Project<OutboundMonitorView, OutboundMonitorViewJson> = obj => ({
    ...obj,
    outboundDateStart: dateToJson(obj.outboundDateStart),
    outboundDateEnd: dateToJson(obj.outboundDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page)
})

export const outboundMonitorViewFromJson: Project<OutboundMonitorViewJson, OutboundMonitorView> = json => ({
    ...json,
    outboundDateStart: dateFromJson(json.outboundDateStart),
    outboundDateEnd: dateFromJson(json.outboundDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page)
})

interface Required extends JsonRequired { }

interface Optional extends Omit<JsonOptional, 'outboundDateStart' | 'outboundDateEnd'> {
    page: PageViewEntity,
    outboundDateStart: Date,
    outboundDateEnd: Date,
    shippingModeList: ShippingMode[],
}

interface JsonRequired {

}

interface JsonOptional {
    outboundRefNo: string,
    outboundNo: string,
    deliveryNoteNo: string,
    receiverList: string[],
    shippingModeList: number[],
    outboundDateStart: string,
    outboundDateEnd: string,
    outboundTimeStart: string,
    outboundTimeEnd: string,
    page: PageViewEntityJson,
}