import { useCallback } from "react"
import { useDownload } from "../../../utils/fetch/useFetch"
import { Projects } from "../../../utils/Projects"
import { BusinessUnitDownloadFactor, businessUnitDownloadFactorToJson } from "../models/BusinessUnitFactor"
import { CompanyListDownloadFactor, companyListDownloadFactorToJson } from "../models/CompanyListFactor"
import { ContractDownloadFactor, contractDownloadFactorToJson } from "../models/ContractFactor"
import { ContractPartsAndPriceFactor, contractPartsAndPriceFactorToJson } from "../models/ContractPartsAndPriceFactor"
import { ContractRouteDownloadFactor, contractRouteDownloadFactorToJson } from "../models/ContractRouteFactor"
import { CreditCheckInfoFactor, creditCheckInfoFactorToJson } from "../models/CreditCheckInfoFactor"
import { CreditSettingInfoFactor, creditSettingInfoFactorToJson } from "../models/CreditSettingInfoFactor"
import { CustomerDownloadFactor, customerDownloadFactorToJson } from "../models/CustomerFactor"
import { DCDownloadFactor, dcDownloadFactorToJson } from "../models/DCFactor"
import { FileDownloadFactor, fileDownloadFactorToJson } from "../models/FileDownloadFactor"
import { FOBSettingDownloadView, fOBSettingDownloadViewToJson } from "../models/FOBSettingListView"
import { GlobalPartsDownloadFactor, globalPartsDownloadFactorToJson } from "../models/GlobalPartsFactor"
import { GlobalPartsResult, globalPartsResultToJson } from "../models/GlobalPartsResult"
import { MLS152CustomsRequest, mls152CustomsRequestToJson } from "../models/MLS152CustomsRequest"
import { PartsDetailResult, partsDetailResultToJson } from "../models/PartsDetailResult"
import { PaymentTermsDownloadFactor, paymentTermsDownloadFactorToJson } from "../models/PaymentTermsFactor"
import { PortDownloadFactor, portDownloadFactorToJson } from "../models/PortFactor"
import { ShippingRouteDownloadFactor, shippingRouteDownloadFactorToJson } from "../models/ShippingRouteFactor"
import { SupplierDownloadFactor, supplierDownloadFactorToJson } from "../models/SupplierFactor"
import { UnitPartsDownloadFactor, unitPartsDownloadFactorToJson } from "../models/UnitPartsFactor"
import { UnitPartsResult, unitPartsResultToJson } from "../models/UnitPartsResult"
import { WorkingResult, workingResultToJson } from "../models/WorkingResult"

// master download
export const useDownloadMaster = () => {
    const url = '/lcbm-local-master-api/download/master'
    const payloadProject = useCallback(fileDownloadFactorToJson, [])
    return useDownload<FileDownloadFactor>(url, payloadProject, { fileTimestamp: true })
}

// company
export const useDownloadCompanyMasterByCompanyId = () => {
    const url = '/lcbm-system-master-api/download/companyMasterByCompanyId'
    const payloadProject = useCallback((obj: { companyIdList: number[] }) => obj.companyIdList, [])
    return useDownload<{ companyIdList: number[] }>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadCompanyMasterByFilter = () => {
    const url = '/lcbm-system-master-api/download/companyMasterByFilter'
    const payloadProject = useCallback(companyListDownloadFactorToJson, [])
    return useDownload<CompanyListDownloadFactor>(url, payloadProject, { fileTimestamp: true })
}

// business unit
export const useDownloadBusinessMasterByBuId = () => {
    const url = '/lcbm-system-master-api/download/businessUnitMasterByBuId'
    const payloadProject = useCallback((obj: { buIdList: number[] }) => obj.buIdList, [])
    return useDownload<{ buIdList: number[] }>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadBusinessMasterByFilter = () => {
    const url = '/lcbm-system-master-api/download/businessUnitMasterByFilter'
    const payloadProject = useCallback(businessUnitDownloadFactorToJson, [])
    return useDownload<BusinessUnitDownloadFactor>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadBusinessSettingByBuId = () => {
    const url = '/lcbm-system-master-api/download/businessUnitSettingByBuId'
    const payloadProject = useCallback((obj: { buIdList: number[] }) => obj.buIdList, [])
    return useDownload<{ buIdList: number[] }>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadBusinessSettingByFilter = () => {
    const url = '/lcbm-system-master-api/download/businessUnitSettingByFilter'
    const payloadProject = useCallback(businessUnitDownloadFactorToJson, [])
    return useDownload<BusinessUnitDownloadFactor>(url, payloadProject, { fileTimestamp: true })
}

// customer
export const useDownloadCustomerMasterByCustomerId = () => {
    const url = '/lcbm-system-master-api/download/customerMasterByCustomerId'
    const payloadProject = useCallback((obj: { customerIdList: number[] }) => obj.customerIdList, [])
    return useDownload<{ customerIdList: number[] }>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadCustomerMasterByFilter = () => {
    const url = '/lcbm-system-master-api/download/customerMasterByFilter'
    const payloadProject = useCallback(customerDownloadFactorToJson, [])
    return useDownload<CustomerDownloadFactor>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadCustomerSettingByCustomerId = () => {
    const url = '/lcbm-system-master-api/download/customerSettingByCustomerId'
    const payloadProject = useCallback((obj: { customerIdList: number[] }) => obj.customerIdList, [])
    return useDownload<{ customerIdList: number[] }>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadCustomerSettingByFilter = () => {
    const url = '/lcbm-system-master-api/download/customerSettingByFilter'
    const payloadProject = useCallback(customerDownloadFactorToJson, [])
    return useDownload<CustomerDownloadFactor>(url, payloadProject, { fileTimestamp: true })
}

// supplier
export const useDownloadSupplierMasterBySupplierId = () => {
    const url = '/lcbm-system-master-api/download/supplierMasterBySupplierId'
    const payloadProject = useCallback((obj: { supplierIdList: number[] }) => obj.supplierIdList, [])
    return useDownload<{ supplierIdList: number[] }>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadSupplierMasterByFilter = () => {
    const url = '/lcbm-system-master-api/download/supplierMasterByFilter'
    const payloadProject = useCallback(supplierDownloadFactorToJson, [])
    return useDownload<SupplierDownloadFactor>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadSupplierSettingBySupplierId = () => {
    const url = '/lcbm-system-master-api/download/supplierSettingBySupplierId'
    const payloadProject = useCallback((obj: { supplierIdList: number[] }) => obj.supplierIdList, [])
    return useDownload<{ supplierIdList: number[] }>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadSupplierSettingByFilter = () => {
    const url = '/lcbm-system-master-api/download/supplierSettingByFilter'
    const payloadProject = useCallback(supplierDownloadFactorToJson, [])
    return useDownload<SupplierDownloadFactor>(url, payloadProject, { fileTimestamp: true })
}

// dc
export const useDownloadDCMasterByDcId = () => {
    const url = '/lcbm-system-master-api/download/dcMasterByDcId'
    const payloadProject = useCallback((obj: { dcIdList: number[] }) => obj.dcIdList, [])
    return useDownload<{ dcIdList: number[] }>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadDCMasterByFilter = () => {
    const url = '/lcbm-system-master-api/download/dcMasterByFilter'
    const payloadProject = useCallback(dcDownloadFactorToJson, [])
    return useDownload<DCDownloadFactor>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadDCSettingByDcId = () => {
    const url = '/lcbm-system-master-api/download/dcSettingByDcId'
    const payloadProject = useCallback((obj: { dcIdList: number[] }) => obj.dcIdList, [])
    return useDownload<{ dcIdList: number[] }>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadDCSettingByFilter = () => {
    const url = '/lcbm-system-master-api/download/dcSettingByFilter'
    const payloadProject = useCallback(dcDownloadFactorToJson, [])
    return useDownload<DCDownloadFactor>(url, payloadProject, { fileTimestamp: true })
}

// port
export const useDownloadPortMasterByPortId = () => {
    const url = '/lcbm-system-master-api/download/portMasterByPortId'
    const payloadProject = useCallback((obj: { portIdList: number[] }) => obj.portIdList, [])
    return useDownload<{ portIdList: number[] }>(url, payloadProject, { fileTimestamp: true })
}
// port
export const useDownloadPortMasterByFilter = () => {
    const url = '/lcbm-system-master-api/download/portMasterByFilter'
    const payloadProject = useCallback(portDownloadFactorToJson, [])
    return useDownload<PortDownloadFactor>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadGlobalPartsMasterByGlobalPartsId = () => {
    const url = '/lcbm-local-master-api/download/globalPartsMasterByGlobalPartsId'
    const payloadProject = useCallback((obj: { partsIdList: number[] }) => obj.partsIdList, [])
    return useDownload<{ partsIdList: number[] }>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadGlobalPartsMasterByEdit = () => {
    const url = '/lcbm-local-master-api/download/globalPartsMasterByEdit'
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const payloadProject = useCallback(Projects.array(globalPartsResultToJson), [])
    return useDownload<GlobalPartsResult[]>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadGlobalPartsMasterByFilter = () => {
    const url = '/lcbm-local-master-api/download/globalPartsMasterByFilter'
    const payloadProject = useCallback(globalPartsDownloadFactorToJson, [])
    return useDownload<GlobalPartsDownloadFactor>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadGlobalPartsPDFByGlobalPartsId = () => {
    const url = '/lcbm-local-master-api/download/globalPartsPDFByGlobalPartsId'
    const payloadProject = useCallback((obj: { partsIdList: number[] }) => obj.partsIdList, [])
    return useDownload<{ partsIdList: number[] }>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadGlobalPartsPDFByFilter = () => {
    const url = '/lcbm-local-master-api/download/globalPartsPDFByFilter'
    const payloadProject = useCallback(globalPartsDownloadFactorToJson, [])
    return useDownload<GlobalPartsDownloadFactor>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadUnitPartsMasterByUnitPartsId = () => {
    const url = '/lcbm-local-master-api/download/unitPartsMasterByUnitPartsId'
    const payloadProject = useCallback((obj: { unitPartsIdList: number[] }) => obj.unitPartsIdList, [])
    return useDownload<{ unitPartsIdList: number[] }>(url, payloadProject, { fileTimestamp: true })
}

export const useUnitPartsMasterByEdit = () => {
    const url = '/lcbm-local-master-api/download/unitPartsMasterByEdit'
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const payloadProject = useCallback(Projects.array(unitPartsResultToJson), [])
    return useDownload<UnitPartsResult[]>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadUnitPartsMasterByFilter = () => {
    const url = '/lcbm-local-master-api/download/unitPartsMasterByFilter'
    const payloadProject = useCallback(unitPartsDownloadFactorToJson, [])
    return useDownload<UnitPartsDownloadFactor>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadUnitPartsPDFByUnitPartsId = () => {
    const url = '/lcbm-local-master-api/download/unitPartsPDFByUnitPartsId'
    const payloadProject = useCallback((obj: { unitPartsIdList: number[] }) => obj.unitPartsIdList, [])
    return useDownload<{ unitPartsIdList: number[] }>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadUnitPartsPDFByFilter = () => {
    const url = '/lcbm-local-master-api/download/unitPartsPDFByFilter'
    const payloadProject = useCallback(unitPartsDownloadFactorToJson, [])
    return useDownload<UnitPartsDownloadFactor>(url, payloadProject, { fileTimestamp: true })
}

// contract
export const useDownloadContractMasterByContractId = () => {
    const url = '/lcbm-local-master-api/download/contractMasterByContractId'
    const payloadProject = useCallback((obj: { contractIdList: number[] }) => obj.contractIdList, [])
    return useDownload<{ contractIdList: number[] }>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadContractMasterByFilter = () => {
    const url = '/lcbm-local-master-api/download/contractMasterByFilter'
    const payloadProject = useCallback(contractDownloadFactorToJson, [])
    return useDownload<ContractDownloadFactor>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadContractPartsMasterByContractId = () => {
    const url = '/lcbm-local-master-api/download/contractPartsMasterByContractId'
    const payloadProject = useCallback((obj: { contractIdList: number[] }) => obj.contractIdList, [])
    return useDownload<{ contractIdList: number[] }>(url, payloadProject, { fileTimestamp: true })
}
export const useDownloadcontractPartsAndPriceMasterCurrentPageData = () => {
    const url = '/lcbm-local-master-api/download/contractPartsAndPriceMasterCurrentPageData'
    const payloadProject = useCallback(contractPartsAndPriceFactorToJson, [])
    return useDownload<ContractPartsAndPriceFactor>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadContractPartsMasterByFilter = () => {
    const url = '/lcbm-local-master-api/download/contractPartsMasterByFilter'
    const payloadProject = useCallback(contractDownloadFactorToJson, [])
    return useDownload<ContractDownloadFactor>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadContractPartsMasterByFilterAll = () => {
    const url = '/lcbm-local-master-api/download/contractPartsMasterByFilterAll'
    const payloadProject = useCallback(contractDownloadFactorToJson, [])
    return useDownload<ContractDownloadFactor>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadContractSplitMasterByContractId = () => {
    const url = '/lcbm-local-master-api/download/contractSplitMasterByContractId'
    const payloadProject = useCallback((obj: { contractIdList: number[] }) => obj.contractIdList, [])
    return useDownload<{ contractIdList: number[] }>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadContractSplitMasterByFilter = () => {
    const url = '/lcbm-local-master-api/download/contractSplitMasterByFilter'
    const payloadProject = useCallback(contractDownloadFactorToJson, [])
    return useDownload<ContractDownloadFactor>(url, payloadProject, { fileTimestamp: true })
}

// contract route
export const useDownloadContractRouteDetailByContractRouteId = () => {
    const url = '/lcbm-local-master-api/download/contractRouteDetailByContractRouteId'
    const payloadProject = useCallback((obj: { contractRouteIdList: number[] }) => obj.contractRouteIdList, [])
    return useDownload<{ contractRouteIdList: number[] }>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadContractRouteDetailByFilter = () => {
    const url = '/lcbm-local-master-api/download/contractRouteDetailByFilter'
    const payloadProject = useCallback(contractRouteDownloadFactorToJson, [])
    return useDownload<ContractRouteDownloadFactor>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadContractRouteOverviewByContractRouteId = () => {
    const url = '/lcbm-local-master-api/download/contractRouteOverviewByContractRouteId'
    const payloadProject = useCallback((obj: { contractRouteIdList: number[] }) => obj.contractRouteIdList, [])
    return useDownload<{ contractRouteIdList: number[] }>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadContractRouteOverviewByContractRouteNo = () => {
    const url = '/lcbm-local-master-api/download/contractRouteOverviewByContractRouteNo'
    const payloadProject = useCallback((obj: { contractRouteNo: string }) => obj.contractRouteNo, [])
    return useDownload<{ contractRouteNo: string }>(url, payloadProject, { fileTimestamp: true })
}

// payment terms
export const useDownloadPaymentTermsMasterByPaymentTermsId = () => {
    const url = '/lcbm-local-master-api/download/paymentTermsMasterByPaymentTermsId'
    const payloadProject = useCallback((obj: { paymentTermsIdList: number[] }) => obj.paymentTermsIdList, [])
    return useDownload<{ paymentTermsIdList: number[] }>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadPaymentTermsMasterByFilter = () => {
    const url = '/lcbm-local-master-api/download/paymentTermsMasterByFilter'
    const payloadProject = useCallback(paymentTermsDownloadFactorToJson, [])
    return useDownload<PaymentTermsDownloadFactor>(url, payloadProject, { fileTimestamp: true })
}

// shipping route
export const useDownloadShippingRouteMasterByShippingRouteId = () => {
    const url = '/lcbm-local-master-api/download/shippingRouteMasterByShippingRouteId'
    const payloadProject = useCallback((obj: { shippingRouteIdList: number[] }) => obj.shippingRouteIdList, [])
    return useDownload<{ shippingRouteIdList: number[] }>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadShippingRouteMasterByFilter = () => {
    const url = '/lcbm-local-master-api/download/shippingRouteMasterByFilter'
    const payloadProject = useCallback(shippingRouteDownloadFactorToJson, [])
    return useDownload<ShippingRouteDownloadFactor>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadShippingRouteDetailMasterByShippingRouteId = () => {
    const url = '/lcbm-local-master-api/download/shippingRouteDetailMasterByShippingRouteId'
    const payloadProject = useCallback((obj: { shippingRouteIdList: number[] }) => obj.shippingRouteIdList, [])
    return useDownload<{ shippingRouteIdList: number[] }>(url, payloadProject, { fileTimestamp: true })
}

// working download
export const useDownloadworking = () => {
    const url = '/lcbm-local-master-api/download/requestContractParts'
    const payloadProject = useCallback(workingResultToJson, [])
    return useDownload<WorkingResult>(url, payloadProject, { fileTimestamp: true })
}
// FOBSetting download
export const useDownloadFOBSettingByFilter = () => {
    const url = '/lcbm-local-master-api/download/downloadFOBSettingByFilter'
    const payloadProject = useCallback(fOBSettingDownloadViewToJson, [])
    return useDownload<FOBSettingDownloadView>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadCustomersParts = () => {
    const url = '/lcbm-local-master-api/download/downloadCustomersParts'
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const payloadProject = useCallback(Projects.array(partsDetailResultToJson), [])
    return useDownload<PartsDetailResult[]>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadRequestPartsModify = () => {
    const url = '/lcbm-local-master-api/download/downloadRequestPartsModify'
    // const payloadProject = useCallback(Projects.array(partsDetailResultToJson), []);
    const payloadProject = useCallback(mls152CustomsRequestToJson, [])
    return useDownload<MLS152CustomsRequest>(url, payloadProject, { fileTimestamp: true })
}
export const useDownloadRequestPartsPrice = () => {
    const url = '/lcbm-local-master-api/download/downloadRequestPartsPrice'
    // const payloadProject = useCallback(Projects.array(partsDetailResultToJson), []);
    const payloadProject = useCallback(mls152CustomsRequestToJson, [])
    return useDownload<MLS152CustomsRequest>(url, payloadProject, { fileTimestamp: true })
}

// credit rule
export const useDownloadCreditRuleMasterByCreditId = () => {
    const url = '/lcbm-credit-limit-api/download/creditRuleMasterByCreditId'
    const payloadProject = useCallback((obj: { creditIdList: number[] }) => obj.creditIdList, [])
    return useDownload<{ creditIdList: number[] }>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadCreditRuleMasterByFilter = () => {
    const url = '/lcbm-credit-limit-api/download/creditRuleMasterByFilter'
    const payloadProject = useCallback(creditCheckInfoFactorToJson, [])
    return useDownload<CreditCheckInfoFactor>(url, payloadProject, { fileTimestamp: true })
}

// credit setting
export const useDownloadCreditSettingMasterByFilter = () => {
    const url = '/lcbm-credit-limit-api/download/creditSettingMasterByFilter'
    const payloadProject = useCallback(creditSettingInfoFactorToJson, [])
    return useDownload<CreditSettingInfoFactor>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadCreditSettingMasterBySelections = () => {
    const url = '/lcbm-credit-limit-api/download/creditSettingMasterBySelections'
    const payloadProject = useCallback(obj => obj.settings, [])
    return useDownload<{ settings: { buyerBuId?: number, customerId?: number, currency?: string }[] }>(url, payloadProject, { fileTimestamp: true })
}
