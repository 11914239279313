import { Typography } from "@material-ui/core"
import { KeyFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/KeyFormatter"
import React from "react"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { TodoListStatus } from "../../../services/master/enums/TodoListStatus"
import { CodeCategoryTypeProvider } from "./CodeCategoryTypeProvider"

export const TodoListTypeProvider = () => {
    return <CodeCategoryTypeProvider codeCategory={CodeCategory.TodoListStatus} Formatter={Formatter} />
}

const Formatter = (props: KeyFormatterProps) => {
    const { value, formatter } = props
    const active = props.value === TodoListStatus.Processed

    return <Typography variant="body2" style={{ color: active ? 'red' : 'green' }}>
        {value === null ? '' : formatter.format(value)}
    </Typography>
}