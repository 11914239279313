import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson, tableEntityToJson } from "../../utils/TableEntity"
import { TermsType } from "../enums/TermsType"

export type TnmPaymentTerms = Required & Partial<Optional> & TableEntity
export type TnmPaymentTermsJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const tnmPaymentTermsToJson: Project<TnmPaymentTerms, TnmPaymentTermsJson> = obj => ({
    ...tableEntityToJson(obj),
})
export const tnmPaymentTermsFromJson: Project<TnmPaymentTermsJson, TnmPaymentTerms> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
    termsType: TermsType
}

interface JsonRequired {
    paymentTermsId: number,
    paymentTermsCode: string,
}

interface JsonOptional {
    buId: number,
    description: string,
    termsType: number
    fromMonth: number,
    days: number,
    fromDay: number,
    supplierId: number,
    version: number,
}