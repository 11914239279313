import { Project } from "../../../utils/Project"
import { dateToJson } from "../../utils/serializer"

export interface OrderMonthFactor {
    startDate?: Date,
    endDate?: Date,
}

interface OrderMonthFactorJson {
    startDate?: string,
    endDate?: string,
}

export const OrderMonthFactorTojson: Project<OrderMonthFactor, OrderMonthFactorJson> = obj => ({
    startDate: dateToJson(obj.startDate),
    endDate: dateToJson(obj.endDate),
})