import { IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core"
import { Delete, Edit } from "@material-ui/icons"
import { DataGridCategoryProps } from "@rithe/data-grid/dist/components/basic/DataGridCategory"
import React from "react"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"

export const useShippingPlanCategoryForSpot = (click: (filed: string, type: ScreenMode) => void, showButton: boolean) => {
    const styles = useStyles()
    return (props: DataGridCategoryProps) => {
        const { category, tableColumns } = props
        return <Tooltip title={""} >
            <Typography variant="body2" className={styles.root}>
                {category}
                {showButton && <IconButton title={""} style={{ zoom: 0.8 }} onClick={() => click(tableColumns[0].field, ScreenMode.MODIFY)}><Edit fontSize={"small"} /></IconButton>}
                {showButton && <IconButton title={""} style={{ zoom: 0.8 }} onClick={() => click(tableColumns[0].field, ScreenMode.REMOVE)}><Delete fontSize={"small"} /></IconButton>}
            </Typography >
        </Tooltip>
    }
}

const useStyles = makeStyles(({
    root: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }
}))