import { DialogActions, DialogContent } from '@material-ui/core'
import { DoneAll, Loop } from '@material-ui/icons'
import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Row, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from '@rithe/data-grid'
import { DataGridRowActionProps } from '@rithe/data-grid/dist/components/basic/DataGridRowAction'
import { AutoCompleteItem, DateItem, DateRangeItem, Form, Message, NumberItem, StringItem } from '@rithe/form'
import { Records } from "@rithe/utils"
import moment from 'moment'
import React, { memo, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from 'react-redux'
import { useFunctionStore } from '../../../Root'
import { SectionCard } from '../../../components/Card/SectionCard'
import { SectionCardContent } from '../../../components/Card/SectionCardContent'
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CallbackRowAction } from '../../../components/DataGrid/rowActions/CallbackRowAction'
import { DownloadGroupedToolbarAction } from '../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction'
import { GroupedCallbackItem } from '../../../components/DataGrid/toolbarActions/GroupedCallbackItem'
import { UploadGroupedToolbarAction } from '../../../components/DataGrid/toolbarActions/UploadGroupedToolbarAction'
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from '../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider'
import { CardDialog } from '../../../components/Dialog/CardDialog'
import { DialogAction } from '../../../components/Dialog/DialogAction'
import { DialogHeader } from '../../../components/Dialog/DialogHeader'
import { View } from '../../../components/View/View'
import { useConfirmExternalOrder, useCreateGrouping, useDownloadExternalOrder, useDownloadExternalOrderTemplate, useUploadExternalOrder } from '../../../services/integration/apis/externalOrderApis'
import { ExternalOrderStatus } from '../../../services/integration/enums/ExternalOrderStatus'
import { ExternalOrderCategoryList } from '../../../services/integration/models/ExternalOrderCategoryList'
import { ExternalOrderListFactor } from '../../../services/integration/models/ExternalOrderFactor'
import { ExternalOrderListResult } from '../../../services/integration/models/ExternalOrderListResult'
import { GroupingDataList } from '../../../services/integration/models/GroupingDataList'
import { InputExternalDownloadDataRangeFactor } from '../../../services/integration/models/InputExternalDownloadDataRangeFactor'
import { BusinessType } from '../../../services/master/enums/BusinessType'
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { OrderFrequency } from '../../../services/order/enums/OrderFrequency'
import { OrderType } from '../../../services/order/enums/OrderType'
import { isNotEmpty, useFieldChecker } from '../../../utils/ValidatorUtils'
import { applicationActions } from '../../Application/applicationSlice'
import { INT040CodeItem } from './INT040CodeItem'
import { INT040CodeItems } from './INT040CodeItems'

export interface INT040PcUiProps {
    filters: ExternalOrderListFactor,
    setFilters: React.Dispatch<React.SetStateAction<ExternalOrderListFactor>>,
    search: (filters: ExternalOrderListFactor) => void,
    data: ExternalOrderListResult[],
    totalCount: number
    category: ExternalOrderCategoryList[]
    groupingData: GroupingDataList[]
}

export const INT040PcUi = (props: INT040PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = memo(({ filters, data, setFilters, search, category, groupingData }: INT040PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])
    const actionPropsUpload = useMemo(() => ({ search, filters }), [filters, search])
    const getStatusName = useCallback((row: Row) => {
        const status = row.state as number
        if (status === ExternalOrderStatus.DRAFT) {
            return intl.formatMessage({ id: 'draft' })
        }
        if (status === ExternalOrderStatus.CONFIRMED) {
            return intl.formatMessage({ id: 'confirmed' })
        }
        if (status === ExternalOrderStatus.PENDING_MASTER) {
            return intl.formatMessage({ id: 'pendingMaster' })
        }
    }, [intl])
    const columns = useMemo(() => [
        { field: 'customerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'externalCustomerCode' }), width: 180 },
        { field: 'orderNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'orderNo' }), width: 250 },
        { field: 'orderType', dataTypeName: CodeCategory.OrderType, title: intl.formatMessage({ id: 'field.orderType' }), width: 180 },
        { field: 'orderFrequency', dataTypeName: CodeCategory.OrderFrequency, title: intl.formatMessage({ id: 'field.orderFrequency' }), width: 200 },
        { field: 'businessType', dataTypeName: CodeCategory.BusinessType, title: intl.formatMessage({ id: 'businessType' }), width: 180 },
        { field: 'orderFirstDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'orderFirstDate' }), width: 180 },
        { field: 'orderLastDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'orderLastDate' }), width: 180 },
        { field: 'status', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.status' }), width: 180, getCellValue: getStatusName },
        { field: 'grouping', dataTypeName: 'string', title: intl.formatMessage({ id: 'grouping' }), width: 300 },
        { field: 'remark', dataTypeName: 'string', title: intl.formatMessage({ id: 'remark' }), width: 300 },
    ], [getStatusName, intl])

    const getRowId = useCallback((row: any) => row.orderId, [])
    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search, category }), [filters, search, setFilters, category])
    const actionProps1 = useMemo(() => ({ selections, category, groupingData }), [selections, category, groupingData])
    const actionProps2 = useMemo(() => ({ search, filters }), [filters, search])
    const displayForeComplete = useCallback((tableRow: TableRow) => (tableRow.row?.state === ExternalOrderStatus.PENDING_MASTER
        || (tableRow.row?.state === ExternalOrderStatus.DRAFT && tableRow.row?.isExistGrouping === 1)), [])
    const displayCreateGrouping = useCallback((tableRow: TableRow) => (tableRow.row?.state === ExternalOrderStatus.DRAFT && tableRow.row?.isExistGrouping === 0), [])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OrderType} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OrderFrequency} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.BusinessType} />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={UploadAction} actionProps={actionPropsUpload} />
        <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps1} />
        <RowActionProvider name="forceComplete" Action={ForceCompleteRowAction} actionProps={actionProps2} display={displayForeComplete} />
        <RowActionProvider name="createGrouping" Action={CreateGroupingRowAction} actionProps={actionProps2} display={displayCreateGrouping} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['reviseVersion', 'contractRouteCode']}
            columnSettings={{
                orderNo: { disableUserControl: true },
                customerCode: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={150} />
    </DataGrid>
})

const FilterItem = memo((props: {
    filters: ExternalOrderListFactor,
    setFilters: React.Dispatch<React.SetStateAction<ExternalOrderListFactor>>,
    search: (filters: ExternalOrderListFactor) => void,
    category: ExternalOrderCategoryList[]
}) => {
    const { filters, setFilters, search, category } = props


    const orderDateGetValue = useCallback((filters: ExternalOrderListFactor) => {
        return [filters.orderFirstDate ?? null, filters.orderLastDate ?? null]
    }, [])

    const orderDateMapValue = useCallback((filters: ExternalOrderListFactor, value: any) => {
        return { ...filters ?? {}, orderFirstDate: value[0], orderLastDate: value[1] }
    }, [])

    const clear = useCallback((filters: ExternalOrderListFactor) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: ExternalOrderListFactor) => {
        return [
            filters.customerCode,
            filters.orderTypeList,
            filters.orderFrequencyList,
            filters.orderFirstDate || filters.orderLastDate,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()


    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="customerCode" label={intl.formatMessage({ id: 'externalCustomerCode' })} />
        <INT040CodeItems field="orderTypeList" label={intl.formatMessage({ id: 'field.orderType' })} code={CodeCategory.OrderType} data={category} />
        <INT040CodeItems field="orderFrequencyList" label={intl.formatMessage({ id: 'field.orderFrequency' })} code={CodeCategory.OrderFrequency} data={category} />
        <INT040CodeItems field="businessTypeList" label={intl.formatMessage({ id: 'field.businessType' })} code={CodeCategory.BusinessType} data={category} />
        <DateRangeItem field="orderDateRange" label={intl.formatMessage({ id: 'orderDateRange' })} getValue={orderDateGetValue} mapValue={orderDateMapValue} />
    </FilterToolbarItem>
})

const DownloadAction = memo(({ selections, category, groupingData }: { selections: number[], category: ExternalOrderCategoryList[], groupingData: GroupingDataList[] }) => {
    const [open, setOpen] = useState<boolean>(false)
    const [editLock, setEditLock] = useState<boolean>(false)
    const [factor, setFactor] = useState<InputExternalDownloadDataRangeFactor>({
        grouping: undefined,
        orderType: OrderType.REGULAR,
        businessType: BusinessType.FIRM,
        orderFrequency: OrderFrequency.MONTHLY,
        orderDate: moment().toDate(),
        targetLeadTime: 1,
        forecastNum: 1
    })

    const dispatch = useDispatch()
    const dowloadApi = useDownloadExternalOrder()
    const downloadTemplate = useDownloadExternalOrderTemplate()

    const callbackDownTemplate = useCallback(() => {
        setOpen(true)
    }, [])
    const intl = useIntl()
    const download = useCallback(() => {
        if (selections.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'downloadExternalOrder' }, messages: { code: 'w0002' } }))
            return
        }
        dowloadApi({ poIds: selections })
    }, [dispatch, dowloadApi, selections])

    const leadtimeName = factor.orderFrequency === 10 ? 'field.targetleadtimeMonth' : factor.orderFrequency === 20 ? 'field.targetleadtimeWeek' :
        factor.orderFrequency === 30 ? 'field.targetleadtimeBiweek' : factor.orderFrequency === 40 ? 'field.targetleadtimeTenDay' : 'field.targetLeadtime'

    const title = useMemo(() => intl.formatMessage({ id: 'InputDownloadPopup' }), [intl])
    const download2 = useCallback(() => {
        if (!isNotEmpty(factor.orderDate)) {
            dispatch(applicationActions.pushError({
                title: title,
                messages: { code: 'w0001', args: [intl.formatMessage({ id: 'field.orderDate' })] },
            }))
            return
        }
        // check targetLeadTime
        let targetLeadTime = factor.targetLeadTime;
        if (targetLeadTime === null || targetLeadTime === undefined) {
            targetLeadTime = 1;
        }
        if (targetLeadTime! < 0 || !Number.isInteger(targetLeadTime)) {
            dispatch(applicationActions.pushError({
                title: title,
                messages: { code: 'integerCheck', args: [intl.formatMessage({ id: leadtimeName })] },
            }))
            return
        }
        if (targetLeadTime.toString().length > 2) {
            dispatch(applicationActions.pushError({
                title: title,
                messages: { code: 'w0019', args: [intl.formatMessage({ id: leadtimeName }), 2] },
            }))
            return
        }
        // check forecast
        let forecast = factor.forecastNum;
        if (forecast === null || forecast === undefined) {
            forecast = 1;
        }
        if (forecast! < 0 || !Number.isInteger(forecast)) {
            dispatch(applicationActions.pushError({
                title: title,
                messages: { code: 'integerCheck', args: [intl.formatMessage({ id: 'field.forecastNum' })] },
            }))
            return
        }
        if (forecast.toString().length > 2) {
            dispatch(applicationActions.pushError({
                title: title,
                messages: { code: 'w0019', args: [intl.formatMessage({ id: 'field.forecastNum' }), 2] },
            }))
            return
        }

        downloadTemplate({ ...factor })
        setOpen(false)
    }, [factor, downloadTemplate, dispatch, title, intl, leadtimeName])

    const [messages, setMessages] = useState<Message[]>([])
    const checkFields = useMemo(() => ({
        targetLeadTime: { labelId: 'field.targetLeadTime', length: { max: 2, integer: 2, scale: 0 }, compare: { min: 0 } },
        forecastNum: { labelId: 'field.forecastNum', length: { max: 2, integer: 2, scale: 0 }, compare: { min: 0 } },
        orderDate: { labelId: 'field.orderDate', required: true }
    }), [])
    const filedCheck = useFieldChecker(checkFields, setMessages)


    const suggustion: string[] = useMemo(() => groupingData
        .filter(m => m.grouping !== null)
        .map(m => m.grouping!),
        [groupingData])

    const groupingOnChange = useCallback((value: string) => {
        if (value == null) {
            return
        }
        const currentGroup = groupingData.find(item => item.grouping === value)
        if (currentGroup === null || currentGroup === undefined) {
            setFactor({
                grouping: value,
                orderType: OrderType.REGULAR,
                businessType: BusinessType.FIRM,
                orderFrequency: OrderFrequency.MONTHLY,
                orderDate: moment().toDate(),
                targetLeadTime: 1,
                forecastNum: 1
            })
            setEditLock(false)
        } else {
            setFactor({
                grouping: value,
                orderType: OrderType.REGULAR,
                businessType: currentGroup.businessType,
                orderFrequency: currentGroup.orderFrequency,
                orderDate: moment().toDate(),
                targetLeadTime: currentGroup.targetLeadTime ?? 1,
                forecastNum: currentGroup.forecastNum ?? 1
            })
            setEditLock(true)
        }

    }, [groupingData, setEditLock, setFactor])
    return <>
        <DownloadGroupedToolbarAction access="INT.INT040.DOWNLOAD">
            {onClose => <>
                <GroupedCallbackItem label={<FormattedMessage id="downloadExternalOrder" />} callback={download} onClose={onClose} />
                <GroupedCallbackItem label={<FormattedMessage id="downloadTemplate" />} callback={callbackDownTemplate} onClose={onClose} />
            </>}
        </DownloadGroupedToolbarAction>
        <CardDialog open={open} style={{ height: '700px', overflow: 'hidden' }} fullWidth fullScreen={false} maxWidth="md">
            <DialogHeader onClose={() => setOpen(false)}>
                <FormattedMessage id="InputDownloadPopup" />
            </DialogHeader>
            <DialogContent>
                <Form data={factor} setData={setFactor} labelDisplay="block" helperDisplay="none" columnCount={2} messages={messages} setMessages={setMessages} ensure={filedCheck}>
                    <AutoCompleteItem field="grouping" label={intl.formatMessage({ id: 'field.grouping' })} suggestions={suggustion} onChange={groupingOnChange} />
                    <INT040CodeItem field="orderType" required label={intl.formatMessage({ id: 'field.orderType' })} code={CodeCategory.OrderType} data={category} />
                    <INT040CodeItem field="businessType" required readonly={editLock} label={intl.formatMessage({ id: 'field.businessType' })} code={CodeCategory.BusinessType} data={category} />
                    <INT040CodeItem field="orderFrequency" required readonly={editLock} label={intl.formatMessage({ id: 'field.orderFrequency' })} code={CodeCategory.OrderFrequency} data={category} />
                    <NumberItem field="targetLeadTime" label={intl.formatMessage({ id: leadtimeName })} />
                    <NumberItem field="forecastNum" label={intl.formatMessage({ id: 'forecastNumber' })} />
                    <DateItem field="orderDate" required label={intl.formatMessage({ id: 'field.orderDate' })} />
                </Form>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={() => setOpen(false)} />
                <DialogAction title={<FormattedMessage id="download" />} callback={download2} />
            </DialogActions>
        </CardDialog>
    </>
})


const UploadAction = ({ search, filters }: { search: (filters: ExternalOrderListFactor) => void, filters: ExternalOrderListFactor }) => {
    const uploadExternalOrderApi = useUploadExternalOrder();
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const upload = useCallback((file: File) => {
        uploadExternalOrderApi({ file, isConfirm: false }, { serialized: true, silent: true }).then(warningMessages => {
            if (warningMessages.length === 0) {
                dispatch(applicationActions.pushSuccess({
                    title: intl.formatMessage({ id: 'post /integration-api/externalOrder/uploadExternalOrder' }),
                    messages: [{ code: 'notice.success' }],
                }))
                search(filters)
            } else {
                const functionId = functionStore.register(() => {
                    uploadExternalOrderApi({ file, isConfirm: true }, { serialized: true }).then(() => {
                        search(filters)
                    })
                })
                dispatch(applicationActions.pushWarning({
                    title: intl.formatMessage({ id: 'confirm' }),
                    messages: warningMessages,
                    actions: [{
                        label: 'CANCEL'
                    }, {
                        functionId,
                        label: 'CONFIRM',
                    }]
                }))
            }
        })
    }, [dispatch, filters, functionStore, intl, search, uploadExternalOrderApi])
    const uploadExternalOrderMethod = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            if (files === null || files.length === 0) {
                return
            } else {
                upload(files[0])
            }
        })
    }, [upload])

    return <UploadGroupedToolbarAction access='INT.INT040.UPLOAD'>
        {(popupUpload, onClose) => <>
            <GroupedCallbackItem label={<FormattedMessage id="uploadExternalOrder" />} callback={() => uploadExternalOrderMethod(popupUpload)} onClose={onClose} />
        </>}
    </UploadGroupedToolbarAction>
}


const ForceCompleteRowAction = ({ tableRow, filters, search }: DataGridRowActionProps & { filters: ExternalOrderListFactor, search: (filters: ExternalOrderListFactor) => void }) => {
    const externalOrderCompleteApi = useConfirmExternalOrder()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'complete' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)


    const callback = useCallback((tableRow: TableRow) => {
        setDisabled(true)
        const functionId = functionStore.register(() => {
            externalOrderCompleteApi({ orderId: tableRow.rowId as number }, { serialized: true }).then(() => search(filters)).finally(() => {
                setDisabled(false)
            }).catch(() => {
                search(filters)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [dispatch, filters, externalOrderCompleteApi, functionStore, search, title])
    return <CallbackRowAction tableRow={tableRow} title={<FormattedMessage id="confirm" />} icon={<DoneAll />} callback={callback} disabled={disabled} />
}

const CreateGroupingRowAction = ({ tableRow, filters, search }: DataGridRowActionProps & { filters: ExternalOrderListFactor, search: (filters: ExternalOrderListFactor) => void }) => {
    const createGroupingApi = useCreateGrouping()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'createGrouping' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)


    const callback = useCallback((tableRow: TableRow) => {
        setDisabled(true)
        const functionId = functionStore.register(() => {
            createGroupingApi({ orderId: tableRow.rowId as number }, { serialized: true }).then(() => search(filters)).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [dispatch, filters, createGroupingApi, functionStore, search, title])
    return <CallbackRowAction tableRow={tableRow} title={<FormattedMessage id="createGrouping" />} icon={<Loop />} callback={callback} disabled={disabled} />
}
