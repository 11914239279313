import { AxiosInstance } from 'axios'
import { CbdsInfo } from '../models/CbdsInfo'

const cbdsApi = {
    getCompany: function (axiosInstance: AxiosInstance) {
        return axiosInstance.post<CbdsInfo[]>('/lcbm-system-master-api/api/getActiveCompanyByType', [1, 2, 3, 4])
    },
    getActiveCompanyByType: function (axiosInstance: AxiosInstance, types: number[]) {
        return axiosInstance.post<CbdsInfo[]>('/lcbm-system-master-api/api/getActiveCompanyByType', types) 
    },
}

export default cbdsApi