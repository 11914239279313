import { Project } from "../../../utils/Project"
import { SettingDetail, settingDetailFromJson, SettingDetailJson, settingDetailToJson } from "./SettingDetail"
import { TnmCustomerTemp, tnmCustomerTempFromJson, TnmCustomerTempJson, tnmCustomerTempToJson } from "./TnmCustomerTemp"

export interface CustomerDetail {
    basicInfo: TnmCustomerTemp,
    settings: SettingDetail[],
}
interface CustomerDetailJson {
    basicInfo: TnmCustomerTempJson,
    settings: SettingDetailJson[],
}

export const customerDetailFromJson: Project<CustomerDetailJson, CustomerDetail> = json => ({
    basicInfo: tnmCustomerTempFromJson(json.basicInfo),
    settings: json.settings.map(settingDetailFromJson),
})

export const customerDetailToJson: Project<CustomerDetail, CustomerDetailJson> = obj => ({
    basicInfo: tnmCustomerTempToJson(obj.basicInfo),
    settings: obj.settings.map(settingDetailToJson),
})