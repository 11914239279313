import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { IntBatchTransfer, intBatchTransferFromJson } from "../models/IntBatchTransfer"
import { IntBatchView, intBatchViewToJson } from "../models/IntBatchView"

export const useGetIntegrationBatchPage = () => {
    const url = '/integration-api/batch/getBatchPageList'
    const payloadProject = useCallback(intBatchViewToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(intBatchTransferFromJson), [])
    return usePost<IntBatchView, PageResultEntity<IntBatchTransfer>>(url, payloadProject, resultProject)
}
