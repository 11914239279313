import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { dateToJson } from "../../utils/serializer"

export type ARTransfer = Required & Partial<Optional>

type ARTransferJson = JsonRequired & Partial<JsonOptional>

export const aRTransferFromJson: Project<ARTransferJson, ARTransfer> = json => ({
    ...json,
    invoiceDate: dateFromJson(json.invoiceDate),
    paymentDueDate: dateFromJson(json.paymentDueDate),
    paidList: json.paidList == null ? [] : json.paidList.map(m => PaidTransferFromJson(m))
})

interface Required extends JsonRequired { }

interface Optional extends Omit<JsonOptional, 'invoiceDate' | 'paymentDueDate' | 'paidList'> {
    invoiceDate: Date,
    paymentDueDate: Date,
    paidList: PaidTransfer[] | null,
}

interface JsonRequired {
    arId: number,
    invoiceId: number,
    invoiceNo: string,
}

interface JsonOptional {
    contractNo: string,
    outboundNo: string,
    buyerCode: number,
    currency: string,
    amount: string,
    amountPaid: string,
    invoiceDate: string,
    paymentDueDate: string,
    status: string,
    paidList: PaidTransferJson[] | null,
}


export type PaidTransfer = SubRequired & Partial<SubOptional>

type PaidTransferJson = SubJsonRequired & Partial<SubJsonOptional>

const PaidTransferFromJson: Project<PaidTransferJson, PaidTransfer> = json => ({
    ...json,
    paidDate: dateFromJson(json.paidDate)
})

export const PaidTransferToJson: Project<PaidTransfer, PaidTransferJson> = json => ({
    ...json,
    paidDate: dateToJson(json.paidDate)
})

interface SubRequired extends SubJsonRequired { }

interface SubOptional extends Omit<SubJsonOptional, 'paidDate'> {
    paidDate: Date
}

interface SubJsonRequired {

}

interface SubJsonOptional {
    arId: string,
    paidAmount: string,
    paidDate: string,
    paidRefNo: number,
}