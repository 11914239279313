import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"

export type InventoryByPartsFactor = Partial<Optional>
type InventoryByPartsFactorJson = Partial<JsonOptional>
export const inventoryByPartsFactorToJson: Project<InventoryByPartsFactor, InventoryByPartsFactorJson> = obj => ({
    ...obj,
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
})

export const inventoryByPartsFactorFromJson: Project<InventoryByPartsFactorJson, InventoryByPartsFactor> = json => ({
    ...json,
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page),
})

interface Optional extends Omit<JsonOptional, 'page'> {
    page: PageViewEntity,
}


interface JsonOptional {
    dcIdList: number[],
    dcIdForQueryList: number[],
    dcCodeList: string[],
    customerIdList: number[],
    customerCodeList: string[],
    dcCodes: string,
    customerCodes: string,
    partsNo: string,
    unitPartsNo: string,
    backNo: string,
    colorCode: string,
    buId: number,
    resourceCode: string,
    page: PageViewEntityJson,
}
