import { MenuItem } from "@material-ui/core"
import React from "react"
import { useNavigate } from "react-router-dom"
import { Access } from "../../Access/Access"

interface GroupedRedirectItemProps {
    access?: string,
    power?: boolean,
    label: NonNullable<React.ReactNode>,
    path: string,
    state?: any,
    onClose: () => void,
}

export const GroupedRedirectItem = (props: GroupedRedirectItemProps) => {
    const { access, power, label, path, state, onClose } = props
    const navigate = useNavigate()
    const action = <MenuItem onClick={() => {
        navigate(path, { state })
        onClose()
    }}>
        {label}
    </MenuItem>
    return access ? <Access access={access} power={power}>{action}</Access> : action
}