import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { Projects } from "../../../utils/Projects"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { BusinessUnitDetail, businessUnitDetailFromJson, businessUnitDetailToJson } from "../models/BusinessUnitDetail"
import { BusinessUnitFactor, businessUnitFactorToJson } from "../models/BusinessUnitFactor"
import { BusinessUnitResult, businessUnitResultFromJson } from "../models/BusinessUnitResult"
import { TnmBusinessUnitTemp, tnmBusinessUnitTempFromJson } from "../models/TnmBusinessUnitTemp"

export const useGetBUList = () => {
    const url = '/lcbm-system-master-api/api/getBUList'
    const payloadProject = useCallback(businessUnitFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(businessUnitResultFromJson), [])
    return usePost<BusinessUnitFactor, PageResultEntity<BusinessUnitResult>>(url, payloadProject, resultProject)
}

/* 2020.2.3 */

// Query Bu Info
export const useGetBuDetailForEdit = () => {
    const url = '/lcbm-system-master-api/api/getBuDetailForEdit'
    const payloadProject = useCallback((obj: { buCode: string }) => obj.buCode, [])
    const resultProject = useCallback(businessUnitDetailFromJson, [])
    return usePost<{ buCode: string }, BusinessUnitDetail>(url, payloadProject, resultProject)
}

export const useGetBuDetailForView = () => {
    const url = '/lcbm-system-master-api/api/getBuDetailForView'
    const payloadProject = useCallback((obj: { buCode: string }) => obj.buCode, [])
    const resultProject = useCallback(businessUnitDetailFromJson, [])
    return usePost<{ buCode: string }, BusinessUnitDetail>(url, payloadProject, resultProject)
}


// To Temporary DataBase
export const useUpdatedOrInsertBuDetail = () => {
    const url = '/lcbm-system-master-api/api/updatedOrInsertBuDetail'
    const payloadProject = useCallback(businessUnitDetailToJson, [])
    // const resultProject = Projects.IGNORE
    const resultProject = useCallback(tnmBusinessUnitTempFromJson, [])
    return usePost<BusinessUnitDetail, TnmBusinessUnitTemp>(url, payloadProject, resultProject)
}

// To Temporary DataBase
export const useGetPNAInfo = () => {
    const url = '/lcbm-system-master-api/api/pnaInfo'
    const payloadProject = Projects.IGNORE
    // const resultProject = Projects.IGNORE
    const resultProject = useCallback(tnmBusinessUnitTempFromJson, [])
    return usePost<undefined, TnmBusinessUnitTemp>(url, payloadProject, resultProject)
}

// To Formal DataBase
export const useSaveAndUpdatedBuInfo = () => {
    const url = '/lcbm-system-master-api/api/saveAndIssueBuInfo'
    const payloadProject = useCallback(businessUnitDetailToJson, [])
    // const resultProject = Projects.IGNORE
    const resultProject = useCallback(tnmBusinessUnitTempFromJson, [])
    return usePost<BusinessUnitDetail, TnmBusinessUnitTemp>(url, payloadProject, resultProject)
}

export const useIssBuInfo = () => {
    const url = '/lcbm-system-master-api/api/issBuInfo'
    const payloadProject = useCallback((obj: { tempBuId: number }) => obj.tempBuId, [])
    const resultProject = Projects.IGNORE
    return usePost<{ tempBuId: number }, undefined>(url, payloadProject, resultProject)
}

export const useWithdrawBuInfo = () => {
    const url = '/lcbm-system-master-api/api/withdrawEditBu'
    const payloadProject = useCallback((obj: { tempBuId: number }) => obj.tempBuId, [])
    const resultProject = Projects.IGNORE
    return usePost<{ tempBuId: number }, undefined>(url, payloadProject, resultProject)
}

export const useRunshell = () => {
    const url = '/lcbm-system-master-api/api/runshell'
    const payloadProject = useCallback((obj: { batchId: string }) => obj, [])
    const resultProject = Projects.IGNORE
    return usePost<{ batchId: string }, undefined>(url, payloadProject, resultProject)
}