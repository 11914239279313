import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useComMasterGetActiveCustomer, useComMasterGetActiveDc } from "../../../services/common/apis/commonMasterApi"
import { useInitInventoryByPartsList } from "../../../services/managementTool/apis/mtApis"
import { InventoryByPartsFactor, inventoryByPartsFactorFromJson, inventoryByPartsFactorToJson } from "../../../services/managementTool/models/InventoryByPartsFactor"
import { InventoryByPartsResult } from "../../../services/managementTool/models/InventoryByPartsResult"
import { TnmCustomer } from "../../../services/master/models/TnmCustomer"
import { TnmDistributionCenter } from "../../../services/master/models/TnmDistributionCenter"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { MTS020PcUi } from "./MTS020PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }
const searchCachekey = sessionKeys.Filters_MTS020

export const MTS020 = () => {
    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? inventoryByPartsFactorFromJson(JSON.parse(filters)) : null
    }, [])

    const [filters, setFilters] = useState<InventoryByPartsFactor>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<InventoryByPartsResult[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [customerList, setCustomerList] = useState<TnmCustomer[]>([])
    const [dcList, setDcList] = useState<TnmDistributionCenter[]>([])

    const getDataList = useInitInventoryByPartsList()
    const search = useCallback((filters: InventoryByPartsFactor) => {
        getDataList(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount ?? 0)
            setData(result?.data ?? [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(inventoryByPartsFactorToJson(filters)))
        })
    }, [getDataList, setData])

    useSearchOnLoad(search, filters)

    const getCustomerList = useComMasterGetActiveCustomer()
    useEffect(() => {
        getCustomerList(undefined, { silent: true }).then(result => {
            setCustomerList(result ?? [])
        })
    }, [getCustomerList])

    const getDcList = useComMasterGetActiveDc()
    useEffect(() => {
        getDcList(undefined, { silent: true }).then(result => {
            setDcList(result ?? [])
        })
    }, [getDcList])

    return <MTS020PcUi
        filters={filters}
        search={search}
        data={data}
        totalCount={totalCount}
        customerList={customerList}
        dcList={dcList}
        setFilters={setFilters}
    />
}