import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Typography, makeStyles } from "@material-ui/core"
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import SettingsInputSvideoIcon from '@material-ui/icons/SettingsInputSvideo'
import { Calendar } from "@material-ui/pickers"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"
import { CheckItem, Form, Message, StringItem } from "@rithe/form"
import { Records } from "@rithe/utils"
import moment from "moment"
import React, { memo, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { CallbackViewAction } from "../../../components/Action/CallbackViewAction"
import { ModifyCallbackViewAction } from "../../../components/Action/ModifyCallbackViewAction"
import { SaveCallbackViewAction } from "../../../components/Action/SaveCallbackViewAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { CodeItem } from "../../../components/Form/CodeItem"
import { View } from "../../../components/View/View"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { useSaveCalendar } from "../../../services/master/apis/calendarApi"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { WorkingFlag } from "../../../services/master/enums/WorkingFlag"
import { CalendarGetDateResult } from "../../../services/master/models/CalendarGetDateResult"
import { TnmCalendar } from "../../../services/master/models/TnmCalendar"
import { today } from "../../../utils/ApplicationUtils"
import { useFieldChecker, useFormValidater } from "../../../utils/ValidatorUtils"

interface MLS131PcUiProps {
    url: string,
    path: string,
    year: number,
    setYear: React.Dispatch<React.SetStateAction<number>>,
    calendarInfo: TnmCalendar,
    setCalendarInfo: React.Dispatch<React.SetStateAction<TnmCalendar>>,
    orgDateList: CalendarGetDateResult[],
    monthList: Record<string, CalendarGetDateResult[]>,
    setMonthList: React.Dispatch<React.SetStateAction<Record<string, CalendarGetDateResult[]>>>,
    mode: ScreenMode,
    setMode: React.Dispatch<React.SetStateAction<ScreenMode>>,
    search: () => void,
    flagRoleCode: any,
    transferId: number,
}

export const MLS131PcUi = (props: MLS131PcUiProps) => {
    const { year, setYear, calendarInfo, setCalendarInfo, mode, monthList, setMonthList, orgDateList, search, path, setMode, url, flagRoleCode, transferId } = props
    const intl = useIntl()
    const [messages, setMessages] = useState<Message[]>([])
    const modifyChange = () => {
        setMode(ScreenMode.EDIT)
    }
    const [dialog, setDialog] = useState<boolean>(false)

    const show = (path === '/calendar/edit-:calendarId' || path === '/calendar/create') ? true : false

    const originalActions = mode === ScreenMode.VIEW ? [] :
        <SaveAction
            calendarInfo={calendarInfo}
            year={year}
            monthList={monthList}
            mode={mode}
            search={search}
            setMessages={setMessages}
            path={path}
            flagRoleCode={flagRoleCode}
            transferId={transferId}
            setDialog={setDialog}
            orgDateList={orgDateList}
        />

    const newActions = mode === ScreenMode.VIEW ?
        [<ModifyCallbackViewAction outlined callback={modifyChange} />] : [
            <SaveAction calendarInfo={calendarInfo} year={year} monthList={monthList} mode={mode} search={search} setMessages={setMessages} path={path} flagRoleCode={flagRoleCode} transferId={transferId} setDialog={setDialog} orgDateList={orgDateList} />,
            <BackAction url={url} />
        ]

    const actions = show ? originalActions : newActions

    return <View actions={actions}>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={1}
                title={intl.formatMessage({ id: 'BasicInfo' })}
                subtitle={intl.formatMessage({ id: 'basicInfoSub' })}
            />
            <SectionCardContent>
                <HeaderBox
                    calendarInfo={calendarInfo}
                    setCalendarInfo={setCalendarInfo}
                    mode={mode}
                    messages={messages}
                    setMessages={setMessages}
                    transferId={transferId}
                />
            </SectionCardContent>
        </SectionCard>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={2}
                title={intl.formatMessage({ id: 'calendarInfo' })}
                subtitle={intl.formatMessage({ id: 'calendarInfoSub' })}
                actions={[<SetRestDayAction setMonthList={setMonthList} mode={mode} />, <YearAction orgDateList={orgDateList} year={year} setYear={setYear} />]}
            />
            <SectionCardContent>
                <CalendarTable monthList={monthList} setMonthList={setMonthList} mode={mode} />
            </SectionCardContent>
        </SectionCard>
        <WaringMessageDialog dialog={dialog} onClose={() => setDialog(false)} />
    </View>
}

const HeaderBox = memo((props: {
    calendarInfo: TnmCalendar,
    setCalendarInfo: React.Dispatch<React.SetStateAction<TnmCalendar>>,
    mode: number,
    messages: Message[],
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
    transferId: number
}) => {

    const intl = useIntl()
    const { calendarInfo, setCalendarInfo, messages, setMessages, mode } = props
    const fields = useMemo(() => getFormCheckFields(true), [])
    const filedCheck = useFieldChecker(fields, setMessages)

    const readOnly = mode !== ScreenMode.CREATE
    return <Form data={calendarInfo} setData={setCalendarInfo} labelDisplay="block" helperDisplay="tooltip" messages={messages} setMessages={setMessages} ensure={filedCheck}>
        <StringItem field="calendarCode" readonly={readOnly} required label={intl.formatMessage({ id: 'field.calendarCode' })} />
        <CodeItem field="activeFlag" readonly={readOnly} required label={intl.formatMessage({ id: 'field.activeFlag' })} code={CodeCategory.ActiveFlag} />
        <StringItem field="description" readonly={mode === ScreenMode.VIEW} label={intl.formatMessage({ id: 'field.description' })} colSpan={2} />
    </Form>
})

interface CalendarDatailPcUiProps {
    monthList: Record<string, CalendarGetDateResult[]>,
    setMonthList: React.Dispatch<React.SetStateAction<Record<string, CalendarGetDateResult[]>>>,
    mode: ScreenMode
}

const CalendarTable = memo((props: CalendarDatailPcUiProps) => {

    const { monthList, setMonthList, mode } = props

    if (Records.size(monthList) === 0) {
        return <></>
    }

    const { month0, month1, month2, month3, month4, month5, month6, month7, month8, month9, month10, month11 } = monthList
    return (
        <Grid container style={{ margin: '8px 0 8px 0' }} spacing={1}>
            <CalendarView dateList={month0} setMonthList={setMonthList} keyId="month0" mode={mode} />
            <CalendarView dateList={month1} setMonthList={setMonthList} keyId="month1" mode={mode} />
            <CalendarView dateList={month2} setMonthList={setMonthList} keyId="month2" mode={mode} />
            <CalendarView dateList={month3} setMonthList={setMonthList} keyId="month3" mode={mode} />
            <CalendarView dateList={month4} setMonthList={setMonthList} keyId="month4" mode={mode} />
            <CalendarView dateList={month5} setMonthList={setMonthList} keyId="month5" mode={mode} />
            <CalendarView dateList={month6} setMonthList={setMonthList} keyId="month6" mode={mode} />
            <CalendarView dateList={month7} setMonthList={setMonthList} keyId="month7" mode={mode} />
            <CalendarView dateList={month8} setMonthList={setMonthList} keyId="month8" mode={mode} />
            <CalendarView dateList={month9} setMonthList={setMonthList} keyId="month9" mode={mode} />
            <CalendarView dateList={month10} setMonthList={setMonthList} keyId="month10" mode={mode} />
            <CalendarView dateList={month11} setMonthList={setMonthList} keyId="month11" mode={mode} />
        </Grid >
    )
})

interface CalendarProps {
    dateList: CalendarGetDateResult[],
    setMonthList: React.Dispatch<React.SetStateAction<Record<string, CalendarGetDateResult[]>>>,
    keyId: string,
    mode: ScreenMode,
}

const CalendarView = memo((props: CalendarProps) => {
    const { dateList, setMonthList, keyId, mode } = props
    let minDate = dateList[0].workingDate
    let maxDate = dateList[dateList.length - 1].workingDate
    const onChange = useCallback((tday: MaterialUiPickersDate) => {
        if (mode === ScreenMode.VIEW) return

        const toDate = tday?.toDate()
        if (toDate !== undefined) {
            setMonthList((months) => {
                // calculate
                const newMonths = { ...months }
                const currentMonth = [...dateList]
                const curIdx = moment(toDate).date() - 1
                const currentDate = currentMonth[curIdx]
                currentMonth[curIdx] = { workingDate: toDate, workingFlag: currentDate.workingFlag === WorkingFlag.REST_DAY ? WorkingFlag.WORKING_DAY : WorkingFlag.REST_DAY }
                newMonths[keyId] = currentMonth
                // return new list
                return newMonths
            })
        }
    }, [dateList, keyId, mode, setMonthList])

    const style = useStyles()

    const renderDay = useCallback((day: MaterialUiPickersDate, selectedDate, isInCurrentMonth, dayComponent) => {
        if (isInCurrentMonth && day?.toDate()) {
            const toDate = day.toDate()
            const isWorkingDay = dateList.some(f => (toDate.getTime() === f.workingDate?.getTime()) && f.workingFlag === WorkingFlag.WORKING_DAY)
            if (!isWorkingDay) {
                return <div className={style.restDay}>{dayComponent}</div>
            } else {
                return <div className={style.workingDay}>{dayComponent}</div>
            }
        }
        return dayComponent
    }, [dateList, style.restDay, style.workingDay])

    return <>
        <Grid item xs={3} alignItems="flex-end">
            <Calendar
                date={moment(minDate ?? today())}
                leftArrowIcon={<></>}
                rightArrowIcon={<></>}
                onChange={onChange}
                renderDay={renderDay}
                minDate={moment(minDate ?? today())}
                maxDate={moment(maxDate ?? today())}
                allowKeyboardControl={false}
            />
        </Grid>
    </>
})

interface WeekFactor {
    sunday?: boolean,
    monday?: boolean,
    tuesday?: boolean,
    wednesday?: boolean,
    thursday?: boolean,
    friday?: boolean,
    saturday?: boolean,
}

const SetRestDayAction = memo(({ setMonthList, mode }: {
    setMonthList: React.Dispatch<React.SetStateAction<Record<string, CalendarGetDateResult[]>>>,
    mode: ScreenMode
}) => {

    const [open, setOpen] = useState<boolean>(false)
    const intl = useIntl()
    const [week, setWeek] = useState<WeekFactor>({})

    const recalculate = useCallback(() => {
        setMonthList((months) => {
            let selects: number[] = []
            week.sunday && selects.push(0)
            week.monday && selects.push(1)
            week.tuesday && selects.push(2)
            week.wednesday && selects.push(3)
            week.thursday && selects.push(4)
            week.friday && selects.push(5)
            week.saturday && selects.push(6)

            let newMonths: Record<string, CalendarGetDateResult[]> = {}
            Object.keys(months).forEach((key) => {
                newMonths[key] = months[key].map(day => {
                    return ({ workingDate: day.workingDate, workingFlag: selects.includes(moment(day.workingDate).day()) ? WorkingFlag.REST_DAY : day.workingFlag })
                })
            })
            return newMonths
        })
        setWeek({})
        setOpen(false)
    }, [setMonthList, week.friday, week.monday, week.saturday, week.sunday, week.thursday, week.tuesday, week.wednesday])

    if (mode === ScreenMode.VIEW) {
        return <></>
    }

    return <>
        <IconButton onClick={() => setOpen(true)}><SettingsInputSvideoIcon /></IconButton>
        <Dialog open={open} style={{ overflow: 'hidden' }} maxWidth="sm" fullWidth fullScreen={false}>
            <DialogHeader onClose={() => setOpen(false)}>
                <FormattedMessage id="selectRestDays" />
            </DialogHeader>
            <DialogContent>
                <Form data={week} setData={setWeek} labelDisplay="none" labelAlign="start" helperDisplay="tooltip" helperAlign="end" minWidth={500} maxWidth={500}>
                    <CheckItem field="monday" labelWidth={80} label={intl.formatMessage({ id: 'Monday' })} />
                    <CheckItem field="tuesday" labelWidth={80} label={intl.formatMessage({ id: 'Tuesday' })} />
                    <CheckItem field="wednesday" labelWidth={80} label={intl.formatMessage({ id: 'Wednesday' })} />
                    <CheckItem field="thursday" labelWidth={80} label={intl.formatMessage({ id: 'Thursday' })} />
                    <CheckItem field="friday" labelWidth={80} label={intl.formatMessage({ id: 'Friday' })} />
                    <CheckItem field="saturday" labelWidth={80} label={intl.formatMessage({ id: 'Saturday' })} />
                    <CheckItem field="sunday" labelWidth={80} label={intl.formatMessage({ id: 'Sunday' })} />
                </Form>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={() => setOpen(false)} />
                <DialogAction title={<FormattedMessage id="confirm" />} callback={recalculate} />
            </DialogActions>
        </Dialog>
    </>
})

const YearAction = memo(({ orgDateList, year, setYear }: {
    orgDateList: CalendarGetDateResult[],
    year: number,
    setYear: React.Dispatch<React.SetStateAction<number>>,
}) => {
    const onLeftClick = useCallback(() => setYear(year => year - 1), [setYear])
    const onRightClick = useCallback(() => setYear(year => year + 1), [setYear])
    const hasYear = orgDateList !== undefined && orgDateList.length > 0
    return <>
        <IconButton onClick={onLeftClick}><ArrowLeftIcon /></IconButton>
        <span style={{ color: hasYear ? 'green' : 'red', fontSize: '1.2rem' }}>{year}</span>
        <IconButton onClick={onRightClick}><ArrowRightIcon /></IconButton>
    </>
})


const BackAction = (props: { url: string }) => {
    const { url } = props
    const navigate = useNavigate()
    const returnBeforePage = useCallback(() => {
        const toPath = url.substr(0, url.lastIndexOf('/'))
        navigate(toPath)
    }, [navigate, url])

    return <CallbackViewAction outlined callback={returnBeforePage} title={<FormattedMessage id="cancel" />} />
}

function arraysEqual(arr1: any[], arr2: any[]): boolean {
    if (arr1.length !== arr2.length) {
        return false;
    }
    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();

    return sortedArr1.every((value, index) => value === sortedArr2[index]);
}

const SaveAction = memo((props: {
    calendarInfo: TnmCalendar,
    year: number,
    monthList: Record<string, CalendarGetDateResult[]>,
    mode: ScreenMode,
    search: () => void,
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
    path: string,
    flagRoleCode: any,
    transferId: number,
    setDialog: React.Dispatch<React.SetStateAction<boolean>>,
    orgDateList: CalendarGetDateResult[];
}) => {
    const { calendarInfo, year, monthList, setMessages, mode, search, path, flagRoleCode, transferId, setDialog, orgDateList } = props
    const saveCalendar = useSaveCalendar()
    const navigate = useNavigate()
    const fields = useMemo(() => getFormCheckFields(true), [])
    const formValidate = useFormValidater(setMessages, fields)
    const [disabled, setDisabled] = useState<boolean>(false)

    const onclickSave = useCallback(() => {
        // const CbdsCode = flagRoleCode
        const flagNumber = path === '/bu/create-:buCode' ? CbdsType.BU : path === '/customer/create-:customerCode' ? CbdsType.CUST : path === '/supplier/create-:supplierCode' ?
            CbdsType.SUPP : path === '/dc/create-:dcCode' ? CbdsType.DC : null

        // DO filed check
        if (formValidate(calendarInfo)) {
            const factors = {
                calendarId: calendarInfo.calendarId,
                calendarCode: calendarInfo.calendarCode,
                companyId: transferId,
                regionCode: calendarInfo.regionCode,
                activeFlag: calendarInfo.activeFlag,
                calendarYear: year.toString(),
                description: calendarInfo.description,
                restDateList: Object.values(monthList).flatMap(f => f).filter(f => f.workingFlag === WorkingFlag.REST_DAY).map(m => moment(m.workingDate).format('YYYYMMDD')),
                // flag: flagNumber,
                // flagRoleCode: CbdsCode
            }
            setDisabled(true)
            // do update
            saveCalendar(factors, { serialized: true }).then((result) => {
                if (flagNumber != null && flagNumber !== undefined) {
                    flagNumber === CbdsType.BU ? navigate(`/bu/view-${flagRoleCode}`) : flagNumber === CbdsType.CUST ? navigate(`/customer/view-${flagRoleCode}`) : flagNumber === CbdsType.SUPP ?
                        navigate(`/supplier/view-${flagRoleCode}`) : navigate(`/dc/view-${flagRoleCode}`)
                } else {
                    mode === ScreenMode.CREATE ? navigate(`/calendar/edit-${result}`) : search()
                }
                const selectDate: string[] = orgDateList.filter(f => f.workingFlag === WorkingFlag.REST_DAY).map(m => moment(m.workingDate).format('YYYYMMDD'))
                const isWaring: boolean = arraysEqual(selectDate, factors.restDateList)
                if (!isWaring || orgDateList.length === 0) {
                    setDialog(true)
                }
            }).finally(() => {
                setDisabled(false)
            })
        }
    }, [calendarInfo, flagRoleCode, formValidate, mode, monthList, navigate, orgDateList, path, saveCalendar, search, setDialog, transferId, year])

    return <SaveCallbackViewAction callback={onclickSave} access="MARS.MLS131.SAVE" disabled={disabled} />
})

const WaringMessageDialog = memo((props: {
    dialog: boolean,
    onClose: () => void,
}) => {
    const { dialog, onClose } = props

    return <Dialog open={dialog} onClose={onClose} maxWidth="xl" style={{ flex: 1 }} BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}>
        <DialogTitle style={{ textAlign: 'center' }}><Typography variant="h3"><FormattedMessage id="warningMessageTitle" /></Typography></DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
            <DialogContentText>
                <FormattedMessage id="warningMessageContent" />
            </DialogContentText>
            <Button
                variant={'outlined'}
                color="secondary"
                onClick={onClose}
                style={{
                    width: '30%',
                }}
            >
                <FormattedMessage id="ok" />
            </Button>
        </DialogContent>
    </Dialog>
})

const getFormCheckFields = (isIssue: boolean) => {
    return ({
        calendarCode: { labelId: 'field.calendarCode', required: true, length: { max: 20 } },
        description: { labelId: 'field.description', length: { max: 100 } },
        activeFlag: { labelId: 'field.activeFlag', required: true },
    })
}

const useStyles = makeStyles(() => ({
    restDay: () => {
        return {
            '&>button': {
                color: '#000000',
                backgroundColor: '#e0e0e0',
                borderRadius: '50%',
                '&:hover': {
                    backgroundColor: '#d0d0d0',
                },
            }
        }
    },
    workingDay: () => {
        return {
            '&>button': {
                color: '#000000',
                backgroundColor: 'rgba(0, 0, 0, 0)',
                '&:hover': {
                    backgroundColor: '#d0d0d0',
                },
            }
        }
    },
}))
