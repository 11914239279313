import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"
import { ActiveFlag } from "../enums/ActiveFlag"

export type TnmCompany = Required & Partial<Optional> & TableEntity
type TnmCompanyJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const tnmCompanyFromJson: Project<TnmCompanyJson, TnmCompany> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
    activeFlag: ActiveFlag,
}

interface JsonRequired {
    companyId: number,
    companyCode: string,
}

interface JsonOptional {
    companyName: string,
    companyShortCode: string,
    regionCode: string,
    address1: string,
    address2: string,
    address3: string,
    address4: string,
    postalCode: string,
    contact1: string,
    fax1: string,
    telephone1: string,
    email1: string,
    contact2: string,
    fax2: string,
    telephone2: string,
    email2: string,
    timeZone: string,
    fileName: string,
    activeFlag: number,
    status: number,
}