import { Route, Routes } from "react-router-dom"
import { AuthNavigate } from "../components/AuthNavigate/AuthNavigate"
import { NotFound } from "../layouts/NotFound/NotFound"
import { CAS010 } from "../layouts/privilege/CAS010/CAS010"
import { CAS011 } from "../layouts/privilege/CAS011/CAS011"
import { CAS020 } from "../layouts/privilege/CAS020/CAS020"
import { CAS021 } from "../layouts/privilege/CAS021/CAS021"
import { CAS030 } from "../layouts/privilege/CAS030/CAS030"
import { CAS040 } from "../layouts/privilege/CAS040/CAS040"

export function PrivilegeRoutes() {
    return <Routes>
        <Route path="/role/create" element={<AuthNavigate access="ACCS.CAS011.VIEW"><CAS011 /></AuthNavigate>} />,
        <Route path="/role/edit-:id" element={<AuthNavigate access="ACCS.CAS011.VIEW"><CAS011 /></AuthNavigate>} />,
        <Route path="/role/view-:id" element={<AuthNavigate access="ACCS.CAS011.VIEW"><CAS011 /></AuthNavigate>} />,
        <Route path="/role" element={<AuthNavigate access="ACCS.CAS010.VIEW"><CAS010 /></AuthNavigate>} />,

        <Route path="/user/create" element={<AuthNavigate access="ACCS.CAS021.VIEW"><CAS021 /></AuthNavigate>} />,
        <Route path="/user/edit-:id" element={<AuthNavigate access="ACCS.CAS021.VIEW"><CAS021 /></AuthNavigate>} />,
        <Route path="/user/view-:id" element={<AuthNavigate access="ACCS.CAS021.VIEW"><CAS021 /></AuthNavigate>} />,
        <Route path="/user" element={<AuthNavigate access="ACCS.CAS020.VIEW"><CAS020 /></AuthNavigate>} />,
        <Route path="/accessHistory" element={<AuthNavigate access="ACCS.CAS030.VIEW"><CAS030 /></AuthNavigate>} />,
        <Route path="/userActive" element={<AuthNavigate access="ACCS.CAS040.VIEW"><CAS040 /></AuthNavigate>} />,
        <Route path="*" element={<NotFound scope="privilege" />} />,
    </Routes>
}
