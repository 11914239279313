import { Project } from "@rithe/utils"
import { Projects } from "../../../utils/Projects";
import { dateFromJson } from "../../utils/deserializer"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity";
import { dateToJson } from "../../utils/serializer"

export type OutboundToWIPFactor = Partial<Optional>;
export type OutboundToWIPFactorJson = Partial<JsonOptional>;

export const outboundToWIPFactorToJson: Project<OutboundToWIPFactor, OutboundToWIPFactorJson> = obj => ({
    ...obj,
    outboundDateStart: dateToJson(obj.outboundDateStart),
    outboundDateEnd: dateToJson(obj.outboundDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page)
})

export const outboundToWIPFactorFromJson: Project<OutboundToWIPFactorJson, OutboundToWIPFactor> = json => ({
    ...json,
    outboundDateStart: dateFromJson(json.outboundDateStart),
    outboundDateEnd: dateFromJson(json.outboundDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page)
})


interface Optional extends Omit<JsonOptional, 'outboundDateStart' | 'outboundDateEnd' | 'page'> {
    outboundDateStart: Date,
    outboundDateEnd: Date,
    page: PageViewEntity,
}


interface JsonOptional {
    partsNo: string,
    customerCodes: string[],
    contractNo: string,
    unitPartsNo: string,
    outboundDateStart: string,
    outboundDateEnd: string,
    backNo: string,
    expCountrys: string[],
    supplierCodes: string[],

    page: PageViewEntityJson

}


