import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"
import { ActiveFlag } from "../enums/ActiveFlag"
import { CbdsType } from "../enums/CbdsType"

export type RequestToResult = Required & Partial<Optional> & TableEntity
type RequestToResultJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const requestToResultFromJson: Project<RequestToResultJson, RequestToResult> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
    activeFlag: ActiveFlag,
    cbdsType: CbdsType,
}

interface JsonRequired {
    cbdsUid: string,
    cbdsType: number,
    cbdsId: number,
}

interface JsonOptional {
    cbdsCode: string,
    cbdsName: string,
    cbdsShortCode: string,
    regionCode: string,
    address1: string,
    address2: string,
    address3: string,
    address4: string,
    postalCode: string,
    contact1: string,
    fax1: string,
    telephone1: string,
    email1: string,
    contact2: string,
    fax2: string,
    telephone2: string,
    email2: string,
    timeZone: string,
    calendarId: number,
    activeFlag: number,
}



