import { Button, makeStyles, Tooltip } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import { Access } from '../Access/Access'
import { IconButton } from '../Button/IconButton'

interface CallbackCardActionProps {
    access?: string,
    title: NonNullable<React.ReactNode>,
    icon?: React.ReactNode,
    disabled?: boolean,
    outlined?: boolean,
    callback: () => void,
}

export const CallbackCardAction = (props: CallbackCardActionProps) => {
    const { access, title, icon, disabled, outlined, callback } = props

    const styles = useStyles()
    const action = icon
        ? <Tooltip title={title}>
            <IconButton
                variant={outlined ? 'outlined' : 'contained'}
                color="secondary"
                disabled={disabled}
                onClick={callback}
                className={clsx(styles.root, outlined && styles.rootOutlined)}
            >
                {icon}
            </IconButton>
        </Tooltip>
        : <Button
            variant={outlined ? 'outlined' : 'contained'}
            color="secondary"
            disabled={disabled}
            onClick={callback}
            className={clsx(styles.root, outlined && styles.rootOutlined)}
        >
            {title}
        </Button>
    return access ? <Access access={access}>{action}</Access> : action
}

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 120,
    },
    rootOutlined: {
        color: theme.palette.secondary.main,
    },
}))