import { useCallback, useEffect, useMemo, useState } from "react"
import { useGetFCChangedableOrderList, useGetOrderChangedableOrderList } from "../../../services/order/apis/ChangeRequestApi"
import type { BUChangeRequestListResult } from "../../../services/order/models/BUChangeRequestListResult"
import { useMatch } from "../../../utils/useMatch"
import { ONS040PcUi } from "./ONS040PcUi"
import { TabId } from "./Records"


export const ONS040 = () => {

    const { path } = useMatch()
    const currentTabId = useMemo(() => prepareTabIdByPath(path), [path])
    const [orderChangedableList, setOrderChangedableList] = useState<BUChangeRequestListResult[]>([])
    const [fcChangedableList, setFcChangedableList] = useState<BUChangeRequestListResult[]>([])

    const getOrderChangedableList = useGetOrderChangedableOrderList()
    const getFcChangedableList = useGetFCChangedableOrderList()

    const searchOrderChangedableList = useCallback(() => {
        getOrderChangedableList(undefined, { silent: true, serialized: true }).then(result => {
            setOrderChangedableList(result || [])
        })
    }, [getOrderChangedableList])

    const searchFcChangedableList = useCallback(() => {
        getFcChangedableList(undefined, { silent: true, serialized: true }).then(result => {
            setFcChangedableList(result || [])
        })
    }, [getFcChangedableList])


    useEffect(() => {
        if (currentTabId === TabId.forecast) {
            searchFcChangedableList()
        } else {
            searchOrderChangedableList()
        }

    }, [currentTabId, searchFcChangedableList, searchOrderChangedableList])

    return <ONS040PcUi
        tabId={currentTabId}
        orderChangedableList={orderChangedableList}
        searchOrderChangedableList={searchOrderChangedableList}
        fcChangedableList={fcChangedableList}
        searchFcChangedableList={searchFcChangedableList}
    />
}
const prepareTabIdByPath = (path: any) => {
    switch (path) {
        case "/bro-order":
            return TabId.order;
        case "/bro-forecast":
            return TabId.forecast;
        default:
            return TabId.ONS040;
    }
}