import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, KeyTypeProvider, PaginationLayout, Paging, Row, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { EntriesItem, StringItem } from "@rithe/form"
import { Objects, Records } from "@rithe/utils"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useFunctionStore } from "../../../Root"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { EditRedirectRowAction } from "../../../components/DataGrid/rowActions/EditRedirectRowAction"
import { SubmitCallbackRowAction } from "../../../components/DataGrid/rowActions/SubmitCallbackRowAction"
import { ViewRedirectRowAction } from "../../../components/DataGrid/rowActions/ViewRedirectRowAction"
import { WithdrawCallbackRowAction } from "../../../components/DataGrid/rowActions/WithdrawCallbackRowAction"
import { CreateRedirectToolbarAction } from "../../../components/DataGrid/toolbarActions/CreateRedirectToolbarAction"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { UploadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/UploadGroupedToolbarAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { ActiveFlagTypeProvider } from "../../../components/DataGrid/typeProviders/ActiveFlagTypeProvider"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { useDownloadSupplierMasterByFilter, useDownloadSupplierMasterBySupplierId, useDownloadSupplierSettingByFilter, useDownloadSupplierSettingBySupplierId } from "../../../services/master/apis/masterDownloadApi"
import { useUploadMasterSupplierMaster, useUploadMasterSupplierSettingMaster } from "../../../services/master/apis/masterUploadApi"
import { useGetSupplierDetailForView, useIssueSupplierInfo, useWithdrawSupplierInfo } from "../../../services/master/apis/supplierApi"
import { ActiveFlag } from "../../../services/master/enums/ActiveFlag"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { SupplierFactor } from "../../../services/master/models/SupplierFactor"
import { SupplierResult } from "../../../services/master/models/SupplierResult"
import { TnmCompany } from "../../../services/master/models/TnmCompany"
import { useGetCompanyId, useGetCompanyType } from "../../../utils/ApplicationUtils"
import { useIsPowerUser } from "../../../utils/useIsPowerUser"
import { applicationActions } from "../../Application/applicationSlice"

interface MLS040PcUiProps {
    filters: SupplierFactor,
    search: (filters: SupplierFactor) => void,
    data: SupplierResult[],
    totalCount: number,
    companyList: TnmCompany[],
    setFilters: React.Dispatch<React.SetStateAction<Partial<SupplierFactor>>>,
    timeZoneMap: Record<string, string>,
}

export const MLS040PcUi = (props: MLS040PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({ filters, setFilters, data, search, companyList, timeZoneMap }: MLS040PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<string[]>([])
    const partyType = useGetCompanyType()
    const partyId = useGetCompanyId()
    const isPowerUser = useIsPowerUser()
    const isSupp = partyType === CbdsType.SUPP
    const getStatusLabel = useCallback((row: Row) => row.tempSupplierId !== undefined && row.tempSupplierId !== null ? 'Y' : 'N', [])

    const columns = useMemo(() => [
        { field: 'supplierCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierCode' }), width: 200 },
        { field: 'supplierName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierName' }), width: 300 },
        { field: 'supplierShortCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierShortCode' }), width: 300 },
        { field: 'companyCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.companyCode' }), width: 200 },
        { field: 'regionCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.regionCode' }), width: 200 },
        { field: 'postalCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.postalCode' }), width: 200 },
        { field: 'address1', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.address1' }), width: 400 },
        { field: 'address2', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.address2' }), width: 400 },
        { field: 'address3', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.address3' }), width: 400 },
        { field: 'address4', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.address4' }), width: 400 },
        { field: 'contact1', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.companyContact1' }), width: 400 },
        { field: 'fax1', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.companyFax1' }), width: 200 },
        { field: 'telephone1', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.companyTelephone1' }), width: 200 },
        { field: 'email1', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.companyEmail1' }), width: 200 },
        { field: 'contact2', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.companyContact2' }), width: 200 },
        { field: 'fax2', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.companyFax2' }), width: 200 },
        { field: 'telephone2', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.companyTelephone2' }), width: 200 },
        { field: 'email2', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.companyEmail2' }), width: 200 },
        { field: 'calendarCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.calendarCode' }), width: 200 },
        { field: 'timeZone', dataTypeName: 'timezone', title: intl.formatMessage({ id: 'field.timeZone' }), width: 200 },
        { field: 'activeFlag', dataTypeName: CodeCategory.ActiveFlag, title: intl.formatMessage({ id: 'field.activeFlag' }), width: 200 },
        { field: 'status', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.stillEditing' }), width: 200, getCellValue: getStatusLabel },
        // { field: 'milkrunFlag', dataTypeName: CodeCategory.MilkrunFlag, title: intl.formatMessage({ id: 'field.milkrunFlag' }), width: 200 },
        { field: 'createdDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.createdDate' }), width: 200 },
        { field: 'updatedDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.updatedDate' }), width: 200 },
    ], [getStatusLabel, intl])
    const getRowId = useCallback((row: any) => row.supplierCode, [])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, companyList, search }), [filters, setFilters, companyList, search])
    const actionProps1 = useMemo(() => ({ search, filters }), [filters, search])
    const actionProps2 = useMemo(() => ({ data, selections, filters }), [data, filters, selections])
    const viewable = useCallback((tableRow: TableRow) => tableRow.row?.supplierId !== undefined && tableRow.row?.supplierId !== null, [])
    //const editable = useCallback((tableRow: TableRow) => isPowerUser || (isSupp && tableRow.row?.supplierId === partyId), [isSupp, isPowerUser, partyId])
    const editable = useCallback((tableRow: TableRow) => {
        const company = companyList.find(f => f.companyId === tableRow.row?.companyId)
        return (isPowerUser || (isSupp && tableRow.row?.supplierId === partyId)) && company?.activeFlag === ActiveFlag.ACTIVE
    }, [companyList, isSupp, isPowerUser, partyId])
    const display2 = useCallback((tableRow: TableRow) => (isPowerUser || (isSupp && tableRow.row?.supplierId === partyId)) && tableRow.row?.tempSupplierId !== undefined && tableRow.row?.tempSupplierId !== null, [isSupp, isPowerUser, partyId])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <TimeZoneTypeProvider timeZoneMap={timeZoneMap} />
        <ActiveFlagTypeProvider />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.MilkrunFlag} />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={CreateAction} />
        <ToolbarActionProvider Action={UploadAction} actionProps={actionProps1} />
        <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps2} />
        <RowActionProvider name="view" Action={ViewRowAction} display={viewable} />
        <RowActionProvider name="edit" Action={EditRowAction} display={editable} />
        <RowActionProvider name="submit" Action={SubmitRowAction} actionProps={actionProps1} display={display2} />
        <RowActionProvider name="withdraw" Action={WithdrawRowAction} actionProps={actionProps1} display={display2} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['supplierShortCode', 'timeZone', 'address1', 'address2', 'address3', 'address4', 'contact1', 'fax1', 'telephone1', 'email1', 'contact2', 'fax2', 'telephone2', 'email2', 'calendarCode', 'status']}
            columnSettings={{
                supplierCode: { disableUserControl: true },
                companyCode: { disableUserControl: true },
                postalCode: { disableUserControl: true },
                supplierName: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={160} />
    </DataGrid>
}

const TimeZoneTypeProvider = (props: {
    timeZoneMap: Record<string, string>
}) => {
    const format = useCallback((value: any) => {
        const display = props.timeZoneMap[value]
        return display === undefined ? value : display
    }, [props.timeZoneMap])
    const formatter = useMemo(() => ({
        format
    }), [format])
    return <KeyTypeProvider name="timezone" formatter={formatter} />
}

const FilterItem = (props: {
    search: (filters: SupplierFactor) => void,
    filters: SupplierFactor,
    setFilters: React.Dispatch<React.SetStateAction<Partial<SupplierFactor>>>,
    companyList: TnmCompany[]
}) => {
    const { filters, companyList, setFilters, search } = props
    const companyListEntries: [number, string][] = useMemo(() => companyList.map(m => [m.companyId, m.companyCode]), [companyList])

    const clear = useCallback((filters: SupplierFactor) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: SupplierFactor) => {
        return [
            filters.supplierCode,
            filters.supplierName,
            filters.supplierShortCode,
            filters.timeZone,
            filters.regionCode,
            filters.companyIdList,
            filters.postalCode,
            filters.createdBy,
            filters.updatedBy,
            filters.createdDateStart || filters.createdDateEnd,
            filters.updatedDateStart || filters.updatedDateEnd,
            filters.activeFlagList
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="supplierCode" label={intl.formatMessage({ id: 'field.supplierCode' })} />
        <StringItem field="supplierName" label={intl.formatMessage({ id: 'field.supplierName' })} />
        <StringItem field="supplierShortCode" label={intl.formatMessage({ id: 'field.supplierShortCode' })} />
        <StringItem field="timeZone" label={intl.formatMessage({ id: 'field.timeZone' })} />
        <StringItem field="regionCode" label={intl.formatMessage({ id: 'field.regionCode' })} />
        <EntriesItem field="companyIdList" label={intl.formatMessage({ id: 'field.companyCode' })} entries={companyListEntries} />
        <CodesItem field="activeFlagList" label={intl.formatMessage({ id: 'field.activeFlag' })} code={CodeCategory.ActiveFlag} />
        <StringItem field="postalCode" label={intl.formatMessage({ id: 'field.postalCode' })} />
        <StringItem field="createdBy" label={intl.formatMessage({ id: 'field.createdBy' })} />
        <StringItem field="updatedBy" label={intl.formatMessage({ id: 'field.updatedBy' })} />
        {/* <DateRangeItem field="createdDate" label={intl.formatMessage({ id: 'field.createdDate' })} getValue={createdDateGetValue} mapValue={createdDateMapValue} /> */}
        {/* <DateRangeItem field="updatedDate" label={intl.formatMessage({ id: 'field.updatedDate' })} getValue={updatedDateGetValue} mapValue={updatedDateMapValue} /> */}
    </FilterToolbarItem>
}


const CreateAction = () => {
    const isPowerUser = useIsPowerUser()
    if (isPowerUser) {
        return <CreateRedirectToolbarAction access="MARS.MLS040.CREATE" title={<FormattedMessage id="createNewSupplier" />} path="/supplier/create" />
    } else {
        return <></>
    }
}

const UploadAction = ({ search, filters }: { search: (filter: SupplierFactor) => void, filters: SupplierFactor }) => {
    const uploadMasterApi = useUploadMasterSupplierMaster()
    const uploadSettingApi = useUploadMasterSupplierSettingMaster()
    const uploadMaster = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            files && uploadMasterApi({ file: files[0] }, { serialized: true }).then(() => search(filters))
        })
    }, [filters, search, uploadMasterApi])
    const uploadSetting = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            files && uploadSettingApi({ file: files[0] }, { serialized: true }).then(() => search(filters))
        })
    }, [filters, search, uploadSettingApi])

    const isPowerUser = useIsPowerUser()

    if (isPowerUser) {
        return <UploadGroupedToolbarAction access="MARS.MLS040.UPLOAD">
            {(popupUpload, onClose) => <>
                <GroupedCallbackItem label={<FormattedMessage id="uploadMaster" />} callback={() => uploadMaster(popupUpload)} onClose={onClose} />
                <GroupedCallbackItem label={<FormattedMessage id="uploadSetting" />} callback={() => uploadSetting(popupUpload)} onClose={onClose} />
            </>}
        </UploadGroupedToolbarAction>
    } else {
        return <></>
    }
}

const DownloadAction = ({ data, selections, filters }: { data: SupplierResult[], selections: string[], filters: SupplierFactor }) => {
    const dispatch = useDispatch()
    const downloadMasterBySelectionApi = useDownloadSupplierMasterBySupplierId()
    const downloadMasterByFilterApi = useDownloadSupplierMasterByFilter()
    const downloadSettingBySelectionApi = useDownloadSupplierSettingBySupplierId()
    const downloadSettingByFilterApi = useDownloadSupplierSettingByFilter()
    const downloadMaster = useCallback(() => {
        if (selections.length <= 0) {
            downloadMasterByFilterApi(Objects.delete(filters, 'page'))
        } else {
            const supplierIds = data.filter(d => selections.indexOf(d.supplierCode ?? '') >= 0 && d.supplierId !== undefined && d.supplierId !== null).map(d => d.supplierId!)
            if (supplierIds.length < selections.length) {
                dispatch(applicationActions.pushError({ title: { code: 'downloadSupplier' }, messages: { code: 'w0352' } }))
            } else {
                downloadMasterBySelectionApi({ supplierIdList: supplierIds })
            }
        }
    }, [data, dispatch, downloadMasterByFilterApi, downloadMasterBySelectionApi, filters, selections])
    const downloadSetting = useCallback(() => {
        if (selections.length <= 0) {
            downloadSettingByFilterApi(Objects.delete(filters, 'page'))
        } else {
            const supplierIds = data.filter(d => selections.indexOf(d.supplierCode ?? '') >= 0 && d.supplierId !== undefined && d.supplierId !== null).map(d => d.supplierId!)
            if (supplierIds.length < selections.length) {
                dispatch(applicationActions.pushError({ title: { code: 'downloadSettings' }, messages: { code: 'w0352' } }))
            } else {
                downloadSettingBySelectionApi({ supplierIdList: supplierIds })
            }
        }
    }, [data, dispatch, downloadSettingByFilterApi, downloadSettingBySelectionApi, filters, selections])

    const getSuppDetailForView = useGetSupplierDetailForView()
    const [settingExist, SetSettingExist] = useState<boolean>(false)
    useEffect(() => {
        if (data !== undefined && data.length !== 0) {
            data.forEach(supp => {
                if (supp.supplierId !== undefined && supp.supplierId !== null && supp.supplierCode !== undefined) {
                    getSuppDetailForView({ supplierCode: supp.supplierCode }, { silent: true, serialized: false }).then(result => {
                        SetSettingExist(pre => pre ? pre : result.settings.length !== 0)
                    })
                }
            })
        }
    }, [data, getSuppDetailForView])

    return <DownloadGroupedToolbarAction access="MARS.MLS040.DOWNLOAD">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="downloadSupplier" />} callback={downloadMaster} onClose={onClose} />
            {settingExist ? <GroupedCallbackItem label={<FormattedMessage id="downloadSettings" />} callback={downloadSetting} onClose={onClose} /> : <></>}
        </>}
    </DownloadGroupedToolbarAction>
}

const ViewRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/supplier/view-${tableRow.rowId}`, [])
    return <ViewRedirectRowAction tableRow={tableRow} access="MARS.MLS040.VIEWDETAIL" path={path} />
}

const EditRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/supplier/edit-${tableRow.rowId}`, [])
    return <EditRedirectRowAction tableRow={tableRow} access="MARS.MLS040.EDIT" path={path} />
}

const SubmitRowAction = ({ tableRow, search, filters }: DataGridRowActionProps & { search: (filters: SupplierFactor) => void, filters: SupplierFactor }) => {
    const submitApi = useIssueSupplierInfo()
    const [disabled, setDisabled] = useState<boolean>(false)
    const callback = useCallback((tableRow: TableRow) => {
        setDisabled(true)
        submitApi({ tempSupplierId: tableRow.row?.tempSupplierId }, { serialized: true }).then(() => search(filters)).finally(() => {
            setDisabled(false)
        })
    }, [filters, search, submitApi])
    return <SubmitCallbackRowAction tableRow={tableRow} access="MARS.MLS040.SUBMIT" callback={callback} disabled={disabled} />
}

const WithdrawRowAction = ({ tableRow, search, filters }: DataGridRowActionProps & { search: (filters: SupplierFactor) => void, filters: SupplierFactor }) => {
    const withdrawApi = useWithdrawSupplierInfo()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'withdraw' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const callback = useCallback((tableRow: TableRow) => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            withdrawApi({ tempSupplierId: tableRow.row?.tempSupplierId }, { serialized: true }).then(() => search(filters)).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [dispatch, filters, functionStore, search, title, withdrawApi])
    return <WithdrawCallbackRowAction tableRow={tableRow} access="MARS.MLS040.WITHDRAW" callback={callback} disabled={disabled} />
}