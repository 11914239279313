import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"

export type CoListResult = Required & Partial<Optional>
type CoListResultJson = JsonRequired & Partial<JsonOptional>
export const CoListResultFromJson: Project<CoListResultJson, CoListResult> = json => ({
    ...json,
    coDate: dateFromJson(json.coDate),
    submittedDate: dateFromJson(json.submittedDate),
    createdDate: dateFromJson(json.createdDate),
    updatedDate: dateFromJson(json.updatedDate),
    completedDate: dateFromJson(json.completedDate),
    currentDate: dateFromJson(json.currentDate),
})

interface Required extends JsonRequired {

}

interface Optional extends Omit<JsonOptional, 'coDate' | 'submittedDate' | 'createdDate' | 'updatedDate' | 'completedDate' | 'currentDate'> {
    coDate: Date,
    submittedDate: Date,
    createdDate: Date,
    updatedDate: Date,
    completedDate: Date,
    currentDate: Date,
}

interface JsonRequired {
    coSpotId: number,
}

interface JsonOptional {
    preSpotId: number,
    customerId: number,
    buId: number,
    supplierId: number,
    orderType: number,
    customerOrderNo: string,
    orderReference: string,
    receiveDcId: number,
    shippingMode: number,
    remark: string,
    paymentTermsId: string,
    currency: string,
    submittedBy: string,
    status: number,
    createdBy: string,
    updatedBy: string,
    version: number,
    updatedDate: string,
    createdDate: string,
    coDate: string,
    submittedDate: string,
    completedDate: string,
    currentDate: string,
    sellerUid: string
}