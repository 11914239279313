export enum CodeCategory {
    OpenArFlag = 'OPEN_AR_FLAG',
    UnvoiceOrderFlag = 'UNVOICED_ORDER_FLAG',
    ActiveFlag = "ACTIVE_FLAG",
    StockAlertLevel = "STOCK_ALERT_LEVEL",
    StockSendFrequercy = "STOCK_SEND_FREQUERCY",
    StockSendDay = "STOCK_SEND_DAY",
    StockAlertTiming = "STOCK_ALERT_TIMING",
    PokayokeAlertLevel = "POKAYOKE_ALERT_LEVEL",
    MilkrunFlag = "MILKRUN_FLAG",
    BuildoutFlag = "BUILDOUT_FLAG",
    ChangeFlag = "CHANGE_FLAG",
    ChangeType = "CHANGE_TYPE",
    CbdsType = "CBDS_TYPE",
    IsPowerFlag = "IS_POWER_FLAG",
    ContractType = "CONTRACT_TYPE",
    CustomsFlag = "CUSTOMS_FLAG",
    CustomsInvoiceStatus = "CUSTOMS_INVOICE_STATUS",
    OutboundStatus = "OUTBOUND_STATUS",
    InboundStatus = "INBOUND_STATUS",
    InboundPackageStatus = "INBOUND_PACKAGE_STATUS",
    CoStatus = "CO_STATUS",
    SoStatus = "SO_STATUS",
    PoStatus = "PO_STATUS",
    DelayStatus = "DELAY_STATUS",
    InvoiceStatus = "INVOICE_STATUS",
    DisplayInvoiceStatus = "INVOICE_DISPLAY_STATUS",
    InboundType = "INBOUND_TYPE",
    IsHeavy = "IS_HEAVY",
    OrderFrequency = "ORDER_FREQUENCY",
    OrderlotFlag = "ORDERLOT_FLAG",
    OrderType = "ORDER_TYPE",
    SoType = "SO_TYPE",
    PoType = "PO_TYPE",
    OutboundType = "OUTBOUND_TYPE",
    UploadOutboundType = "UPLOAD_OUTBOUND_TYPE",
    PairedFlag = "PAIRED_FLAG",
    PairedOrderFlag = "PAIRED_ORDER_FLAG",
    RequestStatus = "REQUEST_STATUS",
    RequestType = "REQUEST_TYPE",
    CrdType = "CRD_TYPE",
    RolledPartsFlag = "ROLLED_PARTS_FLAG",
    ShippingMode = "SHIPPING_MODE",
    UnitType = "UNIT_TYPE",
    UserAccesslevel = "USER_ACCESSLEVEL",
    UserStatus = "USER_STATUS",
    WorkingFlag = "WORKING_FLAG",
    TermsType = "TERMS_TYPE",
    PriceBasis = "PRICE_BASIS",
    InboundResultStatus = "INBOUND_RESULT_STATUS",
    CustomsInvoiceType = "CUSTOMS_INVOICE_TYPE",
    GrInvoiceType = "GR_INVOICE_TYPE",
    GiInvoiceType = "GI_INVOICE_TYPE",
    UserType = "USER_TYPE",
    ArStatus = "AR_STATUS",
    ApStatus = "AP_STATUS",
    InvoiceCategory = "INVOICE_CATEGORY",
    ProfileType = "PROFILE_TYPE",
    SimulatedType = "SIMULATED_TYPE",
    MonitoringType = "MONITORING_TYPE",
    SimulationStep = "SIMULATION_STEP",
    SimulationOpt = "SIMULATION_OPT",
    DeliveryType = "DELIVERY_TYPE",
    UserDefaultFlag = "USER_DEFAULT_FLAG",
    CalcOrderType = "CALC_ORDER_TYPE",
    RepackingType = "REPACKING_TYPE",
    BusinessType = "BUSINESS_TYPE",
    IncomingStatus = "INCOMING_STATUS",
    DiType = "DI_TYPE",
    DiStatus = "DI_STATUS",
    UnpackingFlag = "UNPACKING_FLAG",
    FtaFlag = "FTA_FLAG",
    ImpCcStatus = "IMP_CC_STATUS",
    ActionType = "ACTION_TYPE",
    DiscontinueIndicator = "DISCONTINUE_INDICATOR",
    SETTING_TYPE = "SETTING_TYPE",
    HscodeOutputFlag = "HSCODE_OUTPUT_FLAG",
    OnBehalfFlag = "ON_BEHALF_FLAG",
    PartsType = "PARTS_TYPE",
    AutoDiFlag = "AUTO_DI_FLAG",
    ContractRequestStatus = "CONTRACT_REQUEST_STATUS",
    ContractRequestType = "CONTRACT_REQUEST_TYPE",
    ParticipantType = "PARTICIPANT_TYPE",
    ContentType = "CONTENT_TYPE",
    OwnerType = "OWNER_TYPE",
    WorkingType = "WORKING_TYPE",
    WorkingStatus = "WORKING_STATUS",
    StateCodeStatus = "STATE_CODE_STATUS",
    ContractRouteStatus = "CONTRACT_ROUTE_STATUS",
    RoStatus = "RO_STATUS",
    CustomsClearanceStatus = "CUSTOMS_CLEARANCE_STATUS",
    WeekFlag = "WeekFlag",
    FobInvValueLogic = "FOB_INV_VALUE_LOGIC",
    TodoListStatus = "TODO_LIST_STATUS",
    DeliveryTypeFlag = "DELIVERY_TYPE_FLAG",
    SmtActiveFlag = "SMT_ACTIVE_FLAG",
    OnShippingDelayPattern = "ON_SHIPPING_DELAY_PATTERN",
    AdjustmentPattern1 = "ADJUSTMENT_PATTERN1",
    AdjustmentPattern2 = "ADJUSTMENT_PATTERN2",
    DataSource = "DATA_SOURCE",
    InventoryBoxFlag = "INVENTORY_BOX_FLAG",
    SafetyStockUom = "SAFETY_STOCK_UOM",
    CalculationPattern = "CALCULATION_PATTERN",
    LeadtimeMethod = "LEADTIME_LEVEL",
    TodoListTaskType = "TODO_LIST_TASK_TYPE",
    TodoListRecurrence = "TODO_LIST_RECURRENCE",
    TodoListPrority = "TODO_LIST_PRORITY",
    TodoListScheduleType = "TODOLIST_SCHEDULETYPE",
    CustomerMailSettingType = "CUST_MAIL_SETTING_TYPE",
    DCMailSettingType = "DC_MAIL_SETTING_TYPE",
    BUMailSettingType = "BU_MAIL_SETTING_TYPE",
    SUMailSettingType = "SU_MAIL_SETTING_TYPE",
    PlaceSpotStatus = "PLACE_SPOT_STATUS",
    PlaceType = "PLACE_TYPE",
    PlaceSpotDetailStatus = "PLACE_SPOT_DETAIL_STATUS",
    SoSpotStatus = "SO_SPOT_STATUS",
    SoSpotDetailStatus = "SO_SPOT_DETAIL_STATUS",
    CoSpotStatus = "CO_SPOT_STATUS",
    CoSpotDetailStatus = "CO_SPOT_DETAIL_STATUS",
    PoSpotStatus = "PO_SPOT_STATUS",
    PoSpotDetailStatus = "PO_SPOT_DETAIL_STATUS",
    RoSpotDetailStatus = "RO_SPOT_DETAIL_STATUS",
    RoSpotStatus = "RO_SPOT_STATUS",
    RoSpotReviceType = "RO_SPOT_REVISE_TYPE",
    CreditCheckFlag = "CREDIT_CHECK_FLAG",
    CreditHoldFlag = "CREDIT_HOLD_FLAG",
    OrderCalcStatus = "ORDER_CALC_STATUS",
    CargoRole = "CARGO_ROLE",
    ShippingDetailStatus = "SHIPPING_DETAIL_STATUS",
    BufferUsageType = "BUFFER_USAGE_TYPE",
    AlertMethod = "ALERT_METHOD",
    YesNoFlag = "YES_NO_FLAG",
    InboundDataType = "INBOUND_DATA_TYPE",
    CustomerStockFlag = "CUSTOMER_STOCK_FLAG"
}