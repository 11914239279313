import { Project } from "@rithe/utils";



export type DownloadFiledsFactor = Required & Partial<Optional>;

type DownloadFiledsFactorJson = JsonRequired & Partial<JsonOptional>;

export const downloadFiledsFactorToJson: Project<DownloadFiledsFactor, DownloadFiledsFactorJson> = obj => ({
    ...obj,
})
interface Required extends JsonRequired {

}

interface Optional extends JsonOptional {
}

interface JsonRequired {

}

interface JsonOptional {
    projectId: number,
    noOfLevel: number,
    usageHistoryId: number,
}
