import { Tooltip } from "@material-ui/core"
import { AssignmentOutlined } from "@material-ui/icons"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { useState } from "react"
import { FormattedMessage } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Access } from "../../../components/Access/Access"
import { IconButton } from "../../../components/Button/IconButton"
import { OrderCalcStatus } from "../../../services/order/enums/OrderCalcStatus"
import { ConfirmDialog } from "./ConfirmDialog"
import { DetailTableRow } from "./DetailTable"
import { occls010Actions, OrderCalculationType } from "./OCCLS010Slice"

export interface DetailPlaceOrderRowActionProps extends DataGridRowActionProps {
  type: OrderCalculationType
}

export function DetailPlaceOrderRowAction(props: DetailPlaceOrderRowActionProps) {
  const { tableRow, type } = props
  const row = tableRow.row as DetailTableRow
  const orderCalcNo = row.orderCalcNo

  const [open, setOpen] = useState(false)
  const display = (row.status === OrderCalcStatus.PENDING_ORDER
    || row.status === OrderCalcStatus.PENDING_ISSUED
    || row.inCurrentMonth) && false

  const navigate = useNavigate()
  const dispatch = useDispatch()
  if (!display) {
    return null
  } else if (row.status === OrderCalcStatus.PENDING_ORDER) {
    return <>
      <Access access="STCK.OCCLS010.CONFRIM">
        <Tooltip title={<FormattedMessage id="PlaceOrder" />}>
          <IconButton onClick={() => setOpen(true)}>
            <AssignmentOutlined />
          </IconButton>
        </Tooltip>
      </Access>
      <ConfirmDialog
        open={open}
        onClose={() => setOpen(false)}
        title={<FormattedMessage id="confirm" />}
        content={"Place Order will be generated after submission. Are you sure to submit?"}
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={<FormattedMessage id="confirm" />}
        onConfirm={() => {
          dispatch(occls010Actions.placeOrder({
            data: { orderCalcNo },
            callback: (poGroupId) => navigate(type === 'PNA'
              ? `/orderCalculationPNA/placeOrderRegular-${poGroupId}`
              : type === 'Ehm'
                ? `/orderCalculationEhm/placeOrderRegular-${poGroupId}`
                : `/orderCalculation/placeOrderRegular-${poGroupId}`)
          }))
        }}
      />
    </>
  } else {
    return <Access access="STCK.OCCLS010.CONFRIM">
      <Tooltip title={<FormattedMessage id="PlaceOrder" />}>
        <IconButton onClick={() => {
          if (row.orderVersion === 1) {
            if (row.status === OrderCalcStatus.PENDING_ISSUED) {
              type === 'PNA'
                ? navigate(`/orderCalculationPNA/placeOrderRegular-${row.orderCalcId}`)
                : type === 'Ehm'
                  ? navigate(`/orderCalculationEhm/placeOrderRegular-${row.orderCalcId}`)
                  : navigate(`/orderCalculation/placeOrderRegular-${row.orderCalcId}`)
            } else {
              type === 'PNA'
                ? navigate(`/orderCalculationPNA/placeOrderRegularView-${row.orderCalcId}`)
                : type === 'Ehm'
                  ? navigate(`/orderCalculationEhm/placeOrderRegularView-${row.orderCalcId}`)
                  : navigate(`/orderCalculation/placeOrderRegularView-${row.orderCalcId}`)
            }
          } else {
            if (row.status === OrderCalcStatus.PENDING_ISSUED) {
              type === 'PNA'
                ? navigate(`/orderCalculationPNA/placeOrderSpot-${row.poGroupId}`)
                : type === 'Ehm'
                  ? navigate(`/orderCalculationEhm/placeOrderSpot-${row.poGroupId}`)
                  : navigate(`/orderCalculation/placeOrderSpot-${row.poGroupId}`)
            } else {
              type === 'PNA'
                ? navigate(`/orderCalculationPNA/placeOrderSpotView-${row.poGroupId}`)
                : type === 'Ehm'
                  ? navigate(`/orderCalculationEhm/placeOrderSpotView-${row.poGroupId}`)
                  : navigate(`/orderCalculation/placeOrderSpotView-${row.poGroupId}`)
            }
          }
        }}>
          <AssignmentOutlined />
        </IconButton>
      </Tooltip>
    </Access>
  }
}