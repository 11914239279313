import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { DateRangeItem, EntriesItem } from "@rithe/form"
import { Records } from "@rithe/utils"
import { useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, IntlProvider, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useFunctionStore } from "../../../Root"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DeleteCallbackRowAction } from "../../../components/DataGrid/rowActions/DeleteCallbackRowAction"
import { EditCallbackRowAction } from "../../../components/DataGrid/rowActions/EditCallbackRowAction"
import { ViewCallbackRowAction } from "../../../components/DataGrid/rowActions/ViewCallbackRowAction"
import { CreateRedirectToolbarAction } from "../../../components/DataGrid/toolbarActions/CreateRedirectToolbarAction"
import { DeleteCallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/DeleteCallbackToolbarAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { View } from "../../../components/View/View"
import appConfig from "../../../configs/appConfig"
import { Language, effectiveLanguage } from "../../../configs/i18n/Language"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { AnnouncementStatus } from "../../../services/system/enums/AnnouncementStatus"
import { AnnouncementType } from "../../../services/system/enums/AnnouncementType"
import { applicationActions, useApplicationSelector } from "../../Application/applicationSlice"
import { mls260Actions, useMLS260Selector } from "./MLS260Slice"
import messagesEnUs from './i18n/mls260.en-US.json'

const messagesMap: Record<Language, Record<string, string>> = {
    'en-US': messagesEnUs,
    'zh-CN': messagesEnUs,
}

export function MLS260() {

    const dispatch = useDispatch()
    useEffect(() => {
        const sessionKey = `${appConfig.appFullName}:MLS260.filter`
        let cachedFilter = {}
        try {
            cachedFilter = JSON.parse(sessionStorage.getItem(sessionKey) ?? '{}')
        } catch (e) { }
        const defaultFilter = {
            typeIn: undefined,
            publishAtSince: undefined,
            publishAtUntil: undefined,
            expireAtSince: undefined,
            expireAtUntil: undefined,
        }
        dispatch(mls260Actions.setFilter(
            { ...defaultFilter, ...cachedFilter }
        ))
        dispatch(mls260Actions.listAnnouncements())
    }, [dispatch])

    return <ScreenIntlProvider>
        <View flex>
            <SectionCard>
                <SectionCardContent>
                    <DataTable />
                </SectionCardContent>
            </SectionCard>
        </View>
    </ScreenIntlProvider>
}

const ScreenIntlProvider = ({ children }: { children: React.ReactNode }) => {
    const language = useApplicationSelector(state => state.i18n.language)
    const timezone = useApplicationSelector(state => state.i18n.timezone)
    const messages = useMemo(() => {
        const messages = messagesMap[effectiveLanguage(language)]
        const defaultMessages = messagesMap[Language.DEFAULT]
        return { ...defaultMessages, ...messages }
    }, [language])
    return <IntlProvider messages={messages} locale={language} timeZone={timezone}>
        {children}
    </IntlProvider>
}

function DataTable() {
    const intl = useIntl()
    const announcements = useMLS260Selector(state => state.announcements)
    const [selections, setSelections] = useState<string[]>([])

    const getPublishAt = useCallback((row: any) => {
        return row.publishAt ? new Date(row.publishAt) : null
    }, [])

    const getExpireAt = useCallback((row: any) => {
        return row.expireAt ? new Date(row.expireAt) : null
    }, [])

    const columns = useMemo(() => [
        // { field: 'id', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.id' }), width: 200 },
        { field: 'type', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.type' }), width: 200 },
        { field: 'title', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.title' }), width: 200 },
        { field: 'summary', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.summary' }), width: 250 },
        { field: 'publishAt', dataTypeName: 'date', title: intl.formatMessage({ id: 'column.publishAt' }), width: 200, getCellValue: getPublishAt },
        { field: 'expireAt', dataTypeName: 'date', title: intl.formatMessage({ id: 'column.expireAt' }), width: 200, getCellValue: getExpireAt },
        { field: 'status', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.status' }), width: 150 },
    ], [getExpireAt, getPublishAt, intl])
    const getRowId = useCallback((row: any) => row.id, [])
    const displayRowEdit = useCallback((tableRow: TableRow) => tableRow.row?.status === AnnouncementStatus.DRAFT
        || tableRow.row?.status === AnnouncementStatus.CONFIRMED
        || tableRow.row?.status === AnnouncementStatus.PUBLISHED, [])
    const displayRowDelete = useCallback((tableRow: TableRow) => tableRow.row?.status === AnnouncementStatus.DRAFT
        || tableRow.row?.status === AnnouncementStatus.CONFIRMED, [])
    const deleteActionProps = useMemo(() => ({ selections, setSelections }), [selections])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.CoStatus} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.BusinessType} />
        <Data rows={announcements} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={CreateAction} />
        <ToolbarActionProvider Action={DeleteAction} actionProps={deleteActionProps} />
        <RowActionProvider name="view" Action={ViewRegularRowAction} />
        <RowActionProvider name="edit" Action={EditRegularRowAction} display={displayRowEdit} />
        <RowActionProvider name="delete" Action={DeleteRowAction} display={displayRowDelete} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={[]}
            columnSettings={{
                customerCode: { disableUserControl: true },
                customerContractNo: { disableUserControl: true }
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={AnnouncementsFilter} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={200} />
    </DataGrid>
}

const CreateAction = () => {
    return <CreateRedirectToolbarAction
        power
        title={<FormattedMessage id="action.create" />}
        path="/announcements/create" />
}


const DeleteAction = ({ selections, setSelections }: {
    selections: string[],
    setSelections: React.Dispatch<React.SetStateAction<string[]>>
}) => {
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'action.delete' }), [intl])

    const callback = useCallback(() => {
        const functionId = functionStore.register(() => {
            const ids = selections
            ids && dispatch(mls260Actions.deleteAnnouncements(ids))
            setSelections([])
        })

        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [dispatch, functionStore, selections, setSelections, title])

    const disabled = useMLS260Selector(state => selections.length === 0 || !state.announcements
        .filter(announcement => selections.includes(announcement.id))
        .every(annoncement => annoncement.status === AnnouncementStatus.DRAFT
            || annoncement.status === AnnouncementStatus.CONFIRMED))

    return <DeleteCallbackToolbarAction
        power
        disabled={disabled}
        title={title}
        callback={callback} />
}

function AnnouncementsFilter() {
    const sessionKey = `${appConfig.appFullName}:MLS260.filter`
    const dispatch = useDispatch()
    const filters = useMLS260Selector(state => state.filter)
    const [filter, setFilter] = useState(() => ({
        typeIn: filters.typeIn === undefined ? null : filters.typeIn,
        publishAtSince: filters.publishAtSince === undefined ? null : new Date(filters.publishAtSince),
        publishAtUntil: filters.publishAtUntil === undefined ? null : new Date(filters.publishAtUntil),
        expireAtSince: filters.expireAtSince === undefined ? null : new Date(filters.expireAtSince),
        expireAtUntil: filters.expireAtUntil === undefined ? null : new Date(filters.expireAtUntil),
    }))

    const types = useMemo(() => {
        return [AnnouncementType.NEW, AnnouncementType.IMPROVEMENT, AnnouncementType.MAINTENANCE].map(type => [type, type]) as [string, string][]
    }, [])
    const filterCounter = useCallback((filter: any) => {
        return [
            filter.typeIn,
            filter.publishAtSince || filter.publishAtUntil,
            filter.expireAtSince || filter.expireAtUntil,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const clear = useCallback(() => {
        return {
            typeIn: null,
            publishAtSince: null,
            publishAtUntil: null,
            expireAtSince: null,
            expireAtUntil: null,
        }
    }, [])
    const submit = useCallback(() => {
        dispatch(mls260Actions.setFilter({
            typeIn: filter.typeIn as AnnouncementType[],
            publishAtSince: filter.publishAtSince === null ? undefined : filter.publishAtSince.getTime(),
            publishAtUntil: filter.publishAtUntil === null ? undefined : filter.publishAtUntil.getTime(),
            expireAtSince: filter.expireAtSince === null ? undefined : filter.expireAtSince.getTime(),
            expireAtUntil: filter.expireAtUntil === null ? undefined : filter.expireAtUntil.getTime(),
        }))
        dispatch(mls260Actions.listAnnouncements())
    }, [dispatch, filter])

    useEffect(() => {
        sessionStorage.setItem(sessionKey, JSON.stringify(filters))
    }, [filters, sessionKey])

    const publishGetValue = useCallback((filter: any) => {
        return [filter.publishAtSince, filter.publishAtUntil]
    }, [])
    const publishMapValue = useCallback((filter: any, value: any) => {
        return { ...filter, publishAtSince: value[0], publishAtUntil: value[1] }
    }, [])
    const expireGetValue = useCallback((filter: any) => {
        return [filter.expireAtSince, filter.expireAtUntil]
    }, [])
    const expireMapValue = useCallback((filter: any, value: any) => {
        return { ...filter, expireAtSince: value[0], expireAtUntil: value[1] }
    }, [])

    const intl = useIntl()
    return <FilterToolbarItem
        columnCount={3}
        filters={filter}
        onFiltersChange={setFilter}
        filterCounter={filterCounter}
        clear={clear}
        onSubmit={submit}
    >
        <EntriesItem field="typeIn" label={intl.formatMessage({ id: 'filter.type' })} entries={types} />
        <DateRangeItem field="publish" label={intl.formatMessage({ id: 'filter.publishAt' })} getValue={publishGetValue} mapValue={publishMapValue} />
        <DateRangeItem field="expire" label={intl.formatMessage({ id: 'filter.expireAt' })} getValue={expireGetValue} mapValue={expireMapValue} />
    </FilterToolbarItem >
}

const DeleteRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'action.delete' }), [intl])
    const callback = useCallback((tableRow: TableRow) => {
        const functionId = functionStore.register(() => {
            const id = tableRow.row?.id
            id && dispatch(mls260Actions.deleteAnnouncement(id))
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: intl.formatMessage({ id: 'action.cancel' }),
            }, {
                functionId,
                label: intl.formatMessage({ id: 'action.confirm' }),
            }]
        }))
    }, [dispatch, functionStore, intl, title])
    return <DeleteCallbackRowAction
        power
        title={title}
        tableRow={tableRow}
        callback={callback} />
}


const EditRegularRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const navigate = useNavigate()
    const callback = useCallback((tableRow: TableRow) => {
        navigate(`/announcements/edit-${tableRow.rowId}`)
    }, [navigate])
    return <EditCallbackRowAction
        power
        title={<FormattedMessage id="action.edit" />}
        tableRow={tableRow}
        callback={callback} />
}


const ViewRegularRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const navigate = useNavigate()
    const callback = useCallback((tableRow: TableRow) => {
        navigate(`/announcements/view-${tableRow.row?.id}`)
    }, [navigate])
    return <ViewCallbackRowAction
        power
        title={<FormattedMessage id="action.view" />}
        tableRow={tableRow}
        callback={callback} />
}

