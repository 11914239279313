import { useCallback } from "react"
import { Projects } from "../../../utils/Projects"
import { useUpload } from "../../../utils/fetch/useFetch"

/** INT070 */
export const useUploadExternalMPDStock = () => {
    const url = '/integration-api/tool/uploadExternalMPDStock'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File, stockDate: string }, undefined>(url, payloadProject, resultProject)
}