import { Objects } from "@rithe/utils"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { useComMasterGetActiveCbds, useComMasterGetAllUom } from "../../../services/common/apis/commonMasterApi"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { TnmUom } from "../../../services/master/models/TnmUom"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { useGetPCWCDetail } from "../../../services/order/apis/ChangeRequestApi"
import { ParamRequest, ParamRequestFromJson } from "../../../services/order/models/ChangeRequestWithoutContractListResult"
import { NewInboundPalnDetail, PartsDetail, PlaceChangeWCDetailResult } from "../../../services/order/models/PlaceChangeWCDetailResult"
import { addNumber, mulNumber } from "../../../utils/NumberUtil"
import sessionKeys from "../../../utils/sessionKeys"
import { useGetSessionAndGobackIfEmpty } from "../../../utils/sessionUtil"
import { useMatch } from "../../../utils/useMatch"
import { OCS026PcUi } from "./OCS026PcUi"


export interface PlaceChangeWCResultBasic extends PlaceChangeWCDetailResult {
    totalQty: number,
    totalAmount: number,
    totalNumberOfParts: number,
}

export const OCS026 = () => {

    const { path } = useMatch()
    const { coSpotId } = useParams() as any

    const getSession = useGetSessionAndGobackIfEmpty()
    let factor = useMemo(() => getSession(sessionKeys.Data_OCS026, ParamRequestFromJson, '/pcwc/modify-:coSpotId') as ParamRequest, [getSession])
    const roSpotId = factor.roSpotId

    const mode = path === '/pcwc/modify-:coSpotId' ? ScreenMode.EDIT : ScreenMode.VIEW

    const [basic, setBasic] = useState<PlaceChangeWCResultBasic>({} as PlaceChangeWCResultBasic)
    const [inboundPlans, setInboundPlans] = useState<PartsDetail[]>([] as PartsDetail[])
    const [uomList, setUomList] = useState<TnmUom[]>([])
    const [receiveDcList, setReceiveDcList] = useState<TnvCbds[]>([])

    const splitDatas = useSplitDatas()
    const getData = useGetPCWCDetail()
    const getUomList = useComMasterGetAllUom()
    const getCbds = useComMasterGetActiveCbds()

    const search = useCallback((coSpotId: number | null | undefined, roSpotId: number | null | undefined) => {
        if (coSpotId) {
            getData({ coSpotId: coSpotId, roSpotId: roSpotId }, { silent: true }).then(result => {
                if (result) {
                    let defaultData: PlaceChangeWCDetailResult = result
                    if (defaultData && defaultData.totCoSpotDetailList && !defaultData.roSpotIdInRo) {
                        defaultData.totCoSpotDetailList.forEach(m => {
                            m.orderQtyInRo = m.oldFirmQty
                            if (m.oldReqInboundPlanList) {
                                m.oldReqInboundPlanList.forEach(oldPlan => {
                                    let newReqInboundPlan: NewInboundPalnDetail = {
                                        crdDate: oldPlan.crdDate,
                                        crdQty: oldPlan.crdQty
                                    }
                                    m.newReqInboundPlanList.push(newReqInboundPlan)

                                })
                            }


                        })
                    }

                    const { orderbasic, orderInboundPlans } = splitDatas(defaultData)
                    setBasic(orderbasic)
                    setInboundPlans(orderInboundPlans)

                }
            })
        }
    }, [getData, splitDatas])

    useEffect(() => {
        getUomList(undefined, { silent: true }).then(result => {
            setUomList(result || [])
        })
        getCbds({ types: [] }, { silent: true }).then(result => {
            setReceiveDcList(result || [])
        })
        search(coSpotId, roSpotId)
    }, [getUomList, search, coSpotId, roSpotId, getCbds])

    return <OCS026PcUi
        search={search}
        basic={basic}
        setBasic={setBasic}
        inboundPlans={inboundPlans}
        setInboundPlans={setInboundPlans}
        mode={mode}
        uomList={uomList}
        receiveDcList={receiveDcList}
    />
}

export const useSplitDatas = () => {
    return useCallback((basic: PlaceChangeWCDetailResult) => {
        const { totCoSpotDetailList } = basic
        const totalQty = totCoSpotDetailList.reduce((v1, v2) => addNumber(v1, v2.orderQtyInRo ? v2.orderQtyInRo : 0), 0)
        const totalAmount = totCoSpotDetailList.reduce((v1, v2) => addNumber(v1, (v2.orderQtyInRo && v2.buyingPrice ? mulNumber(v2.orderQtyInRo, v2.buyingPrice) : 0)), 0)
        const totalNumberOfParts = totCoSpotDetailList.length
        const orderbasic: PlaceChangeWCResultBasic = { ...basic, totalQty, totalNumberOfParts, totalAmount }
        const orderInboundPlans: PartsDetail[] = totCoSpotDetailList.map(parts => ({ ...parts }))
        return { orderbasic: orderbasic, orderInboundPlans: orderInboundPlans }
    }, [])
}

export const useMergeDatas = () => {
    return useCallback((orderbasic: PlaceChangeWCResultBasic, orderInboundPlans: PartsDetail[]) => {
        const basic = Objects.delete(orderbasic, 'totalQty', 'totalNumberOfParts', 'totalAmount') as PlaceChangeWCDetailResult
        return { ...basic, totCoSpotDetailList: orderInboundPlans }
    }, [])
}