import React from 'react'
import { FormattedMessage } from 'react-intl'
import { CallbackViewAction } from './CallbackViewAction'

interface ResetCallbackViewActionProps {
    access?: string,
    power?: boolean,
    title?: React.ReactNode,
    disabled?: boolean,
    outlined?: boolean,
    callback: () => void,
}

export const ResetCallbackViewAction = (props: ResetCallbackViewActionProps) => {
    return <CallbackViewAction {...props} title={<FormattedMessage id="reset" />} />
}