import { useCallback, useEffect, useState } from "react"
import { useGetCurencyList, useGetDCList, useGetInventoryData } from "../../../services/dashboard/apis/dashboardApi"
import { Filters } from "../../../services/dashboard/models/InventoryDashboard"
import { InventoryFactor } from "../../../services/dashboard/models/InventoryDashboardFactor"
import { InventoryResult, WareHouseResult } from "../../../services/dashboard/models/InventoryDashboardResult"
import { TnmContract } from "../../../services/master/models/TnmContract"
import { CbdsType } from "../../../services/systemmaster/enums/CbdsType"
import { useGetCompanyType } from "../../../utils/ApplicationUtils"
import { DAS014PcUi } from "./DAS014PcUi"

export const DAS014 = () => {

    const [filters, setFilters] = useState<InventoryFactor>({ year: (new Date()).getFullYear(), month: (new Date()).getMonth() + 1 })
    const [dc, setDc] = useState<WareHouseResult[]>([])
    const [currency, setCurrency] = useState<TnmContract[]>([])
    const [inventoryData, setInventoryData] = useState<InventoryResult>({} as InventoryResult)
    const companyType = useGetCompanyType()

    const getDCList = useGetDCList()
    const getCurrency = useGetCurencyList()
    const getInventoryData = useGetInventoryData()

    const search = useCallback((filters: Filters) => {
        getInventoryData(filters, { silent: true, serialized: true }).then(result => {
            setInventoryData(result ?? {} as any)
        })
    }, [getInventoryData, setInventoryData])

    useEffect(() => {
        if (companyType !== CbdsType.CUST) {
            getDCList(undefined, { silent: true }).then(result => {
                setDc(result || [])
            })
        }
        getCurrency(undefined, { silent: true }).then(result => {
            setCurrency(result || [])
        })
    }, [companyType, getCurrency, getDCList])

    // useSearchOnLoad(search, filters)

    return <DAS014PcUi
        filters={filters}
        setFilters={setFilters}
        search={search}
        inventoryData={inventoryData}
        dc={dc}
        currency={currency}
    />
}