import { AxiosInstance, AxiosResponse } from "axios"
import { UpdateUserSettingAPIRequest } from "../models/UpdateUserSettingAPIRequest"
import { UserSettingAPIResponse } from "../models/UserSettingAPIResponse"

const userSettingApi = {
    get: function(axiosInstance: AxiosInstance): Promise<
        AxiosResponse<UserSettingAPIResponse>
    > {
        return axiosInstance.get('/common-system-api/user/setting')
    },
    update: function(axiosInstance: AxiosInstance, data: UpdateUserSettingAPIRequest): Promise<
        AxiosResponse<UserSettingAPIResponse>
    > {
        return axiosInstance.put('/common-system-api/user/setting', data)
    }
}

export default userSettingApi