import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { DateRangeItem, EntriesItem, NumberItem, StringItem } from "@rithe/form"
import { Objects, Records } from "@rithe/utils"
import React, { useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { EditRedirectRowAction } from "../../../components/DataGrid/rowActions/EditRedirectRowAction"
import { ViewRedirectRowAction } from "../../../components/DataGrid/rowActions/ViewRedirectRowAction"
import { CreateRedirectToolbarAction } from "../../../components/DataGrid/toolbarActions/CreateRedirectToolbarAction"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { ActiveFlagTypeProvider } from "../../../components/DataGrid/typeProviders/ActiveFlagTypeProvider"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { useDownloadShippingRouteDetailMasterByShippingRouteId, useDownloadShippingRouteMasterByFilter, useDownloadShippingRouteMasterByShippingRouteId } from "../../../services/master/apis/masterDownloadApi"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { ShippingRouteFactor } from "../../../services/master/models/ShippingRouteFactor"
import { ShippingRouteResult } from "../../../services/master/models/ShippingRouteResult"
import { TnmPort } from "../../../services/master/models/TnmPort"
import { TnmRegion } from "../../../services/master/models/TnmRegion"
import { useGetCompanyUid } from "../../../utils/ApplicationUtils"
import { applicationActions } from "../../Application/applicationSlice"

interface MLS120PcUiProps {
    filters: ShippingRouteFactor,
    search: (filters: ShippingRouteFactor) => void,
    data: ShippingRouteResult[],
    totalCount: number,
    regionList: TnmRegion[]
    portList: TnmPort[],
    setFilters: React.Dispatch<React.SetStateAction<Partial<ShippingRouteFactor>>>
}

export const MLS120PcUi = (props: MLS120PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({ filters, data, regionList, portList, setFilters, search }: MLS120PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])

    const columns = useMemo(() => [
        { field: 'shippingRouteCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.shippingRouteCode' }), width: 250 },
        { field: 'displayShippingRoute', dataTypeName: 'string', title: intl.formatMessage({ id: 'displayShippingRoute' }), width: 250 },
        { field: 'shippingMode', dataTypeName: CodeCategory.ShippingMode, title: intl.formatMessage({ id: 'field.shippingMode' }), width: 200 },
        { field: 'shipperCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'shipper' }), width: 150 },
        { field: 'receiverCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'receiver' }), width: 150 },
        { field: 'originRegionCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'originCountry' }), width: 200 },
        { field: 'originStateCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'originState' }), width: 200 },
        { field: 'fromPortCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'originPort' }), width: 200 },
        { field: 'destinationRegionCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'destinationCountry' }), width: 250 },
        { field: 'destinationStateCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'destinationState' }), width: 200 },
        { field: 'toPortCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'destinationPort' }), width: 200 },
        { field: 'expCcLeadtime', dataTypeName: 'number', title: intl.formatMessage({ id: 'originCustomsLeadtime' }), width: 200 },
        { field: 'impCcLeadtime', dataTypeName: 'number', title: intl.formatMessage({ id: 'destinationCustomLeadtime' }), width: 200 },
        { field: 'activeFlag', dataTypeName: CodeCategory.ActiveFlag, title: intl.formatMessage({ id: 'field.activeFlag' }), width: 150 },
        { field: 'createdDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.createdDate' }), width: 200 },
        { field: 'updatedDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.updatedDate' }), width: 200 },
    ], [intl])
    const getRowId = useCallback((row: any) => row.srId, [])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search, regionList, portList }), [filters, setFilters, search, regionList, portList])
    const actionProps = useMemo(() => ({ selections, filters }), [filters, selections])

    const companyUid = useGetCompanyUid()
    const displayEdit = useCallback((tableRow: TableRow) => tableRow.row?.createUid === companyUid, [companyUid])
    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.InboundType} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.ShippingMode} />
        <ActiveFlagTypeProvider />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={CreateAction} />
        <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps} />
        <RowActionProvider name="view" Action={ViewRowAction} />
        <RowActionProvider name="edit" Action={EditRowAction} display={displayEdit} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['expCcLeadtime', 'impCcLeadtime', 'createdDate', 'updatedDate', 'originStateCode', 'fromPortCode', 'destinationStateCode', 'toPortCode']}
            columnSettings={{
                shippingRouteCode: { disableUserControl: true },
                shippingMode: { disableUserControl: true },
                shipperCode: { disableUserControl: true },
                receiverCode: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={88} />
    </DataGrid>
}


const FilterItem = (props: {
    search: (filters: ShippingRouteFactor) => void,
    filters: ShippingRouteFactor,
    setFilters: React.Dispatch<React.SetStateAction<Partial<ShippingRouteFactor>>>,
    regionList: TnmRegion[],
    portList: TnmPort[],
}) => {
    const { filters, regionList, portList, setFilters, search } = props
    const regionListEntries: [string, string][] = useMemo(() => regionList.map(m => [m.regionCode, m.regionCode]), [regionList])
    const portListEntries: [number, string][] = useMemo(() => portList.map(m => [m.portId, m.portCode]), [portList])

    const createdDateGetValue = useCallback((filters: ShippingRouteFactor) => {
        return [filters.createdDateStart ?? null, filters.createdDateEnd ?? null]
    }, [])

    const createdDateMapValue = useCallback((filters: ShippingRouteFactor, value: any) => {
        return { ...filters ?? {}, createdDateStart: value[0], createdDateEnd: value[1] }
    }, [])

    const updatedDateGetValue = useCallback((filters: ShippingRouteFactor) => {
        return [filters.updatedDateStart ?? null, filters.updatedDateEnd ?? null]
    }, [])

    const updatedDateMapValue = useCallback((filters: ShippingRouteFactor, value: any) => {
        return { ...filters ?? {}, updatedDateStart: value[0], updatedDateEnd: value[1] }
    }, [])

    const clear = useCallback((filters: ShippingRouteFactor) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: ShippingRouteFactor) => {
        return [
            filters.shippingRouteCode,
            filters.shippingModeList,
            filters.expCountryList,
            filters.expPortList,
            filters.impCountryList,
            filters.impPortList,

            filters.impCcLeadtime,
            filters.expCcLeadtime,
            filters.discontinueIndicatorList,
            filters.createdBy,
            filters.updatedBy,
            filters.effectiveStartDateStart,
            filters.effectiveEndDateStart,
            filters.createdDateStart || filters.createdDateEnd,
            filters.updatedDateStart || filters.updatedDateEnd,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="shippingRouteCode" label={intl.formatMessage({ id: 'field.shippingRouteCode' })} />
        <CodesItem field="shippingModeList" label={intl.formatMessage({ id: 'field.shippingMode' })} code={CodeCategory.ShippingMode} />
        <EntriesItem field="expCountryList" label={intl.formatMessage({ id: 'expCountryCode' })} entries={regionListEntries} />
        <EntriesItem field="expPortList" label={intl.formatMessage({ id: 'expPortCode' })} entries={portListEntries} />
        <EntriesItem field="impCountryList" label={intl.formatMessage({ id: 'impCountryCode' })} entries={regionListEntries} />
        <EntriesItem field="impPortList" label={intl.formatMessage({ id: 'impPortCode' })} entries={portListEntries} />
        <NumberItem field="impCcLeadtime" label={intl.formatMessage({ id: 'field.impCcLeadtime' })} />
        <NumberItem field="expCcLeadtime" label={intl.formatMessage({ id: 'field.expCcLeadtime' })} />
        <CodesItem field="discontinueIndicatorList" label={intl.formatMessage({ id: 'field.activeFlag' })} code={CodeCategory.ActiveFlag} />
        <StringItem field="createdBy" label={intl.formatMessage({ id: 'field.createdBy' })} />
        <StringItem field="updatedBy" label={intl.formatMessage({ id: 'field.updatedBy' })} />
        <DateRangeItem field="createdDate" label={intl.formatMessage({ id: 'field.createdDate' })} getValue={createdDateGetValue} mapValue={createdDateMapValue} />
        <DateRangeItem field="updatedDate" label={intl.formatMessage({ id: 'field.updatedDate' })} getValue={updatedDateGetValue} mapValue={updatedDateMapValue} />
    </FilterToolbarItem>
}


const CreateAction = () => {
    return <CreateRedirectToolbarAction access="MARS.MLS120.CREATE" title={<FormattedMessage id="createNewShippingRoute" />} path="/shippingroute/create" />
}

const DownloadAction = ({ selections, filters }: { selections: number[], filters: ShippingRouteFactor }) => {

    const dispatch = useDispatch()

    const downloadMasterBySelectionApi = useDownloadShippingRouteMasterByShippingRouteId()
    const downloadMasterByFilterApi = useDownloadShippingRouteMasterByFilter()
    const downloadShippingDetail = useDownloadShippingRouteDetailMasterByShippingRouteId()
    const downloadMaster = useCallback(() => {
        selections.length <= 0 ? downloadMasterByFilterApi(Objects.delete(filters, 'page')) : downloadMasterBySelectionApi({ shippingRouteIdList: selections })
    }, [downloadMasterByFilterApi, downloadMasterBySelectionApi, filters, selections])

    const downloadShipping = useCallback(() => {
        if (selections.length <= 0) {
            dispatch(applicationActions.pushError({ title: { code: 'downloadShippingDetail' }, messages: { code: 'w0002' } }))
        } else {
            downloadShippingDetail({ shippingRouteIdList: selections })
        }
    }, [dispatch, downloadShippingDetail, selections])

    return <DownloadGroupedToolbarAction access="MARS.MLS120.DOWNLOAD">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="downloadShippingMaster" />} callback={downloadMaster} onClose={onClose} />
            <GroupedCallbackItem label={<FormattedMessage id="downloadShippingDetail" />} callback={downloadShipping} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}

const ViewRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/shippingroute/view-${tableRow.rowId}`, [])
    return <ViewRedirectRowAction tableRow={tableRow} access="MARS.MLS120.VIEWDETAIL" path={path} />
}

const EditRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/shippingroute/edit-${tableRow.rowId}`, [])
    return <EditRedirectRowAction tableRow={tableRow} access="MARS.MLS120.EDIT" path={path} />
}