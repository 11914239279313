import { Arrays, Objects } from "@rithe/utils"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { useGetFcReviseDetailByCustomer, useGetOrderReviseDetailByCustomer } from "../../../services/order/apis/ChangeRequestApi"
import { ChangeOrderDetailResult, PartsDetail } from "../../../services/order/models/ChangeOrderDetailResult"
import { formatDateRange } from "../../../utils/formatDateRange"
import { useMatch } from "../../../utils/useMatch"
import { ONS031FcPcUi } from "./ONS031FcPcUi"
import { ONS031OrderPcUi } from "./ONS031OrderPcUi"

export interface ONS031Factor {
    coId: number,
    roId: number,
}

export interface ChangeOrderBasic extends ChangeOrderDetailResult {
    totalQty: number,
    totalAmount: number,
    currency?: string,
    totalNumberOfParts: number,
    deliveryPlanRange: string,
}

export const ONS031 = () => {
    const { coId, roId } = useParams() as any
    const { path } = useMatch()
    const isOrderChange = useMemo(() => path.startsWith('/cro-order'), [path])
    const mode = (path === '/cro-order/view-:roId' || path === '/cro-forecast/view-:roId') ? ScreenMode.VIEW : ScreenMode.EDIT

    const factor: ONS031Factor = useMemo(() => {
        return { coId: coId, roId: roId }
    }, [coId, roId])

    const getOrderReviseDetailByCustomer = useGetOrderReviseDetailByCustomer()
    const getFcReviseDetailByCustomer = useGetFcReviseDetailByCustomer()

    const [basic, setBasic] = useState<ChangeOrderBasic>({} as ChangeOrderBasic)
    const [partsDetails, setPartsDetails] = useState<PartsDetail[]>([] as PartsDetail[])
    const splitDatas = useSplitDatas()
    useEffect(() => {
        if (isOrderChange) {
            getOrderReviseDetailByCustomer(factor, { silent: true, serialized: true }).then(result => {
                if (result) {
                    const { orderbasic, partsDetails } = splitDatas(result)
                    setBasic(orderbasic)
                    setPartsDetails(partsDetails)
                }
            })
        } else {
            getFcReviseDetailByCustomer(factor, { silent: true, serialized: true }).then(result => {
                if (result) {
                    const { orderbasic, partsDetails } = splitDatas(result)
                    setBasic(orderbasic)
                    setPartsDetails(partsDetails)
                }
            })
        }
    }, [factor, getFcReviseDetailByCustomer, getOrderReviseDetailByCustomer, isOrderChange, splitDatas])

    return isOrderChange ? <ONS031OrderPcUi
        factor={factor}
        basic={basic}
        setBasic={setBasic}
        partsDetails={partsDetails}
        setPartsDetails={setPartsDetails} mode={mode} /> : <ONS031FcPcUi
        factor={factor}
        basic={basic}
        setBasic={setBasic}
        partsDetails={partsDetails}
        setPartsDetails={setPartsDetails}
    />
}

export const useSplitDatas = () => {
    const intl = useIntl()
    return useCallback((data: ChangeOrderDetailResult) => {
        const { partsDetailList } = data
        const totalNumberOfParts = partsDetailList.length
        const currency = partsDetailList.length > 0 ? partsDetailList[0].buyingCurrency : ''
        const planDates = Arrays.distinct(partsDetailList.flatMap(parts => parts.inbPlanList).map(plan => plan.crdDate.getTime())).sort().map(m => new Date(m))
        const deliveryPlanRange = planDates.length > 0 ? formatDateRange(intl, planDates[0], planDates[planDates.length - 1]) : ''
        const orderbasic: ChangeOrderBasic = { ...data, totalNumberOfParts, deliveryPlanRange, currency }

        return { orderbasic: orderbasic, partsDetails: partsDetailList }
    }, [intl])
}

export const useMergeDatas = () => {
    return useCallback((orderbasic: ChangeOrderBasic, partsDetails: PartsDetail[]) => {
        return { ...Objects.delete(orderbasic, 'totalNumberOfParts', 'deliveryPlanRange', 'currency'), partsDetailList: partsDetails } as ChangeOrderDetailResult
    }, [])
}

