import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { InvoiceStatus } from "../enums/InvoiceStatus"
import { ShippingMode } from "../enums/ShippingMode"

export type BuyerInvoiceTransfer = Required & Partial<Optional>

type BuyerInvoiceTransferJson = JsonRequired & Partial<JsonOptional>

export const buyerInvoiceTransferFromJson: Project<BuyerInvoiceTransferJson, BuyerInvoiceTransfer> = json => ({
    ...json,
    issuedDate: dateFromJson(json.issuedDate),
    approvedDate: dateFromJson(json.approvedDate),
    releasedDate: dateFromJson(json.releasedDate),
    outboundDate: dateFromJson(json.outboundDate)
})

interface Required extends JsonRequired { }

interface Optional extends Omit<JsonOptional, 'issuedDate' | 'approvedDate' | 'invoices' | 'releasedDate' | 'outboundDate'> {
    status: InvoiceStatus,
    shippingMode: ShippingMode,
    issuedDate: Date,
    approvedDate: Date,
    releasedDate: Date,
    outboundDate: Date
}

interface JsonRequired {
    invoiceId: number,
    invoiceNo: string,
}

interface JsonOptional {
    externalInvoiceNo: string,
    contractNo: string,
    inboundNo: string,
    outboundNo: string,
    shippingMode: number,
    shippingModes: number[],
    loadingPort: string,
    dischargePort: string,
    issuedDate: string,
    approvedBy: string,
    approvedDate: string,
    releasedBy: string,
    releasedDate: string,
    status: number,
    version: number,
    soNo: string,
    orderType: number,
    buyer: string,
    seller: string,
    outboundDate: string
}