import { Badge, Button, makeStyles, Tooltip, useTheme } from "@material-ui/core"
import { Tune } from "@material-ui/icons"
import { Form } from "@rithe/form"
import { useMixed, usePopover } from "@rithe/utils"
import React, { MouseEvent, ReactNode, useCallback } from "react"
import { FormattedMessage } from "react-intl"
import { Access } from "../../Access/Access"
import { IconButton } from "../../Button/IconButton"
import { DottedDivider } from "../../Divider/DottedDivider"
import { PopoverMenu } from "../../PopoverMenu"

interface FilterToolbarItemProps<T> {
    access?: string,
    filters?: T,
    defaultFilters?: T,
    onFiltersChange?: React.Dispatch<React.SetStateAction<T>>,
    onOpen?: () => void,
    onClose?: () => void,
    onSubmit?: (filter: T) => void,
    clear?: (filter: T) => T,
    filterCounter?: (filter: T) => number,
    columnCount?: number,
    children?: ReactNode | ReactNode[],
}

export function FilterToolbarItem<T>(props: FilterToolbarItemProps<T>) {
    const { access, onSubmit, clear, filterCounter, columnCount, children } = props
    const popover = usePopover()

    const [filters, setFilters] = useMixed(props.filters, props.onFiltersChange, props.defaultFilters, {} as T)
    const filterCount = filterCounter && filterCounter(filters)

    const onOpen = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        props.onOpen && props.onOpen()
        popover.onOpen(e)
    }, [popover, props])

    const onClose = useCallback(() => {
        props.onClose && props.onClose()
        popover.onClose()
    }, [popover, props])

    const theme = useTheme()
    const styles = useStyles()
    const action = <>
        <Tooltip title={<FormattedMessage id="remoteFilter" />}>
            <Badge badgeContent={filterCount} classes={{ badge: styles.badge }}>
                <IconButton onClick={onOpen} className={styles.root}><Tune /></IconButton>
            </Badge>
        </Tooltip>
        <PopoverMenu
            open={popover.open}
            anchorEl={popover.anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            onClose={onClose}
        >
            {popover.open && <div className={styles.container}>
                <div className={styles.form}>
                    <Form data={filters} setData={setFilters} columnCount={columnCount} labelDisplay='inset' labelWidth={130} labelAlign="end" minWidth={1100} maxWidth={1100} rowGap={theme.spacing(2)} columnGap={theme.spacing(2)}>
                        {children}
                    </Form>
                </div>
                <DottedDivider light />
                <div className={styles.control}>
                    <Button variant="outlined" color="secondary" onClick={() => setFilters(clear ? clear(filters) : {} as T)}>Clear</Button>
                    <Button variant="contained" color="secondary" onClick={() => {
                        onSubmit && onSubmit(filters)
                        onClose()
                    }}>Search</Button>
                </div>
            </div>}
        </PopoverMenu>
    </>
    return access ? <Access access={access}>{action}</Access> : action
}

const useStyles = makeStyles(theme => ({
    root: {
        verticalAlign: 'bottom',
        backgroundColor: theme.palette.info.main,
        color: theme.palette.info.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.info.light,
        },
    },
    badge: {
        zoom: 0.8,
        backgroundColor: theme.palette.success.main,
        color: theme.palette.success.contrastText,
    },
    container: {
        padding: theme.spacing(3.5),
    },
    form: {
        paddingBottom: theme.spacing(2),
    },
    control: {
        paddingTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'flex-end',
        '&>*': {
            marginLeft: theme.spacing(1),
        },
    },

}))