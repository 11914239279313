import { Tooltip } from "@material-ui/core"
import { Pageview } from "@material-ui/icons"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { FormattedMessage } from "react-intl"
import { useNavigate } from "react-router-dom"
import { Access } from "../../../components/Access/Access"
import { IconButton } from "../../../components/Button/IconButton"
import { OrderCalcStatus } from "../../../services/order/enums/OrderCalcStatus"
import { DetailTableRow } from "./DetailTable"
import { OrderCalculationType } from "./OCCLS010Slice"

export interface DetailViewRowActionProps extends DataGridRowActionProps {
  type: OrderCalculationType
}

export function DetailViewRowAction(props: DetailViewRowActionProps) {
  const { tableRow, type } = props
  const row = tableRow.row as DetailTableRow

  const navigate = useNavigate()
  const display = row.status !== OrderCalcStatus.PENDING_CALCULATION
  if (!display) {
    return null
  } else {
    return <Access access="STCK.OCCLS010.VIEWDETAIL">
      <Tooltip title={<FormattedMessage id="view" />}>
        <IconButton onClick={() => {
          if (row.orderVersion === 1) {
            type === 'PNA'
              ? navigate(`/orderCalculationPNA/viewRegular-${row.orderCalcId}`)
              : type === 'Ehm'
                ? navigate(`/orderCalculationEhm/viewRegular-${row.orderCalcId}`)
                : navigate(`/orderCalculation/viewRegular-${row.orderCalcId}`)
          } else {
            type === 'PNA'
              ? navigate(`/orderCalculationPNA/placeOrderSpotView-${row.poGroupId}`)
              : type === 'Ehm'
                ? navigate(`/orderCalculationEhm/placeOrderSpotView-${row.poGroupId}`)
                : navigate(`/orderCalculation/placeOrderSpotView-${row.poGroupId}`)
          }
        }}>
          <Pageview />
        </IconButton>
      </Tooltip>
    </Access>
  }
}