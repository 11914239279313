import { Project } from "@rithe/utils"
import { dateFromJson } from "../../utils/deserializer"
import { dateToJson } from "../../utils/serializer"

export interface OrderCalculationDetailResult extends Omit<OrderCalculationDetailResultJson, 'orderStartDate' | 'orderEndDate' | 'targetStartDate' | 'targetEndDate' | 'cutoffDate' | 'custUsageDate' | 'previousUsageDate' | 'partsDetailList'> {
    orderStartDate: Date,
    orderEndDate: Date,
    targetStartDate: Date,
    targetEndDate: Date,
    cutoffDate: Date,
    custUsageDate: Date,
    previousUsageDate?: Date,
    partsDetailList: OrderCalculationPartsDetailEntity[]
}

export interface OrderCalculationDetailResultJson {
    orderCalcId: string,
    orderCalcNo: string,
    orderGroupNo: string,
    orderVersion?: number,
    customerCode?: string,
    buyerCode?: string,
    sellerCode?: string,
    orderFrequency: number,
    orderStartDate: string,
    orderEndDate: string,
    targetStartDate: string,
    targetEndDate: string,
    cutoffDate: string,
    targetLeadtime: number,
    forecastCount: number,
    usageVersion: string,
    custUsageDate: string,
    previousUsageDate?: string,
    status: number,
    remark?: string,
    partsDetailList: OrderCalculationPartsDetailEntityJson[]
}

export const orderCalculationDetailResultFromJson: Project<OrderCalculationDetailResultJson, OrderCalculationDetailResult> = json => ({
    ...json,
    orderStartDate: dateFromJson(json.orderStartDate),
    orderEndDate: dateFromJson(json.orderEndDate),
    targetStartDate: dateFromJson(json.targetStartDate),
    targetEndDate: dateFromJson(json.targetEndDate),
    cutoffDate: dateFromJson(json.cutoffDate),
    custUsageDate: dateFromJson(json.custUsageDate),
    previousUsageDate: dateFromJson(json.previousUsageDate),
    partsDetailList: json.partsDetailList.map(orderCalculationPartsDetailEntityFromJson)
})
export const orderCalculationDetailResultToJson: Project<OrderCalculationDetailResult, OrderCalculationDetailResultJson> = obj => ({
    ...obj,
    orderStartDate: dateToJson(obj.orderStartDate),
    orderEndDate: dateToJson(obj.orderEndDate),
    targetStartDate: dateToJson(obj.targetStartDate),
    targetEndDate: dateToJson(obj.targetEndDate),
    cutoffDate: dateToJson(obj.cutoffDate),
    custUsageDate: dateToJson(obj.custUsageDate),
    previousUsageDate: dateToJson(obj.previousUsageDate),
    partsDetailList: obj.partsDetailList.map(orderCalculationPartsDetailEntityToJson)
})

export interface OrderCalculationPartsDetailEntity extends Omit<OrderCalculationPartsDetailEntityJson, 'periodInfoList'> {
    periodInfoList: OrderCalculationPeriodDetailEntity[]
}

export interface OrderCalculationPartsDetailEntityJson {
    partsNo: string,
    customerPartsId: string,
    orderCalcPartsId: string,
    externalRefNo?: string,
    customerPartsNo: string,
    partsDescription?: string,
    expCountry?: string,
    supplier?: string,
    pairedPartsNo?: string,
    model?: string,
    spq?: number,
    orderLot?: number,
    outboundFluctuation?: number,
    safetyStockUom?: number,
    maxStock?: number,
    safetyStock?: number,
    minStock?: number,
    warehouseStock?: number,
    customerStock?: number,
    wipQty?: number,
    decimalDigits?: number,
    calculationPattern?: number,
    periodInfoList: OrderCalculationPeriodDetailEntityJson[]
}

export const orderCalculationPartsDetailEntityFromJson: Project<OrderCalculationPartsDetailEntityJson, OrderCalculationPartsDetailEntity> = json => ({
    ...json,
    periodInfoList: json.periodInfoList.map(orderCalculationPeriodDetailEntityFromJson)
})
export const orderCalculationPartsDetailEntityToJson: Project<OrderCalculationPartsDetailEntity, OrderCalculationPartsDetailEntityJson> = obj => ({
    ...obj,
    periodInfoList: obj.periodInfoList.map(orderCalculationPeriodDetailEntityToJson)
})



export interface OrderCalculationPeriodDetailEntity extends Omit<OrderCalculationPeriodDetailEntityJson, 'orderStartDate' | 'orderEndDate' | 'targetStartDate' | 'targetEndDate'> {
    orderStartDate: Date,
    orderEndDate: Date,
    targetStartDate: Date,
    targetEndDate: Date,
}

interface OrderCalculationPeriodDetailEntityJson {
    periodIndex: number,
    orderCalcPeriod: string,
    orderStartDate: string,
    orderEndDate: string,
    targetStartDate: string,
    targetEndDate: string,
    nextWorkingDay: number,
    currentUsageQty?: number,
    previousUsageQty?: number,
    currentUsageBalance?: number,
    currentOrderQty?: number,
    previousOrderBalance?: number,
    currentOrderBalance?: number,
    suggestedQty?: number,
    finalQty?: number,
    lastFcQty?: number,
    adjustmentReason?: string,
    usageFlag?: number,
    adjustmentSafetyStock?: number,
}

const orderCalculationPeriodDetailEntityFromJson: Project<OrderCalculationPeriodDetailEntityJson, OrderCalculationPeriodDetailEntity> = json => ({
    ...json,
    orderStartDate: dateFromJson(json.orderStartDate),
    orderEndDate: dateFromJson(json.orderEndDate),
    targetStartDate: dateFromJson(json.targetStartDate),
    targetEndDate: dateFromJson(json.targetEndDate),
})

const orderCalculationPeriodDetailEntityToJson: Project<OrderCalculationPeriodDetailEntity, OrderCalculationPeriodDetailEntityJson> = obj => ({
    ...obj,
    orderStartDate: dateToJson(obj.orderStartDate),
    orderEndDate: dateToJson(obj.orderEndDate),
    targetStartDate: dateToJson(obj.targetStartDate),
    targetEndDate: dateToJson(obj.targetEndDate),
})