import { DialogActions, DialogContent, Tooltip, makeStyles } from "@material-ui/core"
import EventNoteIcon from '@material-ui/icons/EventNote'
import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { Break, CheckItem, DateItem, EntriesItem, Form, StringItem } from "@rithe/form"
import { Records } from "@rithe/utils"
import React, { useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { NeumorphismButton } from "../../../components/Button/NeumorphismButton"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { DarkDialog } from "../../../components/Dialog/DarkDialog"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { View } from "../../../components/View/View"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { useDownloadRundownContainer, useDownloadRundownInventory } from "../../../services/smt/api/smtDownloadApi"
import { DailyRundownList } from "../../../services/smt/models/DailyRundownList"
import { DailyRundownListFactor } from "../../../services/smt/models/DailyRundownListFactor"
import { InputDownloadDataRangeFactor } from "../../../services/smt/models/InputDownloadDataRangeFactor"
import { Daily, dailyToJson } from "../../../services/smt/stockManagement/models/DailyRundownResult"
import { Pair } from "../../../services/utils/Pair"
import { today, useGetCompanyType } from "../../../utils/ApplicationUtils"
import sessionKeys from "../../../utils/sessionKeys"
import { useSetDataIntoSessionAndGotoUrl } from "../../../utils/sessionUtil"
import { applicationActions } from "../../Application/applicationSlice"

interface SMGTS210PcUiProps {
    filters: DailyRundownListFactor,
    setFilters: React.Dispatch<React.SetStateAction<DailyRundownListFactor>>,
    data: DailyRundownList[],
    customerList: Pair[],
    supplierList: TnvCbds[],
    totalCount: number,
    search: (filters: DailyRundownListFactor) => void,
}

export const SMGTS210PcUi = (props: SMGTS210PcUiProps) => {
    const intl = useIntl()
    return <View flex>
        <SectionCard>
            <SectionCardHeader
                title={''}
                subtitle={intl.formatMessage({ id: 'dailySubTitle' })}
            />
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({ filters, data, setFilters, search, customerList, supplierList }: SMGTS210PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])
    const cbdsType = useGetCompanyType()
    const columns = useMemo(() => cbdsType === CbdsType.BU ? [
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 180 },
        { field: 'partsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsName' }), width: 300 },
        { field: 'externalRefNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.externalRefNo' }), width: 300 },
        { field: 'customerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerCode' }), width: 200 },
        { field: 'expCountry', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.expCountry' }), width: 300 },
        { field: 'supplierCodes', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierCodes' }), width: 300 },
    ] : [
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 180 },
        { field: 'partsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsName' }), width: 300 },
        { field: 'externalRefNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.externalRefNo' }), width: 300 },
        { field: 'sellerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sellerCode' }), width: 200 },
        { field: 'expCountry', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.expCountry' }), width: 300 },
        { field: 'supplierCodes', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierCodes' }), width: 300 },
    ], [cbdsType, intl])
    const getRowId = useCallback((row: any) => row.customerPartsId, [])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search, customerList, supplierList }), [filters, setFilters, search, customerList, supplierList])
    const downloadProps = useMemo(() => ({ selections }), [selections])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.DataSource} />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={DailyRundownAction} actionProps={{ selections, data }} />
        <ToolbarActionProvider Action={DownloadAction} actionProps={downloadProps} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={[]}
            columnSettings={{
                partsNo: { disableUserControl: true },
            }}
            ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
    </DataGrid>
}

const FilterItem = (props: {
    supplierList: TnvCbds[],
    customerList: Pair[],
    filters: DailyRundownListFactor,
    setFilters: React.Dispatch<React.SetStateAction<DailyRundownListFactor>>,
    search: (filters: DailyRundownListFactor) => void
}) => {

    const { filters, setFilters, search, customerList, supplierList } = props
    const customersMap = useMemo(() => customerList ? customerList.map(m => [m.second, m.second] as [string, string]) : [], [customerList])
    const supplierMap = useMemo(() => supplierList ? supplierList.map(m => [m.cbdsCode, m.cbdsCode] as [string, string]) : [], [supplierList])


    const clear = useCallback((filters: DailyRundownListFactor) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: DailyRundownListFactor) => {
        return [
            filters.partsNo,
            filters.partsName,
            filters.customerCodes,
            filters.supplierCodes,
            filters.expCountries,
            filters.externalRefNo,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field='partsNo' label={intl.formatMessage({ id: 'field.partsNo' })} />
        <StringItem field='partsName' label={intl.formatMessage({ id: 'field.partsName' })} />
        <EntriesItem field="customerCodes" label={intl.formatMessage({ id: 'field.customerCodes' })} entries={customersMap} />
        <EntriesItem field="supplierCodes" label={intl.formatMessage({ id: 'field.supplierCodes' })} entries={supplierMap} />
        <StringItem field='expCountries' label={intl.formatMessage({ id: 'field.expCountry' })} />
        <StringItem field='externalRefNo' label={intl.formatMessage({ id: 'field.externalRefNo' })} />
    </FilterToolbarItem>
}


const DownloadAction = ({ selections }: { selections: number[] }) => {
    const [open, setOpen] = useState<boolean>(false)
    const [type, setType] = useState<number>(1)
    const [factor, setFactor] = useState<InputDownloadDataRangeFactor>({
        stockDate: today(),
        includePlanFlag: false,
        simulateByParts: false,
        baseCarrierDate: true,
    })
    const callbackForDaily = useCallback(() => {
        setType(1)
        setOpen(true)
    }, [])
    const callbackForContainer = useCallback(() => {
        setType(2)
        setOpen(true)
    }, [])

    const intl = useIntl()
    const downloadDailyApi = useDownloadRundownInventory()
    const downloadContainerApi = useDownloadRundownContainer()
    const download = useCallback(() => {
        if (type === 1) {
            downloadDailyApi({ ...factor, customerPartsIdList: selections })
        } else {
            downloadContainerApi({ ...factor, customerPartsIdList: selections })
        }
        setOpen(false)
    }, [downloadContainerApi, downloadDailyApi, factor, selections, type])
    return <>
        <DownloadGroupedToolbarAction access="STCK.SMGTS210.DOWNLOAD">
            {onClose => <>
                <GroupedCallbackItem label={<FormattedMessage id="downloadDailyRundown" />} callback={callbackForDaily} onClose={onClose} />
                <GroupedCallbackItem label={<FormattedMessage id="downloadContainerRundown" />} callback={callbackForContainer} onClose={onClose} />
            </>}
        </DownloadGroupedToolbarAction>
        <DarkDialog open={open} style={{ height: '700px', overflow: 'hidden' }} maxWidth="md" fullWidth>
            <DialogHeader onClose={() => setOpen(false)}>
                <FormattedMessage id="InputDownloadDateRange" />
            </DialogHeader>
            <DialogContent>
                <Form data={factor} setData={setFactor} labelDisplay="block" helperDisplay="tooltip" columnCount={1}>
                    <DateItem field="stockDate" label={intl.formatMessage({ id: 'RundownStartDate' })} />
                    <Break />
                    <CheckItem field="includePlanFlag" label={intl.formatMessage({ id: 'includeNoInvoiced' })} />
                    <Break />
                    <CheckItem field="simulateByParts" label={intl.formatMessage({ id: 'stockQtyByPart' })} />
                    <Break />
                    <CheckItem field="baseCarrierDate" label={intl.formatMessage({ id: 'stockQtyBaseCarrierDate' })} />
                </Form>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={() => setOpen(false)} />
                <DialogAction title={<FormattedMessage id="download" />} callback={download} />
            </DialogActions>
        </DarkDialog>
    </>
}


const DailyRundownAction = (({ selections, data }: { selections: number[], data: DailyRundownList[] }) => {
    const dispatch = useDispatch()
    const setSesion = useSetDataIntoSessionAndGotoUrl()
    const styles = useStyles();

    const dataList: DailyRundownList[] = data.filter(f => f.customerPartsId && selections.indexOf(f.customerPartsId) >= 0)
    const partsList: Daily[] = useMemo(() => {
        return dataList.map(v => {
            return {
                partsNo: v.partsNo,
                customerPartsId: v.customerPartsId
            }
        })
    }, [dataList])

    const callback = useCallback(() => {
        if (selections.length > 0) {
            setSesion(sessionKeys.Data_SMGTS211, dailyToJson, partsList, '/dailyRundownList/dailyRundown')
        } else {
            dispatch(applicationActions.pushWarning({ title: { code: 'dailyRundown' }, messages: { code: 'w0002' } }))
        }
    }, [dispatch, partsList, selections.length, setSesion])
    // return <EditCallbackToolbarAction access="STCK.SMGTS211.VIEW" title={<FormattedMessage id="dailyRundown" />} callback={callback} />
    return <>
        <Tooltip title={<FormattedMessage id="dailyRundown" />}>
            <NeumorphismButton onClick={callback} className={styles.root}><EventNoteIcon /></NeumorphismButton>
        </Tooltip>
    </>
})


const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1),
        width: 36,
    }
}))
