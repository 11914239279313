import { Clear } from "@material-ui/icons"
import { TableRow } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import React from "react"
import { FormattedMessage } from "react-intl"
import { CallbackRowAction } from "./CallbackRowAction"

interface RejectCallbackRowActionProps {
    access?: string,
    power?: boolean,
    title?: NonNullable<React.ReactNode>,
    icon?: NonNullable<React.ReactNode>,
    callback: (tableRow: TableRow) => void,
    disabled?: boolean,
}

export const RejectCallbackRowAction = (props: RejectCallbackRowActionProps & DataGridRowActionProps) => {
    const defaultProps = {
        title: <FormattedMessage id="reject" />,
        icon: <Clear />
    }
    return <CallbackRowAction {...defaultProps} {...props} />
}


export const CancelOneDetail029backRowAction = (props: RejectCallbackRowActionProps & DataGridRowActionProps) => {
    const defaultProps = {

        title: <FormattedMessage id="cancel" />,
        icon: <Clear />
    }
    return <CallbackRowAction {...defaultProps} {...props} />
}