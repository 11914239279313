import { IconButton, makeStyles, Typography } from "@material-ui/core"
import { Clear } from "@material-ui/icons"
import clsx from 'clsx'
import React from "react"
import { useTabContext } from "../View/Tab/TabContext"

interface CardTabProps {
    value: any,
    title: React.ReactNode,
    subtitle?: React.ReactNode,
    onClick?: (value: any) => void,
    onClose?: (value: any) => void,
}

export function CardTab(props: CardTabProps) {
    const { value, title, subtitle, onClick, onClose } = props

    const { selectedValue } = useTabContext()
    const selected = selectedValue === value

    const styles = useStyles()
    return <div onClick={onClick && (() => onClick(value))} className={clsx(styles.root, selected && styles.active)}>
        <div className={styles.content}>
            <Typography variant="subtitle1" color="textPrimary">{title}</Typography>
            {subtitle && <Typography variant="body1" color="textSecondary">{subtitle}</Typography>}
        </div>
        {onClose && <IconButton size="small" onClick={e => {
            onClose(value)
            e.stopPropagation()
        }} className={styles.close}>
            <Clear />
        </IconButton>}
    </div>
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'stretch',
        padding: theme.spacing(1, 2),
        minWidth: 160,
        borderLeft: `1px solid ${theme.palette.background.card.main}`,
        borderRight: `1px solid ${theme.palette.background.card.main}`,
        borderTopLeftRadius: theme.spacing(1),
        borderTopRightRadius: theme.spacing(1),
    },
    active: {
        backgroundColor: theme.palette.background.card.main,
        borderBottom: `1px solid ${theme.palette.secondary.main}`
    },
    content: {
        flex: '1 1 auto',
    },
    close: {
        marginLeft: theme.spacing(2),
        zoom: 0.6,
    },
}))