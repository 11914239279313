import { Project } from "../../../utils/Project"
import { BusinessType } from "../../master/enums/BusinessType"
import { dateFromJson } from "../../utils/deserializer"
import { CoStatus } from "../enums/CoStatus"
import { OrderFrequency } from "../enums/OrderFrequency"
import { OrderType } from "../enums/OrderType"

export type CustomerForecastListResult = Required & Partial<Optional>
type CustomerForecastListResultJson = JsonRequired & Partial<JsonOptional>
export const customerForecastListResultFromJson: Project<CustomerForecastListResultJson, CustomerForecastListResult> = json => ({
    ...json,
    orderDate: dateFromJson(json.orderDate),
    orderDateStart: dateFromJson(json.orderDateStart),
    orderDateEnd: dateFromJson(json.orderDateEnd)
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'orderDate' | 'orderDateStart' | 'orderDateEnd'> {
    orderType: OrderType,
    orderFrequency: OrderFrequency,
    orderDate: Date,
    status: CoStatus,
    businessType: BusinessType,
    orderDateStart: Date,
    orderDateEnd: Date,
}

interface JsonRequired {
    coId: number,
}

interface JsonOptional {
    customerForecastNo: string,
    customerRefNo: string,
    customer: string,
    orderType: number,
    orderFrequency: number,
    customerContractNo: string,
    contractRouteNo: string,
    orderDate: string,
    status: number,
    version: number,
    businessType: number,
    customerContractId: number,
    contractRouteId: number,
    orderDateStart: string,
    orderDateEnd: string,
    remark: string,
    orderVersion: number,
}
