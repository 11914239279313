import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { dateToJson } from "../../utils/serializer"
import { TableEntity, tableEntityFromJson, TableEntityJson, tableEntityToJson } from "../../utils/TableEntity"
import { ActiveFlag } from "../enums/ActiveFlag"
import { PairedFlag } from "../enums/PairedFlag"
import { PairedOrderFlag } from "../enums/PairedOrderFlag"
import { UnitType } from "../enums/UnitType"

export type UnitPartsResult = Required & Partial<Optional> & TableEntity
type UnitPartsResultJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const unitPartsResultFromJson: Project<UnitPartsResultJson, UnitPartsResult> = json => ({
    ...tableEntityFromJson(json),
    currEffectiveFrom: dateFromJson(json.currEffectiveFrom),
    effectiveFrom: dateFromJson(json.effectiveFrom),
})
export const unitPartsResultToJson: Project<UnitPartsResult, UnitPartsResultJson> = obj => ({
    ...tableEntityToJson(obj),
    currEffectiveFrom: dateToJson(obj.currEffectiveFrom),
    effectiveFrom: dateToJson(obj.effectiveFrom),
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'currEffectiveFrom' | 'effectiveFrom'> {
    unitType: UnitType,
    pairedFlag: PairedFlag,
    pairedOrderFlag: PairedOrderFlag,
    currEffectiveFrom: Date,
    effectiveFrom: Date,
    activeFlag: ActiveFlag,
}

interface JsonRequired {
    unitPartsId: number,
}

interface JsonOptional {
    newMod: string,
    unitPartsNo: string,
    partsNo: string,
    unitType: number,
    cbdsCode: string,
    uomCode: string,
    unitPartsName: string,
    partsRefNo: string,
    backNo: string,
    hscode: string,
    pairedFlag: number,
    pairedPartsNo: string,
    pairedOrderFlag: number,
    currentSpq: number,
    currOrderLot: number,
    currLength: number,
    currWidth: number,
    currHeight: number,
    currNetWeight: number,
    currGrossWeight: number,
    currEffectiveFrom: string,
    nextSpq: number,
    orderLot: number,
    length: number,
    width: number,
    height: number,
    grossWeight: number,
    netWeight: number,
    effectiveFrom: string,
    activeFlag: number,
    decimalDigits: number,
}