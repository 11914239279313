import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { StringItem } from "@rithe/form"
import { Objects, Records } from "@rithe/utils"
import React, { SetStateAction, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { EditCallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/EditCallbackToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { ActiveFlagTypeProvider } from "../../../components/DataGrid/typeProviders/ActiveFlagTypeProvider"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { useDownloadUnitPartsMasterByFilter, useDownloadUnitPartsMasterByUnitPartsId, useDownloadUnitPartsPDFByFilter, useDownloadUnitPartsPDFByUnitPartsId } from "../../../services/master/apis/masterDownloadApi"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { UnitPartsFactor } from "../../../services/master/models/UnitPartsFactor"
import { UnitPartsResult, unitPartsResultToJson } from "../../../services/master/models/UnitPartsResult"
import sessionKeys from "../../../utils/sessionKeys"
import { useSetDataIntoSessionAndGotoUrl } from "../../../utils/sessionUtil"

interface MLS080PcUiProps {
    path: string,
    filters: UnitPartsFactor,
    search: (filters: UnitPartsFactor) => void,
    data: UnitPartsResult[],
    totalCount: number,
    setFilters: React.Dispatch<SetStateAction<UnitPartsFactor>>,
}

export const MLS080PcUi = (props: MLS080PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}


const DataTable = ({ filters, data, setFilters, search, path }: MLS080PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])

    const columns = useMemo(() => [
        { field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsNo' }), width: 250 },
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 250 },
        { field: 'unitPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsName' }), width: 300 },
        { field: 'partsRefNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsRefNo' }), width: 250 },
        { field: 'uomCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.uomCode' }), width: 180 },
        { field: 'backNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.backNo' }), width: 170 },
        { field: 'hscode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.hscode' }), width: 170 },
        { field: 'pairedFlag', dataTypeName: CodeCategory.PairedFlag, title: intl.formatMessage({ id: 'field.pairedFlag' }), width: 170 },
        { field: 'pairedPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'pairedPartsNo' }), width: 170 },
        { field: 'pairedOrderFlag', dataTypeName: CodeCategory.PairedOrderFlag, title: intl.formatMessage({ id: 'field.pairedOrderFlag' }), width: 170 },
        { field: 'activeFlag', dataTypeName: CodeCategory.ActiveFlag, title: intl.formatMessage({ id: 'field.activeFlag' }), width: 130 },
    ], [intl])
    const getRowId = useCallback((row: any) => row.unitPartsId, [])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search }), [filters, setFilters, search])
    const actionProps1 = useMemo(() => ({ selections, data, path }), [data, selections, path])
    const actionProps2 = useMemo(() => ({ selections, filters }), [filters, selections])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.PairedFlag} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.PairedOrderFlag} />
        <ActiveFlagTypeProvider />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={CreateModifyAction} actionProps={actionProps1} />
        <ToolbarActionProvider Action={DownloadUnitPartsAction} actionProps={actionProps2} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['pairedPartsNo', 'currentSpq', 'createdDate', 'updatedDate']}
            columnSettings={{
                partsNo: { disableUserControl: true },
                oldPartsNo: { disableUserControl: true },
                displayPartsNo: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
    </DataGrid>
}


const FilterItem = (props: {
    search: (filters: UnitPartsFactor) => void,
    filters: UnitPartsFactor,
    setFilters: React.Dispatch<SetStateAction<UnitPartsFactor>>
}) => {
    const { filters, setFilters, search } = props

    const clear = useCallback((filters: UnitPartsFactor) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: UnitPartsFactor) => {
        return [
            filters.unitPartsNo,
            filters.partsNo,
            filters.unitPartsName,
            filters.partsRefNo,
            filters.backNo,
            filters.hscode,
            filters.pairedPartsNo,
            filters.activeFlagList,
            filters.pairedFlagList,
            filters.pairedOrderFlagList,
            filters.effectiveFromStart,
            filters.currentDate,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="unitPartsNo" label={intl.formatMessage({ id: 'field.unitPartsNo' })} />
        <StringItem field="partsNo" label={intl.formatMessage({ id: 'field.partsNo' })} />
        <StringItem field="unitPartsName" label={intl.formatMessage({ id: 'field.unitPartsName' })} />
        <StringItem field="partsRefNo" label={intl.formatMessage({ id: 'field.partsRefNo' })} />
        <StringItem field="backNo" label={intl.formatMessage({ id: 'field.backNo' })} />
        <StringItem field="hscode" label={intl.formatMessage({ id: 'field.hscode' })} />
        <StringItem field="pairedPartsNo" label={intl.formatMessage({ id: 'pairedPartsNo' })} />
        <CodesItem field="activeFlagList" label={intl.formatMessage({ id: 'field.activeFlag' })} code={CodeCategory.ActiveFlag} />
        <CodesItem field="pairedFlagList" label={intl.formatMessage({ id: 'field.pairedFlag' })} code={CodeCategory.PairedFlag} />
        {/* <CodesItem field="pairedOrderFlagList" label={intl.formatMessage({ id: 'field.pairedOrderFlag' })} code={CodeCategory.PairedOrderFlag} /> */}
        {/* <DateRangeItem field="createdDate" label={intl.formatMessage({ id: 'field.createdDate' })} getValue={effectiveFromDateGetValue} mapValue={effectiveFromDateMapValue} /> */}
        {/* <StringItem field="currentDate" label={intl.formatMessage({ id: 'currentDate' })} /> */}
    </FilterToolbarItem>
}


const CreateModifyAction = ({ selections, data, path }: { selections: number[], data: UnitPartsResult[], path: string }) => {
    const setSesion = useSetDataIntoSessionAndGotoUrl()
    const callback = useCallback(() => {
        const partsList = selections.length === 0 ? [] : data.filter(f => selections.indexOf(f.unitPartsId) >= 0).map(m => ({ ...m, newMod: 'MOD' }))
        const url = selections.length === 0 ? `${path}/create` : `${path}/edit`
        setSesion(sessionKeys.Data_MLS081, unitPartsResultToJson, partsList, url)
    }, [data, path, selections, setSesion])
    return <EditCallbackToolbarAction access="MARS.MLS080.CREATEOREDIT" title={<FormattedMessage id="createOrModifyUnitParts" />} callback={callback} />
}

export const DownloadUnitPartsAction = ({ selections, filters }: { selections: number[], filters: UnitPartsFactor }) => {
    const downloadMasterBySelectionApi = useDownloadUnitPartsMasterByUnitPartsId()
    const downloadMasterByFilterApi = useDownloadUnitPartsMasterByFilter()
    const downloadPdfBySelectionApi = useDownloadUnitPartsPDFByUnitPartsId()
    const downloadPdfByFilterApi = useDownloadUnitPartsPDFByFilter()
    const downloadMaster = useCallback(() => {
        if (selections.length <= 0) {
            downloadMasterByFilterApi(Objects.delete(filters, 'page'))
        } else {
            downloadMasterBySelectionApi({ unitPartsIdList: selections })
        }
    }, [downloadMasterByFilterApi, downloadMasterBySelectionApi, filters, selections])
    const downloadPdf = useCallback(() => {
        if (selections.length <= 0) {
            downloadPdfByFilterApi(Objects.delete(filters, 'page'))
        } else {
            downloadPdfBySelectionApi({ unitPartsIdList: selections })
        }
    }, [downloadPdfByFilterApi, downloadPdfBySelectionApi, filters, selections])
    return <DownloadGroupedToolbarAction access="MARS.MLS080.DOWNLOAD">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="downloadUnitParts" />} callback={downloadMaster} onClose={onClose} />
            <GroupedCallbackItem label={<FormattedMessage id="downloadUnitPartsByPDF" />} callback={downloadPdf} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}