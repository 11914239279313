import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { Records } from "@rithe/utils"
import React, { SetStateAction, memo, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useFunctionStore } from "../../../Root"
import { ConfirmCallbackViewAction } from "../../../components/Action/ConfirmCallbackViewAction"
import { DownloadCallbackCardAction } from "../../../components/Action/DownloadCallbackCardAction"
import { UploadCallbackCardAction } from "../../../components/Action/UploadCallbackCardAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { View } from "../../../components/View/View"
import { useDownloadExternalInboundPlan, useDownloadInboundPlanForUpload } from "../../../services/delivery/apis/deliveryDownloadApi"
import { useConfirmInboundResult } from "../../../services/delivery/apis/deliveryInboundApi"
import { useUploadExternalInbound, useUploadInbResult } from "../../../services/delivery/apis/deliveryUploadApi"
import { InboundInfo } from "../../../services/delivery/models/InboundInfo"
import { InboundResult } from "../../../services/delivery/models/InboundResult"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { applicationActions } from "../../Application/applicationSlice"

interface LIS014PcUiProps {
    inboundList: InboundInfo[]
}

export const LIS014PcUi = (props: LIS014PcUiProps) => {
    const { inboundList } = props
    return <ConfirmByUpload inboundList={inboundList} />
}

interface confirmProps {
    inboundList: InboundInfo[]
}

const ConfirmByUpload = ({ inboundList }: confirmProps) => {
    const intl = useIntl()
    const [uploadData, setUploadData] = useState<InboundResult[]>([])
    const [selections, setSelections] = useState<number[]>([])
    return (
        <View actions={[<ConfirmAction uploadData={uploadData} />]}>
            <SectionCard allowCollapse defaultCollapse={true}>
                <SectionCardHeader
                    serialNumber={1}
                    title={intl.formatMessage({ id: "step1ofInbound" })}
                    subtitle={intl.formatMessage({ id: "step1ofInboundSub" })}
                    actions={[<DownloadAction selections={selections} inboundList={inboundList} />,
                              <DownloadExternalAction selections={selections} inboundList={inboundList} />]}
                />
                < SectionCardContent >
                    <DataTable inboundList={inboundList} selections={selections} setSelections={setSelections} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={2}
                    title={intl.formatMessage({ id: "step2ofInbound" })}
                    subtitle={intl.formatMessage({ id: "step2ofInboundSub" })}
                    actions={[
                        <UploadAction setUploadData={setUploadData} />,
                        <UploadExternalAction setUploadData={setUploadData} />]}
                />
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={3}
                    title={intl.formatMessage({ id: "step3ofInbound" })}
                    subtitle={intl.formatMessage({ id: "step3ofInboundSub" })}
                />
                <SectionCardContent>
                    <DisplayTable uploadData={uploadData} />
                </SectionCardContent>
            </SectionCard>
        </View>
    )
}

interface dataTableProps {
    inboundList: InboundInfo[],
    selections: number[],
    setSelections: React.Dispatch<SetStateAction<number[]>>,
}
const DataTable = memo((props: dataTableProps) => {
    const intl = useIntl()
    const { inboundList, selections, setSelections } = props

    const columns = useMemo(() => [
        { field: 'latestInboundPlanDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.inboundPlanDate' }), width: 200 },
        { field: 'eta', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.eta' }), width: 180 },
        { field: 'inboundNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.inboundNo' }), width: 250 },
        { field: 'deliveryNoteNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.deliveryNoteNo' }), width: 250 },
        { field: 'outboundNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.outboundNo' }), width: 250 },
        { field: 'fromSupplierCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.fromSupplier' }), width: 200 },
        { field: 'fromDcCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.fromDc' }), width: 180 },
        { field: 'loadingPortCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'loadingPort' }), width: 180 },
        { field: 'dischargePortCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'dischargePort' }), width: 180 },
        { field: 'blNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.blNo' }), width: 200 },
        { field: 'blDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.blDate' }), width: 180 },
        { field: 'shippingMode', dataTypeName: CodeCategory.ShippingMode, title: intl.formatMessage({ id: 'field.shippingMode' }), width: 180 },
        { field: 'atd', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.atd' }), width: 180 },
        { field: 'vesselName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.vesselName' }), width: 250 },
        { field: 'voyageNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.voyageNo' }), width: 250 }
    ], [intl])
    const getRowId = useCallback((row: any) => row.inbId, [])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.InboundType} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.ShippingMode} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.InboundStatus} />
        <Data rows={inboundList} columns={columns} getRowId={getRowId} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['blNo', 'blDate', 'shippingMode', 'eta', 'atd', 'vesselName', 'voyageNo',]}
            columnSettings={{
                inboundNo: { disableUserControl: true },
                inboundRefNo: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <Sorting />
        <Filtering />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
    </DataGrid>
})

interface DisplayProps {
    uploadData: InboundResult[],
}

const DisplayTable = memo((props: DisplayProps) => {
    const { uploadData } = props
    const intl = useIntl()

    const columns = useMemo(() => {
        const CTITitles = [{ key: 'cti', value: intl.formatMessage({ id: 'containerInfo' }) }]
        const OPITitles = [{ key: 'opi', value: intl.formatMessage({ id: 'outerPkgInfo' }) }]
        const IPITitles = [{ key: 'ipi', value: intl.formatMessage({ id: 'innerPkgInfo' }) }]
        const SPITitles = [{ key: 'spi', value: intl.formatMessage({ id: 'sellerInfo' }) }]
        const BPITitles = [{ key: 'bpi', value: intl.formatMessage({ id: 'buyerInfo' }) }]
        return [
            { field: 'receiver', dataTypeName: 'string', title: intl.formatMessage({ id: 'receiver' }), width: 200 },
            { field: 'inboundNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'inboundNo' }), width: 250 },
            { field: 'inboundDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'inboundDate' }), width: 180 },
            { field: 'inboundTime', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.inboundTime' }), width: 180 },
            { field: 'shipper', dataTypeName: 'string', title: intl.formatMessage({ id: 'shipper' }), width: 200 },
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 250 },
            { field: 'uomCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'uom' }), width: 180 },
            { field: 'inboundQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.inboundQty' }), width: 180 },

            { field: 'cntTruNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'cntTruNo' }), categories: CTITitles, width: 200 },
            { field: 'cntTruType', dataTypeName: 'string', title: intl.formatMessage({ id: 'cntTruType' }), categories: CTITitles, width: 200 },
            { field: 'sealNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sealNo' }), categories: CTITitles, width: 200 },
            { field: 'cntTruM3', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.m3' }), categories: CTITitles, width: 180 },
            { field: 'cntTruNW', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.netWeight' }), categories: CTITitles, width: 180 },
            { field: 'cntTruGW', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.grossWeight' }), categories: CTITitles, width: 180 },

            { field: 'palletNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'palletNo' }), categories: OPITitles, width: 200 },
            { field: 'outerPkgNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'outerPkgNo' }), categories: OPITitles, width: 200 },
            { field: 'outerPkgType', dataTypeName: 'string', title: intl.formatMessage({ id: 'outerPkgType' }), categories: OPITitles, width: 200 },
            { field: 'outerPkgM3', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.m3' }), categories: OPITitles, width: 180 },
            { field: 'outerPkgNW', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.netWeight' }), categories: OPITitles, width: 180 },
            { field: 'outerPkgGW', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.grossWeight' }), categories: OPITitles, width: 180 },

            { field: 'innerPkgNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'innerPkgNo' }), categories: IPITitles, width: 200 },
            { field: 'innerPkgType', dataTypeName: 'string', title: intl.formatMessage({ id: 'innerPkgType' }), categories: IPITitles, width: 200 },
            { field: 'innerPkgM3', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.m3' }), categories: IPITitles, width: 180 },
            { field: 'innerPkgNW', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.netWeight' }), categories: IPITitles, width: 180 },
            { field: 'innerPkgGW', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.grossWeight' }), categories: IPITitles, width: 180 },

            { field: 'soNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.soNo' }), categories: SPITitles, width: 250 },
            { field: 'sellerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'sellerCode' }), categories: SPITitles, width: 200 },
            { field: 'sellerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sellerPartsNo' }), categories: SPITitles, width: 250 },
            { field: 'sellerPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sellerPartsName' }), categories: SPITitles, width: 300 },
            { field: 'sellerBackNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.backNo' }), categories: SPITitles, width: 180 },
            { field: 'colorCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.colorCode' }), categories: SPITitles, width: 180 },
            { field: 'srbq', dataTypeName: 'number', title: intl.formatMessage({ id: 'srbq' }), width: 180 },

            { field: 'poNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.poNo' }), categories: BPITitles, width: 250 },
            { field: 'buyerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'buyerCode' }), categories: BPITitles, width: 200 },
            { field: 'buyerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'buyerPartsNo' }), categories: BPITitles, width: 250 },
            { field: 'buyerPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.buyerPartsName' }), categories: BPITitles, width: 300 },
            { field: 'buyerBackNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.backNo' }), categories: BPITitles, width: 180 },
            { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), categories: BPITitles, width: 180 },
        ]
    }, [intl])

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <Data rows={uploadData} columns={columns} />
            <ColumnFreeze />
            <ColumnVisibility ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
            <Paging defaultPageSize={15} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        </DataGrid>
    </div>
})

const DownloadAction = ({ selections, inboundList }: { selections: number[], inboundList: InboundInfo[] }) => {
    const downloadResult = useDownloadInboundPlanForUpload()
    
    const download = useCallback(() => {
        if (selections.length > 0) {
            downloadResult({ inbNoList: inboundList.filter(f => selections.includes(f.inbId)).map(m => m.inboundNo) })
        } else {
            downloadResult({ inbNoList: [] })
        }
    }, [downloadResult, inboundList, selections])
    return <DownloadCallbackCardAction access="LOGI.LIS014.DOWNLOAD" callback={download} />
}

const DownloadExternalAction = ({ selections, inboundList }: { selections: number[], inboundList: InboundInfo[] }) => {
    const downloadResult = useDownloadExternalInboundPlan()
    
    const download = useCallback(() => {
        if (selections.length > 0) {
            downloadResult({ inbNoList: inboundList.filter(f => selections.includes(f.inbId)).map(m => m.inboundNo) })
        } else {
            downloadResult({ inbNoList: [] })
        }
    }, [downloadResult, inboundList, selections])
    return <DownloadCallbackCardAction access="LOGI.LIS014.EXTERNALDOWNLOAD" callback={download} title={<FormattedMessage id="DownloadExternalInbound" />}/>
}

interface UploadProps {
    setUploadData: React.Dispatch<React.SetStateAction<InboundResult[]>>
}
const UploadAction = ((props: UploadProps) => {
    const { setUploadData } = props
    const uploadInboundResult = useUploadInbResult()
    const upload = useCallback((files: FileList | null) => {
        if (files === null) return
        uploadInboundResult({ file: files[0] }, { serialized: true }).then(result => {
            setUploadData(result ? result : [])
        })
    }, [setUploadData, uploadInboundResult])
    return <UploadCallbackCardAction access="LOGI.LIS014.UPLOAD" title={<FormattedMessage id="upload" />} callback={upload} />
})

const UploadExternalAction = ((props: UploadProps) => {
    const { setUploadData } = props
    const uploadInboundResult = useUploadExternalInbound()
    const upload = useCallback((files: FileList | null) => {
        if (files === null) return
        uploadInboundResult({ file: files[0] }, { serialized: true }).then(result => {
            setUploadData(result ? result : [])
        })
    }, [setUploadData, uploadInboundResult])
    return <UploadCallbackCardAction access="LOGI.LIS014.EXTERNALUPLOAD" title={<FormattedMessage id="uploadExternalInbound" />} callback={upload} />
})

const ConfirmAction = ({ uploadData }: { uploadData: InboundResult[] }) => {
    const save = useConfirmInboundResult()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'confirm' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const callback = useCallback(() => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            save(uploadData, { serialized: true }).then(() => navigate('/inbound')).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions:[{
                label: 'CANCEL'
            },{
                functionId,
                label:'CONFIRM',
            }]
        }))
    }, [dispatch, functionStore, navigate, save, title, uploadData])
    return <ConfirmCallbackViewAction access="LOGI.LIS014.CONFIRM" callback={callback} disabled={disabled}/>
}