import {Project} from "../../../utils/Project"

export type ExternalOrderCategoryList = Required & Partial<Optional>
export type ExternalOrderCategoryListJson = JsonRequired & Partial<JsonOptional>
export const externalOrderCategoryListToJson: Project<ExternalOrderCategoryList, ExternalOrderCategoryListJson> = obj => ({
    ...obj,
})
export const externalOrderCategoryListFromJson: Project<ExternalOrderCategoryListJson, ExternalOrderCategoryList> = json => ({
    ...json,
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
}

interface JsonRequired {
}

interface JsonOptional {
    codeCategory: string,
    language: string,
    codeValue: number,
    codeName:string
}