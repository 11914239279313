import { useCallback, useMemo, useState } from "react"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { useGetAlarmUsagePatternPage } from "../../../services/smt/api/AlarmUsagePatternApi"
import { AlarmUsagePattemTransfer } from "../../../services/smt/models/AlarmUsagePattemTransfer"
import { AlarmUsagePatternView, alarmUsagePatternViewFromson, alarmUsagePatternViewToJson } from "../../../services/smt/models/AlarmUsagePatternView"
import { useGetCompanyType } from "../../../utils/ApplicationUtils"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { MSAUF010CustPcUi } from "./MSAUF010CustPcUi"
import { MSAUF010PcUi } from "./MSAUF010PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000, } }

const searchCachekey = sessionKeys.Filters_MSAUF010

export const MSAUF010 = () => {

    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? alarmUsagePatternViewFromson(JSON.parse(filters)) : null
    }, [])

    const [filters, setFilters] = useState<AlarmUsagePatternView>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<AlarmUsagePattemTransfer[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)



    const getAlarmUsagePatternList = useGetAlarmUsagePatternPage()
    const search = useCallback((filters: AlarmUsagePatternView) => {
        getAlarmUsagePatternList(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount ?? 0)
            setData(result?.data ?? [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(alarmUsagePatternViewToJson(filters)))
        })
    }, [getAlarmUsagePatternList, setData])

    useSearchOnLoad(search, filters)

    const cbdsType = useGetCompanyType()

    if (cbdsType === CbdsType.BU) {
        return <MSAUF010PcUi
            filters={filters}
            setFilters={setFilters}
            search={search}
            data={data}
            totalCount={totalCount}
        />
    } else {
        return <MSAUF010CustPcUi
            filters={filters}
            setFilters={setFilters}
            search={search}
            data={data}
            totalCount={totalCount}
        />
    }
}