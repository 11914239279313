import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { Distatus } from "../enums/DiStatus"
import { DiType } from "../enums/DiType"

export type CustDeliveryTransfer = Required & Partial<Optional>

type CustDeliveryTransferJson = JsonRequired & Partial<JsonOptional>

export const custDeliveryTransferFromJson: Project<CustDeliveryTransferJson, CustDeliveryTransfer> = json => ({
    ...json,
    diDate: dateFromJson(json.diDate),
})

interface Required extends JsonRequired { }

interface Optional extends Omit<JsonOptional, 'diDate'> {
    status: Distatus,
    diType: DiType,
    diDate: Date,
}

interface JsonRequired {
    diId: number,
}

interface JsonOptional {
    diNo: string,
    diRefNo: string,
    diType: number,
    diDate: string,
    remark: string,
    status: number,
    customerId: number,
    dcId: number,
    diVersion: number,
    version: number
}