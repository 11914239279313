
import moment from "moment"
import { useCallback, useMemo } from "react"
import { useApplicationSelector } from "../layouts/Application/applicationSlice"

export const useGetCompanyType = () => {

    const companyUid = useApplicationSelector(state => state.auth.companyUid)

    const companyType = useMemo(() => {
        if (companyUid !== null && companyUid !== undefined) {
            return Number(companyUid.substr(companyUid.indexOf('-') + 1))
        }
        return null
    }, [companyUid])

    return companyType
}

export const useGetCompanyCode = () => {

    const companyUid = useApplicationSelector(state => state.auth.companyUid)
    const loginUserCompanies = useApplicationSelector(state => state.auth.userCompanies)

    const companyCode = useMemo(() => {
        if (companyUid !== null && companyUid !== undefined) {
            return loginUserCompanies?.find(f => f.cbdsUid === companyUid)?.cbdsCode
        }
        return null
    }, [companyUid, loginUserCompanies])

    return companyCode
}

export const useGetCompanyId = () => {

    const companyUid = useApplicationSelector(state => state.auth.companyUid)

    const companyId = useMemo(() => {
        if (companyUid !== null && companyUid !== undefined) {
            return Number(companyUid.substr(0, companyUid.indexOf('-')))
        }
        return null
    }, [companyUid])

    return companyId
}

export const useGetCompanyUid = () => {

    const companyUid = useApplicationSelector(state => state.auth.companyUid)
    const uid = useMemo(() => {
        if (companyUid !== null && companyUid !== undefined) {
            return companyUid
        }
        return null
    }, [companyUid])

    return uid
}

export const useGetPartyCompanyId = () => {

    const companyUid = useApplicationSelector(state => state.auth.companyUid)
    const companies = useApplicationSelector(state => state.auth.userCompanies)
    const companyId = useMemo(() => {
        const company = companies?.find(f => f.cbdsUid === companyUid)
        return company?.cbdsId
    }, [companies, companyUid])

    return companyId
}

export const usePrepareCompanyUid = () => {
    return useCallback((companyId, companyType) => companyId + '-' + companyType, [])
}

export const useSplitCompanyType = () => {
    return useCallback((companyUid) => Number(companyUid.substr(companyUid.indexOf('-') + 1)), [])
}

export const useSplitCompanyId = () => {
    return useCallback((companyUid) => Number(companyUid.substr(0, companyUid.indexOf('-'))), [])
}

export const today = () => {
    return moment(new Date()).add(0, 'd').toDate()
}