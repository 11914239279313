import { Project } from "@rithe/utils"
import { OrderCalcVo, OrderCalcVoFromJson, OrderCalcVoJson } from "./OrderCalcVo"
import { OrderGroupVo, orderGroupVoFromJson, OrderGroupVoJson } from "./OrderGroupVo"

export interface OrderCalculationCreateViewResult {
    orderGroup: OrderGroupVo,
    orderCalc?: OrderCalcVo,
}

interface OrderCalculationCreateViewResultJson {
    orderGroup: OrderGroupVoJson,
    orderCalc?: OrderCalcVoJson,
}

export const OrderCalculationCreateViewResultFromJson: Project<OrderCalculationCreateViewResultJson, OrderCalculationCreateViewResult> = json => ({
    orderGroup: orderGroupVoFromJson(json.orderGroup),
    orderCalc: json.orderCalc ? OrderCalcVoFromJson(json.orderCalc) : undefined,
})