import { CancelOutlined } from "@material-ui/icons"
import React from "react"
import { FormattedMessage } from "react-intl"
import { CallbackToolbarAction } from "./CallbackToolbarAction"

interface CancelCallbackToolbarActionProps {
    access?: string,
    power?: boolean,
    title?: NonNullable<React.ReactNode>,
    icon?: NonNullable<React.ReactNode>,
    callback: () => void,
    disabled?: boolean,
}

export const CancelCallbackToolbarAction = (props: CancelCallbackToolbarActionProps) => {
    const defaultProps = {
        title: <FormattedMessage id="cancel" />,
        icon: <CancelOutlined />
    }
    return <CallbackToolbarAction {...defaultProps} {...props} />
}