import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useGetCostsOrderList, useGetInboundRecordList } from "../../../services/dashboard/apis/dashboardApi"
import { OrderMonthFactor } from "../../../services/dashboard/models/CostMonitorFactor"
import { CostResult, CostsOrderResult } from "../../../services/dashboard/models/CostMonitorResult"
import { today } from "../../../utils/ApplicationUtils"
import { DAS012PcUi } from "./DAS012PcUi"

export const DAS012 = () => {

    const defaultFilter = useMemo<OrderMonthFactor>(() => {
        const startDate = today()
        startDate.setMonth(Number(startDate.getMonth() - 12))
        const endDate = today()
        return { startDate: startDate, endDate: endDate }
    }, [])

    const [inboundData, setInboundData] = useState<CostResult[]>([])
    const [purchaseData, setPurchaseData] = useState<CostsOrderResult[]>([])

    const getInboundRecord = useGetInboundRecordList()
    const getOrderList = useGetCostsOrderList()
    const search = useCallback((filters: OrderMonthFactor) => {
        getInboundRecord(filters, { silent: true, serialized: true }).then(result => {
            setInboundData(result || [])
        })
        getOrderList(filters, { silent: true, serialized: true }).then(result => {
            setPurchaseData(result || [])
        })

    }, [getInboundRecord, getOrderList])
    useEffect(() => search(defaultFilter), [defaultFilter, search])

    return <DAS012PcUi
        defaultFilter={defaultFilter}
        search={search}
        inboundData={inboundData}
        purchaseData={purchaseData}
    />
}
