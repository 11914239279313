import { EntriesItem, EntriesItemProps } from "@rithe/form"
import { Objects } from "@rithe/utils"
import React, { useMemo } from "react"
import { UserAccessHistoryTypeList } from "../../../services/privilege/models/UserAccessHistoryType"

type CodesItemProp = Omit<EntriesItemProps, 'entries'> & {
  data: UserAccessHistoryTypeList[]
}

export const MSESF030CodeItems = React.memo((props: CodesItemProp) => {
  const { data } = props
  const entries = useMemo(() => {
    return data.map(item => [item.codeValue, item.codeName] as [number, string])
  }, [data])
  return <EntriesItem {...Objects.delete(props)} entries={entries} />
})
MSESF030CodeItems.displayName = 'CodesItem'