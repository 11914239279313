import { Project } from "../../../utils/Project"
import { SettingDetail, settingDetailFromJson, SettingDetailJson, settingDetailToJson } from "./SettingDetail"
import { TnmSupplier, tnmSupplierFromJson, TnmSupplierJson, tnmSupplierToJson } from "./TnmSupplier"

export interface SupplierDetail {
    basicInfo: TnmSupplier,
    settings: SettingDetail[],
}
interface SupplierDetailJson {
    basicInfo: TnmSupplierJson,
    settings: SettingDetailJson[],
}

export const supplierDetailFromJson: Project<SupplierDetailJson, SupplierDetail> = json => ({
    basicInfo: tnmSupplierFromJson(json.basicInfo),
    settings: json.settings.map(settingDetailFromJson),
})

export const supplierDetailToJson: Project<SupplierDetail, SupplierDetailJson> = obj => ({
    basicInfo: tnmSupplierToJson(obj.basicInfo),
    settings: obj.settings.map(settingDetailToJson),
})