import { Project } from "@rithe/utils"
import { CoStatus } from "../../order/enums/CoStatus"
import { OrderType } from "../../order/enums/OrderType"
import { dateFromJson } from "../../utils/deserializer"
import { dateToJson } from "../../utils/serializer"

export type OCPlaceRegularOrderResult = ResultRequired & Partial<ResultOptional>
export type OCPlaceRegularOrderResultson = ResultJsonRequired & Partial<ResultJsonOptional>

export const ocPlaceRegularOrderResultDeserializer: Project<OCPlaceRegularOrderResultson, OCPlaceRegularOrderResult> = json => ({
    ...json,
    orderFirstDate: dateFromJson(json.orderFirstDate),
    orderLastDate: dateFromJson(json.orderLastDate),
    targetFirstDate: dateFromJson(json.targetFirstDate),
    targetLastDate: dateFromJson(json.targetLastDate),
    partsDetailList: json.partsDetailList.map(ocPartsDetailDeserializer),
})

export const ocPlaceRegularOrderResultSerializer: Project<OCPlaceRegularOrderResult, OCPlaceRegularOrderResultson> = data => ({
    ...data,
    orderFirstDate: dateToJson(data.orderFirstDate),
    orderLastDate: dateToJson(data.orderLastDate),
    targetFirstDate: dateToJson(data.targetFirstDate),
    targetLastDate: dateToJson(data.targetLastDate),
    partsDetailList: data.partsDetailList.map(ocPartsDetailSerializer),
})

export interface OCPartsDetail extends Omit<PartsDetailJson, 'forecastList' | 'inbPlanList'> {
    forecastList: OCForecastDetail[],
    inbPlanList: OCInboundPalnDetail[],
}

interface ResultRequired extends Omit<ResultJsonRequired, 'orderFirstDate' | 'orderLastDate' | 'targetFirstDate' | 'targetLastDate' | 'partsDetailList'> {
    orderFirstDate: Date,
    orderLastDate: Date,
    targetFirstDate: Date,
    targetLastDate: Date,
    partsDetailList: OCPartsDetail[],
}

interface ResultOptional extends Omit<ResultJsonOptional, 'orderType' | 'status'> {
    orderType: OrderType,
    status: CoStatus,
}

interface ResultJsonRequired {
    orderCalcPoId: string,
    poGroupId: string,
    contractNo: string,
    sellerCode: string,
    orderType: number,
    buId: string,
    buCode: string,
    orderFirstDate: string,
    orderLastDate: string,
    targetFirstDate: string,
    targetLastDate: string,
    partsDetailList: PartsDetailJson[],
}

interface ResultJsonOptional {
    forecastNum: number,
    targetLeadtime: number,
    orderType: number,
    orderRefNo: string,
    status: number,
    shippingMode: number,
    paymentTermsCode: string,
    termsDescription: string,
    deliveryToCode: string,
    inboundDcCode: string,
    remark: string,
    spotOrderReason: string,
    vesion: number,
    orderFrequency: number,
}

interface PartsDetailJson {
    orderCalcPoDetailId: string | null,
    orderCalcPoId: string,
    customerPartsId: string,
    supplierPartsId: string,
    customerId: string,
    contractNo: string,
    partsNo: string,
    partsDescription: string,
    customerPartsNo: string,
    supplierPartsNo: string,
    exportCountry: string,
    supplierId: string,
    supplierCode: string,
    uomCode: string,
    sellerUomCode: string,
    orderLot: number,
    spq: number,
    fluctuationRatio: number | null,
    // fcFluctuationRate: number | null,
    firmQty: number | null,
    previouslySuggestedOrderQty: number | null,
    currentFirmQty: number | null,
    currentCustomerUsage: number | null,
    currentSuggestedOrderQty: number | null,
    suggestedAdditionalQty: number | null,
    additionalQty: number | null,
    status: number,
    version: number,
    forecastList: ForecastDetailJson[] | null,
    inbPlanList: InboundPalnDetailJson[] | null,


    fcFluctuationRatio: number,
    fluctuationReason: string,
    price: number,
    uomDigits: number,
    sellerUomDigits: number,
    lastForecastQty: number | null,
    currencyDigits: number,

    targetFirstDate: string,
    targetLastDate: string,
    targetLeadtime: number,
}

export const ocPartsDetailDeserializer: Project<PartsDetailJson, OCPartsDetail> = json => ({
    ...json,
    forecastList: json.forecastList ? json.forecastList.map(ocForecastDetailDeserializer) : [],
    inbPlanList: json.inbPlanList ? json.inbPlanList.map(ocInboundPalnDetailDeserializer) : [],
})

export const ocPartsDetailSerializer: Project<OCPartsDetail, PartsDetailJson> = data => ({
    ...data,
    forecastList: data.forecastList ? data.forecastList.map(ocForecastDetailSerializer) : [],
    inbPlanList: data.inbPlanList ? data.inbPlanList.map(ocInboundPalnDetailSerializer) : [],
})

export interface OCForecastDetail extends Omit<ForecastDetailJson, 'fcFirstDate' | 'fcLastDate' | 'targetFirstDate' | 'targetLastDate'> {
    fcFirstDate: Date,
    fcLastDate: Date,
    targetFirstDate: Date,
    targetLastDate: Date,
}

interface ForecastDetailJson {
    orderCalcPoFcId: string,
    orderCalcPoDetailId: string,
    fcFirstDate: string,
    fcLastDate: string,
    targetFirstDate: string,
    targetLastDate: string,
    fcQty: number,
    fluctuationReason: string,
    fcQtyOrigin: string,
    inventoryCtrlQty: number,
    version: number,
    lastForecastQty: number,
}

const ocForecastDetailDeserializer: Project<ForecastDetailJson, OCForecastDetail> = json => ({
    ...json,
    fcFirstDate: dateFromJson(json.fcFirstDate),
    fcLastDate: dateFromJson(json.fcLastDate),
    targetFirstDate: dateFromJson(json.targetFirstDate),
    targetLastDate: dateFromJson(json.targetLastDate),
})
const ocForecastDetailSerializer: Project<OCForecastDetail, ForecastDetailJson> = data => ({
    ...data,
    fcFirstDate: dateToJson(data.fcFirstDate),
    fcLastDate: dateToJson(data.fcLastDate),
    targetFirstDate: dateToJson(data.targetFirstDate),
    targetLastDate: dateToJson(data.targetLastDate),
})


export interface OCInboundPalnDetail extends Omit<InboundPalnDetailJson, 'crdDate' | 'drDate'> {
    crdDate: Date,
    drDate: Date | null
}

interface InboundPalnDetailJson {
    crdId: string | null,
    orderCalcPoDetailId: string | null,
    crdDate: string,
    crdQty: number | null,
    version: number | null,
    drDate: string | null,
}

const ocInboundPalnDetailDeserializer: Project<InboundPalnDetailJson, OCInboundPalnDetail> = json => ({
    ...json,
    crdDate: dateFromJson(json.crdDate),
    drDate: json.drDate ? dateFromJson(json.drDate) : null
})
const ocInboundPalnDetailSerializer: Project<OCInboundPalnDetail, InboundPalnDetailJson> = data => ({
    ...data,
    crdDate: dateToJson(data.crdDate),
    drDate: data.drDate ? dateToJson(data.drDate) : null
})
