import { Project } from "../../../utils/Project"
import { dateFromJson, datetimeFromJson } from "../../utils/deserializer"



export interface InventoryResult {
    dataDateTime?: Date,
    stockPositionList?: StockPositionResult[],
    inventoryValueList?: InventoryValueResult[],
    salesRatioInfo?: InventorySalesRatioResult
}

export interface InventoryResultJson {
    dataDateTime?: number,
    stockPositionList?: StockPositionResultJson[],
    inventoryValueList?: InventoryValueResultJson[],
    salesRatioInfo?: InventorySalesRatioResultJson
}

export const inventoryResultFromJson: Project<InventoryResultJson, InventoryResult> = json => ({
    dataDateTime: datetimeFromJson(json.dataDateTime),
    stockPositionList: json.stockPositionList?.map(stockPositionResultFromJson),
    inventoryValueList: json.inventoryValueList?.map(inventoryValueResultFromJson),
    salesRatioInfo: json.salesRatioInfo ? inventorySalesRatioResultFromJson(json.salesRatioInfo) : undefined,
})


export interface StockPositionResult extends StockPositionResultJson {

}
interface StockPositionResultJson {
    partsNo: string,
    contractNo: string,
    min1?: number | null,
    min2?: number | null,
    min3?: number | null,
    max?: number | null,
    stocks?: string
}
const stockPositionResultFromJson: Project<StockPositionResultJson, StockPositionResult> = json => ({
    ...json
})


export interface InventoryValueResult extends InventoryValueResultJson {

}
interface InventoryValueResultJson {
    partsNo: string,
    contractNo: string,
    warehouse: string,
    currency: string,
    inventoryAmount: number,
}
const inventoryValueResultFromJson: Project<InventoryValueResultJson, InventoryValueResult> = json => ({
    ...json
})


export interface InventorySalesRatioResult {
    salesCost: number,
    inventoryCost: number,
    salesRatio: number,
    weeklySalesRatioList: WeeklySalesRatioResult[]
}
interface InventorySalesRatioResultJson {
    salesCost: number,
    inventoryCost: number,
    salesRatio: number,
    weeklySalesRatioList: WeeklySalesRatioResultJson[]
}
const inventorySalesRatioResultFromJson: Project<InventorySalesRatioResultJson, InventorySalesRatioResult> = json => ({
    ...json,
    weeklySalesRatioList: json.weeklySalesRatioList.map(weeklySalesRatioResultFromJson)

})

export interface WeeklySalesRatioResult {
    startDate: Date,
    endDate: Date,
    weekNum: number,
    averageInvAmount: number
    totalSalesAmount: number
    salesRatio: number
}
interface WeeklySalesRatioResultJson {
    startDate: string,
    endDate: string,
    weekNum: number,
    averageInvAmount: number
    totalSalesAmount: number
    salesRatio: number
}
const weeklySalesRatioResultFromJson: Project<WeeklySalesRatioResultJson, WeeklySalesRatioResult> = json => ({
    ...json,
    startDate: dateFromJson(json.startDate),
    endDate: dateFromJson(json.endDate)
})


export interface WareHouseResult {
    dcId: number,
    dcCode: string,
}
interface WareHouseResultJson {
    dcId: number,
    dcCode: string,
}
export const wareHouseResultFromJson: Project<WareHouseResultJson, WareHouseResult> = json => ({
    ...json,
})


export interface ItemsResult {
    partsId: number,
    partsNo: string,
}
interface ItemsResultJson {
    partsId: number,
    partsNo: string,
}
export const itemsResultFromJson: Project<ItemsResultJson, ItemsResult> = json => ({
    ...json,
})
