import { Break, CheckItem, DateItem, EntriesItem, Form, StringItem } from "@rithe/form"
import { Objects } from "@rithe/utils"
import { SetStateAction, useCallback, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { DownloadCallbackViewAction } from "../../../components/Action/DownloadCallbackViewAction"
import { UploadCallbackViewAction } from "../../../components/Action/UploadCallbackViewAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { DeleteCallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/DeleteCallbackToolbarAction"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { ContractVo } from "../../../services/localmaster/models/ContractVo"
import { useDownloadContractPartsMasterByFilterAll } from "../../../services/master/apis/masterDownloadApi"
import { useUploadMasterContractPartsMaster } from "../../../services/master/apis/masterUploadApi"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { ContractFactor } from "../../../services/master/models/ContractFactor"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { useDownloadPOBalanceByFilter } from "../../../services/order/apis/OrderDownloadApi"
import { useUploadPNAPoBalanceFile } from "../../../services/order/apis/OrderUploadApi"
import { useRemoveExistsOrder } from "../../../services/order/apis/PlaceOrderApi"
import { POBalanceFactor } from "../../../services/order/models/POBalanceFactor"
import { useIsPowerUser } from "../../../utils/useIsPowerUser"
import { applicationActions } from "../../Application/applicationSlice"

interface TOLS010PcUiProps {
    contractParts: ContractFactor,
    setContractParts: React.Dispatch<SetStateAction<ContractFactor>>,
    pOBalance: POBalanceFactor,
    setPOBalance: React.Dispatch<SetStateAction<POBalanceFactor>>,
    allCbdss: TnvCbds[],
    contracts: ContractVo[]
}


export const TOLS010PcUi = (props: TOLS010PcUiProps) => {
    const { setContractParts, contractParts, allCbdss, pOBalance, setPOBalance, contracts } = props
    const intl = useIntl()
    const [removeOrder, setRemoveOrder] = useState<{orderNo: string}>({orderNo:''})

    return (
        <View>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={1}
                    title={intl.formatMessage({ id: 'step1ChangeContractParts' })}
                    actions={[DownloadContractPartsAction({ contractParts }), UploadContractPartsAction()]}
                />
                <SectionCardContent>
                    <Step1ChangeContractParts contractParts={contractParts} setContractParts={setContractParts} allCbdss={allCbdss} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={2}
                    title={intl.formatMessage({ id: 'step2ChangePOBalance' })}
                    actions={[DownloadPOBalanceAction({ pOBalance }), UploadPOBalanceAction()]}
                />
                <SectionCardContent>
                    <Step2ChangePOBalance pOBalance={pOBalance} setPOBalance={setPOBalance} allCbdss={allCbdss} contracts={contracts} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={2}
                    title={intl.formatMessage({ id: 'step3RemoveOrder' })}
                    subtitle={intl.formatMessage({ id: 'step3RemoveOrderSubtitle' })}
                    actions={[DeleteOrderAction(removeOrder)]}
                />
                <SectionCardContent>
                    <Step3RemoveOrder setRemoveOrder={setRemoveOrder} removeOrder={removeOrder} />
                </SectionCardContent>
            </SectionCard>
        </View >
    )
}


const Step1ChangeContractParts = ({ allCbdss, setContractParts, contractParts }: {
    contractParts: ContractFactor,
    setContractParts: React.Dispatch<SetStateAction<ContractFactor>>,
    allCbdss: TnvCbds[],
}) => {

    const intl = useIntl()
    const sellerCodeEntries: [string, string][] = useMemo(() => (allCbdss ?? []).filter(f => f.cbdsType === CbdsType.SUPP || f.cbdsType === CbdsType.BU).map(m => [m.cbdsUid, m.cbdsCode]), [allCbdss])
    const buyerCodeEntries: [string, string][] = useMemo(() => (allCbdss ?? []).filter(f => f.cbdsType === CbdsType.CUST || f.cbdsType === CbdsType.BU).map(m => [m.cbdsUid, m.cbdsCode]), [allCbdss])
    const consigneeEntries: [string, string][] = useMemo(() => (allCbdss ?? []).filter(f => f.cbdsType === CbdsType.CUST || f.cbdsType === CbdsType.BU).map(m => [m.cbdsUid, m.cbdsCode]), [allCbdss])
    const accounteeEntries: [string, string][] = useMemo(() => (allCbdss ?? []).filter(f => f.cbdsType === CbdsType.CUST || f.cbdsType === CbdsType.BU).map(m => [m.cbdsUid, m.cbdsCode]), [allCbdss])
    const deliveryToEntries: [string, string][] = useMemo(() => (allCbdss ?? []).filter(f => f.cbdsType === CbdsType.CUST || f.cbdsType === CbdsType.DC).map(m => [m.cbdsUid, m.cbdsCode]), [allCbdss])

    return <Form data={contractParts} setData={setContractParts} labelDisplay="block" helperDisplay="tooltip" >
        <StringItem field="contractNo" label={intl.formatMessage({ id: 'field.contractNo' })} />
        <StringItem field="description" label={intl.formatMessage({ id: 'field.description' })} />
        <EntriesItem field="sellerList" label={intl.formatMessage({ id: 'sellerCode' })} entries={sellerCodeEntries} />
        <EntriesItem field="buyerList" label={intl.formatMessage({ id: 'buyerCode' })} entries={buyerCodeEntries} />
        <CodesItem field="businessTypeList" label={intl.formatMessage({ id: 'field.businessType' })} code={CodeCategory.BusinessType} />
        <EntriesItem field="consigneeList" label={intl.formatMessage({ id: 'consigneeCode' })} entries={consigneeEntries} />
        <EntriesItem field="accounteeList" label={intl.formatMessage({ id: 'accounteeCode' })} entries={accounteeEntries} />
        <EntriesItem field="deliveryToList" label={intl.formatMessage({ id: 'deliveryToCode' })} entries={deliveryToEntries} />
        <CodesItem field="statusList" label={intl.formatMessage({ id: 'field.status' })} code={CodeCategory.ActiveFlag} />
        <Break />
    </Form>
}

const Step2ChangePOBalance = ({ pOBalance, setPOBalance, allCbdss, contracts }: {
    pOBalance: POBalanceFactor,
    setPOBalance: React.Dispatch<SetStateAction<POBalanceFactor>>,
    allCbdss: TnvCbds[],
    contracts: ContractVo[]
}) => {
    const intl = useIntl()
    const [suppContractEntries, setSuppContractEntries] = useState<[number, string][]>([])
    const supplierEntries: [number, string][] = useMemo(() => (allCbdss ?? []).filter(f => f.cbdsType === CbdsType.SUPP).map(m => [m.cbdsId, m.cbdsCode]), [allCbdss])

    useEffect(() => {
        setSuppContractEntries(contracts.filter(c => !pOBalance.supplierIds || pOBalance.supplierIds.length === 0 || pOBalance.supplierIds.some(s => s === c.supplierId)).map(m => [m.contractId, m.contractNo]))
    }, [contracts, pOBalance.supplierIds])

    const onChange = useCallback((value: any) => {
        setPOBalance(poBalance => poBalance.supplierIds === value ? { ...poBalance, suppContractIds: [] } : poBalance )
    }, [setPOBalance])

    return <Form data={pOBalance} setData={setPOBalance} labelDisplay="block" helperDisplay="tooltip" >
        <EntriesItem field="supplierIds" label={intl.formatMessage({ id: 'supplier' })} entries={supplierEntries} onChange={onChange} />
        <EntriesItem field="suppContractIds" label={intl.formatMessage({ id: 'suppContractNo' })} entries={suppContractEntries} />
        <Break />
        <StringItem field="soNo" label={intl.formatMessage({ id: 'salesOrderNo' })} />
        <StringItem field="poNo" label={intl.formatMessage({ id: 'puchaseOrderNo' })} />
        <CheckItem field="onlyUncompletedFlag" label={intl.formatMessage({ id: 'onlyUncompletedFlag' })} />
        <Break />
        <DateItem field="pnaRequestDateStart" label={intl.formatMessage({ id: 'requestDateFrom' })} />
        <DateItem field="pnaRequestDateEnd" label={intl.formatMessage({ id: 'requestDateTo' })} />
        <Break />
        <StringItem field="partsNo" label={intl.formatMessage({ id: 'partsNo' })} />
        <StringItem field="sellerPartsNo" label={intl.formatMessage({ id: 'supplierPartsNo' })} />
        <StringItem field="buyerPartsNo" label={intl.formatMessage({ id: 'buyerPartsNo' })} />
    </Form>
}

const Step3RemoveOrder = ({ removeOrder, setRemoveOrder }: {
    removeOrder: {orderNo: string},
    setRemoveOrder: React.Dispatch<SetStateAction<{orderNo: string}>>
}) => {
    const intl = useIntl()

    return <Form data={removeOrder} setData={setRemoveOrder} labelDisplay="block" helperDisplay="tooltip" >
        <StringItem field="orderNo" label={intl.formatMessage({ id: 'field.orderNo' })} placeholder={intl.formatMessage({ id: 'removeOrderPlaceHolder' })} />
    </Form>
}

export const DownloadContractPartsAction = ({ contractParts }: { contractParts: ContractFactor }) => {
    const downloadConractparts = useDownloadContractPartsMasterByFilterAll()
    const downloadParts = useCallback(() => {
        contractParts ? downloadConractparts(Objects.delete(contractParts, 'page')) : downloadConractparts({})
    }, [contractParts, downloadConractparts])

    return <DownloadCallbackViewAction outlined callback={downloadParts} />
}

const DownloadPOBalanceAction = ({ pOBalance }: { pOBalance: POBalanceFactor }) => {
    const downloadPOBalance = useDownloadPOBalanceByFilter()
    const download = useCallback(() => {
        downloadPOBalance(pOBalance)
    }, [downloadPOBalance, pOBalance])
    return <DownloadCallbackViewAction outlined callback={download} />
}

const DeleteOrderAction = (param: { orderNo: String }) => {
    const removeOrder = useRemoveExistsOrder()
    const remove = useCallback(() => {
        removeOrder(param)
    }, [param, removeOrder])
    return <DeleteCallbackToolbarAction callback={remove} />
}


const UploadContractPartsAction = () => {
    const uploadContractParts = useUploadMasterContractPartsMaster()

    const isPowerUser = useIsPowerUser()
    const dispatch = useDispatch()
    const uploadMethod = useCallback((files: FileList | null) => {
        if (files === null) {
            dispatch(applicationActions.pushError({ title: { code: 'upload' }, messages: { code: 'w0342' } }))
            return
        }
        // only power user can do
        if (!isPowerUser) {
            dispatch(applicationActions.pushError({ title: { code: 'upload' }, messages: { code: 'w0592' } }))
        }
        uploadContractParts({ file: files[0] }, { serialized: true })
    }, [dispatch, isPowerUser, uploadContractParts])

    return <UploadCallbackViewAction callback={uploadMethod} />
}


const UploadPOBalanceAction = () => {
    const uploadoBalance = useUploadPNAPoBalanceFile()

    const isPowerUser = useIsPowerUser()
    const dispatch = useDispatch()
    const uploadMethod = useCallback((files: FileList | null) => {
        if (files === null) {
            dispatch(applicationActions.pushError({ title: { code: 'upload' }, messages: { code: 'w0342' } }))
            return
        }
        // only power user can do
        if (!isPowerUser) {
            dispatch(applicationActions.pushError({ title: { code: 'upload' }, messages: { code: 'w0592' } }))
        }
        uploadoBalance({ file: files[0] }, { serialized: true })
    }, [dispatch, isPowerUser, uploadoBalance])

    return <UploadCallbackViewAction callback={uploadMethod} />
}

