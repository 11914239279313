import { useCallback, useEffect, useMemo, useState } from "react"
import { useGetGrouping } from "../../../services/smt/api/CustomerUsageApi"
import { useGetInOutPartsListPageMapa } from "../../../services/smt/api/StockManagementMasterApi"
import { alarmUsagePatternViewFromson } from "../../../services/smt/models/AlarmUsagePatternView"
import { StockManagementMasterTransfer } from "../../../services/smt/models/StockManagementMasterTransfer"
import { StockManagementMasterView, stockManagementMasterViewToJson } from "../../../services/smt/models/StockMangementMasterView"
import { IncomingDownloadFactor } from "../../../services/smt/stockManagement/models/IncomingDownloadFactor"
import sessionKeys from "../../../utils/sessionKeys"
import { SMGTS710MAPAPcUi } from "./SMGTS710MAPAPcUi"

const defaultPartsFilters = { page: { currentPage: 0, pageSize: 1000, } }
const searchCachekey = sessionKeys.Filters_SMGTS710MAPA

export const SMGTS710MAPA = () => {
    const [filters, setFilters] = useState<IncomingDownloadFactor>({})
    const [totalCount, setTotalCount] = useState<number>(0)
    const [groupings, setGroupings] = useState<string[]>([])
    const partsCacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? alarmUsagePatternViewFromson(JSON.parse(filters)) : null
    }, [])
    const [partsFilters, setPartsFilters] = useState<StockManagementMasterView>(partsCacheFilters ?? defaultPartsFilters)
    const [partsData, setPartsData] = useState<StockManagementMasterTransfer[]>([])
    const [partsSelections, setPartsSelections] = useState<number[]>([])
    const displayData = partsData.filter(m =>
        filters.groupings !== undefined && filters.groupings?.length > 0 && filters.buyerList !== undefined && filters.buyerList?.length > 0 ? filters.groupings?.includes(m.grouping ?? "") && filters.buyerList?.includes(m.buyerCode ?? "")
            : (filters.groupings === undefined || filters.groupings?.length === 0) && filters.buyerList !== undefined && filters.buyerList?.length > 0 ? filters.buyerList?.includes(m.buyerCode ?? "")
                : (filters.buyerList === undefined || filters.buyerList?.length === 0) && filters.groupings !== undefined && filters.groupings?.length > 0 ? filters.groupings?.includes(m.grouping ?? "")
                    : partsData
    )
    const getStockManagementAndOrderCalculationMasterPage = useGetInOutPartsListPageMapa()
    const getPageData = getStockManagementAndOrderCalculationMasterPage
    const searchParts = useCallback(() => {
        getPageData(partsFilters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount ?? 0)
            setPartsData(result?.data ?? [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(stockManagementMasterViewToJson(partsFilters)))
        })
    }, [getPageData, partsFilters])

    useEffect(() => {
        searchParts()
    }, [searchParts])

    const getGrouping = useGetGrouping()
    useEffect(() => {
        getGrouping(undefined, { silent: true, serialized: true }).then((result) => {
            setGroupings(result ?? [])
        })
    }, [getGrouping])

    return <SMGTS710MAPAPcUi
        filters={filters}
        totalCount={totalCount}
        setFilters={setFilters}
        groupings={groupings}
        partsData={partsData}
        partsSelections={partsSelections}
        setPartsSelections={setPartsSelections}
        displayData={displayData}
    />
}