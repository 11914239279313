import { Project } from "@rithe/utils"

export type OrderCalculationReviewBo = Required & Partial<Optional>;
export type OrderCalculationReviewBoJson = JsonRequired & Partial<JsonOptional>;

export const orderCalculationReviewBoToJson: Project<OrderCalculationReviewBo, OrderCalculationReviewBoJson> = obj => ({
    ...obj
})

export const orderCalculationReviewBoFromJson: Project<OrderCalculationReviewBoJson, OrderCalculationReviewBo> = json => ({
    ...json
})

interface Required extends JsonRequired { }

interface Optional extends JsonOptional {
}

interface JsonRequired {
}

interface JsonOptional {
    orderCalcNo: string,
    adjustmentReason: string,
    orderCalcPartsIds: number[],
}
