import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarLayout } from "@rithe/data-grid"
import { Records } from "@rithe/utils"
import { useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { UploadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/UploadGroupedToolbarAction"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { View } from "../../../components/View/View"
import { useUploadBUOrderChange } from "../../../services/integration/apis/changeCancelMapaUploadApis"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { useDownloadBUOrderChange } from "../../../services/order/apis/OrderDownloadApi"
import { BChangeOrderDetailResult } from "../../../services/order/models/BChangeOrderDetailResult"
import { BChangeRequestListResult } from "../../../services/order/models/BChangeRequestListResult"
import { ChangeRequestWithoutContractListResult } from "../../../services/order/models/ChangeRequestWithoutContractListResult"
import { useDispatch } from "react-redux"
import { applicationActions } from "../../Application/applicationSlice"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { useCancelBuOrder } from "../../../services/order/apis/SalesOrderApi"
import { useFunctionStore } from "../../../Root"
import { CancelCallbackRowAction } from "../../../components/DataGrid/rowActions/CancelCallbackRowAction"
import { PoStatus } from "../../../services/order/enums/PoStatus"

interface INT050PcUiProps {
    orderChangedableList: BChangeRequestListResult[],
    search: () => void,
    filters: ChangeRequestWithoutContractListResult
}

export interface ChangeOrderBasic extends BChangeOrderDetailResult {
    totalQty: number,
    totalAmount: number,
    currency?: string,
    totalNumberOfParts: number,
    deliveryPlanRange: string,
}


export const INT050PcUi = (props: INT050PcUiProps) => {
    const { orderChangedableList, search } = props

    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <OrderChangeUi orderChangedableList={orderChangedableList} search={search} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

interface OrderChangeUiProps {
    orderChangedableList: BChangeRequestListResult[],
    search: () => void,
}

const OrderChangeUi = (props: OrderChangeUiProps) => {
    return <OrderChangeDataTable {...props} />
}

const OrderChangeDataTable = ({ orderChangedableList, search }: OrderChangeUiProps) => {
    const [selections, setSelections] = useState<number[]>([])

    const intl = useIntl()
    const columns = useMemo(() => [
        { field: 'soNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.salesOrderNo' }), width: 300 },
        { field: 'poNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'purchaseOrderNo' }), width: 300 },
        { field: 'groupNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.groupNo' }), width: 300 },
        { field: 'buCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.buyerCode' }), width: 200 },
        { field: 'customerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerCode' }), width: 250 },
        { field: 'supplierCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierCode' }), width: 250 },
        { field: 'orderType', dataTypeName: CodeCategory.OrderType, title: intl.formatMessage({ id: 'field.orderType' }), width: 180 },
        { field: 'orderStatus', dataTypeName: CodeCategory.PoStatus, title: intl.formatMessage({ id: 'orderStatus' }), width: 180 },
    ], [intl])

    const getRowId = useCallback((row: any) => row.poId, [])
    const actionProps1 = useMemo(() => ({ search }), [search])
    const actionProps2 = useMemo(() => ({ selections, orderChangedableList }), [orderChangedableList, selections])
    const display = useCallback((tableRow: TableRow) => tableRow.row?.orderStatus === PoStatus.RECEIVED || tableRow.row?.orderStatus === PoStatus.CONFIRMED, [])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.BusinessType} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OrderFrequency} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OrderType} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.PoStatus} />
        <Data rows={orderChangedableList} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps2} />
        <ToolbarActionProvider Action={UploadAction} actionProps={actionProps1} />
        <RowActionProvider name="cancel" Action={CancelRowAction} actionProps={actionProps1} display={display} />
        <ColumnFreeze />
        <ColumnVisibility
            ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={180} />
    </DataGrid>
}


const DownloadAction = ({ selections, orderChangedableList }: { selections: number[], orderChangedableList: BChangeRequestListResult[] }) => {
    const downloadBUOrderChange = useDownloadBUOrderChange()
    const dispatch = useDispatch()
    const downloadData = orderChangedableList.filter(item => selections.includes(item.poId))[0]
    const download = useCallback(() => {
        if (selections.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'download' }, messages: { code: 'w0002' } }))
            return
        }
        if (selections.length >1) {
            dispatch(applicationActions.pushError({ title: { code: 'download' }, messages: { code: 'w0003' } }))
            return
        }
        downloadBUOrderChange(downloadData)
    }, [dispatch, downloadBUOrderChange, downloadData, selections.length])

    return <DownloadGroupedToolbarAction access="INT.INT050.DOWNLOAD">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="download" />} callback={download} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}

const UploadAction = ({ search }: { search: () => void }) => {
    const uploadMethod = useUploadBUOrderChange()
    const upload = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            files && uploadMethod({ file: files[0] }, { serialized: true }).then(() => search())
        })
    }, [search, uploadMethod])

    return <UploadGroupedToolbarAction>
        {(popupUpload, onClose) => <>
            <GroupedCallbackItem label={<FormattedMessage id="upload" />} callback={() => upload(popupUpload)} onClose={onClose} />
        </>}
    </UploadGroupedToolbarAction>
}


const CancelRowAction = ({ tableRow, search }: DataGridRowActionProps & { search: () => void }) => {
    const cancelOrder = useCancelBuOrder()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'cancel' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const callback = useCallback((tableRow: TableRow) => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            const poId = tableRow.row?.poId
            poId && cancelOrder({ poIdList: [poId] }, { serialized: true }).then(search).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [cancelOrder, dispatch, functionStore, search, title])
    return <CancelCallbackRowAction tableRow={tableRow} access="INT.INT050.CANCEL" callback={callback} disabled={disabled} />
}





