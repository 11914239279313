import { useCallback, useEffect, useMemo, useState } from "react"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { useGetBuyerByLoginUser, useGetSellerByLoginUser } from "../../../services/smt/api/ComboboxApi"
import { useGetOrderCalculationPage } from "../../../services/smt/api/OrderCalculationApi"
import { OrderCalculationTransfer } from "../../../services/smt/models/OrderCalculationTransfer"
import { OrderCalculationView, orderCalculationViewFromJson, orderCalculationViewToJson } from "../../../services/smt/models/OrderCalculationView"
import { Pair } from "../../../services/utils/Pair"
import { useGetCompanyType } from "../../../utils/ApplicationUtils"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { MSOGS010CustPcUi } from "./MSOGS010CustPcUi"
import { MSOGS010PcUi } from "./MSOGS010PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }

const searchCachekey = sessionKeys.Filters_MSOGS010

export const MSOGS010 = () => {

    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? orderCalculationViewFromJson(JSON.parse(filters)) : null
    }, [])

    const [filters, setFilters] = useState<OrderCalculationView>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<OrderCalculationTransfer[]>([])
    const [buyerList, setBuyerList] = useState<Pair[]>([])
    const [sellerList, setSellerList] = useState<Pair[]>([])
    const getBuyerList = useGetBuyerByLoginUser()
    const getSellerList = useGetSellerByLoginUser()
    const [totalCount, setTotalCount] = useState<number>(0)
    const getOrderCalculationPage = useGetOrderCalculationPage()
    const search = useCallback((filters: OrderCalculationView) => {
        getOrderCalculationPage(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(orderCalculationViewToJson(filters)))
        })
    }, [getOrderCalculationPage, setData])


    const cbdsType = useGetCompanyType()

    useEffect(() => {
        if (cbdsType === CbdsType.BU) {
            getBuyerList(undefined, { silent: true, serialized: true }).then((result) => {
                setBuyerList(result ?? [])
            })
        } else {
            getSellerList(undefined, { silent: true, serialized: true }).then((result) => {
                setSellerList(result ?? [])
            })
        }
    }, [cbdsType, getBuyerList, getSellerList, setSellerList])


    useSearchOnLoad(search, filters)


    if (CbdsType.CUST === cbdsType) {
        return <MSOGS010CustPcUi
            filters={filters}
            search={search}
            data={data}
            totalCount={totalCount}
            setFilters={setFilters}
            sellerList={sellerList}
        />
    } else {
        return <MSOGS010PcUi
            filters={filters}
            search={search}
            data={data}
            totalCount={totalCount}
            setFilters={setFilters}
            buyerList={buyerList}
        />
    }
}