import React, { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useComMasterGetActiveCbds } from "../../../services/common/apis/commonMasterApi"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { userQueryById } from "../../../services/delivery/apis/deliveryToDoListApi"
import { TnmUserToListResult } from "../../../services/delivery/models/TnmUserToListResult"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { useMatch } from "../../../utils/useMatch"
import { TDL011PcUi } from "./TDL011PcUi"

export const TDL011 = () => {
    const { path } = useMatch()
    const { todoId } = useParams() as any
    let mode
    if (path === '/todolist/edit-:todoId') {
        mode = ScreenMode.EDIT
    } else if (path === '/todolist/create') {
        mode = ScreenMode.CREATE
    } else {
        mode = ScreenMode.VIEW
    }
    const [companyList, setCompanyList] = useState<TnvCbds[]>([])
    const getActiveCbds = useComMasterGetActiveCbds()

    const [todolistInfo, setTodoListInfo] = useState<TnmUserToListResult>({} as any)

    const getTodoListDetail = userQueryById()

    const search = useCallback((todoId: number | null) => {
        if (todoId) {
            getTodoListDetail({ todoId }, { silent: true, serialized: true }).then(result => {
                setTodoListInfo(result ?? {} as any)
            })
        }
    }, [getTodoListDetail])
    useEffect(() => {
        if (todoId !== null && todoId !== undefined) {
            search(todoId)
        }
    }, [search, todoId])
    useEffect(() => {
        getActiveCbds({ types: [1, 2, 3, 4] }, { silent: true }).then(result => {
            setCompanyList(result || [])
        })
    }, [getActiveCbds, setCompanyList])

    return <TDL011PcUi

        todolistInfo={todolistInfo}
        setTodoListInfo={setTodoListInfo}
        search={search}
        mode={mode}
        companyList={companyList}
    />
}