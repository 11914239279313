import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { Records } from "@rithe/utils"
import React, { SetStateAction, memo, useCallback, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { ActiveFlagTypeProvider } from "../../../components/DataGrid/typeProviders/ActiveFlagTypeProvider"
import { useFindPartsByCondition } from "../../../services/master/apis/requestDetailApi"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { ContractRequestType } from "../../../services/master/enums/ContaractRequestType"
import { PartsDetailResult } from "../../../services/master/models/PartsDetailResult"

interface PartsListViewProps {
    requestType: ContractRequestType,
    requestTo?: string,
    selectedPartsList: string[],
    setSelectedList: React.Dispatch<React.SetStateAction<PartsDetailResult[]>>,
    keyIds?: string,
}

export const PartsListView = (props: PartsListViewProps) => {

    const { requestType, requestTo, selectedPartsList, setSelectedList, keyIds } = props
    const [partList, setPartList] = useState<PartsDetailResult[]>([])
    const [selections, setSelections] = useState<number[]>([])

    const getPartList = useFindPartsByCondition()
    useEffect(() => {
        getPartList({ requestType, requestTo, keyIds }, { silent: true }).then(result => {
            setPartList(result ? result.filter(parts => !selectedPartsList.includes(parts.unitPartsId + '-' + parts.contractNo)) : [])
        })
    }, [getPartList, keyIds, requestTo, requestType, selectedPartsList, setPartList])

    return <DataTable
        requestType={requestType}
        requestTo={requestTo}
        partList={partList}
        selections={selections}
        setSelections={setSelections}
        setSelectedList={setSelectedList}
    />
}

const DataTable = memo((props: {
    requestType: ContractRequestType,
    requestTo?: string,
    partList: PartsDetailResult[],
    selections: number[],
    setSelections: React.Dispatch<SetStateAction<number[]>>,
    setSelectedList: React.Dispatch<React.SetStateAction<PartsDetailResult[]>>,
}) => {
    const { requestType, selections, partList, setSelections, setSelectedList } = props
    const intl = useIntl()
    const columns = useMemo(() => {
        let columns = requestType === ContractRequestType.MODIFY_PRICE ? [
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
            { field: 'sellerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsNo' }), width: 200 },
            { field: 'contractNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contractNo' }), width: 220 },
            { field: 'unitPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsName' }), width: 300 },
            { field: 'partsRefNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsRefNo' }), width: 200 },
            { field: 'backNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.backNo' }), width: 150 },
            { field: 'pairedPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'pairedPartsNo' }), width: 200 },
            { field: 'activeFlag', dataTypeName: CodeCategory.ActiveFlag, title: intl.formatMessage({ id: 'activeFlag' }), width: 150 },
        ] : requestType === ContractRequestType.ADD ? [
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
            { field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsNo' }), width: 200 },
            { field: 'contractNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contractNo' }), width: 220 },
            { field: 'unitPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsName' }), width: 300 },
            { field: 'partsRefNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsRefNo' }), width: 200 },
            { field: 'backNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.backNo' }), width: 150 },
            { field: 'pairedPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'pairedPartsNo' }), width: 200 },
            { field: 'activeFlag', dataTypeName: CodeCategory.ActiveFlag, title: intl.formatMessage({ id: 'activeFlag' }), width: 150 },
        ] : [
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
            { field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsNo' }), width: 200 },
            { field: 'contractNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contractNo' }), width: 220 },
            { field: 'unitPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsName' }), width: 300 },
            { field: 'partsRefNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsRefNo' }), width: 200 },
            { field: 'backNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.backNo' }), width: 150 },
            { field: 'pairedPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'pairedPartsNo' }), width: 200 },
            { field: 'activeFlag', dataTypeName: CodeCategory.ActiveFlag, title: intl.formatMessage({ id: 'activeFlag' }), width: 150 },
        ]
        if (requestType === ContractRequestType.ADD) {
            columns.splice(2, 1)
        }
        return columns
    }, [intl, requestType])

    const onSelectionsChange = useCallback((selects: (string | number)[]) => {
        const indexs = selects as number[]
        setSelections(indexs)
        setSelectedList(partList.filter((_, idx) => indexs.includes(idx)))
    }, [partList, setSelectedList, setSelections])

    return <div style={{ width: '100%', height: 400 }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <ActiveFlagTypeProvider />
            <Data rows={partList} columns={columns} />
            <ColumnFreeze />
            <ColumnVisibility
                defaultHiddenFields={['partsRefNo', 'backNo', 'pairedPartsNo']}
                ToolbarButton={ColumnVisibilityToolbarButton}
            />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
            <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
            <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={onSelectionsChange} />
        </DataGrid>
    </div>
})
