import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useComMasterGetAllCompany, useComMasterGetAllRegion, useGetCalendarByActive, useGetSupplierSettingList, useGetZoneList } from "../../../services/common/apis/commonMasterApi"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { useGetSupplierDetailForEdit, useGetSupplierDetailForView } from "../../../services/master/apis/supplierApi"
import { SettingDetail } from "../../../services/master/models/SettingDetail"
import { TnmCompany } from "../../../services/master/models/TnmCompany"
import { TnmRegion } from "../../../services/master/models/TnmRegion"
import { TnmSupplierTemp } from "../../../services/master/models/TnmSupplierTemp"
import { useMatch } from "../../../utils/useMatch"
import { MLS041PcUi } from "./MLS041PcUi"


export const MLS041 = () => {
    const { supplierCode } = useParams() as any
    const { path } = useMatch()
    let mode = ScreenMode.VIEW
    if (path === '/supplier/edit-:supplierCode') {
        mode = ScreenMode.EDIT
    } else if (path === '/supplier/create') {
        mode = ScreenMode.CREATE
    }

    const [supplierInfo, setSupplierInfo] = useState<TnmSupplierTemp>({} as any)
    const [settings, setSettings] = useState<SettingDetail[]>([])
    const [zones, setZones] = useState<{ first: string, second: string }[]>([])
    const [regions, setRegions] = useState<TnmRegion[]>([])

    const [companyList, setCompanyList] = useState<TnmCompany[]>([])

    const getSupplierDetailForView = useGetSupplierDetailForView()
    const getSupplierDetailForEdit = useGetSupplierDetailForEdit()
    const getSupplierDetail = mode === ScreenMode.VIEW ? getSupplierDetailForView : getSupplierDetailForEdit
    const getSettingList = useGetSupplierSettingList()
    const search = useCallback(() => {
        getSupplierDetail({ supplierCode: supplierCode }, { silent: true, serialized: true }).then((result) => {
            setSupplierInfo(result?.basicInfo ?? {} as any)
            setSettings(result?.settings ?? [])
        })
    }, [getSupplierDetail, supplierCode])

    useEffect(() => {
        if (supplierCode !== null && supplierCode !== undefined) {
            search()
        } else if (mode === ScreenMode.CREATE) {
            getSettingList(undefined, { silent: true, serialized: true }).then(result => {
                setSettings(result?.map(setting => ({
                    settingCode: setting.settingCode,
                    description: setting.description,
                    valueType: setting.valueType,
                })) ?? [])
            })
        }
    }, [supplierCode, search, mode, getSettingList])

    const getZoneList = useGetZoneList()
    const getRegionList = useComMasterGetAllRegion()
    const getCalendarList = useGetCalendarByActive()
    // const getCompanyList = useComMasterGetAllCompanyByActive()
    const getCompanyList = useComMasterGetAllCompany()
    useEffect(() => {
        getZoneList(undefined, { silent: true }).then(result => {
            setZones(result || [])
        })
        getRegionList(undefined, { silent: true }).then(result => {
            setRegions(result || [])
        })

        getCompanyList(undefined, { silent: true }).then(result => {
            setCompanyList(result || [])
        })
    }, [getRegionList, getZoneList, getCalendarList, getCompanyList])


    return <MLS041PcUi
        supplierInfo={supplierInfo}
        setSupplierInfo={setSupplierInfo}
        settings={settings}
        setSettings={setSettings}
        search={search}
        supplierCode={supplierCode}

        zones={zones}
        regions={regions}
        companyList={companyList}
        mode={mode}
    />
}