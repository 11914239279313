import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { PurchaseOrderListFactor, purchaseOrderListFactorToJson } from "../models/PurchaseOrderListFactor"
import { SpotOrderListResult, spotOrderListResultFromJson } from "../models/SpotOrderListResult"

export const useGetPoSpotListByPage = () => {
    const url = '/lcbm-spot-order-api/order/po/api/getPoSpotListPage'
    const payloadProject = useCallback(purchaseOrderListFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(spotOrderListResultFromJson), [])
    return usePost<PurchaseOrderListFactor, PageResultEntity<SpotOrderListResult>>(url, payloadProject, resultProject)
}

