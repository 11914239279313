import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { PageViewEntity, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"


export type CreditCheckInfoFactor = Required & Partial<Optional>
type CreditCheckInfoResultJson = JsonRequired & Partial<JsonOptional>
export const creditCheckInfoFactorToJson: Project<CreditCheckInfoFactor, CreditCheckInfoResultJson> = obj => ({
    ...obj,
    createdDateStart: dateToJson(obj.createdDateStart),
    createdDateEnd: dateToJson(obj.createdDateEnd),
    updatedDateStart: dateToJson(obj.updatedDateStart),
    updatedDateEnd: dateToJson(obj.updatedDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page)
})


interface Required extends JsonRequired {

}

interface Optional extends Omit<JsonOptional, 'createdDateStart' | 'createdDateEnd' | 'updatedDateStart' | 'updatedDateEnd'> {
    createdDateStart: Date,
    createdDateEnd: Date,
    updatedDateStart: Date,
    updatedDateEnd: Date,
    page: PageViewEntity,
}

interface JsonRequired {

}

interface JsonOptional {
    creditId: number,
    creditRuleNo: string,
    openArFlag: number,
    openArRange: number,
    openArFlagList: number[],
    unvoicedOrderFlag: number,
    unvoicedOrderTargetRange: number,
    unvoicedOrderFlagList: number[],
    statusList: number[],
    updatedBy: string,
    createdDateStart: string,
    createdDateEnd: string,
    updatedDateStart: string,
    updatedDateEnd: string,
    version: number,
    page: PageViewEntityJson
}