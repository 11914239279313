import { Project } from "@rithe/utils"

export type FavouritePageAPIRequest = ResultRequired & Partial<ResultOptional>
type FavouritePageAPIRequestJson = ResultJsonRequired & Partial<ResultJsonOptional>
export const favouritePageAPIRequestFromJson: Project<FavouritePageAPIRequestJson, FavouritePageAPIRequest> = json => ({
    ...json,
})

export const favouritePageAPIRequestToJson: Project<FavouritePageAPIRequest, FavouritePageAPIRequestJson> = obj => ({
    ...obj,
})


interface ResultRequired extends ResultJsonRequired {

}

interface ResultOptional extends ResultJsonOptional {

}

interface ResultJsonRequired {
}

interface ResultJsonOptional {
    pageCode: string,
    resourceCode: string,
}
