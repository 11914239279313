import {Project} from "../../../utils/Project"

export type UserMfaResult = Partial<Optional>
export type UserMfaResultJson = Partial<JsonOptional>
export const userMfaResultToJson: Project<UserMfaResult, UserMfaResultJson> = obj => ({
  ...obj,
  recovers: obj.recovers ? obj.recovers.map(m => userMfaRecoverListResultToJson(m)) : [],
})
export const userMfaResultFromJson: Project<UserMfaResultJson, UserMfaResult> = json => ({
  ...json,
  recovers: json.recovers ? json.recovers.map(m => userMfaRecoverListResultFromJson(m)) : [],
})

interface Optional {
    isEnable: number,
    recovers: UserMfaRecoverList[]
}
interface JsonOptional {
    isEnable: number,
    recovers: UserMfaRecoverListJson[]                
}

export type UserMfaRecoverList = Partial<UserMfaRecoverListOptional>
type UserMfaRecoverListJson = Partial<UserMfaRecoverListJsonOptional>
const userMfaRecoverListResultFromJson: Project<UserMfaRecoverListJson, UserMfaRecoverList> = json => ({
    ...json,
})
const userMfaRecoverListResultToJson: Project<UserMfaRecoverList, UserMfaRecoverListJson> = obj => ({
    ...obj,
})
interface UserMfaRecoverListOptional extends UserMfaRecoverListJsonOptional {

}
interface UserMfaRecoverListJsonOptional {
    recoverKey: string,
    isUsed: number,
}


















