import { Project } from "../../../utils/Project"
import { TableEntity, TableEntityJson } from "../../utils/TableEntity"

export type DeliveryPlanInitResult = Required & Partial<Optional> & TableEntity
type DeliveryPlanInitResultJson = JsonRequired & Partial<JsonOptional> & TableEntityJson

export const deliveryPlanInitResultFromJson: Project<DeliveryPlanInitResultJson, DeliveryPlanInitResult> = json => ({
    supplierCodeList: json.supplierCodeList?.map(m => [m.first, m.second]),
    supplierContraList: json.supplierContraList?.map(m => [m.first, m.second]),
    receiverList: json.receiverList?.map(m => [m.first, m.second]),
    customerCodeList: json.customerCodeList?.map(m => [m.first, m.second]),
})

interface Required extends JsonRequired {
}

interface Optional {
    supplierCodeList: [number, string][],
    supplierContraList: [number, string][],
    receiverList: [string, string][],
    customerCodeList: [number, string][],
}

interface JsonRequired {
}

interface JsonOptional {
    supplierCodeList: { first: number, second: string }[],
    supplierContraList: { first: number, second: string }[],
    receiverList: { first: string, second: string }[],
    customerCodeList: { first: number, second: string }[],
}