import { useCallback, useMemo } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { Access } from "../../../components/Access/Access"
import { ViewPanel } from "../../../components/View/Tab/ViewPanel"
import { ViewPanels } from "../../../components/View/Tab/ViewPanels"
import { ViewTab } from "../../../components/View/Tab/ViewTab"
import { ViewTabControl } from "../../../components/View/Tab/ViewTabControl"
import { ViewTabs } from "../../../components/View/Tab/ViewTabs"
import { View } from "../../../components/View/View"
import { useMatch } from "../../../utils/useMatch"
import { useApplicationSelector } from "../../Application/applicationSlice"
import { OCS020 } from "../OCS020/OCS020"
import { ONS010RegularTabContent } from "./ONS010RegularTabContent"
import { ONS010SpotTabContent } from "./ONS010SpotTabContent"
import { TabId, ViewTabList } from "./Records"

export const ONS010 = () => {
    const intl = useIntl()
    const { path } = useMatch()
    const navigate = useNavigate()
    const currentTabId = useMemo(() => prepareTabIdByPath(path), [path])
    const onSelectedChange = useCallback((value: string | number) => {
        navigate(prepareUrlbyTabId(value))
    }, [navigate])

    const resources = useApplicationSelector(state => state.auth.accessResources)
    const activeViewTabList = ViewTabList.filter(f => resources?.includes(f.access))

    if (currentTabId === TabId.ONS010) {
        navigate(activeViewTabList[0].navigation)
        return <></>
    } else {
        return <View flex>
            <ViewTabControl>
                <ViewTabs selectedValue={currentTabId} onSelectedValueChange={onSelectedChange}>
                    {activeViewTabList.map(activeViewTab => {
                        return <ViewTab key={activeViewTab.tabId} label={intl.formatMessage({ id: activeViewTab.label })} value={activeViewTab.tabId} />
                    })}
                </ViewTabs>
                <ViewPanels>
                    {currentTabId === TabId.Regular
                        ? <ViewPanel value={TabId.Regular}>
                            <Access access="ORDER.ONS010REGULAR.VIEW"><ONS010RegularTabContent /></Access>
                        </ViewPanel> : <></>
                    }
                    {currentTabId === TabId.Spot
                        ? <ViewPanel value={TabId.Spot}>
                            <Access access="ORDER.ONS010SPOT.VIEW"><ONS010SpotTabContent /></Access>
                        </ViewPanel> : <></>
                    }
                    {currentTabId === TabId.SpotWithoutContract
                        ? <ViewPanel value={TabId.SpotWithoutContract}>
                            <Access access="ORDER.ONS010SOWC.VIEW"><OCS020 /></Access>
                        </ViewPanel> : <></>
                    }
                </ViewPanels>
            </ViewTabControl>
        </View>
    }
}

const prepareUrlbyTabId = (id: any) => {
    switch (id) {
        case TabId.Regular:
            return "/placecustorder-regular"
        case TabId.Spot:
            return "/placecustorder-spot"
        case TabId.SpotWithoutContract:
            return "/placecustorder-sowc"
        default:
            return "/placecustorder-regular"
    }
}
const prepareTabIdByPath = (path: any) => {
    switch (path) {
        case "/placecustorder-regular":
            return TabId.Regular;
        case "/placecustorder-spot":
            return TabId.Spot;
        case "/placecustorder-sowc":
            return TabId.SpotWithoutContract;
        default:
            return TabId.ONS010;
    }
}