import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { OrderFrequency } from "../enums/OrderFrequency"
import { OrderType } from "../enums/OrderType"
import { RequestStatus } from "../enums/RequestStatus"
import { RequestType } from "../enums/RequestType"

export type CCRequestListResult = Required & Partial<Optional>
type CCRequestListResultJson = JsonRequired & Partial<JsonOptional>
export const cCRequestListResultFromJson: Project<CCRequestListResultJson, CCRequestListResult> = json => ({
    ...json,
    handledDate: dateFromJson(json.handledDate),
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'handledDate'> {
    requestType: RequestType,
    requestStatus: RequestStatus,
    orderType: OrderType,
    orderFrequency: OrderFrequency,
    handledDate: Date,
}

interface JsonRequired {
    roSoId: number,
    dataFlag: number,
}

interface JsonOptional {
    roSoId: number,
    dataFlag: number,
    requestFrom: string,
    requestNo: string,
    requestType: number,
    requestStatus: number,
    customerOrderNo: string,
    orderVersion: number,
    customerRefNo: string,
    salesOrderNo: string,
    customer: string,
    supplier: string,
    orderType: number,
    orderFrequency: number,
    contractNo: string,
    contractRouteNo: string,
    handledBy: string,
    handledDate: string,
    soId: number
}
