import { Project } from "../../../utils/Project"
import { dateFromJson, datetimeFromJson } from "../../utils/deserializer"
import { datetimeToJson, dateToJson } from "../../utils/serializer"
import { TnvCbds, tnvCbdsFromJson, TnvCbdsJson, tnvCbdsToJson } from "./TnvCbds"

export type FOBSettingDetailResult = {
    isConfirm?: number,
    fobSettingInfo: FOBSettingInfo,
    details: FOBSettingDetail[],
    consigneeList?: TnvCbds[],
    ftaInvoiceList?: TnvCbds[],
    customClearanceInvoiceList?: TnvCbds[],
    deleteIdList?: number[]
}
type FOBSettingDetailResultJson = {
    isConfirm?: number,
    fobSettingInfo: FOBSettingInfoJson,
    details: FOBSettingDetailJson[],
    consigneeList?: TnvCbdsJson[],
    ftaInvoiceList?: TnvCbdsJson[],
    customClearanceInvoiceList?: TnvCbdsJson[],
    deleteIdList?: number[]
}
export const gtFOBSettingInfoResultFromJson: Project<FOBSettingDetailResultJson, FOBSettingDetailResult> = json => ({
    isConfirm: json?.isConfirm,
    fobSettingInfo: json.fobSettingInfo ? getFOBSettingDetailFromJson(json.fobSettingInfo) : {},
    details: json.details ? json.details.map(m => getFOBSettingDetailFromJson(m)) : [],
    consigneeList: json.consigneeList ? json.consigneeList.map(m => tnvCbdsFromJson(m)) : [],
    ftaInvoiceList: json.ftaInvoiceList ? json.ftaInvoiceList.map(m => tnvCbdsFromJson(m)) : [],
    customClearanceInvoiceList: json.customClearanceInvoiceList ? json.customClearanceInvoiceList.map(m => tnvCbdsFromJson(m)) : [],
})

export const fOBSettingInfoResultToJson: Project<FOBSettingDetailResult, FOBSettingDetailResultJson> = obj => ({
    isConfirm: obj?.isConfirm,
    fobSettingInfo: obj.fobSettingInfo ? fOBSettingInfoToJson(obj.fobSettingInfo) : {},
    details: obj.details ? obj.details.map(fOBSettingDetailToJson) : [],
    consigneeList: obj.consigneeList ? obj.consigneeList.map(tnvCbdsToJson) : [],
    ftaInvoiceList: obj.ftaInvoiceList ? obj.ftaInvoiceList.map(tnvCbdsToJson) : [],
    customClearanceInvoiceList: obj.customClearanceInvoiceList ? obj.customClearanceInvoiceList.map(tnvCbdsToJson) : [],
    deleteIdList: obj.deleteIdList

})

export type FOBSettingInfo = InfoRequired & Partial<InfoOptional>
type FOBSettingInfoJson = InfoJsonRequired & Partial<InfoJsonOptional>
export const fOBSettingInfoToJson: Project<FOBSettingInfo, FOBSettingInfoJson> = obj => ({
    ...obj,
    createdDate: datetimeToJson(obj.createdDate),
    updatedDate: datetimeToJson(obj.updatedDate),
})
interface InfoRequired extends InfoJsonRequired {
}

interface InfoOptional extends Omit<InfoJsonOptional, 'createdDate' | 'updatedDate'> {
    createdDate: Date,
    updatedDate: Date,
}

interface InfoJsonRequired {
}

interface InfoJsonOptional {
    fobSettingId: number,
    invCurrencyCode: string,
    contrRouteId: number,
    consignee: number,
    ftaInvoice: number,
    customClearanceInvoice: number,
    createdBy: string,
    createdDate: number,
    updatedBy: string,
    updatedDate: number,
    version: number,
}

export type FOBSettingDetail = DetailRequired & Partial<DetailOptional>
type FOBSettingDetailJson = DetailJsonRequired & Partial<DetailJsonOptional>
const getFOBSettingDetailFromJson: Project<FOBSettingDetailJson, FOBSettingDetail> = json => ({
    ...json,
    effectiveStartDate: dateFromJson(json.effectiveStartDate),
    effectiveEndDate: dateFromJson(json.effectiveEndDate),
    createdDate: datetimeFromJson(json.createdDate),
    updatedDate: datetimeFromJson(json.updatedDate),
})

const fOBSettingDetailToJson: Project<FOBSettingDetail, FOBSettingDetailJson> = obj => ({
    ...obj,
    effectiveStartDate: dateToJson(obj.effectiveStartDate),
    effectiveEndDate: dateToJson(obj.effectiveEndDate),
    createdDate: datetimeToJson(obj.createdDate),
    updatedDate: datetimeToJson(obj.updatedDate),
})

interface DetailRequired extends DetailJsonRequired {

}

interface DetailOptional extends Omit<DetailJsonOptional, 'effectiveStartDate' | 'effectiveEndDate' | 'createdDate' | 'updatedDate'> {
    effectiveStartDate: Date,
    effectiveEndDate: Date,
    createdDate: Date,
    updatedDate: Date,
}

interface DetailJsonRequired {

}

interface DetailJsonOptional {
    fobSettingDetailId: number,
    fobSettingId: number,
    ftaExchangeRate: number,
    customExchangeRate: number,
    effectiveStartDate: string,
    effectiveEndDate: string,
    fobInvValueLogic: number,
    remark: string,
    createdBy: string,
    createdDate: number,
    updatedBy: string,
    updatedDate: number,
    version: number,
}