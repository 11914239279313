import moment from "moment"
import {useCallback} from "react"
import {IntlString} from "../../../utils/IntlString"
import {Projects} from "../../../utils/Projects"
import {useUpload} from "../../../utils/fetch/useFetch"
import {DownloadFiledsFactor} from "../models/DownloadFiledsFactor"
import {OCPlaceRegularOrderResult, ocPlaceRegularOrderResultDeserializer} from "../models/OCPlaceOrderDetailResult"
import {OrderCalculationUpdateResult, orderCalculationUpdateResultFromJson} from "../models/OrderCalculationUpdateResult"
import {ProjectPlanFactor} from "../models/ProjectPlanFactor"

//CSUGS120-------------------------
export const useUploadDirectInput_usageMonthly = () => {
    const url = '/smt-api/usage/usageMonthly'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File, isConfirm?: boolean }, undefined>(url, payloadProject, resultProject)
}

export const useUploadDirectInput_usageWeekly = () => {
    const url = '/smt-api/usage/usageWeekly'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File, isConfirm?: boolean }, undefined>(url, payloadProject, resultProject)
}

export const useUploadDirectInput_usageDaily = () => {
    const url = '/smt-api/usage/usageDaily'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = useCallback((m: IntlString[]) => m, [])
    return useUpload<{ file: File, isConfirm?: boolean }, IntlString[]>(url, payloadProject, resultProject)
}
//-------------------------------------

//MSOGF010  ---TODO
export const useUploadOrderCalcGrouping = () => {
    const url = '/smt-api/ordermng/uploadOrderGroup'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

//MSOGF020  ---TODO
export const useUploadOrderCalcGroupingForEhm = () => {
    const url = '/smt-api/ordermng/uploadOrderGroupForEhm'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

//MSAUF011
export const useUploadAlarmUsagePattern = () => {
    const url = '/smt-api/ordermng/uploadOrderCalculationSetting'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = useCallback(orderCalculationUpdateResultFromJson, [])
    return useUpload<{ file: File }, OrderCalculationUpdateResult>(url, payloadProject, resultProject)
}


//SMGTS310 
export const useUploadCustomerStock = () => {
    const url = '/smt-api/stockmng/uploadCustomerStock'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

//SMGTS410 
export const useUploadImpStockAdjust = () => {
    const url = '/smt-api/stockmng/uploadStockAdjustment'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

//CSUGS131
export const useUploadBom = () => {
    const url = '/smt-api/project/uploadBom'
    const payloadProject = useCallback((obj: { file: File } & DownloadFiledsFactor) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File } & DownloadFiledsFactor, undefined>(url, payloadProject, resultProject)
}

export const useUploadBomMaterial = () => {
    //const url = '/smt-api/project/upload/uploadBomMaterial';
    const url = '/smt-api/project/uploadBom'
    const payloadProject = useCallback((obj: { file: File } & DownloadFiledsFactor) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File } & DownloadFiledsFactor, undefined>(url, payloadProject, resultProject)
}

export const useUploadBillOfMaterial = () => {
    const url = '/smt-api/project/uploadUsage'
    const payloadProject = useCallback((obj: { file: File } & { projectId: number }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File, projectId: number }, undefined>(url, payloadProject, resultProject)
}

export const useUploadProduction = () => {
    const url = '/smt-api/prodplan/uploadPlan'
    const payloadProject = useCallback((obj: { file: File } & ProjectPlanFactor) => ({
        ...obj,
        yearMonthFrom: obj.yearMonthFrom ? moment(obj.yearMonthFrom).format('YYYYMM') : undefined,
        yearMonthTo: obj.yearMonthTo ? moment(obj.yearMonthTo).format('YYYYMM') : undefined,
    }), [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File } & ProjectPlanFactor, undefined>(url, payloadProject, resultProject)
}

//OCCLS010 
export const useUploadOrderCalculation = () => {
    const url = '/smt-api/ordermng/uploadOrderCalculation'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useUploadOrderCalculationForPNA = () => {
    const url = '/smt-api/ordermng/upload/uploadOrderCalForPNA';
    const payloadProject = useCallback((obj: { file: File, isConfirm?: number }) => obj, []);
    const resultProject = Projects.IGNORE;
    return useUpload<{ file: File, isConfirm?: number }, undefined>(url, payloadProject, resultProject);
}

/** OCCLS015 */
export const useUploadRegularOrderFormListForCustomer = () => {
    const url = '/smt-api/ordermng/uploadPlaceOrderRegular'
    const payloadProject = useCallback((obj: { file: File } & { poGroupId: string }) => obj, [])
    const resultProject = useCallback(Projects.array(ocPlaceRegularOrderResultDeserializer), [])
    return useUpload<{ file: File, poGroupId: string }, OCPlaceRegularOrderResult[]>(url, payloadProject, resultProject)
}

/** OCCLS015 */
export const useUploadRegularOrderFormListForCustomerPNA = () => {
    const url = '/smt-api/ordermng/uploadPlaceOrderRegularForPNA';
    const payloadProject = useCallback((obj: { file: File } & { poGroupId: string }) => obj, []);
    const resultProject = useCallback(Projects.array(ocPlaceRegularOrderResultDeserializer), []);
    return useUpload<{ file: File, poGroupId: string }, OCPlaceRegularOrderResult[]>(url, payloadProject, resultProject);
}

/** OCCLS015 */
export const useUploadRegularOrderFormListForCustomerSMT = () => {
    const url = '/smt-api/ordermng/uploadPlaceOrderRegularForSMT';
    const payloadProject = useCallback((obj: { file: File } & { poGroupId: string }) => obj, []);
    const resultProject = useCallback(Projects.array(ocPlaceRegularOrderResultDeserializer), []);
    return useUpload<{ file: File, poGroupId: string }, OCPlaceRegularOrderResult[]>(url, payloadProject, resultProject);
}

/** OCCLS017 */
export const useUploadSpotOrderFormListForCustomer = () => {
    const url = '/smt-api/ordermng/uploadPlaceOrderSpot'
    const payloadProject = useCallback((obj: { file: File } & { poGroupId: string }) => obj, [])
    const resultProject = useCallback(Projects.array(ocPlaceRegularOrderResultDeserializer), [])
    return useUpload<{ file: File, poGroupId: string }, OCPlaceRegularOrderResult[]>(url, payloadProject, resultProject)
}

export const useUploadSpotOrderFormListForCustomerPNA = () => {
    const url = '/smt-api/ordermng/uploadPlaceOrderSpotForPNA';
    const payloadProject = useCallback((obj: { file: File } & { poGroupId: string }) => obj, []);
    const resultProject = useCallback(Projects.array(ocPlaceRegularOrderResultDeserializer), []);
    return useUpload<{ file: File, poGroupId: string }, OCPlaceRegularOrderResult[]>(url, payloadProject, resultProject);
}

export const useUploadSpotOrderFormListForCustomerSMT = () => {
    const url = '/smt-api/ordermng/uploadPlaceOrderSpotForSMT';
    const payloadProject = useCallback((obj: { file: File } & { poGroupId: string }) => obj, []);
    const resultProject = useCallback(Projects.array(ocPlaceRegularOrderResultDeserializer), []);
    return useUpload<{ file: File, poGroupId: string }, OCPlaceRegularOrderResult[]>(url, payloadProject, resultProject);
}

//CSUGF140
export const useUploadActualUsage = () => {
    const url = '/smt-api/usage/uploadActualUsage'
    const payloadProject = useCallback((obj: { file: File, isConfirm?: boolean }) => obj, [])
    const resultProject = useCallback((m: IntlString[]) => m, [])
    return useUpload<{ file: File, isConfirm?: boolean }, IntlString[]>(url, payloadProject, resultProject)
}

export const useUploadCustActualUsage = () => {
    const url = '/smt-api/usage/uploadCustActualUsage'
    const payloadProject = useCallback((obj: { file: File, isConfirm?: boolean }) => obj, [])
    const resultProject = useCallback((m: IntlString[]) => m, [])
    return useUpload<{ file: File, isConfirm?: boolean }, IntlString[]>(url, payloadProject, resultProject)
}

//CSUGF150
export const useUploadWIPStockAdjust = () => {
    const url = '/smt-api/usage/uploadWIPStockAdjust'
    const payloadProject = useCallback((obj: { file: File, isConfirm?: boolean }) => obj, [])
    const resultProject = useCallback((result: IntlString[]) => result, [])
    return useUpload<{ file: File, isConfirm?: boolean }, IntlString[]>(url, payloadProject, resultProject)
}

/** MSAUF020 */
export const useUploadStockManagementMaster = () => {
    const url = '/smt-api/stockManagementMaster/uploadStockManagementMaster' 
    const payloadProject = useCallback((obj: { file: File, isConfirm?: boolean }) => obj, [])
    const resultProject = useCallback((m: { messages: IntlString[], warningFlag: number }) => m, []);
    return useUpload<{ file: File, isConfirm?: boolean }, { messages: IntlString[], warningFlag: number }>(url, payloadProject, resultProject)
}
export const useUploadOrderCalculationMaster = () => {
    const url = '/smt-api/stockManagementMaster/uploadOrderCalculationMaster'
    const payloadProject = useCallback((obj: { file: File, isConfirm?: boolean }) => obj, [])
    const resultProject = useCallback((m: { messages: IntlString[], warningFlag: number }) => m, []);
    return useUpload<{ file: File, isConfirm?: boolean }, { messages: IntlString[], warningFlag: number }>(url, payloadProject, resultProject)
}
export const useUploadStockManagementAndOrderCalculationMaster = () => {
    const url = '/smt-api/stockManagementMaster/uploadStockManagementAndOrderCalculationMaster'
    const payloadProject = useCallback((obj: { file: File, isConfirm?: boolean }) => obj, [])
    const resultProject = useCallback((m: { messages: IntlString[], warningFlag: number }) => m, []);
    return useUpload<{ file: File, isConfirm?: boolean }, { messages: IntlString[], warningFlag: number }>(url, payloadProject, resultProject)
}

/** MSAUF020 */
export const useUploadIncoming = () => {
    const url = '/smt-api/moving/uploadIncoming'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useUploadIncomingMapa = () => {
    const url = '/smt-api/moving/uploadIncomingMapa'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = useCallback((rs: IntlString[]) => rs, [])
    return useUpload<{ file: File }, IntlString[]>(url, payloadProject, resultProject)
}

/** MSAUF020 */
export const useUploadOutbound = () => {
    const url = '/smt-api/moving/uploadOutbound'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useUploadOutboundMapa = () => {
    const url = '/smt-api/moving/uploadOutboundMapa'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}