import { Route, Routes } from "react-router-dom"
import { AuthNavigate } from "../components/AuthNavigate/AuthNavigate"
import { AIS010 } from "../layouts/accounting/AIS010/AIS010"
import { AIS011 } from "../layouts/accounting/AIS011/AIS011"
import { AIS020 } from "../layouts/accounting/AIS020/AIS020"
import { AIS021 } from "../layouts/accounting/AIS021/AIS021"
import { AIS060 } from "../layouts/accounting/AIS060/AIS060"
import { NotFound } from "../layouts/NotFound/NotFound"

export function AccountingRoutes() {
    return <Routes>
        <Route path="/gr/view-:invoiceId" element={<AuthNavigate access="ACCT.AIS011.VIEW"><AIS011 /></AuthNavigate>} />,
        <Route path="/gr" element={<AuthNavigate access="ACCT.AIS010.VIEW"><AIS010 /></AuthNavigate>} />,
        <Route path="/gi/view-:invoiceId" element={<AuthNavigate access="ACCT.AIS021.VIEW"><AIS021 /></AuthNavigate>} />,
        <Route path="/gi/edit-:invoiceId" element={<AuthNavigate access="ACCT.AIS021.VIEW"><AIS021 /></AuthNavigate>} />,
        <Route path="/gi" element={<AuthNavigate access="ACCT.AIS020.VIEW"><AIS020 /></AuthNavigate>} />,
        <Route path="/ar/view-:invoiceId" element={<AuthNavigate access="ACCT.AIS021.VIEW"><AIS021 /></AuthNavigate>} />,
        <Route path="/ar" element={<AuthNavigate access="ACCT.AIS060.VIEW"><AIS060 /></AuthNavigate>} />,
        <Route path="/ap/view-:invoiceId" element={<AuthNavigate access="ACCT.AIS021.VIEW"><AIS021 /></AuthNavigate>} />,
        <Route path="/ap" element={<AuthNavigate access="ACCT.AIS060.VIEW"><AIS060 /></AuthNavigate>} />,
        <Route path="*" element={<NotFound scope="accounting"/>} />,
    </Routes>
}