import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, RowActionProvider, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { Records } from "@rithe/utils"
import { useCallback, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CancelCallbackRowAction } from "../../../components/DataGrid/rowActions/CancelCallbackRowAction"
import { CreateRedirectRowAction } from "../../../components/DataGrid/rowActions/CreateRedirectRowAction"
import { DeleteCallbackRowAction } from "../../../components/DataGrid/rowActions/DeleteCallbackRowAction"
import { EditRedirectRowAction } from "../../../components/DataGrid/rowActions/EditRedirectRowAction"
import { IssueCallbackRowAction } from "../../../components/DataGrid/rowActions/IssueCallbackRowAction"
import { ViewRedirectRowAction } from "../../../components/DataGrid/rowActions/ViewRedirectRowAction"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { View } from "../../../components/View/View"
import { useFunctionStore } from "../../../Root"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { useCancelSuppOrder, useDeleteChangeSuppOrder, useIssueChangeSuppOrder } from "../../../services/order/apis/ChangeRequestApi"
import { CoStatus } from "../../../services/order/enums/CoStatus"
import { RoStatus } from "../../../services/order/enums/RoStatus"
import { ChangeRequestWithoutContractListResult } from "../../../services/order/models/ChangeRequestWithoutContractListResult"
import { SChangeRequestListResult } from "../../../services/order/models/SChangeRequestListResult"
import { formatDateRange } from "../../../utils/formatDateRange"
import { applicationActions } from "../../Application/applicationSlice"

interface ORS020PcUiProps {
    orderChangedableList: SChangeRequestListResult[],
    searchOrderChangedableList: () => void,
    pnaCode?: string,
    filters: ChangeRequestWithoutContractListResult
}

export const ORS020PcUi = (props: ORS020PcUiProps) => {
    const { orderChangedableList, searchOrderChangedableList, pnaCode } = props

    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <OrderChangeUi orderChangedableList={orderChangedableList} searchOrderChangedableList={searchOrderChangedableList} pnaCode={pnaCode} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

interface OrderChangeUiProps {
    orderChangedableList: SChangeRequestListResult[],
    searchOrderChangedableList: () => void,
    pnaCode?: string
}

const OrderChangeUi = (props: OrderChangeUiProps) => {
    return <OrderChangeDataTable {...props} />
}

const OrderChangeDataTable = ({ orderChangedableList, searchOrderChangedableList, pnaCode }: OrderChangeUiProps) => {
    const intl = useIntl()
    const getOrderDateRange = useCallback((row: any) => formatDateRange(intl, row.orderFirstDate, row.orderLastDate), [intl])
    const getTargetDateRange = useCallback((row: any) => formatDateRange(intl, row.targetFirstDate, row.targetLastDate), [intl])
    const columns = useMemo(() => [
        { field: 'purchaseOrderNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'purchaseOrderNo' }), width: 300 },
        { field: 'soNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.salesOrderNo' }), width: 300 },
        { field: 'customerRefNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerRefNo' }), width: 350 },
        { field: 'contractNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contractNo' }), width: 300 },
        { field: 'contractRouteCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contrRouteCode' }), width: 300 },
        { field: 'buyerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.buyerCode' }), width: 200 },
        { field: 'sellerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sellerCode' }), width: 200 },
        { field: 'customerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerCode' }), width: 250 },
        { field: 'supplierCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierCode' }), width: 200 },
        { field: 'businessType', dataTypeName: CodeCategory.BusinessType, title: intl.formatMessage({ id: 'field.businessType' }), width: 200 },
        { field: 'orderFrequency', dataTypeName: CodeCategory.OrderFrequency, title: intl.formatMessage({ id: 'field.orderFrequency' }), width: 200 },
        { field: 'orderType', dataTypeName: CodeCategory.OrderType, title: intl.formatMessage({ id: 'field.orderType' }), width: 180 },
        { field: 'orderRange', dataTypeName: 'string', title: intl.formatMessage({ id: 'orderPeriod' }), width: 200, getCellValue: getOrderDateRange },
        { field: 'targetRange', dataTypeName: 'string', title: intl.formatMessage({ id: 'targetPeriod' }), width: 200, getCellValue: getTargetDateRange },
        { field: 'shippingMode', dataTypeName: CodeCategory.ShippingMode, title: intl.formatMessage({ id: 'field.shippingMode' }), width: 180 },
        { field: 'coDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.coDate' }), width: 200 },
        { field: 'spotOrderReason', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.spotOrderReason' }), width: 300 },
        { field: 'remark', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.remark' }), width: 300, },
        { field: 'orderStatus', dataTypeName: CodeCategory.CoStatus, title: intl.formatMessage({ id: 'orderStatus' }), width: 180 },
        { field: 'reviseNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.reviseNo' }), width: 300 },
        { field: 'reviseStatus', dataTypeName: CodeCategory.RoStatus, title: intl.formatMessage({ id: 'roStatus' }), width: 180 },
    ], [getOrderDateRange, getTargetDateRange, intl])
    const getRowId = useCallback((row: any) => row.soId, [])

    const actionProps1 = useMemo(() => ({ search: searchOrderChangedableList }), [searchOrderChangedableList])
    const actionProps2 = useMemo(() => ({ pnaCode }), [pnaCode])
    const display1 = useCallback((tableRow: TableRow) => tableRow.row?.reviseStatus !== RoStatus.DRAFT && tableRow.row?.reviseStatus !== RoStatus.PROPOSE_NEW && tableRow.row?.reviseStatus !== RoStatus.SUBMITTED && (tableRow.row?.orderStatus === CoStatus.PROCESSING || tableRow.row?.orderStatus === CoStatus.CONFIRMED || tableRow.row?.orderStatus === CoStatus.RECEIVED), [])
    const display2 = useCallback((tableRow: TableRow) => tableRow.row?.reviseStatus === RoStatus.DRAFT || tableRow.row?.reviseStatus === RoStatus.PROPOSE_NEW, [])
    const display3 = useCallback((tableRow: TableRow) => tableRow.row?.reviseStatus === RoStatus.SUBMITTED, [])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.BusinessType} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OrderFrequency} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OrderType} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.ShippingMode} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.CoStatus} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.RoStatus} />
        <Data rows={orderChangedableList} columns={columns} getRowId={getRowId} />
        <RowActionProvider name="create" Action={CreateOrderRowAction} actionProps={actionProps2} display={display1} />
        <RowActionProvider name="cancel" Action={CancelRowAction} actionProps={actionProps1} display={display1} />
        <RowActionProvider name="edit" Action={EditOrderRowAction} actionProps={actionProps2} display={display2} />
        <RowActionProvider name="issue" Action={IssueRowAction} actionProps={actionProps1} display={display2} />
        <RowActionProvider name="delete" Action={DeleteRowAction} actionProps={actionProps1} display={display2} />
        <RowActionProvider name="view" Action={ViewOrderRowAction} actionProps={actionProps2} display={display3} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['purchaseOrderNo', 'customerRefNo', 'sellerCode', 'supplierCode', 'businessType', 'orderFrequency', 'orderRange', 'shippingMode', 'coDate', 'spotOrderReason', 'remark', 'reviseNo', 'reviseStatus']}
            ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Action width={180} />
    </DataGrid>
}

const CreateOrderRowAction = ({ tableRow, pnaCode }: DataGridRowActionProps & { pnaCode: string }) => {
    const path = useCallback((tableRow: TableRow) => pnaCode === tableRow.row?.buyerCode ? `/scro/createpna-${tableRow.row?.soId}` : `/scro/create-${tableRow.row?.soId}`, [pnaCode])
    return <CreateRedirectRowAction tableRow={tableRow} access="ORDER.ORS020.CREATE" path={path} />
}

const CancelRowAction = ({ tableRow, search }: DataGridRowActionProps & { search: () => void }) => {
    const cancelOrder = useCancelSuppOrder()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'cancel' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const callback = useCallback((tableRow: TableRow) => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            const soId = tableRow.row?.soId
            soId && cancelOrder({ soId: soId }, { serialized: true }).then(search).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [cancelOrder, dispatch, functionStore, search, title])
    return <CancelCallbackRowAction tableRow={tableRow} access="ORDER.ORS020.CREATE" callback={callback} disabled={disabled} />
}

const EditOrderRowAction = ({ tableRow, pnaCode }: DataGridRowActionProps & { pnaCode: string }) => {
    const path = useCallback((tableRow: TableRow) => pnaCode === tableRow.row?.buyerCode ? `/scro/editpna-${tableRow.row?.suppRoId}` : `/scro/edit-${tableRow.row?.suppRoId}`, [pnaCode])
    return <EditRedirectRowAction tableRow={tableRow} access="ORDER.ORS020.EDIT" path={path} />
}



const ViewOrderRowAction = ({ tableRow, pnaCode }: DataGridRowActionProps & { pnaCode: string }) => {
    const path = useCallback((tableRow: TableRow) => pnaCode === tableRow.row?.buyerCode ? `/scro/viewpna-${tableRow.row?.suppRoId}` : `/scro/view-${tableRow.row?.suppRoId}`, [pnaCode])
    return <ViewRedirectRowAction tableRow={tableRow} access="ORDER.ORS020.VIEWDETAIL" path={path} />
}


const IssueRowAction = ({ tableRow, search }: DataGridRowActionProps & { search: () => void }) => {
    const issueChangeSuppOrder = useIssueChangeSuppOrder()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'Issue' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const callback = useCallback((tableRow: TableRow) => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            const suppRoId = tableRow.row?.suppRoId
            suppRoId && issueChangeSuppOrder({ suppRoId }, { serialized: true }).then(search).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [dispatch, functionStore, issueChangeSuppOrder, search, title])
    return <IssueCallbackRowAction tableRow={tableRow} access="ORDER.ORS020.ISSUE" callback={callback} disabled={disabled} />
}

const DeleteRowAction = ({ tableRow, search }: DataGridRowActionProps & { search: () => void }) => {
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'delete' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const deleteChangeSuppOrder = useDeleteChangeSuppOrder()
    const callback = useCallback((tableRow: TableRow) => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            const suppRoId = tableRow.row?.suppRoId
            suppRoId && deleteChangeSuppOrder({ suppRoId }, { serialized: true }).then(search).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [deleteChangeSuppOrder, dispatch, functionStore, search, title])
    return <DeleteCallbackRowAction access="ORDER.ORS020.DELETE" tableRow={tableRow} callback={callback} disabled={disabled} />
}