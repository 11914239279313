import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"
import { ActiveFlag } from "../enums/ActiveFlag"

export type TnmCalendar = Required & Partial<Optional> & TableEntity
type TnmCalendarJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const tnmCalendarFromJson: Project<TnmCalendarJson, TnmCalendar> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
    activeFlag: ActiveFlag,
}

interface JsonRequired {
    calendarId: number,
    calendarCode: string,
    flag: number,
}

interface JsonOptional {
    companyId: number,
    description: string,
    regionCode: string,
    activeFlag: number,
}