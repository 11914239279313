import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { dateToJson } from "../../utils/serializer"
import { ActiveFlag } from "../enums/ActiveFlag"
import { DeliveryType } from "../enums/DeliveryType"
import { RepackingType } from "../enums/RepackingType"

export type ContractPartsResult = Required & Partial<Optional>
export type ContractPartsResultJson = JsonRequired & Partial<JsonOptional>
export const contractPartsResultToJson: Project<ContractPartsResult, ContractPartsResultJson> = obj => ({
    ...obj,
    nextApplyDate: dateToJson(obj.nextApplyDate),
    currentApplyDate: dateToJson(obj.currentApplyDate),
    applyDate: dateToJson(obj.applyDate),
})
export const contractPartsResultFromJson: Project<ContractPartsResultJson, ContractPartsResult> = json => ({
    ...json,
    nextApplyDate: dateFromJson(json.nextApplyDate),
    currentApplyDate: dateFromJson(json.currentApplyDate),
    applyDate: dateFromJson(json.applyDate)
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'nextApplyDate' | 'currentApplyDate' | 'applyDate'> {
    deliveryType: DeliveryType,
    activeFlag: ActiveFlag,
    repackingType: RepackingType,
    nextApplyDate: Date,
    currentApplyDate: Date,
    applyDate: Date
}

interface JsonRequired {
}

interface JsonOptional {
    contractNo: string,
    unitPartsNo: string,
    partsNo: string,
    sellerPartsNo: string,
    buyerPartsNo: string,
    unitPartsName: string,
    deliveryType: number,
    deliveryPattern: string,
    fluctuationRatio: number,
    fcFluctuationRatio: number,
    repackingType: number,
    safetyStockDays: number,
    safetyStockPct: number,
    endUser1: string,
    endUser2: string,
    endUser3: string,
    endUser4: string,
    endUser5: string,
    activeFlag: number,
    contractPartsId: number,
    nextPriceId: number,
    seller: string,
    buyer: string,
    currency: string

    /* price */
    currentPrice: number,
    currentApplyDate: string,
    nextPrice: number,
    nextApplyDate: string,

    /* packing */
    orderLot: number
    spq: number
    m3: number
    length: number
    width: number
    height: number
    netWeight: number
    grossWeight: number
    uom: string,
    applyDate: string,

    /* other */
    flag: number
}