import { Card, CardContent, CardHeader, Grid, MenuItem, Select } from "@material-ui/core"
import { EntriesItem, EntryItem, StringItem } from "@rithe/form"
import { ArrayMultimaps, Arrays } from "@rithe/utils"
import FusionCharts from "fusioncharts"
import Charts from "fusioncharts/fusioncharts.charts"
import Widgets from "fusioncharts/fusioncharts.widgets"
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion'
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import ReactFusioncharts from 'react-fusioncharts'
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { SimpleCard } from "../../../components/Card/SimpleCard"
import { SimpleCardContent } from "../../../components/Card/SimpleCardContent"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { InventoryFactor } from "../../../services/dashboard/models/InventoryDashboardFactor"
import { InventoryResult, InventorySalesRatioResult, InventoryValueResult, StockPositionResult, WareHouseResult, WeeklySalesRatioResult } from "../../../services/dashboard/models/InventoryDashboardResult"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { TnmContract } from "../../../services/master/models/TnmContract"
import { today, useGetCompanyType } from "../../../utils/ApplicationUtils"
import { applicationActions } from "../../Application/applicationSlice"
import { EntryInput } from "./EntryInput"
import { InvCardHeader } from "./InvCardHeader"

interface DAS014PcUiProps {
    filters: InventoryFactor,
    setFilters: React.Dispatch<React.SetStateAction<InventoryFactor>>,
    search: (filters: InventoryFactor) => void,
    inventoryData: InventoryResult,
    dc: WareHouseResult[],
    currency: TnmContract[],
}

export const DAS014PcUi = (props: DAS014PcUiProps) => {
    const { inventoryData, filters, setFilters, search, dc, currency } = props
    const intl = useIntl()
    const companyType = useGetCompanyType()
    const [split, setSplit] = useState<'Warehouse' | 'Item'>(CbdsType.BU === companyType ? 'Warehouse' : 'Item')
    const dataDataTime = "Last Updated: " + intl.formatDate(inventoryData.dataDateTime ?? today(), { dateStyle: 'medium', timeStyle: 'medium', hour12: false })

    ReactFusioncharts.fcRoot(FusionCharts, Charts, Widgets, FusionTheme)
    return <SimpleCard>
        <InvCardHeader title={intl.formatMessage({ id: 'stockStatistics' })} subtitle={dataDataTime} actions={[<FilterItem filters={filters} setFilters={setFilters} search={search} warehouses={dc} currencys={currency} />]} />
        <SimpleCardContent>
            <Grid container direction="row" justifyContent="flex-start" spacing={2}>
                <Grid item xs={6}>
                    <Card >
                        <CardHeader title={intl.formatMessage({ id: 'stockPosition' })} />
                        <CardContent>
                            <StockPositionChart inventories={inventoryData.stockPositionList ?? []} />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card >
                        <CardHeader title={intl.formatMessage({ id: 'inventoryValue' })} action={
                            <Select defaultValue={split} onChange={e => setSplit(e.target.value as ('Warehouse' | 'Item'))}>
                               {companyType !== CbdsType.CUST && <MenuItem value={'Warehouse'}>Warehouse</MenuItem>} 
                                <MenuItem value={'Item'}>Item</MenuItem>
                            </Select>
                        } />
                        <CardContent>
                            <InventoryValueChart stocks={inventoryData.inventoryValueList ?? []} split={split} />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card >
                        <CardHeader title={intl.formatMessage({ id: 'inventoryToSalesRatio' })} />
                        <CardContent>
                            <Grid container>
                                <Grid item xs={4}>
                                    <SalesRatioGaugeChart inventories={inventoryData.salesRatioInfo} />
                                </Grid>
                                <Grid item xs={8}>
                                    <SalesRatioColumnChart inventories={inventoryData.salesRatioInfo?.weeklySalesRatioList ? inventoryData.salesRatioInfo.weeklySalesRatioList : []} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SimpleCardContent>
    </SimpleCard>
}

const FilterItem = (props: {
    filters: InventoryFactor,
    setFilters: React.Dispatch<React.SetStateAction<InventoryFactor>>,
    search: (filters: InventoryFactor) => void,
    warehouses: WareHouseResult[],
    currencys: TnmContract[],
}) => {
    const { filters, setFilters, search, currencys, warehouses } = props
    const [ableSearch, setAbleSearch] = useState<boolean>(true)
    const clear = useCallback((filters: InventoryFactor) => {
        return {}
    }, [])
    const years = useMemo(() => Array(100).fill(0).map((_, i) => [1999 + i, (1999 + i) + ''] as [number, string]), [])
    const months = useMemo(() => {
        return [[1, "Jan"], [2, "Feb"], [3, "Mar"], [4, "Apr"], [5, "May"], [6, "Jun"], [7, "Jul"], [8, "Aug"], [9, "Sep"], [10, "Oct"], [11, "Nov"], [12, "Dec"],] as [number, string][]
    }, [])
    const whsCodes: [number, string][] = useMemo(() => warehouses.map(v => [v.dcId, v.dcCode]), [warehouses])
    const currs: [string, string][] = useMemo(() => Arrays.distinct(currencys.map(v => v.currency ?? '')).map(v => [v, v] as [string, string]), [currencys])
    const intl = useIntl()
    const dispatch = useDispatch()
    const companyType = useGetCompanyType()

    useEffect(() => {
        if (ableSearch && !filters.currency && currs && currs.length > 0) {
            setAbleSearch(false)
            setFilters(f => ({ ...f, currency: currs[0][0] }))
            search({ ...filters, currency: currs[0][0] })
        }
    }, [ableSearch, currs, filters, search, setAbleSearch, setFilters])

    const doSearch = useCallback(() => {
        if (filters.currency === undefined || filters.currency === null || filters.currency === "") {
            dispatch(applicationActions.pushError({ title: { code: 'search' }, messages: { code: 'w0036', args:[intl.formatMessage({id:"currency"})] } }))
            return;
        }
        search(filters)
    }, [dispatch, filters, intl, search])

    const filterCounter = useCallback((filters: InventoryFactor) => {
        return [
            filters.year,
            filters.month,
            filters.item,
            filters.itemFamily,
            filters.currency,
            filters.warehouses
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={doSearch} clear={clear} filterCounter={filterCounter}>
        <EntryItem field="year" labelWidth={120} label={intl.formatMessage({ id: 'year' })} entries={years} Input={EntryInput} />
        <EntryItem field="month" labelWidth={120} label={intl.formatMessage({ id: 'month' })} entries={months} Input={EntryInput} />
        <StringItem field="item" label={intl.formatMessage({ id: 'field.item' })} />
        <StringItem field="itemFamily" label={intl.formatMessage({ id: 'itemFamily' })} />
        {companyType !== CbdsType.CUST && <EntriesItem field="warehouses" labelWidth={120} label={intl.formatMessage({ id: 'warehouse' })} entries={whsCodes} />} 
        <EntryItem field="currency" labelWidth={120} label={intl.formatMessage({ id: 'currency' })} entries={currs} Input={EntryInput} />
    </FilterToolbarItem>
}


const StockPositionChart = React.memo(({ inventories }: { inventories: StockPositionResult[] }) => {

    const stockDays = useCallback((stocks: string | null | undefined): number => {
        try {
            return Number((stocks ?? "-1").replaceAll(">", ""));
        } catch (e) {
            return -1;
        }
    }, [])

    const dataSource = useMemo(() => {
        const overStock: number = inventories.filter(f => f.max !== null && f.max !== undefined && stockDays(f.stocks) >= f.max).length
        const alarm1: number = inventories.filter(f => {
            if (f.min1 === null || f.min1 === undefined) {
                return false;
            }
            const stock = stockDays(f.stocks);
            const next = f.min2 === null && f.min2 === undefined ? f.min3 : f.min2;
            return stock <= f.min1 && (next === null || next === undefined || next < stock);
        }).length
        const alarm2: number = inventories.filter(f => {
            if (f.min2 === null || f.min2 === undefined) {
                return false;
            }
            const stock = stockDays(f.stocks);
            return stock <= f.min2 && (f.min3 === null || f.min3 === undefined || f.min3 < stock);
        }).length
        const alarm3: number = inventories.filter(f => {
            return f.min3 !== null && f.min3 !== undefined && stockDays(f.stocks) <= f.min3;
        }).length

        return {
            chart: {
                theme: "fusion",
                palettecolors: "#F6BA7D,#9AC694,#D07576,#666665",
                placeValuesInside: 1,
                divLineAlpha: 0,
                showYAxisValues: 0,
                showXAxisLine: 0,
                baseFontSize: 14,
                plottooltext: "$label : $displayValue",
            },
            data: [
                { label: "Over Inventory", value: overStock, displayValue: `${overStock} parts`, color: '#7030A0' },
                { label: "≤ Min Inventory Alert 1", value: alarm1, displayValue: `${alarm1} parts`, color: '#FFFF00' },
                { label: "≤ Min Inventory Alert 2", value: alarm2, displayValue: `${alarm2} parts`, color: '#FFC000' },
                { label: "≤ Min Inventory Alert 3", value: alarm3, displayValue: `${alarm3} parts`, color: '#FF5050' }
            ]
        }
    }, [inventories, stockDays])

    return <ReactFusioncharts {...{
        type: 'bar2d',
        width: '100%',
        height: 230,
        dataFormat: 'json',
        dataSource
    }} />
})

const InventoryValueChart = React.memo(({ stocks, split }: { stocks: InventoryValueResult[], split: 'Warehouse' | 'Item' }) => {
    const intl = useIntl()
    const dataSource = useMemo(() => {
        const inventories = stocks.map(m => {
            return {
                itemNo: m.partsNo,
                warehouse: m.warehouse,
                inventoryCost: m.inventoryAmount,
                currency: m.currency,
            }
        })
        const group = ArrayMultimaps.from(inventories.map(item => [split === 'Warehouse' ? item.warehouse : item.itemNo, item]))
        return {
            chart: {
                theme: "fusion",
                placeValuesInside: 1,
                divLineAlpha: 0,
                showYAxisValues: 0,
                showXAxisLine: 0,
                baseFontSize: 14,
                plottooltext: "$label : $displayValue",
                scrollPosition: "right",
                numVisiblePlot: 5,
            },
            categories: [{
                category: Arrays.from(group.keys()).map(key => ({ label: key }))
            }],
            dataset: [{
                data: Arrays.from(group.collections()).map(values => {
                    const value = values.map(v => v.inventoryCost ?? 0).reduce((a, b) => a + b, 0)
                    const displayValue = intl.formatNumber(value, { currency: values[0].currency, minimumFractionDigits: 3, maximumFractionDigits: 3 })
                    return { value, displayValue }
                })
            }]
        }
    }, [intl, split, stocks])

    return <ReactFusioncharts {...{
        type: 'scrollcolumn2d',
        width: '100%',
        height: 230,
        dataFormat: 'json',
        dataSource
    }} />
})

const SalesRatioGaugeChart = React.memo(({ inventories }: { inventories: InventorySalesRatioResult | undefined }) => {
    // calculate result
    const dataSource = useMemo(() => {
        //const totalInventoryCost = inventories === undefined ? 0 : inventories.inventoryCost
        //const totalSalesCost = inventories === undefined ? 0 : inventories.salesCost
        // const turnover = totalInventoryCost === 0 ? 0 : Number((totalSalesCost * 100 / totalInventoryCost).toFixed(1))
        const turnover = inventories === undefined ? 0 : inventories.salesRatio
        const minTurnover = 0
        const maxTurnover = 16 > turnover ? 16 : Math.floor(turnover + 16)
        const midTurnover = (minTurnover + maxTurnover) / 2

        return {
            chart: {
                lowerlimit: minTurnover,
                upperlimit: maxTurnover,
                showvalue: 1,
                theme: "fusion",
                showtooltip: "0",
                showTickValues: 1,
                forceTickValueDecimals: 1,
                tickValueDecimals: 1,
                showPivotBorder: 1,
                decimals: 1,
                forceDecimals: 1,
                animation: 0,
            },
            colorrange: {
                color: [
                    { minvalue: minTurnover, maxvalue: Math.min(turnover, midTurnover), code: "#F77F1D" },
                    { minvalue: Math.min(turnover, midTurnover), maxvalue: Math.max(turnover, midTurnover), code: Math.min(turnover, midTurnover) === midTurnover ? "#F77F1D" : "#E9E9E9" },
                    { minvalue: Math.max(turnover, midTurnover), maxvalue: maxTurnover, code: "#E9E9E9" }
                ]
            },
            dials: {
                dial: [{ value: turnover }]
            }
        }
    }, [inventories])

    const ref = useRef<any>()
    return <ReactFusioncharts ref={ref} {...{
        type: 'angulargauge',
        width: '100%',
        height: 230,
        dataFormat: 'json',
        dataSource
    }} />
})

const SalesRatioColumnChart = React.memo(({ inventories }: { inventories: WeeklySalesRatioResult[] }) => {
    const intl = useIntl()
    const dataSource = useMemo(() => {

        const data = inventories?.map(values => {
            const digits = 2
            const ratio = values.salesRatio
            const salesAmount = values.totalSalesAmount
            const ratioName: string = salesAmount === 0 ? 'N/A' : intl.formatNumber(ratio, { minimumFractionDigits: digits, maximumFractionDigits: digits })
            return {
                label: `Week ${values.weekNum}`,
                value: ratio,
                displayValue: ratioName,
            }
        })
        // const list: number[] = data?.map(m => m.value ?? 0).sort()
        // const trendlinesData = {
        //     startvalue: list ? list[0] : 1,
        //     endvalue: list ? list[list.length - 1] : 1,
        // }

        return {
            chart: {
                showvalues: 0,
                theme: "fusion",
                labelStep: data?.length > 15 ? 5 : 1,
                rotateLabels: 1,
                slantLabels: 1,
                baseFontSize: 12,
                plottooltext: "$label : $displayValue",
            },
            // trendlines: [{
            //     line: [{
            //         startvalue: trendlinesData.startvalue,
            //         endvalue: trendlinesData.endvalue,
            //         valueOnRight: "15",
            //         displayvalue: " ",
            //         color: "#00CC00",
            //     }]
            // }],
            categories: [{
                category: data.map(record => ({ label: record.label }))
            }],
            dataset: [{
                data: data ?? []
            }]
        }
    }, [intl, inventories])

    return <ReactFusioncharts {...{
        type: 'scrollcolumn2d',
        width: '100%',
        height: 230,
        dataFormat: 'json',
        dataSource
    }} />
})

