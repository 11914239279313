import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import appConfig from "../../../configs/appConfig"
import { applicationActions } from '../../../layouts/Application/applicationSlice'
import { OrderFrequency } from '../../../services/order/enums/OrderFrequency'
import { OrderType } from '../../../services/order/enums/OrderType'
import { PlaceRegularOrderFactor, PlaceRegularOrderFactorSerializer } from '../../../services/order/models/PlaceOrderListFactor'
import { PlaceOrderListResult, placeOrderListResultFromJson } from '../../../services/order/models/PlaceOrderListResult'
import { dateFromJson } from '../../../services/utils/deserializer'
import { today } from "../../../utils/ApplicationUtils"
import errorToNotification from "../../../utils/axios/errorToNotification"
import responseToNotification from "../../../utils/axios/responseToNotification"
import thunkAxiosInstance from "../../../utils/axios/thunkAxiosInstance"

interface ONS010State {
    referenceDate: Date,
    availableOrderFrequencies: OrderFrequency[],
    availableOrderPeriodsRecord: Partial<Record<OrderFrequency, [Date, Date][]>>,
    regularDataFilter: PlaceRegularOrderFactor,
    regularOrderPlacements: PlaceOrderListResult[],
    spotOrderPlacements: PlaceOrderListResult[],
}

const initialState: ONS010State = {
    referenceDate: new Date(today().setUTCHours(0, 0, 0, 0)),
    availableOrderFrequencies: [],
    availableOrderPeriodsRecord: {},
    regularDataFilter: { orderFrequency: null, orderStartDate: null, orderEndDate: null },
    regularOrderPlacements: [],
    spotOrderPlacements: [],
}

const loadAvailableOrderFrequencies = createAsyncThunk('ons010/loadAvailableOrderFrequencies', async (_, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    return await axiosInstance.post(`/lcbm-order-api/order/pl/api/getAllOrderFrequency`)
        .then(response => {
            thunk.dispatch(ons010Actions.availableOrderFrequenciesLoaded(response.data))
        })
        .catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        })
})

const loadAvailableOrderPeriods = createAsyncThunk<void, OrderFrequency>('ons010/loadAvailableOrderPeriods', async (orderFrequency, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const data = { orderFrequency }
    return await axiosInstance.post(`/lcbm-order-api/order/pl/api/getOrderDateRange`, data)
        .then(response => {
            thunk.dispatch(ons010Actions.availableOrderPeriodsLoaded({
                orderFrequency,
                orderPeriods: response.data.map((range: [string, string]) => [dateFromJson(range[0]), dateFromJson(range[1])]),
            }))
        })
        .catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        })
})

const searchRegularOrderPlacements = createAsyncThunk('ons010/searchRegularOrderPlacements', async (_, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const { regularDataFilter } = selectONS010State(thunk.getState())
    const data = PlaceRegularOrderFactorSerializer(regularDataFilter)
    thunk.dispatch(applicationActions.addMask())
    return await axiosInstance.post(`/lcbm-order-api/order/pl/api/getRegularCustomerContractList`, data)
        .then(response => {
            thunk.dispatch(ons010Actions.regularOrderPlacementsSearched(response.data.map(placeOrderListResultFromJson)))
        })
        .catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        }).then(() => {
            thunk.dispatch(applicationActions.removeMask())
        })
})

const searchSpotOrderPlacements = createAsyncThunk('ons010/searchSpotOrderPlacements', async (_, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    thunk.dispatch(applicationActions.addMask())
    return await axiosInstance.post(`/lcbm-order-api/order/pl/api/getSpotCustomerContractList`)
        .then(response => {
            thunk.dispatch(ons010Actions.spotOrderPlacementsSearched(response.data.map(placeOrderListResultFromJson)))
        })
        .catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        }).then(() => {
            thunk.dispatch(applicationActions.removeMask())
        })
})

const deletePlaceOrder = createAsyncThunk<void, { coId: number, orderType: number }>('ons010/deletePlaceOrder', ({ coId, orderType }, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const data = { coId: coId }
    return axiosInstance.post(`/lcbm-order-api/order/pl/api/deletePlacedOrder`, data)
        .then(response => {
            if (OrderType.REGULAR === orderType) {
                thunk.dispatch(searchRegularOrderPlacements())
            } else {
                thunk.dispatch(searchSpotOrderPlacements())
            }
            thunk.dispatch(responseToNotification(response))
        })
        .catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        })
})

const issuePlaceOrder = createAsyncThunk<void, { coId: number, orderType: number }>('ons010/issuePlaceOrder', async ({ coId, orderType }, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const data = { coId: coId }
    return axiosInstance.post(`/lcbm-order-api/order/pl/api/issuePlacedOrder`, data)
        .then(response => {
            if (OrderType.REGULAR === orderType) {
                thunk.dispatch(searchRegularOrderPlacements())
            } else {
                thunk.dispatch(searchSpotOrderPlacements())
            }
            //thunk.dispatch(responseToNotification(response))
            thunk.dispatch(applicationActions.pushSuccess({
                title: { code: 'issue' },
                messages: [{ code: 'notice.success' }],
            }))
        })
        .catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        })
})

export const ons010Slice = createSlice({
    name: 'ons010',
    initialState,
    reducers: {
        changeRegularDataFilter: (state, { payload: filter }: PayloadAction<PlaceRegularOrderFactor>) => {
            state.regularDataFilter = filter
        },
        availableOrderFrequenciesLoaded: (state, { payload: orderFrequencies }: PayloadAction<OrderFrequency[]>) => {
            state.availableOrderFrequencies = orderFrequencies
            const orderFrequency = orderFrequencies[0]
            const orderPeriods = state.availableOrderPeriodsRecord[orderFrequency]
            if (orderFrequency && !state.regularDataFilter.orderFrequency) {
                state.regularDataFilter.orderFrequency = orderFrequency
            }
            if (orderPeriods && orderPeriods.length > 0 && (!state.regularDataFilter.orderStartDate || !!state.regularDataFilter.orderEndDate)) {
                const index = orderPeriods.length >= 3 ? 2 : 0
                state.regularDataFilter.orderStartDate = orderPeriods[index][0]
                state.regularDataFilter.orderEndDate = orderPeriods[index][1]
            }
        },
        availableOrderPeriodsLoaded: (state, { payload: { orderFrequency, orderPeriods } }: PayloadAction<{ orderFrequency: OrderFrequency, orderPeriods: [Date, Date][] }>) => {
            state.availableOrderPeriodsRecord[orderFrequency] = orderPeriods
            if (state.regularDataFilter.orderFrequency === orderFrequency && orderPeriods.length > 0 && (!state.regularDataFilter.orderStartDate || !!state.regularDataFilter.orderEndDate)) {
                const index = orderPeriods.length >= 3 ? 2 : 0
                state.regularDataFilter.orderStartDate = orderPeriods[index][0]
                state.regularDataFilter.orderEndDate = orderPeriods[index][1]
            }
        },
        regularOrderPlacementsSearched: (state, { payload: regularOrderPlacements }: PayloadAction<PlaceOrderListResult[]>) => {
            state.regularOrderPlacements = regularOrderPlacements
        },
        spotOrderPlacementsSearched: (state, { payload: spotOrderPlacements }: PayloadAction<PlaceOrderListResult[]>) => {
            state.spotOrderPlacements = spotOrderPlacements
        },
    },
    extraReducers: {
    }
})

export const ons010Actions = {
    ...ons010Slice.actions,
    loadAvailableOrderFrequencies,
    loadAvailableOrderPeriods,
    searchRegularOrderPlacements,
    searchSpotOrderPlacements,
    deletePlaceOrder,
    issuePlaceOrder,
}

function selectONS010State(state: any) {
    return state[appConfig.appFullName][ons010Slice.name]
}

export function useONS010Selector<R>(selector: (state: ONS010State) => R, equalityFn?: (left: R, right: R) => boolean) {
    return useSelector<any, R>(state => selector(selectONS010State(state)), equalityFn)
}