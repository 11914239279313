import { Route, Routes } from "react-router-dom"
import { AuthNavigate } from "../components/AuthNavigate/AuthNavigate"
import { NotFound } from "../layouts/NotFound/NotFound"
import { INT010 } from "../layouts/integration/INT010/INT010"
import { INT011 } from "../layouts/integration/INT011/INT011"
import { INT020 } from "../layouts/integration/INT020/INT020"
import { INT030 } from "../layouts/integration/INT030/INT030"
import { INT040 } from "../layouts/integration/INT040/INT040"
import { INT070 } from "../layouts/integration/INT070/INT070"

export function IntegrationRoutes() {
    return <Routes>
        <Route path="/manageExternalData" element={<AuthNavigate access="INT.INT010.VIEW"><INT010 /></AuthNavigate>} />,
        <Route path="/manageExternalData/create" element={<AuthNavigate access="INT.INT011.VIEW"><INT011 /></AuthNavigate>} />,
        <Route path="/manageExternalData/edit-:templateId" element={<AuthNavigate access="INT.INT011.VIEW"><INT011 /></AuthNavigate>} />,
        <Route path="/myUpload" element={<AuthNavigate access="INT.INT020.VIEW"><INT020 /></AuthNavigate>} />,
        <Route path="/integrationBatch" element={<AuthNavigate access="INT.INT030.VIEW"><INT030 /></AuthNavigate>} />,
        <Route path="/externalOrderList" element={<AuthNavigate access="INT.INT040.VIEW"><INT040 /></AuthNavigate>} />

        <Route path="/comparisonTool" element={<AuthNavigate /*access="INT.INT070.VIEW"*/><INT070 /></AuthNavigate>} />,

        <Route path="*" element={<NotFound scope="integration" />} />,
    </Routes>
}
