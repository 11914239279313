import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson, tableEntityToJson } from "../../utils/TableEntity"
import { ActiveFlag } from "../enums/ActiveFlag"

export type TnmCustomerTemp = Required & Partial<Optional> & TableEntity
export type TnmCustomerTempJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const tnmCustomerTempToJson: Project<TnmCustomerTemp, TnmCustomerTempJson> = obj => ({
    ...tableEntityToJson(obj),
})
export const tnmCustomerTempFromJson: Project<TnmCustomerTempJson, TnmCustomerTemp> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
    activeFlag: ActiveFlag,
}

interface JsonRequired {
    tempCustomerId: number,
    customerCode: string,
}

interface JsonOptional {
    customerId: number,
    customerName: string,
    regionCode: string,
    companyId: number,
    customerShortCode: string,
    address1: string,
    address2: string,
    address3: string,
    address4: string,
    postalCode: string,
    contact1: string,
    fax1: string,
    telephone1: string,
    email1: string,
    contact2: string,
    fax2: string,
    telephone2: string,
    email2: string,
    timeZone: string,
    calendarId: number,
    activeFlag: number,
}