import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { Projects } from "../../../utils/Projects"
import { FOBSettingListResult, getFOBSettingListResultFromJson } from "../../master/models/FOBSettingListResult"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { FOBSettingDetailResult, FOBSettingInfo, fOBSettingInfoResultToJson, fOBSettingInfoToJson, gtFOBSettingInfoResultFromJson } from "../models/FOBSettingDetailResult"
import { FOBSettingListView, fobSettingListViewToJson } from "../models/FOBSettingListView"
import { TnmContractRoute, tnmContractRouteFromJson } from "../models/TnmContrRoute"

export const useGetFOBSettingList = () => {
    const url = '/lcbm-local-master-api/api/getFOBSettingList'
    const payloadProject = useCallback(fobSettingListViewToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(getFOBSettingListResultFromJson), [])
    return usePost<FOBSettingListView, PageResultEntity<FOBSettingListResult>>(url, payloadProject, resultProject)
}

export const useGetFOBSettingDetailById = () => {
    const url = '/lcbm-local-master-api/api/getFOBSettingDetailById'
    const payloadProject = useCallback((obj: { fobSettingId: number }) => obj.fobSettingId, [])
    const resultProject = useCallback(gtFOBSettingInfoResultFromJson, [])
    return usePost<{ fobSettingId: number }, FOBSettingDetailResult>(url, payloadProject, resultProject)
}

export const useSaveFOBSettingDetail = () => {
    const url = '/lcbm-local-master-api/api/saveFOBSettingDetail'
    const payloadProject = useCallback(fOBSettingInfoResultToJson, [])
    const resultProject = useCallback(gtFOBSettingInfoResultFromJson, [])
    return usePost<FOBSettingDetailResult, FOBSettingDetailResult>(url, payloadProject, resultProject)
}

export const useGetContrRouteList = () => {
    const url = '/lcbm-local-master-api/api/getFOBContrRouteList'
    const payloadProject = useCallback(fOBSettingInfoToJson, [])
    const resultProject = useCallback(Projects.array(tnmContractRouteFromJson), [])
    return usePost<FOBSettingInfo, TnmContractRoute[]>(url, payloadProject, resultProject)
}

export const useGetCBDSListByContrRouteId = () => {
    const url = '/lcbm-local-master-api/api/getCBDSListByContrRouteId'
    const payloadProject = useCallback((obj: { contrRouteId: number }) => obj.contrRouteId, [])
    const resultProject = useCallback(gtFOBSettingInfoResultFromJson, [])
    return usePost<{ contrRouteId: number }, FOBSettingDetailResult>(url, payloadProject, resultProject)
}


