import { AutoCompleteItem, DateItem, EntryItem, Form, NumberItem, StringItem } from "@rithe/form"
import React, { useCallback, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useFunctionStore } from "../../../Root"
import { ConfirmCallbackViewAction } from "../../../components/Action/ConfirmCallbackViewAction"
import { SaveCallbackViewAction } from "../../../components/Action/SaveCallbackViewAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { CodeItem } from "../../../components/Form/CodeItem"
import { View } from "../../../components/View/View"
import { useConfirmShippingDetail, useEditShippingDetail } from "../../../services/delivery/apis/deliveryShippingDetailApi"
import { ShippingDetailStatus } from "../../../services/delivery/enums/ShippingDetailStatus"
import { ShippingDetailEntity } from "../../../services/delivery/models/ShippingDetailEntity"
import { CargoRole } from "../../../services/master/enums/CargoRole"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { TnmContainerType } from "../../../services/master/models/TnmContainerType"
import { TnmPort } from "../../../services/master/models/TnmPort"
import { applicationActions } from "../../Application/applicationSlice"

interface LSS011PcUiProps {
    mode: 'edit' | 'view',
    search: () => void,
    data?: ShippingDetailEntity,
    setData: React.Dispatch<React.SetStateAction<ShippingDetailEntity | undefined>>,
    portList: TnmPort[],
    containerTypeList: TnmContainerType[],
}

export const LSS011PcUi = (props: LSS011PcUiProps) => {
    const { mode, data, search } = props

    const actions: any[] = React.useMemo(() => {
        if (mode === 'view') {
            return []
        } else if (data?.status === ShippingDetailStatus.Draft && data.role === CargoRole.ExpShipping) {
            // only exp shipping can do confirm
            return [<SaveAction data={data} search={search} />, <ConfirmAction data={data} search={search} />]
        } else if (data) {
            return [<SaveAction data={data} search={search} />]
        } else {
            return []
        }
    }, [data, mode, search])

    const intl = useIntl()
    return <View actions={actions}>
        <SectionCard>
            <SectionCardHeader
                title={intl.formatMessage({ id: 'ShippingDetail' })}
                actions={[]}
            />
            <SectionCardContent>
                <DataForm {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataForm = (props: LSS011PcUiProps) => {
    const { data, setData, portList, containerTypeList } = props
    const portEntries: [number, string][] = useMemo(() => portList.map(port => [port.portId, port.portCode]), [portList])
    const cargoStatusEntries: [string, string][] = useMemo(() => {
        const entries: [string, string][] = data?.availableCargoStatuss?.map(s => [s, s]) ?? []
        const index = data?.availableCargoStatuss?.indexOf(data.cargoStatus ?? '') ?? -1
        return index >= 0 ? entries : [[data?.cargoStatus ?? '', data?.cargoStatus ?? ''], ...entries]
    }, [data])
    const containerTypes: string[] = useMemo(() => containerTypeList.map(ct => ct.containerTypeCode!), [containerTypeList])
    const m3Map: { [containerType: string]: number } = useMemo(() => {
        const map: { [containerType: string]: number } = {}
        containerTypeList.forEach(ct => {
            map[ct.containerTypeCode!] = ct.m3!
        })
        return map
    }, [containerTypeList])

    const readonly = props.mode === 'view' || data?.status === ShippingDetailStatus.Confirmed || (data?.role === CargoRole.ImpShipping || data?.role === CargoRole.ImpCustoms)

    const onContainerTypeChange = useCallback((value: string) => {
        const m3 = m3Map[value]
        setData(data => {
            if (m3 !== undefined && data !== undefined) {
                return { ...data, m3 }
            } else {
                return data
            }
        })
    }, [m3Map, setData])

    const intl = useIntl()
    return <Form data={data} setData={setData} readonly={props.mode === 'view'} labelDisplay="block" helperDisplay="tooltip" minWidth={500}>
        <StringItem field="bookingNo" readonly label={intl.formatMessage({ id: 'field.bookingNo' })} />
        <StringItem field="containerNo" readonly label={intl.formatMessage({ id: 'field.containerNo' })} />
        <StringItem field="outboundNo" readonly label={intl.formatMessage({ id: 'field.outboundNo' })} />
        <CodeItem field="role" readonly label={intl.formatMessage({ id: 'field.cargoRole' })} code={CodeCategory.CargoRole} />
        <CodeItem field="shippingMode" required readonly={readonly} label={intl.formatMessage({ id: 'field.shippingMode' })} code={CodeCategory.ShippingMode} />
        <EntryItem field="loadingPortId" readonly label={intl.formatMessage({ id: 'loadingPort' })} entries={portEntries} />
        <EntryItem field="dischargePortId" readonly label={intl.formatMessage({ id: 'dischargePort' })} entries={portEntries} />
        <DateItem field="atd" required readonly={readonly} label={intl.formatMessage({ id: 'field.atd' })} />
        <DateItem field="eta" required readonly={readonly} label={intl.formatMessage({ id: 'field.eta' })} />
        <StringItem field="blNo" required readonly={readonly} label={intl.formatMessage({ id: 'field.blNo' })} />
        <DateItem field="blDate" required readonly={readonly} label={intl.formatMessage({ id: 'field.blDate' })} />
        <StringItem field="vesselName" required readonly={readonly} label={intl.formatMessage({ id: 'field.vesselName' })} />
        <StringItem field="voyageNo" required readonly={readonly} label={intl.formatMessage({ id: 'field.voyageNo' })} />

        <EntryItem field="cargoStatus" required readonly={props.mode === 'view'} label={intl.formatMessage({ id: 'field.cargoStatus' })} entries={cargoStatusEntries} />

        <AutoCompleteItem field="containerType" readonly={readonly} label={intl.formatMessage({ id: 'field.containerType' })} suggestions={containerTypes} onChange={onContainerTypeChange} />
        <StringItem field="sealNo" readonly={readonly} label={intl.formatMessage({ id: 'field.sealNo' })} />
        <NumberItem field="m3" readonly={readonly} label={intl.formatMessage({ id: 'field.m3' })} />
        <NumberItem field="netWeight" readonly={readonly} label={intl.formatMessage({ id: 'field.netWeight' })} />
        <NumberItem field="grossWeight" readonly={readonly} label={intl.formatMessage({ id: 'field.grossWeight' })} />
        <CodeItem field="viaPortcastFlag" readonly label={intl.formatMessage({ id: 'field.viaPortcastFlag' })} code={CodeCategory.YesNoFlag} />
    </Form>
}

const ConfirmAction = (props: {
    data: ShippingDetailEntity,
    search: () => void,
}) => {
    const { data, search } = props
    const confirm = useConfirmShippingDetail()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'confirm' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const onClick = useCallback(() => {
        if (data) {
            const functionId = functionStore.register(() => {
                setDisabled(true)
                confirm({
                    shippingDetailId: data.shippingDetailId!,
                    shippingMode: data.shippingMode!,
                    atd: data.atd,
                    eta: data.eta,
                    blNo: data.blNo,
                    blDate: data.blDate,
                    vesselName: data.vesselName,
                    voyageNo: data.voyageNo,
                    cargoStatus: data.cargoStatus,
                    containerType: data.containerType,
                    sealNo: data.sealNo,
                    m3: data.m3,
                    netWeight: data.netWeight,
                    grossWeight: data.grossWeight,
                    viaPortcastFlag: data.viaPortcastFlag!
                }, { silent: true }).then(warningMessages => {
                    if (warningMessages.length > 0) {
                        const message = warningMessages.find(m => m.code === "c0024")
                        if (message) {
                            dispatch(applicationActions.pushWarning({
                                title: intl.formatMessage({ id: 'post /lcbm-shipping-detail-api/api/confirmShippingDetail' }),
                                messages: message,
                            }))
                        }
                    } else {
                        dispatch(applicationActions.pushSuccess({
                            title: intl.formatMessage({ id: 'post /lcbm-shipping-detail-api/api/confirmShippingDetail' }),
                            messages: [{ code: 'notice.success' }],
                        }))
                    }
                    
                    search()
                }).finally(() => {
                    setDisabled(false)
                })
            })
            dispatch(applicationActions.pushWarning({
                title: title,
                messages: { code: 'c0001', args: [title] },
                actions: [{
                    label: 'CANCEL'
                }, {
                    functionId,
                    label: 'CONFIRM',
                }]
            }))
        }
    }, [confirm, data, dispatch, functionStore, intl, search, title])

    return <ConfirmCallbackViewAction access="LOGI.LSS011.CONFIRM" callback={onClick} disabled={disabled} />
}

const SaveAction = (props: {
    data: ShippingDetailEntity,
    search: () => void,
}) => {
    const { data, search } = props
    const save = useEditShippingDetail()
    const [disabled, setDisabled] = useState<boolean>(false)
    const onClick = useCallback(() => {
        if (data) {
            setDisabled(true)
            save({
                shippingDetailId: data.shippingDetailId!,
                shippingMode: data.shippingMode!,
                atd: data.atd,
                eta: data.eta,
                blNo: data.blNo,
                blDate: data.blDate,
                vesselName: data.vesselName,
                voyageNo: data.voyageNo,
                cargoStatus: data.cargoStatus,
                containerType: data.containerType,
                sealNo: data.sealNo,
                m3: data.m3,
                netWeight: data.netWeight,
                grossWeight: data.grossWeight,
                viaPortcastFlag: data.viaPortcastFlag!
            }).then(() => {
                search()
            }).finally(() => {
                setDisabled(false)
            })
        }
    }, [save, data, search])

    return <SaveCallbackViewAction access="LOGI.LSS011.SAVE" callback={onClick} disabled={disabled} />
}