import { useCallback } from "react"
import { Projects } from "../../../utils/Projects"
import { usePost, usePut } from "../../../utils/fetch/useFetch"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { EtlBatchTransfer, etlBatchTransferFromJson } from "../models/EtlBatchTransfer"
import { EtlBatchView, etlBatchViewToJson } from "../models/EtlBatchView"


export const useGetEtlBatchPage = () => {
    const url = '/smt-api/etl/getEtlBatchPageList'
    const payloadProject = useCallback(etlBatchViewToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(etlBatchTransferFromJson), [])
    return usePost<EtlBatchView, PageResultEntity<EtlBatchTransfer>>(url, payloadProject, resultProject)
}

export const useDoSyncETLDataLogic = () => {
    const url = '/smt-api/etl/doSyncETLDataLogic'
    const payloadProject = Projects.IGNORE
    const resultProject = Projects.IGNORE
    return usePost<undefined, undefined>(url, payloadProject, resultProject)
}

export const useSyncETLData = () => {
    const url = '/smt-api/etl/syncETLData'
    const payloadProject = Projects.IGNORE
    const resultProject = Projects.IGNORE
    return usePost<undefined, undefined>(url, payloadProject, resultProject)
}

export const useSyncEtlForPnaWip = () => {
    const url = '/smt-api/etl/syncEtlForPnaWip'
    const payloadProject = Projects.IGNORE
    const resultProject = Projects.IGNORE
    return usePost<undefined, undefined>(url, payloadProject, resultProject)
}

export const useSendMailEtlBatchFail = () => {
  const url = "/smt-api/etl/sendMailEtlBatchFail";
  const payloadProject = Projects.IGNORE;
  const resultProject = Projects.IGNORE;
  return usePost<undefined, undefined>(url, payloadProject, resultProject);
};

export const useRunETLDataProcessing = () => {
    const url = '/smt-api/etl/runETLDataProcessing'
    const payloadProject = Projects.IGNORE
    const resultProject = Projects.IGNORE
    return usePost<undefined, undefined>(url, payloadProject, resultProject)
}


export const useCheckToDoList = () => {
    const url = '/smt-api/etl/checkToDoList'
    const payloadProject = Projects.IGNORE
    const resultProject = Projects.IGNORE
    return usePost<undefined, undefined>(url, payloadProject, resultProject)
}

export const useDoGenerationDailyStock = () => {
    const url = '/smt-api/etl/doGenerationDailyStock'
    const payloadProject = Projects.IGNORE
    const resultProject = Projects.IGNORE
    return usePost<undefined, undefined>(url, payloadProject, resultProject)
}

export const useDoCalculatePokayoke = () => {
    const url = '/smt-api/etl/doCalculatePokayoke'
    const payloadProject = Projects.IGNORE
    const resultProject = Projects.IGNORE
    return usePost<undefined, undefined>(url, payloadProject, resultProject)
}

export const useDoCompareBalance = () => {
    const url = '/smt-api/etl/doBalanceCompare'
    const payloadProject = Projects.IGNORE
    const resultProject = Projects.IGNORE
    return usePost<undefined, undefined>(url, payloadProject, resultProject)
}

export const useCheckInventoryAlert = () => {
    const url = '/smt-api/etl/checkInventoryAlert'
    const payloadProject = Projects.IGNORE
    const resultProject = Projects.IGNORE
    return usePost<undefined, undefined>(url, payloadProject, resultProject)
}

export const useDoInitOnlineChatUser = () => {
    const url = '/common-online-chat-api/init'
    const payloadProject = Projects.IGNORE
    const resultProject = Projects.IGNORE
    return usePut<undefined, undefined>(url, payloadProject, resultProject)
}
