import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, RowActionProvider, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { Records } from "@rithe/utils"
import React, { useCallback, useMemo } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { DeleteCallbackRowAction } from "../../../components/DataGrid/rowActions/DeleteCallbackRowAction"
import { EditCallbackRowAction } from "../../../components/DataGrid/rowActions/EditCallbackRowAction"
import { IssueCallbackRowAction } from "../../../components/DataGrid/rowActions/IssueCallbackRowAction"
import { useFunctionStore } from "../../../Root"
import { OrderType } from "../../../services/order/enums/OrderType"
import { placeOrderDetailFactorSerializer } from "../../../services/order/models/PlaceOrderDetailFactor"
import { TotCo } from "../../../services/order/models/TotCo"
import sessionKeys from "../../../utils/sessionKeys"
import { useSetDataIntoSession } from "../../../utils/sessionUtil"
import { applicationActions } from "../../Application/applicationSlice"
import { ons010Actions } from "./ONS010Slice"

interface ONS010CoListDialogContentProps {
    coList?: TotCo[],
    contractRouteId?: number,
}

export function ONS010CoListDialogContent(props: ONS010CoListDialogContentProps) {
    const { coList, contractRouteId } = props

    const intl = useIntl()
    const columns = useMemo(() => [
        { field: 'customerOrderNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerOrderNo' }), width: 300 },
        { field: 'customerRefNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerRefNo' }), width: 300 },
        { field: 'coDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'orderDate' }), width: 150 },
    ], [intl])
    const getRowId = useCallback((row: any) => row.coId, [])

    const actionProps1 = useMemo(() => ({ contractRouteId }), [contractRouteId])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <Data rows={coList ?? []} columns={columns} getRowId={getRowId} />
        <RowActionProvider name="edit" Action={EditSpotRowAction} actionProps={actionProps1} />
        <RowActionProvider name="issue" Action={IssueRowAction} />
        <RowActionProvider name="delete" Action={DeleteRowAction} />
        <ColumnFreeze />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Sorting />
        <Filtering />
        <Action width={128} />
    </DataGrid>
}

const EditSpotRowAction = ({ tableRow, contractRouteId }: DataGridRowActionProps & { contractRouteId: number }) => {
    const navigate = useNavigate()
    const setSesion = useSetDataIntoSession()
    const callback = useCallback((tableRow: TableRow) => {
        setSesion(sessionKeys.Data_ONS011, placeOrderDetailFactorSerializer, {
            contractRouteId: contractRouteId,
            coId: tableRow.row?.coId,
            orderStartDate: null,
            orderEndDate: null
        })
        navigate(`/placecustorder-spot/create-${contractRouteId}`)
    }, [contractRouteId, navigate, setSesion])
    return <EditCallbackRowAction tableRow={tableRow} access="ORDER.ONS010.EDIT" callback={callback} />
}

const IssueRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'Issue' }), [intl])
    const callback = useCallback((tableRow: TableRow) => {
        const functionId = functionStore.register(() => {
            const coId = getCoInfo(tableRow.row as any, 'coId')
            coId && dispatch(ons010Actions.issuePlaceOrder({ coId: coId, orderType: OrderType.SPOT }))
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions:[{
                label: 'CANCEL'
            },{
                functionId,
                label:'CONFIRM',
            }]
        }))
    }, [dispatch, functionStore, title])
    return <IssueCallbackRowAction tableRow={tableRow} access="ORDER.ONS010.ISSUE" callback={callback} />
}

const DeleteRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'delete' }), [intl])
    const callback = useCallback((tableRow: TableRow) => {
        const functionId = functionStore.register(() => {
            const coId = getCoInfo(tableRow.row as any, 'coId')
            coId !== null && dispatch(ons010Actions.deletePlaceOrder({ coId: coId, orderType: OrderType.SPOT }))
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions:[{
                label: 'CANCEL'
            },{
                functionId,
                label:'CONFIRM',
            }]
        }))
    }, [dispatch, functionStore, title])
    return <DeleteCallbackRowAction tableRow={tableRow} access="ORDER.ONS010.DELETE" callback={callback} />
}

function getCoInfo<K extends keyof TotCo>(row: TotCo | undefined, key: K): TotCo[K] | null {
    return row ? row[key] : null
}