import React, { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { useGetSoForecast } from "../../../services/order/apis/SalesOrderApi"
import { OrderForecastDetailResult } from "../../../services/order/models/OrderForecastDetailResult"
import { OSS012PcUi } from "./OSS012PcUi"

export const OSS012 = () => {

    const { orderId } = useParams() as any
    const [data, setData] = useState<OrderForecastDetailResult>({} as OrderForecastDetailResult)

    const getSoForecastInfo = useGetSoForecast()
    useEffect(() => {
        getSoForecastInfo({ orderId: orderId }, { silent: true, serialized: true }).then(result => {
            setData(result ?? {} as OrderForecastDetailResult)
        })
    }, [orderId, getSoForecastInfo, setData])

    const forecastDates = useMemo(() => {
        if (data && data.partsDetailList && data.partsDetailList.length > 0 && data.partsDetailList[0].forecastList) {
            return data.partsDetailList[0].forecastList.map(forecast => [forecast.targetFirstDate, forecast.targetLastDate])
        }
        return []
    }, [data])


    return <OSS012PcUi data={data} forecastDates={forecastDates} />
}