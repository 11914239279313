import React, { useEffect, useState } from "react"
import { useGetVisualizationReportView } from "../../../services/smt/api/VisualizationReportApi"
import { VisualizationReportView } from "../../../services/smt/models/VisualizationReportView"
import { VSRPS010PcUi } from "./VSRPS010PcUi"

export const VSRPS010 = () => {

    const [data, setData] = useState<VisualizationReportView[]>([])

    const getVisualizationReportView = useGetVisualizationReportView()
    useEffect(() => {
        getVisualizationReportView(undefined, { silent: true, serialized: true }).then(result => {
            setData(result || [])
        })
    }, [getVisualizationReportView, setData])

    return <VSRPS010PcUi
        data={data}
    />
}