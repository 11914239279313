import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { IntlString } from "../../../utils/IntlString"
import { Projects } from "../../../utils/Projects"
import { PlaceOrderDetailFactor, placeOrderDetailFactorSerializer } from "../models/PlaceOrderDetailFactor"
import { PlaceRegularOrderResult, placeRegularOrderResultDeserializer, placeRegularOrderResultSerializer } from "../models/PlaceOrderDetailResult"


export const useGetRegularOrderFormInfo = () => {
    const url = '/lcbm-order-api/order/pl/api/getRegularOrderFormInfo'
    const payloadProject = useCallback(placeOrderDetailFactorSerializer, [])
    const resultProject = useCallback(placeRegularOrderResultDeserializer, [])
    return usePost<PlaceOrderDetailFactor, PlaceRegularOrderResult>(url, payloadProject, resultProject)
}

export const useGetSpotOrderFormInfo = () => {
    const url = '/lcbm-order-api/order/pl/api/getSpotOrderFormInfo'
    const payloadProject = useCallback(placeOrderDetailFactorSerializer, [])
    const resultProject = useCallback(placeRegularOrderResultDeserializer, [])
    return usePost<PlaceOrderDetailFactor, PlaceRegularOrderResult>(url, payloadProject, resultProject)
}

export const useSavePlacedOrder = () => {
    const url = '/lcbm-order-api/order/pl/api/savePlacedOrder'
    const payloadProject = useCallback(placeRegularOrderResultSerializer, [])
    const resultProject = Projects.IGNORE
    return usePost<PlaceRegularOrderResult, undefined>(url, payloadProject, resultProject)
}

export const useSaveAndIssuePlacedOrder = () => {
    const url = '/lcbm-order-api/order/pl/api/saveAndIssuePlacedOrder'
    const payloadProject = useCallback(placeRegularOrderResultSerializer, [])
    const resultProject = useCallback((m: IntlString[]) => m, [])
    return usePost<PlaceRegularOrderResult, IntlString[]>(url, payloadProject, resultProject)
}

export const useCombineOrder = () => {
    const url = '/lcbm-order-api/order/combineOrder'
    const payloadProject = Projects.IGNORE
    const resultProject = Projects.IGNORE
    return usePost<undefined, undefined>(url, payloadProject, resultProject)
}

export const useRemoveExistsOrder = () => {
    const url = '/lcbm-order-api/order/pl/api/remove'
    const payloadProject = useCallback(obj => obj, [])
    const resultProject = Projects.IGNORE
    return usePost<{orderNo: String}, undefined>(url, payloadProject, resultProject)
}