import { IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core"
import { LocationOn } from "@material-ui/icons"
import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Row, Searching, Sorting, StringTypeProvider, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarLayout } from "@rithe/data-grid"
import { StringFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/StringFormatter"
import { Break, DateItem, Form, NumberItem, StringItem } from "@rithe/form"
import { Arrays, Records } from "@rithe/utils"
import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router"
import { PrintCallbackViewAction } from "../../../components/Action/PrintCallbackViewAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CodeItem } from "../../../components/Form/CodeItem"
import { View } from "../../../components/View/View"
import { usePrintDownloadPDF } from "../../../services/delivery/apis/deliveryOutboundApi"
import { useCheckShippingDetailConfirm } from "../../../services/delivery/apis/deliveryShippingDetailApi"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { CoStatus } from "../../../services/order/enums/CoStatus"
import { EstimatedData, InboundPalnData, OrderDetailHead, OrderPartsData } from "../../../services/order/models/OrderDetailResult"
import { useGetCodeName } from "../../../utils/CodeCategoryUtil"
import { applicationActions } from "../../Application/applicationSlice"


interface OCS011PcUiProps {
    headerInfo: OrderDetailHead,
    partsDetailList: OrderPartsData[]
}

export const OCS011PcUi = (props: OCS011PcUiProps) => {
    const { headerInfo, partsDetailList } = props
    const actions = [<PrintAction headerInfo={headerInfo}></PrintAction>]
    const intl = useIntl()
    return (
        <View actions={actions} key="OCS011View">
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={1}
                    title="Basic Info Detail"
                    subtitle={intl.formatMessage({ id: 'basicInfoSub' })}
                />
                <SectionCardContent>
                    <Step1PoMonitoringDetail headerInfo={headerInfo} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={2}
                    title="Shipping Plan List"
                    subtitle={intl.formatMessage({ id: 'contactInfoSub' })}
                />
                <SectionCardContent>
                    <Step2PoBaseInfoTable headerInfo={headerInfo} currentDate={headerInfo.currentDate} partsDetailList={partsDetailList} />
                </SectionCardContent>
            </SectionCard>
        </View>
    )
}

const Step1PoMonitoringDetail = memo((props: { headerInfo: OrderDetailHead }) => {
    const { headerInfo } = props
    const intl = useIntl()
    const { getCodeName } = useGetCodeName()
    const getStatusName = useCallback((data: any) => {
        const status = data.status as number
        const orgStatus = data.orgStatus as number
        return (orgStatus === CoStatus.FORCE_COMPLETED && status === CoStatus.PROCESSING) ? getCodeName(CodeCategory.CoStatus, status) + "(" + getCodeName(CodeCategory.CoStatus, orgStatus) + ")" : getCodeName(CodeCategory.CoStatus, status)
    }, [getCodeName])
    return <>
        <Form readonly data={headerInfo} labelDisplay="block" helperDisplay="tooltip">
            <StringItem field="orderNo" label={intl.formatMessage({ id: 'field.customerOrderNo' })} />
            <CodeItem field="orderType" label={intl.formatMessage({ id: 'field.orderType' })} code={CodeCategory.OrderType} />
            <StringItem field="status" label={intl.formatMessage({ id: 'field.status' })} getValue={getStatusName} />
            <StringItem field="orderRefNo" label={intl.formatMessage({ id: 'orderRefNo' })} />
            <CodeItem field="orderFrequency" label={intl.formatMessage({ id: 'field.orderFrequency' })} code={CodeCategory.OrderFrequency} />
            <StringItem field="customerCode" label={intl.formatMessage({ id: 'customerCode' })} />
            <DateItem field="submittedDate" label={intl.formatMessage({ id: 'field.submittedDate' })} />
            <StringItem field="submittedBy" label={intl.formatMessage({ id: 'field.submittedBy' })} />
            {headerInfo.contractNo && <StringItem field="contractNo" label={intl.formatMessage({ id: 'field.contractNo' })} />}
            {headerInfo.groupNo && <StringItem field="groupNo" label={intl.formatMessage({ id: 'field.groupNo' })} />}
            <DateItem field="receivedDate" label={intl.formatMessage({ id: 'receivedDate' })} />
            <DateItem field="completedDate" label={intl.formatMessage({ id: 'field.completedDate' })} />
            <CodeItem field="shippingMode" label={intl.formatMessage({ id: 'field.shippingMode' })} code={CodeCategory.ShippingMode} />
            <Break />
            <StringItem colSpan={2} field="paymentTermsDesc" label={intl.formatMessage({ id: 'field.paymentTermsDesc' })} multiline />
            <NumberItem field="totalAmount" label={intl.formatMessage({ id: 'totalAmount' })} suffix={headerInfo.currency} />
            <Break />
            <StringItem colSpan={2} field="remark" label={intl.formatMessage({ id: 'field.remark' })} multiline />
        </Form>
    </>
})

const Step2PoBaseInfoTable = memo((props: { headerInfo: OrderDetailHead, currentDate: Date, partsDetailList: OrderPartsData[] }) => {
    const { currentDate, partsDetailList, headerInfo } = props
    const intl = useIntl()
    const [order, setOrder] = useState<string[]>([])

    const palnDetas: InboundPalnData[] = useMemo(() => partsDetailList[0]?.inboundPlanList ?? [], [partsDetailList])
    const estimatedDatas: EstimatedData[] = useMemo(() => partsDetailList[0]?.estimatedInboundList ?? [], [partsDetailList])

    const { orgStatus, orderNo } = headerInfo
    const actionProps1 = { orderNo: orderNo }
    const { getCodeName } = useGetCodeName()
    const getStatusName = useCallback((row: Row) => {
        const status = row.detailStatus as number
        const inTransitQty = row.inTransitQty ?? 0
        const estimatedInboundList = row.estimatedInboundList ?? []
        const estimatedQty = estimatedInboundList.reduce((v1: any, v2: any) => v1 + (v2.qty ? v2.qty : 0), 0)
        const forceCompletedQty = row.forceCompletedQty ?? 0
        return (forceCompletedQty > 0 && (inTransitQty > 0 || estimatedQty > 0)) ? getCodeName(CodeCategory.CoStatus, CoStatus.PROCESSING) + "(" + getCodeName(CodeCategory.CoStatus, CoStatus.FORCE_COMPLETED) + ")" : getCodeName(CodeCategory.CoStatus, status)
    }, [getCodeName])
    const display1 = useCallback(() => headerInfo.status !== CoStatus.RECEIVED && headerInfo.status !== CoStatus.CANCELLED, [headerInfo.status])

    const columns = useMemo(() => {
        const fixedColumns = CoStatus.FORCE_COMPLETED === orgStatus ? [
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
            { field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'customerPartsNo' }), width: 240 },
            { field: 'backNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.backNo' }), width: 200 },
            { field: 'salesOrderNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'salesOrderNo' }), width: 200 },
            { field: 'supplierCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierCode' }), width: 200 },
            { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 100 },
            { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 150 },
            { field: 'orderQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderQty' }), width: 150 },
            { field: 'forceCompletedQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.forceCompletedQty' }), width: 220 },
            { field: 'unitPrice', dataTypeName: 'number', title: intl.formatMessage({ id: 'unitPrice' }), width: 150 },
            { field: 'currency', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.currency' }), width: 120 },
            { field: 'detailStatus', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.status' }), width: 220, getCellValue: getStatusName },
        ] : [
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
            { field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'customerPartsNo' }), width: 240 },
            { field: 'backNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.backNo' }), width: 200 },
            { field: 'salesOrderNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'salesOrderNo' }), width: 200 },
            { field: 'supplierCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierCode' }), width: 200 },
            { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 100 },
            { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 150 },
            { field: 'orderQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderQty' }), width: 150 },
            { field: 'forceCompletedQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.forceCompletedQty' }), width: 220 },
            { field: 'unitPrice', dataTypeName: 'number', title: intl.formatMessage({ id: 'unitPrice' }), width: 150 },
            { field: 'currency', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.currency' }), width: 120 },
            { field: 'detailStatus', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.status' }), width: 220, getCellValue: getStatusName },
        ]
        //Inbounded Qty(Current Date) Column
        const iCurrentCategories = [{ key: 'iCurrent', value: intl.formatMessage({ id: 'InboundedQtyCurrent' }) }]
        const iCurrentDateColumns = [{ field: 'currentInbQty', dataTypeName: 'number', title: intl.formatTime(currentDate, { dateStyle: 'medium' }), categories: iCurrentCategories, width: 240 }]

        //Inbound Plan Date Column
        const getPlanCategories = (index: number) => [
            { key: 'plan', value: intl.formatMessage({ id: 'field.inboundPlanDate' }) },
            { key: `plan${index}`, value: intl.formatDate(palnDetas[index].crdDate, { dateStyle: 'medium' }) },
        ]
        const inboundPlanColumns = Arrays.range(0, palnDetas.length).flatMap(index => [
            { field: 'crdQty' + index, dataTypeName: 'number', title: intl.formatMessage({ id: 'plan' }), categories: getPlanCategories(index), width: 150, getCellValue: getPlanDataValueByName(index, 'crdQty') },
            { field: 'status' + index, dataTypeName: 'status', title: intl.formatMessage({ id: 'field.status' }), categories: getPlanCategories(index), width: 150, getCellValue: getPlanDataValueByName(index, 'status') }
        ])

        // Estimated Inbound Date Column   
        const getEstimatedCategories = (index: number) => [
            { key: 'estimated', value: intl.formatMessage({ id: 'estimatedInboundDate' }) },
            { key: `estimated${index}`, value: intl.formatTime(estimatedDatas[index].estimatedCrdDate, { dateStyle: 'medium' }) }
        ]
        const estimatedInboundColums = Arrays.range(0, estimatedDatas.length).map(index => {
            return { field: `qty${index}`, dataTypeName: 'number', title: estimatedDatas[index].companyTitle ?? '', categories: getEstimatedCategories(index), width: 220, getCellValue: getEstimatedValue(index) }
        })

        // remove shortage qty is only for DI
        // const lastColumns = [{ field: 'shortageQty', dataTypeName: 'string', title: intl.formatMessage({ id: 'shortageQty' }), width: 180 }]
        // all columns
        // return Arrays.concat(fixedColumns, iCurrentDateColumns, inboundPlanColumns, estimatedInboundColums, lastColumns)
        return Arrays.concat(fixedColumns, iCurrentDateColumns, inboundPlanColumns, estimatedInboundColums)
    }, [currentDate, estimatedDatas, getStatusName, intl, orgStatus, palnDetas])

    useEffect(() => {
        setOrder(columns.map(column => column.field))
    }, [columns])

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <StatusTypeProvider />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.PoStatus} />
            <Data rows={partsDetailList} columns={columns} />
            <ColumnFreeze />
            <ToolbarActionProvider Action={ViewCargoTrackingAction} actionProps={actionProps1} display={display1} />
            <ColumnVisibility
                columnSettings={{
                    partsNo: { disableUserControl: true },
                }} ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnOrdering order={order} onOrderChange={setOrder} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
        </DataGrid>
    </div>
})

const getEstimatedValue = (index: number) => {
    return (row: Row) => {
        return row.estimatedInboundList[index].qty
    }
}

const getPlanDataValueByName = (index: number, columnName: string) => {
    return (row: Row) => {
        return row.inboundPlanList[index][columnName]
    }
}

const PrintAction = ({ headerInfo }: { headerInfo: OrderDetailHead }) => {
    const print = usePrintDownloadPDF()
    const click = useCallback(() => {
        if (headerInfo.orderId) {
            let coIds: string = JSON.stringify([headerInfo.orderId])
            print({ reportId: 'OCR010', labelId: 'Customers Order', param: coIds })
        }
    }, [headerInfo, print])
    return <PrintCallbackViewAction access="ORDER.OCS011.PRINT" callback={click} />
}

const CheckFormatter = ({ value }: StringFormatterProps) => {
    const style = useStyles()
    const bgcolor = 'OK' === value ? '#00CCAD' : '#D94C00'
    return <div className={style.status} style={{ background: bgcolor, color: 'white', width: '100%' }}>
        <Typography variant="body2" >{value}</Typography>
    </div>
}

const StatusTypeProvider = () => {
    return <StringTypeProvider name="status" Formatter={CheckFormatter} />
}

const ViewCargoTrackingAction = ({ orderNo }: { orderNo: string }) => {
    const checkConfirm = useCheckShippingDetailConfirm()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const click = useCallback(() => {
        checkConfirm({ orderNo: orderNo }, { silent: true }).then(result => {
            if (result === false) {
                dispatch(applicationActions.pushError({ title: { code: 'viewCargoTracking' }, messages: { code: 'w1039' } }))
            } else {
                navigate('/co/cargotrackingDetail-' + orderNo)
            }
        })
    }, [checkConfirm, dispatch, navigate, orderNo])

    return <Tooltip title={<FormattedMessage id="viewCargoTracking" />}>
        <IconButton onClick={click}>
            <LocationOn />
        </IconButton>
    </Tooltip>
}

const useStyles = makeStyles(theme => ({
    status: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70%',
        paddingRight: theme.spacing(1),
        borderRadius: 5
    },
    default: {},
}))