import { useCallback, useEffect, useState } from "react"
import { useGetCustomerByLoginUser } from "../../../services/smt/api/ComboboxApi"
import { useGetActualUsagePage } from "../../../services/smt/api/CustomerUsageApi"
import { ActualUsageEntityList } from "../../../services/smt/models/ActualUsageEntityList"
import { ActualUsageFactor } from "../../../services/smt/models/ActualUsageFactor"
import { Pair } from "../../../services/utils/Pair"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { CSUHS140PcUi } from "./CSUHS140PcUi"


const defaultFilters = { page: { currentPage: 0, pageSize: 100000 } }
export const CSUHS140 = () => {

    const [filters, setFilters] = useState<ActualUsageFactor>({ ...defaultFilters, usageDateStart: new Date(), usageDateEnd: new Date() })
    const [data, setData] = useState<ActualUsageEntityList[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [customerList, setCustomerList] = useState<Pair[]>([]);
    const getActualUsageList = useGetActualUsagePage()

    const search = useCallback((filters: ActualUsageFactor) => {
        getActualUsageList(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result.page?.totalCount || 0)
            setData(result.data ?? [])
        })
    }, [getActualUsageList, setData])

    useSearchOnLoad(search, filters)

    const getCustomerList = useGetCustomerByLoginUser()
    useEffect(() => {
        getCustomerList(undefined, { silent: true, serialized: true }).then((result) => {
            setCustomerList(result ?? [])
        })
    }, [getCustomerList])

    return <CSUHS140PcUi
        filters={filters}
        search={search}
        data={data}
        totalCount={totalCount}
        setFilters={setFilters}
        customerList={customerList}
    />
}