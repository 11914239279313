import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { WorkingFlag } from "../enums/WorkingFlag"

export type CalendarGetDateResult = Required & Partial<Optional>
type CalendarGetDateResultJson = JsonRequired & Partial<JsonOptional>

export const calendarGetDateResultFromJson: Project<CalendarGetDateResultJson, CalendarGetDateResult> = json => ({
    ...json,
    workingDate: dateFromJson(json.workingDate),
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'workingDate'> {
    workingFlag: WorkingFlag,
    workingDate: Date,
}

interface JsonRequired {
}

interface JsonOptional {
    workingFlag: number,
    workingDate: string,
}