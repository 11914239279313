import { Project } from "@rithe/utils";
import moment from "moment";



export type ProjectPlanHistoryResult = Required & Partial<Optional>;

type ProjectPlanHistoryResultJson = JsonRequired & Partial<JsonOptional>;

export const projectPlanHistoryResultFromJson: Project<ProjectPlanHistoryResultJson, ProjectPlanHistoryResult> = json => ({
    ...json,
    uploadDate: json.uploadDate ? moment(json.uploadDate).toDate() : undefined,
})

interface Required extends JsonRequired {

}

interface Optional extends Omit<JsonOptional, 'uploadDate'> {
    uploadDate: Date,
}

interface JsonRequired {

}

interface JsonOptional {
    planHistoryId: number,
    projectId: number,
    productionPlanNo: string,
    remarks: string,
    uploadDate: string,
    activeFlag: number,
    customerId: number,
    noOfLevel: number,
}
