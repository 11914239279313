import { makeStyles, Typography } from "@material-ui/core"

export function ViewFooter() {
    const styles = useStyles()
    return <div className={styles.root}>
        <Typography variant="body2" className={styles.copyright}>
            {'Copyright © 2021 BriVge. All rights reserved.'}
        </Typography>
    </div>
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: theme.layout.copyrightBarHeight,
        flex: '0 0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#231F20',
    },
    copyright: {
        color: theme.palette.common.white,
    },
}))