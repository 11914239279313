import { CloudUpload } from "@material-ui/icons"
import React, { ReactNode, useCallback, useRef } from "react"
import { FormattedMessage } from "react-intl"
import { GroupedToolbarAction } from "./GroupedToolbarAction"

interface UploadGroupedToolbarActionProps {
    access?: string,
    power?: boolean,
    title?: NonNullable<React.ReactNode>,
    icon?: NonNullable<React.ReactNode>,
    children?: (upload: (callback: (files: FileList | null) => void) => void, onClose: () => void) => ReactNode | ReactNode[],
}

export const UploadGroupedToolbarAction = (props: UploadGroupedToolbarActionProps) => {
    const {
        access,
        power,
        title = <FormattedMessage id="upload" />,
        icon = <CloudUpload />,
        children,
    } = props

    const inputRef = useRef<HTMLInputElement | null>(null)
    const uploadCallbackRef = useRef<(files: FileList | null) => void>()
    const onPopup = useCallback(() => {
        inputRef.current && inputRef.current.click()
    }, [])
    const onChange = useCallback(() => {
        if (inputRef.current && uploadCallbackRef.current) {
            uploadCallbackRef.current(inputRef.current.files)
            inputRef.current.value = ''
        }
    }, [])
    const upload = useCallback((callback: (files: FileList | null) => void) => {
        onPopup()
        uploadCallbackRef.current = callback
    }, [onPopup])

    return <>
        <GroupedToolbarAction access={access} power={power} title={title} icon={icon}>
            {children ? onClose => children(upload, onClose) : undefined}
        </GroupedToolbarAction>
        <input ref={inputRef} type="file" onChange={onChange} style={{ display: 'none' }} />
    </>
}