import {Project} from "../../../utils/Project"

export type OrderGroupDetailPartsResult = Required & Partial<Optional>
type OrderGroupDetailPartsResultJson = JsonRequired & Partial<JsonOptional>
export const orderGroupDetailPartsResultFromJson: Project<OrderGroupDetailPartsResultJson, OrderGroupDetailPartsResult> = json => ({
    ...json,
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {

}

interface JsonRequired {
}

interface JsonOptional {
    customerPartsNo: string,
    partsNo: string,
    customerCode: string,
    blockInfoList: BlockInfo[]
}

interface BlockInfo {
    cbdsId: number,
    cbdsCode: string,
    unitPartsNo: string,
    orderLot: number,
    spq: number,
    price: number,
    customsFlag: string,
    srId: number,
    shippingRouteCode: string,
    shipper: string,
    receiver: string
}