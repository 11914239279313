import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Row, RowActionProvider, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { EntryItem } from "@rithe/form"
import { Arrays, Maps, Records } from "@rithe/utils"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CreateCallbackRowAction } from "../../../components/DataGrid/rowActions/CreateCallbackRowAction"
import { DeleteCallbackRowAction } from "../../../components/DataGrid/rowActions/DeleteCallbackRowAction"
import { EditCallbackRowAction } from "../../../components/DataGrid/rowActions/EditCallbackRowAction"
import { IssueCallbackRowAction } from "../../../components/DataGrid/rowActions/IssueCallbackRowAction"
import { ViewCallbackRowAction } from "../../../components/DataGrid/rowActions/ViewCallbackRowAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { effectiveLanguage, Language } from "../../../configs/i18n/Language"
import { applicationActions, useApplicationSelector } from "../../../layouts/Application/applicationSlice"
import { useFunctionStore } from "../../../Root"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { CoStatus } from "../../../services/order/enums/CoStatus"
import { OrderFrequency } from "../../../services/order/enums/OrderFrequency"
import { OrderType } from "../../../services/order/enums/OrderType"
import { placeOrderDetailFactorSerializer } from "../../../services/order/models/PlaceOrderDetailFactor"
import { PlaceOrderListResult } from "../../../services/order/models/PlaceOrderListResult"
import { TotCo } from "../../../services/order/models/TotCo"
import { formatDateRange } from "../../../utils/formatDateRange"
import sessionKeys from "../../../utils/sessionKeys"
import { useSetDataIntoSession } from "../../../utils/sessionUtil"
import { ons010Actions, useONS010Selector } from "./ONS010Slice"

const SEARCH_KEY = sessionKeys.Data_ONS010_Regular

export function ONS010RegularTabContent() {
    return <SectionCard>
        <SectionCardContent>
            <Initializer />
            <DataTable />
        </SectionCardContent>
    </SectionCard>
}

function Initializer() {
    const dispatch = useDispatch()
    useEffect(() => {
        const sessionValue = sessionStorage.getItem(SEARCH_KEY)
        if (sessionValue) {
            const filter = JSON.parse(sessionValue)
            dispatch(ons010Actions.changeRegularDataFilter({
                orderFrequency: filter.orderFrequency,
                orderStartDate: filter.orderStartDate ? new Date(filter.orderStartDate) : null,
                orderEndDate: filter.orderEndDate ? new Date(filter.orderEndDate) : null,
            }))
        }
        dispatch(ons010Actions.loadAvailableOrderFrequencies())
        dispatch(ons010Actions.loadAvailableOrderPeriods(OrderFrequency.BI_WEEKLY))
        dispatch(ons010Actions.loadAvailableOrderPeriods(OrderFrequency.MONTHLY))
        dispatch(ons010Actions.loadAvailableOrderPeriods(OrderFrequency.TEN_DAY))
        dispatch(ons010Actions.loadAvailableOrderPeriods(OrderFrequency.WEEKLY))
    }, [dispatch])

    const filter = useONS010Selector(state => state.regularDataFilter)
    const [initialized, setInitialized] = useState(false)
    useEffect(() => {
        if (!initialized && filter.orderFrequency !== null && filter.orderStartDate !== null && filter.orderEndDate !== null) {
            dispatch(ons010Actions.searchRegularOrderPlacements())
            setInitialized(true)
        }
    }, [dispatch, filter.orderEndDate, filter.orderFrequency, filter.orderStartDate, initialized])

    return null
}

const getCoNoCellValue = (row: PlaceOrderListResult) => getCoInfo(row, 'customerOrderNo')
const getCustomerRefNoCellValue = (row: PlaceOrderListResult) => getCoInfo(row, 'customerRefNo')
const getCoDateCellValue = (row: PlaceOrderListResult) => getCoInfo(row, 'coDate')
const getCoStatusCellValue = (row: PlaceOrderListResult) => getCoInfo(row, 'status')
const getBusinessTypeCellValue = (row: PlaceOrderListResult) => getCoInfo(row, 'businessType')

function DataTable() {
    const intl = useIntl()
    const data = useONS010Selector(state => state.regularOrderPlacements)

    const getTargetDateRange = useCallback((row: any) => formatDateRange(intl, row.targetFirstDate, row.targetLastDate), [intl])
    const columns = useMemo(() => [
        { field: 'customerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerCode' }), width: 200 },
        { field: 'sellerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'sellerCode' }), width: 200 },
        { field: 'customerContractNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'customerContractNo' }), width: 300 },
        { field: 'contractDescription', dataTypeName: 'string', title: intl.formatMessage({ id: 'contractDescription' }), width: 350 },
        { field: 'contractRouteCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'contractRouteNo' }), width: 300 },
        { field: 'routeDescription', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.routeDescription' }), width: 350 },
        { field: 'supplierCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierCode' }), width: 180 },
        { field: 'targetDateRange', dataTypeName: 'string', title: intl.formatMessage({ id: 'targetPeriod' }), width: 220, getCellValue: getTargetDateRange as (row: Row) => any },
        { field: 'orderDueDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'orderDueDate' }), width: 200 },
        { field: 'coNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerOrderNo' }), width: 250, getCellValue: getCoNoCellValue as (row: Row) => any },
        { field: 'orderRefNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerRefNo' }), width: 300, getCellValue: getCustomerRefNoCellValue as (row: Row) => any },
        { field: 'coDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.coDate' }), width: 150, getCellValue: getCoDateCellValue as (row: Row) => any },
        { field: 'status', dataTypeName: CodeCategory.CoStatus, title: intl.formatMessage({ id: 'field.status' }), width: 150, getCellValue: getCoStatusCellValue as (row: Row) => any },
        { field: 'businessType', dataTypeName: CodeCategory.BusinessType, title: intl.formatMessage({ id: 'field.businessType' }), width: 180, getCellValue: getBusinessTypeCellValue as (row: Row) => any },
        { field: 'receiverDcCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'receiverCode' }), width: 180 },
        { field: 'currency', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.currency' }), width: 180 },
    ], [getTargetDateRange, intl])
    const getRowId = useCallback((row: any) => row.contractRouteId, [])

    const display1 = useCallback((tableRow: TableRow) => !getCoInfo(tableRow.row as any, 'status'), [])
    const display2 = useCallback((tableRow: TableRow) => getCoInfo(tableRow.row as any, 'status') === CoStatus.DRAFT, [])
    const display3 = useCallback((tableRow: TableRow) => !!getCoInfo(tableRow.row as any, 'status') && getCoInfo(tableRow.row as any, 'status') !== CoStatus.DRAFT, [])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.CoStatus} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.BusinessType} />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <RowActionProvider name="create" Action={CreateRegularRowAction} display={display1} />
        <RowActionProvider name="edit" Action={EditRegularRowAction} display={display2} />
        <RowActionProvider name="issue" Action={IssueRowAction} display={display2} />
        <RowActionProvider name="delete" Action={DeleteRowAction} display={display2} />
        <RowActionProvider name="view" Action={ViewRegularRowAction} display={display3} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['sellerCode', 'contractDescription', 'routeDescription', 'coNo', 'orderRefNo', 'coDate', 'businessType', 'receiverDcCode', 'currency']}
            columnSettings={{
                customerCode: { disableUserControl: true },
                customerContractNo: { disableUserControl: true }
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={RegularFilterItem} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Action width={124} />
    </DataGrid>
}

const RegularFilterItem = () => {
    return <RegularFilter />
}

function RegularFilter() {
    const dispatch = useDispatch()
    const intl = useIntl()
    const regularDataFilter = useONS010Selector(state => state.regularDataFilter)
    const orderFrequencies = useONS010Selector(state => state.availableOrderFrequencies)
    const orderPeriodsRecord = useONS010Selector(state => state.availableOrderPeriodsRecord)

    const generateDraftFilter = useCallback(() => {
        const orderFrequency = regularDataFilter.orderFrequency
        const orderPeriods = orderFrequency !== null ? orderPeriodsRecord[orderFrequency] ?? [] : []
        const orderPeriodIndex = orderPeriods.findIndex(period =>
            period[0].getTime() === regularDataFilter.orderStartDate?.getTime()
            && period[1].getTime() === regularDataFilter.orderEndDate?.getTime())
        return { orderFrequency, orderPeriodIndex }
    }, [orderPeriodsRecord, regularDataFilter.orderEndDate, regularDataFilter.orderFrequency, regularDataFilter.orderStartDate])

    const [draftFilter, setDraftFilter] = useState(generateDraftFilter)

    const onDraftFilterChange = useCallback<React.Dispatch<React.SetStateAction<{ orderFrequency: OrderFrequency | null, orderPeriodIndex: number }>>>(nextDraftFilterFunc => {
        setDraftFilter(prevDraftFilter => {
            const nextDraftFilter = typeof nextDraftFilterFunc === 'function' ? nextDraftFilterFunc(prevDraftFilter) : nextDraftFilterFunc
            if (nextDraftFilter.orderFrequency !== draftFilter.orderFrequency) {
                return { ...nextDraftFilter, orderPeriodIndex: -1 }
            } else {
                return nextDraftFilter
            }
        })
    }, [draftFilter.orderFrequency])

    const onOpen = useCallback(() => {
        setDraftFilter(generateDraftFilter())
    }, [generateDraftFilter])

    const onSubmit = useCallback(({ orderFrequency, orderPeriodIndex }: { orderFrequency: OrderFrequency | null, orderPeriodIndex: number }) => {
        if (orderFrequency === null || orderFrequency === undefined || orderPeriodIndex < 0 || orderPeriodIndex === undefined) {
            dispatch(applicationActions.pushError({ title: 'Search Regular Order', messages: intl.formatMessage({ id: 'w0696' }) }))
        } else {
            const orderPeriods = orderPeriodsRecord[orderFrequency] ?? []
            const orderPeriod = orderPeriods[orderPeriodIndex] ?? [null, null]
            dispatch(ons010Actions.changeRegularDataFilter({
                orderFrequency,
                orderStartDate: orderPeriod[0],
                orderEndDate: orderPeriod[1],
            }))
            sessionStorage.setItem(SEARCH_KEY, JSON.stringify({
                orderFrequency,
                orderStartDate: orderPeriod[0].getTime(),
                orderEndDate: orderPeriod[1].getTime(),
            }))
            dispatch(ons010Actions.searchRegularOrderPlacements())
        }
    }, [dispatch, intl, orderPeriodsRecord])

    const codeCategories = useApplicationSelector(state => state.cache.codeCategories ?? [])
    const language = useApplicationSelector(state => state.i18n.language)

    const orderFrequencyEntries = useMemo<[number, string][]>(() => {
        const constructOptions = (language: string) => codeCategories.filter(cc =>
            cc.codeCategory === CodeCategory.OrderFrequency
            && cc.language && effectiveLanguage(cc.language) === language
            && cc.codeValue !== undefined
            && orderFrequencies.includes(cc.codeValue)
            && cc.codeName !== undefined).map(cc => [cc.codeValue!, cc.codeName!] as [number, string])
        return Arrays.from(
            Maps.from([...constructOptions(Language.DEFAULT), ...constructOptions(language)]).entries()
        )
    }, [codeCategories, language, orderFrequencies])

    const orderPeriodEntries = useMemo<[number, string][]>(() => {
        const orderPeriods = draftFilter.orderFrequency !== null ? orderPeriodsRecord[draftFilter.orderFrequency] ?? [] : []
        return orderPeriods.map(([startDate, endDate], index) => [index, formatDateRange(intl, startDate, endDate)])
    }, [draftFilter.orderFrequency, intl, orderPeriodsRecord])

    const filterCounter = useCallback(() => {
        return [
            regularDataFilter.orderFrequency !== null,
            regularDataFilter.orderStartDate !== null || regularDataFilter.orderEndDate !== null,
        ].filter(value => value).length
    }, [regularDataFilter.orderEndDate, regularDataFilter.orderFrequency, regularDataFilter.orderStartDate])

    return <FilterToolbarItem columnCount={2} filters={draftFilter} onFiltersChange={onDraftFilterChange} onOpen={onOpen} onSubmit={onSubmit} filterCounter={filterCounter}>
        <EntryItem field="orderFrequency" label={intl.formatMessage({ id: 'field.orderFrequency' })} entries={orderFrequencyEntries} />
        <EntryItem field="orderPeriodIndex" label={intl.formatMessage({ id: 'orderPeriod' })} entries={orderPeriodEntries} />
    </FilterToolbarItem>
}


const IssueRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'Issue' }), [intl])
    const callback = useCallback((tableRow: TableRow) => {
        const functionId = functionStore.register(() => {
            const coId = getCoInfo(tableRow.row as any, 'coId')
            coId && dispatch(ons010Actions.issuePlaceOrder({ coId: coId, orderType: OrderType.REGULAR }))
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [dispatch, functionStore, title])
    return <IssueCallbackRowAction tableRow={tableRow} access="ORDER.ONS010.ISSUE" callback={callback} />
}

const DeleteRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'delete' }), [intl])
    const callback = useCallback((tableRow: TableRow) => {
        const functionId = functionStore.register(() => {
            const coId = getCoInfo(tableRow.row as any, 'coId')
            coId && dispatch(ons010Actions.deletePlaceOrder({ coId: coId, orderType: OrderType.REGULAR }))
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [dispatch, functionStore, title])
    return <DeleteCallbackRowAction tableRow={tableRow} access="ORDER.ONS010.DELETE" callback={callback} />
}

const CreateRegularRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const navigate = useNavigate()
    const setSesion = useSetDataIntoSession()
    const callback = useCallback((tableRow: TableRow) => {
        setSesion(sessionKeys.Data_ONS011, placeOrderDetailFactorSerializer, {
            contractRouteId: tableRow.row?.contractRouteId,
            coId: null,
            orderStartDate: tableRow.row?.orderFirstDate,
            orderEndDate: tableRow.row?.orderLastDate
        })
        navigate(`/placecustorder-regular/create-${tableRow.row?.contractRouteId}`)
    }, [navigate, setSesion])
    return <CreateCallbackRowAction tableRow={tableRow} access="ORDER.ONS010.CREATE" callback={callback} />
}

const EditRegularRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const navigate = useNavigate()
    const setSesion = useSetDataIntoSession()
    const callback = useCallback((tableRow: TableRow) => {
        setSesion(sessionKeys.Data_ONS011, placeOrderDetailFactorSerializer, {
            contractRouteId: tableRow.row?.contractRouteId,
            coId: getCoInfo(tableRow.row as any, 'coId'),
            orderStartDate: tableRow.row?.orderFirstDate,
            orderEndDate: tableRow.row?.orderLastDate
        })
        navigate(`/placecustorder-regular/create-${tableRow.row?.contractRouteId}`)
    }, [navigate, setSesion])
    return <EditCallbackRowAction tableRow={tableRow} access="ORDER.ONS010.EDIT" callback={callback} />
}

const ViewRegularRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const navigate = useNavigate()
    const setSesion = useSetDataIntoSession()
    const callback = useCallback((tableRow: TableRow) => {
        setSesion(sessionKeys.Data_ONS011, placeOrderDetailFactorSerializer, {
            contractRouteId: tableRow.row?.contractRouteId,
            coId: getCoInfo(tableRow.row as any, 'coId'),
            orderStartDate: tableRow.row?.orderFirstDate,
            orderEndDate: tableRow.row?.orderLastDate
        })
        navigate(`/placecustorder-regular/view-${tableRow.row?.contractRouteId}`)
    }, [navigate, setSesion])
    return <ViewCallbackRowAction tableRow={tableRow} access="ORDER.ONS010.VIEWDETAIL" callback={callback} />
}

function getCoInfo<K extends keyof TotCo>(row: PlaceOrderListResult | undefined, key: K): TotCo[K] | null {
    return row && row.coList && row.coList.length > 0 ? row.coList[0][key] : null
}