import { Project } from "../../../utils/Project"
import { Distatus } from "../enums/DiStatus"


export type CustDIParts = Required & Partial<Optional>

type CustDIPartsJson = JsonRequired & Partial<JsonOptional>

export const custDIPartsFromJson: Project<CustDIPartsJson, CustDIParts> = json => ({
    ...json,
})

interface Required extends JsonRequired {}

interface Optional extends JsonOptional {
    moduleStatus: Distatus,
    partsStatus: Distatus,
}

interface JsonRequired {
}

interface JsonOptional {
    moduleStatus: number,
    partsStatus: number,
    deliveredQty: number,
    moduleNo: string,
    partsNo: string,
    oldPartsNo: string,
    uom: string,
    colorCode: string,
    customerPartsNo: string,
    customerPartsName: string,
    pairedPartsNo: string,
    digits: number,
    qty: number,
    length: number,
    width: number,
    height: number,
    m3: number,
    netWeight: number,
    grossWeight: number,
    moduleCount: number
}
