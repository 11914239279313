import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { getCustomerDIDetail, getCustomerDIHeader } from "../../../services/delivery/apis/deliveryCustApi"
import { DiType } from "../../../services/delivery/enums/DiType"
import { CustDIHeaderInfo } from "../../../services/delivery/models/CustDIHeaderInfo"
import { CustDIParts } from "../../../services/delivery/models/CustDIParts"
import { LDS011PcUi } from "./LDS011PcUi"

export const LDS011 = () => {
    const { diId } = useParams() as any
    const [diType, setDiType] = useState<number>()
    const [headerInfo, setHeaderInfo] = useState<CustDIHeaderInfo>({} as any)
    const [partsList, setPartsList] = useState<CustDIParts[]>([])
    const [moduleList, setModuleList] = useState<CustDIByModule[]>([])

    const getHeaderInfo = getCustomerDIHeader()
    const getPartsList = getCustomerDIDetail()


    useEffect(() => {
        if (diId) {
            getHeaderInfo({ diId: diId }, { silent: true }).then(result => {
                setHeaderInfo(result ?? {} as CustDIHeaderInfo)
                setDiType(result?.diType || undefined)
            })
            getPartsList({ diId: diId }, { silent: true }).then(result => {
                setPartsList(result ?? [])
                setModuleList(dataReorganization(result ?? []))
            })
        }
    }, [diId, getHeaderInfo, getPartsList, setHeaderInfo])

    const props = {
        diId: diId,
        diType: diType,
        headerInfo: headerInfo,
        partsList: partsList,
        moduleList: moduleList,
    }

    return <LDS011PcUi {...props} />
}

export interface CustDIByModule {
    rowNo: number,
    dataType: DiType,
    moduleNo: string,
    lengthPartNo: number | string,
    widthCustPartsNo: number | string,
    heightOldPartsNo: number | string,
    m3ColorCode: number | string,
    nwCustPartsName: number | string,
    gwPairedPartsNo: number | string,
    status?: number,
    qty?: number,
    uom?: string,
}

const dataReorganization = (partsList: CustDIParts[]) => {
    const dataList: CustDIByModule[] = []
    let moduleNo: any = null
    let rowNo = 0
    partsList.forEach((m) => {
        if (m.moduleNo !== moduleNo) {
            // set header
            dataList.push({
                rowNo: 0,
                dataType: DiType.BY_MODULE,
                moduleNo: m.moduleNo ?? '',
                lengthPartNo: m.length ?? '',
                widthCustPartsNo: m.width ?? '',
                heightOldPartsNo: m.height ?? '',
                m3ColorCode: m.m3 ?? '',
                nwCustPartsName: m.netWeight ?? '',
                gwPairedPartsNo: m.grossWeight ?? '',
                status: m.moduleStatus,
                qty: partsList.filter(f => f.moduleNo === m.moduleNo).map(part => part.qty ?? 0).reduce((a, b) => a + b, 0)
            })
            rowNo = 1
        } else {
            rowNo++
        }
        // set detail data
        dataList.push({
            rowNo: rowNo,
            dataType: DiType.BY_PARTS,
            moduleNo: m.moduleNo ?? '',
            lengthPartNo: m.partsNo ?? '',
            widthCustPartsNo: m.customerPartsNo ?? '',
            heightOldPartsNo: m.oldPartsNo ?? '',
            m3ColorCode: m.colorCode ?? '',
            nwCustPartsName: m.customerPartsName ?? '',
            gwPairedPartsNo: m.pairedPartsNo ?? '',
            status: m.partsStatus,
            qty: m.qty,
            uom: m.uom,
        })
        // reset container no
        moduleNo = m.moduleNo
    })
    return dataList
}

