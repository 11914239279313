import { NumberTypeProvider } from '@rithe/data-grid'
import React, { useMemo } from 'react'

interface PercentTypeProviderProps {
    name?: string,
    fractionDigits?: number,
}

export const PercentTypeProvider = (props: PercentTypeProviderProps) => {
    const { name = 'percent', fractionDigits } = props
    const options = useMemo(() => ({ style: 'percent', minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits }), [fractionDigits])
    return <NumberTypeProvider name={name} options={options} />
}