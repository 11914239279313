import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { EntriesItem, StringItem } from "@rithe/form"
import { Objects, Records } from "@rithe/utils"
import React, { useCallback, useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { View } from "../../../components/View/View"
import { useDownloadInventoryByParts } from "../../../services/managementTool/apis/mtDownloadApis"
import { InventoryByPartsFactor } from "../../../services/managementTool/models/InventoryByPartsFactor"
import { InventoryByPartsResult } from "../../../services/managementTool/models/InventoryByPartsResult"
import { TnmCustomer } from "../../../services/master/models/TnmCustomer"
import { TnmDistributionCenter } from "../../../services/master/models/TnmDistributionCenter"

interface MTS020PcUiProps {
    search: (filters: InventoryByPartsFactor) => void,
    data: InventoryByPartsResult[],
    totalCount: number,
    customerList: TnmCustomer[],
    dcList: TnmDistributionCenter[],
    filters: InventoryByPartsFactor,
    setFilters: React.Dispatch<React.SetStateAction<Partial<InventoryByPartsFactor>>>,
}

export const MTS020PcUi = (props: MTS020PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({ filters, dcList, customerList, data, setFilters, search }: MTS020PcUiProps) => {
    const intl = useIntl()

    const columns = useMemo(() => [
        { field: 'dcCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.dcCode' }), width: 200 },
        { field: 'customerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerCode' }), width: 200 },
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
        { field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsNo' }), width: 200 },
        { field: 'backNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.backNo' }), width: 200 },
        { field: 'colorCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.colorCode' }), width: 200 },
        // { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 200 },
        { field: 'incomingQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.incomingQty' }), width: 200 },
        { field: 'stockQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.stockQty' }), width: 200 },
    ], [intl])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search, customerList, dcList }), [filters, setFilters, search, customerList, dcList])
    const actionProps1 = useMemo(() => ({ filters }), [filters])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <Data rows={data} columns={columns} />
        <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps1} />
        <ColumnFreeze />
        <ColumnVisibility columnSettings={{
            dcCode: { disableUserControl: true },
            customerCode: { disableUserControl: true },
            partsNo: { disableUserControl: true },
        }}
            ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
    </DataGrid>
}

const FilterItem = (props: {
    filters: InventoryByPartsFactor,
    setFilters: React.Dispatch<React.SetStateAction<Partial<InventoryByPartsFactor>>>,
    customerList: TnmCustomer[],
    dcList: TnmDistributionCenter[],
    search: (filters: InventoryByPartsFactor) => void,
}) => {
    const { filters, customerList, dcList, setFilters, search } = props
    const customerEntries: [number, string][] = useMemo(() => customerList.map(m => [m.customerId, m.customerCode]), [customerList])
    const dcEntries: [number, string][] = useMemo(() => dcList.map(m => [m.dcId, m.dcCode]), [dcList])

    const clear = useCallback((filters: InventoryByPartsFactor) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: InventoryByPartsFactor) => {
        return [
            filters.dcIdList,
            filters.customerIdList,
            filters.partsNo,
            filters.unitPartsNo,
            filters.backNo,
            filters.colorCode,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <EntriesItem field="dcIdList" label={intl.formatMessage({ id: 'field.dcCode' })} entries={dcEntries} />
        <EntriesItem field="customerIdList" label={intl.formatMessage({ id: 'field.customerCode' })} entries={customerEntries} />
        <StringItem field="partsNo" label={intl.formatMessage({ id: 'field.partsNo' })} />
        <StringItem field="unitPartsNo" label={intl.formatMessage({ id: 'field.unitPartsNo' })} />
        <StringItem field="backNo" label={intl.formatMessage({ id: 'field.backNo' })} />
        <StringItem field="colorCode" label={intl.formatMessage({ id: 'field.colorCode' })} />
    </FilterToolbarItem>
}

const DownloadAction = ({ filters }: { filters: InventoryByPartsFactor }) => {
    const downloadApi = useDownloadInventoryByParts()
    const downloadMaster = useCallback(() => {
        downloadApi(Objects.delete(filters, 'page'))
    }, [filters, downloadApi])
    return <DownloadGroupedToolbarAction access="MT.MTS020.DOWNLOAD">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="download" />} callback={downloadMaster} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}
