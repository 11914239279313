import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useGetExpCustomsInvoicePageByVO } from "../../../services/accounting/apis/accountingSellerApi"
import { CustomsInvoiceTransfer } from "../../../services/accounting/models/CustomsInvoiceTransfer"
import { CustomsInvoiceView, customsInvoiceViewFromJson, customsInvoiceViewToJson } from "../../../services/accounting/models/CustomsInvoiceView"
import { useComMasterGetAllPort } from "../../../services/common/apis/commonMasterApi"
import { TnmPort } from "../../../services/master/models/TnmPort"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { AIS030PcUi } from "./AIS030PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }
const searchCachekey = sessionKeys.Filters_AIS030
export const AIS030 = () => {

    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? customsInvoiceViewFromJson(JSON.parse(filters)) : null
    }, [])

    const [filters, setFilters] = useState<CustomsInvoiceView>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<CustomsInvoiceTransfer[]>([])
    const [selections, setSelections] = useState<string[]>([])
    const [portList, setPortList] = useState<TnmPort[]>([])

    const getDataList = useGetExpCustomsInvoicePageByVO()
    const search = useCallback((filters: CustomsInvoiceView) => {
        getDataList(filters, { serialized: true, silent: true }).then(result => {
            setData(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(customsInvoiceViewToJson(filters)))
        })
    }, [getDataList, setData])

    useSearchOnLoad(search, filters)

    const getPortList = useComMasterGetAllPort()
    useEffect(() => {
        getPortList(undefined, { silent: true }).then(result => {
            setPortList(result || [])
        })
    }, [getPortList])

    return <AIS030PcUi
        filters={filters}
        search={search}
        data={data}
        selections={selections}
        setSelections={setSelections}
        setFilters={setFilters}
        portList={portList}
    />
}