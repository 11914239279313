import { Tooltip } from "@material-ui/core"
import { Pageview } from "@material-ui/icons"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { FormattedMessage } from "react-intl"
import { useNavigate } from "react-router-dom"
import { Access } from "../../../components/Access/Access"
import { IconButton } from "../../../components/Button/IconButton"
import { OrderCalcStatus } from "../../../services/order/enums/OrderCalcStatus"
import { ListTableRow } from "./ListTable"
import { OrderCalculationType } from "./OCCLS010Slice"

export interface ViewRowActionProps extends DataGridRowActionProps {
  type: OrderCalculationType
}

export function ViewRowAction(props: ViewRowActionProps) {
  const { tableRow, type } = props
  const row = tableRow.row as ListTableRow

  let display = row.status !== OrderCalcStatus.PENDING_CALCULATION
  if (type === 'PNA') {
    display = display && (row.orderCalcCount === 1 || (row.orderVersion ?? 0) > 1)
  } else {
    display = display && row.orderCalcCount === 1
  }
  const navigate = useNavigate()

  if (!display) {
    return null
  } else {
    return <Access access="STCK.OCCLS010.VIEWDETAIL">
      <Tooltip title={<FormattedMessage id="view" />}>
        <IconButton onClick={() => {
          if (row.orderVersion === 1) {
            navigate(type === 'PNA'
              ? `/orderCalculationPNA/viewRegular-${row.orderCalcId}`
              : type === 'Ehm'
                ? `/orderCalculationEhm/viewRegular-${row.orderCalcId}`
                : `/orderCalculation/viewRegular-${row.orderCalcId}`)
          } else {
            navigate(type === 'PNA'
              ? `/orderCalculationPNA/viewSpot-${encodeURIComponent(row.orderCalcNo ?? '')}`
              : type === 'Ehm'
                ? `/orderCalculationEhm/viewSpot-${encodeURIComponent(row.orderCalcNo ?? '')}`
                : `/orderCalculation/viewSpot-${encodeURIComponent(row.orderCalcNo ?? '')}`)
          }
        }}>
          <Pageview />
        </IconButton>
      </Tooltip>
    </Access>
  }
}