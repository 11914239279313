import { useCallback } from "react"
import { Projects } from "../../../utils/Projects"
import { useDownload } from "../../../utils/fetch/useFetch"
import { ActualUsageFactor, actualUsageFactorToJson } from "../models/ActualUsageFactor"
import { AlarmUsagePatternView, alarmUsagePatternViewToJson } from "../models/AlarmUsagePatternView"
import { CustomerUsageView120, customerUsageViewToJson120 } from "../models/CustomerUsageView120"
import { DirectInputDownloadFactor, directInputDownloadFactorToJson } from "../models/DirectInputDownloadFactor"
import { DownloadFiledsFactor, downloadFiledsFactorToJson } from "../models/DownloadFiledsFactor"
import { ImpInventoryListFactor, impInventoryListFactorToJson } from "../models/ImpInventoryListFactor"
import { InputDownloadDataRangeFactor, inputDownloadDataRangeFactorToJson } from "../models/InputDownloadDataRangeFactor"
import { OCPlaceRegularOrderResult, ocPlaceRegularOrderResultSerializer } from "../models/OCPlaceOrderDetailResult"
import { OrderCalculationView, orderCalculationViewToJson } from "../models/OrderCalculationView"
import { ProjectPlanFactor, projectPlanFactorToJson } from "../models/ProjectPlanFactor"
import { StockManagementMasterView } from "../models/StockMangementMasterView"
import { WIPAdjustmentFactor, wipAdjustmentFactorToJson } from "../models/WIPAdjustmentFactor"
import { IncomingDownloadFactor, incomingDownloadFactorToJson } from "../stockManagement/models/IncomingDownloadFactor"
import { OutboundDownloadFactor, outboundDownloadFactorToJson } from "../stockManagement/models/OutboundDownloadFactor"
import { PokayokeDownloadFactor, pokayokeDownloadFactorToJson } from "../stockManagement/models/PokayokeDownloadFactor"
import { WeeklyRundownDownloadFactor, weeklyRundownDownloadFactorToJson } from "../stockManagement/models/WeeklyRundownFactor"


// CSUGS120
export const useDownloadDirectInputPlan = () => {
    const url = '/smt-api/usage/downloadCustomerUsage'
    const payloadProject = useCallback(directInputDownloadFactorToJson, [])
    return useDownload<DirectInputDownloadFactor>(url, payloadProject, { fileTimestamp: true })
}

// CSUHS120
export const useDownloadUasgeOfTTC = () => {
    const url = '/smt-api/usage/downloadUasgeOfTTC'
    const payloadProject = useCallback(customerUsageViewToJson120, [])
    return useDownload<CustomerUsageView120 & { selectDatas: { customerPartsId: number | undefined, usageVersion: string | undefined }[] }>(url, payloadProject, { fileTimestamp: true })
}

//MSOGF010 [1]
export const useDownloadOrderCalcGroupingByOrderGroupId = () => {
    const url = '/smt-api/ordermng/downloadOrderGroupListById'
    const payloadProject = useCallback((obj: { orderGroupIdList: number[] }) => obj.orderGroupIdList, [])
    return useDownload<{ orderGroupIdList: number[] }>(url, payloadProject, { fileName: 'Order Calculation Grouping.xlsx', fileTimestamp: true })
    // const payloadProject = useCallback(orderCalculationViewToJson, []);
    // return useDownload<OrderCalculationView>(url, payloadProject, { fileTimestamp: true });
}

//MSOGF010 [2]
export const useDownloadOrderCalcGroupingByFilter = () => {
    const url = '/smt-api/ordermng/downloadOrderGroupList'
    const payloadProject = useCallback(orderCalculationViewToJson, [])
    return useDownload<OrderCalculationView>(url, payloadProject, { fileName: 'Order Calculation Grouping.xlsx', fileTimestamp: true })
}

//MSOGF020 [1]
export const useDownloadOrderCalcGroupingByOrderGroupIdForEhm = () => {
    const url = '/smt-api/ordermng/downloadOrderGroupListByIdForEhm'
    const payloadProject = useCallback((obj: { orderGroupIdList: number[] }) => obj.orderGroupIdList, [])
    return useDownload<{ orderGroupIdList: number[] }>(url, payloadProject, { fileName: 'Order Calculation Grouping.xlsx', fileTimestamp: true })
    // const payloadProject = useCallback(orderCalculationViewToJson, []);
    // return useDownload<OrderCalculationView>(url, payloadProject, { fileTimestamp: true });
}

//MSOGF020 [2]
export const useDownloadOrderCalcGroupingByFilterForEhm = () => {
    const url = '/smt-api/ordermng/downloadOrderGroupListForEhm'
    const payloadProject = useCallback(orderCalculationViewToJson, [])
    return useDownload<OrderCalculationView>(url, payloadProject, { fileName: 'Order Calculation Grouping.xlsx', fileTimestamp: true })
}

//MSAUF010
export const useDownloadAlarmUsagePatternByFilter = () => {
    const url = '/smt-api/ordermng/downloadOrderCalculationSetting'
    const payloadProject = useCallback(alarmUsagePatternViewToJson, [])
    return useDownload<AlarmUsagePatternView>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadAlarmUsagePatternBySelection = () => {
    const url = '/smt-api/ordermng/downloadOrderCalculationSettingById'
    const payloadProject = useCallback((obj: { customerPartsIds: number[] }) => obj.customerPartsIds, [])
    return useDownload<{ customerPartsIds: number[] }>(url, payloadProject, { fileTimestamp: true })
}

// SMGTS210
export const useDownloadRundownInventory = () => {
    const url = '/smt-api/stockmng/downloadRundownInventory'
    const payloadProject = useCallback(inputDownloadDataRangeFactorToJson, [])
    return useDownload<InputDownloadDataRangeFactor>(url, payloadProject, { fileTimestamp: true })
}
// SMGTS210
export const useDownloadRundownContainer = () => {
    const url = '/smt-api/stockmng/downloadRundownContainer'
    const payloadProject = useCallback(inputDownloadDataRangeFactorToJson, [])
    return useDownload<InputDownloadDataRangeFactor>(url, payloadProject, { fileTimestamp: true })
}

// SMGTS310
export const useDownloadCustomerStock = () => {
    const url = '/smt-api/stockmng/downloadCustomerStock'
    const payloadProject = useCallback((obj: { customerPartIdList: number[] }) => obj.customerPartIdList, [])
    return useDownload<{ customerPartIdList: number[] }>(url, payloadProject, { fileName: 'Customer Inventory.xlsx', fileTimestamp: true })
}

// SMGTS410
export const useDownloadImpStockAdjustment = () => {
    const url = '/smt-api/stockmng/downloadStockAdjustmentList'
    const payloadProject = useCallback((obj: { partList: string[] }) => obj.partList, [])
    return useDownload<{ partList: string[] }>(url, payloadProject, { fileName: 'ImpStock Adjustment.xlsx', fileTimestamp: true })
}

// SMGTS610
export const useDownloadImpInv = () => {
    const url = '/smt-api/stockmng/downloadStockHistory'
    const payloadProject = useCallback(impInventoryListFactorToJson, [])
    return useDownload<ImpInventoryListFactor>(url, payloadProject, { fileName: 'ImpInventory History.xlsx', fileTimestamp: true })
}
// SMGTS610 - by selection
export const useDownloadImpInvBydailyStockId = () => {
    const url = '/smt-api/stockmng/downloadStockHistoryBydailyStockId'
    const payloadProject = useCallback((obj: { dailyStockIdList: number[] }) => obj.dailyStockIdList, [])
    return useDownload<{ dailyStockIdList: number[] }>(url, payloadProject, { fileTimestamp: true })
}

//SMGTS110
export const useDownloadStockStatusById = () => {
    const url = '/smt-api/stockmng/downloadWeeklyRundown'
    const payloadProject = useCallback(weeklyRundownDownloadFactorToJson, [])
    return useDownload<WeeklyRundownDownloadFactor>(url, payloadProject, { fileTimestamp: true })
}

//SMGTS110
export const useDownloadIncoming = () => {
    const url = '/smt-api/moving/downloadIncoming'
    const payloadProject = useCallback(incomingDownloadFactorToJson, [])
    return useDownload<IncomingDownloadFactor>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadIncomingMapa = () => {
    const url = '/smt-api/moving/downloadIncomingMapa'
    const payloadProject = useCallback(incomingDownloadFactorToJson, [])
    return useDownload<IncomingDownloadFactor>(url, payloadProject, { fileTimestamp: true })
}

//SMGTS110
export const useDownloadOutbound = () => {
    const url = '/smt-api/moving/downloadOutbound'
    const payloadProject = useCallback(outboundDownloadFactorToJson, [])
    return useDownload<OutboundDownloadFactor>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadOutboundMapa = () => {
    const url = '/smt-api/moving/downloadOutboundMapa'
    const payloadProject = useCallback(outboundDownloadFactorToJson, [])
    return useDownload<OutboundDownloadFactor>(url, payloadProject, { fileTimestamp: true })
}

//CSUGS131
export const useDownloadField = () => {
    const url = '/smt-api/project/downloadField'
    const payloadProject = useCallback(downloadFiledsFactorToJson, [])
    return useDownload<DownloadFiledsFactor>(url, payloadProject, { fileName: 'Project Fields.xlsx', fileTimestamp: true })
}

export const useDownloadMaterials = () => {
    const url = '/smt-api/project/downloadMaterials'
    const payloadProject = useCallback(downloadFiledsFactorToJson, [])
    return useDownload<DownloadFiledsFactor>(url, payloadProject, { fileName: 'Project Materials.xlsx', fileTimestamp: true })
}

export const useDownloadUsage = () => {
    const url = '/smt-api/project/downloadUsage'
    const payloadProject = useCallback(downloadFiledsFactorToJson, [])
    return useDownload<DownloadFiledsFactor>(url, payloadProject, { fileName: 'Project Usage.xlsx', fileTimestamp: true })
}

export const useDownloadPlan = () => {
    const url = '/smt-api/prodplan/downloadPlan'
    const payloadProject = useCallback(projectPlanFactorToJson, [])
    return useDownload<ProjectPlanFactor>(url, payloadProject, { fileName: 'Project Production Plan.xlsx', fileTimestamp: true })
}

export const useDownloadPokayokeResults = () => {
    const url = '/smt-api/vreport/downloadPokayokeResults'
    const payloadProject = useCallback(pokayokeDownloadFactorToJson, [])
    return useDownload<PokayokeDownloadFactor>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadActualUsage = () => {
    const url = '/smt-api/usage/downloadActualUsageByFilter'
    const payloadProject = useCallback(actualUsageFactorToJson, [])
    return useDownload<ActualUsageFactor>(url, payloadProject, { fileName: 'Actual Usage.xlsx', fileTimestamp: true })
}

export const useDownloadCustActualUsage = () => {
    const url = '/smt-api/usage/downloadCustActualUsageByFilter'
    const payloadProject = useCallback(actualUsageFactorToJson, [])
    return useDownload<ActualUsageFactor>(url, payloadProject, { fileName: 'Actual Usage.xlsx', fileTimestamp: true })
}

export const useDownloadActualUsageById = () => {
    const url = '/smt-api/usage/downloadActualUsageById'
    const payloadProject = useCallback((obj: { actualUsageIds: number[] }) => obj, [])
    return useDownload<{ actualUsageIds: number[] }>(url, payloadProject, { fileName: 'Actual Usage.xlsx', fileTimestamp: true })
}

export const useDownloadCustActualUsageById = () => {
    const url = '/smt-api/usage/downloadCustActualUsageById'
    const payloadProject = useCallback((obj: { actualUsageIds: number[] }) => obj, [])
    return useDownload<{ actualUsageIds: number[] }>(url, payloadProject, { fileName: 'Actual Usage.xlsx', fileTimestamp: true })
}

export const useDownloadWIPStockAdjustHistory = () => {
    const url = '/smt-api/usage/downloadWIPStockAdjustHistory'
    const payloadProject = useCallback(wipAdjustmentFactorToJson, [])
    return useDownload<WIPAdjustmentFactor>(url, payloadProject, { fileName: 'Wip Adjustment.xlsx', fileTimestamp: true })
}

//OCCLS010
export const useDownloadOrderCaculation = () => {
    const url = '/smt-api/ordermng/downloadOrderCalculation'
    const payloadProject = useCallback((obj: { orderCalcNos: string[] }) => obj.orderCalcNos, [])
    return useDownload<{ orderCalcNos: string[] }>(url, payloadProject, { fileTimestamp: false });
}

export const useDownloadOrderCaculationForPNA = () => {
    const url = '/smt-api/ordermng/downloadOrderCaculationForPNA'
    const payloadProject = useCallback((obj: { orderCalcNos: string[] }) => obj.orderCalcNos, [])
    return useDownload<{ orderCalcNos: string[] }>(url, payloadProject, { fileTimestamp: false });
}

export const useDownloadOrderCaculationForSMT = () => {
    const url = '/smt-api/ordermng/downloadOrderCaculationForSMT'
    const payloadProject = useCallback((obj: { orderCalcNos: string[] }) => obj.orderCalcNos, [])
    return useDownload<{ orderCalcNos: string[] }>(url, payloadProject, { fileTimestamp: false });
}

/** OCCLS015 */
export const useDownloadRegularOrderformListForCustomer = () => {
    const url = '/smt-api/ordermng/downloadPlaceOrderRegular'
    const payloadProject = useCallback((obj: OCPlaceRegularOrderResult[]) => obj.map(ocPlaceRegularOrderResultSerializer), []);
    return useDownload<OCPlaceRegularOrderResult[]>(url, payloadProject, { fileTimestamp: true, fileName: 'Customer Order Form.xlsx' });
}

/** OCCLS015 */
export const useDownloadRegularOrderformListForCustomerPNA = () => {
    const url = '/smt-api/ordermng/downloadPlaceOrderRegularForPNA'
    const payloadProject = useCallback((obj: OCPlaceRegularOrderResult[]) => obj.map(ocPlaceRegularOrderResultSerializer), []);
    return useDownload<OCPlaceRegularOrderResult[]>(url, payloadProject, { fileTimestamp: true, fileName: 'Customer Order Form.xlsx' });
}

/** OCCLS015 */
export const useDownloadRegularOrderformListForCustomerSMT = () => {
    const url = '/smt-api/ordermng/downloadPlaceOrderRegularForSMT'
    const payloadProject = useCallback((obj: OCPlaceRegularOrderResult[]) => obj.map(ocPlaceRegularOrderResultSerializer), []);
    return useDownload<OCPlaceRegularOrderResult[]>(url, payloadProject, { fileTimestamp: true, fileName: 'Customer Order Form.xlsx' });
}

/** OCCLS017 */
export const useDownloadSpotOrderformListForCustomer = () => {
    const url = '/smt-api/ordermng/downloadPlaceOrderSpot'
    const payloadProject = useCallback(Projects.array(ocPlaceRegularOrderResultSerializer), [])
    return useDownload<OCPlaceRegularOrderResult[]>(url, payloadProject, { fileTimestamp: true, fileName: 'Customer Order Form.xlsx' })
}

export const useDownloadSpotOrderformListForCustomerPNA = () => {
    const url = '/smt-api/ordermng/downloadPlaceOrderSpotForPna'
    const payloadProject = useCallback(Projects.array(ocPlaceRegularOrderResultSerializer), []);
    return useDownload<OCPlaceRegularOrderResult[]>(url, payloadProject, { fileTimestamp: true, fileName: 'Customer Order Form.xlsx' });
}

export const useDownloadSpotOrderformListForCustomerSMT = () => {
    const url = '/smt-api/ordermng/downloadPlaceOrderSpotForSmt'
    const payloadProject = useCallback(Projects.array(ocPlaceRegularOrderResultSerializer), []);
    return useDownload<OCPlaceRegularOrderResult[]>(url, payloadProject, { fileTimestamp: true, fileName: 'Customer Order Form.xlsx' });
}

/** order summary list */
export const useDownloadOrderSummaryReportForPNA = () => {
    const url = '/smt-api/ordermng/downloadOrderSummaryReportForPNA'
    const payloadProject = useCallback((obj: OCPlaceRegularOrderResult[]) => obj.map(ocPlaceRegularOrderResultSerializer), []);
    return useDownload<OCPlaceRegularOrderResult[]>(url, payloadProject, { fileTimestamp: true, fileName: 'Order Summary Report.xlsx' });
}

export const useDownloadOrderSummaryReportForSMT = () => {
    const url = '/smt-api/ordermng/downloadOrderSummaryReportForSMT'
    const payloadProject = useCallback((obj: OCPlaceRegularOrderResult[]) => obj.map(ocPlaceRegularOrderResultSerializer), []);
    return useDownload<OCPlaceRegularOrderResult[]>(url, payloadProject, { fileTimestamp: true, fileName: 'Order Summary Report.xlsx' });
}

/** MSAUF020 */
export const useDownloadStockManagementMasterByFilter = () => {
    const url = '/smt-api/stockManagementMaster/downloadStockManagementMaster'
    const payloadProject = useCallback(alarmUsagePatternViewToJson, [])
    return useDownload<StockManagementMasterView>(url, payloadProject, { fileTimestamp: true })
}

/** MSAUF020 */
export const useDownloadStockManagementMasterById = () => {
    const url = '/smt-api/stockManagementMaster/downloadStockManagementMasterById'
    const payloadProject = useCallback((obj: { customerPartIdList: number[] }) => obj.customerPartIdList, [])
    return useDownload<{ customerPartIdList: number[] }>(url, payloadProject, { fileTimestamp: true })
}

/** MSAUF020 */
export const useDownloadOrderCalculationMasterFilter = () => {
    const url = '/smt-api/stockManagementMaster/downloadOrderCalculationMaster'
    const payloadProject = useCallback(alarmUsagePatternViewToJson, [])
    return useDownload<StockManagementMasterView>(url, payloadProject, { fileTimestamp: true })
}

/** MSAUF020 */
export const useDownloadOrderCalculationMasterByIdById = () => {
    const url = '/smt-api/stockManagementMaster/downloadOrderCalculationMasterById'
    const payloadProject = useCallback((obj: { customerPartIdList: number[] }) => obj.customerPartIdList, [])
    return useDownload<{ customerPartIdList: number[] }>(url, payloadProject, { fileTimestamp: true })
}

/** MSAUF020 */
export const useDownloadStockManagementAndOrderCalculationMasterByFilter = () => {
    const url = '/smt-api/stockManagementMaster/downloadStockManagementAndOrderCalculationMaster'
    const payloadProject = useCallback(alarmUsagePatternViewToJson, [])
    return useDownload<StockManagementMasterView>(url, payloadProject, { fileTimestamp: true })
}

/** MSAUF020 */
export const useDownloadStockManagementAndOrderCalculationMasterById = () => {
    const url = '/smt-api/stockManagementMaster/downloadStockManagementAndOrderCalculationMasterById'
    const payloadProject = useCallback((obj: { customerPartIdList: number[] }) => obj.customerPartIdList, [])
    return useDownload<{ customerPartIdList: number[] }>(url, payloadProject, { fileTimestamp: true })
}