import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { OrderFrequency } from "../../order/enums/OrderFrequency"
import { dateFromJson } from "../../utils/deserializer"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"
import { TableEntity, tableEntityFromJson, TableEntityJson, tableEntityToJson } from "../../utils/TableEntity"
import { ActiveFlag } from "../enums/ActiveFlag"
import { AutoDiFlag } from "../enums/AutoDiFlag"
import { BusinessType } from "../enums/BusinessType"
import { ContractRouteStatus } from "../enums/ContractRouteStatus"
import { ContractType } from "../enums/ContractType"
import { CustomsFlag } from "../enums/CustomsFlag"
import { HSCodeOutputFlag } from "../enums/HSCodeOutPutFlag"
import { PriceBasis } from "../enums/PriceBasis"
import { WorkingStatus } from "../enums/WorkingStatus"
import { WorkingType } from "../enums/WorkingType"
import { PartsDetailResult, partsDetailResultFromJson, PartsDetailResultJson, partsDetailResultToJson } from "./PartsDetailResult"
import { RequestHeaderResult, requestHeaderResultFromJson, RequestHeaderResultJson, requestHeaderResultToJson } from "./RequestHeaderResult"
import { TnmPaymentTerms, tnmPaymentTermsFromJson, TnmPaymentTermsJson, tnmPaymentTermsToJson } from "./TnmPaymentTerms"
import { WorkingPartsList, workingPartsListFromJson, WorkingPartsListJson, workingPartsListToJson } from "./WorkingPartsList"


export type ContractRoute = ContractRouteRequired & Partial<ContractRouteOptional>
type ContractRouteJson = ContractRouteJsonRequired & Partial<ContractRouteJsonOptional>
const contractRouteFromJson: Project<ContractRouteJson, ContractRoute> = json => ({
    ...json,
})
export const contractRouteToJson: Project<ContractRoute, ContractRouteJson> = obj => ({
    ...obj,
})
interface ContractRouteRequired extends ContractRouteJsonRequired {
}

interface ContractRouteOptional extends ContractRouteJsonOptional {

}
interface ContractRouteJsonRequired {
    contrRouteId: number,
    contrRouteCode: string,
}
interface ContractRouteJsonOptional {
    receiveDcId: number,
    shipperDcId: number,
    autoDiFlag: number,
    srId: number,
    spotSrId: number,
    priceBasis: number,
}



export type WorkingResult = Required & Partial<Optional> & TableEntity
type WorkingResultJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const workingResultFromJson: Project<WorkingResultJson, WorkingResult> = json => ({
    ...tableEntityFromJson(json),
    confirmedDate: dateFromJson(json.confirmedDate),
    today: dateFromJson(json.today),
    confirmedDateStart: dateFromJson(json.confirmedDateStart),
    confirmedDateEnd: dateFromJson(json.today),
    partsList: json.partsList ? json.partsList.map((m) => workingPartsListFromJson(m)) : [],
    nextPartsList: json.nextPartsList ? json.nextPartsList.map((m) => workingPartsListFromJson(m)) : [],
    paymentTermList: json.paymentTermList ? json.paymentTermList.map(tnmPaymentTermsFromJson) : [],
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page),
    requestHeader: json.requestHeader ? requestHeaderResultFromJson(json.requestHeader) : undefined,
    requestPartsList: json.requestPartsList ? json.requestPartsList.map((m) => partsDetailResultFromJson(m)) : [],
    contractRouteList: json.contractRouteList ? json.contractRouteList.map((m) => contractRouteFromJson(m)) : [],
})

export const workingResultToJson: Project<WorkingResult, WorkingResultJson> = obj => ({
    ...tableEntityToJson(obj),
    confirmedDate: dateToJson(obj.confirmedDate),
    today: dateToJson(obj.today),
    confirmedDateStart: dateToJson(obj.confirmedDateStart),
    confirmedDateEnd: dateToJson(obj.today),
    partsList: obj.partsList ? obj.partsList.map((m) => workingPartsListToJson(m)) : [],
    nextPartsList: obj.nextPartsList ? obj.nextPartsList.map((m) => workingPartsListToJson(m)) : [],
    paymentTermList: obj.paymentTermList ? obj.paymentTermList.map(tnmPaymentTermsToJson) : [],
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
    requestHeader: obj.requestHeader ? requestHeaderResultToJson(obj.requestHeader) : undefined,
    requestPartsList: obj.requestPartsList ? obj.requestPartsList.map((m) => partsDetailResultToJson(m)) : [],
    contractRouteList: obj.contractRouteList ? obj.contractRouteList.map(contractRouteToJson) : [],
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'confirmedDate' | 'today' | 'confirmedDateStart' | 'confirmedDateEnd' | 'partsList' | 'nextPartsList' | 'page' | 'paymentTermList' | 'requestHeader' | 'requestPartsList'> {
    status: WorkingStatus,
    workingType: WorkingType,
    confirmedDate: Date,
    contractType: ContractType,
    businessType: BusinessType,
    printHSCodeFlag: HSCodeOutputFlag,
    orderFrequency: OrderFrequency,
    customsFlag: CustomsFlag,
    priceBasis: PriceBasis,
    autoDiFlag: AutoDiFlag,
    routeStatus: ContractRouteStatus,
    statusList: WorkingStatus[],
    workingTypeList: WorkingType[],
    partsList: WorkingPartsList[],
    nextPartsList: WorkingPartsList[],
    today: Date,
    activeFlag: ActiveFlag,
    confirmedDateStart: Date,
    confirmedDateEnd: Date,
    paymentTermList: TnmPaymentTerms[],
    page: PageViewEntity,
    requestHeader: RequestHeaderResult,
    requestPartsList: PartsDetailResult[],
    contractRouteList: ContractRoute[],
}

interface JsonRequired {
    workingId: number,
}

interface JsonOptional {
    // Working
    workingNo: string,
    description: string,
    status: number,
    workingType: number,
    workingUid: string,
    confirmedBy: string,
    confirmedDate: string,

    // Contract
    basicId: number,
    useOldContract: number,
    contractId: number,
    contractNo: string,
    contractShortCode: string,
    contractType: number,
    contractTypeName: string,
    basicDescription: string,
    sellerUid: string,
    sellerCode: string,
    buyerUid: string,
    buyerCode: string,
    consigneeUid: string,
    consigneeCode: string,
    accounteeUid: string,
    accounteeCode: string,
    deliveryToUid: string,
    deliveryToCode: string,
    businessType: number,
    printHSCodeFlag: number,
    incotermsId: number,
    incotermsCode: string,
    incotermsPlace: string,
    paymentTermsId: number,
    paymentTermsCode: string,
    paymentTermsName: string,
    currencyCode: string,
    orderFrequency: number,
    customsFlag: number,
    forecastNumber: number,
    expAgencyUid: string,
    expAgencyCode: string,
    impAgencyUid: string,
    impAgencyCode: string,

    targetLeadTime: number,
    confirmOrderLeadtime: number,
    leadtime: number,
    deliveryPlanStartDate: string,
    deliveryPlanEndDate: string,
    deliveryTypeFlag: number,

    // Contract Route 
    contractRouteId: number,
    orgRouteDetailId: number,
    receiverUid: string,
    receiverId: number,
    receiverCode: string,
    shipperUid: string,
    shipperId: number,
    shipperCode: string,
    srId: number,
    shippingRoutCode: string,
    spotSrId: number,
    spotShippingRouteCode: string,
    routeDescription: string,
    priceBasis: number,
    autoDiFlag: number,
    digits: number,
    routeStatus: number,
    partsList: WorkingPartsListJson[],
    nextPartsList: WorkingPartsListJson[],
    requestDetailIds: number[],
    workingIds: number[],
    statusList: number[],
    workingTypeList: number[],
    today: string,
    activeFlag: number,
    confirmedDateStart: string,
    confirmedDateEnd: string,
    isDeleteContract: number, /**1:Delete,2:NotDelete*****/
    paymentTermList: TnmPaymentTermsJson[],
    page: PageViewEntityJson,

    version: number,
    confirmedDateFormat: string,
    requestHeader: RequestHeaderResultJson,
    requestPartsList: PartsDetailResultJson[],
    requestId: number,
    contractRouteList: ContractRouteJson[],
    stepNo: number,
}


