import { useEffect, useMemo } from "react"
import { useDispatch } from "react-redux"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { View } from "../../../components/View/View"
import { orderCalculationListViewConditionFromJson } from "../../../services/smt/models/OrderCalculationListViewCondition"
import sessionKeys from "../../../utils/sessionKeys"
import { ListTableEhm } from "./ListTableEhm"
import { occls010Actions } from "./OCCLS010Slice"

export function OCCLS010Ehm() {

  const defaultFilters = useMemo(() => {
    const filters = sessionStorage.getItem(sessionKeys.Data_OCCLS010)
    return filters ? orderCalculationListViewConditionFromJson(JSON.parse(filters)) : { }
  }, [])

  const dispatch = useDispatch()
  useEffect(() => {
    // load data
    dispatch(occls010Actions.setType('Ehm'))
    dispatch(occls010Actions.setFilters(defaultFilters))
    dispatch(occls010Actions.getBuyerOptionsForEhm())
    dispatch(occls010Actions.listViewForEhm())
    // destroy state after unmount
    return () => {
      dispatch(occls010Actions.unmount())
    }
  }, [defaultFilters, dispatch])

  return <View flex>
    <SectionCard>
      <SectionCardContent>
        <ListTableEhm />
      </SectionCardContent>
    </SectionCard>
  </View>
}