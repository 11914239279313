import React, { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useComMasterGetAllCbds, useComMasterGetAllPort, useComMasterGetAllRegion, useComMasterGetStateByRegion } from "../../../services/common/apis/commonMasterApi"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { useGetShippingRouteDetail } from "../../../services/master/apis/shippingRouteApi"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { ShippingRouteDetailResult } from "../../../services/master/models/ShippingRouteDetailResult"
import { TnmPort } from "../../../services/master/models/TnmPort"
import { TnmRegion } from "../../../services/master/models/TnmRegion"
import { TnmShippingRouteDetail } from "../../../services/master/models/TnmShippingRouteDetail"
import { TnmState } from "../../../services/master/models/TnmState"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { useMatch } from "../../../utils/useMatch"
import { MLS121PcUi } from "./MLS121PcUi"

export const MLS121 = () => {

    const { srId } = useParams() as any
    const { path } = useMatch()
    let mode = ScreenMode.VIEW
    if (path === '/shippingroute/edit-:srId') {
        mode = ScreenMode.EDIT
    } else if (path === '/shippingroute/create') {
        mode = ScreenMode.CREATE
    } else if (path === '/receivedRequest/addNewPart-:requestNo/shippingrouteCreate') {
        mode = ScreenMode.CREATE
    }

    const [data, setData] = useState<ShippingRouteDetailResult>({} as any)
    const [detailData, setDetailData] = useState<TnmShippingRouteDetail[]>([])
    const [regions, setRegions] = useState<TnmRegion[]>([])
    const [states, setStates] = useState<TnmState[]>([])
    const [ports, setPorts] = useState<TnmPort[]>([])
    const [cbdsList, setCbdsList] = useState<TnvCbds[]>([])

    const getShippingRouteDetail = useGetShippingRouteDetail()
    const getRegionList = useComMasterGetAllRegion()
    const getStateList = useComMasterGetStateByRegion()
    const getPortList = useComMasterGetAllPort()
    const getCbdsList = useComMasterGetAllCbds()

    const search = useCallback(() => {
        getShippingRouteDetail({ srId: srId }, { silent: true, serialized: true }).then(result => {
            setData(result || {} as any)
            setDetailData(result?.detailList || [])
        })
    }, [getShippingRouteDetail, srId])

    useEffect(() => {
        if (mode !== ScreenMode.CREATE) {
            search()
        }
    }, [mode, search])

    useEffect(() => {
        getRegionList(undefined, { silent: true }).then(result => {
            setRegions(result || [])
        })
    }, [getRegionList])

    useEffect(() => {
        getStateList(undefined, { silent: true }).then(result => {
            setStates(result || [])
        })
    }, [getStateList])


    useEffect(() => {
        getPortList(undefined, { silent: true }).then(result => {
            setPorts(result || [])
        })
    }, [getPortList])


    useEffect(() => {
        getCbdsList({ types: [CbdsType.BU, CbdsType.CUST, CbdsType.DC, CbdsType.SUPP] }, { silent: true }).then(result => {
            setCbdsList(result || [])
        })
    }, [getCbdsList])

    return <MLS121PcUi
        data={data}
        setData={setData}
        search={search}
        detailData={detailData}
        setDetailData={setDetailData}
        mode={mode}
        regions={regions}
        states={states}
        ports={ports}
        cbdsList={cbdsList}
        path={path}
    />
}