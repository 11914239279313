import { Project } from "../../../utils/Project"
import { SettingDetail, settingDetailFromJson, SettingDetailJson, settingDetailToJson } from "./SettingDetail"
import { TnmBusinessUnitTemp, tnmBusinessUnitTempFromJson, TnmBusinessUnitTempJson, tnmBusinessUnitTempToJson } from "./TnmBusinessUnitTemp"

export interface BusinessUnitDetail {
    basicInfo: TnmBusinessUnitTemp,
    settings: SettingDetail[],
}
interface BusinessUnitDetailJson {
    basicInfo: TnmBusinessUnitTempJson,
    settings: SettingDetailJson[],
}

export const businessUnitDetailFromJson: Project<BusinessUnitDetailJson, BusinessUnitDetail> = json => ({
    basicInfo: tnmBusinessUnitTempFromJson(json.basicInfo),
    settings: json.settings.map(settingDetailFromJson),
})

export const businessUnitDetailToJson: Project<BusinessUnitDetail, BusinessUnitDetailJson> = obj => ({
    basicInfo: tnmBusinessUnitTempToJson(obj.basicInfo),
    settings: obj.settings.map(settingDetailToJson),
})