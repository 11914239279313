import { Project } from "../../../utils/Project"
import { ActiveFlag } from "../enums/ActiveFlag"

export type SaveCalendarWorkingDateFactor = Required & Partial<Optional>
type SaveCalendarWorkingDateFactorJson = JsonRequired & Partial<JsonOptional>
export const saveCalendarWorkingDateFactorToJson: Project<SaveCalendarWorkingDateFactor, SaveCalendarWorkingDateFactorJson> = obj => ({
    ...obj,
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
    activeFlag: ActiveFlag,
}

interface JsonRequired {
}

interface JsonOptional {
    calendarId: number,
    calendarCode: string,
    companyId: number,
    regionCode: string,
    calendarYear: string,
    description: string,
    restDateList: string[],
    activeFlag: number,
}