import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useGetActiveRequestToList, useGetAllFromRequestList } from "../../../services/master/apis/requestApi"
import { RequestFactor, requestFactorFromJson, requestFactorToJson } from "../../../services/master/models/RequestFactor"
import { RequestResult } from "../../../services/master/models/RequestResult"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import sessionKeys from "../../../utils/sessionKeys"
import { useMatch } from "../../../utils/useMatch"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { MOS020PcUi } from "./MOS020PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }
const searchCachekey = sessionKeys.Filters_MOS020

export const MOS020 = () => {
    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? requestFactorFromJson(JSON.parse(filters)) : null
    }, [])
    const { path } = useMatch()
    const [filters, setFilters] = useState<RequestFactor>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<RequestResult[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [requestToList, setRequestToList] = useState<TnvCbds[]>([])

    const getFromRequestList = useGetAllFromRequestList()
    const search = useCallback((filters: RequestFactor) => {
        getFromRequestList(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(requestFactorToJson(filters)))
        })
    }, [getFromRequestList, setData])

    useSearchOnLoad(search, filters)

    const getRequestToList = useGetActiveRequestToList()

    useEffect(() => {
        getRequestToList(undefined, { silent: true }).then(result => {
            setRequestToList(result || [])
        })
    }, [getRequestToList, setRequestToList])

    return <MOS020PcUi
        path={path}
        filters={filters}
        search={search}
        data={data}
        totalCount={totalCount}
        requestToList={requestToList}
        setFilters={setFilters}
    />
}