import { Objects } from "@rithe/utils"
import React, { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useComMasterGetActiveCbds, useComMasterGetAllUom } from "../../../services/common/apis/commonMasterApi"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { TnmUom } from "../../../services/master/models/TnmUom"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { useGetPlaceOrderForCustomerDetail } from "../../../services/order/apis/ChangeRequestApi"
import { PartsDetail, PlaceOrderForCustomerDetailResult } from "../../../services/order/models/PlaceOrderForCustomerDetailResult"
import { addNumber, mulNumber } from "../../../utils/NumberUtil"
import { useMatch } from "../../../utils/useMatch"
import { OCS029PcUi } from "./OCS029PcUi"


export interface PlaceOrderForCustomerDetailResultBasic extends PlaceOrderForCustomerDetailResult {
    totalQty: number,
    totalAmount: number,
    totalNumberOfParts: number,
}

export const OCS029 = () => {

    const { path } = useMatch()
    const { roSpotId } = useParams() as any

    const mode = path === '/rc/edit-:roSpotId' ? ScreenMode.EDIT : ScreenMode.VIEW

    const [basic, setBasic] = useState<PlaceOrderForCustomerDetailResultBasic>({} as PlaceOrderForCustomerDetailResultBasic)
    const [inboundPlans, setInboundPlans] = useState<PartsDetail[]>([] as PartsDetail[])
    const [uomList, setUomList] = useState<TnmUom[]>([])
    const [receiveDcList, setReceiveDcList] = useState<TnvCbds[]>([])

    const splitDatas = useSplitDatas()
    const getData = useGetPlaceOrderForCustomerDetail()
    const getUomList = useComMasterGetAllUom()
    const getCbds = useComMasterGetActiveCbds()

    const search = useCallback((roSpotId: number | null | undefined) => {
        if (roSpotId) {
            getData({ roSpotId }, { silent: true }).then(result => {
                if (result) {
                    const { orderbasic, orderInboundPlans } = splitDatas(result)
                    setBasic(orderbasic)
                    setInboundPlans(orderInboundPlans)

                }
            })
        }
    }, [getData, splitDatas])

    useEffect(() => {
        getUomList(undefined, { silent: true }).then(result => {
            setUomList(result || [])
        })
        getCbds({ types: [] }, { silent: true }).then(result => {
            setReceiveDcList(result || [])
        })
        search(roSpotId)
    }, [getUomList, search, roSpotId, getCbds])

    return <OCS029PcUi
        search={search}
        basic={basic}
        setBasic={setBasic}
        inboundPlans={inboundPlans}
        setInboundPlans={setInboundPlans}
        mode={mode}
        uomList={uomList}
        receiveDcList={receiveDcList}
    />
}

export const useSplitDatas = () => {
    return useCallback((basic: PlaceOrderForCustomerDetailResult) => {
        const { totPoRoSpotDetailList } = basic
        const totalQty = totPoRoSpotDetailList.reduce((v1, v2) => addNumber(v1, v2.orderQtyInRo ? v2.orderQtyInRo : 0), 0)
        const totalAmount = totPoRoSpotDetailList.reduce((v1, v2) => addNumber(v1, (v2.orderQtyInRo && v2.buyingPrice ? mulNumber(v2.orderQtyInRo, v2.buyingPrice) : 0)), 0)
        const totalNumberOfParts = totPoRoSpotDetailList.length
        const orderbasic: PlaceOrderForCustomerDetailResultBasic = { ...basic, totalQty, totalNumberOfParts, totalAmount }
        const orderInboundPlans: PartsDetail[] = totPoRoSpotDetailList.map(parts => ({ ...parts }))
        return { orderbasic: orderbasic, orderInboundPlans: orderInboundPlans }
    }, [])
}

export const useMergeDatas = () => {
    return useCallback((orderbasic: PlaceOrderForCustomerDetailResultBasic, orderInboundPlans: PartsDetail[]) => {
        const basic = Objects.delete(orderbasic, 'totalQty', 'totalNumberOfParts', 'totalAmount') as PlaceOrderForCustomerDetailResult
        return { ...basic, totPoRoSpotDetailList: orderInboundPlans }
    }, [])
}