import { useCallback } from "react"
import { Projects } from "../../../utils/Projects"
import { usePost } from "../../../utils/fetch/useFetch"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { BChangeRequestListResult, bChangeRequestListResultFromJson } from "../models/BChangeRequestListResult"
import { BUChangeRequestListResult, buChangeRequestListResultFromJson } from "../models/BUChangeRequestListResult"
import { ChangeOrderDetailResult, changeOrderDetailResultFromJson, changeOrderDetailResultToJson } from "../models/ChangeOrderDetailResult"
import { ChangeRequestListResult, changeRequestListResultFromJson } from "../models/ChangeRequestListResult"
import { ChangeRequestWithoutContractListResult, ParamRequest, ParamRequestFromJson, ParamRequestToJson, changeRequestWithoutContractListResultFromJson, changeRequestWithoutContractListResultToJson } from "../models/ChangeRequestWithoutContractListResult"
import { PartsDetail, PlaceChangeWCDetailResult, partsDetailDeserializer, partsDetailSerializer, placeChangeWCDetailResultDeserializer, placeChangeWCDetailResultSerializer } from "../models/PlaceChangeWCDetailResult"
import { PlaceOrderForCustomerDetailResult, PlaceOrderForCustomerDetailResultDeserializer, PlaceOrderForCustomerDetailResultSerializer } from "../models/PlaceOrderForCustomerDetailResult"
import { ReceivedPCDetailResult, ReceivedPCPartsDetail, receivedPCDetailResultDeserializer, receivedPCDetailResultSerializer, receivedPCPartsDetailDeserializer, receivedPCPartsDetailSerializer } from "../models/ReceivedPCDetailResult"
import { ReceivedPlaceChangView, receivedPlaceChangOrderFromJson, receivedPlaceChangOrderToJson } from "../models/ReceivedPlaceChangView"
import { SChangeOrderDetailResult, sChangeOrderDetailResultFromJson, sChangeOrderDetailResultToJson } from "../models/SChangeOrderDetailResult"
import { SChangeRequestListResult, sChangeRequestListResultFromJson } from "../models/SChangeRequestListResult"
//ONS030 Customer
export const useGetOrderChangedableCustomerOrderList = () => {
    const url = '/lcbm-order-api/order/cro/api/getOrderChangedableCustomerOrderList'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(changeRequestListResultFromJson), [])
    return usePost<undefined, ChangeRequestListResult[]>(url, payloadProject, resultProject)
}

export const useGetFCChangedableCustomerOrderList = () => {
    const url = '/lcbm-order-api/order/cro/api/getFCChangedableCustomerOrderList'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(changeRequestListResultFromJson), [])
    return usePost<undefined, ChangeRequestListResult[]>(url, payloadProject, resultProject)
}

export const useGetOCWCChangedableCustomerOrderList = () => {
    const url = '/lcbm-spot-order-api/order/cro/api/listPlaceSpotChangeCancel'
    const payloadProject = useCallback(changeRequestWithoutContractListResultToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(changeRequestWithoutContractListResultFromJson), [])
    return usePost<ChangeRequestWithoutContractListResult, PageResultEntity<ChangeRequestWithoutContractListResult>>(url, payloadProject, resultProject)
}

export const useGetOrderReviseDetailByCustomer = () => {
    const url = '/lcbm-order-api/order/cro/api/getOrderReviseDetailByCustomer'
    const payloadProject = Projects.KEEP
    const resultProject = useCallback(changeOrderDetailResultFromJson, [])
    return usePost<{ coId: number, roId: number }, ChangeOrderDetailResult>(url, payloadProject, resultProject)
}

export const useGetFcReviseDetailByCustomer = () => {
    const url = '/lcbm-order-api/order/cro/api/getFcReviseDetailByCustomer'
    const payloadProject = Projects.KEEP
    const resultProject = useCallback(changeOrderDetailResultFromJson, [])
    return usePost<{ coId: number, roId: number }, ChangeOrderDetailResult>(url, payloadProject, resultProject)
}

export const useSaveRevisedCustomerOrder = () => {
    const url = '/lcbm-order-api/order/cro/api/saveRevisedCustomerOrder'
    const payloadProject = useCallback(changeOrderDetailResultToJson, [])
    const resultProject = Projects.IGNORE
    return usePost<ChangeOrderDetailResult, undefined>(url, payloadProject, resultProject)
}

export const useSaveAndIssueRevisedCustomerOrder = () => {
    const url = '/lcbm-order-api/order/cro/api/saveAndIssueRevisedCustomerOrder'
    const payloadProject = useCallback(changeOrderDetailResultToJson, [])
    const resultProject = Projects.IGNORE
    return usePost<ChangeOrderDetailResult, undefined>(url, payloadProject, resultProject)
}

export const useDeleteRevisedCustomerOrder = () => {
    const url = '/lcbm-order-api/order/cro/api/deleteRevisedCustomerOrder'
    const payloadProject = useCallback((obj: { roId: number }) => obj.roId, [])
    const resultProject = Projects.IGNORE
    return usePost<{ roId: number }, undefined>(url, payloadProject, resultProject)
}

export const useIssueRevisedCustomerOrder = () => {
    const url = '/lcbm-order-api/order/cro/api/issueRevisedCustomerOrder'
    const payloadProject = useCallback((obj: { roId: number }) => obj.roId, [])
    const resultProject = Projects.IGNORE
    return usePost<{ roId: number }, undefined>(url, payloadProject, resultProject)
}

//ONS040 BU
export const useGetOrderChangedableOrderList = () => {
    const url = '/lcbm-order-api/order/bro/api/getOrderChangedableOrderList'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(buChangeRequestListResultFromJson), [])
    return usePost<undefined, BUChangeRequestListResult[]>(url, payloadProject, resultProject)
}
export const useGetFCChangedableOrderList = () => {
    const url = '/lcbm-order-api/order/bro/api/getFCChangedableOrderList'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(buChangeRequestListResultFromJson), [])
    return usePost<undefined, BUChangeRequestListResult[]>(url, payloadProject, resultProject)
}

export const useGetOrderReviseDetailByBU = () => {
    const url = '/lcbm-order-api/order/bro/api/getOrderReviseDetailByBU'
    const payloadProject = Projects.KEEP
    const resultProject = useCallback(changeOrderDetailResultFromJson, [])
    return usePost<{ poId: number, roId: number }, ChangeOrderDetailResult>(url, payloadProject, resultProject)
}

// ors020
export const useFindChangedableOrderList = () => {
    const url = '/lcbm-order-api/order/scro/api/findChangedableOrderList'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(sChangeRequestListResultFromJson), [])
    return usePost<undefined, SChangeRequestListResult[]>(url, payloadProject, resultProject)
}

export const useIssueChangeSuppOrder = () => {
    const url = '/lcbm-order-api/order/scro/api/issueChangeSuppOrder'
    const payloadProject = useCallback((obj: { suppRoId: number }) => obj.suppRoId, [])
    const resultProject = Projects.IGNORE
    return usePost<{ suppRoId: number }, undefined>(url, payloadProject, resultProject)
}

export const useDeleteChangeSuppOrder = () => {
    const url = '/lcbm-order-api/order/scro/api/deleteChangeSuppOrder'
    const payloadProject = useCallback((obj: { suppRoId: number }) => obj.suppRoId, [])
    const resultProject = Projects.IGNORE
    return usePost<{ suppRoId: number }, undefined>(url, payloadProject, resultProject)
}

export const useCancelSuppOrder = () => {
    const url = '/lcbm-order-api/order/scro/api/cancelSuppOrder'
    const payloadProject = useCallback((obj: { soId: number }) => obj.soId, [])
    return usePost<{ soId: number }, undefined>(url, payloadProject, Projects.IGNORE)
}

// ocs026
export const useGetPCWCDetail = () => {
    const url = '/lcbm-order-api/order/cro/api/getTotCoRoAllInfo'
    const payloadProject = useCallback(ParamRequestToJson, [])
    const resultProject = useCallback(placeChangeWCDetailResultDeserializer, [])
    return usePost<ParamRequest, PlaceChangeWCDetailResult>(url, payloadProject, resultProject)
}

export const useConfirmPCWCDetail = () => {
    const url = '/lcbm-order-api/order/cro/api/doConfirm'
    const payloadProject = useCallback(placeChangeWCDetailResultSerializer, [])
    const resultProject = useCallback(placeChangeWCDetailResultDeserializer, [])
    return usePost<PlaceChangeWCDetailResult, PlaceChangeWCDetailResult>(url, payloadProject, resultProject)
}

export const useTempSavePCWCDetail = () => {
    const url = '/lcbm-order-api/order/cro/api/saveTemp'
    const payloadProject = useCallback(placeChangeWCDetailResultSerializer, [])
    const resultProject = useCallback(placeChangeWCDetailResultDeserializer, [])
    return usePost<PlaceChangeWCDetailResult, PlaceChangeWCDetailResult>(url, payloadProject, resultProject)
}

export const useCancelPCWCDetail = () => {
    const url = '/lcbm-order-api/order/cro/api/doCancel'
    const payloadProject = useCallback(partsDetailSerializer, [])
    const resultProject = useCallback(partsDetailDeserializer, [])
    return usePost<PartsDetail, PartsDetail>(url, payloadProject, resultProject)
}

// ocs025
export const useChangePCWCById = () => {
    const url = '/lcbm-spot-order-api/order/cro/api/changeOrder'
    const payloadProject = useCallback((obj: { coSpotId: number }) => obj, [])
    const resultProject = useCallback(ParamRequestFromJson, [])
    return usePost<{ coSpotId: number }, ParamRequest>(url, payloadProject, resultProject)
}

export const useCancelPCWCById = () => {
    const url = '/lcbm-spot-order-api/order/cro/api/cancelOrder'
    const payloadProject = useCallback((obj: { coSpotId: number }) => obj, [])
    const resultProject = Projects.IGNORE
    return usePost<{ coSpotId: number }, undefined>(url, payloadProject, resultProject)
}

// ocs027
export const useGetRePCWCDataByPage = () => {
    const url = '/lcbm-spot-order-api/order/cro/api/listReceivedPlace'
    const payloadProject = useCallback(receivedPlaceChangOrderToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(receivedPlaceChangOrderFromJson), [])
    return usePost<ReceivedPlaceChangView, PageResultEntity<ReceivedPlaceChangView>>(url, payloadProject, resultProject)
}

// ocs028
export const useGetReceivedPCWCDetail = () => {
    const url = '/lcbm-spot-order-api/order/cro/api/getTotSoRoAllInfo'
    const payloadProject = useCallback((obj: { roSpotId: number | null | undefined }) => obj, [])
    const resultProject = useCallback(receivedPCDetailResultDeserializer, [])
    return usePost<{ roSpotId: number | null | undefined }, ReceivedPCDetailResult>(url, payloadProject, resultProject)
}

export const useConfirmReceivedPCWCDetail = () => {
    const url = '/lcbm-spot-order-api/order/cro/api/doConfirmSoRo'
    const payloadProject = useCallback(receivedPCDetailResultSerializer, [])
    const resultProject = useCallback(receivedPCDetailResultDeserializer, [])
    return usePost<ReceivedPCDetailResult, ReceivedPCDetailResult>(url, payloadProject, resultProject)
}

export const useRejectReceivedPCWCDetail = () => {
    const url = '/lcbm-spot-order-api/order/cro/api/doRejectSoRo'
    const payloadProject = useCallback(receivedPCDetailResultSerializer, [])
    const resultProject = useCallback(receivedPCDetailResultDeserializer, [])
    return usePost<ReceivedPCDetailResult, ReceivedPCDetailResult>(url, payloadProject, resultProject)
}

export const useRejectOneSoRoDetail = () => {
    const url = '/lcbm-spot-order-api/order/cro/api/doRejectOneSoRoDetail'
    const payloadProject = useCallback(receivedPCPartsDetailSerializer, [])
    const resultProject = useCallback(receivedPCPartsDetailDeserializer, [])
    return usePost<ReceivedPCPartsDetail, ReceivedPCPartsDetail>(url, payloadProject, resultProject)
}


// ocs029
export const useGetPlaceOrderForCustomerDetail = () => {
    const url = '/lcbm-spot-order-api/order/cro/api/getTotPoRoAllInfo'
    const payloadProject = useCallback((obj: { roSpotId: number | null | undefined }) => obj, [])
    const resultProject = useCallback(PlaceOrderForCustomerDetailResultDeserializer, [])
    return usePost<{ roSpotId: number | null | undefined }, PlaceOrderForCustomerDetailResult>(url, payloadProject, resultProject)
}

export const useTempSavePlaceOrderForCustomerDetail = () => {
    const url = '/lcbm-spot-order-api/order/cro/api/saveTemp029'
    const payloadProject = useCallback(PlaceOrderForCustomerDetailResultSerializer, [])
    const resultProject = useCallback(PlaceOrderForCustomerDetailResultDeserializer, [])
    return usePost<PlaceOrderForCustomerDetailResult, PlaceOrderForCustomerDetailResult>(url, payloadProject, resultProject)
}

export const useConfirmPlaceOrderForCustomerDetail = () => {
    const url = '/lcbm-spot-order-api/order/cro/api/doConfirm029'
    const payloadProject = useCallback(PlaceOrderForCustomerDetailResultSerializer, [])
    const resultProject = useCallback(PlaceOrderForCustomerDetailResultDeserializer, [])
    return usePost<PlaceOrderForCustomerDetailResult, PlaceOrderForCustomerDetailResult>(url, payloadProject, resultProject)
}

// ORS021
export const useGetSuppOrderChangeDetail = () => {
    const url = '/lcbm-order-api/order/scro/api/getSuppOrderChangeDetail'
    const payloadProject = Projects.KEEP
    const resultProject = useCallback(sChangeOrderDetailResultFromJson, [])
    return usePost<{ soId: number, suppRoId: number }, SChangeOrderDetailResult>(url, payloadProject, resultProject)
}

export const useSaveChangeSuppOrder = () => {
    const url = '/lcbm-order-api/order/scro/api/saveChangeSuppOrder'
    const payloadProject = useCallback(sChangeOrderDetailResultToJson, [])
    const resultProject = Projects.IGNORE
    return usePost<SChangeOrderDetailResult, undefined>(url, payloadProject, resultProject)
}

export const useSaveAndIssueChangeSuppOrder = () => {
    const url = '/lcbm-order-api/order/scro/api/saveAndIssueChangeSuppOrder'
    const payloadProject = useCallback(sChangeOrderDetailResultToJson, [])
    const resultProject = Projects.IGNORE
    return usePost<SChangeOrderDetailResult, undefined>(url, payloadProject, resultProject)
}

export const useFindBUChangedableOrderList = () => {
    const url = '/lcbm-order-api/order/bocc/api/findBUChangeableOrderList'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(bChangeRequestListResultFromJson), [])
    return usePost<undefined, BChangeRequestListResult[]>(url, payloadProject, resultProject)
}