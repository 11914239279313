import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { OrderFrequency } from "../enums/OrderFrequency"
import { OrderType } from "../enums/OrderType"

export interface OrderForecastDetailResult {
    headerInfo: OrderForecastHead,
    partsDetailList: OrderPartsDetailGrid[]
}

interface OrderForecastDetailResultJson {
    headerInfo: OrderForecastHeadJson,
    partsDetailList: OrderPartsDetailGridJson[]
}

export const OrderForecastDetailResultDeserializer: Project<OrderForecastDetailResultJson, OrderForecastDetailResult> = json => ({
    headerInfo: OrderForecastHeadDeserializer(json.headerInfo),
    partsDetailList: json.partsDetailList.map(m => OrderPartsDetailGridDeserializer(m))
})

interface OrderForecastHead {
    orderId: number,
    customerId: number,
    orderNo: string,
    orderType: OrderType,
    orderFrequency: OrderFrequency,
    contractNo: string,
    contractId: number,
    contractRouteId: number,
    forecastNum: number,
    buyer: string,
    receiver: string,
    orderFirstDate: Date,
    orderLastDate: Date,
    targetLastDate: Date,
    targetFirstDate: Date,
    groupId: number,
    groupNo: string,
}

interface OrderForecastHeadJson {
    orderId: number,
    customerId: number,
    orderNo: string,
    orderType: number,
    orderFrequency: number,
    contractNo: string,
    contractId: number,
    contractRouteId: number,
    forecastNum: number,
    buyer: string,
    receiver: string,
    orderFirstDate: string,
    orderLastDate: string,
    targetLastDate: string,
    targetFirstDate: string,
    groupId: number,
    groupNo: string,
}

const OrderForecastHeadDeserializer: Project<OrderForecastHeadJson, OrderForecastHead> = json => ({
    ...json,
    orderFirstDate: dateFromJson(json.orderFirstDate),
    orderLastDate: dateFromJson(json.orderLastDate),
    targetLastDate: dateFromJson(json.targetLastDate),
    targetFirstDate: dateFromJson(json.targetFirstDate)
})

export interface OrderPartsDetailGrid {
    orderDetailId: number,
    coDetailOrigin: string | null,
    soDetailOrigin: string | null,
    partsNo: string,
    unitPartsNo: string,
    backNo: string | null,
    unitPartsId: number,
    supplierId: number,
    supplierCode: string,
    customerCode: string,
    salesOrderNo: string,
    purchaseOrderNo: string,
    spq: number,
    orderLot: number,
    uomCode: string,
    fluctuationRatio: number | null,
    forecastList: OrderForecastGrid[]
}

interface OrderPartsDetailGridJson {
    orderDetailId: number,
    coDetailOrigin: string | null,
    soDetailOrigin: string | null,
    partsNo: string,
    unitPartsNo: string,
    backNo: string | null,
    unitPartsId: number,
    supplierId: number,
    supplierCode: string,
    customerCode: string,
    salesOrderNo: string,
    purchaseOrderNo: string,
    spq: number,
    orderLot: number,
    uomCode: string,
    fluctuationRatio: number | null,
    forecastList: OrderForecastGridJson[]
}

const OrderPartsDetailGridDeserializer: Project<OrderPartsDetailGridJson, OrderPartsDetailGrid> = json => ({
    ...json,
    forecastList: json.forecastList.map(m => OrderForecastGridDeserializer(m))
})

interface OrderForecastGrid {
    coDetailOrigin: string | null,
    soDetailOrigin: string | null,
    fcFirstDate: Date,
    fcLastDate: Date,
    targetFirstDate: Date,
    targetLastDate: Date,
    forecastQty: number | null,
    lastForecastQty: number | null
}

interface OrderForecastGridJson {
    coDetailOrigin: string | null,
    soDetailOrigin: string | null,
    fcFirstDate: string,
    fcLastDate: string,
    targetFirstDate: string,
    targetLastDate: string,
    forecastQty: number | null,
    lastForecastQty: number | null
}

const OrderForecastGridDeserializer: Project<OrderForecastGridJson, OrderForecastGrid> = json => ({
    ...json,
    fcFirstDate: dateFromJson(json.fcFirstDate),
    fcLastDate: dateFromJson(json.fcLastDate),
    targetFirstDate: dateFromJson(json.targetFirstDate),
    targetLastDate: dateFromJson(json.targetLastDate),
})