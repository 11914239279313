import { Project } from "../../../utils/Project"
import { CustomsFlag } from "../../master/enums/CustomsFlag"
import { dateFromJson } from "../../utils/deserializer"
import { dateToJson } from "../../utils/serializer"
import { OrderType } from "../enums/OrderType"


export type ReSpotOrderResult = ResultRequired & Partial<ResultOptional>
export type ReSpotOrderResultson = ResultJsonRequired & Partial<ResultJsonOptional>

export const reSpotOrderResultDeserializer: Project<ReSpotOrderResultson, ReSpotOrderResult> = json => ({
    ...json,
    // createdDate: dateFromJson(json.createdDate),
    // updatedDate: dateFromJson(json.updatedDate),
    ocs023ParamDetailBo: json.ocs023ParamDetailBo.map(partsDetailDeserializer),



})

export const reSpotOrderResultSerializer: Project<ReSpotOrderResult, ReSpotOrderResultson> = data => ({
    ...data,
    // createdDate: dateToJson(data.createdDate),
    // updatedDate: dateToJson(data.updatedDate),
    ocs023ParamDetailBo: data.ocs023ParamDetailBo.map(partsDetailSerializer),

})



export interface NextPartsDetail extends Omit<NextPartsDetailJson, 'estimatedCrdDate'> {
    estimatedCrdDate: Date

}

interface ResultRequired extends Omit<ResultJsonRequired, 'ocs023ParamDetailBo'> {
    // createdDate: Date,
    // updatedDate: Date,
    ocs023ParamDetailBo: PartsDetail[],

}

interface ResultOptional extends Omit<ResultJsonOptional, 'orderType' | 'status' | 'customsFlag'> {
    orderType: OrderType,
    status: number,
    customsFlag: CustomsFlag,
}

interface ResultJsonRequired {
    ocs023ParamDetailBo: PartsDetailJson[],
    status: number,

}
// /customsFlag: CustomsFlag,
interface ResultJsonOptional {
    soSpotId: number,
    spotId: number,
    inboundPlan: string,
    buyerCode: string,
    orderType: number,
    soNo: string,
    orderDate: string,
    preCoSpotId: number,
    prePoSpotId: number,
    preSpotId: number,
    preSoId: number,
    soType: number,
    buId: number,
    supplierId: number,
    customerId: number,
    buyerBuId: number,
    soDate: string,
    inboundDcId: number,
    shipperDcId: number,
    shippingMode: number,
    remark: string,
    paymentTermsId: number,
    currency: string,
    submittedBy: string,
    submittedDate: string,
    completedDate: string,
    customsFlag: number,
}


export interface PartsDetail extends Omit<PartsDetailJson, 'estimatedInboundPlanList' | 'soSpotDrBoList' | 'nextSellerSoSpotDrSimuBoList'> {
    // forecastList: ForecastDetail[],
    estimatedInboundPlanList: InboundPalnDetail[],
    soSpotDrBoList: BuyerInboundPalnDetail[],
    nextSellerSoSpotDrSimuBoList: NextPartsDetail[],
}

interface PartsDetailJson {
    soSpotDetailId: number,
    soSpotId: number,
    poNo: string
    customerId: number,
    finalSellerUid: string,
    coDetailOrigin: string,
    soDetailOrigin: string,
    globalPartsId: number,
    colorCode: string,
    soPartsId: number,
    soPartsNo: string,
    buyerPartsId: number,
    buyerPartsNo: string,
    sellingPrice: number,
    sellingCurrency: string,
    sSpq: number,
    bSpq: number,
    orderLot: number,
    orderQty: number,
    forceCompletedQty: number,
    inventoryCtrlQty: number,
    customerStockQty: number,
    orderQtyOrigin: number,
    totalDeliveredQty: number,
    finalFlag: number
    status: number,
    createdBy: string,
    //  createdDate:string,
    updatedBy: string,
    //  updatedDate:string,
    version: number,
    partsNo: string,
    partsDescription: string,
    estimatedInboundPlanList: InboundPalnDetailJson[] | null,
    soSpotDrBoList: BuyerInboundPalnDetailJson[] | null,
    nextSellerSoSpotDrSimuBoList: NextPartsDetailJson[] | null,
    nextSellerStatus: number,
}

export const partsDetailDeserializer: Project<PartsDetailJson, PartsDetail> = json => ({
    ...json,
    // forecastList: json.forecastList ? json.forecastList.map(forecastDetailDeserializer) : [],
    estimatedInboundPlanList: json.estimatedInboundPlanList ? json.estimatedInboundPlanList.map(inboundPalnDetailDeserializer) : [],
    soSpotDrBoList: json.soSpotDrBoList ? json.soSpotDrBoList.map(buyerInboundPalnDetailDeserializer) : [],
    nextSellerSoSpotDrSimuBoList: json.nextSellerSoSpotDrSimuBoList ? json.nextSellerSoSpotDrSimuBoList.map(nextPartsDetailDeserializer) : []
})

export const partsDetailSerializer: Project<PartsDetail, PartsDetailJson> = data => ({
    ...data,
    // forecastList: data.forecastList ? data.forecastList.map(forecastDetailSerializer) : [],
    estimatedInboundPlanList: data.estimatedInboundPlanList ? data.estimatedInboundPlanList.map(inboundPalnDetailSerializer) : [],
    soSpotDrBoList: data.soSpotDrBoList ? data.soSpotDrBoList.map(buyerInboundPalnDetailSerializer) : [],
    nextSellerSoSpotDrSimuBoList: data.nextSellerSoSpotDrSimuBoList ? data.nextSellerSoSpotDrSimuBoList.map(nextPartsDetailSerializer) : []
})

interface NextPartsDetailJson {
    soSpotDetailId: number,
    spotDrId: number,
    estimatedQty: number,
    estimatedCrdDate: string,
}

const nextPartsDetailDeserializer: Project<NextPartsDetailJson, NextPartsDetail> = json => ({
    ...json,
    estimatedCrdDate: dateFromJson(json.estimatedCrdDate)
})

const nextPartsDetailSerializer: Project<NextPartsDetail, NextPartsDetailJson> = data => ({
    ...data,
    estimatedCrdDate: dateToJson(data.estimatedCrdDate)
})




//forelist

export interface ForecastDetail extends Omit<ForecastDetailJson, 'fcFirstDate' | 'fcLastDate' | 'targetFirstDate' | 'targetLastDate'> {
    fcFirstDate: Date,
    fcLastDate: Date,
    targetFirstDate: Date,
    targetLastDate: Date,
}

interface ForecastDetailJson {
    coDetailOrigin: string | null,
    soDetailOrigin: string | null,
    fcFirstDate: string,
    fcLastDate: string,
    targetFirstDate: string,
    targetLastDate: string,
    forecastQty: number,
    lastForecastQty: number,
    fluctuationReason: string
}

//inboundlist
export interface InboundPalnDetail extends Omit<InboundPalnDetailJson, 'planDate'> {
    planDate: Date,
}

interface InboundPalnDetailJson {
    estimatedInPlanId?: number,
    soDetailId: number,
    planDate: string,
    planQty: number | null,
    leftQty?: number,
    usedFlag?: string,
    totalUsedQty?: number,

}

const inboundPalnDetailDeserializer: Project<InboundPalnDetailJson, InboundPalnDetail> = json => ({
    ...json,
    planDate: dateFromJson(json.planDate)
})
const inboundPalnDetailSerializer: Project<InboundPalnDetail, InboundPalnDetailJson> = data => ({
    ...data,
    planDate: dateToJson(data.planDate)
})

//soSpotDrBoListList
export interface BuyerInboundPalnDetail extends Omit<BuyerInboundPalnDetailJson, 'crdDate'> {
    crdDate: Date,
}

interface BuyerInboundPalnDetailJson {
    spotDrId?: number,
    ParentDrId?: number,
    soSpotDetailId?: number,
    crdQty?: number | null,
    drNo?: string,
    crdDate: string,
    totalDeliveredQty?: number,
    //  ocs023SoSpotDrSimuBoList

}

const buyerInboundPalnDetailDeserializer: Project<BuyerInboundPalnDetailJson, BuyerInboundPalnDetail> = json => ({
    ...json,
    crdDate: dateFromJson(json.crdDate)
})
const buyerInboundPalnDetailSerializer: Project<BuyerInboundPalnDetail, BuyerInboundPalnDetailJson> = data => ({
    ...data,
    crdDate: dateToJson(data.crdDate)
})