import { useCallback, useEffect, useMemo, useState } from "react"
import { useComMasterGetAllCompany, useGetZoneList } from "../../../services/common/apis/commonMasterApi"
import { useGetDCList, useGetPartnerDCList } from "../../../services/master/apis/dcApi"
import { DCFactor, dcFactorFromJson, dcFactorToJson } from "../../../services/master/models/DCFactor"
import { DCResult } from "../../../services/master/models/DCResult"
import { TnmCompany } from "../../../services/master/models/TnmCompany"
import sessionKeys from "../../../utils/sessionKeys"
import { useMatch } from "../../../utils/useMatch"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { MLS050PcUi } from "./MLS050PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }
const searchCachekey = sessionKeys.Filters_MLS050

export const MLS050 = () => {

    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? dcFactorFromJson(JSON.parse(filters)) : null
    }, [])

    const { path } = useMatch()
    const [filters, setFilters] = useState<DCFactor>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<DCResult[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [companyList, setCompany] = useState<TnmCompany[]>([])
    const [timeZoneMap, setTimeZoneMap] = useState<Record<string, string>>({})

    const getDcList = useGetDCList()
    const getPartnerDcList = useGetPartnerDCList()
    const queryPartnerDcList = path === "/masterOverView/dc" ? getPartnerDcList : getDcList
    const search = useCallback((filters: DCFactor) => {
        queryPartnerDcList(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(dcFactorToJson(filters)))
        })
    }, [queryPartnerDcList])

    useSearchOnLoad(search, filters)

    // const getCompanyList = useComMasterGetAllCompanyByActive()
    const getCompanyList = useComMasterGetAllCompany()
    useEffect(() => {
        getCompanyList(undefined, { silent: true }).then(result => {
            setCompany(result || [])
        })
    }, [getCompanyList])

    const getZoneList = useGetZoneList()
    useEffect(() => {
        getZoneList(undefined, { silent: true }).then(result => {
            const map: Record<string, string> = {}
            result.forEach(({ first, second }) => map[first] = second)
            setTimeZoneMap(map)
        })
    }, [getZoneList])

    return <MLS050PcUi
        path={path}
        filters={filters}
        search={search}
        data={data}
        totalCount={totalCount}
        companyList={companyList}
        setFilters={setFilters}
        timeZoneMap={timeZoneMap}
    />
}