import { Project } from "../../../utils/Project"
import { ContractType } from "../../master/enums/ContractType"
import { dateFromJson } from "../../utils/deserializer"


export interface ContractResult {
    sellerId: number,
    sellerUid: string,
    sellerCode: string,
    buyerId: number,
    buyerUid: string,
    buyerCode: string,
    contractType: ContractType
}

interface ContractResultJson {
    sellerId: number,
    sellerUid: string,
    sellerCode: string,
    buyerId: number,
    buyerUid: string,
    buyerCode: string,
    contractType: number
}

export const contractResultFromJson: Project<ContractResultJson, ContractResult> = json => ({
    ...json,
})




export interface InvoiceResult {
    invId: number,
    invoiceNo: string,
    invoiceDate: Date,
    paymentDueDate: Date,
    invoiceAmount: number,
    paymentedAmount: number | null,
    invoiceCurrency: string,
    buyerCode: string,
    sellerCode: string,
    digits: number,
}
interface InvoiceResultJson {
    invId: number,
    invoiceNo: string,
    invoiceDate: string,
    paymentDueDate: string,
    invoiceAmount: number,
    paymentedAmount: number | null,
    invoiceCurrency: string,
    buyerCode: string,
    sellerCode: string,
    digits: number,
}
export const invoiceResultFromJson: Project<InvoiceResultJson, InvoiceResult> = json => ({
    ...json,
    paymentDueDate: dateFromJson(json.paymentDueDate),
    invoiceDate: dateFromJson(json.invoiceDate),
})





export interface CreditLimitResult {
    creditLimitId: number,
    customerId: number,
    buId: number,
    baseCurrency: string,
    totalCreditLimit: number,
    orderCreditLimit: number,
    arCreditLimit: number,
    customerCode: string,
}

interface CreditLimitResultJson {
    creditLimitId: number,
    customerId: number,
    buId: number,
    baseCurrency: string,
    totalCreditLimit: number,
    orderCreditLimit: number,
    arCreditLimit: number,
    customerCode: string,
}

export const creditLimitResultFromJson: Project<CreditLimitResultJson, CreditLimitResult> = json => ({
    ...json
})