import {Project} from "../../../utils/Project"

export type GroupingDataList = Required & Partial<Optional>
export type GroupingDataListJson = JsonRequired & Partial<JsonOptional>
export const groupingDataListToJson: Project<GroupingDataList, GroupingDataListJson> = obj => ({
    ...obj,
})
export const groupingDataListFromJson: Project<GroupingDataListJson, GroupingDataList> = json => ({
    ...json,
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
}

interface JsonRequired {
}

interface JsonOptional {
    actionType: number,
    groupId: number,
    grouping: string,
    orderType: number,
    businessType: number,
    orderFrequency: number,
    description: string,
    remark: string,
    createdBy: string,
    createdDate: number,
    updatedBy: string,
    updatedDate: number,
    version: number,
    targetLeadTime: number,
    forecastNum: number
    status: number
}