import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { DateRangeItem, StringItem } from "@rithe/form"
import { Records } from "@rithe/utils"
import { default as React, memo, SetStateAction, useCallback, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DetailRedirectRowAction } from "../../../components/DataGrid/rowActions/DetailRedirectRowAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { SoListResult } from "../../../services/order/models/SoListResult"
import { SoMonitoringListFactor } from "../../../services/order/models/SoMonitoringListFactor"

interface OSS020PcUiProPs {
    filters: SoMonitoringListFactor,
    setFilters: React.Dispatch<SetStateAction<SoMonitoringListFactor>>,
    soList: SoListResult[],
    search: (filters: SoMonitoringListFactor) => void,
    totalCount: number
}

export const OSS020PcUi = (props: OSS020PcUiProPs) => {

    return <View flex >
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>

    </View>
}

const DataTable = ({ filters, setFilters, soList, search }: OSS020PcUiProPs) => {

    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])

    const columns = useMemo(() => [
        { field: 'soNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'orderNo' }), width: 300 },
        { field: 'orderType', dataTypeName: CodeCategory.OrderType, title: intl.formatMessage({ id: 'field.orderType' }), width: 180 },
        { field: 'soDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'placeDate' }), width: 150 },
        { field: 'status', dataTypeName: CodeCategory.SoSpotStatus, title: intl.formatMessage({ id: 'field.status' }), width: 240 },
    ], [intl])

    const getRowId = useCallback((row: any) => row.soSpotId, [])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search }), [filters, setFilters, search])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OrderType} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.SoSpotStatus} />
        <Data rows={soList} columns={columns} getRowId={getRowId} />
        <RowActionProvider name="detail" Action={DetailRowAction} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['']}
            columnSettings={{
                orderNo: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={124} />
    </DataGrid>

}

const FilterItem = memo((props: {
    filters: SoMonitoringListFactor,
    setFilters: React.Dispatch<SetStateAction<SoMonitoringListFactor>>,
    search: (filters: SoMonitoringListFactor) => void
}) => {
    const { filters, setFilters, search } = props

    const soDateGetValue = useCallback((filters: SoMonitoringListFactor) => {
        return [filters.soDateStart ?? null, filters.soDateEnd ?? null]
    }, [])

    const soDateMapValue = useCallback((filters: SoMonitoringListFactor, value: any) => {
        return { ...filters ?? {}, soDateStart: value[0], soDateEnd: value[1] }
    }, [])

    const clear = useCallback((filters: SoMonitoringListFactor) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: SoMonitoringListFactor) => {
        return [
            filters.soNo,
            filters.orderTypeList,
            filters.statusList,
            filters.soDateStart || filters.soDateEnd,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()


    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="soNo" label={intl.formatMessage({ id: 'orderNo' })} />
        <CodesItem field="orderTypeList" label={intl.formatMessage({ id: 'field.orderType' })} code={CodeCategory.OrderType} />
        <CodesItem field="statusList" label={intl.formatMessage({ id: 'field.status' })} code={CodeCategory.SoStatus} />
        <DateRangeItem field="soDate" label={intl.formatMessage({ id: 'placeDate' })} getValue={soDateGetValue} mapValue={soDateMapValue} />
    </FilterToolbarItem>

})

const DetailRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/soMonitoring/detail-${tableRow.rowId}`, [])
    return <DetailRedirectRowAction tableRow={tableRow} access="ORDER.OSS021.VIEW" path={path} />
}
