import { FormattedMessage } from 'react-intl'
import { CallbackViewAction } from './CallbackViewAction'

interface SwitchCallbackViewActionProps {
    access?: string,
    disabled?: boolean,
    outlined?: boolean,
    callback: () => void,
}

export const SwitchCallbackViewAction = (props: SwitchCallbackViewActionProps) => {
    return <CallbackViewAction {...props} title={<FormattedMessage id="switch" />} />
}