import { Breadcrumbs, Typography, makeStyles } from "@material-ui/core"
import { NavigateNext } from "@material-ui/icons"
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import { useCallback } from "react"
import { FormattedMessage } from "react-intl"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { applicationActions, useApplicationSelector } from "../../layouts/Application/applicationSlice"
import { pageInfoList } from "../../layouts/Home/PageInfoList"
import { useAddFavouritePage, useRemoveFavouritePage } from "../../services/system/apis/FavouritePageApi"
import { useMatch } from "../../utils/useMatch"

export function ViewBreadcrumbs() {
    const { path, url } = useMatch()
    const pathnames = path.split('/').filter(Boolean)
    const urls = url.split('/').filter(Boolean)
    const nowPage = pageInfoList.filter(page => page.url === path).length > 0 ? pageInfoList.filter(page => page.url === path)[0] : null
    const resources = useApplicationSelector(state => state.auth.accessResources)
    const favouritePages = useApplicationSelector(state => state.auth.favouritePages)
    const favourited = nowPage && favouritePages?.map(item => item.pageCode).includes(nowPage.pageCode)
    const unFavourited = nowPage && !favouritePages?.map(item => item.pageCode).includes(nowPage.pageCode) && (nowPage.access === '' || resources?.includes(nowPage.access))
    const add = useAddFavouritePage()
    const remove = useRemoveFavouritePage()
    const dispatch = useDispatch()
    const onClick = useCallback(() => {
        if (favourited) {
            //remove
            remove({ pageCode: nowPage.pageCode, resourceCode: nowPage.access }).then(() => {
                dispatch(applicationActions.loadFavouritePages())
            })
        } else if (unFavourited) {
            //create
            add({ pageCode: nowPage.pageCode, resourceCode: nowPage.access }).then(() => {
                dispatch(applicationActions.loadFavouritePages())
            })
        }
    }, [add, dispatch, favourited, nowPage?.access, nowPage?.pageCode, remove, unFavourited])

    const styles = useStyles()

    return <div>
        <div style={{ display: 'flex' }}>
            <Typography variant="h3" color="primary">
                <FormattedMessage id={`/${pathnames.slice(0, pathnames.length).join('/')}`} />
            </Typography>
            {nowPage &&
                <Typography variant="h3" color="primary" style={{ paddingLeft: 10, cursor: 'pointer' }} onClick={onClick}>
                    {favourited && <StarIcon className={styles.icon}></StarIcon>}
                    {unFavourited && <StarBorderIcon className={styles.icon}></StarBorderIcon>}
                </Typography>}
        </div>
        <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNext style={{ width: 18, height: 18 }} />} maxItems={4}>
            <Breadcrumb to="/home" labelId="/home" />
            {urls.map((_, index) => {
                const last = index === urls.length - 1
                const to = `/${urls.slice(0, index + 1).join('/')}`
                const labelId = `/${pathnames.slice(0, index + 1).join('/')}`

                return <Breadcrumb key={index} to={to} bold={last} labelId={labelId} />
            })}
        </Breadcrumbs>
    </div>
}

function Breadcrumb({ to, bold, labelId }: { to: string, bold?: boolean, labelId: string }) {
    return <Link to={to} style={{ textDecoration: 'none' }}>
        <Typography variant="body2" color="textPrimary" style={{ fontWeight: bold ? 'bold' : undefined }}>
            <FormattedMessage id={labelId} />
        </Typography>
    </Link>
}

const useStyles = makeStyles(theme => ({
    icon: {
        color: 'orange',
        height: '100%',
        width: 'auto'
    },
}))
