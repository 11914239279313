import { Project } from "../../../utils/Project"

export type FileDownloadFactor = Partial<Optional>
type FileDownloadFactorJson = Partial<JsonOptional>

export const fileDownloadFactorToJson: Project<FileDownloadFactor, FileDownloadFactorJson> = obj => ({
    ...obj,
})


interface Optional extends JsonOptional {

}

interface JsonOptional {
    fileTypeList: string[],
    cbdsCode: string,
    cbdsName: string,
    cbdsShortCode: string,
    timeZone: string,
    regionCode: string,
    printHSCodeFlag: number,
    incotermsId: number,
    incotermsPlace: string,
    orderFrequency: number,
    customsFlag: number,
    forecastNumber: number,
    expAgencyUid: string,
    impAgencyUid: string,
    targetLeadTime: number,
    contractNo: string,
    contractRouteNo: string,
    description: string,
}