import React, { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { useComMasterGetAllCurrency } from "../../../services/common/apis/commonMasterApi"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { useGetCBDSListByContrRouteId, useGetContrRouteList, useGetFOBSettingDetailById } from "../../../services/master/apis/fobSettingApi"
import { FOBSettingDetail, FOBSettingInfo } from "../../../services/master/models/FOBSettingDetailResult"
import { TnmContractRoute } from "../../../services/master/models/TnmContrRoute"
import { TnmCurrency } from "../../../services/master/models/TnmCurrency"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { applicationActions } from "../../Application/applicationSlice"
import { MLS171PcUi } from "./MLS171PcUi"

export const MLS171 = () => {
    const { fobSettingId } = useParams() as any
    let mode = ScreenMode.VIEW

    const [fOBSettingInfo, setFOBSettingInfo] = useState<FOBSettingInfo>({} as FOBSettingInfo)
    const [fOBSettingDetailList, setFOBSettingDetailList] = useState<FOBSettingDetail[]>([])
    const [currencyList, setCurrencyList] = useState<TnmCurrency[]>([])
    const [contractRouteList, setContractRouteList] = useState<TnmContractRoute[]>([])

    const [consigneeList, setConsigneeList] = useState<TnvCbds[]>([])
    const [ftaInvoiceList, setFtaInvoiceList] = useState<TnvCbds[]>([])
    const [customClearanceInvoiceList, setCustomClearanceInvoiceList] = useState<TnvCbds[]>([])

    const getCurrency = useComMasterGetAllCurrency()
    const getContractRouteList = useGetContrRouteList()
    const getCBDSListByContrRouteId = useGetCBDSListByContrRouteId()

    const findCBDSList = useCallback((contrRouteId: number | null) => {
        if (contrRouteId != null) {
            getCBDSListByContrRouteId({ contrRouteId: contrRouteId }, { silent: true, serialized: true }).then(result => {
                if (result) {
                    setConsigneeList(result?.consigneeList || [])
                    setFtaInvoiceList(result?.ftaInvoiceList || [])
                    setCustomClearanceInvoiceList(result?.customClearanceInvoiceList || [])
                }
            })
        } else {
            setConsigneeList([])
            setFtaInvoiceList([])
            setCustomClearanceInvoiceList([])
        }
    }, [getCBDSListByContrRouteId])

    const getData = useGetFOBSettingDetailById()
    const search = useCallback((fobSettingId: number | null) => {
        // if (mode !== ScreenMode.CREATE) {
        if (fobSettingId !== null) {
            getData({ fobSettingId: fobSettingId }, { silent: true, serialized: true }).then(result => {
                if (result) {
                    setFOBSettingInfo(result.fobSettingInfo ?? {})
                    setFOBSettingDetailList(result?.details ?? [])
                    if (result.fobSettingInfo) {
                        findCBDSList(result.fobSettingInfo.contrRouteId ?? null)
                    }
                }
            })
        }
        // }
    }, [findCBDSList, getData])

    const dispatch = useDispatch()
    const init = useCallback(() => {
        getCurrency(undefined, { silent: true }).then(result => {
            setCurrencyList(result || [])
        })

        getContractRouteList({ fobSettingId: fobSettingId } as FOBSettingInfo, { silent: true }).then(result => {
            setContractRouteList(result || [])
            if (result?.length === 0) {
                dispatch(applicationActions.pushError({ title: { code: 'field.contrRouteCode' }, messages: { code: 'w0380' } }))
            }
        })


    }, [getCurrency, getContractRouteList, fobSettingId, dispatch])

    useEffect(() => {
        init()
        search(fobSettingId)
    }, [fobSettingId, init, search])

    return <MLS171PcUi
        fobSettingId={fobSettingId}
        mode={mode}
        search={search}
        fOBSettingInfo={fOBSettingInfo}
        setFOBSettingInfo={setFOBSettingInfo}
        fOBSettingDetailList={fOBSettingDetailList}
        setFOBSettingDetailList={setFOBSettingDetailList}
        currencyList={currencyList}
        contractRouteList={contractRouteList}
        consigneeList={consigneeList}
        ftaInvoiceList={ftaInvoiceList}
        customClearanceInvoiceList={customClearanceInvoiceList}
        findCBDSList={findCBDSList}
    />
}