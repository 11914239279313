import { Project } from "@rithe/utils";



export type ProjectFieldsResult = Required & Partial<Optional>;

type ProjectFieldsResultJson = JsonRequired & Partial<JsonOptional>;

export const projectFieldsResultFromJson: Project<ProjectFieldsResultJson, ProjectFieldsResult> = json => ({
    ...json,
})
interface Required extends JsonRequired {

}

interface Optional extends JsonOptional {
}

interface JsonRequired {
    projectId: number,

    projectName: string,
}

interface JsonOptional {
    fieldId: number,
    remarks: string,
    mainFlag: number,
    fieldName: string,
    orderNo: number,
    levelType: number,
    projectId: number,
}
