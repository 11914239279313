import {
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    Fade,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Menu,
    MenuItem,
    Tooltip,
    useTheme
} from "@material-ui/core"
import { FileCopyOutlined, MonetizationOnOutlined, Print } from "@material-ui/icons"
import {
    Action,
    ColumnFreeze,
    ColumnOrdering,
    ColumnResizing,
    ColumnVisibility,
    Data,
    DataGrid,
    DataTypePreset,
    Filtering,
    PaginationLayout,
    Paging,
    RowActionProvider,
    Searching,
    Selection,
    Sorting,
    TableBodyLayout,
    TableHeaderLayout,
    TableLayout,
    TableRow,
    ToolbarActionProvider,
    ToolbarItemProvider,
    ToolbarLayout
} from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { DateItem, DateRangeItem, EntriesItem, Form, NumberItem, StringItem } from "@rithe/form"
import { Records } from "@rithe/utils"
import React, { SetStateAction, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { NeumorphismButton } from "../../../components/Button/NeumorphismButton"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CallbackRowAction } from "../../../components/DataGrid/rowActions/CallbackRowAction"
import { ViewRedirectRowAction } from "../../../components/DataGrid/rowActions/ViewRedirectRowAction"
import { CallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/CallbackToolbarAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { DarkDialog } from "../../../components/Dialog/DarkDialog"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { useFunctionStore } from "../../../Root"
import {
    useFullPaymentReceived,
    useUpdatePaymentAmount
} from "../../../services/accounting/apis/accountingBuyerApi"
import { ARTransfer, PaidTransfer } from "../../../services/accounting/models/ARTransfer"
import { ARView } from "../../../services/accounting/models/ArView"
import { useDownloadArPDF } from "../../../services/delivery/apis/deliveryOutboundApi"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { TnmCurrency } from "../../../services/master/models/TnmCurrency"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { ArStatus } from "../../../services/privilege/enums/ArStatus"
import { applicationActions } from "../../Application/applicationSlice"

interface AIS060PcUiProps {
    filters: ARView,
    setFilters: React.Dispatch<SetStateAction<ARView>>,
    search: (filter: ARView) => void,
    data: ARTransfer[],
    totalCount: number,
    currencys: TnmCurrency[],
    buyers: TnvCbds[],
}

export const AIS060PcUi = (props: AIS060PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({
    filters,
    setFilters,
    search,
    data,
    currencys,
    buyers,
}: AIS060PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<string[]>([])

    const columns = useMemo(() => [
        { field: 'invoiceNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.invoiceNo' }), width: 260 },
        { field: 'outboundNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.outboundNo' }), width: 260 },
        { field: 'contractNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contractNo' }), width: 250 },
        { field: 'buyerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'invoiceTo' }), width: 160 },
        { field: 'currency', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.currency' }), width: 160 },
        { field: 'amount', dataTypeName: 'number', title: intl.formatMessage({ id: 'amount' }), width: 180 },
        { field: 'amountPaid', dataTypeName: 'number', title: intl.formatMessage({ id: 'amountPaid' }), width: 150 },
        { field: 'invoiceDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'issuedDate' }), width: 180 },
        { field: 'paymentDueDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.paymentDueDate' }), width: 180 },
        { field: 'status', dataTypeName: CodeCategory.ArStatus, title: intl.formatMessage({ id: 'field.status' }), width: 150 },
    ], [intl])

    const getRowId = useCallback((row: any) => row.arId, [])

    const itemProps1 = useMemo(() => ({ filters, setFilters, search, currencys, buyers }), [filters, setFilters, search, currencys, buyers])
    const actionProps1 = useMemo(() => ({ selections, data }), [selections, data])
    const actionProps2 = useMemo(() => ({ selections, filters, search }), [selections, filters, search])
    const actionProps3 = useMemo(() => ({ filters, search }), [filters, search])
    const display3 = useCallback((tableRow: TableRow) => tableRow?.row?.status !== ArStatus.PAID, [])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.ArStatus} />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={PrintAction} actionProps={actionProps1} />
        <ToolbarActionProvider Action={FullPaymentReceivedAction} actionProps={actionProps2} />
        <RowActionProvider name="view" Action={ViewRowAction} />
        <RowActionProvider name="payment" Action={PaymentRowAction} display={display3} actionProps={actionProps3} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={[]}
            columnSettings={{
                invoiceNo: { disableUserControl: true }
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemProps1} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={124} />
    </DataGrid>
}

const FilterItem = (props: {
    filters: ARView,
    search: (filter: ARView) => void,
    currencys: TnmCurrency[],
    buyers: TnvCbds[],
    setFilters: React.Dispatch<SetStateAction<ARView>>,
}) => {
    const { filters, setFilters, currencys, buyers, search } = props
    const currencyEntries: [string, string][] = useMemo(() => currencys.map(m => [m.currencyCode, m.currencyCode]), [currencys])
    const buyerEntries: [string, string][] = useMemo(() => buyers.map(m => [m.cbdsUid, m.cbdsCode]), [buyers])

    const issueDateGetValue = useCallback((filters: ARView) => {
        return [filters.issuedDateStart ?? null, filters.issuedDateEnd ?? null]
    }, [])

    const issueDateMapValue = useCallback((filters: ARView, value: any) => {
        return { ...filters ?? {}, issuedDateStart: value[0], issuedDateEnd: value[1] }
    }, [])

    const dueDateGetValue = useCallback((filters: ARView) => {
        return [filters.paymentDueDateStart ?? null, filters.paymentDueDateEnd ?? null]
    }, [])

    const dueDateMapValue = useCallback((filters: ARView, value: any) => {
        return { ...filters ?? {}, paymentDueDateStart: value[0], paymentDueDateEnd: value[1] }
    }, [])

    const clear = useCallback((filters: ARView) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: ARView) => {
        return [
            filters.invoiceNo,
            filters.contractNo,
            filters.outboundNo,
            filters.buyers,
            filters.currencys,
            filters.statusList,
            filters.issuedDateStart || filters.issuedDateEnd,
            filters.paymentDueDateStart || filters.paymentDueDateEnd,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="invoiceNo" label={intl.formatMessage({ id: 'field.invoiceNo' })} />
        <StringItem field="outboundNo" label={intl.formatMessage({ id: 'field.outboundNo' })} />
        <StringItem field="contractNo" label={intl.formatMessage({ id: 'field.contractNo' })} />

        <EntriesItem field="buyers" label={intl.formatMessage({ id: 'invoiceTo' })} entries={buyerEntries} />
        <EntriesItem field="currencys" label={intl.formatMessage({ id: 'currency' })} entries={currencyEntries} />
        <CodesItem field="statusList" label={intl.formatMessage({ id: 'field.status' })} code={CodeCategory.ArStatus} />

        <DateRangeItem field="issuedDate" label={intl.formatMessage({ id: 'issuedDate' })} getValue={issueDateGetValue} mapValue={issueDateMapValue} />
        <DateRangeItem field="paymentDueDate" label={intl.formatMessage({ id: 'field.paymentDueDate' })} getValue={dueDateGetValue} mapValue={dueDateMapValue} />
    </FilterToolbarItem>
}

const PrintAction = ({ selections, data }: { selections: any[], data: ARTransfer[] }) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const theme = useTheme()
    const [open, setOpen] = useState<boolean>(false)
    const [checked, setChecked] = useState<number[]>([])
    const handleClose = () => { setOpen(false) }
    const handleCheck = (value: number) => () => {
        const currentIndex = checked.indexOf(value)
        const newChecked = [...checked]
        if (currentIndex === -1) {
            newChecked.push(value)
        } else {
            newChecked.splice(currentIndex, 1)
        }

        setChecked(newChecked)
    }

    const typeList = useMemo(() => [
        { typeName: intl.formatMessage({ id: 'printAccountsPayableReport' }), typeCode: 3 },
    ], [intl])

    const print = useDownloadArPDF()
    const printChangeFrom = useCallback(() => {
        if (checked.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'print' }, messages: { code: 'w0036' } }))
            return
        }
        if (selections.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'print' }, messages: { code: 'w0002' } }))
            return
        }
        print({ arIds: selections })
    }, [checked.length, dispatch, print, selections])

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
        setOpen(true)
    }

    return <>
        {/* <Access access=" ACCT.AIS010.PRINT"> */}
        <Tooltip title={intl.formatMessage({ id: "print" })}>
            <NeumorphismButton onClick={handleClickListItem} style={{ width: 36, marginLeft: theme.spacing(1) }}>{<Print></Print>}</NeumorphismButton>
        </Tooltip>
        <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <List>
                {typeList.map((value, index) => {
                    const labelId = `checkbox-list-label-${index}`
                    return (
                        <>
                            <ListItem key={index} dense button onClick={handleCheck(value.typeCode)}>
                                <ListItemAvatar>
                                    <FileCopyOutlined></FileCopyOutlined>
                                </ListItemAvatar>
                                <ListItemText id={labelId} primary={value.typeName} />
                                <ListItemSecondaryAction>
                                    <Checkbox
                                        edge="end"
                                        onChange={handleCheck(value.typeCode)}
                                        checked={checked.indexOf(value.typeCode) !== -1}
                                        inputProps={{ 'aria-labelledby': labelId }} />
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider component="li" />
                        </>
                    )
                })}
            </List>
            <MenuItem>
                <Button onClick={printChangeFrom} variant="contained" color="primary"><FormattedMessage id="print" /></Button>
            </MenuItem>
        </Menu>
        {/* </Access> */}
    </>
}

const FullPaymentReceivedAction = ({ selections, filters, search }: { selections: any[], filters: ARView, search: (filter: ARView) => void }) => {
    const dispatch = useDispatch()
    const fullPaymentReceived = useFullPaymentReceived()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'fullPaymentReceived' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const callback = useCallback(() => {
        if (selections.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'fullPaymentReceived' }, messages: { code: 'w0002' } }))
            return
        }
        const functionId = functionStore.register(() => {
            setDisabled(true)
            fullPaymentReceived({ arIds: selections }).then(() => search(filters)).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [dispatch, filters, fullPaymentReceived, functionStore, search, selections, title])

    return <CallbackToolbarAction title="Full payment received" callback={callback} icon={<MonetizationOnOutlined />} disabled={disabled} />
}

const ViewRowAction = (props: DataGridRowActionProps) => {
    const { tableRow } = props
    const path = useMemo(() => `/ar/view-${tableRow?.row?.invoiceId}`, [tableRow])
    return <ViewRedirectRowAction tableRow={tableRow} path={path} />
}

const PaymentRowAction = (props: DataGridRowActionProps & { filters: ARView, search: (filter: ARView) => void, }) => {
    const { tableRow, filters, search } = props

    const intl = useIntl()
    const functionStore = useFunctionStore()
    const dispatch = useDispatch()
    const [open, setOpen] = useState<boolean>(false)
    const [disabled, setDisabled] = useState<boolean>(false)
    const [payment, setPayment] = useState<PaidTransfer>({ arId: tableRow?.row?.arId })
    const title = useMemo(() => intl.formatMessage({ id: 'confirm' }), [intl])

    const updatePayment = useUpdatePaymentAmount()
    const doUpdatePayment = useCallback(() => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            updatePayment(payment, { serialized: true }).then(() => {
                search(filters)
                setOpen(false)
                setPayment(pay => ({ arId: pay.arId }))
            }).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [dispatch, filters, functionStore, payment, search, title, updatePayment])

    return <>
        <CallbackRowAction tableRow={tableRow} title="Input Received Payment" callback={() => setOpen(true)} icon={<MonetizationOnOutlined />} />
        <DarkDialog onClose={() => { setOpen(false) }} maxWidth="md" fullWidth aria-labelledby="simple-dialog-title" open={open}>
            <DialogHeader onClose={() => setOpen(false)}><FormattedMessage id="inputPaymentInfo" /></DialogHeader>
            <DialogContent>
                <DialogContentText>
                    <Form data={payment} setData={setPayment} labelDisplay="block" helperDisplay="tooltip" columnCount={3} >
                        <StringItem readonly field="invoiceNo" labelWidth={120} label={intl.formatMessage({ id: 'invoiceNo' })} getValue={() => tableRow?.row?.invoiceNo} />
                        <StringItem readonly field="amount" labelWidth={120} label={intl.formatMessage({ id: 'amount' })} getValue={() => tableRow?.row?.amount} />
                        <StringItem readonly field="amountPaid" labelWidth={120} label={intl.formatMessage({ id: 'amountPaid' })} getValue={() => tableRow?.row?.amountPaid} />

                        <DateItem field="paidDate" required label={intl.formatMessage({ id: 'field.paidDate' })} colSpan={3} />
                        <NumberItem field="paidAmount" required label={intl.formatMessage({ id: 'field.paidAmount' })} colSpan={3} />
                        <StringItem field="paidRefNo" label={intl.formatMessage({ id: 'field.paidRefNo' })} colSpan={3} />
                    </Form>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={() => setOpen(false)} />
                <DialogAction title={<FormattedMessage id="confirm" />} callback={() => doUpdatePayment()} disabled={disabled} />
            </DialogActions>
        </DarkDialog>
    </>
}