import { Project } from "../../../utils/Project"

export type DownloadDiByPartForBuView = Required & Partial<Optional>

type DownloadDiByPartForBuViewJson = JsonRequired & Partial<JsonOptional>

export const DownloadDiByPartForBuViewToJson: Project<DownloadDiByPartForBuView, DownloadDiByPartForBuViewJson> = obj => ({
    ...obj,
})

interface Required extends JsonRequired { }

interface Optional extends JsonOptional { }

interface JsonRequired {
}

interface JsonOptional {
    grouping: string,
    customerCode: string,
    partsNo: string,
    customerPartsNo: string,
}