import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, makeStyles, Tooltip, Typography } from "@material-ui/core"
import { Clear } from "@material-ui/icons"
import { Break, EntryItem, Form, NumberItem, StringItem } from "@rithe/form"
import { GridItem } from "@rithe/ui"
import React, { ReactNode, useCallback, useMemo, useState } from "react"
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl"
import { CallbackCardAction } from "../../../components/Action/CallbackCardAction"
import { IconButton } from "../../../components/Button/IconButton"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { Currency, GiGrInvoice } from "../../../services/accounting/models/GiGrInvoice"
import { TatInvoiceBreakdown } from "../../../services/accounting/models/TatInvoiceBreakdown"
import { TnmIncoterms } from "../../../services/master/models/TnmIncoterms"

interface BreakdownProps {
    title: ReactNode,
    invoice?: GiGrInvoice,
    onInvoiceChange?: React.Dispatch<React.SetStateAction<GiGrInvoice | undefined>>,
    open: boolean,
    onClose: () => void,
    incoterms: TnmIncoterms[],
    setInvoice?: React.Dispatch<React.SetStateAction<GiGrInvoice | undefined>>,
    search: () => void,
    editable: boolean,
}

export const AIS021BreakdownPcUi = ({ title, invoice, onInvoiceChange, open, onClose, incoterms, editable }: BreakdownProps) => {

    const viewOnlyFlag: boolean = !editable

    const defaultBreakdowns = useMemo(() => {
        return invoice?.invoices ? invoice?.invoices.flatMap(inv => prepareBreakdown(inv.breakdown, inv.invoiceNo, inv.invoiceCurrency)) : [prepareBreakdown(invoice?.breakdown, invoice?.invoiceNo, invoice?.invoiceCurrency)]
    }, [invoice?.breakdown, invoice?.invoiceCurrency, invoice?.invoiceNo, invoice?.invoices])
    const [breakdowns, setBreakdowns] = useState<InvoiceBreakDown[]>(defaultBreakdowns)
    const invoiceNos = useMemo(() => invoice?.invoices ? invoice?.invoices.flatMap(m => m.invoiceNo) ?? [] : [invoice?.invoiceNo ?? ''], [invoice?.invoiceNo, invoice?.invoices])

    const onBreakdownChange = useCallback((breakdown) => {
        return (nextDraftDataFunc: React.Dispatch<React.SetStateAction<any>>) => {
            setBreakdowns(breakdowns => {
                const afterData = fatchBreakdown(typeof nextDraftDataFunc === 'function' ? nextDraftDataFunc(breakdown) : nextDraftDataFunc)
                return breakdowns.map((m) => m.invoiceNo === breakdown.invoiceNo ? afterData : m)
            })
        }
    }, [])

    const fetchInvoiceChange = useCallback((breakdowns) => {
        onInvoiceChange && onInvoiceChange(inv => {
            return inv ? (breakdowns.length === 1 ? {
                ...inv,
                breakdown: deconstructBreakdown(breakdowns[0], inv.breakdown ?? {}, incoterms)
            } : {
                ...inv,
                invoices: inv?.invoices ? inv?.invoices?.map((m, index) => ({ ...m, breakdown: deconstructBreakdown(breakdowns[index], m.breakdown ?? {}, incoterms) })) : null
            }) : inv
        })
    }, [incoterms, onInvoiceChange])

    return <Dialog open={open} onClose={onClose} maxWidth="lg" >
        <DialogTitle style={{ cursor: 'move' }} ><Typography variant="h3">{title}</Typography></DialogTitle>
        <DialogContent>
            <Divider />
            {breakdowns.map((breakdown, index) => <BreakdownContent viewOnlyFlag={viewOnlyFlag} index={index} invoiceBreakdown={breakdown} invoiceNos={invoiceNos} onBreakdownChange={onBreakdownChange(breakdown)} setBreakdowns={setBreakdowns} incoterms={incoterms} />)}
            <Divider />
        </DialogContent>
        <DialogActions>
            {viewOnlyFlag ? <DialogAction outlined title={<FormattedMessage id="close" />} callback={onClose} />
                : <>
                    <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={onClose} />
                    <DialogAction title={<FormattedMessage id="ok" />} callback={() => {
                        fetchInvoiceChange(breakdowns)
                        onClose()
                    }} />
                </>
            }
        </DialogActions>
    </Dialog >
}

const BreakdownContent = ({ index, invoiceNos, invoiceBreakdown, onBreakdownChange, incoterms, setBreakdowns, viewOnlyFlag }: {
    index: number,
    invoiceNos: string[],
    invoiceBreakdown: InvoiceBreakDown,
    onBreakdownChange: React.Dispatch<any>,
    setBreakdowns: React.Dispatch<React.SetStateAction<InvoiceBreakDown[]>>,
    incoterms: TnmIncoterms[],
    viewOnlyFlag: boolean
}) => {
    const intl = useIntl()
    return <>
        {index > 0 && <Divider />}
        {invoiceNos.length > 1 && <div style={{ paddingLeft: 16, paddingTop: 16 }}>
            <Typography variant="subtitle2">{invoiceNos[index]}</Typography>
        </div>}
        <div style={{ padding: 16 }}>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    title={intl.formatMessage({ id: 'InvoiceAmount' })}
                />
                <SectionCardContent>
                    <InvoiceAmountView viewOnlyFlag={viewOnlyFlag} invoiceBreakdown={invoiceBreakdown} onBreakdownChange={onBreakdownChange} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    title={intl.formatMessage({ id: 'CostBreakdown' })}
                />
                <SectionCardContent>
                    <CostBreakdownView viewOnlyFlag={viewOnlyFlag} incoterms={incoterms} onBreakdownChange={onBreakdownChange} invoiceBreakdown={invoiceBreakdown} setBreakdowns={setBreakdowns} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    title={intl.formatMessage({ id: 'AddtionalCost' })}
                />
                <SectionCardContent>
                    <AddtionalCostView viewOnlyFlag={viewOnlyFlag} invoiceBreakdown={invoiceBreakdown} setBreakdowns={setBreakdowns} onBreakdownChange={onBreakdownChange} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    title={intl.formatMessage({ id: 'InvoiceAmountGrandTotal' })}
                />
                <SectionCardContent>
                    <InvoiceAmountGrandTotalView viewOnlyFlag={viewOnlyFlag} incoterms={incoterms} onBreakdownChange={onBreakdownChange} invoiceBreakdown={invoiceBreakdown} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    title={intl.formatMessage({ id: 'Preview' })}
                />
                <SectionCardContent>
                    <PreviewView viewOnlyFlag={viewOnlyFlag} incoterms={incoterms} invoiceBreakdown={invoiceBreakdown} />
                </SectionCardContent>
            </SectionCard>
        </div>
    </>
}

// card_1
const InvoiceAmountView = ({ invoiceBreakdown, onBreakdownChange, viewOnlyFlag }: {
    invoiceBreakdown: InvoiceBreakDown,
    onBreakdownChange: React.Dispatch<any>,
    viewOnlyFlag: boolean
}) => {
    const intl = useIntl()
    const getAmountByAdjustValue = useCallback((data) => data.invoiceAmount + (data.invoiceAdjustmentAmount ?? 0), [])
    return <Form columnCount={13} data={invoiceBreakdown} setData={onBreakdownChange} labelDisplay="block" helperDisplay="tooltip">
        <StringItem field='invoiceCurrency' readonly label={intl.formatMessage({ id: 'field.invoiceCurrency' })} colSpan={2} />
        <NumberItem field='invoiceAmount' readonly label={intl.formatMessage({ id: 'field.invoiceAmount' })} colSpan={3} />
        <NumberItem field='invoiceAdjustmentAmount' readonly={viewOnlyFlag} label={intl.formatMessage({ id: 'field.adjustInvoiceAmount' })} colSpan={3} />
        <NumberItem field='invoiceAmountByAdjust' readonly label={intl.formatMessage({ id: 'field.invoiceAmount(adjusted)' })} getValue={getAmountByAdjustValue} colSpan={4} />
        <Break />
        <StringItem field='invoiceAdjustmentReason' readonly={viewOnlyFlag} label={intl.formatMessage({ id: 'field.invoiceReason' })} colSpan={13} />
    </Form>
}

// card_2
const CostBreakdownView = ({ incoterms, invoiceBreakdown, onBreakdownChange, setBreakdowns, viewOnlyFlag }: {
    invoiceBreakdown: InvoiceBreakDown,
    onBreakdownChange: React.Dispatch<any>,
    incoterms: TnmIncoterms[],
    setBreakdowns: React.Dispatch<React.SetStateAction<InvoiceBreakDown[]>>,
    viewOnlyFlag: boolean
}) => {
    const intl = useIntl()
    const incoMap: [number, string][] = useMemo(() => incoterms.map((m) => [m.incotermsId, m.incotermsCode]), [incoterms])
    const { costItems } = invoiceBreakdown
    const mapItemNameValue = useCallback((item) => {
        return (data: any, value: any) => ({ ...data, costItems: data.costItems.map((m: any) => m.itemNo === item.itemNo ? { ...m, itemName: value } : { ...m }) })
    }, [])
    const mapItemAmountValue = useCallback((item) => {
        return (data: any, value: any) => ({ ...data, costItems: data.costItems.map((m: any) => m.itemNo === item.itemNo ? { ...m, itemAmount: value } : { ...m }) })
    }, [])

    return <Form columnCount={13} data={invoiceBreakdown} setData={onBreakdownChange} labelDisplay="block" helperDisplay="tooltip" >
        <EntryItem field='initialIncotermsId' readonly={viewOnlyFlag} label={intl.formatMessage({ id: 'field.initialIncotermsCode' })} entries={incoMap} colSpan={2} />
        <StringItem field='initialIncotermsPlace' readonly={viewOnlyFlag} label={intl.formatMessage({ id: 'field.initialIncotermsPlace' })} colSpan={6} />
        <NumberItem field='initialIncotermsAmount' readonly label={intl.formatMessage({ id: 'field.initialIncotermsAmount' })} colSpan={4} />
        <Break />

        {costItems && costItems.map((item) => {
            return <>
                <StringItem field={'itemName' + item.itemNo} readonly={viewOnlyFlag} label={intl.formatMessage({ id: 'field.item' }) + ' ' + (item.itemNo + 1)} colSpan={8} getValue={() => item.itemName} mapValue={mapItemNameValue(item)} />
                <NumberItem field={'itemAmount' + item.itemNo} readonly={viewOnlyFlag} label={intl.formatMessage({ id: 'field.item' }) + ' ' + (item.itemNo + 1) + ' ' + intl.formatMessage({ id: 'field.Amount' })} colSpan={4} getValue={() => item.itemAmount} mapValue={mapItemAmountValue(item)} />
                {!viewOnlyFlag && <GridItem style={{ display: 'flex', alignItems: 'flex-end' }}> <RemoveCostItemsAction setBreakdowns={setBreakdowns} invoiceBreakdown={invoiceBreakdown} itemNo={item.itemNo} /></GridItem>}
            </>
        })}

        {!viewOnlyFlag && <>
            <Break />
            <GridItem columnSpan={4}>
                {(!costItems || costItems.length < 5) && <AddCostItemsAction setBreakdowns={setBreakdowns} invoiceBreakdown={invoiceBreakdown} />}
            </GridItem>
        </>
        }
    </Form >
}

const AddCostItemsAction = ({ setBreakdowns, invoiceBreakdown }: {
    setBreakdowns: React.Dispatch<React.SetStateAction<InvoiceBreakDown[]>>,
    invoiceBreakdown: InvoiceBreakDown
}) => {
    const addAction = useCallback((orgBreakdown) => {
        return () => {
            setBreakdowns(breakdowns => {
                const costItems = orgBreakdown.costItems
                const afterBreakdown = { ...orgBreakdown, costItems: [...costItems, { itemNo: costItems.length }] }
                return breakdowns.map((m) => m.invoiceNo === afterBreakdown.invoiceNo ? afterBreakdown : m)
            })
        }
    }, [setBreakdowns])
    return <CallbackCardAction outlined title={<FormattedMessage id="addDesc" />} callback={addAction(invoiceBreakdown)} />
}

const RemoveCostItemsAction = ({ setBreakdowns, invoiceBreakdown, itemNo }: {
    setBreakdowns: React.Dispatch<React.SetStateAction<InvoiceBreakDown[]>>,
    invoiceBreakdown: InvoiceBreakDown,
    itemNo: number,
}) => {
    const costRemoveAction = useCallback((orgBreakdown, itemNo) => {
        return () => {
            setBreakdowns(breakdowns => {
                const costItems = orgBreakdown.costItems
                const newCostItems = costItems.filter((f: any) => f.itemNo !== itemNo).map((m: any, idx: number) => ({ itemNo: idx, ...m }))
                const afterBreakdown = { ...orgBreakdown, costItems: newCostItems }
                return breakdowns.map((m) => m.invoiceNo === afterBreakdown.invoiceNo ? afterBreakdown : m)
            })
        }
    }, [setBreakdowns])
    return <Tooltip title={<FormattedMessage id="delDesc" />}>
        <IconButton onClick={costRemoveAction(invoiceBreakdown, itemNo)}><Clear fontSize={'small'} /></IconButton>
    </Tooltip>
}

// card_3
const AddtionalCostView = ({ invoiceBreakdown, setBreakdowns, onBreakdownChange, viewOnlyFlag }: {
    invoiceBreakdown: InvoiceBreakDown,
    setBreakdowns: React.Dispatch<React.SetStateAction<InvoiceBreakDown[]>>,
    onBreakdownChange: React.Dispatch<any>,
    viewOnlyFlag: boolean
}) => {

    const intl = useIntl()
    const { additionalItems } = invoiceBreakdown
    const mapItemNameValue = useCallback((item) => {
        return (data: any, value: any) => ({ ...data, additionalItems: data.additionalItems.map((m: any) => m.itemNo === item.itemNo ? { ...m, itemName: value } : { ...m }) })
    }, [])
    const mapItemAmountValue = useCallback((item) => {
        return (data: any, value: any) => ({ ...data, additionalItems: data.additionalItems.map((m: any) => m.itemNo === item.itemNo ? { ...m, itemAmount: value } : { ...m }) })
    }, [])

    return <Form columnCount={13} data={invoiceBreakdown} readonly={viewOnlyFlag} setData={onBreakdownChange} labelDisplay="block" helperDisplay="tooltip" >
        {additionalItems && additionalItems.map((item) => {
            return <>
                <StringItem field={'additionalItemName' + item.itemNo} label={intl.formatMessage({ id: 'field.item' }) + ' ' + (item.itemNo + 1)} colSpan={8} getValue={() => item.itemName} mapValue={mapItemNameValue(item)} />
                <NumberItem field={'additionalItemAmount' + item.itemNo} label={intl.formatMessage({ id: 'field.item' }) + ' ' + (item.itemNo + 1) + ' ' + intl.formatMessage({ id: 'field.Amount' })} colSpan={4} getValue={() => item.itemAmount} mapValue={mapItemAmountValue(item)} />
                {!viewOnlyFlag && <GridItem style={{ display: 'flex', alignItems: 'flex-end' }}> <RemoveAddtionItemsAction setBreakdowns={setBreakdowns} invoiceBreakdown={invoiceBreakdown} itemNo={item.itemNo} /></GridItem>}
            </>
        })}
        {!viewOnlyFlag && <>
            <Break />
            <GridItem columnSpan={4}>
                {(additionalItems === undefined || additionalItems.length < 5) && <AddAddtionItemsAction setBreakdowns={setBreakdowns} invoiceBreakdown={invoiceBreakdown} />}
            </GridItem>
        </>}
    </Form >
}

const AddAddtionItemsAction = ({ setBreakdowns, invoiceBreakdown }: {
    setBreakdowns: React.Dispatch<React.SetStateAction<InvoiceBreakDown[]>>,
    invoiceBreakdown: InvoiceBreakDown
}) => {
    const addAction = useCallback((orgBreakdown) => {
        return () => {
            setBreakdowns(breakdowns => {
                const additionalItems = orgBreakdown.additionalItems
                const afterBreakdown = { ...orgBreakdown, additionalItems: [...additionalItems, { itemNo: additionalItems.length }] }
                return breakdowns.map((m) => m.invoiceNo === afterBreakdown.invoiceNo ? afterBreakdown : m)
            })
        }
    }, [setBreakdowns])
    return <CallbackCardAction outlined title={<FormattedMessage id="addDesc" />} callback={addAction(invoiceBreakdown)} />
}

const RemoveAddtionItemsAction = ({ setBreakdowns, invoiceBreakdown, itemNo }: {
    setBreakdowns: React.Dispatch<React.SetStateAction<InvoiceBreakDown[]>>,
    invoiceBreakdown: InvoiceBreakDown,
    itemNo: number,
}) => {
    const removeAction = useCallback((orgBreakdown, itemNo) => {
        return () => {
            setBreakdowns(breakdowns => {
                const additionalItems = orgBreakdown.additionalItems
                const newAdditionalItems = additionalItems.filter((f: any) => f.itemNo !== itemNo).map((m: any, idx: number) => ({ ...m, itemNo: idx }))
                const afterBreakdown = { ...orgBreakdown, additionalItems: newAdditionalItems }
                return breakdowns.map((m) => m.invoiceNo === afterBreakdown.invoiceNo ? afterBreakdown : m)
            })
        }
    }, [setBreakdowns])
    return <Tooltip title={<FormattedMessage id="delDesc" />}>
        <IconButton onClick={removeAction(invoiceBreakdown, itemNo)}><Clear fontSize={'small'} /></IconButton>
    </Tooltip>
}

// card_4
const InvoiceAmountGrandTotalView = ({ invoiceBreakdown, onBreakdownChange, incoterms, viewOnlyFlag }: {
    invoiceBreakdown: InvoiceBreakDown,
    onBreakdownChange: React.Dispatch<any>,
    incoterms: TnmIncoterms[],
    viewOnlyFlag: boolean
}) => {
    const intl = useIntl()
    const IncoMap: [number, string][] = useMemo(() => incoterms.map((m) => [m.incotermsId, m.incotermsCode]), [incoterms])

    return <Form columnCount={13} data={invoiceBreakdown} setData={onBreakdownChange} labelDisplay="block" helperDisplay="tooltip" >
        <EntryItem field='initialIncotermsId' readonly label={intl.formatMessage({ id: 'field.initialIncotermsCode' })} entries={IncoMap} colSpan={2} />
        <StringItem field='initialIncotermsPlace' readonly label={intl.formatMessage({ id: 'field.initialIncotermsPlace' })} colSpan={6} />
        <NumberItem field='initialIncotermsAmount' readonly label={intl.formatMessage({ id: 'field.initialIncotermsAmount' })} colSpan={4} />
        <Break />

        <EntryItem field='finalIncotermsId' readonly={viewOnlyFlag} label={intl.formatMessage({ id: 'field.finalIncotermsCode' })} entries={IncoMap} colSpan={2} />
        <StringItem field='finalIncotermsPlace' readonly={viewOnlyFlag} label={intl.formatMessage({ id: 'field.finalIncotermsPlace' })} colSpan={6} />
        <NumberItem field='finalIncotermsAmount' readonly label={intl.formatMessage({ id: 'field.finalIncotermsAmount' })} colSpan={4} />
    </Form>
}

// card_5
const PreviewView = ({ incoterms, invoiceBreakdown }: {
    incoterms: TnmIncoterms[],
    invoiceBreakdown: InvoiceBreakDown,
    viewOnlyFlag: boolean
}) => {

    const styles = useStyles()

    const { initialIncotermsId, finalIncotermsId, invoiceAmount, invoiceAdjustmentAmount, invoiceCurrency, costItems, additionalItems, currencyDigits } = invoiceBreakdown

    const disCostItems = useMemo(() => costItems ? costItems.filter(f => f.itemName || (f.itemAmount !== null && f.itemAmount !== undefined)) : [], [costItems])
    const disAdditionItems = useMemo(() => additionalItems ? additionalItems.filter(f => f.itemName || (f.itemAmount !== null && f.itemAmount !== undefined)) : [], [additionalItems])

    const initialIncotermsCode = useMemo(() => incoterms.find(f => f.incotermsId === initialIncotermsId)?.incotermsCode, [incoterms, initialIncotermsId])
    const finalIncotermsCode = useMemo(() => incoterms.find(f => f.incotermsId === finalIncotermsId)?.incotermsCode, [finalIncotermsId, incoterms])
    const invoiceAmountByAdjust = useMemo(() => (invoiceAmount ?? 0) + (invoiceAdjustmentAmount ?? 0), [invoiceAdjustmentAmount, invoiceAmount])

    return <DataBlock >
        {((disCostItems.length + disAdditionItems.length) > 0 || initialIncotermsId !== finalIncotermsId) ?
            <>
                <Grid container>
                    <Grid item xs={1} container direction="column">
                        <Typography variant="body2">{initialIncotermsCode}</Typography>
                    </Grid>
                    <Grid item xs={3} container direction="column" alignItems="flex-start">
                        <Typography variant="body2">{invoiceBreakdown.initialIncotermsPlace}</Typography>
                    </Grid>
                    <Grid item xs={2} container direction="column" alignItems="flex-end">
                        <Typography variant="body2">{invoiceCurrency} <FormattedNumber value={invoiceBreakdown.initialIncotermsAmount ?? 0} minimumFractionDigits={currencyDigits} maximumFractionDigits={currencyDigits} /></Typography>
                    </Grid>
                </Grid>
                {
                    disCostItems && <>
                        {
                            disCostItems.map(item => {
                                return <Grid container>
                                    <Grid item xs={4} container direction="column">
                                        <Typography variant="body2">{item.itemName}</Typography>
                                    </Grid>
                                    <Grid item xs={2} container direction="column" alignItems="flex-end">
                                        <Typography variant="body2">{invoiceCurrency} <FormattedNumber value={item.itemAmount ?? 0} minimumFractionDigits={currencyDigits} maximumFractionDigits={currencyDigits} /></Typography>
                                    </Grid>
                                </Grid>
                            })
                        }
                        <Grid item container xs={6}><div className={styles.subDivider} ><Divider /></div></Grid>
                        <Grid container>
                            <Grid item xs={4} container direction="column">
                                <Typography variant="body2">SUB TOTAL</Typography>
                            </Grid>
                            <Grid item xs={2} container direction="column" alignItems="flex-end">
                                <Typography variant="body2">{invoiceCurrency} <FormattedNumber value={invoiceAmountByAdjust} minimumFractionDigits={currencyDigits} maximumFractionDigits={currencyDigits} /></Typography>
                            </Grid>
                        </Grid>
                        <Grid item container xs={6}>
                            <div style={{ width: '100%', alignItems: 'center', padding: 8 }}></div>
                        </Grid>
                    </>
                }
                {
                    disAdditionItems.map(item => {
                        return <Grid container>
                            <Grid item xs={4} container direction="column">
                                <Typography variant="body2">{item.itemName}</Typography>
                            </Grid>
                            <Grid item xs={2} container direction="column" alignItems="flex-end">
                                <Typography variant="body2">{invoiceCurrency} <FormattedNumber value={item.itemAmount ?? 0} minimumFractionDigits={currencyDigits} maximumFractionDigits={currencyDigits} /></Typography>
                            </Grid>
                        </Grid>
                    })
                }
            </> : <></>
        }
        <Grid container>
            <Grid item xs={1} container direction="column">
                <Typography variant="body2">{finalIncotermsCode}</Typography>
            </Grid>
            <Grid item xs={3} container direction="column" alignItems="flex-start">
                <Typography variant="body2">{invoiceBreakdown.finalIncotermsPlace}</Typography>
            </Grid>
            <Grid item xs={2} container direction="column" alignItems="flex-end">
                <Typography variant="body2">{invoiceCurrency} <FormattedNumber value={invoiceBreakdown.finalIncotermsAmount ?? 0} minimumFractionDigits={currencyDigits} maximumFractionDigits={currencyDigits} /></Typography>
            </Grid>
        </Grid>

        <Grid item container xs={6}><div className={styles.divider} ><Divider /></div></Grid>
        <Grid container>
            <Grid item xs={1} container direction="column">
                <Typography variant="body2" style={{ fontWeight: 'bold' }}><FormattedMessage id="GrandTotal" /></Typography>
            </Grid>
            <Grid item xs={3} container direction="column">
                <Typography variant="body2"></Typography>
            </Grid>
            <Grid item xs={2} container direction="column" alignItems="flex-end">
                <Typography variant="body2" style={{ fontWeight: 'bold' }}>{invoiceCurrency} <FormattedNumber value={invoiceBreakdown.finalIncotermsAmount as number} minimumFractionDigits={currencyDigits} maximumFractionDigits={currencyDigits} /></Typography>
            </Grid>
        </Grid>
    </DataBlock >
}

const DataBlock = ({ colSpan = 1, rowSpan = 1, children }: { colSpan?: number, rowSpan?: number, children?: ReactNode | ReactNode[] }) => {
    const styles = useStyles()
    return <div className={styles.dataArea} style={{ gridColumn: `span ${colSpan}`, gridRow: `span ${rowSpan}` }}>
        <div>
            {children}
        </div>
    </div>
}

const useStyles = makeStyles(theme => ({
    dataArea: {
        border: `1px dashed ${theme.palette.divider}`,
        borderRadius: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: theme.spacing(2),
        '&>*:nth-child(1)': {
            textTransform: 'uppercase',
            marginBottom: theme.spacing(2),
        },
        '&>*:nth-child(2)': {
            padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
            borderRadius: theme.spacing(1),
            backgroundColor: theme.palette.grey[200],
            minHeight: 37,
            boxSizing: 'border-box',
        },
    },
    divider: {
        width: '100%',
        alignItems: 'center',
        padding: theme.spacing(1, 0),
    },
    subDivider: {
        width: '100%',
        alignItems: 'center',
        padding: 5,
    }
}))


const prepareBreakdown = (breakdown: TatInvoiceBreakdown | undefined, invoiceNo: string | undefined, currency: Currency | undefined) => {

    return breakdown ? {
        invoiceNo: invoiceNo,
        invoiceCurrency: currency?.currencyCode,
        currencyDigits: currency?.decimalDigits,
        invoiceAmount: breakdown.invoiceAmount,
        invoiceAdjustmentAmount: breakdown.invoiceAdjustmentAmount,
        invoiceAdjustmentReason: breakdown.invoiceAdjustmentReason,
        initialIncotermsId: breakdown.initialIncotermsId,
        initialIncotermsPlace: breakdown.initialIncotermsPlace,
        initialIncotermsAmount: breakdown.initialIncotermsAmount,
        finalIncotermsId: breakdown.finalIncotermsId,
        finalIncotermsPlace: breakdown.finalIncotermsPlace,
        finalIncotermsAmount: breakdown.finalIncotermsAmount,
        costItems: prepareItems([
            [breakdown.costItem1, breakdown.costItem1Amount],
            [breakdown.costItem2, breakdown.costItem2Amount],
            [breakdown.costItem3, breakdown.costItem3Amount],
            [breakdown.costItem4, breakdown.costItem4Amount],
            [breakdown.costItem5, breakdown.costItem5Amount],
        ]),
        additionalItems: prepareItems([
            [breakdown.additionalItem1, breakdown.additionalItem1Amount],
            [breakdown.additionalItem2, breakdown.additionalItem2Amount],
            [breakdown.additionalItem3, breakdown.additionalItem3Amount],
            [breakdown.additionalItem4, breakdown.additionalItem4Amount],
            [breakdown.additionalItem5, breakdown.additionalItem5Amount],
        ]),
    } : {
        invoiceNo: invoiceNo
    } as InvoiceBreakDown
}
const prepareItems = (items: [string | undefined, number | undefined][]) => {
    const max = items.map((m, idx) => (m[0] || (m[1] !== null && m[1] !== undefined)) ? idx : -1).sort((a, b) => b - a)[0]
    return max >= 0 ? items.filter((_, idx) => idx <= max).map((m, idx) => ({ itemNo: idx, itemName: m[0], itemAmount: m[1] })) : [{ itemNo: 0 }, { itemNo: 1 }, { itemNo: 2 }]
}


const deconstructBreakdown = (editBreakdown: InvoiceBreakDown, orgBreakdown: TatInvoiceBreakdown, incoterms: TnmIncoterms[]) => {

    const { initialIncotermsId, finalIncotermsId } = editBreakdown

    const initialIncoterms = incoterms.find(f => f.incotermsId === initialIncotermsId)
    const initialIncotermsCode = initialIncoterms?.incotermsCode
    const initialIncotermsDescription = initialIncoterms?.description

    const finalIncoterms = incoterms.find(f => f.incotermsId === finalIncotermsId)
    const finalIncotermsCode = finalIncoterms?.incotermsCode
    const finalIncotermsDescription = finalIncoterms?.description

    return editBreakdown ? {
        invBreakId: orgBreakdown?.invBreakId,
        invId: orgBreakdown?.invId,
        invoiceCurrency: orgBreakdown?.invoiceCurrency,
        invoiceAmount: orgBreakdown?.invoiceAmount,
        invoiceAdjustmentAmount: editBreakdown?.invoiceAdjustmentAmount,
        invoiceAdjustmentReason: editBreakdown?.invoiceAdjustmentReason,
        costItem1: editBreakdown.costItems && editBreakdown.costItems.length >= 1 ? editBreakdown.costItems[0].itemName : undefined,
        costItem1Amount: editBreakdown.costItems && editBreakdown.costItems.length >= 1 ? editBreakdown.costItems[0].itemAmount : undefined,
        costItem2: editBreakdown.costItems && editBreakdown.costItems.length >= 2 ? editBreakdown.costItems[1].itemName : undefined,
        costItem2Amount: editBreakdown.costItems && editBreakdown.costItems.length >= 2 ? editBreakdown.costItems[1].itemAmount : undefined,
        costItem3: editBreakdown.costItems && editBreakdown.costItems.length >= 3 ? editBreakdown.costItems[2].itemName : undefined,
        costItem3Amount: editBreakdown.costItems && editBreakdown.costItems.length >= 3 ? editBreakdown.costItems[2].itemAmount : undefined,
        costItem4: editBreakdown.costItems && editBreakdown.costItems.length >= 4 ? editBreakdown.costItems[3].itemName : undefined,
        costItem4Amount: editBreakdown.costItems && editBreakdown.costItems.length >= 4 ? editBreakdown.costItems[3].itemAmount : undefined,
        costItem5: editBreakdown.costItems && editBreakdown.costItems.length >= 5 ? editBreakdown.costItems[4].itemName : undefined,
        costItem5Amount: editBreakdown.costItems && editBreakdown.costItems.length >= 5 ? editBreakdown.costItems[4].itemAmount : undefined,
        additionalItem1: editBreakdown.additionalItems && editBreakdown.additionalItems.length >= 1 ? editBreakdown.additionalItems[0].itemName : undefined,
        additionalItem1Amount: editBreakdown.additionalItems && editBreakdown.additionalItems.length >= 1 ? editBreakdown.additionalItems[0].itemAmount : undefined,
        additionalItem2: editBreakdown.additionalItems && editBreakdown.additionalItems.length >= 2 ? editBreakdown.additionalItems[1].itemName : undefined,
        additionalItem2Amount: editBreakdown.additionalItems && editBreakdown.additionalItems.length >= 2 ? editBreakdown.additionalItems[1].itemAmount : undefined,
        additionalItem3: editBreakdown.additionalItems && editBreakdown.additionalItems.length >= 3 ? editBreakdown.additionalItems[2].itemName : undefined,
        additionalItem3Amount: editBreakdown.additionalItems && editBreakdown.additionalItems.length >= 3 ? editBreakdown.additionalItems[2].itemAmount : undefined,
        additionalItem4: editBreakdown.additionalItems && editBreakdown.additionalItems.length >= 4 ? editBreakdown.additionalItems[3].itemName : undefined,
        additionalItem4Amount: editBreakdown.additionalItems && editBreakdown.additionalItems.length >= 4 ? editBreakdown.additionalItems[3].itemAmount : undefined,
        additionalItem5: editBreakdown.additionalItems && editBreakdown.additionalItems.length >= 5 ? editBreakdown.additionalItems[4].itemName : undefined,
        additionalItem5Amount: editBreakdown.additionalItems && editBreakdown.additionalItems.length >= 5 ? editBreakdown.additionalItems[4].itemAmount : undefined,
        initialIncotermsId: editBreakdown?.initialIncotermsId,
        initialIncotermsCode: initialIncotermsCode,
        initialIncotermsPlace: editBreakdown?.initialIncotermsPlace,
        initialIncotermsAmount: editBreakdown?.initialIncotermsAmount,
        initialIncotermsDescription: initialIncotermsDescription,
        finalIncotermsId: editBreakdown?.finalIncotermsId,
        finalIncotermsCode: finalIncotermsCode,
        finalIncotermsPlace: editBreakdown?.finalIncotermsPlace,
        finalIncotermsAmount: editBreakdown?.finalIncotermsAmount,
        finalIncotermsDescription: finalIncotermsDescription,
    } as TatInvoiceBreakdown : orgBreakdown
}

const fatchBreakdown = (orgData: any) => {
    const calculateItemsAmount = (items: ItemsField[]) => items.map(m => m.itemAmount ?? 0).reduce((a, b) => a + b, 0)
    return {
        ...orgData,
        initialIncotermsAmount: (orgData.invoiceAmount ?? 0) + (orgData.invoiceAdjustmentAmount ?? 0) - calculateItemsAmount(orgData.costItems),
        finalIncotermsAmount: (orgData.invoiceAmount ?? 0) + (orgData.invoiceAdjustmentAmount ?? 0) + calculateItemsAmount(orgData.additionalItems)
    }
}

interface InvoiceBreakDown {

    invoiceNo?: string,
    invoiceCurrency?: string,
    invoiceAmount?: number,
    invoiceAdjustmentAmount?: number,
    invoiceAdjustmentReason?: string,

    initialIncotermsId?: number,
    initialIncotermsPlace?: string,
    initialIncotermsAmount?: number,
    finalIncotermsId?: number,
    finalIncotermsPlace?: string,
    finalIncotermsAmount?: number,
    currencyDigits?: number,
    costItems?: ItemsField[],
    additionalItems?: ItemsField[],
}

interface ItemsField {
    itemNo: number,
    itemName?: string,
    itemAmount?: number,
}