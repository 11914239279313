import { Project } from "../../../utils/Project"
import { dateToJson } from "../../utils/serializer"

export interface OrderCalcRunConditionVo {
    orderGroupId: string,
    orderTime: Date,
    cutOffDate?: Date,
    weeklyOrderForecastCount?: number,
    usageVersion?: string,
}

interface OrderCalcRunConditionVoJson {
    orderGroupId: string,
    orderTime: string,
    cutOffDate?: string,
    weeklyOrderForecastCount?: number,
    usageVersion?: string,
}

export const orderCalcRunConditionVoToJson: Project<OrderCalcRunConditionVo, OrderCalcRunConditionVoJson> = obj => ({
    ...obj,
    cutOffDate: dateToJson(obj.cutOffDate),
    orderTime: dateToJson(obj.orderTime),
})
