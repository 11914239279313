import { Grid } from "@material-ui/core"
import { ColumnFreeze, ColumnOrdering, ColumnResizing, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { Records } from "@rithe/utils"
import { useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { SimpleCard } from "../../../components/Card/SimpleCard"
import { SimpleCardContent } from "../../../components/Card/SimpleCardContent"
import { SimpleCardHeader } from "../../../components/Card/SimpleCardHeader"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SectionTitle } from "../../../components/SectionTitle/SectionTitle"
import { OrderMonthFactor } from "../../../services/dashboard/models/CostMonitorFactor"
import { CostResult, CostsOrderResult } from "../../../services/dashboard/models/CostMonitorResult"
import { DataCard } from "../Card/DataCard"

interface DAS012PcUiProps {
    defaultFilter: OrderMonthFactor,
    search: (filters: OrderMonthFactor) => void,
    inboundData: CostResult[],
    purchaseData: CostsOrderResult[],
}

export const DAS012PcUi = (props: DAS012PcUiProps) => {
    const { inboundData, purchaseData } = props

    const totalData = useMemo(() => {
        const perCost: number = 0// reset to zero
        const totalNo = purchaseData.length ?? 0
        const inboundNoNum = inboundData.length ?? 0
        const totalCost = (totalNo * perCost) + (inboundNoNum * perCost)

        return {
            totalNo: totalNo,
            totalCost: totalCost,
            perCost: perCost,
            inboundNoNum: inboundNoNum,
        }
    }, [inboundData.length, purchaseData.length])

    const intl = useIntl()

    return <SimpleCard>
        <SimpleCardHeader title={intl.formatMessage({ id: 'transactionalStatistics' })} actions={[]} />
        <SimpleCardContent>
            <PurchaseRecordView totalData={totalData} purchaseData={purchaseData} />
            <SectionTitle size='large' ><FormattedMessage id='inboundRecord' /></SectionTitle>
            <InboundRecordView inboundData={inboundData} />
        </SimpleCardContent>
    </SimpleCard>
}

const PurchaseRecordView = ({ totalData, purchaseData }: {
    totalData: { totalNo: number, totalCost: number, perCost: number, inboundNoNum: number },
    purchaseData: CostsOrderResult[]
}) => {

    const intl = useIntl()
    const columns = useMemo(() => [
        { field: 'purchaseDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'purchaseOrderDate' }), width: 400 },
        { field: 'purchaseNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'purchaseOrderNo' }), width: 400 },
    ], [intl])

    return <div style={{ width: '100%', paddingTop: 5, height: 500, display: 'flex' }}>
        <div style={{ width: '300px', paddingTop: 10 }}>
            <Grid container direction="column" spacing={2} >
                <Grid item >
                    <DataCard subContents={intl.formatMessage({ id: 'totalCost' })} currency={"USD"} numberData={totalData.totalCost} />
                </Grid>
                <Grid item >
                    <DataCard subContents={intl.formatMessage({ id: 'totalPO' })} numberData={totalData.totalNo} />
                </Grid>
                <Grid item >
                    <DataCard subContents={intl.formatMessage({ id: 'totalInbound' })} numberData={totalData.inboundNoNum} />
                </Grid>
                <Grid item >
                    <DataCard subContents={intl.formatMessage({ id: 'tierTransaction' })} currency={"USD"} numberData={totalData.perCost} />
                </Grid>
            </Grid>
        </div>
        <div style={{ paddingLeft: 15, width: '100%' }}>
            <SectionTitle size='large' ><FormattedMessage id='purchaseRecord' /></SectionTitle>
            <DataGrid>
                <ToolbarLayout />
                <TableLayout Container={FlexScrollbar}>
                    <TableHeaderLayout sticky />
                    <TableBodyLayout />
                </TableLayout>
                <PaginationLayout Pagination={Pagination} />
                <DataTypePreset />
                <Data rows={purchaseData} columns={columns} />
                <ColumnFreeze />
                <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
                <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
                <Sorting />
                <Filtering />
            </DataGrid>
        </div>
    </div>
}

const InboundRecordView = ({ inboundData }: { inboundData: CostResult[] }) => {
    const intl = useIntl()
    const columns = useMemo(() => [
        { field: 'inboundDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'inboundDate' }), width: 200 },
        { field: 'inboundNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'inboundNo' }), width: 300 },
        { field: 'purchaseDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'purchaseOrderDate' }), width: 250 },
        { field: 'purchaseNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'purchaseOrderNo' }), width: 300 },
    ], [intl])

    return <div style={{ width: '100%', paddingTop: 15, paddingBottom: 15, height: 600 }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <Data rows={inboundData} columns={columns} />
            <ColumnFreeze />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Sorting />
            <Filtering />
        </DataGrid>
    </div>
}