import React, { createContext, ReactNode, useCallback, useContext, useMemo, useState } from "react";

interface Context {
    enabled: boolean,
    collapsed: boolean,
    enable: () => void,
    disable: () => void,
    collapse: () => void,
    expand: () => void,
    toggle: () => void,
}

const CollapseContext = createContext<Context>(undefined as any)

export const useCollapseContext = () => {
    return useContext(CollapseContext)
}

export const CollapseContextProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
    const [enabled, setEnabled] = useState(false)
    const [collapsed, setCollapsed] = useState(false)
    const enable = useCallback(() => setEnabled(true), [])
    const disable = useCallback(() => setEnabled(false), [])
    const collapse = useCallback(() => setCollapsed(true), [])
    const expand = useCallback(() => setCollapsed(false), [])
    const toggle = useCallback(() => setCollapsed(c => !c), [])

    const value = useMemo(() => ({
        enabled,
        collapsed,
        enable,
        disable,
        collapse,
        expand,
        toggle
    }), [collapse, collapsed, disable, enable, enabled, expand, toggle])

    return <CollapseContext.Provider value={value}>
        {children}
    </CollapseContext.Provider>
}