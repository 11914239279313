import { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { useGetExternalDataForCreate, useGetExternalDataForUpdate, useGetFunctionList } from "../../../services/integration/apis/externalDataApis"
import { RequestDetailHead, RequestList } from "../../../services/integration/models/ExternalDataResult"
import { FunctionList } from "../../../services/integration/models/FunctionList"
import { useMatch } from "../../../utils/useMatch"
import { INT011PcUi } from "./INT011PcUi"

export const INT011 = () => {
    const { templateId } = useParams() as any
    const { path } = useMatch()
    const mode = useMemo(() => path === '/manageExternalData/create' ? ScreenMode.CREATE : ScreenMode.EDIT, [path])
    const [functionList, setFunctionList] = useState<FunctionList[]>([])
    const [headerInfo, setHeaderInfo] = useState<RequestDetailHead>({} as RequestDetailHead)
    const [list, setList] = useState<RequestList[]>([])

    const getFunctionList = useGetFunctionList()
    useEffect(() => {
        getFunctionList(undefined, { silent: true }).then(result => {
            setFunctionList(result ?? [])
        })
    }, [getFunctionList])

    const getDataForView = useGetExternalDataForUpdate()
    const getDataForCreate = useGetExternalDataForCreate()
    useEffect(() => {
        if (templateId !== null && templateId !== undefined) {
            getDataForView({ templateId: templateId }, { silent: true, serialized: true }).then(result => {
                setHeaderInfo(result?.header ?? {} as RequestDetailHead)
                setList(result?.list ?? [])
            })
        } else {
            if (headerInfo.function !== null && headerInfo.function !== undefined) {
                getDataForCreate({ function: headerInfo.function }, { silent: true, serialized: true }).then(result => {
                    setHeaderInfo(result?.header ?? {} as RequestDetailHead)
                    setList(result?.list ?? [])
                })
            }
        }
    }, [getDataForCreate, getDataForView, headerInfo.function, templateId])

    return <INT011PcUi
        functionList={functionList}
        headerInfo={headerInfo}
        setHeaderInfo={setHeaderInfo}
        list={list}
        setList={setList}
        mode={mode}
    />
}