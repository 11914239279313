import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, ObjectTypeProvider, PaginationLayout, Paging, Row, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { ObjectFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/ObjectFormatter"
import { DateRangeItem, EntriesItem, StringItem } from "@rithe/form"
import { Objects, Records } from "@rithe/utils"
import React, { SetStateAction, useCallback, useMemo, useState } from "react"
import { FormattedDate, FormattedMessage, useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { UploadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/UploadGroupedToolbarAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { ActiveFlagTypeProvider } from "../../../components/DataGrid/typeProviders/ActiveFlagTypeProvider"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { useDownloadPortMasterByFilter, useDownloadPortMasterByPortId } from "../../../services/master/apis/masterDownloadApi"
import { useUploadMasterPortMaster } from "../../../services/master/apis/masterUploadApi"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { PortFactor } from "../../../services/master/models/PortFactor"
import { PortResult } from "../../../services/master/models/PortResult"
import { TnmRegion } from "../../../services/master/models/TnmRegion"
import { useIsPowerUser } from "../../../utils/useIsPowerUser"

interface MLS060PcUiProps {
    filters: PortFactor,
    search: (filters: PortFactor) => void,
    data: PortResult[],
    totalCount: number,
    regionList: TnmRegion[],
    setFilters: React.Dispatch<SetStateAction<PortFactor>>,
}

export const MLS060PcUi = (props: MLS060PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({ filters, data, regionList, setFilters, search }: MLS060PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])
    const isPowerUser = useIsPowerUser()
    const columns = useMemo(() => [
        { field: 'portCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.portCode' }), width: 150 },
        { field: 'portName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.portName' }), width: 200 },
        { field: 'regionCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.regionCode' }), width: 200 },
        { field: 'activeFlag', dataTypeName: CodeCategory.ActiveFlag, title: intl.formatMessage({ id: 'field.activeFlag' }), width: 200 },
        { field: 'createdDate', dataTypeName: 'dateOnTime', title: intl.formatMessage({ id: 'field.createdDate' }), width: 200, getCellValue: (row: Row) => ({ date: row.createdDate })},
        { field: 'updatedDate', dataTypeName: 'dateOnTime', title: intl.formatMessage({ id: 'field.updatedDate' }), width: 200, getCellValue: (row: Row) => ({ date: row.updatedDate })},
    ], [intl])
    const getRowId = useCallback((row: any) => row.portId, [])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search, regionList }), [filters, setFilters, search, regionList])
    const actionProps1 = useMemo(() => ({ search, filters }), [filters, search])
    const actionProps2 = useMemo(() => ({ selections, filters }), [filters, selections])

    const displayUpload = () => isPowerUser

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <ActiveFlagTypeProvider />
        <DateOnTimeTypeProvider />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={UploadAction} actionProps={actionProps1} display={displayUpload} />
        <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps2} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={[]}
            columnSettings={{
                dcCode: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
    </DataGrid>
}

const FilterItem = (props: {
    search: (filters: PortFactor) => void,
    filters: PortFactor,
    setFilters: React.Dispatch<SetStateAction<PortFactor>>,
    regionList: TnmRegion[]
}) => {
    const { filters, regionList, setFilters, search } = props
    const regionListEntries: [string, string][] = useMemo(() => regionList.map(m => [m.regionCode, m.regionCode]), [regionList])

    const createdDateGetValue = useCallback((filters: PortFactor) => {
        return [filters.createdDateStart ?? null, filters.createdDateEnd ?? null]
    }, [])

    const createdDateMapValue = useCallback((filters: PortFactor, value: any) => {
        return { ...filters ?? {}, createdDateStart: value[0], createdDateEnd: value[1] }
    }, [])

    const updatedDateGetValue = useCallback((filters: PortFactor) => {
        return [filters.updatedDateStart ?? null, filters.updatedDateEnd ?? null]
    }, [])

    const updatedDateMapValue = useCallback((filters: PortFactor, value: any) => {
        return { ...filters ?? {}, updatedDateStart: value[0], updatedDateEnd: value[1] }
    }, [])

    const clear = useCallback((filters: PortFactor) => {
        return { page: filters.page }
    }, [])


    const filterCounter = useCallback((filters: PortFactor) => {
        return [
            filters.portCode,
            filters.portName,
            filters.regionList,
            filters.activeList,
            filters.createdBy,
            filters.updatedBy,
            filters.createdDateStart || filters.createdDateEnd,
            filters.updatedDateStart || filters.updatedDateEnd,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="portCode" label={intl.formatMessage({ id: 'field.portCode' })} />
        <StringItem field="portName" label={intl.formatMessage({ id: 'field.portName' })} />
        <EntriesItem field="regionList" label={intl.formatMessage({ id: 'field.regionCode' })} entries={regionListEntries} />
        <CodesItem field="activeList" label={intl.formatMessage({ id: 'field.activeFlag' })} code={CodeCategory.ActiveFlag} />
        <StringItem field="createdBy" label={intl.formatMessage({ id: 'field.createdBy' })} />
        <StringItem field="updatedBy" label={intl.formatMessage({ id: 'field.updatedBy' })} />
        <DateRangeItem field="createdDate" label={intl.formatMessage({ id: 'field.createdDate' })} getValue={createdDateGetValue} mapValue={createdDateMapValue} />
        <DateRangeItem field="updatedDate" label={intl.formatMessage({ id: 'field.updatedDate' })} getValue={updatedDateGetValue} mapValue={updatedDateMapValue} />
    </FilterToolbarItem>
}


const UploadAction = ({ search, filters }: { search: (filter: PortFactor) => void, filters: PortFactor }) => {
    const uploadMasterApi = useUploadMasterPortMaster()
    const uploadMaster = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            files && uploadMasterApi({ file: files[0] }, { serialized: true }).then(() => search(filters))
        })
    }, [filters, search, uploadMasterApi])
    return <UploadGroupedToolbarAction access="MARS.MLS060.UPLOAD">
        {(popupUpload, onClose) => <>
            <GroupedCallbackItem label={<FormattedMessage id="uploadPortMaster" />} callback={() => uploadMaster(popupUpload)} onClose={onClose} />
        </>}
    </UploadGroupedToolbarAction>
}

const DownloadAction = ({ selections, filters }: { selections: number[], filters: PortFactor }) => {
    const downloadMasterBySelectionApi = useDownloadPortMasterByPortId()
    const downloadMasterByFilterApi = useDownloadPortMasterByFilter()
    const download = useCallback(() => {
        if (selections.length <= 0) {
            downloadMasterByFilterApi(Objects.delete(filters, 'page'))
        } else {
            downloadMasterBySelectionApi({ portIdList: selections })
        }
    }, [downloadMasterByFilterApi, downloadMasterBySelectionApi, filters, selections])
    return <DownloadGroupedToolbarAction access="MARS.MLS060.DOWNLOAD">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="downloadPortMaster" />} callback={download} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}

const DateOnTimeTypeProvider = () => {
    return <ObjectTypeProvider name="dateOnTime" Formatter={DateOnTimeFormatter} />
}

const DateOnTimeFormatter = ({ value } : ObjectFormatterProps) => {
    return value.date ? <><FormattedDate value={value.date} dateStyle="medium" /></> : null

}