import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { PageViewEntity, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"

export type ARView = Required & Partial<Optional>

type ARViewJson = JsonRequired & Partial<JsonOptional>

export const aRViewToJson: Project<ARView, ARViewJson> = obj => ({
    ...obj,
    paymentDueDateStart: dateToJson(obj.paymentDueDateStart),
    paymentDueDateEnd: dateToJson(obj.paymentDueDateEnd),
    issuedDateStart: dateToJson(obj.issuedDateStart),
    issuedDateEnd: dateToJson(obj.issuedDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page)
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'paymentDueDateStart' | 'paymentDueDateEnd' | 'issuedDateStart' | 'issuedDateEnd'> {
    page: PageViewEntity
    paymentDueDateStart: Date,
    paymentDueDateEnd: Date,
    issuedDateStart: Date,
    issuedDateEnd: Date,

}

interface JsonRequired {
}

interface JsonOptional {
    invoiceNo: string,
    outboundNo: string,
    contractNo: string,
    paymentDueDateStart: string,
    paymentDueDateEnd: string,
    issuedDateStart: string,
    issuedDateEnd: string,
    buyers: string[],
    currencys: string[],
    statusList: number[],
    page: PageViewEntityJson
}

