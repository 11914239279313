import React, { ReactNode, useCallback, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { CallbackViewAction } from './CallbackViewAction'

interface UploadCallbackViewActionProps {
    access?: string,
    disabled?: boolean,
    title?: ReactNode,
    outlined?: boolean,
    callback: (files: FileList | null) => void,
}

export const UploadCallbackViewAction = (props: UploadCallbackViewActionProps) => {
    const { access, disabled, title, outlined, callback } = props

    const inputRef = useRef<HTMLInputElement | null>(null)
    const onPopup = useCallback(() => {
        inputRef.current && inputRef.current.click()
    }, [])
    const onChange = useCallback(() => {
        if (inputRef.current) {
            callback(inputRef.current.files)
            inputRef.current.value = ''
        }
    }, [callback])

    return <>
        <input ref={inputRef} type="file" onChange={onChange} style={{ display: 'none' }} />
        <CallbackViewAction access={access} outlined={outlined} disabled={disabled} title={title ?? <FormattedMessage id="upload" />} callback={onPopup} />
    </>
}