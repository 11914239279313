import { useEffect, useMemo } from "react"
import { useDispatch } from "react-redux"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { View } from "../../../components/View/View"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { orderCalculationListViewConditionFromJson } from "../../../services/smt/models/OrderCalculationListViewCondition"
import { useGetCompanyType } from "../../../utils/ApplicationUtils"
import sessionKeys from "../../../utils/sessionKeys"
import { ListTable } from "./ListTable"
import { ListTableCust } from "./ListTableCust"
import { occls010Actions } from "./OCCLS010Slice"

export function OCCLS010() {

  const defaultFilters = useMemo(() => {
    const filters = sessionStorage.getItem(sessionKeys.Data_OCCLS010)
    return filters ? orderCalculationListViewConditionFromJson(JSON.parse(filters)) : { }
  }, [])
    
  const dispatch = useDispatch()
  const companyType = useGetCompanyType()
  useEffect(() => {
    // load data
    dispatch(occls010Actions.setType('default'))
    dispatch(occls010Actions.setFilters(defaultFilters))
    if (CbdsType.BU === companyType) {
        dispatch(occls010Actions.getBuyerOptions())
    } else {
        dispatch(occls010Actions.getSellerOptions())
    }
    dispatch(occls010Actions.listView())
    // destroy state after unmount
    return () => {
      dispatch(occls010Actions.unmount())
    }
  }, [defaultFilters, companyType, dispatch])

  return <View flex>
    <SectionCard>
      <SectionCardContent>
        {CbdsType.BU === companyType ? <ListTable /> : <ListTableCust /> }
      </SectionCardContent>
    </SectionCard>
  </View>
}