import React, { useCallback, useMemo, useState } from "react"
import { useGetPaymentTermsList } from "../../../services/master/apis/paymentTermsApi"
import { PaymentTermsFactor, paymentTermsFactorFromJson, paymentTermsFactorToJson } from "../../../services/master/models/PaymentTermsFactor"
import { PaymentTermsResult } from "../../../services/master/models/PaymentTermsResult"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { MLS110PcUi } from "./MLS110PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }
const searchCachekey = sessionKeys.Filters_MLS110
export const MLS110 = () => {
    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? paymentTermsFactorFromJson(JSON.parse(filters)) : null
    }, [])

    const [filters, setFilters] = useState<PaymentTermsFactor>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<PaymentTermsResult[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)

    const getDataList = useGetPaymentTermsList()
    const search = useCallback((filters: PaymentTermsFactor) => {
        getDataList(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(paymentTermsFactorToJson(filters)))
        })
    }, [getDataList, setData])

    useSearchOnLoad(search, filters)

    return <MLS110PcUi
        filters={filters}
        search={search}
        data={data}
        totalCount={totalCount}
        setFilters={setFilters}
    />
}