import { Project } from "../../../utils/Project"

export type UploadedDataList = Required & Partial<Optional>
export type UploadedDataListJson = JsonRequired & Partial<JsonOptional>
export const uploadedDataListToJson: Project<UploadedDataList, UploadedDataListJson> = obj => ({
    ...obj,
})
export const uploadedDataListFromJson: Project<UploadedDataListJson, UploadedDataList> = json => ({
    ...json,
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
}

interface JsonRequired {
}

interface JsonOptional {
    fileId: string,
    batchId: string,
    fileName: string,
    fileIdentifier: string,
    createdDate: number,
    updatedDate: number
    templateId: string,
    createdBy: string
}