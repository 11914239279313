import { Project } from "../../../utils/Project"

export type OrderCalculationTransfer = Required & Partial<Optional>

type OrderCalculationTransferJson = JsonRequired & Partial<JsonOptional>

export const orderCalculationTransferFromJson: Project<OrderCalculationTransferJson, OrderCalculationTransfer> = json => ({
    ...json,
})

interface Required extends JsonRequired { }

interface Optional extends JsonOptional {

}

interface JsonRequired {
    orderGroupId: number,
}

interface JsonOptional {
    orderGroupNo: string,
    orderFrequency: number,
    orderTiming: string,
    remark: string,
    createdBy: string,
    createdDate: string,
    updatedBy: string,
    updatedDate: string,
    version: number,
    targetLeadtime: number,
    // calendarId: number,
    activeFlag: number,
    customerCode: string,
    buyerUid: string,
    buyerCode: string,
    sellerCode: string,
    sellerUid: string,
    // calendarCode: string,
    bufferUsageType: number,
    forecastNum: number,
    ownerUid: string,
}