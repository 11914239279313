import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useGetOrderGroupDetailHeaderById, useGetOrderGroupDetailPartsById } from "../../../services/master/apis/orderGroupApi"
import { ContractRouteResult } from "../../../services/master/models/ContractRouteResult"
import { OrderGroupDetailPartsResult } from "../../../services/master/models/OrderGroupDetailPartsResult"
import { OrderGroupResult } from "../../../services/master/models/OrderGroupResult"
import { MLS141PcUi } from "./MLS141PcUi"

export const MLS141 = () => {
    const { orderGroupId } = useParams() as any
    const [headerInfo, setHeadeInfo] = useState<OrderGroupResult>({} as OrderGroupResult)
    const [partsList, setPartsList] = useState<OrderGroupDetailPartsResult[]>([])
    const getHeaderInfo = useGetOrderGroupDetailHeaderById()
    useEffect(() => {
        getHeaderInfo({ orderGroupId: orderGroupId }, { silent: true }).then(result => {
            setHeadeInfo(result || {} as ContractRouteResult)
        })
    }, [orderGroupId, getHeaderInfo, setHeadeInfo])

    const getPartsDataList = useGetOrderGroupDetailPartsById()
    useEffect(() => {
        getPartsDataList({ orderGroupId: orderGroupId }, { silent: true }).then(result => {
            setPartsList(result || [])
        })
    }, [orderGroupId, getPartsDataList, setPartsList])

    return <MLS141PcUi
        headerInfo={headerInfo}
        partsList={partsList}
    />
}