import React from 'react'
import { FormattedMessage } from 'react-intl'
import { CallbackViewAction } from './CallbackViewAction'

interface EditCallbackViewActionProps {
    access?: string,
    disabled?: boolean,
    outlined?: boolean,
    callback: () => void,
}

export const EditCallbackViewAction = (props: EditCallbackViewActionProps) => {
    return <CallbackViewAction {...props} title={<FormattedMessage id="edit" />} />
}