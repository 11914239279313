import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { dateToJson } from "../../utils/serializer"
import { OrderType } from "../enums/OrderType"


export type SpotOrderResult = ResultRequired & Partial<ResultOptional>
export type SpotOrderResultson = ResultJsonRequired & Partial<ResultJsonOptional>

export const spotOrderResultDeserializer: Project<SpotOrderResultson, SpotOrderResult> = json => ({
    ...json,
    // createdDate: dateFromJson(json.createdDate),
    // updatedDate: dateFromJson(json.updatedDate),
    totPlaceSpotDetailEx: json.totPlaceSpotDetailEx ? json.totPlaceSpotDetailEx.map(partsDetailDeserializer) : [],
})

export const spotOrderResultSerializer: Project<SpotOrderResult, SpotOrderResultson> = data => ({
    ...data,
    // createdDate: dateToJson(data.createdDate),
    // updatedDate: dateToJson(data.updatedDate),
    totPlaceSpotDetailEx: data.totPlaceSpotDetailEx ? data.totPlaceSpotDetailEx.map(partsDetailSerializer) : [],
})

export interface PartsDetail extends Omit<PartsDetailJson, 'totPlaceSpotRequest'> {
    // forecastList: ForecastDetail[],
    totPlaceSpotRequest: InboundPalnDetail[],
}

interface ResultRequired extends Omit<ResultJsonRequired, 'totPlaceSpotDetailEx'> {
    // createdDate: Date,
    // updatedDate: Date,
    totPlaceSpotDetailEx: PartsDetail[],
}

interface ResultOptional extends Omit<ResultJsonOptional, 'orderType' | 'status'> {
    orderType: OrderType,
    status: number,
}

interface ResultJsonRequired {
    totPlaceSpotDetailEx: PartsDetailJson[] | null,
    status: number,
    stepNo: number,

}

interface ResultJsonOptional {
    placeType: number | null | undefined,
    buId: number,
    orderDate: string,
    createdBy: string
    // createdDate: string
    currency: string
    customerId: string,
    orderReference: string,
    orderType: number,
    paymentTermsId: number,

    preSoId: number,
    receiveDcId: number,
    rejectRemark: string,
    remark: string,
    shippingMode: number,
    spotId: number,

    submittedBy: string,
    submittedDate: string,
    updatedBy: string,
    // updatedDate: string,
    version: number,
    spotLevel: number,
    buyerHaveDcFlag: number,
    // totPlaceSpotDetailEx:PartsDetailJson[],
}

interface PartsDetailJson {
    buyerPartsId: number,
    buyerPartsNo: string,
    buyingCurrency: string,
    currencyDigits: number,
    buyingPrice: number,
    colorCode: string,
    createdBy: string,
    // createdDate: string,
    customerId: number,
    globalPartsId: number,
    globalPartsNo: string,
    orderLot: number,
    orderQty: number,
    rejectRemark: string,
    rowNo: number,
    sellerPartsId: number,
    sellerPartsNo: string,
    sellerUid: string,
    soDetailOrigin: string,
    soNo: string,
    spotDetailId: number,
    spotId: number,
    spq: number,
    status: number,
    uomCode: string,
    updatedBy: string,
    // updatedDate: string,
    version: number,
    // forecastList: ForecastDetailJson[] | null,
    totPlaceSpotRequest: InboundPalnDetailJson[] | null,
    sellerCode: string,
    globalPartsName: string,
}

export const partsDetailDeserializer: Project<PartsDetailJson, PartsDetail> = json => ({
    ...json,
    // forecastList: json.forecastList ? json.forecastList.map(forecastDetailDeserializer) : [],
    totPlaceSpotRequest: json.totPlaceSpotRequest ? json.totPlaceSpotRequest.map(inboundPalnDetailDeserializer) : [],
})

export const partsDetailSerializer: Project<PartsDetail, PartsDetailJson> = data => ({
    ...data,
    // forecastList: data.forecastList ? data.forecastList.map(forecastDetailSerializer) : [],
    totPlaceSpotRequest: data.totPlaceSpotRequest ? data.totPlaceSpotRequest.map(inboundPalnDetailSerializer) : [],
})




//forelist

export interface ForecastDetail extends Omit<ForecastDetailJson, 'fcFirstDate' | 'fcLastDate' | 'targetFirstDate' | 'targetLastDate'> {
    fcFirstDate: Date,
    fcLastDate: Date,
    targetFirstDate: Date,
    targetLastDate: Date,
}

interface ForecastDetailJson {
    coDetailOrigin: string | null,
    soDetailOrigin: string | null,
    fcFirstDate: string,
    fcLastDate: string,
    targetFirstDate: string,
    targetLastDate: string,
    forecastQty: number,
    lastForecastQty: number,
    fluctuationReason: string
}

//inboundlist
export interface InboundPalnDetail extends Omit<InboundPalnDetailJson, 'crdDate'> {
    crdDate: Date,
}

interface InboundPalnDetailJson {
    spotReqId?: number,
    spotDetailId: number,
    crdDate: string,
    crdQty: number | null
}

const inboundPalnDetailDeserializer: Project<InboundPalnDetailJson, InboundPalnDetail> = json => ({
    ...json,
    crdDate: dateFromJson(json.crdDate)
})
const inboundPalnDetailSerializer: Project<InboundPalnDetail, InboundPalnDetailJson> = data => ({
    ...data,
    crdDate: dateToJson(data.crdDate)
})