import { Action, Data, DataGrid, DataTypePreset, Filtering, Row, RowActionProvider, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { DataGridDetailFormatterProps } from "@rithe/data-grid/dist/components/detail/DataGridDetailFormatter"
import { useIntl } from "react-intl"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { useGetCodeName } from "../../../utils/CodeCategoryUtil"
import { DetailPlaceOrderRowAction } from "./DetailPlaceOrderRowAction"
import { DetailViewRowAction } from "./DetailViewRowAction"
import { DetailWithdrawRowAction } from "./DetailWithdrawRowAction"
import { ListTableRow } from "./ListTable"
import { MonthTypeProvider } from "./MonthTypeProvider"
import { useOCCLS010Selector } from "./OCCLS010Slice"
import { StatusTypeProvider } from "./StatusTypeProvider"

export interface DetailTableRow {
  /* Metadata */
  orderCalcId: string,
  orderCalcNo: string,
  poGroupId: string,
  orderVersion: number,
  customerCode?: string,
  buyerCode?: string,
  inCurrentMonth: boolean,
  /* Data */
  targetMonth?: Date,
  status?: number,
  remark?: string,
  updatedBy?: string,
  updatedDate?: Date,
}

export function DetailTable(props: DataGridDetailFormatterProps) {
  const type = useOCCLS010Selector(state => state.type)
  const listRow = props.row as ListTableRow

  const rows = useOCCLS010Selector(state => {
    return state.results.filter(result => {
      const orderGroup = result.orderGroup
      const orderCalcs = result.orderCalcs ?? []
      return orderGroup.orderGroupId === listRow.orderGroupId
        && orderCalcs[0]?.orderCalcNo === listRow.orderCalcNo
    }).flatMap(result => {
      const orderGroup = result.orderGroup
      const orderCalcs = result.orderCalcs ?? []
      return orderCalcs.map(orderCalc => ({
        /* Metadata */
        orderCalcId: orderCalc.orderCalcId,
        orderCalcNo: orderCalc.orderCalcNo,
        poGroupId: orderCalc.poGroupId,
        orderVersion: orderCalc.orderVersion,
        customerCode: state.buyerOptions[orderGroup.customerId + '-1'],
        buyerCode: state.buyerOptions[((orderGroup.customerId + '-1' || (orderGroup.buyerBuId + '-4')) ?? '') as any],
        inCurrentMonth: listRow.inCurrentMonth,
        /* Data */
        targetMonth: orderCalc.targetStartDate,
        status: orderCalc.status,
        remark: orderCalc.remark,
        updatedBy: orderCalc.updatedBy,
        updatedDate: orderCalc.updatedDate,
      }))
    })
  })


  const { getCodeName } = useGetCodeName()

  const intl = useIntl()
  const columns = [
    { field: 'targetMonth', dataTypeName: 'month', title: intl.formatMessage({ id: 'targetMonth' }), width: 300 },
    { field: 'status', dataTypeName: 'status', title: intl.formatMessage({ id: 'field.status' }), width: 200, getCellValue: (row: Row) => getCodeName(CodeCategory.OrderCalcStatus, row.status ?? undefined) },
    { field: 'remark', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.remark' }), width: 300 },
    { field: 'updatedBy', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.updatedBy' }), width: 200 },
    { field: 'updatedDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.updatedDate' }), width: 200 },
  ]

  return <div style={{ paddingLeft: 100, paddingTop: 15, paddingBottom: 15 }}>
    <DataGrid>
      <ToolbarLayout />
      <TableLayout>
        <TableHeaderLayout sticky />
        <TableBodyLayout />
      </TableLayout>
      <DataTypePreset />
      <MonthTypeProvider name="month" />
      <StatusTypeProvider name="status" />
      <Data rows={rows} columns={columns} getRowId={(row: Row) => row.orderCalcId} />
      <RowActionProvider name="view" Action={DetailViewRowAction} actionProps={{ type }} />
      <RowActionProvider name="placeOrder" Action={DetailPlaceOrderRowAction} actionProps={{ type }} />
      <RowActionProvider name="withdraw" Action={DetailWithdrawRowAction} actionProps={{ type }} />
      <Action width={178} />
      <Filtering />
    </DataGrid>
  </div>
}