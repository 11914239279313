import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { ShippingMode } from "../../master/enums/ShippingMode"
import { dateFromJson } from "../../utils/deserializer"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"
import { DelayStatus } from "../enums/DelayStatus"
import { OrderFrequency } from "../enums/OrderFrequency"
import { OrderType } from "../enums/OrderType"
import { PoStatus } from "../enums/PoStatus"

export type PurchaseOrderDownloadListFactor = Omit<PurchaseOrderListFactor, 'page'>
export type PurchaseOrderDownloadListFactorJson = Omit<PurchaseOrderListFactorJson, 'page'>
export const purchaseOrderDownloadListFactorToJson: Project<PurchaseOrderListFactor, PurchaseOrderListFactorJson> = obj => ({
    ...obj,
    orderDateStart: dateToJson(obj.orderDateStart),
    orderDateEnd: dateToJson(obj.orderDateEnd),
})


export type PurchaseOrderListFactor = Partial<Optional>
export type PurchaseOrderListFactorJson = Partial<JsonOptional>
export const purchaseOrderListFactorToJson: Project<PurchaseOrderListFactor, PurchaseOrderListFactorJson> = obj => ({
    ...obj,
    orderDateStart: dateToJson(obj.orderDateStart),
    orderDateEnd: dateToJson(obj.orderDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
})

export const purchaseOrderListFactorFromJson: Project<PurchaseOrderListFactorJson, PurchaseOrderListFactor> = json => ({
    ...json,
    orderDateStart: dateFromJson(json.orderDateStart),
    orderDateEnd: dateFromJson(json.orderDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page),
})

interface Optional extends Omit<JsonOptional, 'orderDateStart' | 'orderDateEnd' | 'page'> {
    orderTypeList: OrderType[],
    orderFrequencyList: OrderFrequency[],
    shippingModeList: ShippingMode[],
    orderDateStart: Date,
    orderDateEnd: Date,
    statusList: PoStatus[],
    delayStatusList: DelayStatus[],
    page: PageViewEntity
}


interface JsonOptional {
    orderNo: string,
    contractNo: string,
    contractRouteCode: string,
    orderTypeList: number[],
    orderFrequencyList: number[],
    shippingModeList: number[],
    orderDateStart: string,
    orderDateEnd: string,
    statusList: number[],
    delayStatusList: number[],
    page: PageViewEntityJson
}
