import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { ShippingMode } from "../../master/enums/ShippingMode"
import { dateFromJson } from "../../utils/deserializer"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"
import { InboundDataType } from "../enums/InboundDataType"
import { InboundPackageStatus } from "../enums/InboundPackageStatus"
import { InboundType } from "../enums/InboundType"

export type InboundMonitorView = Required & Partial<Optional>

type InboundMonitorViewJson = JsonRequired & Partial<JsonOptional>

export const inboundMonitorViewToJson: Project<InboundMonitorView, InboundMonitorViewJson> = obj => ({
    ...obj,
    outboundDateStart: dateToJson(obj.outboundDateStart),
    outboundDateEnd: dateToJson(obj.outboundDateEnd),
    etdStart: dateToJson(obj.etdStart),
    etdEnd: dateToJson(obj.etdEnd),
    etaStart: dateToJson(obj.etaStart),
    etaEnd: dateToJson(obj.etaEnd),
    latestPlanInboundDateStart: dateToJson(obj.latestPlanInboundDateStart),
    latestPlanInboundDateEnd: dateToJson(obj.latestPlanInboundDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page)
})

export const inboundMonitorViewFromJson: Project<InboundMonitorViewJson, InboundMonitorView> = json => ({
    ...json,
    outboundDateStart: dateFromJson(json.outboundDateStart),
    outboundDateEnd: dateFromJson(json.outboundDateEnd),
    etdStart: dateFromJson(json.etdStart),
    etdEnd: dateFromJson(json.etdEnd),
    etaStart: dateFromJson(json.etaStart),
    etaEnd: dateFromJson(json.etaEnd),
    latestPlanInboundDateStart: dateFromJson(json.latestPlanInboundDateStart),
    latestPlanInboundDateEnd: dateFromJson(json.latestPlanInboundDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page)
})


interface Required extends JsonRequired { }

interface Optional extends Omit<JsonOptional, 'outboundDateStart' | 'outboundDateEnd' | 'etdStart' | 'etdEnd' | 'etaStart' | 'etaEnd' | 'latestPlanInboundDateStart' | 'latestPlanInboundDateEnd'> {
    page: PageViewEntity,
    outboundDateStart: Date,
    outboundDateEnd: Date,
    etdStart: Date,
    etdEnd: Date,
    etaStart: Date,
    etaEnd: Date,
    latestPlanInboundDateStart: Date,
    latestPlanInboundDateEnd: Date,
    inboundTypeList: InboundType[],
    shippingModeList: ShippingMode[],
    statusList: InboundPackageStatus[],
    dataTypeList: InboundDataType[],
}

interface JsonRequired {
}

interface JsonOptional {
    latestPlanInboundDateStart: string,
    latestPlanInboundDateEnd: string,
    planInboundNo: string,
    inboundTypeList: number[],
    shipper: string,
    shippingModeList: number[],
    port: string,
    etdStart: string,
    etdEnd: string,
    etaStart: string,
    etaEnd: string,
    outboundNo: string,
    outboundDateStart: string,
    outboundDateEnd: string,
    statusList: number[],
    inbNoList: string[],
    dataTypeList: number[],
    page: PageViewEntityJson,
}