import { Project } from "../../../../utils/Project"
import { DailyRundownContainerTrackingResult } from "./DailyRundownContainerTrackingResult"

export type DailyRundownResult = Required & Partial<JsonOptional>
type DailyRundownResultJson = JsonRequired & Partial<JsonOptional>

export const dailyRundownResultFromJson: Project<DailyRundownResultJson, DailyRundownResult> = json => ({
    ...json
})

interface Required extends JsonRequired { }


interface JsonRequired {
    customerPartsId: number,
}

interface JsonOptional {

    decimalDigits: number,
    uomCode: string,
    partsNo: string,
    customerCode: string,
    sellerCode: string,
    supplierCode: string,
    impStockQty: number,
    impTotalStockQty: number,
    custStockFlag: number,
    custStockQty: number,
    safetyDays: number,
    contractNumber: number,
    dateList: string[],
    dateTimeList: string[],
    dayWeekList: string[],

    inventoryBoxFlag: number,
    minDays1: number,
    minDays2: number,
    minDays3: number,
    maxDays: number,
    minBoxes1: number,
    minBoxes2: number,
    minBoxes3: number,
    maxBoxes: number,
    stockDaysBoxes: string,

    calendarMap: {},
    dateTimeMap: {},
    drPlanDispMap: {},
    drActualDispMap: {},
    planEtdDispMap: {},
    planEtaDispMap: {},
    incomingDispMap: {},
    planCustUsageDispMap: {},

    actualCustUsageDispMap: {},
    impStockQtyDispMap: Record<string, string | null>,
    stockDayOrBoxDispMap: Record<string, string | null>,
    stockTitleName: string,
    externalRefNo: string,
    custStockDate: number,
    containerTrackMap: Map<string, DailyRundownContainerTrackingResult[]>
}


export type Daily = dailyRequired & Partial<dailyOptional>
type DailyJson = dailyJsonRequired & Partial<dailyJsonOptional>

export const dailyToJson: Project<Daily, DailyJson> = obj => ({
    ...obj,
})

export const dailyFromJson: Project<DailyJson, Daily> = json => ({
    ...json,
})

interface dailyRequired extends dailyJsonRequired {

}
interface dailyOptional extends dailyJsonOptional {

}

interface dailyJsonRequired {
    customerPartsId: number,
}
interface dailyJsonOptional {
    partsNo: string,
    simulateByParts: boolean,
    includePlanFlag: boolean,
    baseCarrierDateFlag: boolean,
}