import { Typography } from "@material-ui/core"
import { KeyFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/KeyFormatter"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { SmtActiveFlag } from "../../../services/master/enums/SmtActiveFlag"
import { CodeCategoryTypeProvider } from "./CodeCategoryTypeProvider"

export const SmtActiveFlagTypeProvider = () => {
    return <CodeCategoryTypeProvider codeCategory={CodeCategory.SmtActiveFlag} Formatter={Formatter} />
}

const Formatter = (props: KeyFormatterProps) => {
    const { value, formatter } = props
    const active = props.value === SmtActiveFlag.ACTIVE

    return <Typography variant="body2" style={{ color: active ? 'green' : 'red' }}>
        {value === null ? '' : formatter.format(value)}
    </Typography>
}