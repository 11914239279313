import { useCallback, useEffect, useState } from "react"
import { useLocation } from "react-router"
import { useGetExternalDataForView, useGetExternalTemplateList, useGetFunctionList } from "../../../services/integration/apis/externalDataApis"
import { ExternalDataFactor } from "../../../services/integration/models/ExternalDataFactor"
import { RequestDetailHead, RequestList } from "../../../services/integration/models/ExternalDataResult"
import { ExternalTemplateList } from "../../../services/integration/models/ExternalTemplateList"
import { FunctionList } from "../../../services/integration/models/FunctionList"
import { INT010PcUi } from "./INT010PcUi"


export const INT010 = () => {
    const { functionCode, templateId } = useLocation().state as any ?? {}
    const [functionList, setFunctionList] = useState<FunctionList[]>([])
    const [templateList, setTemplateList] = useState<ExternalTemplateList[]>([])
    const [filters, setFilters] = useState<ExternalDataFactor>({ function: functionCode ?? undefined, externalTemplateId: templateId ?? undefined })
    const [headerInfo, setHeaderInfo] = useState<RequestDetailHead>({} as RequestDetailHead)
    const [list, setList] = useState<RequestList[]>([])

    const getFunctionList = useGetFunctionList()
    const getTemplateList = useGetExternalTemplateList()
    const findTemplateList = useCallback((filters: ExternalDataFactor) => {
        if (filters.function !== undefined && filters.function !== null) {
            getTemplateList({ function: filters.function }, { silent: true }).then(result => {
                setTemplateList(result ?? [])
            })
        } else {
            setTemplateList([])
        }
    }, [getTemplateList])
    useEffect(() => {
        getFunctionList(undefined, { silent: true }).then(result => {
            setFunctionList(result ?? [])
        })
        findTemplateList(filters)
    }, [filters, findTemplateList, getFunctionList])

    const getDataForView = useGetExternalDataForView()
    useEffect(() => {
        if (filters.externalTemplateId) {
            getDataForView({ templateId: filters.externalTemplateId }, { silent: true, serialized: true }).then(result => {
                setHeaderInfo(result?.header ?? {} as RequestDetailHead)
                setList(result?.list ?? [])
            })
        }
    }, [filters, filters.externalTemplateId, filters.function, getDataForView])

    return <INT010PcUi
        functionList={functionList}
        templateList={templateList}
        findTemplateList={findTemplateList}
        filters={filters}
        setFilters={setFilters}
        headerInfo={headerInfo}
        list={list}
    />
}