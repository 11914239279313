import { useCallback, useState } from "react"
import { ExternalMPDStockTransfer } from "../../../services/smt/models/ExternalMPDStockTransfer"
import { ExternalMPDStockView } from "../../../services/smt/models/ExternalMPDStockView"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { INT070PcUi } from "./INT070PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }


export const INT070 = () => {
    const [filters, setFilters] = useState<ExternalMPDStockView>(defaultFilters)
    const [totalCount, setTotalCount] = useState<number>(0)
    const [data, setData] = useState<ExternalMPDStockTransfer[]>([])

    // const getData = useGetInventoryPage()
    const search = useCallback((filters: ExternalMPDStockView) => {
        // getData({ ...filters }, { silent: true, serialized: true }).then(result => {
        //     setTotalCount(result?.page?.totalCount || 0)
        //     setData(result?.data || [])
        // })
        setData([])
    }, [])

    useSearchOnLoad(search, filters)

    return <INT070PcUi
        filters={filters}
        setFilters={setFilters}
        search={search}
        data={data}
        setData={setData}
        totalCount={totalCount}
    />
}