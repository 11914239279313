import { Project } from "@rithe/utils";



export type ProjectFieldsFactor = Required & Partial<Optional>;

type ProjectFieldsFactorJson = JsonRequired & Partial<JsonOptional>;

export const projectFieldsFactorToJson: Project<ProjectFieldsFactor, ProjectFieldsFactorJson> = obj => ({
    ...obj,
    otherFieldNames: obj.otherFieldNames?.map(otherFieldToJson)
})
interface Required extends JsonRequired {

}

interface Optional extends JsonOptional {
}

interface JsonRequired {

}

interface JsonOptional {
    projectId: number,
    projectName: string,
    projectCode: string,
    customerId: number,
    customerCode: string,
    remarks: string,
    mainFieldId: number,
    mainFieldName: string,
    noOfLevel: number,
    otherFieldNames: OtherField[],
    isConfirm: number,
}

export interface OtherField {
    fieldId?: number,
    mainFlag?: number,
    fieldName: string,
    orderNo: number,
    //isDel: boolean,
}

interface OtherFieldJson {
    fieldId?: number,
    mainFlag?: number,
    fieldName: string,
    orderNo: number,
    //isDel: boolean,
}

export const otherFieldToJson: Project<OtherField, OtherFieldJson> = obj => ({
    ...obj,
})