import { IconButton, makeStyles, Typography } from "@material-ui/core";
import { Clear, ExpandLess, ExpandMore } from "@material-ui/icons";
import { condition } from "@rithe/utils";
import React, { ReactNode } from "react";
import { useCollapseContext } from "./CollapseContext";

interface BlackSimpleCardHeaderProps {
    title: string,
    actions?: ReactNode[],
    clear?: () => void
}

export function BlackSimpleCardHeader(props: BlackSimpleCardHeaderProps) {
    const { title, clear, actions } = props
    const { enabled, collapsed, toggle } = useCollapseContext()
    const styles = useStyles()
    return <div className={styles.root}>
        <div className={styles.content}>
            {clear && <IconButton style={{ color: 'white' }} onClick={clear} ><Clear /></IconButton>}
            <Typography variant="h6" color="textPrimary" className={styles.title}>
                {title}
            </Typography>
        </div>
        {/* <div>
            {actions}
        </div> */}
        <div className={styles.actions}>
            {React.Children.map(actions, (action, index) => {
                return <div key={index} style={{ marginLeft: 12 }}>{action}</div>
            })}
            {condition()
                .case(enabled)
                .then(<IconButton onClick={toggle} style={{ marginLeft: 12 }}>
                    {collapsed ? <ExpandMore style={{ color: 'white' }} /> : <ExpandLess style={{ color: 'white' }} />}
                </IconButton>)
                .default(null)}
        </div>
    </div>
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: theme.spacing(1, 2),
        flex: '0 0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'stretch',
        backgroundColor: '#003361'
    },
    content: {
        flex: '1 1 auto',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center'
    },
    title: {
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        color: 'white',
    },
    actions: {
        flex: '0 0 auto',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center'
    },
}))