import { ColumnFreeze, ColumnOrdering, ColumnResizing, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { Records } from "@rithe/utils"
import { useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { ActiveFlagTypeProvider } from "../../../components/DataGrid/typeProviders/ActiveFlagTypeProvider"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { View } from "../../../components/View/View"
import { IntBatchTransfer } from "../../../services/integration/models/IntBatchTransfer"
import { IntBatchView } from "../../../services/integration/models/IntBatchView"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"

interface INT030PcUiProps {
    filters: IntBatchView,
    setFilters: React.Dispatch<React.SetStateAction<IntBatchView>>,
    data: IntBatchTransfer[],
    search: (filters: IntBatchView) => void,
}

export const INT030PcUi = (props: INT030PcUiProps) => {
    const intl = useIntl()

    return <View flex>
        <SectionCard>
            <SectionCardHeader
                title={intl.formatMessage({ id: 'batchRunList' })}
                subtitle={intl.formatMessage({ id: 'Auto Batch excute SEQ : COMBINE BATCH -> LCBM DAILY BATCH -> ETL BATCH -> SMT DAILY BATCH' })} />
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({ data }: INT030PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])

    const columns = useMemo(() => [
        // { field: 'batchId', dataTypeName: 'number', title: intl.formatMessage({ id: 'batchId' }), width: 200 },
        { field: 'dataSource', dataTypeName: CodeCategory.DataSource, title: intl.formatMessage({ id: 'dataSource' }), width: 200 },
        { field: 'generateTime', dataTypeName: 'datetime', title: intl.formatMessage({ id: 'generateTime' }), width: 200 },
        { field: 'launchTime', dataTypeName: 'datetime', title: intl.formatMessage({ id: 'launchTime' }), width: 200 },
        // { field: 'backupTime', dataTypeName: 'datetime', title: intl.formatMessage({ id: 'backupTime' }), width: 200 },
        { field: 'verifyTime', dataTypeName: 'datetime', title: intl.formatMessage({ id: 'verifyTime' }), width: 200 },
        { field: 'storeTime', dataTypeName: 'datetime', title: intl.formatMessage({ id: 'storeTime' }), width: 200 },
        { field: 'runTime', dataTypeName: 'datetime', title: intl.formatMessage({ id: 'runTime' }), width: 200 },
        { field: 'createdDate', dataTypeName: 'datetime', title: intl.formatMessage({ id: 'createdDate' }), width: 200 },
        { field: 'updatedDate', dataTypeName: 'datetime', title: intl.formatMessage({ id: 'updatedDate' }), width: 200 },
    ], [intl])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.DataSource} />
        <ActiveFlagTypeProvider />
        <Data rows={data} columns={columns} />
        <ColumnFreeze />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
    </DataGrid>
}