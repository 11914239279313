import { Divider, makeStyles, Typography } from "@material-ui/core";
import { condition } from "@rithe/utils";
import React, { ReactNode } from "react";

interface SectionTitleProps {
    size?: 'large' | 'middle' | 'small',
    disableTypography?: boolean,
    children?: ReactNode | ReactNode[],
}

export function SectionTitle(props: SectionTitleProps) {
    const { size, disableTypography, children } = props

    const styles = useStyles()
    return <div className={styles.root}>
        {disableTypography
            ? <div className={styles.title}>{children}</div>
            : <Typography variant={condition().case(size === 'large').then('h6').case(size === 'small').then('body2').default('body1')} color="primary" className={styles.title}>{children}</Typography>
        }
        <Divider className={styles.divider} />
    </div>
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'stretch',
        padding: theme.spacing(1, 0),
    },
    title: {
        flex: '0 0 auto',
        marginRight: theme.spacing(4),
        textTransform: 'uppercase',
    },
    divider: {
        flex: '1 1 auto',
    },
}))