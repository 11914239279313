import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { TotCo, totCoFromJson, TotCoJson } from "./TotCo"

export type PlaceOrderListResult = PlaceOrderResultRequired & Partial<PlaceOrderResultOptional>
export type PlaceOrderListResultJson = PlaceOrderResultJsonRequired & Partial<PlaceOrderResultJsonOptional>

export const placeOrderListResultFromJson: Project<PlaceOrderListResultJson, PlaceOrderListResult> = json => ({
    ...json,
    orderFirstDate: dateFromJson(json.orderFirstDate),
    orderLastDate: dateFromJson(json.orderLastDate),
    targetFirstDate: dateFromJson(json.targetFirstDate),
    targetLastDate: dateFromJson(json.targetLastDate),
    orderDueDate: dateFromJson(json.orderDueDate),
    coList: json.coList?.map(totCoFromJson)
})

interface PlaceOrderResultRequired extends PlaceOrderResultJsonRequired {

}

interface PlaceOrderResultOptional extends Omit<PlaceOrderResultJsonOptional, 'orderFirstDate' | 'orderLastDate' | 'targetFirstDate' | 'targetLastDate' | 'orderDueDate' | 'coList'> {
    orderFirstDate: Date,
    orderLastDate: Date,
    targetFirstDate: Date,
    targetLastDate: Date,
    orderDueDate: Date,
    coList: TotCo[]
}

interface PlaceOrderResultJsonRequired {
    customerId: number,
    customerCode: string,
    sellerBuId: number,
    sellerCode: string,
    supplierCode: string,
    customerContractId: number,
    customerContractNo: string,
    contractRouteId: number,
    contractRouteCode: string,
    orderFrequency: number,
    businessType: number,
}

interface PlaceOrderResultJsonOptional {
    contractDescription: string,
    routeDescription: string,
    orderType: number,
    forecastNum: number,
    currency: string,
    targetLeadtime: number,
    paymentTermsCode: string,
    receiverDcCode: string,
    orderFirstDate: string,
    orderLastDate: string,
    targetFirstDate: string,
    targetLastDate: string,
    orderDueDate: string,
    coList: TotCoJson[]
}

