import {Project} from "../../../utils/Project"
import {OrderFrequency} from "../../order/enums/OrderFrequency"
import {TableEntity, tableEntityFromJson, TableEntityJson} from "../../utils/TableEntity"
import {BusinessType} from "../enums/BusinessType"
import {GroupStatus} from "../enums/GroupStatus"

export type OrderGroupResult = Required & Partial<Optional> & TableEntity
type OrderGroupResultJson = JsonRequired & Partial<JsonOptional> & TableEntityJson

export const orderGroupResultFromJson: Project<OrderGroupResultJson, OrderGroupResult> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
    businessType: BusinessType,
    orderFrequency: OrderFrequency,
    status: GroupStatus
}

interface JsonRequired {

}

interface JsonOptional {
    groupId: number,
    grouping: string
    businessType: number,
    orderFrequency: number,
    description: string,
    remark: string
    forecastNum: number,
    targetLeadtime: number,
    status: number
}