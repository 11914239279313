import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { EntriesItem, StringItem } from "@rithe/form"
import { Objects, Records } from "@rithe/utils"
import React, { SetStateAction, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { EditCallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/EditCallbackToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { ActiveFlagTypeProvider } from "../../../components/DataGrid/typeProviders/ActiveFlagTypeProvider"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { useDownloadGlobalPartsMasterByFilter, useDownloadGlobalPartsMasterByGlobalPartsId, useDownloadGlobalPartsPDFByFilter, useDownloadGlobalPartsPDFByGlobalPartsId } from "../../../services/master/apis/masterDownloadApi"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { GlobalPartsFactor } from "../../../services/master/models/GlobalPartsFactor"
import { GlobalPartsResult, globalPartsResultToJson } from "../../../services/master/models/GlobalPartsResult"
import { TnmUom } from "../../../services/master/models/TnmUom"
import sessionKeys from "../../../utils/sessionKeys"
import { useSetDataIntoSessionAndGotoUrl } from "../../../utils/sessionUtil"

interface MLS070PcUiProps {
    filters: GlobalPartsFactor,
    search: (filters: GlobalPartsFactor) => void,
    data: GlobalPartsResult[],
    totalCount: number,
    uomList: TnmUom[],
    setFilters: React.Dispatch<SetStateAction<GlobalPartsFactor>>,
}

export const MLS070PcUi = (props: MLS070PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({ filters, setFilters, data, uomList, search }: MLS070PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])

    const columns = useMemo(() => [
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
        { field: 'oldPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.oldPartsNo' }), width: 200 },
        { field: 'displayPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.displayPartsNo' }), width: 200 },
        { field: 'colorCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.colorCode' }), width: 150 },
        { field: 'uomCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.uomCode' }), width: 180 },
        { field: 'rolledPartsFlag', dataTypeName: CodeCategory.RolledPartsFlag, title: intl.formatMessage({ id: 'field.rolledPartsFlag' }), width: 200 },
        { field: 'rolledPartsUom', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.rolledPartsUom' }), width: 220 },
        { field: 'netWeight', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.netWeight' }), width: 150 },
        { field: 'remark1', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.remark1' }), width: 300 },
        { field: 'remark2', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.remark2' }), width: 300 },
        { field: 'remark3', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.remark3' }), width: 300 },
        { field: 'remark4', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.remark4' }), width: 300 },
        { field: 'remark5', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.remark5' }), width: 300 },
        { field: 'partsType', dataTypeName: CodeCategory.PartsType, title: intl.formatMessage({ id: 'field.partsType' }), width: 200 },
        { field: 'activeFlag', dataTypeName: CodeCategory.ActiveFlag, title: intl.formatMessage({ id: 'field.activeFlag' }), width: 150 },
        { field: 'createdDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.createdDate' }), width: 180 },
        { field: 'updatedDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.updatedDate' }), width: 180 },
    ], [intl])
    const getRowId = useCallback((row: any) => row.partsId, [])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, uomList, search }), [filters, setFilters, uomList, search])
    const actionProps1 = useMemo(() => ({ selections, data }), [data, selections])
    const actionProps2 = useMemo(() => ({ selections, filters }), [filters, selections])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.RolledPartsFlag} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.PartsType} />
        <ActiveFlagTypeProvider />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={CreateModifyAction} actionProps={actionProps1} />
        <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps2} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['netWeight', 'remark1', 'remark2', 'remark3', 'remark4', 'remark5', 'partsType', 'updatedDate']}
            columnSettings={{
                partsNo: { disableUserControl: true },
                oldPartsNo: { disableUserControl: true },
                displayPartsNo: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
    </DataGrid>
}

const FilterItem = (props: {
    search: (filters: GlobalPartsFactor) => void,
    filters: GlobalPartsFactor,
    setFilters: React.Dispatch<SetStateAction<GlobalPartsFactor>>,
    uomList: TnmUom[]
}) => {
    const { filters, uomList, setFilters, search } = props
    const uomListEntries: [string, string][] = useMemo(() => uomList.map(m => [m.uomCode, m.uomCode]), [uomList])

    const clear = useCallback((filters: GlobalPartsFactor) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: GlobalPartsFactor) => {
        return [
            filters.partsNo,
            filters.oldPartsNo,
            filters.displayPartsNo,
            filters.colorCode,
            filters.uomCodeList,
            filters.rolledPartsFlagList,
            filters.rolledPartsUomList,
            filters.remark1,
            filters.partsTypeList,
            filters.activeFlagList,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="partsNo" label={intl.formatMessage({ id: 'field.partsNo' })} />
        <StringItem field="oldPartsNo" label={intl.formatMessage({ id: 'field.oldPartsNo' })} />
        <StringItem field="displayPartsNo" label={intl.formatMessage({ id: 'field.displayPartsNo' })} />
        <StringItem field="colorCode" label={intl.formatMessage({ id: 'field.colorCode' })} />
        <EntriesItem field="uomCodeList" label={intl.formatMessage({ id: 'field.uomCode' })} entries={uomListEntries} />
        <CodesItem field="rolledPartsFlagList" label={intl.formatMessage({ id: 'field.rolledPartsFlag' })} code={CodeCategory.RolledPartsFlag} />
        <EntriesItem field="rolledPartsUomList" label={intl.formatMessage({ id: 'field.rolledPartsUom' })} entries={uomListEntries} />
        <StringItem field="remark1" label={intl.formatMessage({ id: 'field.remark1' })} />
        <CodesItem field="partsTypeList" label={intl.formatMessage({ id: 'field.partsType' })} code={CodeCategory.PartsType} />
        <CodesItem field="activeFlagList" label={intl.formatMessage({ id: 'field.activeFlag' })} code={CodeCategory.ActiveFlag} />
    </FilterToolbarItem>
}

const DownloadAction = ({ selections, filters }: { selections: number[], filters: GlobalPartsFactor }) => {
    const downloadMasterBySelectionApi = useDownloadGlobalPartsMasterByGlobalPartsId()
    const downloadMasterByFilterApi = useDownloadGlobalPartsMasterByFilter()
    const downloadPdfBySelectionApi = useDownloadGlobalPartsPDFByGlobalPartsId()
    const downloadPdfByFilterApi = useDownloadGlobalPartsPDFByFilter()
    const downloadMaster = useCallback(() => {
        if (selections.length <= 0) {
            downloadMasterByFilterApi(Objects.delete(filters, 'page'))
        } else {
            downloadMasterBySelectionApi({ partsIdList: selections })
        }
    }, [downloadMasterByFilterApi, downloadMasterBySelectionApi, filters, selections])
    const downloadPdf = useCallback(() => {
        if (selections.length <= 0) {
            downloadPdfByFilterApi(Objects.delete(filters, 'page'))
        } else {
            downloadPdfBySelectionApi({ partsIdList: selections })
        }
    }, [downloadPdfByFilterApi, downloadPdfBySelectionApi, filters, selections])
    return <DownloadGroupedToolbarAction access="MARS.MLS070.DOWNLOAD">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="downloadGlobalParts" />} callback={downloadMaster} onClose={onClose} />
            <GroupedCallbackItem label={<FormattedMessage id="downloadGlobalPartsByPDF" />} callback={downloadPdf} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}

const CreateModifyAction = ({ selections, data }: { selections: number[], data: GlobalPartsResult[] }) => {
    const setSesion = useSetDataIntoSessionAndGotoUrl()
    const callback = useCallback(() => {
        const partsList = selections.length === 0 ? [] : data.filter(f => selections.indexOf(f.partsId) >= 0).map(m => ({ ...m, newMod: 'MOD' }))
        const url = selections.length === 0 ? '/globalparts/create' : '/globalparts/edit'
        setSesion(sessionKeys.Data_MLS071, globalPartsResultToJson, partsList, url)
    }, [data, selections, setSesion])
    return <EditCallbackToolbarAction access="MARS.MLS070.CREATEOREDIT" title={<FormattedMessage id="createOrModifyGlobalParts" />} callback={callback} />
}