
import { DialogActions, DialogContent, makeStyles, Typography, useTheme } from "@material-ui/core"
import { Column, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Editing, Filtering, NumberTypeProvider, PaginationLayout, Row, Searching, Sorting, StringTypeProvider, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarLayout } from "@rithe/data-grid"
import { NumberFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/NumberFormatter"
import { StringFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/StringFormatter"
import { Break, DateItem, Form, Message, NumberItem, StringItem } from "@rithe/form"
import { GridContainer, GridItem } from "@rithe/ui"
import { Arrays, arrx, Records } from "@rithe/utils"
import moment from "moment"
import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { DownloadCallbackCardAction } from "../../../components/Action/DownloadCallbackCardAction"
import { IssueCallbackViewAction } from "../../../components/Action/IssueCallbackViewAction"
import { SaveCallbackViewAction } from "../../../components/Action/SaveCallbackViewAction"
import { UploadCallbackCardAction } from "../../../components/Action/UploadCallbackCardAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CreateCallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/CreateCallbackToolbarAction"
import { PercentTypeProvider } from "../../../components/DataGrid/typeProviders/PercentTypeProvider"
import { DarkDialog } from "../../../components/Dialog/DarkDialog"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { CodeItem } from "../../../components/Form/CodeItem"
import { StepperAction } from "../../../components/View/Step/StepperAction"
import { View } from "../../../components/View/View"
import { useFunctionStore } from "../../../Root"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { OrderLotFlag } from "../../../services/master/enums/OrderLotFlag"
import { useDownloadRegularOrderformForCustomer, useDownloadSpotOrderformForCustomer } from "../../../services/order/apis/OrderDownloadApi"
import { useUploadRegularOrderFormForCustomer, useUploadSpotOrderFormForCustomer } from "../../../services/order/apis/OrderUploadApi"
import { useSaveAndIssuePlacedOrder, useSavePlacedOrder } from "../../../services/order/apis/PlaceOrderApi"
import { CoStatus } from "../../../services/order/enums/CoStatus"
import { OrderType } from "../../../services/order/enums/OrderType"
import { PlaceOrderDetailFactor } from "../../../services/order/models/PlaceOrderDetailFactor"
import { PartsDetail } from "../../../services/order/models/PlaceOrderDetailResult"
import { formatDateRange } from "../../../utils/formatDateRange"
import { isMultiple } from "../../../utils/NumberUtil"
import { useFieldChecker } from "../../../utils/ValidatorUtils"
import { applicationActions } from "../../Application/applicationSlice"
import { PlaceOrderBasic, useMergeDatas, useSplitDatas } from "./ONS011"
import { useShippingPlanCategory } from "./ShippingPlanCategory"

interface ONS011PcUiProps {
    orderType: OrderType,
    factor: PlaceOrderDetailFactor,
    basic: PlaceOrderBasic,
    setBasic: React.Dispatch<React.SetStateAction<PlaceOrderBasic>>,
    forecasts: PartsDetail[],
    setForecasts: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
    inboundPlans: PartsDetail[],
    setInboundPlans: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
    mode: ScreenMode,
}

export const ONS011PcUi = (props: ONS011PcUiProps) => {

    const { factor, basic, setBasic, forecasts, setForecasts, inboundPlans, setInboundPlans, orderType, mode } = props
    const { targetFirstDate, targetLastDate } = basic
    const intl = useIntl()
    const actions = usePrepareActions(orderType, basic, forecasts, inboundPlans)
    const editable = useMemo(() => basic && basic.status ? basic.status === CoStatus.DRAFT : true, [basic])

    return (
        <View actions={<StepperAction actions={actions} />}>
            <SectionCard allowCollapse id="ONS011_Section_1" >
                <SectionCardHeader
                    serialNumber={1}
                    step
                    title={intl.formatMessage({ id: 'step1OfPlaceRegularOrder' })}
                    subtitle={intl.formatMessage({ id: 'step1OfPlaceRegularOrderSub' })}

                />
                <SectionCardContent>
                    <Step1DownloadUploadCard
                        orderType={orderType}
                        factor={factor}
                        editable={editable}
                        basic={basic}
                        setBasic={setBasic}
                        forecasts={forecasts}
                        setForecasts={setForecasts}
                        inboundPlans={inboundPlans}
                        setInboundPlans={setInboundPlans}
                    />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse defaultCollapse id="ONS011_Section_2">
                <SectionCardHeader
                    serialNumber={2}
                    step
                    title={intl.formatMessage({ id: 'step2OfPlaceRegularOrder' })}
                    subtitle={intl.formatMessage({ id: 'basicInfoSub' })}
                />
                <SectionCardContent>
                    <Step2FirmAndFcTable orderType={orderType} targetFirstDate={targetFirstDate} editable={editable} targetLastDate={targetLastDate} forecasts={forecasts} setForecasts={setForecasts} setInboundPlans={setInboundPlans} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse defaultCollapse id="ONS011_Section_3">
                <SectionCardHeader
                    serialNumber={3}
                    step
                    title={intl.formatMessage({ id: 'step3OfPlaceRegularOrder' })}
                    subtitle={intl.formatMessage({ id: 'basicInfoSub' })}
                />
                <SectionCardContent>
                    <Step3ShippingPlanTable inboundPlans={inboundPlans} setBasic={setBasic} editable={editable} setInboundPlans={setInboundPlans} mode={mode} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse defaultCollapse id="ONS011_Section_4">
                <SectionCardHeader
                    serialNumber={4}
                    step
                    title={intl.formatMessage({ id: 'step4OfPlaceRegularOrder' })}
                    subtitle={intl.formatMessage({ id: 'basicInfoSub' })}
                />
                <SectionCardContent>
                    <Step4BasicInfoPanelCard basic={basic} setBasic={setBasic} />
                </SectionCardContent>
            </SectionCard>
        </View >
    )
}

interface Step1Props {
    orderType: OrderType,
    factor: PlaceOrderDetailFactor,
    editable: boolean,
    basic: PlaceOrderBasic,
    setBasic: React.Dispatch<React.SetStateAction<PlaceOrderBasic>>,
    forecasts: PartsDetail[],
    setForecasts: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
    inboundPlans: PartsDetail[],
    setInboundPlans: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
}
const Step1DownloadUploadCard = memo((props: Step1Props) => {
    const { editable } = props
    const theme = useTheme()
    return (
        <GridContainer columnWidth={[500, 200]} style={{ paddingLeft: theme.spacing(5) }} rowGap={theme.spacing(2)}>
            <GridItem style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='body1'><FormattedMessage id='downloadInStep1' /></Typography>
            </GridItem>
            <GridItem >
                {editable ? <DownloadAction {...props} /> : <></>}
            </GridItem>
            <GridItem style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='body1'><FormattedMessage id='uplaodInStep1' /></Typography>
            </GridItem>
            <GridItem >
                {editable ? <UploadAction {...props} /> : <></>}
            </GridItem>
        </GridContainer>
    )
})

interface Step2Props {
    orderType: OrderType,
    targetFirstDate: Date,
    targetLastDate: Date,
    forecasts: PartsDetail[],
    editable: boolean,
    setForecasts: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
    setInboundPlans: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
}
const Step2FirmAndFcTable = memo((props: Step2Props) => {
    const { orderType, targetFirstDate, targetLastDate, forecasts, setForecasts, editable, setInboundPlans } = props
    const intl = useIntl()
    const [order, setOrder] = useState<string[]>([])

    const getOrderLotCheck = useCallback((row: Row) => {
        return (row.orderlotFlag === OrderLotFlag.N || !row.firmQty || !row.orderLot) ? 'N/A' : isMultiple(row.firmQty, row.orderLot) ? 'OK' : 'NG'
    }, [])
    const getFlctuationFirm = useCallback((row: Row) => (row.firmQty != null && row.lastOrderForecast1) ? (row.firmQty - row.lastOrderForecast1) / row.lastOrderForecast1 : null, [])
    const getForecastQty = useCallback((index: number) => (row: Row) => row.forecastList[index].forecastQty, [])
    const setForecastQty = useCallback((index: number) => (row: Row, value: any) => ({ ...row, forecastList: row.forecastList?.map((fc: any, idx: number) => idx === index ? { ...fc, forecastQty: value } : fc) }), [])
    const getLastForecastQty = useCallback((index: number) => (row: Row) => row.forecastList[index].lastForecastQty, [])
    const getFlctuationFc = useCallback((index: number) => {
        return (row: Row) => {
            if (row.forecastList && row.forecastList[index]) {
                const forecast = row.forecastList[index]
                return (forecast.forecastQty !== null && forecast.lastForecastQty) ? (forecast.forecastQty - forecast.lastForecastQty) / forecast.lastForecastQty : null
            }
            return null
        }
    }, [])
    const getFlctuationReasnFc = useCallback((index: number) => (row: Row) => row.forecastList[index].fluctuationReason, [])

    const setFlctuationReasnFc = useCallback((index: number) => (row: Row, value: any) => ({
        ...row, forecastList: row.forecastList?.map((fc: any, idx: number) => idx === index ? { ...fc, fluctuationReason: value } : fc)
    }), [])

    const forecastNum = forecasts[0]?.forecastList?.length ?? 0

    const columns = useMemo(() => {
        const fixedColumns = [
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
            { field: 'customerPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'partsDescription' }), width: 350 },
            { field: 'customerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'customerPartsNo' }), width: 220 },
            { field: 'supplierPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'supplierPartsNo' }), width: 220 },
            { field: 'exportCountry', dataTypeName: 'string', title: intl.formatMessage({ id: 'expCountry' }), width: 180 },
            { field: 'supplierCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierCode' }), width: 180 },
            { field: 'uomCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.uomCode' }), width: 150 },
            { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 150 },
        ] as Column[]
        const firmCategories = [{ key: 'firm', value: formatDateRange(intl, targetFirstDate, targetLastDate) }]
        const firmColumns = orderType === OrderType.REGULAR ? [
            { field: 'fluctuationRate', dataTypeName: 'percent', title: intl.formatMessage({ id: 'fluctuationRate' }), width: 200 },
            { field: 'firmQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'firmQty' }), categories: firmCategories, width: 150 },
            { field: 'orderLotCheck', dataTypeName: 'orderLotCheck', title: intl.formatMessage({ id: 'orderLotCheck' }), categories: firmCategories, width: 200, getCellValue: getOrderLotCheck },
            { field: 'lastOrderForecast1', dataTypeName: 'number', title: intl.formatMessage({ id: 'lastOrderForecast' }) + " 1", categories: firmCategories, width: 230 },
            { field: 'flctuationFirm', dataTypeName: 'fluctuation', title: intl.formatMessage({ id: 'fluctuation' }), categories: firmCategories, width: 150, getCellValue: getFlctuationFirm },
            { field: 'fluctReasonFirm', dataTypeName: 'string', title: intl.formatMessage({ id: 'reasonForFluctuation' }), categories: firmCategories, width: 250 },
        ] as Column[] : [
            { field: 'firmQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'firmQty' }), categories: firmCategories, width: 150 },
            { field: 'orderLotCheck', dataTypeName: 'orderLotCheck', title: intl.formatMessage({ id: 'orderLotCheck' }), categories: firmCategories, width: 200, getCellValue: getOrderLotCheck },
        ] as Column[]

        // Check order Type
        const getFcCategories = (index: number) => [{ key: `fc${index}`, value: formatDateRange(intl, forecasts[0].forecastList[index].targetFirstDate, forecasts[0].forecastList[index].targetLastDate) }]
        const fcColumns = orderType === OrderType.REGULAR ? Arrays.concat(
            [{ field: 'fcFluctuationRate', dataTypeName: 'percent', title: intl.formatMessage({ id: 'fcFluctuationRate' }), width: 220 } as Column],
            Arrays.range(0, forecastNum).flatMap(index => {
                const categories = getFcCategories(index)
                return arrx(
                    { field: 'forecastQty' + index + 1, dataTypeName: 'number', title: `${intl.formatMessage({ id: 'forecast' })} ${index + 1}`, categories, width: index < forecastNum - 1 ? 150 : 250, getCellValue: getForecastQty(index), setCellValue: setForecastQty(index) },
                    index < forecastNum - 1 && { field: 'lastOrderForecast' + (index + 2), dataTypeName: 'number', title: `${intl.formatMessage({ id: 'lastOrderForecast' })} ${(index + 2)}`, categories, width: 230, getCellValue: getLastForecastQty(index) },
                    index < forecastNum - 1 && { field: 'fluctuation' + index + 1, dataTypeName: 'fcFluctuation', title: intl.formatMessage({ id: 'fluctuation' }), categories, width: 150, getCellValue: getFlctuationFc(index) },
                    index < forecastNum - 1 && { field: 'reasonForFluctuation' + index + 1, dataTypeName: 'string', title: intl.formatMessage({ id: 'reasonForFluctuation' }), categories, width: 250, getCellValue: getFlctuationReasnFc(index), setCellValue: setFlctuationReasnFc(index) },
                )
            })
        ) : []
        // all columns
        return Arrays.concat(fixedColumns, firmColumns, fcColumns)
    }, [forecastNum, forecasts, getFlctuationFc, getFlctuationFirm, getFlctuationReasnFc, getForecastQty, getLastForecastQty, getOrderLotCheck, intl, orderType, setFlctuationReasnFc, setForecastQty, targetFirstDate, targetLastDate])

    const onEditingCellCommit = useCallback((_column: Column, row: Row) => {
        setForecasts(forecasts => forecasts.map(item => item.partsId === row.partsId ? row as PartsDetail : item))
        setInboundPlans(inboundPlans => {
            if (inboundPlans.some(s => s.partsId === row.partsId && s.firmQty !== row.firmQty)) {
                return inboundPlans.map(m => m.partsId === row.partsId ? { ...m, firmQty: row.firmQty } : m)
            } else {
                return inboundPlans
            }
        })
        return true
    }, [setForecasts, setInboundPlans])

    const defaultEditingDisabled = Records.from(columns.filter(f => !f.field.includes('forecast') && !f.field.includes('firmQty') && !f.field.includes('fluctReasonFirm') && !f.field.includes('reasonForFluctuation')).map(({ field }) => [field, { editingDisabled: true }]))

    useEffect(() => {
        setOrder(columns.map(column => column.field))
    }, [columns])

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <PercentTypeProvider />
            <CheckTypeProvider />
            <FluctuationTypeProvider />
            <FcFluctuationTypeProvider />
            <Data rows={forecasts} columns={columns} />
            <ColumnFreeze />
            <ColumnVisibility ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnOrdering order={order} onOrderChange={setOrder} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Editing
                enableInlineEdit={editable ? true : false}
                onEditingCellCommit={onEditingCellCommit}
                columnSettings={defaultEditingDisabled}
            />
            <Filtering />
        </DataGrid>
    </div>
})

interface Step3Props {
    setBasic: React.Dispatch<React.SetStateAction<PlaceOrderBasic>>,
    inboundPlans: PartsDetail[],
    editable: boolean,
    setInboundPlans: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
    mode: ScreenMode
}

const Step3ShippingPlanTable = memo((props: Step3Props) => {
    const { inboundPlans, setInboundPlans, editable, mode } = props
    const planDates = useMemo(() => {
        return inboundPlans && inboundPlans.length > 0 ? Arrays.distinct(inboundPlans.flatMap(parts => parts.inbPlanList).map(plan => plan.crdDate.getTime())).sort().map(m => new Date(m)) : []
    }, [inboundPlans])

    const intl = useIntl()
    const [order, setOrder] = useState<string[]>([])
    const [actions, setActions] = useState<RequestProps>({ open: false, editDate: null })

    const columns = useMemo(() => {
        const fixedColumns: Column[] = [
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
            { field: 'customerPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'partsDescription' }), width: 300 },
            { field: 'customerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'customerPartsNo' }), width: 220 },
            { field: 'supplierPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'supplierPartsNo' }), width: 220 },
            { field: 'exportCountry', dataTypeName: 'string', title: intl.formatMessage({ id: 'expCountry' }), width: 180 },
            { field: 'supplierCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierCode' }), width: 180 },
            { field: 'uomCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.uomCode' }), width: 150 },
            { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 150 },
        ]
        const firmCategories = [{ key: 'firm', value: intl.formatMessage({ id: 'deliveryDate' }) }]
        const firmColumns = [{ field: 'firmQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'firmQty' }), categories: firmCategories, width: 150 }]
        const getPlanCategories = (index: number) => [{ key: `plan${index}`, value: intl.formatDate(planDates[index], { dateStyle: 'medium' }) }]
        const qtytitle = intl.formatMessage({ id: 'field.qty' })
        const planColumns = planDates.map((planDate, index) => ({
            field: `planQty${index}`,
            dataTypeName: 'shippingPlan',
            title: qtytitle,
            categories: getPlanCategories(index),
            width: 125,
            getCellValue: (row: Row) => row.inbPlanList.find((f: any) => f.crdDate.getTime() === planDate.getTime())?.crdQty ?? null,
            setCellValue: (row: Row, value: any) => {
                // do fiter
                if (row.inbPlanList?.some((plan: any) => plan.crdDate.getTime() === planDate.getTime())) {
                    return ({ ...row, inbPlanList: row.inbPlanList?.map((plan: any) => plan.crdDate.getTime() === planDate.getTime() ? { ...plan, crdQty: value } : plan) })
                } else {
                    const inbPlanList = row.inbPlanList
                    inbPlanList.push({
                        coDetailOrigin: row.coDetailOrigin,
                        soDetailOrigin: null,
                        crdDate: planDate,
                        crdQty: value,
                    })
                    return ({ ...row, inbPlanList: inbPlanList })
                }
            }
        }))
        return Arrays.concat(fixedColumns, firmColumns, planColumns)
    }, [intl, planDates])

    const onEditingCellCommit = useCallback((_column: Column, row: Row) => {
        setInboundPlans(inboundPlans => inboundPlans.map(item => item.partsId === row.partsId ? row as PartsDetail : item))
        return true
    }, [setInboundPlans])

    const defaultEditingDisabled = Records.from(columns.filter(f => !f.field.includes('planQty')).map(({ field }) => [field, { editingDisabled: true }]))
    const AddnewAction = useMemo(() => ({ setActions }), [setActions])
    const clickHeader = useCallback((field: string) => {
        field.startsWith("planQty") && setActions({ open: true, editDate: planDates[Number(field.substring(7))] })
    }, [planDates])
    const shippingPlanCategory = useShippingPlanCategory(clickHeader, mode !== ScreenMode.VIEW)

    useEffect(() => {
        setOrder(columns.map(column => column.field))
    }, [columns])

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <NumberTypeProvider name="shippingPlan" Category={shippingPlanCategory} />
            <Data rows={inboundPlans} columns={columns} />
            <ColumnFreeze />
            <ToolbarActionProvider Action={CreateAction} actionProps={AddnewAction} display={() => editable} />
            <ColumnVisibility ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnOrdering order={order} onOrderChange={setOrder} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Editing
                enableInlineEdit={editable ? true : false}
                onEditingCellCommit={onEditingCellCommit}
                columnSettings={defaultEditingDisabled}
            />
            <Sorting />
            <Filtering />
        </DataGrid>
        <AddNewRequestDialog actions={actions} setActions={setActions} inboundPlans={inboundPlans} setInboundPlans={setInboundPlans} />
    </div>
})

const CreateAction = ({ setActions }: { setActions: React.Dispatch<React.SetStateAction<RequestProps>> }) => {
    return <CreateCallbackToolbarAction title={<FormattedMessage id="Add New Plan" />} callback={() => setActions({ open: true, editDate: null })} />
}

interface RequestProps {
    open: boolean,
    editDate: Date | null
}

const AddNewRequestDialog = ({ actions, setActions, inboundPlans, setInboundPlans }: {
    actions: RequestProps,
    setActions: React.Dispatch<React.SetStateAction<RequestProps>>,
    inboundPlans: PartsDetail[],
    setInboundPlans: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
}) => {
    const dispatch = useDispatch()
    const { open, editDate } = actions
    const [factor, setFactor] = useState<{ inboundPlanDate: Date | null }>({ inboundPlanDate: null })
    const handleClose = useCallback(() => setActions({ open: false, editDate: null }), [setActions])

    const intl = useIntl()

    useEffect(() => {
        setFactor({ inboundPlanDate: editDate })
    }, [editDate])

    const aplyDate = useCallback(() => {
        if (!factor.inboundPlanDate) {
            dispatch(applicationActions.pushError({ title: { code: 'confirm' }, messages: { code: 'w0001', args: ['field.inboundPlanDate'] } }))
            return
        } else {
            const inboundPlanDate = factor.inboundPlanDate
            if (inboundPlans.flatMap(f => f.inbPlanList).some(s => moment(s.crdDate).format(moment.HTML5_FMT.DATE) === moment(inboundPlanDate).format(moment.HTML5_FMT.DATE))
                && (!editDate || moment(editDate).format(moment.HTML5_FMT.DATE) !== moment(inboundPlanDate).format(moment.HTML5_FMT.DATE))) {
                dispatch(applicationActions.pushError({ title: { code: 'confirm' }, messages: { code: 'w0381' } }))
                return
            } else {
                setInboundPlans(inboundPlans => inboundPlans.map(m => ({
                    ...m, inbPlanList: !editDate ? [...m.inbPlanList, {
                        coDetailOrigin: m.coDetailOrigin,
                        soDetailOrigin: null,
                        crdDate: inboundPlanDate,
                        crdQty: null
                    }] : m.inbPlanList.map(plan => plan.crdDate.getTime() === editDate.getTime() ? { ...plan, crdDate: inboundPlanDate } : plan)
                })))
            }
        }
        // do reflect
        setFactor({ inboundPlanDate: null })
        setActions({ open: false, editDate: null })
    }, [dispatch, editDate, factor.inboundPlanDate, inboundPlans, setActions, setInboundPlans])

    return <>
        <DarkDialog open={open} style={{ overflow: 'hidden' }} maxWidth="sm" fullWidth keepMounted={false} fullScreen={false}>
            <DialogHeader onClose={handleClose}><FormattedMessage id="inputInboundDate" /></DialogHeader>
            <DialogContent>
                <Form data={factor} setData={setFactor} labelDisplay="block" helperDisplay="tooltip" columnCount={1} minWidth={500} maxWidth={500}  >
                    <DateItem required field="inboundPlanDate" labelWidth={120} label={intl.formatMessage({ id: 'field.inboundPlanDate' })} />
                </Form>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={handleClose} />
                <DialogAction title={<FormattedMessage id="confirm" />} callback={aplyDate} />
            </DialogActions>
        </DarkDialog>
    </>
}

const Step4BasicInfoPanelCard = memo(({ basic, setBasic }: { basic: PlaceOrderBasic, setBasic: React.Dispatch<React.SetStateAction<PlaceOrderBasic>> }) => {

    const intl = useIntl()
    const { orderType } = basic
    const editable = useMemo(() => basic && basic.status ? basic.status === CoStatus.DRAFT : true, [basic])
    const orderRange = useMemo(() => formatDateRange(intl, basic.orderFirstDate, basic.orderLastDate), [basic.orderFirstDate, basic.orderLastDate, intl])
    const [messages, setMessages] = useState<Message[]>([])
    const checkFields = useMemo(() => ({
        orderRefNo: { labelId: 'orderRefNo', length: { max: 100 } },
        remark: { labelId: 'remark', length: { max: 255 } }
    }), [])
    const filedCheck = useFieldChecker(checkFields, setMessages)

    return (
        <Form data={basic} setData={setBasic} labelDisplay="block" helperDisplay="tooltip" columnCount={2} messages={messages} setMessages={setMessages} ensure={filedCheck}>
            <StringItem field="orderRefNo" readonly={!editable} label={intl.formatMessage({ id: 'orderReference' })} />
            <StringItem field="customerContractNo" readonly={true} label={intl.formatMessage({ id: 'field.contractNo' })} />
            <Break />
            <StringItem field="sellerCode" readonly={true} label={intl.formatMessage({ id: 'seller' })} />
            <NumberItem field="totalAmount" readonly={true} label={intl.formatMessage({ id: 'totalAmount' })} suffix={basic.currency} />
            <Break />
            <StringItem field="deliveryToCode" readonly={true} label={intl.formatMessage({ id: 'deliveryToCode' })} />
            <NumberItem field="totalNumberOfParts" readonly={true} label={intl.formatMessage({ id: 'totalNumberOfParts' })} />
            <Break />
            <CodeItem field="shippingMode" readonly={OrderType.REGULAR === orderType} label={intl.formatMessage({ id: 'field.shippingMode' })} code={CodeCategory.ShippingMode} />
            <NumberItem field="totalQty" readonly={true} label={intl.formatMessage({ id: 'totalQty' })} />
            <Break />
            <StringItem field="orderRange" readonly={true} label={intl.formatMessage({ id: 'orderRange' })} getValue={() => orderRange} />
            <StringItem field="deliveryPlanRange" readonly={true} label={intl.formatMessage({ id: 'deliveryPlanRange' })} />
            <Break />
            <StringItem field="paymentTermsDescription" readonly={true} label={intl.formatMessage({ id: 'field.paymentTermsDesc' })} colSpan={2} />
            {OrderType.SPOT === orderType && <StringItem required field="spotOrderReason" readonly={!editable} label={intl.formatMessage({ id: 'field.spotOrderReason' })} colSpan={2} />}
            <StringItem field="remark" readonly={!editable} label={intl.formatMessage({ id: 'field.remark' })} colSpan={2} />
        </Form>
    )
})

const UploadAction = (props: Step1Props) => {

    const { factor, orderType, setBasic, setForecasts, setInboundPlans } = props
    const uploadRegularMethod = useUploadRegularOrderFormForCustomer()
    const uploadSpotMethod = useUploadSpotOrderFormForCustomer()
    const uploadMethod = orderType === OrderType.REGULAR ? uploadRegularMethod : uploadSpotMethod
    const splitDatas = useSplitDatas()
    const upload = useCallback((files: FileList | null) => {
        if (files === null) return
        uploadMethod({ file: files[0], ...factor }, { serialized: true }).then(result => {
            if (result) {
                const { orderbasic, orderforecasts, orderInboundPlans } = splitDatas(result)
                setBasic(orderbasic)
                setForecasts(orderforecasts)
                setInboundPlans(orderInboundPlans)
            }
        })
    }, [factor, setBasic, setForecasts, setInboundPlans, splitDatas, uploadMethod])

    return <UploadCallbackCardAction access="ORDER.ONS011.UPLOAD" callback={upload} />
}

const DownloadAction = (props: Step1Props) => {

    const mergeDatas = useMergeDatas()

    const { orderType, basic, forecasts, inboundPlans } = props
    const downloadRegularOrderformForCustomer = useDownloadRegularOrderformForCustomer()
    const downloadSpotOrderformForCustomer = useDownloadSpotOrderformForCustomer()
    const download = useCallback(() => {
        const data = mergeDatas(basic, forecasts, inboundPlans)
        if (orderType === OrderType.REGULAR) {
            downloadRegularOrderformForCustomer(data)
        } else {
            downloadSpotOrderformForCustomer(data)
        }
    }, [basic, downloadRegularOrderformForCustomer, downloadSpotOrderformForCustomer, forecasts, inboundPlans, mergeDatas, orderType])

    return <DownloadCallbackCardAction outlined access="ORDER.ONS011.DOWNLOAD" callback={download} />
}

const usePrepareActions= (orderType: OrderType,basic: PlaceOrderBasic, forecasts: PartsDetail[], inboundPlans: PartsDetail[]) => {
    const savePlacedOrder = useSavePlacedOrder()
    const saveAndIssuePlacedOrder = useSaveAndIssuePlacedOrder()
    const navigate = useNavigate()
    const mergeDatas = useMergeDatas()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const issueTitle = useMemo(() => intl.formatMessage({ id: 'Issue' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const onclickToSave = useCallback(() => {
        setDisabled(true)
        const data = mergeDatas(basic, forecasts, inboundPlans)
        savePlacedOrder(data, { serialized: true }).then(result => {
            orderType===OrderType.REGULAR? navigate(`/placecustorder-regular`):navigate(`/placecustorder-spot`)
        }).finally(() => {
            setDisabled(false)
        })
    }, [orderType,basic, forecasts, inboundPlans, mergeDatas, navigate, savePlacedOrder])

    const editable = useMemo(() => basic && basic.status ? basic.status === CoStatus.DRAFT : true, [basic])

    const onclickToIssue = useCallback(() => {
        const issueOrder = functionStore.register(() => {
            setDisabled(true)
            const data = mergeDatas(basic, forecasts, inboundPlans)
            saveAndIssuePlacedOrder({ ...data, isConfirm: false }, { serialized: true, silent: true }).then(warningMessages => {
                if (warningMessages.length === 0) {
                    dispatch(applicationActions.pushSuccess({
                        title: issueTitle,
                        messages: [{ code: 'notice.success' }],
                    }))
                    orderType===OrderType.REGULAR? navigate(`/placecustorder-regular`):navigate(`/placecustorder-spot`)
                } else {
                    const ignoreNotInput = functionStore.register(() => {
                        const data = mergeDatas(basic, forecasts, inboundPlans)
                        saveAndIssuePlacedOrder({ ...data, isConfirm: true }, { serialized: true }).then(() => {
                            orderType===OrderType.REGULAR? navigate(`/placecustorder-regular`):navigate(`/placecustorder-spot`)
                        })
                    })
                    dispatch(applicationActions.pushWarning({
                        title: issueTitle,
                        messages: warningMessages,
                        actions: [{
                            label: 'CANCEL'
                        }, {
                            functionId: ignoreNotInput,
                            label: 'CONFIRM',
                        }]
                    }))
                }
            }).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: issueTitle,
            messages: { code: 'c0001', args: [issueTitle] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId: issueOrder,
                label: 'CONFIRM',
            }]
        }))
    }, [orderType,basic, dispatch, forecasts, functionStore, inboundPlans, issueTitle, mergeDatas, navigate, saveAndIssuePlacedOrder])

    return editable ? [
        <SaveCallbackViewAction access="ORDER.ONS011.SAVE" outlined callback={onclickToSave} disabled={disabled} />,
        <IssueCallbackViewAction access="ORDER.ONS011.ISSUE" callback={onclickToIssue} disabled={disabled} />
    ] : []
}

const CheckFormatter = ({ value }: StringFormatterProps) => {
    const style = useStyles()
    const bgcolor = 'NG' === value ? '#D94C00' : 'N/A' === value ? '#ECEFF2' : '#00CCAD'
    const color = 'N/A' === value ? '' : 'white'
    return <div className={style.orderLotCheck} style={{ background: bgcolor, color: color, width: '100%' }}>
        <Typography variant="body2" >{value}</Typography>
    </div>
}

const CheckTypeProvider = () => {
    return <StringTypeProvider name="orderLotCheck" Formatter={CheckFormatter} />
}

const FluctuationFormatter = ({ value, row, formatter }: NumberFormatterProps) => {
    const style = useStyles()
    const fluctuationRate = row.fluctuationRate
    const displayValue = value === null ? 'N/A' : formatter.format(value)
    const bgcolor = (value === null || fluctuationRate === null) ? '#ECEFF2' : Math.abs(value) > fluctuationRate ? '#D94C00' : '#00CCAD'
    const color = (value === null || fluctuationRate === null) ? '' : 'white'
    return <div className={style.fulcuationCheck} style={{ background: bgcolor, color: color, width: '100%' }}>
        <Typography variant="body2" >{displayValue}</Typography>
    </div>
}

const FluctuationTypeProvider = () => {
    return <NumberTypeProvider name="fluctuation" options={{ style: 'percent', maximumFractionDigits: 0, minimumFractionDigits: 0 }} Formatter={FluctuationFormatter} />
}

const FcFluctuationFormatter = ({ value, row, formatter }: NumberFormatterProps) => {
    const style = useStyles()
    const fluctuationRate = row.fcFluctuationRate
    const displayValue = value === null ? 'N/A' : formatter.format(value)
    const bgcolor = (value === null || fluctuationRate === null) ? '#ECEFF2' : Math.abs(value) > fluctuationRate ? '#D94C00' : '#00CCAD'
    const color = (value === null || fluctuationRate === null) ? '' : 'white'
    return <div className={style.fulcuationCheck} style={{ background: bgcolor, color: color, width: '100%' }}>
        <Typography variant="body2" >{displayValue}</Typography>
    </div>
}

const FcFluctuationTypeProvider = () => {
    return <NumberTypeProvider name="fcFluctuation" options={{ style: 'percent', maximumFractionDigits: 0, minimumFractionDigits: 0 }} Formatter={FcFluctuationFormatter} />
}

const useStyles = makeStyles(theme => ({
    orderLotCheck: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70%',
        paddingRight: theme.spacing(1),
        borderRadius: 5
    },
    fulcuationCheck: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70%',
        paddingRight: theme.spacing(1),
        borderRadius: 5
    },
    default: {},
}))
