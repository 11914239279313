import {useEffect, useState} from "react"
import {useGetMfaStateAndRecover} from "../../../services/system/apis/userMfaSettingApi"
import {UserMfaRecoverList} from "../../../services/system/models/UserMfaResponse"
import {MSESF023PcUi} from "./MSESF023PcUi"

export const MSESF023 = () => {
  const getMfaStateAndRecover = useGetMfaStateAndRecover()
  const [dialog, setDialog] = useState<boolean>(false)
  const [data, setData] = useState<UserMfaRecoverList[]>([])

  useEffect(() => {
    getMfaStateAndRecover(undefined, {silent: true}).then(result => {
      setDialog(result.isEnable === 0)
      setData(result.recovers ?? [])
    })
  }, [getMfaStateAndRecover, setDialog, setData])

  return <MSESF023PcUi
    dialog={dialog}
    setDialog={setDialog}
    data={data}
  />
}