import { useCallback } from "react"
import { Projects } from "../../../utils/Projects"
import { usePost } from "../../../utils/fetch/useFetch"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { MLS152CustomsRequest, mls152CustomsRequestFromJson, mls152CustomsRequestToJson } from "../models/MLS152CustomsRequest"
import { RejectRequestFactor, rejectRequestFactorToJson } from "../models/RejectRequestFactor"
import { RequestFactor, requestFactorToJson } from "../models/RequestFactor"
import { RequestResult, requestResultFromJson } from "../models/RequestResult"
import { RequestToResult, requestToResultFromJson } from "../models/RequestToResult"
import { TnvCbds, tnvCbdsFromJson } from "../models/TnvCbds"

export const useGetAllFromRequestList = () => {
    const url = '/lcbm-local-master-api/api/getAllFromRequestList'
    const payloadProject = useCallback(requestFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(requestResultFromJson), [])
    return usePost<RequestFactor, PageResultEntity<RequestResult>>(url, payloadProject, resultProject)
}

export const useGetAllToRequestList = () => {
    const url = '/lcbm-local-master-api/api/getAllToRequestList'
    const payloadProject = useCallback(requestFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(requestResultFromJson), [])
    return usePost<RequestFactor, PageResultEntity<RequestResult>>(url, payloadProject, resultProject)
}

export const useGetActiveRequestToList = () => {
    const url = '/lcbm-system-master-api/api/getActiveRequestToList'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(tnvCbdsFromJson), [])
    return usePost<undefined, TnvCbds[]>(url, payloadProject, resultProject)
}

export const useGetActiveRequestFromList = () => {
    const url = '/lcbm-system-master-api/api/getActiveRequestFromList'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(tnvCbdsFromJson), [])
    return usePost<undefined, TnvCbds[]>(url, payloadProject, resultProject)
}

export const useSubmitRequest = () => {
    const url = '/lcbm-local-master-api/api/submitRequest'
    const payloadProject = useCallback((obj: { requestIdList: number[] }) => obj.requestIdList, [])
    const resultProject = Projects.IGNORE
    return usePost<{ requestIdList: number[] }, undefined>(url, payloadProject, resultProject)
}

export const useCancelRequest = () => {
    const url = '/lcbm-local-master-api/api/cancelRequest'
    const payloadProject = useCallback((obj: { requestIdList: number[] }) => obj.requestIdList, [])
    const resultProject = Projects.IGNORE
    return usePost<{ requestIdList: number[] }, undefined>(url, payloadProject, resultProject)
}

export const useRejectRequest = () => {
    const url = '/lcbm-local-master-api/api/rejectRequest'
    const payloadProject = useCallback(rejectRequestFactorToJson, [])
    const resultProject = Projects.IGNORE
    return usePost<RejectRequestFactor, undefined>(url, payloadProject, resultProject)
}


export const useGetRequestToList = () => {
    const url = '/lcbm-local-master-api/api/getRequestToList'
    // const payloadProject = useCallback((obj: { requestType: number }) => obj.requestType, []);
    const payloadProject = useCallback(requestFactorToJson, [])
    const resultProject = useCallback(Projects.array(requestToResultFromJson), [])
    return usePost<RequestFactor, RequestToResult[]>(url, payloadProject, resultProject)
}

export const useSubmitRequestDetail = () => {
    const url = '/lcbm-local-master-api/api/submitRequestDetail'
    const payloadProject = useCallback(mls152CustomsRequestToJson, [])
    const resultProject = useCallback(mls152CustomsRequestFromJson, [])
    return usePost<MLS152CustomsRequest, MLS152CustomsRequest>(url, payloadProject, resultProject)
}

