import {Project} from "../../../utils/Project"
import {BusinessType} from "../../master/enums/BusinessType"
import {OrderFrequency} from "../../order/enums/OrderFrequency"
import {OrderType} from "../../order/enums/OrderType"
import {dateToJson} from "../../utils/serializer"

export type InputExternalDownloadDataRangeFactor = Required & Partial<Optional>
type InputExternalDownloadDataRangeFactorJson = JsonRequired & Partial<JsonOptional>
export const inputExternalDownloadDataRangeFactorToJson: Project<InputExternalDownloadDataRangeFactor, InputExternalDownloadDataRangeFactorJson> = obj => ({
    ...obj,
    orderDate: dateToJson(obj.orderDate),
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'orderDate'> {
    orderDate: Date,
    orderType: OrderType,
    businessType: BusinessType,
    orderFrequency: OrderFrequency,
}

interface JsonRequired {
}

interface JsonOptional {
    grouping: string,
    orderDate: string,
    orderType: number,
    businessType: number,
    orderFrequency: number,
    targetLeadTime: number,
    forecastNum:number
}