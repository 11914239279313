import { useCallback, useEffect, useMemo, useState } from "react"
import { useGetDailyRundownStock } from "../../../services/smt/stockManagement/api/weeklyRundownApi"
import { DailyRundownFactor } from "../../../services/smt/stockManagement/models/DailyRundownFactor"
import { Daily, DailyRundownResult } from "../../../services/smt/stockManagement/models/DailyRundownResult"
import sessionKeys from "../../../utils/sessionKeys"
import { SMGTS211PcUi } from "./SMGTS211PcUi"

export const SMGTS211 = (_props: any) => {
    // get parts list information
    const cachedPartsList = useMemo(() => JSON.parse(sessionStorage.getItem(sessionKeys.Data_SMGTS211) || '[]') as Daily[], [])

    const [partsList, setPartsList] = useState(cachedPartsList)
    const [customerPartsId, setCustomerPartsId] = useState<number>(partsList[0].customerPartsId)
    const [includePlanFlag, setIncludePlanFlag] = useState<boolean>(partsList[0].includePlanFlag ?? false)
    const [baseCarrierDateFlag, setBaseCarrierDateFlag] = useState<boolean>(partsList[0].baseCarrierDateFlag ?? true)
    const simulateByParts = useMemo(() => partsList[0].simulateByParts ?? false, [partsList])

    const [today] = useState(new Date())
    const [filters, setFilters] = useState<DailyRundownFactor>({ includePlanFlag: true, stockDate: today })
    const [data, setData] = useState<DailyRundownResult>({} as DailyRundownResult)
    const getDailyRundownStock = useGetDailyRundownStock()

    const search = useCallback((includePlanFlag: boolean, baseCarrierDateFlag: boolean, customerPartsId: number, filters: DailyRundownFactor) => {
        getDailyRundownStock({ ...filters, customerPartsIdList: [customerPartsId], includePlanFlag: includePlanFlag, simulateByParts: simulateByParts, baseCarrierDate: baseCarrierDateFlag }, { silent: true, serialized: true }).then(result => {
            setData(result ?? {} as DailyRundownResult)
        })
    }, [getDailyRundownStock, simulateByParts])

    useEffect(() => {
        search(includePlanFlag, baseCarrierDateFlag, customerPartsId, filters)
    }, [includePlanFlag, customerPartsId, filters, search, baseCarrierDateFlag])

    return <SMGTS211PcUi
        filters={filters}
        data={data}
        setFilters={setFilters}
        partsList={partsList}
        setPartsList={setPartsList}
        customerPartsId={customerPartsId}
        setCustomerPartsId={setCustomerPartsId}
        includePlanFlag={includePlanFlag}
        setIncludePlanFlag={setIncludePlanFlag}
        baseCarrierDateFlag={baseCarrierDateFlag}
        setBaseCarrierDateFlag={setBaseCarrierDateFlag}
    />
}