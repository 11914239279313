import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { Projects } from "../../../utils/Projects"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { CalendarFactor, calendarFactorToJson } from "../models/CalendarFactor"
import { CalendarGetDateFactor, calendarGetDateFactorToJson } from "../models/CalendarGetDateFactor"
import { CalendarGetDateResult, calendarGetDateResultFromJson } from "../models/CalendarGetDateResult"
import { CalendarResult, calendarResultFromJson } from "../models/CalendarResult"
import { SaveCalendarWorkingDateFactor, saveCalendarWorkingDateFactorToJson } from "../models/SaveCalendarWorkingDateFactor"
import { TnmCalendar, tnmCalendarFromJson } from "../models/TnmCalendar"

export const useGetCalendarList = () => {
    const url = '/lcbm-system-master-api/api/getCalendarList'
    const payloadProject = useCallback(calendarFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(calendarResultFromJson), [])
    return usePost<CalendarFactor, PageResultEntity<CalendarResult>>(url, payloadProject, resultProject)
}

export const useGetCalendarInfo = () => {
    const url = '/lcbm-system-master-api/api/getCalendarInfo'
    const payloadProject = useCallback((obj: { calendarId: number }) => obj.calendarId, [])
    const resultProject = useCallback(tnmCalendarFromJson, [])
    return usePost<{ calendarId: number }, TnmCalendar>(url, payloadProject, resultProject)
}

export const useGetCalendarWorkDateInfo = () => {
    const url = '/lcbm-system-master-api/api/getCalendarWorkDateInfo'
    const payloadProject = useCallback(calendarGetDateFactorToJson, [])
    const resultProject = useCallback(Projects.array(calendarGetDateResultFromJson), [])
    return usePost<CalendarGetDateFactor, CalendarGetDateResult[]>(url, payloadProject, resultProject)
}

export const useSaveCalendar = () => {
    const url = '/lcbm-system-master-api/api/saveCalendar'
    const payloadProject = useCallback(saveCalendarWorkingDateFactorToJson, [])
    const resultProject = useCallback((calendarId: number) => calendarId, [])
    return usePost<SaveCalendarWorkingDateFactor, number>(url, payloadProject, resultProject)
}