import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Row, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { StringItem } from "@rithe/form"
import { Records } from "@rithe/utils"
import React, { useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { UploadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/UploadGroupedToolbarAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { View } from "../../../components/View/View"
import { useDownloadImpStockAdjustment } from "../../../services/smt/api/smtDownloadApi"
import { useUploadImpStockAdjust } from "../../../services/smt/api/smtUploadApi"
import { StockAdjustmentListFactor } from "../../../services/smt/models/StockAdjustmentListFactor"
import { StockAdjustmentListForCust } from "../../../services/smt/models/StockAdjustmentListForCust"

interface SMGTS410ForCustPcUiProps {
    filters: StockAdjustmentListFactor,
    setFilters: React.Dispatch<React.SetStateAction<StockAdjustmentListFactor>>,
    data: StockAdjustmentListForCust[],
    totalCount: number,
    search: (filters: StockAdjustmentListFactor) => void,
}

export const SMGTS410ForCustPcUi = (props: SMGTS410ForCustPcUiProps) => {
    const intl = useIntl()
    return <View flex>
        <SectionCard>
            <SectionCardHeader title={''} subtitle={intl.formatMessage({ id: 'adjustmentListSub' })} />
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({ filters, data, setFilters, search }: SMGTS410ForCustPcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<string[]>([])

    const getAdjustStockQty = useCallback((row: Row) => (row.impWHStock ?? 0) - (row.impWHOnHold ?? 0) + (row.stockDeltaQty ?? 0) - (row.onholdDeltaQty ?? 0), [])

    const columns = useMemo(() => [
        { field: 'ttcPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.ttcPartsNo' }), width: 200 },
        { field: 'sellerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sellerCode' }), width: 180 },
        { field: 'customerPartsCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.buyerPartsNo' }), width: 240 },
        { field: 'externalReferenceNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.externalReferenceNo' }), width: 240 },
        { field: 'customerBackNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.buyerBackNo' }), width: 200 },
        { field: 'impWHStock', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.impWHStock' }), width: 160 },
        { field: 'impWHOnHold', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.impWHOnHold' }), width: 160 },
        { field: 'stockDeltaQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'stockDeltaQty' }), width: 220 },
        { field: 'onholdDeltaQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'onholdDeltaQty' }), width: 220 },
        { field: 'adjustStockQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'adjustStockQty' }), width: 300, getCellValue: getAdjustStockQty },
    ], [getAdjustStockQty, intl])
    const getRowId = useCallback((row: any) => row.customerPartsId, [])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search }), [filters, setFilters, search])
    const actionProps1 = useMemo(() => ({ search, filters }), [filters, search])
    const actionProps2 = useMemo(() => ({ selections }), [selections])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={UploadAction} actionProps={actionProps1} />
        <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps2} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['customerBackNo']}
            columnSettings={{
                ttcPartsNo: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
    </DataGrid>
}

const FilterItem = (props: { filters: StockAdjustmentListFactor, setFilters: React.Dispatch<React.SetStateAction<StockAdjustmentListFactor>>, search: (filters: StockAdjustmentListFactor) => void }) => {
    const { filters, setFilters, search } = props

    const clear = useCallback((filters: StockAdjustmentListFactor) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: StockAdjustmentListFactor) => {
        return [
            filters.ttcPartsNo,
            filters.customerPartsNo,
            filters.externalReferenceNo,
            filters.customerBackNo,
        ].filter(value => value !== undefined && value !== null).length
    }, [])
    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="ttcPartsNo" label={intl.formatMessage({ id: 'field.ttcPartsNo' })} />
        <StringItem field="customerPartsNo" label={intl.formatMessage({ id: 'field.buyerPartsNo' })} />
        <StringItem field="externalReferenceNo" label={intl.formatMessage({ id: 'field.externalReferenceNo' })} />
        <StringItem field="customerBackNo" label={intl.formatMessage({ id: 'field.buyerBackNo' })} />
    </FilterToolbarItem>
}


const UploadAction = ({ search, filters }: { search: (filters: StockAdjustmentListFactor) => void, filters: StockAdjustmentListFactor }) => {
    const uploadMasterApi = useUploadImpStockAdjust()
    const uploadMaster = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            files && uploadMasterApi({ file: files[0] }, { serialized: true }).then(() => search(filters))
        })
    }, [filters, search, uploadMasterApi])
    return <UploadGroupedToolbarAction access='STCK.SMGTS410.UPLOAD'>
        {(popupUpload, onClose) => <>
            <GroupedCallbackItem label={<FormattedMessage id="uploadMaster" />} callback={() => uploadMaster(popupUpload)} onClose={onClose} />
        </>}
    </UploadGroupedToolbarAction>
}

const DownloadAction = ({ selections }: { selections: string[] }) => {
    const downloadApi = useDownloadImpStockAdjustment()
    const download = useCallback(() => downloadApi({ partList: selections }), [downloadApi, selections])
    return <DownloadGroupedToolbarAction access='STCK.SMGTS410.DOWNLOAD' >
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="download" />} callback={download} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}