import { Project } from "../../../utils/Project"
import { dateToJson } from "../../utils/serializer"

export type DownloadOubPriorityView = Partial<Optional>
type DownloadOubPriorityViewJson = Partial<JsonOptional>
export const downloadOubPriorityViewToJson: Project<DownloadOubPriorityView, DownloadOubPriorityViewJson> = obj => ({
    ...obj,
    onbPlanDateStartFormat: dateToJson(obj.onbPlanDateStartFormat),
    onbPlanDateEndFormat: dateToJson(obj.onbPlanDateEndFormat),
    rcvrReqInbPlanDateStartFormat: dateToJson(obj.rcvrReqInbPlanDateStartFormat),
    rcvrReqInbPlanDateEndFormat: dateToJson(obj.rcvrReqInbPlanDateEndFormat),
    suggestDateStartFormat: dateToJson(obj.suggestDateStartFormat),
    suggestDateEndFormat: dateToJson(obj.suggestDateEndFormat),
})

interface Optional extends Omit<JsonOptional, 'onbPlanDateStartFormat' | 'onbPlanDateEndFormat' | 'rcvrReqInbPlanDateStartFormat' | 'rcvrReqInbPlanDateEndFormat'
    | 'suggestDateStartFormat' | 'suggestDateEndFormat'> {
    onbPlanDateStartFormat: Date,
    onbPlanDateEndFormat: Date,
    rcvrReqInbPlanDateStartFormat: Date,
    rcvrReqInbPlanDateEndFormat: Date,
    suggestDateStartFormat: Date,
    suggestDateEndFormat: Date,
}

interface JsonOptional {

    supplierId: number,
    onbPlanDateStartFormat: string,
    onbPlanDateEndFormat: string,
    rcvrReqInbPlanDateStartFormat: string,
    rcvrReqInbPlanDateEndFormat: string,
    suggestDateStartFormat: string,
    suggestDateEndFormat: string,
    salesOrderNo: string,
    receiverUid: string,
    customerId: number,
    partsNo: string,
    downloadType: number,
}