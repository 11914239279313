import React, { useCallback, useEffect, useRef } from "react"
import { useComMasterGetAllCbds } from "../../../services/common/apis/commonMasterApi"
import { useListCargoTracking } from "../../../services/delivery/apis/deliveryCargoTrackingApi"
import { CargoTrackingFactor } from "../../../services/delivery/models/CargoTrackingFactor"
import { CargoTrackingView } from "../../../services/delivery/models/CargoTrackingView"
import { useGetPortList } from "../../../services/master/apis/portApi"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { TnmPort } from "../../../services/master/models/TnmPort"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { LCS010PcUi } from "./LCS010PcUi"

export const LCS010 = () => {
    const [filters, setFilters] = React.useState<CargoTrackingFactor>({})
    const [data, setData] = React.useState<CargoTrackingView[]>([])
    const [totalCount, setTotalCount] = React.useState(0)
    const [sellerList, setSellerList] = React.useState<TnvCbds[]>([])
    const [portList, setPortList] = React.useState<TnmPort[]>([])

    const getData = useListCargoTracking()
    const getSeller = useComMasterGetAllCbds()
    const getPort = useGetPortList()

    const search = useCallback((filters: CargoTrackingFactor) => {
        getData(filters, { serialized: true, silent: true }).then(data => {
            setData(data.data ?? [])
            setTotalCount(data.page?.totalCount ?? 0)
        })
    }, [getData])
    const loadedRef = useRef(false)
    useEffect(() => {
        if (!loadedRef.current) {
            search(filters)
            getSeller({ types: [CbdsType.BU, CbdsType.SUPP] }, { serialized: true, silent: true }).then(data => {
                setSellerList(data ?? [])
            })
            getPort({}, { serialized: true, silent: true }).then(data => {
                setPortList((data.data ?? []).map(result => ({ ...result, portCode: result.portCode! })))
            })
            loadedRef.current = true
        }
    }, [filters, getPort, getSeller, search])

    return <LCS010PcUi
        filters={filters}
        setFilters={setFilters}
        search={search}
        data={data}
        totalCount={totalCount}
        sellerList={sellerList}
        portList={portList}
    />
}