import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"

export type CreditLimitResult = Required & Partial<Optional>
type CreditLimitResultJson = JsonRequired & Partial<JsonOptional>


export const creditLimitResultFromJson: Project<CreditLimitResultJson, CreditLimitResult> = json => ({
    ...(json),
    openArList: json.openArList?.map(arCreditCheckFromJson),
    uninvoicedOrderList: json.uninvoicedOrderList?.map(orderCreditCheckFromJson),
    drOfCurrentOrderList: json.drOfCurrentOrderList?.map(drDataFromJson),

})

interface Required extends JsonRequired {

}

interface Optional extends Omit<JsonOptional, 'openArList' | 'uninvoicedOrderList' | 'drOfCurrentOrderList'> {
    openArList: ArCreditCheckResult[],
    uninvoicedOrderList: OrderCreditCheckResult[],
    drOfCurrentOrderList: DrDataResult[],

}

interface JsonRequired {

}

interface JsonOptional {
    dataId: number,
    creditLimitAmount: number,
    checkedCreditLimitAmount: number,
    drOfCurrentOrderList: DrDataJson[],
    openArList: ArCreditCheckJson[],
    uninvoicedOrderList: OrderCreditCheckJson[],
}

interface DrDataJson {
    dataId: number,
    drDate: string,
    amount: number,
}

export interface DrDataResult extends Omit<DrDataJson, 'drDate'> {
    drDate: Date,
}

const drDataFromJson: Project<DrDataJson, DrDataResult> = json => ({
    ...json,
    drDate: dateFromJson(json.drDate),
})

interface ArCreditCheckJson {
    creditCheckId: number,
    invId: number,
    documnetType: number,
    documnetNo: string,
    documentDate: string,
    amount: number,
    createdDate: string,
    createdBy: string,
    updatedDate: string,
    updateBy: string,
    version: number,
}
export interface ArCreditCheckResult extends Omit<ArCreditCheckJson, 'documentDate' | 'createdDate' | 'updatedDate'> {
    documentDate: Date,
    createdDate: Date,
    updatedDate: Date,
}

const arCreditCheckFromJson: Project<ArCreditCheckJson, ArCreditCheckResult> = json => ({
    ...json,
    documentDate: dateFromJson(json.documentDate),
    createdDate: dateFromJson(json.createdDate),
    updatedDate: dateFromJson(json.updatedDate),
})

interface OrderCreditCheckJson {
    creditCheckId: number,
    soId: number,
    documnetType: number,
    documnetNo: string,
    documentDate: string,
    amount: number,
    createdDate: string,
    createdBy: string,
    updatedDate: string,
    updateBy: string,
    version: number,
}

export interface OrderCreditCheckResult extends Omit<OrderCreditCheckJson, 'documentDate' | 'createdDate' | 'updatedDate'> {
    documentDate: Date,
    createdDate: Date,
    updatedDate: Date,
}

const orderCreditCheckFromJson: Project<OrderCreditCheckJson, OrderCreditCheckResult> = json => ({
    ...json,
    documentDate: dateFromJson(json.documentDate),
    createdDate: dateFromJson(json.createdDate),
    updatedDate: dateFromJson(json.updatedDate),
})