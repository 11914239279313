import { useCallback } from "react"
import { Projects } from "../../../utils/Projects"
import { usePost } from "../../../utils/fetch/useFetch"
import { FavouritePageAPIRequest, favouritePageAPIRequestToJson } from "../models/FavouritePageAPIRequest"

export const useAddFavouritePage = () => {
    const url = '/common-system-api/user-pages/favorites/create'
    const payloadProject = useCallback(favouritePageAPIRequestToJson, [])
    const resultProject = Projects.IGNORE
    return usePost<FavouritePageAPIRequest, undefined>(url, payloadProject, resultProject)
}

export const useAddFavouritePageList = () => {
    const url = '/common-system-api/user-pages/favorites/batch-create'
    const payloadProject = useCallback(Projects.array(favouritePageAPIRequestToJson), [])
    const resultProject = Projects.IGNORE
    return usePost<FavouritePageAPIRequest[], undefined>(url, payloadProject, resultProject)
}

export const useRemoveFavouritePage = () => {
    const url = '/common-system-api/user-pages/favorites/remove'
    const payloadProject = useCallback(favouritePageAPIRequestToJson, [])
    const resultProject = Projects.IGNORE
    return usePost<FavouritePageAPIRequest, undefined>(url, payloadProject, resultProject)
}

export const useRemoveFavouritePageList = () => {
    const url = '/common-system-api/user-pages/favorites/batch-remove'
    const payloadProject = useCallback(Projects.array(favouritePageAPIRequestToJson), [])
    const resultProject = Projects.IGNORE
    return usePost<FavouritePageAPIRequest[], undefined>(url, payloadProject, resultProject)
}

