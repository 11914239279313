import { Project } from "@rithe/utils";
import { Detail, DetailJson } from "./CustomsInvoice";

export interface CustomsInvoiceFta {
    invoiceId: number,
    invoiceNo: string,
    details: Detail[],
}

interface CustomsInvoiceFtaJson {
    invoiceId: number,
    invoiceNo: string,
    details: DetailJson[]
}

export const customsInvoiceFtaSerializer: Project<CustomsInvoiceFta, CustomsInvoiceFtaJson> = obj => ({
    ...obj
})