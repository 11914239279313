import { DialogActions, DialogContent, makeStyles, Typography, useTheme } from "@material-ui/core"
import { Column, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Editing, Filtering, NumberTypeProvider, PaginationLayout, Row, Searching, Sorting, StringTypeProvider, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarLayout } from "@rithe/data-grid"
import { NumberFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/NumberFormatter"
import { StringFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/StringFormatter"
import { Break, DateItem, Form, Message, NumberItem, StringItem } from "@rithe/form"
import { GridContainer, GridItem } from "@rithe/ui"
import { Arrays, Records } from "@rithe/utils"
import moment from "moment"
import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { DownloadCallbackCardAction } from "../../../components/Action/DownloadCallbackCardAction"
import { IssueCallbackViewAction } from "../../../components/Action/IssueCallbackViewAction"
import { SaveCallbackViewAction } from "../../../components/Action/SaveCallbackViewAction"
import { UploadCallbackCardAction } from "../../../components/Action/UploadCallbackCardAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CreateCallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/CreateCallbackToolbarAction"
import { PercentTypeProvider } from "../../../components/DataGrid/typeProviders/PercentTypeProvider"
import { DarkDialog } from "../../../components/Dialog/DarkDialog"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { View } from "../../../components/View/View"
import { useFunctionStore } from "../../../Root"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { OrderLotFlag } from "../../../services/master/enums/OrderLotFlag"
import { useSaveAndIssueChangeSuppOrder, useSaveChangeSuppOrder } from "../../../services/order/apis/ChangeRequestApi"
import { useDownloadSuppOrderChange } from "../../../services/order/apis/OrderDownloadApi"
import { useUploadSuppOrderChange } from "../../../services/order/apis/OrderUploadApi"
import { RoStatus } from "../../../services/order/enums/RoStatus"
import { OutboundPlanDetail, PartsDetail } from "../../../services/order/models/SChangeOrderDetailResult"
import { useGetCodeName } from "../../../utils/CodeCategoryUtil"
import { formatDateRange } from "../../../utils/formatDateRange"
import { applicationActions } from "../../Application/applicationSlice"
import { useShippingPlanCategory } from "../ONS011/ShippingPlanCategory"
import { ChangeOrderBasic, useMergeDatas, useSplitDatas } from "./ORS021"

interface ORS021PcUiProps {
    soId: number,
    suppRoId: number,
    basic: ChangeOrderBasic,
    setBasic: React.Dispatch<React.SetStateAction<ChangeOrderBasic>>,
    partsDetails: PartsDetail[],
    setPartsDetails: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
    mode: ScreenMode,
}

export const ORS021PcUi = (props: ORS021PcUiProps) => {

    const { soId, suppRoId, basic, setBasic, partsDetails, setPartsDetails, mode } = props
    const { targetFirstDate, targetLastDate } = basic
    const intl = useIntl()
    const editable = useMemo(() => mode !== ScreenMode.VIEW && (basic && !basic.reviseStatus && basic.reviseStatus ? basic.reviseStatus === RoStatus.DRAFT : true), [basic, mode])
    const actions = usePrepareActions(basic, partsDetails, mode)
    return (
        <View actions={actions}>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={1}
                    title={intl.formatMessage({ id: 'step1OfOrderChange' })}
                    subtitle=""
                />
                <SectionCardContent>
                    <Step1DownloadUploadCard soId={soId} suppRoId={suppRoId} editable={editable} basic={basic} setBasic={setBasic} partsDetails={partsDetails} setPartsDetails={setPartsDetails} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={2}
                    title={intl.formatMessage({ id: 'step2OfOrderChange' })}
                    subtitle=""
                />
                <SectionCardContent>
                    <Step2FirmTable editable={editable} targetFirstDate={targetFirstDate} targetLastDate={targetLastDate} basic={basic} partsDetails={partsDetails} setPartsDetails={setPartsDetails} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={3}
                    title={intl.formatMessage({ id: 'step3OfOrderChange' })}
                    subtitle=""
                />
                <SectionCardContent>
                    <Step3ShippingPlanTable editable={editable} partsDetails={partsDetails} setBasic={setBasic} setPartsDetails={setPartsDetails} mode={mode} basic={basic} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={4}
                    title={intl.formatMessage({ id: 'step4OfOrderChange' })}
                    subtitle=""
                />
                <SectionCardContent>
                    <Step4BasicInfoPanelCard basic={basic} setBasic={setBasic} mode={mode} />
                </SectionCardContent>
            </SectionCard>
        </View >
    )
}

interface Step1Props {
    soId: number,
    suppRoId: number,
    editable: boolean,
    basic: ChangeOrderBasic,
    setBasic: React.Dispatch<React.SetStateAction<ChangeOrderBasic>>,
    partsDetails: PartsDetail[],
    setPartsDetails: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
}
const Step1DownloadUploadCard = memo((props: Step1Props) => {
    const { editable } = props
    const theme = useTheme()
    return (
        <GridContainer columnWidth={[500, 200]} style={{ paddingLeft: theme.spacing(5) }} rowGap={theme.spacing(2)}>
            <GridItem style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='body1'><FormattedMessage id='downloadOcInStep1' /></Typography>
            </GridItem>
            <GridItem >
                {editable ? <DownloadAction basic={props.basic} partsDetails={props.partsDetails} /> : <></>}
            </GridItem>
            <GridItem style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='body1'><FormattedMessage id='uplaodOcInStep1' /></Typography>
            </GridItem>
            <GridItem >
                {editable ? <UploadAction {...props} /> : <></>}
            </GridItem>
        </GridContainer>
    )
})

interface Step2Props {
    targetFirstDate: Date,
    targetLastDate: Date,
    editable: boolean,
    basic: ChangeOrderBasic,
    partsDetails: PartsDetail[],
    setPartsDetails: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
}
const Step2FirmTable = memo((props: Step2Props) => {
    const { targetFirstDate, targetLastDate, basic, editable, partsDetails, setPartsDetails } = props
    const intl = useIntl()

    const getOrderLotCheck = useCallback((row: Row) => {
        return (row.orderlotFlag === OrderLotFlag.N || !row.oldFirmQty || !row.orderLot) ? 'N/A' : (Math.round(row.firmQty * 1000000) % Math.round(row.orderLot * 1000000) === 0 ? 'OK' : 'NG')
    }, [])
    const getFlctuationFirm = useCallback((row: Row) => {
        return (row.firmQty != null && row.lastOrderForecast1) ? (row.firmQty - row.lastOrderForecast1) / row.lastOrderForecast1 : null
    }, [])
    const getCodeValue = useCallback((row: Row) => {
        return basic.customerId != null ? basic.customerCode : basic.buyerCode
    }, [basic.buyerCode, basic.customerCode, basic.customerId])
    const codeType = basic.customerId != null ? "field.customerCode" : "buyerCode"

    const columns = useMemo(() => {
        const fixedColumns = [
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
            { field: 'partsDescription', dataTypeName: 'string', title: intl.formatMessage({ id: 'partsDescription' }), width: 300 },
            { field: 'customerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'customerPartsNo' }), width: 220 },
            { field: 'supplierPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'supplierPartsNo' }), width: 220 },
            { field: 'impCountry', dataTypeName: 'string', title: intl.formatMessage({ id: 'impCountry' }), width: 180 },
            { field: 'code', dataTypeName: 'string', title: intl.formatMessage({ id: codeType }), width: 200, getCellValue: getCodeValue },
            { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 150 },
        ] as Column[]
        const categories = [{ key: 'c', value: formatDateRange(intl, targetFirstDate, targetLastDate) }]
        const firmColumns = [
            { field: 'fluctuationRate', dataTypeName: 'percent', title: intl.formatMessage({ id: 'fluctuationRate' }), width: 200 },
            { field: 'oldFirmQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'oldFirmQty' }), categories, width: 180 },
            { field: 'firmQty', dataTypeName: 'firmQty', title: intl.formatMessage({ id: 'firmQty' }), categories, width: 180 },
            { field: 'orderLotCheck', dataTypeName: 'orderLotCheck', title: intl.formatMessage({ id: 'orderLotCheck' }), categories, width: 200, getCellValue: getOrderLotCheck },
            { field: 'lastOrderForecast1', dataTypeName: 'number', title: intl.formatMessage({ id: 'lastOrderForecast' }) + " 1", categories, width: 230 },
            { field: 'fluctuation', dataTypeName: 'fluctuation', title: intl.formatMessage({ id: 'fluctuation' }), categories, width: 150, getCellValue: getFlctuationFirm },
            { field: 'fluctuationReason', dataTypeName: 'string', title: intl.formatMessage({ id: 'reasonForFluctuation' }), categories, width: 250 },
        ] as Column[]
        // all columns
        return Arrays.concat(fixedColumns, firmColumns)
    }, [codeType, getCodeValue, getFlctuationFirm, getOrderLotCheck, intl, targetFirstDate, targetLastDate])

    const onEditingCellCommit = useCallback((_column: Column, row: Row) => {
        setPartsDetails(partsDetails => partsDetails.map(item => item.partsId === row.partsId ? row as PartsDetail : item))
        return true
    }, [setPartsDetails])



    const defaultEditDisabled = Records.from(columns.filter(({ field }) => field !== 'firmQty').map(({ field }) => [field, { editingDisabled: true }]))

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <FirmQtyTypeProvider />
            <PercentTypeProvider />
            <CheckTypeProvider />
            <FluctuationTypeProvider />
            <Data rows={partsDetails} columns={columns} />
            <ColumnFreeze />
            <ColumnVisibility ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Editing
                enableInlineEdit={editable ? true : false}
                onEditingCellCommit={onEditingCellCommit}
                columnSettings={defaultEditDisabled}
            />
            <Sorting />
            <Filtering />
        </DataGrid>
    </div>
})


interface Step3Props {
    setBasic: React.Dispatch<React.SetStateAction<ChangeOrderBasic>>,
    partsDetails: PartsDetail[],
    editable: boolean,
    setPartsDetails: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
    mode: ScreenMode,
    basic: ChangeOrderBasic,
}

const Step3ShippingPlanTable = memo((props: Step3Props) => {
    const { partsDetails, setPartsDetails, editable, mode, basic } = props
    const intl = useIntl()
    const [order, setOrder] = useState<string[]>([])
    const [actions, setActions] = useState<RequestProps>({ open: false, editDate: null })

    const planDates = useMemo(() => {
        return partsDetails ? Arrays.distinct(partsDetails.flatMap(parts => parts.oldOutboundPlanList).map(plan => plan.drDate.getTime())).sort().map(m => new Date(m)) : []
    }, [partsDetails])
    const newPlanDates = useMemo(() => {
        return partsDetails ? Arrays.distinct(partsDetails.flatMap(parts => parts.newOutboundPlanList).map(plan => plan.drDate.getTime())).sort().map(m => new Date(m)) : []
    }, [partsDetails])

    const getPlanQty = useCallback((planDate: Date | null) => (row: any) => {
        if (row.oldOutboundPlanList && planDate) {
            const plans: [OutboundPlanDetail] = row.oldOutboundPlanList.filter((f: OutboundPlanDetail) => (f.drDate.getTime() === planDate.getTime()))
            if (plans && plans.length > 0) {
                let qty = 0;
                plans.forEach(o => {
                    qty = qty + (o.drQty === null ? 0 : o.drQty);
                })
                return qty
            }
        }
        return null
    }, [])
    const getNewPlanQty = useCallback((planDate: Date | null) => (row: any) => {
        if (row.newOutboundPlanList) {
            const plans: [OutboundPlanDetail] = row.newOutboundPlanList.filter((f: OutboundPlanDetail) => (planDate && f.drDate.getTime() === planDate.getTime()))
            if (plans && plans.length > 0) {
                return plans[0].drQty
            }
        }
        return null
    }, [])

    const setNewPlanQty = useCallback((planDate: Date | null) => (row: Row, value: any) => {
        // do fiter
        if (row.newOutboundPlanList?.some((plan: any) => (planDate && plan.drDate.getTime() === planDate.getTime()))) {
            return ({ ...row, newOutboundPlanList: row.newOutboundPlanList?.map((plan: any) => (planDate && plan.drDate.getTime() === planDate.getTime()) ? { ...plan, drQty: value } : plan) })
        } else {
            const newOutboundPlanList = row.newOutboundPlanList
            newOutboundPlanList.push({
                drDate: planDate,
                drQty: value,
            })
            return ({ ...row, newOutboundPlanList: newOutboundPlanList })
        }
    }, [])

    const getCodeValue = useCallback((row: Row) => {
        return basic.customerId != null ? basic.customerCode : basic.buyerCode
    }, [basic.buyerCode, basic.customerCode, basic.customerId])
    const codeType = basic.customerId != null ? "field.customerCode" : "buyerCode"

    const columns = useMemo(() => {
        const fixedColumns = [
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
            { field: 'partsDescription', dataTypeName: 'string', title: intl.formatMessage({ id: 'partsDescription' }), width: 300 },
            { field: 'customerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'customerPartsNo' }), width: 220 },
            { field: 'supplierPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'supplierPartsNo' }), width: 220 },
            { field: 'impCountry', dataTypeName: 'string', title: intl.formatMessage({ id: 'impCountry' }), width: 180 },
            { field: 'code', dataTypeName: 'string', title: intl.formatMessage({ id: codeType }), width: 200, getCellValue: getCodeValue },
            { field: 'uomCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.uomCode' }), width: 150 },
            { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 150 },
        ]
        const firmColumns = [
            { field: 'firmQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'firmQty' }), width: 180 },
        ]

        const planCategories = (index: number) => [
            { key: 'old', value: intl.formatMessage({ id: 'oldOutboundPlan' }) },
            { key: `old${index}`, value: intl.formatDate(planDates[index], { dateStyle: 'medium' }) }
        ]
        const planColumns = planDates.map((plan, idx) => ({
            field: 'planQty' + idx,
            dataTypeName: 'number',
            title: 'Qty',
            categories: planCategories(idx),
            width: 250,
            getCellValue: getPlanQty(plan)
        }))
        const getPlanCategories = (index: number) => [
            { key: 'plan', value: intl.formatMessage({ id: 'newOutboundPlan' }) },
            { key: `plan${index}`, value: intl.formatDate(newPlanDates[index], { dateStyle: 'medium' }) }
        ]
        const newPlanColumns = newPlanDates.map((plan, idx) => ({
            field: 'newPlanQty' + idx,
            dataTypeName: 'shippingPlan',
            title: 'Qty',
            categories: getPlanCategories(idx),
            width: 250,
            getCellValue: getNewPlanQty(plan),
            setCellValue: setNewPlanQty(plan)
        }))
        return Arrays.concat(fixedColumns, firmColumns, planColumns, newPlanColumns)
    }, [codeType, getCodeValue, getNewPlanQty, getPlanQty, intl, newPlanDates, planDates, setNewPlanQty])


    const onEditingCellCommit = useCallback((_cloumn: Column, row: Row) => {
        setPartsDetails(partsDetails => partsDetails.map(item => item.partsId === row.partsId ? row as PartsDetail : item))
        return true
    }, [setPartsDetails])

    const defaultEditDisabled = Records.from(columns.filter(f => !f.field.includes('newPlanQty')).map(({ field }) => [field, { editingDisabled: true }]))
    const AddnewAction = useMemo(() => ({ setActions }), [setActions])
    const clickHeader = useCallback((field: string) => {
        field.startsWith("newPlanQty") && setActions({ open: true, editDate: newPlanDates[Number(field.substring(10))] })
    }, [newPlanDates])
    const shippingPlanCategory = useShippingPlanCategory(clickHeader, mode !== ScreenMode.VIEW, intl.formatMessage({ id: 'newOutboundPlan' }))



    useEffect(() => {
        setOrder(columns.map(column => column.field))
    }, [columns])

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <NumberTypeProvider name="shippingPlan" Category={shippingPlanCategory} />
            <Data rows={partsDetails} columns={columns} />
            <ColumnFreeze />
            <ToolbarActionProvider Action={CreateAction} actionProps={AddnewAction} display={() => editable} />
            <ColumnVisibility ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnOrdering order={order} onOrderChange={setOrder} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Editing
                enableInlineEdit={editable ? true : false}
                onEditingCellCommit={onEditingCellCommit}
                columnSettings={defaultEditDisabled}
            />
            <Sorting />
            <Filtering />
        </DataGrid>
        <AddNewRequestDialog actions={actions} setActions={setActions} newOutboundPlan={partsDetails} setNewOutboundPlan={setPartsDetails} />
    </div>
})

const CreateAction = ({ setActions }: { setActions: React.Dispatch<React.SetStateAction<RequestProps>> }) => {
    return <CreateCallbackToolbarAction title={<FormattedMessage id="Add New Plan" />} callback={() => setActions({ open: true, editDate: null })} />
}

interface RequestProps {
    open: boolean,
    editDate: Date | null
}

const AddNewRequestDialog = ({ actions, setActions, newOutboundPlan, setNewOutboundPlan }: {
    actions: RequestProps,
    setActions: React.Dispatch<React.SetStateAction<RequestProps>>,
    newOutboundPlan: PartsDetail[],
    setNewOutboundPlan: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
}) => {
    const dispatch = useDispatch()
    const { open, editDate } = actions
    const [factor, setFactor] = useState<{ newOutboundPlanDate: Date | null }>({ newOutboundPlanDate: null })
    const handleClose = useCallback(() => setActions({ open: false, editDate: null }), [setActions])

    const intl = useIntl()

    useEffect(() => {
        setFactor({ newOutboundPlanDate: editDate })
    }, [editDate])

    const aplyDate = useCallback(() => {
        if (!factor.newOutboundPlanDate) {
            dispatch(applicationActions.pushError({ title: { code: 'confirm' }, messages: { code: 'w0001', args: ['newOutboundPlan'] } }))
            return
        } else {
            const newOutboundPlanDate = factor.newOutboundPlanDate
            if (newOutboundPlan.flatMap(f => f.newOutboundPlanList).some(s => moment(s.drDate).format(moment.HTML5_FMT.DATE) === moment(newOutboundPlanDate).format(moment.HTML5_FMT.DATE))
                && (!editDate || moment(editDate).format(moment.HTML5_FMT.DATE) !== moment(newOutboundPlanDate).format(moment.HTML5_FMT.DATE))) {
                dispatch(applicationActions.pushError({ title: { code: 'confirm' }, messages: { code: 'w0381' } }))
                return
            } else {
                setNewOutboundPlan(newOutboundPlan => newOutboundPlan.map(m => ({
                    ...m, newOutboundPlanList: !editDate ? [...m.newOutboundPlanList, {
                        drDate: newOutboundPlanDate,
                        drQty: null
                    }] : m.newOutboundPlanList.map(plan => (plan.drDate.getTime() === editDate.getTime()) ? { ...plan, drDate: newOutboundPlanDate } : plan)
                })))
            }
        }
        // do reflect
        setFactor({ newOutboundPlanDate: null })
        setActions({ open: false, editDate: null })
    }, [dispatch, editDate, factor.newOutboundPlanDate, newOutboundPlan, setActions, setNewOutboundPlan])

    return <>
        <DarkDialog open={open} style={{ overflow: 'hidden' }} maxWidth="sm" fullWidth keepMounted={false} fullScreen={false}>
            <DialogHeader onClose={handleClose}><FormattedMessage id="inputOutboundDate" /></DialogHeader>
            <DialogContent>
                <Form data={factor} setData={setFactor} labelDisplay="block" helperDisplay="tooltip" columnCount={1} minWidth={500} maxWidth={500}  >
                    <DateItem required field="newOutboundPlanDate" labelWidth={120} label={intl.formatMessage({ id: 'newOutboundPlan' })} />
                </Form>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={handleClose} />
                <DialogAction title={<FormattedMessage id="confirm" />} callback={aplyDate} />
            </DialogActions>
        </DarkDialog>
    </>
}


const Step4BasicInfoPanelCard = memo(({ basic, setBasic, mode }: { basic: ChangeOrderBasic, setBasic: React.Dispatch<React.SetStateAction<ChangeOrderBasic>>, mode: ScreenMode }) => {

    const { getCodeName } = useGetCodeName()
    const intl = useIntl()
    const editable = useMemo(() => mode !== ScreenMode.VIEW && (basic && !basic.reviseStatus && basic.reviseStatus ? basic.reviseStatus === RoStatus.DRAFT : true), [basic, mode])

    const shippingMode = useMemo(() => getCodeName(CodeCategory.ShippingMode, basic.shippingMode), [getCodeName, basic.shippingMode])
    const orderRange = useMemo(() => formatDateRange(intl, basic.orderFirstDate, basic.orderLastDate), [basic.orderFirstDate, basic.orderLastDate, intl])
    const [messages, setMessages] = useState<Message[]>([])
    const filedCheck = useMemo(() => {
        return (field: string, value: any) => []
    }, [])

    return (
        <Form data={basic} setData={setBasic} labelDisplay="block" helperDisplay="tooltip" messages={messages} setMessages={setMessages} ensure={filedCheck}>
            <StringItem field="customerRefNo" readonly={true} label={intl.formatMessage({ id: 'cOrderReference' })} />
            <StringItem field="contractNo" readonly={true} label={intl.formatMessage({ id: 'field.contractNo' })} />
            <Break />
            <StringItem field="buyerCode" readonly={true} label={intl.formatMessage({ id: 'buyer' })} />
            <NumberItem field="totalAmount" readonly={true} label={intl.formatMessage({ id: 'totalAmount' })} suffix={basic.currency} />
            <Break />
            <StringItem field="deliveryToCode" readonly={true} label={intl.formatMessage({ id: 'deliveryToCode' })} />
            <NumberItem field="totalNumberOfParts" readonly={true} label={intl.formatMessage({ id: 'totalNumberOfParts' })} />
            <Break />
            <StringItem field="shippingMode" readonly={true} label={intl.formatMessage({ id: 'field.shippingMode' })} getValue={() => shippingMode} />
            <NumberItem field="totalQty" readonly={true} label={intl.formatMessage({ id: 'totalQty' })} />
            <Break />
            <StringItem field="orderRange" readonly={true} label={intl.formatMessage({ id: 'orderRange' })} getValue={() => orderRange} />
            <StringItem field="deliveryPlanRange" readonly={true} label={intl.formatMessage({ id: 'deliveryPlanRange' })} />
            <Break />
            <StringItem field="paymentTermsDescription" readonly={true} label={intl.formatMessage({ id: 'field.paymentTermsDesc' })} colSpan={2} />
            <Break />
            <StringItem field="remark" readonly={!editable} label={intl.formatMessage({ id: 'chanageReason' })} colSpan={2} />
        </Form>
    )
})

const UploadAction = (props: Step1Props) => {
    const { soId, suppRoId, setBasic, setPartsDetails } = props
    const uploadMethod = useUploadSuppOrderChange()
    const splitDatas = useSplitDatas()
    const upload = useCallback((files: FileList | null) => {
        if (files === null) return
        uploadMethod({ file: files[0], soId: soId, suppRoId: suppRoId }, { serialized: true }).then(result => {
            if (result) {
                const { orderbasic, partsDetails } = splitDatas(result)
                setBasic(orderbasic)
                setPartsDetails(partsDetails)
            }
        })
    }, [setBasic, setPartsDetails, soId, splitDatas, suppRoId, uploadMethod])
    return <UploadCallbackCardAction callback={upload} />
}

const DownloadAction = (props: { basic: ChangeOrderBasic, partsDetails: PartsDetail[] }) => {

    const { basic, partsDetails } = props
    const downloadOrderChangeBySupplier = useDownloadSuppOrderChange()
    const mergeDatas = useMergeDatas()
    const download = useCallback(() => {
        const data = mergeDatas(basic, partsDetails)
        downloadOrderChangeBySupplier(data)
    }, [basic, downloadOrderChangeBySupplier, mergeDatas, partsDetails])

    return <DownloadCallbackCardAction outlined callback={download} />
}

const usePrepareActions = (basic: ChangeOrderBasic, partsDetails: PartsDetail[], mode: ScreenMode) => {
    const saveChangeSuppOrder = useSaveChangeSuppOrder()
    const saveAndIssueSuppOrder = useSaveAndIssueChangeSuppOrder()
    const navigate = useNavigate()
    const mergeDatas = useMergeDatas()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const issueTitle = useMemo(() => intl.formatMessage({ id: 'Issue' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const onclickToSave = useCallback(() => {
        setDisabled(true)
        const data = mergeDatas(basic, partsDetails)
        saveChangeSuppOrder(data, { serialized: true }).then(result => {
            navigate(`/scro`)// Search?
        }).finally(() => {
            setDisabled(false)
        })
    }, [basic, mergeDatas, navigate, partsDetails, saveChangeSuppOrder])
    const onclickToIssue = useCallback(() => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            const data = mergeDatas(basic, partsDetails)
            saveAndIssueSuppOrder(data, { serialized: true }).then(result => {
                navigate(`/scro`)// Search?
            }).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: issueTitle,
            messages: { code: 'c0001', args: [issueTitle] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [basic, dispatch, functionStore, issueTitle, mergeDatas, navigate, partsDetails, saveAndIssueSuppOrder])
    const editable = useMemo(() => mode !== ScreenMode.VIEW && (basic && !basic.reviseStatus && basic.reviseStatus ? basic.reviseStatus === RoStatus.DRAFT : true), [basic, mode])
    return editable ? [
        <SaveCallbackViewAction access="ORDER.ORS021.SAVE" outlined callback={onclickToSave} disabled={disabled} />,
        <IssueCallbackViewAction access="ORDER.ORS021.ISSUE" callback={onclickToIssue} disabled={disabled} />
    ] : []
}





const FirmQtyFormatter = ({ value }: NumberFormatterProps) => {
    const style = useStyles()
    const bgcolor = '#00CCAD'
    const color = 'white'
    return <div className={style.firmQtyCheck} style={{ background: bgcolor, color: color, width: '100%' }}>
        <Typography variant="body2" >{value}</Typography>
    </div>
}

const FirmQtyTypeProvider = () => {
    return <NumberTypeProvider name="firmQty" Formatter={FirmQtyFormatter} />
}

const CheckFormatter = ({ value }: StringFormatterProps) => {
    const style = useStyles()
    const bgcolor = 'NG' === value ? '#D94C00' : 'N/A' === value ? '#ECEFF2' : '#00CCAD'
    const color = 'N/A' === value ? '' : 'white'
    return <div className={style.orderLotCheck} style={{ background: bgcolor, color: color, width: '100%' }}>
        <Typography variant="body2" >{value}</Typography>
    </div>
}

const CheckTypeProvider = () => {
    return <StringTypeProvider name="orderLotCheck" Formatter={CheckFormatter} />
}

const FluctuationFormatter = ({ value, row, formatter }: NumberFormatterProps) => {
    const style = useStyles()
    const fluctuationRate = row.fluctuationRate
    const displayValue = value === null ? 'N/A' : formatter.format(value)
    const bgcolor = (value === null || fluctuationRate === null) ? '#ECEFF2' : Math.abs(value) > fluctuationRate ? '#D94C00' : '#00CCAD'
    const color = (value === null || fluctuationRate === null) ? '' : 'white'
    return <div className={style.fulcuationCheck} style={{ background: bgcolor, color: color, width: '100%' }}>
        <Typography variant="body2" >{displayValue}</Typography>
    </div>
}

const FluctuationTypeProvider = () => {
    return <NumberTypeProvider name="fluctuation" options={{ style: 'percent', maximumFractionDigits: 0, minimumFractionDigits: 0 }} Formatter={FluctuationFormatter} />
}

const useStyles = makeStyles(theme => ({
    firmQtyCheck: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70%',
        paddingRight: theme.spacing(1),
        borderRadius: 5
    },
    orderLotCheck: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70%',
        paddingRight: theme.spacing(1),
        borderRadius: 5
    },
    fulcuationCheck: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70%',
        paddingRight: theme.spacing(1),
        borderRadius: 5
    },
    default: {},
}))