import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { DateRangeItem, StringItem } from "@rithe/form"
import { Records } from "@rithe/utils"
import React, { useCallback, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { ViewRedirectRowAction } from "../../../components/DataGrid/rowActions/ViewRedirectRowAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { CoListFactor } from "../../../services/order/models/CoListFactor"
import { CoListResult } from "../../../services/order/models/CoListResult"

interface OCS030PcUiProps {
    coList: CoListResult[],
    setCoList: React.Dispatch<React.SetStateAction<CoListResult[]>>,
    filters: CoListFactor,
    setFilters: React.Dispatch<React.SetStateAction<CoListFactor>>,
    search: (filters: CoListFactor) => void
}

export const OCS030PcUi = (props: OCS030PcUiProps) => {

    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({ coList, filters, setFilters, search }: OCS030PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])
    const columns = useMemo(() => [
        { field: 'customerOrderNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'orderNo' }), width: 300 },
        { field: 'orderReference', dataTypeName: 'string', title: intl.formatMessage({ id: 'orderRefNo' }), width: 300 },
        { field: 'orderType', dataTypeName: CodeCategory.OrderType, title: intl.formatMessage({ id: 'orderType' }), width: 300 },
        { field: 'coDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'placeDate' }), width: 300 },
        { field: 'status', dataTypeName: CodeCategory.CoSpotStatus, title: intl.formatMessage({ id: 'field.status' }), width: 300 },
    ], [intl])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search }), [filters, setFilters, search])
    const getRowId = useCallback((row: any) => row.coSpotId, [])
    // const actionPropsView = useCallback(() => ({}), [])
    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar} >
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OrderType} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.CoSpotStatus} />
        <Data rows={coList} columns={columns} getRowId={getRowId} />
        <RowActionProvider name="view" Action={ViewDetail} />
        <DataTypePreset />
        <ColumnFreeze />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={88} />
    </DataGrid>
}

const FilterItem = (props: {
    filters: CoListFactor,
    setFilters: React.Dispatch<React.SetStateAction<CoListFactor>>,
    search: (filters: CoListFactor) => void
}) => {
    const { filters, setFilters, search } = props
    const coDateGetValue = useCallback((filters: CoListFactor) => {
        return [filters.coDateStart ?? null, filters.coDateEnd ?? null]
    }, [])

    const coDateMapValue = useCallback((filters: CoListFactor, value: any) => {
        return { ...filters ?? {}, coDateStart: value[0], coDateEnd: value[1] }
    }, [])

    const clear = useCallback((filters: CoListFactor) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: CoListFactor) => {
        return [
            filters.coDateStart || filters.coDateEnd
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])

    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter} >
        <StringItem field="customerOrderNo" label={intl.formatMessage({ id: 'orderNo' })} />
        <StringItem field="orderReference" label={intl.formatMessage({ id: 'orderRefNo' })} />
        <CodesItem field="orderTypeList" label={intl.formatMessage({ id: 'orderType' })} code={CodeCategory.OrderType} />
        <CodesItem field="statusList" label={intl.formatMessage({ id: 'field.activeFlag' })} code={CodeCategory.CoStatus} />
        <DateRangeItem field="coDate" label={intl.formatMessage({ id: 'placeDate' })} getValue={coDateGetValue} mapValue={coDateMapValue} />
    </FilterToolbarItem>
}


const ViewDetail = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/monitoring/view-${tableRow.rowId}`, [])
    return <ViewRedirectRowAction tableRow={tableRow} access="ORDER.OCS030.VIEWDETAIL" path={path} />
}

