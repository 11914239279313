import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { Action, Column, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Editing, Filtering, KeyTypeProvider, NumberTypeProvider, PaginationLayout, Paging, Row, RowActionProvider, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarLayout } from '@rithe/data-grid'
import { DataGridRowActionProps } from '@rithe/data-grid/dist/components/basic/DataGridRowAction'
import { Break, EntryItem, Form, Message, StringItem } from "@rithe/form"
import { Arrays, Records, arrx } from '@rithe/utils'
import { default as React, memo, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useFunctionStore } from '../../../Root'
import { SaveCallbackViewAction } from '../../../components/Action/SaveCallbackViewAction'
import { SubmitCallbackViewAction } from '../../../components/Action/SubmitCallbackViewAction'
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from '../../../components/DataGrid/components/PageInfo'
import { PageSelect } from '../../../components/DataGrid/components/PageSelect'
import { PageSizeSelect } from '../../../components/DataGrid/components/PageSizeSelect'
import { DeleteCallbackRowAction } from '../../../components/DataGrid/rowActions/DeleteCallbackRowAction'
import { DownloadGroupedToolbarAction } from '../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction'
import { GroupedCallbackItem } from '../../../components/DataGrid/toolbarActions/GroupedCallbackItem'
import { UploadGroupedToolbarAction } from '../../../components/DataGrid/toolbarActions/UploadGroupedToolbarAction'
import { CodeCategoryTypeProvider } from '../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider'
import { PercentTypeProvider } from '../../../components/DataGrid/typeProviders/PercentTypeProvider'
import { ScreenMode } from '../../../services/common/enums/ScreenMode'
import { useDownloadCustomersParts } from '../../../services/master/apis/masterDownloadApi'
import { useUploadCustomerParts } from '../../../services/master/apis/masterUploadApi'
import { useSubmitRequestDetail } from '../../../services/master/apis/requestApi'
import { useSaveRequest } from '../../../services/master/apis/requestDetailApi'
import { CbdsType } from '../../../services/master/enums/CbdsType'
import { CodeCategory } from '../../../services/master/enums/CodeCategory'
import { ContractRequestType } from '../../../services/master/enums/ContaractRequestType'
import { PartsDetailResult } from '../../../services/master/models/PartsDetailResult'
import { RequestHeaderResult } from '../../../services/master/models/RequestHeaderResult'
import { RequestToResult } from "../../../services/master/models/RequestToResult"
import { TnmCurrency } from '../../../services/master/models/TnmCurrency'
import { TnmUom } from '../../../services/master/models/TnmUom'
import { useGetCompanyType, useGetCompanyUid } from '../../../utils/ApplicationUtils'
import { useFieldChecker, useFormValidater } from '../../../utils/ValidatorUtils'
import { applicationActions } from "../../Application/applicationSlice"
import { MOS021PcUiProps } from './MOS021PcUi'
import { PartsListView } from './PartsListView'

export const RequestAddView = (props: MOS021PcUiProps) => {
    const { mode, requestToList, headerInfo, setHeaderInfo, partsList, setPartsList, uomList, currencyList, messages, setMessages } = props
    const intl = useIntl()
    const companyType = useGetCompanyType()
    return (
        <>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={1}
                    step
                    title={intl.formatMessage({ id: 'add_new_parts_title_one' })}
                    subtitle={intl.formatMessage({ id: "add_new_parts_message_one" })}
                />
                <SectionCardContent>
                    <TheFirstDiv mode={mode} requestToList={requestToList} headerInfo={headerInfo} setHeaderInfo={setHeaderInfo} messages={messages} setMessages={setMessages} setPartsList={setPartsList} />
                </SectionCardContent>
            </SectionCard>

            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={2}
                    step
                    title={intl.formatMessage({ id: 'add_new_parts_title_two' })}
                    subtitle={intl.formatMessage({ id: "add_new_parts_message_two" })}
                    actions={(CbdsType.CUST === companyType && mode !== ScreenMode.VIEW) ? <SelectPartsAction headerInfo={headerInfo} partsList={partsList} setPartsList={setPartsList} mode={mode} /> : <></>}
                />
            </SectionCard>

            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={3}
                    step
                    title={intl.formatMessage({ id: 'add_new_parts_title_three' })}
                    subtitle={intl.formatMessage({ id: "add_new_parts_message_three" })}
                    actions={mode !== ScreenMode.VIEW ? [
                        <DownLoadAction partsList={partsList} />,
                        <UploadAction headerInfo={headerInfo} partsList={partsList} setPartsList={setPartsList} />
                    ] : []}
                />
            </SectionCard>

            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={4}
                    step
                    title={intl.formatMessage({ id: 'add_new_parts_title_four' })}
                    subtitle={intl.formatMessage({ id: "add_new_parts_message_four" })}
                />
                <SectionCardContent>
                    <TheFifthDiv mode={mode} headerInfo={headerInfo} partsList={partsList} setPartsList={setPartsList} uomList={uomList} currencyList={currencyList} />
                </SectionCardContent>
            </SectionCard>
        </ >
    )
}

export const CreateRequestAction = ({ headerInfo, partsList, setMessages, search, setHeaderInfo, setPartsList }: {
    headerInfo: RequestHeaderResult,
    partsList: PartsDetailResult[],
    setHeaderInfo: React.Dispatch<React.SetStateAction<RequestHeaderResult>>,
    setPartsList: React.Dispatch<React.SetStateAction<PartsDetailResult[]>>,
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
    search: (requestNo: string) => void
}) => {
    const navigate = useNavigate()
    const fields = useMemo(() => getFormCheckFields(false), [])
    const formValidate = useFormValidater(setMessages, fields)
    const saveRequest = useSaveRequest()
    const dispatch = useDispatch()
    const [disabled, setDisabled] = useState<boolean>(false)
    const clickToSave = useCallback(() => {
        // do check partsList
        if (!formValidate(headerInfo)) {
            return
        }
        // check select
        if (partsList === undefined || partsList === null || partsList.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'createNewRequest' }, messages: { code: 'w0336' } }))
        } else {
            const requestInfo = {
                requestHeader: { ...headerInfo, requestType: ContractRequestType.ADD },
                requestPartsList: [...partsList]
            }
            setDisabled(true)
            saveRequest((requestInfo), { serialized: true }).then((result) => {
                navigate('/requestTo/edit-' + result.requestHeader?.requestNo)
            }).finally(() => {
                setDisabled(false)
            })
        }
    }, [formValidate, headerInfo, partsList, dispatch, saveRequest, navigate])

    return <SaveCallbackViewAction callback={clickToSave} disabled={disabled} />
}

export const SaveRequestAction = ({ headerInfo, partsList, search, setMessages, requestNo, setHeaderInfo, setPartsList }: {
    headerInfo: RequestHeaderResult,
    partsList: PartsDetailResult[],
    setHeaderInfo: React.Dispatch<React.SetStateAction<RequestHeaderResult>>,
    setPartsList: React.Dispatch<React.SetStateAction<PartsDetailResult[]>>,
    search: (requestNo: string) => void,
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
    requestNo: string,
}) => {
    const saveRequest = useSaveRequest()
    const fields = useMemo(() => getFormCheckFields(true), [])
    const formValidate = useFormValidater(setMessages, fields)
    const [disabled, setDisabled] = useState<boolean>(false)
    const clickToSave = useCallback(() => {
        if (formValidate(headerInfo)) {
            setMessages([])
            const requestInfo = {
                requestHeader: { ...headerInfo, requestType: ContractRequestType.ADD },
                requestPartsList: [...partsList]
            }
            setDisabled(true)
            saveRequest((requestInfo), { serialized: true }).then((result) => {
                search(result?.requestHeader?.requestNo ?? requestNo)
            }).finally(() => {
                setDisabled(false)
            })
        }
    }, [formValidate, headerInfo, partsList, requestNo, saveRequest, search, setMessages])
    return <SaveCallbackViewAction outlined callback={clickToSave} disabled={disabled} />
}

export const SubmitAction = ({ headerInfo, setMessages, partsList }: {
    headerInfo: RequestHeaderResult,
    partsList: PartsDetailResult[],
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>
}) => {
    const navigate = useNavigate()
    const submitApi = useSubmitRequestDetail()
    const dispatch = useDispatch()
    const intl = useIntl()
    const title = useMemo(() => intl.formatMessage({ id: 'submit' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const fields = useMemo(() => getFormCheckFields(true), [])
    const formValidate = useFormValidater(setMessages, fields)
    const functionStore = useFunctionStore()
    const submitClick = useCallback(() => {
        if (formValidate(headerInfo)) {
            setMessages([])
            const requestInfo = {
                requestHeader: { ...headerInfo, requestType: ContractRequestType.ADD },
                requestPartsList: [...partsList]
            }
            const functionId = functionStore.register(() => {
                setDisabled(true)
                submitApi((requestInfo), { serialized: true }).then(() => navigate(`/requestTo`)).finally(() => {
                    setDisabled(false)
                })
            })
            dispatch(applicationActions.pushWarning({
                title: title,
                messages: { code: 'c0001', args: [title] },
                actions: [{
                    label: 'CANCEL'
                }, {
                    functionId,
                    label: 'CONFIRM',
                }]
            }))

        }
    }, [formValidate, headerInfo, setMessages, partsList, functionStore, dispatch, title, submitApi, navigate])

    return <SubmitCallbackViewAction callback={submitClick} disabled={disabled} />
}

const SelectPartsAction = ({ headerInfo, partsList, setPartsList, mode }: {
    headerInfo: RequestHeaderResult,
    partsList: PartsDetailResult[],
    setPartsList: React.Dispatch<React.SetStateAction<PartsDetailResult[]>>,
    mode: ScreenMode
}) => {
    const [open, setOpen] = useState<boolean>(false)
    const onOpen = useCallback(() => {
        setOpen(true)
    }, [setOpen])

    const onClose = useCallback(() => setOpen(false), [])
    const selectedPartsList = useMemo(() => partsList.filter(f => f.unitPartsId !== null && f.unitPartsId !== undefined).map(p => p.unitPartsId + '-' + p.contractNo), [partsList])
    const [selectedList, setSelectedList] = useState<PartsDetailResult[]>([])
    const keyIds = useMemo(() => partsList.filter(f => f.unitPartsId !== null && f.unitPartsId !== undefined).map(p => p.unitPartsId).join(","), [partsList])

    const resetPartsList = useCallback(() => {
        if (selectedList.length > 0) {
            setPartsList(partsList => ([...partsList, ...selectedList]))
        }
        setOpen(false)
        setSelectedList([])
    }, [selectedList, setPartsList])

    return <>
        <Button onClick={onOpen} variant="contained" style={{ backgroundColor: '#7bd3fb' }}><FormattedMessage id="selectPart" /></Button >
        <Dialog open={open} style={{ height: '700px', overflow: 'hidden', color: '#fff', }} maxWidth="md" fullWidth>
            <DialogTitle>
                <FormattedMessage id="selectPartsScreen" />
            </DialogTitle>
            <DialogContent>
                <PartsListView requestType={ContractRequestType.ADD} selectedPartsList={selectedPartsList} setSelectedList={setSelectedList} keyIds={keyIds} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained"><FormattedMessage id="close" /></Button>
                <Button autoFocus onClick={resetPartsList} variant="contained" style={{ backgroundColor: '#ffcc99' }}><FormattedMessage id="alert.ok" /></Button>
            </DialogActions>
        </Dialog>
    </>
}

/** Request To */
interface TheFirstDivInfo {
    mode: ScreenMode,
    requestToList: RequestToResult[],
    headerInfo: RequestHeaderResult,
    setHeaderInfo: React.Dispatch<React.SetStateAction<RequestHeaderResult>>
    messages: Message[],
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
    setPartsList: React.Dispatch<React.SetStateAction<PartsDetailResult[]>>,
}
const TheFirstDiv = memo((props: TheFirstDivInfo) => {
    const { mode, requestToList, headerInfo, setHeaderInfo, messages, setMessages, setPartsList } = props
    const intl = useIntl()
    const companyUid = useGetCompanyUid()
    const requestToMap = useMemo(() => requestToList.filter((m) => m.cbdsUid !== companyUid).map(m => [m.cbdsUid, m.cbdsCode] as [string, string]), [companyUid, requestToList])
    const fields = useMemo(() => getFormCheckFields(true), [])
    const filedCheck = useFieldChecker(fields, setMessages)
    const dataChange = useCallback<React.Dispatch<React.SetStateAction<RequestHeaderResult>>>(nextDraftDataFunc => {
        setHeaderInfo(data => {
            const draftData = typeof nextDraftDataFunc === 'function' ? nextDraftDataFunc(data) : nextDraftDataFunc
            if (draftData.requestType !== data.requestType || draftData.requestTo !== data.requestTo) {
                setPartsList([])
            }
            return draftData;
        })
    }, [setHeaderInfo, setPartsList])
    return <Form data={headerInfo} setData={dataChange} labelDisplay="block" helperDisplay="tooltip" messages={messages} setMessages={setMessages} ensure={filedCheck}>
        <EntryItem field="requestTo" required label={intl.formatMessage({ id: 'field.requestTo' })} entries={requestToMap} readonly={mode !== ScreenMode.CREATE} />
        <Break />
        <StringItem field="description" label={intl.formatMessage({ id: 'field.description' })} colSpan={2} rowSpan={4} readonly={mode === ScreenMode.VIEW} />
    </Form>
})

const DownLoadAction = ({ partsList }: {
    partsList: PartsDetailResult[]
}) => {
    const downloadCustomersParts = useDownloadCustomersParts()
    const downLoadMethod = useCallback(() => {
        downloadCustomersParts(partsList)
    }, [downloadCustomersParts, partsList])
    return <DownloadGroupedToolbarAction>
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="downloadPartForm" />} callback={downLoadMethod} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}

const UploadAction = ({ headerInfo, partsList, setPartsList }: {
    headerInfo: RequestHeaderResult,
    partsList: PartsDetailResult[],
    setPartsList: React.Dispatch<React.SetStateAction<PartsDetailResult[]>>,
}) => {
    const uploadCustomerParts = useUploadCustomerParts()
    const uploadMethod = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            if (files) {
                uploadCustomerParts({ file: files[0], requestId: headerInfo.requestId }, { serialized: true }).then((result) => {
                    if (result?.length > 0) {
                        setPartsList(result)
                    } else {
                        setPartsList([])
                    }
                })
            }
        })
    }, [uploadCustomerParts, headerInfo.requestId, setPartsList])

    return <>
        <UploadGroupedToolbarAction>
            {(popupUpload, onClose) => <>
                <GroupedCallbackItem label={<FormattedMessage id="uploadPartForm" />} callback={() => uploadMethod(popupUpload)} onClose={onClose} />
            </>}
        </UploadGroupedToolbarAction>
    </>
}

/** INPUT PART INFO */
const TheFifthDiv = memo(({ mode, headerInfo, partsList, setPartsList, uomList, currencyList }: {
    mode: ScreenMode,
    headerInfo: RequestHeaderResult,
    partsList: PartsDetailResult[],
    setPartsList: React.Dispatch<React.SetStateAction<PartsDetailResult[]>>,
    uomList: TnmUom[],
    currencyList: TnmCurrency[]
}) => {
    const intl = useIntl()
    const companyType = useGetCompanyType()
    const uomArr = useMemo(() => Arrays.distinct(uomList.map((m) => m.uomCode)), [uomList])
    const currencyArr = useMemo(() => currencyList.map((m) => m.currencyCode), [currencyList])
    const columns = useMemo(() => arrx(
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 180 },
        { field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsNo' }), width: 180 },
        { field: 'unitPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsName' }), width: 180 },

        (companyType === CbdsType.CUST) ?
            { field: 'partsRefNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsRefNo' }), width: 180 } : undefined,
        (companyType === CbdsType.CUST) ?
            { field: 'backNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.backNo' }), width: 180 } : undefined,
        (companyType === CbdsType.CUST) ?
            { field: 'hscode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.hscode' }), width: 180 } : undefined,
        { field: 'uomCode', dataTypeName: 'uomType', title: intl.formatMessage({ id: 'field.uomCode' }), width: 180 },
        (companyType === CbdsType.CUST) ?
            { field: 'pairedFlag', dataTypeName: CodeCategory.PairedFlag, title: intl.formatMessage({ id: 'field.pairedFlag' }), width: 180 } : undefined,
        (companyType === CbdsType.CUST) ?
            { field: 'pairedPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'pairedPartsNo' }), width: 180 } : undefined,
        (companyType === CbdsType.CUST) ?
            { field: 'pairedOrderFlag', dataTypeName: CodeCategory.PairedOrderFlag, title: intl.formatMessage({ id: 'field.pairedOrderFlag' }), width: 180 } : undefined,

        { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 200 },
        { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 200 },
        { field: 'm3', dataTypeName: 'volume', title: intl.formatMessage({ id: 'field.m3' }), width: 200 },
        { field: 'netWeight', dataTypeName: 'weight', title: intl.formatMessage({ id: 'field.netWeight' }), width: 200 },
        { field: 'grossWeight', dataTypeName: 'weight', title: intl.formatMessage({ id: 'field.grossWeight' }), width: 200 },
        // { field: 'currency', dataTypeName: 'currencyType', title: intl.formatMessage({ id: 'field.currency' }), width: 200 },
        // { field: 'unitPrice', dataTypeName: 'number', title: intl.formatMessage({ id: 'unitPrice' }), width: 200 },
        { field: 'sellerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sellerPartsNo' }), width: 200 },
    ), [companyType, intl])

    const display1 = useCallback((tableRow: TableRow) => mode !== ScreenMode.VIEW && companyType === CbdsType.CUST, [mode, companyType])
    const actionProps1 = useMemo(() => ({ setPartsList }), [setPartsList])
    const onEditingRowCommit = useCallback((_: Column, row: Row) => {
        setPartsList(partsList => partsList.map((item, idx) => item.unitPartsNo === row.unitPartsNo ? row as PartsDetailResult : item))
        return true
    }, [setPartsList])

    return <div style={{ width: '100%', height: '500px' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout />
            <DataTypePreset />
            <PercentTypeProvider />
            <NumberTypeProvider name="weight" options={{ minimumFractionDigits: 6, maximumFractionDigits: 6 }} />
            <NumberTypeProvider name="volume" options={{ minimumFractionDigits: 6, maximumFractionDigits: 6 }} />
            <KeyTypeProvider name="uomType" options={uomArr} formatter={{
                format: (value: any) => {
                    return value === undefined ? '' : uomArr.find(f => f === value) ?? ''
                }
            }} />
            <KeyTypeProvider name="currencyType" options={currencyArr} formatter={{
                format: (value: any) => {
                    return value === undefined ? '' : currencyArr.find(f => f === value) ?? ''
                }
            }} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.PairedFlag} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.PairedOrderFlag} />

            <Data rows={partsList} columns={columns} />
            <RowActionProvider name="delete" Action={DeleteRowActionTotal} actionProps={actionProps1} display={display1} />
            <ColumnFreeze />
            <ColumnVisibility
                defaultHiddenFields={[]}
                columnSettings={{
                    unitPartsNo: { disableUserControl: true },
                    globalPartsNo: { disableUserControl: true },
                }} />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase />
            <Editing
                enableInlineEdit={mode !== ScreenMode.VIEW}
                columnSettings={{
                    partsNo: { editingDisabled: true },
                    unitPartsNo: { editingDisabled: true },
                    globalPartsNo: { editingDisabled: true },
                    contractNo: { editingDisabled: true },
                    partsRefNo: { editingDisabled: true },
                    backNo: { editingDisabled: true },
                    unitPartsName: { editingDisabled: true },
                    hscode: { editingDisabled: true },
                    uomCode: { editingDisabled: true },
                    pairedFlag: { editingDisabled: true },
                    pairedPartsNo: { editingDisabled: true },
                    pairedOrderFlag: { editingDisabled: true },
                }}
                onEditingCellCommit={onEditingRowCommit}
            />
            <Sorting />
            <Filtering />
            <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
            <Action width={52} />
        </DataGrid>
    </div>
})

const DeleteRowActionTotal = ({ tableRow, setPartsList }: DataGridRowActionProps & { setPartsList: React.Dispatch<React.SetStateAction<PartsDetailResult[]>> }) => {
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'delete' }), [intl])
    const callback = useCallback((tableRow: TableRow) => {
        const functionId = functionStore.register(() => setPartsList(partList => partList.filter((_) => _.unitPartsNo !== tableRow.row?.unitPartsNo)))
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [functionStore, dispatch, title, setPartsList])
    return <DeleteCallbackRowAction tableRow={tableRow} callback={callback} />
}

/** formValidate function */
const getFormCheckFields = (isIssue: boolean) => {
    return ({
        requestTo: { labelId: 'field.requestTo', required: true },
        description: { labelId: 'field.description', length: { max: 255 } },
    })
}


