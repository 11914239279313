import { Project } from "../../../utils/Project"
import { ShippingMode } from "../../accounting/enums/ShippingMode"
import { ActiveFlag } from "../enums/ActiveFlag"
import { TnmShippingRouteDetail, tnmShippingRouteDetailFromJson, TnmShippingRouteDetailJson, tnmShippingRouteDetailToJson } from "./TnmShippingRouteDetail"

export type ShippingRouteDetailResult = Required & Partial<Optional>
type ShippingRouteDetailResultJson = JsonRequired & Partial<JsonOptional>
export const shippingRouteDetailResultToJson: Project<ShippingRouteDetailResult, ShippingRouteDetailResultJson> = obj => ({
    ...obj,
    detailList: obj.detailList ? obj.detailList.map((m) => tnmShippingRouteDetailToJson(m)) : []
})
export const shippingRouteDetailResultFromJson: Project<ShippingRouteDetailResultJson, ShippingRouteDetailResult> = json => ({
    ...json,
    detailList: json.detailList ? json.detailList.map((m) => tnmShippingRouteDetailFromJson(m)) : []
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'detailList'> {
    shippingMode: ShippingMode,
    activeFlag: ActiveFlag,
    detailList: TnmShippingRouteDetail[],
}

interface JsonRequired {
    srId: number,
}

interface JsonOptional {
    shippingRouteCode: string,
    displayShippingRoute: string,
    shippingMode: number,
    shipperUid: string,
    receiverUid: string,
    fromRegion: string,
    toRegion: string,
    fromPortId: number,
    toPortId: number,
    fromState: string,
    toState: string,
    expCcLeadtime: number,
    impCcLeadtime: number,
    activeFlag: number,
    createUid: string,
    detailList: TnmShippingRouteDetailJson[],
}