import { Edit } from "@material-ui/icons"
import { TableRow } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import React from "react"
import { FormattedMessage } from "react-intl"
import { CallbackRowAction } from "./CallbackRowAction"

interface EditCallbackRowActionProps {
    access?: string,
    power?: boolean,
    title?: NonNullable<React.ReactNode>,
    icon?: NonNullable<React.ReactNode>,
    callback: (tableRow: TableRow) => void,
}

export const EditCallbackRowAction = (props: EditCallbackRowActionProps & DataGridRowActionProps) => {
    const defaultProps = {
        title: <FormattedMessage id="edit" />,
        icon: <Edit />
    }
    return <CallbackRowAction {...defaultProps} {...props} />
}