import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { dateToJson } from "../../utils/serializer"

/** main Bean */
export type SChangeOrderDetailResult = ResultRequired & Partial<ResultOptional>
type SChangeOrderDetailResulttson = ResultJsonRequired & Partial<ResultJsonOptional>

export const sChangeOrderDetailResultFromJson: Project<SChangeOrderDetailResulttson, SChangeOrderDetailResult> = json => ({
    ...json,
    orderFirstDate: dateFromJson(json.orderFirstDate),
    orderLastDate: dateFromJson(json.orderLastDate),
    targetFirstDate: dateFromJson(json.targetFirstDate),
    targetLastDate: dateFromJson(json.targetLastDate),
    partsDetailList: json.partsDetailList.map(partsDetailDeserializer),
})

export const sChangeOrderDetailResultToJson: Project<SChangeOrderDetailResult, SChangeOrderDetailResulttson> = data => ({
    ...data,
    orderFirstDate: dateToJson(data.orderFirstDate),
    orderLastDate: dateToJson(data.orderLastDate),
    targetFirstDate: dateToJson(data.targetFirstDate),
    targetLastDate: dateToJson(data.targetLastDate),
    partsDetailList: data.partsDetailList.map(partsDetailSerializer),
})

interface ResultRequired extends Omit<ResultJsonRequired, 'partsDetailList' | 'orderFirstDate' | 'orderLastDate' | 'targetFirstDate' | 'targetLastDate'> {
    partsDetailList: PartsDetail[],
    orderFirstDate: Date,
    orderLastDate: Date,
    targetFirstDate: Date,
    targetLastDate: Date,
}

interface ResultOptional extends ResultJsonOptional {

}

interface ResultJsonRequired {
    partsDetailList: PartsDetailJson[]
    orderFirstDate: string,
    orderLastDate: string,
    targetFirstDate: string,
    targetLastDate: string,
    totalQty: number,
    totalAmount: number,
}

interface ResultJsonOptional {
    supplierId: number,
    customerRefNo: string,
    supplierCode: string,
    customerId: number,
    customerCode: string,
    buyerId: number,
    buyerCode: string,
    deliveryToId: number,
    deliveryToCode: string,
    contractId: number,
    contractNo: string,
    contractRouteId: number,
    contractRouteCode: string,
    shippingMode: number,
    businessType: number,
    soId: number,
    soNo: string,
    soStatus: number,
    orderFrequency: number,
    orderType: number,
    orderVersion: number,
    spotOrderReason: string,
    suppRoId: number,
    reviseNo: string,
    reviseType: number,
    paymentTermsCode: string,
    paymentTermsDescription: string,
    remark: string,
    reviseStatus: number,
}


/** Parts Detail information */
export interface PartsDetail extends Omit<PartsDetailJson, 'oldOutboundPlanList' | 'newOutboundPlanList'> {
    oldOutboundPlanList: OutboundPlanDetail[],
    newOutboundPlanList: OutboundPlanDetail[],
}

interface PartsDetailJson {
    soDetailId: number,
    soDetailOrigin: string,
    partsId: number,
    partsNo: string,
    partsDescription: string,
    customerPartsId: number,
    customerPartsNo: string,
    supplierPartsNo: string,
    supplierId: number,
    supplierCode: string,
    impCountry: string,
    uomCode: string,
    uomDigits: number,
    orderLot: number,
    fluctuationRate?: number,
    oldFirmQty: number,
    firmQty?: number,
    sellingPrice: number,
    lastOrderForecast1?: number,
    fluctuationReason: string,
    currency?: string,
    companyCode: string,
    orderlotFlag: number,
    oldOutboundPlanList?: OutboundPlanDetailJson[],
    newOutboundPlanList?: OutboundPlanDetailJson[],

    suppRoId: number,
    suppRoSoId: number,
    soId: number,
    soNo: string,
    orderSeq: number,
    isAcknowledger: number,
    orderVersion: number,
    poNo: string,
    oldPartsNo: string,
    colorCode: string,
    supplierPartsName: string,
    spq: number,
    sSpq: number,
    deliveredQty: number,
    buildoutFlag: number,
    lastOrderMonth: string,
    changeFlag: number,
    orderStatus: number,
    soDetailStatus: number,
    pairedPartsNo: string,
    pairedOrderFlag: number

}

const partsDetailDeserializer: Project<PartsDetailJson, PartsDetail> = json => ({
    ...json,
    oldOutboundPlanList: json.oldOutboundPlanList ? json.oldOutboundPlanList.map(outboundPalnDetailDeserializer) : [],
    newOutboundPlanList: json.newOutboundPlanList ? json.newOutboundPlanList.map(outboundPalnDetailDeserializer) : [],
})

const partsDetailSerializer: Project<PartsDetail, PartsDetailJson> = data => ({
    ...data,
    oldOutboundPlanList: data.oldOutboundPlanList ? data.oldOutboundPlanList.map(outboundPalnDetailSerializer) : [],
    newOutboundPlanList: data.newOutboundPlanList ? data.newOutboundPlanList.map(outboundPalnDetailSerializer) : [],
})


/** Inbound plan Information */
export interface OutboundPlanDetail extends Omit<OutboundPlanDetailJson, 'crdDate' | 'drDate'> {
    crdDate?: Date,
    drDate: Date
}

interface OutboundPlanDetailJson {
    soDetailId?: number,
    soPartsId?: number,
    roDetailId?: number,
    soDetailOrigin?: string,
    drType?: number,
    drId?: number,
    changeFlag?: number,
    crdDate?: string,
    drDate: string,
    drQty: number | null,
}

const outboundPalnDetailDeserializer: Project<OutboundPlanDetailJson, OutboundPlanDetail> = json => ({
    ...json,
    crdDate: dateFromJson(json.crdDate),
    drDate: dateFromJson(json.drDate)
})

const outboundPalnDetailSerializer: Project<OutboundPlanDetail, OutboundPlanDetailJson> = data => ({
    ...data,
    crdDate: dateToJson(data.crdDate),
    drDate: dateToJson(data.drDate)
})





