import { Project } from "../../../../utils/Project"
import { dateToJson } from "../../../utils/serializer"

export type PokayokeDownloadFactor = Required & Optional
type PokayokeDownloadFactorJson = JsonRequired & JsonOptional
export const pokayokeDownloadFactorToJson: Project<PokayokeDownloadFactor, PokayokeDownloadFactorJson> = obj => ({
    ...obj,
    stockDates: obj.stockDates?.map(m => dateToJson(m))
})

interface Required {
}

interface Optional {
    customers?: string[],
    stockDates?: Date[],
}

interface JsonRequired {
}

interface JsonOptional {
    customers?: string[],
    stockDates?: string[],
}