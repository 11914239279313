import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { dateFromJson } from "../../utils/deserializer"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"
import { CoStatus } from "../enums/CoStatus"
import { OrderFrequency } from "../enums/OrderFrequency"
import { OrderType } from "../enums/OrderType"

export type CustomerForecastListFactor = Partial<Optional>
type CustomerForecastListFactorJson = Partial<JsonOptional>
export const customerForecastListFactorToJson: Project<CustomerForecastListFactor, CustomerForecastListFactorJson> = obj => ({
    ...obj,
    orderDateStart: dateToJson(obj.orderDateStart),
    orderDateEnd: dateToJson(obj.orderDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
})

export const customerForecastListFactorFromJson: Project<CustomerForecastListFactorJson, CustomerForecastListFactor> = json => ({
    ...json,
    orderDateStart: dateFromJson(json.orderDateStart),
    orderDateEnd: dateFromJson(json.orderDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page),
})

interface Optional extends Omit<JsonOptional, 'orderDateStart' | 'orderDateEnd' | 'page'> {
    orderTypeList: OrderType[],
    orderFrequencyList: OrderFrequency[],
    orderDateStart: Date,
    orderDateEnd: Date,
    coStatusList: CoStatus[],
    page: PageViewEntity
}


interface JsonOptional {
    customerForecastNo: string,
    customerRefNo: string,
    customerIdList: number[],
    orderTypeList: number[],
    orderFrequencyList: number[],
    customerContractNo: string,
    contractRouteNo: string,
    orderDateStart: string,
    orderDateEnd: string,
    coStatusList: number[],
    page: PageViewEntityJson
}

