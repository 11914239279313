import { useCallback, useMemo, useState } from "react"
import { useGetCustomerUsagePage120 } from "../../../services/smt/api/CustomerUsageApi120"
import { CustomerUsageTransfer120 } from "../../../services/smt/models/CustomerUsageTransfer120"
import { CustomerUsageView120, customerUsageViewFromJson120, customerUsageViewToJson120 } from "../../../services/smt/models/CustomerUsageView120"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { CSUHS120PcUi } from "./CSUHS120PcUi"

const defaultFilters = { /* page: { currentPage: 0, pageSize: 1000 } */ }

const searchCachekey = sessionKeys.Filters_CSUHS120

export const CSUHS120 = () => {
    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? customerUsageViewFromJson120(JSON.parse(filters)) : null
    }, [])
    const [monthList, setMonthList] = useState<Date[]>([])
    const [filters, setFilters] = useState<CustomerUsageView120>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<CustomerUsageTransfer120[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)

    const getCustomerUsageList = useGetCustomerUsagePage120()
    const search = useCallback((filters: CustomerUsageView120) => {
        getCustomerUsageList(filters, { silent: true, serialized: true }).then(result => {
            if (result?.page) {
                setTotalCount(result.page.page?.totalCount || 0)
                setData(result.page?.data || [])
                sessionStorage.setItem(searchCachekey, JSON.stringify(customerUsageViewToJson120(filters)))
            }
            setMonthList(result?.monthList ?? [])
        })
    }, [getCustomerUsageList, setData])


    useSearchOnLoad(search, filters)

    return <CSUHS120PcUi
        filters={filters}
        search={search}
        data={data}
        setData={setData}
        totalCount={totalCount}
        setFilters={setFilters}
        monthList={monthList}
        setMonthList={setMonthList}
    />
}