import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DateRangeItem, EntriesItem, StringItem } from "@rithe/form"
import { Records } from "@rithe/utils"
import React, { useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { UploadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/UploadGroupedToolbarAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { View } from "../../../components/View/View"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { useDownloadCustomerStock } from "../../../services/smt/api/smtDownloadApi"
import { useUploadCustomerStock } from "../../../services/smt/api/smtUploadApi"
import { CustomerStockList } from "../../../services/smt/models/CustomerStockList"
import { CustomerStockListFactor } from "../../../services/smt/models/CustomerStockListFactor"
import { Pair } from "../../../services/utils/Pair"
import { useGetCompanyType } from "../../../utils/ApplicationUtils"

interface SMGTS310PcUiProps {
    filters: CustomerStockListFactor,
    setFilters: React.Dispatch<React.SetStateAction<CustomerStockListFactor>>,
    data: CustomerStockList[],
    totalCount: number,
    customerList: Pair[],
    search: (filters: CustomerStockListFactor) => void,
}

export const SMGTS310PcUi = (props: SMGTS310PcUiProps) => {
    const intl = useIntl()
    return <View flex>
        <SectionCard>
            <SectionCardHeader title={''} subtitle={intl.formatMessage({ id: 'customerListSub' })} />
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({ filters, data, setFilters, search, customerList }: SMGTS310PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])

    const columns = useMemo(() => [
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 180 },
        { field: 'customerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.buyerCode' }), width: 200 },
        { field: 'externalRefNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.externalRefNo' }), width: 300 },
        { field: 'customerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.buyerPartsNo' }), width: 300 },
        { field: 'customerBackNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.buyerBackNo' }), width: 300 },
        { field: 'customerStockDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.buyerStockDate' }), width: 300 },
        { field: 'customerStockQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.buyerStockQty' }), width: 200 },
    ], [intl])
    const getRowId = useCallback((row: any) => row.customerPartsId, [])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search, customerList }), [filters, setFilters, search, customerList])
    const actionProps1 = useMemo(() => ({ search, filters }), [filters, search])
    const actionProps2 = useMemo(() => ({ selections }), [selections])

    const cbdsType = useGetCompanyType()

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={UploadAction} actionProps={actionProps1} display={() => cbdsType !== CbdsType.CUST} />
        <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps2} display={() => cbdsType !== CbdsType.CUST} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['decimalDigits']}
            columnSettings={{
                partsNo: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
    </DataGrid>
}

const FilterItem = (props: {
    customerList: Pair[],
    filters: CustomerStockListFactor,
    setFilters: React.Dispatch<React.SetStateAction<CustomerStockListFactor>>,
    search: (filters: CustomerStockListFactor) => void
}) => {
    const { filters, setFilters, search, customerList } = props

    const customersMap = useMemo(() => customerList ? customerList.map(m => [m.second, m.second] as [string, string]) : [], [customerList])

    const customerStockDateGetValue = useCallback((filters: CustomerStockListFactor) => {
        return [filters.customerStockDateStart ?? null, filters.customerStockDateEnd ?? null]
    }, [])

    const customerStockDateMapValue = useCallback((filters: CustomerStockListFactor, value: any) => {
        return { ...filters ?? {}, customerStockDateStart: value[0], customerStockDateEnd: value[1] }
    }, [])

    const clear = useCallback((filters: CustomerStockListFactor) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: CustomerStockListFactor) => {
        return [
            filters.partsNo,
            filters.customerCodeList,
            filters.externalRefNo,
            filters.customerPartsNo,
            filters.customerBackNo,
            filters.customerStockDateStart || filters.customerStockDateEnd,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()


    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="partsNo" label={intl.formatMessage({ id: 'field.partsNo' })} />
        <EntriesItem field="customerCodeList" label={intl.formatMessage({ id: 'field.buyerCode' })} entries={customersMap} />
        <StringItem field="externalRefNo" label={intl.formatMessage({ id: 'field.externalRefNo' })} />
        <StringItem field="customerPartsNo" label={intl.formatMessage({ id: 'field.buyerPartsNo' })} />
        <StringItem field="customerBackNo" label={intl.formatMessage({ id: 'field.buyerBackNo' })} />
        <DateRangeItem field="customerStockDate" label={intl.formatMessage({ id: 'field.buyerStockDate' })} getValue={customerStockDateGetValue} mapValue={customerStockDateMapValue} />
    </FilterToolbarItem>
}


const UploadAction = ({ search, filters }: { search: (filters: CustomerStockListFactor) => void, filters: CustomerStockListFactor }) => {
    const uploadMasterApi = useUploadCustomerStock()
    const uploadMaster = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            files && uploadMasterApi({ file: files[0] }, { serialized: true }).then(() => search(filters))
        })
    }, [filters, search, uploadMasterApi])
    return <UploadGroupedToolbarAction access='STCK.SMGTS310.UPLOAD' >
        {(popupUpload, onClose) => <>
            <GroupedCallbackItem label={<FormattedMessage id="uploadMaster" />} callback={() => uploadMaster(popupUpload)} onClose={onClose} />
        </>}
    </UploadGroupedToolbarAction>
}

const DownloadAction = ({ selections }: { selections: number[] }) => {
    const downloadApi = useDownloadCustomerStock()
    const download = useCallback(() => downloadApi({ customerPartIdList: selections }), [downloadApi, selections])
    return <DownloadGroupedToolbarAction access='STCK.SMGTS310.DOWNLOAD'>
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="download" />} callback={download} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}