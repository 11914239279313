export enum SoSpotStatus {
    Received = 10,
    Confirmed = 30,
    Completed = 80,
    Rejected = 90,

    // Submitted = 10,
    // Received = 20,
    // Confirmed = 25,
    // Processing = 30,
    // Force_completed = 35,
    // Completed = 40,
    // Rejected = 90,
    // Inactive = 99
}