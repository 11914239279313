import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"
import { ActiveFlag } from "../enums/ActiveFlag"
import { MilkrunFlag } from "../enums/MilkrunFlag"

export type SupplierResult = Required & Partial<Optional> & TableEntity
type SupplierResultJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const supplierResultFromJson: Project<SupplierResultJson, SupplierResult> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
    activeFlag: ActiveFlag,
    milkrunFlag: MilkrunFlag,
}

interface JsonRequired {
    supplierCode: string,
}

interface JsonOptional {
    companyId: number,
    companyCode: string,
    tempSupplierId: number,
    supplierId: number,
    supplierName: string,
    supplierShortCode: string,
    regionCode: string,
    address1: string,
    address2: string,
    address3: string,
    address4: string,
    postalCode: string,
    contact1: string,
    fax1: string,
    telephone1: string,
    email1: string,
    contact2: string,
    fax2: string,
    telephone2: string,
    email2: string,
    timeZone: string,
    calendarId: number,
    calendarCode: string,
    activeFlag: number,
    milkrunFlag: number,
}