import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { ShippingRouteDetailResult, shippingRouteDetailResultFromJson, shippingRouteDetailResultToJson } from "../models/ShippingRouteDetailResult"
import { ShippingRouteFactor, shippingRouteFactorToJson } from "../models/ShippingRouteFactor"
import { ShippingRouteResult, shippingRouteResultFromJson } from "../models/ShippingRouteResult"

export const useGetShippingRouteList = () => {
    const url = '/lcbm-local-master-api/api/getShippingRouteList'
    const payloadProject = useCallback(shippingRouteFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(shippingRouteResultFromJson), [])
    return usePost<ShippingRouteFactor, PageResultEntity<ShippingRouteResult>>(url, payloadProject, resultProject)
}

export const useGetShippingRouteDetail = () => {
    const url = '/lcbm-local-master-api/api/getShippingRouteDetail'
    const payloadProject = useCallback((obj: { srId: number }) => obj.srId, [])
    const resultProject = useCallback(shippingRouteDetailResultFromJson, [])
    return usePost<{ srId: number }, ShippingRouteDetailResult>(url, payloadProject, resultProject)
}

export const useSaveShippingRouteDetail = () => {
    const url = '/lcbm-local-master-api/api/saveShippingRouteDetail'
    const payloadProject = useCallback(shippingRouteDetailResultToJson, [])
    const resultProject = useCallback(shippingRouteDetailResultFromJson, [])
    return usePost<ShippingRouteDetailResult, ShippingRouteDetailResult>(url, payloadProject, resultProject)
}