import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { YESNOFlag } from "../../../services/privilege/enums/YESNOFlag"
import { useGetAlarmUsagePatternDetail } from "../../../services/smt/api/AlarmUsagePatternApi"
import { useGetOrderGroups } from "../../../services/smt/api/ComboboxApi"
import { OdmCustomerParts, OdmCustomerPartsControl, OdmCustomerPartsOrder } from "../../../services/smt/models/AlarmUsagePatternDetail"
import { OrderGroupTransfer } from "../../../services/smt/models/OrderGroupTransfer"
import { useMatch } from "../../../utils/useMatch"
import { MSAUF011PcUi } from "./MSAUF011PcUi"

export const MSAUF011 = () => {
    const { customerPartsId } = useParams() as any
    const { path } = useMatch()
    let mode = path === '/alarmUsagePattern/edit-:customerPartsId' ? ScreenMode.EDIT : ScreenMode.VIEW
    const [odmCustomerParts, setOdmCustomerParts] = useState<OdmCustomerParts>({} as OdmCustomerParts)
    const [odmCustomerPartsControl, setOdmCustomerPartsControl] = useState<OdmCustomerPartsControl>({} as OdmCustomerPartsControl)
    const [odmCustomerPartsOrder, setOdmCustomerPartsOrder] = useState<OdmCustomerPartsOrder>({} as OdmCustomerPartsOrder)
    const [orderGroupList, setOrderGroupList] = useState<OrderGroupTransfer[]>([])

    const getAlarmUsagePatternDetail = useGetAlarmUsagePatternDetail()
    const getOrderGroupsByPa = useGetOrderGroups()
    const search = useCallback((customerPartsId: number | null) => {
        if (customerPartsId !== null) {
            getAlarmUsagePatternDetail({ customerPartsId: customerPartsId }, { silent: true, serialized: true }).then(result => {
                setOdmCustomerParts(result?.customerPart ?? {})
                setOdmCustomerPartsControl(result?.customerPartControl ?? {})
                setOdmCustomerPartsOrder(result?.customerPartOrder ?? {})
            })
        }
        if (odmCustomerParts.customerId !== null && odmCustomerParts.customerId !== undefined) {
            getOrderGroupsByPa({ customerId: odmCustomerParts.customerId, enhancementFlag: YESNOFlag.NO }, { silent: true, serialized: true }).then(result => {
                setOrderGroupList(result || [])
            })
        }

    }, [getAlarmUsagePatternDetail, getOrderGroupsByPa, odmCustomerParts.customerId])


    useEffect(() => {
        search(customerPartsId)
    }, [customerPartsId, search])



    return <MSAUF011PcUi
        mode={mode}
        odmCustomerParts={odmCustomerParts}
        setOdmCustomerParts={setOdmCustomerParts}
        odmCustomerPartsControl={odmCustomerPartsControl}
        setOdmCustomerPartsControl={setOdmCustomerPartsControl}
        odmCustomerPartsOrder={odmCustomerPartsOrder}
        setOdmCustomerPartsOrder={setOdmCustomerPartsOrder}
        search={search}
        orderGroupList={orderGroupList}

    />
}