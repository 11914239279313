import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { useSelector } from "react-redux"
import appConfig from "../../../configs/appConfig"
import { ActiveFlag } from "../../../services/privilege/enums/ActiveFlag"
import roleApi from "../../../services/system/apis/roleApi"
import errorToNotification from "../../../utils/axios/errorToNotification"
import thunkAxiosInstance from "../../../utils/axios/thunkAxiosInstance"
import { applicationActions } from "../../Application/applicationSlice"

interface Filter {
    nameContains?: string,
    descriptionContains?: string,
    statusIn?: number[],
}

interface Role {
    id: string,
    name: string,
    description: string,
    resourceIds: string[],
}

interface CAS010State {
    filter: Filter,
    roles: Role[],
}

const cacheFilters = sessionStorage.getItem(`${appConfig.appFullName}:CAS010.filter`)
const initialState: CAS010State = {
    filter: cacheFilters ? JSON.parse(cacheFilters) : {
        nameContains: undefined,
        descriptionContains: undefined,
        statusIn: undefined,
    },
    roles: [],
}

const listRoles = createAsyncThunk('cas010/listRoles', async (_, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const filter = selectCAS010State(thunk.getState()).filter
    let active = undefined as boolean | undefined
    if (filter.statusIn && filter.statusIn.length === 1) {
        if (filter.statusIn[0] === ActiveFlag.ACTIVE) {
            active = true
        } else if (filter.statusIn[0] === ActiveFlag.INACTIVE) {
            active = false
        }
    }
    thunk.dispatch(applicationActions.addMask())
    return await roleApi.list(axiosInstance, {
        nameContains: filter.nameContains,
        descriptionContains: filter.descriptionContains,
        active: active,
        pageSize: 1000
    })
        .then(response => {
            thunk.dispatch(cas010Actions.setRoles(response.data.data!))
        })
        .catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        }).then(() => {
            thunk.dispatch(applicationActions.removeMask())
        })
})

const activateRole = createAsyncThunk('cas010/activateRole', async (id: string, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    thunk.dispatch(applicationActions.addMask())
    return await roleApi.activate(axiosInstance, id)
        .then(() => {
            thunk.dispatch(listRoles())
        })
        .catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        }).then(() => {
            thunk.dispatch(applicationActions.removeMask())
        })
})

const deactivateRole = createAsyncThunk('cas010/deactivateRole', async (id: string, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    thunk.dispatch(applicationActions.addMask())
    return await roleApi.deactivate(axiosInstance, id)
        .then(() => {
            thunk.dispatch(listRoles())
        })
        .catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        }).then(() => {
            thunk.dispatch(applicationActions.removeMask())
        })
})

export const cas010Slice = createSlice({
    name: 'cas010',
    initialState,
    reducers: {
        setRoles: (state, { payload: data }: PayloadAction<Role[]>) => {
            state.roles = data
        },
        setFilter: (state, { payload: filter }: PayloadAction<Filter>) => {
            state.filter = filter
        },
    }
})

export const cas010Actions = {
    ...cas010Slice.actions,
    listRoles,
    activateRole,
    deactivateRole,
}

function selectCAS010State(state: any): CAS010State {
    return state[appConfig.appFullName][cas010Slice.name]
}

export function useCAS010Selector<R>(selector: (state: CAS010State) => R, equalityFn?: (left: R, right: R) => boolean) {
    return useSelector<any, R>(state => selector(selectCAS010State(state)), equalityFn)
}