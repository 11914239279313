import { Dialog, DialogActions, DialogContent } from "@material-ui/core"
import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, NumberTypeProvider, PaginationLayout, Paging, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarLayout } from '@rithe/data-grid'
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { Break, DateItem, EntryItem, Form, Message, NumberItem, StringItem } from "@rithe/form"
import { Comparators, Records } from "@rithe/utils"
import React, { SetStateAction, useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { SaveCallbackViewAction } from "../../../components/Action/SaveCallbackViewAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DeleteCallbackRowAction } from "../../../components/DataGrid/rowActions/DeleteCallbackRowAction"
import { CreateCallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/CreateCallbackToolbarAction"
import { EditCallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/EditCallbackToolbarAction"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { CodeItem } from "../../../components/Form/CodeItem"
import { View } from "../../../components/View/View"
import { useFunctionStore } from "../../../Root"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { useSaveFOBSettingDetail } from "../../../services/master/apis/fobSettingApi"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { FOBSettingDetail, FOBSettingDetailResult, FOBSettingInfo } from "../../../services/master/models/FOBSettingDetailResult"
import { TnmContractRoute } from "../../../services/master/models/TnmContrRoute"
import { TnmCurrency } from "../../../services/master/models/TnmCurrency"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { useFieldChecker, useFormValidater } from "../../../utils/ValidatorUtils"
import { applicationActions } from "../../Application/applicationSlice"


interface MLS171PcUiProps {
    fobSettingId: number | null,
    mode: ScreenMode,
    search: (fobSettingId: number | null) => void,
    fOBSettingInfo: FOBSettingInfo,
    setFOBSettingInfo: React.Dispatch<SetStateAction<FOBSettingInfo>>,
    fOBSettingDetailList: FOBSettingDetail[],
    setFOBSettingDetailList: React.Dispatch<SetStateAction<FOBSettingDetail[]>>,
    currencyList: TnmCurrency[],
    contractRouteList: TnmContractRoute[],
    consigneeList: TnvCbds[],
    ftaInvoiceList: TnvCbds[],
    customClearanceInvoiceList: TnvCbds[],
    findCBDSList: (fobSettingId: number | null) => void,
}

export const MLS171PcUi = (props: MLS171PcUiProps) => {
    const intl = useIntl()
    const { mode, fOBSettingInfo, fOBSettingDetailList, search, fobSettingId } = props
    const [messages, setMessages] = useState<Message[]>([])
    const [deleteIdList, setDeleteIdList] = useState<number[]>([])
    const actions = mode !== ScreenMode.VIEW ? [<SaveAction mode={mode} dataInfo={fOBSettingInfo} details={fOBSettingDetailList} search={search} setMessages={setMessages} fobSettingId={fobSettingId} deleteIdList={deleteIdList} />] : []
    return <View actions={actions}>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={1}
                title={intl.formatMessage({ id: 'BasicInfo' })}
                subtitle={intl.formatMessage({ id: 'basicInfoSub' })}
            />
            <SectionCardContent>
                <FormView {...props} messages={messages} setMessages={setMessages} />
            </SectionCardContent>
        </SectionCard>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={2}
                title={intl.formatMessage({ id: 'settingInfo' })}
                subtitle={intl.formatMessage({ id: 'settingInfoSub' })}
            />
            <SectionCardContent>
                <TableView {...props} setDeleteIdList={setDeleteIdList} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const FormView = (props: MLS171PcUiProps & { messages: any, setMessages: React.Dispatch<React.SetStateAction<Message[]>> }) => {
    const { mode, fOBSettingInfo, setFOBSettingInfo, currencyList, contractRouteList, consigneeList, ftaInvoiceList, customClearanceInvoiceList, findCBDSList, messages, setMessages } = props
    const intl = useIntl()

    const fields = useMemo(() => getFormCheckFields(), [])
    const filedCheck = useFieldChecker(fields, setMessages)
    const currencysMap = useMemo(() => currencyList ? currencyList.map(m => [m.currencyCode, m.currencyCode] as [string, string]) : [], [currencyList])
    const contractRouteMap = useMemo(() => contractRouteList ? contractRouteList.map(m => [m.contrRouteId, m.contrRouteCode] as [number, string]) : [], [contractRouteList])


    const consigneeMap = useMemo(() => consigneeList.map(m => [m.cbdsUid, m.cbdsCode] as [string, string]), [consigneeList])
    const accounteeMap = useMemo(() => ftaInvoiceList.map(m => [m.cbdsUid, m.cbdsCode] as [string, string]), [ftaInvoiceList])
    const deliveryToMap = useMemo(() => customClearanceInvoiceList.map(m => [m.cbdsUid, m.cbdsCode] as [string, string]), [customClearanceInvoiceList])

    const dataChange = useCallback<React.Dispatch<React.SetStateAction<FOBSettingInfo>>>(nextDraftDataFunc => {
        setFOBSettingInfo(data => {
            const newData = typeof nextDraftDataFunc === 'function' ? nextDraftDataFunc(data) : nextDraftDataFunc
            if (newData.contrRouteId && newData.contrRouteId !== data.contrRouteId) {
                findCBDSList(newData.contrRouteId)
                newData.consignee = undefined
                newData.ftaInvoice = undefined
                newData.customClearanceInvoice = undefined
            }
            return newData
        })
    }, [findCBDSList, setFOBSettingInfo])

    return <Form data={fOBSettingInfo} setData={dataChange} labelDisplay="block" helperDisplay="tooltip" messages={messages} setMessages={setMessages} ensure={filedCheck}>
        <EntryItem readonly={mode === ScreenMode.VIEW} required field="invCurrencyCode" label={intl.formatMessage({ id: 'invCurrencyCode' })} entries={currencysMap} />
        <EntryItem readonly={mode === ScreenMode.VIEW} required field="contrRouteId" label={intl.formatMessage({ id: 'field.contrRouteCode' })} entries={contractRouteMap} />
        <Break />
        <EntryItem readonly={mode === ScreenMode.VIEW} required field="consignee" label={intl.formatMessage({ id: 'consignee' })} entries={consigneeMap} />
        <Break />
        <EntryItem readonly={mode === ScreenMode.VIEW} required field="ftaInvoice" label={intl.formatMessage({ id: 'ftaInvoice' })} entries={accounteeMap} />
        <EntryItem readonly={mode === ScreenMode.VIEW} required field="customClearanceInvoice" label={intl.formatMessage({ id: 'customClearanceInvoice' })} entries={deliveryToMap} />
    </Form>
}

const TableView = (props: MLS171PcUiProps & { setDeleteIdList: React.Dispatch<React.SetStateAction<number[]>> }) => {
    const { mode, fOBSettingInfo, fOBSettingDetailList, setFOBSettingDetailList, ftaInvoiceList, customClearanceInvoiceList, setDeleteIdList } = props
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])
    const accounteeMap = useMemo(() => ftaInvoiceList?.map(m => [m.cbdsUid, m.cbdsCode] as [string, string]), [ftaInvoiceList])
    const deliveryToMap = useMemo(() => customClearanceInvoiceList?.map(m => [m.cbdsUid, m.cbdsCode] as [string, string]), [customClearanceInvoiceList])

    const columns = useMemo(() => [
        { field: 'ftaExchangeRate', dataTypeName: 'exchangeRate', title: intl.formatMessage({ id: 'ftaExchangeRate' }), width: 230 },
        { field: 'customExchangeRate', dataTypeName: 'exchangeRate', title: intl.formatMessage({ id: 'customExchangeRate' }), width: 230 },
        { field: 'effectiveStartDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'effectiveStartDate' }), width: 200 },
        { field: 'effectiveEndDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'effectiveEndDate' }), width: 200 },
        { field: 'fobInvValueLogic', dataTypeName: CodeCategory.FobInvValueLogic, title: intl.formatMessage({ id: 'fobInvValueLogic' }), width: 230 },
        { field: 'remark', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.remark' }), width: 300 },
        { field: 'createdBy', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.createdBy' }), width: 200 },
        { field: 'createdDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.createdDate' }), width: 200 },
        { field: 'updatedBy', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.updatedBy' }), width: 200 },
        { field: 'updatedDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.updatedDate' }), width: 200 },
        { field: 'version', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.version' }), width: 120 },
    ], [intl])

    const defaultHiddenFields = useMemo(() => [
        'createdBy', 'createdDate', 'updatedBy', 'updatedDate', 'version',
    ], [])

    const columnVisibilityOptions = {
        ftaExchangeRate: { disableUserControl: true },
        customExchangeRate: { disableUserControl: true },
        effectiveStartDate: { disableUserControl: true },
        effectiveEndDate: { disableUserControl: true },
        fobInvValueLogic: { disableUserControl: true },
    }
    const actionProps = useMemo(() => ({
        fOBSettingInfo,
        fOBSettingDetailList,
        setFOBSettingDetailList,
        accounteeMap,
        deliveryToMap
    }), [fOBSettingInfo, fOBSettingDetailList, setFOBSettingDetailList, accounteeMap, deliveryToMap])
    const actionProps1 = useMemo(() => ({ setFOBSettingDetailList, setDeleteIdList }), [setFOBSettingDetailList, setDeleteIdList])
    const display = useCallback(() => mode !== ScreenMode.VIEW, [mode])
    const display1 = useCallback((tableRow: TableRow) => mode !== ScreenMode.VIEW && tableRow.rowId !== undefined && tableRow.rowId !== null, [mode])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <NumberTypeProvider name="exchangeRate" options={{ minimumFractionDigits: 6, maximumFractionDigits: 6 }} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.FobInvValueLogic} />
        <Data rows={fOBSettingDetailList ?? []} columns={columns} />
        <ToolbarActionProvider Action={CreateAction} actionProps={actionProps} display={display} />
        <RowActionProvider name="edit" Action={EditRowAction} actionProps={actionProps} display={display1} />
        <RowActionProvider name="delete" Action={DeleteRowAction} actionProps={actionProps1} display={display1} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={defaultHiddenFields}
            columnSettings={columnVisibilityOptions}
            ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection hideSelectionColumn highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={120} />
    </DataGrid>
}

interface FOBDetailPops {
    fOBSettingInfo: FOBSettingInfo,
    accounteeMap: [string, string][],
    deliveryToMap: [string, string][],
    fOBSettingDetailList: FOBSettingDetail[],
    setFOBSettingDetailList: React.Dispatch<React.SetStateAction<FOBSettingDetail[]>>
}

interface PopProps extends Omit<FOBDetailPops, 'fOBSettingDetailList' | 'setFOBSettingDetailList'> {
    messages: Message[],
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>
    fOBSettingDetail: FOBSettingDetail,
    setFOBSettingDetail: React.Dispatch<React.SetStateAction<FOBSettingDetail>>
}

const CreateAction = ({ fOBSettingInfo, fOBSettingDetailList, setFOBSettingDetailList, accounteeMap, deliveryToMap }: FOBDetailPops) => {
    const [open, setOpen,] = useState(false)
    const onOpen = useCallback(() => setOpen(true), [])
    const onClose = useCallback(() => { setOpen(false); setData({}); setMessages([]) }, [])
    const [messages, setMessages] = useState<Message[]>([])
    const fields = useMemo(() => getAddFormCheckFields(), [])
    const [data, setData] = useState<FOBSettingDetail>({} as FOBSettingDetail)
    const formValidate = useFormValidater(setMessages, fields)
    const dispatch = useDispatch()
    const checkData = useCallback((obj: FOBSettingDetail) => {
        if (obj.effectiveStartDate && obj.effectiveEndDate && obj.effectiveStartDate.getTime() > obj.effectiveEndDate.getTime()) {
            dispatch(applicationActions.pushError({ title: { code: 'effectiveEndDate' }, messages: { code: 'w0564' } }))
            return false
        }
        return true
    }, [dispatch])
    const onSave = useCallback(() => {
        if (!formValidate(data) || !checkData(data)) {
            return
        }
        // check Date
        // add 
        setFOBSettingDetailList(list => {
            const newList = [...list, { ...data }]
            newList.sort(Comparators.compare<FOBSettingDetail, number>(d => d.effectiveStartDate?.getTime() ?? 0, Comparators.reverseOrder()))
            return newList
        })
        // close
        setOpen(false)
        setData({})
        setMessages([])
    }, [setData, setMessages, formValidate, data, checkData, setFOBSettingDetailList])

    return <>
        <CreateCallbackToolbarAction access="MARS.MLS171.CREATE" callback={onOpen} />
        <Dialog open={open} style={{ height: '100%', overflow: 'hidden', width: '100%' }}>
            <DialogHeader ><FormattedMessage id="fobDetailEdit" /></DialogHeader>
            <DialogContent style={{ height: '100%', overflow: 'hidden', width: '100%', marginBottom: 20 }}>
                <DetailViewPop messages={messages} setMessages={setMessages} fOBSettingInfo={fOBSettingInfo} fOBSettingDetail={data} setFOBSettingDetail={setData} accounteeMap={accounteeMap} deliveryToMap={deliveryToMap} />
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={onClose} />
                <DialogAction title={<FormattedMessage id="confirm" />} callback={onSave} />
            </DialogActions>
        </Dialog>
    </>
}

const EditRowAction = ({ fOBSettingInfo, fOBSettingDetailList, setFOBSettingDetailList, accounteeMap, deliveryToMap, tableRow }: FOBDetailPops & DataGridRowActionProps) => {
    const [open, setOpen,] = useState(false)
    const onOpen = useCallback(() => setOpen(true), [])
    const onClose = useCallback(() => { setOpen(false); setMessages([]) }, [])
    const [messages, setMessages] = useState<Message[]>([])
    const fields = useMemo(() => getAddFormCheckFields(), [])
    const [data, setData] = useState<FOBSettingDetail>({})
    const formValidate = useFormValidater(setMessages, fields)
    const dispatch = useDispatch()
    const checkData = useCallback((obj: FOBSettingDetail) => {
        if (obj.effectiveStartDate && obj.effectiveEndDate && obj.effectiveStartDate.getTime() > obj.effectiveEndDate.getTime()) {
            dispatch(applicationActions.pushError({ title: { code: 'effectiveEndDate' }, messages: { code: 'w0564' } }))
            return false
        }
        return true
    }, [dispatch])
    const onSave = useCallback(() => {
        if (!formValidate(data) || !checkData(data)) {
            return
        }
        // add 
        setFOBSettingDetailList(list => list.map((m, index) => tableRow.rowId === index ? { ...data } : m))
        // close
        setOpen(false)
        setData({})
        setMessages([])
    }, [formValidate, data, checkData, setFOBSettingDetailList, tableRow.rowId])

    useEffect(() => {
        setData({ ...tableRow.row })
    }, [setData, tableRow.row])

    return <>
        <EditCallbackToolbarAction access="MARS.MLS171.EDIT" callback={onOpen} />
        <Dialog open={open} style={{ height: '100%', overflow: 'hidden', width: '100%' }}>
            <DialogHeader ><FormattedMessage id="fobDetailEdit" /></DialogHeader>
            <DialogContent style={{ height: '100%', overflow: 'hidden', width: '100%', marginBottom: 20 }}>
                <DetailViewPop messages={messages} setMessages={setMessages} fOBSettingInfo={fOBSettingInfo} fOBSettingDetail={data} setFOBSettingDetail={setData} accounteeMap={accounteeMap} deliveryToMap={deliveryToMap} />
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={onClose} />
                <DialogAction title={<FormattedMessage id="confirm" />} callback={onSave} />
            </DialogActions>
        </Dialog>
    </>
}


const DetailViewPop = ({ messages, setMessages, fOBSettingInfo, fOBSettingDetail, setFOBSettingDetail, accounteeMap, deliveryToMap }: PopProps) => {
    const intl = useIntl()
    const fields = useMemo(() => getAddFormCheckFields(), [])
    const checkItem = useFieldChecker(fields, setMessages)


    return <>
        <Form data={fOBSettingInfo} labelDisplay="block" helperDisplay="tooltip" minWidth={500} maxWidth={500} columnCount={2} >
            <EntryItem readonly field="ftaInvoice" label={intl.formatMessage({ id: 'ftaInvoice' })} entries={accounteeMap} />
            <EntryItem readonly field="customClearanceInvoice" label={intl.formatMessage({ id: 'customClearanceInvoice' })} entries={deliveryToMap} />
        </Form>
        <Form data={fOBSettingDetail} setData={setFOBSettingDetail} labelDisplay="block" helperDisplay="tooltip" minWidth={500} maxWidth={500} columnCount={2} messages={messages} setMessages={setMessages} ensure={checkItem}>
            <NumberItem field="ftaExchangeRate" required label={intl.formatMessage({ id: 'ftaExchangeRate' })} />
            <NumberItem field="customExchangeRate" required label={intl.formatMessage({ id: 'customExchangeRate' })} />
            <CodeItem field="fobInvValueLogic" required label={intl.formatMessage({ id: 'fobInvValueLogic' })} code={CodeCategory.FobInvValueLogic} />
            <Break />
            <DateItem field="effectiveStartDate" required label={intl.formatMessage({ id: 'effectiveStartDate' })} />
            <DateItem field="effectiveEndDate" required label={intl.formatMessage({ id: 'effectiveEndDate' })} />
            <StringItem field="remark" label={intl.formatMessage({ id: 'field.remark' })} />
        </Form>
    </>
}

const getAddFormCheckFields = () => {
    return ({
        ftaExchangeRate: { labelId: 'ftaExchangeRate', required: true, length: { max: 9, integer: 9, scale: 6 }, compare: { min: 0 } },
        customExchangeRate: { labelId: 'customExchangeRate', required: true, length: { max: 9, integer: 9, scale: 6 }, compare: { min: 0 } },
        effectiveStartDate: { labelId: 'effectiveStartDate', required: true },
        effectiveEndDate: { labelId: 'effectiveEndDate', required: true },
        fobInvValueLogic: { labelId: 'fobInvValueLogic', required: true },
        remark: { labelId: 'field.remark', compare: { max: 50 } },
    })
}

const DeleteRowAction = ({ tableRow, setFOBSettingDetailList, setDeleteIdList }: DataGridRowActionProps & { setFOBSettingDetailList: React.Dispatch<React.SetStateAction<FOBSettingDetail[]>>, setDeleteIdList: React.Dispatch<React.SetStateAction<number[]>> }) => {
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'delete' }), [intl])
    const callback = useCallback((tableRow: TableRow) => {
        setFOBSettingDetailList(detail => detail.filter((_, index) => index !== tableRow.rowId))
        if (tableRow.row?.fobSettingDetailId !== undefined) {
            let fobSettingDetailId = tableRow.row.fobSettingDetailId
            const functionId = functionStore.register(() => {
                setDeleteIdList(detail => [...detail, fobSettingDetailId])
            })
            dispatch(applicationActions.pushWarning({
                title: title,
                messages: { code: 'c0001', args: [title] },
                actions:[{
                    label: 'CANCEL'
                },{
                    functionId,
                    label:'CONFIRM',
                }]
            }))
        }
    }, [setFOBSettingDetailList, functionStore, dispatch, title, setDeleteIdList])
    return <DeleteCallbackRowAction access="MARS.MLS171.DELETE" tableRow={tableRow} callback={callback} />
}

const SaveAction = (props: { mode: ScreenMode, dataInfo: FOBSettingInfo, details: FOBSettingDetail[], search: (fobSettingId: number | null) => void, setMessages: React.Dispatch<React.SetStateAction<Message[]>>, fobSettingId: number | null, deleteIdList: number[] }) => {
    const { mode, dataInfo, details, setMessages, search, fobSettingId, deleteIdList } = props
    const fields = useMemo(() => getFormCheckFields(), [])
    const formValidate = useFormValidater(setMessages, fields)
    const [open, setOpen] = React.useState(false)
    const [data, setData] = useState<FOBSettingDetailResult>({} as FOBSettingDetailResult)
    const navigate = useNavigate()
    const [disabled, setDisabled] = useState<boolean>(false)
    const saveFOBSettingDetail = useSaveFOBSettingDetail()
    const saveData = useCallback(() => {
        if (!formValidate(dataInfo)) {
            return
        }
        const data: FOBSettingDetailResult = {
            fobSettingInfo: {
                fobSettingId: dataInfo.fobSettingId,
                invCurrencyCode: dataInfo.invCurrencyCode,
                contrRouteId: dataInfo.contrRouteId,
                consignee: dataInfo.consignee,
                ftaInvoice: dataInfo.ftaInvoice,
                customClearanceInvoice: dataInfo.customClearanceInvoice,
                createdBy: dataInfo.createdBy,
                createdDate: dataInfo.createdDate,
                updatedBy: dataInfo.updatedBy,
                updatedDate: dataInfo.updatedDate,
                version: dataInfo.version
            },
            details: [...details],
            deleteIdList: [...deleteIdList]
        }
        if (mode === ScreenMode.CREATE) {
            setDisabled(true)
            saveFOBSettingDetail(data, { serialized: true }).then((result) => {
                if (0 === result.isConfirm) {
                    setData(result)
                    setOpen(true)
                } else {
                    navigate({ pathname: `/fobSetting/edit-` + result.fobSettingInfo.fobSettingId })
                }
            }).finally(() => {
                setDisabled(false)
            })
        } else {
            setDisabled(true)
            saveFOBSettingDetail(data, { serialized: true }).then((result) => {
                if (0 === result.isConfirm) {
                    setData(result)
                    setOpen(true)
                } else {
                    search(result.fobSettingInfo.fobSettingId ?? fobSettingId)
                }
            }).finally(() => {
                setDisabled(false)
            })
        }
    }, [formValidate, dataInfo, details, deleteIdList, mode, saveFOBSettingDetail, navigate, search, fobSettingId])

    return <>
        <SaveCallbackViewAction access="MARS.MLS171.SAVE" callback={saveData} disabled={disabled}/>
        <ConfirmAction open={open} setOpen={setOpen} data={data} />
    </>
}

const getFormCheckFields = () => {
    return ({
        invCurrencyCode: { labelId: 'invCurrencyCode', required: true },
        contrRouteId: { labelId: 'field.contrRouteCode', required: true },
        consignee: { labelId: 'consignee', required: true },
        ftaInvoice: { labelId: 'ftaInvoice', required: true },
        customClearanceInvoice: { labelId: 'customClearanceInvoice', required: true },
    })
}

const ConfirmAction = (props: { open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>>, data: FOBSettingDetailResult }) => {
    const { open, setOpen, data } = props
    const navigate = useNavigate()
    const saveFOBSettingDetail = useSaveFOBSettingDetail()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'confirm' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const closePop = useCallback(() => {
        setOpen(false)
    }, [setOpen])
    const clickEvent = useCallback(() => {
        setOpen(false)
        const functionId = functionStore.register(() => {
            setDisabled(true)
            saveFOBSettingDetail({ ...data, isConfirm: 1 }, { serialized: true }).then((result) => {
                navigate(`/fobSetting/edit-` + result.fobSettingInfo.fobSettingId)
            }).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions:[{
                label: 'CANCEL'
            },{
                functionId,
                label:'CONFIRM',
            }]
        }))
    }, [data, dispatch, functionStore, navigate, saveFOBSettingDetail, setOpen, title])
    return <>
        <Dialog open={open} onClose={closePop}>
            <DialogHeader onClose={() => setOpen(false)}><FormattedMessage id="FOBSettingPopTitle" /></DialogHeader>
            <DialogContent>
                <FormattedMessage id="FOBSettingConfirmLable" />
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={closePop} />
                <DialogAction title={<FormattedMessage id="confirm" />} callback={clickEvent} disabled={disabled}/>
            </DialogActions>
        </Dialog >
    </>
}