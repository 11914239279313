import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useGetPaymentStatusList } from "../../../services/accounting/apis/accountingBuyerApi"
import { ARTransfer } from "../../../services/accounting/models/ARTransfer"
import { ARView } from "../../../services/accounting/models/ArView"
import { BuyerInvoiceView, buyerInvoiceViewFromJson, buyerInvoiceViewToJson } from "../../../services/accounting/models/BuyerInvoiceView"
import { useComMasterGetAllCbds, useComMasterGetAllCurrency } from "../../../services/common/apis/commonMasterApi"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { TnmCurrency } from "../../../services/master/models/TnmCurrency"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { AIS060PcUi } from "./AIS060PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }
const searchCachekey = sessionKeys.Filters_AIS060
export const AIS060 = () => {

    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? buyerInvoiceViewFromJson(JSON.parse(filters)) : null
    }, [])

    const [filters, setFilters] = useState<ARView>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<ARTransfer[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [currencys, setCurrencys] = useState<TnmCurrency[]>([])
    const [buyers, setBuyers] = useState<TnvCbds[]>([])
    const getPaymentStatusList = useGetPaymentStatusList()
    const getAllCurrency = useComMasterGetAllCurrency()
    const getAllCbds = useComMasterGetAllCbds()


    useEffect(() => {
        getAllCurrency(undefined, { silent: true }).then(result => {
            setCurrencys(result || [])
        })
        getAllCbds({ types: [CbdsType.CUST, CbdsType.BU] }, { silent: true }).then(result => {
            setBuyers(result || [])
        })
    }, [getAllCbds, getAllCurrency])

    const search = useCallback((filters: BuyerInvoiceView) => {
        getPaymentStatusList(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(buyerInvoiceViewToJson(filters)))
        })
    }, [getPaymentStatusList])

    useSearchOnLoad(search, filters)

    return <AIS060PcUi
        filters={filters}
        setFilters={setFilters}
        search={search}
        data={data}
        totalCount={totalCount}
        currencys={currencys}
        buyers={buyers}
    />
}