import { useCallback } from "react"
import { Projects } from "../../../utils/Projects"
import { usePost } from "../../../utils/fetch/useFetch"
import { Pair, pairFromJson } from "../../utils/Pair"
import { OrderGroupTransfer, OrderGroupTransferFromJson } from "../models/OrderGroupTransfer"

export const useGetCustomerByLoginUser = () => {
    const url = '/smt-api/custparts/getCustomerByBu'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(pairFromJson), [])
    return usePost<undefined, Pair[]>(url, payloadProject, resultProject)
}

export const useGetBuyerByLoginUser = () => {
    const url = '/smt-api/custparts/getBuyerByBu'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(pairFromJson), [])
    return usePost<undefined, Pair[]>(url, payloadProject, resultProject)
}

export const useGetSellerByLoginUser = () => {
    const url = '/smt-api/custparts/getSellerByCustomer'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(pairFromJson), [])
    return usePost<undefined, Pair[]>(url, payloadProject, resultProject)
}

// 2021.4.1
export const useGetSuppliers = () => {
    const url = '/lcbm-system-master-api/api/supplier/getAllForCombo'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(pairFromJson), [])
    return usePost<undefined, Pair[]>(url, payloadProject, resultProject)
}

export const useGetOrderGroups = () => {
    const url = '/smt-api/ordermng/getAllOrderGroupList'
    const payloadProject = useCallback((obj: { customerId?: number, buyerBuId?: number }) => obj, [])
    const resultProject = useCallback(Projects.array(OrderGroupTransferFromJson), [])
    return usePost<{ customerId?: number, buyerBuId?: number, enhancementFlag: number }, OrderGroupTransfer[]>(url, payloadProject, resultProject)
}


