import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { PaymentTermsFactor, paymentTermsFactorToJson } from "../models/PaymentTermsFactor"
import { PaymentTermsResult, paymentTermsResultFromJson } from "../models/PaymentTermsResult"
import { TnmPaymentTerms, tnmPaymentTermsFromJson, tnmPaymentTermsToJson } from "../models/TnmPaymentTerms"


export const useGetPaymentTermsList = () => {
    const url = '/lcbm-local-master-api/api/getPaymentTermsList'
    const payloadProject = useCallback(paymentTermsFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(paymentTermsResultFromJson), [])
    return usePost<PaymentTermsFactor, PageResultEntity<PaymentTermsResult>>(url, payloadProject, resultProject)
}

export const useGetPaymentTermsDetail = () => {
    const url = '/roe-api/master/api/getPaymentTermsDetail'
    const payloadProject = useCallback((obj: { paymentTermsId: number }) => obj.paymentTermsId, [])
    const resultProject = useCallback(tnmPaymentTermsFromJson, [])
    return usePost<{ paymentTermsId: number }, TnmPaymentTerms>(url, payloadProject, resultProject)
}

export const useSavePaymentTermsDetail = () => {
    const url = '/lcbm-local-master-api/api/savePaymentTermsDetail'
    const payloadProject = useCallback(tnmPaymentTermsToJson, [])
    const resultProject = useCallback(tnmPaymentTermsFromJson, [])
    return usePost<TnmPaymentTerms, TnmPaymentTerms>(url, payloadProject, resultProject)
}
