import { FormattedMessage } from 'react-intl'
import { CallbackViewAction } from './CallbackViewAction'

interface ConfirmCallbackViewActionProps {
    access?: string,
    power?: boolean,
    title?: React.ReactNode,
    disabled?: boolean,
    outlined?: boolean,
    callback: () => void,
}

export const ConfirmCallbackViewAction = (props: ConfirmCallbackViewActionProps) => {
    return <CallbackViewAction {...props} title={<FormattedMessage id="confirm" />} />
}