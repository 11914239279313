import { DialogActions, DialogContent, IconButton, Tooltip } from "@material-ui/core"
import { Clear, SaveOutlined } from "@material-ui/icons"
import { Action, Column, ColumnFreeze, ColumnOrdering, ColumnResizing, Data, DataGrid, DataTypePreset, Editing, Filtering, NumberTypeProvider, PaginationLayout, Row, RowActionProvider, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { Break, DateItem, EntryItem, Form, Message, NumberItem, StringItem } from "@rithe/form"
import { Arrays, Records } from "@rithe/utils"
import moment from "moment"
import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { Access } from "../../../components/Access/Access"
import { CallbackViewAction } from "../../../components/Action/CallbackViewAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/CallbackToolbarAction"
import { CreateCallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/CreateCallbackToolbarAction"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { UploadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/UploadGroupedToolbarAction"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { LibertyTypeProvider } from "../../../components/DataGrid/typeProviders/LibertyTypeProvider"
import { DarkDialog } from "../../../components/Dialog/DarkDialog"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { View } from "../../../components/View/View"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { TnmUom } from "../../../services/master/models/TnmUom"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { useCancelPCWCDetail, useConfirmPCWCDetail, useTempSavePCWCDetail } from "../../../services/order/apis/ChangeRequestApi"
import { useDownloadPlaceOrderChangeWithoutContract } from "../../../services/order/apis/OrderDownloadApi"
import { useUploadPlaceChangeWithContract } from "../../../services/order/apis/OrderUploadApi"
import { ParamRequestToJson } from "../../../services/order/models/ChangeRequestWithoutContractListResult"
import { PartsDetail, PlaceChangeWCDetailResult } from "../../../services/order/models/PlaceChangeWCDetailResult"
import sessionKeys from "../../../utils/sessionKeys"
import { useSetDataIntoSession } from "../../../utils/sessionUtil"
import { useFieldChecker, useFormValidater } from "../../../utils/ValidatorUtils"
import { applicationActions } from "../../Application/applicationSlice"
import { PlaceChangeWCResultBasic, useMergeDatas, useSplitDatas } from "./OCS026"
import { useShippingPlanCategory, useShippingPlanCategory2 } from "./ShippingPlanCategory"

interface OCS026PcUiProps {
    search: (orderId: number | null | undefined, roSpotId: number | null | undefined) => void,
    basic: PlaceChangeWCResultBasic,
    setBasic: React.Dispatch<React.SetStateAction<PlaceChangeWCResultBasic>>,
    inboundPlans: PartsDetail[],
    setInboundPlans: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
    mode: ScreenMode,
    uomList: TnmUom[],
    receiveDcList: TnvCbds[],

}

export const OCS026PcUi = (props: OCS026PcUiProps) => {
    const { search, basic, setBasic, inboundPlans, uomList, setInboundPlans, receiveDcList } = props

    const intl = useIntl()
    const [messages, setMessages] = useState<Message[]>([] as Message[])
    const uomArr = useMemo(() => Arrays.distinct(uomList.map((m) => m.uomCode)), [uomList])
    const showButton = (!basic.roSpotIdInRo || (basic.roSpotIdInRo && (basic.statusInRo === 10 || basic.statusInRo === 25 || basic.statusInRo === 80))) ? true : false


    return <View actions={showButton ? [<ConfirmAction basic={basic} inboundPlans={inboundPlans} search={search} />,] : []}>
        < SectionCard allowCollapse>
            <SectionCardHeader
                step
                serialNumber={1}
                title={intl.formatMessage({ id: 'downloadOrUploadTitle' })}
                subtitle={intl.formatMessage({ id: 'downloadOrUploadTitleSub' })}
                actions={showButton ? [<DownloadAction showButton={showButton} search={search} setInboundPlans={setInboundPlans} uomArr={uomArr} editable={true} basic={basic} setBasic={setBasic} inboundPlans={inboundPlans} />, <UploadAction showButton={showButton} search={search} uomArr={uomArr} editable={true} basic={basic} setBasic={setBasic} inboundPlans={inboundPlans} setInboundPlans={setInboundPlans} />] : []}
            />
            <SectionCardContent>
            </SectionCardContent>
        </SectionCard >

        <SectionCard allowCollapse>
            <SectionCardHeader
                step
                serialNumber={2}
                title={intl.formatMessage({ id: 'inputOrderFcTitle' })}
                subtitle={intl.formatMessage({ id: 'inputShippingPlanTitleSub' })}
                actions={showButton ? [<TempSave basic={basic} search={search} inboundPlans={inboundPlans} setMessages={setMessages}></TempSave>] : []}
            />
            <SectionCardContent>
                <ShippingPlanTable showButton={showButton} search={search} setInboundPlans={setInboundPlans} uomArr={uomArr} editable={true} basic={basic} setBasic={setBasic} inboundPlans={inboundPlans} />
            </SectionCardContent>
        </SectionCard>

        <SectionCard allowCollapse>
            <SectionCardHeader
                step
                serialNumber={3}
                title={intl.formatMessage({ id: 'inputInboundPlanTitle' })}
                subtitle={intl.formatMessage({ id: 'inputShippingPlanTitleSub' })}
            />
            <SectionCardContent>
                <ShippingPlanTable2 showButton={showButton} search={search} setInboundPlans={setInboundPlans} uomArr={uomArr} editable={true} basic={basic} setBasic={setBasic} inboundPlans={inboundPlans} />
            </SectionCardContent>
        </SectionCard>

        <SectionCard allowCollapse>
            <SectionCardHeader
                step
                serialNumber={4}
                title={intl.formatMessage({ id: 'basicInfo' })}
                subtitle={intl.formatMessage({ id: 'inputOrderQTYForEachPNTitleSub' })}
            />
            <SectionCardContent>
                <OrderSummaryForm showButton={showButton} basic={basic} setBasic={setBasic} messages={messages} receiveDcList={receiveDcList} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const OrderSummaryForm = ({ basic, setBasic, messages, showButton, receiveDcList }: {
    basic: PlaceChangeWCResultBasic,
    setBasic: React.Dispatch<React.SetStateAction<PlaceChangeWCResultBasic>>,
    messages: Message[],
    showButton: boolean,
    receiveDcList: TnvCbds[],
}) => {
    const dcMap: [number, string][] = useMemo(() => receiveDcList.filter((m) => m.cbdsType === CbdsType.DC).map(m => [m.cbdsId, m.cbdsCode]), [receiveDcList])
    const intl = useIntl()
    const fields = getFormCheckFields(false)
    const filedCheck = useFieldChecker(fields)

    return <Form data={basic} setData={setBasic} labelDisplay="block" helperDisplay="tooltip" ensure={filedCheck} messages={messages} columnCount={3}>
        <StringItem field='orderReference' readonly={true} label={intl.formatMessage({ id: 'orderReference' })} />
        <EntryItem field="receiveDcId" readonly={true} label={intl.formatMessage({ id: 'receiveDcCode' })} entries={dcMap} />
        <Break />
        <NumberItem field="totalAmount" readonly label={intl.formatMessage({ id: 'totalAmount' })} />
        <NumberItem field="totalNumberOfParts" readonly label={intl.formatMessage({ id: 'totalNumberofParts' })} />
        <NumberItem field="totalQty" readonly label={intl.formatMessage({ id: 'totalQty' })} />
        <Break />
        <StringItem field="remark" colSpan={2} readonly={true} label={intl.formatMessage({ id: 'field.remark' })} />
    </Form>
}

const TempSave = ({ basic, setMessages, inboundPlans, search }: {
    basic: PlaceChangeWCResultBasic,
    inboundPlans: PartsDetail[],
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
    search: (orderId: number | null | undefined, roSpotId: number | null | undefined) => void,
}) => {
    const saveInfo = useTempSavePCWCDetail()
    const mergeDatas = useMergeDatas()
    const dispatch = useDispatch()
    const fields = useMemo(() => getFormCheckFields(false), [])
    const formValidate = useFormValidater(setMessages, fields)
    const setSesion = useSetDataIntoSession()
    const [disabled, setDisabled] = useState<boolean>(false)
    const onclickToSave = useCallback(() => {
        let flag = true
        if (inboundPlans && inboundPlans.length > 0) {
            inboundPlans.forEach(val => {
                if (!checkNumber(val.orderQtyInRo, 1)) {
                    flag = false
                    dispatch(applicationActions.pushError({ title: { code: 'firmQty' }, messages: { code: 'integerCheck' } }))
                    return
                }
            })
        }
        if (!flag) return
        if (formValidate(basic)) {
            const data = mergeDatas(basic, inboundPlans)
            setDisabled(true)
            saveInfo(data, { serialized: true }).then(result => {
                if (result) {
                    setSesion(sessionKeys.Data_OCS026, ParamRequestToJson, {
                        coSpotId: result.coSpotId,
                        roSpotId: result.roSpotIdInRo,
                    })
                    search(result.coSpotId, result.roSpotIdInRo)
                }
            }).finally(() => {
                setDisabled(false)
            })
        }

    }, [basic, dispatch, formValidate, inboundPlans, mergeDatas, saveInfo, search, setSesion])

    return <CallbackToolbarAction access="ORDER.OCS026.TEMPSAVE" callback={onclickToSave} disabled={disabled} title={<FormattedMessage id="tempSave" />} icon={<SaveOutlined />} />
}


const getFormCheckFields = (required: boolean) => {
    return {
        orderReference: { labelId: 'orderReference', required: required, length: { max: 30 } },
        remark: { labelId: 'remark', required: required, length: { max: 255 } }
    }
}

interface ShippingPlanProps {
    basic: PlaceChangeWCResultBasic,
    setBasic: React.Dispatch<React.SetStateAction<PlaceChangeWCResultBasic>>,
    inboundPlans: PartsDetail[],
    setInboundPlans: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
    editable: boolean,
    uomArr: string[],
    search: (orderId: number | null | undefined, roSpotId: number | null | undefined) => void,
    showButton: boolean
}



const ShippingPlanTable = memo((props: ShippingPlanProps) => {
    const { inboundPlans, editable, basic, search, uomArr, setInboundPlans, showButton } = props
    const intl = useIntl()
    const [order, setOrder] = useState<string[]>([])
    const columns = useMemo(() => {
        const fixedColumns = [
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
            { field: 'partsDescription', dataTypeName: 'string', title: intl.formatMessage({ id: 'partsDescription' }), width: 350 },
            { field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'unitPartsNo' }), width: 220 },
            { field: 'sellerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'sellerCode' }), width: 220 },
            { field: 'sellerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'sellerPartsNo' }), width: 220 },
            { field: 'uomCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'uomCode' }), width: 180 },
            { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 180 },
            { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 150 },
            { field: 'oldFirmQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.oldFirmQty' }), width: 150 },
            { field: 'orderQtyInRo', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.newFirmQty' }), width: 150 },
            { field: 'statusInRo', dataTypeName: CodeCategory.RoSpotDetailStatus, title: intl.formatMessage({ id: 'field.status' }), width: 150 },
        ] as Column[]
        return Arrays.concat(fixedColumns)
    }, [intl])

    const mergeDatas = useMergeDatas()
    const data = mergeDatas(basic, inboundPlans)
    const actionProps = useMemo(() => ({ inboundPlans, data, search }), [inboundPlans, data, search])
    const defaultEditingDisabled = showButton ?
        Records.from(columns.filter(f => !f.field.includes('orderQtyInRo')).map(({ field }) => [field, { editingDisabled: true }]))
        :
        Records.from(columns.filter(f => !f.field.includes('-1')).map(({ field }) => [field, { editingDisabled: true }]))
    const onEditingCellCommit = useCallback((_column: Column, row: Row) => {
        setInboundPlans(inboundPlans => inboundPlans.map(item => item.coSpotDetailId === row.coSpotDetailId ? row as PartsDetail : item))
        return true
    }, [setInboundPlans])

    useEffect(() => {
        setOrder(columns.map(column => column.field))
    }, [columns])

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <Data rows={inboundPlans} columns={columns} />
            <LibertyTypeProvider name="uomType" options={uomArr} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.RoSpotDetailStatus} />
            <RowActionProvider name="reject" Action={CancelPartsRowAction} actionProps={actionProps} />
            <ColumnFreeze />
            <ColumnOrdering order={order} onOrderChange={setOrder} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
            <Editing
                enableInlineEdit={editable ? true : false}
                columnSettings={defaultEditingDisabled}
                onEditingCellCommit={onEditingCellCommit}
            />

            <Action width={200} />
        </DataGrid>
    </div>
})

const ShippingPlanTable2 = memo((props: ShippingPlanProps) => {
    const { inboundPlans, setInboundPlans, editable, uomArr, showButton } = props
    const intl = useIntl()
    const [order, setOrder] = useState<string[]>([])
    const oldPlanDates = useMemo(() => {
        return inboundPlans && inboundPlans.length > 0 ? Arrays.distinct(inboundPlans.flatMap(parts => parts.oldReqInboundPlanList).map(plan => (plan.crdDate ?? '').getTime())).sort().map(m => new Date(m)) : []
    }, [inboundPlans])

    const newPlanDates = useMemo(() => {
        return inboundPlans && inboundPlans.length > 0 ? Arrays.distinct(inboundPlans.flatMap(parts => parts.newReqInboundPlanList).map(plan => (plan.crdDate ?? '').getTime())).sort().map(m => new Date(m)) : []
    }, [inboundPlans])

    const columns = useMemo(() => {
        const fixedColumns = [
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
            { field: 'partsDescription', dataTypeName: 'string', title: intl.formatMessage({ id: 'partsDescription' }), width: 350 },
            { field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'unitPartsNo' }), width: 220 },
            { field: 'sellerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'sellerPartsNo' }), width: 220 },
            { field: 'uomCode', dataTypeName: 'uomType', title: intl.formatMessage({ id: 'uomCode' }), width: 180 },
            { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 180 },
            { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 150 },
        ] as Column[]

        const firmCategories = [{ key: 'firm', value: intl.formatMessage({ id: 'orderQty' }) }]
        const firmColumns = [{ field: 'oldFirmQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.oldFirmQty' }), categories: firmCategories, width: 150 },
        { field: 'orderQtyInRo', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.newFirmQty' }), categories: firmCategories, width: 150 }]

        //old
        const getOldInboundPlanCategories = (index: number) => [
            { key: 'inbound', value: intl.formatMessage({ id: 'oldReqInboundPlanList' }) },
            { key: `inbound${index}`, value: intl.formatDate(oldPlanDates[index], { dateStyle: 'medium' }) },
        ]
        const oldInboundPlanColumns = Arrays.range(0, oldPlanDates.length).flatMap(index => [
            { field: 'crdQty' + index, dataTypeName: 'number', title: intl.formatMessage({ id: 'Qty' }), categories: getOldInboundPlanCategories(index), width: 150, getCellValue: (row: Row) => row.oldReqInboundPlanList.find((f: any) => f.crdDate.getTime() === oldPlanDates[index].getTime())?.crdQty ?? null, },
        ])

        //new
        const getNewInboundPlanCategories = (index: number) => [
            { key: 'esplan', value: intl.formatMessage({ id: 'newReqInboundPlanList' }) },
            { key: `plan2${index}`, value: intl.formatDate(newPlanDates[index], { dateStyle: 'medium' }) }]
        const dataTypeName = showButton ? 'shippingPlan' : 'shippingPlan2'
        const newInboundPlanColumns = newPlanDates.map((planDate, index) => ({
            field: `planQty2${index}`,
            dataTypeName: dataTypeName,
            title: intl.formatMessage({ id: 'Qty' }),
            categories: getNewInboundPlanCategories(index),
            width: 250,
            getCellValue: (row: Row) => row.newReqInboundPlanList.find((f: any) => f.crdDate.getTime() === planDate.getTime())?.crdQty ?? null,
            setCellValue: (row: Row, value: any) => {
                // do fiter
                if (row.newReqInboundPlanList?.some((plan: any) => plan.crdDate.getTime() === planDate.getTime())) {
                    return ({ ...row, newReqInboundPlanList: row.newReqInboundPlanList?.map((plan: any) => plan.crdDate.getTime() === planDate.getTime() ? { ...plan, crdQty: value } : plan) })
                } else {
                    const newReqInboundPlanList = row.newReqInboundPlanList
                    newReqInboundPlanList.push({
                        crdDate: planDate,
                        crdQty: value,
                    })
                    return ({ ...row, newReqInboundPlanList: newReqInboundPlanList })
                }
            }
        }))

        return Arrays.concat(fixedColumns, firmColumns, oldInboundPlanColumns, newInboundPlanColumns)
    }, [intl, newPlanDates, oldPlanDates, showButton])

    const onEditingCellCommit = useCallback((_column: Column, row: Row) => {
        setInboundPlans(inboundPlans => inboundPlans.map(item => item.coSpotDetailId === row.coSpotDetailId ? row as PartsDetail : item))
        return true
    }, [setInboundPlans])

    const defaultEditingDisabled = showButton ?
        Records.from(columns.filter(f => !f.field.includes('planQty2')).map(({ field }) => [field, { editingDisabled: true }]))
        :
        Records.from(columns.filter(f => !f.field.includes('-1')).map(({ field }) => [field, { editingDisabled: true }]))
    const [actions, setActions] = useState<RequestInboundPlanProps>({ open: false, editDate: null })
    const AddnewAction = useMemo(() => ({ setActions }), [setActions])
    const clickHeader = useCallback((field: string) => {
        field.startsWith("planQty2") && setActions({ open: true, editDate: newPlanDates[Number(field.substring(8))] })
    }, [newPlanDates])
    const shippingPlanCategory = useShippingPlanCategory(clickHeader)
    const shippingPlanCategory2 = useShippingPlanCategory2()


    useEffect(() => {
        setOrder(columns.map(column => column.field))
    }, [columns])

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <Data rows={inboundPlans} columns={columns} />
            <NumberTypeProvider name="shippingPlan" Category={shippingPlanCategory} />
            <NumberTypeProvider name="shippingPlan2" Category={shippingPlanCategory2} />
            <LibertyTypeProvider name="uomType" options={uomArr} />
            <ToolbarActionProvider Action={CreateAction} actionProps={AddnewAction} display={() => showButton} />
            <ColumnFreeze />
            <ColumnOrdering order={order} onOrderChange={setOrder} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
            <Editing
                enableInlineEdit={editable ? true : false}
                onEditingCellCommit={onEditingCellCommit}
                columnSettings={defaultEditingDisabled}
            />

            <Action width={200} />
        </DataGrid>
        <AddNewRequestDialog actions={actions} setActions={setActions} inboundPlans={inboundPlans} setInboundPlans={setInboundPlans} />
    </div>
})

interface RequestInboundPlanProps {
    open: boolean,
    editDate: Date | null
}
const CreateAction = ({ setActions }: { setActions: React.Dispatch<React.SetStateAction<RequestInboundPlanProps>> }) => {
    return <CreateCallbackToolbarAction access="ORDER.OCS026.CREATE" title={<FormattedMessage id="Add New Plan" />} callback={() => setActions({ open: true, editDate: null })} />
}
const AddNewRequestDialog = ({ actions, setActions, inboundPlans, setInboundPlans }: {
    actions: RequestInboundPlanProps,
    setActions: React.Dispatch<React.SetStateAction<RequestInboundPlanProps>>,
    inboundPlans: PartsDetail[],
    setInboundPlans: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
}) => {
    const dispatch = useDispatch()
    const { open, editDate } = actions
    const [factor, setFactor] = useState<{ inboundPlanDate: Date | null }>({ inboundPlanDate: null })
    const handleClose = useCallback(() => setActions({ open: false, editDate: null }), [setActions])

    const intl = useIntl()

    useEffect(() => {
        setFactor({ inboundPlanDate: editDate })
    }, [editDate])

    const aplyDate = useCallback(() => {
        if (!factor.inboundPlanDate) {
            dispatch(applicationActions.pushError({ title: { code: 'confirm' }, messages: { code: 'w0441' } }))
            return
        } else {
            const inboundPlanDate = factor.inboundPlanDate
            if (inboundPlans.flatMap(f => f.newReqInboundPlanList).some(s => moment(s.crdDate).format(moment.HTML5_FMT.DATE) === moment(inboundPlanDate).format(moment.HTML5_FMT.DATE))
                && (!editDate || moment(editDate).format(moment.HTML5_FMT.DATE) !== moment(inboundPlanDate).format(moment.HTML5_FMT.DATE))) {
                dispatch(applicationActions.pushError({ title: { code: 'confirm' }, messages: { code: 'w0381' } }))
                return
            } else {
                setInboundPlans(inboundPlans => inboundPlans.map(m => ({
                    ...m, newReqInboundPlanList: !editDate ? [...m.newReqInboundPlanList, {
                        crdDate: inboundPlanDate,
                        crdQty: null
                    }] : m.newReqInboundPlanList.map(plan => plan.crdDate.getTime() === editDate.getTime() ? { ...plan, crdDate: inboundPlanDate } : plan)
                })))
            }
        }
        // do reflect
        setFactor({ inboundPlanDate: null })
        setActions({ open: false, editDate: null })
    }, [dispatch, editDate, factor.inboundPlanDate, inboundPlans, setActions, setInboundPlans])

    return <>
        <DarkDialog open={open} style={{ overflow: 'hidden' }} maxWidth="sm" fullWidth keepMounted={false} fullScreen={false}>
            <DialogHeader onClose={handleClose}><FormattedMessage id="inputInboundDate" /></DialogHeader>
            <DialogContent>
                <Form data={factor} setData={setFactor} labelDisplay="block" helperDisplay="tooltip" columnCount={1} minWidth={500} maxWidth={500}  >
                    <DateItem required field="inboundPlanDate" labelWidth={120} label={intl.formatMessage({ id: 'field.inboundPlanDate' })} />
                </Form>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={handleClose} />
                <DialogAction title={<FormattedMessage id="confirm" />} callback={aplyDate} />
            </DialogActions>
        </DarkDialog>
    </>
}


const UploadAction = (props: ShippingPlanProps) => {
    const { basic, setBasic, setInboundPlans } = props
    const uploadReSpotOrder = useUploadPlaceChangeWithContract()
    const splitDatas = useSplitDatas()

    const upload = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            files && uploadReSpotOrder({ file: files[0], coSpotId: basic.coSpotId, roSpotId: basic.roSpotIdInRo }, { serialized: true }).then(result => {
                if (result) {
                    const { orderbasic, orderInboundPlans } = splitDatas(result)
                    setBasic(orderbasic)
                    setInboundPlans(orderInboundPlans)
                }
            })
        })
    }, [basic.coSpotId, basic.roSpotIdInRo, setBasic, setInboundPlans, splitDatas, uploadReSpotOrder])

    return <UploadGroupedToolbarAction access="ORDER.OCS026.UPLOAD">
        {(popupUpload, onClose) => <>
            <GroupedCallbackItem label={<FormattedMessage id="upload" />} callback={() => upload(popupUpload)} onClose={onClose} />
        </>}
    </UploadGroupedToolbarAction>
}

const DownloadAction = (props: ShippingPlanProps) => {
    const { basic, inboundPlans } = props
    const mergeDatas = useMergeDatas()
    const down = useDownloadPlaceOrderChangeWithoutContract()
    const download = useCallback(() => {
        const data = mergeDatas(basic, inboundPlans)
        down(data)
    }, [basic, down, inboundPlans, mergeDatas])
    return <DownloadGroupedToolbarAction access="ORDER.OCS026.DOWNLOAD">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="download" />} callback={download} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}

const checkNumber = (field: number, type: number) => {
    if (type === 1) {
        if (field && field <= 0) {
            return false
        }
        return true
    } else if (type === 2) {
        if (field && field < 0) {
            return false
        }
        return true
    }

}


const CancelPartsRowAction = ({ tableRow, data, search }: DataGridRowActionProps & { data: PlaceChangeWCDetailResult, search: (orderId: number | null | undefined, roSpotId: number | null | undefined) => void, }) => {
    const cancelParts = useCancelPCWCDetail()
    const detailId = tableRow.row?.coSpotDetailId
    const detailData: PartsDetail = data.totCoSpotDetailList.filter(m => m.coSpotDetailId === detailId)[0]
    const viewClick = useCallback(() => {
        cancelParts(detailData, { serialized: true }).then(result => {
            if (result) {
                search(result.coSpotId, data.roSpotIdInRo)
            }
        })
    }, [cancelParts, data.roSpotIdInRo, detailData, search])

    const detailStatus = tableRow.row?.statusInRo

    if (detailStatus === 90) {
        return <>
            <Access access="ORDER.OCS026.REJECT">
                <Tooltip title="Cancel">
                    <IconButton onClick={viewClick}><Clear /></IconButton>
                </Tooltip>
            </Access>
        </>
    } else {
        return <></>
    }

}


const ConfirmAction = memo((props: {
    basic: PlaceChangeWCResultBasic, inboundPlans: PartsDetail[], search: (orderId: number | null | undefined, roSpotId: number | null | undefined) => void
}) => {
    const { basic, inboundPlans, search } = props
    const mergeDatas = useMergeDatas()
    const confirm = useConfirmPCWCDetail()
    const dispatch = useDispatch()

    const onclickSave = useCallback(() => {
        //check
        let flag = true
        if (inboundPlans && inboundPlans.length > 0) {
            inboundPlans.forEach(val => {
                if (!checkNumber(val.orderQtyInRo, 1)) {
                    flag = false
                    dispatch(applicationActions.pushError({ title: { code: 'firmQty' }, messages: { code: 'integerCheck' } }))
                    return
                }
            })
        }
        if (!flag) return
        //confirm
        const data = mergeDatas(basic, inboundPlans)
        confirm(data, { serialized: true }).then(result => {
            if (result) {
                search(result.coSpotId, result.roSpotIdInRo)
            }

        })
    }, [inboundPlans, mergeDatas, basic, confirm, dispatch, search])

    return <CallbackViewAction access="ORDER.OCS026.CONFRIM" title={<FormattedMessage id="comfirm" />} callback={onclickSave} />


})

