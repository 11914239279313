import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson, tableEntityToJson } from "../../utils/TableEntity"
import { ActiveFlag } from "../enums/ActiveFlag"
import { CbdsType } from "../enums/CbdsType"

export type TnvCbds = Required & Partial<Optional> & TableEntity
export type TnvCbdsJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const tnvCbdsToJson: Project<TnvCbds, TnvCbdsJson> = obj => ({
    ...tableEntityToJson(obj),
})
export const tnvCbdsFromJson: Project<TnvCbdsJson, TnvCbds> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
    cbdsType: CbdsType,
}

interface Optional extends JsonOptional {
    activeFlag: ActiveFlag,
}

interface JsonRequired {
    cbdsUid: string,
    cbdsType: number,
    cbdsId: number,
    cbdsCode: string,
}

interface JsonOptional {
    cbdsName: string,
    cbdsShortCode: string,
    companyId: number,
    regionCode: string,
    address1: string,
    address2: string,
    address3: string,
    address4: string,
    contact1: string,
    telephone1: string,
    fax1: string,
    email1: string,
    postalCode: string,
    contact2: string,
    telephone2: string,
    fax2: string,
    email2: string,
    calendarId: number,
    timeZone: string,
    activeFlag: number,
}