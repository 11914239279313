import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { dateToJson } from "../../utils/serializer"


export type ReceivedPCDetailResult = ReceivedPCResultRequired & Partial<ReceivedPCResultOptional>
export type ReceivedPCDetailResultson = ReceivedPCResultJsonRequired & Partial<ReceivedPCResultJsonOptional>

//co detail
interface ReceivedPCResultJsonRequired {
    totSoRoSpotDetailList: ReceivedPCPartsDetailJson[],
    status: number,
}
interface ReceivedPCResultJsonOptional {
    //co
    soSpotId: number,
    customerOrderNo: string,
    // orderReference: string,
    // sellerCode: string,
    submittedBy: string
    submittedDate: string,
    // orderType: number,
    status: number,
    version: number,
    //ro
    roSpotIdInRo: number,
    coSpotIdInRo: number,
    statusInRo: number,
    reviseTypeInRo: number,
    versionInRo: number,
    preRoSpotId: number,
    hasInsertNextRo: number,

}

interface ReceivedPCResultRequired extends Omit<ReceivedPCResultJsonRequired, 'totSoRoSpotDetailList'> {
    totSoRoSpotDetailList: ReceivedPCPartsDetail[],
}

interface ReceivedPCResultOptional extends Omit<ReceivedPCResultJsonOptional, 'status'> {
    // orderType: OrderType,
    status: number,
}

export const receivedPCDetailResultDeserializer: Project<ReceivedPCDetailResultson, ReceivedPCDetailResult> = json => ({
    ...json,
    totSoRoSpotDetailList: json.totSoRoSpotDetailList.map(receivedPCPartsDetailDeserializer),
})

export const receivedPCDetailResultSerializer: Project<ReceivedPCDetailResult, ReceivedPCDetailResultson> = data => ({
    ...data,
    totSoRoSpotDetailList: data.totSoRoSpotDetailList.map(receivedPCPartsDetailSerializer),
})

// parts
export interface ReceivedPCPartsDetail extends Omit<ReceivedPCPartsDetailJson, 'oldReqInboundPlanList' | 'newReqInboundPlanList'> {
    oldReqInboundPlanList: OldInboundPalnDetail[],
    newReqInboundPlanList: NewInboundPalnDetail[],
}

interface ReceivedPCPartsDetailJson {
    soSpotDetailId: number,
    soSpotId: number,
    globalPartsId: number,
    partsNo: string,
    partsDescription: string,
    buyerPartsId: number,
    buyerPartsNo: string,
    soPartsId: number,
    soDetailOrigin: string,
    coDetailOrigin: string,
    unitPartsNo: string,
    // sellerUid: string,
    // sellerCode: string,
    // sellerPartsId: number,
    // sellerPartsNo: string,
    finalFlag: number,
    uomCode: string,
    orderLot: number,
    srbq: number,
    sspq: number,
    bspq: number,
    oldFirmQty: number,
    status: number,
    version: number,

    roSpotDetailIdInRo: number,
    roSpotIdInRo: number,
    coDetailIdInRo: number,
    orderQtyInRo: number,
    soDetailOriginInRo: string,
    coDetailOriginInRo: string,
    statusInRo: number,
    versionInRo: number,
    nextSellerUidInRo: string,
    nextSellerCodeInRo: string,
    sellingPrice: number,

    belowRoDetailStatus: number,

    oldReqInboundPlanList: OldInboundPalnDetailJson[] | null,
    newReqInboundPlanList: NewInboundPalnDetailJson[] | null,

}

export const receivedPCPartsDetailDeserializer: Project<ReceivedPCPartsDetailJson, ReceivedPCPartsDetail> = json => ({
    ...json,
    oldReqInboundPlanList: json.oldReqInboundPlanList ? json.oldReqInboundPlanList.map(oldInboundPalnDetailDeserializer) : [],
    newReqInboundPlanList: json.newReqInboundPlanList ? json.newReqInboundPlanList.map(newInboundPalnDetailDeserializer) : [],
})

export const receivedPCPartsDetailSerializer: Project<ReceivedPCPartsDetail, ReceivedPCPartsDetailJson> = data => ({
    ...data,
    oldReqInboundPlanList: data.oldReqInboundPlanList ? data.oldReqInboundPlanList.map(inboundPalnDetailSerializer) : [],
    newReqInboundPlanList: data.newReqInboundPlanList ? data.newReqInboundPlanList.map(buyerInboundPalnDetailSerializer) : [],
})

//old inbound plan
export interface OldInboundPalnDetail extends Omit<OldInboundPalnDetailJson, 'crdDate'> {
    crdDate: Date
}

interface OldInboundPalnDetailJson {
    coSpotReqId: number,
    coSpotDetailId: number,
    crdDate: string,
    crdQty: number,
    totalReceivedQty: number,
    delayReceivedQty: number,
}

const oldInboundPalnDetailDeserializer: Project<OldInboundPalnDetailJson, OldInboundPalnDetail> = json => ({
    ...json,
    crdDate: dateFromJson(json.crdDate)
})
const inboundPalnDetailSerializer: Project<OldInboundPalnDetail, OldInboundPalnDetailJson> = data => ({
    ...data,
    crdDate: dateToJson(data.crdDate)
})

//new inbound plan
export interface NewInboundPalnDetail extends Omit<NewInboundPalnDetailJson, 'crdDate'> {
    crdDate: Date
}

interface NewInboundPalnDetailJson {
    roSpotCrdId?: number,
    roSpotDetailId?: number,
    crdType?: number,
    crdDate: string,
    crdQty?: number | null,
    changeFlag?: number,
}

const newInboundPalnDetailDeserializer: Project<NewInboundPalnDetailJson, NewInboundPalnDetail> = json => ({
    ...json,
    crdDate: dateFromJson(json.crdDate)


})
const buyerInboundPalnDetailSerializer: Project<NewInboundPalnDetail, NewInboundPalnDetailJson> = data => ({
    ...data,
    crdDate: dateToJson(data.crdDate)

})
