import { Project } from "../../../utils/Project"
export type UserActiveListResult = Required & Partial<Optional>

export type UserActiveListResultJson = JsonRequired & Partial<JsonOptional>

export const userActiveFactorToJson: Project<UserActiveListResult, UserActiveListResultJson> = obj => ({
  ...obj,
  account: obj.account ? userAccountResultToJson(obj.account) : {},
  cbdss: obj.cbdss ? obj.cbdss.map(m => userCbdsResultToJson(m)) : [],
})
export const userActiveFactorFromJson: Project<UserActiveListResultJson, UserActiveListResult> = json => ({
  ...json,
  account: json.account ? userAccountResultFromJson(json.account) : {},
  cbdss: json.cbdss ? json.cbdss.map(m => userCbdsResultFromJson(m)) : [],
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
}

interface JsonRequired {
  id: string
  name: string
  email: string
  contactCode: string
  defaultCbds: string
  cbdsCode: string
  activateChat: string
  account: UserAccountJson
  cbdss: UserCbdsJson[]
}

interface JsonOptional {
}


// UserAccount
export type UserAccount = Partial<UserAccountOptional>
type UserAccountJson = Partial<UserAccountJsonOptional>
const userAccountResultFromJson: Project<UserAccountJson, UserAccount> = json => ({
    ...json,
})
const userAccountResultToJson: Project<UserAccount, UserAccountJson> = obj => ({
    ...obj,

})
interface UserAccountOptional extends UserAccountJsonOptional {
}
interface UserAccountJsonOptional {
    loginId: string,
    expireAt: number,
    active: boolean,
    locked: boolean,
}


// UserCbds
export type UserCbds = Partial<UserCbdsOptional>
type UserCbdsJson = Partial<UserCbdsJsonOptional>
const userCbdsResultFromJson: Project<UserCbdsJson, UserCbds> = json => ({
    ...json,
})
const userCbdsResultToJson: Project<UserCbds, UserCbdsJson> = obj => ({
    ...obj,
})
interface UserCbdsOptional extends UserCbdsJsonOptional {

}
interface UserCbdsJsonOptional {
    cbdsUid: string,
    defaultCbds: boolean,
    roleIds: string[],
    resourceIds: string[],
}

