import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { dateFromJson } from "../../utils/deserializer"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"

export type CustomsInvoiceView = Required & Partial<Optional>

type CustomsInvoiceViewJson = JsonRequired & Partial<JsonOptional>

export const customsInvoiceViewToJson: Project<CustomsInvoiceView, CustomsInvoiceViewJson> = obj => ({
    ...obj,
    issuedDateStart: dateToJson(obj.issuedDateStart),
    issuedDateEnd: dateToJson(obj.issuedDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page)
})

export const customsInvoiceViewFromJson: Project<CustomsInvoiceViewJson, CustomsInvoiceView> = json => ({
    ...json,
    issuedDateStart: dateFromJson(json.issuedDateStart),
    issuedDateEnd: dateFromJson(json.issuedDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page)
})


interface Required extends JsonRequired {

}

interface Optional extends Omit<JsonOptional, 'issuedDateStart' | 'issuedDateEnd'> {
    page: PageViewEntity,
    issuedDateStart: Date,
    issuedDateEnd: Date,

}

interface JsonRequired {
}

interface JsonOptional {
    invoiceNo: string,
    outboundNo: string,
    containerNo: string,
    issuedDateStart: string,
    issuedDateEnd: string,
    shippingModeList: number[],
    loadingPortList: string[],
    dischargePortList: string[],
    statusList: number[],
    invoiceNoList: string[],
    page: PageViewEntityJson,
}
