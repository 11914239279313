import { Arrays } from "@rithe/utils"
import { useCallback, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { useGetCoDetail } from "../../../services/order/apis/CustomerOrderApi"
import { CoStatus } from "../../../services/order/enums/CoStatus"
import { SimulatedType } from "../../../services/order/enums/SimulatedType"
import { EstimatedData, InboundPalnData, OrderDetailHead, OrderPartsData, OrderPartsDetail } from "../../../services/order/models/OrderDetailResult"
import { datetimeFromJson } from "../../../services/utils/deserializer"
import { OCS011PcUi } from "./OCS011PcUi"

export const OCS011 = () => {
    const intl = useIntl()
    const { orderId } = useParams() as any
    const [headerInfo, setHeaderInfo] = useState<OrderDetailHead>({} as OrderDetailHead)
    const [partsDetailList, setPartsDetailList] = useState<OrderPartsData[]>([])

    const creatInboundPlanList = useCallback((headerInfo: OrderDetailHead, partsDetailList: OrderPartsDetail[]) => {

        const allIPlanDateList: number[] = Arrays.distinct(partsDetailList.filter(f => f.inboundPlanList).flatMap(m => m.inboundPlanList).map(m2 => m2.crdDate.getTime())).sort()
        const allEstimatedDataList: string[] = Arrays.distinct(partsDetailList.filter(f => f.estimatedInboundList).flatMap(f => f.estimatedInboundList).map(m3 => [m3.estimatedCrdDate.getTime(), m3.fromCompany, SimulatedType.ON_STOCK !== m3.simulatedType ? m3.toCompany : ''].join(','))).sort()

        return partsDetailList.map((opd) => {
            const actualInboundQty: number = (opd?.inTransitQty ?? 0) + (opd?.currentInbQty ?? 0)
            const acutalOrderQty = opd.detailStatus === CoStatus.CANCELLED ? 0 : (opd.orderQty ?? 0) - (opd.forceCompletedQty ?? 0)

            // So Detail Page: Shortage Qty = Order Qty - Received Qty - Total Estimated qty(including Intransit Qty)
            // let shortageQty: number = opd?.orderQty | 0 - opd?.currentInbQty | 0 - opd?.inTransitQty | 0
            const newinboundPlanList = allIPlanDateList.map((crdDate) => {
                // sum plan qty
                const planQty = opd.inboundPlanList ? opd.inboundPlanList.filter(f => f.crdDate.getTime() <= crdDate).map(m => m.crdQty).reduce((a, b) => a + b, 0) : 0
                const actualPlanQty = (planQty > acutalOrderQty ? acutalOrderQty : planQty)
                const estOutboundQty = opd.estimatedInboundList ? opd.estimatedInboundList.filter(f => f.estimatedCrdDate.getTime() <= crdDate).map(m => m.qty).reduce((a, b) => a + b, actualInboundQty) : 0
                const planInfo = opd.inboundPlanList.find(o => o.crdDate.getTime() === crdDate)

                return {
                    crdDate: datetimeFromJson(crdDate),
                    status: (headerInfo.status !== null && headerInfo.status > CoStatus.SUBMITTED) ? (actualPlanQty > estOutboundQty ? 'NG-' + (intl.formatNumber((actualPlanQty - estOutboundQty), { maximumFractionDigits: 3, minimumFractionDigits: 0 })) : 'OK') : 'OK',
                    crdQty: planInfo?.crdQty ?? 0
                } as InboundPalnData
            })

            const newEstimatedInboundList = allEstimatedDataList.map((eData) => {
                const tempDataList: string[] = eData.split(',')
                const crdDate: number = Number(tempDataList[0]), companyTitle: string = '' === tempDataList[2] ? tempDataList[1] : tempDataList[1] + ' Ship To ' + tempDataList[2]
                const estimatedData = opd.estimatedInboundList.find(f => [f.estimatedCrdDate.getTime(), f.fromCompany, SimulatedType.ON_STOCK !== f.simulatedType ? f.toCompany : ''].join(',') === eData)

                return {
                    estimatedCrdDate: datetimeFromJson(crdDate),
                    companyTitle: companyTitle,
                    qty: estimatedData?.qty ?? 0
                } as EstimatedData
            })
            return { ...opd, inboundPlanList: newinboundPlanList, estimatedInboundList: newEstimatedInboundList }
        })
    }, [intl])

    const getCoDetail = useGetCoDetail()
    useEffect(() => {
        if (orderId) {
            getCoDetail({ orderId }, { silent: true, serialized: true }).then(result => {
                setHeaderInfo(result?.headerInfo || {} as OrderDetailHead)
                if (result?.partsDetailList && result.partsDetailList.length) {
                    setPartsDetailList(creatInboundPlanList(result?.headerInfo || {}, result.partsDetailList))
                }
            })
        } else {
            //TODO Show ERROR Message
            setHeaderInfo({} as OrderDetailHead)
            setPartsDetailList([])
        }
    }, [creatInboundPlanList, getCoDetail, orderId])

    return <OCS011PcUi headerInfo={headerInfo} partsDetailList={partsDetailList} />
}