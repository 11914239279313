import { datetimeFromJson } from "./deserializer"
import { datetimeToJson } from "./serializer"

export type TableEntity = Partial<Optional>

export type TableEntityJson = Partial<JsonOptional>

export function tableEntityToJson<T extends object>(obj: TableEntity & T): TableEntityJson & T {
    return {
        ...obj,
        createdDate: datetimeToJson(obj.createdDate),
        updatedDate: datetimeToJson(obj.updatedDate),
    }
}

export function tableEntityFromJson<T extends object>(json: TableEntityJson & T): TableEntity & T {
    return {
        ...json,
        createdDate: datetimeFromJson(json.createdDate),
        updatedDate: datetimeFromJson(json.updatedDate),
    }
}

interface Optional extends Omit<JsonOptional, 'createdDate' | 'updatedDate'> {
    createdDate: Date,
    updatedDate: Date,
}

interface JsonOptional {
    createdBy: string,
    createdDate: number,
    updatedBy: string,
    updatedDate: number,
    version: number,
}
