import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { dateFromJson } from "../../utils/deserializer"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"
import { TermsType } from "../enums/TermsType"

export type PaymentTermsDownloadFactor = Omit<PaymentTermsFactor, 'page'>
type PaymentTermsDownloadFactorJson = Omit<PaymentTermsFactorJson, 'page'>
export const paymentTermsDownloadFactorToJson: Project<PaymentTermsDownloadFactor, PaymentTermsDownloadFactorJson> = obj => ({
    ...obj,
    createdDateStart: dateToJson(obj.createdDateStart),
    createdDateEnd: dateToJson(obj.createdDateEnd),
    updatedDateStart: dateToJson(obj.updatedDateStart),
    updatedDateEnd: dateToJson(obj.updatedDateEnd),
})

export type PaymentTermsFactor = Required & Partial<Optional>
type PaymentTermsFactorJson = JsonRequired & Partial<JsonOptional>
export const paymentTermsFactorToJson: Project<PaymentTermsFactor, PaymentTermsFactorJson> = obj => ({
    ...obj,
    createdDateStart: dateToJson(obj.createdDateStart),
    createdDateEnd: dateToJson(obj.createdDateEnd),
    updatedDateStart: dateToJson(obj.updatedDateStart),
    updatedDateEnd: dateToJson(obj.updatedDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
})

export const paymentTermsFactorFromJson: Project<PaymentTermsFactorJson, PaymentTermsFactor> = json => ({
    ...json,
    createdDateStart: dateFromJson(json.createdDateStart),
    createdDateEnd: dateFromJson(json.createdDateEnd),
    updatedDateStart: dateFromJson(json.updatedDateStart),
    updatedDateEnd: dateFromJson(json.updatedDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page),
})


interface Required extends JsonRequired {

}

interface Optional extends Omit<JsonOptional, 'createdDateStart' | 'createdDateEnd' | 'updatedDateStart' | 'updatedDateEnd'> {
    termsTypes: TermsType[],
    createdDateStart: Date,
    createdDateEnd: Date,
    updatedDateStart: Date,
    updatedDateEnd: Date,
    page: PageViewEntity,
}

interface JsonRequired {

}

interface JsonOptional {
    buId: number,
    paymentTermsCode: string,
    description: string,
    termsTypes: number[],
    fromMonth: number,
    days: number,
    fromDay: number,
    createdBy: string,
    updatedBy: string,
    createdDateStart: string,
    createdDateEnd: string,
    updatedDateStart: string,
    updatedDateEnd: string,
    page: PageViewEntityJson
}
