import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { EntriesItem, StringItem } from "@rithe/form"
import { Objects, Records } from "@rithe/utils"
import React, { useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { UploadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/UploadGroupedToolbarAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { ActiveFlagTypeProvider } from "../../../components/DataGrid/typeProviders/ActiveFlagTypeProvider"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { SmtActiveFlagTypeProvider } from "../../../components/DataGrid/typeProviders/SmtActiveFlagTypeProvider"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { useDownloadOrderCalcGroupingByFilter, useDownloadOrderCalcGroupingByOrderGroupId } from "../../../services/smt/api/smtDownloadApi"
import { useUploadOrderCalcGrouping } from "../../../services/smt/api/smtUploadApi"
import { OrderCalculationTransfer } from "../../../services/smt/models/OrderCalculationTransfer"
import { OrderCalculationView } from "../../../services/smt/models/OrderCalculationView"
import { Pair } from "../../../services/utils/Pair"

interface MSOGS010CustPcUiProps {
    filters: OrderCalculationView,
    setFilters: React.Dispatch<React.SetStateAction<OrderCalculationView>>,
    data: OrderCalculationTransfer[],
    totalCount: number,
    search: (filters: OrderCalculationView) => void,
    sellerList: Pair[],
}

export const MSOGS010CustPcUi = (props: MSOGS010CustPcUiProps) => {
    const intl = useIntl()
    return <View flex>
        <SectionCard>
            <SectionCardHeader title={''} subtitle={intl.formatMessage({ id: 'calculationGroupingSub' })} />
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({ filters, data, setFilters, search, sellerList }: MSOGS010CustPcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])

    const columns = useMemo(() => [
        { field: 'orderGroupNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'orderGroupNo' }), width: 200 },
        { field: 'sellerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sellerCode' }), width: 200 },
        // { field: 'calendarCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.calendarCode' }), width: 200 },
        { field: 'bufferUsageType', dataTypeName: CodeCategory.BufferUsageType, title: intl.formatMessage({ id: 'bufferUsageType' }), width: 200 },
        { field: 'orderFrequency', dataTypeName: CodeCategory.OrderFrequency, title: intl.formatMessage({ id: 'field.orderFrequency' }), width: 200 },
        { field: 'targetLeadtime', dataTypeName: 'number', title: intl.formatMessage({ id: 'targetMonth' }), width: 200 },
        { field: 'forecastNum', dataTypeName: 'number', title: intl.formatMessage({ id: 'forecastNumber' }), width: 200 },
        { field: 'orderTiming', dataTypeName: 'string', title: intl.formatMessage({ id: 'orderTiming' }), width: 200 },
        { field: 'activeFlag', dataTypeName: CodeCategory.SmtActiveFlag, title: intl.formatMessage({ id: 'discontinueFlag' }), width: 200 },
        { field: 'remark', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.remark' }), width: 300 },
    ], [intl])
    const getRowId = useCallback((row: any) => row.orderGroupId, [])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search, sellerList }), [filters, setFilters, search, sellerList])
    const actionProps1 = useMemo(() => ({ search, filters }), [filters, search])
    const actionProps2 = useMemo(() => ({ selections, filters }), [filters, selections])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OrderFrequency} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.SmtActiveFlag} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.BufferUsageType} />
        <SmtActiveFlagTypeProvider />
        <ActiveFlagTypeProvider />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={UploadAction} actionProps={actionProps1} />
        <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps2} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['remark']}
            columnSettings={{
                orderGroupNo: { disableUserControl: true },
                buyerCode: { disableUserControl: true },
                calendarCode: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
    </DataGrid>
}

const FilterItem = (props: {
    filters: OrderCalculationView,
    setFilters: React.Dispatch<React.SetStateAction<OrderCalculationView>>,
    search: (filters: OrderCalculationView) => void,
    sellerList: Pair[],
}) => {
    const { filters, setFilters, search, sellerList } = props

    const sellersMap = useMemo(() => sellerList ? sellerList.map(m => [m.first, m.second] as [string, string]) : [], [sellerList])

    const clear = useCallback((filters: OrderCalculationView) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: OrderCalculationView) => {
        return [
            filters.orderGroupNo,
            filters.sellerUids,
            filters.orderFrequency,
            filters.orderTiming,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field='orderGroupNo' label={intl.formatMessage({ id: 'orderGroupNo' })} />
        <EntriesItem field='sellerUids' label={intl.formatMessage({ id: 'field.sellerCode' })} entries={sellersMap} />
        <CodesItem field='orderFrequency' label={intl.formatMessage({ id: 'field.orderFrequency' })} code={CodeCategory.OrderFrequency} />
        <StringItem field='orderTiming' label={intl.formatMessage({ id: 'orderTiming' })} />
    </FilterToolbarItem>
}


const UploadAction = ({ search, filters }: { search: (filters: OrderCalculationView) => void, filters: OrderCalculationView }) => {
    const uploadApi = useUploadOrderCalcGrouping()
    const upload = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            files && uploadApi({ file: files[0] }, { serialized: true }).then(() => search(filters))
        })
    }, [filters, search, uploadApi])
    return <UploadGroupedToolbarAction access="STCK.MSOGS010.DOWNLOAD">
        {(popupUpload, onClose) => <>
            <GroupedCallbackItem label={<FormattedMessage id="uploadOrderCalcGrouping" />} callback={() => upload(popupUpload)} onClose={onClose} />
        </>}
    </UploadGroupedToolbarAction>
}

const DownloadAction = ({ selections, filters }: { selections: number[], filters: OrderCalculationView }) => {
    const downloadBySelectionApi = useDownloadOrderCalcGroupingByOrderGroupId()
    const downloadByFilterApi = useDownloadOrderCalcGroupingByFilter()

    const download = useCallback(() => {
        selections.length <= 0 ? downloadByFilterApi(Objects.delete(filters, 'page')) : downloadBySelectionApi({ orderGroupIdList: selections })
    }, [downloadByFilterApi, downloadBySelectionApi, filters, selections])
    return <DownloadGroupedToolbarAction access="STCK.MSOGS010.DOWNLOAD">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="downloadOrderCalcGrouping" />} callback={download} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}