import { makeStyles, Tooltip } from '@material-ui/core'
import React from 'react'
import { useNavigate } from "react-router-dom"
import { Access } from '../../Access/Access'
import { NeumorphismButton } from '../../Button/NeumorphismButton'

interface RedirectToolbarActionProps {
    access?: string,
    power?: boolean,
    title: NonNullable<React.ReactNode>,
    icon: NonNullable<React.ReactNode>,
    path: string,
    state?: Record<string, unknown>,
}

export const RedirectToolbarAction = (props: RedirectToolbarActionProps) => {
    const { access, power, title, icon, path, state } = props
    const navigate = useNavigate()

    const styles = useStyles()
    const action = <Tooltip title={title}>
        <NeumorphismButton onClick={() => navigate(path, { state })} className={styles.root}>
            {icon}
        </NeumorphismButton>
    </Tooltip>
    return access ? <Access access={access} power={power}>{action}</Access> : action
}

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1),
        width: 36,
    },
}))