import { Project } from "@rithe/utils"
import { datetimeFromJson } from "../../utils/deserializer"

export interface OrderGroupVo {
    orderGroupId: string,
    customerId?: string,
    buyerBuId?: string,
    supplierId?: string,
    buId?: string,
    orderGroupNo: string,
    orderFrequency: number,
    targetLeadtime: number,
    orderTiming?: string,
    remark?: string,
    createdBy?: string,
    createdDate?: Date,
    forecastNum?: number,
    bufferUsageType?: number,
    updatedBy?: string,
    updatedDate?: Date,
    version?: number,
    activeFlag?: number,
}

export interface OrderGroupVoJson {
    orderGroupId: string,
    customerId?: string,
    buyerBuId?: string,
    orderGroupNo: string,
    orderFrequency: number,
    targetLeadtime: number,
    orderTiming?: string,
    remark?: string,
    createdBy?: string,
    createdDate?: number,
    forecastNum?: number,
    bufferUsageType?: number,
    updatedBy?: string,
    updatedDate?: number,
    version?: number,
    activeFlag?: number,
}

export const orderGroupVoFromJson: Project<OrderGroupVoJson, OrderGroupVo> = json => ({
    ...json,
    createdDate: datetimeFromJson(json.createdDate),
    updatedDate: datetimeFromJson(json.updatedDate),
})