import { Project } from "@rithe/utils"

export type GetAllContractVo = Required & Partial<Optional>
type GetAllContractVoJson = JsonRequired & Partial<JsonOptional>
export const GetAllContractVoToJson: Project<GetAllContractVo, GetAllContractVoJson> = obj => ({
    ...obj,
})

export const GetAllContractVoFromJson: Project<GetAllContractVoJson, GetAllContractVo> = json => ({
    ...json
})


interface Required extends JsonRequired {

}

interface Optional extends JsonOptional {
}

interface JsonRequired {

}

interface JsonOptional {
    customerIds: number[],
    buIds: number[],
    supplierIds: number[],
}