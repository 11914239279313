import { ArrayMultimaps } from "@rithe/utils"
import { useCallback, useMemo } from "react"
import { effectiveLanguage, Language } from "../configs/i18n/Language"
import { useApplicationSelector } from "../layouts/Application/applicationSlice"
import { CodeCategory } from "../services/master/enums/CodeCategory"

export const useGetCodeName = () => {
    const codeCategories = useApplicationSelector(state => state.cache.codeCategories ?? [])
    const locale = Language.EN_US
    const codeMap = useMemo(() => {
        return ArrayMultimaps.from(codeCategories.filter(cc =>
            cc.language && effectiveLanguage(cc.language) === locale
            && cc.codeValue !== undefined
            && cc.codeName !== undefined).map(cc => [cc.codeCategory!, cc]))
    }, [codeCategories, locale])

    const getCodeName = useCallback((codeCategory: CodeCategory, codeValue: number | undefined) => {
        if (!codeValue) {
            return ""
        } else {
            const categoryList = codeMap.get(codeCategory)
            if (categoryList) {
                const valueList = categoryList.filter(f => f.codeValue === codeValue)
                return valueList ? (valueList[0] ? valueList[0].codeName : "") : ""
            } else {
                return ""
            }
        }
    }, [codeMap])

    return { getCodeName }
}

export const useGetCodeList = () => {
    const codeCategories = useApplicationSelector(state => state.cache.codeCategories ?? [])
    const locale = Language.EN_US
    const codeMap = useMemo(() => {
        return ArrayMultimaps.from(codeCategories.filter(cc =>
            cc.language && effectiveLanguage(cc.language) === locale
            && cc.codeValue !== undefined
            && cc.codeName !== undefined).map(cc => [cc.codeCategory!, cc]))
    }, [codeCategories, locale])

    const getCodeList = useCallback((codeCategory: CodeCategory) => codeMap.get(codeCategory), [codeMap])

    return getCodeList
}