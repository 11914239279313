import { Project } from "@rithe/utils";
import { RolledPartsFlag } from "../../master/enums/RolledPartsFlag";

export interface InnerPartsResult {
    soNo: string,
    soDetailId: number,
    soDetailOrgin: string,
    contractRouteId: number,
    sellerCode: string,
    sellerPartsNo: string,
    sellerPartsName: string,
    sellerBackNo: string,
    colorCode: string,
    srbq: number,
    uomCode: string,
    uomDigits: number,
    partsNo: string,
    notYetDeliveryQty: number,
    buyers: string[],
    sellingPrice: number,
    invoiceNo: string,
    rolledPartsFlag: RolledPartsFlag
}

interface InnerPartsResultJson {
    soNo: string,
    soDetailId: number,
    soDetailOrgin: string,
    contractRouteId: number,
    sellerCode: string,
    sellerPartsNo: string,
    sellerPartsName: string,
    sellerBackNo: string,
    colorCode: string,
    srbq: number,
    uomCode: string,
    uomDigits: number,
    partsNo: string,
    notYetDeliveryQty: number,
    buyers: string[],
    sellingPrice: number,
    invoiceNo: string,
    rolledPartsFlag: number
}

export const innerPartsResultFromJson: Project<InnerPartsResultJson, InnerPartsResult> = json => ({
    ...json,
})