import { Project } from "../../../utils/Project"
import { CreditHoldFlag } from "../enums/CreditHoldFlag"

export type CreditSettingInfoResult = Required & Partial<Optional>
type CreditSettingInfoResultJson = JsonRequired & Partial<JsonOptional>
export const creditSettingInfoResultFromJson: Project<CreditSettingInfoResultJson, CreditSettingInfoResult> = json => ({
    ...json,
})

interface Required extends JsonRequired {

}

interface Optional extends JsonOptional {
    autoHoldOrder: CreditHoldFlag,
    autoHoldInvoice: CreditHoldFlag,
}

interface JsonRequired {

}

interface JsonOptional {
    settingId: number,
    buyerBuId: number,
    customerId: number,
    buyerCode: string,
    currency: string,
    creditRuleNo: string,
    limitAmount: number,
    autoHoldOrder: number,
    autoHoldInvoice: number,
    status: number,
    digits: number,
}