import { Project } from "../../../utils/Project"
import { OrderFrequency } from "../../order/enums/OrderFrequency"
import { dateFromJson } from "../../utils/deserializer"
import { dateToJson } from "../../utils/serializer"
import { TableEntity, tableEntityFromJson, TableEntityJson, tableEntityToJson } from "../../utils/TableEntity"
import { ActiveFlag } from "../enums/ActiveFlag"
import { AutoDiFlag } from "../enums/AutoDiFlag"
import { BusinessType } from "../enums/BusinessType"
import { ContractRouteStatus } from "../enums/ContractRouteStatus"
import { ContractType } from "../enums/ContractType"
import { CustomsFlag } from "../enums/CustomsFlag"
import { HSCodeOutputFlag } from "../enums/HSCodeOutPutFlag"
import { PriceBasis } from "../enums/PriceBasis"
import { WorkingStatus } from "../enums/WorkingStatus"
import { WorkingType } from "../enums/WorkingType"
import { WorkingPartsList, workingPartsListFromJson, WorkingPartsListJson, workingPartsListToJson } from "./WorkingPartsList"

export type WorkingFactor = Required & Partial<Optional> & TableEntity
type WorkingFactorJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const workingFactorFromJson: Project<WorkingFactorJson, WorkingFactor> = json => ({
    ...tableEntityFromJson(json),
    confirmedDate: dateFromJson(json.confirmedDate),
    today: dateFromJson(json.today),
    confirmedDateStart: dateFromJson(json.confirmedDateStart),
    confirmedDateEnd: dateFromJson(json.today),
    partsList: json.partsList ? json.partsList.map((m) => workingPartsListFromJson(m)) : [],
    nextPartsList: json.nextPartsList ? json.nextPartsList.map((m) => workingPartsListFromJson(m)) : [],
})

export const workingFactorToJson: Project<WorkingFactor, WorkingFactorJson> = obj => ({
    ...tableEntityToJson(obj),
    confirmedDate: dateToJson(obj.confirmedDate),
    today: dateToJson(obj.today),
    confirmedDateStart: dateToJson(obj.confirmedDateStart),
    confirmedDateEnd: dateToJson(obj.today),
    partsList: obj.partsList ? obj.partsList.map((m) => workingPartsListToJson(m)) : [],
    nextPartsList: obj.nextPartsList ? obj.nextPartsList.map((m) => workingPartsListToJson(m)) : [],
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'confirmedDate' | 'today' | 'confirmedDateStart' | 'confirmedDateEnd' | 'partsList' | 'nextPartsList'> {
    status: WorkingStatus,
    workingType: WorkingType,
    confirmedDate: Date,
    contractType: ContractType,
    businessType: BusinessType,
    printHSCodeFlag: HSCodeOutputFlag,
    orderFrequency: OrderFrequency,
    customsFlag: CustomsFlag,
    priceBasis: PriceBasis,
    autoDiFlag: AutoDiFlag,
    routeStatus: ContractRouteStatus,
    statusList: WorkingStatus[],
    workingTypeList: WorkingType[],
    partsList: WorkingPartsList[],
    nextPartsList: WorkingPartsList[],
    today: Date,
    activeFlag: ActiveFlag,
    confirmedDateStart: Date,
    confirmedDateEnd: Date,
    currencyCode: string,
}

interface JsonRequired {

}

interface JsonOptional {
    workingId: number,
    // Working
    workingNo: string,
    description: string,
    status: number,
    workingType: number,
    workingUid: string,
    confirmedBy: string,
    confirmedDate: string,

    // Contract
    basicId: number,
    contractId: number,
    contractNo: string,
    contractType: number,
    basicDescription: string,
    sellerUid: string,
    sellerCode: string,
    buyerUid: string,
    buyerCode: string,
    consigneeUid: string,
    consigneeCode: string,
    accounteeUid: string,
    accounteeCode: string,
    deliveryToUid: string,
    deliveryToCode: string,
    businessType: number,
    printHSCodeFlag: number,
    incotermsId: number,
    incotermsCode: string,
    incotermsPlace: string,
    paymentTermsId: number,
    paymentTermsCode: string,
    paymentTermsName: string,
    currencyCode: string,
    orderFrequency: number,
    customsFlag: number,
    forecastNumber: number,
    expAgencyUid: string,
    expAgencyCode: string,
    impAgencyUid: string,
    impAgencyCode: string,
    targetLeadtime: number,

    // Contract Route 
    contractRouteId: number,
    receiverUid: string,
    receiverId: number,
    receiverCode: string,
    shipperUid: string,
    shipperId: number,
    shipperCode: string,
    srId: number,
    shippingRoutCode: string,
    spotSrId: number,
    spotShippingRouteCode: string,
    routeDescription: string,
    priceBasis: number,
    autoDiFlag: number,
    decimalDigits: number,
    routeStatus: number,
    partsList: WorkingPartsListJson[],
    nextPartsList: WorkingPartsListJson[],
    requestDetailIds: number[],
    workingIds: number[],
    statusList: number[],
    workingTypeList: number[],
    today: string,
    activeFlag: number,
    confirmedDateStart: string,
    confirmedDateEnd: string,
    isDeleteContract: number,
    requestId: number
}