import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"



export type CostResult = Required & Partial<Optional>

type CostResultJson = JsonRequired & Partial<JsonOptional>

export const costResultFromJson: Project<CostResultJson, CostResult> = json => ({
    ...json,
    inboundDate: dateFromJson(json.inboundDate),
    purchaseDate: dateFromJson(json.purchaseDate),
})

interface Required extends JsonRequired { }

interface Optional extends Omit<JsonOptional, "inboundDate" | "purchaseDate"> {
    inboundDate: Date
    purchaseDate: Date,
}

interface JsonRequired {
}

interface JsonOptional {
    inboundDate: string,
    inboundNo: string,
    purchaseDate: string,
    purchaseNo: string,
    qty: number,
    coDetailOrigin: string,
    price: number,
    cost: number,
    currency: string,
    supplierCode: string,
}

export interface CostsOrderResult {
    orderId: number,
    purchaseNo: string,
    purchaseDate: Date,
}

interface CostsOrderResultJson {
    orderId: number,
    purchaseNo: string,
    purchaseDate: string,
}

export const costsOrderResultFromJson: Project<CostsOrderResultJson, CostsOrderResult> = json => ({
    ...json,
    purchaseDate: dateFromJson(json.purchaseDate),
})