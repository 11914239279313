import { Project } from "../../../utils/Project"
import { dateToJson } from "../../utils/serializer"
import { OrderFrequency } from "../enums/OrderFrequency"

export interface PlaceRegularOrderFactor {
    orderFrequency: OrderFrequency | null,
    orderStartDate: Date | null,
    orderEndDate: Date | null,
}

export interface PlaceRegularOrderFactorJson {
    orderFrequency: number | null,
    orderStartDate: string | null,
    orderEndDate: string | null,
}

export const PlaceRegularOrderFactorSerializer: Project<PlaceRegularOrderFactor, PlaceRegularOrderFactorJson> = factor => ({
    orderFrequency: factor.orderFrequency,
    orderStartDate: factor.orderStartDate ? dateToJson(factor.orderStartDate) : null,
    orderEndDate: factor.orderEndDate ? dateToJson(factor.orderEndDate) : null,
})

