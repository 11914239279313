import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarLayout } from "@rithe/data-grid"
import { Records } from "@rithe/utils"
import React, { useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { View } from "../../../components/View/View"
import { useDownloadPokayokeResults } from "../../../services/smt/api/smtDownloadApi"
import { VisualizationReportView } from "../../../services/smt/models/VisualizationReportView"
import { applicationActions } from "../../Application/applicationSlice"

interface VSRPS010PcUiProps {
    data: VisualizationReportView[],
}

export const VSRPS010PcUi = (props: VSRPS010PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({ data }: VSRPS010PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])

    const columns = useMemo(() => [
        { field: 'stockDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'stockDate' }), width: 200 },
        { field: 'totalAlerts', dataTypeName: 'number', title: intl.formatMessage({ id: 'totalAlerts' }), width: 400 },
        { field: 'newAlerts', dataTypeName: 'number', title: intl.formatMessage({ id: 'newAlerts' }), width: 300 },
    ], [intl])

    const actionProps2 = useMemo(() => ({ selections, data }), [data, selections])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <Data rows={data} columns={columns} />
        <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps2} />
        <ColumnFreeze />
        <ColumnVisibility ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
    </DataGrid>
}

const DownloadAction = ({ selections, data }: { selections: number[], data: VisualizationReportView[] }) => {
    const dispatch = useDispatch()
    const downloadPokayokeResults = useDownloadPokayokeResults()
    const download = useCallback(() => {
        if (!selections || selections.length <= 0) {
            dispatch(applicationActions.pushError({ title: { code: 'download' }, messages: { code: 'w0002' } }))
            return
        }
        downloadPokayokeResults({ stockDates: data.filter((_, index) => selections.includes(index)).map(m => m.stockDate) })
    }, [data, dispatch, downloadPokayokeResults, selections])
    return <DownloadGroupedToolbarAction >
        {onClose => <GroupedCallbackItem label={<FormattedMessage id="download" />} onClose={onClose} callback={download} />}
    </DownloadGroupedToolbarAction>
}