import { useCallback, useMemo, useState } from "react"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { StockAdjustmentList } from "../../../services/smt/models/StockAdjustmentList"
import { StockAdjustmentListFactor, stockAdjustmentListFactorFromJson, stockAdjustmentListFactorToJson } from "../../../services/smt/models/StockAdjustmentListFactor"
import { StockAdjustmentListForCust } from "../../../services/smt/models/StockAdjustmentListForCust"
import { useGetStockAdjustmentList, useGetStockAdjustmentListForCustomer } from "../../../services/smt/stockManagement/api/weeklyRundownApi"
import { useGetCompanyType } from "../../../utils/ApplicationUtils"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { SMGTS410ForCustPcUi } from "./SMGTS410ForCustPcUi"
import { SMGTS410PcUi } from "./SMGTS410PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }
const searchCachekey = sessionKeys.Filters_SMGTS410

export const SMGTS410 = () => {
    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? stockAdjustmentListFactorFromJson(JSON.parse(filters)) : null
    }, [])

    const [filters, setFilters] = useState<StockAdjustmentListFactor>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<StockAdjustmentList[]>([])
    const [dataForCust, setDataForCust] = useState<StockAdjustmentListForCust[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const getStockAdjustmentList = useGetStockAdjustmentList()
    const getStockAdjustmentListForCust = useGetStockAdjustmentListForCustomer()
    const cbdsType = useGetCompanyType()


    const search = useCallback((filters: StockAdjustmentListFactor) => {
        if (cbdsType === CbdsType.BU) {
            getStockAdjustmentList(filters, { silent: true, serialized: true }).then(result => {
                setTotalCount(result?.page?.totalCount || 0)
                setData(result?.data || [])
                sessionStorage.setItem(searchCachekey, JSON.stringify(stockAdjustmentListFactorToJson(filters)))
            })
        } else {
            getStockAdjustmentListForCust(filters, { silent: true, serialized: true }).then(result => {
                setTotalCount(result?.page?.totalCount || 0)
                setDataForCust(result?.data || [])
                sessionStorage.setItem(searchCachekey, JSON.stringify(stockAdjustmentListFactorToJson(filters)))
            })
        }

    }, [cbdsType, getStockAdjustmentList, getStockAdjustmentListForCust])


    useSearchOnLoad(search, filters)

    if (CbdsType.CUST === cbdsType) {
        return <SMGTS410ForCustPcUi
            filters={filters}
            search={search}
            data={dataForCust}
            totalCount={totalCount}
            setFilters={setFilters}
        />
    } else {
        return <SMGTS410PcUi
            filters={filters}
            search={search}
            data={data}
            totalCount={totalCount}
            setFilters={setFilters}
        />
    }
}