import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { dateFromJson } from "../../utils/deserializer"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"
import { ActiveFlag } from "../enums/ActiveFlag"
import { CbdsType } from "../enums/CbdsType"
import { PairedFlag } from "../enums/PairedFlag"
import { PairedOrderFlag } from "../enums/PairedOrderFlag"

export type UnitPartsDownloadFactor = Omit<UnitPartsFactor, 'page'>
type UnitPartsDownloadFactorJson = Omit<UnitPartsFactorJson, 'page'>
export const unitPartsDownloadFactorToJson: Project<UnitPartsDownloadFactor, UnitPartsDownloadFactorJson> = obj => ({
    ...obj,
    currentDate: dateToJson(obj.currentDate),
    effectiveFromStart: dateToJson(obj.effectiveFromStart),
    effectiveFromEnd: dateToJson(obj.effectiveFromEnd),
    effectiveFromEndForQuery: dateToJson(obj.effectiveFromEndForQuery),
})

export type UnitPartsFactor = Partial<Optional>
type UnitPartsFactorJson = Partial<JsonOptional>
export const unitPartsFactorToJson: Project<UnitPartsFactor, UnitPartsFactorJson> = obj => ({
    ...obj,
    currentDate: dateToJson(obj.currentDate),
    effectiveFromStart: dateToJson(obj.effectiveFromStart),
    effectiveFromEnd: dateToJson(obj.effectiveFromEnd),
    effectiveFromEndForQuery: dateToJson(obj.effectiveFromEndForQuery),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
})

export const UnitPartsFactorFromJson: Project<UnitPartsFactorJson, UnitPartsFactor> = json => ({
    ...json,
    currentDate: dateFromJson(json.currentDate),
    effectiveFromStart: dateFromJson(json.effectiveFromStart),
    effectiveFromEnd: dateFromJson(json.effectiveFromEnd),
    effectiveFromEndForQuery: dateFromJson(json.effectiveFromEndForQuery),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page)
})

interface Optional extends Omit<JsonOptional, 'currentDate' | 'effectiveFromStart' | 'effectiveFromEnd' | 'effectiveFromEndForQuery' | 'page'> {
    cbdsType: CbdsType,
    currentDate: Date,
    pairedFlagList: PairedFlag[],
    pairedOrderFlagList: PairedOrderFlag[],
    effectiveFromStart: Date,
    effectiveFromEnd: Date,
    effectiveFromEndForQuery: Date,
    activeFlagList: ActiveFlag[],
    page: PageViewEntity,
}


interface JsonOptional {
    cbdsType: number,
    customerId: number,
    buId: number,
    supplierId: number,
    unitPartsNo: string,
    partsNo: string,
    unitPartsName: string,
    partsRefNo: string,
    backNo: string,
    hscode: string,
    pairedPartsNo: string,
    currentDate: string,
    pairedFlagList: number[],
    pairedOrderFlagList: number[],
    effectiveFromStart: string,
    effectiveFromEnd: string,
    effectiveFromEndForQuery: string,
    activeFlagList: number[],
    page: PageViewEntityJson,
}
