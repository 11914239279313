import { useFieldComponent } from "@rithe/form"
import { GridContainer, GridItem } from "@rithe/ui"
import { Arrays } from "@rithe/utils"
import moment from "moment"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { today, useGetCompanyType } from "../../../utils/ApplicationUtils"
import { occls013Actions, useOCCLS013Selector } from "./OCCLS013Slice"

export function BasicForm() {
    const companyType = useGetCompanyType()

    return <GridContainer
        component="form"
        columnWidth={Arrays.repeat('1fr', 2)}
        rowHeight="max-content"
        columnGap={24}
        rowGap={8}
        style={{ minWidth: 560, maxWidth: 1920 }}
    >
        <GridItem style={{ overflow: 'hidden' }}>
            <OrderCalcNoInput />
        </GridItem>
        <GridItem style={{ overflow: 'hidden' }}>
            <OrderGroupingCodeInput />
        </GridItem>
        <GridItem style={{ overflow: 'hidden' }}>
            <OrderTimeInput />
        </GridItem>
        {CbdsType.BU === companyType && <GridItem style={{ overflow: 'hidden' }}>
            <CustomerCodeInput />
        </GridItem>}
        {CbdsType.CUST === companyType && <GridItem style={{ overflow: 'hidden' }}>
            <SellerCodeInput />
        </GridItem>}
        <GridItem style={{ overflow: 'hidden' }}>
            <CutoffDateInput />
        </GridItem>
        <GridItem style={{ overflow: 'hidden' }}>
            <CustUsageDateInput />
        </GridItem>
    </GridContainer>
}

function OrderCalcNoInput() {
    const value = useOCCLS013Selector(state => state.orderCalc?.orderCalcNo || '')
    const { Control, Label, Input, InputWrapper } = useFieldComponent('StringInput', {})
    return <Control
        inputWrapper={<InputWrapper readonly />}
        label={<Label readonly><FormattedMessage id="field.orderCalcNo" /></Label>}
        labelDisplay="block"
        input={<Input
            field="orderCalcNo"
            value={value}
            setValue={() => { }}
            readonly
        />}
    />
}

function OrderGroupingCodeInput() {
    const value = useOCCLS013Selector(state => state.orderGroup?.orderGroupNo || '')
    const { Control, Label, Input, InputWrapper } = useFieldComponent('StringInput', {})
    return <Control
        inputWrapper={<InputWrapper readonly />}
        label={<Label readonly><FormattedMessage id="orderGroupingCode" /></Label>}
        labelDisplay="block"
        input={<Input
            field="orderGroupingCode"
            value={value}
            setValue={() => { }}
            readonly
        />}
    />
}

function OrderTimeInput() {
    const value = useOCCLS013Selector(state => state.orderTime || null)
    const { Control, Label, Input, InputWrapper } = useFieldComponent('YearMonthInput', {})
    return <Control
        inputWrapper={<InputWrapper readonly />}
        label={<Label readonly><FormattedMessage id="field.orderMonth" /></Label>}
        labelDisplay="block"
        input={<Input
            field="orderTime"
            value={value}
            setValue={() => { }}
            readonly
        />}
    />
}

function CustomerCodeInput() {
    const value = useOCCLS013Selector(state => state.buyerOptions[state.orderGroup?.customerId + '-1' as any])
    const { Control, Label, Input, InputWrapper } = useFieldComponent('StringInput', {})
    return <Control
        inputWrapper={<InputWrapper readonly />}
        label={<Label readonly><FormattedMessage id="field.customerCode" /></Label>}
        labelDisplay="block"
        input={<Input
            field="customerCode"
            value={value}
            setValue={() => { }}
            readonly
        />}
    />
}

function SellerCodeInput() {
    const value = useOCCLS013Selector(state => state.sellerOptions[(state.orderGroup?.supplierId === null || state.orderGroup?.supplierId === undefined) ? state.orderGroup?.buId + '-4' : state.orderGroup?.supplierId + '-3'])
    const { Control, Label, Input, InputWrapper } = useFieldComponent('StringInput', {})
    return <Control
        inputWrapper={<InputWrapper readonly />}
        label={<Label readonly><FormattedMessage id="field.sellerCode" /></Label>}
        labelDisplay="block"
        input={<Input
            field="sellerCode"
            value={value}
            setValue={() => { }}
            readonly
        />}
    />
}

function CutoffDateInput() {
    const messages = useOCCLS013Selector(state => state.formMessages)
    const dispatch = useDispatch()
    const intl = useIntl()
    const value = useOCCLS013Selector(state => state.cutoffDate || null)
    const { Control, Label, Helper, Input, InputWrapper } = useFieldComponent('DateInput', {})
    return <Control
        inputWrapper={<InputWrapper error={messages.length > 0} />}
        label={<Label required><FormattedMessage id="field.cutoffDate" /></Label>}
        labelDisplay="block"
        helper={<Helper messages={messages} />}
        helperDisplay="tooltip"
        input={<Input
            field="cutoffDate"
            value={value}
            setValue={value => {
                dispatch(occls013Actions.setCutoffDate(value || undefined))
                if (!value) {
                    dispatch(occls013Actions.setFormMessages([{
                        field: 'cutoffDate',
                        level: 'error',
                        value: intl.formatMessage({id:"w0001"}).replace("<field>{0}</field>", intl.formatMessage({id:"field.cutoffDate"}))
                    }]))
                } else {
                    messages && messages.length> 0 && dispatch(occls013Actions.setFormMessages([]))
                }
            }}
            maxDate={moment(today()).format('YYYY-MM-DD')}
        />}
    />
}

function CustUsageDateInput() {
    const dispatch = useDispatch()
    const value = useOCCLS013Selector(state => state.custUsageDate || null)
    const { Control, Label, Input, InputWrapper } = useFieldComponent('DateInput', {})
    return <Control
        inputWrapper={<InputWrapper />}
        label={<Label><FormattedMessage id="custUsageDate" /></Label>}
        labelDisplay="block"
        input={<Input
            field="custUsageDate"
            value={value}
            setValue={value => {
                dispatch(occls013Actions.setCustUsageDate(value || undefined))
            }}
        />}
    />
}