import { makeStyles, Paper, Typography } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { FormHelperProps } from "@rithe/form";
import clsx from 'clsx';
import React from "react";

export function Helper(props: FormHelperProps) {
    const { messages } = props

    const styles = useStyles()
    if (!messages) {
        return null
    } else {
        return <Paper className={styles.container}>
            {messages.map(({ level, value }, index) => <Typography
                key={index}
                variant="body2"
                display="block"
                className={clsx(
                    styles.root,
                    level === 'info' && styles.info,
                    level === 'success' && styles.success,
                    level === 'warning' && styles.warning,
                    level === 'error' && styles.error,
                )}>
                {value}
            </Typography>)}
        </Paper>
    }
}

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: grey[300],
        boxShadow: theme.shadows[3],
        padding: theme.spacing(1),
        marginTop: theme.spacing(2),
    },
    root: {
        zoom: 0.8,
        marginRight: theme.spacing(1),
    },
    info: {
        color: theme.palette.info.main,
    },
    success: {
        color: theme.palette.success.main,
    },
    warning: {
        color: theme.palette.warning.main,
    },
    error: {
        color: theme.palette.error.main,
    },
}))