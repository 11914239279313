import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { receivedPlaceOrderFromJson, receivedPlaceOrderToJson, ReceivedPlaceOrderView } from "../models/ReceivedPlaceOrderView"
import { PartsDetail, partsDetailDeserializer, partsDetailSerializer, ReSpotOrderResult, reSpotOrderResultDeserializer, reSpotOrderResultSerializer } from "../models/ReSpotOrderDetailResult"

export const useGetDataByPage = () => {
    const url = '/lcbm-spot-order-api/order/co/api/listTotSoSpot'
    const payloadProject = useCallback(receivedPlaceOrderToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(receivedPlaceOrderFromJson), [])
    return usePost<ReceivedPlaceOrderView, PageResultEntity<ReceivedPlaceOrderView>>(url, payloadProject, resultProject)
}


export const useGetSpotOderDetail = () => {
    const url = '/lcbm-spot-order-api/order/co/api/detailTotSoSpotEx'
    const payloadProject = useCallback((obj: { soSpotId: string }) => obj, [])
    const resultProject = useCallback(reSpotOrderResultDeserializer, [])
    return usePost<{ soSpotId: string }, ReSpotOrderResult>(url, payloadProject, resultProject)
}

export const useSaveReSoSpotOrder = () => {
    const url = '/lcbm-spot-order-api/order/co/api/saveHandleOcs023'
    const payloadProject = useCallback(reSpotOrderResultSerializer, [])
    const resultProject = useCallback(reSpotOrderResultDeserializer, [])
    return usePost<ReSpotOrderResult, ReSpotOrderResult>(url, payloadProject, resultProject)
}
export const useConfirmAndSwitch = () => {
    const url = '/lcbm-spot-order-api/order/co/api/confirmAndSwitch'
    const payloadProject = useCallback(reSpotOrderResultSerializer, [])
    const resultProject = useCallback(reSpotOrderResultDeserializer, [])
    return usePost<ReSpotOrderResult, ReSpotOrderResult>(url, payloadProject, resultProject)
}
export const useConfirmAndSetFinal = () => {
    const url = '/lcbm-spot-order-api/order/co/api/confirmAndSetFinal'
    const payloadProject = useCallback(reSpotOrderResultSerializer, [])
    const resultProject = useCallback(reSpotOrderResultDeserializer, [])
    return usePost<ReSpotOrderResult, ReSpotOrderResult>(url, payloadProject, resultProject)
}

export const useConfirmParts = () => {
    const url = '/lcbm-spot-order-api/order/co/api/confirmEstimatedInboundPlan'
    const payloadProject = useCallback(partsDetailSerializer, [])
    const resultProject = useCallback(partsDetailDeserializer, [])
    return usePost<PartsDetail, PartsDetail>(url, payloadProject, resultProject)
}

export const useRejectParts = () => {
    const url = '/lcbm-spot-order-api/order/co/api/rejectParts'
    const payloadProject = useCallback(partsDetailSerializer, [])
    const resultProject = useCallback(partsDetailDeserializer, [])
    return usePost<PartsDetail, PartsDetail>(url, payloadProject, resultProject)
}
