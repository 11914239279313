import { Project } from "../../../utils/Project"

export type OrderGroupTransfer = Required & Partial<Optional>

type OrderGroupTransferJson = JsonRequired & Partial<JsonOptional>

export const OrderGroupTransferFromJson: Project<OrderGroupTransferJson, OrderGroupTransfer> = json => ({
    ...json,
    orderGroupId: json.orderGroupId
})

interface Required extends JsonRequired { }

interface Optional extends JsonOptional {

}

interface JsonRequired {
    orderGroupId: number,
    orderGroupNo: string,
}

interface JsonOptional {
    customerId: number,
    orderFrequency: number,
    remark: string,
    createdBy: string,
    createdDate: number,
    updatedBy: string,
    updatedDate: number,
    version: number,
    targetLeadtime: number,
    orderTiming: string,
    calendarId: number,
    activeFlag: number,
}