import { Project } from "../../../utils/Project"
import { dateToJson } from "../../utils/serializer"



export interface VerdorProfileFactor {
    regions?: string[],
    suppliers?: number[],
    startMonth?: Date,
    endMonth?: Date

}

export interface VerdorProfileFactorJson {
    regions?: string[],
    suppliers?: number[],
    startMonth?: String,
    endMonth?: String
}

export const verdorProfileFactorToJson: Project<VerdorProfileFactor, VerdorProfileFactorJson> = obj => ({
    ...obj,
    startMonth: dateToJson(obj.startMonth),
    endMonth: dateToJson(obj.endMonth),
})
