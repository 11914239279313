import { Print } from "@material-ui/icons"
import React, { ReactNode } from "react"
import { FormattedMessage } from "react-intl"
import { GroupedToolbarAction } from "./GroupedToolbarAction"

interface PrintGroupedToolbarActionProps {
    access?: string,
    power?: boolean,
    title?: NonNullable<React.ReactNode>,
    icon?: NonNullable<React.ReactNode>,
    children?: (onClose: () => void) => ReactNode | ReactNode[],
}

export const PrintGroupedToolbarAction = (props: PrintGroupedToolbarActionProps) => {
    const {
        access,
        power,
        title = <FormattedMessage id="print" />,
        icon = <Print />,
        children,
    } = props
    return <GroupedToolbarAction access={access} power={power} title={title} icon={icon}>
        {children}
    </GroupedToolbarAction>
}