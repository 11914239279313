import { Project } from "../../../utils/Project"
import { ActiveFlag } from "../enums/ActiveFlag"
import { BusinessType } from "../enums/BusinessType"
import { ContractType } from "../enums/ContractType"
import { CustomsFlag } from "../enums/CustomsFlag"
import { ContractPartsResult, contractPartsResultFromJson, ContractPartsResultJson, contractPartsResultToJson } from "./ContractPartsResult"

export type ContractHeaderResult = Required & Partial<Optional>
type ContractHeaderResultJson = JsonRequired & Partial<JsonOptional>
export const contractHeaderResultToJson: Project<ContractHeaderResult, ContractHeaderResultJson> = obj => ({
    ...obj,
    partsList: obj.partsList ? obj.partsList.map((m) => contractPartsResultToJson(m)) : []
})
export const contractHeaderResultFromJson: Project<ContractHeaderResultJson, ContractHeaderResult> = json => ({
    ...json,
    partsList: json.partsList ? json.partsList.map(m => contractPartsResultFromJson(m)) : []
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'partsList'> {
    contractType: ContractType,
    businessType: BusinessType,
    customsFlag: CustomsFlag,
    activeFlag: ActiveFlag,
    partsList: ContractPartsResult[],
}

interface JsonRequired {
    contractId: number,
}

interface JsonOptional {
    contractNo: string,
    contractShortCode: string,
    contractType: number,
    description: string,
    sellerCode: string,
    buyerCode: string,
    businessType: number,
    orderFrequency: number,
    currency: string,
    forecastNum: number,
    targetLeadtime: number,
    customsFlag: number,
    printHscodeFlag: number,
    activeFlag: number,
    incotermsPlace: string,
    consignee: string,
    accountee: string,
    deliveryTo: string,
    exportCCAgency: string,
    importCCAgency: string,
    paymentTermsId: number,
    paymentTerms: string,
    incotermsId: number,
    incoterms: string,
    digits: number,
    sellerFlag: boolean,
    confirmOrderLeadtime: number,
    leadtime: number,
    deliveryPlanStartDate: string,
    deliveryPlanEndDate: string,
    deliveryTypeFlag: number,

    routeDetailId: number,
    contrRouteId: number,
    levelSeq: number,
    priceBasis: number,
    receiveDcId: number,
    shipperDcId: number,
    autoDiFlag: number,
    srId: number,
    spotSrId: number,
    createdBy: string,
    createdDate: string,
    updatedBy: string,
    updatedDate: string,
    version: number,
    partsList: ContractPartsResultJson[],

    creditRuleId: number,
    creditLimitAmount: number,
    creditAutoHoldOrder: number,
    creditAutoShipmentOrInvoice: number,
}