import { Button, Checkbox, Divider, Fade, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Menu, MenuItem, Tooltip, useTheme } from "@material-ui/core"
import { FileCopyOutlined, Print } from "@material-ui/icons"
import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Row, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { DateRangeItem, EntriesItem, StringItem } from "@rithe/form"
import { Records } from "@rithe/utils"
import React, { SetStateAction, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { NeumorphismButton } from "../../../components/Button/NeumorphismButton"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { ViewRedirectRowAction } from "../../../components/DataGrid/rowActions/ViewRedirectRowAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { BuyerInvoiceTransfer } from "../../../services/accounting/models/BuyerInvoiceTransfer"
import { BuyerInvoiceView } from "../../../services/accounting/models/BuyerInvoiceView"
import { useDownloadInvoicePDF } from "../../../services/delivery/apis/deliveryOutboundApi"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { TnmPort } from "../../../services/master/models/TnmPort"
import { useGetCodeName } from "../../../utils/CodeCategoryUtil"
import { applicationActions } from "../../Application/applicationSlice"

interface AIS010PcUiProps {
    filters: BuyerInvoiceView,
    setFilters: React.Dispatch<SetStateAction<BuyerInvoiceView>>,
    search: (filter: BuyerInvoiceView) => void,
    data: BuyerInvoiceTransfer[],
    totalCount: number,
    portList: TnmPort[],
}

export const AIS010PcUi = (props: AIS010PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({
    filters,
    setFilters,
    search,
    data,
    portList,
}: AIS010PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<string[]>([])
    const { getCodeName } = useGetCodeName()
    const getShippingMode = useCallback((row: Row) => {
        return row.shippingMode ? getCodeName(CodeCategory.ShippingMode, row.shippingMode) : (row.shippingModes && row.shippingModes.map((m: any) => getCodeName(CodeCategory.ShippingMode, m)).join(','))
    }, [getCodeName])

    const columns = useMemo(() => [
        { field: 'invoiceNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.invoiceNo' }), width: 200 },
        { field: 'externalInvoiceNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.externalInvoiceNo' }), width: 200 },
        { field: 'inboundNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.inboundNo' }), width: 250 },
        { field: 'contractNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contractNo' }), width: 250 },
        { field: 'shippingMode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.shippingMode' }), width: 180, getCellValue: getShippingMode },
        { field: 'loadingPort', dataTypeName: 'string', title: intl.formatMessage({ id: 'loadingPort' }), width: 180 },
        { field: 'dischargePort', dataTypeName: 'string', title: intl.formatMessage({ id: 'dischargePort' }), width: 180 },
        { field: 'issuedDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'issuedDate' }), width: 150 },
        { field: 'releasedBy', dataTypeName: 'string', title: intl.formatMessage({ id: 'releasedBy' }), width: 180 },
        { field: 'releasedDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'releasedDate' }), width: 180 },
        { field: 'status', dataTypeName: CodeCategory.InvoiceStatus, title: intl.formatMessage({ id: 'field.status' }), width: 150 },
    ], [getShippingMode, intl])
    const getRowId = useCallback((row: any) => row.invoiceNo, [])

    const itemProps1 = useMemo(() => ({ filters, setFilters, search, portList }), [filters, setFilters, search, portList])
    const actionProps1 = useMemo(() => ({ selections }), [selections])
    const actionProps2 = useMemo(() => ({ data }), [data])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.ShippingMode} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.InvoiceStatus} />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={PrintAction} actionProps={actionProps1} />
        <RowActionProvider name="view" Action={ViewRowAction} actionProps={actionProps2} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['externalInvoiceNo', 'loadingPort', 'dischargePort', 'issuedDate', 'approvedBy', 'approvedDate']}
            columnSettings={{
                invoiceNo: { disableUserControl: true }
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemProps1} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={124} />
    </DataGrid>
}

const FilterItem = (props: {
    filters: BuyerInvoiceView,
    search: (filter: BuyerInvoiceView) => void,
    portList: TnmPort[],
    setFilters: React.Dispatch<SetStateAction<BuyerInvoiceView>>,
}) => {
    const { filters, setFilters, portList, search } = props
    const portEntries: [number, string][] = useMemo(() => portList.map(m => [m.portId, m.portCode]), [portList])


    const createdDateGetValue = useCallback((filters: BuyerInvoiceView) => {
        return [filters.issuedDateStart ?? null, filters.issuedDateEnd ?? null]
    }, [])

    const createdDateMapValue = useCallback((filters: BuyerInvoiceView, value: any) => {
        return { ...filters ?? {}, issuedDateStart: value[0], issuedDateEnd: value[1] }
    }, [])

    const clear = useCallback((filters: BuyerInvoiceView) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: BuyerInvoiceView) => {
        return [
            filters.invoiceNo,
            filters.externalInvoiceNo,
            filters.inboundNo,
            filters.contractNo,
            filters.shippingModeList,
            filters.dischargePortList,
            filters.loadingPortList,
            filters.approvedBy,
            filters.statusList,
            filters.issuedDateStart || filters.issuedDateEnd,
            filters.approvedDateStart || filters.approvedDateEnd,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="invoiceNo" label={intl.formatMessage({ id: 'field.invoiceNo' })} />
        <StringItem field="externalInvoiceNo" label={intl.formatMessage({ id: 'field.externalInvoiceNo' })} />
        <StringItem field="inboundNo" label={intl.formatMessage({ id: 'field.inboundNo' })} />
        <StringItem field="contractNo" label={intl.formatMessage({ id: 'field.contractNo' })} />
        <CodesItem field="shippingModeList" label={intl.formatMessage({ id: 'field.shippingMode' })} code={CodeCategory.ShippingMode} />
        <EntriesItem field="dischargePortList" label={intl.formatMessage({ id: 'dischargePort' })} entries={portEntries} />
        <EntriesItem field="loadingPortList" label={intl.formatMessage({ id: 'loadingPort' })} entries={portEntries} />
        {/* <StringItem field="approvedBy" label={intl.formatMessage({ id: 'field.approvedBy' })} /> */}
        {/* <CodesItem field="statusList" label={intl.formatMessage({ id: 'field.status' })} code={CodeCategory.InvoiceStatus} /> */}
        <DateRangeItem field="issuedDate" label={intl.formatMessage({ id: 'issuedDate' })} getValue={createdDateGetValue} mapValue={createdDateMapValue} />
        {/* <DateRangeItem field="approvedDate" label={intl.formatMessage({ id: 'field.approvedDate' })} getValue={updatedDateGetValue} mapValue={updatedDateMapValue} /> */}
    </FilterToolbarItem>

}

const PrintAction = ({ selections }: { selections: string[] }) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const theme = useTheme()
    const [open, setOpen] = useState<boolean>(false)
    const [checked, setChecked] = useState<number[]>([])
    const handleClose = () => { setOpen(false) }
    const handleCheck = (value: number) => () => {
        const currentIndex = checked.indexOf(value)
        const newChecked = [...checked]
        if (currentIndex === -1) {
            newChecked.push(value)
        } else {
            newChecked.splice(currentIndex, 1)
        }

        setChecked(newChecked)
    }

    const typeList = useMemo(() => [
        { typeName: intl.formatMessage({ id: 'printInvoice' }), typeCode: 1 },
        { typeName: intl.formatMessage({ id: 'printPackingList' }), typeCode: 2 }
    ], [intl])

    const print = useDownloadInvoicePDF()
    const printChangeFrom = useCallback(() => {
        if (checked.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'print' }, messages: { code: 'w0036' } }))
            return
        }
        if (selections.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'print' }, messages: { code: 'w0002' } }))
            return
        }
        const conditions = {
            invoiceNos: selections,
            printInvoiceFlag: checked.find(m => m === 1) !== undefined ? 1 : 0,
            printPackingListFlag: checked.find(m => m === 2) !== undefined ? 1 : 0,
            printPayableFlag: checked.find(m => m === 3) !== undefined ? 1 : 0,
            printReceiveableFlag: 0
        }
        // // prepare print name
        // let labelId = ''
        // if (conditions.printInvoiceFlag === 1) {
        //     labelId = 'Invoice'
        // }
        // if (conditions.printPackingListFlag === 1) {
        //     labelId = labelId + (labelId.length > 0 ? '&' : '') + 'PackingList'
        // }
        // if (conditions.printPayableFlag === 1) {
        //     labelId = labelId + (labelId.length > 0 ? '&' : '') + 'AccountsPayableReport'
        // }
        print(conditions)
    }, [checked, dispatch, print, selections])

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
        setOpen(true)
    }

    return <>
        {/* <Access access=" ACCT.AIS010.PRINT"> */}
        <Tooltip title={intl.formatMessage({ id: "print" })}>
            <NeumorphismButton onClick={handleClickListItem} style={{ width: 36, marginLeft: theme.spacing(1) }}>{<Print></Print>}</NeumorphismButton>
        </Tooltip>
        <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <List>
                {typeList.map((value, index) => {
                    const labelId = `checkbox-list-label-${index}`
                    return (
                        <>
                            <ListItem key={index} dense button onClick={handleCheck(value.typeCode)}>
                                <ListItemAvatar>
                                    <FileCopyOutlined></FileCopyOutlined>
                                </ListItemAvatar>
                                <ListItemText id={labelId} primary={value.typeName} />
                                <ListItemSecondaryAction>
                                    <Checkbox
                                        edge="end"
                                        onChange={handleCheck(value.typeCode)}
                                        checked={checked.indexOf(value.typeCode) !== -1}
                                        inputProps={{ 'aria-labelledby': labelId }} />
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider component="li" />
                        </>
                    )
                })}
            </List>
            <MenuItem>
                <Button onClick={printChangeFrom} variant="contained" color="primary"><FormattedMessage id="print" /></Button>
            </MenuItem>
        </Menu>
        {/* </Access> */}
    </>
}

const ViewRowAction = (props: DataGridRowActionProps & { data: BuyerInvoiceTransfer[] }) => {
    const { tableRow, data } = props
    const path = useMemo(() => `/gr/view-${data?.find(d => tableRow.rowId === d.invoiceNo)?.invoiceId}`, [data, tableRow.rowId])
    return <ViewRedirectRowAction tableRow={tableRow} access="ACCT.AIS010.VIEWDETAIL" path={path} />
}