import { Project } from "../../../utils/Project"

export interface BookmarkCreateResult {
    actionType: number,
    externalBookmarkId: string
}
interface BookmarkCreateResultJson {
    actionType: number,
    externalBookmarkId: string
}
export const bookmarkCreateResultFromJson: Project<BookmarkCreateResultJson, BookmarkCreateResult> = json => ({
    ...json
})


