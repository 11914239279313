import moment from "moment"
import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"

export type ImpInventoryListFactor = Partial<Optional>

type ImpInventoryListFactorJson = Partial<JsonOptional>

export const impInventoryListFactorToJson: Project<ImpInventoryListFactor, ImpInventoryListFactorJson> = obj => ({
    ...obj,
    updatedDateStart0: obj.updatedDateStart0 == null ? undefined : moment(obj.updatedDateStart0).format('YYYY-MM-DD'),
    updatedDateEndNext: obj.updatedDateEndNext == null ? undefined : moment(obj.updatedDateEndNext).format('YYYY-MM-DD'),
    createdDateStart0: obj.createdDateStart0 == null ? undefined : moment(obj.createdDateStart0).format('YYYY-MM-DD'),
    createdDateEndNext: obj.createdDateEndNext == null ? undefined : moment(obj.createdDateEndNext).format('YYYY-MM-DD'),
    stockDateStart0: obj.stockDateStart0 == null ? undefined : moment(obj.stockDateStart0).format('YYYY-MM-DD'),
    stockDateEndNext: obj.stockDateEndNext == null ? undefined : moment(obj.stockDateEndNext).format('YYYY-MM-DD'),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page)
})

export const impInventoryListFactorFromJson: Project<ImpInventoryListFactorJson, ImpInventoryListFactor> = json => ({
    ...json,
    updatedDateStart0: json.updatedDateStart0 == null ? undefined : moment(json.updatedDateStart0).toDate(),
    updatedDateEndNext: json.updatedDateEndNext == null ? undefined : moment(json.updatedDateEndNext).toDate(),
    createdDateStart0: json.createdDateStart0 == null ? undefined : moment(json.createdDateStart0).toDate(),
    createdDateEndNext: json.createdDateEndNext == null ? undefined : moment(json.createdDateEndNext).toDate(),
    stockDateStart0: json.stockDateStart0 == null ? undefined : moment(json.stockDateStart0).toDate(),
    stockDateEndNext: json.stockDateEndNext == null ? undefined : moment(json.stockDateEndNext).toDate(),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page)
})


interface Optional extends Omit<JsonOptional, 'updatedDateStart0' | 'updatedDateEndNext' | 'createdDateStart0' | 'createdDateEndNext' | 'stockDateStart0' | 'stockDateEndNext'> {
    updatedDateStart0: Date,
    updatedDateEndNext: Date,
    createdDateStart0: Date,
    createdDateEndNext: Date,
    stockDateStart0: Date,
    stockDateEndNext: Date,

    page: PageViewEntity,
}


interface JsonOptional {
    ttcPartsNo: string,
    custPartsNo: string,
    custBackNo: string,
    externalRefNo: string,

    customerList: string[],
    dataSourceList: number[],

    customerCode: string,


    updatedDateStart0: string,
    updatedDateEndNext: string,
    createdDateStart0: string,
    createdDateEndNext: string,
    stockDateStart0: string,
    stockDateEndNext: string,

    stockQtySt: string,
    stockDeltaQtySt: string,
    onholdDeltaQtySt: string,
    dateFormat: string,

    groupList: number[],
    custCodeList: string[],
    sellerCodeList: string[],
    dataCustCodeList: string[],

    page: PageViewEntityJson
}

