import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Row, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DateRangeItem, Form, StringItem, YearMonthItem } from "@rithe/form"
import { Arrays, Records } from "@rithe/utils"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { ApplyCallbackViewAction } from "../../../components/Action/ApplyCallbackViewAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { View } from "../../../components/View/View"
import { useGetCustomerUsagePage120 } from "../../../services/smt/api/CustomerUsageApi120"
import { useDownloadUasgeOfTTC } from "../../../services/smt/api/smtDownloadApi"
import { CustomerUsageTransfer120 } from "../../../services/smt/models/CustomerUsageTransfer120"
import { CustomerUsageView120, customerUsageViewToJson120 } from "../../../services/smt/models/CustomerUsageView120"
import { applicationActions } from "../../Application/applicationSlice"

interface CSUHS120PcUiProps {
  filters: CustomerUsageView120
  setFilters: React.Dispatch<React.SetStateAction<CustomerUsageView120>>
  monthList: Date[],
  setMonthList: React.Dispatch<React.SetStateAction<Date[]>>
  data: CustomerUsageTransfer120[]
  setData: React.Dispatch<React.SetStateAction<CustomerUsageTransfer120[]>>
  totalCount: number
  search: (filters: CustomerUsageView120) => void
}

export const CSUHS120PcUi = (props: CSUHS120PcUiProps) => {
  const { filters, setFilters, setData, setMonthList } = props
  return <View flex actions={[<FormAction filters={filters} setFilters={setFilters} />, <ApplyAction filters={filters} setData={setData} setMonthList={setMonthList} />]}>
    <SectionCard>
      <SectionCardContent>
        <DataTable {...props} />
      </SectionCardContent>
    </SectionCard>
  </View>
}

const DataTable = ({ filters, data, setFilters, search, monthList, setMonthList }: CSUHS120PcUiProps) => {
  const intl = useIntl()
  const [selections, setSelections] = useState<number[]>([])

  const getEachMothData = (index: number) => {
    return (row: Row) => {
      return row.monthlyQtyList[index]
    }
  }

  const columns = useMemo(() => {
    const defaultColumns = [
      { field: "partsNo", dataTypeName: "string", title: intl.formatMessage({ id: "partsNo" }), width: 200, },
      { field: "customerCode", dataTypeName: "string", title: intl.formatMessage({ id: "field.buyerCode" }), width: 200, },
      { field: "expCountry", dataTypeName: "string", title: intl.formatMessage({ id: "field.sellerCountry" }), width: 200, },
      { field: "supplierCode", dataTypeName: "string", title: intl.formatMessage({ id: "field.sellerCode" }), width: 200, },
      { field: "extRefNo", dataTypeName: "string", title: intl.formatMessage({ id: "field.extRefNo" }), width: 200, },
      { field: "updatedDate", dataTypeName: "date", title: intl.formatMessage({ id: "updatedDate" }), width: 200, },
      { field: "updatedDateTime", dataTypeName: "datetime", title: intl.formatMessage({ id: "updatedDateTime" }), width: 200, },
      // { field: "usageStartDate", dataTypeName: "date", title: intl.formatMessage({ id: "usageStartDate" }), width: 200, },
      // { field: "usageEndDate", dataTypeName: "date", title: intl.formatMessage({ id: "usageEndDate" }), width: 200, },
      // { field: "usageQty", dataTypeName: "number", title: intl.formatMessage({ id: "usageQty" }), width: 200, },
      { field: "usageVersion", dataTypeName: "string", title: intl.formatMessage({ id: "buyerUsage" }), width: 200, },
    ]
    const monthColumns = (monthList ?? []).map((month, index) => {
      const monthformat = intl.formatDate(month, { month: 'short', year: 'numeric' })
      return ({ field: monthformat, dataTypeName: 'number', title: monthformat, width: 150, getCellValue: getEachMothData(index) })
    })
    return Arrays.concat(defaultColumns, monthColumns)
  }, [intl, monthList])

  // const getRowId = useCallback((row: any) => row.customerPartsId, []);

  const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search }), [filters, setFilters, search])
  const [order, setOrder] = useState<string[]>([])
  useEffect(() => {
    setOrder(columns.map(column => column.field))
  }, [columns])

  const actionProps1 = useMemo(() => ({ search, filters, selections, data }), [filters, search, selections, data])
  const displayDownload = useCallback(() =>
    filters.usageStartDate !== null && filters.usageStartDate !== undefined &&
    filters.usageEndDate !== null && filters.usageEndDate !== undefined,
    [filters.usageEndDate, filters.usageStartDate])

  return <DataGrid>
    <ToolbarLayout />
    <TableLayout Container={FlexScrollbar}>
      <TableHeaderLayout sticky />
      <TableBodyLayout />
    </TableLayout>
    <PaginationLayout Pagination={Pagination} />
    <DataTypePreset />
    <Data rows={data} columns={columns} />
    <ColumnFreeze />
    <ToolbarActionProvider Action={DownloadAction} display={displayDownload} actionProps={actionProps1} />
    <ColumnVisibility defaultHiddenFields={["customerPartsId", "decimalDigits", "updatedDateTime", "usageStartDate", "usageEndDate", "usageQty"]} ToolbarButton={ColumnVisibilityToolbarButton} />
    <ColumnOrdering order={order} onOrderChange={setOrder} />
    <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
    <Searching ignoreCase Input={SearchInput} />
    <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
    <Sorting />
    <Filtering />
    <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
    <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
  </DataGrid>
}

const DownloadAction = ({ filters, search, selections, data }: {
  filters: CustomerUsageView120
  search: (filters: CustomerUsageView120) => void
  selections: number[]
  data: CustomerUsageTransfer120[],
}) => {

  const downloadByFilterApi = useDownloadUasgeOfTTC()
  const download = useCallback(() => {
    const selectDatas = selections.map(index => ({ customerPartsId: data[index].customerPartsId, usageVersion: data[index].usageVersion }))
    downloadByFilterApi({
      ...filters,
      page: undefined,
      selectDatas: selectDatas,
    })
  }, [data, downloadByFilterApi, filters, selections])

  //CUST.USAGEHIST.CSUHS121.DOWNLOAD
  return <DownloadGroupedToolbarAction access="">
    {onClose => <>
      <GroupedCallbackItem label={<FormattedMessage id="download" />} callback={download} onClose={onClose} />
    </>}
  </DownloadGroupedToolbarAction>
}

const FormAction = (({ filters, setFilters }: {
  filters: CustomerUsageView120,
  setFilters: React.Dispatch<React.SetStateAction<CustomerUsageView120>>
}) => {
  const intl = useIntl()
  return <div style={{ display: "flex" }}>
    <Form data={filters} setData={setFilters} labelDisplay="inline" helperDisplay="none" columnCount={2} maxWidth={600} minWidth={600} labelWidth={150}>
      <YearMonthItem field="usageStartDate" label={intl.formatMessage({ id: 'ProductionStartDate' })} />
      <YearMonthItem field="usageEndDate" label={intl.formatMessage({ id: 'ProductionEndDate' })} />
    </Form>
  </div>
})

const ApplyAction = (({ filters, setData, setMonthList }: {
  filters: CustomerUsageView120,
  setData: React.Dispatch<React.SetStateAction<CustomerUsageTransfer120[]>>,
  setMonthList: React.Dispatch<React.SetStateAction<Date[]>>
}) => {
  const dispatch = useDispatch()
  const getCustomerUsageList = useGetCustomerUsagePage120()
  const search = useCallback(() => {
    const startDate = customerUsageViewToJson120(filters).usageStartDate ?? ''
    const endDate = customerUsageViewToJson120(filters).usageEndDate ?? ''
    if (MonthsBetWeen(startDate, endDate) > 12) {
      dispatch(applicationActions.pushError({ title: { code: 'dateRange' }, messages: { code: 'w0583' } }))
    } else {
      getCustomerUsageList(filters, { silent: true, serialized: true }).then(result => {
        if (result?.page) {
          setData(result.page?.data || [])
        }
        setMonthList(result?.monthList ?? [])
      })
    }
  }, [dispatch, filters, getCustomerUsageList, setData, setMonthList])

  return <ApplyCallbackViewAction callback={search} />
})

function MonthsBetWeen(startUsageDate: string, endUsageDate: string) {
  const startDate = startUsageDate.split('-')
  const endDate = endUsageDate.split('-')
  const startYear = parseInt(startDate[0])
  const startMonth = parseInt(startDate[1])
  const endYear = parseInt(endDate[0])
  const endMonth = parseInt(endDate[1])
  const months = (endYear - startYear) * 12 + (endMonth - startMonth) + 1
  return months
}


const FilterItem = (props: {
  filters: CustomerUsageView120
  setFilters: React.Dispatch<React.SetStateAction<CustomerUsageView120>>
  search: (filters: CustomerUsageView120) => void
}) => {
  const { filters, setFilters, search } = props

  const updatedDateGetValue = useCallback((filters: CustomerUsageView120) => {
    return [filters.updatedDateStart ?? null, filters.updatedDateEnd ?? null]
  }, [])

  const updatedDateMapValue = useCallback((filters: CustomerUsageView120, value: any) => {
    return { ...filters ?? {}, updatedDateStart: value[0], updatedDateEnd: value[1] }
  }, [])

  const clear = useCallback((filters: CustomerUsageView120) => {
    return { page: filters.page }
  }, [])


  const filterCounter = useCallback((filters: CustomerUsageView120) => {
    return [
      filters.modelNo,
      filters.partsNo,
      filters.customerCode,
      filters.expCountry,
      filters.extRefNo,
      filters.supplierCode,
      //filters.usageStartDate || filters.usageEndDate,
      filters.updatedDateStart || filters.updatedDateEnd,
    ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
  }, [])
  const intl = useIntl()

  return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
    <StringItem field="modelNo" label={intl.formatMessage({ id: 'field.modelNo' })} />
    <StringItem field="partsNo" label={intl.formatMessage({ id: 'field.partsNo' })} />
    <StringItem field="customerCode" label={intl.formatMessage({ id: 'field.buyerCode' })} />
    <StringItem field="expCountry" label={intl.formatMessage({ id: 'field.sellerCountry' })} />
    <StringItem field="extRefNo" label={intl.formatMessage({ id: 'field.extRefNo' })} />
    <StringItem field="supplierCode" label={intl.formatMessage({ id: 'field.sellerCode' })} />
    <DateRangeItem field="updatedDate" label={intl.formatMessage({ id: 'field.updatedDate' })} getValue={updatedDateGetValue} mapValue={updatedDateMapValue} />
  </FilterToolbarItem>
}

