import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useComMasterGetAllRegion, useGetZoneList } from "../../../services/common/apis/commonMasterApi"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { useGetCompanyDetailForEdit, useGetCompanyDetailForView } from "../../../services/master/apis/companyApi"
import { TnmCompanyTemp } from "../../../services/master/models/TnmCompanyTemp"
import { TnmRegion } from "../../../services/master/models/TnmRegion"
import { useMatch } from "../../../utils/useMatch"
import { MLS011PcUi } from "./MLS011PcUi"

export const MLS011 = () => {
    const { companyCode } = useParams() as any
    const { path } = useMatch()
    const mode = path === '/company/edit-:companyCode' ? ScreenMode.EDIT : path === '/company/create' ? ScreenMode.CREATE : ScreenMode.VIEW

    const [companyInfo, setCompanyInfo] = useState<TnmCompanyTemp>({} as any)
    const [zones, setZones] = useState<{ first: string, second: string }[]>([])
    const [regions, setRegions] = useState<TnmRegion[]>([])

    const getCompanyDetailForView = useGetCompanyDetailForView()
    const getCompanyDetailForEdit = useGetCompanyDetailForEdit()
    const getCompanyDetail = mode === ScreenMode.VIEW ? getCompanyDetailForView : getCompanyDetailForEdit


    const search = useCallback(() => {
        getCompanyDetail({ companyCode: companyCode }, { silent: true, serialized: true }).then(result => {
            setCompanyInfo(result?.basicInfo ?? {} as any)
        })
    }, [companyCode, getCompanyDetail])

    useEffect(() => {
        if (companyCode !== null && companyCode !== undefined) {
            search()
        }
    }, [companyCode, search])

    const getZoneList = useGetZoneList()
    const getRegionList = useComMasterGetAllRegion()
    useEffect(() => {
        getZoneList(undefined, { silent: true }).then(result => {
            setZones(result || [])
        })
        getRegionList(undefined, { silent: true }).then(result => {
            setRegions(result || [])
        })
    }, [getRegionList, getZoneList])

    return <MLS011PcUi
        companyInfo={companyInfo}
        setCompanyInfo={setCompanyInfo}
        search={search}
        companyCode={companyCode}

        zones={zones}
        regions={regions}
        mode={mode}
    />
}