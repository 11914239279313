import { Project } from "../../../utils/Project"
import { dateToJson } from "../../utils/serializer"

export type POBalanceFactor = Required & Partial<Optional>
type POBalanceFactorJson = JsonRequired & Partial<JsonOptional>
export const POBalanceFactorToJson: Project<POBalanceFactor, POBalanceFactorJson> = obj => ({
    ...obj,
    pnaRequestDateStart: dateToJson(obj.pnaRequestDateStart),
    pnaRequestDateEnd: dateToJson(obj.pnaRequestDateEnd)
})

interface Required extends JsonRequired {

}

interface Optional extends Omit<JsonOptional, 'pnaRequestDateStart' | 'pnaRequestDateEnd'> {
    pnaRequestDateStart: Date,
    pnaRequestDateEnd: Date,
}

interface JsonRequired {

}

interface JsonOptional {
    poNo: string,
    soNo: string,
    partsNo: string,
    buyerPartsNo: string,
    sellerPartsNo: string,
    pnaRequestDateStart: string,
    pnaRequestDateEnd: string,
    onlyUncompletedFlag: boolean,
    supplierIds: number[],
    suppContractIds: number[],
}

