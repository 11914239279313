import { makeStyles, TableCell } from "@material-ui/core"
import { DataGridBodyCellProps } from "@rithe/data-grid/dist/components/basic/DataGridBodyCell"
import { ListTableRow } from "./ListTable"

export function ExpandCell(props: DataGridBodyCellProps) {
  const {
    align,
    width,
    colSpan,
    rowSpan,
    freezePosition,
    freezeOffset,
    children,
    tableRows,
  } = props

  const row = tableRows[0].row as ListTableRow
  const display = row.orderCalcCount >  1 && row.orderVersion === 1
  const styles = useStyles()
  return <TableCell
    colSpan={colSpan}
    rowSpan={rowSpan}
    style={{
      width,
      position: freezePosition ? 'sticky' : 'static',
      left: freezePosition === 'start' ? freezeOffset : undefined,
      right: freezePosition === 'end' ? freezeOffset : undefined,
      zIndex: freezePosition ? 1 : 0,
    }}
    className={styles.root}
  >
    <div
      style={{
        width,
        justifyContent: align === 'center' ? 'center' : `flex-${align}`,
      }}
      className={styles.layout}
    >
      {display ? children : null}
    </div>
  </TableCell>
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    boxSizing: 'border-box',
  },
  layout: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: `0 ${theme.spacing(1)}px`,
    boxSizing: 'border-box',
    overflow: 'hidden',
  }
}))