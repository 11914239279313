import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useComMasterGetActiveCbds, useComMasterGetActiveShippingRoute, useComMasterGetAllCurrency, useComMasterGetAllDc, useComMasterGetAllIncoterms } from "../../../services/common/apis/commonMasterApi"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { useGetContractDetailHeader, useGetContractDetailListByContractId } from "../../../services/master/apis/contractApi"
import { useGetMyPaymentTerms } from "../../../services/master/apis/workingApi"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { DeliveryTypeFlag } from "../../../services/master/enums/DeliveryTypeFlag"
import { ContractHeaderResult } from "../../../services/master/models/ContractHeaderResult"
import { ContractPartsResult } from "../../../services/master/models/ContractPartsResult"
import { TnmCurrency } from "../../../services/master/models/TnmCurrency"
import { TnmDistributionCenter } from "../../../services/master/models/TnmDistributionCenter"
import { TnmIncoterms } from "../../../services/master/models/TnmIncoterms"
import { TnmPaymentTerms } from "../../../services/master/models/TnmPaymentTerms"
import { TnmShippingRoute } from "../../../services/master/models/TnmShippingRoute"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { useMatch } from "../../../utils/useMatch"
import { MLS091PcUi } from "./MLS091PcUi"

export const MLS091 = () => {

    const { contractId } = useParams() as any
    const { path } = useMatch()
    const mode = path === '/contract/edit-:contractId' ? ScreenMode.EDIT : ScreenMode.VIEW

    const [partsData, setPartsData] = useState<ContractPartsResult[]>([])
    const [headerData, setHeaderData] = useState<ContractHeaderResult>({} as ContractHeaderResult)
    const [cbdsList, setCbdsList] = useState<TnvCbds[]>([] as TnvCbds[])
    const [dcList, setDcList] = useState<TnmDistributionCenter[]>([])
    const [paymentTermList, setPaymentTermList] = useState<TnmPaymentTerms[]>([] as TnmPaymentTerms[])
    const [currencyList, setCurrencyList] = useState<TnmCurrency[]>([] as TnmCurrency[])
    const [incotermList, setIncotermList] = useState<TnmIncoterms[]>([] as TnmIncoterms[])
    const [shippingRouteList, setShippingRouteList] = useState<TnmShippingRoute[]>([])

    const getContractParts = useGetContractDetailListByContractId()
    const getContractHeader = useGetContractDetailHeader()
    const getCbdsList = useComMasterGetActiveCbds()
    const getDcList = useComMasterGetAllDc()
    const getPaymentTerms = useGetMyPaymentTerms()
    const getCurrencyList = useComMasterGetAllCurrency()
    const getIncotermList = useComMasterGetAllIncoterms()
    const getShippingRoute = useComMasterGetActiveShippingRoute()

    const search = useCallback(() => {
        getContractParts({ contractId: contractId }, { silent: true, serialized: true }).then(result => {
            setPartsData(result ?? [])
        })
        getContractHeader({ contractId: contractId }, { silent: true, serialized: true }).then(result => {
            setHeaderData({ ...result, deliveryTypeFlag: DeliveryTypeFlag.N, } || {} as ContractHeaderResult)
        })
    }, [contractId, getContractHeader, getContractParts])

    useEffect(() => {
        if (contractId !== null || contractId !== undefined) {
            search()
        } else {
            setPartsData([])
            setHeaderData({} as ContractHeaderResult)
        }
    }, [contractId, search])

    useEffect(() => {
        getCbdsList({ types: [CbdsType.CUST, CbdsType.SUPP, CbdsType.BU, CbdsType.DC] }, { silent: true, serialized: true }).then(result => {
            setCbdsList(result || [])
        })
    }, [getCbdsList])

    useEffect(() => {
        getDcList(undefined, { silent: true, serialized: true }).then(result => {
            setDcList(result || [])
        })
    }, [getCbdsList, getDcList])

    useEffect(() => {
        getPaymentTerms(undefined, { silent: true, serialized: true }).then(result => {
            setPaymentTermList(result || [])
        })
    }, [getPaymentTerms])

    useEffect(() => {
        getCurrencyList(undefined, { silent: true, serialized: true }).then(result => {
            setCurrencyList(result || [])
        })
    }, [getCurrencyList])

    useEffect(() => {
        getIncotermList(undefined, { silent: true, serialized: true }).then(result => {
            setIncotermList(result || [])
        })
    }, [getIncotermList])

    useEffect(() => {
        getShippingRoute(undefined, { silent: true, serialized: true }).then(result => {
            setShippingRouteList(result || [])
        })
    }, [getShippingRoute])


    return <MLS091PcUi
        partsData={partsData}
        headerData={headerData}
        cbdsList={cbdsList}
        dcList={dcList}
        paymentTermList={paymentTermList}
        currencyList={currencyList}
        incotermList={incotermList}
        mode={mode}
        setHeaderData={setHeaderData}
        setPartsData={setPartsData}
        search={search}
        shippingRouteList={shippingRouteList}
    />
}