import { Project } from "../../../utils/Project"
import { YESNOFlag } from "../../privilege/enums/YESNOFlag"
import { TableEntity, tableEntityFromJson, TableEntityJson, tableEntityToJson } from "../../utils/TableEntity"
import { ActiveFlag } from "../enums/ActiveFlag"

export type TnmCompanyTemp = Required & Partial<Optional> & TableEntity
export type TnmCompanyTempJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const tnmCompanyTempToJson: Project<TnmCompanyTemp, TnmCompanyTempJson> = obj => ({
    ...tableEntityToJson(obj),
})
export const tnmCompanyTempFromJson: Project<TnmCompanyTempJson, TnmCompanyTemp> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
    activeFlag: ActiveFlag,
    portcastFlag: YESNOFlag
}

interface JsonRequired {
}

interface JsonOptional {
    tempCompanyId: number,
    companyId: number,
    companyCode: string,
    companyName: string,
    companyShortCode: string,
    regionCode: string,
    address1: string,
    address2: string,
    address3: string,
    address4: string,
    postalCode: string,
    contact1: string,
    fax1: string,
    telephone1: string,
    email1: string,
    contact2: string,
    fax2: string,
    telephone2: string,
    email2: string,
    timeZone: string,
    fileName: string,
    activeFlag: number,
    portcastFlag: number
}