import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { Projects } from "../../../utils/Projects"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { ProjectFactor, projectFactorToJson } from "../models/ProjectFactor"
import { ProjectFieldsFactor, projectFieldsFactorToJson } from "../models/ProjectFieldsFactor"
import { ProjectFieldsResult, projectFieldsResultFromJson } from "../models/ProjectFieldsResult"
import { ProjectMaterialsFactor, projectMaterialsFactorToJson } from "../models/ProjectMaterialsFactor"
import { ProjectPlanHistoryResult, projectPlanHistoryResultFromJson } from "../models/ProjectPlanHistoryResult"
import { ProjectResult, projectResultFromJson } from "../models/ProjectResult"
import { UsageHistoryResult, usageHistoryResultFromJson } from "../models/UsageHistoryResult"

export const useGetProjectPage = () => {
    const url = '/smt-api/project/getPageList'
    const payloadProject = useCallback(projectFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(projectResultFromJson), [])
    return usePost<ProjectFactor, PageResultEntity<ProjectResult>>(url, payloadProject, resultProject)
}

export const useDeleteProject = () => {
    const url = '/smt-api/project/deleteProject'
    const payloadProject = useCallback((obj: { projectId: number }) => obj, [])
    const resultProject = Projects.IGNORE
    return usePost<{ projectId: number }, undefined>(url, payloadProject, resultProject)
}

export const useGetUsageHistoryPage = () => {
    const url = '/smt-api/project/getProjectBomUsageHistoryList'
    const payloadProject = useCallback((obj: { projectId: number }) => obj, [])
    const resultProject = useCallback(Projects.array(usageHistoryResultFromJson), [])
    return usePost<{ projectId: number }, UsageHistoryResult[]>(url, payloadProject, resultProject)
}

export const useGetPlanHistoryPage = () => {
    const url = '/smt-api/prodplan/getPlanHistoryList'
    const payloadProject = useCallback((obj: { projectId: number }) => obj, [])
    const resultProject = useCallback(Projects.array(projectPlanHistoryResultFromJson), [])
    return usePost<{ projectId: number }, ProjectPlanHistoryResult[]>(url, payloadProject, resultProject)
}

export const useDeleteUasgeHistory = () => {
    const url = '/smt-api/project/deleteUsageHistory'
    const payloadProject = useCallback((obj: { usageHistoryId: number }) => obj, [])
    const resultProject = Projects.IGNORE
    return usePost<{ usageHistoryId: number }, undefined>(url, payloadProject, resultProject)
}

export const useGetFieldName = () => {
    const url = '/smt-api/project/getFieldNameList'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback((nameList: string[]) => nameList, [])
    return usePost<undefined, string[]>(url, payloadProject, resultProject)
}

export const useGetMaterialName = () => {
    const url = '/smt-api/project/getMaterialNameList'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback((nameList: string[]) => nameList, [])
    return usePost<undefined, string[]>(url, payloadProject, resultProject)
}

export const useGetFieldListById = () => {
    const url = '/smt-api/project/getProjectFieldList'
    const payloadProject = useCallback((obj: { projectId: number }) => obj, [])
    const resultProject = useCallback(Projects.array(projectFieldsResultFromJson), [])
    return usePost<{ projectId: number }, ProjectFieldsResult[]>(url, payloadProject, resultProject)
}

export const useGetMeterialListById = () => {
    const url = '/smt-api/project/getProjectMaterialList'
    const payloadProject = useCallback((obj: { projectId: number }) => obj, [])
    const resultProject = useCallback(Projects.array(projectFieldsResultFromJson), [])
    return usePost<{ projectId: number }, ProjectFieldsResult[]>(url, payloadProject, resultProject)
}

export const useSaveProject = () => {
    const url = '/smt-api/project/saveProject'
    const payloadProject = useCallback(projectFieldsFactorToJson, [])
    const resultProject = useCallback((projectId: number) => projectId, [])
    return usePost<ProjectFieldsFactor, number>(url, payloadProject, resultProject)
}

export const useSaveField = () => {
    const url = '/smt-api/project/saveField'
    const payloadProject = useCallback(projectFieldsFactorToJson, [])
    const resultProject = useCallback((projectId: number) => projectId, [])
    return usePost<ProjectFieldsFactor, number>(url, payloadProject, resultProject)
}

export const useSaveMeterial = () => {
    const url = '/smt-api/project/saveMaterial'
    const payloadProject = useCallback(projectMaterialsFactorToJson, [])
    const resultProject = Projects.IGNORE
    return usePost<ProjectMaterialsFactor, undefined>(url, payloadProject, resultProject)
}

export const useGetProjectById = () => {
    const url = '/smt-api/project/getProjectById'
    //const payloadProject = useCallback((obj: { projectId: number }) => obj.projectId, []);
    const payloadProject = useCallback((obj: { projectId: number }) => obj, [])
    const resultProject = useCallback(projectResultFromJson, [])
    return usePost<{ projectId: number }, ProjectResult>(url, payloadProject, resultProject)
}

export const useGetAllProject = () => {
    const url = '/smt-api/usage/project/findAllProject'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(projectResultFromJson), [])
    return usePost<undefined, ProjectResult[]>(url, payloadProject, resultProject)
}