import { Project } from "@rithe/utils";
import { dateToJson } from "../../utils/serializer";
import { Currency } from "./GiGrInvoice";

export interface GiGrInvoiceSaveVo {
    invoiceId: number,
    vesselName?: string,
    voyageNo?: string,
    etd?: Date,
    eta?: Date,
    invoiceDate?: Date,
    paymentTermId?: number,
    countryOfOrigin: string,
    remark?: string,
    profiles: Profile[],
    prices: Price[],
    breakdown: Breakdown,
    invoices: Invoice[],
    packages: Package[],
}

interface GiGrInvoiceSaveVoJson {
    invoiceId: number,
    vesselName?: string,
    voyageNo?: string,
    etd?: string,
    eta?: string,
    invoiceDate?: string,
    paymentTermId?: number,
    countryOfOrigin: string,
    remark?: string,
    profiles: ProfileJson[],
    prices: PriceJson[],
    breakdown: BreakdownJson,
    invoices: InvoiceJson[],
    packages: PackageJson[],
}

interface Invoice {
    invoiceId: number,
    vesselName?: string,
    voyageNo?: string,
    etd?: Date,
    eta?: Date,
    invoiceDate?: Date,
    paymentTermId?: number,
    countryOfOrigin: string,
    remark?: string,
    profiles: Profile[],
    prices: Price[],
    breakdown: Breakdown,
    packages: Package[],
}

interface InvoiceJson {
    invoiceId: number,
    vesselName?: string,
    voyageNo?: string,
    etd?: string,
    eta?: string,
    invoiceDate?: string,
    paymentTermId?: number,
    countryOfOrigin: string,
    remark?: string,
    profiles: ProfileJson[],
    prices: PriceJson[],
    breakdown: BreakdownJson,
    packages: PackageJson[],
}

interface Profile extends ProfileJson {
}

interface ProfileJson {
    profileUid: string,
    profileType: number,
    name?: string,
    address1?: string,
    address2?: string,
    address3?: string,
    address4?: string,
    tel?: string,
    fax?: string,
}

interface Package extends PackageJson { }

interface PackageJson {
    invoicePackageId: number,
    packageNo?: string,
    packageType?: number,
    length?: number,
    width?: number,
    height?: number,
    m3?: number,
    grossWeight?: number,
    netWeight: number,
}

interface Breakdown extends BreakdownJson { }

interface BreakdownJson {
    invoiceCurrency?: Currency,
    invoiceAdjustmentAmount?: number,
    invoiceAdjustmentReason?: string,
    costItem1?: string,
    costItem1Amount?: number,
    costItem2?: string,
    costItem2Amount?: number,
    costItem3?: string,
    costItem3Amount?: number,
    costItem4?: string,
    costItem4Amount?: number,
    costItem5?: string,
    costItem5Amount?: number,
    additionalItem1?: string,
    additionalItem1Amount?: number,
    additionalItem2?: string,
    additionalItem2Amount?: number,
    additionalItem3?: string,
    additionalItem3Amount?: number,
    additionalItem4?: string,
    additionalItem4Amount?: number,
    additionalItem5?: string,
    additionalItem5Amount?: number,
    initialIncotermsId?: number,
    initialIncotermsCode?: string,
    initialIncotermsPlace?: string,
    initialIncotermsAmount?: number,
    initialIncotermsDescription?: string,
    finalIncotermsId?: number,
    finalIncotermsCode?: string,
    finalIncotermsPlace?: string,
    finalIncotermsAmount?: number,
    finalIncotermsDescription?: string,
}

interface Price extends PriceJson { }

interface PriceJson {
    invoicePriceId: number,
    price?: number,
    qty?: number,
}

export const giGrInvoiceSaveVoSerializer: Project<GiGrInvoiceSaveVo, GiGrInvoiceSaveVoJson> = data => ({
    ...data,
    etd: dateToJson(data.etd),
    eta: dateToJson(data.eta),
    invoiceDate: dateToJson(data.invoiceDate),
    invoices: data.invoices.map(invoiceSaveVoSerializer)
})

const invoiceSaveVoSerializer: Project<Invoice, InvoiceJson> = data => ({
    ...data,
    etd: dateToJson(data.etd),
    eta: dateToJson(data.eta),
    invoiceDate: dateToJson(data.invoiceDate),
})