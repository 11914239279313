import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { CustomerForecastListFactor, customerForecastListFactorToJson } from "../models/CustomerForecastListFactor"
import { CustomerForecastListResult, customerForecastListResultFromJson } from "../models/CustomerForecastListResult"


export const useGetCfcListByPage = () => {
    const url = '/lcbm-order-api/order/cfc/api/getCfcListByPage'
    const payloadProject = useCallback(customerForecastListFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(customerForecastListResultFromJson), [])
    return usePost<CustomerForecastListFactor, PageResultEntity<CustomerForecastListResult>>(url, payloadProject, resultProject)
}