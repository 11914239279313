
import { Column, ColumnFreeze, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { Break, EntryItem, Form, Message, NumberItem, StringItem } from "@rithe/form"
import { GridItem } from "@rithe/ui"
import { Arrays, Records } from "@rithe/utils"
import { default as React, memo, useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useFunctionStore } from "../../../Root"
import { SubmitCallbackViewAction } from '../../../components/Action/SubmitCallbackViewAction'
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { ActiveFlagTypeProvider } from "../../../components/DataGrid/typeProviders/ActiveFlagTypeProvider"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { PercentTypeProvider } from "../../../components/DataGrid/typeProviders/PercentTypeProvider"
import { CodeItem } from "../../../components/Form/CodeItem"
import { SectionTitle } from "../../../components/SectionTitle/SectionTitle"
import { useComMasterGetActiveShippingRoute, useComMasterGetAllCbds, useComMasterGetAllDc, useComMasterGetAllIncoterms } from "../../../services/common/apis/commonMasterApi"
import { ScreenMode } from '../../../services/common/enums/ScreenMode'
import { useGetContractDetailHeader, useGetContractDetailListByContractId } from "../../../services/master/apis/contractApi"
import { useSubmitRequestDetail } from '../../../services/master/apis/requestApi'
import { useGetMyPaymentTerms } from "../../../services/master/apis/workingApi"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { ContractRequestType } from '../../../services/master/enums/ContaractRequestType'
import { ContractType } from "../../../services/master/enums/ContractType"
import { DeliveryTypeFlag } from "../../../services/master/enums/DeliveryTypeFlag"
import { ContractHeaderResult } from "../../../services/master/models/ContractHeaderResult"
import { ContractPartsResult } from "../../../services/master/models/ContractPartsResult"
import { PartsDetailResult } from '../../../services/master/models/PartsDetailResult'
import { RequestHeaderResult } from '../../../services/master/models/RequestHeaderResult'
import { RequestToResult } from "../../../services/master/models/RequestToResult"
import { TnmDistributionCenter } from "../../../services/master/models/TnmDistributionCenter"
import { TnmIncoterms } from "../../../services/master/models/TnmIncoterms"
import { TnmPaymentTerms } from "../../../services/master/models/TnmPaymentTerms"
import { TnmShippingRoute } from "../../../services/master/models/TnmShippingRoute"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { useGetCompanyUid } from '../../../utils/ApplicationUtils'
import { useFieldChecker, useFormValidater } from '../../../utils/ValidatorUtils'
import { applicationActions } from "../../Application/applicationSlice"
import { MOS021PcUiProps } from './MOS021PcUi'


interface ContractProps {
    shippingRouteList: TnmShippingRoute[],
    partsData: ContractPartsResult[],
    headerData: ContractHeaderResult,
    cbdsList: TnvCbds[],
    dcList: TnmDistributionCenter[],
    paymentTermList: TnmPaymentTerms[],
    incotermList: TnmIncoterms[],
}

export const RequestTerminateView = (props: MOS021PcUiProps) => {

    const contractId = useMemo(() => props.headerInfo.contractId, [props.headerInfo])
    const [partsData, setPartsData] = useState<ContractPartsResult[]>([])
    const [headerData, setHeaderData] = useState<ContractHeaderResult>({} as ContractHeaderResult)
    const [cbdsList, setCbdsList] = useState<TnvCbds[]>([] as TnvCbds[])
    const [dcList, setDcList] = useState<TnmDistributionCenter[]>([])
    const [paymentTermList, setPaymentTermList] = useState<TnmPaymentTerms[]>([] as TnmPaymentTerms[])
    const [incotermList, setIncotermList] = useState<TnmIncoterms[]>([] as TnmIncoterms[])
    const [shippingRouteList, setShippingRouteList] = useState<TnmShippingRoute[]>([])

    const getContractParts = useGetContractDetailListByContractId()
    const getContractHeader = useGetContractDetailHeader()
    const getCbdsList = useComMasterGetAllCbds()
    const getDcList = useComMasterGetAllDc()
    const getPaymentTerms = useGetMyPaymentTerms()
    const getIncotermList = useComMasterGetAllIncoterms()
    const getShippingRoute = useComMasterGetActiveShippingRoute()

    const search = useCallback((contractId: number) => {
        getContractParts({ contractId: contractId }, { silent: true, serialized: true }).then(result => {
            setPartsData(result ?? [])
        })
        getContractHeader({ contractId: contractId }, { silent: true, serialized: true }).then(result => {
            setHeaderData({ ...result, deliveryTypeFlag: DeliveryTypeFlag.N, } || {} as ContractHeaderResult)
        })
    }, [getContractHeader, getContractParts])

    useEffect(() => {
        if (contractId !== null && contractId !== undefined) {
            search(contractId)
        } else {
            setPartsData([])
            setHeaderData({} as ContractHeaderResult)
        }
    }, [contractId, search])

    useEffect(() => {
        getCbdsList({ types: [CbdsType.CUST, CbdsType.SUPP, CbdsType.BU, CbdsType.DC] }, { silent: true, serialized: true }).then(result => {
            setCbdsList(result || [])
        })
    }, [getCbdsList])

    useEffect(() => {
        getDcList(undefined, { silent: true, serialized: true }).then(result => {
            setDcList(result || [])
        })
    }, [getCbdsList, getDcList])

    useEffect(() => {
        getPaymentTerms(undefined, { silent: true, serialized: true }).then(result => {
            setPaymentTermList(result || [])
        })
    }, [getPaymentTerms])

    useEffect(() => {
        getIncotermList(undefined, { silent: true, serialized: true }).then(result => {
            setIncotermList(result || [])
        })
    }, [getIncotermList])

    useEffect(() => {
        getShippingRoute(undefined, { silent: true, serialized: true }).then(result => {
            setShippingRouteList(result || [])
        })
    }, [getShippingRoute])

    const uiProps = {
        ...props,
        shippingRouteList,
        partsData,
        headerData,
        cbdsList,
        dcList,
        paymentTermList,
        incotermList,
    }

    return <PCUiView {...uiProps} />
}

const PCUiView = (props: MOS021PcUiProps & ContractProps) => {
    const { mode, requestToList, headerInfo, setHeaderInfo, setPartsList, messages, setMessages } = props
    const intl = useIntl()
    return (
        <>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={1}
                    step
                    title={intl.formatMessage({ id: 'terminate_contract_title_one' })}
                    subtitle={intl.formatMessage({ id: "add_new_parts_message_one" })}
                />
                <SectionCardContent>
                    <TheFirstDiv mode={mode} requestToList={requestToList} headerInfo={headerInfo} setHeaderInfo={setHeaderInfo} messages={messages} setMessages={setMessages} setPartsList={setPartsList} />
                </SectionCardContent>
            </SectionCard>

            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={2}
                    step
                    title={intl.formatMessage({ id: 'ContractDetailInformation' })}
                    subtitle='View Detail contract information.'
                />
                <SectionCardContent>
                    <HeaderInfo {...props} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={3}
                    step
                    title={intl.formatMessage({ id: 'PartsListInformation' })}
                    subtitle='View parts list information for contract.'
                />
                <SectionCardContent>
                    <DataTable {...props} />
                </SectionCardContent>
            </SectionCard>
        </ >
    )
}

export const SubmitForRequestTerminateAction = ({ headerInfo, setMessages, partsList }: {
    headerInfo: RequestHeaderResult,
    partsList: PartsDetailResult[],
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>
}) => {
    const navigate = useNavigate()
    const submitApi = useSubmitRequestDetail()
    const fields = useMemo(() => getFormCheckFields(true), [])
    const formValidate = useFormValidater(setMessages, fields)
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'submit' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const submitClick = useCallback(() => {
        if (formValidate(headerInfo)) {
            const requestInfo = {
                requestHeader: { ...headerInfo, requestType: ContractRequestType.REMOVE },
                requestPartsList: [...partsList]
            }
            const functionId = functionStore.register(() => {
                setDisabled(true)
                submitApi((requestInfo), { serialized: true }).then(() => navigate(`/requestTo`)).finally(() => {
                    setDisabled(false)
                })
            })
            dispatch(applicationActions.pushWarning({
                title: title,
                messages: { code: 'c0001', args: [title] },
                actions: [{
                    label: 'CANCEL'
                }, {
                    functionId,
                    label: 'CONFIRM',
                }]
            }))
        }
    }, [formValidate, headerInfo, partsList, functionStore, dispatch, title, submitApi, navigate])

    return <SubmitCallbackViewAction callback={submitClick} disabled={disabled} />
}


/** Request To */
interface TheFirstDivInfo {
    mode: ScreenMode,
    requestToList: RequestToResult[],
    headerInfo: RequestHeaderResult,
    setHeaderInfo: React.Dispatch<React.SetStateAction<RequestHeaderResult>>
    messages: Message[],
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
    setPartsList: React.Dispatch<React.SetStateAction<PartsDetailResult[]>>,
}
const TheFirstDiv = memo((props: TheFirstDivInfo) => {
    const { mode, requestToList, headerInfo, setHeaderInfo, messages, setMessages, setPartsList } = props
    const intl = useIntl()
    const companyUid = useGetCompanyUid()
    const requestToMap = useMemo(() => requestToList.filter((m) => m.cbdsUid !== companyUid).map(m => [m.cbdsUid, m.cbdsCode] as [string, string]), [companyUid, requestToList])
    const fields = useMemo(() => getFormCheckFields(true), [])
    const filedCheck = useFieldChecker(fields, setMessages)
    const dataChange = useCallback<React.Dispatch<React.SetStateAction<RequestHeaderResult>>>(nextDraftDataFunc => {
        setHeaderInfo(data => {
            const draftData = typeof nextDraftDataFunc === 'function' ? nextDraftDataFunc(data) : nextDraftDataFunc
            if (draftData.requestType !== data.requestType || draftData.requestTo !== data.requestTo) {
                setPartsList([])
            }
            return draftData;
        })
    }, [setHeaderInfo, setPartsList])
    return <Form data={headerInfo} setData={dataChange} labelDisplay="block" helperDisplay="tooltip" messages={messages} setMessages={setMessages} ensure={filedCheck}>
        <EntryItem field="requestTo" required label={intl.formatMessage({ id: 'field.requestTo' })} entries={requestToMap} readonly />
        <Break />
        <StringItem field="description" label={intl.formatMessage({ id: 'field.description' })} colSpan={2} rowSpan={4} readonly={mode === ScreenMode.VIEW} />
    </Form>
})

/** formValidate function */
const getFormCheckFields = (isIssue: boolean) => {
    return ({
        requestTo: { labelId: 'field.requestTo', required: true },
        description: { labelId: 'field.description', length: { max: 255 } },
    })
}


const HeaderInfo = memo((props: MOS021PcUiProps & ContractProps) => {
    const intl = useIntl()
    const { headerData, paymentTermList, currencyList, cbdsList, dcList, incotermList, shippingRouteList } = props
    const { contractType, orderFrequency } = headerData

    const dcMap: [number, string][] = useMemo(() => dcList.map(m => [m.dcId, m.dcCode]), [dcList])
    const buMap: [string, string][] = useMemo(() => cbdsList.filter((m) => m.cbdsType === CbdsType.BU).map((m) => [m.cbdsUid, m.cbdsCode]), [cbdsList])
    const buCustMap: [string, string][] = useMemo(() => cbdsList.filter((m) => m.cbdsType === CbdsType.CUST || m.cbdsType === CbdsType.BU).map(m => [m.cbdsUid, m.cbdsCode]), [cbdsList])
    const dcCustMap: [string, string][] = useMemo(() => cbdsList.filter((m) => m.cbdsType === CbdsType.CUST || m.cbdsType === CbdsType.DC).map(m => [m.cbdsUid, m.cbdsCode]), [cbdsList])
    const incoMap: [number, string][] = useMemo(() => incotermList.map((m) => [m.incotermsId, m.incotermsCode]), [incotermList])
    const paymentMap: [number, string][] = useMemo(() => paymentTermList.map((m) => [m.paymentTermsId, m.paymentTermsCode + '(' + m.description + ')']), [paymentTermList])
    const srMap: [number, string][] = useMemo(() => shippingRouteList.map((m) => [m.srId, m.shippingRouteCode]), [shippingRouteList])

    const showItem1 = contractType === ContractType.CUST_BU || contractType === ContractType.CUST_SUPP
    const showItem2 = contractType === ContractType.BU_SUPP || contractType === ContractType.CUST_SUPP
    if (contractType === undefined) {
        return <></>
    }
    const leadtimeName = orderFrequency === 10 ? 'field.targetleadtimeMonth' : orderFrequency === 20 ? 'field.targetleadtimeWeek' :
        orderFrequency === 30 ? 'field.targetleadtimeBiweek' : orderFrequency === 40 ? 'field.targetleadtimeTenDay' : 'field.targetLeadtime'

    return <Form data={headerData} labelDisplay="block" helperDisplay="tooltip" readonly={true}>
        <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="basicInfo" /></SectionTitle></GridItem>
        <StringItem required field='contractNo' label={intl.formatMessage({ id: 'field.contractNo' })} />
        <StringItem field="sellerCode" required label={intl.formatMessage({ id: 'seller' })} />
        <EntryItem field="consignee" required label={intl.formatMessage({ id: 'consigneeCode' })} entries={buCustMap} />

        <CodeItem field="contractType" required label={intl.formatMessage({ id: 'field.contractType' })} code={CodeCategory.ContractType} />
        <StringItem field="buyerCode" required label={intl.formatMessage({ id: 'buyer' })} />
        <EntryItem field="accountee" required label={intl.formatMessage({ id: 'accounteeCode' })} entries={buCustMap} />

        <CodeItem field="businessType" required label={intl.formatMessage({ id: 'field.businessType' })} code={CodeCategory.BusinessType} />
        {showItem1 && <CodeItem field="orderFrequency" required label={intl.formatMessage({ id: 'field.orderFrequency' })} code={CodeCategory.OrderFrequency} />}
        <EntryItem field="deliveryTo" required label={intl.formatMessage({ id: 'deliveryToCode' })} entries={dcCustMap} />
        <Break />

        {showItem1 && <NumberItem field="forecastNum" required label={intl.formatMessage({ id: 'field.forecastNum' })} />}
        {showItem1 && <NumberItem field="targetLeadtime" required label={intl.formatMessage({ id: leadtimeName })} />}
        <Break />
        {showItem2 && <NumberItem field="confirmOrderLeadtime" required label={intl.formatMessage({ id: 'field.confirmOrderLeadtime' })} />}
        {showItem2 && <NumberItem field="leadtime" required label={intl.formatMessage({ id: 'field.leadtime' })} />}
        <Break />
        {showItem2 && <NumberItem field="deliveryPlanStartDate" required label={intl.formatMessage({ id: 'field.deliveryPlanStartDate' })} />}
        {showItem2 && <NumberItem field="deliveryPlanEndDate" label={intl.formatMessage({ id: 'field.deliveryPlanEndDate' })} />}
        <Break />
        <StringItem field="description" label={intl.formatMessage({ id: 'description' })} colSpan={2} />

        <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="paymentInfo" /></SectionTitle></GridItem>
        <EntryItem field="paymentTermsId" required label={intl.formatMessage({ id: 'field.paymentTermsCode' })} entries={paymentMap} />
        <EntryItem field="currency" required label={intl.formatMessage({ id: 'field.currency' })} entries={currencyList.map((m) => [m.currencyCode, m.currencyCode])} />
        <CodeItem field="priceBasis" label={intl.formatMessage({ id: 'field.priceBasis' })} code={CodeCategory.PriceBasis} />

        <GridItem columnSpan={3} ><SectionTitle><FormattedMessage id="deliveryInfo" /></SectionTitle></GridItem>
        <EntryItem field="receiveDcId" label={intl.formatMessage({ id: 'receiveDcCode' })} entries={dcMap} />
        <EntryItem field="shipperDcId" label={intl.formatMessage({ id: 'receiveShipperCode' })} entries={dcMap} />
        {/* shipperUid */}
        <EntryItem field="srId" label={intl.formatMessage({ id: 'field.shippingRouteCode' })} entries={srMap} />
        <EntryItem field="spotSrId" label={intl.formatMessage({ id: 'spotSR' })} entries={srMap} />
        {showItem1 && <CodeItem field="autoDiFlag" label={intl.formatMessage({ id: 'autoDiFlag' })} code={CodeCategory.AutoDiFlag} />}
        <Break />

        <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="customsInfo" /></SectionTitle></GridItem>
        <CodeItem field="customsFlag" label={intl.formatMessage({ id: 'field.customsFlag' })} code={CodeCategory.CustomsFlag} />
        <EntryItem field="exportCCAgency" label={intl.formatMessage({ id: 'importCCAgency' })} entries={buMap} />
        <EntryItem field="importCCAgency" label={intl.formatMessage({ id: 'exportCCAgency' })} entries={buMap} />
        <CodeItem field="printHscodeFlag" label={intl.formatMessage({ id: 'field.printHscodeFlag' })} code={CodeCategory.HscodeOutputFlag} />

        <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="incotermsInfo" /></SectionTitle></GridItem>
        <EntryItem field="incotermsId" label={intl.formatMessage({ id: 'field.incotermsCode' })} entries={incoMap} />
        <StringItem field="incotermsPlace" label={intl.formatMessage({ id: 'field.incotermsPlace' })} />
    </Form>
})


const DataTable = memo(({ headerData, partsData }: ContractProps) => {
    const intl = useIntl()
    const { contractType } = headerData

    const columns = useMemo(() => {
        const partsCategories = [{ key: 'partsFirst', value: intl.formatMessage({ id: 'partsName' }) }]
        const priceCogetories = [{ key: 'priceSecond', value: intl.formatMessage({ id: 'priceName' }) }]
        const packingCogetories = [{ key: 'packingThird', value: intl.formatMessage({ id: 'packingName' }) }]
        const psrtsColumns = [
            { width: 220, field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsNo' }), categories: partsCategories },
            { width: 220, field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), categories: partsCategories },
            { width: 220, field: 'sellerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sellerPartsNo' }), categories: partsCategories },
            { width: 220, field: 'buyerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'buyerPartsNo' }), categories: partsCategories },
            { width: 300, field: 'unitPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsName' }), categories: partsCategories },
            { field: 'activeFlag', dataTypeName: CodeCategory.ActiveFlag, title: intl.formatMessage({ id: 'field.activeFlag' }), width: 180, categories: partsCategories }
        ] as Column[]

        const bucolumns = (contractType === ContractType.CUST_BU || contractType === ContractType.BU_BU) ? [
            { field: 'repackingType', dataTypeName: CodeCategory.RepackingType, title: intl.formatMessage({ id: 'field.repackingType' }), width: 200, categories: partsCategories },
            { field: 'safetyStockDays', dataTypeName: 'number', title: intl.formatMessage({ id: 'safetyStockDays' }), width: 200, categories: partsCategories },
            { field: 'safetyStockPct', dataTypeName: 'percent', title: intl.formatMessage({ id: 'field.safetyStockPct' }), width: 200, categories: partsCategories },
        ] as Column[] : []

        const supplierColumns = (contractType === ContractType.CUST_BU || contractType === ContractType.CUST_SUPP) ? [
            { field: 'fluctuationRatio', dataTypeName: 'percent', title: intl.formatMessage({ id: 'field.fluctuationRatio' }), width: 200, categories: partsCategories },
            { field: 'fcFluctuationRatio', dataTypeName: 'percent', title: intl.formatMessage({ id: 'field.fcFluctuationRatio' }), width: 230, categories: partsCategories },
        ] as Column[] : []

        const priceColumns = [
            { width: 180, categories: priceCogetories, field: 'currentPrice', dataTypeName: 'number', title: intl.formatMessage({ id: 'currentPrice' }) },
            { width: 180, categories: priceCogetories, field: 'currentApplyDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'currentApplyDate' }) },
            { width: 180, categories: priceCogetories, field: 'nextPrice', dataTypeName: 'number', title: intl.formatMessage({ id: 'nextPrice' }) },
            { width: 180, categories: priceCogetories, field: 'nextApplyDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'nextApplyDate' }) },
        ] as Column[]

        const buOrSupplierColumns = (contractType === ContractType.CUST_BU || contractType === ContractType.CUST_SUPP) ? [
            { field: 'endUser1', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.endUser1' }), width: 300, categories: partsCategories },
            { field: 'endUser2', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.endUser2' }), width: 300, categories: partsCategories },
            { field: 'endUser3', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.endUser3' }), width: 300, categories: partsCategories },
            { field: 'endUser4', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.endUser4' }), width: 300, categories: partsCategories },
            { field: 'endUser5', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.endUser5' }), width: 300, categories: partsCategories },
        ] as Column[] : []

        const packingColumns = [
            { width: 180, field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), categories: packingCogetories },
            { width: 180, field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), categories: packingCogetories },
            { width: 180, field: 'uom', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.uomCode' }), categories: packingCogetories },
            { width: 180, field: 'm3', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.m3' }), categories: packingCogetories },
            { width: 180, field: 'length', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.length' }), categories: packingCogetories },
            { width: 180, field: 'width', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.width' }), categories: packingCogetories },
            { width: 180, field: 'height', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.height' }), categories: packingCogetories },
            { width: 180, field: 'netWeight', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.netWeight' }), categories: packingCogetories },
            { width: 180, field: 'grossWeight', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.grossWeight' }), categories: packingCogetories },
        ] as Column[]

        return Arrays.concat(psrtsColumns, bucolumns, supplierColumns, priceColumns, buOrSupplierColumns, packingColumns)
    }, [contractType, intl])

    const getRowId = useCallback((row: any) => row.buId, [])
    return <div style={{ width: '100%', height: 500 }} >
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.DeliveryType} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.RepackingType} />
            <ActiveFlagTypeProvider />
            <PercentTypeProvider />
            <Data rows={partsData} columns={columns} getRowId={getRowId} />
            <ColumnFreeze />
            <ColumnVisibility
                defaultHiddenFields={[]}
                columnSettings={{
                    unitPartsNo: { disableUserControl: true },
                    partsNo: { disableUserControl: true },
                }} ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
        </DataGrid>
    </div>
})