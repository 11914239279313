import { Project } from "../../../utils/Project"

export interface SearchConditionResult {
    total?: number,
    suggestions?: SuggestionJson[]
}
interface SearchConditionResultJson {
    total?: number,
    suggestions?: SuggestionJson[]
}
export const searchConditionResultFromJson: Project<SearchConditionResultJson, SearchConditionResult> = json => ({
    ...json,
    suggestions: json.suggestions?.map(suggestionFromJson)
})


export interface Suggestion extends SuggestionJson {
}
interface SuggestionJson {
    type: number,
    suggestion: string
}
export const suggestionFromJson: Project<SuggestionJson, Suggestion> = json => ({
    ...json
})
export const suggestionToJson: Project<Suggestion, SuggestionJson> = obj => ({
    ...obj
})