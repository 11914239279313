import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { dateToJson } from "../../utils/serializer"
import { CompanyOrderInfo, companyOrderInfoFromJson, CompanyOrderInfoJson, companyOrderInfoToJson } from "./CompanyOrderInfo"

export type InboundResult = Required & Partial<Optional>
type InboundResultJson = JsonRequired & Partial<JsonOptional>

export const inboundResultToJson: Project<InboundResult, InboundResultJson> = obj => ({
    ...obj,
    inboundDate: dateToJson(obj.inboundDate),
    salOrderInfo: obj.salOrderInfo ? companyOrderInfoToJson(obj.salOrderInfo) : undefined,
    purOrderInfo: obj.purOrderInfo ? companyOrderInfoToJson(obj.purOrderInfo) : undefined,
    orderInfoList: obj.orderInfoList ? obj.orderInfoList.map((m) => companyOrderInfoToJson(m)) : []
})

export const inboundResultFromJson: Project<InboundResultJson, InboundResult> = json => ({
    ...json,
    inboundDate: dateFromJson(json.inboundDate),
    salOrderInfo: json.salOrderInfo ? companyOrderInfoFromJson(json.salOrderInfo) : undefined,
    purOrderInfo: json.purOrderInfo ? companyOrderInfoFromJson(json.purOrderInfo) : undefined,
    orderInfoList: json.orderInfoList ? json.orderInfoList.map((m) => companyOrderInfoFromJson(m)) : []
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'inboundDate' | 'salOrderInfo' | 'purOrderInfo' | 'orderInfoList'> {
    inboundDate: Date,
    salOrderInfo: CompanyOrderInfo,
    purOrderInfo: CompanyOrderInfo,
    orderInfoList: CompanyOrderInfo[],
}

interface JsonRequired {

}

interface JsonOptional {
    receiver: string,
    inboundNo: string,
    inboundDate: string,
    inboundTime: string,
    shipper: string,
    partsNo: string,
    uomCode: string,
    inboundQty: number,
    cntTruNo: string,
    cntTruType: string,
    sealNo: string,
    cntTruM3: number,
    cntTruNW: number,
    cntTruGW: number,
    palletNo: string,
    outerPkgNo: string,
    outerPkgType: string,
    outerPkgM3: number,
    outerPkgNW: number,
    outerPkgGW: number,
    innerPkgNo: string,
    innerPkgType: string,
    innerPkgM3: number,
    innerPkgNW: number,
    innerPkgGW: number,
    soNo: string,
    sellerCode: string,
    sellerPartsNo: string,
    sellerPartsName: string,
    sellerBackNo: string,
    colorCode: string,
    srbq: number,
    poNo: string,
    buyerCode: string,
    buyerPartsNo: string,
    buyerPartsName: string,
    buyerBackNo: string,
    spq: number,
    salOrderInfo: CompanyOrderInfoJson,
    purOrderInfo: CompanyOrderInfoJson,
    orderInfoList: CompanyOrderInfoJson[],
    receiverDcId: number,
    shipperDcId: number,
    remaindQty: number,
    uinkey: string,
    datasource: number,
}
