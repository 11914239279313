import { useCallback } from "react"
import { Projects } from "../../../utils/Projects"
import { useUpload } from "../../../utils/fetch/useFetch"
import { GlobalPartsResult, globalPartsResultFromJson } from "../models/GlobalPartsResult"
import { PartsDetailResult, partsDetailResultFromJson } from "../models/PartsDetailResult"
import { UnitPartsResult, unitPartsResultFromJson } from "../models/UnitPartsResult"
import { WorkingResult, workingResultFromJson } from "../models/WorkingResult"

export const useUploadMasterRegionMaster = () => {
    const url = '/lcbm-system-master-api/upload/regionMaster'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useUploadMasterUomMaster = () => {
    const url = '/lcbm-system-master-api/upload/uomMaster'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useUploadMasterContainerTypeMaster = () => {
    const url = '/lcbm-system-master-api/upload/containerTypeMaster'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useUploadMasterPackageTypeMaster = () => {
    const url = '/lcbm-system-master-api/upload/packageTypeMaster'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useUploadMasterCurrencyMaster = () => {
    const url = '/lcbm-system-master-api/upload/currencyMaster'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useUploadMasterIncotermsMaster = () => {
    const url = '/lcbm-system-master-api/upload/incotermsMaster'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useUploadMasterStateMaster = () => {
    const url = '/lcbm-system-master-api/upload/stateMaster'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useUploadMasterCompanyMaster = () => {
    const url = '/lcbm-system-master-api/upload/companyMaster'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useUploadMasterBusinessMaster = () => {
    const url = '/lcbm-system-master-api/upload/businessUnitMaster'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useUploadMasterBusinessSettingMaster = () => {
    const url = '/lcbm-system-master-api/upload/businessUnitSetting'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useUploadMasterCustomerMaster = () => {
    const url = '/lcbm-system-master-api/upload/customerMaster'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useUploadMasterCustomerSettingMaster = () => {
    const url = '/lcbm-system-master-api/upload/customerSetting'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useUploadMasterSupplierMaster = () => {
    const url = '/lcbm-system-master-api/upload/supplierMaster'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useUploadMasterSupplierSettingMaster = () => {
    const url = '/lcbm-system-master-api/upload/supplierSetting'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useUploadMasterDCMaster = () => {
    const url = '/lcbm-system-master-api/upload/dcMaster'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useUploadMasterDCSettingMaster = () => {
    const url = '/lcbm-system-master-api/upload/dcSetting'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

// port 
export const useUploadMasterPortMaster = () => {
    const url = '/lcbm-system-master-api/upload/portMaster'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useUploadMasterGlobalPartsMaster = () => {
    const url = '/lcbm-local-master-api/upload/globalPartsMaster'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = useCallback(Projects.array(globalPartsResultFromJson), [])
    return useUpload<{ file: File }, GlobalPartsResult[]>(url, payloadProject, resultProject)
}

export const useUploadMasterUnitPartsMaster = () => {
    const url = '/lcbm-local-master-api/upload/unitPartsMaster'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = useCallback(Projects.array(unitPartsResultFromJson), [])
    return useUpload<{ file: File }, UnitPartsResult[]>(url, payloadProject, resultProject)
}

export const useUploadMasterContractMaster = () => {
    const url = '/lcbm-local-master-api/upload/contractMaster'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useUploadMasterContractSplitMaster = () => {
    const url = '/lcbm-local-master-api/upload/contractSplitMaster'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useUploadMasterContractRouteMaster = () => {
    const url = '/lcbm-local-master-api/upload/contractRouteMaster'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useUploadMasterPaymentTermsMaster = () => {
    const url = '/lcbm-local-master-api/upload/paymenTermsMaster'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useUploadMasterShippingRouteMaster = () => {
    const url = '/lcbm-local-master-api/upload/shippingRouteMaster'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useUploadMasterShippingRouteDetail = () => {
    const url = '/lcbm-local-master-api/upload/shippingRouteDetail'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useUploadMasterContractPartsMaster = () => {
    const url = '/lcbm-local-master-api/upload/contractPartsMaster'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useUploadMasterContractPriceHistoryMaster = () => {
    const url = '/lcbm-local-master-api/upload/contractPriceHistory'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useUploadMasterworkingMaster = () => {
    const url = '/lcbm-local-master-api/upload/workingMaster'
    const payloadProject = useCallback((obj: { file: File, workingId: number, requestId?: number, finalSellerFlag?: number }) => obj, [])
    const resultProject = useCallback(workingResultFromJson, [])
    return useUpload<{ file: File, workingId: number, requestId?: number, finalSellerFlag?: number }, WorkingResult>(url, payloadProject, resultProject)
}
export const useUploadFOBSetting = () => {
    const url = '/lcbm-local-master-api/lcbm-local-master-api/upload/fobSetting'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useUploadCustomerParts = () => {
    const url = '/lcbm-local-master-api/upload/uploadCustomerParts'
    const payloadProject = useCallback((obj: { file: File }, requestId?: number) => obj, [])
    const resultProject = useCallback(Projects.array(partsDetailResultFromJson), [])
    return useUpload<{ file: File, requestId?: number }, PartsDetailResult[]>(url, payloadProject, resultProject)
}

export const useUploadRequestPartsModify = () => {
    const url = '/lcbm-local-master-api/upload/uploadRequestPartsModify'
    const payloadProject = useCallback((obj: { file: File, requestTo?: string }) => obj, [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const resultProject = useCallback(Projects.array(partsDetailResultFromJson), [])
    return useUpload<{ file: File, requestTo?: string }, PartsDetailResult[]>(url, payloadProject, resultProject)
}

export const useUploadRequestPartsPrice = () => {
    const url = '/lcbm-local-master-api/upload/uploadRequestPartsPrice'
    const payloadProject = useCallback((obj: { file: File, requestTo?: string }) => obj, [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const resultProject = useCallback(Projects.array(partsDetailResultFromJson), [])
    return useUpload<{ file: File, requestTo?: string }, PartsDetailResult[]>(url, payloadProject, resultProject)
}


export const useUploadMasterCreditRuleMaster = () => {
    const url = '/lcbm-credit-limit-api/upload/creditRuleMaster'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}


export const useUploadMasterCreditSettingMaster = () => {
    const url = '/lcbm-credit-limit-api/upload/creditSettingMaster'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}