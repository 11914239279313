import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { Projects } from "../../../utils/Projects"
import { CreditCheckInfoFactor } from "../../master/models/CreditCheckInfoFactor"
import { CreditLimitResult, creditLimitResultFromJson } from "../../order/models/CreditLimitResult"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { CreditCheckInfoResult, creditCheckInfoResultFromJson, creditCheckInfoResultToJson } from "../models/CreditCheckInfoResult"
import { CreditSettingInfoFactor } from "../models/CreditSettingInfoFactor"
import { CreditSettingInfoResult, creditSettingInfoResultFromJson } from "../models/CreditSettingInfoResult"
import { TnmCreditSetting, tnmCreditSettingFromJson, tnmCreditSettingToJson } from "../models/TnmCreditSetting"

export const useGetCreditRuleList = () => {
    const url = '/lcbm-credit-limit-api/api/listCreditRule'
    const payloadProject = useCallback(creditCheckInfoResultToJson, [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const resultProject = useCallback(pageResultEntityFromJson(creditCheckInfoResultFromJson), [])
    return usePost<CreditCheckInfoFactor, PageResultEntity<CreditCheckInfoResult>>(url, payloadProject, resultProject)
}

export const useSaveCreditRuled = () => {
    const url = '/lcbm-credit-limit-api/api/saveCreditRule'
    const payloadProject = useCallback(creditCheckInfoResultToJson, [])
    const resultProject = useCallback(creditCheckInfoResultFromJson, [])
    return usePost<CreditCheckInfoResult, CreditCheckInfoResult>(url, payloadProject, resultProject)
}

export const useEditCreditRule = () => {
    const url = '/lcbm-credit-limit-api/api/editCreditRule'
    const payloadProject = useCallback(creditCheckInfoResultToJson, [])
    const resultProject = useCallback(creditCheckInfoResultFromJson, [])
    return usePost<CreditCheckInfoResult, CreditCheckInfoResult>(url, payloadProject, resultProject)
}

export const useWithdrawCreditRule = () => {
    const url = '/lcbm-credit-limit-api/api/withdrawCreditRule'
    const payloadProject = useCallback(obj => obj.creditId, [])
    const resultProject = Projects.IGNORE
    return usePost<{ creditId: number }, undefined>(url, payloadProject, resultProject)
}

export const useGetCreditSettingList = () => {
    const url = '/lcbm-credit-limit-api/api/listCreditSetting'
    const payloadProject = useCallback(creditCheckInfoResultToJson, [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const resultProject = useCallback(pageResultEntityFromJson(creditSettingInfoResultFromJson), [])
    return usePost<CreditSettingInfoFactor, PageResultEntity<CreditSettingInfoResult>>(url, payloadProject, resultProject)
}


export const useGetCreditSettingDetail = () => {
    const url = '/lcbm-credit-limit-api/api/loadCreditSettingDetail'
    const payloadProject = useCallback((obj: { settingId: number }) => obj.settingId, [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const resultProject = useCallback(tnmCreditSettingFromJson, [])
    return usePost<{ settingId: number }, TnmCreditSetting>(url, payloadProject, resultProject)
}

export const useSaveCreditSetting = () => {
    const url = '/lcbm-credit-limit-api/api/saveCreditSetting'
    const payloadProject = useCallback(tnmCreditSettingToJson, [])
    const resultProject = Projects.IGNORE
    return usePost<TnmCreditSetting, undefined>(url, payloadProject, resultProject)
}

export const useWithdrawCreditSetting = () => {
    const url = '/lcbm-credit-limit-api/api/withdrawCreditSetting'
    const payloadProject = useCallback(obj => obj.settingId, [])
    const resultProject = Projects.IGNORE
    return usePost<{ settingId: number }, undefined>(url, payloadProject, resultProject)
}

export const useFroceCredit = () => {
    const url = '/roe-api/order/so/api/doForce'
    const payloadProject = useCallback((obj: { soId: number, creditCheckFlag: number }) => obj, [])
    const resultProject = useCallback(creditLimitResultFromJson, [])
    return usePost<{ soId: number, creditCheckFlag: number }, CreditLimitResult>(url, payloadProject, resultProject)
}

export const useRefreshCredit = () => {
    const url = '/roe-api/order/so/api/doFresh'
    const payloadProject = useCallback((obj: { soId: number, creditCheckFlag: number }) => obj, [])
    const resultProject = useCallback(creditLimitResultFromJson, [])
    return usePost<{ soId: number, creditCheckFlag: number }, CreditLimitResult>(url, payloadProject, resultProject)
}