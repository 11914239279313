import React, { useEffect, useState } from "react"
import { useGetIncomingOrProcessInbound } from "../../../services/delivery/apis/deliveryInboundApi"
import { InboundInfo } from "../../../services/delivery/models/InboundInfo"
import { LIS014PcUi } from "./LIS014PcUi"

export const LIS014 = () => {
    const [data, setData] = useState<InboundInfo[]>([])

    const getData = useGetIncomingOrProcessInbound()
    useEffect(() => {
        getData(undefined, { silent: true, serialized: true }).then(result => {
            setData(result || [])
        })
    }, [getData])

    return <LIS014PcUi inboundList={data} />
}
