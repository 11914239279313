import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useGetContractRouteDetailHeaderByContractRouteId, useGetContractRouteDetailPartsByContractRouteId } from "../../../services/master/apis/contractRouteApi"
import { ContractRouteDetailPartsResult } from "../../../services/master/models/ContractRouteDetailPartsResult"
import { ContractRouteResult } from "../../../services/master/models/ContractRouteResult"
import { MLS101PcUi } from "./MLS101PcUi"

export const MLS101 = () => {

    const { contractRouteId } = useParams() as any
    const [partsDataList, setPartsData] = useState<ContractRouteDetailPartsResult[]>([])
    const [headerData, setHeaderData] = useState<ContractRouteResult>({} as ContractRouteResult)

    const getHeaderData = useGetContractRouteDetailHeaderByContractRouteId()
    useEffect(() => {
        getHeaderData({ contractRouteId: contractRouteId }, { silent: true }).then(result => {
            setHeaderData(result || {} as ContractRouteResult)
        })
    }, [contractRouteId, getHeaderData, setHeaderData])

    const getPartsDataList = useGetContractRouteDetailPartsByContractRouteId()
    useEffect(() => {
        getPartsDataList({ contractRouteId: contractRouteId }, { silent: true }).then(result => {
            setPartsData(result || [])
        })
    }, [contractRouteId, getPartsDataList, setPartsData])

    return <MLS101PcUi
        partsDataList={partsDataList}
        headerData={headerData}
    />
}