import React, { useCallback, useMemo, useState } from "react"
import { useGetPoListByPage } from "../../../services/order/apis/PurchaseOrderApi"
import { OrderListResult } from "../../../services/order/models/OrderListResult"
import { PurchaseOrderListFactor, purchaseOrderListFactorFromJson, purchaseOrderListFactorToJson } from "../../../services/order/models/PurchaseOrderListFactor"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { OPS010PcUi } from "./OPS010PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }
const searchCachekey = sessionKeys.Filters_OPS010

export const OPS010 = () => {
    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? purchaseOrderListFactorFromJson(JSON.parse(filters)) : null
    }, [])

    const [filters, setFilters] = useState<PurchaseOrderListFactor>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<OrderListResult[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)

    const getData = useGetPoListByPage()
    const search = useCallback((filters: PurchaseOrderListFactor) => {
        getData(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(purchaseOrderListFactorToJson(filters)))
        })
    }, [getData, setData])

    useSearchOnLoad(search, filters)


    return <OPS010PcUi
        filters={filters}
        setFilters={setFilters}
        search={search}
        data={data}
        totalCount={totalCount}
    />
}