import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { dateFromJson } from "../../utils/deserializer"

export type CargoTackingDetailResult = Required & Partial<Optional>
type CargoTackingDetailResultJson = JsonRequired & Partial<JsonOptional>

export const cargoTackingDetailResultFromJson: Project<CargoTackingDetailResultJson, CargoTackingDetailResult> = json => ({
    ...json,
    trackings: json?.trackings?.map(cargoTackingFromJson)
})

interface Required extends JsonRequired {
}
interface Optional extends Omit<JsonOptional, 'trackings'> {
    trackings: CargoTacking[]
}

interface JsonRequired {
}
interface JsonOptional {
    total: number,
    trackings: CargoTackingJson[]
}


export interface CargoTacking extends Omit<CargoTackingJson, 'realtime' | 'manual'> {
    realtime?: RealtimeEvents,
    manual?: ManualEvents
}

interface CargoTackingJson {
    containerNo?: string,
    bookingNo?: string,
    containerType?: string,
    realtime?: RealtimeEventsJson,
    manual?: ManualEventsJson
}

export const cargoTackingFromJson: Project<CargoTackingJson, CargoTacking> = json => ({
    ...json,
    realtime: Projects.ignoreIfNull(realtimeEventsFromJson)(json.realtime),
    manual: Projects.ignoreIfNull(manualEventsFromJson)(json.manual)
})


export interface RealtimeEvents {
    etd: Date,
    planEta: Date,
    predictiveEta: Date,
    externalBookmarkId: string,
    events: Events[]
}

interface RealtimeEventsJson {
    etd: string,
    planEta: string,
    predictiveEta: string,
    externalBookmarkId: string,
    events: EventsJson[]
}

const realtimeEventsFromJson: Project<RealtimeEventsJson, RealtimeEvents> = json => ({
    ...json,
    etd: dateFromJson(json.etd),
    planEta: dateFromJson(json.planEta),
    predictiveEta: dateFromJson(json.predictiveEta),
    events: json.events.map(EventsFromJson),
})

export interface ManualEvents {
    etd: Date,
    planEta: Date,
    predictiveEta: Date,
    externalBookmarkId: string,
    events: Events[]
}

interface ManualEventsJson {
    etd: string,
    planEta: string,
    predictiveEta: string,
    externalBookmarkId: string,
    events: EventsJson[]
}

const manualEventsFromJson: Project<ManualEventsJson, ManualEvents> = json => ({
    ...json,
    etd: dateFromJson(json.etd),
    planEta: dateFromJson(json.planEta),
    predictiveEta: dateFromJson(json.predictiveEta),
    events: json.events.map(EventsFromJson),
})

interface EventsJson {
    milestone: string,
    location: string,
    planedDate: string,
    predictedDate: string,
    actualDate: string,
    carrierForwarder: string,
    vessel: string[],
    status: string
}

export interface Events {
    milestone: string,
    location: string,
    planedDate: Date,
    predictedDate: Date,
    actualDate: Date,
    carrierForwarder: string,
    vessel: string[],
    status: string
}

const EventsFromJson: Project<EventsJson, Events> = json => ({
    ...json,
    planedDate: dateFromJson(json.planedDate),
    predictedDate: dateFromJson(json.predictedDate),
    actualDate: dateFromJson(json.actualDate),
})
