import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useComMasterGetActivePort, useComMasterGetAllRegion } from "../../../services/common/apis/commonMasterApi"
import { useGetShippingRouteList } from "../../../services/master/apis/shippingRouteApi"
import { ShippingRouteFactor, shippingRouteFactorFromJson, shippingRouteFactorToJson } from "../../../services/master/models/ShippingRouteFactor"
import { ShippingRouteResult } from "../../../services/master/models/ShippingRouteResult"
import { TnmPort } from "../../../services/master/models/TnmPort"
import { TnmRegion } from "../../../services/master/models/TnmRegion"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { MLS120PcUi } from "./MLS120PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }
const searchCachekey = sessionKeys.Filters_MLS120
export const MLS120 = () => {

    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? shippingRouteFactorFromJson(JSON.parse(filters)) : null
    }, [])
    const [filters, setFilters] = useState<ShippingRouteFactor>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<ShippingRouteResult[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [regionList, setRegion] = useState<TnmRegion[]>([])
    const [portList, setPort] = useState<TnmPort[]>([])

    const getDataList = useGetShippingRouteList()
    const search = useCallback((filters: ShippingRouteFactor) => {
        getDataList(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(shippingRouteFactorToJson(filters)))
        })
    }, [getDataList, setData])

    useSearchOnLoad(search, filters)

    const getRegionList = useComMasterGetAllRegion()
    useEffect(() => {
        getRegionList(undefined, { silent: true }).then(result => {
            setRegion(result || [])
        })
    }, [getRegionList])

    const getPortList = useComMasterGetActivePort()
    useEffect(() => {
        getPortList(undefined, { silent: true }).then(result => {
            setPort(result || [])
        })
    }, [getPortList])


    return <MLS120PcUi
        filters={filters}
        search={search}
        data={data}
        totalCount={totalCount}
        regionList={regionList}
        portList={portList}
        setFilters={setFilters}
    />
}