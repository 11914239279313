import { combineReducers } from 'redux'
import { mls260Slice } from '../layouts/master/MLS260/MLS260Slice'
import { mls261Slice } from '../layouts/master/MLS261/MLS261Slice'
import { msesf021Slice } from "../layouts/master/MSESF021/MSESF021Slice"
import { ons010Slice } from '../layouts/order/ONS010/ONS010Slice'
import { cas010Slice } from "../layouts/privilege/CAS010/CAS010Slice"
import { cas011Slice } from '../layouts/privilege/CAS011/CAS011Slice'
import { cas020Slice } from '../layouts/privilege/CAS020/CAS020Slice'
import { cas021Slice } from '../layouts/privilege/CAS021/CAS021Slice'
import { occls010Slice } from '../layouts/smt/OCCLS010/OCCLS010Slice'
import { occls013Slice } from "../layouts/smt/OCCLS013/OCCLS013Slice"
import { homeSlice } from '../layouts/Home/HomeSlice'

const appReducer = combineReducers({
    [ons010Slice.name]: ons010Slice.reducer,
    [occls010Slice.name]: occls010Slice.reducer,
    [occls013Slice.name]: occls013Slice.reducer,
    [msesf021Slice.name]: msesf021Slice.reducer,
    [mls260Slice.name]: mls260Slice.reducer,
    [mls261Slice.name]: mls261Slice.reducer,
    [cas010Slice.name]: cas010Slice.reducer,
    [cas011Slice.name]: cas011Slice.reducer,
    [cas020Slice.name]: cas020Slice.reducer,
    [cas021Slice.name]: cas021Slice.reducer,
    [homeSlice.name]: homeSlice.reducer,
})

export default appReducer