import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { applicationActions, ApplicationState } from "../Application/applicationSlice"

export interface NotFoundProps {
    scope: keyof ApplicationState['ui']['notFound']
}

export const NotFound = ({ scope }: NotFoundProps) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(applicationActions.notFound({ scope, notFound: true }))
        return () => {
            dispatch(applicationActions.notFound({ scope, notFound: false }))
        }
    }, [dispatch, scope])
    return null
}