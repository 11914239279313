import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Step, StepLabel, Stepper, Tooltip } from "@material-ui/core"
import { CancelOutlined, SaveOutlined } from "@material-ui/icons"
import { Column, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Editing, Filtering, NumberTypeProvider, PaginationLayout, Row, Searching, Sorting, StringTypeProvider, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarLayout } from "@rithe/data-grid"
import { Break, DateItem, EntryItem, Form, Message, NumberItem, StringItem } from "@rithe/form"
import { Arrays, Records } from "@rithe/utils"
import moment from "moment"
import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Access } from "../../../components/Access/Access"
import { IconButton } from "../../../components/Button/IconButton"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/CallbackToolbarAction"
import { CreateCallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/CreateCallbackToolbarAction"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { UploadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/UploadGroupedToolbarAction"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { LibertyTypeProvider } from "../../../components/DataGrid/typeProviders/LibertyTypeProvider"
import { PercentTypeProvider } from "../../../components/DataGrid/typeProviders/PercentTypeProvider"
import { DarkDialog } from "../../../components/Dialog/DarkDialog"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { CodeItem } from "../../../components/Form/CodeItem"
import { StepperAction } from "../../../components/View/Step/StepperAction"
import { View } from "../../../components/View/View"
import { useFunctionStore } from "../../../Root"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { ContractRequestType } from "../../../services/master/enums/ContaractRequestType"
import { PlaceSpotDetailStatus } from "../../../services/master/enums/PlaceSpotDetailStatus"
import { PlaceSpotStatus } from "../../../services/master/enums/PlaceSpotStatus"
import { GlobalPartsResult } from "../../../services/master/models/GlobalPartsResult"
import { TnmUom } from "../../../services/master/models/TnmUom"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { useDownloadSpotOrderWithContractForCustomer, useDownloadSpotOrderWithContractStep1ForCustomer } from "../../../services/order/apis/OrderDownloadApi"
import { useUploadSpotOrderWithContractFormForCustomer, useUploadSpotOrderWithContractStep1FormForCustomer } from "../../../services/order/apis/OrderUploadApi"
import { useSaveSpotOrder, useSaveSpotOrderNext, useSaveSpotOrderSubmit } from "../../../services/order/apis/SpotOrderApi"
import { AvailableSellerResult } from "../../../services/order/models/AvailableSellerResult"
import { PartsDetail } from "../../../services/order/models/SpotOrderDetailResult"
import { useGetCompanyType } from "../../../utils/ApplicationUtils"
import { useFieldChecker } from "../../../utils/ValidatorUtils"
import { applicationActions } from "../../Application/applicationSlice"
import { SpotOrderResultBasic, useMergeDatas, useSplitDatas } from "./OCS021"
import { OCS021GlobalParts } from "./OCS021GlobalParts"
import { useShippingPlanCategoryForSpot } from "./ShippingPlanCategory"

interface OCS021PcUiProps {
    mode: ScreenMode,
    search: (orderId: number | null | undefined) => void,
    receiveDcList: TnvCbds[],
    basic: SpotOrderResultBasic,
    setBasic: React.Dispatch<React.SetStateAction<SpotOrderResultBasic>>,
    forecasts: PartsDetail[],
    setForecasts: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
    inboundPlans: PartsDetail[],
    setInboundPlans: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
    searchAva: (globalPartsIdList: number[] | undefined, placeType: number | null | undefined, spotId: number | null | undefined) => void,
    availableSellers: AvailableSellerResult[]
    setAvailableSellers: React.Dispatch<React.SetStateAction<AvailableSellerResult[]>>,
    uomList: TnmUom[],
    viewNum: number,
    setViewNum: React.Dispatch<React.SetStateAction<number>>
}

export const OCS021PcUi = (props: OCS021PcUiProps) => {
    const { mode, search, receiveDcList, basic, setBasic, forecasts, setForecasts, inboundPlans, setInboundPlans, availableSellers, searchAva, uomList, viewNum, setViewNum } = props
    const uomArr = useMemo(() => Arrays.distinct(uomList.map((m) => m.uomCode)), [uomList])

    return <View
        actions={
            <StepperAction
                actions={[
                    <StepAction
                        setInboundPlans={setInboundPlans}
                        setBasic={setBasic}
                        setForecasts={setForecasts}
                        search={search}
                        mode={mode}
                        viewNum={viewNum}
                        setViewNum={setViewNum}
                        basic={basic}
                        forecasts={forecasts}
                        inboundPlans={inboundPlans} />
                ]} />
        }>
        {viewNum <= 1 && <PartsGridCard setInboundPlans={setInboundPlans} setViewNum={setViewNum} uomArr={uomArr} search={search} searchAva={searchAva} availableSellers={availableSellers} inboundPlans={inboundPlans} setBasic={setBasic} mode={mode} basic={basic} forecasts={forecasts} setForecasts={setForecasts} />}
        {viewNum > 1 && <OrderFrom setInboundPlans={setInboundPlans} setViewNum={setViewNum} uomArr={uomArr} inboundPlans={inboundPlans} forecasts={forecasts} setForecasts={setForecasts} mode={mode} search={search} basic={basic} setBasic={setBasic} receiveDcList={receiveDcList} />}
    </View>
}

const StepAction = ({ mode, viewNum, setViewNum, basic, forecasts, inboundPlans, search, setBasic, setForecasts, setInboundPlans, }: {
    mode: ScreenMode,
    viewNum: number,
    setViewNum: React.Dispatch<React.SetStateAction<number>>,
    basic: SpotOrderResultBasic,
    forecasts: PartsDetail[],
    inboundPlans: PartsDetail[],
    search: (orderId: number | null | undefined) => void,
    setBasic: React.Dispatch<React.SetStateAction<SpotOrderResultBasic>>,
    setInboundPlans: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
    setForecasts: React.Dispatch<React.SetStateAction<PartsDetail[]>>,

}) => {
    const stepStyles = useStepStyles()
    const stepNo = useMemo(() => {
        return basic.stepNo ?? 0
    }, [basic.stepNo])
    return <>
        <Stepper activeStep={viewNum} className={stepStyles.root}>
            {[1, 2].map((label, index) => {
                const stepProps: { completed?: boolean } = { completed: (index + 1 > stepNo ? false : true) }
                const labelProps: { optional?: React.ReactNode } = {}
                return (
                    <Step key={label} {...stepProps}><StepLabel {...labelProps}>{label}</StepLabel></Step>
                )
            })}
            <BackAction viewNum={viewNum} setViewNum={setViewNum} />
            <NextAction setBasic={setBasic} setInboundPlans={setInboundPlans} setForecasts={setForecasts} search={search} mode={mode} viewNum={viewNum} setViewNum={setViewNum} forecasts={forecasts} basic={basic} inboundPlans={inboundPlans} />
        </Stepper>
    </>
}

const BackAction = ({ viewNum, setViewNum }: {
    viewNum: number,
    setViewNum: React.Dispatch<React.SetStateAction<number>>
}) => {
    const backClick = useCallback(() => {
        setViewNum(viewNum - 2)
    }, [setViewNum, viewNum])
    return <>
        <Button onClick={backClick} disabled={viewNum === 0} variant="contained" style={{ backgroundColor: '#fff' }}><FormattedMessage id="back" /></Button >
    </>
}

const NextAction = ({ mode, viewNum, setViewNum, forecasts, basic, inboundPlans, search, setBasic, setInboundPlans, setForecasts, }: {
    mode: ScreenMode,
    viewNum: number,
    forecasts: PartsDetail[],
    basic: SpotOrderResultBasic,
    inboundPlans: PartsDetail[],
    search: (orderId: number | null | undefined) => void,
    setBasic: React.Dispatch<React.SetStateAction<SpotOrderResultBasic>>,
    setInboundPlans: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
    setViewNum: React.Dispatch<React.SetStateAction<number>>,
    setForecasts: React.Dispatch<React.SetStateAction<PartsDetail[]>>
}) => {
    const intl = useIntl()
    const navigate = useNavigate()
    const mergeDatas = useMergeDatas()
    const splitDatas = useSplitDatas()
    const dispatch = useDispatch()
    const saveInfo = useSaveSpotOrderNext()
    const confirmInfo = useSaveSpotOrderSubmit()
    const data = mergeDatas(basic, forecasts, inboundPlans)
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'confirm' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const nextClick = useCallback(() => {
        if (mode === ScreenMode.VIEW) {
            setViewNum(viewNum + 2)
        } else {
            if (!basic.status) {
                dispatch(applicationActions.pushError({ title: { code: 'Click Next Error' }, messages: { code: 'w0400' } }))
                return
            }
            // do check 
            if (forecasts?.length > 0) {
                let empty = forecasts.filter(f => !f.globalPartsNo)
                if (empty.length > 0) {
                    // throw error
                    dispatch(applicationActions.pushError({ title: { code: 'partsNumberAndAvailableSellers' }, messages: { code: 'w0345' } }))
                } else {
                    setDisabled(true)
                    saveInfo(data, { serialized: true }).then(result => {
                        if (result) {
                            const { orderbasic, orderforecasts, orderInboundPlans } = splitDatas(result)
                            setBasic(orderbasic)
                            setForecasts(orderforecasts)
                            setInboundPlans(orderInboundPlans)
                            setViewNum(orderbasic.stepNo)
                        }
                    }).finally(() => {
                        setDisabled(false)
                    })
                }
            } else {
                // throw error
                dispatch(applicationActions.pushError({ title: { code: 'partsNumberAndAvailableSellers' }, messages: { code: 'w0345' } }))
            }
        }
    }, [basic.status, data, dispatch, forecasts, mode, saveInfo, setBasic, setForecasts, setInboundPlans, setViewNum, splitDatas, viewNum])

    const confirmClick = useCallback(() => {
        if (!basic.status) {
            dispatch(applicationActions.pushError({ title: { code: 'Click Confirm Error' }, messages: { code: 'w0400' } }))
            return
        }

        let flag = true
        if (forecasts && forecasts.length > 0) {
            forecasts.filter(f => f.orderLot || f.orderQty || f.spq).forEach(val => {
                if (!checkNumber(val.orderQty, 1)) {
                    flag = false
                    dispatch(applicationActions.pushError({ title: { code: 'orderQty' }, messages: { code: 'integerCheck' } }))
                    return
                }
                if (!checkNumber(val.orderLot, 1)) {
                    flag = false
                    dispatch(applicationActions.pushError({ title: { code: 'orderLot' }, messages: { code: 'integerCheck' } }))
                    return
                }
                if (!checkNumber(val.spq, 1)) {
                    flag = false
                    dispatch(applicationActions.pushError({ title: { code: 'spq' }, messages: { code: 'integerCheck' } }))
                    return
                }
            })
        }

        if (!checkLength(basic.remark ?? '', 255)) {
            flag = false
            dispatch(applicationActions.pushError({ title: { code: 'remark' }, messages: { code: 'w0020', args: [intl.formatMessage({ id: 'remark' }), 255, 1] } }))
        }

        if (!checkLength(basic.orderReference ?? '', 255)) {
            flag = false
            dispatch(applicationActions.pushError({ title: { code: 'orderReference' }, messages: { code: 'w0020', args: [intl.formatMessage({ id: 'orderReference' }), 255, 1] } }))
        }
        if (!flag) return

        const functionId = functionStore.register(() => {
            setDisabled(true)
            confirmInfo(data, { serialized: true }).then(result => {
                if (result) {
                    setViewNum(result.stepNo)
                    navigate(`/placecustorder-sowc`)
                }
            }).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [basic.orderReference, basic.remark, basic.status, confirmInfo, data, dispatch, forecasts, functionStore, intl, navigate, setViewNum, title])

    return <Access access="ORDER.OCS021.NEXT">
        {viewNum < 2 && <Button onClick={nextClick} variant="contained" style={{ backgroundColor: '#003362', color: '#fff' }} disabled={disabled}><FormattedMessage id="next" /></Button >}
        {viewNum === 2 && mode !== ScreenMode.VIEW && <Button onClick={confirmClick} variant="contained" style={{ backgroundColor: '#003362', color: '#fff' }} disabled={disabled}><FormattedMessage id="confirm" /></Button >}
    </Access>
}

const PartsGridCard = ({ mode, basic, forecasts, setForecasts, setBasic, inboundPlans, availableSellers, searchAva, search, uomArr, setInboundPlans, setViewNum, }: {
    mode: ScreenMode,
    basic: SpotOrderResultBasic,
    setBasic: React.Dispatch<React.SetStateAction<SpotOrderResultBasic>>,
    forecasts: PartsDetail[],
    inboundPlans: PartsDetail[],
    setForecasts: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
    availableSellers: AvailableSellerResult[],
    searchAva: (globalPartsIdList: number[] | undefined, placeType: number | null | undefined, spotId: number | null | undefined) => void,
    search: (orderId: number | null | undefined) => void,
    uomArr: string[],
    setInboundPlans: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
    setViewNum: React.Dispatch<React.SetStateAction<number>>
}) => {
    const intl = useIntl()
    const [messages, setMessages] = useState<Message[]>([] as Message[])
    const companyType = useGetCompanyType()
    const showAddWithEdit = ((((basic.placeType === null || basic.placeType === undefined) && companyType === CbdsType.CUST) || basic.placeType === 10) && basic.status === 10 && mode !== ScreenMode.VIEW) || (mode === ScreenMode.CREATE) ? true : false
    const editable = useMemo(() => mode !== ScreenMode.VIEW && basic && basic.status ? basic.status < PlaceSpotStatus.Confirmed : true, [mode, basic])
    return <>
        < SectionCard allowCollapse id="OCS021_PartsGridCard_Section_1">
            <SectionCardHeader
                serialNumber={1}
                title={intl.formatMessage({ id: 'add_new_parts_title_two' })}
                subtitle={intl.formatMessage({ id: 'add_new_parts_sub_title_one' })}
                actions={showAddWithEdit ? [<SelectPartsAction basic={basic} searchAva={searchAva} setBasic={setBasic} forecasts={forecasts} setForecasts={setForecasts} mode={mode} />] : []}
            />
            <SectionCardContent>
            </SectionCardContent>
        </SectionCard >

        < SectionCard allowCollapse defaultCollapse id="OCS021_PartsGridCard_Section_2">
            <SectionCardHeader
                serialNumber={2}
                title={intl.formatMessage({ id: 'uploadPartsTitle' })}
                subtitle={intl.formatMessage({ id: 'uploadPartsSubTitle' })}
                actions={showAddWithEdit ? [
                    <DownloadAction search={search} stepNo={1} setInboundPlans={setInboundPlans} setViewNum={setViewNum} mode={mode} uomArr={uomArr} editable={editable}
                        forecasts={forecasts} setForecasts={setForecasts} basic={basic} setBasic={setBasic} inboundPlans={inboundPlans} />,
                    <UploadAction search={search} stepNo={1} setInboundPlans={setInboundPlans} setViewNum={setViewNum} mode={mode} uomArr={uomArr}
                        editable={editable} forecasts={forecasts} setForecasts={setForecasts} basic={basic} setBasic={setBasic} inboundPlans={inboundPlans} />
                ] : []}
            />
            <SectionCardContent>
            </SectionCardContent>
        </SectionCard >

        <SectionCard allowCollapse defaultCollapse id="OCS021_PartsGridCard_Section_3">
            <SectionCardHeader
                serialNumber={3}
                title={intl.formatMessage({ id: 'inputPartsInfoTitle' })}
                subtitle={intl.formatMessage({ id: 'inputPartsInfoSubTitle' })}
                actions={mode !== ScreenMode.VIEW ? [<TempSave mode={mode} search={search} basic={basic} forecasts={forecasts} setMessages={setMessages} inboundPlans={inboundPlans} viewIndex={1} />] : []}
            />
            <SectionCardContent>
                <PartsList mode={mode} availableSellers={availableSellers} forecasts={forecasts} setForecasts={setForecasts} basic={basic} setBasic={setBasic} />
            </SectionCardContent>
        </SectionCard>
    </>
}

const OrderFrom = ({ mode, search, basic, setBasic, forecasts, setForecasts, receiveDcList, inboundPlans, uomArr, setInboundPlans, setViewNum, }: {
    mode: ScreenMode,
    search: (orderId: number | null | undefined) => void,
    basic: SpotOrderResultBasic,
    setBasic: React.Dispatch<React.SetStateAction<SpotOrderResultBasic>>,
    forecasts: PartsDetail[],
    setForecasts: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
    receiveDcList: TnvCbds[],
    inboundPlans: PartsDetail[],
    uomArr: string[],
    setInboundPlans: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
    setViewNum: React.Dispatch<React.SetStateAction<number>>
}) => {
    const intl = useIntl()
    // const actions = usePrepareActions(basic, forecasts, planList)
    const editable = useMemo(() => mode !== ScreenMode.VIEW && basic && basic.status ? basic.status < PlaceSpotStatus.Confirmed : true, [mode, basic])

    const [messages, setMessages] = useState<Message[]>([])
    return <>
        < SectionCard allowCollapse id="OCS021_OrderFrom_Section_1"> 
            <SectionCardHeader
                serialNumber={1}
                title={intl.formatMessage({ id: 'uploadPartsTitle' })}
                subtitle={intl.formatMessage({ id: 'uploadPartsSubTitleTwo' })}
                actions={mode !== ScreenMode.VIEW ? [<DownloadAction search={search} stepNo={2} setInboundPlans={setInboundPlans} setViewNum={setViewNum} mode={mode} uomArr={uomArr} editable={editable} forecasts={forecasts} setForecasts={setForecasts} basic={basic} setBasic={setBasic} inboundPlans={inboundPlans} />,
                <UploadAction search={search} stepNo={2} setInboundPlans={setInboundPlans} setViewNum={setViewNum} mode={mode} uomArr={uomArr} editable={editable} forecasts={forecasts} setForecasts={setForecasts} basic={basic} setBasic={setBasic} inboundPlans={inboundPlans} />] : []}
            />
            <SectionCardContent>
            </SectionCardContent>
        </SectionCard >

        <SectionCard allowCollapse defaultCollapse id="OCS021_OrderFrom_Section_2">
            <SectionCardHeader
                serialNumber={2}
                title={intl.formatMessage({ id: 'inputOrderQTYForEachPNTitle' })}
                subtitle={intl.formatMessage({ id: 'inputOrderQTYForEachPNTitleSub' })}
                actions={mode !== ScreenMode.VIEW ? [<TempSave mode={mode} search={search} basic={basic} forecasts={forecasts} setMessages={setMessages} inboundPlans={inboundPlans} viewIndex={2} />] : []}
            />
            <SectionCardContent>
                <Step2FirmAndFcTable search={search} stepNo={2} setInboundPlans={setInboundPlans} setViewNum={setViewNum} mode={mode} uomArr={uomArr} editable={editable} forecasts={forecasts} setForecasts={setForecasts} basic={basic} setBasic={setBasic} inboundPlans={inboundPlans} />

            </SectionCardContent>
        </SectionCard>

        <SectionCard allowCollapse defaultCollapse id="OCS021_OrderFrom_Section_3">
            <SectionCardHeader
                serialNumber={3}
                title={intl.formatMessage({ id: 'inputInboundPlanTitle' })}
                subtitle={intl.formatMessage({ id: 'inputShippingPlanTitleSub' })}
            />
            <SectionCardContent>
                <Step3FirmAndFcTable search={search} stepNo={2} setInboundPlans={setInboundPlans} setViewNum={setViewNum} mode={mode} uomArr={uomArr} editable={editable} forecasts={forecasts} setForecasts={setForecasts} basic={basic} setBasic={setBasic} inboundPlans={inboundPlans} />
            </SectionCardContent>
        </SectionCard>

        <SectionCard allowCollapse defaultCollapse id="OCS021_OrderFrom_Section_4">
            <SectionCardHeader
                serialNumber={4}
                title={intl.formatMessage({ id: 'orderSummaryBasicInfoTitle' })}
                subtitle={intl.formatMessage({ id: 'orderSummaryBasicInfoTitleSub' })}
            />
            <SectionCardContent>
                <OrderSummaryForm mode={mode} basic={basic} setBasic={setBasic} messages={messages} receiveDcList={receiveDcList} />
            </SectionCardContent>
        </SectionCard>
    </>
}

const OrderSummaryForm = ({ basic, setBasic, messages, receiveDcList, mode }: {
    basic: SpotOrderResultBasic,
    setBasic: React.Dispatch<React.SetStateAction<SpotOrderResultBasic>>,
    messages: Message[],
    receiveDcList: TnvCbds[],
    mode: ScreenMode,
}) => {
    const intl = useIntl()
    const fields = getFormCheckFields(true)
    const filedCheck = useFieldChecker(fields)
    const readonly = (basic.status >= PlaceSpotStatus.PartialConfirmed || mode === ScreenMode.VIEW) ? true : false

    const dcMap: [number, string][] = useMemo(() => receiveDcList.filter((m) => m.cbdsType === CbdsType.DC).map(m => [m.cbdsId, m.cbdsCode]), [receiveDcList])

    return <Form data={basic} setData={setBasic} labelDisplay="block" helperDisplay="tooltip" ensure={filedCheck} messages={messages} columnCount={3}>
        <StringItem field='orderReference' readonly={readonly} label={intl.formatMessage({ id: 'orderReference' })} />
        <EntryItem field="receiveDcId" readonly={readonly} label={intl.formatMessage({ id: 'receiveDcCode' })} entries={dcMap} />
        <Break />
        <NumberItem field="totalAmount" readonly label={intl.formatMessage({ id: 'totalAmount' })} />
        <NumberItem field="totalNumberOfParts" readonly label={intl.formatMessage({ id: 'totalNumberofParts' })} />
        <NumberItem field="totalQty" readonly label={intl.formatMessage({ id: 'totalQty' })} />
        <Break />
        <StringItem field="remark" colSpan={2} readonly={readonly} label={intl.formatMessage({ id: 'field.remark' })} />
    </Form>
}

const TempSave = ({ basic, forecasts, setMessages, inboundPlans, search, mode, viewIndex }: {
    basic: SpotOrderResultBasic,
    forecasts: PartsDetail[],
    inboundPlans: PartsDetail[],
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
    search: (orderId: number | null | undefined) => void,
    mode: ScreenMode,
    viewIndex: number
}) => {
    const navigate = useNavigate()
    const saveInfo = useSaveSpotOrder()
    const mergeDatas = useMergeDatas()
    const dispatch = useDispatch()
    const intl = useIntl()
    const [disabled, setDisabled] = useState<boolean>(false)
    const onclickToSave = useCallback(() => {
        let flag = true
        if (forecasts && forecasts.length > 0) {
            forecasts.filter(f => f.orderLot || f.orderQty || f.spq).forEach(val => {
                if (!checkNumber(val.orderQty, 1)) {
                    flag = false
                    dispatch(applicationActions.pushError({ title: { code: 'orderQty' }, messages: { code: 'integerCheck' } }))
                    return
                }
                if (!checkNumber(val.orderLot, 1)) {
                    flag = false
                    dispatch(applicationActions.pushError({ title: { code: 'orderLot' }, messages: { code: 'integerCheck' } }))
                    return
                }
                if (!checkNumber(val.spq, 1)) {
                    flag = false
                    dispatch(applicationActions.pushError({ title: { code: 'spq' }, messages: { code: 'integerCheck' } }))
                    return
                }
            })
        }

        if (!checkLength(basic.remark ?? '', 255)) {
            flag = false
            dispatch(applicationActions.pushError({ title: { code: 'remark' }, messages: { code: 'w0020', args: [intl.formatMessage({ id: 'remark' }), 255, 1] } }))
        }

        if (!checkLength(basic.orderReference ?? '', 255)) {
            flag = false
            dispatch(applicationActions.pushError({ title: { code: 'orderReference' }, messages: { code: 'w0020', args: [intl.formatMessage({ id: 'orderReference' }), 255, 1] } }))
        }

        if (!flag) return
        const data = mergeDatas({ ...basic, stepNo: viewIndex }, forecasts, inboundPlans)
        setDisabled(true)
        saveInfo(data, { serialized: true }).then(result => {
            if (mode === ScreenMode.CREATE) {
                navigate(`/placecustorder-sowc/edit-${result.spotId}`)
            } else {
                search(result.spotId)
            }
        }).finally(() => {
            setDisabled(false)
        })
    }, [basic, dispatch, forecasts, inboundPlans, intl, mergeDatas, mode, navigate, saveInfo, search, viewIndex])

    return <Access access="ORDER.OCS021.SAVE">
        <CallbackToolbarAction callback={onclickToSave} title={<FormattedMessage id="save" />} icon={<SaveOutlined />} disabled={disabled} />
    </Access>
}

const useStepStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        height: 50,
        top: 108,
        right: 96,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        background: 'white',
        padding: '8px 12px',
        borderRadius: theme.spacing(1.5),
        zIndex: theme.zIndex.appBar,
        boxShadow: theme.shadows[3],
    }
}))

const PartsList = memo(({ forecasts, setForecasts, basic, setBasic, availableSellers, mode }: {
    forecasts: PartsDetail[],
    setForecasts: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
    basic: SpotOrderResultBasic,
    setBasic: React.Dispatch<React.SetStateAction<SpotOrderResultBasic>>,
    availableSellers: AvailableSellerResult[],
    mode: ScreenMode,
}) => {
    return <>
        {forecasts.map((part, index) => {
            return (
                <PartListCardView
                    part={part}
                    index={index}
                    setForecasts={setForecasts}
                    basic={basic}
                    setBasic={setBasic}
                    availableSellers={availableSellers}
                    mode={mode}
                />)
        })}
    </>
})

const PartListCardView = memo(({ part, index, setForecasts, basic, setBasic, availableSellers, mode }: {
    part: PartsDetail,
    index: number,
    setForecasts: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
    basic: SpotOrderResultBasic,
    setBasic: React.Dispatch<React.SetStateAction<SpotOrderResultBasic>>,
    availableSellers: AvailableSellerResult[],
    mode: ScreenMode,
}) => {
    const [messages, setMessages] = useState<Message[]>([])
    const intl = useIntl()
    const availableSellerEntity = useMemo(() => {
        let aEntity: TnvCbds[] = []
        if (availableSellers && availableSellers.length > 0) {
            availableSellers.forEach((value) => {
                if (value.partsId === part.globalPartsId) {
                    aEntity = value.sellerList
                }
            })
        }
        return aEntity.map(m => [m.cbdsUid, m.cbdsCode] as [string, string])
    }, [availableSellers, part.globalPartsId])

    const readonly = (part.status >= PlaceSpotDetailStatus.Confirmed || mode === ScreenMode.VIEW) ? true : false
    const fields = useMemo(() => getFormCheckFields(true), [])
    const filedCheck = useFieldChecker(fields, setMessages)
    const onFieldChange = useCallback<React.Dispatch<React.SetStateAction<PartsDetail>>>(nextDraftDataFunc => {
        setForecasts(forecasts => {
            const part = forecasts[index] as PartsDetail
            const draftData = typeof nextDraftDataFunc === 'function' ? nextDraftDataFunc(part) : nextDraftDataFunc
            let newObj = { ...draftData }
            if (draftData.sellerUid) {
                availableSellerEntity.forEach(m => {
                    if (m[0] === draftData.sellerUid) {
                        newObj.sellerCode = m[1]
                    }
                })
            } else {
                newObj.sellerCode = ""
            }
            return forecasts.map((m, idx) => idx === index ? newObj : m)
        })
    }, [setForecasts, index, availableSellerEntity])


    const removepart = useCallback(() => {
        setForecasts(mailList => mailList.filter((_f, idx) => idx !== index))
    }, [index, setForecasts])

    return (
        <Form data={part} setData={onFieldChange} labelDisplay="block" helperDisplay="tooltip" messages={messages} setMessages={setMessages} ensure={filedCheck} columnCount={4}>
            <StringItem field="globalPartsNo" required={true} readonly={true} label={intl.formatMessage({ id: 'globalPartsNo' })} />
            <EntryItem field="sellerUid" required={false} readonly={readonly} label={intl.formatMessage({ id: "availableSellers" })} entries={availableSellerEntity}></EntryItem>
            {basic.status > PlaceSpotStatus.New ? <CodeItem field="status" required={false} readonly={true} label={intl.formatMessage({ id: 'status' })} code={CodeCategory.PlaceSpotDetailStatus} /> : undefined}
            {readonly ? undefined : <Tooltip title={<FormattedMessage id="remove" />} style={{ marginTop: 30, }}><IconButton onClick={removepart} ><CancelOutlined /></IconButton></Tooltip>}
        </Form>)
})

//select parts
const SelectPartsAction = ({ forecasts, setForecasts, mode, basic, setBasic, searchAva }: {
    forecasts: PartsDetail[],
    setForecasts: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
    mode: ScreenMode,
    basic: SpotOrderResultBasic,
    setBasic: React.Dispatch<React.SetStateAction<SpotOrderResultBasic>>,
    searchAva: (globalPartsIdList: number[] | undefined, placeType: number | null | undefined, spotId: number | null | undefined) => void
}) => {
    const [open, setOpen] = useState<boolean>(false)
    const onOpen = useCallback(() => {
        setOpen(true)
    }, [setOpen])
    const onClose = useCallback(() => setOpen(false), [])
    const selectedPartsList = useMemo(() => forecasts.map(p => p.globalPartsId), [forecasts])
    const [selectedList, setSelectedList] = useState<GlobalPartsResult[]>([])
    const resetPartsList = useCallback(() => {
        let globalPartsIdList: number[] = []
        if (selectedList.length > 0) {
            for (let i = 0; i < selectedList.length; i++) {
                setForecasts(forecasts => [...forecasts, { globalPartsNo: selectedList[i].partsNo, globalPartsId: selectedList[i].partsId } as PartsDetail])
                globalPartsIdList.push(selectedList[i].partsId)
            }
        }
        setOpen(false)
        setSelectedList([])
        if (forecasts && forecasts.length > 0) {
            forecasts.forEach(m => {
                globalPartsIdList.push(m.globalPartsId)
            })
        }

        searchAva(globalPartsIdList, basic.placeType, basic.spotId)
    }, [basic.placeType, basic.spotId, forecasts, searchAva, selectedList, setForecasts])
    return <>
        <Button onClick={onOpen} variant="contained" style={{ backgroundColor: '#7bd3fb' }}><FormattedMessage id="selectParts" /></Button >
        <Dialog open={open} style={{ height: '700px', overflow: 'hidden', color: '#fff', }} maxWidth="md" fullWidth>
            <DialogTitle>
                <FormattedMessage id="Global Part List" />
            </DialogTitle>
            <DialogContent>
                <OCS021GlobalParts requestType={ContractRequestType.ADD} selectedPartsList={selectedPartsList} setSelectedList={setSelectedList} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained" ><FormattedMessage id="close" /></Button>
                <Button autoFocus onClick={resetPartsList} variant="contained" style={{ backgroundColor: '#ffcc99' }}><FormattedMessage id="alert.ok" /></Button>
            </DialogActions>
        </Dialog>
    </>
}

const getFormCheckFields = (required: boolean) => {
    return {
        globalPartsNo: { labelId: 'globalPartsNo', required: required, length: { max: 255 } },
    }
}

interface Step2Props {
    basic: SpotOrderResultBasic,
    setBasic: React.Dispatch<React.SetStateAction<SpotOrderResultBasic>>,
    forecasts: PartsDetail[],
    setForecasts: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
    inboundPlans: PartsDetail[],
    editable: boolean,
    uomArr: string[],
    mode: ScreenMode,
    setInboundPlans: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
    setViewNum: React.Dispatch<React.SetStateAction<number>>,
    stepNo: number,
    search: (orderId: number | null | undefined) => void
}

const Step2FirmAndFcTable = memo((props: Step2Props) => {
    const { forecasts, setForecasts, uomArr, mode } = props
    const intl = useIntl()
    const [order, setOrder] = useState<string[]>([])
    const columns = useMemo(() => {
        const fixedColumns = [
            { field: 'globalPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
            { field: 'globalPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'partsDescription' }), width: 350 },
            { field: 'status', dataTypeName: CodeCategory.PlaceSpotStatus, title: intl.formatMessage({ id: 'status' }), width: 150 },
            { field: 'buyerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'unitPartsNo' }), width: 220 },
            { field: 'sellerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'sellerCode' }), width: 220 },
            { field: 'sellerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'sellerPartsNo' }), width: 180 },
            { field: 'uomCode', dataTypeName: 'uomType', title: intl.formatMessage({ id: 'uomCode' }), width: 180 },
            { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 180 },
            { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 150 },
        ] as Column[]
        const firmCategories = [{ key: 'firm', value: intl.formatMessage({ id: 'orderQty' }) }]
        const firmColumns = [{ field: 'orderQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'firmQty' }), categories: firmCategories, width: 150 }]
        // all columns
        return Arrays.concat(fixedColumns, firmColumns)
    }, [intl])

    const onEditingCellCommit = useCallback((_column: Column, row: Row) => {
        if (row.status < PlaceSpotStatus.Confirmed) {
            setForecasts(forecasts => forecasts.map(item => item.spotDetailId === row.spotDetailId ? row as PartsDetail : item))
        }
        return true
    }, [setForecasts])

    const defaultEditingDisabled = Records.from(columns.filter(f => !f.field.includes('uomCode') && !f.field.includes('orderQty') && !f.field.includes('orderLot') && !f.field.includes('sellerPartsNo') && !f.field.includes('buyerPartsNo') && !f.field.includes('spq')).map(({ field }) => [field, { editingDisabled: true }]))

    useEffect(() => {
        setOrder(columns.map(column => column.field))
    }, [columns])

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.PlaceSpotStatus} />
            <DataTypePreset />
            <PercentTypeProvider />
            <Data rows={forecasts} columns={columns} />
            <LibertyTypeProvider name="uomType" options={uomArr} />
            <ColumnFreeze />
            <ColumnVisibility ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnOrdering order={order} onOrderChange={setOrder} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Editing
                enableInlineEdit={mode !== ScreenMode.VIEW}
                onEditingCellCommit={onEditingCellCommit}
                columnSettings={defaultEditingDisabled}
            />
            <Filtering />
        </DataGrid>
    </div>
})

const Step3FirmAndFcTable = memo((props: Step2Props) => {
    const { forecasts, setForecasts, uomArr, mode } = props
    const intl = useIntl()
    const [order, setOrder] = useState<string[]>([])
    const planDates = useMemo(() => {
        return forecasts && forecasts.length > 0 ? Arrays.distinct(forecasts.flatMap(parts => parts.totPlaceSpotRequest).map(plan => (plan.crdDate ?? '').getTime())).sort().map(m => new Date(m)) : []
    }, [forecasts])
    const companyType = useGetCompanyType()
    const columns = useMemo(() => {
        const fixedColumns = [
            { field: 'globalPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
            { field: 'globalPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'partsDescription' }), width: 350 },
            { field: 'buyerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'unitPartsNo' }), width: 220 },
            { field: 'sellerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'sellerPartsNo' }), width: 180 },
            { field: 'uomCode', dataTypeName: 'uomType', title: intl.formatMessage({ id: 'uomCode' }), width: 180 },
            { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 180 },
            { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 150 },
        ] as Column[]

        if (mode === ScreenMode.VIEW && (companyType === CbdsType.CUST || companyType === CbdsType.BU)) {
            fixedColumns.push({ field: 'buyingCurrency', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.currency' }), width: 150 })
            fixedColumns.push({ field: 'buyingPrice', dataTypeName: 'quantity', title: intl.formatMessage({ id: 'field.unitPrice' }), width: 150, getCellValue: (row: Row) => (row.currencyDigits !== null && row.currencyDigits !== undefined) ? intl.formatNumber(row.buyingPrice, { minimumFractionDigits: row?.currencyDigits, maximumFractionDigits: row?.currencyDigits }) : row.buyingPrice })
        }

        const firmCategories = [{ key: 'firm', value: intl.formatMessage({ id: 'orderQty' }) }]
        const firmColumns = [{ field: 'orderQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'firmQty' }), categories: firmCategories, width: 150 }]
        const getPlanCategories = (index: number) => [{ key: `plan${index}`, value: intl.formatDate(planDates[index], { dateStyle: 'medium' }) }]
        const qtytitle = intl.formatMessage({ id: 'field.qty' })
        const dataTypeName = 'shippingPlan'
        const planColumns = planDates.map((planDate, index) => ({
            field: `planQty${index}`,
            dataTypeName: dataTypeName,
            title: qtytitle,
            categories: getPlanCategories(index),
            width: 180,
            getCellValue: (row: Row) => row.totPlaceSpotRequest.find((f: any) => f.crdDate.getTime() === planDate.getTime())?.crdQty ?? null,
            setCellValue: (row: Row, value: any) => {
                // do fiter
                if (row.totPlaceSpotRequest?.some((plan: any) => plan.crdDate.getTime() === planDate.getTime())) {
                    return ({ ...row, totPlaceSpotRequest: row.totPlaceSpotRequest?.map((plan: any) => plan.crdDate.getTime() === planDate.getTime() ? { ...plan, crdQty: value } : plan) })
                } else {
                    const totPlaceSpotRequest = row.totPlaceSpotRequest
                    totPlaceSpotRequest.push({
                        crdDate: planDate,
                        crdQty: value,
                    })
                    return ({ ...row, totPlaceSpotRequest: totPlaceSpotRequest })
                }
            }
        }))

        // all columns
        return Arrays.concat(fixedColumns, firmColumns, planColumns)
    }, [companyType, intl, mode, planDates])

    const onEditingCellCommit = useCallback((_column: Column, row: Row) => {
        // only TOT_PLACE_SPOT_DETAIL STATUS < 30:Confirmed can modify
        setForecasts(forecasts => forecasts.map(item => (item.spotDetailId === row.spotDetailId && item.status < 30) ? row as PartsDetail : item))
        return true
    }, [setForecasts])

    const defaultEditingDisabled = Records.from(columns.filter(f => !f.field.includes('planQty')).map(({ field }) => [field, { editingDisabled: true }]))

    const [actions, setActions] = useState<RequestInboundPlanProps>({ open: false, editDate: null })
    const AddnewAction = useMemo(() => ({ setActions }), [setActions])

    const dataHeaderCallBack = useCallback((field: string, type: ScreenMode) => {
        if (type === ScreenMode.REMOVE) {
            if (field.startsWith("planQty")) {
                let editDate: Date = planDates[Number(field.substring(7))]
                setForecasts(inboundPlans => inboundPlans.map(m => ({
                    ...m,
                    totPlaceSpotRequest: m.totPlaceSpotRequest.filter(f => f.crdDate.getTime() !== editDate.getTime())
                })))
            }
        } else if (type === ScreenMode.MODIFY) {
            field.startsWith("planQty") && setActions({ open: true, editDate: planDates[Number(field.substring(7))] })
        } else {

        }
    }, [planDates, setForecasts])

    const showButton: boolean = (mode === ScreenMode.VIEW || forecasts.filter(f => f?.status >= 30)?.length > 0) ? false : true
    const shippingPlanCategory = useShippingPlanCategoryForSpot(dataHeaderCallBack, showButton)

    useEffect(() => {
        setOrder(columns.map(column => column.field))
    }, [columns])


    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <PercentTypeProvider />
            <StringTypeProvider name="quantity" align="end" />
            <NumberTypeProvider name="shippingPlan" Category={shippingPlanCategory} />
            <LibertyTypeProvider name="uomType" options={uomArr} />
            <Data rows={forecasts} columns={columns} />
            <ToolbarActionProvider Action={CreateAction} actionProps={AddnewAction} display={() => mode !== ScreenMode.VIEW} />
            <ColumnFreeze />
            <ColumnVisibility ToolbarButton={ColumnVisibilityToolbarButton} />
            {/* <ColumnOrdering order={order} onOrderChange={setOrder} /> */}
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Editing
                enableInlineEdit={mode !== ScreenMode.VIEW}
                onEditingCellCommit={onEditingCellCommit}
                columnSettings={defaultEditingDisabled}
            />
            <Filtering />
        </DataGrid>
        <AddNewRequestDialog actions={actions} setActions={setActions} inboundPlans={forecasts} setInboundPlans={setForecasts} />
    </div>
})

interface RequestInboundPlanProps {
    open: boolean,
    editDate: Date | null
}
const CreateAction = ({ setActions }: { setActions: React.Dispatch<React.SetStateAction<RequestInboundPlanProps>> }) => {
    return <CreateCallbackToolbarAction title={<FormattedMessage id="Add New Plan" />} callback={() => setActions({ open: true, editDate: null })} />
}
const AddNewRequestDialog = ({ actions, setActions, inboundPlans, setInboundPlans }: {
    actions: RequestInboundPlanProps,
    setActions: React.Dispatch<React.SetStateAction<RequestInboundPlanProps>>,
    inboundPlans: PartsDetail[],
    setInboundPlans: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
}) => {
    const dispatch = useDispatch()
    const { open, editDate } = actions
    const [factor, setFactor] = useState<{ inboundPlanDate: Date | null }>({ inboundPlanDate: null })
    const handleClose = useCallback(() => setActions({ open: false, editDate: null }), [setActions])

    const intl = useIntl()

    useEffect(() => {
        setFactor({ inboundPlanDate: editDate })
    }, [editDate])

    const aplyDate = useCallback(() => {
        if (!factor.inboundPlanDate) {
            dispatch(applicationActions.pushError({ title: { code: 'confirm' }, messages: { code: 'w0441' } }))
            return
        } else {
            const inboundPlanDate = factor.inboundPlanDate
            if (inboundPlans.flatMap(f => f.totPlaceSpotRequest).some(s => moment(s.crdDate).format(moment.HTML5_FMT.DATE) === moment(inboundPlanDate).format(moment.HTML5_FMT.DATE))
                && (!editDate || moment(editDate).format(moment.HTML5_FMT.DATE) !== moment(inboundPlanDate).format(moment.HTML5_FMT.DATE))) {
                dispatch(applicationActions.pushError({ title: { code: 'confirm' }, messages: { code: 'w0381' } }))
                return
            } else {
                setInboundPlans(inboundPlans => inboundPlans.map(m => ({
                    ...m, totPlaceSpotRequest: !editDate ? [...m.totPlaceSpotRequest, {
                        spotDetailId: m.spotDetailId,
                        crdDate: inboundPlanDate,
                        crdQty: null
                    }] : m.totPlaceSpotRequest.map(plan => plan.crdDate.getTime() === editDate.getTime() ? { ...plan, crdDate: inboundPlanDate } : plan)
                })))
            }
        }
        // do reflect
        setFactor({ inboundPlanDate: null })
        setActions({ open: false, editDate: null })
    }, [dispatch, editDate, factor.inboundPlanDate, inboundPlans, setActions, setInboundPlans])

    return <>
        <DarkDialog open={open} style={{ overflow: 'hidden' }} maxWidth="sm" fullWidth keepMounted={false} fullScreen={false}>
            <DialogHeader onClose={handleClose}><FormattedMessage id="inputInboundDate" /></DialogHeader>
            <DialogContent>
                <Form data={factor} setData={setFactor} labelDisplay="block" helperDisplay="tooltip" columnCount={1} minWidth={500} maxWidth={500}  >
                    <DateItem required field="inboundPlanDate" labelWidth={120} label={intl.formatMessage({ id: 'field.inboundPlanDate' })} />
                </Form>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={handleClose} />
                <DialogAction title={<FormattedMessage id="confirm" />} callback={aplyDate} />
            </DialogActions>
        </DarkDialog>
    </>
}

const UploadAction = (props: Step2Props) => {
    const { basic, setBasic, setForecasts, setInboundPlans, setViewNum, stepNo, mode, search } = props
    const navigate = useNavigate()
    const splitDatas = useSplitDatas()
    const mergeDatas = useMergeDatas()
    const saveInfo = useSaveSpotOrder()
    const upload1 = useUploadSpotOrderWithContractStep1FormForCustomer()
    const upload2 = useUploadSpotOrderWithContractFormForCustomer()
    const uploadMethod = stepNo === 1 ? upload1 : upload2
    const upload = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            files && uploadMethod({ file: files[0], spotId: basic.spotId }, { serialized: true }).then(result => {
                if (result) {
                    const { orderbasic, orderforecasts, orderInboundPlans } = splitDatas(result)
                    if (stepNo === 1) {
                        const saveData = mergeDatas({ ...orderbasic, stepNo: stepNo }, orderforecasts, orderInboundPlans)
                        saveInfo(saveData, { serialized: true, silent: true }).then(() => {
                            if (mode === ScreenMode.CREATE) {
                                navigate(`/placecustorder-sowc/edit-${result.spotId}`)
                            } else {
                                search(result.spotId)
                            }
                        })
                    } else {
                        setBasic(orderbasic)
                        setForecasts(orderforecasts)
                        setInboundPlans(orderInboundPlans)
                        setViewNum(stepNo)
                    }
                }
            })
        })
    }, [basic.spotId, mergeDatas, mode, navigate, saveInfo, search, setBasic, setForecasts, setInboundPlans, setViewNum, splitDatas, stepNo, uploadMethod])

    return <UploadGroupedToolbarAction >
        {(popupUpload, onClose) => <>
            <GroupedCallbackItem label={<FormattedMessage id="upload" />} callback={() => upload(popupUpload)} onClose={onClose} />
        </>}
    </UploadGroupedToolbarAction>
}

const DownloadAction = (props: Step2Props) => {
    const { basic, forecasts, inboundPlans, stepNo, mode, search } = props
    const navigate = useNavigate()
    const mergeDatas = useMergeDatas()
    const saveInfo = useSaveSpotOrder()
    const downloadStep1 = useDownloadSpotOrderWithContractStep1ForCustomer()
    const downloadStep2 = useDownloadSpotOrderWithContractForCustomer()
    const download = useCallback(() => {
        const data = mergeDatas({ ...basic, stepNo: 1 }, forecasts, inboundPlans)
        if (stepNo === 1) {
            saveInfo(data, { serialized: true, silent: true }).then(result => {
                if (result) {
                    downloadStep1(result)
                    if (mode === ScreenMode.CREATE) {
                        navigate(`/placecustorder-sowc/edit-${result.spotId}`)
                    } else {
                        search(result.spotId)
                    }
                }

            })
        } else {
            downloadStep2(data)
        }

    }, [basic, downloadStep1, downloadStep2, forecasts, inboundPlans, mergeDatas, mode, navigate, saveInfo, search, stepNo])
    return <DownloadGroupedToolbarAction >
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="download" />} callback={download} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}


const checkNumber = (field: number, type: number) => {
    if (type === 1) {
        if (field && field <= 0) {
            return false
        }
        return true
    } else if (type === 2) {
        if (field && field < 0) {
            return false
        }
        return true
    }

}

const checkLength = (field: string, length: number) => {
    if (field.length > length) return false
    return true
}
function dispatch(arg0: { payload: Omit<import("../../../utils/Notification").Notification, "type">; type: string }) {
    throw new Error("Function not implemented.")
}

