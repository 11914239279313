import { useCallback } from "react"
import { useDownload, usePost } from "../../../utils/fetch/useFetch"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { UserActiveFactorListFactorToJson, UserActiveListFactor } from "../models/UserActiveFactor"
import { userActiveFactorFromJson, UserActiveListResult } from "../models/UserActiveResult"


export const useGetUserActiveByPage = () => {
    const url = '/common-system-api/users/activeList'
    const payloadProject = useCallback(UserActiveFactorListFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(userActiveFactorFromJson), [])
    return usePost<UserActiveListFactor, PageResultEntity<UserActiveListResult>>(url, payloadProject, resultProject)
}

export const useDownloadUserActive = () => {
    const url = '/common-system-api/users/downloadUserActive'
    const payloadProject = useCallback((obj: {ids: string[], filter: UserActiveListFactor}) => {
        return {
            ids: obj.ids,
            filter: {
            ...obj.filter
        }};
    }, []);
    return useDownload<{ ids: string[], filter: UserActiveListFactor}>(url, payloadProject, { fileTimestamp: true })
}