import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useGetCustomerByLoginUser } from "../../../services/smt/api/ComboboxApi"
import { CustomerStockList } from "../../../services/smt/models/CustomerStockList"
import { CustomerStockListFactor, customerStockListFactorFromJson, customerStockListFactorToJson } from "../../../services/smt/models/CustomerStockListFactor"
import { useGetCustomerStockList } from "../../../services/smt/stockManagement/api/weeklyRundownApi"
import { Pair } from "../../../services/utils/Pair"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { SMGTS310PcUi } from "./SMGTS310PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }
const searchCachekey = sessionKeys.Filters_SMGTS310


export const SMGTS310 = () => {
    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? customerStockListFactorFromJson(JSON.parse(filters)) : null
    }, [])

    const [filters, setFilters] = useState<CustomerStockListFactor>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<CustomerStockList[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [customerList, setCustomerList] = useState<Pair[]>([])


    const getCustomerStockList = useGetCustomerStockList()
    const search = useCallback((filters: CustomerStockListFactor) => {
        getCustomerStockList(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(customerStockListFactorToJson(filters)))
        })
    }, [getCustomerStockList, setData])


    useSearchOnLoad(search, filters)

    const getCustomerList = useGetCustomerByLoginUser()
    useEffect(() => {
        getCustomerList(undefined, { silent: true, serialized: true }).then((result) => {
            setCustomerList(result ?? [])
        })
    }, [getCustomerList])

    return <SMGTS310PcUi
        filters={filters}
        search={search}
        data={data}
        totalCount={totalCount}
        setFilters={setFilters}
        customerList={customerList}
    />
}