import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { PageViewEntity, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"


export type TnmUserToListFilter = Partial<Optional>

type TnmUserToListFilterJson = Partial<JsonOptional>

export const tnmUserToListFilterJson: Project<TnmUserToListFilter, TnmUserToListFilterJson> = obj => ({
    ...obj,
    remindStartDate: dateToJson(obj.remindStartDate),
    remindEndDate: dateToJson(obj.remindEndDate),
    dueDate: dateToJson(obj.dueDate),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page)
})

interface Optional extends Omit<JsonOptional, 'remindStartDate' | 'remindEndDate' | 'dueDate'> {
    remindStartDate: Date,
    remindEndDate: Date,
    dueDate: Date,
    page: PageViewEntity,
}

interface JsonOptional {
    todoId: number,
    fromUserId: number,
    toCbdsUid: string,
    toUserId: number,
    title: string,
    titleParam: string,
    description: string,
    descriptionParam: string,
    todoType: number,
    scheduleType: number,
    urgency: number,
    url: string,
    urlParam: string,
    taskKey: string,
    remindStartDate: string
    remindEndDate: string,
    status: number[],
    createdBy: string,
    createdDate: number,
    updatedBy: string,
    updatedDate: number,
    version: number,
    importanceFlag: number,
    dueDate: string,
    task: string,
    taskCategory: number,
    recurrence: number,
    file: string,
    fileName: string,
    page: PageViewEntityJson,
    urgencies: number[],
    scheduleTypes: number[],
}