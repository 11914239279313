import { AxiosResponse } from 'axios'
import moment from "moment"

export default function printResponse(response: AxiosResponse, options?: { defaultFilename?: string, timestamp?: boolean, mimeType?: string }) {
    // get content type from header
    const contentType = options?.mimeType || response.headers['content-type'] || response.headers['Content-Type']
    // get file name from header
    const contentDisposition = response.headers['content-disposition'] || response.headers['Content-Disposition'] || response.headers['Content-disposition']
    const filename = filenameFromContentDisposition(contentDisposition)
    // get file name
    let downloadFilename = 'download'
    if (filename) {
        downloadFilename = options?.timestamp ? appendTimestamp(filename) : filename
    } else if (options?.defaultFilename) {
        downloadFilename = options?.timestamp ? appendTimestamp(options?.defaultFilename) : options?.defaultFilename
    }
    // construct blob
    const blob = new Blob([response.data], { type: contentType })
    // create url
    const url = window.URL.createObjectURL(blob)
    // create iframe
    const iframe = document.createElement('iframe')
    iframe.style.position = 'fixed'
    iframe.style.top = '0'
    iframe.style.left = '0'
    iframe.style.width = '0'
    iframe.style.height = '0'
    iframe.style.opacity = '0'
    iframe.title = downloadFilename
    iframe.src = url
    // mount iframe
    document.body.appendChild(iframe)
    iframe.onload = () => {
        iframe.contentWindow!.focus()
        iframe.contentWindow!.print()
        // revoke url
        window.URL.revokeObjectURL(url)
        // unmount iframe
        setTimeout(() => {
            iframe.remove()
        }, 60 * 60 * 1000)
    }
}

function filenameFromContentDisposition(contentDisposition: string) {
    if (contentDisposition !== null) {
        const matchResult = contentDisposition.match(/filename="(.+)"/)
        return matchResult === null ? null : matchResult[1]
    }
    return null
}

function appendTimestamp(fileName: string) {
    const timestamp = moment().format('YYYYMMDDHHmmss')
    const matchResult = fileName.match(/(.*)\.(.*)/)
    return matchResult === null ? fileName + timestamp : `${matchResult[1]}.${timestamp}.${matchResult[2]}`

}