import { useCallback } from "react"
import { Projects } from "../../../utils/Projects"
import { useDownload } from "../../../utils/fetch/useFetch"
import { DownloadDiByPartForBuView, DownloadDiByPartForBuViewToJson } from "../models/DownloadDiByPartForBuView"
import { DownloadDiMonitorView, downloadDiMonitorViewToJson } from "../models/DownloadDiMonitorView"
import { DownloadInboundFormResult } from "../models/DownloadInboundFormResult"
import { DownloadOnbDlvrView, downloadOnbDlvrViewToJson } from "../models/DownloadOnbDlvrView"
import { DownloadOubPriorityView, downloadOubPriorityViewToJson } from "../models/DownloadOubPriorityView"
import { DownloadOutBoundPartsInfo, downloadInboundFormResultToJson } from "../models/DownloadOutBoundPartsInfo"
import { OutboundDownloadFactor, outboundDownloadFactorToJson } from "../models/OutboundDownloadFactor"
import { OutboundToWIPFactor, outboundToWIPFactorToJson } from "../models/OutboundToWIPFactor"
import { WIPAdjustFactor, wipAdjustFactorToJson } from "../models/WIPAdjustFactor"

export const useDownloadDiByParts = () => {
    const url = '/lcbm-logistics-api/download/downloadDiByParts'
    const payloadProject = Projects.IGNORE
    return useDownload<undefined>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadDiByRoll = () => {
    const url = '/lcbm-logistics-api/download/downloadDiByRoll'
    const payloadProject = Projects.IGNORE
    return useDownload<undefined>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadDiForBuByParts = () => {
    const url = '/lcbm-logistics-api/download/downloadDiForBuByParts'
    const payloadProject = useCallback(DownloadDiByPartForBuViewToJson, [])
    return useDownload<DownloadDiByPartForBuView>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadDiByModule = () => {
    const url = '/lcbm-logistics-api/download/downloadDiByModule'
    const payloadProject = Projects.IGNORE
    return useDownload<undefined>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadDiChangeForm = () => {
    const url = '/lcbm-logistics-api/download/downloadDiChangeForm'
    const payloadProject = useCallback((obj: { diIdList: number[] }) => obj.diIdList, [])
    return useDownload<{ diIdList: number[] }>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadDiForBuChangeForm = () => {
    const url = '/lcbm-logistics-api/download/downloadDiForBuChangeForm'
    const payloadProject = useCallback((obj: { diIdList: number[] }) => obj.diIdList, [])
    return useDownload<{ diIdList: number[] }>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadDiMonitor = () => {
    const url = '/lcbm-logistics-api/download/downloadDiMonitor'
    const payloadProject = useCallback(downloadDiMonitorViewToJson, [])
    return useDownload<DownloadDiMonitorView>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadInbResult = () => {
    const url = '/lcbm-logistics-api/download/downloadInboundResult'
    const payloadProject = useCallback((obj: { inbNoList: string[], manualInbNoList: string[] }) => obj, [])
    return useDownload<{ inbNoList: string[], manualInbNoList: string[] }>(url, payloadProject, { fileName: 'Inbound Result.xlsx', fileTimestamp: true })
}

export const useDownloadInbPlan = () => {
    const url = '/lcbm-logistics-api/download/downloadInbPlan'
    const payloadProject = useCallback((obj: { inbNoList: string[] }) => obj.inbNoList, [])
    return useDownload<{ inbNoList: string[] }>(url, payloadProject, { fileName: 'Inbound Plan.xlsx', fileTimestamp: true })
}

export const useDownloadInboundPlanForUpload = () => {
    const url = '/lcbm-logistics-api/download/downloadInboundPlanForUpload'
    const payloadProject = useCallback((obj: { inbNoList: string[] }) => obj.inbNoList, [])
    return useDownload<{ inbNoList: string[] }>(url, payloadProject, { fileName: 'Inbound Result for Upload.xlsx', fileTimestamp: true })
}

export const useDownloadExternalInboundPlan = () => {
    const url = '/lcbm-logistics-api/download/downloadExternalInboundPlan'
    const payloadProject = useCallback((obj: { inbNoList: string[] }) => obj.inbNoList, [])
    return useDownload<{ inbNoList: string[] }>(url, payloadProject, { fileName: 'Inbound Result for Upload.xlsx', fileTimestamp: true })
}

export const useDownloadOnbDlvr = () => {
    const url = '/lcbm-logistics-api/download/downloadOnbDlvr'
    const payloadProject = useCallback(downloadOnbDlvrViewToJson, [])
    return useDownload<DownloadOnbDlvrView>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadOubPriorityByParts = () => {
    const url = '/lcbm-logistics-api/download/downloadOubPriorityByParts'
    const payloadProject = useCallback(downloadOubPriorityViewToJson, [])
    return useDownload<DownloadOubPriorityView>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadOubPriorityByModule = () => {
    const url = '/lcbm-logistics-api/download/downloadOubPriorityByModule'
    const payloadProject = useCallback(downloadOubPriorityViewToJson, [])
    return useDownload<DownloadOubPriorityView>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadOutTemplate = () => {
    const url = '/lcbm-logistics-api/download/downloadOutboundFromScreen'
    const payloadProject = useCallback(Projects.array(downloadInboundFormResultToJson), [])
    return useDownload<DownloadOutBoundPartsInfo[]>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadOutTemplateByPkg = () => {
    const url = '/lcbm-logistics-api/download/downloadOutTemplateByPkg'
    const payloadProject = useCallback(downloadInboundFormResultToJson, [])
    return useDownload<DownloadOutBoundPartsInfo>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadSimpleOutboundByPkg = () => {
    const url = '/lcbm-logistics-api/download/simple-outbound/downloadBySelected'
    const payloadProject = useCallback(downloadInboundFormResultToJson, [])
    return useDownload<DownloadOutBoundPartsInfo>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadSimpleOutboundFromScreen = () => {
    const url = '/lcbm-logistics-api/download/simple-outbound/downloadFromScreen'
    const payloadProject = useCallback(Projects.array(downloadInboundFormResultToJson), [])
    return useDownload<DownloadOutBoundPartsInfo[]>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadExternalOutboundTemplate = () => {
    const url = '/lcbm-logistics-api/download/downloadExternalOutbound'
    const payloadProject = useCallback(Projects.array(downloadInboundFormResultToJson), [])
    return useDownload<DownloadOutBoundPartsInfo[]>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadOutboundResult = () => {
    const url = '/lcbm-logistics-api/download/downloadOutboundBySelect'
    const payloadProject = useCallback((obj: { outboundNoList: string[] }) => obj.outboundNoList, [])
    return useDownload<{ outboundNoList: string[] }>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadOuterByParts = () => {
    const url = '/roe-api/delivery/download/downloadOuterByParts'
    const payloadProject = useCallback(outboundDownloadFactorToJson, [])
    return useDownload<OutboundDownloadFactor>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadPNAInboundResult = () => {
    const url = '/lcbm-logistics-api/download/downloadPNAInboundResult';
    const payloadProject = Projects.KEEP
    return useDownload<{ inboundNos: string[] }>(url, payloadProject, { fileTimestamp: true });
}

export const useDownloadPNAInboundResultTemplate = () => {
    const url = '/lcbm-logistics-api/download/downloadPNAInboundResultTemplate';
    const payloadProject = useCallback((obj: { manualInboundPartsList: DownloadInboundFormResult[] }) => obj.manualInboundPartsList, [])
    return useDownload<{ manualInboundPartsList: DownloadInboundFormResult[] }>(url, payloadProject, { fileTimestamp: true });
}

export const useDownloadRootsInboundResult = () => {
    const url = '/lcbm-logistics-api/download/downloadRootsInboundResult';
    const payloadProject = Projects.KEEP
    return useDownload<{ inboundNos: string[] }>(url, payloadProject, { fileTimestamp: true });
}

export const useDownloadOutboundToWIP = () => {
    const url = '/lcbm-logistics-api/download/outboundtowip/downloadByFilters'
    const payloadProject = useCallback(outboundToWIPFactorToJson, [])
    return useDownload<OutboundToWIPFactor>(url, payloadProject, { fileName: 'Outbound To WIP.xlsx', fileTimestamp: true })
}

export const useDownloadOutboundToWIPById = () => {
    const url = '/lcbm-logistics-api/download/outboundtowip/downloadByIds'
    const payloadProject = useCallback((obj: { outboundToWipIds: number[] }) => obj, [])
    return useDownload<{ outboundToWipIds: number[] }>(url, payloadProject, { fileName: 'Outbound To WIP.xlsx', fileTimestamp: true })
}

export const useDownloadWIPStockAdjustHistory = () => {
    const url = '/lcbm-logistics-api/download/wipadjust/download'
    const payloadProject = useCallback(wipAdjustFactorToJson, [])
    return useDownload<WIPAdjustFactor>(url, payloadProject, { fileName: 'Wip Adjustment.xlsx', fileTimestamp: true })
}