import { Project } from "../../../../utils/Project"
import { dateToJson } from "../../../utils/serializer"

export type OutboundDownloadFactor = Required & Partial<Optional>
type OutboundDownloadFactorJson = JsonRequired & Partial<JsonOptional>
export const outboundDownloadFactorToJson: Project<OutboundDownloadFactor, OutboundDownloadFactorJson> = obj => ({
    ...obj,
    outboundDateStart: dateToJson(obj.outboundDateStart),
    outboundDateEnd: dateToJson(obj.outboundDateEnd),
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'outboundDateStart' | 'outboundDateEnd'> {
    outboundDateStart: Date,
    outboundDateEnd: Date
}

interface JsonRequired {
}

interface JsonOptional {
    customerPartsIds: number[],
    groupings: string[],
    outboundDateStart: string,
    outboundDateEnd: string,
    buyerList:string[]
}