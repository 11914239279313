import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { PartsDetail, partsDetailDeserializer, partsDetailSerializer } from "../models/PlaceOrderForCustomerDetailResult"

export const useCancelPlaceOrderForCustomerDetail = () => {
    const url = '/lcbm-spot-order-api/order/cro/api/doCancelOneDetail029'
    const payloadProject = useCallback(partsDetailSerializer, [])
    const resultProject = useCallback(partsDetailDeserializer, [])
    return usePost<PartsDetail, PartsDetail>(url, payloadProject, resultProject)
}



