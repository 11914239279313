import { useCallback, useEffect, useMemo, useState } from "react"
import { useGetFCChangedableCustomerOrderList, useGetOCWCChangedableCustomerOrderList, useGetOrderChangedableCustomerOrderList } from "../../../services/order/apis/ChangeRequestApi"
import { ChangeRequestListResult } from "../../../services/order/models/ChangeRequestListResult"
import { ChangeRequestWithoutContractListResult } from "../../../services/order/models/ChangeRequestWithoutContractListResult"
import { useMatch } from "../../../utils/useMatch"
import { ONS030PcUi } from "./ONS030PcUi"
import { TabId } from "./Records"


export const ONS030 = () => {

    const filters = useMemo(() => ({ page: { currentPage: 0, pageSize: 1000 } }), [])
    const [totalCount, setTotalCount] = useState<number>(0)
    const { path } = useMatch()
    const currentTabId = useMemo(() => prepareTabIdByPath(path), [path])
    const [orderChangedableList, setOrderChangedableList] = useState<ChangeRequestListResult[]>([])
    const [fcChangedableList, setFcChangedableList] = useState<ChangeRequestListResult[]>([])
    const [ocwcChangedableList, setOcWcChangedableList] = useState<ChangeRequestWithoutContractListResult[]>([])

    const getOrderChangedableList = useGetOrderChangedableCustomerOrderList()
    const getFcChangedableList = useGetFCChangedableCustomerOrderList()
    const getOcWcChangedableList = useGetOCWCChangedableCustomerOrderList()

    const searchOrderChangedableList = useCallback(() => {
        getOrderChangedableList(undefined, { silent: true, serialized: true }).then(result => {
            setOrderChangedableList(result || [])
        })
    }, [getOrderChangedableList])

    const searchFcChangedableList = useCallback(() => {
        getFcChangedableList(undefined, { silent: true, serialized: true }).then(result => {
            setFcChangedableList(result || [])
        })
    }, [getFcChangedableList])

    const searchOcWcChangedableList = useCallback((filters: ChangeRequestWithoutContractListResult) => {
        getOcWcChangedableList(filters, { silent: true, serialized: true }).then(result => {
            setOcWcChangedableList(result?.data || [])
            setTotalCount(result?.page?.totalCount || 0)
        })
    }, [getOcWcChangedableList])

    useEffect(() => {
        if (currentTabId === TabId.forecast) {
            searchFcChangedableList()
        } else {
            searchOrderChangedableList()
        }

    }, [currentTabId, filters, searchFcChangedableList, searchOcWcChangedableList, searchOrderChangedableList])

    return <ONS030PcUi
        tabId={currentTabId}
        orderChangedableList={orderChangedableList}
        searchOrderChangedableList={searchOrderChangedableList}
        fcChangedableList={fcChangedableList}
        searchFcChangedableList={searchFcChangedableList}
        ocwcChangedableList={ocwcChangedableList}
        searchOcWcChangedableList={searchOcWcChangedableList}
        filters={filters}
        totalCount={totalCount}

    />
}
const prepareTabIdByPath = (path: any) => {
    switch (path) {
        case "/cro-order":
            return TabId.order;
        case "/cro-forecast":
            return TabId.forecast;
        default:
            return TabId.ONS030;
    }
}