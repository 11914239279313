import { Message } from "@rithe/form"
import moment from "moment"
import { Dispatch, SetStateAction, useCallback } from "react"
import { IntlShape, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { applicationActions } from "../layouts/Application/applicationSlice"

interface lengthCheckProps {
    min?: number,
    max?: number,
    integer?: number,
    scale?: number
}

interface compareCheckProps {
    min?: number | Date,
    minType?: 'G' | 'GE',
    max?: number | Date,
    maxType?: 'L' | 'LE',
}

interface ValidateProps {
    labelId: string,
    required?: boolean,
    length?: lengthCheckProps,
    fixed?: (number | string)[],
    compare?: compareCheckProps,
    integer?: boolean,
}

export const useFieldChecker = (items: Record<string, ValidateProps>, setMessages?: React.Dispatch<React.SetStateAction<Message[]>>) => {

    const intl = useIntl()
    const checker = useCallback((field: string, value: any): Message[] => {
        // clear message which belongs current field
        setMessages && setMessages(msgs => msgs.findIndex(f => f.field === field) < 0 ? msgs : msgs.filter(f => f.field !== field))
        const messages: Message[] = []
        if (items[field]) {
            doCheck(field, value, items[field], messages, intl)
        }
        return messages
    }, [intl, items, setMessages])
    // return check
    return checker
}

export const useFormValidater = (setMessages: Dispatch<SetStateAction<Message[]>>, items: Record<string, ValidateProps>) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const formCheck = useCallback((data: any, actionId?: string) => {
        const messages: Message[] = []
        // do check
        Object.keys(items).forEach((field) => doCheck(field, data[field], items[field], messages, intl))
        // if has error message
        if (messages.length !== 0) {
            setMessages(messages)
            dispatch(applicationActions.pushError({ title: { code: actionId ?? 'inputError' }, messages: { code: 'w0345' } }))
            return false
        }
        return true
    }, [dispatch, intl, items, setMessages])
    // return check
    return formCheck
}

export const useFormCheck = (items: Record<string, ValidateProps>) => {
    const intl = useIntl()
    const formCheck = useCallback((data: any, actionId?: string) => {
        const messages: Message[] = []
        // do check
        Object.keys(items).forEach((field) => doCheck(field, data[field], items[field], messages, intl))
        // if has error message
        return messages
    }, [intl, items])
    // return check
    return formCheck
}

const doCheck = (field: string, value: any, props: ValidateProps, messages: Message[], intl: IntlShape) => {

    // get props
    const { labelId, required, length, fixed, compare } = props

    // reqired check
    required && requiredChecker(field, value, labelId, messages, intl)
    // length check
    length && lengthChecker(field, value, labelId, length, messages, intl)
    // fixed check
    fixed && fixedChecker(field, value, labelId, fixed, messages, intl)
    // fixed check
    compare && compareChecker(field, value, labelId, compare, messages, intl)
}

export const isNotEmpty = (value: any) => {
    if (value !== undefined && value !== null) {
        if (typeof value === 'string') {
            return value !== '' && value.length > 0
        }
        if (value instanceof Array) {
            return value.length > 0
        }
        return true
    }
    return false
}

const requiredChecker = (field: string, value: any, labelId: string, messages: Message[], intl: IntlShape) => {
    if (!isNotEmpty(value)) {
        messages.push({ field: field, level: 'error', value: intl.formatMessage({ id: 'w0001' }, [intl.formatMessage({ id: labelId })] as any) })
    }
}

const lengthChecker = (field: string, value: any, labelId: string, length: lengthCheckProps, messages: Message[], intl: IntlShape) => {
    const { min, max, integer, scale } = length
    if (isNotEmpty(value)) {
        if (typeof value === 'string' || value instanceof Array) {
            if ((min !== undefined && value.length < min)) {
                messages.push({ field: field, level: 'error', value: intl.formatMessage({ id: 'w0340' }, [intl.formatMessage({ id: labelId }), min] as any) })
            }
            if ((max !== undefined && value.length > max)) {
                messages.push({ field: field, level: 'error', value: intl.formatMessage({ id: 'w0019' }, [intl.formatMessage({ id: labelId }), max] as any) })
            }
        } else if (typeof value === 'number') {
            if ((integer !== undefined && value >= Math.pow(10, integer))) {
                messages.push({ field: field, level: 'error', value: intl.formatMessage({ id: 'w0054' }, [intl.formatMessage({ id: labelId }), integer, scale ?? 0] as any) })
            }
            if (scale !== undefined) {
                const digits = (Math.floor(value) !== value) ? value.toString().split(".")[1].length : 0
                if (scale < digits) {
                    // 12 is the max lenth of integer for most items 
                    messages.push({ field: field, level: 'error', value: intl.formatMessage({ id: 'w0054' }, [intl.formatMessage({ id: labelId }), integer ?? 12, scale] as any) })
                }
            }
        }
    }
}

const fixedChecker = (field: string, value: any, labelId: string, fixed: (number | string)[], messages: Message[], intl: IntlShape) => {
    if (fixed.length > 0 && isNotEmpty(value)) {
        if (!fixed.includes(value)) {
            messages.push({ field: field, level: 'error', value: intl.formatMessage({ id: 'w0231' }, [intl.formatMessage({ id: labelId }), fixed.join(',')] as any) })
        }
    }
}

const compareChecker = (field: string, value: any, labelId: string, compare: compareCheckProps, messages: Message[], intl: IntlShape) => {
    const { min, minType, max } = compare
    if (isNotEmpty(value)) {
        // if check min
        if (min !== undefined && min !== null) {
            const type = minType ?? 'GE'
            if (typeof min === 'number') {
                if (type === 'GE' ? min > value : min >= value) {
                    messages.push({ field: field, level: 'error', value: intl.formatMessage({ id: 'w0031' }, [intl.formatMessage({ id: labelId }), min] as any) })
                }
            } else if (min instanceof Date) {
                if (type === 'GE' ? moment(min).isAfter(moment(value)) : !moment(min).isBefore(moment(value))) {
                    messages.push({ field: field, level: 'error', value: intl.formatMessage({ id: 'w0339' }, [intl.formatMessage({ id: labelId }), intl.formatDate(min, { dateStyle: 'medium' })] as any) })
                }
            }
        }
        // if check max
        if (max !== undefined && max !== null) {

        }
    }
}