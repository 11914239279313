import { Project } from "../../../utils/Project"


export type InventoryFactor = Required & Partial<Optional>

type InventoryFactorJson = JsonRequired & Partial<JsonOptional>

export const iventoryFactorToJson: Project<InventoryFactor, InventoryFactorJson> = obj => ({
    ...obj
})

interface Required extends JsonRequired { }

interface Optional extends JsonOptional {
}

interface JsonRequired {
}

interface JsonOptional {
    month?: number,
    year?: number,
    item?: string,
    itemFamily?: string,
    warehouses?: number[],
    currency?: string
}
