import { Project } from "@rithe/utils"
import { dateFromJson, datetimeFromJson } from "../../utils/deserializer"

export interface OrderCalcVo {
    orderCalcId: string,
    buId?: string,
    orderCalcNo: string,
    orderVersion: number,
    customerId?: string,
    orderGroupId: string,
    cutoffDate?: Date,
    previousUsageDate?: Date,
    orderFrequency?: number,
    orderStartDate?: Date,
    orderEndDate?: Date,
    targetStartDate?: Date,
    targetEndDate?: Date,
    forecastCount?: number,
    targetLeadtime?: number,
    status?: number,
    remark?: string,
    createdBy?: string,
    createdDate?: Date,
    updatedBy?: string,
    updatedDate?: Date,
    version?: number,
    usageVersion?: string,
    custUsageDate?: Date,
    bufferUsageType?: number,
    poGroupId?: string,
}

export interface OrderCalcVoJson {
    orderCalcId: string,
    buId?: string,
    orderCalcNo: string,
    orderVersion: number,
    customerId?: string,
    orderGroupId: string,
    cutoffDate?: string,
    previousUsageDate?: string,
    orderFrequency?: number,
    orderStartDate?: string,
    orderEndDate?: string,
    targetStartDate?: string,
    targetEndDate?: string,
    forecastCount?: number,
    targetLeadtime?: number,
    status?: number,
    remark?: string,
    createdBy?: string,
    createdDate?: number,
    updatedBy?: string,
    updatedDate?: number,
    version?: number,
    usageVersion?: string,
    custUsageDate?: string,
    bufferUsageType?: number,
    poGroupId?: string,
}

export const OrderCalcVoFromJson: Project<OrderCalcVoJson, OrderCalcVo> = json => ({
    ...json,
    cutoffDate: dateFromJson(json.cutoffDate),
    previousUsageDate: dateFromJson(json.previousUsageDate),
    orderStartDate: dateFromJson(json.orderStartDate),
    orderEndDate: dateFromJson(json.orderEndDate),
    targetStartDate: dateFromJson(json.targetStartDate),
    targetEndDate: dateFromJson(json.targetEndDate),
    createdDate: datetimeFromJson(json.createdDate),
    updatedDate: datetimeFromJson(json.updatedDate),
    custUsageDate: dateFromJson(json.custUsageDate),
})