import {Project} from "@rithe/utils"
import {dateFromJson} from "../../utils/deserializer"

export type SchedulesResult = ResultRequired & Partial<ResultOptional>
type SchedulesResultJson = ResultJsonRequired & Partial<ResultJsonOptional>
export const schedulesResultFromJson: Project<SchedulesResultJson, SchedulesResult> = json => ({
    ...json,
    dueDate: dateFromJson(json.dueDate),
    remindStartDate: dateFromJson(json.remindStartDate),
    remindEndDate: dateFromJson(json.remindEndDate),
    createdDate: dateFromJson(json.createdDate),
    updatedDate: dateFromJson(json.updatedDate),
})

interface ResultRequired extends ResultJsonRequired {

}

interface ResultOptional extends Omit<ResultJsonOptional, 'dueDate' | 'remindStartDate' | 'remindEndDate' | 'createdDate' | 'updatedDate'> {
    dueDate: Date,
    remindStartDate: Date,
    remindEndDate: Date,
    createdDate: Date,
    updatedDate: Date,
}

interface ResultJsonRequired {
}

interface ResultJsonOptional {
    todoId: number,
    fromUserId: number,
    toCbdsUid: number,
    toUserId: number,
    title: string,
    titleParam: string,
    description: string,
    descriptionParam: string,
    scheduleType: number,
    urgency: number,
    remindStartDate: string,
    remindEndDate: string,
    status: number,
    url: string,
    urlParam: string,
    createdBy: string,
    createdDate: string,
    updatedBy: string,
    updatedDate: string,
    version: number,
    importanceFlag: number,
    dueDate: string,
    taskCategory: number,
    todoType: number,
    urlKey: string,
    file: string,
    task: string,
    taskKey: string,
    recurrence: number,
}
