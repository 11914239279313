import { Project } from "@rithe/utils"
import { dateFromJson } from "../../utils/deserializer"
import { OrderCalcVo, OrderCalcVoFromJson, OrderCalcVoJson } from "./OrderCalcVo"
import { OrderGroupVo, orderGroupVoFromJson, OrderGroupVoJson } from "./OrderGroupVo"

export interface OrderCalculationListViewResult {
    orderTime: Date,
    orderGroup: OrderGroupVo,
    orderCalcs: OrderCalcVo[],
}

interface OrderCalculationListViewResultJson {
    orderTime: string,
    orderGroup: OrderGroupVoJson,
    orderCalcs: OrderCalcVoJson[],
}

export const OrderCalculationListViewResultFromJson: Project<OrderCalculationListViewResultJson, OrderCalculationListViewResult> = json => ({
    orderTime: dateFromJson(json.orderTime),
    orderGroup: orderGroupVoFromJson(json.orderGroup),
    orderCalcs: json.orderCalcs ? json.orderCalcs.map(OrderCalcVoFromJson) : [],
})