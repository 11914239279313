import { Arrays, Objects } from "@rithe/utils"
import React, { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useComMasterGetActiveCbds, useComMasterGetAllUom } from "../../../services/common/apis/commonMasterApi"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { TnmUom } from "../../../services/master/models/TnmUom"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { useGetAvailableSellers, useGetSpotOderDetail } from "../../../services/order/apis/SpotOrderApi"
import { AvailableSellerResult } from "../../../services/order/models/AvailableSellerResult"
import { PartsDetail, SpotOrderResult } from "../../../services/order/models/SpotOrderDetailResult"
import { addNumber, mulNumber } from "../../../utils/NumberUtil"
import { useMatch } from "../../../utils/useMatch"
import { OCS021PcUi } from "./OCS021PcUi"

export interface SpotOrderResultBasic extends SpotOrderResult {
    totalQty: number,
    totalAmount: number,
    totalNumberOfParts: number,
}

export const OCS021 = () => {
    const { path } = useMatch()
    const { orderId } = useParams() as any
    const mode = path === '/placecustorder-sowc/create' ? ScreenMode.CREATE : path === '/placecustorder-sowc/view-:orderId' ? ScreenMode.VIEW : path === '/placecustorder-sowc/edit-:orderId' ? ScreenMode.EDIT : orderId !== undefined ? ScreenMode.VIEW : ScreenMode.CREATE

    const [receiveDcList, setReceiveDcList] = useState<TnvCbds[]>([])
    const [basic, setBasic] = useState<SpotOrderResultBasic>({} as SpotOrderResultBasic)
    const [forecasts, setForecasts] = useState<PartsDetail[]>([] as PartsDetail[])
    const [inboundPlans, setInboundPlans] = useState<PartsDetail[]>([] as PartsDetail[])
    const [availableSellers, setAvailableSellers] = useState<AvailableSellerResult[]>([])
    const [uomList, setUomList] = useState<TnmUom[]>([])
    const [globalPartsId, setGlobalPartsId] = useState<number[]>([])
    const [viewNum, setViewNum] = useState<number>(0)
    const getAvailableSellers = useGetAvailableSellers()
    const getUomList = useComMasterGetAllUom()

    const splitDatas = useSplitDatas()
    const getCbds = useComMasterGetActiveCbds()
    const getData = useGetSpotOderDetail()

    const search = useCallback((orderId: number | null | undefined) => {
        if (orderId) {
            getData({ spotId: String(orderId) }, { silent: true }).then(result => {
                if (result) {
                    const { orderbasic, orderforecasts, orderInboundPlans } = splitDatas(result)
                    setBasic(orderbasic)
                    setForecasts(orderforecasts)
                    setInboundPlans(orderInboundPlans)
                    setViewNum(orderbasic.stepNo)
                    setGlobalPartsId(Arrays.distinct(orderforecasts.map(plan => (plan.globalPartsId))).sort().map(m => m))
                }
            })
        }
    }, [getData, splitDatas])

    const searchAva = useCallback((partsNoList: number[] | undefined, placeType: number | null | undefined, spotId: number | null | undefined) => {
        if (partsNoList) {
            getAvailableSellers({ globalPartsIdList: partsNoList, placeType: placeType, spotId: spotId }, { serialized: true, silent: true }).then(result => {
                if (result) {
                    setAvailableSellers(result ?? [])
                }
            })
        }

    }, [getAvailableSellers])

    useEffect(() => {
        getCbds({ types: [] }, { silent: true }).then(result => {
            setReceiveDcList(result || [])
        })

        search(orderId)
        getUomList(undefined, { silent: true }).then(result => {
            setUomList(result || [])
        })

    }, [getAvailableSellers, getCbds, getUomList, orderId, search])

    useEffect(() => {
        searchAva(globalPartsId, basic.placeType, orderId)
    }, [basic.placeType, searchAva, globalPartsId, orderId])

    return <OCS021PcUi
        mode={mode}
        search={search}
        receiveDcList={receiveDcList}
        basic={basic}
        setBasic={setBasic}
        forecasts={forecasts}
        setForecasts={setForecasts}
        inboundPlans={inboundPlans}
        setInboundPlans={setInboundPlans}
        searchAva={searchAva}
        availableSellers={availableSellers}
        setAvailableSellers={setAvailableSellers}
        uomList={uomList}
        viewNum={viewNum}
        setViewNum={setViewNum}
    />
}

export const useSplitDatas = () => {
    return useCallback((basic: SpotOrderResult) => {
        const { totPlaceSpotDetailEx } = basic
        const totalQty = totPlaceSpotDetailEx.reduce((v1, v2) => addNumber(v1, v2.orderQty ? v2.orderQty : 0), 0)
        const totalAmount = totPlaceSpotDetailEx.reduce((v1, v2) => addNumber(v1, (v2.orderQty && v2.buyingPrice ? mulNumber(v2.orderQty, v2.buyingPrice) : 0)), 0)
        const totalNumberOfParts = totPlaceSpotDetailEx.length
        const currency = totPlaceSpotDetailEx.length > 0 ? totPlaceSpotDetailEx[0].buyingCurrency : undefined

        const orderbasic: SpotOrderResultBasic = { ...basic, totalQty, totalAmount, totalNumberOfParts, currency }
        const orderforecasts: PartsDetail[] = totPlaceSpotDetailEx.map(parts => ({ ...parts }))
        const orderInboundPlans: PartsDetail[] = totPlaceSpotDetailEx.map(parts => ({ ...parts }))

        return { orderbasic: orderbasic, orderforecasts: orderforecasts, orderInboundPlans: orderInboundPlans }
    }, [])
}

export const useMergeDatas = () => {
    return useCallback((orderbasic: SpotOrderResultBasic, orderforecasts: PartsDetail[], orderInboundPlans: PartsDetail[]) => {
        const basic = Objects.delete(orderbasic, 'totalQty', 'totalAmount', 'totalNumberOfParts', 'currency') as SpotOrderResult
        return { ...basic, totPlaceSpotDetailEx: orderforecasts }
    }, [])
}