import React, { useCallback, useEffect, useState } from "react"
import { useGetContractList, useGetCreditLimitList, useGetInvoiceList } from "../../../services/dashboard/apis/dashboardApi"
import { AccountingFactor } from "../../../services/dashboard/models/AccountingDashboardFactor"
import { ContractResult, CreditLimitResult, InvoiceResult } from "../../../services/dashboard/models/AccountingDashboardResult"
import { DAS015PcUi } from "./DAS015PcUi"


export const DAS015 = () => {

    const [contract, setContract] = useState<ContractResult[]>([])
    const [invoices, setInvoices] = useState<InvoiceResult[]>([])
    const [limit, setLimit] = useState<CreditLimitResult[]>([])

    const getContractList = useGetContractList()
    const getInvoiceList = useGetInvoiceList()
    const getCreditLimitList = useGetCreditLimitList()

    const search = useCallback((filters: AccountingFactor) => {
        getCreditLimitList(filters, { silent: true, serialized: true }).then(result => {
            setLimit(result || [])
        })
        getInvoiceList(filters, { silent: true, serialized: true }).then(result => {
            setInvoices(result || [])
        })
    }, [getCreditLimitList, getInvoiceList])

    useEffect(() => {
        getContractList(undefined, { silent: true }).then(result => {
            setContract(result || [])
        })
        search({})
    }, [getContractList, search])

    return <DAS015PcUi
        search={search}
        data={invoices}
        limits={limit}
        contracts={contract}
    />
}
