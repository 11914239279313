import { Project } from "./Project"

const keep: Project<unknown, unknown> = value => value

const ignore: Project<unknown, undefined> = () => undefined

const ignoreIfNull = <T, R>(project: Project<T, R>): Project<T | undefined, R | undefined> => {
    return (t: T | undefined) => t === undefined || t === null ? undefined : project(t)
}

const array = <T, R>(project: Project<T, R>): Project<T[], R[]> => {
    return (arr: T[]) => arr.map(project)
}

export const Projects = {
    KEEP: keep,
    IGNORE: ignore,
    array,
    ignoreIfNull,
}