import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"

export type StockManagementMasterTransfer = Required & Partial<Optional>

type StockManagementMasterTransferJson = JsonRequired & Partial<JsonOptional>

export const stockManagementMasterTransferFromJson: Project<StockManagementMasterTransferJson, StockManagementMasterTransfer> = json => ({
    ...json,
    outboundFluctuation: json.outboundFluctuation === undefined ? '0%' : json.outboundFluctuation * 100 + "%",
    buildOutMonth: dateFromJson(json.buildOutMonth),
    lastPOMonth: dateFromJson(json.lastPOMonth),
    lastDeliveryDate: dateFromJson(json.lastDeliveryDate),
})

interface Required extends JsonRequired { }

interface Optional extends Omit<JsonOptional, 'outboundFluctuation' | 'buildOutMonth' | 'lastPOMonth' | 'lastDeliveryDate'> {
    outboundFluctuation: string,
    buildOutMonth: Date,
    lastPOMonth: Date,
    lastDeliveryDate: Date

}

interface JsonRequired {
    customerPartsId: number,
    partsNo: string,
}

interface JsonOptional {
    grouping: string,
    buyerCountry: string,
    buyerCode: string,
    sellerCountry: string,
    sellerCode: string,
    orderLot: number,
    spq: number,
    externalRefNo: string,
    buyerPartsNo: string,
    backNo: string,
    commonPartsNo: string,
    specialPartsType: string,
    minDays1: number,
    minDays2: number,
    minDays3: number,
    maxDays: number,
    minBoxes1: number,
    minBoxes2: number,
    minBoxes3: number,
    maxBoxes: number,
    inventoryBoxFlag: number,
    outboundFluctuation: number,
    onshippingDelayPat: number,
    deliveryCustPat: string,
    custDelayPat: number,
    custAdvancePat: number,
    customerStockFlag: number,
    alertType: number,

    buildOutMonth: string,
    lastPOMonth: string,
    lastDeliveryDate: string,
}