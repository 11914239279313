import { AxiosInstance } from 'axios'
import { ContractVo } from '../models/ContractVo'
import { GetAllContractVo } from '../models/GetAllContractVo'

const contractApi = {
    getAllContracts: function (axiosInstance: AxiosInstance, condition: GetAllContractVo) {
        return axiosInstance.post<ContractVo[]>('/lcbm-local-master-api/api/getAllContracts', condition)
    },
}

export default contractApi