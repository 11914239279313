import { TextField, useTheme } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { KeyTypeProvider, KeyTypeProviderProps } from "@rithe/data-grid";
import { KeyInlineEditorProps } from "@rithe/data-grid/dist/components/dataTypes/KeyInlineEditor";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import React from "react";


export const ClearableKeyTypeProvider = (props: KeyTypeProviderProps) => {
    return <KeyTypeProvider {...props} InlineEditor={KeyInlineEditor} />
}

export const KeyInlineEditor = (props: KeyInlineEditorProps) => {
    const { align, value, options, formatter, setValue } = props

    const theme = useTheme()
    return <div
        style={{ width: '100%' }}
        onClick={e => e.stopPropagation()}
    >
        <Autocomplete
            autoHighlight
            autoComplete
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            fullWidth
            value={value}
            options={options}
            getOptionLabel={option => formatter.format(option)}
            groupBy={formatter.group ? (option => formatter.group!(option)) : undefined}
            getOptionDisabled={formatter.disable ? (option => formatter.disable!(option)) : undefined}
            onChange={(_, newValue) => setValue(newValue)}
            renderInput={params => <TextField {...params} InputProps={{ ...params.InputProps, disableUnderline: false }} inputProps={{ ...params.inputProps, style: { textAlign: align, fontSize: theme.typography.body2.fontSize } }} />}
            renderOption={(option, { inputValue }) => {
                const label = formatter.format(option)
                const matches = match(label, inputValue)
                const parts = parse(label, matches)
                return <div>
                    {parts.map((part, index) => (
                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                            {part.text}
                        </span>
                    ))}
                </div>
            }}
        />
    </div>
}