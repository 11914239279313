import { Arrays } from "@rithe/utils"
import { useCallback, useEffect, useState } from "react"
import { useGetExternalTemplateList, useGetFunctionList } from "../../../services/integration/apis/externalDataApis"
import { useGetUploadedData } from "../../../services/integration/apis/myUploadApis"
import { TemplateStatus } from "../../../services/integration/enums/TemplateStatus"
import { ExternalDataFactor } from "../../../services/integration/models/ExternalDataFactor"
import { ExternalTemplateList } from "../../../services/integration/models/ExternalTemplateList"
import { FunctionList } from "../../../services/integration/models/FunctionList"
import { UploadedDataList } from "../../../services/integration/models/UploadedDataList"
import { INT020PcUi } from "./INT020PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000, } }

export const INT020 = () => {

    const [functionList, setFunctionList] = useState<FunctionList[]>([])
    const [categoryList, setCategoryList] = useState<string[]>(["CATEGORY"])
    const [templateList, setTemplateList] = useState<ExternalTemplateList[]>([])
    const [filters, setFilters] = useState<ExternalDataFactor>(defaultFilters)
    const [data, setData] = useState<UploadedDataList[]>([])
    const [tabId, setTabId] = useState<number>(0)

    const getFunctionList = useGetFunctionList()
    useEffect(() => {
        getFunctionList(undefined, { silent: true }).then(result => {
            setFunctionList(result ?? [])
            setCategoryList(Arrays.distinct(result.map(f => f.category!) ?? []))
        })
    }, [getFunctionList])

    const getTemplateList = useGetExternalTemplateList()
    const findTemplateList = useCallback((filters: ExternalDataFactor) => {
        if (filters.function !== undefined && filters.function !== null) {
            getTemplateList({ function: filters.function }, { silent: true }).then((result: ExternalTemplateList[]) => {
                setTemplateList(result.filter(data => data.status === TemplateStatus.CONFIRMED) ?? [])
            })
        } else {
            setTemplateList([])
        }
    }, [getTemplateList])

    const getUploadedData = useGetUploadedData()

    const search = useCallback(() => {
        if (filters.externalTemplateId) {
            getUploadedData({ templateId: filters.externalTemplateId }, { silent: true, serialized: true }).then(result => {
                setData(result ?? [])
            })
        } else {
            setData([])
        }
    }, [filters.externalTemplateId, getUploadedData])

    useEffect(() => {
        search()
    }, [search])

    return <INT020PcUi
        functionList={functionList}
        categoryList={categoryList}
        templateList={templateList}
        findTemplateList={findTemplateList}
        filters={filters}
        setFilters={setFilters}
        data={data}
        tabId={tabId}
        setTabId={setTabId}
        search={search}
    />
}