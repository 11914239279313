import { makeStyles } from "@material-ui/core"
import React, { useCallback, useEffect, useRef } from "react"
import { Step } from "./Step"
import { useStepContext } from "./StepContext"

const DEADBAND = 5

interface StepperProps {
    activeOffset?: number,
    target?: HTMLElement | Window,
}

export function Stepper(props: StepperProps) {
    const { activeOffset = 88, target = window } = props

    const { targetAnchorEl, setTargetAnchorEl, setActiveAnchorEl, stepDescriptors } = useStepContext()

    const targetAnchorElRef = useRef<HTMLElement | null>(null)
    const stopScrollTopRef = useRef(0)

    useEffect(() => {
        if (targetAnchorEl) {
            setActiveAnchorEl(targetAnchorEl)
            targetAnchorElRef.current = targetAnchorEl
            // targetAnchorEl.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
            target.scrollTo({ top: targetAnchorEl.offsetTop - 96, behavior: 'smooth' })
        }
    }, [setActiveAnchorEl, target, targetAnchorEl])

    const onScrollStart = useCallback(() => {
    }, [])
    const onScroll = useCallback(() => {
        const scrollTop = target instanceof Window ? target.pageYOffset : target.scrollTop
        if (Math.abs(scrollTop - stopScrollTopRef.current) > DEADBAND && !targetAnchorEl) {
            setActiveAnchorEl(detectActiveAnchorEl(target, stepDescriptors.map(({ anchorEl }) => anchorEl), activeOffset))
        }
    }, [activeOffset, setActiveAnchorEl, stepDescriptors, target, targetAnchorEl])
    const onScrollStop = useCallback(() => {
        if (targetAnchorEl && targetAnchorEl === targetAnchorElRef.current) {
            if (!anchorElInViewport(target, targetAnchorEl)) {
                setActiveAnchorEl(detectActiveAnchorEl(target, stepDescriptors.map(({ anchorEl }) => anchorEl), activeOffset))
            }
            setTargetAnchorEl(null)
            targetAnchorElRef.current = null
        }
        const scrollTop = target instanceof Window ? target.pageYOffset : target.scrollTop
        if (Math.abs(scrollTop - stopScrollTopRef.current) > DEADBAND) {
            stopScrollTopRef.current = scrollTop
        }
    }, [activeOffset, setActiveAnchorEl, setTargetAnchorEl, stepDescriptors, target, targetAnchorEl])

    useEffect(() => {
        let scrolling = false, animationId = 0
        const listener = () => {
            scrolling || onScrollStart()
            animationId && cancelAnimationFrame(animationId)

            scrolling = true
            onScroll()

            requestAnimationFrame(() => {
                animationId = requestAnimationFrame(() => {
                    scrolling = false
                    animationId = 0
                    onScrollStop()
                })
            })
        }
        target.addEventListener('scroll', listener)
        return () => target.removeEventListener('scroll', listener)
    }, [onScroll, onScrollStart, onScrollStop, setActiveAnchorEl, target])

    const styles = useStyles()
    return <div className={styles.root}>
        {stepDescriptors.map((descriptor, index) => {
            return <React.Fragment key={index}>
                {index > 0 && <div className={styles.dashed} />}
                <Step {...descriptor} />
            </React.Fragment>
        })}
    </div>
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    dashed: {
        width: theme.spacing(2.5),
        height: 2,
        background: `linear-gradient(90deg, ${theme.palette.divider} 7px, transparent 7px 10px)`,
        backgroundSize: '10px 2px',
        backgroundPositionX: -3,
    },
}))

function detectActiveAnchorEl(target: HTMLElement | Window, anchorEls: HTMLElement[], activeOffset: number) {
    const targetTop = target instanceof Window ? 0 : target.getBoundingClientRect().top
    return anchorEls.map(anchorEl => ({ anchorEl, offset: anchorEl.getBoundingClientRect().top - targetTop } as { anchorEl: HTMLElement | null, offset: number }))
        .filter(({ offset }) => offset <= activeOffset)
        .reduce((a, b) => a.offset < b.offset ? b : a, { anchorEl: null, offset: -Number.MAX_VALUE })
        .anchorEl
}

function anchorElInViewport(target: HTMLElement | Window, anchorEl: HTMLElement) {
    const { top: targetTop, bottom: targetBottom } = target instanceof Window ? { top: 0, bottom: document.documentElement.clientHeight } : target.getBoundingClientRect()
    const { top: anchorTop, bottom: anchorBottom } = anchorEl.getBoundingClientRect()
    return targetTop <= anchorTop && targetBottom >= anchorBottom
}

