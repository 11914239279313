import { Input, Select, Tooltip, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { EntryInputProps, FormContext } from "@rithe/form";
import { useCallback, useContext, useMemo } from "react";


export function EntryInput(props: EntryInputProps) {
    const {
        field,
        value,
        setValue,
        placeholder,
        readonly,
        disabled,
        entries,
    } = props

    const options = useMemo(() => entries.map(([k]) => k), [entries])
    const getOptionLabel = useCallback((value: unknown) => {
        const entry = entries.find(([k]) => k === value)
        return entry ? entry[1] : ''
    }, [entries])

    const { entryInputAutocompleteProps, entryInputSelectProps } = useContext(FormContext)
    return entries.length === 0 ? <Select
        disableUnderline
        {...entryInputSelectProps}
        name={field}
        value=""
        placeholder={placeholder}
        readOnly={readonly}
        disabled={disabled}
    /> : <Autocomplete
        autoHighlight
        autoComplete
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        disableClearable
        fullWidth
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        renderInput={({ InputProps, inputProps, InputLabelProps, ...restProps }) => {
            const input = <Input
                disableUnderline
                {...restProps}
                {...InputProps}
                inputProps={{ ...inputProps }}
                name={field}
                readOnly={readonly}
                disabled={readonly ? false : disabled}
            />
            return value === null || value === undefined ? input : <Tooltip title={<Typography variant="body2">{getOptionLabel(value)}</Typography>}>{input}</Tooltip>
        }}
        {...entryInputAutocompleteProps}
        value={value ?? null}
        placeholder={placeholder}
        disabled={disabled || readonly}
        options={options}
        getOptionLabel={getOptionLabel}
        onChange={(_, value) => setValue(value)}
    />
}