import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { Projects } from "../../../utils/Projects"
import { TnmCalendar, tnmCalendarFromJson } from "../../master/models/TnmCalendar"
import { TnmCompany, tnmCompanyFromJson } from "../../master/models/TnmCompany"
import { TnmContainerType, tnmContainerTypeFromJson } from "../../master/models/TnmContainerType"
import { TnmCurrency, tnmCurrencyFromJson } from "../../master/models/TnmCurrency"
import { TnmCustomer, tnmCustomerFromJson } from "../../master/models/TnmCustomer"
import { TnmDistributionCenter, tnmDistributionCenterFromJson } from "../../master/models/TnmDistributionCenter"
import { TnmIncoterms, tnmIncotermsFromJson } from "../../master/models/TnmIncoterms"
import { TnmPackageType, tnmPackageTypeFromJson } from "../../master/models/TnmPackageType"
import { TnmPaymentTerms, tnmPaymentTermsFromJson } from "../../master/models/TnmPaymentTerms"
import { TnmPort, tnmPortFromJson } from "../../master/models/TnmPort"
import { TnmRegion, tnmRegionFromJson } from "../../master/models/TnmRegion"
import { TnmSettingCode, tnmSettingCodeFromJson } from "../../master/models/TnmSettingCode"
import { TnmShippingRoute, tnmShippingRouteFromJson } from "../../master/models/TnmShippingRoute"
import { TnmState, tnmStateFromJson } from "../../master/models/TnmState"
import { TnmUom, tnmUomFromJson } from "../../master/models/TnmUom"
import { TnvCbds, tnvCbdsFromJson } from "../../master/models/TnvCbds"

export const useGetCalendarByActive = () => {
    const url = '/lcbm-system-master-api/api/calendar/getByActive'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(tnmCalendarFromJson), [])
    return usePost<undefined, TnmCalendar[]>(url, payloadProject, resultProject)
}

export const useGetCalendarByActiveAndCompanyId = () => {
    const url = '/lcbm-system-master-api/api/calendar/getByActiveAndCompanyId'
    const payloadProject = useCallback((obj: { companyId: number }) => obj.companyId, [])
    const resultProject = useCallback(Projects.array(tnmCalendarFromJson), [])
    return usePost<{ companyId: number }, TnmCalendar[]>(url, payloadProject, resultProject)
}

export const useComMasterGetAllDc = () => {
    const url = '/lcbm-system-master-api/api/dc/getAll'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(tnmDistributionCenterFromJson), [])
    return usePost<undefined, TnmDistributionCenter[]>(url, payloadProject, resultProject)
}

export const useComMasterGetActiveDc = () => {
    const url = '/lcbm-system-master-api/api/dc/getActive'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(tnmDistributionCenterFromJson), [])
    return usePost<undefined, TnmDistributionCenter[]>(url, payloadProject, resultProject)
}

export const useComMasterGetActiveCustomer = () => {
    const url = '/lcbm-system-master-api/api/customer/getActive'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(tnmCustomerFromJson), [])
    return usePost<undefined, TnmCustomer[]>(url, payloadProject, resultProject)
}

export const useComMasterGetAllCbds = () => {
    const url = '/lcbm-system-master-api/api/getAllCompanyByType'
    const payloadProject = useCallback((obj: { types: number[] }) => obj.types, [])
    const resultProject = useCallback(Projects.array(tnvCbdsFromJson), [])
    return usePost<{ types: number[] }, TnvCbds[]>(url, payloadProject, resultProject)
}

export const useComMasterGetActiveCbds = () => {
    const url = '/lcbm-system-master-api/api/getActiveCompanyByType'
    const payloadProject = useCallback((obj: { types: number[] }) => obj.types, [])
    const resultProject = useCallback(Projects.array(tnvCbdsFromJson), [])
    return usePost<{ types: number[] }, TnvCbds[]>(url, payloadProject, resultProject)
}

export const useComMasterGetAllCompany = () => {
    const url = '/lcbm-system-master-api/api/company/getAll'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(tnmCompanyFromJson), [])
    return usePost<undefined, TnmCompany[]>(url, payloadProject, resultProject)
}

export const useComMasterGetAllCompanyByActive = () => {
    const url = '/lcbm-system-master-api/api/company/getAllAcitve'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(tnmCompanyFromJson), [])
    return usePost<undefined, TnmCompany[]>(url, payloadProject, resultProject)
}

// region
export const useComMasterGetAllRegion = () => {
    const url = '/lcbm-system-master-api/api/getAllRegion'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(tnmRegionFromJson), [])
    return usePost<undefined, TnmRegion[]>(url, payloadProject, resultProject)
}

export const useGetPaymentTermsByBuId = () => {
    const url = '/lcbm-local-master-api/api/payment/getByBu'
    const payloadProject = useCallback((obj: { buId: number }) => obj.buId, [])
    const resultProject = useCallback(Projects.array(tnmPaymentTermsFromJson), [])
    return usePost<{ buId: number }, TnmPaymentTerms[]>(url, payloadProject, resultProject)
}

export const useGetPaymentTermsBySupplierId = () => {
    const url = '/lcbm-local-master-api/api/payment/getBySupplier'
    const payloadProject = useCallback((obj: { supplierId: number }) => obj.supplierId, [])
    const resultProject = useCallback(Projects.array(tnmPaymentTermsFromJson), [])
    return usePost<{ supplierId: number }, TnmPaymentTerms[]>(url, payloadProject, resultProject)
}

// port
export const useComMasterGetAllPort = () => {
    // const url = '/roe-api/com/master/port/getAll';
    const url = '/lcbm-system-master-api/api/port/getAll'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(tnmPortFromJson), [])
    return usePost<undefined, TnmPort[]>(url, payloadProject, resultProject)
}

export const useComMasterGetActivePort = () => {
    const url = '/lcbm-system-master-api/api/port/getActive'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(tnmPortFromJson), [])
    return usePost<undefined, TnmPort[]>(url, payloadProject, resultProject)
}

// uom 
export const useComMasterGetAllUom = () => {
    // const url = '/roe-api/com/master/uom/getAll';
    const url = '/lcbm-system-master-api/api/uom/getAll'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(tnmUomFromJson), [])
    return usePost<undefined, TnmUom[]>(url, payloadProject, resultProject)
}

// currency 
export const useComMasterGetAllCurrency = () => {
    // const url = '/roe-api/com/master/currency/getAll';
    const url = '/lcbm-system-master-api/api/currency/getAll'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(tnmCurrencyFromJson), [])
    return usePost<undefined, TnmCurrency[]>(url, payloadProject, resultProject)
}

// containerpe
export const useComMasterGetAllConttype = () => {
    // const url = '/roe-api/com/master/conttype/getAll';
    const url = '/lcbm-system-master-api/api/containerType/getAll'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(tnmContainerTypeFromJson), [])
    return usePost<undefined, TnmContainerType[]>(url, payloadProject, resultProject)
}

export const useComMasterGetActiveConttype = () => {
    const url = '/lcbm-system-master-api/api/containerType/getActive'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(tnmContainerTypeFromJson), [])
    return usePost<undefined, TnmContainerType[]>(url, payloadProject, resultProject)
}

// packageType
export const useComMasterGetAllPkgtype = () => {
    // const url = '/roe-api/com/master/pkgtype/getAll';
    const url = '/lcbm-system-master-api/api/packageType/getAll'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(tnmPackageTypeFromJson), [])
    return usePost<undefined, TnmPackageType[]>(url, payloadProject, resultProject)
}

// incoterms
export const useComMasterGetAllIncoterms = () => {
    const url = '/lcbm-system-master-api/api/incoterms/getAll'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(tnmIncotermsFromJson), [])
    return usePost<undefined, TnmIncoterms[]>(url, payloadProject, resultProject)
}

export const useComMasterGetActiveShippingRoute = () => {
    const url = '/lcbm-local-master-api/api/shippingRoute/ByActive'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(tnmShippingRouteFromJson), [])
    return usePost<undefined, TnmShippingRoute[]>(url, payloadProject, resultProject)
}


// state
export const useComMasterGetStateByRegion = () => {
    // const url = '/roe-api/com/master/state/getAll';
    const url = '/lcbm-system-master-api/api/state/getAll'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(tnmStateFromJson), [])
    return usePost<undefined, TnmState[]>(url, payloadProject, resultProject)
}

export const useGetZoneList = () => {
    const url = '/lcbm-system-master-api/timezone/getZoneList'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(json => json, [])
    return usePost<undefined, { first: string, second: string }[]>(url, payloadProject, resultProject)
}

export const useGetBuSettingList = () => {
    const url = '/lcbm-system-master-api/api/setting/busetting'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(tnmSettingCodeFromJson), [])
    return usePost<undefined, TnmSettingCode[]>(url, payloadProject, resultProject)
}

export const useGetDcSettingList = () => {
    const url = '/lcbm-system-master-api/api/setting/dcsetting'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(tnmSettingCodeFromJson), [])
    return usePost<undefined, TnmSettingCode[]>(url, payloadProject, resultProject)
}

export const useGetSupplierSettingList = () => {
    const url = '/lcbm-system-master-api/api/setting/suppsetting'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(tnmSettingCodeFromJson), [])
    return usePost<undefined, TnmSettingCode[]>(url, payloadProject, resultProject)
}

export const useGetCustomerSettingList = () => {
    const url = '/lcbm-system-master-api/api/setting/custsetting'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(tnmSettingCodeFromJson), [])
    return usePost<undefined, TnmSettingCode[]>(url, payloadProject, resultProject)
}