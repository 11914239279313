import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Row, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { DateRangeItem, StringItem } from "@rithe/form"
import { Arrays, Records } from "@rithe/utils"
import React, { useCallback, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { EditRedirectRowAction } from "../../../components/DataGrid/rowActions/EditRedirectRowAction"
import { SubmitCallbackRowAction } from "../../../components/DataGrid/rowActions/SubmitCallbackRowAction"
import { ViewRedirectRowAction } from "../../../components/DataGrid/rowActions/ViewRedirectRowAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { View } from "../../../components/View/View"
import { useFunctionStore } from "../../../Root"
import { useIssueCompanyInfo } from "../../../services/master/apis/companyApi"
import { CustomerUsageTransfer } from "../../../services/smt/models/CustomerUsageTransfer"
import { CustomerUsageView } from "../../../services/smt/models/CustomerUsageView"
import { applicationActions } from "../../Application/applicationSlice"

interface CSUHSPcUi110Props {
    filters: CustomerUsageView,
    setFilters: React.Dispatch<React.SetStateAction<CustomerUsageView>>,
    data: CustomerUsageTransfer[],
    totalCount: number,
    search: (filters: CustomerUsageView) => void,
}

export const CSUHS110PcUi = (props: CSUHSPcUi110Props) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = (props: CSUHSPcUi110Props) => {
    const { filters, data, setFilters, search } = props
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])

    const columns = useMemo(() => {
        const defaultColumns = [
            { field: 'modelNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'modelNo' }), width: 200 },
            { field: 'katashiki', dataTypeName: 'string', title: intl.formatMessage({ id: 'katashiki' }), width: 200 },
            { field: 'customerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerCode' }), width: 200 },
            { field: 'grade', dataTypeName: 'string', title: intl.formatMessage({ id: 'grade' }), width: 200 },
            { field: 'level1PartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'level1PartsNo' }), width: 200 },
            { field: 'updatedDate', dataTypeName: 'string', title: intl.formatMessage({ id: 'updatedDate' }), width: 200 },
            { field: 'bomId', dataTypeName: 'string', title: intl.formatMessage({ id: 'bomId' }), width: 200 },
            { field: 'planEndDate', dataTypeName: 'string', title: intl.formatMessage({ id: 'planEndDate' }), width: 200 },
            { field: 'planQty', dataTypeName: 'string', title: intl.formatMessage({ id: 'planQty' }), width: 200 },
            { field: 'planStartDate', dataTypeName: 'string', title: intl.formatMessage({ id: 'planStartDate' }), width: 200 },
        ]
        const monthColumns = (data[0]?.monthList ?? []).map((month, index) => ({ field: month, dataTypeName: 'number', title: month, width: 150, getCellValue: getPlanData(index) }))
        return Arrays.concat(defaultColumns, monthColumns)
    }, [data, intl])
    const getRowId = useCallback((row: any) => row.bomId, [])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search }), [filters, setFilters, search])
    const actionProps1 = useMemo(() => ({ search, filters }), [filters, search])
    const display1 = useCallback((tableRow: TableRow) => tableRow.row?.tempCompanyId !== null && tableRow.row?.tempCompanyId !== undefined, [])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <RowActionProvider name="view" Action={ViewRowAction} />
        <RowActionProvider name="edit" Action={EditRowAction} />
        <RowActionProvider name="submit" Action={SubmitRowAction} actionProps={actionProps1} display={display1} />
        <ColumnFreeze />
        <ColumnVisibility defaultHiddenFields={['planQty', 'updatedDateTime', 'planStartDate', 'planEndDate', 'monthlyQtyMap', 'bomId']} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={124} />
    </DataGrid>
}

const FilterItem = (props: {
    filters: CustomerUsageView,
    setFilters: React.Dispatch<React.SetStateAction<CustomerUsageView>>,
    search: (filters: CustomerUsageView) => void
}) => {
    const { filters, setFilters, search } = props

    const planDateGetValue = useCallback((filters: CustomerUsageView) => {
        return [filters.planStartDate ?? null, filters.planEndDate ?? null]
    }, [])

    const planDateMapValue = useCallback((filters: CustomerUsageView, value: any) => {
        return { ...filters ?? {}, planStartDate: value[0], planEndDate: value[1] }
    }, [])

    const updatedDateGetValue = useCallback((filters: CustomerUsageView) => {
        return [filters.updatedDateStart ?? null, filters.updatedDateEnd ?? null]
    }, [])

    const updatedDateMapValue = useCallback((filters: CustomerUsageView, value: any) => {
        return { ...filters ?? {}, updatedDateStart: value[0], updatedDateEnd: value[1] }
    }, [])


    const clear = useCallback((filters: CustomerUsageView) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: CustomerUsageView) => {
        return [
            filters.modelNo,
            filters.katashiki,
            filters.grade,
            filters.planStartDate || filters.planEndDate,
            filters.updatedDateEnd || filters.updatedDateEnd,
        ].filter(f => f != null && f !== undefined).length
    }, [])

    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter} >
        <StringItem field="modelNo" label={intl.formatMessage({ id: 'field.modelNo' })} />
        <StringItem field="katashiki" label={intl.formatMessage({ id: 'katashiki' })} />
        <StringItem field="grade" label={intl.formatMessage({ id: 'grade' })} />
        <DateRangeItem field="planDate" label={intl.formatMessage({ id: 'planDate' })} getValue={planDateGetValue} mapValue={planDateMapValue} />
        <DateRangeItem field="updatedDate" label={intl.formatMessage({ id: 'updatedDate' })} getValue={updatedDateGetValue} mapValue={updatedDateMapValue} />

    </FilterToolbarItem>
}


const getPlanData = (index: number) => {
    return (row: Row) => {
        return row.monthlyQtyList[index]
    }
}

const ViewRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/company/view-${tableRow.rowId}`, [])
    return <ViewRedirectRowAction tableRow={tableRow} path={path} />
}

const EditRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/company/edit-${tableRow.rowId}`, [])
    return <EditRedirectRowAction tableRow={tableRow} path={path} />
}

const SubmitRowAction = ({ tableRow, search, filters }: DataGridRowActionProps & { search: (filters: CustomerUsageView) => void, filters: CustomerUsageView }) => {
    const submitApi = useIssueCompanyInfo()
    const [disabled, setDisabled] = useState<boolean>(false)
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'submit' }), [intl])
    const callback = useCallback((tableRow: TableRow) => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            submitApi({ tempCompanyId: tableRow.row?.tempCompanyId }, { serialized: true }).then(() => search(filters)).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
	        messages: { code: 'c0001', args: [title] },
            actions:[{
                label: 'CANCEL'
            },{
                functionId,
                label:'CONFIRM',
            }]
        }))
    }, [dispatch, filters, functionStore, search, submitApi, title])
    return <SubmitCallbackRowAction tableRow={tableRow} callback={callback} disabled={disabled}/>
}