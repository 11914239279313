import { Form, Message, NumberItem, StringItem } from "@rithe/form"
import React, { SetStateAction, useCallback, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { SaveCallbackViewAction } from "../../../components/Action/SaveCallbackViewAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { CodeItem } from "../../../components/Form/CodeItem"
import { View } from "../../../components/View/View"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { useSavePaymentTermsDetail } from "../../../services/master/apis/paymentTermsApi"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { TnmPaymentTerms } from "../../../services/master/models/TnmPaymentTerms"
import { useFieldChecker, useFormValidater } from "../../../utils/ValidatorUtils"

interface MLS111PcUiProps {
    mode: ScreenMode,
    path: string,
    data: TnmPaymentTerms,
    setData: React.Dispatch<SetStateAction<TnmPaymentTerms>>,
    search: (paymentTermsId: number) => void,
}

export const MLS111PcUi = (props: MLS111PcUiProps) => {
    const { mode, path, data, setData, search } = props
    const [messages, setMessages] = useState<Message[]>([])

    const actions = mode !== ScreenMode.VIEW ? [<SaveAction mode={mode} path={path} data={data} setData={setData} search={search} setMessages={setMessages} />] : []
    return <View actions={actions}>
        <SectionCard>
            <SectionCardContent>
                <DataFrom mode={mode} path={path} data={data} setData={setData} messages={messages} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataFrom = ({ mode, data, setData, messages }: {
    mode: ScreenMode,
    path: string,
    data: TnmPaymentTerms,
    setData: React.Dispatch<SetStateAction<TnmPaymentTerms>>,
    messages: Message[]
}) => {
    const intl = useIntl()
    const fields = getFormCheckFields(true)
    const filedCheck = useFieldChecker(fields)
    const readonly = mode === ScreenMode.VIEW

    return <Form data={data} setData={setData} labelDisplay="block" helperDisplay="tooltip" ensure={filedCheck} messages={messages} columnCount={2}>

        <StringItem field="paymentTermsCode" required readonly={readonly} label={intl.formatMessage({ id: 'field.paymentTermsCode' })} />
        <StringItem field="description" required readonly={readonly} label={intl.formatMessage({ id: 'field.description' })} />

        <CodeItem field="termsType" required readonly={readonly} label={intl.formatMessage({ id: 'field.termsTypes' })} code={CodeCategory.TermsType} />
        <NumberItem field="fromMonth" required readonly={readonly} label={intl.formatMessage({ id: 'field.fromMonth' })} />

        <NumberItem field="days" required readonly={readonly} label={intl.formatMessage({ id: 'field.days' })} />
        <NumberItem field="fromDay" required readonly={readonly} label={intl.formatMessage({ id: 'field.fromDay' })} />
    </Form>
}

const SaveAction = ({ mode, path, data, setData, search, setMessages }: {
    mode: ScreenMode,
    path: string,
    data: TnmPaymentTerms,
    search: (paymentTermsId: number) => void,
    setData: React.Dispatch<SetStateAction<TnmPaymentTerms>>,
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>
}) => {
    const navigate = useNavigate()
    const saveActive = useSavePaymentTermsDetail()
    const fields = getFormCheckFields(true)
    const formValidate = useFormValidater(setMessages, fields)
    const [disabled, setDisabled] = useState<boolean>(false)
    const onclickSave = useCallback(() => {
        if (formValidate(data)) {
            setDisabled(true)
            setMessages([])
            saveActive({ ...data }, { serialized: true }).then(result => {
                setData({ ...result })
                if (mode === ScreenMode.CREATE) {
                    if (path === '/receivedRequest/addNewPart-:requestNo/paymentCreate') {
                        navigate(-1)
                    } else {
                        navigate(`/payment/edit-${result?.paymentTermsId}`)
                    }
                } else {
                    search(result?.paymentTermsId)
                }
            }).finally(() => {
                setDisabled(false)
            })
        }
    }, [data, formValidate, navigate, mode, path, saveActive, search, setData, setMessages])
    return <SaveCallbackViewAction access="MARS.MLS111.SAVE" callback={onclickSave} disabled={disabled}/>
}

const getFormCheckFields = (required: boolean) => {
    return {
        paymentTermsCode: { labelId: 'field.paymentTermsCode', required: required, length: { max: 20 } },
        description: { labelId: 'field.description', required: required, length: { max: 255 } },
        termsType: { labelId: 'field.termsTypes', required: required },
        fromMonth: { labelId: 'field.fromMonth', required: required, length: { max: 2, integer: 2, scale: 0 }, compare: { min: 0 } },
        days: { labelId: 'field.days', required: required, length: { max: 3, integer: 3, scale: 0 }, compare: { min: 0 } },
        fromDay: { labelId: 'field.fromDay', required: required, length: { max: 3, integer: 3, scale: 0 }, compare: { min: 0 } },
    }
}