import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, KeyTypeProvider, PaginationLayout, Paging, Row, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { StringItem } from '@rithe/form'
import { Objects, Records } from "@rithe/utils"
import React, { useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useFunctionStore } from "../../../Root"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { EditRedirectRowAction } from "../../../components/DataGrid/rowActions/EditRedirectRowAction"
import { SubmitCallbackRowAction } from "../../../components/DataGrid/rowActions/SubmitCallbackRowAction"
import { ViewRedirectRowAction } from "../../../components/DataGrid/rowActions/ViewRedirectRowAction"
import { WithdrawCallbackRowAction } from "../../../components/DataGrid/rowActions/WithdrawCallbackRowAction"
import { CreateRedirectToolbarAction } from "../../../components/DataGrid/toolbarActions/CreateRedirectToolbarAction"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { UploadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/UploadGroupedToolbarAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { ActiveFlagTypeProvider } from "../../../components/DataGrid/typeProviders/ActiveFlagTypeProvider"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { useIssueCompanyInfo, useWithdrawCompanyInfo } from "../../../services/master/apis/companyApi"
import { useDownloadCompanyMasterByCompanyId, useDownloadCompanyMasterByFilter } from "../../../services/master/apis/masterDownloadApi"
import { useUploadMasterCompanyMaster } from "../../../services/master/apis/masterUploadApi"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { CompanyListFactor } from "../../../services/master/models/CompanyListFactor"
import { TnmCompanyTemp } from "../../../services/master/models/TnmCompanyTemp"
import { useGetPartyCompanyId } from "../../../utils/ApplicationUtils"
import { useIsPowerUser } from "../../../utils/useIsPowerUser"
import { applicationActions } from "../../Application/applicationSlice"

interface MLS010PcUiProps {
    path: string,
    filters: CompanyListFactor,
    setFilters: React.Dispatch<React.SetStateAction<CompanyListFactor>>,
    data: TnmCompanyTemp[],
    totalCount: number,
    search: (filters: CompanyListFactor) => void,
    timeZoneMap: Record<string, string>,
}

export const MLS010PcUi = (props: MLS010PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({ filters, setFilters, data, search, path, timeZoneMap }: MLS010PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<string[]>([])
    const getStatusLabel = useCallback((row: Row) => row.tempCompanyId !== undefined && row.tempCompanyId !== null ? intl.formatMessage({ id: 'editing' }) : '', [intl])
    const partyCompanyId = useGetPartyCompanyId()

    const isPowerUser = useIsPowerUser()

    const columns = useMemo(() => [
        { field: 'companyCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.companyCode' }), width: 180 },
        { field: 'companyName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.companyName' }), width: 300 },
        { field: 'companyShortCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.companyShortCode' }), width: 300 },
        { field: 'regionCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.regionCode' }), width: 160 },
        { field: 'address1', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.address1' }), width: 300 },
        { field: 'address2', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.address2' }), width: 300 },
        { field: 'address3', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.address3' }), width: 300 },
        { field: 'address4', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.address4' }), width: 300 },
        { field: 'contact1', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.companyContact1' }), width: 300 },
        { field: 'fax1', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.companyFax1' }), width: 200 },
        { field: 'telephone1', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.companyTelephone1' }), width: 200 },
        { field: 'email1', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.companyEmail1' }), width: 300 },
        { field: 'contact2', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.companyContact2' }), width: 300 },
        { field: 'fax2', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.companyFax2' }), width: 200 },
        { field: 'telephone2', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.companyTelephone2' }), width: 200 },
        { field: 'email2', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.companyEmail2' }), width: 300 },
        { field: 'postalCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.postalCode' }), width: 160 },
        { field: 'timeZone', dataTypeName: 'timezone', title: intl.formatMessage({ id: 'field.timeZone' }), width: 300 },
        { field: 'activeFlag', dataTypeName: CodeCategory.ActiveFlag, title: intl.formatMessage({ id: 'field.activeFlag' }), width: 150 },
        { field: 'status', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.status' }), width: 200, getCellValue: getStatusLabel },
        { field: 'createdDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.createdDate' }), width: 170 },
        { field: 'updatedDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.updatedDate' }), width: 170 },
    ], [getStatusLabel, intl])

    const getRowId = useCallback((row: any) => row.companyCode, [])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search }), [filters, setFilters, search])
    const actionProps1 = useMemo(() => ({ data, selections, filters }), [data, filters, selections])
    const actionProps2 = useMemo(() => ({ search, filters }), [filters, search])
    const ViewOrEditAction = useMemo(() => ({ path }), [path])
    const viewable = useCallback((tableRow: TableRow) => tableRow.row?.companyId !== null && tableRow.row?.companyId !== undefined, [])
    const editable = useCallback((tableRow: TableRow) => isPowerUser || tableRow.row?.companyId === partyCompanyId, [isPowerUser, partyCompanyId])
    const display2 = useCallback((tableRow: TableRow) => (isPowerUser || tableRow.row?.companyId === partyCompanyId) && tableRow.row?.tempCompanyId !== null && tableRow.row?.tempCompanyId !== undefined, [isPowerUser, partyCompanyId])


    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <TimeZoneTypeProvider timeZoneMap={timeZoneMap} />
        <ActiveFlagTypeProvider />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={CreateAction} actionProps={ViewOrEditAction} />
        <ToolbarActionProvider Action={UploadAction} actionProps={actionProps2} />
        <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps1} />
        <RowActionProvider name="view" Action={ViewRowAction} display={viewable} actionProps={ViewOrEditAction} />
        <RowActionProvider name="edit" Action={EditRowAction} display={editable} actionProps={ViewOrEditAction} />
        <RowActionProvider name="submit" Action={SubmitRowAction} actionProps={actionProps2} display={display2} />
        <RowActionProvider name="Withdraw" Action={WithdrawRowAction} actionProps={actionProps2} display={display2} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['timeZone', 'address1', 'address2', 'address3', 'address4', 'contact1', 'fax1', 'telephone1', 'email1', 'contact2', 'fax2', 'telephone2', 'email2', 'fileName', 'status']}
            columnSettings={{
                companyCode: { disableUserControl: true },
                companyName: { disableUserControl: true },
            }}
            ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={160} />
    </DataGrid>
}

const TimeZoneTypeProvider = (props: {
    timeZoneMap: Record<string, string>
}) => {
    const format = useCallback((value: any) => {
        const display = props.timeZoneMap[value]
        return display === undefined ? value : display
    }, [props.timeZoneMap])
    const formatter = useMemo(() => ({
        format
    }), [format])
    return <KeyTypeProvider name="timezone" formatter={formatter} />
}

const FilterItem = (props: {
    filters: CompanyListFactor,
    setFilters: React.Dispatch<React.SetStateAction<CompanyListFactor>>,
    search: (filters: CompanyListFactor) => void
}) => {
    const { filters, setFilters, search } = props
    const clear = useCallback((filters: CompanyListFactor) => {
        return { page: filters.page }
    }, [])
    const filterCounter = useCallback((filters: CompanyListFactor) => {
        return [
            filters.companyCode,
            filters.companyName,
            filters.companyShortCode,
            filters.regionCode,
            filters.createdDateStart || filters.createdDateEnd,
            filters.updatedDateStart || filters.updatedDateEnd,
            filters.statusList
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()
    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="companyCode" label={intl.formatMessage({ id: 'field.companyCode' })} />
        <StringItem field="companyName" label={intl.formatMessage({ id: 'field.companyName' })} />
        <StringItem field="companyShortCode" label={intl.formatMessage({ id: 'field.companyShortCode' })} />
        <StringItem field="regionCode" label={intl.formatMessage({ id: 'field.regionCode' })} />
        <CodesItem field="statusList" label={intl.formatMessage({ id: 'field.activeFlag' })} code={CodeCategory.ActiveFlag} />
        {/* <DateRangeItem field="createdDate" label={intl.formatMessage({ id: 'field.createdDate' })} getValue={createdDateGetValue} mapValue={createdDateMapValue} /> */}
        {/* <DateRangeItem field="updatedDate" label={intl.formatMessage({ id: 'field.updatedDate' })} getValue={updatedDateGetValue} mapValue={updatedDateMapValue} /> */}
    </FilterToolbarItem>
}

const UploadAction = ({ search, filters }: { search: (filters: CompanyListFactor) => void, filters: CompanyListFactor }) => {
    const uploadApi = useUploadMasterCompanyMaster()
    const upload = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            files && uploadApi({ file: files[0] }, { serialized: true }).then(() => search(filters))
        })
    }, [filters, search, uploadApi])

    const isPowerUser = useIsPowerUser()
    if (isPowerUser) {
        return <UploadGroupedToolbarAction access="MARS.MLS010.UPLOAD">
            {(popupUpload, onClose) => <>
                <GroupedCallbackItem label={<FormattedMessage id="upload" />} callback={() => upload(popupUpload)} onClose={onClose} />
            </>}
        </UploadGroupedToolbarAction>
    } else {
        return <></>
    }
}

const CreateAction = ({ path }: { path: string }) => {

    const isPowerUser = useIsPowerUser()
    if (isPowerUser) {
        const toPath = `${path}/create`
        return <CreateRedirectToolbarAction access="MARS.MLS010.CREATE" title={<FormattedMessage id="createNewCompany" />} path={toPath} />
    } else {
        return <></>
    }
}

const DownloadAction = ({ data, selections, filters }: { data: TnmCompanyTemp[], selections: string[], filters: CompanyListFactor }) => {
    const dispatch = useDispatch()
    const downloadBySelectionApi = useDownloadCompanyMasterByCompanyId()
    const downloadByFilterApi = useDownloadCompanyMasterByFilter()
    const download = useCallback(() => {
        if (selections.length <= 0) {
            downloadByFilterApi(Objects.delete(filters, 'page'))
        } else {
            const companyIds = data.filter(d => selections.indexOf(d.companyCode ?? '') >= 0 && d.companyId !== undefined && d.companyId !== null).map(d => d.companyId!)
            if (companyIds.length < selections.length) {
                dispatch(applicationActions.pushError({ title: { code: 'downloadCompany' }, messages: { code: 'w0352' } }))
            } else {
                downloadBySelectionApi({ companyIdList: companyIds })
            }
        }
    }, [data, dispatch, downloadByFilterApi, downloadBySelectionApi, filters, selections])
    return <DownloadGroupedToolbarAction access="MARS.MLS010.DOWNLOAD">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="download" />} callback={download} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}

const ViewRowAction = ({ tableRow, path }: DataGridRowActionProps & { path: string }) => {
    const toPath = useCallback((tableRow: TableRow) => `${path}/view-${tableRow.row?.companyCode}`, [path])
    return <ViewRedirectRowAction tableRow={tableRow} access="MARS.MLS010.VIEWDETAIL" path={toPath} />
}

const EditRowAction = ({ tableRow, path }: DataGridRowActionProps & { path: string }) => {
    const toPath = useCallback((tableRow: TableRow) => `${path}/edit-${tableRow.row?.companyCode}`, [path])
    return <EditRedirectRowAction tableRow={tableRow} access="MARS.MLS010.EDIT" path={toPath} />
}

const SubmitRowAction = ({ tableRow, search, filters }: DataGridRowActionProps & { search: (filters: CompanyListFactor) => void, filters: CompanyListFactor }) => {
    const submitApi = useIssueCompanyInfo()
    const [disabled, setDisabled] = useState<boolean>(false)
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'submit' }), [intl])
    const callback = useCallback((tableRow: TableRow) => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            submitApi({ tempCompanyId: tableRow.row?.tempCompanyId }, { serialized: true }).then(() => {
                search(filters)
            }).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
	        messages: { code: 'c0001', args: [title] },
            actions:[{
                label: 'CANCEL'
            },{
                functionId,
                label:'CONFIRM',
            }]
        }))
    }, [dispatch, filters, functionStore, search, submitApi, title])
    return <SubmitCallbackRowAction tableRow={tableRow} access="MARS.MLS010.SUBMIT" callback={callback} disabled={disabled}/>
}

const WithdrawRowAction = ({ tableRow, search, filters }: DataGridRowActionProps & { search: (filters: CompanyListFactor) => void, filters: CompanyListFactor }) => {
    const withdrawApi = useWithdrawCompanyInfo()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'withdraw' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const callback = useCallback((tableRow: TableRow) => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            withdrawApi({ tempCompanyId: tableRow.row?.tempCompanyId }, { serialized: true }).then(() => {
                search(filters)
            }).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions:[{
                label: 'CANCEL'
            },{
                functionId,
                label:'CONFIRM',
            }]
        }))
    }, [dispatch, filters, functionStore, search, title, withdrawApi])
    return <WithdrawCallbackRowAction tableRow={tableRow} access="MARS.MLS010.WITHDRAW" callback={callback} disabled={disabled}/>
}

