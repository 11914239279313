/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Dispatch, SetStateAction } from "react"
import { useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { View } from "../../../components/View/View"
import { CargoTackingDetailResult } from "../../../services/cargo/models/CargoTackingDetailResult"
import { SearchConditionResult, Suggestion } from "../../../services/cargo/models/SearchConditionResult"
import { SearchCard } from "./SearchCard"
import { SearchCondition } from "./SearchCondition"
import { SearchResult } from "./SearchResult"

interface CIS011PcUiProps {
    suggestions: SearchConditionResult,
    searchSuggestions: (filters: { keyword: string }) => void,
    data: CargoTackingDetailResult,
    setData: Dispatch<SetStateAction<CargoTackingDetailResult>>,
    searchCargoTrackingDetail: (filters: Suggestion[]) => void,
    selections: Suggestion[],
    setSelections: Dispatch<SetStateAction<Suggestion[]>>,
    orderNo: string,
}

export const CIS011PcUi = (props: CIS011PcUiProps) => {
    const { suggestions, data, setData, searchSuggestions, searchCargoTrackingDetail, selections, setSelections, orderNo } = props
    const intl = useIntl()

    return <View >
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={1}
                title={intl.formatMessage({ id: 'step1OfCargoTrackingDetail' })}
                subtitle={intl.formatMessage({ id: 'step1OfCargoTrackingDetailSub' })}
            />
            <SectionCardContent>
                <SearchCard searchSuggestions={searchSuggestions} suggestions={suggestions} orderNo={orderNo} />
            </SectionCardContent>
        </SectionCard>

        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={2}
                title={intl.formatMessage({ id: 'step2OfCargoTrackingDetail' })}
                subtitle={intl.formatMessage({ id: 'step2OfCargoTrackingDetailSub' })}
            />
            <SectionCardContent>
                <SearchCondition suggestions={suggestions} searchCargoTrackingDetail={searchCargoTrackingDetail} setData={setData} selections={selections} setSelections={setSelections} orderNo={orderNo} />
            </SectionCardContent>
        </SectionCard>

        <SearchResult data={data} />
    </View>
}