import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { ReactNode, useState } from "react"
import { DataLayer } from '../DataLayer/DataLayer'
import { DocumentTitle } from '../DocumentTitle/DocumentTitle'
import { FrequentlyUsedLayer } from '../FrequentlyUsedLayer/FrequentlyUsedLayer'
import { Scrollbar } from '../Scrollbar'
import { StepContextProvider } from './Step/StepContext'
import { TabContextProvider } from './Tab/TabContext'
import { ViewBody } from './ViewBody'
import { ViewContextProvider } from './ViewContext'
import { ViewFooter } from './ViewFooter'
import { ViewHeader } from './ViewHeader'

export interface ViewProps {
    noHeader?: boolean,
    flex?: boolean,
    actions?: ReactNode | ReactNode[],
    children?: ReactNode | ReactNode[],
}

export function View(props: ViewProps) {
    const { noHeader, flex, actions, children } = props
    const [scrollTarget, setScrollTarget] = useState<HTMLElement | undefined>(undefined)

    const classes = useStyles()
    return <ViewContextProvider flex={flex} scrollTarget={scrollTarget}>
        <StepContextProvider>
            <TabContextProvider>
                <Scrollbar ref={instance => {
                    setScrollTarget(instance?.osTarget()?.querySelector('.os-viewport') as HTMLDivElement || null)
                }} className={classes.scrollbar}>
                    <div className={clsx(classes.root, flex && classes.flex)}>
                        {!noHeader && < ViewHeader actions={actions} />}
                        <ViewBody>
                            {children}
                        </ViewBody>
                        <ViewFooter />
                    </div>
                </Scrollbar>
                <DocumentTitle />
                <DataLayer />
                <FrequentlyUsedLayer />
            </TabContextProvider>
        </StepContextProvider>
    </ViewContextProvider>
}

const useStyles = makeStyles(theme => ({
    scrollbar: {
        width: '100%',
        height: '100%',
    },
    root: {
        width: '100%',
        minHeight: '100%',
        boxSizing: 'border-box',
        background: theme.palette.background.workbench,
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'stretch',
        paddingTop: theme.layout.appbarHeight,
    },
    flex: {
        height: '100%',
    }
}))