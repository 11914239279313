import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { Projects } from "../../../utils/Projects"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { CoListResult, CoListResultFromJson } from "../models/CoListResult"
import { CustomerOrderListFactor, customerOrderListFactorToJson } from "../models/CustomerOrderListFactor"
import { OrderDetailResult, OrderDetailResultDeserializer } from "../models/OrderDetailResult"
import { OrderForecastDetailResult, OrderForecastDetailResultDeserializer } from "../models/OrderForecastDetailResult"
import { OrderListResult, orderListResultFromJson } from "../models/OrderListResult"
import { SpotView, totCoFromJson, totCoToJson } from "../models/SpotView"

export const useGetCoListByPage = () => {
    const url = '/lcbm-order-api/order/co/api/getCoListByPage'
    const payloadProject = useCallback(customerOrderListFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(orderListResultFromJson), [])
    return usePost<CustomerOrderListFactor, PageResultEntity<OrderListResult>>(url, payloadProject, resultProject)
}

//OCS030
export const useGetCoListSpotWithContractByPage = () => {
    const url = '/lcbm-spot-order-api/order/co/api/getCoListSpotWithContract'
    const payloadProject = useCallback(customerOrderListFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(CoListResultFromJson), [])
    return usePost<CustomerOrderListFactor, PageResultEntity<CoListResult>>(url, payloadProject, resultProject)
}

// OCS031
export const useGetCoDetailData = () => {
    const url = '/lcbm-spot-order-api/order/co/api/getCoHeaderData'
    const payloadProject = useCallback((obj: { coSpotId: number }) => obj.coSpotId, [])
    const resultProject = useCallback((OrderDetailResultDeserializer), [])
    return usePost<{ coSpotId: number }, OrderDetailResult>(url, payloadProject, resultProject)
}

export const useCancelOrder = () => {
    const url = '/lcbm-order-api/order/co/api/cancelOrder'
    const payloadProject = useCallback((obj: { orderIdList: number[] }) => obj.orderIdList, [])
    return usePost<{ orderIdList: number[] }, undefined>(url, payloadProject, Projects.IGNORE)
}

export const useGetCoForecast = () => {
    const url = '/lcbm-order-api/order/co/api/getCoForecast'
    const payloadProject = useCallback((obj: { orderId: number }) => obj.orderId, [])
    const resultProject = useCallback(OrderForecastDetailResultDeserializer, [])
    return usePost<{ orderId: number }, OrderForecastDetailResult>(url, payloadProject, resultProject)
}
// OCS011
export const useGetCoDetail = () => {
    const url = '/lcbm-order-api/order/co/api/getCoDetail'
    const payloadProject = useCallback((obj: { orderId: number }) => obj.orderId, [])
    const resultProject = useCallback(OrderDetailResultDeserializer, [])
    return usePost<{ orderId: number }, OrderDetailResult>(url, payloadProject, resultProject)
}

export const useGetSpotOrderByPage = () => {
    const url = '/lcbm-spot-order-api/order/co/api/getSpotOrderWithoutContractListByPage'
    const payloadProject = useCallback(totCoToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(totCoFromJson), [])
    return usePost<SpotView, PageResultEntity<SpotView>>(url, payloadProject, resultProject)
}

