import { useCallback } from "react"
import { useDownload } from "../../../utils/fetch/useFetch"
import { Projects } from "../../../utils/Projects"
import { DownloadInbPlanFactor, downloadInbPlanFactorToJson } from "../models/DownloadInbPlanFactor"
import { DownloadOverallVisualisationFactor, downloadOverallVisualisationFactorToJson } from "../models/DownloadOverallVisualisationFactor"
import { InventoryDownloadByPackageFactor, inventoryDownloadByPackageFactorToJson } from "../models/InventoryDownloadByPackageFactor"
import {
    InventoryDownloadByPartsFactor,
    inventoryDownloadByPartsFactorToJson
} from "../models/InventoryDownloadByPartsFactor"

export const useDownloadShippingStatusTemplate = () => {
    const url = '/lcbm-management-tool-api/api/download/shippingStatusTemplate'
    const payloadProject = Projects.IGNORE
    return useDownload<undefined>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadInventoryByParts = () => {
    const url = '/lcbm-management-tool-api/api/download/inventoryByParts'
    const payloadProject = useCallback(inventoryDownloadByPartsFactorToJson, [])
    return useDownload<InventoryDownloadByPartsFactor>(url, payloadProject, { fileName: 'Inventory(By Parts).xlsx', fileTimestamp: true })
}

export const useDownloadInventoryByPackage = () => {
    const url = '/lcbm-management-tool-api/api/download/inventoryByPackage'
    const payloadProject = useCallback(inventoryDownloadByPackageFactorToJson, [])
    return useDownload<InventoryDownloadByPackageFactor>(url, payloadProject, { fileName: 'Inventory(By Package).xlsx', fileTimestamp: true })
}

export const useDownloadInbPlan = () => {
    const url = '/lcbm-management-tool-api/api/download/inbPlan'
    const payloadProject = useCallback(downloadInbPlanFactorToJson, [])
    return useDownload<DownloadInbPlanFactor>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadOverallVisualisation = () => {
    const url = '/lcbm-management-tool-api/api/download/overallVisualisation'
    const payloadProject = useCallback(downloadOverallVisualisationFactorToJson, [])
    return useDownload<DownloadOverallVisualisationFactor>(url, payloadProject, { fileTimestamp: true })
}