export enum TodoScheduleType{
            /** Request */
             REQUEST = 1,

            /** Place order */
             PLACE_ORDER = 2,
    
            /** Incoming inbound notification */
             INCOMING_INBOUND_NOTIFICATION = 3,
    
            /** Reminder to perform inbound */
             REMINDER_TO_PERFORM_INBOUND = 4,
    
            /** Reminder to perform outbound */
             REMINDER_TO_PERFORM_OUTBOUND = 5,
    
            /** Shipment delay */
             SHIPMENT_DELAY = 6,
    
            /** Customer usage */
             CUSTOMER_USAGE = 7,
    
            /** Inventory alert */
             INVENTORY_ALERT = 8,
    
            /** Change cancel request approval */
             CHANGE_CANCEL_REQUEST_APPROVAL = 9,
    
            /** Order force complete notification */
             ORDER_FORCE_COMPLETE_NOTIFICATION = 10,
    
            /** Supplier to confirm order & delivery plan */
             SUPPLIER_TO_CONFIRM_ORDER = 11,
    
            /** Combine order timing */
             COMBINE_ORDER_TIMING = 12,
    
            /** Place order timing */
             PLACE_ORDER_TIMING = 13,
    
            /** Auto approve shipping plan timing */
             AUTO_APPROVE_SHIPPING = 14,
    
            /** Supplier Delivery plan date timing */
             SUPPLIER_DELIVERY_PLAN_DATE = 15,
}