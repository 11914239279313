import { Objects } from "@rithe/utils"
import React, { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useComMasterGetActiveCbds, useComMasterGetAllCurrency, useComMasterGetAllUom } from "../../../services/common/apis/commonMasterApi"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { useGetMyPaymentTerms } from "../../../services/master/apis/workingApi"
import { TnmCurrency } from "../../../services/master/models/TnmCurrency"
import { TnmPaymentTerms } from "../../../services/master/models/TnmPaymentTerms"
import { TnmUom } from "../../../services/master/models/TnmUom"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { useGetSpotOderDetail } from "../../../services/order/apis/ReceivedPlaceOrderApi"
import { PartsDetail, ReSpotOrderResult } from "../../../services/order/models/ReSpotOrderDetailResult"
import { useMatch } from "../../../utils/useMatch"
import { OCS023PcUi } from "./OCS023PcUi"

export interface ReSpotOrderResultBasic extends ReSpotOrderResult {
}

export const OCS023 = () => {

    const { path } = useMatch()
    const { soSpotId } = useParams() as any

    const mode = path === '/rece/modify-:soSpotId' ? ScreenMode.EDIT : ScreenMode.VIEW

    const [receiveDcList, setReceiveDcList] = useState<TnvCbds[]>([])
    const [basic, setBasic] = useState<ReSpotOrderResultBasic>({} as ReSpotOrderResultBasic)
    const [forecasts, setForecasts] = useState<PartsDetail[]>([] as PartsDetail[])
    const [inboundPlans, setInboundPlans] = useState<PartsDetail[]>([] as PartsDetail[])
    const [currencyList, setCurrencyList] = useState<TnmCurrency[]>([])
    const [uomList, setUomList] = useState<TnmUom[]>([])
    const [paymentTermList, setPaymentTermList] = useState<TnmPaymentTerms[]>([])



    const splitDatas = useSplitDatas()
    const getCbds = useComMasterGetActiveCbds()
    const getData = useGetSpotOderDetail()
    const getCurrency = useComMasterGetAllCurrency()
    const getUomList = useComMasterGetAllUom()
    const getPaymentTerms = useGetMyPaymentTerms()

    const search = useCallback((soSpotId: number | null | undefined) => {
        if (soSpotId) {
            getData({ soSpotId: String(soSpotId) }, { silent: true }).then(result => {
                if (result) {
                    const { orderbasic, orderforecasts, orderInboundPlans } = splitDatas(result)
                    setBasic(orderbasic)
                    setForecasts(orderforecasts)
                    setInboundPlans(orderInboundPlans)

                }
            })
        }
    }, [getData, splitDatas])

    useEffect(() => {
        getCbds({ types: [] }, { silent: true }).then(result => {
            setReceiveDcList(result || [])
        })
        getCurrency(undefined, { silent: true }).then(result => {
            setCurrencyList(result || [])
        })
        getUomList(undefined, { silent: true }).then(result => {
            setUomList(result || [])
        })
        getPaymentTerms(undefined, { silent: true, serialized: true }).then(result => {
            setPaymentTermList(result || [])
        })
        search(soSpotId)
    }, [getCbds, getCurrency, getPaymentTerms, getUomList, search, soSpotId])



    return <OCS023PcUi
        search={search}
        receiveDcList={receiveDcList}
        basic={basic}
        setBasic={setBasic}
        forecasts={forecasts}
        setForecasts={setForecasts}
        inboundPlans={inboundPlans}
        setInboundPlans={setInboundPlans}
        currencyList={currencyList}
        mode={mode}
        uomList={uomList}
        paymentTermList={paymentTermList}
    />
}

export const useSplitDatas = () => {
    return useCallback((basic: ReSpotOrderResult) => {
        const { ocs023ParamDetailBo } = basic
        const orderbasic: ReSpotOrderResultBasic = { ...basic }
        const orderforecasts: PartsDetail[] = ocs023ParamDetailBo.map(parts => ({ ...parts }))
        const orderInboundPlans: PartsDetail[] = ocs023ParamDetailBo.map(parts => ({ ...parts }))

        return { orderbasic: orderbasic, orderforecasts: orderforecasts, orderInboundPlans: orderInboundPlans }
    }, [])
}

export const useMergeDatas = () => {
    return useCallback((orderbasic: ReSpotOrderResultBasic, orderforecasts: PartsDetail[], orderInboundPlans: PartsDetail[]) => {
        const basic = Objects.delete(orderbasic) as ReSpotOrderResult
        return { ...basic, ocs023ParamDetailBo: orderforecasts }
    }, [])
}