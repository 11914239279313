import { useEffect, useState } from "react"
import { useComMasterGetAllCbds } from "../../../services/common/apis/commonMasterApi"
import { ContractVo } from "../../../services/localmaster/models/ContractVo"
import { useGetAllContracts } from "../../../services/master/apis/contractApi"
import { ContractFactor } from "../../../services/master/models/ContractFactor"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { POBalanceFactor } from "../../../services/order/models/POBalanceFactor"
import { CbdsType } from "../../../services/systemmaster/enums/CbdsType"
import { TOLS010PcUi } from "./TOLS010PcUi"

export const TOLS010 = () => {

    const [contractParts, setContractParts] = useState<ContractFactor>({})
    const [pOBalance, setPOBalance] = useState<POBalanceFactor>({})
    const [allCbdss, setAllCbdss] = useState<TnvCbds[]>([])
    const [contracts, setContracts] = useState<ContractVo[]>([])

    const getAllCbds = useComMasterGetAllCbds()
    useEffect(() => {
        getAllCbds({ types: [CbdsType.SUPP, CbdsType.BU, CbdsType.CUST, CbdsType.DC] }, { silent: true, serialized: true }).then(result => {
            setAllCbdss(result || {})
        })
    }, [getAllCbds])

    const getSupplierContracts = useGetAllContracts()
    useEffect(() => {
        if (allCbdss && allCbdss.length > 0) {
            const supplierIds = allCbdss.filter(f => f.cbdsType === CbdsType.SUPP).map(m => m.cbdsId)
            getSupplierContracts({
                customerIds: [],
                buIds: [],
                supplierIds: supplierIds
            }, { silent: true, serialized: true }).then((result) => {
                setContracts(result)
            })
        }
    }, [allCbdss, getSupplierContracts])

    return <TOLS010PcUi
        contractParts={contractParts}
        setContractParts={setContractParts}
        allCbdss={allCbdss}
        pOBalance={pOBalance}
        setPOBalance={setPOBalance}
        contracts={contracts}
    />
}