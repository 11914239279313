import { DialogActions, DialogContent } from "@material-ui/core"
import { FileCopyOutlined, People } from "@material-ui/icons"
import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { useFieldComponent } from "@rithe/form"
import { GridContainer, GridItem } from "@rithe/ui"
import { Arrays, Records } from "@rithe/utils"
import { useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, IntlProvider, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { ResetCallbackViewAction } from "../../../components/Action/ResetCallbackViewAction"
import { SaveCallbackViewAction } from "../../../components/Action/SaveCallbackViewAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CallbackRowAction } from "../../../components/DataGrid/rowActions/CallbackRowAction"
import { CallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/CallbackToolbarAction"
import { CardDialog } from "../../../components/Dialog/CardDialog"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { View } from "../../../components/View/View"
import { effectiveLanguage, Language } from "../../../configs/i18n/Language"
import { useMatch } from "../../../utils/useMatch"
import { useApplicationSelector } from "../../Application/applicationSlice"
import { cas011Actions, useCAS011Selector } from "./CAS011Slice"
import messagesEnUs from './i18n/cas011.en-US.json'
import screenEnUs from './i18n/screen.en-US.json'

const messagesMap: Record<Language, Record<string, string>> = {
    'en-US': { ...messagesEnUs, ...screenEnUs },
    'zh-CN': { ...messagesEnUs, ...screenEnUs },
}

export const CAS011 = () => {
    const { path } = useMatch()
    const { id } = useParams() as { id: string }
    const dispatch = useDispatch()
    useEffect(() => {
        if (path === '/role/edit-:id') {
            dispatch(cas011Actions.pageInit({ mode: 'EDIT', id }))
        } else if (path === '/role/view-:id') {
            dispatch(cas011Actions.pageInit({ mode: 'VIEW', id }))
        } else if (path === '/role/create') {
            dispatch(cas011Actions.pageInit({ mode: 'CREATE' }))
        }
    }, [dispatch, id, path])

    return <ScreenIntlProvider>
        <RootView />
    </ScreenIntlProvider>
}

const ScreenIntlProvider = ({ children }: { children: React.ReactNode }) => {
    const language = useApplicationSelector(state => state.i18n.language)
    const timezone = useApplicationSelector(state => state.i18n.timezone)
    const messages = useMemo(() => {
        const messages = messagesMap[effectiveLanguage(language)]
        const defaultMessages = messagesMap[Language.DEFAULT]
        return { ...defaultMessages, ...messages }
    }, [language])
    return <IntlProvider messages={messages} locale={language} timeZone={timezone}>
        {children}
    </IntlProvider>
}

const RootView = () => {
    const intl = useIntl()
    const mode = useCAS011Selector(state => state.ui.mode)
    const actions = useMemo(() => {
        const actions = []
        if (mode === 'CREATE') {
            actions.push(<ResetAction key="reset" />)
            actions.push(<CreateAction key="create" />)
        } else if (mode === 'EDIT') {
            actions.push(<ResetAction key="reset" />)
            actions.push(<UpdateAction key="update" />)
        }
        return actions
    }, [mode])

    return <View actions={actions}>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={1}
                title={intl.formatMessage({ id: 'title.basicInfo' })}
                subtitle={intl.formatMessage({ id: 'subtitle.basicInfo' })}
            />
            <SectionCardContent>
                <BasicInfo />
            </SectionCardContent>
        </SectionCard>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={2}
                title={intl.formatMessage({ id: 'title.permissionList' })}
                subtitle={intl.formatMessage({ id: 'subtitle.permissionList' })}
            />
            <SectionCardContent>
                <Permissions />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const CreateAction = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    return <SaveCallbackViewAction
        access="ACCS.CAS011.SAVE"
        title={<FormattedMessage id="action.create" />}
        callback={() => dispatch(cas011Actions.clickCreate((id: string) => navigate(`/role/view-${id}`)))} />
}

const UpdateAction = () => {
    const dispatch = useDispatch()
    return <SaveCallbackViewAction
        access="ACCS.CAS011.SAVE"
        title={<FormattedMessage id="action.update" />}
        callback={() => dispatch(cas011Actions.clickUpdate((id: string) => dispatch(cas011Actions.pageInit({ mode: 'EDIT', id }))))} />
}

const ResetAction = () => {
    const dispatch = useDispatch()
    return <ResetCallbackViewAction outlined
        access="ACCS.CAS011.RESET"
        title={<FormattedMessage id="action.reset" />}
        callback={() => dispatch(cas011Actions.clickReset())} />
}

const BasicInfo = () => {
    return <GridContainer
        component="form"
        columnWidth={Arrays.repeat('1fr', 3)}
        columnGap={24}
        rowHeight="max-content"
    >
        <GridItem style={{ overflow: 'hidden' }}>
            <NameInput />
        </GridItem>
        <GridItem columnSpan={2} style={{ overflow: 'hidden' }}>
            <DescriptionInput />
        </GridItem>
    </GridContainer>
}

const NameInput = () => {
    const value = useCAS011Selector(state => state.domain.role.name)
    const readonly = useCAS011Selector(state => state.ui.mode === 'VIEW' || state.ui.mode === 'EDIT')
    const visited = useCAS011Selector(state => state.ui.visitedMap.name)
    const messages = useCAS011Selector(state => state.ui.messagesMap.name)
    const { Control, Label, Helper, Input, InputWrapper } = useFieldComponent('StringInput', {})
    const dispatch = useDispatch()
    return <Control
        inputWrapper={<InputWrapper readonly={readonly} error={visited && messages.length > 0} />}
        label={<Label required readonly={readonly}><FormattedMessage id="field.name" /></Label>}
        input={<Input
            field="name"
            value={value}
            setValue={(value) => {
                dispatch(cas011Actions.setFieldVisited({ field: 'name', visited: true }))
                dispatch(cas011Actions.setName(value || ''))
            }}
            readonly={readonly}
        />}
        helper={<Helper messages={visited ? messages : undefined} />}
    />
}

const DescriptionInput = () => {
    const value = useCAS011Selector(state => state.domain.role.description)
    const readonly = useCAS011Selector(state => state.ui.mode === 'VIEW')
    const visited = useCAS011Selector(state => state.ui.visitedMap.description)
    const messages = useCAS011Selector(state => state.ui.messagesMap.description)
    const { Control, Label, Helper, Input, InputWrapper } = useFieldComponent('StringInput', {})
    const dispatch = useDispatch()
    return <Control
        inputWrapper={<InputWrapper readonly={readonly} error={visited && messages.length > 0} />}
        label={<Label required readonly={readonly}><FormattedMessage id="field.description" /></Label>}
        input={<Input
            field="description"
            value={value}
            setValue={(value) => {
                dispatch(cas011Actions.setFieldVisited({ field: 'description', visited: true }))
                dispatch(cas011Actions.setDescription(value || ''))
            }}
            readonly={readonly}
        />}
        helper={<Helper messages={visited ? messages : undefined} />}
    />
}

const Permissions = () => {
    const intl = useIntl()
    const getModuleValue = useCallback((row: any) => {
        const module = row.module
        return module ? intl.formatMessage({ id: module }) : ''
    }, [intl])
    const columns = useMemo(() => [
        { field: 'category', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.category' }), width: 200 },
        { field: 'module', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.module' }), width: 300, getCellValue: getModuleValue },
        { field: 'type', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.type' }), width: 200 },
        { field: 'action', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.action' }), width: 300 },
        { field: 'code', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.code' }), width: 400 },
    ], [getModuleValue, intl])
    const getRowId = useCallback((row: any) => row.id, [])

    const selections = useCAS011Selector(state => state.domain.role.resourceIds || [])
    const dispatch = useDispatch()
    const setSelections = useCallback((selections: string[]) => {
        dispatch(cas011Actions.setResourceIds(selections))
    }, [dispatch])
    const resources = useCAS011Selector(state => state.domain.resources)
    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <Data rows={resources} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={CopyRoleAction} />
        <ColumnFreeze />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
    </DataGrid>
}

const CopyRoleAction = () => {
    const [open, setOpen] = useState<boolean>(false)
    const roles = useCAS011Selector(state => state.domain.roles.filter(role => role.id !== state.domain.role.id))

    const intl = useIntl()
    const getActive = useCallback((row: any) => {
        return row.active ? intl.formatMessage({ id: 'enum.active' }) : intl.formatMessage({ id: 'enum.inactive' })
    }, [intl])
    const columns = useMemo(() => [
        { width: 150, field: 'name', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.name' }) },
        { width: 180, field: 'description', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.description' }) },
        { field: 'active', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.active' }), width: 150, getCellValue: getActive },
    ], [getActive, intl])
    const getRowId = useCallback((row: any) => row.id, [])

    return <>
        <CallbackToolbarAction access="ACCS.CAS011.COPYROLE" title={<FormattedMessage id="action.copyRole" />} icon={<People />} callback={() => setOpen(true)} />
        <CardDialog open={open} style={{ overflow: 'hidden' }} fullWidth={true} maxWidth={'sm'} >
            <DialogHeader onClose={() => setOpen(false)}>
                <FormattedMessage id="title.copyRole" />
            </DialogHeader>
            <DialogContent style={{ overflow: 'hidden' }} >
                <div style={{ width: '100%', height: 500, display: 'flex' }}>
                    <div style={{ flex: '1 1 auto', overflow: 'hidden' }}>
                        <DataGrid>
                            <ToolbarLayout />
                            <TableLayout Container={FlexScrollbar}>
                                <TableHeaderLayout sticky />
                                <TableBodyLayout />
                            </TableLayout>
                            <PaginationLayout Pagination={Pagination} />
                            <DataTypePreset />
                            <Data rows={roles} columns={columns} getRowId={getRowId} />
                            <RowActionProvider name="copy" Action={CopyRowAction} />
                            <ColumnFreeze />
                            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
                            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
                            <Sorting />
                            <Filtering />
                            <Searching ignoreCase Input={SearchInput} />
                            <Action width={160} />
                        </DataGrid>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="action.cancel" />} callback={() => setOpen(false)} />
            </DialogActions>
        </CardDialog>
    </>
}

const CopyRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const resourceIds = useCAS011Selector(state => {
        const selectedResourceIds = state.domain.roles.find(role => role.id === tableRow.row?.id)?.resourceIds || []
        const currentResourceIds = state.domain.role.resourceIds || []
        const resourceIds = [...currentResourceIds]
        for (const resourceId of selectedResourceIds) {
            if (!resourceIds.includes(resourceId)) {
                resourceIds.push(resourceId)
            }
        }
        return resourceIds
    })
    const dispatch = useDispatch()
    return <CallbackRowAction tableRow={tableRow} title={<FormattedMessage id="action.copy" />} icon={<FileCopyOutlined />} callback={() => {
        dispatch(cas011Actions.setResourceIds(resourceIds))
    }} />
}