import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { DateRangeItem, EntriesItem, StringItem } from "@rithe/form"
import { Objects, Records } from "@rithe/utils"
import React, { SetStateAction, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { ViewRedirectRowAction } from "../../../components/DataGrid/rowActions/ViewRedirectRowAction"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { useDownloadContractRouteDetailByContractRouteId, useDownloadContractRouteDetailByFilter, useDownloadContractRouteOverviewByContractRouteId } from "../../../services/master/apis/masterDownloadApi"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { ContractRouteFactor } from "../../../services/master/models/ContractRouteFactor"
import { ContractRouteResult } from "../../../services/master/models/ContractRouteResult"
import { TnmCustomer } from "../../../services/master/models/TnmCustomer"
import { applicationActions } from "../../Application/applicationSlice"

interface MLS100PcUiProps {
    path: string,
    filters: ContractRouteFactor,
    search: (filters: ContractRouteFactor) => void,
    data: ContractRouteResult[],
    totalCount: number,
    customerList: TnmCustomer[],
    setFilters: React.Dispatch<SetStateAction<ContractRouteFactor>>,
}

export const MLS100PcUi = (props: MLS100PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({ filters, data, customerList, setFilters, search, path }: MLS100PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])

    const columns = useMemo(() => [
        { field: 'contractRouteNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contrRouteCode' }), width: 250 },
        { field: 'description', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.description' }), width: 250 },
        { field: 'customer', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerCode' }), width: 200 },
        { field: 'status', dataTypeName: CodeCategory.ContractRouteStatus, title: intl.formatMessage({ id: 'field.status' }), width: 180 },
        { field: 'createdBy', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.createdBy' }), width: 180 },
        { field: 'updatedBy', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.updatedBy' }), width: 180 },
        { field: 'createdDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.createdDate' }), width: 180 },
        { field: 'updatedDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.updatedDate' }), width: 180 },
    ], [intl])

    const getRowId = useCallback((row: any) => row.contractRouteId, [])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search, customerList }), [filters, setFilters, search, customerList])
    const actionProps = useMemo(() => ({ selections, filters }), [filters, selections])
    const ViewOrEditAction = useMemo(() => ({ path }), [path])
    const display = useCallback((tableRow: TableRow) => tableRow.row?.contractRouteId !== undefined && tableRow.row?.contractRouteId !== null, [])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.ContractRouteStatus} />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={DownloadContractRouteAction} actionProps={actionProps} />
        <RowActionProvider name="view" Action={ViewRowAction} display={display} actionProps={ViewOrEditAction} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={[]}
            columnSettings={{
                contractRouteNo: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={52} />
    </DataGrid>
}


const FilterItem = (props: {
    search: (filters: ContractRouteFactor) => void,
    filters: ContractRouteFactor,
    setFilters: React.Dispatch<SetStateAction<ContractRouteFactor>>,
    customerList: TnmCustomer[]
}) => {
    const { filters, customerList, setFilters, search } = props
    const customerEntries: [string, string][] = useMemo(() => customerList.map(m => [m.customerCode, m.customerCode]), [customerList])

    const createdDateGetValue = useCallback((filters: ContractRouteFactor) => {
        return [filters.createdDateStart ?? null, filters.createdDateEnd ?? null]
    }, [])

    const createdDateMapValue = useCallback((filters: ContractRouteFactor, value: any) => {
        return { ...filters ?? {}, createdDateStart: value[0], createdDateEnd: value[1] }
    }, [])

    const updatedDateGetValue = useCallback((filters: ContractRouteFactor) => {
        return [filters.modifiedDateStart ?? null, filters.modifiedDateEnd ?? null]
    }, [])

    const updatedDateMapValue = useCallback((filters: ContractRouteFactor, value: any) => {
        return { ...filters ?? {}, modifiedDateStart: value[0], modifiedDateEnd: value[1] }
    }, [])

    const clear = useCallback((filters: ContractRouteFactor) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: ContractRouteFactor) => {
        return [
            filters.contractRouteNo,
            filters.description,
            filters.customerList,
            filters.statusList,
            // filters.activeFlag, HAS ERROR
            filters.createdBy,
            filters.modifiedBy,
            filters.createdDateStart || filters.createdDateEnd,
            filters.modifiedDateStart || filters.modifiedDateEnd,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="contractRouteNo" label={intl.formatMessage({ id: 'field.contrRouteCode' })} />
        <StringItem field="description" label={intl.formatMessage({ id: 'field.description' })} />
        <EntriesItem field="customerList" label={intl.formatMessage({ id: 'field.customerCode' })} entries={customerEntries} />
        <CodesItem field="statusList" label={intl.formatMessage({ id: 'field.status' })} code={CodeCategory.ContractRouteStatus} />
        <StringItem field="createdBy" label={intl.formatMessage({ id: 'field.createdBy' })} />
        <StringItem field="modifiedBy" label={intl.formatMessage({ id: 'field.updatedBy' })} />
        <DateRangeItem field="createdDate" label={intl.formatMessage({ id: 'field.createdDate' })} getValue={createdDateGetValue} mapValue={createdDateMapValue} />
        <DateRangeItem field="modifiedDate" label={intl.formatMessage({ id: 'field.updatedDate' })} getValue={updatedDateGetValue} mapValue={updatedDateMapValue} />
    </FilterToolbarItem>
}



export const DownloadContractRouteAction = ({ selections, filters }: { selections: number[], filters: ContractRouteFactor }) => {
    const downloadOverviewBySelectionApi = useDownloadContractRouteOverviewByContractRouteId()
    const downloadDetailBySelectionApi = useDownloadContractRouteDetailByContractRouteId()
    const downloadDetailByFilterApi = useDownloadContractRouteDetailByFilter()
    const dispatch = useDispatch()
    const downloadOverview = useCallback(() => {
        if (selections.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'downLoad' }, messages: { code: 'w0002' } }))
            return
        }
        downloadOverviewBySelectionApi({ contractRouteIdList: selections })
    }, [dispatch, downloadOverviewBySelectionApi, selections])
    const downloadDetail = useCallback(() => {
        selections.length <= 0 ? downloadDetailByFilterApi(Objects.delete(filters, 'page')) : downloadDetailBySelectionApi({ contractRouteIdList: selections })
    }, [downloadDetailByFilterApi, downloadDetailBySelectionApi, filters, selections])
    return <DownloadGroupedToolbarAction access="MARS.MLS100.DOWNLOAD">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="downloadOverView" />} callback={downloadOverview} onClose={onClose} />
            <GroupedCallbackItem label={<FormattedMessage id="downloadDetail" />} callback={downloadDetail} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}

const ViewRowAction = ({ tableRow, path }: DataGridRowActionProps & { path: string }) => {
    const toPath = useCallback((tableRow: TableRow) => `${path}/${tableRow.rowId}`, [path])
    return <ViewRedirectRowAction tableRow={tableRow} path={toPath} />
}
