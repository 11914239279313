import { Break, DateItem, EntryItem, Form, StringItem } from "@rithe/form"
import { default as React, useCallback } from "react"
import { useIntl } from "react-intl"
import { DownloadCallbackViewAction } from "../../../components/Action/DownloadCallbackViewAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { View } from "../../../components/View/View"
import { useDownloadOnbDlvr } from "../../../services/delivery/apis/deliveryDownloadApi"
import { DeliveryPlanInitResult } from "../../../services/delivery/models/DeliveryPlanInitResult"
import { DownloadOnbDlvrView } from "../../../services/delivery/models/DownloadOnbDlvrView"

interface LOS011PcUiProps {
    factor: DownloadOnbDlvrView,
    setFactor: React.Dispatch<React.SetStateAction<DownloadOnbDlvrView>>,
    initData: DeliveryPlanInitResult,
}

export const LOS011PcUi = (props: LOS011PcUiProps) => {
    const { factor, setFactor } = props
    const intl = useIntl()
    const { supplierCodeList, supplierContraList, receiverList, customerCodeList } = props.initData

    return <View actions={[<DownloadAction factor={factor} />]}>
        <SectionCard>
            <SectionCardContent>
                <Form data={factor} setData={setFactor} labelDisplay="block" helperDisplay="tooltip">
                    <StringItem field="salesOrderNo" label={intl.formatMessage({ id: 'salesOrderNo' })} />
                    <StringItem field="partsNo" label={intl.formatMessage({ id: 'field.partsNo' })} />
                    <Break />
                    <DateItem field="dlvrPlanDateStartDt" label={intl.formatMessage({ id: 'deliveryPlanDateStart' })} />
                    <DateItem field="dlvrPlanDateEndDt" label={intl.formatMessage({ id: 'deliveryPlanDateEnd' })} />
                    <Break />
                    <EntryItem field="supplierId" label={intl.formatMessage({ id: 'field.supplierCode' })} entries={supplierCodeList ?? []} />
                    <EntryItem field="supplierContractId" label={intl.formatMessage({ id: 'supplierContractNo' })} entries={supplierContraList ?? []} />
                    <Break />
                    <EntryItem field="receiverUid" label={intl.formatMessage({ id: 'receiver' })} entries={receiverList ?? []} />
                    <EntryItem field="customerId" label={intl.formatMessage({ id: 'field.customerCode' })} entries={customerCodeList ?? []} />
                </Form>
            </SectionCardContent>
        </SectionCard>
    </View >
}

const DownloadAction = ({ factor }: { factor: DownloadOnbDlvrView, }) => {
    const downloadDeliveryPlan = useDownloadOnbDlvr()
    const download = useCallback(() => {
        downloadDeliveryPlan(factor)
    }, [downloadDeliveryPlan, factor])

    return <DownloadCallbackViewAction access="LOGI.LOS011.DOWNLOAD" callback={download} />
}


