import { useCallback } from "react"
import { Projects } from "../../../utils/Projects"
import { useDownload, usePost, useUpload } from "../../../utils/fetch/useFetch"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { OrderGroupDetailPartsResult, orderGroupDetailPartsResultFromJson } from "../models/OrderGroupDetailPartsResult"
import { OrderGroupFactor, orderGroupFactorToJson } from "../models/OrderGroupFactor"
import { OrderGroupResult, orderGroupResultFromJson } from "../models/OrderGroupResult"

export const useGetOrderGroupList = () => {
    const url = '/lcbm-local-master-api/api/getOrderGroupList'
    const payloadProject = useCallback(orderGroupFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(orderGroupResultFromJson), [])
    return usePost<OrderGroupFactor, PageResultEntity<OrderGroupResult>>(url, payloadProject, resultProject)
}

export const useGetOrderGroupDetailHeaderById = () => {
    const url = '/lcbm-local-master-api/api/getOrderGroupDetailHeaderById'
    const payloadProject = useCallback((obj: { orderGroupId: number }) => obj.orderGroupId, [])
    const resultProject = useCallback(orderGroupResultFromJson, [])
    return usePost<{ orderGroupId: number }, OrderGroupResult>(url, payloadProject, resultProject)
}

export const useGetOrderGroupDetailPartsById = () => {
    const url = '/lcbm-local-master-api/api/getOrderGroupDetailPartsById'
    const payloadProject = useCallback((obj: { orderGroupId: number }) => obj.orderGroupId, [])
    const resultProject = useCallback(Projects.array(orderGroupDetailPartsResultFromJson), [])
    return usePost<{ orderGroupId: number }, OrderGroupDetailPartsResult[]>(url, payloadProject, resultProject)
}

export const useDownloadOrderGroup = () => {
    const url = '/lcbm-local-master-api/download/downloadOrderGroup'
    const payloadProject = useCallback((obj: { orderGroupIds: number[] }) => obj.orderGroupIds, [])
    return useDownload<{ orderGroupIds: number[] }>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadOrderGroupTemplate = () => {
    const url = '/lcbm-local-master-api/download/downloadOrderGroupTemplate'
    const payloadProject = Projects.IGNORE;
    return useDownload<{}>(url, payloadProject, { fileTimestamp: true })
}

export const useConfirmOrderGroup = () => {
    const url = '/lcbm-local-master-api/api/confirmOrderGroup'
    const payloadProject = useCallback((obj: { orderGroupId: number }) => obj.orderGroupId, [])
    return usePost<{ orderGroupId: number }, undefined>(url, payloadProject, Projects.IGNORE)
}


export const useUploadOrderGroup = () => {
    const url = '/lcbm-local-master-api/upload/uploadOrderGroup'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}
