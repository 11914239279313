import { CheckInputProps, EntriesItemProps, useFieldAccess, useFieldComponent, useFieldEnsure, useFieldLayout, useFieldMessages, useFieldNoticeLevel, useFieldOnChange, useFieldSetValue, useFieldValue } from "@rithe/form";
import { GridContainer, GridItem } from "@rithe/ui";
import { Arrays } from "@rithe/utils";
import React, { ComponentType } from "react";

type ChecksItemProps = Omit<EntriesItemProps, 'Input'> & {
    labelPlacement?: 'end' | 'start' | 'top' | 'bottom',
    itemsPerRow?: number,
    Input?: ComponentType<CheckInputProps>,
}

export const ChecksItem = React.memo((props: ChecksItemProps) => {
    const {
        field,
        label,
        placeholder,
        getValue,
        mapValue,
        onChange,
        ensure,
        colSpan,
        rowSpan,
        required,
        labelPlacement,
        itemsPerRow,
        entries,
    } = props

    // accessibility
    const { readonly, disabled } = useFieldAccess(props)

    // layout
    const { labelDisplay, labelAlign, labelWidth, helperDisplay, helperAlign } = useFieldLayout(props)

    // messages
    const messages = useFieldMessages(field)
    const { info, success, warning, error } = useFieldNoticeLevel(messages)

    // value
    const value = useFieldValue(field, getValue)

    // setValue
    const setValue = useFieldSetValue(field, mapValue)

    // onChange
    useFieldOnChange(field, value, onChange)

    // validate
    useFieldEnsure(field, value, ensure)

    const { Control, Label, Helper, Input, InputWrapper } = useFieldComponent('CheckInput', props)
    return <GridItem columnSpan={colSpan} rowSpan={rowSpan} style={{ overflow: 'hidden' }}>
        <Control
            label={<Label
                required={required}
                readonly={readonly}
                disabled={disabled}>
                {label}
            </Label>}
            labelDisplay={labelDisplay}
            labelAlign={labelAlign}
            labelWidth={labelWidth}
            helper={<Helper messages={messages} />}
            helperDisplay={helperDisplay}
            helperAlign={helperAlign}
            input={<GridContainer columnWidth={Arrays.repeat('1fr', itemsPerRow ?? entries.length)}>
                {entries.map(([option, label], index) => {
                    const checked = value && value.indexOf(option) >= 0
                    const setChecked = (checked: boolean | null) => setValue(
                        checked
                            ? [...(value ?? []), option]
                            : (value ?? []).filter((item: unknown) => item !== option)
                    )
                    return <GridItem key={index}>
                        <Input
                            field={`${field}_${index}`}
                            value={checked}
                            setValue={setChecked}
                            placeholder={placeholder}
                            readonly={readonly}
                            disabled={disabled}
                            label={label}
                            labelPlacement={labelPlacement}
                        />
                    </GridItem>
                })}
            </GridContainer>}
            inputWrapper={<InputWrapper
                readonly={readonly}
                disabled={disabled}
                info={info}
                success={success}
                warning={warning}
                error={error}
            />}
        />
    </GridItem>
})
ChecksItem.displayName = 'ChecksItem'