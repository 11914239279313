import { Divider, withStyles } from "@material-ui/core";

export const DottedDivider = withStyles(theme => ({
    root: {
        background: `linear-gradient(90deg, ${theme.palette.primary.light} 1px, transparent 1px 6px)`,
        backgroundSize: '6px 1px',
    },
    light: {
        background: `linear-gradient(90deg, rgba(152,155,158, 0.5) 1px, transparent 1px 6px)`,
        backgroundSize: '6px 1px',
    },
}))(Divider)