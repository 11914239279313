import moment from "moment"
import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"

export type EtlBatchView = Required & Partial<Optional>

type EtlBatchViewJson = JsonRequired & Partial<JsonOptional>

export const etlBatchViewToJson: Project<EtlBatchView, EtlBatchViewJson> = obj => ({
    ...obj,
    generateDate: obj.generateDate == null ? undefined : moment(obj.generateDate).format('YYYY-MM-DD'),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page)
})

export const etlBatchViewFromJson: Project<EtlBatchViewJson, EtlBatchView> = json => ({
    ...json,
    generateDate: json.generateDate == null ? undefined : moment(json.generateDate).toDate(),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page)
})


interface Required extends JsonRequired {

}

interface Optional extends Omit<JsonOptional, 'generateDate'> {
    generateDate: Date,
    page: PageViewEntity,
}

interface JsonRequired {

}

interface JsonOptional {
    batchId: number,
    generateDate: string,
    page: PageViewEntityJson,
}
