import { Button } from '@material-ui/core'
import { Break, DateItem, EntryItem, Form, StringItem } from "@rithe/form"
import { default as React, useCallback, useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { SectionCard } from '../../../components/Card/SectionCard'
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { View } from "../../../components/View/View"
import { useDownloadDiMonitor } from "../../../services/delivery/apis/deliveryDownloadApi"
import { DownloadDiMonitorView } from "../../../services/delivery/models/DownloadDiMonitorView"
import { TnmDistributionCenter } from "../../../services/master/models/TnmDistributionCenter"

interface LDS013PcUiProps {
    factor: DownloadDiMonitorView,
    setFactor: React.Dispatch<React.SetStateAction<DownloadDiMonitorView>>,
    initData: TnmDistributionCenter[],
}

export const LDS013PcUi = (props: LDS013PcUiProps) => {
    const { factor, setFactor, initData } = props
    const intl = useIntl()

    const downloadDeliveryPlan = useDownloadDiMonitor()
    const download = useCallback(() => {
        downloadDeliveryPlan(factor)
    }, [downloadDeliveryPlan, factor])

    const DcCodeMap: [number, string][] = useMemo(() => initData.map(m => [m.dcId, m.dcCode]), [initData])
    const downloadButton = useMemo(() => {
        return < Button onClick={download} variant="contained" color="primary" > <FormattedMessage id="download" /></Button >
    }, [download])
    return <View actions={[downloadButton]}>
        <SectionCard >
            <SectionCardContent>
                <Form data={factor} setData={setFactor} labelDisplay="block" helperDisplay="tooltip">
                    <StringItem field="diRefNo" label={intl.formatMessage({ id: 'diRefNo' })} />
                    <EntryItem field="dcId" label={intl.formatMessage({ id: 'field.dcCode' })} entries={DcCodeMap} />
                    <Break />
                    <DateItem field="diDateStartDt" label={intl.formatMessage({ id: 'diDateStart' })} />
                    <DateItem field="diDateEndDt" label={intl.formatMessage({ id: 'diDateEnd' })} />
                    <Break />
                    <StringItem field="partsNo" label={intl.formatMessage({ id: 'field.partsNo' })} />
                </Form>
            </SectionCardContent>
        </SectionCard>
    </View >
}

