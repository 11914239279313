import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"

export type AlarmUsagePatternView = Required & Partial<Optional>

type AlarmUsagePatternViewJson = JsonRequired & Partial<JsonOptional>

export const alarmUsagePatternViewToJson: Project<AlarmUsagePatternView, AlarmUsagePatternViewJson> = obj => ({
    ...obj,
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page)
})

export const alarmUsagePatternViewFromson: Project<AlarmUsagePatternViewJson, AlarmUsagePatternView> = json => ({
    ...json,
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page)
})


interface Required extends JsonRequired {

}

interface Optional extends JsonOptional {
    page: PageViewEntity,
}

interface JsonRequired {

}

interface JsonOptional {
    inventoryBoxFla: string[],
    onshippingDelayPat: string[],
    custDelayPat: string[],
    custAdvancePat: string[],
    safetyBox: string[],
    calculationPattern: string[],

    comCustomerList: string[],
    comSellerList: string[],
    customerList: string[],
    sourceListVal: number[],
    activeFlag: number,
    page: PageViewEntityJson,
}
