import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useComMasterGetActiveCustomer, useComMasterGetActiveDc } from "../../../services/common/apis/commonMasterApi"
import { useGetCustDeliveryPage } from "../../../services/delivery/apis/deliveryCustApi"
import { CustDeliveryTransfer } from "../../../services/delivery/models/CustDeliveryTransfer"
import { CustDeliveryView, custDeliveryViewFromJson, custDeliveryViewToJson } from "../../../services/delivery/models/CustDeliveryView"
import { TnmCustomer } from "../../../services/master/models/TnmCustomer"
import { TnmDistributionCenter } from "../../../services/master/models/TnmDistributionCenter"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { LDS010PcUi } from "./LDS010PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }
const searchCachekey = sessionKeys.Filters_LDS010
export const LDS010 = () => {
    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? custDeliveryViewFromJson(JSON.parse(filters)) : null
    }, [])

    const [filters, setFilters] = useState<CustDeliveryView>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<CustDeliveryTransfer[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [customerList, setCustomerList] = useState<TnmCustomer[]>([])
    const [dcList, setDcList] = useState<TnmDistributionCenter[]>([])

    const getCustDeliveryList = useGetCustDeliveryPage()
    const search = useCallback((filters: CustDeliveryView) => {
        getCustDeliveryList(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(custDeliveryViewToJson(filters)))
        })
    }, [getCustDeliveryList, setData])

    useSearchOnLoad(search, filters)

    const getCustomerList = useComMasterGetActiveCustomer()
    useEffect(() => {
        getCustomerList(undefined, { silent: true }).then(result => {
            setCustomerList(result || [])
        })
    }, [getCustomerList])

    const getDcList = useComMasterGetActiveDc()
    useEffect(() => {
        getDcList(undefined, { silent: true }).then(result => {
            setDcList(result || [])
        })
    }, [getDcList])

    return <LDS010PcUi
        filters={filters}
        search={search}
        data={data}
        totalCount={totalCount}
        customerList={customerList}
        dcList={dcList}
        setFilters={setFilters}
    />
}