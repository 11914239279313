import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useGetActiveRequestFromList, useGetAllToRequestList } from "../../../services/master/apis/requestApi"
import { RequestFactor, requestFactorFromJson, requestFactorToJson } from "../../../services/master/models/RequestFactor"
import { RequestResult } from "../../../services/master/models/RequestResult"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import sessionKeys from "../../../utils/sessionKeys"
import { useMatch } from "../../../utils/useMatch"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { MOS030PcUi } from "./MOS030PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }
const searchCachekey = sessionKeys.Filters_MOS030

export enum FromTo {
    FROM = 1,
    TO = 2
}

export const MOS030 = () => {

    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? requestFactorFromJson(JSON.parse(filters)) : null
    }, [])


    const { path } = useMatch()
    const fromTo = FromTo.TO
    const [filters, setFilters] = useState<RequestFactor>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<RequestResult[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [requestFromList, setRequestFromList] = useState<TnvCbds[]>([])

    // const getFromRequestList = useGetAllFromRequestList()
    const getToRequestList = useGetAllToRequestList()
    const search = useCallback((filters: RequestFactor) => {
        getToRequestList(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(requestFactorToJson(filters)))
        })
    }, [getToRequestList, setData])


    useSearchOnLoad(search, filters)
    const getRequestFromList = useGetActiveRequestFromList()
    useEffect(() => {
        getRequestFromList(undefined, { silent: true }).then(result => {
            setRequestFromList(result || [])
        })
    }, [getRequestFromList, setRequestFromList])



    return <MOS030PcUi
        path={path}
        filters={filters}
        search={search}
        data={data}
        totalCount={totalCount}
        requestToList={[]}
        requestFromList={requestFromList}
        setFilters={setFilters}
        fromTo={fromTo}
    />
}