import { Project } from "../../../utils/Project"
import { ShippingMode } from "../../master/enums/ShippingMode"
import { YESNOFlag } from "../../privilege/enums/YESNOFlag"
import { dateFromJson } from "../../utils/deserializer"
import { ShippingDetailStatus } from "../enums/ShippingDetailStatus"


export type ShippingDetailEntity = Required & Partial<Optional>

type ShippingDetailEntityJson = JsonRequired & Partial<JsonOptional>

export const shippingDetailEntityFromJson: Project<ShippingDetailEntityJson, ShippingDetailEntity> = json => ({
    ...json,
    atd: dateFromJson(json.atd),
    eta: dateFromJson(json.eta),
    blDate: dateFromJson(json.blDate),
})


interface Required extends JsonRequired { }

interface Optional extends Omit<JsonOptional, 'shippingMode' | 'atd' | 'eta' | 'blDate' | 'viaPortcastFlag'> {
    shippingMode: ShippingMode,
    atd: Date,
    eta: Date,
    blDate: Date,
    status: ShippingDetailStatus,
    viaPortcastFlag: YESNOFlag
}

interface JsonRequired {
    shippingDetailId: number,
}

interface JsonOptional {
    bookingNo: string,
    containerNo: string,
    outboundNo: string,
    shippingMode: number,
    loadingPortId: string,
    dischargePortId: string,
    atd: string,
    eta: string,
    blNo: string,
    blDate: string,
    vesselName: string,
    voyageNo: string,
    cargoStatus: string,
    containerType: string,
    sealNo: string,
    role: number,
    m3: number,
    netWeight: number,
    grossWeight: number,
    status: number,
    availableCargoStatuss: string[],
    viaPortcastFlag: number
}