import { Project } from "../../../utils/Project"
import { ContractRequestType } from "../enums/ContaractRequestType"

export type PartsFactor = Partial<Optional>
type PartsFactorJson = Partial<JsonOptional>
export const partsFactorToJson: Project<PartsFactor, PartsFactorJson> = obj => ({
    ...obj,
})

interface Optional extends JsonOptional {
    requestType: ContractRequestType
}


interface JsonOptional {
    keyIds: string,
    requestTo: string,
    requestType: number,
    isSeller: boolean,
}
