import { makeStyles, Tooltip } from '@material-ui/core'
import React from 'react'
import { Access } from '../../Access/Access'
import { NeumorphismButton } from '../../Button/NeumorphismButton'

interface CallbackToolbarActionProps {
    access?: string,
    power?: boolean,
    title: NonNullable<React.ReactNode>,
    icon: NonNullable<React.ReactNode>,
    callback: () => void,
    disabled?: boolean,
}

export const CallbackToolbarAction = (props: CallbackToolbarActionProps) => {
    const { access, power, title, icon, disabled, callback } = props

    const styles = useStyles()
    const action = <Tooltip title={title}>
        <NeumorphismButton disabled={disabled} onClick={callback} className={styles.root}>
            {icon}
        </NeumorphismButton>
    </Tooltip>
    return access ? <Access access={access} power={power}>{action}</Access> : action
}

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1),
        width: 36,
    },
}))