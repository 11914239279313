import React from 'react'
import { FormattedMessage } from 'react-intl'
import { CallbackCardAction } from './CallbackCardAction'

interface DownloadCallbackCardActionProps {
    access?: string,
    disabled?: boolean,
    outlined?: boolean,
    title?: NonNullable<React.ReactNode>,
    callback: () => void,
}

export const DownloadCallbackCardAction = (props: DownloadCallbackCardActionProps) => {
    return <CallbackCardAction {...props} title={props.title ?? <FormattedMessage id="download" />} />
}