import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useGetRoSoDetail } from "../../../services/order/apis/CCRequestApi"
import { RequestType } from "../../../services/order/enums/RequestType"
import { RequestDetailHead, RequestPartsDetail } from "../../../services/order/models/CCRequestDetailResult"
import { ORS011FcPcUi } from "./ORS011FcPcUi"
import { ORS011PcUi } from "./ORS011PcUi"

export const ORS011 = () => {

    const { roSoId, dataFlag, soId, requestType } = useParams() as any
    const [headerInfo, setHeaderInfo] = useState<RequestDetailHead>({} as RequestDetailHead)
    const [partsList, setPartsList] = useState<RequestPartsDetail[]>([])
    const getDetailData = useGetRoSoDetail()
    const search = useCallback(() => {
        getDetailData({ roSoId: roSoId, dataFlag: dataFlag, soId: soId }, { silent: true, serialized: true }).then(result => {
            setHeaderInfo(result?.header ?? {} as RequestDetailHead)
            setPartsList(result?.detailList ?? [])
        })
    }, [dataFlag, getDetailData, roSoId, soId])
    useEffect(() => {
        search()
    }, [search])
    if (requestType && Number(requestType) === RequestType.FC_CHANGE) {
        return <ORS011FcPcUi roSoId={roSoId} headerInfo={headerInfo} partsList={partsList} search={search} />
    } else {
        return <ORS011PcUi roSoId={roSoId} headerInfo={headerInfo} partsList={partsList} setPartsList={setPartsList} search={search} />
    }
}