import { DialogActions, DialogContent } from "@material-ui/core"
import { ReactNode } from "react"
import { DarkDialog } from "../../../components/Dialog/DarkDialog"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"

interface ConfirmDialogProps {
    open: boolean,
    onClose: () => void,
    title: ReactNode,
    content: NonNullable<React.ReactNode>,
    confirmButtonLabel: NonNullable<ReactNode>,
    cancelButtonLabel: NonNullable<ReactNode>,
    onConfirm: () => void,
}

export function ConfirmDialog(props: ConfirmDialogProps) {
    const { open, onClose, title, content, confirmButtonLabel, cancelButtonLabel, onConfirm } = props
    return <DarkDialog open={open} style={{ overflow: 'hidden' }} maxWidth="sm" fullWidth fullScreen={false}>
        <DialogHeader onClose={onClose}>
            {title}
        </DialogHeader>
        <DialogContent>
            {content}
        </DialogContent>
        <DialogActions>
            <DialogAction title={confirmButtonLabel} callback={onConfirm} />
            <DialogAction outlined title={cancelButtonLabel} callback={onClose} />
        </DialogActions>
    </DarkDialog>
}