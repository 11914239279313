import {Button, Dialog, DialogContent, DialogContentText, DialogTitle, Typography} from '@material-ui/core'

import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from '@rithe/data-grid'
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { Objects, Records } from "@rithe/utils"
import React, { memo, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { SectionCard } from '../../../components/Card/SectionCard'
import { SectionCardContent } from '../../../components/Card/SectionCardContent'
import { SectionCardHeader } from '../../../components/Card/SectionCardHeader'
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { EditRedirectRowAction } from "../../../components/DataGrid/rowActions/EditRedirectRowAction"
import { ViewRedirectRowAction } from "../../../components/DataGrid/rowActions/ViewRedirectRowAction"
import { DownloadGroupedToolbarAction } from '../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction'
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { UploadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/UploadGroupedToolbarAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CodesItem } from '../../../components/Form/CodesItem'
import { View } from '../../../components/View/View'
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { useDownloadAlarmUsagePatternByFilter, useDownloadAlarmUsagePatternBySelection } from '../../../services/smt/api/smtDownloadApi'
import { useUploadAlarmUsagePattern } from '../../../services/smt/api/smtUploadApi'
import { AlarmUsagePattemTransfer } from '../../../services/smt/models/AlarmUsagePattemTransfer'
import { AlarmUsagePatternView } from '../../../services/smt/models/AlarmUsagePatternView'

interface MSAUF010PcUiProps {
    filters: AlarmUsagePatternView,
    setFilters: React.Dispatch<React.SetStateAction<AlarmUsagePatternView>>,
    search: (filters: AlarmUsagePatternView) => void,
    data: AlarmUsagePattemTransfer[],
    totalCount: number,
}

export const MSAUF010PcUi = (props: MSAUF010PcUiProps) => {
    const intl = useIntl()
    return <View flex>
        <SectionCard>
            <SectionCardHeader title={''} subtitle={intl.formatMessage({ id: 'alarmUsagePatternSub' })} />
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({ filters, setFilters, search, data }: MSAUF010PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])

    const columns = useMemo(() => [
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 230 },
        { field: 'customerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerCode' }), width: 200 },
        // { field: 'dataSource', dataTypeName: CodeCategory.DataSource, title: intl.formatMessage({ id: 'dataSource' }), width: 200 },
        { field: 'externalRefNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.externalRefNo' }), width: 230 },
        { field: 'customerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerPartsNo' }), width: 230 },
        { field: 'customerBackNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerBackNo' }), width: 230 },

        { field: 'grouping', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.grouping' }), width: 230 },
        { field: 'commonPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.commonPartsNo' }), width: 230 },
        { field: 'specialPartsType', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.specialPartsType' }), width: 230 },
        { field: 'customerStockFlag', dataTypeName: CodeCategory.CustomsFlag, title: intl.formatMessage({ id: 'field.customerStockFlag' }), width: 230 },

        { field: 'minDays1', dataTypeName: 'string', title: intl.formatMessage({ id: 'minStockDays1' }), width: 200 },
        { field: 'minDays2', dataTypeName: 'string', title: intl.formatMessage({ id: 'minStockDays2' }), width: 200 },
        { field: 'minDays3', dataTypeName: 'string', title: intl.formatMessage({ id: 'minStockDays3' }), width: 200 },
        { field: 'maxDays', dataTypeName: 'string', title: intl.formatMessage({ id: 'maxStockDays' }), width: 200 },
        { field: 'minBoxes1', dataTypeName: 'string', title: intl.formatMessage({ id: 'minStockBoxes1' }), width: 200 },
        { field: 'minBoxes2', dataTypeName: 'string', title: intl.formatMessage({ id: 'minStockBoxes2' }), width: 200 },
        { field: 'minBoxes3', dataTypeName: 'string', title: intl.formatMessage({ id: 'minStockBoxes3' }), width: 200 },
        { field: 'maxBoxes', dataTypeName: 'string', title: intl.formatMessage({ id: 'maxStockBoxes' }), width: 200 },
        { field: 'inventoryBoxFlag', dataTypeName: CodeCategory.InventoryBoxFlag, title: intl.formatMessage({ id: 'inventoryBoxFla' }), width: 200 },
        { field: 'outboundFluctuation', dataTypeName: 'string', title: intl.formatMessage({ id: 'outboundFluctuation' }), width: 200 },
        { field: 'onshippingDelayPat', dataTypeName: CodeCategory.OnShippingDelayPattern, title: intl.formatMessage({ id: 'onshippingDelayPat' }), width: 200 },
        { field: 'deliveryCustPat', dataTypeName: 'string', title: intl.formatMessage({ id: 'deliveryCustPat' }), width: 200 },
        { field: 'custDelayPat', dataTypeName: CodeCategory.AdjustmentPattern1, title: intl.formatMessage({ id: 'custDelayPat' }), width: 200 },
        { field: 'custAdvancePat', dataTypeName: CodeCategory.AdjustmentPattern2, title: intl.formatMessage({ id: 'custAdvancePat' }), width: 200 },
        { field: 'orderGroupNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'orderGroupNo' }), width: 200 },
        { field: 'buildOutMonth', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.buildOutMonth' }), width: 150 },
        { field: 'lastPOMonth', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.lastPOMonth' }), width: 150 },
        { field: 'lastDeliveryDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.lastDeliveryDate' }), width: 150 },
        { field: 'alertType', dataTypeName: CodeCategory.AlertMethod, title: intl.formatMessage({ id: 'field.alertType' }), width: 150 },
        // { field: 'safetyBox', dataTypeName: 'string', title: intl.formatMessage({ id: 'safetyBox' }), width: 200 },
        // { field: 'safetyStockPercent', dataTypeName: 'string', title: intl.formatMessage({ id: 'safetyStockPercent' }), width: 200 },
        // { field: 'safetyStockPercent1', dataTypeName: 'string', title: intl.formatMessage({ id: 'safetyStockPercent1' }), width: 200 },
        // { field: 'safetyStockPercent2', dataTypeName: 'string', title: intl.formatMessage({ id: 'safetyStockPercent2' }), width: 200 },
        // { field: 'safetyStockPercent3', dataTypeName: 'string', title: intl.formatMessage({ id: 'safetyStockPercent3' }), width: 200 },
        // { field: 'maxStockPercent', dataTypeName: 'string', title: intl.formatMessage({ id: 'maxStockPercent' }), width: 200 },


        { field: 'safetyStockUom', dataTypeName: CodeCategory.SafetyStockUom, title: intl.formatMessage({ id: 'safetyStockUom' }), width: 200 },
        { field: 'safetyStock', dataTypeName: 'string', title: intl.formatMessage({ id: 'safetyStock' }), width: 200 },
        { field: 'maxStock', dataTypeName: 'string', title: intl.formatMessage({ id: 'maxStock' }), width: 200 },
        { field: 'pairedPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'pairedPartsNo' }), width: 200 },
        { field: 'calculationPattern', dataTypeName: CodeCategory.CalculationPattern, title: intl.formatMessage({ id: 'calculationPattern' }), width: 200 },
    ], [intl])

    const getRowId = useCallback((row: any) => row.customerPartsId, [])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search }), [filters, setFilters, search])
    const actionProps1 = useMemo(() => ({ search, filters }), [filters, search])
    const actionProps2 = useMemo(() => ({ filters, selections }), [filters, selections])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.DataSource} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.InventoryBoxFlag} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.CustomsFlag} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OnShippingDelayPattern} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.AdjustmentPattern1} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.AdjustmentPattern2} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.CalculationPattern} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.SafetyStockUom} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.AlertMethod} />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={UploadAction} actionProps={actionProps1} />
        <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps2} />
        <RowActionProvider name="view" Action={ViewRowAction} />
        <RowActionProvider name="edit" Action={EditRowAction} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['grouping', 'commonPartsNo', 'specialPartsType', 'customerStockFlag', 'minDays1', 'minDays2', 'minDays3', 'maxDays', 'minBoxes1', 'minBoxes2', 'minBoxes3', 'maxBoxes'
                , 'inventoryBoxFlag', 'outboundFluctuation', 'onshippingDelayPat', 'deliveryCustPat', 'custDelayPat', 'custAdvancePat'
                , 'buildOutMonth', 'lastPOMonth', 'lastDeliveryDate', 'safetyStockUom', 'alertType', 'safetyStock', 'maxStock', 'pairedPartsNo', 'calculationPattern']}
            columnSettings={{
                partsNo: { disableUserControl: true },
                customerCode: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />

        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={88} />
    </DataGrid>
}

const FilterItem = (props: {
    filters: AlarmUsagePatternView,
    setFilters: React.Dispatch<React.SetStateAction<AlarmUsagePatternView>>,
    search: (filters: AlarmUsagePatternView) => void,
}) => {
    const { filters, search, setFilters } = props

    const clear = useCallback((filters: AlarmUsagePatternView) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: AlarmUsagePatternView) => {
        return [
            filters.inventoryBoxFla,
            filters.onshippingDelayPat,
            filters.custDelayPat,
            filters.custAdvancePat,
            filters.calculationPattern,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <CodesItem field='inventoryBoxFla' label={intl.formatMessage({ id: 'inventoryBoxFla' })} code={CodeCategory.InventoryBoxFlag} />
        <CodesItem field='onshippingDelayPat' label={intl.formatMessage({ id: 'onshippingDelayPat' })} code={CodeCategory.OnShippingDelayPattern} />
        <CodesItem field='custDelayPat' label={intl.formatMessage({ id: 'custDelayPat' })} code={CodeCategory.AdjustmentPattern1} />
        <CodesItem field='custAdvancePat' label={intl.formatMessage({ id: 'custAdvancePat' })} code={CodeCategory.AdjustmentPattern2} />
        <CodesItem field='calculationPattern' label={intl.formatMessage({ id: 'calculationPattern' })} code={CodeCategory.CalculationPattern} />
    </FilterToolbarItem>
}

const UploadAction = ({search, filters}: {search: (filters: AlarmUsagePatternView) => void, filters: AlarmUsagePatternView}) => {
    const uploadApi = useUploadAlarmUsagePattern()
    const [dialog, setDialog] = useState<boolean>(false)

    const upload = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            files && uploadApi({file: files[0]}, {serialized: true}).then((result) => {
                search(filters)
                if (result.warningFlag === 1) {
                    setDialog(true)
                }
            })
        })
    }, [filters, search, uploadApi, setDialog])
    return <>
        <UploadGroupedToolbarAction access="STCK.MSAUF010.UPLOAD">
            {(popupUpload, onClose) => <>
                <GroupedCallbackItem label={<FormattedMessage id="uploadAlarmUsagePattern" />} callback={() => upload(popupUpload)} onClose={onClose} />
            </>}
        </UploadGroupedToolbarAction>
        <WaringMessageDialog dialog={dialog} setDialog={setDialog} />
    </>
}

const DownloadAction = ({ filters, selections }: { filters: AlarmUsagePatternView, selections: number[] }) => {
    const downloadByFilterApi = useDownloadAlarmUsagePatternByFilter()
    const downloadBySelectApi = useDownloadAlarmUsagePatternBySelection()
    const download = useCallback(() => {
        if (selections.length <= 0) {
            downloadByFilterApi(Objects.delete(filters, 'page'))
        } else {
            downloadBySelectApi({ customerPartsIds: selections })
        }
    }, [downloadByFilterApi, downloadBySelectApi, filters, selections])
    //customerPartsIdList
    // const download = useCallback(() => downloadApi(Objects.delete(filters, 'page')), [downloadApi, filters])
    return <DownloadGroupedToolbarAction access="STCK.MSAUF010.DOWNLOAD">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="downloadAlarmUsagePattern" />} callback={download} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}

const ViewRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/alarmUsagePattern/view-${tableRow.rowId}`, [])
    return <ViewRedirectRowAction access="STCK.MSAUF010.DETAIL" tableRow={tableRow} /* 'ACCS.MSAUF010.VIEWDETAIL' */ path={path} />
}

const EditRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/alarmUsagePattern/edit-${tableRow.rowId}`, [])
    return <EditRedirectRowAction access="STCK.MSAUF010.EDIT" tableRow={tableRow} /* 'ACCS.MSAUF010.EDIT' */ path={path} />
}

const WaringMessageDialog = memo((props: {
    dialog: boolean,
    setDialog: React.Dispatch<React.SetStateAction<boolean>>,
}) => {
    const {dialog, setDialog} = props
    const onClose = useCallback(() => setDialog(false), [setDialog])

    return <Dialog open={dialog} onClose={onClose} maxWidth="xl" style={{flex: 1}} BackdropProps={{style: {backgroundColor: 'rgba(0, 0, 0, 0.2)'}}}>
        <DialogTitle style={{textAlign: 'center'}}><Typography variant="h3"><FormattedMessage id="warningMessageTitle" /></Typography></DialogTitle>
        <DialogContent style={{textAlign: 'center'}}>
            <DialogContentText>
                <FormattedMessage id="warningMessageContent2" />
            </DialogContentText>
            <Button
                variant={'outlined'}
                color="secondary"
                onClick={onClose}
                style={{
                    width: '30%',
                }}>
                <FormattedMessage id="ok" />
            </Button>
        </DialogContent>
    </Dialog>
})
