import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"
import { ActiveFlag } from "../enums/ActiveFlag"

export type DCResult = Required & Partial<Optional> & TableEntity
type DCResultJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const dcResultFromJson: Project<DCResultJson, DCResult> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
    activeFlag: ActiveFlag,
}

interface JsonRequired {
    dcCode: string,
}

interface JsonOptional {
    companyId: number,
    companyCode: string,
    temDcId: number,
    dcId: number,
    dcName: string,
    dcShortCode: string,
    regionCode: string,
    address1: string,
    address2: string,
    address3: string,
    address4: string,
    postalCode: string,
    contact1: string,
    fax1: string,
    telephone1: string,
    email1: string,
    contact2: string,
    fax2: string,
    telephone2: string,
    email2: string,
    timeZone: string,
    calendarId: number,
    calendarCode: string,
    activeFlag: number,
}