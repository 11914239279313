import { makeStyles, MenuList, Tooltip } from "@material-ui/core"
import { usePopover } from "@rithe/utils"
import React, { ReactNode } from "react"
import { Access } from "../../Access/Access"
import { NeumorphismButton } from "../../Button/NeumorphismButton"
import { PopoverMenu } from "../../PopoverMenu"

interface GroupedToolbarActionProps {
    access?: string,
    power?: boolean,
    title: NonNullable<React.ReactNode>,
    icon: NonNullable<React.ReactNode>,
    children?: (onClose: () => void) => ReactNode | ReactNode[],
}

export const GroupedToolbarAction = (props: GroupedToolbarActionProps) => {
    const { access, power, title, icon, children } = props
    const { open, anchorEl, onOpen, onClose } = usePopover()

    const styles = useStyles()
    const action = <>
        <Tooltip title={title}>
            <NeumorphismButton onClick={onOpen} className={styles.root}>{icon}</NeumorphismButton>
        </Tooltip>
        <PopoverMenu
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            onClose={onClose}
        >
            <MenuList>
                {children && children(onClose)}
            </MenuList>
        </PopoverMenu>
    </>
    return access ? <Access access={access} power={power}>{action}</Access> : action
}

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1),
        width: 36,
    },
}))