import { Project } from "../../../../utils/Project"
import { dateFromJson } from "../../../utils/deserializer"

export type WeeklyRundownResult = Required & Partial<Optional>
type WeeklyRundownResultJson = JsonRequired & Partial<JsonOptional>

export const weeklyRundownResultFromJson: Project<WeeklyRundownResultJson, WeeklyRundownResult> = json => ({
    ...json,
    adjustedDate: json.adjustedDate ? dateFromJson(json.adjustedDate) : undefined,

})

interface Required extends JsonRequired { }

interface Optional extends Omit<JsonOptional, 'adjustedDate'> {
    adjustedDate: Date
}

interface JsonRequired {
    customerPartsId: number,
}

interface JsonOptional {

    decimalDigits: number,
    partsNo: string,
    customerCode: string,
    dataCustomerCode: string,
    customerPartsNo: string,
    externalRefNo: string,
    expCountry: string,
    customerBackNo: string,
    dataSource: string,
    partRemark: string,
    partType: string,
    byBox: boolean,
    stockQty: number,
    onholdQty: number,
    stockDeltaQty: number,
    onholdDeltaQty: number,
    adjustedDate: string,
    stockDays: number,
    stockBox: number,
    alert: string,
    alertSetting: string,
    weeklyAlerts: string[],
    weeks: number[],
    weeklyAlertsDate: Date[],
    weeklyStockDays: number[],
    weeklyStockBoxes: number[],
    weeklyContainsShortageDate: boolean[],
    weeklyNoUsage: boolean[],
    shortageDateStr: string,
    nextInboundDateStr: string,
    nextIncomingQty: number,
    buildoutStr: string,
    partsName: string,
    sellerCode: string,
    supplierCodes: string,
    onShippingQty: number,
    expBalQty: number,
    expWhStockQty: number,
    unit: string,
    buildoutMonthStr: string,
    spq: number,
}

