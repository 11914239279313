import { useCallback } from "react"
import { Projects } from "../../../utils/Projects"
import { usePost } from "../../../utils/fetch/useFetch"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { ActualUsageEntityList, actualUsageEntityListFromJson } from "../models/ActualUsageEntityList"
import { ActualUsageFactor, actualUsageFactorToJson } from "../models/ActualUsageFactor"
import { CustomerUsageResult, customerUsageResultDeserializer } from "../models/CustomerUsageTransfer"
import { CustomerUsageView, customerUsageViewToJson } from "../models/CustomerUsageView"

export const useGetCustomerUsagePage = () => {
    const url = '/smt-api/usage/history/getUsageHistoryList'
    const payloadProject = useCallback(customerUsageViewToJson, [])
    const resultProject = useCallback(customerUsageResultDeserializer, [])
    return usePost<CustomerUsageView, CustomerUsageResult>(url, payloadProject, resultProject)

}

export const useGetActualUsagePage = () => {
    const url = '/smt-api/usage/getActualUsageList';
    const payloadProject = useCallback(actualUsageFactorToJson, []);
    const resultProject = useCallback(pageResultEntityFromJson(actualUsageEntityListFromJson), []);
    return usePost<ActualUsageFactor, PageResultEntity<ActualUsageEntityList>>(url, payloadProject, resultProject);
}

export const useGetCustActualUsagePage = () => {
    const url = '/smt-api/usage/getCustActualUsageList';
    const payloadProject = useCallback(actualUsageFactorToJson, []);
    const resultProject = useCallback(pageResultEntityFromJson(actualUsageEntityListFromJson), []);
    return usePost<ActualUsageFactor, PageResultEntity<ActualUsageEntityList>>(url, payloadProject, resultProject);
}

export const useGetGrouping = () => {
    const url = '/smt-api/usage/getGrouping';
    const resultProject = useCallback((obj: string[]) => obj, []);
    return usePost<undefined, string[]>(url, Projects.IGNORE, resultProject);
}
