import { useCallback, useState } from "react"
import { useGetUserAccessHistoryByPage } from "../../../services/privilege/api/UserAccessHistoryApi"
import { AccessHistoryType } from "../../../services/privilege/enums/AccessHistoryType"
import { UserAccessHistoryListFactor, UserAccessHistoryListFactorToJson } from "../../../services/privilege/models/UserAccessHistoryFactor"
import { UserAccessHistoryListResult } from "../../../services/privilege/models/UserAccessHistoryListResult"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { CAS030PcUi } from "./CAS030PcUi"

const searchCachekey = sessionKeys.Filters_CAS030
const defaultFilters = {page: {currentPage: 0, pageSize: 100000}, type: [AccessHistoryType.LOGIN]}

export const CAS030 = () => {
  const [filters, setFilters] = useState<UserAccessHistoryListFactor>(defaultFilters)
  const [data, setData] = useState<UserAccessHistoryListResult[]>([])

  const getData = useGetUserAccessHistoryByPage()
  const search = useCallback((filters: UserAccessHistoryListFactor) => {
    getData(filters, {silent: true, serialized: true}).then(result => {
      // setTotalCount(result?.page?.totalCount || 0)
      setData(result?.data || [])
      sessionStorage.setItem(searchCachekey, JSON.stringify(UserAccessHistoryListFactorToJson(filters)))
    })
  }, [getData, setData])

  useSearchOnLoad(search, filters)

  return <CAS030PcUi
    filters={filters}
    setFilters={setFilters}
    search={search}
    data={data}
  />
}


