import { AxiosInstance } from 'axios'
import { PageResultEntity } from '../../utils/PageResultEntity'
import { AdjustAnnouncementAPIRequest } from "../models/AdjustAnnouncementAPIRequest"
import { AnnouncementAPIResponse } from '../models/AnnouncementAPIResponse'
import { CreateAnnouncementAPIRequest } from '../models/CreateAnnouncementAPIRequest'
import { EditAnnouncementAPIRequest } from '../models/EditAnnouncementAPIRequest'
import { ListAnnouncementsAPIRequest } from '../models/ListAnnouncementsAPIRequest'

const announcementApi = {
    get: function (axiosInstance: AxiosInstance, id: string) {
        return axiosInstance.get<AnnouncementAPIResponse>('/common-system-api/announcements/:id', { pathVars: { id } })
    },
    list: function (axiosInstance: AxiosInstance, data: ListAnnouncementsAPIRequest) {
        return axiosInstance.get<PageResultEntity<AnnouncementAPIResponse>>('/common-system-api/announcements', { params: data })
    },
    create: function (axiosInstance: AxiosInstance, data: CreateAnnouncementAPIRequest) {
        return axiosInstance.post<AnnouncementAPIResponse>('/common-system-api/announcements', data)
    },
    edit: function (axiosInstance: AxiosInstance, data: EditAnnouncementAPIRequest) {
        return axiosInstance.put<AnnouncementAPIResponse>('/common-system-api/announcements/:id', data, { pathVars: { id: data.id } })
    },
    confirm: function (axiosInstance: AxiosInstance, id: string) {
        return axiosInstance.post<AnnouncementAPIResponse>('/common-system-api/announcements/:id/confirm', {}, { pathVars: { id } })
    },
    adjust: function (axiosInstance: AxiosInstance, data: AdjustAnnouncementAPIRequest) {
        return axiosInstance.post<AnnouncementAPIResponse>('/common-system-api/announcements/:id/adjust', data, { pathVars: { id: data.id } })
    },
    cancel: function (axiosInstance: AxiosInstance, id: string) {
        return axiosInstance.post<AnnouncementAPIResponse>('/common-system-api/announcements/:id/cancel', {}, { pathVars: { id } })
    },
    delete: function (axiosInstance: AxiosInstance, id: string) {
        return axiosInstance.delete('/common-system-api/announcements/:id', { pathVars: { id } })
    },
    batchDelete: function (axiosInstance: AxiosInstance, ids: string[]) {
        return axiosInstance.post('/common-system-api/announcements/batch-delete', ids)
    },
}

export default announcementApi