import { makeStyles, MenuItem, Select, Tooltip, Typography } from "@material-ui/core"
import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, ObjectTypeProvider, Row, Searching, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { ObjectFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/ObjectFormatter"
import { Records } from "@rithe/utils"
import moment from "moment"
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CargoTacking, CargoTackingDetailResult, Events } from "../../../services/cargo/models/CargoTackingDetailResult"
import { TrackingCardHeader } from "./TrackingCardHeader"
import { TrackingType } from "./TrackingType"


export const SearchResult = ({ data }: { data: CargoTackingDetailResult }) => {
    const records = data.trackings ?? []
    return <>
        {
            records.map((record, idx) => <ResultCard cargoTracking={record} index={idx} />)
        }
    </>
}

export const ResultCard = ({
    cargoTracking,
    index
}: {
    cargoTracking: CargoTacking,
    index: number
}) => {
    const intl = useIntl()

    const containerNo = cargoTracking.containerNo ? cargoTracking.containerNo : ""
    const bookingNo = cargoTracking.bookingNo ? cargoTracking.bookingNo : ""
    const defaultType = useMemo(() => cargoTracking.realtime && cargoTracking.realtime.events && cargoTracking.realtime.events.length > 0 ? TrackingType.REALTIME : TrackingType.MANUAL, [cargoTracking.realtime])
    const [trackingType, setTrackingType] = useState<number>(defaultType)

    return <SectionCard allowCollapse defaultCollapse={index > 0} >
        <TrackingCardHeader
            title={intl.formatMessage({ id: 'bookingNumber' }) + ": " + bookingNo + "\xa0\xa0\xa0\xa0" + intl.formatMessage({ id: 'containerNumber' }) + ": " + containerNo}
        />
        <OverViewCards data={cargoTracking} trackingType={trackingType} setTrackingType={setTrackingType} />
        <SectionCardContent>
            <CargoTrackingTable data={cargoTracking} trackingType={trackingType} />
        </SectionCardContent>
    </SectionCard>
}

const OverViewCards = ({
    data,
    trackingType,
    setTrackingType
}: {
    data: CargoTacking,
    trackingType: TrackingType,
    setTrackingType: Dispatch<SetStateAction<number>>
}) => {
    const intl = useIntl();
    const styles = useStyles()
    const backgroundColor = trackingType === TrackingType.REALTIME ? "rgb(145,238,243)" : "rgb(128,128,128)"
    const color = trackingType === TrackingType.MANUAL ? "white" : "#003362"

    const planEta = useMemo(() => {
        const planEta = trackingType === TrackingType.REALTIME ? data.realtime?.planEta : data.manual?.planEta
        return planEta ? intl.formatDate(planEta, { dateStyle: 'medium' }) : ''
    }, [data.manual?.planEta, data.realtime?.planEta, intl, trackingType])

    const predictiveEta = useMemo(() => {
        const predictiveEta = trackingType === TrackingType.REALTIME ? data.realtime?.predictiveEta : data.manual?.predictiveEta
        return predictiveEta ? intl.formatDate(predictiveEta, { dateStyle: 'medium' }) : ''
    }, [data.manual?.predictiveEta, data.realtime?.predictiveEta, intl, trackingType])

    const lastEvent = useMemo(() => {
        const actualEvents = trackingType === TrackingType.REALTIME ? data.realtime?.events.filter(f => f.actualDate) : data.manual?.events.filter(f => f.actualDate);
        const lastEvent = actualEvents && actualEvents.length > 0 ? actualEvents[actualEvents?.length - 1] : null;
        return lastEvent ? lastEvent.milestone + ' @ ' + intl.formatDate(lastEvent.actualDate, { dateStyle: 'medium' }) : ''
    }, [data.manual?.events, data.realtime?.events, intl, trackingType])

    const status = useMemo(() => {
        const actualEvents = trackingType === TrackingType.REALTIME ? data.realtime?.events.filter(f => f.actualDate) : data.manual?.events.filter(f => f.actualDate);
        const lastEvent = actualEvents && actualEvents.length > 0 ? actualEvents[actualEvents?.length - 1] : null;
        return lastEvent && lastEvent.planedDate && lastEvent.actualDate > lastEvent.planedDate ? 'DELAYED' : 'ONTIME'
    }, [data.manual?.events, data.realtime?.events, trackingType])
    
    return <div style={{ display: "flex" }}>
        <Tooltip title={intl.formatMessage({ id: 'plannerETA' }) + " " + planEta}>
            <div className={styles.viewcard}><p>{intl.formatMessage({ id: 'plannerETA' }) + " " + planEta}</p></div>
        </Tooltip>
        <Tooltip title={intl.formatMessage({ id: 'predictiveETA' }) + " " + (predictiveEta ? predictiveEta : intl.formatMessage({ id: 'nullPredictiveETA' }))}>
            <div className={styles.viewcard} ><p>{intl.formatMessage({ id: 'predictiveETA' }) + " "}<span style={{ color: predictiveEta ? "" : "red" }}>{(predictiveEta ? predictiveEta : intl.formatMessage({ id: 'nullPredictiveETA' }))}</span></p></div>
        </Tooltip>
        <Tooltip title={intl.formatMessage({ id: 'latestEvent' }) + " " + lastEvent}>
            <div className={styles.viewcard}><p>{intl.formatMessage({ id: 'latestEvent' }) + " " + lastEvent}</p></div>
        </Tooltip>
        <div className={styles.viewcard} style={{ width: "18%", color: 'white', backgroundColor: status === 'ONTIME' ? 'green' : 'red' }}><p>{intl.formatMessage({ id: 'status' }) + ": " + status}</p></div>
        <div className={styles.select} style={{ color: color, backgroundColor: backgroundColor }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                <div style={{ paddingRight: 8 }}>{intl.formatMessage({ id: 'trackingType' })}</div>
                <Select style={{ color: color, fontSize: '0.65rem' }} value={trackingType} onChange={e => setTrackingType(e.target.value as number)}>
                    {
                        data.realtime?.events && data.realtime.events.length > 0 ? <MenuItem value={TrackingType.REALTIME}>{intl.formatMessage({ id: 'realTime' })}</MenuItem> : <></>
                    }
                    {
                        data.manual?.events && data.manual.events.length > 0 ? <MenuItem value={TrackingType.MANUAL}>{intl.formatMessage({ id: 'manual' })}</MenuItem> : <></>
                    }
                </Select>
            </div>
        </div>
    </div >
}

interface EventsEx extends Events {
    type: 'past' | 'current' | 'future'
}

const CargoTrackingTable = ({
    data,
    trackingType
}: {
    data: CargoTacking,
    trackingType: TrackingType
}) => {

    const events = useMemo(() => trackingType === TrackingType.MANUAL ? data.manual?.events : data.realtime?.events, [data.manual?.events, data.realtime?.events, trackingType])
    const intl = useIntl()

    const getVessels = useCallback((row: Row) => row.vessel ? row.vessel.join(",") : '', [])
    const columns = useMemo(() => [
        { field: 'milestone', dataTypeName: 'event', title: intl.formatMessage({ id: 'milestone' }), width: 400 },
        { field: 'location', dataTypeName: 'event', title: intl.formatMessage({ id: 'location' }), width: 200 },
        { field: 'planedDate', dataTypeName: 'eventdate', title: intl.formatMessage({ id: 'planned' }), width: 150 },
        { field: 'predictedDate', dataTypeName: 'eventdate', title: intl.formatMessage({ id: 'predicted' }), width: 150 },
        { field: 'actualDate', dataTypeName: 'eventdate', title: intl.formatMessage({ id: 'actual' }), width: 150 },
        { field: 'carrierForwarder', dataTypeName: 'event', title: intl.formatMessage({ id: 'carrier_forwarder' }), width: 180 },
        { field: 'vessel', dataTypeName: 'event', title: intl.formatMessage({ id: 'vessel' }), width: 200, getCellValue: (row: Row) => getVessels(row) },
        { field: 'status', dataTypeName: 'event', title: intl.formatMessage({ id: 'status' }), width: 150 },
    ], [getVessels, intl])

    const contents = useMemo(() => {
        const actualEvents = events?.filter(f => f.actualDate);
        const lastEvent = actualEvents && actualEvents.length > 0 ? actualEvents[actualEvents?.length - 1] : null;
        let type = 'past'
        return events == null ? [] : events.map(ent => {
            const status = ent && ent.planedDate && ent.actualDate > ent.planedDate ? 'DELAYED' : 'ONTIME'
            if (lastEvent === null) {
                type = type === 'past' ? 'current' : 'future'
                return { ...ent, type, status } as EventsEx
            } else {
                const preType = type
                type = lastEvent === ent ? 'current' : (type === 'current' || type === 'future') ? 'future' : 'past'
                return { ...ent, type: preType, status } as EventsEx
            }
        })
    }, [events])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <EventTypeProvider />
        <EventDateTypeProvider />
        <Data rows={contents} columns={columns} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={[]}
            columnSettings={{
                invoiceNo: { disableUserControl: true }
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <Action width={124} />
    </DataGrid>
}

/** Formatter for LastEvent  */
const EventFormatter = ({ value, row }: ObjectFormatterProps) => {
    return <>
        {row.type === 'past' ?
            <Typography variant="body2" style={{ color: '#888888' }}>{value}</Typography>
            : row.type === 'current' ?
                <Typography variant="body2" style={{ fontWeight: 'bold' }}>{value}</Typography>
                : <Typography variant="body2" style={{ color: 'blue' }}>{value}</Typography>
        }
    </>
}
const EventTypeProvider = () => {
    return <ObjectTypeProvider name="event" Formatter={EventFormatter} />
}

/** Formatter for LastEvent  */
const EventDateFormatter = ({ value, row }: ObjectFormatterProps) => {
    return <>
        {row.type === 'past' ?
            <Typography variant="body2" style={{ color: '#888888' }}>{!value ? '' : moment(value).format('DD MMM YYYY')}</Typography>
            : row.type === 'current' ?
                <Typography variant="body2" style={{ fontWeight: 'bold' }}>{!value ? '' : moment(value).format('DD MMM YYYY')}</Typography>
                : <Typography variant="body2" style={{ color: 'blue' }}>{!value ? '' : moment(value).format('DD MMM YYYY')}</Typography>
        }
    </>
}
const EventDateTypeProvider = () => {
    return <ObjectTypeProvider name="eventdate" Formatter={EventDateFormatter} />
}

const useStyles = makeStyles(theme => ({

    viewcard: {
        width: "30%",
        backgroundColor: "white",
        boxSizing: 'border-box',
        border: "1px solid rgb(208,208,208)",
        marginRight: theme.spacing(1),
        padding: theme.spacing(1),
        borderRadius: theme.spacing(1),
        height: "2.7rem",
        overflow: "hidden",
        //whiteSpace: "nowrap",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: 'center',
        '&>p': {
            display: '-webkit-inline-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical'
        }
    },
    select: {
        width: "18%",
        minWidth: "220px",
        border: "1px solid rgb(208,208,208)",
        borderRadius: theme.spacing(1),
        boxSizing: 'border-box',
        height: "2.7rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    status: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70%',
        paddingRight: theme.spacing(1),
        borderRadius: theme.spacing(1)
    },

}))

