import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"
import { ActiveFlag } from "../enums/ActiveFlag"
import { BusinessType } from "../enums/BusinessType"
import { CalcOrderType } from "../enums/CalcOrderType"
import { ContractType } from "../enums/ContractType"
import { CustomsFlag } from "../enums/CustomsFlag"

export type TnmContract = Required & Partial<Optional> & TableEntity
export type TnmContractJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const tnmContractFromJson: Project<TnmContractJson, TnmContract> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
    activeFlag: ActiveFlag,
    contractType: ContractType,
    businessType: BusinessType,
    customsFlag: CustomsFlag,
    calcOrderType: CalcOrderType,
}

interface JsonRequired {
    contractId: number,
}

interface JsonOptional {
    contractNo: string,
    contractType: number,
    description: string,
    customerId: number,
    buyerBuId: number,
    sellerBuId: number,
    supplierId: number,
    businessType: number,
    OrderFrequency: number,
    paymentTermsId: string,
    currency: string,
    incotermsId: number,
    incotermsPlace: string,
    consigneeUid: string,
    accounteeUid: string,
    deliveryToUid: string,
    inboundDcId: number,
    forecastNum: number,
    targetLeadtime: number,
    customsFlag: number,
    calcOrderType: number,
    expCcUid: string,
    impCcUid: string,
    printHscodeFlag: number,
    activeFlag: number,
    confirmOrderLeadtime: number,
    leadtime: number,
    deliveryPlanStartDate: string,
    deliveryPlanEndDate: string,
    deliveryTypeFlag: number,
}