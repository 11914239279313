import { Button, ButtonGroup, Checkbox, Divider, Fade, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Menu, MenuItem, Switch, Tooltip, Typography } from "@material-ui/core"
import { AddAlert } from "@material-ui/icons"
import ErrorIcon from '@material-ui/icons/Error'
import EventNoteIcon from '@material-ui/icons/EventNote'
import { Action, Column, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, ObjectTypeProvider, PaginationLayout, Paging, Row, Searching, Selection, Sorting, StringTypeProvider, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { ObjectFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/ObjectFormatter"
import { EntriesItem, StringItem } from "@rithe/form"
import { Arrays, Records } from "@rithe/utils"
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { NeumorphismButton } from "../../../components/Button/NeumorphismButton"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { ActiveFlagTypeProvider } from "../../../components/DataGrid/typeProviders/ActiveFlagTypeProvider"
import { PopoverMenu } from "../../../components/PopoverMenu"
import { View } from "../../../components/View/View"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { useDownloadStockStatusById } from "../../../services/smt/api/smtDownloadApi"
import { Daily, dailyToJson } from "../../../services/smt/stockManagement/models/DailyRundownResult"
import { WeeklyRundownFactor } from "../../../services/smt/stockManagement/models/WeeklyRundownFactor"
import { WeeklyRundownResult } from "../../../services/smt/stockManagement/models/WeeklyRundownResult"
import { Pair } from "../../../services/utils/Pair"
import { useGetCompanyType } from "../../../utils/ApplicationUtils"
import sessionKeys from "../../../utils/sessionKeys"
import { useSetDataIntoSessionAndGotoUrl } from "../../../utils/sessionUtil"
import { applicationActions } from "../../Application/applicationSlice"

export interface SMGTS110PcUiProps {
    filters: WeeklyRundownFactor,
    search: (filters: WeeklyRundownFactor) => void,
    data: WeeklyRundownResult[],
    totalCount: number,
    setFilters: React.Dispatch<SetStateAction<WeeklyRundownFactor>>,
    customerList: Pair[],
    groupings: string[],
    selections: number[],
    setSelections: Dispatch<SetStateAction<number[]>>,
}

export const SMGTS110PcUi = (props: SMGTS110PcUiProps) => {
    const intl = useIntl()
    return <View flex>
        <SectionCard>
            <SectionCardHeader
                serialNumber={1}
                title={intl.formatMessage({ id: 'weeklyTitle' })}
                subtitle={intl.formatMessage({ id: 'weeklySubTitle' })}
                actions={<IncludeView {...props} />}
            />
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
            <LegendView />
        </SectionCard>
    </View>
}

const DataTable = ({ data, filters, setFilters, search, customerList, groupings, selections, setSelections }: SMGTS110PcUiProps) => {
    const intl = useIntl()
    const [order, setOrder] = useState<string[]>([])
    const cbdsType = useGetCompanyType()

    const columns = useMemo(() => {
        const defaultColumns1: Column[] = [
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 230 },
            { field: 'customerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerPartsNo' }), width: 230 },
            { field: 'customerBackNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerBackNo' }), width: 230 },
            { field: 'partsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsName' }), width: 230 },
        ]

        const roleColumn : Column[] = cbdsType === CbdsType.BU ?  [
            { field: 'customerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerCode' }), width: 200 }
        ] :  [
            { field: 'sellerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sellerCode' }), width: 200 }
        ] 

        const defaultColumns2: Column[] = [
            { field: 'supplierCodes', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierCodes' }), width: 200 },
            { field: 'expCountry', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.expCountry' }), width: 200 },
            { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 180 },
            { field: 'partType', dataTypeName: 'string', title: intl.formatMessage({ id: 'partType' }), width: 180 },
            { field: 'externalRefNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.externalRefNo' }), width: 230 },
            { field: 'expBalQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'expBalQty' }), width: 180 },
            { field: 'expWhStockQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'expWhStockQty' }), width: 180 },
            { field: 'onShippingQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'onShippingQty' }), width: 180 },
            { field: 'stockQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'totalStockQty' }), width: 280 },
            { field: 'unit', dataTypeName: 'string', title: intl.formatMessage({ id: 'unit' }), width: 200 },
            { field: 'stockDays', dataTypeName: 'stockDaysFormart', title: intl.formatMessage({ id: 'stockDays' }), width: 180 },
            { field: 'stockBox', dataTypeName: 'stockBoxFormart', title: intl.formatMessage({ id: 'field.StockBoxes' }), width: 180 },
            { field: 'alertSetting', dataTypeName: 'alertFormart', title: intl.formatMessage({ id: 'lowStockAlert' }), width: 200 },
            { field: 'nextInboundDateStr', dataTypeName: 'string', title: intl.formatMessage({ id: 'nextInboundDateStr' }), width: 200 },
            { field: 'nextIncomingQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'nextIncomingQty' }), width: 200 },
        ]
        
        const lastColumns: Column[] = [
            { field: 'buildoutStr', dataTypeName: 'string', title: intl.formatMessage({ id: 'buildoutStr' }), width: 200 },
            { field: 'buildoutMonthStr', dataTypeName: 'string', title: intl.formatMessage({ id: 'buildoutMonthStr' }), width: 200 },
            //{ field: 'alert', dataTypeName: 'alertFormart', title: intl.formatMessage({ id: 'lowStockAlert' }), width: 200 },
        ]
        const hiddenColimns: Column[] = [
            // { field: 'dataSource', dataTypeName: 'string', title: intl.formatMessage({ id: 'dataSource' }), width: 200 },
            // { field: 'alert', dataTypeName: 'string', title: intl.formatMessage({ id: 'lowStockAlert' }), width: 200 },
        ]
        const weekColumns: Column[] = []
        let weeks = data.find(f => f.weeklyAlerts)
        const weeksDate = data.map(m => m.weeklyAlertsDate)[0]
        const weekNums = data.map(m => m.weeks)[0] ?? []
        if (weeks && weeks.weeklyAlerts) {
            let i: number = 0
            while (i < 24) {
                const weekBetweenDate = weeksDate ? weeksDate[i] : ''
                const weekStartDate = intl.formatDate(Object.values(weekBetweenDate)[0], { month: 'short', day: '2-digit' })
                const weekEndDate = intl.formatDate(Object.values(weekBetweenDate)[1], { month: 'short', day: '2-digit' })
                weekColumns.push({ field: 'week' + i, dataTypeName: 'weekDataFormart', title: intl.formatMessage({ id: "week " + weekNums[i] + ' (' + weekStartDate + '~' + weekEndDate + ')' }), width: 250, getCellValue: getWeekValue(i) })
                i++
            }
        }
        return Arrays.concat(defaultColumns1, roleColumn, defaultColumns2, weekColumns, lastColumns, hiddenColimns)
    }, [cbdsType, data, intl])


    const defaultHiddenFields = useMemo(() => [
        // 'alert',
    ], [])

    const columnVisibilityOptions = {
        partsNo: { disableUserControl: true },
        customerPartsNo: { disableUserControl: true },
        customerBackNo: { disableUserControl: true },
        partsName: { disableUserControl: true },
        customerCode: { disableUserControl: true },
        supplierCodes: { disableUserControl: true },
        expCountry: { disableUserControl: true },
        spq: { disableUserControl: true },
        partType: { disableUserControl: true },
    }

    useEffect(() => {
        setOrder(columns.map(column => column.field))
    }, [columns])

    const getRowId = useCallback((row: any) => row.customerPartsId, [])
    const itemPropsForByParts = useMemo(() => ({ filters, setFilters, search }), [filters, setFilters, search])
    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search, customerList, groupings }), [filters, setFilters, search, customerList, groupings])
    const actionProps2 = useMemo(() => ({ selections, filters, data, groupings }), [selections, filters, data, groupings])
    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <ActiveFlagTypeProvider />
        <WeekDataFormartProvider />
        <AlertFormartProvider />
        <StockDaysFormartProvider />
        <StockBoxFormartProvider />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={StockByPartsAction} actionProps={itemPropsForByParts} />
        <ToolbarActionProvider Action={AlertOptions} actionProps={itemPropsForFilters} />
        <ToolbarActionProvider Action={DailyRundownDetailAction} actionProps={actionProps2} />
        <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps2} />
        {/* <DailyRundownAction selections={selections} />,
        <UploadAction key="uploadMaster" filters={filters} search={search} />, */}
        <ColumnFreeze defaultFreeze={{ partsNo: 'start' }} />
        <ColumnVisibility
            defaultHiddenFields={defaultHiddenFields}
            columnSettings={columnVisibilityOptions}
            ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering order={order} onOrderChange={setOrder} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={160} />
    </DataGrid>
}

const FilterItem = (props: {
    customerList: Pair[],
    groupings: string[],
    search: (filters: WeeklyRundownFactor) => void,
    filters: WeeklyRundownFactor,
    setFilters: React.Dispatch<SetStateAction<WeeklyRundownFactor>>
}) => {
    const { filters, setFilters, search, customerList, groupings } = props
    const intl = useIntl()

    const customersMap = useMemo(() => customerList ? customerList.map(m => [m.second, m.second] as [string, string]) : [], [customerList])
    const groupingsMap = useMemo(() => {
        const entries: [[string, string]] = [["", intl.formatMessage({ id: 'noGrouping' })]]
        groupings && groupings.forEach(g => entries.push([g, g]))
        return entries;
    }, [groupings, intl])

    const clear = useCallback((filters: WeeklyRundownFactor) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: WeeklyRundownFactor) => {
        return [
            filters.partsNosStr,
            filters.customerCodes,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field='partsNosStr' label={intl.formatMessage({ id: 'field.partsNo' })} />
        {/* <StringItem field='customerCodes' label={intl.formatMessage({ id: 'field.customerCode' })} /> */}
        <EntriesItem field="customerCodes" label={intl.formatMessage({ id: 'field.customerCode' })} entries={customersMap} />
        <EntriesItem field="groupings" label={intl.formatMessage({ id: 'grouping' })} entries={groupingsMap} />
    </FilterToolbarItem>
}


const getWeekValue = (index: number) => {
    return (row: Row) => ({
        index: index
    })
}
const WeekDataFormartProvider = () => {
    return <ObjectTypeProvider name="weekDataFormart" Formatter={WeekDayFormatter} />
}

const AlertFormartProvider = () => {
    return <StringTypeProvider name="alertFormart" Formatter={AlertFormartter} />
}

const AlertFormartter = ({ value, row }: ObjectFormatterProps) => {
    const style = useStyles()
    return <div className={style.numerRight} style={{ background: getWeekColor(row.alert), width: '100%' }}>
        <Typography variant="body1" >{row.alertSetting}</Typography>
    </div>
}

const StockDaysFormartProvider = () => {
    return <StringTypeProvider name="stockDaysFormart" Formatter={StockDaysFormart} />
}

const StockDaysFormart = ({ value, row }: ObjectFormatterProps) => {
    const style = useStyles();
    return <div className={style.numerRight} style={{ background: Boolean(row.byBox) ? 'rgba(166,166,166)' : '', width: '100%' }}>
        <Typography variant="body1" >{Boolean(row.byBox) ? undefined : row.stockDays}</Typography>
    </div>
}

const StockBoxFormartProvider = () => {
    return <StringTypeProvider name="stockBoxFormart" Formatter={StockBoxFormart} />
}

const StockBoxFormart = ({ value, row }: ObjectFormatterProps) => {
    const style = useStyles();
    return <div className={style.numerRight} style={{ background: Boolean(row.byBox) ? '' : 'rgba(166,166,166)', width: '100%' }}>
        <Typography variant="body1" >{Boolean(row.byBox) ? row.stockBox : undefined}</Typography>
    </div>
}


const WeekDayFormatter = ({ value, row }: ObjectFormatterProps) => {
    const style = useStyles()
    const intl = useIntl()

    if (row.byBox) {
        return <div className={style.numerRight} style={{ background: getWeekColor(row.weeklyAlerts[value.index]), width: '100%' }}>
            <Typography variant="body1" >{row.weeklyStockBoxes[value.index]}</Typography>
            {row.weeklyContainsShortageDate[value.index] ? <ProportionView progressData={{ shortageDateStr: row.shortageDateStr, stockQty: row.stockQty, stockDays: row.stockDays }} /> : ""}
        </div>
    } else if (row.weeklyNoUsage[value.index]) {
        return <div className={style.numerRight}>
            <Typography variant="body1" >{intl.formatMessage({ id: 'noValue' })}</Typography>
        </div>
    } else {
        return <div className={style.numerRight} style={{ background: getWeekColor(row.weeklyAlerts[value.index]), width: '100%' }}>
            <Typography variant="body1" >{row.weeklyStockDays[value.index]}</Typography>
            {row.weeklyContainsShortageDate[value.index] ? <ProportionView progressData={{ shortageDateStr: row.shortageDateStr, stockQty: row.stockQty, stockDays: row.stockDays }} /> : ""}
        </div >
    }

}
interface ProgressProps {
    progressData: { shortageDateStr: string, stockQty: number, stockDays: number },
}
const ProportionView = (props: ProgressProps) => {
    const intl = useIntl()
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const classes = useStyles()
    const progressData = props.progressData

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }
    const open = Boolean(anchorEl)
    // paddingLeft: 20,
    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}><ErrorIcon />
            {open && <PopoverMenu
                className={classes.popover}
                classes={{ paper: classes.paper }}
                open={open}
                onClose={handlePopoverClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                disableRestoreFocus
            >
                <div>
                    <Grid container>
                        <Grid item xs={6}>
                            {intl.formatMessage({ id: 'shortageDate' })}:
                        </Grid>
                        <Grid item xs={6}>
                            {progressData.shortageDateStr}
                        </Grid>
                    </Grid>
                    {/* <Grid container style={{ marginTop: 10 }}>
                        <Grid item xs={6}>
                            {intl.formatMessage({ id: 'stockQty' })}:
                        </Grid>
                        <Grid item xs={6}>
                            {progressData.stockQty}
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: 10 }}>
                        <Grid item xs={6}>
                            {intl.formatMessage({ id: 'stockDays' })}:
                        </Grid>
                        <Grid item xs={6}>
                            {progressData.stockDays}
                        </Grid>
                    </Grid> */}
                </div>
            </PopoverMenu>
            }
        </div>
    )
}

const getWeekColor = (alert: string) => {
    let color = ""
    switch (true) {
        case (alert === '1'):
            color = 'rgba(255,255,0,0.6)'//"#FFFF00";
            break
        case (alert === '2'):
            color = 'rgba(255,192,0,0.72)'//"#FFC000";
            break
        case (alert === '3'):
            color = 'rgba(255,80,80,1)' //"#FF5000";
            break
        case (alert === 'MAX'):
            color = 'rgba(112, 48, 160, 0.74)' //"#7030A0";
            break
        default:
            color = ""
            break
    }
    return color
}
const IncludeView = (props: SMGTS110PcUiProps) => {
    const intl = useIntl()
    const style = useStyles()
    const { filters, setFilters, search } = props
    const includePlanClick = useCallback(() => {
        // setFilters(filters => filters.includeShippingPlan === 1 ? filters : { ...filters, includeShippingPlan: 1 });
        setFilters(filters => {
            const newFilters = filters.includeShippingPlan === 1 ? filters : { ...filters, includeShippingPlan: 1 }
            search(newFilters)
            return newFilters
        })
    }, [search, setFilters])
    const actualOnlyClick = useCallback(() => {
        setFilters(filters => {
            const newFilters = filters.includeShippingPlan === 0 ? filters : { ...filters, includeShippingPlan: 0 }
            search(newFilters)
            return newFilters
        })
    }, [search, setFilters])
    const carrierOnlyClick = useCallback(() => {
        setFilters(filters => {
            const newFilters = filters.baseCarrierDate === 1 ? filters : { ...filters, baseCarrierDate: 1 }
            search(newFilters)
            return newFilters
        })
    }, [search, setFilters])
    const predictionClick = useCallback(() => {
        setFilters(filters => {
            const newFilters = filters.baseCarrierDate === 0 ? filters : { ...filters, baseCarrierDate: 0 }
            search(newFilters)
            return newFilters
        })
    }, [search, setFilters])

    return (
        <>
            <Grid container>
                <ButtonGroup disableRipple={true} variant="contained" className={style.buttonGroup}>
                    <Button className={filters.includeShippingPlan === 1 ? style.buttonNotChosen : style.buttonChosen} onClick={actualOnlyClick}>{intl.formatMessage({ id: 'actualOnly' })} </Button>
                    <Button className={filters.includeShippingPlan === 1 ? style.buttonChosen : style.buttonNotChosen} onClick={includePlanClick}>{intl.formatMessage({ id: 'includePlan' })} </Button>
                </ButtonGroup>
            </Grid>
            <Grid container>
                <ButtonGroup disableRipple={true} variant="contained" className={style.buttonGroup}>
                    <Button className={filters.baseCarrierDate === 1 ? style.buttonChosen : style.buttonNotChosen} onClick={carrierOnlyClick}>{intl.formatMessage({ id: 'carrierOnly' })} </Button>
                    <Button className={filters.baseCarrierDate === 1 ? style.buttonNotChosen : style.buttonChosen} onClick={predictionClick}>{intl.formatMessage({ id: 'prediction' })} </Button>
                </ButtonGroup>
            </Grid>
        </>
    )
}


const StockByPartsAction = (({ filters, setFilters, search }: {
    filters: WeeklyRundownFactor,
    setFilters: React.Dispatch<SetStateAction<WeeklyRundownFactor>>,
    search: (filters: WeeklyRundownFactor) => void
}) => {
    const onChange = useCallback((_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setFilters(filters => ({ ...filters, simulateByParts: checked }))
        search({ ...filters, simulateByParts: checked })
    }, [filters, search, setFilters])

    return <>
        <Tooltip title={<FormattedMessage id="stockQtyByPart" />}>
            <Switch checked={filters.simulateByParts} onChange={onChange} />
        </Tooltip>
    </>
})


const DownloadAction = ({ selections, filters, data }: { selections: number[], filters: WeeklyRundownFactor, data?: WeeklyRundownResult[] }) => {
    const dispatch = useDispatch()

    const downloadStockStatusById = useDownloadStockStatusById()
    const downloadStockStatusAction = useCallback(() => {
        const customerPartsIds = selections.length > 0 ? selections : (data?.map(m => m.customerPartsId) ?? [])
        if (customerPartsIds.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'download' }, messages: { code: 'w0028' } }))
        } else {
            downloadStockStatusById({ customerPartsIdList: customerPartsIds, includeShippingPlan: filters.includeShippingPlan, baseCarrierDate: filters.baseCarrierDate,simulateByParts: filters.simulateByParts })
        }
    }, [selections, data, dispatch, downloadStockStatusById, filters.includeShippingPlan, filters.baseCarrierDate, filters.simulateByParts])

    return <>
        <DownloadGroupedToolbarAction access="STCK.SMGTS110.DOWNLOAD">
            {onClose => <GroupedCallbackItem label={<FormattedMessage id="downloadStockStatus" />} onClose={onClose} callback={downloadStockStatusAction} />}
        </DownloadGroupedToolbarAction>
    </>
}

const DailyRundownDetailAction = (({ selections, data, filters }: {
    selections: number[],
    data: WeeklyRundownResult[],
    filters: WeeklyRundownFactor
}) => {
    const dispatch = useDispatch()
    const setSesion = useSetDataIntoSessionAndGotoUrl()
    const styles = useStyles();

    const dataList: WeeklyRundownResult[] = data.filter(f => f.customerPartsId && selections.indexOf(f.customerPartsId) >= 0)
    const partsList: Daily[] = useMemo(() => {
        return dataList.map(v => {
            return {
                partsNo: v.partsNo,
                customerPartsId: v.customerPartsId,
                simulateByParts: filters.simulateByParts ?? false,
                includePlanFlag: filters.includeShippingPlan === 1 ? true : false,
                baseCarrierDateFlag: filters.baseCarrierDate  === 1 ? true : false
            }
        })
    }, [dataList, filters.baseCarrierDate, filters.includeShippingPlan, filters.simulateByParts])

    const callback = useCallback(() => {
        if (selections.length > 0) {
            setSesion(sessionKeys.Data_SMGTS211, dailyToJson, partsList, '/weeklyRundown/dailyRundown')
        } else {
            dispatch(applicationActions.pushWarning({ title: { code: 'dailyRundown' }, messages: { code: 'w0002' } }))
        }
    }, [dispatch, partsList, selections.length, setSesion])
    return <>
        <Tooltip title={<FormattedMessage id="dailyRundown" />}>
            <NeumorphismButton onClick={callback} className={styles.root}><EventNoteIcon /></NeumorphismButton>
        </Tooltip>
    </>
})

const AlertOptions = ({ filters, setFilters, search }: {
    filters: WeeklyRundownFactor,
    setFilters: React.Dispatch<SetStateAction<WeeklyRundownFactor>>,
    search: (filters: WeeklyRundownFactor) => void,
}) => {

    const intl = useIntl()
    const styles = useStyles();
    const [open, setOpen] = useState<boolean>(false)

    const aletsMax = useMemo(() => {
        return filters.alerts ? filters.alerts.map(m => {
            if (m === 'MAX') {
                return 4
            } else {
                return Number(m)
            }
        }) : []
    }, [filters.alerts])
    const [checked, setChecked] = useState<number[]>(aletsMax)
    const handleClose = () => { setOpen(false) }
    const handleCheck = (value: number) => () => {
        const currentIndex = checked.indexOf(value)
        const newChecked = [...checked]
        if (currentIndex === -1) {
            newChecked.push(value)
        } else {
            newChecked.splice(currentIndex, 1)
        }
        setChecked(newChecked)
    }

    const typeList = useMemo(() => [
        { typeName: intl.formatMessage({ id: 'alert1' }), typeCode: 1 },
        { typeName: intl.formatMessage({ id: 'alert2' }), typeCode: 2 },
        { typeName: intl.formatMessage({ id: 'alert3' }), typeCode: 3 },
        { typeName: intl.formatMessage({ id: 'MAX' }), typeCode: 4 },
    ], [intl])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    let alerts: string[] = []
    const searchChangeFrom = useCallback(() => {
        if (checked.length !== 0) {
            if (checked.find(m => m === 1) !== undefined) {
                alerts.push('1')
            }
            if (checked.find(m => m === 2) !== undefined) {
                alerts.push('2')
            }
            if (checked.find(m => m === 3) !== undefined) {
                alerts.push('3')
            }
            if (checked.find(m => m === 4) !== undefined) {
                alerts.push('MAX')
            }
        }
        // setFilters({ ...filters, alerts: alerts })
        setFilters(filters => {
            const newFilters = { ...filters, alerts: alerts }
            search(newFilters)
            return newFilters
        })
    }, [alerts, checked, search, setFilters])

    const clearChangeFrom = useCallback(() => {
        setChecked([])
        setFilters({ ...filters, alerts: [] })
    }, [filters, setFilters])

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
        setOpen(true)
    }

    return <>
        <Tooltip title={<FormattedMessage id="alertOptions" />}>
            <NeumorphismButton onClick={handleClickListItem} className={styles.root}><AddAlert /></NeumorphismButton>
        </Tooltip>
        <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <List>
                {typeList.map((value, index) => {
                    const labelId = `checkbox-list-label-${index}`
                    return (
                        <>
                            <ListItem key={index} dense button onClick={handleCheck(value.typeCode)}>
                                <ListItemSecondaryAction>
                                    <Checkbox
                                        edge="end"
                                        onChange={handleCheck(value.typeCode)}
                                        checked={checked.indexOf(value.typeCode) !== -1}
                                        inputProps={{ 'aria-labelledby': labelId }} />
                                </ListItemSecondaryAction>
                                <ListItemText id={labelId} primary={value.typeName} />
                            </ListItem>
                            <Divider component="li" />
                        </>
                    )
                })}
            </List>
            <MenuItem>
                <Button onClick={searchChangeFrom} color="secondary" variant="contained" style={{ color: 'white' }}><FormattedMessage id="search" /></Button>
                <Button onClick={clearChangeFrom} color="secondary" variant="outlined" style={{ color: 'black', marginLeft: 8 }}><FormattedMessage id="clear" /></Button>
            </MenuItem>
        </Menu>
    </>
}

const LegendView = () => {

    const intl = useIntl()
    return <div style={{ width: '100%', display: 'flex', marginTop: 15, backgroundColor: 'white', alignItems: 'center', height: 42, borderRadius: 5, paddingBottom: 15 }}>
        <Typography variant="body2" style={{ fontWeight: 'bold', marginLeft: 20 }} >{intl.formatMessage({ id: 'legend' })}</Typography>
        <Legend color='alert1' label={intl.formatMessage({ id: 'alert1' })} />
        <Legend color='alert2' label={intl.formatMessage({ id: 'alert2' })} />
        <Legend color='alert3' label={intl.formatMessage({ id: 'alert3' })} />
        <Legend color='max' label={intl.formatMessage({ id: 'alertMax' })} />
    </div>
}

const Legend = ({ color, label }: { color: 'alert1' | 'alert2' | 'alert3' | 'max', label: string }) => {
    return <>
        <div style={{ backgroundColor: colors[color], marginLeft: 20, height: 10, width: 10 }}></div>
        <Typography variant="body2" color="textSecondary" style={{ marginLeft: 10 }}>{label}</Typography>
    </>
}

const colors = {
    alert1: "rgba(255,255,0,0.6)",
    alert2: "rgba(255,192,0,0.72)",
    alert3: "rgba(255,80,80,1)",
    max: "rgba(112,48,160,0.74)",
}

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1),
        width: 36,
    },
    alerts1: {
        color: 'rgba(255,255,0,0.6)',
        position: 'relative'
    },
    alerts2: {
        color: 'rgba(255,192,0,0.72)',
        position: 'relative'
    },
    alerts3: {
        color: 'rgba(255,80,80,1)',
        position: 'relative'
    },
    alertsMAX: {
        color: 'rgba(112,48,160,0.74)',
        position: 'relative'
    },
    circleAlerts3: {
        border: '2px solid rgba(0,0,255,0.6)',
        borderRadius: 9,
        width: 14,
        height: 14,
        position: 'absolute',
        top: 2,
        right: 2
    },
    circleAlerts: {
        border: '2px solid rgba(255,0,0,0.6)',
        borderRadius: 9,
        width: 14,
        height: 14,
        position: 'absolute',
        top: 2,
        right: 2
    },
    numerRight: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: '70%',
        paddingRight: theme.spacing(1),
        borderRadius: 5
    },
    buttonChosen: {
        width: '130px',
        backgroundColor: '#00cbad',
        '&:hover': {
            backgroundColor: '#00cbad',
        },
    },
    buttonNotChosen: {
        width: '130px',
        backgroundColor: '#bdbdbd',
        '&:hover': {
            backgroundColor: '#bdbdbd',
        },
    },
    buttonGroup: {
        marginBottom: '5px',
    },
    menuColor: {
        background: 'rgba(1, 38, 72, 0.9)',
    },
    popover: {
        pointerEvents: "none",
    },
    paper: {
        padding: theme.spacing(1),
    },
    default: {},
}))
