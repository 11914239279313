import { Dialog, DialogActions, DialogContent, Typography } from "@material-ui/core"
import { green } from "@material-ui/core/colors"
import { Add, Check, PlaylistAdd } from "@material-ui/icons"
import { Action, ColumnFreeze, ColumnResizing, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Row, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { Placeholder, useFieldComponent } from "@rithe/form"
import { GridContainer, GridItem } from "@rithe/ui"
import { Arrays, Records } from "@rithe/utils"
import { useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, IntlProvider, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { Access } from "../../../components/Access/Access"
import { CallbackViewAction } from "../../../components/Action/CallbackViewAction"
import { ResetCallbackViewAction } from "../../../components/Action/ResetCallbackViewAction"
import { SaveCallbackViewAction } from "../../../components/Action/SaveCallbackViewAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CallbackRowAction } from "../../../components/DataGrid/rowActions/CallbackRowAction"
import { CallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/CallbackToolbarAction"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { DarkDialog } from "../../../components/Dialog/DarkDialog"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { TransparentInputWrapper } from "../../../components/Form/TransparentInputWrapper"
import { View } from "../../../components/View/View"
import { Language, effectiveLanguage } from "../../../configs/i18n/Language"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { UserStatus } from "../../../services/system/enums/UserStatus"
import { CbdsType } from "../../../services/systemmaster/enums/CbdsType"
import { useMatch } from "../../../utils/useMatch"
import { useApplicationSelector } from "../../Application/applicationSlice"
import { cas020Actions, useCAS020Selector } from "../CAS020/CAS020Slice"
import { cas021Actions, useCAS021Selector } from "./CAS021Slice"
import messagesEnUs from './i18n/cas021.en-US.json'
import screenEnUs from './i18n/screen.en-US.json'

const messagesMap: Record<Language, Record<string, string>> = {
    'en-US': { ...messagesEnUs, ...screenEnUs },
    'zh-CN': { ...messagesEnUs, ...screenEnUs },
}

export const CAS021 = () => {
    const { path } = useMatch()
    const { id } = useParams() as { id: string }

    const dispatch = useDispatch()
    useEffect(() => {
        if (path === '/user/edit-:id') {
            dispatch(cas021Actions.pageInit({ mode: 'EDIT', id }))
        } else if (path === '/user/view-:id') {
            dispatch(cas021Actions.pageInit({ mode: 'VIEW', id }))
        } else if (path === '/user/create') {
            dispatch(cas021Actions.pageInit({ mode: 'CREATE' }))
        }
    }, [dispatch, id, path])

    return <ScreenIntlProvider>
        <RootView />
    </ScreenIntlProvider>
}

const ScreenIntlProvider = ({ children }: { children: React.ReactNode }) => {
    const language = useApplicationSelector(state => state.i18n.language)
    const timezone = useApplicationSelector(state => state.i18n.timezone)
    const messages = useMemo(() => {
        const messages = messagesMap[effectiveLanguage(language)]
        const defaultMessages = messagesMap[Language.DEFAULT]
        return { ...defaultMessages, ...messages }
    }, [language])
    return <IntlProvider messages={messages} locale={language} timeZone={timezone}>
        {children}
    </IntlProvider>
}

const RootView = () => {
    const intl = useIntl()
    const mode = useCAS021Selector(state => state.ui.mode)
    const id = useCAS021Selector(state => state.domain.user.id)
    const isSelf = useApplicationSelector(state => state.auth.user?.id === id)
    const power = useApplicationSelector(state => state.auth.user?.powerUser)
    const actions = useMemo(() => {
        const actions = []
        if (isSelf) {
            actions.push(<ChangePasswordAction key="changePassword" />)
        }
        if (isSelf && mode === 'VIEW') {
            actions.push(<EditAction key="edit" />)
        }
        if ((mode === 'CREATE' || mode === 'CLONETOCREATE') && (isSelf || power)) {
            actions.push(<CloneAction key="clone" />)
            actions.push(<ResetAction key="reset" />)
            actions.push(<CreateAction key="create" />)
        } else if (mode === 'EDIT' || mode === 'CLONETOEDIT') {
            actions.push(<CloneAction key="clone" />)
            actions.push(<ResetAction key="reset" />)
            actions.push(<UpdateAction key="update" />)
        }
        return actions
    }, [isSelf, mode, power])

    return <View actions={actions}>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={1}
                title={intl.formatMessage({ id: 'title.basicInfo' })}
                subtitle={intl.formatMessage({ id: 'subtitle.basicInfo' })}
            />
            <SectionCardContent>
                <BasicInfo />
            </SectionCardContent>
        </SectionCard>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={2}
                title={intl.formatMessage({ id: 'title.companyInfo' })}
                subtitle={intl.formatMessage({ id: 'subtitle.companyInfo' })}
            />
            <SectionCardContent>
                <CompanyInfo />
            </SectionCardContent>
        </SectionCard>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={2}
                title={intl.formatMessage({ id: 'title.buyerAndContractInfo' })}
                subtitle={intl.formatMessage({ id: 'subtitle.buyerAndContractInfo' })}
            />
            <SectionCardContent>
                <BuyerAndContractInfo />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const CreateAction = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    return <SaveCallbackViewAction
        access="ACCS.CAS021.SAVE"
        title={<FormattedMessage id="action.create" />}
        callback={() => dispatch(cas021Actions.clickCreate((id: string) => navigate(`/user/view-${id}`)))} />
}

const UpdateAction = () => {
    const dispatch = useDispatch()
    return <SaveCallbackViewAction
        access="ACCS.CAS021.SAVE"
        title={<FormattedMessage id="action.update" />}
        callback={() => dispatch(cas021Actions.clickUpdate((id: string) => dispatch(cas021Actions.pageInit({ mode: 'EDIT', id }))))} />
}

const EditAction = () => {
    const id = useCAS021Selector(state => state.domain.user.id)
    const navigate = useNavigate()
    return <CallbackViewAction
        access="ACCS.CAS021.SAVE"
        title={<FormattedMessage id="action.edit" />}
        callback={() => navigate(`/user/edit-${id}`)} />
}

const CloneAction = () => {
    const intl = useIntl()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(cas020Actions.listAllUsers())
        dispatch(cas020Actions.listCbdss())
    }, [dispatch])

    const users = useCAS020Selector(state => state.users)
    const cbdss = useCAS020Selector(state => state.cbdss)
    const mode = useCAS021Selector(state => state.ui.mode)
    const clonedUserId = useCAS021Selector(state => state.ui.clonedUserId)
    const currentUser = useCAS021Selector(state => state.domain.user)

    const [open, setOpen] = useState(false)
    const [selection, setSelection] = useState<string>('')

    const onSelectionsChange = useCallback((selects: string[]) => {
        setSelection(selects[selects.length - 1])
    }, [])

    const getLoginId = useCallback((row: Row) => row.account.loginId, [])
    const getCbds = useCallback((row: Row) => {
        return row.cbdss.map((userCbds: any) => {
            return cbdss.find(cbds => cbds.cbdsUid === userCbds.cbdsUid)?.cbdsCode ?? ''
        }).join(', ')
    }, [cbdss])

    const getStatus = useCallback((row: Row) => {
        if (row.account.active && !row.account.locked) {
            return intl.formatMessage({ id: 'enum.active' })
        } else if (row.account.active && row.account.locked) {
            return intl.formatMessage({ id: 'enum.locked' })
        } else {
            return intl.formatMessage({ id: 'enum.inactive' })
        }
    }, [intl])

    const columns = useMemo(() => [
        { field: 'loginId', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.loginId' }), width: 150, getCellValue: getLoginId },
        { field: 'name', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.name' }), width: 200 },
        { field: 'email', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.companyEmail' }), width: 300 },
        { field: 'contactCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.companyContactCode' }), width: 150 },
        { field: 'localCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.companyLocalCode' }), width: 150 },
        { field: 'phone', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.companyPhone' }), width: 150 },
        { field: 'cbds', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.cbds' }), width: 250, getCellValue: getCbds },
        { field: 'status', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.status' }), width: 100, getCellValue: getStatus },
    ], [getCbds, getLoginId, getStatus, intl])

    const getRowId = useCallback((row: Row) => row.id, [])

    return <>
        <CallbackViewAction outlined access="ACCS.CAS021.CLONE" title={<FormattedMessage id="action.clone" />}
            callback={() => {
                setSelection(mode === 'CLONETOEDIT' || mode === 'CLONETOCREATE' ? clonedUserId : currentUser.id)
                setOpen(true)
            }} />
        <Dialog open={open} style={{ overflow: 'hidden' }} fullWidth maxWidth={'md'}>
            <DialogHeader onClose={() => setOpen(false)}><FormattedMessage id="title.userList" /></DialogHeader>
            <DialogContent style={{ overflow: 'hidden' }}>
                <div style={{ width: '100%', height: 500, display: 'flex' }}>
                    <div style={{ flex: '1 1 auto', overflow: 'hidden' }}>
                        <DataGrid>
                            <ToolbarLayout />
                            <TableLayout Container={FlexScrollbar}>
                                <TableHeaderLayout sticky />
                                <TableBodyLayout />
                            </TableLayout>
                            <PaginationLayout Pagination={Pagination} />
                            <DataTypePreset />
                            <CodeCategoryTypeProvider codeCategory={CodeCategory.CbdsType} />
                            <Data rows={users} columns={columns} getRowId={getRowId} />
                            <ColumnFreeze />
                            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
                            <Sorting />
                            <Filtering />
                            <Searching ignoreCase Input={SearchInput} />
                            <Selection showSelectAll highlightSelectedRow selectedRowIds={[selection]} onSelectedRowIdsChange={onSelectionsChange} />
                        </DataGrid>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="action.cancel" />} callback={() => setOpen(false)} />
                <DialogAction title={<FormattedMessage id="action.confirm" />}
                    callback={() => {
                        if (selection) {
                            const targetMode = mode === 'CLONETOEDIT' || mode === 'EDIT' ? 'CLONETOEDIT' : 'CLONETOCREATE'
                            dispatch(cas021Actions.pageInit({ mode: targetMode, id: selection }))
                        }
                        setOpen(false)
                    }} />
            </DialogActions>
        </Dialog>
    </>
}

const ResetAction = () => {
    const dispatch = useDispatch()
    return <ResetCallbackViewAction outlined
        access="ACCS.CAS021.RESET"
        title={<FormattedMessage id="action.reset" />}
        callback={() => dispatch(cas021Actions.clickReset())} />
}

const ChangePasswordAction = () => {
    const [open, setOpen] = useState(false)
    const [oldPassword, setOldPassword] = useState<string | null>('')
    const [newPassword, setNewPassword] = useState<string | null>('')
    const [confirmPassword, setConfirmPassword] = useState<string | null>('')
    const [pass, setPass] = useState([false, false, false, false, false])

    useEffect(() => {
        setPass(pass => {
            const pass0 = !!newPassword && newPassword.length >= 8
            const pass1 = /[a-z]/.test(newPassword || '') && /[A-Z]/.test(newPassword || '')
            const pass2 = /[a-zA-Z]/.test(newPassword || '') && /[0-9]/.test(newPassword || '')
            const pass3 = /[!"#$%&'()*+,-./:;=?@[\]^_`{|}~]/.test(newPassword || '')
            const pass4 = !!newPassword && newPassword.length > 0 && !/[<>]/.test(newPassword || '')
            if (pass0 !== pass[0] || pass1 !== pass[1] || pass2 !== pass[2] || pass3 !== pass[3] || pass4 !== pass[4]) {
                return [pass0, pass1, pass2, pass3, pass4]
            } else {
                return pass
            }
        })
    }, [newPassword])

    const { Control, Label, Input, InputWrapper } = useFieldComponent('PasswordInput', {})
    const dispatch = useDispatch()
    return <>
        <CallbackViewAction access="ACCS.CAS021.CHANGEPASSWORD" outlined
            title={<FormattedMessage id="action.changePassword" />}
            callback={() => setOpen(true)} />
        <DarkDialog open={open} style={{ overflow: 'hidden' }} fullScreen={false}>
            <DialogHeader onClose={() => setOpen(false)}>
                <FormattedMessage id="title.changePassword" />
            </DialogHeader>
            <DialogContent>
                <div>
                    <GridContainer>
                        <Control
                            inputWrapper={<InputWrapper />}
                            label={<Label><FormattedMessage id="field.oldPassword" /></Label>}
                            input={<Input
                                field="oldPassword"
                                value={oldPassword}
                                setValue={setOldPassword}
                            />}
                        />
                        <Control
                            inputWrapper={<InputWrapper />}
                            label={<Label><FormattedMessage id="field.newPassword" /></Label>}
                            input={<Input
                                field="newPassword"
                                value={newPassword}
                                setValue={setNewPassword}
                            />}
                        />
                        <Control
                            inputWrapper={<InputWrapper />}
                            label={<Label><FormattedMessage id="field.confirmPassword" /></Label>}
                            input={<Input
                                field="confirmPassword"
                                value={confirmPassword}
                                setValue={setConfirmPassword}
                            />}
                        />
                        <GridItem style={{ marginTop: 16 }}>
                            <Typography variant="caption" display="block" gutterBottom>
                                {pass[0] && <Check fontSize="small" style={{ height: 13, color: green[500] }} />}
                                {"▪ At least 8 characters."}
                            </Typography>
                            <Typography variant="caption" display="block" gutterBottom>
                                {pass[1] && <Check fontSize="small" style={{ height: 13, color: green[500] }} />}
                                {"▪ A mixture of both uppercase and lowercase letters."}
                            </Typography>
                            <Typography variant="caption" display="block" gutterBottom>
                                {pass[2] && <Check fontSize="small" style={{ height: 13, color: green[500] }} />}
                                {"▪ A mixture of letters and numbers."}
                            </Typography>
                            <Typography variant="caption" display="block" gutterBottom>
                                {pass[3] && <Check fontSize="small" style={{ height: 13, color: green[500] }} />}
                                {"▪ Inclusion of at least one special character, e.g., ! @ # ? ] "}
                            </Typography>
                            <Typography variant="caption" display="block" gutterBottom>
                                {pass[4] && <Check fontSize="small" style={{ height: 13, color: green[500] }} />}
                                {"▪ Note: do not use < or > in your password."}
                            </Typography>
                        </GridItem>
                    </GridContainer>
                </div>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="action.cancel" />} callback={() => setOpen(false)} />
                <DialogAction outlined disabled={!pass.every(v => v) || newPassword !== confirmPassword} title={<FormattedMessage id="action.confirm" />} callback={() => {
                    dispatch(cas021Actions.clickChangePassword({ oldPassword: oldPassword || '', newPassword: newPassword || '' }))
                    setOpen(false)
                }} />
            </DialogActions>
        </DarkDialog>
    </>
}

const BasicInfo = () => {
    return <GridContainer
        component="form"
        columnWidth={Arrays.repeat('1fr', 3)}
        columnGap={24}
        rowHeight="max-content"
    >
        <GridItem style={{ overflow: 'hidden' }}>
            <LoginIdInput />
        </GridItem>
        <GridItem style={{ overflow: 'hidden' }}>
            <NameInput />
        </GridItem>
        <GridItem>
            <Placeholder />
        </GridItem>
        <GridItem style={{ overflow: 'hidden' }}>
            <ContactCodeInput />
        </GridItem>
        <GridItem style={{ overflow: 'hidden' }}>
            <LocalCodeInput />
        </GridItem>
        <GridItem>
            <Placeholder />
        </GridItem>
        <GridItem style={{ overflow: 'hidden' }}>
            <PhoneInput />
        </GridItem>
        <GridItem style={{ overflow: 'hidden' }}>
            <EmailInput />
        </GridItem>
        <GridItem>
            <Placeholder />
        </GridItem>
        <GridItem style={{ overflow: 'hidden' }}>
            <DefaultCbdsInput />
        </GridItem>
        <GridItem style={{ overflow: 'hidden' }}>
            <StatusInput />
        </GridItem>
        <GridItem>
            <Placeholder />
        </GridItem>
        <Access power>
            <GridItem style={{ overflow: 'hidden' }}>
                <PowerUserInput />
            </GridItem>
        </Access>
        <GridItem style={{ overflow: 'hidden' }}>
            <ActivateChatInput />
        </GridItem>
    </GridContainer>
}

const LoginIdInput = () => {
    const value = useCAS021Selector(state => state.domain.user.account.loginId)
    const readonly = useCAS021Selector(state => state.ui.mode === 'VIEW' || state.ui.mode === 'EDIT')
    const visited = useCAS021Selector(state => state.ui.visitedMap.loginId)
    const messages = useCAS021Selector(state => state.ui.messagesMap.loginId)
    const { Control, Label, Helper, Input, InputWrapper } = useFieldComponent('StringInput', {})
    const dispatch = useDispatch()
    return <Control
        inputWrapper={<InputWrapper readonly={readonly} error={visited && messages.length > 0} />}
        label={<Label required readonly={readonly}><FormattedMessage id="field.loginId" /></Label>}
        input={<Input
            field="loginId"
            value={value}
            setValue={(value) => {
                dispatch(cas021Actions.setFieldVisited({ field: 'loginId', visited: true }))
                dispatch(cas021Actions.setLoginId(value || ''))
            }}
            readonly={readonly}
        />}
        helper={<Helper messages={visited ? messages : undefined} />}
    />
}

const NameInput = () => {
    const value = useCAS021Selector(state => state.domain.user.name)
    const readonly = useCAS021Selector(state => state.ui.mode === 'VIEW')
    const visited = useCAS021Selector(state => state.ui.visitedMap.name)
    const messages = useCAS021Selector(state => state.ui.messagesMap.name)
    const { Control, Label, Helper, Input, InputWrapper } = useFieldComponent('StringInput', {})
    const dispatch = useDispatch()
    return <Control
        inputWrapper={<InputWrapper readonly={readonly} error={visited && messages.length > 0} />}
        label={<Label required readonly={readonly}><FormattedMessage id="field.name" /></Label>}
        input={<Input
            field="name"
            value={value}
            setValue={(value) => {
                dispatch(cas021Actions.setFieldVisited({ field: 'name', visited: true }))
                dispatch(cas021Actions.setName(value || ''))
            }}
            readonly={readonly}
        />}
        helper={<Helper messages={visited ? messages : undefined} />}
    />
}

const ContactCodeInput = () => {
    const value = useCAS021Selector(state => state.domain.user.contactCode || '')
    const readonly = useCAS021Selector(state => state.ui.mode === 'VIEW')
    const visited = useCAS021Selector(state => state.ui.visitedMap.contactCode)
    const messages = useCAS021Selector(state => state.ui.messagesMap.contactCode)
    const { Control, Label, Helper, Input, InputWrapper } = useFieldComponent('StringInput', {})
    const dispatch = useDispatch()
    return <Control
        inputWrapper={<InputWrapper readonly={readonly} error={visited && messages.length > 0} />}
        label={<Label readonly={readonly}><FormattedMessage id="field.companyContactCode" /></Label>}
        input={<Input
            field="contactCode"
            value={value}
            setValue={(value) => {
                dispatch(cas021Actions.setFieldVisited({ field: 'contactCode', visited: true }))
                dispatch(cas021Actions.setContactCode(value || undefined))
            }}
            readonly={readonly}
        />}
        helper={<Helper messages={visited ? messages : undefined} />}
    />
}

const LocalCodeInput = () => {
    const value = useCAS021Selector(state => state.domain.user.localCode || '')
    const readonly = useCAS021Selector(state => state.ui.mode === 'VIEW')
    const visited = useCAS021Selector(state => state.ui.visitedMap.localCode)
    const messages = useCAS021Selector(state => state.ui.messagesMap.localCode)
    const { Control, Label, Helper, Input, InputWrapper } = useFieldComponent('StringInput', {})
    const dispatch = useDispatch()
    return <Control
        inputWrapper={<InputWrapper readonly={readonly} error={visited && messages.length > 0} />}
        label={<Label readonly={readonly}><FormattedMessage id="field.companyLocalCode" /></Label>}
        input={<Input
            field="localCode"
            value={value}
            setValue={(value) => {
                dispatch(cas021Actions.setFieldVisited({ field: 'localCode', visited: true }))
                dispatch(cas021Actions.setLocalCode(value || undefined))
            }}
            readonly={readonly}
        />}
        helper={<Helper messages={visited ? messages : undefined} />}
    />
}

const PhoneInput = () => {
    const value = useCAS021Selector(state => state.domain.user.phone || '')
    const readonly = useCAS021Selector(state => state.ui.mode === 'VIEW')
    const visited = useCAS021Selector(state => state.ui.visitedMap.phone)
    const messages = useCAS021Selector(state => state.ui.messagesMap.phone)
    const { Control, Label, Helper, Input, InputWrapper } = useFieldComponent('StringInput', {})
    const dispatch = useDispatch()
    return <Control
        inputWrapper={<InputWrapper readonly={readonly} error={visited && messages.length > 0} />}
        label={<Label readonly={readonly}><FormattedMessage id="field.companyPhone" /></Label>}
        input={<Input
            field="phone"
            value={value}
            setValue={(value) => {
                dispatch(cas021Actions.setFieldVisited({ field: 'phone', visited: true }))
                dispatch(cas021Actions.setPhone(value || undefined))
            }}
            readonly={readonly}
        />}
        helper={<Helper messages={visited ? messages : undefined} />}
    />
}

const EmailInput = () => {
    const value = useCAS021Selector(state => state.domain.user.email || '')
    const readonly = useCAS021Selector(state => state.ui.mode === 'VIEW')
    const visited = useCAS021Selector(state => state.ui.visitedMap.email)
    const messages = useCAS021Selector(state => state.ui.messagesMap.email)
    const { Control, Label, Helper, Input, InputWrapper } = useFieldComponent('StringInput', {})
    const dispatch = useDispatch()
    return <Control
        inputWrapper={<InputWrapper readonly={readonly} error={visited && messages.length > 0} />}
        label={<Label required readonly={readonly}><FormattedMessage id="field.companyEmail" /></Label>}
        input={<Input
            field="email"
            value={value}
            setValue={(value) => {
                dispatch(cas021Actions.setFieldVisited({ field: 'email', visited: true }))
                dispatch(cas021Actions.setEmail(value || ''))
            }}
            readonly={readonly}
        />}
        helper={<Helper messages={visited ? messages : undefined} />}
    />
}

const DefaultCbdsInput = () => {
    const value = useCAS021Selector(state => {
        const userCbds = state.domain.user.cbdss.find(cbds => cbds.defaultCbds)
        if (userCbds && state.domain.cbdss.length > 0) {
            return userCbds.cbdsUid
        } else {
            return ''
        }
    })
    const readonly = useCAS021Selector(state => state.ui.mode === 'VIEW')
    const visited = useCAS021Selector(state => state.ui.visitedMap.defaultCompanyUid)
    const messages = useCAS021Selector(state => state.ui.messagesMap.defaultCompanyUid)
    const entries = useCAS021Selector<[string, string][]>(state => {
        const userCbdss = state.domain.user.cbdss || []
        const cbdss = state.domain.cbdss
        return userCbdss.map(userCbds => {
            const cbds = cbdss.find(cbds => cbds.cbdsUid === userCbds.cbdsUid)
            return [userCbds.cbdsUid, cbds ? `${cbds.cbdsCode} ( ${cbds.cbdsName} )` : '']
        })
    })
    const { Control, Label, Helper, Input, InputWrapper } = useFieldComponent('EntryInput', {})
    const dispatch = useDispatch()
    return <Control
        inputWrapper={<InputWrapper readonly={readonly} error={visited && messages.length > 0} />}
        label={<Label required readonly={readonly}><FormattedMessage id="field.defaultCompanyUid" /></Label>}
        input={<Input
            field="defaultCompanyUid"
            value={value}
            setValue={(value) => {
                dispatch(cas021Actions.setFieldVisited({ field: 'defaultCompanyUid', visited: true }))
                dispatch(cas021Actions.setDefaultCbds(value as any || ''))
            }}
            readonly={readonly}
            entries={entries}
        />}
        helper={<Helper messages={visited ? messages : undefined} />}
    />
}

const StatusInput = () => {
    const intl = useIntl()
    const value = useCAS021Selector(state => {
        if (state.domain.user.account.active && !state.domain.user.account.locked) {
            return UserStatus.Active
        } else if (state.domain.user.account.active && state.domain.user.account.locked) {
            return UserStatus.Locked
        } else {
            return UserStatus.Inactive
        }
    })
    const readonly = useCAS021Selector(state => state.ui.mode === 'VIEW')
    const visited = useCAS021Selector(state => state.ui.visitedMap.status)
    const messages = useCAS021Selector(state => state.ui.messagesMap.status)
    const entries = useMemo<[number, string][]>(() => [
        [UserStatus.Active, intl.formatMessage({ id: 'enum.active' })],
        [UserStatus.Inactive, intl.formatMessage({ id: 'enum.inactive' })],
        [UserStatus.Locked, intl.formatMessage({ id: 'enum.locked' })]
    ], [intl])
    const { Control, Label, Helper, Input, InputWrapper } = useFieldComponent('EntryInput', {})
    const dispatch = useDispatch()
    return <Control
        inputWrapper={<InputWrapper readonly={readonly} error={visited && messages.length > 0} />}
        label={<Label required readonly={readonly}><FormattedMessage id="field.status" /></Label>}
        input={<Input
            field="status"
            value={value}
            setValue={(value) => {
                dispatch(cas021Actions.setFieldVisited({ field: 'status', visited: true }))
                dispatch(cas021Actions.setStatus(value as any || null))
            }}
            readonly={readonly}
            entries={entries}
        />}
        helper={<Helper messages={visited ? messages : undefined} />}
    />
}

const PowerUserInput = () => {
    const intl = useIntl()
    const value = useCAS021Selector(state => `${state.domain.user.powerUser || false}`)
    const readonly = useCAS021Selector(state => state.ui.mode === 'VIEW')
    const visited = useCAS021Selector(state => state.ui.visitedMap.powerUser)
    const messages = useCAS021Selector(state => state.ui.messagesMap.powerUser)
    const entires = useMemo<[string, string][]>(() => [
        ['true', intl.formatMessage({ id: 'enum.yes' })],
        ['false', intl.formatMessage({ id: 'enum.no' })]
    ], [intl])
    const { Control, Label, Helper, Input, InputWrapper } = useFieldComponent('EntryInput', {})
    const dispatch = useDispatch()
    return <Control
        inputWrapper={<InputWrapper readonly={readonly} error={visited && messages.length > 0} />}
        label={<Label required readonly={readonly}><FormattedMessage id="field.powerUser" /></Label>}
        input={<Input
            field="powerUser"
            value={value}
            setValue={(value) => {
                dispatch(cas021Actions.setFieldVisited({ field: 'powerUser', visited: true }))
                dispatch(cas021Actions.setPowerUser(value === 'true'))
            }}
            readonly={readonly}
            entries={entires}
        />}
        helper={<Helper messages={visited ? messages : undefined} />}
    />
}

const ActivateChatInput = () => {
    const intl = useIntl()
    const value = useCAS021Selector(state => `${state.domain.user.activateChat || false}`)
    const readonly = useCAS021Selector(state => state.ui.mode === 'VIEW')
    const visited = useCAS021Selector(state => state.ui.visitedMap.activateChat)
    const messages = useCAS021Selector(state => state.ui.messagesMap.activateChat)
    const entires = useMemo<[string, string][]>(() => [
        ['true', intl.formatMessage({ id: 'enum.yes' })],
        ['false', intl.formatMessage({ id: 'enum.no' })]
    ], [intl])
    const { Control, Label, Helper, Input, InputWrapper } = useFieldComponent('EntryInput', {})
    const dispatch = useDispatch()
    return <Control
        inputWrapper={<InputWrapper readonly={readonly} error={visited && messages.length > 0} />}
        label={<Label required readonly={readonly}><FormattedMessage id="field.activateChat" /></Label>}
        input={<Input
            field="activateChat"
            value={value}
            setValue={(value) => {
                dispatch(cas021Actions.setFieldVisited({ field: 'activateChat', visited: true }))
                dispatch(cas021Actions.setActivateChat(value === 'true'))
            }}
            readonly={readonly}
            entries={entires}
        />}
        helper={<Helper messages={visited ? messages : undefined} />}
    />
}

const CompanyInfo = () => {
    const roles = useCAS021Selector(state => state.domain.roles)
    const resources = useCAS021Selector(state => state.domain.resources)
    const userCbdss = useCAS021Selector(state => state.domain.user.cbdss || [])
    const getRoles = useCallback((row: any) => {
        return userCbdss.find(userCbds => userCbds.cbdsUid === row.cbdsUid)?.roleIds.map((roleId: string) => roles.find(role => role.id === roleId)?.name || '').join(', ')
    }, [roles, userCbdss])
    const getResources = useCallback((row: any) => {
        return userCbdss.find(userCbds => userCbds.cbdsUid === row.cbdsUid)?.resourceIds.map((resourceId: string) => resources.find(resource => resource.id === resourceId)?.code || '').join(', ')
    }, [resources, userCbdss])
    const intl = useIntl()
    const columns = useMemo(() => [
        { field: 'cbdsType', dataTypeName: CodeCategory.CbdsType, title: intl.formatMessage({ id: 'column.cbds.type' }), width: 150 },
        { field: 'cbdsCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.cbds.code' }), width: 200 },
        { field: 'cbdsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.cbds.name' }), width: 400 },
        { field: 'roles', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.cbds.roles' }), width: 400, getCellValue: getRoles },
        { field: 'resources', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.cbds.resources' }), width: 400, getCellValue: getResources },
    ], [getResources, getRoles, intl])
    const getRowId = useCallback((row: any) => row.cbdsUid, [])
    const displayAssign = useCAS021Selector(state => state.ui.mode !== 'VIEW')

    const cbdss = useCAS021Selector(state => state.domain.cbdss.filter(cbds => state.domain.user.cbdss.find(userCbds => userCbds.cbdsUid === cbds.cbdsUid)), (prev, next) => {
        return prev.length === next.length && prev.every((cbds, index) => cbds.cbdsUid === next[index].cbdsUid)
    })
    const visited = useCAS021Selector(state => state.ui.visitedMap.roleIds)
    const messages = useCAS021Selector(state => state.ui.messagesMap.roleIds)
    const { Control, Helper, InputWrapper } = useFieldComponent('StringInput', { InputWrapper: TransparentInputWrapper })
    return <Control
        inputWrapper={<InputWrapper error={visited && messages.length > 0} />}
        labelDisplay="none"
        label={<></>}
        input={
            <div style={{ width: '100%', height: 400, display: 'flex' }}>
                <div style={{ flex: '1 1 auto', overflow: 'hidden' }}>
                    <DataGrid>
                        <ToolbarLayout />
                        <TableLayout Container={FlexScrollbar}>
                            <TableHeaderLayout sticky />
                            <TableBodyLayout />
                        </TableLayout>
                        <PaginationLayout Pagination={Pagination} />
                        <DataTypePreset />
                        <CodeCategoryTypeProvider codeCategory={CodeCategory.CbdsType} />
                        <Data rows={cbdss} columns={columns} getRowId={getRowId} />
                        <ToolbarActionProvider Action={AssignCbdsAction} display={() => displayAssign} />
                        <RowActionProvider name="assignRoles" Action={AssignRolesRowAction} display={() => displayAssign} />
                        <RowActionProvider name="assignResources" Action={AssignResourcesRowAction} display={() => displayAssign} />
                        <ColumnFreeze />
                        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
                        <Sorting />
                        <Filtering />
                        <Action width={104} />
                    </DataGrid>
                </div>
            </div>
        }
        helper={<Helper messages={visited ? messages : undefined} />}
    />
}

const AssignCbdsAction = () => {
    const [open, setOpen] = useState(false)
    const [selections, setSelections] = useState<string[]>([])
    const cbdss = useCAS021Selector(state => state.domain.cbdss)
    const user = useCAS021Selector(state => state.domain.user)
    useEffect(() => {
        if (!open && user) {
            setSelections(user.cbdss.map(cbds => cbds.cbdsUid))
        }
    }, [open, user])

    const intl = useIntl()
    const columns = useMemo(() => [
        { field: 'cbdsType', dataTypeName: CodeCategory.CbdsType, title: intl.formatMessage({ id: 'column.cbds.type' }), width: 150 },
        { field: 'cbdsCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.cbds.code' }), width: 200 },
        { field: 'cbdsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.cbds.name' }), width: 400 },
    ], [intl])
    const getRowId = useCallback((row: any) => row.cbdsUid, [])

    const dispatch = useDispatch()
    return <>
        <CallbackToolbarAction power access="ACCS.CAS021.ASSGIN" title={<FormattedMessage id="action.assignCompany" />} icon={<PlaylistAdd />} callback={() => setOpen(true)} />
        <Dialog open={open} style={{ overflow: 'hidden' }} fullWidth maxWidth={'md'}>
            <DialogHeader onClose={() => setOpen(false)}><FormattedMessage id="title.companyList" /></DialogHeader>
            <DialogContent style={{ overflow: 'hidden' }}>
                <div style={{ width: '100%', height: 500, display: 'flex' }}>
                    <div style={{ flex: '1 1 auto', overflow: 'hidden' }}>
                        <DataGrid>
                            <ToolbarLayout />
                            <TableLayout Container={FlexScrollbar}>
                                <TableHeaderLayout sticky />
                                <TableBodyLayout />
                            </TableLayout>
                            <PaginationLayout Pagination={Pagination} />
                            <DataTypePreset />
                            <CodeCategoryTypeProvider codeCategory={CodeCategory.CbdsType} />
                            <Data rows={cbdss} columns={columns} getRowId={getRowId} />
                            <ColumnFreeze />
                            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
                            <Sorting />
                            <Filtering />
                            <Searching ignoreCase Input={SearchInput} />
                            <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
                        </DataGrid>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="action.cancel" />} callback={() => setOpen(false)} />
                <DialogAction title={<FormattedMessage id="action.confirm" />} callback={() => {
                    dispatch(cas021Actions.setCbdsUids(selections))
                    setOpen(false)
                }} />
            </DialogActions>
        </Dialog>
    </>
}

const AssignRolesRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const [open, setOpen] = useState(false)
    const [selections, setSelections] = useState<string[]>([])
    const userCbds = useCAS021Selector(state => state.domain.user.cbdss.find(cbds => cbds.cbdsUid === tableRow.row?.cbdsUid))
    const roles = useCAS021Selector(state => state.domain.roles)
    useEffect(() => {
        if (!open && userCbds) {
            setSelections(userCbds.roleIds)
        }
    }, [open, userCbds])

    const intl = useIntl()
    const getActive = useCallback((row: any) => {
        return row.active ? intl.formatMessage({ id: 'enum.active' }) : intl.formatMessage({ id: 'enum.inactive' })
    }, [intl])

    const columns = useMemo(() => [
        { field: 'name', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.role.name' }), width: 150 },
        { field: 'description', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.role.description' }), width: 300 },
        { field: 'active', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.role.active' }), width: 150, getCellValue: getActive },
    ], [getActive, intl])
    const getRowId = useCallback((row: any) => row.id, [])

    const dispatch = useDispatch()
    return <>
        <CallbackRowAction power access="ACCS.CAS021.ASSGIN" tableRow={tableRow} title={<FormattedMessage id="action.assignRole" />} icon={<Add />} callback={() => setOpen(true)} />
        <Dialog open={open} style={{ overflow: 'hidden' }} fullWidth maxWidth={'md'}>
            <DialogHeader onClose={() => setOpen(false)}><FormattedMessage id="title.roleList" /></DialogHeader>
            <DialogContent style={{ overflow: 'hidden' }}>
                <div style={{ width: '100%', height: 500, display: 'flex' }}>
                    <div style={{ flex: '1 1 auto', overflow: 'hidden' }}>
                        <DataGrid>
                            <ToolbarLayout />
                            <TableLayout Container={FlexScrollbar}>
                                <TableHeaderLayout sticky />
                                <TableBodyLayout />
                            </TableLayout>
                            <PaginationLayout Pagination={Pagination} />
                            <DataTypePreset />
                            <Data rows={roles} columns={columns} getRowId={getRowId} />
                            <ColumnFreeze />
                            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
                            <Sorting />
                            <Filtering />
                            <Searching ignoreCase Input={SearchInput} />
                            <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
                        </DataGrid>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="action.cancel" />} callback={() => setOpen(false)} />
                <DialogAction title={<FormattedMessage id="action.confirm" />} callback={() => {
                    dispatch(cas021Actions.setCbdsRoleIds({
                        cbdsUid: userCbds?.cbdsUid || '',
                        roleIds: selections,
                    }))
                    setOpen(false)
                }} />
            </DialogActions>
        </Dialog>
    </>
}

const AssignResourcesRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const [open, setOpen] = useState(false)
    const [selections, setSelections] = useState<string[]>([])
    const userCbds = useCAS021Selector(state => state.domain.user.cbdss.find(cbds => cbds.cbdsUid === tableRow.row?.cbdsUid))
    const resources = useCAS021Selector(state => state.domain.resources)
    useEffect(() => {
        if (!open && userCbds) {
            setSelections(userCbds.resourceIds)
        }
    }, [open, userCbds])

    const intl = useIntl()
    const getModuleValue = useCallback((row: any) => {
        const module = row.module
        return module ? intl.formatMessage({ id: module }) : ''
    }, [intl])
    const columns = useMemo(() => [
        { field: 'category', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.resource.category' }), width: 200 },
        { field: 'module', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.resource.module' }), width: 300, getCellValue: getModuleValue },
        { field: 'type', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.resource.type' }), width: 200 },
        { field: 'action', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.resource.action' }), width: 300 },
        { field: 'code', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.resource.code' }), width: 400 },
    ], [getModuleValue, intl])
    const getRowId = useCallback((row: any) => row.id, [])

    const dispatch = useDispatch()
    return <>
        <CallbackRowAction power access="ACCS.CAS021.ASSGIN" tableRow={tableRow} title={<FormattedMessage id="action.assignResource" />} icon={<PlaylistAdd />} callback={() => setOpen(true)} />
        <Dialog open={open} style={{ overflow: 'hidden' }} fullWidth maxWidth={'md'}>
            <DialogHeader onClose={() => setOpen(false)}><FormattedMessage id="title.resourceList" /></DialogHeader>
            <DialogContent style={{ overflow: 'hidden' }}>
                <div style={{ width: '100%', height: 500, display: 'flex' }}>
                    <div style={{ flex: '1 1 auto', overflow: 'hidden' }}>
                        <DataGrid>
                            <ToolbarLayout />
                            <TableLayout Container={FlexScrollbar}>
                                <TableHeaderLayout sticky />
                                <TableBodyLayout />
                            </TableLayout>
                            <PaginationLayout Pagination={Pagination} />
                            <DataTypePreset />
                            <Data rows={resources} columns={columns} getRowId={getRowId} />
                            <ColumnFreeze />
                            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
                            <Sorting />
                            <Filtering />
                            <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
                            <Searching ignoreCase Input={SearchInput} />
                        </DataGrid>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="action.cancel" />} callback={() => setOpen(false)} />
                <DialogAction title={<FormattedMessage id="action.confirm" />} callback={() => {
                    dispatch(cas021Actions.setCbdsResourceIds({
                        cbdsUid: userCbds?.cbdsUid || '',
                        resourceIds: selections,
                    }))
                    setOpen(false)
                }} />
            </DialogActions>
        </Dialog>
    </>
}

const BuyerAndContractInfo = () => {
    const cbdss = useCAS021Selector(state => state.domain.cbdss)
    const getBuyer = useCallback((row: any) => {
        const cbds = cbdss.find(cbds => (cbds.cbdsType === CbdsType.CUST && cbds.cbdsId === row.customerId)
            || (cbds.cbdsType === CbdsType.BU && cbds.cbdsId === row.buyerBuId))
        return cbds ? cbds.cbdsCode : ''
    }, [cbdss])
    const intl = useIntl()
    const columns = useMemo(() => [
        { field: 'contractNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.contract.contractNo' }), width: 280 },
        { field: 'buyer', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.contract.buyer' }), width: 280, getCellValue: getBuyer },
        { field: 'description', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.contract.description' }), width: 500 },
    ], [getBuyer, intl])
    const getRowId = useCallback((row: any) => row.contractId, [])

    const displayAssign = useCAS021Selector(state => state.ui.mode !== 'VIEW')
    const contracts = useCAS021Selector(state => state.domain.contracts.filter(contract => (state.domain.userContract?.contractIds || []).includes(`${contract.contractId}`)), (prev, next) => {
        return prev.length === next.length && prev.every((contract, index) => contract.contractId === next[index].contractId)
    })
    return <div style={{ width: '100%', height: 400, display: 'flex' }}>
        <div style={{ flex: '1 1 auto', overflow: 'hidden' }}>
            <DataGrid>
                <ToolbarLayout />
                <TableLayout Container={FlexScrollbar}>
                    <TableHeaderLayout sticky />
                    <TableBodyLayout />
                </TableLayout>
                <PaginationLayout Pagination={Pagination} />
                <DataTypePreset />
                <Data rows={contracts} columns={columns} getRowId={getRowId} />
                <ToolbarActionProvider Action={AssignContractAction} display={() => displayAssign} />
                <ColumnFreeze />
                <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
                <Sorting />
                <Filtering />
            </DataGrid>
        </div>
    </div>
}

const AssignContractAction = () => {
    const [open, setOpen] = useState(false)
    const [selections, setSelections] = useState<string[]>([])
    const userContract = useCAS021Selector(state => state.domain.userContract)
    useEffect(() => {
        if (!open && userContract) {
            setSelections(userContract.contractIds)
        }
    }, [open, userContract])

    const cbdss = useCAS021Selector(state => state.domain.cbdss)
    const getBuyer = useCallback((row: any) => {
        const cbds = cbdss.find(cbds => (cbds.cbdsType === CbdsType.CUST && cbds.cbdsId === row.customerId)
            || (cbds.cbdsType === CbdsType.BU && cbds.cbdsId === row.buyerBuId))
        return cbds ? cbds.cbdsCode : ''
    }, [cbdss])
    const intl = useIntl()
    const columns = useMemo(() => [
        { field: 'contractNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.contract.contractNo' }), width: 280 },
        { field: 'buyer', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.contract.buyer' }), width: 280, getCellValue: getBuyer },
        { field: 'description', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.contract.description' }), width: 500 },
    ], [getBuyer, intl])
    const getRowId = useCallback((row: any) => row.contractId + '', [])

    const dispatch = useDispatch()
    const contracts = useCAS021Selector(state => state.domain.contracts)
    useEffect(() => {
        if (open) {
            dispatch(cas021Actions.contractDialogInit())
        }
    }, [dispatch, open])
    return <>
        <CallbackToolbarAction access="ACCS.CAS021.ASSGIN" title={<FormattedMessage id="action.assignContract" />} icon={<PlaylistAdd />} callback={() => setOpen(true)} />
        <Dialog open={open} style={{ overflow: 'hidden' }} fullWidth maxWidth={'md'}>
            <DialogHeader onClose={() => setOpen(false)}><FormattedMessage id="title.contractList" /></DialogHeader>
            <DialogContent style={{ overflow: 'hidden' }}>
                <div style={{ width: '100%', height: 500, display: 'flex' }}>
                    <div style={{ flex: '1 1 auto', overflow: 'hidden' }}>
                        <DataGrid>
                            <ToolbarLayout />
                            <TableLayout Container={FlexScrollbar}>
                                <TableHeaderLayout sticky />
                                <TableBodyLayout />
                            </TableLayout>
                            <PaginationLayout Pagination={Pagination} />
                            <DataTypePreset />
                            <Data rows={contracts} columns={columns} getRowId={getRowId} />
                            <ColumnFreeze />
                            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
                            <Sorting />
                            <Filtering />
                            <Searching ignoreCase Input={SearchInput} />
                            <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
                        </DataGrid>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="action.cancel" />} callback={() => setOpen(false)} />
                <DialogAction title={<FormattedMessage id="action.confirm" />} callback={() => {
                    dispatch(cas021Actions.setContractIds(selections.map(s => s)))
                    setOpen(false)
                }} />
            </DialogActions>
        </Dialog>
    </>
}
