import { Project } from "../../../utils/Project"

export type DiPartForBuResult = Partial<Optional>
type DiPartForBuResultJson = Partial<JsonOptional>
export const DiPartForBuResultFromJson: Project<DiPartForBuResultJson, DiPartForBuResult> = json => ({
    ...json
})
interface Optional extends JsonOptional {
}

interface JsonOptional {
    grouping: string
    partsNo: string
    customerPartsNo: string
    customerCode: string
    oldPartsNo: string
    customerPartsName: string
}