import { Project } from "../../../utils/Project"
import { ValueType } from "../enums/ValueType"

export type SettingDetail = Required & Partial<Optional>
export type SettingDetailJson = JsonRequired & Partial<JsonOptional>
export const settingDetailToJson: Project<SettingDetail, SettingDetailJson> = obj => ({
    ...obj
})
export const settingDetailFromJson: Project<SettingDetailJson, SettingDetail> = json => ({
    ...json
})

interface Required extends JsonRequired {
    valueType: ValueType,
}

interface Optional extends JsonOptional {
}

interface JsonRequired {
    settingCode: string,
    description: string,
    valueType: number,
}

interface JsonOptional {
    validation: string,
    valueLength: string
    settingValue: string,
}