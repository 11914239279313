import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { Projects } from "../../../utils/Projects"
import { TnmContract, tnmContractFromJson } from "../../master/models/TnmContract"
import { AccountingFactor, AccountingFactorToJson } from "../models/AccountingDashboardFactor"
import { ContractResult, contractResultFromJson, CreditLimitResult, creditLimitResultFromJson, InvoiceResult, invoiceResultFromJson } from "../models/AccountingDashboardResult"
import { OrderMonthFactor, OrderMonthFactorTojson } from "../models/CostMonitorFactor"
import { CostResult, costResultFromJson, CostsOrderResult, costsOrderResultFromJson } from "../models/CostMonitorResult"
import { InventoryFactor, iventoryFactorToJson } from "../models/InventoryDashboardFactor"
import { InventoryResult, inventoryResultFromJson, ItemsResult, itemsResultFromJson, WareHouseResult, wareHouseResultFromJson } from "../models/InventoryDashboardResult"
import { OrderFactor, OrderFactorTojson } from "../models/OrderDashboardFactor"
import { OrderResult, orderResultFromJson } from "../models/OrderDashboardResult"
import { VerdorProfileFactor, verdorProfileFactorToJson } from "../models/VendorDasboardFactor"
import { VerdorProfileResult, verdorProfileResultFromJson } from "../models/VendorDasboardResult"

export const useGetOrderList = () => {
    const url = '/lcbm-dashboard-api/api/getOrderList'
    const payloadProject = useCallback(OrderFactorTojson, [])
    const resultProject = useCallback(orderResultFromJson, [])
    return usePost<OrderFactor, OrderResult>(url, payloadProject, resultProject)
}

export const useGetContractList = () => {
    const url = '/lcbm-dashboard-api/api/getContractList'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(contractResultFromJson), [])
    return usePost<undefined, ContractResult[]>(url, payloadProject, resultProject)
}

export const useGetInvoiceList = () => {
    const url = '/lcbm-dashboard-api/api/getInvoiceList'
    const payloadProject = useCallback(AccountingFactorToJson, [])
    const resultProject = useCallback(Projects.array(invoiceResultFromJson), [])
    return usePost<AccountingFactor, InvoiceResult[]>(url, payloadProject, resultProject)
}

export const useGetCreditLimitList = () => {
    const url = '/lcbm-dashboard-api/api/getCreditLimitList'
    const payloadProject = useCallback(AccountingFactorToJson, [])
    const resultProject = useCallback(Projects.array(creditLimitResultFromJson), [])
    return usePost<AccountingFactor, CreditLimitResult[]>(url, payloadProject, resultProject)
}

export const useGetDeliveryList = () => {
    const url = '/lcbm-dashboard-api/api/getDeliveriesList'
    const payloadProject = useCallback(verdorProfileFactorToJson, [])
    const resultProject = useCallback(Projects.array(verdorProfileResultFromJson), [])
    return usePost<VerdorProfileFactor, VerdorProfileResult[]>(url, payloadProject, resultProject)
}

export const useGetInboundRecordList = () => {
    const url = '/lcbm-dashboard-api/api/getInboundResultList'
    const payloadProject = useCallback(OrderMonthFactorTojson, [])
    const resultProject = useCallback(Projects.array(costResultFromJson), [])
    return usePost<OrderMonthFactor, CostResult[]>(url, payloadProject, resultProject)
}

export const useGetCostsOrderList = () => {
    const url = '/lcbm-dashboard-api/api/getCostsOrderList'
    const payloadProject = useCallback(OrderMonthFactorTojson, [])
    const resultProject = useCallback(Projects.array(costsOrderResultFromJson), [])
    return usePost<OrderMonthFactor, CostsOrderResult[]>(url, payloadProject, resultProject)
}

export const useGetDCList = () => {
    const url = '/lcbm-dashboard-api/api/getDCList'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(wareHouseResultFromJson), [])
    return usePost<undefined, WareHouseResult[]>(url, payloadProject, resultProject)
}

export const useGetItemsList = () => {
    const url = '/lcbm-dashboard-api/api/getItems'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(itemsResultFromJson), [])
    return usePost<undefined, ItemsResult[]>(url, payloadProject, resultProject)
}

export const useGetInventoryData = () => {
    const url = '/lcbm-dashboard-api/api/getInventoryData'
    const payloadProject = useCallback(iventoryFactorToJson, [])
    const resultProject = useCallback(inventoryResultFromJson, [])
    return usePost<InventoryFactor, InventoryResult>(url, payloadProject, resultProject)
}

export const useGetCurencyList = () => {
    const url = '/lcbm-dashboard-api/api/getCurrency'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(tnmContractFromJson), [])
    return usePost<undefined, TnmContract[]>(url, payloadProject, resultProject)
}