import { Project } from "../../../utils/Project"

export type InventoryByPartsResult = Required & Partial<Optional>
type InventoryByPartsResultJson = JsonRequired & Partial<JsonOptional>
export const inventoryByPartsResultFromJson: Project<InventoryByPartsResultJson, InventoryByPartsResult> = json => ({
    ...json,
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
}

interface JsonRequired {
}

interface JsonOptional {
    dcId: number,
    dcCode: string,
    customerId: number,
    customerCode: string,
    partsNo: string,
    unitPartsNo: string,
    backNo: string,
    colorCode: string,
    spq: number,
    incomingQty: number,
    stockQty: number,
    digits: number,
}