import { Data, DataGrid, DataTypePreset, PaginationLayout, Paging, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { EntryItem, Form } from "@rithe/form"
import React, { SetStateAction, useCallback, useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { UploadCallbackViewAction } from "../../../components/Action/UploadCallbackViewAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { ViewPanel } from "../../../components/View/Tab/ViewPanel"
import { ViewPanels } from "../../../components/View/Tab/ViewPanels"
import { ViewTab } from "../../../components/View/Tab/ViewTab"
import { ViewTabControl } from "../../../components/View/Tab/ViewTabControl"
import { ViewTabs } from "../../../components/View/Tab/ViewTabs"
import { View } from "../../../components/View/View"
import { useUploadstorageDetail } from "../../../services/integration/apis/myUploadApis"
import { ExternalDataFactor } from "../../../services/integration/models/ExternalDataFactor"
import { ExternalTemplateList } from "../../../services/integration/models/ExternalTemplateList"
import { FunctionList } from "../../../services/integration/models/FunctionList"
import { UploadedDataList } from "../../../services/integration/models/UploadedDataList"
import { applicationActions } from "../../Application/applicationSlice"

interface INT020PcUiProps {
    functionList: FunctionList[],
    categoryList: string[],
    templateList: ExternalTemplateList[],
    findTemplateList: (filters: ExternalDataFactor) => void,
    filters: ExternalDataFactor,
    setFilters: React.Dispatch<SetStateAction<ExternalDataFactor>>,
    data: UploadedDataList[]
    tabId: number,
    setTabId: React.Dispatch<SetStateAction<number>>,
    search: () => void
}

export const INT020PcUi = (props: INT020PcUiProps) => {
    const { functionList, categoryList, templateList, findTemplateList, filters, setFilters, tabId, setTabId, search } = props
    const intl = useIntl()

    const onSelectedValueChange = useCallback((value: string | number) => {
        setTabId(value as number)
        setFilters({})
    }, [setFilters, setTabId])

    return <View >
        <ViewTabControl>
            <ViewTabs onSelectedValueChange={onSelectedValueChange} selectedValue={tabId} >
                {categoryList.map((category, index) =>
                    <ViewTab label={category} value={index} key={index} />
                )}
            </ViewTabs>
            <ViewPanels>
                {categoryList.map((category, index) =>
                    <ViewPanel value={index} key={index}>
                        <SectionCard key={1} >
                            <SectionCardHeader
                                serialNumber={1}
                                title={intl.formatMessage({ id: 'selectCondition' })}
                            />
                            <SectionCardContent>
                                <TransactionData functionList={functionList.filter(f => f.category === category)}
                                    templateList={templateList} findTemplateList={findTemplateList} filters={filters} setFilters={setFilters}
                                />
                            </SectionCardContent>
                        </SectionCard>
                        {filters.externalTemplateId && <SectionCard key={2}>
                            <SectionCardHeader
                                actions={[<UploadStorageDetailAction filters={filters} search={search} />]}
                                serialNumber={2}
                                title={intl.formatMessage({ id: 'recentUploadedData' })}
                            />
                            <SectionCardContent>
                                <DataTable {...props} />
                            </SectionCardContent>
                        </SectionCard>}
                    </ViewPanel>)}
            </ViewPanels>
        </ViewTabControl>
    </View>
}

const TransactionData = ({ functionList, templateList, findTemplateList, filters, setFilters }: {
    functionList: FunctionList[]
    templateList: ExternalTemplateList[],
    findTemplateList: (filters: ExternalDataFactor) => void,
    filters: ExternalDataFactor,
    setFilters: React.Dispatch<SetStateAction<ExternalDataFactor>>,
}) => {
    const intl = useIntl()
    const functionMap = useMemo(() => functionList ? functionList.map(m => [m.code, m.name] as [string, string]) : [], [functionList])
    const templateMap = useMemo(() => templateList ? templateList.sort((a, b) => {
        const identifierA = a.identifier ?? ""
        const identifierB = b.identifier ?? ""
        return identifierA > identifierB ? 1 : identifierA === identifierB ? 0 : -1
    }).map(m => [m.templateId, m.identifier] as [string, string]) : [], [templateList])

    const handleDataChange = useCallback<React.Dispatch<React.SetStateAction<ExternalDataFactor>>>(nextDraftDataFunc => {
        setFilters(filters => {
            const newFilters = typeof nextDraftDataFunc === 'function' ? nextDraftDataFunc(filters) : nextDraftDataFunc
            if (filters.function !== newFilters.function) {
                const resetFilters = { ...newFilters, externalTemplateId: undefined }
                findTemplateList(resetFilters)
                return resetFilters
            }
            return newFilters
        })
    }, [findTemplateList, setFilters])

    return <Form data={filters} setData={handleDataChange} labelDisplay="block" helperDisplay="tooltip" columnCount={10}>
        <EntryItem field="function" label={intl.formatMessage({ id: 'functionList' })} entries={functionMap} colSpan={5} />
        <EntryItem field="externalTemplateId" label={intl.formatMessage({ id: 'selectDownloadFormat' })} entries={templateMap} colSpan={5} />
    </Form>
}

const DataTable = ({ data }: INT020PcUiProps) => {
    const intl = useIntl()
    const columns = useMemo(() => [
        { field: 'fileIdentifier', dataTypeName: 'string', title: intl.formatMessage({ id: 'transactionData' }), width: 200 },
        { field: 'fileName', dataTypeName: 'string', title: intl.formatMessage({ id: 'uploadFile' }), width: 350 },
        { field: 'createdBy', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.uploadedBy' }), width: 200 },
        { field: 'createdDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.uploadedDate' }), width: 200 },
    ], [intl])

    const list = data.sort((a, b) => { return (b.createdDate ?? 0) - (a.createdDate ?? 0) })

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <Data rows={list} columns={columns} />
        <Searching ignoreCase Input={SearchInput} />
        <Sorting />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
    </DataGrid>
}

const UploadStorageDetailAction = ({ filters, search }: { filters: ExternalDataFactor, search: () => void }) => {
    const dispatch = useDispatch()
    const uploadApi = useUploadstorageDetail();
    const upload = useCallback((files: FileList | null) => {
        if (files === null) {
            dispatch(applicationActions.pushError({ title: { code: 'uploadStorageDetail' }, messages: { code: 'w0005' } }))
            return
        }
        if (filters.externalTemplateId === null || filters.externalTemplateId === undefined) {
            dispatch(applicationActions.pushError({ title: { code: 'uploadStorageDetail' }, messages: { code: 'w1038' } }))
            return
        }
        uploadApi({ templateId: filters.externalTemplateId, file: files[0] }, { serialized: true }).then(() => search())
    }, [dispatch, filters.externalTemplateId, search, uploadApi])

    return <UploadCallbackViewAction title={<FormattedMessage id="uploadStorageDetail" />} callback={upload} />
}