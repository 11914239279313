import { Project } from "../../../utils/Project"
import { TnmCompanyTemp, tnmCompanyTempFromJson, TnmCompanyTempJson, tnmCompanyTempToJson } from "./TnmCompanyTemp"

export interface CompanyDetail {
    basicInfo: TnmCompanyTemp,
}
interface CompanyDetailJson {
    basicInfo: TnmCompanyTempJson,
}

export const companyDetailFromJson: Project<CompanyDetailJson, CompanyDetail> = json => ({
    basicInfo: tnmCompanyTempFromJson(json.basicInfo),
})

export const companyDetailToJson: Project<CompanyDetail, CompanyDetailJson> = obj => ({
    basicInfo: tnmCompanyTempToJson(obj.basicInfo),
})