import { Card, CardContent, Grid, makeStyles, Typography } from "@material-ui/core"
import { ReactNode } from "react"
import { FormattedDate, FormattedNumber } from "react-intl"
import { Currency } from "../../../utils/Currency"


interface Props {
    title?: string,
    stringData?: string,
    numberData?: number,
    dateData?: Date,
    percentageData?: number,
    currency?: Currency,
    prefix?: ReactNode,
    suffix?: ReactNode,
    noHeader?: boolean,
    color?: 'default' | 'safe' | 'alarm' | 'error' | 'invaild',
    subContents?: string,
    nowrap?: boolean,
}

export const DataCard = (props: Props) => {
    const { stringData, numberData, dateData, percentageData, currency, prefix, suffix, color, subContents, nowrap } = props
    const styles = useStyles()
    return (
        <Card elevation={3} className={styles.root} style={{ backgroundColor: color === 'alarm' ? '#F0B548' : color === 'error' ? '#CB4747' : color === 'invaild' ? '#ECEFF2' : undefined }}>
            <CardContent className={styles.content}>
                <Grid container direction="row" spacing={1} justifyContent="center" alignItems="center" alignContent="center">
                    {prefix && <Grid item>
                        <Typography variant="body1">{prefix}</Typography>
                    </Grid>}
                    {numberData !== undefined &&
                        <Grid item>
                            <Typography variant="h5" className={color ? styles[color] : styles.default}><FormattedNumber value={numberData} /></Typography>
                        </Grid>
                    }
                    {dateData &&
                        <Grid item>
                            <Typography variant="h5" className={color ? styles[color] : styles.default}><FormattedDate value={dateData} dateStyle="medium" /></Typography>
                        </Grid>
                    }
                    {stringData &&
                        <Grid item style={{ width: '100%', overflow: 'hidden' }}>
                            <Typography variant="h5" className={color ? styles[color] : styles.default}>{stringData}</Typography>
                        </Grid>
                    }
                    {percentageData !== undefined &&
                        <Grid item>
                            <Typography variant="h5" className={color ? styles[color] : styles.default}><FormattedNumber value={percentageData} /> %</Typography>
                        </Grid>
                    }
                    {currency &&
                        <Grid item>
                            <Typography variant="h5" className={color ? styles[color] : styles.default}>{currency}</Typography>
                        </Grid>
                    }
                    {suffix && <Grid item>
                        <Typography variant="h5" align="center" style={{ color: color === 'alarm' || color === 'error' ? 'white' : undefined }}>{suffix}</Typography>
                    </Grid>}
                </Grid>
                {subContents && <Grid container direction="row" spacing={0} justifyContent="center" alignItems="center" alignContent="center">
                    <Grid item style={{ width: '100%' }}>
                        <Typography variant="body2" color="textSecondary" style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: nowrap === false ? undefined : 'nowrap', color: color === 'alarm' || color === 'error' ? 'white' : undefined }}>{subContents}</Typography>
                    </Grid>
                </Grid>}
            </CardContent>
        </Card>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        paddingBottom: theme.spacing(1),
    },
    content: {
        paddingTop: theme.spacing(2),
        textAlign: "center",
        paddingBottom: "10px!important",
    },
    default: {
        color: theme.palette.common.black,
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    safe: {
        color: theme.palette.success.dark,
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    alarm: {
        color: theme.palette.common.white,
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    error: {
        color: theme.palette.common.white,
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    invaild: {
        color: theme.palette.common.black,
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },

}))