import { IconButton, Typography } from "@material-ui/core"
import { Details, InsertInvitation, UnarchiveOutlined } from "@material-ui/icons"
import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, DateTypeProvider, Filtering, PaginationLayout, Row, Searching, Selection, Sorting, StringTypeProvider, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { DateFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/DateFormatter"
import { StringFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/StringFormatter"
import { Arrays, Records } from "@rithe/utils"
import React, { useCallback, useMemo, useState } from "react"
import { FormattedDate, FormattedMessage, useIntl } from "react-intl"
import { DownloadCallbackCardAction } from "../../../components/Action/DownloadCallbackCardAction"
import { UploadCallbackCardAction } from "../../../components/Action/UploadCallbackCardAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { View } from "../../../components/View/View"
import { useDownloadOutTemplate, useDownloadOuterByParts } from "../../../services/delivery/apis/deliveryDownloadApi"
import { useDeliveryUploadOutbound } from "../../../services/delivery/apis/deliveryUploadApi"
import { OutboundDownloadFactor } from "../../../services/delivery/models/OutboundDownloadFactor"
import { OutboundPartsResult } from "../../../services/delivery/models/OutboundPartsResult"

interface LOS033PcUiProps {
    partsList: OutboundPartsResult[],
    search: () => void,
}
export const LOS033PcUi = (props: LOS033PcUiProps) => {
    const { partsList, search } = props
    const [selections, setSelections] = useState<string[]>([])

    const intl = useIntl()
    return <View>
        <SectionCard allowCollapse>
            <SectionCardHeader
                icon={<UnarchiveOutlined />}
                title={intl.formatMessage({ id: '(Optional) Choose outbound parts from in-stock parts list' })}
                subtitle="Choose outbound parts from list"
                actions={[
                    < DownloadAction key="download" selections={selections} />,
                    <UploadAction key="upload" search={search} />
                ]}
            />
            <SectionCardContent>
                <OutBoundTableView partsList={partsList} selections={selections} setSelections={setSelections} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const OutBoundTableView = ({ partsList, selections, setSelections }: { partsList: OutboundPartsResult[], selections: string[], setSelections: React.Dispatch<React.SetStateAction<string[]>> }) => {
    const intl = useIntl()
    const columns = useMemo(() => [
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 150, getCellValue: (row: Row) => row.packageNo ? '' : row.partsNo },
        { field: 'shipperCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'shipper' }), width: 150 },
        { field: 'reciverCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'reciver' }), width: 150 },
        { field: 'packageNo', dataTypeName: 'packageNo', title: intl.formatMessage({ id: 'field.packageNo' }), width: 150 },
        { field: 'inboundDate', dataTypeName: 'inboundDate', title: intl.formatMessage({ id: 'field.inboundDate' }), width: 150 },
    ], [intl])
    const getRowId = useCallback((row: any) => {
        if (row.packageNo !== null && row.packageNo !== undefined) {
            return "|" + row.packageNo
        }
        return row.partsNo + "|"
    }, [])

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <ExpandTypeProvider />
            <EditTypeProvider />
            <Data rows={partsList} columns={columns} getRowId={getRowId} />
            <ColumnFreeze />
            <ColumnVisibility ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
            <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        </DataGrid>
    </div>
}

const UploadAction = ({ search }: { search: () => void }) => {
    const uploadMethod = useDeliveryUploadOutbound()
    const uploadChangeFrom = useCallback((files: FileList | null) => {
        if (files === null) return
        uploadMethod({ file: files[0] }, { serialized: true }).then(() => {
            search()
        })
    }, [search, uploadMethod])

    return <UploadCallbackCardAction access="LOGI.LOS030.Upload" title={<FormattedMessage id="uploadDiForm" />} callback={uploadChangeFrom} />
}

const DownloadAction = ({ selections }: { selections: string[] }) => {
    const downloadTemplete = useDownloadOutTemplate()
    const downloadDiByParts = useDownloadOuterByParts()
    const download = useCallback(() => {
        if (selections && selections.length > 0) {
            const partsNoList: string[] = []
            const packageNoList: string[] = []
            const factor: OutboundDownloadFactor = {
                partsNoList: Arrays.distinct(partsNoList.filter(m => m !== null && m !== '')),
                packageNoList: Arrays.distinct(packageNoList.filter(m => m !== null && m !== '')),
            }
            downloadDiByParts(factor)
        }
        else {
            downloadTemplete([])
        }
    }, [downloadDiByParts, downloadTemplete, selections])

    return <DownloadCallbackCardAction outlined access="LOGI.LOS030.DOWNLOAD" callback={download} />
}

const ExpandFormatter = (props: StringFormatterProps) => {
    return props.value ? <Typography variant="body2">{props.value}</Typography> : <IconButton><Details /></IconButton>
}

const ExpandTypeProvider = () => {
    return <StringTypeProvider name="packageNo" Formatter={ExpandFormatter} />
}

const EditFormatter = (props: DateFormatterProps) => {
    return props.value ? <FormattedDate value={props.value} dateStyle="short" /> : <IconButton><InsertInvitation /></IconButton>
}

const EditTypeProvider = () => {
    return <DateTypeProvider name="inboundDate" Formatter={EditFormatter} />
}