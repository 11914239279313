import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { dateFromJson } from "../../utils/deserializer"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"
import { ActiveFlag } from "../enums/ActiveFlag"
import { BusinessType } from "../enums/BusinessType"

export type ContractDownloadFactor = Omit<ContractFactor, 'page'>
type ContractDownloadFactorJson = Omit<ContractFactorJson, 'page'>
export const contractDownloadFactorToJson: Project<ContractDownloadFactor, ContractDownloadFactorJson> = obj => ({
    ...obj,
    today: dateToJson(obj.today),
})

export type ContractFactor = Required & Partial<Optional>
type ContractFactorJson = JsonRequired & Partial<JsonOptional>
export const contractFactorToJson: Project<ContractFactor, ContractFactorJson> = obj => ({
    ...obj,
    today: dateToJson(obj.today),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
})

export const ContractFactorFromJson: Project<ContractFactorJson, ContractFactor> = json => ({
    ...json,
    today: dateFromJson(json.today),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page),

})


interface Required extends JsonRequired {

}

interface Optional extends Omit<JsonOptional, 'today'> {
    businessTypeList: BusinessType[],
    statusList: ActiveFlag[],
    today: Date,
    page: PageViewEntity
}

interface JsonRequired {

}

interface JsonOptional {
    buId: number,
    customerId: number,
    supplierId: number,
    contractNo: string,
    description: string,
    sellerList: string[],
    buyerList: string[],
    businessTypeList: number[],
    consigneeList: string[],
    accounteeList: string[],
    deliveryToList: string[],
    receiverList: string[],
    statusList: number[],
    today: string,
    page: PageViewEntityJson
}

