import { DialogActions, DialogContent } from "@material-ui/core"
import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { EntriesItem, EntryItem, Form, NumberItem } from '@rithe/form'
import { Objects, Records } from "@rithe/utils"
import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { EditCallbackRowAction } from "../../../components/DataGrid/rowActions/EditCallbackRowAction"
import { WithdrawCallbackRowAction } from "../../../components/DataGrid/rowActions/WithdrawCallbackRowAction"
import { CreateCallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/CreateCallbackToolbarAction"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { UploadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/UploadGroupedToolbarAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { ActiveFlagTypeProvider } from "../../../components/DataGrid/typeProviders/ActiveFlagTypeProvider"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { DarkDialog } from "../../../components/Dialog/DarkDialog"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { CodeItem } from "../../../components/Form/CodeItem"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { useFunctionStore } from "../../../Root"
import { useGetCreditSettingDetail, useSaveCreditSetting, useWithdrawCreditSetting } from "../../../services/master/apis/creditRuleApi"
import { useDownloadCreditSettingMasterByFilter, useDownloadCreditSettingMasterBySelections } from "../../../services/master/apis/masterDownloadApi"
import { useUploadMasterCreditSettingMaster } from "../../../services/master/apis/masterUploadApi"
import { ActiveFlag } from "../../../services/master/enums/ActiveFlag"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { CreditCheckInfoResult } from "../../../services/master/models/CreditCheckInfoResult"
import { CreditSettingInfoFactor } from "../../../services/master/models/CreditSettingInfoFactor"
import { CreditSettingInfoResult } from "../../../services/master/models/CreditSettingInfoResult"
import { TnmCreditSetting } from "../../../services/master/models/TnmCreditSetting"
import { TnmCurrency } from "../../../services/master/models/TnmCurrency"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { usePrepareCompanyUid, useSplitCompanyId, useSplitCompanyType } from "../../../utils/ApplicationUtils"
import { applicationActions } from "../../Application/applicationSlice"


interface MLS190PcUiProps {
    filters: CreditSettingInfoFactor,
    setFilters: React.Dispatch<React.SetStateAction<CreditSettingInfoFactor>>,
    settingList: CreditSettingInfoResult[],
    search: (filters: CreditSettingInfoFactor) => void,
    currencys: TnmCurrency[],
    buyers: TnvCbds[],
    ruleList: CreditCheckInfoResult[],
}

export const MLS190PcUi = (props: MLS190PcUiProps) => {

    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({ filters, setFilters, settingList, search, currencys, buyers, ruleList }: MLS190PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])
    const columns = useMemo(() => [
        { field: 'buyerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.buyerCode' }), width: 200 },
        { field: 'currency', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.currency' }), width: 200 },
        { field: 'creditRuleNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.creditRuleNo' }), width: 300 },
        { field: 'limitAmount', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.creditLimitAmount' }), width: 200 },
        { field: 'autoHoldOrder', dataTypeName: CodeCategory.CreditHoldFlag, title: intl.formatMessage({ id: 'field.creditAutoHoldOrder' }), width: 200 },
        { field: 'autoHoldInvoice', dataTypeName: CodeCategory.CreditHoldFlag, title: intl.formatMessage({ id: 'field.creditAutoShipmentOrInvoice' }), width: 260 },
        // { field: 'status', dataTypeName: CodeCategory.ActiveFlag, title: intl.formatMessage({ id: 'field.status' }), width: 200 },
    ], [intl])

    const [action, setAction] = useState<ActionProps>({ open: false })

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search, currencys, buyers, ruleList }), [filters, setFilters, search, currencys, buyers, ruleList])
    const display = useCallback((tableRow: TableRow) => !tableRow.row?.status && tableRow.row?.status !== ActiveFlag.INACTIVE, [])
    const actionProps = useMemo(() => ({ search, filters, action, setAction }), [filters, search, action, setAction])
    const downloadProps = useMemo(() => ({ selections, filters, settingList }), [filters, selections, settingList])

    return <>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar} >
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <ActiveFlagTypeProvider />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.CreditHoldFlag} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.ActiveFlag} />
            <Data rows={settingList} columns={columns} />
            <ToolbarActionProvider Action={CreateAction} actionProps={actionProps} />
            <ToolbarActionProvider Action={DownloadAction} actionProps={downloadProps} />
            <ToolbarActionProvider Action={UploadAction} actionProps={actionProps} />
            <RowActionProvider name="edit" Action={EditRowAction} actionProps={actionProps} display={display} />
            <RowActionProvider name="withdraw" Action={WithdrawRowAction} actionProps={actionProps} display={display} />
            <ColumnFreeze />
            <ColumnVisibility
                defaultHiddenFields={[]}
                columnSettings={{
                    buyerCode: { disableUserControl: true },
                    currency: { disableUserControl: true }
                }}
                ToolbarButton={ColumnVisibilityToolbarButton}
            />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
            <Sorting />
            <Filtering />
            <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
            <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
            <Action width={120} />
        </DataGrid>
        <CreateEditCreditSetting action={action} setAction={setAction} search={search} filters={filters} currencys={currencys} buyers={buyers} ruleList={ruleList} />
    </>
}

const FilterItem = (props: {
    filters: CreditSettingInfoFactor,
    setFilters: React.Dispatch<React.SetStateAction<CreditSettingInfoFactor>>,
    search: (filters: CreditSettingInfoFactor) => void,
    currencys: TnmCurrency[],
    buyers: TnvCbds[],
    ruleList: CreditCheckInfoResult[],
}) => {

    const { filters, setFilters, search, buyers, currencys, ruleList } = props
    const clear = useCallback((filters: CreditSettingInfoFactor) => {
        return { page: filters.page }
    }, [])
    const buyerEntries: [string, string][] = useMemo(() => buyers.map(m => [m.cbdsCode, m.cbdsCode]), [buyers])
    const currencyEntries: [string, string][] = useMemo(() => currencys.map(m => [m.currencyCode, m.currencyCode]), [currencys])
    const ruleEntries: [string, string][] = useMemo(() => ruleList.map(m => [m.creditRuleNo ?? '', m.creditRuleNo ?? '']), [ruleList])

    const filterCounter = useCallback((filters: CreditSettingInfoFactor) => {
        return [
            filters.buyerCodes,
            filters.currencys,
            filters.creditRuleNos,
            filters.autoHoldOrders,
            filters.autoHoldInvoices,
            filters.statuss,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])

    const intl = useIntl()
    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <EntriesItem field="buyerCodes" label={intl.formatMessage({ id: 'field.buyerCode' })} entries={buyerEntries} />
        <EntriesItem field="currencys" label={intl.formatMessage({ id: 'field.currency' })} entries={currencyEntries} />
        <EntriesItem field="creditRuleNos" label={intl.formatMessage({ id: 'field.creditRuleNo' })} entries={ruleEntries} />
        <CodesItem field="autoHoldOrders" label={intl.formatMessage({ id: 'field.creditAutoHoldOrder' })} code={CodeCategory.CreditHoldFlag} />
        <CodesItem field="autoHoldInvoices" label={intl.formatMessage({ id: 'field.creditAutoShipmentOrInvoice' })} code={CodeCategory.CreditHoldFlag} />
        {/* <CodesItem field="statuss" label={intl.formatMessage({ id: 'field.status' })} code={CodeCategory.ActiveFlag} /> */}
    </FilterToolbarItem>
}

const CreateAction = ({ setAction }: {
    setAction: React.Dispatch<React.SetStateAction<ActionProps>>
}) => {
    const callCreateAction = useCallback(() => {
        setAction({ open: true, editFlag: false, setting: {} })
    }, [setAction])
    return false ? <CreateCallbackToolbarAction access="MASTER.MLS190.CREATE" callback={callCreateAction} /> : <></>
}

const DownloadAction = ({ settingList, selections, filters }: { settingList: CreditSettingInfoResult[], selections: number[], filters: CreditSettingInfoFactor }) => {
    const downloadMasterBySelectionApi = useDownloadCreditSettingMasterBySelections()
    const downloadMasterByFilterApi = useDownloadCreditSettingMasterByFilter()
    const downloadMaster = useCallback(() => {
        if (!selections || selections.length <= 0) {
            downloadMasterByFilterApi(Objects.delete(filters, 'page'))
        } else {
            const selectedDatas = settingList.filter((_, idx) => selections.indexOf(idx) >= 0).map(m => ({ buyerBuId: m.buyerBuId, customerId: m.customerId, currency: m.currency }))
            downloadMasterBySelectionApi({ settings: selectedDatas })
        }
    }, [settingList, downloadMasterByFilterApi, downloadMasterBySelectionApi, filters, selections])
    return <DownloadGroupedToolbarAction >
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="download" />} callback={downloadMaster} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}

const UploadAction = ({ search, filters }: { search: (filter: CreditSettingInfoFactor) => void, filters: CreditSettingInfoFactor }) => {
    const uploadMasterApi = useUploadMasterCreditSettingMaster()
    const uploadMaster = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            files && uploadMasterApi({ file: files[0] }, { serialized: true }).then(() => search(filters))
        })
    }, [filters, search, uploadMasterApi])
    return <UploadGroupedToolbarAction access="MASTER.MLS190.UPLOAD">
        {(popupUpload, onClose) => <>
            <GroupedCallbackItem label={<FormattedMessage id="upload" />} callback={() => uploadMaster(popupUpload)} onClose={onClose} />
        </>}
    </UploadGroupedToolbarAction>
}

const EditRowAction = ({ tableRow, setAction }: DataGridRowActionProps & { setAction: React.Dispatch<React.SetStateAction<ActionProps>> }) => {
    const setting: CreditCheckInfoResult = useMemo(() => tableRow.row ? { ...tableRow.row } : {}, [tableRow.row])
    const callEditAction = useCallback(() => {
        setAction({ open: true, editFlag: false, setting: setting })
    }, [setAction, setting])
    return <EditCallbackRowAction tableRow={tableRow} access="MASTER.MLS190.EDIT" callback={callEditAction} />
}

const WithdrawRowAction = ({ tableRow, search, filters }: DataGridRowActionProps & { search: (filters: CreditSettingInfoFactor) => void, filters: CreditSettingInfoFactor }) => {
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'withdraw' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const withdrawApi = useWithdrawCreditSetting()
    const callback = useCallback(() => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            withdrawApi({ settingId: tableRow.row?.settingId }, { serialized: true }).then(() => search(filters)).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions:[{
                label: 'CANCEL'
            },{
                functionId,
                label:'CONFIRM',
            }]
        }))
    }, [dispatch, filters, functionStore, search, tableRow.row?.settingId, title, withdrawApi])

    return false ? <WithdrawCallbackRowAction access="MASTER.MLS190.WITHDRAW" tableRow={tableRow} callback={callback} disabled={disabled}/> : <></>
}


interface ActionProps {
    open: boolean,
    editFlag?: boolean,
    setting?: CreditSettingInfoResult
}

const CreateEditCreditSetting = memo(({ action, setAction, search, filters, currencys, buyers, ruleList }: {
    action: ActionProps,
    setAction: React.Dispatch<React.SetStateAction<ActionProps>>,
    search: (filters: CreditSettingInfoFactor) => void
    filters: CreditSettingInfoFactor,
    currencys: TnmCurrency[],
    buyers: TnvCbds[],
    ruleList: CreditCheckInfoResult[],
}) => {
    const { open, editFlag, setting } = action
    const [data, setData] = useState<{ buyerUid?: string } & TnmCreditSetting>({})

    const buyerEntries: [string, string][] = useMemo(() => buyers.map(m => [m.cbdsUid, m.cbdsCode]), [buyers])
    const currencyEntries: [string, string][] = useMemo(() => currencys.map(m => [m.currencyCode, m.currencyCode]), [currencys])
    const ruleEntries: [number, string][] = useMemo(() => ruleList.map(m => [m.creditId ?? 0, m.creditRuleNo ?? '']), [ruleList])
    const dispatch = useDispatch()

    const saveSetting = useSaveCreditSetting()
    const getSettingDetail = useGetCreditSettingDetail()
    const prepareCompanyUid = usePrepareCompanyUid()
    const intl = useIntl()

    useEffect(() => {
        if (setting && setting.settingId !== undefined && setting.settingId !== null) {
            getSettingDetail({ settingId: setting.settingId }, { silent: true }).then(result => {
                setData({ ...result, buyerUid: result.buyerBuId ? prepareCompanyUid(result.buyerBuId, CbdsType.BU) : prepareCompanyUid(result.customerId, CbdsType.CUST) })
            })
        } else if (setting) {
            setData({
                buyerUid: setting.buyerBuId ? prepareCompanyUid(setting.buyerBuId, CbdsType.BU) : prepareCompanyUid(setting.customerId, CbdsType.CUST),
                buyerBuId: setting.buyerBuId,
                currency: setting.currency,
                customerId: setting.customerId
            })
        }
    }, [getSettingDetail, prepareCompanyUid, setting])

    const onClose = useCallback(() => setAction({ open: false }), [setAction])
    const functionStore = useFunctionStore()
    const [disabled, setDisabled] = useState<boolean>(false)
    const title = useMemo(() => intl.formatMessage({ id: 'confirm' }), [intl])
    const getCompanyType = useSplitCompanyType()
    const getCompanyId = useSplitCompanyId()
    const confrim = useCallback(() => {
        const functionId = functionStore.register(() => {
            if (data) {
                const companyType = getCompanyType(data.buyerUid)
                const companyId = getCompanyId(data.buyerUid)
                const customerId = CbdsType.CUST === companyType ? companyId : undefined
                const buyerBuId = CbdsType.BU === companyType ? companyId : undefined
                setDisabled(true)
                saveSetting({
                    settingId: data.settingId,
                    supplierId: data.supplierId,
                    sellerBuId: data.sellerBuId,
                    buyerBuId: buyerBuId,
                    customerId: customerId,
                    creditId: data.creditId,
                    currency: data.currency,
                    limitAmount: data.limitAmount,
                    autoHoldOrder: data.autoHoldOrder,
                    autoHoldInvoice: data.autoHoldInvoice,
                    status: data.status,
                }, { serialized: true }).then(result => {
                    search(filters)
                    onClose()
                }).finally(() => {
                    setDisabled(false)
                })
            }
        })

        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions:[{
                label: 'CANCEL'
            },{
                functionId,
                label:'CONFIRM',
            }]
        }))
        
    }, [data, dispatch, filters, functionStore, getCompanyId, getCompanyType, onClose, saveSetting, search, title])

    return <>
        <DarkDialog keepMounted={false} maxWidth={"lg"} open={open} onClose={onClose} >
            <DialogHeader onClose={onClose}><FormattedMessage id={editFlag ? 'editSetting' : 'createSetting'} /></DialogHeader>
            <DialogContent >
                <Form data={data} setData={setData} labelDisplay="block" helperDisplay="tooltip" columnCount={2}>
                    <EntryItem field="buyerUid" required readonly={editFlag} label={intl.formatMessage({ id: 'field.buyerCode' })} entries={buyerEntries} />
                    <EntryItem field="currency" required readonly={editFlag} label={intl.formatMessage({ id: 'field.currency' })} entries={currencyEntries} />
                    <EntryItem field="creditId" required label={intl.formatMessage({ id: 'field.creditRuleNo' })} entries={ruleEntries} />
                    <NumberItem field="limitAmount" required label={intl.formatMessage({ id: 'field.creditLimitAmount' })} />
                    <CodeItem field="autoHoldOrder" label={intl.formatMessage({ id: 'field.creditAutoHoldOrder' })} code={CodeCategory.CreditHoldFlag} />
                    <CodeItem field="autoHoldInvoice" label={intl.formatMessage({ id: 'field.creditAutoShipmentOrInvoice' })} code={CodeCategory.CreditHoldFlag} />
                </Form>
            </DialogContent>
            <DialogActions>
                <DialogAction title={<FormattedMessage id="confirm" />} callback={confrim} disabled={disabled}/>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={onClose} />
            </DialogActions>
        </DarkDialog >
    </>
})
