import { Arrays, Objects } from "@rithe/utils"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { useGetRegularOrderFormInfo, useGetSpotOrderFormInfo } from "../../../services/order/apis/PlaceOrderApi"
import { OrderType } from "../../../services/order/enums/OrderType"
import { PlaceOrderDetailFactor, placeOrderDetailFactorDeserializer } from "../../../services/order/models/PlaceOrderDetailFactor"
import { PartsDetail, PlaceRegularOrderResult } from "../../../services/order/models/PlaceOrderDetailResult"
import { formatDateRange } from "../../../utils/formatDateRange"
import sessionKeys from "../../../utils/sessionKeys"
import { useGetSessionAndGobackIfEmpty } from "../../../utils/sessionUtil"
import { useMatch } from "../../../utils/useMatch"
import { ONS011PcUi } from "./ONS011PcUi"

export interface PlaceOrderBasic extends PlaceRegularOrderResult {
    totalQty: number,
    totalAmount: number,
    currency?: string | null,
    totalNumberOfParts: number,
    deliveryPlanRange: string,
}

export const ONS011 = () => {

    const { path } = useMatch()
    const orderType = (path === '/placecustorder-spot/create-:contractRouteId' || path === '/placecustorder-spot/view-:contractRouteId') ? OrderType.SPOT : OrderType.REGULAR
    const mode = (path === '/placecustorder-regular/view-:contractRouteId' || path === '/placecustorder-spot/view-:contractRouteId') ? ScreenMode.VIEW : ScreenMode.EDIT
    const getSession = useGetSessionAndGobackIfEmpty()
    const factor = useMemo(() => getSession(sessionKeys.Data_ONS011, placeOrderDetailFactorDeserializer, '/placecustorder-regular') as PlaceOrderDetailFactor, [getSession])

    const getRegularOrderFormInfo = useGetRegularOrderFormInfo()
    const getSpotOrderFormInfo = useGetSpotOrderFormInfo()
    const [basic, setBasic] = useState<PlaceOrderBasic>({} as PlaceOrderBasic)
    const [forecasts, setForecasts] = useState<PartsDetail[]>([] as PartsDetail[])
    const [inboundPlans, setInboundPlans] = useState<PartsDetail[]>([] as PartsDetail[])
    const splitDatas = useSplitDatas()
    useEffect(() => {
        if (orderType === OrderType.REGULAR) {
            getRegularOrderFormInfo(factor ?? {}, { silent: true, serialized: true }).then(result => {
                if (result) {
                    const { orderbasic, orderforecasts, orderInboundPlans } = splitDatas(result)
                    setBasic(orderbasic)
                    setForecasts(orderforecasts)
                    setInboundPlans(orderInboundPlans)
                }
            })
        } else {
            getSpotOrderFormInfo(factor ?? {}, { silent: true, serialized: true }).then(result => {
                if (result) {
                    const { orderbasic, orderforecasts, orderInboundPlans } = splitDatas(result)
                    setBasic(orderbasic)
                    setForecasts(orderforecasts)
                    setInboundPlans(orderInboundPlans)
                }
            })
        }
    }, [factor, getRegularOrderFormInfo, getSpotOrderFormInfo, orderType, splitDatas])

    return <ONS011PcUi orderType={orderType}
        factor={factor}
        basic={basic}
        setBasic={setBasic}
        forecasts={forecasts}
        setForecasts={setForecasts}
        inboundPlans={inboundPlans}
        setInboundPlans={setInboundPlans}
        mode={mode}
    />
}

export const useSplitDatas = () => {
    const intl = useIntl()
    return useCallback((data: PlaceRegularOrderResult) => {
        const { partsDetailList } = data
        const totalNumberOfParts = partsDetailList.length
        const planDates = Arrays.distinct(partsDetailList.flatMap(parts => parts.inbPlanList).map(plan => plan.crdDate.getTime())).map(m => new Date(m))
        const deliveryPlanRange = planDates.length > 0 ? formatDateRange(intl, planDates[0], planDates[planDates.length - 1]) : ''
        const currency = partsDetailList.length > 0 ? partsDetailList[0].buyingCurrency : undefined

        const orderbasic: PlaceOrderBasic = { ...data, totalNumberOfParts, deliveryPlanRange, currency }
        const orderforecasts: PartsDetail[] = partsDetailList.map(parts => ({ ...parts }))
        const orderInboundPlans: PartsDetail[] = partsDetailList.map(parts => ({ ...parts }))

        return { orderbasic: orderbasic, orderforecasts: orderforecasts, orderInboundPlans: orderInboundPlans }
    }, [intl])
}

export const useMergeDatas = () => {
    return useCallback((orderbasic: PlaceOrderBasic, orderforecasts: PartsDetail[], orderInboundPlans: PartsDetail[]) => {
        const basic = Objects.delete(orderbasic, 'totalNumberOfParts', 'deliveryPlanRange', 'currency') as PlaceRegularOrderResult
        const partsDetailList: PartsDetail[] = orderInboundPlans.map((parts, idx) => {
            const { firmQty, fluctReasonFirm, forecastList } = orderforecasts[idx]
            return { ...parts, firmQty, fluctReasonFirm, forecastList }
        })
        return { ...basic, partsDetailList: partsDetailList }
    }, [])
}

