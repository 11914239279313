import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { useGetLatestUsageVersion, useGetOrderCalcSpot, useGetOrderCalcSpotEhm } from "../../../services/smt/api/OrderCalculationApi"
import { OrderCalculationReviewResult } from "../../../services/smt/models/OrderCalculationReviewResult"
import { useMatch } from "../../../utils/useMatch"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { OCCLS016EhmPcUi } from "./OCCLS016EhmPcUi"
import { OCCLS016PcUi } from "./OCCLS016PcUi"
import { OCCLS016PNAPcUi } from "./OCCLS016PNAPcUi"

export const OCCLS016 = (_props: any) => {
    const { orderCalcNo } = useParams()
    const { path } = useMatch()
    const mode = (path === '/orderCalculation/editSpot-:orderCalcNo' || path === '/orderCalculationPNA/editSpot-:orderCalcNo' || path === '/orderCalculationEhm/editSpot-:orderCalcNo') ? ScreenMode.EDIT : ScreenMode.VIEW
    const isPNA: boolean = path === '/orderCalculationPNA/editSpot-:orderCalcNo' || path === '/orderCalculationPNA/viewSpot-:orderCalcNo'
    const isSMT: boolean = path === '/orderCalculationEhm/editSpot-:orderCalcNo' || path === '/orderCalculationEhm/viewSpot-:orderCalcNo'
    const [data, setData] = useState<OrderCalculationReviewResult[]>([])
    const [latestVersion, setLatestVersion] = useState<string | undefined>()

    const getOrderCalcSpot = useGetOrderCalcSpot()
    const getOrderCalcSpotEhm = useGetOrderCalcSpotEhm()
    const getOrderCalcDetail = path.includes("/orderCalculationEhm") ? getOrderCalcSpotEhm : getOrderCalcSpot
    const search = useCallback((orderCalcNo?: string) => {
        if (orderCalcNo) {
            getOrderCalcDetail({ orderCalcNo: orderCalcNo }, { silent: true, serialized: true }).then(result => {
                setData(result ?? [])
            })
        }
    }, [getOrderCalcDetail])

    useSearchOnLoad(search, orderCalcNo)

    // check if has new usage version 
    const getLatestUsageVersion = useGetLatestUsageVersion()
    useEffect(() => {
        if (orderCalcNo) {
            getLatestUsageVersion({ orderCalcNo: orderCalcNo }, { silent: true }).then(result => {
                setLatestVersion(result)
            })
        }
    }, [getLatestUsageVersion, orderCalcNo])

    if (isPNA) {
        return <OCCLS016PNAPcUi
            mode={mode}
            orderCalcNo={orderCalcNo}
            data={data}
            latestUsageVersion={latestVersion}
            search={search}
        />
    } else if (isSMT) {
        return <OCCLS016EhmPcUi
            mode={mode}
            orderCalcNo={orderCalcNo}
            data={data}
            latestUsageVersion={latestVersion}
            search={search}
        />
    } else {
        return <OCCLS016PcUi
            mode={mode}
            orderCalcNo={orderCalcNo}
            data={data}
            latestUsageVersion={latestVersion}
            search={search}
        />
    }
}
