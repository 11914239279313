import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useGetInvoice } from "../../../services/accounting/apis/accountingBuyerApi"
import { GiGrInvoice } from "../../../services/accounting/models/GiGrInvoice"
import { AIS011PcUi } from "./AIS011PcUi"

export const AIS011 = () => {
    let { invoiceId } = useParams() as any
    const [invoice, setInvoice] = useState<GiGrInvoice>()
    const getInvoice = useGetInvoice()

    useEffect(() => {
        if (invoiceId !== undefined && invoiceId !== null) {
            getInvoice(({ invoiceId: invoiceId }), { silent: true, serialized: true }).then(result => {
                setInvoice(result ?? {} as any)
            })
        };
    }, [getInvoice, invoiceId])

    return <AIS011PcUi
        invoice={invoice}
    />
}