import { Project } from "../../../utils/Project"

export type EtlBatchTransfer = Required & Partial<Optional>

type EtlBatchTransferJson = JsonRequired & Partial<JsonOptional>

export const etlBatchTransferFromJson: Project<EtlBatchTransferJson, EtlBatchTransfer> = json => ({
    ...json,
})

interface Required extends JsonRequired { }

interface Optional extends JsonOptional {

}

interface JsonRequired {
    batchId: number,
}

interface JsonOptional {
    batchId: number,
    dataSource: number,
    generateTime: string,
    launchTime: string,
    backupTime: string,
    verifyTime: string,
    storeTime: string,
    runTime: string,
    createdDate: string,
    updatedDate: string,
}
