import { MenuItem } from "@material-ui/core"
import React from "react"
import { Access } from "../../Access/Access"

interface GroupedCallbackItemProps {
    access?: string,
    power?: boolean,
    label: NonNullable<React.ReactNode>,
    callback: () => void,
    onClose: () => void,
    disabled?: boolean,
}

export const GroupedCallbackItem = (props: GroupedCallbackItemProps) => {
    const { access, power, label, callback, onClose } = props
    const action = <MenuItem onClick={() => {
        callback()
        onClose()
    }}>
        {label}
    </MenuItem>
    return access ? <Access access={access} power={power}>{action}</Access> : action
}