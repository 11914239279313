import { Project } from "../../../utils/Project"
import { SettingDetail, settingDetailFromJson, SettingDetailJson, settingDetailToJson } from "./SettingDetail"
import { TnmDistributionCenter, tnmDistributionCenterFromJson, TnmDistributionCenterJson, tnmDistributionCenterToJson } from "./TnmDistributionCenter"

export interface DcDetail {
    basicInfo: TnmDistributionCenter,
    settings: SettingDetail[],
}
interface DcDetailJson {
    basicInfo: TnmDistributionCenterJson,
    settings: SettingDetailJson[],
}

export const dcDetailFromJson: Project<DcDetailJson, DcDetail> = json => ({
    basicInfo: tnmDistributionCenterFromJson(json.basicInfo),
    settings: json.settings.map(settingDetailFromJson),
})

export const dcDetailToJson: Project<DcDetail, DcDetailJson> = obj => ({
    basicInfo: tnmDistributionCenterToJson(obj.basicInfo),
    settings: obj.settings.map(settingDetailToJson),
})