import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson, tableEntityToJson } from "../../utils/TableEntity"
import { ContractType } from "../enums/ContractType"
import { DeliveryType } from "../enums/DeliveryType"
import { RepackingType } from "../enums/RepackingType"
import { WorkingStatus } from "../enums/WorkingStatus"

export type WorkingPartsList = Required & Partial<Optional> & TableEntity
export type WorkingPartsListJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const workingPartsListToJson: Project<WorkingPartsList, WorkingPartsListJson> = obj => ({
    ...tableEntityToJson(obj),
})
export const workingPartsListFromJson: Project<WorkingPartsListJson, WorkingPartsList> = json => ({
    ...tableEntityFromJson(json),

})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
    deliveryType: DeliveryType,
    repackingType: RepackingType,
    contractType: ContractType,
    status: WorkingStatus,
}

interface JsonRequired {
    requestDetailId: number,
}

interface JsonOptional {
    requestDetailCode: string,
    contractPartsId: number,
    contractRouteId: number,
    routeDetailId: number,
    requestId: number,
    contractNo: string,
    requestNo: string,
    buyerPartsId: number,
    buyerPartsNo: string,
    partsId: number,
    isPartCompleted: boolean,
    globerPartsNo: string,
    sellerPartsId: number,
    sellerPartsNo: string,
    unitPartsName: string,
    customerId: number,
    customerCode: string,
    deliveryType: number,
    deliveryPattern: string,
    repackingType: number,
    saftyStockDays: number,
    saftyStockPct: number,
    fluctuationRatio: number,
    fcFluctuationRatio: number,
    unitPrice: number,
    endUser1: string,
    endUser2: string,
    endUser3: string,
    endUser4: string,
    endUser5: string,
    finalSellerFlag: number,
    nextSellerUid: string,
    nextSellerCode: string,
    decimalDigits: number,
    contractType: number,
    rowNo: number,
    workingDetailId: number,
    status: number,
    workingId: number,
    /** 1:can be modified 0: can not be modified **/
    modifiedFlg: number,

    preContractNo: string,
    buyerPartsName: string,
    currency: string,
    effectiveFromPrice: number,
    nextSellerPartsId: number,
    nextSellerPartsNo: string,
    digits: number,
    buyerUomCode: string,

    // Part's spec
    workingDetailSpecId: number,
    orderLot: number,
    spq: number,
    m3: number,
    netWeight: number,
    grossWeight: number,
    effectiveFromProp: number,
    buyerSpq: number,

    index: number,

    pairedFlag: number,
    pairedPartsNo: string,
    pairedOrderFlag: number,
    description: string,

    sellerUomCode: string,
    uomChangeRate: number,
    supplierLeadtime: number,
    orgContractPartsStatus: number,
}