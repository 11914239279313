export enum JourneyStatus {
    SUCCESS = "0",
    INIT = "1",
    CONTAINER_RETURNED_TO_CARRIER = "8",
    CONTAINER_IDLE_AFTER_DELIVERY = "9",
    CONTAINER_IDLE_AFTER_GATEOUT = "10",
    CONTAINER_IDLE_AFTER_DISCHARGE = "11",
    STOPPED_TRACKING = "100",
    SUCCESS_EARLY_JOURNEY = "0.5",
    
    UPLOADED = "31",
    BL_NOT_FOUND = "32",
    CNTR_NOT_FOUND = "33",
    INVALID_BL_NO = "34",
    CNTR_NOT_MAPPED_TO_BL = "35",
    EARLY_JOURNEY = "36",
    JOURNEY_IN_PROGRESS = "37",
    JOURNEY_COMPLETED = "38",
}