import { Project } from "../../../utils/Project"

export type ReleaseWorkingFactor = Partial<Optional>
type ReleaseWorkingFactorJson = Partial<JsonOptional>
export const releaseWorkingFactorToJson: Project<ReleaseWorkingFactor, ReleaseWorkingFactorJson> = obj => ({
    ...obj,
})

interface Optional extends JsonOptional {
}


interface JsonOptional {
    workingId: number,
    /**1:Delete,2:NotDelete*****/
    isDeleteContract: number,
}
