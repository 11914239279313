import { useCallback, useState } from "react"
import { useGetUserActiveByPage } from "../../../services/privilege/api/UserActiveApi"
import { UserActiveFactorListFactorToJson, UserActiveListFactor } from "../../../services/privilege/models/UserActiveFactor"
import { UserActiveListResult } from "../../../services/privilege/models/UserActiveResult"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { CAS040PcUi } from "./CAS040PcUi"

const searchCachekey = sessionKeys.Filters_CAS040
const defaultFilters = { page: { currentPage: 0, pageSize: 100000 } }
export const CAS040 = () => {
  const [filters, setFilters] = useState<UserActiveListFactor>(defaultFilters)
  const [data, setData] = useState<UserActiveListResult[]>([])

  const getData = useGetUserActiveByPage()
  const search = useCallback((filters: UserActiveListFactor) => {
    getData(filters, { silent: true, serialized: true }).then(result => {
      setData(result?.data || [])
      sessionStorage.setItem(searchCachekey, JSON.stringify(UserActiveFactorListFactorToJson(filters)))
    })
  }, [getData, setData])

  useSearchOnLoad(search, filters)

  return <CAS040PcUi
    filters={filters}
    setFilters={setFilters}
    search={search}
    data={data}
  />
}


