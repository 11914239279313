import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { IntlString } from "../../../utils/IntlString"
import { Projects } from "../../../utils/Projects"
import { cargoStatusSettingFromJson, CargoStatusSettingList } from "../models/CargoStatusSettingList"
import { CreateCargoStatusSetting, createCargoStatusSettingToJson } from "../models/CreateCargoStatusSetting"
import { CustomerAndContract, customerAndContractFromJson } from "../models/CustomerAndContract"
import { DeleteCargoStatusSetting, deleteCargoStatusSettingToJson } from "../models/DeleteCargoStatusSetting"
import { EditCargoStatusSetting, editCargoStatusSettingToJson } from "../models/EditCargoStatusSetting"

export const useGetDefaultCargoStatus = () => {
    const url = '/lcbm-shipping-detail-api/api/getDefaultCargoStatus'
    const payloadProject = Projects.IGNORE
    const resultProject = Projects.KEEP
    return usePost<undefined, { [role: number]: string[] }>(url, payloadProject, resultProject as any)
}

export const useGetCargoStatusSetting = () => {
    const url = '/lcbm-shipping-detail-api/api/getCargoStatusSetting'
    const payloadProject = useCallback((payload: undefined) => payload, [])
    const resultProject = useCallback(cargoStatusSettingFromJson, [])
    return usePost<undefined, CargoStatusSettingList>(url, payloadProject, resultProject)
}

export const useCreateCargoStatusSetting = () => {
    const url = '/lcbm-shipping-detail-api/api/createCargoStatusSetting'
    const payloadProject = useCallback(createCargoStatusSettingToJson, [])
    const resultProject = useCallback((result: IntlString[]) => result, [])
    return usePost<CreateCargoStatusSetting, IntlString[]>(url, payloadProject, resultProject)
}

export const useEditCargoStatusSetting = () => {
    const url = '/lcbm-shipping-detail-api/api/editCargoStatusSetting'
    const payloadProject = useCallback(editCargoStatusSettingToJson, [])
    const resultProject = useCallback((result: undefined) => result, [])
    return usePost<EditCargoStatusSetting, undefined>(url, payloadProject, resultProject)
}

export const useDeleteCargoStatusSetting = () => {
    const url = '/lcbm-shipping-detail-api/api/deleteCargoStatusSetting'
    const payloadProject = useCallback(deleteCargoStatusSettingToJson, [])
    const resultProject = useCallback((result: undefined) => result, [])
    return usePost<DeleteCargoStatusSetting, undefined>(url, payloadProject, resultProject)
}

export const useGetRelatedCustomerContract = () => {
    const url = '/lcbm-shipping-detail-api/api/getRelatedCustomerContract'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(customerAndContractFromJson), [])
    return usePost<undefined, CustomerAndContract[]>(url, payloadProject, resultProject)
}