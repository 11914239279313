
import { Project } from "../../utils/Project"

export interface PageResultEntity<T> {
    data?: T[],
    page?: {
        totalCount: number,
        totalPages: number,
        pageSize: number,
        currentCount: number
        currentPage: number
    },
}

export interface PageResultEntityJson<T> {
    data?: T[],
    page?: {
        totalCount: number,
        totalPages: number,
        pageSize: number,
        currentCount: number
        currentPage: number
    },
}

export const pageResultEntityToJson = <T, R>(contentToJson: Project<T, R>) => (obj: PageResultEntity<T>) => ({
    data: obj.data?.map(contentToJson),
    page: obj.page,
})

export const pageResultEntityFromJson = <T, R>(contentFromJson: Project<T, R>) => (json: PageResultEntityJson<T>) => ({
    data: json.data?.map(contentFromJson),
    page: json.page,
})

