import { Button, makeStyles, } from "@material-ui/core"
import clsx from 'clsx'
import React, { useCallback, useRef } from "react"
import { Access } from "../Access/Access"

interface DialogActionProps {
    access?: string,
    title: NonNullable<React.ReactNode>,
    error?: boolean,
    disabled?: boolean,
    outlined?: boolean,
    callback: (files: FileList | null) => void,
}

export const DialogUploadAction = (props: DialogActionProps) => {
    const { access, title, disabled, outlined, callback } = props


    const inputRef = useRef<HTMLInputElement | null>(null)
    const onPopup = useCallback(() => {
        inputRef.current && inputRef.current.click()
    }, [])
    const onChange = useCallback(() => {
        if (inputRef.current) {
            callback(inputRef.current.files)
            inputRef.current.value = ''
        }
    }, [callback])

    const styles = useStyles()
    const action = <Button
        variant={outlined ? 'outlined' : 'contained'}
        color="secondary"
        disabled={disabled}
        onClick={onPopup}
        className={clsx(styles.root, outlined && styles.rootOutlined)}
    >
        {title}
    </Button>

    return <>
        <input ref={inputRef} type="file" onChange={onChange} style={{ display: 'none' }} />
        {access
            ? <Access access={access} >{action}</Access>
            : action}   </>
}
const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 120,
    },
    rootOutlined: {
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
    }
}))
