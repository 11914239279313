import { KeyTypeProvider } from "@rithe/data-grid"
import { KeyEditorProps } from "@rithe/data-grid/dist/components/dataTypes/KeyEditor"
import { KeyFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/KeyFormatter"
import { KeyInlineEditorProps } from "@rithe/data-grid/dist/components/dataTypes/KeyInlineEditor"
import { Arrays, Comparators } from "@rithe/utils"
import React, { ComponentType, useMemo } from "react"
import { useIntl } from "react-intl"
import { useApplicationSelector } from "../../../layouts/Application/applicationSlice"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"

interface CodeCategoryTypeProviderProps {
    codeCategory: CodeCategory,
    Formatter?: ComponentType<KeyFormatterProps>,
    Editor?: ComponentType<KeyEditorProps>,
    InlineEditor?: ComponentType<KeyInlineEditorProps>,
}

export const CodeCategoryTypeProvider = (props: CodeCategoryTypeProviderProps) => {
    const { codeCategory, Formatter, Editor, InlineEditor } = props
    const intl = useIntl()
    const formatter = useMemo(() => ({
        format: (value: any) => {
            return value === undefined ? '' : intl.formatMessage({ id: `${codeCategory}_${value}` })
        }
    }), [codeCategory, intl])

    const codeCategories = useApplicationSelector(state => state.cache.codeCategories ?? [])
    const options = useMemo(() => {
        return Arrays.distinct(
            codeCategories.filter(cc => cc.codeCategory === codeCategory)
                .map(cc => cc.codeValue)
        ).sort(Comparators.natualOrder())
    }, [codeCategory, codeCategories])

    return <KeyTypeProvider name={codeCategory} formatter={formatter} options={options} Formatter={Formatter} Editor={Editor} InlineEditor={InlineEditor} />
}