import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { PortFactor, portFactorToJson } from "../models/PortFactor"
import { PortResult, portResultFromJson } from "../models/PortResult"

export const useGetPortList = () => {
    const url = '/lcbm-system-master-api/api/port/getPortList'
    const payloadProject = useCallback(portFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(portResultFromJson), [])
    return usePost<PortFactor, PageResultEntity<PortResult>>(url, payloadProject, resultProject)
}