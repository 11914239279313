import { Project } from "../../../utils/Project"

export type GetContractListFactor = Partial<Optional>
type GetContractListFactorJson = Partial<JsonOptional>
export const getContractListFactorToJson: Project<GetContractListFactor, GetContractListFactorJson> = obj => ({
    ...obj,
})

interface Optional extends JsonOptional {
}


interface JsonOptional {
    customerId: number,
    contractRouteId: number,
}
