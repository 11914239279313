import { makeStyles, Tabs } from "@material-ui/core";
import { useMixed } from "@rithe/utils";
import React, { ReactNode, useLayoutEffect } from "react";
import { useTabContext } from "./TabContext";

interface ViewTabsProps {
    selectedValue?: string | number,
    defaultSelectedValue?: string | number,
    onSelectedValueChange?: (value: string | number) => void,
    children?: ReactNode | ReactNode[],
}

export function ViewTabs(props: ViewTabsProps) {
    const { children } = props
    const [selectedValue, setSelectedValue] = useMixed(props.selectedValue, props.onSelectedValueChange, props.defaultSelectedValue, '')

    const { setSelectedValue: setTabId } = useTabContext()
    useLayoutEffect(() => {
        setTabId(selectedValue)
    }, [selectedValue, setTabId])

    const styles = useStyles()
    return <Tabs value={selectedValue} onChange={(_, tabId) => setSelectedValue(tabId)} className={styles.root}>
        {children}
    </Tabs>
}

const useStyles = makeStyles(theme => ({
    root: {
        flex: '0 0 auto',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    }
}))