export function formify(payload?: { [key: string]: any }) {
  if (payload === undefined)
      return payload

  const form = new FormData()
  for (let key in payload) {
      let val = payload[key]
      if (val instanceof Array) {
          for (let item of val) {
              item !== null && item !== undefined && form.append(key, item)
          }
      } else if (val instanceof File) {
          form.append(key, val, val.name)
      } else {
          val !== null && val !== undefined && form.append(key, val)
      }
  }

  return form
}