import { FormattedMessage } from 'react-intl'
import { CallbackViewAction } from './CallbackViewAction'

interface CreateCallbackViewActionProps {
    access?: string,
    disabled?: boolean,
    outlined?: boolean,
    callback: () => void,
}

export const CreateCallbackViewAction = (props: CreateCallbackViewActionProps) => {
    return <CallbackViewAction {...props} title={<FormattedMessage id="create" />} />
}