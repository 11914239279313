/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback } from "react"
import { Projects } from "../../../utils/Projects"
import { useDownload, usePost, useUpload } from "../../../utils/fetch/useFetch"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { TnmUserToListFilter, tnmUserToListFilterJson } from "../models/TnmUserToList"
import { TnmUserToListResult, tnmUserToListResultFromJson } from "../models/TnmUserToListResult"
import { TnmUserToListView } from "../models/TnmUserToListView"

export const useQueryByPage = () => {
    const url = '/common-todo-list-api/todolist/queryByPage'
    const payloadProject = useCallback(tnmUserToListFilterJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(tnmUserToListResultFromJson), [])
    return usePost<TnmUserToListFilter, PageResultEntity<TnmUserToListResult>>(url, payloadProject, resultProject)
}

export const userQueryById = () => {
    const url = '/common-todo-list-api/todolist/queryById'
    const payloadProject = useCallback((obj) => obj, [])
    const resultProject = useCallback(tnmUserToListResultFromJson, [])
    return usePost<{ todoId: number }, TnmUserToListResult>(url, payloadProject, resultProject)
}

export const useInsert = () => {
    const url = '/common-todo-list-api/todolist/insert'
    const payloadProject = useCallback((obj: { todolistView: TnmUserToListView }) => obj.todolistView, [])
    const resultProject = Projects.IGNORE
    return usePost<{ todolistView: TnmUserToListView }, undefined>(url, payloadProject, resultProject)
}

export const useUpdateById = () => {
    const url = '/common-todo-list-api/todolist/update'
    const payloadProject = useCallback((obj: { todolistView: TnmUserToListView }) => obj.todolistView, [])
    const resultProject = Projects.IGNORE
    return usePost<{ todolistView: TnmUserToListView }, undefined>(url, payloadProject, resultProject)
}

export const useUploadTDL = () => {
    const url = '/common-todo-list-api/todolist/uploadFile'
    const payloadProject = useCallback((obj: { files: File }) => obj, [])
    const resultProject = useCallback(json => json, [])
    return useUpload<{ files: File }, string>(url, payloadProject, resultProject)
}

export const useUpdateSampleById = () => {
    const url = '/common-todo-list-api/todolist/updateSample'
    const payloadProject = useCallback((obj: { todolistView: TnmUserToListView }) => obj.todolistView, [])
    const resultProject = Projects.IGNORE
    return usePost<{ todolistView: TnmUserToListView }, undefined>(url, payloadProject, resultProject)
}

export const useDownloadTDL = () => {
    const url = '/common-todo-list-api/todolist/download'
    const payloadProject = useCallback((obj: {} & TnmUserToListView) => obj, [])
    return useDownload<{} & TnmUserToListView>(url, payloadProject, { fileTimestamp: true })
}

export const useDeleteFile = () => {
    const url = '/common-todo-list-api/todolist/deleteFile'
    const payloadProject = useCallback((obj: TnmUserToListView) => obj, [])
    const resultProject = useCallback(tnmUserToListResultFromJson, [])
    return usePost<TnmUserToListView, TnmUserToListResult>(url, payloadProject, resultProject)
}

