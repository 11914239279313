import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { Projects } from "../../../utils/Projects"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { GlobalPartsFactor, globalPartsFactorToJson } from "../models/GlobalPartsFactor"
import { GlobalPartsResult, globalPartsResultFromJson, globalPartsResultToJson } from "../models/GlobalPartsResult"

export const useGetGlobalOartsList = () => {
    const url = '/lcbm-local-master-api/api/getGlobalPartsList'
    const payloadProject = useCallback(globalPartsFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(globalPartsResultFromJson), [])
    return usePost<GlobalPartsFactor, PageResultEntity<GlobalPartsResult>>(url, payloadProject, resultProject)
}

// save data to formal dataBase
export const useSaveGlobalPartsList = () => {
    const url = '/lcbm-local-master-api/api/saveGlobalPartsList'
    const payloadProject = useCallback(Projects.array(globalPartsResultToJson), [])
    const resultProject = Projects.IGNORE
    return usePost<GlobalPartsResult[], undefined>(url, payloadProject, resultProject)
}
