import { Project } from "@rithe/utils"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"


export type TatCustomsInvoiceBreakdown = Required & Partial<Optional> & TableEntity
export type TatCustomsInvoiceBreakdownJson = JsonRequired & Partial<JsonOptional> & TableEntityJson

export const tatCustomsInvoiceBreakdownFromJson: Project<TatCustomsInvoiceBreakdownJson, TatCustomsInvoiceBreakdown> = json => ({
    ...tableEntityFromJson(json),
})


interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {

}

interface JsonRequired {
}

interface JsonOptional {
    customsInvBreakId: number,
    customsInvId: number,
    invoiceCurrency: string,
    invoiceAmount: number,
    invoiceAdjustmentAmount: number,
    invoiceAdjustmentReason: string,
    costItem1: string,
    costItem1Amount: number,
    costItem2: string,
    costItem2Amount: number,
    costItem3: string,
    costItem3Amount: number,
    costItem4: string,
    costItem4Amount: number,
    costItem5: string,
    costItem5Amount: number,
    additionalItem1: string,
    additionalItem1Amount: number,
    additionalItem2: string,
    additionalItem2Amount: number,
    additionalItem3: string,
    additionalItem3Amount: number,
    additionalItem4: string,
    additionalItem4Amount: number,
    additionalItem5: string,
    additionalItem5Amount: number,
    initialIncotermsId: number,
    initialIncotermsCode: string,
    initialIncotermsPlace: string,
    initialIncotermsAmount: number,
    initialIncotermsDescription: string,
    finalIncotermsId: number,
    finalIncotermsCode: string,
    finalIncotermsPlace: string,
    finalIncotermsAmount: number,
    finalIncotermsDescription: string,
}