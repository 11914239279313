import { Project } from "@rithe/utils";
import { CargoRole } from "../enums/CargoRole";

export interface CreateCargoStatusSetting {
    cargoStatusSettingName: string,
    customerId: number,
    role: CargoRole,
    contractIds: number[],
    cargoStatuss: string[],
    confirmed: boolean,
}

interface CreateCargoStatusSettingJson {
    cargoStatusSettingName: string,
    customerId: number,
    role: number,
    contractIds: number[],
    cargoStatuss: string[],
    confirmed: boolean,
}

export const createCargoStatusSettingToJson: Project<CreateCargoStatusSetting, CreateCargoStatusSettingJson> = data => data
