import { Project } from "@rithe/utils"
import { dateToJson } from "../../utils/serializer"

export type DownloadOutBoundPartsInfo = Required & Partial<Optional>
type DownloadOutBoundPartsInfoJson = JsonRequired & Partial<JsonOptional>

export const downloadInboundFormResultToJson: Project<DownloadOutBoundPartsInfo, DownloadOutBoundPartsInfoJson> = obj => ({
    ...obj,
    outboundDate: dateToJson(obj.outboundDate),
    productionDate: dateToJson(obj.productionDate),
    etd: dateToJson(obj.etd),
    eta: dateToJson(obj.eta),
})

interface Required extends JsonRequired {}

interface Optional extends Omit<JsonOptional, 'outboundDate' | 'productionDate' | 'etd' | 'eta'> {
    outboundDate: Date,
    productionDate: Date,
    etd: Date,
    eta: Date
}

interface JsonRequired {
}

interface JsonOptional {
    shipper: string,
    outboundNo: string,
    outboundDate: string,
    outboundTime: string,
    bookingNo: string,
    partsNo: string,
    uomCode: string,
    uomDigits: number,
    outboundQty: number,
    shippingMode: number,
    outboundType: number,
    receiver: string,
    buyer: string,
    etd: string,
    eta: string,
    containerTruckNo: string,
    containerTruckType: string,
    commodityType: string,
    sealNo: string,
    containerM3: number,
    containerNetWeight: number,
    containerGrossWeight: number,
    productionDate: string,
    palletNo: string,
    outerPackageNo: string,
    outerPackageType: string,
    outerM3: number,
    outerNetWeight: number,
    outerGrossWeight: number,
    innerPackageNo: string,
    innerPackageType: string,
    innerM3: number,
    innerNetWeight: number,
    innerGrossWeight: number,
    soNo: string,
    sellerCode: string,
    sellerPartsNo: string,
    sellerPartsName: string,
    sellerBackNo: string,
    colorCode: string,
    srbq: number,
    remainingQty: number,
    outPackageIds: number[],
    originalOutboundRefNo: string
}