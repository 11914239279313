import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"

export type TnmState = Required & Partial<Optional> & TableEntity
type TnmStateJson = JsonRequired & Partial<JsonOptional> & TableEntityJson

export const tnmStateFromJson: Project<TnmStateJson, TnmState> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
}

interface JsonRequired {
    stateCode: string,
    stateName: string,
    regionCode: string,
}

interface JsonOptional {
}