import { Arrays } from "@rithe/utils"
import { now } from "moment"
import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useGetPoDetail } from "../../../services/order/apis/PurchaseOrderApi"
import { PoStatus } from "../../../services/order/enums/PoStatus"
import { SimulatedType } from "../../../services/order/enums/SimulatedType"
import { EstimatedData, InboundPalnData, OrderDetailHead, OrderPartsData, OrderPartsDetail } from "../../../services/order/models/OrderDetailResult"
import { datetimeFromJson } from "../../../services/utils/deserializer"
import { OPS011PcUi } from "./OPS011PcUi"

export const OPS011 = () => {
    const { orderId } = useParams() as any
    const [headerInfo, setHeaderInfo] = useState<OrderDetailHead>({} as OrderDetailHead)
    const [partsDetailList, setPartsDetailList] = useState<OrderPartsData[]>([])

    const creatInboundPlanList = useCallback((partsDetailList: OrderPartsDetail[], currentDate: number) => {
        const allIPlanDateList: number[] = Arrays.distinct(partsDetailList.filter(f => f.inboundPlanList).flatMap(m => m.inboundPlanList).map(m2 => m2.crdDate.getTime())).sort()
        const allEstimatedDataList: string[] = Arrays.distinct(partsDetailList.filter(f => f.estimatedInboundList).flatMap(f => f.estimatedInboundList).map(m3 => [m3.estimatedCrdDate.getTime(), m3.fromCompany, SimulatedType.ON_STOCK !== m3.simulatedType ? m3.toCompany : ''].join(','))).sort()

        return partsDetailList.map((opd) => {
            const actualInboundQty: number = (opd?.currentInbQty ?? 0)
            const acutalOrderQty = opd.detailStatus === PoStatus.CANCELLED ? 0 : (opd.orderQty ?? 0) - (opd.forceCompletedQty ?? 0)

            // So Detail Page: Shortage Qty = Order Qty - Received Qty - Total Estimated qty(including Intransit Qty)
            // let shortageQty: number = opd?.orderQty | 0 - opd?.currentInbQty | 0
            const newinboundPlanList = allIPlanDateList.map((crdDate) => {
                // sum plan qty
                const planQty = opd.inboundPlanList ? opd.inboundPlanList.filter(f => f.crdDate.getTime() <= crdDate).map(m => m.crdQty).reduce((a, b) => a + b, 0) : 0
                const actualPlanQty = (planQty > acutalOrderQty ? acutalOrderQty : planQty)
                const estOutboundQty = opd.estimatedInboundList ? opd.estimatedInboundList.filter(f => f.estimatedCrdDate.getTime() <= crdDate).map(m => m.qty).reduce((a, b) => a + b, actualInboundQty) : 0
                const crdQty = opd.inboundPlanList.filter(o => o.crdDate.getTime() === crdDate).map(m => m.crdQty ?? 0).reduce((a, b) => a + b, 0)

                return {
                    crdDate: datetimeFromJson(crdDate),
                    status: actualPlanQty > estOutboundQty ? 'NG-' + (actualPlanQty - estOutboundQty) : 'OK',
                    crdQty: crdQty ?? 0
                } as InboundPalnData
            })

            const newEstimatedInboundList = allEstimatedDataList.map((eData) => {
                const tempDataList: string[] = eData.split(',')
                const crdDate: number = Number(tempDataList[0]), companyTitle: string = '' === tempDataList[2] ? tempDataList[1] : tempDataList[1] + ' Ship To ' + tempDataList[2]
                const estimatedQty = opd.estimatedInboundList.filter(f => [f.estimatedCrdDate.getTime(), f.fromCompany, SimulatedType.ON_STOCK !== f.simulatedType ? f.toCompany : ''].join(',') === eData).map(m => m.qty ?? 0).reduce((a, b) => a + b, 0)

                return {
                    estimatedCrdDate: datetimeFromJson(crdDate),
                    companyTitle: companyTitle,
                    qty: estimatedQty ?? 0
                } as EstimatedData
            })

            return { ...opd, inboundPlanList: newinboundPlanList, estimatedInboundList: newEstimatedInboundList }
        })
    }, [])

    const getPoDetail = useGetPoDetail()
    useEffect(() => {
        if (orderId) {
            getPoDetail({ orderId: orderId }, { silent: true, serialized: true }).then(result => {
                setHeaderInfo(result?.headerInfo || {} as OrderDetailHead)
                if (result?.partsDetailList && result.partsDetailList.length) {
                    setPartsDetailList(creatInboundPlanList(result.partsDetailList, result?.headerInfo?.currentDate.getTime() ?? now()))
                }
            })
        } else {
            //TODO Show ERROR Message
            setHeaderInfo({} as OrderDetailHead)
            setPartsDetailList([])
        }
    }, [creatInboundPlanList, getPoDetail, orderId])

    const props = {
        headerInfo: headerInfo,
        partsDetailList: partsDetailList
    }

    return <OPS011PcUi {...props} />
}