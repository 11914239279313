import { Break, EntriesItem, Form, YearMonthItem } from "@rithe/form"
import { default as React, useCallback, useMemo } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useFunctionStore } from "../../../Root"
import { DownloadCallbackViewAction } from "../../../components/Action/DownloadCallbackViewAction"
import { UploadCallbackViewAction } from "../../../components/Action/UploadCallbackViewAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ChecksItem } from "../../../components/Form/ChecksItem"
import { View } from "../../../components/View/View"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { useDownloadDirectInputPlan } from "../../../services/smt/api/smtDownloadApi"
import { useUploadDirectInput_usageDaily, useUploadDirectInput_usageMonthly, useUploadDirectInput_usageWeekly } from "../../../services/smt/api/smtUploadApi"
import { CustUsageType } from "../../../services/smt/enums/CustUsageType"
import { DirectInputDownloadFactor } from "../../../services/smt/models/DirectInputDownloadFactor"
import { Pair } from "../../../services/utils/Pair"
import { useGetCompanyType } from "../../../utils/ApplicationUtils"
import { applicationActions } from "../../Application/applicationSlice"

interface CSUGS120PcUiProps {
    factor: DirectInputDownloadFactor,
    setFactor: React.Dispatch<React.SetStateAction<DirectInputDownloadFactor>>,
    participantList: Pair[],
    groupings: string[]
}

export const CSUGS120PcUi = (props: CSUGS120PcUiProps) => {

    const { factor, setFactor, participantList, groupings } = props
    const intl = useIntl()
    const cbdsType = useGetCompanyType()
    const participantMap = useMemo(() => participantList ? participantList.map(m => [m.second, m.second] as [number, string]) : [], [participantList])
    const groupingsMap = useMemo(() => {
        const entries: [[string, string]] = [["", intl.formatMessage({ id: 'noGrouping' })]]
        groupings && groupings.forEach(g => entries.push([g, g]))
        return entries;
    }, [groupings, intl])
    const usagesMap = useMemo(() => [
        [CustUsageType.DAILY, intl.formatMessage({ id: 'daily' })],
        [CustUsageType.WEEKLY, intl.formatMessage({ id: 'weekly' })],
        [CustUsageType.MONTHLY, intl.formatMessage({ id: 'monthly' })],
    ] as [number, string][], [intl])

    return <View actions={[<DownloadAction factor={factor} />, <UploadAction factor={factor} />]}>
        <SectionCard>
            <SectionCardHeader title={''} subtitle={intl.formatMessage({ id: 'customerUsageSub' })} />
            <SectionCardContent>
                <Form data={factor} setData={setFactor} labelDisplay="block" helperDisplay="tooltip" columnCount={3}>
                    {
                        cbdsType === CbdsType.BU ?
                            <EntriesItem field="customerList" label={intl.formatMessage({ id: 'field.buyerCode' })} entries={participantMap} />
                            : <EntriesItem field="sellerList" label={intl.formatMessage({ id: 'field.sellerCode' })} entries={participantMap} />
                    }

                    <Break />
                    <YearMonthItem field="yearMonthFrom" label={intl.formatMessage({ id: 'yearMonthFrom' })} />
                    <YearMonthItem field="yearMonthTo" label={intl.formatMessage({ id: 'yearMonthTo' })} />
                    <EntriesItem field="groupings" label={intl.formatMessage({ id: 'grouping' })} entries={groupingsMap} />
                    <Break />
                    <ChecksItem field="usageTypes" label={intl.formatMessage({ id: 'planType' })} entries={usagesMap} colSpan={2} />
                </Form>
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DownloadAction = ({ factor }: { factor: DirectInputDownloadFactor }) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const downloadTemplate = useDownloadDirectInputPlan()
    const download = useCallback(() => {
        if (factor.usageTypes && factor.usageTypes.length > 0) {
            downloadTemplate(factor)
        } else {
            dispatch(applicationActions.pushError({ title: { code: 'download' }, messages: { code: 'w0571', args: [intl.formatMessage({ id: 'download' })] } }))
        }
    }, [dispatch, downloadTemplate, factor, intl])
    return <DownloadCallbackViewAction outlined callback={download} />
}

const UploadAction = ({ factor }: { factor: DirectInputDownloadFactor }) => {
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const dispatch = useDispatch()
    const uploadUsageMonthly = useUploadDirectInput_usageMonthly()
    const uploadUsageWeekly = useUploadDirectInput_usageWeekly()
    const uploadUsageDaily = useUploadDirectInput_usageDaily()

    const upload = useCallback((file: File) => {

        if (factor.usageTypes && factor.usageTypes.length > 0) {
            if (factor.usageTypes.length === 1) {
                const usageType = factor.usageTypes[0]
                if (CustUsageType.DAILY === usageType) {
                    uploadUsageDaily({ file }, { serialized: true, silent: true }).then(warningMessages => {
                        if (warningMessages.length === 0) {
                            dispatch(applicationActions.pushSuccess({
                                title: intl.formatMessage({ id: 'post /smt-api/usage/usageDaily' }),
                                messages: [{ code: 'notice.success' }],
                            }))
                        } else {
                            const functionId = functionStore.register(() => {
                                uploadUsageDaily({ file, isConfirm: true }, { serialized: true })
                            })
                            dispatch(applicationActions.pushWarning({
                                title: intl.formatMessage({ id: 'confirm' }),
                                messages: warningMessages,
                                actions: [{
                                    label: 'CANCEL'
                                }, {
                                    functionId,
                                    label: 'CONFIRM',
                                }]
                            }))
                        }
                    })
                } else if (CustUsageType.WEEKLY === usageType) {
                    uploadUsageWeekly({ file }, { serialized: true })
                } else if (CustUsageType.MONTHLY === usageType) {
                    uploadUsageMonthly({ file }, { serialized: true })
                }
            } else {
                dispatch(applicationActions.pushError({ title: { code: 'upload' }, messages: { code: 'w0003' } }))
            }
        } else {
            dispatch(applicationActions.pushError({ title: { code: 'upload' }, messages: { code: 'w0571', args: [intl.formatMessage({ id: 'upload' })] } }))
        }
    }, [dispatch, factor.usageTypes, functionStore, intl, uploadUsageDaily, uploadUsageMonthly, uploadUsageWeekly])

    const uploadUsageMethod = useCallback((files: FileList | null) => {
        files !== null && upload(files[0])
    }, [upload])

    return <UploadCallbackViewAction access="STCK.CSUGS120.UPLOAD" callback={uploadUsageMethod} />
}