import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { dateToJson } from "../../utils/serializer"
import { TableEntity, tableEntityFromJson, TableEntityJson, tableEntityToJson } from "../../utils/TableEntity"

export type TnmShippingRouteDetail = Required & Partial<Optional> & TableEntity
export type TnmShippingRouteDetailJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const tnmShippingRouteDetailToJson: Project<TnmShippingRouteDetail, TnmShippingRouteDetailJson> = obj => ({
    ...tableEntityToJson(obj),
    cyCloseDate: dateToJson(obj.cyCloseDate),
    etd: dateToJson(obj.etd),
    eta: dateToJson(obj.eta),
})
export const tnmShippingRouteDetailFromJson: Project<TnmShippingRouteDetailJson, TnmShippingRouteDetail> = json => ({
    ...tableEntityFromJson(json),
    cyCloseDate: dateFromJson(json.cyCloseDate),
    etd: dateFromJson(json.etd),
    eta: dateFromJson(json.eta),
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'cyCloseDate' | 'etd' | 'eta'> {
    cyCloseDate: Date,
    etd: Date,
    eta: Date,
}

interface JsonRequired {
}

interface JsonOptional {
    shippingCalendarId: number,
    srId: number,
    transportDays: number,
    cyCloseDays: number,
    cyCloseDate: string,
    etd: string,
    eta: string,
    etdWeek: string,
    etaWeek: string,
    shippingFrequency: string,
}