import {useCallback} from "react"
import {IntlString} from "../../../utils/IntlString"
import {Projects} from "../../../utils/Projects"
import {useDownload, usePost, useUpload} from "../../../utils/fetch/useFetch"
import {PageResultEntity, pageResultEntityFromJson} from "../../utils/PageResultEntity"
import {ExternalOrderCategoryList, externalOrderCategoryListFromJson} from "../models/ExternalOrderCategoryList"
import {ExternalOrderDownloadListFactorToJson, ExternalOrderListFactor} from "../models/ExternalOrderFactor"
import {ExternalOrderListResult, externalOrderListResult} from "../models/ExternalOrderListResult"
import {GroupingDataList, groupingDataListFromJson} from "../models/GroupingDataList"
import {inputExternalDownloadDataRangeFactorToJson} from "../models/InputExternalDownloadDataRangeFactor"

export const useGetExternalOrderByPage = () => {
    const url = '/integration-api/externalOrder/getExternalOrderListByPage'
    const payloadProject = useCallback(ExternalOrderDownloadListFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(externalOrderListResult), [])
    return usePost<ExternalOrderListFactor, PageResultEntity<ExternalOrderListResult>>(url, payloadProject, resultProject)
}

export const useUploadExternalOrder = () => {
    const url = '/integration-api/externalOrder/uploadExternalOrder'
    const payloadProject = useCallback((obj: { file: File, isConfirm?: boolean }) => obj, [])
    const resultProject = useCallback((m: IntlString[]) => m, [])
    return useUpload<{ file: File, isConfirm?: boolean }, IntlString[]>(url, payloadProject, resultProject)
}

export const useDownloadExternalOrder = () => {
    const url = '/integration-api/externalOrder/downloadExternalOrder'
    const payloadProject = useCallback((obj: { poIds: number[] }) => obj.poIds, [])
    return useDownload<{ poIds: number[] }>(url, payloadProject, { fileTimestamp: true })
}

export const useDownloadExternalOrderTemplate = () => {
    const url = '/integration-api/externalOrder/downloadExternalOrderTemplate'
    const payloadProject = useCallback(inputExternalDownloadDataRangeFactorToJson, [])
    return useDownload<{ }>(url, payloadProject, { fileTimestamp: true })
}

export const useConfirmExternalOrder = () => {
    const url = '/integration-api/externalOrder/confirmExternalOrder'
    const payloadProject = useCallback((obj: {orderId: number}) => obj.orderId, [])
    return usePost<{ orderId: number }, undefined>(url, payloadProject, Projects.IGNORE)
}

export const useGetCategory = () => {
    const url = '/integration-api/externalOrder/getCategory'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(externalOrderCategoryListFromJson), [])
    return usePost<undefined, ExternalOrderCategoryList[]>(url, payloadProject, resultProject)
}

export const useGetGrouping = () => {
    const url = '/integration-api/externalOrder/getGrouping'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(groupingDataListFromJson), [])
    return usePost<undefined, GroupingDataList[]>(url, payloadProject, resultProject)
}

export const useIsExistGrouping = () => {
    const url = '/integration-api/externalOrder/isExistGrouping'
    const payloadProject = useCallback((obj: {grouping: string}) => obj.grouping, [])
    const resultProject = useCallback((state: number) => state, [])
    return usePost<{grouping: string}, number>(url, payloadProject, resultProject)
}

export const useCreateGrouping = () => {
    const url = '/integration-api/externalOrder/createGrouping'
    const payloadProject = useCallback((obj: {orderId: number}) => obj.orderId, [])
    const resultProject = useCallback((state: number) => state, [])
    return usePost<{orderId: number}, number>(url, payloadProject, resultProject)
}
