import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"

export type FOBSettingListResult = Required & Partial<Optional>
type FOBSettingListResultJson = JsonRequired & Partial<JsonOptional>
export const getFOBSettingListResultFromJson: Project<FOBSettingListResultJson, FOBSettingListResult> = json => ({
    ...json,
    createdDate: dateFromJson(json.createdDate),
    updatedDate: dateFromJson(json.updatedDate),
    effectiveStartDate: dateFromJson(json.effectiveStartDate),
    effectiveEndDate: dateFromJson(json.effectiveEndDate),
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'createdDate' | 'updatedDate' | 'effectiveStartDate' | 'effectiveEndDate'> {
    createdDate: Date,
    updatedDate: Date,
    effectiveStartDate: Date,
    effectiveEndDate: Date,
}

interface JsonRequired {
}

interface JsonOptional {
    fobSettingId: number,
    invCurrencyCode: string,
    contrRouteId: number,
    consignee: string,
    ftaInvoice: string,
    customClearanceInvoice: string,
    createdBy: string,
    createdDate: string,
    updatedBy: string,
    updatedDate: string,
    version: number,
    ftaExchangeRate: number,
    customExchangeRate: number,
    effectiveStartDate: string,
    effectiveEndDate: string,
    fobInvValueLogic: number,
    remark: string,

    contrRouteCode: string,
    consigneeCode: string,
    ftaInvoiceCode: string,
    customClearanceInvoiceCode: string,
}