import { useCallback, useMemo, useState } from "react"
import { useGetUnitPartsList } from "../../../services/master/apis/unitPartsApi"
import { UnitPartsFactor, UnitPartsFactorFromJson, unitPartsFactorToJson } from "../../../services/master/models/UnitPartsFactor"
import { UnitPartsResult } from "../../../services/master/models/UnitPartsResult"
import sessionKeys from "../../../utils/sessionKeys"
import { useMatch } from "../../../utils/useMatch"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { MLS080PcUi } from "./MLS080PcUi"

const deaultFilters = { page: { currentPage: 0, pageSize: 10000 } }
const searchCachekey = sessionKeys.Filters_MLS080

export const MLS080 = () => {

    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? UnitPartsFactorFromJson(JSON.parse(filters)) : null
    }, [])

    const { path } = useMatch()
    const [filters, setFilters] = useState<UnitPartsFactor>(cacheFilters ?? deaultFilters)
    const [data, setData] = useState<UnitPartsResult[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)

    const getDataList = useGetUnitPartsList()
    const search = useCallback((filters: UnitPartsFactor) => {
        getDataList(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(unitPartsFactorToJson(filters)))
        })
    }, [getDataList, setData])

    useSearchOnLoad(search, filters)

    return <MLS080PcUi
        path={path}
        filters={filters}
        search={search}
        data={data}
        totalCount={totalCount}
        setFilters={setFilters}
    />
}