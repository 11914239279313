import { Button, makeStyles, MenuList } from "@material-ui/core"
import { usePopover } from "@rithe/utils"
import clsx from 'clsx'
import React, { ReactNode } from "react"
import { Access } from '../Access/Access'
import { PopoverMenu } from "../PopoverMenu"

interface GroupedViewActionProps {
    access?: string,
    title: NonNullable<React.ReactNode>,
    disabled?: boolean,
    outlined?: boolean,
    children?: (onClose: () => void) => ReactNode | ReactNode[],
}

export const GroupedViewAction = (props: GroupedViewActionProps) => {
    const { access, title, disabled, outlined, children } = props
    const { open, anchorEl, onOpen, onClose } = usePopover()

    const styles = useStyles()
    const action = <>
        <Button
            variant={outlined ? 'outlined' : 'contained'}
            color="primary"
            disabled={disabled}
            onClick={onOpen}
            className={clsx(styles.root, outlined && styles.rootOutlined)}
        >
            {title}
        </Button>
        <PopoverMenu
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            onClose={onClose}
        >
            <MenuList>
                {children && children(onClose)}
            </MenuList>
        </PopoverMenu>
    </>
    return access ? <Access access={access}>{action}</Access> : action
}

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 120,
    },
    rootOutlined: {
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
    },
}))