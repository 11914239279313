import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useComMasterGetAllCompany, useComMasterGetAllRegion } from "../../../services/common/apis/commonMasterApi"
import { useGetCalendarList } from "../../../services/master/apis/calendarApi"
import { CalendarFactor, calendarFactorFromJson, calendarFactorToJson } from "../../../services/master/models/CalendarFactor"
import { CalendarResult } from "../../../services/master/models/CalendarResult"
import { TnmCompany } from "../../../services/master/models/TnmCompany"
import { TnmRegion } from "../../../services/master/models/TnmRegion"
import sessionKeys from "../../../utils/sessionKeys"
import { useMatch } from "../../../utils/useMatch"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { MLS130PcUi } from "./MLS130PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }
const searchCachekey = sessionKeys.Filters_MLS130

export const MLS130 = () => {

    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? calendarFactorFromJson(JSON.parse(filters)) : null
    }, [])
    const { path } = useMatch()
    const [filters, setFilters] = useState<CalendarFactor>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<CalendarResult[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [regionList, setRegion] = useState<TnmRegion[]>([])
    const [companyList, setCompany] = useState<TnmCompany[]>([])

    const getDataList = useGetCalendarList()
    const search = useCallback((filters: CalendarFactor) => {
        getDataList(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(calendarFactorToJson(filters)))
        })
    }, [getDataList, setData])

    useSearchOnLoad(search, filters)

    const getRegionList = useComMasterGetAllRegion()
    useEffect(() => {
        getRegionList(undefined, { silent: true }).then(result => {
            setRegion(result || [])
        })
    }, [getRegionList])

    const getCompanyList = useComMasterGetAllCompany()
    useEffect(() => {
        getCompanyList(undefined, { silent: true }).then(result => {
            setCompany(result || [])
        })
    }, [getCompanyList])

    return <MLS130PcUi
        path={path}
        filters={filters}
        search={search}
        data={data}
        totalCount={totalCount}
        regionList={regionList}
        companyList={companyList}
        setFilters={setFilters}
    />
}