import { Break, EntryItem, Form, Message, StringItem } from "@rithe/form"
import { default as React, memo, useMemo } from "react"
import { useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { StepperAction } from "../../../components/View/Step/StepperAction"
import { View } from "../../../components/View/View"
import { ScreenMode } from '../../../services/common/enums/ScreenMode'
import { ContractRequestType } from "../../../services/master/enums/ContaractRequestType"
import { PartsDetailResult } from '../../../services/master/models/PartsDetailResult'
import { RequestHeaderResult } from '../../../services/master/models/RequestHeaderResult'
import { RequestToResult } from "../../../services/master/models/RequestToResult"
import { TnmCurrency } from "../../../services/master/models/TnmCurrency"
import { TnmUom } from "../../../services/master/models/TnmUom"
import { useGetCompanyUid } from "../../../utils/ApplicationUtils"
import { CreateRequestAction, RequestAddView, SaveRequestAction, SubmitAction } from "./RequestAddView"
import { RequestModifyView, SubmitForModifyPackingAction } from "./RequestModifyView"
import { RequestPriceView, SubmitForModifyPriceAction } from "./RequestPriceView"
import { RequestRemoveView, SubmitForRemoveAction } from "./RequestRemoveView"
import { RequestTerminateView, SubmitForRequestTerminateAction } from "./RequestTerminateView"

export interface MOS021PcUiProps {
    mode: ScreenMode,
    requestToList: RequestToResult[],
    headerInfo: RequestHeaderResult,
    setHeaderInfo: React.Dispatch<React.SetStateAction<RequestHeaderResult>>,
    partsList: PartsDetailResult[],
    setPartsList: React.Dispatch<React.SetStateAction<PartsDetailResult[]>>,
    search: (requestNo: string) => void,
    requestNo: string,
    requestType: number | undefined,
    uomList: TnmUom[],
    currencyList: TnmCurrency[],
    messages: Message[],
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>
}

export const MOS021PcUi = memo((props: MOS021PcUiProps) => {
    const { requestType, mode, headerInfo, setHeaderInfo, partsList, setPartsList, search, requestNo, setMessages } = props

    const actionsForAdd =
        (mode === ScreenMode.CREATE && requestNo === undefined) ? [<CreateRequestAction headerInfo={headerInfo} setHeaderInfo={setHeaderInfo} partsList={partsList} setPartsList={setPartsList} setMessages={setMessages} search={search} />] :
            (mode === ScreenMode.CREATE && requestNo !== undefined) ? [<SaveRequestAction headerInfo={headerInfo} setHeaderInfo={setHeaderInfo} partsList={partsList} setPartsList={setPartsList} search={search} setMessages={setMessages} requestNo={requestNo} />, <SubmitAction setMessages={setMessages} headerInfo={headerInfo} partsList={partsList} />] :
                mode === ScreenMode.EDIT ? [<SaveRequestAction headerInfo={headerInfo} setHeaderInfo={setHeaderInfo} partsList={partsList} setPartsList={setPartsList} search={search} setMessages={setMessages} requestNo={requestNo} />, <SubmitAction setMessages={setMessages} headerInfo={headerInfo} partsList={partsList} />] : []
    const actionsForPacking = mode !== ScreenMode.VIEW ? [<SubmitForModifyPackingAction setMessages={setMessages} headerInfo={headerInfo} partsList={partsList} />] : []
    const actionsForPrice = mode !== ScreenMode.VIEW ? [<SubmitForModifyPriceAction setMessages={setMessages} headerInfo={headerInfo} partsList={partsList} />] : []
    const actionsForRemove = mode !== ScreenMode.VIEW ? [<SubmitForRemoveAction setMessages={setMessages} headerInfo={headerInfo} partsList={partsList} />] : []
    const actionsForRequestTerminate = mode !== ScreenMode.VIEW ? [<SubmitForRequestTerminateAction setMessages={setMessages} headerInfo={headerInfo} partsList={partsList} />] : []

    const actions = requestType === ContractRequestType.ADD ? actionsForAdd : requestType === ContractRequestType.MODIFY_PACKING ? actionsForPacking :
        requestType === ContractRequestType.MODIFY_PRICE ? actionsForPrice : requestType === ContractRequestType.REMOVE ? actionsForRemove : requestType === ContractRequestType.TERMINATE ? actionsForRequestTerminate : []

    return <View actions={<StepperAction actions={actions} />}>
        {requestType === ContractRequestType.ADD ? <RequestAddView {...props} /> :
            requestType === ContractRequestType.MODIFY_PACKING ? <RequestModifyView {...props} /> :
                requestType === ContractRequestType.MODIFY_PRICE ? <RequestPriceView {...props} /> :
                    requestType === ContractRequestType.REMOVE ? <RequestRemoveView {...props} /> :
                        requestType === ContractRequestType.TERMINATE ? <RequestTerminateView {...props} /> :
                            <DefaultView {...props} />}
    </View>
})

const DefaultView = (props: MOS021PcUiProps) => {
    const { requestToList, headerInfo } = props
    const intl = useIntl()
    return (
        <>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={1}
                    step
                    title={intl.formatMessage({ id: 'add_new_parts_title_one' })}
                    subtitle={intl.formatMessage({ id: "add_new_parts_message_one" })}
                />
                <SectionCardContent>
                    <TheFirstDiv requestToList={requestToList} headerInfo={headerInfo} />
                </SectionCardContent>
            </SectionCard>
        </>
    )
}
interface TheFirstDivInfo {
    requestToList: RequestToResult[],
    headerInfo: RequestHeaderResult
}
const TheFirstDiv = memo((props: TheFirstDivInfo) => {
    const { requestToList, headerInfo, } = props
    const intl = useIntl()
    const companyUid = useGetCompanyUid()
    const requestToMap = useMemo(() => requestToList.filter((m) => m.cbdsUid !== companyUid).map(m => [m.cbdsUid, m.cbdsCode] as [string, string]), [companyUid, requestToList])
    return <Form data={headerInfo} readonly labelDisplay="block" helperDisplay="tooltip" >
        <EntryItem field="requestTo" required label={intl.formatMessage({ id: 'field.requestTo' })} entries={requestToMap} />
        <Break />
        <StringItem field="description" label={intl.formatMessage({ id: 'field.description' })} colSpan={2} rowSpan={4} />
    </Form>
})