import { useCallback, useState } from "react"
import { useGetEtlBatchPage } from "../../../services/smt/api/ETLBatchApi"
import { EtlBatchTransfer } from "../../../services/smt/models/EtlBatchTransfer"
import { EtlBatchView } from "../../../services/smt/models/EtlBatchView"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { BTETL010PcUi } from "./BTETL010PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }

export const BTETL010 = () => {

    const [filters, setFilters] = useState<EtlBatchView>(defaultFilters)
    const [data, setData] = useState<EtlBatchTransfer[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const getEtlBatchPage = useGetEtlBatchPage()
    const search = useCallback((filters: EtlBatchView) => {
        getEtlBatchPage({ ...filters }, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
        })
    }, [getEtlBatchPage])

    useSearchOnLoad(search, filters)

    return <BTETL010PcUi
        filters={filters}
        setFilters={setFilters}
        search={search}
        data={data}
        totalCount={totalCount}
    />
}