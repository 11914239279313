import { makeStyles, Typography } from "@material-ui/core"
import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Row, Searching, Sorting, StringTypeProvider, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { StringFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/StringFormatter"
import { Break, DateItem, Form, StringItem } from "@rithe/form"
import { Arrays, Records } from "@rithe/utils"
import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { PrintCallbackViewAction } from "../../../components/Action/PrintCallbackViewAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CodeItem } from "../../../components/Form/CodeItem"
import { View } from "../../../components/View/View"
import { usePrintDownloadPDF } from "../../../services/delivery/apis/deliveryOutboundApi"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { EstimatedData, InboundPalnData, OrderDetailHead, OrderPartsData } from "../../../services/order/models/OrderDetailResult"

interface OPS021PcUiProps {
    headerInfo: OrderDetailHead,
    partsDetailList: OrderPartsData[],
    cbdsList: TnvCbds[]
}

export const OPS021PcUi = (props: OPS021PcUiProps) => {
    const { headerInfo, partsDetailList, cbdsList } = props
    const actions = [<PrintAction headerInfo={headerInfo}></PrintAction>]
    const intl = useIntl()
    return (
        <View actions={actions}>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={1}
                    title={intl.formatMessage({ id: 'BasicInfo' })}
                    subtitle={intl.formatMessage({ id: 'basicInfoSubview' })}
                />
                <SectionCardContent>
                    <Step1PoMonitoringDetail headerInfo={headerInfo} cbdsList={cbdsList} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={2}
                    title={intl.formatMessage({ id: 'shippingPlanInfo' })}
                    subtitle={intl.formatMessage({ id: 'shippingPlanInfoSub' })}
                />
                <SectionCardContent>
                    <Step2PoBaseInfoTable currentDate={headerInfo.currentDate} partsDetailList={partsDetailList} />
                </SectionCardContent>
            </SectionCard>
        </View >
    )
}

const Step1PoMonitoringDetail = memo((props: { headerInfo: OrderDetailHead, cbdsList: TnvCbds[] }) => {
    const { headerInfo } = props
    const intl = useIntl()

    return <Form readonly data={headerInfo} labelDisplay="block" helperDisplay="tooltip" >
        <StringItem field="orderNo" label={intl.formatMessage({ id: 'purchaseOrderNo' })} />
        <CodeItem field="orderType" label={intl.formatMessage({ id: 'field.orderType' })} code={CodeCategory.OrderType} />
        <CodeItem field="spotStatus" label={intl.formatMessage({ id: 'field.status' })} code={CodeCategory.PoSpotStatus} />
        <CodeItem field="shippingMode" label={intl.formatMessage({ id: 'field.shippingMode' })} code={CodeCategory.ShippingMode} />
        <StringItem field="seller" required readonly label={intl.formatMessage({ id: 'seller' })} />
        <StringItem field="receiver" label={intl.formatMessage({ id: 'receiver' })} />
        <DateItem field="receivedDate" label={intl.formatMessage({ id: 'orderDate' })} />
        <StringItem colSpan={2} field="paymentTermsDesc" label={intl.formatMessage({ id: 'field.paymentTermsDesc' })} multiline />
        <DateItem field="completedDate" label={intl.formatMessage({ id: 'field.completedDate' })} />
        <Break />
        <StringItem colSpan={2} field="remark" label={intl.formatMessage({ id: 'field.remark' })} multiline />
    </Form>
})


const Step2PoBaseInfoTable = memo((props: { currentDate: Date, partsDetailList: OrderPartsData[] }) => {
    const { currentDate, partsDetailList } = props
    const intl = useIntl()
    const [order, setOrder] = useState<string[]>([])

    const palnDetas: InboundPalnData[] = useMemo(() => partsDetailList.length > 0 ? partsDetailList[0].inboundPlanList : [], [partsDetailList])
    const estimatedDatas: EstimatedData[] = useMemo(() => partsDetailList.length > 0 ? partsDetailList[0].estimatedInboundList : [], [partsDetailList])

    const columns = useMemo(() => {
        const fixedColumns = [
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
            { field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsNo' }), width: 200 },
            { field: 'backNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.backNo' }), width: 200 },
            { field: 'salesOrderNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'salesOrderNo' }), width: 200 },
            { field: 'supplierCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierCode' }), width: 200 },
            { field: 'shipper', dataTypeName: 'string', title: intl.formatMessage({ id: 'shipper' }), width: 200 },
            { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 100 },
            { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 150 },
            { field: 'orderQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderQty' }), width: 150 },
            { field: 'unitPrice', dataTypeName: 'number', title: intl.formatMessage({ id: 'unitPrice' }), width: 150 },
            { field: 'currency', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.currency' }), width: 120 },
            { field: 'spotDetailStatus', dataTypeName: CodeCategory.PoSpotDetailStatus, title: intl.formatMessage({ id: 'field.status' }), width: 120 },
        ]
        //Inbounded Qty(Current Date) Column
        const iCurrentCategories = [{ key: 'iCurrent', value: 'Inbounded Qty (Current Date)' }]
        const iCurrentDateColumns = [{ field: 'currentInbQty', dataTypeName: 'number', title: intl.formatTime(currentDate, { dateStyle: 'medium' }), categories: iCurrentCategories, width: 240 }]

        //Inbound Plan Date Column
        const getInboundCategories = (index: number) => [
            { key: 'inbound', value: intl.formatMessage({ id: 'field.inboundPlanDate' }) },
            { key: `inbound${index}`, value: intl.formatDate(palnDetas[index].crdDate, { dateStyle: 'medium' }) },
        ]
        const inboundPlanColumns = Arrays.range(0, palnDetas.length).flatMap(index => [
            { field: 'crdQty' + index, dataTypeName: 'number', title: intl.formatMessage({ id: 'plan' }), categories: getInboundCategories(index), width: 150, getCellValue: getPlanDataValueByName(index, 'crdQty') },
            { field: 'status' + index, dataTypeName: 'status', title: intl.formatMessage({ id: 'field.status' }), categories: getInboundCategories(index), width: 150, getCellValue: getPlanDataValueByName(index, 'status') },
        ])

        // Estimated Inbound Date Column   
        const getEstimatedCategories = (index: number) => [
            { key: 'estimated', value: intl.formatMessage({ id: 'estimatedInboundDate' }) },
            { key: `estimated${index}`, value: intl.formatTime(estimatedDatas[index].estimatedCrdDate, { dateStyle: 'medium' }) },
        ]
        const estimatedInboundColums = Arrays.range(0, estimatedDatas.length).map(index => {
            return { field: 'qty' + index, dataTypeName: 'number', title: estimatedDatas[index].companyTitle ?? '', categories: getEstimatedCategories(index), width: estimatedDatas.length > 1 ? 200 : 350, getCellValue: getEstimatedValue(index) }
        })

        // all columns
        return Arrays.concat(fixedColumns, iCurrentDateColumns, inboundPlanColumns, estimatedInboundColums)
    }, [currentDate, estimatedDatas, intl, palnDetas])

    useEffect(() => {
        setOrder(columns.map(column => column.field))
    }, [columns])


    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <StatusTypeProvider />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.PoSpotDetailStatus} />
            <Data rows={partsDetailList} columns={columns} />
            <ColumnFreeze />
            <ColumnVisibility columnSettings={{
                partsNo: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnOrdering order={order} onOrderChange={setOrder} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
        </DataGrid>
    </div>
})

const CheckFormatter = ({ value }: StringFormatterProps) => {
    const style = useStyles()
    const bgcolor = 'OK' === value ? '#00CCAD' : '#D94C00'
    return <div className={style.status} style={{ background: bgcolor, color: 'white', width: '100%' }}>
        <Typography variant="body2" >{value}</Typography>
    </div>
}

const StatusTypeProvider = () => {
    return <StringTypeProvider name="status" Formatter={CheckFormatter} />
}

const getEstimatedValue = (index: number) => {
    return (row: Row) => {
        return row.estimatedInboundList[index].qty
    }
}

const getPlanDataValueByName = (index: number, columnName: string) => {
    return (row: Row) => {
        return row.inboundPlanList[index][columnName]
    }
}

const PrintAction = ({ headerInfo }: { headerInfo: OrderDetailHead }) => {
    const print = usePrintDownloadPDF()
    const click = useCallback(() => {
        if (headerInfo.orderId) {
            const poIds: string = JSON.stringify([headerInfo.orderId])
            print({ reportId: 'OPR010', labelId: 'Purchase Order', param: poIds })
        }
    }, [print, headerInfo])
    return <PrintCallbackViewAction access="ORDER.OPS021.PRINT" callback={click} />
}

const useStyles = makeStyles(theme => ({
    status: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70%',
        paddingRight: theme.spacing(1),
        borderRadius: 5
    },
    default: {},
}))