import { useCallback } from "react"
import { Projects } from "../../../utils/Projects"
import { usePost, useUpload } from "../../../utils/fetch/useFetch"
import { UploadedDataList, uploadedDataListFromJson } from "../models/UploadedDataList"

export const useGetUploadedData = () => {
    const url = '/integration-api/myUpload/getUploadedData'
    const payloadProject = useCallback((obj: { templateId: string }) => obj.templateId, [])
    const resultProject = useCallback(Projects.array(uploadedDataListFromJson), [])
    return usePost<{ templateId: string }, UploadedDataList[]>(url, payloadProject, resultProject)
}

export const useUploadstorageDetail = () => {
    const url = '/integration-api/upload/storage'
    const payloadProject = useCallback((obj: { templateId?: String, file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ templateId?: String, file: File }, undefined>(url, payloadProject, resultProject)
}