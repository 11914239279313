import moment from "moment"
import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"


export type CustomerUsageView120 = Required & Partial<Optional>
type CustomerUsageViewJson120 = JsonRequired & Partial<JsonOptional>

export const customerUsageViewToJson120: Project<CustomerUsageView120, CustomerUsageViewJson120> = obj => ({
    ...obj,
    updatedDateStart: obj.updatedDateStart == null ? undefined : moment(obj.updatedDateStart).format('YYYY-MM-DD'),
    updatedDateEnd: obj.updatedDateEnd == null ? undefined : moment(obj.updatedDateEnd).format('YYYY-MM-DD'),
    usageStartDate: obj.usageStartDate == null ? undefined : moment(obj.usageStartDate).format('YYYY-MM-DD'),
    usageEndDate: obj.usageEndDate == null ? undefined : moment(obj.usageEndDate).format('YYYY-MM-DD'),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
})

export const customerUsageViewFromJson120: Project<CustomerUsageViewJson120, CustomerUsageView120> = json => ({
    ...json,
    updatedDateStart: json.updatedDateStart == null ? undefined : moment(json.updatedDateStart).toDate(),
    updatedDateEnd: json.updatedDateEnd == null ? undefined : moment(json.updatedDateEnd).toDate(),
    usageStartDate: json.usageStartDate == null ? undefined : moment(json.usageStartDate).toDate(),
    usageEndDate: json.usageEndDate == null ? undefined : moment(json.usageEndDate).toDate(),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page),
})

interface Required extends JsonRequired {

}

interface Optional extends Omit<JsonOptional, 'updatedDateStart' | 'updatedDateEnd' | 'usageStartDate' | 'usageEndDate'> {
    updatedDateStart: Date,
    updatedDateEnd: Date,
    usageStartDate: Date,
    usageEndDate: Date,
    page: PageViewEntity,

}

interface JsonRequired {

}

interface JsonOptional {
    customerPartsId: number,
    modelNo: string,
    partsNo: string,
    customerCode: string,
    expCountry: string,
    supplierCode: string,
    extRefNo: string,
    dataSource: number,
    dataSourceStr: string,
    decimalDigits: number,
    updatedDateStart: string,
    updatedDateEnd: string,
    usageStartDate: string,
    usageEndDate: string,
    usageQty: number,
    monthlyQtyMap: Map<string, number>,
    page: PageViewEntityJson,
}