import { Project } from "../../../utils/Project"

export interface OrderCalculationCreateViewCondition {
    orderCalcNo?: string,
    orderGroupId?: string,
}

interface OrderCalculationCreateViewConditionJson {
    orderCalcNo?: string,
    orderGroupId?: string,
}

export const orderCalculationCreateViewConditionToJson: Project<OrderCalculationCreateViewCondition, OrderCalculationCreateViewConditionJson> = obj => ({
    ...obj
})


