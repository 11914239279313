import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"

export type SChangeRequestListResult = SChangeRequestResultRequired & Partial<SChangeRequestResultOptional>
type SChangeRequestListResultJson = SChangeRequestResultJsonRequired & Partial<SChangeRequestResultJsonOptional>

export const sChangeRequestListResultFromJson: Project<SChangeRequestListResultJson, SChangeRequestListResult> = json => ({
    ...json,
    orderDate: dateFromJson(json.orderDate),
    orderFirstDate: dateFromJson(json.orderFirstDate),
    orderLastDate: dateFromJson(json.orderLastDate),
    targetFirstDate: dateFromJson(json.targetFirstDate),
    targetLastDate: dateFromJson(json.targetLastDate),
})

interface SChangeRequestResultRequired extends SChangeRequestResultJsonRequired {

}

interface SChangeRequestResultOptional extends Omit<SChangeRequestResultJsonOptional, 'orderDate' | 'orderFirstDate' | 'orderLastDate' | 'targetFirstDate' | 'targetLastDate' | 'reviseType'> {
    orderDate: Date,
    orderFirstDate: Date,
    orderLastDate: Date,
    targetFirstDate: Date,
    targetLastDate: Date,
}

interface SChangeRequestResultJsonRequired {
    soId: number,
    soNo: string,
    purchaseOrderNo: string,
    sellerCode: string,
    supplierCode: string,
}

interface SChangeRequestResultJsonOptional {
    suppRoId: number,
    customerRefNo: string,
    contractRouteCode: string,
    buyerCode: string,
    customerCode: string,
    businessType: number,
    orderFrequency: number,
    orderType: number,
    orderFirstDate: string,
    orderLastDate: string,
    targetFirstDate: string,
    targetLastDate: string,
    shippingMode: number,
    orderDate: string,
    spotOrderReason: string,
    remark: string,
    orderStatus: number,
    reviseNo: string,
    reviseStatus: number,
    contractNo: string,
    groupId: number,
    groupNo: string,
    contractOrGroupNo:string
}

