import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { Distatus } from "../enums/DiStatus"
import { DiType } from "../enums/DiType"

export type CustDIHeaderInfo = Required & Partial<Optional>

type CustDIHeaderInfoJson = JsonRequired & Partial<JsonOptional>

export const custDIHeaderInfoFromJson: Project<CustDIHeaderInfoJson, CustDIHeaderInfo> = json => ({
    ...json,
    diDate: dateFromJson(json.diDate),
})

interface Required extends JsonRequired { }

interface Optional extends Omit<JsonOptional, 'diDate'> {
    diDate: Date,
    diType: DiType,
    status: Distatus,
}

interface JsonRequired {
}

interface JsonOptional {
    diId: number,
    customerId: number,
    dcCode: string,
    customerCode: string,
    diNo: string,
    diVersion: number,
    diType: number,
    diRefNo: string,
    diDate: string,
    diRun: string,
    remark: string,
    status: number,
}