import { useCallback, useEffect, useMemo, useState } from "react"
import { useGetBuyerSellerFilterList, useGetGIInvoicePage } from "../../../services/accounting/apis/accountingBuyerApi"
import { BuyerInvoiceTransfer } from "../../../services/accounting/models/BuyerInvoiceTransfer"
import { BuyerInvoiceView, buyerInvoiceViewFromJson, buyerInvoiceViewToJson } from "../../../services/accounting/models/BuyerInvoiceView"
import { InvoiceCbds } from "../../../services/accounting/models/SellerInvoiceFilterResponse"
import { useComMasterGetActivePort } from "../../../services/common/apis/commonMasterApi"
import { TnmPort } from "../../../services/master/models/TnmPort"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { AIS020PcUi } from "./AIS020PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }
const searchCachekey = sessionKeys.Filters_AIS020

export const AIS020 = () => {
    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? buyerInvoiceViewFromJson(JSON.parse(filters)) : null
    }, [])

    const [filters, setFilters] = useState<BuyerInvoiceView>(cacheFilters ?? defaultFilters)

    const [data, setData] = useState<BuyerInvoiceTransfer[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [portList, setPortList] = useState<TnmPort[]>([])
    const [buyerList, setBuyerList] = useState<InvoiceCbds[]>([])
    const [sellerList, setSellerList] = useState<InvoiceCbds[]>([])

    const getGIInvoiceList = useGetGIInvoicePage()
    const getPortList = useComMasterGetActivePort()
    const getBuyerSellerList = useGetBuyerSellerFilterList()

    useEffect(() => {
        getPortList(undefined, { silent: true }).then(result => {
            setPortList(result || [])
        })
        getBuyerSellerList(undefined, { silent: true }).then(result => {
            setBuyerList(result.buyerFilter || [])
            setSellerList(result.sellerFilter || [])
        })
    }, [getPortList, getBuyerSellerList])

    const search = useCallback((filters: BuyerInvoiceView) => {
        getGIInvoiceList(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(buyerInvoiceViewToJson(filters)))
        })
    }, [getGIInvoiceList, setData])

    useSearchOnLoad(search, filters)

    return <AIS020PcUi
        filters={filters}
        setFilters={setFilters}
        search={search}
        data={data}
        totalCount={totalCount}
        portList={portList}
        buyerList={buyerList}
        sellerList={sellerList}
    />
}