import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"
import { ActiveFlag } from "../enums/ActiveFlag"

export type ShippingRouteResult = Required & Partial<Optional> & TableEntity
type ShippingRouteResultJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const shippingRouteResultFromJson: Project<ShippingRouteResultJson, ShippingRouteResult> = json => ({
    ...tableEntityFromJson(json),
    effectiveStartDate: dateFromJson(json.effectiveStartDate),
    effectiveEndDate: dateFromJson(json.effectiveEndDate),
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'effectiveStartDate' | 'effectiveEndDate'> {
    activeFlag: ActiveFlag,
    effectiveStartDate: Date,
    effectiveEndDate: Date,
}

interface JsonRequired {
    buId: number,
}

interface JsonOptional {
    srId: number,
    shippingRouteCode: string,
    displayShippingRoute: string,
    shippingMode: number,
    shipperUid: string,
    shipperCode: string,
    receiveUid: number,
    receiverCode: string,
    originRegionCode: string,
    originStateCode: string,
    fromPortId: number,
    fromPortCode: string,
    destinationRegionCode: string,
    destinationStateCode: string,
    toPortId: number,
    toPortCode: string,
    expCcLeadtime: number,
    impCcLeadtime: number,
    transportDays: number,
    cyCloseDay: number,
    activeFlag: number,
    effectiveStartDate: string,
    effectiveEndDate: string,
    frequency: string,

    createUid: string,
}