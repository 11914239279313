import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { dateFromJson } from "../../utils/deserializer"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"

export type ReceivedPlaceOrderView = Required & Partial<Optional>
type ReceivedPlaceOrderViewJson = JsonRequired & Partial<JsonOptional>
export const receivedPlaceOrderToJson: Project<ReceivedPlaceOrderView, ReceivedPlaceOrderViewJson> = obj => ({
    ...obj,
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
    orderDate: dateToJson(obj.orderDate),
    soDate: dateToJson(obj.soDate),
    submittedDate: dateToJson(obj.submittedDate),
    completedDate: dateToJson(obj.completedDate),
    createdDate: dateToJson(obj.createdDate),
    updatedDate: dateToJson(obj.updatedDate),
})
export const receivedPlaceOrderFromJson: Project<ReceivedPlaceOrderViewJson, ReceivedPlaceOrderView> = json => ({
    ...json,
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page),
    orderDate: dateFromJson(json.orderDate),
    soDate: dateFromJson(json.soDate),
    submittedDate: dateFromJson(json.submittedDate),
    completedDate: dateFromJson(json.completedDate),
    createdDate: dateFromJson(json.createdDate),
    updatedDate: dateFromJson(json.updatedDate),
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'page' | 'orderDate' | 'soDate' | 'submittedDate' | 'completedDate' | 'createdDate' | 'updatedDate'> {
    page: PageViewEntity,
    orderDate: Date,
    soDate: Date,
    submittedDate: Date,
    completedDate: Date,
    createdDate: Date,
    updatedDate: Date,

}

interface JsonRequired {

}

interface JsonOptional {
    soSpotId: number,
    spotId: number,
    inboundPlan: string,
    buyerCode: string,
    orderType: number,
    soNo: string,
    orderDate: string,
    status: number,
    preCoId: number,
    prePoId: number,
    preSpotId: number,
    preSoId: number,
    soType: number,
    buId: number,
    supplierId: number,
    customerId: number,
    buyerBuId: number,
    soDate: string,
    inboundDcId: number,
    shipperDcId: number,
    shippingMode: number,
    remark: string,
    paymentTermsId: number,
    currency: string,
    submittedBy: string,
    submittedDate: string,
    completedDate: string,
    pendingUpdateFlag: number,
    pendingReqeustFlag: number,
    createdBy: string,
    createdDate: string,
    updatedBy: string,
    updatedDate: string,
    index?: number,
    page: PageViewEntityJson,
}