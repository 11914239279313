import { useContext, useEffect } from "react"
import { useDispatch } from "react-redux"
import { UNSAFE_RouteContext as RouteContext } from "react-router-dom"
import { homeActions } from "../../layouts/Home/HomeSlice"
import { pageInfoList } from "../../layouts/Home/PageInfoList"

export const FrequentlyUsedLayer = () => {
    const dispatch = useDispatch()
    const matches = useContext(RouteContext).matches
    const path = '/' + matches.flatMap(match => match.route.path?.split('/')).filter(Boolean).join('/')

    useEffect(() => {
        const pages = pageInfoList.filter(item => item.url === path)
        if (pages.length > 0) {
            dispatch(homeActions.statistics(pages[0].pageCode))
        }
    }, [dispatch, path])
    return null
}