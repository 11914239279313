import React, { ReactNode, useCallback } from "react"
import { Scrollbar } from "../../Scrollbar"

export const FlexScrollbar = ({ children }: { children?: ReactNode | ReactNode[] }) => {

    const onScroll = useCallback((e: UIEvent) => {
        const target = e.target as HTMLElement | null
        const container = target?.querySelector('#flex-table') as HTMLElement | null | undefined
        const table = container?.querySelector('table') as HTMLElement | null | undefined
        if (target && container && table) {
            const scrollTop = target.scrollTop
            const { height } = table.getBoundingClientRect()
            container.style.marginTop = `${scrollTop}px`
            container.style.height = `${height - scrollTop}px`
            table.style.marginTop = `-${scrollTop}px`
        }
    }, [])

    return <Scrollbar onScrollbarScroll={onScroll} flexbox>
        {children}
    </Scrollbar>
}