import { AxiosInstance, AxiosResponse } from 'axios'
import { AuthAPIResponse } from '../models/AuthAPIResponse'
import { ChangePasswordAPIRequest } from '../models/ChangePasswordAPIRequest'
import { ForgetPasswordAPIRequest } from '../models/ForgetPasswordAPIRequest'
import { LoginAPIRequest } from '../models/LoginAPIRequest'
import { RandomPasswordAPIRequest } from '../models/RandomPasswordAPIRequest'
import { RefreshVerificationCodeAPIRequest } from '../models/RefreshVerificationCodeAPIRequest'
import { ResetPasswordAPIRequest } from '../models/ResetPasswordAPIRequest'
import { UserAPIResponse } from '../models/UserAPIResponse'

const authApi = {
    login: function (axiosInstance: AxiosInstance, data: LoginAPIRequest) {
        return axiosInstance.post('/common-system-api/login', data) as Promise<AxiosResponse<AuthAPIResponse>>
    },
    changePassword: function (axiosInstance: AxiosInstance, data: ChangePasswordAPIRequest) {
        return axiosInstance.post('/common-system-api/change-password', data) as Promise<AxiosResponse<UserAPIResponse>>
    },
    forgetPassword: function (axiosInstance: AxiosInstance, data: ForgetPasswordAPIRequest) {
        return axiosInstance.post('/common-system-api/forget-password', data) as Promise<AxiosResponse<UserAPIResponse>>
    },
    resetPassword: function (axiosInstance: AxiosInstance, data: ResetPasswordAPIRequest) {
        return axiosInstance.post('/common-system-api/reset-password', data) as Promise<AxiosResponse<UserAPIResponse>>
    },
    randomPassword: function (axiosInstance: AxiosInstance, data: RandomPasswordAPIRequest) {
        return axiosInstance.post('/common-system-api/random-password', data) as Promise<AxiosResponse<UserAPIResponse>>
    },
    refreshVerificationCode: function (axiosInstance: AxiosInstance, data: RefreshVerificationCodeAPIRequest) {
        return axiosInstance.post('/common-system-api/refresh-verification-code', data) as Promise<AxiosResponse<UserAPIResponse>>
    }
}

export default authApi