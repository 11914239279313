import { useCallback } from "react"
import { Projects } from "../../../utils/Projects"
import { usePost } from "../../../utils/fetch/useFetch"
import { GetRejectByRequestResult, getRejectByRequestResultFromJson } from "../models/GetRejectByRequestResult"
import { MLS152CustomsRequest, mls152CustomsRequestFromJson, mls152CustomsRequestToJson } from "../models/MLS152CustomsRequest"
import { PartsDetailResult, partsDetailResultFromJson } from "../models/PartsDetailResult"
import { PartsFactor, partsFactorToJson } from "../models/PartsFactor"
import { RequestFactor, requestFactorToJson } from "../models/RequestFactor"

export const useGetRejectByRequest = () => {
    const url = '/roe-api/master/api/getRejectByRequest'
    const payloadProject = useCallback((obj: { requestId: number }) => obj.requestId, [])
    const resultProject = useCallback(Projects.array(getRejectByRequestResultFromJson), [])
    return usePost<{ requestId: number }, GetRejectByRequestResult[]>(url, payloadProject, resultProject)
}

//mls159
export const useFindPartsByCondition = () => {
    const url = '/lcbm-local-master-api/api/findPartsByCondition'
    const payloadProject = useCallback(partsFactorToJson, [])
    const resultProject = useCallback(Projects.array(partsDetailResultFromJson), [])
    return usePost<PartsFactor, PartsDetailResult[]>(url, payloadProject, resultProject)
}

export const useSaveRequest = () => {
    const url = '/lcbm-local-master-api/api/saveRequest'
    const payloadProject = useCallback(mls152CustomsRequestToJson, [])
    const resultProject = useCallback(mls152CustomsRequestFromJson, [])
    return usePost<MLS152CustomsRequest, MLS152CustomsRequest>(url, payloadProject, resultProject)
}

export const useGetRequest = () => {
    const url = '/lcbm-local-master-api/api/getRequest'
    // const payloadProject = useCallback((obj: { requestNo: string }) => obj.requestNo, []);
    const payloadProject = useCallback(requestFactorToJson, [])
    const resultProject = useCallback(mls152CustomsRequestFromJson, [])
    return usePost<RequestFactor, MLS152CustomsRequest>(url, payloadProject, resultProject)
}

export const useConfirmContractRequest = () => {
    const url = '/lcbm-local-master-api/api/confirmContractRequest'
    const payloadProject = useCallback((obj: { requestId: number }) => obj, [])
    const resultProject = Projects.IGNORE
    return usePost<{ requestId: number }, undefined>(url, payloadProject, resultProject)
}