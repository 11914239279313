import { FilledInput, InputAdornment, makeStyles } from "@material-ui/core"
import { Search } from "@material-ui/icons"
import { useCallback, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { SearchConditionResult } from "../../../services/cargo/models/SearchConditionResult"
import { applicationActions } from "../../Application/applicationSlice"
import { SearchCallbackViewAction } from "./SearchCallbackViewAction"


export const SearchCard = ({ searchSuggestions, suggestions, orderNo }: {
    searchSuggestions: (filters: { keyword: string }) => void,
    suggestions: SearchConditionResult,
    orderNo: string,
}) => {
    const [keyword, setKeyword] = useState<{ keyword: string }>({ keyword: orderNo ? orderNo : "" })
    const onChange = useCallback((event: any) => {
        setKeyword({ keyword: event.target.value })
    }, [])
    const intl = useIntl()
    const message = useMemo(() => {
        const total = (suggestions.total ?? 0)
        if (!suggestions.suggestions) {
            return ''
        } else if (total === 0) {
            return intl.formatMessage({ id: 'noSearchResultInformation' }).replace('{0}', total + '')
        } else if (total < 20) {
            return intl.formatMessage({ id: 'searchResultInformation' }).replace('{0}', total + '')
        } else {
            return intl.formatMessage({ id: 'searchResultOverDueInformation' }).replace('{0}', total + '')
        }
    }, [intl, suggestions])

    const styles = useStyles()
    return <>
        <div style={{ display: "flex" }}>
            <FilledInput
                value={orderNo ? orderNo : null}
                style={{ height: 36 }}
                onChange={onChange}
                startAdornment={<InputAdornment position="start">
                    <Search />
                </InputAdornment>}
                inputProps={{
                    className: styles.input,
                }}
                className={styles.search} placeholder={intl.formatMessage({ id: 'cargoTrackingDetailInputPlaceholder' })} disabled={orderNo !== undefined && orderNo !== null} />
            <div className={styles.retrieve}>
                <SearchAction searchcondition={searchSuggestions} filters={keyword} orderNo={orderNo} />
            </div>
        </div>
        <div className={styles.searchResult}>
            {message}
        </div>
    </>
}

const SearchAction = ({ searchcondition, filters, orderNo }: {
    searchcondition: (filters: { keyword: string }) => void,
    filters: { keyword: string },
    orderNo: string
}) => {
    const dispatch = useDispatch()
    const callback = useCallback(() => {
        if (filters && filters.keyword && filters.keyword.trim() !== "") {
            searchcondition(filters)
        } else {
            dispatch(applicationActions.pushError({ title: { code: 'search' }, messages: { code: 'w0938' } }))
        }
    }, [dispatch, filters, searchcondition])
    return <SearchCallbackViewAction callback={callback} disabled={orderNo !== undefined && orderNo !== null} />
}

const useStyles = makeStyles(theme => ({
    search: {
        borderRadius: 8,
        width: 645,
        backgroundColor: "white",
        '&:hover': {
            backgroundColor: theme.palette.background.search.hover,
        },
        '&$focused': {
            backgroundColor: theme.palette.background.search.focused,
        },
        border: "1px solid rgb(208,208,208)",
    },
    input: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    retrieve: {
        marginLeft: 20
    },
    searchResult: {
        paddingTop: theme.spacing(1),
    }
}))