import { Project } from "../../../utils/Project"
import { TnvCbds } from "../../master/models/TnvCbds"
import { tableEntityFromJson } from "../../utils/TableEntity"


export type AvailableSellerResult = ResultRequired & Partial<ResultOptional>
type AvailableSellerResultson = ResultJsonRequired & Partial<ResultJsonOptional>

export const availableSellerResultFromJson: Project<AvailableSellerResultson, AvailableSellerResult> = json => ({
    ...tableEntityFromJson(json),
})

interface ResultRequired extends Omit<ResultJsonRequired, ''> {

}

interface ResultOptional extends Omit<ResultJsonOptional, ''> {
}

interface ResultJsonRequired {
    globalPartsNo: string,
    sellerList: TnvCbds[],
    placeType: number,
}

interface ResultJsonOptional {
    partsId: number,
}




