import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DateRangeItem, EntriesItem, StringItem } from "@rithe/form"
import { Records } from "@rithe/utils"
import React, { useCallback, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { TnmCustomer } from "../../../services/master/models/TnmCustomer"
import { CustomerForecastListFactor } from "../../../services/order/models/CustomerForecastListFactor"
import { CustomerForecastListResult } from "../../../services/order/models/CustomerForecastListResult"

interface OFS010PcUiProps {
    filters: CustomerForecastListFactor,
    setFilters: React.Dispatch<React.SetStateAction<CustomerForecastListFactor>>,
    search: (filters: CustomerForecastListFactor) => void,
    data: CustomerForecastListResult[],
    totalCount: number,
    customerList: TnmCustomer[]
}

export const OFS010PcUi = (props: OFS010PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({ filters, setFilters, customerList, data, search }: OFS010PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])

    const columns = useMemo(() => [
        { field: 'customerForecastNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'customerForecastNo' }), width: 250 },
        { field: 'orderVersion', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.orderVersion' }), width: 180 },
        { field: 'customerRefNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerRefNo' }), width: 250 },
        { field: 'customerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerCode' }), width: 180 },
        { field: 'orderType', dataTypeName: CodeCategory.OrderType, title: intl.formatMessage({ id: 'field.orderType' }), width: 180 },
        { field: 'orderFrequency', dataTypeName: CodeCategory.OrderFrequency, title: intl.formatMessage({ id: 'field.orderFrequency' }), width: 200 },
        { field: 'customerContractNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'customerContractNo' }), width: 240 },
        { field: 'contractRouteNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'contractRouteNo' }), width: 240 },
        { field: 'orderDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'orderDate' }), width: 150 },
        { field: 'status', dataTypeName: CodeCategory.CoStatus, title: intl.formatMessage({ id: 'field.status' }), width: 150 },
    ], [intl])
    const getRowId = useCallback((row: any) => row.coId, [])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search, customerList }), [filters, setFilters, search, customerList])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OrderType} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OrderFrequency} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.CoStatus} />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['orderDate', 'contractRouteNo', 'status', 'customerContractNo']}
            columnSettings={{
                customerForecastNo: { disableUserControl: true },
                customerCode: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
    </DataGrid>
}

const FilterItem = (props: {
    filters: CustomerForecastListFactor,
    setFilters: React.Dispatch<React.SetStateAction<CustomerForecastListFactor>>,
    search: (filters: CustomerForecastListFactor) => void,
    customerList: TnmCustomer[]
}) => {
    const { filters, setFilters, customerList, search } = props
    const customerEntries: [number, string][] = useMemo(() => customerList.map(m => [m.customerId, m.customerCode]), [customerList])

    const orderDateGetValue = useCallback((filters: CustomerForecastListFactor) => {
        return [filters.orderDateStart ?? null, filters.orderDateEnd ?? null]
    }, [])

    const orderDateMapValue = useCallback((filters: CustomerForecastListFactor, value: any) => {
        return { ...filters ?? {}, orderDateStart: value[0], orderDateEnd: value[1] }
    }, [])

    const clear = useCallback((filters: CustomerForecastListFactor) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: CustomerForecastListFactor) => {
        return [
            filters.customerForecastNo,
            filters.customerRefNo,
            filters.customerIdList,
            filters.orderTypeList,
            filters.orderFrequencyList,
            // filters.activeFlag, HAS ERROR
            filters.customerContractNo,
            filters.contractRouteNo,
            filters.coStatusList,
            filters.orderDateStart || filters.orderDateEnd,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="customerForecastNo" label={intl.formatMessage({ id: 'customerForecastNo' })} />
        <StringItem field="customerRefNo" label={intl.formatMessage({ id: 'field.customerRefNo' })} />
        <EntriesItem field="customerIdList" label={intl.formatMessage({ id: 'field.customerCode' })} entries={customerEntries} />
        <CodesItem field="orderTypeList" label={intl.formatMessage({ id: 'field.orderType' })} code={CodeCategory.OrderType} />
        <CodesItem field="orderFrequencyList" label={intl.formatMessage({ id: 'field.orderFrequency' })} code={CodeCategory.OrderFrequency} />
        <StringItem field="customerContractNo" label={intl.formatMessage({ id: 'customerContractNo' })} />
        <StringItem field="contractRouteNo" label={intl.formatMessage({ id: 'contractRouteNo' })} />
        <CodesItem field="coStatusList" label={intl.formatMessage({ id: 'field.status' })} code={CodeCategory.CoStatus} />
        <DateRangeItem field="orderDate" label={intl.formatMessage({ id: 'orderDate' })} getValue={orderDateGetValue} mapValue={orderDateMapValue} />
    </FilterToolbarItem>
}
