import {makeStyles, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@material-ui/core"
import {List} from "@material-ui/icons"
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid'
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined'
import {Message, useFieldComponent} from "@rithe/form"
import {GridContainer, GridItem} from "@rithe/ui"
import {Arrays, condition} from "@rithe/utils"
import {useCallback, useEffect, useState} from "react"
import {FormattedMessage, useIntl} from "react-intl"
import {useDispatch} from "react-redux"
import {useNavigate} from "react-router-dom"
import {CallbackCardAction} from "../../../components/Action/CallbackCardAction"
import {CallbackViewAction} from "../../../components/Action/CallbackViewAction"
import {EditCallbackViewAction} from "../../../components/Action/EditCallbackViewAction"
import {SubmitCallbackViewAction} from "../../../components/Action/SubmitCallbackViewAction"
import {BlackSimpleCard} from "../../../components/Card/BlackSimpleCard"
import {BlackSimpleCardContent} from "../../../components/Card/BlackSimpleCardContent"
import {BlackSimpleCardHeader} from "../../../components/Card/BlackSimpleCardHeader"
import {SectionCard} from "../../../components/Card/SectionCard"
import {SectionCardContent} from "../../../components/Card/SectionCardContent"
import {SectionCardHeader} from "../../../components/Card/SectionCardHeader"
import {View} from "../../../components/View/View"
import {useGetMfaState} from "../../../services/system/apis/userMfaSettingApi"
import {DayOfWeek} from "../../../services/system/enums/DayOfWeek"
import {NotificationAction} from "../../../services/system/enums/NotificationAction"
import {StockAlertFrequency} from "../../../services/system/enums/StockAlertFrequency"
import {StockAlertLevel} from "../../../services/system/enums/StockAlertLevel"
import {MSESF021Actions, useMSESF021Selector} from "./MSESF021Slice"


export const MSESF021 = () => {

    const dispatch = useDispatch()
    const getMfaState = useGetMfaState()

    useEffect(() => {
        dispatch(MSESF021Actions.getUserSetting())
    }, [dispatch])

    const [enableMfa, setEnableMfa] = useState<number>(0)

    useEffect(() => {
        getMfaState(undefined, {silent: true, serialized: true}).then(result => {
            setEnableMfa(result)
        })
    }, [getMfaState])


    const intl = useIntl()
    return <View actions={[<SaveAction />]}>
        <SectionCard allowCollapse>
            <SectionCardHeader
                icon={<List />}
                title={intl.formatMessage({ id: 'msesf021.notificationSettingsTitle' })}
                subtitle={intl.formatMessage({ id: 'msesf021.notificationSettingsSubtitle' })}
                actions={[<AddNotificationSettingAction />]}
            />
            <SectionCardContent>
                <NotificationSettings />
            </SectionCardContent>
        </SectionCard>

        <SectionCard allowCollapse>
            <SectionCardHeader
                icon={<List />}
                title={intl.formatMessage({ id: 'msesf021.stockAlertSettingsTitle' })}
                subtitle={intl.formatMessage({ id: 'msesf021.stockAlertSettingsSubtitle' })}
            />
            <SectionCardContent>
                <StockAlertSettings />
            </SectionCardContent>
        </SectionCard>

        <SectionCard allowCollapse>
            <SectionCardHeader
                icon={<List />}
                title={intl.formatMessage({ id: 'msesf021.daSettingsTitle' })}
                subtitle={intl.formatMessage({ id: 'msesf021.daSettingsSubtitle' })}
            />
            <SectionCardContent>
                <DaSettings />
            </SectionCardContent>
        </SectionCard>

        <SectionCard allowCollapse>
            <SectionCardHeader
                icon={<List />}
                title={intl.formatMessage({id: 'msesf021.mfaSetting'})}
                subtitle={intl.formatMessage({id: 'msesf021.mfaSettingMessage'})}
            />
            <SectionCardContent>
                <MFASettings enableMfa={enableMfa} />
            </SectionCardContent>
        </SectionCard>

    </View>
}

const SaveAction = () => {
    const dispatch = useDispatch()
    const disabled = useMSESF021Selector(state => state.notificationSettingItems.map(i => i.actions.length === 0 || i.days.length === 0).reduce((a, b) => a || b, false)
        || state.stockAlertSettingItems.map(i => i.frequency !== StockAlertFrequency.DAILY && i.days.length === 0).reduce((a, b) => a || b, false))
    return <SubmitCallbackViewAction disabled={disabled} callback={() => dispatch(MSESF021Actions.saveUserSetting())} />
}

const AddNotificationSettingAction = () => {
    const dispatch = useDispatch()
    return <CallbackCardAction
        outlined
        title={<FormattedMessage id="msesf021.addNotificationSettingAction" />}
        callback={() => dispatch(MSESF021Actions.addNotificationSetting())} />
}

const NotificationSettings = () => {
    const notificationSettingItemCount = useMSESF021Selector(state => state.notificationSettingItems.length)
    const styles = useStyles()
    return <div className={styles.notificationSettings}>
        {Array(notificationSettingItemCount).fill(0).map((_, index) => {
            return <NotificationSettingCard
                key={index}
                index={index}
            />
        })}
    </div>
}

const NotificationSettingCard = (props: {
    index: number,
}) => {
    const { index } = props
    const dispatch = useDispatch()
    const intl = useIntl()
    return <GridItem>
        <BlackSimpleCard allowCollapse>
            <BlackSimpleCardHeader
                clear={() => dispatch(MSESF021Actions.removeNotificationSetting(index))}
                title={intl.formatMessage({ id: 'msesf021.notificationSettingCard' }, { num: index + 1 })}
            />
            <BlackSimpleCardContent>
                <GridContainer
                    component="form"
                    columnWidth={Arrays.repeat('1fr', 3)}
                    rowHeight="max-content"
                    columnGap={24}
                    rowGap={8}
                >
                    <GridItem columnSpan={3} style={{ overflow: 'hidden' }}>
                        <NotificationActionInput index={index} />
                    </GridItem>
                    <GridItem columnSpan={3} style={{ overflow: 'hidden' }}>
                        <NotificationDaysInput index={index} />
                    </GridItem>
                </GridContainer>
            </BlackSimpleCardContent>
        </BlackSimpleCard>
    </GridItem>
}

const NotificationActionInput = (props: {
    index: number,
}) => {
    const { index } = props
    const actions = useMSESF021Selector(state => state.notificationSettingItems[index].actions)
    const messages = useMSESF021Selector<Message[] | undefined>(state => state.notificationSettingItems[index].actions.length === 0 ? [{
        field: 'actions',
        level: 'error',
        value: <FormattedMessage id="w0001" values={{ '0': 'field.funList' }} />,
    }] : undefined)
    const dispatch = useDispatch()
    const intl = useIntl()
    const { Control, Label, Helper, Input, InputWrapper } = useFieldComponent('EntriesInput', {})
    return <Control
        inputWrapper={<InputWrapper error={!!messages} />}
        label={<Label required><FormattedMessage id="msesf021.notificationAction" /></Label>}
        labelDisplay="block"
        helper={<Helper messages={messages} />}
        helperDisplay="tooltip"
        input={<Input
            field="actions"
            value={[
                NotificationAction.CONTRACT_REQUEST,
                NotificationAction.PLACE_ORDER,
                NotificationAction.INCOMING_INBOUND,
                NotificationAction.SHIPMENT_DELAY,
                NotificationAction.CUSTOMER_USAGE,
                NotificationAction.INVENTORY_ALERT,
                NotificationAction.CHANGE_CANCEL_REQUEST_APPROVAL,
                NotificationAction.SUPPLIER_TO_CONFIRM_ORDER,
                NotificationAction.PERSONAL_TODO_LIST,
                NotificationAction.NEW_ORDER_REQUEST,
                NotificationAction.ORDER_CHANGE_CANCEL_REQUEST,
                NotificationAction.ORDER_FORCE_COMPLETE,
                NotificationAction.INBOUND_DELAY,
                NotificationAction.OUTBOUND_DELAY,
                NotificationAction.CONTAINER_REFRESH_STATUS,
            ].filter(action => actions.includes(action))}
            setValue={value => dispatch(MSESF021Actions.updateNotificationSettingActions({ index, actions: value as any }))}
            entries={[
                [NotificationAction.CONTRACT_REQUEST, intl.formatMessage({ id: 'msesf021.contractRequest' })],
                [NotificationAction.PLACE_ORDER, intl.formatMessage({ id: 'msesf021.placeOrder' })],
                [NotificationAction.INCOMING_INBOUND, intl.formatMessage({ id: 'msesf021.incomingInboundNotification' })],
                [NotificationAction.SHIPMENT_DELAY, intl.formatMessage({ id: 'msesf021.shipmentDelay' })],
                [NotificationAction.CUSTOMER_USAGE, intl.formatMessage({ id: 'msesf021.customerUsage' })],
                [NotificationAction.INVENTORY_ALERT, intl.formatMessage({ id: 'msesf021.inventoryAlert' })],
                [NotificationAction.CHANGE_CANCEL_REQUEST_APPROVAL, intl.formatMessage({ id: 'msesf021.changeCancelRequestApproval' })],
                [NotificationAction.SUPPLIER_TO_CONFIRM_ORDER, intl.formatMessage({ id: 'msesf021.supplierToConfirmOrder' })],
                [NotificationAction.PERSONAL_TODO_LIST, intl.formatMessage({ id: 'msesf021.personalTodoList' })],
                [NotificationAction.NEW_ORDER_REQUEST, intl.formatMessage({ id: 'msesf021.newOrderRequest' })],
                [NotificationAction.ORDER_CHANGE_CANCEL_REQUEST, intl.formatMessage({ id: 'msesf021.orderChangeCancelRequest' })],
                [NotificationAction.ORDER_FORCE_COMPLETE, intl.formatMessage({ id: 'msesf021.orderForceComplete' })],
                [NotificationAction.INBOUND_DELAY, intl.formatMessage({ id: 'msesf021.inboundDelay' })],
                [NotificationAction.OUTBOUND_DELAY, intl.formatMessage({ id: 'msesf021.outboundDelay' })],
                [NotificationAction.CONTAINER_REFRESH_STATUS, intl.formatMessage({ id: 'msesf021.containerRefreshStatus' })],
            ]}
        />}
    />
}

const NotificationDaysInput = (props: {
    index: number,
}) => {
    const { index } = props
    const days = useMSESF021Selector(state => state.notificationSettingItems[index].days)
    const messages = useMSESF021Selector<Message[] | undefined>(state => state.notificationSettingItems[index].days.length === 0 ? [{
        field: 'days',
        level: 'error',
        value: <FormattedMessage id="w0001" values={{ '0': 'field.mailReminderTiming' }} />,
    }] : undefined)
    const dispatch = useDispatch()
    const intl = useIntl()
    const { Control, Label, Helper, Input, InputWrapper } = useFieldComponent('EntriesInput', {})
    return <Control
        inputWrapper={<InputWrapper error={!!messages} />}
        label={<Label required><FormattedMessage id="msesf021.notificationDays" /></Label>}
        labelDisplay="block"
        helper={<Helper messages={messages} />}
        helperDisplay="tooltip"
        input={<Input
            field="days"
            value={days}
            setValue={value => dispatch(MSESF021Actions.updateNotificationSettingDays({ index, days: value as any }))}
            entries={[
                [DayOfWeek.MONDAY, intl.formatMessage({ id: 'msesf021.monday' })],
                [DayOfWeek.TUESDAY, intl.formatMessage({ id: 'msesf021.tuesday' })],
                [DayOfWeek.WEDNESDAY, intl.formatMessage({ id: 'msesf021.wednesday' })],
                [DayOfWeek.THURSDAY, intl.formatMessage({ id: 'msesf021.thursday' })],
                [DayOfWeek.FRIDAY, intl.formatMessage({ id: 'msesf021.friday' })],
                [DayOfWeek.SATURDAY, intl.formatMessage({ id: 'msesf021.saturday' })],
                [DayOfWeek.SUNDAY, intl.formatMessage({ id: 'msesf021.sunday' })],
            ]}
        />}
    />
}

const StockAlertSettings = () => {
    return <TableContainer component={Paper} style={{ width: 1050 }}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell align="center" style={{ width: 150 }}>
                        <FormattedMessage id="msesf021.stockAlertLevel" />
                    </TableCell>
                    <TableCell align="center" style={{ width: 300 }}>
                        <FormattedMessage id="msesf021.stockAlertFrequency" />
                    </TableCell>
                    <TableCell align="center" style={{ width: 600 }}>
                        <FormattedMessage id="msesf021.stockAlertDays" />
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <StockAlertRow level={StockAlertLevel.ALERT_1} />
                <StockAlertRow level={StockAlertLevel.ALERT_2} />
                <StockAlertRow level={StockAlertLevel.ALERT_3} />
                <StockAlertRow level={StockAlertLevel.ALERT_MAX} />
            </TableBody>
        </Table>
    </TableContainer>
}

const StockAlertRow = (props: {
    level: StockAlertLevel,
}) => {
    const { level } = props
    return <TableRow>
        <TableCell align="center">
            {condition(level)
                .case(StockAlertLevel.ALERT_1).then(<FormattedMessage id="msesf021.stockAlertLevel1" />)
                .case(StockAlertLevel.ALERT_2).then(<FormattedMessage id="msesf021.stockAlertLevel2" />)
                .case(StockAlertLevel.ALERT_3).then(<FormattedMessage id="msesf021.stockAlertLevel3" />)
                .case(StockAlertLevel.ALERT_MAX).then(<FormattedMessage id="msesf021.stockAlertLevelMax" />)
                .default("")
            }
        </TableCell>
        <TableCell align="center">
            <StockAlertFrequencyInput level={level} />
        </TableCell>
        <TableCell align="center">
            <StockAlertDaysInput level={level} />
        </TableCell>
    </TableRow>
}

const StockAlertFrequencyInput = (props: {
    level: StockAlertLevel,
}) => {
    const { level } = props
    const settingItem = useMSESF021Selector(state => state.stockAlertSettingItems.find(x => x.level === level))
    const dispatch = useDispatch()
    const intl = useIntl()
    const { Control, Label, Helper, Input, InputWrapper } = useFieldComponent('EntryInput', {})
    return <Control
        inputWrapper={<InputWrapper />}
        label={<Label required><FormattedMessage id="msesf021.notificationAction" /></Label>}
        labelDisplay="none"
        helper={<Helper />}
        helperDisplay="tooltip"
        input={<Input
            field="actions"
            value={settingItem?.frequency || null}
            setValue={value => dispatch(MSESF021Actions.updateStockAlertSettingFrequency({ level, frequency: value as StockAlertFrequency }))}
            entries={[
                [StockAlertFrequency.DAILY, intl.formatMessage({ id: 'msesf021.daily' })],
                [StockAlertFrequency.WEEKLY, intl.formatMessage({ id: 'msesf021.weekly' })],
                [StockAlertFrequency.BI_WEEKLY, intl.formatMessage({ id: 'msesf021.biWeekly' })],
            ]}
        />}
    />
}

const StockAlertDaysInput = (props: {
    level: StockAlertLevel,
}) => {
    const { level } = props
    const settingItem = useMSESF021Selector(state => state.stockAlertSettingItems.find(x => x.level === level))
    const message = useMSESF021Selector<Message | undefined>(state => {
        const settingItem = state.stockAlertSettingItems.find(x => x.level === level)
        return settingItem && settingItem.frequency !== StockAlertFrequency.DAILY && settingItem.days.length === 0
            ? {
                field: 'days',
                level: 'error',
                value: <FormattedMessage id="w0001" values={{ '0': 'field.sendDay' }} />,
            }
            : undefined
    })
    const dispatch = useDispatch()
    const intl = useIntl()
    const { Control, Label, Helper, Input, InputWrapper } = useFieldComponent('EntriesInput', {})
    return [StockAlertFrequency.WEEKLY, StockAlertFrequency.BI_WEEKLY].includes(settingItem?.frequency as any) ? <Control
        inputWrapper={<InputWrapper error={message !== undefined} />}
        label={<Label required><FormattedMessage id="msesf021.notificationDays" /></Label>}
        labelDisplay="none"
        helper={<Helper messages={message ? [message] : undefined} />}
        helperDisplay="tooltip"
        input={<Input
            field="days"
            value={settingItem?.days || []}
            setValue={value => dispatch(MSESF021Actions.updateStockAlertSettingDays({ level, days: value as any }))}
            entries={[
                [DayOfWeek.MONDAY, intl.formatMessage({ id: 'msesf021.monday' })],
                [DayOfWeek.TUESDAY, intl.formatMessage({ id: 'msesf021.tuesday' })],
                [DayOfWeek.WEDNESDAY, intl.formatMessage({ id: 'msesf021.wednesday' })],
                [DayOfWeek.THURSDAY, intl.formatMessage({ id: 'msesf021.thursday' })],
                [DayOfWeek.FRIDAY, intl.formatMessage({ id: 'msesf021.friday' })],
                [DayOfWeek.SATURDAY, intl.formatMessage({ id: 'msesf021.saturday' })],
                [DayOfWeek.SUNDAY, intl.formatMessage({ id: 'msesf021.sunday' })],
            ]}
        />}
    /> : null
}

const DaSettings = () => {
    return <TableContainer component={Paper} style={{ width: 960 }}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell align="left" style={{ width: 10 }}></TableCell>
                    <TableCell align="center" style={{ width: 150 }}>
                        <FormattedMessage id="msesf021.daSettingKey" />
                    </TableCell>
                    <TableCell align="center" style={{ width: 300 }}>
                        <FormattedMessage id="msesf021.daSettingDescription" />
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <DaSettingRow settingItem="ruleBasedRecommendation" />
                <DaSettingRow settingItem="aiPageRecommendation" />
            </TableBody>
        </Table>
    </TableContainer>
}

const DaSettingRow = (props: {
    settingItem: string,
}) => {
    const { settingItem } = props
    const daSettingItem = useMSESF021Selector(state => state.daSettingItems.find(x => x.item === settingItem))
    const dispatch = useDispatch()
    return <TableRow>
        <TableCell align="center">
            {condition(settingItem)
                .case("ruleBasedRecommendation").then(<Switch
                    checked={daSettingItem?.value ?? false}
                    onChange={() => dispatch(MSESF021Actions.toggleRuleBasedRecommendation())}
                />)
                .case("aiPageRecommendation").then(<Switch
                    checked={daSettingItem?.value ?? false}
                    onChange={() => dispatch(MSESF021Actions.toggleAiPageRecommendation())}
                />)
                .default("")
            }
        </TableCell>
        <TableCell align="center">
            {condition(settingItem)
                .case("ruleBasedRecommendation").then(<FormattedMessage id="msesf021.ruleBasedRecommendation" />)
                .case("aiPageRecommendation").then(<FormattedMessage id="msesf021.aiPageRecommendation" />)
                .default("")
            }
        </TableCell>
        <TableCell align="center">
            {condition(settingItem)
                .case("ruleBasedRecommendation").then(<FormattedMessage id="msesf021.ruleBasedRecommendationDescription" />)
                .case("aiPageRecommendation").then(<FormattedMessage id="msesf021.aiPageRecommendationDescription" />)
                .default("")
            }
        </TableCell>
    </TableRow>
}

const MFASettings = (props: {
    enableMfa: number
}) => {
    const {enableMfa} = props
    return <>
        <EnableMfaVTable enableMfa={enableMfa} />
        <div style={{marginBottom: '20px'}}></div>
        <RecoverTable />
    </>
}

const EnableMfaVTable = (props: {
    enableMfa: number
}) => {
    const {enableMfa} = props
    return <TableContainer component={Paper} style={{width: '100%'}}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell align="left" style={{width: '100%'}}>
                        <Typography variant="subtitle1" color="textPrimary">
                            <FormattedMessage id="msesf021.mfaMethods" />
                        </Typography>
                    </TableCell>
                    <TableCell align="left" style={{width: 50}}>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell align="left" style={{width: '100%'}}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <PhoneAndroidIcon />
                            <div style={{fontSize: 'larger', fontWeight: 'bold', marginLeft: 10}}>
                                <FormattedMessage id="msesf021.authenticatorApp" />
                            </div>
                            {enableMfa === 1 &&
                                <div style={{color: 'green', border: '1px solid green', borderRadius: 5, padding: 2, fontWeight: 'bold', marginLeft: 10}}>
                                    <FormattedMessage id="msesf021.configured" />
                                </div>
                            }
                            {(!enableMfa || enableMfa === 0)&&
                                <div style={{color: 'red', border: '1px solid red', borderRadius: 5, padding: 2, fontWeight: 'bold', marginLeft: 10}}>
                                    <FormattedMessage id="msesf021.noConfigured" />
                                </div>
                            }
                        </div>
                        <div style={{marginTop: 10}}>
                            <FormattedMessage id="msesf021.authenticatorDescription" />
                        </div>
                    </TableCell>
                    <TableCell align="left" style={{width: 50}}>
                        <BindAppAction />
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </TableContainer>
}

const RecoverTable = () => {
    return <TableContainer component={Paper} style={{width: '100%'}}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell align="left" style={{width: '100%'}}>
                        <Typography variant="subtitle1" color="textPrimary">
                            <FormattedMessage id="msesf021.recoveryOptions" />
                        </Typography>
                    </TableCell>
                    <TableCell align="left" style={{width: 50}}>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell align="left" style={{width: '100%'}}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <VpnKeyOutlinedIcon />
                            <div style={{fontSize: 'larger', fontWeight: 'bold', marginLeft: 10}}>
                                <FormattedMessage id="msesf021.recoveryCodes" />
                            </div>
                        </div>
                        <div style={{marginTop: 10}}>
                            <FormattedMessage id="msesf021.recoveryDescription" />
                        </div>
                    </TableCell>
                    <TableCell align="left" style={{width: 50}}>
                        <ViewCodeAction />
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </TableContainer>
}

const BindAppAction = () => {
    const navigate = useNavigate()
    const callback = useCallback(() => {
        navigate(`/user-setting/authenticatorApp`)
    }, [navigate])
    return <EditCallbackViewAction callback={callback} />
}

const ViewCodeAction = () => {
    const navigate = useNavigate()
    const callback = useCallback(() => {
        navigate(`/user-setting/recoveryCodes`)
    }, [navigate])
    return <CallbackViewAction callback={callback} title={<FormattedMessage id="view" />} />
}

const useStyles = makeStyles(theme => ({
    notificationSettings: {
        width: '100%',
        padding: theme.spacing(2, 0),
    }
}))
