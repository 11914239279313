import { useCallback, useEffect, useMemo, useState } from "react"
import { useFindBUChangedableOrderList } from "../../../services/order/apis/ChangeRequestApi"
import { BChangeRequestListResult } from "../../../services/order/models/BChangeRequestListResult"
import { INT050PcUi } from "./INT050PcUi"

export const INT050 = () => {

    const filters = useMemo(() => ({ page: { currentPage: 0, pageSize: 1000 } }), [])
    const [orderChangedableList, setOrderChangedableList] = useState<BChangeRequestListResult[]>([])

    const findBUChangedableOrderList = useFindBUChangedableOrderList()
    const search = useCallback(() => {
        findBUChangedableOrderList(undefined, { silent: true, serialized: true }).then(result => {
            setOrderChangedableList(result || [])
        })
    }, [findBUChangedableOrderList])

    useEffect(() => {
        search()
    }, [filters, search])

    return <INT050PcUi
        orderChangedableList={orderChangedableList}
        search={search}
        filters={filters}
    />
}
