import { Project } from "@rithe/utils";
import { FtaFlag } from "../enums/FtaFlag";
import { ShippingMode } from "../enums/ShippingMode";
import { UnPackingFlag } from "../enums/UnPackingFlag";
import { TatCustomsInvoiceBreakdown, tatCustomsInvoiceBreakdownFromJson, TatCustomsInvoiceBreakdownJson } from "./TatCustomsInvoiceBreakdown";

export interface CustomsInvoice {
    invoiceId: number,
    invoiceNo: string,
    invoiceDate?: Date,
    seller?: Participant,
    buyer?: Participant,
    shipper?: Participant,
    consignee?: Participant,
    accountee?: Participant,
    deliveryTo?: Participant,
    vesselName?: string,
    voyageNo?: string,
    blNo?: string,
    blDate?: Date,
    atd?: Date,
    etd?: Date,
    eta?: Date,
    loadingPortId?: number,
    loadingPort?: string,
    dischargePort?: string,
    dischargePortId?: number,
    shippingMode?: ShippingMode,
    paymentTerms?: PaymentTerms,
    countryOfOrigin: string,
    incoterms?: Incoterms,
    invoiceCurrency?: Currency,
    invoiceAmount?: number,
    remark?: string,
    containers: Container[],
    details: Detail[],
    breakdown: TatCustomsInvoiceBreakdown[],
}

interface Participant {
    cbdsUid: string,
    cbdsCode?: string,
    cbdsName?: string,
    regionCode?: string,
    regionName?: string,
    contact?: {
        address1?: string,
        address2?: string,
        address3?: string,
        address4?: string,
        tel?: string,
        fax?: string,
        postalCode?: string,
    }
}

interface PaymentTerms {
    paymentTermsId: number,
    code?: string,
    description?: string,
    dueDate?: Date,
}

interface Incoterms {
    incotermsId: number,
    code?: string,
    description?: string,
    place?: string,
}

export interface Currency {
    currencyCode: string,
    decimalDigits?: number,
}

interface Container {
    invoiceContainerId: number,
    conatinerNo?: string,
    containerType?: string,
    sealNo?: string,
    m3?: number,
    grossWeight?: number,
    netWeight?: number,
    packages: Package[],
}

interface Package {
    invoicePackageId: number,
    packageNo?: string,
    packageType?: string,
    length?: number,
    width?: number,
    height?: number,
    m3?: number,
    grossWeight?: number,
    netWeight?: number,
    fillingRateWeight?: number,
    fillingRateM3?: number,
    unpackingFlag?: UnPackingFlag,
}

export interface Detail {
    invoicePriceId: number,
    soNo?: string,
    poNo?: string,
    part?: Part,
    soPart?: UnitPart,
    poPart?: UnitPart,
    price?: number,
    buyerSpq?: number,
    sellerSpq?: number,
    qty?: number,
    ftaFlag?: FtaFlag,
}

interface Part {
    partsId: number,
    partsNo?: string,
    colorCode?: string,
    uom?: Uom,
    netWeight?: number,
}

interface Uom {
    uomCode: string,
    uomName?: string,
    decimalDigits?: number,
}

interface UnitPart {
    unitPartsId: number,
    unitPartsNo?: string,
    unitPartsName?: string,
}

interface CustomsInvoiceJson {
    invoiceId: number,
    invoiceNo: string,
    invoiceDate?: string,
    seller?: ParticipantJson,
    buyer?: ParticipantJson,
    shipper?: ParticipantJson,
    consignee?: ParticipantJson,
    accountee?: ParticipantJson,
    deliveryTo?: ParticipantJson,
    vesselName?: string,
    voyageNo?: string,
    blNo?: string,
    blDate?: string,
    atd?: string,
    etd?: string,
    eta?: string,
    loadingPortId?: number,
    loadingPort?: string,
    dischargePort?: string,
    dischargePortId?: number,
    shippingMode?: ShippingMode,
    paymentTerms?: PaymentTermsJson,
    countryOfOrigin: string,
    incoterms?: IncotermsJson,
    invoiceCurrency?: CurrencyJson,
    invoiceAmount?: number,
    remark?: string,
    containers: ContainerJson[],
    details: DetailJson[],
    breakdown: TatCustomsInvoiceBreakdownJson[],
}

interface ParticipantJson {
    cbdsUid: string,
    cbdsCode?: string,
    cbdsName?: string,
    regionCode?: string,
    regionName?: string,
    contact?: {
        address1?: string,
        address2?: string,
        address3?: string,
        address4?: string,
        tel?: string,
        fax?: string,
        postalCode?: string,
    }
}

interface PaymentTermsJson {
    paymentTermsId: number,
    code?: string,
    description?: string,
    dueDate?: string,
}

interface IncotermsJson {
    incotermsId: number,
    code?: string,
    description?: string,
    place?: string,
}

interface CurrencyJson {
    currencyCode: string,
    decimalDigits?: number,
}

interface ContainerJson {
    invoiceContainerId: number,
    conatinerNo?: string,
    containerType?: string,
    sealNo?: string,
    m3?: number,
    grossWeight?: number,
    netWeight?: number,
    packages: PackageJson[],
}

interface PackageJson {
    invoicePackageId: number,
    packageNo?: string,
    packageType?: string,
    length?: number,
    width?: number,
    height?: number,
    m3?: number,
    grossWeight?: number,
    netWeight?: number,
    fillingRateWeight?: number,
    fillingRateM3?: number,
    unpackingFlag?: UnPackingFlag,
}

export interface DetailJson {
    invoicePriceId: number,
    soNo?: string,
    poNo?: string,
    part?: PartJson,
    soPart?: UnitPartJson,
    poPart?: UnitPartJson,
    price?: number,
    buyerSpq?: number,
    sellerSpq?: number,
    qty?: number,
    ftaFlag?: number,
}

interface PartJson {
    partsId: number,
    partsNo?: string,
    colorCode?: string,
    uom?: UomJson,
    netWeight?: number,
}

interface UomJson {
    uomCode: string,
    uomName?: string,
    decimalDigits?: number,
}

interface UnitPartJson {
    unitPartsId: number,
    unitPartsNo?: string,
    unitPartsName?: string,
}

export const customsInvoiceDeserializer: Project<CustomsInvoiceJson, CustomsInvoice> = json => ({
    ...json,
    invoiceDate: json.invoiceDate ? new Date(json.invoiceDate) : undefined,
    blDate: json.blDate ? new Date(json.blDate) : undefined,
    atd: json.atd ? new Date(json.atd) : undefined,
    etd: json.etd ? new Date(json.etd) : undefined,
    eta: json.eta ? new Date(json.eta) : undefined,
    paymentTerms: json.paymentTerms ? { ...json.paymentTerms, dueDate: json.paymentTerms.dueDate ? new Date(json.paymentTerms.dueDate) : undefined } : undefined,
    breakdown: json.breakdown && json.breakdown.map(tatCustomsInvoiceBreakdownFromJson),
})