import { Button, Checkbox, DialogActions, DialogContent, DialogContentText, Divider, Fade, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Menu, MenuItem, Tooltip, useTheme } from "@material-ui/core"
import { DoneAll, FileCopyOutlined, Print } from "@material-ui/icons"
import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { DateRangeItem, EntriesItem, EntryItem, Form, StringItem } from "@rithe/form"
import { Records } from "@rithe/utils"
import React, { SetStateAction, useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { NeumorphismButton } from "../../../components/Button/NeumorphismButton"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CallbackRowAction } from "../../../components/DataGrid/rowActions/CallbackRowAction"
import { EditCallbackRowAction } from "../../../components/DataGrid/rowActions/EditCallbackRowAction"
import { ViewRedirectRowAction } from "../../../components/DataGrid/rowActions/ViewRedirectRowAction"
import { CallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/CallbackToolbarAction"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { DarkDialog } from "../../../components/Dialog/DarkDialog"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { useFunctionStore } from "../../../Root"
import { useDownloadFTAFlagByPart } from "../../../services/accounting/apis/accountingDownloadApi"
import { useCompletedImpCustoms, useGetImpCargoStatus, useUpdateImpCargoStatus } from "../../../services/accounting/apis/accountingSellerApi"
import { BuyerInvoiceTransfer } from "../../../services/accounting/models/BuyerInvoiceTransfer"
import { CustomsInvoiceTransfer } from "../../../services/accounting/models/CustomsInvoiceTransfer"
import { CustomsInvoiceView } from "../../../services/accounting/models/CustomsInvoiceView"
import { useDownloadCustomsInvoicePDF } from "../../../services/delivery/apis/deliveryOutboundApi"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { CustomsInvoiceStatus } from "../../../services/master/enums/CustomsInvoiceStatus"
import { TnmPort } from "../../../services/master/models/TnmPort"
import { applicationActions } from "../../Application/applicationSlice"

interface AIS040PcUiProps {
    filters: CustomsInvoiceView,
    search: (filters: CustomsInvoiceView) => void,
    data: CustomsInvoiceTransfer[],
    selections: string[],
    setSelections: React.Dispatch<SetStateAction<string[]>>,
    setFilters: React.Dispatch<React.SetStateAction<CustomsInvoiceView>>,
    portList: TnmPort[]
}

export const AIS040PcUi = (props: AIS040PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({
    filters,
    search,
    data,
    selections,
    setSelections,
    setFilters,
    portList,
}: AIS040PcUiProps) => {
    const intl = useIntl()

    const columns = useMemo(() => [
        { field: 'invoiceNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.invoiceNo' }), width: 250 },
        { field: 'outboundNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.outboundNo' }), width: 300 },
        // { field: 'bookingNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.bookingNo' }), width: 300 },
        { field: 'containerNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.containerNo' }), width: 300 },
        { field: 'shippingMode', dataTypeName: CodeCategory.ShippingMode, title: intl.formatMessage({ id: 'field.shippingMode' }), width: 200 },
        { field: 'loadingPort', dataTypeName: 'string', title: intl.formatMessage({ id: 'loadingPort' }), width: 200 },
        { field: 'dischargePort', dataTypeName: 'string', title: intl.formatMessage({ id: 'dischargePort' }), width: 200 },
        { field: 'issuedDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'issuedDate' }), width: 200 },
        { field: 'impCargoStatus', dataTypeName: 'string', title: intl.formatMessage({ id: 'cargoStatus' }), width: 260 },
        { field: 'impCustomsStatus', dataTypeName: CodeCategory.CustomsInvoiceStatus, title: intl.formatMessage({ id: 'field.status' }), width: 260 },
    ], [intl])

    const itemProps1 = useMemo(() => ({ filters, setFilters, search, portList }), [filters, setFilters, search, portList])
    const actionProps1 = useMemo(() => ({ invoiceNos: selections }), [selections])
    const actionProps2 = useMemo(() => ({ invoiceNos: selections, search, filters }), [filters, search, selections])
    const actionProps3 = useMemo(() => ({ data }), [data])
    const completeRowProps = useMemo(() => ({ search, filters }), [filters, search])
    const completeDisplay = useCallback((tableRow: TableRow) => {
        return tableRow.row?.impCustomsStatus === CustomsInvoiceStatus.PendingCustomsClearance
    }, [])
    const getRowId = useCallback((row: any) => row.invoiceNo, [])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.ShippingMode} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.CustomsInvoiceStatus} />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        {/* <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps1} /> */}
        <ToolbarActionProvider Action={PrintAction} actionProps={actionProps1} />
        <ToolbarActionProvider Action={CompletedAction} actionProps={actionProps2} />
        <RowActionProvider name="view" Action={ViewRowAction} actionProps={actionProps3} />
        <RowActionProvider name="editCargoStatus" Action={EditCargoStatusRowAction} actionProps={completeRowProps} display={completeDisplay} />
        <RowActionProvider name="complete" Action={CompletedRowAction} actionProps={completeRowProps} display={completeDisplay} />
        <ColumnFreeze />
        <ColumnVisibility defaultHiddenFields={['outboundNo', 'issuedDate']}
            columnSettings={{
                invoiceNo: { disableUserControl: true }
            }}
            ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemProps1} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={132} />
    </DataGrid>
}

const FilterItem = (props: { filters: CustomsInvoiceView, setFilters: React.Dispatch<React.SetStateAction<CustomsInvoiceView>>, search: (filters: CustomsInvoiceView) => void, portList: TnmPort[] }) => {
    const { filters, setFilters, search, portList } = props
    const portMap: [number, string][] = useMemo(() => portList.map(m => [m.portId, m.portCode]), [portList])

    const createdDateGetValue = useCallback((filters: CustomsInvoiceView) => {
        return [filters.issuedDateStart ?? null, filters.issuedDateEnd ?? null]
    }, [])

    const createdDateMapValue = useCallback((filters: CustomsInvoiceView, value: any) => {
        return { ...filters ?? {}, issuedDateStart: value[0], issuedDateEnd: value[1] }
    }, [])

    const clear = useCallback((filters: CustomsInvoiceView) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: CustomsInvoiceView) => {
        return [
            filters.outboundNo,
            filters.containerNo,
            filters.shippingModeList,
            filters.loadingPortList,
            filters.dischargePortList,
            // filters.activeFlag, HAS ERROR
            filters.statusList,
            filters.issuedDateStart || filters.issuedDateEnd,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="outboundNo" label={intl.formatMessage({ id: 'field.outboundNo' })} />
        <StringItem field="containerNo" label={intl.formatMessage({ id: 'field.containerNo' })} />
        <CodesItem field="shippingModeList" label={intl.formatMessage({ id: 'field.shippingMode' })} code={CodeCategory.ShippingMode} />
        <EntriesItem field="dischargePortList" label={intl.formatMessage({ id: 'dischargePort' })} entries={portMap} />
        <EntriesItem field="loadingPortList" label={intl.formatMessage({ id: 'loadingPort' })} entries={portMap} />
        <CodesItem field="statusList" label={intl.formatMessage({ id: 'field.status' })} code={CodeCategory.CustomsInvoiceStatus} />
        <DateRangeItem field="issuedDate" label={intl.formatMessage({ id: 'issuedDate' })} getValue={createdDateGetValue} mapValue={createdDateMapValue} />
    </FilterToolbarItem>

}

const PrintAction = ({ invoiceNos }: { invoiceNos: string[] }) => {
    const intl = useIntl()
    const theme = useTheme()
    const dispatch = useDispatch()
    const [open, setOpen] = useState<boolean>(false)
    const [checked, setChecked] = useState<number[]>([])
    const handleClose = () => { setOpen(false) }
    const handleCheck = (value: number) => () => {
        const currentIndex = checked.indexOf(value)
        const newChecked = [...checked]
        if (currentIndex === -1) {
            newChecked.push(value)
        } else {
            newChecked.splice(currentIndex, 1)
        }

        setChecked(newChecked)
    }

    const typeList = useMemo(() => [
        { typeName: intl.formatMessage({ id: 'printInvoice' }), typeCode: 1 },
        { typeName: intl.formatMessage({ id: 'printPackingList' }), typeCode: 2 }
    ], [intl])

    const print = useDownloadCustomsInvoicePDF()

    const printChangeFrom = useCallback(() => {
        if (checked.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'print' }, messages: { code: 'w0036' } }))
            return
        }
        if (invoiceNos.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'print' }, messages: { code: 'w0002' } }))
            return
        }
        const conditions = {
            invoiceNos: invoiceNos,
            printInvoiceFlag: checked.find(m => m === 1) !== undefined ? 1 : 0,
            printPackingListFlag: checked.find(m => m === 2) !== undefined ? 1 : 0
        }

        print(conditions)
    }, [checked, dispatch, invoiceNos, print])

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
        setOpen(true)
    }

    return <>
        {/* <Access access="ACCT.AIS030.PRINT"> */}
        <Tooltip title={intl.formatMessage({ id: "print" })}>
            <NeumorphismButton onClick={handleClickListItem} style={{ width: 36, marginLeft: theme.spacing(1) }}>{<Print></Print>}</NeumorphismButton>
        </Tooltip>
        <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <List>
                {typeList.map((value, index) => {
                    const labelId = `checkbox-list-label-${index}`
                    return (
                        <>
                            <ListItem key={index} dense button onClick={handleCheck(value.typeCode)}>
                                <ListItemAvatar>
                                    <FileCopyOutlined></FileCopyOutlined>
                                </ListItemAvatar>
                                <ListItemText id={labelId} primary={value.typeName} />
                                <ListItemSecondaryAction>
                                    <Checkbox
                                        edge="end"
                                        onChange={handleCheck(value.typeCode)}
                                        checked={checked.indexOf(value.typeCode) !== -1}
                                        inputProps={{ 'aria-labelledby': labelId }} />
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider component="li" />
                        </>
                    )
                })}
            </List>
            <MenuItem>
                <Button onClick={printChangeFrom} variant="contained" color="primary"><FormattedMessage id="print" /></Button>
            </MenuItem>
        </Menu>
        {/* </Access> */}
    </>
}

const ViewRowAction = (props: DataGridRowActionProps & { data: BuyerInvoiceTransfer[] }) => {
    const { tableRow, data } = props
    const path = useMemo(() => `/impcustomsinvoice/view-${data?.find(d => tableRow.rowId === d.invoiceNo)?.invoiceId}`, [data, tableRow.rowId])
    return <ViewRedirectRowAction tableRow={tableRow} access="LOGI.AIS040.VIEWDETAIL" path={path} />
}

const CompletedRowAction = ({ tableRow, search, filters }: DataGridRowActionProps & { search: (filter: CustomsInvoiceView) => void, filters: CustomsInvoiceView }) => {
    const doCompleted = useCompletedImpCustoms()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'complete' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const callback = useCallback(() => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            doCompleted({ invoiceNoList: [tableRow?.row?.invoiceNo ?? ''] }, { serialized: true }).then(() => search(filters)).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [functionStore, dispatch, title, doCompleted, tableRow?.row?.invoiceNo, search, filters])
    return <CallbackRowAction tableRow={tableRow} title={<FormattedMessage id="completed" />} icon={<DoneAll />} callback={callback} disabled={disabled} />
}

const EditCargoStatusRowAction = ({ tableRow, search, filters }: DataGridRowActionProps & { search: (filter: CustomsInvoiceView) => void, filters: CustomsInvoiceView }) => {
    const intl = useIntl()
    const [cargoStatus, setCargoStatus] = useState<string[]>([])
    const invoiceNo = useMemo(() => tableRow?.row?.invoiceNo, [tableRow?.row?.invoiceNo])
    const orgCargoStatus = useMemo(() => tableRow?.row?.impCargoStatus, [tableRow?.row?.impCargoStatus])
    const [data, setData] = useState<{ invoiceNo: string, cargoStatus?: string }>({ invoiceNo: invoiceNo, cargoStatus: orgCargoStatus })
    const [open, setOpen] = useState<boolean>(false)
    const entries: [string, string][] = useMemo(() => cargoStatus.map(v => [v, v]), [cargoStatus])

    const getCargoStatus = useGetImpCargoStatus()
    const updateCargoStatus = useUpdateImpCargoStatus()

    const doUpdateInfo = useCallback(() => {
        updateCargoStatus(data, { serialized: true }).then(() => {
            search(filters)
            setOpen(false)
        })
    }, [data, filters, search, updateCargoStatus])

    useEffect(() => {
        open && getCargoStatus({ invoiceNo: invoiceNo }, { serialized: true, silent: true }).then(result => setCargoStatus(result.cargoStatus ?? []))
    }, [getCargoStatus, invoiceNo, open])

    return <>
        <EditCallbackRowAction tableRow={tableRow} title={<FormattedMessage id="inputCargoStatus" />} callback={() => setOpen(true)} />
        <DarkDialog onClose={() => { setOpen(false) }} maxWidth="md" fullWidth aria-labelledby="simple-dialog-title" open={open}>
            <DialogHeader onClose={() => setOpen(false)}><FormattedMessage id="inputUpdateCargoStatus" /></DialogHeader>
            <DialogContent>
                <DialogContentText>
                    <Form data={data} setData={setData} labelDisplay="block" helperDisplay="tooltip" columnCount={1} >
                        <EntryItem field="cargoStatus" labelWidth={120} label={intl.formatMessage({ id: 'cargoStatus' })} entries={entries} />
                    </Form>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={() => setOpen(false)} />
                <DialogAction title={<FormattedMessage id="update" />} callback={() => doUpdateInfo()} />
            </DialogActions>
        </DarkDialog>
    </>
}

const DownloadAction = ({ invoiceNos }: { invoiceNos: string[] }) => {
    const dispatch = useDispatch()
    const downloadFTAFlagByPart = useDownloadFTAFlagByPart()
    const downloadCallback = useCallback(() => {
        if (invoiceNos && invoiceNos.length > 0) {
            downloadFTAFlagByPart({ invoiceNos })
        } else {
            dispatch(applicationActions.pushError({ title: { code: 'downloadFTAFlagByPart' }, messages: { code: 'w0002' } }))
        }
    }, [dispatch, downloadFTAFlagByPart, invoiceNos])
    return <DownloadGroupedToolbarAction access="LOGI.AIS030.DOWNLOAD">
        {onClose => <GroupedCallbackItem label={<FormattedMessage id="downloadFTAFlagByPart" />} callback={downloadCallback} onClose={onClose} />}
    </DownloadGroupedToolbarAction>
}

const CompletedAction = ({ invoiceNos, search, filters }: { invoiceNos: string[], search: (filter: CustomsInvoiceView) => void, filters: CustomsInvoiceView }) => {
    const doCompleted = useCompletedImpCustoms()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'complete' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const callback = useCallback(() => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            doCompleted({ invoiceNoList: invoiceNos }, { serialized: true }).then(() => search(filters)).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [functionStore, dispatch, title, doCompleted, invoiceNos, search, filters])
    return <CallbackToolbarAction access="LOGI.AIS030.COMPLETED" title={<FormattedMessage id="completed" />} icon={<DoneAll />} callback={callback} disabled={disabled} />
}