import { Project } from "../../../utils/Project"
import { ShippingMode } from "../../accounting/enums/ShippingMode"
import { dateFromJson } from "../../utils/deserializer"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"
import { InboundStatus } from "../enums/InboundStatus"
import { InboundType } from "../enums/InboundType"

export type InboundInfo = Required & Partial<Optional> & TableEntity

type InboundInfoJson = JsonRequired & Partial<JsonOptional> & TableEntityJson

export const inboundInfoFromJson: Project<InboundInfoJson, InboundInfo> = json => ({
    ...tableEntityFromJson(json),
    blDate: dateFromJson(json.blDate),
    etd: dateFromJson(json.etd),
    eta: dateFromJson(json.eta),
    atd: dateFromJson(json.atd),
    orgInboundPlanDate: dateFromJson(json.orgInboundPlanDate),
    latestInboundPlanDate: dateFromJson(json.latestInboundPlanDate),
    completedDate: dateFromJson(json.completedDate),
})

interface Required extends JsonRequired { }

interface Optional extends Omit<JsonOptional, 'blDate' | 'etd' | 'eta' | 'atd' | 'orgInboundPlanDate' | 'latestInboundPlanDate' | 'completedDate'> {
    inboundType: InboundType,
    blDate: Date,
    shippinMode: ShippingMode,
    etd: Date,
    eta: Date,
    atd: Date,
    orgInboundPlanDate: Date,
    latestInboundPlanDate: Date,
    completedDate: Date,
    status: InboundStatus,
}

interface JsonRequired {
    inbId: number,
    inboundNo: string,
}

interface JsonOptional {
    inboundRefNo: string,
    deliveryNoteNo: string,
    outboundNo: string,
    dcId: number,
    customerId: number,
    inboundType: number,
    fromSupplier: number,
    fromSupplierCode: string,
    fromDc: number,
    fromDcCode: string,
    loadingPortId: number,
    loadingPortCode: string,
    dischargePortId: number,
    dischargePortCode: string,
    blNo: string,
    blDate: string,
    shippingMode: number,
    etd: string,
    eta: string,
    atd: string,
    orgInboundPlanDate: string,
    latestInboundPlanDate: string,
    vesselName: string,
    voyageNo: string,
    completedDate: string,
    status: number,
    dataType: number
    datasource: number,
}