import { Dialog, DialogActions, DialogContent, FormControlLabel, Radio, RadioGroup } from "@material-ui/core"
import { Add } from "@material-ui/icons"
import { Data, DataGrid, DataTypePreset, Filtering, Searching, Selection, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { Placeholder, useFieldComponent } from "@rithe/form"
import { GridContainer, GridItem } from "@rithe/ui"
import { Arrays } from "@rithe/utils"
import { useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, IntlProvider, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { AdjustCallbackViewAction } from "../../../components/Action/AdjustCallbackViewAction"
import { CallbackViewAction } from "../../../components/Action/CallbackViewAction"
import { ConfirmCallbackViewAction } from "../../../components/Action/ConfirmCallbackViewAction"
import { DeleteCallbackViewAction } from "../../../components/Action/DeleteCallbackViewAction"
import { SaveCallbackViewAction } from "../../../components/Action/SaveCallbackViewAction"
import { NeumorphismButton } from "../../../components/Button/NeumorphismButton"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { View } from "../../../components/View/View"
import { effectiveLanguage, Language } from "../../../configs/i18n/Language"
import { useFunctionStore } from "../../../Root"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { AnnouncementStatus } from "../../../services/system/enums/AnnouncementStatus"
import { AnnouncementType } from "../../../services/system/enums/AnnouncementType"
import { useMatch } from "../../../utils/useMatch"
import { applicationActions, useApplicationSelector } from "../../Application/applicationSlice"
import messagesEnUs from './i18n/mls261.en-US.json'
import { mls261Actions as actions, useMLS261Selector } from "./MLS261Slice"

const messagesMap: Record<Language, Record<string, string>> = {
    'en-US': messagesEnUs,
    'zh-CN': messagesEnUs,
}

export const MLS261 = () => {
    const { path } = useMatch()
    const { id } = useParams() as { id: string }
    const dispatch = useDispatch()
    useEffect(() => {
        if (path === '/announcements/edit-:id') {
            dispatch(actions.pageInit({ mode: 'EDIT', id }))
        } else if (path === '/announcements/view-:id') {
            dispatch(actions.pageInit({ mode: 'VIEW', id }))
        } else if (path === '/announcements/create') {
            dispatch(actions.pageInit({ mode: 'CREATE' }))
        }
    }, [dispatch, id, path])

    return <ScreenIntlProvider>
        <RootView />
    </ScreenIntlProvider>
}

const ScreenIntlProvider = ({ children }: { children: React.ReactNode }) => {
    const language = useApplicationSelector(state => state.i18n.language)
    const timezone = useApplicationSelector(state => state.i18n.timezone)
    const messages = useMemo(() => {
        const messages = messagesMap[effectiveLanguage(language)]
        const defaultMessages = messagesMap[Language.DEFAULT]
        return { ...defaultMessages, ...messages }
    }, [language])
    return <IntlProvider messages={messages} locale={language} timeZone={timezone}>
        {children}
    </IntlProvider>
}

const RootView = () => {
    const mode = useMLS261Selector(state => state.ui.mode)
    const announcement = useMLS261Selector(state => state.domain.announcement)

    const actions = useMemo(() => {
        const actions = []
        if (mode === 'CREATE') {
            actions.push(<CreateAction key="create" />)
        } else if (mode === 'EDIT') {
            if (announcement.status === AnnouncementStatus.DRAFT) {
                actions.push(<EditAction key="edit" />)
                actions.push(<ConfirmAction key="confirm" />)
                actions.push(<DeleteAction key="delete" />)
            } else if (announcement.status === AnnouncementStatus.CONFIRMED) {
                actions.push(<EditAction key="edit" />)
                actions.push(<DeleteAction key="delete" />)
            } else if (announcement.status === AnnouncementStatus.PUBLISHED) {
                actions.push(<AdjustAction key="adjust" />)
                actions.push(<CancelAction key="cancel" />)
            }
        }
        return actions
    }, [announcement.status, mode])

    const intl = useIntl()
    return <View actions={actions}>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={1}
                title={intl.formatMessage({ id: 'title.basicInfo' })}
                subtitle={intl.formatMessage({ id: 'subtitle.basicInfo' })}
            />
            <SectionCardContent>
                <BasicInfo />
            </SectionCardContent>
        </SectionCard>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={2}
                title={intl.formatMessage({ id: 'title.publishStrategy' })}
                subtitle={intl.formatMessage({ id: 'subtitle.publishStrategy' })}
            />
            <SectionCardContent >
                <PublishStrategy />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const CreateAction = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    return <SaveCallbackViewAction
        power
        title={<FormattedMessage id="action.create" />}
        callback={() => dispatch(actions.clickCreate(id => navigate(`/announcements/view-${id}`)))}
    />
}

const EditAction = () => {
    const dispatch = useDispatch()
    return <SaveCallbackViewAction
        power
        title={<FormattedMessage id="action.edit" />}
        callback={() => dispatch(actions.clickEdit(id => dispatch(actions.pageInit({ mode: 'EDIT', id }))))}
    />
}

const ConfirmAction = () => {
    const dispatch = useDispatch()
    return <ConfirmCallbackViewAction
        power
        title={<FormattedMessage id="action.confirm" />}
        callback={() => dispatch(actions.clickConfirm(id => dispatch(actions.pageInit({ mode: 'EDIT', id }))))}
    />
}

const DeleteAction = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const functionStore = useFunctionStore()
    const intl = useIntl()
    const title = useMemo(() => intl.formatMessage({ id: 'action.delete' }), [intl])
    return <DeleteCallbackViewAction
        power
        title={title}
        callback={() => {
            const functionId = functionStore.register(() => {
                dispatch(actions.clickDelete(() => navigate(`/announcements`)))
            })
            dispatch(applicationActions.pushWarning({
                title: title,
                messages: { code: 'c0001', args: [title] },
                actions: [{
                    label: 'CANCEL'
                }, {
                    functionId,
                    label: 'CONFIRM',
                }]
            }))
        }}
    />
}

const AdjustAction = () => {
    const dispatch = useDispatch()
    return <AdjustCallbackViewAction
        power
        title={<FormattedMessage id="action.adjust" />}
        callback={() => dispatch(actions.clickAdjust(id => dispatch(actions.pageInit({ mode: 'EDIT', id }))))}
    />
}

const CancelAction = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const functionStore = useFunctionStore()
    const intl = useIntl()
    const title = useMemo(() => intl.formatMessage({ id: 'action.endNow' }), [intl])
    return <CallbackViewAction
        power
        title={title}
        callback={() => {
            const functionId = functionStore.register(() => {
                dispatch(actions.clickCancel(id => navigate(`/announcements/view-${id}`)))
            })
            dispatch(applicationActions.pushWarning({
                title: title,
                messages: { code: 'c0001', args: [title] },
                actions: [{
                    label: intl.formatMessage({ id: 'action.cancel' })
                }, {
                    functionId,
                    label: intl.formatMessage({ id: 'action.confirm' }),
                }]
            }))
        }}
    />
}

const BasicInfo = () => {
    return <GridContainer
        component="form"
        columnWidth={Arrays.repeat('1fr', 3)}
        columnGap={24}
        rowHeight="max-content"
    >
        <GridItem columnSpan={2} style={{ overflow: 'hidden' }}>
            <TitleInput />
        </GridItem>
        <GridItem style={{ overflow: 'hidden' }}>
            <TypeInput />
        </GridItem>
        <GridItem columnSpan={3} rowSpan={2} style={{ overflow: 'hidden' }}>
            <SummaryInput />
        </GridItem>
        <GridItem columnSpan={3} rowSpan={4} style={{ overflow: 'hidden' }}>
            <ContentInput />
        </GridItem>
    </GridContainer>
}

const TitleInput = () => {
    const value = useMLS261Selector(state => state.domain.announcement.title)
    const readonly = useMLS261Selector(state => state.ui.mode === 'VIEW'
        || state.domain.announcement.status === AnnouncementStatus.PUBLISHED)
    const visited = useMLS261Selector(state => state.ui.visitedMap['title'] ?? false)
    const messages = useMLS261Selector(state => state.ui.messagesMap['title'])
    const { Control, Label, Helper, Input, InputWrapper } = useFieldComponent('StringInput', {})
    const dispatch = useDispatch()
    return <Control
        inputWrapper={<InputWrapper readonly={readonly} error={visited && messages.length > 0} />}
        label={<Label required readonly={readonly}><FormattedMessage id="field.title" /></Label>}
        input={<Input
            field="title"
            value={value}
            setValue={(value) => {
                dispatch(actions.setFieldVisited({ field: 'title', visited: true }))
                dispatch(actions.setTitle(value || ''))
            }}
            readonly={readonly}
        />}
        helper={<Helper messages={visited ? messages : undefined} />}
    />
}

const TypeInput = () => {
    const value = useMLS261Selector(state => state.domain.announcement.type || null)
    const readonly = useMLS261Selector(state => state.ui.mode === 'VIEW'
        || state.domain.announcement.status === AnnouncementStatus.PUBLISHED)
    const visited = useMLS261Selector(state => state.ui.visitedMap['type'] ?? false)
    const messages = useMLS261Selector(state => state.ui.messagesMap['type'])
    const { Control, Label, Helper, Input, InputWrapper } = useFieldComponent('EntryInput', {})
    const dispatch = useDispatch()
    const intl = useIntl()
    return <Control
        inputWrapper={<InputWrapper readonly={readonly} error={visited && messages.length > 0} />}
        label={<Label required readonly={readonly}><FormattedMessage id="field.type" /></Label>}
        input={<Input
            field="type"
            value={value}
            setValue={(value) => {
                dispatch(actions.setFieldVisited({ field: 'type', visited: true }))
                dispatch(actions.setType(value as any))
            }}
            readonly={readonly}
            entries={[
                [AnnouncementType.NEW, intl.formatMessage({ id: 'announcementType.new' })],
                [AnnouncementType.IMPROVEMENT, intl.formatMessage({ id: 'announcementType.improvement' })],
                [AnnouncementType.MAINTENANCE, intl.formatMessage({ id: 'announcementType.maintenance' })],
            ]}
        />}
        helper={<Helper messages={visited ? messages : undefined} />}
    />
}

const SummaryInput = () => {
    const value = useMLS261Selector(state => state.domain.announcement.summary || '')
    const readonly = useMLS261Selector(state => state.ui.mode === 'VIEW'
        || state.domain.announcement.status === AnnouncementStatus.PUBLISHED)
    const visited = useMLS261Selector(state => state.ui.visitedMap['summary'] ?? false)
    const messages = useMLS261Selector(state => state.ui.messagesMap['summary'])
    const { Control, Label, Helper, Input, InputWrapper } = useFieldComponent('StringInput', {})
    const dispatch = useDispatch()
    return <Control
        inputWrapper={<InputWrapper readonly={readonly} error={visited && messages.length > 0} />}
        label={<Label required readonly={readonly}><FormattedMessage id="field.summary" /></Label>}
        input={<Input multiline
            field="summary"
            value={value}
            setValue={(value) => {
                dispatch(actions.setFieldVisited({ field: 'summary', visited: true }))
                dispatch(actions.setSummary(value || ''))
            }}
            readonly={readonly}
        />}
        helper={<Helper messages={visited ? messages : undefined} />}
    />
}

const ContentInput = () => {
    const value = useMLS261Selector(state => state.domain.announcement.content || '')
    const readonly = useMLS261Selector(state => state.ui.mode === 'VIEW'
        || state.domain.announcement.status === AnnouncementStatus.PUBLISHED)
    const visited = useMLS261Selector(state => state.ui.visitedMap['content'] ?? false)
    const messages = useMLS261Selector(state => state.ui.messagesMap['content'])
    const { Control, Label, Helper, Input, InputWrapper } = useFieldComponent('StringInput', {})
    const dispatch = useDispatch()
    return <Control
        inputWrapper={<InputWrapper readonly={readonly} error={visited && messages.length > 0} />}
        label={<Label required readonly={readonly}><FormattedMessage id="field.content" /></Label>}
        input={<Input multiline
            field="content"
            value={value}
            setValue={(value) => {
                dispatch(actions.setFieldVisited({ field: 'content', visited: true }))
                dispatch(actions.setContent(value || ''))
            }}
            readonly={readonly}
        />}
        helper={<Helper messages={visited ? messages : undefined} />}
    />
}

const PublishStrategy = () => {
    return <GridContainer
        component="form"
        columnWidth={Arrays.repeat('1fr', 3)}
        columnGap={24}
        rowHeight="max-content"
    >
        <GridItem style={{ overflow: 'hidden' }}>
            <PublishAtInput />
        </GridItem>
        <GridItem style={{ overflow: 'hidden' }}>
            <ExpireAtInput />
        </GridItem>
        <GridItem style={{ overflow: 'hidden' }}>
            <StatusInput />
        </GridItem>
        <Placeholder />
        <GridItem columnSpan={3} style={{ overflow: 'hidden' }}>
            <RecipientsInput />
        </GridItem>
    </GridContainer>
}

const PublishAtInput = () => {
    const value = useMLS261Selector(state => state.domain.announcement.publishAt || null)
    const readonly = useMLS261Selector(state => state.ui.mode === 'VIEW'
        || state.domain.announcement.status === AnnouncementStatus.PUBLISHED)
    const visited = useMLS261Selector(state => state.ui.visitedMap['publishAt'] ?? false)
    const messages = useMLS261Selector(state => state.ui.messagesMap['publishAt'])
    const { Control, Label, Helper, Input, InputWrapper } = useFieldComponent('DateTimeInput', {})
    const dispatch = useDispatch()
    return <Control
        inputWrapper={<InputWrapper readonly={readonly} error={visited && messages.length > 0} />}
        label={<Label required readonly={readonly}><FormattedMessage id="field.publishAt" /></Label>}
        input={<Input
            field="publishAt"
            value={value === null ? null : new Date(value)}
            setValue={(value) => {
                dispatch(actions.setFieldVisited({ field: 'publishAt', visited: true }))
                dispatch(actions.setPublishAt(value === null ? null : value.getTime()))
            }}
            readonly={readonly}
            disablePast
            minutesStep={5}
        />}
        helper={<Helper messages={visited ? messages : undefined} />}
    />
}

const ExpireAtInput = () => {
    const value = useMLS261Selector(state => state.domain.announcement.expireAt || null)
    const readonly = useMLS261Selector(state => state.ui.mode === 'VIEW')
    const visited = useMLS261Selector(state => state.ui.visitedMap['expireAt'] ?? false)
    const messages = useMLS261Selector(state => state.ui.messagesMap['expireAt'])
    const { Control, Label, Helper, Input, InputWrapper } = useFieldComponent('DateTimeInput', {})
    const dispatch = useDispatch()
    return <Control
        inputWrapper={<InputWrapper readonly={readonly} error={visited && messages.length > 0} />}
        label={<Label required readonly={readonly}><FormattedMessage id="field.expireAt" /></Label>}
        input={<Input
            field="expireAt"
            value={value === null ? null : new Date(value)}
            setValue={(value) => {
                dispatch(actions.setFieldVisited({ field: 'expireAt', visited: true }))
                dispatch(actions.setExpireAt(value === null ? null : value.getTime()))
            }}
            readonly={readonly}
            disablePast
            minutesStep={5}
        />}
        helper={<Helper messages={visited ? messages : undefined} />}
    />
}

const StatusInput = () => {
    const display = useMLS261Selector(state => state.ui.mode !== 'CREATE')
    const value = useMLS261Selector(state => state.domain.announcement.status || null)
    const { Control, Label, Input, InputWrapper } = useFieldComponent('EntryInput', {})
    const intl = useIntl()
    return display ? <Control
        inputWrapper={<InputWrapper readonly />}
        label={<Label readonly><FormattedMessage id="field.status" /></Label>}
        input={<Input
            field="status"
            value={value}
            setValue={(value) => { }}
            readonly
            entries={[
                [AnnouncementStatus.DRAFT, intl.formatMessage({ id: 'announcementStatus.draft' })],
                [AnnouncementStatus.CONFIRMED, intl.formatMessage({ id: 'announcementStatus.confirmed' })],
                [AnnouncementStatus.PUBLISHED, intl.formatMessage({ id: 'announcementStatus.published' })],
                [AnnouncementStatus.EXPIRED, intl.formatMessage({ id: 'announcementStatus.expired' })],
                [AnnouncementStatus.CANCELLED, intl.formatMessage({ id: 'announcementStatus.cancelled' })],
            ]}
        />}
    /> : null
}

const RecipientsInput = () => {
    const toAll = useMLS261Selector(state => state.domain.announcement.publishToAll ?? true)
    const userIds = useMLS261Selector(state => state.domain.announcement.receiveUserIds || [])
    const customerIds = useMLS261Selector(state => state.domain.announcement.receiveCustomerIds || [])
    const supplierIds = useMLS261Selector(state => state.domain.announcement.receiveSupplierIds || [])
    const buIds = useMLS261Selector(state => state.domain.announcement.receiveBuIds || [])
    const dcIds = useMLS261Selector(state => state.domain.announcement.receiveDcIds || [])
    const readonly = useMLS261Selector(state => state.ui.mode === 'VIEW'
        || state.domain.announcement.status === AnnouncementStatus.PUBLISHED)
    const visited = useMLS261Selector(state => state.ui.visitedMap['recipients'] ?? false)
    const messages = useMLS261Selector(state => state.ui.messagesMap['recipients'])

    const intl = useIntl()
    const users = useMLS261Selector(state => state.domain.users.filter(user => userIds.includes(user.id)), (a, b) => {
        return a.length === b.length && a.every((user, index) => user.id === b[index].id)
    })
    const userColumns = [
        { field: 'loginId', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.user.loginId' }), width: 150 },
        { field: 'name', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.user.name' }), width: 200 },
    ]
    const getUserRowId = useCallback((row: any) => row.id, [])
    const cbdss = useMLS261Selector(state => state.domain.cbdss.filter(cbds => {
        if (cbds.type === CbdsType.CUST) {
            return customerIds.includes(cbds.id)
        } else if (cbds.type === CbdsType.SUPP) {
            return supplierIds.includes(cbds.id)
        } else if (cbds.type === CbdsType.BU) {
            return buIds.includes(cbds.id)
        } else if (cbds.type === CbdsType.DC) {
            return dcIds.includes(cbds.id)
        }
        return false
    }), (a, b) => {
        return a.length === b.length && a.every((cbds, index) => cbds.uid === b[index].uid)
    })
    const cbdsColumns = [
        { field: 'type', dataTypeName: CodeCategory.CbdsType, title: intl.formatMessage({ id: 'column.cbds.type' }), width: 150 },
        { field: 'code', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.cbds.code' }), width: 150 },
        { field: 'name', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.cbds.name' }), width: 200 },
    ]
    const getCbdsRowId = useCallback((row: any) => row.uid, [])

    const { Control, Label, Helper, InputWrapper } = useFieldComponent('StringInput', {})
    const dispatch = useDispatch()
    return <>
        <Control
            inputWrapper={<InputWrapper readonly={readonly} error={visited && messages.length > 0} />}
            label={<Label required readonly={readonly}><FormattedMessage id="field.recipients" /></Label>}
            input={<RadioGroup value={toAll ? 'true' : 'false'} onChange={(e, value) => {
                dispatch(actions.setFieldVisited({ field: 'recipients', visited: true }))
                dispatch(actions.setPublishToAll(value === 'true'))
            }}>
                <FormControlLabel value="true" disabled={readonly} control={<Radio />} label={<FormattedMessage id="recipients.all" />} />
                <FormControlLabel value="false" disabled={readonly} control={<Radio />} label={<FormattedMessage id="recipients.customs" />} />
            </RadioGroup>}
            helper={<Helper messages={visited ? messages : undefined} />}
        />
        {!toAll && <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: 16 }}>
            <div style={{ marginRight: 24 }}>
                {!readonly && <UsersSelector disabled={toAll} />}
                <DataGrid>
                    <TableLayout Container={FlexScrollbar}>
                        <TableHeaderLayout sticky />
                        <TableBodyLayout />
                    </TableLayout>
                    <DataTypePreset />
                    <Data rows={users} columns={userColumns} getRowId={getUserRowId} />
                </DataGrid>
            </div>
            <div style={{ marginRight: 24 }}>
                {!readonly && <CbdssSelector disabled={toAll} />}
                <DataGrid>
                    <TableLayout Container={FlexScrollbar}>
                        <TableHeaderLayout sticky />
                        <TableBodyLayout />
                    </TableLayout>
                    <DataTypePreset />
                    <CodeCategoryTypeProvider codeCategory={CodeCategory.CbdsType} />
                    <Data rows={cbdss} columns={cbdsColumns} getRowId={getCbdsRowId} />
                </DataGrid>
            </div>
        </div>}
    </>
}

const UsersSelector = ({ disabled }: { disabled?: boolean }) => {
    const [open, setOpen] = useState(false)

    const dispatch = useDispatch()
    const intl = useIntl()
    const users = useMLS261Selector(state => state.domain.users)
    const receiveUserIds = useMLS261Selector(state => state.domain.announcement.receiveUserIds || [])
    const [selections, setSelections] = useState<any[]>(receiveUserIds)
    const userColumns = [
        { field: 'loginId', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.user.loginId' }), width: 150 },
        { field: 'name', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.user.name' }), width: 200 },
    ]
    const getUserRowId = useCallback((row: any) => row.id, [])
    return <>
        <NeumorphismButton disabled={disabled} onClick={() => setOpen(true)} style={{ marginBottom: 8 }}>
            <Add />
        </NeumorphismButton>
        <Dialog open={open} style={{ overflow: 'hidden' }} fullWidth maxWidth={'md'}>
            <DialogHeader onClose={() => setOpen(false)}><FormattedMessage id="title.userList" /></DialogHeader>
            <DialogContent style={{ overflow: 'hidden' }}>
                <div style={{ height: 500 }}>
                    <DataGrid>
                        <ToolbarLayout />
                        <TableLayout Container={FlexScrollbar}>
                            <TableHeaderLayout sticky />
                            <TableBodyLayout />
                        </TableLayout>
                        <DataTypePreset />
                        <Data rows={users} columns={userColumns} getRowId={getUserRowId} />
                        <Searching ignoreCase Input={SearchInput} />
                        <Filtering />
                        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
                    </DataGrid>
                </div>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="action.cancel" />} callback={() => setOpen(false)} />
                <DialogAction title={<FormattedMessage id="action.confirm" />} callback={() => {
                    dispatch(actions.setFieldVisited({ field: 'recipients', visited: true }))
                    dispatch(actions.setReceiveUserIds(selections))
                    setOpen(false)
                }} />
            </DialogActions>
        </Dialog>
    </>
}

const CbdssSelector = ({ disabled }: { disabled?: boolean }) => {
    const [open, setOpen] = useState(false)

    const dispatch = useDispatch()
    const intl = useIntl()
    const cbdss = useMLS261Selector(state => state.domain.cbdss)
    const receiveCustomerIds = useMLS261Selector(state => state.domain.announcement.receiveCustomerIds || [])
    const receiveBuIds = useMLS261Selector(state => state.domain.announcement.receiveBuIds || [])
    const receiveDcIds = useMLS261Selector(state => state.domain.announcement.receiveDcIds || [])
    const receiveSupplierIds = useMLS261Selector(state => state.domain.announcement.receiveSupplierIds || [])
    const [selections, setSelections] = useState<any[]>([])
    useEffect(() => {
        if (open) {
            setSelections(cbdss.filter(cbds => {
                if (cbds.type === CbdsType.CUST) {
                    return receiveCustomerIds.includes(cbds.id)
                } else if (cbds.type === CbdsType.BU) {
                    return receiveBuIds.includes(cbds.id)
                } else if (cbds.type === CbdsType.DC) {
                    return receiveDcIds.includes(cbds.id)
                } else if (cbds.type === CbdsType.SUPP) {
                    return receiveSupplierIds.includes(cbds.id)
                } else {
                    return false
                }
            }).map(cbds => cbds.uid))
        }
    }, [cbdss, open, receiveBuIds, receiveCustomerIds, receiveDcIds, receiveSupplierIds])
    const cbdsColumns = [
        { field: 'type', dataTypeName: CodeCategory.CbdsType, title: intl.formatMessage({ id: 'column.cbds.type' }), width: 150 },
        { field: 'code', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.cbds.code' }), width: 150 },
        { field: 'name', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.cbds.name' }), width: 200 },
    ]
    const getCbdsRowId = useCallback((row: any) => row.uid, [])
    return <>
        <NeumorphismButton disabled={disabled} onClick={() => setOpen(true)} style={{ marginBottom: 8 }}>
            <Add />
        </NeumorphismButton>
        <Dialog open={open} style={{ overflow: 'hidden' }} fullWidth maxWidth={'md'}>
            <DialogHeader onClose={() => setOpen(false)}><FormattedMessage id="title.companyList" /></DialogHeader>
            <DialogContent style={{ overflow: 'hidden' }}>
                <div style={{ height: 500 }}>
                    <DataGrid>
                        <ToolbarLayout />
                        <TableLayout Container={FlexScrollbar}>
                            <TableHeaderLayout sticky />
                            <TableBodyLayout />
                        </TableLayout>
                        <DataTypePreset />
                        <CodeCategoryTypeProvider codeCategory={CodeCategory.CbdsType} />
                        <Data rows={cbdss} columns={cbdsColumns} getRowId={getCbdsRowId} />
                        <Searching ignoreCase Input={SearchInput} />
                        <Filtering />
                        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
                    </DataGrid>
                </div>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={() => setOpen(false)} />
                <DialogAction title={<FormattedMessage id="confirm" />} callback={() => {
                    setOpen(false)
                    const selectedCbds = cbdss.filter(cbds => selections.includes(cbds.uid))
                    dispatch(actions.setFieldVisited({ field: 'recipients', visited: true }))
                    dispatch(actions.setReceiveCustomerIds(selectedCbds.filter(cbds => cbds.type === CbdsType.CUST).map(cbds => cbds.id)))
                    dispatch(actions.setReceiveSupplierIds(selectedCbds.filter(cbds => cbds.type === CbdsType.SUPP).map(cbds => cbds.id)))
                    dispatch(actions.setReceiveBuIds(selectedCbds.filter(cbds => cbds.type === CbdsType.BU).map(cbds => cbds.id)))
                    dispatch(actions.setReceiveDcIds(selectedCbds.filter(cbds => cbds.type === CbdsType.DC).map(cbds => cbds.id)))
                }} />
            </DialogActions>
        </Dialog>
    </>
}