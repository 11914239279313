import { DialogActions, DialogContent } from "@material-ui/core"
import DoneOutlineOutlinedIcon from '@material-ui/icons/DoneOutlineOutlined'
import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarLayout } from "@rithe/data-grid"
import { DateItem, Form, StringItem, TimeItem } from "@rithe/form"
import { Records } from "@rithe/utils"
import React, { useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { Access } from "../../../components/Access/Access"
import { IconButton } from "../../../components/Button/IconButton"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CardDialog } from "../../../components/Dialog/CardDialog"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { View } from "../../../components/View/View"
import { useTotallyConfirmInbound } from "../../../services/delivery/apis/deliveryInboundApi"
import { ConfirmInboundInfo } from "../../../services/delivery/models/ConfirmInboundInfo"
import { InboundInfo } from "../../../services/delivery/models/InboundInfo"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { applicationActions } from "../../Application/applicationSlice"

interface LIS013PcUiProps {
    inboundList: InboundInfo[],
    search: () => void,
}

export const LIS013PcUi = (props: LIS013PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = (props: LIS013PcUiProps) => {
    const intl = useIntl()
    const { inboundList, search } = props
    const [selections, setSelections] = useState<number[]>([])

    const columns = useMemo(() => [
        { field: 'inboundNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.inboundNo' }), width: 250 },
        { field: 'inboundRefNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.inboundRefNo' }), width: 200 },
        { field: 'deliveryNoteNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.deliveryNoteNo' }), width: 250 },
        { field: 'outboundNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.outboundNo' }), width: 250 },
        { field: 'inboundType', dataTypeName: CodeCategory.InboundType, title: intl.formatMessage({ id: 'field.inboundType' }), width: 200 },
        { field: 'fromSupplierCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.fromSupplier' }), width: 200 },
        { field: 'fromDcCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.fromDc' }), width: 150 },
        { field: 'loadingPortCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'loadingPort' }), width: 150 },
        { field: 'dischargePortCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'dischargePort' }), width: 150 },
        { field: 'blNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.blNo' }), width: 150 },
        { field: 'blDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.blDate' }), width: 100 },
        { field: 'shippingMode', dataTypeName: CodeCategory.ShippingMode, title: intl.formatMessage({ id: 'field.shippingMode' }), width: 100 },
        { field: 'etd', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.etd' }), width: 100 },
        { field: 'eta', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.eta' }), width: 100 },
        { field: 'atd', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.atd' }), width: 100 },
        { field: 'inboundPlanDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.inboundPlanDate' }), width: 100 },
        { field: 'vesselName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.vesselName' }), width: 150 },
        { field: 'voyageNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.voyageNo' }), width: 150 },
        { field: 'completedDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.completedDate' }), width: 100 },
        { field: 'status', dataTypeName: CodeCategory.InboundStatus, title: intl.formatMessage({ id: 'field.status' }), width: 100 },
    ], [intl])
    const getRowId = useCallback((row: any) => row.inbId, [])

    const actionProps = useMemo(() => ({ search, selections }), [search, selections])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.InboundType} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.ShippingMode} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.InboundStatus} />
        <Data rows={inboundList} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={ConfirmAction} actionProps={actionProps} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['inboundRefNo', 'blNo', 'blDate', 'shippingMode', 'etd', 'eta', 'atd', 'inboundPlanDate', 'vesselName', 'voyageNo', 'completedDate']}
            columnSettings={{
                inboundNo: { disableUserControl: true },
                inboundRefNo: { disableUserControl: true },
            }}
            ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <Sorting />
        <Filtering />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
    </DataGrid>
}

const ConfirmAction = ({ search, selections }: { search: () => void, selections: number[] }) => {
    const intl = useIntl()
    const confirmInbound = useTotallyConfirmInbound()
    const [open, setOpen] = useState<boolean>(false)
    const [factor, setFactor] = useState<ConfirmInboundInfo>({ inbIdList: selections })
    const dispatch = useDispatch()
    const openConfirm = () => {
        if (selections && selections.length > 0) {
            setOpen(true)
        } else {
            dispatch(applicationActions.pushError({ title: { code: 'confirm' }, messages: { code: 'w0002' } }))
        }
    }
    const confirmMethod = () => {
        confirmInbound({ ...factor, inbIdList: [...selections] }, { serialized: true }).then(() => {
            search()
        })
    }
    // TODO
    return <Access access="LOGI.LIS013.CONFIRM">
        <IconButton onClick={openConfirm} style={{ marginRight: '8px' }}>{<DoneOutlineOutlinedIcon></DoneOutlineOutlinedIcon>}</IconButton>
        <CardDialog open={open} style={{ overflow: 'hidden' }} maxWidth="sm" fullWidth>
            <DialogHeader onClose={() => setOpen(false)}>Confirm</DialogHeader>
            <DialogContent>
                <Form data={factor} setData={setFactor} labelDisplay="block" helperDisplay="tooltip" columnCount={1} minWidth={500} maxWidth={500}>
                    <StringItem field="inboundNo" labelWidth={120} label={intl.formatMessage({ id: 'field.inboundNo' })} />
                    <DateItem field="inboundDate" labelWidth={120} label={intl.formatMessage({ id: 'field.inboundDate' })} />
                    <TimeItem field="inboundTime" labelWidth={120} label={intl.formatMessage({ id: 'field.inboundTime' })} />
                </Form>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={() => setOpen(false)} />
                <DialogAction title={<FormattedMessage id="confirm" />} callback={confirmMethod} />
            </DialogActions>
        </CardDialog>
    </Access>
}