import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { Projects } from "../../../utils/Projects"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { ContractRouteDetailPartsResult, contractRouteDetailPartsResultFromJson } from "../models/ContractRouteDetailPartsResult"
import { ContractRouteFactor, contractRouteFactorToJson } from "../models/ContractRouteFactor"
import { ContractRouteResult, contractRouteResultFromJson } from "../models/ContractRouteResult"

export const useGetContractRouteList = () => {
    const url = '/lcbm-local-master-api/api/getContractRouteList'
    const payloadProject = useCallback(contractRouteFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(contractRouteResultFromJson), [])
    return usePost<ContractRouteFactor, PageResultEntity<ContractRouteResult>>(url, payloadProject, resultProject)
}

export const useGetContractRouteDetailHeaderByContractRouteId = () => {
    const url = '/lcbm-local-master-api/api/getContractRouteDetailHeaderByContractRouteId'
    const payloadProject = useCallback((obj: { contractRouteId: number }) => obj.contractRouteId, [])
    const resultProject = useCallback(contractRouteResultFromJson, [])
    return usePost<{ contractRouteId: number }, ContractRouteResult>(url, payloadProject, resultProject)
}

export const useGetContractRouteDetailPartsByContractRouteId = () => {
    const url = '/lcbm-local-master-api/api/getContractRouteDetailPartsByContractRouteId'
    const payloadProject = useCallback((obj: { contractRouteId: number }) => obj.contractRouteId, [])
    const resultProject = useCallback(Projects.array(contractRouteDetailPartsResultFromJson), [])
    return usePost<{ contractRouteId: number }, ContractRouteDetailPartsResult[]>(url, payloadProject, resultProject)
}