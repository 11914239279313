import { Project } from "../../../utils/Project"

export interface AccountingFactor {
    buyerList?: string[],
    sellerList?: string[],
}

interface AccountingFactorJson {
    buyerList?: string[],
    sellerList?: string[],
}

export const AccountingFactorToJson: Project<AccountingFactor, AccountingFactorJson> = obj => ({
    ...obj
})