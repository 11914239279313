import { Project } from "../../../utils/Project"

export type FunctionList = Required & Partial<Optional>
export type FunctionListJson = JsonRequired & Partial<JsonOptional>
export const functionListToJson: Project<FunctionList, FunctionListJson> = obj => ({
    ...obj,
})
export const functionListFromJson: Project<FunctionListJson, FunctionList> = json => ({
    ...json,
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
}

interface JsonRequired {
}

interface JsonOptional {
    code: string
    category: string
    name: string
    description: string
    sort: number
    createdBy: string
    createdDate: number
}