import { useCallback, useEffect, useMemo, useState } from "react"
import { useGetCustomerByLoginUser } from "../../../services/smt/api/ComboboxApi"
import { useGetGrouping } from "../../../services/smt/api/CustomerUsageApi"
import { useGetWeeklyRundownList } from "../../../services/smt/stockManagement/api/weeklyRundownApi"
import { WeeklyRundownFactor, weeklyRundownFactorFromJson, weeklyRundownFactorToJson } from "../../../services/smt/stockManagement/models/WeeklyRundownFactor"
import { WeeklyRundownResult } from "../../../services/smt/stockManagement/models/WeeklyRundownResult"
import { Pair } from "../../../services/utils/Pair"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { SMGTS110PcUi } from "./SMGTS110PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 }, simulateByParts: false, baseCarrierDate: 1 }
const searchCachekey = sessionKeys.Filters_SMGTS110

export const SMGTS110 = () => {
    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? weeklyRundownFactorFromJson(JSON.parse(filters)) : null
    }, [])

    const [filters, setFilters] = useState<WeeklyRundownFactor>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<WeeklyRundownResult[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [customerList, setCustomerList] = useState<Pair[]>([])
    const [groupings, setGroupings] = useState<string[]>([])
    const [selections, setSelections] = useState<number[]>([])

    const getWeeklyRundownList = useGetWeeklyRundownList()
    const search = useCallback((filters: WeeklyRundownFactor) => {
        getWeeklyRundownList(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            setSelections([])
            sessionStorage.setItem(searchCachekey, JSON.stringify(weeklyRundownFactorToJson(filters)))
        })
    }, [getWeeklyRundownList, setData])

    useSearchOnLoad(search, filters)

    const getCustomerList = useGetCustomerByLoginUser()
    const getGrouping = useGetGrouping()
    useEffect(() => {
        getCustomerList(undefined, { silent: true, serialized: true }).then((result) => {
            setCustomerList(result ?? [])
        })
        getGrouping(undefined, { silent: true, serialized: true }).then((result) => {
            setGroupings(result ?? [])
        })
    }, [getCustomerList, getGrouping])

    return <SMGTS110PcUi
        filters={filters}
        search={search}
        data={data}
        totalCount={totalCount}
        setFilters={setFilters}
        customerList={customerList}
        groupings={groupings}
        selections={selections}
        setSelections={setSelections}
    />
}