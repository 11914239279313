import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, makeStyles, Typography } from "@material-ui/core"
import { Break, Form, NumberItem, StringItem } from "@rithe/form"
import React, { ReactNode, useCallback, useMemo } from "react"
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { Currency, GiGrInvoice } from "../../../services/accounting/models/GiGrInvoice"
import { TatInvoiceBreakdown } from "../../../services/accounting/models/TatInvoiceBreakdown"

interface BreakdownProps {
    invoice?: GiGrInvoice,
    open: boolean,
    onClose: () => void,
}

export const AIS011BreakdownPcUi = ({ invoice, open, onClose }: BreakdownProps) => {

    const breakdowns = useMemo(() => {
        return invoice?.invoices ? invoice?.invoices.flatMap(inv => prepareBreakdown(inv.breakdown, inv.invoiceNo, inv.invoiceCurrency)) : [prepareBreakdown(invoice?.breakdown, invoice?.invoiceNo, invoice?.invoiceCurrency)]
    }, [invoice?.breakdown, invoice?.invoiceCurrency, invoice?.invoiceNo, invoice?.invoices])
    const invoiceNos = useMemo(() => invoice?.invoices ? invoice?.invoices.flatMap(m => m.invoiceNo) ?? [] : [invoice?.invoiceNo ?? ''], [invoice?.invoiceNo, invoice?.invoices])

    return <Dialog open={open} onClose={onClose} maxWidth="lg" >
        <DialogTitle style={{ cursor: 'move' }} ><Typography variant="h3">{<FormattedMessage id="incotermBreakdownView" />}</Typography></DialogTitle>
        <DialogContent>
            <Divider />
            {breakdowns.map((breakdown, index) => <BreakdownContent index={index} invoiceBreakdown={breakdown} invoiceNos={invoiceNos} />)}
            <Divider />
        </DialogContent>
        <DialogActions>
            <DialogAction outlined title={<FormattedMessage id="close" />} callback={onClose} />
        </DialogActions>
    </Dialog >
}

const BreakdownContent = ({ index, invoiceNos, invoiceBreakdown }: {
    index: number,
    invoiceNos: string[],
    invoiceBreakdown: InvoiceBreakDown,
}) => {
    const intl = useIntl()
    return <>
        {index > 0 && <Divider />}
        {invoiceNos.length > 1 && <div style={{ paddingLeft: 16, paddingTop: 16 }}>
            <Typography variant="subtitle2">{invoiceNos[index]}</Typography>
        </div>}
        <div style={{ padding: 16 }}>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    title={intl.formatMessage({ id: 'InvoiceAmount' })}
                />
                <SectionCardContent>
                    <InvoiceAmountView invoiceBreakdown={invoiceBreakdown} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    title={intl.formatMessage({ id: 'CostBreakdown' })}
                />
                <SectionCardContent>
                    <CostBreakdownView invoiceBreakdown={invoiceBreakdown} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    title={intl.formatMessage({ id: 'AddtionalCost' })}
                />
                <SectionCardContent>
                    <AddtionalCostView invoiceBreakdown={invoiceBreakdown} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    title={intl.formatMessage({ id: 'InvoiceAmountGrandTotal' })}
                />
                <SectionCardContent>
                    <InvoiceAmountGrandTotalView invoiceBreakdown={invoiceBreakdown} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    title={intl.formatMessage({ id: 'Preview' })}
                />
                <SectionCardContent>
                    <PreviewView invoiceBreakdown={invoiceBreakdown} />
                </SectionCardContent>
            </SectionCard>
        </div>
    </>
}

// card_1
const InvoiceAmountView = ({ invoiceBreakdown }: {
    invoiceBreakdown: InvoiceBreakDown,
}) => {
    const intl = useIntl()
    const getAmountByAdjustValue = useCallback((data) => data.invoiceAmount + (data.invoiceAdjustmentAmount ?? 0), [])
    return <Form columnCount={13} data={invoiceBreakdown} readonly labelDisplay="block" helperDisplay="tooltip">
        <StringItem field='invoiceCurrency' label={intl.formatMessage({ id: 'field.invoiceCurrency' })} colSpan={2} />
        <NumberItem field='invoiceAmount' label={intl.formatMessage({ id: 'field.invoiceAmount' })} colSpan={3} />
        <NumberItem field='invoiceAdjustmentAmount' label={intl.formatMessage({ id: 'field.adjustInvoiceAmount' })} colSpan={3} />
        <NumberItem field='invoiceAmountByAdjust' label={intl.formatMessage({ id: 'field.invoiceAmount(adjusted)' })} getValue={getAmountByAdjustValue} colSpan={4} />
        <Break />
        <StringItem field='invoiceAdjustmentReason' label={intl.formatMessage({ id: 'field.invoiceReason' })} colSpan={13} />
    </Form>
}

// card_2
const CostBreakdownView = ({ invoiceBreakdown }: {
    invoiceBreakdown: InvoiceBreakDown,
}) => {
    const intl = useIntl()
    const { costItems } = invoiceBreakdown
    const mapItemNameValue = useCallback((item) => {
        return (data: any, value: any) => ({ ...data, costItems: data.costItems.map((m: any) => m.itemNo === item.itemNo ? { ...m, itemName: value } : { ...m }) })
    }, [])
    const mapItemAmountValue = useCallback((item) => {
        return (data: any, value: any) => ({ ...data, costItems: data.costItems.map((m: any) => m.itemNo === item.itemNo ? { ...m, itemAmount: value } : { ...m }) })
    }, [])

    return <Form columnCount={13} data={invoiceBreakdown} readonly labelDisplay="block" helperDisplay="tooltip" >
        <StringItem field='initialIncotermsCode' label={intl.formatMessage({ id: 'field.initialIncotermsCode' })} colSpan={2} />
        <StringItem field='initialIncotermsPlace' label={intl.formatMessage({ id: 'field.initialIncotermsPlace' })} colSpan={6} />
        <NumberItem field='initialIncotermsAmount' label={intl.formatMessage({ id: 'field.initialIncotermsAmount' })} colSpan={4} />
        <Break />

        {costItems && costItems.map((item) => {
            return <>
                <StringItem field={'itemName' + item.itemNo} label={intl.formatMessage({ id: 'field.item' }) + ' ' + (item.itemNo + 1)} colSpan={8} getValue={() => item.itemName} mapValue={mapItemNameValue(item)} />
                <NumberItem field={'itemAmount' + item.itemNo} label={intl.formatMessage({ id: 'field.item' }) + ' ' + (item.itemNo + 1) + ' ' + intl.formatMessage({ id: 'field.Amount' })} colSpan={4} getValue={() => item.itemAmount} mapValue={mapItemAmountValue(item)} />
            </>
        })}
    </Form >
}

// card_3
const AddtionalCostView = ({ invoiceBreakdown }: {
    invoiceBreakdown: InvoiceBreakDown,
}) => {

    const intl = useIntl()
    const { additionalItems } = invoiceBreakdown
    const mapItemNameValue = useCallback((item) => {
        return (data: any, value: any) => ({ ...data, additionalItems: data.additionalItems.map((m: any) => m.itemNo === item.itemNo ? { ...m, itemName: value } : { ...m }) })
    }, [])
    const mapItemAmountValue = useCallback((item) => {
        return (data: any, value: any) => ({ ...data, additionalItems: data.additionalItems.map((m: any) => m.itemNo === item.itemNo ? { ...m, itemAmount: value } : { ...m }) })
    }, [])

    return <Form columnCount={13} data={invoiceBreakdown} readonly labelDisplay="block" helperDisplay="tooltip" >
        {additionalItems && additionalItems.map((item) => {
            return <>
                <StringItem field={'additionalItemName' + item.itemNo} label={intl.formatMessage({ id: 'field.item' }) + ' ' + (item.itemNo + 1)} colSpan={8} getValue={() => item.itemName} mapValue={mapItemNameValue(item)} />
                <NumberItem field={'additionalItemAmount' + item.itemNo} label={intl.formatMessage({ id: 'field.item' }) + ' ' + (item.itemNo + 1) + ' ' + intl.formatMessage({ id: 'field.Amount' })} colSpan={4} getValue={() => item.itemAmount} mapValue={mapItemAmountValue(item)} />
            </>
        })}
    </Form >
}

// card_4
const InvoiceAmountGrandTotalView = ({ invoiceBreakdown }: {
    invoiceBreakdown: InvoiceBreakDown
}) => {
    const intl = useIntl()

    return <Form columnCount={13} data={invoiceBreakdown} readonly labelDisplay="block" helperDisplay="tooltip" >
        <StringItem field='initialIncotermsCode' label={intl.formatMessage({ id: 'field.initialIncotermsCode' })} colSpan={2} />
        <StringItem field='initialIncotermsPlace' label={intl.formatMessage({ id: 'field.initialIncotermsPlace' })} colSpan={6} />
        <NumberItem field='initialIncotermsAmount' label={intl.formatMessage({ id: 'field.initialIncotermsAmount' })} colSpan={4} />
        <Break />

        <StringItem field='finalIncotermsCode' label={intl.formatMessage({ id: 'field.finalIncotermsCode' })} colSpan={2} />
        <StringItem field='finalIncotermsPlace' label={intl.formatMessage({ id: 'field.finalIncotermsPlace' })} colSpan={6} />
        <NumberItem field='finalIncotermsAmount' label={intl.formatMessage({ id: 'field.finalIncotermsAmount' })} colSpan={4} />
    </Form>
}

// card_5
const PreviewView = ({ invoiceBreakdown }: {
    invoiceBreakdown: InvoiceBreakDown
}) => {

    const styles = useStyles()

    const { initialIncotermsCode, finalIncotermsCode, invoiceAmount, invoiceAdjustmentAmount, invoiceCurrency, costItems, additionalItems, currencyDigits } = invoiceBreakdown

    const disCostItems = useMemo(() => costItems ? costItems.filter(f => f.itemName || (f.itemAmount !== null && f.itemAmount !== undefined)) : [], [costItems])
    const disAdditionItems = useMemo(() => additionalItems ? additionalItems.filter(f => f.itemName || (f.itemAmount !== null && f.itemAmount !== undefined)) : [], [additionalItems])
    const invoiceAmountByAdjust = useMemo(() => (invoiceAmount ?? 0) + (invoiceAdjustmentAmount ?? 0), [invoiceAdjustmentAmount, invoiceAmount])

    return <DataBlock >
        {((disCostItems.length + disAdditionItems.length) > 0 || initialIncotermsCode !== finalIncotermsCode) ?
            <>
                <Grid container>
                    <Grid item xs={1} container direction="column">
                        <Typography variant="body2">{initialIncotermsCode}</Typography>
                    </Grid>
                    <Grid item xs={3} container direction="column" alignItems="flex-start">
                        <Typography variant="body2">{invoiceBreakdown.initialIncotermsPlace}</Typography>
                    </Grid>
                    <Grid item xs={2} container direction="column" alignItems="flex-end">
                        <Typography variant="body2">{invoiceCurrency} <FormattedNumber value={invoiceBreakdown.initialIncotermsAmount ?? 0} minimumFractionDigits={currencyDigits} maximumFractionDigits={currencyDigits} /></Typography>
                    </Grid>
                </Grid>
                {
                    disCostItems && <>
                        {
                            disCostItems.map(item => {
                                return <Grid container>
                                    <Grid item xs={4} container direction="column">
                                        <Typography variant="body2">{item.itemName}</Typography>
                                    </Grid>
                                    <Grid item xs={2} container direction="column" alignItems="flex-end">
                                        <Typography variant="body2">{invoiceCurrency} <FormattedNumber value={item.itemAmount ?? 0} minimumFractionDigits={currencyDigits} maximumFractionDigits={currencyDigits} /></Typography>
                                    </Grid>
                                </Grid>
                            })
                        }
                        <Grid item container xs={6}><div className={styles.subDivider} ><Divider /></div></Grid>
                        <Grid container>
                            <Grid item xs={4} container direction="column">
                                <Typography variant="body2">SUB TOTAL</Typography>
                            </Grid>
                            <Grid item xs={2} container direction="column" alignItems="flex-end">
                                <Typography variant="body2">{invoiceCurrency} <FormattedNumber value={invoiceAmountByAdjust} minimumFractionDigits={currencyDigits} maximumFractionDigits={currencyDigits} /></Typography>
                            </Grid>
                        </Grid>
                        <Grid item container xs={6}>
                            <div style={{ width: '100%', alignItems: 'center', padding: 8 }}></div>
                        </Grid>
                    </>
                }
                {
                    disAdditionItems.map(item => {
                        return <Grid container>
                            <Grid item xs={4} container direction="column">
                                <Typography variant="body2">{item.itemName}</Typography>
                            </Grid>
                            <Grid item xs={2} container direction="column" alignItems="flex-end">
                                <Typography variant="body2">{invoiceCurrency} <FormattedNumber value={item.itemAmount ?? 0} minimumFractionDigits={currencyDigits} maximumFractionDigits={currencyDigits} /></Typography>
                            </Grid>
                        </Grid>
                    })
                }
            </> : <></>
        }
        <Grid container>
            <Grid item xs={1} container direction="column">
                <Typography variant="body2">{finalIncotermsCode}</Typography>
            </Grid>
            <Grid item xs={3} container direction="column" alignItems="flex-start">
                <Typography variant="body2">{invoiceBreakdown.finalIncotermsPlace}</Typography>
            </Grid>
            <Grid item xs={2} container direction="column" alignItems="flex-end">
                <Typography variant="body2">{invoiceCurrency} <FormattedNumber value={invoiceBreakdown.finalIncotermsAmount ?? 0} minimumFractionDigits={currencyDigits} maximumFractionDigits={currencyDigits} /></Typography>
            </Grid>
        </Grid>

        <Grid item container xs={6}><div className={styles.divider} ><Divider /></div></Grid>
        <Grid container>
            <Grid item xs={1} container direction="column">
                <Typography variant="body2" style={{ fontWeight: 'bold' }}><FormattedMessage id="GrandTotal" /></Typography>
            </Grid>
            <Grid item xs={3} container direction="column">
                <Typography variant="body2"></Typography>
            </Grid>
            <Grid item xs={2} container direction="column" alignItems="flex-end">
                <Typography variant="body2" style={{ fontWeight: 'bold' }}>{invoiceCurrency} <FormattedNumber value={invoiceBreakdown.finalIncotermsAmount as number} minimumFractionDigits={currencyDigits} maximumFractionDigits={currencyDigits} /></Typography>
            </Grid>
        </Grid>
    </DataBlock >
}

const DataBlock = ({ colSpan = 1, rowSpan = 1, children }: { colSpan?: number, rowSpan?: number, children?: ReactNode | ReactNode[] }) => {
    const styles = useStyles()
    return <div className={styles.dataArea} style={{ gridColumn: `span ${colSpan}`, gridRow: `span ${rowSpan}` }}>
        <div>
            {children}
        </div>
    </div>
}

const useStyles = makeStyles(theme => ({
    dataArea: {
        border: `1px dashed ${theme.palette.divider}`,
        borderRadius: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: theme.spacing(2),
        '&>*:nth-child(1)': {
            textTransform: 'uppercase',
            marginBottom: theme.spacing(2),
        },
        '&>*:nth-child(2)': {
            padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
            borderRadius: theme.spacing(1),
            backgroundColor: theme.palette.grey[200],
            minHeight: 37,
            boxSizing: 'border-box',
        },
    },
    divider: {
        width: '100%',
        alignItems: 'center',
        padding: theme.spacing(1, 0),
    },
    subDivider: {
        width: '100%',
        alignItems: 'center',
        padding: 5,
    }
}))


const prepareBreakdown = (breakdown: TatInvoiceBreakdown | undefined, invoiceNo: string | undefined, currency: Currency | undefined) => {

    return breakdown ? {
        invoiceNo: invoiceNo,
        invoiceCurrency: currency?.currencyCode,
        currencyDigits: currency?.decimalDigits,
        invoiceAmount: breakdown.invoiceAmount,
        invoiceAdjustmentAmount: breakdown.invoiceAdjustmentAmount,
        invoiceAdjustmentReason: breakdown.invoiceAdjustmentReason,
        initialIncotermsCode: breakdown.initialIncotermsCode,
        initialIncotermsPlace: breakdown.initialIncotermsPlace,
        initialIncotermsAmount: breakdown.initialIncotermsAmount,
        finalIncotermsCode: breakdown.finalIncotermsCode,
        finalIncotermsPlace: breakdown.finalIncotermsPlace,
        finalIncotermsAmount: breakdown.finalIncotermsAmount,
        costItems: prepareItems([
            [breakdown.costItem1, breakdown.costItem1Amount],
            [breakdown.costItem2, breakdown.costItem2Amount],
            [breakdown.costItem3, breakdown.costItem3Amount],
            [breakdown.costItem4, breakdown.costItem4Amount],
            [breakdown.costItem5, breakdown.costItem5Amount],
        ]),
        additionalItems: prepareItems([
            [breakdown.additionalItem1, breakdown.additionalItem1Amount],
            [breakdown.additionalItem2, breakdown.additionalItem2Amount],
            [breakdown.additionalItem3, breakdown.additionalItem3Amount],
            [breakdown.additionalItem4, breakdown.additionalItem4Amount],
            [breakdown.additionalItem5, breakdown.additionalItem5Amount],
        ]),
    } : {
        invoiceNo: invoiceNo
    } as InvoiceBreakDown
}
const prepareItems = (items: [string | undefined, number | undefined][]) => {
    const max = items.map((m, idx) => (m[0] || (m[1] !== null && m[1] !== undefined)) ? idx : -1).sort((a, b) => b - a)[0]
    return max >= 0 ? items.filter((_, idx) => idx <= max).map((m, idx) => ({ itemNo: idx, itemName: m[0], itemAmount: m[1] })) : [{ itemNo: 0 }, { itemNo: 1 }, { itemNo: 2 }]
}

interface InvoiceBreakDown {

    invoiceNo?: string,
    invoiceCurrency?: string,
    invoiceAmount?: number,
    invoiceAdjustmentAmount?: number,
    invoiceAdjustmentReason?: string,

    initialIncotermsCode?: string,
    initialIncotermsPlace?: string,
    initialIncotermsAmount?: number,
    finalIncotermsCode?: string,
    finalIncotermsPlace?: string,
    finalIncotermsAmount?: number,
    currencyDigits?: number,
    costItems?: ItemsField[],
    additionalItems?: ItemsField[],
}

interface ItemsField {
    itemNo: number,
    itemName?: string,
    itemAmount?: number,
}