import { Typography } from "@material-ui/core"
import { KeyFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/KeyFormatter"
import React from "react"
import { ActiveFlag } from "../../../services/master/enums/ActiveFlag"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { CodeCategoryTypeProvider } from "./CodeCategoryTypeProvider"

export const ActiveFlagTypeProvider = () => {
    return <CodeCategoryTypeProvider codeCategory={CodeCategory.ActiveFlag} Formatter={Formatter} />
}

const Formatter = (props: KeyFormatterProps) => {
    const { value, formatter } = props
    const active = props.value === ActiveFlag.ACTIVE

    return <Typography variant="body2" style={{ color: active ? 'green' : 'red' }}>
        {value === null ? '' : formatter.format(value)}
    </Typography>
}