import moment from "moment"
import { useCallback, useEffect, useMemo, useState } from "react"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { useGetCustomerByLoginUser, useGetSellerByLoginUser } from "../../../services/smt/api/ComboboxApi"
import { ImpInventoryList } from "../../../services/smt/models/ImpInventoryList"
import { ImpInventoryListFactor, impInventoryListFactorFromJson, impInventoryListFactorToJson } from "../../../services/smt/models/ImpInventoryListFactor"
import { ImpInventoryListForCust } from "../../../services/smt/models/ImpInventoryListForCust"
import { useGetImpInventoryList, useGetImpInventoryListForCust } from "../../../services/smt/stockManagement/api/weeklyRundownApi"
import { Pair } from "../../../services/utils/Pair"
import { useGetCompanyType } from "../../../utils/ApplicationUtils"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { SMGTS610PcUi } from "./SMGTS610PcUi"
import { SMGTS610PcUiForCust } from "./SMGTS610PcUiForCust"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }

const searchCachekey = sessionKeys.Filters_SMGTS610

export const SMGTS610 = () => {
    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? impInventoryListFactorFromJson(JSON.parse(filters)) : null
    }, [])

    // The date format like "Fri Nov 04 2022 00:00:00", can't be "Fri Nov 04 2022 17:40:06"
    const [filters, setFilters] = useState<ImpInventoryListFactor>(cacheFilters ?? {
        ...defaultFilters,
        stockDateStart0: moment(new Date().setHours(0, 0, 0, 0)).add(-6, 'd').toDate(),
        stockDateEndNext: new Date(new Date().setHours(0, 0, 0, 0))
    })

    const [data, setData] = useState<ImpInventoryList[]>([])
    const [dataForCust, setDataForCust] = useState<ImpInventoryListForCust[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [customerList, setCustomerList] = useState<Pair[]>([])
    const [sellerList, setSellerList] = useState<Pair[]>([])
    const cbdsType = useGetCompanyType()

    const getImpInventoryList = useGetImpInventoryList()
    const getImpInventoryListForCust = useGetImpInventoryListForCust()

    const search = useCallback((filters: ImpInventoryListFactor) => {
        if (cbdsType === CbdsType.BU) {
            getImpInventoryList(filters, { silent: true, serialized: true }).then(result => {
                setTotalCount(draft => result?.page?.totalCount || 0)
                setData(result?.data ?? [])
                sessionStorage.setItem(searchCachekey, JSON.stringify(impInventoryListFactorToJson(filters)))
            })
        } else {
            getImpInventoryListForCust(filters, { silent: true, serialized: true }).then(result => {
                setTotalCount(draft => result?.page?.totalCount || 0)
                setDataForCust(result?.data ?? [])
                sessionStorage.setItem(searchCachekey, JSON.stringify(impInventoryListFactorToJson(filters)))
            })
        }
    }, [cbdsType, getImpInventoryList, getImpInventoryListForCust])


    useSearchOnLoad(search, filters)

    const getCustomerList = useGetCustomerByLoginUser()
    const getSellerList = useGetSellerByLoginUser()

    useEffect(() => {
        if (cbdsType === CbdsType.BU) {
            getCustomerList(undefined, { silent: true, serialized: true }).then((result) => {
                setCustomerList(result ?? [])
            })
        } else {
            getSellerList(undefined, { silent: true, serialized: true }).then((result) => {
                setSellerList(result ?? [])
            })
        }
    }, [cbdsType, getCustomerList, getSellerList])

    if (CbdsType.CUST === cbdsType) {
        return <SMGTS610PcUiForCust
            filters={filters}
            search={search}
            data={dataForCust}
            totalCount={totalCount}
            setFilters={setFilters}
            sellerList={sellerList}
        />
    } else {
        return <SMGTS610PcUi
            filters={filters}
            search={search}
            data={data}
            totalCount={totalCount}
            setFilters={setFilters}
            customerList={customerList}
        />
    }

}