import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { PageViewEntity, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"


export type CreditSettingInfoFactor = Required & Partial<Optional>
type CreditSettingInfoResultJson = JsonRequired & Partial<JsonOptional>
export const creditSettingInfoFactorToJson: Project<CreditSettingInfoFactor, CreditSettingInfoResultJson> = obj => ({
    ...obj,
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page)
})


interface Required extends JsonRequired {

}

interface Optional extends JsonOptional {
    page: PageViewEntity,
}

interface JsonRequired {

}

interface JsonOptional {
    buyerCodes: string[],
    currencys: string[],
    creditRuleNos: string[],
    autoHoldOrders: number[],
    autoHoldInvoices: number[],
    statuss: number[],
    page: PageViewEntityJson
}