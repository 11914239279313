import { useCallback, useEffect, useMemo, useState } from "react"
import { useComMasterGetActiveCustomer } from "../../../services/common/apis/commonMasterApi"
import { TnmCustomer } from "../../../services/master/models/TnmCustomer"
import { useFindMapaCCHistoryList } from "../../../services/order/apis/MapaChangeCancelHistoryApi"
import { cCRequestListFactorFromJson } from "../../../services/order/models/CCRequestListFactor"
import { MapaCCHistoryFactor, mapaCCHistoryFactorToJson } from "../../../services/order/models/MapaCCHistoryFactor"
import { MapaCCHistoryResult } from "../../../services/order/models/MapaCCHistoryResult"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { INT060PcUi } from "./INT060PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }

const searchCachekey = sessionKeys.Filters_INT060

export const INT060 = () => {
    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? cCRequestListFactorFromJson(JSON.parse(filters)) : null
    }, [])

    const [filters, setFilters] = useState<MapaCCHistoryFactor>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<MapaCCHistoryResult[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [customerList, setCustomerList] = useState<TnmCustomer[]>([])

    const getData = useFindMapaCCHistoryList()
    const search = useCallback((filters: MapaCCHistoryFactor) => {
        getData(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(mapaCCHistoryFactorToJson(filters)))
        })
    }, [getData, setData])

    useSearchOnLoad(search, filters)

    const getCustomerList = useComMasterGetActiveCustomer()
    useEffect(() => {
        getCustomerList(undefined, { silent: true }).then(result => {
            setCustomerList(result || [])
        })
    }, [getCustomerList])


    return <INT060PcUi
        filters={filters}
        setFilters={setFilters}
        search={search}
        data={data}
        totalCount={totalCount}
        customerList={customerList}
    />
}