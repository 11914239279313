import { makeStyles } from "@material-ui/core";
import React, { ReactNode } from "react";

interface ViewPanelsProps {
    children?: ReactNode | ReactNode[],
}

export function ViewPanels(props: ViewPanelsProps) {
    const { children } = props

    const styles = useStyles()
    return <div className={styles.root}>
        {children}
    </div>
}

const useStyles = makeStyles({
    root: {
        width: '100%',
        flex: '1 1 auto',
        overflow: 'hidden',
    }
})