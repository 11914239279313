import React, { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useGetInboundInfo } from "../../../services/delivery/apis/deliveryInboundApi"
import { InboundMonitorInfo } from "../../../services/delivery/models/InboundMonitorInfo"
import { LIS011PcUi } from "./LIS011PcUi"

export const LIS011 = () => {

    const { inboundNo } = useParams() as any
    const [inboundInfo, setInboundInfo] = useState<InboundMonitorInfo>({} as any)

    const getInboundInfo = useGetInboundInfo()
    const search = useCallback(() => {
        if (inboundNo) {
            getInboundInfo({ inboundNo }, { silent: true, serialized: true }).then(result => setInboundInfo(result))
        }
    }, [inboundNo, getInboundInfo])

    useEffect(() => {
        search()
    }, [search])

    return <LIS011PcUi inboundNo={inboundNo} inboundInfo={inboundInfo} search={search} />
}
