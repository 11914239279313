import { Project } from "@rithe/utils"
import { dateFromJson } from "../../utils/deserializer"
import { dateToJson } from "../../utils/serializer"

export type WIPAdjustmentFactor = Partial<Optional>;
export type WIPAdjustmentFactorJson = Partial<JsonOptional>;

export const wipAdjustmentFactorToJson: Project<WIPAdjustmentFactor, WIPAdjustmentFactorJson> = obj => ({
    ...obj,
    adjustDateStart: dateToJson(obj.adjustDateStart),
    adjustDateEnd: dateToJson(obj.adjustDateEnd),
})

export const wipAdjustmentFactorFromJson: Project<WIPAdjustmentFactorJson, WIPAdjustmentFactor> = json => ({
    ...json,
    adjustDateStart: dateFromJson(json.adjustDateStart),
    adjustDateEnd: dateFromJson(json.adjustDateEnd),
})


interface Optional extends Omit<JsonOptional, 'adjustDateStart' | 'adjustDateEnd'> {
    adjustDateStart: Date,
    adjustDateEnd: Date,
}


interface JsonOptional {

    customerCodes: string[],
    adjustDateStart: string,
    adjustDateEnd: string,
}


