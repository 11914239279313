import { Card, CardContent, Grid, makeStyles, Tooltip, Typography } from "@material-ui/core"

interface Props {
    typeName: string,
    suggestion: string,
    color?: 'default' | 'select',
    callback: () => void,
    disabled?:boolean
}

export const SuggestionCard = (props: Props) => {
    const { typeName, suggestion, color, callback } = props
    const styles = useStyles()
    return (
        <Card elevation={20} className={styles.root} style={{
            backgroundColor: color === 'select' ? '#0e72ed' : undefined,
            cursor: color === 'select' ? undefined : "pointer"
        }} onClick={callback}>
            <CardContent className={styles.content} style={{ paddingBottom: 0 }}>
                <Grid container direction="column-reverse" alignItems="center" alignContent="center">
                    <Grid item style={{ width: '100%', overflow: 'hidden' }}>
                        <Tooltip title={typeName + ': ' + suggestion}>
                            <Typography
                                variant="subtitle2"
                                style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                className={color ? styles[color] : styles.default}>
                                <strong>{typeName}</strong>: {suggestion}
                            </Typography>
                        </Tooltip>
                    </Grid>
                </Grid>
            </CardContent>
        </Card >
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        paddingBottom: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    content: {
        paddingTop: theme.spacing(1),
        textAlign: "center",
    },
    default: {
        color: theme.palette.common.black,
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    select: {
        color: theme.palette.common.white,
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    }
}))