import { Project } from "../../../utils/Project"
import { ContractPartsResult, ContractPartsResultJson, contractPartsResultToJson } from "../models/ContractPartsResult"

export interface ContractPartsAndPriceFactor {
    flag: number,
    currentPageList: ContractPartsResult[],
}

interface ContractPartsAndPriceFactorJson {
    flag: number,
    currentPageList: ContractPartsResultJson[],
}

export const contractPartsAndPriceFactorToJson: Project<ContractPartsAndPriceFactor, ContractPartsAndPriceFactorJson> = obj => ({
    flag: obj.flag,
    currentPageList: obj.currentPageList.map(contractPartsResultToJson),
})