import { Project } from "@rithe/utils";

export interface OutboundDownloadFactor {
    partsNoList: string[],
    packageNoList: string[],
}

interface OutboundDownloadFactorJson {
    partsNoList: string[],
    packageNoList: string[],
}

export const outboundDownloadFactorToJson: Project<OutboundDownloadFactor, OutboundDownloadFactorJson> = obj => ({
    ...obj
})