import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useGetCargoTrackingSuggestions, useListCargoTrackingDetail } from "../../../services/cargo/api/cargoTackingApi"
import { CargoTackingDetailResult } from "../../../services/cargo/models/CargoTackingDetailResult"
import { SearchConditionResult, Suggestion } from "../../../services/cargo/models/SearchConditionResult"
import { CIS011PcUi } from "./CIS011PcUi"
import { ConditionType } from "./SearchCondition"


export const CIS011 = () => {
    const { orderNo } = useParams() as any
    const [data, setData] = useState<CargoTackingDetailResult>({})
    const [suggestions, setSuggestions] = useState<SearchConditionResult>({})
    const [selections, setSelections] = useState<Suggestion[]>([])
    const getCargoTrackingSuggestions = useGetCargoTrackingSuggestions()
    const getCargoTrackingList = useListCargoTrackingDetail()

    const searchCargoTrackingDetail = useCallback((suggestions: Suggestion[]) => {
        getCargoTrackingList({ suggestions, limit: 15 }, { silent: true, serialized: true }).then(result => {
            setData(result || [])
        })
    }, [getCargoTrackingList])

    const searchSuggestions = useCallback(({ keyword }: { keyword: string }) => {
        getCargoTrackingSuggestions({ keyword, limit: 20 }, { silent: true, serialized: true }).then(result => {
            const suggestions = result || [];
            setSuggestions(suggestions)
            setSelections([])
            setData([])
        })
    }, [getCargoTrackingSuggestions])

    useEffect(() => {
        if (orderNo !== undefined && orderNo !== null) {
            const suggestions = [{ type: ConditionType.ORDER, suggestion: orderNo }]
            setSuggestions({ total: 1, suggestions: suggestions })
            setSelections(suggestions)
            searchCargoTrackingDetail(suggestions)
        }
    }, [orderNo, searchCargoTrackingDetail, searchSuggestions])

    return <CIS011PcUi
        searchCargoTrackingDetail={searchCargoTrackingDetail}
        data={data}
        setData={setData}
        suggestions={suggestions}
        searchSuggestions={searchSuggestions}
        selections={selections}
        setSelections={setSelections}
        orderNo={orderNo}
    />
}