import { Tooltip } from "@material-ui/core"
import { AssignmentOutlined } from "@material-ui/icons"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { useState } from "react"
import { FormattedMessage } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Access } from "../../../components/Access/Access"
import { IconButton } from "../../../components/Button/IconButton"
import { OrderCalcStatus } from "../../../services/order/enums/OrderCalcStatus"
import { ConfirmDialog } from "./ConfirmDialog"
import { ListTableRow } from "./ListTable"
import { occls010Actions, OrderCalculationType } from "./OCCLS010Slice"

export interface PlaceOrderRowActionProps extends DataGridRowActionProps {
  type: OrderCalculationType
}

export function PlaceOrderRowAction(props: PlaceOrderRowActionProps) {
  const { tableRow, type } = props
  const row = tableRow.row as ListTableRow


  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const orderCalcNo = row.orderCalcNo!
  const poGroupId = row.poGroupId!

  const display = (row.status === OrderCalcStatus.PENDING_ORDER
    || row.status === OrderCalcStatus.PENDING_ISSUED
    || row.status === OrderCalcStatus.ORDER_ISSUED)
    && !row.partialPlaceOrder
    && type !== "Ehm"//TODO
  // && row.inCurrentMonth => remove first TODO

  const navigate = useNavigate()
  if (!display) {
    return null
  } else if (row.orderVersion === 1) {
    if (row.status === OrderCalcStatus.PENDING_ORDER) {
      // place regular order
      return <>
        <Access access="STCK.OCCLS010.CONFRIM">
          <Tooltip title={<FormattedMessage id="PlaceOrder" />}>
            <IconButton onClick={() => setOpen(true)}>
              <AssignmentOutlined />
            </IconButton>
          </Tooltip>
        </Access>
        <ConfirmDialog
          open={open}
          onClose={() => setOpen(false)}
          title={<FormattedMessage id="confirm" />}
          content={"Place Order will be generated after submission. Are you sure to submit?"}
          cancelButtonLabel={"Cancel"}
          confirmButtonLabel={<FormattedMessage id="confirm" />}
          onConfirm={() => {
            setOpen(false)
            if (type === 'PNA') {
              dispatch(occls010Actions.placeOrderPNA({
                data: { orderCalcNo },
                callback: (poGroupId) => navigate(`/orderCalculationPNA/placeOrderRegular-${poGroupId}`)
              }))
            } else {
              dispatch(occls010Actions.placeOrder({
                data: { orderCalcNo },
                callback: (poGroupId) => navigate(`/orderCalculation/placeOrderRegular-${poGroupId}`)
              }))
            }
          }}
        />
      </>
    } else if (row.status === OrderCalcStatus.PENDING_ISSUED) {
      // goto regular order
      return <Access access="STCK.OCCLS010.CONFRIM">
        <Tooltip title={<FormattedMessage id="PlaceOrder" />}>
          <IconButton onClick={() => navigate(type === 'PNA'
            ? `/orderCalculationPNA/placeOrderRegular-${poGroupId}`
            //: type === 'Ehm'
            //  ? `/orderCalculationEhm/placeOrderRegular-${poGroupId}`
            : `/orderCalculation/placeOrderRegular-${poGroupId}`)}>
            <AssignmentOutlined />
          </IconButton>
        </Tooltip>
      </Access>
    } else {
      // goto regular order view
      return <Access access="STCK.OCCLS010.CONFRIM">
        <Tooltip title={<FormattedMessage id="PlaceOrder" />}>
          <IconButton onClick={() => navigate(type === 'PNA'
            ? `/orderCalculationPNA/placeOrderRegularView-${poGroupId}`
            //: type === 'Ehm'
            //  ? `/orderCalculationEhm/placeOrderRegularView-${poGroupId}`
            : `/orderCalculation/placeOrderRegularView-${poGroupId}`)}>
            <AssignmentOutlined />
          </IconButton>
        </Tooltip>
      </Access>
    }
  } else {
    if (row.status === OrderCalcStatus.PENDING_ORDER) {
      // goto spot order edit
      return <Access access="STCK.OCCLS010.CONFRIM">
        <Tooltip title={<FormattedMessage id="PlaceOrder" />}>
          <IconButton onClick={() => navigate(type === 'PNA'
            ? `/orderCalculationPNA/editSpot-${encodeURIComponent(orderCalcNo)}`
            //: type === 'Ehm'
            //  ? `/orderCalculationEhm/editSpot-${encodeURIComponent(orderCalcNo)}`
            : `/orderCalculation/editSpot-${encodeURIComponent(orderCalcNo)}`)}>
            <AssignmentOutlined />
          </IconButton>
        </Tooltip>
      </Access>
    } else if (row.status === OrderCalcStatus.PENDING_ISSUED) {
      // goto place spot order
      return <Access access="STCK.OCCLS010.CONFRIM">
        <Tooltip title={<FormattedMessage id="PlaceOrder" />}>
          <IconButton onClick={() => navigate(type === 'PNA'
            ? `/orderCalculationPNA/placeOrderSpot-${poGroupId}`
            //: type === 'Ehm'
            //  ? `/orderCalculationEhm/placeOrderSpot-${poGroupId}`
            : `/orderCalculation/placeOrderSpot-${poGroupId}`)}>
            <AssignmentOutlined />
          </IconButton>
        </Tooltip>
      </Access>
    } else {
      // goto place spot order view
      return <Access access="STCK.OCCLS010.CONFRIM">
        <Tooltip title={<FormattedMessage id="PlaceOrder" />}>
          <IconButton onClick={() => navigate(type === 'PNA'
            ? `/orderCalculationPNA/placeOrderSpotView-${poGroupId}`
            //: type === 'Ehm'
            //  ? `/orderCalculationEhm/placeOrderSpotView-${poGroupId}`
            : `/orderCalculation/placeOrderSpotView-${poGroupId}`)}>
            <AssignmentOutlined />
          </IconButton>
        </Tooltip>
      </Access>
    }
  }
}