import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"

export type TnmSettingCode = Required & Partial<Optional> & TableEntity
type TnmSettingCodeJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const tnmSettingCodeFromJson: Project<TnmSettingCodeJson, TnmSettingCode> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {

}

interface JsonRequired {
    settingCodeId: number,
    settingType: number,
    settingCode: string,
    language: string,
    valueType: number,
    validation: string,
    description: string,
}

interface JsonOptional {
    valueLength: string
}