import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useComMasterGetActiveCustomer } from "../../../services/common/apis/commonMasterApi"
import { useGetContractRouteList } from "../../../services/master/apis/contractRouteApi"
import { ContractRouteFactor, ContractRouteFactorFromJson, contractRouteFactorToJson } from "../../../services/master/models/ContractRouteFactor"
import { ContractRouteResult } from "../../../services/master/models/ContractRouteResult"
import { TnmCustomer } from "../../../services/master/models/TnmCustomer"
import sessionKeys from "../../../utils/sessionKeys"
import { useMatch } from "../../../utils/useMatch"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { MLS100PcUi } from "./MLS100PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }

const searchCachekey = sessionKeys.Filters_MLS100

export const MLS100 = () => {

    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? ContractRouteFactorFromJson(JSON.parse(filters)) : null
    }, [])
    const { path } = useMatch()
    const [filters, setFilters] = useState<ContractRouteFactor>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<ContractRouteResult[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [customerList, setCustomer] = useState<TnmCustomer[]>([])

    const getDataList = useGetContractRouteList()
    const search = useCallback((filters: ContractRouteFactor) => {
        getDataList(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(contractRouteFactorToJson(filters)))
        })
    }, [getDataList, setData])

    useSearchOnLoad(search, filters)

    const getCustomerList = useComMasterGetActiveCustomer()
    useEffect(() => {
        getCustomerList(undefined, { silent: true }).then(result => {
            setCustomer(result || [])
        })
    }, [getCustomerList])

    return <MLS100PcUi
        path={path}
        filters={filters}
        search={search}
        data={data}
        totalCount={totalCount}
        customerList={customerList}
        setFilters={setFilters}
    />
}