import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"

export type SoListResult = Required & Partial<Optional>
export type SoListResultJson = JsonRequired & Partial<JsonOptional>
export const soListResultFromJson: Project<SoListResultJson, SoListResult> = json => ({
    ...json,
    soDate: dateFromJson(json.soDate),
    submittedDate: dateFromJson(json.submittedDate),
    completedDate: dateFromJson(json.completedDate),
    createdDate: dateFromJson(json.createdDate),
    updatedDate: dateFromJson(json.updatedDate),

})

interface Required extends JsonRequired {

}

interface Optional extends Omit<JsonOptional, 'soDate' | 'submittedDate' | 'completedDate' | 'createdDate' | 'updatedDate'> {
    soDate: Date,
    submittedDate: Date,
    completedDate: Date,
    createdDate: Date,
    updatedDate: Date,

}

interface JsonRequired {

}

interface JsonOptional {

    soSpotId: number,
    preCoSpotId: number,
    prePoSpotId: number,
    preSpotId: number,
    orderType: number,
    soType: number,
    buId: number,
    supplierId: number,
    customerId: number,
    customerCode: string,
    customerName: string,
    buyerBuId: number,
    soNo: string,
    inboundDcId: number,
    shipperDcId: number,
    buyerHaveDcFlag: number,
    soSpotIdHaveDc: number,
    shippingMode: number,
    remark: string,
    paymentTermsId: number,
    paymentTermsCode: string,
    termsDescription: string,
    termsType: number,
    termsFromMonth: number,
    termsDays: number,
    termsFromDay: number,
    currency: string,
    currencyDigits: number,
    submittedBy: string,
    spotLevel: number,
    customsFlag: number,
    status: number,
    createdBy: string,
    updatedBy: string,
    version: number,
    soDate: string,
    submittedDate: string,
    completedDate: string,
    createdDate: string,
    updatedDate: string,
}