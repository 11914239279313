import {Project} from "../../../utils/Project"
import {Projects} from "../../../utils/Projects"
import {OrderFrequency} from "../../order/enums/OrderFrequency"
import {OrderType} from "../../order/enums/OrderType"
import {dateFromJson} from "../../utils/deserializer"
import {PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson} from "../../utils/PageViewEntity"
import {dateToJson} from "../../utils/serializer"

export type ExternalOrderDownloadListFactor = Omit<ExternalOrderListFactor, 'page'>
export type ExternalOrderDownloadListFactorJson = Omit<ExternalOrderListFactorJson, 'page'>
export const ExternalOrderDownloadListFactorToJson: Project<ExternalOrderListFactor, ExternalOrderListFactorJson> = obj => ({
    ...obj,
    orderFirstDate: dateToJson(obj.orderFirstDate),
    orderLastDate: dateToJson(obj.orderLastDate),
})


export type ExternalOrderListFactor = Partial<Optional>
export type ExternalOrderListFactorJson = Partial<JsonOptional>
export const ExternalOrderListFactorToJson: Project<ExternalOrderListFactor, ExternalOrderListFactorJson> = obj => ({
    ...obj,
    orderFirstDate: dateToJson(obj.orderFirstDate),
    orderLastDate: dateToJson(obj.orderLastDate),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
})

export const ExternalOrderListFactorFromJson: Project<ExternalOrderListFactorJson, ExternalOrderListFactor> = json => ({
    ...json,
    orderFirstDate: dateFromJson(json.orderFirstDate),
    orderLastDate: dateFromJson(json.orderLastDate),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page),
})

interface Optional extends Omit<JsonOptional, 'orderFirstDate' | 'orderLastDate' | 'page'> {
    orderTypeList: OrderType[],
    orderFrequencyList: OrderFrequency[],
    orderFirstDate: Date,
    orderLastDate: Date,
    page: PageViewEntity
}


interface JsonOptional {
    customerCode: string,
    orderTypeList: number[],
    orderFrequencyList: number[],
    orderFirstDate: string,
    orderLastDate: string,
    page: PageViewEntityJson
}
