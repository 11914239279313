import { Project } from "@rithe/utils"

export type ContractVo = Required & Partial<Optional>
type ContractVoJson = JsonRequired & Partial<JsonOptional>
export const ContractVoToJson: Project<ContractVo, ContractVoJson> = obj => ({
    ...obj,
})

export const ContractVoFromJson: Project<ContractVoJson, ContractVo> = json => ({
    ...json
})


interface Required extends JsonRequired {

}

interface Optional extends JsonOptional {
}

interface JsonRequired {
    buyer: string,
    contractId: number,
    contractNo: string,
    contractType: number,
    description: string,
}

interface JsonOptional {

    customerId?: number,
    sellerBuId?: number,
    buyerBuId?: number,
    supplierId?: number,
}