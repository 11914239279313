import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { Projects } from "../../../utils/Projects"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { SupplierDetail, supplierDetailFromJson, supplierDetailToJson } from "../models/SupplierDetail"
import { SupplierFactor, supplierFactorToJson } from "../models/SupplierFactor"
import { SupplierResult, supplierResultFromJson } from "../models/SupplierResult"
import { TnmSupplier, tnmSupplierFromJson } from "../models/TnmSupplier"



export const useGetSupplierList = () => {
    const url = '/lcbm-system-master-api/api/getSupplierList'
    const payloadProject = useCallback(supplierFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(supplierResultFromJson), [])
    return usePost<SupplierFactor, PageResultEntity<SupplierResult>>(url, payloadProject, resultProject)
}

// Query Supplier Info
export const useGetSupplierDetailForEdit = () => {
    const url = '/lcbm-system-master-api/api/getSupplierDetailForEdit'
    const payloadProject = useCallback((obj: { supplierCode: string }) => obj.supplierCode, [])
    const resultProject = useCallback(supplierDetailFromJson, [])
    return usePost<{ supplierCode: string }, SupplierDetail>(url, payloadProject, resultProject)
}
export const useGetSupplierDetailForView = () => {
    const url = '/lcbm-system-master-api/api/getSupplierDetailForView'
    const payloadProject = useCallback((obj: { supplierCode: string }) => obj.supplierCode, [])
    const resultProject = useCallback(supplierDetailFromJson, [])
    return usePost<{ supplierCode: string }, SupplierDetail>(url, payloadProject, resultProject)
}

// To Temporary Database
export const useUpdateOrInsertSupplierDetail = () => {
    const url = '/lcbm-system-master-api/api/updatedOrInsertSupplierDetail'
    const payloadProject = useCallback(supplierDetailToJson, [])
    // const resultProject = useCallback((supplierId: number) => supplierId, []);
    const resultProject = useCallback(tnmSupplierFromJson, [])
    return usePost<SupplierDetail, TnmSupplier>(url, payloadProject, resultProject)
}

// To Formal Database
export const useSaveAndIssueSupplierInfo = () => {
    const url = '/lcbm-system-master-api/api/saveAndIssueSupplierInfo'
    const payloadProject = useCallback(supplierDetailToJson, [])
    // const resultProject = useCallback((supplierId: number) => supplierId, []);
    const resultProject = useCallback(tnmSupplierFromJson, [])
    return usePost<SupplierDetail, TnmSupplier>(url, payloadProject, resultProject)
}

export const useIssueSupplierInfo = () => {
    const url = '/lcbm-system-master-api/api/issueSupplierInfo'
    const payloadProject = useCallback((obj: { tempSupplierId: number }) => obj.tempSupplierId, [])
    const resultProject = Projects.IGNORE
    return usePost<{ tempSupplierId: number }, undefined>(url, payloadProject, resultProject)
}

export const useWithdrawSupplierInfo = () => {
    const url = '/lcbm-system-master-api/api/withdrawEditSupplier'
    const payloadProject = useCallback((obj: { tempSupplierId: number }) => obj.tempSupplierId, [])
    const resultProject = Projects.IGNORE
    return usePost<{ tempSupplierId: number }, undefined>(url, payloadProject, resultProject)
}
