import { useCallback, useEffect, useMemo, useState } from "react"
import { useComMasterGetAllUom } from "../../../services/common/apis/commonMasterApi"
import { useGetGlobalOartsList } from "../../../services/master/apis/globalPartsApi"
import { GlobalPartsFactor, GlobalPartsFactorFromJson, globalPartsFactorToJson } from "../../../services/master/models/GlobalPartsFactor"
import { GlobalPartsResult } from "../../../services/master/models/GlobalPartsResult"
import { TnmUom } from "../../../services/master/models/TnmUom"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { MLS070PcUi } from "./MLS070PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 10000 } }
const searchCachekey = sessionKeys.Filters_MLS070

export const MLS070 = () => {

    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? GlobalPartsFactorFromJson(JSON.parse(filters)) : null
    }, [])

    const [filters, setFilters] = useState<GlobalPartsFactor>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<GlobalPartsResult[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [uomList, setUom] = useState<TnmUom[]>([])

    const getDataList = useGetGlobalOartsList()
    const search = useCallback((filters: GlobalPartsFactor) => {
        getDataList(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(globalPartsFactorToJson(filters)))
        })
    }, [getDataList, setData])

    useSearchOnLoad(search, filters)

    const getUomList = useComMasterGetAllUom()
    useEffect(() => {
        getUomList(undefined, { silent: true }).then(result => {
            setUom(result || [])
        })
    }, [getUomList])

    return <MLS070PcUi
        filters={filters}
        search={search}
        data={data}
        totalCount={totalCount}
        uomList={uomList}
        setFilters={setFilters}
    />
}