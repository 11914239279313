import { Badge, makeStyles } from "@material-ui/core"
import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, RowActionProvider, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import Records from "@rithe/utils/dist/base/Records"
import React, { useCallback, useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DetailRedirectRowAction } from "../../../components/DataGrid/rowActions/DetailRedirectRowAction"
import { ForecastRedirectRowAction } from "../../../components/DataGrid/rowActions/ForecastRedirectRowAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { ReceivedPlaceChangView } from "../../../services/order/models/ReceivedPlaceChangView"

interface OCS027PcUiProps {
    filters: ReceivedPlaceChangView,
    setFilters: React.Dispatch<React.SetStateAction<ReceivedPlaceChangView>>,
    search: (filters: ReceivedPlaceChangView) => void,
    data: ReceivedPlaceChangView[],
    totalCount: number,
}

export const OCS027PcUi = (props: OCS027PcUiProps) => {

    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>

}

const DataTable = ({ filters, setFilters, data, search }: OCS027PcUiProps) => {
    const intl = useIntl()
    const columns = useMemo(() => [
        { field: 'requestNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.requestNo' }), width: 250 },
        { field: 'requestType', dataTypeName: CodeCategory.RoSpotReviceType, title: intl.formatMessage({ id: 'field.requestType' }), width: 250 },
        { field: 'requestStatus', dataTypeName: CodeCategory.RoSpotStatus, title: intl.formatMessage({ id: 'field.requestStatus' }), width: 250 },
        { field: 'orderType', dataTypeName: CodeCategory.OrderType, title: intl.formatMessage({ id: 'field.orderType' }), width: 250 },
        { field: 'customerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerCode' }), width: 250 },
    ], [intl])
    const getRowId = useCallback((row: any) => row.roSpotId, [])
    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search }), [filters, setFilters, search])

    const actionProps = useMemo(() => ({ search, filters }), [filters, search])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OrderType} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.RoSpotStatus} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.RoSpotReviceType} />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <RowActionProvider name="detail" Action={DetailRowAction} actionProps={actionProps} />
        <RowActionProvider name="placeOrderRequest" Action={ForecastRowAction} actionProps={actionProps} />
        <ColumnFreeze />
        <ColumnVisibility
            columnSettings={{
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Action width={300} />
    </DataGrid>
}

const FilterItem = (props: {
    filters: any,
    search: (filters: any) => void,
    setFilters: React.Dispatch<React.SetStateAction<any>>,
}) => {
    const { filters, search, setFilters } = props

    const clear = useCallback((filters: any) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: any) => {
        return [
            filters.status,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])

    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <CodesItem field="requestStatusList" label={intl.formatMessage({ id: 'status' })} code={CodeCategory.RoSpotStatus} />
    </FilterToolbarItem>
}

const DetailRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/rc/edit-${tableRow.rowId}`, [])
    const styles = useStyles()
    const status = tableRow.row?.pendingUpdateFlag
    const invisible = status === 1
    return <>
        <Badge color='error' variant="dot" invisible={!invisible} classes={{ badge: styles.badge }}>
            <DetailRedirectRowAction tableRow={tableRow} path={path} />
        </Badge>
    </>

}

const ForecastRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const show = tableRow.row?.hasPreId === 1
    const styles = useStyles()
    const status = tableRow.row?.pendingReqeustFlag
    const invisible = status === 1
    const path = useCallback((tableRow: TableRow) => `/rc/modify-${tableRow.rowId}`, [])
    if (show) {
        return <>
            <Badge color='error' variant="dot" invisible={!invisible} classes={{ badge: styles.badge }}>
                <ForecastRedirectRowAction title={<FormattedMessage id="My Request" />} tableRow={tableRow} path={path} />
            </Badge>
        </>
    } else {
        return <></>
    }

}

const useStyles = makeStyles(theme => ({
    badge: {
        transform: 'scale(0.8) translate(-5px, -5px)',
        width: 9,
        height: 9,
        minWidth: 9,
        borderRadius: 8,
        marginTop: 10,
    },
}))

