/* eslint-disable react-hooks/rules-of-hooks */
import { Button, DialogActions, DialogContent } from '@material-ui/core'
import { Column, ColumnFreeze, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from '@rithe/data-grid'
import { Break, DateItem, EntryItem, Form, Message, NumberItem, StringItem } from "@rithe/form"
import { GridItem } from '@rithe/ui'
import { Arrays, Records } from '@rithe/utils'
import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useFunctionStore } from '../../../Root'
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from '../../../components/DataGrid/components/ColumnVisibilityToolbarButton'
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { Pagination } from '../../../components/DataGrid/components/Pagination'
import { SearchInput } from '../../../components/DataGrid/components/SearchInput'
import { ActiveFlagTypeProvider } from '../../../components/DataGrid/typeProviders/ActiveFlagTypeProvider'
import { CodeCategoryTypeProvider } from '../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider'
import { PercentTypeProvider } from '../../../components/DataGrid/typeProviders/PercentTypeProvider'
import { DarkDialog } from '../../../components/Dialog/DarkDialog'
import { DialogAction } from '../../../components/Dialog/DialogAction'
import { DialogHeader } from '../../../components/Dialog/DialogHeader'
import { CodeItem } from '../../../components/Form/CodeItem'
import { SectionTitle } from '../../../components/SectionTitle/SectionTitle'
import { View } from "../../../components/View/View"
import { ScreenMode } from '../../../services/common/enums/ScreenMode'
import { useGetContractDetailHeader, useGetContractDetailListByContractId } from '../../../services/master/apis/contractApi'
import { useRejectRequest } from '../../../services/master/apis/requestApi'
import { useConfirmContractRequest } from '../../../services/master/apis/requestDetailApi'
import { CbdsType } from '../../../services/master/enums/CbdsType'
import { CodeCategory } from '../../../services/master/enums/CodeCategory'
import { ContractRequestStatus } from '../../../services/master/enums/ContractRequestStatus'
import { ContractType } from '../../../services/master/enums/ContractType'
import { DeliveryTypeFlag } from '../../../services/master/enums/DeliveryTypeFlag'
import { ContractHeaderResult } from '../../../services/master/models/ContractHeaderResult'
import { ContractPartsResult } from '../../../services/master/models/ContractPartsResult'
import { RejectRequestFactor } from '../../../services/master/models/RejectRequestFactor'
import { RequestHeaderResult } from '../../../services/master/models/RequestHeaderResult'
import { TnmCurrency } from '../../../services/master/models/TnmCurrency'
import { TnmIncoterms } from '../../../services/master/models/TnmIncoterms'
import { TnmPaymentTerms } from '../../../services/master/models/TnmPaymentTerms'
import { TnmShippingRoute } from '../../../services/master/models/TnmShippingRoute'
import { TnvCbds } from '../../../services/master/models/TnvCbds'
import { useFieldChecker, useFormValidater } from '../../../utils/ValidatorUtils'
import { applicationActions } from "../../Application/applicationSlice"

interface MOS031RemovePcUiProps {
    mode: ScreenMode,
    requesHeaderInfo: RequestHeaderResult,
    paymentTermList: TnmPaymentTerms[],
    cbdsList: TnvCbds[],
    shippingRouteList: TnmShippingRoute[],
    incotermList: TnmIncoterms[],
    currencyList: TnmCurrency[]
}

interface ContractProps {
    partsData: ContractPartsResult[],
    headerData: ContractHeaderResult,
}

export const MOS031TerminatePcUi = (props: MOS031RemovePcUiProps) => {

    const contractId = useMemo(() => props.requesHeaderInfo.contractId, [props.requesHeaderInfo])
    const [partsData, setPartsData] = useState<ContractPartsResult[]>([])
    const [headerData, setHeaderData] = useState<ContractHeaderResult>({} as ContractHeaderResult)

    const getContractParts = useGetContractDetailListByContractId()
    const getContractHeader = useGetContractDetailHeader()

    const search = useCallback((contractId: number) => {
        getContractParts({ contractId: contractId }, { silent: true, serialized: true }).then(result => {
            setPartsData(result ?? [])
        })
        getContractHeader({ contractId: contractId }, { silent: true, serialized: true }).then(result => {
            setHeaderData({ ...result, deliveryTypeFlag: DeliveryTypeFlag.N, } || {} as ContractHeaderResult)
        })
    }, [getContractHeader, getContractParts])

    useEffect(() => {
        if (contractId !== null && contractId !== undefined) {
            search(contractId)
        } else {
            setPartsData([])
            setHeaderData({} as ContractHeaderResult)
        }
    }, [contractId, search])

    return <PCUiView {
        ...{
            ...props,
            partsData,
            headerData,
         } 
    } />
}

const PCUiView = (props: MOS031RemovePcUiProps & ContractProps) => {
    const { mode, requesHeaderInfo } = props
    const intl = useIntl()
    const { status } = requesHeaderInfo
    const actions = useMemo(() => {
        let actions = []
        if (mode !== ScreenMode.VIEW && status === ContractRequestStatus.SUBMITTED) {
            actions.push(<CompleteAction requesHeaderInfo={requesHeaderInfo} />)
        }
        if (requesHeaderInfo?.status === ContractRequestStatus.SUBMITTED) {
            actions.push(<RejectDilog requesHeaderInfo={requesHeaderInfo} />)
        }
        return actions
    }, [mode, status, requesHeaderInfo])
    return (
        <View>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={1}
                    variant='normal'
                    title={intl.formatMessage({ id: 'terminate_contract_title_one' })}
                    subtitle={intl.formatMessage({ id: "add_new_parts_message_one" })}
                    actions={actions}
                />
                <SectionCardContent>
                    <RequesHeaderInfoView headerInfo={requesHeaderInfo} />
                </SectionCardContent>
            </SectionCard>

            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={2}
                    title={intl.formatMessage({ id: 'ContractDetailInformation' })}
                    subtitle='View Detail contract information.'
                />
                <SectionCardContent>
                    <ContractInfoView {...props} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={3}
                    title={intl.formatMessage({ id: 'PartsListInformation' })}
                    subtitle='View parts list information for contract.'
                />
                <SectionCardContent>
                    <ContractPartsInfoView {...props} />
                </SectionCardContent>
            </SectionCard>
        </View >
    )
}

const RequesHeaderInfoView = memo(({ headerInfo }: {
    headerInfo: RequestHeaderResult
}) => {
    const intl = useIntl()
    return <Form data={headerInfo} labelDisplay="block" helperDisplay="tooltip" >
        <StringItem field="requestNo" readonly label={intl.formatMessage({ id: 'field.requestNo' })} />
        <CodeItem field="requestType" readonly label={intl.formatMessage({ id: 'field.requestType' })} code={CodeCategory.ContractRequestType} />
        <CodeItem field="status" readonly label={intl.formatMessage({ id: 'field.status' })} code={CodeCategory.ContractRequestStatus} />

        <StringItem field="requestFromCode" readonly label={intl.formatMessage({ id: 'field.requestFrom' })} />
        <StringItem field="submittedBy" readonly label={intl.formatMessage({ id: 'field.submittedBy' })} />
        <DateItem field="submittedDate" readonly label={intl.formatMessage({ id: 'field.submittedDate' })} />

        <StringItem field="description" readonly label={intl.formatMessage({ id: 'field.description' })} colSpan={2} multiline />
    </Form>
})

const ContractInfoView = memo((props: MOS031RemovePcUiProps & ContractProps) => {
    const intl = useIntl()
    const { headerData, paymentTermList, currencyList, cbdsList, incotermList, shippingRouteList } = props
    const { contractType, orderFrequency } = headerData

    const dcMap: [number, string][] = useMemo(() => cbdsList.filter((m) => m.cbdsType === CbdsType.DC).map((m) => [m.cbdsId, m.cbdsCode]), [cbdsList])
    const buMap: [string, string][] = useMemo(() => cbdsList.filter((m) => m.cbdsType === CbdsType.BU).map((m) => [m.cbdsUid, m.cbdsCode]), [cbdsList])
    const buCustMap: [string, string][] = useMemo(() => cbdsList.filter((m) => m.cbdsType === CbdsType.CUST || m.cbdsType === CbdsType.BU).map(m => [m.cbdsUid, m.cbdsCode]), [cbdsList])
    const dcCustMap: [string, string][] = useMemo(() => cbdsList.filter((m) => m.cbdsType === CbdsType.CUST || m.cbdsType === CbdsType.DC).map(m => [m.cbdsUid, m.cbdsCode]), [cbdsList])
    const incoMap: [number, string][] = useMemo(() => incotermList.map((m) => [m.incotermsId, m.incotermsCode]), [incotermList])
    const paymentMap: [number, string][] = useMemo(() => paymentTermList.map((m) => [m.paymentTermsId, m.paymentTermsCode + '(' + m.description + ')']), [paymentTermList])
    const srMap: [number, string][] = useMemo(() => shippingRouteList.map((m) => [m.srId, m.shippingRouteCode]), [shippingRouteList])

    const showItem1 = contractType === ContractType.CUST_BU || contractType === ContractType.CUST_SUPP
    const showItem2 = contractType === ContractType.BU_SUPP || contractType === ContractType.CUST_SUPP
    if (contractType === undefined) {
        return <></>
    }
    const leadtimeName = orderFrequency === 10 ? 'field.targetleadtimeMonth' : orderFrequency === 20 ? 'field.targetleadtimeWeek' :
        orderFrequency === 30 ? 'field.targetleadtimeBiweek' : orderFrequency === 40 ? 'field.targetleadtimeTenDay' : 'field.targetLeadtime'

    return <Form data={headerData} labelDisplay="block" helperDisplay="tooltip" readonly={true}>
        <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="basicInfo" /></SectionTitle></GridItem>
        <StringItem required field='contractNo' label={intl.formatMessage({ id: 'field.contractNo' })} />
        <StringItem field="sellerCode" required label={intl.formatMessage({ id: 'seller' })} />
        <EntryItem field="consignee" required label={intl.formatMessage({ id: 'consigneeCode' })} entries={buCustMap} />

        <CodeItem field="contractType" required label={intl.formatMessage({ id: 'field.contractType' })} code={CodeCategory.ContractType} />
        <StringItem field="buyerCode" required label={intl.formatMessage({ id: 'buyer' })} />
        <EntryItem field="accountee" required label={intl.formatMessage({ id: 'accounteeCode' })} entries={buCustMap} />

        <CodeItem field="businessType" required label={intl.formatMessage({ id: 'field.businessType' })} code={CodeCategory.BusinessType} />
        {showItem1 && <CodeItem field="orderFrequency" required label={intl.formatMessage({ id: 'field.orderFrequency' })} code={CodeCategory.OrderFrequency} />}
        <EntryItem field="deliveryTo" required label={intl.formatMessage({ id: 'deliveryToCode' })} entries={dcCustMap} />
        <Break />

        {showItem1 && <NumberItem field="forecastNum" required label={intl.formatMessage({ id: 'field.forecastNum' })} />}
        {showItem1 && <NumberItem field="targetLeadtime" required label={intl.formatMessage({ id: leadtimeName })} />}
        <Break />
        {showItem2 && <NumberItem field="confirmOrderLeadtime" required label={intl.formatMessage({ id: 'field.confirmOrderLeadtime' })} />}
        {showItem2 && <NumberItem field="leadtime" required label={intl.formatMessage({ id: 'field.leadtime' })} />}
        <Break />
        {showItem2 && <NumberItem field="deliveryPlanStartDate" required label={intl.formatMessage({ id: 'field.deliveryPlanStartDate' })} />}
        {showItem2 && <NumberItem field="deliveryPlanEndDate" label={intl.formatMessage({ id: 'field.deliveryPlanEndDate' })} />}
        <Break />
        <StringItem field="description" label={intl.formatMessage({ id: 'description' })} colSpan={2} />

        <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="paymentInfo" /></SectionTitle></GridItem>
        <EntryItem field="paymentTermsId" required label={intl.formatMessage({ id: 'field.paymentTermsCode' })} entries={paymentMap} />
        <EntryItem field="currency" required label={intl.formatMessage({ id: 'field.currency' })} entries={currencyList.map((m) => [m.currencyCode, m.currencyCode])} />
        <CodeItem field="priceBasis" label={intl.formatMessage({ id: 'field.priceBasis' })} code={CodeCategory.PriceBasis} />

        <GridItem columnSpan={3} ><SectionTitle><FormattedMessage id="deliveryInfo" /></SectionTitle></GridItem>
        <EntryItem field="receiveDcId" label={intl.formatMessage({ id: 'receiveDcCode' })} entries={dcMap} />
        <EntryItem field="shipperDcId" label={intl.formatMessage({ id: 'receiveShipperCode' })} entries={dcMap} />
        {/* shipperUid */}
        <EntryItem field="srId" label={intl.formatMessage({ id: 'field.shippingRouteCode' })} entries={srMap} />
        <EntryItem field="spotSrId" label={intl.formatMessage({ id: 'spotSR' })} entries={srMap} />
        {showItem1 && <CodeItem field="autoDiFlag" label={intl.formatMessage({ id: 'autoDiFlag' })} code={CodeCategory.AutoDiFlag} />}
        <Break />

        <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="customsInfo" /></SectionTitle></GridItem>
        <CodeItem field="customsFlag" label={intl.formatMessage({ id: 'field.customsFlag' })} code={CodeCategory.CustomsFlag} />
        <EntryItem field="exportCCAgency" label={intl.formatMessage({ id: 'importCCAgency' })} entries={buMap} />
        <EntryItem field="importCCAgency" label={intl.formatMessage({ id: 'exportCCAgency' })} entries={buMap} />
        <CodeItem field="printHscodeFlag" label={intl.formatMessage({ id: 'field.printHscodeFlag' })} code={CodeCategory.HscodeOutputFlag} />

        <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="incotermsInfo" /></SectionTitle></GridItem>
        <EntryItem field="incotermsId" required label={intl.formatMessage({ id: 'field.incotermsCode' })} entries={incoMap} />
        <StringItem field="incotermsPlace" required label={intl.formatMessage({ id: 'field.incotermsPlace' })} />
    </Form>
})


const ContractPartsInfoView = memo(({ headerData, partsData }: ContractProps) => {
    const intl = useIntl()
    const { contractType } = headerData

    const columns = useMemo(() => {
        const partsCategories = [{ key: 'partsFirst', value: intl.formatMessage({ id: 'partsName' }) }]
        const priceCogetories = [{ key: 'priceSecond', value: intl.formatMessage({ id: 'priceName' }) }]
        const packingCogetories = [{ key: 'packingThird', value: intl.formatMessage({ id: 'packingName' }) }]
        const psrtsColumns = [
            { width: 220, field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsNo' }), categories: partsCategories },
            { width: 220, field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), categories: partsCategories },
            { width: 220, field: 'sellerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sellerPartsNo' }), categories: partsCategories },
            { width: 220, field: 'buyerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'buyerPartsNo' }), categories: partsCategories },
            { width: 300, field: 'unitPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsName' }), categories: partsCategories },
            { field: 'activeFlag', dataTypeName: CodeCategory.ActiveFlag, title: intl.formatMessage({ id: 'field.activeFlag' }), width: 180, categories: partsCategories }
        ] as Column[]

        const bucolumns = (contractType === ContractType.CUST_BU || contractType === ContractType.BU_BU) ? [
            { field: 'repackingType', dataTypeName: CodeCategory.RepackingType, title: intl.formatMessage({ id: 'field.repackingType' }), width: 200, categories: partsCategories },
            { field: 'safetyStockDays', dataTypeName: 'number', title: intl.formatMessage({ id: 'safetyStockDays' }), width: 200, categories: partsCategories },
            { field: 'safetyStockPct', dataTypeName: 'percent', title: intl.formatMessage({ id: 'field.safetyStockPct' }), width: 200, categories: partsCategories },
        ] as Column[] : []

        const supplierColumns = (contractType === ContractType.CUST_BU || contractType === ContractType.CUST_SUPP) ? [
            { field: 'fluctuationRatio', dataTypeName: 'percent', title: intl.formatMessage({ id: 'field.fluctuationRatio' }), width: 200, categories: partsCategories },
            { field: 'fcFluctuationRatio', dataTypeName: 'percent', title: intl.formatMessage({ id: 'field.fcFluctuationRatio' }), width: 230, categories: partsCategories },
        ] as Column[] : []

        const priceColumns = [
            { width: 180, categories: priceCogetories, field: 'currentPrice', dataTypeName: 'number', title: intl.formatMessage({ id: 'currentPrice' }) },
            { width: 180, categories: priceCogetories, field: 'currentApplyDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'currentApplyDate' }) },
            { width: 180, categories: priceCogetories, field: 'nextPrice', dataTypeName: 'number', title: intl.formatMessage({ id: 'nextPrice' }) },
            { width: 180, categories: priceCogetories, field: 'nextApplyDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'nextApplyDate' }) },
        ] as Column[]

        const buOrSupplierColumns = (contractType === ContractType.CUST_BU || contractType === ContractType.CUST_SUPP) ? [
            { field: 'endUser1', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.endUser1' }), width: 300, categories: partsCategories },
            { field: 'endUser2', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.endUser2' }), width: 300, categories: partsCategories },
            { field: 'endUser3', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.endUser3' }), width: 300, categories: partsCategories },
            { field: 'endUser4', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.endUser4' }), width: 300, categories: partsCategories },
            { field: 'endUser5', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.endUser5' }), width: 300, categories: partsCategories },
        ] as Column[] : []

        const packingColumns = [
            { width: 180, field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), categories: packingCogetories },
            { width: 180, field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), categories: packingCogetories },
            { width: 180, field: 'uom', dataTypeName: 'string', title: intl.formatMessage({ id: 'buyerUomCode' }), categories: packingCogetories },
            { width: 180, field: 'sellerUomCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'sellerUomCode' }), categories: packingCogetories },
            { width: 180, field: 'uomChangeRate', dataTypeName: 'number', title: intl.formatMessage({ id: 'uomChangeRate' }), categories: packingCogetories },
            { width: 180, field: 'm3', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.m3' }), categories: packingCogetories },
            { width: 180, field: 'length', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.length' }), categories: packingCogetories },
            { width: 180, field: 'width', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.width' }), categories: packingCogetories },
            { width: 180, field: 'height', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.height' }), categories: packingCogetories },
            { width: 180, field: 'netWeight', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.netWeight' }), categories: packingCogetories },
            { width: 180, field: 'grossWeight', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.grossWeight' }), categories: packingCogetories },
        ] as Column[]

        return Arrays.concat(psrtsColumns, bucolumns, supplierColumns, priceColumns, buOrSupplierColumns, packingColumns)
    }, [contractType, intl])

    const getRowId = useCallback((row: any) => row.buId, [])
    return <div style={{ width: '100%', height: 500 }} >
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.DeliveryType} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.RepackingType} />
            <ActiveFlagTypeProvider />
            <PercentTypeProvider />
            <Data rows={partsData} columns={columns} getRowId={getRowId} />
            <ColumnFreeze />
            <ColumnVisibility
                defaultHiddenFields={[]}
                columnSettings={{
                    unitPartsNo: { disableUserControl: true },
                    partsNo: { disableUserControl: true },
                    uom: { disableUserControl: true }
                }} ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
        </DataGrid>
    </div>
})

const CompleteAction = (({ requesHeaderInfo }: {
    requesHeaderInfo: RequestHeaderResult,
}) => {
    const navigate = useNavigate()
    const confirmActive = useConfirmContractRequest()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'complete' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const completeClick = useCallback(() => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            confirmActive({ requestId: requesHeaderInfo.requestId }, { serialized: true }).then(result => {
                navigate('/receivedRequest')
            }).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title]},
            actions:[{
                label: 'CANCEL'
            },{
                functionId,
                label:'CONFIRM',
            }]
        }))
    }, [functionStore, dispatch, title, confirmActive, requesHeaderInfo.requestId, navigate])

    return <>
        <Button onClick={completeClick} variant="contained" style={{ backgroundColor: '#003362', color: '#fff' }} disabled={disabled}><FormattedMessage id="complete" /></Button >
    </>
})


const RejectDilog = ({ requesHeaderInfo }: {
    requesHeaderInfo: RequestHeaderResult,
}) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [remark, setRemark] = useState<RejectRequestFactor>({} as any)
    const [messages, setMessages] = useState<Message[]>([])
    const [open, setOpen] = useState<boolean>(false)
    const callback = useCallback(() => {
        setOpen(true)
    }, [])

    const fields = {
        rejectReason: { labelId: 'field.contractNo', required: true, length: { max: 255 } },
    }
    const filedCheck = useFieldChecker(fields, setMessages)
    const formValidate = useFormValidater(setMessages, fields)
    const rejectRequest = useRejectRequest()
    const functionStore = useFunctionStore()
    const [disabled, setDisabled] = useState<boolean>(false)
    const title = useMemo(() => intl.formatMessage({ id: 'reject' }), [intl])
    const doReject = useCallback(() => {
        if (requesHeaderInfo.requestId) {
            const functionId = functionStore.register(() => {
                setDisabled(true)
                if (formValidate(remark)) {
                    rejectRequest(({ ...remark, requestId: requesHeaderInfo.requestId, requestIdList: [requesHeaderInfo.requestId] }), { serialized: true }).then(result => {
                        navigate('/receivedRequest')
                    }).finally(() => {
                        setDisabled(false)
                    })
                } else {
                    setDisabled(false)
                }
            })
            dispatch(applicationActions.pushWarning({
                title: title,
	            messages: { code: 'c0001', args: [title] },
                actions:[{
                    label: 'CANCEL'
                },{
                    functionId,
                    label:'CONFIRM',
                }]
            }))
        } else {
            dispatch(applicationActions.pushError({ title: { code: 'reject' }, messages: { code: 'w0002' } }))
        }
    }, [dispatch, formValidate, functionStore, navigate, rejectRequest, remark, requesHeaderInfo.requestId, title])
    // do check
    return <>
        <Button onClick={callback} variant="contained" style={{ backgroundColor: '#fff' }}><FormattedMessage id="reject" /></Button >
        <DarkDialog open={open} style={{ height: '600px', overflow: 'hidden' }} maxWidth="sm" fullWidth fullScreen={false} keepMounted={true}>
            <DialogHeader onClose={() => { setRemark({}); setOpen(false) }}>
                <FormattedMessage id="isRequestReject" />
            </DialogHeader>
            <DialogContent>
                <Form data={remark} setData={setRemark} labelDisplay="block" helperDisplay="tooltip" minWidth={300} columnCount={1} messages={messages} ensure={filedCheck}>
                    <StringItem field="rejectReason" label={intl.formatMessage({ id: 'field.rejectReason' })} />
                </Form>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={() => { setRemark({}); setOpen(false) }} />
                <DialogAction title={<FormattedMessage id="reject" />} callback={doReject} disabled={disabled}/>
            </DialogActions>
        </DarkDialog>
    </>
}