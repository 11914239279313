import { makeStyles, Typography } from "@material-ui/core"
import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Row, Searching, Sorting, StringTypeProvider, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { StringFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/StringFormatter"
import { Break, DateItem, EntryItem, Form, StringItem } from "@rithe/form"
import { Arrays, Records } from "@rithe/utils"
import { default as React, memo, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CodeItem } from "../../../components/Form/CodeItem"
import { View } from "../../../components/View/View"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { OrderDetailHead, OrderPartsData } from "../../../services/order/models/OrderDetailResult"




interface OSS021PcUiProps {
    headerInfo: OrderDetailHead,
    partsDetailList: OrderPartsData[],
    receiveDcList: TnvCbds[],
    search: () => void
}

export const OSS021PcUi = (props: OSS021PcUiProps) => {
    const { headerInfo, receiveDcList, partsDetailList, search } = props
    const intl = useIntl()
    return <View>
        <SectionCard>
            <SectionCardHeader
                serialNumber={1}
                title={intl.formatMessage({ id: 'basicInfo' })}
                subtitle={intl.formatMessage({ id: 'basicInfoSubview' })}
            />
            <SectionCardContent>
                <HeaderDetailTable headerInfo={headerInfo} receiveDcList={receiveDcList} />
            </SectionCardContent>
        </SectionCard>
        <SectionCard >
            <SectionCardHeader
                serialNumber={2}
                title={intl.formatMessage({ id: 'shippingPlanTitle' })}
                subtitle={intl.formatMessage({ id: 'shippingPlanSubview' })}
            />
            <SectionCardContent>
                <ShippingPlanTbale partsDetailList={partsDetailList} headerInfo={headerInfo} search={search} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const HeaderDetailTable = memo((props: { headerInfo: OrderDetailHead, receiveDcList: TnvCbds[], }) => {
    const { headerInfo, receiveDcList } = props
    const intl = useIntl()
    const dcMap: [number, string][] = useMemo(() => receiveDcList.filter(f => f.cbdsType === CbdsType.DC).map(m => [m.cbdsId, m.cbdsCode]), [receiveDcList])

    return <Form readonly data={headerInfo} labelDisplay="block" helperDisplay="tooltip" >
        <StringItem field="orderNo" label={intl.formatMessage({ id: 'salesOrderNo' })} />
        <CodeItem field="orderType" label={intl.formatMessage({ id: 'field.orderType' })} code={CodeCategory.OrderType} />
        <CodeItem field="spotStatus" label={intl.formatMessage({ id: 'field.status' })} code={CodeCategory.SoSpotStatus} />
        <EntryItem field="receiveDcId" label={intl.formatMessage({ id: 'receiver' })} entries={dcMap} />
        <CodeItem field="shippingMode" label={intl.formatMessage({ id: 'field.shippingMode' })} code={CodeCategory.ShippingMode} />
        <DateItem field="receivedDate" label={intl.formatMessage({ id: 'field.soDate' })} />
        <Break />
        <StringItem colSpan={2} field="paymentTermsDesc" label={intl.formatMessage({ id: 'field.paymentTermsDesc' })} multiline />
        <DateItem field="completedDate" label={intl.formatMessage({ id: 'field.completedDate' })} />
        <Break />
        <StringItem colSpan={2} field="remark" label={intl.formatMessage({ id: 'field.remark' })} multiline />
    </Form>
})


const ShippingPlanTbale = memo((props: { headerInfo: OrderDetailHead, partsDetailList: OrderPartsData[], search: () => void }) => {
    const { partsDetailList, headerInfo, } = props
    const intl = useIntl()

    const { currentDate } = headerInfo
    const palnDetas = useMemo(() => partsDetailList[0]?.inboundPlanList ?? [], [partsDetailList])
    const estimatedDatas = useMemo(() => partsDetailList[0]?.estimatedInboundList ?? [], [partsDetailList])

    const [order, setOrder] = useState<string[]>([])
    const columns = useMemo(() => {
        const fixedColumns = [
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
            { field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsNo' }), width: 200 },
            { field: 'purchaseOrderNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'purchaseOrderNo' }), width: 300 },
            { field: 'customerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'customer' }), width: 200 },
            { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 100 },
            { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 150 },
            { field: 'orderQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderQty' }), width: 150 },
            { field: 'unitPrice', dataTypeName: 'number', title: intl.formatMessage({ id: 'unitPrice' }), width: 150 },
            { field: 'currency', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.currency' }), width: 120 },
            { field: 'spotDetailStatus', dataTypeName: CodeCategory.SoSpotDetailStatus, title: intl.formatMessage({ id: 'field.status' }), width: 220 },
        ]
        //Inbounded Qty(Current Date) Column
        const iCurrentDataCategories = [{ key: 'icurrent', value: `${intl.formatMessage({ id: 'currentDate' })}(${intl.formatTime(currentDate, { dateStyle: 'medium' })})` }]
        const iCurrentDateColumns = [
            { field: 'currentDeliveryedQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'currentDeliveryedQty' }), categories: iCurrentDataCategories, width: 180 },
            { field: 'inTransitQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'inTransitQty' }), categories: iCurrentDataCategories, width: 180 },
            { field: 'receiverInbQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'receiverInbQty' }), categories: iCurrentDataCategories, width: 250 },
        ]

        //Receiver Inbound Plan Column
        const getInboundPlanCategories = (index: number) => [
            { key: 'plan', value: intl.formatMessage({ id: 'receiverInboundPlan' }) },
            { key: `plan${index}`, value: intl.formatDate(palnDetas[index].crdDate, { dateStyle: 'medium' }) }
        ]
        const inboundPlanColumns = palnDetas.flatMap((_, index) => {
            return [{ field: 'crdQty' + index, dataTypeName: 'number', title: intl.formatMessage({ id: 'plan' }), categories: getInboundPlanCategories(index), width: 150, getCellValue: getPlanDataValueByName(index, 'crdQty') },
            { field: 'status' + index, dataTypeName: 'status', title: intl.formatMessage({ id: 'field.status' }), categories: getInboundPlanCategories(index), width: 150, getCellValue: getPlanDataValueByName(index, 'status') }]
        })

        // Estimated Inbound Date Column   
        const getEstimatedCategories = (index: number) => [
            { key: 'estimate', value: intl.formatMessage({ id: 'estimatedInboundDate' }) },
            { key: `estimate${index}`, value: intl.formatTime(estimatedDatas[index].estimatedCrdDate, { dateStyle: 'medium' }) },
        ]
        let estimatedInboundColums = Arrays.range(0, estimatedDatas.length).map(index => {
            return { field: `qty${index}`, dataTypeName: 'number', title: estimatedDatas[index].companyTitle ?? '', categories: getEstimatedCategories(index), width: 220, getCellValue: getEstimatedValue(index) }
        })

        // all columns
        return Arrays.concat(fixedColumns, iCurrentDateColumns, inboundPlanColumns, estimatedInboundColums)
    }, [currentDate, estimatedDatas, intl, palnDetas])

    useEffect(() => {
        setOrder(columns.map(column => column.field))
    }, [columns])

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <StatusTypeProvider />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.SoSpotDetailStatus} />
            <Data rows={partsDetailList} columns={columns} />
            <ColumnFreeze />
            <ColumnVisibility columnSettings={{
                partsNo: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnOrdering order={order} onOrderChange={setOrder} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
        </DataGrid>
    </div>
})

const CheckFormatter = ({ value }: StringFormatterProps) => {
    const style = useStyles()
    const bgcolor = 'OK' === value ? '#00CCAD' : '#D94C00'
    return <div className={style.status} style={{ background: bgcolor, color: 'white', width: '100%' }}>
        <Typography variant="body2" >{value}</Typography>
    </div>
}

const StatusTypeProvider = () => {
    return <StringTypeProvider name="status" Formatter={CheckFormatter} />
}

const getEstimatedValue = (index: number) => {
    return (row: Row) => {
        return row.estimatedInboundList[index].qty
    }
}

const getPlanDataValueByName = (index: number, columnName: string) => {
    return (row: Row) => {
        return row.inboundPlanList[index][columnName]
    }
}


const useStyles = makeStyles(theme => ({
    status: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70%',
        paddingRight: theme.spacing(1),
        borderRadius: 5
    },
    default: {},
}))

