import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { dateToJson } from "../../utils/serializer"
import { TableEntity, tableEntityFromJson, TableEntityJson, tableEntityToJson } from "../../utils/TableEntity"
import { ContractRequestType } from "../enums/ContaractRequestType"
import { ContractRequestStatus } from "../enums/ContractRequestStatus"

export type RequestResult = Required & Partial<Optional> & TableEntity
type RequestResultJson = JsonRequired & Partial<JsonOptional> & TableEntityJson

export const requestResultToJson: Project<RequestResult, RequestResultJson> = obj => ({
    ...tableEntityToJson(obj),
    requestDate: dateToJson(obj.requestDate),
    submittedDate: dateToJson(obj.submittedDate),
})

export const requestResultFromJson: Project<RequestResultJson, RequestResult> = json => ({
    ...tableEntityFromJson(json),
    requestDate: dateFromJson(json.requestDate),
    submittedDate: dateFromJson(json.submittedDate),
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'requestDate' | 'submittedDate'> {
    requestType: ContractRequestType,
    status: ContractRequestStatus,
    requestDate: Date,
    submittedDate: Date,
}

interface JsonRequired {
}

interface JsonOptional {
    requestId: number,
    requestNo: string,
    description: string,
    requestType: number,
    requestFrom: string,
    requestTo: string,
    requestBy: string,
    requestDate: string,
    submittedBy: string,
    submittedDate: string,
    status: number,
    reject: string,
}



