import { useCallback } from "react"
import { IntlString } from "../../../utils/IntlString"
import { Projects } from "../../../utils/Projects"
import { useDownload, usePost, useUpload } from "../../../utils/fetch/useFetch"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { CarrierEntity } from "../models/CarrierEntity"
import { editShippingDetailToJson } from "../models/EditShippingDetail"
import { ShippingDetailEntity, shippingDetailEntityFromJson } from "../models/ShippingDetailEntity"
import { ShippingDetailFactor, shippingDetailFactorToJson } from "../models/ShippingDetailFactor"
import { ShippingDetailView, shippingDetailViewFromJson } from "../models/ShippingDetailView"

export const useListShippingDetail = () => {
    const url = '/lcbm-shipping-detail-api/api/listShippingDetail'
    const payloadProject = useCallback(shippingDetailFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(shippingDetailViewFromJson), [])
    return usePost<ShippingDetailFactor, PageResultEntity<ShippingDetailView>>(url, payloadProject, resultProject)
}

export const useUploadShippingDetail = () => {
    const url = '/lcbm-shipping-detail-api/api/uploadShippingDetails'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

export const useDownloadShippingDetail = () => {
    const url = '/lcbm-shipping-detail-api/api/downloadShippingDetails'
    const payloadProject = useCallback((obj: { shippingDetailIds: number[] }) => obj.shippingDetailIds, [])
    return useDownload<{ shippingDetailIds: number[] }>(url, payloadProject, { fileTimestamp: true })
}

export const useGetShippingDetail = () => {
    const url = '/lcbm-shipping-detail-api/api/viewShippingDetail'
    const payloadProject = useCallback(Projects.KEEP, [])
    const resultProject = useCallback(shippingDetailEntityFromJson, [])
    return usePost<number, ShippingDetailEntity>(url, payloadProject, resultProject)
}

export const useEditShippingDetail = () => {
    const url = '/lcbm-shipping-detail-api/api/editShippingDetail'
    const payloadProject = useCallback(editShippingDetailToJson, [])
    const resultProject = Projects.IGNORE
    return usePost<ShippingDetailEntity, undefined>(url, payloadProject, resultProject)
}

export const useConfirmShippingDetail = () => {
    const url = '/lcbm-shipping-detail-api/api/confirmShippingDetail'
    const payloadProject = useCallback(editShippingDetailToJson, [])
    const resultProject = useCallback((rs: IntlString[]) => rs, [])
    return usePost<ShippingDetailEntity, IntlString[]>(url, payloadProject, resultProject)
}

export const useUpdateCargoStatus = () => {
    const url = `/lcbm-shipping-detail-api/api/updateCargoStatus`;
    const payloadProject = Projects.KEEP;
    const resultProject = Projects.IGNORE;
    return usePost<{ shippingDetailId: string, cargoStatus?: string, status?: string }, undefined>(url, payloadProject, resultProject);
}

export const useListAllCarriers = () => {
    const url = '/lcbm-shipping-detail-api/api/listAllCarriers'
    const payloadProject = Projects.IGNORE
    const resultProject = Projects.KEEP as any
    return usePost<undefined, CarrierEntity[]>(url, payloadProject, resultProject)
}

export const useViaportcast = () => {
    const url = `/lcbm-shipping-detail-api/api/shippingdetail/viaportcast`;
    const payloadProject = useCallback((obj: { shippingDetailIds: number[] }) => obj.shippingDetailIds, [])
    const resultProject = Projects.IGNORE;
    return usePost<{ shippingDetailIds: number[] }, undefined>(url, payloadProject, resultProject)
}

export const useCheckShippingDetailConfirm = () => {
    const url = '/lcbm-shipping-detail-api/api/shippingdetail/checkAllConfirm'
    const payloadProject = useCallback((obj: { orderNo: string }) => obj, [])
    const resultProject = useCallback(Boolean, [])
    return useUpload<{ orderNo: string }, Boolean>(url, payloadProject, resultProject)
}