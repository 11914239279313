import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { CoStatus } from "../enums/CoStatus"
import { RequestStatus } from "../enums/RequestStatus"
import { RequestType } from "../enums/RequestType"

export type ChangeRequestListResult = ChangeRequestResultRequired & Partial<ChangeRequestResultOptional>
type ChangeRequestListResultJson = ChangeRequestResultJsonRequired & Partial<ChangeRequestResultJsonOptional>

export const changeRequestListResultFromJson: Project<ChangeRequestListResultJson, ChangeRequestListResult> = json => ({
    ...json,
    coDate: dateFromJson(json.coDate),
    orderFirstDate: dateFromJson(json.orderFirstDate),
    orderLastDate: dateFromJson(json.orderLastDate),
    targetFirstDate: dateFromJson(json.targetFirstDate),
    targetLastDate: dateFromJson(json.targetLastDate),
    combinedDate: dateFromJson(json.combinedDate),
    completedDate: dateFromJson(json.completedDate)
})

interface ChangeRequestResultRequired extends ChangeRequestResultJsonRequired {
    status: CoStatus,

}

interface ChangeRequestResultOptional extends Omit<ChangeRequestResultJsonOptional, 'coDate' | 'orderFirstDate' | 'orderLastDate' | 'targetFirstDate' | 'targetLastDate' | 'combinedDate' | 'completedDate' | 'reviseType' | 'roStatus'> {
    coDate: Date,
    orderFirstDate: Date,
    orderLastDate: Date,
    targetFirstDate: Date,
    targetLastDate: Date,
    combinedDate: Date,
    completedDate: Date,
    reviseType: RequestType,
    roStatus: RequestStatus
}

interface ChangeRequestResultJsonRequired {
    coId: number,
    customerOrderNo: string,
    contractNo: string,
    contrRouteCode: string,
    status: number,
}

interface ChangeRequestResultJsonOptional {
    customerRefNo: string,
    businessType: number,
    orderFrequency: number,
    orderType: number,
    orderVersion: number,
    spotOrderReason: string,
    coDate: string,
    orderFirstDate: string,
    orderLastDate: string,
    targetFirstDate: string,
    targetLastDate: string,
    shippingMode: number,
    remark: string,
    combinedDate: string,
    completedDate: string,
    sellerCode: string,
    dcCode: string,
    roId: number,
    reviseNo: string,
    reviseType: number,
    roStatus: number,
    roVersion: number,
}

