export enum TabId {
    DAS010,
    DAS012,
    DAS013,
    DAS014,
    DAS015,
    DAS000,
}

export interface ViewTabInfo {
    access: string,
    tabId: TabId,
    label: string,
    navigation: string,
}

export const ViewTabList: ViewTabInfo[] = [
    {
        access: "DASH.DAS010.VIEW",
        tabId: TabId.DAS010,
        label: "pomanagement",
        navigation: "/dashboard/pomanagement",
    }, {
        access: "DASH.DAS012.VIEW",
        tabId: TabId.DAS012,
        label: "costsMonitoring",
        navigation: "/dashboard/costs",
    }, {
        access: "DASH.DAS013.VIEW",
        tabId: TabId.DAS013,
        label: "vendorAnalysis",
        navigation: "/dashboard/vendorAnalysis",
    }, {
        access: "DASH.DAS014.VIEW",
        tabId: TabId.DAS014,
        label: "inventory",
        navigation: "/dashboard/inventory",
    }, {
        access: "DASH.DAS015.VIEW",
        tabId: TabId.DAS015,
        label: "acctReceivable",
        navigation: "/dashboard/acctReceivable",
    }
]