import { Arrays, Objects } from "@rithe/utils"
import { useCallback, useState } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { useGetSpotOrderFormInfoList } from "../../../services/smt/api/OrderCalculationApi"
import { OCPlaceOrderDetailFactor } from "../../../services/smt/models/OCPlaceOrderDetailFactor"
import { OCPartsDetail, OCPlaceRegularOrderResult } from "../../../services/smt/models/OCPlaceOrderDetailResult"
import { today } from "../../../utils/ApplicationUtils"
import { formatDateRange } from "../../../utils/formatDateRange"
import { useMatch } from "../../../utils/useMatch"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { OCCLS017PNAPcUi } from "./OCCLS017PNAPcUi"

export interface PlaceOrderBasic extends OCPlaceRegularOrderResult {
    totalQty: number,
    totalAmount: number,
    currency?: string | null,
    totalNumberOfParts: number,
    deliveryPlanRange: string,
}

export const OCCLS017PNA = (_props: any) => {
    const { poGroupId } = useParams()
    const { path } = useMatch()

    const mode = path === '/orderCalculationPNA/placeOrderSpot-:poGroupId' ? ScreenMode.EDIT : ScreenMode.VIEW

    const getSpotOrderFormInfoList = useGetSpotOrderFormInfoList()
    const [basic, setBasic] = useState<PlaceOrderBasic[]>([])
    const [forecasts, setForecasts] = useState<OCPartsDetail[]>([] as OCPartsDetail[])
    const [inboundPlans, setInboundPlans] = useState<OCPartsDetail[]>([] as OCPartsDetail[])

    const [targetFirstDate, setTargetFirstDate] = useState<Date>(() => today())
    const [targetLastDate, setTargetLastDate] = useState<Date>(() => today())

    const splitDatas = useSplitDatas()
    const refresh = useCallback((poGroupId: string | null | undefined, silent: boolean) => {
        if (poGroupId) {
            getSpotOrderFormInfoList({ poGroupId: poGroupId }, { silent: silent, serialized: true }).then(result => {
                if (result) {
                    const { orderbasic, orderforecasts, orderInboundPlans } = splitDatas(result)
                    setBasic(orderbasic)
                    setForecasts(orderforecasts)
                    setInboundPlans(orderInboundPlans)
                    if (orderbasic.length > 0) {
                        setTargetFirstDate(orderbasic[0].targetFirstDate)
                        setTargetLastDate(orderbasic[0].targetLastDate)
                    }
                }
            })

        }
    }, [getSpotOrderFormInfoList, splitDatas])

    const search = useCallback((poGroupId: string | null | undefined) => {
        refresh(poGroupId, true)
    }, [refresh])

    useSearchOnLoad(search, poGroupId)

    return <OCCLS017PNAPcUi
        mode={mode}
        factor={{} as OCPlaceOrderDetailFactor}
        basic={basic}
        setBasic={setBasic}
        forecasts={forecasts}
        setForecasts={setForecasts}
        inboundPlans={inboundPlans}
        setInboundPlans={setInboundPlans}
        targetFirstDate={targetFirstDate}
        targetLastDate={targetLastDate}
        refresh={refresh}
        poGroupId={poGroupId ?? ''}
    />
}

export const useSplitDatas = () => {
    const intl = useIntl()
    return useCallback((dataArr: OCPlaceRegularOrderResult[]) => {
        const orderbasicArr = dataArr.map((data, index) => {
            const { partsDetailList } = data
            const totalQty = partsDetailList.reduce((v1, v2) => v1 + (v2.firmQty ? v2.firmQty : 0), 0);
            const totalAmount = partsDetailList.reduce((v1, v2) => v1 + (v2.firmQty && v2.price ? v2.firmQty * v2.price : 0), 0);
            const totalNumberOfParts = partsDetailList.length;
            const planDates = Arrays.distinct(partsDetailList.flatMap(parts => parts.inbPlanList).map(plan => plan.crdDate.getTime())).map(m => new Date(m))
            const deliveryPlanRange = planDates.length > 0 ? formatDateRange(intl, planDates[0], planDates[planDates.length - 1]) : ''
            return { ...data, totalQty, totalAmount, totalNumberOfParts, deliveryPlanRange }
        })

        const allPartsDetailList = orderbasicArr.flatMap(f => f.partsDetailList)
        const orderforecasts: OCPartsDetail[] = allPartsDetailList.map(parts => ({ ...parts }))
        const orderInboundPlans: OCPartsDetail[] = allPartsDetailList.map(parts => ({ ...parts }))

        return { orderbasic: orderbasicArr, orderforecasts: orderforecasts, orderInboundPlans: orderInboundPlans }
    }, [intl])
}

export const useMergeDatas = () => {
    return useCallback((orderbasics: PlaceOrderBasic[], orderforecasts: OCPartsDetail[], orderInboundPlans: OCPartsDetail[]) => {
        const newOrderBasics = orderbasics.map((orderbasic) => {
            const basic = Objects.delete(orderbasic, 'totalQty', 'totalAmount', 'totalNumberOfParts', 'deliveryPlanRange', 'currency') as OCPlaceRegularOrderResult
            const partsDetailList: OCPartsDetail[] = orderInboundPlans.filter(f => f.orderCalcPoId === orderbasic.orderCalcPoId).map((parts) => {
                const index = orderforecasts.findIndex(f2 => f2.orderCalcPoDetailId === parts.orderCalcPoDetailId)
                const { fluctuationReason, firmQty, fluctuationRatio, forecastList } = orderforecasts[index]
                return { ...parts, firmQty: firmQty, fluctuationRatio: fluctuationRatio, forecastList: forecastList, fluctuationReason: fluctuationReason }
            })
            return { ...basic, partsDetailList: [...partsDetailList] }
        })
        return newOrderBasics
    }, [])
}