import React, { createContext, ReactNode, useContext, useMemo, useState } from "react";

interface Context {
    selectedValue: string | number,
    setSelectedValue: (tabId: string | number) => void,
}

const TabContext = createContext<Context>(undefined as any)

export const useTabContext = () => {
    return useContext(TabContext)
}

export const TabContextProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
    const [selectedValue, setSelectedValue] = useState<string | number>('')

    const value = useMemo(() => ({
        selectedValue,
        setSelectedValue,
    }), [selectedValue])

    return <TabContext.Provider value={value}>
        {children}
    </TabContext.Provider>
}