import { makeStyles, Typography } from "@material-ui/core";
import { ReactNode } from "react";

interface SimpleCardHeaderProps {
    title: string,
    subtitle?: string,
    actions?: ReactNode[],
}

export function SimpleCardHeader(props: SimpleCardHeaderProps) {
    const { title, subtitle, actions } = props
    const styles = useStyles()
    return <div className={styles.root}>
        <div className={styles.content}>
            <Typography variant="h3" color="textPrimary" className={styles.title}>
                {title}
            </Typography>
            {subtitle && <Typography variant="body1" color="textSecondary">{subtitle}</Typography>}
        </div>
        <div>
            {actions}
        </div>
    </div>
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: theme.spacing(1),
        flex: '0 0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'stretch',
    },
    content: {
        flex: '1 1 auto',
        overflow: 'hidden',
    },
    title: {
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    actions: {
        flex: '0 0 auto',
    },
}))