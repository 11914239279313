import React from 'react'
import { FormattedMessage } from 'react-intl'
import { CallbackViewAction } from './CallbackViewAction'

interface DownloadCallbackViewActionProps {
    access?: string,
    disabled?: boolean,
    outlined?: boolean,
    callback: () => void,
}

export const DownloadCallbackViewAction = (props: DownloadCallbackViewActionProps) => {
    return <CallbackViewAction {...props} title={<FormattedMessage id="download" />} />
}