import { Project } from "@rithe/utils";

export interface DeleteCargoStatusSetting {
    cargoStatusSettingId: number,
}

interface DeleteCargoStatusSettingJson {
    cargoStatusSettingId: number,
}

export const deleteCargoStatusSettingToJson: Project<DeleteCargoStatusSetting, DeleteCargoStatusSettingJson> = data => data
