import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, RowActionProvider, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { StringItem } from "@rithe/form"
import { Records } from "@rithe/utils"
import React, { useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useFunctionStore } from "../../../Root"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DeleteCallbackRowAction } from "../../../components/DataGrid/rowActions/DeleteCallbackRowAction"
import { EditRedirectRowAction } from "../../../components/DataGrid/rowActions/EditRedirectRowAction"
import { ViewRedirectRowAction } from "../../../components/DataGrid/rowActions/ViewRedirectRowAction"
import { CreateRedirectToolbarAction } from "../../../components/DataGrid/toolbarActions/CreateRedirectToolbarAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { SmtActiveFlag } from "../../../services/master/enums/SmtActiveFlag"
import { useDeleteProject } from "../../../services/smt/api/ProjectApi"
import { ProjectFactor } from "../../../services/smt/models/ProjectFactor"
import { ProjectResult } from "../../../services/smt/models/ProjectResult"
import { useGetCompanyType } from "../../../utils/ApplicationUtils"
import { applicationActions } from "../../Application/applicationSlice"

interface CSUGS130PcUiProps {
  filters: ProjectFactor
  setFilters: React.Dispatch<React.SetStateAction<ProjectFactor>>
  data: ProjectResult[]
  search: (filters: ProjectFactor) => void
}

export const CSUGS130PcUi = (props: CSUGS130PcUiProps) => {
  return <View flex>
    <SectionCard>
      <SectionCardContent>
        <DataTable {...props} />
      </SectionCardContent>
    </SectionCard>
  </View>
}


const DataTable = ({ filters, setFilters, data, search }: CSUGS130PcUiProps) => {
  const intl = useIntl()
  const cbdsType = useGetCompanyType()

  const columns = useMemo(() =>
    cbdsType === CbdsType.BU ?
      [
        { field: "projectName", dataTypeName: "string", title: intl.formatMessage({ id: "projectName" }), width: 180 },
        { field: "projectCode", dataTypeName: "string", title: intl.formatMessage({ id: "projectCode" }), width: 180 },
        { field: "customerCode", dataTypeName: "string", title: intl.formatMessage({ id: "buyer" }), width: 180 },
        { field: "remarks", dataTypeName: "string", title: intl.formatMessage({ id: "remarks" }), width: 300 },
        { field: "activeFlag", dataTypeName: CodeCategory.SmtActiveFlag, title: intl.formatMessage({ id: "field.status" }), width: 200 },
      ] : [
        { field: "projectName", dataTypeName: "string", title: intl.formatMessage({ id: "projectName" }), width: 180 },
        { field: "projectCode", dataTypeName: "string", title: intl.formatMessage({ id: "projectCode" }), width: 180 },
        { field: "sellerCode", dataTypeName: "string", title: intl.formatMessage({ id: "seller" }), width: 180 },
        { field: "remarks", dataTypeName: "string", title: intl.formatMessage({ id: "remarks" }), width: 300 },
        { field: "activeFlag", dataTypeName: CodeCategory.SmtActiveFlag, title: intl.formatMessage({ id: "field.status" }), width: 200 },
      ], [cbdsType, intl])

  const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search }), [filters, setFilters, search,])
  const actionProps = useMemo(() => ({ filters, search }), [filters, search])
  const getRowId = useCallback((row: any) => row.projectId, [])

  return <DataGrid>
    <ToolbarLayout />
    <TableLayout Container={FlexScrollbar}>
      <TableHeaderLayout sticky />
      <TableBodyLayout />
    </TableLayout>
    <PaginationLayout Pagination={Pagination} />
    <DataTypePreset />
    <CodeCategoryTypeProvider codeCategory={CodeCategory.SmtActiveFlag} />
    <Data rows={data} columns={columns} getRowId={getRowId} />
    <ToolbarActionProvider Action={CreateAction} />
    <RowActionProvider name="view" Action={ViewRowAction} />
    <RowActionProvider name="edit" Action={EditRowAction} />
    <RowActionProvider name="delete" Action={DeleteRowAction} actionProps={actionProps} />
    <ColumnFreeze />
    <ColumnVisibility
      defaultHiddenFields={[]}
      columnSettings={{
        companyCode: { disableUserControl: true },
        companyName: { disableUserControl: true },
      }} ToolbarButton={ColumnVisibilityToolbarButton} />
    <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
    <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
    <Searching ignoreCase Input={SearchInput} />
    <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
    <Sorting />
    <Filtering />
    <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
    {/* <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} /> */}
    <Action width={160} />
  </DataGrid>
}

const FilterItem = (props: {
  filters: ProjectFactor
  setFilters: React.Dispatch<React.SetStateAction<ProjectFactor>>
  search: (filters: ProjectFactor) => void
}) => {
  const { filters, setFilters, search } = props

  const clear = useCallback((filters: ProjectFactor) => {
    return { page: filters.page }
  }, [])

  const filterCounter = useCallback((filters: ProjectFactor) => {
    return [
      filters.projectName,
      filters.remarks,
      filters.statusList
    ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
  }, [])
  const intl = useIntl()

  return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
    <StringItem field="projectName" label={intl.formatMessage({ id: 'projectName' })} />
    <StringItem field="remarks" label={intl.formatMessage({ id: 'remarks' })} />
    <CodesItem field="statusList" label={intl.formatMessage({ id: 'field.activeFlag' })} code={CodeCategory.SmtActiveFlag} />
  </FilterToolbarItem>
}


const CreateAction = () => {
  return <CreateRedirectToolbarAction access="STCK.CSUGS130.CREATE" title={<FormattedMessage id="createProject" />} path="/project/create" />
}

const ViewRowAction = ({ tableRow }: DataGridRowActionProps) => {
  const path = useCallback((tableRow: TableRow) => `/project/view-${tableRow.row?.projectId}`, [])
  return <ViewRedirectRowAction tableRow={tableRow} path={path} />
}

const EditRowAction = ({ tableRow }: DataGridRowActionProps) => {
  const path = useCallback((tableRow: TableRow) => `/project/modify-${tableRow.row?.projectId}`, [])
  return <>{tableRow.row?.activeFlag === SmtActiveFlag.ACTIVE && < EditRedirectRowAction tableRow={tableRow} access="STCK.CSUGS130.EDIT" path={path} />}</>
}

const DeleteRowAction = ({ tableRow, filters, search }: DataGridRowActionProps & { filters: ProjectFactor, search: (filters: ProjectFactor) => void }) => {
  const deleteMethod = useDeleteProject()
  const dispatch = useDispatch()
  const intl = useIntl()
  const functionStore = useFunctionStore()
  const title = useMemo(() => intl.formatMessage({ id: 'delete' }), [intl])
  const [disabled, setDisabled] = useState<boolean>(false)
  const callback = useCallback((tableRow: TableRow) => {
    const functionId = functionStore.register(() => {
      setDisabled(true)
      deleteMethod({ projectId: tableRow.row?.projectId }, { silent: true, serialized: true }).then(() => {
        search(filters)
      }).finally(() => {
        setDisabled(false)
      })
    })
    dispatch(applicationActions.pushWarning({
      title: title,
      messages: { code: 'c0001', args: [title] },
      actions: [{
        label: 'CANCEL'
      }, {
        functionId,
        label: 'CONFIRM',
      }]
    }))
  }, [deleteMethod, dispatch, filters, functionStore, search, title])
  return <>{tableRow.row?.activeFlag === SmtActiveFlag.ACTIVE && <DeleteCallbackRowAction access="STCK.CSUGS130.DELETE" tableRow={tableRow} callback={callback} disabled={disabled}
  />}</>
}
