import { makeStyles, Typography, useTheme } from "@material-ui/core"
import { Column, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Editing, Filtering, NumberTypeProvider, PaginationLayout, Row, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { NumberFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/NumberFormatter"
import { Break, Form, Message, NumberItem, StringItem } from "@rithe/form"
import { GridContainer, GridItem } from "@rithe/ui"
import { Arrays, arrx, Records } from "@rithe/utils"
import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { DownloadCallbackCardAction } from "../../../components/Action/DownloadCallbackCardAction"
import { IssueCallbackViewAction } from "../../../components/Action/IssueCallbackViewAction"
import { SaveCallbackViewAction } from "../../../components/Action/SaveCallbackViewAction"
import { UploadCallbackCardAction } from "../../../components/Action/UploadCallbackCardAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { PercentTypeProvider } from "../../../components/DataGrid/typeProviders/PercentTypeProvider"
import { View } from "../../../components/View/View"
import { useFunctionStore } from "../../../Root"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { useSaveAndIssueRevisedCustomerOrder, useSaveRevisedCustomerOrder } from "../../../services/order/apis/ChangeRequestApi"
import { useDownloadFcChangeByCustomer } from "../../../services/order/apis/OrderDownloadApi"
import { useUploadChangeFcByCustomer } from "../../../services/order/apis/OrderUploadApi"
import { RoStatus } from "../../../services/order/enums/RoStatus"
import { PartsDetail } from "../../../services/order/models/ChangeOrderDetailResult"
import { useGetCodeName } from "../../../utils/CodeCategoryUtil"
import { formatDateRange } from "../../../utils/formatDateRange"
import { applicationActions } from "../../Application/applicationSlice"
import { ChangeOrderBasic, ONS031Factor, useMergeDatas, useSplitDatas } from "./ONS031"

interface ONS031PcUiProps {
    factor: ONS031Factor,
    basic: ChangeOrderBasic,
    setBasic: React.Dispatch<React.SetStateAction<ChangeOrderBasic>>,
    partsDetails: PartsDetail[],
    setPartsDetails: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
}

export const ONS031FcPcUi = (props: ONS031PcUiProps) => {

    const { factor, basic, setBasic, partsDetails, setPartsDetails } = props
    const intl = useIntl()
    const editable = useMemo(() => basic && basic.roStatus ? basic.roStatus === RoStatus.DRAFT : true, [basic])
    const actions = usePrepareActions(basic, partsDetails, editable)
    return (
        <View actions={actions}>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={1}
                    title={intl.formatMessage({ id: 'step1OfOrderChange' })}
                    subtitle=""
                />
                <SectionCardContent>
                    <Step1DownloadUploadCard editable={editable} factor={factor} basic={basic} setBasic={setBasic} partsDetails={partsDetails} setPartsDetails={setPartsDetails} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={2}
                    title={intl.formatMessage({ id: 'step2OfOrderChange' })}
                    subtitle=""
                />
                <SectionCardContent>
                    <Step2ForcastTable editable={editable} partsDetails={partsDetails} setPartsDetails={setPartsDetails} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={3}
                    title={intl.formatMessage({ id: 'step4OfOrderChange' })}
                    subtitle=""
                />
                <SectionCardContent>
                    <Step4BasicInfoPanelCard basic={basic} setBasic={setBasic} />
                </SectionCardContent>
            </SectionCard>
        </View >
    )
}

interface Step1Props {
    factor: ONS031Factor,
    editable: boolean,
    basic: ChangeOrderBasic,
    setBasic: React.Dispatch<React.SetStateAction<ChangeOrderBasic>>,
    partsDetails: PartsDetail[],
    setPartsDetails: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
}
const Step1DownloadUploadCard = memo((props: Step1Props) => {
    const { editable } = props
    const theme = useTheme()
    return (
        <GridContainer columnWidth={[500, 200]} style={{ paddingLeft: theme.spacing(5) }} rowGap={theme.spacing(2)}>
            <GridItem style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='body1'><FormattedMessage id='downloadOcInStep1' /></Typography>
            </GridItem>
            <GridItem >
                {editable ? <DownloadAction basic={props.basic} partsDetails={props.partsDetails} /> : <></>}
            </GridItem>
            <GridItem style={{ display: 'flex', alignItems: 'center' }} >
                <Typography variant='body1'><FormattedMessage id='uplaodOcInStep1' /></Typography>
            </GridItem>
            <GridItem >
                {editable ? <UploadAction {...props} /> : <></>}
            </GridItem>
        </GridContainer>
    )
})

interface Step2Props {
    partsDetails: PartsDetail[],
    editable: boolean,
    setPartsDetails: React.Dispatch<React.SetStateAction<PartsDetail[]>>,
}
const Step2ForcastTable = memo((props: Step2Props) => {
    const { editable, partsDetails, setPartsDetails } = props
    const intl = useIntl()
    const [order, setOrder] = useState<string[]>([])

    const getOldForecastQty = useCallback((index: number) => (row: Row) => row.fcList[index].oldFcQty, [])
    const getForecastQty = useCallback((index: number) => (row: Row) => row.fcList[index].fcQty, [])
    const setForecastQty = useCallback((index: number) => (row: Row, value: any) => {
        const fcList = row.fcList
        return { ...row, fcList: fcList.map((fc: any, idx: number) => idx === index ? { ...fc, fcQty: value } : fc) }
    }, [])
    const getLastForecastQty = useCallback((index: number) => (row: Row) => row.fcList[index].lastFcQty, [])
    const getFlctuationFc = useCallback((index: number) => {
        return (row: Row) => {
            if (row.fcList && row.fcList[index]) {
                const forecast = row.fcList[index]
                return (forecast.fcQty != null && forecast.lastFcQty) ? (forecast.fcQty - forecast.lastFcQty) / forecast.lastFcQty : null
            }
            return null
        }
    }, [])
    const getFlctuationReasnFc = useCallback((index: number) => (row: Row) => row.fcList[index].fluctuationReason, [])
    const setFlctuationReasnFc = useCallback((index: number) => (row: Row, value: any) => {
        const fcList = row.fcList
        return { ...row, fcList: fcList.map((fc: any, idx: number) => idx === index ? { ...fc, fluctuationReason: value } : fc) }
    }, [])
    const forecastNum = partsDetails[0]?.fcList?.length ?? 0

    const columns = useMemo(() => {
        const fixedColumns = [
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
            { field: 'customerPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'partsDescription' }), width: 300 },
            { field: 'customerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'customerPartsNo' }), width: 220 },
            { field: 'supplierPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'supplierPartsNo' }), width: 220 },
            { field: 'exportCountry', dataTypeName: 'string', title: intl.formatMessage({ id: 'expCountry' }), width: 180 },
            { field: 'supplierCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierCode' }), width: 180 },
            { field: 'uomCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.uomCode' }), width: 150 },
            { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 150 },
        ]
        const firmColumns = [
            { field: 'firmQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'firmQty' }), width: 180 },
            { field: 'fcFluctuationRate', dataTypeName: 'percent', title: intl.formatMessage({ id: 'fcFluctuationRate' }), width: 200 },
        ]
        // Check order Type
        const fcColumns = Arrays.range(0, forecastNum).flatMap(index => {
            const fcDetail = (partsDetails[0]?.fcList ?? [])[index]
            const categories = [{ key: `fc${index}`, value: fcDetail ? formatDateRange(intl, fcDetail.targetFirstDate, fcDetail.targetLastDate) : '' }]
            return arrx(
                { field: `oldforecastQty${index}`, dataTypeName: 'number', title: `${intl.formatMessage({ id: 'oldforecast' })} ${index + 1}`, categories, width: index + 1 < forecastNum ? 150 : 200, getCellValue: getOldForecastQty(index) },
                { field: `forecastQty${index}`, dataTypeName: 'number', title: `${intl.formatMessage({ id: 'forecast' })} ${index + 1}`, categories, width: index + 1 < forecastNum ? 150 : 200, getCellValue: getForecastQty(index), setCellValue: setForecastQty(index) },
                index + 1 < forecastNum && { field: `lastOrderForecast${index}`, dataTypeName: 'number', title: `${intl.formatMessage({ id: 'lastOrderForecast' })} ${(index + 1)}`, categories, width: 230, getCellValue: getLastForecastQty(index) },
                index + 1 < forecastNum && { field: `fluctuation${index}`, dataTypeName: 'fcFluctuation', title: intl.formatMessage({ id: 'fluctuation' }), categories, width: 150, getCellValue: getFlctuationFc(index) },
                index + 1 < forecastNum && { field: `reasonForFluctuation${index}`, dataTypeName: 'string', title: intl.formatMessage({ id: 'reasonForFluctuation' }), categories, width: 250, getCellValue: getFlctuationReasnFc(index), setCellValue: setFlctuationReasnFc(index) },
            )
        })

        // all columns
        return Arrays.concat(fixedColumns, firmColumns, fcColumns)
    }, [forecastNum, getFlctuationFc, getFlctuationReasnFc, getForecastQty, getLastForecastQty, getOldForecastQty, intl, partsDetails, setFlctuationReasnFc, setForecastQty])

    const onEditingCellCommit = useCallback((_column: Column, row: Row) => {
        setPartsDetails(partsDetails => partsDetails.map(item => item.partsId === row.partsId ? row as PartsDetail : item))
        return true
    }, [setPartsDetails])

    const defaultEditDisabled = Records.from(columns.filter(({ field }) => !field.startsWith('forecastQty') && !field.startsWith('reasonForFluctuation')).map(({ field }) => [field, { editingDisabled: true }]))

    useEffect(() => {
        setOrder(columns.map(column => column.field))
    }, [columns])

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <PercentTypeProvider />
            <FcFluctuationTypeProvider />
            <Data rows={partsDetails} columns={columns} />
            <ColumnFreeze />
            <ColumnVisibility ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnOrdering order={order} onOrderChange={setOrder} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Editing
                enableInlineEdit={editable ? true : false}
                onEditingCellCommit={onEditingCellCommit}
                columnSettings={defaultEditDisabled}
            />
            <Sorting />
            <Filtering />
        </DataGrid>
    </div>
})

const Step4BasicInfoPanelCard = memo(({ basic, setBasic }: { basic: ChangeOrderBasic, setBasic: React.Dispatch<React.SetStateAction<ChangeOrderBasic>> }) => {

    const { getCodeName } = useGetCodeName()
    const intl = useIntl()
    const editable = useMemo(() => basic && basic.roStatus ? basic.roStatus === RoStatus.DRAFT : true, [basic])
    const shippingMode = useMemo(() => getCodeName(CodeCategory.ShippingMode, basic.shippingMode), [getCodeName, basic.shippingMode])
    const orderRange = useMemo(() => formatDateRange(intl, basic.orderFirstDate, basic.orderLastDate), [basic.orderFirstDate, basic.orderLastDate, intl])
    const [messages, setMessages] = useState<Message[]>([])
    const filedCheck = useMemo(() => {
        return (field: string, value: any) => []
    }, [])

    return (
        <Form data={basic} setData={setBasic} labelDisplay="block" helperDisplay="tooltip" messages={messages} setMessages={setMessages} ensure={filedCheck}>
            <StringItem field="customerRefNo" readonly={!editable} label={intl.formatMessage({ id: 'orderReference' })} />
            <StringItem field="contractNo" readonly={true} label={intl.formatMessage({ id: 'field.contractNo' })} />
            <Break />
            <StringItem field="sellerCode" readonly={true} label={intl.formatMessage({ id: 'seller' })} />
            <NumberItem field="totalAmount" readonly={true} label={intl.formatMessage({ id: 'totalAmount' })} suffix={basic.currency} />
            <Break />
            <StringItem field="deliveryToCode" readonly={true} label={intl.formatMessage({ id: 'deliveryToCode' })} />
            <NumberItem field="totalNumberOfParts" readonly={true} label={intl.formatMessage({ id: 'totalNumberOfParts' })} />
            <Break />
            <StringItem field="shippingMode" readonly={true} label={intl.formatMessage({ id: 'field.shippingMode' })} getValue={() => shippingMode} />
            <NumberItem field="totalQty" readonly={true} label={intl.formatMessage({ id: 'totalQty' })} />
            <Break />
            <StringItem field="orderRange" readonly={true} label={intl.formatMessage({ id: 'orderRange' })} getValue={() => orderRange} />
            <StringItem field="deliveryPlanRange" readonly={true} label={intl.formatMessage({ id: 'deliveryPlanRange' })} />
            <Break />
            <StringItem field="paymentTermsDescription" readonly={true} label={intl.formatMessage({ id: 'field.paymentTermsDesc' })} colSpan={2} />
            <Break />
            <StringItem field="remark" readonly={!editable} label={intl.formatMessage({ id: 'field.remark' })} colSpan={2} />
        </Form>
    )
})

const UploadAction = (props: Step1Props) => {

    const { factor, setBasic, setPartsDetails } = props
    const uploadMethod = useUploadChangeFcByCustomer()
    const splitDatas = useSplitDatas()
    const upload = useCallback((files: FileList | null) => {
        if (files === null) return
        uploadMethod({ file: files[0], ...factor }, { serialized: true }).then(result => {
            if (result) {
                const { orderbasic, partsDetails } = splitDatas(result)
                setBasic(orderbasic)
                setPartsDetails(partsDetails)
            }
        })
    }, [factor, setBasic, setPartsDetails, splitDatas, uploadMethod])

    return <UploadCallbackCardAction access="ORDER.ONS031.UPLOAD" callback={upload} />
}

const DownloadAction = (props: { basic: ChangeOrderBasic, partsDetails: PartsDetail[] }) => {

    const { basic, partsDetails } = props
    const downloadFcChangeByCustomer = useDownloadFcChangeByCustomer()
    const mergeDatas = useMergeDatas()
    const download = useCallback(() => {
        const data = mergeDatas(basic, partsDetails)
        downloadFcChangeByCustomer(data)
    }, [basic, downloadFcChangeByCustomer, mergeDatas, partsDetails])

    return <DownloadCallbackCardAction outlined access="ORDER.ONS031.DOWNLOAD" callback={download} />
}

const usePrepareActions = (basic: ChangeOrderBasic, partsDetails: PartsDetail[], editable: boolean) => {
    const saveRevisedCustomerOrder = useSaveRevisedCustomerOrder()
    const saveAndIssueRevisedCustomerOrder = useSaveAndIssueRevisedCustomerOrder()
    const navigate = useNavigate()
    const mergeDatas = useMergeDatas()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const issueTitle = useMemo(() => intl.formatMessage({ id: 'Issue' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const onclickToSave = useCallback(() => {
        setDisabled(true)
        const data = mergeDatas(basic, partsDetails)
        saveRevisedCustomerOrder(data, { serialized: true }).then(result => {
            navigate(`/cro-forecast`)// Search?
        }).finally(() => {
            setDisabled(false)
        })
    }, [basic, mergeDatas, navigate, partsDetails, saveRevisedCustomerOrder])
    const onclickToIssue = useCallback(() => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            const data = mergeDatas(basic, partsDetails)
            saveAndIssueRevisedCustomerOrder(data, { serialized: true }).then(result => {
                navigate(`/cro-forecast`)
            }).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: issueTitle,
            messages: { code: 'c0001', args: [issueTitle] },
            actions:[{
                label: 'CANCEL'
            },{
                functionId,
                label:'CONFIRM',
            }]
        }))
    }, [basic, dispatch, functionStore, issueTitle, mergeDatas, navigate, partsDetails, saveAndIssueRevisedCustomerOrder])
    return editable ? [
        <SaveCallbackViewAction access="ORDER.ONS031.SAVE" outlined callback={onclickToSave} disabled={disabled}/>,
        <IssueCallbackViewAction access="ORDER.ONS031.ISSUE" callback={onclickToIssue} disabled={disabled} />
    ] : []
}


const FcFluctuationFormatter = ({ value, row, formatter }: NumberFormatterProps) => {

    const style = useStyles()
    const fluctuationRate = row.fluctuationRate
    const displayValue = value === null ? 'N/A' : formatter.format(value)
    const bgcolor = (value === null || fluctuationRate === null) ? '#ECEFF2' : Math.abs(value) > fluctuationRate ? '#D94C00' : '#00CCAD'
    const color = (value === null || fluctuationRate === null) ? '' : 'white'
    return <div className={style.fulcuationCheck} style={{ background: bgcolor, color: color, width: '100%' }}>
        <Typography variant="body2" >{displayValue}</Typography>
    </div>
}

const FcFluctuationTypeProvider = () => {
    return <NumberTypeProvider name="fcFluctuation" options={{ style: 'percent', maximumFractionDigits: 0, minimumFractionDigits: 0 }} Formatter={FcFluctuationFormatter} />
}

const useStyles = makeStyles(theme => ({
    fulcuationCheck: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70%',
        paddingRight: theme.spacing(1),
        borderRadius: 5
    },
    default: {},
}))

