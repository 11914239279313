import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { PageViewEntity, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"

export type InventoryByPackageFactor = Partial<Optional>
type InventoryByPackageFactorJson = Partial<JsonOptional>
export const inventoryByPackageFactorToJson: Project<InventoryByPackageFactor, InventoryByPackageFactorJson> = obj => ({
    ...obj,
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
})

interface Optional extends Omit<JsonOptional, 'page'> {
    page: PageViewEntity,
}


interface JsonOptional {
    dcIdList: number[],
    dcIdForQueryList: number[],
    dcCodeList: string[],
    customerIdList: number[],
    customerCodeList: string[],
    partsNo: string,
    unitPartsNo: string,
    backNo: string,
    colorCode: string,
    packageNo: string,
    packageType: string,
    buId: number,
    resourceCode: string,
    page: PageViewEntityJson,
}
