import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Tooltip, Typography } from "@material-ui/core"
import { LocationOn, Refresh } from "@material-ui/icons"
import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Row, Searching, Sorting, StringTypeProvider, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarLayout } from "@rithe/data-grid"
import { StringFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/StringFormatter"
import { Break, DateItem, Form, NumberItem, StringItem } from "@rithe/form"
import { Arrays, Records } from "@rithe/utils"
import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { CallbackViewAction } from "../../../components/Action/CallbackViewAction"
import { ConfirmCallbackViewAction } from "../../../components/Action/ConfirmCallbackViewAction"
import { PrintCallbackViewAction } from "../../../components/Action/PrintCallbackViewAction"
import { NeumorphismButton } from "../../../components/Button/NeumorphismButton"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { UploadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/UploadGroupedToolbarAction"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CodeItem } from "../../../components/Form/CodeItem"
import { View } from "../../../components/View/View"
import { useFunctionStore } from "../../../Root"
import { usePrintDownloadPDF } from "../../../services/delivery/apis/deliveryOutboundApi"
import { useCheckShippingDetailConfirm } from "../../../services/delivery/apis/deliveryShippingDetailApi"
import { useFroceCredit, useRefreshCredit } from "../../../services/master/apis/creditRuleApi"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { useDownloadDrForSupplier, useDownloadPrice } from "../../../services/order/apis/OrderDownloadApi"
import { useUploadDrForSupplier, useUploadOrderPrice } from "../../../services/order/apis/OrderUploadApi"
import { useConfirmSupplierSo, useRefreshSoDetailParts } from "../../../services/order/apis/SalesOrderApi"
import { RoStatus } from "../../../services/order/enums/RoStatus"
import { SoStatus } from "../../../services/order/enums/SoStatus"
import { OrderDetailHead, OrderPartsData } from "../../../services/order/models/OrderDetailResult"
import { useGetCompanyType } from "../../../utils/ApplicationUtils"
import { useGetCodeName } from "../../../utils/CodeCategoryUtil"
import { applicationActions } from "../../Application/applicationSlice"
import { CreditLimitView } from "../CreditLimit/CreditLimit"

interface OSS011PcUiProps {
    headerInfo: OrderDetailHead,
    partsDetailList: OrderPartsData[],
    pnaCode?: string,
    search: () => void
}

export const OSS011PcUi = (props: OSS011PcUiProps) => {
    const { headerInfo, partsDetailList, search, pnaCode } = props
    const intl = useIntl()
    const actions = headerInfo.contractNo === null ? <PrintAction headerInfo={headerInfo} /> : headerInfo.status === SoStatus.RECEIVED ?
        [<PrintAction headerInfo={headerInfo} />, <CreateOrderChangeAction headerInfo={headerInfo} pnaCode={pnaCode} />, <ConfirmAction headerInfo={headerInfo} search={search} />, <CreditCheckAction headerInfo={headerInfo} search={search} />]
        : [<PrintAction headerInfo={headerInfo} />, <CreateOrderChangeAction headerInfo={headerInfo} pnaCode={pnaCode} />, <CreditCheckAction headerInfo={headerInfo} search={search} />]
    return (
        <View actions={actions}>
            <SectionCard allowCollapse id="OSS011_Section_1">
                <SectionCardHeader
                    serialNumber={1}
                    title={intl.formatMessage({ id: 'basicInfo' })}
                    subtitle={intl.formatMessage({ id: 'basicInfoSubview' })}
                />
                <SectionCardContent>
                    <Step1PoMonitoringDetail headerInfo={headerInfo} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse defaultCollapse id="OSS011_Section_2">
                <SectionCardHeader
                    serialNumber={2}
                    title={intl.formatMessage({ id: 'shippingPlanTitle' })}
                    subtitle={intl.formatMessage({ id: 'shippingPlanSubview' })}
                />
                <SectionCardContent>
                    <Step2PoBaseInfoTable partsDetailList={partsDetailList} headerInfo={headerInfo} search={search} />
                </SectionCardContent>
            </SectionCard>
        </View >
    )
}


const Step1PoMonitoringDetail = memo((props: { headerInfo: OrderDetailHead }) => {
    const { headerInfo } = props
    const intl = useIntl()
    const { getCodeName } = useGetCodeName()
    const getStatusName = useCallback((data: any) => {
        const status = data.status as number
        const orgStatus = data.orgStatus as number
        return (orgStatus === SoStatus.FORCE_COMPLETED && status === SoStatus.PROCESSING) ? getCodeName(CodeCategory.SoStatus, status) + "(" + getCodeName(CodeCategory.SoStatus, orgStatus) + ")" : getCodeName(CodeCategory.SoStatus, status)
    }, [getCodeName])

    return <Form readonly data={headerInfo} labelDisplay="block" helperDisplay="tooltip" >
        <StringItem field="orderNo" label={intl.formatMessage({ id: 'salesOrderNo' })} />
        <CodeItem field="orderType" label={intl.formatMessage({ id: 'field.orderType' })} code={CodeCategory.OrderType} />
        <StringItem field="status" label={intl.formatMessage({ id: 'field.status' })} getValue={getStatusName} />
        {headerInfo.contractNo && <StringItem field="contractNo" label={intl.formatMessage({ id: 'field.contractNo' })} />}
        {headerInfo.groupNo && <StringItem field="groupNo" label={intl.formatMessage({ id: 'field.groupNo' })} />}
        <CodeItem field="orderFrequency" label={intl.formatMessage({ id: 'field.orderFrequency' })} code={CodeCategory.OrderFrequency} />
        <StringItem field="buyer" label={intl.formatMessage({ id: 'buyer' })} />
        <DateItem field="receivedDate" label={intl.formatMessage({ id: 'field.soDate' })} />
        <DateItem field="completedDate" label={intl.formatMessage({ id: 'field.completedDate' })} />
        <StringItem field="receiver" label={intl.formatMessage({ id: 'receiver' })} />
        <Break />
        <StringItem colSpan={2} field="paymentTermsDesc" label={intl.formatMessage({ id: 'field.paymentTermsDesc' })} multiline />
        <NumberItem field="totalAmount" label={intl.formatMessage({ id: 'totalAmount' })} suffix={headerInfo.currency} />
        <Break />
        <StringItem colSpan={2} field="remark" label={intl.formatMessage({ id: 'field.remark' })} multiline />
    </Form>
})


const Step2PoBaseInfoTable = memo((props: { headerInfo: OrderDetailHead, partsDetailList: OrderPartsData[], search: () => void }) => {
    const { partsDetailList, headerInfo, search } = props
    const intl = useIntl()

    const { currentDate, orderNo, orderId, orgStatus, groupId } = headerInfo
    const palnDates = useMemo(() => partsDetailList[0]?.inboundPlanList ?? [], [partsDetailList])
    const estimatedDates = useMemo(() => partsDetailList[0]?.estimatedInboundList ?? [], [partsDetailList])

    const { getCodeName } = useGetCodeName()
    const getStatusName = useCallback((row: Row) => {
        const status = row.detailStatus as number
        const inTransitQty = row.inTransitQty ?? 0
        const estimatedInboundList = row.estimatedInboundList ?? []
        const estimatedQty = estimatedInboundList.reduce((v1: any, v2: any) => v1 + (v2.qty ? v2.qty : 0), 0)
        const forceCompletedQty = row.forceCompletedQty ?? 0
        return (forceCompletedQty > 0 && (inTransitQty > 0 || estimatedQty > 0)) ? getCodeName(CodeCategory.SoStatus, SoStatus.PROCESSING) + "(" + getCodeName(CodeCategory.SoStatus, SoStatus.FORCE_COMPLETED) + ")" : getCodeName(CodeCategory.SoStatus, status)
    }, [getCodeName])
    const display1 = useCallback(() => headerInfo.status !== SoStatus.RECEIVED && headerInfo.status !== SoStatus.CANCELLED && headerInfo.contractNo !== null, [headerInfo.contractNo, headerInfo.status])
    const display2 = useCallback(() => headerInfo.groupId !== null, [headerInfo.groupId])
    const display = useCallback(() => headerInfo.status === SoStatus.RECEIVED && headerInfo.contractNo !== null, [headerInfo.contractNo, headerInfo.status])

    const [order, setOrder] = useState<string[]>([])
    const columns = useMemo(() => {
        const fixedColumns = SoStatus.FORCE_COMPLETED === orgStatus ? [
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
            { field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsNo' }), width: 200 },
            { field: 'backNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.backNo' }), width: 200 },
            { field: 'purchaseOrderNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'purchaseOrderNo' }), width: 300 },
            { field: 'customerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'customer' }), width: 200 },
            { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 100 },
            { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 150 },
            { field: 'orderQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderQty' }), width: 150 },
            { field: 'forceCompletedQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.forceCompletedQty' }), width: 220 },
            { field: 'unitPrice', dataTypeName: 'number', title: intl.formatMessage({ id: 'unitPrice' }), width: 150 },
            { field: 'currency', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.currency' }), width: 120 },
            { field: 'detailStatus', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.status' }), width: 220, getCellValue: getStatusName },
        ] : [
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
            { field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsNo' }), width: 200 },
            { field: 'backNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.backNo' }), width: 200 },
            { field: 'purchaseOrderNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'purchaseOrderNo' }), width: 300 },
            { field: 'customerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'customer' }), width: 200 },
            { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 100 },
            { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 150 },
            { field: 'orderQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderQty' }), width: 150 },
            { field: 'unitPrice', dataTypeName: 'number', title: intl.formatMessage({ id: 'unitPrice' }), width: 150 },
            { field: 'currency', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.currency' }), width: 120 },
            { field: 'detailStatus', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.status' }), width: 220, getCellValue: getStatusName },
        ]
        //Inbounded Qty(Current Date) Column
        const iCurrentDataCategories = [{ key: 'icurrent', value: `${intl.formatMessage({ id: 'currentDate' })}(${intl.formatTime(currentDate, { dateStyle: 'medium' })})` }]
        const iCurrentDateColumns = [
            { field: 'currentDeliveryedQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'currentDeliveryedQty' }), categories: iCurrentDataCategories, width: 180 },
            { field: 'inTransitQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'inTransitQty' }), categories: iCurrentDataCategories, width: 180 },
            { field: 'receiverInbQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'receiverInbQty' }), categories: iCurrentDataCategories, width: 250 },
        ]

        //Receiver Inbound Plan Column
        const getInboundPlanCategories = (index: number) => [
            { key: 'plan', value: intl.formatMessage({ id: 'receiverInboundPlan' }) },
            { key: `plan-dr-${index}`, value: intl.formatDate(palnDates[index].drDate, { dateStyle: 'medium' }) },
            { key: `plan-crd-${index}`, value: intl.formatDate(palnDates[index].crdDate, { dateStyle: 'medium' }) }
        ]
        const inboundPlanColumns = palnDates.flatMap((_, index) => {
            return [
                { field: 'crdQty' + index, dataTypeName: 'number', title: intl.formatMessage({ id: 'plan' }), categories: getInboundPlanCategories(index), width: 150, getCellValue: getPlanDataValueByName(index, 'crdQty') },
                { field: 'status' + index, dataTypeName: 'status', title: intl.formatMessage({ id: 'field.status' }), categories: getInboundPlanCategories(index), width: 150, getCellValue: getPlanDataValueByName(index, 'status') }]
        })

        // Estimated Inbound Date Column   
        const getEstimatedCategories = (index: number) => [
            { key: 'estimate', value: intl.formatMessage({ id: 'estimatedOutInDate' }) },
            { key: `estimate-dr-${index}`, value: intl.formatTime(estimatedDates[index].estimatedDrDate, { dateStyle: 'medium' }) },
            { key: `estimate-crd-${index}`, value: intl.formatTime(estimatedDates[index].estimatedCrdDate, { dateStyle: 'medium' }) },
        ]
        let estimatedInboundColums = estimatedDates.map((_, index) => {
            return { field: 'qty' + index, dataTypeName: 'number', title: estimatedDates[index].companyTitle ?? '', categories: getEstimatedCategories(index), width: 200, getCellValue: getEstimatedValue(index) }
        })

        // all columns
        return Arrays.concat(fixedColumns, iCurrentDateColumns, inboundPlanColumns, estimatedInboundColums)
    }, [currentDate, estimatedDates, getStatusName, intl, orgStatus, palnDates])
    useEffect(() => {
        setOrder(columns.map(column => column.field))
    }, [columns])

    const cbdsType = useGetCompanyType()
    const actionProps1 = {
        salesOrderNo: orderNo,
        search: search,
        isSupplier: CbdsType.SUPP === cbdsType,
    }
    const actionProps2 = {
        orderId: orderId,
        isSupplier: CbdsType.SUPP === cbdsType
    }
    const actionProps3 = { orderNo: orderNo }
    const actionProps4 = { orderId: orderId, groupId: groupId, search: search }

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <StatusTypeProvider />
            <ToolbarActionProvider Action={UploadAction} actionProps={actionProps1} display={display} />
            <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps2} />
            <ToolbarActionProvider Action={ViewCargoTrackingAction} actionProps={actionProps3} display={display1} />
            <ToolbarActionProvider Action={RefreshAction} actionProps={actionProps4} display={display2} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.SoStatus} />
            <Data rows={partsDetailList} columns={columns} />
            <ColumnFreeze />
            <ColumnVisibility columnSettings={{
                partsNo: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnOrdering order={order} onOrderChange={setOrder} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
        </DataGrid>
    </div>
})

const CheckFormatter = ({ value }: StringFormatterProps) => {
    const style = useStyles()
    const bgcolor = 'OK' === value ? '#00CCAD' : '#D94C00'
    return <div className={style.status} style={{ background: bgcolor, color: 'white', width: '100%' }}>
        <Typography variant="body2" >{value}</Typography>
    </div>
}

const StatusTypeProvider = () => {
    return <StringTypeProvider name="status" Formatter={CheckFormatter} />
}

const getEstimatedValue = (index: number) => {
    return (row: Row) => {
        return row.estimatedInboundList[index].qty
    }
}

const getPlanDataValueByName = (index: number, columnName: string) => {
    return (row: Row) => {
        return row.inboundPlanList[index][columnName]
    }
}

const ConfirmAction = ({ headerInfo, search }: { headerInfo: OrderDetailHead, search: () => void }) => {
    const display = useMemo(() => headerInfo?.supplierId !== null && headerInfo?.supplierId !== undefined, [headerInfo])
    const confirmSupplierSo = useConfirmSupplierSo()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const [disabled, setDisabled] = useState<boolean>(false)
    const title = useMemo(() => intl.formatMessage({ id: 'confirm' }), [intl])
    const doConfirm = useCallback(() => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            confirmSupplierSo({ orderId: headerInfo.orderId }, { serialized: true }).then(result => {
                search()
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))

    }, [confirmSupplierSo, dispatch, functionStore, headerInfo, search, title])
    return <>
        {display && <ConfirmCallbackViewAction access="ORDER.OSS011.CONFIRM" callback={doConfirm} disabled={disabled} />}
    </>
}

const PrintAction = ({ headerInfo }: { headerInfo: OrderDetailHead }) => {
    const print = usePrintDownloadPDF()
    const click = useCallback(() => {
        if (headerInfo.orderId) {
            let poIds: string = JSON.stringify([headerInfo.orderId])
            print({ reportId: 'OSR010', labelId: 'Sales Order', param: poIds })
        }
    }, [headerInfo, print])
    return <PrintCallbackViewAction outlined access="ORDER.OSS011.PRINT" callback={click} />
}

const UploadAction = ({ salesOrderNo, isSupplier, search }: { salesOrderNo: string, isSupplier: boolean, search: () => void }) => {
    const uploadDrForSupplierApi = useUploadDrForSupplier()
    const uploadOrderPriceApi = useUploadOrderPrice()
    const uploadDrForSupplier = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            files && uploadDrForSupplierApi({ file: files[0], salesOrderNo: salesOrderNo }, { serialized: true }).then(result => search())
        })
    }, [salesOrderNo, search, uploadDrForSupplierApi])
    const uploadOrderPrice = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            files && uploadOrderPriceApi({ file: files[0], salesOrderNo: salesOrderNo }, { serialized: true }).then(result => search())
        })
    }, [salesOrderNo, search, uploadOrderPriceApi])

    return <UploadGroupedToolbarAction access="ORDER.OSS011.UPLOAD">
        {(popupUpload, onClose) => <>
            {isSupplier && <GroupedCallbackItem label={<FormattedMessage id="uploadDr" />} callback={() => uploadDrForSupplier(popupUpload)} onClose={onClose} />}
            <GroupedCallbackItem label={<FormattedMessage id="uploadOrderPrice" />} callback={() => uploadOrderPrice(popupUpload)} onClose={onClose} />
        </>}
    </UploadGroupedToolbarAction>
}

const DownloadAction = ({ orderId, isSupplier }: { orderId: number, isSupplier: boolean }) => {
    const downloadDrForSupplierApi = useDownloadDrForSupplier()
    const downloadPriceApi = useDownloadPrice()
    const downloadDr = useCallback(() => (
        downloadDrForSupplierApi({ orderId: orderId })
    ), [downloadDrForSupplierApi, orderId])
    const downloadPrice = useCallback(() => (
        downloadPriceApi({ orderId: orderId })
    ), [downloadPriceApi, orderId])
    return <DownloadGroupedToolbarAction access="ORDER.OSS011.DOWNLOAD">
        {onClose => <>
            {isSupplier && <GroupedCallbackItem label={<FormattedMessage id="downloadDr" />} callback={downloadDr} onClose={onClose} />}
            <GroupedCallbackItem label={<FormattedMessage id="downloadOrderPrice" />} callback={downloadPrice} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}

const ViewCargoTrackingAction = ({ orderNo }: { orderNo: string }) => {
    const checkConfirm = useCheckShippingDetailConfirm()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const styles = useStyles()

    const click = useCallback(() => {
        checkConfirm({ orderNo: orderNo }, { silent: true }).then(result => {
            if (result === false) {
                dispatch(applicationActions.pushError({ title: { code: 'viewCargoTracking' }, messages: { code: 'w1039' } }))
            } else {
                navigate('/so/cargotrackingDetail-' + orderNo)
            }
        })
    }, [checkConfirm, dispatch, navigate, orderNo])

    return <Tooltip title={<FormattedMessage id="viewCargoTracking" />}>
        <NeumorphismButton onClick={click} className={styles.button}>
            <LocationOn />
        </NeumorphismButton>
    </Tooltip>
}

const RefreshAction = ({ orderId, groupId, search }: { orderId: number, groupId: number, search: () => void }) => {
    const styles = useStyles()

    const refreshSoDetailParts = useRefreshSoDetailParts()
    const refresh = useCallback(() => (
        refreshSoDetailParts({ orderId: orderId, groupId: groupId }, { serialized: true }).then(() => {
            search()
        })
    ), [groupId, orderId, refreshSoDetailParts, search])

    return <Tooltip title={<FormattedMessage id="refresh" />}>
        <NeumorphismButton onClick={refresh} className={styles.button}>
            <Refresh />
        </NeumorphismButton>
    </Tooltip>
}

const useStyles = makeStyles(theme => ({
    status: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70%',
        paddingRight: theme.spacing(1),
        borderRadius: 5
    },
    default: {},
    button: {
        marginLeft: theme.spacing(1),
        width: 36,
    },
}))

const CreditCheckAction = ({ headerInfo, search }: { headerInfo: OrderDetailHead, search: () => void }) => {
    const froceCreditFun = useFroceCredit()
    const refreshCreditFun = useRefreshCredit()
    const soId = headerInfo.orderId
    const creditCheckFlag = headerInfo.creditCheckFlag
    const [open, setOpen] = useState<boolean>(false)
    const onOpen = useCallback(() => {
        setOpen(true)
    }, [setOpen])

    const onClose = useCallback(() => setOpen(false), [])
    const force = useCallback(() => {
        if (soId && creditCheckFlag) {
            froceCreditFun({ soId: soId, creditCheckFlag: creditCheckFlag }, { serialized: true, silent: true }).then(result => {
                if (result) {
                    // refrech
                    search()
                }

            })
        }

    }, [creditCheckFlag, froceCreditFun, search, soId])
    const refresh = useCallback(() => {
        if (soId && creditCheckFlag) {
            refreshCreditFun({ soId: soId, creditCheckFlag: creditCheckFlag }, { serialized: true, silent: true }).then(result => {
                if (result) {
                    // refrech
                    search()
                }

            })
        }
    }, [creditCheckFlag, refreshCreditFun, search, soId])
    if (creditCheckFlag && creditCheckFlag === 1) {
        return <>
            <CallbackViewAction title={<FormattedMessage id="creditCheckView" />} callback={onOpen} />
            <Dialog open={open} style={{ height: '700px', overflow: 'hidden', color: '#fff', }} maxWidth="md" fullWidth>
                <DialogTitle>
                    <FormattedMessage id="checkItemDetail" />
                </DialogTitle>
                <DialogContent>
                    <CreditLimitView soId={soId} creditCheckFlag={creditCheckFlag} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={force} variant="contained" style={{ backgroundColor: '#0074a5' }}><FormattedMessage id="force" /></Button>
                    <Button onClick={refresh} variant="contained" style={{ backgroundColor: '#0074a5' }}><FormattedMessage id="refresh" /></Button>
                    <Button onClick={onClose} variant="contained" ><FormattedMessage id="close" /></Button>
                </DialogActions>
            </Dialog>
        </>
    } else {
        return <></>
    }
}

// link to supp order change
const CreateOrderChangeAction = ({ headerInfo, pnaCode }: { headerInfo: OrderDetailHead, pnaCode?: string }) => {
    const display = useMemo(() => headerInfo?.suppFlag === 1, [headerInfo])
    const navigate = useNavigate()
    const onClick = useCallback(() => {
        if (headerInfo?.suppRoId !== undefined && headerInfo?.suppRoId !== null) {
            if (headerInfo?.suppRoStatus === RoStatus.DRAFT) {
                if (pnaCode === headerInfo.buyer) {
                    navigate(`/so/suppRoEditpna-${headerInfo.suppRoId}`)
                } else {
                    navigate(`/so/suppRoEdit-${headerInfo.suppRoId}`)
                }
            } else {
                if (pnaCode === headerInfo.buyer) {
                    navigate(`/so/suppRoViewpna-${headerInfo.suppRoId}`)
                } else {
                    navigate(`/so/suppRoView-${headerInfo.suppRoId}`)
                }
            }
        } else {
            if (pnaCode === headerInfo.buyer) {
                navigate(`/so/suppRoCreatepna-${headerInfo?.soId}`)
            } else {
                navigate(`/so/suppRoCreate-${headerInfo?.soId}`)
            }
        }

    }, [headerInfo.buyer, headerInfo?.soId, headerInfo.suppRoId, headerInfo?.suppRoStatus, navigate, pnaCode])

    return <>
        {display && <CallbackViewAction access='ORDER.ORS020.CREATE' callback={onClick} title={<FormattedMessage id="CHANGE ORDER" />} />}
    </>
}



