import { Project } from "@rithe/utils";
import moment from "moment";



export type ProjectPlanFactor = Required & Partial<Optional>;

type ProjectPlanFactorJson = JsonRequired & Partial<JsonOptional>;

export const projectPlanFactorToJson: Project<ProjectPlanFactor, ProjectPlanFactorJson> = obj => ({
    ...obj,
    yearMonthFrom: obj.yearMonthFrom ? moment(obj.yearMonthFrom).format('YYYYMM') : undefined,
    yearMonthTo: obj.yearMonthTo ? moment(obj.yearMonthTo).format('YYYYMM') : undefined,
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'yearMonthFrom' | 'yearMonthTo'> {
    yearMonthFrom: Date,
    yearMonthTo: Date,
}

interface JsonRequired {

}

interface JsonOptional {
    projectId: number,
    noOfLevel: number,
    productionType: number,
    usageHistoryId: number,
    planHistoryId: number,
    yearMonthFrom: string,
    yearMonthTo: string,
}
