import { useCallback } from "react"
import { Projects } from "../../../utils/Projects"
import { usePost } from "../../../utils/fetch/useFetch"
import { ExternalDataResult, externalDataResultFromJson, externalDataResultToJson } from "../models/ExternalDataResult"
import { ExternalTemplateList, externalTemplateListFromJson } from "../models/ExternalTemplateList"
import { FunctionList, functionListFromJson } from "../models/FunctionList"

export const useGetFunctionList = () => {
    const url = '/integration-api/template/getFunctionList'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(functionListFromJson), [])
    return usePost<undefined, FunctionList[]>(url, payloadProject, resultProject)
}

export const useGetExternalTemplateList = () => {
    const url = '/integration-api/template/getExternalTemplateList'
    const payloadProject = useCallback((obj: { function: string }) => obj.function, [])
    const resultProject = useCallback(Projects.array(externalTemplateListFromJson), [])
    return usePost<{ function: string }, ExternalTemplateList[]>(url, payloadProject, resultProject)
}

export const useGetExternalTemplateById = () => {
    const url = '/integration-api/template/getExternalTemplateById'
    const payloadProject = useCallback((obj: { templateId: string }) => obj.templateId, [])
    const resultProject = useCallback(externalTemplateListFromJson, [])
    return usePost<{ templateId: string }, ExternalTemplateList>(url, payloadProject, resultProject)
}

export const useGetExternalDataForView = () => {
    const url = '/integration-api/template/view'
    const payloadProject = useCallback((obj: { templateId: string }) => obj.templateId, [])
    const resultProject = useCallback(externalDataResultFromJson, [])
    return usePost<{ templateId: string }, ExternalDataResult>(url, payloadProject, resultProject)
}

export const useGetExternalDataForUpdate = () => {
    const url = '/integration-api/template/update'
    const payloadProject = useCallback((obj: { templateId: string }) => obj.templateId, [])
    const resultProject = useCallback(externalDataResultFromJson, [])
    return usePost<{ templateId: string }, ExternalDataResult>(url, payloadProject, resultProject)
}

export const useGetExternalDataForCreate = () => {
    const url = '/integration-api/template/create'
    const payloadProject = useCallback((obj: { function: string }) => obj.function, [])
    const resultProject = useCallback(externalDataResultFromJson, [])
    return usePost<{ function: string }, ExternalDataResult>(url, payloadProject, resultProject)
}

export const useSaveTemplateDetail = () => {
    const url = '/integration-api/template/saveTemplateDetail'
    const payloadProject = useCallback(externalDataResultToJson, [])
    const resultProject = useCallback((obj: { templateId: string }) => obj.templateId, [])
    return usePost<ExternalDataResult, string>(url, payloadProject, resultProject)
}

export const useConfirmTemplate = () => {
    const url = '/integration-api/template/confirmTemplate'
    const payloadProject = useCallback(externalDataResultToJson, [])
    const resultProject = useCallback((obj: { templateId: string }) => obj.templateId, [])
    return usePost<ExternalDataResult, string>(url, payloadProject, resultProject)
}




