import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useGetOutboundInfo } from "../../../services/delivery/apis/deliveryOutboundApi"
import { OutboundMonitorInfo } from "../../../services/delivery/models/OutboundMonitorInfo"
import { LOS031PcUi } from "./LOS031PcUi"

export const LOS031 = () => {
    const { outboundNo } = useParams() as any
    const [outboundInfo, setOutboundInfo] = useState<OutboundMonitorInfo>({} as any)
    const getOutboundInfo = useGetOutboundInfo()

    const [hugeData, setHugeData] = useState<boolean>(false)

    useEffect(() => {
        if (outboundNo) {
            getOutboundInfo({ outboundNo: outboundNo }, { silent: true, serialized: true }).then(result => {
                setOutboundInfo(result)

                result.containers?.forEach(container => {
                    setHugeData(pre => pre ? pre : container.outerPackageList != null && container.outerPackageList?.length > 20)
                })
            })
        }
    }, [getOutboundInfo, outboundNo])

    return <LOS031PcUi outboundInfo={outboundInfo} hugeData={hugeData}/>
}