import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"

export type StockAdjustmentList = Required & Partial<Optional> & TableEntity
type StockAdjustmentListJson = JsonRequired & Partial<JsonOptional> & TableEntityJson

export const stockAdjustmentFromJson: Project<StockAdjustmentListJson, StockAdjustmentList> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
}

interface JsonRequired {
}

interface JsonOptional {
    no: number,
    ttcPartsNo: string,
    buCustomerCode: string,
    customerCode: string,
    customerPartsCode: string,
    externalReferenceNo: string,
    backNo: string,
    customerBackNo: string,
    sourceStr: string,
    impWHStock: number,
    impWHOnHold: number,
    stockDeltaQty: number,
    onholdDeltaQty: number,
    customerPartsId: number,
    decimalDigits: number,
}