import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, Slide } from "@material-ui/core"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { TransitionProps } from "@material-ui/core/transitions/transition"
import { Break, DateItem, EntryItem, Form, Message, Placeholder, StringItem } from "@rithe/form"
import { GridItem } from "@rithe/ui"
import { Records } from "@rithe/utils"
import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useFunctionStore } from "../../../Root"
import { SubmitCallbackViewAction } from "../../../components/Action/SubmitCallbackViewAction"
import { TempSaveCallbackViewAction } from "../../../components/Action/TempSaveCallbackViewAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { CodeItem } from "../../../components/Form/CodeItem"
import { SectionTitle } from "../../../components/SectionTitle/SectionTitle"
import { View } from "../../../components/View/View"
import { useGetCalendarByActiveAndCompanyId } from "../../../services/common/apis/commonMasterApi"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { useSaveAndIssueDcInfo, useUpdatedOrInsertDcDetail } from "../../../services/master/apis/dcApi"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { ValueType } from "../../../services/master/enums/ValueType"
import { SettingDetail } from "../../../services/master/models/SettingDetail"
import { TnmCalendar } from "../../../services/master/models/TnmCalendar"
import { TnmCompany } from "../../../services/master/models/TnmCompany"
import { TnmDistributionCenterTemp } from "../../../services/master/models/TnmDistributionCenterTemp"
import { TnmRegion } from "../../../services/master/models/TnmRegion"
import { dateFromJson } from "../../../services/utils/deserializer"
import { useGetCompanyCode } from "../../../utils/ApplicationUtils"
import { useFieldChecker, useFormValidater } from "../../../utils/ValidatorUtils"
import { useIsPowerUser } from "../../../utils/useIsPowerUser"
import { applicationActions } from "../../Application/applicationSlice"

interface MLS051PcUiProps {
    dcInfo: TnmDistributionCenterTemp,
    setDcInfo: React.Dispatch<React.SetStateAction<TnmDistributionCenterTemp>>,
    settings: SettingDetail[],
    setSettings: React.Dispatch<React.SetStateAction<SettingDetail[]>>,
    search: () => void,

    zones: { first: string, second: string }[],
    regions: TnmRegion[],

    companyList: TnmCompany[],
    mode: ScreenMode,
    path: string
    dcCode: string,
}

export const MLS051PcUi = (props: MLS051PcUiProps) => {
    const { dcInfo, setDcInfo, settings, setSettings, zones, regions, companyList, mode, search, path, dcCode } = props
    const intl = useIntl()
    const [calendarList, setCalendarList] = useState<TnmCalendar[]>([])
    const [messages, setMessages] = useState<Message[]>([])
    const actions = mode === ScreenMode.VIEW ? [] : [
        <SaveAction dcInfo={dcInfo} search={search} mode={mode} dcCode={dcCode} settings={settings} setMessages={setMessages} path={path} />,
        <SubmitAction dcInfo={dcInfo} settings={settings} setMessages={setMessages} mode={mode} path={path} />
    ]

    return (
        <View actions={actions}>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={1}
                    title={intl.formatMessage({ id: 'selectCompany' })}
                    subtitle={intl.formatMessage({ id: 'selectCompanySub' })}
                />
                <SectionCardContent>
                    <Selectview dcInfo={dcInfo} setDcInfo={setDcInfo} messages={messages} setMessages={setMessages} companyList={companyList} mode={mode} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={2}
                    title={intl.formatMessage({ id: 'basicInfo' })}
                    subtitle={intl.formatMessage({ id: 'basicInfoSub' })}
                />
                <SectionCardContent>
                    <DcDetailView dcInfo={dcInfo} setDcInfo={setDcInfo} messages={messages} setMessages={setMessages} zones={zones} regions={regions} companyList={companyList} calendarList={calendarList} mode={mode} setCalendarList={setCalendarList} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={3}
                    title={intl.formatMessage({ id: 'contactInfo' })}
                    subtitle={intl.formatMessage({ id: 'contactInfoSub' })}
                />
                <SectionCardContent>
                    <ContactDetailView dcInfo={dcInfo} setDcInfo={setDcInfo} mode={mode} messages={messages} setMessages={setMessages} />
                </SectionCardContent>
            </SectionCard>
            {settings.length > 0 && <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={4}
                    title={intl.formatMessage({ id: 'settingInfo' })}
                    subtitle={intl.formatMessage({ id: 'settingInfoSub' })}
                />
                <SectionCardContent>
                    <SettingView settings={settings} setSettings={setSettings} mode={mode} />
                </SectionCardContent>
            </SectionCard>}
        </View>
    )
}

interface Selectviewprops {
    dcInfo: TnmDistributionCenterTemp,
    setDcInfo: React.Dispatch<React.SetStateAction<TnmDistributionCenterTemp>>,
    companyList: TnmCompany[],
    mode: ScreenMode,
    messages: Message[],
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
}

const Selectview = (props: Selectviewprops) => {
    const { dcInfo, setDcInfo, companyList, mode, messages, setMessages } = props
    const intl = useIntl()
    const companyMap: [number, string][] = useMemo(() => companyList.map(m => [m.companyId, m.companyCode]), [companyList])
    const fields = useMemo(() => getFormCheckFields(true), [])
    const filedCheck = useFieldChecker(fields, setMessages)
    const viewOnlyFlag = mode === ScreenMode.CREATE || dcInfo.dcId === null || dcInfo.dcId === undefined

    const [checked, setChecked] = useState<boolean>(false)
    const onFieldChange = useCallback((field: string, value: any) => {
        // Restore the checkBox's default value
        setChecked(false)
    }, [])

    const handleChange = useCallback((event) => {
        const checked = event.target.checked
        setChecked(checked)
        if (checked) {
            const companyInfo = companyList.find(f => f.companyId === dcInfo.companyId)
            if (viewOnlyFlag && companyInfo !== null && companyInfo !== undefined) {
                setDcInfo({
                    companyId: companyInfo.companyId,
                    regionCode: companyInfo.regionCode ?? '',
                    activeFlag: companyInfo.activeFlag,
                    dcName: companyInfo.companyName ?? '',
                    dcShortCode: companyInfo.companyShortCode,
                    timeZone: companyInfo.timeZone,
                    address1: companyInfo.address1,
                    address2: companyInfo.address2,
                    address3: companyInfo.address3,
                    address4: companyInfo.address4,
                    postalCode: companyInfo.postalCode,
                    contact1: companyInfo.contact1,
                    email1: companyInfo.email1,
                    telephone1: companyInfo.telephone1,
                    fax1: companyInfo.fax1,
                    contact2: companyInfo.contact2,
                    email2: companyInfo.email2,
                    telephone2: companyInfo.telephone2,
                    fax2: companyInfo.fax2,
                } as TnmDistributionCenterTemp)
            }
        } else {
            setDcInfo({ companyId: dcInfo.companyId } as TnmDistributionCenterTemp)
        }
    }, [companyList, viewOnlyFlag, dcInfo.companyId, setDcInfo])


    return <>
        <Form data={dcInfo} setData={setDcInfo} labelDisplay="block" helperDisplay="tooltip" messages={messages} setMessages={setMessages} onChange={onFieldChange} ensure={filedCheck}>
            <EntryItem field="companyId" required readonly={!viewOnlyFlag} label={intl.formatMessage({ id: 'field.companyCode' })} entries={companyMap} />
        </Form>

        {viewOnlyFlag && <FormControlLabel
            style={{ marginLeft: '1px', marginTop: '10px' }}
            value="start"
            control={<Checkbox title={"Click it"} checked={checked} onClick={handleChange} style={{ marginLeft: '15px' }} color="default" />}
            label={intl.formatMessage({ id: 'copyCompanyToDc' })}
            labelPlacement="start"
            color="default"
        />}
    </>
}

interface DcDetailViewProps {
    dcInfo: TnmDistributionCenterTemp,
    setDcInfo: React.Dispatch<React.SetStateAction<TnmDistributionCenterTemp>>,
    zones: { first: string, second: string }[],
    regions: TnmRegion[],
    calendarList: TnmCalendar[],
    companyList: TnmCompany[],
    mode: ScreenMode,
    messages: Message[],
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>
    setCalendarList: React.Dispatch<React.SetStateAction<TnmCalendar[]>>,
}

const DcDetailView = (props: DcDetailViewProps) => {

    const { dcInfo, setDcInfo, zones, regions, mode, messages, setMessages, setCalendarList } = props
    const intl = useIntl()

    const zonesMap = useMemo(() => zones.map(m => [m.first, m.second] as [string, string]), [zones])
    const regionsMap = useMemo(() => regions.map(m => [m.regionCode, m.regionCode] as [string, string]), [regions])
    // const calendarMap: [number, string][] = useMemo(() => calendarList.map(m => [m.calendarId, m.calendarCode]), [calendarList]);

    const fields = useMemo(() => getFormCheckFields(true), [])
    const filedCheck = useFieldChecker(fields, setMessages)

    const viewOnlyFlag = mode !== ScreenMode.CREATE && dcInfo.dcId !== null && dcInfo.dcId !== undefined
    const readOnly = mode === ScreenMode.VIEW
    const getCurrentCalendarList = useGetCalendarByActiveAndCompanyId()
    useEffect(() => {
        if (dcInfo.companyId !== null && dcInfo.companyId !== undefined) {
            getCurrentCalendarList({ companyId: dcInfo.companyId }, { silent: true, serialized: true }).then(result => {
                setCalendarList(result || [])
            })
        }
    }, [dcInfo.companyId, getCurrentCalendarList, setCalendarList])

    const navigate = useNavigate()
    const isShow = (dcInfo.calendarId !== null && dcInfo.calendarId !== undefined) ? true : false
    const CreateOrViewOrEditCalendar = () => {
        const transferId = dcInfo.companyId
        const flagRoleCode = dcInfo.dcCode
        if (isShow) {
            navigate(`/dc/view-${dcInfo.dcCode}/calendar-view-${dcInfo.calendarId}`, { state: { transferId, flagRoleCode } })
        } else {
            // create Calendar
            navigate(`/dc/create-${dcInfo.dcCode}`, { state: { transferId, flagRoleCode } })
        }
    }

    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const isPowerUser = useIsPowerUser()
    const companyCode = useGetCompanyCode()
    const showCalendarEditFlag = isPowerUser || companyCode === dcInfo.dcCode

    return <Form data={dcInfo} setData={setDcInfo} labelDisplay="block" helperDisplay="tooltip" messages={messages} setMessages={setMessages} ensure={filedCheck}>
        <StringItem field="dcCode" required readonly={viewOnlyFlag} label={intl.formatMessage({ id: 'field.dcCode' })} />
        <EntryItem field="regionCode" required readonly={viewOnlyFlag} label={intl.formatMessage({ id: 'field.regionCode' })} entries={regionsMap} />
        <CodeItem field="activeFlag" required readonly={readOnly} label={intl.formatMessage({ id: 'field.activeFlag' })} code={CodeCategory.ActiveFlag} />
        <StringItem field="dcName" required readonly={readOnly} label={intl.formatMessage({ id: 'field.dcName' })} />
        <StringItem field="dcShortCode" required readonly={readOnly} label={intl.formatMessage({ id: 'field.dcShortCode' })} />
        <EntryItem field="timeZone" required readonly={readOnly} label={intl.formatMessage({ id: 'field.timeZone' })} entries={zonesMap} />
        <StringItem field="address1" required readonly={readOnly} label={intl.formatMessage({ id: 'field.address1' })} />
        <StringItem field="address2" readonly={readOnly} label={intl.formatMessage({ id: 'field.address2' })} />
        <StringItem field="address3" readonly={readOnly} label={intl.formatMessage({ id: 'field.address3' })} />
        <StringItem field="address4" readonly={readOnly} label={intl.formatMessage({ id: 'field.address4' })} />
        <StringItem field="postalCode" required readonly={readOnly} label={intl.formatMessage({ id: 'field.postalCode' })} />
        {mode === ScreenMode.VIEW && showCalendarEditFlag &&
            <GridItem >
                <div style={{ display: 'flex', marginTop: '42px' }}>
                    {isShow &&
                        <Link onClick={CreateOrViewOrEditCalendar} component="button" variant="body1"><FormattedMessage id="setCalendarCode" /></Link>
                    }
                    {!isShow &&
                        <>
                            <Link onClick={handleClickOpen} href="#" variant="body1" ><FormattedMessage id="setCalendarCode" /></Link>
                            <Dialog
                                open={open}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-slide-title"
                                aria-describedby="alert-dialog-slide-description"
                            >
                                <DialogTitle id="alert-dialog-slide-title"><FormattedMessage id="operationTitle" /></DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-slide-description">
                                        <FormattedMessage id="operationSuggestions" />
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={CreateOrViewOrEditCalendar} color="primary"><FormattedMessage id="confirm" /></Button>
                                    <Button onClick={handleClose} color="primary"><FormattedMessage id="cancel" /></Button>
                                </DialogActions>
                            </Dialog></>}
                </div>
            </GridItem>}
    </Form>
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />
})

interface ContactDetailViewProps {
    dcInfo: TnmDistributionCenterTemp,
    setDcInfo: React.Dispatch<React.SetStateAction<TnmDistributionCenterTemp>>,
    mode: ScreenMode,
    messages: Message[],
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>
}

const ContactDetailView = (props: ContactDetailViewProps) => {

    const { dcInfo, setDcInfo, mode, messages, setMessages } = props
    const intl = useIntl()

    const fields = useMemo(() => getFormCheckFields(true), [])
    const filedCheck = useFieldChecker(fields, setMessages)

    return <Form readonly={mode === ScreenMode.VIEW} data={dcInfo} setData={setDcInfo} labelDisplay="block" helperDisplay="tooltip" messages={messages} setMessages={setMessages} ensure={filedCheck}>
        <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="mainContact" /></SectionTitle></GridItem>
        <StringItem field="contact1" required label={intl.formatMessage({ id: 'field.contactName' })} />
        <StringItem field="fax1" required label={intl.formatMessage({ id: 'companyFax' })} />
        <Placeholder />
        <StringItem field="telephone1" required label={intl.formatMessage({ id: 'companyTelephone' })} />
        <StringItem field="email1" required label={intl.formatMessage({ id: 'companyEmail' })} />
        <Placeholder />
        <Break />
        <Placeholder />

        <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="secondaryContact" /></SectionTitle></GridItem>
        <StringItem field="contact2" label={intl.formatMessage({ id: 'field.contactName' })} />
        <StringItem field="fax2" label={intl.formatMessage({ id: 'companyFax' })} />
        <Placeholder />
        <StringItem field="telephone2" label={intl.formatMessage({ id: 'companyTelephone' })} />
        <StringItem field="email2" label={intl.formatMessage({ id: 'companyEmail' })} />
    </Form>
}


interface SettingViewProps {
    settings: SettingDetail[],
    setSettings: React.Dispatch<React.SetStateAction<SettingDetail[]>>,
    mode: ScreenMode,
}

const SettingView = (props: SettingViewProps) => {
    const { settings, setSettings, mode } = props

    const defaultSettings = Records.from(settings.map(m => [m.settingCode, castValue(m.valueType, m.settingValue)]))
    const [data, setData] = useState<any>(defaultSettings)
    const onFieldChange = useCallback((field: string, value: any) => {
        setSettings(settings => settings.map(setting => ({ ...setting, settingValue: setting.settingCode === field ? (value === null ? undefined : value) : setting.settingValue })))
    }, [setSettings])

    return (
        <Form readonly={mode === ScreenMode.VIEW} data={data} setData={setData} onChange={onFieldChange} labelDisplay="block" helperDisplay="tooltip">
            {settings.map(setting => {
                const { valueType, settingCode, description } = setting
                if (valueType === ValueType.DATE || valueType === ValueType.DATETIME) {
                    return <DateItem field={settingCode} label={description} />
                } else if (valueType === ValueType.DECIMAL || valueType === ValueType.INTEGER) {
                    return <StringItem field={settingCode} label={description} />
                }
                return <StringItem field={settingCode} label={description} />
            })}
        </Form>
    )
}

const castValue = (valueType: ValueType, settingValue: string | undefined) => {
    if (valueType === ValueType.DATE || valueType === ValueType.DATETIME) {
        return dateFromJson(settingValue)
    } else if (valueType === ValueType.DECIMAL || valueType === ValueType.INTEGER) {
        return (settingValue === undefined || settingValue === null) ? undefined : Number(settingValue)
    } else {
        return settingValue
    }
}

const SaveAction = memo((props: {
    dcInfo: TnmDistributionCenterTemp,
    settings: SettingDetail[],
    mode: ScreenMode, search: () => void,
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
    path: string,
    dcCode: string
}) => {
    const { dcInfo, settings, mode, search, setMessages, path, dcCode } = props
    const updatedOrInsertDcDetail = useUpdatedOrInsertDcDetail()
    const navigate = useNavigate()
    const fields = useMemo(() => getFormCheckFields(false), [])
    const formValidate = useFormValidater(setMessages, fields)
    const dispaly = path !== '/receivedRequest/addNewPart-:requestNo/dcCreate'
    const [disabled, setDisabled] = useState<boolean>(false)
    const onclickSave = useCallback(() => {
        if (formValidate(dcInfo)) {
            setDisabled(true)
            updatedOrInsertDcDetail({ basicInfo: dcInfo, settings: settings }, { serialized: true }).then((result) => {
                if (mode === ScreenMode.CREATE || dcCode !== result.dcCode) {
                    // refresh screen
                    navigate(`/dc/edit-${result.dcCode}`)
                } else {
                    search()
                }
            }).finally(() => {
                setDisabled(false)
            })
        }
    }, [formValidate, dcInfo, updatedOrInsertDcDetail, settings, mode, dcCode, navigate, search])

    return <>
        {dispaly && <TempSaveCallbackViewAction outlined access="MARS.MLS051.TEMPSAVE" callback={onclickSave} disabled={disabled} />}
    </>
})

const SubmitAction = memo((props: {
    dcInfo: TnmDistributionCenterTemp,
    settings: SettingDetail[],
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
    mode: ScreenMode,
    path: string,
}) => {
    const { dcInfo, settings, setMessages, path, mode } = props
    const saveAndIssueDcInfo = useSaveAndIssueDcInfo()
    const navigate = useNavigate()
    const fields = useMemo(() => getFormCheckFields(true), [])
    const formValidate = useFormValidater(setMessages, fields)
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'submit' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const onclickSubmit = useCallback(() => {
        if (formValidate(dcInfo)) {
            const functionId = functionStore.register(() => {
                setDisabled(true)
                saveAndIssueDcInfo({ basicInfo: dcInfo, settings: settings }, { serialized: true }).then((result) => {
                    if (mode === ScreenMode.CREATE && path === '/receivedRequest/addNewPart-:requestNo/dcCreate') {
                        navigate(-1)
                    } else {
                        // refresh screen
                        navigate('/dc')
                    }
                }).finally(() => {
                    setDisabled(false)
                })
            })
            dispatch(applicationActions.pushWarning({
                title: title,
                messages: { code: 'c0001', args: [title] },
                actions: [{
                    label: 'CANCEL'
                }, {
                    functionId,
                    label: 'CONFIRM',
                }]
            }))
        }
    }, [formValidate, dcInfo, functionStore, dispatch, title, saveAndIssueDcInfo, settings, mode, path, navigate])

    return <SubmitCallbackViewAction access="MARS.MLS051.SUBMIT" callback={onclickSubmit} disabled={disabled} />
})

const getFormCheckFields = (isIssue: boolean) => {
    return ({
        dcCode: { labelId: 'field.dcCode', required: true, length: { max: 20 } },
        regionCode: { labelId: 'field.regionCode', required: true },
        companyId: { labelId: 'field.companyCode', required: true },
        dcName: { labelId: 'field.dcName', required: isIssue, length: { max: 255 } },
        dcShortCode: { labelId: 'field.dcShortCode', required: true, length: { max: 3 } },
        address1: { labelId: 'field.address1', required: isIssue, length: { max: 100 } },
        address2: { labelId: 'field.address2', length: { max: 100 } },
        address3: { labelId: 'field.address3', length: { max: 100 } },
        address4: { labelId: 'field.address4', length: { max: 100 } },
        postalCode: { labelId: 'field.postalCode', required: isIssue, length: { max: 10 } },
        timeZone: { labelId: 'field.timeZone', required: isIssue },
        activeFlag: { labelId: 'field.activeFlag', required: isIssue },
        // calendarId: { labelId: 'field.calendarCode', required: isIssue },
        contact1: { labelId: 'field.contact1', required: isIssue, length: { max: 100 } },
        fax1: { labelId: 'field.fax1', required: isIssue, length: { max: 100 } },
        telephone1: { labelId: 'field.telephone1', required: isIssue, length: { max: 100 } },
        email1: { labelId: 'field.email1', required: isIssue, length: { max: 255 } },
        contact2: { labelId: 'field.contact2', length: { max: 100 } },
        fax2: { labelId: 'field.fax2', length: { max: 100 } },
        telephone2: { labelId: 'field.telephone2', length: { max: 100 } },
        email2: { labelId: 'field.email2', length: { max: 255 } },
    })
}