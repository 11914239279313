import { Checkbox, FormControlLabel, FormGroup, Grid, makeStyles, Theme } from "@material-ui/core"
import { EntryItem, Form, Message, Placeholder, StringItem } from "@rithe/form"
import React, { useCallback, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { DownloadCallbackViewAction } from "../../../components/Action/DownloadCallbackViewAction"
import { UploadCallbackViewAction } from "../../../components/Action/UploadCallbackViewAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { CodeItem } from "../../../components/Form/CodeItem"
import { ViewPanel } from "../../../components/View/Tab/ViewPanel"
import { ViewPanels } from "../../../components/View/Tab/ViewPanels"
import { ViewTab } from "../../../components/View/Tab/ViewTab"
import { ViewTabControl } from "../../../components/View/Tab/ViewTabControl"
import { ViewTabs } from "../../../components/View/Tab/ViewTabs"
import { View } from "../../../components/View/View"
import { useDownloadMaster } from "../../../services/master/apis/masterDownloadApi"
import { useUploadMasterBusinessMaster, useUploadMasterCompanyMaster, useUploadMasterContainerTypeMaster, useUploadMasterContractMaster, useUploadMasterContractPartsMaster, useUploadMasterContractPriceHistoryMaster, useUploadMasterContractRouteMaster, useUploadMasterCurrencyMaster, useUploadMasterCustomerMaster, useUploadMasterDCMaster, useUploadMasterGlobalPartsMaster, useUploadMasterIncotermsMaster, useUploadMasterPackageTypeMaster, useUploadMasterPaymentTermsMaster, useUploadMasterPortMaster, useUploadMasterRegionMaster, useUploadMasterShippingRouteMaster, useUploadMasterStateMaster, useUploadMasterSupplierMaster, useUploadMasterUnitPartsMaster, useUploadMasterUomMaster } from "../../../services/master/apis/masterUploadApi"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { FileDownloadFactor } from "../../../services/master/models/FileDownloadFactor"
import { FileTypeWithResult } from "../../../services/master/models/FileTypeWithMasterResult"
import { TnmIncoterms } from "../../../services/master/models/TnmIncoterms"
import { TnmRegion } from "../../../services/master/models/TnmRegion"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { useIsPowerUser } from "../../../utils/useIsPowerUser"
import { useFieldChecker } from "../../../utils/ValidatorUtils"
import { applicationActions } from "../../Application/applicationSlice"

interface MGS010PcUiProps {
    path: string,
    data: FileDownloadFactor,
    setData: React.Dispatch<React.SetStateAction<FileDownloadFactor>>,
    zones: { first: string, second: string }[],
    regions: TnmRegion[],
    incoterms: TnmIncoterms[],
    cbdsList: TnvCbds[],
}

enum TabId {
    company = 0,
    individual = 1,
    contract = 2,
    common = 3,
}

export const MGS010PcUi = (props: MGS010PcUiProps) => {
    const { zones, regions, data, setData, incoterms, cbdsList, path } = props
    const intl = useIntl()
    const fileTypeList = useFileType()
    const [typeList, setTypeList] = useState<string[]>([])
    const [tabId, setTabId] = useState<number>(TabId.company)
    const onSelectedValueChange = useCallback((value: string | number) => {
        setTabId(value as number)
    }, [setTabId])

    const actions = usePrepareActions(typeList, data, tabId)

    const show_masterOverView = path !== '/masterOverView' ? true : false

    if (show_masterOverView) {
        return <View actions={actions}>
            <ViewTabControl>
                <ViewTabs onSelectedValueChange={onSelectedValueChange} selectedValue={tabId}>
                    <ViewTab label={intl.formatMessage({ id: 'tab.company' })} value={TabId.company} />
                    <ViewTab label={intl.formatMessage({ id: 'tab.individual' })} value={TabId.individual} />
                    <ViewTab label={intl.formatMessage({ id: 'tab.contract' })} value={TabId.contract} />
                    <ViewTab label={intl.formatMessage({ id: 'tab.common' })} value={TabId.common} />
                </ViewTabs>
                <ViewPanels>
                    <ViewPanel value={TabId.company}>
                        <SectionCard>
                            <SectionCardContent>
                                <CompanyView zones={zones} regions={regions} fileTypeList={fileTypeList} data={data} setData={setData} typeList={typeList} setTypeList={setTypeList} />
                            </SectionCardContent>
                        </SectionCard>
                    </ViewPanel>
                    <ViewPanel value={TabId.individual}>
                        <SectionCard>
                            <SectionCardContent>
                                <IndividualView fileTypeList={fileTypeList} incoterms={incoterms} cbdsList={cbdsList} data={data} setData={setData} typeList={typeList} setTypeList={setTypeList} />
                            </SectionCardContent>
                        </SectionCard>
                    </ViewPanel>
                    <ViewPanel value={TabId.contract}>
                        <SectionCard>
                            <SectionCardContent>
                                <ContractView fileTypeList={fileTypeList} data={data} setData={setData} typeList={typeList} setTypeList={setTypeList} />
                            </SectionCardContent>
                        </SectionCard>
                    </ViewPanel>
                    <ViewPanel value={TabId.common}>
                        <SectionCard>
                            <SectionCardContent>
                                <CommonView fileTypeList={fileTypeList} typeList={typeList} setTypeList={setTypeList} />
                            </SectionCardContent>
                        </SectionCard>
                    </ViewPanel>
                </ViewPanels>
            </ViewTabControl>
        </View>
    } else {
        return <View noHeader actions={actions}>
            {/* <Button variant='contained' style={{ marginLeft: '1570px' }}  >111</Button> */}
            {/* <SectionCardContent> */}
            <div>
                <CommonView fileTypeList={fileTypeList} typeList={typeList} setTypeList={setTypeList} />
            </div>
            {/* </SectionCardContent> */}
        </View>
    }



}

const CompanyView = ({ zones, regions, fileTypeList, data, setData, typeList, setTypeList }: {
    zones: { first: string, second: string }[],
    regions: TnmRegion[],
    fileTypeList: FileTypeWithResult[],
    data: FileDownloadFactor,
    setData: React.Dispatch<React.SetStateAction<FileDownloadFactor>>, typeList: string[], setTypeList: React.Dispatch<React.SetStateAction<string[]>>
}) => {
    const intl = useIntl()
    const zonesMap = useMemo(() => zones.map(m => [m.first, m.second] as [string, string]), [zones])
    const regionsMap = useMemo(() => regions.map(m => [m.regionCode, m.regionCode] as [string, string]), [regions])
    const [messages, setMessages] = useState<Message[]>([])
    const fields = useMemo(() => ({
        cbdsCode: { labelId: 'field.cbdsCode', length: { max: 20 } },
        cbdsName: { labelId: 'field.cbdsName', length: { max: 255 } },
        cbdsShortCode: { labelId: 'field.cbdsShortCode', length: { max: 30 } }
    }), [])
    const filedCheck = useFieldChecker(fields, setMessages)
    return <>
        <Form data={data} setData={setData} labelDisplay="block" helperDisplay="tooltip" messages={messages} setMessages={setMessages} ensure={filedCheck}>
            <StringItem field="cbdsCode" label={intl.formatMessage({ id: 'field.cbdsCode' })} />
            <StringItem field="cbdsName" label={intl.formatMessage({ id: 'field.cbdsName' })} />
            <Placeholder />
            <StringItem field="cbdsShortCode" label={intl.formatMessage({ id: 'field.cbdsShortCode' })} />
            <EntryItem field="regionCode" label={intl.formatMessage({ id: 'field.regionCode' })} entries={regionsMap} />
            <Placeholder />
            <EntryItem field="timeZone" label={intl.formatMessage({ id: 'field.timeZone' })} entries={zonesMap} />
        </Form>
        <CheckModuleView fileTypeList={fileTypeList} moduleId={TabId.company} typeList={typeList} setTypeList={setTypeList}></CheckModuleView>
    </>
}

const ContractView = ({ fileTypeList, data, setData, typeList, setTypeList }: {
    fileTypeList: FileTypeWithResult[], data: FileDownloadFactor,
    setData: React.Dispatch<React.SetStateAction<FileDownloadFactor>>, typeList: string[], setTypeList: React.Dispatch<React.SetStateAction<string[]>>
}) => {
    const intl = useIntl()
    const [messages, setMessages] = useState<Message[]>([])
    const fields = useMemo(() => ({
        contractNo: { labelId: 'contractNo', length: { max: 30 } },
        contractRouteNo: { labelId: 'contractRouteNo', length: { max: 30 } },
        description: { labelId: 'description', length: { max: 255 } }
    }), [])
    const filedCheck = useFieldChecker(fields, setMessages)

    return <>
        <Form data={data} setData={setData} labelDisplay="block" helperDisplay="tooltip" messages={messages} setMessages={setMessages} ensure={filedCheck}>
            <StringItem field="contractNo" label={intl.formatMessage({ id: 'field.contractNo' })} />
            <StringItem field="contractRouteNo" label={intl.formatMessage({ id: 'contractRouteNo' })} />
            <Placeholder />
            <StringItem field="description" label={intl.formatMessage({ id: 'description' })} />
        </Form>
        <CheckModuleView fileTypeList={fileTypeList} moduleId={TabId.contract} typeList={typeList} setTypeList={setTypeList}></CheckModuleView>
    </>
}

const IndividualView = ({ fileTypeList, incoterms, data, setData, cbdsList, typeList, setTypeList }: {
    fileTypeList: FileTypeWithResult[], incoterms: TnmIncoterms[], cbdsList: TnvCbds[], data: FileDownloadFactor,
    setData: React.Dispatch<React.SetStateAction<FileDownloadFactor>>, typeList: string[], setTypeList: React.Dispatch<React.SetStateAction<string[]>>
}) => {
    const intl = useIntl()
    const [messages, setMessages] = useState<Message[]>([])
    const incotermsMap = useMemo(() => incoterms.map(m => [m.incotermsId, m.incotermsCode] as [number, string]), [incoterms])
    const buList: [string, string][] = useMemo(() => cbdsList.filter((m) => m.cbdsType === CbdsType.BU).map((m) => [m.cbdsUid, m.cbdsCode]), [cbdsList])
    const fields = useMemo(() => ({
        incotermsPlace: { labelId: 'field.incotermsPlace', length: { max: 100 } }
    }), [])
    const filedCheck = useFieldChecker(fields, setMessages)

    return <>
        <Form data={data} setData={setData} labelDisplay="block" helperDisplay="tooltip" messages={messages} setMessages={setMessages} ensure={filedCheck}>
            {/* <CodeItem field="orderFrequency" label={intl.formatMessage({ id: 'field.orderFrequency' })} code={CodeCategory.OrderFrequency} /> */}
            <StringItem field="forecastNum" label={intl.formatMessage({ id: 'field.forecastNum' })} />
            {/* <EntryItem field="targetLeadTime" label={intl.formatMessage({ id: 'field.targetLeadtime' })} entries={numMap} /> */}
            <CodeItem field="customsFlag" label={intl.formatMessage({ id: 'field.customsFlag' })} code={CodeCategory.CustomsFlag} />
            <Placeholder />
            <EntryItem field="incotermsId" label={intl.formatMessage({ id: 'field.incotermsCode' })} entries={incotermsMap} />
            <StringItem field="incotermsPlace" label={intl.formatMessage({ id: 'field.incotermsPlace' })} />
            <Placeholder />
            <EntryItem field="expAgencyUid" label={intl.formatMessage({ id: 'expAgency' })} entries={buList} />
            <EntryItem field="impAgencyUid" label={intl.formatMessage({ id: 'impAgency' })} entries={buList} />
            <Placeholder />
            <CodeItem field="hscodeOutputFlag" label={intl.formatMessage({ id: 'printHsCodeFlag' })} code={CodeCategory.HscodeOutputFlag} />
        </Form>
        <CheckModuleView fileTypeList={fileTypeList} moduleId={TabId.individual} typeList={typeList} setTypeList={setTypeList}></CheckModuleView>
    </>
}

const CommonView = ({ fileTypeList, typeList, setTypeList }: {
    fileTypeList: FileTypeWithResult[], typeList: string[], setTypeList: React.Dispatch<React.SetStateAction<string[]>>
}) => {
    return <>
        <CheckModuleView fileTypeList={fileTypeList} moduleId={TabId.common} typeList={typeList} setTypeList={setTypeList}></CheckModuleView>
    </>
}

const CheckModuleView = ({ fileTypeList, moduleId, typeList, setTypeList }: {
    fileTypeList: FileTypeWithResult[], moduleId: number, typeList: string[], setTypeList: React.Dispatch<React.SetStateAction<string[]>>
}) => {

    const classes = useStyles()
    const defaultFileList = useMemo(() => fileTypeList.filter(m => m.moduleId === moduleId).map(m => { m.checked = typeList.includes(m.fileName); return m }), [fileTypeList, moduleId, typeList])
    const [commonFileList, setCommonFileList] = useState<FileTypeWithResult[]>(defaultFileList)
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTypeList(typeList => {
            typeList.includes(event.target.value) ? typeList.splice(typeList.indexOf(event.target.value), 1) : typeList.push(event.target.value)
            return typeList
        })

        setCommonFileList(list => {
            return list.map(m => { m.checked = typeList.includes(m.fileName); return m })
        })
    }

    return <Grid container direction="column" spacing={3} className={classes.root}>
        <FormGroup>
            {commonFileList.map((data, index) => {
                return <Grid item xs={4} style={{ marginTop: '15px' }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                value={data.fileName}
                                onChange={handleChange}
                                name={data.moduleName}
                                color="primary"
                                checked={data.checked}
                            />
                        }
                        label={data.masterName}
                    />
                </Grid>
            })}
        </FormGroup>
    </Grid>
}

const usePrepareActions = (typeList: string[], data: FileDownloadFactor, tabId: TabId) => {
    data.fileTypeList = typeList
    const dispatch = useDispatch()
    const downloadByFilter = useDownloadMaster()

    const download = useCallback(() => {
        if (typeList.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'download' }, messages: { code: 'w0343' } }))
            return
        }
        downloadByFilter({ ...data, fileTypeList: typeList })
    }, [data, dispatch, downloadByFilter, typeList])

    return tabId !== TabId.common ? [
        <DownloadCallbackViewAction outlined access="MARS.MGS010.DOWNLOAD" callback={download} />
    ] : [
        <DownloadCallbackViewAction outlined access="MARS.MGS010.DOWNLOAD" callback={download} />,
        <UploadFileAction typeList={typeList} />
    ]
}

const UploadFileAction = ({ typeList }: { typeList: string[] }) => {
    const uploadCompanyMaster = useUploadMasterCompanyMaster()
    const uploadBUMaster = useUploadMasterBusinessMaster()
    const uploadCustomerMaster = useUploadMasterCustomerMaster()
    const uploadSupplierMaster = useUploadMasterSupplierMaster()
    const uploadDcMaster = useUploadMasterDCMaster()
    const uploadRegionMaster = useUploadMasterRegionMaster()
    const uploadUomMaster = useUploadMasterUomMaster()
    const uploadContainerTypeMaster = useUploadMasterContainerTypeMaster()
    const uploadPackageTypeMaster = useUploadMasterPackageTypeMaster()
    const uploadCurrencyMaster = useUploadMasterCurrencyMaster()
    const uploadIncotermsMaster = useUploadMasterIncotermsMaster()
    const uploadPortMaster = useUploadMasterPortMaster()
    const uploadGlobalPartsMaster = useUploadMasterGlobalPartsMaster()
    const uploadUnitPartsMaster = useUploadMasterUnitPartsMaster()
    const uploadContractMaster = useUploadMasterContractMaster()
    const uploadPaymentTermsMaster = useUploadMasterPaymentTermsMaster()
    const uploadContractParts = useUploadMasterContractPartsMaster()
    const uploadContractPriceMaster = useUploadMasterContractPriceHistoryMaster()
    const uploadShippingRouteMaster = useUploadMasterShippingRouteMaster()
    const uploadContractRouteMaster = useUploadMasterContractRouteMaster()
    const uploadMasterStateMaster = useUploadMasterStateMaster()
    const isPowerUser = useIsPowerUser()
    const dispatch = useDispatch()

    const uploadMethod = useCallback((files: FileList | null) => {
        if (files === null || typeList.length === 0 || typeList.length > 1) {
            dispatch(applicationActions.pushError({ title: { code: 'upload' }, messages: { code: 'w0342' } }))
            return
        }

        switch (typeList[0]) {
            case "MLF010":
                uploadCompanyMaster({ file: files[0] }, { serialized: true })
                break
            case "MLF020":
                uploadBUMaster({ file: files[0] }, { serialized: true })
                break
            case "MLF030":
                uploadCustomerMaster({ file: files[0] }, { serialized: true })
                break
            case "MLF040":
                uploadSupplierMaster({ file: files[0] }, { serialized: true })
                break
            case "MLF050":
                uploadDcMaster({ file: files[0] }, { serialized: true })
                break
            case "MLF060":
                if (isPowerUser) {
                    uploadPortMaster({ file: files[0] }, { serialized: true })
                } else {
                    dispatch(applicationActions.pushError({ title: { code: 'upload' }, messages: { code: 'w0592' } }))
                }
                break
            case "MLF070":
                uploadGlobalPartsMaster({ file: files[0] }, { serialized: true })
                break
            case "MLF080":
                uploadUnitPartsMaster({ file: files[0] }, { serialized: true })
                break
            case "MLF090":
                uploadContractMaster({ file: files[0] }, { serialized: true })
                break
            case "MLF100":
                uploadContractRouteMaster({ file: files[0] }, { serialized: true })
                break
            case "MLF110":
                uploadPaymentTermsMaster({ file: files[0] }, { serialized: true })
                break
            case "MLF120":
                uploadShippingRouteMaster({ file: files[0] }, { serialized: true })
                break
            case "MLF140":
                uploadContractParts({ file: files[0] }, { serialized: true })
                break
            case "MLF150":
                uploadContractPriceMaster({ file: files[0] }, { serialized: true })
                break
            case "MGF010":
                if (isPowerUser) {
                    uploadRegionMaster({ file: files[0] }, { serialized: true })
                } else {
                    dispatch(applicationActions.pushError({ title: { code: 'upload' }, messages: { code: 'w0592' } }))
                }
                break
            case "MGF020":
                if (isPowerUser) {
                    uploadUomMaster({ file: files[0] }, { serialized: true })
                } else {
                    dispatch(applicationActions.pushError({ title: { code: 'upload' }, messages: { code: 'w0592' } }))
                }
                break
            case "MGF030":
                if (isPowerUser) {
                    uploadContainerTypeMaster({ file: files[0] }, { serialized: true })
                } else {
                    dispatch(applicationActions.pushError({ title: { code: 'upload' }, messages: { code: 'w0592' } }))
                }
                break
            case "MGF040":
                if (isPowerUser) {
                    uploadPackageTypeMaster({ file: files[0] }, { serialized: true })
                } else {
                    dispatch(applicationActions.pushError({ title: { code: 'upload' }, messages: { code: 'w0592' } }))
                }
                break
            case "MGF050":
                if (isPowerUser) {
                    uploadCurrencyMaster({ file: files[0] }, { serialized: true })
                } else {
                    dispatch(applicationActions.pushError({ title: { code: 'upload' }, messages: { code: 'w0592' } }))
                }
                break
            case "MGF060":
                if (isPowerUser) {
                    uploadIncotermsMaster({ file: files[0] }, { serialized: true })
                } else {
                    dispatch(applicationActions.pushError({ title: { code: 'upload' }, messages: { code: 'w0592' } }))
                }
                break
            case "MGF070":
                if (isPowerUser) {
                    uploadMasterStateMaster({ file: files[0] }, { serialized: true })
                } else {
                    dispatch(applicationActions.pushError({ title: { code: 'upload' }, messages: { code: 'w0592' } }))
                }
                break
            default:
                break
        }

    }, [dispatch, isPowerUser, typeList, uploadBUMaster, uploadCompanyMaster, uploadContainerTypeMaster, uploadContractMaster, uploadContractParts, uploadContractPriceMaster, uploadContractRouteMaster, uploadCurrencyMaster, uploadCustomerMaster, uploadDcMaster, uploadGlobalPartsMaster, uploadIncotermsMaster, uploadMasterStateMaster, uploadPackageTypeMaster, uploadPaymentTermsMaster, uploadPortMaster, uploadRegionMaster, uploadShippingRouteMaster, uploadSupplierMaster, uploadUnitPartsMaster, uploadUomMaster])

    return <UploadCallbackViewAction access="MARS.MGS010.UPLOAD" callback={uploadMethod} />
}

const useFileType = () => {
    const intl = useIntl()
    return useMemo(() => [
        { masterName: intl.formatMessage({ id: 'page.company' }), fileName: "MLF010", moduleName: intl.formatMessage({ id: 'tab.company' }), moduleId: TabId.company },
        { masterName: intl.formatMessage({ id: 'page.businessUnit' }), fileName: "MLF020", moduleName: intl.formatMessage({ id: 'tab.company' }), moduleId: TabId.company },
        { masterName: intl.formatMessage({ id: 'page.customer' }), fileName: "MLF030", moduleName: intl.formatMessage({ id: 'tab.company' }), moduleId: TabId.company },
        { masterName: intl.formatMessage({ id: 'page.supplier' }), fileName: "MLF040", moduleName: intl.formatMessage({ id: 'tab.company' }), moduleId: TabId.company },
        { masterName: intl.formatMessage({ id: 'page.distributionCenter' }), fileName: "MLF050", moduleName: intl.formatMessage({ id: 'tab.company' }), moduleId: TabId.company },

        { masterName: intl.formatMessage({ id: 'page.globalParts' }), fileName: "MLF070", moduleName: intl.formatMessage({ id: 'tab.individual' }), moduleId: TabId.individual },
        { masterName: intl.formatMessage({ id: 'page.unitParts' }), fileName: "MLF080", moduleName: intl.formatMessage({ id: 'tab.individual' }), moduleId: TabId.individual },
        { masterName: intl.formatMessage({ id: 'page.contract' }), fileName: "MLF090", moduleName: intl.formatMessage({ id: 'tab.individual' }), moduleId: TabId.individual },
        { masterName: intl.formatMessage({ id: 'page.shippingRoute' }), fileName: "MLF120", moduleName: intl.formatMessage({ id: 'tab.individual' }), moduleId: TabId.individual },
        { masterName: intl.formatMessage({ id: 'page.paymentTerm' }), fileName: "MLF110", moduleName: intl.formatMessage({ id: 'tab.individual' }), moduleId: TabId.individual },

        { masterName: intl.formatMessage({ id: 'page.contractParts' }), fileName: "MLF140", moduleName: intl.formatMessage({ id: 'tab.contract' }), moduleId: TabId.contract },
        { masterName: intl.formatMessage({ id: 'page.contractPrice' }), fileName: "MLF150", moduleName: intl.formatMessage({ id: 'tab.contract' }), moduleId: TabId.contract },
        { masterName: intl.formatMessage({ id: 'page.contractPacking' }), fileName: "MLF250", moduleName: intl.formatMessage({ id: 'tab.contract' }), moduleId: TabId.contract },
        { masterName: intl.formatMessage({ id: 'page.contractRouteDetail' }), fileName: "MLF100", moduleName: intl.formatMessage({ id: 'tab.contract' }), moduleId: TabId.contract },

        // { masterName: intl.formatMessage({ id: 'page.paymentTerm' })}, fileName: "MLF110", moduleName: intl.formatMessage({ id: 'tab.common'  }), moduleId: TabId.common },
        { masterName: intl.formatMessage({ id: 'page.region' }), fileName: "MGF010", moduleName: intl.formatMessage({ id: 'tab.common' }), moduleId: TabId.common },
        { masterName: intl.formatMessage({ id: 'page.state' }), fileName: "MGF070", moduleName: intl.formatMessage({ id: 'tab.common' }), moduleId: TabId.common },
        { masterName: intl.formatMessage({ id: 'page.uom' }), fileName: "MGF020", moduleName: intl.formatMessage({ id: 'tab.common' }), moduleId: TabId.common },
        { masterName: intl.formatMessage({ id: 'page.containerType' }), fileName: "MGF030", moduleName: intl.formatMessage({ id: 'tab.common' }), moduleId: TabId.common },
        { masterName: intl.formatMessage({ id: 'page.packageType' }), fileName: "MGF040", moduleName: intl.formatMessage({ id: 'tab.common' }), moduleId: TabId.common },
        { masterName: intl.formatMessage({ id: 'page.currency' }), fileName: "MGF050", moduleName: intl.formatMessage({ id: 'tab.common' }), moduleId: TabId.common },
        { masterName: intl.formatMessage({ id: 'page.port' }), fileName: "MLF060", moduleName: intl.formatMessage({ id: 'tab.common' }), moduleId: TabId.common },
        { masterName: intl.formatMessage({ id: 'page.incoterms' }), fileName: "MGF060", moduleName: intl.formatMessage({ id: 'tab.common' }), moduleId: TabId.common },
    ], [intl])
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginLeft: "80px",
        marginTop: "30px",
    },
}))