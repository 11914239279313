import { useCallback, useEffect, useMemo, useState } from "react"
import { useGetCcCustomerList, useGetCcRequestListByPage, useGetCcSupplerList } from "../../../services/order/apis/CCRequestApi"
import { CCRequestListFactor, cCRequestListFactorFromJson, cCRequestListFactorToJson } from "../../../services/order/models/CCRequestListFactor"
import { CCRequestListResult } from "../../../services/order/models/CCRequestListResult"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { ORS010PcUi } from "./ORS010PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }

const searchCachekey = sessionKeys.Filters_ORS010

export const ORS010 = () => {
    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? cCRequestListFactorFromJson(JSON.parse(filters)) : null
    }, [])

    const [filters, setFilters] = useState<CCRequestListFactor>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<CCRequestListResult[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [customerList, setCustomerList] = useState<{ customerId: number, customerCode: string }[]>([])
    const [supplierList, setSupplierList] = useState<{ supplierId: number, supplierCode: string }[]>([])

    const getData = useGetCcRequestListByPage()
    const search = useCallback((filters: CCRequestListFactor) => {
        getData(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(cCRequestListFactorToJson(filters)))
        })
    }, [getData, setData])

    useSearchOnLoad(search, filters)

    const getCustomer = useGetCcCustomerList()
    useEffect(() => {
        getCustomer(undefined, { silent: true }).then(result => {
            setCustomerList(result || [])
        })
    }, [getCustomer])

    const getSupplier = useGetCcSupplerList()
    useEffect(() => {
        getSupplier(undefined, { silent: true }).then(result => {
            setSupplierList(result || [])
        })
    }, [getSupplier])

    return <ORS010PcUi
        filters={filters}
        setFilters={setFilters}
        search={search}
        data={data}
        totalCount={totalCount}
        customerList={customerList}
        supplierList={supplierList}
    />
}