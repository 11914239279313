import { useCallback, useState } from "react"
import { useGetDeliveryList } from "../../../services/dashboard/apis/dashboardApi"
import { VerdorProfileFactor } from "../../../services/dashboard/models/VendorDasboardFactor"
import { VerdorProfileResult } from "../../../services/dashboard/models/VendorDasboardResult"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { DAS013PcUi } from "./DAS013PcUi"

export const DAS013 = () => {

    const [filters, setFilters] = useState<VerdorProfileFactor>({ startMonth: new Date(), endMonth: new Date() })
    const [venders, setVenders] = useState<VerdorProfileResult[]>([])

    const getCurrentDeliverList = useGetDeliveryList();
    const search = useCallback((filters: VerdorProfileFactor) => {
        getCurrentDeliverList(filters, { silent: true, serialized: true }).then(result => {
            setVenders(result || [])
        })
    }, [getCurrentDeliverList])

    useSearchOnLoad(search, filters)

    return <DAS013PcUi
        filters={filters}
        setFilters={setFilters}
        venders={venders}
        search={search}
    />
}