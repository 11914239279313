import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"
import { ActiveFlag } from "../enums/ActiveFlag"

export type BusinessUnitResult = Required & Partial<Optional> & TableEntity
type BusinessUnitResultJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const businessUnitResultFromJson: Project<BusinessUnitResultJson, BusinessUnitResult> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
    activeFlag: ActiveFlag,
}

interface JsonRequired {
    buCode: string,
}

interface JsonOptional {
    companyId: number,
    companyCode: string,
    tempBuId: number,
    buId: number,
    buName: string,
    buShortCode: string,
    regionCode: string,
    address1: string,
    address2: string,
    address3: string,
    address4: string,
    postalCode: string,
    contact1: string,
    fax1: string,
    telephone1: string,
    email1: string,
    contact2: string,
    fax2: string,
    telephone2: string,
    email2: string,
    timeZone: string,
    calendarId: number,
    calendarCode: string,
    activeFlag: number,
}