import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"

export type SpotOrderListResult = Required & Partial<Optional>
type SpotOrderListResultJson = JsonRequired & Partial<JsonOptional>
export const spotOrderListResultFromJson: Project<SpotOrderListResultJson, SpotOrderListResult> = json => ({
    ...json,
    poDate: dateFromJson(json.poDate),
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'poDate'> {
    poDate: Date,
}

interface JsonRequired {

}

interface JsonOptional {
    orderId: number,

    poSpotId: number,
    preSpotId: number,
    preSoId: number,
    buId: number,
    orderNo: string,
    orderType: number,
    poType: number,
    sellerBuId: number,
    supplierId: number,
    poDate: string,
    shippingMode: number,
    remark: string,
    completedDate: string,
    paymentTermsId: number,
    currency: string,
    receiveDcId: number,
    submittedBy: string,
    submittedDate: string,
    spotLevel: number,
    status: number,
    createdBy: string,
    createdDate: string,
    updatedBy: string,
    updatedDate: string,
    version: number,

    //  List<OPS021TotPoSpotDetailBo> ops021TotPoSpotDetailBoLst;

}
