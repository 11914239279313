import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Input, makeStyles, Typography } from "@material-ui/core"
import { ArrowDropDown, ArrowDropUp, Close } from "@material-ui/icons"
import { Autocomplete } from "@material-ui/lab"
import {
    Action,
    ColumnFreeze, ColumnOrdering, ColumnResizing,
    Data,
    DataGrid,
    DataTypePreset, Filtering, NumberTypeProvider,
    PaginationLayout,
    Row,
    RowActionProvider, Searching, Sorting,
    TableBodyLayout,
    TableHeaderLayout,
    TableLayout,
    TableRow,
    ToolbarActionProvider,
    ToolbarLayout
} from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { EntriesItem, EntryItem, Form, StringItem } from "@rithe/form"
import { Records } from "@rithe/utils"
import React, { useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DeleteCallbackRowAction } from "../../../components/DataGrid/rowActions/DeleteCallbackRowAction"
import { EditCallbackRowAction } from "../../../components/DataGrid/rowActions/EditCallbackRowAction"
import { ViewCallbackRowAction } from "../../../components/DataGrid/rowActions/ViewCallbackRowAction"
import { CreateCallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/CreateCallbackToolbarAction"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { CodeItem } from "../../../components/Form/CodeItem"
import { View } from "../../../components/View/View"
import { useFunctionStore } from "../../../Root"
import {
    useCreateCargoStatusSetting,
    useDeleteCargoStatusSetting,
    useEditCargoStatusSetting,
    useGetDefaultCargoStatus,
    useGetRelatedCustomerContract
} from "../../../services/master/apis/cargoStatusSettingApi"
import { useGetCustomerList } from "../../../services/master/apis/customerApi"
import { ActiveFlag } from "../../../services/master/enums/ActiveFlag"
import { CargoRole } from "../../../services/master/enums/CargoRole"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { CargoStatusSetting, CargoStatusSettingList } from "../../../services/master/models/CargoStatusSettingList"
import { EditCargoStatusSetting } from "../../../services/master/models/EditCargoStatusSetting"
import { useGetCompanyType } from "../../../utils/ApplicationUtils"
import { applicationActions } from "../../Application/applicationSlice"

interface MLS250PcUiProps {
    data?: CargoStatusSettingList,
    refresh: () => void,
}

export const MLS250PcUi = (props: MLS250PcUiProps) => {
    const { data, refresh } = props

    const intl = useIntl()

    return <View>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={1}
                title={intl.formatMessage({ id: 'basicInfo' })}
                subtitle={intl.formatMessage({ id: 'basicInfoSub' })}
            />
            <SectionCardContent>
                <CompanyInfoView data={data} />
            </SectionCardContent>
        </SectionCard>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={2}
                title={intl.formatMessage({ id: 'cargoStatus' })}
                subtitle={intl.formatMessage({ id: 'cargoStatusSub' })}
            />
            <SectionCardContent>
                <SpecificSettingList data={data} refresh={refresh} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const CompanyInfoView = (props: { data?: CargoStatusSettingList }) => {
    const intl = useIntl()
    const companyType = useGetCompanyType()
    const currentCode = companyType === CbdsType.DC ? 'field.dcCode' : companyType === CbdsType.SUPP
        ? 'field.supplierCode' : companyType === CbdsType.BU ? 'field.buCode' : 'field.customerCode'
    const currentName = companyType === CbdsType.DC ? 'field.dcName' : companyType === CbdsType.SUPP
        ? 'field.supplierName' : companyType === CbdsType.BU ? 'field.buName' : 'field.customerName'
    return <Form data={props.data} labelDisplay="block" helperDisplay="tooltip">
        <StringItem field="companyCode" readonly label={intl.formatMessage({ id: currentCode })} />
        <StringItem field="companyName" readonly label={intl.formatMessage({ id: currentName })} />
    </Form>
}

const SpecificSettingList = (props: { data?: CargoStatusSettingList, refresh: () => void }) => {
    const intl = useIntl()
    const { data, refresh } = props
    const rows = data?.settings ?? []
    const columns = useMemo(() => [
        { field: 'cargoStatusSettingName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.cargoStatusSettingName' }), width: 300 },
        { field: 'customerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerCode' }), width: 170 },
        { field: 'role', dataTypeName: CodeCategory.CargoRole, title: intl.formatMessage({ id: 'field.cargoRole' }), width: 200 },
        { field: 'contractNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contractNo' }), width: 200, getCellValue: (row: Row) => row.contracts.map((c: any) => c.contractNo).join(', ') },
        { field: 'cargoStatus', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.cargoStatus' }), width: 300, getCellValue: (row: Row) => row.cargoStatuss.join(', ') },
    ], [intl])
    const getRowId = useCallback((row: any) => row.cargoStatusSettingId, [])
    const actionProps = useMemo(() => ({ refresh }), [refresh])
    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <NumberTypeProvider name="exchangeRate" options={{ minimumFractionDigits: 6, maximumFractionDigits: 6 }} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.CargoRole} />
        <Data rows={rows} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={CreateToolbarAction} actionProps={actionProps} />
        <RowActionProvider name="view" Action={ViewRowAction} />
        <RowActionProvider name="edit" Action={EditRowAction} actionProps={actionProps} />
        <RowActionProvider name="delete" Action={DeleteRowAction} actionProps={actionProps} />
        <ColumnFreeze />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <Sorting />
        <Filtering />
        <Action width={120} />
    </DataGrid>
}

const CreateToolbarAction = ({ refresh }: { refresh: () => void }) => {
    const [open, setOpen] = React.useState(false)
    const onClose = useCallback(() => setOpen(false), [])
    const create = useCreateCargoStatusSetting()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'confirm' }), [intl])

    const onSave = useCallback((setting: Required<CreateSetting>) => {
        const successFunction = () => {
            dispatch(applicationActions.pushSuccess({
                title: intl.formatMessage({ id: 'post /lcbm-shipping-detail-api/api/createCargoStatusSetting' }),
                messages: [{ code: 'notice.success' }],
            }))
            refresh()
            setOpen(false)
        }
        const functionId = functionStore.register(() => {
            create({ ...setting, confirmed: false }, { serialized: true, silent: true }).then((warningMessages) => {
                if (warningMessages.length === 0) {
                    successFunction()
                } else {
                    const functionId = functionStore.register(() => {
                        create({ ...setting, confirmed: true }, { serialized: true, silent: true }).then(() => {
                            successFunction()
                        })
                    })
                    dispatch(applicationActions.pushWarning({
                        title: intl.formatMessage({ id: 'confirm' }),
                        messages: warningMessages,
                        actions: [{
                            label: 'CANCEL'
                        }, {
                            functionId,
                            label: 'CONFIRM',
                        }]
                    }))

                }
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [create, dispatch, functionStore, intl, refresh, title])

    return <>
        <CreateCallbackToolbarAction access="MARS.MLS250.SAVE" callback={() => setOpen(true)} />
        {open && <SettingDialog mode="create" open={open} onClose={onClose} onSave={onSave} />}
    </>
}

const ViewRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const [open, setOpen] = React.useState(false)
    const onClose = useCallback(() => setOpen(false), [])

    return <>
        <ViewCallbackRowAction tableRow={tableRow} callback={() => setOpen(true)} />
        {open && <SettingDialog mode="view" open={open} onClose={onClose} setting={mapView(tableRow.row as any)} />}
    </>
}

const mapView = (row: CargoStatusSetting): ViewSetting => {
    return {
        cargoStatusSettingId: row.cargoStatusSettingId,
        cargoStatusSettingName: row.cargoStatusSettingName,
        customerId: row.customerId,
        role: row.role,
        cargoStatuss: row.cargoStatuss,
        contractIds: row.contracts.map((c: any) => c.contractId),
    }
}

const EditRowAction = ({ tableRow, refresh }: DataGridRowActionProps & { refresh: () => void }) => {
    const [open, setOpen] = React.useState(false)
    const onClose = useCallback(() => setOpen(false), [])
    const edit = useEditCargoStatusSetting()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'confirm' }), [intl])
    const editConfirm = useCallback((data: EditCargoStatusSetting, messages: any) => {
        const functionId = functionStore.register(() => {
            edit(data, { serialized: true }).then(() => {
                refresh()
                setOpen(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: messages,
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [dispatch, edit, functionStore, refresh, title])

    const onSave = useCallback((setting: EditSetting) => {
        const functionId = functionStore.register(() => {
            edit({ ...setting, confirmed: false }, { serialized: true }).then((result) => {
                const messages: any = result
                if (messages && messages?.length && messages.length > 0) {
                    editConfirm({ ...setting, confirmed: true }, messages)
                } else {
                    refresh()
                    setOpen(false)
                }
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [dispatch, edit, editConfirm, functionStore, refresh, title])

    return <>
        <EditCallbackRowAction access="MARS.MLS250.SAVE" tableRow={tableRow} callback={() => setOpen(true)} />
        {open && <SettingDialog mode="edit" open={open} onClose={onClose} onSave={onSave} setting={mapEdit(tableRow.row as any)} />}
    </>
}

const mapEdit = (row: CargoStatusSetting): EditSetting => {
    return {
        cargoStatusSettingId: row.cargoStatusSettingId,
        cargoStatusSettingName: row.cargoStatusSettingName,
        customerId: row.customerId,
        role: row.role,
        cargoStatuss: row.cargoStatuss,
        contractIds: row.contracts.map((c: any) => c.contractId),
    }
}

const DeleteRowAction = ({ tableRow, refresh }: DataGridRowActionProps & { refresh: () => void }) => {
    const del = useDeleteCargoStatusSetting()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'delete' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const onSave = useCallback((tableRow: TableRow) => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            del({ cargoStatusSettingId: tableRow.row?.cargoStatusSettingId || -1 }).then(() => {
                refresh()
            }).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [del, dispatch, functionStore, refresh, title])

    return <DeleteCallbackRowAction access="MARS.MLS250.SAVE" tableRow={tableRow} callback={onSave} disabled={disabled} />
}

interface CreateSettingDialogProps {
    mode: 'create',
    open: boolean,
    onClose: () => void,
    onSave: (setting: Required<CreateSetting>) => void,
}

interface CreateSetting {
    cargoStatusSettingName?: string,
    customerId?: number,
    role?: CargoRole,
    contractIds?: number[],
    cargoStatuss?: string[],
}

interface EditSettingDialogProps {
    mode: 'edit',
    open: boolean,
    onClose: () => void,
    setting: EditSetting,
    onSave: (setting: Required<EditSetting>) => void,
}

interface EditSetting {
    cargoStatusSettingId: number,
    cargoStatusSettingName: string,
    customerId: number,
    role: CargoRole,
    contractIds: number[],
    cargoStatuss: string[],
}

interface ViewSettingDialogProps {
    mode: 'view',
    open: boolean,
    onClose: () => void,
    setting: ViewSetting,
}

interface ViewSetting {
    cargoStatusSettingId: number,
    cargoStatusSettingName: string,
    customerId: number,
    role: CargoRole,
    contractIds: number[],
    cargoStatuss: string[],
}

const SettingDialog = (props: ViewSettingDialogProps | CreateSettingDialogProps | EditSettingDialogProps) => {
    const { open, onClose } = props
    const [customerPairs, setCustomerPairs] = React.useState<[number, string][]>([])
    const [contractPairs, setContractPairs] = React.useState<[number, string][]>([])
    const [contractMap, setContractMap] = React.useState<{ [role: number]: { [customerId: number]: [number, string][] } }>({})
    const [defaultCargoStatuss, setDefaultCargoStatuss] = React.useState<{ [role: number]: string[] }>({})
    const [cargoStatuss, setCargoStatuss] = React.useState<string[]>(props.mode === 'create' ? [/*DEFAULT*/] : props.setting.cargoStatuss)
    const getCustomers = useGetCustomerList()
    const getContractMap = useGetRelatedCustomerContract()
    const getDefaultCargoStatuss = useGetDefaultCargoStatus()

    const [setting, setSetting] = React.useState<CreateSetting | EditSetting | ViewSetting>(props.mode === 'create' ? {} : props.setting)
    React.useEffect(() => {
        setSetting(setting => ({ ...setting, cargoStatuss }))
    }, [cargoStatuss])

    const loadedRef = React.useRef(false)
    React.useEffect(() => {
        if (!loadedRef.current) {
            getCustomers({ statusList: [ActiveFlag.ACTIVE] }, { serialized: true, silent: true }).then(data => {
                setCustomerPairs(data.data?.map(c => [c.customerId!, c.customerCode]) ?? [])
            })
            getContractMap(undefined, { serialized: true, silent: true }).then(data => {
                const map: { [role: number]: { [customerId: number]: [number, string][] } } = {}
                data.forEach(item => {
                    if (!map[item.role]) {
                        map[item.role] = {}
                    }
                    if (!map[item.role][item.customerId]) {
                        map[item.role][item.customerId] = []
                    }
                    map[item.role][item.customerId].push([item.contractId, item.contractNo])
                })
                setContractMap(map)
                if (setting.role !== undefined && setting.customerId !== undefined) {
                    setContractPairs(map[setting.role]?.[setting.customerId] ?? [])
                }
            })
            getDefaultCargoStatuss(undefined, { serialized: true, silent: true }).then(data => {
                setDefaultCargoStatuss(data)
            })
            loadedRef.current = true
        }
    }, [getContractMap, getCustomers, getDefaultCargoStatuss, setting.customerId, setting.role])

    const onRoleChange = React.useCallback((role: number) => {
        if (props.mode === 'create' || props.mode === 'edit') {
            if (setting.role !== role) {
                setCargoStatuss(defaultCargoStatuss[setting.role ?? -1] ?? [])
            }
            if (setting.customerId !== undefined) {
                setContractPairs(contractMap[role]?.[setting.customerId] ?? [])
            }
        }
    }, [contractMap, defaultCargoStatuss, props.mode, setting.customerId, setting.role])

    const onCustomerChange = React.useCallback((customerId: number) => {
        if (props.mode === 'create' || props.mode === 'edit') {
            if (setting.role !== undefined) {
                setContractPairs(contractMap[setting.role]?.[customerId] ?? [])
            }
        }
    }, [contractMap, props.mode, setting.role])

    const onChange = React.useCallback((index: number, value: string) => {
        setCargoStatuss(s => [...s.slice(0, index), value, ...s.slice(index + 1)])
    }, [])
    const onDelete = React.useCallback((index: number) => {
        setCargoStatuss(s => [...s.slice(0, index), ...s.slice(index + 1)])
    }, [])
    const onMoveUp = React.useCallback((index: number) => {
        if (index > 0) {
            setCargoStatuss(s => {
                const [a, b] = [s[index - 1], s[index]]
                return [...s.slice(0, index - 1), b, a, ...s.slice(index + 1)]
            })
        }
    }, [])
    const onMoveDown = React.useCallback((index: number) => {
        if (index < cargoStatuss.length - 1) {
            setCargoStatuss(s => {
                const [a, b] = [s[index], s[index + 1]]
                return [...s.slice(0, index), b, a, ...s.slice(index + 2)]
            })
        }
    }, [cargoStatuss.length])
    const onAdd = React.useCallback(() => {
        setCargoStatuss(s => [...s, ""])
    }, [])

    return <Dialog open={open} onClose={onClose}>
        <DialogTitle>
            <Typography variant="h3">
                <FormattedMessage id="cargoStatusSetting" />
            </Typography>
        </DialogTitle>
        <DialogContent style={{ backgroundColor: '#F3F5F8' }}>
            <div>
                <Form readonly={props.mode === 'view'} data={setting} setData={setSetting} columnCount={1}>
                    <StringItem field="cargoStatusSettingName" label={<FormattedMessage id="field.cargoStatusSettingName" />} />
                    <EntryItem field="customerId" onChange={onCustomerChange} entries={customerPairs} label={<FormattedMessage id="field.customerCode" />} />
                    <CodeItem field="role" onChange={onRoleChange} code={CodeCategory.CargoRole} label={<FormattedMessage id="field.cargoRole" />} />
                    <EntriesItem field="contractIds" entries={contractPairs} label={<FormattedMessage id="field.contractNo" />} />
                </Form>
                <Typography variant="subtitle1">
                    <FormattedMessage id="cargoStatusAlert" />
                </Typography>
                {cargoStatuss.map((status, index) => <CargoStatus
                    index={index}
                    value={status}
                    isFirst={index === 0}
                    isLast={index === cargoStatuss.length - 1}
                    readonly={props.mode === 'view'}
                    suggestions={setting.role !== undefined ? defaultCargoStatuss[setting.role] : undefined}
                    onChange={onChange}
                    onDelete={onDelete}
                    onMoveUp={onMoveUp}
                    onMoveDown={onMoveDown}
                />)}
                {props.mode !== 'view' && <AddUnit callback={onAdd} />}
            </div>
        </DialogContent>
        <DialogActions>
            {props.mode !== 'view' && <DialogAction title={<FormattedMessage id="saveAndExist" />} callback={() => { props.onSave(setting as any) }} />}
            <DialogAction title={<FormattedMessage id="close" />} callback={onClose} />
        </DialogActions>
    </Dialog>
}

const CargoStatus = (props: {
    index: number,
    value: string,
    isFirst?: boolean,
    isLast?: boolean,
    readonly?: boolean,
    suggestions?: string[],
    onChange: (index: number, value: string) => void,
    onDelete: (index: number) => void,
    onMoveUp: (index: number) => void,
    onMoveDown: (index: number) => void,
}) => {
    const { index, value, isFirst, isLast, readonly, suggestions, onChange, onDelete, onMoveUp, onMoveDown } = props
    const styles = useStyles()
    return <div className={styles.setting}>
        <span className={styles.move}>
            <IconButton disabled={isFirst || readonly} className={styles.arrow} onClick={() => onMoveUp(index)}><ArrowDropUp /></IconButton>
            <IconButton disabled={isLast || readonly} className={styles.arrow} onClick={() => onMoveDown(index)}><ArrowDropDown /></IconButton>
        </span>
        <div className={styles.input}>
            <Autocomplete
                autoHighlight
                autoComplete
                selectOnFocus
                handleHomeEndKeys
                fullWidth
                renderInput={({ InputProps, inputProps, InputLabelProps, ...restProps }) => <Input
                    disableUnderline
                    {...restProps}
                    {...InputProps}
                    inputProps={{ ...inputProps }}
                    readOnly={readonly}
                />}
                freeSolo
                style={{ backgroundColor: 'white' }}
                value={value ?? null}
                disabled={readonly}
                options={suggestions ?? []}
                onInputChange={(_, value) => onChange(index, value)}
            />
        </div>
        <IconButton disabled={readonly} onClick={() => onDelete(index)}><Close /></IconButton>
    </div >
}

const AddUnit = (props: { callback: () => void }) => {
    const { callback } = props
    const styles = useStyles()
    return <Button onClick={callback} className={styles.add}>
        <FormattedMessage id="addUnit" />
    </Button>
}

const useStyles = makeStyles(theme => ({
    setting: {
        width: '100%',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
    },
    move: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginRight: theme.spacing(1),
    },
    arrow: {
        width: 18,
        height: 18,
    },
    input: {
        borderRadius: 8,
        flex: '1 1 auto',
        padding: `0px ${theme.spacing(2)}px`,
        backgroundColor: theme.palette.common.white,
    },
    add: {
        border: `2px dashed ${theme.palette.primary.light}`,
        backgroundColor: 'rgba(200,230,255,0.3)',
        width: '100%',
    }
}))