import { DialogActions, DialogContent, IconButton, Tooltip } from "@material-ui/core"
import { Edit } from "@material-ui/icons"
import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { Break, Form, Message, NumberItem, StringItem } from '@rithe/form'
import { Objects, Records } from "@rithe/utils"
import React, { memo, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { Access } from "../../../components/Access/Access"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { WithdrawCallbackRowAction } from "../../../components/DataGrid/rowActions/WithdrawCallbackRowAction"
import { CreateCallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/CreateCallbackToolbarAction"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { UploadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/UploadGroupedToolbarAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { ActiveFlagTypeProvider } from "../../../components/DataGrid/typeProviders/ActiveFlagTypeProvider"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { DarkDialog } from "../../../components/Dialog/DarkDialog"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { CodeItem } from "../../../components/Form/CodeItem"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { useFunctionStore } from "../../../Root"
import { useEditCreditRule, useSaveCreditRuled, useWithdrawCreditRule } from "../../../services/master/apis/creditRuleApi"
import { useDownloadCreditRuleMasterByCreditId, useDownloadCreditRuleMasterByFilter } from "../../../services/master/apis/masterDownloadApi"
import { useUploadMasterCreditRuleMaster } from "../../../services/master/apis/masterUploadApi"
import { ActiveFlag } from "../../../services/master/enums/ActiveFlag"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { CreditCheckInfoFactor } from "../../../services/master/models/CreditCheckInfoFactor"
import { CreditCheckInfoResult } from "../../../services/master/models/CreditCheckInfoResult"
import { useFieldChecker, useFormValidater } from "../../../utils/ValidatorUtils"
import { applicationActions } from "../../Application/applicationSlice"

interface MLS180PcUiProps {
    filters: CreditCheckInfoFactor,
    setFilters: React.Dispatch<React.SetStateAction<CreditCheckInfoFactor>>,
    creditList: CreditCheckInfoResult[],
    search: (filters: CreditCheckInfoFactor) => void
}

export const MLS180PcUi = (props: MLS180PcUiProps) => {

    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({ filters, setFilters, creditList, search }: MLS180PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])
    const columns = useMemo(() => [
        { field: 'creditRuleNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.creditRuleNo' }), width: 200 },
        { field: 'openArFlag', dataTypeName: CodeCategory.OpenArFlag, title: intl.formatMessage({ id: 'field.openArFlag' }), width: 200 },
        { field: 'openArRange', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.openArRange' }), width: 200 },
        { field: 'unvoicedOrderFlag', dataTypeName: CodeCategory.UnvoiceOrderFlag, title: intl.formatMessage({ id: 'field.unvoiceOrderFlag' }), width: 250 },
        { field: 'unvoicedOrderTargetRange', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.unvoiceOrderTargetRange' }), width: 300 },
        { field: 'status', dataTypeName: CodeCategory.ActiveFlag, title: intl.formatMessage({ id: 'field.status' }), width: 200 },
        { field: 'createdDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.createdDate' }), width: 200 },
        { field: 'updatedBy', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.updatedBy' }), width: 200 },
        { field: 'updatedDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.updatedDate' }), width: 200 },
    ], [intl])
    const getRowId = useCallback((row: any) => row.creditId, [])
    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search }), [filters, setFilters, search])
    const display = useCallback((tableRow: TableRow) => !tableRow.row?.status && tableRow.row?.status !== ActiveFlag.INACTIVE, [])
    const actionProps = useMemo(() => ({ search, filters }), [filters, search])
    const actionProps2 = useMemo(() => ({ selections, filters }), [filters, selections])
    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar} >
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <ActiveFlagTypeProvider />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OpenArFlag} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.UnvoiceOrderFlag} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.ActiveFlag} />
        <Data rows={creditList} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={CreateAction} actionProps={actionProps} />
        <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps2} />
        <ToolbarActionProvider Action={UploadAction} actionProps={actionProps} />
        <RowActionProvider name="edit" Action={EditRowAction} actionProps={actionProps} display={display} />
        <RowActionProvider name="withdraw" Action={WithdrawRowAction} actionProps={actionProps} display={display} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['createdDate', 'updatedBy', 'updatedDate']}
            columnSettings={{
                creditRuleNo: { disableUserControl: true }
            }}
            ToolbarButton={ColumnVisibilityToolbarButton}
        />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={120} />
    </DataGrid>
}

const FilterItem = (props: {
    filters: CreditCheckInfoFactor,
    setFilters: React.Dispatch<React.SetStateAction<CreditCheckInfoFactor>>,
    search: (filters: CreditCheckInfoFactor) => void
}) => {

    const { filters, setFilters, search } = props

    const clear = useCallback((filters: CreditCheckInfoFactor) => {
        return { page: filters.page }
    }, [])
    const filterCounter = useCallback((filters: CreditCheckInfoFactor) => {
        return [
            filters.creditRuleNo,
            filters.openArFlagList,
            filters.unvoicedOrderFlagList,
            filters.statusList
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])

    const intl = useIntl()
    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="creditRuleNo" label={intl.formatMessage({ id: 'field.creditRuleNo' })} />
        <CodesItem field="openArFlagList" label={intl.formatMessage({ id: 'field.openArFlag' })} code={CodeCategory.OpenArFlag} />
        <CodesItem field="unvoicedOrderFlagList" label={intl.formatMessage({ id: 'field.unvoiceOrderFlag' })} code={CodeCategory.UnvoiceOrderFlag} />
        <CodesItem field="statusList" label={intl.formatMessage({ id: 'field.status' })} code={CodeCategory.ActiveFlag} />
        {/* <DateRangeItem field="createdDate" label={intl.formatMessage({ id: 'field.createdDate' })} getValue={createdDateGetValue} mapValue={createdDateMapValue} /> */}
        {/* <DateRangeItem field="updatedDate" label={intl.formatMessage({ id: 'field.updatedDate' })} getValue={updatedDateGetValue} mapValue={updatedDateMapValue} /> */}
    </FilterToolbarItem>
}


const CreateAction = ({ search, filters }: { search: (filters: CreditCheckInfoFactor) => void, filters: CreditCheckInfoFactor }) => {
    const [actions, setActions] = useState<CreditCheckInfoResult>({})
    return <SelectCreditCheckRule actions={actions} setActions={setActions} editFlag={false} search={search} filters={filters} />
}

const DownloadAction = ({ selections, filters }: { selections: number[], filters: CreditCheckInfoFactor }) => {
    const downloadMasterBySelectionApi = useDownloadCreditRuleMasterByCreditId()
    const downloadMasterByFilterApi = useDownloadCreditRuleMasterByFilter()
    const downloadMaster = useCallback(() => {
        selections.length <= 0 ? downloadMasterByFilterApi(Objects.delete(filters, 'page')) : downloadMasterBySelectionApi({ creditIdList: selections })
    }, [downloadMasterByFilterApi, downloadMasterBySelectionApi, filters, selections])
    return <DownloadGroupedToolbarAction access="MASTER.MLS180.DOWNLOAD">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="download" />} callback={downloadMaster} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}

const UploadAction = ({ search, filters }: { search: (filter: CreditCheckInfoFactor) => void, filters: CreditCheckInfoFactor }) => {
    const uploadMasterApi = useUploadMasterCreditRuleMaster()
    const uploadMaster = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            files && uploadMasterApi({ file: files[0] }, { serialized: true }).then(() => search(filters))
        })
    }, [filters, search, uploadMasterApi])
    return <UploadGroupedToolbarAction access="MASTER.MLS180.UPLOAD">
        {(popupUpload, onClose) => <>
            <GroupedCallbackItem label={<FormattedMessage id="upload" />} callback={() => uploadMaster(popupUpload)} onClose={onClose} />
        </>}
    </UploadGroupedToolbarAction>
}

const EditRowAction = ({ tableRow, search, filters }: DataGridRowActionProps & { search: (filters: CreditCheckInfoFactor) => void, filters: CreditCheckInfoFactor }) => {
    const data: CreditCheckInfoResult = {
        creditId: tableRow.row?.creditId,
        creditRuleNo: tableRow.row?.creditRuleNo,
        openArFlag: tableRow.row?.openArFlag,
        openArRange: tableRow.row?.openArRange,
        unvoicedOrderFlag: tableRow.row?.unvoicedOrderFlag,
        unvoicedOrderTargetRange: tableRow.row?.unvoicedOrderTargetRange,
        buId: tableRow.row?.buId,
        supplierId: tableRow.row?.supplierId,
        status: tableRow.row?.status,
        version: tableRow.row?.version,
    }
    const [actions, setActions] = useState<CreditCheckInfoResult>(data)
    return <SelectCreditCheckRule actions={actions} setActions={setActions} editFlag={true} search={search} filters={filters} />
}

const WithdrawRowAction = ({ tableRow, search, filters }: DataGridRowActionProps & { search: (filters: CreditCheckInfoFactor) => void, filters: CreditCheckInfoFactor }) => {
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'withdraw' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const withdrawApi = useWithdrawCreditRule()
    const callback = useCallback(() => {
        if (tableRow.row) {
            const functionId = functionStore.register(() => {
                setDisabled(true)
                withdrawApi({ creditId: tableRow.row?.creditId }, { serialized: true }).then(() => {
                    search(filters)
                }).finally(() => {
                    setDisabled(false)
                })
            })
            dispatch(applicationActions.pushWarning({
                title: title,
                messages: { code: 'c0001', args: [title] },
                actions:[{
                    label: 'CANCEL'
                },{
                    functionId,
                    label:'CONFIRM',
                }]
            }))
        }
    }, [dispatch, filters, functionStore, search, tableRow.row, title, withdrawApi])

    return <WithdrawCallbackRowAction access="MASTER.MLS180.WITHDRAW" tableRow={tableRow} callback={callback} disabled={disabled}/>
}

const SelectCreditCheckRule = memo(({ actions, setActions, editFlag, search, filters }: {
    actions: CreditCheckInfoResult,
    setActions: React.Dispatch<React.SetStateAction<CreditCheckInfoResult>>,
    editFlag: boolean
    search: (filters: CreditCheckInfoFactor) => void
    filters: CreditCheckInfoFactor,

}) => {
    const [open, setOpen] = useState<boolean>(false)
    const [messages, setMessages] = useState<Message[]>([])
    const createRule = useSaveCreditRuled()
    const editRule = useEditCreditRule()

    const fields = useMemo(() => getFormCheckFields(true), [])
    const filedCheck = useFieldChecker(fields, setMessages)
    const formValidate = useFormValidater(setMessages, fields)
    const intl = useIntl()
    const dispatch = useDispatch()
    const onOpen = useCallback(() => {
        setOpen(true)
    }, [setOpen])
    const onClose = useCallback(() => setOpen(false), [])
    const functionStore = useFunctionStore()
    const [disabled, setDisabled] = useState<boolean>(false)
    const title = useMemo(() => intl.formatMessage({ id: 'confirm' }), [intl])
    const confrim = useCallback(() => {
        if (actions && formValidate(actions)) {
            const checkResult = checkNumber(actions)
            if (checkResult) {
                dispatch(applicationActions.pushError({ title: { code: checkResult }, messages: { code: 'integerCheck' } }))
                return
            }
            if (editFlag) {
                //edit
                editRule(actions, { serialized: true }).then(result => {
                    if (result) {
                        search(filters)
                        setOpen(false)
                    }
                })
            } else {
                const functionId = functionStore.register(() => {
                    //new
                    setDisabled(true)
                    createRule(actions, { serialized: true }).then(result => {
                        if (result) {
                            search(filters)
                            setOpen(false)
                            setActions({})
                        }
                    }).finally(() => {
                        setDisabled(false)
                    })
                })

                dispatch(applicationActions.pushWarning({
                    title: title,
                    messages: { code: 'c0001', args: [title] },
                    actions:[{
                        label: 'CANCEL'
                    },{
                        functionId,
                        label:'CONFIRM',
                    }]
                }))
                
            }
        }

    }, [actions, createRule, dispatch, editFlag, editRule, filters, formValidate, functionStore, search, setActions, title])

    const readonly1 = actions.openArFlag === 0 || actions.openArFlag === undefined || actions.openArFlag === null
    const readonly2 = actions.unvoicedOrderFlag === 0 || actions.unvoicedOrderFlag === undefined || actions.unvoicedOrderFlag === null
    return <>
        {!editFlag ?
            <CreateCallbackToolbarAction access="MASTER.MLS180.CREATE" callback={onOpen} />
            :
            <Access access="MASTER.MLS180.EDIT"><Tooltip title={<FormattedMessage id="edit" />}><IconButton onClick={onOpen}>{<Edit />}</IconButton></Tooltip></Access>
        }
        <DarkDialog keepMounted={false} maxWidth={"lg"} open={open} onClose={onClose} >
            <DialogHeader onClose={onClose}><FormattedMessage id={editFlag ? 'editRule' : 'addNewRule'} /></DialogHeader>
            <DialogContent >
                <Form data={actions} setData={setActions} labelDisplay="block" helperDisplay="tooltip" messages={messages} setMessages={setMessages} ensure={filedCheck} columnCount={2}>
                    <StringItem field="creditRuleNo" required readonly={editFlag} label={intl.formatMessage({ id: 'field.creditRuleNo' })} />
                    <Break />
                    <CodeItem field="openArFlag" label={intl.formatMessage({ id: 'field.openArFlag' })} code={CodeCategory.OpenArFlag} />
                    <NumberItem field="openArRange" readonly={readonly1} label={intl.formatMessage({ id: 'field.openArRange' })}></NumberItem>
                    <Break />
                    <CodeItem field="unvoicedOrderFlag" label={intl.formatMessage({ id: 'field.unvoiceOrderFlag' })} code={CodeCategory.UnvoiceOrderFlag} />
                    <NumberItem field="unvoicedOrderTargetRange" readonly={readonly2} label={intl.formatMessage({ id: 'field.unvoiceOrderTargetRange' })}></NumberItem>
                </Form>
            </DialogContent>
            <DialogActions>
                <DialogAction title={<FormattedMessage id="confirm" />} callback={confrim} disabled={disabled}/>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={onClose} />
            </DialogActions>
        </DarkDialog >
    </>
})

const getFormCheckFields = (isIssue: boolean) => {
    return ({
        creditRuleNo: { labelId: 'field.creditRuleNo', required: true, length: { max: 200 } },
        openArFlag: { labelId: 'field.openArFlag', required: true, length: { max: 1 } },
        unvoicedOrderFlag: { labelId: 'field.unvoicedOrderFlag', required: true, length: { max: 1 } },
    })
}


const checkNumber = (data: CreditCheckInfoResult) => {
    if (data) {
        if (data.openArFlag === 1 && data.openArRange) {
            const rangeString: string = String(data.openArRange)
            if (data.openArRange < 0 || (!(/(^[1-9]\d*$)/.test(rangeString)))) {
                return 'field.openArRange'
            }
        }
        if (data.unvoicedOrderFlag === 1 && data.unvoicedOrderTargetRange) {
            const targetString: string = String(data.unvoicedOrderTargetRange)
            if (data.unvoicedOrderTargetRange < 0 || (!(/(^[1-9]\d*$)/.test(targetString)))) {
                return 'field.unvoiceOrderTargetRange'
            }
        }
    }

}
