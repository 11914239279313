import { Column, ColumnFreeze, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Editing, Filtering, NumberTypeProvider, PaginationLayout, Row, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarLayout } from "@rithe/data-grid"
import { Break, EntryItem, Form, Message, NumberItem, StringItem } from "@rithe/form"
import { GridItem } from "@rithe/ui"
import { Arrays, Records } from "@rithe/utils"
import React, { memo, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { SaveCallbackViewAction } from "../../../components/Action/SaveCallbackViewAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { ActiveFlagTypeProvider } from "../../../components/DataGrid/typeProviders/ActiveFlagTypeProvider"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { PercentTypeProvider } from "../../../components/DataGrid/typeProviders/PercentTypeProvider"
import { CodeItem } from "../../../components/Form/CodeItem"
import { SectionTitle } from "../../../components/SectionTitle/SectionTitle"
import { View } from "../../../components/View/View"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { useSaveContract } from "../../../services/master/apis/contractApi"
import { useDownloadcontractPartsAndPriceMasterCurrentPageData } from "../../../services/master/apis/masterDownloadApi"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { ContractType } from "../../../services/master/enums/ContractType"
import { CustomsFlag } from "../../../services/master/enums/CustomsFlag"
import { DeliveryTypeFlag } from "../../../services/master/enums/DeliveryTypeFlag"
import { ContractHeaderResult } from "../../../services/master/models/ContractHeaderResult"
import { ContractPartsResult } from "../../../services/master/models/ContractPartsResult"
import { TnmCurrency } from "../../../services/master/models/TnmCurrency"
import { TnmDistributionCenter } from "../../../services/master/models/TnmDistributionCenter"
import { TnmIncoterms } from "../../../services/master/models/TnmIncoterms"
import { TnmPaymentTerms } from "../../../services/master/models/TnmPaymentTerms"
import { TnmShippingRoute } from "../../../services/master/models/TnmShippingRoute"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { useFieldChecker, useFormValidater } from "../../../utils/ValidatorUtils"

interface MLS091PcUiProps {
    shippingRouteList: TnmShippingRoute[],
    partsData: ContractPartsResult[],
    headerData: ContractHeaderResult,
    cbdsList: TnvCbds[],
    dcList: TnmDistributionCenter[],
    paymentTermList: TnmPaymentTerms[],
    currencyList: TnmCurrency[],
    incotermList: TnmIncoterms[],
    mode: ScreenMode,
    setHeaderData: React.Dispatch<React.SetStateAction<ContractHeaderResult>>,
    setPartsData: React.Dispatch<React.SetStateAction<ContractPartsResult[]>>,
    search: () => void,
}
export const MLS091PcUi = (props: MLS091PcUiProps) => {
    const { headerData, mode, partsData, search } = props
    const intl = useIntl()
    const [messages, setMessages] = useState<Message[]>([])
    const actions = mode === ScreenMode.VIEW ? [] : [<SaveAction headerData={headerData} partsData={partsData} setMessages={setMessages} search={search} />]

    return <View actions={actions}>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={1}
                title={intl.formatMessage({ id: 'ContractDetailInformation' })}
                subtitle='View Detail contract information.'
            />
            <SectionCardContent>
                <HeaderInfo {...props} messages={messages} setMessages={setMessages} />
            </SectionCardContent>
        </SectionCard>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={2}
                title={intl.formatMessage({ id: 'PartsListInformation' })}
                subtitle='View parts list information for contract.'
            />
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const HeaderInfo = memo((props: MLS091PcUiProps & { messages: Message[], setMessages: React.Dispatch<React.SetStateAction<Message[]>> }) => {
    const intl = useIntl()
    const { headerData, setHeaderData, paymentTermList, currencyList, cbdsList, dcList, incotermList, shippingRouteList, mode, messages, setMessages } = props
    const { contractType, orderFrequency, contractId, customsFlag } = headerData

    const dcMap: [number, string][] = useMemo(() => dcList.map(m => [m.dcId, m.dcCode]), [dcList])
    const allCbdsMap: [string, string][] = useMemo(() => cbdsList.map(m => [m.cbdsUid, m.cbdsCode]), [cbdsList])
    const buCustMap: [string, string][] = useMemo(() => cbdsList.filter((m) => m.cbdsType === CbdsType.CUST || m.cbdsType === CbdsType.BU).map(m => [m.cbdsUid, m.cbdsCode]), [cbdsList])
    const dcCustMap: [string, string][] = useMemo(() => cbdsList.filter((m) => m.cbdsType === CbdsType.CUST || m.cbdsType === CbdsType.DC).map(m => [m.cbdsUid, m.cbdsCode]), [cbdsList])
    const incoMap: [number, string][] = useMemo(() => incotermList.map((m) => [m.incotermsId, m.incotermsCode]), [incotermList])
    const paymentMap: [number, string][] = useMemo(() => paymentTermList.map((m) => [m.paymentTermsId, m.paymentTermsCode + '(' + m.description + ')']), [paymentTermList])


    const onDataChange = useCallback<React.Dispatch<React.SetStateAction<ContractHeaderResult>>>(nextDraftDataFunc => {
        setHeaderData((org) => {
            const data = typeof nextDraftDataFunc === 'function' ? nextDraftDataFunc(org) : nextDraftDataFunc
            return data.customsFlag !== CustomsFlag.Y ? {
                ...data,
                printHscodeFlag: undefined,
                exportCCAgency: undefined,
                importCCAgency: undefined,
            } : { ...data }
        })
    }, [setHeaderData])
    // const shipperMap: [string, string][] = useMemo(() => cbdsList.filter(f => f.cbdsType === CbdsType.DC).map(m => [m.cbdsUid, m.cbdsCode]), [cbdsList])

    const srMap: [number, string][] = useMemo(() => shippingRouteList.map((m) => [m.srId, m.shippingRouteCode]), [shippingRouteList])
    const readonly = mode === ScreenMode.VIEW
    const readonlyForCustoms = readonly || CustomsFlag.Y !== customsFlag
    const showItem1 = contractType === ContractType.CUST_BU || contractType === ContractType.CUST_SUPP
    const showItem2 = contractType === ContractType.BU_SUPP || contractType === ContractType.CUST_SUPP
    const fields = useMemo(() => getFormCheckFields(true, contractType), [contractType])
    const filedCheck = useFieldChecker(fields, setMessages)
    if (contractType === undefined) {
        return <></>
    }

    const readonly2 = mode === ScreenMode.VIEW ? true : (contractId !== undefined && contractId !== null) ? true : (contractType === ContractType.CUST_BU || contractType === ContractType.CUST_SUPP) ? false : true

    const leadtimeName = orderFrequency === 10 ? 'field.targetleadtimeMonth' : orderFrequency === 20 ? 'field.targetleadtimeWeek' :
        orderFrequency === 30 ? 'field.targetleadtimeBiweek' : orderFrequency === 40 ? 'field.targetleadtimeTenDay' : 'field.targetLeadtime'

    return <Form data={headerData} setData={onDataChange} labelDisplay="block" helperDisplay="tooltip" messages={messages} setMessages={setMessages} ensure={filedCheck}>
        <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="basicInfo" /></SectionTitle></GridItem>
        <StringItem readonly required field='contractNo' label={intl.formatMessage({ id: 'field.contractNo' })} />
        <StringItem field="sellerCode" required readonly label={intl.formatMessage({ id: 'seller' })} />
        <EntryItem field="consignee" required readonly={readonly} label={intl.formatMessage({ id: 'consigneeCode' })} entries={buCustMap} />

        <CodeItem field="contractType" required readonly label={intl.formatMessage({ id: 'field.contractType' })} code={CodeCategory.ContractType} />
        <StringItem field="buyerCode" required readonly label={intl.formatMessage({ id: 'buyer' })} />
        <EntryItem field="accountee" required readonly={readonly} label={intl.formatMessage({ id: 'accounteeCode' })} entries={buCustMap} />

        {showItem1 && <CodeItem field="businessType" required readonly={readonly} label={intl.formatMessage({ id: 'field.businessType' })} code={CodeCategory.BusinessType} />}
        {showItem1 && <CodeItem field="orderFrequency" required readonly={readonly} label={intl.formatMessage({ id: 'field.orderFrequency' })} code={CodeCategory.OrderFrequency} />}
        <EntryItem field="deliveryTo" required readonly={readonly} label={intl.formatMessage({ id: 'deliveryToCode' })} entries={dcCustMap} />
        <Break />

        {showItem1 && <NumberItem field="forecastNum" required readonly={readonly} label={intl.formatMessage({ id: 'field.forecastNum' })} />}
        {showItem1 && <NumberItem field="targetLeadtime" required readonly={readonly} label={intl.formatMessage({ id: leadtimeName })} />}
        <Break />
        {showItem2 && <NumberItem field="confirmOrderLeadtime" required readonly={readonly} label={intl.formatMessage({ id: 'field.confirmOrderLeadtime' })} />}
        {showItem2 && <NumberItem field="leadtime" required readonly={readonly} label={intl.formatMessage({ id: 'field.leadtime' })} />}
        {/* {showItem2 && <CodeItem field="deliveryTypeFlag" required readonly={props.mode === ScreenMode.VIEW} label={intl.formatMessage({ id: 'field.deliveryTypeFlag' })} codeCategory={CodeCategory.DeliveryTypeFlag} />} */}
        <Break />
        {showItem2 && <NumberItem field="deliveryPlanStartDate" required readonly={readonly} label={intl.formatMessage({ id: 'field.deliveryPlanStartDate' })} />}
        {showItem2 && <NumberItem field="deliveryPlanEndDate" readonly={readonly} label={intl.formatMessage({ id: 'field.deliveryPlanEndDate' })} />}
        <Break />
        <StringItem required readonly field='contractShortCode' label={intl.formatMessage({ id: 'field.contractShortCode' })} />
        <StringItem field="description" readonly={readonly} label={intl.formatMessage({ id: 'description' })} colSpan={2} />

        <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="paymentInfo" /></SectionTitle></GridItem>
        <EntryItem field="paymentTermsId" required readonly={readonly} label={intl.formatMessage({ id: 'field.paymentTermsCode' })} entries={paymentMap} />
        <EntryItem field="currency" required readonly={readonly} label={intl.formatMessage({ id: 'field.currency' })} entries={currencyList.map((m) => [m.currencyCode, m.currencyCode])} />
        <CodeItem field="priceBasis" readonly={readonly} label={intl.formatMessage({ id: 'field.priceBasis' })} code={CodeCategory.PriceBasis} />

        <GridItem columnSpan={3} ><SectionTitle size="small"><FormattedMessage id="deliveryInfo" /></SectionTitle></GridItem>
        <EntryItem field="receiveDcId" readonly={readonly2} label={intl.formatMessage({ id: 'receiveDcCode' })} entries={dcMap} />
        <EntryItem field="shipperDcId" readonly={readonly} label={intl.formatMessage({ id: 'receiveShipperCode' })} entries={dcMap} />
        {/* shipperUid */}
        <EntryItem field="srId" readonly={readonly} label={intl.formatMessage({ id: 'field.shippingRouteCode' })} entries={srMap} />
        <EntryItem field="spotSrId" readonly={readonly} label={intl.formatMessage({ id: 'spotSR' })} entries={srMap} />
        {showItem1 && <CodeItem field="autoDiFlag" readonly={readonly} label={intl.formatMessage({ id: 'autoDiFlag' })} code={CodeCategory.AutoDiFlag} />}
        <Break />

        <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="customsInfo" /></SectionTitle></GridItem>
        <CodeItem field="customsFlag" readonly={readonly} label={intl.formatMessage({ id: 'field.customsFlag' })} code={CodeCategory.CustomsFlag} />
        <Break />
        <EntryItem field="exportCCAgency" readonly={readonlyForCustoms} label={intl.formatMessage({ id: 'exportCCAgency' })} entries={allCbdsMap} />
        <EntryItem field="importCCAgency" readonly={readonlyForCustoms} label={intl.formatMessage({ id: 'importCCAgency' })} entries={allCbdsMap} />
        <CodeItem field="printHscodeFlag" readonly={readonlyForCustoms} label={intl.formatMessage({ id: 'field.printHscodeFlag' })} code={CodeCategory.HscodeOutputFlag} />

        <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="incotermsInfo" /></SectionTitle></GridItem>
        <EntryItem field="incotermsId" readonly={readonly} label={intl.formatMessage({ id: 'field.incotermsCode' })} entries={incoMap} />
        <StringItem field="incotermsPlace" readonly={readonly} label={intl.formatMessage({ id: 'field.incotermsPlace' })} />
    </Form>
})


const DataTable = memo((props: MLS091PcUiProps) => {
    const intl = useIntl()
    const { headerData, partsData, setPartsData, mode } = props
    const { contractType } = headerData

    const columns = useMemo(() => {
        const partsCategories = [{ key: 'partsFirst', value: intl.formatMessage({ id: 'partsName' }) }]
        const priceCogetories = [{ key: 'priceSecond', value: intl.formatMessage({ id: 'priceName' }) }]
        const packingCogetories = [{ key: 'packingThird', value: intl.formatMessage({ id: 'packingName' }) }]
        const psrtsColumns = [
            { width: 220, field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsNo' }), categories: partsCategories },
            { width: 220, field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), categories: partsCategories },
            { width: 220, field: 'sellerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sellerPartsNo' }), categories: partsCategories },
            { width: 220, field: 'buyerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'buyerPartsNo' }), categories: partsCategories },
            { width: 300, field: 'unitPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsName' }), categories: partsCategories },
            { field: 'activeFlag', dataTypeName: CodeCategory.ActiveFlag, title: intl.formatMessage({ id: 'field.activeFlag' }), width: 180, categories: partsCategories }
        ] as Column[]

        const bucolumns = (contractType === ContractType.CUST_BU || contractType === ContractType.BU_BU) ? [
            { field: 'repackingType', dataTypeName: CodeCategory.RepackingType, title: intl.formatMessage({ id: 'field.repackingType' }), width: 200, categories: partsCategories },
            { field: 'safetyStockDays', dataTypeName: 'number', title: intl.formatMessage({ id: 'safetyStockDays' }), width: 200, categories: partsCategories },
            { field: 'safetyStockPct', dataTypeName: 'percent', title: intl.formatMessage({ id: 'field.safetyStockPct' }), width: 200, categories: partsCategories },
        ] as Column[] : []

        const supplierColumns = (contractType === ContractType.CUST_BU || contractType === ContractType.CUST_SUPP) ? [
            { field: 'fluctuationRatio', dataTypeName: 'percent', title: intl.formatMessage({ id: 'field.fluctuationRatio' }), width: 200, categories: partsCategories },
            { field: 'fcFluctuationRatio', dataTypeName: 'percent', title: intl.formatMessage({ id: 'field.fcFluctuationRatio' }), width: 230, categories: partsCategories },
        ] as Column[] : []

        const priceColumns = [
            { width: 180, categories: priceCogetories, field: 'currentPrice', dataTypeName: 'number', title: intl.formatMessage({ id: 'currentPrice' }) },
            { width: 180, categories: priceCogetories, field: 'currentApplyDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'currentApplyDate' }) },
            { width: 180, categories: priceCogetories, field: 'nextPrice', dataTypeName: 'number', title: intl.formatMessage({ id: 'nextPrice' }) },
            { width: 180, categories: priceCogetories, field: 'nextApplyDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'nextApplyDate' }) },
        ] as Column[]

        const buOrSupplierColumns = (contractType === ContractType.CUST_BU || contractType === ContractType.CUST_SUPP) ? [
            { field: 'endUser1', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.endUser1' }), width: 300, categories: partsCategories },
            { field: 'endUser2', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.endUser2' }), width: 300, categories: partsCategories },
            { field: 'endUser3', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.endUser3' }), width: 300, categories: partsCategories },
            { field: 'endUser4', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.endUser4' }), width: 300, categories: partsCategories },
            { field: 'endUser5', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.endUser5' }), width: 300, categories: partsCategories },
        ] as Column[] : []

        const packingColumns = [
            { width: 180, field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), categories: packingCogetories },
            { width: 180, field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), categories: packingCogetories },
            { width: 180, field: 'buyerUomCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'buyerUomCode' }), categories: packingCogetories },
            { width: 180, field: 'sellerUomCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'sellerUomCode' }), categories: packingCogetories },
            { width: 180, field: 'uomChangeRate', dataTypeName: 'rateType', title: intl.formatMessage({ id: 'uomChangeRate' }), categories: packingCogetories },
            { width: 180, field: 'm3', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.m3' }), categories: packingCogetories },
            { width: 180, field: 'length', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.length' }), categories: packingCogetories },
            { width: 180, field: 'width', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.width' }), categories: packingCogetories },
            { width: 180, field: 'height', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.height' }), categories: packingCogetories },
            { width: 180, field: 'netWeight', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.netWeight' }), categories: packingCogetories },
            { width: 180, field: 'grossWeight', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.grossWeight' }), categories: packingCogetories },
            { width: 180, field: 'supplierLeadtime', dataTypeName: 'number', title: intl.formatMessage({ id: 'supplierLeadtime' }), categories: packingCogetories },
        ] as Column[]

        return Arrays.concat(psrtsColumns, bucolumns, supplierColumns, priceColumns, buOrSupplierColumns, packingColumns)
    }, [contractType, intl])



    const getRowId = useCallback((row: any) => row.buId, [])

    const onEditingCellCommit = useCallback((_column: Column, row: Row) => {
        setPartsData(partsData => partsData.map(item => item.unitPartsNo === row.unitPartsNo ? row as ContractPartsResult : item))
        return true
    }, [setPartsData])

    //const actionProps1 = useMemo(() => ({ setPartsData, headerData }), [setPartsData, headerData])
    const actionProps2 = useMemo(() => ({ partsData, headerData, setPartsData }), [partsData, headerData, setPartsData])
    const displayDownload = useCallback(() => mode !== ScreenMode.CREATE, [mode])
    //const displayUpload = useCallback(() => mode === ScreenMode.EDIT, [mode])

    return <div style={{ width: '100%', height: 500 }} >
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.DeliveryType} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.RepackingType} />
            <NumberTypeProvider name="rateType" options={{ minimumFractionDigits: 6, maximumFractionDigits: 6 }} />
            <ActiveFlagTypeProvider />
            <PercentTypeProvider />
            <Data rows={partsData} columns={columns} getRowId={getRowId} />
            {/* <ToolbarActionProvider Action={RowUploadAction} actionProps={actionProps1} display={displayUpload} /> */}
            <ToolbarActionProvider Action={RowDownloadAction} actionProps={actionProps2} display={displayDownload} />
            <ColumnFreeze />
            <ColumnVisibility
                defaultHiddenFields={[]}
                columnSettings={{
                    unitPartsNo: { disableUserControl: true },
                    partsNo: { disableUserControl: true },
                }} ToolbarButton={ColumnVisibilityToolbarButton} />
            {/* <ColumnOrdering defaultOrder={columns.map(column => column.field)} /> */}
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Editing
                enableInlineEdit={false}
                columnSettings={{
                    unitPartsNo: { editingDisabled: true },
                    partsNo: { editingDisabled: true },
                    sellerPartsNo: { editingDisabled: true },
                    buyerPartsNo: { editingDisabled: true },
                    unitPartsName: { editingDisabled: true },
                    currentPrice: { editingDisabled: true },
                    currentApplyDate: { editingDisabled: true },
                    activeFlag: { editingDisabled: true },
                    orderLot: { editingDisabled: true },
                    spq: { editingDisabled: true },
                    uom: { editingDisabled: true },
                    m3: { editingDisabled: true },
                    length: { editingDisabled: true },
                    width: { editingDisabled: true },
                    height: { editingDisabled: true },
                    netWeight: { editingDisabled: true },
                    grossWeight: { editingDisabled: true },
                }}
                onEditingCellCommit={onEditingCellCommit}
            />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
        </DataGrid>
    </div>
})

const SaveAction = ({ headerData, partsData, setMessages, search }: { headerData: ContractHeaderResult, partsData: ContractPartsResult[], setMessages: React.Dispatch<React.SetStateAction<Message[]>>, search: () => void, }) => {
    const saveActive = useSaveContract()
    const { contractType } = headerData
    const fields = useMemo(() => getFormCheckFields(true, contractType), [contractType])
    const formValidate = useFormValidater(setMessages, fields)
    const [disabled, setDisabled] = useState<boolean>(false)
    const click = useCallback(() => {
        if (formValidate(headerData)) {
            setDisabled(true)
            const entity = {
                ...headerData,
                deliveryTypeFlag: DeliveryTypeFlag.N,
                partsList: partsData
            }
            saveActive(entity, { serialized: true }).then(result => {
                search()
            }).finally(() => {
                setDisabled(false)
            })
        }
    }, [formValidate, headerData, partsData, saveActive, search])

    return <SaveCallbackViewAction access="MARS.MLS091.SAVE" callback={click} disabled={disabled} />
}

const getFormCheckFields = (isIssue: boolean, contractType?: ContractType) => {
    const baseFields = {
        description: { labelId: 'description', length: { max: 255 } },
        contractNo: { labelId: 'field.contractNo', required: true },
        sellerCode: { labelId: 'seller', required: true },
        contractType: { labelId: 'field.contractType', required: true },
        buyerCode: { labelId: 'buyer', required: true },
        consignee: { labelId: 'consigneeCode', required: true },
        accountee: { labelId: 'accounteeCode', required: true },
        businessType: { labelId: 'field.businessType', required: true },
        deliveryTo: { labelId: 'deliveryToCode', required: true },
        paymentTermsId: { labelId: 'field.paymentTermsId', required: true },
        currency: { labelId: 'field.currency', required: true },
    }
    let custFields = {}
    let suppFields = {}
    if (contractType === ContractType.CUST_BU || contractType === ContractType.CUST_SUPP) {
        custFields = {
            orderFrequency: { labelId: 'field.orderFrequency', required: true },
            forecastNum: { labelId: 'field.forecastNum', required: true, length: { max: 2, integer: 2 }, compare: { min: 0 } },
            targetLeadtime: { labelId: 'field.targetLeadtime', required: true, length: { max: 9, integer: 9 } },
        }
    }
    if (contractType === ContractType.BU_SUPP || contractType === ContractType.CUST_SUPP) {
        suppFields = {
            confirmOrderLeadtime: { labelId: 'field.confirmOrderLeadtime', required: true },
            leadtime: { labelId: 'field.leadtime', required: true, length: { max: 9, integer: 9 } },
            // deliveryTypeFlag: { labelId: 'field.deliveryTypeFlag', required: true},
            deliveryPlanStartDate: { labelId: 'field.deliveryPlanStartDate', required: true, length: { max: 9, integer: 9 } }
        }
    }
    return {
        ...baseFields,
        ...custFields,
        ...suppFields
    }
}

// const RowUploadAction = ({ setPartsData, headerData }: { setPartsData: React.Dispatch<React.SetStateAction<ContractPartsResult[]>>, headerData: ContractHeaderResult }) => {
//     const getContractParts = useGetContractDetailListByContractId()
//     const uploadPartsToPageApi = useUploadMasterContractPartsMasterToPage()
//     const uploadPartsToPage = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
//         popupUpload((files: FileList | null) => {
//             files && uploadPartsToPageApi({ file: files[0] }, { serialized: true }).then((uploadResult) => {
//                 getContractParts({ contractId: headerData.contractId }, { silent: true, serialized: true }).then(result => {
//                     setPartsData(result ?? [])
//                 })
//                 // setPartsData(uploadResult ?? [])
//             })
//         })
//     }, [uploadPartsToPageApi, getContractParts, headerData.contractId, setPartsData])
//     return <UploadGroupedToolbarAction access="MARS.MLS091.UPLOAD">
//         {(popupUpload, onClose) => <>
//             <GroupedCallbackItem label={<FormattedMessage id="UploadPartsFromPage" />} callback={() => uploadPartsToPage(popupUpload)} onClose={onClose} />
//         </>}
//     </UploadGroupedToolbarAction>
// }

const RowDownloadAction = ({ headerData, partsData }: { headerData: ContractHeaderResult, partsData: ContractPartsResult[] }) => {
    const downloadcontractPartsAndPriceMasterCurrentPageDataApi = useDownloadcontractPartsAndPriceMasterCurrentPageData()
    const downloadCurrentPage = useCallback(() => {
        downloadcontractPartsAndPriceMasterCurrentPageDataApi({
            flag: 0,
            currentPageList: partsData.map(m => {
                return {
                    ...m,
                    contractNo: headerData.contractNo,
                    seller: headerData.sellerCode,
                    buyer: headerData.buyerCode,
                    currency: headerData.currency,
                }
            })
        })
    }, [downloadcontractPartsAndPriceMasterCurrentPageDataApi, headerData, partsData])

    return <DownloadGroupedToolbarAction access="MARS.MLS091.DOWNLOAD">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="downloadFromPage" />} callback={downloadCurrentPage} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}