import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"

export type TnmUserToListResult = Required & Partial<Optional> & TableEntity
type TnmUserToListResultJson = JsonRequired & Partial<JsonOptional> & TableEntityJson


export const tnmUserToListResultFromJson: Project<TnmUserToListResultJson, TnmUserToListResult> = json => ({
    ...tableEntityFromJson(json),
    remindStartDate: dateFromJson(json.remindStartDate),
    remindEndDate: dateFromJson(json.remindEndDate),
    dueDate: dateFromJson(json.dueDate),

})

interface Required extends JsonRequired {

}

interface Optional extends Omit<JsonOptional, 'remindStartDate' | 'remindEndDate' | 'dueDate'> {
    remindStartDate: Date,
    remindEndDate: Date,
    dueDate: Date,
}
// interface Optional extends JsonOptional{

// }

interface JsonRequired {

}

interface JsonOptional {
    todoId: number,
    fromUserId: number,
    toCbdsUid: string,
    toUserId: number,
    title: string,
    titleParam: string,
    description: string,
    descriptionParam: string,
    todoType: number,
    scheduleType: number,
    urgency: number,
    url: string,
    urlParam: string,
    taskKey: string,
    remindStartDate: string
    remindEndDate: string,
    status: number,
    importanceFlag: number,
    dueDate: string,
    task: string,
    taskCategory: number,
    recurrence: number,
    file: string,
    fileName: string,
}