import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { Records } from "@rithe/utils"
import React, { memo, SetStateAction, useCallback, useMemo } from "react"
import { useIntl } from "react-intl"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { ActiveFlagTypeProvider } from "../../../components/DataGrid/typeProviders/ActiveFlagTypeProvider"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { ContractRequestType } from "../../../services/master/enums/ContaractRequestType"
import { GlobalPartsResult } from "../../../services/master/models/GlobalPartsResult"

interface OCS021GlobalPartsPcUiProps {
    requestType: ContractRequestType,
    requestTo?: string,
    partList: GlobalPartsResult[],
    selections: number[],
    setSelections: React.Dispatch<SetStateAction<number[]>>,
    setSelectedList: React.Dispatch<React.SetStateAction<GlobalPartsResult[]>>,
}

export const OCS021GlobalPartsPcUi = (props: OCS021GlobalPartsPcUiProps) => {
    return <DataTable {...props} />
}

const DataTable = memo((props: OCS021GlobalPartsPcUiProps) => {
    const { requestType, selections, partList, setSelections, setSelectedList } = props
    const intl = useIntl()

    const columns = useMemo(() => {
        let columns = [

            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
            { field: 'oldPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.oldPartsNo' }), width: 200 },
            { field: 'displayPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.displayPartsNo' }), width: 200 },
            { field: 'colorCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.colorCode' }), width: 200, },
            // { field: 'uomCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.uomCode' }), width: 180 },
            // { field: 'rolledPartsFlag', dataTypeName: CodeCategory.RolledPartsFlag, title: intl.formatMessage({ id: 'field.rolledPartsFlag' }), width: 200 },
            // { field: 'rolledPartsUom', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.rolledPartsUom' }), width: 220 },
            // { field: 'netWeight', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.netWeight' }), width: 150 },
            // { field: 'remark1', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.remark1' }), width: 300 },
            // { field: 'remark2', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.remark2' }), width: 300 },
            // { field: 'remark3', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.remark3' }), width: 300 },
            // { field: 'remark4', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.remark4' }), width: 300 },
            // { field: 'remark5', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.remark5' }), width: 300 },
            // { field: 'partsType', dataTypeName: CodeCategory.PartsType, title: intl.formatMessage({ id: 'field.partsType' }), width: 200 },
            { field: 'activeFlag', dataTypeName: CodeCategory.ActiveFlag, title: intl.formatMessage({ id: 'field.activeFlag' }), width: 200 },
            // { field: 'createdDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.createdDate' }), width: 180 },
            // { field: 'updatedDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.updatedDate' }), width: 180 },

        ]
        if (requestType === ContractRequestType.ADD) {
            columns.splice(2, 1)
        }
        return columns


    }, [intl, requestType])

    const onSelectionsChange = useCallback((selects: (string | number)[]) => {
        const indexs = selects as number[]
        setSelections(indexs)
        setSelectedList(partList.filter((_, idx) => indexs.includes(idx)))
    }, [partList, setSelectedList, setSelections])


    return <div style={{ width: '100%', height: 400 }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <ActiveFlagTypeProvider />
            <Data rows={partList} columns={columns} />
            <ColumnFreeze />
            <ColumnVisibility
                defaultHiddenFields={['remark1', 'remark2', 'remark3', 'remark4', 'remark5']}
                ToolbarButton={ColumnVisibilityToolbarButton}
            />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
            <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
            <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={onSelectionsChange} />
        </DataGrid>
    </div>
})
