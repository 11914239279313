import { Button, DialogActions, DialogContent, makeStyles, Typography, useTheme } from "@material-ui/core"
import { Column, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Editing, Filtering, NumberTypeProvider, PaginationLayout, Paging, Row, Searching, Sorting, StringTypeProvider, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarLayout } from "@rithe/data-grid"
import { NumberFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/NumberFormatter"
import { StringFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/StringFormatter"
import { Break, DateItem, Form, Message, NumberItem, StringItem } from "@rithe/form"
import { GridContainer, GridItem } from "@rithe/ui"
import { Arrays, Records } from "@rithe/utils"
import moment from "moment"
import React, { Dispatch, memo, SetStateAction, useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, IntlShape, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Access } from "../../../components/Access/Access"
import { DownloadCallbackCardAction } from "../../../components/Action/DownloadCallbackCardAction"
import { IssueCallbackViewAction } from "../../../components/Action/IssueCallbackViewAction"
import { SaveCallbackViewAction } from "../../../components/Action/SaveCallbackViewAction"
import { UploadCallbackCardAction } from "../../../components/Action/UploadCallbackCardAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CreateCallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/CreateCallbackToolbarAction"
import { PercentTypeProvider } from "../../../components/DataGrid/typeProviders/PercentTypeProvider"
import { DarkDialog } from "../../../components/Dialog/DarkDialog"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { StepperAction } from "../../../components/View/Step/StepperAction"
import { ViewPanel } from "../../../components/View/Tab/ViewPanel"
import { ViewPanels } from "../../../components/View/Tab/ViewPanels"
import { ViewTab } from "../../../components/View/Tab/ViewTab"
import { ViewTabControl } from "../../../components/View/Tab/ViewTabControl"
import { ViewTabs } from "../../../components/View/Tab/ViewTabs"
import { View } from "../../../components/View/View"
import { useFunctionStore } from "../../../Root"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { OrderType } from "../../../services/order/enums/OrderType"
import { useGetDeliveryDateForEhm, useSaveAndIssuePlacedOrderListSMT, useSavePlacedOrderListSMT } from "../../../services/smt/api/OrderCalculationApi"
import { useDownloadOrderSummaryReportForSMT, useDownloadRegularOrderformListForCustomerSMT } from "../../../services/smt/api/smtDownloadApi"
import { useUploadRegularOrderFormListForCustomerSMT } from "../../../services/smt/api/smtUploadApi"
import { OCPlaceOrderDetailFactor } from "../../../services/smt/models/OCPlaceOrderDetailFactor"
import { OCPartsDetail } from "../../../services/smt/models/OCPlaceOrderDetailResult"
import { useGetCodeName } from "../../../utils/CodeCategoryUtil"
import { formatDateRange } from "../../../utils/formatDateRange"
import { useFieldChecker, useFormCheck } from "../../../utils/ValidatorUtils"
import { applicationActions } from "../../Application/applicationSlice"
import { PlaceOrderBasic, useMergeDatas, useSplitDatas } from "./OCCLS015"
import { useOCCLS015ShippingPlanCategory } from "./OCCLS015ShippingPlanCategory"

export interface OCCLS015EhmPcUiProps {
    mode: ScreenMode,
    orderType: OrderType,
    factor: OCPlaceOrderDetailFactor,
    basic: PlaceOrderBasic[],
    setBasic: React.Dispatch<React.SetStateAction<PlaceOrderBasic[]>>,
    forecasts: OCPartsDetail[],
    setForecasts: React.Dispatch<React.SetStateAction<OCPartsDetail[]>>,
    inboundPlans: OCPartsDetail[],
    setInboundPlans: React.Dispatch<React.SetStateAction<OCPartsDetail[]>>,
    search: (poGroupId: string | null | undefined) => void,
    poGroupId: string
}

export interface MessagesItem {
    contractNo: string,
    index: number,
    setMessages: Dispatch<SetStateAction<Message[]>>
}


export const OCCLS015EhmPcUi = (props: OCCLS015EhmPcUiProps) => {
    const { mode, factor, basic, setBasic, forecasts, setForecasts, inboundPlans, setInboundPlans, orderType, search, poGroupId } = props
    const messageItemList: MessagesItem[] = []
    const intl = useIntl()
    const actions = usePrepareActions(mode, basic, forecasts, inboundPlans, search, poGroupId, messageItemList)
    const editable = useMemo(() => mode === ScreenMode.EDIT ? true : false, [mode])

    return (
        <View actions={<StepperAction actions={actions} />}>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={1}
                    step
                    title={intl.formatMessage({ id: 'step1OfPlaceRegularOrder' })}
                    subtitle={intl.formatMessage({ id: 'step1OfPlaceRegularOrderSub' })}
                />
                <SectionCardContent>
                    <Step1DownloadUploadCard
                        orderType={orderType}
                        factor={factor}
                        editable={editable}
                        basic={basic}
                        setBasic={setBasic}
                        forecasts={forecasts}
                        setForecasts={setForecasts}
                        inboundPlans={inboundPlans}
                        setInboundPlans={setInboundPlans}
                        poGroupId={poGroupId}
                    />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={2}
                    step
                    title={intl.formatMessage({ id: 'step2OfPlaceRegularOrder' })}
                    subtitle={intl.formatMessage({ id: 'basicInfoSub' })}
                />
                <SectionCardContent>
                    <Step2FirmAndFcTable setBasic={setBasic} editable={editable} forecasts={forecasts} setForecasts={setForecasts} setInboundPlans={setInboundPlans} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={3}
                    step
                    title={intl.formatMessage({ id: 'step3OfPlaceRegularOrder' })}
                    subtitle={intl.formatMessage({ id: 'basicInfoSub' })}
                />
                <SectionCardContent>
                    <Step3ShippingPlanTable basic={basic} forecasts={forecasts} setForecasts={setForecasts} inboundPlans={inboundPlans} setBasic={setBasic} editable={editable} setInboundPlans={setInboundPlans} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={4}
                    step
                    title={intl.formatMessage({ id: 'step4OfPlaceRegularOrder' })}
                    subtitle={intl.formatMessage({ id: 'basicInfoSub' })}
                />
                <SectionCardContent>
                    <Step4BasicInfoPanelCard editable={editable} orderType={orderType} basic={basic} setBasic={setBasic} messageItemList={messageItemList} />
                </SectionCardContent>
            </SectionCard>
        </View >
    )
}

interface Step1Props {
    orderType: OrderType,
    factor: OCPlaceOrderDetailFactor,
    editable: boolean,
    basic: PlaceOrderBasic[],
    setBasic: React.Dispatch<React.SetStateAction<PlaceOrderBasic[]>>,
    forecasts: OCPartsDetail[],
    setForecasts: React.Dispatch<React.SetStateAction<OCPartsDetail[]>>,
    inboundPlans: OCPartsDetail[],
    setInboundPlans: React.Dispatch<React.SetStateAction<OCPartsDetail[]>>,
    poGroupId: string
}
const Step1DownloadUploadCard = memo((props: Step1Props) => {
    const { editable } = props
    const theme = useTheme()
    return (
        <GridContainer columnWidth={[500, 200]} style={{ paddingLeft: theme.spacing(5) }} rowGap={theme.spacing(2)}>
            <GridItem style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='body1'><FormattedMessage id='downloadInStep1' /></Typography>
            </GridItem>
            <GridItem >
                {editable ? <DownloadAction {...props} /> : <></>}
            </GridItem>
            <GridItem style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='body1'><FormattedMessage id='uplaodInStep1' /></Typography>
            </GridItem>
            <GridItem >
                {editable ? <UploadAction {...props} /> : <></>}
            </GridItem>
            <GridItem style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='body1'><FormattedMessage id='downloadSummaryInStep1' /></Typography>
            </GridItem>
            <GridItem >
                {editable ? <DownloadOrderSummaryAction {...props} /> : <></>}
            </GridItem>
        </GridContainer>
    )
})

interface Step2Props {
    forecasts: OCPartsDetail[],
    editable: boolean,
    setForecasts: React.Dispatch<React.SetStateAction<OCPartsDetail[]>>,
    setInboundPlans: React.Dispatch<React.SetStateAction<OCPartsDetail[]>>,
    setBasic: React.Dispatch<React.SetStateAction<PlaceOrderBasic[]>>,
}
const Step2FirmAndFcTable = memo((props: Step2Props) => {
    const { forecasts, setForecasts, editable, setInboundPlans, setBasic } = props
    const intl = useIntl()
    const [order, setOrder] = useState<string[]>([])
    const getMpqCheck = useCallback((row: Row) => {
        return (!row.firmQty || !row.spq) ? 'N/A' : (row.firmQty % row.spq === 0 ? 'OK' : 'NG')
    }, [])

    const columns = useMemo(() => {
        const fixedColumns = [
            { field: 'contractNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contractNo' }), width: 200 },
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
            { field: 'partsDescription', dataTypeName: 'string', title: intl.formatMessage({ id: 'partsDescription' }), width: 350 },
            { field: 'customerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'customerPartsNo' }), width: 220 },
            { field: 'supplierPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'supplierPartsNo' }), width: 220 },
            { field: 'exportCountry', dataTypeName: 'string', title: intl.formatMessage({ id: 'expCountry' }), width: 180 },
            { field: 'supplierCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierCode' }), width: 180 },
            { field: 'sellerUomCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.uomCode' }), width: 150 },
            { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'moq' }), width: 150 },
            { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'mpq' }), width: 150 },
            { field: 'firmQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'firmQty' }), width: 150 },
            { field: 'mpqCheck', dataTypeName: 'mpqCheck', title: intl.formatMessage({ id: 'mpqCheck' }), width: 200, getCellValue: getMpqCheck },
        ] as Column[]
        // all columns
        return fixedColumns
    }, [getMpqCheck, intl])

    const onEditingCellCommit = useCallback((_column: Column, row: Row) => {
        setForecasts(forecasts => forecasts.map(item => {
            if (item.orderCalcPoDetailId === row.orderCalcPoDetailId) {
                return row as OCPartsDetail
            } else {
                return item
            }
        }))

        setInboundPlans(inboundPlans => {
            if (inboundPlans.some(s => s.orderCalcPoDetailId === row.orderCalcPoDetailId && s.firmQty !== row.firmQty)) {
                return inboundPlans.map(m => m.orderCalcPoDetailId === row.orderCalcPoDetailId ? { ...m, firmQty: row.firmQty } : m)
            } else {
                return inboundPlans
            }
        })

        setBasic(basics => basics.map(baseic => {
            if (row.orderCalcPoId === baseic.orderCalcPoId) {
                const partsDetailList = forecasts.filter(f => f.orderCalcPoId === baseic.orderCalcPoId)
                const totalQty = partsDetailList.reduce((v1, v2) => v1 + (v2.orderCalcPoDetailId === row.orderCalcPoDetailId ? row.firmQty ? row.firmQty : 0 : v2.firmQty ? v2.firmQty : 0), 0)
                const totalAmount = partsDetailList.reduce((v1, v2) => v1 + (v2.orderCalcPoDetailId === row.orderCalcPoDetailId ? row.firmQty && v2.price ? row.firmQty * v2.price : 0 : v2.firmQty && v2.price ? v2.firmQty * v2.price : 0), 0)
                const totalNumberOfParts = partsDetailList.length
                const planDates = Arrays.distinct(partsDetailList.flatMap(parts => parts.inbPlanList).map(plan => plan.crdDate.getTime())).map(m => new Date(m))
                const deliveryPlanRange = planDates.length > 0 ? formatDateRange(intl, Arrays.min(planDates, (a, b) => a > b ? 1 : a === b ? 0 : -1), Arrays.max(planDates, (a, b) => a > b ? 1 : a === b ? 0 : -1)) : ''
                return { ...baseic, totalQty, totalAmount, totalNumberOfParts, deliveryPlanRange }
            } else {
                return baseic
            }
        }))

        return true
    }, [forecasts, intl, setBasic, setForecasts, setInboundPlans])

    const defaultEditingDisabled = Records.from(columns.filter(f => !f.field.includes('firmQty')).map(({ field }) => [field, { editingDisabled: true }]))

    useEffect(() => {
        setOrder(columns.map(column => column.field))
    }, [columns])

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <PercentTypeProvider />
            <CheckTypeProvider />
            <FluctuationTypeProvider />
            <FcFluctuationTypeProvider />
            <Data rows={forecasts} columns={columns} />
            <ColumnFreeze />
            <ColumnVisibility ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnOrdering order={order} onOrderChange={setOrder} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Editing
                enableInlineEdit={editable ? true : false}
                onEditingCellCommit={onEditingCellCommit}
                columnSettings={defaultEditingDisabled}
            />
            <Filtering />
            <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        </DataGrid>
    </div>
})

interface Step3Props {
    setBasic: React.Dispatch<React.SetStateAction<PlaceOrderBasic[]>>,
    inboundPlans: OCPartsDetail[],
    editable: boolean,
    setInboundPlans: React.Dispatch<React.SetStateAction<OCPartsDetail[]>>,
    basic: PlaceOrderBasic[],
    forecasts: OCPartsDetail[],
    setForecasts: React.Dispatch<React.SetStateAction<OCPartsDetail[]>>,
}

const dsduplication = (item: { crdDate: number, drDate: number | null }[]) => {
    const n: { crdDate: number, drDate: number | null }[] = [...item]
    for (let i = 0; i < n.length - 1; i++) {
        for (let j = i + 1; j < n.length; j++) {
            if (n[i].crdDate === n[j].crdDate) {
                n.splice(j, 1)
                j--
            }
        }
    }
    return n
}

const getDateLabel = (value: Date | null | undefined, value2: Date | null | undefined, intl: IntlShape) => {
    const crdDate: string = value ? intl.formatDate(value, { dateStyle: 'medium' }) : ''
    if (value2 !== null && value2 !== undefined) {
        return intl.formatDate(value2, { dateStyle: 'medium' }) + ' ~ ' + crdDate
    } else {
        return crdDate
    }
}

const Step3ShippingPlanTable = memo((props: Step3Props) => {
    const { basic, forecasts, setForecasts, inboundPlans, setInboundPlans, editable, setBasic } = props
    const planDates = useMemo(() => {
        return inboundPlans && inboundPlans.length > 0
            ? dsduplication(Arrays.distinct(
                inboundPlans.flatMap(parts => parts.inbPlanList)
                    .sort((a, b) => a.crdDate.getTime() - b.crdDate.getTime())
                    .map(plan => { return { crdDate: plan.crdDate.getTime(), drDate: plan.drDate ? plan.drDate.getTime() : null } })
            ))
                .map(m => { return { crdDate: new Date(m.crdDate), drDate: m.drDate ? new Date(m.drDate) : null } })
            : []
    }, [inboundPlans])

    const intl = useIntl()
    const [order, setOrder] = useState<string[]>([])
    const [actions, setActions] = useState<RequestProps>({ open: false, editDate: null, editDate2: null })

    const columns = useMemo(() => {
        const fixedColumns: Column[] = [
            { field: 'contractNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contractNo' }), width: 200 },
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
            { field: 'partsDescription', dataTypeName: 'string', title: intl.formatMessage({ id: 'partsDescription' }), width: 300 },
            { field: 'customerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'customerPartsNo' }), width: 220 },
            { field: 'supplierPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'supplierPartsNo' }), width: 220 },
            { field: 'exportCountry', dataTypeName: 'string', title: intl.formatMessage({ id: 'expCountry' }), width: 180 },
            { field: 'supplierCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierCode' }), width: 180 },
            { field: 'sellerUomCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.uomCode' }), width: 150 },
            { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'moq' }), width: 150 },
            { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'mpq' }), width: 150 },
        ]
        const firmCategories = [{ key: 'firm', value: intl.formatMessage({ id: 'deliveryDate' }) }]
        const firmColumns = [{ field: 'firmQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'firmQty' }), categories: firmCategories, width: 150 }]
        const getPlanCategories = (index: number) => [{ key: `plan${index}`, value: getDateLabel(planDates[index].crdDate, planDates[index].drDate, intl) }]
        const qtytitle = intl.formatMessage({ id: 'field.qty' })
        const planColumns = planDates.map((planDate, index) => ({
            field: `planQty${index}`,
            dataTypeName: 'shippingPlan',
            title: qtytitle,
            categories: getPlanCategories(index),
            width: 250,
            getCellValue: (row: Row) => row.inbPlanList.find((f: any) => f.crdDate.getTime() === planDate.crdDate.getTime())?.crdQty ?? null,
            setCellValue: (row: Row, value: any) => {
                // do fiter
                if (row.inbPlanList?.some((plan: any) => plan.crdDate.getTime() === planDate.crdDate.getTime())) {
                    return ({ ...row, inbPlanList: row.inbPlanList?.map((plan: any) => plan.crdDate.getTime() === planDate.crdDate.getTime() ? { ...plan, crdQty: value } : plan) })
                } else {
                    const inbPlanList = row.inbPlanList
                    inbPlanList.push({
                        orderCalcPoDetailId: row.orderCalcPoDetailId,
                        crdDate: planDate.crdDate,
                        crdQty: value,
                        drDate: planDate.drDate
                    })
                    return ({ ...row, inbPlanList: inbPlanList })
                }
            }
        }))
        return Arrays.concat(fixedColumns, firmColumns, planColumns)
    }, [intl, planDates])

    const onEditingCellCommit = useCallback((_column: Column, row: Row) => {
        setInboundPlans(inboundPlans => inboundPlans.map(item => item.orderCalcPoDetailId === row.orderCalcPoDetailId ? row as OCPartsDetail : item))
        return true
    }, [setInboundPlans])

    const defaultEditingDisabled = Records.from(columns.filter(f => !f.field.includes('planQty')).map(({ field }) => [field, { editingDisabled: true }]))
    const AddnewAction = useMemo(() => ({ setActions }), [setActions])
    const clickHeader = useCallback((field: string) => {
        field.startsWith("planQty") && setActions({ open: true, editDate: planDates[Number(field.substring(7))].crdDate, editDate2: planDates[Number(field.substring(7))].drDate })
    }, [planDates])
    const shippingPlanCategory = useOCCLS015ShippingPlanCategory(clickHeader, editable)

    useEffect(() => {
        setOrder(columns.map(column => column.field))
    }, [columns])

    const actionProps1 = useMemo(() => ({ basic, forecasts, inboundPlans, setBasic, setForecasts, setInboundPlans }), [basic, forecasts, inboundPlans, setBasic, setForecasts, setInboundPlans])

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <NumberTypeProvider name="shippingPlan" Category={shippingPlanCategory} />
            <Data rows={inboundPlans} columns={columns} />
            <ColumnFreeze />
            <ToolbarActionProvider Action={ResetDeliveryDateAction} actionProps={actionProps1} display={() => editable} />
            <ToolbarActionProvider Action={CreateAction} actionProps={AddnewAction} display={() => editable} />
            <ColumnVisibility ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnOrdering order={order} onOrderChange={setOrder} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Editing
                enableInlineEdit={editable ? true : false}
                onEditingCellCommit={onEditingCellCommit}
                columnSettings={defaultEditingDisabled}
            />
            <Sorting />
            <Filtering />
            <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        </DataGrid>
        <AddNewRequestDialog actions={actions} setActions={setActions} inboundPlans={inboundPlans} setInboundPlans={setInboundPlans} />
    </div>
})

const CreateAction = ({ setActions }: { setActions: React.Dispatch<React.SetStateAction<RequestProps>> }) => {
    return <CreateCallbackToolbarAction title={<FormattedMessage id="Add New Plan" />} callback={() => setActions({ open: true, editDate: null, editDate2: null })} />
}

interface RequestProps {
    open: boolean,
    editDate: Date | null,
    editDate2: Date | null
}

const AddNewRequestDialog = ({ actions, setActions, inboundPlans, setInboundPlans }: {
    actions: RequestProps,
    setActions: React.Dispatch<React.SetStateAction<RequestProps>>,
    inboundPlans: OCPartsDetail[],
    setInboundPlans: React.Dispatch<React.SetStateAction<OCPartsDetail[]>>,
}) => {
    const { open, editDate, editDate2 } = actions
    const [factor, setFactor] = useState<{ inboundPlanDate: Date | null, inboundDrDate: Date | null }>({ inboundPlanDate: null, inboundDrDate: null })
    const handleClose = useCallback(() => setActions({ open: false, editDate: null, editDate2: null }), [setActions])

    const intl = useIntl()

    useEffect(() => {
        setFactor({ inboundPlanDate: editDate, inboundDrDate: editDate2 })
    }, [editDate, editDate2])

    const dispatch = useDispatch()
    const aplyDate = useCallback(() => {
        if (!factor.inboundDrDate) {
            dispatch(applicationActions.pushError({
                title: { code: 'confirm' },
                messages: { code: 'w0001', args: ['field.drDate'] }
            }))
            return
        } else if (!factor.inboundPlanDate) {
            dispatch(applicationActions.pushError({
                title: { code: 'confirm' },
                messages: { code: 'w0001', args: ['field.inboundPlanDate'] }
            }))
            return
        } else {
            const inboundPlanDate = factor.inboundPlanDate
            const inboundDrDate = factor.inboundDrDate
            if (inboundPlans.flatMap(f => f.inbPlanList).some(s => moment(s.crdDate).format(moment.HTML5_FMT.DATE) === moment(inboundPlanDate).format(moment.HTML5_FMT.DATE))
                && (!editDate || moment(editDate).format(moment.HTML5_FMT.DATE) !== moment(inboundPlanDate).format(moment.HTML5_FMT.DATE))) {
                dispatch(applicationActions.pushError({
                    title: { code: 'confirm' },
                    messages: { code: 'w0381' }
                }))
                return
            } else {
                setInboundPlans(inboundPlans => inboundPlans.map(m => ({
                    ...m, inbPlanList: !editDate ? [...m.inbPlanList, {
                        orderCalcPoDetailId: m.orderCalcPoDetailId,
                        crdId: null,
                        crdDate: inboundPlanDate,
                        crdQty: null,
                        version: null,
                        drDate: inboundDrDate
                    }] : m.inbPlanList.map(plan => plan.crdDate.getTime() === editDate.getTime() ? { ...plan, crdDate: inboundPlanDate, drDate: inboundDrDate } : plan)
                })))
            }
        }
        // do reflect
        setFactor({ inboundPlanDate: null, inboundDrDate: null })
        setActions({ open: false, editDate: null, editDate2: null })
    }, [dispatch, editDate, factor.inboundDrDate, factor.inboundPlanDate, inboundPlans, setActions, setInboundPlans])

    return <>
        <DarkDialog open={open} style={{ overflow: 'hidden' }} maxWidth="sm" fullWidth keepMounted={false} fullScreen={false}>
            <DialogHeader onClose={handleClose}><FormattedMessage id="inputInboundDate" /></DialogHeader>
            <DialogContent>
                <Form data={factor} setData={setFactor} labelDisplay="block" helperDisplay="tooltip" columnCount={1} minWidth={500} maxWidth={500}  >
                    <DateItem required field="inboundDrDate" labelWidth={120} label={intl.formatMessage({ id: 'deliveryDate' })} />
                    <DateItem required field="inboundPlanDate" labelWidth={120} label={intl.formatMessage({ id: 'field.inboundDate' })} />
                </Form>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={handleClose} />
                <DialogAction title={<FormattedMessage id="confirm" />} callback={aplyDate} />
            </DialogActions>
        </DarkDialog>
    </>
}

const Step4BasicInfoPanelCard = memo(({ editable, orderType, basic, setBasic, messageItemList }: {
    editable: boolean,
    orderType: OrderType,
    basic: PlaceOrderBasic[],
    setBasic: React.Dispatch<React.SetStateAction<PlaceOrderBasic[]>>,
    messageItemList: MessagesItem[]
}) => {

    const disContracts = useMemo(() => basic.filter(f => f?.totalQty > 0).map(m => m.contractNo), [basic])
    const [selectValue, setSelectedValue] = useState<string | number>("")
    const onSelectedValueChange = useCallback((value: string | number) => {
        setSelectedValue(value)
    }, [setSelectedValue])

    useEffect(() => {
        if (disContracts && disContracts.length > 0) {
            setSelectedValue(value => disContracts.indexOf(value as string) >= 0 ? value : disContracts[0])
        } else {
            setSelectedValue("")
        }
    }, [disContracts, setSelectedValue])

    return (
        <ViewTabControl>
            <ViewTabs selectedValue={selectValue} onSelectedValueChange={onSelectedValueChange}>
                {
                    basic.map((m, index) => {
                        if (m?.totalQty > 0) {
                            return <ViewTab label={m.contractNo} value={m.contractNo} />
                        } else {
                            return <></>
                        }
                    })
                }
            </ViewTabs>
            <ViewPanels>
                {
                    basic.map((m, index) => {
                        if (m?.totalQty > 0) {
                            return <ViewPanel value={m.contractNo}>
                                <ContractCard editable={editable} orderType={orderType} basic={m} index={index} setBasic={setBasic} messageItemList={messageItemList} />
                            </ViewPanel>
                        } else {
                            return <></>
                        }
                    })
                }
            </ViewPanels>
        </ViewTabControl>
    )
})


const ContractCard = memo(({ editable, orderType, basic, index, setBasic, messageItemList }: { editable: boolean, orderType: OrderType, basic: PlaceOrderBasic, index: number, setBasic: React.Dispatch<React.SetStateAction<PlaceOrderBasic[]>>, messageItemList: MessagesItem[] }) => {
    const { getCodeName } = useGetCodeName()
    const intl = useIntl()
    const shippingMode = useMemo(() => getCodeName(CodeCategory.ShippingMode, basic?.shippingMode), [getCodeName, basic.shippingMode])
    const orderRange = useMemo(() => formatDateRange(intl, basic.orderFirstDate, basic.orderLastDate), [basic.orderFirstDate, basic.orderLastDate, intl])
    const [messages, setMessages] = useState<Message[]>([])

    if (messageItemList.find(f => f.contractNo === basic.contractNo && f.index === index) === undefined) {
        messageItemList.push({ contractNo: basic.contractNo, index: index, setMessages: setMessages })
    }

    const filedCheck = useFieldChecker(OrderFields, setMessages)
    const onContactChange = useCallback((index) => {
        return (nextDraftDataFunc: React.Dispatch<React.SetStateAction<any>>) => {
            setBasic(contacts => {
                const editContact = contacts[index]
                const draftData: any = typeof nextDraftDataFunc === 'function' ? nextDraftDataFunc(editContact) : nextDraftDataFunc
                return contacts.map((m, idx) => idx === index ? draftData : m)
            })
        }
    }, [setBasic])

    const setOrderRefNo = useCallback((data: any, value: string | undefined) => {
        const noInput = value === null || value === "" || value === undefined
        return { ...data, orderRefNo: noInput ? "" : value.length > OrderFields.orderRefNo.length.max ? data.orderRefNo : value }
    }, [])

    return (
        <Form data={basic} setData={onContactChange(index)} labelDisplay="block" helperDisplay="tooltip" columnCount={2} messages={messages} setMessages={setMessages} ensure={filedCheck} >
            <StringItem field="orderRefNo" required readonly={!editable} label={intl.formatMessage({ id: 'orderReference' })} mapValue={setOrderRefNo} />
            <StringItem field="contractNo" readonly={true} label={intl.formatMessage({ id: 'field.contractNo' })} />
            <Break />
            <StringItem field="sellerCode" readonly={true} label={intl.formatMessage({ id: 'seller' })} />
            <NumberItem field="totalAmount" readonly={true} label={intl.formatMessage({ id: 'totalAmount' })} suffix={basic.currency} />
            <Break />
            <StringItem field="deliveryToCode" readonly={true} label={intl.formatMessage({ id: 'deliveryToCode' })} />
            <NumberItem field="totalNumberOfParts" readonly={true} label={intl.formatMessage({ id: 'totalNumberOfParts' })} />
            <Break />
            <StringItem field="shippingMode" readonly={true} label={intl.formatMessage({ id: 'field.shippingMode' })} getValue={() => shippingMode} />
            <NumberItem field="totalQty" readonly={true} label={intl.formatMessage({ id: 'totalQty' })} />
            <Break />
            <StringItem field="orderRange" readonly={true} label={intl.formatMessage({ id: 'orderRange' })} getValue={() => orderRange} />
            <StringItem field="deliveryPlanRange" readonly={true} label={intl.formatMessage({ id: 'deliveryPlanRange' })} />
            <Break />
            <StringItem field="termsDescription" readonly={true} label={intl.formatMessage({ id: 'field.paymentTermsDesc' })} colSpan={2} />
            {OrderType.SPOT === orderType && <StringItem required readonly={!editable} field="spotOrderReason" label={intl.formatMessage({ id: 'field.spotOrderReason' })} colSpan={2} />}
            <StringItem field="remark" readonly={!editable} label={intl.formatMessage({ id: 'field.remark' })} colSpan={2} />
        </Form>
    )
})

const UploadAction = (props: Step1Props) => {
    const { setBasic, setForecasts, setInboundPlans, poGroupId } = props
    const uploadRegularListMethod = useUploadRegularOrderFormListForCustomerSMT()
    const splitDatas = useSplitDatas()
    const upload = useCallback((files: FileList | null) => {
        if (files === null) return
        uploadRegularListMethod({ file: files[0], poGroupId: poGroupId }, { serialized: true }).then(result => {
            if (result) {
                const { orderbasic, orderforecasts, orderInboundPlans } = splitDatas(result)
                setBasic(orderbasic)
                setForecasts(orderforecasts)
                setInboundPlans(orderInboundPlans)
            }
        })
    }, [poGroupId, setBasic, setForecasts, setInboundPlans, splitDatas, uploadRegularListMethod])

    return <UploadCallbackCardAction access="STCK.OCCLS015.UPLOAD" callback={upload} />
}

const DownloadAction = (props: Step1Props) => {
    const mergeDatas = useMergeDatas()
    const { basic, forecasts, inboundPlans } = props
    const downloadRegularOrderformListForCustomer = useDownloadRegularOrderformListForCustomerSMT()
    const download = useCallback(() => {
        const data = mergeDatas(basic, forecasts, inboundPlans)
        downloadRegularOrderformListForCustomer(data)
    }, [basic, downloadRegularOrderformListForCustomer, forecasts, inboundPlans, mergeDatas])

    return <DownloadCallbackCardAction outlined access="STCK.OCCLS015.DOWNLOAD" callback={download} />
}

const DownloadOrderSummaryAction = ({ basic, forecasts, inboundPlans }: Step1Props) => {
    const mergeDatas = useMergeDatas()
    const dispatch = useDispatch()
    const downloadOrderSummaryReportForSMT = useDownloadOrderSummaryReportForSMT()
    const download = useCallback(() => {
        // do check inboundPlans if no data
        if (inboundPlans.some(f => f.inbPlanList && f.inbPlanList.length && f.inbPlanList.length > 0)) {
            downloadOrderSummaryReportForSMT(mergeDatas(basic, forecasts, inboundPlans))
        } else {
            // show message 
            dispatch(applicationActions.pushError({ title: { code: 'downloadSummaryReport' }, messages: { code: 'w0925' } }))
        }
    }, [basic, dispatch, downloadOrderSummaryReportForSMT, forecasts, inboundPlans, mergeDatas])

    return <DownloadCallbackCardAction outlined access="STCK.OCCLS015.DOWNLOADSUMMARYREPORT" callback={download} />
}

const usePrepareActions = (
    mode: ScreenMode,
    basic: PlaceOrderBasic[],
    forecasts: OCPartsDetail[],
    inboundPlans: OCPartsDetail[],
    search: (poGroupId: string | null | undefined) => void,
    poGroupId: string,
    messageItemList: MessagesItem[]
) => {
    const savePlacedOrderList = useSavePlacedOrderListSMT()
    const saveAndIssuePlacedOrderList = useSaveAndIssuePlacedOrderListSMT()
    const navigate = useNavigate()
    const mergeDatas = useMergeDatas()
    const formValidate = useFormCheck(OrderFieldsWithRequired)
    const formValidateTemp = useFormCheck(OrderFields)
    const [disabled, setDisabled] = useState<boolean>(false)
    const intl = useIntl()
    const title = useMemo(() => intl.formatMessage({ id: 'issue' }), [intl])

    const dispatch = useDispatch()
    const functionStore = useFunctionStore()
    const saveData = useCallback((isIssue: number) => {
        // check
        let checkResult = false
        basic.forEach((m, index) => {
            if (m?.totalQty > 0) {
                const item = messageItemList.find(f => f.index === index)
                item && item.setMessages([])
                const messages = isIssue === 1 ? formValidate(m) : formValidateTemp(m)
                item && messages && messages.length > 0 && item.setMessages(messages)
                checkResult = checkResult || (messages && messages.length > 0)
            }
        })

        if (checkResult) {
            dispatch(applicationActions.pushError({
                title: { code: 'inputError' },
                messages: { code: 'w0345' },
            }))
            return
        }

        const data = mergeDatas(basic, forecasts, inboundPlans)
        if (isIssue === 1) {
            const functionId = functionStore.register(() => {
                setDisabled(true)
                saveAndIssuePlacedOrderList(data, { silent: false, serialized: true }).then(() => {
                    navigate('/orderCalculationEhm')
                }).finally(() => {
                    setDisabled(false)
                })
            })
            dispatch(applicationActions.pushWarning({
                title,
                messages: { code: 'c0001', args: [title] },
                actions: [{
                    label: 'CANCEL',
                }, {
                    label: 'CONFIRM',
                    functionId
                }]
            }))
        } else {
            setDisabled(true)
            savePlacedOrderList(data, { silent: false, serialized: true }).then(() => {
                search(poGroupId)
            }).finally(() => {
                setDisabled(false)
            })
        }
    }, [basic, dispatch, forecasts, formValidate, formValidateTemp, functionStore, inboundPlans, mergeDatas, messageItemList, navigate, poGroupId, saveAndIssuePlacedOrderList, savePlacedOrderList, search, title])

    const editable = useMemo(() => mode !== ScreenMode.VIEW, [mode])
    return editable ? [
        <SaveCallbackViewAction access="STCK.OCCLS015.SAVE" outlined callback={() => { saveData(0) }} disabled={disabled} />,
        <IssueCallbackViewAction access="STCK.OCCLS015.SAVE" callback={() => { saveData(1) }} disabled={disabled} />
    ] : []
}

const CheckFormatter = ({ value }: StringFormatterProps) => {
    const style = useStyles()
    const bgcolor = 'NG' === value ? '#D94C00' : 'N/A' === value ? '#ECEFF2' : '#00CCAD'
    const color = 'N/A' === value ? '' : 'white'
    return <div className={style.mpqCheck} style={{ background: bgcolor, color: color, width: '100%' }}>
        <Typography variant="body2" >{value}</Typography>
    </div>
}

const CheckTypeProvider = () => {
    return <StringTypeProvider name="mpqCheck" Formatter={CheckFormatter} />
}

const FluctuationFormatter = ({ value, row, formatter }: NumberFormatterProps) => {
    const style = useStyles()
    const fluctuationRatio = row.fluctuationRatio
    const displayValue = value === null ? 'N/A' : formatter.format(value)
    const bgcolor = (value === null || fluctuationRatio === null) ? '#ECEFF2' : Math.abs(value) > fluctuationRatio ? '#D94C00' : '#00CCAD'
    const color = (value === null || fluctuationRatio === null) ? '' : 'white'
    return <div className={style.fulcuationCheck} style={{ background: bgcolor, color: color, width: '100%' }}>
        <Typography variant="body2" >{displayValue}</Typography>
    </div>
}

const FluctuationTypeProvider = () => {
    return <NumberTypeProvider name="fluctuation" options={{ style: 'percent', maximumFractionDigits: 0, minimumFractionDigits: 0 }} Formatter={FluctuationFormatter} />
}

const FcFluctuationFormatter = ({ value, row, formatter }: NumberFormatterProps) => {
    const style = useStyles()
    const fluctuationRate = row.fcFluctuationRatio
    const displayValue = value === null ? 'N/A' : formatter.format(value)
    const bgcolor = (value === null || fluctuationRate === null) ? '#ECEFF2' : Math.abs(value) > fluctuationRate ? '#D94C00' : '#00CCAD'
    const color = (value === null || fluctuationRate === null) ? '' : 'white'
    return <div className={style.fulcuationCheck} style={{ background: bgcolor, color: color, width: '100%' }}>
        <Typography variant="body2" >{displayValue}</Typography>
    </div>
}

const FcFluctuationTypeProvider = () => {
    return <NumberTypeProvider name="fcFluctuation" options={{ style: 'percent', maximumFractionDigits: 0, minimumFractionDigits: 0 }} Formatter={FcFluctuationFormatter} />
}

const ResetDeliveryDateAction = ({ basic, forecasts, inboundPlans, setBasic, setForecasts, setInboundPlans }: {
    basic: PlaceOrderBasic[],
    forecasts: OCPartsDetail[],
    inboundPlans: OCPartsDetail[],
    setBasic: React.Dispatch<React.SetStateAction<PlaceOrderBasic[]>>,
    setForecasts: React.Dispatch<React.SetStateAction<OCPartsDetail[]>>,
    setInboundPlans: React.Dispatch<React.SetStateAction<OCPartsDetail[]>>,
}) => {
    const mergeDatas = useMergeDatas()
    const splitDatas = useSplitDatas()
    const getDeliveryDateForSmt = useGetDeliveryDateForEhm()

    const onClick = useCallback(() => {
        const data = mergeDatas(basic, forecasts, inboundPlans)
        getDeliveryDateForSmt(data, { silent: true, serialized: true }).then(result => {
            if (result) {
                const { orderInboundPlans } = splitDatas(result)
                setInboundPlans(orderInboundPlans)
            }
        })
    }, [basic, forecasts, getDeliveryDateForSmt, inboundPlans, mergeDatas, setInboundPlans, splitDatas])
    return <Access access="STCK.OCCLS015.SAVE">
        <Button variant={'outlined'} color="primary" onClick={onClick}>
            {<FormattedMessage id="resetDeliveryDate" />}
        </Button>
    </Access>
}

const useStyles = makeStyles(theme => ({
    mpqCheck: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70%',
        paddingRight: theme.spacing(1),
        borderRadius: 5
    },
    fulcuationCheck: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70%',
        paddingRight: theme.spacing(1),
        borderRadius: 5
    },
    default: {},
}))


const OrderFields = {
    orderRefNo: { labelId: 'orderRefNo', length: { max: 100 }, required: false },
    remark: { labelId: 'remark', length: { max: 255 } }
}

const OrderFieldsWithRequired = {
    orderRefNo: { labelId: 'orderRefNo', length: { max: 100 }, required: true },
    remark: { labelId: 'remark', length: { max: 255 } }
}
