import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson, tableEntityToJson } from "../../utils/TableEntity"
import { ActiveFlag } from "../enums/ActiveFlag"
import { PartsType } from "../enums/PartsType"
import { RolledPartsFlag } from "../enums/RolledPartsFlag"

export type GlobalPartsResult = Required & Partial<Optional> & TableEntity
type GlobalPartsResultJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const globalPartsResultFromJson: Project<GlobalPartsResultJson, GlobalPartsResult> = json => ({
    ...tableEntityFromJson(json),
})
export const globalPartsResultToJson: Project<GlobalPartsResult, GlobalPartsResultJson> = obj => ({
    ...tableEntityToJson(obj),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
    rolledPartsFlag: RolledPartsFlag,
    partsType: PartsType,
    activeFlag: ActiveFlag,
}

interface JsonRequired {
    partsId: number,
}

interface JsonOptional {
    newMod: string,
    partsNo: string,
    oldPartsNo: string,
    displayPartsNo: string,
    uomCode: string,
    colorCode: string,
    netWeight: number,
    rolledPartsFlag: number,
    rolledPartsUom: string,
    remark1: string,
    remark2: string,
    remark3: string,
    remark4: string,
    remark5: string,
    partsType: number,
    activeFlag: number,
}