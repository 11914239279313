import React, { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { useGetCoForecast } from "../../../services/order/apis/CustomerOrderApi"
import { OrderForecastDetailResult } from "../../../services/order/models/OrderForecastDetailResult"
import { OCS012PcUi } from "./OCS012PcUi"

export const OCS012 = () => {
    const { orderId } = useParams() as any
    const [data, setData] = useState<OrderForecastDetailResult>({} as OrderForecastDetailResult)

    const getCoForecastInfo = useGetCoForecast()
    useEffect(() => {
        getCoForecastInfo({ orderId }, { silent: true, serialized: true }).then(result => {
            setData(result ?? {} as OrderForecastDetailResult)
        })
    }, [orderId, getCoForecastInfo, setData])

    const forecastDates = useMemo(() => {
        // calculate plan list
        if (data && data.partsDetailList && data.partsDetailList.length > 0 && data.partsDetailList[0].forecastList) {
            return data.partsDetailList[0].forecastList.map(forecast => [forecast.targetFirstDate, forecast.targetLastDate])
        }
        return []
    }, [data])

    return <OCS012PcUi data={data} forecastDates={forecastDates} />
}