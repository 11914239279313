import React, { useCallback, useEffect, useState } from "react"
import { useGetContractRouteIdAndCodeListForOverall, useGetCustomerIdAndCodeListForOverall } from "../../../services/managementTool/apis/mtApis"
import { DownloadOverallVisualisationFactor } from "../../../services/managementTool/models/DownloadOverallVisualisationFactor"
import { IdAndCode } from "../../../services/managementTool/models/IdAndCode"
import { MTS050PcUi } from "./MTS050PcUi"

export const MTS050 = () => {
    const [customerList, setCustomerList] = useState<IdAndCode[]>([])
    const [contractRouteList, setContractRouteList] = useState<IdAndCode[]>([])
    const [factor, setFactor] = useState<DownloadOverallVisualisationFactor>({} as DownloadOverallVisualisationFactor)

    const getCustomerList = useGetCustomerIdAndCodeListForOverall()
    useEffect(() => {
        getCustomerList(undefined, { silent: true, serialized: true }).then(result => {
            setCustomerList(result ?? [])
        })
    }, [getCustomerList])

    const getContractRouteList = useGetContractRouteIdAndCodeListForOverall()
    const findContractRouteList = useCallback((factor: DownloadOverallVisualisationFactor) => {
        if (factor.customerId !== undefined && factor.customerId !== null) {
            getContractRouteList({ customerId: factor.customerId }, { silent: true }).then(result => {
                setContractRouteList(result ?? [])
            })
        } else {
            setContractRouteList([])
        }
    }, [getContractRouteList])

    return <MTS050PcUi
        customerList={customerList}
        contractRouteList={contractRouteList}
        factor={factor}
        setFactor={setFactor}
        findContractRouteList={findContractRouteList}
    />
}