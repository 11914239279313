/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback } from "react"
import { Projects } from "../../../utils/Projects"
import { usePost } from "../../../utils/fetch/useFetch"
import { TnmDistributionCenter, tnmDistributionCenterFromJson } from "../../master/models/TnmDistributionCenter"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { CustDIHeaderInfo, custDIHeaderInfoFromJson } from "../models/CustDIHeaderInfo"
import { CustDIParts, custDIPartsFromJson } from "../models/CustDIParts"
import { CustDeliveryTransfer, custDeliveryTransferFromJson } from "../models/CustDeliveryTransfer"
import { CustDeliveryView, custDeliveryViewToJson } from "../models/CustDeliveryView"
import { DeliveryPlanInitResult, deliveryPlanInitResultFromJson } from "../models/DeliveryPlanInitResult"
import { DiPartForBuResult, DiPartForBuResultFromJson } from "../models/DiPartForBuResult"
import { InitOutboundResult, initOutboundResultFromJson } from "../models/InitOutboundResult"

export const useGetCustDeliveryPage = () => {
    const url = '/lcbm-logistics-api/api/getDIPageList'
    const payloadProject = useCallback(custDeliveryViewToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(custDeliveryTransferFromJson), [])
    return usePost<CustDeliveryView, PageResultEntity<CustDeliveryTransfer>>(url, payloadProject, resultProject)
}


export const useGetCustDeliveryForBuPage = () => {
    const url = '/lcbm-logistics-api/api/getDIForBuList'
    const payloadProject = useCallback(custDeliveryViewToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(custDeliveryTransferFromJson), [])
    return usePost<CustDeliveryView, PageResultEntity<CustDeliveryTransfer>>(url, payloadProject, resultProject)
}


export const useDoCancelOfDi = () => {
    const url = '/lcbm-logistics-api/api/doCancelOfDi'
    const payloadProject = useCallback((obj: CustDeliveryTransfer[]) => obj, [])
    const resultProject = Projects.IGNORE
    return usePost<CustDeliveryTransfer[], undefined>(url, payloadProject, resultProject)
}

export const getCustomerDIHeader = () => {
    const url = '/lcbm-logistics-api/api/getCustomerDIHeader'
    const payloadProject = useCallback((obj: { diId: number }) => obj.diId, [])
    const resultProject = useCallback(custDIHeaderInfoFromJson, [])
    return usePost<{ diId: number }, CustDIHeaderInfo>(url, payloadProject, resultProject)
}

export const getCustomerDIDetail = () => {
    const url = '/lcbm-logistics-api/api/getCustomerDIDetail'
    const payloadProject = useCallback((obj: { diId: number }) => obj.diId, [])
    const resultProject = useCallback(Projects.array(custDIPartsFromJson), [])
    return usePost<{ diId: number }, CustDIParts[]>(url, payloadProject, resultProject)
}

export const useInitDeliveryPlanScreen = () => {
    const url = '/lcbm-logistics-api/api/initDeliveryPlan'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(deliveryPlanInitResultFromJson, [])
    return usePost<undefined, DeliveryPlanInitResult>(url, payloadProject, resultProject)
}

export const useInitDIMonitorScreen = () => {
    const url = '/lcbm-logistics-api/api/initDIMonitor'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(tnmDistributionCenterFromJson), [])
    return usePost<undefined, TnmDistributionCenter[]>(url, payloadProject, resultProject)
}

export const useInitOutboundPrioritizationScreen = () => {
    const url = '/lcbm-logistics-api/api/initOutboundPrioritization'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(initOutboundResultFromJson, [])
    return usePost<undefined, InitOutboundResult>(url, payloadProject, resultProject)
}

export const useListDiPartForBu = () => {
    const url = '/lcbm-logistics-api/api/listDiPartsForBu'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(DiPartForBuResultFromJson), [])
    return usePost<undefined, DiPartForBuResult[]>(url, payloadProject, resultProject)
}