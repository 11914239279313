import { Project } from "@rithe/utils"
import { Projects } from "../../../utils/Projects"
import { dateFromJson } from "../../utils/deserializer"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"

export type ActualUsageFactor = Partial<Optional>;
export type ActualUsageFactorJson = Partial<JsonOptional>;

export const actualUsageFactorToJson: Project<ActualUsageFactor, ActualUsageFactorJson> = obj => ({
    ...obj,
    usageDateStart: dateToJson(obj.usageDateStart),
    usageDateEnd: dateToJson(obj.usageDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page)
})

export const actualUsageFactorFromJson: Project<ActualUsageFactorJson, ActualUsageFactor> = json => ({
    ...json,
    usageDateStart: dateFromJson(json.usageDateStart),
    usageDateEnd: dateFromJson(json.usageDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page)
})


interface Optional extends Omit<JsonOptional, 'usageDateStart' | 'usageDateEnd'> {
    usageDateStart: Date,
    usageDateEnd: Date,
    page: PageViewEntity,
}


interface JsonOptional {

    partsNo: string,
    externalRefNo: string,
    customerPartsNo: string,
    backNo: string,
    customerCodes: string[],
    expRegions: string[],
    supplierCodes: string[],
    usageDateStart: string,
    usageDateEnd: string,

    page: PageViewEntityJson
}


