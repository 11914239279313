import {Button, Dialog, DialogContent, DialogContentText, DialogTitle, Typography} from "@material-ui/core"
import { EntryItem, Form, NumberItem, StringItem } from "@rithe/form"
import { GridItem } from "@rithe/ui"
import React, { SetStateAction, memo, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useFunctionStore } from "../../../Root"
import { SubmitCallbackViewAction } from "../../../components/Action/SubmitCallbackViewAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { CodeItem } from "../../../components/Form/CodeItem"
import { SectionTitle } from "../../../components/SectionTitle/SectionTitle"
import { View } from "../../../components/View/View"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { InventoryBoxFlag } from "../../../services/master/enums/InventoryBoxFlag"
import { useUpdateAlarmUsagePatternDetail } from "../../../services/smt/api/AlarmUsagePatternApi"
import { AlarmUsagePatternDetail, OdmCustomerParts, OdmCustomerPartsControl, OdmCustomerPartsOrder } from "../../../services/smt/models/AlarmUsagePatternDetail"
import { OrderGroupTransfer } from "../../../services/smt/models/OrderGroupTransfer"
import { useGetCompanyType } from "../../../utils/ApplicationUtils"
import { applicationActions } from "../../Application/applicationSlice"

interface MSAUF011PcUiProps {
    odmCustomerParts: OdmCustomerParts,
    setOdmCustomerParts: React.Dispatch<SetStateAction<OdmCustomerParts>>,
    odmCustomerPartsControl: OdmCustomerPartsControl,
    setOdmCustomerPartsControl: React.Dispatch<SetStateAction<OdmCustomerPartsControl>>,
    odmCustomerPartsOrder: OdmCustomerPartsOrder,
    setOdmCustomerPartsOrder: React.Dispatch<SetStateAction<OdmCustomerPartsOrder>>,
    mode: ScreenMode,
    search: (customerPartsId: number | null) => void,
    orderGroupList: OrderGroupTransfer[],
}

export const MSAUF011PcUi = (props: MSAUF011PcUiProps) => {
    const intl = useIntl()

    const {mode, odmCustomerParts, odmCustomerPartsControl, odmCustomerPartsOrder} = props
    const [dialog, setDialog] = useState<boolean>(false)

    const actions = mode === ScreenMode.VIEW ? [] : [
        <SubmitAction odmCustomerParts={odmCustomerParts} odmCustomerPartsControl={odmCustomerPartsControl} odmCustomerPartsOrder={odmCustomerPartsOrder} mode={mode} setDialog={setDialog} />
    ]
    return <View actions={actions}>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={1}
                title={intl.formatMessage({ id: 'customerPartsInfo' })}
                subtitle={intl.formatMessage({ id: 'basicCustomerPartsInfoSub' })}
            />
            <SectionCardContent>
                <CustomerPartsFormView {...props} />
            </SectionCardContent>
        </SectionCard>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={2}
                title={intl.formatMessage({ id: 'stockAlarmInfo' })}
                subtitle={intl.formatMessage({ id: 'stockAlarmInfoSub' })}
            />
            <SectionCardContent>
                <StockAlarmFormView {...props} />
            </SectionCardContent>
        </SectionCard>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={3}
                title={intl.formatMessage({ id: 'usagePatternInfo' })}
                subtitle={intl.formatMessage({ id: 'usagePatternInfoSub' })}
            />
            <SectionCardContent>
                <UsagePatternFormView {...props} />
            </SectionCardContent>
        </SectionCard>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={4}
                title={intl.formatMessage({ id: 'orderCalculationInfo' })}
                subtitle={intl.formatMessage({ id: 'orderCalculationInfoSub' })}
            />
            <SectionCardContent>
                <OrderCalculationFormView {...props} />
            </SectionCardContent>
        </SectionCard>
        <WaringMessageDialog dialog={dialog} setDialog={setDialog} />
    </View>
}

const CustomerPartsFormView = ({ odmCustomerParts, setOdmCustomerParts, mode }: MSAUF011PcUiProps) => {
    const intl = useIntl()
    const readOnly = true
    const cbdsType = useGetCompanyType()

    return <Form data={odmCustomerParts} setData={setOdmCustomerParts} labelDisplay="block" helperDisplay="tooltip">
        <StringItem field="partsNo" required readonly={readOnly} label={intl.formatMessage({ id: 'field.partsNo' })} />
        {
            cbdsType === CbdsType.BU ?
                <StringItem field="customerCode" required readonly={readOnly} label={intl.formatMessage({ id: 'field.customerCode' })} />
                : <StringItem field="sellerCode" required readonly={readOnly} label={intl.formatMessage({ id: 'field.sellerCode' })} />
        }
        <StringItem field="externalRefNo" required readonly={readOnly} label={intl.formatMessage({ id: 'field.externalRefNo' })} />

        <StringItem field="customerPartsNo" required readonly={readOnly} label={intl.formatMessage({ id: 'field.customerPartsNo' })} />
        <StringItem field="customerBackNo" readonly={readOnly} label={intl.formatMessage({ id: 'field.customerBackNo' })} />
        <StringItem field="commonPartsNo" readonly={readOnly} label={intl.formatMessage({ id: 'field.commonPartsNo' })} />
    </Form >
}

const StockAlarmFormView = ({ odmCustomerPartsControl, setOdmCustomerPartsControl, mode }: MSAUF011PcUiProps) => {
    const intl = useIntl()
    const readOnly = mode === ScreenMode.VIEW
    const readOnly1 = mode === ScreenMode.VIEW ? true : (odmCustomerPartsControl.inventoryBoxFlag === null ? true : (odmCustomerPartsControl.inventoryBoxFlag === undefined ? true : (odmCustomerPartsControl.inventoryBoxFlag === InventoryBoxFlag.Y)))
    const readOnly2 = mode === ScreenMode.VIEW ? true : (odmCustomerPartsControl.inventoryBoxFlag === null ? true : (odmCustomerPartsControl.inventoryBoxFlag === undefined ? true : (odmCustomerPartsControl.inventoryBoxFlag === InventoryBoxFlag.N)))

    const dataChange = useCallback<React.Dispatch<React.SetStateAction<OdmCustomerPartsControl>>>(nextDraftDataFunc => {
        setOdmCustomerPartsControl(data => {
            const draftData = typeof nextDraftDataFunc === 'function' ? nextDraftDataFunc(data) : nextDraftDataFunc
            if (draftData) {
                if (draftData.inventoryBoxFlag === InventoryBoxFlag.Y) {
                    return { ...draftData, minDays1: undefined, minDays2: undefined, minDays3: undefined, maxDays: undefined }
                } else if (draftData.inventoryBoxFlag === InventoryBoxFlag.N) {
                    return { ...draftData, minBoxes1: undefined, minBoxes2: undefined, minBoxes3: undefined, maxBoxes: undefined }
                } else {
                    return { ...draftData, minDays1: undefined, minDays2: undefined, minDays3: undefined, maxDays: undefined, minBoxes1: undefined, minBoxes2: undefined, minBoxes3: undefined, maxBoxes: undefined }
                }
            }
            return draftData;
        })
    }, [setOdmCustomerPartsControl])

    return <Form data={odmCustomerPartsControl} setData={dataChange} labelDisplay="block" helperDisplay="tooltip">
        <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="basicInfo" /></SectionTitle></GridItem>
        <CodeItem field="inventoryBoxFlag" required readonly={readOnly} label={intl.formatMessage({ id: 'inventoryBoxFlag' })} code={CodeCategory.InventoryBoxFlag} />
        <NumberItem field="outboundFluctuation" readonly={readOnly} label={intl.formatMessage({ id: 'outboundFluctuation' })} />
        <CodeItem field="onshippingDelayPat" readonly={readOnly} label={intl.formatMessage({ id: 'onshippingDelayPat' })} code={CodeCategory.OnShippingDelayPattern} />
        <CodeItem field="alertType" readonly={readOnly} label={intl.formatMessage({ id: 'field.alertType' })} code={CodeCategory.AlertMethod} />
        <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="stockDays" /></SectionTitle></GridItem>
        <NumberItem field="minDays1" readonly={readOnly1} label={intl.formatMessage({ id: 'minStockDays1' })} />
        <NumberItem field="minDays2" readonly={readOnly1} label={intl.formatMessage({ id: 'minStockDays2' })} />
        <NumberItem field="minDays3" readonly={readOnly1} label={intl.formatMessage({ id: 'minStockDays3' })} />
        <NumberItem field="maxDays" readonly={readOnly1} label={intl.formatMessage({ id: 'maxStockDays' })} />
        <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="stockBoxes" /></SectionTitle></GridItem>
        <NumberItem field="minBoxes1" readonly={readOnly2} label={intl.formatMessage({ id: 'minStockBoxes1' })} />
        <NumberItem field="minBoxes2" readonly={readOnly2} label={intl.formatMessage({ id: 'minStockBoxes2' })} />
        <NumberItem field="minBoxes3" readonly={readOnly2} label={intl.formatMessage({ id: 'minStockBoxes3' })} />
        <NumberItem field="maxBoxes" readonly={readOnly2} label={intl.formatMessage({ id: 'maxStockBoxes' })} />
    </Form >
}

const UsagePatternFormView = ({ odmCustomerPartsControl, setOdmCustomerPartsControl, mode }: MSAUF011PcUiProps) => {
    const intl = useIntl()
    const readOnly = mode === ScreenMode.VIEW

    return <Form data={odmCustomerPartsControl} setData={setOdmCustomerPartsControl} labelDisplay="block" helperDisplay="tooltip" >
        <StringItem field="deliveryCustPat" required readonly={readOnly} label={intl.formatMessage({ id: 'deliveryCustPat' })} />
        <CodeItem field="custDelayPat" required readonly={readOnly} label={intl.formatMessage({ id: 'custDelayPat' })} code={CodeCategory.AdjustmentPattern1} />
        <CodeItem field="custAdvancePat" required readonly={readOnly} label={intl.formatMessage({ id: 'custAdvancePat' })} code={CodeCategory.AdjustmentPattern2} />

    </Form >
}

const OrderCalculationFormView = ({ odmCustomerPartsOrder, setOdmCustomerPartsOrder, mode, orderGroupList }: MSAUF011PcUiProps) => {
    const intl = useIntl()
    const readOnly = mode === ScreenMode.VIEW
    const readOnly1 = mode === ScreenMode.VIEW ? true : (odmCustomerPartsOrder.orderGroupId ? false : true)


    const orderGroupMap: [number, string][] = useMemo(() => orderGroupList.map(m => [m.orderGroupId, m.orderGroupNo]), [orderGroupList])

    return <Form data={odmCustomerPartsOrder} setData={setOdmCustomerPartsOrder} >
        <EntryItem field="orderGroupId" readonly={readOnly} label={intl.formatMessage({ id: 'orderGroupNo' })} entries={orderGroupMap} />
        <CodeItem field="safetyStockUom" readonly={readOnly1} label={intl.formatMessage({ id: 'safetyStockUom' })} code={CodeCategory.SafetyStockUom} />
        <NumberItem field="safetyStock" readonly={readOnly1} label={intl.formatMessage({ id: 'safetyStock' })} />

        <NumberItem field="maxStock" readonly={readOnly1} label={intl.formatMessage({ id: 'maxStock' })} />
        {/* <NumberItem field="minStock" readonly={readOnly1} label={intl.formatMessage({ id: 'minStock' })} /> */}
        <CodeItem field="calculationPattern" readonly={readOnly1} label={intl.formatMessage({ id: 'calculationPattern' })} code={CodeCategory.CalculationPattern} />
    </Form >
}

const SubmitAction = memo((props: {
    odmCustomerParts: OdmCustomerParts,
    odmCustomerPartsControl: OdmCustomerPartsControl,
    odmCustomerPartsOrder: OdmCustomerPartsOrder,
    mode: ScreenMode,
    setDialog: React.Dispatch<React.SetStateAction<boolean>>,
}) => {
    const {odmCustomerParts, odmCustomerPartsControl, odmCustomerPartsOrder, setDialog} = props
    const updatedAlarmUsagePatternDetail = useUpdateAlarmUsagePatternDetail()
    // const fields = useMemo(() => getFormCheckFields(true), [])
    // const formValidate = useFormValidater(setMessages, fields)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'submit' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const onclickSubmit = useCallback(() => {
        let alarmUsagePatternDetail: AlarmUsagePatternDetail = {
            customerPart: odmCustomerParts,
            customerPartControl: odmCustomerPartsControl,
            customerPartOrder: odmCustomerPartsOrder,
        }
        const functionId = functionStore.register(() => {
            setDisabled(true)
            updatedAlarmUsagePatternDetail(alarmUsagePatternDetail, {serialized: true}).then((result) => {
                if (result !== null && result.warningFlag === 1) {
                    setDialog(true)
                } else {
                    navigate('/alarmUsagePattern')
                }
            }).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [odmCustomerParts, odmCustomerPartsControl, odmCustomerPartsOrder, functionStore, dispatch, title, updatedAlarmUsagePatternDetail, setDialog, navigate])

    return <>
        <SubmitCallbackViewAction callback={onclickSubmit} disabled={disabled} />
    </>
})

const WaringMessageDialog = memo((props: {
    dialog: boolean,
    setDialog: React.Dispatch<React.SetStateAction<boolean>>,
}) => {
    const {dialog, setDialog} = props
    const navigate = useNavigate()
    const onClose = useCallback(() => {
        setDialog(false)
        navigate('/alarmUsagePattern')
    }, [navigate, setDialog])
    return <Dialog open={dialog} onClose={onClose} maxWidth="xl" style={{flex: 1}} BackdropProps={{style: {backgroundColor: 'rgba(0, 0, 0, 0.2)'}}}>
        <DialogTitle style={{textAlign: 'center'}}><Typography variant="h3"><FormattedMessage id="warningMessageTitle" /></Typography></DialogTitle>
        <DialogContent style={{textAlign: 'center'}}>
            <DialogContentText>
                <FormattedMessage id="warningMessageContent2" />
            </DialogContentText>
            <Button
                variant={'outlined'}
                color="secondary"
                onClick={onClose}
                style={{
                    width: '30%',
                }}>
                <FormattedMessage id="ok" />
            </Button>
        </DialogContent>
    </Dialog>
})
