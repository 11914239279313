/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles, Typography } from "@material-ui/core"
import { Dispatch, SetStateAction, useCallback } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { CargoTackingDetailResult } from "../../../services/cargo/models/CargoTackingDetailResult"
import { SearchConditionResult, Suggestion } from "../../../services/cargo/models/SearchConditionResult"
import { SuggestionCard } from "./SuggestionCard"

export enum ConditionType {
    ORDER = 1,
    BOOKING_NO = 2,
    CONTAINER_NO = 3,
    PARTS_NO = 4,
    ORDER_REF_NO = 5,
}

export const SearchCondition = ({
    suggestions,
    searchCargoTrackingDetail,
    setData,
    selections,
    setSelections,
    orderNo,
}: {
    suggestions: SearchConditionResult,
    searchCargoTrackingDetail: (filters: Suggestion[]) => void,
    setData: Dispatch<SetStateAction<CargoTackingDetailResult>>,
    selections: Suggestion[],
    setSelections: Dispatch<SetStateAction<Suggestion[]>>,
    orderNo: string
}) => {
    const styles = useStyles()
    const intl = useIntl()
    const desiredRecords = suggestions.suggestions ?? []

    const clickMethod = useCallback((orderNo: string | undefined | null, record: Suggestion) => {
        return () => {
            // if order no, no process
            if (orderNo !== undefined && orderNo !== null) {
                return;
            }
            // replace
            const newSelections = selections.includes(record) ? selections.filter(f => f !== record) : [...selections, record]
            // set selections
            setSelections(newSelections)
            // do query
            if (newSelections && newSelections.length > 0) {
                searchCargoTrackingDetail(newSelections)
            } else {
                setData([])
            }
        }
    }, [searchCargoTrackingDetail, selections, setData, setSelections])

    const getTypeName = useCallback((record) => {
        return record.type === ConditionType.ORDER ? intl.formatMessage({ id: 'orderNumber' })
            : record.type === ConditionType.BOOKING_NO ? intl.formatMessage({ id: 'bookingNumber' })
                : record.type === ConditionType.CONTAINER_NO ? intl.formatMessage({ id: 'containerNumber' })
                    : record.type === ConditionType.PARTS_NO ? intl.formatMessage({ id: 'unitPartsNumber' })
                        : record.type === ConditionType.ORDER_REF_NO ? intl.formatMessage({ id: 'externalOrderNo' }) : ""
    }, [intl])

    if (suggestions.suggestions && desiredRecords.length === 0) {
        return <div style={{ textAlign: 'center', width: '100%' }}>
            <Typography variant="h4" >
                <FormattedMessage id={"searchResults"} />
            </Typography >
            <Typography variant="body2" >
                <FormattedMessage id={"noResultFound"} />
            </Typography >
        </div>
    } else {
        return <div className={styles.viewcard}>
            {
                desiredRecords.map((record) => {
                    return <SuggestionCard
                        typeName={getTypeName(record)}
                        suggestion={record.suggestion ?? ''}
                        callback={clickMethod(orderNo, record)}
                        color={selections.indexOf(record) >= 0 ? 'select' : 'default'}
                    />
                })
            }
        </div >
    }
}

const useStyles = makeStyles(theme => ({
    viewcard: {
        width: '100%',
        minWidth: 850,
        paddingBottom: theme.spacing(2),
        columnGap: theme.spacing(3),
        boxSizing: 'border-box',
        display: 'grid',
        gridAutoFlow: 'row',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gridAutoRows: 'max-content'
    }
}))
