
import { DialogActions, DialogContent, Typography } from "@material-ui/core"
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons"
import { ColumnFreeze, ColumnOrdering, ColumnResizing, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { Break, DateItem, EntryItem, Form, Message } from "@rithe/form"
import { GridItem } from "@rithe/ui"
import { Arrays, Records } from "@rithe/utils"
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { Access } from "../../../components/Access/Access"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CardDialog } from "../../../components/Dialog/CardDialog"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { CodeItem } from "../../../components/Form/CodeItem"
import { useDownloadSimpleOutboundByPkg } from "../../../services/delivery/apis/deliveryDownloadApi"
import { OutboundDatasource } from "../../../services/delivery/enums/OutboundDatasource"
import { UploadOutboundType } from "../../../services/delivery/enums/UploadOutboundType"
import { DownloadOutBoundPartsInfo } from "../../../services/delivery/models/DownloadOutBoundPartsInfo"
import { InnerPartsResult } from "../../../services/delivery/models/InnerPartsResult"
import { ContainerInfo, OutboundCreateFactor } from "../../../services/delivery/models/OutboundCreateFactor"
import { PendingOutboundRollPackage } from "../../../services/delivery/models/PedingOutboundRollPackageInfo"
import { RollPackageInfo } from "../../../services/delivery/models/RollPackageInfo"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { ShippingMode } from "../../../services/master/enums/ShippingMode"
import { Pair } from "../../../services/utils/Pair"
import { today, useGetCompanyCode } from "../../../utils/ApplicationUtils"
import { useFieldChecker, useFormValidater } from "../../../utils/ValidatorUtils"
import { applicationActions } from "../../Application/applicationSlice"

interface DownloadSimpleOutboundFormProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    dbRollPartList: InnerPartsResult[],
    dbRollPackageList: PendingOutboundRollPackage[],
    buyerList: Pair[],
    receiverList: Pair[],
    outbound: OutboundCreateFactor,
    containers: ContainerInfo[],
}
enum Step {
    INPUT,
    SELECTPARTS,
    SELECTPACKAGE
}

export const DownloadSimpleOutboundForm = ({ open, setOpen, buyerList, receiverList, dbRollPartList, dbRollPackageList, outbound, containers }: DownloadSimpleOutboundFormProps) => {

    const dispatch = useDispatch()
    const [data, setData] = useState<OutboundCreateFactor>(defaultOutboundInfo)
    const [rollPackageList, setRollPackageList] = useState<RollPackageInfo[]>([])
    const [step, setStep] = useState<Step>(Step.INPUT)
    const [displayParts, setDisplayParts] = useState<InnerPartsResult[]>([])
    const [selections, setSelections] = useState<string[]>([])
    const [inbPkgIds, setInbPkgIds] = useState<number[]>([])
    const [messages, setMessages] = useState<Message[]>([])
    const intl = useIntl()

    const shipper = useGetCompanyCode()
    const formValidate = useFormValidater(setMessages, {
        buyer: { labelId: 'buyer', required: true },
        receiver: { labelId: 'receiverCode', required: true }
    })

    // do close
    const handleClose = useCallback(() => {
        setOpen(false)
        setStep(Step.INPUT)
        setSelections([])
        setInbPkgIds([])
    }, [setOpen, setStep])

    // handle go to selected parts
    const goToSelectParts = useCallback(() => {
        // do check 
        if (formValidate(data)) {
            // get parts from list
            const buyerInfo = buyerList.find(f => f.first === data.buyer);
            setDisplayParts(dbRollPartList.filter(m => m.buyers.includes(buyerInfo?.second as string ?? '')))
            // go to next step
            setStep(Step.SELECTPARTS)
        }
    }, [buyerList, data, formValidate, dbRollPartList])

    // handle go to selected pkg
    const goToSelectPkg = useCallback(() => {
        // do check 
        if (selections.length > 0) {
            // get parts from list
            const sellerPartsNos = selections.map(select => {
                const selectedParts = displayParts.filter(part => part.sellerPartsNo === select)[0]
                return selectedParts?.sellerPartsNo
            })
            
            setRollPackageList(dbRollPackageList.filter(p => p.sellerPartsNo && sellerPartsNos.includes(p.sellerPartsNo)))
            // go to next step
            setStep(Step.SELECTPACKAGE)
        } else {
            dispatch(applicationActions.pushError({
                title: intl.formatMessage({ id: 'Select Parts' }),
                messages: [{ code: 'w0575' }],
            }))
        }
    }, [dbRollPackageList, dispatch, displayParts, intl, selections])

    // same as screen
    useEffect(() => {
        setData((data) => ({
            buyer: (outbound.buyer && outbound.buyer !== '') ? outbound.buyer : data.buyer,
            receiver: (outbound.receiver && outbound.receiver !== '') ? outbound.receiver : data.receiver,
            outboundRefNo: (outbound.outboundRefNo && outbound.outboundRefNo !== '') ? outbound.outboundRefNo : data.outboundRefNo,
            outboundType: (outbound.outboundType !== null && outbound.outboundType !== undefined) ? outbound.outboundType : data.outboundType,
            outboundDate: outbound.outboundDate ?? data.outboundDate,
            shippingMode: outbound.shippingMode ?? data.shippingMode,
            datasource: data.datasource
        }))
    }, [outbound])

    // same as screen
    useEffect(() => {
        const selectedPartsList = containers.flatMap(f => f.outerPackageList ?? []).flatMap(f => f.innerPackageList ?? []).flatMap(f => f.partsList ?? [])
        if (selectedPartsList && selectedPartsList.length > 0) {
            setSelections(dbRollPartList.filter(f => selectedPartsList.some(s => s.partsNo === f.partsNo && s.sellerPartsNo === f.sellerPartsNo)).map(m => m.sellerPartsNo))
        }
    }, [containers, dbRollPartList])

    // handle back to input 
    const backToInput = useCallback(() => setStep(Step.INPUT), [setStep])

    // handle download
    const downloadApi = useDownloadSimpleOutboundByPkg()
    const download = useCallback(() => {
        // selections
        if (inbPkgIds.length > 0) {
            // get data from selections
            downloadApi(prepareDownload(data, buyerList, receiverList, shipper ?? '', inbPkgIds))
            handleClose()
        } else {
            const allPkgIds: number[] = []
            rollPackageList.forEach(p => {
                if (p.inbPkgId) {
                    allPkgIds.push(p.inbPkgId)
                }
            } )
            downloadApi(prepareDownload(data, buyerList, receiverList, shipper ?? '', allPkgIds))
            handleClose()
        }
    }, [buyerList, data, downloadApi, handleClose, inbPkgIds, receiverList, rollPackageList, shipper])

    // step
    return <CardDialog keepMounted={false} maxWidth={"lg"} fullWidth open={open} onClose={handleClose} >
        <DialogHeader onClose={handleClose}><FormattedMessage id="DownloadOutboundForm" /></DialogHeader>
        <DialogContent style={{ height: 600 }}>
            {step === Step.INPUT
                && <Step1InputFilters buyerList={buyerList} receiverList={receiverList} data={data} setData={setData} messages={messages} setMessages={setMessages} />}
            {step === Step.SELECTPARTS
                && <Step2SelectPartsList partsList={displayParts} selections={selections} setSelections={setSelections} />}
            {step === Step.SELECTPACKAGE
                && <Step3SelectPkgList rollPackageList={rollPackageList} partsList={displayParts} inbPkgIds={inbPkgIds} setInbPkgIds={setInbPkgIds} />}
        </DialogContent>
        <DialogActions>
            {step === Step.INPUT
                && <DialogAction outlined title={<><FormattedMessage id="goToSelectParts" />&nbsp;<ArrowForwardIos fontSize="inherit" /></>} callback={goToSelectParts} />}
            {step === Step.SELECTPARTS
                && <DialogAction outlined title={<><ArrowBackIos fontSize="inherit" /><FormattedMessage id="backToInput" /></>} callback={backToInput} />}
            {step === Step.SELECTPARTS
                && <DialogAction outlined title={<><FormattedMessage id="goToSelectPkg" />&nbsp;<ArrowForwardIos fontSize="inherit" /></>} callback={goToSelectPkg} />}
            {step === Step.SELECTPACKAGE
                && <DialogAction outlined title={<><ArrowBackIos fontSize="inherit" /><FormattedMessage id="goToSelectParts" /></>} callback={goToSelectParts} />}
            {step === Step.SELECTPACKAGE
                && <Access access="LOGI.LOS035.DOWNLOAD">
                    <DialogAction title={<FormattedMessage id="download" />} callback={download} />
                </Access>}
        </DialogActions >
    </CardDialog >
}

const Step1InputFilters = ({ buyerList, receiverList, data, setData, messages, setMessages }: {
    buyerList: Pair[],
    receiverList: Pair[],
    data: OutboundCreateFactor,
    setData: Dispatch<SetStateAction<OutboundCreateFactor>>,
    messages: Message[],
    setMessages: Dispatch<SetStateAction<Message[]>>,
}) => {

    const intl = useIntl()
    const receivers = useMemo(() => receiverList.map(m => [m.first, m.second] as [string, string]), [receiverList])
    const buyers = useMemo(() => Arrays.distinct(buyerList.map(m => [m.first, m.second] as [string, string])), [buyerList])
    const checkFields = useMemo(() => ({
        buyer: { labelId: 'buyer', required: true },
        receiver: { labelId: 'receiverCode', required: true }
    }), [])
    const filedCheck = useFieldChecker(checkFields, setMessages)

    return <Form data={data} setData={setData} labelDisplay="block" helperDisplay="tooltip" columnCount={3} messages={messages} setMessages={setMessages} ensure={filedCheck}>
        <GridItem columnSpan={3}><FormattedMessage id="step1ForDownloadOutboundForm" /></GridItem>
        <EntryItem field="buyer" required label={intl.formatMessage({ id: 'buyer' })} entries={buyers} />
        <EntryItem field="receiver" required label={intl.formatMessage({ id: 'receiverCode' })} entries={receivers} />
        <Break />
        <CodeItem field="outboundType" label={intl.formatMessage({ id: 'field.outboundType' })} code={CodeCategory.UploadOutboundType} />
        <DateItem field="outboundDate" label={intl.formatMessage({ id: 'field.outboundDate' })}></DateItem>
        <CodeItem field="shippingMode" label={intl.formatMessage({ id: 'field.shippingMode' })} code={CodeCategory.ShippingMode} />
    </Form>
}

const Step2SelectPartsList = ({ partsList, selections, setSelections }: {
    partsList: InnerPartsResult[],
    selections: string[],
    setSelections: Dispatch<SetStateAction<string[]>>
}) => {
    const intl = useIntl()
    const columns = useMemo(() => [
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 180 },
        { field: 'sellerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sellerPartsNo' }), width: 180 },
        { field: 'sellerPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sellerPartsName' }), width: 260 },
        { field: 'notYetDeliveryQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'availableDeliveryQty' }), width: 180 },
    ], [intl])
    const getRowId = useCallback((row: any) => row.sellerPartsNo, [])

    return <>
        <Typography variant="body2" style={{ padding: 8 }}><FormattedMessage id="step2ForDownloadOutboundForm" /> </Typography>
        <div style={{ height: '91%' }}>
            <DataGrid>
                <ToolbarLayout />
                <TableLayout Container={FlexScrollbar}>
                    <TableHeaderLayout sticky />
                    <TableBodyLayout />
                </TableLayout>
                <PaginationLayout Pagination={Pagination} />
                <DataTypePreset />
                <Data rows={partsList} columns={columns} getRowId={getRowId} />
                <ColumnFreeze />
                <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
                <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
                <Searching ignoreCase Input={SearchInput} />
                <Sorting />
                <Filtering />
                <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
                <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
            </DataGrid>
        </div >
    </>
}

const Step3SelectPkgList = ({ rollPackageList, partsList, inbPkgIds, setInbPkgIds }: {
    rollPackageList: RollPackageInfo[],
    partsList: InnerPartsResult[],
    inbPkgIds: number[],
    setInbPkgIds: Dispatch<SetStateAction<number[]>>
}) => {
    const displayData = rollPackageList.map(pkg => {
        const parts = partsList.filter(p => p.sellerPartsNo === pkg.sellerPartsNo)
        const partsInfo = parts.length > 0 ? parts[0] : null
        return {
            ...pkg,
            stockDays: pkg.productionDate ? Math.floor(((today().getTime() - pkg.productionDate.getTime()) / (1 * 24 * 60 * 60 * 1000))) : null,
            sellerPartsNo: partsInfo !== null ? partsInfo.sellerPartsNo : null,
        }
    }).sort((a, b) => { return (b.stockDays ?? 0) - (a.stockDays ?? 0) })

    const intl = useIntl()
    const columns = useMemo(() => [
        { field: 'outerPackageNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'outerPkgNo' }), width: 180 },
        { field: 'sellerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sellerPartsNo' }), width: 180 },
        { field: 'stockDays', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.stockDays' }), width: 260 },
        { field: 'qty', dataTypeName: 'number', title: intl.formatMessage({ id: 'availableDeliveryQty' }), width: 180 },
    ], [intl])
    const getRowId = useCallback((row: any) => row.inbPkgId, [])

    return <>
        <Typography variant="body2" style={{ padding: 1 }}><FormattedMessage id="step3ForDownloadSimpleOutboundForm" /> </Typography>
        <Typography variant="body2" style={{ padding: 4 }}><FormattedMessage id="downloadAllIfNoSelecting" /> </Typography>
        <div style={{ height: '91%' }}>
            <DataGrid>
                <ToolbarLayout />
                <TableLayout Container={FlexScrollbar}>
                    <TableHeaderLayout sticky />
                    <TableBodyLayout />
                </TableLayout>
                <PaginationLayout Pagination={Pagination} />
                <DataTypePreset />
                <Data rows={displayData} columns={columns} getRowId={getRowId} />
                <ColumnFreeze />
                <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
                <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
                <Searching ignoreCase Input={SearchInput} />
                <Sorting />
                <Filtering />
                <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
                <Selection showSelectAll highlightSelectedRow selectedRowIds={inbPkgIds} onSelectedRowIdsChange={setInbPkgIds} />
            </DataGrid>
        </div >
    </>
}

export const defaultOutboundInfo = {
    buyer: '',
    receiver: '',
    outboundRefNo: '',
    outboundDate: today(),
    outboundType: UploadOutboundType.OUTBOUND,
    shippingMode: ShippingMode.SEA,
    datasource: OutboundDatasource.INTERNAL,
}

const prepareDownload = (data: OutboundCreateFactor, buyerList: Pair[], receiverList: Pair[], shipper: string, inbPkgIds: number[]) => {

    // prepare
    const receiver = receiverList.find(f => f.first === data.receiver)?.second
    const buyer = buyerList.find(f => f.first === data.buyer)?.second
    // return
    return {
        shipper: shipper,
        outboundNo: data.outboundRefNo,
        outboundDate: data.outboundDate,
        outboundTime: data.outboundTime,
        bookingNo: data.bookingNo,
        shippingMode: data.shippingMode,
        outboundType: data.outboundType,
        receiver: receiver,
        buyer: buyer,
        etd: data.etd,
        eta: data.eta,
        outPackageIds: inbPkgIds
    } as DownloadOutBoundPartsInfo
}