import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { dateToJson } from "../../utils/serializer"
import { CoStatus } from "../enums/CoStatus"
import { OrderFrequency } from "../enums/OrderFrequency"
import { OrderType } from "../enums/OrderType"
import { PoStatus } from "../enums/PoStatus"
import { RequestStatus } from "../enums/RequestStatus"
import { RequestType } from "../enums/RequestType"
import { SoStatus } from "../enums/SoStatus"


export type CCRequestDetailResult = Partial<Optional>
type CCRequestDetailResultJson = Partial<JsonOptional>
export const cCRequestDetailResultFromJson: Project<CCRequestDetailResultJson, CCRequestDetailResult> = json => ({
    header: json.header ? requestDetailHeadResultFromJson(json.header) : undefined,
    detailList: json.detailList ? json.detailList.map(m => requestPartsDetailResultFromJson(m)) : undefined,
})
export const cCRequestDetailResultToJson: Project<CCRequestDetailResult, CCRequestDetailResultJson> = obj => ({
    header: obj.header ? requestDetailHeadResultToJson(obj.header) : undefined,
    detailList: obj.detailList ? obj.detailList.map(m => requestPartsDetailResultToJson(m)) : undefined,
})
interface Optional {
    header: RequestDetailHead,
    detailList: RequestPartsDetail[]
}
interface JsonOptional {
    header: RequestDetailHeadJson,
    detailList: RequestPartsDetailJson[]
}

export type RequestDetailHead = Partial<RequestHeaderDetailOptional>
type RequestDetailHeadJson = Partial<RequesHeaderDetailJsonOptional>
const requestDetailHeadResultFromJson: Project<RequestDetailHeadJson, RequestDetailHead> = json => ({
    ...json,
    requestCreatedDate: dateFromJson(json.requestCreatedDate),
    requestHandledDate: dateFromJson(json.requestHandledDate),
    submittedDate: dateFromJson(json.submittedDate),
    firmDateStart: dateFromJson(json.firmDateStart),
    firmDateEnd: dateFromJson(json.firmDateEnd),
    forecastDates: json.forecastDates ? json.forecastDates.map(m => m.map(fcDate => dateFromJson(fcDate))) : undefined,
})
const requestDetailHeadResultToJson: Project<RequestDetailHead, RequestDetailHeadJson> = obj => ({
    ...obj,
    requestCreatedDate: dateToJson(obj.requestCreatedDate),
    requestHandledDate: dateToJson(obj.requestHandledDate),
    submittedDate: dateToJson(obj.submittedDate),
    firmDateStart: dateToJson(obj.firmDateStart),
    firmDateEnd: dateToJson(obj.firmDateEnd),
    forecastDates: obj.forecastDates ? obj.forecastDates.map(m => m.map(fcDate => dateToJson(fcDate))) : undefined,
})
interface RequestHeaderDetailOptional extends Omit<RequesHeaderDetailJsonOptional, 'requestCreatedDate' | 'requestHandledDate' | 'submittedDate' | 'firmDateStart' | 'firmDateEnd' | 'forecastDates' > {
    requestType: RequestType,
    requestStatus: RequestStatus,
    requestCreatedDate: Date,
    requestHandledDate: Date,
    submittedDate: Date,
    orderType: OrderType,
    orderFrequency: OrderFrequency,
    status: SoStatus | PoStatus | CoStatus,
    firmDateStart: Date,
    firmDateEnd: Date,
    forecastDates: Date[][],
}
interface RequesHeaderDetailJsonOptional {
    roSoId: number,
    dataFlag: number,
    requestNo: string,
    requestType: number,
    requestStatus: number,
    requestCreatedBy: string,
    requestCreatedDate: string,
    requestHandledBy: string,
    requestHandledDate: string,
    orderId: number,
    orderNo: string,
    customerRefNo: string,
    buyer: string,
    seller: string,
    contractId: number,
    contractNo: string,
    contractRouteId: number,
    contractRouteNo: string,
    submittedBy: string,
    submittedDate: string,
    orderType: number,
    orderFrequency: number,
    orderStatus: number,
    shippingMode: number,
    firmDateStart: string,
    firmDateEnd: string,
    isAcknowledger: number,
    orderSeq: number,
    showSuppOutboundFlag?: number,
    rejectRemark?: string,
    forecastNum: number,
    forecastDates: string[][],

}


export type RequestPartsDetail = Partial<RequestPartsDetailOptional>
type RequestPartsDetailJson = Partial<RequestPartsDetailJsonOptional>
const requestPartsDetailResultFromJson: Project<RequestPartsDetailJson, RequestPartsDetail> = json => ({
    ...json,
    oldCrdQtys: json.oldCrdQtys ? json.oldCrdQtys.map(crdQtysDeserializer) : [],
    newCrdQtys: json.newCrdQtys ? json.newCrdQtys.map(crdQtysDeserializer) : [],
    oldSuppOutboundDrQtys: json.oldSuppOutboundDrQtys ? json.oldSuppOutboundDrQtys.map(crdQtysDeserializer) : [],
    newSuppOutboundDrQtys: json.newSuppOutboundDrQtys ? json.newSuppOutboundDrQtys.map(crdQtysDeserializer) : [],
})
const requestPartsDetailResultToJson: Project<RequestPartsDetail, RequestPartsDetailJson> = obj => ({
    ...obj,
    oldCrdQtys: obj.oldCrdQtys ? obj.oldCrdQtys.map(crdQtysSerializer) : [],
    newCrdQtys: obj.newCrdQtys ? obj.newCrdQtys.map(crdQtysSerializer) : [],
    oldSuppOutboundDrQtys: obj.oldSuppOutboundDrQtys ? obj.oldSuppOutboundDrQtys.map(crdQtysSerializer) : [],
    newSuppOutboundDrQtys: obj.newSuppOutboundDrQtys ? obj.newSuppOutboundDrQtys.map(crdQtysSerializer) : [],
})
interface RequestPartsDetailOptional extends Omit<RequestPartsDetailJsonOptional, 'oldCrdQtys' | 'newCrdQtys' | 'oldSuppOutboundDrQtys' | 'newSuppOutboundDrQtys'> {
    oldCrdQtys: crdQtys[],
    newCrdQtys: crdQtys[],
    oldSuppOutboundDrQtys?: crdQtys[],
    newSuppOutboundDrQtys?: crdQtys[]
}
interface RequestPartsDetailJsonOptional {
    soDetailId: number,
    soDetailOrigin: string,
    changeFlag: number,
    partsId: number,
    partsNo: string,
    partsDescription: string,
    unitPartsId: string,
    unitPartsNo: string,
    backNo: string,
    supplierId: number,
    supplier: string,
    colorCode: string,
    uom: string,
    pairedPartsNo: string,
    spq: number,
    orderLot: number,
    uomDigits: number,
    fluctuationReason: string,
    oldFirm: number,
    newFirm: number,
    lastOrderForecast1: number,
    oldCrdQtys: crdQtysJson[],
    newCrdQtys: crdQtysJson[],
    oldSuppOutboundDrQtys?: crdQtysJson[],
    newSuppOutboundDrQtys?: crdQtysJson[]
}

export interface crdQtys extends Omit<crdQtysJson, 'crdDate' | 'drDate'> {
    crdDate?: Date,
    drDate: Date,
}

interface crdQtysJson {
    soDetailId?: number,
    soDetailOrigin?: string,
    soPartsId?: number,
    roDetailId?: number,
    drType?: number,
    drId?: number,
    changeFlag?: number,
    crdDate?: string,
    drDate: string,
    drQty: number,
}

const crdQtysDeserializer: Project<crdQtysJson, crdQtys> = json => ({
    ...json,
    crdDate: dateFromJson(json.crdDate),
    drDate: dateFromJson(json.drDate)
})

const crdQtysSerializer: Project<crdQtys, crdQtysJson> = obj => ({
    ...obj,
    crdDate: dateToJson(obj.crdDate),
    drDate: dateToJson(obj.drDate)
})