import { Button } from "@material-ui/core"
import AttachFileIcon from '@material-ui/icons/AttachFile'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import RefreshIcon from '@material-ui/icons/Refresh'
import { Break, DateItem, Form, Message, StringItem } from "@rithe/form"
import { GridItem } from "@rithe/ui"
import React, { memo, useCallback, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useFunctionStore } from "../../../Root"
import { Access } from "../../../components/Access/Access"
import { SubmitCallbackViewAction } from "../../../components/Action/SubmitCallbackViewAction"
import { UploadButton } from "../../../components/Button/UploadButton"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { CodeItem } from "../../../components/Form/CodeItem"
import { View } from "../../../components/View/View"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { TodoListTypeMode } from "../../../services/common/enums/TodoListTypeMode"
import { useDownloadTDL, useInsert, useUpdateById, useUploadTDL } from "../../../services/delivery/apis/deliveryToDoListApi"
import { TnmUserToListResult } from "../../../services/delivery/models/TnmUserToListResult"
import { TnmUserToListView } from "../../../services/delivery/models/TnmUserToListView"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { TodoListStatus } from "../../../services/master/enums/TodoListStatus"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { dateToJson, datetimeToJson } from "../../../services/utils/serializer"
import { useFieldChecker, useFormValidater } from "../../../utils/ValidatorUtils"
import { applicationActions } from "../../Application/applicationSlice"

interface TDL011PcUiProps {
    todolistInfo: TnmUserToListResult,
    setTodoListInfo: React.Dispatch<React.SetStateAction<TnmUserToListResult>>,
    search: (todoId: number | null) => void,
    mode: ScreenMode,
    companyList: TnvCbds[],
}

export const TDL011PcUi = (props: TDL011PcUiProps) => {
    const { todolistInfo, setTodoListInfo, mode } = props
    const intl = useIntl()
    const [messages, setMessages] = useState<Message[]>([])
    const actions = mode === ScreenMode.VIEW ? [] : [
        <SubmitAction todolistInfo={todolistInfo} setMessages={setMessages} mode={mode} />
    ]

    return (
        <View actions={actions}>
            <SectionCard allowCollapse>
                <SectionCardHeader serialNumber={1} title={intl.formatMessage({ id: 'BasicInfo' })} subtitle={intl.formatMessage({ id: 'basicInfo' })} />
                <SectionCardContent>
                    <HeaderView todolistInfo={todolistInfo} setTodoListInfo={setTodoListInfo} messages={messages} setMessages={setMessages} mode={mode} />
                </SectionCardContent>
            </SectionCard>
        </View >

    )
}

const HeaderView = (props: {
    todolistInfo: TnmUserToListResult,
    setTodoListInfo: React.Dispatch<React.SetStateAction<TnmUserToListResult>>,
    mode: ScreenMode,
    messages: Message[],
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>
}) => {
    const { todolistInfo, setTodoListInfo, mode, messages, setMessages } = props
    if (mode === ScreenMode.CREATE) {
        todolistInfo.status = TodoListStatus.Pending
    }
    const intl = useIntl()
    if (todolistInfo.todoType === TodoListTypeMode.SYSTEM) {
        let titleParamArr: string[] = []
        if (todolistInfo.titleParam) {
            titleParamArr = todolistInfo.titleParam.split(",")

        }
        todolistInfo.task = todolistInfo.task ? intl.formatMessage({ id: todolistInfo.task }, titleParamArr as Record<number, string>) : ''
    }
    const fields = useMemo(() => getFormCheckFields(true), [])
    const filedCheck = useFieldChecker(fields, setMessages)
    const readOnly = mode === ScreenMode.VIEW

    return (
        <Form data={todolistInfo} setData={setTodoListInfo} labelDisplay="block" helperDisplay="tooltip" messages={messages} setMessages={setMessages} ensure={filedCheck}>
            <StringItem field="task" required readonly={readOnly} label={intl.formatMessage({ id: 'field.task' })} />
            <CodeItem field="taskCategory" readonly={readOnly} label={intl.formatMessage({ id: 'field.taskCategory' })} code={CodeCategory.TodoListTaskType} />
            <Break />
            <DateItem field="dueDate" readonly={readOnly} label={<><CalendarTodayIcon />{intl.formatMessage({ id: 'field.dueDate' })}</>} />
            <CodeItem field='recurrence' readonly={readOnly} label={<><RefreshIcon />{intl.formatMessage({ id: 'field.recurrence' })}</>} code={CodeCategory.TodoListRecurrence} />
            <Break />
            <DateItem field="remindStartDate" required readonly={readOnly} label={<><NotificationsActiveIcon />{intl.formatMessage({ id: 'field.remindStartDate' })}</>} />
            <DateItem field="remindEndDate" readonly={readOnly} label={<><NotificationsActiveIcon />{intl.formatMessage({ id: 'field.remindEndDate' })}</>} />
            <Break />
            <StringItem field="description" readonly={readOnly} label={intl.formatMessage({ id: 'field.description' })} colSpan={2} />
            <Break />
            <StringItem field="fileName" readonly={readOnly} label={<><AttachFileIcon />Add File</>} ></StringItem>
            <GridItem style={{ display: 'flex', alignItems: 'center', marginTop: 45 }}>
                <DownloadAction mode={mode} todolistInfo={todolistInfo} />
                <UploadAction mode={mode} todolistInfo={todolistInfo} setTodoListInfo={setTodoListInfo} />
                <>  </>
                <DeleteAction mode={mode} todolistInfo={todolistInfo} setTodoListInfo={setTodoListInfo} />
            </GridItem>
            <Break />
            <CodeItem field="urgency" required readonly={readOnly} label={intl.formatMessage({ id: 'field.prority' })} code={CodeCategory.TodoListPrority} />

        </Form>
    )
}

const SubmitAction = memo((props: {
    todolistInfo: TnmUserToListResult,
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>
    mode: ScreenMode,
}) => {
    const { todolistInfo, setMessages, mode } = props
    const updateById = useUpdateById()
    const insert = useInsert()
    const dispatch = useDispatch()
    const intl = useIntl()
    const fields = useMemo(() => getFormCheckFields(true), [])
    const formValidate = useFormValidater(setMessages, fields)
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'submit' }), [intl])
    const navigate = useNavigate()
    const onclickSubmit = useCallback(() => {
        const functionId = functionStore.register(() => {
            if (formValidate(todolistInfo)) {
                let todolistView: TnmUserToListView = {
                    todoId: todolistInfo.todoId,
                    fromUserId: todolistInfo.fromUserId,
                    toCbdsUid: todolistInfo.toCbdsUid,
                    toUserId: todolistInfo.toUserId,
                    title: todolistInfo.task,
                    titleParam: todolistInfo.titleParam,
                    description: todolistInfo.description,
                    descriptionParam: todolistInfo.descriptionParam,
                    todoType: (todolistInfo.todoType) ? (todolistInfo.todoType) : TodoListTypeMode.USER,
                    scheduleType: todolistInfo.scheduleType,
                    urgency: todolistInfo.urgency,
                    url: todolistInfo.url,
                    urlParam: todolistInfo.urlParam,
                    taskKey: todolistInfo.taskKey,
                    remindStartDate: dateToJson(todolistInfo.remindStartDate),
                    remindEndDate: dateToJson(todolistInfo.remindEndDate),
                    status: todolistInfo.status,
                    createdBy: todolistInfo.createdBy,
                    createdDate: datetimeToJson(todolistInfo.createdDate),
                    updatedBy: todolistInfo.updatedBy,
                    updatedDate: datetimeToJson(todolistInfo.updatedDate),
                    version: todolistInfo.version,
                    importanceFlag: todolistInfo.importanceFlag,
                    dueDate: dateToJson(todolistInfo.dueDate),
                    task: todolistInfo.task,
                    taskCategory: todolistInfo.taskCategory,
                    recurrence: todolistInfo.recurrence,
                    file: todolistInfo.file,
                    fileName: todolistInfo.fileName,
                }
                const updatedCompanyInfo = mode === ScreenMode.EDIT ? updateById : insert
                updatedCompanyInfo({ todolistView: todolistView }, { serialized: true }).then((result) => {
                    navigate('/todolist')
                })
            }
        })

        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))

    }, [functionStore, dispatch, title, formValidate, todolistInfo, mode, updateById, insert, navigate])

    return <SubmitCallbackViewAction callback={onclickSubmit} />
})

const getFormCheckFields = (isIssue: boolean) => {
    return ({
        task: { labelId: 'field.task', required: true, length: { max: 200 } },
        urgency: { labelId: 'field.urgency', required: true, length: { max: 11 } },
        remindStartDate: { labelId: 'field.remindStartDate', required: true },
    })
}

const DownloadAction = memo((props: {
    todolistInfo: TnmUserToListResult,
    mode: ScreenMode,
}) => {
    const { mode, todolistInfo } = props
    const todoId = todolistInfo.todoId
    const file = todolistInfo.file
    const useDownloadTDLs = useDownloadTDL()
    const download = useCallback(() => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useDownloadTDLs({ todoId: todoId, file: file })
    }, [file, todoId, useDownloadTDLs])

    if (mode === ScreenMode.VIEW && file) {
        return <>
            <Button onClick={download} color="primary" variant="contained">download</Button>
        </>
    }
    return <></>
})

const UploadAction = memo((props: {
    todolistInfo: TnmUserToListResult,
    setTodoListInfo: React.Dispatch<React.SetStateAction<TnmUserToListResult>>,
    mode: ScreenMode,
}) => {
    const { mode, todolistInfo, setTodoListInfo } = props
    const uploadMethod = useUploadTDL()

    const changeFun = useCallback((files: File[] | null) => {
        if (files === null) return
        if (todolistInfo.file !== null && todolistInfo.file !== undefined) {
            overwriteFun()
        }
        uploadMethod({ files: files[0], }, { serialized: true }).then((fileKey) => {
            setTodoListInfo({ ...todolistInfo, file: fileKey, fileName: files[0].name })
        })

    }, [setTodoListInfo, todolistInfo, uploadMethod])
    if (mode === ScreenMode.VIEW) {
        return <></>
    }
    return <Access access="LOGI.TDL011.UPLOAD">
        <UploadButton onChange={changeFun} />
    </Access>
})

const DeleteAction = memo((props: {
    todolistInfo: TnmUserToListResult,
    setTodoListInfo: React.Dispatch<React.SetStateAction<TnmUserToListResult>>,
    mode: ScreenMode,
}) => {
    const { mode, todolistInfo, setTodoListInfo } = props
    const onDeleteFile = useCallback(() => {
        setTodoListInfo(todo => ({ ...todo, file: undefined, fileName: undefined }))
    }, [setTodoListInfo])
    if ((mode === ScreenMode.VIEW) || (!todolistInfo.file)) {
        return <></>
    }
    return <Access access="LOGI.TDL011.DELETEFILE">
        <Button onClick={onDeleteFile} style={{ backgroundColor: 'red' }} variant="contained">delete</Button>
    </Access>
})

const overwriteFun = () => {
    return (window.confirm("Do you want to cover the original file?"))
}