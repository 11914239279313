/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback } from "react"
import { IntlString } from "../../../utils/IntlString"
import { Projects } from "../../../utils/Projects"
import { usePost, usePrint } from "../../../utils/fetch/useFetch"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { InnerPartsResult, innerPartsResultFromJson } from "../models/InnerPartsResult"
import { OutboundCreateFactor, OuterPackageInfo, outboundCreateFactorFromJson, outboundCreateFactorToJson, outerPackageInfoFromJson } from "../models/OutboundCreateFactor"
import { OutboundMonitorInfo, outboundMonitorInfoFromJson } from "../models/OutboundMonitorInfo"
import { OutboundMonitorTransfer, outboundMonitorTransferFromJson } from "../models/OutboundMonitorTransfer"
import { OutboundMonitorView, outboundMonitorViewToJson } from "../models/OutboundMonitorView"
import { OutboundPartsResult, outboundPartsResultFromJson } from "../models/OutboundPartsResult"
import { OutboundToWIPEntityList, outboundToWIPEntityListFromJson } from "../models/OutboundToWIPEntityList"
import { OutboundToWIPFactor, outboundToWIPFactorToJson } from "../models/OutboundToWIPFactor"
import { PendingOutboundRollPackage, pendingOutboundRollPackageFromJson } from "../models/PedingOutboundRollPackageInfo"
import { RollPackageInfo, rollPackageInfoFromJson } from "../models/RollPackageInfo"

export const useGetOutboundPage = () => {
    const url = '/lcbm-logistics-api/api/getOutboundPageByVO'
    const payloadProject = useCallback(outboundMonitorViewToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(outboundMonitorTransferFromJson), [])
    return usePost<OutboundMonitorView, PageResultEntity<OutboundMonitorTransfer>>(url, payloadProject, resultProject)
}

export const useGetReceiverList = () => {
    const url = '/lcbm-logistics-api/api/getReceiverList'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback((receiverList: { first: string, second: string }[]) => { return { receiverList: receiverList } }, [])
    return usePost<undefined, { receiverList: { first: string, second: string }[] }>(url, payloadProject, resultProject)
}

export const useGetBuyerList = () => {
    const url = '/lcbm-logistics-api/api/getBuyerList'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback((buyerList: { first: string, second: string }[]) => { return { buyerList: buyerList } }, [])
    return usePost<undefined, { buyerList: { first: string, second: string }[] }>(url, payloadProject, resultProject)
}

export const useGetOutboundInfo = () => {
    const url = '/lcbm-logistics-api/api/getOutboundInfo'
    const payloadProject = useCallback((obj: { outboundNo: string }) => obj.outboundNo, [])
    const resultProject = useCallback(outboundMonitorInfoFromJson, [])
    return usePost<{ outboundNo: string }, OutboundMonitorInfo>(url, payloadProject, resultProject)
}

export const useDownloadInboundPlanReportPDF = () => {
    const url = '/lcbm-logistics-api/download/downloadInboundPlanReportPDF'
    const payloadProject = useCallback((obj: { inboundNos: string[] }) => obj.inboundNos, [])
    return usePrint<{ inboundNos: string[] }>(url, payloadProject, { mimeType: 'application/pdf' })
}

export const usePrintDownloadPDF = () => {
    const url = '/roe-api/downloadPDF'
    const payloadProject = useCallback((param: { reportId: string, labelId: string, param: string }) => param, [])
    return usePrint<{ reportId: string, labelId: string, param: string }>(url, payloadProject, { mimeType: 'application/pdf' })
}


export const useDownloadCustomsInvoicePDF = () => {
    const url = '/lcbm-customs-api/api/downloadCustomsInvoicePDF'
    const payloadProject = useCallback((param: { invoiceNos: string[], printInvoiceFlag: number, printPackingListFlag: number }) => param, [])
    return usePrint<{ invoiceNos: string[], printInvoiceFlag: number, printPackingListFlag: number }>(url, payloadProject, { mimeType: 'application/pdf' })
}

export const useDownloadInvoicePDF = () => {
    const url = '/lcbm-accounting-api/api/downloadInvoicePDF'
    const payloadProject = useCallback((param: { invoiceNos: string[], printInvoiceFlag: number, printPackingListFlag: number, printPayableFlag: number, printReceiveableFlag: number }) => param, [])
    return usePrint<{ invoiceNos: string[], printInvoiceFlag: number, printPackingListFlag: number, printPayableFlag: number, printReceiveableFlag: number }>(url, payloadProject, { mimeType: 'application/pdf' })
}

export const useDownloadOutboundPDF = () => {
    const url = '/lcbm-accounting-api/api/downloadOutboundPDF'
    const payloadProject = useCallback((param: { outboundNos: string[] }) => param.outboundNos, [])
    return usePrint<{ outboundNos: string[] }>(url, payloadProject, { mimeType: 'application/pdf' })
}

export const useDownloadDeliveryNotePDF = () => {
    const url = '/lcbm-logistics-api/download/downloadDeliveryNotePDF'
    const payloadProject = useCallback((param: { outboundNos: string[], type: number }) => param, [])
    return usePrint<{ outboundNos: string[], type: number }>(url, payloadProject, { mimeType: 'application/pdf' })
}


// export const useDownloadCustomsinvoicePDF = () => {
//     const url = '/lcbm-accounting-api/api/downloadCustomsInvoicePDF'
//     const payloadProject = useCallback((param: { invoiceNos: string[], fobFlag: number, printInvoiceFlag: number, printPackingListFlag: number }) => param, [])
//     return usePrint<{ invoiceNos: string[], fobFlag: number, printInvoiceFlag: number, printPackingListFlag: number }>(url, payloadProject, { mimeType: 'application/pdf' })
// }

export const useDownloadArPDF = () => {
    const url = '/lcbm-arap-api/api/downloadArPDF'
    const payloadProject = useCallback((param: { arIds: number[] }) => param.arIds, [])
    return usePrint<{ arIds: number[] }>(url, payloadProject, { mimeType: 'application/pdf' })
}

export const useDownloadApPDF = () => {
    const url = '/lcbm-arap-api/api/downloadApPDF'
    const payloadProject = useCallback((param: { apIds: number[] }) => param.apIds, [])
    return usePrint<{ apIds: number[] }>(url, payloadProject, { mimeType: 'application/pdf' })
}

// ====LOS033
export const useGetOutbondPartsList = () => {
    const url = '/lcbm-logistics-api/api/getOutboundPartsList'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(outboundPartsResultFromJson), [])
    return usePost<undefined, OutboundPartsResult[]>(url, payloadProject, resultProject)
}

export const useGetPackageList = () => {
    const url = '/lcbm-logistics-api/api/getPackageList'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback((packageList: { first: string, second: string }[]) => ({ packageList: packageList }), [])
    return usePost<undefined, { packageList: { first: string, second: string }[] }>(url, payloadProject, resultProject)
}

export const useGetRollPackageList = () => {
    const url = '/lcbm-logistics-api/api/getRollPackageList'
    const payloadProject = useCallback((param: { soDetailOrgins: string[] }) => param.soDetailOrgins, [])
    const resultProject = useCallback(Projects.array(rollPackageInfoFromJson), [])
    return usePost<{ soDetailOrgins: string[] }, RollPackageInfo[]>(url, payloadProject, resultProject)
}


export const useGetPackagePartsList = () => {
    const url = '/lcbm-logistics-api/api/getPackagePartsList'
    const payloadProject = useCallback((obj: { packageNo: string }) => obj.packageNo, [])
    const resultProject = useCallback(outerPackageInfoFromJson, [])
    return usePost<{ packageNo: string }, OuterPackageInfo>(url, payloadProject, resultProject)
}

export const useGetInnerPartsList = () => {
    const url = '/lcbm-logistics-api/api/getInnerPartsList'
    const payloadProject = useCallback((obj: { buyer?: string }) => obj, [])
    const resultProject = useCallback(Projects.array(innerPartsResultFromJson), [])
    return usePost<{ buyer?: string }, InnerPartsResult[]>(url, payloadProject, resultProject)
}

export const userGetInventoryRollParts = () => {
    const url = '/lcbm-logistics-api/api/findInventoryRolledParts'
    const payloadProject = useCallback((obj: { buyer?: string }) => obj, [])
    const resultProject = useCallback(Projects.array(innerPartsResultFromJson), [])
    return usePost<{ buyer?: string }, InnerPartsResult[]>(url, payloadProject, resultProject)
} 

export const userGetInventoryRollPackages = () => {
    const url = '/lcbm-logistics-api/api/getInventoryRolledPackages'
    const payloadProject = useCallback((param: { sellerPartNos: string[] }) => param.sellerPartNos, [])
    const resultProject = useCallback(Projects.array(pendingOutboundRollPackageFromJson), [])
    return usePost<{ sellerPartNos: string[] }, PendingOutboundRollPackage[]>(url, payloadProject, resultProject)
}

export const useSaveAndSubmitOutbound = () => {
    // const url = '/lcbm-logistics-api/api/createOutbound'
    const url = '/lcbm-logistics-api/api/outbound/saveAndSubmit'
    const payloadProject = useCallback(outboundCreateFactorToJson, [])
    const resultProject = useCallback((rs: IntlString[]) => rs, [])
    return usePost<OutboundCreateFactor, IntlString[]>(url, payloadProject, resultProject)
}

export const useSaveAndSubmitSimpleOutbound = () => {
    const url = '/lcbm-logistics-api/api/simple-outbound/saveAndSubmit'
    const payloadProject = useCallback(outboundCreateFactorToJson, [])
    const resultProject = useCallback((rs: IntlString[]) => rs, [])
    return usePost<OutboundCreateFactor, IntlString[]>(url, payloadProject, resultProject)
}

export const useSaveOutbound = () => {
    const url = '/lcbm-logistics-api/api/outbound/save'
    const payloadProject = useCallback(outboundCreateFactorToJson, [])
    const resultProject = useCallback((rs: string) => rs, [])
    return usePost<OutboundCreateFactor, string>(url, payloadProject, resultProject)
}

export const useSaveSimpleOutbound = () => {
    const url = '/lcbm-logistics-api/api/simple-outbound/save'
    const payloadProject = useCallback(outboundCreateFactorToJson, [])
    const resultProject = useCallback((rs: string) => rs, [])
    return usePost<OutboundCreateFactor, string>(url, payloadProject, resultProject)
}

export const useWithdrawOutbound = () => {
    const url = '/lcbm-logistics-api/api/outbound/withdraw'
    const payloadProject = useCallback((obj: { outboundNo: string }) => obj, [])
    const resultProject = Projects.IGNORE
    return usePost<{ outboundNo: string }, undefined>(url, payloadProject, resultProject)
}

export const useSubmitOutbound = () => {
    const url = '/lcbm-logistics-api/api/outbound/submit'
    const payloadProject = useCallback((obj: { outboundNo: string }) => obj, [])
    const resultProject = useCallback((rs: IntlString[]) => rs, [])
    return usePost<{ outboundNo: string, isConfirm?: boolean }, IntlString[]>(url, payloadProject, resultProject)
}

export const useGetOutboundInfoForEdit = () => {
    const url = '/lcbm-logistics-api/api/getOutboundInfoForEdit'
    const payloadProject = useCallback((obj: { outboundNo: string }) => obj.outboundNo, [])
    const resultProject = useCallback(outboundCreateFactorFromJson, [])
    return usePost<{ outboundNo: string }, OutboundCreateFactor>(url, payloadProject, resultProject)
}

export const useGetOutboundToWIPPage = () => {
    const url = '/lcbm-logistics-api/api/outboundtowip/list';
    const payloadProject = useCallback(outboundToWIPFactorToJson, []);
    const resultProject = useCallback(pageResultEntityFromJson(outboundToWIPEntityListFromJson), []);
    return usePost<OutboundToWIPFactor, PageResultEntity<OutboundToWIPEntityList>>(url, payloadProject, resultProject);

}

