import { Project } from "../../../utils/Project"

export type RejectRequestFactor = Partial<Optional>
export type RejectRequestFactorJson = Partial<JsonOptional>
export const rejectRequestFactorToJson: Project<RejectRequestFactor, RejectRequestFactorJson> = obj => ({
    ...obj,
})

interface Optional extends JsonOptional {
}

interface JsonOptional {
    requestIdList: number[],
    requestDetailIdList: number[],
    requestId: number,
    rejectReason: string,
}
