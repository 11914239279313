import React, { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { useGetPaymentTermsDetail } from "../../../services/master/apis/paymentTermsApi"
import { TnmPaymentTerms } from "../../../services/master/models/TnmPaymentTerms"
import { useMatch } from "../../../utils/useMatch"
import { MLS111PcUi } from "./MLS111PcUi"

export const MLS111 = () => {
    const { paymentTermsId } = useParams() as any
    const { path } = useMatch()
    let mode = ScreenMode.VIEW
    if (path === '/payment/edit-:paymentTermsId') {
        mode = ScreenMode.EDIT
    } else if (path === '/payment/create') {
        mode = ScreenMode.CREATE
    } else if (path === '/receivedRequest/addNewPart-:requestNo/paymentCreate') {
        mode = ScreenMode.CREATE
    }
    const [data, setData] = useState<TnmPaymentTerms>({} as TnmPaymentTerms)
    const getPaymentTermsDetail = useGetPaymentTermsDetail()
    const search = useCallback((paymentTermsId: number) => {
        getPaymentTermsDetail({ paymentTermsId: paymentTermsId }).then(result => {
            setData(result)
        })
    }, [getPaymentTermsDetail])
    useEffect(() => {
        if (mode !== ScreenMode.CREATE) {
            search(paymentTermsId)
        }
    }, [mode, search, paymentTermsId])
    return <MLS111PcUi
        mode={mode}
        path={path}
        data={data}
        setData={setData}
        search={search}
    />
}