import {EntryItem, EntryItemProps} from "@rithe/form"
import {Arrays, Comparators, Objects} from "@rithe/utils"
import React, {useMemo} from "react"
import {useIntl} from "react-intl"
import {ExternalOrderCategoryList} from "../../../services/integration/models/ExternalOrderCategoryList"
import {CodeCategory} from "../../../services/master/enums/CodeCategory"
type CodeItemProp = Omit<EntryItemProps, 'entries'> & {
    code: CodeCategory,
    data: ExternalOrderCategoryList[]
}

export const INT040CodeItem = React.memo((props: CodeItemProp) => {
    const {code, data} = props
    const intl = useIntl()
    const entries = useMemo(() => {
        return Arrays.distinct(
            data.filter(cc => cc.codeCategory === code).map(cc => cc.codeValue)
        ).sort(Comparators.natualOrder())
            .map(option => [option, intl.formatMessage({id: `${code}_${option}`})] as [number, string])
    }, [code, data, intl])

    return <EntryItem {...Objects.delete(props, 'code')} entries={entries} />
})
INT040CodeItem.displayName = 'CodesItem'