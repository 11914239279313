import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"

export type OrderCalculationView = Required & Partial<Optional>

type OrderCalculationViewJson = JsonRequired & Partial<JsonOptional>

export const orderCalculationViewToJson: Project<OrderCalculationView, OrderCalculationViewJson> = obj => ({
    ...obj,
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page)
})

export const orderCalculationViewFromJson: Project<OrderCalculationViewJson, OrderCalculationView> = json => ({
    ...json,
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page)
})


interface Required extends JsonRequired {

}

interface Optional extends JsonOptional {
    page: PageViewEntity,
}

interface JsonRequired {

}

interface JsonOptional {
    orderGroupNo: string,
    customerCode: string,
    buyerUids: string[],
    sellerUids: string[],
    orderFrequency: number,
    orderTiming: String,
    calendarCode: string,
    targetMonth: number,
    orderGroupIdList: number[],
    page: PageViewEntityJson,
}
