import { useCallback, useEffect, useMemo, useState } from "react"
import { useComMasterGetAllCompany, useGetZoneList } from "../../../services/common/apis/commonMasterApi"
import { useGetBUList } from "../../../services/master/apis/businessUnitApi"
import { BusinessUnitFactor, businessUnitFactorFromJson, businessUnitFactorToJson } from "../../../services/master/models/BusinessUnitFactor"
import { BusinessUnitResult } from "../../../services/master/models/BusinessUnitResult"
import { TnmCompany } from "../../../services/master/models/TnmCompany"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { MLS020PcUi } from "./MLS020PcUi"


const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }

const searchCacheKey = sessionKeys.Filters_MLS020

export const MLS020 = () => {
    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCacheKey)
        return filters ? businessUnitFactorFromJson(JSON.parse(filters)) : null
    }, [])

    const [filters, setFilters] = useState<BusinessUnitFactor>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<BusinessUnitResult[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [companyList, setCompany] = useState<TnmCompany[]>([])
    const [timeZoneMap, setTimeZoneMap] = useState<Record<string, string>>({})

    const getBUList = useGetBUList()
    const search = useCallback((filters: BusinessUnitFactor) => {
        getBUList(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            sessionStorage.setItem(searchCacheKey, JSON.stringify(businessUnitFactorToJson(filters)))

        })
    }, [getBUList, setData])

    useSearchOnLoad(search, filters)

    // const getCompanyList = useComMasterGetAllCompanyByActive()
    const getCompanyList = useComMasterGetAllCompany()
    useEffect(() => {
        getCompanyList(undefined, { silent: true }).then(result => {
            setCompany(result || [])
        })
    }, [getCompanyList])


    const getZoneList = useGetZoneList()
    useEffect(() => {
        getZoneList(undefined, { silent: true }).then(result => {
            const map: Record<string, string> = {}
            result.forEach(({ first, second }) => map[first] = second)
            setTimeZoneMap(map)
        })
    }, [getZoneList])

    return <MLS020PcUi
        filters={filters}
        search={search}
        data={data}
        totalCount={totalCount}
        companyList={companyList}
        setFilters={setFilters}
        timeZoneMap={timeZoneMap}
    />
}

