import { useEffect, useMemo, useState } from "react"
import { useComMasterGetAllUom } from "../../../services/common/apis/commonMasterApi"
import { GlobalPartsResult, globalPartsResultFromJson } from "../../../services/master/models/GlobalPartsResult"
import { TnmUom } from "../../../services/master/models/TnmUom"
import sessionKeys from "../../../utils/sessionKeys"
import { useGetSessionAndGobackIfEmpty } from "../../../utils/sessionUtil"
import { useMatch } from "../../../utils/useMatch"
import { MLS071PcUi } from "./MLS071PcUi"

export interface GlobalPartsResultEx extends GlobalPartsResult {
    lineId: number,
}

export const MLS071 = () => {
    const { path } = useMatch()
    const getSession = useGetSessionAndGobackIfEmpty()
    const cachedPratsList = useMemo(() => {
        if (path === '/globalparts/create') {
            return [] as GlobalPartsResult[]
        } else {
            return getSession(sessionKeys.Data_MLS071, globalPartsResultFromJson, '/globalparts') as GlobalPartsResult[]
        }
    }, [getSession, path])
    const partsList = useMemo(() => cachedPratsList.map((record, index) => ({ lineId: index, ...record })), [cachedPratsList])
    const [entityList, setEntityList] = useState<GlobalPartsResultEx[]>(partsList)

    const [uoms, setUoms] = useState<TnmUom[]>([])
    const getUoms = useComMasterGetAllUom()
    useEffect(() => {
        getUoms(undefined, { silent: true, serialized: true }).then(result => setUoms(result || []))
    }, [getUoms])

    return <MLS071PcUi
        entityList={entityList}
        setEntityList={setEntityList}
        uoms={uoms}
    />
}