import React, { useCallback, useState } from "react"
import { useGetCreditRuleList } from "../../../services/master/apis/creditRuleApi"
import { CreditCheckInfoFactor } from "../../../services/master/models/CreditCheckInfoFactor"
import { CreditCheckInfoResult } from "../../../services/master/models/CreditCheckInfoResult"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { MLS180PcUi } from "./MLS180PcUi"




export const MLS180 = () => {

    const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }
    const [filters, setFilters] = useState<CreditCheckInfoFactor>(defaultFilters)
    const [creditList, setCredutList] = useState<CreditCheckInfoResult[]>([])
    const getData = useGetCreditRuleList()

    const search = useCallback((filters: CreditCheckInfoFactor) => {

        getData({ ...filters }, { serialized: true, silent: true }).then(result => {
            setCredutList(result?.data || [])
        })
    }, [getData])

    useSearchOnLoad(search, filters)


    return <MLS180PcUi
        filters={filters}
        setFilters={setFilters}
        creditList={creditList}
        search={search}
    />
}