import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { dateFromJson } from "../../utils/deserializer"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"


export type CustDeliveryView = Required & Partial<Optional>

type CustDeliveryViewJson = JsonRequired & Partial<JsonOptional>

export const custDeliveryViewToJson: Project<CustDeliveryView, CustDeliveryViewJson> = obj => ({
    ...obj,
    diDateStart: dateToJson(obj.diDateStart),
    diDateEnd: dateToJson(obj.diDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page)
})

export const custDeliveryViewFromJson: Project<CustDeliveryViewJson, CustDeliveryView> = json => ({
    ...json,
    diDateStart: dateFromJson(json.diDateStart),
    diDateEnd: dateFromJson(json.diDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page)
})


interface Required extends JsonRequired { }

interface Optional extends Omit<JsonOptional, 'diDateStart' | 'diDateEnd'> {
    diDateStart: Date,
    diDateEnd: Date,
    page: PageViewEntity,
}

interface JsonRequired {
}

interface JsonOptional {
    customerIdList: number[],
    buIdList: number[],
    diRefNo: string,
    diNo: string,
    dcIdList: number[],
    diTypeList: number[],
    diDateStart: string,
    diDateEnd: string,
    statusList: number[],
    diIdList: string[],
    remark: string,
    customerId: number,
    page: PageViewEntityJson,
}