import { Arrays } from "@rithe/utils"
import React, { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useComMasterGetActiveCbds } from "../../../services/common/apis/commonMasterApi"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { useGetSoMonitroingDetail } from "../../../services/order/apis/SalesOrderApi"
import { SimulatedType } from "../../../services/order/enums/SimulatedType"
import { SoSpotDetailStatus } from "../../../services/order/enums/SoSpotDetailStatus"
import { EstimatedData, InboundPalnData, OrderDetailHead, OrderPartsData, OrderPartsDetail } from "../../../services/order/models/OrderDetailResult"
import { datetimeFromJson } from "../../../services/utils/deserializer"
import { OSS021PcUi } from "./OSS021PcUi"



export const OSS021 = () => {

    const { soSpotId } = useParams() as any
    const [headerInfo, setHeaderInfo] = useState<OrderDetailHead>({} as OrderDetailHead)
    const [partsDetailList, setPartsDetailList] = useState<OrderPartsData[]>([])

    const [receiveDcList, setReceiveDcList] = useState<TnvCbds[]>([])

    const creatInboundPlanList = useCallback((partsDetailList: OrderPartsDetail[]) => {
        let list: OrderPartsDetail[] = partsDetailList.filter(f => f.spotDetailStatus !== SoSpotDetailStatus.REJECTED)
        let newPartsDetailList: OrderPartsData[] = []
        let allIPlanDateList: number[] = []
        let allEstimatedDataList: string[] = []

        list.filter((f) => f.inboundPlanList).map(m => m.inboundPlanList.map(m2 => m2.crdDate.getTime())).map(m3 => m3.forEach(o => { allIPlanDateList.push(o) }))
        list.filter((f) => f.estimatedInboundList).map(m => m.estimatedInboundList.map(m2 => m2).sort((a, b) => {
            if (a.estimatedCrdDate.getTime() === b.estimatedCrdDate.getTime()) {
                return a.fromBuId - b.fromBuId
            } else {
                if (a.estimatedCrdDate.getTime() > b.estimatedCrdDate.getTime()) {
                    return 1
                } else {
                    return -1
                }
            }
        }).map(m3 => [m3.estimatedCrdDate.getTime(), m3.fromCompany, SimulatedType.ON_STOCK !== m3.simulatedType ? m3.toCompany : ''].join(','))).map(m4 => m4.forEach(o => { allEstimatedDataList.push(o) }))

        allIPlanDateList = Arrays.distinct(allIPlanDateList).sort((a, b) => { return a - b })
        allEstimatedDataList = Arrays.distinct(allEstimatedDataList).sort()

        list.forEach((opd) => {
            let newinboundPlanList: InboundPalnData[] = []
            let inboundQtyTotal: number = (opd?.inTransitQty ?? 0) + (opd?.receiverInbQty ?? 0)
            const acutalOrderQty = (opd.orderQty ?? 0) - (opd.forceCompletedQty ?? 0)
            let planQtyTotal: number = 0
            let i: number = 0
            // So Detail Page: Shortage Qty = Order Qty - Received Qty - Total Estimated qty(including Intransit Qty)
            // let shortageQty: number = opd?.orderQty | 0 - opd?.receiverInbQty | 0 - opd?.inTransitQty | 0
            allIPlanDateList.forEach((pDate) => {
                if (opd?.estimatedInboundList && opd.estimatedInboundList.length > 0) {
                    for (i; i < opd.estimatedInboundList.length;) {
                        if (pDate >= opd.estimatedInboundList[i].estimatedCrdDate.getTime()) {
                            inboundQtyTotal = inboundQtyTotal + (opd.estimatedInboundList[i]?.qty ?? 0)
                            // shortageQty = shortageQty - opd.estimatedInboundList[i]?.qty | 0
                            i++
                        } else {
                            break
                        }
                    }
                }
                let flag: boolean = true
                if (opd.inboundPlanList.length > 0) {
                    let planData: InboundPalnData | undefined = opd.inboundPlanList.find(o => o.crdDate.getTime() === pDate)
                    if (planData) {
                        flag = false
                        planQtyTotal = planQtyTotal + planData.crdQty
                        const actualQty = (planQtyTotal > acutalOrderQty ? acutalOrderQty : planQtyTotal)
                        planData.status = actualQty > inboundQtyTotal ? 'NG-' + (planQtyTotal - inboundQtyTotal) : 'OK'
                        newinboundPlanList.push(planData)
                    }
                }
                if (flag) {
                    newinboundPlanList.push({ crdDate: datetimeFromJson(pDate), status: 'OK', crdQty: 0 } as InboundPalnData)
                }
            })

            let newEstimatedInboundList: EstimatedData[] = []
            allEstimatedDataList.forEach((eData) => {
                let flag: boolean = true
                if (opd.estimatedInboundList.length > 0) {
                    let estimatedData: EstimatedData | undefined = opd.estimatedInboundList.find(f => [f.estimatedCrdDate.getTime(), f.fromCompany, SimulatedType.ON_STOCK !== f.simulatedType ? f.toCompany : ''].join(',') === eData)
                    if (estimatedData) {
                        flag = false
                        if (SimulatedType.ON_STOCK !== estimatedData.simulatedType) {
                            estimatedData.companyTitle = estimatedData.fromCompany + ' Ship To ' + estimatedData.toCompany
                        } else {
                            estimatedData.companyTitle = estimatedData.fromCompany
                        }
                        newEstimatedInboundList.push(estimatedData)
                    }
                }
                if (flag) {
                    let tempDataList: string[] = eData.split(',')
                    let crdDate: number = Number(tempDataList[0])
                    let companyTitle: string = tempDataList[1]
                    if ('' !== tempDataList[2]) {
                        companyTitle = tempDataList[1] + ' Ship To ' + tempDataList[2]
                    }
                    newEstimatedInboundList.push({ estimatedCrdDate: datetimeFromJson(crdDate), companyTitle: companyTitle, qty: 0 } as EstimatedData)
                }
            })
            newPartsDetailList.push({
                ...opd,
                inboundPlanList: newinboundPlanList,
                estimatedInboundList: newEstimatedInboundList,
            })
        })
        return newPartsDetailList
    }, [])

    const getSoMonitoringDetail = useGetSoMonitroingDetail()
    const search = useCallback(() => {
        getSoMonitoringDetail({ soSpotId: soSpotId }, { silent: true, serialized: true }).then(result => {
            setHeaderInfo(result?.headerInfo || {} as OrderDetailHead)
            if (result?.partsDetailList && result.partsDetailList.length > 0) {
                setPartsDetailList(creatInboundPlanList(result.partsDetailList))
            }
        })
    }, [creatInboundPlanList, getSoMonitoringDetail, soSpotId])


    const getCbds = useComMasterGetActiveCbds()
    useEffect(() => {
        if (soSpotId !== null && soSpotId !== undefined) {
            search()
        }
        getCbds({ types: [] }, { silent: true }).then(result => {
            setReceiveDcList(result || [])
        })
    }, [getCbds, search, soSpotId])


    return <OSS021PcUi
        headerInfo={headerInfo}
        partsDetailList={partsDetailList}
        receiveDcList={receiveDcList}
        search={search}
    />
}