import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { MapaCCHistoryFactor, mapaCCHistoryFactorToJson } from "../models/MapaCCHistoryFactor"
import { MapaCCHistoryResult, mapaCCHistoryResultFromJson } from "../models/MapaCCHistoryResult"

export const useFindMapaCCHistoryList = () => {
    const url = '/lcbm-order-api/order/bocc/api/findBuChangeHistoryList'
    const payloadProject = useCallback(mapaCCHistoryFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(mapaCCHistoryResultFromJson), [])
    return usePost<MapaCCHistoryFactor, PageResultEntity<MapaCCHistoryResult>>(url, payloadProject, resultProject)
}