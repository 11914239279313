import { makeStyles } from '@material-ui/core'
import { ReactNode } from 'react'
import { ViewActions } from './ViewActions'
import { ViewBreadcrumbs } from './ViewBreadcrumbs'

interface ViewHeaderProps {
    actions?: ReactNode | ReactNode[],
}

export function ViewHeader(props: ViewHeaderProps) {
    const { actions } = props
    const classes = useStyles()
    return <div className={classes.root}>
        <ViewBreadcrumbs />
        <ViewActions actions={actions} />
    </div>
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: 72,
        flex: '0 0 auto',
        boxSizing: 'border-box',
        padding: `${theme.spacing(0.75)}px ${theme.spacing(12)}px`,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    }
}))