import { DialogActions, DialogContent, Typography } from "@material-ui/core"
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons"
import { ColumnFreeze, ColumnOrdering, ColumnResizing, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { Break, DateItem, EntryItem, Form, Message } from "@rithe/form"
import { GridItem } from "@rithe/ui"
import { Arrays, Records } from "@rithe/utils"
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CardDialog } from "../../../components/Dialog/CardDialog"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { CodeItem } from "../../../components/Form/CodeItem"
import { useComMasterGetActiveCbds } from "../../../services/common/apis/commonMasterApi"
import { useDownloadExternalOutboundTemplate, useDownloadOutTemplate } from "../../../services/delivery/apis/deliveryDownloadApi"
import { useGetBuyerList, useGetInnerPartsList } from "../../../services/delivery/apis/deliveryOutboundApi"
import { OutboundDatasource } from "../../../services/delivery/enums/OutboundDatasource"
import { UploadOutboundType } from "../../../services/delivery/enums/UploadOutboundType"
import { DownloadOutBoundPartsInfo } from "../../../services/delivery/models/DownloadOutBoundPartsInfo"
import { InnerPartsResult } from "../../../services/delivery/models/InnerPartsResult"
import { OutboundCreateFactor } from "../../../services/delivery/models/OutboundCreateFactor"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { ShippingMode } from "../../../services/master/enums/ShippingMode"
import { CbdsType } from "../../../services/systemmaster/enums/CbdsType"
import { Pair } from "../../../services/utils/Pair"
import { today, useGetCompanyCode } from "../../../utils/ApplicationUtils"
import { useFieldChecker, useFormValidater } from "../../../utils/ValidatorUtils"
import { applicationActions } from "../../Application/applicationSlice"

interface DownloadOutboundFormProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    isInternal: boolean,
}
enum Step {
    INPUT,
    SELECT
}

export const DownloadOutboundForm = ({ open, setOpen, isInternal }: DownloadOutboundFormProps) => {

    const dispatch = useDispatch()
    const [buyerList, setBuyerList] = useState<Pair[]>([])
    const [receiverList, setReceiverList] = useState<Pair[]>([])
    const [data, setData] = useState<OutboundCreateFactor>(defaultOutboundInfo)
    const [step, setStep] = useState<Step>(Step.INPUT)
    const [partsList, setPartsList] = useState<InnerPartsResult[]>([])
    const [selections, setSelections] = useState<number[]>([])
    const [messages, setMessages] = useState<Message[]>([])
    const shipper = useGetCompanyCode()
    const formValidate = useFormValidater(setMessages, {
        buyer: { labelId: 'buyer', required: true },
        receiver: { labelId: 'receiverCode', required: true }
    })

    // get buyer list
    const getBuyerList = useGetBuyerList()
    useEffect(() => {
        getBuyerList(undefined, { silent: true, serialized: true }).then((result) => {
            setBuyerList(result?.buyerList || [])
        })
    }, [getBuyerList, setBuyerList])

    // get recevier list
    const getReceiverList = useComMasterGetActiveCbds()
    useEffect(() => {
        getReceiverList({ types: [CbdsType.DC, CbdsType.CUST] }, { silent: true, serialized: true }).then((result) => {
            setReceiverList(result?.map(m => ({ first: m.cbdsUid, second: m.cbdsCode })) || [])
        })
    }, [getReceiverList, setReceiverList])

    // do close
    const handleClose = useCallback(() => {
        setOpen(false)
        setData(defaultOutboundInfo)
        setStep(Step.INPUT)
        setSelections([])
    }, [setOpen, setData, setStep])

    // hander go to selected parts
    const getPartsList = useGetInnerPartsList()
    const goToSelectParts = useCallback(() => {
        // do check 
        if (formValidate(data)) {
            // pick up buyercode
            const buyerInfo = buyerList.find(f => f.first === data.buyer);
            // get parts from database
            setStep(Step.SELECT)
            getPartsList({ buyer: buyerInfo ? buyerInfo.second + '' : undefined }, { silent: true, serialized: true }).then((result) => {
                setPartsList(result || [])
            })
        }
    }, [buyerList, data, formValidate, getPartsList])

    // hander back to input 
    const backToInput = useCallback(() => setStep(Step.INPUT), [setStep])

    // hander download
    const downloadApi = useDownloadOutTemplate()
    const downloadExternalApi = useDownloadExternalOutboundTemplate()
    const download = useCallback(() => {
        // selections
        if (selections.length > 0) {
            // get data from selections
            const selectedParts = partsList.filter((_, idx) => selections.some(s => s === idx))
            // do download
            downloadApi(prepareDownload(data, selectedParts, buyerList, receiverList, shipper ?? ''))
            handleClose()
        } else {
            dispatch(applicationActions.pushError({ title: { code: 'download' }, messages: { code: 'w0002' } }))
        }
    }, [buyerList, data, dispatch, downloadApi, handleClose, partsList, receiverList, selections, shipper])
    const downloadExternal = useCallback(() => {
        // selections
        if (selections.length > 0) {
            // get data from selections
            const selectedParts = partsList.filter((_, idx) => selections.some(s => s === idx))
            // do download
            downloadExternalApi(prepareDownload(data, selectedParts, buyerList, receiverList, shipper ?? ''))
            handleClose()
        } else {
            dispatch(applicationActions.pushError({ title: { code: 'download' }, messages: { code: 'w0002' } }))
        }
    }, [buyerList, data, dispatch, downloadExternalApi, handleClose, partsList, receiverList, selections, shipper])

    // step
    return <CardDialog keepMounted={false} maxWidth={"lg"} fullWidth open={open} onClose={handleClose} >
        <DialogHeader onClose={handleClose}><FormattedMessage id="DownloadOutboundForm" /></DialogHeader>
        <DialogContent style={{ height: 600 }}>
            {step === Step.INPUT
                && <Step1InputFilters buyerList={buyerList} receiverList={receiverList} data={data} setData={setData} messages={messages} setMessages={setMessages} />}
            {step === Step.SELECT
                && <Step2SelectPartsList partsList={partsList} selections={selections} setSelections={setSelections} />}
        </DialogContent>
        <DialogActions>
            {step === Step.INPUT
                && <DialogAction outlined title={<><FormattedMessage id="goToSelectParts" />&nbsp;<ArrowForwardIos fontSize="inherit" /></>} callback={goToSelectParts} />}
            {step === Step.SELECT
                && <DialogAction outlined title={<><ArrowBackIos fontSize="inherit" /><FormattedMessage id="backToInput" /></>} callback={backToInput} />}
            {step === Step.SELECT && isInternal
                && <DialogAction title={<FormattedMessage id="download" />} callback={download} access="LOGI.LOS030.DOWNLOAD"/>}
            {step === Step.SELECT && !isInternal
                && <DialogAction title={<FormattedMessage id="download" />} callback={downloadExternal} access="LOGI.LOS030.EXTERNALDOWNLOAD"/>}
        </DialogActions>
    </CardDialog >
}

const Step1InputFilters = ({ buyerList, receiverList, data, setData, messages, setMessages }: {
    buyerList: Pair[],
    receiverList: Pair[],
    data: OutboundCreateFactor,
    setData: Dispatch<SetStateAction<OutboundCreateFactor>>,
    messages: Message[],
    setMessages: Dispatch<SetStateAction<Message[]>>,
}) => {

    const intl = useIntl()
    const receivers = useMemo(() => receiverList.map(m => [m.first, m.second] as [string, string]), [receiverList])
    const buyers = useMemo(() => Arrays.distinct(buyerList.map(m => [m.first, m.second] as [string, string])), [buyerList])
    const chekFields = useMemo(() => ({
        buyer: { labelId: 'buyer', required: true },
        receiver: { labelId: 'receiverCode', required: true }
    }), [])
    const filedCheck = useFieldChecker(chekFields, setMessages)

    return <Form data={data} setData={setData} labelDisplay="block" helperDisplay="tooltip" columnCount={3} messages={messages} setMessages={setMessages} ensure={filedCheck}>
        <GridItem columnSpan={3}><FormattedMessage id="step1ForDownloadOutboundForm" /></GridItem>
        <EntryItem field="buyer" required label={intl.formatMessage({ id: 'buyer' })} entries={buyers} />
        <EntryItem field="receiver" required label={intl.formatMessage({ id: 'receiverCode' })} entries={receivers} />
        <Break />
        <CodeItem field="outboundType" label={intl.formatMessage({ id: 'field.outboundType' })} code={CodeCategory.UploadOutboundType} />
        <DateItem field="outboundDate" label={intl.formatMessage({ id: 'field.outboundDate' })}></DateItem>
        <CodeItem field="shippingMode" label={intl.formatMessage({ id: 'field.shippingMode' })} code={CodeCategory.ShippingMode} />
    </Form>
}

const Step2SelectPartsList = ({ partsList, selections, setSelections }: {
    partsList: InnerPartsResult[],
    selections: number[],
    setSelections: Dispatch<SetStateAction<number[]>>
}) => {

    const intl = useIntl()
    const columns = useMemo(() => [
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 180 },
        { field: 'sellerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sellerPartsNo' }), width: 180 },
        { field: 'sellerPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sellerPartsName' }), width: 260 },
        { field: 'soNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'salesOrderNo' }), width: 220 },
        { field: 'notYetDeliveryQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'notYetDeliveryQty' }), width: 180 },
    ], [intl])

    return <>
        <Typography variant="body2"><FormattedMessage id="step2ForDownloadOutboundForm" /> </Typography>
        <div style={{ height: '91%' }}>
            <DataGrid>
                <ToolbarLayout />
                <TableLayout Container={FlexScrollbar}>
                    <TableHeaderLayout sticky />
                    <TableBodyLayout />
                </TableLayout>
                <PaginationLayout Pagination={Pagination} />
                <DataTypePreset />
                <Data rows={partsList} columns={columns} />
                <ColumnFreeze />
                <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
                <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
                <Searching ignoreCase Input={SearchInput} />
                <Sorting />
                <Filtering />
                <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
                <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
            </DataGrid>
        </div >
    </>
}

export const defaultOutboundInfo = {
    buyer: '',
    receiver: '',
    outboundRefNo: '',
    outboundDate: today(),
    outboundType: UploadOutboundType.OUTBOUND,
    shippingMode: ShippingMode.SEA,
    datasource: OutboundDatasource.INTERNAL,
}

const prepareDownload = (data: OutboundCreateFactor, parts: InnerPartsResult[], buyerList: Pair[], receiverList: Pair[], shipper: string) => {
    // prepare
    const receiver = receiverList.find(f => f.first === data.receiver)?.second as string
    const buyer = buyerList.find(f => f.first === data.buyer)?.second as string
    // prepare
    return parts.map(part => ({
        shipper: shipper,
        outboundNo: data.outboundRefNo,
        outboundDate: data.outboundDate,
        outboundTime: data.outboundTime,
        bookingNo: data.bookingNo,
        partsNo: part.partsNo,
        uomCode: part.uomCode,
        uomDigits: part.uomDigits,
        outboundQty: undefined,
        shippingMode: data.shippingMode,
        outboundType: data.outboundType,
        receiver: receiver,
        buyer: buyer,
        etd: data.etd,
        eta: data.eta,
        containerTruckNo: undefined,
        containerTruckType: undefined,
        commodityType: undefined,
        sealNo: undefined,
        containerM3: undefined,
        containerNetWeight: undefined,
        containerGrossWeight: undefined,
        palletNo: undefined,
        outerPackageNo: undefined,
        outerPackageType: undefined,
        outerM3: undefined,
        outerNetWeight: undefined,
        outerGrossWeight: undefined,
        innerPackageNo: undefined,
        innerPackageType: undefined,
        innerM3: undefined,
        innerNetWeight: undefined,
        innerGrossWeight: undefined,
        soNo: part.soNo,
        sellerCode: part.sellerCode,
        sellerPartsNo: part.sellerPartsNo,
        sellerPartsName: part.sellerPartsName,
        sellerBackNo: part.sellerBackNo,
        colorCode: part.colorCode,
        srbq: part.srbq,
        remainingQty: part.notYetDeliveryQty,
    }) as DownloadOutBoundPartsInfo)
}