import { IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { DataGridCategoryProps } from "@rithe/data-grid/dist/components/basic/DataGridCategory";

export const useShippingPlanCategory = (click: (filed: string) => void, showButton: boolean, title?: string) => {
    const styles = useStyles()
    return (props: DataGridCategoryProps) => {
        const { category, tableColumns } = props
        return <Tooltip title={"Edit"} >
            <Typography variant="body2" className={styles.root} onClick={() => {
                if (title === undefined || title !== category) { click(tableColumns[0].field) }
            }}>
                {category} {showButton && (title === undefined || title !== category) && <IconButton style={{ zoom: 0.8 }}><Edit fontSize={"small"} /></IconButton>}
            </Typography >
        </Tooltip>
    }
}

const useStyles = makeStyles(({
    root: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }
}))