import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"

export type TnmCurrency = Required & Partial<Optional> & TableEntity
type TnmCurrencyJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const tnmCurrencyFromJson: Project<TnmCurrencyJson, TnmCurrency> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
}

interface JsonRequired {
    currencyCode: string,
}

interface JsonOptional {
    description: string,
    decimalDigits: number,
}