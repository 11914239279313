import { Project } from "@rithe/utils";
import { dateFromJson } from "../../utils/deserializer";
import { dateToJson } from "../../utils/serializer";

export type OrderCalculationReviewResult = Required & Partial<Optional>;
export type OrderCalculationReviewResultJson = JsonRequired & Partial<JsonOptional>;

export const orderCalculationReviewResultToJson: Project<OrderCalculationReviewResult, OrderCalculationReviewResultJson> = obj => ({
    ...obj,
    usageDate: dateToJson(obj.usageDate)
})

export const orderCalculationReviewResultFromJson: Project<OrderCalculationReviewResultJson, OrderCalculationReviewResult> = json => ({
    ...json,
    usageDate: dateFromJson(json.usageDate)
})

interface Required extends JsonRequired {

}

interface Optional extends Omit<JsonOptional, 'usageDate'> {
    usageDate: Date
}

interface JsonRequired {
    orderCalcPartsId: string,
    customerPartsId: string,
    orderCalcId: string,
    orderCalcNo: string,
    usageVersion: string,
    partsNo: string,
}

interface JsonOptional {
    usageDate: string,
    customerPartsNo: string,
    partsDescription: string,
    contractNo: string,
    orderRefNo: string,
    sellerCode: string,
    orderType: number,
    orderLot: number,
    expCountry: string,
    supplierCode: string,
    supplierPartsNo: string,
    sellerUomCode: string,
    sellerUomDigits: number,

    uomCode: string,
    uomDigits: number,

    currentUsageQty: number,
    currentSuggestQty: number,
    previoudSuggestQty: number,
    currentFirmQty: number,
    suggestAddtionalQty: number
}