import { Project } from "../../../utils/Project"
import { ShippingMode } from "../../accounting/enums/ShippingMode"
import { dateFromJson } from "../../utils/deserializer"
import { InboundDataType } from "../enums/InboundDataType"
import { InboundPackageStatus } from "../enums/InboundPackageStatus"
import { InboundType } from "../enums/InboundType"

export type InboundMonitorTransfer = Required & Partial<Optional>

type InboundMonitorTransferJson = JsonRequired & Partial<JsonOptional>

export const inboundMonitorTransferFromJson: Project<InboundMonitorTransferJson, InboundMonitorTransfer> = json => ({
    ...json,
    etd: dateFromJson(json.etd),
    eta: dateFromJson(json.eta),
    outboundDate: dateFromJson(json.outboundDate),
    orgPlanInboundDate: dateFromJson(json.orgPlanInboundDate),
    latestPlanInboundDate: dateFromJson(json.latestPlanInboundDate),
})

interface Required extends JsonRequired { }

interface Optional extends Omit<JsonOptional, 'latestPlanInboundDate' | 'orgPlanInboundDate' | 'etd' | 'eta' | 'outboundDate'> {
    status: InboundPackageStatus,
    inboundType: InboundType,
    shippingMode: ShippingMode,
    orgPlanInboundDate: Date,
    latestPlanInboundDate: Date,
    etd: Date,
    eta: Date,
    outboundDate: Date,
    dataType: InboundDataType
}

interface JsonRequired {
    planInboundId: number,
    planInboundNo: string,
}

interface JsonOptional {
    latestPlanInboundDate: string,
    orgPlanInboundDate: string,
    inboundType: number,
    supplierName: string,
    dcName: string,
    shipper: string,
    shippingMode: number,
    port: string,
    etd: string,
    eta: string,
    outboundNo: string,
    outboundDate: string,
    outboundTime: string,
    containerNo: string,
    inboundRefNo: string,
    status: number,
    dataType: number
}