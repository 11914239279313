import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { dateFromJson } from "../../utils/deserializer"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"
import { ActiveFlag } from "../enums/ActiveFlag"

export type PortDownloadFactor = Omit<PortFactor, 'page'>
type PortDownloadFactorJson = Omit<PortFactorJson, 'page'>
export const portDownloadFactorToJson: Project<PortDownloadFactor, PortDownloadFactorJson> = obj => ({
    ...obj,
    createdDateStart: dateToJson(obj.createdDateStart),
    createdDateEnd: dateToJson(obj.createdDateEnd),
    updatedDateStart: dateToJson(obj.updatedDateStart),
    updatedDateEnd: dateToJson(obj.updatedDateEnd),
})

export type PortFactor = Required & Partial<Optional>
type PortFactorJson = JsonRequired & Partial<JsonOptional>
export const portFactorToJson: Project<PortFactor, PortFactorJson> = obj => ({
    ...obj,
    createdDateStart: dateToJson(obj.createdDateStart),
    createdDateEnd: dateToJson(obj.createdDateEnd),
    updatedDateStart: dateToJson(obj.updatedDateStart),
    updatedDateEnd: dateToJson(obj.updatedDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
})

export const portFactorFromJson: Project<PortFactorJson, PortFactor> = json => ({
    ...json,
    createdDateStart: dateFromJson(json.createdDateStart),
    createdDateEnd: dateFromJson(json.createdDateEnd),
    updatedDateStart: dateFromJson(json.updatedDateStart),
    updatedDateEnd: dateFromJson(json.updatedDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page)
})

interface Required extends JsonRequired {

}

interface Optional extends Omit<JsonOptional, 'createdDateStart' | 'createdDateEnd' | 'updatedDateStart' | 'updatedDateEnd'> {
    activeList: ActiveFlag[],
    createdDateStart: Date,
    createdDateEnd: Date,
    updatedDateStart: Date,
    updatedDateEnd: Date,
    page: PageViewEntity
}

interface JsonRequired {

}

interface JsonOptional {
    portCode: string,
    portName: string,
    regionList: string[],
    activeList: number[],
    createdBy: string,
    updatedBy: string,
    createdDateStart: string,
    createdDateEnd: string,
    updatedDateStart: string,
    updatedDateEnd: string,
    page: PageViewEntityJson
}
