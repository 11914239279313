import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { dateToJson } from "../../utils/serializer"
import { OpenArFlag } from "../enums/OpenArFlag"
import { UnvoiceOrderFlag } from "../enums/UnvoiceOrderFlag"

export type CreditCheckInfoResult = Required & Partial<Optional>
type CreditCheckInfoResultJson = JsonRequired & Partial<JsonOptional>
export const creditCheckInfoResultFromJson: Project<CreditCheckInfoResultJson, CreditCheckInfoResult> = json => ({
    ...json,
    createdDate: dateFromJson(json.createdDate),
    updatedDate: dateFromJson(json.updatedDate)

})

export const creditCheckInfoResultToJson: Project<CreditCheckInfoResult, CreditCheckInfoResultJson> = obj => ({
    ...obj,
    createdDate: dateToJson(obj.createdDate),
    updatedDate: dateToJson(obj.updatedDate)

})


interface Required extends JsonRequired {

}

interface Optional extends Omit<JsonOptional, 'createdDate' | 'updatedDate'> {
    openArFlag: OpenArFlag,
    unvoicedOrderFlag: UnvoiceOrderFlag,
    createdDate: Date,
    updatedDate: Date,
}

interface JsonRequired {

}

interface JsonOptional {
    creditId: number,
    creditRuleNo: string,
    openArFlag: number,
    openArRange: number,
    unvoicedOrderFlag: number,
    unvoicedOrderTargetRange: number,
    buId: number,
    supplierId: number,
    createdDate: string,
    updatedBy: string,
    updatedDate: string,
    status: number,
    version: number
}