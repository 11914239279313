import { DialogActions, DialogContent, DialogContentText } from "@material-ui/core"
import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { DateRangeItem, EntryItem, Form, StringItem } from "@rithe/form"
import { Records } from "@rithe/utils"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DownloadCallbackRowAction } from "../../../components/DataGrid/rowActions/DownloadCallbackRowAction"
import { EditRedirectRowAction } from "../../../components/DataGrid/rowActions/EditRedirectRowAction"
import { PortcastFlagCallbackRowAction } from "../../../components/DataGrid/rowActions/PortcastFlagCallbackRowAction"
import { SubmitCallbackRowAction } from "../../../components/DataGrid/rowActions/SubmitCallbackRowAction"
import { ViewRedirectRowAction } from "../../../components/DataGrid/rowActions/ViewRedirectRowAction"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { PortcastFlagToolbarAction } from "../../../components/DataGrid/toolbarActions/PortcastFlagToolbarAction"
import { UploadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/UploadGroupedToolbarAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { DarkDialog } from "../../../components/Dialog/DarkDialog"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { useDownloadShippingDetail, useGetShippingDetail, useUpdateCargoStatus, useUploadShippingDetail, useViaportcast } from "../../../services/delivery/apis/deliveryShippingDetailApi"
import { ShippingDetailEntity } from "../../../services/delivery/models/ShippingDetailEntity"
import { ShippingDetailFactor } from "../../../services/delivery/models/ShippingDetailFactor"
import { ShippingDetailView } from "../../../services/delivery/models/ShippingDetailView"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { TnmPort } from "../../../services/master/models/TnmPort"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { YESNOFlag } from "../../../services/privilege/enums/YESNOFlag"
import { applicationActions } from "../../Application/applicationSlice"

interface LSS010PcUiProps {
    filters: ShippingDetailFactor,
    setFilters: React.Dispatch<React.SetStateAction<ShippingDetailFactor>>,
    search: (filters: ShippingDetailFactor) => void,
    data: ShippingDetailView[],
    totalCount: number,
    sellerList: TnvCbds[],
    portList: TnmPort[],
}

export const LSS010PcUi = (props: LSS010PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = (props: LSS010PcUiProps) => {
    const { filters, setFilters, search, data, sellerList, portList } = props
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])
    const portcastFlagDisplay = useCallback((tableRow: TableRow) => {
        return (YESNOFlag.YES !== tableRow.row?.viaPortcastFlag)
    }, [])

    const columns = useMemo(() => [
        { field: 'bookingNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.bookingNo' }), width: 200 },
        { field: 'containerNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.containerNo' }), width: 200 },
        { field: 'outboundNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.outboundNo' }), width: 200 },
        { field: 'shippingMode', dataTypeName: CodeCategory.ShippingMode, title: intl.formatMessage({ id: 'field.shippingMode' }), width: 200 },
        { field: 'sellerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'seller' }), width: 150 },
        { field: 'loadingPortCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'loadingPort' }), width: 200 },
        { field: 'dischargePortCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'dischargePort' }), width: 180 },
        { field: 'atd', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.atd' }), width: 150 },
        { field: 'eta', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.eta' }), width: 150 },
        { field: 'cargoStatus', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.cargoStatus' }), width: 300 },
        { field: 'viaPortcastFlag', dataTypeName: CodeCategory.YesNoFlag, title: intl.formatMessage({ id: 'field.viaPortcastFlag' }), width: 270 },
    ], [intl])
    const getRowId = useCallback((row: any) => row.shippingDetailId, [])
    const actionProps1 = useMemo(() => ({ filters, setFilters, search, sellerList, portList }), [filters, setFilters, search, sellerList, portList])
    const actionProps2 = useMemo(() => ({ selections, search, filters }), [selections, search, filters])
    const actionProps3 = useMemo(() => ({ search, filters }), [filters, search])
    const completeRowProps = useMemo(() => ({ search, filters }), [filters, search])
    const completeDisplay = useCallback(() => true, [])
    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.ShippingMode} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.FtaFlag} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.YesNoFlag} />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={PortcastFlagAction} actionProps={actionProps2} />
        <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps2} />
        <ToolbarActionProvider Action={UploadAction} actionProps={actionProps3} />
        <RowActionProvider name="view" Action={ViewRowAction} />
        <RowActionProvider name="update" Action={UpdateCargoStatusAction} actionProps={completeRowProps} display={completeDisplay} />
        <RowActionProvider name="edit" Action={EditAction} />
        <RowActionProvider name="download" Action={DownloadRowAction} />
        <RowActionProvider name="viaPortcastFlag" Action={PortcastFlagRowAction} actionProps={completeRowProps} display={portcastFlagDisplay} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={[]}
            ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={actionProps1} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={220} />
    </DataGrid>
}

const FilterItem = (props: {
    filters: ShippingDetailFactor,
    setFilters: React.Dispatch<React.SetStateAction<ShippingDetailFactor>>,
    search: (filters: ShippingDetailFactor) => void,
    sellerList: TnvCbds[],
    portList: TnmPort[],
}) => {
    const { filters, setFilters, search, sellerList, portList } = props

    const sellerEntires: [string, string][] = useMemo(() => sellerList.map(({ cbdsUid, cbdsCode }) => [cbdsUid, cbdsCode]), [sellerList])
    const portEntries: [number, string][] = useMemo(() => portList.map(port => [port.portId, port.portCode]), [portList])

    const clear = useCallback((filters: ShippingDetailFactor) => {
        return { page: filters.page }
    }, [])
    const atdGetValue = useCallback((filters: ShippingDetailFactor) => {
        return [filters.atdStart ?? null, filters.atdEnd ?? null]
    }, [])
    const atdMapValue = useCallback((filters: ShippingDetailFactor, value: any) => {
        return { ...filters ?? {}, atdStart: value[0], atdEnd: value[1] }
    }, [])
    const etaGetValue = useCallback((filters: ShippingDetailFactor) => {
        return [filters.etaStart ?? null, filters.etaEnd ?? null]
    }, [])
    const etaMapValue = useCallback((filters: ShippingDetailFactor, value: any) => {
        return { ...filters ?? {}, etaStart: value[0], etaEnd: value[1] }
    }, [])

    const filterCounter = useCallback((filters: ShippingDetailFactor) => {
        return [
            filters.bookingNo,
            filters.containerNo,
            filters.shippingMode,
            filters.sellerUid,
            filters.loadingPortId,
            filters.dischargePortId,
            filters.atdStart || filters.atdEnd,
            filters.etaStart || filters.etaEnd,
            filters.cargoStatus,
            filters.viaPortcastFlag
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()
    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="bookingNo" label={intl.formatMessage({ id: 'field.bookingNo' })} labelAlign={'start'} />
        <StringItem field="containerNo" label={intl.formatMessage({ id: 'field.containerNo' })} labelAlign={'start'} />
        <CodesItem field="shippingMode" label={intl.formatMessage({ id: 'field.shippingMode' })} code={CodeCategory.ShippingMode} labelAlign={'start'} />
        <EntryItem field="seller" label={intl.formatMessage({ id: 'seller' })} entries={sellerEntires} labelAlign={'start'} />
        <EntryItem field="loadingPortId" label={intl.formatMessage({ id: 'loadingPort' })} entries={portEntries} labelAlign={'start'} />
        <EntryItem field="dischargePortId" label={intl.formatMessage({ id: 'dischargePort' })} entries={portEntries} labelAlign={'start'} />
        <DateRangeItem field="atd" label={intl.formatMessage({ id: 'field.atd' })} getValue={atdGetValue} mapValue={atdMapValue} labelAlign={'start'} />
        <DateRangeItem field="eta" label={intl.formatMessage({ id: 'field.eta' })} getValue={etaGetValue} mapValue={etaMapValue} labelAlign={'start'} />
        <StringItem field="cargoStatus" label={intl.formatMessage({ id: 'field.cargoStatus' })} labelAlign={'start'} />
        <CodesItem field="viaPortcastFlag" label={intl.formatMessage({ id: 'field.viaPortcastFlag' })} code={CodeCategory.YesNoFlag} labelAlign={'start'} labelWidth={250} />
    </FilterToolbarItem>
}

const UploadAction = ({ search, filters }: { search: (filters: ShippingDetailFactor) => void, filters: ShippingDetailFactor }) => {
    const uploadApi = useUploadShippingDetail()
    const uploadShippingDetail = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            files && uploadApi({ file: files[0] }, { serialized: true }).then(() => search(filters))
        })
    }, [filters, search, uploadApi])
    return <UploadGroupedToolbarAction access="LOGI.LSS010.UPLOAD">
        {(popupUpload, onClose) => <>
            <GroupedCallbackItem label={<FormattedMessage id="uploadShippingDetail" />} callback={() => uploadShippingDetail(popupUpload)} onClose={onClose} />
        </>}
    </UploadGroupedToolbarAction>
}

const PortcastFlagAction = ({ selections, search, filters }: { selections: number[], search: (filters: ShippingDetailFactor) => void, filters: ShippingDetailFactor }) => {
    const Viaportcast = useViaportcast()
    const dispatch = useDispatch()
    const portcastFlag = useCallback(() => {
        if (selections.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'viaPortcastFlag' }, messages: { code: 'w0936' } }))
            return
        }
        Viaportcast({ shippingDetailIds: selections }, { serialized: true }).then(() => {
            search(filters)
        })
    }, [selections, Viaportcast, dispatch, search, filters])

    return <PortcastFlagToolbarAction >
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="viaPortcastFlag" />} callback={portcastFlag} onClose={onClose} />
        </>}
    </PortcastFlagToolbarAction>
}

const DownloadAction = ({ selections }: { selections: number[] }) => {
    const downloadShippingDetail = useDownloadShippingDetail()
    const dispatch = useDispatch()
    const download = useCallback(() => {
        if (selections.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'download' }, messages: { code: 'w0343' } }))
            return
        }
        downloadShippingDetail({ shippingDetailIds: selections })
    }, [dispatch, downloadShippingDetail, selections])

    return <DownloadGroupedToolbarAction access="LOGI.LSS010.DOWNLOAD">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="download" />} callback={download} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}

const EditAction = ({ tableRow }: DataGridRowActionProps) => {
    const toPath = useCallback((tableRow: TableRow) => `/shippingdetail/edit-${tableRow.rowId}`, [])
    return <EditRedirectRowAction access="LOGI.LSS011.VIEW" tableRow={tableRow} path={toPath} />
}

const UpdateCargoStatusAction = ({ tableRow, search, filters }: DataGridRowActionProps & {
    search: (filters: ShippingDetailFactor) => void,
    filters: ShippingDetailFactor,
}) => {
    const intl = useIntl()
    const [cargoStatus, setCargoStatus] = useState<ShippingDetailEntity>()
    const shippingDetailId = useMemo(() => tableRow?.row?.shippingDetailId, [tableRow?.row?.shippingDetailId])
    const orgCargoStatus = useMemo(() => tableRow?.row?.cargoStatus, [tableRow?.row?.cargoStatus])
    const status = useMemo(() => tableRow?.row?.status, [tableRow?.row?.status])
    const [data, setData] = useState<{ shippingDetailId: string, cargoStatus?: string, status?: string }>({
        shippingDetailId: shippingDetailId,
        cargoStatus: orgCargoStatus,
        status: status
    })
    const [open, setOpen] = useState<boolean>(false)

    const entries = useMemo(() => cargoStatus?.availableCargoStatuss?.map((m: string) => [m, m] as [string, string]) ?? [], [cargoStatus?.availableCargoStatuss])

    const save = useUpdateCargoStatus()
    const getCargoStatus = useGetShippingDetail()
    const [disabled, setDisabled] = useState<boolean>(false)

    const doUpdateInfo = useCallback(() => {
        setDisabled(true)
        save(data, { serialized: true }).then(() => {
            search(filters)
            setOpen(false)
        }).finally(() => {
            setDisabled(false)
        })
    }, [data, search, filters, save])

    useEffect(() => {

        open && getCargoStatus(shippingDetailId, { serialized: true, silent: true }).then(result => setCargoStatus(result ?? []))

    }, [getCargoStatus, open, shippingDetailId])

    return <>
        <SubmitCallbackRowAction tableRow={tableRow} title={<FormattedMessage id="inputCargoStatus" />} callback={() => setOpen(true)} />
        <DarkDialog onClose={() => {
            setOpen(false)
        }} maxWidth="md" fullWidth aria-labelledby="simple-dialog-title" open={open}>
            <DialogHeader onClose={() => setOpen(false)}><FormattedMessage id="inputUpdateCargoStatus" /></DialogHeader>
            <DialogContent>
                <DialogContentText>
                    <Form data={data} setData={setData} labelDisplay="block" helperDisplay="tooltip" columnCount={1}>
                        <EntryItem field="cargoStatus" labelWidth={120} label={intl.formatMessage({ id: 'cargoStatus' })} entries={entries} />
                    </Form>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={() => setOpen(false)} />
                <DialogAction title={<FormattedMessage id="update" />} callback={() => doUpdateInfo()} disabled={disabled} />
            </DialogActions>
        </DarkDialog>
    </>
}

const ViewRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const toPath = useCallback((tableRow: TableRow) => `/shippingdetail/view-${tableRow.rowId}`, [])
    return <ViewRedirectRowAction access="LOGI.LSS011.VIEW" tableRow={tableRow} path={toPath} />
}

const DownloadRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const downloadShippingDetail = useDownloadShippingDetail()
    const dispatch = useDispatch()
    const download = useCallback(() => {
        if (!tableRow.rowId) {
            dispatch(applicationActions.pushError({ title: tableRow.rowId as string, messages: { code: 'w0343' } }))
            return
        }
        downloadShippingDetail({ shippingDetailIds: [tableRow.rowId as any] })
    }, [dispatch, downloadShippingDetail, tableRow.rowId])
    return <DownloadCallbackRowAction access="LOGI.LSS010.DOWNLOAD" tableRow={tableRow} callback={download} />
}

const PortcastFlagRowAction = ({ tableRow, search, filters }: DataGridRowActionProps & {
    search: (filters: ShippingDetailFactor) => void,
    filters: ShippingDetailFactor,
}) => {
    const viaportcast = useViaportcast()
    const dispatch = useDispatch()
    const portcastFlagChange = useCallback(() => {
        if (!tableRow.rowId) {
            dispatch(applicationActions.pushError({ title: tableRow.rowId as string, messages: { code: 'w0343' } }))
            return
        }
        viaportcast({ shippingDetailIds: [tableRow.rowId as any] }, { serialized: true }).then(() => {
            search(filters)
        })
    }, [dispatch, filters, search, tableRow.rowId, viaportcast])
    return <PortcastFlagCallbackRowAction tableRow={tableRow} callback={portcastFlagChange} />
}
