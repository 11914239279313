import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { Break, DateRangeItem, EntriesItem, Form } from "@rithe/form"
import { Arrays, Records } from "@rithe/utils"
import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { DownloadCallbackViewAction } from "../../../components/Action/DownloadCallbackViewAction"
import { UploadCallbackViewAction } from "../../../components/Action/UploadCallbackViewAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { View } from "../../../components/View/View"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { useDownloadOutboundMapa } from "../../../services/smt/api/smtDownloadApi"
import { useUploadOutboundMapa } from "../../../services/smt/api/smtUploadApi"
import { StockManagementMasterTransfer } from "../../../services/smt/models/StockManagementMasterTransfer"
import { OutboundDownloadFactor } from "../../../services/smt/stockManagement/models/OutboundDownloadFactor"
import { applicationActions } from "../../Application/applicationSlice"

interface SMGTS810MAPAPcUiProps {
    filters: OutboundDownloadFactor,
    setFilters: React.Dispatch<SetStateAction<OutboundDownloadFactor>>,
    totalCount: number,
    groupings: string[],
    partsData: StockManagementMasterTransfer[],
    partsSelections: number[],
    setPartsSelections: Dispatch<SetStateAction<number[]>>,
    displayData: StockManagementMasterTransfer[],
}

export const SMGTS810MAPAPcUi = (props: SMGTS810MAPAPcUiProps) => {
    const { filters, setFilters, groupings, partsData, partsSelections, displayData } = props
    const intl = useIntl()

    return <View actions={[DownloadAction({ filters, partsSelections, displayData }), UploadAction()]}>
        <SectionCard allowCollapse key={1}>
            <SectionCardHeader
                title={intl.formatMessage({ id: 'inputOutbound' })}
            />
            <SectionCardContent>
                <Outbound filters={filters} setFilters={setFilters} groupings={groupings} partsData={partsData} />
            </SectionCardContent>
        </SectionCard>
        <SectionCard key={2}>
            <SectionCardHeader title={intl.formatMessage({ id: "selectPartsList" })} />
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const Outbound = ({ groupings, filters, setFilters, partsData }: {
    filters: OutboundDownloadFactor,
    setFilters: React.Dispatch<SetStateAction<OutboundDownloadFactor>>,
    groupings: string[],
    partsData: StockManagementMasterTransfer[],
}) => {
    const intl = useIntl()
    const groupingsMap = useMemo(() => {
        const entries: [[string, string]] = [["", intl.formatMessage({ id: 'noGrouping' })]]
        groupings && groupings.forEach(g => entries.push([g, g]))
        return entries;
    }, [groupings, intl])

    const buyersMap = useMemo(() => {
        const entries: [[string, string]] = [["", ""]]
        partsData && Arrays.distinct(partsData.filter(f => f.buyerCode).map(m => m.buyerCode)).forEach(p => entries.push([p!, p!]))
        return entries;
    }, [partsData])

    const actualOutboundDateGetValue = useCallback((filters: OutboundDownloadFactor) => {
        return [filters.outboundDateStart ?? null, filters.outboundDateEnd ?? null]
    }, [])

    const actualOutboundDateMapValue = useCallback((filters: OutboundDownloadFactor, value: any) => {
        return { ...filters ?? {}, outboundDateStart: value[0], outboundDateEnd: value[1] }
    }, [])

    return <Form data={filters} setData={setFilters} labelDisplay="block" helperDisplay="tooltip" columnCount={2}>
        <EntriesItem field="buyerList" label={intl.formatMessage({ id: 'buyerCode' })} entries={buyersMap} />
        <EntriesItem field="groupings" label={intl.formatMessage({ id: 'grouping' })} entries={groupingsMap} />
        <Break />
        <DateRangeItem field="actualOutboundDate" label={intl.formatMessage({ id: 'actualOutboundDatePeriod' })} getValue={actualOutboundDateGetValue} mapValue={actualOutboundDateMapValue} />
    </Form>
}

const DataTable = ({ partsSelections, setPartsSelections, displayData }: SMGTS810MAPAPcUiProps) => {
    const intl = useIntl()
    const columns = useMemo(() => [
        { field: 'grouping', dataTypeName: 'string', title: intl.formatMessage({ id: 'grouping' }), width: 230 },
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 230 },
        { field: 'buyerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.buyerCode' }), width: 200 },
        // { field: 'dataSource', dataTypeName: CodeCategory.DataSource, title: intl.formatMessage({ id: 'dataSource' }), width: 200 },
        { field: 'externalRefNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.externalRefNo' }), width: 230 },
        { field: 'buyerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.buyerPartsNo' }), width: 230 },
        { field: 'backNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.backNo' }), width: 230 },

        { field: 'commonPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.commonPartsNo' }), width: 230 },
        { field: 'specialPartsType', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.specialPartsType' }), width: 230 },
        { field: 'customerStockFlag', dataTypeName: CodeCategory.CustomsFlag, title: intl.formatMessage({ id: 'field.customerStockFlag' }), width: 230 },

        { field: 'deliveryCustPat', dataTypeName: 'string', title: intl.formatMessage({ id: 'deliveryCustPat' }), width: 200 },
        { field: 'custDelayPat', dataTypeName: CodeCategory.AdjustmentPattern1, title: intl.formatMessage({ id: 'custDelayPat' }), width: 200 },
        { field: 'custAdvancePat', dataTypeName: CodeCategory.AdjustmentPattern2, title: intl.formatMessage({ id: 'custAdvancePat' }), width: 200 },
    ], [intl])

    const getRowId = useCallback((row: any) => row.customerPartsId, [])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.DataSource} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.InventoryBoxFlag} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.CustomsFlag} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OnShippingDelayPattern} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.AdjustmentPattern1} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.AdjustmentPattern2} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.AlertMethod} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.CalculationPattern} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.SafetyStockUom} />
        <Data rows={displayData} columns={columns} getRowId={getRowId} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['commonPartsNo', 'specialPartsType', 'customerStockFlag', 'deliveryCustPat', 'custDelayPat', 'custAdvancePat']}
            columnSettings={{
                partsNo: { disableUserControl: true },
                customerCode: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={partsSelections} onSelectedRowIdsChange={setPartsSelections} />
    </DataGrid>
}

const DownloadAction = ({ filters, partsSelections, displayData }: {
    filters: OutboundDownloadFactor,
    partsSelections: number[],
    displayData: StockManagementMasterTransfer[]
}) => {
    const dispatch = useDispatch()
    const downloadOutbound = useDownloadOutboundMapa()
    const download = useCallback((filters: OutboundDownloadFactor) => {
        const customerPartsIds = partsSelections.length > 0 ? partsSelections : (displayData?.map(m => m.customerPartsId) ?? [])
        if (customerPartsIds.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'download' }, messages: { code: 'w0028' } }))
        } else {
            downloadOutbound({ ...filters, customerPartsIds: customerPartsIds })
        }
    }, [dispatch, displayData, downloadOutbound, partsSelections])
    return <DownloadCallbackViewAction access="STCK.SMGTS810MAPA.DOWNLOAD" callback={() => download(filters)} outlined />
}

const UploadAction = () => {
    const dispatch = useDispatch()
    const uploadOutbound = useUploadOutboundMapa()
    const upload = useCallback((files: FileList | null) => {
        if (files === null) {
            dispatch(applicationActions.pushError({ title: { code: 'upload' }, messages: { code: 'w0342' } }))
            return
        }
        uploadOutbound({ file: files[0] }, { serialized: true })
    }, [dispatch, uploadOutbound])
    return <UploadCallbackViewAction access="STCK.SMGTS810MAPA.UPLOAD" callback={upload} title={<FormattedMessage id="Upload" />} />
}