import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from '@rithe/data-grid'
import { DataGridRowActionProps } from '@rithe/data-grid/dist/components/basic/DataGridRowAction'
import { DateRangeItem, StringItem } from '@rithe/form'
import { Records } from "@rithe/utils"
import React, { memo, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { SectionCard } from '../../../components/Card/SectionCard'
import { SectionCardContent } from '../../../components/Card/SectionCardContent'
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { ForecastRedirectRowAction } from '../../../components/DataGrid/rowActions/ForecastRedirectRowAction'
import { ViewRedirectRowAction } from '../../../components/DataGrid/rowActions/ViewRedirectRowAction'
import { GroupedCallbackItem } from '../../../components/DataGrid/toolbarActions/GroupedCallbackItem'
import { PrintGroupedToolbarAction } from '../../../components/DataGrid/toolbarActions/PrintGroupedToolbarAction'
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from '../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider'
import { CodesItem } from '../../../components/Form/CodesItem'
import { View } from '../../../components/View/View'
import { usePrintDownloadPDF } from '../../../services/delivery/apis/deliveryOutboundApi'
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { OrderType } from "../../../services/order/enums/OrderType"
import { PurchaseOrderListFactor } from "../../../services/order/models/PurchaseOrderListFactor"
import { SpotOrderListResult } from "../../../services/order/models/SpotOrderListResult"

interface OPS020PcUiProps {
    filters: PurchaseOrderListFactor,
    setFilters: React.Dispatch<React.SetStateAction<PurchaseOrderListFactor>>,
    search: (filters: PurchaseOrderListFactor) => void,
    data: SpotOrderListResult[],
    totalCount: number
}

export const OPS020PcUi = (props: OPS020PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = memo(({ filters, data, setFilters, search }: OPS020PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])

    const columns = useMemo(() => [
        { field: 'orderNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'orderNo' }), width: 300 },
        { field: 'orderType', dataTypeName: CodeCategory.OrderType, title: intl.formatMessage({ id: 'field.orderType' }), width: 300 },
        { field: 'poDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'placeDate' }), width: 200 },
        // { field: 'orderFrequency', dataTypeName: CodeCategory.OrderFrequency, title: intl.formatMessage({ id: 'field.orderFrequency' }), width: 200 },
        // { field: 'contractNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contractNo' }), width: 300 },
        // { field: 'contractRouteCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contrRouteCode' }), width: 300 },
        // { field: 'orderDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'orderDate' }), width: 180 },
        { field: 'status', dataTypeName: CodeCategory.PoSpotStatus, title: intl.formatMessage({ id: 'field.status' }), width: 200 },
        // { field: 'delayStatus', dataTypeName: CodeCategory.DelayStatus, title: intl.formatMessage({ id: 'delayStatus' }), width: 180 },
        // { field: 'reviseVersion', dataTypeName: 'number', title: intl.formatMessage({ id: 'reviseVersion' }), width: 180 },
    ], [intl])

    const getRowId = useCallback((row: any) => row.orderId, [])
    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search }), [filters, search, setFilters])
    const actionProps1 = useMemo(() => ({ selections }), [selections])
    const display1 = useCallback((tableRow: TableRow) => tableRow.row?.orderType === OrderType.REGULAR, [])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OrderType} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OrderFrequency} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.PoSpotStatus} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.DelayStatus} />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={PrintAction} actionProps={actionProps1} />
        <RowActionProvider name="detail" Action={DetailRowAction} />
        <RowActionProvider name="forecast" Action={ForecastRowAction} display={display1} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['reviseVersion', 'contractRouteCode']}
            columnSettings={{
                orderNo: { disableUserControl: true },
                customerCode: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={88} />
    </DataGrid>
})

const FilterItem = memo((props: {
    filters: PurchaseOrderListFactor,
    setFilters: React.Dispatch<React.SetStateAction<PurchaseOrderListFactor>>,
    search: (filters: PurchaseOrderListFactor) => void
}) => {
    const { filters, setFilters, search } = props


    const orderDateGetValue = useCallback((filters: PurchaseOrderListFactor) => {
        return [filters.orderDateStart ?? null, filters.orderDateEnd ?? null]
    }, [])

    const orderDateMapValue = useCallback((filters: PurchaseOrderListFactor, value: any) => {
        return { ...filters ?? {}, orderDateStart: value[0], orderDateEnd: value[1] }
    }, [])

    const clear = useCallback((filters: PurchaseOrderListFactor) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: PurchaseOrderListFactor) => {
        return [
            filters.orderNo,
            filters.contractNo,
            filters.contractRouteCode,
            filters.orderTypeList,
            filters.orderFrequencyList,
            filters.shippingModeList,
            filters.statusList,
            filters.delayStatusList,
            filters.orderDateStart || filters.orderDateEnd,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()


    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="orderNo" label={intl.formatMessage({ id: 'orderNo' })} />
        <StringItem field="contractNo" label={intl.formatMessage({ id: 'field.contractNo' })} />
        <StringItem field="contractRouteCode" label={intl.formatMessage({ id: 'field.contrRouteCode' })} />
        <CodesItem field="orderTypeList" label={intl.formatMessage({ id: 'field.orderType' })} code={CodeCategory.OrderType} />
        <CodesItem field="orderFrequencyList" label={intl.formatMessage({ id: 'field.orderFrequency' })} code={CodeCategory.OrderFrequency} />
        <CodesItem field="shippingModeList" label={intl.formatMessage({ id: 'field.shippingMode' })} code={CodeCategory.ShippingMode} />
        <CodesItem field="statusList" label={intl.formatMessage({ id: 'field.status' })} code={CodeCategory.PoStatus} />
        <CodesItem field="delayStatusList" label={intl.formatMessage({ id: 'delayStatus' })} code={CodeCategory.DelayStatus} />
        <DateRangeItem field="orderDate" label={intl.formatMessage({ id: 'orderDate' })} getValue={orderDateGetValue} mapValue={orderDateMapValue} />
    </FilterToolbarItem>
})

const PrintAction = ({ selections }: { selections: number[] }) => {
    const printApi = usePrintDownloadPDF()
    const print = useCallback(() => printApi({ reportId: 'OPR010', labelId: 'Purchase Order', param: JSON.stringify(selections) }), [printApi, selections])
    return <PrintGroupedToolbarAction access="ORDER.OPS020.PRINT">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="print" />} callback={print} onClose={onClose} />
        </>}
    </PrintGroupedToolbarAction>
}

const DetailRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/pospot/view-${tableRow.rowId}`, [])
    return <ViewRedirectRowAction tableRow={tableRow} access="" path={path} />
}

const ForecastRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/po/fc-${tableRow.rowId}`, [])
    return <ForecastRedirectRowAction tableRow={tableRow} access="ORDER.OPS020.VIEWFORECAST" path={path} />
}