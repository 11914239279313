import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson, tableEntityToJson } from "../../utils/TableEntity"
import { ActiveFlag } from "../enums/ActiveFlag"
import { MilkrunFlag } from "../enums/MilkrunFlag"

export type TnmSupplier = Required & Partial<Optional> & TableEntity
export type TnmSupplierJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const tnmSupplierToJson: Project<TnmSupplier, TnmSupplierJson> = obj => ({
    ...tableEntityToJson(obj),
})
export const tnmSupplierFromJson: Project<TnmSupplierJson, TnmSupplier> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
    activeFlag: ActiveFlag,
    milkrunFlag: MilkrunFlag,
}

interface JsonRequired {
    supplierId: number,
    supplierCode: string,
    supplierName: string,
    regionCode: string,
}

interface JsonOptional {
    companyId: number,
    supplierShortCode: string,
    address1: string,
    address2: string,
    address3: string,
    address4: string,
    postalCode: string,
    contact1: string,
    fax1: string,
    telephone1: string,
    email1: string,
    contact2: string,
    fax2: string,
    telephone2: string,
    email2: string,
    timeZone: string,
    calendarId: number,
    activeFlag: number,
    milkrunFlag: number,
    status: number
}