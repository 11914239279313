import { arrx } from "@rithe/utils"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { useGetInvoice, useSaveInvoice } from "../../../services/accounting/apis/accountingBuyerApi"
import { ProfileType } from "../../../services/accounting/enums/ProfileType"
import { GiGrInvoice } from "../../../services/accounting/models/GiGrInvoice"
import { useComMasterGetAllCbds, useComMasterGetAllIncoterms, useComMasterGetAllRegion, useGetPaymentTermsByBuId, useGetPaymentTermsBySupplierId } from "../../../services/common/apis/commonMasterApi"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { TnmIncoterms } from "../../../services/master/models/TnmIncoterms"
import { TnmPaymentTerms } from "../../../services/master/models/TnmPaymentTerms"
import { TnmRegion } from "../../../services/master/models/TnmRegion"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { useGetCompanyId, useGetCompanyType } from "../../../utils/ApplicationUtils"
import { useMatch } from "../../../utils/useMatch"
import { AIS021PcUi } from "./AIS021PcUi"

export const AIS021 = () => {
    const { invoiceId } = useParams() as any
    const { path } = useMatch()
    const [invoice, setInvoice] = useState<GiGrInvoice>()
    const [paymentTerms, setPaymentTerms] = useState<TnmPaymentTerms[]>([])
    const [incoterms, setIncoterms] = useState<TnmIncoterms[]>([])
    const [partys, setPartys] = useState<TnvCbds[]>([])
    const [regions, setRegions] = useState<TnmRegion[]>([])
    const getInvoice = useGetInvoice()
    const saveInvoice = useSaveInvoice()
    const getIncoterms = useComMasterGetAllIncoterms()

    const getPaymentByBu = useGetPaymentTermsByBuId()
    const getPaymentBySupplier = useGetPaymentTermsBySupplierId()
    const getCbdsListByType = useComMasterGetAllCbds()
    const getAllRegins = useComMasterGetAllRegion()
    const companyType = useGetCompanyType()
    const companyId = useGetCompanyId()

    const editing = useMemo(() => path === '/gi/edit-:invoiceId', [path])

    const search = useCallback(() => {
        if (invoiceId !== undefined && invoiceId !== null) {
            getInvoice(({ invoiceId: invoiceId }), { silent: true, serialized: true }).then(result => {
                setInvoice(result ?? {} as any)
            })
        };
    }, [getInvoice, invoiceId])

    useEffect(() => {
        search()
    }, [search])


    useEffect(() => {
        getIncoterms(undefined, { silent: true }).then((result) => setIncoterms(result ?? []))
    }, [editing, getIncoterms])

    useEffect(() => {
        if (editing && companyId !== null) {
            if (CbdsType.BU === companyType) {
                getPaymentByBu({ buId: companyId }, { silent: true }).then((result) => setPaymentTerms(result ?? []))
            } else {
                getPaymentBySupplier({ supplierId: companyId }, { silent: true }).then((result) => setPaymentTerms(result ?? []))
            }
        }
    }, [companyId, companyType, editing, getIncoterms, getPaymentByBu, getPaymentBySupplier])

    useEffect(() => {
        editing && getCbdsListByType({ types: [CbdsType.CUST, CbdsType.BU, CbdsType.DC] }, { silent: true }).then((result) => setPartys(result ?? []))
    }, [editing, getCbdsListByType])


    useEffect(() => {
        editing && getAllRegins(undefined, { silent: true }).then((result) => setRegions(result ?? []))
    }, [editing, getAllRegins])

    const onSave = useCallback(() => {
        invoice && saveInvoice({ ...prepareInvoice(invoice), invoices: invoice.invoices ? invoice.invoices.map(prepareInvoice) : [] }, { serialized: true }).then(() => search())
    }, [invoice, saveInvoice, search])


    return <AIS021PcUi
        search={search}
        editing={editing}
        invoice={invoice}
        setInvoice={setInvoice}
        onSave={onSave}
        paymentTerms={paymentTerms}
        incoterms={incoterms}
        partys={partys}
        regions={regions}
    />
}

const prepareInvoice = (invoice: any) => {
    return ({
        invoiceId: invoice.invoiceId,
        vesselName: invoice.vesselName,
        voyageNo: invoice.voyageNo,
        etd: invoice.etd,
        eta: invoice.eta,
        remark: invoice.remark,
        countryOfOrigin: invoice.countryOfOrigin,
        invoiceDate: invoice.invoiceDate,
        paymentTermId: invoice.paymentTerms?.paymentTermsId,
        prices: invoice.details.map((m: any) => ({ invoicePriceId: m.invoicePriceId, invoiceId: m.invoiceId, price: m.price, qty: m.qty })),
        //----BreakDownInfo
        breakdown: invoice.breakdown ? {
            invoiceAdjustmentAmount: invoice.breakdown.invoiceAdjustmentAmount,
            invoiceAdjustmentReason: invoice.breakdown.invoiceAdjustmentReason,
            costItem1: invoice.breakdown.costItem1,
            costItem1Amount: invoice.breakdown.costItem1Amount,
            costItem2: invoice.breakdown.costItem2,
            costItem2Amount: invoice.breakdown.costItem2Amount,
            costItem3: invoice.breakdown.costItem3,
            costItem3Amount: invoice.breakdown.costItem3Amount,
            costItem4: invoice.breakdown.costItem4,
            costItem4Amount: invoice.breakdown.costItem4Amount,
            costItem5: invoice.breakdown.costItem5,
            costItem5Amount: invoice.breakdown.costItem5Amount,
            additionalItem1: invoice.breakdown.additionalItem1,
            additionalItem1Amount: invoice.breakdown.additionalItem1Amount,
            additionalItem2: invoice.breakdown.additionalItem2,
            additionalItem2Amount: invoice.breakdown.additionalItem2Amount,
            additionalItem3: invoice.breakdown.additionalItem3,
            additionalItem3Amount: invoice.breakdown.additionalItem3Amount,
            additionalItem4: invoice.breakdown.additionalItem4,
            additionalItem4Amount: invoice.breakdown.additionalItem4Amount,
            additionalItem5: invoice.breakdown.additionalItem5,
            additionalItem5Amount: invoice.breakdown.additionalItem5Amount,
            initialIncotermsId: invoice.breakdown.initialIncotermsId,
            initialIncotermsCode: invoice.breakdown.initialIncotermsCode,
            initialIncotermsPlace: invoice.breakdown.initialIncotermsPlace,
            initialIncotermsAmount: invoice.breakdown.initialIncotermsAmount,
            initialIncotermsDescription: invoice.breakdown.initialIncotermsDescription,
            finalIncotermsId: invoice.breakdown.finalIncotermsId,
            finalIncotermsCode: invoice.breakdown.finalIncotermsCode,
            finalIncotermsPlace: invoice.breakdown.finalIncotermsPlace,
            finalIncotermsAmount: invoice.breakdown.finalIncotermsAmount,
            finalIncotermsDescription: invoice.breakdown.finalIncotermsDescription,
        } : {},
        packages: (invoice?.containers ?? []).flatMap((m: any) => m?.packages).map((m: any) => ({
            invoicePackageId: m.invoicePackageId,
            packageNo: m?.packageNo,
            packageType: m?.packageType,
            length: m?.length,
            width: m?.width,
            height: m?.height,
            m3: m?.m3,
            grossWeight: m?.grossWeight,
            netWeight: m?.netWeight,
        })),
        profiles: arrx(
            invoice.consignee && {
                profileUid: invoice.consignee.cbdsUid,
                profileType: ProfileType.CONSIGNEE,
                name: invoice.consignee.cbdsName,
                address1: invoice.consignee?.contact?.address1,
                address2: invoice.consignee?.contact?.address2,
                address3: invoice.consignee?.contact?.address3,
                address4: invoice.consignee?.contact?.address4,
                postalCode: invoice.consignee?.contact?.postalCode,
                tel: invoice.consignee?.contact?.tel,
                fax: invoice.consignee?.contact?.fax,
            },
            invoice.accountee && {
                profileUid: invoice.accountee.cbdsUid,
                profileType: ProfileType.ACCOUNTEE,
                name: invoice.accountee?.cbdsName,
                address1: invoice.accountee?.contact?.address1,
                address2: invoice.accountee?.contact?.address2,
                address3: invoice.accountee?.contact?.address3,
                address4: invoice.accountee?.contact?.address4,
                postalCode: invoice.accountee?.contact?.postalCode,
                tel: invoice.accountee?.contact?.tel,
                fax: invoice.accountee?.contact?.fax,
            },
            invoice.deliveryTo && {
                profileUid: invoice.deliveryTo.cbdsUid,
                profileType: ProfileType.DELIVERY,
                name: invoice.deliveryTo?.cbdsName,
                address1: invoice.deliveryTo?.contact?.address1,
                address2: invoice.deliveryTo?.contact?.address2,
                address3: invoice.deliveryTo?.contact?.address3,
                address4: invoice.deliveryTo?.contact?.address4,
                postalCode: invoice.deliveryTo?.contact?.postalCode,
                tel: invoice.deliveryTo?.contact?.tel,
                fax: invoice.deliveryTo?.contact?.fax,
            }
        )
    })
}