import React from 'react'
import { FormattedMessage } from 'react-intl'
import { CallbackViewAction } from './CallbackViewAction'

interface ApplyCallbackViewActionProps {
    access?: string,
    disabled?: boolean,
    outlined?: boolean,
    callback: () => void,
}

export const ApplyCallbackViewAction = (props: ApplyCallbackViewActionProps) => {
    return <CallbackViewAction {...props} title={<FormattedMessage id="apply" />} />
}