import { Column, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Row, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { Form, StringItem } from "@rithe/form"
import { Arrays, Records } from "@rithe/utils"
import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CodeItem } from "../../../components/Form/CodeItem"
import { View } from "../../../components/View/View"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { GroupStatus } from "../../../services/master/enums/GroupStatus"
import { OrderGroupDetailPartsResult } from "../../../services/master/models/OrderGroupDetailPartsResult"
import { OrderGroupResult } from "../../../services/master/models/OrderGroupResult"

interface MLS141PcUiProps {
    headerInfo: OrderGroupResult,
    partsList: OrderGroupDetailPartsResult[],
}

export const MLS141PcUi = (props: MLS141PcUiProps) => {
    const intl = useIntl()
    return (
        <View>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={1}
                    title={intl.formatMessage({ id: 'orderGroupHeaderInfo' })}
                    subtitle={intl.formatMessage({ id: 'orderGroupHeaderInfo' })}
                />
                <SectionCardContent>
                    <HeaderInfo {...props} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={2}
                    title={intl.formatMessage({ id: 'PartsListInformation' })}
                    subtitle={intl.formatMessage({ id: 'PartsListInformation' })}
                />
                <SectionCardContent>
                    <DataTable {...props} />
                </SectionCardContent>
            </SectionCard>
        </View>
    )
}

const HeaderInfo = memo((props: MLS141PcUiProps) => {
    const intl = useIntl()
    const { headerInfo } = props
    const getStatusName = useCallback(() => {
        const status = headerInfo.status
        if (status === GroupStatus.DRAFT) {
            return intl.formatMessage({id: 'draft'})
        }
        if (status === GroupStatus.CONFIRMED) {
            return intl.formatMessage({id: 'confirmed'})
        }
    }, [headerInfo.status, intl])
    return (
        <Form readonly data={headerInfo} labelDisplay="block" helperDisplay="tooltip" >
            <StringItem field="grouping" label={intl.formatMessage({ id: 'grouping' })} />
            <CodeItem field="businessType" label={intl.formatMessage({ id: 'field.businessType' })} code={CodeCategory.BusinessType} />
            <CodeItem field='orderFrequency' label={intl.formatMessage({ id: 'field.orderFrequency' })} code={CodeCategory.OrderFrequency} />
            <StringItem field="forecastNum" label={intl.formatMessage({id: 'forecastNumber'})} />
            <StringItem field="targetLeadtime" label={intl.formatMessage({id: 'targetLeadtime'})} />
            <StringItem field="status" label={intl.formatMessage({id: 'status'})} getValue={getStatusName} />
            <StringItem field="description" label={intl.formatMessage({id: 'description'})} />
            <StringItem field="remark" label={intl.formatMessage({id: 'remark'})} />
        </Form>
    )
})

const DataTable = (props: MLS141PcUiProps) => {
    const { partsList } = props
    const intl = useIntl()
    const [order, setOrder] = useState<string[]>([])

    const maxBlock = Arrays.max(partsList, (a, b) => {
        const alen = a.blockInfoList?.length ?? 0
        const bLen = b.blockInfoList?.length ?? 0
        return alen > bLen ? 1 : alen === bLen ? 0 : -1
    })
    const count = maxBlock?.blockInfoList?.length ?? 0
    const getBlockValue = useCallback((index: number, record: string) => {
        return (row: Row) => {
            const blockList = row.blockInfoList ?? []
            return blockList.length < index ? null : blockList[index - 1][record]
        }
    }, [])

    const columns = useMemo(() => {
 
        const fixColumns: Column[] = [
            { width: 220, field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }) },
            { width: 220, field: 'customerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerPartsNo' }) },
            { width: 200, field: 'customerCode', dataTypeName: 'string', title: intl.formatMessage({id: 'field.customerCode'})},
        ]

        let dynamicColumns: Column[] = []
        if (partsList.length > 0) {
            const getBlockCategories = (index: number) => [
                { key: `block` + index, value: intl.formatMessage({ id: 'block' }) + " " + index },
            ]
            Arrays.range(1, count).forEach(index => {
                dynamicColumns.push({field: `sellerCode` + index, dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sellerCode' }), categories: getBlockCategories(index), width: 200, getCellValue: getBlockValue(index, 'cbdsCode') })
                dynamicColumns.push({field: `unitPartsNo` + index, dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsNo' }), categories: getBlockCategories(index), width: 220, getCellValue: getBlockValue(index, 'unitPartsNo') })
                dynamicColumns.push({field: `orderLot` + index, dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), categories: getBlockCategories(index), width: 100, getCellValue: getBlockValue(index, 'orderLot') })
                dynamicColumns.push({field: `spq` + index, dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), categories: getBlockCategories(index), width: 100, getCellValue: getBlockValue(index, 'spq') })
                dynamicColumns.push({field: `price` + index, dataTypeName: 'number', title: intl.formatMessage({ id: 'field.price' }), categories: getBlockCategories(index), width: 100, getCellValue: getBlockValue(index, 'price') })
                dynamicColumns.push({field: `currency` + index, dataTypeName: 'string', title: intl.formatMessage({id: 'field.currency'}), categories: getBlockCategories(index), width: 100, getCellValue: getBlockValue(index, 'currency')})
                dynamicColumns.push({field: `customsFlag` + index, dataTypeName: 'string', title: intl.formatMessage({id: 'field.customsFlag'}), categories: getBlockCategories(index), width: 150, getCellValue: getBlockValue(index, 'customsFlag')})
                dynamicColumns.push({field: `shippingRouteCode` + index, dataTypeName: 'string', title: intl.formatMessage({id: 'field.shippingRouteCode'}), categories: getBlockCategories(index), width: 220, getCellValue: getBlockValue(index, 'shippingRouteCode')})
                dynamicColumns.push({field: `shipper` + index, dataTypeName: 'string', title: intl.formatMessage({id: 'field.shipper'}), categories: getBlockCategories(index), width: 200, getCellValue: getBlockValue(index, 'shipper')})
                dynamicColumns.push({field: `receiver` + index, dataTypeName: 'string', title: intl.formatMessage({id: 'field.receiver'}), categories: getBlockCategories(index), width: 200, getCellValue: getBlockValue(index, 'receiver')})
            })
        }
        return Arrays.concat(fixColumns, dynamicColumns)
    }, [count, getBlockValue, intl, partsList.length])

    useEffect(() => {
        setOrder(columns.map(column => column.field))
    }, [columns])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <Data rows={partsList} columns={columns} />
        <ColumnFreeze />
        <ColumnVisibility ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering order={order} onOrderChange={setOrder} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
    </DataGrid>
}