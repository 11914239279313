import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { PageResultEntity, PageResultEntityJson, pageResultEntityToJson } from "../../utils/PageResultEntity"


export interface CustomerUsageResult120 {
    monthList: Date[],
    page: PageResultEntity<CustomerUsageTransfer120>
}

interface CustomerUsageResultJson120 {
    monthList: string[],
    page: PageResultEntityJson<CustomerUsageTransferJson120>,
}

export const customerUsageResultDeserializer120: Project<CustomerUsageResultJson120, CustomerUsageResult120> = json => ({
    monthList: json.monthList.map(v => dateFromJson(v)),
    page: pageResultEntityToJson(custDeliveryTransferFromJson120)(json.page)
})

export type CustomerUsageTransfer120 = Required & Partial<Optional>
type CustomerUsageTransferJson120 = JsonRequired & Partial<JsonOptional>

const custDeliveryTransferFromJson120: Project<CustomerUsageTransferJson120, CustomerUsageTransfer120> = json => ({
    ...json,
    updatedDate: json.updatedDate ? dateFromJson(json.updatedDate) : undefined,
    updatedDateTime: json.updatedDateTime ? dateFromJson(json.updatedDateTime) : undefined,
    usageStartDate: json.usageStartDate ? dateFromJson(json.usageStartDate) : undefined,
    usageEndDate: json.usageEndDate ? dateFromJson(json.usageEndDate) : undefined,
    usageDate: json.usageDate ? dateFromJson(json.usageDate) : undefined,
})

interface Required extends JsonRequired { }

interface Optional extends Omit<JsonOptional, 'updatedDate' | 'updatedDateTime' | 'usageStartDate' | 'usageEndDate' | 'usageDate'> {
    updatedDate: Date,
    updatedDateTime: Date,
    usageDate: Date,
    usageStartDate: Date,
    usageEndDate: Date,

}

interface JsonRequired {

}

interface JsonOptional {
    customerPartsId: number,
    modelNo: string,
    partsNo: string,
    customerCode: string,
    expCountry: string,
    supplierCode: string,
    extRefNo: string,
    dataSource: number,
    dataSourceStr: string,
    decimalDigits: number,
    updatedDate: string,
    updatedDateTime: string,
    usageStartDate: string,
    usageEndDate: string,
    usageDate: string,
    usageQty: number,
    usageVersion: string,
    monthlyQtyList: (number | null)[],
}
