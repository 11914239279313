import { IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { DataGridCategoryProps } from "@rithe/data-grid/dist/components/basic/DataGridCategory";
import React from "react";

export const useShippingPlanCategory = (click: (filed: string,) => void) => {
    const styles = useStyles()
    return (props: DataGridCategoryProps) => {
        const { category, tableColumns } = props
        return <Tooltip title={"Edit"} >
            <Typography variant="body2" className={styles.root} onClick={() => click(tableColumns[0].field)}>
                {category}<IconButton style={{ zoom: 0.8 }}><Edit fontSize={"small"} /></IconButton>
            </Typography >
        </Tooltip>
    }
}

export const useShippingPlanCategory2 = () => {
    const styles = useStyles()
    return (props: DataGridCategoryProps) => {
        const { category } = props
        return <Tooltip title={""} >
            <Typography variant="body2" className={styles.root} >
                {category}
            </Typography >
        </Tooltip>
    }
}

const useStyles = makeStyles(({
    root: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }
}))