import { Project } from "@rithe/utils"
import { dateFromJson } from "../../utils/deserializer"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"

export type SdtSmtCustUsage = Required & Partial<Optional> & TableEntity
type SdtSmtCustUsageJson = JsonRequired & Partial<JsonOptional> & TableEntityJson

export const sdtSmtCustUsageFromJson: Project<SdtSmtCustUsageJson, SdtSmtCustUsage> = json => ({
    ...tableEntityFromJson(json),
    uploadedDatetime: dateFromJson(json.uploadedDatetime),
})

interface Required extends JsonRequired {

}


interface Optional extends Omit<JsonOptional, 'uploadedDatetime'> {
    uploadedDatetime: Date
}

interface JsonRequired {

}

interface JsonOptional {
    uploadedDatetime: string,
    uploadedDate: string,
    usageVersion: string,
    updatedBy: string,
    partsNum: number,
}