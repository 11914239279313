import { useCallback, useEffect, useMemo, useState } from "react"
import { useGetPNAInfo } from "../../../services/master/apis/businessUnitApi"
import { useFindChangedableOrderList } from "../../../services/order/apis/ChangeRequestApi"
import { SChangeRequestListResult } from "../../../services/order/models/SChangeRequestListResult"
import { ORS020PcUi } from "./ORS020PcUi"

export const ORS020 = () => {

    const filters = useMemo(() => ({ page: { currentPage: 0, pageSize: 1000 } }), [])
    const [orderChangedableList, setOrderChangedableList] = useState<SChangeRequestListResult[]>([])
    const [pnaCode, setPnaCode] = useState<string | undefined>()
    const findChangedableOrderList = useFindChangedableOrderList()

    const searchOrderChangedableList = useCallback(() => {
        findChangedableOrderList(undefined, { silent: true, serialized: true }).then(result => {
            setOrderChangedableList(result || [])
        })
    }, [findChangedableOrderList])

    useEffect(() => {
        searchOrderChangedableList()
    }, [filters, searchOrderChangedableList])

    const getPNAInfo = useGetPNAInfo()
    useEffect(() => {
        getPNAInfo(undefined, { silent: true, serialized: true }).then(result => {
            setPnaCode(result?.buCode)
        })
    }, [getPNAInfo])

    return <ORS020PcUi
        orderChangedableList={orderChangedableList}
        searchOrderChangedableList={searchOrderChangedableList}
        filters={filters}
        pnaCode={pnaCode}
    />
}
