import { Project } from "../../../utils/Project"
import { dateToJson } from "../../utils/serializer"

export interface OrderFactor {
    startDate?: Date,
    endDate?: Date,
}

interface OrderFactorJson {
    startDate?: string,
    endDate?: string,
}

export const OrderFactorTojson: Project<OrderFactor, OrderFactorJson> = obj => ({
    startDate: dateToJson(obj.startDate),
    endDate: dateToJson(obj.endDate),
})