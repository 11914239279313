import React, { useEffect, useState } from "react"
import { useComMasterGetActiveCbds, useComMasterGetAllIncoterms, useComMasterGetAllRegion, useGetZoneList } from "../../../services/common/apis/commonMasterApi"
import { FileDownloadFactor } from "../../../services/master/models/FileDownloadFactor"
import { TnmIncoterms } from "../../../services/master/models/TnmIncoterms"
import { TnmRegion } from "../../../services/master/models/TnmRegion"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { MGS010PcUi } from "./MGS010PcUi"

export const MGS010 = ({ path }: { path?: string }) => {
    const [zones, setZones] = useState<{ first: string, second: string }[]>([])
    const [regions, setRegions] = useState<TnmRegion[]>([])
    const [data, setData] = useState<FileDownloadFactor>({} as any)
    const [incoterms, setIncoterms] = useState<TnmIncoterms[]>([])
    const [cbdsList, setCbdsList] = useState<TnvCbds[]>([])


    const getZoneList = useGetZoneList()
    const getRegionList = useComMasterGetAllRegion()
    const getIncotermsList = useComMasterGetAllIncoterms()
    const getCbds = useComMasterGetActiveCbds()
    useEffect(() => {
        getZoneList(undefined, { silent: true }).then(result => {
            setZones(result || [])
        })
        getRegionList(undefined, { silent: true }).then(result => {
            setRegions(result || [])
        })
        getIncotermsList(undefined, { silent: true }).then(result => {
            setIncoterms(result || [])
        })
        getCbds({ types: [] }, { silent: true }).then(result => {
            setCbdsList(result || [])
        })
    }, [getCbds, getIncotermsList, getRegionList, getZoneList])

    return <MGS010PcUi
        path={path!}
        data={data}
        setData={setData}
        zones={zones}
        regions={regions}
        incoterms={incoterms}
        cbdsList={cbdsList}
    />
}