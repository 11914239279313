import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { BusinessType } from "../../master/enums/BusinessType"
import { PageViewEntity, PageViewEntityJson, pageViewEntityFromJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { OrderFrequency } from "../enums/OrderFrequency"
import { OrderType } from "../enums/OrderType"

export type MapaCCHistoryFactor = Partial<Optional>
type MapaCCHistoryFactorJson = Partial<JsonOptional>
export const mapaCCHistoryFactorToJson: Project<MapaCCHistoryFactor, MapaCCHistoryFactorJson> = obj => ({
    ...obj,
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page)
})

export const mapaCCHistoryFactorFromJson: Project<MapaCCHistoryFactorJson, MapaCCHistoryFactor> = json => ({
    ...json,
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page)
})

interface Optional extends Omit<JsonOptional, 'orderFrequency' | 'orderType' | 'businessType' |'page'> {
    orderFrequency: OrderFrequency,
    orderType: OrderType,
    businessType: BusinessType,
    page: PageViewEntity
}


interface JsonOptional {
    soNo: string,
    poNo: string,
    customerIdList: number[],
    orderFrequencyList: number[],
    orderTypeList: number[],
    businessTypeList: number[],
    grouping: string,
    changeVersion: number,
    page: PageViewEntityJson
}
