import { FormattedMessage } from "react-intl"
import { useDispatch } from "react-redux"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { occls010Actions, OrderCalculationType } from "./OCCLS010Slice"

export interface DownloadActionProps {
  type: OrderCalculationType
}

export function DownloadAction(props: DownloadActionProps) {
  const { type } = props
  const dispatch = useDispatch()
  const download = () => {
    dispatch(occls010Actions.download({ type }))
  }

  const downloadWest = () => {
    dispatch(occls010Actions.downloadWestOrderRPAJob({ type }))
  }

  return <DownloadGroupedToolbarAction>
    {onClose => <>
      <GroupedCallbackItem
        label={<FormattedMessage id="download" />}
        access="STCK.OCCLS010.DOWNLOAD"
        callback={download}
        onClose={onClose} />
        {
            type === 'Ehm' ? 
              <GroupedCallbackItem
                label={<FormattedMessage id="downloadWestOrderRpaJob" />}
                access="STCK.OCCLS010.DOWNLOADWEST"
                callback={downloadWest}
                onClose={onClose} /> 
            : <></>
        }
    </>}
  </DownloadGroupedToolbarAction>
}