import React, { useEffect, useState } from "react"
import { useInitOutboundPrioritizationScreen } from "../../../services/delivery/apis/deliveryCustApi"
import { DownloadOubPriorityView } from "../../../services/delivery/models/DownloadOubPriorityView"
import { InitOutboundResult } from "../../../services/delivery/models/InitOutboundResult"
import { LOS020PcUi } from "./LOS020PcUi"

export const LOS020 = () => {
    const [factor, setFactor] = useState<DownloadOubPriorityView>({} as DownloadOubPriorityView)
    const [initData, setInitData] = useState<InitOutboundResult>({} as InitOutboundResult)

    const getInitData = useInitOutboundPrioritizationScreen()
    useEffect(() => {
        getInitData(undefined, { silent: true }).then(result => {
            setInitData(result || {})
        })
    }, [getInitData])

    return <LOS020PcUi
        factor={factor}
        setFactor={setFactor}
        initData={initData}
    />
}