import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useGetGRInvoicePage } from "../../../services/accounting/apis/accountingBuyerApi"
import { BuyerInvoiceTransfer } from "../../../services/accounting/models/BuyerInvoiceTransfer"
import { BuyerInvoiceView, buyerInvoiceViewFromJson, buyerInvoiceViewToJson } from "../../../services/accounting/models/BuyerInvoiceView"
import { useComMasterGetActivePort } from "../../../services/common/apis/commonMasterApi"
import { TnmPort } from "../../../services/master/models/TnmPort"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { AIS010PcUi } from "./AIS010PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }
const searchCachekey = sessionKeys.Filters_AIS010
export const AIS010 = () => {

    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? buyerInvoiceViewFromJson(JSON.parse(filters)) : null
    }, [])

    const [filters, setFilters] = useState<BuyerInvoiceView>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<BuyerInvoiceTransfer[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [portList, setPortList] = useState<TnmPort[]>([])
    const getGrInvoiceList = useGetGRInvoicePage()
    const getPortList = useComMasterGetActivePort()
    useEffect(() => {
        getPortList(undefined, { silent: true }).then(result => {
            setPortList(result || [])
        })
    }, [getPortList])
    const search = useCallback((filters: BuyerInvoiceView) => {
        getGrInvoiceList(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(buyerInvoiceViewToJson(filters)))
        })
    }, [getGrInvoiceList, setData])

    useSearchOnLoad(search, filters)

    return <AIS010PcUi
        filters={filters}
        setFilters={setFilters}
        search={search}
        data={data}
        totalCount={totalCount}
        portList={portList}
    />
}