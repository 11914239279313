import { Project } from "../../../utils/Project"
import { CargoRole } from "../enums/CargoRole"

export interface CustomerAndContract {
    role: CargoRole,
    customerId: number,
    customerCode: string,
    contractId: number,
    contractNo: string,
}

interface CustomerAndContractJson {
    role: number,
    customerId: number,
    customerCode: string,
    contractId: number,
    contractNo: string,
}

export const customerAndContractFromJson: Project<CustomerAndContractJson, CustomerAndContract> = json => ({
    ...json
})
