import { Project } from "../../../../utils/Project"
import { Projects } from "../../../../utils/Projects"
import { dateFromJson } from "../../../utils/deserializer"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../../utils/PageViewEntity"
import { dateToJson } from "../../../utils/serializer"

export type WeeklyRundownDownloadFactor = Omit<WeeklyRundownFactor, 'page'> & Partial<{ customerPartsIdList: number[] }>
type WeeklyRundownDownloadFactorJson = Omit<WeeklyRundownFactorJson, 'page'> & Partial<{ customerPartsIdList: number[] }>
export const weeklyRundownDownloadFactorToJson: Project<WeeklyRundownDownloadFactor, WeeklyRundownDownloadFactorJson> = obj => ({
    ...obj,
    customerPartsIdList: obj.customerPartsIdList,
    lot: dateToJson(obj.lot)
})

export type WeeklyRundownFactor = Required & Partial<Optional>
type WeeklyRundownFactorJson = JsonRequired & Partial<JsonOptional>

export const weeklyRundownFactorToJson: Project<WeeklyRundownFactor, WeeklyRundownFactorJson> = obj => ({
    ...obj,
    lot: dateToJson(obj.lot),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
})

export const weeklyRundownFactorFromJson: Project<WeeklyRundownFactorJson, WeeklyRundownFactor> = json => ({
    ...json,
    lot: dateFromJson(json.lot),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page),
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'lot'> {
    lot: Date,
    page: PageViewEntity,
}

interface JsonRequired {
}

interface JsonOptional {
    initialized: number,
    includeShippingPlan: number,
    baseCarrierDate: number,
    simulateByParts: boolean,
    partsNosStr: string,
    customerCodes: string[],
    dataCustomerCodes: string[],
    customerPartsNoPrefix: string,
    externalRefNoPrefix: string,
    expCountries: string[],
    customerBackNoPrefix: string,
    dataSources: number[],
    partRemarks: string[],
    partTypes: number[],
    stockQtyStr: string,
    stockDaysStr: string,
    boxCountStr: string,
    alerts: string[],
    alerts1: string[],
    alerts2: string[],
    alerts3: string[],
    alerts4: string[],
    alerts5: string[],
    alerts6: string[],
    alerts7: string[],
    alerts8: string[],
    alerts9: string[],
    alerts10: string[],
    alerts11: string[],
    alerts12: string[],
    shortageDateStartStr: string,
    shortageDateEndStr: string,
    nextInboundDateStartStr: string,
    nextInboundDateEndStr: string,
    nextIncomingQtyStr: string,
    buildouts: number[],
    partsName: string,
    supplierIds: number[],
    onShippingQty: number,
    expBalQty: number,
    expWhStockQty: number,
    onShippingQtyStr: string,
    expBalQtyStr: string,
    expWhStockQtyStr: string,
    shortAlerts: string[],
    hasShortage: boolean,
    units: boolean[],
    buildoutMonthStartStr: string,
    buildoutMonthEndStr: string,
    lot: string,
    lotStr: string,
    groupings: string[],
    page: PageViewEntityJson
}