import { Button, Card, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, makeStyles, Typography } from "@material-ui/core"
import { ChevronRight } from "@material-ui/icons"
import DateRangeIcon from '@material-ui/icons/DateRange'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import TimerIcon from '@material-ui/icons/Timer'
import { Calendar } from "@material-ui/pickers"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"
import { ClassNameMap } from "@material-ui/styles"
import { DateItem, EntryItem, Form, Message, StringItem } from "@rithe/form"
import { Arrays, Comparator } from "@rithe/utils"
import moment from "moment"
import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import { FormattedDate, FormattedMessage, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { Access } from "../../components/Access/Access"
import { SectionCard } from "../../components/Card/SectionCard"
import { SectionCardContent } from "../../components/Card/SectionCardContent"
import { Scrollbar } from "../../components/Scrollbar"
import { View } from "../../components/View/View"
import { TodoListTypeMode } from "../../services/common/enums/TodoListTypeMode"
import { useQueryByPage, useUpdateSampleById } from "../../services/delivery/apis/deliveryToDoListApi"
import { TnmUserToListFilter } from "../../services/delivery/models/TnmUserToList"
import { TnmUserToListChangeRequestResult } from "../../services/delivery/models/TnmUserToListChangeRequestResult"
import { TnmUserToListInboundResult } from "../../services/delivery/models/TnmUserToListInboundResult"
import { TnmUserToListOutboundResult } from "../../services/delivery/models/TnmUserToListOutboundResult"
import { TnmUserToListPlaceOrderResult } from "../../services/delivery/models/TnmUserToListPlaceOrderResult"
import { TnmUserToListRequestResult } from "../../services/delivery/models/TnmUserToListRequestResult"
import { TnmUserToListResult } from "../../services/delivery/models/TnmUserToListResult"
import { TnmUserToListShipmentDealyResult } from "../../services/delivery/models/TnmUserToListShipmentDealyResult"
import { TnmUserToListSupplierConfirm } from "../../services/delivery/models/TnmUserToListSupplierConfirm"
import { TnmUserToListView } from "../../services/delivery/models/TnmUserToListView"
import { CodeCategory } from "../../services/master/enums/CodeCategory"
import { TodoListPrority } from "../../services/master/enums/TodoListPrority"
import { TodoListStatus } from "../../services/master/enums/TodoListStatus"
import { TnvCbds } from "../../services/master/models/TnvCbds"
import { placeOrderDetailFactorSerializer } from "../../services/order/models/PlaceOrderDetailFactor"
import { useGetSave, useGetToDoList } from "../../services/others/apis/SchedulesApi"
import { TodoScheduleType } from "../../services/others/enums/TodoScheduleType"
import { SchedulesFactor } from "../../services/others/models/SchedulesFactor"
import { SchedulesResult } from "../../services/others/models/SchedulesResult"
import { dateFromJson } from "../../services/utils/deserializer"
import { dateToJson } from "../../services/utils/serializer"
import { today } from "../../utils/ApplicationUtils"
import { useGetCodeName } from "../../utils/CodeCategoryUtil"
import sessionKeys from "../../utils/sessionKeys"
import { useSetDataIntoSession } from "../../utils/sessionUtil"
import { useFieldChecker } from "../../utils/ValidatorUtils"
import { useApplicationSelector } from "../Application/applicationSlice"
import { DAS010 } from "../dashboard/DAS010/DAS010"
import { DAS012 } from "../dashboard/DAS012/DAS012"
import { DAS013 } from "../dashboard/DAS013/DAS013"
import { DAS014 } from "../dashboard/DAS014/DAS014"
import { DAS015 } from "../dashboard/DAS015/DAS015"
import { FrequentlyUsedandFavouritePageView } from "./FrequentlyUsedandFavouritePageView"

interface HomeProps {
    companyList: TnvCbds[],
    mfaWarnDialog: boolean,
    setMfaWarnDialog: React.Dispatch<React.SetStateAction<boolean>>,
}

export const HomeUiPc = (props: HomeProps) => {
    const { companyList, mfaWarnDialog, setMfaWarnDialog } = props
    return <View noHeader>
        <Grid container direction="row" spacing={2}>
            <Grid item md={9}>
                <FrequentlyUsedandFavouritePageView />
                <DashboardView />
            </Grid>
            <Grid item md={3}>
                <TodoView companyList={companyList} />
            </Grid>
        </Grid>
        <WarnDialog dialog={mfaWarnDialog} setDialog={setMfaWarnDialog} />
    </View>
}

const WarnDialog = memo((props: {
    dialog: boolean,
    setDialog: React.Dispatch<React.SetStateAction<boolean>>,
}) => {
    const {dialog, setDialog} = props
    const navigate = useNavigate()
    const onClose = useCallback(() => {
        setDialog(false)
    }, [setDialog])

    const handleSetupKeyClick = () => {
        navigate(`/user-setting/authenticatorApp`)
    };

    return <Dialog open={dialog} onClose={onClose} maxWidth="md" style={{flex: 1}} BackdropProps={{style: {backgroundColor: 'rgba(0, 0, 0, 0.2)'}}}>
        <DialogTitle style={{textAlign: 'center', marginBottom: -10}}><Typography variant="h3"><FormattedMessage id="mfaHomeWarnDialogTitle" /></Typography></DialogTitle>
        <DialogContent style={{textAlign: 'center'}}>
            <DialogContentText>
                <div style={{marginTop: 10}}>
                    <FormattedMessage id="mfaHomeWarnDialogContent" />
                </div>
                <div style={{marginTop: 10}}>
                    <FormattedMessage
                        id="mfaHomeWarnDialogContentClick"
                        defaultMessage="Please {clickHere} to go to Settings."
                        values={{
                            clickHere: <span onClick={(handleSetupKeyClick)} style={{cursor: "pointer", color: 'blue', textDecoration: 'underline'}}>
                                <FormattedMessage id="mfaHomeWarnClick" />
                            </span>
                        }}
                    />
                </div>
            </DialogContentText>
            <Button
                variant={'outlined'}
                color="secondary"
                onClick={onClose}
                style={{
                    marginTop: 15,
                    width: '30%',
                }}>
                <FormattedMessage id="ok" />
            </Button>
        </DialogContent>
    </Dialog>
})

interface DashboardTabsProps {
    leftPrimary: string,
    leftSecondary: string,
    rightPrimary: string,
    rightSecondary: string,
    index: number
}

const DashboardView = () => {
    const styles = useStyles({ color: '' })
    const intl = useIntl()
    const companyUid = useApplicationSelector(state => state.auth.companyUid)
    const [index, setIndex] = useState<number>(0)
    const dashboardList = useMemo(() => {
        return [
            { leftPrimary: intl.formatMessage({ id: 'pomanagement' }), leftSecondary: "", rightPrimary: "", rightSecondary: "", index: 0 },
            { leftPrimary: intl.formatMessage({ id: 'costsMonitoring' }), leftSecondary: "", rightPrimary: "", rightSecondary: "", index: 1 },
            { leftPrimary: intl.formatMessage({ id: 'vendorAnalysis' }), leftSecondary: "", rightPrimary: "", rightSecondary: "", index: 2 },
            { leftPrimary: intl.formatMessage({ id: 'inventory' }), leftSecondary: "", rightPrimary: "", rightSecondary: "", index: 3 },
            { leftPrimary: intl.formatMessage({ id: 'acctReceivable' }), leftSecondary: "", rightPrimary: "", rightSecondary: "", index: 4 },
        ]
    }, [intl])

    // if company change
    useEffect(() => {
        setIndex(-1)
    }, [companyUid])

    // if index change
    useEffect(() => {
        index < 0 && setIndex(0)
    }, [index, setIndex])

    // render
    return <>
        <Scrollbar style={{ width: '100%' }}>
            <div className={styles.root}>
                {dashboardList.map((data, idx) => {
                    return <DashboardItem key={idx} index={index < 0 ? 0 : index} setIndex={setIndex} data={data}></DashboardItem>
                })}
            </div>
        </Scrollbar>
        {index === 0 && <DAS010 />}
        {index === 1 && <DAS012 />}
        {index === 2 && <DAS013 />}
        {index === 3 && <DAS014 />}
        {index === 4 && <DAS015 />}
    </>
}

const DashboardItem = ({ index, setIndex, data }: {
    index: number,
    setIndex: React.Dispatch<React.SetStateAction<number>>,
    data: DashboardTabsProps
}) => {
    const styles = useStyles({ color: '' })
    const active = index === data.index
    const clickItem = () => {
        !active && setIndex(() => data.index)
    }
    return <div className={active ? styles.itemGridActive : styles.itemGrid} onClick={clickItem}>
        <div className={styles.itemLeft}>
            <Typography variant="h5" color={active ? 'initial' : 'textPrimary'}>{data.leftPrimary}</Typography>
            <Typography variant="h6" color={active ? 'initial' : 'textSecondary'}>{data.leftSecondary} </Typography>
        </div>
    </div>
}

const TodoView = (props: {
    companyList: TnvCbds[]
}) => {
    const { companyList } = props
    const companyUid = useApplicationSelector(state => state.auth.companyUid)
    const [date, setDate] = useState<Date>(today())
    const [toDoList, setToDoList] = useState<SchedulesResult[]>([])
    const getToDoList = useGetToDoList()
    const search = useCallback((date) => {
        const searchDate = moment(date).format('YYYY-MM-DD')
        getToDoList({ searchDate }, { silent: true, serialized: false }).then(result => {
            setToDoList(result ?? [])
        })
    }, [getToDoList])

    // if company change
    useEffect(() => {
        setDate(today())
    }, [companyUid])

    useEffect(() => {
        search(date)
    }, [date, search])

    // render
    return <SectionCard>
        <SectionCardContent>
            <CalendarView date={date} setDate={setDate} />
            <Divider></Divider>
            <ToDoListView toDoList={toDoList} companyList={companyList} search={search} date={date} />
        </SectionCardContent>
    </SectionCard>
}

interface CalendarViewProps {
    date: Date,
    setDate: React.Dispatch<React.SetStateAction<Date>>
}

interface ExtraTodoInfo {
    dueDate: string,
    count: number,
    color: string[]
}

const CalendarView = (props: CalendarViewProps) => {
    const { date, setDate } = props

    const [allTodoList, setAllTodoList] = useState<TnmUserToListResult[]>([])
    const getTodoList = useQueryByPage()
    const getAllTodoList = useCallback((filters: TnmUserToListFilter) => {
        getTodoList(filters, { silent: true, serialized: true }).then((result) => {
            setAllTodoList(result?.data || [])
        })
    }, [getTodoList])
    const companyUid = useApplicationSelector(state => state.auth.companyUid)

    useEffect(() => {
        getAllTodoList({ page: { currentPage: 0, pageSize: 1000 }, status: [TodoListStatus.Pending, TodoListStatus.InProcess] })
    }, [getAllTodoList, companyUid])

    const now = moment(today()).format('DD MMM YYYY')

    const todoDueDate = (todo: TnmUserToListResult) => {
        return todo.dueDate ? moment(todo.dueDate).format('DD MMM YYYY') : moment
            (new Date(todo.remindStartDate!.getFullYear(), todo.remindStartDate!.getMonth(), todo.remindStartDate!.getDate() + 3)).format('DD MMM YYYY')
    }
    const colorList = (urgencyList: number[]) => {
        if (urgencyList.length === 0) {
            return ['none', 'none', 'none']
        } else {
            const colors = urgencyList.sort((a, b) => { return a - b }).map(u => u === TodoListPrority.High ? 'red' : u === TodoListPrority.Medium ? 'orange' : 'blue')
            if (urgencyList.length === 1) {
                return [colors[0], 'none', 'none']
            }
            if (urgencyList.length === 2) {
                return [colors[0], colors[1], 'none']
            }
            if (urgencyList.length >= 3) {
                return [colors[0], colors[1], colors[2]]
            }
        }
    }

    const nowDay = new Date();
    nowDay.setHours(0, 0, 0, 0)
    const startTimestamp = nowDay.getTime();
    nowDay.setHours(23, 59, 59, 999);
    const endTimestamp = nowDay.getTime();
    const displayToDoList = allTodoList.filter(todo => {
        const start = todo.remindStartDate === undefined || todo.remindStartDate === null ? true : todo.remindStartDate!.getTime() <= endTimestamp
        const end = todo.remindEndDate === undefined || todo.remindEndDate === null ? true : todo.remindEndDate!.getTime() >= startTimestamp
        return start && end && todo.remindStartDate
    })

    const dueDate = displayToDoList.map(todo => todoDueDate(todo))
    const uniqDueDate = Arrays.distinct(dueDate)
    const dueDateInfoList = useMemo(() => {
        const dueDateInfo = [] as ExtraTodoInfo[]
        uniqDueDate.map(d => {
            const urgencyList = displayToDoList.filter(todo => todoDueDate(todo) === d).map(todo => todo.urgency!)
            return dueDateInfo.push({ dueDate: d!, count: dueDate.filter(date => date === d).length, color: colorList(urgencyList)! })
        })
        return dueDateInfo
    }, [dueDate, displayToDoList, uniqDueDate])

    const onChange = useCallback((tday: MaterialUiPickersDate) => {
        setDate(date => tday?.toDate() ?? date)
        // search(date)
    }, [setDate])

    const renderDay = useCallback((day: MaterialUiPickersDate, selectedDate, isInCurrentMonth, dayComponent) => {
        const dueDateInfo = dueDateInfoList.filter(d => d.dueDate === day!.format('DD MMM YYYY'))[0]
        const todayUrgencyList = displayToDoList.filter(todo => todoDueDate(todo) <= now).map(todo => todo.urgency!)
        const todayColor = colorList(todayUrgencyList)!
        const todayCount = dueDate.filter(date => date <= now).length
        const dotCount = day!.format('DD MMM YYYY') === now ? todayCount : dueDateInfo ? dueDateInfo.count : 0
        const dotColor = day!.format('DD MMM YYYY') === now ? todayColor : dueDateInfo ? dueDateInfo.color : ['none', 'none', 'none']

        return <div>
            {dayComponent}
            {
                <div style={{
                    marginLeft: '30%',
                    width: '40%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    position: 'relative',
                    bottom: 10
                }}>
                    {isInCurrentMonth && dotCount > 0 && <div style={{ height: 3, width: 3, borderRadius: 1.5, backgroundColor: dotColor[0] }} />}
                    {isInCurrentMonth && dotCount > 1 && <div style={{ height: 3, width: 3, borderRadius: 1.5, backgroundColor: dotColor[1] }} />}
                    {isInCurrentMonth && dotCount > 2 && <div style={{ height: 3, width: 3, borderRadius: 1.5, backgroundColor: dotColor[2] }} />}
                </div>
            }
        </div>



    }, [displayToDoList, dueDate, dueDateInfoList, now])
    return (
        <Calendar date={moment(date)} onChange={onChange} allowKeyboardControl={false} renderDay={renderDay} />
    )
}

const ToDoListView = ({ toDoList, companyList, search, date }: { toDoList: SchedulesResult[], companyList: TnvCbds[], search: (date: any) => void, date: Date }) => {

    const [open, setOpen] = useState<boolean>(false)
    const navigate = useNavigate()
    const toToDoList = () => {
        navigate(`/todolist`)
    }

    const nowDay = new Date();
    nowDay.setHours(0, 0, 0, 0)
    const startTimestamp = nowDay.getTime();
    nowDay.setHours(23, 59, 59, 999);
    const endTimestamp = nowDay.getTime();

    const comparator: Comparator<SchedulesResult> = (a, b) => {
        if (a.importanceFlag !== b.importanceFlag) {
            return b.importanceFlag! > a.importanceFlag! ? 1 : b.importanceFlag! === a.importanceFlag! ? 0 : -1
        }
        return a.urgency! > b.urgency! ? 1 : a.urgency! === b.urgency! ? 0 : -1
    }

    const todoList = Arrays.sort(toDoList.filter(todo => {
        const start = todo.remindStartDate === undefined || todo.remindStartDate === null ? true : todo.remindStartDate!.getTime() <= endTimestamp
        const end = todo.remindEndDate === undefined || todo.remindEndDate === null ? true : todo.remindEndDate!.getTime() >= startTimestamp
        return start && end && todo.remindStartDate
    }), comparator)

    return (
        <>
            <Grid container direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Grid container direction="row" spacing={2} alignItems="center">
                        <Grid item><Typography variant="h5" align="left"><FormattedMessage id="toDoList" /></Typography></Grid>
                        <Grid item><Chip label={todoList.length} /></Grid>
                    </Grid>
                </Grid>
                <Grid item >
                    {/* <IconButton onClick={() => { setOpen(true) }}><AddCircle></AddCircle></IconButton> */}
                    <Access access="LOGI.TDL010.VIEW">
                        <IconButton onClick={toToDoList}><Typography variant="h5">more</Typography><ChevronRight></ChevronRight></IconButton>
                    </Access>

                    <AddSchedulesView open={open} setOpen={setOpen}></AddSchedulesView>
                </Grid>
            </Grid>
            <Scrollbar style={{ width: '100%', height: 420 }}>
                {
                    todoList.map((data, index) => {
                        return <Grid key={index} item style={{ marginTop: "15px" }}>
                            <ToDoDetailView data={data} companyList={companyList} search={search} date={date}></ToDoDetailView>
                        </Grid>
                    })
                }
            </Scrollbar>
        </>
    )
}

const AddSchedulesView = ({ open, setOpen }: {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
}) => {
    const intl = useIntl()
    const save = useGetSave()
    const [factor, setFactor] = useState<SchedulesFactor>({} as SchedulesFactor)
    const navigate = useNavigate()
    const [messages, setMessages] = useState<Message[]>([])
    const chekFields = useMemo(() => (
        {
            title: { labelId: 'title', required: true, length: { max: 200 } },
            description: { labelId: 'description', required: true, length: { max: 500 } },
            scheduleType: { labelId: 'scheduleType', required: false, length: { max: 20 } },
            urgency: { labelId: 'urgency', required: false },
            remindStartDate: { labelId: 'remindStartDate', required: true },
            remindEndDate: { labelId: 'remindEndDate', required: true },
        }
    ), [])
    const filedCheck = useFieldChecker(chekFields, setMessages)
    const scheduleTypeMap = useMemo(() => [{ first: 1, second: 'Order' }, { first: 2, second: 'Inbound' }, { first: 3, second: 'Inventory' }, { first: 4, second: 'Invoice' }].map(m => [m.first, m.second] as [number, string]), [])
    const urgencyMap = useMemo(() => [{ first: 0, second: 'High' }, { first: 1, second: 'Middle' }, { first: 2, second: 'Low' }].map(m => [m.first, m.second] as [number, string]), [])

    const handleSure = () => {
        save(factor).then(result => {
            navigate('/home')
        })
        setOpen(false)
    }

    return <Dialog
        open={open}
        onClose={() => { setOpen(v => !v) }}
        {...DialogLableId(0)}
        keepMounted={false}
    >
        <DialogTitle style={{ cursor: 'move' }} {...DialogLableId(0)}><FormattedMessage id="To DO" /></DialogTitle>
        <DialogContent>
            <Form data={factor} setData={setFactor} labelDisplay="block" helperDisplay="tooltip" maxWidth={500} minWidth={500} columnCount={1} messages={messages} setMessages={setMessages} ensure={filedCheck}>
                <StringItem field="title" label={intl.formatMessage({ id: 'title' })} required={true} />
                <StringItem field="description" label={intl.formatMessage({ id: 'description' })} required={true} />
                <EntryItem field="scheduleType" required label={intl.formatMessage({ id: 'scheduleType' })} entries={scheduleTypeMap} />
                <EntryItem field="urgency" required label={intl.formatMessage({ id: 'urgency' })} entries={urgencyMap} />
                <DateItem field="remindStartDate" label={intl.formatMessage({ id: 'remindStartDate' })} required={true} />
                <DateItem field="remindEndDate" label={intl.formatMessage({ id: 'remindEndDate' })} required={true} />
            </Form>
        </DialogContent>
        <DialogActions>
            <Button autoFocus onClick={() => { setOpen(v => !v) }} color="primary"> <FormattedMessage id={"alert.cancel"}></FormattedMessage>  </Button>
            <Button onClick={handleSure} color="primary"> <FormattedMessage id={"alert.ok"}></FormattedMessage>   </Button>
        </DialogActions>
    </Dialog >

}

const DialogLableId = (index: any) => {
    return {
        id: `draggable-dialog-title-${index}`,
        'aria-labelledby': `draggable-dialog-title-${index}`,
    }
}

const ToDoDetailView = ({ data, companyList, search, date }: { data: SchedulesResult, companyList: TnvCbds[], search: (date: any) => void, date: Date }) => {
    const navigate = useNavigate()
    const setSesion = useSetDataIntoSession()

    const viewClick = useCallback(() => {
        if (!data.url) return
        if (data.url.startsWith('/placecustorder-regular/create-')) {
            setSesion(sessionKeys.Data_ONS011, placeOrderDetailFactorSerializer, data.urlParam ? JSON.parse(data.urlParam) : {})
        }
        navigate(data.url);
    }, [data.url, data.urlParam, navigate, setSesion])
    const updateById = useUpdateSampleById()
    const onclickSubmit = () => {

        let todolistView: TnmUserToListView = {
            todoId: data.todoId,
            importanceFlag: data.importanceFlag === 1 ? 0 : 1,

            remindStartDate: dateToJson(data.remindStartDate),
            remindEndDate: dateToJson(data.remindEndDate),
            status: data.status,
            dueDate: dateToJson(data.dueDate),
            file: data.file,
            toCbdsUid: String(data.toCbdsUid),
            title: data.title,
            task: data.task,
            description: data.description,
            fromUserId: data.fromUserId,
            toUserId: data.toUserId,
            titleParam: data.titleParam,
            descriptionParam: data.descriptionParam,
            todoType: data.todoType,
            scheduleType: data.scheduleType,
            urgency: data.urgency,
            url: data.url,
            urlParam: data.urlParam,
            taskKey: data.taskKey,
            createdBy: data.createdBy,
            createdDate: Number(dateToJson(data.createdDate)),
            taskCategory: data.taskCategory,
            recurrence: data.recurrence,
        }
        updateById({ todolistView: todolistView }, { serialized: true }).then(() => search(date))

    }
    const taskCategory = data.taskCategory
    const overdue = taskCategory !== 1 ? (data.dueDate ? (!moment(moment(data.dueDate).format("YYYY-MM-DD")).isAfter(moment(moment(today()).format("YYYY-MM-DD"))) ? <TimerIcon color="error" /> : undefined) : null) : null
    const color = data.urgency === 1 ? 'red' : data.urgency === 2 ? 'orange' : data.urgency === 3 ? 'blue' : '#ECEEFA'
    const importanceFlag = data.importanceFlag === 1
    const styles = useStyles({ color: color })
    const { getCodeName } = useGetCodeName()
    const intl = useIntl()
    let descriptionArr: string[] = []
    if (data.descriptionParam) {
        descriptionArr = data.descriptionParam.split(",")
    }
    const description = data.description ? intl.formatMessage({ id: data.description }, descriptionArr as Record<number, string>) : ''
    let titleParamArr: string[] = []
    if (data.titleParam) {
        titleParamArr = data.titleParam.split(",")
    }
    if (data.scheduleType === TodoScheduleType.REMINDER_TO_PERFORM_OUTBOUND) {
        const filters = companyList?.filter(f => f.cbdsUid === titleParamArr[2])
        const receiver = filters && filters.length > 0 ? filters[0].cbdsCode : titleParamArr[2]
        titleParamArr[2] = receiver;
    }
    const title = intl.formatMessage({ id: data.title }, titleParamArr as Record<number, string>)
    return <Card>
        <Grid className={styles.header}>
            <Grid className={styles.headerMain}>
                <span className={styles.headerTitle}>
                    <Typography variant="subtitle1" color="textPrimary" className={styles.headerSecondaryTitle}>{title}</Typography>
                    {
                        getDesData(description, getCodeName, companyList, data, styles)
                    }
                    {data.dueDate ? <span><IconButton>{overdue}<DateRangeIcon fontSize="small"></DateRangeIcon></IconButton>{data.dueDate ? <FormattedDate value={data.dueDate} dateStyle="medium" /> : ''}</span> : null}
                </span>
            </Grid>
            <Grid className={styles.headerAction}>
                {data.url ? <IconButton onClick={viewClick}><ChevronRight></ChevronRight></IconButton> : undefined}
                <br />
                <IconButton onClick={onclickSubmit}>{importanceFlag ? <StarIcon style={{ color: 'orange' }}></StarIcon> : <StarBorderIcon></StarBorderIcon>}</IconButton>
            </Grid>
        </Grid>
    </Card>
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        whiteSpace: 'nowrap',
        height: '100%',
        display: 'flex'
    },
    itemGrid: {
        backgroundColor: '#8080802b',
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
        borderRadius: `${theme.spacing(1)}px`,
        marginRight: `${theme.spacing(2)}px`,
        width: 350,
        height: '100%',
        display: 'flex'
    },
    itemGridActive: {
        backgroundColor: '#00000087',
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
        borderRadius: `${theme.spacing(1)}px`,
        color: 'white',
        marginRight: `${theme.spacing(2)}px`,
        width: 350,
        height: '100%',
        display: 'flex'
    },
    itemLeft: {
        maxWidth: 250,
        marginRight: `${theme.spacing(2)}px`,
    },
    itemRight: {
        borderRadius: `${theme.spacing(1)}px`,
        minWidth: 120,
        paddingTop: 3,
        color: 'white',
    },
    header: ({ color }: { color?: string }) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
        backgroundColor: '#ebeefa',
        borderLeft: `10px solid ${color}`,
    }),
    headerMain: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
    },
    headerIcon: {
        width: 36,
        height: 36,
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.getContrastText(theme.palette.grey[600]),
        backgroundColor: theme.palette.primary.main,
        borderRadius: theme.shape.borderRadius,
        marginRight: theme.spacing(3),
    },
    headerTitle: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        alignItems: 'flex-start',
    },
    headerPrimaryTitle: {
        lineHeight: 1.3,
        color: theme.palette.primary.main,
        textTransform: 'uppercase'
    },
    headerSecondaryTitle: {
        lineHeight: 1.3,
    },
    headerAction: {
        '&>*': {
            marginLeft: theme.spacing(1),
        },
    },
}))

const isJSON = (str: string) => {
    if (typeof str == 'string') {
        try {
            var obj = JSON.parse(str)
            if (typeof obj == 'object' && obj) {
                return true
            } else {
                return false
            }
        } catch (e) {
            return false
        }
    }
}
const getDesData = (description: string, getCodeName: (codeCategory: CodeCategory, codeValue: number | undefined) => string | undefined, companyList: TnvCbds[], data: SchedulesResult, styles: ClassNameMap<"header" | "root" | "itemGrid" | "itemGridActive" | "itemLeft" | "itemRight" | "headerMain" | "headerIcon" | "headerTitle" | "headerPrimaryTitle" | "headerSecondaryTitle" | "headerAction">) => {
    const todoType = data.todoType
    const scheduleType = data.scheduleType
    let requestData: TnmUserToListRequestResult = {}
    let supplierConfirmData: TnmUserToListSupplierConfirm = {}
    let placeOrderData: TnmUserToListPlaceOrderResult = {}
    let changeResultData: TnmUserToListChangeRequestResult = {}
    let shipmentDailyData: TnmUserToListShipmentDealyResult[] = []
    let inboundData: TnmUserToListInboundResult[] = []
    let outboundData: TnmUserToListOutboundResult[] = []
    if (description && isJSON(description) && todoType === TodoListTypeMode.SYSTEM) {
        if (scheduleType === TodoScheduleType.REQUEST) {
            requestData = JSON.parse(description)
            return <>Customer Code:{requestData.customerCode}<br />Request No.:{requestData.requestNo}</>
        }
        else if (scheduleType === TodoScheduleType.PLACE_ORDER) {
            placeOrderData = JSON.parse(description)
            return <>Contract No.:{placeOrderData.contractNo}<br />Due Date:<FormattedDate value={dateFromJson(placeOrderData.dueDate)} dateStyle="medium" /></>
        }
        else if (scheduleType === TodoScheduleType.SUPPLIER_TO_CONFIRM_ORDER) {
            supplierConfirmData = JSON.parse(description)
            return <>Customer Code:{supplierConfirmData.customerCode}<br />Order No.:{supplierConfirmData.orderNo}</>
        }
        else if (scheduleType === TodoScheduleType.CHANGE_CANCEL_REQUEST_APPROVAL) {
            changeResultData = JSON.parse(description)
            const isCus = description.includes("customerCode")
            const isSupp = description.includes("supplierCode")
            return <>
                {isCus && <>Customer Code:{changeResultData.customerCode}<br />Request No.:{changeResultData.requestNo}</>}
                {isSupp && <>Supplier Code:{changeResultData.supplierCode}<br />Request No.:{changeResultData.requestNo}</>}
            </>
        }
        else if (scheduleType === TodoScheduleType.SHIPMENT_DELAY) {
            shipmentDailyData = JSON.parse(description)
            return <>{
                shipmentDailyData.map((value, index) => {
                    return <>
                        Outbound No.:{value.outboundNo},Other Amount:{value.otherAmount},Outbound RefNo:{value.outboundRefNo}<br />
                    </>
                })
            }</>
        }
        else if (scheduleType === TodoScheduleType.REMINDER_TO_PERFORM_INBOUND) {
            inboundData = JSON.parse(description)
            return <>
                {
                    inboundData.map((m, index) => {
                        return <>Inbound No.:{m.inboundNo},Inbound Plan Date:<FormattedDate value={dateFromJson(m.inboundPlanDate)} dateStyle="medium" /><br /></>
                    })
                }

            </>

        }
        else if (scheduleType === TodoScheduleType.REMINDER_TO_PERFORM_OUTBOUND) {
            outboundData = JSON.parse(description)
            return <>
                {
                    outboundData.map((m, index) => {
                        const filters = companyList?.filter(f => f.cbdsUid === m.receiver)
                        const receiver = filters && filters.length > 0 ? filters[0].cbdsCode : m.receiver
                        return <>Receiver:{receiver},Sales Order:{m.orderNo},Plan Outbound Date:<FormattedDate value={dateFromJson(m.planOutboundDate)} dateStyle="medium" />,No. of Parts:{m.noOfParts}<br /></>
                    })
                }

            </>
        }

        else {
            return <>{description}</>
        }
    }
    else {
        return <>{description}</>
    }
}



