import { Project } from "../../../utils/Project"

export interface SearchConditionFactor {
    keyword: string,
    limit: number
}
interface SearchConditionFactorJson {
    keyword: string,
    limit: number
}
export const searchConditionFactorFromJson: Project<SearchConditionFactorJson, SearchConditionFactor> = json => ({
    ...json
})
