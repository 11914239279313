import { Project } from "../../../utils/Project"
import { OrderType } from "../enums/OrderType"

export type BChangeRequestListResult = BChangeRequestResultRequired & Partial<BChangeRequestResultOptional>
type BChangeRequestListResultJson = BChangeRequestResultJsonRequired & Partial<BChangeRequestResultJsonOptional>

export const bChangeRequestListResultFromJson: Project<BChangeRequestListResultJson, BChangeRequestListResult> = json => ({
    ...json,
})

export const bChangeRequestListResultToJson: Project<BChangeRequestListResult, BChangeRequestListResultJson> = obj => ({
    ...obj,
})

interface BChangeRequestResultRequired extends BChangeRequestResultJsonRequired {

}

interface BChangeRequestResultOptional extends Omit<BChangeRequestResultJsonOptional, 'orderType'> {
    orderType: OrderType

}

interface BChangeRequestResultJsonRequired {
    poId: number,
    soId: number,
    soNo: string,
    poNo: string,
    groupId: number,
    groupNo: number,
    buId: number,
    buCode: string,
    supplierId: number,
    supplierCode: string,
    customerId: number,
    customerCode: string,
    orderType: number,
    orderStatus: number
}

interface BChangeRequestResultJsonOptional {


}

