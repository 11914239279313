import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import {
  CustomerUsageResult120,
  customerUsageResultDeserializer120
} from "../models/CustomerUsageTransfer120"
import {
  CustomerUsageView120,
  customerUsageViewToJson120
} from "../models/CustomerUsageView120"

export const useGetCustomerUsagePage120 = () => {
  const url = '/smt-api/usage/getUsageHistoryList'
  const payloadProject = useCallback(customerUsageViewToJson120, [])
  const resultProject = useCallback(customerUsageResultDeserializer120, [])
  return usePost<CustomerUsageView120, CustomerUsageResult120>(url, payloadProject, resultProject)
}
