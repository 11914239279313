import { Project } from "@rithe/utils"
import { Projects } from "../../../utils/Projects"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"



export type ProjectFactor = Required & Partial<Optional>

type ProjectFactorJson = JsonRequired & Partial<JsonOptional>

export const projectFactorToJson: Project<ProjectFactor, ProjectFactorJson> = obj => ({
    ...obj,
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page)
})

export const projectFactorFromJson: Project<ProjectFactorJson, ProjectFactor> = json => ({
    ...json,
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page)
})

interface Required extends JsonRequired {

}

interface Optional extends Omit<JsonOptional, 'uploadDateStart' | 'uploadDateEnd'> {
    page: PageViewEntity,
}

interface JsonRequired {

}

interface JsonOptional {
    projectName: string,
    remarks: string,
    statusList: number[],
    page: PageViewEntityJson,
}
