import React, { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { GroupedViewAction } from './GroupedViewAction'

interface DownloadGroupedViewActionProps {
    access?: string,
    disabled?: boolean,
    outlined?: boolean,
    children?: (onClose: () => void) => ReactNode | ReactNode[],
}

export const DownloadGroupedViewAction = (props: DownloadGroupedViewActionProps) => {
    return <GroupedViewAction {...props} title={<FormattedMessage id="download" />} />
}