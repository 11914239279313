import { Tooltip } from "@material-ui/core"
import { AutorenewOutlined } from "@material-ui/icons"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { useState } from "react"
import { FormattedMessage } from "react-intl"
import { useNavigate } from "react-router-dom"
import { Access } from "../../../components/Access/Access"
import { IconButton } from "../../../components/Button/IconButton"
import { OrderCalcStatus } from "../../../services/order/enums/OrderCalcStatus"
import { ConfirmDialog } from "./ConfirmDialog"
import { ListTableRow } from "./ListTable"
import { OrderCalculationType } from "./OCCLS010Slice"

export interface RecalculationRowActionProps extends DataGridRowActionProps {
  type: OrderCalculationType
}

export function RecalculationRowAction(props: RecalculationRowActionProps) {
  const { tableRow, type } = props
  const row = tableRow.row as ListTableRow

  const [open, setOpen] = useState(false)
  const orderCalcNo = row.orderCalcNo!

  const display = row.status === OrderCalcStatus.PENDING_ORDER
    && row.maxOrderVersion === 1
  const navigate = useNavigate()
  if (!display) {
    return null
  } else {
    return <>
      <Access access="STCK.OCCLS010.REVIEW">
        <Tooltip title={<FormattedMessage id="Recalculation" />}>
          <IconButton onClick={() => setOpen(true)}>
            <AutorenewOutlined />
          </IconButton>
        </Tooltip>
      </Access>
      <ConfirmDialog
        open={open}
        onClose={() => setOpen(false)}
        title={"Recalculation"}
        content={"Order has already been calculated. Would you like to re-calculate the order?"}
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Recalculation"}
        onConfirm={() => {
          navigate(type === 'PNA'
            ? `/orderCalculationPNA/modifyCalculate-${encodeURIComponent(orderCalcNo)}`
            : type === 'Ehm'
              ? `/orderCalculationEhm/modifyCalculate-${encodeURIComponent(orderCalcNo)}`
              : `/orderCalculation/modifyCalculate-${encodeURIComponent(orderCalcNo)}`)
        }}
      />
    </>
  }
}