import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { EntriesItem, StringItem } from "@rithe/form"
import { Records } from "@rithe/utils"
import React, { useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { TnmCustomer } from "../../../services/master/models/TnmCustomer"
import { useDownloadMapaCCHistory } from "../../../services/order/apis/OrderDownloadApi"
import { MapaCCHistoryFactor } from "../../../services/order/models/MapaCCHistoryFactor"
import { MapaCCHistoryResult } from "../../../services/order/models/MapaCCHistoryResult"
import { applicationActions } from "../../Application/applicationSlice"
import { RequestType } from "../../../services/order/enums/RequestType"

interface INT060PcUiProps {
    filters: MapaCCHistoryFactor,
    setFilters: React.Dispatch<React.SetStateAction<MapaCCHistoryFactor>>,
    search: (filters: MapaCCHistoryFactor) => void,
    data: MapaCCHistoryResult[],
    totalCount: number,
    customerList: TnmCustomer[],
}

export const INT060PcUi = (props: INT060PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}


const DataTable = ({ filters, setFilters, search, customerList, data }: INT060PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])

    const columns = useMemo(() => [
        { field: 'soNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.soNo' }), width: 180 },
        { field: 'poNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.poNo' }), width: 180 },
        { field: 'customerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'customerCode' }), width: 200 },
        { field: 'orderFrequency', dataTypeName: CodeCategory.OrderFrequency, title: intl.formatMessage({ id: 'field.orderFrequency' }), width: 200 },
        { field: 'dataType', dataTypeName: CodeCategory.RequestType, title: intl.formatMessage({ id: 'field.dataType' }), width: 180 },
        { field: 'orderType', dataTypeName: CodeCategory.OrderType, title: intl.formatMessage({ id: 'field.orderType' }), width: 200 },
        { field: 'businessType', dataTypeName: CodeCategory.BusinessType, title: intl.formatMessage({ id: 'field.businessType' }), width: 200 },
        { field: 'grouping', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.groupNo' }), width: 300 },
        { field: 'remark', dataTypeName: 'string', title: intl.formatMessage({ id: 'remark' }), width: 180 },
        { field: 'changeVersion', dataTypeName: 'string', title: intl.formatMessage({ id: 'changeVersion' }), width: 180 },
    ], [intl])

    const getRowId = useCallback((row: any) => row.changeHistoryId, [])
    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search, customerList }), [filters, setFilters, search, customerList])
    const actionPropsForDownload = useMemo(() => ({ selections, data }), [selections, data])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.RequestStatus} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.RequestType} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OrderType} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.OrderFrequency} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.BusinessType} />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={DownloadAction} actionProps={actionPropsForDownload} />
        <ColumnFreeze />
        <ColumnVisibility ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={54} />
    </DataGrid>
}

const FilterItem = (props: {
    customerList: TnmCustomer[],
    filters: MapaCCHistoryFactor,
    setFilters: React.Dispatch<React.SetStateAction<MapaCCHistoryFactor>>,
    search: (filters: MapaCCHistoryFactor) => void
}) => {
    const { filters, setFilters, customerList, search } = props
    const customerEntries: [number, string][] = useMemo(() => customerList.map(m => [m.customerId, m.customerCode]), [customerList])
    const clear = useCallback((filters: MapaCCHistoryFactor) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: MapaCCHistoryFactor) => {
        return [
            filters.soNo,
            filters.poNo,
            filters.customerIdList,
            filters.orderFrequencyList,
            filters.orderTypeList,
            filters.businessTypeList,
            filters.grouping,
            filters.changeVersion,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()
    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="soNo" label={intl.formatMessage({ id: 'field.soNo' })} />
        <StringItem field="poNo" label={intl.formatMessage({ id: 'field.poNo' })} />
        <EntriesItem field="customerIdList" label={intl.formatMessage({ id: 'field.customerCode' })} entries={customerEntries} />
        <CodesItem field="orderTypeList" label={intl.formatMessage({ id: 'field.orderType' })} code={CodeCategory.OrderType} />
        <CodesItem field="orderFrequencyList" label={intl.formatMessage({ id: 'field.orderFrequency' })} code={CodeCategory.OrderFrequency} />
        <CodesItem field="businessTypeList" label={intl.formatMessage({ id: 'field.businessType' })} code={CodeCategory.BusinessType} />
        <StringItem field="grouping" label={intl.formatMessage({ id: 'field.grouping' })} />
    </FilterToolbarItem>
}

const DownloadAction = ({ selections,data }: { selections: number[], data: MapaCCHistoryResult[] }) => {
    const downloadMapaCCHistory = useDownloadMapaCCHistory()
    const dispatch = useDispatch()
    const download = useCallback(() => {
        if (selections.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'download' }, messages: { code: 'w0002' } }))
            return
        }
        if (selections.length > 1) {
            dispatch(applicationActions.pushError({ title: { code: 'download' }, messages: { code: 'w0003' } }))
            return
        }
        if (selections.length === 1 && data.filter(m => m.changeHistoryId === selections[0])[0].dataType === RequestType.CANCEL) {
            dispatch(applicationActions.pushError({ title: { code: 'download' }, messages: { code: 'w1097' } }))
            return
        }
        downloadMapaCCHistory({ changeHistoryId: selections[0] })
    }, [data, dispatch, downloadMapaCCHistory, selections])

    return <DownloadGroupedToolbarAction access="INT.INT060.DOWNLOAD">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="download" />} callback={download} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}