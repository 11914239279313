import { makeStyles, Typography } from "@material-ui/core"
import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Searching, Sorting, StringTypeProvider, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { StringFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/StringFormatter"
import { Form, StringItem } from "@rithe/form"
import { Arrays, Records } from "@rithe/utils"
import { memo, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CodeItem } from "../../../components/Form/CodeItem"
import { View } from "../../../components/View/View"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { OrderForecastDetailResult, OrderPartsDetailGrid } from "../../../services/order/models/OrderForecastDetailResult"
import { formatDateRange } from "../../../utils/formatDateRange"

interface OPS012PcUiProps {
    data: OrderForecastDetailResult,
    forecastDates: Date[][]
}

export const OPS012PcUi = (props: OPS012PcUiProps) => {
    const { data, forecastDates } = props
    const intl = useIntl()
    return (
        <View>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={1}
                    title={intl.formatMessage({ id: 'basicInfo' })}
                    subtitle={intl.formatMessage({ id: 'basicInfoSubview' })}
                />
                <SectionCardContent>
                    <Step1BasicInfoPanelCard {...props} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={2}
                    title={intl.formatMessage({ id: 'forecastTitle' })}
                    subtitle={intl.formatMessage({ id: 'forecastSubview' })}
                />
                <SectionCardContent>
                    <Step2FirmAndFcTable forecastNum={data.headerInfo?.forecastNum} forecastDates={forecastDates} partsDetailList={data?.partsDetailList} />
                </SectionCardContent>
            </SectionCard>
        </View >
    )
}

interface Step2Props {
    forecastNum?: number,
    partsDetailList?: OrderPartsDetailGrid[],
    forecastDates: Date[][]
}

const Step2FirmAndFcTable = memo((props: Step2Props) => {
    const { forecastNum, partsDetailList, forecastDates } = props
    const intl = useIntl()
    const [order, setOrder] = useState<string[]>([])

    const columns = useMemo(() => {
        const fixedColumns = [
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
            { field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'customerPartsNo' }), width: 250 },
            { field: 'backNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'backNo' }), width: 150 },
            { field: 'salesOrderNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'salesOrderNo' }), width: 200 },
            { field: 'supplierCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierCode' }), width: 180 },
            { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 100 },
            { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 150 },
        ]
        const getForecastqty = (num: number) => (row: any) => row.forecastList[num - 1].forecastQty
        const getLastForecastqty = (num: number) => (row: any) => row.forecastList[num - 1].lastForecastQty
        const getFluctuationRate = (num: number) => {
            return (row: any) => {
                const forecatInfo = row.forecastList[num - 1]
                const forecastQty = forecatInfo.forecastQty
                const lastForecastQty = forecatInfo.lastForecastQty

                return !lastForecastQty || forecastQty === undefined || forecastQty === null ? 'N/A' : (Math.abs(forecastQty - lastForecastQty) * 100 / lastForecastQty).toFixed(0) + '%'
            }
        }
        const getFcCategories = (index: number) => [
            { key: 'fc', value: intl.formatMessage({ id: 'OrderForecast' }) },
            { key: `fc${index}`, value: formatDateRange(intl, forecastDates[index][0], forecastDates[index][1]) },
        ]
        let fcColumns = Arrays.range(0, forecastNum ?? 0).flatMap(index => {
            return index < forecastNum! - 1 ? [
                { field: `fcQty${index + 1}`, dataTypeName: 'number', title: `${intl.formatMessage({ id: 'fcFirstDate' })}${index + 1}`, categories: getFcCategories(index), width: 150, getCellValue: getForecastqty(index + 1) },
                { field: `lastFcQty${index + 2}`, dataTypeName: 'number', title: `${intl.formatMessage({ id: 'fcLastDate' })}${index + 2}`, categories: getFcCategories(index), width: 180, getCellValue: getLastForecastqty(index + 1) },
                { field: `fluctuationRatio${index + 1}`, dataTypeName: 'fulcuation', title: intl.formatMessage({ id: 'fluctuationRatio' }), categories: getFcCategories(index), width: 200, getCellValue: getFluctuationRate(index + 1) }
            ] : [
                { field: `fcQty${index + 1}`, dataTypeName: 'number', title: `${intl.formatMessage({ id: 'fcFirstDate' })}${index + 1}`, categories: getFcCategories(index), width: 250, getCellValue: getForecastqty(index + 1) }
            ]
        })
        // all columns
        return Arrays.concat(fixedColumns, fcColumns)
    }, [intl, forecastNum, forecastDates])

    useEffect(() => {
        setOrder(columns.map(column => column.field))
    }, [columns])

    if (!partsDetailList) {
        return <></>
    }

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <CheckTypeProvider />
            <Data rows={partsDetailList} columns={columns} />
            <ColumnFreeze />
            <ColumnVisibility
                columnSettings={{
                    partsNo: { disableUserControl: true },
                }} ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnOrdering order={order} onOrderChange={setOrder} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
        </DataGrid>
    </div>
})

const CheckFormatter = ({ value }: StringFormatterProps) => {
    const style = useStyles()
    return <div className={style.fulcuationCheck}>
        <Typography variant="body2" >{value}</Typography>
    </div>
}

const CheckTypeProvider = () => {
    return <StringTypeProvider name="fulcuation" Formatter={CheckFormatter} />
}

const Step1BasicInfoPanelCard = memo((props: OPS012PcUiProps) => {

    const { data } = props
    const intl = useIntl()
    const date_first = intl.formatDate(data.headerInfo?.orderFirstDate, { dateStyle: 'medium' })
    const date_last = intl.formatDate(data.headerInfo?.orderLastDate, { dateStyle: 'medium' })
    return (
        <Form readonly data={data.headerInfo} labelDisplay="block" helperDisplay="tooltip" columnCount={2}>
            <StringItem field="orderNo" label={intl.formatMessage({ id: 'purchaseOrderNo' })} />
            { data.headerInfo?.contractNo && <StringItem field="contractNo" label={intl.formatMessage({ id: 'field.contractNo' })} />}
            { data.headerInfo?.groupNo && <StringItem field="groupNo" label={intl.formatMessage({ id: 'field.groupNo' })} />}
            <CodeItem field="orderType" label={intl.formatMessage({ id: 'field.orderType' })} code={CodeCategory.OrderType} />
            <CodeItem field="orderFrequency" label={intl.formatMessage({ id: 'field.orderFrequency' })} code={CodeCategory.OrderFrequency} />
            <StringItem field="orderDateRange" label={intl.formatMessage({ id: 'orderDateRange' })} getValue={() => (date_first + ' ~ ' + date_last)} />
        </Form>
    )
})

const useStyles = makeStyles(theme => ({
    fulcuationCheck: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: '70%',
        paddingRight: theme.spacing(1),
        borderRadius: 5
    },
    default: {},
}))
