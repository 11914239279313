import { Project } from "@rithe/utils";
import { CargoRole } from "../enums/CargoRole";

export interface CargoStatusSettingList {
    companyCode: string,
    companyName: string,
    settings: CargoStatusSetting[],
}

interface CargoStatusSettingListJson {
    companyCode: string,
    companyName: string,
    settings: CargoStatusSettingJson[],
}

export interface CargoStatusSetting {
    cargoStatusSettingId: number,
    cargoStatusSettingName: string,
    customerId: number,
    customerCode: string,
    role: CargoRole,
    contracts: Contract[],
    cargoStatuss: string[],
}

interface CargoStatusSettingJson {
    cargoStatusSettingId: number,
    cargoStatusSettingName: string,
    customerId: number,
    customerCode: string,
    role: number,
    contracts: ContractJson[],
    cargoStatuss: string[],
}

interface Contract {
    contractId: number,
    contractNo: string,
}

interface ContractJson {
    contractId: number,
    contractNo: string,
}

export const cargoStatusSettingFromJson: Project<CargoStatusSettingListJson, CargoStatusSettingList> = data => data
