import React from 'react'
import { FormattedMessage } from 'react-intl'
import { CallbackViewAction } from './CallbackViewAction'

interface ApproveCallbackViewActionProps {
    access?: string,
    disabled?: boolean,
    outlined?: boolean,
    callback: () => void,
}

export const ApproveCallbackViewAction = (props: ApproveCallbackViewActionProps) => {
    return <CallbackViewAction {...props} title={<FormattedMessage id="approve" />} />
}