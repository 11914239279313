import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { dateToJson } from "../../utils/serializer"
import { CoStatus } from "../enums/CoStatus"
import { OrderType } from "../enums/OrderType"


export type PlaceRegularOrderResult = ResultRequired & Partial<ResultOptional>
type PlaceRegularOrderResultson = ResultJsonRequired & Partial<ResultJsonOptional>

export const placeRegularOrderResultDeserializer: Project<PlaceRegularOrderResultson, PlaceRegularOrderResult> = json => ({
    ...json,
    orderFirstDate: dateFromJson(json.orderFirstDate),
    orderLastDate: dateFromJson(json.orderLastDate),
    targetFirstDate: dateFromJson(json.targetFirstDate),
    targetLastDate: dateFromJson(json.targetLastDate),
    partsDetailList: json.partsDetailList.map(partsDetailDeserializer),
})

export const placeRegularOrderResultSerializer: Project<PlaceRegularOrderResult, PlaceRegularOrderResultson> = data => ({
    ...data,
    orderFirstDate: dateToJson(data.orderFirstDate),
    orderLastDate: dateToJson(data.orderLastDate),
    targetFirstDate: dateToJson(data.targetFirstDate),
    targetLastDate: dateToJson(data.targetLastDate),
    partsDetailList: data.partsDetailList.map(partsDetailSerializer),
})

export interface PartsDetail extends Omit<PartsDetailJson, 'forecastList' | 'inbPlanList'> {
    forecastList: ForecastDetail[],
    inbPlanList: InboundPalnDetail[],
}

interface ResultRequired extends Omit<ResultJsonRequired, 'orderFirstDate' | 'orderLastDate' | 'targetFirstDate' | 'targetLastDate' | 'partsDetailList'> {
    orderFirstDate: Date,
    orderLastDate: Date,
    targetFirstDate: Date,
    targetLastDate: Date,
    partsDetailList: PartsDetail[],
}

interface ResultOptional extends Omit<ResultJsonOptional, 'orderType' | 'status'> {
    orderType: OrderType,
    status: CoStatus,
}

interface ResultJsonRequired {
    customerId: number,
    customerCode: string,
    sellerUid: string,
    sellerCode: string,
    customerContractId: number,
    customerContractNo: string,
    contractRouteId: number,
    contractRouteCode: string,
    orderFrequency: number,
    businessType: number,
    orderFirstDate: string,
    orderLastDate: string,
    targetFirstDate: string,
    targetLastDate: string,
    partsDetailList: PartsDetailJson[],
    totalQty: number,
    totalAmount: number,
}

interface ResultJsonOptional {
    contractDescription: string,
    calcOrderType: number,
    forecastNum: number,
    targetLeadtime: number,
    coId: number,
    coNo: string,
    orderType: number,
    orderRefNo: string,
    spotOrderReason: string,
    shippingMode: number,
    remark: string,
    status: number,
    vesion: number,
    paymentTermsCode: string,
    paymentTermsDescription: string,
    deliveryToCode: string,
    receiverDcId: number,
    receiverDcCode: string,
    isConfirm: boolean,
}

interface PartsDetailJson {
    partsId: number,
    partsNo: string,
    customerPartsId: number,
    customerPartsNo: string,
    supplierPartsNo: string,
    supplierId: number,
    supplierCode: string,
    exportCountry: string,
    oldPartsNo: string | null,
    colorCode: string | null,
    customerPartsName: string,
    pairedUnitPartsId: number | null,
    pairedOrderFlag: number | null,
    pairedPartsNo: string | null,
    uomCode: string,
    fluctuationRate: number | null,
    fcFluctuationRate: number | null,
    spq: number,
    orderLot: number,
    poBalance: number | null,
    firmQty: number | null,
    lastOrderForecast1: number | null,
    orderDetailId: number | null,
    coDetailOrigin: string | null,
    fluctReasonFirm: string | null,
    buildoutFlag: number | null,
    orderlotFlag: number | null,
    lastOrderMonth: string | null,
    buyingPrice: number | null,
    buyingCurrency: string | null,
    forecastList: ForecastDetailJson[] | null,
    inbPlanList: InboundPalnDetailJson[] | null,
}

const partsDetailDeserializer: Project<PartsDetailJson, PartsDetail> = json => ({
    ...json,
    forecastList: json.forecastList ? json.forecastList.map(forecastDetailDeserializer) : [],
    inbPlanList: json.inbPlanList ? json.inbPlanList.map(inboundPalnDetailDeserializer) : [],
})

const partsDetailSerializer: Project<PartsDetail, PartsDetailJson> = data => ({
    ...data,
    forecastList: data.forecastList ? data.forecastList.map(forecastDetailSerializer) : [],
    inbPlanList: data.inbPlanList ? data.inbPlanList.map(inboundPalnDetailSerializer) : [],
})

interface ForecastDetail extends Omit<ForecastDetailJson, 'fcFirstDate' | 'fcLastDate' | 'targetFirstDate' | 'targetLastDate'> {
    fcFirstDate: Date,
    fcLastDate: Date,
    targetFirstDate: Date,
    targetLastDate: Date,
}

interface ForecastDetailJson {
    coDetailOrigin: string | null,
    soDetailOrigin: string | null,
    fcFirstDate: string,
    fcLastDate: string,
    targetFirstDate: string,
    targetLastDate: string,
    forecastQty: number,
    lastForecastQty: number,
    fluctuationReason: string
}

const forecastDetailDeserializer: Project<ForecastDetailJson, ForecastDetail> = json => ({
    ...json,
    fcFirstDate: dateFromJson(json.fcFirstDate),
    fcLastDate: dateFromJson(json.fcLastDate),
    targetFirstDate: dateFromJson(json.targetFirstDate),
    targetLastDate: dateFromJson(json.targetLastDate),
})
const forecastDetailSerializer: Project<ForecastDetail, ForecastDetailJson> = data => ({
    ...data,
    fcFirstDate: dateToJson(data.fcFirstDate),
    fcLastDate: dateToJson(data.fcLastDate),
    targetFirstDate: dateToJson(data.targetFirstDate),
    targetLastDate: dateToJson(data.targetLastDate),
})


interface InboundPalnDetail extends Omit<InboundPalnDetailJson, 'crdDate'> {
    crdDate: Date,
}

interface InboundPalnDetailJson {
    coDetailOrigin: string | null,
    soDetailOrigin: string | null,
    crdDate: string,
    crdQty: number | null
}

const inboundPalnDetailDeserializer: Project<InboundPalnDetailJson, InboundPalnDetail> = json => ({
    ...json,
    crdDate: dateFromJson(json.crdDate)
})
const inboundPalnDetailSerializer: Project<InboundPalnDetail, InboundPalnDetailJson> = data => ({
    ...data,
    crdDate: dateToJson(data.crdDate)
})