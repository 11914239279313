import { Dialog, DialogActions, DialogContent, IconButton } from "@material-ui/core"
import { Add, Send } from "@material-ui/icons"
import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { DateItem, DateRangeItem, EntryItem, Form, Message, StringItem, TimeItem } from "@rithe/form"
import { Arrays, Records } from "@rithe/utils"
import { default as React, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useFunctionStore } from "../../../Root"
import { Access } from "../../../components/Access/Access"
import { UploadCallbackCardAction } from "../../../components/Action/UploadCallbackCardAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CallbackRowAction } from "../../../components/DataGrid/rowActions/CallbackRowAction"
import { ConfirmCallbackRowAction } from "../../../components/DataGrid/rowActions/ConfirmCallbackRowAction"
import { ViewRedirectRowAction } from "../../../components/DataGrid/rowActions/ViewRedirectRowAction"
import { CreateRedirectToolbarAction } from "../../../components/DataGrid/toolbarActions/CreateRedirectToolbarAction"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { PrintGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/PrintGroupedToolbarAction"
import { UploadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/UploadGroupedToolbarAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { DarkDialog } from "../../../components/Dialog/DarkDialog"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { useDownloadInbPlan, useDownloadInbResult, useDownloadPNAInboundResultTemplate, useDownloadRootsInboundResult } from "../../../services/delivery/apis/deliveryDownloadApi"
import { useTotallyConfirmInbound } from "../../../services/delivery/apis/deliveryInboundApi"
import { useDownloadInboundPlanReportPDF } from "../../../services/delivery/apis/deliveryOutboundApi"
import { useUploadInbResultForPNA, useUploadInbResultForRoots } from "../../../services/delivery/apis/deliveryUploadApi"
import { InboundDataType } from "../../../services/delivery/enums/InboundDataType"
import { InboundStatus } from "../../../services/delivery/enums/InboundStatus"
import { ConfirmInboundInfo } from "../../../services/delivery/models/ConfirmInboundInfo"
import { DownloadInboundFormResult } from "../../../services/delivery/models/DownloadInboundFormResult"
import { InboundMonitorTransfer } from "../../../services/delivery/models/InboundMonitorTransfer"
import { InboundMonitorView } from "../../../services/delivery/models/InboundMonitorView"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { TnmDistributionCenter } from "../../../services/master/models/TnmDistributionCenter"
import { useFieldChecker, useFormValidater } from "../../../utils/ValidatorUtils"
import { applicationActions } from "../../Application/applicationSlice"

interface LIS010PcUiProps {
    filters: InboundMonitorView,
    setFilters: React.Dispatch<React.SetStateAction<InboundMonitorView>>,
    search: (filters: InboundMonitorView) => void,
    data: InboundMonitorTransfer[],
    totalCount: number,
    dcList: TnmDistributionCenter[],
    manualInboundParts: DownloadInboundFormResult[],
}

export const LIS010PcUi = (props: LIS010PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({ filters, setFilters, data, search, dcList, manualInboundParts }: LIS010PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<string[]>([])

    const columns = useMemo(() => [
        { field: 'latestPlanInboundDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'planInboundDate' }), width: 200 },
        { field: 'planInboundNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'planInboundNo' }), width: 240 },
        { field: 'inboundRefNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'inboundRefNo' }), width: 240 },
        { field: 'inboundType', dataTypeName: CodeCategory.InboundType, title: intl.formatMessage({ id: 'field.inboundType' }), width: 200 },
        { field: 'dataType', dataTypeName: CodeCategory.InboundDataType, title: intl.formatMessage({ id: 'field.dataType' }), width: 200 },
        { field: 'shipper', dataTypeName: 'string', title: intl.formatMessage({ id: 'shipper' }), width: 200 },
        { field: 'shippingMode', dataTypeName: CodeCategory.ShippingMode, title: intl.formatMessage({ id: 'field.shippingMode' }), width: 180 },
        { field: 'port', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.portCode' }), width: 180 },
        { field: 'etd', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.etd' }), width: 150 },
        { field: 'eta', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.eta' }), width: 150 },
        { field: 'outboundNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.outboundNo' }), width: 220 },
        { field: 'outboundDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.outboundDate' }), width: 180 },
        { field: 'outboundTime', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.outboundTime' }), width: 180 },
        // { field: 'containerNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.containerNo' }), width: 220 },
        // { field: 'dcName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.dcName' }), width: 300 },
        // { field: 'supplierName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierName' }), width: 250 },
        { field: 'status', dataTypeName: CodeCategory.InboundStatus, title: intl.formatMessage({ id: 'field.status' }), width: 150 },
    ], [intl])
    const getRowId = useCallback((row: any) => row.planInboundNo, [])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search }), [filters, setFilters, search])
    const actionProps = useMemo(() => ({ selections, data }), [selections, data])
    const downloadActionProps = useMemo(() => ({ selections, data, manualInboundParts }), [selections, data, manualInboundParts])
    const actionPropsForConfirm = useMemo(() => ({ search, filters }), [filters, search])
    const actionPropsForSwitch = useMemo(() => ({ search, filters }), [filters, search])

    const confirmDisplay = useCallback((tableRow: TableRow) => InboundDataType.MANUAL !== tableRow.row?.dataType, [])
    const uploadProps = useMemo(() => ({ search, filters, dcList }), [search, filters, dcList])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.InboundType} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.ShippingMode} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.InboundStatus} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.InboundDataType} />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={CreateAction} />
        <ToolbarActionProvider Action={UploadROOTSAction} actionProps={uploadProps} />
        <ToolbarActionProvider Action={UploadPNAAction} actionProps={uploadProps} />
        <ToolbarActionProvider Action={DownloadAction} actionProps={downloadActionProps} />
        <ToolbarActionProvider Action={PrintAction} actionProps={actionProps} />
        <RowActionProvider name="confirm" Action={ConfirmRowAction} display={confirmDisplay} actionProps={actionPropsForConfirm} />
        <RowActionProvider name="switch" Action={SwitchRowAction} display={() => false} actionProps={actionPropsForSwitch} />
        <RowActionProvider name="view" Action={ViewRowAction} />
        <ColumnFreeze />
        <ColumnVisibility defaultHiddenFields={['dcName', 'supplierName', 'containerNo', 'outboundTime', 'inboundRefNo']} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={122} />
    </DataGrid>
}

const FilterItem = (props: {
    filters: InboundMonitorView,
    setFilters: React.Dispatch<React.SetStateAction<InboundMonitorView>>,
    search: (filters: InboundMonitorView) => void
}) => {
    const { filters, setFilters, search } = props

    const etdGetValue = useCallback((filters: InboundMonitorView) => {
        return [filters.etdStart ?? null, filters.etdEnd ?? null]
    }, [])

    const etdMapValue = useCallback((filters: InboundMonitorView, value: any) => {
        return { ...filters ?? {}, etdStart: value[0], etdEnd: value[1] }
    }, [])

    const etaGetValue = useCallback((filters: InboundMonitorView) => {
        return [filters.etaStart ?? null, filters.etaEnd ?? null]
    }, [])

    const etaMapValue = useCallback((filters: InboundMonitorView, value: any) => {
        return { ...filters ?? {}, etaStart: value[0], etaEnd: value[1] }
    }, [])

    const outboundDateGetValue = useCallback((filters: InboundMonitorView) => {
        return [filters.outboundDateStart ?? null, filters.outboundDateEnd ?? null]
    }, [])

    const outboundDateMapValue = useCallback((filters: InboundMonitorView, value: any) => {
        return { ...filters ?? {}, outboundDateStart: value[0], outboundDateEnd: value[1] }
    }, [])

    const planInboundDateGetValue = useCallback((filters: InboundMonitorView) => {
        return [filters.latestPlanInboundDateStart ?? null, filters.latestPlanInboundDateEnd ?? null]
    }, [])

    const planInboundDateMapValue = useCallback((filters: InboundMonitorView, value: any) => {
        return { ...filters ?? {}, latestPlanInboundDateStart: value[0], latestPlanInboundDateEnd: value[1] }
    }, [])


    const clear = useCallback((filters: InboundMonitorView) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: InboundMonitorView) => {
        return [
            filters.planInboundNo,
            filters.shipper,
            filters.inboundTypeList,
            filters.shippingModeList,
            filters.port,
            filters.outboundNo,
            filters.statusList,
            filters.etdStart || filters.etdEnd,
            filters.etaStart || filters.etaEnd,
            filters.outboundDateStart || filters.outboundDateEnd,
            filters.latestPlanInboundDateStart || filters.latestPlanInboundDateEnd,
            filters.dataTypeList,
        ].filter(f => f !== null && f !== undefined).length
    }, [])

    const intl = useIntl()
    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="planInboundNo" label={intl.formatMessage({ id: 'planInboundNo' })} />
        <StringItem field="shipper" label={intl.formatMessage({ id: 'shipper' })} />
        <CodesItem field="inboundTypeList" label={intl.formatMessage({ id: 'field.inboundType' })} code={CodeCategory.InboundType} />
        <CodesItem field="shippingModeList" label={intl.formatMessage({ id: 'field.shippingMode' })} code={CodeCategory.ShippingMode} />
        <StringItem field="port" label={intl.formatMessage({ id: 'field.portCode' })} />
        <StringItem field="outboundNo" label={intl.formatMessage({ id: 'field.outboundNo' })} />
        <CodesItem field="statusList" label={intl.formatMessage({ id: 'field.status' })} code={CodeCategory.InboundPackageStatus} />
        <DateRangeItem field="etd" label={intl.formatMessage({ id: 'field.etd' })} getValue={etdGetValue} mapValue={etdMapValue} />
        <DateRangeItem field="eta" label={intl.formatMessage({ id: 'field.eta' })} getValue={etaGetValue} mapValue={etaMapValue} />
        <DateRangeItem field="outboundDate" label={intl.formatMessage({ id: 'field.outboundDate' })} getValue={outboundDateGetValue} mapValue={outboundDateMapValue} />
        <DateRangeItem field="planInboundDate" label={intl.formatMessage({ id: 'planInboundDate' })} getValue={planInboundDateGetValue} mapValue={planInboundDateMapValue} />
        <CodesItem field="dataTypeList" label={intl.formatMessage({ id: 'field.dataType' })} code={CodeCategory.InboundDataType} />
    </FilterToolbarItem>
}


const CreateAction = () => {
    return <CreateRedirectToolbarAction access="LOGI.LIS010.CREATEBYDOWNLOADUPLOAD" path="/inbound/create" />
}

const ConfirmRowAction = ({ tableRow, search, filters }: DataGridRowActionProps & { search: (filters: InboundMonitorView) => void, filters: InboundMonitorView }) => {
    const [open, setOpen] = useState<boolean>(false)
    const [factor, setFactor] = useState<ConfirmInboundInfo>({ inbIdList: [tableRow.row?.planInboundId] })
    const [messages, setMessages] = useState<Message[]>([])
    const fields = useMemo(() => getFormCheckFields(), [])
    const filedCheck = useFieldChecker(fields, setMessages)
    const formValidate = useFormValidater(setMessages, fields)
    const dispatch = useDispatch()
    const functionStore = useFunctionStore()
    const intl = useIntl()
    const title = useMemo(() => intl.formatMessage({ id: 'confirm' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const confirmInbound = useTotallyConfirmInbound()
    const confirmMethod = useCallback(() => {
        if (formValidate(factor)) {
            const functionId = functionStore.register(() => {
                setDisabled(true)
                confirmInbound(factor, { serialized: true }).then(() => {
                    setOpen(false)
                    search(filters)
                }).finally(() => {
                    setDisabled(false)
                })
            })
            dispatch(applicationActions.pushWarning({
                title: title,
                messages: { code: 'c0001', args: [title] },
                actions: [{
                    label: 'CANCEL'
                }, {
                    functionId,
                    label: 'CONFIRM',
                }]
            }))
        } else {
            setDisabled(false)
        }
    }, [confirmInbound, dispatch, factor, filters, formValidate, functionStore, search, title])

    if (InboundStatus.COMPLETED === tableRow.row?.status || InboundStatus.CANCELLED === tableRow.row?.status) {
        return <></>
    }

    return <>
        <ConfirmCallbackRowAction tableRow={tableRow} access="LOGI.LIS010.DIRECTCONFIRM" callback={() => setOpen(true)} />
        <DarkDialog open={open} style={{ overflow: 'hidden' }} maxWidth="sm" fullWidth fullScreen={false}>
            <DialogHeader onClose={() => setOpen(false)}><FormattedMessage id="confirm" /></DialogHeader>
            <DialogContent>
                <Form data={factor} setData={setFactor} labelDisplay="block" helperDisplay="tooltip" columnCount={1} minWidth={500} maxWidth={500} messages={messages} setMessages={setMessages} ensure={filedCheck}>
                    <StringItem required field="inboundRefNo" labelWidth={120} label={intl.formatMessage({ id: 'inboundRefNo' })} />
                    <DateItem required field="inboundDate" labelWidth={120} label={intl.formatMessage({ id: 'field.inboundDate' })} />
                    <TimeItem field="inboundTime" labelWidth={120} label={intl.formatMessage({ id: 'field.inboundTime' })} />
                </Form>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={() => setOpen(false)} disabled={disabled} />
                <DialogAction title={<FormattedMessage id="confirm" />} callback={confirmMethod} disabled={disabled} />
            </DialogActions>
        </DarkDialog>
    </>
}

const SwitchRowAction = ({ tableRow, search, filters }: DataGridRowActionProps & { search: (filters: InboundMonitorView) => void, filters: InboundMonitorView }) => {
    const [open, setOpen] = useState<boolean>(false)
    const [factor, setFactor] = useState<ConfirmInboundInfo>({ inbIdList: [tableRow.row?.planInboundId] })
    const [messages, setMessages] = useState<Message[]>([])
    const fields = useMemo(() => getFormCheckFields(), [])
    const filedCheck = useFieldChecker(fields, setMessages)
    const formValidate = useFormValidater(setMessages, fields)
    const dispatch = useDispatch()
    const functionStore = useFunctionStore()
    const intl = useIntl()
    const title = useMemo(() => intl.formatMessage({ id: 'confirm' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const confirmInbound = useTotallyConfirmInbound()
    const confirmMethod = useCallback(() => {
        if (formValidate(factor)) {
            const functionId = functionStore.register(() => {
                setDisabled(true)
                confirmInbound(factor, { serialized: true }).then(() => {
                    setOpen(false)
                    search(filters)
                }).finally(() => {
                    setDisabled(false)
                })
            })
            dispatch(applicationActions.pushWarning({
                title: title,
                messages: { code: 'c0001', args: [title] },
                actions: [{
                    label: 'CANCEL'
                }, {
                    functionId,
                    label: 'CONFIRM',
                }]
            }))
        } else {
            setDisabled(false)
        }
    }, [confirmInbound, dispatch, factor, filters, formValidate, functionStore, search, title])

    if (InboundStatus.COMPLETED === tableRow.row?.status || InboundStatus.CANCELLED === tableRow.row?.status) {
        return <></>
    }

    return <>
        <CallbackRowAction tableRow={tableRow} access="LOGI.LIS010.DIRECTCONFIRM" title={<FormattedMessage id="switch" />} callback={() => setOpen(true)} icon={<Send />} />
        <DarkDialog open={open} style={{ overflow: 'hidden' }} maxWidth="sm" fullWidth fullScreen={false}>
            <DialogHeader onClose={() => setOpen(false)}><FormattedMessage id="confirm" /></DialogHeader>
            <DialogContent>
                <Form data={factor} setData={setFactor} labelDisplay="block" helperDisplay="tooltip" columnCount={1} minWidth={500} maxWidth={500} messages={messages} setMessages={setMessages} ensure={filedCheck}>
                    <StringItem required field="inboundRefNo" labelWidth={120} label={intl.formatMessage({ id: 'inboundRefNo' })} />
                    <DateItem required field="inboundDate" labelWidth={120} label={intl.formatMessage({ id: 'field.inboundDate' })} />
                    <TimeItem field="inboundTime" labelWidth={120} label={intl.formatMessage({ id: 'field.inboundTime' })} />
                    <EntryItem field="nextBuyer" labelWidth={120} label={intl.formatMessage({ id: 'nextBuyer' })} entries={[]} />
                    <EntryItem field="nextBuyer" labelWidth={120} label={intl.formatMessage({ id: 'nextReceiver' })} entries={[]} />
                </Form>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={() => setOpen(false)} disabled={disabled} />
                <DialogAction title={<FormattedMessage id="confirm" />} callback={confirmMethod} disabled={disabled} />
            </DialogActions>
        </DarkDialog>
    </>
}

const DownloadAction = ({ selections, data, manualInboundParts }: { selections: string[], data: InboundMonitorTransfer[], manualInboundParts: DownloadInboundFormResult[] }) => {
    const dispatch = useDispatch()

    const findSelects = useCallback((isManual: boolean) => {
        if (isManual) {
            return data.filter(f => InboundDataType.MANUAL === f.dataType && Arrays.has(selections, f.planInboundNo)).map(m => m.planInboundNo)
        } else {
            return data.filter(f => InboundDataType.MANUAL !== f.dataType && Arrays.has(selections, f.planInboundNo)).map(m => m.planInboundNo)
        }
    }, [selections, data])

    const downloadInboundPlanApi = useDownloadInbPlan()
    const downloadInboundResultApi = useDownloadInbResult()
    // manual
    const downloadRootsInboundResultApi = useDownloadRootsInboundResult()
    const downloadPNAInboundResultTemplateApi = useDownloadPNAInboundResultTemplate()

    const downloadInboundPlan = useCallback(() => {
        if (!selections || selections.length <= 0) {
            dispatch(applicationActions.pushError({ title: { code: 'downloadInbPlan' }, messages: { code: 'w0002' } }))
            return
        }
        const selectionsPlan = findSelects(false)
        if (!selectionsPlan || selectionsPlan.length <= 0) {
            dispatch(applicationActions.pushError({ title: { code: 'downloadInbPlan' }, messages: { code: 'w0929' } }))
            return
        }
        downloadInboundPlanApi({ inbNoList: selectionsPlan })
    }, [dispatch, downloadInboundPlanApi, findSelects, selections])

    const downloadInboundResult = useCallback(() => {
        if (!selections || selections.length <= 0) {
            dispatch(applicationActions.pushError({ title: { code: 'downloadInboundResult' }, messages: { code: 'w0002' } }))
            return
        }

        const selectionsNonManual = findSelects(false)
        const selectionsManual = findSelects(true)
        downloadInboundResultApi({ inbNoList: selectionsNonManual, manualInbNoList: selectionsManual })


    }, [dispatch, downloadInboundResultApi, findSelects, selections])

    const downloadRootsInboundResult = useCallback(() => {
        if (!selections || selections.length <= 0) {
            dispatch(applicationActions.pushError({ title: { code: 'downloadInboundResult' }, messages: { code: 'w0002' } }))
            return
        }
        downloadRootsInboundResultApi({ inboundNos: selections })
    }, [dispatch, downloadRootsInboundResultApi, selections])

    const intl = useIntl()
    const [open, setOpen] = useState<boolean>(false)
    const [manualPartsSelections, setManualPartsSelections] = useState<number[]>([])
    const functionStore = useFunctionStore()
    const openDownloadPopUp = useCallback(() => { setOpen(true) }, [setOpen])
    const handleCancelAction = useCallback(() => { setOpen(false) }, [setOpen])
    const handleDownloadAction = useMemo(() => () => {
        if (manualPartsSelections.length === 0) {
            const functionId = functionStore.register(() => {
                setOpen(false)
                downloadPNAInboundResultTemplateApi({ manualInboundPartsList: [] })
            })
            const functionCancelId = functionStore.register(() => {
                setOpen(false)
            })
            dispatch(applicationActions.pushWarning({
                title: { code: 'downloadPNAInboundResultTemplate' },
                messages: { code: 'c0020' },
                actions: [{
                    functionId: functionCancelId,
                    label: 'CANCEL',
                }, {
                    functionId,
                    label: 'CONFIRM',
                }]
            }))
            return
        }
        setOpen(false)
        downloadPNAInboundResultTemplateApi({ manualInboundPartsList: manualPartsSelections.map(m => manualInboundParts[m]) })
    }, [manualPartsSelections, downloadPNAInboundResultTemplateApi, functionStore, dispatch, manualInboundParts, setOpen])

    const columns = useMemo(() => [
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'partsNo' }), width: 180 },
        { field: 'receiver', dataTypeName: 'string', title: intl.formatMessage({ id: 'receiver' }), width: 160 },
        { field: 'buyer', dataTypeName: 'string', title: intl.formatMessage({ id: 'buyer' }), width: 160 },
        { field: 'customerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerCode' }), width: 220 },
        { field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'unitPartsNo' }), width: 200 },
        { field: 'unitPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsName' }), width: 200 },
        { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'spq' }), width: 120 },
    ], [intl])

    return <>
        <DownloadGroupedToolbarAction access="LOGI.LIS010.DOWNLOAD">
            {onClose => <>
                <GroupedCallbackItem access="LOGI.LIS010.DOWNLOAD" label={<FormattedMessage id="downloadInboundPlan" />} callback={downloadInboundPlan} onClose={onClose} />
                <GroupedCallbackItem access="LOGI.LIS010.DOWNLOAD" label={<FormattedMessage id="downloadInboundResult" />} callback={downloadInboundResult} onClose={onClose} />
                <GroupedCallbackItem access="LOGI.LIS010.DOWNLOAD.PNA" label={<FormattedMessage id="downloadPNAInboundResultTemplate" />} callback={openDownloadPopUp} onClose={onClose} />
                <GroupedCallbackItem access="LOGI.LIS010.DOWNLOAD.ROOTS" label={<FormattedMessage id="downloadRootsInboundResult" />} callback={downloadRootsInboundResult} onClose={onClose} />
            </>}
        </DownloadGroupedToolbarAction>
        <Dialog keepMounted={false} maxWidth={"lg"} open={open} onClose={handleCancelAction} >
            <DialogHeader onClose={handleCancelAction}><FormattedMessage id="downloadInboundForm" /></DialogHeader>
            <DialogContent style={{ height: 600 }}>
                <DataGrid>
                    <ToolbarLayout />
                    <TableLayout Container={FlexScrollbar}>
                        <TableHeaderLayout sticky />
                        <TableBodyLayout />
                    </TableLayout>
                    <PaginationLayout Pagination={Pagination} />
                    <DataTypePreset />
                    <Data rows={manualInboundParts} columns={columns} />
                    <ColumnFreeze />
                    <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
                    <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
                    <Searching ignoreCase Input={SearchInput} />
                    <Sorting />
                    <Filtering />
                    <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
                    <Selection showSelectAll highlightSelectedRow selectedRowIds={manualPartsSelections} onSelectedRowIdsChange={setManualPartsSelections} />
                </DataGrid>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={handleCancelAction} />
                <DialogAction title={<FormattedMessage id="download" />} callback={handleDownloadAction} />
            </DialogActions>
        </Dialog >
    </>
}

const PrintAction = ({ selections, data }: { selections: string[], data: InboundMonitorTransfer[] }) => {
    const dispatch = useDispatch()
    const printApi = useDownloadInboundPlanReportPDF()
    const print = useCallback(() => {
        if (!selections || selections.length <= 0) {
            dispatch(applicationActions.pushError({ title: { code: 'downloadInboundResult' }, messages: { code: 'w0002' } }))
            return
        }
        const selectionsNonManual = data.filter(f => InboundDataType.MANUAL !== f.dataType && Arrays.has(selections, f.planInboundNo)).map(m => m.planInboundNo)
        if (selectionsNonManual.length <= 0) {
            dispatch(applicationActions.pushError({ title: { code: 'Inbound Plan Repor' }, messages: { code: 'w0930' } }))
            return
        }
        printApi({ inboundNos: selectionsNonManual })

    }, [data, dispatch, printApi, selections])

    return <PrintGroupedToolbarAction access="LOGI.LIS010.PRINT">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="print" />} callback={print} onClose={onClose} />
        </>}
    </PrintGroupedToolbarAction>
}

const ViewRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => InboundDataType.MANUAL === tableRow.row?.dataType ? `/inbound/manualDetail-${tableRow.rowId}` : `/inbound/detail-${tableRow.rowId}`, [])
    return <ViewRedirectRowAction tableRow={tableRow} access="LOGI.LIS010.VIEWDETAIL" path={path} />
}

const getFormCheckFields = () => {
    return ({
        inboundRefNo: { labelId: 'inboundRefNo', required: true, length: { max: 30 } },
        inboundDate: { labelId: 'field.inboundDate', required: true },
    })
}

// manul
const UploadROOTSAction = ({ search, filters, dcList }: { search: (filter: InboundMonitorView) => void, filters: InboundMonitorView, dcList: TnmDistributionCenter[] }) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const [dcData, setDCData] = useState<TnmDistributionCenter>()
    const [open, setOpen] = useState<boolean>(false)
    const onClose = useCallback(() => { setOpen(false) }, [])
    const dcMap = useMemo(() => Arrays.distinct(dcList.map(m => [m.dcId, m.dcCode] as [number, string])), [dcList])
    const uploadInboundReportForRoots = useUploadInbResultForRoots();
    const uploadFrom = useCallback((files: FileList | null) => {
        if (files === null) return
        if (!dcData || dcData.dcId === undefined || dcData.dcId === null) {
            dispatch(applicationActions.pushError({ title: { code: 'uploadInboundForm' }, messages: { code: 'w0002' } }))
            return
        }
        onClose()
        uploadInboundReportForRoots({ file: files[0], dcId: dcData.dcId }, { serialized: true }).then(() => search(filters))
    }, [dcData, dispatch, filters, onClose, search, uploadInboundReportForRoots])

    return <Access access="LOGI.LIS010.UPLOAD.ROOTS">
        <IconButton accessKey="" onClick={() => { setOpen(true) }}>{<Add />}</IconButton>
        <DarkDialog keepMounted={false} maxWidth={"lg"} open={open} onClose={onClose} >
            <DialogHeader ><FormattedMessage id="Select DC" /></DialogHeader>
            <DialogContent style={{ height: 200 }}>
                <Form data={dcData} setData={setDCData} labelDisplay="block" helperDisplay="tooltip" columnCount={1}>
                    <EntryItem field="dcId" required label={intl.formatMessage({ id: "rolesRequire.DC" })} entries={dcMap} />
                </Form>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={onClose} />
                <UploadCallbackCardAction callback={uploadFrom} title={<FormattedMessage id="uploadInboundForm" />} />
            </DialogActions>
        </DarkDialog >
    </Access>
}

const UploadPNAAction = ({ search, filters }: { search: (filter: InboundMonitorView) => void, filters: InboundMonitorView }) => {
    const uploadApi = useUploadInbResultForPNA();
    const upload = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            files && uploadApi({ file: files[0] }, { serialized: true }).then(() => search(filters))
        })
    }, [filters, search, uploadApi])

    return <UploadGroupedToolbarAction access="LOGI.LIS010.UPLOAD.PNA">
        {(popupUpload, onClose) => <>
            <GroupedCallbackItem label={<FormattedMessage id="uploadPNAInboundResult" />} callback={() => upload(popupUpload)} onClose={onClose} />
        </>}
    </UploadGroupedToolbarAction>
}
