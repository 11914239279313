import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"
import { ActiveFlag } from "../enums/ActiveFlag"

export type TnmPort = Required & Partial<Optional> & TableEntity
type TnmPortJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const tnmPortFromJson: Project<TnmPortJson, TnmPort> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
    activeFlag: ActiveFlag,
}

interface JsonRequired {
    portId: number,
    portCode: string,
}

interface JsonOptional {
    portName: string,
    regionCode: string,
    activeFlag: number,
}