import { DateItem, EntryItem, Form, StringItem } from "@rithe/form"
import { default as React, useCallback, useMemo } from "react"
import { useIntl } from "react-intl"
import { DownloadCallbackViewAction } from '../../../components/Action/DownloadCallbackViewAction'
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { View } from "../../../components/View/View"
import { useDownloadInbPlan } from "../../../services/managementTool/apis/mtDownloadApis"
import { DownloadInbPlanFactor } from "../../../services/managementTool/models/DownloadInbPlanFactor"
import { IdAndCode } from "../../../services/managementTool/models/IdAndCode"

interface MTS040PcUiProps {
    customerList: IdAndCode[],
    contractRouteList: IdAndCode[],
    contractList: IdAndCode[],
    factor: DownloadInbPlanFactor,
    setFactor: React.Dispatch<React.SetStateAction<DownloadInbPlanFactor>>,
    findContractRouteList: (factor: DownloadInbPlanFactor) => void,
    findContractList: (factor: DownloadInbPlanFactor) => void,
}

export const MTS040PcUi = (props: MTS040PcUiProps) => {
    const intl = useIntl()
    const { customerList, contractRouteList, contractList, factor, setFactor, findContractRouteList, findContractList } = props
    const customersMap = useMemo(() => customerList ? customerList.map(m => [m.id, m.code] as [number, string]) : [], [customerList])
    const contractRouteMap = useMemo(() => contractRouteList ? contractRouteList.map(m => [m.id, m.code] as [number, string]) : [], [contractRouteList])
    const contractMap = useMemo(() => contractList ? contractList.map(m => [m.id, m.code] as [number, string]) : [], [contractList])

    const handleDataChange = useCallback<React.Dispatch<React.SetStateAction<DownloadInbPlanFactor>>>(nextDraftDataFunc => {
        setFactor(factor => {
            const newFactor = typeof nextDraftDataFunc === 'function' ? nextDraftDataFunc(factor) : nextDraftDataFunc
            if (factor.customerId !== newFactor.customerId) {
                const resetFactor = { ...newFactor, contractId: undefined, contractRouteId: undefined }
                findContractRouteList(resetFactor)
                return resetFactor
            } else if (factor.contractRouteId !== newFactor.contractRouteId) {
                const resetFactor = { ...newFactor, contractId: undefined }
                findContractList(resetFactor)
                return resetFactor
            }
            return newFactor
        })
    }, [findContractList, findContractRouteList, setFactor])

    return (
        <View actions={[<DownloadAction factor={factor} />]}>
            <SectionCard>
                <SectionCardContent>
                    <Form data={factor} setData={handleDataChange} labelDisplay="block" helperDisplay="tooltip" columnCount={2} >
                        <EntryItem field="customerId" label={intl.formatMessage({ id: 'field.customerCode' })} entries={customersMap} />
                        <EntryItem field="contractRouteId" label={intl.formatMessage({ id: 'field.contrRouteCode' })} entries={contractRouteMap} />
                        <EntryItem field="contractId" label={intl.formatMessage({ id: 'field.contractNo' })} entries={contractMap} />
                        <StringItem field="partsNo" label={intl.formatMessage({ id: 'field.partsNo' })} />
                        <DateItem field="inbPlanDateStart" label={intl.formatMessage({ id: 'inbPlanDateStart' })} />
                        <DateItem field="inbPlanDateEnd" label={intl.formatMessage({ id: 'inbPlanDateEnd' })} />
                    </Form>
                </SectionCardContent>
            </SectionCard>
        </View>
    )
}

const DownloadAction = ({ factor }: { factor: DownloadInbPlanFactor, }) => {
    const downloadTemplate = useDownloadInbPlan()
    const download = useCallback(() => {
        downloadTemplate(factor)
    }, [downloadTemplate, factor])
    return <DownloadCallbackViewAction access="MT.MTS040.DOWNLOAD" callback={download} />
}