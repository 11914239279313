import { Card, CardContent, DialogActions, DialogContent, useTheme } from "@material-ui/core"
import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { Break, DateItem, Form, Message, NumberItem, StringItem, TimeItem } from "@rithe/form"
import { GridItem } from "@rithe/ui"
import { Arrays, Records } from "@rithe/utils"
import React, { memo, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useFunctionStore } from "../../../Root"
import { CallbackCardAction } from "../../../components/Action/CallbackCardAction"
import { ConfirmCallbackViewAction } from "../../../components/Action/ConfirmCallbackViewAction"
import { SwitchCallbackViewAction } from "../../../components/Action/SwitchCallbackViewAction"
import { BlackSimpleCard } from "../../../components/Card/BlackSimpleCard"
import { BlackSimpleCardContent } from "../../../components/Card/BlackSimpleCardContent"
import { BlackSimpleCardHeader } from "../../../components/Card/BlackSimpleCardHeader"
import { CardPanel } from "../../../components/Card/CardPanel"
import { CardTab } from "../../../components/Card/CardTab"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { TabsCard } from "../../../components/Card/TabsCard"
import { TabsCardContent } from "../../../components/Card/TabsCardContent"
import { TabsCardHeader } from "../../../components/Card/TabsCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { DarkDialog } from "../../../components/Dialog/DarkDialog"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { DottedDivider } from "../../../components/Divider/DottedDivider"
import { CodeItem } from "../../../components/Form/CodeItem"
import { SectionTitle } from "../../../components/SectionTitle/SectionTitle"
import { View } from "../../../components/View/View"
import { useTotallyConfirmInbound } from "../../../services/delivery/apis/deliveryInboundApi"
import { InboundStatus } from "../../../services/delivery/enums/InboundStatus"
import { ConfirmInboundInfo } from "../../../services/delivery/models/ConfirmInboundInfo"
import { ContainerInfo, InboundMonitorInfo, InboundPartsInfo, InnerPackageInfo, OuterPackageInfo } from "../../../services/delivery/models/InboundMonitorInfo"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { useFieldChecker, useFormValidater } from "../../../utils/ValidatorUtils"
import { applicationActions } from "../../Application/applicationSlice"

export const LIS011PcUi = (props: {
    inboundNo: string,
    inboundInfo: InboundMonitorInfo,
    search: () => void
}) => {
    const { inboundNo, inboundInfo, search } = props
    const intl = useIntl()

    const [gridMode, setGridMode] = useState<boolean>(true)
    const [status, setStatus] = useState<DialogStatus>({ open: false, conditions: { inboundNo: inboundNo, containerNo: null, outerPackageNo: null, innerPackageNo: null } })

    return <View actions={[
        <ConfirmInboundAction inboundInfo={inboundInfo} setStatus={setStatus} />,
        <SwitchInboundAction inboundInfo={inboundInfo} setStatus={setStatus} />
    ]}>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={1}
                title={intl.formatMessage({ id: 'viewInboundBasic' })}
                subtitle={intl.formatMessage({ id: 'viewInboundBasicSub' })}
            />
            <SectionCardContent>
                <InboundBasicView inboundInfo={inboundInfo} />
                <ConfirmDetailDialog search={search} status={status} setStatus={setStatus} />
            </SectionCardContent>
        </SectionCard>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={2}
                title={intl.formatMessage({ id: 'viewOutboundParts' })}
                subtitle={intl.formatMessage({ id: 'viewOutboundPartsSub' })}
                actions={[<ChangeModeAction gridMode={gridMode} setGridMode={setGridMode} inboundInfo={inboundInfo}/>]}
            />
            <SectionCardContent>
                {gridMode ? <ContainerPackageGridView inboundInfo={inboundInfo} />
                    : <ContainerPackageView inboundInfo={inboundInfo} setStatus={setStatus} />}
            </SectionCardContent>
        </SectionCard>
    </View>
}

const ConfirmInboundAction = ({ inboundInfo, setStatus }: {
    inboundInfo: InboundMonitorInfo,
    setStatus: React.Dispatch<React.SetStateAction<DialogStatus>>,
}) => {

    const onClick = useCallback(() => {
        setStatus({ open: true, conditions: { inboundNo: inboundInfo.inboundNo, containerNo: null, outerPackageNo: null, innerPackageNo: null } })
    }, [inboundInfo.inboundNo, setStatus])
    if (InboundStatus.COMPLETED === inboundInfo.status || InboundStatus.CANCELLED === inboundInfo.status) {
        return <></>
    }
    return <ConfirmCallbackViewAction callback={onClick} />
}

const SwitchInboundAction = ({ inboundInfo, setStatus }: {
    inboundInfo: InboundMonitorInfo,
    setStatus: React.Dispatch<React.SetStateAction<DialogStatus>>,
}) => {

    const onClick = useCallback(() => {
        setStatus({ open: true, conditions: { inboundNo: inboundInfo.inboundNo, containerNo: null, outerPackageNo: null, innerPackageNo: null } })
    }, [inboundInfo.inboundNo, setStatus])
    if (InboundStatus.COMPLETED === inboundInfo.status || InboundStatus.CANCELLED === inboundInfo.status) {
        return <></>
    }
    return <SwitchCallbackViewAction access="AKSD.DDDD.DDDL" outlined callback={onClick} />
}


const InboundBasicView = ({ inboundInfo }: {
    inboundInfo: InboundMonitorInfo,
}) => {
    const intl = useIntl()
    return <Form data={inboundInfo} readonly labelDisplay="block" helperDisplay="tooltip" >
        <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="TripInfo" /></SectionTitle></GridItem>
        <StringItem field="inboundNo" label={intl.formatMessage({ id: 'field.inboundNo' })} />
        <StringItem field="shipper" label={intl.formatMessage({ id: 'shipper' })} />
        <DateItem field="orgInboundPlanDate" label={intl.formatMessage({ id: 'field.orgInboundPlanDate' })}></DateItem>

        <DateItem field="etd" label={intl.formatMessage({ id: 'fullEtd' })}></DateItem>
        <DateItem field="eta" label={intl.formatMessage({ id: 'fullEta' })}></DateItem>
        <DateItem field="latestInboundPlanDate" label={intl.formatMessage({ id: 'field.latestInboundPlanDate' })}></DateItem>

        <StringItem field="outboundNo" label={intl.formatMessage({ id: 'field.outboundNo' })} />
        <StringItem field="outboundDatTime" label={intl.formatMessage({ id: 'outboundDateTime' })} getValue={(data: any) => {
            return (data.outboundDate ? intl.formatDate(data.outboundDate, { dateStyle: 'medium' }) : '')
                + (data.outboundTime ? '  ' + data.outboundTime : '')
        }} />
        <DateItem field="actualInboundPlanDate" label={intl.formatMessage({ id: 'field.actualInboundPlanDate' })}></DateItem>


        <StringItem field="voyageNo" label={intl.formatMessage({ id: 'field.voyageNo' })} />
        <StringItem field="vesselName" label={intl.formatMessage({ id: 'field.vesselName' })} />
        <DateItem field="completedDate" label={intl.formatMessage({ id: 'field.completedDate' })}></DateItem>

        <CodeItem field="shippingMode" label={intl.formatMessage({ id: 'field.shippingMode' })} code={CodeCategory.ShippingMode} />
        <CodeItem field="status" label={intl.formatMessage({ id: 'field.status' })} code={CodeCategory.InboundStatus} />
    </Form>
}

const ChangeModeAction = ({ gridMode, setGridMode, inboundInfo }: {
    gridMode: boolean,
    setGridMode: React.Dispatch<React.SetStateAction<boolean>>,
    inboundInfo: InboundMonitorInfo,
}) => {

    const [hugeData, setHugeData] = useState<boolean>(false)
    useMemo(() => {
        inboundInfo.containers?.forEach(container => {
            setHugeData(pre => pre ? pre : container.outerPackageList != null && container.outerPackageList?.length > 30)
        })
    }, [inboundInfo.containers])

    const onChangeMode = useCallback(() => {
        setGridMode(mode => !mode)
    }, [setGridMode])

    return hugeData ? <></> : <CallbackCardAction callback={onChangeMode} title={gridMode ? <FormattedMessage id="changeToTreeMode" /> : <FormattedMessage id="changeToGridMode" />} />
}

const ContainerPackageView = ({ inboundInfo, setStatus }: {
    inboundInfo: InboundMonitorInfo,
    setStatus: React.Dispatch<React.SetStateAction<DialogStatus>>,
}) => {

    const theme = useTheme()
    const { containers } = inboundInfo

    return <>
        <PartsSummaryView containers={containers ?? []} />
        <ContainerPackagesSummaryView containers={containers ?? []} />
        <DottedDivider style={{ marginTop: theme.spacing(4), marginBottom: theme.spacing(4) }} />
        <ContainerPackagesTabView containers={containers ?? []} setStatus={setStatus} />
    </>
}

const PartsSummaryView = memo(({ containers }: {
    containers: ContainerInfo[]
}) => {

    const intl = useIntl()
    const theme = useTheme()
    const partsSummaryList = useMemo(() => {
        const partsList = containers.flatMap(f => f.outerPackageList ?? []).flatMap(f => f.innerPackageList ?? []).flatMap(f => f.partsList ?? [])
        const partsNoList = Arrays.distinct(partsList.map(m => m.partsNo))
        return partsNoList.map((partsNo, index) => {
            const filterList = partsList.filter(f => f.partsNo === partsNo)
            return {
                rowNo: index + 1,
                partsNo: partsNo,
                partsName: filterList[0].buyerPartsName,
                qty: filterList.map(m => m.qty ?? 0).reduce((a, b) => a + b, 0),
                inboundQty: filterList.map(m => m.inboundedQty ?? 0).reduce((a, b) => a + b, 0),
            }
        })
    }, [containers])
    const columns = useMemo(() => [
        { field: 'rowNo', dataTypeName: 'number', title: intl.formatMessage({ id: 'rowNo' }), width: 150 },
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 320 },
        { field: 'partsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsName' }), width: 320 },
        { field: 'qty', dataTypeName: 'number', title: intl.formatMessage({ id: 'totalQuantity' }), width: 250 },
        { field: 'inboundQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'totalInboundQuantity' }), width: 250 }
    ], [intl])

    return <div style={{ width: '100%', height: 300, marginBottom: theme.spacing(2) }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <Data rows={partsSummaryList} columns={columns} />
            <ColumnFreeze />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Sorting />
            <Filtering />
            <Paging defaultPageSize={15} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        </DataGrid>
    </div>
})

const ContainerPackagesSummaryView = memo(({ containers }: {
    containers: ContainerInfo[]
}) => {
    const intl = useIntl()
    const containerPackageSummary = useMemo(() => {
        const packageList = containers.flatMap(m => m.outerPackageList ?? [])
        return {
            numOfContianer: containers.length,
            contentOfContainer: Arrays.distinct(containers.map(m => m.commodityType ?? '')).join(','),
            typeOfContainer: Arrays.distinct(containers.map(m => m.containerType ?? '')).join(','),
            totalGWOfContianer: containers.map(m => m.grossWeight ?? 0).reduce((a, b) => a + b, 0),
            numOfPackage: packageList.length,
            totalM3OfPackage: packageList.map(m => m.m3 ?? 0).reduce((a, b) => a + b, 0),
            totalNWOfPackage: packageList.map(m => m.netWeight ?? 0).reduce((a, b) => a + b, 0),
            totalGWOfPackage: packageList.map(m => m.grossWeight ?? 0).reduce((a, b) => a + b, 0),
        }
    }, [containers])

    return <Card>
        <CardContent>
            <Form data={containerPackageSummary} labelDisplay="block" helperDisplay="tooltip" columnCount={2}>
                <GridItem columnSpan={2}><SectionTitle size="small">{intl.formatMessage({ id: 'containerInfo' })}</SectionTitle></GridItem>
                <NumberItem field="numOfContianer" readonly label={intl.formatMessage({ id: 'numOfContianer' })} />
                <StringItem field="contentOfContainer" readonly label={intl.formatMessage({ id: 'contentOfContainer' })} />
                <StringItem field="typeOfContainer" readonly label={intl.formatMessage({ id: 'typeOfContainer' })} />
                <NumberItem field="totalGWOfContianer" readonly label={intl.formatMessage({ id: 'totalGWOfContianer' })} />

                <GridItem columnSpan={2}><SectionTitle size="middle">{intl.formatMessage({ id: 'packageInfo' })} </SectionTitle></GridItem>
                <NumberItem field="numOfPackage" readonly label={intl.formatMessage({ id: 'numOfPackage' })} />
                <NumberItem field="totalM3OfPackage" readonly label={intl.formatMessage({ id: 'totalM3OfPackage' })} />
                <NumberItem field="totalNWOfPackage" readonly label={intl.formatMessage({ id: 'totalNWOfPackage' })} />
                <NumberItem field="totalGWOfPackage" readonly label={intl.formatMessage({ id: 'totalGWOfPackage' })} />
            </Form>
        </CardContent>
    </Card>
})

const ContainerPackagesTabView = memo(({ containers, setStatus }: {
    containers: ContainerInfo[],
    setStatus: React.Dispatch<React.SetStateAction<DialogStatus>>,
}) => {

    const [containerIndex, setContainerIndex] = useState<number>(0)

    const onSelectedValueChange = useCallback((value: string | number) => {
        setContainerIndex(value as number)
    }, [setContainerIndex])

    return <TabsCard>
        <TabsCardHeader
            selectedValue={containerIndex}
            onSelectedValueChange={onSelectedValueChange}
            actions={[
                <ConfirmContainerAction containers={containers} containerIndex={containerIndex} setStatus={setStatus} />,
                <SwitchContainerAction containers={containers} containerIndex={containerIndex} setStatus={setStatus} />
            ]}
        >
            {containers.map((m, index) => <CardTab title={'Container ' + (index + 1)} subtitle={m.containerNo} value={index} />)}
        </TabsCardHeader>
        <TabsCardContent>
            <CardPanel value={containerIndex}>
                <ContainerPackageCardView container={containers[containerIndex]} setStatus={setStatus} />
            </CardPanel>
        </TabsCardContent>
    </TabsCard>
})

const ConfirmContainerAction = ({ containers, containerIndex, setStatus }: {
    containers: ContainerInfo[],
    containerIndex: number,
    setStatus: React.Dispatch<React.SetStateAction<DialogStatus>>,
}) => {
    const container = containers[containerIndex]
    const onClick = useCallback(() => {
        setStatus(status => ({ open: true, conditions: { ...status.conditions, containerNo: container.containerNo ?? '', outerPackageNo: null, innerPackageNo: null } }))
    }, [container.containerNo, setStatus])

    if (InboundStatus.COMPLETED === container.status || InboundStatus.CANCELLED === container.status) {
        return <></>
    }
    return <CallbackCardAction callback={onClick} title={<FormattedMessage id="confirm" />} />
}


const SwitchContainerAction = ({ containers, containerIndex, setStatus }: {
    containers: ContainerInfo[],
    containerIndex: number,
    setStatus: React.Dispatch<React.SetStateAction<DialogStatus>>,
}) => {
    const container = containers[containerIndex]
    const onClick = useCallback(() => {
        setStatus(status => ({ open: true, conditions: { ...status.conditions, containerNo: container.containerNo ?? '', outerPackageNo: null, innerPackageNo: null } }))
    }, [container.containerNo, setStatus])

    if (InboundStatus.COMPLETED === container.status || InboundStatus.CANCELLED === container.status) {
        return <></>
    }
    return <CallbackCardAction outlined callback={onClick} title={<FormattedMessage id="switch" />} />
}

const ContainerPackageCardView = memo(({ container, setStatus }: {
    container: ContainerInfo,
    setStatus: React.Dispatch<React.SetStateAction<DialogStatus>>,
}) => {

    const { outerPackageList, containerNo } = container

    return <>
        <ContainerInfoView container={container} />
        <OutPackageListView containerNo={containerNo ?? ''} outerPackageList={outerPackageList ?? []} setStatus={setStatus} />
    </>
})

const ContainerInfoView = memo(({ container }: {
    container: ContainerInfo,
}) => {

    const intl = useIntl()

    const getNumberOfCarton = useCallback(() => {
        return container.outerPackageList?.length ?? 0
    }, [container.outerPackageList?.length])

    const getPartsQty = useCallback(() => {
        const partsList = (container.outerPackageList ?? []).flatMap(m => m.innerPackageList ?? []).flatMap(m => m.partsList)
        return partsList.map(m => m.qty ?? 0).reduce((a, b) => a + b, 0)
    }, [container.outerPackageList])

    return <Form readonly data={container} labelDisplay="block" helperDisplay="tooltip" >
        <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="containerInfo" /></SectionTitle></GridItem>
        <StringItem field="containerType" label={<FormattedMessage id='typeOfContainer' />} />
        <NumberItem field="numberOfCarton" readonly label={<FormattedMessage id='numberOfCarton' />} getValue={getNumberOfCarton} />
        <StringItem field="commodityType" label={<FormattedMessage id='typeOfCommodities' />} />

        <NumberItem field="qty" readonly label={<FormattedMessage id='quantity' />} getValue={getPartsQty} />
        <StringItem field="containerNo" label={<FormattedMessage id='field.containerNo' />} />
        <StringItem field="sealNo" label={<FormattedMessage id='field.sealNo' />} />

        <NumberItem field="m3" label={<FormattedMessage id='containerM3' />} />
        <NumberItem field="netWeight" label={<FormattedMessage id='containerNW' />} />
        <NumberItem field="grossWeight" label={<FormattedMessage id='containerGW' />} />

        <CodeItem field="impCcStatus" readonly label={intl.formatMessage({ id: 'field.impCcStatus' })} code={CodeCategory.ImpCcStatus} />
        <CodeItem field="status" readonly label={intl.formatMessage({ id: 'status' })} code={CodeCategory.InboundStatus} />
    </Form>
})

const OutPackageListView = memo(({ containerNo, outerPackageList, setStatus }: {
    containerNo: string,
    outerPackageList: OuterPackageInfo[],
    setStatus: React.Dispatch<React.SetStateAction<DialogStatus>>,
}) => {

    const theme = useTheme()
    return <div style={{ width: '100%', paddingTop: theme.spacing(2) }}>
        <GridItem><SectionTitle size="middle"><FormattedMessage id="withInContainerOuterPackage" /></SectionTitle></GridItem>
        {outerPackageList?.map((outerPackage, index) => {
            return <GridItem>
                <OutPackageInfoCardView
                    containerNo={containerNo}
                    outerPackageIndex={index}
                    outerPackage={outerPackage}
                    setStatus={setStatus}
                />
            </GridItem>
        })}
    </div>
})

const OutPackageInfoCardView = memo(({ containerNo, outerPackage, outerPackageIndex, setStatus }: {
    containerNo: string,
    outerPackage: OuterPackageInfo,
    outerPackageIndex: number,
    setStatus: React.Dispatch<React.SetStateAction<DialogStatus>>,
}) => {

    const { outerPackageNo, innerPackageList } = outerPackage
    const hasBlank = useMemo(() => innerPackageList.some(f => f.boxNo === ''), [innerPackageList])
    return <BlackSimpleCard allowCollapse defaultCollapse >
        <BlackSimpleCardHeader title={'Outer Package ' + (outerPackageIndex + 1) + ': ' + (outerPackageNo && outerPackageNo !== '' ? outerPackageNo : '')}
            actions={[
                <ConfirmOuterPackageAction containerNo={containerNo} outerPackage={outerPackage} setStatus={setStatus} />,
                <SwitchOuterPackageAction containerNo={containerNo} outerPackage={outerPackage} setStatus={setStatus} />
            ]} />
        <BlackSimpleCardContent>
            <OutPackageBasicInfoView outerPackage={outerPackage} />
            {hasBlank && <WithInOutboundPackagePartsView innerPackageList={innerPackageList} />}
            <WithInOutboundPackageInnerPackageView containerNo={containerNo} outerPackageNo={outerPackageNo} innerPackageList={innerPackageList} setStatus={setStatus} />
        </BlackSimpleCardContent>
    </BlackSimpleCard>
})

const ConfirmOuterPackageAction = ({ containerNo, outerPackage, setStatus }: {
    containerNo: string,
    outerPackage: OuterPackageInfo,
    setStatus: React.Dispatch<React.SetStateAction<DialogStatus>>,
}) => {
    const onClick = useCallback(() => {
        setStatus(status => ({ open: true, conditions: { ...status.conditions, containerNo: containerNo, outerPackageNo: outerPackage.outerPackageNo, innerPackageNo: null } }))
    }, [containerNo, outerPackage.outerPackageNo, setStatus])

    if (InboundStatus.COMPLETED === outerPackage.status || InboundStatus.CANCELLED === outerPackage.status) {
        return <></>
    }
    return <CallbackCardAction callback={onClick} title={<FormattedMessage id="confirm" />} />
}

const SwitchOuterPackageAction = ({ containerNo, outerPackage, setStatus }: {
    containerNo: string,
    outerPackage: OuterPackageInfo,
    setStatus: React.Dispatch<React.SetStateAction<DialogStatus>>,
}) => {
    const onClick = useCallback(() => {
        setStatus(status => ({ open: true, conditions: { ...status.conditions, containerNo: containerNo, outerPackageNo: outerPackage.outerPackageNo, innerPackageNo: null } }))
    }, [containerNo, outerPackage.outerPackageNo, setStatus])

    if (InboundStatus.COMPLETED === outerPackage.status || InboundStatus.CANCELLED === outerPackage.status) {
        return <></>
    }
    return <CallbackCardAction outlined callback={onClick} title={<FormattedMessage id="switch" />} />
}

const OutPackageBasicInfoView = memo(({ outerPackage }: {
    outerPackage: OuterPackageInfo,
}) => {

    const intl = useIntl()

    return <Form readonly data={outerPackage} labelDisplay="block" helperDisplay="tooltip" >
        <StringItem field="outerPackageNo" label={<FormattedMessage id='outerPackageNo' />} />
        <StringItem field="palletNo" label={<FormattedMessage id='palletNo' />} />
        <StringItem field="outerPackageType" label={<FormattedMessage id='outerPackageType' />} />
        <DateItem field="productionDate" label={<FormattedMessage id='productionDate' />} />
        <CodeItem field="status" readonly label={intl.formatMessage({ id: 'status' })} code={CodeCategory.InboundPackageStatus} />

        <NumberItem field="m3" label={<FormattedMessage id='packageM3' />} />
        <NumberItem field="netWeight" label={<FormattedMessage id='packageNW' />} />
        <NumberItem field="grossWeight" label={<FormattedMessage id='packageGW' />} />
    </Form>
})

const WithInOutboundPackagePartsView = memo(({ innerPackageList }: {
    innerPackageList: InnerPackageInfo[],
}) => {

    const theme = useTheme()
    const partsList = useMemo(() => {
        const innerPackage = innerPackageList.find(f => f.boxNo === '')
        return innerPackage?.partsList ?? []
    }, [innerPackageList])

    return <div style={{ width: '100%', padding: theme.spacing(2, 0) }}>
        <GridItem><SectionTitle size="middle"><FormattedMessage id="withOuterPackageParts" /></SectionTitle></GridItem>
        <GridItem >
            <PartsInfoListView partsList={partsList} />
        </GridItem>
    </div>
})

const WithInOutboundPackageInnerPackageView = memo(({ containerNo, outerPackageNo, innerPackageList, setStatus }: {
    containerNo: string,
    outerPackageNo: string,
    innerPackageList: InnerPackageInfo[],
    setStatus: React.Dispatch<React.SetStateAction<DialogStatus>>,
}) => {

    const theme = useTheme()
    const hasBlank = useMemo(() => innerPackageList.some(f => f.boxNo === ''), [innerPackageList])

    return <div style={{ width: '100%', padding: theme.spacing(2, 0) }}>
        <GridItem><SectionTitle size="middle"><FormattedMessage id="withOuterPackageInnerPackage" /></SectionTitle></GridItem>
        {innerPackageList.map((innerPackage, index) => {
            return innerPackage.boxNo === '' ? <></> : <GridItem >
                <InnerPackageInfoCardView containerNo={containerNo} outerPackageNo={outerPackageNo} innerPackage={innerPackage} hasBlank={hasBlank} innerPackageIndex={index} setStatus={setStatus} />
            </GridItem>
        })}
    </div>
})

const InnerPackageInfoCardView = memo(({ containerNo, outerPackageNo, innerPackage, hasBlank, innerPackageIndex, setStatus }: {
    containerNo: string,
    outerPackageNo: string,
    innerPackage: InnerPackageInfo,
    hasBlank: boolean,
    innerPackageIndex: number,
    setStatus: React.Dispatch<React.SetStateAction<DialogStatus>>,
}) => {

    const { boxNo, partsList } = innerPackage

    return <BlackSimpleCard allowCollapse defaultCollapse>
        <BlackSimpleCardHeader title={'Inner Package ' + (hasBlank ? innerPackageIndex : innerPackageIndex + 1) + ': ' + (boxNo && boxNo !== '' ? boxNo : '')}
            actions={[
                <ConfirmInnerPackageAction containerNo={containerNo} outerPackageNo={outerPackageNo} innerPackage={innerPackage} setStatus={setStatus} />,
                <SwitchInnerPackageAction containerNo={containerNo} outerPackageNo={outerPackageNo} innerPackage={innerPackage} setStatus={setStatus} />
            ]} />
        <BlackSimpleCardContent>
            <InnerPackageBasicInfoView innerPackage={innerPackage} />
            <PartsInfoListView partsList={partsList} />
        </BlackSimpleCardContent>
    </BlackSimpleCard>
})

const ConfirmInnerPackageAction = ({ containerNo, outerPackageNo, innerPackage, setStatus }: {
    containerNo: string,
    outerPackageNo: string,
    innerPackage: InnerPackageInfo,
    setStatus: React.Dispatch<React.SetStateAction<DialogStatus>>,
}) => {
    const onClick = useCallback(() => {
        setStatus(status => ({ open: true, conditions: { ...status.conditions, containerNo: containerNo, outerPackageNo: outerPackageNo, innerPackageNo: innerPackage.boxNo ?? '' } }))
    }, [containerNo, innerPackage.boxNo, outerPackageNo, setStatus])

    if (InboundStatus.COMPLETED === innerPackage.status || InboundStatus.CANCELLED === innerPackage.status) {
        return <></>
    }
    return <CallbackCardAction callback={onClick} title={<FormattedMessage id="confirm" />} />
}

const SwitchInnerPackageAction = ({ containerNo, outerPackageNo, innerPackage, setStatus }: {
    containerNo: string,
    outerPackageNo: string,
    innerPackage: InnerPackageInfo,
    setStatus: React.Dispatch<React.SetStateAction<DialogStatus>>,
}) => {
    const onClick = useCallback(() => {
        setStatus(status => ({ open: true, conditions: { ...status.conditions, containerNo: containerNo, outerPackageNo: outerPackageNo, innerPackageNo: innerPackage.boxNo ?? '' } }))
    }, [containerNo, innerPackage.boxNo, outerPackageNo, setStatus])

    if (InboundStatus.COMPLETED === innerPackage.status || InboundStatus.CANCELLED === innerPackage.status) {
        return <></>
    }
    return <CallbackCardAction outlined callback={onClick} title={<FormattedMessage id="switch" />} />
}


const InnerPackageBasicInfoView = memo(({ innerPackage }: {
    innerPackage: InnerPackageInfo,
}) => {
    const intl = useIntl()

    return <Form readonly data={innerPackage} labelDisplay="block" helperDisplay="tooltip" >
        <StringItem field="boxNo" label={<FormattedMessage id='innerPacakgeNo' />} />
        <StringItem field="ipPackageType" label={<FormattedMessage id='innerPacakgeType' />} />
        <CodeItem field="status" readonly label={intl.formatMessage({ id: 'status' })} code={CodeCategory.InboundStatus} />

        <NumberItem field="m3" label={<FormattedMessage id='innerPacakgeM3' />} />
        <NumberItem field="netWeight" label={<FormattedMessage id='innerPacakgeNw' />} />
        <NumberItem field="grossWeight" label={<FormattedMessage id='innerPacakgeGw' />} />
        <Break />
    </Form>
})

const PartsInfoListView = memo(({ partsList }: {
    partsList: InboundPartsInfo[],
}) => {

    const intl = useIntl()
    const columns = useMemo(() => [
        { field: 'buyerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'buyerPartsNo' }), width: 220 },
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 280 },
        { field: 'buyerPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'buyerPartsName' }), width: 220 },
        { field: 'buyerBackNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'backNo' }), width: 180 },
        { field: 'colorCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'colorCode' }), width: 180 },
        { field: 'poNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'puchaseOrderNo' }), width: 280 },
        { field: 'qty', dataTypeName: 'number', title: intl.formatMessage({ id: 'quantity' }), width: 150 },
        { field: 'inboundedQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'inboundQty' }), width: 180 },
        { field: 'status', dataTypeName: CodeCategory.InboundStatus, title: intl.formatMessage({ id: 'field.status' }), width: 180 },
    ], [intl])

    const getRowId = useCallback((row: any) => row.soDetailId, [])

    return <div style={{ width: '100%', height: 300 }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <DataTypePreset />
            <Data rows={partsList} columns={columns} getRowId={getRowId} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.InboundStatus} />
            <ColumnFreeze />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <ColumnVisibility
                defaultHiddenFields={['buyerPartsName', 'buyerBackNo', 'colorCode']}
                columnSettings={{
                    companyCode: { disableUserControl: true },
                    companyName: { disableUserControl: true },
                }}
                ToolbarButton={ColumnVisibilityToolbarButton} />
            <Sorting />
            <Filtering />
        </DataGrid>
    </div>
})


const ContainerPackageGridView = ({ inboundInfo }: {
    inboundInfo: InboundMonitorInfo,
}) => {

    const partsList = useMemo(() => {
        const contPartsList: any[] = []
        inboundInfo.containers?.forEach(container => {
            container.outerPackageList?.forEach(pkg => {
                pkg.innerPackageList.forEach(box => {
                    box.partsList.forEach(part => {
                        contPartsList.push({
                            ...container,
                            ...pkg,
                            ...box,
                            ...part,
                            m3Container: container.m3,
                            netWeightContainer: container.netWeight,
                            grossWeightContainer: container.grossWeight,
                            m3Outer: pkg.m3,
                            netWeightOuter: pkg.netWeight,
                            grossWeightOuter: pkg.grossWeight,
                            m3Inner: box.m3,
                            netWeightInner: box.netWeight,
                            grossWeightInner: box.grossWeight,
                        })
                    })
                })
            })
        })
        return contPartsList
    }, [inboundInfo.containers])

    const intl = useIntl()
    const containerCategories = useMemo(() => [{ key: 'container', value: intl.formatMessage({ id: 'containerInfo' }) }], [intl])
    const outerPacakgeCategories = useMemo(() => [{ key: 'outerPackage', value: intl.formatMessage({ id: 'outerPkgInfo' }) }], [intl])
    const innerPacakgeCategories = useMemo(() => [{ key: 'innerPackage', value: intl.formatMessage({ id: 'innerPkgInfo' }) }], [intl])
    const columns = useMemo(() => [
        { field: 'buyerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsNo' }), width: 220 },
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 220 },
        { field: 'buyerPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsName' }), width: 280 },
        { field: 'buyerBackNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.backNo' }), width: 180 },
        { field: 'colorCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'colorCode' }), width: 180 },
        { field: 'sellerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'sellerCode' }), width: 180 },
        { field: 'soNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'salesOrderNo' }), width: 220 },
        { field: 'buyerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'buyerCode' }), width: 180 },
        { field: 'poNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'puchaseOrderNo' }), width: 220 },
        { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 180 },
        { field: 'qty', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.qty' }), width: 180 },
        { field: 'inboundedQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'inboundQty' }), width: 180 },
        { field: 'status', dataTypeName: CodeCategory.InboundStatus, title: intl.formatMessage({ id: 'field.status' }), width: 180 },

        { field: 'containerNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'cntTruNo' }), categories: containerCategories, width: 240 },
        { field: 'containerType', dataTypeName: 'string', title: intl.formatMessage({ id: 'cntTruType' }), categories: containerCategories, width: 240 },
        { field: 'commodityType', dataTypeName: 'string', title: intl.formatMessage({ id: 'typeOfCommodities' }), categories: containerCategories, width: 200 },
        { field: 'sealNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sealNo' }), categories: containerCategories, width: 240 },
        { field: 'm3Container', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.m3' }), categories: containerCategories, width: 180 },
        { field: 'netWeightContainer', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.netWeight' }), categories: containerCategories, width: 220 },
        { field: 'grossWeightContainer', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.grossWeight' }), categories: containerCategories, width: 220 },
        { field: 'palletNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'palletNo' }), categories: outerPacakgeCategories, width: 240 },
        { field: 'outerPackageNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'outerPkgNo' }), categories: outerPacakgeCategories, width: 240 },
        { field: 'outerPackageType', dataTypeName: 'string', title: intl.formatMessage({ id: 'outerPkgType' }), categories: outerPacakgeCategories, width: 200 },
        { field: 'productionDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'productionDate' }), categories: outerPacakgeCategories, width: 200 },
        { field: 'm3Outer', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.m3' }), categories: outerPacakgeCategories, width: 200 },
        { field: 'netWeightOuter', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.netWeight' }), categories: outerPacakgeCategories, width: 220 },
        { field: 'grossWeightOuter', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.grossWeight' }), categories: outerPacakgeCategories, width: 220 },
        { field: 'boxNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'innerPkgNo' }), categories: innerPacakgeCategories, width: 240 },
        { field: 'ipPackageType', dataTypeName: 'string', title: intl.formatMessage({ id: 'innerPkgType' }), categories: innerPacakgeCategories, width: 200 },
        { field: 'm3Inner', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.m3' }), categories: innerPacakgeCategories, width: 200 },
        { field: 'netWeightInner', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.netWeight' }), categories: innerPacakgeCategories, width: 220 },
        { field: 'grossWeightInner', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.grossWeight' }), categories: innerPacakgeCategories, width: 220 },
    ], [containerCategories, innerPacakgeCategories, intl, outerPacakgeCategories])

    return <div style={{ width: '100%', height: 460 }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <Data rows={partsList} columns={columns} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.InboundStatus} />
            <ColumnFreeze />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <ColumnVisibility
                defaultHiddenFields={['sellerPartsName', 'sellerBackNo', 'colorCode', 'srbq', 'soNo',
                    'commodityType', 'sealNo', 'm3Container', 'netWeightContainer', 'grossWeightContainer',
                    'm3Outer', 'netWeightOuter', 'grossWeightOuter', 'm3Inner', 'netWeightInner', 'grossWeightInner']}
                columnSettings={{
                    companyCode: { disableUserControl: true },
                    companyName: { disableUserControl: true },
                }}
                ToolbarButton={ColumnVisibilityToolbarButton} />
            <Searching ignoreCase Input={SearchInput} />
            <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
            <Sorting />
            <Filtering />
        </DataGrid>
    </div>
}

interface Conditions {
    inboundNo: string,
    containerNo: string | null,
    outerPackageNo: string | null,
    innerPackageNo: string | null,
}

interface DialogStatus {
    open: boolean
    conditions: Conditions
}

const ConfirmDetailDialog = ({ search, status, setStatus }: {
    search: () => void,
    status: DialogStatus,
    setStatus: React.Dispatch<React.SetStateAction<DialogStatus>>,
}) => {

    const { open, conditions } = status
    const [factor, setFactor] = useState<ConfirmInboundInfo>({})
    const [messages, setMessages] = useState<Message[]>([])
    const fields = useMemo(() => getFormCheckFields(), [])
    const filedCheck = useFieldChecker(fields, setMessages)
    const formValidate = useFormValidater(setMessages, fields)
    const handleClose = useCallback(() => setStatus(status => ({ ...status, open: false })), [setStatus])

    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const [disabled, setDisabled] = useState<boolean>(false)
    const title = useMemo(() => intl.formatMessage({ id: 'confirm' }), [intl])
    const confirmInbound = useTotallyConfirmInbound()
    const confirmMethod = useCallback(() => {
        if (formValidate(factor)) {
            const functionId = functionStore.register(() => {
                setDisabled(true)
                confirmInbound({ ...factor, ...conditions }, { serialized: true }).then(() => {
                    search()
                    setStatus(status => ({ ...status, open: false }))
                    setFactor({})
                }).finally(() => {
                    setDisabled(false)
                })
            })
            dispatch(applicationActions.pushWarning({
                title: title,
                messages: { code: 'c0001', args: [title] },
                actions: [{
                    label: 'CANCEL'
                }, {
                    functionId,
                    label: 'CONFIRM',
                }]
            }))
        }

    }, [conditions, confirmInbound, dispatch, factor, formValidate, functionStore, search, setStatus, title])

    return <>
        <DarkDialog open={open} style={{ overflow: 'hidden' }} maxWidth="sm" fullWidth keepMounted={false} fullScreen={false}>
            <DialogHeader onClose={handleClose}><FormattedMessage id="confirm" /></DialogHeader>
            <DialogContent>
                <Form data={factor} setData={setFactor} labelDisplay="block" helperDisplay="tooltip" columnCount={1} minWidth={500} maxWidth={500} messages={messages} setMessages={setMessages} ensure={filedCheck}>
                    <StringItem required field="inboundRefNo" labelWidth={120} label={intl.formatMessage({ id: 'inboundRefNo' })} />
                    <DateItem required field="inboundDate" labelWidth={120} label={intl.formatMessage({ id: 'field.inboundDate' })} />
                    <TimeItem field="inboundTime" labelWidth={120} label={intl.formatMessage({ id: 'field.inboundTime' })} />
                </Form>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={handleClose} disabled={disabled} />
                <DialogAction title={<FormattedMessage id="confirm" />} callback={confirmMethod} disabled={disabled} />
            </DialogActions>
        </DarkDialog>
    </>
}

const getFormCheckFields = () => {
    return ({
        inboundRefNo: { labelId: 'inboundRefNo', required: true, length: { max: 30 } },
        inboundDate: { labelId: 'field.inboundDate', required: true },
    })
}
