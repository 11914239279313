import { Arrays } from "@rithe/utils"
import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useGetPNAInfo } from "../../../services/master/apis/businessUnitApi"
import { useGetSoDetail } from "../../../services/order/apis/SalesOrderApi"
import { SimulatedType } from "../../../services/order/enums/SimulatedType"
import { SoStatus } from "../../../services/order/enums/SoStatus"
import { EstimatedData, InboundPalnData, OrderDetailHead, OrderPartsData, OrderPartsDetail } from "../../../services/order/models/OrderDetailResult"
import { datetimeFromJson } from "../../../services/utils/deserializer"
import { OSS011PcUi } from "./OSS011PcUi"


export const OSS011 = () => {
    const { orderId } = useParams() as any
    const [headerInfo, setHeaderInfo] = useState<OrderDetailHead>({} as OrderDetailHead)
    const [partsDetailList, setPartsDetailList] = useState<OrderPartsData[]>([])

    const creatInboundPlanList = useCallback((partsDetailList: OrderPartsDetail[]) => {
        const allIPlanDateList: string[] = Arrays.distinct(partsDetailList.filter((f) => f.inboundPlanList).flatMap(m => m.inboundPlanList).map(m2 => [m2.drDate.getTime(), m2.crdDate.getTime()].join(','))).sort()
        const allEstimatedDataList: string[] = Arrays.distinct(partsDetailList.filter(f => f.estimatedInboundList).flatMap(f => f.estimatedInboundList).map(m3 => [m3.estimatedDrDate.getTime(), m3.estimatedCrdDate.getTime(), m3.fromCompany, SimulatedType.ON_STOCK !== m3.simulatedType ? m3.toCompany : ''].join(','))).sort()

        return partsDetailList.map((opd) => {
            const actualInboundQty: number = (opd?.inTransitQty ?? 0) + (opd?.receiverInbQty ?? 0)
            const acutalOrderQty = opd.detailStatus === SoStatus.CANCELLED ? 0 : (opd.orderQty ?? 0) - (opd.forceCompletedQty ?? 0)

            // So Detail Page: Shortage Qty = Order Qty - Received Qty - Total Estimated qty(including Intransit Qty)
            // let shortageQty: number = opd?.orderQty | 0 - opd?.receiverInbQty | 0 - opd?.inTransitQty | 0
            const newinboundPlanList = allIPlanDateList.map((dates) => {
                // get dates
                const planDates: number[] = dates.split(',').map(date => Number(date))
                const drDate = planDates[0], crdDate = planDates[1]

                // sum plan qty
                const planQty = opd.inboundPlanList ? opd.inboundPlanList.filter(f => f.drDate.getTime() <= drDate && f.crdDate.getTime() <= crdDate).map(m => m.crdQty).reduce((a, b) => (a * 1000000 + b * 1000000) / 1000000, 0) : 0
                const actualPlanQty = (planQty > acutalOrderQty ? acutalOrderQty : planQty)
                const estOutboundQty = opd.estimatedInboundList ? opd.estimatedInboundList.filter(f => f.estimatedCrdDate.getTime() <= crdDate).map(m => m.qty).reduce((a, b) => (a * 1000000 + b * 1000000) / 1000000, actualInboundQty) : 0
                const planInfo = opd.inboundPlanList.find(o => o.drDate.getTime() === drDate && o.crdDate.getTime() === crdDate)

                return {
                    drDate: datetimeFromJson(drDate),
                    crdDate: datetimeFromJson(crdDate),
                    status: actualPlanQty > estOutboundQty ? 'NG-' + (actualPlanQty * 1000000 - estOutboundQty * 1000000) / 1000000 : 'OK',
                    crdQty: planInfo?.crdQty ?? 0
                } as InboundPalnData
            })

            const newEstimatedInboundList = allEstimatedDataList.map((eData) => {
                const tempDataList: string[] = eData.split(',')
                const drDate: number = Number(tempDataList[0]), crdDate: number = Number(tempDataList[1]), companyTitle: string = '' === tempDataList[3] ? tempDataList[2] : tempDataList[2] + ' Ship To ' + tempDataList[3]
                const estimatedData = opd.estimatedInboundList.find(f => [f.estimatedDrDate.getTime(), f.estimatedCrdDate.getTime(), f.fromCompany, SimulatedType.ON_STOCK !== f.simulatedType ? f.toCompany : ''].join(',') === eData)

                return {
                    estimatedDrDate: datetimeFromJson(drDate),
                    estimatedCrdDate: datetimeFromJson(crdDate),
                    companyTitle: companyTitle,
                    qty: estimatedData?.qty ?? 0
                } as EstimatedData
            })

            return { ...opd, inboundPlanList: newinboundPlanList, estimatedInboundList: newEstimatedInboundList }
        })
    }, [])

    const getSoDetail = useGetSoDetail()
    const search = useCallback(() => {
        getSoDetail({ orderId: orderId }, { silent: true, serialized: true }).then(result => {
            setHeaderInfo(result?.headerInfo || {} as OrderDetailHead)
            if (result?.partsDetailList && result.partsDetailList.length > 0) {
                setPartsDetailList(creatInboundPlanList(result.partsDetailList))
            }
        })
    }, [creatInboundPlanList, getSoDetail, orderId])

    useEffect(() => {
        search()
    }, [search])

    const [pnaCode, setPnaCode] = useState<string | undefined>()
    const getPNAInfo = useGetPNAInfo()
    useEffect(() => {
        getPNAInfo(undefined, { silent: true, serialized: true }).then(result => {
            setPnaCode(result?.buCode)
        })
    }, [getPNAInfo])

    const props = {
        headerInfo: headerInfo,
        partsDetailList: partsDetailList,
        pnaCode: pnaCode,
        search: search
    }

    return <OSS011PcUi {...props} />
}
