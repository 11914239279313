import { Check } from "@material-ui/icons"
import React from "react"
import { FormattedMessage } from "react-intl"
import { CallbackToolbarAction } from "./CallbackToolbarAction"

interface ApproveCallbackToolbarActionProps {
    access?: string,
    power?: boolean,
    title?: NonNullable<React.ReactNode>,
    icon?: NonNullable<React.ReactNode>,
    callback: () => void,
    disabled?: boolean,
}

export const ApproveCallbackToolbarAction = (props: ApproveCallbackToolbarActionProps) => {
    const defaultProps = {
        title: <FormattedMessage id="approve" />,
        icon: <Check />
    }
    return <CallbackToolbarAction {...defaultProps} {...props} />
}