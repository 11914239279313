import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useComMasterGetAllUom } from "../../../services/common/apis/commonMasterApi"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { TnmUom } from "../../../services/master/models/TnmUom"
import { YESNOFlag } from "../../../services/privilege/enums/YESNOFlag"
import { useGetOrderGroups } from "../../../services/smt/api/ComboboxApi"
import { useGetOrderCalculationSettingDetail, useGetStockManagementAndOrderCalculationMasterDetail, useGetStockManagementMasterDetail } from "../../../services/smt/api/StockManagementMasterApi"
import { OdmCustomerPartsOrder } from "../../../services/smt/models/AlarmUsagePatternDetail"
import { OrderGroupTransfer } from "../../../services/smt/models/OrderGroupTransfer"
import { OdmCustomerParts, OdmCustomerPartsControl } from "../../../services/smt/models/StockManagementMasterDetail"
import { useMatch } from "../../../utils/useMatch"
import { StockOrderScreenMode } from "../MSAUF020/MSAUF020"
import { MSAUF021PcUi } from "./MSAUF021PcUi"

export const MSAUF021 = () => {
    const { customerPartsId } = useParams() as any
    const { path } = useMatch()
    const mode = path.includes("edit") ? ScreenMode.EDIT : ScreenMode.VIEW
    const display = path.includes("orderCalculationMaster")
        ? StockOrderScreenMode.ONLYOC
        : path.includes("stockManagementMaster")
            ? StockOrderScreenMode.ONLYSMT
            : StockOrderScreenMode.ALLPATTERN

    const [odmCustomerParts, setOdmCustomerParts] = useState<OdmCustomerParts>({} as OdmCustomerParts)
    const [odmCustomerPartsControl, setOdmCustomerPartsControl] = useState<OdmCustomerPartsControl>({} as OdmCustomerPartsControl)
    const [odmCustomerPartsOrder, setOdmCustomerPartsOrder] = useState<OdmCustomerPartsOrder>({} as OdmCustomerPartsOrder)
    const [orderGroupList, setOrderGroupList] = useState<OrderGroupTransfer[]>([])
    const [uoms, setUoms] = useState<TnmUom[]>([])

    const getStockManagementMasterDetail = useGetStockManagementMasterDetail()
    const getOrderCalculationSettingDetail = useGetOrderCalculationSettingDetail()
    const getStockManagementAndOrderCalculationMasterDetail = useGetStockManagementAndOrderCalculationMasterDetail()
    const getDetailInfo = StockOrderScreenMode.ONLYSMT === display
        ? getStockManagementMasterDetail
        : StockOrderScreenMode.ONLYOC === display
            ? getOrderCalculationSettingDetail
            : getStockManagementAndOrderCalculationMasterDetail
    const search = useCallback((customerPartsId: number | null) => {
        if (customerPartsId !== null) {
            getDetailInfo({ customerPartsId: customerPartsId }, { silent: true, serialized: true }).then(result => {
                setOdmCustomerParts(result?.customerPart ?? {})
                setOdmCustomerPartsControl(result?.customerPartControl ?? {})
                setOdmCustomerPartsOrder(result?.customerPartOrder ?? {})
            })
        }
    }, [getDetailInfo])

    const getOrderGroupsByPa = useGetOrderGroups()
    useEffect(() => {
        if ((odmCustomerParts.customerId !== null && odmCustomerParts.customerId !== undefined)
            || (odmCustomerParts.buyerBuId !== null && odmCustomerParts.buyerBuId !== undefined)) {
            getOrderGroupsByPa({ customerId: odmCustomerParts.customerId, buyerBuId: odmCustomerParts.buyerBuId, enhancementFlag: YESNOFlag.YES }, { silent: true, serialized: true }).then(result => {
                setOrderGroupList(result || [])
            })
        }
    }, [getOrderGroupsByPa, odmCustomerParts.buyerBuId, odmCustomerParts.customerId])

    const getUomList = useComMasterGetAllUom()
    useEffect(() => {
        getUomList(undefined, { silent: true }).then(result => {
            setUoms(result || [])
        })
    }, [getUomList])

    useEffect(() => {
        search(customerPartsId)
    }, [customerPartsId, search])


    return <MSAUF021PcUi
        mode={mode}
        odmCustomerParts={odmCustomerParts}
        setOdmCustomerParts={setOdmCustomerParts}
        odmCustomerPartsControl={odmCustomerPartsControl}
        setOdmCustomerPartsControl={setOdmCustomerPartsControl}
        odmCustomerPartsOrder={odmCustomerPartsOrder}
        setOdmCustomerPartsOrder={setOdmCustomerPartsOrder}
        search={search}
        orderGroupList={orderGroupList}
        display={display}
        uoms={uoms}
    />
}