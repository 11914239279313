import { FormattedMessage } from "react-intl"
import { useDispatch } from "react-redux"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { UploadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/UploadGroupedToolbarAction"
import { useFunctionStore } from "../../../Root"
import { occls010Actions, OrderCalculationType } from "./OCCLS010Slice"

export interface UploadActionProps {
  type: OrderCalculationType
}

export function UploadAction(props: UploadActionProps) {
  const { type } = props
  const dispatch = useDispatch()
  const functionStore = useFunctionStore()
  const upload =(file: File) => {
    dispatch(occls010Actions.upload({ file, functionStore, type }))
  }

  return <UploadGroupedToolbarAction access="STCK.OCCLS010.UPLOAD">
    {(popupUpload, onClose) => <>
      <GroupedCallbackItem
        label={<FormattedMessage id="upload" />}
        callback={() => popupUpload(files => files && upload(files[0]))}
        onClose={onClose} />
    </>}
  </UploadGroupedToolbarAction>
}