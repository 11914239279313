import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Message } from "@rithe/form"
import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import appConfig from "../../../configs/appConfig"
import resourceApi from "../../../services/system/apis/resourceApi"
import roleApi from "../../../services/system/apis/roleApi"
import { ResourceType } from "../../../services/system/enums/ResourceType"
import errorToNotification from "../../../utils/axios/errorToNotification"
import responseToNotification from "../../../utils/axios/responseToNotification"
import thunkAxiosInstance from "../../../utils/axios/thunkAxiosInstance"
import { applicationActions } from "../../Application/applicationSlice"

type Mode = 'CREATE' | 'EDIT' | 'VIEW'

interface Role {
    id: string,
    name: string,
    description: string,
    active: boolean,
    resourceIds: string[],
}

interface Resource {
    id: string,
    code: string,
    type: ResourceType
    category: string,
    module: string,
    action: string,
}

interface CAS011State {
    ui: {
        mode: Mode,
        visitedMap: {
            name: boolean,
            description: boolean,
        },
        messagesMap: {
            name: Message[],
            description: Message[],
        }
    },
    domain: {
        role: Role,
        resources: Resource[],
        roles: Role[],
    },
}

const emptyRole = {
    id: '',
    name: '',
    description: '',
    active: true,
    resourceIds: [],
}

const initialState: CAS011State = {
    ui: {
        mode: 'CREATE',
        visitedMap: {
            name: false,
            description: false,
        },
        messagesMap: {
            name: [],
            description: [],
        },
    },
    domain: {
        role: emptyRole,
        resources: [],
        roles: [],
    },
}

const pageInit = createAsyncThunk<void, { mode: Mode, id?: string }>('cas011/pageInit', async ({ mode, id = '' }, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)

    thunk.dispatch(actions.setMode(mode))
    thunk.dispatch(actions.setAllVisited(false))
    thunk.dispatch(actions.clearAllMessages())
    thunk.dispatch(applicationActions.addMask())
    Promise.all([
        mode === 'CREATE' ? Promise.resolve(null) : roleApi.get(axiosInstance, id).then(response => response.data),
        resourceApi.list(axiosInstance, {}).then(response => response.data.data!),
        mode === 'VIEW' ? Promise.resolve([]) : roleApi.list(axiosInstance, {}).then(response => response.data.data!),
    ]).then(([role, resources, roles]) => {
        thunk.dispatch(actions.setRole(role || emptyRole))
        thunk.dispatch(actions.setResources(resources))
        thunk.dispatch(actions.setRoles(roles))
    }).catch(error => {
        thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
    }).then(() => {
        thunk.dispatch(applicationActions.removeMask())
    })
})

const clickCreate = createAsyncThunk<void, (id: string) => void>('cas011/clickCreate', (successCallback, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const role = select(thunk.getState()).domain.role
    const messagesMap = validate(role)
    if (Object.values(messagesMap).flat().length > 0) {
        thunk.dispatch(actions.setAllMessages(messagesMap))
        thunk.dispatch(actions.setAllVisited(true))
        thunk.dispatch(applicationActions.pushError({ title: { code: 'inputError' }, messages: { code: 'w0345' } }))
    } else {
        thunk.dispatch(applicationActions.addMask())
        roleApi.create(axiosInstance, role)
            .then(response => {
                thunk.dispatch(applicationActions.pushNotification(responseToNotification(response)))
                successCallback(response.data.id)
            }).catch(error => {
                thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
            }).then(() => {
                thunk.dispatch(applicationActions.removeMask())
            })
    }
})

const clickUpdate = createAsyncThunk<void, (id: string) => void>('cas011/clickUpdate', (successCallback, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const role = select(thunk.getState()).domain.role
    const messagesMap = validate(role)
    if (Object.values(messagesMap).flat().length > 0) {
        thunk.dispatch(actions.setAllMessages(messagesMap))
        thunk.dispatch(actions.setAllVisited(true))
        thunk.dispatch(applicationActions.pushError({ title: { code: 'inputError' }, messages: { code: 'w0345' } }))
    } else {
        roleApi.update(axiosInstance, { ...role, roleId: role.id })
            .then(response => {
                thunk.dispatch(applicationActions.pushNotification(responseToNotification(response)))
                successCallback(response.data.id)
            }).catch(error => {
                thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
            }).then(() => {
                thunk.dispatch(applicationActions.removeMask())
            })
    }
})

const clickReset = createAsyncThunk<void, void>('cas011/clickReset', (_, thunk) => {
    const mode = select(thunk.getState()).ui.mode
    const id = select(thunk.getState()).domain.role.id
    thunk.dispatch(pageInit({ mode, id }))
})

function validate(role: Role) {
    const messagesMap: CAS011State['ui']['messagesMap'] = {
        name: [],
        description: [],
    }
    if (!role.name) {
        messagesMap.name.push({
            field: 'name',
            level: 'error',
            value: <FormattedMessage id="error.name.required" />
        })
    } else if (role.name.length > 255) {
        messagesMap.name.push({
            field: 'name',
            level: 'error',
            value: <FormattedMessage id="error.name.tooLong" values={{ length: 255 }} />
        })
    }
    if (!role.description) {
        messagesMap.description.push({
            field: 'description',
            level: 'error',
            value: <FormattedMessage id="error.description.required" />
        })
    } else if (role.description.length > 65535) {
        messagesMap.description.push({
            field: 'description',
            level: 'error',
            value: <FormattedMessage id="error.description.maxLength" values={{ length: 65535 }} />
        })
    }
    return messagesMap
}

export const slice = createSlice({
    name: 'cas011',
    initialState,
    reducers: {
        setMode: (state, action: PayloadAction<Mode>) => {
            state.ui.mode = action.payload
        },
        setFieldVisited: (state, action: PayloadAction<{ field: keyof CAS011State['ui']['visitedMap'], visited: boolean }>) => {
            state.ui.visitedMap[action.payload.field] = action.payload.visited
        },
        setAllVisited: (state, action: PayloadAction<boolean>) => {
            state.ui.visitedMap = {
                name: action.payload,
                description: action.payload,
            }
        },
        setAllMessages: (state, action: PayloadAction<CAS011State['ui']['messagesMap']>) => {
            state.ui.messagesMap = action.payload
        },
        clearAllMessages: (state) => {
            state.ui.messagesMap = {
                name: [],
                description: [],
            }
        },
        setRole: (state, action: PayloadAction<Role>) => {
            state.domain.role = action.payload
        },
        setResources: (state, action: PayloadAction<Resource[]>) => {
            state.domain.resources = action.payload
        },
        setRoles: (state, action: PayloadAction<Role[]>) => {
            state.domain.roles = action.payload
        },
        setName: (state, action: PayloadAction<string>) => {
            state.domain.role.name = action.payload
            state.ui.messagesMap = validate(state.domain.role)
        },
        setDescription: (state, action: PayloadAction<string>) => {
            state.domain.role.description = action.payload
            state.ui.messagesMap = validate(state.domain.role)
        },
        setResourceIds: (state, action: PayloadAction<string[]>) => {
            state.domain.role.resourceIds = action.payload
            state.ui.messagesMap = validate(state.domain.role)
        },
    },
})

const actions = {
    ...slice.actions,
    pageInit,
    clickCreate,
    clickUpdate,
    clickReset,
}

function select(state: any): CAS011State {
    return state[appConfig.appFullName][cas011Slice.name]
}

export const cas011Slice = slice
export const cas011Actions = actions
export function useCAS011Selector<R>(selector: (state: CAS011State) => R, equalityFn?: (left: R, right: R) => boolean) {
    return useSelector<any, R>(state => selector(select(state)), equalityFn)
}