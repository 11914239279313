import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"

export type TnmIncoterms = Required & Partial<Optional> & TableEntity
type TnmIncotermsJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const tnmIncotermsFromJson: Project<TnmIncotermsJson, TnmIncoterms> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
}

interface JsonRequired {
    incotermsId: number,
    incotermsCode: string,
}

interface JsonOptional {
    description: string,
}