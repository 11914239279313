import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { dateToJson } from "../../utils/serializer"

export interface OrderCalculationListViewCondition {
    orderGroupNo?: string,
    orderMonthStart?: Date,
    orderMonthEnd?: Date,
    statusList?: number[],
    orderCalcNo?: string,
    createDateStart?: Date,
    createDateEnd?: Date,
    customerIdList?: number[],
    orderFrequencyList?: number[],
    createdBy?: string,
    buyerUids?: string[],
    sellerUids?: string[]
}

interface OrderCalculationListViewConditionJson {
    orderGroupNo?: string,
    orderMonthStart?: string,
    orderMonthEnd?: string,
    statusList?: number[],
    orderCalcNo?: string,
    createDateStart?: string,
    createDateEnd?: string,
    customerIdList?: number[],
    orderFrequencyList?: number[],
    createdBy?: string,
    buyerUids?: string[],
    sellerUids?: string[]
}

export const orderCalculationListViewConditionToJson: Project<OrderCalculationListViewCondition, OrderCalculationListViewConditionJson> = obj => ({
    ...obj,
    orderMonthStart: dateToJson(obj.orderMonthStart),
    orderMonthEnd: dateToJson(obj.orderMonthEnd),
    createDateStart: dateToJson(obj.createDateStart),
    createDateEnd: dateToJson(obj.createDateEnd),
})

export const orderCalculationListViewConditionFromJson: Project<OrderCalculationListViewConditionJson, OrderCalculationListViewCondition> = obj => ({
    ...obj,
    orderMonthStart: dateFromJson(obj.orderMonthStart),
    orderMonthEnd: dateFromJson(obj.orderMonthEnd),
    createDateStart: dateFromJson(obj.createDateStart),
    createDateEnd: dateFromJson(obj.createDateEnd),
})


