import { Project } from "../../../utils/Project"
import { ShippingMode } from "../../master/enums/ShippingMode"
import { dateToJson } from "../../utils/serializer"
import { ShippingDetailStatus } from "../enums/ShippingDetailStatus"


type EditShippingDetail = Required & Partial<Optional>

type EditShippingDetailJson = JsonRequired & Partial<JsonOptional>

export const editShippingDetailToJson: Project<EditShippingDetail, EditShippingDetailJson> = obj => ({
    ...obj,
    atd: dateToJson(obj.atd),
    eta: dateToJson(obj.eta),
    blDate: dateToJson(obj.blDate),
})


interface Required extends JsonRequired { }

interface Optional extends Omit<JsonOptional, 'shippingMode' | 'atd' | 'eta' | 'blDate'> {
    shippingMode: ShippingMode,
    atd: Date,
    eta: Date,
    blDate: Date,
    status: ShippingDetailStatus,
}

interface JsonRequired {
    shippingDetailId: number,
}

interface JsonOptional {
    shippingMode: number,
    atd: string,
    eta: string,
    blNo: string,
    blDate: string,
    vesselName: string,
    voyageNo: string,
    cargoStatus: string,
    containerType: string,
    sealNo: string,
    m3: number,
    netWeight: number,
    grossWeight: number,
}