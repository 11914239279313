import { useEffect, useRef } from "react";

export function useSearchOnLoad<T>(search: (filters: T) => void, filters: T) {
    const loadedRef = useRef(false)
    useEffect(() => {
        if (!loadedRef.current) {
            search(filters)
            loadedRef.current = true
        }
    }, [filters, search])
}