import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"

export type InventoryByPackageResult = Required & Partial<Optional>
type InventoryByPackageResultJson = JsonRequired & Partial<JsonOptional>
export const inventoryByPackageResultFromJson: Project<InventoryByPackageResultJson, InventoryByPackageResult> = json => ({
    ...json,
    productionDate: dateFromJson(json.productionDate),
    inboundDate: dateFromJson(json.inboundDate),
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'productionDate' |'inboundDate'> {
    productionDate: Date,
    inboundDate: Date
}

interface JsonRequired {
}

interface JsonOptional {
    dcId: number,
    dcCode: string,
    customerId: number,
    customerCode: string,
    partsNo: string,
    unitPartsNo: string,
    backNo: string,
    colorCode: string,
    spq: number,
    incomingQty: number,
    stockQty: number,
    palletNo: string,
    packageNo: string,
    packageType: string,
    digits: number,
    productionDate: string,
    stockDays: number,
    inboundDate: string,
    outboundRefNo: string,
}