import { Arrays } from "@rithe/utils"
import { useCallback, useEffect, useMemo } from "react"
import { useIntl } from "react-intl"
import { useApplicationSelector } from "../../layouts/Application/applicationSlice"
import { CbdsType } from "../../services/master/enums/CbdsType"
import { useMatch } from "../../utils/useMatch"
import { routeList } from "./ResourceRouteList"


export const DataLayer = () => {

    const company = useGetCompany()
    const loginUser = useApplicationSelector(state => state.auth.user)
    const language = useApplicationSelector(state => state.i18n.language)
    const userSetting = useApplicationSelector(state => state.setting?.userSetting)
    const accessList = useAccessList()
    const screenName = useScreen()

    const pageChange = useCallback((
        pageName: string,
        company: any,
        loginUser: any,
        language: string,
        userSetting: any,
        accessList?: string[],
    ) => {
        if (loginUser && company && pageName && userSetting) {
            // GET USER ROLE
            const userRole = company.cbdsType === CbdsType.BU ? "BU" : (company.cbdsType === CbdsType.CUST ? "Customer" : (company.cbdsType === CbdsType.DC ? "DC" : "Supplier"));
            (window as any).dataLayer = (window as any).dataLayer || [];
            (window as any).dataLayer.push({
                'userId': loginUser.account.loginId,
                'name': loginUser.name,
                'lang': language,
                'userRole': userRole,
                'accessList': accessList,
                'pageName': pageName,
                'email': loginUser.email,
                'contactNumber': loginUser.contactCode,
                'companyName': company.cbdsCode,
                'rulebase': userSetting?.daSetting.ruleBasedRecommendation ? 'yes' : 'no',
                'recommendation': userSetting?.daSetting.aiPageRecommendation ? 'yes' : 'no',
                'event': 'pageChange'
            })
        }
    }, [])

    useEffect(() => {
        pageChange(screenName, company, loginUser, language, userSetting, accessList)
    }, [company, language, loginUser, pageChange, screenName, accessList, userSetting])

    return null
}

function useAccessList() {
    const intl = useIntl()
    const resources = useApplicationSelector(state => state.auth.accessResources)
    const screenNames = useMemo(() => {
        const screenResources = resources?.filter(f => f.endsWith(".VIEW"))
        return screenResources ? Arrays.distinct(screenResources?.map(m => routeList[m]).flatMap(m => m).filter(f => f).map(s => intl.formatMessage({ id: s }))) : []
    }, [intl, resources])
    return screenNames
}

function useScreen() {
    const { path } = useMatch()
    const intl = useIntl()
    return intl.formatMessage({ id: path })
}

const useGetCompany = () => {

    const companyUid = useApplicationSelector(state => state.auth.companyUid)
    const loginUserCompanies = useApplicationSelector(state => state.auth.userCompanies)

    const company = useMemo(() => {
        if (companyUid !== null && companyUid !== undefined) {
            const selectedCompany = loginUserCompanies?.find(f => f.cbdsUid === companyUid)
            if (selectedCompany) {
                return selectedCompany
            }
        }
        return null
    }, [companyUid, loginUserCompanies])

    return company
}
