import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"

export type DailyRundownList = Required & Partial<Optional> & TableEntity
type DailyRundownListJson = JsonRequired & Partial<JsonOptional> & TableEntityJson

export const dailyRundownFromJson: Project<DailyRundownListJson, DailyRundownList> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
}

interface JsonRequired {
    customerPartsId: number,
}

interface JsonOptional {
    partsNo: string,
    partsName: string,
    customerCode: string,
    sellerCode: string,
    supplierCodes: string,
    expCountry: string,
    dataSource: number,
    dataSourceStr: string,
    externalRefNo: string,
}