import {useEffect, useState} from "react"
import {UserQrInfoResponse} from "../../../services/master/models/UserQrInfoResponse"
import {useGetQrCode} from "../../../services/system/apis/userMfaSettingApi"
import {MSESF022PcUi} from "./MSESF022PcUi"

export const MSESF022 = () => {
  const [qrInfo, setQrInfo] = useState<UserQrInfoResponse>({} as UserQrInfoResponse)
  const [code, setCode] = useState<string>("")
  const [dialog, setDialog] = useState<boolean>(false)

  const getQrCode = useGetQrCode()
  useEffect(() => {
    getQrCode(undefined, {silent: true, serialized: true}).then(result => {
      setQrInfo(result)
      if (result.isViewed === 0) { 
        setDialog(true)
      }
    })
  }, [getQrCode])

  return <MSESF022PcUi
    code={code}
    setCode={setCode}
    qrInfo={qrInfo}
    setQrInfo={setQrInfo}
    dialog={dialog}
    setDialog={setDialog}
  />
}