import {useCallback, useEffect, useMemo, useState} from "react"
import {useGetCategory, useGetExternalOrderByPage, useGetGrouping} from "../../../services/integration/apis/externalOrderApis"
import {ExternalOrderCategoryList} from "../../../services/integration/models/ExternalOrderCategoryList"
import {ExternalOrderListFactor, ExternalOrderListFactorFromJson, ExternalOrderListFactorToJson} from "../../../services/integration/models/ExternalOrderFactor"
import {ExternalOrderListResult} from "../../../services/integration/models/ExternalOrderListResult"
import {GroupingDataList} from "../../../services/integration/models/GroupingDataList"
import sessionKeys from "../../../utils/sessionKeys"
import {useSearchOnLoad} from "../../../utils/useSearchOnLoad"
import {INT040PcUi} from "./INT040PcUi"

const defaultFilters = {page: {currentPage: 0, pageSize: 10000}}
const searchCachekey = sessionKeys.Filters_INT040

export const INT040 = () => {
    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? ExternalOrderListFactorFromJson(JSON.parse(filters)) : null
    }, [])

    const [filters, setFilters] = useState<ExternalOrderListFactor>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<ExternalOrderListResult[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [categoryDate, setCategoryData] = useState<ExternalOrderCategoryList[]>([])
    const [groupingData, setGroupingData] = useState<GroupingDataList[]>([])

    const getData = useGetExternalOrderByPage()
    const search = useCallback((filters: ExternalOrderListFactor) => {
        getData(filters, {silent: true, serialized: true}).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(ExternalOrderListFactorToJson(filters)))
        })
    }, [getData, setData])

    useSearchOnLoad(search, filters)
    // category
    const category = useGetCategory()
    const codeCategories = useCallback(() => {
        category(undefined, {silent: true}).then(result => {
            setCategoryData(result || [])
        })
    }, [setCategoryData, category])
    // grouping
    const getGrouping = useGetGrouping()
    const groupingList = useCallback(() => {
        getGrouping(undefined, {silent: true}).then(result => {
            setGroupingData(result)
        })
    }, [setGroupingData, getGrouping])

    useEffect(() => {
        codeCategories()
        groupingList()
    }, [codeCategories, groupingList])

    return <INT040PcUi
        filters={filters}
        setFilters={setFilters}
        search={search}
        data={data}
        totalCount={totalCount}
        category={categoryDate}
        groupingData={groupingData}
    />
}
