import { Arrays } from "@rithe/utils"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { useComMasterGetActiveCbds, useComMasterGetActiveShippingRoute, useComMasterGetAllCurrency, useComMasterGetAllIncoterms, useComMasterGetAllUom } from "../../../services/common/apis/commonMasterApi"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { useGetMyPaymentTerms, useGetWorkingByRequestNo, useGetWorkingForModifyByRequestNo, useGetWorkingForPriceModifyByRequestNo, useGetWorkingForRemoveByRequestNo, useGetWorkingForTerminateByRequestNo } from "../../../services/master/apis/workingApi"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { ContractRequestType } from "../../../services/master/enums/ContaractRequestType"
import { DeliveryTypeFlag } from "../../../services/master/enums/DeliveryTypeFlag"
import { FinalSellerFlag } from "../../../services/master/enums/FinalSellerFlag"
import { MLS152CustomsRequest } from "../../../services/master/models/MLS152CustomsRequest"
import { PartsDetailResult } from "../../../services/master/models/PartsDetailResult"
import { RequestHeaderResult } from "../../../services/master/models/RequestHeaderResult"
import { TnmCurrency } from "../../../services/master/models/TnmCurrency"
import { TnmIncoterms } from "../../../services/master/models/TnmIncoterms"
import { TnmPaymentTerms } from "../../../services/master/models/TnmPaymentTerms"
import { TnmShippingRoute } from "../../../services/master/models/TnmShippingRoute"
import { TnmUom } from "../../../services/master/models/TnmUom"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { WorkingPartsList } from "../../../services/master/models/WorkingPartsList"
import { WorkingResult, workingResultFromJson } from "../../../services/master/models/WorkingResult"
import { useGetCompanyType } from "../../../utils/ApplicationUtils"
import sessionKeys from "../../../utils/sessionKeys"
import { useMatch } from "../../../utils/useMatch"
import { MOS031AddNewPartsPcUi, View4DescriptionItem } from "./MOS031AddNewPartsPcUi"
import { MOS031ModifyPackingPcUi } from "./MOS031ModifyPackingPcUi"
import { MOS031ModifyPricePcUi } from "./MOS031ModifyPricePcUi"
import { MOS031RemovePartsPcUi } from "./MOS031RemovePartsPcUi"
import { MOS031TerminatePcUi } from "./MOS031TerminatePcUi"

const searchCachekey = sessionKeys.Data_MOS031
export const MOS031 = () => {
    const { path } = useMatch()
    const { requestNo } = useParams() as any
    const mode = useMemo(() => path.indexOf('View-:requestNo') >= 0 ? ScreenMode.VIEW : requestNo !== undefined ? ScreenMode.EDIT : ScreenMode.CREATE, [path, requestNo])

    const requestType = useMemo(() => {
        return (path === '/receivedRequest/modifyPrice-:requestNo' || path === '/receivedRequest/modifyPriceView-:requestNo') ? ContractRequestType.MODIFY_PRICE :
            (path === '/receivedRequest/modify-:requestNo' || path === '/receivedRequest/modifyView-:requestNo') ? ContractRequestType.MODIFY_PACKING :
                (path === '/receivedRequest/remove-:requestNo' || path === '/receivedRequest/removeView-:requestNo') ? ContractRequestType.REMOVE :
                    (path === '/receivedRequest/addNewPart-:requestNo' || path === '/receivedRequest/addNewPartView-:requestNo') ? ContractRequestType.ADD :
                        (path === '/receivedRequest/terminate-:requestNo' || path === '/receivedRequest/terminateView-:requestNo') ? ContractRequestType.TERMINATE : undefined
    }, [path])

    const [viewNum, setViewNum] = useState<number>(0)
    const [data, setData] = useState<WorkingResult>({} as WorkingResult)// all data
    const [partsDataList, setPartsDataList] = useState<WorkingPartsList[]>([]) // view 4 grid data
    const [nextPartsDataList, setNextPartsDataList] = useState<WorkingPartsList[]>([]) // view 4 grid data

    const [requesHeaderInfo, setRequesHeaderInfo] = useState<RequestHeaderResult>({} as RequestHeaderResult) // reques header
    const [requesPartsList, setRequesPartsList] = useState<PartsDetailResult[]>([]) // reques list

    const [paymentTermList, setPaymentTermList] = useState<TnmPaymentTerms[]>([])
    const [cbdsList, setCbdsList] = useState<TnvCbds[]>([])
    const [currencyList, setCurrencyList] = useState<TnmCurrency[]>([])
    const [shippingRouteList, setShippingRouteList] = useState<TnmShippingRoute[]>([])
    const [incotermsList, setIncotermsList] = useState<TnmIncoterms[]>([])
    const [uomList, setUom] = useState<TnmUom[]>([])

    const [nextRequesList, setNextRequesList] = useState<MLS152CustomsRequest[]>([])
    const [view4DescriptionItemList, setView4DescriptionItem] = useState<View4DescriptionItem[]>([])

    const getWorkingByRequestNo = useGetWorkingByRequestNo()
    const getWorkingForModifyByRequestNo = useGetWorkingForModifyByRequestNo()
    const getWorkingForPriceModifyByRequestNo = useGetWorkingForPriceModifyByRequestNo()
    const getWorkingForRemoveByRequestNo = useGetWorkingForRemoveByRequestNo()
    const getWorkingForTerminateByRequestNo = useGetWorkingForTerminateByRequestNo()
    const getCbds = useComMasterGetActiveCbds()
    const getPaymentTerms = useGetMyPaymentTerms()
    const getCurrency = useComMasterGetAllCurrency()
    const getIncoterms = useComMasterGetAllIncoterms()
    const getShippingRoute = useComMasterGetActiveShippingRoute()
    const getUomList = useComMasterGetAllUom()
    const companyType = useGetCompanyType()
    const showView4 = useMemo(() => companyType !== CbdsType.SUPP, [companyType])

    const getView4DescriptionArray = useCallback((nextPartsDataList: WorkingPartsList[] | undefined | null) => {
        if (nextPartsDataList) {
            const groupBySellerCodeList = Arrays.distinct(nextPartsDataList.map(m => m.finalSellerFlag === FinalSellerFlag.Y ? '' : m.nextSellerCode ?? "")).map(o => nextPartsDataList.filter(f => (f.nextSellerCode ?? "") === o))
            setView4DescriptionItem(groupBySellerCodeList.map((m, i) => { return { index: i, nextSellerCode: m?.length > 0 ? m[0]?.nextSellerCode ?? "" : "", description: "" } }))
        } else {
            setView4DescriptionItem([])
        }
    }, [setView4DescriptionItem])

    const search = useCallback((requestNo: string | undefined) => {
        if (requestNo) {
            if (requestType === ContractRequestType.ADD) {
                const dataMOS031Json = sessionStorage.getItem(searchCachekey)
                if (dataMOS031Json !== null) {
                    let dataMOS031 = workingResultFromJson(JSON.parse(dataMOS031Json))
                    setData({ ...dataMOS031, deliveryTypeFlag: DeliveryTypeFlag.N })
                    setRequesHeaderInfo(dataMOS031?.requestHeader ? { ...dataMOS031.requestHeader } : {} as RequestHeaderResult)
                    setRequesPartsList(dataMOS031?.requestPartsList ? [...dataMOS031.requestPartsList] : [])
                    setPartsDataList(dataMOS031?.partsList ? [...dataMOS031.partsList.map((m, i) => ({ ...m, index: i }))] : [])
                    setViewNum(dataMOS031?.stepNo ? dataMOS031.stepNo < 3 ? dataMOS031.stepNo : showView4 ? 3 : 2 : 0)
                    setNextPartsDataList(dataMOS031?.nextPartsList ? [...dataMOS031.nextPartsList.map((m, i) => ({ ...m, index: i }))] : [])
                    getView4DescriptionArray(dataMOS031.nextPartsList ? [...dataMOS031.nextPartsList] : [])
                    sessionStorage.removeItem(searchCachekey)
                } else {
                    getWorkingByRequestNo({ requestNo: requestNo }, { serialized: true, silent: true }).then(result => {
                        setData({ ...result, deliveryTypeFlag: DeliveryTypeFlag.N })
                        setRequesHeaderInfo(result?.requestHeader ? { ...result.requestHeader } : {} as RequestHeaderResult)
                        setRequesPartsList(result?.requestPartsList ? [...result.requestPartsList] : [])
                        setPartsDataList(result?.partsList ? [...result.partsList.map((m, i) => ({ ...m, index: i }))] : [])
                        if (mode !== ScreenMode.VIEW) {
                            setViewNum(result?.stepNo ? result.stepNo < 3 ? result.stepNo : showView4 ? 3 : 2 : 0)
                        }
                        setNextPartsDataList(result.nextPartsList ? [...result.nextPartsList.map((m, i) => ({ ...m, index: i }))] : [])
                        getView4DescriptionArray(result.nextPartsList ? [...result.nextPartsList] : [])
                    })
                }
            } else if (requestType === ContractRequestType.MODIFY_PACKING) {
                getWorkingForModifyByRequestNo({ requestNo: requestNo }, { serialized: true, silent: true }).then(result => {
                    setData({ ...result, deliveryTypeFlag: DeliveryTypeFlag.N })
                    setRequesHeaderInfo(result?.requestHeader ? { ...result.requestHeader } : {} as RequestHeaderResult)
                    setRequesPartsList(result?.requestPartsList ? [...result.requestPartsList] : [])
                })
            } else if (requestType === ContractRequestType.MODIFY_PRICE) {
                getWorkingForPriceModifyByRequestNo({ requestNo: requestNo }, { serialized: true, silent: true }).then(result => {
                    setRequesHeaderInfo(result?.requestHeader ? { ...result.requestHeader } : {} as RequestHeaderResult)
                    setRequesPartsList(result?.requestPartsList ? [...result.requestPartsList] : [])
                })
            } else if (requestType === ContractRequestType.REMOVE) {
                getWorkingForRemoveByRequestNo({ requestNo: requestNo }, { serialized: true, silent: true }).then(result => {
                    setRequesHeaderInfo(result?.requestHeader ? { ...result.requestHeader } : {} as RequestHeaderResult)
                    setRequesPartsList(result?.requestPartsList ? [...result.requestPartsList] : [])
                })
            } else {
                getWorkingForTerminateByRequestNo({ requestNo: requestNo }, { serialized: true, silent: true }).then(result => {
                    setRequesHeaderInfo(result?.requestHeader ? { ...result.requestHeader } : {} as RequestHeaderResult)
                })
            }
        }
    }, [getView4DescriptionArray, getWorkingByRequestNo, getWorkingForModifyByRequestNo, getWorkingForPriceModifyByRequestNo, getWorkingForRemoveByRequestNo, getWorkingForTerminateByRequestNo, mode, requestType, showView4])


    useEffect(() => {
        search(requestNo)
    }, [requestNo, search])

    useEffect(() => {
        getCbds({ types: [] }, { silent: true }).then(result => {
            setCbdsList(result || [])
        })
        getPaymentTerms(undefined, { silent: true, serialized: true }).then(result => {
            setPaymentTermList(result || [])
        })

        getCurrency(undefined, { silent: true }).then(result => {
            setCurrencyList(result || [])
        })
        getIncoterms(undefined, { silent: true }).then(result => {
            setIncotermsList(result || [])
        })
        getShippingRoute(undefined, { silent: true }).then(result => {
            setShippingRouteList(result || [])
        })
        getUomList(undefined, { silent: true }).then(result => {
            setUom(result || [])
        })
    }, [getCbds, getCurrency, getIncoterms, getPaymentTerms, getShippingRoute, getUomList])

    if (requestType === ContractRequestType.ADD) {
        return <MOS031AddNewPartsPcUi
            mode={mode}
            data={data}
            setData={setData}
            requesHeaderInfo={requesHeaderInfo}
            requesPartsList={requesPartsList}
            partsDataList={partsDataList}
            setPartsDataList={setPartsDataList}
            cbdsList={cbdsList}
            currencyList={currencyList}
            shippingRouteList={shippingRouteList}
            incotermsList={incotermsList}
            requestNo={requestNo}
            requestType={requestType}
            paymentTermList={paymentTermList}
            viewNum={viewNum}
            setViewNum={setViewNum}
            nextPartsDataList={nextPartsDataList}
            setNextPartsDataList={setNextPartsDataList}
            uomList={uomList}
            view4DescriptionItemList={view4DescriptionItemList}
            setView4DescriptionItem={setView4DescriptionItem}
            getView4DescriptionArray={getView4DescriptionArray}
        />
    } else if (requestType === ContractRequestType.MODIFY_PACKING) {
        return <MOS031ModifyPackingPcUi
            mode={mode}
            data={data}
            requesHeaderInfo={requesHeaderInfo}
            requesPartsList={requesPartsList}
            nextRequesList={nextRequesList}
            setNextRequesList={setNextRequesList}
        />
    } else if (requestType === ContractRequestType.MODIFY_PRICE) {
        return <MOS031ModifyPricePcUi
            mode={mode}
            requesHeaderInfo={requesHeaderInfo}
            requesPartsList={requesPartsList}
            nextRequesList={nextRequesList}
            setNextRequesList={setNextRequesList}
        />
    } else if (requestType === ContractRequestType.TERMINATE) {
        return <MOS031TerminatePcUi
            mode={mode}
            requesHeaderInfo={requesHeaderInfo}
            cbdsList={cbdsList}
            currencyList={currencyList}
            shippingRouteList={shippingRouteList}
            incotermList={incotermsList}
            paymentTermList={paymentTermList}
        />
    } else {
        return <MOS031RemovePartsPcUi
            mode={mode}
            requesHeaderInfo={requesHeaderInfo}
            requesPartsList={requesPartsList}
        />
    }
}