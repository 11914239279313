import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { ActiveFlag } from "../enums/ActiveFlag"
import { PartsType } from "../enums/PartsType"
import { RolledPartsFlag } from "../enums/RolledPartsFlag"

export type GlobalPartsDownloadFactor = Omit<GlobalPartsFactor, 'page'>
type GlobalPartsDownloadFactorJson = Omit<GlobalPartsFactorJson, 'page'>
export const globalPartsDownloadFactorToJson: Project<GlobalPartsDownloadFactor, GlobalPartsDownloadFactorJson> = obj => ({
    ...obj,
})

export type GlobalPartsFactor = Required & Partial<Optional>
type GlobalPartsFactorJson = JsonRequired & Partial<JsonOptional>
export const globalPartsFactorToJson: Project<GlobalPartsFactor, GlobalPartsFactorJson> = obj => ({
    ...obj,
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
})

export const GlobalPartsFactorFromJson: Project<GlobalPartsFactorJson, GlobalPartsFactor> = json => ({
    ...json,
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page)
})


interface Required extends JsonRequired {

}

interface Optional extends JsonOptional {
    rolledPartsFlagList: RolledPartsFlag[],
    partsTypeList: PartsType[],
    activeFlagList: ActiveFlag[],
    page: PageViewEntity
}

interface JsonRequired {

}

interface JsonOptional {
    partsIdList: number[],
    partsNo: string,
    oldPartsNo: string,
    displayPartsNo: string,
    uomCodeList: string[],
    colorCode: string,
    rolledPartsFlagList: number[],
    rolledPartsUomList: string[],
    remark1: string,
    partsTypeList: number[],
    activeFlagList: number[],
    page: PageViewEntityJson
}
