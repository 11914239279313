import { Dialog, DialogContent } from "@material-ui/core"
import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, RowActionProvider, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { Records } from "@rithe/utils"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CreateCallbackRowAction } from "../../../components/DataGrid/rowActions/CreateCallbackRowAction"
import { EditCallbackRowAction } from "../../../components/DataGrid/rowActions/EditCallbackRowAction"
import { RefreshToolbarItem } from "../../../components/DataGrid/toolbarItems/RefreshToolbarItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { placeOrderDetailFactorSerializer } from "../../../services/order/models/PlaceOrderDetailFactor"
import sessionKeys from "../../../utils/sessionKeys"
import { useSetDataIntoSession } from "../../../utils/sessionUtil"
import { ONS010CoListDialogContent } from "./ONS010CoListDialogContent"
import { ons010Actions, useONS010Selector } from "./ONS010Slice"

export function ONS010SpotTabContent() {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(ons010Actions.searchSpotOrderPlacements())
    }, [dispatch])

    return <SectionCard>
        <SectionCardContent>
            <DataTable />
        </SectionCardContent>
    </SectionCard>
}

function DataTable() {
    const data = useONS010Selector(state => state.spotOrderPlacements)

    const intl = useIntl()
    const columns = useMemo(() => [
        { field: 'customerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.companyCode' }), width: 180 },
        { field: 'sellerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'sellerCode' }), width: 180 },
        { field: 'customerContractNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'customerContractNo' }), width: 240 },
        { field: 'contractDescription', dataTypeName: 'string', title: intl.formatMessage({ id: 'contractDescription' }), width: 300 },
        { field: 'contractRouteCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'contractRouteNo' }), width: 240 },
        { field: 'routeDescription', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.routeDescription' }), width: 350 },
        { field: 'supplierCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierCode' }), width: 180 },
        { field: 'businessType', dataTypeName: CodeCategory.BusinessType, title: intl.formatMessage({ id: 'field.businessType' }), width: 180 },
        { field: 'receiverDcCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'receiverCode' }), width: 180 },
        { field: 'currency', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.currency' }), width: 180 },
    ], [intl])
    const getRowId = useCallback((row: any) => row.contractRouteId, [])

    const display = useCallback((tableRow: TableRow) => tableRow.row?.coList && tableRow.row.coList.length > 0, [])


    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.BusinessType} />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <RowActionProvider name="create" Action={CreateSpotRowAction} />
        <RowActionProvider name="edit" Action={EditDialogRowAction} display={display} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['receiverDcCode', 'currency']}
            columnSettings={{
                customerCode: { disableUserControl: true },
                customerContractNo: { disableUserControl: true }
            }}
            ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={RefreshItem} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Action width={88} />
    </DataGrid>
}

const RefreshItem = () => {
    const dispatch = useDispatch()
    const refresh = useCallback(() => dispatch(ons010Actions.searchSpotOrderPlacements()), [dispatch])
    return <RefreshToolbarItem refresh={refresh} />
}

const CreateSpotRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const navigate = useNavigate()
    const setSesion = useSetDataIntoSession()
    const callback = useCallback((tableRow: TableRow) => {
        setSesion(sessionKeys.Data_ONS011, placeOrderDetailFactorSerializer, {
            contractRouteId: tableRow.row?.contractRouteId,
            coId: null,
            orderStartDate: tableRow.row?.orderFirstDate,
            orderEndDate: tableRow.row?.orderLastDate
        })
        navigate(`/placecustorder-spot/create-${tableRow.row?.contractRouteId}`)
    }, [navigate, setSesion])
    return <CreateCallbackRowAction tableRow={tableRow} access="ORDER.ONS010.CREATE" callback={callback} />
}

const EditDialogRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const [open, setOpen] = useState(false)
    const callback = useCallback(() => setOpen(true), [])
    // const handleClose = () => {
    //     setOpen(false)
    // }

    return <>
        <EditCallbackRowAction tableRow={tableRow} callback={callback} />
        <Dialog open={open}
            // onClose={handleClose} 
            style={{ overflow: 'hidden' }}
            maxWidth="md"
            fullWidth
            fullScreen={false}
        >
            <DialogHeader onClose={() => setOpen(false)}>Placed Order List</DialogHeader>
            <DialogContent>
                <ONS010CoListDialogContent
                    coList={tableRow.row?.coList}
                    contractRouteId={tableRow.row?.contractRouteId}
                />
            </DialogContent>
        </Dialog>
    </>
}

