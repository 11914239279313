import {Project} from "../../../utils/Project"

export type UserQrInfoResponse = Partial<RequestListOptional>
export type UserQrInfoResponseJson = Partial<RequestListJsonOptional>
export const userQrInfoResponseFromJson: Project<UserQrInfoResponseJson, UserQrInfoResponse> = json => ({
    ...json,
})
export const userQrInfoResponseToJson: Project<UserQrInfoResponse, UserQrInfoResponseJson> = obj => ({
    ...obj,
})
interface RequestListOptional extends RequestListJsonOptional {

}
interface RequestListJsonOptional {
  qrCode: string,
  secretKey: string,
  isViewed: number,
}