import { useEffect } from "react"
import { useIntl } from "react-intl"
import { useMatch } from "../../utils/useMatch"


export const DocumentTitle = () => {

    const screenName = useScreen()
    useEffect(() => {
        document.title = (screenName??"") + " - Brivge"
    }, [screenName])
    return null
}

function useScreen() {
    const { path } = useMatch()
    const intl = useIntl()
    return intl.formatMessage({ id: path })
}
