import moment from "moment"
import { Project } from "../../../utils/Project"
import { CustUsageType } from "../enums/CustUsageType"

export type DirectInputDownloadFactor = Required & Partial<Optional>
type DirectInputDownloadFactorJson = JsonRequired & Partial<JsonOptional>
export const directInputDownloadFactorToJson: Project<DirectInputDownloadFactor, DirectInputDownloadFactorJson> = obj => ({
    ...obj,
    yearMonthFrom: obj.yearMonthFrom ? moment(obj.yearMonthFrom).format("YYYYMM") : undefined,
    yearMonthTo: obj.yearMonthTo ? moment(obj.yearMonthTo).format("YYYYMM") : undefined,
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'yearMonthFrom' | 'yearMonthTo' | 'usageTypes'> {
    yearMonthFrom: Date,
    yearMonthTo: Date,
    groupings: string[],
    usageTypes: CustUsageType[],
}

interface JsonRequired {
}

interface JsonOptional {
    customerList: string[],
    sellerList: string[],
    yearMonthFrom: string,
    yearMonthTo: string,
    groupings: string[],
    usageTypes: number[],
}