import moment from "moment"
import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"

export type CustomerStockListFactor = Partial<Optional>
type CustomerStockListFactorJson = Partial<JsonOptional>
export const customerStockListFactorToJson: Project<CustomerStockListFactor, CustomerStockListFactorJson> = obj => ({
    ...obj,
    customerStockDateStart: obj.customerStockDateStart == null ? undefined : moment(obj.customerStockDateStart).format('YYYY-MM-DD'),
    customerStockDateEnd: obj.customerStockDateEnd == null ? undefined : moment(obj.customerStockDateEnd).format('YYYY-MM-DD'),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
})

export const customerStockListFactorFromJson: Project<CustomerStockListFactorJson, CustomerStockListFactor> = json => ({
    ...json,
    customerStockDateStart: json.customerStockDateStart == null ? undefined : moment(json.customerStockDateStart).toDate(),
    customerStockDateEnd: json.customerStockDateEnd == null ? undefined : moment(json.customerStockDateEnd).toDate(),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page),
})

interface Optional extends Omit<JsonOptional, 'customerStockDateStart' | 'customerStockDateEnd'> {
    customerStockDateStart: Date,
    customerStockDateEnd: Date,
    page: PageViewEntity,
}

interface JsonOptional {
    buid: number,
    partsNo: string,
    customerCode: string,
    externalRefNo: string,
    customerPartsNo: string,
    customerBackNo: string,
    customerStockDateStart: string,
    customerStockDateEnd: string,

    // customerIdList: number[],
    customerCodeList: string[],
    customerPartIdList: number[],

    page: PageViewEntityJson
}


