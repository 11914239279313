import { Project } from "../../../utils/Project"
import { ShippingMode } from "../../accounting/enums/ShippingMode"
import { dateFromJson } from "../../utils/deserializer"
import { CoSpotDetailStatus } from "../enums/CoSpotDetailStatus"
import { CoSpotStatus } from "../enums/CoSpotStatus"
import { CoStatus } from "../enums/CoStatus"
import { OrderFrequency } from "../enums/OrderFrequency"
import { OrderType } from "../enums/OrderType"
import { PoSpotDetailStatus } from "../enums/PoSpotDetailStatus"
import { PoSpotStatus } from "../enums/PoSpotStatus"
import { PoStatus } from "../enums/PoStatus"
import { SimulatedType } from "../enums/SimulatedType"
import { SoSpotDetailStatus } from "../enums/SoSpotDetailStatus"
import { SoSpotStatus } from "../enums/SoSpotStatus"
import { SoStatus } from "../enums/SoStatus"

export type InboundPalnData = InboundPalnDetail & { status?: string }
export type EstimatedData = EstimatedDetail & { companyTitle?: string }
export type OrderPartsData = Omit<OrderPartsDetail, 'inboundPlanList' | 'estimatedInboundList'> & { inboundPlanList: InboundPalnData[], estimatedInboundList: EstimatedData[], shortageQty?: number }

export interface OrderDetailResult {
    headerInfo: OrderDetailHead,
    partsDetailList: OrderPartsDetail[]
}

interface OrderDetailResultJson {
    headerInfo: OrderDetailHeadJson,
    partsDetailList: OrderPartsDetailJson[]
}

export const OrderDetailResultDeserializer: Project<OrderDetailResultJson, OrderDetailResult> = json => ({
    headerInfo: OrderDetailHeadDeserializer(json.headerInfo),
    partsDetailList: json.partsDetailList ? json.partsDetailList.map(m => OrderPartsDetailDeserializer(m)) : []
})

export interface OrderDetailHead {
    orderId: number,
    orderNo: string,
    orderRefNo: string,
    orderType: OrderType,
    orderFrequency: OrderFrequency,
    shippingMode: ShippingMode,
    customerId: number,
    customerCode: string,
    submittedDate: Date,
    coDate: Date,
    submittedBy: string,
    contractId: number,
    contractNo: string,
    receivedDate: Date,
    completedDate: Date,
    status: SoStatus | PoStatus | CoStatus,
    spotStatus: SoSpotStatus | PoSpotStatus | CoSpotStatus,
    orgStatus: SoStatus | PoStatus | CoStatus,
    paymentTermsCode: string,
    paymentTermsDesc: string,
    seller: string,
    buyer: string,
    receiver: string,
    remark: string,
    routeLevel: number,
    receiveDcId: number,
    contractRouteId: number,
    soId: number,
    currentDate: Date,

    //------------------------
    coSpotId: number,
    preSpotId: number,
    buId: number,
    supplierId: number,
    customerOrderNo: string,
    orderReference: string,
    paymentTermsId: string,
    currency: string,
    createdBy: string,
    updatedBy: string,
    version: number,
    updatedDate: string,
    createdDate: string,
    sellerUid: string,
    sellerBuId: number,
    creditCheckFlag: number,
    totalAmount?: number,
    suppRoId?: number,
    suppRoStatus?: number,
    suppFlag?: number,

    groupId: number,
    groupNo: string,
}

interface OrderDetailHeadJson {
    orderId: number,
    orderNo: string,
    orderRefNo: string,
    orderType: number,
    orderFrequency: number,
    shippingMode: number,
    customerId: number,
    customerCode: string,
    submittedDate: string,
    coDate: string,
    submittedBy: string,
    contractId: number,
    contractNo: string,
    receivedDate: string,
    completedDate: string,
    status: number,
    spotStatus: number,
    orgStatus: number,
    paymentTermsCode: string,
    paymentTermsDesc: string,
    seller: string,
    buyer: string,
    receiver: string,
    remark: string,
    routeLevel: number,
    receiveDcId: number,
    contractRouteId: number,
    soId: number,
    currentDate: string,
    //--------------

    coSpotId: number,
    preSpotId: number,
    buId: number,
    supplierId: number,
    customerOrderNo: string,
    orderReference: string,
    paymentTermsId: string,
    currency: string,
    createdBy: string,
    updatedBy: string,
    version: number,
    updatedDate: string,
    createdDate: string,
    sellerUid: string,
    sellerBuId: number,
    creditCheckFlag: number,
    totalAmount?: number,

    groupId: number,
    groupNo: string,
}

const OrderDetailHeadDeserializer: Project<OrderDetailHeadJson, OrderDetailHead> = json => ({
    ...json,
    submittedDate: dateFromJson(json.submittedDate),
    receivedDate: dateFromJson(json.receivedDate),
    completedDate: dateFromJson(json.completedDate),
    currentDate: dateFromJson(json.currentDate),
    coDate: dateFromJson(json.coDate),
})


export interface OrderPartsDetail {
    orderDetailId: number,
    coDetailOrigin: string,
    soDetailOrigin: string,
    partsNo: string,
    unitPartsId: number,
    unitPartsNo: string,
    unitPartsName: string,
    backNo: string,
    purchaseOrderNo: string,
    salesOrderNo: string,
    supplierId: number,
    supplierCode: string,
    customerId: number,
    customerCode: string,
    spq: number,
    orderLot: number,
    orderQty: number,
    forceCompletedQty: number,
    detailStatus: SoStatus | PoStatus | CoStatus,
    spotDetailStatus: SoSpotDetailStatus | PoSpotDetailStatus | CoSpotDetailStatus,
    shipper: string,
    buyBuId: number,
    currency: string,
    uomCode: string,
    unitPrice: number,
    currentDcId: number,
    currentInbQty: number,
    currentDeliveryedQty: number,
    inTransitQty: number,
    receiverInbQty: number,
    inboundPlanList: InboundPalnDetail[],
    estimatedInboundList: EstimatedDetail[],
    priceAmount: number,
}

interface OrderPartsDetailJson {
    orderDetailId: number,
    coDetailOrigin: string,
    soDetailOrigin: string,
    partsNo: string,
    unitPartsId: number,
    unitPartsNo: string,
    unitPartsName: string,
    backNo: string,
    purchaseOrderNo: string,
    salesOrderNo: string,
    supplierId: number,
    supplierCode: string,
    customerId: number,
    customerCode: string,
    spq: number,
    orderLot: number,
    orderQty: number,
    forceCompletedQty: number,
    detailStatus: number,
    spotDetailStatus: number,
    shipper: string,
    buyBuId: number,
    currency: string,
    uomCode: string,
    unitPrice: number,
    currentDcId: number,
    currentInbQty: number,
    currentDeliveryedQty: number,
    inTransitQty: number,
    receiverInbQty: number,
    inboundPlanList: InboundPalnDetailJson[],
    estimatedInboundList: EstimatedDetailJson[],
    priceAmount: number,
}

const OrderPartsDetailDeserializer: Project<OrderPartsDetailJson, OrderPartsDetail> = json => ({
    ...json,
    inboundPlanList: json.inboundPlanList ? json.inboundPlanList.map(m => InboundPalnDetailDeserializer(m)) : [],
    estimatedInboundList: json.estimatedInboundList ? json.estimatedInboundList.map(m => EstimatedDetailDeserializer(m)) : []
})

interface InboundPalnDetail {
    coDetailOrigin: number,
    soDetailOrigin: string,
    drDate: Date,
    crdDate: Date,
    crdQty: number,
    totalReceivedQty: number,
    remainQty: number,
}

interface InboundPalnDetailJson {
    coDetailOrigin: number,
    soDetailOrigin: string,
    drDate: string,
    crdDate: string,
    crdQty: number,
    totalReceivedQty: number,
    remainQty: number,
}

const InboundPalnDetailDeserializer: Project<InboundPalnDetailJson, InboundPalnDetail> = json => ({
    ...json,
    drDate: dateFromJson(json.drDate),
    crdDate: dateFromJson(json.crdDate)
})

interface EstimatedDetail {
    soDetailOrigin: string,
    buyerBuId: number,
    estimatedDrDate: Date,
    estimatedCrdDate: Date,
    simulatedType: SimulatedType,
    fromCompany: string,
    toCompany: string,
    fromBuId: number,
    toBuId: number,
    qty: number,
    estimatedQty: number
}

interface EstimatedDetailJson {
    soDetailOrigin: string,
    buyerBuId: number,
    estimatedDrDate: string,
    estimatedCrdDate: string,
    simulatedType: number,
    fromCompany: string,
    toCompany: string,
    fromBuId: number,
    toBuId: number,
    qty: number,
    estimatedQty: number
}

const EstimatedDetailDeserializer: Project<EstimatedDetailJson, EstimatedDetail> = json => ({
    ...json,
    estimatedDrDate: dateFromJson(json.estimatedDrDate),
    estimatedCrdDate: dateFromJson(json.estimatedCrdDate),
})