import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"

export type TnmRegion = Required & Partial<Optional> & TableEntity
type TnmRegionJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const tnmRegionFromJson: Project<TnmRegionJson, TnmRegion> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
}

interface JsonRequired {
    regionCode: string,
    regionName: string,
}

interface JsonOptional {

}