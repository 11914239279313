import { Project } from "@rithe/utils";
import moment from "moment";



export type ProjectResult = Required & Partial<Optional>;

type ProjectResultJson = JsonRequired & Partial<JsonOptional>;

export const projectResultFromJson: Project<ProjectResultJson, ProjectResult> = json => ({
    ...json,
    uploadDate: json.uploadDate ? moment(json.uploadDate).toDate() : undefined,
})

interface Required extends JsonRequired {

}

interface Optional extends Omit<JsonOptional, 'uploadDate'> {
    uploadDate: Date,
}

interface JsonRequired {

}

interface JsonOptional {
    projectId: number,
    projectName: string,
    projectCode: string,
    customerCode: string,
    sellerCode: string,
    sellerUid: string,
    ownerType: number,
    remarks: string,
    uploadDate: string,
    activeFlag: number,
    customerId: number,
    noOfLevel: number,
    step: number,
}
