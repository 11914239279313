import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson, tableEntityToJson } from "../../utils/TableEntity"
import { ActiveFlag } from "../enums/ActiveFlag"

export type TnmBusinessUnitTemp = Required & Partial<Optional> & TableEntity
export type TnmBusinessUnitTempJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const tnmBusinessUnitTempToJson: Project<TnmBusinessUnitTemp, TnmBusinessUnitTempJson> = obj => ({
    ...tableEntityToJson(obj),
})
export const tnmBusinessUnitTempFromJson: Project<TnmBusinessUnitTempJson, TnmBusinessUnitTemp> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
    activeFlag: ActiveFlag,
}

interface JsonRequired {
    buId: number,
    buCode: string,
    regionCode: string,
    buName: string,
}

interface JsonOptional {
    companyId: number,
    buShortCode: string,
    address1: string,
    address2: string,
    address3: string,
    address4: string,
    postalCode: string,
    contact1: string,
    fax1: string,
    telephone1: string,
    email1: string,
    contact2: string,
    fax2: string,
    telephone2: string,
    email2: string,
    timeZone: string,
    calendarId: number,
    activeFlag: number,
}