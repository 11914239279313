import { Loop } from "@material-ui/icons"
import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { EntriesItem, StringItem } from "@rithe/form"
import { Records } from "@rithe/utils"
import { useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, IntlProvider, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useFunctionStore } from "../../../Root"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CallbackRowAction } from "../../../components/DataGrid/rowActions/CallbackRowAction"
import { EditRedirectRowAction } from "../../../components/DataGrid/rowActions/EditRedirectRowAction"
import { ViewRedirectRowAction } from "../../../components/DataGrid/rowActions/ViewRedirectRowAction"
import { CreateRedirectToolbarAction } from "../../../components/DataGrid/toolbarActions/CreateRedirectToolbarAction"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { ActiveFlagTypeProvider } from "../../../components/DataGrid/typeProviders/ActiveFlagTypeProvider"
import { View } from "../../../components/View/View"
import appConfig from "../../../configs/appConfig"
import { Language, effectiveLanguage } from "../../../configs/i18n/Language"
import { ActiveFlag } from "../../../services/privilege/enums/ActiveFlag"
import { useDownloadRoleDetail } from "../../../services/system/apis/roleApi"
import { applicationActions, useApplicationSelector } from "../../Application/applicationSlice"
import { cas010Actions, useCAS010Selector } from "./CAS010Slice"
import messagesEnUs from './i18n/cas010.en-US.json'

const messagesMap: Record<Language, Record<string, string>> = {
    'en-US': messagesEnUs,
    'zh-CN': messagesEnUs,
}

export const CAS010 = () => {

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(cas010Actions.listRoles())
    }, [dispatch])

    return <ScreenIntlProvider>
        <View flex>
            <SectionCard>
                <SectionCardContent>
                    <DataTable />
                </SectionCardContent>
            </SectionCard>
        </View>
    </ScreenIntlProvider>
}

const ScreenIntlProvider = ({ children }: { children: React.ReactNode }) => {
    const language = useApplicationSelector(state => state.i18n.language)
    const timezone = useApplicationSelector(state => state.i18n.timezone)
    const messages = useMemo(() => {
        const messages = messagesMap[effectiveLanguage(language)]
        const defaultMessages = messagesMap[Language.DEFAULT]
        return { ...defaultMessages, ...messages }
    }, [language])
    return <IntlProvider messages={messages} locale={language} timeZone={timezone}>
        {children}
    </IntlProvider>
}

const DataTable = () => {
    const intl = useIntl()
    const roles = useCAS010Selector(state => state.roles)
    const [selections, setSelections] = useState<string[]>([])

    const getActive = useCallback((row: any) => {
        return row.active ? intl.formatMessage({ id: 'enum.active' }) : intl.formatMessage({ id: 'enum.inactive' })
    }, [intl])

    const getCreatedAt = useCallback((row: any) => {
        return row.createdAt ? new Date(row.createdAt) : null
    }, [])

    const getUpdatedAt = useCallback((row: any) => {
        return row.updatedAt ? new Date(row.updatedAt) : null
    }, [])

    const columns = useMemo(() => [
        { field: 'name', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.name' }), width: 150 },
        { field: 'description', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.description' }), width: 300 },
        { field: 'active', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.active' }), width: 150, getCellValue: getActive },
        { field: 'createdAt', dataTypeName: 'date', title: intl.formatMessage({ id: 'column.createdAt' }), width: 150, getCellValue: getCreatedAt },
        { field: 'createdBy', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.createdBy' }), width: 150 },
        { field: 'updatedAt', dataTypeName: 'date', title: intl.formatMessage({ id: 'column.updatedAt' }), width: 150, getCellValue: getUpdatedAt },
        { field: 'updatedBy', dataTypeName: 'string', title: intl.formatMessage({ id: 'column.updatedBy' }), width: 150 },
    ], [getActive, getCreatedAt, getUpdatedAt, intl])
    const getRowId = useCallback((row: any) => row.id, [])
    const actionProps1 = useMemo(() => ({ selections }), [selections])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <ActiveFlagTypeProvider />
        <Data rows={roles} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps1} />
        <ToolbarActionProvider Action={CreateAction} actionProps={{}} />
        <RowActionProvider name="view" Action={ViewRowAction} />
        <RowActionProvider name="edit" Action={EditRowAction} />
        <RowActionProvider name="changeStatus" Action={ActivateDeactivateRowAction} />
        <ColumnFreeze />
        <ColumnVisibility columnSettings={{
            roleName: { disableUserControl: true },
            rowNotes: { disableUserControl: true },
        }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={RoleFilter} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={124} />
    </DataGrid>
}

const RoleFilter = () => {
    const sessionKey = `${appConfig.appFullName}:CAS010.filter`
    const dispatch = useDispatch()
    const [filter, setFilter] = useState(() => {
        let cachedFilter = {}
        try {
            cachedFilter = JSON.parse(sessionStorage.getItem(sessionKey) ?? '{}')
        } catch (e) {}
        const defaultFilter = {
            nameContains: null as string | null,
            descriptionContains: null as string | null,
            statusIn: null as number[] | null,
        }
        return { ...defaultFilter, ...cachedFilter }
    })
    const filterCounter = useCallback((filter: any) => {
        return [
            filter.nameContains,
            filter.descriptionContains,
            filter.statusIn,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const clear = useCallback(() => {
        return {
            nameContains: null,
            descriptionContains: null,
            statusIn: null,
        }
    }, [])
    const submit = useCallback(() => {
        dispatch(cas010Actions.setFilter({
            nameContains: filter.nameContains === null ? undefined : filter.nameContains,
            descriptionContains: filter.descriptionContains === null ? undefined : filter.descriptionContains,
            statusIn: filter.statusIn === null ? undefined : filter.statusIn,
        }))
        dispatch(cas010Actions.listRoles())
        sessionStorage.setItem(sessionKey, JSON.stringify(filter))
    }, [dispatch, filter, sessionKey])

    const intl = useIntl()

    const statusEntries = useMemo<[number, string][]>(() => [
        [ActiveFlag.ACTIVE, intl.formatMessage({ id: 'enum.active' })],
        [ActiveFlag.INACTIVE, intl.formatMessage({ id: 'enum.inactive' })],
    ], [intl])

    return <FilterToolbarItem
        columnCount={3}
        filters={filter}
        onFiltersChange={setFilter}
        filterCounter={filterCounter}
        clear={clear}
        onSubmit={submit}
    >
        <StringItem field="nameContains" label={intl.formatMessage({ id: 'filter.name' })} />
        <StringItem field="descriptionContains" label={intl.formatMessage({ id: 'filter.description' })} />
        <EntriesItem field="statusIn" label={intl.formatMessage({ id: 'filter.statuss' })} entries={statusEntries} />
    </FilterToolbarItem>
}

const DownloadAction = ({ selections }: {
    selections: string[]
}) => {
    const dispatch = useDispatch()
    const dowloadApi = useDownloadRoleDetail()

    const download = useCallback(() => {
        if (selections.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'downloadRoleDetail' }, messages: { code: 'w0002' } }))
            return
        }
        dowloadApi({ roleIds: selections })
    }, [dispatch, dowloadApi, selections])

    return <>
        <DownloadGroupedToolbarAction access="ACCS.CAS010.DOWNLOAD" >
            {onClose =>
                <GroupedCallbackItem label={<FormattedMessage id="download" />} callback={download} onClose={onClose} />
            }
        </DownloadGroupedToolbarAction>
    </>
}

const CreateAction = () => {
    return <CreateRedirectToolbarAction title={<FormattedMessage id="action.create" />} access="ACCS.CAS010.CREATE" path="/role/create" />
}

const ViewRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/role/view-${tableRow.rowId}`, [])
    return <ViewRedirectRowAction title={<FormattedMessage id="action.view" />} tableRow={tableRow} access="ACCS.CAS010.VIEWDETAIL" path={path} />
}

const EditRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/role/edit-${tableRow.rowId}`, [])
    return <EditRedirectRowAction title={<FormattedMessage id="action.edit" />} tableRow={tableRow} access="ACCS.CAS010.EDIT" path={path} />
}

const ActivateDeactivateRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const callback = useCallback(() => {
        const functionId = functionStore.register(() => {
            if (tableRow.row?.active) {
                dispatch(cas010Actions.deactivateRole(tableRow.rowId as string))
            } else {
                dispatch(cas010Actions.activateRole(tableRow.rowId as string))
            }
        })
        dispatch(applicationActions.pushWarning({
            title: { code: tableRow.row?.active ? 'deactivateRole' : "activateRole" },
            messages: { code: 'c0001', args: [intl.formatMessage({ id: tableRow.row?.active ? 'deactivateRole' : "activateRole" })] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [dispatch, functionStore, intl, tableRow.row?.active, tableRow.rowId])
    return tableRow.row?.active ?
        <CallbackRowAction tableRow={tableRow} access="ACCS.CAS010.CHANGESTATUS" title={<FormattedMessage id="action.change.toInActove" />} icon={<Loop />} callback={callback} /> :
        <CallbackRowAction tableRow={tableRow} access="ACCS.CAS010.CHANGESTATUS" title={<FormattedMessage id="action.change.toActive" />} icon={<Loop />} callback={callback} />
}