import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { dateFromJson } from "../../utils/deserializer"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"
import { ContractRequestStatus } from "../enums/ContractRequestStatus"

export type ContractRouteDownloadFactor = Omit<ContractRouteFactor, 'page'>
type ContractRouteDownloadFactorJson = Omit<ContractRouteFactorJson, 'page'>
export const contractRouteDownloadFactorToJson: Project<ContractRouteDownloadFactor, ContractRouteDownloadFactorJson> = obj => ({
    ...obj,
    createdDateStart: dateToJson(obj.createdDateStart),
    createdDateEnd: dateToJson(obj.createdDateEnd),
    modifiedDateStart: dateToJson(obj.modifiedDateStart),
    modifiedDateEnd: dateToJson(obj.modifiedDateEnd),
})

export type ContractRouteFactor = Required & Partial<Optional>
type ContractRouteFactorJson = JsonRequired & Partial<JsonOptional>
export const contractRouteFactorToJson: Project<ContractRouteFactor, ContractRouteFactorJson> = obj => ({
    ...obj,
    createdDateStart: dateToJson(obj.createdDateStart),
    createdDateEnd: dateToJson(obj.createdDateEnd),
    modifiedDateStart: dateToJson(obj.modifiedDateStart),
    modifiedDateEnd: dateToJson(obj.modifiedDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
})

export const ContractRouteFactorFromJson: Project<ContractRouteFactorJson, ContractRouteFactor> = json => ({
    ...json,
    createdDateStart: dateFromJson(json.createdDateStart),
    createdDateEnd: dateFromJson(json.createdDateEnd),
    modifiedDateStart: dateFromJson(json.modifiedDateStart),
    modifiedDateEnd: dateFromJson(json.modifiedDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page),
})

interface Required extends JsonRequired {

}

interface Optional extends Omit<JsonOptional, 'createdDateStart' | 'createdDateEnd' | 'modifiedDateStart' | 'modifiedDateEnd'> {
    statusList: ContractRequestStatus[],
    createdDateStart: Date,
    createdDateEnd: Date,
    modifiedDateStart: Date,
    modifiedDateEnd: Date,
    page: PageViewEntity
}

interface JsonRequired {
}

interface JsonOptional {
    buId: number,
    customerId: number,
    supplierId: number,
    contractRouteNo: string,
    description: string,
    customerList: string[],
    statusList: number[],
    createdBy: string,
    modifiedBy: string,
    createdDateStart: string,
    createdDateEnd: string,
    modifiedDateStart: string,
    modifiedDateEnd: string,
    page: PageViewEntityJson
}
