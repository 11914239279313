import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"
import { ContractRouteStatus } from "../enums/ContractRouteStatus"

export type ContractRouteResult = Required & Partial<Optional> & TableEntity
type ContractRouteResultJson = JsonRequired & Partial<JsonOptional> & TableEntityJson

export const contractRouteResultFromJson: Project<ContractRouteResultJson, ContractRouteResult> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
    status: ContractRouteStatus,
}

interface JsonRequired {
    contractRouteId: number,
    contractRouteNo: string,
    customer: string,
}

interface JsonOptional {
    description: string,
    status: number,
}