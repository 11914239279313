import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { dateToJson } from "../../utils/serializer"
import { OrderType } from "../enums/OrderType"


export type PlaceChangeWCDetailResult = PlaceChangeWCResultRequired & Partial<PlaceChangeWCResultOptional>
type PlaceChangeWCDetailResultson = PlaceChangeWCResultJsonRequired & Partial<PlaceChangeWCResultJsonOptional>

//co detail
interface PlaceChangeWCResultJsonRequired {
    totCoSpotDetailList: PartsDetailJson[],
    status: number,
}
interface PlaceChangeWCResultJsonOptional {
    //co
    coSpotId: number,
    customerOrderNo: string,
    orderReference: string,
    sellerCode: string,
    submittedBy: string
    submittedDate: string,
    orderType: number,
    status: number,
    version: number,
    //ro
    roSpotIdInRo: number,
    coSpotIdInRo: number,
    statusInRo: number,
    reviseTypeInRo: number,
    versionInRo: number,
    receiveDcId: number,
}

interface PlaceChangeWCResultRequired extends Omit<PlaceChangeWCResultJsonRequired, 'totCoSpotDetailList'> {
    totCoSpotDetailList: PartsDetail[],
}

interface PlaceChangeWCResultOptional extends Omit<PlaceChangeWCResultJsonOptional, 'orderType' | 'status'> {
    orderType: OrderType,
    status: number,
}

export const placeChangeWCDetailResultDeserializer: Project<PlaceChangeWCDetailResultson, PlaceChangeWCDetailResult> = json => ({
    ...json,
    totCoSpotDetailList: json.totCoSpotDetailList.map(partsDetailDeserializer),
})

export const placeChangeWCDetailResultSerializer: Project<PlaceChangeWCDetailResult, PlaceChangeWCDetailResultson> = data => ({
    ...data,
    totCoSpotDetailList: data.totCoSpotDetailList.map(partsDetailSerializer),
})

// parts
export interface PartsDetail extends Omit<PartsDetailJson, 'oldReqInboundPlanList' | 'newReqInboundPlanList'> {
    oldReqInboundPlanList: OldInboundPalnDetail[],
    newReqInboundPlanList: NewInboundPalnDetail[],
}

interface PartsDetailJson {
    coSpotDetailId: number,
    coSpotId: number,
    globalPartsId: number,
    partsNo: string,
    partsDescription: string,
    unitPartsNo: string,
    sellerUid: string,
    sellerCode: string,
    sellerPartsId: number,
    sellerPartsNo: string,
    uomCode: string,
    orderLot: number,
    spq: number,
    oldFirmQty: number,
    status: number,
    version: number,

    roSpotDetailIdInRo: number,
    roSpotIdInRo: number,
    coDetailIdInRo: number,
    orderQtyInRo: number,
    statusInRo: number,
    versionInRo: number,
    buyingPrice: number,

    oldReqInboundPlanList: OldInboundPalnDetailJson[] | null,
    newReqInboundPlanList: NewInboundPalnDetailJson[] | null,

}

export const partsDetailDeserializer: Project<PartsDetailJson, PartsDetail> = json => ({
    ...json,
    oldReqInboundPlanList: json.oldReqInboundPlanList ? json.oldReqInboundPlanList.map(oldInboundPalnDetailDeserializer) : [],
    newReqInboundPlanList: json.newReqInboundPlanList ? json.newReqInboundPlanList.map(newInboundPalnDetailDeserializer) : [],
})

export const partsDetailSerializer: Project<PartsDetail, PartsDetailJson> = data => ({
    ...data,
    oldReqInboundPlanList: data.oldReqInboundPlanList ? data.oldReqInboundPlanList.map(inboundPalnDetailSerializer) : [],
    newReqInboundPlanList: data.newReqInboundPlanList ? data.newReqInboundPlanList.map(buyerInboundPalnDetailSerializer) : [],
})

//old inbound plan
interface OldInboundPalnDetail extends Omit<OldInboundPalnDetailJson, 'crdDate'> {
    crdDate: Date
}

interface OldInboundPalnDetailJson {
    coSpotReqId: number,
    coSpotDetailId: number,
    crdDate: string,
    crdQty: number,
    totalReceivedQty: number,
    delayReceivedQty: number,
    createdBy: string,
    createdDate: string,
    updatedBy: string,
    updatedDate: string,
    version: number,
}

const oldInboundPalnDetailDeserializer: Project<OldInboundPalnDetailJson, OldInboundPalnDetail> = json => ({
    ...json,
    crdDate: dateFromJson(json.crdDate)
})
const inboundPalnDetailSerializer: Project<OldInboundPalnDetail, OldInboundPalnDetailJson> = data => ({
    ...data,
    crdDate: dateToJson(data.crdDate)
})

//new inbound plan
export interface NewInboundPalnDetail extends Omit<NewInboundPalnDetailJson, 'crdDate'> {
    crdDate: Date
}

interface NewInboundPalnDetailJson {
    roSpotCrdId?: number,
    roSpotDetailId?: number,
    crdType?: number,
    crdDate: string,
    crdQty?: number | null,
    changeFlag?: number,
    createdBy?: string,
    createdDate?: string,
    updatedBy?: string,
    updatedDate?: string,
    version?: number,
}

const newInboundPalnDetailDeserializer: Project<NewInboundPalnDetailJson, NewInboundPalnDetail> = json => ({
    ...json,
    crdDate: dateFromJson(json.crdDate)


})
const buyerInboundPalnDetailSerializer: Project<NewInboundPalnDetail, NewInboundPalnDetailJson> = data => ({
    ...data,
    crdDate: dateToJson(data.crdDate)

})
