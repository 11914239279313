import React, { useCallback, useEffect, useState } from "react"
import { useGetOutbondPartsList } from "../../../services/delivery/apis/deliveryOutboundApi"
import { OutboundPartsResult } from "../../../services/delivery/models/OutboundPartsResult"
import { LOS033PcUi } from "./LOS033PcUi"


export const LOS033 = () => {
    const [partsList, setPartsList] = useState<OutboundPartsResult[]>([])

    const getOutboundPartsList = useGetOutbondPartsList()

    const search = useCallback(() => {
        getOutboundPartsList(undefined, { silent: true }).then(result => {
            setPartsList(result || [])
        })
    }, [getOutboundPartsList])

    useEffect(() => {
        search()
    }, [search])

    return <LOS033PcUi
        partsList={partsList}
        search={search}
    />
}