import { Route, Routes } from "react-router-dom"
import { AuthNavigate } from "../components/AuthNavigate/AuthNavigate"
import { NotFound } from "../layouts/NotFound/NotFound"
import { CIS010 } from "../layouts/cargo/CIS010/CIS010"
import { CIS011 } from "../layouts/cargo/CIS011/CIS011"
import { AIS030 } from "../layouts/delivery/AIS030/AIS030"
import { AIS031 } from "../layouts/delivery/AIS031/AIS031"
import { AIS040 } from "../layouts/delivery/AIS040/AIS040"
import { AIS041 } from "../layouts/delivery/AIS041/AIS041"
import { LCS010 } from "../layouts/delivery/LCS010/LCS010"
import { LDS010 } from "../layouts/delivery/LDS010/LDS010"
import { LDS010BU } from "../layouts/delivery/LDS010BU/LDS010BU"
import { LDS011 } from "../layouts/delivery/LDS011/LDS011"
import { LDS013 } from "../layouts/delivery/LDS013/LDS013"
import { LIS010 } from "../layouts/delivery/LIS010/LIS010"
import { LIS011 } from "../layouts/delivery/LIS011/LIS011"
import { LIS013 } from "../layouts/delivery/LIS013/LIS013"
import { LIS014 } from "../layouts/delivery/LIS014/LIS014"
import { LOS011 } from "../layouts/delivery/LOS011/LOS011"
import { LOS020 } from "../layouts/delivery/LOS020/LOS020"
import { LOS030 } from "../layouts/delivery/LOS030/LOS030"
import { LOS031 } from "../layouts/delivery/LOS031/LOS031"
import { LOS033 } from "../layouts/delivery/LOS033/LOS033"
import { LOS034 } from "../layouts/delivery/LOS034/LOS034"
import { LOS035 } from "../layouts/delivery/LOS035/LOS035"
import { LOS036 } from "../layouts/delivery/LOS036/LOS036"
import { LOS040 } from "../layouts/delivery/LOS040/LOS040"
import { LSS010 } from "../layouts/delivery/LSS010/LSS010"
import { LSS011 } from "../layouts/delivery/LSS011/LSS011"

export function LogisticsRoutes() {
    return <Routes>
        <Route path="/di/download" element={<AuthNavigate access="LOGI.LDS013.VIEW"><LDS013 /></AuthNavigate>} />,
        <Route path="/di/:diId" element={<AuthNavigate access="LOGI.LDS011.VIEW"><LDS011 /></AuthNavigate>} />,
        <Route path="/diForBu/:diId" element={<AuthNavigate access="LOGI.LDS010BU.VIEW"><LDS011 /></AuthNavigate>} />,
        <Route path="/di" element={<AuthNavigate access="LOGI.LDS010.VIEW"><LDS010 /></AuthNavigate>} />,
        <Route path="/diForBu" element={<AuthNavigate access="LOGI.LDS010BU.VIEW"><LDS010BU /></AuthNavigate>} />,

        <Route path="/inbound/create" element={<AuthNavigate access="LOGI.LIS014.VIEW"><LIS014 /></AuthNavigate>} />,
        <Route path="/inbound/confirm" element={<AuthNavigate access="LOGI.LIS013.VIEW"><LIS013 /></AuthNavigate>} />,
        <Route path="/inbound/detail-:inboundNo" element={<AuthNavigate access="LOGI.LIS011.VIEW"><LIS011 /></AuthNavigate>} />,
        <Route path="/inbound" element={<AuthNavigate access="LOGI.LIS010.VIEW"><LIS010 /></AuthNavigate>} />,
        <Route path="/inbound/manualDetail-:inboundNo" element={<AuthNavigate access="LOGI.LIS011.VIEW"><LIS011 /></AuthNavigate>} />,

        <Route path="/deliveryplan" element={<AuthNavigate access="LOGI.LOS011.VIEW"><LOS011 /></AuthNavigate>} />,

        <Route path="/outboundPrioritization" element={<AuthNavigate access="LOGI.LOS020.VIEW"><LOS020 /></AuthNavigate>} />,
        <Route path="/outbound/parts" element={<AuthNavigate access="LOGI.LOS033.VIEW"><LOS033 /></AuthNavigate>} />,
        <Route path="/outbound/create-simple" element={<AuthNavigate access="LOGI.LOS035.VIEW"><LOS035 /></AuthNavigate>} />,
        <Route path="/outbound/create" element={<AuthNavigate access="LOGI.LOS034.VIEW"><LOS034 /></AuthNavigate>} />,
        <Route path="/outbound/edit-simple-:outboundNo" element={<AuthNavigate access="LOGI.LOS035.VIEW"><LOS035 /></AuthNavigate>} />,
        <Route path="/outbound/edit-:outboundNo" element={<AuthNavigate access="LOGI.LOS034.VIEW"><LOS034 /></AuthNavigate>} />,
        <Route path="/outbound/view-simple-:outboundNo" element={<AuthNavigate access="LOGI.LOS036.VIEW"><LOS036 /></AuthNavigate>} />,
        <Route path="/outbound/view-:outboundNo" element={<AuthNavigate access="LOGI.LOS031.VIEW"><LOS031 /></AuthNavigate>} />,
        <Route path="/outbound" element={<AuthNavigate access="LOGI.LOS030.VIEW"><LOS030 /></AuthNavigate>} />,
        <Route path="/outboundToWIP" element={<AuthNavigate access="LOGI.LOS040.VIEW"><LOS040 /></AuthNavigate>} />,
        <Route path="/cargotracking" element={<AuthNavigate access="LOGI.LCS010.VIEW"><LCS010 /></AuthNavigate>} />,
        <Route path="/shippingdetail/view-:shippingDetailId" element={<AuthNavigate access="LOGI.LSS011.VIEW"><LSS011 /></AuthNavigate>} />,
        <Route path="/shippingdetail/edit-:shippingDetailId" element={<AuthNavigate access="LOGI.LSS011.VIEW"><LSS011 /></AuthNavigate>} />,
        <Route path="/shippingdetail" element={<AuthNavigate access="LOGI.LSS010.VIEW"><LSS010 /></AuthNavigate>} />,

        {/* <Route path="/shippingstatus" element={<AuthNavigate access="LOGI.MTS010.VIEW"><MTS010 /></AuthNavigate>} />, */}

        <Route path="/expcustomsinvoice/view-:invoiceId" element={<AuthNavigate access="LOGI.AIS031.VIEW"><AIS031 /></AuthNavigate>} />,
        <Route path="/expcustomsinvoice" element={<AuthNavigate access="LOGI.AIS030.VIEW"><AIS030 /></AuthNavigate>} />,
        <Route path="/impcustomsinvoice/view-:invoiceId" element={<AuthNavigate access="LOGI.AIS041.VIEW"><AIS041 /></AuthNavigate>} />,
        <Route path="/impcustomsinvoice" element={<AuthNavigate access="LOGI.AIS040.VIEW"><AIS040 /></AuthNavigate>} />,

        <Route path="/containerJourney" element={<AuthNavigate access="LOGI.CIS010.VIEW"><CIS010 /></AuthNavigate>} />,
        <Route path="/cargotrackingDetail" element={<AuthNavigate access="LOGI.CIS011.VIEW"><CIS011 /></AuthNavigate>} />,

        <Route path="*" element={<NotFound scope="logistics" />} />,

    </Routes>
}