import { Typography } from "@material-ui/core"
import { ColumnFreeze, ColumnOrdering, ColumnResizing, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { Form, NumberItem } from "@rithe/form"
import { Records } from '@rithe/utils'
import React, { useCallback, useMemo } from "react"
import { useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { ArCreditCheckResult, CreditLimitResult, DrDataResult, OrderCreditCheckResult } from "../../../services/order/models/CreditLimitResult"



interface CreditLimitPcUiProps {
    data: CreditLimitResult,
    setData: React.Dispatch<React.SetStateAction<CreditLimitResult>>,
    drOfCurrentOrderList: DrDataResult[],
    openArList: ArCreditCheckResult[],
    uninvoicedOrderList: OrderCreditCheckResult[],
}

export const CreditLimitPcUi = (props: CreditLimitPcUiProps) => {
    const { data, setData, drOfCurrentOrderList, openArList, uninvoicedOrderList } = props
    const intl = useIntl()
    return (
        <>
            <SectionCard >
                <SectionCardHeader title={intl.formatMessage({ id: 'BasicInfo' })} />

                <SectionCardContent>
                    <HeaderView data={data} setData={setData} />
                </SectionCardContent>
            </SectionCard>
            {drOfCurrentOrderList ?
                <SectionCard>
                    <SectionCardHeader title={intl.formatMessage({ id: 'drOfCurrentOrder' })} />
                    <Typography variant="subtitle1" color="textPrimary" align="left">
                        {intl.formatMessage({ id: 'total' })}:{1234}
                    </Typography>
                    <SectionCardContent>
                        <DataTable1 {...props} />
                    </SectionCardContent>
                </SectionCard>
                : undefined
            }
            {openArList && openArList.length > 0 ?
                <SectionCard>
                    <SectionCardHeader title={intl.formatMessage({ id: 'openAr' })} />
                    <Typography variant="subtitle1" color="textPrimary" align="left">
                        {intl.formatMessage({ id: 'total' })}:{1234}
                    </Typography>
                    <SectionCardContent>
                        <DataTable2 {...props} />
                    </SectionCardContent>
                </SectionCard>
                : undefined

            }

            {uninvoicedOrderList && uninvoicedOrderList.length > 0 ?
                <SectionCard>
                    <SectionCardHeader title={intl.formatMessage({ id: 'uninvoicedOrder' })} />
                    <Typography variant="subtitle1" color="textPrimary" align="left">
                        {intl.formatMessage({ id: 'total' })}:{1234}
                    </Typography>
                    <SectionCardContent>
                        <DataTable3 {...props} />
                    </SectionCardContent>
                </SectionCard> : undefined

            }
        </>

    )
}

const HeaderView = (props: {
    data: CreditLimitResult,
    setData: React.Dispatch<React.SetStateAction<CreditLimitResult>>,
}) => {
    const { data, setData } = props
    const intl = useIntl()


    return (
        <Form data={data} setData={setData} labelDisplay="block" helperDisplay="tooltip" >
            <NumberItem field="creditLimitAmount" readonly label={intl.formatMessage({ id: 'field.creditLimitAmount' })} />
            <NumberItem field="checkedCreditLimitAmount" readonly label={intl.formatMessage({ id: 'field.checkedCreditLimitAmount' })} />
        </Form>
    )
}

const DataTable1 = ({ drOfCurrentOrderList }: CreditLimitPcUiProps) => {
    const intl = useIntl()
    const columns = useMemo(() => [
        { field: 'drDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.drDate' }), width: 200 },
        { field: 'amount', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.amount' }), width: 200 },
    ], [intl])
    const getRowId = useCallback((row: any) => row.creditId, [])
    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar} >
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <Data rows={drOfCurrentOrderList} columns={columns} getRowId={getRowId} />
        <ColumnFreeze />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Sorting />
        <Filtering />
    </DataGrid>
}

const DataTable2 = ({ openArList }: CreditLimitPcUiProps) => {
    const intl = useIntl()
    const columns = useMemo(() => [
        { field: 'documnetNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.invoiceNumber' }), width: 200 },
        { field: 'documentDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.invoiceDate' }), width: 200 },
        { field: 'amount', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.invoiceAmt' }), width: 200 },

    ], [intl])
    const getRowId = useCallback((row: any) => row.creditId, [])
    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar} >
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <Data rows={openArList} columns={columns} getRowId={getRowId} />
        <ColumnFreeze />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Sorting />
        <Filtering />
    </DataGrid>
}

const DataTable3 = ({ uninvoicedOrderList }: CreditLimitPcUiProps) => {
    const intl = useIntl()
    const columns = useMemo(() => [
        { field: 'documnetNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.orderNo' }), width: 200 },
        { field: 'documentDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.targetInvoiceDate' }), width: 200 },
        { field: 'amount', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderAmt' }), width: 200 },
    ], [intl])
    const getRowId = useCallback((row: any) => row.creditId, [])
    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar} >
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <Data rows={uninvoicedOrderList} columns={columns} getRowId={getRowId} />
        <ColumnFreeze />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Sorting />
        <Filtering />
    </DataGrid>
}
