import { makeStyles } from "@material-ui/core"
import React, { ReactNode } from "react"
import { useViewContext } from "../ViewContext"
import { Stepper } from "./Stepper"

interface StepperActionProps {
    actions?: ReactNode | ReactNode[],
}

export function StepperAction(props: StepperActionProps) {
    const { actions } = props

    const { scrollTarget } = useViewContext()
    const styles = useStyles()
    return <div className={styles.root}>
        <div style={{ marginLeft: 12 }}>
            <Stepper target={scrollTarget ?? undefined} />
        </div>
        {React.Children.map(actions, (action, index) => {
            return <div key={index} style={{ marginLeft: 12 }}>{action}</div>
        })}
    </div>
}

const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        top: 108,
        right: 96,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        background: 'white',
        padding: '8px 12px',
        borderRadius: theme.spacing(1.5),
        zIndex: theme.zIndex.appBar,
        boxShadow: theme.shadows[3],
    },
}))