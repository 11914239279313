import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"

export type ReceivedPlaceChangView = Required & Partial<Optional>
type ReceivedPlaceChangViewJson = JsonRequired & Partial<JsonOptional>
export const receivedPlaceChangOrderToJson: Project<ReceivedPlaceChangView, ReceivedPlaceChangViewJson> = obj => ({
    ...obj,
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
    // orderDate: dateToJson(obj.orderDate),

})
export const receivedPlaceChangOrderFromJson: Project<ReceivedPlaceChangViewJson, ReceivedPlaceChangView> = json => ({
    ...json,
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page),
    // orderDate: dateFromJson(json.orderDate),

})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'page'> {
    page: PageViewEntity,
}

interface JsonRequired {

}

interface JsonOptional {
    roSpotId: number,
    requestNo: string,
    requestType: number,
    requestStatus: number,
    orderType: number,
    customerCode: string,
    version: number,
    preRoSpotId: number,
    poSpotId: number,
    coSpotId: number,
    customerId: number,
    buyerBuId: number,
    buId: number,
    supplierId: number,
    hasPreId: number,
    page: PageViewEntityJson,
    pendingUpdateFlag: number,
    pendingReqeustFlag: number,
}