import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router"
import { useGetZoneList } from "../../../services/common/apis/commonMasterApi"
import { useGetCompanyList, useGetPartnerCompanyList } from "../../../services/master/apis/companyApi"
import { CompanyListFactor, companyListFactorFromJson, companyListFactorToJson } from "../../../services/master/models/CompanyListFactor"
import { TnmCompanyTemp } from "../../../services/master/models/TnmCompanyTemp"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { MLS010PcUi } from "./MLS010PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }
const searchCachekey = sessionKeys.Filters_MLS010

export const MLS010 = () => {
    const { pathname: path } = useLocation()

    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? companyListFactorFromJson(JSON.parse(filters)) : null
    }, [])

    const [filters, setFilters] = useState<CompanyListFactor>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<TnmCompanyTemp[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [timeZoneMap, setTimeZoneMap] = useState<Record<string, string>>({})
    const getCompanyList = useGetCompanyList()
    const getPartnerCompanyList = useGetPartnerCompanyList()
    const queryCompanyList = path === "/masterOverView/company" ? getPartnerCompanyList : getCompanyList

    const search = useCallback((filters: CompanyListFactor) => {
        queryCompanyList(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(companyListFactorToJson(filters)))
        })
    }, [queryCompanyList])

    useSearchOnLoad(search, filters)

    const getZoneList = useGetZoneList()
    useEffect(() => {
        getZoneList(undefined, { silent: true }).then(result => {
            const map: Record<string, string> = {}
            result.forEach(({ first, second }) => map[first] = second)
            setTimeZoneMap(map)
        })
    }, [getZoneList])

    return <MLS010PcUi
        path={path}
        filters={filters}
        setFilters={setFilters}
        search={search}
        data={data}
        totalCount={totalCount}
        timeZoneMap={timeZoneMap}
    />
}