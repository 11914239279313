import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { useParams } from "react-router"
import { useComMasterGetAllConttype } from "../../../services/common/apis/commonMasterApi"
import { useGetShippingDetail } from "../../../services/delivery/apis/deliveryShippingDetailApi"
import { ShippingDetailEntity } from "../../../services/delivery/models/ShippingDetailEntity"
import { useGetPortList } from "../../../services/master/apis/portApi"
import { TnmContainerType } from "../../../services/master/models/TnmContainerType"
import { TnmPort } from "../../../services/master/models/TnmPort"
import { useMatch } from "../../../utils/useMatch"
import { LSS011PcUi } from "./LSS011PcUi"

export const LSS011 = () => {
    const { shippingDetailId } = useParams() as any
    const { path } = useMatch()
    const [data, setData] = React.useState<ShippingDetailEntity>()
    const [portList, setPortList] = React.useState<TnmPort[]>([])
    const [containerTypeList, setContainerTypeList] = React.useState<TnmContainerType[]>([])

    const getData = useGetShippingDetail()
    const getPorts = useGetPortList()
    const getContainerTypes = useComMasterGetAllConttype()

    const mode = useMemo(() => path === '/shippingdetail/edit-:shippingDetailId' ? 'edit' : 'view', [path])

    const search = useCallback(() => {
        getData(shippingDetailId, { serialized: true, silent: true }).then(data => {
            setData(data)
        })
    }, [getData, shippingDetailId])
    const loadedRef = useRef(false)
    useEffect(() => {
        if (!loadedRef.current) {
            search()
            getPorts({}, { serialized: true, silent: true }).then(data => {
                setPortList((data.data ?? []).map(result => ({ ...result, portCode: result.portCode! })))
            })
            getContainerTypes(undefined, { serialized: true, silent: true }).then(data => {
                setContainerTypeList(data ?? [])
            })
            loadedRef.current = true
        }
    }, [getContainerTypes, getPorts, search])

    return <LSS011PcUi
        mode={mode}
        search={search}
        data={data}
        setData={setData}
        portList={portList}
        containerTypeList={containerTypeList}
    />
}