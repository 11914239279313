import { Add } from "@material-ui/icons"
import { TableRow } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import React from "react"
import { FormattedMessage } from "react-intl"
import { RedirectRowAction } from "./RedirectRowAction"

interface CreateRedirectRowActionProps {
    access?: string,
    power?: boolean,
    title?: NonNullable<React.ReactNode>,
    icon?: NonNullable<React.ReactNode>,
    path: string | ((tableRow: TableRow) => string),
    state?: Record<string, unknown> | ((tableRow: TableRow) => Record<string, unknown>)
}

export const CreateRedirectRowAction = (props: CreateRedirectRowActionProps & DataGridRowActionProps) => {
    const defaultProps = {
        title: <FormattedMessage id="create" />,
        icon: <Add />
    }
    return <RedirectRowAction {...defaultProps} {...props} />
}