import { makeStyles, MenuItem, Select, Typography } from "@material-ui/core";
import { DataGridPagingPageSizeSelectProps } from "@rithe/data-grid/dist/components/paging/DataGridPagingPageSizeSelect";
import React from "react";

export const PageSizeSelect = (props: DataGridPagingPageSizeSelectProps) => {
    const { pageSize, pageSizes, setPageSize } = props

    const styles = useStyles()
    return <div className={styles.root}>
        <Typography variant="body2">Show:</Typography>
        <Select
            value={pageSize}
            onChange={e => setPageSize(e.target.value as number)}
            className={styles.select}
        >
            {pageSizes.map(pageSize => <MenuItem key={pageSize} value={pageSize}>{`${pageSize} rows`}</MenuItem>)}
        </Select>
    </div>
}

const useStyles = makeStyles(theme => ({
    root: {
        flex: '0 0 auto',
        display: 'flex',
        alignItems: 'center',
    },
    select: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(0, 2),
        marginLeft: theme.spacing(0.5),
    }
}))