import { Project } from "@rithe/utils";
import moment from "moment";



export type UsageHistoryResult = Required & Partial<Optional>;

type UsageHistoryResultJson = JsonRequired & Partial<JsonOptional>;

export const usageHistoryResultFromJson: Project<UsageHistoryResultJson, UsageHistoryResult> = json => ({
    ...json,
    uploadDate: json.uploadDate ? moment(json.uploadDate).toDate() : undefined,
})

interface Required extends JsonRequired {

}

interface Optional extends Omit<JsonOptional, 'uploadDate'> {
    uploadDate: Date,
}

interface JsonRequired {
    usageHistoryId: number,
}

interface JsonOptional {
    projectId: number,
    bomNo: string,
    remarks: string,
    uploadDate: string,
    activeFlag: number,
    customerId: number,
    noOfLevel: number,
}
