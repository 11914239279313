import React from 'react'
import { FormattedMessage } from 'react-intl'
import { CallbackViewAction } from './CallbackViewAction'

interface PrintCallbackViewActionProps {
    access?: string,
    disabled?: boolean,
    outlined?: boolean,
    callback: () => void,
}

export const PrintCallbackViewAction = (props: PrintCallbackViewActionProps) => {
    return <CallbackViewAction {...props} title={<FormattedMessage id="print" />} />
}