import { Project } from "../../../../utils/Project"
import { dateToJson } from "../../../utils/serializer"



export type DailyRundownFactor = Required & Partial<Optional>
type DailyRundownFactorJson = JsonRequired & Partial<JsonOptional>

export const dailyRundownFactorToJson: Project<DailyRundownFactor, DailyRundownFactorJson> = obj => ({
    ...obj,
    stockDate: dateToJson(obj.stockDate),
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'stockDate'> {
    stockDate: Date,
}

interface JsonRequired {
}

interface JsonOptional {
    customerPartsIdList: number[],
    includePlanFlag: boolean,
    simulateByParts: boolean,
    baseCarrierDate: boolean,
    stockDate: String,
}