import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"


export type OrderGroupFactor = Required & Partial<Optional>
type OrderGroupFactorJson = JsonRequired & Partial<JsonOptional>
export const orderGroupFactorToJson: Project<OrderGroupFactor, OrderGroupFactorJson> = obj => ({
    ...obj,
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
})

export const orderGroupFactorFromJson: Project<OrderGroupFactorJson, OrderGroupFactor> = json => ({
    ...json,
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page),

})


interface Required extends JsonRequired {

}

interface Optional extends JsonOptional {
    page: PageViewEntity
}

interface JsonRequired {

}

interface JsonOptional {
    grouping: string
    businessTypeList: number[],
    orderFrequency: number[],
    description: string,
    remark: string,
    page: PageViewEntityJson
}

