import { AxiosInstance } from 'axios'
import { PageResultEntity } from '../../utils/PageResultEntity'
import { CreateRoleAPIRequest } from '../models/CreateRoleAPIRequest'
import { ListRolesAPIRequest } from '../models/ListRolesAPIRequest'
import { RoleAPIResponse } from '../models/RoleAPIResponse'
import { UpdateRoleAPIRequest } from '../models/UpdateRoleAPIRequest'
import { useCallback } from 'react'
import { useDownload } from '../../../utils/fetch/useFetch'

const roleApi = {
    get: function(axiosInstance: AxiosInstance, id: string) {
        return axiosInstance.get<RoleAPIResponse>('/common-system-api/roles/:id', {pathVars: {id}})
    },
    batchGet: function (axiosInstance: AxiosInstance, ids: string[]) {
        return axiosInstance.post<RoleAPIResponse[]>('/common-system-api/roles/batch-get', ids) 
    },
    list: function (axiosInstance: AxiosInstance, data: ListRolesAPIRequest) {
        return axiosInstance.get<PageResultEntity<RoleAPIResponse>>('/common-system-api/roles', {params: data})
    },
    create: function (axiosInstance: AxiosInstance, data: CreateRoleAPIRequest) {
        return axiosInstance.post<RoleAPIResponse>('/common-system-api/roles', data)
    },
    batchCreate: function (axiosInstance: AxiosInstance, data: CreateRoleAPIRequest[]) {
        return axiosInstance.post<RoleAPIResponse[]>('/common-system-api/roles/batch-create', data)
    },
    update: function (axiosInstance: AxiosInstance, data: UpdateRoleAPIRequest) {
        return axiosInstance.put<RoleAPIResponse>('/common-system-api/roles/:id', data, {pathVars: {id: data.roleId}})
    },
    batchUpdate: function (axiosInstance: AxiosInstance, data: UpdateRoleAPIRequest[]) {
        return axiosInstance.post<RoleAPIResponse[]>('/common-system-api/roles/batch-update', data)
    },
    delete: function (axiosInstance: AxiosInstance, id: string) {
        return axiosInstance.delete('/common-system-api/roles/:id', {pathVars: {id}})
    },
    batchDelete: function (axiosInstance: AxiosInstance, ids: string[]) {
        return axiosInstance.post('/common-system-api/roles/batch-delete', ids)
    },
    activate: function (axiosInstance: AxiosInstance, id: string) {
        return axiosInstance.post<RoleAPIResponse>('/common-system-api/roles/:id/activate', null, {pathVars: {id}})
    },
    batchActivate: function (axiosInstance: AxiosInstance, ids: string[]) {
        return axiosInstance.post<RoleAPIResponse[]>('/common-system-api/roles/batch-activate', ids)
    },
    deactivate: function (axiosInstance: AxiosInstance, id: string) {
        return axiosInstance.post<RoleAPIResponse>('/common-system-api/roles/:id/deactivate', null, {pathVars: {id}})
    },
    batchDeactivate: function (axiosInstance: AxiosInstance, ids: string[]) {
        return axiosInstance.post<RoleAPIResponse[]>('/common-system-api/roles/batch-deactivate', ids)
    },
}

export const useDownloadRoleDetail = () => {
    const url = '/common-system-api/roles/downloadRoleDetail'
    const payloadProject = useCallback((obj: { roleIds: string[] }) => obj.roleIds, [])
    return useDownload<{ roleIds: string[] }>(url, payloadProject, { fileTimestamp: true })
}


export default roleApi