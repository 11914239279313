import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Row, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { DateRangeItem, EntriesItem, StringItem } from "@rithe/form"
import { Records } from "@rithe/utils"
import { default as React, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useFunctionStore } from "../../../Root"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { ViewRedirectRowAction } from "../../../components/DataGrid/rowActions/ViewRedirectRowAction"
import { CancelCallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/CancelCallbackToolbarAction"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { GroupedRedirectItem } from "../../../components/DataGrid/toolbarActions/GroupedRedirectItem"
import { UploadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/UploadGroupedToolbarAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { useDoCancelOfDi } from "../../../services/delivery/apis/deliveryCustApi"
import { useDownloadDiByModule, useDownloadDiByParts, useDownloadDiByRoll, useDownloadDiChangeForm } from "../../../services/delivery/apis/deliveryDownloadApi"
import { useDeliveryUploadDi, useDeliveryUploadDiChangeFrom } from "../../../services/delivery/apis/deliveryUploadApi"
import { CustDeliveryTransfer } from "../../../services/delivery/models/CustDeliveryTransfer"
import { CustDeliveryView } from "../../../services/delivery/models/CustDeliveryView"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { TnmCustomer } from "../../../services/master/models/TnmCustomer"
import { TnmDistributionCenter } from "../../../services/master/models/TnmDistributionCenter"
import { applicationActions } from "../../Application/applicationSlice"

interface LDS010PcUiProps {
    filters: CustDeliveryView,
    setFilters: React.Dispatch<React.SetStateAction<CustDeliveryView>>,
    search: (filters: CustDeliveryView) => void,
    data: CustDeliveryTransfer[],
    totalCount: number,
    customerList: TnmCustomer[],
    dcList: TnmDistributionCenter[],
}

export const LDS010PcUi = (props: LDS010PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = (props: LDS010PcUiProps) => {
    const { filters, customerList, dcList, search, data, setFilters } = props
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])

    const columns = useMemo(() => {
        const customerCodeMap: Record<number, string> = {}
        for (const customer of customerList) {
            customerCodeMap[customer.customerId] = customer.customerCode
        }
        const dcCodeMap: Record<number, string> = {}
        for (const dc of dcList) {
            dcCodeMap[dc.dcId] = dc.dcCode
        }
        return [
            { field: 'customerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerCode' }), width: 200, getCellValue: (row: Row) => customerCodeMap[row.customerId] },
            { field: 'diRefNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.diRefNo' }), width: 250 },
            { field: 'diNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.diNo' }), width: 300 },
            { field: 'diVersion', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.diVersion' }), width: 180 },
            { field: 'dcCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.dcCode' }), width: 180, getCellValue: (row: Row) => dcCodeMap[row.dcId] },
            { field: 'diType', dataTypeName: CodeCategory.DiType, title: intl.formatMessage({ id: 'field.diType' }), width: 180 },
            { field: 'diDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.diDate' }), width: 180 },
            { field: 'status', dataTypeName: CodeCategory.DiStatus, title: intl.formatMessage({ id: 'field.status' }), width: 150 },
            { field: 'remark', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.remark' }), width: 300 },
        ]
    }, [customerList, dcList, intl])
    const getRowId = useCallback((row: any) => row.diId, [])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search, customerList, dcList }), [filters, setFilters, search, customerList, dcList])
    const actionProps1 = useMemo(() => ({ selections, data, filters, search }), [selections, data, filters, search])
    const actionProps2 = useMemo(() => ({ search, filters }), [filters, search])
    const actionProps3 = useMemo(() => ({ selections }), [selections])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.DiType} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.DiStatus} />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={CancelAction} actionProps={actionProps1} />
        <ToolbarActionProvider Action={UploadAction} actionProps={actionProps2} />
        <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps3} />
        <RowActionProvider name="view" Action={ViewRowAction} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['loadingPort', 'dischargePort', 'approvedBy']}
            columnSettings={{
                customerCode: { disableUserControl: true },
                dcCode: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={52} />
    </DataGrid>
}

const FilterItem = (props: {
    filters: CustDeliveryView,
    setFilters: React.Dispatch<React.SetStateAction<CustDeliveryView>>,
    search: (filters: CustDeliveryView) => void,
    customerList: TnmCustomer[],
    dcList: TnmDistributionCenter[]
}) => {
    const { filters, customerList, dcList, setFilters, search } = props
    const customerEntries: [number, string][] = useMemo(() => customerList.map(m => [m.customerId, m.customerCode]), [customerList])
    const dcEntries: [number, string][] = useMemo(() => dcList.map(m => [m.dcId, m.dcCode]), [dcList])

    const diDateGetValue = useCallback((filters: CustDeliveryView) => {
        return [filters.diDateStart ?? null, filters.diDateEnd ?? null]
    }, [])

    const diDateMapValue = useCallback((filters: CustDeliveryView, value: any) => {
        return { ...filters ?? {}, diDateStart: value[0], diDateEnd: value[1] }
    }, [])

    const clear = useCallback((filters: CustDeliveryView) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: CustDeliveryView) => {
        return [
            filters.customerIdList,
            filters.diRefNo,
            filters.diNo,
            filters.dcIdList,
            filters.diTypeList,
            filters.statusList,
            filters.remark,
            filters.diDateStart || filters.diDateEnd
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()
    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <EntriesItem field="customerIdList" label={intl.formatMessage({ id: 'field.customerCode' })} entries={customerEntries} />
        <StringItem field="diRefNo" label={intl.formatMessage({ id: 'field.diRefNo' })} />
        <StringItem field="diNo" label={intl.formatMessage({ id: 'field.diNo' })} />
        <EntriesItem field="dcIdList" label={intl.formatMessage({ id: 'field.dcCode' })} entries={dcEntries} />
        <CodesItem field="diTypeList" label={intl.formatMessage({ id: 'field.diType' })} code={CodeCategory.DiType} />
        <CodesItem field="statusList" label={intl.formatMessage({ id: 'field.status' })} code={CodeCategory.DiStatus} />
        <StringItem field="remark" label={intl.formatMessage({ id: 'field.remark' })} />
        <DateRangeItem field="diDate" label={intl.formatMessage({ id: 'field.diDate' })} getValue={diDateGetValue} mapValue={diDateMapValue} />
    </FilterToolbarItem>
}


const CancelAction = ({ selections, data, filters, search }: { selections: number[], data: CustDeliveryTransfer[], filters: CustDeliveryView, search: (filters: CustDeliveryView) => void, }) => {
    const dispatch = useDispatch()
    const cancelApi = useDoCancelOfDi()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'cancel' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const callback = useCallback(() => {
        if (selections.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'cancel' }, messages: { code: 'w0002' } }))
            return
        }
        const functionId = functionStore.register(() => {
            setDisabled(true)
            const diList = data.filter(f => selections.some(e => e === f.diId)).map(m => { return { diId: m.diId, diVersion: m.diVersion } as CustDeliveryTransfer })
            cancelApi(diList).then(() => search(filters)).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [selections, functionStore, dispatch, title, data, cancelApi, search, filters])
    return <CancelCallbackToolbarAction access="LOGI.LDS010.CANCELDI" callback={callback} disabled={disabled} />
}

const UploadAction = ({ search, filters }: { search: (filter: CustDeliveryView) => void, filters: CustDeliveryView }) => {
    const uploadDiChangeApi = useDeliveryUploadDiChangeFrom()
    const uploadDiApi = useDeliveryUploadDi()
    const uploadChangeForm = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            files && uploadDiChangeApi({ file: files[0] }, { serialized: true }).then(() => search(filters))
        })
    }, [filters, search, uploadDiChangeApi])
    const uploadDiForm = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            files && uploadDiApi({ file: files[0] }, { serialized: true }).then(() => search(filters))
        })
    }, [filters, search, uploadDiApi])
    return <UploadGroupedToolbarAction>
        {(popupUpload, onClose) => <>
            <GroupedCallbackItem label={<FormattedMessage id="uploadChangeForm" />} callback={() => uploadChangeForm(popupUpload)} onClose={onClose} />
            <GroupedCallbackItem label={<FormattedMessage id="uploadDiForm" />} callback={() => uploadDiForm(popupUpload)} onClose={onClose} />
        </>}
    </UploadGroupedToolbarAction>
}

const DownloadAction = ({ selections }: { selections: number[] }) => {
    const dispatch = useDispatch()
    const downloadDiByPartsApi = useDownloadDiByParts()
    const downloadDiByRollApi = useDownloadDiByRoll()
    const downloadDiByModuleApi = useDownloadDiByModule()
    const downloadDiChangeformApi = useDownloadDiChangeForm()
    const downloadByParts = useCallback(() => downloadDiByPartsApi(undefined), [downloadDiByPartsApi])
    const downloadByRoll = useCallback(() => downloadDiByRollApi(undefined), [downloadDiByRollApi])
    const downloadByModule = useCallback(() => downloadDiByModuleApi(undefined), [downloadDiByModuleApi])
    const downloadChangeform = useCallback(() => {
        if (selections.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'downloadChangeform' }, messages: { code: 'w0002' } }))
            return
        }
        downloadDiChangeformApi({ diIdList: selections })
    }, [dispatch, downloadDiChangeformApi, selections])

    return <DownloadGroupedToolbarAction access="LOGI.LDS010.DOWNLOADDI">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="downloadByParts" />} callback={downloadByParts} onClose={onClose} />
            <GroupedCallbackItem label={<FormattedMessage id="downloadByModule" />} callback={downloadByModule} onClose={onClose} />
            <GroupedCallbackItem label={<FormattedMessage id="DownloadDiByRoll" />} callback={downloadByRoll} onClose={onClose} />
            <GroupedRedirectItem label={<FormattedMessage id="downloadMonitor" />} path="/di/download" onClose={onClose} />
            <GroupedCallbackItem label={<FormattedMessage id="downloadChangeform" />} callback={downloadChangeform} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}

const ViewRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/di/${tableRow.rowId}`, [])
    return <ViewRedirectRowAction tableRow={tableRow} access="LOGI.LDS010.VIEWDETAIL" path={path} />
}