import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { useLayoutEffect, useState } from "react";
import { useTabContext } from "../View/Tab/TabContext";

interface CardPanelProps {
    value: any,
    children?: React.ReactNode | React.ReactNode[],
}

export function CardPanel(props: CardPanelProps) {
    const { value, children } = props

    const { selectedValue: selectedTabId } = useTabContext()
    const selected = value === selectedTabId

    const [loaded, setLoaded] = useState(false)
    useLayoutEffect(() => {
        selected && setLoaded(true)
    }, [selected])

    const styles = useStyles()
    return loaded || selected ? <div className={clsx(styles.root, selected || styles.hidden)}>{children}</div> : null
}

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%',
    },
    hidden: {
        display: 'none',
    },
})