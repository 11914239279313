import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { Projects } from "../../../utils/Projects"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { CustomsInvoice, customsInvoiceDeserializer } from "../models/CustomsInvoice"
import { CustomsInvoiceFta, customsInvoiceFtaSerializer } from "../models/CustomsInvoiceFta"
import { CustomsInvoiceTransfer, customsInvoiceTransferFromJson } from "../models/CustomsInvoiceTransfer"
import { CustomsInvoiceView, customsInvoiceViewToJson } from "../models/CustomsInvoiceView"

export const useGetExpCustomsInvoicePageByVO = () => {
    const url = '/lcbm-customs-api/api/getExpCustomsInvoicePageByVO'
    const payloadProject = useCallback(customsInvoiceViewToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(customsInvoiceTransferFromJson), [])
    return usePost<CustomsInvoiceView, PageResultEntity<CustomsInvoiceTransfer>>(url, payloadProject, resultProject)
}

export const useGetImpCustomsInvoicePageByVO = () => {
    const url = '/lcbm-customs-api/api/getImpCustomsInvoicePageByVO'
    const payloadProject = useCallback(customsInvoiceViewToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(customsInvoiceTransferFromJson), [])
    return usePost<CustomsInvoiceView, PageResultEntity<CustomsInvoiceTransfer>>(url, payloadProject, resultProject)
}

export const useCompletedExpCustoms = () => {
    const url = '/lcbm-customs-api/api/completedExpCustoms'
    const payloadProject = useCallback((obj: { invoiceNoList: string[] }) => obj.invoiceNoList, [])
    const resultProject = Projects.IGNORE
    return usePost<{ invoiceNoList: string[] }, undefined>(url, payloadProject, resultProject)
}

export const useCompletedImpCustoms = () => {
    const url = '/lcbm-customs-api/api/completedImpCustoms'
    const payloadProject = useCallback((obj: { invoiceNoList: string[] }) => obj.invoiceNoList, [])
    const resultProject = Projects.IGNORE
    return usePost<{ invoiceNoList: string[] }, undefined>(url, payloadProject, resultProject)
}

export const useUpdateExpCargoStatus = () => {
    const url = '/lcbm-customs-api/api/updateExpCargoStatus'
    const payloadProject = Projects.KEEP
    const resultProject = Projects.IGNORE
    return usePost<{ invoiceNo: string, cargoStatus?: string }, undefined>(url, payloadProject, resultProject)
}

export const useUpdateImpCargoStatus = () => {
    const url = '/lcbm-customs-api/api/updateImpCargoStatus'
    const payloadProject = Projects.KEEP
    const resultProject = Projects.IGNORE
    return usePost<{ invoiceNo: string, cargoStatus?: string }, undefined>(url, payloadProject, resultProject)
}

export const useGetExpCargoStatus = () => {
    const url = '/lcbm-customs-api/api/getExpCargoStatus'
    const payloadProject = useCallback((obj: { invoiceNo: string }) => obj.invoiceNo, [])
    const resultProject = useCallback((json: { cargoStatus: string[] }) => json, [])
    return usePost<{ invoiceNo: string }, { cargoStatus: string[] }>(url, payloadProject, resultProject)
}

export const useGetImpCargoStatus = () => {
    const url = '/lcbm-customs-api/api/getImpCargoStatus'
    const payloadProject = useCallback((obj: { invoiceNo: string }) => obj.invoiceNo, [])
    const resultProject = useCallback((json: { cargoStatus: string[] }) => json, [])
    return usePost<{ invoiceNo: string }, { cargoStatus: string[] }>(url, payloadProject, resultProject)
}

export const useGetCustomsInvoice = () => {
    const url = '/lcbm-customs-api/api/getCustomsInvoice'
    const payloadProject = useCallback((obj: { invoiceId: number }) => obj.invoiceId, [])
    const resultProject = useCallback(customsInvoiceDeserializer, [])
    return usePost<{ invoiceId: number }, CustomsInvoice>(url, payloadProject, resultProject)
}

export const useUpdateFtaInfo = () => {
    const url = '/lcbm-customs-api/api/updateFtaInfo'
    const payloadProject = useCallback(customsInvoiceFtaSerializer, [])
    const resultProject = Projects.IGNORE
    return usePost<CustomsInvoiceFta, undefined>(url, payloadProject, resultProject)
}