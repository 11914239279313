import { CloudDownload } from "@material-ui/icons"
import React, { ReactNode } from "react"
import { FormattedMessage } from "react-intl"
import { GroupedToolbarAction } from "./GroupedToolbarAction"

interface DownloadGroupedToolbarActionProps {
    access?: string,
    power?: boolean,
    title?: NonNullable<React.ReactNode>,
    icon?: NonNullable<React.ReactNode>,
    children?: (onClose: () => void) => ReactNode | ReactNode[],
}

export const DownloadGroupedToolbarAction = (props: DownloadGroupedToolbarActionProps) => {
    const {
        access,
        power,
        title = <FormattedMessage id="download" />,
        icon = <CloudDownload />,
        children,
    } = props
    return <GroupedToolbarAction access={access} power={power} title={title} icon={icon}>
        {children}
    </GroupedToolbarAction>
}