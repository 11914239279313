/* ---------> Please arrange those sessionKeys in order <--------- */

const sessionKey_Filters: Record<string, string> = {

    /* Accounting */
    'Filters_AIS010': 'Filters_AIS010',
    'Filters_AIS020': 'Filters_AIS020',
    'Filters_AIS030': 'Filters_AIS030',
    'Filters_AIS040': 'Filters_AIS040',
    'Filters_AIS050': 'Filters_AIS050',
    'Filters_AIS060': 'Filters_AIS060',

    /* Logistics */
    'Filters_LDS010': 'Filters_LDS010',
    'Filters_LIS010': 'Filters_LIS010',
    'Filters_LOS030': 'Filters_LOS030',
    'Filters_TDL010': 'Filters_TDL010',

    /* Master */
    'Filters_MTS010': 'Filters_MTS010',
    'Filters_MLS010': 'Filters_MLS010',
    'Filters_MLS020': 'Filters_MLS020',
    'Filters_MLS030': 'Filters_MLS030',
    'Filters_MLS040': 'Filters_MLS040',
    'Filters_MLS050': 'Filters_MLS050',
    'Filters_MLS060': 'Filters_MLS060',
    'Filters_MLS070': 'Filters_MLS070',
    'Filters_MLS080': 'Filters_MLS080',
    'Filters_MLS090': 'Filters_MLS090',
    'Filters_MLS100': 'Filters_MLS100',
    'Filters_MLS110': 'Filters_MLS110',
    'Filters_MLS120': 'Filters_MLS120',
    'Filters_MLS130': 'Filters_MLS130',
    'Filters_MLS150': 'Filters_MLS150',
    'Filters_MLS160': 'Filters_MLS160',
    'Filters_MLS170': 'Filters_MLS170',
    'Filters_MSESF010': 'Filters_MSESF010',
    'Filters_MOS020': 'Filters_MOS020',
    'Filters_MOS030': 'Filters_MOS030',

    'Filters_MSESF030': 'Filters_MSESF030',

    /* Mt */
    'Filters_MTS020': 'Filters_MTS020',
    'Filters_MTS030': 'Filters_MTS030',

    /* Order */
    'Filters_OCS010': 'Filters_OCS010',
    'Filters_OPS010': 'Filters_OPS010',
    'Filters_ORS010': 'Filters_ORS010',
    'Filters_OSS010': 'Filters_OSS010',
    'Filters_OCS030': 'Filters_OCS030',
    'Filters_OPS020': 'Filters_OPS020',
    'Filters_OSS020': 'Filters_OSS020',


    /* Privilege */
    'Filters_CAS010': 'Filters_CAS010',
    'Filters_CAS020': 'Filters_CAS020',
    'Filters_UserRegistrationApprove': 'Filters_UserRegistrationApprove',
    'Filters_CAS030': 'Filters_CAS030',


    /* Smt */
    'Filters_SMGTS110': 'Filters_SMGTS110',
    'Filters_SMGTS410': 'Filters_SMGTS410',
    'Filters_MSOGS010': 'Filters_MSOGS010',
    'Filters_CSUGS130': 'Filters_CSUGS130',
    'Filters_CSUHS110': 'Filters_CSUHS110',
    'Filters_CSUHS120': 'Filters_CSUHS120',
    'Filters_CIS010': 'Filters_CIS010',
    'Filters_MSAUF010': 'Filters_MSAUF010',
    'Filters_SMGTS610': 'Filters_SMGTS610',
    'Filters_MSAUF020': 'Filters_MSAUF020',

    /* integration */
    'Filters_INT040': 'Filters_INT040',

}

/* ---------> Please arrange those sessionKeys in order <--------- */

const sessionKey_Data: Record<string, string> = {

    /* Accounting */


    /* Logistics */
    'Data_AIS041': 'Data_AIS041',
    'Data_AIS051': 'Data_AIS051',

    /* Master */
    'Data_MLS071': 'Data_MLS071',
    'Data_MLS081': 'Data_MLS081',
    'Data_MOS031': 'Data_MOS031',
    'Data_MLS260': 'Data_MLS260',


    /* Mt */


    /* Netdist */


    /* Order */
    'Data_ONS011': 'Data_ONS011',
    'Data_ONS010_Regular': 'Data_ONS010_Regular',
    'Data_OCS026': 'Data_ocs026',


    /* Privilege */


    /* Smt */
    'Data_SMGTS211': 'Data_SMGTS211',
    'Data_OCCLS013': 'Data_OCCLS013',
    'Data_OCCLS010': 'Data_OCCLS010',

    /* MFA */
    'MFA_Warn_State': 'MFA_Warn_State',
}

const sessionKeys: Record<string, string> = { ...sessionKey_Filters, ...sessionKey_Data }

export default sessionKeys
