import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { dateFromJson } from "../../utils/deserializer"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"

export type SpotView = Required & Partial<Optional>
type SpotViewJson = JsonRequired & Partial<JsonOptional>
export const totCoToJson: Project<SpotView, SpotViewJson> = obj => ({
    ...obj,
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
    placeDate: dateToJson(obj.placeDate),
    submitDate: dateToJson(obj.submitDate),
})
export const totCoFromJson: Project<SpotViewJson, SpotView> = json => ({
    ...json,
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page),
    placeDate: dateFromJson(json.placeDate),
    submitDate: dateFromJson(json.submitDate),
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'page' | 'placeDate' | 'submitDate'> {
    page: PageViewEntity,
    placeDate: Date,
    submitDate: Date,
}

interface JsonRequired {

}

interface JsonOptional {
    orderId: number,
    customerId: number,
    customerCode: string,
    orderRefNo: string,
    orderType: number,
    shippingMode: number,
    placeDate: string,
    submitDate: string,
    status: number,

    createdBy: string,
    createdDate: number,
    updatedBy: string,
    updatedDate: number,
    page: PageViewEntityJson,
    index?: number,
}