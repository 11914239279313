import { Typography } from "@material-ui/core"
import { KeyFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/KeyFormatter"
import React from "react"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { TodoListPrority } from "../../../services/master/enums/TodoListPrority"
import { CodeCategoryTypeProvider } from "./CodeCategoryTypeProvider"

export const TodoListProrityProvider = () => {
    return <CodeCategoryTypeProvider codeCategory={CodeCategory.TodoListPrority} Formatter={Formatter} />
}

const Formatter = (props: KeyFormatterProps) => {
    const { value, formatter } = props
    const active = props.value
    const color = active === TodoListPrority.High ? 'red' : (active === TodoListPrority.Medium ? 'orange' : 'blue')

    return <Typography variant="body2" style={{ color: color }}>
        {value === null ? '' : formatter.format(value)}
    </Typography>
}