import { AxiosInstance } from 'axios'
import { PageResultEntity } from '../../utils/PageResultEntity'
import { CreateResourceAPIRequest } from '../models/CreateResourceAPIRequest'
import { ListResourcesAPIRequest } from '../models/ListResourcesAPIRequest'
import { ResourceAPIResponse } from '../models/ResourceAPIResponse'
import { UpdateResourceAPIRequest } from '../models/UpdateResourceAPIRequest'

const resourceApi = {
    get: function (axiosInstance: AxiosInstance, id: string) {
        return axiosInstance.get<ResourceAPIResponse>('/common-system-api/resources/:id', {pathVars: {id}})
    },
    batchGet: function (axiosInstance: AxiosInstance, ids: string[]) {
        return axiosInstance.post<ResourceAPIResponse[]>('/common-system-api/resources/batch-get', ids)
    },
    list: function (axiosInstance: AxiosInstance, data: ListResourcesAPIRequest) {
        return axiosInstance.get<PageResultEntity<ResourceAPIResponse>>('/common-system-api/resources', {params: data})
    },
    create: function (axiosInstance: AxiosInstance, data: CreateResourceAPIRequest) {
        return axiosInstance.post<ResourceAPIResponse>('/common-system-api/resources', data)
    },
    batchCreate: function (axiosInstance: AxiosInstance, data: CreateResourceAPIRequest[]) {
        return axiosInstance.post<ResourceAPIResponse[]>('/common-system-api/resources/batch-create', data)
    },
    update: function (axiosInstance: AxiosInstance, data: UpdateResourceAPIRequest) {
        return axiosInstance.put<ResourceAPIResponse>('/common-system-api/resources/:id', data, {pathVars: {id: data.resourceId}})
    },
    batchUpdate: function (axiosInstance: AxiosInstance, data: UpdateResourceAPIRequest[]) {
        return axiosInstance.post<ResourceAPIResponse[]>('/common-system-api/resources/batch-update', data)
    },
    delete: function (axiosInstance: AxiosInstance, id: string) {
        return axiosInstance.delete('/common-system-api/resources/:id', {pathVars: {id}})
    },
    batchDelete: function (axiosInstance: AxiosInstance, ids: string[]) {
        return axiosInstance.post('/common-system-api/resources/batch-delete', ids)
    }
}

export default resourceApi