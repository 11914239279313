import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"

export type StockAdjustmentListFactor = Required & Partial<Optional>
type StockAdjustmentListFactorJson = JsonRequired & Partial<JsonOptional>
export const stockAdjustmentListFactorToJson: Project<StockAdjustmentListFactor, StockAdjustmentListFactorJson> = obj => ({
    ...obj,
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
})

export const stockAdjustmentListFactorFromJson: Project<StockAdjustmentListFactorJson, StockAdjustmentListFactor> = json => ({
    ...json,
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page),
})

interface Required extends JsonRequired {

}

interface Optional extends Omit<JsonOptional, ''> {
    page: PageViewEntity
}

interface JsonRequired {

}

interface JsonOptional {
    buId: number,
    customerId: number,
    ttcPartsNo: string,
    customerPartIdList: number[],
    customerPartsNo: string,
    externalReferenceNo: string,
    customerBackNo: string,
    cutOffDate: Date,

    page: PageViewEntityJson
}

