/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { CargoTrackingFactor, cargoTrackingFactorToJson } from "../models/CargoTrackingFactor"
import { CargoTrackingView, cargoTrackingViewFromJson } from "../models/CargoTrackingView"

export const useListCargoTracking = () => {
    const url = '/lcbm-shipping-detail-api/api/listCargoTracking'
    const payloadProject = useCallback(cargoTrackingFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(cargoTrackingViewFromJson), [])
    return usePost<CargoTrackingFactor, PageResultEntity<CargoTrackingView>>(url, payloadProject, resultProject)
}