import { Button, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import { Access } from '../Access/Access'

interface CallbackViewActionProps {
    access?: string,
    power?: boolean,
    title: NonNullable<React.ReactNode>,
    disabled?: boolean,
    outlined?: boolean,
    callback: () => void,
}

export const CallbackViewAction = (props: CallbackViewActionProps) => {
    const { access, power, title, disabled, outlined, callback } = props

    const styles = useStyles()
    const action = <Button
        variant={outlined ? 'outlined' : 'contained'}
        color="primary"
        disabled={disabled}
        onClick={callback}
        className={clsx(styles.root, outlined && styles.rootOutlined)}
    >
        {title}
    </Button>
    return (access || power !== undefined)
        ? <Access access={access} power={power}>{action}</Access>
        : action
}

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 120,
    },
    rootOutlined: {
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
    },
}))