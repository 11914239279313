import { useCallback } from "react"
import { IntlString } from "../../../utils/IntlString"
import { usePost } from "../../../utils/fetch/useFetch"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { StockManagementMasterDetail, stockManagementMasterDetailFromJson, stockManagementMasterDetailToJson } from "../models/StockManagementMasterDetail"
import { StockManagementMasterTransfer, stockManagementMasterTransferFromJson } from "../models/StockManagementMasterTransfer"
import { StockManagementMasterView, stockManagementMasterViewToJson } from "../models/StockMangementMasterView"

export const useGetStockManagementMasterPage = () => {
    const url = '/smt-api/stockManagementMaster/getStockManagementMasterList'
    const payloadProject = useCallback(stockManagementMasterViewToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(stockManagementMasterTransferFromJson), [])
    return usePost<StockManagementMasterView, PageResultEntity<StockManagementMasterTransfer>>(url, payloadProject, resultProject)
}

export const useGetOrderCalculationMasterListPage = () => {
    const url = '/smt-api/stockManagementMaster/getOrderCalculationMasterList'
    const payloadProject = useCallback(stockManagementMasterViewToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(stockManagementMasterTransferFromJson), [])
    return usePost<StockManagementMasterView, PageResultEntity<StockManagementMasterTransfer>>(url, payloadProject, resultProject)
}

export const useGetStockManagementAndOrderCalculationMasterPage = () => {
    const url = '/smt-api/stockManagementMaster/getStockManagementAndOrderCalculationMasterList'
    const payloadProject = useCallback(stockManagementMasterViewToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(stockManagementMasterTransferFromJson), [])
    return usePost<StockManagementMasterView, PageResultEntity<StockManagementMasterTransfer>>(url, payloadProject, resultProject)
}

export const useGetInOutPartsListPage = () => {
    const url = '/smt-api/stockManagementMaster/getInOutPartsList'
    const payloadProject = useCallback(stockManagementMasterViewToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(stockManagementMasterTransferFromJson), [])
    return usePost<StockManagementMasterView, PageResultEntity<StockManagementMasterTransfer>>(url, payloadProject, resultProject)
}

export const useGetInOutPartsListPageMapa = () => {
    const url = '/smt-api/stockManagementMaster/getInOutPartsListMapa'
    const payloadProject = useCallback(stockManagementMasterViewToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(stockManagementMasterTransferFromJson), [])
    return usePost<StockManagementMasterView, PageResultEntity<StockManagementMasterTransfer>>(url, payloadProject, resultProject)
}

export const useGetStockManagementMasterDetail = () => {
    const url = '/smt-api/stockManagementMaster/getStockManagementMasterDetail'
    const payloadProject = useCallback((obj: { customerPartsId: number }) => obj, [])
    const resultProject = useCallback(stockManagementMasterDetailFromJson, [])
    return usePost<{ customerPartsId: number }, StockManagementMasterDetail>(url, payloadProject, resultProject)
}

export const useGetOrderCalculationSettingDetail = () => {
    const url = '/smt-api/stockManagementMaster/getOrderCalculationDetail'
    const payloadProject = useCallback((obj: { customerPartsId: number }) => obj, [])
    const resultProject = useCallback(stockManagementMasterDetailFromJson, [])
    return usePost<{ customerPartsId: number }, StockManagementMasterDetail>(url, payloadProject, resultProject)
}

export const useGetStockManagementAndOrderCalculationMasterDetail = () => {
    const url = '/smt-api/stockManagementMaster/getStockManagementAndOrderCalculationMasterDetail'
    const payloadProject = useCallback((obj: { customerPartsId: number }) => obj, [])
    const resultProject = useCallback(stockManagementMasterDetailFromJson, [])
    return usePost<{ customerPartsId: number }, StockManagementMasterDetail>(url, payloadProject, resultProject)
}

export const useSaveStockManagementMasterDetail = () => {
    const url = '/smt-api/stockManagementMaster/saveStockManagementMasterDetail'
    const payloadProject = useCallback(stockManagementMasterDetailToJson, [])
    const resultProject = useCallback((m: { messages: IntlString[], warningFlag: number }) => m, []);
    return usePost<StockManagementMasterDetail, { messages: IntlString[], warningFlag: number }>(url, payloadProject, resultProject);
}

export const useSaveOrderCalculationMasterDetail = () => {
    const url = '/smt-api/stockManagementMaster/saveOrderCalculationMasterDetail'
    const payloadProject = useCallback(stockManagementMasterDetailToJson, [])
    const resultProject = useCallback((m: { messages: IntlString[], warningFlag: number }) => m, []);
    return usePost<StockManagementMasterDetail, { messages: IntlString[], warningFlag: number }>(url, payloadProject, resultProject);
}

export const useSaveStockManagementAndOrderCalculationMasterDetail = () => {
    const url = '/smt-api/stockManagementMaster/saveStockManagementAndOrderCalculationMasterDetail'
    const payloadProject = useCallback(stockManagementMasterDetailToJson, [])
    const resultProject = useCallback((m: { messages: IntlString[], warningFlag: number }) => m, []);
    return usePost<StockManagementMasterDetail, { messages: IntlString[], warningFlag: number }>(url, payloadProject, resultProject);
}
