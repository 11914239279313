import { Typography } from "@material-ui/core"
import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, ObjectTypeProvider, PaginationLayout, Searching, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarLayout } from "@rithe/data-grid"
import { ObjectFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/ObjectFormatter"
import { EntryItem, Form } from "@rithe/form"
import { Records } from "@rithe/utils"
import React, { SetStateAction, useCallback, useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { CreateCallbackViewAction } from "../../../components/Action/CreateCallbackViewAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { EditCallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/EditCallbackToolbarAction"
import { View } from "../../../components/View/View"
import { TemplateStatus } from "../../../services/integration/enums/TemplateStatus"
import { ExternalDataFactor } from "../../../services/integration/models/ExternalDataFactor"
import { RequestDetailHead, RequestList } from "../../../services/integration/models/ExternalDataResult"
import { ExternalTemplateList } from "../../../services/integration/models/ExternalTemplateList"
import { FunctionList } from "../../../services/integration/models/FunctionList"

interface INT010PcUiProps {
    functionList: FunctionList[],
    templateList: ExternalTemplateList[],
    findTemplateList: (filters: ExternalDataFactor) => void,
    filters: ExternalDataFactor,
    setFilters: React.Dispatch<SetStateAction<ExternalDataFactor>>,
    headerInfo: RequestDetailHead
    list: RequestList[]
}

export const INT010PcUi = (props: INT010PcUiProps) => {
    const { functionList, templateList, findTemplateList, filters, setFilters } = props
    const intl = useIntl()

    return <View >
        <SectionCard key={1}>
            <SectionCardHeader actions={<CreateAction />}
                serialNumber={1}
                title={intl.formatMessage({ id: 'manageExternalData' })}
                subtitle={intl.formatMessage({ id: 'manageExternalDataSub' })}
            />
            <SectionCardContent>
                <ManageExternalData functionList={functionList} templateList={templateList} findTemplateList={findTemplateList} filters={filters} setFilters={setFilters} />
            </SectionCardContent>
        </SectionCard>
        {filters.externalTemplateId && <SectionCard key={2} >
            <SectionCardHeader
                serialNumber={2}
                title={intl.formatMessage({ id: "externalData" })}
                subtitle={intl.formatMessage({ id: "templateSub" })} />
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>}
    </View>
}

const ManageExternalData = ({ functionList, templateList, findTemplateList, filters, setFilters }: {
    functionList: FunctionList[],
    templateList: ExternalTemplateList[],
    findTemplateList: (filters: ExternalDataFactor) => void,
    filters: ExternalDataFactor,
    setFilters: React.Dispatch<SetStateAction<ExternalDataFactor>>,
}) => {
    const intl = useIntl()
    const functionMap = useMemo(() => functionList ? functionList.map(m => [m.code, m.name] as [string, string]) : [], [functionList])
    const templateMap = useMemo(() => templateList ? templateList.sort((a, b) => {
        const identifierA = a.identifier ?? ""
        const identifierB = b.identifier ?? ""
        return identifierA > identifierB ? 1 : identifierA === identifierB ? 0 : -1
    }).map(m => [m.templateId, m.identifier] as [string, string]) : [], [templateList])

    const handleDataChange = useCallback<React.Dispatch<React.SetStateAction<ExternalDataFactor>>>(nextDraftDataFunc => {
        setFilters(filters => {
            const newFilters = typeof nextDraftDataFunc === 'function' ? nextDraftDataFunc(filters) : nextDraftDataFunc
            if (filters.function !== newFilters.function) {
                const resetFilters = { ...newFilters, externalTemplateId: undefined }
                findTemplateList(resetFilters)
                return resetFilters
            }
            return newFilters
        })
    }, [findTemplateList, setFilters])

    return <Form data={filters} setData={handleDataChange} labelDisplay="block" helperDisplay="tooltip" columnCount={10}>
        <EntryItem field="function" label={intl.formatMessage({ id: 'functionList' })} entries={functionMap} colSpan={4} />
        <EntryItem field="externalTemplateId" label={intl.formatMessage({ id: 'existingExternalTemplateID' })} entries={templateMap} colSpan={4} />
    </Form>
}

const DataTable = ({ list, filters, headerInfo }: INT010PcUiProps) => {
    const intl = useIntl()
    const displayData = list.filter(data => data.required || data.matchedColumnName)
    const columns = useMemo(() => [
        { field: 'displayTitle', dataTypeName: 'displayTitle', title: intl.formatMessage({ id: 'displayDataTitle' }), width: 300 },
        { field: 'description', dataTypeName: 'string', title: intl.formatMessage({ id: 'dataDescription' }), width: 300 },
        { field: 'matchedColumnName', dataTypeName: 'string', title: intl.formatMessage({ id: 'matchingDateofOwnFormat' }), width: 350 },

        { field: 'createdBy', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.createdBy' }), width: 200 },
        { field: 'createdDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.createdDate' }), width: 200 },
        { field: 'updatedBy', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.updatedBy' }), width: 200 },
        { field: 'updatedDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.updatedDate' }), width: 200 },

    ], [intl])

    const getRowId = useCallback((row: any) => row.customerPartsId, [])
    const editProps = useMemo(() => ({ filters }), [filters])
    const display = useCallback(() => list.length > 0 && headerInfo.status !== TemplateStatus.CONFIRMED, [headerInfo.status, list.length])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DisplayTitleProvider />
        <DataTypePreset />
        <Data rows={displayData} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={EditAction} actionProps={editProps} display={display} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['createdBy', 'createdDate', 'updatedBy', 'updatedDate']}
            ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
    </DataGrid>
}

/** Formatter for Display Title  */
const DisplayTitleFormatter = ({ value, row }: ObjectFormatterProps) => {
    return <div style={{ display: 'flex' }}>
        <Typography variant="body2">{row.displayTitle}</Typography>
        {row.required && <Typography variant="body2" color="error">{'*'}</Typography>}
    </div >
}

const DisplayTitleProvider = () => {
    return <ObjectTypeProvider name="displayTitle" Formatter={DisplayTitleFormatter} />
}

const CreateAction = () => {
    const navigate = useNavigate()
    const callback = useCallback(() => {
        navigate(`/manageExternalData/create`)
    }, [navigate])
    return <CreateCallbackViewAction callback={callback} access="INT.INT010.CREATE" />
}

const EditAction = ({ filters }: {
    filters: ExternalDataFactor,
}) => {
    const navigate = useNavigate()
    const callback = useCallback(() => {
        navigate(`/manageExternalData/edit-${filters.externalTemplateId}`)
    }, [filters.externalTemplateId, navigate])
    return <EditCallbackToolbarAction title={<FormattedMessage id="editTemplate" />} callback={callback} access="INT.INT010.EDIT" />
}