import { Button, makeStyles } from "@material-ui/core"
import clsx from 'clsx'
import React from "react"
import { Access } from "../Access/Access"

interface DialogActionProps {
    access?: string,
    title: NonNullable<React.ReactNode>,
    error?: boolean,
    disabled?: boolean,
    outlined?: boolean,
    callback: () => void,
}

export const DialogAction = (props: DialogActionProps) => {
    const { access, title, error, disabled, outlined, callback } = props

    const styles = useStyles()
    const action = <Button
        variant={outlined ? 'outlined' : 'contained'}
        color="secondary"
        disabled={disabled}
        onClick={callback}
        style={{ height: 32 }}
        className={clsx(styles.root, error && styles.error)}
    >
        {title}
    </Button>
    return access ? <Access access={access}>{action}</Access> : action
}

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 120,
    },
    error: {
        background: theme.palette.error.main,
    },
}))