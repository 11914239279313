import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { dateFromJson } from "../../utils/deserializer"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"


export type FOBSettingDownloadView = Omit<FOBSettingListView, 'page'>
type FOBSettingDownloadViewJson = Omit<FOBSettingListViewJson, 'page'>
export const fOBSettingDownloadViewToJson: Project<FOBSettingDownloadView, FOBSettingDownloadViewJson> = obj => ({
    ...obj,
    effectiveStartDateStart: dateToJson(obj.effectiveStartDateStart),
    effectiveStartDateEnd: dateToJson(obj.effectiveStartDateEnd),
    effectiveEndDateStart: dateToJson(obj.effectiveEndDateStart),
    effectiveEndDateEnd: dateToJson(obj.effectiveEndDateEnd),
})


export type FOBSettingListView = Required & Partial<Optional>
type FOBSettingListViewJson = JsonRequired & Partial<JsonOptional>
export const fobSettingListViewToJson: Project<FOBSettingListView, FOBSettingListViewJson> = obj => ({
    ...obj,
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
    effectiveStartDateStart: dateToJson(obj.effectiveStartDateStart),
    effectiveStartDateEnd: dateToJson(obj.effectiveStartDateEnd),
    effectiveEndDateStart: dateToJson(obj.effectiveEndDateStart),
    effectiveEndDateEnd: dateToJson(obj.effectiveEndDateEnd),
})

export const fobSettingListViewFromJson: Project<FOBSettingListViewJson, FOBSettingListView> = json => ({
    ...json,
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page),
    effectiveStartDateStart: dateFromJson(json.effectiveStartDateStart),
    effectiveStartDateEnd: dateFromJson(json.effectiveStartDateEnd),
    effectiveEndDateStart: dateFromJson(json.effectiveEndDateStart),
    effectiveEndDateEnd: dateFromJson(json.effectiveEndDateEnd),
})
interface Required extends JsonRequired {

}

interface Optional extends Omit<JsonOptional, 'page' | 'effectiveStartDateStart' | 'effectiveStartDateEnd' | 'effectiveEndDateStart' | 'effectiveEndDateEnd'> {
    page: PageViewEntity,
    effectiveStartDateStart: Date,
    effectiveStartDateEnd: Date,
    effectiveEndDateStart: Date,
    effectiveEndDateEnd: Date,
}

interface JsonRequired {

}

interface JsonOptional {
    fobSettingIdList: number[],
    invCurrencyCode: string,
    contrRouteIdList: number[],
    consignee: string[],
    ftaInvoice: string[],
    customClearanceInvoice: string[],
    page: PageViewEntityJson,
    valid: number,
    effectiveStartDateStart: string,
    effectiveStartDateEnd: string,
    effectiveEndDateStart: string,
    effectiveEndDateEnd: string,
}
