import MomentUtils from '@date-io/moment'
import { createGenerateClassName, CssBaseline, StylesProvider, ThemeProvider } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { FormContextProvider } from '@rithe/form'
import { History } from 'history'
import { GenerateId } from 'jss'
import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react'
import { IntlProvider } from 'react-intl'
import { useDispatch } from 'react-redux'
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom"
import { AppProps } from "single-spa"
import { Helper } from './components/Form/Helper'
import { InputWrapper } from './components/Form/InputWrapper'
import { useDateLabelFunc } from "./components/Form/useDateLabelFunc"
import { useDateTimeLabelFunc } from "./components/Form/useDateTimeLabelFunc"
import { useTimeLabelFunc } from "./components/Form/useTimeLabelFunc"
import { useYearMonthLabelFunc } from "./components/Form/useYearMonthLabelFunc"
import appConfig from './configs/appConfig'
import { messages } from './configs/i18n/messages'
import { themes } from './configs/theme/themes'
import { applicationActions, useApplicationSelector } from './layouts/Application/applicationSlice'
import { AccountingRoutes } from './routes/AccountingRoutes'
import { AppRoutes } from "./routes/AppRoutes"
import { DashboardRoutes } from "./routes/DashboardRoutes"
import { IntegrationRoutes } from './routes/IntegrationRoutes'
import { LogisticsRoutes } from './routes/LogisticsRoutes'
import { MasterRoutes } from './routes/MasterRoutes'
import { MtRoutes } from './routes/MtRoutes'
import { OrderRoutes } from "./routes/OrderRoutes"
import { PrivilegeRoutes } from './routes/PrivilegeRoutes'
import { SmtRoutes } from './routes/SmtRoutes'
import { TodolistRoutes } from './routes/TodolistRoutes'
import { ReduxProvider } from './store/ReduxProvider'
import { SharedStore } from './store/SharedStore'
import { FunctionStore } from "./utils/FunctionStore"

export interface RootProps {
    history: History,
    store: SharedStore,
    functionStore: FunctionStore,
}

const RootContext = createContext<RootProps>(undefined as any)

export function Root(props: RootProps & AppProps) {
    return <React.StrictMode>
        <RootContext.Provider value={props}>
            <StoreProvider>
                <I18nProvider>
                    <MuiProvider>
                        <RouterProvider>
                            <AppRoutes />
                            <OrderRoutes />
                            <LogisticsRoutes />
                            <AccountingRoutes />
                            <MtRoutes />
                            <DashboardRoutes />
                            <MasterRoutes />
                            <PrivilegeRoutes />
                            <SmtRoutes />
                            <TodolistRoutes />
                            <IntegrationRoutes />
                        </RouterProvider>
                    </MuiProvider>
                </I18nProvider>
            </StoreProvider>
        </RootContext.Provider>
    </React.StrictMode>
}

function StoreProvider({ children }: { children: React.ReactElement }) {
    const context = useContext(RootContext)
    return <ReduxProvider store={context.store}>
        {children}
    </ReduxProvider>
}

function I18nProvider({ children }: { children: React.ReactNode }) {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(applicationActions.loadMessage(messages))
    }, [dispatch])


    const language = useApplicationSelector(state => state.i18n.language)
    const timezone = useApplicationSelector(state => state.i18n.timezone)
    const localeMessages = useApplicationSelector(state => state.i18n.messages[state.i18n.language])
    const defaultRichTextElements = useMemo(() => {
        const mapChunk = (field: string) => localeMessages[field] ?? field
        return {
            field: (chunk: any) => <span>{mapChunk(chunk)}</span>,
            code: (chunk: any) => <span>{mapChunk(chunk)}</span>
        }
    }, [localeMessages])

    return <IntlProvider locale={language} timeZone={timezone} messages={localeMessages} defaultRichTextElements={defaultRichTextElements}>
        {children}
    </IntlProvider>
}

function MuiProvider({ children }: { children: React.ReactNode }) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const generateClassName = useCallback<GenerateId>(createGenerateClassName({
        productionPrefix: appConfig.projectName,
        seed: appConfig.projectName,
    }), [])
    const language = useApplicationSelector(state => state.i18n.language)
    const theme = useApplicationSelector(state => state.ui.theme)
    const dateLabelFunc = useDateLabelFunc()
    const timeLabelFunc = useTimeLabelFunc()
    const dateTimeLabelFunc = useDateTimeLabelFunc()
    const yearMonthLabelFunc = useYearMonthLabelFunc()
    return <StylesProvider generateClassName={generateClassName}>
        <ThemeProvider theme={themes[theme]}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale={language}>
                <FormContextProvider
                    InputWrapper={InputWrapper}
                    Helper={Helper}
                    dateInputPickerProps={{ labelFunc: dateLabelFunc }}
                    timeInputPickerProps={{ labelFunc: timeLabelFunc }}
                    dateTimeInputPickerProps={{ labelFunc: dateTimeLabelFunc }}
                    yearMonthInputPickerProps={{ labelFunc: yearMonthLabelFunc }}
                    checkInputCheckboxProps={{ style: { paddingLeft: 11 } }}
                >
                    <CssBaseline />
                    {children}
                </FormContextProvider>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    </StylesProvider>
}

function RouterProvider({ children }: { children: React.ReactNode }) {
    const context = useContext(RootContext)
    return <HistoryRouter history={context.history} basename={appConfig.routerBasePath}>
        {children}
    </HistoryRouter>
}

export function useFunctionStore() {
    const context = useContext(RootContext)
    return context.functionStore
}