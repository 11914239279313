import { Project } from "../../../utils/Project"

export type DownloadOverallVisualisationFactor = Partial<Optional>
type DownloadOverallVisualisationFactorJson = Partial<JsonOptional>
export const downloadOverallVisualisationFactorToJson: Project<DownloadOverallVisualisationFactor, DownloadOverallVisualisationFactorJson> = obj => ({
    ...obj,
})

interface Optional extends JsonOptional {
}


interface JsonOptional {
    customerId: number,
    contractRouteId: number,
    partsNo: string,
    withPoNoFlag: boolean,
    withInvoiceNoFlag: boolean,
}
