import { FormattedMessage } from 'react-intl'
import { CallbackViewAction } from './CallbackViewAction'

interface DeleteCallbackViewActionProps {
    access?: string,
    power?: boolean,
    title?: React.ReactNode,
    disabled?: boolean,
    outlined?: boolean,
    callback: () => void,
}

export const DeleteCallbackViewAction = (props: DeleteCallbackViewActionProps) => {
    return <CallbackViewAction {...props} title={<FormattedMessage id="delete" />} />
}