import { KeyTypeProvider } from "@rithe/data-grid";
import { KeyEditorProps } from "@rithe/data-grid/dist/components/dataTypes/KeyEditor";
import { KeyFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/KeyFormatter";
import { KeyInlineEditorProps } from "@rithe/data-grid/dist/components/dataTypes/KeyInlineEditor";
import React, { ComponentType, useMemo } from "react";

interface LibertyTypeProviderProps {
    name: string,
    options: any[],
    Formatter?: ComponentType<KeyFormatterProps>,
    Editor?: ComponentType<KeyEditorProps>,
    InlineEditor?: ComponentType<KeyInlineEditorProps>,
}

export const LibertyTypeProvider = (props: LibertyTypeProviderProps) => {
    const { name, options, Formatter, Editor, InlineEditor } = props
    const formatter = useMemo(() => ({
        format: (value: any) => {
            return value === undefined ? '' : options.find(f => f === value) ?? ''
        }
    }), [options])
    return <KeyTypeProvider name={name} formatter={formatter} options={options} Formatter={Formatter} Editor={Editor} InlineEditor={InlineEditor} />
}