import { Project } from "../../../utils/Project"

export type InventoryDownloadByPartsFactor = Partial<Optional>
type InventoryDownloadByPartsFactorJson = Partial<JsonOptional>
export const inventoryDownloadByPartsFactorToJson: Project<InventoryDownloadByPartsFactor, InventoryDownloadByPartsFactorJson> = obj => ({
    ...obj,
})

interface Optional extends JsonOptional {
}


interface JsonOptional {
    dcIdList: number[],
    customerIdList: number[],
    partsNo: string,
    unitPartsNo: string,
    backNo: string,
    colorCode: string,
}
