import { Project } from "../../utils/Project"

export type Pair = Required

type PairJson = JsonRequired

export const pairFromJson: Project<PairJson, Pair> = json => ({
    ...json
})

interface Required extends JsonRequired {
}

interface JsonRequired {
    first: string | number,
    second: string | number,
}
