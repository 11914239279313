import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { Projects } from "../../../utils/Projects"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { DcDetail, dcDetailFromJson, dcDetailToJson } from "../models/DcDetail"
import { DCFactor, dcFactorToJson } from "../models/DCFactor"
import { DCResult, dcResultFromJson } from "../models/DCResult"
import { TnmDistributionCenter, tnmDistributionCenterFromJson } from "../models/TnmDistributionCenter"


export const useGetDCList = () => {
    const url = '/lcbm-system-master-api/api/getDCList'
    const payloadProject = useCallback(dcFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(dcResultFromJson), [])
    return usePost<DCFactor, PageResultEntity<DCResult>>(url, payloadProject, resultProject)
}

export const useGetPartnerDCList = () => {
    const url = '/lcbm-system-master-api/api/getPartnerDCList'
    const payloadProject = useCallback(dcFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(dcResultFromJson), [])
    return usePost<DCFactor, PageResultEntity<DCResult>>(url, payloadProject, resultProject)
}

// Query Dc Info
export const useGetDcDetailForView = () => {
    const url = '/lcbm-system-master-api/api/getDcDetailForView'
    const payloadProject = useCallback((obj: { dcCode: string }) => obj.dcCode, [])
    const resultProject = useCallback(dcDetailFromJson, [])
    return usePost<{ dcCode: string }, DcDetail>(url, payloadProject, resultProject)
}

export const useGetDcDetailForEdit = () => {
    const url = '/lcbm-system-master-api/api/getDcDetailForEdit'
    const payloadProject = useCallback((obj: { dcCode: string }) => obj.dcCode, [])
    const resultProject = useCallback(dcDetailFromJson, [])
    return usePost<{ dcCode: string }, DcDetail>(url, payloadProject, resultProject)
}


// To Temporary Database
export const useUpdatedOrInsertDcDetail = () => {
    const url = '/lcbm-system-master-api/api/updatedOrInsertDcDetail'
    const payloadProject = useCallback(dcDetailToJson, [])
    // const resultProject = useCallback((dcId: number) => dcId, []);
    const resultProject = useCallback(tnmDistributionCenterFromJson, [])
    return usePost<DcDetail, TnmDistributionCenter>(url, payloadProject, resultProject)
}

// To Formal Database
export const useSaveAndIssueDcInfo = () => {
    const url = '/lcbm-system-master-api/api/saveAndIssueDcInfo'
    const payloadProject = useCallback(dcDetailToJson, [])
    // const resultProject = useCallback((dcId: number) => dcId, []);
    const resultProject = useCallback(tnmDistributionCenterFromJson, [])
    return usePost<DcDetail, TnmDistributionCenter>(url, payloadProject, resultProject)
}

export const useIssueDcInfo = () => {
    const url = '/lcbm-system-master-api/api/issueDcInfo'
    const payloadProject = useCallback((obj: { tempDcId: number }) => obj.tempDcId, [])
    const resultProject = Projects.IGNORE
    return usePost<{ tempDcId: number }, undefined>(url, payloadProject, resultProject)
}

export const useWithdrawDcInfo = () => {
    const url = '/lcbm-system-master-api/api/withdrawEditDc'
    const payloadProject = useCallback((obj: { tempDcId: number }) => obj.tempDcId, [])
    const resultProject = Projects.IGNORE
    return usePost<{ tempDcId: number }, undefined>(url, payloadProject, resultProject)
}

export const useComMasterGetDCForRoots = () => {
    const url = '/lcbm-system-master-api/api/getDCForRoots'
    const payloadProject = Projects.IGNORE;
    const resultProject = useCallback(Projects.array(tnmDistributionCenterFromJson), []);
    return usePost<undefined, TnmDistributionCenter[]>(url, payloadProject, resultProject);
}



