import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { dateToJson } from "../../utils/serializer"


export type PlaceOrderForCustomerDetailResult = PlaceOrderForCustomerResultRequired & Partial<PlaceOrderForCustomerResultOptional>
type PlaceOrderForCustomerDetailResultson = PlaceOrderForCustomerResultJsonRequired & Partial<PlaceOrderForCustomerResultJsonOptional>

//co detail
interface PlaceOrderForCustomerResultJsonRequired {
    totPoRoSpotDetailList: PartsDetailJson[],
    status: number,
}
interface PlaceOrderForCustomerResultJsonOptional {
    poSpotId: number,
    customerOrderNo: string,
    orderReference: string,
    submittedBy: string,
    submittedDate: string,
    status: number,
    version: number,
    remark: string,
    roSpotIdInRo: number,
    coSpotIdInRo: number,
    statusInRo: number,
    reviseTypeInRo: number,
    versionInRo: number,
    preRoSpotId: number,
    receiveDcId: number,
    preRoSpotStatusShowConfirm: number,
}

interface PlaceOrderForCustomerResultRequired extends Omit<PlaceOrderForCustomerResultJsonRequired, 'totPoRoSpotDetailList'> {
    totPoRoSpotDetailList: PartsDetail[],
}

interface PlaceOrderForCustomerResultOptional extends Omit<PlaceOrderForCustomerResultJsonOptional, 'status'> {
    status: number,
}

export const PlaceOrderForCustomerDetailResultDeserializer: Project<PlaceOrderForCustomerDetailResultson, PlaceOrderForCustomerDetailResult> = json => ({
    ...json,
    totPoRoSpotDetailList: json.totPoRoSpotDetailList.map(partsDetailDeserializer),
})

export const PlaceOrderForCustomerDetailResultSerializer: Project<PlaceOrderForCustomerDetailResult, PlaceOrderForCustomerDetailResultson> = data => ({
    ...data,
    totPoRoSpotDetailList: data.totPoRoSpotDetailList.map(partsDetailSerializer),
})

// parts
export interface PartsDetail extends Omit<PartsDetailJson, 'oldReqInboundPlanList' | 'newReqInboundPlanList'> {
    oldReqInboundPlanList: OldInboundPalnDetail[],
    newReqInboundPlanList: NewInboundPalnDetail[],
}

interface PartsDetailJson {
    poSpotDetailId: number,
    poSpotId: number,
    globalPartsId: number,
    partsNo: string,
    partsDescription: string,
    sellerPartsId: number,
    sellerPartsNo: string,
    finalSellerUid: string,
    poPartsId: number,
    unitPartsNo: string,
    soDetailOrigin: string,
    sellerCode: string,
    uomCode: string,
    orderLot: number,
    spq: number,
    poQty: number,
    status: number,
    version: number,
    roSpotDetailIdInRo: number,
    roSpotIdInRo: number,
    coDetailIdInRo: number,
    orderQtyInRo: number,
    soDetailOriginInRo: string,
    coDetailOriginInRo: string,
    statusInRo: number,
    versionInRo: number,
    buyingPrice: number,
    preRoSpotId: number,
    oldReqInboundPlanList: OldInboundPalnDetailJson[] | null,
    newReqInboundPlanList: NewInboundPalnDetailJson[] | null,
}

export const partsDetailDeserializer: Project<PartsDetailJson, PartsDetail> = json => ({
    ...json,
    oldReqInboundPlanList: json.oldReqInboundPlanList ? json.oldReqInboundPlanList.map(oldInboundPalnDetailDeserializer) : [],
    newReqInboundPlanList: json.newReqInboundPlanList ? json.newReqInboundPlanList.map(newInboundPalnDetailDeserializer) : [],
})

export const partsDetailSerializer: Project<PartsDetail, PartsDetailJson> = data => ({
    ...data,
    oldReqInboundPlanList: data.oldReqInboundPlanList ? data.oldReqInboundPlanList.map(inboundPalnDetailSerializer) : [],
    newReqInboundPlanList: data.newReqInboundPlanList ? data.newReqInboundPlanList.map(buyerInboundPalnDetailSerializer) : [],
})

//old inbound plan
interface OldInboundPalnDetail extends Omit<OldInboundPalnDetailJson, 'crdDate'> {
    crdDate: Date
}

interface OldInboundPalnDetailJson {
    poSpotCrdId: number,
    poSpotDetailId: number,
    crdDate: string,
    crdQty: number,
    totalReceivedQty: number,
    delayReceivedQty: number,
    //  createdBy:string,
    //  createdDate:string,
    //  updatedBy:string,
    //  updatedDate:string,
    version: number,
}

const oldInboundPalnDetailDeserializer: Project<OldInboundPalnDetailJson, OldInboundPalnDetail> = json => ({
    ...json,
    crdDate: dateFromJson(json.crdDate)
})
const inboundPalnDetailSerializer: Project<OldInboundPalnDetail, OldInboundPalnDetailJson> = data => ({
    ...data,
    crdDate: dateToJson(data.crdDate)
})

//new inbound plan
interface NewInboundPalnDetail extends Omit<NewInboundPalnDetailJson, 'crdDate'> {
    crdDate: Date
}

interface NewInboundPalnDetailJson {
    roSpotCrdId?: number,
    roSpotDetailId?: number,
    crdType?: number,
    crdDate: string,
    crdQty?: number | null,
    changeFlag?: number,
    createdBy?: string,
    createdDate?: string,
    updatedBy?: string,
    updatedDate?: string,
    version?: number,
}

const newInboundPalnDetailDeserializer: Project<NewInboundPalnDetailJson, NewInboundPalnDetail> = json => ({
    ...json,
    crdDate: dateFromJson(json.crdDate)


})
const buyerInboundPalnDetailSerializer: Project<NewInboundPalnDetail, NewInboundPalnDetailJson> = data => ({
    ...data,
    crdDate: dateToJson(data.crdDate)

})
