import { Card, makeStyles } from "@material-ui/core";
import clsx from 'clsx';
import React, { ReactNode } from "react";
import { TabContextProvider } from "../View/Tab/TabContext";
import { useViewContext } from "../View/ViewContext";

interface TabsCardProps {
    children?: ReactNode | ReactNode[],
}

export function TabsCard(props: TabsCardProps) {
    const { children } = props
    const { flex } = useViewContext()

    const styles = useStyles()
    return <TabContextProvider>
        <Card className={clsx(styles.root, flex && styles.flexRoot)}>
            {children}
        </Card>
        <div className={styles.gap} />
    </TabContextProvider>
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: 0,
        background: theme.palette.background.card.linear,
        borderRadius: theme.spacing(1.5),
    },
    flexRoot: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'stretch',
    },
    gap: {
        width: '100%',
        height: theme.spacing(2.5),
    },
}))