import { Button, withStyles } from "@material-ui/core"

export const NeumorphismButton = withStyles(theme => ({
    root: {
        minWidth: 36,
        height: 36,
        borderRadius: 4,
        background: theme.palette.background.neumorphism.main,
        boxShadow: theme.palette.background.neumorphism.shadow,
    },
}))(Button)