import { Selection } from "@rithe/data-grid"
import { useDispatch } from "react-redux"
import { occls013Actions, useOCCLS013Selector } from "./OCCLS013Slice"

export function CustUsageTableSelection() {
    const selections = useOCCLS013Selector(state => state.selections)
    const dispatch = useDispatch()
    return <Selection
        highlightSelectedRow
        selectedRowIds={selections}
        onSelectedRowIdsChange={(selections: string[]) => dispatch(occls013Actions.setSelections(selections))}
    />
}
