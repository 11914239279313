import { IconButton, Tooltip } from "@material-ui/core"
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined'
import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, NumberTypeProvider, PaginationLayout, Paging, RowActionProvider, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from '@rithe/data-grid'
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { StringItem } from "@rithe/form"
import { Objects, Records } from "@rithe/utils"
import React, { memo, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Access } from "../../../components/Access/Access"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { EditRedirectRowAction } from "../../../components/DataGrid/rowActions/EditRedirectRowAction"
import { ViewRedirectRowAction } from "../../../components/DataGrid/rowActions/ViewRedirectRowAction"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { UploadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/UploadGroupedToolbarAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { View } from "../../../components/View/View"
import { useDownloadFOBSettingByFilter } from "../../../services/master/apis/masterDownloadApi"
import { useUploadFOBSetting } from "../../../services/master/apis/masterUploadApi"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { FOBSettingListResult } from "../../../services/master/models/FOBSettingListResult"
import { FOBSettingDownloadView, FOBSettingListView } from "../../../services/master/models/FOBSettingListView"
import { applicationActions } from "../../Application/applicationSlice"

interface MLS170PcUiProps {
    filters: FOBSettingListView,
    setFilters: React.Dispatch<React.SetStateAction<Partial<FOBSettingListView>>>
    data: FOBSettingListResult[],
    search: (filters: FOBSettingListView) => void,
    totalCount: number
}

export const MLS170PcUi = (props: MLS170PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = memo(({ filters, data, setFilters, search }: MLS170PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])
    const columns = useMemo(() => [
        { field: 'invCurrencyCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'invCurrencyCode' }), width: 200 },
        { field: 'contrRouteCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contrRouteCode' }), width: 250 },
        { field: 'consigneeCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'consignee' }), width: 200 },
        { field: 'ftaInvoiceCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'ftaInvoice' }), width: 200 },
        { field: 'customClearanceInvoiceCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'customClearanceInvoice' }), width: 230 },
        { field: 'ftaExchangeRate', dataTypeName: 'exchangeRate', title: intl.formatMessage({ id: 'ftaExchangeRate' }), width: 230 },
        { field: 'customExchangeRate', dataTypeName: 'exchangeRate', title: intl.formatMessage({ id: 'customExchangeRate' }), width: 230 },
        { field: 'effectiveStartDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'effectiveStartDate' }), width: 200 },
        { field: 'effectiveEndDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'effectiveEndDate' }), width: 200 },
        { field: 'fobInvValueLogic', dataTypeName: CodeCategory.FobInvValueLogic, title: intl.formatMessage({ id: 'fobInvValueLogic' }), width: 230 },
        { field: 'remark', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.remark' }), width: 300 },
        { field: 'createdBy', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.createdBy' }), width: 200 },
        { field: 'createdDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.createdDate' }), width: 200 },
        { field: 'updatedBy', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.updatedBy' }), width: 200 },
        { field: 'updatedDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.updatedDate' }), width: 200 },
        { field: 'version', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.version' }), width: 120 },
    ], [intl])

    const defaultHiddenFields = useMemo(() => [
        'createdBy', 'createdDate', 'updatedBy', 'updatedDate', 'version',
    ], [])

    const columnVisibilityOptions = {
        invCurrencyCode: { disableUserControl: true },
        contrRouteId: { disableUserControl: true },
        consignee: { disableUserControl: true }
    }

    const getRowId = useCallback((row: any) => row.fobSettingId, [])
    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search }), [filters, setFilters, search])
    const actionProps1 = useMemo(() => ({ search, filters }), [filters, search])
    const actionProps2 = useMemo(() => ({ selections, filters }), [filters, selections])
    const display1 = useCallback((tableRow: TableRow) => tableRow.row?.fobSettingId !== undefined && tableRow.row?.fobSettingId !== null, [])
    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <NumberTypeProvider name="exchangeRate" options={{ minimumFractionDigits: 6, maximumFractionDigits: 6 }} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.FobInvValueLogic} />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={CreateNewAction} actionProps={{}} />
        <ToolbarActionProvider Action={UploadAction} actionProps={actionProps1} />
        <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps2} />
        <RowActionProvider name="view" Action={ViewRowAction} actionProps={{}} display={display1} />
        <RowActionProvider name="edit" Action={EditRowAction} actionProps={{}} display={display1} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={defaultHiddenFields}
            columnSettings={columnVisibilityOptions}
            ToolbarButton={ColumnVisibilityToolbarButton} />

        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={120} />
    </DataGrid>
})

const FilterItem = (props: { filters: FOBSettingListView, setFilters: React.Dispatch<React.SetStateAction<Partial<FOBSettingListView>>>, search: (filters: FOBSettingListView) => void }) => {
    const { filters, setFilters, search } = props

    const clear = useCallback((filters: FOBSettingListView) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: FOBSettingListView) => {
        return [
            filters.invCurrencyCode,
        ].filter(value => value !== undefined && value !== null).length
    }, [])
    const intl = useIntl()


    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="invCurrencyCode" label={intl.formatMessage({ id: 'invCurrencyCode' })} />
    </FilterToolbarItem>
}

const UploadAction = ({ filters, search }: { filters: FOBSettingListView, search: (filters: FOBSettingListView) => void, }) => {
    const dispatch = useDispatch()
    const uploadFOBSetting = useUploadFOBSetting()
    const uploadMaster = useCallback((files: FileList | null) => {
        if (files === null) {
            dispatch(applicationActions.pushError({ title: { code: 'uploadFOBSetting' }, messages: { code: 'w0005' } }))
            return
        }
        uploadFOBSetting({ file: files[0] }, { serialized: true }).then(() => {
            search(filters)
        })
    }, [uploadFOBSetting, dispatch, search, filters])
    return <UploadGroupedToolbarAction access="MARS.MLS170.UPDATE">
        {(upload, onClose) => <GroupedCallbackItem label={<FormattedMessage id="uploadFOBSetting" />} onClose={onClose} callback={() => upload(uploadMaster)} />}
    </UploadGroupedToolbarAction>
}

const DownloadAction = ({ filters, selections }: { filters: FOBSettingListView, selections: number[], }) => {
    const downloadByFilter = useDownloadFOBSettingByFilter()
    const download = useCallback((valid: number) => {
        if (selections.length <= 0) {
            downloadByFilter({ ...Objects.delete(filters, 'page'), valid: valid } as FOBSettingDownloadView)
        } else {
            downloadByFilter({ fobSettingIdList: selections, valid: valid } as FOBSettingDownloadView)
        }
    }, [selections, downloadByFilter, filters])

    return <DownloadGroupedToolbarAction access="MARS.MLS170.DOWNLOAD">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="downloadFOBSetting" />} onClose={onClose} callback={() => download(0)} />
            <GroupedCallbackItem label={<FormattedMessage id="downloadValidFOBSetting" />} onClose={onClose} callback={() => download(1)} />
        </>}
    </DownloadGroupedToolbarAction>
}

const CreateNewAction = () => {
    const navigate = useNavigate()
    const createNew = useCallback(() => {
        navigate(`/fobSetting/create`)
    }, [navigate])
    return <>
        <Access access="MARS.MLS170.CREATE">
            <Tooltip title={<FormattedMessage id="createnew" />}>
                <IconButton onClick={createNew}>{<AddCircleOutlineOutlinedIcon />}</IconButton>
            </Tooltip>
        </Access>
    </>
}

const ViewRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/fobSetting/view-${tableRow.rowId}`, [])
    return <ViewRedirectRowAction access="MARS.MLS171.VIEW" tableRow={tableRow} path={path} />
}

const EditRowAction = ({ tableRow }: DataGridRowActionProps) => {
    const path = useCallback((tableRow: TableRow) => `/fobSetting/edit-${tableRow.rowId}`, [])
    return <EditRedirectRowAction access="MARS.MLS170.EDIT" tableRow={tableRow} path={path} />
}