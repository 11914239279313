import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { Projects } from "../../../utils/Projects"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { CustomerDetail, customerDetailFromJson, customerDetailToJson } from "../models/CustomerDetail"
import { CustomerFactor, customerFactorToJson } from "../models/CustomerFactor"
import { CustomerResult, customerResultFromJson } from "../models/CustomerResult"
import { TnmCustomerTemp, tnmCustomerTempFromJson } from "../models/TnmCustomerTemp"



export const useGetCustomerList = () => {
    const url = '/lcbm-system-master-api/api/getCustomerList'
    const payloadProject = useCallback(customerFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(customerResultFromJson), [])
    return usePost<CustomerFactor, PageResultEntity<CustomerResult>>(url, payloadProject, resultProject)
}

// Query Customer Info
export const useGetCustomerDetailForEdit = () => {
    const url = '/lcbm-system-master-api/api/getCustomerDetailForEdit'
    const payloadProject = useCallback((obj: { customerCode: string }) => obj.customerCode, [])
    const resultProject = useCallback(customerDetailFromJson, [])
    return usePost<{ customerCode: string }, CustomerDetail>(url, payloadProject, resultProject)
}

export const useGetCustomerDetailForView = () => {
    const url = '/lcbm-system-master-api/api/getCustomerDetailForView'
    const payloadProject = useCallback((obj: { customerCode: string }) => obj.customerCode, [])
    const resultProject = useCallback(customerDetailFromJson, [])
    return usePost<{ customerCode: string }, CustomerDetail>(url, payloadProject, resultProject)
}

// To Temporary dataBase
export const useUpdatedOrInsertCustomerDetail = () => {
    const url = '/lcbm-system-master-api/api/updatedOrInsertCustomerDetail'
    const payloadProject = useCallback(customerDetailToJson, [])
    // const resultProject = useCallback((customerId: number) => customerId, []);
    const resultProject = useCallback(tnmCustomerTempFromJson, [])
    return usePost<CustomerDetail, TnmCustomerTemp>(url, payloadProject, resultProject)
}

// To Formal Database
export const useSaveAndIssueCustomerInfo = () => {
    const url = '/lcbm-system-master-api/api/saveAndIssueCustomerInfo'
    const payloadProject = useCallback(customerDetailToJson, [])
    // const resultProject = useCallback((customerId: number) => customerId, []);
    const resultProject = useCallback(tnmCustomerTempFromJson, [])
    return usePost<CustomerDetail, TnmCustomerTemp>(url, payloadProject, resultProject)
}

export const useIssueCustomerInfo = () => {
    const url = '/lcbm-system-master-api/api/issueCustomerInfo'
    const payloadProject = useCallback((obj: { tempCustomerId: number }) => obj.tempCustomerId, [])
    const resultProject = Projects.IGNORE
    return usePost<{ tempCustomerId: number }, undefined>(url, payloadProject, resultProject)
}

export const useWithdrawCustomerInfo = () => {
    const url = '/lcbm-system-master-api/api/withdrawEditCustomer'
    const payloadProject = useCallback((obj: { tempCustomerId: number }) => obj.tempCustomerId, [])
    const resultProject = Projects.IGNORE
    return usePost<{ tempCustomerId: number }, undefined>(url, payloadProject, resultProject)
}
