import { Project } from "../../../utils/Project"

export type ExternalDataResult = Partial<Optional>
export type ExternalDataResultJson = Partial<JsonOptional>
export const externalDataResultToJson: Project<ExternalDataResult, ExternalDataResultJson> = obj => ({
    header: obj.header ? requestDetailHeadResultToJson(obj.header) : undefined,
    list: obj.list ? obj.list.map(m => requestListResultToJson(m)) : undefined,

})
export const externalDataResultFromJson: Project<ExternalDataResultJson, ExternalDataResult> = json => ({
    header: json.header ? requestDetailHeadResultFromJson(json.header) : undefined,
    list: json.list ? json.list.map(m => requestListResultFromJson(m)) : undefined,

})

interface Optional {
    header: RequestDetailHead,
    list: RequestList[]
}
interface JsonOptional {
    header: RequestDetailHeadJson,
    list: RequestListJson[]
}

export type RequestDetailHead = Partial<RequestHeaderDetailOptional>
type RequestDetailHeadJson = Partial<RequesHeaderDetailJsonOptional>
const requestDetailHeadResultFromJson: Project<RequestDetailHeadJson, RequestDetailHead> = json => ({
    ...json,
})
const requestDetailHeadResultToJson: Project<RequestDetailHead, RequestDetailHeadJson> = obj => ({
    ...obj,

})
interface RequestHeaderDetailOptional extends RequesHeaderDetailJsonOptional {
}
interface RequesHeaderDetailJsonOptional {
    templateId: string,
    companyUid: string,
    function: string,
    identifier: string,
    description: string,
    createdBy: string,
    createdDate: number
    updatedBy: string,
    updatedDate: number,
    version: number
    status: number
}





export type RequestList = Partial<RequestListOptional>
type RequestListJson = Partial<RequestListJsonOptional>
const requestListResultFromJson: Project<RequestListJson, RequestList> = json => ({
    ...json,
})
const requestListResultToJson: Project<RequestList, RequestListJson> = obj => ({
    ...obj,
})
interface RequestListOptional extends RequestListJsonOptional {

}
interface RequestListJsonOptional {
    templateItemId: string,
    matchedColumn: number,
    matchedColumnName: string,
    displayTitle: string,
    description: string,
    createdBy: string,
    createdDate: number,
    updatedBy: string,
    updatedDate: number,
    templateId: string,
    identifier: string,
    version: number,
    required: number,
}

















