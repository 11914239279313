import { Project } from "../../../utils/Project"
import { dateToJson } from "../../utils/serializer"

export type DownloadOnbDlvrView = Required & Partial<Optional>
type DownloadOnbDlvrViewJson = JsonRequired & Partial<JsonOptional>
export const downloadOnbDlvrViewToJson: Project<DownloadOnbDlvrView, DownloadOnbDlvrViewJson> = obj => ({
    ...obj,
    dlvrPlanDateStartDt: dateToJson(obj.dlvrPlanDateStartDt),
    dlvrPlanDateEndDt: dateToJson(obj.dlvrPlanDateEndDt),
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'dlvrPlanDateStartDt' | 'dlvrPlanDateEndDt'> {
    dlvrPlanDateStartDt: Date,
    dlvrPlanDateEndDt: Date,
}

interface JsonRequired {
}

interface JsonOptional {
    loginId: string,
    supplierId: number,
    supplierCode: string,
    supplierContractId: number,
    supplierContractNo: string,
    dlvrPlanDateStartDt: string,
    dlvrPlanDateEndDt: string,
    salesOrderNo: string,
    receiverUid: string,
    receiverCode: string,
    customerId: number,
    customerCode: string,
    partsNo: string,
    activeFlag: number,
    buId: number,
    soDetailIdList: number[],
    supplierIdList: number[],
}