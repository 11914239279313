import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarLayout } from "@rithe/data-grid"
import { Form, StringItem } from "@rithe/form"
import { arrx, Records } from "@rithe/utils"
import React, { memo, useCallback, useMemo } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CodeItem } from "../../../components/Form/CodeItem"
import { View } from "../../../components/View/View"
import { useDownloadContractRouteDetailByFilter, useDownloadContractRouteOverviewByContractRouteNo } from "../../../services/master/apis/masterDownloadApi"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { ContractRouteDetailPartsResult } from "../../../services/master/models/ContractRouteDetailPartsResult"
import { ContractRouteResult } from "../../../services/master/models/ContractRouteResult"
import { useGetCompanyType } from "../../../utils/ApplicationUtils"

interface MLS101PcUiProps {
    partsDataList: ContractRouteDetailPartsResult[],
    headerData: ContractRouteResult,
}

export const MLS101PcUi = (props: MLS101PcUiProps) => {
    const intl = useIntl()
    return (
        <View>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={1}
                    title={intl.formatMessage({ id: 'contractHeaderDetailInformation' })}
                    subtitle={intl.formatMessage({ id: 'contractHeaderDetailInformation' })}
                />
                <SectionCardContent>
                    <HeaderInfo {...props} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={2}
                    title={intl.formatMessage({ id: 'PartsListInformation' })}
                    subtitle={intl.formatMessage({ id: 'PartsListInformation' })}
                />
                <SectionCardContent>
                    <DataTable {...props} />
                </SectionCardContent>
            </SectionCard>
        </View>
    )
}

const HeaderInfo = memo((props: MLS101PcUiProps) => {
    const intl = useIntl()
    const { headerData } = props

    return (
        <Form readonly data={headerData} labelDisplay="block" helperDisplay="tooltip" >
            <StringItem field="contractRouteNo" label={intl.formatMessage({ id: 'field.contrRouteCode' })} />
            <StringItem field="customer" label={intl.formatMessage({ id: 'customerCode' })} />
            <CodeItem field="status" label={intl.formatMessage({ id: 'status' })} code={CodeCategory.ContractRouteStatus} />
            <StringItem field="description" label={intl.formatMessage({ id: 'description' })} colSpan={2} />
        </Form>
    )
})

const DataTable = (props: MLS101PcUiProps) => {
    const { headerData, partsDataList } = props
    const intl = useIntl()
    const cbdsType = useGetCompanyType()

    const saleCategories = useMemo(() => [
        { key: 'sales', value: intl.formatMessage({ id: 'sales' }) },
    ], [intl])
    const purchaseCategories = useMemo(() => [
        { key: 'purchase', value: intl.formatMessage({ id: 'purchases' }) }
    ], [intl])

    const isCustomer = CbdsType.CUST === cbdsType
    const isSupplier = CbdsType.SUPP === cbdsType
    const columns = useMemo(() => arrx(
        { width: 220, field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }) },
        { width: 220, field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsNo' }) },
        !isCustomer && { width: 220, field: 'buyerPartsNo', dataTypeName: 'string', categories: saleCategories, title: intl.formatMessage({ id: 'buyerPartsNo' }) },
        !isCustomer && { width: 150, field: 'buyerCode', dataTypeName: 'string', categories: saleCategories, title: intl.formatMessage({ id: 'buyer' }) },
        !isCustomer && { width: 250, field: 'saleContractNo', dataTypeName: 'string', categories: saleCategories, title: intl.formatMessage({ id: 'contractNo' }) },
        !isCustomer && { width: 150, field: 'salePriceBasis', dataTypeName: CodeCategory.PriceBasis, categories: saleCategories, title: intl.formatMessage({ id: 'field.priceBasis' }) },
        !isCustomer && { width: 180, field: 'saleShipper', dataTypeName: 'string', categories: saleCategories, title: intl.formatMessage({ id: 'shipperCode' }) },
        !isCustomer && { width: 150, field: 'saleReceiver', dataTypeName: 'string', categories: saleCategories, title: intl.formatMessage({ id: 'receiver' }) },
        !isCustomer && { width: 200, field: 'saleAutoDiFlag', dataTypeName: CodeCategory.AutoDiFlag, categories: saleCategories, title: intl.formatMessage({ id: 'autoDiFlag' }) },
        !isCustomer && { width: 200, field: 'saleSrCode', dataTypeName: 'string', categories: saleCategories, title: intl.formatMessage({ id: 'shippingRoute' }) },
        !isCustomer && { width: 200, field: 'saleSpotSrCode', dataTypeName: 'string', categories: saleCategories, title: intl.formatMessage({ id: 'spotSR' }) },

        !isSupplier && { width: 220, field: 'sellerPartsNo', dataTypeName: 'string', categories: purchaseCategories, title: intl.formatMessage({ id: 'sellerPartsNo' }) },
        !isSupplier && { width: 150, field: 'sellerCode', dataTypeName: 'string', categories: purchaseCategories, title: intl.formatMessage({ id: 'seller' }) },
        !isSupplier && { width: 250, field: 'buyContractNo', dataTypeName: 'string', categories: purchaseCategories, title: intl.formatMessage({ id: 'contractNo' }) },
        !isSupplier && { width: 180, field: 'buyPriceBasis', dataTypeName: CodeCategory.PriceBasis, categories: purchaseCategories, title: intl.formatMessage({ id: 'field.priceBasis' }) },
        !isSupplier && { width: 180, field: 'buyShipper', dataTypeName: 'string', categories: purchaseCategories, title: intl.formatMessage({ id: 'shipperCode' }) },
        !isSupplier && { width: 150, field: 'buyReceiver', dataTypeName: 'string', categories: purchaseCategories, title: intl.formatMessage({ id: 'receiver' }) },
        !isSupplier && { width: 200, field: 'buyAutoDiFlag', dataTypeName: CodeCategory.AutoDiFlag, categories: purchaseCategories, title: intl.formatMessage({ id: 'autoDiFlag' }) },
        !isSupplier && { width: 200, field: 'buySrCode', dataTypeName: 'string', categories: purchaseCategories, title: intl.formatMessage({ id: 'shippingRoute' }) },
        !isSupplier && { width: 200, field: 'buySpotSrCode', dataTypeName: 'string', categories: purchaseCategories, title: intl.formatMessage({ id: 'spotSR' }) },
    ), [intl, isCustomer, isSupplier, purchaseCategories, saleCategories])




    const actionProps1 = useMemo(() => ({ headerData }), [headerData])
    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.PriceBasis} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.AutoDiFlag} />
        <Data rows={partsDataList} columns={columns} />
        <ToolbarActionProvider Action={DownloadDetailAction} actionProps={actionProps1} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['buyerPartsNo', 'sellerPartsNo', 'saleShipper', 'buyReceiver', 'saleSpotSrCode', 'buyAutoDiFlag', 'saleAutoDiFlag', 'buySpotSrCode']}
            columnSettings={{
                contractRouteNo: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
    </DataGrid>
}

const DownloadDetailAction = ({ headerData }: { headerData: ContractRouteResult }) => {
    const downloadByFilter = useDownloadContractRouteDetailByFilter()
    const downloadContractRouteOverviewByContractRouteNo = useDownloadContractRouteOverviewByContractRouteNo()
    const download = useCallback(() => {
        downloadByFilter({ contractRouteNo: headerData.contractRouteNo })
    }, [downloadByFilter, headerData.contractRouteNo])
    const download2 = useCallback(() => {
        downloadContractRouteOverviewByContractRouteNo({ contractRouteNo: headerData.contractRouteNo })
    }, [downloadContractRouteOverviewByContractRouteNo, headerData.contractRouteNo])

    return <DownloadGroupedToolbarAction access="MARS.MLS101.DOWNLOAD">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="downloadDetail" />} callback={download} onClose={onClose} />
            <GroupedCallbackItem label={<FormattedMessage id="downloadOverView" />} callback={download2} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}
