import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useComMasterGetAllCompany, useComMasterGetAllRegion, useGetCalendarByActive, useGetDcSettingList, useGetZoneList } from "../../../services/common/apis/commonMasterApi"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { useGetDcDetailForEdit, useGetDcDetailForView } from "../../../services/master/apis/dcApi"
import { SettingDetail } from "../../../services/master/models/SettingDetail"
import { TnmCompany } from "../../../services/master/models/TnmCompany"
import { TnmDistributionCenterTemp } from "../../../services/master/models/TnmDistributionCenterTemp"
import { TnmRegion } from "../../../services/master/models/TnmRegion"
import { useMatch } from "../../../utils/useMatch"
import { MLS051PcUi } from "./MLS051PcUi"

export const MLS051 = () => {
    const { dcCode } = useParams() as any
    const { path } = useMatch()
    let mode = ScreenMode.VIEW
    if (path === '/dc/edit-:dcCode') {
        mode = ScreenMode.EDIT
    } else if (path === '/dc/create') {
        mode = ScreenMode.CREATE
    } else if (path === '/receivedRequest/addNewPart-:requestNo/dcCreate') {
        mode = ScreenMode.CREATE
    }

    const [dcInfo, setDcInfo] = useState<TnmDistributionCenterTemp>({} as any)
    const [settings, setSettings] = useState<SettingDetail[]>([])

    const [zones, setZones] = useState<{ first: string, second: string }[]>([])
    const [regions, setRegions] = useState<TnmRegion[]>([])

    const [companyList, setCompanyList] = useState<TnmCompany[]>([])

    const getDcDetailForView = useGetDcDetailForView()
    const getDcDetailForEdit = useGetDcDetailForEdit()
    const getDcDetail = mode === ScreenMode.VIEW ? getDcDetailForView : getDcDetailForEdit
    const getSettingList = useGetDcSettingList()
    const search = useCallback(() => {
        getDcDetail({ dcCode: dcCode }, { silent: true, serialized: true }).then(result => {
            setDcInfo(result?.basicInfo ?? {} as any)
            setSettings(result?.settings ?? [])
        })
    }, [dcCode, getDcDetail])

    useEffect(() => {
        if (dcCode !== null && dcCode !== undefined) {
            search()
        } else if (mode === ScreenMode.CREATE) {
            getSettingList(undefined, { silent: true, serialized: true }).then(result => {
                setSettings(result?.map(setting => ({
                    settingCode: setting.settingCode,
                    description: setting.description,
                    valueType: setting.valueType,
                })) ?? [])
            })
        }
    }, [dcCode, getSettingList, mode, search])

    const getZoneList = useGetZoneList()
    const getRegionList = useComMasterGetAllRegion()
    const getCalendarList = useGetCalendarByActive()
    // const getCompanyList = useComMasterGetAllCompanyByActive()
    const getCompanyList = useComMasterGetAllCompany()
    useEffect(() => {
        getZoneList(undefined, { silent: true }).then(result => {
            setZones(result || [])
        })
        getRegionList(undefined, { silent: true }).then(result => {
            setRegions(result || [])
        })
        getCompanyList(undefined, { silent: true }).then(result => {
            setCompanyList(result || [])
        })
    }, [getRegionList, getZoneList, getCalendarList, getCompanyList])

    return <MLS051PcUi
        dcInfo={dcInfo}
        setDcInfo={setDcInfo}
        settings={settings}
        setSettings={setSettings}
        search={search}
        dcCode={dcCode}

        zones={zones}
        regions={regions}
        companyList={companyList}
        mode={mode}

        path={path}
    />
}