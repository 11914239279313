import React, { useCallback, useState } from "react"
import { useGetSpotOrderByPage } from "../../../services/order/apis/CustomerOrderApi"
import { SpotView } from "../../../services/order/models/SpotView"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { OCS020PcUi } from "./OCS020PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }

export const OCS020 = () => {


    const [filters, setFilters] = useState<SpotView>({ ...defaultFilters })
    const [data, setData] = useState<SpotView[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const getData = useGetSpotOrderByPage()



    const search = useCallback((filters: SpotView) => {
        getData(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
        })
    }, [getData])

    useSearchOnLoad(search, filters)

    return <OCS020PcUi
        filters={filters}
        setFilters={setFilters}
        data={data}
        search={search}
        totalCount={totalCount}
    />
}