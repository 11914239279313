import { Arrays, Objects } from "@rithe/utils"
import { useCallback, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { useGetSuppOrderChangeDetail } from "../../../services/order/apis/ChangeRequestApi"
import { PartsDetail, SChangeOrderDetailResult } from "../../../services/order/models/SChangeOrderDetailResult"
import { formatDateRange } from "../../../utils/formatDateRange"
import { useMatch } from "../../../utils/useMatch"
import { ORS021PcUi } from "./ORS021PcUi"
import { ORS021PNAPcUi } from "./ORS021PNAPcUi"

export interface ChangeOrderBasic extends SChangeOrderDetailResult {
    totalQty: number,
    totalAmount: number,
    currency?: string,
    totalNumberOfParts: number,
    deliveryPlanRange: string,
}

export const ORS021 = () => {
    const { soId, suppRoId } = useParams() as any

    const { path } = useMatch()
    const mode = (path === '/scro/view-:suppRoId' || path === `/scro/viewpna-:suppRoId`) ? ScreenMode.VIEW : (path === '/scro/edit-:suppRoId' || path === '/scro/editpna-:suppRoId') ? ScreenMode.EDIT : ScreenMode.CREATE
    const isPna = path === `/scro/viewpna-:suppRoId` || path === '/scro/editpna-:suppRoId' || path === '/scro/createpna-:soId' || path === '/so/suppRoViewpna-:suppRoId' || path === '/so/suppRoEditpna-:suppRoId' || path === '/so/suppRoCreatepna-:soId'

    const getOrderReviseDetailByCustomer = useGetSuppOrderChangeDetail()

    const [basic, setBasic] = useState<ChangeOrderBasic>({} as ChangeOrderBasic)
    const [partsDetails, setPartsDetails] = useState<PartsDetail[]>([] as PartsDetail[])

    const splitDatas = useSplitDatas()
    useEffect(() => {
        getOrderReviseDetailByCustomer({ soId: soId, suppRoId: suppRoId }, { silent: true, serialized: true }).then(result => {
            if (result) {
                const { orderbasic, partsDetails } = splitDatas(result)
                setBasic(orderbasic)
                setPartsDetails(partsDetails)
            }
        })
    }, [soId, suppRoId, getOrderReviseDetailByCustomer, splitDatas])

    if (isPna) {
        return <ORS021PNAPcUi
            soId={soId}
            suppRoId={suppRoId}
            basic={basic}
            setBasic={setBasic}
            partsDetails={partsDetails}
            setPartsDetails={setPartsDetails} mode={mode} />
    } else {
        return <ORS021PcUi
            soId={soId}
            suppRoId={suppRoId}
            basic={basic}
            setBasic={setBasic}
            partsDetails={partsDetails}
            setPartsDetails={setPartsDetails} mode={mode} />
    }
}

export const useSplitDatas = () => {
    const intl = useIntl()
    return useCallback((data: SChangeOrderDetailResult) => {
        const { partsDetailList } = data
        const totalNumberOfParts = partsDetailList.length
        const currency = partsDetailList.length > 0 ? partsDetailList[0].currency : ''
        const planDates = Arrays.distinct(partsDetailList.flatMap(parts => parts.oldOutboundPlanList).map(plan => plan.drDate.getTime())).sort().map(m => new Date(m))
        const deliveryPlanRange = planDates.length > 0 ? formatDateRange(intl, planDates[0], planDates[planDates.length - 1]) : ''
        const orderbasic: ChangeOrderBasic = { ...data, totalNumberOfParts, deliveryPlanRange, currency }

        return { orderbasic: orderbasic, partsDetails: partsDetailList }
    }, [intl])
}

export const useMergeDatas = () => {
    return useCallback((orderbasic: ChangeOrderBasic, partsDetails: PartsDetail[]) => {
        return { ...Objects.delete(orderbasic, 'totalNumberOfParts', 'deliveryPlanRange', 'currency'), partsDetailList: partsDetails } as SChangeOrderDetailResult
    }, [])
}

