import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Message } from "@rithe/form"
import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import appConfig from "../../../configs/appConfig"
import contractApi from "../../../services/localmaster/api/contractApi"
import authApi from "../../../services/system/apis/authApi"
import resourceApi from "../../../services/system/apis/resourceApi"
import roleApi from "../../../services/system/apis/roleApi"
import userApi from "../../../services/system/apis/userApi"
import userContractApi from "../../../services/system/apis/userContractApi"
import { ResourceType } from "../../../services/system/enums/ResourceType"
import { UserStatus } from "../../../services/system/enums/UserStatus"
import cbdsApi from "../../../services/systemmaster/apis/cbdsApi"
import { CbdsType } from "../../../services/systemmaster/enums/CbdsType"
import errorToNotification from "../../../utils/axios/errorToNotification"
import responseToNotification from "../../../utils/axios/responseToNotification"
import thunkAxiosInstance from "../../../utils/axios/thunkAxiosInstance"
import { applicationActions } from "../../Application/applicationSlice"

type Mode = 'CREATE' | 'CLONETOCREATE' | 'CLONETOEDIT' | 'EDIT' | 'VIEW'

interface User {
    id: string,
    name: string,
    powerUser: boolean,
    activateChat: boolean,
    email: string,
    phone?: string,
    contactCode?: string,
    localCode?: string,
    account: UserAccount,
    cbdss: UserCbds[],
}

interface UserAccount {
    loginId: string,
    expireAt?: number,
    active: boolean,
    locked: boolean,
}

interface UserCbds {
    cbdsUid: string,
    defaultCbds: boolean,
    roleIds: string[],
    resourceIds: string[],
}

interface UserContract {
    userId: string,
    contractIds: string[],
}

interface Role {
    id: string,
    name: string,
    description: string,
    active: boolean,
    resourceIds: string[],
}

interface Resource {
    id: string,
    code: string,
    type: ResourceType
    category: string,
    module: string,
    action: string,
}

interface Cbds {
    cbdsUid: string,
    cbdsType: number,
    cbdsId: number,
    cbdsCode: string,
    cbdsName: string,
}

interface Contract {
    contractId: number,
    contractNo: string,
    buyer: string,
    description: string,
    customerId?: number,
    sellerBuId?: number,
    buyerBuId?: number,
    supplierId?: number,
}

interface CAS021State {
    ui: {
        clonedUserId: string,
        mode: Mode,
        visitedMap: {
            loginId: boolean,
            name: boolean,
            contactCode: boolean,
            localCode: boolean,
            phone: boolean,
            email: boolean,
            defaultCompanyUid: boolean,
            status: boolean,
            powerUser: boolean,
            activateChat: boolean,
            roleIds: boolean,
        },
        messagesMap: {
            loginId: Message[],
            name: Message[],
            contactCode: Message[],
            localCode: Message[],
            phone: Message[],
            email: Message[],
            defaultCompanyUid: Message[],
            status: Message[],
            powerUser: Message[],
            activateChat: Message[],
            roleIds: Message[],
        }
    },
    domain: {
        user: User,
        userContract: UserContract,
        roles: Role[],
        resources: Resource[],
        cbdss: Cbds[],
        contracts: Contract[],
    },
}

const emptyUser = {
    id: '',
    name: '',
    powerUser: false,
    activateChat: false,
    email: '',
    phone: undefined,
    contactCode: undefined,
    localCode: undefined,
    account: {
        loginId: '',
        expireAt: undefined,
        active: true,
        locked: false,
    },
    cbdss: [],
}

const emptyUserContract = {
    userId: '',
    contractIds: [],
}

const initialState: CAS021State = {
    ui: {
        clonedUserId: '',
        mode: 'CREATE',
        visitedMap: {
            loginId: false,
            name: false,
            contactCode: false,
            localCode: false,
            phone: false,
            email: false,
            defaultCompanyUid: false,
            status: false,
            powerUser: false,
            activateChat: false,
            roleIds: false,
        },
        messagesMap: {
            loginId: [],
            name: [],
            contactCode: [],
            localCode: [],
            phone: [],
            email: [],
            defaultCompanyUid: [],
            status: [],
            powerUser: [],
            activateChat: [],
            roleIds: [],
        },
    },
    domain: {
        user: emptyUser,
        userContract: emptyUserContract,
        roles: [],
        resources: [],
        cbdss: [],
        contracts: [],
    },
}

const pageInit = createAsyncThunk<void, { mode: Mode, id?: string }>('cas021/pageInit', async ({ mode, id = '' }, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)

    thunk.dispatch(actions.setMode(mode))
    thunk.dispatch(actions.setAllVisited(false))
    thunk.dispatch(actions.clearAllMessages())
    thunk.dispatch(applicationActions.addMask())
    Promise.all([
        mode === 'CREATE' ? Promise.resolve(null) : userApi.get(axiosInstance, id).then(response => response.data),
        mode === 'CREATE' ? Promise.resolve(null) : userContractApi.get(axiosInstance, id).then(response => response.data),
        roleApi.list(axiosInstance, { active: true }).then(response => response.data.data!),
        resourceApi.list(axiosInstance, {}).then(response => response.data.data!),
        cbdsApi.getCompany(axiosInstance).then(response => response.data),
    ]).then(([user, userContract, roles, resources, cbdss]) => {
        if (mode === 'CLONETOCREATE') {
            thunk.dispatch(actions.setClonedUserId(user?.id || ''))
            const clonedUser = { ...emptyUser, cbdss: user?.cbdss || [] }
            thunk.dispatch(actions.setUser(clonedUser))
        } else if (mode === 'CLONETOEDIT') {
            const userInfo = select(thunk.getState()).domain.user
            thunk.dispatch(actions.setClonedUserId(user?.id || ''))
            const clonedUser = { ...userInfo, cbdss: user?.cbdss || [] }
            thunk.dispatch(actions.setUser(clonedUser))
        } else {
            thunk.dispatch(actions.setUser(user || emptyUser))
        }
        thunk.dispatch(actions.setUserContract(userContract || emptyUserContract))
        thunk.dispatch(actions.setRoles(roles))
        thunk.dispatch(actions.setResources(resources))
        thunk.dispatch(actions.setCbdss(cbdss))

        if (user) {
            const cbdsUids = user.cbdss.map(cbds => cbds.cbdsUid)
            const customerIds = cbdss.filter(cbds => cbdsUids.includes(cbds.cbdsUid) && cbds.cbdsType === CbdsType.CUST).map(cbds => cbds.cbdsId)
            const supplierIds = cbdss.filter(cbds => cbdsUids.includes(cbds.cbdsUid) && cbds.cbdsType === CbdsType.SUPP).map(cbds => cbds.cbdsId)
            const buIds = cbdss.filter(cbds => cbdsUids.includes(cbds.cbdsUid) && cbds.cbdsType === CbdsType.BU).map(cbds => cbds.cbdsId)
            return contractApi.getAllContracts(axiosInstance, { customerIds, supplierIds, buIds }).then(response => response.data)
        } else {
            return []
        }
    }).then(contracts => {
        thunk.dispatch(actions.setContracts(contracts))
    }).catch(error => {
        thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
    }).then(() => {
        thunk.dispatch(applicationActions.removeMask())
    })
})

const contractDialogInit = createAsyncThunk<void>('cas021/contractDialogInit', async (_, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const user = select(thunk.getState()).domain.user
    const cbdss = select(thunk.getState()).domain.cbdss
    const cbdsUids = user.cbdss.map(cbds => cbds.cbdsUid)
    const customerIds = cbdss.filter(cbds => cbdsUids.includes(cbds.cbdsUid) && cbds.cbdsType === CbdsType.CUST).map(cbds => cbds.cbdsId)
    const supplierIds = cbdss.filter(cbds => cbdsUids.includes(cbds.cbdsUid) && cbds.cbdsType === CbdsType.SUPP).map(cbds => cbds.cbdsId)
    const buIds = cbdss.filter(cbds => cbdsUids.includes(cbds.cbdsUid) && cbds.cbdsType === CbdsType.BU).map(cbds => cbds.cbdsId)

    if (customerIds.length === 0 && supplierIds.length === 0 && buIds.length === 0) {
        thunk.dispatch(actions.setContracts([]))
    } else {
        thunk.dispatch(applicationActions.addMask())
        contractApi.getAllContracts(axiosInstance, { customerIds, supplierIds, buIds })
            .then(response => {
                thunk.dispatch(actions.setContracts(response.data))
            }).catch(error => {
                thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
            }).then(() => {
                thunk.dispatch(applicationActions.removeMask())
            })
    }
})

const clickCreate = createAsyncThunk<void, (id: string) => void>('cas021/clickCreate', (successCallback, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const user = select(thunk.getState()).domain.user
    const userContract = select(thunk.getState()).domain.userContract
    const messagesMap = validate(user, userContract)
    if (Object.values(messagesMap).flat().length > 0) {
        thunk.dispatch(actions.setAllMessages(messagesMap))
        thunk.dispatch(actions.setAllVisited(true))
        thunk.dispatch(applicationActions.pushError({ title: { code: 'inputError' }, messages: { code: 'w0345' } }))
    } else {
        thunk.dispatch(applicationActions.addMask())
        userApi.create(axiosInstance, user)
            .then(response => userContractApi.create(axiosInstance, {
                userId: response.data.id,
                contractIds: userContract.contractIds,
            }).then(() => response)).then(response => {
                thunk.dispatch(applicationActions.pushNotification(responseToNotification(response)))
                successCallback(response.data.id)
            }).catch(error => {
                thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
            }).then(() => {
                thunk.dispatch(applicationActions.removeMask())
            })
    }
})

const clickUpdate = createAsyncThunk<void, (id: string) => void>('cas021/clickUpdate', (successCallback, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const user = select(thunk.getState()).domain.user
    const userContract = select(thunk.getState()).domain.userContract
    const messagesMap = validate(user, userContract)
    if (Object.values(messagesMap).flat().length > 0) {
        thunk.dispatch(actions.setAllMessages(messagesMap))
        thunk.dispatch(actions.setAllVisited(true))
        thunk.dispatch(applicationActions.pushError({ title: { code: 'inputError' }, messages: { code: 'w0345' } }))
    } else {
        thunk.dispatch(applicationActions.addMask())
        Promise.all([
            userApi.update(axiosInstance, { ...user, userId: user.id }),
            userContractApi.update(axiosInstance, userContract),
        ]).then(([response]) => {
            thunk.dispatch(applicationActions.pushNotification(responseToNotification(response)))
            successCallback(response.data.id)
        }).catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        }).then(() => {
            thunk.dispatch(applicationActions.removeMask())
        })
    }
})

const clickChangePassword = createAsyncThunk<void, { oldPassword: string, newPassword: string }>('cas021/clickChangePassword', async ({ oldPassword, newPassword }, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    thunk.dispatch(applicationActions.addMask())
    return await authApi.changePassword(axiosInstance, { oldPassword, newPassword })
        .then(response => {
            thunk.dispatch(applicationActions.pushNotification(responseToNotification(response)))
        }).catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        }).then(() => {
            thunk.dispatch(applicationActions.removeMask())
        })
})

const clickReset = createAsyncThunk<void, void>('cas011/clickReset', (_, thunk) => {
    const mode = select(thunk.getState()).ui.mode
    const id = select(thunk.getState()).domain.user.id
    thunk.dispatch(pageInit({ mode: mode === 'CLONETOCREATE' ? 'CREATE' : mode === 'CLONETOEDIT' ? 'EDIT' : mode, id }))
})

function validate(user: User, userContract: UserContract) {
    const messagesMap: CAS021State['ui']['messagesMap'] = {
        loginId: [],
        name: [],
        contactCode: [],
        localCode: [],
        phone: [],
        email: [],
        defaultCompanyUid: [],
        status: [],
        powerUser: [],
        activateChat: [],
        roleIds: [],
    }
    if (!user.account.loginId) {
        messagesMap.loginId.push({
            field: 'loginId',
            level: 'error',
            value: <FormattedMessage id="error.loginId.required" />
        })
    } else if (user.account.loginId.length > 255) {
        messagesMap.loginId.push({
            field: 'loginId',
            level: 'error',
            value: <FormattedMessage id="error.loginId.maxLength" values={{ length: 255 }} />
        })
    }
    if (!user.name) {
        messagesMap.name.push({
            field: 'name',
            level: 'error',
            value: <FormattedMessage id="error.name.required" />
        })
    } else if (user.name.length > 255) {
        messagesMap.name.push({
            field: 'name',
            level: 'error',
            value: <FormattedMessage id="error.name.maxLength" values={{ length: 255 }} />
        })
    }
    if (user.contactCode && user.contactCode.length > 255) {
        messagesMap.contactCode.push({
            field: 'contactCode',
            level: 'error',
            value: <FormattedMessage id="error.contactCode.maxLength" values={{ length: 255 }} />
        })
    }
    if (user.localCode && user.localCode.length > 255) {
        messagesMap.localCode.push({
            field: 'localCode',
            level: 'error',
            value: <FormattedMessage id="error.localCode.maxLength" values={{ length: 255 }} />
        })
    }
    if (user.phone && user.phone.length > 255) {
        messagesMap.phone.push({
            field: 'phone',
            level: 'error',
            value: <FormattedMessage id="error.phone.maxLength" values={{ length: 255 }} />
        })
    }
    if (!user.email) {
        messagesMap.email.push({
            field: 'email',
            level: 'error',
            value: <FormattedMessage id="error.email.required" />
        })
    } else if (user.email.length > 255) {
        messagesMap.email.push({
            field: 'email',
            level: 'error',
            value: <FormattedMessage id="error.email.maxLength" values={{ length: 255 }} />
        })
    }
    if (!user.cbdss.some(cbds => cbds.defaultCbds)) {
        messagesMap.defaultCompanyUid.push({
            field: 'defaultCompanyUid',
            level: 'error',
            value: <FormattedMessage id="error.defaultCompanyUid.required" />
        })
    }
    // if (user.cbdss.some(cbds => cbds.roleIds.length === 0)) {
    //     messagesMap.roleIds.push({
    //         field: 'roleIds',
    //         level: 'error',
    //         value: <FormattedMessage id="error.role.required" />
    //     })
    // }
    return messagesMap
}

export const slice = createSlice({
    name: 'cas021',
    initialState,
    reducers: {
        setClonedUserId: (state, action: PayloadAction<string>) => {
            state.ui.clonedUserId = action.payload
        },
        setMode: (state, action: PayloadAction<Mode>) => {
            state.ui.mode = action.payload
        },
        setFieldVisited: (state, action: PayloadAction<{ field: keyof CAS021State['ui']['visitedMap'], visited: boolean }>) => {
            state.ui.visitedMap[action.payload.field] = action.payload.visited
        },
        setAllVisited: (state, action: PayloadAction<boolean>) => {
            state.ui.visitedMap = {
                loginId: action.payload,
                name: action.payload,
                contactCode: action.payload,
                localCode: action.payload,
                phone: action.payload,
                email: action.payload,
                defaultCompanyUid: action.payload,
                status: action.payload,
                powerUser: action.payload,
                activateChat: action.payload,
                roleIds: action.payload,
            }
        },
        setAllMessages: (state, action: PayloadAction<CAS021State['ui']['messagesMap']>) => {
            state.ui.messagesMap = action.payload
        },
        clearAllMessages: (state) => {
            state.ui.messagesMap = {
                loginId: [],
                name: [],
                contactCode: [],
                localCode: [],
                phone: [],
                email: [],
                defaultCompanyUid: [],
                status: [],
                powerUser: [],
                activateChat: [],
                roleIds: [],
            }
        },
        setUser: (state, action: PayloadAction<User>) => {
            state.domain.user = action.payload
        },
        setUserContract: (state, action: PayloadAction<UserContract>) => {
            state.domain.userContract = action.payload
        },
        setRoles: (state, action: PayloadAction<Role[]>) => {
            state.domain.roles = action.payload
        },
        setResources: (state, action: PayloadAction<Resource[]>) => {
            state.domain.resources = action.payload
        },
        setCbdss: (state, action: PayloadAction<Cbds[]>) => {
            state.domain.cbdss = action.payload
        },
        setContracts: (state, action: PayloadAction<Contract[]>) => {
            state.domain.contracts = action.payload
        },
        setLoginId: (state, action: PayloadAction<string>) => {
            state.domain.user.account.loginId = action.payload
            state.ui.messagesMap = validate(state.domain.user, state.domain.userContract)
        },
        setName: (state, action: PayloadAction<string>) => {
            state.domain.user.name = action.payload
            state.ui.messagesMap = validate(state.domain.user, state.domain.userContract)
        },
        setContactCode: (state, action: PayloadAction<string | undefined>) => {
            state.domain.user.contactCode = action.payload
            state.ui.messagesMap = validate(state.domain.user, state.domain.userContract)
        },
        setLocalCode: (state, action: PayloadAction<string | undefined>) => {
            state.domain.user.localCode = action.payload
            state.ui.messagesMap = validate(state.domain.user, state.domain.userContract)
        },
        setPhone: (state, action: PayloadAction<string | undefined>) => {
            state.domain.user.phone = action.payload
            state.ui.messagesMap = validate(state.domain.user, state.domain.userContract)
        },
        setEmail: (state, action: PayloadAction<string>) => {
            state.domain.user.email = action.payload
            state.ui.messagesMap = validate(state.domain.user, state.domain.userContract)
        },
        setDefaultCbds: (state, action: PayloadAction<string>) => {
            for (const cbds of state.domain.user.cbdss) {
                if (cbds.cbdsUid === action.payload) {
                    cbds.defaultCbds = true
                } else {
                    cbds.defaultCbds = false
                }
            }
            state.ui.messagesMap = validate(state.domain.user, state.domain.userContract)
        },
        setStatus: (state, action: PayloadAction<UserStatus>) => {
            if (action.payload === UserStatus.Active) {
                state.domain.user.account.active = true
                state.domain.user.account.locked = false
            } else if (action.payload === UserStatus.Locked) {
                state.domain.user.account.active = true
                state.domain.user.account.locked = true
            } else {
                state.domain.user.account.active = false
                state.domain.user.account.locked = false
            }
            state.ui.messagesMap = validate(state.domain.user, state.domain.userContract)
        },
        setPowerUser: (state, action: PayloadAction<boolean>) => {
            state.domain.user.powerUser = action.payload
            state.ui.messagesMap = validate(state.domain.user, state.domain.userContract)
        },
        setActivateChat: (state, action: PayloadAction<boolean>) => {
            state.domain.user.activateChat = action.payload
            state.ui.messagesMap = validate(state.domain.user, state.domain.userContract)
        },
        setCbdsUids: (state, action: PayloadAction<string[]>) => {
            const prevUserCbdss = state.domain.user.cbdss
            state.domain.user.cbdss = action.payload.map(cbdsUid => {
                const prevCbds = prevUserCbdss.find(prevCbds => prevCbds.cbdsUid === cbdsUid)
                if (prevCbds) {
                    return prevCbds
                } else {
                    return {
                        cbdsUid,
                        defaultCbds: false,
                        roleIds: [],
                        resourceIds: [],
                    }
                }
            })
            if (!state.domain.user.cbdss.find(cbds => cbds.defaultCbds) && state.domain.user.cbdss.length > 0) {
                state.domain.user.cbdss[0].defaultCbds = true
            }
            const currentCbdss = state.domain.cbdss.filter(cbds => action.payload.includes(cbds.cbdsUid))
            state.domain.userContract.contractIds = state.domain.userContract.contractIds.filter(contractId => {
                const contract = state.domain.contracts.find(contract => `${contract.contractId}` === contractId)
                return contract && (
                    currentCbdss.some(cbds => (cbds.cbdsType === CbdsType.CUST && contract.customerId === cbds.cbdsId)
                        || (cbds.cbdsType === CbdsType.BU && contract.sellerBuId === cbds.cbdsId)
                        || (cbds.cbdsType === CbdsType.SUPP && contract.supplierId === cbds.cbdsId))
                )
            })
            state.ui.messagesMap = validate(state.domain.user, state.domain.userContract)
        },
        setCbdsRoleIds: (state, action: PayloadAction<{ cbdsUid: string, roleIds: string[] }>) => {
            const cbds = state.domain.user.cbdss.find(cbds => cbds.cbdsUid === action.payload.cbdsUid)
            if (cbds) {
                cbds.roleIds = action.payload.roleIds
            }
            state.ui.messagesMap = validate(state.domain.user, state.domain.userContract)
        },
        setCbdsResourceIds: (state, action: PayloadAction<{ cbdsUid: string, resourceIds: string[] }>) => {
            const cbds = state.domain.user.cbdss.find(cbds => cbds.cbdsUid === action.payload.cbdsUid)
            if (cbds) {
                cbds.resourceIds = action.payload.resourceIds
            }
            state.ui.messagesMap = validate(state.domain.user, state.domain.userContract)
        },
        setContractIds: (state, action: PayloadAction<string[]>) => {
            state.domain.userContract.contractIds = action.payload
            state.ui.messagesMap = validate(state.domain.user, state.domain.userContract)
        }
    },
})

const actions = {
    ...slice.actions,
    pageInit,
    contractDialogInit,
    clickCreate,
    clickUpdate,
    clickChangePassword,
    clickReset,
}

function select(state: any): CAS021State {
    return state[appConfig.appFullName][cas021Slice.name]
}

export const cas021Slice = slice
export const cas021Actions = actions
export function useCAS021Selector<R>(selector: (state: CAS021State) => R, equalityFn?: (left: R, right: R) => boolean) {
    return useSelector<any, R>(state => selector(select(state)), equalityFn)
}