import { FormattedMessage } from 'react-intl'
import { CallbackViewAction } from '../../../components/Action/CallbackViewAction'


interface SearchCallbackViewActionProps {
    access?: string,
    disabled?: boolean,
    outlined?: boolean,
    callback: () => void,
}

export const SearchCallbackViewAction = (props: SearchCallbackViewActionProps) => {
    return <CallbackViewAction {...props} title={<FormattedMessage id="search" />} />
}