import { Avatar, IconButton, makeStyles, Typography } from "@material-ui/core"
import { Check, Close, ExpandLess, ExpandMore, PriorityHigh } from "@material-ui/icons"
import { condition } from "@rithe/utils"
import clsx from 'clsx'
import React, { ReactNode, useLayoutEffect, useRef } from "react"
import { DottedDivider } from "../Divider/DottedDivider"
import { useStepContext } from "../View/Step/StepContext"
import { useCollapseContext } from "./CollapseContext"

interface SectionCardHeaderProps {
    step?: boolean,
    serialNumber?: number,
    variant?: 'normal' | 'success' | 'warning' | 'error' | 'info' | 'disabled',
    icon?: ReactNode,
    title: string,
    subtitle?: string,
    actions?: ReactNode | ReactNode[],
}

export function SectionCardHeader(props: SectionCardHeaderProps) {
    const { step, serialNumber, variant, icon, title, subtitle, actions } = props
    const { enabled, collapsed, toggle } = useCollapseContext()
    const { registerAnchorEl, unregisterAnchorEl } = useStepContext()
    const ref = useRef<HTMLDivElement>(null)
    useLayoutEffect(() => {
        const anchorEl = ref.current
        step && anchorEl && registerAnchorEl(anchorEl, { serialNumber, variant, icon, title })
        return () => { step && anchorEl && unregisterAnchorEl(anchorEl) }
    }, [icon, registerAnchorEl, serialNumber, step, title, unregisterAnchorEl, variant])

    const styles = useStyles()

    const styledClassName = condition()
        .case(variant === 'success').then(styles.success)
        .case(variant === 'warning').then(styles.warning)
        .case(variant === 'error').then(styles.error)
        .case(variant === 'info').then(styles.info)
        .case(variant === 'disabled').then(styles.disabled)
        .default(styles.normal)

    const iconPart = condition()
        .case(variant === 'success').then(<Check />)
        .case(variant === 'warning').then(<PriorityHigh />)
        .case(variant === 'error').then(<Close />)
        .case(!!icon && icon !== true).then(icon)
        .case(serialNumber !== undefined).then(<Typography variant="body1">{serialNumber}</Typography>)
        .default(null)

    return <>
        <div ref={ref} className={styles.root}>
            {iconPart && <Avatar className={clsx(styles.icon, styledClassName)}>{iconPart}</Avatar>}
            <div className={styles.content}>
                <Typography variant="subtitle1" color="textPrimary" className={styles.title}>
                    {title}
                </Typography>
                <Typography variant="body2" color="textSecondary" className={styles.subtitle}>
                    {subtitle}
                </Typography>
            </div>
            <div className={styles.actions}>
                {React.Children.map(actions, (action, index) => {
                    return <div key={index} style={{ marginLeft: 12 }}>{action}</div>
                })}
                {condition()
                    .case(enabled)
                    .then(<IconButton onClick={toggle} style={{ marginLeft: 12 }} id={serialNumber ? "step_" + serialNumber : undefined}>
                        {collapsed ? <ExpandMore /> : <ExpandLess />}
                    </IconButton>)
                    .default(null)}
            </div>
        </div>
        <DottedDivider flexItem className={clsx(styles.divider, collapsed && styles.dividerHidden)} />
    </>
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: theme.spacing(1),
        flex: '0 0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'stretch',
    },
    icon: {
        flex: '0 0 auto',
        width: 32,
        height: 32,
        marginRight: theme.spacing(1),
    },
    success: {
        backgroundColor: theme.palette.success.main,
        color: 'white',
    },
    warning: {
        backgroundColor: theme.palette.warning.main,
        color: 'white',
    },
    error: {
        backgroundColor: theme.palette.error.main,
        color: 'white',
    },
    info: {
        backgroundColor: theme.palette.info.main,
        color: 'white',
    },
    disabled: {
        backgroundColor: '#EAEFF2',
        color: theme.palette.text.disabled,
    },
    normal: {
        backgroundColor: '#EAEFF2',
        color: theme.palette.text.primary,
    },
    content: {
        // height: 32, //minHeight
        minHeight: 32,
        flex: '1 1 auto',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-around',
    },
    title: {
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        textTransform: 'uppercase',
        lineHeight: 1,
        paddingBottom: '1.7px',
    },
    subtitle: {
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'break-spaces',  //break-spaces ,nowrap
        textOverflow: 'ellipsis',
        lineHeight: 1.7,
    },
    actions: {
        flex: '0 0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    divider: {
        height: 1,
        margin: `${theme.spacing(1)}px 0`,
        transition: `all ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}`,
    },
    dividerHidden: {
        height: 0,
        margin: 0,
    },
}))