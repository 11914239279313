import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { dateToJson } from "../../utils/serializer"

export interface PlaceOrderDetailFactor {
    contractRouteId: number,
    coId: number | null,
    orderStartDate: Date | null,
    orderEndDate: Date | null,
}

interface PlaceOrderDetailFactorJson {
    contractRouteId: number,
    coId: number | null,
    orderStartDate: string | null,
    orderEndDate: string | null,
}

export const placeOrderDetailFactorSerializer: Project<PlaceOrderDetailFactor, PlaceOrderDetailFactorJson> = factor => ({
    contractRouteId: factor.contractRouteId,
    coId: factor.coId,
    orderStartDate: factor.orderStartDate ? dateToJson(factor.orderStartDate) : null,
    orderEndDate: factor.orderEndDate ? dateToJson(factor.orderEndDate) : null,
})

export const placeOrderDetailFactorDeserializer: Project<PlaceOrderDetailFactorJson, PlaceOrderDetailFactor> = json => ({
    contractRouteId: json.contractRouteId,
    coId: json.coId,
    orderStartDate: json.orderStartDate ? dateFromJson(json.orderStartDate) : null,
    orderEndDate: json.orderEndDate ? dateFromJson(json.orderEndDate) : null,
})
