import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"

export type UserActiveFactorDownloadListFactor = Omit<UserActiveListFactor, 'page'>
export type UserActiveFactorDownloadListFactorJson = Omit<UserActiveListFactorJson, 'page'>
export const UserActiveFactorDownloadListFactorToJson: Project<UserActiveListFactor, UserActiveListFactorJson> = obj => ({
    ...obj,
})

export type UserActiveListFactor = Partial<Optional>
export type UserActiveListFactorJson = Partial<JsonOptional>
export const UserActiveFactorListFactorToJson: Project<UserActiveListFactor, UserActiveListFactorJson> = obj => ({
    ...obj,
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
})

export const UserActiveFactorListFactorFromJson: Project<UserActiveListFactorJson, UserActiveListFactor> = json => ({
    ...json,
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page),
})

interface Optional extends Omit<JsonOptional, 'page'> {
    page: PageViewEntity
}

interface JsonOptional {
  loginIdContains: string
  nameContains: string
  emailContains: string
  contactCodeContains: string
  onlineChatStatus: number[]
  page: PageViewEntityJson
}
