import { Project } from "../../../utils/Project"

export type IdAndCode = Required & Partial<Optional>
type IdAndCodeJson = JsonRequired & Partial<JsonOptional>
export const idAndCodeFromJson: Project<IdAndCodeJson, IdAndCode> = json => ({
    ...json,
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {

}

interface JsonRequired {
}

interface JsonOptional {
    id: number,
    code: string,
}