import { Project } from "../../../utils/Project"
import { ShippingMode } from "../../master/enums/ShippingMode"
import { dateFromJson } from "../../utils/deserializer"
import { UploadOutboundType } from "../enums/UploadOutboundType"


export interface OutboundMonitorInfo {
    receiver: string,
    outboundNo: string,
    outboundDate: Date,
    outboundTime: string,
    outboundRefNo: string,
    outboundType: UploadOutboundType,
    etd?: Date,
    eta?: Date,
    shippingMode: ShippingMode,
    bookingNo?: string,
    containers?: ContainerInfo[],
    datasource: number,
}

interface OutboundMonitorInfoJson {
    receiver: string,
    outboundNo: string,
    outboundDate: string,
    outboundTime: string,
    outboundRefNo: string,
    outboundType: number,
    etd?: string,
    eta?: string,
    shippingMode: number,
    bookingNo?: string,
    containers?: ContainerInfoJson[],
    datasource: number,
}

export const outboundMonitorInfoFromJson: Project<OutboundMonitorInfoJson, OutboundMonitorInfo> = json => ({
    ...json,
    outboundDate: dateFromJson(json.outboundDate),
    etd: dateFromJson(json.etd),
    eta: dateFromJson(json.eta),
    containers: json.containers?.map(ContainerInfoFromJson),
})

// Container information
export interface ContainerInfo {
    containerNo?: string,
    containerType?: string,
    commodityType?: string,
    sealNo?: string,
    m3?: number,
    netWeight?: number,
    grossWeight?: number,
    outerPackageList?: OuterPackageInfo[],
}

interface ContainerInfoJson {
    containerNo?: string,
    containerType?: string,
    sealNo?: string,
    m3?: number,
    netWeight?: number,
    grossWeight?: number,
    outerPackageList?: OuterPackageInfoJson[],
}

const ContainerInfoFromJson: Project<ContainerInfoJson, ContainerInfo> = json => ({
    ...json,
    outerPackageList: json.outerPackageList?.map(outerPackageInfoFromJson),
})

//outer package
export interface OuterPackageInfo {
    palletNo?: string,
    outerPackageNo: string,
    outerPackageType?: string,
    productionDate?: Date,
    m3?: number,
    netWeight?: number,
    grossWeight?: number,
    outboundByPackage: boolean,
    innerPackageList: InnerPackageInfo[],
}

interface OuterPackageInfoJson {
    palletNo?: string,
    outerPackageNo: string,
    outerPackageType?: string,
    productionDate?: string,
    m3?: number,
    netWeight?: number,
    grossWeight?: number,
    outboundByPackage: boolean,
    innerPackageList: InnerPackageInfoJson[],
}

const outerPackageInfoFromJson: Project<OuterPackageInfoJson, OuterPackageInfo> = json => ({
    ...json,
    productionDate: dateFromJson(json.productionDate),
    partsList: json.innerPackageList.map(innerPackageInfoFromJson)
})

//inner package
export interface InnerPackageInfo {
    boxNo?: string,
    ipPackageType?: string,
    m3?: number,
    netWeight?: number,
    grossWeight?: number,
    partsList: OutboundPartsInfo[],
}

interface InnerPackageInfoJson {
    boxNo?: string,
    ipPackageType?: string,
    m3?: number,
    netWeight?: number,
    grossWeight?: number,
    partsList: OutboundPartsInfoJson[],
}


const innerPackageInfoFromJson: Project<InnerPackageInfoJson, InnerPackageInfo> = json => ({
    ...json,
    partsList: json.partsList.map(outboundPartsInfoFromJson)
})

//parts
export interface OutboundPartsInfo {
    soNo: string,
    buyerCode: string,
    sellerCode: string,
    sellerPartsNo: string,
    sellerPartsName?: string,
    sellerBackNo?: string,
    colorCode?: string,
    srbq: number,
    partsNo: string,
    qty?: number,
}

interface OutboundPartsInfoJson {
    soNo: string,
    buyerCode: string,
    sellerCode: string,
    sellerPartsNo: string,
    sellerPartsName?: string,
    sellerBackNo?: string,
    colorCode?: string,
    srbq: number,
    partsNo: string,
    qty?: number,
}

const outboundPartsInfoFromJson: Project<OutboundPartsInfoJson, OutboundPartsInfo> = json => ({
    ...json
})
