import { useEffect, useMemo, useState } from "react"
import { useComMasterGetAllUom } from "../../../services/common/apis/commonMasterApi"
import { TnmUom } from "../../../services/master/models/TnmUom"
import { UnitPartsResult, unitPartsResultFromJson } from "../../../services/master/models/UnitPartsResult"
import sessionKeys from "../../../utils/sessionKeys"
import { useGetSessionAndGobackIfEmpty } from "../../../utils/sessionUtil"
import { useMatch } from "../../../utils/useMatch"
import { MLS081PcUi } from "./MLS081PcUi"

export interface UnitPartsResultEx extends UnitPartsResult {
    virturalId: number,
}

export const MLS081 = () => {

    const { path } = useMatch()
    const getSession = useGetSessionAndGobackIfEmpty()
    const cachedPratsList = useMemo(() => {
        if (path === '/unitparts/create') {
            return [] as UnitPartsResult[]
        } else {
            return getSession(sessionKeys.Data_MLS081, unitPartsResultFromJson, '/unitparts') as UnitPartsResult[]
        }
    }, [getSession, path])
    const partsList = useMemo(() => cachedPratsList.map((record, index) => ({ virturalId: index, ...record })), [cachedPratsList])
    const [entityList, setEntityList] = useState<UnitPartsResultEx[]>(partsList)

    const [uoms, setUoms] = useState<TnmUom[]>([])
    const getUoms = useComMasterGetAllUom()
    useEffect(() => {
        getUoms(undefined, { silent: true, serialized: true }).then(result => setUoms(result || []))
    }, [getUoms])

    return <MLS081PcUi
        entityList={entityList}
        setEntityList={setEntityList}
        uoms={uoms}
    />
}