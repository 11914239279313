import { FormControl, FormControlLabel, Radio, RadioGroup, Typography, useTheme } from "@material-ui/core"
import { Column, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Editing, Filtering, KeyTypeProvider, NumberTypeProvider, PaginationLayout, Paging, Row, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarLayout } from "@rithe/data-grid"
import { GridContainer, GridItem } from "@rithe/ui"
import { Objects, Records } from "@rithe/utils"
import React, { memo, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { DownloadCallbackCardAction } from "../../../components/Action/DownloadCallbackCardAction"
import { SubmitCallbackViewAction } from "../../../components/Action/SubmitCallbackViewAction"
import { UploadCallbackCardAction } from "../../../components/Action/UploadCallbackCardAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CreateCallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/CreateCallbackToolbarAction"
import { RemoveCallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/RemoveCallbackToolbarAction"
import { ActiveFlagTypeProvider } from "../../../components/DataGrid/typeProviders/ActiveFlagTypeProvider"
import { ClearableKeyTypeProvider } from "../../../components/DataGrid/typeProviders/ClearableKeyTypeProvider"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { View } from "../../../components/View/View"
import { useFunctionStore } from "../../../Root"
import { useSaveGlobalPartsList } from "../../../services/master/apis/globalPartsApi"
import { useDownloadGlobalPartsMasterByEdit } from "../../../services/master/apis/masterDownloadApi"
import { useUploadMasterGlobalPartsMaster } from "../../../services/master/apis/masterUploadApi"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { TnmUom } from "../../../services/master/models/TnmUom"
import { applicationActions } from "../../Application/applicationSlice"
import { GlobalPartsResultEx } from "./MLS071"

interface MLS071PcUiProps {
    entityList: GlobalPartsResultEx[],
    setEntityList: React.Dispatch<React.SetStateAction<GlobalPartsResultEx[]>>
    uoms: TnmUom[],
}

export const MLS071PcUi = (props: MLS071PcUiProps) => {
    const { entityList, setEntityList, uoms } = props
    const intl = useIntl()
    const [type, setType] = useState<number>(0)
    const actions = [<SubmitAction entityList={entityList} setEntityList={setEntityList} />]

    return <View actions={actions}>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={1}
                title={'Select Mode'}
                subtitle={intl.formatMessage({ id: 'SelectGlobalparts' })}
            />
            <SectionCardContent>
                <SelectCard type={type} setType={setType} />
            </SectionCardContent>
        </SectionCard>
        {type === 1 && <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={2}
                title={'Select Download Or Upload'}
                subtitle={'Please select download or upload'}
            />
            <SectionCardContent>
                <StepDownloadUploadCard entityList={entityList} setEntityList={setEntityList} />
            </SectionCardContent>
        </SectionCard>
        }
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={type === 1 ? 3 : 2}
                title={'Display Data'}
                subtitle={'Please create or modify data'}
            />
            <SectionCardContent>
                <DataTable uoms={uoms} entityList={entityList} setEntityList={setEntityList} />
            </SectionCardContent>
        </SectionCard>
    </View >
}

interface SelectCardProps {
    type: number,
    setType: React.Dispatch<React.SetStateAction<number>>
}

const SelectCard = memo(({ type, setType }: SelectCardProps) => {
    const intl = useIntl()
    const changeRedioValue = useCallback((value) => (event: React.ChangeEvent<{}>, checked: boolean) => { checked && setType(value) }, [setType])
    return <FormControl component="fieldset">
        <RadioGroup aria-label="method" name="method" value={type} >
            <FormControlLabel value={0} control={<Radio id="MLS071_Radio_CreateGlobalparts" />} onChange={changeRedioValue(0)} label={intl.formatMessage({ id: 'CreateGlobalparts' })} />
            <FormControlLabel value={1} control={<Radio id="MLS071_Radio_CreateGlobalPartsbyDownloadUpload" />} onChange={changeRedioValue(1)} label={intl.formatMessage({ id: 'CreateGlobalPartsbyDownloadUpload' })} style={{ marginTop: '20px' }} />
        </RadioGroup>
    </FormControl>
})

interface DownloadUploadProps {
    entityList: GlobalPartsResultEx[],
    setEntityList: React.Dispatch<React.SetStateAction<GlobalPartsResultEx[]>>,
}
const StepDownloadUploadCard = memo(({ entityList, setEntityList }: DownloadUploadProps) => {
    const theme = useTheme()
    return (
        <GridContainer columnWidth={[500, 200]} style={{ paddingLeft: theme.spacing(5) }} rowGap={theme.spacing(2)}>
            <GridItem style={{ display: 'flex', alignItems: 'center' }} >
                <Typography variant='body1'><FormattedMessage id='downloadGlobalInStep1' /></Typography>
            </GridItem>
            <GridItem>
                <DownloadAction entityList={entityList} />
            </GridItem>
            <GridItem style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='body1'><FormattedMessage id='uplaodGlobalInStep2' /></Typography>
            </GridItem>
            <GridItem >
                <UploadAction setEntityList={setEntityList} />
            </GridItem>
        </GridContainer>
    )
})

const UploadAction = ({ setEntityList }: { setEntityList: React.Dispatch<React.SetStateAction<GlobalPartsResultEx[]>> }) => {
    const uploadMethod = useUploadMasterGlobalPartsMaster()
    const upload = useCallback((files: FileList | null) => {
        if (files === null) return
        uploadMethod({ file: files[0] }, { serialized: true }).then((result) => {
            if (result) {
                setEntityList((result || []).map((m, idx) => ({ lineId: idx, ...m } as GlobalPartsResultEx)))
            }
        })
    }, [setEntityList, uploadMethod])

    return <UploadCallbackCardAction access="MARS.MLS071.UPLOAD" callback={upload} />
}

const DownloadAction = ({ entityList }: { entityList: GlobalPartsResultEx[] }) => {
    const downloadByEdit = useDownloadGlobalPartsMasterByEdit()
    const download = useCallback(() => {
        const partsList = entityList.map(m => Objects.delete(m, 'lineId'))
        downloadByEdit(partsList)
    }, [downloadByEdit, entityList])
    return <DownloadCallbackCardAction outlined access="MARS.MLS071.DOWNLOAD" callback={download} />
}

const DataTable = memo(({ uoms, entityList, setEntityList }: {
    uoms: TnmUom[],
    entityList: GlobalPartsResultEx[],
    setEntityList: React.Dispatch<React.SetStateAction<GlobalPartsResultEx[]>>
}) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])
    const weightDigits = useMemo(() => uoms.find(f => "KG" === f.uomCode)?.decimalDigits, [uoms])
    const columns = useMemo(() => [
        { field: 'newMod', dataTypeName: 'NewModType', title: intl.formatMessage({ id: 'newMod' }), width: 180 },
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 180 },
        { field: 'oldPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.oldPartsNo' }), width: 180 },
        { field: 'displayPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.displayPartsNo' }), width: 180 },
        { field: 'colorCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.colorCode' }), width: 150 },
        { field: 'uomCode', dataTypeName: 'uomType', title: intl.formatMessage({ id: 'field.uomCode' }), width: 180 },
        { field: 'netWeight', dataTypeName: 'weight', title: intl.formatMessage({ id: 'field.netWeight' }), width: 150 },
        { field: 'rolledPartsFlag', dataTypeName: CodeCategory.RolledPartsFlag, title: intl.formatMessage({ id: 'field.rolledPartsFlag' }), width: 190 },
        { field: 'rolledPartsUom', dataTypeName: 'uomType', title: intl.formatMessage({ id: 'field.rolledPartsUom' }), width: 190 },
        { field: 'partsType', dataTypeName: CodeCategory.PartsType, title: intl.formatMessage({ id: 'field.partsType' }), width: 130 },
        { field: 'remark1', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.remark1' }), width: 270 },
        { field: 'remark2', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.remark2' }), width: 270 },
        { field: 'remark3', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.remark3' }), width: 270 },
        { field: 'remark4', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.remark4' }), width: 270 },
        { field: 'remark5', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.remark5' }), width: 270 },
        { field: 'activeFlag', dataTypeName: CodeCategory.ActiveFlag, title: intl.formatMessage({ id: 'field.activeFlag' }), width: 150 },
    ], [intl])

    const actionProps1 = useMemo(() => ({ setEntityList }), [setEntityList])
    const actionProps2 = useMemo(() => ({ selections, setEntityList, setSelections }), [selections, setEntityList, setSelections])
    const displayAdd = useCallback(() => true, [])
    const displayRemove = useCallback(() => true, [])
    const onEditingRowCommit = useCallback((_: Column, row: Row) => {
        setEntityList(entityList => entityList.map((item, idx) => item.lineId === row.lineId ? row as GlobalPartsResultEx : item))
        return true
    }, [setEntityList])

    const editingDisabledColumns = {}//{ partsNo: { editingDisabled: mode !== 'create' } }

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.RolledPartsFlag} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.PartsType} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.UnitType} />
            <NumberTypeProvider name="weight" options={{ minimumFractionDigits: weightDigits !== undefined ? weightDigits : 3, maximumFractionDigits: weightDigits !== undefined ? weightDigits : 3 }} />
            <UomProvider uoms={uoms} />
            <NewModTypeProvider />
            <ActiveFlagTypeProvider />
            <Data rows={entityList} columns={columns} />
            <ToolbarActionProvider Action={AddAction} actionProps={actionProps1} display={displayAdd} />
            <ToolbarActionProvider Action={RemoveAction} actionProps={actionProps2} display={displayRemove} />
            <ColumnFreeze />
            <ColumnVisibility
                columnSettings={{
                    partsNo: { disableUserControl: true },
                    oldPartsNo: { disableUserControl: true },
                    displayPartsNo: { disableUserControl: true },
                }} ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Editing
                enableInlineEdit
                onEditingCellCommit={onEditingRowCommit}
                columnSettings={editingDisabledColumns}
            />
            <Sorting />
            <Filtering />
            <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
            <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        </DataGrid>
    </div>
})

const NewModTypeProvider = (props: {}) => {
    const formatter = useMemo(() => ({ format: (value: any) => value }), [])
    const options = useMemo(() => ['NEW', 'MOD'], [])
    return <KeyTypeProvider name={'NewModType'} formatter={formatter} options={options} />
}

const UomProvider = ({ uoms }: { uoms: TnmUom[] }) => {
    const formatter = useMemo(() => ({ format: (value: any) => value }), [])
    const options = useMemo(() => uoms.map(uom => uom.uomCode), [uoms])
    return <ClearableKeyTypeProvider name={'uomType'} formatter={formatter} options={options} />
}

const SubmitAction = (({ entityList, setEntityList }: {
    entityList: GlobalPartsResultEx[],
    setEntityList: React.Dispatch<React.SetStateAction<GlobalPartsResultEx[]>>
}) => {
    const navigate = useNavigate()
    const saveEvent = useSaveGlobalPartsList()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'submit' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const clickEvent = useCallback(() => {
        if (entityList.length === 0) {
            dispatch(applicationActions.pushError({ title: { code: 'submit' }, messages: { code: 'w0002' } }))
            return
        }
        const functionId = functionStore.register(() => {
            setDisabled(true)
            const partsList = entityList.map(m => Objects.delete(m, 'lineId'))
            saveEvent(partsList, { serialized: true }).then(() => {
                navigate(`/globalparts`)
            }).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [dispatch, entityList, functionStore, navigate, saveEvent, title])

    return <SubmitCallbackViewAction access="MARS.MLS071.SUBMIT" callback={clickEvent} disabled={disabled} />
})

const AddAction = ({ setEntityList }: { setEntityList: React.Dispatch<React.SetStateAction<GlobalPartsResultEx[]>> }) => {
    const callback = useCallback(() => setEntityList(entityList => [...entityList, { newMod: 'NEW', lineId: entityList.length } as GlobalPartsResultEx]), [setEntityList])
    return <CreateCallbackToolbarAction title={<FormattedMessage id="Add Item" />} callback={callback} />
}

const RemoveAction = ({ selections, setSelections, setEntityList }: {
    selections: number[],
    setSelections: React.Dispatch<React.SetStateAction<number[]>>,
    setEntityList: React.Dispatch<React.SetStateAction<GlobalPartsResultEx[]>>
}) => {
    const callback = useCallback(() => (
        setSelections((selections) => {
            setEntityList(entityList => entityList.filter((f, idx) => selections.indexOf(idx) < 0))
            return []
        })
    ), [setEntityList, setSelections])
    return <RemoveCallbackToolbarAction title={<FormattedMessage id="Remove Item" />} callback={callback} />
}
