import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DateRangeItem, EntryItem, StringItem } from "@rithe/form"
import { Records } from "@rithe/utils"
import React, { useCallback, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { CargoTrackingFactor } from "../../../services/delivery/models/CargoTrackingFactor"
import { CargoTrackingView } from "../../../services/delivery/models/CargoTrackingView"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { TnmPort } from "../../../services/master/models/TnmPort"
import { TnvCbds } from "../../../services/master/models/TnvCbds"

interface LCS010PcUiProps {
    filters: CargoTrackingFactor,
    setFilters: React.Dispatch<React.SetStateAction<CargoTrackingFactor>>,
    search: (filters: CargoTrackingFactor) => void,
    data: CargoTrackingView[],
    totalCount: number,
    sellerList: TnvCbds[],
    portList: TnmPort[],
}

export const LCS010PcUi = (props: LCS010PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = (props: LCS010PcUiProps) => {
    const { filters, setFilters, search, data, totalCount, sellerList, portList } = props
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])

    const columns = useMemo(() => [
        { field: 'bookingNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.bookingNo' }), width: 200 },
        { field: 'containerNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.containerNo' }), width: 200 },
        { field: 'outboundNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.outboundNo' }), width: 200 },
        { field: 'shippingMode', dataTypeName: CodeCategory.ShippingMode, title: intl.formatMessage({ id: 'field.shippingMode' }), width: 200 },
        { field: 'sellerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'seller' }), width: 150 },
        { field: 'loadingPortCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'loadingPort' }), width: 200 },
        { field: 'dischargePortCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'dischargePort' }), width: 180 },
        { field: 'etd', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.etd' }), width: 150 },
        { field: 'atd', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.atd' }), width: 150 },
        { field: 'eta', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.eta' }), width: 150 },
        { field: 'ata', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.ata' }), width: 150 },
        { field: 'cargoStatus', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.cargoStatus' }), width: 180 },
    ], [intl])
    const actionProps1 = useMemo(() => ({ filters, setFilters, search, sellerList, portList }), [filters, setFilters, search, sellerList, portList])
    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.ShippingMode} />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.FtaFlag} />
        <Data rows={data} columns={columns} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['outboundNo']}
            ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={actionProps1} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
        <Action width={126} />
    </DataGrid>

}

const FilterItem = (props: {
    filters: CargoTrackingFactor,
    setFilters: React.Dispatch<React.SetStateAction<CargoTrackingFactor>>,
    search: (filters: CargoTrackingFactor) => void,
    sellerList: TnvCbds[],
    portList: TnmPort[],
}) => {
    const { filters, setFilters, search, sellerList, portList } = props

    const sellerEntires: [string, string][] = useMemo(() => sellerList.map(({ cbdsUid, cbdsCode }) => [cbdsUid, cbdsCode]), [sellerList])
    const portEntries: [number, string][] = useMemo(() => portList.map(port => [port.portId, port.portCode]), [portList])

    const clear = useCallback((filters: CargoTrackingFactor) => {
        return { page: filters.page }
    }, [])
    const etdGetValue = useCallback((filters: CargoTrackingFactor) => {
        return [filters.etdStart ?? null, filters.etdEnd ?? null]
    }, [])
    const etdMapValue = useCallback((filters: CargoTrackingFactor, value: any) => {
        return { ...filters ?? {}, etdStart: value[0], etdEnd: value[1] }
    }, [])
    const atdGetValue = useCallback((filters: CargoTrackingFactor) => {
        return [filters.atdStart ?? null, filters.atdEnd ?? null]
    }, [])
    const atdMapValue = useCallback((filters: CargoTrackingFactor, value: any) => {
        return { ...filters ?? {}, atdStart: value[0], atdEnd: value[1] }
    }, [])
    const etaGetValue = useCallback((filters: CargoTrackingFactor) => {
        return [filters.etaStart ?? null, filters.etaEnd ?? null]
    }, [])
    const etaMapValue = useCallback((filters: CargoTrackingFactor, value: any) => {
        return { ...filters ?? {}, etaStart: value[0], etaEnd: value[1] }
    }, [])
    const ataGetValue = useCallback((filters: CargoTrackingFactor) => {
        return [filters.ataStart ?? null, filters.ataEnd ?? null]
    }, [])
    const ataMapValue = useCallback((filters: CargoTrackingFactor, value: any) => {
        return { ...filters ?? {}, ataStart: value[0], ataEnd: value[1] }
    }, [])
    const updatedDateTimeGetValue = useCallback((filters: CargoTrackingFactor) => {
        return [filters.updatedDateTimeStart ?? null, filters.updatedDateTimeEnd ?? null]
    }, [])
    const updatedDateTimeMapValue = useCallback((filters: CargoTrackingFactor, value: any) => {
        return { ...filters ?? {}, updatedDateTimeStart: value[0], updatedDateTimeEnd: value[1] }
    }, [])

    const filterCounter = useCallback((filters: CargoTrackingFactor) => {
        return [
            filters.bookingNo,
            filters.containerNo,
            filters.shippingMode,
            filters.sellerUid,
            filters.loadingPortId,
            filters.dischargePortId,
            filters.etdStart || filters.etdEnd,
            filters.atdStart || filters.atdEnd,
            filters.etaStart || filters.etaEnd,
            filters.ataStart || filters.ataEnd,
            filters.cargoStatus,
            filters.updatedDateTimeStart || filters.updatedDateTimeEnd,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()
    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="bookingNo" label={intl.formatMessage({ id: 'field.bookingNo' })} />
        <StringItem field="containerNo" label={intl.formatMessage({ id: 'field.containerNo' })} />
        <CodesItem field="shippingMode" label={intl.formatMessage({ id: 'field.shippingMode' })} code={CodeCategory.ShippingMode} />
        <EntryItem field="seller" label={intl.formatMessage({ id: 'seller' })} entries={sellerEntires} />
        <EntryItem field="loadingPortId" label={intl.formatMessage({ id: 'loadingPort' })} entries={portEntries} />
        <EntryItem field="dischargePortId" label={intl.formatMessage({ id: 'dischargePort' })} entries={portEntries} />
        <DateRangeItem field="etd" label={intl.formatMessage({ id: 'field.etd' })} getValue={etdGetValue} mapValue={etdMapValue} />
        <DateRangeItem field="atd" label={intl.formatMessage({ id: 'field.atd' })} getValue={atdGetValue} mapValue={atdMapValue} />
        <DateRangeItem field="eta" label={intl.formatMessage({ id: 'field.eta' })} getValue={etaGetValue} mapValue={etaMapValue} />
        <DateRangeItem field="ata" label={intl.formatMessage({ id: 'field.ata' })} getValue={ataGetValue} mapValue={ataMapValue} />
        <StringItem field="cargoStatus" label={intl.formatMessage({ id: 'field.cargoStatus' })} />
        <DateRangeItem field="updatedDateTime" label={intl.formatMessage({ id: 'field.updatedDateTime' })} getValue={updatedDateTimeGetValue} mapValue={updatedDateTimeMapValue} />
    </FilterToolbarItem>
}