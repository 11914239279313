import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { useSelector } from "react-redux"
import appConfig from "../../../configs/appConfig"
import authApi from "../../../services/system/apis/authApi"
import userApi from "../../../services/system/apis/userApi"
import { UserStatus } from "../../../services/system/enums/UserStatus"
import cbdsApi from "../../../services/systemmaster/apis/cbdsApi"
import errorToNotification from "../../../utils/axios/errorToNotification"
import responseToNotification from "../../../utils/axios/responseToNotification"
import thunkAxiosInstance from "../../../utils/axios/thunkAxiosInstance"
import { applicationActions, selectApplicationState } from "../../Application/applicationSlice"

interface Filter {
    nameContains?: string,
    emailContains?: string,
    phoneContains?: string,
    contactCodeContains?: string,
    localCodeContains?: string,
    statusIn?: number[],
    loginIdContains?: string,
    cbdsUidIn?: string[],
}

interface Cbds {
    cbdsUid: string,
    cbdsCode: string,
}

interface User {
    id: string,
    name: String,
    powerUser: boolean,
    email: string,
    phone?: string,
    contactCode?: string,
    localCode?: string,
    account: UserAccount,
    cbdss: UserCbds[],
}

interface UserAccount {
    loginId: string,
    expireAt?: number,
    active: boolean,
    locked: boolean,
}

interface UserCbds {
    cbdsUid: string,
    defaultCbds: boolean,
    roleIds: string[],
    resourceIds: string[],
}

interface CAS020State {
    filter: Filter,
    cbdss: Cbds[],
    users: User[]
}

const cacheFilters = sessionStorage.getItem( `${appConfig.appFullName}:CAS020.filter`)
const initialState: CAS020State = {
    filter: cacheFilters ? JSON.parse(cacheFilters) : {
        nameContains: undefined,
        emailContains: undefined,
        phoneContains: undefined,
        contactCodeContains: undefined,
        localCodeContains: undefined,
        loginIdContains: undefined,
        statusIn: undefined,
        cbdsUidIn: undefined,
    },
    cbdss: [],
    users: [],
}

const listAllUsers = createAsyncThunk<void, void>('cas020/listAllUsers', async (_, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const currentCbdsUid = selectApplicationState(thunk.getState()).auth.companyUid
    thunk.dispatch(applicationActions.addMask())
    return await userApi.list(axiosInstance, { cbdsUidIn: currentCbdsUid ? [currentCbdsUid] : [], pageSize: 1000 })
        .then(response => {
            thunk.dispatch(cas020Actions.setUsers(response.data.data!))
        })
        .catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        }).then(() => {
            thunk.dispatch(applicationActions.removeMask())
        })
})

const listUsers = createAsyncThunk<void, void>('cas020/listUsers', async (_, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const filter = selectCAS020State(thunk.getState()).filter
    const currentCbdsUid = selectApplicationState(thunk.getState()).auth.companyUid
    let active = undefined as boolean | undefined, locked = undefined as boolean | undefined
    if (filter.statusIn && filter.statusIn.length === 1) {
        if (filter.statusIn[0] === UserStatus.Active) {
            active = true
            locked = false
        } else if (filter.statusIn[0] === UserStatus.Inactive) {
            active = false
            locked = false
        } else if (filter.statusIn[0] === UserStatus.Locked) {
            active = true
            locked = true
        }
    } else if (filter.statusIn && filter.statusIn.length === 2) {
        if (filter.statusIn.includes(UserStatus.Active) && filter.statusIn.includes(UserStatus.Inactive)) {
            locked = false
        } else if (filter.statusIn.includes(UserStatus.Active) && filter.statusIn.includes(UserStatus.Locked)) {
            active = true
        }
    }
    thunk.dispatch(applicationActions.addMask())
    return await userApi.list(axiosInstance, {
        nameContains: filter.nameContains,
        emailContains: filter.emailContains,
        phoneContains: filter.phoneContains,
        contactCodeContains: filter.contactCodeContains,
        localCodeContains: filter.localCodeContains,
        loginIdContains: filter.loginIdContains,
        active,
        locked,
        cbdsUidIn: currentCbdsUid ? [currentCbdsUid] : [],
        pageSize: 1000
    })
        .then(response => {
            thunk.dispatch(cas020Actions.setUsers(response.data.data!))
        })
        .catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        }).then(() => {
            thunk.dispatch(applicationActions.removeMask())
        })
})

const listCbdss = createAsyncThunk<void, void>('cas020/listCbdss', async (_, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    thunk.dispatch(applicationActions.addMask())
    return await cbdsApi.getCompany(axiosInstance)
        .then(response => {
            thunk.dispatch(cas020Actions.setCbdss(response.data))
        })
        .catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        }).then(() => {
            thunk.dispatch(applicationActions.removeMask())
        })
})

const changePassword = createAsyncThunk<void, { oldPassword: string, newPassword: string }>('cas020/changePassword', async ({ oldPassword, newPassword }, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    thunk.dispatch(applicationActions.addMask())
    return await authApi.changePassword(axiosInstance, { oldPassword, newPassword })
        .then(response => {
            thunk.dispatch(applicationActions.pushNotification(responseToNotification(response)))
        })
        .catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        })
        .then(() => {
            thunk.dispatch(applicationActions.removeMask())
        })
})

const resetPassword = createAsyncThunk<void, { username: string }>('cas020/resetPassword', async ({ username }, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    thunk.dispatch(applicationActions.addMask())
    return await authApi.randomPassword(axiosInstance, { username })
        .then(response => {
            thunk.dispatch(applicationActions.pushNotification(responseToNotification(response)))
        })
        .catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        }
        ).then(() => {
            thunk.dispatch(applicationActions.removeMask())
        })
})

export const cas020Slice = createSlice({
    name: 'cas020',
    initialState,
    reducers: {
        setUsers: (state, { payload: data }: PayloadAction<User[]>) => {
            state.users = data
        },
        setCbdss: (state, { payload: cbdss }: PayloadAction<Cbds[]>) => {
            state.cbdss = cbdss
        },
        setFilter: (state, { payload: filter }: PayloadAction<Filter>) => {
            state.filter = filter
        },
    }
})

export const cas020Actions = {
    ...cas020Slice.actions,
    listAllUsers,
    listUsers,
    listCbdss,
    changePassword,
    resetPassword
}

function selectCAS020State(state: any): CAS020State {
    return state[appConfig.appFullName][cas020Slice.name]
}

export function useCAS020Selector<R>(selector: (state: CAS020State) => R, equalityFn?: (left: R, right: R) => boolean) {
    return useSelector<any, R>(state => selector(selectCAS020State(state)), equalityFn)
}