import { useCallback, useMemo } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import { Access } from "../../../components/Access/Access"
import { ViewPanel } from "../../../components/View/Tab/ViewPanel"
import { ViewPanels } from "../../../components/View/Tab/ViewPanels"
import { ViewTab } from "../../../components/View/Tab/ViewTab"
import { ViewTabControl } from "../../../components/View/Tab/ViewTabControl"
import { ViewTabs } from "../../../components/View/Tab/ViewTabs"
import { View } from "../../../components/View/View"
import { useMatch } from "../../../utils/useMatch"
import { useApplicationSelector } from "../../Application/applicationSlice"
import { DAS010 } from "../DAS010/DAS010"
import { DAS012 } from "../DAS012/DAS012"
import { DAS013 } from "../DAS013/DAS013"
import { DAS014 } from "../DAS014/DAS014"
import { DAS015 } from "../DAS015/DAS015"
import { TabId, ViewTabList } from "./Records"

export const DAS000 = () => {

    const { path } = useMatch()
    const intl = useIntl()
    const navigate = useNavigate()
    const currentTabId = useMemo(() => prepareTabIdByPath(path), [path])
    const selectedChange = useCallback((value: string | number) => {
        navigate(prepareUrlbyTabId(value))
    }, [navigate])
    const resources = useApplicationSelector(state => state.auth.accessResources)
    const activeViewTabList = ViewTabList.filter(f => resources?.includes(f.access))

    if (currentTabId === TabId.DAS000) {
        navigate(activeViewTabList[0].navigation)
        return <></>
    } else {
        return <View noHeader>
            <ViewTabControl>
                <ViewTabs selectedValue={currentTabId} onSelectedValueChange={selectedChange}>
                    {activeViewTabList.map(activeViewTab => {
                        return <ViewTab key={activeViewTab.tabId} label={intl.formatMessage({ id: activeViewTab.label })} value={activeViewTab.tabId} />
                    })}
                </ViewTabs>
                <ViewPanels>
                    {currentTabId === TabId.DAS010
                        ? <ViewPanel value={TabId.DAS010}>
                            <Access access="DASH.DAS010.VIEW"><DAS010 /></Access>
                        </ViewPanel> : <></>
                    }
                    {currentTabId === TabId.DAS012
                        ? <ViewPanel value={TabId.DAS012}>
                            <Access access="DASH.DAS012.VIEW"><DAS012 /></Access>
                        </ViewPanel> : <></>
                    }
                    {currentTabId === TabId.DAS013
                        ? <ViewPanel value={TabId.DAS013}>
                            <Access access="DASH.DAS013.VIEW"><DAS013 /></Access>
                        </ViewPanel> : <></>
                    }
                    {currentTabId === TabId.DAS014
                        ? <ViewPanel value={TabId.DAS014}>
                            <Access access="DASH.DAS014.VIEW"><DAS014 /></Access>
                        </ViewPanel> : <></>
                    }
                    {currentTabId === TabId.DAS015
                        ? <ViewPanel value={TabId.DAS015}>
                            <Access access="DASH.DAS015.VIEW"><DAS015 /></Access>
                        </ViewPanel> : <></>
                    }
                </ViewPanels>
            </ViewTabControl>
        </View >
    }
}

const prepareUrlbyTabId = (tabId: any) => {
    switch (tabId) {
        case TabId.DAS010:
            return "/dashboard/pomanagement"
        case TabId.DAS012:
            return "/dashboard/costs"
        case TabId.DAS013:
            return "/dashboard/vendorAnalysis"
        case TabId.DAS014:
            return "/dashboard/inventory"
        case TabId.DAS015:
            return "/dashboard/acctReceivable"
        default:
            return "/dashboard/pomanagement"
    }
}
const prepareTabIdByPath = (path: any) => {
    switch (path) {
        case "/dashboard/pomanagement":
            return TabId.DAS010;
        case "/dashboard/costs":
            return TabId.DAS012;
        case "/dashboard/vendorAnalysis":
            return TabId.DAS013;
        case "/dashboard/inventory":
            return TabId.DAS014;
        case "/dashboard/acctReceivable":
            return TabId.DAS015;
        default:
            return TabId.DAS000;
    }
}