import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { dateFromJson } from "../../utils/deserializer"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"
import { ActiveFlag } from "../enums/ActiveFlag"
import { MilkrunFlag } from "../enums/MilkrunFlag"

export type SupplierDownloadFactor = Omit<SupplierFactor, 'page'>
type SupplierDownloadFactorJson = Omit<SupplierFactorJson, 'page'>
export const supplierDownloadFactorToJson: Project<SupplierDownloadFactor, SupplierDownloadFactorJson> = obj => ({
    ...obj,
    createdDateStart: dateToJson(obj.createdDateStart),
    createdDateEnd: dateToJson(obj.createdDateEnd),
    updatedDateStart: dateToJson(obj.updatedDateStart),
    updatedDateEnd: dateToJson(obj.updatedDateEnd),
})

export type SupplierFactor = Partial<Optional>
type SupplierFactorJson = Partial<JsonOptional>
export const supplierFactorToJson: Project<SupplierFactor, SupplierFactorJson> = obj => ({
    ...obj,
    createdDateStart: dateToJson(obj.createdDateStart),
    createdDateEnd: dateToJson(obj.createdDateEnd),
    updatedDateStart: dateToJson(obj.updatedDateStart),
    updatedDateEnd: dateToJson(obj.updatedDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(obj.page),
})

export const supplierFactorFromJson: Project<SupplierFactorJson, SupplierFactor> = json => ({
    ...json,
    createdDateStart: dateFromJson(json.createdDateStart),
    createdDateEnd: dateFromJson(json.createdDateEnd),
    updatedDateStart: dateFromJson(json.updatedDateStart),
    updatedDateEnd: dateFromJson(json.updatedDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(json.page),
})

interface Optional extends Omit<JsonOptional, 'createdDateStart' | 'createdDateEnd' | 'updatedDateStart' | 'updatedDateEnd' | 'page'> {
    activeFlagList: ActiveFlag[],
    milkrunFlagList: MilkrunFlag[],
    createdDateStart: Date,
    createdDateEnd: Date,
    updatedDateStart: Date,
    updatedDateEnd: Date,
    page: PageViewEntity,
}


interface JsonOptional {
    supplierCode: string,
    supplierName: string,
    supplierShortCode: string,
    companyIdList: number[],
    regionCode: string,
    postalCode: string,
    milkrunFlagList: number[],
    activeFlagList: number[],
    calendarCode: string,
    timeZone: string,
    createdBy: string,
    updatedBy: string,
    createdDateStart: string,
    createdDateEnd: string,
    updatedDateStart: string,
    updatedDateEnd: string,
    page: PageViewEntityJson,
}
