import { Typography } from "@material-ui/core"
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, ObjectTypeProvider, PaginationLayout, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { ObjectFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/ObjectFormatter"
import { DateItem, Form, StringItem } from "@rithe/form"
import { Records } from "@rithe/utils"
import { memo, useMemo } from "react"
import { useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CodeItem } from "../../../components/Form/CodeItem"
import { View } from "../../../components/View/View"
import { DiType } from "../../../services/delivery/enums/DiType"
import { CustDIHeaderInfo } from "../../../services/delivery/models/CustDIHeaderInfo"
import { CustDIParts } from "../../../services/delivery/models/CustDIParts"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { CustDIByModule } from "./LDS011"

interface LDS011PcUiProps {
    diId: number,
    diType: number | undefined,
    headerInfo: CustDIHeaderInfo,
    partsList: CustDIParts[],
    moduleList: CustDIByModule[],
}

export const LDS011PcUi = (props: LDS011PcUiProps) => {
    const intl = useIntl()
    const { headerInfo, diType, partsList, moduleList } = props
    return (
        <View>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={1}
                    title={intl.formatMessage({ id: 'DI Detail Information' })}
                    subtitle={intl.formatMessage({ id: 'Detail header information' })}
                />
                <SectionCardContent>
                    <DetailHeaderInfo headerInfo={headerInfo} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={2}
                    title={intl.formatMessage({ id: 'Parts Detail List Information' })}
                    subtitle={intl.formatMessage({ id: 'Detail list information' })}
                />
                <SectionCardContent>
                    {diType === DiType.BY_MODULE && <DetailInfoByModule moduleList={moduleList} />}
                    {diType === DiType.BY_PARTS && <DetailInfoByParts partsList={partsList} />}
                    {diType === DiType.BY_ROLL && <DetailInfoByRoll partsList={partsList} />}
                </SectionCardContent>
            </SectionCard>
        </View >
    )
}

const DetailHeaderInfo = memo((props: { headerInfo: CustDIHeaderInfo }) => {

    const intl = useIntl()
    const { headerInfo } = props

    return (
        <Form readonly={true} data={headerInfo} labelDisplay="block" helperDisplay="tooltip">
            <StringItem field="diNo" label={intl.formatMessage({ id: 'field.diNo' })} />
            <CodeItem field="status" label={intl.formatMessage({ id: 'field.status' })} code={CodeCategory.DiStatus} />
            <StringItem field="diRefNo" label={intl.formatMessage({ id: 'field.diRefNo' })} />
            <CodeItem field="diType" label={intl.formatMessage({ id: 'field.diType' })} code={CodeCategory.DiType} />
            <StringItem field="dcCode" label={intl.formatMessage({ id: 'field.dcCode' })} />
            <DateItem field="diDate" label={intl.formatMessage({ id: 'field.diDate' })} />
            <StringItem field="remark" label={intl.formatMessage({ id: 'field.remark' })} />
        </Form>
    )
})

const DetailInfoByParts = memo((props: { partsList: CustDIParts[] }) => {
    const { partsList } = props
    const intl = useIntl()
    const columns = useMemo(() => [
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 150 },
        { field: 'customerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'customerPartsNo' }), width: 250 },
        { field: 'oldPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.oldPartsNo' }), width: 200 },
        { field: 'colorCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'colorCode' }), width: 150 },
        { field: 'customerPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'customerPartsName' }), width: 300 },
        { field: 'pairedPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'pairedPartsNo' }), width: 200 },
        { field: 'uom', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.uomCode' }), width: 150 },
        { field: 'qty', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.qty' }), width: 100 },
        { field: 'partsStatus', dataTypeName: CodeCategory.DiStatus, title: intl.formatMessage({ id: 'field.status' }), width: 100 }
    ], [intl])

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.DiStatus} />
            <Data rows={partsList} columns={columns} />
            <ColumnFreeze />
            <ColumnVisibility ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
        </DataGrid>
    </div>
})

const DetailInfoByModule = memo((props: { moduleList: CustDIByModule[] }) => {
    const { moduleList } = props
    const intl = useIntl()
    const columns = useMemo(() => [
        { field: 'moduleNo', dataTypeName: 'add', title: intl.formatMessage({ id: 'no' }), width: 200 },
        { field: 'lengthPartNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 150 },
        { field: 'widthCustPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'customerPartsNo' }), width: 250 },
        { field: 'heightOldPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.oldPartsNo' }), width: 200 },
        { field: 'm3ColorCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'colorCode' }), width: 150 },
        { field: 'nwCustPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'customerPartsName' }), width: 300 },
        { field: 'gwPairedPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'pairedPartsNo' }), width: 200 },
        { field: 'uom', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.uomCode' }), width: 150 },
        { field: 'qty', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.qty' }), width: 100 },
        { field: 'status', dataTypeName: CodeCategory.DiStatus, title: intl.formatMessage({ id: 'field.status' }), width: 100 }
    ], [intl])

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <ExpandTypeProvider />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.DiStatus} />
            <Data rows={moduleList} columns={columns} />
            <ColumnFreeze />
            <ColumnVisibility ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
        </DataGrid>
    </div>
})

const DetailInfoByRoll = memo((props: { partsList: CustDIParts[] }) => {
    const { partsList } = props
    const intl = useIntl()
    const columns = useMemo(() => [
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 150 },
        { field: 'customerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'customerPartsNo' }), width: 250 },
        { field: 'sellerBuCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'sellerBuCode' }), width: 300 },
        { field: 'oldPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.oldPartsNo' }), width: 200 },
        { field: 'colorCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'colorCode' }), width: 150 },
        { field: 'customerPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'customerPartsName' }), width: 300 },
        { field: 'pairedPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'pairedPartsNo' }), width: 200 },
        { field: 'uom', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.uomCode' }), width: 150 },
        { field: 'moduleCount', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.moduleCount' }), width: 150 },
    ], [intl])

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.DiStatus} />
            <Data rows={partsList} columns={columns} />
            <ColumnFreeze />
            <ColumnVisibility ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
        </DataGrid>
    </div>
})

const ExpandFormatter = (props: ObjectFormatterProps) => {
    const dataType = props.row.dataType
    if (dataType === DiType.BY_MODULE) {
        return <><RemoveCircleOutlineIcon /><Typography style={{ paddingLeft: 5 }} variant="body1" align="left">{props.value}</Typography></>
    } else {
        return props.row.rowNo
    }
}

const ExpandTypeProvider = () => {
    return <ObjectTypeProvider name="add" Formatter={ExpandFormatter} />
}