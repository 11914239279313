import { Typography } from "@material-ui/core"
import { Column, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Editing, ObjectTypeProvider, PaginationLayout, Row, Searching, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { ObjectFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/ObjectFormatter"
import { Break, EntryItem, Form, Message, StringItem } from "@rithe/form"
import { Records } from "@rithe/utils"
import React, { SetStateAction, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useFunctionStore } from "../../../Root"
import { ConfirmCallbackViewAction } from "../../../components/Action/ConfirmCallbackViewAction"
import { DownloadCallbackViewAction } from "../../../components/Action/DownloadCallbackViewAction"
import { SaveCallbackViewAction } from "../../../components/Action/SaveCallbackViewAction"
import { UploadCallbackViewAction } from "../../../components/Action/UploadCallbackViewAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { View } from "../../../components/View/View"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { useConfirmTemplate, useSaveTemplateDetail } from "../../../services/integration/apis/externalDataApis"
import { useDownloadTemplateDefinitionFile } from "../../../services/integration/apis/externalDataDownloadApis"
import { useUploadTemplateDefinitionFile } from "../../../services/integration/apis/externalDataUploadApis"
import { TemplateStatus } from "../../../services/integration/enums/TemplateStatus"
import { ExternalDataResult, RequestDetailHead, RequestList } from "../../../services/integration/models/ExternalDataResult"
import { FunctionList } from "../../../services/integration/models/FunctionList"
import { useFieldChecker } from "../../../utils/ValidatorUtils"
import { applicationActions } from "../../Application/applicationSlice"

interface INT011PcUiProps {
    functionList: FunctionList[],
    headerInfo: RequestDetailHead
    setHeaderInfo: React.Dispatch<SetStateAction<RequestDetailHead>>,
    list: RequestList[]
    setList: React.Dispatch<SetStateAction<RequestList[]>>,
    mode: ScreenMode,
}

export const INT011PcUi = (props: INT011PcUiProps) => {
    const { functionList, mode, headerInfo, setHeaderInfo, list, setList } = props
    const intl = useIntl()
    const [messages, setMessages] = useState<Message[]>([])

    return <View actions={[
        <SaveAction headerInfo={headerInfo} list={list} />,
        <ConfirmAction headerInfo={headerInfo} list={list} />]}>
        <SectionCard allowCollapse key={1}>
            <SectionCardHeader
                title={intl.formatMessage({ id: 'createTemplate' })}
                subtitle={intl.formatMessage({ id: 'createTemplateSub' })} />
            <SectionCardContent>
                <ManageExternalData mode={mode} functionList={functionList} headerInfo={headerInfo} setHeaderInfo={setHeaderInfo} messages={messages} setMessages={setMessages} />
            </SectionCardContent>
        </SectionCard>
        <SectionCard key={2} >
            <SectionCardHeader
                title={intl.formatMessage({ id: "fileFormat" })}
                subtitle={intl.formatMessage({ id: "createTemplateUpload" })}
                actions={[headerInfo.function && <DownloadAction headerInfo={headerInfo} list={list} />, <UploadFormatAction headerInfo={headerInfo} setHeaderInfo={setHeaderInfo} list={list} setList={setList} />]} />
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const ManageExternalData = ({ mode, functionList, headerInfo, setHeaderInfo, messages, setMessages }: {
    functionList: FunctionList[],
    headerInfo: RequestDetailHead
    setHeaderInfo: React.Dispatch<SetStateAction<RequestDetailHead>>,
    mode: ScreenMode,
    messages: Message[],
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>
}) => {
    const intl = useIntl()
    const editReadOnly = mode === ScreenMode.EDIT
    const externalTemplateId = mode === ScreenMode.EDIT ? "existingExternalTemplateID" : "newExternalTemplateID"
    const functionMap = useMemo(() => functionList ? functionList.map(m => [m.code, m.name] as [string, string]) : [], [functionList])

    const fields = useMemo(() => getFormCheckFields(true), [])
    const filedCheck = useFieldChecker(fields, setMessages)

    return <Form data={headerInfo} setData={setHeaderInfo} labelDisplay="block" helperDisplay="tooltip" columnCount={12} messages={messages} setMessages={setMessages} ensure={filedCheck}>
        <EntryItem field="function" label={intl.formatMessage({ id: 'functionList' })} entries={functionMap} required readonly={editReadOnly} colSpan={4} />
        <StringItem field="identifier" label={intl.formatMessage({ id: externalTemplateId })} required readonly={editReadOnly} colSpan={4} />
        <Break />
        <StringItem field="description" label={intl.formatMessage({ id: 'externalTemplateDescription' })} colSpan={8} rowSpan={2} />
    </Form>
}

const DataTable = ({ list, setList }: INT011PcUiProps) => {
    const intl = useIntl()

    const columns = useMemo(() => [
        { field: 'displayTitle', dataTypeName: 'displayTitle', title: intl.formatMessage({ id: 'displayDataTitle' }), width: 300 },
        { field: 'description', dataTypeName: 'string', title: intl.formatMessage({ id: 'dataDescription' }), width: 300 },
        { field: 'matchedColumnName', dataTypeName: 'string', title: intl.formatMessage({ id: 'matchingDateofOwnFormat' }), width: 350 },

        { field: 'createdBy', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.createdBy' }), width: 200 },
        { field: 'createdDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.createdDate' }), width: 200 },
        { field: 'updatedBy', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.updatedBy' }), width: 200 },
        { field: 'updatedDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.updatedDate' }), width: 200 },
    ], [intl])

    const defaultEditDisabled = Records.from(columns.filter(({ field }) => field !== 'matchedColumnName').map(({ field }) => [field, { editingDisabled: true }]))
    const onEditingCellCommit = useCallback((_column: Column, row: Row) => {
        setList(data => data.map(item => item.description === row.description ? row as RequestList : item))
        return true
    }, [setList])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DisplayTitleProvider />
        <DataTypePreset />
        <Data rows={list} columns={columns} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['createdBy', 'createdDate', 'updatedBy', 'updatedDate']}
            ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Editing
            enableInlineEdit={true}
            onEditingCellCommit={onEditingCellCommit}
            columnSettings={defaultEditDisabled}
        />
        <Searching ignoreCase Input={SearchInput} />
    </DataGrid>
}

/** Formatter for Display Title  */
const DisplayTitleFormatter = ({ value, row }: ObjectFormatterProps) => {
    return <div style={{ display: 'flex' }}>
        <Typography variant="body2">{row.displayTitle}</Typography>
        {row.required && <Typography variant="body2" color="error">{'*'}</Typography>}
    </div >
}

const DisplayTitleProvider = () => {
    return <ObjectTypeProvider name="displayTitle" Formatter={DisplayTitleFormatter} />
}

const getFormCheckFields = (isIssue: boolean) => {
    return ({
        function: { labelId: 'functionList', required: true, length: { max: 50 } },
        identifier: { labelId: 'newExternalTemplateID', required: true, length: { max: 100 } },
        description: { labelId: 'externalTemplateDescription', length: { max: 255 } },
    })
}

const UploadFormatAction = ({ headerInfo, setHeaderInfo, setList }: {
    headerInfo: RequestDetailHead,
    setHeaderInfo: React.Dispatch<SetStateAction<RequestDetailHead>>,
    list: RequestList[]
    setList: React.Dispatch<SetStateAction<RequestList[]>>,
}) => {
    const uploadTemplate = useUploadTemplateDefinitionFile()
    const upload = useCallback((files: FileList | null) => {
        if (files === null) return
        uploadTemplate({ file: files[0], templateStatus: headerInfo.status ?? TemplateStatus.DRAFT }, { serialized: true }).then((result) => {
            if (result) {
                setHeaderInfo(result.header ? result.header : {})
                setList(result.list ? result.list : [])
            }
        })
    }, [headerInfo.status, setHeaderInfo, setList, uploadTemplate])
    return <UploadCallbackViewAction title={<FormattedMessage id="uploadyourformat" />} callback={upload} access="INT.INT011.UPLOAD" />
}

const DownloadAction = ({ headerInfo, list }: {
    headerInfo: RequestDetailHead,
    list: RequestList[]
}) => {
    const downloadTemplate = useDownloadTemplateDefinitionFile()
    const download = useCallback(() => {
        downloadTemplate({ header: headerInfo, list: list })
    }, [downloadTemplate, headerInfo, list])
    return <DownloadCallbackViewAction callback={download} access="INT.INT011.DOWNLOAD" />
}

const SaveAction = (({ headerInfo, list }: {
    headerInfo: RequestDetailHead
    list: RequestList[]
}) => {
    const [disabled, setDisabled] = useState<boolean>(false)
    const navigate = useNavigate()

    const SaveTemplateDetail = useSaveTemplateDetail()
    const onSave = useCallback(() => {
        setDisabled(true)
        const templateDetail: ExternalDataResult = {
            header: headerInfo,
            list: list
        }
        const functionCode = headerInfo.function
        SaveTemplateDetail(templateDetail, { serialized: true }).then(templateId => {
            navigate(`/manageExternalData`, { state: { functionCode, templateId } })
        }).finally(() => {
            setDisabled(false)
        })
    }, [SaveTemplateDetail, headerInfo, list, navigate])
    return <SaveCallbackViewAction callback={onSave} disabled={disabled} access="INT.INT011.SAVE" />
})

const ConfirmAction = (({ headerInfo, list }: {
    headerInfo: RequestDetailHead,
    list: RequestList[],
}) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'confirm' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)

    const ComfirmTemplate = useConfirmTemplate()
    const onclickSubmit = useCallback(() => {
        const templateDetail: ExternalDataResult = {
            header: headerInfo,
            list: list
        }
        const functionCode = headerInfo.function
        const functionId = functionStore.register(() => {
            setDisabled(true)
            ComfirmTemplate(templateDetail, { serialized: true }).then(templateId => {
                navigate(`/manageExternalData`, { state: { functionCode, templateId } })
            }).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))

    }, [ComfirmTemplate, dispatch, functionStore, headerInfo, list, navigate, title])
    return <ConfirmCallbackViewAction callback={onclickSubmit} disabled={disabled} access="INT.INT011.CONFIRM" />
})

