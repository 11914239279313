import { DialogTitle, IconButton, makeStyles, Typography } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import React from "react";
import { DashedDivider } from "../Divider/DashedDivider";

interface DialogHeaderProps {
    onClose?: () => void,
    children?: React.ReactNode;
}

export function DialogHeader(props: DialogHeaderProps) {
    const { onClose, children } = props

    const styles = useStyles()
    return <>
        <DialogTitle disableTypography className={styles.root}>
            <div>
                <Typography variant="h5">
                    {children}
                </Typography>
            </div>
            {onClose && <IconButton onClick={onClose}>
                <Clear />
            </IconButton>}
        </DialogTitle>
        <DashedDivider light className={styles.divider} />
    </>
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        textTransform: 'uppercase',
        position: 'relative',
    },
    divider: {
        marginLeft: 24,
        marginRight: 24,
    },
}))