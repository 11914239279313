import { Button, makeStyles } from "@material-ui/core";
import { useMixed } from "@rithe/utils";
import React, { ReactNode, useCallback, useRef } from "react";
import { FormattedMessage } from "react-intl";

interface UploadButtonProps {
    value?: File[] | null,
    onChange?: (fileList: File[] | null) => void,
    defaultValue?: File[] | null,
    multiple?: boolean,
    accept?: string,
    children?: ReactNode | ReactNode[]
}

export const UploadButton = (props: UploadButtonProps) => {
    const { children, accept, multiple } = props
    const [, setValue] = useMixed<File[] | null>(props.value, props.onChange, props.defaultValue)
    const ref = useRef<HTMLInputElement | null>(null)
    const onChange = useCallback(() => {
        ref.current && ref.current.files && setValue(Array.from(ref.current.files))
        ref.current && (ref.current.value = '')
    }, [setValue])
    const onSelectFile = useCallback(() => {
        ref.current && ref.current.click()
    }, [])

    const styles = useStyles()
    return <>
        <Button onClick={onSelectFile} style={{ backgroundColor: '#56ac9a' }} variant="contained">{children ? children : <FormattedMessage id="upload" />}</Button>
        <input ref={ref} type="file" multiple={multiple} accept={accept} onChange={onChange} className={styles.input} />
    </>
}

const useStyles = makeStyles(theme => ({
    input: {
        display: 'none',
    }
}))
