const appConfig = {
    baseFullName: process.env.REACT_APP_BASE_FULL_NAME!,
    appFullName: process.env.REACT_APP_APP_FULL_NAME!,
    routerBasePath: process.env.REACT_APP_ROUTER_BASE_PATH!,
    orgName: process.env.REACT_APP_ORG_NAME!,
    projectName: process.env.REACT_APP_PROJECT_NAME!,
    apiBaseURL: '/',
    requestTimeout: 60000,
}

export default appConfig