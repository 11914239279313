import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"

export type AlarmUsagePattemTransfer = Required & Partial<Optional>

type AlarmUsagePattemTransferJson = JsonRequired & Partial<JsonOptional>

export const alarmUsagePattemTransferFromJson: Project<AlarmUsagePattemTransferJson, AlarmUsagePattemTransfer> = json => ({
    ...json,
    outboundFluctuation: json.outboundFluctuation === undefined ? '0%' : json.outboundFluctuation * 100 + "%",
    buildOutMonth: dateFromJson(json.buildOutMonth),
    lastPOMonth: dateFromJson(json.lastPOMonth),
    lastDeliveryDate: dateFromJson(json.lastDeliveryDate),
})

interface Required extends JsonRequired { }

interface Optional extends Omit<JsonOptional, 'outboundFluctuation' | 'buildOutMonth' | 'lastPOMonth' | 'lastDeliveryDate'> {
    outboundFluctuation: string,
    buildOutMonth: Date,
    lastPOMonth: Date,
    lastDeliveryDate: Date

}

interface JsonRequired {
    customerPartsId: number,
    partsNo: string,
    customerCode: string,
}

interface JsonOptional {
    partsNo: string,
    customerCode: string,
    sellerCode: string,
    dataSource: number,
    externalRefNo: string,
    customerPartsNo: string,
    customerBackNo: string,
    grouping: string,
    commonPartsNo: string,
    specialPartsType: string,
    customerStockFlag: number,
    minDays1: number,
    minDays2: number,
    minDays3: number,
    maxDays: number,
    minBoxes1: number,
    minBoxes2: number,
    minBoxes3: number,
    maxBoxes: number,
    inventoryBoxFla: number,
    outboundFluctuation: number,
    onshippingDelayPat: number,
    deliveryCustPat: string,
    custDelayPat: number,
    custAdvancePat: number,
    orderGroupNo: string,
    safetyBox: number,
    safetyStockPercent: number,
    safetyStockPercent1: number,
    safetyStockPercent2: number,
    safetyStockPercent3: number,
    maxStockPercent: number,
    pairedPartsNo: string,
    calculationPattern: number,
    safetyStockUom: number,
    safetyStock: number,
    maxStock: number,

    buildOutMonth: string,
    lastPOMonth: string,
    lastDeliveryDate: string,

}