import React, { useCallback, useEffect, useState } from "react"
import { useGetOrderCreditCheck } from "../../../services/order/apis/SalesOrderApi"
import { CreditLimitResult } from "../../../services/order/models/CreditLimitResult"
import { CreditLimitPcUi } from "./CreditLimitPcUi"

interface CreditLimitProps {
    soId: number,
    creditCheckFlag: number,
}


export const CreditLimitView = (props: CreditLimitProps) => {
    const { soId, creditCheckFlag } = props

    const [data, setData] = useState<CreditLimitResult>({})
    const drOfCurrentOrderList = data.drOfCurrentOrderList ? data.drOfCurrentOrderList : []
    const openArList = data.openArList ? data.openArList : []
    const uninvoicedOrderList = data.uninvoicedOrderList ? data.uninvoicedOrderList : []
    const getData = useGetOrderCreditCheck()
    const search = useCallback((soId: number, creditCheckFlag: number) => {
        if (soId) {
            getData({ soId: soId, creditCheckFlag: creditCheckFlag }, { serialized: true, silent: true }).then(result => {
                if (result) {
                    setData(result)
                }
            })
        }

    }, [getData])
    useEffect(() => {
        search(soId, creditCheckFlag)
    }, [creditCheckFlag, search, soId])

    return <CreditLimitPcUi
        data={data}
        setData={setData}
        drOfCurrentOrderList={drOfCurrentOrderList}
        openArList={openArList}
        uninvoicedOrderList={uninvoicedOrderList}
    />
}