import { makeStyles } from "@material-ui/core";
import { DataGridPaginationProps } from "@rithe/data-grid/dist/components/basic/DataGridPagination";
import React from "react";

export const Pagination = (props: DataGridPaginationProps) => {
    const { children } = props
    const styles = useStyles()
    return <div className={styles.root}>{children}</div>
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'stretch',
        alignItems: 'center',
        padding: theme.spacing(1),
        paddingBottom: 0,
        '&>*': {
            marginLeft: theme.spacing(2)
        }
    }
}))