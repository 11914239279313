import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import appConfig from "../../../configs/appConfig"
import announcementApi from '../../../services/system/apis/announcementApi'
import { AnnouncementStatus } from "../../../services/system/enums/AnnouncementStatus"
import { AnnouncementType } from "../../../services/system/enums/AnnouncementType"
import errorToNotification from "../../../utils/axios/errorToNotification"
import responseToNotification from '../../../utils/axios/responseToNotification'
import thunkAxiosInstance from "../../../utils/axios/thunkAxiosInstance"
import { applicationActions } from '../../Application/applicationSlice'

interface Filter {
    typeIn?: AnnouncementType[],
    publishAtSince?: number,
    publishAtUntil?: number,
    expireAtSince?: number,
    expireAtUntil?: number,
}

interface Announcement {
    id: string,
    type: AnnouncementType,
    title: string,
    summary: string,
    content: string,
    publishAt: number | null,
    expireAt: number | null,
    status: AnnouncementStatus,
}

interface MLS260State {
    filter: Filter,
    announcements: Announcement[],
}

const initialState: MLS260State = {
    filter: {
        typeIn: undefined,
        publishAtSince: undefined,
        publishAtUntil: undefined,
        expireAtSince: undefined,
        expireAtUntil: undefined,
    },
    announcements: []
}

const listAnnouncements = createAsyncThunk('mls260/listAnnouncements', async (_, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const filter = selectMLS260State(thunk.getState()).filter
    thunk.dispatch(applicationActions.addMask())
    return announcementApi.list(axiosInstance, {
        typeIn: filter.typeIn,
        publishAtSince: filter.publishAtSince,
        publishAtUntil: filter.publishAtUntil,
        expireAtSince: filter.expireAtSince,
        expireAtUntil: filter.expireAtUntil,
        pageSize: 1000
    })
        .then(response => {
            thunk.dispatch(mls260Actions.setAnnouncements(response.data.data!))
        })
        .catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        })
        .then(() => {
            thunk.dispatch(applicationActions.removeMask())
        })
})

const deleteAnnouncement = createAsyncThunk<void, string>('mls260/deleteAnnouncement', async (id, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    thunk.dispatch(applicationActions.addMask())
    return announcementApi.delete(axiosInstance, id)
        .then(response => {
            thunk.dispatch(applicationActions.pushNotification(responseToNotification(response)))
            thunk.dispatch(mls260Actions.listAnnouncements())
        })
        .catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        }).then(() => {
            thunk.dispatch(applicationActions.removeMask())
        })
})

const deleteAnnouncements = createAsyncThunk<void, string[]>('mls260/deleteAnnouncements', async (ids, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    thunk.dispatch(applicationActions.addMask())
    return announcementApi.batchDelete(axiosInstance, ids)
        .then(response => {
            thunk.dispatch(applicationActions.pushNotification(responseToNotification(response)))
            thunk.dispatch(mls260Actions.listAnnouncements())
        })
        .catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        }).then(() => {
            thunk.dispatch(applicationActions.removeMask())
        })
})

export const mls260Slice = createSlice({
    name: 'mls260',
    initialState,
    reducers: {
        setAnnouncements: (state, action: PayloadAction<Announcement[]>) => {
            state.announcements = action.payload.reverse()
        },
        setFilter: (state, action: PayloadAction<Filter>) => {
            state.filter = action.payload
        },
    },
    extraReducers: {
    }
})

export const mls260Actions = {
    ...mls260Slice.actions,
    listAnnouncements,
    deleteAnnouncement,
    deleteAnnouncements,
}

function selectMLS260State(state: any): MLS260State {
    return state[appConfig.appFullName][mls260Slice.name]
}

export function useMLS260Selector<R>(selector: (state: MLS260State) => R, equalityFn?: (left: R, right: R) => boolean) {
    return useSelector<any, R>(state => selector(selectMLS260State(state)), equalityFn)
}