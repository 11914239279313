import { Project } from "@rithe/utils"
import { useCallback } from "react"
import { useNavigate } from "react-router-dom"

export const useGetSessionAndGobackIfEmpty = () => {
    const navigate = useNavigate()
    return useCallback(<T, R>(sessionKey: string, mapFormJson: Project<T, R>, goBackUrl: string) => {
        const sessionInfo = sessionStorage.getItem(sessionKey)
        if (sessionInfo) {
            const data = JSON.parse(sessionInfo)
            return data instanceof Array ? data.map(mapFormJson) : mapFormJson(data)
        } else {
            goBackUrl && navigate(goBackUrl)
            return null
        }
    }, [navigate])
}

export const useSetDataIntoSession = () => {
    return useCallback(<T, R>(sessionKey: string, mapToJson: Project<R, T>, data: R | R[]) => {
        data && sessionStorage.setItem(sessionKey, JSON.stringify(data instanceof Array ? data.map(mapToJson) : mapToJson(data)))
    }, [])
}
export const useSetDataIntoSessionAndGotoUrl = () => {
    const navigate = useNavigate()
    return useCallback(<T, R>(sessionKey: string, mapToJson: Project<R, T>, data: R | R[], url: string) => {
        data && sessionStorage.setItem(sessionKey, JSON.stringify(data instanceof Array ? data.map(mapToJson) : mapToJson(data)))
        navigate(url)
    }, [navigate])
}