import { Project } from "../../../utils/Project"
import { dateToJson } from "../../utils/serializer"


export type DownloadDiMonitorView = Required & Partial<Optional>

type DownloadDiMonitorViewJson = JsonRequired & Partial<JsonOptional>

export const downloadDiMonitorViewToJson: Project<DownloadDiMonitorView, DownloadDiMonitorViewJson> = obj => ({
    ...obj,
    diDateStartDt: dateToJson(obj.diDateStartDt),
    diDateEndDt: dateToJson(obj.diDateEndDt),
})

interface Required extends JsonRequired { }

interface Optional extends Omit<JsonOptional, 'diDateStartDt' | 'diDateEndDt'> {
    diDateStartDt: Date,
    diDateEndDt: Date,
}

interface JsonRequired {
}

interface JsonOptional {
    customerId: number,
    diRefNo: string,
    dcId: number,
    dcCode: string,
    diDateRngStart: string,
    diDateRngEnd: string,
    diDateStartDt: string,
    diDateEndDt: string,
    partsNo: string,
    dcIdList: number[],
    customerIdList: number[],
}