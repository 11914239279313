import { Project } from "@rithe/utils";
import { OrderCalculationPartsDetailEntity, orderCalculationPartsDetailEntityFromJson, OrderCalculationPartsDetailEntityJson, orderCalculationPartsDetailEntityToJson } from "./OrderCalculationDetailResult";

export type OrderCalculationDetailRequestBo = RequiredBo & Partial<OptionalBo>;
export type OrderCalculationDetailRequestBoJson = JsonRequiredBo & Partial<JsonOptionalBo>;

export const orderCalculationDetailRequestBoToJson: Project<OrderCalculationDetailRequestBo, OrderCalculationDetailRequestBoJson> = obj => ({
    ...obj,
    partsDetailList: obj.partsDetailList?.map(orderCalculationPartsDetailEntityToJson)
})

export const orderCalculationDetailRequestFromJson: Project<OrderCalculationDetailRequestBoJson, OrderCalculationDetailRequestBo> = json => ({
    ...json,
    partsDetailList: json.partsDetailList?.map(orderCalculationPartsDetailEntityFromJson)
})

interface RequiredBo extends JsonRequiredBo { }

interface OptionalBo extends Omit<JsonOptionalBo, 'partsDetailList'> {
    partsDetailList: OrderCalculationPartsDetailEntity[],
}

interface JsonRequiredBo {
}

interface JsonOptionalBo {
    orderCalcId: string,
    partsDetailList: OrderCalculationPartsDetailEntityJson[],
}
