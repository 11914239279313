import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"

export type TnmPackageType = Required & Partial<Optional> & TableEntity
type TnmPackageTypeJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const tnmPackageTypeFromJson: Project<TnmPackageTypeJson, TnmPackageType> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
}

interface JsonRequired {
    pkgTypeId: number,
}

interface JsonOptional {
    packageTypeCode: string,
    description: string,
    tareWeight: number,
    m3: number,
    length: number,
    width: number,
    height: number,
    maxM3: number,
    maxWeight: number,
}