import { Project } from "@rithe/utils"
import { Projects } from "../../../utils/Projects"
import { PageViewEntity, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"


export interface CargoTrackingFactor {
    bookingNo?: string,
    containerNo?: string,
    shippingMode?: number[],
    sellerUid?: string,
    loadingPortId?: string,
    dischargePortId?: string,
    etdStart?: Date,
    etdEnd?: Date,
    atdStart?: Date,
    atdEnd?: Date,
    etaStart?: Date,
    etaEnd?: Date,
    ataStart?: Date,
    ataEnd?: Date,
    cargoStatus?: string,
    updatedDateTimeStart?: Date,
    updatedDateTimeEnd?: Date,
    page?: PageViewEntity,
}

interface CargoTrackingFactorJson {
    bookingNo?: string,
    containerNo?: string,
    shippingMode?: number[],
    sellerUid?: string,
    loadingPortId?: string,
    dischargePortId?: string,
    etdStart?: string,
    etdEnd?: string,
    atdStart?: string,
    atdEnd?: string,
    etaStart?: string,
    etaEnd?: string,
    ataStart?: string,
    ataEnd?: string,
    cargoStatus?: string,
    updatedDateTimeStart?: string,
    updatedDateTimeEnd?: string,
    page?: PageViewEntityJson,
}

export const cargoTrackingFactorToJson: Project<CargoTrackingFactor, CargoTrackingFactorJson> = obj => ({
    ...obj,
    etdStart: dateToJson(obj.etdStart),
    etdEnd: dateToJson(obj.etdEnd),
    atdStart: dateToJson(obj.atdStart),
    atdEnd: dateToJson(obj.atdEnd),
    etaStart: dateToJson(obj.etaStart),
    etaEnd: dateToJson(obj.etaEnd),
    ataStart: dateToJson(obj.ataStart),
    ataEnd: dateToJson(obj.ataEnd),
    updatedDateTimeStart: dateToJson(obj.updatedDateTimeStart),
    updatedDateTimeEnd: dateToJson(obj.updatedDateTimeEnd),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
})