import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { Projects } from "../../../utils/Projects"
import { AvailableSellerResult, availableSellerResultFromJson } from "../models/AvailableSellerResult"
import { SpotOrderResult, spotOrderResultDeserializer, spotOrderResultSerializer } from "../models/SpotOrderDetailResult"

export const useGetAvailableSellers = () => {
    const url = '/lcbm-spot-order-api/order/co/api/getAvSellersByPartsNo'
    const payloadProject = useCallback((obj: { globalPartsIdList: number[], placeType: number | null | undefined, spotId: number | null | undefined }) => obj, [])
    const resultProject = useCallback(Projects.array(availableSellerResultFromJson), [])
    return usePost<{ globalPartsIdList: number[], placeType: number | null | undefined, spotId: number | null | undefined }, AvailableSellerResult[]>(url, payloadProject, resultProject)
}


export const useGetSpotOderDetail = () => {
    const url = '/lcbm-spot-order-api/order/co/api/getPlaceSpotAllInfo'
    const payloadProject = useCallback((obj: { spotId: string }) => obj, [])
    const resultProject = useCallback(spotOrderResultDeserializer, [])
    return usePost<{ spotId: string }, SpotOrderResult>(url, payloadProject, resultProject)
}

export const useSaveSpotOrder = () => {
    const url = '/lcbm-spot-order-api/order/co/api/step1Save'
    const payloadProject = useCallback(spotOrderResultSerializer, [])
    const resultProject = useCallback(spotOrderResultDeserializer, [])
    return usePost<SpotOrderResult, SpotOrderResult>(url, payloadProject, resultProject)
}
export const useSaveSpotOrderNext = () => {
    const url = '/lcbm-spot-order-api/order/co/api/step1Next'
    const payloadProject = useCallback(spotOrderResultSerializer, [])
    const resultProject = useCallback(spotOrderResultDeserializer, [])
    return usePost<SpotOrderResult, SpotOrderResult>(url, payloadProject, resultProject)
}
export const useSaveSpotOrderSubmit = () => {
    const url = '/lcbm-spot-order-api/order/co/api/step2Submit'
    const payloadProject = useCallback(spotOrderResultSerializer, [])
    const resultProject = useCallback(spotOrderResultDeserializer, [])
    return usePost<SpotOrderResult, SpotOrderResult>(url, payloadProject, resultProject)
}
