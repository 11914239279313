import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"

export type ImpInventoryList = Required & Partial<Optional> & TableEntity
type ImpInventoryListJson = JsonRequired & Partial<JsonOptional> & TableEntityJson

export const impInventoryFromJson: Project<ImpInventoryListJson, ImpInventoryList> = json => ({
    ...tableEntityFromJson(json),
    stockDate: dateFromJson(json.stockDate),
    createdDate: dateFromJson(json.createdDate),
    updatedDate: dateFromJson(json.updatedDate),


})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'stockDate' | 'createdDate' | 'updatedDate'> {
    stockDate: Date,
    createdDate: Date,
    updatedDate: Date,

}

interface JsonRequired {
}

interface JsonOptional {
    dailyStockId: number,
    ttcPartsNo: string,
    buCode: string,
    custCode: string,
    dataCustCode: string,
    custPartsNo: string,
    backNo: string,
    externalRefNo: string,
    custBackNo: string,

    stockDate: string,
    createdDate: string,
    updatedDate: string,

    balanceQty: number,
    stockQty: number,
    onshippingQty: number,
    stockDeltaQty: number,
    onholdDeltaQty: number,
    decimalDigits: number,

    dataSource: number,
    dataSourceName: string,
}