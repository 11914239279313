import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useComMasterGetActiveCbds, useComMasterGetActiveConttype, useComMasterGetAllPkgtype } from "../../../services/common/apis/commonMasterApi"
import { useGetBuyerList, useGetInnerPartsList, useGetOutboundInfoForEdit, useGetPackageList } from "../../../services/delivery/apis/deliveryOutboundApi"
import { OutboundDatasource } from "../../../services/delivery/enums/OutboundDatasource"
import { UploadOutboundType } from "../../../services/delivery/enums/UploadOutboundType"
import { InnerPartsResult } from "../../../services/delivery/models/InnerPartsResult"
import { ContainerInfo, OutboundCreateFactor } from "../../../services/delivery/models/OutboundCreateFactor"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { ShippingMode } from "../../../services/master/enums/ShippingMode"
import { TnmContainerType } from "../../../services/master/models/TnmContainerType"
import { TnmPackageType } from "../../../services/master/models/TnmPackageType"
import { Pair } from "../../../services/utils/Pair"
import { today } from "../../../utils/ApplicationUtils"
import { LOS034PcUi } from "./LOS034PcUi"

export const LOS034 = () => {

    const { outboundNo } = useParams() as any

    const [buyerList, setBuyerList] = useState<Pair[]>([])
    const [receiverList, setReceiverList] = useState<Pair[]>([])
    const [dbOuterPackageList, setDbOuterPackageList] = useState<Pair[]>([])
    const [outerPackageList, setOuterPackageList] = useState<Pair[]>([])
    const [dbInnerPartsList, setDbInnerPartsList] = useState<InnerPartsResult[]>([])
    const [innerPartsList, setInnerPartsList] = useState<InnerPartsResult[]>([])
    const [containerTypes, setContainerTypes] = useState<TnmContainerType[]>([])
    const [packageTypes, setPackageTypes] = useState<TnmPackageType[]>([])
    const [outboundInfo, setOutboundInfo] = useState<OutboundCreateFactor>(undefined as any)
    const [data, setData] = useState<OutboundCreateFactor>(defaultOutboundInfo)
    const [containers, setContainers] = useState<ContainerInfo[]>(defaultContainers)
    const [load, setLoad] = useState<number>(0)

    const getBuyerList = useGetBuyerList()
    const getReceiverList = useComMasterGetActiveCbds()
    const getPackageList = useGetPackageList()
    const getInnerPartsList = useGetInnerPartsList()
    const getContainerTypes = useComMasterGetActiveConttype()
    const getPackageTypes = useComMasterGetAllPkgtype()
    const getOutboundInfo = useGetOutboundInfoForEdit()
    const [hugeData, setHugeData] = useState<boolean>(true)

    const restInnerPartsList = useCallback((buyer: string) => {
        // find buyers
        const buyerInfo = buyerList.find(f => f.first === buyer);
        setInnerPartsList(dbInnerPartsList.filter(m => m.buyers.includes(buyerInfo?.second as string ?? '')))
    }, [buyerList, dbInnerPartsList])

    const resetNotyetDeliveryQty = useCallback((containers: ContainerInfo[]) => {
        const abc = containers.map(container => ({
            ...container,
            outerPackageList: (container.outerPackageList ?? []).map(outerPackage => ({
                palletNo: outerPackage.palletNo,
                outerPackageNo: outerPackage.outerPackageNo,
                outerPackageType: outerPackage.outerPackageType,
                productionDate: outerPackage.productionDate,
                m3: outerPackage.m3,
                netWeight: outerPackage.netWeight,
                grossWeight: outerPackage.grossWeight,
                outboundByPackage: outerPackage.outboundByPackage,
                innerPackageList: (outerPackage.innerPackageList ?? []).map(innerParts => ({
                    ...innerParts,
                    partsList: (innerParts.partsList ?? []).map(parts => ({
                        ...parts,
                        notYetDeliveryQty: dbInnerPartsList.find(f => f.soDetailId === parts.soDetailId)?.notYetDeliveryQty ?? 0,
                        rolledPartsFlag: dbInnerPartsList.find(f => f.soDetailId === parts.soDetailId)?.rolledPartsFlag ?? undefined,
                    }))
                }))
            }))
        }))
        return abc;
    }, [dbInnerPartsList])

    const restPackageList = useCallback((buyer: string) => {
        // find buyers
        const buyerInfo = buyerList.find(f => f.first === buyer);
        setOuterPackageList(dbOuterPackageList.filter(m => m.second === buyerInfo?.second))
    }, [buyerList, dbOuterPackageList])

    useEffect(() => {
        getBuyerList(undefined, { silent: true }).then((result) => {
            setBuyerList(result?.buyerList || [])
        })
        getReceiverList({ types: [CbdsType.DC, CbdsType.CUST] }, { silent: true }).then((result) => {
            setReceiverList(result?.map(m => ({ first: m.cbdsUid, second: m.cbdsCode })) || [])
        })
        getPackageList(undefined, { silent: true }).then((result) => {
            setDbOuterPackageList(result?.packageList || [])
            setLoad(load => load + 1)
        })
        getInnerPartsList({}, { silent: true, serialized: true }).then((result) => {
            setDbInnerPartsList(result || [])
            setLoad(load => load + 1)
        })
        getContainerTypes(undefined, { silent: true }).then((result) => {
            setContainerTypes(result || [])
        })
        getPackageTypes(undefined, { silent: true }).then((result) => {
            setPackageTypes(result || [])
        })
    }, [getBuyerList, getContainerTypes, getInnerPartsList, getPackageList, getPackageTypes, getReceiverList])

    useEffect(() => {
        if (outboundNo !== null && outboundNo !== undefined) {
            getOutboundInfo({ outboundNo: outboundNo }, { silent: true, serialized: true }).then((result) => {
                setOutboundInfo(result)
                setLoad(load => load + 1)
                result.containers?.forEach(container => {
                    setHugeData(container.outerPackageList != null && container.outerPackageList?.length > 20)
                })
            }) 
        } else {
            setHugeData(false)
        }
    }, [getOutboundInfo, outboundNo])

    useEffect(() => {
        if (load === 3 && outboundInfo) {
            setData({ ...outboundInfo, containers: undefined })
            setContainers(resetNotyetDeliveryQty(outboundInfo.containers ?? defaultContainers))
            // set parts and set net
            restInnerPartsList(outboundInfo.buyer)
            restPackageList(outboundInfo.buyer)
        }
    }, [load, outboundInfo, resetNotyetDeliveryQty, restInnerPartsList, restPackageList])

    return <LOS034PcUi
        data={data}
        setData={setData}
        containers={containers}
        setContainers={setContainers}
        hugeData={hugeData}
        setHugeData={setHugeData}
        buyerList={buyerList}
        receiverList={receiverList}
        outerPackages={outerPackageList}
        innerPartsList={innerPartsList}
        dbInnerPartsList={dbInnerPartsList}
        containerTypes={containerTypes}
        packageTypes={packageTypes}
        restInnerPartsList={restInnerPartsList}
        restPackageList={restPackageList}
        resetNotyetDeliveryQty={resetNotyetDeliveryQty}
    />
}

export const defaultOutboundInfo = {
    buyer: '',
    receiver: '',
    outboundDate: today(),
    outboundRefNo: '',
    outboundType: UploadOutboundType.OUTBOUND,
    shippingMode: ShippingMode.SEA,
    datasource: OutboundDatasource.INTERNAL,
}

export const defaultContainers = [{
    containerNo: '',
    outerPackageList: []
}]

