import { Card, makeStyles } from "@material-ui/core";
import clsx from 'clsx';
import React, { ReactNode, useLayoutEffect } from "react";
import { useViewContext } from "../View/ViewContext";
import { CollapseContextProvider, useCollapseContext } from "./CollapseContext";

interface BlackSimpleCardProps {
    allowCollapse?: boolean,
    defaultCollapse?: boolean,
    children?: ReactNode | ReactNode[],
}

export function BlackSimpleCard(props: BlackSimpleCardProps) {
    return <CollapseContextProvider>
        <BlackSimpleCardConsumer {...props} />
    </CollapseContextProvider>
}

function BlackSimpleCardConsumer(props: BlackSimpleCardProps) {
    const { allowCollapse, defaultCollapse, children } = props
    const { flex } = useViewContext()

    const { enable, disable, collapse, expand } = useCollapseContext()
    useLayoutEffect(() => {
        allowCollapse ? enable() : disable()
        defaultCollapse ? collapse() : expand()
    }, [allowCollapse, collapse, defaultCollapse, disable, enable, expand])

    const styles = useStyles()
    return <>
        <Card className={clsx(styles.root, flex && styles.flexRoot)}>
            {children}
        </Card>
        <div className={styles.gap} />
    </>
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        paddingBottom: theme.spacing(1, 3),
        background: theme.palette.background.card.linear,
        borderRadius: theme.spacing(1.5),
    },
    flexRoot: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'stretch',
    },
    gap: {
        width: '100%',
        height: theme.spacing(2.5),
    },
}))