import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { dateFromJson } from "../../utils/deserializer"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"
import { ActiveFlag } from "../enums/ActiveFlag"

export type ShippingRouteDownloadFactor = Omit<ShippingRouteFactor, 'page'>
type ShippingRouteDownloadFactorJson = Omit<ShippingRouteFactorJson, 'page'>
export const shippingRouteDownloadFactorToJson: Project<ShippingRouteDownloadFactor, ShippingRouteDownloadFactorJson> = obj => ({
    ...obj,
    effectiveStartDateStart: dateToJson(obj.effectiveStartDateStart),
    effectiveStartDateEnd: dateToJson(obj.effectiveStartDateEnd),
    effectiveEndDateStart: dateToJson(obj.effectiveEndDateStart),
    effectiveEndDateEnd: dateToJson(obj.effectiveEndDateEnd),
    createdDateStart: dateToJson(obj.createdDateStart),
    createdDateEnd: dateToJson(obj.createdDateEnd),
    updatedDateStart: dateToJson(obj.updatedDateStart),
    updatedDateEnd: dateToJson(obj.updatedDateEnd),
})

export type ShippingRouteFactor = Partial<Optional>
type ShippingRouteFactorJson = Partial<JsonOptional>
export const shippingRouteFactorToJson: Project<ShippingRouteFactor, ShippingRouteFactorJson> = obj => ({
    ...obj,
    effectiveStartDateStart: dateToJson(obj.effectiveStartDateStart),
    effectiveStartDateEnd: dateToJson(obj.effectiveStartDateEnd),
    effectiveEndDateStart: dateToJson(obj.effectiveEndDateStart),
    effectiveEndDateEnd: dateToJson(obj.effectiveEndDateEnd),
    createdDateStart: dateToJson(obj.createdDateStart),
    createdDateEnd: dateToJson(obj.createdDateEnd),
    updatedDateStart: dateToJson(obj.updatedDateStart),
    updatedDateEnd: dateToJson(obj.updatedDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
})


export const shippingRouteFactorFromJson: Project<ShippingRouteFactorJson, ShippingRouteFactor> = json => ({
    ...json,
    effectiveStartDateStart: dateFromJson(json.effectiveStartDateStart),
    effectiveStartDateEnd: dateFromJson(json.effectiveStartDateEnd),
    effectiveEndDateStart: dateFromJson(json.effectiveEndDateStart),
    effectiveEndDateEnd: dateFromJson(json.effectiveEndDateEnd),
    createdDateStart: dateFromJson(json.createdDateStart),
    createdDateEnd: dateFromJson(json.createdDateEnd),
    updatedDateStart: dateFromJson(json.updatedDateStart),
    updatedDateEnd: dateFromJson(json.updatedDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page),
})

interface Optional extends Omit<JsonOptional, 'effectiveStartDateStart' | 'effectiveStartDateEnd' | 'effectiveEndDateStart' |
    'effectiveEndDateEnd' | 'createdDateStart' | 'createdDateEnd' | 'updatedDateStart' | 'updatedDateEnd' | 'page'> {
    discontinueIndicatorList: ActiveFlag[],
    effectiveStartDateStart: Date,
    effectiveStartDateEnd: Date,
    effectiveEndDateStart: Date,
    effectiveEndDateEnd: Date,
    createdDateStart: Date,
    createdDateEnd: Date,
    updatedDateStart: Date,
    updatedDateEnd: Date,
    page: PageViewEntity,
}


interface JsonOptional {
    shippingRouteIdList: number[],
    shippingRouteCode: string,
    shippingModeList: number[],
    expCountryList: string[],
    expPortList: number[],
    impCountryList: string[],
    impPortList: number[],
    impCcLeadtime: number,
    expCcLeadtime: number,
    discontinueIndicatorList: number[],
    effectiveStartDateStart: string,
    effectiveStartDateEnd: string,
    effectiveEndDateStart: string,
    effectiveEndDateEnd: string,
    createdDateStart: string,
    createdDateEnd: string,
    createdBy: string,
    updatedDateStart: string,
    updatedDateEnd: string,
    updatedBy: string,
    page: PageViewEntityJson,
}
