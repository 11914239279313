import { useCallback, useMemo, useState } from "react"
import { useFindManualInboundParts, useGetInboundPage } from "../../../services/delivery/apis/deliveryInboundApi"
import { InboundMonitorTransfer } from "../../../services/delivery/models/InboundMonitorTransfer"
import { InboundMonitorView, inboundMonitorViewFromJson, inboundMonitorViewToJson } from "../../../services/delivery/models/InboundMonitorView"
import { useComMasterGetDCForRoots } from "../../../services/master/apis/dcApi"
import { TnmDistributionCenter } from "../../../services/master/models/TnmDistributionCenter"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { LIS010PcUi } from "./LIS010PcUi"
import { DownloadInboundFormResult } from "../../../services/delivery/models/DownloadInboundFormResult"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }
const searchCachekey = sessionKeys.Filters_LIS010
export const LIS010 = () => {
    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? inboundMonitorViewFromJson(JSON.parse(filters)) : null
    }, [])

    const [filters, setFilters] = useState<InboundMonitorView>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<InboundMonitorTransfer[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [dcList, setDCList] = useState<TnmDistributionCenter[]>([])
    const [manualInboundParts, setManualInboundParts] = useState<DownloadInboundFormResult[]>([])
    const getInboundList = useGetInboundPage()
    const getDownloadInboundParts = useFindManualInboundParts()
    const getDCListForRoots = useComMasterGetDCForRoots()
    const search = useCallback((filters: InboundMonitorView) => {
        const getList = getInboundList
        getList(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(inboundMonitorViewToJson(filters)))
        })
        getDCListForRoots(undefined, { silent: true }).then((result) => {
            setDCList([...result] || [])
        })
        getDownloadInboundParts(undefined, { silent: true }).then((result) => {
            setManualInboundParts([...result] || [])
        })
    }, [getDCListForRoots, getInboundList, getDownloadInboundParts])

    useSearchOnLoad(search, filters)

    return <LIS010PcUi
        search={search}
        data={data}
        totalCount={totalCount}
        filters={filters}
        setFilters={setFilters}
        dcList={dcList}
        manualInboundParts={manualInboundParts}
    />
}