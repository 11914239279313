import moment from "moment"
import { FormattedMessage } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { CallbackCardAction } from "../../../components/Action/CallbackCardAction"
import { useFunctionStore } from "../../../Root"
import { applicationActions } from "../../Application/applicationSlice"
import { occls013Actions, useOCCLS013Selector } from "./OCCLS013Slice"

export function CalculateAction() {
    const data = useOCCLS013Selector(state => ({
        orderGroupId: state.orderGroup?.orderGroupId!,
        orderTime: moment(state.orderTime, 'YYYYMM').toDate(),
        cutOffDate: state.cutoffDate,
        weeklyOrderForecastCount: 4,
        usageVersion: state.selections[0],
    }))

    const type = useOCCLS013Selector(state => state.type)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    function calculate() {
        if (type === 'PNA') {
            dispatch(occls013Actions.calculatePNA({
                data,
                callback: () => navigate('/orderCalculationPNA')
            }))
        } else if (type === 'Ehm') {
            dispatch(occls013Actions.calculateSMT({
                data,
                callback: () => navigate('/orderCalculationEhm')
            }))
        } else {
            dispatch(occls013Actions.calculate({
                data,
                callback: () => navigate('/orderCalculation')
            }))
        }
    }

    const hasOrderCalc = useOCCLS013Selector(state => Boolean(state.orderCalc))
    const hasSelection = useOCCLS013Selector(state => state.selections.length > 0)
    const functionStore = useFunctionStore()
    function callback() {
        if (hasSelection) {
            if (hasOrderCalc) {
                // has order calculation, has selection, warn
                const functionId = functionStore.register(() => {
                    calculate()
                })
                dispatch(applicationActions.pushWarning({
                    title: { code: 'calculate' },
                    messages: { code: 'affirmRunOrderCalculation2' },
                    actions: [{
                        label: 'CANCEL',
                    }, {
                        functionId,
                        label: 'CONFIRM',
                    }]
                }))
            } else {
                // no order calculation, has selection, calculate
                calculate()
            }
        } else {
            // no selection, warn
            const functionId = functionStore.register(() => {
                calculate()
            })
            dispatch(applicationActions.pushWarning({
                title: { code: 'calculate' },
                messages: { code: 'affirmRunOrderCalculation' },
                actions: [{
                    label: 'CANCEL',
                }, {
                    functionId,
                    label: 'CONFIRM',
                }]
            }))
        }
    }

    return <CallbackCardAction
        access="STCK.OCCLS013.CALCULATE"
        disabled={!data.cutOffDate}
        callback={callback}
        title={<FormattedMessage id="calculate" />}
    />
}