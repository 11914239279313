import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DateRangeItem, EntriesItem, StringItem } from "@rithe/form"
import { Objects, Records } from "@rithe/utils"
import React, { useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { View } from "../../../components/View/View"
import { useDownloadImpInv, useDownloadImpInvBydailyStockId } from "../../../services/smt/api/smtDownloadApi"
import { ImpInventoryList } from "../../../services/smt/models/ImpInventoryList"
import { ImpInventoryListFactor } from "../../../services/smt/models/ImpInventoryListFactor"
import { Pair } from "../../../services/utils/Pair"

interface SMGTS610PcUiProps {
    filters: ImpInventoryListFactor,
    setFilters: React.Dispatch<React.SetStateAction<ImpInventoryListFactor>>,
    data: ImpInventoryList[],
    totalCount: number,
    customerList: Pair[],
    search: (filters: ImpInventoryListFactor) => void,
}

export const SMGTS610PcUi = (props: SMGTS610PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({ filters, data, setFilters, search, customerList }: SMGTS610PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])

    const columns = useMemo(() => [
        { field: 'ttcPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.ttcPartsNo' }), width: 250 },
        { field: 'buCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.buCode' }), width: 200 },
        { field: 'custCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.buyerCode' }), width: 200 },
        { field: 'custPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.buyerPartsNo' }), width: 250 },
        { field: 'backNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.backNo' }), width: 180 },
        { field: 'externalRefNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.externalRefNo' }), width: 300 },
        { field: 'stockDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.stockDate' }), width: 150 },
        { field: 'stockQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'stockQty' }), width: 200 },
        { field: 'stockDeltaQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.stockDeltaQty' }), width: 200 },
        { field: 'onholdDeltaQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.onholdDeltaQty' }), width: 200 },
        { field: 'balanceQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'balanceQty' }), width: 200 },
        { field: 'onshippingQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'onShippingQty' }), width: 200 },
        // { field: 'decimalDigits', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.decimalDigits' }), width: 300 },
    ], [intl])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search, customerList }), [filters, setFilters, search, customerList])
    const actionProps1 = useMemo(() => ({ filters, selections }), [filters, selections])

    const getRowId = useCallback((row: any) => row.dailyStockId, [])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps1} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={['externalRefNo', 'decimalDigits']}
            columnSettings={{
                ttcPartsNo: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll selectByRowClick highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
    </DataGrid>
}

const FilterItem = (props: {
    customerList: Pair[],
    filters: ImpInventoryListFactor,
    setFilters: React.Dispatch<React.SetStateAction<ImpInventoryListFactor>>,
    search: (filters: ImpInventoryListFactor) => void
}) => {
    const { filters, setFilters, search, customerList } = props
    const customersMap = useMemo(() => customerList ? customerList.map(m => [m.second, m.second] as [string, string]) : [], [customerList])

    const stockDateGetValue = useCallback((filters: ImpInventoryListFactor) => {
        return [filters.stockDateStart0 ?? null, filters.stockDateEndNext ?? null]
    }, [])

    const stockDateMapValue = useCallback((filters: ImpInventoryListFactor, value: any) => {
        return { ...filters ?? {}, stockDateStart0: value[0], stockDateEndNext: value[1] }
    }, [])

    const clear = useCallback((filters: ImpInventoryListFactor) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: ImpInventoryListFactor) => {
        return [
            filters.custCodeList,
            (filters.stockDateStart0 || filters.stockDateEndNext) ? [filters.stockDateStart0, filters.stockDateEndNext] : [],
            filters.externalRefNo,
            filters.ttcPartsNo,
            filters.custPartsNo,
            filters.custBackNo,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <EntriesItem field="custCodeList" label={intl.formatMessage({ id: 'field.buyerCode' })} entries={customersMap} />
        <DateRangeItem field="stockDate" label={intl.formatMessage({ id: 'field.stockDate' })} getValue={stockDateGetValue} mapValue={stockDateMapValue} />
        <StringItem field="externalRefNo" label={intl.formatMessage({ id: 'field.externalRefNo' })} />
        <StringItem field="ttcPartsNo" label={intl.formatMessage({ id: 'field.ttcPartsNo' })} />
        <StringItem field="custPartsNo" label={intl.formatMessage({ id: 'field.buyerPartsNo' })} />
        <StringItem field="custBackNo" label={intl.formatMessage({ id: 'field.backNo' })} />
    </FilterToolbarItem>
}


const DownloadAction = ({ filters, selections }: { filters: ImpInventoryListFactor, selections: number[] }) => {
    const downloadApi = useDownloadImpInv()
    const downloadBySelectionApi = useDownloadImpInvBydailyStockId()

    const download = useCallback(() => {
        if (selections.length <= 0) {
            downloadApi(Objects.delete(filters, 'page'))
        } else {
            downloadBySelectionApi({ dailyStockIdList: selections })
        }
    }, [downloadApi, downloadBySelectionApi, filters, selections])
    return <DownloadGroupedToolbarAction access="STCK.SMGTS610.DOWNLOAD">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="download" />} callback={download} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}