import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson, tableEntityToJson } from "../../utils/TableEntity"
import { PartsDetailResult, partsDetailResultFromJson, PartsDetailResultJson, partsDetailResultToJson } from "./PartsDetailResult"
import { RequestHeaderResult, requestHeaderResultFromJson, RequestHeaderResultJson, requestHeaderResultToJson } from "./RequestHeaderResult"

export type MLS152CustomsRequest = Required & Partial<Optional> & TableEntity
type MLS152CustomsRequestJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const mls152CustomsRequestFromJson: Project<MLS152CustomsRequestJson, MLS152CustomsRequest> = json => ({
    ...tableEntityFromJson(json),
    requestHeader: json.requestHeader ? requestHeaderResultFromJson(json.requestHeader) : undefined,
    requestPartsList: json.requestPartsList ? json.requestPartsList.map((m) => partsDetailResultFromJson(m)) : [],
})

export const mls152CustomsRequestToJson: Project<MLS152CustomsRequest, MLS152CustomsRequestJson> = obj => ({
    ...tableEntityToJson(obj),
    requestHeader: obj.requestHeader ? requestHeaderResultToJson(obj.requestHeader) : undefined,
    requestPartsList: obj.requestPartsList ? obj.requestPartsList.map(partsDetailResultToJson) : [],
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'requestHeader' | 'requestPartsList'> {
    requestHeader: RequestHeaderResult,
    requestPartsList: PartsDetailResult[],
}

interface JsonRequired {

}

interface JsonOptional {
    requestHeader: RequestHeaderResultJson,
    requestPartsList: PartsDetailResultJson[],
    useConfirmFlag: number
}