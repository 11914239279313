import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { PageViewEntity, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"

export type DailyRundownListFactor = Required & Partial<Optional>
type DailyRundownListFactorJson = JsonRequired & Partial<JsonOptional>
export const dailyRundownListFactorToJson: Project<DailyRundownListFactor, DailyRundownListFactorJson> = obj => ({
    ...obj,
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
})

interface Required extends JsonRequired {

}

interface Optional extends JsonOptional {
    page: PageViewEntity,
}

interface JsonRequired {

}

interface JsonOptional {
    partsNo: string,
    partsName: string,
    customerCodes: string[],
    supplierCodes: string[],
    expCountries: string[],
    externalRefNo: string,
    buId: number,
    page: PageViewEntityJson
}

