import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useComMasterGetActiveCustomer } from "../../../services/common/apis/commonMasterApi"
import { TnmCustomer } from "../../../services/master/models/TnmCustomer"
import { useGetCoListByPage } from "../../../services/order/apis/CustomerOrderApi"
import { CustomerOrderListFactor, customerOrderListFactorFromJson, customerOrderListFactorToJson } from "../../../services/order/models/CustomerOrderListFactor"
import { OrderListResult } from "../../../services/order/models/OrderListResult"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { OCS010PcUi } from "./OCS010PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }
const searchCachekey = sessionKeys.Filters_OCS010

export const OCS010 = () => {
    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? customerOrderListFactorFromJson(JSON.parse(filters)) : null
    }, [])

    const [filters, setFilters] = useState<CustomerOrderListFactor>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<OrderListResult[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [customerList, setCustomerList] = useState<TnmCustomer[]>([])

    const getData = useGetCoListByPage()
    const search = useCallback((filters: CustomerOrderListFactor) => {
        getData(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(customerOrderListFactorToJson(filters)))
        })
    }, [getData, setData])

    useSearchOnLoad(search, filters)

    const getCustomer = useComMasterGetActiveCustomer()
    useEffect(() => {
        getCustomer(undefined, { silent: true }).then(result => {
            setCustomerList(result || [])
        })
    }, [getCustomer])

    return <OCS010PcUi
        filters={filters}
        setFilters={setFilters}
        search={search}
        data={data}
        totalCount={totalCount}
        customerList={customerList}
    />
}