import { Add } from "@material-ui/icons"
import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, RowActionProvider, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { DateRangeItem, EntriesItem, StringItem } from "@rithe/form"
import { arrx, Records } from "@rithe/utils"
import React, { SetStateAction, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { EditRedirectRowAction } from "../../../components/DataGrid/rowActions/EditRedirectRowAction"
import { SubmitCallbackRowAction } from "../../../components/DataGrid/rowActions/SubmitCallbackRowAction"
import { ViewRedirectRowAction } from "../../../components/DataGrid/rowActions/ViewRedirectRowAction"
import { WithdrawCallbackRowAction } from "../../../components/DataGrid/rowActions/WithdrawCallbackRowAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { GroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/GroupedToolbarAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { useFunctionStore } from "../../../Root"
import { useCancelRequest, useSubmitRequest } from "../../../services/master/apis/requestApi"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { ContractRequestType } from "../../../services/master/enums/ContaractRequestType"
import { ContractRequestStatus } from "../../../services/master/enums/ContractRequestStatus"
import { RequestFactor } from "../../../services/master/models/RequestFactor"
import { RequestResult } from "../../../services/master/models/RequestResult"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { useGetCompanyType } from "../../../utils/ApplicationUtils"
import { applicationActions } from "../../Application/applicationSlice"


interface MOS020PcUiProps {
    path: string,
    filters: RequestFactor,
    search: (filters: RequestFactor) => void,
    data: RequestResult[],
    totalCount: number,
    requestToList: TnvCbds[],
    setFilters: React.Dispatch<SetStateAction<RequestFactor>>
}

export const MOS020PcUi = (props: MOS020PcUiProps) => {
    const { path } = props

    if (path === "/masterOverView/request") {
        return <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    } else {
        return <View flex>
            <SectionCard>
                <SectionCardContent>
                    <DataTable {...props} />
                </SectionCardContent>
            </SectionCard>
        </View>
    }
}

const DataTable = ({ filters, data, setFilters, requestToList, search, path }: MOS020PcUiProps) => {
    const intl = useIntl()

    const columns = useMemo(() => arrx(
        { field: 'requestNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.requestNo' }), width: 230 },
        { field: 'description', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.description' }), width: 180 },
        { field: 'requestType', dataTypeName: CodeCategory.ContractRequestType, title: intl.formatMessage({ id: 'field.requestType' }), width: 180 },
        { field: 'requestTo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.requestTo' }), width: 180 },
        { field: 'requestBy', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.requestBy' }), width: 180 },
        { field: 'status', dataTypeName: CodeCategory.ContractRequestStatus, title: intl.formatMessage({ id: 'field.status' }), width: 110 },
        { field: 'requestDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.requestDate' }), width: 180 },
        { field: 'submittedBy', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.submittedBy' }), width: 180 },
        { field: 'submittedDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.submittedDate' }), width: 180 },
        { field: 'createdDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.createdDate' }), width: 180 },
        { field: 'updatedDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.updatedDate' }), width: 180 },
    ), [intl])
    const getRowId = useCallback((row: any) => row.requestId, [])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search, requestToList }), [filters, setFilters, search, requestToList])
    const actionProps1 = useMemo(() => ({ search, filters }), [filters, search])
    const ViewOrEditAction = useMemo(() => ({ path }), [path])
    const companyType = useGetCompanyType()

    const display2 = useCallback((tableRow: TableRow) => (tableRow.row?.status === ContractRequestStatus.CREATED || tableRow.row?.status === ContractRequestStatus.REJECTED), [])
    const display3 = useCallback((tableRow: TableRow) => (tableRow.row?.status === ContractRequestStatus.CREATED || tableRow.row?.status === ContractRequestStatus.REJECTED) && (tableRow.row?.requestType !== ContractRequestType.MODIFY_PACKING), [])
    const display5 = useCallback((tableRow: TableRow) => (companyType === CbdsType.CUST || (companyType === CbdsType.BU && tableRow.row?.requestType !== ContractRequestType.ADD)) && (tableRow.row?.status === ContractRequestStatus.CREATED || tableRow.row?.status === ContractRequestStatus.REJECTED), [companyType])

    return <>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.ContractRequestType} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.ContractRequestStatus} />
            <Data rows={data} columns={columns} getRowId={getRowId} />
            <ToolbarActionProvider Action={CreateAction} display={() => path !== "/masterOverView/request"} actionProps={ViewOrEditAction} />
            <RowActionProvider name="view" Action={ViewRowAction} actionProps={ViewOrEditAction} />
            <RowActionProvider name="edit" Action={EditRowAction} actionProps={ViewOrEditAction} display={display2} />
            <RowActionProvider name="submit" Action={SubmitRowAction} actionProps={actionProps1} display={display3} />
            <RowActionProvider name="withdraw" Action={WithdrawRowAction} actionProps={actionProps1} display={display5} />
            <ColumnFreeze />
            <ColumnVisibility
                defaultHiddenFields={['submittedBy', 'createdDate', 'updatedDate']}
                columnSettings={{
                    requestNo: { disableUserControl: true },
                }} ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
            <Sorting />
            <Filtering />
            <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
            <Action width={200} />
        </DataGrid>
    </>
}


const FilterItem = (props: {
    search: (filters: RequestFactor) => void,
    filters: RequestFactor,
    setFilters: React.Dispatch<SetStateAction<RequestFactor>>,
    requestToList: TnvCbds[]
}) => {
    const { filters, requestToList, setFilters, search } = props
    const requestToEntries: [string, string][] = useMemo(() => requestToList.map(m => [m.cbdsUid, m.cbdsCode]), [requestToList])

    const createdDateGetValue = useCallback((filters: RequestFactor) => {
        return [filters.requestDateStart ?? null, filters.requestDateEnd ?? null]
    }, [])

    const createdDateMapValue = useCallback((filters: RequestFactor, value: any) => {
        return { ...filters ?? {}, requestDateStart: value[0], requestDateEnd: value[1] }
    }, [])

    const updatedDateGetValue = useCallback((filters: RequestFactor) => {
        return [filters.submittedDateStart ?? null, filters.submittedDateEnd ?? null]
    }, [])

    const updatedDateMapValue = useCallback((filters: RequestFactor, value: any) => {
        return { ...filters ?? {}, submittedDateStart: value[0], submittedDateEnd: value[1] }
    }, [])

    const clear = useCallback((filters: RequestFactor) => {
        return { page: filters.page }
    }, [])
    const intl = useIntl()

    const filterCounter = useCallback((filters: RequestFactor) => {
        return [
            filters.requestNo,
            filters.description,
            filters.requestTypeList,
            filters.requestFromList,
            filters.requestToList,
            filters.statusList,
            filters.requestBy,
            filters.submittedBy,
            filters.requestDateStart || filters.requestDateEnd,
            filters.submittedDateStart || filters.submittedDateEnd,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])


    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="requestNo" label={intl.formatMessage({ id: 'field.requestNo' })} />
        <StringItem field="description" label={intl.formatMessage({ id: 'field.description' })} />
        <CodesItem field="requestTypeList" label={intl.formatMessage({ id: 'field.requestType' })} code={CodeCategory.ContractRequestType} />
        <EntriesItem field="requestToList" label={intl.formatMessage({ id: 'field.requestTo' })} entries={requestToEntries} />
        <CodesItem field="statusList" label={intl.formatMessage({ id: 'field.status' })} code={CodeCategory.ContractRequestStatus} />
        <StringItem field="requestBy" label={intl.formatMessage({ id: 'field.requestBy' })} />
        <StringItem field="submittedBy" label={intl.formatMessage({ id: 'field.submittedBy' })} />
        <DateRangeItem field="requestDate" label={intl.formatMessage({ id: 'field.requestDate' })} getValue={createdDateGetValue} mapValue={createdDateMapValue} />
        <DateRangeItem field="submittedDate" label={intl.formatMessage({ id: 'field.submittedDate' })} getValue={updatedDateGetValue} mapValue={updatedDateMapValue} />
    </FilterToolbarItem>
}

const CreateAction = ({ path }: { path: string }) => {
    const navigate = useNavigate()
    const toCreatePage = useCallback(() => { navigate(`/requestTo/addNewPart`) }, [navigate])
    const toModifyPackingPage = useCallback(() => { navigate(`/requestTo/editPacking`) }, [navigate])
    const toRemovePage = useCallback(() => { navigate(`/requestTo/remove`) }, [navigate])
    const toModifyPricePage = useCallback(() => { navigate(`/requestTo/editPrice`) }, [navigate])
    const companyType = useGetCompanyType()
    return <GroupedToolbarAction icon={<Add />} title={<FormattedMessage id="createNewRequest" />}>
        {onClose => <>
            {(companyType === CbdsType.CUST) ? <GroupedCallbackItem access="MARS.MOS020.CREATEADD" label={<FormattedMessage id="toCreatePage" />} callback={toCreatePage} onClose={onClose} /> : undefined}
            {(companyType === CbdsType.CUST || companyType === CbdsType.BU) ? <GroupedCallbackItem access="MARS.MOS020.CREATEMODIFY" label={<FormattedMessage id="toModifyPackingPage" />} callback={toModifyPackingPage} onClose={onClose} /> : undefined}
            {(companyType === CbdsType.SUPP || companyType === CbdsType.BU) ? <GroupedCallbackItem access="MARS.MOS020.CREATEMODIFY" label={<FormattedMessage id="toModifyPricePage" />} callback={toModifyPricePage} onClose={onClose} /> : undefined}
            {(companyType === CbdsType.CUST) ? <GroupedCallbackItem access="MARS.MOS020.CREATEREMOVE" label={<FormattedMessage id="toRemovePage" />} callback={toRemovePage} onClose={onClose} /> : undefined}
        </>
        }
    </GroupedToolbarAction>
}

const ViewRowAction = ({ tableRow, path }: DataGridRowActionProps & { path: string }) => {
    const toPath = useCallback((tableRow: TableRow) => `${path}/view-${tableRow.row?.requestNo}`, [path])
    return <ViewRedirectRowAction tableRow={tableRow} access="MARS.MOS020.VIEWDETAIL" path={toPath} />
}

const EditRowAction = ({ tableRow, path }: DataGridRowActionProps & { path: string }) => {
    const toPath = useCallback((tableRow: TableRow) => `${path}/edit-${tableRow.row?.requestNo}`, [path])
    return <EditRedirectRowAction tableRow={tableRow} access="MARS.MOS020.EDIT" path={toPath} />
}

const SubmitRowAction = ({ tableRow, search, filters }: DataGridRowActionProps & { search: (filters: RequestFactor) => void, filters: RequestFactor }) => {
    const submitApi = useSubmitRequest()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'submit' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const callback = useCallback((tableRow: TableRow) => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            submitApi({ requestIdList: [tableRow.row?.requestId] }, { serialized: true }).then(() => search(filters)).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [dispatch, filters, functionStore, search, submitApi, title])
    return <SubmitCallbackRowAction tableRow={tableRow} access="MARS.MOS020.SUBMIT" callback={callback} disabled={disabled} />
}

const WithdrawRowAction = ({ tableRow, search, filters }: DataGridRowActionProps & { search: (filters: RequestFactor) => void, filters: RequestFactor }) => {
    const withdrawApi = useCancelRequest()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'withdraw' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const callback = useCallback((tableRow: TableRow) => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            withdrawApi({ requestIdList: [tableRow.row?.requestId] }, { serialized: true }).then(() => search(filters)).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [dispatch, filters, functionStore, search, title, withdrawApi])
    return <WithdrawCallbackRowAction tableRow={tableRow} access="MARS.MOS020.WITHDRAW" callback={callback} disabled={disabled} />
}