import { makeStyles } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { DataGridPagingPageSelectProps } from '@rithe/data-grid/dist/components/paging/DataGridPagingPageSelect';
import React, { useEffect } from "react";

export const PageSelect = (props: DataGridPagingPageSelectProps) => {
    const { currentPage, pageCount, setCurrentPage } = props

    useEffect(() => {
        setCurrentPage(Math.max(0, Math.min(pageCount - 1, currentPage)))
    }, [currentPage, pageCount, setCurrentPage])

    const styles = useStyles()
    return <Pagination
        shape="round"
        page={currentPage + 1}
        count={pageCount}
        onChange={(_, page) => setCurrentPage(page - 1)}
        className={styles.root}
    />
}

const useStyles = makeStyles(theme => ({
    root: {
        flex: '1 1 auto',
        display: 'flex',
        justifyContent: 'flex-end',
    },
}))