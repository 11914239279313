import {useCallback} from "react"
import {usePost} from "../../../utils/fetch/useFetch"
import {PageResultEntity, pageResultEntityFromJson} from "../../utils/PageResultEntity"
import {AlarmUsagePattemTransfer, alarmUsagePattemTransferFromJson} from "../models/AlarmUsagePattemTransfer"
import {AlarmUsagePatternDetail, alarmUsagePattemDetailFromJson, alarmUsagePatternDetailToJson} from "../models/AlarmUsagePatternDetail"
import {AlarmUsagePatternView, alarmUsagePatternViewToJson} from "../models/AlarmUsagePatternView"
import {OrderCalculationUpdateResult, orderCalculationUpdateResultFromJson} from "../models/OrderCalculationUpdateResult"

export const useGetAlarmUsagePatternPage = () => {
    const url = '/smt-api/ordermng/getOrderCalculationSettingList'
    const payloadProject = useCallback(alarmUsagePatternViewToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(alarmUsagePattemTransferFromJson), [])
    return usePost<AlarmUsagePatternView, PageResultEntity<AlarmUsagePattemTransfer>>(url, payloadProject, resultProject)
}

export const useGetAlarmUsagePatternDetail = () => {
    const url = '/smt-api/ordermng/getOrderCalculationSettingDetail'
    const payloadProject = useCallback((obj: { customerPartsId: number }) => obj, [])
    const resultProject = useCallback(alarmUsagePattemDetailFromJson, [])
    return usePost<{ customerPartsId: number }, AlarmUsagePatternDetail>(url, payloadProject, resultProject)
}

export const useUpdateAlarmUsagePatternDetail = () => {
    const url = '/smt-api/ordermng/saveOrderCalculationSetting'
    const payloadProject = useCallback(alarmUsagePatternDetailToJson, [])
     const resultProject = useCallback(orderCalculationUpdateResultFromJson, [])
    return usePost<AlarmUsagePatternDetail, OrderCalculationUpdateResult>(url, payloadProject, resultProject)
}
