import { useCallback, useEffect, useMemo, useState } from "react"
import { useGetContractList, useGetContractPeopleInfo } from "../../../services/master/apis/contractApi"
import { ContractFactor, ContractFactorFromJson, contractFactorToJson } from "../../../services/master/models/ContractFactor"
import { ContractPeopleInfoResult } from "../../../services/master/models/ContractPeopleInfoResult"
import { ContractResult } from "../../../services/master/models/ContractResult"
import sessionKeys from "../../../utils/sessionKeys"
import { useMatch } from "../../../utils/useMatch"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { MLS090PcUi } from "./MLS090PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }

const searchCachekey = sessionKeys.Filters_MLS090

export const MLS090 = () => {
    const { path } = useMatch()

    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? ContractFactorFromJson(JSON.parse(filters)) : null
    }, [])

    const [filters, setFilters] = useState<ContractFactor>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<ContractResult[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [partners, setPartners] = useState<ContractPeopleInfoResult>({})

    const getDataList = useGetContractList()
    const search = useCallback((filters: ContractFactor) => {
        getDataList(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(contractFactorToJson(filters)))
        })
    }, [getDataList, setData])

    useSearchOnLoad(search, filters)

    const getPartners = useGetContractPeopleInfo()
    useEffect(() => {
        getPartners({ isFromTools: false }, { silent: true, serialized: true }).then(result => {
            setPartners(result || {})
        })
    }, [getPartners, setPartners])

    return <MLS090PcUi
        path={path}
        filters={filters}
        search={search}
        data={data}
        totalCount={totalCount}
        partners={partners}
        setFilters={setFilters}
    />
}