import { Route, Routes } from "react-router-dom"
import { AuthNavigate } from "../components/AuthNavigate/AuthNavigate"
import { MTS020 } from "../layouts/mt/MTS020/MTS020"
import { MTS030 } from "../layouts/mt/MTS030/MTS030"
import { MTS040 } from "../layouts/mt/MTS040/MTS040"
import { MTS050 } from "../layouts/mt/MTS050/MTS050"
import { NotFound } from "../layouts/NotFound/NotFound"

export function MtRoutes() {
    return <Routes>
        <Route path="/partsinventory" element={<AuthNavigate access="MT.MTS020.VIEW"><MTS020 /></AuthNavigate>} />,
        <Route path="/packageinventory" element={<AuthNavigate access="MT.MTS030.VIEW"><MTS030 /></AuthNavigate>} />,
        <Route path="/dowloadinbplan" element={<AuthNavigate access="MT.MTS040.VIEW"><MTS040 /></AuthNavigate>} />,
        <Route path="/dowloadOverallVisualisation" element={<AuthNavigate access="MT.MTS050.VIEW"><MTS050 /></AuthNavigate>} />,
        <Route path="*" element={<NotFound scope="mt"/>} />,
    </Routes>
}