import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { PageViewEntity, PageViewEntityJson, pageViewEntityFromJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateFromJson } from "../../utils/deserializer"
import { dateToJson } from "../../utils/serializer"

export type CargoTackingFactor = Required & Partial<Optional>

type CargoTackingFactorJson = JsonRequired & Partial<JsonOptional>

export const cargoTackingFactorFactorFromJson: Project<CargoTackingFactorJson, CargoTackingFactor> = json => ({
    ...json,
    ataStart: dateFromJson(json.ataStart),
    ataEnd: dateFromJson(json.ataEnd),
    etaStart: dateFromJson(json.etaStart),
    etaEnd: dateFromJson(json.etaEnd),
    scheduledEtaStart: dateFromJson(json.scheduledEtaStart),
    scheduledEtaEnd: dateFromJson(json.scheduledEtaEnd),
    initialScheduledEtaStart: dateFromJson(json.initialScheduledEtaStart),
    initialScheduledEtaEnd: dateFromJson(json.initialScheduledEtaEnd),
    dischargeDateStart: dateFromJson(json.dischargeDateStart),
    dischargeDateEnd: dateFromJson(json.dischargeDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page),
})

export const cargoTackingFactorFactorToJson: Project<CargoTackingFactor, CargoTackingFactorJson> = obj => ({
    ...obj,
    ataStart: dateToJson(obj.ataStart),
    ataEnd: dateToJson(obj.ataEnd),
    etaStart: dateToJson(obj.etaStart),
    etaEnd: dateToJson(obj.etaEnd),
    scheduledEtaStart: dateToJson(obj.scheduledEtaStart),
    scheduledEtaEnd: dateToJson(obj.scheduledEtaEnd),
    initialScheduledEtaStart: dateToJson(obj.initialScheduledEtaStart),
    initialScheduledEtaEnd: dateToJson(obj.initialScheduledEtaEnd),
    dischargeDateStart: dateToJson(obj.dischargeDateStart),
    dischargeDateEnd: dateToJson(obj.dischargeDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),

})

interface Required extends JsonRequired {}

interface Optional extends Omit<JsonOptional, 'ataStart' | 'ataEnd' | 'etaStart' | 'etaEnd' | 'scheduledEtaStart' | 'scheduledEtaEnd'
    | 'initialScheduledEtaStart' | 'initialScheduledEtaEnd' | 'dischargeDateStart' | 'dischargeDateEnd' | 'page'> {
    ataStart: Date,
    ataEnd: Date,
    etaStart: Date,
    etaEnd: Date,
    scheduledEtaStart: Date,
    scheduledEtaEnd: Date,
    initialScheduledEtaStart: Date,
    initialScheduledEtaEnd: Date,
    dischargeDateStart: Date,
    dischargeDateEnd: Date,
    page: PageViewEntity,
}

interface JsonRequired {
}

interface JsonOptional {
    containerNo: string,
    blNo: string,
    carrierNo: string,
    ataStart: string,
    ataEnd: string,
    etaStart: string,
    etaEnd: string,
    scheduledEtaStart: string,
    scheduledEtaEnd: string,
    initialScheduledEtaStart: string,
    initialScheduledEtaEnd: string,
    dischargeDateStart: string,
    dischargeDateEnd: string,
    pol: string,
    pot: string,
    pod: string,
    page: PageViewEntityJson,
}
