import { Button, DialogActions, DialogContent, makeStyles, Typography, useTheme } from "@material-ui/core"
import { Column, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Editing, Filtering, NumberTypeProvider, PaginationLayout, Paging, Row, Searching, Sorting, StringTypeProvider, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarLayout } from "@rithe/data-grid"
import { StringFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/StringFormatter"
import { Break, DateItem, Form, Message, NumberItem, StringItem } from "@rithe/form"
import { GridContainer, GridItem } from "@rithe/ui"
import { Arrays, Records } from "@rithe/utils"
import moment from "moment"
import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import { FormattedMessage, IntlShape, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Access } from "../../../components/Access/Access"
import { DownloadCallbackCardAction } from "../../../components/Action/DownloadCallbackCardAction"
import { IssueCallbackViewAction } from "../../../components/Action/IssueCallbackViewAction"
import { SaveCallbackViewAction } from "../../../components/Action/SaveCallbackViewAction"
import { UploadCallbackCardAction } from "../../../components/Action/UploadCallbackCardAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { CreateCallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/CreateCallbackToolbarAction"
import { PercentTypeProvider } from "../../../components/DataGrid/typeProviders/PercentTypeProvider"
import { DarkDialog } from "../../../components/Dialog/DarkDialog"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { StepperAction } from "../../../components/View/Step/StepperAction"
import { ViewPanel } from "../../../components/View/Tab/ViewPanel"
import { ViewPanels } from "../../../components/View/Tab/ViewPanels"
import { ViewTab } from "../../../components/View/Tab/ViewTab"
import { ViewTabControl } from "../../../components/View/Tab/ViewTabControl"
import { ViewTabs } from "../../../components/View/Tab/ViewTabs"
import { View } from "../../../components/View/View"
import { useFunctionStore } from "../../../Root"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { useGetSpotDeliveryDateForPNA, useSaveAndIssueSpotPlacedOrderListPNA, useSaveSpotPlacedOrderListPNA } from "../../../services/smt/api/OrderCalculationApi"
import { useDownloadOrderSummaryReportForPNA, useDownloadSpotOrderformListForCustomerPNA } from "../../../services/smt/api/smtDownloadApi"
import { useUploadSpotOrderFormListForCustomerPNA } from "../../../services/smt/api/smtUploadApi"
import { OCPlaceOrderDetailFactor } from "../../../services/smt/models/OCPlaceOrderDetailFactor"
import { OCPartsDetail } from "../../../services/smt/models/OCPlaceOrderDetailResult"
import { useGetCodeName } from "../../../utils/CodeCategoryUtil"
import { formatDateRange } from "../../../utils/formatDateRange"
import { useFieldChecker } from "../../../utils/ValidatorUtils"
import { applicationActions } from "../../Application/applicationSlice"
import { PlaceOrderBasic, useMergeDatas, useSplitDatas } from "./OCCLS017"
import { useOCCLS017ShippingPlanCategory } from "./OCCLS017ShippingPlanCategory"

export interface OCCLS017PNAPcUiProps {
    mode: ScreenMode,
    factor: OCPlaceOrderDetailFactor,
    basic: PlaceOrderBasic[],
    setBasic: React.Dispatch<React.SetStateAction<PlaceOrderBasic[]>>,
    forecasts: OCPartsDetail[],
    setForecasts: React.Dispatch<React.SetStateAction<OCPartsDetail[]>>,
    inboundPlans: OCPartsDetail[],
    setInboundPlans: React.Dispatch<React.SetStateAction<OCPartsDetail[]>>,
    targetFirstDate: Date,
    targetLastDate: Date,
    refresh: (poGroupId: string | null | undefined, silent: boolean) => void,
    poGroupId: string
}

export const OCCLS017PNAPcUi = (props: OCCLS017PNAPcUiProps) => {

    const { mode, factor, basic, setBasic, forecasts, setForecasts, inboundPlans, setInboundPlans, targetFirstDate, targetLastDate, refresh, poGroupId } = props

    const intl = useIntl()
    const actions = usePrepareActions(mode, basic, forecasts, inboundPlans, refresh, poGroupId)
    const editable = useMemo(() => mode === ScreenMode.EDIT ? true : false, [mode])

    return (
        <View actions={<StepperAction actions={actions} />}>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={1}
                    step
                    title={intl.formatMessage({ id: 'step1OfPlaceRegularOrder' })}
                    subtitle={intl.formatMessage({ id: 'step1OfPlaceRegularOrderSub' })}
                />
                <SectionCardContent>
                    <Step1DownloadUploadCard
                        factor={factor}
                        editable={editable}
                        basic={basic}
                        setBasic={setBasic}
                        forecasts={forecasts}
                        setForecasts={setForecasts}
                        inboundPlans={inboundPlans}
                        setInboundPlans={setInboundPlans}
                        poGroupId={poGroupId}
                    />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={2}
                    step
                    title={intl.formatMessage({ id: 'step2OfPlaceRegularOrder' })}
                    subtitle={intl.formatMessage({ id: 'basicInfoSub' })}
                />
                <SectionCardContent>
                    <Step2FirmAndFcTable targetFirstDate={targetFirstDate} basic={basic} setBasic={setBasic} editable={editable} targetLastDate={targetLastDate} forecasts={forecasts} setForecasts={setForecasts} setInboundPlans={setInboundPlans} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={3}
                    step
                    title={intl.formatMessage({ id: 'step3OfPlaceRegularOrder' })}
                    subtitle={intl.formatMessage({ id: 'basicInfoSub' })}
                />
                <SectionCardContent>
                    <Step3ShippingPlanTable basic={basic} forecasts={forecasts} setForecasts={setForecasts} inboundPlans={inboundPlans} setBasic={setBasic} editable={editable} setInboundPlans={setInboundPlans} />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={4}
                    step
                    title={intl.formatMessage({ id: 'step4OfPlaceRegularOrder' })}
                    subtitle={intl.formatMessage({ id: 'basicInfoSub' })}
                />
                <SectionCardContent>
                    <Step4BasicInfoPanelCard editable={editable} basic={basic} setBasic={setBasic} />
                </SectionCardContent>
            </SectionCard>
        </View >
    )
}

interface Step1Props {
    factor: OCPlaceOrderDetailFactor,
    editable: boolean,
    basic: PlaceOrderBasic[],
    setBasic: React.Dispatch<React.SetStateAction<PlaceOrderBasic[]>>,
    forecasts: OCPartsDetail[],
    setForecasts: React.Dispatch<React.SetStateAction<OCPartsDetail[]>>,
    inboundPlans: OCPartsDetail[],
    setInboundPlans: React.Dispatch<React.SetStateAction<OCPartsDetail[]>>,
    poGroupId: string
}
const Step1DownloadUploadCard = memo((props: Step1Props) => {
    const { editable } = props
    const theme = useTheme()
    return (
        <GridContainer columnWidth={[500, 200]} style={{ paddingLeft: theme.spacing(5) }} rowGap={theme.spacing(2)}>
            <GridItem style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='body1'><FormattedMessage id='downloadInStep1' /></Typography>
            </GridItem>
            <GridItem >
                {editable ? <DownloadAction {...props} /> : <></>}
            </GridItem>
            <GridItem style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='body1'><FormattedMessage id='uplaodInStep1' /></Typography>
            </GridItem>
            <GridItem >
                {editable ? <UploadAction {...props} /> : <></>}
            </GridItem>
            <GridItem style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='body1'><FormattedMessage id='downloadSummaryInStep1' /></Typography>
            </GridItem>
            <GridItem >
                {editable ? <DownloadOrderSummaryAction {...props} /> : <></>}
            </GridItem>
        </GridContainer>
    )
})

interface Step2Props {
    targetFirstDate: Date,
    targetLastDate: Date,
    forecasts: OCPartsDetail[],
    editable: boolean,
    setForecasts: React.Dispatch<React.SetStateAction<OCPartsDetail[]>>,
    setInboundPlans: React.Dispatch<React.SetStateAction<OCPartsDetail[]>>,
    setBasic: React.Dispatch<React.SetStateAction<PlaceOrderBasic[]>>,
    basic: PlaceOrderBasic[],
}
const Step2FirmAndFcTable = memo((props: Step2Props) => {
    const { forecasts, setForecasts, editable, setInboundPlans, setBasic } = props
    const intl = useIntl()
    const [order, setOrder] = useState<string[]>([])
    const getSuggestedAdditionalQty = useCallback((row: Row) => (row.suggestedAdditionalQty != null && row.suggestedAdditionalQty && row.suggestedAdditionalQty > 0) ? row.suggestedAdditionalQty : '-', [])

    const columns = useMemo(() => {
        const fixedColumns = [
            { field: 'contractNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contractNo' }), width: 250 },
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.orderCalcNo' }), width: 250 },
            { field: 'partsDescription', dataTypeName: 'string', title: intl.formatMessage({ id: 'partsDescription' }), width: 200 },
            { field: 'customerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerPartsNo' }), width: 200 },
            { field: 'supplierPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'supplierPartsNo' }), width: 200 },
            { field: 'exportCountry', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.expCountry' }), width: 200 },
            { field: 'supplierCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierCode' }), width: 180 },
            { field: 'sellerUomCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.uomCode' }), width: 200 },
            { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'moq' }), width: 200 },
            { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'mpq' }), width: 200 },
            { field: 'previouslySuggestedOrderQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'previouslySuggestedOrderQty' }), width: 200 },
            { field: 'currentFirmQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'currentFirmQty' }), width: 200 },
            { field: 'currentCustomerUsage', dataTypeName: 'number', title: intl.formatMessage({ id: 'currentCustomerUsage' }), width: 200 },
            { field: 'currentSuggestedOrderQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'currentSuggestedOrderQty' }), width: 250 },
            { field: 'suggestedAdditionalQty', dataTypeName: 'suggestedAdditionalQty', title: intl.formatMessage({ id: 'suggestedAdditionalQty' }), width: 250, getCellValue: getSuggestedAdditionalQty },
            { field: 'additionalQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'additionalQty' }), width: 200 },
        ] as Column[]
        return fixedColumns
    }, [getSuggestedAdditionalQty, intl])

    const onEditingCellCommit = useCallback((_column: Column, row: Row) => {
        setForecasts(forecasts => forecasts.map(item => {
            if (item.orderCalcPoDetailId === row.orderCalcPoDetailId) {
                return row as OCPartsDetail
            } else {
                return item
            }
        }))

        setInboundPlans(inboundPlans => {
            if (inboundPlans.some(s => s.orderCalcPoDetailId === row.orderCalcPoDetailId && s.additionalQty !== row.additionalQty)) {
                return inboundPlans.map(m => m.orderCalcPoDetailId === row.orderCalcPoDetailId ? { ...m, additionalQty: row.additionalQty } : m)
            } else {
                return inboundPlans
            }
        })

        setBasic(basics => basics.map(baseic => {
            if (row.orderCalcPoId === baseic.orderCalcPoId) {
                const partsDetailList = forecasts.filter(f => f.orderCalcPoId === baseic.orderCalcPoId)
                const totalQty = partsDetailList.reduce((v1, v2) => v1 + (v2.orderCalcPoDetailId === row.orderCalcPoDetailId ? row.additionalQty ? row.additionalQty : 0 : v2.additionalQty ? v2.additionalQty : 0), 0)
                const totalAmount = partsDetailList.reduce((v1, v2) => v1 + (v2.orderCalcPoDetailId === row.orderCalcPoDetailId ? row.additionalQty && v2.price ? row.additionalQty * v2.price : 0 : v2.additionalQty && v2.price ? v2.additionalQty * v2.price : 0), 0)
                const totalNumberOfParts = partsDetailList.length
                const planDates = Arrays.distinct(partsDetailList.flatMap(parts => parts.inbPlanList).map(plan => plan.crdDate.getTime())).map(m => new Date(m))
                const deliveryPlanRange = planDates.length > 0 ? formatDateRange(intl, planDates[0], planDates[planDates.length - 1]) : ''
                return { ...baseic, totalQty, totalAmount, totalNumberOfParts, deliveryPlanRange }
            } else {
                return baseic
            }
        }))

        return true
    }, [forecasts, intl, setBasic, setForecasts, setInboundPlans])

    const defaultEditingDisabled = Records.from(columns.filter(f => !f.field.includes('additionalQty')).map(({ field }) => [field, { editingDisabled: true }]))

    useEffect(() => {
        setOrder(columns.map(column => column.field))
    }, [columns])

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <PercentTypeProvider />
            <CheckTypeProvider />
            <SuggestedAdditionalQtyProvider />
            <Data rows={forecasts} columns={columns} />
            <ColumnFreeze />
            <ColumnVisibility ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnOrdering order={order} onOrderChange={setOrder} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Editing
                enableInlineEdit={editable ? true : false}
                onEditingCellCommit={onEditingCellCommit}
                columnSettings={defaultEditingDisabled}
            />
            <Filtering />
            <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        </DataGrid>
    </div>
})

const SuggestedAdditionalQtyFormatter = ({ value, row }: StringFormatterProps) => {
    const suggestedAdditionalQty = row.suggestedAdditionalQty
    const color = (value === '-' || suggestedAdditionalQty <= 0) ? '' : '#00B050'
    return <div style={{ color: color, width: '100%' }}>
        <Typography variant="body2" >{value}</Typography>
    </div>
}

const SuggestedAdditionalQtyProvider = () => {
    return <StringTypeProvider name="suggestedAdditionalQty" align="center" Formatter={SuggestedAdditionalQtyFormatter} />
}

interface Step3Props {
    setBasic: React.Dispatch<React.SetStateAction<PlaceOrderBasic[]>>,
    inboundPlans: OCPartsDetail[],
    editable: boolean,
    setInboundPlans: React.Dispatch<React.SetStateAction<OCPartsDetail[]>>,
    basic: PlaceOrderBasic[],
    forecasts: OCPartsDetail[],
    setForecasts: React.Dispatch<React.SetStateAction<OCPartsDetail[]>>,
}

const dsduplication = (item: { crdDate: number, drDate: number | null }[]) => {
    const n: { crdDate: number, drDate: number | null }[] = [...item]
    for (let i = 0; i < n.length - 1; i++) {
        for (let j = i + 1; j < n.length; j++) {
            if (n[i].crdDate === n[j].crdDate) {
                n.splice(j, 1)
                j--
            }
        }
    }
    return n
}

const getDateLabel = (value: Date | null | undefined, value2: Date | null | undefined, intl: IntlShape) => {
    const crdDate: string = value ? intl.formatDate(value, { dateStyle: 'medium' }) : ''
    if (value2 !== null && value2 !== undefined) {
        return intl.formatDate(value2, { dateStyle: 'medium' }) + ' ~ ' + crdDate
    } else {
        return crdDate
    }
}

const Step3ShippingPlanTable = memo((props: Step3Props) => {
    const { basic, forecasts, setForecasts, inboundPlans, setInboundPlans, editable, setBasic } = props
    const planDates = useMemo(() => {
        return inboundPlans && inboundPlans.length > 0
            ? dsduplication(
                Arrays.distinct(
                    inboundPlans.flatMap(parts => parts.inbPlanList)
                        .sort((a, b) => a.crdDate.getTime() - b.crdDate.getTime())
                        .map(plan => { return { crdDate: plan.crdDate.getTime(), drDate: plan.drDate ? plan.drDate.getTime() : null } })
                ))
                .map(m => ({ crdDate: new Date(m.crdDate), drDate: m.drDate ? new Date(m.drDate) : null })
                )
            : []
    }, [inboundPlans])

    const intl = useIntl()
    const [order, setOrder] = useState<string[]>([])
    const [actions, setActions] = useState<RequestProps>({ open: false, editDate: null, editDate2: null })

    const columns = useMemo(() => {
        const fixedColumns: Column[] = [
            { field: 'contractNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contractNo' }), width: 200 },
            { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
            { field: 'partsDescription', dataTypeName: 'string', title: intl.formatMessage({ id: 'partsDescription' }), width: 300 },
            { field: 'customerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'customerPartsNo' }), width: 220 },
            { field: 'supplierPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'supplierPartsNo' }), width: 220 },
            { field: 'exportCountry', dataTypeName: 'string', title: intl.formatMessage({ id: 'expCountry' }), width: 180 },
            { field: 'supplierCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.supplierCode' }), width: 180 },
            { field: 'sellerUomCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.uomCode' }), width: 150 },
            { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'moq' }), width: 150 },
            { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'mpq' }), width: 150 },
        ]
        const firmCategories = [{ key: 'firm', value: intl.formatMessage({ id: 'deliveryDate' }) }]
        const firmColumns = [{ field: 'additionalQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'additionalQty' }), categories: firmCategories, width: 150 }]
        // const firmColumns = [{ field: 'additionalQty', dataTypeName: 'number', title: intl.formatMessage({ id: 'additionalQty' }), categories: firmCategories, width: 150 }]
        const getPlanCategories = (index: number) => [{ key: `plan${index}`, value: getDateLabel(planDates[index].crdDate, planDates[index].drDate, intl) }]
        const qtytitle = intl.formatMessage({ id: 'field.qty' })
        const planColumns = planDates.map((planDate, index) => ({
            field: `planQty${index}`,
            dataTypeName: 'shippingPlan',
            title: qtytitle,
            categories: getPlanCategories(index),
            width: 250,
            getCellValue: (row: Row) => row.inbPlanList.find((f: any) => f.crdDate.getTime() === planDate.crdDate.getTime())?.crdQty ?? null,
            setCellValue: (row: Row, value: any) => {
                // do fiter
                if (row.inbPlanList?.some((plan: any) => plan.crdDate.getTime() === planDate.crdDate.getTime())) {
                    return ({ ...row, inbPlanList: row.inbPlanList?.map((plan: any) => plan.crdDate.getTime() === planDate.crdDate.getTime() ? { ...plan, crdQty: value } : plan) })
                } else {
                    const inbPlanList = row.inbPlanList
                    inbPlanList.push({
                        orderCalcPoDetailId: row.orderCalcPoDetailId,
                        crdDate: planDate.crdDate,
                        crdQty: value,
                        drDate: planDate.drDate
                    })
                    return ({ ...row, inbPlanList: inbPlanList })
                }
            }
        }))
        return Arrays.concat(fixedColumns, firmColumns, planColumns)
    }, [intl, planDates])

    const onEditingCellCommit = useCallback((_column: Column, row: Row) => {
        setInboundPlans(inboundPlans => inboundPlans.map(item => item.orderCalcPoDetailId === row.orderCalcPoDetailId ? row as OCPartsDetail : item))
        return true
    }, [setInboundPlans])

    const defaultEditingDisabled = Records.from(columns.filter(f => !f.field.includes('planQty')).map(({ field }) => [field, { editingDisabled: true }]))
    const AddnewAction = useMemo(() => ({ setActions }), [setActions])
    const clickHeader = useCallback((field: string) => {
        field.startsWith("planQty") && setActions({ open: true, editDate: planDates[Number(field.substring(7))].crdDate, editDate2: planDates[Number(field.substring(7))].drDate })
    }, [planDates])
    const shippingPlanCategory = useOCCLS017ShippingPlanCategory(clickHeader)

    useEffect(() => {
        setOrder(columns.map(column => column.field))
    }, [columns])

    const actionProps1 = useMemo(() => ({ basic, forecasts, inboundPlans, setBasic, setForecasts, setInboundPlans }), [basic, forecasts, inboundPlans, setBasic, setForecasts, setInboundPlans])

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <NumberTypeProvider name="shippingPlan" Category={shippingPlanCategory} />
            <Data rows={inboundPlans} columns={columns} />
            <ColumnFreeze />
            <ToolbarActionProvider Action={ResetDeliveryDateAction} actionProps={actionProps1} />
            <ToolbarActionProvider Action={CreateAction} actionProps={AddnewAction} display={() => editable} />
            <ColumnVisibility ToolbarButton={ColumnVisibilityToolbarButton} />
            <ColumnOrdering order={order} onOrderChange={setOrder} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Editing
                enableInlineEdit={editable ? true : false}
                onEditingCellCommit={onEditingCellCommit}
                columnSettings={defaultEditingDisabled}
            />
            <Sorting />
            <Filtering />
            <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        </DataGrid>
        <AddNewRequestDialog actions={actions} setActions={setActions} inboundPlans={inboundPlans} setInboundPlans={setInboundPlans} />
    </div>
})

const CreateAction = ({ setActions }: { setActions: React.Dispatch<React.SetStateAction<RequestProps>> }) => {
    return <CreateCallbackToolbarAction title={<FormattedMessage id="Add New Plan" />} callback={() => setActions({ open: true, editDate: null, editDate2: null })} />
}

interface RequestProps {
    open: boolean,
    editDate: Date | null,
    editDate2: Date | null
}

const AddNewRequestDialog = ({ actions, setActions, inboundPlans, setInboundPlans }: {
    actions: RequestProps,
    setActions: React.Dispatch<React.SetStateAction<RequestProps>>,
    inboundPlans: OCPartsDetail[],
    setInboundPlans: React.Dispatch<React.SetStateAction<OCPartsDetail[]>>,
}) => {
    const { open, editDate, editDate2 } = actions
    const [factor, setFactor] = useState<{ inboundPlanDate: Date | null, inboundDrDate: Date | null }>({ inboundPlanDate: null, inboundDrDate: null })
    const handleClose = useCallback(() => setActions({ open: false, editDate: null, editDate2: null }), [setActions])

    const intl = useIntl()

    useEffect(() => {
        setFactor({ inboundPlanDate: editDate, inboundDrDate: editDate2 })
    }, [editDate, editDate2])

    const dispatch = useDispatch()
    const aplyDate = useCallback(() => {
        if (!factor.inboundPlanDate) {
            dispatch(applicationActions.pushError({
                title: { code: 'confirm' },
                messages: { code: 'w0001', args: ['field.inboundPlanDate'] }
            }))
            return
        } else {
            const inboundPlanDate = factor.inboundPlanDate
            const inboundDrDate = factor.inboundDrDate
            if (inboundPlans.flatMap(f => f.inbPlanList).some(s => moment(s.crdDate).format(moment.HTML5_FMT.DATE) === moment(inboundPlanDate).format(moment.HTML5_FMT.DATE))
                && (!editDate || moment(editDate).format(moment.HTML5_FMT.DATE) !== moment(inboundPlanDate).format(moment.HTML5_FMT.DATE))) {
                dispatch(applicationActions.pushError({
                    title: { code: 'confirm' },
                    messages: { code: 'w0381' }
                }))
                return
            } else {
                setInboundPlans(inboundPlans => inboundPlans.map(m => ({
                    ...m, inbPlanList: !editDate ? [...m.inbPlanList, {
                        orderCalcPoDetailId: m.orderCalcPoDetailId,
                        crdId: null,
                        crdDate: inboundPlanDate,
                        crdQty: null,
                        version: null,
                        drDate: inboundDrDate
                    }] : m.inbPlanList.map(plan => plan.crdDate.getTime() === editDate.getTime() ? { ...plan, crdDate: inboundPlanDate, drDate: inboundDrDate } : plan)
                })))
            }
        }
        // do reflect
        setFactor({ inboundPlanDate: null, inboundDrDate: null })
        setActions({ open: false, editDate: null, editDate2: null })
    }, [dispatch, editDate, factor.inboundDrDate, factor.inboundPlanDate, inboundPlans, setActions, setInboundPlans])

    return <>
        <DarkDialog open={open} style={{ overflow: 'hidden' }} maxWidth="sm" fullWidth keepMounted={false} fullScreen={false}>
            <DialogHeader onClose={handleClose}><FormattedMessage id="inputInboundDate" /></DialogHeader>
            <DialogContent>
                <Form data={factor} setData={setFactor} labelDisplay="block" helperDisplay="tooltip" columnCount={1} minWidth={500} maxWidth={500}  >
                    <DateItem field="inboundDrDate" labelWidth={120} label={intl.formatMessage({ id: 'field.drDate' })} />
                    <DateItem required field="inboundPlanDate" labelWidth={120} label={intl.formatMessage({ id: 'field.inboundPlanDate' })} />
                </Form>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={handleClose} />
                <DialogAction title={<FormattedMessage id="confirm" />} callback={aplyDate} />
            </DialogActions>
        </DarkDialog>
    </>
}

const Step4BasicInfoPanelCard = memo(({ editable, basic, setBasic }: { editable: boolean, basic: PlaceOrderBasic[], setBasic: React.Dispatch<React.SetStateAction<PlaceOrderBasic[]>> }) => {

    const disContracts = useMemo(() => basic.filter(f => f?.totalQty > 0).map(m => m.contractNo), [basic])
    const [selectValue, setSelectedValue] = useState<string | number>("")
    const onSelectedValueChange = useCallback((value: string | number) => {
        setSelectedValue(value)
    }, [setSelectedValue])

    useEffect(() => {
        if (disContracts && disContracts.length > 0) {
            setSelectedValue(value => disContracts.indexOf(value as string) >= 0 ? value : disContracts[0])
        } else {
            setSelectedValue("")
        }
    }, [disContracts, setSelectedValue])

    return (
        <ViewTabControl>
            <ViewTabs selectedValue={selectValue} onSelectedValueChange={onSelectedValueChange}>
                {
                    basic.map((m, index) => {
                        if (m?.totalQty > 0) {
                            return <ViewTab label={m.contractNo} value={m.contractNo} />
                        } else {
                            return <>
                            </>
                        }
                    })
                }
            </ViewTabs>
            <ViewPanels>
                {
                    basic.map((m, index) => {
                        if (m?.totalQty > 0) {
                            return <ViewPanel value={m.contractNo}>
                                <ContractCard editable={editable} basic={m} index={index} setBasic={setBasic} />
                            </ViewPanel>
                        } else {
                            return <>
                            </>
                        }
                    })
                }
            </ViewPanels>
        </ViewTabControl>
    )
})

const ContractCard = memo(({ editable, basic, index, setBasic }: {
    editable: boolean,
    basic: PlaceOrderBasic,
    index: number,
    setBasic: React.Dispatch<React.SetStateAction<PlaceOrderBasic[]>>
}) => {
    const { getCodeName } = useGetCodeName()
    const intl = useIntl()
    const shippingMode = useMemo(() => getCodeName(CodeCategory.ShippingMode, basic?.shippingMode), [getCodeName, basic.shippingMode])
    const orderRange = useMemo(() => formatDateRange(intl, basic.targetFirstDate, basic.targetLastDate), [basic.targetFirstDate, basic.targetLastDate, intl])
    const [messages, setMessages] = useState<Message[]>([])
    const filedCheck = useFieldChecker(SpotOrderFields, setMessages)

    const onContactChange = useCallback((index) => {
        return (nextDraftDataFunc: React.Dispatch<React.SetStateAction<any>>) => {
            setBasic(contacts => {
                const editContact = contacts[index]
                const draftData: any = typeof nextDraftDataFunc === 'function' ? nextDraftDataFunc(editContact) : nextDraftDataFunc
                return contacts.map((m, idx) => idx === index ? draftData : m)
            })
        }
    }, [setBasic])

    return (
        <Form data={basic} setData={onContactChange(index)} labelDisplay="block" helperDisplay="tooltip" columnCount={2} messages={messages} setMessages={setMessages} ensure={filedCheck}>
            <StringItem field="orderRefNo" required readonly={!editable} label={intl.formatMessage({ id: 'orderReference' })} />
            <StringItem field="contractNo" readonly={true} label={intl.formatMessage({ id: 'field.contractNo' })} />
            <Break />
            <StringItem field="sellerCode" readonly={true} label={intl.formatMessage({ id: 'seller' })} />
            <NumberItem field="totalAmount" readonly={true} label={intl.formatMessage({ id: 'totalAmount' })} suffix={basic.currency} />
            <Break />
            <StringItem field="deliveryToCode" readonly={true} label={intl.formatMessage({ id: 'deliveryToCode' })} />
            <NumberItem field="totalNumberOfParts" readonly={true} label={intl.formatMessage({ id: 'totalNumberOfParts' })} />
            <Break />
            <StringItem field="shippingMode" readonly={true} label={intl.formatMessage({ id: 'field.shippingMode' })} getValue={() => shippingMode} />
            <NumberItem field="totalQty" readonly={true} label={intl.formatMessage({ id: 'totalQty' })} />
            <Break />
            <StringItem field="orderRange" readonly={true} label={intl.formatMessage({ id: 'orderRange' })} getValue={() => orderRange} />
            <StringItem field="deliveryPlanRange" readonly={true} label={intl.formatMessage({ id: 'deliveryPlanRange' })} />
            <Break />
            <StringItem field="termsDescription" readonly={true} label={intl.formatMessage({ id: 'field.paymentTermsDesc' })} colSpan={2} />
            <StringItem required readonly={!editable} field="spotOrderReason" label={intl.formatMessage({ id: 'field.spotOrderReason' })} colSpan={2} />
            <StringItem field="remark" readonly={!editable} label={intl.formatMessage({ id: 'field.remark' })} colSpan={2} />
        </Form>
    )
})

const UploadAction = (props: Step1Props) => {
    const { setBasic, setForecasts, setInboundPlans, poGroupId } = props
    const uploadSpotListMethod = useUploadSpotOrderFormListForCustomerPNA()
    const uploadMethod = uploadSpotListMethod
    const splitDatas = useSplitDatas()
    const upload = useCallback((files: FileList | null) => {
        if (files === null) return
        uploadMethod({ file: files[0], poGroupId: poGroupId }, { serialized: true }).then(result => {
            if (result) {
                const { orderbasic, orderforecasts, orderInboundPlans } = splitDatas(result)
                setBasic(orderbasic)
                setForecasts(orderforecasts)
                setInboundPlans(orderInboundPlans)
            }
        })
    }, [poGroupId, setBasic, setForecasts, setInboundPlans, splitDatas, uploadMethod])

    return <UploadCallbackCardAction access="STCK.OCCLS017.UPLOAD" callback={upload} />
}

const DownloadAction = ({ basic, forecasts, inboundPlans }: Step1Props) => {
    const mergeDatas = useMergeDatas()
    const downloadSpotOrderformListForCustomer = useDownloadSpotOrderformListForCustomerPNA()
    const download = useCallback(() => {
        const data = mergeDatas(basic, forecasts, inboundPlans)
        downloadSpotOrderformListForCustomer(data)
    }, [basic, downloadSpotOrderformListForCustomer, forecasts, inboundPlans, mergeDatas])

    return <DownloadCallbackCardAction outlined access="STCK.OCCLS017.DOWNLOAD" callback={download} />
}

const DownloadOrderSummaryAction = ({ basic, forecasts, inboundPlans }: Step1Props) => {
    const mergeDatas = useMergeDatas()
    const dispatch = useDispatch()
    const downloadOrderSummaryReportForPNA = useDownloadOrderSummaryReportForPNA()
    const download = useCallback(() => {
        // do check inboundPlans if no data
        if (inboundPlans.some(f => f.inbPlanList && f.inbPlanList.length && f.inbPlanList.length > 0)) {
            downloadOrderSummaryReportForPNA(mergeDatas(basic, forecasts, inboundPlans))
        } else {
            // show message 
            dispatch(applicationActions.pushError({ title: { code: 'downloadSummaryReport' }, messages: { code: 'w0925' } }))
        }
    }, [basic, dispatch, downloadOrderSummaryReportForPNA, forecasts, inboundPlans, mergeDatas])

    return <DownloadCallbackCardAction outlined access="STCK.OCCLS017.DOWNLOADSUMMARYREPORT" callback={download} />
}

const usePrepareActions = (
    mode: ScreenMode,
    basic: PlaceOrderBasic[],
    forecasts: OCPartsDetail[],
    inboundPlans: OCPartsDetail[],
    refresh: (poGroupId: string | null | undefined, silent: boolean) => void,
    poGroupId: string
) => {
    const saveSpotPlacedOrderList = useSaveSpotPlacedOrderListPNA()
    const saveAndIssueSpotPlacedOrderList = useSaveAndIssueSpotPlacedOrderListPNA()
    const navigate = useNavigate()
    const mergeDatas = useMergeDatas()
    const [disabled, setDisabled] = useState<boolean>(false)
    const intl = useIntl()
    const title = useMemo(() => intl.formatMessage({ id: 'issue' }), [intl])

    const dispatch = useDispatch()
    const functionStore = useFunctionStore()
    const saveData = useCallback((isIssue: number) => {
        const data = mergeDatas(basic, forecasts, inboundPlans)
        if (isIssue === 1) {
            const functionId = functionStore.register(() => {
                setDisabled(true)
                saveAndIssueSpotPlacedOrderList(data, { silent: false, serialized: true }).then(result => {
                    navigate('/orderCalculationPNA')
                }).finally(() => {
                    setDisabled(false)
                })
            })
            dispatch(applicationActions.pushWarning({
                title,
                messages: { code: 'c0001', args: [title] },
                actions: [{
                    label: 'CANCEL',
                }, {
                    label: 'CONFIRM',
                    functionId
                }]
            }))
        } else {
            setDisabled(true)
            saveSpotPlacedOrderList(data, { silent: false, serialized: true }).then(result => {
                refresh(poGroupId, true)
            }).finally(() => {
                setDisabled(false)
            })
        }
    }, [basic, dispatch, forecasts, functionStore, inboundPlans, mergeDatas, navigate, poGroupId, refresh, saveAndIssueSpotPlacedOrderList, saveSpotPlacedOrderList, title])

    const editable = useMemo(() => mode !== ScreenMode.VIEW, [mode])
    return editable ? [
        <SaveCallbackViewAction access="STCK.OCCLS017.SAVE" outlined callback={() => { saveData(0) }} disabled={disabled} />,
        <IssueCallbackViewAction access="STCK.OCCLS017.SAVE" callback={() => { saveData(1) }} disabled={disabled} />
    ] : []
}

const CheckFormatter = ({ value }: StringFormatterProps) => {
    const style = useStyles()
    const bgcolor = 'NG' === value ? '#D94C00' : 'N/A' === value ? '#ECEFF2' : '#00CCAD'
    const color = 'N/A' === value ? '' : 'white'
    return <div className={style.orderLotCheck} style={{ background: bgcolor, color: color, width: '100%' }}>
        <Typography variant="body2" >{value}</Typography>
    </div>
}

const CheckTypeProvider = () => {
    return <StringTypeProvider name="orderLotCheck" Formatter={CheckFormatter} />
}

const ResetDeliveryDateAction = ({ basic, forecasts, inboundPlans, setBasic, setForecasts, setInboundPlans }: {
    basic: PlaceOrderBasic[],
    forecasts: OCPartsDetail[],
    inboundPlans: OCPartsDetail[],
    setBasic: React.Dispatch<React.SetStateAction<PlaceOrderBasic[]>>,
    setForecasts: React.Dispatch<React.SetStateAction<OCPartsDetail[]>>,
    setInboundPlans: React.Dispatch<React.SetStateAction<OCPartsDetail[]>>,
}) => {
    const mergeDatas = useMergeDatas()
    const splitDatas = useSplitDatas()
    const getDeliveryDateForPNA = useGetSpotDeliveryDateForPNA()

    const onClickPNA = useCallback(() => {
        const data = mergeDatas(basic, forecasts, inboundPlans)
        getDeliveryDateForPNA(data, { silent: true, serialized: true }).then(result => {
            if (result) {
                const { orderInboundPlans } = splitDatas(result)
                setInboundPlans(orderInboundPlans)
            }
        })
    }, [basic, forecasts, getDeliveryDateForPNA, inboundPlans, mergeDatas, setInboundPlans, splitDatas])
    return <>
        <Access access="STCK.OCCLS017.SAVE">
            <Button variant={'outlined'} color="primary" onClick={onClickPNA}>
                {<FormattedMessage id="resetDeliveryDate" />}
            </Button>
        </Access>
    </>
}

const useStyles = makeStyles(theme => ({
    orderLotCheck: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70%',
        paddingRight: theme.spacing(1),
        borderRadius: 5
    },
    fulcuationCheck: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70%',
        paddingRight: theme.spacing(1),
        borderRadius: 5
    },
    default: {},
}))

const SpotOrderFields = {
    orderRefNo: { labelId: 'orderRefNo', length: { max: 100 }, required: false },
    spotOrderReason: { labelId: 'field.spotOrderReason', length: { max: 100 }, required: false },
    remark: { labelId: 'remark', length: { max: 255 } }
}

