import { Theme, ThemeOptions, unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core/styles"
import { Layout, LayoutOptions, mergeLayout } from './Layout'

declare module '@material-ui/core/styles/overrides' {
    interface ComponentNameToClassKey {
        MuiPickersYearSelection: 'container',
        MuiPickersYear: 'root' | 'yearSelected',
    }
}

declare module '@material-ui/core' {
    interface Theme {
        layout: Layout,
    }
    interface ThemeOptions {
        layout?: LayoutOptions,
    }
}

declare module '@material-ui/core/styles/createPalette' {
    interface TypeBackground {
        workbench: string,
        appbar: {
            light: string,
            main: string,
        },
        neumorphism: {
            main: string,
            shadow: string,
        },
        card: {
            linear: string,
            main: string,
            inactive: string,
        },
        search: {
            main: string,
            hover: string,
            focused: string,
        },
        popover: {
            main: string,
        },
        readonly: {
            main: string
        },
        disabled: {
            main: string
        }
    }
}

export default function createTheme(theme: ThemeOptions): Theme {
    return createMuiTheme({
        layout: mergeLayout(theme.layout),
        shape: theme.shape ?? {},
        breakpoints: theme.breakpoints,
        direction: theme.direction,
        overrides: theme.overrides,
        palette: theme.palette,
        props: theme.props,
        shadows: theme.shadows,
        spacing: theme.spacing,
        transitions: theme.transitions ?? {}, // will throw error if is undefined
        typography: theme.typography,
        zIndex: theme.zIndex,
    })
}