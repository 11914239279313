import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"
import { ActiveFlag } from "../enums/ActiveFlag"

export type TnmContainerType = Required & Partial<Optional> & TableEntity
type TnmContainerTypeJson = JsonRequired & Partial<JsonOptional> & TableEntityJson

export const tnmContainerTypeFromJson: Project<TnmContainerTypeJson, TnmContainerType> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
    activeFlag: ActiveFlag,
}

interface JsonRequired {
    containerTypeId: number,
}

interface JsonOptional {
    containerTypeCode: string,
    description: string,
    tareWeight: number,
    m3: number,
    maxM3: number,
    maxWeight: number,
    activeFlag: number,
}