import {ExternalOrderStatus} from '../../../services/integration/enums/ExternalOrderStatus'
import {Project} from "../../../utils/Project"
import {BusinessType} from "../../master/enums/BusinessType"
import {OrderFrequency} from "../../order/enums/OrderFrequency"
import {OrderType} from "../../order/enums/OrderType"
import {dateFromJson} from "../../utils/deserializer"
export type ExternalOrderListResult = Required & Partial<Optional>

export type ExternalOrderListResultJson = JsonRequired & Partial<JsonOptional>

export const externalOrderListResult: Project<ExternalOrderListResultJson, ExternalOrderListResult> = json => ({
    ...json,
    orderFirstDate: dateFromJson(json.orderFirstDate),
    orderLastDate: dateFromJson(json.orderLastDate),
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional,'orderFirstDate' | 'orderLastDate'> {
    orderType: OrderType,
    orderFrequency: OrderFrequency,
    businessType: BusinessType,
    orderFirstDate: Date,
    orderLastDate: Date,
    state: ExternalOrderStatus
}

interface JsonRequired {
    orderId: number
    orderNo: string
    state: number
}

interface JsonOptional {
    customerCode: string,
    orderFrequency: number,
    orderType: number,
    orderFirstDate: string,
    orderLastDate: string,
    businessType: number,
    grouping: string,
    remark: string,
    isExistGrouping:number
}
