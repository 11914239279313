import { AxiosInstance } from 'axios'
import { PageResultEntity } from '../../utils/PageResultEntity'
import { CreateUserContractRequest } from '../models/CreateUserContractRequest'
import { ListUserContractsAPIRequest } from '../models/ListUserContractsAPIRequest'
import { UpdateUserContractAPIRequest } from '../models/UpdateUserContractAPIRequest'
import { UserContractAPIResponse } from '../models/UserContractAPIResponse'

const userContractApi = {
    get: function (axiosInstance: AxiosInstance, userId: string) {
        return axiosInstance.get<UserContractAPIResponse>('/common-system-api/user-contracts/:userId', {pathVars: {userId}})
    },
    batchGet: function (axiosInstance: AxiosInstance, userIds: string[]) {
        return axiosInstance.post<UserContractAPIResponse[]>('/common-system-api/user-contracts/batch-get', userIds)
    },
    list: function (axiosInstance: AxiosInstance, data: ListUserContractsAPIRequest) {
        return axiosInstance.get<PageResultEntity<UserContractAPIResponse>>('/common-system-api/user-contracts', {params: data})
    },
    create: function (axiosInstance: AxiosInstance, data: CreateUserContractRequest) {
        return axiosInstance.post<UserContractAPIResponse>('/common-system-api/user-contracts', data)
    },
    batchCreate: function (axiosInstance: AxiosInstance, data: CreateUserContractRequest[]) {
        return axiosInstance.post<UserContractAPIResponse[]>('/common-system-api/user-contracts/batch-create', data)
    },
    update: function (axiosInstance: AxiosInstance, data: UpdateUserContractAPIRequest) {
        return axiosInstance.put<UserContractAPIResponse>('/common-system-api/user-contracts/:userId', data, {pathVars: {userId: data.userId}})
    },
    batchUpdate: function (axiosInstance: AxiosInstance, data: UpdateUserContractAPIRequest[]) {
        return axiosInstance.post<UserContractAPIResponse[]>('/common-system-api/user-contracts/batch-update', data)
    },
    delete: function (axiosInstance: AxiosInstance, userId: string) {
        return axiosInstance.delete('/common-system-api/user-contracts/:userId', {pathVars: {userId}})
    },
    batchDelete: function (axiosInstance: AxiosInstance, userIds: string[]) {
        return axiosInstance.post('/common-system-api/user-contracts/batch-delete', userIds)
    }
}

export default userContractApi