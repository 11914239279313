import React, { useEffect, useState } from "react"
import { useInitDeliveryPlanScreen } from "../../../services/delivery/apis/deliveryCustApi"
import { DeliveryPlanInitResult } from "../../../services/delivery/models/DeliveryPlanInitResult"
import { DownloadOnbDlvrView } from "../../../services/delivery/models/DownloadOnbDlvrView"
import { LOS011PcUi } from "./LOS011PcUi"

export const LOS011 = () => {
    const [factor, setFactor] = useState<DownloadOnbDlvrView>({} as DownloadOnbDlvrView)
    const [initData, setInitData] = useState<DeliveryPlanInitResult>({} as DeliveryPlanInitResult)

    const getInitData = useInitDeliveryPlanScreen()
    useEffect(() => {
        getInitData(undefined, { silent: true, serialized: true }).then(result => {
            setInitData(result || {})
        })
    }, [getInitData])

    return <LOS011PcUi
        factor={factor}
        setFactor={setFactor}
        initData={initData}
    />
}