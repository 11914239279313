import { Typography } from '@material-ui/core'
import { ObjectTypeProvider } from '@rithe/data-grid'
import { ObjectFormatterProps } from '@rithe/data-grid/dist/components/dataTypes/ObjectFormatter'
import React from 'react'
import { Link } from 'react-router-dom'

export const LinkTypeProvider = () => {
    return <ObjectTypeProvider name="link" Formatter={Formatter} />
}

const Formatter = (props: ObjectFormatterProps) => {
    const { value } = props
    return value ? <Link to={(value as any).href} style={{ textDecoration: 'none' }}><Typography variant="body1">{(value as any).text}</Typography></Link> : null
}