import { Project } from "../../../utils/Project"

export type InventoryDownloadByPackageFactor = Partial<Optional>
type InventoryDownloadByPackageFactorJson = Partial<JsonOptional>
export const inventoryDownloadByPackageFactorToJson: Project<InventoryDownloadByPackageFactor, InventoryDownloadByPackageFactorJson> = obj => ({
    ...obj,
})

interface Optional extends JsonOptional {
}


interface JsonOptional {
    dcIdList: number[],
    customerIdList: number[],
    partsNo: string,
    unitPartsNo: string,
    backNo: string,
    colorCode: string,
    packageNo: string,
}
