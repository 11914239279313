import { Project } from "../../../utils/Project"
import { AutoDiFlag } from "../enums/AutoDiFlag"
import { PriceBasis } from "../enums/PriceBasis"

export type ContractRouteDetailPartsResult = Required & Partial<Optional>
type ContractRouteDetailPartsResultJson = JsonRequired & Partial<JsonOptional>
export const contractRouteDetailPartsResultFromJson: Project<ContractRouteDetailPartsResultJson, ContractRouteDetailPartsResult> = json => ({
    ...json,
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
    salePriceBasis: PriceBasis,
    buyPriceBasis: PriceBasis,
    saleAutoDiFlag: AutoDiFlag,
    buyAutoDiFlag: AutoDiFlag,
}

interface JsonRequired {
    contractRouteNo: string,
}

interface JsonOptional {
    description: string,
    customerCode: string,
    partsNo: string,
    unitPartsNo: string,
    saleContractNo: string,
    buyerPartsNo: string,
    buyerCode: string,
    salePriceBasis: number,
    saleShipper: string,
    saleReceiver: string,
    saleAutoDiFlag: number,
    saleSrCode: string,
    saleSpotSrCode: string,
    buyContractNo: string,
    sellerPartsNo: string,
    sellerCode: string,
    buyPriceBasis: number,
    buyShipper: string,
    buyReceiver: string,
    buyAutoDiFlag: number,
    buySrCode: string,
    buySpotSrCode: string,
}