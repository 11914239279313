import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { RequestStatus } from "../../master/enums/RequestStatus"
import { dateFromJson } from "../../utils/deserializer"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"
import { OrderFrequency } from "../enums/OrderFrequency"
import { OrderType } from "../enums/OrderType"
import { RequestType } from "../enums/RequestType"

export type CCRequestListFactor = Partial<Optional>
type CCRequestListFactorJson = Partial<JsonOptional>
export const cCRequestListFactorToJson: Project<CCRequestListFactor, CCRequestListFactorJson> = obj => ({
    ...obj,
    handledDateStart: dateToJson(obj.handledDateStart),
    handledDateEnd: dateToJson(obj.handledDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
})

export const cCRequestListFactorFromJson: Project<CCRequestListFactorJson, CCRequestListFactor> = json => ({
    ...json,
    handledDateStart: dateFromJson(json.handledDateStart),
    handledDateEnd: dateFromJson(json.handledDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page),
})

interface Optional extends Omit<JsonOptional, 'handledDateStart' | 'handledDateEnd' | 'page'> {
    requestTypeList: RequestType[],
    requestStatusList: RequestStatus[],
    orderTypeList: OrderType[],
    orderFrequencyList: OrderFrequency[],
    handledDateStart: Date,
    handledDateEnd: Date,
    page: PageViewEntity,
}


interface JsonOptional {
    requestNo: string,
    requestTypeList: number[],
    requestStatusList: number[],
    customerOrderNo: string,
    orderVersion: number,
    customerRefNo: string,
    salesOrderNo: string,
    customerIdList: number[],
    supplierIdList: number[],
    orderTypeList: number[],
    orderFrequencyList: number[],
    contractNo: string,
    contractRouteNo: string,
    handledBy: string,
    handledDateStart: string,
    handledDateEnd: string,
    page: PageViewEntityJson,
}
