import { Project } from "@rithe/utils"
import { dateFromJson } from "../../utils/deserializer"
import { dateToJson } from "../../utils/serializer"

export type WIPAdjustFactor = Partial<Optional>;
export type WIPAdjustFactorJson = Partial<JsonOptional>;

export const wipAdjustFactorToJson: Project<WIPAdjustFactor, WIPAdjustFactorJson> = obj => ({
    ...obj,
    adjustDateStart: dateToJson(obj.adjustDateStart),
    adjustDateEnd: dateToJson(obj.adjustDateEnd),
})

export const wipAdjustFactorFromJson: Project<WIPAdjustFactorJson, WIPAdjustFactor> = json => ({
    ...json,
    adjustDateStart: dateFromJson(json.adjustDateStart),
    adjustDateEnd: dateFromJson(json.adjustDateEnd),
})


interface Optional extends Omit<JsonOptional, 'adjustDateStart' | 'adjustDateEnd'> {
    adjustDateStart: Date,
    adjustDateEnd: Date,
}


interface JsonOptional {
    partsNo: string,
    customerCode: string,
    contractNo: string,
    unitPartsNo: string,
    adjustDateStart: string,
    adjustDateEnd: string
}


