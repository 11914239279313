import React, { useCallback, useEffect, useState } from "react"
import { useComMasterGetAllCbds, useComMasterGetAllCurrency } from "../../../services/common/apis/commonMasterApi"
import { useGetCreditRuleList, useGetCreditSettingList } from "../../../services/master/apis/creditRuleApi"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { CreditCheckInfoResult } from "../../../services/master/models/CreditCheckInfoResult"
import { CreditSettingInfoFactor } from "../../../services/master/models/CreditSettingInfoFactor"
import { CreditSettingInfoResult } from "../../../services/master/models/CreditSettingInfoResult"
import { TnmCurrency } from "../../../services/master/models/TnmCurrency"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { MLS190PcUi } from "./MLS190PcUi"

export const MLS190 = () => {

    const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }
    const [filters, setFilters] = useState<CreditSettingInfoFactor>(defaultFilters)
    const [settingList, setSettingList] = useState<CreditSettingInfoResult[]>([])


    const getData = useGetCreditSettingList()
    const search = useCallback((filters: CreditSettingInfoFactor) => {
        getData({ ...filters }, { serialized: true, silent: true }).then(result => {
            setSettingList(result?.data || [])
        })
    }, [getData])
    useSearchOnLoad(search, filters)

    const [currencys, setCurrencys] = useState<TnmCurrency[]>([])
    const getCurrency = useComMasterGetAllCurrency()
    useEffect(() => {
        getCurrency(undefined, { silent: true }).then(result => {
            setCurrencys(result || [])
        })
    }, [getCurrency])

    const [buyers, setBuyers] = useState<TnvCbds[]>([])
    const getAllCbdss = useComMasterGetAllCbds()
    useEffect(() => {
        getAllCbdss({ types: [CbdsType.CUST, CbdsType.BU] }, { silent: true }).then(result => {
            setBuyers(result || [])
        })
    }, [getAllCbdss])

    const [ruleList, setRuleList] = useState<CreditCheckInfoResult[]>([])
    const getRuleList = useGetCreditRuleList()
    useEffect(() => {
        getRuleList({}, { silent: true }).then(result => {
            setRuleList(result?.data || [])
        })
    }, [getAllCbdss, getRuleList])

    return <MLS190PcUi
        filters={filters}
        setFilters={setFilters}
        settingList={settingList}
        currencys={currencys}
        buyers={buyers}
        search={search}
        ruleList={ruleList}
    />
}