import { Project } from "../../../utils/Project"
import { ShippingMode } from "../../master/enums/ShippingMode"
import { dateFromJson } from "../../utils/deserializer"
import { InboundDataType } from "../enums/InboundDataType"
import { InboundStatus } from "../enums/InboundStatus"
import { InboundType } from "../enums/InboundType"


export interface InboundMonitorInfo {
    shipper: string,
    inboundNo: string,
    inboundType: InboundType,
    orgInboundPlanDate?: Date,
    latestInboundPlanDate?: Date,
    actualInboundPlanDate?: Date,
    etd?: Date,
    eta?: Date,
    shippingMode: ShippingMode,
    vesselName: string,
    voyageNo: string,
    outboundNo: string,
    outboundDate: Date,
    outboundTime: string
    completedDate: Date,
    status: InboundStatus,
    containers?: ContainerInfo[],
    dataType: InboundDataType
}

interface InboundMonitorInfoJson {
    shipper: string,
    inboundNo: string,
    inboundType: number,
    orgInboundPlanDate?: string,
    latestInboundPlanDate?: string,
    actualInboundPlanDate?: string,
    etd?: string,
    eta?: string,
    shippingMode: number,
    vesselName: string,
    voyageNo: string,
    outboundNo: string,
    outboundDate: string,
    outboundTime: string
    completedDate: string,
    status: number,
    containers?: ContainerInfoJson[],
    dataType: number
}

export const inboundMonitorInfoFromJson: Project<InboundMonitorInfoJson, InboundMonitorInfo> = json => ({
    ...json,
    orgInboundPlanDate: dateFromJson(json.orgInboundPlanDate),
    latestInboundPlanDate: dateFromJson(json.latestInboundPlanDate),
    actualInboundPlanDate: dateFromJson(json.actualInboundPlanDate),
    etd: dateFromJson(json.etd),
    eta: dateFromJson(json.eta),
    outboundDate: dateFromJson(json.outboundDate),
    completedDate: dateFromJson(json.completedDate),
    containers: json.containers?.map(ContainerInfoFromJson),
})

// Container information
export interface ContainerInfo {
    containerNo?: string,
    containerType?: string,
    commodityType?: string,
    sealNo?: string,
    m3?: number,
    netWeight?: number,
    grossWeight?: number,
    status: InboundStatus
    outerPackageList?: OuterPackageInfo[],
}

interface ContainerInfoJson {
    containerNo?: string,
    containerType?: string,
    sealNo?: string,
    m3?: number,
    netWeight?: number,
    grossWeight?: number,
    status: number,
    outerPackageList?: OuterPackageInfoJson[],
}

const ContainerInfoFromJson: Project<ContainerInfoJson, ContainerInfo> = json => ({
    ...json,
    outerPackageList: json.outerPackageList?.map(outerPackageInfoFromJson),
})

//outer package
export interface OuterPackageInfo {
    palletNo?: string,
    outerPackageNo: string,
    outerPackageType?: string,
    productionDate?: Date,
    m3?: number,
    netWeight?: number,
    grossWeight?: number,
    outboundByPackage: boolean,
    status: InboundStatus
    innerPackageList: InnerPackageInfo[],
}

interface OuterPackageInfoJson {
    palletNo?: string,
    outerPackageNo: string,
    outerPackageType?: string,
    productionDate?: string,
    m3?: number,
    netWeight?: number,
    grossWeight?: number,
    outboundByPackage: boolean,
    status: number,
    innerPackageList: InnerPackageInfoJson[],
}

const outerPackageInfoFromJson: Project<OuterPackageInfoJson, OuterPackageInfo> = json => ({
    ...json,
    productionDate: dateFromJson(json.productionDate),
    partsList: json.innerPackageList.map(innerPackageInfoFromJson),
})

//inner package
export interface InnerPackageInfo {
    boxNo?: string,
    ipPackageType?: string,
    m3?: number,
    netWeight?: number,
    grossWeight?: number,
    status: InboundStatus
    partsList: InboundPartsInfo[],
}

interface InnerPackageInfoJson {
    boxNo?: string,
    ipPackageType?: string,
    m3?: number,
    netWeight?: number,
    grossWeight?: number,
    status: number,
    partsList: InboundPartsInfoJson[],
}


const innerPackageInfoFromJson: Project<InnerPackageInfoJson, InnerPackageInfo> = json => ({
    ...json,
    partsList: json.partsList.map(inboundPartsInfoFromJson)
})

//parts
export interface InboundPartsInfo {
    poNo: string,
    buyerCode: string,
    sellerCode: string,
    buyerPartsNo: string,
    buyerPartsName?: string,
    buyerBackNo?: string,
    colorCode?: string,
    spq: number,
    status: InboundStatus
    partsNo: string,
    qty?: number,
    inboundedQty?: number,
}

interface InboundPartsInfoJson {
    poNo: string,
    buyerCode: string,
    sellerCode: string,
    buyerPartsNo: string,
    buyerPartsName?: string,
    buyerBackNo?: string,
    colorCode?: string,
    spq: number,
    partsNo: string,
    status: number
    qty?: number,
    inboundedQty?: number,
}

const inboundPartsInfoFromJson: Project<InboundPartsInfoJson, InboundPartsInfo> = json => ({
    ...json
})
