import { Project } from "@rithe/utils"
import { Projects } from "../../../utils/Projects"
import { dateFromJson } from "../../utils/deserializer"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"

export type OutboundToWIPEntityList = Required & Partial<Optional>
export type OutboundToWIPEntityListJson = JsonRequired & Partial<JsonOptional>
export const outboundToWIPEntityListToJson: Project<OutboundToWIPEntityList, OutboundToWIPEntityListJson> = obj => ({
    ...obj,
    outboundDate: dateToJson(obj.outboundDate),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(obj.page)
})
export const outboundToWIPEntityListFromJson: Project<OutboundToWIPEntityListJson, OutboundToWIPEntityList> = json => ({
    ...json,
    outboundDate: dateFromJson(json.outboundDate),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page)
})

interface Required extends JsonRequired {
}

interface Optional extends Omit<JsonOptional, 'outboundDate'|'page'> {
    outboundDate: Date,
    page: PageViewEntity,
}

interface JsonRequired {
}

interface JsonOptional {
    outboundToWipId: number,
    partsNo: string,
    customerCode: string,
    contractNo: string,
    unitPartsNo: string,
    backNo: string,
    expRegion: string,
    supplierCode: string,
    outboundDate: string,
    outboundQty: number,
    uom: string,
    uomDigits: number
    
    page: PageViewEntityJson

}