import { Tooltip } from "@material-ui/core"
import { HighlightOff } from "@material-ui/icons"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { Access } from "../../../components/Access/Access"
import { IconButton } from "../../../components/Button/IconButton"
import { OrderCalcStatus } from "../../../services/order/enums/OrderCalcStatus"
import { ConfirmDialog } from "./ConfirmDialog"
import { ListTableRow } from "./ListTable"
import { OrderCalculationType, occls010Actions } from "./OCCLS010Slice"

export interface DeleteRowActionProps extends DataGridRowActionProps {
  type: OrderCalculationType
}
export function DeleteRowAction(props: DeleteRowActionProps) {
  const { tableRow, type } = props
  const row = tableRow.row as ListTableRow

  const dispatch = useDispatch()
  const intl = useIntl()
  const [open, setOpen] = useState(false)
  const orderCalcNo = row.orderCalcNo!

  // only display PENDING_ORDER, no order placed, no review, in current month
  const display = row.status === OrderCalcStatus.PENDING_ORDER
    && !row.partialPlaceOrder
    && row.maxOrderVersion === row.orderVersion
  // && row.inCurrentMonth=> remove first TODO

  if (!display) {
    return null
  } else {
    return <>
      <Access access="STCK.OCCLS010.DELETE">
        <Tooltip title={<FormattedMessage id="delete" />}>
          <IconButton onClick={() => setOpen(true)}>
            <HighlightOff />
          </IconButton>
        </Tooltip>
      </Access>
      <ConfirmDialog
        open={open}
        onClose={() => setOpen(false)}
        title={<FormattedMessage id="delete" />}
        // content={`Are you sure would like to delete <${row.customerCode}-${row.orderMonth ? moment(row.orderMonth).format('YYYYMM') : ''}-${row.orderVersion}>? This action cannot be reversed. `}
        content={intl.formatMessage({id: "OC_DELETE_CONFIRM"}).replace('{0}', orderCalcNo)}
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={<FormattedMessage id="delete" />}
        onConfirm={() => { setOpen(false); dispatch(occls010Actions.remove({ orderCalcNo, type })); }}
      />
    </>
  }
}