import { ListItemIcon, MenuItem } from "@material-ui/core"
import React from "react"
import { Access } from "../Access/Access"

interface GroupedViewActionCallbackItemProps {
    access?: string,
    icon?: React.ReactNode,
    label: NonNullable<React.ReactNode>,
    callback: () => void,
    onClose: () => void,
}

export const GroupedViewActionCallbackItem = (props: GroupedViewActionCallbackItemProps) => {
    const { access, icon, label, callback, onClose } = props
    const action = <MenuItem onClick={() => {
        callback()
        onClose()
    }}>
        {icon && <ListItemIcon>
            {icon}
        </ListItemIcon>}
        {label}
    </MenuItem>
    return access ? <Access access={access}>{action}</Access> : action
}