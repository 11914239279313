import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Message } from "@rithe/form"
import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import appConfig from "../../../configs/appConfig"
import announcementApi from "../../../services/system/apis/announcementApi"
import userApi from "../../../services/system/apis/userApi"
import { AnnouncementPublishTarget } from "../../../services/system/enums/AnnouncementPublishTarget"
import { AnnouncementStatus } from "../../../services/system/enums/AnnouncementStatus"
import { AnnouncementType } from "../../../services/system/enums/AnnouncementType"
import cbdsApi from "../../../services/systemmaster/apis/cbdsApi"
import errorToNotification from "../../../utils/axios/errorToNotification"
import responseToNotification from "../../../utils/axios/responseToNotification"
import thunkAxiosInstance from "../../../utils/axios/thunkAxiosInstance"
import { applicationActions } from "../../Application/applicationSlice"

type Mode = 'CREATE' | 'EDIT' | 'VIEW'

interface Announcement {
    id: string,
    type: AnnouncementType,
    title: string,
    summary: string,
    content: string,
    publishAt: number | null,
    expireAt: number | null,
    publishToAll: boolean,
    receiveUserIds: string[],
    receiveCustomerIds: string[],
    receiveSupplierIds: string[],
    receiveBuIds: string[],
    receiveDcIds: string[],
    status: AnnouncementStatus,
}

interface User {
    id: string,
    loginId: string,
    name: string,
}

interface Cbds {
    id: string,
    uid: string,
    type: number,
    code: string,
    name: string,
}

interface MLS261State {
    ui: {
        mode: Mode,
        visitedMap: {
            title: boolean,
            type: boolean,
            summary: boolean,
            content: boolean,
            publishAt: boolean,
            expireAt: boolean,
            recipients: boolean,
        },
        messagesMap: {
            title: Message[],
            type: Message[],
            summary: Message[],
            content: Message[],
            publishAt: Message[],
            expireAt: Message[],
            recipients: Message[],
        },
    },
    domain: {
        announcement: Announcement,
        users: User[],
        cbdss: Cbds[],
    },
}

const emptyAnnouncement = {
    id: '',
    title: '',
    type: AnnouncementType.NEW,
    summary: '',
    content: '',
    publishAt: 0,
    expireAt: 0,
    publishToAll: true,
    receiveUserIds: [],
    receiveCustomerIds: [],
    receiveSupplierIds: [],
    receiveBuIds: [],
    receiveDcIds: [],
    status: AnnouncementStatus.DRAFT,
}

const initialState: MLS261State = {
    ui: {
        mode: 'VIEW',
        visitedMap: {
            title: false,
            type: false,
            summary: false,
            content: false,
            publishAt: false,
            expireAt: false,
            recipients: false,
        },
        messagesMap: {
            title: [],
            type: [],
            summary: [],
            content: [],
            publishAt: [],
            expireAt: [],
            recipients: [],
        },
    },
    domain: {
        announcement: emptyAnnouncement,
        users: [],
        cbdss: [],
    },
}

const pageInit = createAsyncThunk<void, { mode: Mode, id?: string }>('mls261/pageInit', async ({ mode, id = '' }, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)

    thunk.dispatch(actions.setMode(mode))
    thunk.dispatch(actions.setAllVisited(false))
    thunk.dispatch(actions.clearAllMessages())
    thunk.dispatch(applicationActions.addMask())
    Promise.all([
        mode === 'CREATE' ? Promise.resolve(null) : announcementApi.get(axiosInstance, id).then(response => response.data),
        userApi.list(axiosInstance, { active: true }).then(response => response.data),
        cbdsApi.getActiveCompanyByType(axiosInstance, [1, 2, 3, 4]).then(response => response.data)
    ]).then(([announcement, users, cbdss]) => {
        thunk.dispatch(actions.setAnnouncement(announcement ? {
            id: announcement.id,
            type: announcement.type,
            title: announcement.title,
            summary: announcement.summary,
            content: announcement.content,
            publishAt: announcement.publishAt,
            expireAt: announcement.expireAt,
            publishToAll: announcement.publishStrategy.toAll,
            receiveUserIds: announcement.publishStrategy.receiveUserIds,
            receiveCustomerIds: announcement.publishStrategy.receiveCustomerIds,
            receiveSupplierIds: announcement.publishStrategy.receiveSupplierIds,
            receiveBuIds: announcement.publishStrategy.receiveBuIds,
            receiveDcIds: announcement.publishStrategy.receiveDcIds,
            status: announcement.status,
        } : emptyAnnouncement))
        thunk.dispatch(actions.setUsers(users.data!.map(user => ({
            id: user.id,
            name: user.name,
            loginId: user.account.loginId,
        }))))
        thunk.dispatch(actions.setCbdss(cbdss!.map(cbds => ({
            id: cbds.cbdsId + '',
            uid: cbds.cbdsUid,
            name: cbds.cbdsName,
            type: cbds.cbdsType,
            code: cbds.cbdsCode,
        }))))
    }).catch(error => {
        thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
    }).then(() => {
        thunk.dispatch(applicationActions.removeMask())
    })
})

const clickCreate = createAsyncThunk<void, (id: string) => void>('mls261/clickCreate', (successCallback, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const announcement = select(thunk.getState()).domain.announcement
    const messagesMap = validate(announcement)
    if (Object.values(messagesMap).flat().length > 0) {
        thunk.dispatch(actions.setAllMessages(messagesMap))
        thunk.dispatch(actions.setAllVisited(true))
        thunk.dispatch(applicationActions.pushError({ title: { code: 'inputError' }, messages: { code: 'w0345' } }))
    } else {
        thunk.dispatch(applicationActions.addMask())
        announcementApi.create(axiosInstance, {
            type: announcement.type,
            title: announcement.title,
            summary: announcement.summary,
            contentType: 'text/plain',
            content: announcement.content,
            publishAt: announcement.publishAt!,
            expireAt: announcement.expireAt!,
            publishStrategy: {
                targets: [AnnouncementPublishTarget.WEBPAGE, AnnouncementPublishTarget.EMAIL],
                toAll: announcement.publishToAll,
                receiveUserIds: announcement.receiveUserIds,
                receiveCustomerIds: announcement.receiveCustomerIds,
                receiveSupplierIds: announcement.receiveSupplierIds,
                receiveBuIds: announcement.receiveBuIds,
                receiveDcIds: announcement.receiveDcIds,
            }
        }).then(response => {
            thunk.dispatch(applicationActions.pushNotification(responseToNotification(response)))
            successCallback(response.data.id)
        }).catch(error => {
            thunk.dispatch(applicationActions.pushError(errorToNotification(error)))
        }).then(() => {
            thunk.dispatch(applicationActions.removeMask())
        })
    }
})

const clickEdit = createAsyncThunk<void, (id: string) => void>('mls261/clickEdit', (successCallback, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const announcement = select(thunk.getState()).domain.announcement
    const messagesMap = validate(announcement)
    if (Object.values(messagesMap).flat().length) {
        thunk.dispatch(actions.setAllMessages(messagesMap))
        thunk.dispatch(actions.setAllVisited(true))
        thunk.dispatch(applicationActions.pushError({ title: { code: 'inputError' }, messages: { code: 'w0345' } }))
    } else {
        thunk.dispatch(applicationActions.addMask())
        announcementApi.edit(axiosInstance, {
            id: announcement.id,
            type: announcement.type,
            title: announcement.title,
            summary: announcement.summary,
            contentType: 'text/plain',
            content: announcement.content,
            publishAt: announcement.publishAt!,
            expireAt: announcement.expireAt!,
            publishStrategy: {
                targets: [AnnouncementPublishTarget.WEBPAGE, AnnouncementPublishTarget.EMAIL],
                toAll: announcement.publishToAll,
                receiveUserIds: announcement.receiveUserIds,
                receiveCustomerIds: announcement.receiveCustomerIds,
                receiveSupplierIds: announcement.receiveSupplierIds,
                receiveBuIds: announcement.receiveBuIds,
                receiveDcIds: announcement.receiveDcIds,
            }
        }).then(response => {
            thunk.dispatch(applicationActions.pushNotification(responseToNotification(response)))
            successCallback(response.data.id)
        }).catch(error => {
            thunk.dispatch(applicationActions.pushError(errorToNotification(error)))
        }).then(() => {
            thunk.dispatch(applicationActions.removeMask())
        })
    }
})

const clickConfirm = createAsyncThunk<void, (id: string) => void>('mls261/clickConfirm', (successCallback, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const announcement = select(thunk.getState()).domain.announcement
    const messagesMap = validate(announcement)
    if (Object.values(messagesMap).flat().length) {
        thunk.dispatch(actions.setAllMessages(messagesMap))
        thunk.dispatch(actions.setAllVisited(true))
        thunk.dispatch(applicationActions.pushError({ title: { code: 'inputError' }, messages: { code: 'w0345' } }))
    } else {
        thunk.dispatch(applicationActions.addMask())
        announcementApi.edit(axiosInstance, {
            id: announcement.id,
            type: announcement.type,
            title: announcement.title,
            summary: announcement.summary,
            contentType: 'text/plain',
            content: announcement.content,
            publishAt: announcement.publishAt!,
            expireAt: announcement.expireAt!,
            publishStrategy: {
                targets: [AnnouncementPublishTarget.WEBPAGE, AnnouncementPublishTarget.EMAIL],
                toAll: announcement.publishToAll,
                receiveUserIds: announcement.receiveUserIds,
                receiveCustomerIds: announcement.receiveCustomerIds,
                receiveSupplierIds: announcement.receiveSupplierIds,
                receiveBuIds: announcement.receiveBuIds,
                receiveDcIds: announcement.receiveDcIds,
            }
        }).then(() => {
            return announcementApi.confirm(axiosInstance, announcement.id)
        }).then(response => {
            thunk.dispatch(applicationActions.pushNotification(responseToNotification(response)))
            successCallback(response.data.id)
        }).catch(error => {
            thunk.dispatch(applicationActions.pushError(errorToNotification(error)))
        }).then(() => {
            thunk.dispatch(applicationActions.removeMask())
        })
    }
})

const clickAdjust = createAsyncThunk<void, (id: string) => void>('mls261/clickAdjust', (successCallback, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const announcement = select(thunk.getState()).domain.announcement
    const messagesMap = validate(announcement)
    if (Object.values(messagesMap).flat().length) {
        thunk.dispatch(actions.setAllMessages(messagesMap))
        thunk.dispatch(actions.setAllVisited(true))
        thunk.dispatch(applicationActions.pushError({ title: { code: 'inputError' }, messages: { code: 'w0345' } }))
    } else {
        thunk.dispatch(applicationActions.addMask())
        announcementApi.adjust(axiosInstance, {
            id: announcement.id,
            expireAt: announcement.expireAt!,
        }).then(response => {
            thunk.dispatch(applicationActions.pushNotification(responseToNotification(response)))
            successCallback(response.data.id)
        }).catch(error => {
            thunk.dispatch(applicationActions.pushError(errorToNotification(error)))
        }).then(() => {
            thunk.dispatch(applicationActions.removeMask())
        })
    }
})

const clickCancel = createAsyncThunk<void, (id: string) => void>('mls261/clickCancel', (successCallback, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const announcement = select(thunk.getState()).domain.announcement
    thunk.dispatch(applicationActions.addMask())
    announcementApi.cancel(axiosInstance, announcement.id).then(response => {
        thunk.dispatch(applicationActions.pushNotification(responseToNotification(response)))
        successCallback(response.data.id)
    }).catch(error => {
        thunk.dispatch(applicationActions.pushError(errorToNotification(error)))
    }).then(() => {
        thunk.dispatch(applicationActions.removeMask())
    })
})

const clickDelete = createAsyncThunk<void, (id: string) => void>('mls261/clickDelete', (successCallback, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const announcement = select(thunk.getState()).domain.announcement
    thunk.dispatch(applicationActions.addMask())
    announcementApi.delete(axiosInstance, announcement.id).then(response => {
        thunk.dispatch(applicationActions.pushNotification(responseToNotification(response)))
        successCallback(response.data.id)
    }).catch(error => {
        thunk.dispatch(applicationActions.pushError(errorToNotification(error)))
    }).then(() => {
        thunk.dispatch(applicationActions.removeMask())
    })
})

function validate(announcement: Announcement) {
    const messagesMap: MLS261State['ui']['messagesMap'] = {
        title: [],
        type: [],
        summary: [],
        content: [],
        publishAt: [],
        expireAt: [],
        recipients: [],
    }
    if (announcement.status === AnnouncementStatus.DRAFT
        || announcement.status === AnnouncementStatus.CONFIRMED) {
        if (!announcement.title) {
            messagesMap['title'].push({
                field: 'title',
                level: 'error',
                value: <FormattedMessage id="error.title.required" />
            })
        } else if (announcement.title.length > 255) {
            messagesMap['title'].push({
                field: 'title',
                level: 'error',
                value: <FormattedMessage id="error.title.maxLength" values={{ length: 255 }} />
            })
        }
        if (!announcement.type) {
            messagesMap['type'].push({
                field: 'type',
                level: 'error',
                value: <FormattedMessage id="error.type.required" />
            })
        }
        if (!announcement.summary) {
            messagesMap['summary'].push({
                field: 'summary',
                level: 'error',
                value: <FormattedMessage id="error.summary.required" />
            })
        } else if (announcement.summary.length > 65535) {
            messagesMap['summary'].push({
                field: 'summary',
                level: 'error',
                value: <FormattedMessage id="error.summary.maxLength" values={{ length: 65535 }} />
            })
        }
        if (!announcement.content) {
            messagesMap['content'].push({
                field: 'content',
                level: 'error',
                value: <FormattedMessage id="error.content.required" />
            })
        } else if (announcement.content.length > 65535) {
            messagesMap['content'].push({
                field: 'content',
                level: 'error',
                value: <FormattedMessage id="error.content.maxLength" values={{ length: 65535 }} />
            })
        }
        if (!announcement.publishAt) {
            messagesMap['publishAt'].push({
                field: 'publishAt',
                level: 'error',
                value: <FormattedMessage id="error.publishAt.required" />
            })
        } else if (announcement.publishAt < new Date().getTime()) {
            messagesMap['publishAt'].push({
                field: 'publishAt',
                level: 'error',
                value: <FormattedMessage id="error.publishAt.lessThanNow" />
            })
        }
        if (!announcement.expireAt) {
            messagesMap['expireAt'].push({
                field: 'expireAt',
                level: 'error',
                value: <FormattedMessage id="error.expireAt.required" />
            })
        } else if (announcement.publishAt && announcement.expireAt <= announcement.publishAt) {
            messagesMap['expireAt'].push({
                field: 'expireAt',
                level: 'error',
                value: <FormattedMessage id="error.expireAt.lessThanPublishAt" />
            })
        }
        if (!announcement.publishToAll
            && announcement.receiveUserIds.length === 0
            && announcement.receiveCustomerIds.length === 0
            && announcement.receiveSupplierIds.length === 0
            && announcement.receiveBuIds.length === 0
            && announcement.receiveDcIds.length === 0) {
            messagesMap['recipients'].push({
                field: 'recipients',
                level: 'error',
                value: <FormattedMessage id="error.recipients.required" />
            })
        }
    } else if (announcement.status === AnnouncementStatus.PUBLISHED) {
        if (!announcement.expireAt) {
            messagesMap['expireAt'].push({
                field: 'expireAt',
                level: 'error',
                value: <FormattedMessage id="error.expireAt.required" />
            })
        } else if (announcement.publishAt && announcement.expireAt <= announcement.publishAt) {
            messagesMap['expireAt'].push({
                field: 'expireAt',
                level: 'error',
                value: <FormattedMessage id="error.expireAt.lessThanPublishAt" />
            })
        }
    }
    return messagesMap
}

const slice = createSlice({
    name: 'mls261',
    initialState,
    reducers: {
        setMode: (state, action: PayloadAction<Mode>) => {
            state.ui.mode = action.payload
        },
        setFieldVisited: (state, action: PayloadAction<{ field: keyof MLS261State['ui']['visitedMap'], visited: boolean }>) => {
            state.ui.visitedMap[action.payload.field] = action.payload.visited
        },
        setAllVisited: (state, action: PayloadAction<boolean>) => {
            state.ui.visitedMap = {
                title: action.payload,
                type: action.payload,
                summary: action.payload,
                content: action.payload,
                publishAt: action.payload,
                expireAt: action.payload,
                recipients: action.payload,
            }
        },
        setAllMessages: (state, action: PayloadAction<MLS261State['ui']['messagesMap']>) => {
            state.ui.messagesMap = action.payload
        },
        clearAllMessages: (state) => {
            state.ui.messagesMap = {
                title: [],
                type: [],
                summary: [],
                content: [],
                publishAt: [],
                expireAt: [],
                recipients: [],
            }
        },
        setAnnouncement: (state, action: PayloadAction<Announcement>) => {
            state.domain.announcement = action.payload
        },
        setUsers: (state, action: PayloadAction<User[]>) => {
            state.domain.users = action.payload
        },
        setCbdss: (state, action: PayloadAction<Cbds[]>) => {
            state.domain.cbdss = action.payload
        },
        setTitle: (state, action: PayloadAction<string>) => {
            state.domain.announcement.title = action.payload
            state.ui.messagesMap = validate(state.domain.announcement)
        },
        setType: (state, action: PayloadAction<AnnouncementType>) => {
            state.domain.announcement.type = action.payload
            state.ui.messagesMap = validate(state.domain.announcement)
        },
        setSummary: (state, action: PayloadAction<string>) => {
            state.domain.announcement.summary = action.payload
            state.ui.messagesMap = validate(state.domain.announcement)
        },
        setContent: (state, action: PayloadAction<string>) => {
            state.domain.announcement.content = action.payload
            state.ui.messagesMap = validate(state.domain.announcement)
        },
        setPublishAt: (state, action: PayloadAction<number | null>) => {
            state.domain.announcement.publishAt = action.payload
            state.ui.messagesMap = validate(state.domain.announcement)
        },
        setExpireAt: (state, action: PayloadAction<number | null>) => {
            state.domain.announcement.expireAt = action.payload
            state.ui.messagesMap = validate(state.domain.announcement)
        },
        setPublishToAll: (state, action: PayloadAction<boolean>) => {
            state.domain.announcement.publishToAll = action.payload
            if (state.domain.announcement.publishToAll) {
                state.domain.announcement.receiveUserIds = []
                state.domain.announcement.receiveCustomerIds = []
                state.domain.announcement.receiveSupplierIds = []
                state.domain.announcement.receiveBuIds = []
                state.domain.announcement.receiveDcIds = []
            }
            state.ui.messagesMap = validate(state.domain.announcement)
        },
        setReceiveUserIds: (state, action: PayloadAction<string[]>) => {
            state.domain.announcement.receiveUserIds = action.payload
            state.ui.messagesMap = validate(state.domain.announcement)
        },
        setReceiveCustomerIds: (state, action: PayloadAction<string[]>) => {
            state.domain.announcement.receiveCustomerIds = action.payload
            state.ui.messagesMap = validate(state.domain.announcement)
        },
        setReceiveSupplierIds: (state, action: PayloadAction<string[]>) => {
            state.domain.announcement.receiveSupplierIds = action.payload
            state.ui.messagesMap = validate(state.domain.announcement)
        },
        setReceiveBuIds: (state, action: PayloadAction<string[]>) => {
            state.domain.announcement.receiveBuIds = action.payload
            state.ui.messagesMap = validate(state.domain.announcement)
        },
        setReceiveDcIds: (state, action: PayloadAction<string[]>) => {
            state.domain.announcement.receiveDcIds = action.payload
            state.ui.messagesMap = validate(state.domain.announcement)
        },
    }
})

const actions = {
    ...slice.actions,
    pageInit,
    clickCreate,
    clickEdit,
    clickConfirm,
    clickAdjust,
    clickCancel,
    clickDelete,
}

function select(state: any) {
    return state[appConfig.appFullName][slice.name] as MLS261State
}

export const mls261Slice = slice
export const mls261Actions = actions
export function useMLS261Selector<R>(selector: (state: MLS261State) => R, equalityFn?: (left: R, right: R) => boolean) {
    return useSelector<any, R>(state => selector(select(state)), equalityFn)
}