import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { Projects } from "../../../utils/Projects"
import { ContractVo } from "../../localmaster/models/ContractVo"
import { PageResultEntity, pageResultEntityFromJson } from "../../utils/PageResultEntity"
import { ContractFactor, contractFactorToJson } from "../models/ContractFactor"
import { ContractHeaderResult, contractHeaderResultFromJson, contractHeaderResultToJson } from "../models/ContractHeaderResult"
import { ContractPartsResult, contractPartsResultFromJson } from "../models/ContractPartsResult"
import { ContractPeopleInfoResult, contractPeopleInfoResultFromJson } from "../models/ContractPeopleInfoResult"
import { ContractResult, contractResultFromJson } from "../models/ContractResult"
import { ContractVoFromJson } from "../models/ContractVo"
import { GetAllContractVo, GetAllContractVoToJson } from "../models/GetAllContractVo"

export const useGetContractList = () => {
    const url = '/lcbm-local-master-api/api/getContractList'
    const payloadProject = useCallback(contractFactorToJson, [])
    const resultProject = useCallback(pageResultEntityFromJson(contractResultFromJson), [])
    return usePost<ContractFactor, PageResultEntity<ContractResult>>(url, payloadProject, resultProject)
}

export const useGetContractPeopleInfo = () => {
    const url = '/lcbm-local-master-api/api/getContractPeopleInfo'
    const payloadProject = useCallback((obj: { isFromTools: boolean }) => obj.isFromTools, [])
    const resultProject = useCallback(contractPeopleInfoResultFromJson, [])
    return usePost<{ isFromTools: boolean }, ContractPeopleInfoResult>(url, payloadProject, resultProject)
}

export const useGetContractPeopleInfoAll = () => {
    const url = '/lcbm-local-master-api/api/getContractPeopleInfoAll'
    const payloadProject = useCallback((obj: { isFromTools: boolean }) => obj.isFromTools, [])
    const resultProject = useCallback(contractPeopleInfoResultFromJson, [])
    return usePost<{ isFromTools: boolean }, ContractPeopleInfoResult>(url, payloadProject, resultProject)
}

export const useGetContractDetailHeader = () => {
    const url = '/lcbm-local-master-api/api/getContractDetailHeader'
    const payloadProject = useCallback((obj: { contractId: number }) => obj.contractId, [])
    const resultProject = useCallback(contractHeaderResultFromJson, [])
    return usePost<{ contractId: number }, ContractHeaderResult>(url, payloadProject, resultProject)
}

export const useGetContractDetailListByContractId = () => {
    const url = '/lcbm-local-master-api/api/getContractDetailListByContractId'
    const payloadProject = useCallback((obj: { contractId: number }) => obj.contractId, [])
    const resultProject = useCallback(Projects.array(contractPartsResultFromJson), [])
    return usePost<{ contractId: number }, ContractPartsResult[]>(url, payloadProject, resultProject)
}

export const useSaveContract = () => {
    const url = '/lcbm-local-master-api/api/saveContract'
    const payloadProject = useCallback(contractHeaderResultToJson, [])
    const resultProject = useCallback((contractId: number) => contractId, [])
    return usePost<ContractHeaderResult, number>(url, payloadProject, resultProject)
}

export const useTerminateContract = () => {
    const url = '/lcbm-local-master-api/api/terminateContract'
    const payloadProject = useCallback((obj: { contractId: number }) => obj, [])
    const resultProject = useCallback((json: { requestId: number }) => json, [])
    return usePost<{ contractId: number, reason: string }, { requestId: number }>(url, payloadProject, resultProject)
}

export const useGetAllContracts = () => {
    const url = '/lcbm-local-master-api/api/getAllContracts'
    const payloadProject = useCallback(GetAllContractVoToJson, [])
    const resultProject = useCallback(Projects.array(ContractVoFromJson), [])
    return usePost<GetAllContractVo, ContractVo[]>(url, payloadProject, resultProject)
}