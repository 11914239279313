import { Project } from "../../../utils/Project"

export type ExternalTemplateList = Required & Partial<Optional>
export type ExternalTemplateListJson = JsonRequired & Partial<JsonOptional>
export const externalTemplateListToJson: Project<ExternalTemplateList, ExternalTemplateListJson> = obj => ({
    ...obj,
})
export const externalTemplateListFromJson: Project<ExternalTemplateListJson, ExternalTemplateList> = json => ({
    ...json,
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
}

interface JsonRequired {
}

interface JsonOptional {
    templateId: string,
    companyUid: string,
    function: string,
    identifier: string,
    description: string,
    createdBy: string,
    createdDate: number
    updatedBy: string,
    updatedDate: number,
    version: number,
    status: number
}



