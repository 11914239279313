import { IconButton, Tooltip } from '@material-ui/core'
import { TableRow } from '@rithe/data-grid'
import { DataGridRowActionProps } from '@rithe/data-grid/dist/components/basic/DataGridRowAction'
import React from 'react'
import { useNavigate } from "react-router-dom"
import { Access } from '../../Access/Access'

interface RedirectRowActionProps {
    access?: string,
    power?: boolean,
    title: NonNullable<React.ReactNode>,
    icon: NonNullable<React.ReactNode>,
    path: string | ((tableRow: TableRow) => string),
    state?: Record<string, unknown> | ((tableRow: TableRow) => Record<string, unknown>)
}

export const RedirectRowAction = (props: RedirectRowActionProps & DataGridRowActionProps) => {
    const { access, power, title, icon, tableRow } = props
    const navigate = useNavigate()

    const path = typeof props.path === 'string' ? props.path : props.path(tableRow)
    const state = typeof props.state !== 'function' ? props.state : props.state(tableRow)
    const action = <Tooltip title={title}>
        <IconButton onClick={() => navigate(path, { state })}>
            {icon}
        </IconButton>
    </Tooltip>
    return access ? <Access access={access} power={power}>{action}</Access> : action
}