import {Button, Dialog, DialogContent, DialogContentText, DialogTitle, Typography, makeStyles} from "@material-ui/core"
import {Form, Message, StringItem} from "@rithe/form"
import {SetStateAction, memo, useCallback, useMemo, useState} from "react"
import {FormattedMessage, useIntl} from "react-intl"
import {useNavigate} from "react-router-dom"
import {CallbackViewAction} from "../../../components/Action/CallbackViewAction"
import {SectionCard} from "../../../components/Card/SectionCard"
import {SectionCardContent} from "../../../components/Card/SectionCardContent"
import {SectionCardHeader} from "../../../components/Card/SectionCardHeader"
import {View} from "../../../components/View/View"
import {UserQrInfoResponse} from "../../../services/master/models/UserQrInfoResponse"
import {useSaveMfa} from "../../../services/system/apis/userMfaSettingApi"
import {useFieldChecker, useFormValidater} from "../../../utils/ValidatorUtils"

interface MSESF022PcUiProps {
  code: string
  setCode: React.Dispatch<React.SetStateAction<string>>,
  qrInfo: UserQrInfoResponse
  setQrInfo: React.Dispatch<React.SetStateAction<UserQrInfoResponse>>,
  dialog: boolean,
  setDialog: React.Dispatch<React.SetStateAction<boolean>>,
}

export const MSESF022PcUi = (props: MSESF022PcUiProps) => {
  const {code, setCode, dialog, setDialog, qrInfo} = props
  const intl = useIntl()
  const [messages, setMessages] = useState<Message[]>([])
  const [keyView, setKeyView] = useState<boolean>(false)

  const action = [
    <SaveAction code={code} setMessages={setMessages} />,
    <BackAction url={'/user-setting'} />
  ]

  const handleSetupKeyClick = () => {
    setKeyView(!keyView)
  };

  return <View actions={action}>
    <SectionCard key={1}>
      <SectionCardHeader
        title={intl.formatMessage({id: 'msesf021.authenticatorApp'})}
        subtitle={intl.formatMessage({id: 'msesf021.authenticatorDescription'})} />
      <SectionCardContent>

        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div style={{marginBottom: 10}}>
            <FormattedMessage id="msesf022.bindDescription" />
          </div>
          <div style={{marginBottom: 10}}>
            <FormattedMessage id="msesf022.bindWarning" />
          </div>
          <div style={{marginBottom: 10, fontWeight: 'bold'}}>
            <FormattedMessage id="msesf022.scanQr" />
          </div>
          <div>
            <FormattedMessage id="msesf022.scanQrDescription" />
          </div>
          <div>
            <Base64Image base64={qrInfo.qrCode!} />
          </div>
          <div style={{marginTop: 10}}>
            <FormattedMessage
              id="msesf022.inputSetupKey"
              defaultMessage="Unable to scan? {clickHere} to get the key to manually configure your authenticator app."
              values={{
                clickHere: <span onClick={handleSetupKeyClick} style={{cursor: "pointer", color: 'blue', textDecoration: 'underline'}}>
                  <FormattedMessage id="msesf022.clickHere"/>
                </span>
              }}
            />
          </div>
          { keyView &&
            <div style={{marginTop: 10}}>
            <SetupKeyTable secret={qrInfo.secretKey!} />
            </div>
          }
          <div style={{marginTop: 30, fontWeight: 'bold'}}>
            <FormattedMessage id="msesf022.verifyCode" />
          </div>
          <div style={{marginTop: 10}}>
            <FormattedMessage id="msesf022.verifyCodeInput" />
          </div>
          <div style={{marginBottom: 30, marginTop: 5}}>
            <VerifyCodeData code={code} setCode={setCode} messages={messages} setMessages={setMessages} />
          </div>
        </div>
      </SectionCardContent>
    </SectionCard>
    <MessageDialog dialog={dialog} setDialog={setDialog} />
  </View>
}


const SetupKeyTable = (props: {
  secret: string
}) => {
  const styles = useStyles()
  const {secret} = props
  return <>
    <div className={styles.secretKey} >
      {secret}
    </div>
  </>
}

const VerifyCodeData = ({code, setCode, messages, setMessages}: {
  code: string
  setCode: React.Dispatch<SetStateAction<string>>,
  messages: Message[],
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>
}) => {
  const fields = useMemo(() => getFormCheckFields(true), [])
  const filedCheck = useFieldChecker(fields, setMessages)
  return <Form data={code} setData={setCode} labelDisplay="block" helperDisplay="tooltip" columnCount={12} messages={messages} setMessages={setMessages} ensure={filedCheck}>
    <StringItem field="code" label="" colSpan={2} rowSpan={2} placeholder="xxxxxx" />
  </Form>
}
const Base64Image = (props: {
  base64: string
}) => {
  const {base64} = props
  return <img src={`data:image/png;base64,${base64}`} alt="Base64 Image" />;
};

const getFormCheckFields = (isIssue: boolean) => {
  return ({
    code: {
      labelId: 'code', required: true, length: {min:6, max: 6},
    }
  })
}

const MessageDialog = memo((props: {
  dialog: boolean,
  setDialog: React.Dispatch<React.SetStateAction<boolean>>,
}) => {
  const {dialog, setDialog} = props
  const onClose = useCallback(() => {
    setDialog(false)
  }, [setDialog])

  return <Dialog open={dialog} onClose={onClose} maxWidth="md" style={{flex: 1}} BackdropProps={{style: {backgroundColor: 'rgba(0, 0, 0, 0.2)'}}}>
    <DialogTitle style={{textAlign: 'center', marginBottom: -10}}><Typography variant="h3"><FormattedMessage id="msesf022.messageDialogTitle" /></Typography></DialogTitle>
    <DialogContent style={{textAlign: 'center'}}>
      <DialogContentText>
        <FormattedMessage id="msesf022.messageDialogContent" />
      </DialogContentText>
      <Button
        variant={'outlined'}
        color="secondary"
        onClick={onClose}
        style={{
          width: '30%',
        }}>
        <FormattedMessage id="ok" />
      </Button>
    </DialogContent>
  </Dialog>
})

const SaveAction = memo((props: {
  code: string,
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
}) => {
  const {code, setMessages} = props
  const [disabled, setDisabled] = useState<boolean>(false)
  const saveMfa = useSaveMfa()
  const navigate = useNavigate()

  const fields = useMemo(() => getFormCheckFields(true), [])
  const formValidate = useFormValidater(setMessages, fields)

  const onclickSave = useCallback(() => {
    if (formValidate(code)) {
      setDisabled(true)
      saveMfa({code: code}, {serialized: true}).then(() => {
        navigate(`/user-setting/recoveryCodes`)
      }).finally(() => {
        setDisabled(false)
      })
    }
  }, [saveMfa, code, navigate])
  return <CallbackViewAction disabled={disabled} callback={onclickSave} title={<FormattedMessage id="confirm" />} />
})

const BackAction = (props: {url: string}) => {
  const {url} = props
  const navigate = useNavigate()
  const returnBeforePage = useCallback(() => {
    navigate(url)
  }, [navigate, url])
  return <CallbackViewAction outlined callback={returnBeforePage} title={<FormattedMessage id="cancel" />} />
}


const useStyles = makeStyles(theme => ({
  secretKey: {
    width: 230,
    height: 40,
    fontWeight: 'bold',
    fontSize: '15px',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    backgroundColor: 'white',
    paddingLeft:20,
    borderRadius: 10, 
  }
}))
