import { Project } from "@rithe/utils";
import { CargoRole } from "../enums/CargoRole";

export interface EditCargoStatusSetting {
    cargoStatusSettingId: number,
    cargoStatusSettingName: string,
    customerId: number,
    role: CargoRole,
    contractIds: number[],
    cargoStatuss: string[],
    confirmed: boolean,
}

interface EditCargoStatusSettingJson {
    cargoStatusSettingId: number,
    cargoStatusSettingName: string,
    customerId: number,
    role: number,
    contractIds: number[],
    cargoStatuss: string[],
    confirmed: boolean,
}

export const editCargoStatusSettingToJson: Project<EditCargoStatusSetting, EditCargoStatusSettingJson> = data => data
