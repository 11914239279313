import { Project } from "../../../utils/Project"
import { OrderType } from "../../order/enums/OrderType"
import { SimulatedType } from "../../order/enums/SimulatedType"
import { dateFromJson } from "../../utils/deserializer"


export interface OrderResult {
    openCount?: number,
    lastOrderNo?: string,
    nextRegularDate?: Date,
    orderList?: OrderDetail[]
}
interface OrderResultJson {
    openCount?: number,
    lastOrderNo?: string,
    nextRegularDate?: string,
    orderList?: OrderDetailJson[]
}
export const orderResultFromJson: Project<OrderResultJson, OrderResult> = json => ({
    ...json,
    nextRegularDate: dateFromJson(json.nextRegularDate),
    orderList: json.orderList?.map(orderDetailFromJson),
})



export interface OrderDetail {
    orderId: number,
    orderNo: string,
    orderDate: Date,
    orderType: OrderType,
    partsList: OrderParts[],
}
interface OrderDetailJson {
    orderId: number,
    orderNo: string,
    orderDate: string,
    orderType: number,
    partsList: OrderPartsJson[],
}
const orderDetailFromJson: Project<OrderDetailJson, OrderDetail> = json => ({
    ...json,
    orderDate: dateFromJson(json.orderDate),
    partsList: json.partsList?.map(orderPartsJson),
})




interface OrderParts {
    partsId: number,
    unitPartsNo: string,
    unitPartsName: string,
    expCtry: string,
    orderQty: number,
    orderId: number,
    customerCode: string,
    planList: PartsPlan[]
}
interface OrderPartsJson {
    partsId: number,
    unitPartsNo: string,
    unitPartsName: string,
    expCtry: string,
    orderQty: number,
    orderId: number,
    customerCode: string,
    planList: PartsPlanJson[]
}
const orderPartsJson: Project<OrderPartsJson, OrderParts> = json => ({
    ...json,
    planList: json.planList?.map(partsPlanFromJson),
})




interface PartsPlan {
    orderId: number,
    partsId: number,
    crdDate: Date,
    lastCrdDate?: Date,
    crdQty: number,
    totalReceivedQty: number,
    simulationPlanList?: Simulation[],
}
interface PartsPlanJson {
    orderId: number,
    partsId: number,
    crdDate: string,
    lastCrdDate?: string,
    crdQty: number,
    totalReceivedQty: number,
    simulationPlanList?: SimulationJson[],
}
const partsPlanFromJson: Project<PartsPlanJson, PartsPlan> = json => ({
    ...json,
    crdDate: dateFromJson(json.crdDate),
    lastCrdDate: dateFromJson(json.lastCrdDate),
    simulationPlanList: json.simulationPlanList?.map(simulationFromJson),
})




interface Simulation {
    orderId: number,
    partsId: number,
    crdDate: Date,
    fromCompany: string,
    toCompany: string,
    simulatedType: SimulatedType,
    estimatedCrdDate: Date,
    simulatedQty: number,
}
interface SimulationJson {
    orderId: number,
    partsId: number,
    crdDate: string,
    fromCompany: string,
    toCompany: string,
    simulatedType: number,
    estimatedCrdDate: string,
    simulatedQty: number,
}
const simulationFromJson: Project<SimulationJson, Simulation> = json => ({
    ...json,
    crdDate: dateFromJson(json.crdDate),
    estimatedCrdDate: dateFromJson(json.estimatedCrdDate),
})
