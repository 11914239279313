import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { dateFromJson } from "../../utils/deserializer"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"
import { ContractRequestType } from "../enums/ContaractRequestType"
import { ContractRequestStatus } from "../enums/ContractRequestStatus"

export type RequestFactor = Partial<Optional>
export type RequestFactorJson = Partial<JsonOptional>
export const requestFactorToJson: Project<RequestFactor, RequestFactorJson> = obj => ({
    ...obj,
    requestDateStart: dateToJson(obj.requestDateStart),
    requestDateEnd: dateToJson(obj.requestDateEnd),
    submittedDateStart: dateToJson(obj.submittedDateStart),
    submittedDateEnd: dateToJson(obj.submittedDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
})

export const requestFactorFromJson: Project<RequestFactorJson, RequestFactor> = json => ({
    ...json,
    requestDateStart: dateFromJson(json.requestDateStart),
    requestDateEnd: dateFromJson(json.requestDateEnd),
    submittedDateStart: dateFromJson(json.submittedDateStart),
    submittedDateEnd: dateFromJson(json.submittedDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page),
})


interface Optional extends Omit<JsonOptional, 'requestDateStart' | 'requestDateEnd' | 'submittedDateStart' | 'submittedDateEnd'> {
    statusList: ContractRequestStatus[],
    requestTypeList: ContractRequestType[],
    requestDateStart: Date,
    requestDateEnd: Date,
    submittedDateStart: Date,
    submittedDateEnd: Date,
    page: PageViewEntity,
}


interface JsonOptional {
    requestId: number,
    requestNo: string,
    requestType: number,
    description: string,
    requestIdList: number[],
    requestTypeList: number[],
    requestToList: string[],
    requestFromList: string[],
    requestDateStart: string,
    requestDateEnd: string,
    submittedDateStart: string,
    submittedDateEnd: string,
    requestBy: string,
    submittedBy: string,
    statusList: number[],
    page: PageViewEntityJson,
}

