import { Button, makeStyles, Typography } from "@material-ui/core";
import { Check, Close, PriorityHigh } from "@material-ui/icons";
import { condition } from "@rithe/utils";
import clsx from 'clsx';
import React, { ReactNode, useCallback, useLayoutEffect, useRef, useState } from "react";
import { useStepContext } from "./StepContext";

const DEFAULT_WIDTH = 32
const PADDING = 8

interface StepProps {
    anchorEl: HTMLElement,
    serialNumber?: number,
    variant?: 'normal' | 'success' | 'warning' | 'error' | 'info' | 'disabled',
    icon?: ReactNode,
    title: string,
}

export function Step(props: StepProps) {
    const { anchorEl, serialNumber, variant, icon, title } = props

    const { setTargetAnchorEl } = useStepContext()

    const onClick = useCallback(() => {
        setTargetAnchorEl(anchorEl)
    }, [anchorEl, setTargetAnchorEl])

    const [stretched, setStretched] = useState(false)
    const onMouseEnter = useCallback(() => {
        setStretched(true)
    }, [])
    const onMouseLeave = useCallback(() => {
        setStretched(false)
    }, [])

    const styles = useStyles()

    const styledClassName = condition()
        .case(variant === 'success').then(styles.success)
        .case(variant === 'warning').then(styles.warning)
        .case(variant === 'error').then(styles.error)
        .case(variant === 'info').then(styles.info)
        .case(variant === 'disabled').then(styles.disabled)
        .default(styles.normal)

    const iconPart = condition()
        .case(variant === 'success').then(<Check />)
        .case(variant === 'warning').then(<PriorityHigh />)
        .case(variant === 'error').then(<Close />)
        .case(!!icon && icon !== true).then(icon)
        .case(serialNumber !== undefined).then(<Typography variant="body1">{serialNumber}</Typography>)
        .default(null)
    const titlePart = stretched ? <Typography variant="body1">{title}</Typography> : null
    const dotPart = iconPart && titlePart ? <Typography variant="body1" style={{ marginRight: 8 }}></Typography> : null

    const ref = useRef<HTMLDivElement>(null)
    const [width, setWidth] = useState(32)
    useLayoutEffect(() => {
        if (stretched && ref.current) {
            const width = ref.current.getBoundingClientRect().width
            setWidth(width + PADDING * 2)
        } else {
            setWidth(DEFAULT_WIDTH)
        }
    }, [stretched])

    return <Button
        className={clsx(styles.root, stretched && styles.stretchedRoot, styledClassName)}
        style={{ width }}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
    >
        <div ref={ref} className={styles.measured}>
            {iconPart}
            {dotPart}
            {titlePart}
        </div>
    </Button>
}

const useStyles = makeStyles(theme => ({
    root: {
        flex: '0 0 auto',
        height: 32,
        minWidth: 0,
        borderRadius: 16,
        overflow: 'hidden',
        transition: `all ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
    },
    stretchedRoot: {
        borderRadius: theme.shape.borderRadius,
    },
    measured: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'nowrap',
        whiteSpace: 'nowrap',
    },
    success: {
        backgroundColor: theme.palette.success.main,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.success.light,
            color: 'white',
        },
    },
    warning: {
        backgroundColor: theme.palette.warning.main,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.warning.light,
            color: 'white',
        },
    },
    error: {
        backgroundColor: theme.palette.error.main,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.error.light,
            color: 'white',
        },
    },
    info: {
        backgroundColor: theme.palette.info.main,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.info.light,
            color: 'white',
        },
    },
    disabled: {
        backgroundColor: '#EAEFF2',
        color: theme.palette.text.disabled,
    },
    normal: {
        backgroundColor: '#EAEFF2',
        color: theme.palette.text.primary,
    },
}))