import { Project } from "../../../utils/Project"
import { BusinessType } from "../../master/enums/BusinessType"
import { dateFromJson } from "../../utils/deserializer"
import { dateToJson } from "../../utils/serializer"
import { OrderFrequency } from "../enums/OrderFrequency"
import { OrderType } from "../enums/OrderType"
import { RequestType } from "../enums/RequestType"

export type MapaCCHistoryResult = MapaCCHistoryResultRequired & Partial<MapaCCHistoryResultOptional>
type MapaCCHistoryResultJson = MapaCCHistoryResultJsonRequired & Partial<MapaCCHistoryResultJsonOptional>

export const mapaCCHistoryResultFromJson: Project<MapaCCHistoryResultJson, MapaCCHistoryResult> = json => ({
    ...json,
    orderFirstDate: dateFromJson(json.orderFirstDate),
    orderLastDate: dateFromJson(json.orderLastDate),
    targetFirstDate: dateFromJson(json.targetFirstDate),
    targetLastDate: dateFromJson(json.targetLastDate),
})

export const mapaCCHistoryResultToJson: Project<MapaCCHistoryResult, MapaCCHistoryResultJson> = obj => ({
    ...obj,
    orderFirstDate: dateToJson(obj.orderFirstDate),
    orderLastDate: dateToJson(obj.orderLastDate),
    targetFirstDate: dateToJson(obj.targetFirstDate),
    targetLastDate: dateToJson(obj.targetLastDate),
})

interface MapaCCHistoryResultRequired extends MapaCCHistoryResultJsonRequired {

}

interface MapaCCHistoryResultOptional extends Omit<MapaCCHistoryResultJsonOptional, 'orderFirstDate' | 'orderLastDate' | 'targetFirstDate' | 'targetLastDate' | 'dataType' | 'orderType' | 'orderFrequency' | 'businessType'> {
    orderFirstDate: Date,
    orderLastDate: Date,
    targetFirstDate: Date,
    targetLastDate: Date,
    orderType: OrderType,
    orderFrequency: OrderFrequency,
    businessType: BusinessType,
    dataType: RequestType

}

interface MapaCCHistoryResultJsonRequired {
}

interface MapaCCHistoryResultJsonOptional {
    changeHistoryId: number
    soId: number,
    poId: number,
    soNo: string,
    poNo: string,
    customerId: number,
    customerCode: string,
    orderFrequency: number,
    dataType: number,
    orderType: number,
    orderFirstDate: string,
    orderLastDate: string,
    targetFirstDate: string,
    targetLastDate: string,
    businessType: number,
    groupId: number,
    grouping: string,
    remark: string,
    forecastNum: number,
    changeVersion: number,
}

