import React, { useCallback, useMemo, useState } from "react"
import { useGetFOBSettingList } from "../../../services/master/apis/fobSettingApi"
import { FOBSettingListResult } from "../../../services/master/models/FOBSettingListResult"
import { FOBSettingListView, fobSettingListViewFromJson, fobSettingListViewToJson } from "../../../services/master/models/FOBSettingListView"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { MLS170PcUi } from "./MLS170PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 }, valid: 1 }
const searchCachekey = sessionKeys.Filters_MLS170
export const MLS170 = () => {
    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? fobSettingListViewFromJson(JSON.parse(filters)) : null
    }, [])


    const [filters, setFilters] = useState<FOBSettingListView>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<FOBSettingListResult[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)

    const getFOBSettingList = useGetFOBSettingList()

    const search = useCallback((filters: FOBSettingListView) => {
        getFOBSettingList({ ...filters, valid: 1 }, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(fobSettingListViewToJson(filters)))
        })
    }, [getFOBSettingList])

    useSearchOnLoad(search, filters)

    return <MLS170PcUi
        filters={filters}
        search={search}
        data={data}
        totalCount={totalCount}
        setFilters={setFilters}
    />
}

