import { makeStyles } from "@material-ui/core";
import React, { ReactNode } from "react";

interface TabsCardContentProps {
    height?: number,
    children?: ReactNode | ReactNode[],
}

export function TabsCardContent(props: TabsCardContentProps) {
    const { height, children } = props
    const styles = useStyles()
    return <div className={styles.root} style={{ height }}>
        {children}
    </div>
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: theme.spacing(1, 2, 3),
        flex: '1 1 auto',
        overflow: 'hidden',
    },
}))