import { ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, Searching, Selection, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarItemProvider, ToolbarLayout } from "@rithe/data-grid"
import { DateRangeItem, StringItem } from "@rithe/form"
import { Objects, Records } from "@rithe/utils"
import React, { SetStateAction, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { ColumnVisibilityToolbarButton } from "../../../components/DataGrid/components/ColumnVisibilityToolbarButton"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { UploadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/UploadGroupedToolbarAction"
import { FilterToolbarItem } from "../../../components/DataGrid/toolbarItems/FilterToolbarItem"
import { CodeCategoryTypeProvider } from "../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider"
import { CodesItem } from "../../../components/Form/CodesItem"
import { View } from "../../../components/View/View"
import { useDownloadPaymentTermsMasterByFilter, useDownloadPaymentTermsMasterByPaymentTermsId } from "../../../services/master/apis/masterDownloadApi"
import { useUploadMasterPaymentTermsMaster } from "../../../services/master/apis/masterUploadApi"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { PaymentTermsFactor } from "../../../services/master/models/PaymentTermsFactor"
import { PaymentTermsResult } from "../../../services/master/models/PaymentTermsResult"

interface MLS110PcUiProps {
    filters: PaymentTermsFactor,
    search: (filters: PaymentTermsFactor) => void,
    data: PaymentTermsResult[],
    totalCount: number,
    setFilters: React.Dispatch<SetStateAction<PaymentTermsFactor>>,
}

export const MLS110PcUi = (props: MLS110PcUiProps) => {
    return <View flex>
        <SectionCard>
            <SectionCardContent>
                <DataTable {...props} />
            </SectionCardContent>
        </SectionCard>
    </View>
}

const DataTable = ({ filters, data, setFilters, search }: MLS110PcUiProps) => {
    const intl = useIntl()
    const [selections, setSelections] = useState<number[]>([])

    const columns = useMemo(() => [
        { field: 'paymentTermsCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.paymentTermsCode' }), width: 250 },
        { field: 'description', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.description' }), width: 300 },
        { field: 'termsType', dataTypeName: CodeCategory.TermsType, title: intl.formatMessage({ id: 'field.termsTypes' }), width: 200 },
        { field: 'fromMonth', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.fromMonth' }), width: 130 },
        { field: 'days', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.days' }), width: 130 },
        { field: 'fromDay', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.fromDay' }), width: 120 },
        { field: 'createdDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.createdDate' }), width: 180 },
        { field: 'updatedDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.updatedDate' }), width: 180 },
    ], [intl])

    const getRowId = useCallback((row: any) => row.paymentTermsId, [])

    const itemPropsForFilters = useMemo(() => ({ filters, setFilters, search }), [filters, setFilters, search])
    const actionProps1 = useMemo(() => ({ search, filters }), [filters, search])
    const actionProps2 = useMemo(() => ({ selections, filters }), [filters, selections])

    return <DataGrid>
        <ToolbarLayout />
        <TableLayout Container={FlexScrollbar}>
            <TableHeaderLayout sticky />
            <TableBodyLayout />
        </TableLayout>
        <PaginationLayout Pagination={Pagination} />
        <DataTypePreset />
        <CodeCategoryTypeProvider codeCategory={CodeCategory.TermsType} />
        <Data rows={data} columns={columns} getRowId={getRowId} />
        <ToolbarActionProvider Action={UploadAction} actionProps={actionProps1} />
        <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps2} />
        <ColumnFreeze />
        <ColumnVisibility
            defaultHiddenFields={[]}
            columnSettings={{
                paymentTermsCode: { disableUserControl: true },
            }} ToolbarButton={ColumnVisibilityToolbarButton} />
        <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
        <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
        <Searching ignoreCase Input={SearchInput} />
        <ToolbarItemProvider Item={FilterItem} itemProps={itemPropsForFilters} />
        <Sorting />
        <Filtering />
        <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
        <Selection showSelectAll highlightSelectedRow selectedRowIds={selections} onSelectedRowIdsChange={setSelections} />
    </DataGrid>
}

const FilterItem = (props: { search: (filters: PaymentTermsFactor) => void, filters: PaymentTermsFactor, setFilters: React.Dispatch<SetStateAction<PaymentTermsFactor>>, }) => {
    const { filters, setFilters, search } = props
    const createdDateGetValue = useCallback((filters: PaymentTermsFactor) => {
        return [filters.createdDateStart ?? null, filters.createdDateEnd ?? null]
    }, [])

    const createdDateMapValue = useCallback((filters: PaymentTermsFactor, value: any) => {
        return { ...filters ?? {}, createdDateStart: value[0], createdDateEnd: value[1] }
    }, [])

    const updatedDateGetValue = useCallback((filters: PaymentTermsFactor) => {
        return [filters.updatedDateStart ?? null, filters.updatedDateEnd ?? null]
    }, [])

    const updatedDateMapValue = useCallback((filters: PaymentTermsFactor, value: any) => {
        return { ...filters ?? {}, updatedDateStart: value[0], updatedDateEnd: value[1] }
    }, [])

    const clear = useCallback((filters: PaymentTermsFactor) => {
        return { page: filters.page }
    }, [])

    const filterCounter = useCallback((filters: PaymentTermsFactor) => {
        return [
            filters.paymentTermsCode,
            filters.description,
            filters.termsTypes,
            filters.createdBy,
            filters.updatedBy,
            filters.createdDateStart || filters.createdDateEnd,
            filters.updatedDateStart || filters.updatedDateEnd,
        ].filter(value => value !== undefined && value !== null && (!(value instanceof Array) || value.length > 0)).length
    }, [])
    const intl = useIntl()

    return <FilterToolbarItem filters={filters} onFiltersChange={setFilters} onSubmit={search} clear={clear} filterCounter={filterCounter}>
        <StringItem field="paymentTermsCode" label={intl.formatMessage({ id: 'field.paymentTermsCode' })} />
        <StringItem field="description" label={intl.formatMessage({ id: 'field.description' })} />
        <CodesItem field="termsTypes" label={intl.formatMessage({ id: 'field.termsTypes' })} code={CodeCategory.TermsType} />
        <StringItem field="createdBy" label={intl.formatMessage({ id: 'field.createdBy' })} />
        <StringItem field="updatedBy" label={intl.formatMessage({ id: 'field.updatedBy' })} />
        <DateRangeItem field="createdDate" label={intl.formatMessage({ id: 'field.createdDate' })} getValue={createdDateGetValue} mapValue={createdDateMapValue} />
        <DateRangeItem field="updatedDate" label={intl.formatMessage({ id: 'field.updatedDate' })} getValue={updatedDateGetValue} mapValue={updatedDateMapValue} />
    </FilterToolbarItem>
}

const UploadAction = ({ search, filters }: { search: (filter: PaymentTermsFactor) => void, filters: PaymentTermsFactor }) => {
    const uploadMasterApi = useUploadMasterPaymentTermsMaster()
    const uploadMaster = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            files && uploadMasterApi({ file: files[0] }, { serialized: true }).then(() => search(filters))
        })
    }, [filters, search, uploadMasterApi])
    return <UploadGroupedToolbarAction access="MARS.MLS110.UPLOAD">
        {(popupUpload, onClose) => <>
            <GroupedCallbackItem label={<FormattedMessage id="upload" />} callback={() => uploadMaster(popupUpload)} onClose={onClose} />
        </>}
    </UploadGroupedToolbarAction>
}

const DownloadAction = ({ selections, filters }: { selections: number[], filters: PaymentTermsFactor }) => {
    const downloadMasterBySelectionApi = useDownloadPaymentTermsMasterByPaymentTermsId()
    const downloadMasterByFilterApi = useDownloadPaymentTermsMasterByFilter()
    const downloadMaster = useCallback(() => {
        selections.length <= 0 ? downloadMasterByFilterApi(Objects.delete(filters, 'page')) : downloadMasterBySelectionApi({ paymentTermsIdList: selections })
    }, [downloadMasterByFilterApi, downloadMasterBySelectionApi, filters, selections])
    return <DownloadGroupedToolbarAction access="MARS.MLS110.DOWNLOAD">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="download" />} callback={downloadMaster} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}