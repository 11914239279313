import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"
import { TermsType } from "../enums/TermsType"

export type PaymentTermsResult = Required & Partial<Optional> & TableEntity
type PaymentTermsResultJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const paymentTermsResultFromJson: Project<PaymentTermsResultJson, PaymentTermsResult> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
    termsType: TermsType,
}

interface JsonRequired {
    paymentTermsId: number,
}

interface JsonOptional {
    buId: number,
    paymentTermsCode: string,
    description: string,
    termsType: number,
    fromMonth: number,
    days: number,
    fromDay: number,
}