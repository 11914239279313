import { Add } from "@material-ui/icons"
import React from "react"
import { FormattedMessage } from "react-intl"
import { RedirectToolbarAction } from "./RedirectToolbarAction"

interface CreateRedirectToolbarActionProps {
    access?: string,
    power?: boolean,
    title?: NonNullable<React.ReactNode>,
    icon?: NonNullable<React.ReactNode>,
    path: string,
    state?: Record<string, unknown>,
}

export const CreateRedirectToolbarAction = (props: CreateRedirectToolbarActionProps) => {
    const defaultProps = {
        title: <FormattedMessage id="create" />,
        icon: <Add />
    }
    return <RedirectToolbarAction {...defaultProps} {...props} />
}