import { makeStyles } from '@material-ui/core'
import React, { ReactNode } from 'react'

interface ViewActionsProps {
    actions?: ReactNode | ReactNode[],
}

export function ViewActions(props: ViewActionsProps) {
    const { actions } = props

    const classes = useStyles()
    return <div className={classes.root}>
        {React.Children.map(actions, (action, index) => {
            return <div key={index} style={{ marginLeft: 12 }}>{action}</div>
        })}
    </div>
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
}))