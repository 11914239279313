import moment from "moment";

function _dateFromJson(v: string): Date
// eslint-disable-next-line no-redeclare
function _dateFromJson(v: string | undefined): Date | undefined
// eslint-disable-next-line no-redeclare
function _dateFromJson(v: string | null): Date | null
// eslint-disable-next-line no-redeclare
function _dateFromJson(v: any): any {
    return v ? moment(v).toDate() : v
}
export const dateFromJson = _dateFromJson

function _enumFromJson<E>(e: string | number): E
// eslint-disable-next-line no-redeclare
function _enumFromJson<E>(e: string | number | undefined): E | undefined
// eslint-disable-next-line no-redeclare
function _enumFromJson(e: any): any {
    return e
}
export const enumFromJson = _enumFromJson

function _datetimeFromJson(v: number): Date
// eslint-disable-next-line no-redeclare
function _datetimeFromJson(v: number | undefined): Date | undefined
// eslint-disable-next-line no-redeclare
function _datetimeFromJson(v: any): any {
    return v ? new Date(v) : null
}
export const datetimeFromJson = _datetimeFromJson