import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { Projects } from "../../../utils/Projects"
import { ReleaseWorkingFactor, releaseWorkingFactorToJson } from "../models/ReleaseWorkingFactor"
import { RequestResult, requestResultToJson } from "../models/RequestResult"
import { TnmPaymentTerms, tnmPaymentTermsFromJson } from "../models/TnmPaymentTerms"
import { WorkingFactor, workingFactorToJson } from "../models/WorkingFactor"
import { WorkingResult, workingResultFromJson, workingResultToJson } from "../models/WorkingResult"

export const useSaveAndConfirmWorking = () => {
    const url = '/lcbm-local-master-api/api/saveAndConfirmWorking'
    const payloadProject = useCallback(workingFactorToJson, [])
    const resultProject = Projects.IGNORE
    return usePost<WorkingFactor, undefined>(url, payloadProject, resultProject)
}

export const useSaveWorking = () => {
    const url = '/lcbm-local-master-api/api/saveWorking'
    const payloadProject = useCallback(workingResultToJson, [])
    const resultProject = useCallback(workingResultFromJson, [])
    return usePost<WorkingResult, WorkingResult>(url, payloadProject, resultProject)
}

export const useSaveWorkingTemp = () => {
    const url = '/lcbm-local-master-api/api/saveWorkingTemp'
    const payloadProject = useCallback(workingResultToJson, [])
    const resultProject = useCallback(workingResultFromJson, [])
    return usePost<WorkingResult, WorkingResult>(url, payloadProject, resultProject)
}

export const useReleaseWorking = () => {
    const url = '/lcbm-local-master-api/api/releaseWorking'
    const payloadProject = useCallback(releaseWorkingFactorToJson, [])
    const resultProject = Projects.IGNORE
    return usePost<ReleaseWorkingFactor, undefined>(url, payloadProject, resultProject)
}

export const useGetWorkingByRequestNo = () => {
    const url = '/lcbm-local-master-api/api/getWorkingByRequestNo'
    // const payloadProject = useCallback((obj: { requestNo: string }) => obj.requestNo, []);
    const payloadProject = useCallback(requestResultToJson, [])
    const resultProject = useCallback(workingResultFromJson, [])
    return usePost<RequestResult, WorkingResult>(url, payloadProject, resultProject)
}

export const useGetWorkingForModifyByRequestNo = () => {
    const url = '/lcbm-local-master-api/api/getWorkingForModifyByRequestNo'
    // const payloadProject = useCallback((obj: { requestNo: string }) => obj.requestNo, []);
    const payloadProject = useCallback(requestResultToJson, [])
    const resultProject = useCallback(workingResultFromJson, [])
    return usePost<RequestResult, WorkingResult>(url, payloadProject, resultProject)
}

export const useGetWorkingForPriceModifyByRequestNo = () => {
    const url = '/lcbm-local-master-api/api/getWorkingForPriceModifyByRequestNo'
    // const payloadProject = useCallback((obj: { requestNo: string }) => obj.requestNo, []);
    const payloadProject = useCallback(requestResultToJson, [])
    const resultProject = useCallback(workingResultFromJson, [])
    return usePost<RequestResult, WorkingResult>(url, payloadProject, resultProject)
}

export const useGetWorkingForRemoveByRequestNo = () => {
    const url = '/lcbm-local-master-api/api/getWorkingForRemoveByRequestNo'
    // const payloadProject = useCallback((obj: { requestNo: string }) => obj.requestNo, []);
    const payloadProject = useCallback(requestResultToJson, [])
    const resultProject = useCallback(workingResultFromJson, [])
    return usePost<RequestResult, WorkingResult>(url, payloadProject, resultProject)
}

export const useGetWorkingForTerminateByRequestNo = () => {
    const url = '/lcbm-local-master-api/api/getWorkingForTerminateByRequestNo'
    // const payloadProject = useCallback((obj: { requestNo: string }) => obj.requestNo, []);
    const payloadProject = useCallback(requestResultToJson, [])
    const resultProject = useCallback(workingResultFromJson, [])
    return usePost<RequestResult, WorkingResult>(url, payloadProject, resultProject)
}

export const useSaveWorkingParts = () => {
    const url = '/lcbm-local-master-api/api/saveWorkingParts'
    const payloadProject = useCallback(workingResultToJson, [])
    const resultProject = useCallback(workingResultFromJson, [])
    return usePost<WorkingResult, WorkingResult>(url, payloadProject, resultProject)
}

export const useSaveWorkingPartsTemp = () => {
    const url = '/lcbm-local-master-api/api/saveWorkingPartsTemp'
    const payloadProject = useCallback(workingResultToJson, [])
    const resultProject = useCallback(workingResultFromJson, [])
    return usePost<WorkingResult, WorkingResult>(url, payloadProject, resultProject)
}

export const useGetMyPaymentTerms = () => {
    const url = '/lcbm-local-master-api/api/getMyPaymentTerms'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(tnmPaymentTermsFromJson), [])
    return usePost<undefined, TnmPaymentTerms[]>(url, payloadProject, resultProject)
}

export const useGetWorkingCountByContractId = () => {
    const url = '/lcbm-local-master-api/api/getWorkingCountByContractId'
    const payloadProject = useCallback((obj: { contractId: number }) => obj.contractId, [])
    const resultProject = useCallback((count: number) => { return { count: count } }, [])
    return usePost<{ contractId: number }, { count: number }>(url, payloadProject, resultProject)
}