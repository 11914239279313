import { useCallback, useMemo, useState } from "react"
import { useGetOrderGroupList } from "../../../services/master/apis/orderGroupApi"
import { ContractFactorFromJson, contractFactorToJson } from "../../../services/master/models/ContractFactor"
import { OrderGroupFactor } from "../../../services/master/models/OrderGroupFactor"
import { OrderGroupResult } from "../../../services/master/models/OrderGroupResult"
import sessionKeys from "../../../utils/sessionKeys"
import { useMatch } from "../../../utils/useMatch"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { MLS140PcUi } from "./MLS140PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }

const searchCachekey = sessionKeys.Filters_MLS140

export const MLS140 = () => {
    const { path } = useMatch()

    const cacheFilters = useMemo(() => {
        const filters = sessionStorage.getItem(searchCachekey)
        return filters ? ContractFactorFromJson(JSON.parse(filters)) : null
    }, [])

    const [filters, setFilters] = useState<OrderGroupFactor>(cacheFilters ?? defaultFilters)
    const [data, setData] = useState<OrderGroupResult[]>([])
    const [totalCount, setTotalCount] = useState<number>(0)

    const getDataList = useGetOrderGroupList()
    const search = useCallback((filters: OrderGroupFactor) => {
        getDataList(filters, { silent: true, serialized: true }).then(result => {
            setTotalCount(result?.page?.totalCount || 0)
            setData(result?.data || [])
            sessionStorage.setItem(searchCachekey, JSON.stringify(contractFactorToJson(filters)))
        })
    }, [getDataList, setData])

    useSearchOnLoad(search, filters)

    return <MLS140PcUi
        path={path}
        filters={filters}
        search={search}
        data={data}
        totalCount={totalCount}
        setFilters={setFilters}
    />
}