import { Project } from "../../../utils/Project"
import { dateFromJson } from "../../utils/deserializer"
import { dateToJson } from "../../utils/serializer"
import { ContractRequestType } from "../enums/ContaractRequestType"
import { ContractRequestStatus } from "../enums/ContractRequestStatus"

export type RequestHeaderResult = Required & Partial<Optional>
export type RequestHeaderResultJson = JsonRequired & Partial<JsonOptional>
export const requestHeaderResultToJson: Project<RequestHeaderResult, RequestHeaderResultJson> = obj => ({
    ...obj,
    submittedDate: obj.submittedDate ? dateToJson(obj.submittedDate) : undefined,
})

export const requestHeaderResultFromJson: Project<RequestHeaderResultJson, RequestHeaderResult> = json => ({
    ...json,
    submittedDate: json.submittedDate ? dateFromJson(json.submittedDate) : undefined,
})

interface Required extends Omit<JsonRequired, 'requestType'> {
    requestType: ContractRequestType,
}

interface Optional extends Omit<JsonOptional, 'submittedDate'> {
    status: ContractRequestStatus,
    submittedDate: Date,
}

interface JsonRequired {
    requestId: number,
    requestNo: string,
    requestType: number,
}

interface JsonOptional {
    description: string,
    requestTo: string,
    requestToCode: string,
    requestFrom: string,
    requestFromCode: string,
    requestBy: string,
    submittedBy: string,
    submittedDate: string,
    status: number,
    version: number,
    repackingType: number,
    contractId: number,
}





