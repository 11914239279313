import { makeStyles } from "@material-ui/core";
import React, { ReactNode } from "react";
import { TabContextProvider } from "./TabContext";

interface ViewTabControlProps {
    children?: ReactNode | ReactNode[],
}

export function ViewTabControl(props: ViewTabControlProps) {
    const { children } = props
    const styles = useStyles()
    return <TabContextProvider>
        <div className={styles.root}>
            {children}
        </div>
    </TabContextProvider>
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'stretch',
    },
}))