import { Button, Card, CardContent, Grid, makeStyles, Typography, useTheme } from "@material-ui/core"
import { ColumnFreeze, ColumnOrdering, ColumnResizing, Data, DataGrid, DataTypePreset, Filtering, ObjectTypeProvider, Row, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from "@rithe/data-grid"
import { ObjectFormatterProps } from "@rithe/data-grid/dist/components/dataTypes/ObjectFormatter"
import { EntriesItem, Form } from "@rithe/form"
import { GridContainer, GridItem } from "@rithe/ui"
import { Arrays, Maps, Records } from '@rithe/utils'
import FusionCharts from "fusioncharts"
import Charts from "fusioncharts/fusioncharts.charts"
import Widgets from "fusioncharts/fusioncharts.widgets"
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion'
import React, { useCallback, useEffect, useMemo, useState } from "react"
import ReactFusioncharts from 'react-fusioncharts'
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl"
import { SimpleCard } from "../../../components/Card/SimpleCard"
import { SimpleCardContent } from "../../../components/Card/SimpleCardContent"
import { SimpleCardHeader } from "../../../components/Card/SimpleCardHeader"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { SectionTitle } from "../../../components/SectionTitle/SectionTitle"
import { AcctInvoiceResult, OverdueRegionDay } from "../../../services/dashboard/models/AccountingDashboard"
import { AccountingFactor } from "../../../services/dashboard/models/AccountingDashboardFactor"
import { ContractResult, InvoiceResult } from "../../../services/dashboard/models/AccountingDashboardResult"
import { CreditLimitResult } from "../../../services/order/models/CreditLimitResult"
import { today } from "../../../utils/ApplicationUtils"
import { Currency } from "../../../utils/Currency"
import { DataCard } from "../Card/DataCard"

interface DAS015PcUiProps {
    search: (filters: AccountingFactor) => void,
    data: InvoiceResult[],
    limits: CreditLimitResult[],
    contracts: ContractResult[],
}

export const DAS015PcUi = (props: DAS015PcUiProps) => {
    const { data, contracts, search } = props
    const intl = useIntl()
    const currentDate = useMemo(() => today(), [])
    const invoices = calInvoices(data, currentDate)

    // add fusion charts fc root
    ReactFusioncharts.fcRoot(FusionCharts, Charts, Widgets, FusionTheme)

    return <SimpleCard>
        <SimpleCardHeader title={intl.formatMessage({ id: 'financialStatistics' })} actions={[<FilterView contracts={contracts} search={search} />]} />
        <SimpleCardContent>
            <OverViewCards invoices={invoices} today={currentDate} />
            <AccountingReceivableView invoices={invoices} today={currentDate} />
            <SectionTitle size='large' ><FormattedMessage id='invoicesNotCleared' /></SectionTitle>
            <NotClearedInvoiceView invoices={invoices} today={currentDate} />
        </SimpleCardContent>
    </SimpleCard>
}

const FilterView = ({ contracts, search, isShow }: { isShow?: boolean, contracts: ContractResult[], search: (filters: AccountingFactor) => void }) => {

    const sellerList = useMemo(() => Arrays.sort(Array.from(Maps.from(contracts.map(m => [m.sellerUid, m.sellerCode])).entries()), (a, b) => a[1] > b[1] ? 1 : a === b ? 0 : -1), [contracts])
    const defaultBuyerList = useMemo(() => Arrays.sort(Array.from(Maps.from(contracts.map(m => [m.buyerUid, m.buyerCode])).entries()), (a, b) => a[1] > b[1] ? 1 : a === b ? 0 : -1), [contracts])

    const [filters, setFilters] = useState<AccountingFactor>({} as AccountingFactor)
    const [buyerList, setBuyerList] = useState<[string, string][]>([])

    useEffect(() => {
        setBuyerList(defaultBuyerList)
    }, [defaultBuyerList])

    const handleConfirm = useCallback(() => {
        search && search(filters)
    }, [filters, search])
    const handleClear = useCallback(() => {
        setFilters({} as AccountingFactor)
    }, [setFilters])
    const intl = useIntl()

    // remove
    if (!isShow) {
        return <></>
    }

    return (<Grid style={{ flexGrow: 1, whiteSpace: 'nowrap', height: '100%', display: 'flex' }}>
        <Grid>
            <Form data={filters} setData={setFilters} labelDisplay="block" labelAlign="start" helperDisplay="tooltip" helperAlign="end" minWidth={1000} columnCount={2}>
                <EntriesItem field="sellerList" label={intl.formatMessage({ id: 'Company' })} entries={sellerList} />
                <EntriesItem field="buyerList" label={intl.formatMessage({ id: 'Customer' })} entries={buyerList} />
            </Form>
        </Grid>
        <Grid style={{ margin: 'auto' }}><Button onClick={handleClear}><FormattedMessage id="clear" /></Button><Button color="primary" onClick={handleConfirm}><FormattedMessage id="search" /></Button></Grid>
    </Grid>)
}

const OverViewCards = ({ invoices, today }: { invoices: AcctInvoiceResult[], today: Date }) => {
    const intl = useIntl()
    const statistics = calStatisticsInfo(invoices, today)
    return <GridContainer columnWidth={['1fr', '1fr', '1fr', '1fr', '1fr']} columnGap={24} style={{ width: '100%', paddingBottom: 15 }}>
        <GridItem style={{ width: '100%' }}>
            <DataCard subContents={intl.formatMessage({ id: 'accounts' })} numberData={statistics.totalAccounts} currency={statistics.currency as Currency} />
        </GridItem>
        <GridItem style={{ width: '100%' }}>
            <DataCard subContents={intl.formatMessage({ id: 'overdueAmountPercentage' })} percentageData={statistics.overdueAmountPercent} />
        </GridItem>
        <GridItem style={{ width: '100%' }}>
            <DataCard subContents={intl.formatMessage({ id: 'totalOutstandingAmount' })} numberData={statistics.totalOutstandingAmount} currency={statistics.currency as Currency} />
        </GridItem>
        <GridItem style={{ width: '100%' }}>
            <DataCard subContents={intl.formatMessage({ id: 'overdueAmount' })} numberData={statistics.totalOverdueAmount} currency={statistics.currency as Currency} />
        </GridItem>
        <GridItem style={{ width: '100%' }}>
            <DataCard subContents={intl.formatMessage({ id: 'creditLimitLeft' })} numberData={statistics.creditLimitLeft} currency={statistics.currency as Currency} />
        </GridItem>
    </GridContainer>
}

const AccountingReceivableView = ({ invoices, today }: { invoices: AcctInvoiceResult[], today: Date }) => {
    const intl = useIntl()
    const styles = useStyles()
    const invoiceNums = calInvoiceNums(invoices, today)
    const regionDays = calRegionDays(invoices, today)
    const data = useMemo(() => {
        const colors = [
            '#0CA78F', '#FFA245', '#00A6ED', '#CB4747', '#CB4747', '#CB4747'
        ]
        return regionDays.map((m, index) => {
            const barChartData = {
                label: m.regionDay,
                value: m.accounts,
                displayValue: intl.formatNumber(m.accounts),
                color: colors[index]

            }
            return barChartData
        })
    }, [intl, regionDays])
    const colors = regionDays.map(m => m.color).join(",")

    return (
        <Card style={{ marginTop: 10, marginBottom: 15 }}>
            <CardContent>
                <div style={{ width: '100%', height: 260, display: 'flex', justifyContent: 'stretch' }}>
                    <div style={{ flex: '1 1 auto' }}>
                        <div className={styles.cardroot}>
                            <div className={styles.cardcontent}>
                                <Typography variant="h3" color="textPrimary" className={styles.cardtitle}><FormattedMessage id='outStandingAmount' /></Typography>
                            </div>
                        </div>
                        <AmountChartView data={data} colors={colors} isShow={true} height={200} renderAt={"regionChart"}></AmountChartView>
                    </div>
                    <div className={styles.leftCard}>
                        <DataCard subContents={intl.formatMessage({ id: 'outStandingInvoices' })} numberData={invoiceNums.outstandingInvoiceNum} color={"error"} nowrap={false} />
                        <DataCard subContents={intl.formatMessage({ id: 'overdueAmounts' })} numberData={invoiceNums.overdueInvoiceNum} nowrap={false} />
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}

const NotClearedInvoiceView = ({ invoices, today }: { invoices: AcctInvoiceResult[], today: Date }) => {
    const intl = useIntl()

    // find all max data
    const calMax = useCallback((invoices: AcctInvoiceResult[], mapFunc: (map: AcctInvoiceResult) => number) => {
        const values = invoices.map(mapFunc).sort()
        return values && values.length > 0 ? values[values.length - 1] : 0
    }, [])
    const maxOverdueAmount = useMemo(() => calMax(invoices, (m) => m.overdueAmount), [calMax, invoices])
    const maxOutstandingAmount = useMemo(() => calMax(invoices, (m) => m.outstandingAmount), [calMax, invoices])
    const maxOverdueAmountPercent = useMemo(() => calMax(invoices, (m) => m.overdueAmountPercent), [calMax, invoices])
    const maxOverdueDays = useMemo(() => calMax(invoices, (m) => m.overdueDays), [calMax, invoices])

    const columns = useMemo(() => [
        { field: 'invoiceNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.invoiceNo' }), width: 240 },
        { field: 'invoiceDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.invoiceDate' }), width: 140, },
        { field: 'sellerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'sellerCode' }), width: 160 },
        { field: 'overdueAmount', dataTypeName: 'bar', title: intl.formatMessage({ id: 'totalOverdueAmount' }), width: 220, getCellValue: (row: Row) => ({ qty: row.overdueAmount, maxQty: maxOverdueAmount, minDigits: 0, maxDigits: 3 }) },
        { field: 'outstandingAmount', dataTypeName: 'bar', title: intl.formatMessage({ id: 'totalOutstandingAmount' }), width: 240, getCellValue: (row: Row) => ({ qty: row.outstandingAmount, maxQty: maxOutstandingAmount, minDigits: 0, maxDigits: 3 }) },
        { field: 'overdueAmountPercent', dataTypeName: 'bar', title: intl.formatMessage({ id: 'overdueAmountPercentage' }), width: 180, getCellValue: (row: Row) => ({ qty: row.overdueAmountPercent, maxQty: maxOverdueAmountPercent, minDigits: 0, maxDigits: 2 }) },
        { field: 'overdueDays', dataTypeName: 'bar', title: intl.formatMessage({ id: 'overdueDays' }), width: 180, getCellValue: (row: Row) => ({ qty: row.overdueDays, maxQty: maxOverdueDays, minDigits: 0, maxDigits: 0 }) },
    ], [intl, maxOutstandingAmount, maxOverdueAmount, maxOverdueAmountPercent, maxOverdueDays])

    return <div style={{ width: '100%', paddingTop: 10, height: 460 }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <DataTypePreset />
            <BarTypeProvider />
            <Data rows={invoices} columns={columns} />
            <ColumnFreeze freeze={{ invoiceNo: 'start' }} />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
        </DataGrid>
    </div>
}

const BarFormatter = ({ value }: ObjectFormatterProps) => {
    return value ? <UseProgress {...value} /> : null
}

const BarTypeProvider = () => {
    return <ObjectTypeProvider name="bar" Formatter={BarFormatter} />
}

const UseProgress = ({ qty, maxQty, minDigits, maxDigits }: { qty: number, maxQty: number, minDigits: 0, maxDigits: 3 }) => {
    const calQty = qty < 0 ? 0 : qty
    const width: number = maxQty === 0 ? 1 : (qty / maxQty) * 95
    const theme = useTheme()
    const styles: React.CSSProperties = {
        height: "35px",
        width: width + "%",
        display: "flex",
        overflow: "hidden",
        alignItems: 'center',
        // border: `1px solid ${props.color}`,
        background: width > 0.5 ? '#768694' : '#ECEFF2',
        backgroundSize: "5px 5px",
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        // paddingLeft: 8,
        float: 'left',
        position: 'absolute',
        transition: `all ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`
    }

    return <>
        <div style={{ width: '100%', float: 'left', position: 'absolute' }}><Typography variant="body2" style={{ paddingLeft: 8 }}><FormattedNumber value={calQty} minimumFractionDigits={minDigits} maximumFractionDigits={maxDigits} /></Typography></div>
        <div style={styles} ><Typography variant="body2" style={{ paddingLeft: 8 }}><FormattedNumber value={calQty} minimumFractionDigits={minDigits} maximumFractionDigits={maxDigits} /></Typography></div>
    </>
}

const AmountChartView = (props: { data: any[], colors: string, isShow: boolean, height: number, renderAt: string }) => {
    const data = props.data
    const colors = props.colors
    const source = useDataSource(data, colors, props.isShow)
    const chartConfigs = {
        type: "column2d",
        renderAt: props.renderAt,
        width: '100%',
        height: props.height,
        dataFormat: 'json',
        dataSource: source,
    }

    return (
        <ReactFusioncharts {...chartConfigs} />
    )
}

const useDataSource = (data: any[], colors: string, isShow: boolean) => {
    const dataSource = {
        chart: {
            theme: "fusion",
            palettecolors: colors,
            placeValuesInside: 1,
            showYAxisValues: 1,
            showXAxisLine: 0,
            baseFontSize: 14,
            plottooltext: "$label : $displayValue",
            showLabels: isShow
        },
        data: data,
    }

    return dataSource
}

const regionData: OverdueRegionDay[] = [
    { regionDay: "On Time", color: "#1aaf5d", minDays: 0, maxDays: 0, accounts: 0, isOnTime: true },
    { regionDay: "1~30 days", color: "#818583", minDays: 1, maxDays: 30, accounts: 0, isOnTime: false },
    { regionDay: "31~60 days", color: "#f05858", minDays: 31, maxDays: 60, accounts: 0, isOnTime: false },
    { regionDay: "61~90 days", color: "#d63e3e", minDays: 61, maxDays: 90, accounts: 0, isOnTime: false },
    { regionDay: "91~120 days", color: "#b52c2c", minDays: 91, maxDays: 120, accounts: 0, isOnTime: false },
    { regionDay: ">120 days", color: "#8a0505", minDays: 121, maxDays: 1500, accounts: 0, isOnTime: false }
]

const calInvoices = (data: InvoiceResult[], today: Date) => {
    return data.map(invoice => {
        const isOverdue: boolean = invoice.paymentDueDate < today && invoice.invoiceAmount > (invoice.paymentedAmount ?? 0)
        const outstandingAmount = Number(invoice.invoiceAmount - (invoice.paymentedAmount ?? 0))
        const overdueAmount: number = isOverdue ? outstandingAmount : 0
        const overdueDays: number = Math.ceil(invoice.paymentDueDate ? ((today.getTime() - invoice.paymentDueDate.getTime()) / (1 * 24 * 60 * 60 * 1000)) : 0)
        const overdueAmountPercent: number = invoice.invoiceAmount === 0 ? 0 : Number((overdueAmount / invoice.invoiceAmount).toFixed(4)) * 100

        const invoiceResult: AcctInvoiceResult = {
            buyerCode: invoice.buyerCode,
            sellerCode: invoice.sellerCode,
            invoiceNo: invoice.invoiceNo,
            invoiceDate: invoice.invoiceDate,
            invoiceAmount: invoice.invoiceAmount,
            invoiceCurrency: invoice.invoiceCurrency,
            paymentedAmount: invoice.paymentedAmount ?? 0,
            outstandingAmount: outstandingAmount,
            overdueAmount: overdueAmount,
            overdueDays: overdueDays,
            overdueAmountPercent: overdueAmountPercent,
            paymentDueDate: invoice.paymentDueDate,
            isOverdue: isOverdue,
        }

        return invoiceResult
    })
}

const calStatisticsInfo = (invoices: AcctInvoiceResult[], today: Date) => {

    const outstandingInvoices = invoices.filter(i => i.invoiceAmount > i.paymentedAmount)
    const overdueInvoices = outstandingInvoices.filter(i => i.paymentDueDate < today)
    const totalAccounts = invoices.map(i => i.invoiceAmount).reduce((a, b) => a + b, 0)
    const totalOutstandingAmount = outstandingInvoices.map(i => i.invoiceAmount - i.paymentedAmount).reduce((a, b) => a + b, 0)
    const totalOverdueAmount = overdueInvoices.map(i => i.invoiceAmount - i.paymentedAmount).reduce((a, b) => a + b, 0)
    const overdueAmountPercent = totalAccounts === 0 ? 0 : Number((totalOverdueAmount / totalAccounts).toFixed(4)) * 100
    const creditLimitLeft = 0//TODO
    const currency = invoices.find(f => f.invoiceCurrency)?.invoiceCurrency

    return {
        totalAccounts,
        totalOutstandingAmount,
        totalOverdueAmount,
        overdueAmountPercent,
        creditLimitLeft,
        currency
    }
}

const calInvoiceNums = (invoices: AcctInvoiceResult[], today: Date) => {

    const outstandingInvoices = invoices.filter(i => i.invoiceAmount > i.paymentedAmount)
    const outstandingInvoiceNum = outstandingInvoices.length
    const overdueInvoiceNum = outstandingInvoices.filter(i => i.paymentDueDate < today).length

    return {
        outstandingInvoiceNum,
        overdueInvoiceNum
    }
}

const calRegionDays = (invoices: AcctInvoiceResult[], today: Date) => {
    const overdueInfos = invoices.map(i => ({
        invoiceNo: i.invoiceNo,
        overdue: i.paymentDueDate < today && i.invoiceAmount > i.paymentedAmount,
        outstandingAmount: i.invoiceAmount - i.paymentedAmount,
        overdueDays: (i.paymentDueDate ? (today.getTime() - i.paymentDueDate.getTime()) : 0) / (1 * 24 * 60 * 60 * 1000)
    }))

    return regionData.map(r => {
        const infos = r.isOnTime ? overdueInfos.filter(i => !i.overdue) : overdueInfos.filter(i => i.overdue && r.minDays <= i.overdueDays && i.overdueDays < r.maxDays)
        const accounts = infos.map(i => i.outstandingAmount).reduce((a, b) => a + b, 0)
        return { ...r, accounts }
    })
}


const useStyles = makeStyles(theme => ({
    viewcard: {
        width: '100%',
        minWidth: 1100,
        paddingBottom: theme.spacing(2),
        columnCount: 5,
        columnGap: theme.spacing(3),
        columnWidth: 180,
        columnFill: 'balance',
        boxSizing: 'border-box'
    },
    leftCard: {
        flex: '0 0 180px',
        marginLeft: 20,
        paddingTop: 30,
        '&>div': {
            marginTop: theme.spacing(2)
        }
    },
    label: {
        fontWeight: 'bold',
        color: theme.palette.text.primary,
        textTransform: 'uppercase',
    },
    cardroot: {
        width: '100%',
        padding: theme.spacing(2),
        flex: '0 0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'stretch',
    },
    cardcontent: {
        flex: '1 1 auto',
        overflow: 'hidden',
    },
    cardtitle: {
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    actions: {
        flex: '0 0 auto',
    },
}))