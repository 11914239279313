import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { useSelector } from "react-redux"
import appConfig from "../../configs/appConfig"
import frequentlyUsedPagesApi, { FrequentlyUsedPageAPIResponse } from "../../services/system/apis/frequentlyUsedPagesApi"
import errorToNotification from "../../utils/axios/errorToNotification"
import thunkAxiosInstance from "../../utils/axios/thunkAxiosInstance"
import { applicationActions } from "../Application/applicationSlice"

interface HomeState {
    frequentlyUsedPages: FrequentlyUsedPageAPIResponse[]
}

const initialState: HomeState = {
    frequentlyUsedPages: []
}

const listFrequentlyUsedPages = createAsyncThunk('home/listFrequentlyUsedPages', (limit: number | undefined, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    thunk.dispatch(applicationActions.addMask())
    return frequentlyUsedPagesApi.list(axiosInstance, { limit: limit })
        .then(response => {
            thunk.dispatch(homeActions.setFrequentlyUsedPages(response.data))
        }).catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        }).then(() => {
            thunk.dispatch(applicationActions.removeMask())
        })
})

const statistics = createAsyncThunk('home/statistics', (pageCode: string, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    thunk.dispatch(applicationActions.addMask())
    return frequentlyUsedPagesApi.statistics(axiosInstance, { pageCode: pageCode })
        .then(() => {
            thunk.dispatch(applicationActions.removeMask())
        })
})


export const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        setFrequentlyUsedPages: (state, { payload: data }: PayloadAction<FrequentlyUsedPageAPIResponse[]>) => {
            state.frequentlyUsedPages = data
        },
    }
})

export const homeActions = {
    ...homeSlice.actions,
    listFrequentlyUsedPages,
    statistics
}


function selectHomeState(state: any): HomeState {
    return state[appConfig.appFullName][homeSlice.name]
}


export function useHomeSelector<R>(selector: (state: HomeState) => R, equalityFn?: (left: R, right: R) => boolean) {
    return useSelector<any, R>(state => selector(selectHomeState(state)), equalityFn)
}