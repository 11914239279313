import React, { useCallback, useMemo, useState } from "react"
import { useGetProjectPage } from "../../../services/smt/api/ProjectApi"
import { ProjectFactor, projectFactorFromJson, projectFactorToJson } from "../../../services/smt/models/ProjectFactor"
import { ProjectResult } from "../../../services/smt/models/ProjectResult"
import sessionKeys from "../../../utils/sessionKeys"
import { useSearchOnLoad } from "../../../utils/useSearchOnLoad"
import { CSUGS130PcUi } from "./CSUGS130PcUi"

const defaultFilters = { page: { currentPage: 0, pageSize: 1000 } }
const searchCachekey = sessionKeys.Filters_CSUGS130

export const CSUGS130 = () => {
  const cacheFilters = useMemo(() => {
    const filters = sessionStorage.getItem(searchCachekey)
    return filters ? projectFactorFromJson(JSON.parse(filters)) : null
  }, [])

  const [filters, setFilters] = useState<ProjectFactor>(cacheFilters ?? defaultFilters)
  const [data, setData] = useState<ProjectResult[]>([])

  const projectPage = useGetProjectPage()
  const search = useCallback((filters: ProjectFactor) => {
    projectPage(filters, { silent: true, serialized: true }).then((result) => {
      setData(result?.data || [])
      sessionStorage.setItem(searchCachekey, JSON.stringify(projectFactorToJson(filters)))
    })
  }, [projectPage])


  useSearchOnLoad(search, filters)

  return (
    <CSUGS130PcUi
      filters={filters}
      setFilters={setFilters}
      data={data}
      search={search}
    ></CSUGS130PcUi>
  )
}
