import { Checkbox, ListItemIcon, ListItemText, makeStyles, MenuItem, MenuList, Tooltip, Typography } from "@material-ui/core"
import { ViewColumn } from "@material-ui/icons"
import { Column } from "@rithe/data-grid"
import { usePopover } from "@rithe/utils"
import React from "react"
import { FormattedMessage } from "react-intl"
import { NeumorphismButton } from "../../Button/NeumorphismButton"
import { PopoverMenu } from "../../PopoverMenu"

interface ColumnVisibilityToolbarButtonProps {
    columns: Column[],
    hiddenFields: string[],
    disabledFields: string[],
    switchColumnVisibility: (field: string) => void,
}

export const ColumnVisibilityToolbarButton = (props: ColumnVisibilityToolbarButtonProps) => {
    const {
        columns,
        hiddenFields,
        disabledFields,
        switchColumnVisibility,
    } = props

    const { open, anchorEl, onOpen, onClose } = usePopover()
    const styles = useStyles()
    return <>
        <Tooltip title={<FormattedMessage id='hiddenShowColumns' />}>
            <NeumorphismButton
                onClick={onOpen}
                className={styles.root}
            >
                <ViewColumn />
            </NeumorphismButton>
        </Tooltip>
        <PopoverMenu
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
        >
            <MenuList>
                {columns.map(({ field, title }) => {
                    const disabled = disabledFields.indexOf(field) >= 0
                    const checked = hiddenFields.indexOf(field) < 0
                    return <MenuItem
                        key={field}
                        disabled={disabled}
                        onClick={() => switchColumnVisibility(field)}
                    >
                        <ListItemIcon>
                            <Checkbox checked={checked} />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant="body1">
                                {title}
                            </Typography>
                        </ListItemText>
                    </MenuItem>
                })}
            </MenuList>
        </PopoverMenu>
    </>
}

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: theme.spacing(1),
        width: 36,
    },
}))