import { IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core"
import { Edit } from "@material-ui/icons"
import { DataGridCategoryProps } from "@rithe/data-grid/dist/components/basic/DataGridCategory"

export const useOCCLS017ShippingPlanCategory = (click: (filed: string) => void) => {
    const styles = useStyles()
    return (props: DataGridCategoryProps) => {
        const { category, tableColumns } = props
        return <Tooltip title={"Edit"} >
            <Typography variant="body2" className={styles.root} onClick={() => click(tableColumns[0].field)}>
                {category}<IconButton style={{ zoom: 0.8 }}><Edit fontSize={"small"} /></IconButton>
            </Typography >
        </Tooltip>
    }
}

const useStyles = makeStyles(({
    root: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }
}))