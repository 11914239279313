import { Project } from "../../../utils/Project"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"
import { ActiveFlag } from "../enums/ActiveFlag"

export type TnmCustomer = Required & Partial<Optional> & TableEntity
type TnmCustomerJson = JsonRequired & Partial<JsonOptional> & TableEntityJson
export const tnmCustomerFromJson: Project<TnmCustomerJson, TnmCustomer> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
    activeFlag: ActiveFlag,
}

interface JsonRequired {
    customerId: number,
    customerCode: string,
    customerName: string,
    regionCode: string,

}

interface JsonOptional {
    companyId: number,
    customerShortCode: string,
    address1: string,
    address2: string,
    address3: string,
    address4: string,
    postalCode: string,
    contact1: string,
    fax1: string,
    telephone1: string,
    email1: string,
    contact2: string,
    fax2: string,
    telephone2: string,
    email2: string,
    timeZone: string,
    calendarId: number,
    activeFlag: number,
    status: number
}