import { Project } from "../../../utils/Project"
import { ShippingMode } from "../../accounting/enums/ShippingMode"
import { TableEntity, tableEntityFromJson, TableEntityJson } from "../../utils/TableEntity"
import { ActiveFlag } from "../enums/ActiveFlag"

export type TnmShippingRoute = Required & Partial<Optional> & TableEntity
type TnmShippingRouteJson = JsonRequired & Partial<JsonOptional> & TableEntityJson

export const tnmShippingRouteFromJson: Project<TnmShippingRouteJson, TnmShippingRoute> = json => ({
    ...tableEntityFromJson(json),
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
    shippingMode: ShippingMode,
    activeFlag: ActiveFlag,
}

interface JsonRequired {
    srId: number,
    shippingRouteCode: string,
}

interface JsonOptional {
    shippingMode: number,
    fromPortId: number,
    toPortId: number,
    expCcLeadtime: number,
    impCcLeadtime: number,
    activeFlag: number,
    displayShippingRoute: string,
}