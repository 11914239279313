import { Project } from "../../../utils/Project"
import { Projects } from "../../../utils/Projects"
import { dateFromJson } from "../../utils/deserializer"
import { PageViewEntity, pageViewEntityFromJson, PageViewEntityJson, pageViewEntityToJson } from "../../utils/PageViewEntity"
import { dateToJson } from "../../utils/serializer"
import { CoStatus } from "../enums/CoStatus"
import { OrderType } from "../enums/OrderType"

export type CoListFactor = Required & Partial<Optional>
type CoListFactorFactorJson = JsonRequired & Partial<JsonOptional>
export const coListFactorFactorToJson: Project<CoListFactor, CoListFactorFactorJson> = obj => ({
    ...obj,
    createdDateStart: dateToJson(obj.createdDateStart),
    createdDateEnd: dateToJson(obj.createdDateEnd),
    updatedDateStart: dateToJson(obj.updatedDateStart),
    updatedDateEnd: dateToJson(obj.updatedDateEnd),
    coDateStart: dateToJson(obj.coDateStart),
    coDateEnd: dateToJson(obj.coDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityToJson)(obj.page),
})

export const coListFactorFactorFromJson: Project<CoListFactorFactorJson, CoListFactor> = json => ({
    ...json,
    createdDateStart: dateFromJson(json.createdDateStart),
    createdDateEnd: dateFromJson(json.createdDateEnd),
    updatedDateStart: dateFromJson(json.updatedDateStart),
    updatedDateEnd: dateFromJson(json.updatedDateEnd),
    coDateStart: dateFromJson(json.coDateStart),
    coDateEnd: dateFromJson(json.coDateEnd),
    page: Projects.ignoreIfNull(pageViewEntityFromJson)(json.page),
})

interface Required extends JsonRequired {

}

interface Optional extends Omit<JsonOptional, 'page' | 'createdDateStart' | 'createdDateEnd' | 'updatedDateStart' | 'updatedDateEnd' | 'coDateStart' | 'coDateEnd'> {
    orderTypeList: OrderType[],
    statusList: CoStatus[],
    createdDateStart: Date,
    createdDateEnd: Date,
    updatedDateStart: Date,
    updatedDateEnd: Date,
    coDateStart: Date,
    coDateEnd: Date,
    page: PageViewEntity,
}

interface JsonRequired {

}



interface JsonOptional {
    orderReference: string,
    status: number,
    createdDateStart: string,
    createdDateEnd: string,
    updatedDateStart: string,
    updatedDateEnd: string,
    coDateStart: string,
    coDateEnd: string,
    statusList: number[],
    coSpotIdList: number[],
    orderTypeList: number[],
    page: PageViewEntityJson
}

