import { Route, Routes } from "react-router-dom"
import { AuthNavigate } from "../components/AuthNavigate/AuthNavigate"
import { NotFound } from "../layouts/NotFound/NotFound"
import { DAS000 } from "../layouts/dashboard/DAS000/DAS000"

export function DashboardRoutes() {
    return <Routes>
        <Route path="/dashboard/pomanagement" element={<AuthNavigate access="DASH.DAS010.VIEW"><DAS000 /></AuthNavigate>} />,
        <Route path="/dashboard/costs" element={<AuthNavigate access="DASH.DAS012.VIEW"><DAS000 /></AuthNavigate>} />,
        <Route path="/dashboard/vendorAnalysis" element={<AuthNavigate access="DASH.DAS013.VIEW"><DAS000 /></AuthNavigate>} />,
        <Route path="/dashboard/inventory" element={<AuthNavigate access="DASH.DAS014.VIEW"><DAS000 /></AuthNavigate>} />,
        <Route path="/dashboard/acctReceivable" element={<AuthNavigate access="DASH.DAS015.VIEW"><DAS000 /></AuthNavigate>} />,
        <Route path="/dashboard" element={<AuthNavigate><DAS000 /></AuthNavigate>} />,
        <Route path="*" element={<NotFound scope="dashboard" />} />,
    </Routes>
}