/* eslint-disable react-hooks/rules-of-hooks */
import { Button, DialogActions, DialogContent, IconButton, Input, makeStyles, Step, StepLabel, Stepper, Tooltip } from '@material-ui/core'
import { InfoSharp, Refresh, SaveOutlined } from '@material-ui/icons'
import { Action, Column, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Editing, Filtering, NumberTypeProvider, PaginationLayout, Paging, Row, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarActionProvider, ToolbarLayout } from '@rithe/data-grid'
import { Break, DateItem, EntryItem, Form, Message, NumberItem, StringItem } from "@rithe/form"
import { GridItem } from '@rithe/ui'
import { Arrays, arrx, Objects, Records } from '@rithe/utils'
import { memo, default as React, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { Access } from '../../../components/Access/Access'
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from '../../../components/DataGrid/components/ColumnVisibilityToolbarButton'
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { FlexTable } from '../../../components/DataGrid/components/FlexTable'
import { Pagination } from '../../../components/DataGrid/components/Pagination'
import { SearchInput } from '../../../components/DataGrid/components/SearchInput'
import { CallbackToolbarAction } from '../../../components/DataGrid/toolbarActions/CallbackToolbarAction'
import { DownloadGroupedToolbarAction } from '../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction'
import { GroupedCallbackItem } from '../../../components/DataGrid/toolbarActions/GroupedCallbackItem'
import { UploadGroupedToolbarAction } from '../../../components/DataGrid/toolbarActions/UploadGroupedToolbarAction'
import { CodeCategoryTypeProvider } from '../../../components/DataGrid/typeProviders/CodeCategoryTypeProvider'
import { LibertyTypeProvider } from '../../../components/DataGrid/typeProviders/LibertyTypeProvider'
import { PercentTypeProvider } from '../../../components/DataGrid/typeProviders/PercentTypeProvider'
import { DarkDialog } from '../../../components/Dialog/DarkDialog'
import { DialogAction } from '../../../components/Dialog/DialogAction'
import { DialogHeader } from '../../../components/Dialog/DialogHeader'
import { CodeItem } from '../../../components/Form/CodeItem'
import { View } from "../../../components/View/View"
import { useFunctionStore } from '../../../Root'
import { ScreenMode } from '../../../services/common/enums/ScreenMode'
import { useDownloadworking } from '../../../services/master/apis/masterDownloadApi'
import { useUploadMasterworkingMaster } from '../../../services/master/apis/masterUploadApi'
import { useRejectRequest } from '../../../services/master/apis/requestApi'
import { useReleaseWorking, useSaveAndConfirmWorking, useSaveWorking, useSaveWorkingParts, useSaveWorkingPartsTemp, useSaveWorkingTemp } from '../../../services/master/apis/workingApi'
import { CbdsType } from '../../../services/master/enums/CbdsType'
import { CodeCategory } from '../../../services/master/enums/CodeCategory'
import { ContractRequestType } from '../../../services/master/enums/ContaractRequestType'
import { ContractRequestStatus } from '../../../services/master/enums/ContractRequestStatus'
import { ContractRouteStatus } from '../../../services/master/enums/ContractRouteStatus'
import { ContractType } from '../../../services/master/enums/ContractType'
import { CustomsFlag } from '../../../services/master/enums/CustomsFlag'
import { FinalSellerFlag } from '../../../services/master/enums/FinalSellerFlag'
import { WorkingStatus } from '../../../services/master/enums/WorkingStatus'
import { PartsDetailResult } from '../../../services/master/models/PartsDetailResult'
import { RejectRequestFactor } from '../../../services/master/models/RejectRequestFactor'
import { RequestHeaderResult } from '../../../services/master/models/RequestHeaderResult'
import { TnmCurrency } from '../../../services/master/models/TnmCurrency'
import { TnmIncoterms } from '../../../services/master/models/TnmIncoterms'
import { TnmPaymentTerms } from '../../../services/master/models/TnmPaymentTerms'
import { TnmShippingRoute } from '../../../services/master/models/TnmShippingRoute'
import { TnmUom } from '../../../services/master/models/TnmUom'
import { TnvCbds } from '../../../services/master/models/TnvCbds'
import { WorkingFactor } from '../../../services/master/models/WorkingFactor'
import { WorkingPartsList } from '../../../services/master/models/WorkingPartsList'
import { ContractRoute, WorkingResult, workingResultToJson } from '../../../services/master/models/WorkingResult'
import { OrderFrequency } from '../../../services/order/enums/OrderFrequency'
import { useGetCompanyType } from '../../../utils/ApplicationUtils'
import sessionKeys from '../../../utils/sessionKeys'
import { useFieldChecker, useFormCheck, useFormValidater } from '../../../utils/ValidatorUtils'
import { applicationActions } from "../../Application/applicationSlice"
const searchCachekey = sessionKeys.Data_MOS031
export interface AddNewPartsPcUiProps {
    mode: ScreenMode,
    requesHeaderInfo: RequestHeaderResult,
    requesPartsList: PartsDetailResult[],
    data: WorkingResult,
    setData: React.Dispatch<React.SetStateAction<WorkingResult>>,
    partsDataList: WorkingPartsList[],
    setPartsDataList: React.Dispatch<React.SetStateAction<WorkingPartsList[]>>,
    nextPartsDataList: WorkingPartsList[],
    setNextPartsDataList: React.Dispatch<React.SetStateAction<WorkingPartsList[]>>,
    cbdsList: TnvCbds[],
    currencyList: TnmCurrency[],
    shippingRouteList: TnmShippingRoute[],
    incotermsList: TnmIncoterms[],
    paymentTermList: TnmPaymentTerms[],
    requestNo: string,
    requestType: ContractRequestType,
    viewNum: number,
    setViewNum: React.Dispatch<React.SetStateAction<number>>,
    uomList: TnmUom[],
    view4DescriptionItemList: View4DescriptionItem[],
    setView4DescriptionItem: React.Dispatch<React.SetStateAction<View4DescriptionItem[]>>,
    getView4DescriptionArray: (nextPartsDataList: WorkingPartsList[] | undefined | null) => void,
}

export interface View4DescriptionItem {
    index: number,
    nextSellerCode: string,
    description: string | undefined
}

export const MOS031AddNewPartsPcUi = memo((props: AddNewPartsPcUiProps) => {
    const { viewNum, setViewNum, mode, requesHeaderInfo, requesPartsList, data, setData, partsDataList, setPartsDataList, cbdsList, currencyList, shippingRouteList, incotermsList, paymentTermList, nextPartsDataList, setNextPartsDataList, uomList, view4DescriptionItemList, setView4DescriptionItem, getView4DescriptionArray } = props
    const [messages, setMessages] = useState<Message[]>([])
    const [contractRouteList, setContractRouteList] = useState<ContractRoute[]>([])
    const companyType = useGetCompanyType()
    const showView4 = companyType !== CbdsType.SUPP
    const uomArr = useMemo(() => Arrays.distinct(uomList.map((m) => m.uomCode)), [uomList])
    const currencyArr = useMemo(() => currencyList.map((m) => m.currencyCode), [currencyList])
    const weightDigits = useMemo(() => uomList.find(f => "KG" === f.uomCode)?.decimalDigits, [uomList])
    const volumeDigits = useMemo(() => uomList.find(f => "M3" === f.uomCode)?.decimalDigits, [uomList])

    return (
        <View actions={<ActiveStepView showView4={showView4} viewNum={viewNum} setViewNum={setViewNum} data={data} setData={setData} setPartsDataList={setPartsDataList} partsDataList={partsDataList} mode={mode} setMessages={setMessages} setContractRouteList={setContractRouteList} nextPartsDataList={nextPartsDataList} setNextPartsDataList={setNextPartsDataList} view4DescriptionItemList={view4DescriptionItemList} getView4DescriptionArray={getView4DescriptionArray} />}>
            {viewNum === 0 && <View1 viewNum={viewNum} data={data} requesHeaderInfo={requesHeaderInfo} requesPartsList={requesPartsList} mode={mode} />}
            {viewNum === 1 && <View2 viewNum={viewNum} data={data} setData={setData} partsDataList={partsDataList} setPartsDataList={setPartsDataList} mode={mode} setContractRouteList={setContractRouteList} uomArr={uomArr} currencyArr={currencyArr} weightDigits={weightDigits} volumeDigits={volumeDigits} />}
            {viewNum === 2 && <View3 viewNum={viewNum} data={data} setData={setData} cbdsList={cbdsList}
                requesHeaderInfo={requesHeaderInfo} requesPartsList={requesPartsList}
                currencyList={currencyList} shippingRouteList={shippingRouteList} incotermsList={incotermsList} paymentTermList={paymentTermList} contractRouteList={contractRouteList}
                mode={mode} partsDataList={partsDataList} setPartsDataList={setPartsDataList} messages={messages} setMessages={setMessages} />}
            {viewNum === 3 && showView4 && <View4 viewNum={viewNum} data={data} mode={mode} nextPartsDataList={nextPartsDataList} setNextPartsDataList={setNextPartsDataList} headerInfo={data} uomList={uomList} currencyList={currencyList} view4DescriptionItemList={view4DescriptionItemList} setView4DescriptionItem={setView4DescriptionItem} weightDigits={weightDigits} volumeDigits={volumeDigits} />}
        </View >
    )
})

const ActiveStepView = memo(({ showView4, viewNum, setViewNum, data, setData, setPartsDataList, partsDataList, mode, setMessages, setContractRouteList, nextPartsDataList, setNextPartsDataList, view4DescriptionItemList, getView4DescriptionArray }: {
    showView4: boolean,
    viewNum: number,
    setViewNum: React.Dispatch<React.SetStateAction<number>>,
    data: WorkingResult,
    setData: React.Dispatch<React.SetStateAction<WorkingResult>>,
    setPartsDataList: React.Dispatch<React.SetStateAction<WorkingPartsList[]>>,
    partsDataList: WorkingPartsList[],
    mode: ScreenMode,
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
    setContractRouteList: React.Dispatch<React.SetStateAction<ContractRoute[]>>,
    nextPartsDataList: WorkingPartsList[],
    setNextPartsDataList: React.Dispatch<React.SetStateAction<WorkingPartsList[]>>,
    view4DescriptionItemList: View4DescriptionItem[],
    getView4DescriptionArray: (nextPartsDataList: WorkingPartsList[] | undefined | null) => void
}) => {
    // const [skipped, setSkipped] = useState<number[]>([1, 2, 3])
    const stepStyles = useStepStyles()
    const stepNo = useMemo(() => {
        return data.stepNo ?? 1
    }, [data.stepNo])
    const labelProps: { optional?: React.ReactNode } = {}

    return <>
        <Stepper activeStep={viewNum} className={stepStyles.root}>
            <Step key={1} completed={0 < stepNo ? true : false}><StepLabel {...labelProps}>{1}</StepLabel></Step>
            <Step key={2} completed={1 < stepNo ? true : false}><StepLabel {...labelProps}>{2}</StepLabel></Step>
            <Step key={3} completed={2 < stepNo ? true : false}><StepLabel  {...labelProps}>{3}</StepLabel></Step>
            {showView4 && <Step key={4} completed={3 < stepNo ? true : false}><StepLabel  {...labelProps}>{4}</StepLabel></Step>}
            <BackAction viewNum={viewNum} setViewNum={setViewNum} data={data} />
            <NextAction showView4={showView4} viewNum={viewNum} setViewNum={setViewNum} data={data} setData={setData} setPartsDataList={setPartsDataList} partsDataList={partsDataList} mode={mode} setMessages={setMessages} setContractRouteList={setContractRouteList} nextPartsDataList={nextPartsDataList} setNextPartsDataList={setNextPartsDataList} view4DescriptionItemList={view4DescriptionItemList} getView4DescriptionArray={getView4DescriptionArray} />
        </Stepper>
    </>
})

const NextAction = (({ showView4, viewNum, setViewNum, data, setData, setPartsDataList, partsDataList, mode, setMessages, setContractRouteList, nextPartsDataList, setNextPartsDataList, view4DescriptionItemList, getView4DescriptionArray }: {
    showView4: boolean,
    viewNum: number,
    setViewNum: React.Dispatch<React.SetStateAction<number>>,
    data: WorkingResult,
    setData: React.Dispatch<React.SetStateAction<WorkingResult>>,
    setPartsDataList: React.Dispatch<React.SetStateAction<WorkingPartsList[]>>,
    partsDataList: WorkingPartsList[],
    mode: ScreenMode,
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
    setContractRouteList: React.Dispatch<React.SetStateAction<ContractRoute[]>>,
    nextPartsDataList: WorkingPartsList[],
    setNextPartsDataList: React.Dispatch<React.SetStateAction<WorkingPartsList[]>>,
    view4DescriptionItemList: View4DescriptionItem[],
    getView4DescriptionArray: (nextPartsDataList: WorkingPartsList[] | undefined | null) => void
}) => {
    const { contractType, orderFrequency, srId , shipperUid } = data
    const contractBasicFields = useMemo(() => getContractBasicFormCheckFields(true, contractType, orderFrequency), [contractType, orderFrequency])
    const paymentFields = getPaymentFormCheckFields(true)
    const companyType = useGetCompanyType()
    const isBu = companyType === CbdsType.BU
    const isSupplier = companyType === CbdsType.SUPP
    const deliveryFields = getDeliveryFormCheckFields(true, srId, shipperUid, isBu, isSupplier)
    const customsFields = getCustomsFormCheckFields(true)
    const fields = useMemo(() => { return { ...contractBasicFields, ...paymentFields, ...deliveryFields, ...customsFields } }, [contractBasicFields, customsFields, deliveryFields, paymentFields])
    const formValidate = useFormValidater(setMessages, fields)
    const [disabled, setDisabled] = useState<boolean>(false)
    const saveWorkingParts = useSaveWorkingParts()
    const saveWorking = useSaveWorking()
    const nextClick = useCallback(() => {
        if (viewNum === 1) {
            if (mode === ScreenMode.VIEW) {
                setContractRouteList(data.contractRouteList ?? [])
                setViewNum(2)
            } else {
                setDisabled(true)
                saveWorkingParts({ ...data, partsList: [...partsDataList?.map(m => Objects.delete(m, 'index'))], nextPartsList: [...partsDataList?.map(m => Objects.delete(m, 'index'))] }, { serialized: true }).then((result) => {
                    setData({ ...result })
                    setPartsDataList(result?.partsList?.map((m, i) => ({ ...m, index: i })) ?? partsDataList)
                    setNextPartsDataList(result?.partsList ? [...result.partsList.map((m, i) => ({ ...m, index: i, nextSellerCode: m.finalSellerFlag === FinalSellerFlag.Y ? '' : m.nextSellerCode }))] : [...partsDataList.map((m, i) => ({ ...m, index: i }))])
                    setContractRouteList(result?.contractRouteList ?? [])
                    setViewNum(2)
                }).finally(() => {
                    setDisabled(false)
                })
            }
        } else if (viewNum === 2) {
            if (mode === ScreenMode.VIEW) {
                if (nextPartsDataList.length === 0) {
                    setNextPartsDataList([...partsDataList.map((m, i) => ({ ...m, index: i, nextSellerCode: m.finalSellerFlag === FinalSellerFlag.Y ? '' : m.nextSellerCode }))])
                } else {
                    setNextPartsDataList([...nextPartsDataList.map((m, i) => ({ ...m, index: i, nextSellerCode: m.finalSellerFlag === FinalSellerFlag.Y ? '' : m.nextSellerCode }))])
                }
                getView4DescriptionArray([...nextPartsDataList])
                setViewNum(3)
            } else {
                // check
                if (formValidate(data)) {
                    setMessages([])
                    setDisabled(true)
                    saveWorking({ ...data, partsList: [...partsDataList?.map(m => Objects.delete(m, 'index'))] }, { serialized: true }).then((result) => {
                        setData(result)
                        setPartsDataList(result?.partsList?.map((m, i) => ({ ...m, index: i })) ?? partsDataList)
                        setNextPartsDataList(result?.partsList ? [...result.partsList.map((m, i) => ({ ...m, index: i, nextSellerCode: m.finalSellerFlag === FinalSellerFlag.Y ? '' : m.nextSellerCode }))] : [...partsDataList.map((m, i) => ({ ...m, index: i }))])
                        getView4DescriptionArray([...nextPartsDataList])
                        setViewNum(3)
                    }).finally(() => {
                        setDisabled(false)
                    })
                }
            }
        } else {
            setViewNum(viewNum + 1)
        }
    }, [viewNum, mode, setViewNum, saveWorkingParts, data, partsDataList, setData, setPartsDataList, setNextPartsDataList, setContractRouteList, nextPartsDataList, getView4DescriptionArray, formValidate, setMessages, saveWorking])
    if (viewNum < 2 || (viewNum === 2 && showView4)) {
        return <>
            <Button onClick={nextClick} disabled={viewNum === 3 ? true : disabled} variant="contained" style={{ backgroundColor: '#003362', color: '#fff' }}><FormattedMessage id="next" /></Button >
        </>
    } else {
        if (mode !== ScreenMode.VIEW) {
            return <CompleteAction data={data} nextPartsDataList={nextPartsDataList} showView4={showView4} setMessages={setMessages} formValidate={formValidate} view4DescriptionItemList={view4DescriptionItemList} />
        } else {
            return <>
            </>
        }
    }
})

const BackAction = (({ viewNum, setViewNum, data }: { viewNum: number, setViewNum: React.Dispatch<React.SetStateAction<number>>, data: WorkingResult, }) => {
    const backClick = useCallback(() => {
        setViewNum(viewNum - 1)
    }, [setViewNum, viewNum])
    return <>
        <Button onClick={backClick} disabled={viewNum === 0} variant="contained" style={{ backgroundColor: '#fff' }}><FormattedMessage id="back" /></Button >
    </>
})

const CompleteAction = (({ data, nextPartsDataList, showView4, setMessages, formValidate, view4DescriptionItemList }: {
    data: WorkingResult,
    nextPartsDataList: WorkingPartsList[],
    showView4: boolean,
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
    formValidate: (data: any, actionId?: string | undefined) => boolean,
    view4DescriptionItemList: View4DescriptionItem[]
}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const entity: WorkingFactor = (Objects.delete({ ...data, nextPartsList: nextPartsDataList }, 'paymentTermList', 'page', 'requestHeader', 'requestPartsList'))
    const saveAndConfirmWorking = useSaveAndConfirmWorking()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'complete' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const completeClick = useCallback(() => {
        if (!showView4) {
            if (!formValidate(data)) {
                return
            }
            setMessages([])
        } else {
            let flag: boolean = true
            view4DescriptionItemList.forEach(o => {
                if (o.description !== undefined && o.description !== null && o.description.length > 255) {
                    flag = false;
                    return
                }
            })
            if (!flag) {
                dispatch(applicationActions.pushError({ title: { code: 'complete' }, messages: { code: 'w0689' } }))
                return
            }

            nextPartsDataList.forEach(m => {
                if (view4DescriptionItemList.find(f => f.nextSellerCode === m.nextSellerCode) !== undefined) {
                    m.description = view4DescriptionItemList.find(f => f.nextSellerCode === m.nextSellerCode)?.description
                }
            })
        }
        const functionId = functionStore.register(() => {
            setDisabled(true)
            saveAndConfirmWorking({ ...entity, nextPartsList: nextPartsDataList }, { serialized: true }).then((result) => {
                navigate('/receivedRequest')
            }).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))

    }, [showView4, functionStore, dispatch, title, formValidate, data, setMessages, view4DescriptionItemList, nextPartsDataList, saveAndConfirmWorking, entity, navigate])
    return <>
        <Access access="MARS.MOS031.COMPLETE">
            <Button onClick={completeClick} variant="contained" style={{ backgroundColor: '#003362', color: '#fff' }} disabled={disabled}><FormattedMessage id="complete" /></Button >
        </Access>
    </>
})

const View1 = memo(({ viewNum, requesHeaderInfo, requesPartsList, mode, data }: {
    viewNum: number,
    requesHeaderInfo: RequestHeaderResult,
    requesPartsList: PartsDetailResult[],
    mode: ScreenMode,
    data: WorkingResult,
}) => {
    const intl = useIntl()
    const { routeStatus, status, requestHeader } = data
    const actions = useMemo(() => {
        let actions = []
        if (requestHeader?.status === ContractRequestStatus.SUBMITTED) {
            actions.push(<RejectAction requesHeaderInfo={requesHeaderInfo} />)
        } else if (status === WorkingStatus.CONFIRMED && routeStatus !== ContractRouteStatus.ORDERED) {
            actions.push(<ReleaseAction data={data} />)
        }
        return actions
    }, [data, requesHeaderInfo, requestHeader, routeStatus, status])

    return <>
        <SectionCard allowCollapse>
            <SectionCardHeader
                icon={<InfoSharp />}
                variant='normal'
                title={intl.formatMessage({ id: 'add_new_work_title_one' })}
                subtitle={intl.formatMessage({ id: "add_new_work_message_one" })}
                actions={actions}
            />
            <SectionCardContent>
                <RequestInfoFrom headerInfo={requesHeaderInfo} />
            </SectionCardContent>
        </SectionCard>

        <SectionCard allowCollapse>
            <SectionCardHeader
                icon={<InfoSharp />}
                title={intl.formatMessage({ id: 'add_new_work_title_two' })}
                subtitle={intl.formatMessage({ id: "add_new_work_message_two" })}
            />
            <SectionCardContent>
                <RequestPartsDataGrid partsList={requesPartsList} />
            </SectionCardContent>
        </SectionCard>
    </>
})

const View2 = memo(({ viewNum, data, setData, partsDataList, setPartsDataList, mode, setContractRouteList, uomArr, currencyArr, weightDigits, volumeDigits }: {
    viewNum: number,
    data: WorkingResult,
    setData: React.Dispatch<React.SetStateAction<WorkingResult>>,
    partsDataList: WorkingPartsList[],
    setPartsDataList: React.Dispatch<React.SetStateAction<WorkingPartsList[]>>,
    mode: ScreenMode,
    setContractRouteList: React.Dispatch<React.SetStateAction<ContractRoute[]>>,
    uomArr: string[],
    currencyArr: string[],
    weightDigits: number | undefined,
    volumeDigits: number | undefined
}) => {
    const intl = useIntl()
    const actions = mode === ScreenMode.VIEW ? [] : [<DownLoadAction workingResult={data} partsDataList={partsDataList} />, <UploadAction workingResult={data} partsDataList={partsDataList} setPartsDataList={setPartsDataList} />]
    return <>
        {mode !== ScreenMode.VIEW && <SectionCard allowCollapse>
            <SectionCardHeader
                icon={<InfoSharp />}
                title={intl.formatMessage({ id: 'add_new_work_title_three' })}
                subtitle={intl.formatMessage({ id: "add_new_work_message_three" })}
                actions={actions}
            />
        </SectionCard>}

        <SectionCard allowCollapse>
            <SectionCardHeader
                icon={<InfoSharp />}
                title={intl.formatMessage({ id: 'add_new_work_title_four' })}
                subtitle={intl.formatMessage({ id: "add_new_work_message_four" })}
                actions={mode === ScreenMode.VIEW ? [] : <SavePartsDataListAction workingResult={data} setWorkingResult={setData} setPartsDataList={setPartsDataList} partsDataList={partsDataList} setContractRouteList={setContractRouteList} />}
            />
            <SectionCardContent>
                <WorkingPartsDataGrid partsDataList={partsDataList} setPartsDataList={setPartsDataList} headerInfo={data} mode={mode} uomArr={uomArr} currencyArr={currencyArr} weightDigits={weightDigits} volumeDigits={volumeDigits} />
            </SectionCardContent>
        </SectionCard>
    </>
})

const View3 = memo(({ viewNum, data, requesHeaderInfo, requesPartsList, setData, cbdsList, currencyList, shippingRouteList, incotermsList, mode, partsDataList, setPartsDataList, paymentTermList, messages, setMessages, contractRouteList }: {
    viewNum: number,
    data: WorkingResult,
    requesHeaderInfo: RequestHeaderResult,
    requesPartsList: PartsDetailResult[],
    setData: React.Dispatch<React.SetStateAction<WorkingResult>>,
    cbdsList: TnvCbds[],
    currencyList: TnmCurrency[],
    shippingRouteList: TnmShippingRoute[],
    incotermsList: TnmIncoterms[],
    mode: ScreenMode,
    partsDataList: WorkingPartsList[],
    setPartsDataList: React.Dispatch<React.SetStateAction<WorkingPartsList[]>>,
    paymentTermList: TnmPaymentTerms[],
    messages: Message[],
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
    contractRouteList: ContractRoute[]
}) => {
    const intl = useIntl()
    const { contractType, contractId } = data
    const readonly = mode === ScreenMode.VIEW || (contractId !== undefined && contractId !== null)
    const actions = readonly ? [] : [<TheFiveDivButton data={data} setData={setData} partsDataList={partsDataList} setPartsDataList={setPartsDataList} setMessages={setMessages} />]

    return <>
        <SectionCard allowCollapse>
            <SectionCardHeader
                icon={<InfoSharp />}
                title={intl.formatMessage({ id: "add_new_work_title_five" })}
                subtitle={intl.formatMessage({ id: "add_new_work_message_five" })}
                actions={actions}
            />
            <SectionCardContent>
                {contractType !== undefined && <ContractBaseInfoView headerInfo={data} setHeaderInfo={setData} cbdsList={cbdsList} mode={mode} messages={messages} setMessages={setMessages} />}
            </SectionCardContent>
        </SectionCard>

        <SectionCard allowCollapse>
            <SectionCardHeader
                icon={<InfoSharp />}
                title={intl.formatMessage({ id: "add_new_work_title_six" })}
                subtitle={intl.formatMessage({ id: "add_new_work_message_six" })}
                actions={readonly ? [] : [<AddPaymentTermsAction data={data} requesHeaderInfo={requesHeaderInfo} requesPartsList={requesPartsList} partsDataList={partsDataList} />]}
            />
            <SectionCardContent>
                {contractType !== undefined && <PaymentInfoView headerInfo={data} setHeaderInfo={setData} currencyList={currencyList} mode={mode} paymentTermList={paymentTermList} messages={messages} setMessages={setMessages} />}
            </SectionCardContent>
        </SectionCard>

        <SectionCard allowCollapse>
            <SectionCardHeader
                icon={<InfoSharp />}
                title={intl.formatMessage({ id: "add_new_work_title_seven" })}
                subtitle={intl.formatMessage({ id: "add_new_work_message_seven" })}
                actions={readonly ? [] : [<AddShippingRouteAction data={data} requesHeaderInfo={requesHeaderInfo} requesPartsList={requesPartsList} partsDataList={partsDataList} />, <AddDistributionCenterAction data={data} requesHeaderInfo={requesHeaderInfo} requesPartsList={requesPartsList} partsDataList={partsDataList} />]}
            />
            <SectionCardContent>
                {contractType !== undefined && <DeliveryInfoView headerInfo={data} setHeaderInfo={setData} cbdsList={cbdsList} shippingRouteList={shippingRouteList} mode={mode} messages={messages} setMessages={setMessages} contractRouteList={contractRouteList} />}
            </SectionCardContent>
        </SectionCard>

        <SectionCard allowCollapse>
            <SectionCardHeader
                icon={<InfoSharp />}
                title={intl.formatMessage({ id: "add_new_work_title_eight" })}
                subtitle={intl.formatMessage({ id: "add_new_work_message_eight" })}
            />
            <SectionCardContent>
                {contractType !== undefined && <CustomsInfoView headerInfo={data} setHeaderInfo={setData} cbdsList={cbdsList} mode={mode} messages={messages} setMessages={setMessages} />}
            </SectionCardContent>
        </SectionCard>

        <SectionCard allowCollapse>
            <SectionCardHeader
                icon={<InfoSharp />}
                title={intl.formatMessage({ id: "add_new_work_title_incoterms" })}
                subtitle={intl.formatMessage({ id: "add_new_work_message_incoterms" })}
            />
            <SectionCardContent>
                {contractType !== undefined && <IncotermsInfoView headerInfo={data} setHeaderInfo={setData} mode={mode} incotermsList={incotermsList} messages={messages} setMessages={setMessages} />}
            </SectionCardContent>
        </SectionCard>
    </>
})

const View4 = memo(({ viewNum, data, mode, headerInfo, nextPartsDataList, setNextPartsDataList, uomList, currencyList, view4DescriptionItemList, setView4DescriptionItem, weightDigits, volumeDigits }: {
    viewNum: number,
    data: WorkingResult,
    mode: ScreenMode,
    headerInfo: WorkingResult,
    nextPartsDataList: WorkingPartsList[],
    setNextPartsDataList: React.Dispatch<React.SetStateAction<WorkingPartsList[]>>,
    uomList: TnmUom[],
    currencyList: TnmCurrency[],
    view4DescriptionItemList: View4DescriptionItem[],
    setView4DescriptionItem: React.Dispatch<React.SetStateAction<View4DescriptionItem[]>>,
    weightDigits: number | undefined,
    volumeDigits: number | undefined

}) => {
    const groupBySellerCodeList = Arrays.distinct(nextPartsDataList.map(m => m.nextSellerCode ?? "")).map(o => nextPartsDataList.filter(f => (f.nextSellerCode ?? "") === o))
    const uomArr = useMemo(() => Arrays.distinct(uomList.map((m) => m.uomCode)), [uomList])
    const currencyArr = useMemo(() => currencyList.map((m) => m.currencyCode), [currencyList])

    return <>
        {groupBySellerCodeList.map((m, i) => {
            return <WorkingPartsDataGridCard mode={mode} nextPartsDataList={m} index={i} headerInfo={headerInfo} setNextPartsDataList={setNextPartsDataList} uomArr={uomArr} currencyArr={currencyArr} view4DescriptionItemList={view4DescriptionItemList} setView4DescriptionItem={setView4DescriptionItem} weightDigits={weightDigits} volumeDigits={volumeDigits} />
        })}
    </>
})

const RequestInfoFrom = memo(({ headerInfo }: {
    headerInfo: RequestHeaderResult
}) => {
    const intl = useIntl()
    return <Form data={headerInfo} labelDisplay="block" helperDisplay="tooltip" >
        <StringItem field="requestNo" readonly label={intl.formatMessage({ id: 'field.requestNo' })} />
        <StringItem field="submittedBy" readonly label={intl.formatMessage({ id: 'field.submittedBy' })} />
        <CodeItem field="status" readonly label={intl.formatMessage({ id: 'field.status' })} code={CodeCategory.ContractRequestStatus} />

        <CodeItem field="requestType" readonly label={intl.formatMessage({ id: 'field.requestType' })} code={CodeCategory.ContractRequestType} />
        <DateItem field="submittedDate" readonly label={intl.formatMessage({ id: 'field.submittedDate' })} />
        <Break />

        <StringItem field="requestFromCode" readonly label={intl.formatMessage({ id: 'field.requestFrom' })} />
        <StringItem field="requestToCode" readonly label={intl.formatMessage({ id: 'field.requestTo' })} />
        <Break />

        <StringItem field="description" readonly label={intl.formatMessage({ id: 'field.description' })} colSpan={2} />
    </Form>
})

const RejectAction = ({ requesHeaderInfo }: {
    requesHeaderInfo: RequestHeaderResult,
}) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [remark, setRemark] = useState<RejectRequestFactor>({} as any)
    const [messages, setMessages] = useState<Message[]>([])
    const [open, setOpen] = useState<boolean>(false)
    const callback = useCallback(() => {
        setOpen(true)
    }, [])
    const fields = {
        rejectReason: { labelId: 'field.rejectReason', required: true, length: { max: 255 } },
    }
    const filedCheck = useFormCheck(fields)
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'reject' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const rejectRequest = useRejectRequest()
    const reject = useCallback(() => {
        if (requesHeaderInfo.requestId) {
            setMessages([])
            const msg = filedCheck(remark)
            if (msg.length === 0) {
                setOpen(false)
                const functionId = functionStore.register(() => {
                    setDisabled(true)
                    rejectRequest({ ...remark, requestId: requesHeaderInfo.requestId, requestIdList: [requesHeaderInfo.requestId] }, { serialized: true }).then((result) => {
                        navigate('/receivedRequest')
                    }).finally(() => {
                        setDisabled(false)
                    })
                })
                dispatch(applicationActions.pushWarning({
                    title: title,
                    messages: { code: 'c0001', args: [title] },
                    actions: [{
                        label: 'CANCEL'
                    }, {
                        functionId,
                        label: 'CONFIRM',
                    }]
                }))
            } else {
                setMessages(msg)
                if (remark.rejectReason === undefined || remark.rejectReason === null) {
                    dispatch(applicationActions.pushError({ title: { code: intl.formatMessage({ id: 'field.rejectReason' }) }, messages: { code: 'w0001', args: [intl.formatMessage({ id: 'field.rejectReason' })] } }))
                } else if ((remark.rejectReason.length > 255)) {
                    dispatch(applicationActions.pushError({ title: { code: intl.formatMessage({ id: 'field.rejectReason' }) }, messages: { code: 'w0019', args: [intl.formatMessage({ id: 'field.rejectReason' }), 255] } }))
                } else {
                    dispatch(applicationActions.pushError({ title: { code: intl.formatMessage({ id: 'field.rejectReason' }) }, messages: { code: 'w0345' } }))
                }
            }
        } else {
            dispatch(applicationActions.pushError({ title: { code: 'reject' }, messages: { code: 'w0002' } }))
        }
    }, [dispatch, filedCheck, functionStore, intl, navigate, rejectRequest, remark, requesHeaderInfo.requestId, title])

    return <>
        <Access access="MARS.MOS031.REJECT">
            <Button onClick={callback} variant="contained" style={{ backgroundColor: '#fff' }}><FormattedMessage id="reject" /></Button >
        </Access>
        <DarkDialog open={open} style={{ overflow: 'hidden' }} maxWidth="sm" fullWidth fullScreen={false}>
            <DialogHeader onClose={() => setOpen(false)}>
                <FormattedMessage id="isRequestReject" />
            </DialogHeader>
            <DialogContent>
                <Form data={remark} setData={setRemark} labelDisplay="block" helperDisplay="tooltip" minWidth={300} columnCount={1} messages={messages}>
                    <GridItem columnSpan={3}><FormattedMessage id="isRequestRejectSub" /></GridItem>
                    <StringItem field="rejectReason" required label={intl.formatMessage({ id: 'field.rejectReason' })} helperDisplay="tooltip" />
                </Form>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={() => setOpen(false)} />
                <DialogAction title={<FormattedMessage id="reject" />} callback={reject} disabled={disabled} />
            </DialogActions>
        </DarkDialog>
    </>
}

const RequestPartsDataGrid = memo(({ partsList }: {
    partsList: PartsDetailResult[]
}) => {
    const intl = useIntl()
    const companyType = useGetCompanyType()
    const columns = useMemo(() => arrx(

        { field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'buyerPartsNo' }), width: 180 },
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'globalPartsNo' }), width: 180 },
        { field: 'unitPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'buyerPartsName' }), width: 180 },

        (companyType === CbdsType.CUST) ?
            { field: 'partsRefNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsRefNo' }), width: 180 } : undefined,
        (companyType === CbdsType.CUST) ?
            { field: 'backNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.backNo' }), width: 180 } : undefined,
        (companyType === CbdsType.CUST) ?
            { field: 'hscode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.hscode' }), width: 180 } : undefined,
        (companyType === CbdsType.CUST) ?
            { field: 'uomCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.uomCode' }), width: 180 } : undefined,
        (companyType === CbdsType.CUST) ?
            { field: 'pairedFlag', dataTypeName: CodeCategory.PairedFlag, title: intl.formatMessage({ id: 'field.pairedFlag' }), width: 180 } : undefined,
        (companyType === CbdsType.CUST) ?
            { field: 'pairedPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'pairedPartsNo' }), width: 180 } : undefined,
        (companyType === CbdsType.CUST) ?
            { field: 'pairedOrderFlag', dataTypeName: CodeCategory.PairedOrderFlag, title: intl.formatMessage({ id: 'field.pairedOrderFlag' }), width: 180 } : undefined,
        { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 200 },
        { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 200 },
        { field: 'm3', dataTypeName: 'volume', title: intl.formatMessage({ id: 'field.m3' }), width: 200 },
        { field: 'netWeight', dataTypeName: 'weight', title: intl.formatMessage({ id: 'field.netWeight' }), width: 200 },
        { field: 'grossWeight', dataTypeName: 'weight', title: intl.formatMessage({ id: 'field.grossWeight' }), width: 200 },
        { field: 'sellerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sellerPartsNo' }), width: 200 },
    ), [companyType, intl])
    return <div style={{ width: '100%', height: '500px' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout Table={FlexTable} />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.ContractRequestStatus} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.PairedOrderFlag} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.PairedFlag} />
            <NumberTypeProvider name="weight" options={{ minimumFractionDigits: 6, maximumFractionDigits: 6 }} />
            <NumberTypeProvider name="volume" options={{ minimumFractionDigits: 6, maximumFractionDigits: 6 }} />
            <Data rows={partsList} columns={columns} />
            <ColumnFreeze />
            <ColumnVisibility
                defaultHiddenFields={[]}
                ToolbarButton={ColumnVisibilityToolbarButton}
            />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
            <Action width={52} />
        </DataGrid>
    </div>
})

const DownLoadAction = ({ workingResult, partsDataList }: {
    workingResult: WorkingResult,
    partsDataList: WorkingPartsList[],
}) => {
    const downloadworking = useDownloadworking()
    const downLoadMethod = useCallback(() => {
        const working = { ...workingResult, paymentTermList: undefined, nextPartsList: [...partsDataList], partsList: [...partsDataList] }
        downloadworking({ ...working }, { serialized: true })
    }, [downloadworking, partsDataList, workingResult])
    return <DownloadGroupedToolbarAction>
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="downloadPartFrom" />} callback={downLoadMethod} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}

const UploadAction = ({ workingResult, partsDataList, setPartsDataList }: {
    workingResult: WorkingResult,
    partsDataList: WorkingPartsList[],
    setPartsDataList: React.Dispatch<React.SetStateAction<WorkingPartsList[]>>,
}) => {
    const { contractType } = workingResult
    const finalSellerFlag = (contractType === ContractType.CUST_SUPP || contractType === ContractType.BU_SUPP) ? FinalSellerFlag.Y : (partsDataList !== undefined && partsDataList.length > 0) ? partsDataList[0].finalSellerFlag : FinalSellerFlag.N

    const dispatch = useDispatch()
    const uploadMasterApi = useUploadMasterworkingMaster()
    const uploadMaster = useCallback((popupUpload: (callback: (files: FileList | null) => void) => void) => {
        popupUpload((files: FileList | null) => {
            files && uploadMasterApi({ file: files[0], workingId: workingResult.workingId, requestId: workingResult.requestId, finalSellerFlag: finalSellerFlag }, { silent: false, serialized: true }).then(result => {
                if (result.partsList) {
                    if (result.partsList.length !== partsDataList.length) {
                        dispatch(applicationActions.pushError({ title: { code: 'uploadMaster' }, messages: { code: 'w0384' } }))
                        return
                    }
                    partsDataList.forEach((item) => {
                        if (result.partsList?.find(f => f.workingDetailId === item.workingDetailId && f.buyerPartsNo === item.buyerPartsNo && f.requestNo === item.requestNo) === undefined) {
                            dispatch(applicationActions.pushError({ title: { code: 'uploadMaster' }, messages: { code: 'w0384' } }))
                            return
                        }
                    })
                    setPartsDataList([])
                    setPartsDataList(result.partsList.map((m, i) => ({ ...m, index: i })))
                }
            })
        })
    }, [uploadMasterApi, workingResult.workingId, workingResult.requestId, finalSellerFlag, partsDataList, setPartsDataList, dispatch])
    return <UploadGroupedToolbarAction >
        {(popupUpload, onClose) => <>
            <GroupedCallbackItem label={<FormattedMessage id="uploadMaster" />} callback={() => uploadMaster(popupUpload)} onClose={onClose} />
        </>}
    </UploadGroupedToolbarAction>
}

const WorkingPartsDataGrid = memo(({ partsDataList, setPartsDataList, headerInfo, mode, uomArr, currencyArr, weightDigits, volumeDigits }: {
    partsDataList: WorkingPartsList[],
    setPartsDataList: React.Dispatch<React.SetStateAction<WorkingPartsList[]>>,
    headerInfo: WorkingResult,
    mode: ScreenMode,
    uomArr: string[],
    currencyArr: string[],
    weightDigits: number | undefined,
    volumeDigits: number | undefined
}) => {
    const intl = useIntl()
    const { contractType, contractId } = headerInfo
    // const readonly = mode === ScreenMode.VIEW || (contractId !== undefined && contractId !== null)
    const finalSellerFlag = (partsDataList !== undefined && partsDataList.length > 0) ? partsDataList[0].finalSellerFlag : FinalSellerFlag.N
    const editGloberPartsNoFlag = useMemo(() => !(contractType === ContractType.CUST_BU || contractType === ContractType.CUST_SUPP) && contractId !== undefined, [contractId, contractType])
    const columns = useMemo(() => arrx(
        { field: 'buyerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'buyerPartsNo' }), width: 200 },
        { field: 'requestNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.requestNo' }), width: 250 },
        { field: 'sellerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sellerPartsNo' }), width: 200 },
        { field: 'globerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 200 },
        { field: 'unitPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'partsDecription' }), width: 300 },
        { field: 'customerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.customerCode' }), width: 200 },
        { field: 'contractNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contractNo' }), width: 200 },

        /** contractInfo.contractType == CUST_BU or BU_BU */
        (contractType === ContractType.CUST_BU || contractType === ContractType.BU_BU) ?
            { field: 'repackingType', dataTypeName: CodeCategory.RepackingType, title: intl.formatMessage({ id: 'field.repackingType' }), width: 200 } : undefined,
        (contractType === ContractType.CUST_BU || contractType === ContractType.BU_BU) ?
            { field: 'saftyStockDays', dataTypeName: 'number', title: intl.formatMessage({ id: 'safetyStockDays' }), width: 200 } : undefined,
        (contractType === ContractType.CUST_BU || contractType === ContractType.BU_BU) ?
            { field: 'saftyStockPct', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.safetyStockPct' }), width: 200 } : undefined,

        /** contractInfo.contractType == CUST_BU or  CUST_SUPP */
        (contractType === ContractType.CUST_BU || contractType === ContractType.CUST_SUPP) ?
            { field: 'fluctuationRatio', dataTypeName: 'percent', title: intl.formatMessage({ id: 'field.fluctuationRatio' }), width: 200 } : undefined,
        (contractType === ContractType.CUST_BU || contractType === ContractType.CUST_SUPP) ?
            { field: 'fcFluctuationRatio', dataTypeName: 'percent', title: intl.formatMessage({ id: 'field.fcFluctuationRatio' }), width: 230 } : undefined,

        (contractType === ContractType.CUST_SUPP || contractType === ContractType.BU_SUPP) ?
            { field: 'supplierLeadtime', dataTypeName: 'number', title: intl.formatMessage({ id: 'supplierLeadtime' }), width: 180 } : undefined,

        { field: 'buyerUomCode', dataTypeName: 'uomType', title: intl.formatMessage({ id: 'buyerUomCode' }), width: 200 },
        { field: 'sellerUomCode', dataTypeName: 'uomType', title: intl.formatMessage({ id: 'sellerUomCode' }), width: 200 },
        { field: 'uomChangeRate', dataTypeName: 'rateType', title: intl.formatMessage({ id: 'uomChangeRate' }), width: 200 },
        { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 200 },
        { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 200 },
        { field: 'm3', dataTypeName: 'volume', title: intl.formatMessage({ id: 'field.m3' }), width: 200 },
        { field: 'netWeight', dataTypeName: 'weight', title: intl.formatMessage({ id: 'field.netWeight' }), width: 200 },
        { field: 'grossWeight', dataTypeName: 'weight', title: intl.formatMessage({ id: 'field.grossWeight' }), width: 200 },
        { field: 'currency', dataTypeName: 'currencyType', title: intl.formatMessage({ id: 'field.currency' }), width: 200 },
        { field: 'unitPrice', dataTypeName: 'number', title: intl.formatMessage({ id: 'unitPrice' }), width: 200 },

        /** contractInfo.contractType == CUST_BU or  CUST_SUPP */
        (contractType === ContractType.CUST_BU || contractType === ContractType.CUST_SUPP) ?
            { field: 'endUser1', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.endUser1' }), width: 300 } : undefined,
        (contractType === ContractType.CUST_BU || contractType === ContractType.CUST_SUPP) ?
            { field: 'endUser2', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.endUser2' }), width: 300 } : undefined,
        (contractType === ContractType.CUST_BU || contractType === ContractType.CUST_SUPP) ?
            { field: 'endUser3', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.endUser3' }), width: 300 } : undefined,
        (contractType === ContractType.CUST_BU || contractType === ContractType.CUST_SUPP) ?
            { field: 'endUser4', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.endUser4' }), width: 300 } : undefined,
        (contractType === ContractType.CUST_BU || contractType === ContractType.CUST_SUPP) ?
            { field: 'endUser5', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.endUser5' }), width: 300 } : undefined,

        // contractInfo.contractType == CUST_BU or BU_BU
        (contractType === ContractType.CUST_BU || contractType === ContractType.BU_BU) && finalSellerFlag !== FinalSellerFlag.Y ?
            { field: 'nextSellerCode', dataTypeName: 'string', title: intl.formatMessage({ id: 'nextSeller' }), width: 200 } : undefined,
        (contractType === ContractType.CUST_BU || contractType === ContractType.BU_BU) && finalSellerFlag !== FinalSellerFlag.Y ?
            { field: 'nextSellerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.nextSellerPartNo' }), width: 200 } : undefined,
    ), [contractType, finalSellerFlag, intl])

    const actionProps1 = useMemo(() => ({ partsDataList, setPartsDataList, mode, contractType }), [partsDataList, setPartsDataList, mode, contractType])

    if (contractType === undefined || contractType === null) {
        return <></>
    }

    const onEditingRowCommit = useCallback((_: Column, row: Row) => {
        setPartsDataList(partsDataList => partsDataList.map((item, idx) => item.index === row.index ? row as WorkingPartsList : item))
        return true
    }, [setPartsDataList])

    const getRowId = useCallback((row: any) => row.index, [])
    return <div style={{ width: '100%', height: '500px' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout />
            <DataTypePreset />
            <PercentTypeProvider />
            <ToolbarActionProvider Action={SetFinalSellerAction} actionProps={actionProps1} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.PairedOrderFlag} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.PairedFlag} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.ContractRequestStatus} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.RepackingType} />
            <NumberTypeProvider name="weight" options={{ minimumFractionDigits: weightDigits !== undefined ? weightDigits : 3, maximumFractionDigits: weightDigits !== undefined ? weightDigits : 3 }} />
            <NumberTypeProvider name="volume" options={{ minimumFractionDigits: volumeDigits !== undefined ? volumeDigits : 3, maximumFractionDigits: volumeDigits !== undefined ? volumeDigits : 3 }} />
            <NumberTypeProvider name="rateType" options={{ minimumFractionDigits: 6, maximumFractionDigits: 6 }} />
            <LibertyTypeProvider name="uomType" options={uomArr} />
            <LibertyTypeProvider name="currencyType" options={currencyArr} />
            <Data rows={partsDataList} columns={columns} getRowId={getRowId} />
            <ColumnFreeze />
            <ColumnVisibility
                defaultHiddenFields={[]}
                columnSettings={{
                    unitPartsNo: { disableUserControl: true },
                    globalPartsNo: { disableUserControl: true },
                }} />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase />
            <Editing
                enableInlineEdit={mode !== ScreenMode.VIEW}
                columnSettings={{
                    buyerPartsNo: { editingDisabled: true },
                    requestNo: { editingDisabled: true },
                    globerPartsNo: { editingDisabled: editGloberPartsNoFlag },
                    customerCode: { editingDisabled: true },
                    buyerUomCode: { editingDisabled: true },
                }}
                onEditingCellCommit={onEditingRowCommit}
            />
            <Sorting />
            <Filtering />
            <Paging defaultPageSize={20} />
            <Action width={52} />
        </DataGrid>
    </div>
})


const SetFinalSellerAction = ({ partsDataList, setPartsDataList, mode, contractType }: {
    partsDataList: WorkingPartsList[],
    setPartsDataList: React.Dispatch<React.SetStateAction<WorkingPartsList[]>>,
    mode: ScreenMode,
    contractType: ContractType | undefined
}) => {
    const intl = useIntl()
    const finalSellerFlag = (partsDataList !== undefined && partsDataList.length > 0) ? partsDataList[0].finalSellerFlag : FinalSellerFlag.N
    const setAllToFinalSeller = useCallback(() => {
        setPartsDataList(partsDataList => partsDataList.map(item => ({
            ...item,
            finalSellerFlag: FinalSellerFlag.Y,
            nextSellerCode: undefined,
            nextSellerUid: undefined,
            nextSellerPartsId: undefined,
            nextSellerPartsNo: undefined,
        })))
    }, [setPartsDataList])
    const setAllToNonFinalSeller = useCallback(() => {
        setPartsDataList(partsDataList => partsDataList.map(item => ({ ...item, finalSellerFlag: FinalSellerFlag.N })))
    }, [setPartsDataList])
    if (mode !== ScreenMode.VIEW && contractType !== undefined && contractType !== ContractType.CUST_SUPP && contractType !== ContractType.BU_SUPP) {
        if (finalSellerFlag === FinalSellerFlag.Y) {
            // return <CallbackToolbarAction access="MARS.MOS031.FINALSELLERACTION" callback={setAllToNonFinalSeller} title={intl.formatMessage({ id: 'changeToMiddleSeller' })} icon={<Refresh />} />
            return <CallbackToolbarAction callback={setAllToNonFinalSeller} title={intl.formatMessage({ id: 'changeToMiddleSeller' })} icon={<Refresh />} />
        } else {
            // return <CallbackToolbarAction access="MARS.MOS031.FINALSELLERACTION" callback={setAllToFinalSeller} title={intl.formatMessage({ id: 'changeToFinalSeller' })} icon={<Refresh />} />
            return <CallbackToolbarAction callback={setAllToFinalSeller} title={intl.formatMessage({ id: 'changeToFinalSeller' })} icon={<Refresh />} />
        }
    } else {
        return <></>
    }
}

const SavePartsDataListAction = memo(({ workingResult, setWorkingResult, setPartsDataList, partsDataList, setContractRouteList }: {
    workingResult: WorkingResult,
    setWorkingResult: React.Dispatch<React.SetStateAction<WorkingResult>>,
    setPartsDataList: React.Dispatch<React.SetStateAction<WorkingPartsList[]>>,
    partsDataList: WorkingPartsList[],
    setContractRouteList: React.Dispatch<React.SetStateAction<ContractRoute[]>>,
}) => {
    const saveWorkingParts = useSaveWorkingPartsTemp()
    const [disabled, setDisabled] = useState<boolean>(false)
    const saveClick = useCallback(() => {
        setDisabled(true)
        saveWorkingParts({ ...workingResult, partsList: [...partsDataList?.map(m => Objects.delete(m, 'index'))] }, { serialized: true }).then((result) => {
            setWorkingResult(result)
            setPartsDataList(result?.partsList?.map((m, i) => ({ ...m, index: i })) ?? partsDataList)
            setContractRouteList(result?.contractRouteList ?? [])
        }).finally(() => {
            setDisabled(false)
        })
    }, [saveWorkingParts, workingResult, partsDataList, setWorkingResult, setPartsDataList, setContractRouteList])

    return <>
        <Access access="MARS.MOS031.SAVEPARTSDATA">
            <Tooltip title={<FormattedMessage id="save" />} >
                <IconButton onClick={saveClick} disabled={disabled}><SaveOutlined></SaveOutlined></IconButton>
            </Tooltip>
        </Access>
    </>
})

const ContractBaseInfoView = memo(({ headerInfo, cbdsList, setHeaderInfo, mode, messages, setMessages }: {
    headerInfo: WorkingResult,
    setHeaderInfo: React.Dispatch<React.SetStateAction<WorkingResult>>,
    cbdsList: TnvCbds[],
    mode: ScreenMode,
    messages: Message[],
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
}) => {
    const intl = useIntl()
    const { contractType, contractId, orderFrequency } = headerInfo
    const buCustMap: [string, string][] = useMemo(() => cbdsList.filter((m) => m.cbdsType === CbdsType.CUST || m.cbdsType === CbdsType.BU).map(m => [m.cbdsUid, m.cbdsCode]), [cbdsList])
    const dcCustMap: [string, string][] = useMemo(() => cbdsList.filter((m) => m.cbdsType === CbdsType.CUST || m.cbdsType === CbdsType.DC).map(m => [m.cbdsUid, m.cbdsCode]), [cbdsList])
    const buSuppMap: [string, string][] = useMemo(() => cbdsList.filter((m) => m.cbdsType === CbdsType.SUPP || m.cbdsType === CbdsType.BU).map((m) => [m.cbdsUid, m.cbdsCode]), [cbdsList])

    const readonly = mode === ScreenMode.VIEW || (contractId !== undefined && contractId !== null)
    const showItem1 = contractType === ContractType.CUST_BU || contractType === ContractType.CUST_SUPP
    const showItem2 = contractType === ContractType.BU_SUPP || contractType === ContractType.CUST_SUPP

    const fields = useMemo(() => getContractBasicFormCheckFields(true, contractType, orderFrequency), [contractType, orderFrequency])
    const filedCheck = useFieldChecker(fields, setMessages)

    const leadtimeName = orderFrequency === 10 ? 'field.targetleadtimeMonth' : orderFrequency === 20 ? 'field.targetleadtimeWeek' :
        orderFrequency === 30 ? 'field.targetleadtimeBiweek' : orderFrequency === 40 ? 'field.targetleadtimeTenDay' : 'field.targetLeadtime'

    return <Form data={headerInfo} setData={setHeaderInfo} labelDisplay="block" helperDisplay="tooltip" ensure={filedCheck} messages={messages} >
        {/* <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="basicInfo" /></SectionTitle></GridItem> */}
        {/* Basic information */}
        <StringItem required field='contractNo' readonly={readonly} label={intl.formatMessage({ id: 'field.contractNo' })} />
        <EntryItem field="sellerUid" required readonly label={intl.formatMessage({ id: 'seller' })} entries={buSuppMap} />
        <EntryItem field="consigneeUid" required readonly={readonly} label={intl.formatMessage({ id: 'consigneeCode' })} entries={buCustMap} />
        {/* readonly */}
        <CodeItem field="contractType" required readonly label={intl.formatMessage({ id: 'field.contractType' })} code={CodeCategory.ContractType} />
        <EntryItem field="buyerUid" required readonly label={intl.formatMessage({ id: 'buyer' })} entries={buCustMap} />
        <EntryItem field="accounteeUid" required readonly={readonly} label={intl.formatMessage({ id: 'accounteeCode' })} entries={buCustMap} />

        {showItem1 && <CodeItem field="businessType" required readonly={readonly} label={intl.formatMessage({ id: 'field.businessType' })} code={CodeCategory.BusinessType} />}
        {showItem1 && <CodeItem field="orderFrequency" readonly={readonly} required label={intl.formatMessage({ id: 'field.orderFrequency' })} code={CodeCategory.OrderFrequency} />}
        <EntryItem field="deliveryToUid" required readonly={readonly} label={intl.formatMessage({ id: 'deliveryToCode' })} entries={dcCustMap} />
        <Break />

        {showItem1 && <NumberItem field="forecastNumber" readonly={readonly} required label={intl.formatMessage({ id: 'forecastNumber' })} />}
        {showItem1 && <NumberItem field="targetLeadTime" readonly={readonly} required label={intl.formatMessage({ id: leadtimeName })} />}
        <Break />
        {showItem2 && <NumberItem field="confirmOrderLeadtime" readonly={readonly} required label={intl.formatMessage({ id: 'field.confirmOrderLeadtime' })} />}
        {showItem2 && <NumberItem field="leadtime" readonly={readonly} required label={intl.formatMessage({ id: 'field.leadtime' })} />}
        {/* {showItem2 && <CodeItem field="deliveryTypeFlag" required readonly={props.mode === ScreenMode.VIEW} label={intl.formatMessage({ id: 'field.deliveryTypeFlag' })} code={CodeCategory.DeliveryTypeFlag} />} */}
        <Break />
        {showItem2 && <NumberItem field="deliveryPlanStartDate" readonly={readonly} required label={intl.formatMessage({ id: 'field.deliveryPlanStartDate' })} />}
        {showItem2 && <NumberItem field="deliveryPlanEndDate" readonly={readonly} label={intl.formatMessage({ id: 'field.deliveryPlanEndDate' })} />}
        <Break />
        <StringItem field='contractShortCode' readonly={readonly} required label={intl.formatMessage({ id: 'field.contractShortCode' })} />
        <StringItem field="basicDescription" readonly={readonly} label={intl.formatMessage({ id: 'contractDescription' })} colSpan={2} />
    </Form >
})

const TheFiveDivButton = memo(({ data, setData, partsDataList, setPartsDataList, setMessages }: {
    data: WorkingResult,
    setData: React.Dispatch<React.SetStateAction<WorkingResult>>,
    partsDataList: WorkingPartsList[],
    setPartsDataList: React.Dispatch<React.SetStateAction<WorkingPartsList[]>>,
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
}) => {
    const { contractType, orderFrequency } = data
    const contractBasicFields = useMemo(() => getContractBasicFormCheckFields(false, contractType, orderFrequency), [contractType, orderFrequency])
    const paymentFields = getPaymentFormCheckFields(false)
    const deliveryFields = getDeliveryFormCheckFields(false)
    const customsFields = getCustomsFormCheckFields(false)
    const incortermsFields = getIncotermsFormCheckFields(false)
    const fields = useMemo(() => { return { ...contractBasicFields, ...paymentFields, ...deliveryFields, ...customsFields, ...incortermsFields } }, [incortermsFields, contractBasicFields, customsFields, deliveryFields, paymentFields])
    const formValidate = useFormValidater(setMessages, fields)
    const saveWorking = useSaveWorkingTemp()
    const [disabled, setDisabled] = useState<boolean>(false)
    const saveClick = useCallback(() => {
        if (formValidate(data)) {
            setMessages([])
            setDisabled(true)
            saveWorking({ ...data, partsList: [...partsDataList?.map(m => Objects.delete(m, 'index'))] }, { serialized: true }).then((result) => {
                setData(result)
                setPartsDataList(result?.partsList?.map((m, i) => ({ ...m, index: i })) ?? partsDataList)
            }).finally(() => {
                setDisabled(false)
            })
        }
    }, [data, formValidate, partsDataList, saveWorking, setData, setMessages, setPartsDataList])

    return <>
        <Access access="MARS.MOS031.SAVEWORKINGDATA">
            <Tooltip title={<FormattedMessage id="save" />}>
                <IconButton onClick={saveClick} disabled={disabled}><SaveOutlined></SaveOutlined></IconButton>
            </Tooltip>
        </Access>
    </>
})

const PaymentInfoView = memo(({ headerInfo, setHeaderInfo, currencyList, mode, paymentTermList, messages, setMessages }: {
    headerInfo: WorkingResult,
    setHeaderInfo: React.Dispatch<React.SetStateAction<WorkingResult>>,
    currencyList: TnmCurrency[],
    paymentTermList: TnmPaymentTerms[],
    mode: ScreenMode,
    messages: Message[],
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
}) => {
    const intl = useIntl()
    const paymentMap: [number, string][] = useMemo(() => paymentTermList.map((m) => [m.paymentTermsId, m.paymentTermsCode + '(' + m.description + ')']) ?? [], [paymentTermList])
    const { contractId } = headerInfo
    const readonly = mode === ScreenMode.VIEW || (contractId !== undefined && contractId !== null)

    const fields = useMemo(() => getPaymentFormCheckFields(true), [])
    const filedCheck = useFieldChecker(fields, setMessages)
    return <Form data={headerInfo} setData={setHeaderInfo} labelDisplay="block" helperDisplay="tooltip" ensure={filedCheck} messages={messages} >
        {/* <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="paymentInfo" /></SectionTitle></GridItem> */}
        <EntryItem field="paymentTermsId" required readonly={readonly} label={intl.formatMessage({ id: 'field.paymentTermsCode' })} entries={paymentMap} />
        <EntryItem field="currencyCode" required readonly={readonly} label={intl.formatMessage({ id: 'field.currency' })} entries={currencyList.map((m) => [m.currencyCode, m.currencyCode])} />
        <CodeItem field="priceBasis" readonly={readonly} label={intl.formatMessage({ id: 'field.priceBasis' })} code={CodeCategory.PriceBasis} />
    </Form >
})

const DeliveryInfoView = memo(({ headerInfo, setHeaderInfo, cbdsList, shippingRouteList, mode, messages, setMessages, contractRouteList }: {
    headerInfo: WorkingResult,
    setHeaderInfo: React.Dispatch<React.SetStateAction<WorkingResult>>,
    cbdsList: TnvCbds[],
    shippingRouteList: TnmShippingRoute[],
    mode: ScreenMode,
    messages: Message[],
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
    contractRouteList: ContractRoute[]
}) => {
    const intl = useIntl()
    const { contractType, contractId, receiverUid } = headerInfo
    const companyType = useGetCompanyType()
    const isSupplier = companyType === CbdsType.SUPP
    const dcMap: [string, string][] = useMemo(() => cbdsList.filter((m) => m.cbdsType === CbdsType.DC).map(m => [m.cbdsUid, m.cbdsCode]), [cbdsList])
    const shipperMap: [string, string][] = useMemo(() => cbdsList.filter(f => f.cbdsType === CbdsType.DC).map(m => [m.cbdsUid, m.cbdsCode]), [cbdsList])
    const srMap: [number, string][] = useMemo(() => shippingRouteList.map((m) => [m.srId, m.shippingRouteCode + (m.displayShippingRoute ? '(' + m.displayShippingRoute + ')' : '')]), [shippingRouteList])
    const spotSrMap: [number, string][] = useMemo(() => shippingRouteList.map((m) => [m.srId, m.shippingRouteCode]), [shippingRouteList])
    const fields = useMemo(() => getDeliveryFormCheckFields(true), [])
    const filedCheck = useFieldChecker(fields, setMessages)

    const readonly = useMemo(() => mode === ScreenMode.VIEW ? true : (contractId !== undefined && contractId !== null) ? true : false, [contractId, mode])
    const readonly2 = useMemo(() => mode === ScreenMode.VIEW ? true : (contractId !== undefined && contractId !== null) ? true : (contractType === ContractType.CUST_BU || contractType === ContractType.CUST_SUPP) ? false : true, [contractId, contractType, mode])
    const routeDescriptionReadonly = useMemo(() => mode === ScreenMode.VIEW ? true : (contractId !== undefined && contractId !== null) ? true : (contractType !== ContractType.CUST_BU && contractType !== ContractType.CUST_SUPP) ? true : false, [contractId, contractType, mode])
    const showItem1 = contractType === ContractType.CUST_BU || contractType === ContractType.CUST_SUPP

    const preReceiverCode = useMemo(() => contractRouteList.length > 0 ? (cbdsList.find((f) => f.cbdsType === CbdsType.DC && f.cbdsId === contractRouteList[0].receiveDcId)?.cbdsCode) : null, [cbdsList, contractRouteList])
    const sohwPreReceiverCode = readonly2 && (receiverUid === undefined || receiverUid === null)

    return <>
        <Form data={headerInfo} setData={setHeaderInfo} labelDisplay="block" helperDisplay="tooltip" ensure={filedCheck} messages={messages} >
            {/* Contract Route information */}
            {/* <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="routeInfo" /></SectionTitle></GridItem> */}
            {sohwPreReceiverCode && <StringItem field="preReceiverCode" readonly label={intl.formatMessage({ id: 'receiveDcCode' })} placeholder={preReceiverCode ?? undefined} />}
            {!sohwPreReceiverCode && <EntryItem field="receiverUid" readonly={readonly2} label={intl.formatMessage({ id: 'receiveDcCode' })} entries={dcMap} />}
            <EntryItem field="shipperUid" readonly={readonly} label={intl.formatMessage({ id: 'receiveShipperCode' })} entries={shipperMap} />
            {/* shipperUid */}
            <EntryItem field="srId" required={isSupplier ? true : false} readonly={readonly} label={intl.formatMessage({ id: 'field.shippingRouteCode' })} entries={srMap} />
            <EntryItem field="spotSrId" readonly={readonly} label={intl.formatMessage({ id: 'spotSR' })} entries={spotSrMap} />
            {showItem1 && <CodeItem field="autoDiFlag" readonly={readonly} label={intl.formatMessage({ id: 'autoDiFlag' })} code={CodeCategory.AutoDiFlag} />}
            <Break />
            <StringItem field="routeDescription" colSpan={2} readonly={routeDescriptionReadonly} label={intl.formatMessage({ id: 'field.routeDescription' })} />
        </Form >
    </>
})

const CustomsInfoView = memo(({ headerInfo, setHeaderInfo, cbdsList, mode, messages, setMessages }: {
    headerInfo: WorkingResult,
    setHeaderInfo: React.Dispatch<React.SetStateAction<WorkingResult>>,
    cbdsList: TnvCbds[],
    mode: ScreenMode,
    messages: Message[],
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
}) => {
    const intl = useIntl()
    const allCbdsMap: [string, string][] = useMemo(() => cbdsList.map((m) => [m.cbdsUid, m.cbdsCode]), [cbdsList])
    const { contractId, customsFlag } = headerInfo
    const readonly = mode === ScreenMode.VIEW || (contractId !== undefined && contractId !== null)
    const readonlyForCustoms = readonly || CustomsFlag.Y !== customsFlag

    const fields = useMemo(() => getCustomsFormCheckFields(true), [])
    const filedCheck = useFieldChecker(fields, setMessages)

    return (
        <Form data={headerInfo} setData={setHeaderInfo} labelDisplay="block" helperDisplay="tooltip" ensure={filedCheck} messages={messages} >
            {/* Customs information */}
            {/* <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="customsInfo" /></SectionTitle></GridItem> */}
            <CodeItem field="customsFlag" required readonly={readonly} label={intl.formatMessage({ id: 'field.customsFlag' })} code={CodeCategory.CustomsFlag} />
            <Break />
            <EntryItem field="expAgencyUid" readonly={readonlyForCustoms} label={intl.formatMessage({ id: 'expAgency' })} entries={allCbdsMap} />
            <EntryItem field="impAgencyUid" readonly={readonlyForCustoms} label={intl.formatMessage({ id: 'impAgency' })} entries={allCbdsMap} />
            <CodeItem field="printHSCodeFlag" readonly={readonlyForCustoms} label={intl.formatMessage({ id: 'printHsCodeFlag' })} code={CodeCategory.HscodeOutputFlag} />
        </Form >
    )
})

const IncotermsInfoView = memo(({ headerInfo, setHeaderInfo, incotermsList, mode, messages, setMessages }: {
    headerInfo: WorkingResult,
    setHeaderInfo: React.Dispatch<React.SetStateAction<WorkingResult>>,
    mode: ScreenMode,
    incotermsList: TnmIncoterms[],
    messages: Message[],
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
}) => {
    const intl = useIntl()
    const incoMap: [number, string][] = useMemo(() => incotermsList.map((m) => [m.incotermsId, m.incotermsCode]), [incotermsList])
    const { contractId } = headerInfo
    const readonly = mode === ScreenMode.VIEW || (contractId !== undefined && contractId !== null)
    const fields = useMemo(() => getIncotermsFormCheckFields(false), [])
    const filedCheck = useFieldChecker(fields, setMessages)

    return (
        <Form data={headerInfo} setData={setHeaderInfo} labelDisplay="block" helperDisplay="tooltip" ensure={filedCheck} messages={messages} >
            {/* Customs information */}
            {/* <GridItem columnSpan={3}><SectionTitle size="small"><FormattedMessage id="incotermsInfo" /></SectionTitle></GridItem> */}
            <EntryItem field="incotermsId" readonly={readonly} label={intl.formatMessage({ id: 'field.incotermsCode' })} entries={incoMap} />
            <StringItem field="incotermsPlace" readonly={readonly} label={intl.formatMessage({ id: 'field.incotermsPlace' })} />
        </Form >
    )
})

const WorkingPartsDataGridCard = memo(({ mode, nextPartsDataList, index, headerInfo, setNextPartsDataList, uomArr, currencyArr, view4DescriptionItemList, setView4DescriptionItem, weightDigits, volumeDigits }: {
    mode: ScreenMode,
    headerInfo: WorkingResult,
    nextPartsDataList: WorkingPartsList[],
    setNextPartsDataList: React.Dispatch<React.SetStateAction<WorkingPartsList[]>>,
    index: number,
    uomArr: string[],
    currencyArr: string[],
    view4DescriptionItemList: View4DescriptionItem[],
    setView4DescriptionItem: React.Dispatch<React.SetStateAction<View4DescriptionItem[]>>,
    weightDigits: number | undefined,
    volumeDigits: number | undefined
}) => {
    const intl = useIntl()
    const nextSellerCode: string = nextPartsDataList?.length > 0 ? nextPartsDataList[0]?.nextSellerCode ?? "" : ""
    const isFinalSeller = nextSellerCode === ""
    const [requestHeader, setRequestHeader] = useState<RequestHeaderResult>({} as RequestHeaderResult)
    const [messages, setMessages] = useState<Message[]>([])
    const fields = useMemo(() => ({
        description: { labelId: 'field.description', length: { max: 255 } },
    }), [])
    const filedCheck = useFieldChecker(fields, setMessages)

    const dataChange = useCallback<React.Dispatch<React.SetStateAction<RequestHeaderResult>>>(nextDraftDataFunc => {
        setRequestHeader(data => {
            const draftData = typeof nextDraftDataFunc === 'function' ? nextDraftDataFunc(data) : nextDraftDataFunc
            setView4DescriptionItem(itemList => itemList.map(item => item.index === index ? { ...item, description: draftData.description } : item))
            return draftData
        })
    }, [index, setView4DescriptionItem])

    return <>
        <SectionCard allowCollapse={true}>
            <SectionCardHeader
                icon={<InfoSharp />}
                title={isFinalSeller ? intl.formatMessage({ id: 'add_new_work_title_nine_final' }) : (intl.formatMessage({ id: 'add_new_work_title_nine' }) + " " + nextSellerCode)}
                subtitle={isFinalSeller ? intl.formatMessage({ id: 'add_new_work_message_nine_final' }) : intl.formatMessage({ id: "add_new_work_message_nine" })}
            />
            <SectionCardContent>
                {(mode !== ScreenMode.VIEW && !isFinalSeller) && <Form data={requestHeader} setData={dataChange} labelDisplay="block" helperDisplay="tooltip" messages={messages} ensure={filedCheck}>
                    <StringItem field="description" colSpan={2} label={intl.formatMessage({ id: 'field.description' })} />
                    <Input style={{ display: 'none' }} />
                </Form>}
                <WorkingResultDataGrid mode={mode} nextPartsDataList={nextPartsDataList} headerInfo={headerInfo} setNextPartsDataList={setNextPartsDataList} uomArr={uomArr} currencyArr={currencyArr} weightDigits={weightDigits} volumeDigits={volumeDigits} />
            </SectionCardContent>
        </SectionCard>
    </>
})

const WorkingResultDataGrid = memo(({ mode, nextPartsDataList, headerInfo, setNextPartsDataList, uomArr, currencyArr, weightDigits, volumeDigits }: {
    mode: ScreenMode,
    headerInfo: WorkingResult,
    nextPartsDataList: WorkingPartsList[],
    setNextPartsDataList: React.Dispatch<React.SetStateAction<WorkingPartsList[]>>,
    uomArr: string[],
    currencyArr: string[],
    weightDigits: number | undefined,
    volumeDigits: number | undefined
}) => {
    const intl = useIntl()
    const companyType = useGetCompanyType()
    const nextSellerCode: string = nextPartsDataList?.length > 0 ? nextPartsDataList[0]?.nextSellerCode ?? "" : ""
    const isFinalSeller = nextSellerCode === ""

    const columns = useMemo(() => arrx(
        { field: 'globerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 180 },
        { field: 'sellerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsNo' }), width: 180 },
        { field: 'unitPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsName' }), width: 180 },
        (companyType === CbdsType.CUST) ?
            { field: 'partsRefNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsRefNo' }), width: 180 } : undefined,
        (companyType === CbdsType.CUST) ?
            { field: 'backNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.backNo' }), width: 180 } : undefined,
        (companyType === CbdsType.CUST) ?
            { field: 'hscode', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.hscode' }), width: 180 } : undefined,
        { field: 'sellerUomCode', dataTypeName: 'uomType', title: intl.formatMessage({ id: 'field.uomCode' }), width: 180 },
        (companyType === CbdsType.CUST) ?
            { field: 'pairedFlag', dataTypeName: CodeCategory.PairedFlag, title: intl.formatMessage({ id: 'field.pairedFlag' }), width: 180 } : undefined,
        (companyType === CbdsType.CUST) ?
            { field: 'pairedPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'pairedPartsNo' }), width: 180 } : undefined,
        (companyType === CbdsType.CUST) ?
            { field: 'pairedOrderFlag', dataTypeName: CodeCategory.PairedOrderFlag, title: intl.formatMessage({ id: 'field.pairedOrderFlag' }), width: 180 } : undefined,
        { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 200 },
        { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 200 },
        { field: 'm3', dataTypeName: 'volume', title: intl.formatMessage({ id: 'field.m3' }), width: 200 },
        { field: 'netWeight', dataTypeName: 'weight', title: intl.formatMessage({ id: 'field.netWeight' }), width: 200 },
        { field: 'grossWeight', dataTypeName: 'weight', title: intl.formatMessage({ id: 'field.grossWeight' }), width: 200 },
        // { field: 'currency', dataTypeName: 'currencyType', title: intl.formatMessage({ id: 'field.currency' }), width: 200 },
        // { field: 'unitPrice', dataTypeName: 'number', title: intl.formatMessage({ id: 'unitPrice' }), width: 200 },
        !isFinalSeller ? { field: 'nextSellerPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.sellerPartsNo' }), width: 200 } : undefined,
    ), [companyType, intl, isFinalSeller])
    const onEditingRowCommit = useCallback((_: Column, row: Row) => {
        setNextPartsDataList(partsList => partsList.map(item => item.workingDetailId === row.workingDetailId ? row as WorkingPartsList : item))
        return true
    }, [setNextPartsDataList])

    const getRowId = useCallback((row: any) => row.workingDetailId, [])

    return <div style={{ width: '100%', height: '500px' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout />
            <DataTypePreset />
            <PercentTypeProvider />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.ContractRequestStatus} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.RepackingType} />
            <NumberTypeProvider name="weight" options={{ minimumFractionDigits: weightDigits !== undefined ? weightDigits : 3, maximumFractionDigits: weightDigits !== undefined ? weightDigits : 3 }} />
            <NumberTypeProvider name="volume" options={{ minimumFractionDigits: volumeDigits !== undefined ? volumeDigits : 3, maximumFractionDigits: volumeDigits !== undefined ? volumeDigits : 3 }} />
            <LibertyTypeProvider name="uomType" options={uomArr} />
            <LibertyTypeProvider name="currencyType" options={currencyArr} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.PairedFlag} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.PairedOrderFlag} />
            <Data rows={nextPartsDataList} columns={columns} getRowId={getRowId} />
            <ColumnFreeze />
            <ColumnVisibility
                defaultHiddenFields={[]}
                columnSettings={{
                    unitPartsNo: { disableUserControl: true },
                    globalPartsNo: { disableUserControl: true },
                }} />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase />
            <Editing
                enableInlineEdit={mode !== ScreenMode.VIEW && !isFinalSeller}
                columnSettings={{
                    partsNo: { editingDisabled: true },
                    globerPartsNo: { editingDisabled: true },
                    buyerPartsNo: { editingDisabled: true },
                    sellerPartsNo: { editingDisabled: true },
                    unitPartsNo: { editingDisabled: true },
                    globalPartsNo: { editingDisabled: true },
                    contractNo: { editingDisabled: true },
                    partsRefNo: { editingDisabled: true },
                    backNo: { editingDisabled: true },
                    unitPartsName: { editingDisabled: true },
                    hscode: { editingDisabled: true },
                    buyerUomCode: { editingDisabled: true },
                    pairedFlag: { editingDisabled: true },
                    pairedPartsNo: { editingDisabled: true },
                    pairedOrderFlag: { editingDisabled: true },
                    nextSellerPartsNo: { editingDisabled: true },
                }}
                onEditingCellCommit={onEditingRowCommit}
            />
            <Sorting />
            <Filtering />
        </DataGrid>
    </div>
})

const getContractBasicFormCheckFields = (required: boolean, contractType?: ContractType, orderFrequency?: OrderFrequency) => {
    const baseFields = {
        contractNo: { labelId: 'field.contractNo', required: required, length: { max: 30 } },
        contractShortCode: { labelId: 'field.contractShortCode', required: required, length: { max: 6 } },
        sellerUid: { labelId: 'field.sellerUid', required: required },
        consigneeUid: { labelId: 'field.consigneeUid', required: required },
        contractType: { labelId: 'field.contractType', required: required },
        buyerUid: { labelId: 'field.buyerUid', required: required },
        accounteeUid: { labelId: 'field.accounteeUid', required: required },
        deliveryToUid: { labelId: 'field.deliveryToUid', required: required },
    }

    let custFields = {}
    let suppFields = {}

    if (contractType === ContractType.CUST_BU || contractType === ContractType.CUST_SUPP) {

        const leadtimeName = orderFrequency === OrderFrequency.MONTHLY ? 'field.targetleadtimeMonth' : orderFrequency === OrderFrequency.WEEKLY ? 'field.targetleadtimeWeek' :
            orderFrequency === OrderFrequency.BI_WEEKLY ? 'field.targetleadtimeBiweek' : orderFrequency === OrderFrequency.TEN_DAY ? 'field.targetleadtimeTenDay' : 'field.targetLeadtime'

        custFields = {
            businessType: { labelId: 'field.businessType', required: required },
            orderFrequency: { labelId: 'field.orderFrequency', required: required },
            forecastNumber: { labelId: 'forecastNumber', required: required, length: { max: 2, integer: 2, scale: 0 }, compare: { min: 0 } },
            targetLeadTime: { labelId: leadtimeName, required: required, length: { max: 9, integer: 9, scale: 0 }, compare: { min: 0 } },
        }
    }

    if (contractType === ContractType.BU_SUPP || contractType === ContractType.CUST_SUPP) {
        suppFields = {
            confirmOrderLeadtime: { labelId: 'field.confirmOrderLeadtime', required: required, length: { max: 9, integer: 9, scale: 0 }, compare: { min: 0 } },
            leadtime: { labelId: 'field.leadtime', required: required, length: { max: 9, integer: 9, scale: 0 }, compare: { min: 0 } },
            deliveryPlanStartDate: { labelId: 'field.deliveryPlanStartDate', required: required, length: { max: 9, integer: 9, scale: 0 }, compare: { min: 0 } },
            deliveryPlanEndDate: { labelId: 'field.deliveryPlanEndDate', length: { max: 9, integer: 9, scale: 0 }, compare: { min: 0 } }
        }
    }

    return {
        ...baseFields,
        ...custFields,
        ...suppFields
    }
}

const getPaymentFormCheckFields = (required: boolean) => {
    return {
        paymentTermsId: { labelId: 'field.paymentTermsId', required: required },
        currencyCode: { labelId: 'field.currencyCode', required: required },
    }
}

const getDeliveryFormCheckFields = (required: boolean, srId?: number, shipperUid?: string, bu?: boolean, isSupplier?: boolean) => {
    return {
        srId: { labelId: 'field.srId', required: isSupplier ? required : (bu ? (shipperUid ? required : false) : false) },
        priceBasis: { labelId: 'field.priceBasis' },
        routeDescription: { labelId: 'field.routeDescription', length: { max: 255 } },
        shipperUid: { labelId: 'field.shipperUid', required: bu ? (srId ? required : false) : false },
    }
}

const getCustomsFormCheckFields = (required: boolean) => {
    return {
        customsFlag: { labelId: 'field.customsFlag', required: required }
    }
}

const getIncotermsFormCheckFields = (required: boolean) => {
    return {
        incotermsId: { labelId: 'field.incotermsCode', required: required },
        incotermsPlace: { labelId: 'field.incotermsPlace', required: required, length: { max: 255 } },
    }
}

const useStepStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        height: 50,
        top: 108,
        right: 96,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        background: 'white',
        padding: '8px 12px',
        borderRadius: theme.spacing(1.5),
        zIndex: theme.zIndex.appBar,
        boxShadow: theme.shadows[3],
    }
}))

const ReleaseAction = ({ data }: { data: WorkingResult }) => {
    // const [open, setOpen] = React.useState(false)
    const navigate = useNavigate()
    // const [isDeleteContract, setIsDeleteContract] = useState<boolean>(true)
    // const [showOption, setShowOption] = useState<boolean>(true)
    // const showOption = false
    // const closePop = useCallback(() => {
    //     setOpen(false)
    //     setIsDeleteContract(true)
    // }, [])
    // const getWorkingCountByContractId = useGetWorkingCountByContractId()
    const releaseActive = useReleaseWorking()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'release' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const clickEvent = useCallback(() => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            releaseActive({ workingId: data.workingId, isDeleteContract: 0 }, { serialized: true }).then(result => {
                // closePop()
                navigate('/receivedRequest')
            }).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [functionStore, dispatch, title, releaseActive, data.workingId, navigate])
    // const onclick = useCallback(() => {
        // setIsDeleteContract(false)
        // if (WorkingType.ASSIGN === data.workingType) {
        //     releaseActive({ workingId: data.workingId, isDeleteContract: 0 }, { serialized: true }).then(result => {
        //         closePop()
        //         navigate('/receivedRequest')
        //     })
        // } else {
            // if (data.contractId) {
            //     const contractId = data.contractId as number
            //     getWorkingCountByContractId({ contractId: contractId }, { serialized: true, silent: true }).then(result => {
            //         setShowOption(result.count > 1 ? false : true)
            //         setOpen(true)
            //     })
            // } else {
            //     setShowOption(true)
            //     setOpen(true)
            // }
        // }
    // }, [])
    return <>
        <Access access="MARS.MOS031.RELEASE">
            {/* <Button onClick={onclick} variant="contained" ><FormattedMessage id="release" /></Button> */}
            <Button onClick={clickEvent} disabled={disabled} variant="contained" ><FormattedMessage id="release" /></Button>
        </Access>
        {/* <DarkDialog open={open} onClose={closePop}>
            <DialogHeader onClose={() => setOpen(false)}> {showOption && <FormattedMessage id="releasePopTitle" />} {!showOption && <FormattedMessage id="releasePopTitle2" />} </DialogHeader>
            <DialogContent>
                {showOption && <FormControlLabel
                    control={
                        <Checkbox
                            value={isDeleteContract}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setIsDeleteContract(event.target.checked)
                            }}
                            checked={isDeleteContract}
                        />
                    }
                    label={<FormattedMessage id="releasePopCheckboxLabel" />}
                />}
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={closePop} />
                <DialogAction title={<FormattedMessage id="confirm" />} callback={clickEvent} disabled={disabled} />
            </DialogActions>
        </DarkDialog > */}
    </>
}

const AddShippingRouteAction = ({ data, requesHeaderInfo, requesPartsList, partsDataList }: {
    data: WorkingResult,
    requesHeaderInfo: RequestHeaderResult,
    requesPartsList: PartsDetailResult[],
    partsDataList: WorkingPartsList[],
}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const pathname = location.pathname
    const onclick = useCallback(() => {
        sessionStorage.setItem(searchCachekey, JSON.stringify(workingResultToJson({ ...data, requestHeader: requesHeaderInfo, requestPartsList: requesPartsList, partsList: partsDataList })))
        const toPath = `${pathname}/shippingrouteCreate`
        navigate(toPath)
    }, [data, navigate, partsDataList, pathname, requesHeaderInfo, requesPartsList])
    return <>
        {/* <Access access="MARS.MOS031.ADDSHIPPINGROUTE"> */}
        <Button onClick={onclick} style={{ color: '#00b0ff', backgroundColor: 'white', borderStyle: 'solid', borderWidth: 2 }} ><FormattedMessage id="addShippingRoute" /></Button>
        {/* </Access> */}
    </>
}

const AddDistributionCenterAction = ({ data, requesHeaderInfo, requesPartsList, partsDataList }: {
    data: WorkingResult,
    requesHeaderInfo: RequestHeaderResult,
    requesPartsList: PartsDetailResult[],
    partsDataList: WorkingPartsList[],
}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const pathname = location.pathname
    const onclick = useCallback(() => {
        sessionStorage.setItem(searchCachekey, JSON.stringify(workingResultToJson({ ...data, requestHeader: requesHeaderInfo, requestPartsList: requesPartsList, partsList: partsDataList })))
        const toPath = `${pathname}/dcCreate`
        navigate(toPath)
    }, [data, navigate, partsDataList, pathname, requesHeaderInfo, requesPartsList])
    return <>
        {/* <Access access="MARS.MOS031.ADDDC"> */}
        <Button onClick={onclick} style={{ color: '#00b0ff', backgroundColor: 'white', borderStyle: 'solid', borderWidth: 2 }} ><FormattedMessage id="addDistributionCenter" /></Button>
        {/* </Access> */}
    </>
}

const AddPaymentTermsAction = ({ data, requesHeaderInfo, requesPartsList, partsDataList }: {
    data: WorkingResult,
    requesHeaderInfo: RequestHeaderResult,
    requesPartsList: PartsDetailResult[],
    partsDataList: WorkingPartsList[],
}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const pathname = location.pathname
    const onclick = useCallback(() => {
        sessionStorage.setItem(searchCachekey, JSON.stringify(workingResultToJson({ ...data, requestHeader: requesHeaderInfo, requestPartsList: requesPartsList, partsList: partsDataList })))
        const toPath = `${pathname}/paymentCreate`
        navigate(toPath)
    }, [data, navigate, partsDataList, pathname, requesHeaderInfo, requesPartsList])
    return <>
        {/* <Access access="MARS.MOS031.ADDPAYMENTTERMS"> */}
        <Button onClick={onclick} style={{ color: '#00b0ff', backgroundColor: 'white', borderStyle: 'solid', borderWidth: 2 }} ><FormattedMessage id="addPaymentTerms" /></Button>
        {/* </Access> */}
    </>
}
