import { useCallback } from "react";
import { Projects } from "../../../utils/Projects";
import { useUpload } from "../../../utils/fetch/useFetch";

export const useUploadBUOrderChange = () => {
    const url = '/lcbm-order-api/order/upload/uploadBuOrderChange'
    const payloadProject = useCallback((obj: { file: File }) => obj, [])
    const resultProject = Projects.IGNORE
    return useUpload<{ file: File }, undefined>(url, payloadProject, resultProject)
}

