/* eslint-disable react-hooks/rules-of-hooks */
import { Button, DialogActions, DialogContent } from '@material-ui/core'
import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, ColumnVisibility, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, ToolbarLayout } from '@rithe/data-grid'
import { Break, DateItem, Form, Message, StringItem } from "@rithe/form"
import { Records, arrx } from '@rithe/utils'
import { default as React, ReactNode, memo, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useFunctionStore } from '../../../Root'
import { Access } from '../../../components/Access/Access'
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { ColumnVisibilityToolbarButton } from '../../../components/DataGrid/components/ColumnVisibilityToolbarButton'
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { FlexTable } from '../../../components/DataGrid/components/FlexTable'
import { Pagination } from '../../../components/DataGrid/components/Pagination'
import { SearchInput } from '../../../components/DataGrid/components/SearchInput'
import { DarkDialog } from '../../../components/Dialog/DarkDialog'
import { DialogAction } from '../../../components/Dialog/DialogAction'
import { DialogHeader } from '../../../components/Dialog/DialogHeader'
import { CodeItem } from '../../../components/Form/CodeItem'
import { View } from "../../../components/View/View"
import { ScreenMode } from '../../../services/common/enums/ScreenMode'
import { useRejectRequest } from '../../../services/master/apis/requestApi'
import { useConfirmContractRequest } from '../../../services/master/apis/requestDetailApi'
import { CodeCategory } from '../../../services/master/enums/CodeCategory'
import { ContractRequestStatus } from '../../../services/master/enums/ContractRequestStatus'
import { MLS152CustomsRequest } from '../../../services/master/models/MLS152CustomsRequest'
import { PartsDetailResult } from '../../../services/master/models/PartsDetailResult'
import { RejectRequestFactor } from '../../../services/master/models/RejectRequestFactor'
import { RequestHeaderResult } from '../../../services/master/models/RequestHeaderResult'
import { WorkingResult } from '../../../services/master/models/WorkingResult'
import { useFieldChecker, useFormValidater } from '../../../utils/ValidatorUtils'
import { applicationActions } from "../../Application/applicationSlice"

interface MOS031ModifyPackingPcUiProps {
    mode: ScreenMode,
    requesHeaderInfo: RequestHeaderResult,
    requesPartsList: PartsDetailResult[],
    data: WorkingResult,
    nextRequesList: MLS152CustomsRequest[],
    setNextRequesList: React.Dispatch<React.SetStateAction<MLS152CustomsRequest[]>>,
}

export const MOS031ModifyPackingPcUi = memo((props: MOS031ModifyPackingPcUiProps) => {
    const { mode, requesHeaderInfo, requesPartsList, data } = props
    const { requestHeader } = data

    const actions = useMemo(() => {
        let actions = []
        if (mode !== ScreenMode.VIEW && requesHeaderInfo.status === ContractRequestStatus.SUBMITTED) {
            actions.push(<CompleteAction requesHeaderInfo={requesHeaderInfo} />)
        }
        if (requestHeader?.status === ContractRequestStatus.SUBMITTED) {
            actions.push(<RejectDilog requesHeaderInfo={requesHeaderInfo} />)
        }

        return actions
    }, [mode, requesHeaderInfo, requestHeader?.status])

    return (
        <View>
            <RequestView mode={mode} requesHeaderInfo={requesHeaderInfo} requesPartsList={requesPartsList} data={data} actions={actions} />
        </View >
    )
})

const RequestView = ({ mode, requesHeaderInfo, requesPartsList, data, actions }: {
    mode: ScreenMode,
    requesHeaderInfo: RequestHeaderResult,
    requesPartsList: PartsDetailResult[],
    data: WorkingResult,
    actions: ReactNode | ReactNode[],
}) => {
    const intl = useIntl()
    return <>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={1}
                variant='normal'
                title={intl.formatMessage({ id: 'add_new_work_title_one' })}
                subtitle={intl.formatMessage({ id: "add_new_work_message_one" })}
                actions={actions}
            />
            <SectionCardContent>
                <RequestInfoDetailView headerInfo={requesHeaderInfo} />
            </SectionCardContent>
        </SectionCard>
        <SectionCard allowCollapse>
            <SectionCardHeader
                serialNumber={2}
                title={intl.formatMessage({ id: 'add_new_work_title_two' })}
                subtitle={intl.formatMessage({ id: "add_new_work_message_two" })}
            />
            <SectionCardContent>
                <RequestPartsDetailView partsList={requesPartsList} />
            </SectionCardContent>
        </SectionCard>
    </>
}

const RequestInfoDetailView = memo(({ headerInfo }: {
    headerInfo: RequestHeaderResult
}) => {
    const intl = useIntl()
    return <Form data={headerInfo} labelDisplay="block" helperDisplay="tooltip" >
        <StringItem field="requestNo" readonly label={intl.formatMessage({ id: 'field.requestNo' })} />
        <StringItem field="submittedBy" readonly label={intl.formatMessage({ id: 'field.submittedBy' })} />
        <CodeItem field="status" readonly label={intl.formatMessage({ id: 'field.status' })} code={CodeCategory.ContractRequestStatus} />

        <CodeItem field="requestType" readonly label={intl.formatMessage({ id: 'field.requestType' })} code={CodeCategory.ContractRequestType} />
        <DateItem field="submittedDate" readonly label={intl.formatMessage({ id: 'field.submittedDate' })} />
        <Break />

        <StringItem field="requestFromCode" readonly label={intl.formatMessage({ id: 'field.requestFrom' })} />
        <StringItem field="requestToCode" readonly label={intl.formatMessage({ id: 'field.requestTo' })} />
        <Break />

        <StringItem field="description" readonly label={intl.formatMessage({ id: 'field.description' })} colSpan={2} />
    </Form>
})

const RequestPartsDetailView = memo(({ partsList }: {
    partsList: PartsDetailResult[]
}) => {
    const intl = useIntl()
    const columns = useMemo(() => arrx(
        { field: 'partsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.partsNo' }), width: 180 },
        { field: 'unitPartsNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsNo' }), width: 180 },
        { field: 'unitPartsName', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.unitPartsName' }), width: 180 },
        { field: 'contractNo', dataTypeName: 'string', title: intl.formatMessage({ id: 'field.contractNo' }), width: 180 },
        { field: 'effectiveFrom', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.effectiveFrom' }), width: 200 },
        { field: 'orderLot', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.orderLot' }), width: 200 },
        { field: 'spq', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.spq' }), width: 200 },
        { field: 'm3', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.m3' }), width: 200 },
        { field: 'netWeight', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.netWeight' }), width: 200 },
        { field: 'grossWeight', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.grossWeight' }), width: 200 },
    ), [intl])

    return <div style={{ width: '100%', height: '500px' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout Table={FlexTable} />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            {/* <CodeCategoryTypeProvider codeCategory={CodeCategory.ContractRequestStatus} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.PairedOrderFlag} />
            <CodeCategoryTypeProvider codeCategory={CodeCategory.PairedFlag} /> */}
            <Data rows={partsList} columns={columns} />
            <ColumnFreeze />
            <ColumnVisibility
                defaultHiddenFields={[]}
                ToolbarButton={ColumnVisibilityToolbarButton}
            />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
            <Action width={52} />
        </DataGrid>
    </div>
})

const CompleteAction = (({ requesHeaderInfo }: {
    requesHeaderInfo: RequestHeaderResult,
}) => {
    const navigate = useNavigate()
    const confirmActive = useConfirmContractRequest()
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'complete' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const completeClick = useCallback(() => {
        const functionId = functionStore.register(() => {
            setDisabled(true)
            confirmActive({ requestId: requesHeaderInfo.requestId }, { serialized: true }).then(result => {
                navigate('/receivedRequest')
            }).finally(() => {
                setDisabled(false)
            })
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [functionStore, dispatch, title, confirmActive, requesHeaderInfo.requestId, navigate])

    return <>
        <Access access="MARS.MOS031.COMPLETE">
            <Button onClick={completeClick} variant="contained" style={{ backgroundColor: '#003362', color: '#fff' }} disabled={disabled}><FormattedMessage id="complete" /></Button >
        </Access>
    </>
})

const RejectDilog = ({ requesHeaderInfo }: {
    requesHeaderInfo: RequestHeaderResult,
}) => {
    const intl = useIntl()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [remark, setRemark] = useState<RejectRequestFactor>({} as any)
    const [messages, setMessages] = useState<Message[]>([])
    const [open, setOpen] = useState<boolean>(false)
    const callback = useCallback(() => {
        setOpen(true)
    }, [])
    const fields = {
        rejectReason: { labelId: 'field.contractNo', required: true, length: { max: 255 } },
    }
    const filedCheck = useFieldChecker(fields, setMessages)
    const formValidate = useFormValidater(setMessages, fields)
    const rejectRequest = useRejectRequest()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'reject' }), [intl])
    const [disabled, setDisabled] = useState<boolean>(false)
    const doReject = useCallback(() => {
        if (requesHeaderInfo.requestId) {
            if (formValidate(remark)) {
                const functionId = functionStore.register(() => {
                    setDisabled(true)
                    rejectRequest(({ ...remark, requestId: requesHeaderInfo.requestId, requestIdList: [requesHeaderInfo.requestId] }), { serialized: true }).then(result => {
                        navigate('/receivedRequest')
                    }).finally(() => {
                        setDisabled(false)
                    })
                })
                dispatch(applicationActions.pushWarning({
                    title: title,
                    messages: { code: 'c0001', args: [title] },
                    actions: [{
                        label: 'CANCEL'
                    }, {
                        functionId,
                        label: 'CONFIRM',
                    }]
                }))
            }
        } else {
            dispatch(applicationActions.pushError({ title: { code: 'reject' }, messages: { code: 'w0002' } }))
        }
    }, [dispatch, formValidate, functionStore, navigate, rejectRequest, remark, requesHeaderInfo.requestId, title])
    // do checl
    return <>
        <Access access="MARS.MOS031.REJECT">
            <Button onClick={callback} variant="contained" style={{ backgroundColor: '#fff' }}><FormattedMessage id="reject" /></Button >
        </Access>
        <DarkDialog open={open} style={{ height: '600px', overflow: 'hidden' }} maxWidth="sm" fullWidth fullScreen={false} keepMounted={true}>
            <DialogHeader onClose={() => setOpen(false)}>
                <FormattedMessage id="isRequestReject" />
            </DialogHeader>
            <DialogContent>
                <Form data={remark} setData={setRemark} labelDisplay="block" helperDisplay="tooltip" minWidth={300} columnCount={1} messages={messages} ensure={filedCheck}>
                    <StringItem field="rejectReason" label={intl.formatMessage({ id: 'field.rejectReason' })} />
                </Form>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={() => setOpen(false)} />
                <DialogAction title={<FormattedMessage id="reject" />} callback={doReject} disabled={disabled} />
            </DialogActions>
        </DarkDialog>
    </>
}

