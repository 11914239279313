import { DialogActions, DialogContent } from "@material-ui/core"
import { Action, ColumnFreeze, ColumnOrdering, ColumnResizing, Data, DataGrid, DataTypePreset, Filtering, PaginationLayout, Paging, RowActionProvider, Searching, Sorting, TableBodyLayout, TableHeaderLayout, TableLayout, TableRow, ToolbarActionProvider, ToolbarLayout } from "@rithe/data-grid"
import { DataGridRowActionProps } from "@rithe/data-grid/dist/components/basic/DataGridRowAction"
import { DateItem, EntriesItem, EntryItem, Form, Message, NumberItem, StringItem } from "@rithe/form"
import { Comparators, Records } from "@rithe/utils"
import moment from "moment"
import React, { memo, useCallback, useMemo, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { SaveCallbackViewAction } from "../../../components/Action/SaveCallbackViewAction"
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { SectionCardHeader } from "../../../components/Card/SectionCardHeader"
import { FlexScrollbar } from "../../../components/DataGrid/components/FlexScrollbar"
import { PageInfo } from "../../../components/DataGrid/components/PageInfo"
import { PageSelect } from "../../../components/DataGrid/components/PageSelect"
import { PageSizeSelect } from "../../../components/DataGrid/components/PageSizeSelect"
import { Pagination } from "../../../components/DataGrid/components/Pagination"
import { SearchInput } from "../../../components/DataGrid/components/SearchInput"
import { DeleteCallbackRowAction } from "../../../components/DataGrid/rowActions/DeleteCallbackRowAction"
import { CreateCallbackToolbarAction } from "../../../components/DataGrid/toolbarActions/CreateCallbackToolbarAction"
import { DownloadGroupedToolbarAction } from "../../../components/DataGrid/toolbarActions/DownloadGroupedToolbarAction"
import { GroupedCallbackItem } from "../../../components/DataGrid/toolbarActions/GroupedCallbackItem"
import { DarkDialog } from "../../../components/Dialog/DarkDialog"
import { DialogAction } from "../../../components/Dialog/DialogAction"
import { DialogHeader } from "../../../components/Dialog/DialogHeader"
import { CodeItem } from "../../../components/Form/CodeItem"
import { View } from "../../../components/View/View"
import { useFunctionStore } from "../../../Root"
import { ScreenMode } from "../../../services/common/enums/ScreenMode"
import { useDownloadShippingRouteDetailMasterByShippingRouteId } from "../../../services/master/apis/masterDownloadApi"
import { useSaveShippingRouteDetail } from "../../../services/master/apis/shippingRouteApi"
import { CbdsType } from "../../../services/master/enums/CbdsType"
import { CodeCategory } from "../../../services/master/enums/CodeCategory"
import { ShippingRouteDetailResult } from "../../../services/master/models/ShippingRouteDetailResult"
import { TnmPort } from "../../../services/master/models/TnmPort"
import { TnmRegion } from "../../../services/master/models/TnmRegion"
import { TnmShippingRouteDetail } from "../../../services/master/models/TnmShippingRouteDetail"
import { TnmState } from "../../../services/master/models/TnmState"
import { TnvCbds } from "../../../services/master/models/TnvCbds"
import { useFieldChecker, useFormValidater } from "../../../utils/ValidatorUtils"
import { applicationActions } from "../../Application/applicationSlice"

interface MLS121PcUiProps {
    data: ShippingRouteDetailResult,
    setData: React.Dispatch<React.SetStateAction<ShippingRouteDetailResult>>,
    detailData: TnmShippingRouteDetail[],
    setDetailData: React.Dispatch<React.SetStateAction<TnmShippingRouteDetail[]>>,
    search: () => void,
    mode: ScreenMode,
    regions: TnmRegion[],
    states: TnmState[],
    ports: TnmPort[],
    cbdsList: TnvCbds[],
    path: string
}

export const MLS121PcUi = (props: MLS121PcUiProps) => {
    const { mode, data, setData, detailData, setDetailData, regions, states, ports, cbdsList, search, path } = props
    const intl = useIntl()
    const [messages, setMessages] = useState<Message[]>([])
    const action = mode !== ScreenMode.VIEW ? [<SaveAction
        data={data}
        detailData={detailData}
        setData={setData}
        setMessages={setMessages}
        mode={mode}
        search={search}
        path={path}
    />] : []
    return <>
        <View actions={action}>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={1}
                    title={intl.formatMessage({ id: 'BasicInfo' })}
                    subtitle={intl.formatMessage({ id: 'basicInfo' })}
                />
                <SectionCardContent>
                    <MainInfo data={data}
                        setData={setData}
                        mode={mode}
                        regions={regions}
                        states={states}
                        ports={ports}
                        cbdsList={cbdsList}
                        messages={messages}
                        setMessages={setMessages}
                    />
                </SectionCardContent>
            </SectionCard>
            <SectionCard allowCollapse>
                <SectionCardHeader
                    serialNumber={2}
                    title={intl.formatMessage({ id: 'ShippingInformation' })}
                    subtitle="Please input related shipping list details"
                />
                <SectionCardContent>
                    <DetailPartsInfo mode={mode} detailData={detailData} setDetailData={setDetailData} data={data} />
                </SectionCardContent>
            </SectionCard>
        </View >
    </>
}

const MainInfo = memo((props: {
    data: ShippingRouteDetailResult,
    setData: React.Dispatch<React.SetStateAction<ShippingRouteDetailResult>>,
    mode: ScreenMode,
    regions: TnmRegion[],
    states: TnmState[],
    ports: TnmPort[],
    cbdsList: TnvCbds[],
    messages: Message[],
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
}) => {
    const { data, setData, mode, regions, states, ports, cbdsList, messages, setMessages } = props
    const intl = useIntl()
    const regionsMap = useMemo(() => regions.map(m => [m.regionCode, m.regionCode] as [string, string]), [regions])
    const shipperMap = useMemo(() => cbdsList.filter(f => f.cbdsType === CbdsType.DC || f.cbdsType === CbdsType.SUPP).map(m => [m.cbdsUid, m.cbdsCode] as [string, string]), [cbdsList])
    const receiverMap = useMemo(() => cbdsList.filter(f => f.cbdsType === CbdsType.DC || f.cbdsType === CbdsType.CUST).map(m => [m.cbdsUid, m.cbdsCode] as [string, string]), [cbdsList])
    const fromStateMap = useMemo(() => states.filter(f => f.regionCode === data.fromRegion).map(m => [m.stateCode, m.stateCode] as [string, string]), [data.fromRegion, states])
    const toStateMap = useMemo(() => states.filter(f => f.regionCode === data.toRegion).map(m => [m.stateCode, m.stateCode] as [string, string]), [data.toRegion, states])
    const fromPortMap = useMemo(() => ports.filter(f => f.regionCode === data.fromRegion).map(m => [m.portId, m.portCode] as [number, string]), [data.fromRegion, ports])
    const toPortMap = useMemo(() => ports.filter(f => f.regionCode === data.toRegion).map(m => [m.portId, m.portCode] as [number, string]), [data.toRegion, ports])
    const isDifferentShow = data.fromRegion === data.toRegion ? false : true
    const formFields = useMemo(() => getFormCheckFields(isDifferentShow), [isDifferentShow])
    const filedCheck = useFieldChecker(formFields, setMessages)
    const editable = mode !== ScreenMode.VIEW
    return <Form data={data} setData={setData} labelDisplay="block" helperDisplay="tooltip" messages={messages} setMessages={setMessages} ensure={filedCheck}>
        <StringItem field="shippingRouteCode" required readonly={mode !== ScreenMode.CREATE} label={intl.formatMessage({ id: 'field.shippingRouteCode' })} />
        <StringItem field="displayShippingRoute" readonly={!editable} label={intl.formatMessage({ id: 'displayShippingRoute' })} />
        <CodeItem field="activeFlag" required readonly={!editable} label={intl.formatMessage({ id: 'field.activeFlag' })} code={CodeCategory.ActiveFlag} />
        <CodeItem field="shippingMode" required readonly={!editable} label={intl.formatMessage({ id: 'field.shippingMode' })} code={CodeCategory.ShippingMode} />

        <EntryItem field="fromRegion" required={isDifferentShow} readonly={!editable} label={intl.formatMessage({ id: 'originCountry' })} entries={regionsMap} />
        <EntryItem field="toRegion" required={isDifferentShow} readonly={!editable} label={intl.formatMessage({ id: 'destinationCountry' })} entries={regionsMap} />
        <EntryItem field="fromState" readonly={!editable} label={intl.formatMessage({ id: 'originState' })} entries={fromStateMap} />
        <EntryItem field="toState" readonly={!editable} label={intl.formatMessage({ id: 'destinationState' })} entries={toStateMap} />

        <EntryItem field="shipperUid" required readonly={!editable} label={intl.formatMessage({ id: 'field.shipper' })} entries={shipperMap} />
        <EntryItem field="receiverUid" required readonly={!editable} label={intl.formatMessage({ id: 'field.receiver' })} entries={receiverMap} />
        {isDifferentShow &&
            <EntryItem field="fromPortId" required readonly={!editable} label={intl.formatMessage({ id: 'originPort' })} entries={fromPortMap} />
        }
        {isDifferentShow &&
            <EntryItem field="toPortId" required readonly={!editable} label={intl.formatMessage({ id: 'destinationPort' })} entries={toPortMap} />
        }
        {isDifferentShow &&
            <NumberItem field="expCcLeadtime" required readonly={!editable} label={intl.formatMessage({ id: 'originCustomsLeadtime' })} />
        }
        {isDifferentShow &&
            <NumberItem field="impCcLeadtime" required readonly={!editable} label={intl.formatMessage({ id: 'destinationCustomLeadtime' })} />
        }
    </Form>
})

const DetailPartsInfo = memo((props: {
    data: ShippingRouteDetailResult,
    detailData: TnmShippingRouteDetail[],
    setDetailData: React.Dispatch<React.SetStateAction<TnmShippingRouteDetail[]>>,
    mode: ScreenMode,
}) => {
    const intl = useIntl()
    const { detailData, mode, setDetailData, data } = props
    const columns = useMemo(() => [
        { field: 'cyCloseDate', dataTypeName: 'date', title: intl.formatMessage({ id: 'cyCloseDate' }), width: 200 },
        { field: 'etd', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.etd' }), width: 200 },
        { field: 'eta', dataTypeName: 'date', title: intl.formatMessage({ id: 'field.eta' }), width: 200 },
        { field: 'transportDays', dataTypeName: 'number', title: intl.formatMessage({ id: 'field.transportDays' }), width: 150 },
        { field: 'cyCloseDays', dataTypeName: 'number', title: intl.formatMessage({ id: 'cyCloseDays' }), width: 150 },
    ], [intl])

    const actionProps = useMemo(() => ({ setDetailData, data }), [setDetailData, data])
    const display = useCallback(() => mode !== ScreenMode.VIEW, [mode])
    const displayDownload = useCallback(() => mode === ScreenMode.VIEW, [mode])

    return <div style={{ width: '100%' }}>
        <DataGrid>
            <ToolbarLayout />
            <TableLayout Container={FlexScrollbar}>
                <TableHeaderLayout sticky />
                <TableBodyLayout />
            </TableLayout>
            <PaginationLayout Pagination={Pagination} />
            <DataTypePreset />
            <Data rows={detailData} columns={columns} />
            <ToolbarActionProvider Action={CreateAction} actionProps={actionProps} display={display} />
            <ToolbarActionProvider Action={DownloadAction} actionProps={actionProps} display={displayDownload} />
            <RowActionProvider name="delete" Action={DeleteRowAction} actionProps={actionProps} display={display} />
            <ColumnFreeze />
            <ColumnOrdering defaultOrder={columns.map(column => column.field)} />
            <ColumnResizing defaultSize={Records.from(columns.map(({ field, width }) => [field, width ?? 0]))} />
            <Searching ignoreCase Input={SearchInput} />
            <Sorting />
            <Filtering />
            <Paging defaultPageSize={20} availablePageSizes={[10, 15, 20, 50]} PageInfo={PageInfo} PageSelect={PageSelect} PageSizeSelect={PageSizeSelect} />
            <Action width={200} />
        </DataGrid>
    </div>
})

const SaveAction = (({ data, detailData, setData, setMessages, mode, search, path }: {
    data: ShippingRouteDetailResult,
    detailData: TnmShippingRouteDetail[],
    setData: React.Dispatch<React.SetStateAction<ShippingRouteDetailResult>>,
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
    mode: ScreenMode,
    search: () => void,
    path: string
}) => {
    const navigate = useNavigate()
    const saveActive = useSaveShippingRouteDetail()

    const isDifferentShow = data.fromRegion === data.toRegion ? false : true

    const fields = useMemo(() => data.fromPortId === data.toPortId ? getFormCheckFields(isDifferentShow) : getFormCheckFields_different(), [data.fromPortId, data.toPortId, isDifferentShow])
    const formValidate = useFormValidater(setMessages, fields)
    const [disabled, setDisabled] = useState<boolean>(false)
    const onclickSave = useCallback(() => {
        if (formValidate(data)) {
            setDisabled(true)
            saveActive({ ...data, detailList: detailData }, { serialized: true }).then(result => {
                if (mode === ScreenMode.CREATE) {
                    if (path === '/receivedRequest/addNewPart-:requestNo/shippingrouteCreate') {
                        navigate(-1)
                    } else {
                        navigate(`/shippingroute/edit-${result?.srId}`)
                    }
                } else {
                    search()
                }
            }).finally(() => {
                setDisabled(false)
            })
        }
    }, [data, detailData, formValidate, navigate, mode, path, saveActive, search])
    return <SaveCallbackViewAction access="MARS.MLS091.SAVE" callback={onclickSave} disabled={disabled} />
})

interface NewRouteInfo {
    etdWeek?: number[],
    etaWeek?: number[],
    transportDays?: number,
    cyCloseDays?: number,
    firstEtd?: Date,
    lastEtd?: Date,
    shippingFrequency?: number[],
}

const CreateAction = ({ setDetailData }: { setDetailData: React.Dispatch<React.SetStateAction<TnmShippingRouteDetail[]>> }) => {
    const intl = useIntl()
    const [open, setOpen,] = useState(false)
    const onOpen = useCallback(() => setOpen(true), [])
    const onClose = useCallback(() => { setMessages([]); setData({}); setOpen(false) }, [])
    const [data, setData] = useState<NewRouteInfo>({})
    const [messages, setMessages] = useState<Message[]>([])
    const fields = useMemo(() => getAddFormCheckFields(), [])
    const checkItem = useFieldChecker(fields, setMessages)

    const filedCheck = useMemo(() => {
        return (field: string, value: any): Message[] => {

            const messages = checkItem(field, value)
            if (messages.length > 0) return messages
            // other check
            if (field === "firstEtd") {
                if (value && data['lastEtd']) {
                    // check 
                    if (moment(value).isAfter(moment(data['lastEtd']))) {
                        const firstEtdLabel = intl.formatMessage({ id: 'field.firstEtd' })
                        const lastEtdLabel = intl.formatMessage({ id: 'field.lastEtd' })
                        messages.push({ field: field, level: 'error', value: intl.formatMessage({ id: 'w0339' }, [lastEtdLabel, firstEtdLabel] as any) })
                    }
                }
            } else if (field === "lastEtd") {
                if (value && data['firstEtd']) {
                    // check 
                    if (moment(value).isBefore(moment(data['firstEtd']))) {
                        const firstEtdLabel = intl.formatMessage({ id: 'field.firstEtd' })
                        const lastEtdLabel = intl.formatMessage({ id: 'field.lastEtd' })
                        messages.push({ field: field, level: 'error', value: intl.formatMessage({ id: 'w0339' }, [lastEtdLabel, firstEtdLabel] as any) })
                    }
                }
            }
            return messages
        }
    }, [checkItem, data, intl])

    const weeksMap = useMemo<[number, string][]>(() => [
        [0, intl.formatMessage({ id: 'Sunday' })],
        [1, intl.formatMessage({ id: 'Monday' })],
        [2, intl.formatMessage({ id: 'Tuesday' })],
        [3, intl.formatMessage({ id: 'Wednesday' })],
        [4, intl.formatMessage({ id: 'Thursday' })],
        [5, intl.formatMessage({ id: 'Friday' })],
        [6, intl.formatMessage({ id: 'Saturday' })],
    ], [intl])

    const frequencyMap = useMemo<[number, string][]>(() => [
        [1, '1st Week'],
        [2, '2nd Week'],
        [3, '3rd Week'],
        [4, '4th Week'],
        [5, '5th Week'],
    ], [])

    const onDataChange = useCallback((field: string, value: any) => {
        if (field !== 'etdWeek' && field !== 'transportDays') {
            return
        }
        setData(data => {
            if (data.etdWeek !== undefined && data.transportDays !== undefined && data.transportDays >= 0) {
                return { ...data, etaWeek: data.etdWeek.map(f => (f + Number(data.transportDays)) % 7) }
            }
            return data
        })
    }, [])

    const dispatch = useDispatch()

    const formValidate = useFormValidater(setMessages, fields)
    const onSave = useCallback(() => {
        if (!formValidate(data)) {
            return
        }
        // check ETD and ETA 
        const { etdWeek, transportDays, cyCloseDays, firstEtd, lastEtd, shippingFrequency } = data
        const messages: Message[] = []
        // other check
        if (firstEtd && lastEtd) {
            // check 
            if (moment(firstEtd).isAfter(moment(lastEtd))) {
                const firstEtdLabel = intl.formatMessage({ id: 'field.firstEtd' })
                const lastEtdLabel = intl.formatMessage({ id: 'field.lastEtd' })
                messages.push({ field: 'firstEtd', level: 'error', value: intl.formatMessage({ id: 'w0339' }, [lastEtdLabel, firstEtdLabel] as any) })
                messages.push({ field: 'lastEtd', level: 'error', value: intl.formatMessage({ id: 'w0339' }, [lastEtdLabel, firstEtdLabel] as any) })
            }

            if (moment(firstEtd).isAfter(moment(lastEtd))) {
                dispatch(applicationActions.pushError({ title: { code: 'confirm' }, messages: { code: 'w0616' } }))
                return
            }
        }
        if (transportDays !== null && transportDays !== undefined) {
            if (transportDays > 99) {
                dispatch(applicationActions.pushError({ title: { code: 'confirm' }, messages: { code: 'w0907' } }))
                return
            }
        }
        if (cyCloseDays !== null && cyCloseDays !== undefined) {
            if (cyCloseDays > 99) {
                dispatch(applicationActions.pushError({ title: { code: 'confirm' }, messages: { code: 'w0908' } }))
                return
            }
        }

        if (messages.length !== 0) {
            setMessages(messages)
            return
        }

        let loopDate = moment(firstEtd)
        let lastDate = moment(lastEtd)
        let routes: TnmShippingRouteDetail[] = []
        // do check 
        while (!loopDate.isAfter(lastDate)) {
            // do check 
            const dayOfMonth = loopDate.date()
            const frequency = Math.floor((dayOfMonth - 1) / 7) + 1
            const realEtdWeek = etdWeek?.includes(loopDate.day())
            if (realEtdWeek && shippingFrequency?.includes(frequency)) {
                const etd: Date = loopDate.toDate()
                routes.push(({
                    cyCloseDate: moment(etd).add((0 - (cyCloseDays ?? 0)), 'd').toDate(),
                    etd: etd,
                    eta: moment(etd).add(transportDays, 'd').toDate(),
                    transportDays: transportDays,
                    cyCloseDays: cyCloseDays,
                }))
            }
            loopDate.add(1, 'd')
        }

        // add 
        setDetailData(list => {
            // let newList = [...list, ...routes]
            const existsList = list.map(m => m.etd?.getTime())
            const newRoutes = routes.filter(m => !existsList.includes(m.etd?.getTime()))
            const newList = [...list, ...newRoutes]
            newList.sort(Comparators.compare<TnmShippingRouteDetail, number>(d => d.etd?.getTime() ?? 0, Comparators.reverseOrder()))
            return newList
        })
        // close
        setOpen(false)
        // clear values
        setData({})
    }, [data, dispatch, formValidate, intl, setDetailData])

    return <>
        <CreateCallbackToolbarAction access="MARS.MLS121.CREATENEW" callback={onOpen} />
        <DarkDialog open={open} style={{ height: '100%', overflow: 'hidden', width: '100%' }}>
            <DialogHeader ><FormattedMessage id="addNewRoute" /></DialogHeader>
            <DialogContent style={{ height: '100%', overflow: 'hidden', width: '100%', marginBottom: 20 }}>
                <Form data={data} setData={setData} labelDisplay="block" helperDisplay="tooltip" minWidth={500} maxWidth={500} columnCount={1} messages={messages} setMessages={setMessages} onChange={onDataChange} ensure={filedCheck}>
                    <EntriesItem field="etdWeek" required label={intl.formatMessage({ id: 'field.etdWeek' })} entries={weeksMap} />
                    <EntriesItem field="etaWeek" readonly label={intl.formatMessage({ id: 'field.etaWeek' })} entries={weeksMap} />
                    <NumberItem field="transportDays" required label={intl.formatMessage({ id: 'field.transportDays' })} />
                    <NumberItem field="cyCloseDays" required label={intl.formatMessage({ id: 'cyCloseDays' })} />
                    <DateItem field="firstEtd" required label={intl.formatMessage({ id: 'field.firstEtd' })} />
                    <DateItem field="lastEtd" required label={intl.formatMessage({ id: 'field.lastEtd' })} />
                    <EntriesItem field="shippingFrequency" required label={intl.formatMessage({ id: 'field.shippingFrequency' })} entries={frequencyMap} colSpan={1} />
                </Form>
            </DialogContent>
            <DialogActions>
                <DialogAction outlined title={<FormattedMessage id="cancel" />} callback={onClose} />
                <DialogAction title={<FormattedMessage id="confirm" />} callback={onSave} />
            </DialogActions>
        </DarkDialog>
    </>
}

const DownloadAction = ({ data }: { data: ShippingRouteDetailResult }) => {

    const downloadShippingDetail = useDownloadShippingRouteDetailMasterByShippingRouteId()

    const downloadShipping = useCallback(() => {
        downloadShippingDetail({ shippingRouteIdList: [data.srId] })
    }, [data.srId, downloadShippingDetail])

    return <DownloadGroupedToolbarAction access="MARS.MLS120.DOWNLOAD">
        {onClose => <>
            <GroupedCallbackItem label={<FormattedMessage id="downloadShippingDetail" />} callback={downloadShipping} onClose={onClose} />
        </>}
    </DownloadGroupedToolbarAction>
}

const getAddFormCheckFields = () => {
    return ({
        etdWeek: { labelId: 'field.etdWeek', required: true },
        etaWeek: { labelId: 'field.etaWeek', required: true },
        transportDays: { labelId: 'field.transportDays', required: true, compare: { min: 0 }, length: { scale: 0 } },
        cyCloseDays: { labelId: 'cyCloseDays', required: true, compare: { min: 0 }, length: { scale: 0 } },
        firstEtd: { labelId: 'field.firstEtd', required: true },
        lastEtd: { labelId: 'field.lastEtd', required: true },
        shippingFrequency: { labelId: 'field.shippingFrequency', required: true }
    })
}

const getFormCheckFields = (isDifferentShow: boolean) => {
    return ({
        shippingRouteCode: { labelId: 'field.shippingRouteCode', required: true, length: { max: 20 } },
        displayShippingRoute: { labelId: 'displayShippingRoute', required: false, length: { max: 100 } },
        shipperUid: { labelId: 'shipper', required: true },
        receiverUid: { labelId: 'receiver', required: true },
        fromRegion: { labelId: 'originCountry', required: isDifferentShow },
        fromPortId: { labelId: 'originPort', required: isDifferentShow },
        expCcLeadtime: { labelId: 'originCustomsLeadtime', required: isDifferentShow, length: { max: 11, scale: 0 } },
        toRegion: { labelId: 'destinationCountry', required: isDifferentShow },
        toPortId: { labelId: 'destinationPort', required: isDifferentShow },
        impCcLeadtime: { labelId: 'destinationCustomLeadtime', required: isDifferentShow, length: { max: 11, scale: 0 } },
        shippingMode: { labelId: 'field.shippingMode', required: true },
        activeFlag: { labelId: 'field.activeFlag', required: true }
    })
}

const getFormCheckFields_different = () => {
    return ({
        shippingRouteCode: { labelId: 'field.shippingRouteCode', required: true, length: { max: 20 } },
        displayShippingRoute: { labelId: 'displayShippingRoute', required: false, length: { max: 100 } },
        shipperUid: { labelId: 'shipper', required: true },
        receiverUid: { labelId: 'receiver', required: true },
        fromRegion: { labelId: 'originCountry', required: true },
        fromPortId: { labelId: 'originPort', required: true },
        expCcLeadtime: { labelId: 'originCustomsLeadtime', required: true, length: { max: 11, scale: 0 } },
        toRegion: { labelId: 'destinationCountry', required: true },
        toPortId: { labelId: 'destinationPort', required: true },
        impCcLeadtime: { labelId: 'destinationCustomLeadtime', required: true, length: { max: 11, scale: 0 } },
        shippingMode: { labelId: 'field.shippingMode', required: true },
        activeFlag: { labelId: 'field.activeFlag', required: true }
    })
}

const DeleteRowAction = ({ tableRow, setDetailData }: DataGridRowActionProps & { setDetailData: React.Dispatch<React.SetStateAction<TnmShippingRouteDetail[]>> }) => {
    const dispatch = useDispatch()
    const intl = useIntl()
    const functionStore = useFunctionStore()
    const title = useMemo(() => intl.formatMessage({ id: 'delete' }), [intl])
    const callback = useCallback((tableRow: TableRow) => {
        const functionId = functionStore.register(() => {
            setDetailData(detail => detail.filter((_, index) => index !== tableRow.rowId))
        })
        dispatch(applicationActions.pushWarning({
            title: title,
            messages: { code: 'c0001', args: [title] },
            actions: [{
                label: 'CANCEL'
            }, {
                functionId,
                label: 'CONFIRM',
            }]
        }))
    }, [dispatch, functionStore, setDetailData, title])
    return <DeleteCallbackRowAction tableRow={tableRow} callback={callback} />
}
