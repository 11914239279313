import { Break, DateItem, EntryItem, Form, StringItem } from "@rithe/form"
import { default as React, useCallback } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { DownloadGroupedViewAction } from '../../../components/Action/DownloadGroupedViewAction'
import { GroupedViewActionCallbackItem } from '../../../components/Action/GroupedViewActionCallbackItem'
import { SectionCard } from "../../../components/Card/SectionCard"
import { SectionCardContent } from "../../../components/Card/SectionCardContent"
import { View } from "../../../components/View/View"
import { useDownloadOubPriorityByModule, useDownloadOubPriorityByParts } from '../../../services/delivery/apis/deliveryDownloadApi'
import { DownloadOubPriorityView } from "../../../services/delivery/models/DownloadOubPriorityView"
import { InitOutboundResult } from "../../../services/delivery/models/InitOutboundResult"

interface LOS020PcUiProps {
    factor: DownloadOubPriorityView,
    setFactor: React.Dispatch<React.SetStateAction<DownloadOubPriorityView>>,
    initData: InitOutboundResult,
}

export const LOS020PcUi = (props: LOS020PcUiProps) => {
    const { factor, setFactor } = props
    const intl = useIntl()
    const { supplierCodeList, receiverList, customerCodeList } = props.initData

    return <View actions={[<DownloadAction factor={factor} />]}>
        <SectionCard>
            <SectionCardContent>
                <Form data={factor} setData={setFactor} labelDisplay="block" helperDisplay="tooltip">
                    <DateItem field="onbPlanDateStartFormat" label={intl.formatMessage({ id: 'lastestOutDateStart' })} />
                    <DateItem field="onbPlanDateEndFormat" label={intl.formatMessage({ id: 'lastestOutDateEnd' })} />
                    <Break />
                    <DateItem field="rcvrReqInbPlanDateStartFormat" label={intl.formatMessage({ id: 'ReceiverReqInbPlanDateStart' })} />
                    <DateItem field="rcvrReqInbPlanDateEndFormat" label={intl.formatMessage({ id: 'ReceiverReqInbPlanDateEnd' })} />
                    <Break />
                    <DateItem field="suggestDateStartFormat" label={intl.formatMessage({ id: 'sugOutDateStart' })} />
                    <DateItem field="suggestDateEndFormat" label={intl.formatMessage({ id: 'sugOutDateEnd' })} />
                    <Break />
                    <EntryItem field="customerId" label={intl.formatMessage({ id: 'field.customerCode' })} entries={customerCodeList ?? []} />
                    <EntryItem field="supplierId" label={intl.formatMessage({ id: 'field.supplierCode' })} entries={supplierCodeList ?? []} />
                    <Break />
                    <StringItem field="salesOrderNo" label={intl.formatMessage({ id: 'salesOrderNo' })} />
                    <StringItem field="partsNo" label={intl.formatMessage({ id: 'field.partsNo' })} />
                    <Break />
                    <EntryItem field="receiverUid" label={intl.formatMessage({ id: 'receiver' })} entries={receiverList ?? []} />
                </Form>
            </SectionCardContent>
        </SectionCard>
    </View >
}

const DownloadAction = ({ factor }: { factor: DownloadOubPriorityView, }) => {
    const downloadOubByParts = useDownloadOubPriorityByParts()
    const downloadOubByModule = useDownloadOubPriorityByModule()

    const downloadByParts = useCallback(() => {
        downloadOubByParts(factor)
    }, [downloadOubByParts, factor])
    const downloadByModule = useCallback(() => {
        downloadOubByModule(factor)
    }, [downloadOubByModule, factor])
    return <DownloadGroupedViewAction access="LOGI.LOS020.DOWNLOAD">
        {onClose => <>
            <GroupedViewActionCallbackItem onClose={onClose} callback={downloadByParts} label={<FormattedMessage id="downloadByParts" />} />
            <GroupedViewActionCallbackItem onClose={onClose} callback={downloadByModule} label={<FormattedMessage id="downloadByModule" />} />
        </>}
    </DownloadGroupedViewAction>
}
