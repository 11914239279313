import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { useSelector } from "react-redux"
import appConfig from "../../../configs/appConfig"
import userSettingApi from "../../../services/system/apis/userSettingApi"
import { DayOfWeek } from "../../../services/system/enums/DayOfWeek"
import { NotificationAction } from "../../../services/system/enums/NotificationAction"
import { StockAlertFrequency } from "../../../services/system/enums/StockAlertFrequency"
import { StockAlertLevel } from "../../../services/system/enums/StockAlertLevel"
import errorToNotification from "../../../utils/axios/errorToNotification"
import responseToNotification from "../../../utils/axios/responseToNotification"
import thunkAxiosInstance from "../../../utils/axios/thunkAxiosInstance"
import { applicationActions } from "../../Application/applicationSlice"

interface MSESF021State {
    daSettingItems: {
        item: string,
        value: boolean,
    }[],
    notificationSettingItems: {
        actions: NotificationAction[],
        days: DayOfWeek[],
    }[],
    stockAlertSettingItems: {
        level: StockAlertLevel,
        frequency: StockAlertFrequency,
        days: DayOfWeek[],
    }[],
}

const initialState: MSESF021State = {
    daSettingItems: [],
    notificationSettingItems: [],
    stockAlertSettingItems: [],
}

const getUserSetting = createAsyncThunk<void>('MSESF021/getUserSetting', (_, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    return userSettingApi.get(axiosInstance)
        .then(response => {
            const { daSetting, notificationSettings, stockAlertSettings } = response.data

            const daSettingItems: { item: string, value: boolean }[] = []
            daSettingItems.push({ item: 'ruleBasedRecommendation', value: daSetting.ruleBasedRecommendation })
            daSettingItems.push({ item: 'aiPageRecommendation', value: daSetting.aiPageRecommendation })

            const notificationSettingItems: { actions: NotificationAction[], days: DayOfWeek[] }[] = []
            for (const notificationSetting of Object.values(notificationSettings)) {
                let sameDayItem = notificationSettingItems.find(item => item.days.join(',') === notificationSetting.days.join(','))
                if (sameDayItem) {
                    sameDayItem.actions.push(notificationSetting.action)
                } else {
                    notificationSettingItems.push({
                        actions: [notificationSetting.action],
                        days: notificationSetting.days,
                    })
                }
            }

            const stockAlertSettingItems: { level: StockAlertLevel, frequency: StockAlertFrequency, days: DayOfWeek[] }[] = []
            for (const stockAlertSetting of Object.values(stockAlertSettings)) {
                stockAlertSettingItems.push({
                    level: stockAlertSetting.level,
                    frequency: stockAlertSetting.frequency,
                    days: stockAlertSetting.days,
                })
            }

            thunk.dispatch(MSESF021Actions.loadUserSetting({
                daSettingItems,
                notificationSettingItems,
                stockAlertSettingItems,
            }))
        })
        .catch(error => {
            thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
        })
})

const saveUserSetting = createAsyncThunk<void>('MSESF021/updateUserSetting', (_, thunk) => {
    const axiosInstance = thunkAxiosInstance(thunk)
    const state = selectMSESF021State(thunk.getState())
    const { daSettingItems, notificationSettingItems, stockAlertSettingItems } = state

    const daSetting = {
        ruleBasedRecommendation: daSettingItems.find(item => item.item === 'ruleBasedRecommendation')?.value ?? false,
        aiPageRecommendation: daSettingItems.find(item => item.item === 'aiPageRecommendation')?.value ?? false,
    }

    const notificationSettings: Record<NotificationAction, { action: NotificationAction, days: DayOfWeek[] }> = {} as any
    for (const notificationSettingItem of notificationSettingItems) {
        for (const action of notificationSettingItem.actions) {
            notificationSettings[action] = { action, days: notificationSettingItem.days }
        }
    }

    const stockAlertSettings: Record<StockAlertLevel, { level: StockAlertLevel, frequency: StockAlertFrequency, days: DayOfWeek[] }> = {} as any
    for (const stockAlertSettingItem of stockAlertSettingItems) {
        stockAlertSettings[stockAlertSettingItem.level] = stockAlertSettingItem
    }

    return userSettingApi.update(axiosInstance, {
        daSetting,
        notificationSettings: notificationSettings,
        stockAlertSettings: stockAlertSettings,
    }).then(response => {
        thunk.dispatch(applicationActions.pushNotification(responseToNotification(response)))
        thunk.dispatch(applicationActions.setUserSetting({ daSetting }))
    }).catch(error => {
        thunk.dispatch(applicationActions.pushNotification(errorToNotification(error)))
    })
})

export const msesf021Slice = createSlice({
    name: 'msesf021',
    initialState,
    reducers: {
        loadUserSetting: (state, action: PayloadAction<MSESF021State>) => {
            const { daSettingItems, notificationSettingItems, stockAlertSettingItems } = action.payload
            state.daSettingItems = daSettingItems
            state.notificationSettingItems = notificationSettingItems
            state.stockAlertSettingItems = stockAlertSettingItems
        },
        toggleRuleBasedRecommendation: (state) => {
            const item = state.daSettingItems.find(item => item.item === 'ruleBasedRecommendation')
            if (item) {
                item.value = !item.value
            }
        },
        toggleAiPageRecommendation: (state) => {
            const item = state.daSettingItems.find(item => item.item === 'aiPageRecommendation')
            if (item) {
                item.value = !item.value
            }
        },
        addNotificationSetting: (state) => {
            state.notificationSettingItems.push({
                actions: [],
                days: [],
            })
        },
        removeNotificationSetting: (state, action: PayloadAction<number>) => {
            state.notificationSettingItems.splice(action.payload, 1)
        },
        updateNotificationSettingActions: (state, action: PayloadAction<{ index: number, actions: NotificationAction[] }>) => {
            const { index: currentIndex, actions } = action.payload
            state.notificationSettingItems[currentIndex].actions = actions
            state.notificationSettingItems.forEach((item, index) => {
                if (index !== currentIndex) {
                    item.actions = item.actions.filter(action => !actions.includes(action))
                }
            })
        },
        updateNotificationSettingDays: (state, action: PayloadAction<{ index: number, days: DayOfWeek[] }>) => {
            const { index, days } = action.payload
            state.notificationSettingItems[index].days = days
        },
        updateStockAlertSettingFrequency: (state, action: PayloadAction<{ level: StockAlertLevel, frequency: StockAlertFrequency | null }>) => {
            const { level, frequency } = action.payload
            if (frequency) {
                const item = state.stockAlertSettingItems.find(item => item.level === level)
                if (item) {
                    item.frequency = frequency
                    if (item.frequency === StockAlertFrequency.DAILY) {
                        item.days = []
                    }
                } else {
                    state.stockAlertSettingItems.push({ level, frequency, days: [] })
                }
            } else {
                state.stockAlertSettingItems = state.stockAlertSettingItems.filter(item => item.level !== level)
            }
        },
        updateStockAlertSettingDays: (state, action: PayloadAction<{ level: StockAlertLevel, days: DayOfWeek[] }>) => {
            const { level, days } = action.payload
            const item = state.stockAlertSettingItems.find(item => item.level === level)
            if (item && item.frequency !== StockAlertFrequency.DAILY) {
                item.days = days
            }
        },
    },
})

export const MSESF021Actions = {
    ...msesf021Slice.actions,
    getUserSetting,
    saveUserSetting,
}

export function selectMSESF021State(state: any) {
    return state[appConfig.appFullName][msesf021Slice.name] as MSESF021State
}

export function useMSESF021Selector<R>(selector: (state: MSESF021State) => R, equalityFn?: (left: R, right: R) => boolean) {
    return useSelector<any, R>(state => selector(selectMSESF021State(state)), equalityFn)
}
