import { useCallback } from "react"
import { usePost } from "../../../utils/fetch/useFetch"
import { Projects } from "../../../utils/Projects"
import { VisualizationReportView, VisualizationReportViewFromJson } from "../models/VisualizationReportView"

export const useGetVisualizationReportView = () => {
    const url = '/smt-api/vreport/getPokayokeList'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(Projects.array(VisualizationReportViewFromJson), [])
    return usePost<undefined, VisualizationReportView[]>(url, payloadProject, resultProject)
}








